import React, { useEffect } from 'react'
import dayjs from 'dayjs'
import { Input } from '@psyomics/components'
import Label from '../../components/Label'
import Field from '../../components/Field'
import Styles from './DateEntry.module.scss'
import { OnboardingFormProps } from 'registration/pages/Onboarding/use-Onboarding'

type IDateEntryProps = OnboardingFormProps & React.ComponentPropsWithoutRef<'div'> & { label?: string }

/**
 * DateEntry is a set of three text inputs to input a date
 * It is hardcoded to date of birth currently!
 * As per https://design-system.service.gov.uk/patterns/dates/
 */
const DateEntry: React.FC<IDateEntryProps> = ({ values, errors, touched, setFieldValue, onChange, onBlur, label }) => {
    useEffect(() => {
        let dob
        if (
            values.dobDay &&
            !errors.dobDay &&
            values.dobMonth &&
            !errors.dobMonth &&
            values.dobYear &&
            !errors.dobYear
        ) {
            dob = dayjs()
                .startOf('day')
                .year(parseInt(values.dobYear))
                .month(parseInt(values.dobMonth) - 1)
                .date(parseInt(values.dobDay))
        } else {
            dob = null
        }
        setFieldValue('dobDate', dob?.isValid() ? dob?.toDate() : new Date())
        setFieldValue('birthdate', dob?.isValid() ? dob?.format('YYYY-MM-DD') : '')
    }, [errors.dobDay, errors.dobMonth, errors.dobYear, setFieldValue, values.dobDay, values.dobMonth, values.dobYear])

    return (
        <fieldset className={Styles.container}>
            {<Label el="legend">{label}</Label>}
            <div className={Styles.fields}>
                <Field>
                    <Label appearance="secondary" htmlFor="dobDay">
                        Day
                    </Label>
                    <Input
                        data-private="lipsum"
                        type="text"
                        id="dobDay"
                        name="dobDay"
                        appearance="outlined"
                        autoComplete="off"
                        inputMode="numeric"
                        spellCheck="false"
                        pattern="^[0123]?[0-9]$"
                        maxLength={2}
                        placeholder="DD"
                        invalid={errors.dobDay && touched.dobDay ? true : false}
                        onChange={onChange}
                        onKeyUp={onChange}
                        onBlur={onBlur}
                        value={values.dobDay}
                    />
                </Field>
                <Field>
                    <Label appearance="secondary" htmlFor="dobMonth">
                        Month
                    </Label>
                    <Input
                        data-private="lipsum"
                        type="text"
                        id="dobMonth"
                        name="dobMonth"
                        appearance="outlined"
                        autoComplete="off"
                        inputMode="numeric"
                        invalid={errors.dobMonth && touched.dobMonth ? true : false}
                        spellCheck="false"
                        pattern="^[01]?[0-9]$"
                        maxLength={2}
                        placeholder="MM"
                        onChange={onChange}
                        onKeyUp={onChange}
                        onBlur={onBlur}
                        value={values.dobMonth}
                    />
                </Field>
                <Field>
                    <Label appearance="secondary" htmlFor="dobYear">
                        Year
                    </Label>
                    <Input
                        data-private="lipsum"
                        type="text"
                        id="dobYear"
                        name="dobYear"
                        appearance="outlined"
                        autoComplete="off"
                        inputMode="numeric"
                        invalid={errors.dobYear && touched.dobYear ? true : false}
                        spellCheck="false"
                        pattern="^[0-9]{4}$"
                        maxLength={4}
                        placeholder="YYYY"
                        onChange={onChange}
                        onKeyUp={onChange}
                        onBlur={onBlur}
                        value={values.dobYear}
                    />
                </Field>
            </div>
        </fieldset>
    )
}

export default DateEntry
