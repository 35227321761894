"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserType = exports.ReportResultMessage = exports.DecisionType = void 0;
var DecisionType;
(function (DecisionType) {
    DecisionType["FreeQuestion"] = "FREE_QUESTION";
    DecisionType["Multichoice"] = "MULTICHOICE";
    DecisionType["NoAnswer"] = "NO_ANSWER";
    DecisionType["Score"] = "SCORE";
})(DecisionType = exports.DecisionType || (exports.DecisionType = {}));
var ReportResultMessage;
(function (ReportResultMessage) {
    ReportResultMessage["AccessDenied"] = "ACCESS_DENIED";
    ReportResultMessage["NotFound"] = "NOT_FOUND";
    ReportResultMessage["Success"] = "SUCCESS";
})(ReportResultMessage = exports.ReportResultMessage || (exports.ReportResultMessage = {}));
var UserType;
(function (UserType) {
    UserType["D2C"] = "D2C";
    UserType["Nhs"] = "NHS";
})(UserType = exports.UserType || (exports.UserType = {}));
