"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.upperCaseFirst = void 0;
// used as a useful tool elsewhere
const upperCaseFirst = (str) => {
    if (!str || !str.length) {
        return '';
    }
    // special case - might be a space first...
    // todo: get first non-whitespace and uppercase it
    if (str.length > 1 && str[0] === ' ') {
        return ` ${str[1].toUpperCase()}${str.substring(2)}`;
    }
    return `${str[0].toUpperCase()}${str.substring(1)}`;
};
exports.upperCaseFirst = upperCaseFirst;
