import React, { createContext, useState, useContext, useMemo } from 'react'
import { ReferralDetailsContext } from 'ui/app/context/referralDetails'

type OnboardingContextType = {
    userUpdated: boolean | null
    setUserUpdated: React.Dispatch<React.SetStateAction<boolean | null>>
    error: string | null
    setError: React.Dispatch<React.SetStateAction<string | null>>
    askOnboardingQuestionsAgain: boolean | null
}

const OnboardingContext = createContext<OnboardingContextType | null>(null)

export const OnboardingProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [userUpdated, setUserUpdated] = useState<boolean | null>(null)
    const [error, setError] = useState<string | null>(null)

    const referralDetails = useContext(ReferralDetailsContext)
    const { dbPatientUserNeedsOnboarding } = referralDetails?.activeReferral || {}

    let askOnboardingQuestionsAgain: boolean | null = null
    if (typeof dbPatientUserNeedsOnboarding === 'boolean') {
        askOnboardingQuestionsAgain = userUpdated ? false : dbPatientUserNeedsOnboarding
    }

    return (
        <OnboardingContext.Provider
            value={useMemo(
                () => ({
                    userUpdated,
                    setUserUpdated,
                    error,
                    setError,
                    askOnboardingQuestionsAgain,
                }),
                [userUpdated, error, askOnboardingQuestionsAgain]
            )}
        >
            {children}
        </OnboardingContext.Provider>
    )
}

export const useOnboardingState = (): OnboardingContextType => {
    return useContext(OnboardingContext) as OnboardingContextType
}
