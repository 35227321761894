import React from 'react'
import PropTypes from 'prop-types'
import { classNameMaker } from '../../utils'
import Styles from './Validation.module.scss'

type IValidationProps = React.ComponentPropsWithoutRef<'div'>

const Validation: React.FC<IValidationProps> = ({ className, children, ...props }) => {
    const containerClass: string = classNameMaker({
        [Styles.container]: true,
    })
    return (
        <div className={`${containerClass} ${className}`} {...props}>
            {children}
        </div>
    )
}

Validation.propTypes = {
    className: PropTypes.string,
}

export default Validation
