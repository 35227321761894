import type { CognitoUser } from '@aws-amplify/auth'
import { User } from 'censeo-core'

export type UserStatus =
    | { type: 'Unknown' }
    | { type: 'Unauthenticated' }
    | { type: 'Authenticated' }
    | { type: 'Unconfirmed'; emailAddress: string; password: string }

export type AuthState = {
    isAuthenticated: UserStatus
    user: User | undefined
    cognitoUser: CognitoUser | undefined
}

export type UpdatableUserAttributes = {
    family_name?: string
    given_name?: string
    address?: string
    birthdate?: string
    termsAcceptedDate?: string
    sex?: string
    gender?: string
    ethnicity?: string
    pregnant?: string
}

export type AuthReducerAction =
    | { type: 'UNAUTHENTICATED' }
    | { type: 'IS_LOGGING_IN' }
    | { type: 'LOGIN_SUCCESS'; user: User; cognitoUser: CognitoUser }
    | { type: 'LOGIN_FAILURE'; error: Error | undefined }
    | {
          type: 'UNCONFIRMED_USER_LOGIN_ATTEMPT'
          emailAddress: string
          password: string
      }
    | { type: 'LOGOUT_SUCCESS' }
    | {
          type: 'UPDATE_USER'
          updatedUserAttributes: UpdatableUserAttributes
      }

export const initialState: AuthState = {
    isAuthenticated: {
        type: 'Unknown',
    },
    user: undefined,
    cognitoUser: undefined,
}

export function authReducer(state: AuthState, action: AuthReducerAction): AuthState {
    switch (action.type) {
        case 'UNAUTHENTICATED': {
            return { ...state, isAuthenticated: { type: 'Unauthenticated' } }
        }
        case 'IS_LOGGING_IN':
        case 'LOGIN_FAILURE':
        case 'LOGOUT_SUCCESS': {
            return {
                ...initialState,
                isAuthenticated: { type: 'Unauthenticated' },
            }
        }
        case 'UNCONFIRMED_USER_LOGIN_ATTEMPT': {
            return {
                ...initialState,
                isAuthenticated: {
                    emailAddress: action.emailAddress,
                    password: action.password,
                    type: 'Unconfirmed',
                },
            }
        }
        case 'LOGIN_SUCCESS': {
            return {
                isAuthenticated: {
                    type: 'Authenticated',
                },
                user: action.user,
                cognitoUser: action.cognitoUser,
            }
        }
        case 'UPDATE_USER': {
            return {
                ...state,
                user: {
                    ...(state.user as User),
                    ...action.updatedUserAttributes,
                },
            }
        }

        default: {
            throw new Error(`Unsupported action type: ${JSON.stringify(action)}`)
        }
    }
}
