"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.userTypeFromString = void 0;
const censeo_1 = require("../schema/generated/censeo");
const userTypeFromString = (input) => {
    const entry = Object.entries(censeo_1.UserType).find(([, v]) => v === input);
    if (!entry) {
        return censeo_1.UserType.D2C;
    }
    return censeo_1.UserType[entry[0]];
};
exports.userTypeFromString = userTypeFromString;
