import { ApolloClient, NormalizedCacheObject, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { Auth } from 'aws-amplify'
import { toAbsolutePath } from '../hostOverride'
// import * as Sentry from '@sentry/react'

const httpLink = createHttpLink({
    uri: toAbsolutePath('/graphql'),
})

const authLink = (apiKey?: string) => {
    return setContext(async (_, { headers }) => {
        try {
            const session = await Auth.currentSession()
            const token = session.getIdToken().getJwtToken()
            return {
                headers: {
                    ...headers,
                    authorization: token ? `Bearer ${token}` : '',
                },
            }
        } catch (error) {
            console.warn('No Cognito token available, falling back to API Key authentication', error)
            return {
                headers: {
                    ...headers,
                    ...(apiKey ? { 'x-api-key': apiKey } : {}),
                },
            }
        }
    })
}

const errorLink = onError(({ networkError }) => {
    if (networkError && 'response' in networkError) {
        const response = (networkError as { response: Response }).response

        const headers = response.headers

        const headersObject: Record<string, string> = {}
        if (headers && headers.forEach) {
            headers.forEach((value, key) => {
                headersObject[key] = value
            })
        } else {
            console.error('Headers not found or not iterable', headers)
        }

        const requestId = headers.get('X-Amzn-Requestid')
        if (!requestId) {
            console.error('Request ID not found in headers', headers)
        }

        // TODO: Commenting this out due to ammount of Sentry errors. But we still need to resolve issue with users getting logged out too early
        // Sentry.captureException(new Error('graphql error'), {
        //     extra: {
        //         networkError,
        //         headers: headersObject,
        //         'X-Amzn-Requestid': requestId,
        //     },
        // })
    } else {
        console.error('Network error or response not found', networkError)
    }
})

export const client = (apiKey?: string): ApolloClient<NormalizedCacheObject> => {
    const link = ApolloLink.from([authLink(apiKey), errorLink, httpLink])
    return new ApolloClient({
        link,
        cache: new InMemoryCache(),
    })
}
