import { gql } from '@apollo/client'
import { CheckIn, AuthorisedReportsForUser } from 'censeo-core'
import { DocumentNode } from 'graphql'

export const checkInQuery: DocumentNode = gql`
    query CheckIn {
        checkIn {
            latestReport {
                report {
                    id
                }
                time
            }
            completedSessions {
                name
                firstQuestionId
            }
            incompleteSession {
                session {
                    name
                    minutesDuration
                    firstQuestionId
                }
                percentageComplete
                time
                nextQuestionId
            }
            remainingSessions {
                firstQuestionId
                name
                minutesDuration
            }
        }
        authorisedReportsForUser {
            assessmentId
            createdAt
        }
    }
`

export const demoCheckInQuery: DocumentNode = gql`
    query demoCheckIn($allAnswers: [AnsweredQuestion!]!) {
        demoCheckIn(allAnswers: $allAnswers) {
            completedSessions {
                name
                firstQuestionId
            }
            incompleteSession {
                session {
                    name
                    minutesDuration
                    firstQuestionId
                }
                percentageComplete
                time
                nextQuestionId
            }
            remainingSessions {
                firstQuestionId
                name
                minutesDuration
            }
            latestReport {
                report {
                    id
                }
            }
        }
    }
`

export interface StandardCheckInData {
    checkIn: CheckIn
    authorisedReportsForUser: AuthorisedReportsForUser[]
}
interface DemoCheckInData {
    demoCheckIn: CheckIn
}

export type CheckInData = StandardCheckInData | DemoCheckInData
