"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.symptoms = void 0;
exports.symptoms = [
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": " Has {}experienced one or more very distressing and traumatic experiences",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "T-s1",
                    "optionSummaries": {
                        "96789d75-b879-5f46-8963-b49a0d5a36c7": "not ",
                        "3587b76d-5e17-5be1-8482-6865a9629851": ""
                    }
                }
            ]
        },
        "section": [
            "Current trauma related symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "T-s3",
                    "optionSummaries": {
                        "ab657537-a14b-5d23-bcc5-8a10faac75b2": "Patient's daily life is not being affected by the experience(s)",
                        "4fb618ab-1a98-5a8b-8a64-000b45d953f9": "Patient's daily life is being affected slightly by the experience(s)",
                        "f8e62fbd-28e3-5527-94f5-9590869545a3": "Patient's daily life is being moderately affected by the experience(s)",
                        "ab90ebb6-b526-5d58-97dd-181c2ddaefdf": "Patient's daily life is being substantially affected by the experience(s)",
                        "cd79664e-d646-54f6-8ce2-1f236d79730f": "Patient's daily life is being extremely affected by the experience(s)",
                        "e3912e04-a5d3-5bf1-869a-a651db93c0f7": "Patient skipped out of trauma section"
                    }
                }
            ]
        },
        "section": [
            "Current trauma related symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "T-s2",
                    "optionSummaries": {
                        "d3770e6a-b463-59c3-9904-5893bd7ead01": "Would prefer not to answer questions about the distressing and traumatic event"
                    }
                }
            ]
        },
        "section": [
            "Current trauma related symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "MultiOptionSummary",
            "questionId": "T-1",
            "title": "Reported traumatic event(s)",
            "overrides": {
                "2f912715-981e-56cd-9769-6fe50451871e": "Death of somebody close",
                "0a40d484-e62c-53ec-811c-a195a979b58a": "Separation from parents or caregivers",
                "af0c6a89-6944-5fae-be10-5243fe9bc934": "Injury, illness or accident of someone close",
                "b9a35392-1bfe-5319-bdb2-34a68f9dff20": "Witnessed harm to an unknown person",
                "a4253f51-5531-586e-bb9f-47e454c7456f": "Involved in accidental harm to another person",
                "d8fafb20-a4f7-5702-82a4-2bf31ee2b523": "Physically assaulted or robbed",
                "20e21d03-7bd9-504e-8726-d2e3e2fc8ef9": "As a child: physically hurt by a family member or other known person",
                "6137d2c9-5a9b-5ec3-a2ae-18d6ad8c5ba0": "As an adult: physically hurt by a family member or other known person",
                "b32206e4-0455-500a-9564-b9d1b1ddb853": "As a child: screamed at, humiliated, insulted, intimidated or controlled by a family member or other known person",
                "966cf398-e957-5889-b1a4-cffa64703207": "As an adult: screamed at, humiliated, insulted, intimidated or controlled by a family member or other known person",
                "e24f82b7-a712-5954-b5fb-421e2119db45": "As an adult: seeing or hearing someone in the household being physically hurt, screamed at, insulted or humiliated",
                "8ec3c46b-8732-5ae9-8c3d-1846b1d05562": "As a child: seeing or hearing someone in the household being physically hurt, screamed at, insulted or humiliated",
                "38b6ec1a-a824-5fd9-bf53-faa9fe2ee355": "As a child: parents or caregiver being unable to provide physical or emotional care",
                "afa84b91-8bb8-582a-97d1-8a13ec771151": "Bullying, stigma or abuse based on identity (e.g. racism, homophobia sexism)",
                "a64cebc6-da46-5d9b-bef5-379159acdadd": "An experience that was not listed",
                "3a55ecb6-c41a-5f30-b1b1-e7cf0728d3a7": "none listed"
            }
        },
        "section": []
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Traumatic event {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "T-35.1",
                    "optionSummaries": {
                        "8f245622-a6a2-5b80-9d90-88282267bfcf": "occurred in the last week",
                        "cce99747-f442-5342-811f-2952a9cc3246": "occurred in the last month",
                        "10fd8dda-f5dc-51b9-93fc-fc09ed79b932": "occurred several months ago",
                        "a8521592-054a-5b2b-98e1-f8c0b3ea00a8": "occurred one year ago or longer",
                        "fc423d44-8ec4-521f-b617-97b57c13d0c0": "Patient chose not to answer regarding how long ago this traumatic experience took place",
                        "0366d501-9bb3-5b7c-9e65-ce9fa9bdc19e": "Patient skipped out of trauma section"
                    }
                }
            ]
        },
        "section": [
            "Current trauma related symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "T-35.2",
                    "optionSummaries": {
                        "2d8f0fc4-245d-51f9-860d-df2b9ec99a3c": "moderate emotional difficulty at the time of the event",
                        "afa0086f-aed1-569d-8b56-6273d9e8fcbb": "extreme emotional difficulty at the time of the event",
                        "f25bcc46-8c38-5275-9be9-3f4ba80df5dd": "extreme emotional difficulty at the time of the event",
                        "8818f5c3-ca8b-5e1a-8f4a-5738238d28fc": "Patient chose not to answer how emotionally difficult this traumatic experience was",
                        "97706d03-4cae-5a94-87d6-7df3a9db2e41": "Patient skipped out of the trauma section"
                    }
                }
            ]
        },
        "section": [
            "Current trauma related symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "T-35.3",
                    "optionSummaries": {
                        "39788390-fbb7-5cc6-b6a3-a65d420d91f0": "currently moderately emotionally affected",
                        "ee7318eb-c751-57b3-9a6e-38248f55fbaa": "currently extremely emotionally affected",
                        "5aed6bad-c53b-59e3-9fa8-47fbf9f32a38": "currently extremely emotionally affected",
                        "ac7cd80b-8d42-562e-b921-f1fe8e38cbad": "Patient chose not to answer if they are still emotionally affected by the traumatic experience",
                        "d856d770-6f7c-57fd-a118-53ffb679faf4": "Patient skipped out of trauma section"
                    }
                }
            ]
        },
        "section": [
            "Current trauma related symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "T-35.4",
                    "optionSummaries": {
                        "c5118e92-1bdf-56c8-b12f-90e686eba051": "sometimes feeling upset when reminded of the event",
                        "acc7c276-c5bd-59e7-b41e-6a6d5f3e1fbc": "often feeling upset when reminded of the event",
                        "85152711-1a93-59c8-9cf4-3e2d320646d6": "always feeling upset when reminded of the event",
                        "a8870f77-99d6-5f47-ba29-0b0454a4f6ae": "Patient chose not to answer if they felt extremely upset when reminded of the traumatic experience",
                        "270448a7-ecf9-56f2-9a83-132ad71e9ed4": "Patient skipped out of trauma section"
                    }
                }
            ]
        },
        "section": [
            "Current trauma related symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "T-34.1",
                    "optionSummaries": {
                        "9083faa9-7932-5540-973c-87dff42ec1b5": "Traumatic event occurred in the last week",
                        "6c280560-c4a9-5455-b732-15e1b1c292b6": "Traumatic event occurred in the last month",
                        "572e18ea-b0f3-5d76-a94f-f1d8c085df82": "Traumatic event occurred several months ago",
                        "54067bec-c99e-5b1b-a364-f59f74d62208": "Traumatic event occurred one year ago or longer",
                        "a3cc76a7-4007-53c5-bc63-ad42b9f64463": "Patient chose not to answer regarding how long ago this traumatic experience took place",
                        "d4f5bbff-1a99-5091-b7cb-e485a73974f4": "Patient skipped out of trauma section"
                    }
                }
            ]
        },
        "section": [
            "Current trauma related symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "T-34.2",
                    "optionSummaries": {
                        "b0424e65-9188-5c13-996b-2e13fca215c6": "moderate emotional difficulty at the time of the event",
                        "5ba6670e-35ef-5545-8135-834feda201d5": "extreme emotional difficulty at the time of the event",
                        "5df58c8b-2b22-57fd-a9e1-f6e61c7ddb61": "extreme emotional difficulty at the time of the event",
                        "1b126b00-ec1b-5366-89a4-d0bacc703e89": "Patient chose not to answer how emotionally difficult this traumatic experience was",
                        "cd55bc55-7826-54d9-940c-8d59fb1638f1": "Patient skipped out of the trauma section"
                    }
                }
            ]
        },
        "section": [
            "Current trauma related symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "T-34.3",
                    "optionSummaries": {
                        "b7db11e3-885c-51fc-843f-610d8d00db10": "currently moderately emotionally affected",
                        "6c7691dc-1c1a-5410-98b7-48ffdd995bcd": "currently very emotionally affected",
                        "90158eab-7f98-553e-b73b-d954fda86727": "currently extremely emotionally affected",
                        "fc231225-070b-500f-b890-891ab25596fe": "Patient chose not to answer if they are still emotionally affected by the traumatic experience",
                        "1cb8da11-92e9-5426-90e7-445972499786": "Patient skipped out of trauma section"
                    }
                }
            ]
        },
        "section": [
            "Current trauma related symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "T-34.4",
                    "optionSummaries": {
                        "aa992ff2-b894-50ad-87b9-1863b8c261b3": "no repeated memories and dreams",
                        "bf6c3ef5-62fa-5172-9a8e-db0dae8fb96a": "occasional repeated memories and dreams",
                        "0bd84624-e550-553c-baff-f4ad880a2a4f": "frequent repeated memories and dreams",
                        "34874654-ecda-51ca-94ea-045a0b3c6837": "extremely frequent repeated memories and dreams",
                        "3125e96d-cc4a-5c45-a2fa-a3d908969214": "Patient chose to not answer if they are still experiencing unwanted memories of the traumatic experience",
                        "60db9fcd-8952-53a9-8db4-9a0377847211": "Patient skipped out of trauma section"
                    }
                }
            ]
        },
        "section": [
            "Current trauma related symptoms",
            "PTSD symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "T-34.5",
                    "optionSummaries": {
                        "40f243ca-75a7-582b-98d6-4cd8b0923958": "occasional flashbacks",
                        "9843f58c-c4c4-59f4-9b4f-a6507272dd62": "frequent flashbacks",
                        "8ef2ba4b-524a-5546-aa24-57e8221880f7": "extremely frequent flashbacks",
                        "220dc4d5-3390-5466-91bc-3ef271716e08": "Patient chose not to answer if they were still experiencing flashbacks of the traumatic experience",
                        "6c3e308f-ffec-59cc-8dec-2f10fb27061a": "Patient skipped out of trauma section"
                    }
                }
            ]
        },
        "section": [
            "Current trauma related symptoms",
            "PTSD symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "T-34.6",
                    "optionSummaries": {
                        "d4d2f085-f924-5ee5-a59d-b214823dab4d": "mild avoidance of triggers related to the event",
                        "10bf3060-43e8-52e2-9382-9427d00fd16a": "substantial avoidance of triggers related to the event",
                        "eff45049-db8f-5586-a84e-edfc649e51da": "severe avoidance of triggers related to the event",
                        "28b44107-292c-5c97-9dfc-5b3cf25c6d14": "Patient chose not to answer if they were avoiding situations that remind them of the experience",
                        "f437edad-132f-576f-8ca7-e4a0c66e0816": "Patient skipped out of trauma section"
                    }
                }
            ]
        },
        "section": [
            "Current trauma related symptoms",
            "PTSD symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "T-34.7",
                    "optionSummaries": {
                        "103cb9b5-785e-5301-afc4-4efa1f89d3f0": "not upset when reminded of the event",
                        "465fe7ff-e2a2-5390-a835-00807ffc1a0c": "sometimes upset when reminded of the event",
                        "c12290da-a5de-5f26-8164-110211b6bd4c": "often upset when reminded of the event",
                        "9c131dec-46af-5942-a8f1-697d8163fe24": "always upset when reminded of the event",
                        "07417cf6-b358-5b33-b247-78ef198c90a0": "Patient chose not to answer if they felt extremely upset when reminded of the traumatic experience",
                        "639306fb-e09d-555a-a2d7-9e325c4ae9e9": "Patient skipped out of trauma section"
                    }
                }
            ]
        },
        "section": [
            "Current trauma related symptoms",
            "PTSD symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "T-34.8",
                    "optionSummaries": {
                        "d97bfb6d-a890-5a97-b3fe-76734424468f": "mild arousal changes",
                        "6ecd2132-af6f-569f-9a93-8c8d5f5eb615": "substantial arousal changes",
                        "dd31cb8b-2e4a-542c-990e-2c589a8fb373": "severe arousal changes",
                        "4ad0ad43-f2d0-5f81-aa72-67dcb9c6b587": "Patient chose not to answer if they experience dizziness, stomach churning, sweating or a faster heart rate when reminded of the traumatic experience",
                        "06ea517e-15cd-50b0-be91-56266dc55c6d": "Patient skipped out of trauma section"
                    }
                }
            ]
        },
        "section": [
            "Current trauma related symptoms",
            "PTSD symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "T-34.9",
                    "optionSummaries": {
                        "1478fd0b-fd2f-5293-89b6-aa0cb1307a51": "mild irritability",
                        "3d567f1d-ec6a-53e2-90fd-c0a9ea495bb6": "substantial irritability",
                        "9f3eee57-d048-5a80-acb5-e43f43f60228": "severe irritability",
                        "e93574dd-3327-519b-96a8-e3ea6d3a55ac": "Patient chose not to answer if they find it harder to control their emotions since experiencing the traumatic experience",
                        "49210a61-58a9-5942-9a9b-2f674f2c707b": "Patient skipped out of trauma section"
                    }
                }
            ]
        },
        "section": [
            "Current trauma related symptoms",
            "PTSD symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "T-34.10",
                    "optionSummaries": {
                        "e3007f71-71f0-5447-9503-dd86a7329d1f": "mildly increased startle response",
                        "21593b50-f48c-5400-8caf-d3b67a445453": "substantially increased startle response",
                        "086bb9b7-2311-5049-9167-f36a1437927c": "severely increased startle response",
                        "ffde4ab6-1983-5272-b638-ccde7958e222": "Patient chose not to answer if they feel more jumpy since the traumatic experience",
                        "b92a0864-2a5e-55e4-a357-7561323780e4": "Patient skipped out of trauma section"
                    }
                }
            ]
        },
        "section": [
            "Current trauma related symptoms",
            "PTSD symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "T-34.11",
                    "optionSummaries": {
                        "e1649842-01ce-5bed-aae0-eda574ace3d2": "mildly increased hypervigilance",
                        "5f030af0-4a37-526d-81c4-0134760ebf84": "substantially increased hypervigilance",
                        "ff7ed352-5958-5063-ac3c-8f00d3dde1d1": "severely increased hypervigilance",
                        "7b3dac7e-6476-5729-bbf8-e00f963b29df": "Patient chose not to answer if they felt more aware to potential dangers since the traumatic experience",
                        "21b2e91e-a1ad-54cc-9672-429635c6b1bb": "Patient skipped out of trauma section"
                    }
                }
            ]
        },
        "section": [
            "Current trauma related symptoms",
            "PTSD symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "T-34.12",
                    "optionSummaries": {
                        "f8239bbb-1e37-5974-bca5-b6ba0daea1fe": "mild concentration problems",
                        "6ae9c2cf-9e52-5909-bd05-ac047a11aed0": "substantial concentration problems",
                        "d3daacee-e0e8-59d7-ac29-3f299611ee5d": "severe concentration problems",
                        "1711ad14-0101-530c-8ab0-20df80146988": "Patient chose not to answer if they have become less able to concentrate since the traumatic experience",
                        "68a6733d-effe-50d0-9c07-a953c5f6128a": "Patient skipped out of trauma section"
                    }
                }
            ]
        },
        "section": [
            "Current trauma related symptoms",
            "PTSD symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Overall level of impairment: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "T-34.13a",
                    "optionSummaries": {
                        "dea28bb5-7e53-5032-a4db-a76aca90041a": "none",
                        "80949ead-3bd4-5888-878f-224cddbebd60": "mild",
                        "04711b6d-71e7-58ea-a595-a9092bd42bde": "moderate",
                        "2061ca08-518e-5daa-b35e-03819ac07b88": "severe",
                        "b07e2ee9-a63c-5902-9683-25e3eb727231": "Patient chose not to answer if they are finding it more difficult to live their life due to the traumatic experience",
                        "3c34b3bd-23eb-51fd-9980-cc71c43126cf": "Patient skipped out of the trauma section"
                    }
                }
            ]
        },
        "section": [
            "Current trauma related symptoms",
            "Functional impairment"
        ]
    },
    {
        "answerSummary": {
            "type": "MultiOptionSummary",
            "questionId": "T-34.13",
            "title": "Areas of impairment",
            "overrides": {
                "70ab1917-9ef6-5273-a79a-38f34a97fd4d": "Other (not listed)",
                "b15c726a-f089-59a6-b4af-ce66c0698fbc": "Patient skipped out of the trauma section",
                "9e2be8bf-318c-46ac-b804-0c36a1c80f22": "None reported"
            }
        },
        "section": [
            "Current trauma related symptoms",
            "Functional impairment"
        ]
    }
];
