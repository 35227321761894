"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.symptoms = void 0;
exports.symptoms = [
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} obsessions in the last 6 months",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCD-s1.5",
                    "optionSummaries": {
                        "6581df20-1f12-5563-9671-fd51c07fd098": "Has not experienced",
                        "934f0a07-8342-52aa-9c61-ec87c8d7b6b9": "Experienced"
                    }
                }
            ]
        },
        "section": [
            "Recent experience of obsessions and compulsions"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} compulsions in the last 6 months",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCD-s2.5",
                    "optionSummaries": {
                        "786ef202-eae2-58ab-bec2-7bbe1746fd5c": "Has not experienced",
                        "f543076e-83c3-56df-9539-f976a57d2fac": "Experienced"
                    }
                }
            ]
        },
        "section": [
            "Recent experience of obsessions and compulsions"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "OCD-c20a"
            ],
            "prefix": "Compulsions experienced for ",
            "formatText": "{}"
        },
        "section": [
            "Duration of symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "OCD-o20a"
            ],
            "prefix": "Obsessions experienced for ",
            "formatText": "{}"
        },
        "section": [
            "Duration of symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "OCD-20a"
            ],
            "prefix": "Obsessions and compulsions experienced for ",
            "formatText": "{}"
        },
        "section": [
            "Duration of symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Daily time spent on obsessions and compulsions: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCD-19a",
                    "optionSummaries": {
                        "5f1c8429-44a8-5ffe-b25a-83342c0377d2": "less than 1 hour per day",
                        "cc0e9f4f-d2cf-5611-8b94-f2de2ba87c8d": "1–3 hours per day",
                        "f177854d-7188-5142-8325-b27ef8aac03f": "more than 3 hours per day",
                        "e3809079-bc8b-52c6-b811-7f6f2437e4a9": "more than 8 hours per day",
                        "8b96bfb1-38bb-56f7-a9cb-693bd9bed98a": "more than 12 hours per day"
                    }
                }
            ]
        },
        "section": [
            "Duration of symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Daily time spent on obsessions: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCD-o19a",
                    "optionSummaries": {
                        "f58cb8d9-832c-58f1-ad61-a38722e1ac30": "less than 1 hour per day",
                        "4fec4813-10b7-5b75-a92a-c3ef0bd0829f": "1–3 hours per day",
                        "a8cdbe2a-7125-51f9-a704-7cc6b5e94a7b": "more than 3 hours per day",
                        "aa57f892-e9e7-52b0-a516-b7541d79ad82": "more than 8 hours per day",
                        "d1baedd2-5bdb-5255-aa0a-5fb0bdbe86d1": "more than 12 hours per day"
                    }
                }
            ]
        },
        "section": [
            "Duration of symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Daily time spent on compulsions: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCD-c19a",
                    "optionSummaries": {
                        "85b5a64f-a893-5a4d-98c5-e7ab4f819b8b": "less than 1 hour per day",
                        "b8bc16e5-c342-5285-8986-82ddbf511a09": "1–3 hours per day",
                        "dfdfabbf-250c-57bc-99c2-017778956597": "more than 3 hours per day",
                        "17b4fbea-ef73-56af-ae4d-765c41ceedd1": "more than 8 hours per day",
                        "f239adce-d665-5b87-ac6f-23b1fe1d840c": "more than 12 hours per day"
                    }
                }
            ]
        },
        "section": [
            "Duration of symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "MultiOptionSummary",
            "questionId": "OCD-3a",
            "title": "",
            "overrides": {
                "654f0850-433c-5068-81b1-cbc8da0115d4": "other (not listed)"
            }
        },
        "section": [
            "Current episode",
            "Type of obsession"
        ]
    },
    {
        "answerSummary": {
            "type": "MultiOptionSummary",
            "questionId": "OCD-4a",
            "title": "",
            "overrides": {
                "3e8b1149-e933-56f0-986f-88ae3cc6eee4": "other (not listed)"
            }
        },
        "section": [
            "Current episode",
            "Type of compulsion"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} emotional distress if prevented from carrying out the compulsion",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCD-15a",
                    "optionSummaries": {
                        "84d8ca85-4007-5557-aa20-b6f3b32a5819": "mild",
                        "d1f530a9-4ad1-5d89-8d08-2d819227639b": "moderate",
                        "3ed49476-9c02-5a93-b8fc-3921a14daaf3": "significant",
                        "1272197a-b659-540a-80ee-1a5ec9b59d3c": "extreme"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Emotional symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} emotional distress due to the obsessions",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCD-7a",
                    "optionSummaries": {
                        "95603b88-c05c-5344-ab26-5d338cd499ff": "mild",
                        "76162037-755b-57b1-93d4-4f9ab77522dc": "moderate",
                        "42716cc5-7285-5b4e-9d65-4be48e7606b1": "significant",
                        "d1a809d8-8068-5778-a74f-a8f209fff96e": "extreme"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Emotional symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} attempts to ignore or suppress obsessions",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCD-5a",
                    "optionSummaries": {
                        "2435ec34-7006-5e0a-b25b-021f0ff5e79e": "some",
                        "fc1867dc-1ad7-5599-848b-dea1a7dd7584": "moderate",
                        "dffa8a56-9791-59eb-8f9c-3e7badb3a26e": "significant",
                        "15202dc0-e463-59a3-886a-81521814b4a3": "extreme"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Other symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} belief that compulsions 'even out' or 'cancel out' obsessions",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCD-6a",
                    "optionSummaries": {
                        "aee9c4fc-09a8-5417-bcf6-72489e6c2295": "some",
                        "d8b5b0e1-310e-5a1e-be90-df3ab36ada8e": "moderately strong",
                        "a32a57d8-8aa9-5e76-a8cc-4102a757ca38": "very strong",
                        "52b17754-786a-58c8-b625-bf1153ae0647": "extreme"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Other symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} avoids situations that can trigger obsessions and compulsions",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCD-16a",
                    "optionSummaries": {
                        "0cfe50db-bb47-5366-bb5a-f34ce230f42c": "sometimes",
                        "d02a6bf0-adb3-55fb-a4e6-06e451ceb402": "very often",
                        "587d85a1-edaa-5939-b424-3d49529d9fd5": "always"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Avoidance of situations that can trigger obsessions/compulsions"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} avoids situations that can trigger obsessions",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCD-o16a",
                    "optionSummaries": {
                        "f52473cd-db0d-5582-9623-4cdd13d4716e": "sometimes",
                        "9614f14b-2e9a-5351-9bf9-29aa1d436e0f": "very often",
                        "9dff1c6f-8e4c-5704-b7df-e8cc3e672d72": "always"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Avoidance of situations that can trigger obsessions/compulsions"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} avoids situations that can trigger compulsions",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCD-c16a",
                    "optionSummaries": {
                        "b9a761bd-2631-5587-beec-4d0edcc823b9": "sometimes",
                        "91fa7bdb-7715-5cfc-8b8b-fcfdef582591": "very often",
                        "baefcf54-02e4-5c0f-af0b-80af2083b847": "always"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Avoidance of situations that can trigger obsessions/compulsions"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} in the truth of the obsessions",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCD-8a",
                    "optionSummaries": {
                        "f78932aa-7c48-5e79-895a-a3dcbc1659ea": "no belief",
                        "257e5561-0975-5e0b-85d0-618d9799a7e9": "some belief",
                        "32d87341-36a2-5c2b-a385-5ac2114fe805": "moderate belief",
                        "4fcc66a3-e0bd-5b6c-bb12-277918c042ee": "very strong belief",
                        "a5935093-4c49-5c9c-acd1-1ace1c4f5d23": "uncertain belief"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Personal insight"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} in the rationality of the compulsions",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCD-13a",
                    "optionSummaries": {
                        "73abd2a9-18bf-5cab-aaa9-7433806d7c36": "no belief",
                        "18f0fe22-b536-5176-a1a6-b0deb375b436": "moderate belief",
                        "06e76867-91d9-521e-9102-62a647414f8c": "very strong belief",
                        "c9936a18-a4eb-59f5-bc80-479d96aac203": "uncertain belief"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Personal insight"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCD-14a",
                    "optionSummaries": {
                        "54e837d8-5c1b-5a1b-8b6a-03cc3c37bb60": "others would find the compulsions necessary",
                        "b04c74ed-9e1d-5829-80a8-bde749828475": "others would find the compulsions unnecessary, but not excessive",
                        "20710ec3-44fe-50bb-966c-e7e197979d04": "others would find the compulsions unnecessary and excessive",
                        "30be1b0f-a1a9-5da9-8ca2-293a63a6d4fe": "unsure if others would think the compulsions are unncessary or excessive"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Personal insight"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Overall level of impairment: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCD-21.0a",
                    "optionSummaries": {
                        "68c4c071-8e58-5ec7-a403-ce8a9c115727": "none",
                        "3f5d0c33-fc5f-55f0-850c-3d48c2572c35": "mild",
                        "9e90d287-ca8e-5942-a6f7-80fc50e48179": "moderate",
                        "345c0bc7-484c-511d-9071-9b43afc5a7f3": "significant",
                        "d3da923e-63ce-57df-b770-213e64c2b98f": "severe"
                    }
                },
                {
                    "questionId": "OCD-o21.0a",
                    "optionSummaries": {
                        "afbe8a05-7261-5d24-a19f-35d6b9f3ff9e": "none",
                        "750f7056-9da3-5ccd-9e7c-25b8c7d9569e": "mild",
                        "7a7e149a-c672-5237-9199-8769482ee74a": "moderate",
                        "e0651ad9-2972-53fc-984f-c55d8aa950c0": "significant",
                        "1fb95c5e-97e0-5aae-8744-a1da4aab42e4": "severe"
                    }
                },
                {
                    "questionId": "OCD-c21.0a",
                    "optionSummaries": {
                        "04ec36f5-6c17-524d-84c5-964056142f3c": "none",
                        "9fa6cec1-d9d3-567d-9c01-727603b77847": "mild",
                        "a6302a85-ec37-51a4-989e-05e001c75b1f": "moderate",
                        "9fe10eb3-a49b-5f56-a4e3-e70fadd249a9": "significant",
                        "440a730f-02f2-55d0-bcdd-e1977b2f5b0c": "severe"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Functional impairment"
        ]
    },
    {
        "answerSummary": {
            "type": "MultiOptionSummary",
            "questionId": "OCD-21a",
            "title": "Areas of impairment",
            "overrides": {
                "28155bbf-d385-5709-ac3e-39b6a2493683": "Other (not listed)",
                "430451ee-6442-43d1-9516-4ce0722d75d7": "None reported"
            }
        },
        "section": [
            "Current episode",
            "Functional impairment"
        ]
    },
    {
        "answerSummary": {
            "type": "MultiOptionSummary",
            "questionId": "OCD-o21a",
            "title": "Areas of impairment",
            "overrides": {
                "3683169a-d99a-5d7e-b1f5-54c44e3af918": "Other (not listed)",
                "4b614374-c0f6-49e8-8e6e-2f7ea5364497": "None reported"
            }
        },
        "section": [
            "Current episode",
            "Functional impairment"
        ]
    },
    {
        "answerSummary": {
            "type": "MultiOptionSummary",
            "questionId": "OCD-c21a",
            "title": "Areas of impairment",
            "overrides": {
                "4f3419ed-c6f4-5024-83e0-3149f6c96807": "Other (not listed)",
                "a29a9a5c-e0c7-46e1-b48c-171d55dca1f1": "None reported"
            }
        },
        "section": [
            "Current episode",
            "Functional impairment"
        ]
    }
];
