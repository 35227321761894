"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filterValidAnswers = void 0;
const questions_1 = require("./questions");
const filterValidAnswers = (answers, questions, aspects, questionPredicates, userId) => {
    const filteredOutAnswers = [];
    const filteredAnswers = answers.filter((answer) => {
        const isInvalidated = answer.invalidated === true;
        const question = questions.find((q) => q.id === answer.questionId);
        const isAnswerRequired = question && (0, questions_1.isRequired)(aspects, question, questionPredicates, answers);
        if (isInvalidated || !isAnswerRequired) {
            filteredOutAnswers.push(answer);
            return false;
        }
        return true;
    });
    if (userId) {
        const message = filteredOutAnswers.length > 0
            ? `Filtered out answers for user ${userId}: ${JSON.stringify(filteredOutAnswers)}`
            : `No answers were filtered out for user ${userId}`;
        console[filteredOutAnswers.length > 0 ? 'warn' : 'info'](message);
    }
    return filteredAnswers;
};
exports.filterValidAnswers = filterValidAnswers;
