import React, { useContext, useEffect } from 'react'
import { Heading } from '@psyomics/components'
import Content from 'registration/components/Content'
import { Widget } from '@typeform/embed-react'
import { Page } from '@psyomics/components'
import { useMixpanelEvent } from 'ui/app/useMixpanelEvent'
import { MixpanelContext } from 'registration/context/mixpanel/mixpanel-context'

const SmsFeedback: React.FC = () => {
    const mixpanelApiUrl = useContext(MixpanelContext)
    const { visitedSmsFeedbackLink } = useMixpanelEvent(mixpanelApiUrl)
    useEffect(() => {
        visitedSmsFeedbackLink()
    }, [])

    return (
        <Page style={{ backgroundColor: 'transparent' }} layout="standard" width="wide">
            <Content>
                <Heading el="h1" size="heading2">
                    Feedback
                </Heading>
            </Content>
            <Widget height={500} id="qljcuZet"></Widget>
        </Page>
    )
}

export default SmsFeedback
