import React, { FunctionComponent, useMemo } from 'react'
import Styles from './PopulationHealthStats.module.scss'

function hashString(str: string): number {
    let hash = 0,
        i,
        chr
    if (str.length === 0) return hash
    for (i = 0; i < str.length; i++) {
        chr = str.charCodeAt(i)
        hash = (hash << 5) - hash + chr
        hash |= 0
    }
    return hash
}

function seededRandom(seed: number): () => number {
    return function () {
        seed = Math.sin(seed) * 10000
        return seed - Math.floor(seed)
    }
}

interface ConditionsLikelihoodProps {
    likelihoods: { label: string; color: string }[]
    conditions: {
        name: string
        segments: { color: string; width: string }[]
    }[]
}

const ConditionsLikelihood: FunctionComponent<ConditionsLikelihoodProps> = ({ likelihoods, conditions }) => {
    return (
        <div className={`${Styles.card} ${Styles.conditionsLikelihoodCard}`}>
            <div className={Styles.conditionsLikelihoodParent}>
                <div className={Styles.titleContainer}>
                    <b className={Styles.title}>Conditions Likelihood</b>
                </div>
                <div className={Styles.legend}>
                    {likelihoods.map((item, index) => (
                        <div key={index} className={Styles.legendItem}>
                            <div className={Styles.colorBox} style={{ backgroundColor: item.color }}></div>
                            <div>{item.label}</div>
                        </div>
                    ))}
                </div>
            </div>
            <div className={Styles.conditionsList}>
                {conditions.map((condition, index) => (
                    <div key={index} className={Styles.conditionItem}>
                        <div className={Styles.barContainer}>
                            {condition.segments.map((segment, idx) => (
                                <div
                                    key={idx}
                                    className={Styles.barSegment}
                                    style={{
                                        backgroundColor: segment.color,
                                        width: segment.width,
                                    }}
                                ></div>
                            ))}
                        </div>
                        <div className={Styles.conditionName}>{condition.name}</div>
                    </div>
                ))}
            </div>
        </div>
    )
}

interface TriagePriorityProps {
    priorities: { label: string; color: string; value: number }[]
}

const TriagePriority: FunctionComponent<TriagePriorityProps> = ({ priorities }) => {
    return (
        <div className={`${Styles.card} ${Styles.triagePriorityCard}`}>
            <div className={Styles.titleContainer}>
                <b className={Styles.title}>Triage Priority</b>
            </div>
            <div className={Styles.priorityContainer}>
                {priorities.map((priority, index) => (
                    <div key={index} className={Styles.priorityItem}>
                        <div className={Styles.barWrapper}>
                            <div
                                className={Styles.priorityBar}
                                style={{ backgroundColor: priority.color, height: `${priority.value}%` }}
                            ></div>
                        </div>
                        <div className={Styles.priorityLabel}>{priority.label}</div>
                    </div>
                ))}
            </div>
        </div>
    )
}

interface AgeRangeProps {
    ageRanges: { label: string; color: string; value: number }[]
}

const AgeRange: FunctionComponent<AgeRangeProps> = ({ ageRanges }) => {
    return (
        <div className={`${Styles.card} ${Styles.ageRangeCard}`}>
            <div className={Styles.titleContainer}>
                <b className={Styles.title}>Age Range</b>
            </div>
            <div className={Styles.priorityContainer}>
                {ageRanges.map((range, index) => (
                    <div key={index} className={Styles.priorityItem}>
                        <div className={Styles.barWrapper}>
                            <div
                                className={Styles.priorityBar}
                                style={{ backgroundColor: range.color, height: `${range.value}%` }}
                            ></div>
                        </div>
                        <div className={Styles.priorityLabel}>{range.label}</div>
                    </div>
                ))}
            </div>
        </div>
    )
}

interface CommonConditionsProps {
    conditions: { name: string; percentage: number }[]
}

const CommonConditions: FunctionComponent<CommonConditionsProps> = ({ conditions }) => {
    const sortedConditions = [...conditions].sort((a, b) => b.percentage - a.percentage)

    return (
        <div className={`${Styles.card} ${Styles.commonConditionsCard}`}>
            <div className={Styles.titleContainer}>
                <b className={Styles.title}>Common Conditions</b>
            </div>
            <div className={Styles.commonConditionsList}>
                {sortedConditions.map((condition, index) => (
                    <div key={index} className={Styles.commonConditionItem}>
                        <div className={Styles.conditionBarContainer}>
                            <div
                                className={Styles.conditionBar}
                                style={{
                                    width: `${condition.percentage}%`,
                                    backgroundColor: '#00d0a4',
                                }}
                            />
                            <div className={Styles.conditionPercentage}>{condition.percentage}%</div>
                        </div>
                        <div className={Styles.conditionName}>{condition.name}</div>
                    </div>
                ))}
            </div>
        </div>
    )
}

interface PopulationHealthStatsProps {
    fromDate: string
    toDate: string
}

const PopulationHealthStats: FunctionComponent<PopulationHealthStatsProps> = ({ fromDate, toDate }) => {
    const data = useMemo(() => {
        const seed = hashString(fromDate + toDate)
        const random = seededRandom(seed)

        const likelihoods = [
            { label: 'Highly likely', color: '#00d0a4' },
            { label: 'Probable', color: '#7fe7d1' },
            { label: 'Possible', color: '#bff3e8' },
            { label: 'Unlikely', color: '#f8f5ef' },
        ]

        const conditionNames = [
            'Adjustment disorder',
            'Major depressive disorder',
            'Generalised anxiety disorder',
            'Social anxiety disorder',
            'Panic disorder',
            'Obsessive compulsive disorder',
            'Insomnia',
            'Emotional Instability',
            'Trauma',
            'Disordered eating',
            'Bipolar 1',
            'Bipolar 2',
            'Psychotic symptoms',
            'Alcohol use',
            'Substance abuse',
        ]

        function generateSegments(): { color: string; width: string }[] {
            const colors = ['#f8f5ef', '#bff3e8', '#7fe7d1', '#00d0a4']
            const widths: number[] = []
            let previousWidth = 100
            for (let i = 0; i < colors.length; i++) {
                widths[i] = previousWidth
                if (i < colors.length - 1) {
                    previousWidth = previousWidth * (0.5 + random() * 0.5)
                }
            }
            return colors.map((color, index) => ({
                color,
                width: widths[index] + '%',
            }))
        }

        const conditions = conditionNames.map((name) => ({
            name,
            segments: generateSegments(),
        }))

        const priorityLabels = [
            { label: 'Low', color: '#34c759' },
            { label: 'Moderate', color: '#ffcc00' },
            { label: 'High', color: '#ff9500' },
            { label: 'Very\nHigh', color: '#ff3b30' },
        ]

        // Modified to ensure Low is highest and Very High is lowest
        const baseValues = [
            80 + random() * 15, // Low: 80-95%
            60 + random() * 15, // Moderate: 60-75%
            40 + random() * 15, // High: 40-55%
            20 + random() * 15, // Very High: 20-35%
        ]

        const priorities = priorityLabels.map((priority, index) => ({
            label: priority.label,
            color: priority.color,
            value: baseValues[index],
        }))

        const ageRangeLabels = ['18-30', '31-40', '41-50', '51-65']
        const ageRanges = ageRangeLabels.map((label) => ({
            label,
            color: '#00d0a4',
            value: Math.round(10 + random() * 90),
        }))

        const commonConditionsNames = [
            'Adjustment disorder',
            'Major depressive disorder',
            'Generalised anxiety disorder',
            'Social anxiety disorder',
            'Trauma',
            'Substance abuse',
        ]

        const commonConditions = commonConditionsNames.map((name) => ({
            name,
            percentage: Math.round(50 + random() * 50),
        }))

        return {
            conditionsLikelihoodData: {
                likelihoods,
                conditions,
            },
            triagePriorityData: {
                priorities,
            },
            ageRangeData: {
                ageRanges,
            },
            commonConditionsData: {
                conditions: commonConditions,
            },
        }
    }, [fromDate, toDate])

    return (
        <div className={Styles.gridContainer}>
            <div className={Styles.leftColumn}>
                <ConditionsLikelihood {...data.conditionsLikelihoodData} />
            </div>
            <div className={Styles.rightColumn}>
                <div className={Styles.topRightCards}>
                    <TriagePriority {...data.triagePriorityData} />
                    <AgeRange {...data.ageRangeData} />
                </div>
                <CommonConditions {...data.commonConditionsData} />
            </div>
        </div>
    )
}

export default PopulationHealthStats
