import React from 'react'
import { Page, Text, Heading } from '@psyomics/components'
import Header from 'registration/components/Header'
import Styles from './SignOut.module.scss'

export const SignOut: React.FC = () => {
    return (
        <Page>
            <Header template="landing" />
            <div className={Styles.container}>
                <Heading el="h2" size="heading2" color="mid" role="heading">
                    Signed Out
                </Heading>
                <Text css={{ mt: 2 }}>Thank you, you have now successfully signed out of your censeo account.</Text>
                <Text css={{ mt: 2 }}>
                    If you logged in through a Single Sign On provider you may also need to log out of that aswell.
                </Text>
            </div>
        </Page>
    )
}
