import * as Yup from 'yup'
import { FormikProps } from 'formik'

export interface ResetPasswordFormInputs {
    email: string
    code: string
    password: string
    confirmPassword: string
}

export interface ResetPasswordFormProps {
    formikProps: FormikProps<ResetPasswordFormInputs>
    setPageState: any
}

export const ResetPasswordFormInputsInitialValues: ResetPasswordFormInputs = {
    email: '',
    code: '',
    password: '',
    confirmPassword: '',
}

export const ResetPasswordFormSchema = Yup.object().shape({
    email: Yup.string()
        .email("That doesn't look like a valid email address. Please try again.")
        .required('You must supply an email address'),
    code: Yup.string()
        .required('A verification code is required to reset your password')
        .min(6, 'Your verification code should be 6 digits')
        .max(6, 'Your verification code should be 6 digits'),
    password: Yup.string()
        .required('Please enter a new password')
        .min(8, 'Your password must be at least 8 characters'),
    confirmPassword: Yup.string().test(
        'passwords-match',
        'The passwords you have entered don’t match',
        function (value) {
            return this.parent.password === value
        }
    ),
})
