import React from 'react'
import Styles from './LogoSpinner.module.scss'

const LogoSpinner: React.FC = () => {
    return (
        <svg width="160px" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="-256.00 -256.00 1024.00 1024.00">
            <circle className={Styles.animatedCircle} fill="#00d0a4" cx="68.77" cy="68.79" r="44.78" />
            <path
                fill="#00d0a4"
                d="
M 254.91 237.85
L 137.79 120.64
Q 137.40 120.25 137.78 119.84
Q 138.86 118.71 140.67 117.16
C 224.04 46.09 347.77 50.04 425.70 127.90
Q 426.08 128.29 425.70 128.68
L 309.04 245.33
Q 308.79 245.59 308.52 245.35
Q 284.86 224.41 255.63 237.98
Q 255.23 238.16 254.91 237.85
Z"
            />
            <path
                fill="#00d0a4"
                d="
M 254.84 317.04
Q 255.31 316.57 255.91 316.85
Q 285.11 330.47 308.88 309.55
Q 309.17 309.30 309.45 309.57
L 425.95 426.08
A 0.35 0.35 0.0 0 1 425.95 426.58
C 384.58 467.09 330.44 489.43 272.26 487.97
C 160.05 485.14 70.58 395.64 66.58 283.93
C 64.65 229.83 84.42 177.13 120.19 136.80
A 0.35 0.35 0.0 0 1 120.70 136.79
L 238.27 254.96
Q 238.62 255.32 238.40 255.78
Q 227.48 277.53 238.32 299.35
Q 238.55 299.82 238.18 300.19
L 210.71 327.67
Q 210.43 327.96 210.07 327.76
C 183.61 313.07 151.78 327.46 144.47 356.50
Q 142.28 365.21 144.06 374.47
C 151.27 412.03 199.00 424.80 223.09 394.34
Q 240.15 372.75 227.51 345.08
Q 227.28 344.59 227.67 344.20
L 254.84 317.04
Z"
            />
        </svg>
    )
}

export default LogoSpinner
