import { Auth } from 'aws-amplify'

export type ScanResult = {
    tags: Record<string, string>
    metadata: Record<string, string>
    content: string
}

export const pollAvScanResult = async (
    filename: string,
    checkStatusCallback?: (result: ScanResult) => boolean,
    avScanApiUrl?: string,
    maxRetries = 24,
    pollingInterval = 5000
): Promise<ScanResult> => {
    let retries = 0

    return new Promise((resolve, reject) => {
        const scanInterval = setInterval(async () => {
            if (retries >= maxRetries) {
                clearInterval(scanInterval)
                return reject(new Error('Scan result timed out.'))
            }

            try {
                const jwt = (await Auth.currentSession()).getAccessToken().getJwtToken()
                const response = await fetch(`${avScanApiUrl}/scan-result?fileName=${filename}`, {
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    },
                })

                const result = (await response.json()) as unknown as ScanResult
                if (response.status === 404) {
                    // First time upload may take some time
                    retries++
                    return
                }

                if (response.ok && (!checkStatusCallback || checkStatusCallback(result))) {
                    clearInterval(scanInterval)
                    resolve(result)
                } else if (!response.ok) {
                    throw new Error('Error checking scan result.')
                }
            } catch (err) {
                clearInterval(scanInterval)
                reject(err instanceof Error ? err : new Error(String(err)))
            }

            retries++
        }, pollingInterval)
    })
}
