import { UserType, ReferralCode, Referrals, calculateAge, User } from 'censeo-core'
import { setPeople as mixpanelSetPeople, track as mixpanelTrack } from './side-effects/mixpanel-proxy'
import { sessionId } from './reporting'
import { Timers, enableTimer, getTimer } from 'ui/utils/timers'

export interface IMixpanelEvents {
    visitedCenseo: (
        orgCode: string | undefined,
        orgName: string | undefined,
        userType: UserType,
        referralCode?: ReferralCode,
        referralPublicId?: string
    ) => Promise<void>
    visitedSmsFeedbackLink: () => Promise<void>
    startedRegistration: (
        orgCode: string | undefined,
        orgName: string | undefined,
        userType: UserType,
        referralCode?: ReferralCode,
        referralPublicId?: string
    ) => Promise<void>
    termsAccepted: (
        orgCode: string | undefined,
        orgName: string | undefined,
        referralPublicId?: string
    ) => Promise<void>
    dateOfBirthEntered: (
        orgCode: string | undefined,
        orgName: string | undefined,
        birthdate: string,
        referralPublicId?: string
    ) => Promise<void>
    postcodeEntered: (
        orgCode: string | undefined,
        orgName: string | undefined,
        referralPublicId?: string
    ) => Promise<void>
    nameEntered: (orgCode: string | undefined, orgName: string | undefined, referralPublicId?: string) => Promise<void>
    verificationCodeRequested: (
        orgCode: string | undefined,
        orgName: string | undefined,
        referralPublicId?: string
    ) => Promise<void>
    accountCreated: (
        orgCode: string | undefined,
        orgName: string | undefined,
        referralPublicId?: string
    ) => Promise<void>
    genderEntered: (
        orgCode: string | undefined,
        orgName: string | undefined,
        gender: string,
        referralPublicId?: string
    ) => Promise<void>
    sexEntered: (
        orgCode: string | undefined,
        orgName: string | undefined,
        sex: string,
        referralPublicId?: string
    ) => Promise<void>
    ethnicGroupEntered: (
        orgCode: string | undefined,
        orgName: string | undefined,
        ethnicity: string,
        referralPublicId?: string
    ) => Promise<void>
    ethnicGroupBackgroundEntered: (
        orgCode: string | undefined,
        orgName: string | undefined,
        background: string,
        referralPublicId?: string
    ) => Promise<void>
    signInClicked: (orgCode: string | undefined, orgName: string | undefined) => Promise<void>
    loggedIn: (orgCode: string | undefined, orgName: string | undefined) => Promise<void>
    loggedOut: (orgCode: string | undefined, orgName: string | undefined, referralPublicId?: string) => Promise<void>
    authenticated: (
        orgCode: string | undefined,
        orgName: string | undefined,
        user: User,
        referralPublicId?: string
    ) => void

    // assessment events:
    assessmentStarted: (orgCode: string | undefined, orgName: string | undefined, referralPublicId?: string) => void
    sessionStarted: (
        orgCode: string | undefined,
        orgName: string | undefined,
        sessionName: string,
        referralPublicId?: string
    ) => Promise<void> // session id?
    screenedIn: (
        orgCode: string | undefined,
        orgName: string | undefined,
        questionId: string,
        conditionName?: string,
        referralPublicId?: string
    ) => Promise<void>
    questionAsked: (
        orgCode: string | undefined,
        orgName: string | undefined,
        questionId: string,
        referralPublicId?: string
    ) => Promise<void>
    questionAnswered: (
        orgCode: string | undefined,
        orgName: string | undefined,
        questionId: string,
        questionType?: string,
        conditionName?: string,
        timeSpent?: string,
        referralPublicId?: string
    ) => Promise<void>
    answerRemoved: (
        orgCode: string | undefined,
        orgName: string | undefined,
        questionId: string,
        referralPublicId?: string
    ) => Promise<void>
    sessionCompleted: (
        orgCode: string | undefined,
        orgName: string | undefined,
        sessionName: string,
        referralPublicId?: string
    ) => void // session id?
    assessmentCompleted: (orgCode: string | undefined, orgName: string | undefined, referralPublicId?: string) => void
    usedDemoAccessCode: (code: string) => Promise<void>
    visitedDemoHomepage: (orgCode: string | undefined, orgName: string | undefined) => Promise<void>
    visitedDemoHealthCareExperiences: (orgCode: string | undefined, orgName: string | undefined) => Promise<void>
    visitedDemoPatientIntro: (orgCode: string | undefined, orgName: string | undefined) => Promise<void>
    visitedDemoExperience: (orgCode: string | undefined, orgName: string | undefined) => Promise<void>
    visitedDemoIntro: (orgCode: string | undefined, orgName: string | undefined) => Promise<void>
    visitedDemoStats: (orgCode: string | undefined, orgName: string | undefined) => Promise<void>
    visitedDemoPatientMgt: (orgCode: string | undefined, orgName: string | undefined) => Promise<void>
    visitedDemoInsights: (orgCode: string | undefined, orgName: string | undefined) => Promise<void>
    visitedDemoStartPatientJourney: (orgCode: string | undefined, orgName: string | undefined) => Promise<void>
}

const preSignUpUserId = sessionId
let userId: string | undefined = undefined

// prettier-ignore
export const useMixpanelEvent: (mixpanelApiUrl: string) => IMixpanelEvents = (mixpanelApiUrl: string) => {
  return {
      // registration events
      visitedCenseo: (
          orgCode: string | undefined,
          orgName: string | undefined,
          userType: UserType,
          referralCode?: ReferralCode,
          referralPublicId?: string,
      ) =>
          trackEvent(mixpanelApiUrl, 'Visited Censeo', {
              super: {
                  'Organisation code': orgCode,
                  'Organisation name': orgName,
                  'User type': userType,
                  ...getReferralPropsFromCode(referralCode),
              },
              event: {
                'Referral public id': referralPublicId
              }
          }),
      visitedSmsFeedbackLink: (referralPublicId?: string) => trackEvent(mixpanelApiUrl, 'Visited SMS feedback link', { event: {
        'Referral public id': referralPublicId,
      }}),
      startedRegistration: (
          orgCode: string | undefined,
          orgName: string | undefined,
          userType: UserType,
          referralCode?: ReferralCode,
          referralPublicId?: string,
      ) =>
          trackEvent(mixpanelApiUrl, 'Started registration', {
              super: {
                  'Organisation code': orgCode,
                  'Organisation name': orgName,
                  'User type': userType,
                  ...getReferralPropsFromCode(referralCode),
              },
              event: {
                'Referral public id': referralPublicId,
              }
          }),
      termsAccepted: (orgCode: string | undefined, orgName: string | undefined, referralPublicId?: string) =>
          trackEvent(mixpanelApiUrl, 'Terms accepted', {
              super: { 'Organisation code': orgCode, 'Organisation name': orgName },
              event: {
                'Referral public id': referralPublicId,
              }
          }),
      dateOfBirthEntered: (orgCode: string | undefined, orgName: string | undefined, birthdate: string, referralPublicId?: string) =>
          trackEvent(mixpanelApiUrl, 'Date of birth entered', {
              super: {
                  'Organisation code': orgCode,
                  'Organisation name': orgName,
                  Age: calculateAge(Date.parse(birthdate)),
              },
              event: {
                'Referral public id': referralPublicId,
              }
          }),
      postcodeEntered: (orgCode: string | undefined, orgName: string | undefined, referralPublicId?: string) =>
          trackEvent(mixpanelApiUrl, 'Postcode entered', {
              super: { 'Organisation code': orgCode, 'Organisation name': orgName },
              event: {
                'Referral public id': referralPublicId,
              }
          }),
      nameEntered: (orgCode: string | undefined, orgName: string | undefined, referralPublicId?: string) =>
          trackEvent(mixpanelApiUrl, 'Name entered', {
              super: { 'Organisation code': orgCode, 'Organisation name': orgName },
              event: {
                'Referral public id': referralPublicId,
              }
          }),
      verificationCodeRequested: (orgCode: string | undefined, orgName: string | undefined, referralPublicId?: string) =>
          trackEvent(mixpanelApiUrl, 'Verification code requested', {
              super: { 'Organisation code': orgCode, 'Organisation name': orgName },
              event: {
                'Referral public id': referralPublicId,
              }
          }),
      accountCreated: (orgCode: string | undefined, orgName: string | undefined, referralPublicId?: string) =>
          trackEvent(mixpanelApiUrl, 'Account created', {
              super: { 'Organisation code': orgCode, 'Organisation name': orgName },
              event: {
                'Referral public id': referralPublicId,
              }
          }),

      // onboarding events
      genderEntered: (orgCode: string | undefined, orgName: string | undefined, gender: string, referralPublicId?: string) =>
          trackEvent(mixpanelApiUrl, 'Gender entered', {
              super: { 'Organisation code': orgCode, 'Organisation name': orgName, Gender: gender },
              event: {
                'Referral public id': referralPublicId,
              }
          }),
      sexEntered: (orgCode: string | undefined, orgName: string | undefined, sex: string, referralPublicId?: string) =>
          trackEvent(mixpanelApiUrl, 'Sex entered', {
              super: { 'Organisation code': orgCode, 'Organisation name': orgName, Sex: sex },
              event: {
                'Referral public id': referralPublicId,
              }
          }),
      ethnicGroupEntered: (orgCode: string | undefined, orgName: string | undefined, ethnicity: string, referralPublicId?: string) =>
          trackEvent(mixpanelApiUrl, 'Ethnic group entered', {
              super: { 'Organisation code': orgCode, 'Organisation name': orgName, 'Ethnic group': ethnicity },
              event: {
                'Referral public id': referralPublicId,
              }
          }),
      ethnicGroupBackgroundEntered: (orgCode: string | undefined, orgName: string | undefined, background: string, referralPublicId?: string) =>
          trackEvent(mixpanelApiUrl, 'Ethnic group background entered', {
              super: {
                  'Organisation code': orgCode,
                  'Organisation name': orgName,
                  'Ethnic group background': background,
              },
              event: {
                'Referral public id': referralPublicId,
              }
          }),

      // login/out events
      signInClicked: (orgCode: string | undefined, orgName: string | undefined) =>
          trackEvent(mixpanelApiUrl, 'Sign in clicked', {
              event: { 'Organisation code': orgCode, 'Organisation name': orgName},
          }),
      loggedIn: (orgCode: string | undefined, orgName: string | undefined) =>
          trackEvent(mixpanelApiUrl, 'Logged in', {
              event: { 'Organisation code': orgCode, 'Organisation name': orgName},
          }),
      loggedOut: (orgCode: string | undefined, orgName: string | undefined, referralPublicId?: string) =>
          trackEvent(mixpanelApiUrl, 'Logged out', {
              event: { 'Organisation code': orgCode, 'Organisation name': orgName, 'Referral public id': referralPublicId },
          }),

      // authenticated is special - it should be called whenever the user is authenticated successfully,
      // and its where we set all the user profile properties
      authenticated: (orgCode: string | undefined, orgName: string | undefined, user: User,referralPublicId?: string) => {
          userId = user.id
          trackEvent(mixpanelApiUrl, 'Authenticated', {
              user: {
                  'User type': user.userType,
                  'Organisation code': orgCode,
                  'Organisation name': orgName,
                  ...getReferralPropsFromCode(user.referralCode),
                  Sex: user.sex,
                  Age: user.birthdate ? calculateAge(Date.parse(user.birthdate)) : undefined,
              },
              event:{
                'Referral public id': referralPublicId,
              }
          })
      },

      // assessment events:
      assessmentStarted: (orgCode: string | undefined, orgName: string | undefined,referralPublicId?: string) => {
          console.log('assessment started', orgCode, orgName)
          enableTimer(Timers.AssessmentTotalDuration)
          trackEvent(mixpanelApiUrl, 'Assessment started', {
              event: { 'Organisation code': orgCode, 'Organisation name': orgName, 'Referral public id': referralPublicId, },
          })
      },
      sessionStarted: (orgCode: string | undefined, orgName: string | undefined, sessionName: string,referralPublicId?: string) =>
          trackEvent(mixpanelApiUrl, 'Session started', {
              event: { 'Session name': sessionName, 'Organisation code': orgCode, 'Organisation name': orgName, 'Referral public id': referralPublicId, },
          }),
      screenedIn: (
          orgCode: string | undefined,
          orgName: string | undefined,
          questionId: string,
          conditionName?: string,
          referralPublicId?: string
      ) =>
          trackEvent(mixpanelApiUrl, 'Screened in', {
              event: {
                  'Question ID': questionId,
                  Condition: conditionName,
                  'Organisation code': orgCode,
                  'Organisation name': orgName,
                  'Referral public id': referralPublicId,
              },
          }),
      questionAsked: (orgCode: string | undefined, orgName: string | undefined, questionId: string,referralPublicId?: string) =>
          trackEvent(mixpanelApiUrl, 'Question asked', {
              event: { 'Question ID': questionId, 'Organisation code': orgCode, 'Organisation name': orgName, 'Referral public id': referralPublicId, },
          }),
      questionAnswered: (
          orgCode: string | undefined,
          orgName: string | undefined,
          questionId: string,
          questionType?: string,
          conditionName?: string,
          timeSpent?: string,
          referralPublicId?: string
      ) =>
          trackEvent(mixpanelApiUrl, 'Question answered', {
              event: {
                  'Question ID': questionId,
                  'Question Type': questionType,
                  Condition: conditionName,
                  'Time spent (secs)': timeSpent,
                  'Organisation code': orgCode,
                  'Organisation name': orgName,
                  'Referral public id': referralPublicId,
              },
          }),
      answerRemoved: (orgCode: string | undefined, orgName: string | undefined, questionId: string, referralPublicId?: string) =>
          trackEvent(mixpanelApiUrl, 'Answer removed', {
              event: { 'Question ID': questionId, 'Organisation code': orgCode, 'Organisation name': orgName, 'Referral public id': referralPublicId, },
          }),
      sessionCompleted: (orgCode: string | undefined, orgName: string | undefined, sessionName: string, referralPublicId?: string) => {
          trackEvent(mixpanelApiUrl, 'Session completed', {
              event: { 'Session name': sessionName, 'Organisation code': orgCode, 'Organisation name': orgName, 'Referral public id': referralPublicId, },
          })
      },

      assessmentCompleted: (orgCode: string | undefined, orgName: string | undefined, referralPublicId?: string) => {
          const assessmentDuration = getTimer(Timers.AssessmentTotalDuration)
          trackEvent(mixpanelApiUrl, 'Completed assessment', {
              super: {
                  'Organisation code': orgCode,
                  'Organisation name': orgName,
                  'Assessment duration (secs)': assessmentDuration,
              },
              event: {
                'Referral public id': referralPublicId,
              }
          })
      },

      // Demo events
      usedDemoAccessCode: (code: string) =>
        trackEvent(mixpanelApiUrl, 'Used demo access code', {
            event: { 'Access code': code },
        }),
      visitedDemoHomepage: (orgCode: string | undefined, orgName: string | undefined) =>
          trackEvent(mixpanelApiUrl, 'Visited demo homepage', {
              event: { 'Organisation code': orgCode, 'Organisation name': orgName },
          }),
      visitedDemoHealthCareExperiences: (orgCode: string | undefined, orgName: string | undefined) =>
          trackEvent(mixpanelApiUrl, 'Visited demo healthcare experiences', {
              event: { 'Organisation code': orgCode, 'Organisation name': orgName },
          }),
      visitedDemoPatientIntro: (orgCode: string | undefined, orgName: string | undefined) =>
          trackEvent(mixpanelApiUrl, 'Visited demo patient intro', {
              event: { 'Organisation code': orgCode, 'Organisation name': orgName },
          }),
      visitedDemoExperience: (orgCode: string | undefined, orgName: string | undefined) =>
          trackEvent(mixpanelApiUrl, 'Visited demo experience', {
              event: { 'Organisation code': orgCode, 'Organisation name': orgName },
          }),
      visitedDemoIntro: (orgCode: string | undefined, orgName: string | undefined) =>
          trackEvent(mixpanelApiUrl, 'Visited demo intro', {
              event: { 'Organisation code': orgCode, 'Organisation name': orgName },
          }),
      visitedDemoStats: (orgCode: string | undefined, orgName: string | undefined) =>
          trackEvent(mixpanelApiUrl, 'Visited demo stats', {
              event: { 'Organisation code': orgCode, 'Organisation name': orgName },
          }),
      visitedDemoPatientMgt: (orgCode: string | undefined, orgName: string | undefined) =>
          trackEvent(mixpanelApiUrl, 'Visited patient mgt', {
              event: { 'Organisation code': orgCode, 'Organisation name': orgName },
          }),
      visitedDemoInsights: (orgCode: string | undefined, orgName: string | undefined) =>
          trackEvent(mixpanelApiUrl, 'Visited demo insights', {
              event: { 'Organisation code': orgCode, 'Organisation name': orgName },
          }),
      visitedDemoStartPatientJourney: (orgCode: string | undefined, orgName: string | undefined) =>
          trackEvent(mixpanelApiUrl, 'Visited demo start patient journey', {
              event: { 'Organisation code': orgCode, 'Organisation name': orgName },
          }),
  }
};

const trackEvent = async (
    mixpanelApiUrl: string,
    name: string,
    properties?: { event?: any; super?: any; user?: any }
) => {
    const orgName =
        properties?.event?.['Organisation name'] ??
        properties?.super?.['Organisation name'] ??
        properties?.user?.['Organisation name'] ??
        'Unknown org name'

    const orgCode =
        properties?.event?.['Organisation code'] ??
        properties?.super?.['Organisation code'] ??
        properties?.user?.['Organisation code'] ??
        'Unknown org code'

    if (properties?.user) {
        await mixpanelSetPeople(mixpanelApiUrl, userId, properties.user, preSignUpUserId)
    }

    await mixpanelTrack(mixpanelApiUrl, name, {
        distinct_id: userId ?? preSignUpUserId,
        $os: navigator.userAgent,
        'Organisation name': orgName,
        'Organisation code': orgCode,
        ...properties?.event,
        ...properties?.super,
    })
}

function getReferralPropsFromCode(referralCodeStr?: string) {
    if (!referralCodeStr) {
        // send these so these values are cleared if someone decides to move back to a D2C registration from NHS landing page
        return { 'Service name': undefined, 'Pathway name': undefined }
    }
    const referralCode = ReferralCode[referralCodeStr as keyof typeof ReferralCode]
    const referral = Referrals[referralCode]
    const { serviceName, pathwayName } = referral
    return { 'Service name': serviceName, 'Pathway name': pathwayName }
}
