export const getReferralsEndpoint = (pathname: string, referralApiUrl: string): string => {
    const isReport = pathname.split('/')[1] === 'report'
    const isOrgAccountPage = pathname.split('/')[1] === 'account'

    if (isOrgAccountPage) {
        const organisationCode = pathname.split('/')[2] || ''
        return `${referralApiUrl}referrals/unauthenticated?organisationCode=${organisationCode}`
    }

    if (isReport) {
        const reportId = pathname.split('/')[2]
        return `${referralApiUrl}referrals/unauthenticated?assessmentId=${reportId}`
    }

    throw new Error('Not supported')
}
