import React from 'react'
import PropTypes from 'prop-types'
import { classNameMaker } from 'ui/utils'
import Styles from './Card.module.scss'

type ICardProps = React.ComponentPropsWithoutRef<'div'>

const Card: React.FC<ICardProps> = ({ children, className, ...props }) => {
    const containerClass: string = classNameMaker({
        [Styles.container]: true,
        className: true,
    })
    return (
        <div className={containerClass} {...props}>
            {children}
        </div>
    )
}

Card.propTypes = {
    className: PropTypes.string,
}

export default Card
