import { gql } from '@apollo/client'
import { Question } from 'censeo-core'
import { DocumentNode } from 'graphql'

export const questionQuery: DocumentNode = gql`
    query Question($id: ID!) {
        question(id: $id) {
            ... on FreeQuestion {
                id
                __typename
                text
                answer
                unit
            }
            ... on RestrictedChoiceQuestion {
                id
                __typename
                text
                answers {
                    id
                    text
                    exclusive
                }
                isMultiselect
                chosenOptionIds
            }
            ... on FreeTextQuestion {
                id
                __typename
                text
                answerText
                mandatory
            }
        }
    }
`

export const demoQuestionQuery: DocumentNode = gql`
    query DemoQuestion($allAnswers: [AnsweredQuestion!]!, $id: ID!) {
        demoQuestion(allAnswers: $allAnswers, id: $id) {
            ... on FreeQuestion {
                id
                __typename
                text
                answer
                unit
            }
            ... on RestrictedChoiceQuestion {
                id
                __typename
                text
                answers {
                    id
                    text
                    exclusive
                }
                isMultiselect
                chosenOptionIds
            }
            ... on FreeTextQuestion {
                id
                __typename
                text
                answerText
                mandatory
            }
        }
    }
`

export interface StandardQuestionData {
    question: Question
}
export interface DemoQuestionData {
    demoQuestion: Question
}
export type QuestionData = StandardQuestionData | DemoQuestionData
