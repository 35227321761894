"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createPatientContext = exports.generateReportDetails = exports.getReportDetails = void 0;
const reportData_1 = require("./conditions/reportData");
const _1 = require(".");
const answers_1 = require("./answers");
const mapSymptomSections = (symptomSections) => {
    return symptomSections.map((x) => ({
        __typename: 'SymptomsSection',
        title: x.title,
        symptoms: x.symptoms.map((y) => ({
            __typename: 'Symptom',
            description: y.description,
        })),
        symptomsSubSections: mapSubsections(x),
    }));
};
const mapSubsections = (symptomSection) => {
    return symptomSection.subSections.reduce((acc, cur) => {
        const val = {
            __typename: 'SymptomsSubSection',
            title: cur.title,
            symptoms: cur.symptoms.map((s) => ({ __typename: 'Symptom', description: s.description })),
        };
        return acc.concat([val]).concat(mapSubsections(cur));
    }, []);
};
const getReportDetails = (data) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const conditionInfo = (0, _1.conditionInformation)(data.user.userType);
    const demographics = {
        __typename: 'Demographics',
        ...data.user,
        nhsNumberTrust: data.trustNhsNumber,
    };
    const risk = {
        __typename: 'Risk',
        score: (_a = data.reportData.rag) === null || _a === void 0 ? void 0 : _a.score,
        outcomes: ((_b = data.reportData.rag) === null || _b === void 0 ? void 0 : _b.outcomes)
            ? (_c = data.reportData.rag) === null || _c === void 0 ? void 0 : _c.outcomes.map(({ name, symptoms, scoreText, ...rest }) => {
                return {
                    __typename: 'Outcome',
                    name,
                    friendlyName: (0, reportData_1.ragOutcomeNamesFromName)(data.user.userType)[name],
                    code: Object.keys(conditionInfo).find((key) => conditionInfo[key].name === name),
                    symptomsSections: mapSymptomSections(symptoms),
                    ...rest,
                };
            })
            : [],
    };
    const riskSummary = {
        __typename: 'RiskSummary',
        score: (_d = data.reportData.rag) === null || _d === void 0 ? void 0 : _d.score,
        outcomes: ((_e = data.reportData.rag) === null || _e === void 0 ? void 0 : _e.outcomes)
            ? (_f = data.reportData.rag) === null || _f === void 0 ? void 0 : _f.outcomes.map((outcome) => ({
                __typename: 'Outcome',
                code: Object.keys(conditionInfo).find((key) => conditionInfo[key].name === outcome.name),
                name: outcome.name,
                friendlyName: (0, reportData_1.ragOutcomeNamesFromName)(data.user.userType)[outcome.name],
                score: outcome.score,
            }))
            : [],
    };
    const keyProblems = [
        {
            __typename: 'KeyProblems',
            title: 'Reasons for seeking treatment',
            description: data.freeTextAnswer,
        },
    ];
    const conditions = data.reportData.conditions.map(({ name, symptoms, score }) => ({
        __typename: 'Conditions',
        code: Object.keys(conditionInfo).find((key) => conditionInfo[key].name === name),
        name,
        patientFacingName: conditionInfo[Object.keys(conditionInfo).find((key) => conditionInfo[key].name === name)]
            .patientFacingName,
        symptomsSections: mapSymptomSections(symptoms),
        isSevere: (0, reportData_1.isSevere)(score),
        isSubClinical: (0, reportData_1.isSubClinical)(score),
        seriousness: (0, reportData_1.toSeriousness)(score),
    }));
    const conditionsSummary = [
        {
            __typename: 'ConditionsSummary',
            code: 'adj_ce',
            displayName: conditionInfo['adj_ce'].name,
            patientFacingDisplayName: conditionInfo['adj_ce'].patientFacingName,
            group: 1,
            order: 1,
        },
        {
            __typename: 'ConditionsSummary',
            code: 'simple_mdd',
            displayName: conditionInfo['simple_mdd'].name,
            patientFacingDisplayName: (_g = conditionInfo['simple_mdd']) === null || _g === void 0 ? void 0 : _g.patientFacingName,
            group: 1,
            order: 2,
        },
        {
            __typename: 'ConditionsSummary',
            code: 'gad_ce',
            displayName: conditionInfo['gad_ce'].name,
            patientFacingDisplayName: conditionInfo['gad_ce'].patientFacingName,
            group: 1,
            order: 3,
        },
        {
            __typename: 'ConditionsSummary',
            code: 'soa_ce',
            displayName: conditionInfo['soa_ce'].name,
            patientFacingDisplayName: conditionInfo['soa_ce'].patientFacingName,
            group: 1,
            order: 4,
        },
        {
            __typename: 'ConditionsSummary',
            code: 'pad_ce',
            displayName: conditionInfo['pad_ce'].name,
            patientFacingDisplayName: conditionInfo['pad_ce'].patientFacingName,
            group: 1,
            order: 5,
        },
        {
            __typename: 'ConditionsSummary',
            code: 'ocd_ce',
            displayName: conditionInfo['ocd_ce'].name,
            patientFacingDisplayName: conditionInfo['ocd_ce'].patientFacingName,
            group: 1,
            order: 6,
        },
        {
            __typename: 'ConditionsSummary',
            code: 'ins_ce',
            displayName: conditionInfo['ins_ce'].name,
            patientFacingDisplayName: conditionInfo['ins_ce'].patientFacingName,
            group: 1,
            order: 7,
        },
        {
            __typename: 'ConditionsSummary',
            code: 'emi_ce',
            displayName: conditionInfo['emi_ce'].name,
            patientFacingDisplayName: conditionInfo['emi_ce'].patientFacingName,
            group: 2,
            order: 1,
        },
        {
            __typename: 'ConditionsSummary',
            code: 'trm_re_ptsd',
            displayName: conditionInfo['trm_re_ptsd'].name,
            patientFacingDisplayName: conditionInfo['trm_re_ptsd'].patientFacingName,
            group: 2,
            order: 2,
        },
        {
            __typename: 'ConditionsSummary',
            code: 'dse_ce',
            displayName: conditionInfo['dse_ce'].name,
            patientFacingDisplayName: conditionInfo['dse_ce'].patientFacingName,
            group: 3,
            order: 1,
        },
        {
            __typename: 'ConditionsSummary',
            code: 'simple_bd1',
            displayName: conditionInfo['simple_bd1'].name,
            patientFacingDisplayName: conditionInfo['simple_bd1'].patientFacingName,
            group: 4,
            order: 1,
        },
        {
            __typename: 'ConditionsSummary',
            code: 'simple_bd2',
            displayName: conditionInfo['simple_bd2'].name,
            patientFacingDisplayName: conditionInfo['simple_bd2'].patientFacingName,
            group: 4,
            order: 2,
        },
        {
            __typename: 'ConditionsSummary',
            code: 'pso_ce',
            displayName: conditionInfo['pso_ce'].name,
            patientFacingDisplayName: conditionInfo['pso_ce'].patientFacingName,
            group: 4,
            order: 3,
        },
        {
            __typename: 'ConditionsSummary',
            code: 'alc_ce',
            displayName: conditionInfo['alc_ce'].name,
            patientFacingDisplayName: conditionInfo['alc_ce'].patientFacingName,
            group: 5,
            order: 2,
        },
        {
            __typename: 'ConditionsSummary',
            code: 'sbs_ce',
            displayName: conditionInfo['sbs_ce'].name,
            patientFacingDisplayName: conditionInfo['sbs_ce'].patientFacingName,
            group: 5,
            order: 1,
        },
        {
            __typename: 'ConditionsSummary',
            code: 'adhd',
            displayName: conditionInfo['adhd'].name,
            patientFacingDisplayName: conditionInfo['adhd'].patientFacingName,
            group: 6,
            order: 1,
        },
    ].filter((summary) => conditions.some((condition) => condition.code === summary.code));
    const patientContext = (0, exports.createPatientContext)(data, conditionInfo);
    const patientContextSummary = [
        {
            __typename: 'PatientContextSummary',
            name: 'Childcare responsibilities',
            group: 1,
            order: 1,
        },
        {
            __typename: 'PatientContextSummary',
            name: 'Current pregnancy',
            group: 1,
            order: 2,
        },
        {
            __typename: 'PatientContextSummary',
            name: 'Unpaid caring responsibilities',
            group: 1,
            order: 3,
        },
        {
            __typename: 'PatientContextSummary',
            name: 'Employment status',
            group: 2,
            order: 1,
        },
        {
            __typename: 'PatientContextSummary',
            name: 'Financial status',
            group: 2,
            order: 2,
        },
        {
            __typename: 'PatientContextSummary',
            name: 'Place of residence',
            group: 2,
            order: 3,
        },
        {
            __typename: 'PatientContextSummary',
            name: 'Perception of physical health',
            group: 3,
            order: 1,
        },
        {
            __typename: 'PatientContextSummary',
            name: 'Physical health diagnosis impacting on mental health',
            group: 3,
            order: 2,
        },
        {
            __typename: 'PatientContextSummary',
            name: 'Perception of mental health',
            group: 4,
            order: 1,
        },
        {
            __typename: 'PatientContextSummary',
            name: 'Previous mental health diagnosis',
            group: 4,
            order: 2,
        },
        {
            __typename: 'PatientContextSummary',
            name: 'Time spent with loved ones',
            group: 5,
            order: 1,
        },
        {
            __typename: 'PatientContextSummary',
            name: 'Someone to talk to',
            group: 5,
            order: 2,
        },
    ];
    const reportDetails = {
        __typename: 'ReportDetails',
        demographics,
        risk,
        riskSummary,
        keyProblems,
        conditions,
        conditionsSummary,
        patientContext,
        patientContextSummary,
        completedDate: data.assessmentCompletedDate,
    };
    return {
        report: reportDetails,
        metadata: {
            ragDecisions: data.reportData.rag.decisions,
            ...(data.orgCode !== undefined && { orgCode: data.orgCode }),
        },
    };
};
exports.getReportDetails = getReportDetails;
const generateReportDetails = (questions, aspects, answers, aspectsToEvaluate, user, sessionConfig = _1.defaultSessionConfigs, noThreshold = false, trustNhsNumber, assessmentCompletedDate, orgCode) => {
    const filteredAnswers = (0, answers_1.filterValidAnswers)(answers, questions, aspects, _1.questionPredicates, user.id);
    const evaluatedAspects = (0, _1.evaluateAspects)(aspectsToEvaluate, aspects, filteredAnswers);
    const reportData = (0, reportData_1.getReportData)(questions, filteredAnswers, evaluatedAspects, noThreshold, user, sessionConfig);
    const freeTextAnswer = filteredAnswers.find((a) => a.questionId === 'DemR');
    const bmi = (0, _1.calculateBmi)(filteredAnswers, questions);
    const data = {
        reportData,
        user,
        trustNhsNumber,
        freeTextAnswer: freeTextAnswer === null || freeTextAnswer === void 0 ? void 0 : freeTextAnswer.freeText,
        bmi,
        assessmentCompletedDate,
        orgCode,
    };
    const reportDetails = (0, exports.getReportDetails)(data);
    return reportDetails;
};
exports.generateReportDetails = generateReportDetails;
const createPatientContext = (data, conditionInfo) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const PAST_ALCOHOL_SECTION_NAME = 'Previous episode';
    const PAST_SUBSTANCE_SECTION_NAME = 'Episode details';
    const reportData = data.reportData;
    let pastAlcoholPreviousEpisodeSymptoms = (_c = (_b = (_a = reportData.staticConditions) === null || _a === void 0 ? void 0 : _a[conditionInfo['alc_pe_dep'].name]) === null || _b === void 0 ? void 0 : _b.symptoms) === null || _c === void 0 ? void 0 : _c.find((symptomSection) => {
        return symptomSection.title === PAST_ALCOHOL_SECTION_NAME;
    });
    function createBase(title, description, foundSymptoms) {
        return {
            subSections: [
                {
                    subSections: [],
                    symptoms: [
                        {
                            description,
                        },
                    ],
                    title: '',
                },
            ],
            symptoms: [],
            ...foundSymptoms,
            title,
        };
    }
    pastAlcoholPreviousEpisodeSymptoms = createBase('Past Alcohol Use', 'No alcohol use recorded', pastAlcoholPreviousEpisodeSymptoms);
    let pastSubstanceEpisodeSymptoms = (_f = (_e = (_d = reportData.staticConditions) === null || _d === void 0 ? void 0 : _d[conditionInfo['sbs_pe'].name]) === null || _e === void 0 ? void 0 : _e.symptoms) === null || _f === void 0 ? void 0 : _f.find((symptomSection) => {
        return symptomSection.title === PAST_SUBSTANCE_SECTION_NAME;
    });
    pastSubstanceEpisodeSymptoms = createBase('Past Substance Use', 'No substance use recorded', pastSubstanceEpisodeSymptoms);
    const patientContextSymptoms = ((_h = (_g = reportData.staticConditions) === null || _g === void 0 ? void 0 : _g[conditionInfo['pc'].name]) === null || _h === void 0 ? void 0 : _h.symptoms) || [];
    const patientContext = [
        ...patientContextSymptoms,
        pastAlcoholPreviousEpisodeSymptoms,
        pastSubstanceEpisodeSymptoms,
    ].map((x) => {
        return {
            __typename: 'PatientContext',
            title: x.title,
            sections: x.subSections
                .map((y) => {
                if (y.symptoms[0]) {
                    return y.symptoms
                        .map((symp, i) => {
                        const output = {
                            __typename: 'Sections',
                            title: i > 0 ? '' : y.title,
                            description: symp.description,
                        };
                        if (y.title === 'Height') {
                            return [
                                output,
                                {
                                    __typename: 'Sections',
                                    title: 'Body Mass Index',
                                    description: data.bmi,
                                },
                            ];
                        }
                        else {
                            return output;
                        }
                    })
                        .flat();
                }
                else {
                    return y.subSections
                        .map((subSections) => subSections.symptoms.map((symp, i) => {
                        return {
                            __typename: 'Sections',
                            title: i > 0 ? '' : y.title,
                            description: symp.description,
                        };
                    }))
                        .flat();
                }
            })
                .flat(),
        };
    });
    return patientContext;
};
exports.createPatientContext = createPatientContext;
