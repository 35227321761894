"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserType = exports.Unit = exports.ReportResultMessage = void 0;
var ReportResultMessage;
(function (ReportResultMessage) {
    ReportResultMessage["AccessDenied"] = "ACCESS_DENIED";
    ReportResultMessage["NotFound"] = "NOT_FOUND";
    ReportResultMessage["Success"] = "SUCCESS";
})(ReportResultMessage = exports.ReportResultMessage || (exports.ReportResultMessage = {}));
var Unit;
(function (Unit) {
    Unit["Age"] = "Age";
    Unit["DurationHours"] = "DurationHours";
    Unit["DurationMinutes"] = "DurationMinutes";
    Unit["Height"] = "Height";
    Unit["None"] = "None";
    Unit["Weight"] = "Weight";
})(Unit = exports.Unit || (exports.Unit = {}));
var UserType;
(function (UserType) {
    UserType["D2C"] = "D2C";
    UserType["Nhs"] = "NHS";
})(UserType = exports.UserType || (exports.UserType = {}));
