"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.nodePaths = exports.supplementalInfoQuestionIds = exports.START_ID = void 0;
const types_1 = require("../types");
exports.START_ID = 'A';
exports.supplementalInfoQuestionIds = ['S-14a', 'S-15a', 'S-22a', 'S-4a', 'EUPD-15'];
const SIX_MONTHS_IN_DAYS = 180;
const THREE_MONTHS_IN_DAYS = SIX_MONTHS_IN_DAYS / 2;
exports.nodePaths = {
    A: {
        questionId: 'S-s1',
        answers: {
            '59a1b51e-3319-5c4d-a9f4-e50455a6f5a6': 'B',
            '00f4664d-929e-5301-bd5b-f405a7ab8ce4': 'D',
        },
    },
    B: {
        questionId: 'S-s2',
        answers: {
            '07c32652-123e-5f58-894e-f4ed9c8b110e': 'C',
            '10e008b4-feb5-5134-9869-3dfc46cb9924': 'D',
        },
    },
    C: {
        questionId: 'S-s3',
        answers: {
            '7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35': types_1.RagIds.rag5,
            'e90b0a3b-f835-5a9b-948b-d1b685da2c2e': 'D',
        },
    },
    D: {
        questionId: 'S-s4',
        answers: {
            'e9fe5141-3a28-5ca3-9d76-2f51202a68b6': 'R',
            '48d1552a-30b1-5dee-a6f1-f9e12ced08a2': 'E',
        },
    },
    E: {
        questionId: 'S-2a',
        answers: {
            '5cd8930b-afc1-5d1e-9ba3-97c0036809b5': 'G',
            'fccc47c0-713b-578c-a123-73b81b4d186d': 'F',
            '51f2de30-69cd-5a83-85a4-2e3310861a6f': 'F',
            '541369ff-8077-58e5-8bf7-90a8a94d14bb': 'G',
            '84b1dac4-ea2f-5f51-a205-0efe9d0e6c8c': 'G',
        },
    },
    F: {
        questionId: 'S-8a',
        answers: {
            'cb6c8c06-d0b8-558d-8e33-bf475718cb0d': 'R',
            '93743584-8c8b-5a15-afa2-95a24c811beb': 'H',
            '4f14f047-a4d6-5709-95b7-4742bec76fa7': 'H',
            'fb782e60-165b-5cd2-ae70-c40b281bc387': 'I',
        },
    },
    G: {
        questionId: 'S-8a',
        answers: {
            'cb6c8c06-d0b8-558d-8e33-bf475718cb0d': 'R',
            '93743584-8c8b-5a15-afa2-95a24c811beb': 'J',
            '4f14f047-a4d6-5709-95b7-4742bec76fa7': 'J',
            'fb782e60-165b-5cd2-ae70-c40b281bc387': 'K',
        },
    },
    H: {
        questionId: 'S-6a',
        answers: {
            'c6b26b20-786e-537b-89bc-71c4c3155678': 'R',
            'c0db0893-6906-5a21-ab74-144c85c0683d': 'P',
            '0667075d-d44a-5ec5-8a05-4d25659c831d': 'P',
            '870c5cac-fb60-51ca-9d26-bf9e93d56ab2': 'M',
            '0181dcdd-3d3d-512a-bdf8-de8a996bd7e8': 'M',
        },
    },
    I: {
        questionId: 'S-6a',
        answers: {
            'c6b26b20-786e-537b-89bc-71c4c3155678': 'T',
            'c0db0893-6906-5a21-ab74-144c85c0683d': 'T',
            '0667075d-d44a-5ec5-8a05-4d25659c831d': 'M',
            '870c5cac-fb60-51ca-9d26-bf9e93d56ab2': 'N',
            '0181dcdd-3d3d-512a-bdf8-de8a996bd7e8': 'N',
        },
    },
    J: {
        questionId: 'S-6a',
        answers: {
            'c6b26b20-786e-537b-89bc-71c4c3155678': 'T',
            'c0db0893-6906-5a21-ab74-144c85c0683d': 'T',
            '0667075d-d44a-5ec5-8a05-4d25659c831d': 'P',
            '870c5cac-fb60-51ca-9d26-bf9e93d56ab2': 'P',
            '0181dcdd-3d3d-512a-bdf8-de8a996bd7e8': 'M',
        },
    },
    K: {
        questionId: 'S-6a',
        answers: {
            'c6b26b20-786e-537b-89bc-71c4c3155678': 'V',
            'c0db0893-6906-5a21-ab74-144c85c0683d': 'P',
            '0667075d-d44a-5ec5-8a05-4d25659c831d': 'M',
            '870c5cac-fb60-51ca-9d26-bf9e93d56ab2': 'U',
            '0181dcdd-3d3d-512a-bdf8-de8a996bd7e8': 'U',
        },
    },
    M: {
        questionId: 'S-18a',
        answers: {
            '890e7c6f-4bdf-5d6c-87e4-d2fc93a63102': types_1.RagIds.rag2,
            'e8bbf23a-7af1-5ec5-a666-e81bbeed6043': types_1.RagIds.rag4,
            '81b3f713-07b4-5d30-8066-60c1326c2aa1': 'S',
        },
    },
    N: {
        questionId: 'S-18a',
        answers: {
            '890e7c6f-4bdf-5d6c-87e4-d2fc93a63102': types_1.RagIds.rag2,
            'e8bbf23a-7af1-5ec5-a666-e81bbeed6043': types_1.RagIds.rag4,
            '81b3f713-07b4-5d30-8066-60c1326c2aa1': types_1.RagIds.rag2,
        },
    },
    P: {
        questionId: 'S-18a',
        answers: {
            '890e7c6f-4bdf-5d6c-87e4-d2fc93a63102': 'S',
            'e8bbf23a-7af1-5ec5-a666-e81bbeed6043': types_1.RagIds.rag4,
            '81b3f713-07b4-5d30-8066-60c1326c2aa1': 'S',
        },
    },
    R: {
        questionId: 'S-14a',
        answers: {},
        noAnswer: types_1.RagIds.rag5,
        freeQuestion: [
            {
                rule: types_1.FreeQuestionRules.lessThan,
                comparisonValue: THREE_MONTHS_IN_DAYS,
                next: types_1.RagIds.rag3,
            },
            {
                rule: types_1.FreeQuestionRules.lessThanOrEqual,
                comparisonValue: SIX_MONTHS_IN_DAYS,
                next: types_1.RagIds.rag4,
            },
            {
                rule: types_1.FreeQuestionRules.greaterThan,
                comparisonValue: SIX_MONTHS_IN_DAYS,
                next: types_1.RagIds.rag5,
            },
        ],
    },
    S: {
        questionId: 'S-14a',
        answers: {},
        noAnswer: types_1.RagIds.rag3,
        freeQuestion: [
            {
                rule: types_1.FreeQuestionRules.lessThanOrEqual,
                comparisonValue: SIX_MONTHS_IN_DAYS,
                next: types_1.RagIds.rag2,
            },
            {
                rule: types_1.FreeQuestionRules.greaterThan,
                comparisonValue: SIX_MONTHS_IN_DAYS,
                next: types_1.RagIds.rag3,
            },
        ],
    },
    T: {
        questionId: 'S-14a',
        answers: {},
        noAnswer: types_1.RagIds.rag4,
        freeQuestion: [
            {
                rule: types_1.FreeQuestionRules.lessThanOrEqual,
                comparisonValue: SIX_MONTHS_IN_DAYS,
                next: types_1.RagIds.rag3,
            },
            {
                rule: types_1.FreeQuestionRules.greaterThan,
                comparisonValue: SIX_MONTHS_IN_DAYS,
                next: types_1.RagIds.rag4,
            },
        ],
    },
    U: {
        questionId: 'S-18a',
        answers: {
            '890e7c6f-4bdf-5d6c-87e4-d2fc93a63102': types_1.RagIds.rag2,
            'e8bbf23a-7af1-5ec5-a666-e81bbeed6043': types_1.RagIds.rag3,
            '81b3f713-07b4-5d30-8066-60c1326c2aa1': types_1.RagIds.rag2,
        },
    },
    V: {
        questionId: 'S-14a',
        answers: {},
        noAnswer: types_1.RagIds.rag5,
        freeQuestion: [
            {
                rule: types_1.FreeQuestionRules.lessThan,
                comparisonValue: THREE_MONTHS_IN_DAYS,
                next: types_1.RagIds.rag2,
            },
            {
                rule: types_1.FreeQuestionRules.lessThanOrEqual,
                comparisonValue: SIX_MONTHS_IN_DAYS,
                next: types_1.RagIds.rag3,
            },
            {
                rule: types_1.FreeQuestionRules.greaterThan,
                comparisonValue: SIX_MONTHS_IN_DAYS,
                next: types_1.RagIds.rag5,
            },
        ],
    },
};
