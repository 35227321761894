import React from 'react'
import { Text, List, ListItem } from '@psyomics/components'

const CrisisContent: React.FC = () => {
    return (
        <>
            <Text el="p">If you are not able to keep yourself safe and you need immediate help:</Text>
            <List spacing={2} css={{ my: 2 }}>
                <ListItem>
                    Go to your <b>nearest A&amp;E</b> or get someone to take you there
                </ListItem>
                <ListItem>
                    If you are unable to do this, <b>call 999</b> or get someone to call for you
                </ListItem>
            </List>
            <Text el="p">If you need urgent help and support, but there is no immediate danger to yourself:</Text>
            <List spacing={2} css={{ my: 2 }}>
                <ListItem>
                    Call <b>NHS 111</b>
                </ListItem>
                <ListItem>
                    Call the <b>Samaritans on 116 123</b> - available 24hr a day, 365 days of the year
                </ListItem>
            </List>
            <Text el="p">
                If you need urgent support and already have contact with your local mental health services, contact your
                crisis team.
            </Text>
        </>
    )
}

export default CrisisContent
