import React, { useContext } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import SignUp from '../../pages/SignUp'
import SignIn from '../../pages/SignIn'
import ResetPassword from '../ResetPassword/ResetPassword'
import { RegistrationProvider } from 'registration/context/registration/registration-provider'
import { RegistrationRoutes } from 'registration/context/registration/registration.definitions'
import NotFound from '../../../ui/app/pages/NotFound'
import NhsLanding1 from '../SignUp/NhsLanding1'
import { Homepage } from './Homepage'
import CreateQuicksilvaReferral from '../CreateQuicksilvaReferral'
import DemoRoutes from '../Demo/DemoRoutes'
import Error from 'ui/app/pages/Error'
import SmsFeedback from '../SmsFeedback'
import ProlificLanding from '../Prolific/ProlificLanding'
import { D2COnboarding } from '../D2COnboarding/D2COnboarding'
import { TestStripe } from '../TestStripe'
import { AfterPayment } from '../TestStripe/AfterPayment'
import { SmsOptOut } from 'ui/app/pages/SmsOptOut/SmsOptOut'
import { ReferralDetailsContext } from 'ui/app/context/referralDetails'
import RenewedReferralWelcome from '../Onboarding/RenewedReferralWelcome'

type IWelcomeProps = {
    referralPublicId?: string
    setReferralPublicId?: React.Dispatch<React.SetStateAction<string | undefined>>
} & React.ComponentPropsWithoutRef<'div'>

const Welcome: React.FC<IWelcomeProps> = ({ ...props }) => {
    const location = useLocation()
    const { referralPublicId, setReferralPublicId } = props
    const { data: referralDetails } = useContext(ReferralDetailsContext) || {}
    const isDemo = referralDetails?.organisationConfig?.demo

    return (
        <RegistrationProvider>
            <Routes location={location}>
                <Route path="/self-assessment" element={<D2COnboarding />} />
                <Route path={'/signup/*'} element={<SignUp referralPublicId={referralPublicId} />} />
                <Route
                    path={RegistrationRoutes.signin}
                    element={<SignIn setReferralPublicId={setReferralPublicId} />}
                />
                <Route path={RegistrationRoutes.resetpassword} element={<ResetPassword />} />
                <Route
                    path={RegistrationRoutes.federatedSignIn}
                    element={
                        <Error
                            customTitle="Login failed"
                            customMessage={
                                new URLSearchParams(new URL(window.location.href).search).get('error_description') ||
                                undefined
                            }
                        />
                    }
                />
                <Route path="/referral/create" element={<CreateQuicksilvaReferral />} />
                <Route
                    path="/referral/:id"
                    element={
                        referralDetails?.hasUserId && !isDemo ? (
                            <RenewedReferralWelcome />
                        ) : (
                            <NhsLanding1
                                referralPublicId={location.pathname.split('/')[2]}
                                setReferralPublicId={setReferralPublicId}
                            />
                        )
                    }
                />
                <Route path="/demo/*" element={<DemoRoutes />} />
                <Route path="/feedback" element={<SmsFeedback />} />
                <Route path="/prolific" element={<ProlificLanding />} />
                <Route path="/test-stripe" element={<TestStripe />} />
                <Route path="/stripe-success" element={<AfterPayment fail={false} />} />
                <Route path="/stripe-fail" element={<AfterPayment fail={true} />} />
                <Route
                    path="/sms-opt-out/:referralPublicId"
                    element={<SmsOptOut referralPublicId={location.pathname.split('/')[2]} />}
                />
                <Route path="/" element={<Homepage {...props} />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </RegistrationProvider>
    )
}

export default Welcome
