"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.symptoms = void 0;
exports.symptoms = [
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "D-5"
            ],
            "prefix": "",
            "formatText": "{} time(s) due to depressive symptoms"
        },
        "section": [
            "Hospitalised"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "D-6"
            ],
            "prefix": "",
            "formatText": "{}"
        },
        "section": [
            "Duration of hospital stay:"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "D-7"
            ],
            "prefix": "",
            "formatText": "{}"
        },
        "section": [
            "Duration of longest stay in hospital:"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "D-8"
            ],
            "prefix": "",
            "formatText": "{}"
        },
        "section": [
            "Formal admittance to hospital due to symptoms:"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "D-1.5b"
            ],
            "prefix": "Number of previous depressive episodes: ",
            "formatText": "{}"
        },
        "section": [
            "Previous episode(s) details"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "D-2"
            ],
            "prefix": "Age at time of first episode: ",
            "formatText": "{}"
        },
        "section": [
            "Previous episode(s) details"
        ]
    },
    {
        "answerSummary": {
            "type": "MultiOptionSummary",
            "questionId": "D-s9.5b",
            "title": "Symptoms first started",
            "overrides": {
                "79ac2ed8-f395-5800-864a-077bf7ccf320": "in response to a recent bereavement",
                "52b44644-64c1-5afe-ac8e-e425d872d612": "in response to overwork/work stress/job loss",
                "dfadacb5-965b-53ba-9161-a71d42b10764": "in response to a physical illness or injury",
                "d91e00bc-a4a2-5153-8932-5798d909d727": "in response to the coronavirus outbreak",
                "99c6ae43-afd8-59cb-a22d-29e24f6d6c5d": "in response to a stressful event",
                "b95297f0-685c-506d-bd74-fe6a17d93184": "out of the blue"
            }
        },
        "section": [
            "Previous episode(s) details"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "D-43bt",
                    "optionSummaries": {
                        "90e60395-fc3d-56cb-b6f7-2e24fba02803": "A typical episode is the same as the most severe episode"
                    }
                }
            ]
        },
        "section": [
            "Previous episode(s) details"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} every day",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "D-12a",
                    "optionSummaries": {
                        "c0453e8e-7a9c-5bf5-b8a2-167a61a6387e": "less than 1 hour",
                        "916d657d-9f2a-5451-8465-18e3bd863347": "1–3 hours",
                        "eb68a88d-b49b-5cde-a9c6-5e4c78e27d19": "4–6 hours",
                        "6ede390f-0e43-50e4-82e9-1203db9d8343": "7–9 hours",
                        "9b47900d-e5a7-5e58-830b-d9b3130a7990": "10–12 hours",
                        "98772fed-cf55-5d42-b4e1-0e6a1a31ce56": "more than 12 hours"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Duration of current symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "D-13a",
                    "optionSummaries": {
                        "322210fb-135d-5ac8-9617-7fc4f85e016d": "1 day per week",
                        "b74511c7-9f97-5c15-8bf5-6ec4357b4814": "2–3 days per week",
                        "a0e528ca-2fbb-5da1-9398-0758f79755aa": "4–5 days per week",
                        "826538fc-8a97-55e4-8013-fe6a93f8e451": "6–7 days per week"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Duration of current symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "D-14a"
            ],
            "prefix": "for ",
            "formatText": "{}"
        },
        "section": [
            "Current episode",
            "Duration of current symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "feeling {}sad or depressed",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "D-1a",
                    "optionSummaries": {
                        "913bc1e9-8da1-54c7-b935-13fc5d48fbd9": "slightly ",
                        "db360e93-3616-5d89-be36-5ccee4aacf1c": "moderately ",
                        "7f1d6182-f8a7-5f4e-a0f8-2556b306ae36": "significantly ",
                        "80c993c8-1a94-5684-a8f9-d8f5072c8e9c": "severely "
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Psychological symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "CombinedAnswersSummary",
            "questionsIdGroups": [
                [
                    "D-2a"
                ],
                [
                    "D-33a"
                ],
                [
                    "D-34a"
                ]
            ],
            "groupsType": "Max",
            "pattern": "{} lack of enjoyment from people or activities",
            "coefficients": {
                "0.25": "mild",
                "0.5": "moderate",
                "0.75": "severe"
            }
        },
        "section": [
            "Current episode",
            "Psychological symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "unable to be cheered up {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "D-16.5a",
                    "optionSummaries": {
                        "5f88b934-32eb-5ed5-8896-a818cbe4b033": "to a slight degree",
                        "ae0a4d4e-e9bf-51bf-8506-0cbced38db92": "to a moderate degree",
                        "0c4e0b7f-132d-5e75-aeae-fd29d3b2eb67": "to a significant degree",
                        "b409dbb9-80ef-5de1-96d0-1075735134db": "to a severe degree"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Psychological symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "CombinedAnswersSummary",
            "questionsIdGroups": [
                [
                    "D-7a"
                ],
                [
                    "D-42a"
                ]
            ],
            "groupsType": "Mean",
            "pattern": "{} self-blame and guilt",
            "coefficients": {
                "0.1": "mild",
                "0.3": "moderate",
                "0.6": "severe"
            }
        },
        "section": [
            "Current episode",
            "Psychological symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "more thoughts about wanting to die {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "D-9a",
                    "optionSummaries": {
                        "13f8bac1-d0f5-539b-8596-1c55821bea46": "to a slight degree",
                        "f8e2518c-72cf-563b-b322-a03406c8ebac": "to a moderate degree",
                        "1200dadf-a0de-5a54-b0b8-fc640f7bc8d1": "to a significant degree",
                        "a70aee2a-9d23-554d-b7d3-9f1a025fd82d": "to a severe degree"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Psychological symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "CombinedAnswersSummary",
            "questionsIdGroups": [
                [
                    "D-20a"
                ],
                [
                    "D-41a"
                ]
            ],
            "groupsType": "Mean",
            "pattern": "{} decrease in sense of self-worth",
            "coefficients": {
                "0.1": "mild",
                "0.3": "moderate",
                "0.6": "severe"
            }
        },
        "section": [
            "Current episode",
            "Psychological symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "CombinedAnswersSummary",
            "questionsIdGroups": [
                [
                    "D-17a"
                ],
                [
                    "D-27a"
                ],
                [
                    "D-35a"
                ]
            ],
            "groupsType": "Max",
            "pattern": "{} increased irritability",
            "coefficients": {
                "0.25": "slightly",
                "0.5": "moderately",
                "0.75": "severely"
            }
        },
        "section": [
            "Current episode",
            "Psychological symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "D-31a",
                    "optionSummaries": {
                        "1cb5b1dd-fcae-50f1-b26b-53e0d5e03ed5": "rarely feels easily rejected or hurt by others",
                        "bfd75ca8-5c95-564e-9603-3b1a4f34f8d8": "occasionally feels easily rejected or hurt by others",
                        "eba65e81-9b02-55d6-82cb-0b7ce6da41de": "often feels easily rejected or hurt by others (without functional impairment)",
                        "c78e0def-d73e-5c4e-8800-7c6f92d0a1ec": "often feels easily rejected or hurt by others (with significant effect on relationship)"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Psychological symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "CombinedAnswersSummary",
            "questionsIdGroups": [
                [
                    "D-8a"
                ],
                [
                    "D-43a"
                ]
            ],
            "groupsType": "Mean",
            "pattern": "{} difficulty concentrating",
            "coefficients": {
                "0.1": "mild",
                "0.3": "moderate",
                "0.6": "severe"
            }
        },
        "section": [
            "Current episode",
            "Psychological symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "CombinedAnswersSummary",
            "questionsIdGroups": [
                [
                    "D-21a"
                ],
                [
                    "D-44a"
                ]
            ],
            "groupsType": "Mean",
            "pattern": "{} difficulty making decisions",
            "coefficients": {
                "0.1": "mild",
                "0.3": "moderate",
                "0.6": "severe"
            }
        },
        "section": [
            "Current episode",
            "Psychological symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "D-5a",
                    "optionSummaries": {
                        "b52073e3-c813-51f5-88ba-9cd3bddd7023": "slightly smaller appetite currently",
                        "188a7831-38c4-55c4-880a-1e4db4e9bab1": "significantly smaller appetite currently",
                        "c9813959-ba61-5220-9e27-659abb256037": "slightly larger appetite currently",
                        "dde1af45-4686-52c9-b960-369527b28f6c": "significantly larger appetite currently"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Somatic symptoms (affecting the body)"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} smaller appetite during the past 2 weeks",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "D-18a",
                    "optionSummaries": {
                        "615b852b-6f81-54a9-bc4a-a8843e82c230": "slightly",
                        "24079de6-89e4-58c8-bb94-8e281cfc37da": "moderately",
                        "d5dff57c-3626-5197-b54a-ddca85aa8775": "significantly",
                        "1586e7ea-daf7-5a6d-806f-aee9cc02b7e7": "very much"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Somatic symptoms (affecting the body)"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} larger appetite during the past 2 weeks",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "D-19a",
                    "optionSummaries": {
                        "b956a09d-6c39-5acc-a8d7-9024b1b2a2b3": "slightly",
                        "d5d03509-fbd0-500d-a2a9-51acdb2f03db": "moderately",
                        "6fde9b5c-ed1c-561c-b516-8f5028a1bcd5": "significantly",
                        "8cdb2b86-64d6-59c9-8594-f8164c1ffce7": "very much"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Somatic symptoms (affecting the body)"
        ]
    },
    {
        "answerSummary": {
            "type": "CombinedAnswersSummary",
            "questionsIdGroups": [
                [
                    "D-3a"
                ],
                [
                    "D-30a"
                ]
            ],
            "groupsType": "Mean",
            "pattern": "{} energy levels",
            "coefficients": {
                "0.1": "slightly lowered",
                "0.3": "moderately lowered",
                "0.6": "severely lowered"
            }
        },
        "section": [
            "Current episode",
            "Somatic symptoms (affecting the body)"
        ]
    },
    {
        "answerSummary": {
            "type": "CombinedAnswersSummary",
            "questionsIdGroups": [
                [
                    "D-28a"
                ],
                [
                    "D-40a"
                ]
            ],
            "groupsType": "Mean",
            "pattern": "tires {}more easily",
            "coefficients": {
                "0.1": "slightly ",
                "0.3": "moderately ",
                "0.6": "significantly "
            }
        },
        "section": [
            "Current episode",
            "Somatic symptoms (affecting the body)"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} slowed down physically or mentally",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "D-6a",
                    "optionSummaries": {
                        "e4732e97-da13-522c-aff4-41be6940e7b0": "slightly",
                        "3e093dd3-857e-5004-a4fb-961fbfa59ece": "moderately",
                        "08a0f83f-2f37-5904-9364-4e40deb90e98": "significantly",
                        "81e9f63b-35b9-5032-8ea0-462275a87cce": "severely"
                    }
                },
                {
                    "questionId": "D-38a",
                    "optionSummaries": {
                        "cfa17d19-5b15-56ef-82c1-07949e21dd81": "slightly",
                        "a03f02de-f86d-525a-b375-383ad9d3cefe": "moderately",
                        "8d5bbc66-0b33-5de5-838e-ff0fccbb6fbc": "significantly",
                        "5ca199a0-50f8-57f0-a098-03c824bb83ba": "severely"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Somatic symptoms (affecting the body)"
        ]
    },
    {
        "answerSummary": {
            "type": "MultiOptionSummary",
            "questionId": "D-4a",
            "title": "Other related symptoms",
            "overrides": {}
        },
        "section": [
            "Current episode",
            "Somatic symptoms (affecting the body)"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "D-37a",
                    "optionSummaries": {
                        "fd4e0608-283c-5f56-a026-edfa381519c9": "sleeping no longer than 5–6 hours per night without napping during the day",
                        "c8ebfb14-b9aa-5da4-8de3-ab8b83254249": "sleeping no longer than 7–8 hours per night without napping during the day",
                        "dc9f1b80-2303-5ac8-86ff-e9a39a6c0e16": "sleeping no longer than 10 hours in a 24-hour period, including naps",
                        "387df69c-36b4-5a16-aace-2219d4486773": "sleeping no longer than 12 hours in a 24-hour period, including naps",
                        "0a40aa9b-b7a1-5c28-8c6c-9e36400e803d": "sleeping longer than 12 hours in a 24-hour period, including naps"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Somatic symptoms (affecting the body)"
        ]
    },
    {
        "answerSummary": {
            "type": "CombinedAnswersSummary",
            "questionsIdGroups": [
                [
                    "D-23a"
                ],
                [
                    "D-39a"
                ]
            ],
            "groupsType": "Mean",
            "pattern": "{} restless",
            "coefficients": {
                "0.1": "slightly",
                "0.3": "moderately",
                "0.6": "severely"
            }
        },
        "section": [
            "Current episode",
            "Somatic symptoms (affecting the body)"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} decreased interest in sex",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "D-26a",
                    "optionSummaries": {
                        "945792e1-fdf5-5959-89c8-6a1caa4eff88": "slightly",
                        "823c244d-25bb-5b84-9f3c-16e82c594aee": "moderately",
                        "400d260d-4647-5f4d-8105-894b04c366f2": "significantly",
                        "eb4d6df4-7e45-5437-a36e-540fa95a45f9": "severely"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Somatic symptoms (affecting the body)"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} during the past 2 weeks",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "D-36a",
                    "optionSummaries": {
                        "f6958030-4bae-5f83-a569-35cb7675b803": "gained more than 5 lbs/2.3 kg",
                        "718c45b9-3e8d-55a6-9fea-28c120051194": "gained more than 2 lbs/0.9 kg",
                        "df6b03d1-7322-5277-91ae-7c8e08091a87": "gained a bit of weight",
                        "7fb54260-7d7e-5c80-809e-584be2bfee10": "lost a bit of weight",
                        "4ab32dca-be9e-573e-a330-24f9350ffaca": "lost more than 2 lbs/0.9 kg"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Somatic symptoms (affecting the body)"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "D-50a",
                    "optionSummaries": {
                        "d91f7277-0497-57d2-93a3-ea638d14162c": "higher intensity",
                        "d14da42d-9612-5827-9583-b2bf528130de": "similar intensity",
                        "b591ccff-25d0-5b4f-8091-4e8f8438987b": "lower intensity"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Severity of current episode compared to typical episode"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "D-32a",
                    "optionSummaries": {
                        "ea2a4fbd-008a-571b-bb68-cbd0ba351521": "no regular relationship between mood and the time of day",
                        "f992ce3a-fffd-5f38-aee4-24930ded9288": "mood is often related to the time of day because of environmental events (e.g. being alone, being at work)",
                        "539a0cec-0f13-510c-993e-f18c4b2d1d55": "mood is more related to the time of day than to environmental events",
                        "93c47fae-1a07-5ad0-b521-e56d8f62f763": "mood is clearly and predictably better or worse at a particular time each day"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Mood changes with time of day"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "mood is {} in the morning than the afternoon/evening",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "D-32.1a",
                    "optionSummaries": {
                        "da39b8b6-3178-5cf4-b68e-92b08cbf087e": "no worse",
                        "536a2dd7-0943-5d01-9537-428496ec789b": "worse"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Mood changes with time of day"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "mood improves {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "D-32.2a",
                    "optionSummaries": {
                        "b6f231f3-76c7-5cee-8223-939c4b9cdca6": "in late morning",
                        "10614446-1f4d-52f1-b11f-7febc78dff2d": "at lunch time",
                        "3b8059aa-8cbd-5853-8c52-9c8b7ede17c2": "in early afternoon",
                        "1bc5b013-a561-524f-91bd-1f5583b10103": "in late afternoon",
                        "c3cfa538-c5a3-55dd-8922-622dfa302b7f": "in early evening",
                        "8ae19a34-3a7c-585d-b93b-4489dead3bb9": "in late evening"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Mood changes with time of day"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Overall impairment: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "D-10a",
                    "optionSummaries": {
                        "45f0ce85-9e19-53da-bc0d-2af8b383318a": "no impairment",
                        "a16e8e14-524e-53df-b4ef-730a2c8e5f32": "mild",
                        "3bd18f66-6f1e-5ea4-ae24-2990ed197bef": "moderate",
                        "fe031280-7d5d-52cb-aca8-1a8664b3234a": "significant",
                        "bc1523b6-7d57-5471-90cd-884ef1f5ea5a": "extreme"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Functional impairment"
        ]
    },
    {
        "answerSummary": {
            "type": "MultiOptionSummary",
            "questionId": "D-46a",
            "title": "Areas of impairment",
            "overrides": {
                "13260a8b-4ec0-5e4d-84fc-1df990527937": "Other (not listed)",
                "3d9ee148-c4cf-4b89-8656-39e4adcf2947": "None reported"
            }
        },
        "section": [
            "Current episode",
            "Functional impairment"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "D-45a"
            ],
            "prefix": "Time off work: ",
            "formatText": "{}"
        },
        "section": [
            "Current episode",
            "Functional impairment"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} every day",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "D-2b",
                    "optionSummaries": {
                        "0b575683-fb35-54a6-ad5e-bc915444a195": "less than 1 hour",
                        "d6ea5d48-d2d0-5006-bc46-08b0b62669ae": "1–3 hours",
                        "0d65fd16-2a3f-5fc1-824e-1aafe23914f6": "4–6 hours",
                        "615cf056-c902-5c85-9f0b-21707d55c3a8": "7–9 hours",
                        "ddb73806-c7e4-5ebf-91db-6a88eaba820e": "10–12 hours",
                        "c27c1480-c35e-5e80-b33d-b61fa1c48946": "more than 12 hours"
                    }
                }
            ]
        },
        "section": [
            "Past episode",
            "Duration of previous symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "D-3b",
                    "optionSummaries": {
                        "47e411d3-147a-5b00-b055-c70f69c69582": "1 day per week",
                        "bfff59ac-9253-51da-adc1-62829cd7e4ee": "2–3 days per week",
                        "70bee62b-fe94-5a15-a02a-d2cb897661cd": "4–5 days per week",
                        "ec523b99-70d4-5885-81d5-718155b7cc35": "6–7 days per week"
                    }
                }
            ]
        },
        "section": [
            "Past episode",
            "Duration of previous symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "D-4b"
            ],
            "prefix": "most recent previous episode lasted for ",
            "formatText": "{}"
        },
        "section": [
            "Past episode",
            "Duration of previous symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "D-4.1b"
            ],
            "prefix": "age of onset: ",
            "formatText": "{}"
        },
        "section": [
            "Past episode",
            "Duration of previous symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Feeling {}sad or depressed",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "D-s1b",
                    "optionSummaries": {
                        "c54d1b8b-0596-526f-b8f6-de5dc0c94599": "slightly ",
                        "2b2162a0-5772-55ae-b58a-59e56d4efce0": "moderately ",
                        "ca8b0d74-4946-5acc-b0cd-e3e3d7e056d1": "significantly ",
                        "336ba897-92da-59e3-b1b4-e0961a5f4d3c": "severely "
                    }
                }
            ]
        },
        "section": [
            "Past episode",
            "Psychological symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} self-blame and guilt",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "D-s7b",
                    "optionSummaries": {
                        "dc146517-eeb9-5c0d-989b-34bbc490cac5": "mild",
                        "2007d2d6-9ca7-586e-8d00-abb447cf25ec": "moderate",
                        "6dc93c47-fa27-526d-b1b0-13f68b2694a0": "significant",
                        "22a649e7-dc15-5df2-b14d-49a4242d2b3e": "severe"
                    }
                }
            ]
        },
        "section": [
            "Past episode",
            "Psychological symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "more thoughts about wanting to die {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "D-s9b",
                    "optionSummaries": {
                        "345d5b08-8ff8-5ac1-8dfd-e5ddccf46e07": "to a slight degree",
                        "ec820881-0787-55b3-809a-9e315a692c71": "to a moderate degree",
                        "c3ab02c3-c46e-5bbb-9979-4f78859648f6": "to a significant degree",
                        "39ccf664-77f5-567b-8dab-bd0ea65773db": "to a severe degree"
                    }
                }
            ]
        },
        "section": [
            "Past episode",
            "Psychological symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} difficulty concentrating",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "D-s8b",
                    "optionSummaries": {
                        "92cd2f03-593f-561c-b917-41cff3e75f5d": "mild",
                        "dde090e7-1014-59ed-83c0-520290051cb9": "moderate",
                        "b1f615f2-7715-5802-987a-ad9800e9d95e": "significant",
                        "f964542f-0918-5b30-a95b-2c21760dd32f": "severe"
                    }
                }
            ]
        },
        "section": [
            "Past episode",
            "Psychological symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} lack of enjoyment from people or activities",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "D-s2b",
                    "optionSummaries": {
                        "d87a8238-4b12-585d-8129-90d0efe66975": "mild",
                        "43895a6b-d913-5d5b-8062-8272c7ac4df4": "moderate",
                        "91e92ac3-1821-52ab-af20-5cf8bfb4a244": "significant",
                        "6ecf7641-0acf-5c5f-a316-f8fa2e508487": "severe"
                    }
                }
            ]
        },
        "section": [
            "Past episode",
            "Psychological symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} appetite",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "D-s5b",
                    "optionSummaries": {
                        "c9f267b8-5676-5bbb-b23b-318371d03049": "slightly smaller",
                        "0d362848-ae83-571b-830f-09fe44d67cf3": "significantly smaller",
                        "a63b346b-d724-57a7-9a17-97cc8fb73a1f": "slightly larger",
                        "a9f1cb51-5560-5e6d-b015-2bbd3fc5c26e": "significantly larger"
                    }
                }
            ]
        },
        "section": [
            "Past episode",
            "Somatic symptoms (affecting the body)"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} energy levels",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "D-s3b",
                    "optionSummaries": {
                        "9d328570-f69d-5ae6-a00f-04f4bb5dcf80": "slightly lowered",
                        "beb2df5a-50a1-53c6-9369-1d190c87c8a6": "moderately lowered",
                        "28e38d08-5a5c-5b7f-97cb-7c4a9ca104c2": "significantly lowered",
                        "1172bab4-704b-5872-9ad4-5df29591d824": "severely lowered"
                    }
                }
            ]
        },
        "section": [
            "Past episode",
            "Somatic symptoms (affecting the body)"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} slowed down physically or mentally",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "D-s6b",
                    "optionSummaries": {
                        "8684d25d-9c9b-54d4-bad0-0ebde3c81ab8": "slightly",
                        "9a8bd1cf-691b-54be-a36f-d7502220dfe9": "moderately",
                        "af371f25-c6ed-514c-8a31-a40365406ad9": "significantly",
                        "0a4030ff-1463-5bd1-8413-3d86695edb1c": "severely"
                    }
                }
            ]
        },
        "section": [
            "Past episode",
            "Somatic symptoms (affecting the body)"
        ]
    },
    {
        "answerSummary": {
            "type": "MultiOptionSummary",
            "questionId": "D-s4b",
            "title": "Other related symptoms",
            "overrides": {
                "1b4f0a1b-c3f6-56b6-b730-37820a5c76e4": "struggled to fall asleep",
                "9f80e5f3-7203-5121-88bc-386307a2bbdf": "restless/unsatisfying sleep",
                "487e802c-fc10-5689-92a5-55db2a8e0fcb": "woke up early and struggled to get back to sleep",
                "a3752db8-33f6-502b-8f0a-5b9273ec6af7": "slept too much",
                "8f5db827-1a26-5044-986d-c7e680d8015d": "no sleeping problems"
            }
        },
        "section": [
            "Past episode",
            "Somatic symptoms (affecting the body)"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "D-4.2b",
                    "optionSummaries": {
                        "ea8f9cc3-7893-5067-9525-b0f19a69a80d": "higher intensity",
                        "cd6659ef-65cf-56bc-ad45-10ab5daa99e9": "similar intensity",
                        "6355031b-09c5-5e72-9447-9bd66050cf6b": "lower intensity"
                    }
                }
            ]
        },
        "section": [
            "Past episode",
            "Severity of current symptoms compared to past episode"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Overall level of impairment: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "D-s10b",
                    "optionSummaries": {
                        "355ea31a-711a-5483-88e5-4be548e1c140": "none",
                        "ad4da3b1-1069-5fd1-915d-6c4cfc90698b": "mild",
                        "be7dfb12-dcb8-547d-84f0-2d97652f6e25": "moderate",
                        "d7200a70-99ee-5d88-b8bd-6d0f2eaa5c9e": "significant",
                        "48f470c1-ae06-5aef-b4b7-55b9a5132bd0": "extreme"
                    }
                }
            ]
        },
        "section": [
            "Past episode",
            "Functional impairment"
        ]
    },
    {
        "answerSummary": {
            "type": "MultiOptionSummary",
            "questionId": "D-s11b",
            "title": "Areas of impairment",
            "overrides": {
                "5d650ed9-7135-5c02-944e-d69c6dde1822": "Other (not listed)",
                "30579260-a044-44f7-bb77-37bd5a6a27b3": "None reported"
            }
        },
        "section": [
            "Past episode",
            "Functional impairment"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "D-42b"
            ],
            "prefix": "Time off work: ",
            "formatText": "{}"
        },
        "section": [
            "Past episode",
            "Functional impairment"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} every day",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "D-2bt",
                    "optionSummaries": {
                        "f13b9643-def0-5210-9517-69925aae8ca9": "less than 1 hour",
                        "618070b6-e9e5-599a-a906-eed44f5c2dd9": "1–3 hours",
                        "d585762b-2491-592f-9340-3053d9151b68": "4–6 hours",
                        "90222879-e5d9-50a0-bfbe-a5cbadbff017": "7–9 hours",
                        "315fa694-3bc0-5231-93f2-ceb1d9766403": "10–12 hours",
                        "acdba44c-4419-55d5-891f-5c03c9b52309": "more than 12 hours"
                    }
                }
            ]
        },
        "section": [
            "Typical episode",
            "Duration of symptoms for a typical episode"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "D-3bt",
                    "optionSummaries": {
                        "bb3333bd-b98d-5838-98c8-18c4b7f1ad21": "1 day per week",
                        "0d89dae6-d14a-56da-8409-ea7c21e59375": "2–3 days per week",
                        "1a1865e2-c338-59ef-a2db-6e2cc7a17d77": "4–5 days per week",
                        "b9abc28d-6e22-5b67-bc5e-27431e123974": "6–7 days per week"
                    }
                }
            ]
        },
        "section": [
            "Typical episode",
            "Duration of symptoms for a typical episode"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "D-4bt"
            ],
            "prefix": "duration of typical episode: ",
            "formatText": "{}"
        },
        "section": [
            "Typical episode",
            "Duration of symptoms for a typical episode"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "feeling {}sad or depressed",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "D-s1bt",
                    "optionSummaries": {
                        "33dd907f-3e0e-5735-96bb-c101f0acced9": "slightly ",
                        "4fa2ad77-a998-57d3-ae06-568782e0fb81": "moderately ",
                        "c17482e4-818a-50cd-90fa-1246d037825e": "significantly ",
                        "5ebc2bd4-4b83-5875-b7b0-dd3698a7674c": "severely "
                    }
                }
            ]
        },
        "section": [
            "Typical episode",
            "Psychological symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} self-blame and guilt",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "D-s7bt",
                    "optionSummaries": {
                        "d703f0d6-4b67-5a66-8740-b5e4b3b0eb4d": "mild",
                        "5bc83e58-4276-5742-b3bc-70af9ad8acc6": "moderate",
                        "1df67815-0b9f-56ef-bd48-96ed977db141": "significant",
                        "b0305c3f-5a4a-5a36-9793-cec574da7f4e": "severe"
                    }
                }
            ]
        },
        "section": [
            "Typical episode",
            "Psychological symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "more thoughts about wanting to die {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "D-s9bt",
                    "optionSummaries": {
                        "da503bbd-e567-576e-a21f-e0fad60dccdd": "to a slight degree",
                        "0acc5780-48a5-59ef-95a0-8e73a5ed0fb3": "to a moderate degree",
                        "8d2e7af7-07c2-5459-8865-609f820de278": "to a significant degree",
                        "c9f1ca13-44a9-5bfb-8999-eb1f82965c59": "to a severe degree"
                    }
                }
            ]
        },
        "section": [
            "Typical episode",
            "Psychological symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} difficulty concentrating",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "D-s8bt",
                    "optionSummaries": {
                        "ebfe9c3e-852e-5116-8185-8430f6b361f8": "mild",
                        "b5d48aea-22b2-52a7-9306-3f2a4bcedc6c": "moderate",
                        "6f5067cf-ef4d-59d9-afa1-b3dfc4ac861f": "significant",
                        "1a4dbf6d-5e13-509e-b1f1-1f8aa1072529": "severe"
                    }
                }
            ]
        },
        "section": [
            "Typical episode",
            "Psychological symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} lack of enjoyment from people or activities",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "D-s2bt",
                    "optionSummaries": {
                        "aef7f114-e448-575f-bcbb-035e54001375": "slight",
                        "e074592a-4667-5b65-b713-b8892451b433": "moderate",
                        "b75d06ef-eb6c-5e23-bf8c-9b566fa4d9dd": "significant",
                        "8783a6d5-cd94-5982-8fd6-66ae46e1c6df": "severe"
                    }
                }
            ]
        },
        "section": [
            "Typical episode",
            "Psychological symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} appetite",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "D-s5bt",
                    "optionSummaries": {
                        "d033bb4c-5d3c-519b-be31-1177042bffb4": "slightly smaller",
                        "8260d432-3a96-519e-b824-d24940e4aa65": "significantly smaller",
                        "508d38ea-9336-504a-a8f2-c375a9bcc839": "slightly larger",
                        "055edc25-4e0b-55a7-8739-145992d4156a": "significantly larger"
                    }
                }
            ]
        },
        "section": [
            "Typical episode",
            "Somatic symptoms (affecting the body)"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} energy levels",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "D-s3bt",
                    "optionSummaries": {
                        "11f57889-ac93-53ab-88a7-9aea03a29429": "slightly lowered",
                        "ae77a2d7-a52c-5cdc-8c8d-93e294ade96d": "moderately lowered",
                        "d065c0ca-9e6d-5300-8e3e-688b16f88c59": "very much lowered",
                        "cd0a474c-7177-5f74-9af9-b84553235a64": "severely lowered"
                    }
                }
            ]
        },
        "section": [
            "Typical episode",
            "Somatic symptoms (affecting the body)"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} slowed down physically or mentally",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "D-s6bt",
                    "optionSummaries": {
                        "bb7e3f7e-73b0-56b0-b3d3-e336498509e8": "slightly",
                        "53625e56-4f03-5f54-a340-d5b8cabe24d9": "moderately",
                        "33cda0a0-a5db-5a6c-8603-b5b899edac54": "very",
                        "57e8cb6f-9ce4-5078-acb5-c3f96ce8e463": "severely"
                    }
                }
            ]
        },
        "section": [
            "Typical episode",
            "Somatic symptoms (affecting the body)"
        ]
    },
    {
        "answerSummary": {
            "type": "MultiOptionSummary",
            "questionId": "D-s4bt",
            "title": "Other related symptoms",
            "overrides": {
                "fd9851a0-7ce7-55a8-b257-5617537d0013": "struggled to fall asleep",
                "5a198500-fc4b-55a1-9521-3abe49eed06f": "restless/unsatisfying sleep",
                "a4021497-af94-56a4-8075-392653262e2e": "woke up early and struggled to get back to sleep",
                "27313271-8ec6-5f99-bb2c-0ed50a7c1527": "slept too much",
                "6dd2621e-8242-58f6-8c6c-77df4a9eef53": "no sleeping problems"
            }
        },
        "section": [
            "Typical episode",
            "Somatic symptoms (affecting the body)"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "D-43bt",
                    "optionSummaries": {
                        "644dd944-5a2a-533b-9ef0-fadb5054c422": "the same as their most severe episode",
                        "90e60395-fc3d-56cb-b6f7-2e24fba02803": "less severe than their most severe episode"
                    }
                }
            ]
        },
        "section": [
            "Typical episode",
            "Severity of typical episode"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Overall level of impairment: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "D-s10bt",
                    "optionSummaries": {
                        "3deea42c-5d93-54cd-ba0f-4242654c3747": "no",
                        "ce0e07f8-36b3-5424-a489-abd9de8a21a4": "mild",
                        "da993bd6-c107-53a4-9f6d-09f5a79aff65": "moderate",
                        "e3ab5219-3af6-5da7-8464-a145eb508a0f": "significant",
                        "e7b114de-aed8-536e-8edf-baf8a2a673bf": "extreme"
                    }
                }
            ]
        },
        "section": [
            "Typical episode",
            "Functional impairment"
        ]
    },
    {
        "answerSummary": {
            "type": "MultiOptionSummary",
            "questionId": "D-s11bt",
            "title": "Areas of impairment",
            "overrides": {
                "049f46ae-b7bd-5a1e-8c63-351f481236ec": "Other (not listed)",
                "8713d313-ed86-4beb-a5c7-6834f473fa76": "None reported"
            }
        },
        "section": [
            "Typical episode",
            "Functional impairment"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "D-42bt"
            ],
            "prefix": "Time off work: ",
            "formatText": "{}"
        },
        "section": [
            "Typical episode",
            "Functional impairment"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} per day",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "D-2bs",
                    "optionSummaries": {
                        "abce3dbd-8dae-59b7-bdf7-e2d73f29950f": "less than 1 hour",
                        "71e4fa61-0420-5a47-8362-38d1db6b5895": "1–3 hours",
                        "4ce86a86-3b04-5a80-b4dd-4069fb04a3ba": "4–6 hours",
                        "d9fc40d3-aa51-587a-a54d-305ad26f67ae": "7–9 hours",
                        "bbd43ce6-ea63-5321-9d05-0bbf9a19513b": "10–12 hours",
                        "7caa4281-408a-5c83-b2b0-b42968a69322": "more than 12 hours"
                    }
                }
            ]
        },
        "section": [
            "Most severe episode",
            "Duration of symptoms for most severe past episode"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "D-3bs",
                    "optionSummaries": {
                        "3b3dc1ea-6b04-582a-9c9e-a91540c64f49": "1 day per week",
                        "fe554f25-dfd7-5062-a2b8-f2662e2c3466": "2–3 days per week",
                        "664c5f59-06d3-53a2-81e0-eaf62108625d": "4–5 days per week",
                        "c4422e8a-6a0a-51ea-8592-9157036922d1": "6–7 days per week"
                    }
                }
            ]
        },
        "section": [
            "Most severe episode",
            "Duration of symptoms for most severe past episode"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "D-4bs"
            ],
            "prefix": "for ",
            "formatText": "{}"
        },
        "section": [
            "Most severe episode",
            "Duration of symptoms for most severe past episode"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} per day",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "D-1bl",
                    "optionSummaries": {
                        "e5483ce2-0d51-5c55-9057-f67518b6b285": "less than 1 hour",
                        "83937123-de06-5b20-a479-c5e3e92848c4": "1–3 hours",
                        "87ef50c9-ba5e-513e-8889-09d8395d4b9f": "4–6 hours",
                        "025e1b4a-d67a-517b-a8fc-1501ee4a6b38": "7–9 hours",
                        "5da400b0-5bff-5744-a664-4305892ae669": "10–12 hours",
                        "4105c26a-4ff2-5fd7-984d-f0bcb7b5656e": "more than 12 hours"
                    }
                }
            ]
        },
        "section": [
            "Longest episode",
            "Duration of symptoms for longest episode"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "D-2bl",
                    "optionSummaries": {
                        "e672828b-b5fe-5d7d-b468-c1ef4ef87a04": "1 day per week",
                        "02464c9b-d1d8-5f60-993d-5f37cbfcc102": "2–3 days per week",
                        "78c1434b-e585-5f1d-b291-a1728f789022": "4–5 days per week",
                        "2a8c96e2-cb5b-596a-99b6-a4885253776c": "6–7 days per week"
                    }
                }
            ]
        },
        "section": [
            "Longest episode",
            "Duration of symptoms for longest episode"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "D-3bl"
            ],
            "prefix": "for ",
            "formatText": "{}"
        },
        "section": [
            "Longest episode",
            "Duration of symptoms for longest episode"
        ]
    }
];
