import { Button, Page, Heading, Input } from '@psyomics/components'
import Validation from 'registration/components/Validation'
import React, { useContext, useRef } from 'react'
import Styles from './Demo.module.scss'
import DemoHeader from 'ui/app/components/DemoHeader'
import { useMixpanelEvent } from 'ui/app/useMixpanelEvent'
import { MixpanelContext } from 'registration/context/mixpanel/mixpanel-context'
import { Splash } from 'ui/app/pages/Splash'

const DemoCode: React.FC<{ codeHandler: (code: string) => void; codeError: string | undefined; loading?: boolean }> = ({
    codeHandler,
    codeError,
    loading,
}) => {
    const inputRef = useRef<null | HTMLInputElement>(null)
    const mixpanelApiUrl = useContext(MixpanelContext)
    const { usedDemoAccessCode } = useMixpanelEvent(mixpanelApiUrl)

    return (
        <>
            {loading ? (
                <Splash></Splash>
            ) : (
                <Page
                    style={{ backgroundColor: 'transparent' }}
                    layout="standard"
                    width="narrow"
                    header={<DemoHeader navButtons={false} />}
                >
                    <Heading el="h2" size="heading2" color="mid" css={{ mb: 2 }}>
                        Please enter your Censeo access code
                    </Heading>

                    <span ref={inputRef}>
                        <Input name={'code'} />
                    </span>

                    <div className={Styles.btnWrapper}>
                        <Button
                            className="text-right"
                            appearance="primary"
                            rounded
                            size="large"
                            label="Submit"
                            onClick={() => {
                                const input = inputRef.current?.querySelector('input')
                                if (typeof input?.value === 'string') {
                                    usedDemoAccessCode(input?.value)
                                    codeHandler(input?.value)
                                }
                            }}
                        />
                    </div>
                    <div style={{ height: 'var(--s-4)' }}></div>
                    {codeError && (
                        <Validation>
                            <>
                                {codeError}
                                <br />
                                Please contact{' '}
                                <a style={{ color: 'var(--c-teal-mid)' }} href="mailto:support@censeo.co.uk">
                                    support@censeo.co.uk
                                </a>{' '}
                                for assistance.
                            </>
                        </Validation>
                    )}
                </Page>
            )}
        </>
    )
}

export default DemoCode
