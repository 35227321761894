import React from 'react'
import { Button, Modal } from '@psyomics/components'
import usePortal from 'react-cool-portal'
import CrisisContent from 'ui/app/components/CrisisContent'

interface IHelpModalProps {
    trigger?: React.ReactElement
    label?: string
}

type HelpModalComponentProps = IHelpModalProps & React.ComponentPropsWithoutRef<'div'>

const HelpModal: React.FC<HelpModalComponentProps> = ({ trigger, label }) => {
    const { Portal, isShow, show, hide } = usePortal({
        defaultShow: false,
    })

    const Trigger = trigger ? (
        React.cloneElement(trigger, {
            onClick: show,
        })
    ) : (
        <Button
            appearance="link"
            size="large"
            icon="crisis"
            onClick={show}
            label={label}
            aria-label="Show crisis information"
        />
    )

    return (
        <>
            {Trigger}
            {isShow && (
                <Portal>
                    <Modal header="In an emergency" onClose={hide}>
                        <CrisisContent />
                    </Modal>
                </Portal>
            )}
        </>
    )
}

export default HelpModal
