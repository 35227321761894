import posthog, { CaptureOptions, Properties } from 'posthog-js'

export const posthogCapture = (
    proxyDomain: string | undefined,
    eventName: string,
    properties?: Properties | null | undefined,
    options?: CaptureOptions | undefined
): void => {
    if (proxyDomain) {
        posthog.capture(eventName, properties, options)
    }
}
