"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.symptoms = void 0;
exports.symptoms = [
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "AU-1",
                    "optionSummaries": {
                        "8dec39a1-5dfe-53fe-99d1-752e776bbcbc": "Never drinks alcohol",
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0": "drinks once a month or less",
                        "d5bb65be-f7eb-568b-93cf-fff92621ccb2": "drinks 2–4 times a month",
                        "b1a86447-08b7-5e7d-a3d5-015e31be0840": "drinks 2–3 times a week",
                        "0b3d129d-c4cd-5adc-a09a-5db81ba0b7b9": "drinks 4 or more times a week"
                    }
                }
            ]
        },
        "section": [
            "Current episode (in the last year)",
            "Alcohol Use Disorder Identification Test (AUDIT) criteria"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "drinks {} units in a typical drinking session",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "AU-2.1",
                    "optionSummaries": {
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e": "0–2",
                        "eea8763e-5c32-5ae4-84d0-91d5de001887": "3–4",
                        "fd2be360-9f0c-51a5-a13f-acebafdf17e8": "5–6",
                        "2214df09-874e-5dae-bdfc-0af7cb9ccc54": "7–9",
                        "28265f82-928c-5c60-be2c-00d6ebfe1285": "10+"
                    }
                }
            ]
        },
        "section": [
            "Current episode (in the last year)",
            "Alcohol Use Disorder Identification Test (AUDIT) criteria"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "AU-3",
                    "optionSummaries": {
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983": "no binge drinking in the past year",
                        "adadb641-a888-5f52-8c2d-a33d52f437ff": "binge drinks less than monthly",
                        "6835adaf-f7b6-5ec3-9027-f771cabd0b98": "binge drinks once a month",
                        "f74522b1-9434-5e73-a272-84ca91bd2e84": "binge drinks once a week",
                        "337596cc-8422-5c6f-8e9d-6b93c0be0208": "binge drinks daily"
                    }
                }
            ]
        },
        "section": [
            "Current episode (in the last year)",
            "Alcohol Use Disorder Identification Test (AUDIT) criteria"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "unable to stop drinking after starting: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "AU-5",
                    "optionSummaries": {
                        "1dc8a1e4-8441-58c6-a0d6-7201676de0c1": "less than once a month",
                        "251c8b46-84a4-5031-83a2-94569d2f7d9a": "monthly",
                        "9ac9db10-6204-521a-b600-d7c2cef3fbab": "weekly",
                        "7b22fec7-e9a6-51fa-a0f7-4a08ffd60960": "daily"
                    }
                }
            ]
        },
        "section": [
            "Current episode (in the last year)",
            "Alcohol Use Disorder Identification Test (AUDIT) criteria"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "doesn't fulfil obligations due to drinking: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "AU-6",
                    "optionSummaries": {
                        "abfc1a2d-bdb1-5553-b79f-f61fff82e345": "less than once a month",
                        "2c0a41c0-5540-5b5f-9bc9-4e70349b1f0b": "monthly",
                        "59a42152-366d-5508-a888-9629c60e91fc": "weekly",
                        "2d7c790a-161a-5001-b269-b70e430a1459": "daily"
                    }
                }
            ]
        },
        "section": [
            "Current episode (in the last year)",
            "Alcohol Use Disorder Identification Test (AUDIT) criteria"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "drinking in the morning when hungover: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "AU-7",
                    "optionSummaries": {
                        "b6739f38-a943-5c85-aa41-205d88f3fec3": "less than monthly",
                        "73c56e21-cb1e-5967-b9aa-9afcddc6fd2e": "once a month",
                        "f6ed9e50-beef-54b5-bd8b-f00b3eecd6dd": "weekly",
                        "bc75d147-4107-5d17-8a9f-a0bf805daa5f": "every day"
                    }
                }
            ]
        },
        "section": [
            "Current episode (in the last year)",
            "Alcohol Use Disorder Identification Test (AUDIT) criteria"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "feels guilt or remorse after drinking: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "AU-8",
                    "optionSummaries": {
                        "fff7d9c5-1abe-5faa-b26e-712c34570801": "less than monthly",
                        "436aea9d-a160-5e1e-86eb-194137fd2a53": "once a month",
                        "1b63a95c-ea92-58a6-8e8b-54d5bc42d76d": "once a week",
                        "77f82166-32a0-546f-b62a-af0e32e83986": "every day"
                    }
                }
            ]
        },
        "section": [
            "Current episode (in the last year)",
            "Alcohol Use Disorder Identification Test (AUDIT) criteria"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "memory loss or blackouts due to drinking: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "AU-9",
                    "optionSummaries": {
                        "e551f0fb-d55f-5f69-9728-989451dcf97f": "less than monthly",
                        "9dbc0af9-57ce-5a39-a2f4-10bf717ecfca": "once a month",
                        "2932b119-b5a7-5478-a243-b880d0865081": "once a week",
                        "30b1f600-8ea0-57a1-83d5-c5b16fec6340": "every day"
                    }
                }
            ]
        },
        "section": [
            "Current episode (in the last year)",
            "Alcohol Use Disorder Identification Test (AUDIT) criteria"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "has injured themselves or someone else when drinking: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "AU-10",
                    "optionSummaries": {
                        "f100da78-6b18-5222-8771-521684105f95": "not in the last year",
                        "a3963f2f-1a9d-5c11-bf4e-e1c7cfdb8cea": "in the last year"
                    }
                }
            ]
        },
        "section": [
            "Current episode (in the last year)",
            "Alcohol Use Disorder Identification Test (AUDIT) criteria"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "others have expressed concern about drinking: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "AU-11",
                    "optionSummaries": {
                        "705dc095-654b-5eb2-a806-1abc7edd31a0": "not in the last year",
                        "61613604-abe9-5fb9-97a6-faa874f7b9cc": "in the last year"
                    }
                }
            ]
        },
        "section": [
            "Current episode (in the last year)",
            "Alcohol Use Disorder Identification Test (AUDIT) criteria"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "drinking has caused physical/mental health damage",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "AU-21",
                    "optionSummaries": {
                        "93f9aeb7-77ef-5172-a965-d21a8be006cd": ""
                    }
                }
            ]
        },
        "section": [
            "Current episode (in the last year)",
            "Other dependency symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "has serious interpersonal problems due to drinking",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "AU-12",
                    "optionSummaries": {
                        "2a74eb35-54b2-5648-8089-4a8c02933778": "",
                        "c2e10e2d-0c13-55ac-a903-16aa35eaecef": ""
                    }
                }
            ]
        },
        "section": [
            {
                "conditionalTitle": {
                    "questionId": "AU-12",
                    "possibleTexts": [
                        {
                            "text": "Current episode (in the last year)",
                            "optionIds": [
                                "44d6a11a-144b-56f5-963f-c6e4e5985cd8",
                                "c2e10e2d-0c13-55ac-a903-16aa35eaecef"
                            ]
                        },
                        {
                            "text": "Previous episode",
                            "optionIds": [
                                "2a74eb35-54b2-5648-8089-4a8c02933778"
                            ]
                        }
                    ]
                },
                "altTitle": "Current episode (in the last year)"
            },
            "Other dependency symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "has been in serious trouble due to drinking",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "AU-17",
                    "optionSummaries": {
                        "6647082b-0e8d-5835-80ad-7dca26d35c9f": "",
                        "ddb8ca69-afbe-580f-b616-aa5987f59dc3": ""
                    }
                }
            ]
        },
        "section": [
            {
                "conditionalTitle": {
                    "questionId": "AU-17",
                    "possibleTexts": [
                        {
                            "text": "Current episode (in the last year)",
                            "optionIds": [
                                "1281438f-eb00-583a-aba5-1645f3104665",
                                "ddb8ca69-afbe-580f-b616-aa5987f59dc3"
                            ]
                        },
                        {
                            "text": "Previous episode",
                            "optionIds": [
                                "6647082b-0e8d-5835-80ad-7dca26d35c9f"
                            ]
                        }
                    ]
                },
                "altTitle": "Current episode (in the last year)"
            },
            "Other dependency symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "has injured themselves or someone else when drinking",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "AU-10",
                    "optionSummaries": {
                        "f100da78-6b18-5222-8771-521684105f95": "",
                        "a3963f2f-1a9d-5c11-bf4e-e1c7cfdb8cea": ""
                    }
                }
            ]
        },
        "section": [
            {
                "conditionalTitle": {
                    "questionId": "AU-10",
                    "possibleTexts": [
                        {
                            "text": "Current episode (in the last year)",
                            "optionIds": [
                                "9d796df4-e587-5bff-bb07-00d6c098a8f4",
                                "a3963f2f-1a9d-5c11-bf4e-e1c7cfdb8cea"
                            ]
                        },
                        {
                            "text": "Previous episode",
                            "optionIds": [
                                "f100da78-6b18-5222-8771-521684105f95"
                            ]
                        }
                    ]
                },
                "altTitle": "Current episode (in the last year)"
            },
            "Other dependency symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "developed tolerance",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "AU-14",
                    "optionSummaries": {
                        "74d81b8d-4bdf-55b0-bc70-67b589b78a95": "",
                        "b5bcc60d-e298-5621-8c59-f788672ef828": ""
                    }
                }
            ]
        },
        "section": [
            {
                "conditionalTitle": {
                    "questionId": "AU-14",
                    "possibleTexts": [
                        {
                            "text": "Current episode (in the last year)",
                            "optionIds": [
                                "111f6379-4749-5adc-828d-88a4f35ba226",
                                "b5bcc60d-e298-5621-8c59-f788672ef828"
                            ]
                        },
                        {
                            "text": "Previous episode",
                            "optionIds": [
                                "74d81b8d-4bdf-55b0-bc70-67b589b78a95"
                            ]
                        }
                    ]
                },
                "altTitle": "Current episode (in the last year)"
            },
            "Other dependency symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "has drunk first thing in the morning",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "AU-18",
                    "optionSummaries": {
                        "f470bbf0-ca10-5e97-9557-3dc05f6bea1d": "",
                        "0b4586e1-706f-5d61-b912-b0934dcb5341": ""
                    }
                }
            ]
        },
        "section": [
            {
                "conditionalTitle": {
                    "questionId": "AU-18",
                    "possibleTexts": [
                        {
                            "text": "Current episode (in the last year)",
                            "optionIds": [
                                "c85d230a-988f-5850-a03d-0e1a877650da",
                                "0b4586e1-706f-5d61-b912-b0934dcb5341"
                            ]
                        },
                        {
                            "text": "Previous episode",
                            "optionIds": [
                                "f470bbf0-ca10-5e97-9557-3dc05f6bea1d"
                            ]
                        }
                    ]
                },
                "altTitle": "Current episode (in the last year)"
            },
            "Other dependency symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "has experienced withdrawal",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "AU-15",
                    "optionSummaries": {
                        "5672e829-525c-5e5b-a8d4-46634023977b": "",
                        "978ea017-853e-52cd-87d5-32f93372bce3": ""
                    }
                }
            ]
        },
        "section": [
            {
                "conditionalTitle": {
                    "questionId": "AU-15",
                    "possibleTexts": [
                        {
                            "text": "Current episode (in the last year)",
                            "optionIds": [
                                "349b824f-b485-5672-8148-d3783d5b2392",
                                "978ea017-853e-52cd-87d5-32f93372bce3"
                            ]
                        },
                        {
                            "text": "Previous episode",
                            "optionIds": [
                                "5672e829-525c-5e5b-a8d4-46634023977b"
                            ]
                        }
                    ]
                },
                "altTitle": "Current episode (in the last year)"
            },
            "Other dependency symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "spent an excessive amount of time on alcohol-related activities",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "AU-16",
                    "optionSummaries": {
                        "b9593781-a11a-5660-9bda-a02059817589": "",
                        "8d4c54c5-18d3-5813-9b02-01d220fe06a7": ""
                    }
                }
            ]
        },
        "section": [
            {
                "conditionalTitle": {
                    "questionId": "AU-16",
                    "possibleTexts": [
                        {
                            "text": "Current episode (in the last year)",
                            "optionIds": [
                                "b36eef07-a5d5-5596-9d6c-f86d8267aeee",
                                "8d4c54c5-18d3-5813-9b02-01d220fe06a7"
                            ]
                        },
                        {
                            "text": "Previous episode",
                            "optionIds": [
                                "b9593781-a11a-5660-9bda-a02059817589"
                            ]
                        }
                    ]
                },
                "altTitle": "Current episode (in the last year)"
            },
            "Other dependency symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "strong desire to drink alcohol",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "AU-19",
                    "optionSummaries": {
                        "4f7598ed-d960-5a23-9255-544dab80fe9d": "",
                        "0052107c-edfc-5abf-b8e2-22d833c18454": ""
                    }
                }
            ]
        },
        "section": [
            {
                "conditionalTitle": {
                    "questionId": "AU-19",
                    "possibleTexts": [
                        {
                            "text": "Current episode (in the last year)",
                            "optionIds": [
                                "39d41b2f-b56f-5f1f-a9b1-16200d417b6d",
                                "0052107c-edfc-5abf-b8e2-22d833c18454"
                            ]
                        },
                        {
                            "text": "Previous episode",
                            "optionIds": [
                                "4f7598ed-d960-5a23-9255-544dab80fe9d"
                            ]
                        }
                    ]
                },
                "altTitle": "Current episode (in the last year)"
            },
            "Other dependency symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "hasn't been able to cut down drinking",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "AU-13",
                    "optionSummaries": {
                        "d78a1a3f-f493-555e-9776-41dc70e2f63b": "",
                        "31663734-0b9f-53d0-ac93-ab227b6a43d1": ""
                    }
                }
            ]
        },
        "section": [
            {
                "conditionalTitle": {
                    "questionId": "AU-13",
                    "possibleTexts": [
                        {
                            "text": "Current episode (in the last year)",
                            "optionIds": [
                                "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8",
                                "31663734-0b9f-53d0-ac93-ab227b6a43d1"
                            ]
                        },
                        {
                            "text": "Previous episode",
                            "optionIds": [
                                "d78a1a3f-f493-555e-9776-41dc70e2f63b"
                            ]
                        }
                    ]
                },
                "altTitle": "Current episode (in the last year)"
            },
            "Other dependency symptoms"
        ]
    }
];
