import { gql } from '@apollo/client'
import { DocumentNode } from 'graphql'
import { Next } from 'censeo-core'

export const answerMutation: DocumentNode = gql`
    mutation Answer($answer: AnsweredQuestion!) {
        answer(answer: $answer) {
            ... on NextQuestion {
                id
                __typename
            }
            ... on InvalidReferral {
                status
                __typename
            }
        }
    }
`

export const demoAnswerMutation: DocumentNode = gql`
    mutation DemoAnswer($allAnswers: [AnsweredQuestion!]!) {
        demoAnswer(allAnswers: $allAnswers) {
            ... on NextQuestion {
                id
                __typename
            }
        }
    }
`

export interface StandardAnswerResponse {
    answer: Next
}
export interface DemoAnswerResponse {
    demoAnswer: Next
}

export function isDemoAnswerResponse(response: AnswerResponse): response is DemoAnswerResponse {
    return 'demoAnswer' in response
}

export type AnswerResponse = StandardAnswerResponse | DemoAnswerResponse
