import { gql } from '@apollo/client'
import { DocumentNode } from 'graphql'

export const updateUserProfileMutation: DocumentNode = gql`
    mutation UpdateUserProfile($input: UpdateUserProfileInput!) {
        updateUserProfile(input: $input)
    }
`

export interface UpdateUserProfileInput {
    userId: string
    referralPublicId?: string
    firstName?: string
    lastName?: string
    email?: string
    birthDate?: string
    gender?: string
    sex?: string
    ethnicity?: string
    postalCode?: string
    termsAcceptedAt?: string
}

export interface UpdateUserProfileResponse {
    updateUserProfile: boolean
}
