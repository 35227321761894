"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.symptoms = void 0;
exports.symptoms = [
    {
        "answerSummary": {
            "type": "CombinedAnswersSummary",
            "questionsIdGroups": [
                [
                    "S-s4"
                ]
            ],
            "groupsType": "Max",
            "pattern": "{}current suicidal thoughts",
            "coefficients": {
                "0": "no ",
                "1": ""
            }
        },
        "section": [
            "Current period",
            "Ideation"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "thoughts are {} troubling",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "S-2a",
                    "optionSummaries": {
                        "5cd8930b-afc1-5d1e-9ba3-97c0036809b5": "not",
                        "fccc47c0-713b-578c-a123-73b81b4d186d": "slightly",
                        "51f2de30-69cd-5a83-85a4-2e3310861a6f": "moderately",
                        "541369ff-8077-58e5-8bf7-90a8a94d14bb": "very",
                        "84b1dac4-ea2f-5f51-a205-0efe9d0e6c8c": "extremely"
                    }
                }
            ]
        },
        "section": [
            "Current period",
            "Ideation"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} of future suicide attempt",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "S-8a",
                    "optionSummaries": {
                        "cb6c8c06-d0b8-558d-8e33-bf475718cb0d": "no plan",
                        "93743584-8c8b-5a15-afa2-95a24c811beb": "beginning planning",
                        "4f14f047-a4d6-5709-95b7-4742bec76fa7": "vague planning",
                        "fb782e60-165b-5cd2-ae70-c40b281bc387": "full planning"
                    }
                }
            ]
        },
        "section": [
            "Current period",
            "Planning"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} likely to act on thoughts",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "S-6a",
                    "optionSummaries": {
                        "c6b26b20-786e-537b-89bc-71c4c3155678": "not at all",
                        "c0db0893-6906-5a21-ab74-144c85c0683d": "slightly",
                        "0667075d-d44a-5ec5-8a05-4d25659c831d": "moderately",
                        "870c5cac-fb60-51ca-9d26-bf9e93d56ab2": "very",
                        "0181dcdd-3d3d-512a-bdf8-de8a996bd7e8": "extremely"
                    }
                }
            ]
        },
        "section": [
            "Current period",
            "Intent"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} to keep themselves safe",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "S-18a",
                    "optionSummaries": {
                        "890e7c6f-4bdf-5d6c-87e4-d2fc93a63102": "not able",
                        "e8bbf23a-7af1-5ec5-a666-e81bbeed6043": "able",
                        "81b3f713-07b4-5d30-8066-60c1326c2aa1": "unsure if able"
                    }
                }
            ]
        },
        "section": [
            "Current period",
            "Risk assessment"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "S-1a"
            ],
            "prefix": "",
            "formatText": "{}"
        },
        "section": [
            "Current period",
            "Duration of current period"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "S-22a",
                    "optionSummaries": {
                        "28660364-460b-5f7f-a281-104044331d08": "does not have anyone to talk to",
                        "c5321fad-d3b3-58d2-979b-11ca81e25dbf": "has someone to talk to but would not contact them",
                        "f18f48b0-a401-5787-bb00-ca8d99523304": "has someone to talk to and would contact them",
                        "9bce9d08-d129-5989-8b7b-5612590dc430": "has someone to talk to and has contacted them"
                    }
                }
            ]
        },
        "section": [
            "Current period",
            "Social support"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} triggering event",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "S-5a",
                    "optionSummaries": {
                        "0e07d0fe-f085-5440-b0f4-a5c8345833d3": "no recent",
                        "86839467-8345-58f0-9355-a14ba91c7079": "recent"
                    }
                }
            ]
        },
        "section": [
            "Current period",
            "Other details"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "S-4a",
                    "optionSummaries": {
                        "1ffa1a8c-d1e3-54ab-9551-deb6e599c67f": "does not feel like a burden",
                        "dada9607-7e9d-565b-8073-73b780535bbe": "slightly feels like a burden",
                        "5627de59-f614-56c2-887e-4c153fb61012": "moderately feels like a burden",
                        "131ae75d-c65c-5204-9e13-603a7cd9123d": "very much feels like a burden",
                        "2ad36ca1-d48c-57c8-a058-9de92c14e840": "feels like a burden to an extreme extent"
                    }
                }
            ]
        },
        "section": [
            "Current period",
            "Other details"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "S-15a"
            ],
            "prefix": "Previous suicide attempt in the last 12 months: ",
            "formatText": "{}"
        },
        "section": [
            "Past period(s)"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{}to take own life",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "S-13a",
                    "optionSummaries": {
                        "8e2bdd16-a97a-5130-8f6e-edb8dd52afe3": "Did not previously try ",
                        "fadcf643-dd8c-5f24-90d5-f971cf3c6ec7": "Previously tried "
                    }
                }
            ]
        },
        "section": [
            "Past period(s)"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "S-14a"
            ],
            "prefix": "Last attempt: ",
            "formatText": "{} ago"
        },
        "section": [
            "Past period(s)"
        ]
    },
    {
        "answerSummary": {
            "type": "CombinedAnswersSummary",
            "questionsIdGroups": [
                [
                    "S-s1"
                ],
                [
                    "S-s2"
                ],
                [
                    "S-s3"
                ]
            ],
            "groupsType": "Max",
            "pattern": "{}previous periods of suicidal thoughts",
            "coefficients": {
                "0": "no ",
                "0.25": ""
            }
        },
        "section": [
            "Past period(s)"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} hospitalisation after last attempt",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "S-17a",
                    "optionSummaries": {
                        "56bb1b4f-6a67-505a-980b-b4b9bb1792e3": "Did not need",
                        "7bd78121-d1a4-5bd7-8be2-eefa7e976604": "Did not need",
                        "efb070b8-83e6-535e-bf20-76b25b83a770": "Needed"
                    }
                }
            ]
        },
        "section": [
            "Past period(s)"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "S-3a"
            ],
            "prefix": "Previous periods of suicidal thoughts: ",
            "formatText": "{}"
        },
        "section": [
            "Past period(s)"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SH-16a",
                    "optionSummaries": {
                        "372a1173-b5c2-502f-9237-df57eebd80f2": "never received medical assistance for self-harm",
                        "f3060ab6-8ac7-559d-838c-a58eeb5d018e": "received medical assistance for self-harm but was not hospitalised",
                        "19c53256-c3a5-5f7a-8020-fffb42c83733": "received medical assistance for self-harm and was hospitalised"
                    }
                },
                {
                    "questionId": "SH-16b",
                    "optionSummaries": {
                        "7feeabee-bfe8-56cd-b16c-fd4199552577": "never received medical assistance for self-harm",
                        "db90d397-9d20-53d9-86ee-b1290cf5610f": "received medical assistance for self-harm but was not hospitalised",
                        "4c4f089f-452e-5a64-ae76-c6aa84efe4eb": "received medical assistance for self-harm and was hospitalised"
                    }
                }
            ]
        },
        "section": [
            "Medical assistance and hospitalisation"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "SH-s2"
            ],
            "prefix": "Age at time of first self-harm incident: ",
            "formatText": "{}"
        },
        "section": [
            "Frequency of self-harm behaviours"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Last self-harmed: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SH-2a",
                    "optionSummaries": {
                        "4992447e-a229-5af8-ba74-0d205735ec32": "within the last day",
                        "35d92f8a-6922-5810-a862-81370a7bb8a5": "a couple of days ago",
                        "b1efc312-9d2e-5ca8-88ba-b4b2392d1bc8": "a week ago",
                        "c81ca000-63f4-5d4d-b175-ed7c74561574": "several weeks ago",
                        "5d85eb1d-b45b-51cb-9405-1a914df66de6": "a month ago",
                        "fe25eeda-81fa-58a3-9c72-545aabe803f5": "several months ago"
                    }
                }
            ]
        },
        "section": [
            "Frequency of self-harm behaviours"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "SH-2b"
            ],
            "prefix": "",
            "formatText": "Last self-harmed: {} ago"
        },
        "section": [
            "Frequency of self-harm behaviours"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} in the last year",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SH-s3",
                    "optionSummaries": {
                        "e2e1bae1-c85a-5ab1-9b76-10e31f65a40e": "Has not self-harmed or had the urge to",
                        "29309292-72fb-5781-ae9a-973977aac3af": "Self-harmed or had the urge to"
                    }
                }
            ]
        },
        "section": [
            "Frequency of self-harm behaviours"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "SH-1a"
            ],
            "prefix": "Has self-harmed ",
            "formatText": "{} time(s) in the last year"
        },
        "section": [
            "Frequency of self-harm behaviours"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Self-harmed {} during the most distressing time",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SH-3b",
                    "optionSummaries": {
                        "6398c0aa-f204-57b5-b75c-e16d73e69cc3": "less than once a year",
                        "8a529b74-7040-57bc-aed3-154d0b92c82f": "several times a year",
                        "11ac7b73-fd3c-5d41-8801-855cb0f008b3": "several times a month",
                        "7083624e-4e8c-5008-81e4-0a56589a2081": "once per week",
                        "bc84679c-383a-5378-bd6b-3c8ed392c8fe": "several times per week",
                        "6d7b7291-09f2-56fe-8574-5b274c2c4c28": "several times per day"
                    }
                }
            ]
        },
        "section": [
            "Frequency of self-harm behaviours"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "SH-4a"
            ],
            "prefix": "Longest interval without self-harm: ",
            "formatText": "{}"
        },
        "section": [
            "Frequency of self-harm behaviours"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} self-harmed or had the urge to",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SH-s1",
                    "optionSummaries": {
                        "519336e4-f9db-552e-a464-11ea704d6288": "never",
                        "981f1af5-5769-5932-8778-58a6e01aa624": ""
                    }
                }
            ]
        },
        "section": [
            "Self-harm details"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} likely to self-harm again",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SH-23a",
                    "optionSummaries": {
                        "e5fa1386-2290-5c3f-990e-962ef0b4f10c": "not at all",
                        "3e347ed1-7ea3-5b21-964f-32cf2d32e1b5": "slightly",
                        "5738b269-a761-5967-a3a9-e5861936cc74": "moderately",
                        "025fa831-b50c-51c6-8ece-7f851af56e4f": "very",
                        "83e881fd-401e-5ce9-9121-1472f92adb82": "extremely"
                    }
                },
                {
                    "questionId": "SH-23b",
                    "optionSummaries": {
                        "a5170112-22fd-5d89-96da-1f1d9abd6a28": "not at all",
                        "fe7c8387-4f0e-5e3f-a41c-9789da592eae": "slightly",
                        "47b9e301-af3c-5127-8396-1153c6696909": "moderately",
                        "acaeb2ca-33f6-5651-aa86-2de5e3de9d9d": "very",
                        "b3418704-a5d7-5927-87f8-23ae8b5ab663": "extremely"
                    }
                }
            ]
        },
        "section": [
            "Self-harm details"
        ]
    },
    {
        "answerSummary": {
            "type": "MultiOptionSummary",
            "questionId": "SH-6a",
            "title": "Method(s) of self-harm",
            "overrides": {
                "f67da8e8-7a80-5726-895d-710472c17bc0": "Other (not listed)"
            }
        },
        "section": [
            "Self-harm details"
        ]
    }
];
