import React, { useState, useEffect, useContext } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import PatientIntro from './PatientIntro'
import DemoHome from './DemoHome'
import NotFound from 'ui/app/pages/NotFound'
import Stats from './Stats'
import ROI from './ROI'
import DemoCode from './DemoCode'
import { InfoContext } from 'configure'
import { getCookie, setCookie } from 'registration/utils/cookies'
import * as Sentry from '@sentry/react'
import { PendoProvider } from 'ui/app/context/pendo'
import { DemoExperience, useDemoContext } from './DemoContext'
import DemoClinicalReports from './ClinicalReports'
import DemoAssessment from './DemoAssessment'
import { ApolloProvider } from '@apollo/client'
import { client } from 'data/GraphQLClient'
import DemoDashboard from './DemoDashboard'
import { LoadReportV2 } from 'ui/app/pages/LoadReportV2'

type DemoRouterProps = React.ComponentPropsWithoutRef<'div'>

const DemoRoutes: React.FC<DemoRouterProps> = () => {
    const { setDemo, setDemoExperienceType, demoExperienceType } = useDemoContext()
    const location = useLocation()
    const info = useContext(InfoContext)
    const [demoType, setDemoType] = useState<'SHORT' | 'LONG'>('SHORT')
    const [isValid, setIsValid] = useState<boolean | null>(null)
    const [codeError, setCodeError] = useState<string | undefined>(undefined)
    const cookieName = 'demoAccessCode'

    useEffect(() => {
        setDemo(true)
        return () => {
            setDemo(false)
        }
    }, [setDemo])

    useEffect(() => {
        if (location.pathname.startsWith('/demo/healthcare')) {
            setDemoExperienceType(DemoExperience.Clinician)
        }
        if (location.pathname.startsWith('/demo/service-user')) {
            setDemoExperienceType(DemoExperience.ServiceUser)
        }
    }, [location.pathname])

    const codeHandler = async (code: string, validate = true) => {
        const response = await fetch(`${info?.referralApiUrl}referrals/unauthenticated?demoCode=${code}`)
        const { type, locale } = await response.json()
        if (response.status === 200) {
            const daysToExpire = 28
            setCookie(cookieName, code, daysToExpire)
            setCookie('demoType', type, daysToExpire)
            setCookie('demoLocale', locale, daysToExpire)
            setIsValid(true)
            setDemoType(type)
        } else {
            setIsValid(false)
            if (validate) {
                if (response.status === 403) {
                    setCodeError('Invalid code.')
                } else {
                    Sentry.captureException(
                        `Error getting demoCode response - status ${response.status} ${response.statusText} code: ${code}`
                    )
                    setCodeError('There has been an error please contact support.')
                }
            }
        }
    }

    useEffect(() => {
        if (demoExperienceType === DemoExperience.ServiceUser) {
            setIsValid(true)
        } else {
            const demoCode = getCookie(cookieName)
            if (demoCode) {
                codeHandler(demoCode, false)
            } else {
                setIsValid(false)
            }
        }
    }, [demoExperienceType])

    if (!isValid) {
        const loadingDemoCode = isValid === null
        return <DemoCode codeHandler={codeHandler} codeError={codeError} loading={loadingDemoCode} />
    }

    return (
        <PendoProvider demo={true}>
            <ApolloProvider client={client(info?.unauthenticatedApiKey)}>
                <Routes location={location}>
                    <Route path="/service-user/*" element={<DemoAssessment />} />
                    <Route path="/healthcare" element={<DemoDashboard />} />
                    <Route path="/patient" element={<PatientIntro demoType={demoType} />} />
                    <Route path="/healthcare/activity-dashboard" element={<ROI />} />
                    <Route path="/healthcare/population-health" element={<Stats />} />
                    <Route path="/healthcare/clinical-reports" element={<DemoClinicalReports />} />
                    <Route path="/healthcare/report/:id" element={<LoadReportV2 />} />
                    <Route path="/patient" element={<PatientIntro demoType={demoType} />} />
                    <Route path="/service-user/*" element={<DemoAssessment />} />
                    <Route path="/" element={<DemoHome />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </ApolloProvider>
        </PendoProvider>
    )
}

export default DemoRoutes
