import * as Sentry from '@sentry/react'

export const track = async (mixpanelApiUrl: string, eventName: string, event: unknown): Promise<void> => {
    return await makeMixpanelApiCall(mixpanelApiUrl + 'track', { eventName, event })
}

export const setPeople = async (
    mixpanelApiUrl: string,
    userId: string | undefined,
    user: unknown,
    preSignUpUserId: string | undefined
): Promise<void> => {
    return await makeMixpanelApiCall(mixpanelApiUrl + 'setPeople', { userId, user, userIdToAlias: preSignUpUserId })
}

export const makeMixpanelApiCall = async (mixpanelApiUrl: string, payload?: unknown): Promise<void> => {
    if (!mixpanelApiUrl) {
        return
    }

    const options: RequestInit = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
    }

    try {
        const response = await fetch(mixpanelApiUrl, options)
        if (response && !response.ok) {
            const error = new Error(
                `Mixpanel call failed. Status code: ${response.status}. Status text: ${response.statusText}`
            )
            Sentry.captureException(error, {
                tags: { mixpanelApiUrl },
                extra: { payload },
            })
        }
    } catch (err) {
        Sentry.captureException(err, {
            tags: { mixpanelApiUrl },
            extra: { payload },
        })
    }
}
