"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.symptoms = void 0;
exports.symptoms = [
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "PD-s3"
            ],
            "prefix": "Panic attacks in the last six months: ",
            "formatText": "{}"
        },
        "section": [
            "Current episode",
            "Frequency"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "PD-17a"
            ],
            "prefix": "Attacks occur  ",
            "formatText": "{}"
        },
        "section": [
            "Current episode",
            "Frequency"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "PD-1a"
            ],
            "prefix": "Attacks last for ",
            "formatText": "{}"
        },
        "section": [
            "Current episode",
            "Duration of symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "PD-18a"
            ],
            "prefix": "Attacks have been happening for ",
            "formatText": "{}"
        },
        "section": [
            "Current episode",
            "Duration of symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "PD-18.5b"
            ],
            "prefix": "Last attack occurred ",
            "formatText": "{}"
        },
        "section": [
            "Current episode",
            "Duration of symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Attacks {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PD-3a",
                    "optionSummaries": {
                        "21982882-23e9-5a7c-88f0-1a2325372560": "have a clear trigger or only occur in a specific situation",
                        "78684e31-9745-5af1-99f9-1e5796aabe72": "happen out of the blue",
                        "3b1605f2-9652-5958-8059-bd8b7aec2f2d": "have an uncertain trigger"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "External cues"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "pounding heart{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PD-4a",
                    "optionSummaries": {
                        "cd09d547-49fa-5075-942e-c2445a1c9687": ""
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Somatic (affecting the body) and psychological symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "increased heart rate{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PD-4a",
                    "optionSummaries": {
                        "f789ab51-5fa9-537b-96b0-08f8cb294040": ""
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Somatic (affecting the body) and psychological symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "heart fluttering{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PD-4a",
                    "optionSummaries": {
                        "359fd14e-76c8-58e0-a327-b3558bc689f1": ""
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Somatic (affecting the body) and psychological symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "chest pain{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PD-4a",
                    "optionSummaries": {
                        "2a760210-55f9-56c9-8244-8837c46fc869": ""
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Somatic (affecting the body) and psychological symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "excessive sweating{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PD-4a",
                    "optionSummaries": {
                        "09558269-66f2-5c37-9fda-90b76dcda6d4": ""
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Somatic (affecting the body) and psychological symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "trembling/shaking{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PD-4a",
                    "optionSummaries": {
                        "6370bf14-ef56-5636-9d47-6dd92dcd3949": ""
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Somatic (affecting the body) and psychological symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "shortness of breath or smothering{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PD-4a",
                    "optionSummaries": {
                        "207eb06d-5508-5584-bbfb-35842d65a1b1": ""
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Somatic (affecting the body) and psychological symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "choking{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PD-4a",
                    "optionSummaries": {
                        "99d611e9-3a3b-531b-9e34-03622d11036b": ""
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Somatic (affecting the body) and psychological symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "nausea{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PD-4a",
                    "optionSummaries": {
                        "ab3abe29-18fb-50dd-8bef-42e35988e746": ""
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Somatic (affecting the body) and psychological symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "upset stomach{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PD-4a",
                    "optionSummaries": {
                        "fc3d9e34-1469-5c14-8235-b40e01a47e4d": ""
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Somatic (affecting the body) and psychological symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "pounding heart{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PD-4a",
                    "optionSummaries": {
                        "bf141530-681d-5e5e-8740-0c846343b361": ""
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Somatic (affecting the body) and psychological symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "feeling too cold or too hot{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PD-4a",
                    "optionSummaries": {
                        "05c3c9bc-7045-5533-98bb-3335d9815667": ""
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Somatic (affecting the body) and psychological symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "fear of dying{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PD-4a",
                    "optionSummaries": {
                        "9521c7f6-a5f3-57c8-a85d-e0abe74cbe1d": ""
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Somatic (affecting the body) and psychological symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "fear of losing control{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PD-4a",
                    "optionSummaries": {
                        "3bb0e41e-4edd-5d74-86ce-cecf92bbbadb": ""
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Somatic (affecting the body) and psychological symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "numbness or tingling sensations{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PD-4a",
                    "optionSummaries": {
                        "06d84ce6-0739-5fe4-8bc0-44efeae17cc0": ""
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Somatic (affecting the body) and psychological symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "derealisation{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PD-4a",
                    "optionSummaries": {
                        "313884fa-19f3-50c7-8a83-0909cb47347c": ""
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Somatic (affecting the body) and psychological symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "self-depersonalisation{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PD-4a",
                    "optionSummaries": {
                        "66d2a64f-6ad5-5f44-8b3d-062aefecd20e": ""
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Somatic (affecting the body) and psychological symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "fear of 'going crazy'{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PD-4a",
                    "optionSummaries": {
                        "040cb5af-b6c0-5561-821f-922bc98f255b": ""
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Somatic (affecting the body) and psychological symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} worry about another attack",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PD-8a",
                    "optionSummaries": {
                        "4490ebc3-f879-5be2-b5d2-fd8bc35b02b9": "mild",
                        "b3cdd648-01d1-5e38-87aa-0d7e3aaa11f9": "moderate",
                        "e28a324b-33cd-5b34-ae0d-1b6653c14634": "significant",
                        "ab6d392b-dfe7-5f88-85c1-830fe8ee1b52": "extreme"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Subsequent worrying"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} worry about 'going crazy'",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PD-9a",
                    "optionSummaries": {
                        "9b567469-85f9-58f7-9665-622d04adadd8": "mild",
                        "350fac3e-6a71-5040-8512-64f34f758bd5": "moderate",
                        "459c484e-031b-5f83-b7e0-542f3d1c5020": "very much",
                        "b94cfc0c-3bc7-52d9-9a59-92d677c89c92": "severe"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Subsequent worrying"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} worry about a physical cause",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PD-10a",
                    "optionSummaries": {
                        "1ce36e6a-5a3f-5842-b0a9-2c48cb1e9d75": "mild",
                        "58752e11-5a9a-57b7-b348-7aee292b99bb": "moderate",
                        "6a825419-a602-5c70-a58f-6d1a4c35fe01": "very much",
                        "01f2d100-5e18-57b1-b7bc-1d3f1c855352": "severe"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Subsequent worrying"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} worries about being judged",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PD-11a",
                    "optionSummaries": {
                        "591bebf0-2340-5fa7-8637-3067656fe6f5": "mildly",
                        "102cc555-ba29-55b9-b619-10976c6782e2": "moderately",
                        "ca08db06-d49d-5976-a9f0-cfc3237fac20": "very much",
                        "9a95a69e-26f4-5e9a-a828-8574672e987b": "severely"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Subsequent worrying"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} potentially panic-inducing situations",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PD-13a",
                    "optionSummaries": {
                        "423a96f8-8bfd-5b6c-a1a9-c774b8791c76": "rarely avoids",
                        "3519ecd3-0b41-5ec6-9951-b6ca15bfd473": "occasionally avoids",
                        "49a87d69-42f3-5a88-9e6f-98bb47e130c8": "frequently avoids",
                        "7b5d4fdb-8dfa-5538-a1cb-8c8eff41a761": "almost always or always avoids"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Subsequent avoidance"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} potentially panic-inducing activities",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PD-15a",
                    "optionSummaries": {
                        "34790fef-2329-565c-9cd4-89b781b0a5d5": "rarely avoids",
                        "38eb3155-4361-5031-a733-6666ccfc02ae": "occasionally avoids",
                        "89496f87-cb5e-58fa-b7cf-b94e6ce45c90": "frequently avoids",
                        "d4c12269-3c45-5f93-a03c-3a2fdba3a082": "almost always or always avoids"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Subsequent avoidance"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} use of distracting techniques",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PD-14a",
                    "optionSummaries": {
                        "369a830f-a251-5df9-a1bb-a83ba74b02ec": "rare",
                        "16a15ddc-d3c4-5efe-a011-d452832682a5": "occasional",
                        "85b6cbb0-6f57-5c28-a3cf-9ded461ed3e4": "frequent",
                        "aea711e7-9612-51a3-b80b-36ab25e459e6": "almost always or always makes"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Subsequent avoidance"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Overall level of impairment: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PD-19.0a",
                    "optionSummaries": {
                        "6f38d6b7-1e8e-59d8-855d-3a2dfab8a7a8": "none",
                        "32d9f34a-6d55-5a74-9831-d50b0674028c": "mild",
                        "b4ec769f-97d2-5fc9-9dd2-667849759e5f": "moderate",
                        "c2ff6ef8-9fa1-5f4f-b8dd-f24d7e67d086": "significant",
                        "f9006ec3-1581-5159-a4bb-c73bd1c0468b": "severe"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Functional impairment"
        ]
    },
    {
        "answerSummary": {
            "type": "MultiOptionSummary",
            "questionId": "PD-19a",
            "title": "Areas of impairment",
            "overrides": {
                "b4d064d4-4b58-52de-8403-374108e44562": "Other (not listed)",
                "0ff1f1a7-4cc7-4756-9f7b-88586a678a63": "None reported"
            }
        },
        "section": [
            "Current episode"
        ]
    }
];
