import React from 'react'
import { MultipleChoice } from './MultipleChoice'
import { Option, Unit } from 'censeo-core'
// enum exports are a bit dodgy see e.g. https://stackoverflow.com/questions/62483612/typescript-module-not-found-cant-resolve
import { FreeQuestion as FreeQuestionComponent } from './FreeQuestion'

interface Props {
    answer: number | undefined | null
    unit: Unit | undefined | null
    onSelect: (answer: number) => void
    onBack: () => void
    question: string
    updating: boolean
}

const options = (values: { [label: string]: number }): Option[] =>
    Object.keys(values).map((x) => ({
        id: x,
        text: x,
        __typename: 'Option',
    }))

const getSelectedOptions = (values: { [label: string]: number }, value: number | undefined | null): string[] =>
    [value === undefined ? undefined : Object.keys(values).find((x) => values[x] === value)].filter(
        (x): x is string => x !== undefined
    )

const getValue = (values: { [label: string]: number }, optionId: string) => values[optionId]

export const FreeQuestion: React.FC<Props> = (props) => {
    if (
        props.unit === Unit.Age ||
        props.unit === Unit.DurationHours ||
        props.unit === Unit.DurationMinutes ||
        props.unit === Unit.None
    ) {
        return (
            <FreeQuestionComponent
                value={props.answer}
                onSelect={props.onSelect}
                onBack={props.onBack}
                question={props.question}
                unit={props.unit}
                updating={props.updating}
            />
        )
    }

    return null
}

interface AdapterProps extends Props {
    values: { [label: string]: number }
}
export const MultipleChoiceAdapter: React.FC<AdapterProps> = (props) => (
    <MultipleChoice
        question={props.question}
        options={options(props.values)}
        selectedOptionIds={getSelectedOptions(props.values, props.answer)}
        onSelect={(optionIds) => props.onSelect(getValue(props.values, optionIds[0]))}
        onBack={props.onBack}
        updating={props.updating}
    />
)
