import React from 'react'
import RadioGroup from 'registration/components/RadioGroup/RadioGroup'
import { GenderOptions } from 'registration/pages/Onboarding'

interface OurProps {
    onChange: (val: any) => void
    label?: string
    value?: string
    error?: string
}

type IGenderRadioGroupProps = OurProps & React.ComponentPropsWithoutRef<'div'>

const GenderRadioGroup: React.FC<IGenderRadioGroupProps> = (props) => {
    return (
        <RadioGroup
            label={props.label ?? ''}
            groupName="gender"
            displayOr
            options={Object.keys(GenderOptions).map((m) => ({
                label: getGenderLabelFromValue(m as keyof typeof GenderOptions),
                value: m,
            }))}
            {...props}
        />
    )
}

export default GenderRadioGroup

export const getGenderLabelFromValue = (genderKey: keyof typeof GenderOptions): any => {
    switch (genderKey) {
        case GenderOptions.Female:
            return 'Female (including trans female)'
        case GenderOptions.Male:
            return 'Male (including trans male)'
        default:
            return (GenderOptions as any)[genderKey]
    }
}
