import { Button, Text, Heading } from '@psyomics/components'
import React, { useState } from 'react'
import { QRCodeSVG } from 'qrcode.react'
import { toAbsolutePath } from 'hostOverride'
import Styles from './DemoQr.module.scss'

const DemoQr: React.FC = () => {
    const [show, setShow] = useState<boolean>(true)

    const url = toAbsolutePath(`/demo/service-user/checkIn?qrstart`)

    return (
        <>
            {show && (
                <div className={Styles.container}>
                    <Heading size="heading2" css={{ mb: 5 }}>
                        Welcome to the Censeo service user demo{' '}
                    </Heading>
                    <Text css={{ mb: 5 }}>
                        This is an example of how Censeo operates for service users. The questions shown are from the
                        clinical questionnaire and the clinical logic is semi-replicated.
                    </Text>
                    <Text css={{ mb: 5 }}>
                        Would you like to try it on your phone?<br></br>Scan the QR code.
                    </Text>
                    <QRCodeSVG value={url} size={105} level="H" marginSize={4} />
                    <div>
                        <Button
                            appearance="primary"
                            rounded={true}
                            label="Got it!"
                            onClick={() => setShow(false)}
                        ></Button>
                    </div>
                </div>
            )}
        </>
    )
}

export default DemoQr
