"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.symptoms = void 0;
exports.symptoms = [
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} in the last 6 months",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SP-s5",
                    "optionSummaries": {
                        "35110660-4738-5bcd-96a1-3ad5a821141f": "Not present",
                        "081d965a-5599-5747-93df-ec639c98b41f": "Present"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Duration of symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "SP-s6a"
            ],
            "prefix": "Present for ",
            "formatText": "{}"
        },
        "section": [
            "Current episode",
            "Duration of symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} fear of being exposed to scrutiny",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SP-s1",
                    "optionSummaries": {
                        "a38151d3-973e-530c-b7c2-51b9e33001d5": "mild",
                        "729f7460-99f9-56df-94fe-854d75e389b1": "moderate",
                        "6b433886-476a-53c6-ada2-1e2260c12d17": "very much",
                        "c4f76c19-a59a-59dd-89af-dbc327647d2d": "severe"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Somatic (affecting the body) and psychological symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} fear of being watched",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SP-s2",
                    "optionSummaries": {
                        "c3f9b887-668c-5d88-bb1f-4492aa66b0e4": "mild",
                        "9e25633b-ffae-5cf6-aefc-bd5a3d5a0a12": "moderate",
                        "43effc14-ac9c-56fb-bba9-aca3514c6c77": "very much has a",
                        "0c4dea35-391c-55c6-8db1-5f20a1f4d6a7": "extreme"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Somatic (affecting the body) and psychological symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} fear of being the centre of attention",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SP-s3",
                    "optionSummaries": {
                        "662a26db-5ace-5827-a9b4-5d9907eefa79": "mild",
                        "06ead534-7682-558b-8af2-f1fe4ca79c1f": "moderate",
                        "5fe7f80f-b639-5222-a34d-394a586888a4": "very much",
                        "a6d974f8-4999-5ad7-ae39-524148b324c0": "severe"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Somatic (affecting the body) and psychological symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} fear of humiliation",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SP-s4",
                    "optionSummaries": {
                        "918e349b-2944-5ad5-8010-9bc2bf8bdc2a": "mild",
                        "256f2151-3716-5adc-89c2-7cc79b551197": "moderate",
                        "442311b3-fedd-589a-95a1-5a0b5c17f8c4": "very much",
                        "89646e2f-b5da-5058-bf39-0dca36bec2cb": "severe"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Somatic (affecting the body) and psychological symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} worry of anxiety being noticed by others",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SP-4a",
                    "optionSummaries": {
                        "c61e6245-438c-520e-b1fc-8fdf2a2b777d": "mild",
                        "ab977d02-7d5f-5e37-bcfc-3dba076f51df": "moderate",
                        "e4eff7df-e208-511d-820e-8607d2c089d8": "very much",
                        "10ff8965-17e1-59ec-a91b-3e903baa13a0": "severe"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Somatic (affecting the body) and psychological symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} very anxious about social situations",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SP-3.5a",
                    "optionSummaries": {
                        "f46ddc96-2ebe-5844-a9a4-1f11c5d98a36": "sometimes",
                        "699b519f-6c57-577e-9324-55766f14447f": "quite often",
                        "acd81294-8831-5a45-8e7a-1f458e4fbbdf": "very often",
                        "dc100be9-9b82-5329-aa16-e143ad018760": "always"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Somatic (affecting the body) and psychological symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} experience of 'extreme fear' if not able to avoid social situations",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SP-16a",
                    "optionSummaries": {
                        "a849e9fd-b390-5784-b120-8488a113fb34": "slight",
                        "cbacc3c9-c893-50d4-ab69-3826f7a9c80e": "moderate",
                        "5e476e67-3397-596c-b5aa-228f70f81ca7": "significant",
                        "0b101597-878f-57d2-b689-f9b3f431a1d9": "severe"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Somatic (affecting the body) and psychological symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "fear is {}excessive",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SP-6a",
                    "optionSummaries": {
                        "b8b2eb62-775e-5d8b-b261-e346863f3b9a": "slightly ",
                        "1ceeedc6-bfdb-5552-b1c2-a4e4a9f3abe0": "moderately ",
                        "9ad81c0e-2776-568a-a0a5-c7eb52a58c78": "very ",
                        "4527f581-eb71-5e29-a9ce-4143e2938ecb": "extremely "
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Somatic (affecting the body) and psychological symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} replays social situations",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SP-12a",
                    "optionSummaries": {
                        "b616f997-0283-5f0c-8c36-131ca536758c": "sometimes",
                        "7001da0d-9f52-57b7-a595-77459b4de19b": "quite often",
                        "a0ef8867-a766-5812-a5d1-4249b1e9c99e": "often",
                        "396f7bdb-367e-544e-8dd1-49a0d8c58589": "always"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Somatic (affecting the body) and psychological symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} physical symptoms",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SP-5a",
                    "optionSummaries": {
                        "9e4e0ea3-cb07-5f37-b5cb-ff5f93854c60": "slight",
                        "1d7cc7f7-6e71-5505-8531-3d8b180adb90": "moderate",
                        "abd020f5-cb4f-54b1-9ed2-893ac7ff4958": "significant",
                        "e25496b1-0ed9-53e9-8ea8-993b3c3123f1": "severe"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Somatic (affecting the body) and psychological symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} on safety behaviours",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SP-13a",
                    "optionSummaries": {
                        "26aa2d4e-ad20-5a75-9e17-b762d7c4b14a": "mild reliance",
                        "dbb234e2-d2f5-510c-afc0-0965824e35b2": "moderate reliance",
                        "2df17952-f192-59e5-966e-faa810365cdc": "very much rely",
                        "d43b4e51-8c41-5b94-85a5-1311e7e9c1ac": "severe reliance"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Behavioural symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} relies on alcohol or drugs",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SP-14a",
                    "optionSummaries": {
                        "fbad4753-c21f-5d92-b920-eeda0df028c1": "sometimes",
                        "e0ce7ba0-7bc1-525e-84ac-3eeb498d43ea": "quite often",
                        "6444b285-a910-5c51-b8e3-b9e2f3a1f4cb": "often",
                        "d98a7849-60ea-54f3-b92b-2720f506b8a8": "always"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Behavioural symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} avoidance of social situations",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SP-15a",
                    "optionSummaries": {
                        "45cab604-070b-5a9f-9c14-39f2d04b4883": "slight",
                        "8c9d90a9-2d0c-553c-aaa2-6f53088f91e7": "moderate",
                        "af6f01bc-55f9-51d4-9531-ab0d07dab4a9": "very much",
                        "1a4b5856-56e4-5e12-98ef-4b19bb86c61c": "severe"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Behavioural symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "MultiOptionSummary",
            "questionId": "SP-1a",
            "title": "",
            "overrides": {
                "f732df62-d939-52ee-ba37-a0cc386bd68f": "Other (not listed)",
                "e4429794-5af9-5ed1-9ebb-ba8ca86a0ce1": "Talking to a person they find attractive"
            }
        },
        "section": [
            "Current episode",
            "Feared social situations"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Overall level of impairment: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SP-17.0a",
                    "optionSummaries": {
                        "b1d6f5a8-ed7b-5983-8c5f-3544ad79bb54": "none",
                        "f9277d5a-c693-5c89-aeba-600ca316f4c2": "mild",
                        "748c3e8a-548a-59c0-841b-8ef86556a2c7": "moderate",
                        "7ea4813f-57cd-5ba7-b21e-c076dda71014": "significant",
                        "9cfbde86-55ad-56ae-b2c4-75aea806f939": "severe"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Functional impairment"
        ]
    },
    {
        "answerSummary": {
            "type": "MultiOptionSummary",
            "questionId": "SP-17a",
            "title": "Areas of impairment",
            "overrides": {
                "c9c685c3-e334-5376-a214-5a96d666a44c": "Other (not listed)",
                "a3a9fc6f-d10d-4abe-86b7-08fd0d3f87e0": "None reported"
            }
        },
        "section": [
            "Current episode",
            "Functional impairment"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Patient reports {} a job involving presenting or performing",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SP-20a",
                    "optionSummaries": {
                        "acd11895-4da3-5ed7-b7b6-d5242627e46e": "not having",
                        "59d8ffbf-ca3c-5a9f-b51c-ecfbe73c6890": "having"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Functional impairment"
        ]
    }
];
