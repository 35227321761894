import React from 'react'
import Styles from './OrBreak.module.scss'

const OrBreak: React.FC<{ text?: string }> = ({ text = 'OR' }) => {
    return (
        <div className={Styles.orBox}>
            <div className={Styles.orLine}></div>
            <div className={Styles.orText}>{text}</div>
            <div className={Styles.orLine}></div>
        </div>
    )
}

export default OrBreak
