export const getCurrentDateISO = (): string => {
    const now = new Date()
    const date = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 23, 59, 59))
    return date.toISOString()
}

export const getLastMonthDateISO = (): string => {
    const now = new Date()
    const date = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth() - 1, now.getUTCDate(), 0, 0, 0))
    return date.toISOString()
}
