const timers: { [key: string]: CenseoTimer } = {}
const censeoTimerKeyPrefix = 'CenseoTimer-'

export enum Timers {
    AssessmentTotalDuration = 'AssessmentTotalDuration',
    QuestionTimeSpent = 'QuestionTimeSpent',
}

enum TimerAction {
    Pause = 'pause',
    Resume = 'resume',
    Delete = 'delete',
}

export class CenseoTimer {
    private intervalId: number | null = null
    private key: string
    private paused = false

    constructor(key: string) {
        this.key = key
    }

    start(): void {
        if (this.intervalId !== null) {
            return
        }

        let elapsed = parseFloat(localStorage.getItem(censeoTimerKeyPrefix + this.key) ?? '0')
        this.intervalId = window.setInterval(() => {
            if (!this.paused) {
                elapsed += 1
                localStorage.setItem(censeoTimerKeyPrefix + this.key, elapsed.toString())
            }
        }, 1000)
    }

    update(action: TimerAction): void {
        switch (action) {
            case 'pause':
                this.paused = true
                break
            case 'resume':
                this.paused = false
                break
            case TimerAction.Delete:
                if (this.intervalId !== null) {
                    window.clearInterval(this.intervalId)
                    localStorage.removeItem(censeoTimerKeyPrefix + this.key)
                    this.intervalId = null
                }
                break
        }
    }

    get(): string {
        const elapsedString = localStorage.getItem(censeoTimerKeyPrefix + this.key)
        return elapsedString ?? '0'
    }
}

function updateTimer(key: Timers, action: TimerAction): void {
    const timer = timers[key]
    if (timer) {
        timer.update(action)
        if (action === TimerAction.Delete) {
            delete timers[key]
        }
    }
}

export function enableTimer(key: Timers): void {
    if (timers[key]) {
        return
    }

    const timer = new CenseoTimer(key)
    timer.start()
    timers[key] = timer
}

export function pauseTimer(key: Timers): void {
    updateTimer(key, TimerAction.Pause)
}

export function resumeTimer(key: Timers): void {
    updateTimer(key, TimerAction.Resume)
}

export function deleteTimer(key: Timers): void {
    updateTimer(key, TimerAction.Delete)
}

export function resetTimer(key: Timers): void {
    deleteTimer(key)
    enableTimer(key)
}

export function getTimer(key: Timers, reset = false): string | undefined {
    const timer = timers[key]
    if (timer) {
        const elapsed = timer.get()
        if (reset) {
            resetTimer(key)
        }
        return elapsed
    }
    return undefined
}

export function restartTimers(): void {
    for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i)

        if (key?.startsWith(censeoTimerKeyPrefix)) {
            const originalKey = key.substring(censeoTimerKeyPrefix.length)
            const timer = timers[originalKey]

            if (!timer) {
                const timer = new CenseoTimer(originalKey)
                timer.start()
                timers[originalKey] = timer
            } else {
                timer.update(TimerAction.Resume)
            }
        }
    }
}
