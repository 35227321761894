"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.symptoms = void 0;
exports.symptoms = [
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} substances in the last year",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SU-s3",
                    "optionSummaries": {
                        "3ed9bf02-1389-5b1a-81e0-5dadb1daa205": "taken"
                    }
                }
            ]
        },
        "section": [
            "Episode details",
            "Duration and frequency"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "used substances {} within the last year",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SU-1a",
                    "optionSummaries": {
                        "dc9dd01a-e6c0-58c8-9b8f-b3315d6a689c": "monthly or less",
                        "64b85f3c-84e5-5d37-bc42-dab6740c8199": "2–4 times per month",
                        "22270598-ff5e-5785-a9a3-9bbed0fd9751": "2–3 times per week",
                        "5f82977e-8743-5b56-9f4d-7a3e8cc571cd": "4–5 times per week",
                        "1a337ce3-5ab7-5773-9fe0-f0d1c23ed96c": "more than 6 times per week"
                    }
                }
            ],
            "tags": [
                "sbs_ce"
            ]
        },
        "section": [
            "Episode details",
            "Duration and frequency"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "used substances {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SU-2b",
                    "optionSummaries": {
                        "f6b6411b-02e6-531d-b2ed-67d6dfacfd96": "monthly or less",
                        "4678ad6e-17f5-504f-84d5-d7e4bd032e1f": "2–4 times per month",
                        "69f7eb47-cf61-5bc3-b485-494de2684114": "2–3 times per week",
                        "f2377583-a77e-55a4-9869-e01408506abf": "4–5 times per week",
                        "28fd19f5-e24d-5aa5-ab06-4e63101e3ef7": "more than 6 times per week"
                    }
                }
            ],
            "tags": [
                "sbs_pe"
            ]
        },
        "section": [
            "Episode details",
            "Duration and frequency"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "SU-1b"
            ],
            "prefix": "",
            "formatText": "{} since last used substances"
        },
        "section": [
            "Episode details",
            "Duration and frequency"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "currently experiencing withdrawal symptoms",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SU-10a",
                    "optionSummaries": {
                        "dfdb12b6-8e8e-58eb-8a79-3ffaf59b4d9c": ""
                    }
                }
            ],
            "tags": [
                "sbs_ce"
            ]
        },
        "section": [
            "Episode details",
            "Symptoms of dependence"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "previously experienced withdrawal symptoms",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SU-11b",
                    "optionSummaries": {
                        "df81db2f-9fe7-5b09-be87-64780dd81a25": ""
                    }
                }
            ],
            "tags": [
                "sbs_pe"
            ]
        },
        "section": [
            "Episode details",
            "Symptoms of dependence"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} a tolerance",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SU-9a",
                    "optionSummaries": {
                        "16c3b305-ee67-59a9-9182-8d6c929a9509": "developed"
                    }
                }
            ],
            "tags": [
                "sbs_ce"
            ]
        },
        "section": [
            "Episode details",
            "Symptoms of dependence"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "previously {} a tolerance",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SU-10b",
                    "optionSummaries": {
                        "eb7acd72-f4fa-52a4-b53f-db24e7756bbf": "developed"
                    }
                }
            ],
            "tags": [
                "sbs_pe"
            ]
        },
        "section": [
            "Episode details",
            "Symptoms of dependence"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} desire to use substances",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SU-2a",
                    "optionSummaries": {
                        "61e46908-594f-5831-b709-767c60df9f2b": "strong"
                    }
                }
            ],
            "tags": [
                "sbs_ce"
            ]
        },
        "section": [
            "Episode details",
            "Symptoms of dependence"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "previous {} desire to use substances",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SU-3b",
                    "optionSummaries": {
                        "97837883-2c07-58ae-87ab-970f4a8e16d7": "strong"
                    }
                }
            ],
            "tags": [
                "sbs_pe"
            ]
        },
        "section": [
            "Episode details",
            "Symptoms of dependence"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} to cut down or control substance use within the last year",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SU-3a",
                    "optionSummaries": {
                        "8b9ec309-bccd-5e75-82c2-0b9f6bbf25fd": "tried but was unable",
                        "a3720a21-cf5f-5c57-9dde-8cb54605246f": "tried and managed"
                    }
                }
            ],
            "tags": [
                "sbs_ce"
            ]
        },
        "section": [
            "Episode details",
            "Symptoms of dependence"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "previously {} to cut down or control substance use",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SU-4b",
                    "optionSummaries": {
                        "c48b4250-be6d-53b5-9c4b-50a2f5facea4": "tried but was unable",
                        "5af10404-91e9-55e5-a3a5-5b0525ebf15c": "tried and managed"
                    }
                }
            ],
            "tags": [
                "sbs_pe"
            ]
        },
        "section": [
            "Episode details",
            "Symptoms of dependence"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "spent an excessive amount of time on substance-related activities in the last year",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SU-5a",
                    "optionSummaries": {
                        "e990acdb-4f26-5f2a-9794-2949aca56fb2": ""
                    }
                }
            ],
            "tags": [
                "sbs_ce"
            ]
        },
        "section": [
            "Episode details",
            "Symptoms of dependence"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "previously spent an excessive amount of time spent on substance-related activities",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SU-6b",
                    "optionSummaries": {
                        "2877cecd-91f0-5b3c-a584-708c83205504": ""
                    }
                }
            ],
            "tags": [
                "sbs_pe"
            ]
        },
        "section": [
            "Episode details",
            "Symptoms of dependence"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} expressed concern about substance use in the last year",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SU-7a",
                    "optionSummaries": {
                        "85e98c24-bf79-526d-8945-070f551c81c7": "others have"
                    }
                }
            ],
            "tags": [
                "sbs_ce"
            ]
        },
        "section": [
            "Episode details",
            "Symptoms of dependence"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "previously, {} expressed concern about substance use",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SU-8b",
                    "optionSummaries": {
                        "32ec1d91-b47c-5754-a656-c6c0f230ace2": "others have"
                    }
                }
            ],
            "tags": [
                "sbs_pe"
            ]
        },
        "section": [
            "Episode details",
            "Symptoms of dependence"
        ]
    },
    {
        "answerSummary": {
            "type": "MultiOptionSummary",
            "questionId": "SU-s1",
            "title": "",
            "overrides": {}
        },
        "section": [
            "Episode details",
            "Recreational substance use during lifetime"
        ]
    },
    {
        "answerSummary": {
            "type": "MultiOptionSummary",
            "questionId": "SU-s3.1",
            "title": "",
            "overrides": {}
        },
        "section": [
            "Episode details",
            "Recreational substance use in the last year"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "everyday functioning {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SU-4a",
                    "optionSummaries": {
                        "ede457f4-731b-56bd-b7b7-58d93ecdcacd": "not disrupted by substance use",
                        "dd97a5ec-89cc-5ee7-b89b-644d9f1a2b7b": "disrupted by substance use less than once per month",
                        "3927c90d-af04-584d-a800-515db0f2ec3d": "disrupted by substance use once per month",
                        "56dbee32-dbd2-53fe-8f12-0a5ca96ae328": "disrupted by substance use once per week",
                        "6d708b4f-6b53-5630-8bc7-ce15608df0e5": "disrupted daily by substance use"
                    }
                }
            ],
            "tags": [
                "sbs_ce"
            ]
        },
        "section": [
            "Episode details",
            "Functional impairment"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "previously, everyday functioning was {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SU-5b",
                    "optionSummaries": {
                        "a6d3f751-7a06-5eb4-97b5-70831bdb0e3d": "not disrupted by substance use",
                        "6e890ffb-ea8c-50f4-87d8-0b9fcb05e77c": "disrupted by substance use less than once per month",
                        "bcac9437-7015-5fe5-9ee4-fb99cf0d5f17": "disrupted by substance use once per month",
                        "6c0b7b5f-bac4-5d59-884f-ca8f482f11d4": "disrupted by substance use once per week",
                        "61e94409-cc88-5298-9cee-7d1ff14341fa": "disrupted daily by substance use"
                    }
                }
            ],
            "tags": [
                "sbs_pe"
            ]
        },
        "section": [
            "Episode details",
            "Functional impairment"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "has {} into serious trouble due to substance use in the last year",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SU-6a",
                    "optionSummaries": {
                        "a16682f6-84c6-5bdd-9553-eafb92701f79": "not got",
                        "9af0558c-80cf-59e9-b3b5-1c0df4b4cf4b": "got"
                    }
                }
            ],
            "tags": [
                "sbs_ce"
            ]
        },
        "section": [
            "Episode details",
            "Functional impairment"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "previously has {} into serious trouble due to substance use",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SU-7b",
                    "optionSummaries": {
                        "fb9594cf-71a8-580c-80d0-18dd9696d5bc": "not got",
                        "b6a26449-f023-5a8a-a4c9-a6c625f0f366": "got"
                    }
                }
            ],
            "tags": [
                "sbs_pe"
            ]
        },
        "section": [
            "Episode details",
            "Functional impairment"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "substance use {} arguments or problems with others",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SU-8a",
                    "optionSummaries": {
                        "38ede561-e889-51b7-baa3-51217ea1552e": "doesn’t cause",
                        "4ade59f6-9242-595c-9d22-1c907b3dcbd6": "causes"
                    }
                }
            ],
            "tags": [
                "sbs_ce"
            ]
        },
        "section": [
            "Episode details",
            "Functional impairment"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "substance use {} arguments or problems with others",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SU-9b",
                    "optionSummaries": {
                        "70dd7443-067d-5db4-bbfd-32f5c623fe8a": "did not cause",
                        "485e4f43-7787-5f16-8ff0-e578b0ff4b83": "caused"
                    }
                }
            ],
            "tags": [
                "sbs_pe"
            ]
        },
        "section": [
            "Episode details",
            "Functional impairment"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} to physical or mental health due to substance use",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SU-11a",
                    "optionSummaries": {
                        "57899734-07b2-50a2-a5d8-56142b5feb92": "no damage",
                        "8f999645-02ed-57fb-90c7-c509bdf9217d": "damage"
                    }
                },
                {
                    "questionId": "SU-12b",
                    "optionSummaries": {
                        "cc068b90-c986-5049-9322-7b8b0fad4143": "no damage",
                        "7debcb22-6637-5de5-9421-89be645c3b96": "damage"
                    }
                }
            ]
        },
        "section": [
            "Episode details",
            "Functional impairment"
        ]
    }
];
