import { gql } from '@apollo/client'
import { DocumentNode } from 'graphql'
import { ReportResultMessage } from 'censeo-core'

export const reportUrlQuery: DocumentNode = gql`
    query ReportUrl($id: ID!, $patientFacing: Boolean!) {
        reportUrl(id: $id, patientFacing: $patientFacing) {
            message
            url
        }
    }
`

export interface ReportUrlData {
    reportUrl: {
        message: ReportResultMessage
        url: string | null
        patientFacing: boolean
    }
}
