import React from 'react'
import { Page, Button, Heading } from '@psyomics/components'
import Header from '../Header'
import { useDemoContext } from 'registration/pages/Demo/DemoContext'

interface HeadingButtonProps {
    display?: boolean
    text: string
    link: () => void
}
interface OurProps {
    /** what will the heading text be */
    headingText?: string
    /** properties for the heading back link  */
    headingButton?: HeadingButtonProps
    hideCrisisLink?: boolean
    footer?: React.ReactNode
}

type IContentPageProps = OurProps & React.ComponentPropsWithoutRef<'div'>

const ContentPage: React.FC<IContentPageProps> = ({
    hideCrisisLink,
    headingText,
    headingButton,
    children,
    ...props
}) => {
    const { demoFrom } = useDemoContext()
    const Head = <Header hideCrisisLink={hideCrisisLink} patientFacing={true}></Header>
    return (
        <Page isInsideMobileFrame={demoFrom === 'start'} header={Head} {...props}>
            {headingButton && (
                <Button
                    appearance="link"
                    size="small"
                    icon="arrow-left"
                    onClick={headingButton.link}
                    label={headingButton.text}
                ></Button>
            )}
            {headingText && (
                <Heading
                    color="mid"
                    css={{
                        mt: 3,
                        mb: 5,
                    }}
                    size="heading1"
                    el="h1"
                >
                    {headingText}
                </Heading>
            )}
            {children}
        </Page>
    )
}

export default ContentPage
