import React, { useState, useEffect, useContext } from 'react'
import { CustomStateContext } from '../../../../AuthenticatedAppPractitioner'
import * as Sentry from '@sentry/react'
import { useNavigate } from 'react-router-dom'
import CustomError from 'ui/app/pages/CustomError'

const PractitionerCallback: React.FC = () => {
    const [error, setError] = useState('')
    const navigate = useNavigate()
    const customState = useContext(CustomStateContext)
    const allowedRedirectPaths = ['/report', '/account']

    useEffect(() => {
        const persistedCustomState = sessionStorage.getItem('persistedCustomState')

        if (!customState || !persistedCustomState) {
            setError('Session information not found. Please log back in and try again.')
            Sentry.captureException(
                `Could not determine received state parameter from CustomStateContext or sessionStorage`,
                {
                    extra: {
                        customState,
                        persistedCustomState,
                    },
                }
            )
            return
        }

        if (customState && customState.includes(':')) {
            const [, callbackRoute] = customState.split(':')
            const stateParamIsVerified = customState === persistedCustomState
            const callbackRouteIsValid = allowedRedirectPaths.some((path) => callbackRoute.startsWith(path))

            if (stateParamIsVerified && callbackRouteIsValid) {
                navigate(callbackRoute)
            } else {
                setError(
                    'Provided link might be invalid or the session has changed. Please log back in and try again. If the problem persists, please contact us.'
                )
                Sentry.captureException(
                    `Looks like either state parameter validation failed or callback route is invalid`,
                    {
                        extra: {
                            stateParamIsVerified,
                            callbackRouteIsValid,
                        },
                    }
                )
            }
        } else {
            setError(
                'Invalid state format. Please check the link and try again or contact support if the issue persists.'
            )
            Sentry.captureException(`Invalid state format.`, {
                extra: {
                    customState,
                },
            })
        }
    }, [history, customState, allowedRedirectPaths])

    return error ? <CustomError message={error} /> : <div>Redirecting...</div>
}

export default PractitionerCallback
