import { getCookie } from './cookies'

const defaultLocale = 'en-GB'

export const getLocale = (): string => {
    const demoLocale = getCookie('demoLocale')
    return demoLocale || defaultLocale
}

export const isInternationalDemo = (): boolean => getLocale() !== defaultLocale
export const isENGBLocale = (): boolean => getLocale() === defaultLocale
