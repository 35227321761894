import React, { useEffect, useContext, useState } from 'react'
import Auth from '@aws-amplify/auth'
import { InfoContext } from '../../../../configure'
import { Splash } from 'ui/app/pages/Splash'
import CenseoError from 'ui/app/pages/Error'
import * as Sentry from '@sentry/react'
import PractitionerCognitoLogin from '../PractitionerCognitoLogin'
import { useParams } from 'react-router-dom'
import { extractErrorDescriptionCognito } from 'ui/utils/parser'
import { generateCsrfToken } from 'ui/utils/csrfToken'
import { getReferralsEndpoint } from 'data/hooks/getReferralsEndpoint'

const PractitionerLogin: React.FC = () => {
    const info = useContext(InfoContext)
    const [error, setError] = useState<string>()
    const [isCognitoLogin, setIsCognitoLogin] = useState<boolean>(false)
    const { id } = useParams<{ id: string }>()

    useEffect(() => {
        const initiateLogin = async () => {
            try {
                const customState = `${generateCsrfToken()}:${window.location.pathname}`
                sessionStorage.setItem('persistedCustomState', customState)

                if (window.location.href) {
                    const errorFromFederatedLoginRedirect = extractErrorDescriptionCognito(window.location.href)
                    if (errorFromFederatedLoginRedirect) {
                        handleError({
                            userMessage: 'an issue with the connection to your login provider occurred',
                            detailedMessage: errorFromFederatedLoginRedirect,
                        })
                        return
                    }
                }

                if (!info?.referralApiUrl) {
                    handleError({
                        userMessage: 'missing configuration',
                        detailedMessage: 'Referral API URL is not defined.',
                    })
                    return
                }

                const endpoint = getReferralsEndpoint(window.location.pathname, info.referralApiUrl)
                const response = await fetch(endpoint)
                if (!response.ok) {
                    handleError({
                        userMessage: 'failed to communicate with the Referral service',
                        detailedMessage: `Referral API response not ok for endpoint: ${endpoint}`,
                        extra: { endpoint, status: response.status, response },
                    })
                    return
                }

                const organisation = await response.json()
                if (!organisation?.organisationCode) {
                    handleError({
                        userMessage: 'an error occurred during authentication',
                        detailedMessage: 'Organisation code is missing for authentication',
                        extra: { organisation },
                    })
                    return
                }

                if (organisation.organisationConfig?.cognitoLogin) {
                    setIsCognitoLogin(true)
                    return
                }

                const customProvider = organisation.organisationCode

                await Auth.federatedSignIn({
                    customProvider,
                    customState,
                })
            } catch (err) {
                handleError({
                    userMessage: 'an unexpected error occurred during the sign-in process',
                    detailedMessage: 'An error occurred during the federated sign-in process',
                    extra: { error: err },
                })
            }
        }

        const handleError = ({
            userMessage,
            detailedMessage,
            extra = {},
        }: {
            userMessage: string
            detailedMessage: string
            extra?: Record<string, unknown>
        }) => {
            setError(userMessage)
            Sentry.captureException(new Error(detailedMessage), { extra })
        }

        initiateLogin()
    }, [])

    if (error) {
        return (
            <CenseoError
                customMessage={`We noticed something wrong happened: ${error}. Please try again and contact us if the problem persists.`}
                homepageButton={false}
            />
        )
    }

    if (isCognitoLogin) {
        return <PractitionerCognitoLogin reportId={id} showHostedUIButton={false} />
    }

    return <Splash />
}

export default PractitionerLogin
