import React from 'react'
import { Button, Text } from '@psyomics/components'
import Card from 'registration/components/Card'
import { useNavigate } from 'react-router-dom'

type IResetPassword = React.ComponentPropsWithoutRef<'div'>

const ResetPasswordSuccess: React.FC<IResetPassword> = () => {
    const navigate = useNavigate()

    return (
        <Card>
            <Text>Your password has been updated.</Text>
            <Text>Use the new password now to sign in to your account</Text>
            <Button
                type="button"
                appearance="primary"
                rounded
                fullWidth
                size="large"
                label="Return to sign in"
                icon={null}
                onClick={() => {
                    navigate('/signin')
                }}
            />
        </Card>
    )
}

export default ResetPasswordSuccess
