export type FormStage = 'general' | 'background'

export enum GeneralEthnicity {
    White = 'White',
    Mixed = 'Mixed/Multiple ethnic groups',
    Asian = 'Asian/Asian British',
    Black = 'Black/African/Caribbean/Black British',
    Other = 'Other ethnic group',
    PreferNotToSay = 'Prefer not to say',
}

export type EthnicBackground = {
    text: string
    additionalInfo?: boolean
}

export type EthnicGroup = {
    general: GeneralEthnicity
    backgrounds: Array<EthnicBackground>
}

export const EthnicGroups: EthnicGroup[] = [
    {
        general: GeneralEthnicity.White,
        backgrounds: [
            {
                text: 'English/Welsh/Scottish/Northern Irish/British',
            },
            { text: 'Irish' },
            { text: 'Gypsy or Irish Traveller' },
            { text: 'Any other White background,', additionalInfo: true },
        ],
    },
    {
        general: GeneralEthnicity.Mixed,
        backgrounds: [
            { text: 'White and Black Caribbean' },
            { text: 'White and Black African' },
            { text: 'White and Asian' },
            {
                text: 'Any other Mixed/Multiple ethnic background,',
                additionalInfo: true,
            },
        ],
    },
    {
        general: GeneralEthnicity.Asian,
        backgrounds: [
            { text: 'Indian' },
            { text: 'Pakistani' },
            { text: 'Bangladeshi' },
            { text: 'Chinese' },
            {
                text: 'Any other Asian/Asian British background,',
                additionalInfo: true,
            },
        ],
    },
    {
        general: GeneralEthnicity.Black,
        backgrounds: [
            { text: 'African' },
            { text: 'Caribbean' },
            {
                text: 'Any other Black/African/Caribbean/Black British background,',
                additionalInfo: true,
            },
        ],
    },
    {
        general: GeneralEthnicity.Other,
        backgrounds: [{ text: 'Arab' }, { text: 'Any other ethnic group,', additionalInfo: true }],
    },
    {
        general: GeneralEthnicity.PreferNotToSay,
        backgrounds: [],
    },
]
