import { gql } from '@apollo/client'
import { DocumentNode } from 'graphql'
import { Previous } from 'censeo-core'

export const invalidateAnswerMutation: DocumentNode = gql`
    mutation InvalidateAnswer($questionId: ID!) {
        invalidateAnswer(questionId: $questionId) {
            ... on PreviousQuestion {
                id
                __typename
            }
        }
    }
`

export interface InvalidateAnswerResponse {
    invalidateAnswer: Previous
}
