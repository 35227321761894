import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from '@psyomics/components'
import Styles from './TopNavBar.module.scss'

interface TopNavBarProps {
    onHomepageClick?: () => void
    settings?: {
        enablePrevButton: boolean
        enableHomepageButton: boolean
    }
}

const TopNavBar: React.FC<TopNavBarProps> = ({
    onHomepageClick,
    settings = { enablePrevButton: true, enableHomepageButton: true },
}) => {
    const navigate = useNavigate()

    return (
        <div className={Styles.buttonGroup}>
            <Button
                appearance="link"
                size="large"
                icon="arrow-left"
                disabled={settings.enablePrevButton ? undefined : true}
                onClick={() => navigate(-1)}
                aria-label="Previous page"
            />
            <Button
                appearance="link"
                size="large"
                icon="home-button"
                disabled={settings.enableHomepageButton ? undefined : true}
                onClick={onHomepageClick ? onHomepageClick : () => navigate(`/demo/healthcare`)}
                aria-label="Homepage"
            />
        </div>
    )
}

export default TopNavBar
