"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isSubClinical = exports.isSevere = exports.toSeriousness = exports.getReportData = exports.getRagData = exports.ragOutcomeNamesFromName = exports.ragScoresNames = void 0;
const __1 = require("..");
const algorithms_1 = require("../algorithms");
const answerSummaries_1 = require("../answerSummaries");
const censeo_1 = require("../schema/generated/censeo");
const conditions_1 = require("../yaml/generated/conditions");
const Suicide_1 = require("../yaml/generated/Suicide");
exports.ragScoresNames = {
    2: 'Very High',
    3: 'High',
    4: 'Moderate',
    5: 'Low',
    0: 'Not enough information',
};
var RagFriendlyNames;
(function (RagFriendlyNames) {
    RagFriendlyNames["s_current"] = "Suicidality";
    RagFriendlyNames["sh_current"] = "Self-harm";
    RagFriendlyNames["trm_re_ptsd"] = "Trauma distress";
    RagFriendlyNames["emi_ce"] = "Emotional Instability";
})(RagFriendlyNames || (RagFriendlyNames = {}));
const ragOutcomeNamesFromName = (userType) => {
    return {
        [(0, conditions_1.conditionInformation)(userType)['s_current'].name]: RagFriendlyNames.s_current,
        [(0, conditions_1.conditionInformation)(userType)['sh_current'].name]: RagFriendlyNames.sh_current,
        [(0, conditions_1.conditionInformation)(userType)['trm_re_ptsd'].name]: RagFriendlyNames.trm_re_ptsd,
        [(0, conditions_1.conditionInformation)(userType)['emi_ce'].name]: RagFriendlyNames.emi_ce,
    };
};
exports.ragOutcomeNamesFromName = ragOutcomeNamesFromName;
const getRagData = (ragEvaluation, questions, answers, userType) => {
    const ragEvaluationScores = Object.keys(ragEvaluation.output).map((x) => ragEvaluation.output[x].score);
    const lowestScoreNotZero = Math.min(...ragEvaluationScores.filter(Boolean));
    const overallScore = lowestScoreNotZero === Infinity ? 0 : lowestScoreNotZero;
    const ragQuestionIds = Object.keys(RagFriendlyNames)
        .map((aspectName) => {
        return ragEvaluation.output[aspectName].usedQuestionIds.concat(ragEvaluation.output[aspectName].supplementaryInfoQuestionIds);
    })
        .flatMap((x) => x);
    const ragQuestions = questions.filter((x) => ragQuestionIds.includes(x.id));
    const ragAnswers = answers.filter((x) => ragQuestionIds.includes(x.questionId));
    const ragOutcomes = Object.keys(RagFriendlyNames).map((aspectName) => {
        const score = ragEvaluation.output[aspectName].score;
        const selfHarmAndSuicideAspects = ['s_current', 'sh_current'];
        const extraQuestionsToUse = selfHarmAndSuicideAspects.includes(aspectName) ? Suicide_1.questions : [];
        const extraAnswersToUse = selfHarmAndSuicideAspects.includes(aspectName)
            ? answers.filter((x) => Suicide_1.questions.map((y) => y.id).includes(x.questionId))
            : [];
        const ragSymptoms = toSymptoms(ragQuestions.concat(extraQuestionsToUse), ragAnswers.concat(extraAnswersToUse), (0, conditions_1.conditionInformation)(userType), aspectName);
        const patientFacingName = (0, conditions_1.conditionInformation)(userType)[aspectName].patientFacingName;
        return {
            name: userType === censeo_1.UserType.D2C && patientFacingName
                ? patientFacingName
                : (0, conditions_1.conditionInformation)(userType)[aspectName].name,
            score: score,
            scoreText: exports.ragScoresNames[score],
            symptoms: ragSymptoms || [],
        };
    });
    return {
        score: overallScore,
        scoreText: exports.ragScoresNames[overallScore],
        outcomes: ragOutcomes,
        decisions: ragEvaluation.decisions,
    };
};
exports.getRagData = getRagData;
const mapToSymptomSections = (map) => Object.keys(map).reduce((acc, next) => {
    const subSections = mapToSymptomSections(map[next].sections);
    return [
        ...acc,
        ...(map[next].symptoms.length > 0 || subSections.length > 0
            ? [
                {
                    symptoms: map[next].symptoms,
                    title: next,
                    subSections,
                },
            ]
            : []),
    ];
}, []);
const getReportData = (questions, answers, evaluatedAspects, noThreshold = false, user, sessionConfig) => {
    const conditions = (0, __1.filterAndSortAspects)(evaluatedAspects, conditions_1.conditionOrdering, noThreshold, sessionConfig).map((aspectName) => {
        const patientFacingName = (0, conditions_1.conditionInformation)(user.userType)[aspectName].patientFacingName;
        const conditionName = user.userType === censeo_1.UserType.D2C && patientFacingName
            ? patientFacingName
            : (0, conditions_1.conditionInformation)(user.userType)[aspectName].name;
        return toCondition(questions, answers, evaluatedAspects, conditionName, aspectName, user.userType);
    });
    const staticConditions = conditions_1.staticConditionOrdering
        .filter((aspect) => (0, __1.sessionConfigContainsAspect)(sessionConfig, aspect))
        .map((aspectName) => toCondition(questions, answers, evaluatedAspects, (0, conditions_1.conditionInformation)(user.userType)[aspectName].name, aspectName, user.userType))
        .filter((c) => c.symptoms.length > 0)
        .reduce((acc, next) => {
        return { ...acc, [next.name]: next };
    }, {});
    const rag = (0, exports.getRagData)((0, __1.evaluateRag)(answers), questions, answers, user.userType);
    return {
        conditions,
        ...(Object.keys(staticConditions).length > 0 && { staticConditions }),
        rag,
    };
};
exports.getReportData = getReportData;
const mergeSections = (sectionA, sectionB) => {
    if (sectionA === undefined) {
        return sectionB || {};
    }
    if (sectionB === undefined) {
        return sectionA;
    }
    return [...Object.keys(sectionA), ...Object.keys(sectionB)].reduce((acc, next) => ({
        ...acc,
        [next]: {
            symptoms: mergeSymptoms(sectionA[next] ? sectionA[next].symptoms : [], sectionB[next] ? sectionB[next].symptoms : []),
            sections: mergeSections((sectionA[next] || {}).sections, (sectionB[next] || {}).sections),
        },
    }), {});
};
const mergeSymptoms = (symptomsA, symptomsB) => [...symptomsA, ...symptomsB];
const createSection = (summaries, titles) => {
    if (titles.length === 0) {
        return summaries
            .filter((x) => x.type === 'GroupSummary')
            .reduce((acc, next) => ({
            ...acc,
            [next.title]: {
                sections: {},
                symptoms: next.summaries.map((s) => ({
                    description: s,
                })),
            },
        }), {});
    }
    if (titles.length === 1) {
        const simpleSummaries = summaries
            .filter((x) => x.type === 'SimpleSummary')
            .map((s) => ({
            description: s.text,
        }));
        return {
            [titles[0]]: {
                symptoms: simpleSummaries,
                sections: createSection(summaries, titles.slice(1)),
            },
        };
    }
    return {
        [titles[0]]: {
            symptoms: [],
            sections: createSection(summaries, titles.slice(1)),
        },
    };
};
const toSymptoms = (questions, answers, summariesByAspectName, aspectName) => {
    let groupedSummaries = (0, answerSummaries_1.getSummaries)(questions, summariesByAspectName, answers, aspectName);
    const ordering = summariesByAspectName[aspectName].summariesOrdering || [];
    if (ordering.length) {
        groupedSummaries = groupedSummaries.sort((a, b) => sectionOrdering(ordering, a.header, b.header));
    }
    const asSections = groupedSummaries.map((a) => createSection(a.summaries, a.header));
    const completeMap = asSections.reduce(mergeSections, {});
    return mapToSymptomSections(completeMap || {});
};
const sectionOrdering = (ordering, a, b) => {
    if (!a.length)
        return -1;
    if (!b.length)
        return 1;
    return ordering.indexOf(a[0]) < ordering.indexOf(b[0]) ? -1 : 1;
};
const toSeriousness = (evaluatedIn) => {
    const evaluated = (0, algorithms_1.mapToAspectValue)(evaluatedIn);
    if (evaluated === undefined) {
        return undefined;
    }
    if (evaluated < 0.25) {
        return 'Low';
    }
    if (evaluated < 0.5) {
        return 'Sub-clinical';
    }
    if (evaluated < 0.75) {
        return 'Moderate';
    }
    return 'Severe';
};
exports.toSeriousness = toSeriousness;
const isSevere = (evaluated) => evaluated !== undefined && evaluated >= 0.75;
exports.isSevere = isSevere;
const isSubClinical = (evaluated) => evaluated !== undefined && evaluated < 0.5;
exports.isSubClinical = isSubClinical;
const toCondition = (questions, answers, evaluatedAspects, conditionName, aspectName, userType) => {
    const evaluated = evaluatedAspects[aspectName];
    return {
        name: conditionName,
        symptoms: toSymptoms(questions, answers, (0, conditions_1.conditionInformation)(userType), aspectName),
        score: (0, algorithms_1.mapToAspectValue)(evaluated),
    };
};
