import React, { useContext } from 'react'
import Styles from './SignUp.module.scss'
import { AnimatePresence } from 'framer-motion'
import { Formik } from 'formik'

import { SignupFormInputsInitialValues, SignupFormSchema, handleRegister } from './use-Signup'
import { useAsync } from '../../hooks/use-async'
import { useAuth } from '../../context/auth/auth-context'
import Content from '../../components/Content'
import Validation from '../../components/Validation'
import { Button, Logo, Page, Text } from '@psyomics/components'
import { useRegistration } from 'registration/context/registration/useRegistration'
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom'
import { RegistrationRoutes } from 'registration/context/registration/registration.definitions'
import { useMixpanelEvent } from 'ui/app/useMixpanelEvent'
import FormikRemember from 'registration/utils/FormikRemember/FormikRemember'
import { MixpanelContext } from 'registration/context/mixpanel/mixpanel-context'
import { ReferralDetailsContext } from 'ui/app/context/referralDetails'
import CreationOptions from './CreationOptions'
import * as Sentry from '@sentry/react'
import NhsLanding2 from './NhsLanding2'
import CustomError from 'ui/app/pages/CustomError'
import NotFound from 'ui/app/pages/NotFound'
import DemoFooter from 'ui/app/components/DemoFooter'

const Confirmation = React.lazy(() => import('./Confirmation'))
const Account = React.lazy(() => import('./Account'))

type SignUpProps = React.ComponentPropsWithoutRef<'div'> & {
    referralPublicId?: string
}

const SignUp: React.FC<SignUpProps> = ({ ...props }) => {
    const { referralPublicId } = props
    const { error, isError, isLoading, run } = useAsync()
    const formInitialValues = SignupFormInputsInitialValues
    const formSignupSchema = SignupFormSchema
    const { initializeUser, requireConfirmation } = useAuth()
    const { state } = useRegistration()
    const navigate = useNavigate()
    const mixpanelApiUrl = useContext(MixpanelContext)
    const { accountCreated } = useMixpanelEvent(mixpanelApiUrl)
    const referralDetails = useContext(ReferralDetailsContext)
    const isDemoUser = referralDetails?.data?.organisationCode?.startsWith('demo')
    const userType = state?.userType
    if (!userType) {
        Sentry.captureException(`User type should be defined when signing up. User type passed: ${userType}`, {
            tags: { section: 'Sign Up' },
        })

        return (
            <CustomError
                message={
                    <>
                        <Text size="medium" css={{ mt: 8, mb: 5 }}>
                            It seems that your registration session was interrupted.
                        </Text>
                        <Text size="medium" css={{ mb: 5 }}>
                            This can happen if your browser&apos;s data was cleared or if there was an unexpected issue.
                            To ensure your information is secure and properly processed, we will need you to start the
                            registration process again by opening the referral link in your SMS or Email invitation.
                        </Text>
                        <Text size="medium" css={{ mb: 7 }}>
                            We apologise for the inconvenience and appreciate your understanding.
                        </Text>
                    </>
                }
            />
        )
    }

    if (formInitialValues.userType !== userType) {
        formInitialValues.userType = userType
    }

    if (formInitialValues.referralCode !== state?.referralCode) {
        formInitialValues.referralCode = state?.referralCode
    }

    const pageProps = {
        className: Styles.page,
        layout: 'standard',
        width: 'narrow',
        header: (
            <header className={Styles.header}>
                <div className={Styles.headerOuter}>
                    <div className={Styles.headerInner}>
                        <Logo className={Styles.largeCenseoLogo} height="56" width="190" />
                        <div className={Styles.nav}>
                            <nav>
                                <Button
                                    appearance="link"
                                    size="small"
                                    onClick={() => navigate(-1)}
                                    label="Back"
                                ></Button>
                            </nav>
                        </div>
                    </div>
                </div>
            </header>
        ),
        footer: isDemoUser ? <DemoFooter footerType="patient" /> : undefined,
    }

    return (
        <Page {...pageProps}>
            <Content>
                <Formik
                    initialValues={formInitialValues}
                    validationSchema={formSignupSchema}
                    onSubmit={(values) => {
                        run(
                            handleRegister(values, initializeUser, requireConfirmation).then((user) => {
                                if (!user) {
                                    return
                                }

                                accountCreated(
                                    referralDetails?.data?.organisationCode,
                                    referralDetails?.data?.organisationName,
                                    referralDetails?.data?.publicId
                                )

                                if (state?.referralCode === 'demo') {
                                    navigate('/demo/start-patient-journey')
                                } else {
                                    navigate('/')
                                }
                            })
                        )
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                        validateForm,
                    }) => {
                        if (isError && error) {
                            Sentry.captureException(error, { extra: { errorMessage: 'Error in signup' } })
                        }
                        return (
                            <form onSubmit={handleSubmit}>
                                {/* todo: somehow prevent loading any URL in the middle of the signup process if previous steps haven't been completed! */}
                                <AnimatePresence mode="wait">
                                    <Routes>
                                        <Route
                                            path={RegistrationRoutes.signup}
                                            element={<Navigate to={RegistrationRoutes.confirmation} replace />}
                                        />
                                        <Route
                                            path={'/afterLanding'}
                                            element={
                                                <React.Suspense fallback={<p>loading...</p>}>
                                                    <NhsLanding2 />
                                                </React.Suspense>
                                            }
                                        />
                                        <Route
                                            path={'/confirmation'}
                                            element={
                                                <React.Suspense fallback={<p>loading...</p>}>
                                                    <Confirmation
                                                        values={values}
                                                        errors={errors}
                                                        touched={touched}
                                                        setFieldValue={setFieldValue}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        validateForm={validateForm}
                                                        referralPublicId={referralPublicId}
                                                    />
                                                </React.Suspense>
                                            }
                                        />
                                        <Route
                                            path={'/sign-up-options'}
                                            element={
                                                <React.Suspense fallback={<p>loading...</p>}>
                                                    <CreationOptions
                                                        values={values}
                                                        errors={errors}
                                                        touched={touched}
                                                        setFieldValue={setFieldValue}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        validateForm={validateForm}
                                                    />
                                                </React.Suspense>
                                            }
                                        />
                                        <Route
                                            path={'/account'}
                                            element={
                                                <React.Suspense fallback={<p>loading...</p>}>
                                                    <Account
                                                        values={values}
                                                        errors={errors}
                                                        touched={touched}
                                                        setFieldValue={setFieldValue}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        isLoading={isLoading}
                                                    />
                                                </React.Suspense>
                                            }
                                        />
                                        <Route path="*" element={<NotFound />}></Route>
                                    </Routes>
                                </AnimatePresence>
                                {isError && error && (
                                    <Validation>
                                        {error.name === 'UsernameExistsException' ||
                                        error.message.includes('EnvSettingsMissing') ||
                                        error.message.includes('ReferralNotFoundError') ||
                                        error.message.includes('ReferralUpdateError') ||
                                        error.message.includes('PatientUserCreationError') ? (
                                            <>
                                                Unable to create an account.
                                                <br />
                                                Please contact{' '}
                                                <a
                                                    style={{ color: 'var(--c-teal-mid)' }}
                                                    href="mailto:support@censeo.co.uk"
                                                >
                                                    support@censeo.co.uk
                                                </a>{' '}
                                                for assistance.
                                            </>
                                        ) : (
                                            error.message
                                        )}
                                    </Validation>
                                )}{' '}
                                <FormikRemember
                                    name="signup-form"
                                    ignoreValues={['password', 'confirmPassword']}
                                    clearOnOnmount={true}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </Content>
        </Page>
    )
}

export default SignUp
