import * as Papa from 'papaparse'

export const generateCSVFile = (csvString: string, onComplete: (result: Papa.ParseResult<unknown>) => void): void => {
    Papa.parse<unknown>(csvString, {
        header: true,
        skipEmptyLines: true,
        complete: onComplete,
    })
}

export const downloadCSV = (csvContent: string, filename: string): void => {
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = filename
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    URL.revokeObjectURL(url)
}
