"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateASRS = exports.calculateWURS = exports.functionSummaries = void 0;
const ADHD_1 = require("./generated/ADHD");
exports.functionSummaries = {
    AsrsThreshold: (answers) => calculateASRS(answers).threshold,
    AsrsA: (answers) => calculateASRS(answers).partA,
    AsrsB: (answers) => calculateASRS(answers).partB,
    WursTotal: (answers) => { var _a; return (_a = calculateWURS(answers)) === null || _a === void 0 ? void 0 : _a.main; },
    WursDisruptive: (answers) => { var _a; return (_a = calculateWURS(answers)) === null || _a === void 0 ? void 0 : _a.disruptive; },
    WursAdhd: (answers) => { var _a; return (_a = calculateWURS(answers)) === null || _a === void 0 ? void 0 : _a.adhd; },
    WursAnxiety: (answers) => { var _a; return (_a = calculateWURS(answers)) === null || _a === void 0 ? void 0 : _a.anxietyDisphoria; },
};
function calculateWURS(answers) {
    const questionSubsections = {
        anxietyDisphoria: ['WURS2', 'WURS9', 'WURS11', 'WURS17'],
        adhd: ['WURS1', 'WURS3', 'WURS4', 'WURS7', 'WURS15', 'WURS16', 'WURS23', 'WURS24', 'WURS25'],
        disruptive: [
            'WURS5',
            'WURS6',
            'WURS8',
            'WURS10',
            'WURS12',
            'WURS13',
            'WURS14',
            'WURS18',
            'WURS19',
            'WURS21',
            'WURS22',
        ],
    };
    const wursAnswers = answers.filter((answer) => answer.questionId.startsWith('WURS'));
    if (wursAnswers.length === 0)
        return undefined;
    if (wursAnswers.length !== 25)
        throw new Error('Incorrect number of WURS questions: ' + JSON.stringify(wursAnswers));
    const total = wursAnswers.reduce((acc, answer) => {
        const correspondingQuestion = ADHD_1.questions.find((question) => question.id === answer.questionId);
        if (!correspondingQuestion)
            throw new Error('No WURS question found for :' + answer.questionId);
        if ('answers' in correspondingQuestion) {
            const correspondingAnswer = correspondingQuestion.answers.find((qAnswer) => { var _a; return (_a = answer.chosenOptionIds) === null || _a === void 0 ? void 0 : _a.includes(qAnswer.id); });
            if (!correspondingAnswer)
                throw new Error('No answers found in ADHD WURS question answers for: ' + JSON.stringify(answer.chosenOptionIds));
            if (null === correspondingAnswer.value || undefined === correspondingAnswer.value)
                throw new Error('No answer value found in ADHD WURS question answers for: ' +
                    JSON.stringify(answer.chosenOptionIds));
            acc.main = acc.main + correspondingAnswer.value;
            if (questionSubsections.anxietyDisphoria.includes(answer.questionId)) {
                acc.anxietyDisphoria = acc.anxietyDisphoria + correspondingAnswer.value;
            }
            if (questionSubsections.adhd.includes(answer.questionId)) {
                acc.adhd = acc.adhd + correspondingAnswer.value;
            }
            if (questionSubsections.disruptive.includes(answer.questionId)) {
                acc.disruptive = acc.disruptive + correspondingAnswer.value;
            }
        }
        else {
            throw new Error('not a RestrictedChoiceQuestion: ' + correspondingQuestion.id);
        }
        return acc;
    }, { main: 0, disruptive: 0, adhd: 0, anxietyDisphoria: 0 });
    const adjustmentFactor = 4;
    const adjustedResults = {
        main: String(total.main * adjustmentFactor),
        disruptive: String(Math.round((100 * (total.disruptive * adjustmentFactor)) / questionSubsections.disruptive.length) / 100),
        anxietyDisphoria: String(Math.round((100 * (total.anxietyDisphoria * adjustmentFactor)) / questionSubsections.anxietyDisphoria.length) / 100),
        adhd: String(Math.round((100 * (total.adhd * adjustmentFactor)) / questionSubsections.adhd.length) / 100),
    };
    return adjustedResults;
}
exports.calculateWURS = calculateWURS;
function calculateASRS(answers) {
    const DEFAULT_THRESHOLD_OF_OFTEN = 0.75;
    const DEFAULT_THRESHOLD_OF_SOMETIMES = 0.5;
    const NUMBER_OF_QUESTIONS_IN_PART_A = 6;
    const QUESTION_NUMBER_CLINCAL_AT_SOMETIMES = [1, 2, 3, 9, 12, 16, 18];
    const asrsAnswers = answers.filter((answer) => answer.questionId.startsWith('ASRS'));
    const partAValid = ['ASRS1', 'ASRS2', 'ASRS3', 'ASRS4', 'ASRS5', 'ASRS6'].every((qId) => asrsAnswers.map((answers) => answers.questionId).includes(qId));
    const partBValid = [
        'ASRS7',
        'ASRS8',
        'ASRS9',
        'ASRS10',
        'ASRS11',
        'ASRS12',
        'ASRS13',
        'ASRS14',
        'ASRS15',
        'ASRS16',
    ].every((qId) => asrsAnswers.map((answers) => answers.questionId).includes(qId));
    const total = asrsAnswers.reduce((acc, answer) => {
        const correspondingQuestion = ADHD_1.questions.find((question) => question.id === answer.questionId);
        if (!correspondingQuestion)
            throw new Error('No ASRS question found for :' + answer.questionId);
        if ('answers' in correspondingQuestion) {
            const correspondingAnswer = correspondingQuestion.answers.find((qAnswer) => { var _a; return (_a = answer.chosenOptionIds) === null || _a === void 0 ? void 0 : _a.includes(qAnswer.id); });
            if (!correspondingAnswer)
                throw new Error('No answers found in ADHD ASRS question answers for: ' + JSON.stringify(answer.chosenOptionIds));
            if (null === correspondingAnswer.value || undefined === correspondingAnswer.value)
                throw new Error('No answer value found in ADHD ASRS question answers for: ' +
                    JSON.stringify(answer.chosenOptionIds));
            const questionNumber = parseInt(answer.questionId.replace('ASRS', ''));
            if (isNaN(questionNumber)) {
                throw new Error('Question Number not found for: ' + answer.questionId);
            }
            let increaseScore = false;
            if (QUESTION_NUMBER_CLINCAL_AT_SOMETIMES.includes(questionNumber) &&
                correspondingAnswer.value >= DEFAULT_THRESHOLD_OF_SOMETIMES) {
                increaseScore = true;
            }
            else if (correspondingAnswer.value >= DEFAULT_THRESHOLD_OF_OFTEN) {
                increaseScore = true;
            }
            if (increaseScore) {
                if (questionNumber <= NUMBER_OF_QUESTIONS_IN_PART_A) {
                    acc.partA++;
                }
                else {
                    acc.partB++;
                }
            }
        }
        else {
            throw new Error('not a RestrictedChoiceQuestion: ' + correspondingQuestion.id);
        }
        return acc;
    }, { partA: 0, partB: 0 });
    const returnValue = {
        threshold: partAValid ? (total.partA >= 4 ? 'Yes' : 'No') : undefined,
        partA: partAValid ? String(total.partA) : undefined,
        partB: partBValid ? String(total.partB) : undefined,
    };
    return returnValue;
}
exports.calculateASRS = calculateASRS;
