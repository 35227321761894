"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.questionPredicates = exports.tests = exports.aspects = exports.questions = void 0;
const censeo_1 = require("../../schema/generated/censeo");
exports.questions = [
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "00a6d678-3119-52f2-b166-138301519fff",
                "text": "Continue",
                "value": 1
            }
        ],
        "id": "PC-content",
        "text": "We would like to now ask some questions about you, your current situation and your health. \n\nYou might be asked some questions that don't feel related to your mental health. However, there are many lifestyle factors or situations that can impact on your mental wellbeing.\n\nSelect 'Continue' to start.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "adb85dd3-8bce-5816-8919-5172e3ebe6ac",
                "text": "Living alone",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "9677e03c-ab1a-5b48-aecd-af7f763c2f48",
                "text": "Living in shared accommodation with previously unknown individuals",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "613e5bd0-5dd9-509e-a24c-f517827bb490",
                "text": "Living with friends or family",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "49638d05-afdd-514b-b719-6ad4797225cd",
                "text": "None of the above",
                "value": 1
            }
        ],
        "id": "PC1",
        "text": "Which best describes your current living situation?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "f9ec0083-4f2f-583b-9e14-22833a7f6423",
                "text": "Almost never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "740bdccd-484c-5eb7-ac04-be5f35766291",
                "text": "Quite rarely, around once every 2 weeks",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "913ecb47-51c8-5bd2-beff-5e2e243bd524",
                "text": "Around once per week",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "f208a2bf-1e58-5d84-a53b-e744c38bbbb0",
                "text": "2-4 times per week",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "8ec24a1a-96a7-5a7d-9061-13d13a160dbd",
                "text": "Most days",
                "value": 1
            }
        ],
        "id": "PC2",
        "text": "On average, how often do you spend time with someone you feel close to, such as a close family member or a friend?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "65c9112f-6125-5a66-b2fd-2254c8077fab",
                "text": "No, I don't have anyone to talk to",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "703dd478-5941-53b7-a283-fec67b82fff6",
                "text": "Yes, but I can't contact them",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "5cce3df7-1e4c-55ef-a13a-0cb109a3a6d1",
                "text": "Yes, and I could talk to them",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "d53c3cd3-095c-5a18-bafb-89eada2f9a77",
                "text": "Yes, and I have talked to them",
                "value": 1
            }
        ],
        "id": "PC3",
        "text": "Do you have someone you can talk to about your mental health concerns?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "188c376f-9b12-5b24-b035-24ea5b5bfb54",
                "text": "Yes",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "441dc600-7ec7-5734-b8ce-1379c53b0dd3",
                "text": "No, I am in a relationship but it doesn't feel supportive and stable at the moment",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "7ebcb370-091b-5558-9a2b-6b0f0cf7f14b",
                "text": "No, I am single",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "d91c5927-02a3-5234-89d5-1a52fd8512fd",
                "text": "Prefer not to say",
                "value": 1
            }
        ],
        "id": "PC4",
        "text": "Are you currently in a supportive and relatively stable relationship?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "a9821ab5-609b-5458-8380-8a0b0f4a3db3",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "d87608b6-55b4-5666-a57b-f16e153d089a",
                "text": "Yes",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "7f1a57ff-f4f5-5181-af2e-b1ec1e232884",
                "text": "I am not sure",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "fcc592f7-fc18-57d2-bd66-cb85eebad936",
                "text": "Prefer not to say",
                "value": 1
            }
        ],
        "id": "PC5",
        "text": "Do you feel that your current relationship situation is contributing to any of your mental health concerns?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "00f8401f-796d-5669-9bbd-cb3f489c028f",
                "text": "Employed",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "ba95321c-b9fd-5fc2-a311-e798bc9bae81",
                "text": "Self-employed",
                "value": 0.2
            },
            {
                "__typename": "Option",
                "id": "006dd4de-b660-5a59-b3b5-b0f42a797ce7",
                "text": "Parental leave or taking time off work to care for a family member",
                "value": 0.4
            },
            {
                "__typename": "Option",
                "id": "57d06c34-8ada-50f5-9bb0-6074c3a295eb",
                "text": "Student",
                "value": 0.6
            },
            {
                "__typename": "Option",
                "id": "18552af0-7fca-5d1c-8911-a9d36026a47f",
                "text": "Retired",
                "value": 0.8
            },
            {
                "__typename": "Option",
                "id": "95b6e5e9-0e09-57e3-8445-8b3f821803dd",
                "text": "Unemployed",
                "value": 1
            }
        ],
        "id": "PC6",
        "text": "What is your current employment situation?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "457561c0-98b6-5242-8e12-ab51cf4cc9b0",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "9ea2346f-530d-5da7-80f6-f7e1fde8942e",
                "text": "Yes",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "f6352f59-313e-5493-8aa8-cf613427d226",
                "text": "I am not sure",
                "value": 1
            }
        ],
        "id": "PC7",
        "text": "Do you feel that your current employment situation is contributing to any of your mental health concerns?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "1ef59007-c98c-52e2-9e8e-545e77e49a47",
                "text": "No, I am not concerned about money",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "ba0ebebe-cb38-5941-9aaf-c4f62f4cc7d7",
                "text": "Yes, I am concerned about money",
                "value": 0.2
            },
            {
                "__typename": "Option",
                "id": "0ada9880-67ab-557f-a9ec-64a2179a4149",
                "text": "Yes, I am concerned about money and struggling to pay some of my bills",
                "value": 0.4
            },
            {
                "__typename": "Option",
                "id": "69ae9d2e-f5d7-5ba1-9649-3fc640882fba",
                "text": "Yes, I am concerned about money and not able to pay all or most of my bills",
                "value": 0.6
            },
            {
                "__typename": "Option",
                "id": "eb0df2de-d214-5ff7-9e94-34b27ad8b5f9",
                "text": "Yes, I am concerned about money and not able to pay my bills and have substantial debts",
                "value": 0.8
            },
            {
                "__typename": "Option",
                "id": "93654a5f-8ef9-54b4-9b94-d9b6dea5914e",
                "text": "Prefer not to say",
                "value": 1
            }
        ],
        "id": "PC8",
        "text": "Are you currently concerned about money and struggling to pay your household bills (e.g. rent or mortgage, utility bills, buying food)?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "5dcab7c1-c884-512b-a893-94f4b41a0373",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "e1c503e9-8baf-5773-bea6-0e4c0e9d5d7f",
                "text": "Yes",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "a0738b11-edf6-5742-9020-8e165432cca8",
                "text": "I am not sure",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "bfa6e007-b64b-50c8-849c-be18d4ce658f",
                "text": "Prefer not to say",
                "value": 1
            }
        ],
        "id": "PC9",
        "text": "Do you feel that your current finanical situation is contributing to any of your mental health concerns?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "13bb3203-2370-54ec-8015-00feab942b55",
                "text": "I have a permanent place of residence (i.e. I own my own home, I rent a house or a room in a house, I live in my family home)",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "46d2d3fc-09de-534f-b15a-6f3e377813c0",
                "text": "I have no permanent place of residence",
                "value": 1
            }
        ],
        "id": "PC10",
        "text": "Which best described your current living situation?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "dda0d067-918b-5f1e-8743-b9a66d869afa",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "a40cc7a8-b47b-501e-a5a8-df8b4319c491",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "PC11",
        "text": "Do you worry that you will suddenly become homeless?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "d8c660d5-fb13-529e-b799-5d797e68e000",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "25230108-cdd8-55cf-9866-daf316466c27",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "PC12",
        "text": "Do you have any children under the age of 18?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "8edeb769-4512-5d5a-bbe8-2b45c0835299",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "5bf1aeaf-025c-5772-b9aa-1956549c4427",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "PC13",
        "text": "Have you had a baby in the last 12 months?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "b97973c6-df76-5c48-84db-e351c57a4f43",
                "text": "My spouse/partner"
            },
            {
                "__typename": "Option",
                "id": "1e3c88aa-bfd2-59e5-852a-1e12fea3d9f9",
                "text": "My child/children over the age of 18"
            },
            {
                "__typename": "Option",
                "id": "6cccfb8e-3986-5b84-84b2-f4320a764677",
                "text": "My sibling(s)"
            },
            {
                "__typename": "Option",
                "id": "51c43a8e-62a4-52e4-bca7-75ae6c1a36e4",
                "text": "My parent(s) or grandparent(s)"
            },
            {
                "__typename": "Option",
                "id": "0693f6e7-3aa4-57bd-b86d-d9913ec377d9",
                "text": "A different person/people not listed here (e.g. a cousin, a friend)"
            },
            {
                "__typename": "Option",
                "id": "63653abe-a120-5161-a6f0-994529ae7540",
                "text": "I am not an unpaid carer",
                "exclusive": true
            }
        ],
        "id": "PC14",
        "text": "Are you an unpaid carer for any of the following people? Please select all options that apply to you. \n \nAn unpaid carer is someone who (without being paid to do so) cares for a friend or family member who, due to illness, disability or a mental health concern, cannot cope without their support.",
        "isMultiselect": true
    },
    {
        "__typename": "FreeQuestion",
        "id": "PC15",
        "text": "How much do you weigh, in KG?",
        "unit": censeo_1.Unit.None
    },
    {
        "__typename": "FreeQuestion",
        "id": "PC16",
        "text": "How tall are you, in CM?",
        "unit": censeo_1.Unit.None
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "da1140f3-7d70-58dc-ad28-28a600c62677",
                "text": "My physical health is extremely poor",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "c5c5016f-7873-530b-bf5f-55af8da1a75f",
                "text": "My physical health is very poor",
                "value": 0.2
            },
            {
                "__typename": "Option",
                "id": "e7e89d3f-3cdf-548a-906c-91abc57b1c55",
                "text": "My physical health is quite poor",
                "value": 0.4
            },
            {
                "__typename": "Option",
                "id": "af273dec-7733-58fc-b069-f1aa4fcebae1",
                "text": "My physical health is OK",
                "value": 0.6
            },
            {
                "__typename": "Option",
                "id": "a6814f67-54b8-56cc-92cb-b77252c3be2a",
                "text": "My physical health is quite good",
                "value": 0.8
            },
            {
                "__typename": "Option",
                "id": "dfcbdad2-6a53-5eef-b057-9e70096c8944",
                "text": "My physical health is very good",
                "value": 1
            }
        ],
        "id": "PC17",
        "text": "How would you rate your overall physical health?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "1a5ddd02-d650-5614-8128-b22afbfbdbd9",
                "text": "I have not been diagnosed with a physical health condition which impacts my wellbeing or mental health",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "6b496a0d-54e9-544e-9292-c58c9eaee687",
                "text": "Yes, I have been diagnosed with a physical health condition which impacts my wellbeing or mental health",
                "value": 1
            }
        ],
        "id": "PC18",
        "text": "There are some health conditions that can have an effect on your mood and energy levels.\n\nHave you ever been diagnosed with any physical health conditions by a GP or healthcare professional which impact your wellbeing or mental health?",
        "isMultiselect": false
    },
    {
        "__typename": "FreeTextQuestion",
        "id": "PC19",
        "text": "Please can you give us some brief details about these physical health conditions, and how they impact your wellbeing or mental health:",
        "userType": censeo_1.UserType.Nhs
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "258cdc86-94d2-510c-8918-31b7fd1df3bd",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "8e17946a-2e79-5e6c-b835-4aaf2f1ac6f8",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "PC20",
        "text": "Are you, or your partner, currently pregnant?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "895135d1-2301-5161-b9be-56accb1d5fcf",
                "text": "My mental health is extremely poor",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "49543cb6-9ae4-56fb-aaa4-d875346a0919",
                "text": "My mental health is very poor",
                "value": 0.2
            },
            {
                "__typename": "Option",
                "id": "8adfde93-2f51-590c-8a8b-a326049d74c7",
                "text": "My mental health is quite poor",
                "value": 0.4
            },
            {
                "__typename": "Option",
                "id": "4805b7f8-78e9-56b2-8b92-8b6580fb785a",
                "text": "My mental health is OK",
                "value": 0.6
            },
            {
                "__typename": "Option",
                "id": "039fd69a-3b3a-5ca7-86ae-dc819903fe68",
                "text": "My mental health is quite good",
                "value": 0.8
            },
            {
                "__typename": "Option",
                "id": "b4a39ab9-1595-546f-8d02-3a27ecffbab8",
                "text": "My mental health is very good",
                "value": 1
            }
        ],
        "id": "PC21",
        "text": "If you had to rate your mental health concerns, how would you rate your overall mental health?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "1c67990e-095f-51cc-9448-2d1da3a30405",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "e1ded407-79fd-5f37-8c5b-19e738980ad2",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "PC22",
        "text": "Have you ever been diagnosed with any mental health conditions by a healthcare professional (i.e., a GP, a psychiatrist, a psychologist, a counsellor)?",
        "isMultiselect": false
    },
    {
        "__typename": "FreeTextQuestion",
        "id": "PC23",
        "text": "Please can you provide us a list of any mental health conditions you have been diagnosed with?",
        "userType": censeo_1.UserType.Nhs
    },
    {
        "__typename": "FreeTextQuestion",
        "id": "PC24",
        "text": "Is there anything else about your current situation which you think is contributing to your mental health concerns?",
        "userType": censeo_1.UserType.Nhs
    }
];
exports.aspects = {
    "pc": {
        "type": "static"
    },
    "PC14_spouse": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "PC14",
            "options": {
                "type": "severities",
                "severities": {
                    "b97973c6-df76-5c48-84db-e351c57a4f43": 1
                }
            }
        }
    },
    "PC14_child": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "PC14",
            "options": {
                "type": "severities",
                "severities": {
                    "1e3c88aa-bfd2-59e5-852a-1e12fea3d9f9": 1
                }
            }
        }
    },
    "PC14_sibling": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "PC14",
            "options": {
                "type": "severities",
                "severities": {
                    "6cccfb8e-3986-5b84-84b2-f4320a764677": 1
                }
            }
        }
    },
    "PC14_parent": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "PC14",
            "options": {
                "type": "severities",
                "severities": {
                    "51c43a8e-62a4-52e4-bca7-75ae6c1a36e4": 1
                }
            }
        }
    },
    "PC14_other": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "PC14",
            "options": {
                "type": "severities",
                "severities": {
                    "0693f6e7-3aa4-57bd-b86d-d9913ec377d9": 1
                }
            }
        }
    },
    "PC14_none": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "PC14",
            "options": {
                "type": "severities",
                "severities": {
                    "63653abe-a120-5161-a6f0-994529ae7540": 1
                }
            }
        }
    },
    "not(PC8=0)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "PC8",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "1ef59007-c98c-52e2-9e8e-545e77e49a47": true,
                        "ba0ebebe-cb38-5941-9aaf-c4f62f4cc7d7": false,
                        "0ada9880-67ab-557f-a9ec-64a2179a4149": false,
                        "69ae9d2e-f5d7-5ba1-9649-3fc640882fba": false,
                        "eb0df2de-d214-5ff7-9e94-34b27ad8b5f9": false,
                        "93654a5f-8ef9-54b4-9b94-d9b6dea5914e": false
                    }
                }
            }
        }
    },
    "and[or[isUndefined(PC8=0), not(PC8=0)], not(isUndefined(PC10=0))]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AnsweredOption",
                                "questionId": "PC8",
                                "options": {
                                    "type": "predicates",
                                    "predicates": {
                                        "1ef59007-c98c-52e2-9e8e-545e77e49a47": true,
                                        "ba0ebebe-cb38-5941-9aaf-c4f62f4cc7d7": false,
                                        "0ada9880-67ab-557f-a9ec-64a2179a4149": false,
                                        "69ae9d2e-f5d7-5ba1-9649-3fc640882fba": false,
                                        "eb0df2de-d214-5ff7-9e94-34b27ad8b5f9": false,
                                        "93654a5f-8ef9-54b4-9b94-d9b6dea5914e": false
                                    }
                                }
                            }
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "not(PC8=0)"
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "not",
                "wire": {
                    "type": "isUnknown",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "PC10",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "13bb3203-2370-54ec-8015-00feab942b55": true,
                                    "46d2d3fc-09de-534f-b15a-6f3e377813c0": false
                                }
                            }
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "PC12=1": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "PC12",
            "options": {
                "type": "predicates",
                "predicates": {
                    "d8c660d5-fb13-529e-b799-5d797e68e000": false,
                    "25230108-cdd8-55cf-9866-daf316466c27": true
                }
            }
        }
    },
    "PC18=1": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "PC18",
            "options": {
                "type": "predicates",
                "predicates": {
                    "1a5ddd02-d650-5614-8128-b22afbfbdbd9": false,
                    "6b496a0d-54e9-544e-9292-c58c9eaee687": true
                }
            }
        }
    },
    "PC22=1": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "PC22",
            "options": {
                "type": "predicates",
                "predicates": {
                    "1c67990e-095f-51cc-9448-2d1da3a30405": false,
                    "e1ded407-79fd-5f37-8c5b-19e738980ad2": true
                }
            }
        }
    }
};
exports.tests = {};
exports.questionPredicates = {
    "PC9": "not(PC8=0)",
    "PC11": "and[or[isUndefined(PC8=0), not(PC8=0)], not(isUndefined(PC10=0))]",
    "PC13": "PC12=1",
    "PC19": "PC18=1",
    "PC23": "PC22=1"
};
