"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.symptoms = void 0;
exports.symptoms = [
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "BP113.1",
                "BP8.1",
                "BP16.1",
                "BP74.1"
            ],
            "prefix": "",
            "formatText": "{} time(s) due to manic symptoms"
        },
        "section": [
            "Hospitalised"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "BP113.2",
                "BP8.2",
                "BP16.2",
                "BP74.2",
                "BP83.1"
            ],
            "prefix": "",
            "formatText": "{}"
        },
        "section": [
            "Duration of hospital stay:"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "BP113.3",
                "BP8.3",
                "BP16.3",
                "BP74.3"
            ],
            "prefix": "",
            "formatText": "{}"
        },
        "section": [
            "Duration of longest hospital stay:"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} to hospital due to symptoms",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "BP113.4",
                    "optionSummaries": {
                        "bd60deee-3a91-5bc5-9487-267c44221755": "No formal admission",
                        "126719e4-fe06-5b9a-9791-f58c8f290fd4": "Formal admission"
                    }
                },
                {
                    "questionId": "BP8.4",
                    "optionSummaries": {
                        "16b49c5e-2955-5b04-997e-e975eb932180": "No formal admission",
                        "eaa4eab0-aec1-5be4-832a-67ef473744d7": "Formal admission"
                    }
                },
                {
                    "questionId": "BP16.4",
                    "optionSummaries": {
                        "2d4daf12-3d1d-5d5b-b03b-8ae16663db77": "No formal admission",
                        "3dc6f331-9c62-574c-afd4-6c281e71b14c": "Formal admission"
                    }
                },
                {
                    "questionId": "BP74.4",
                    "optionSummaries": {
                        "e99f2054-5f88-5322-b642-9257a24f81be": "No formal admission",
                        "45734e40-8054-5f6b-8c8f-a9410fe95696": "Formal admission"
                    }
                },
                {
                    "questionId": "BP83.2",
                    "optionSummaries": {
                        "91374129-e29c-5341-a28e-736721ee7128": "No formal admission",
                        "c827fdc7-e41c-5fdf-b4d0-71b50a9d97ed": "Formal admission"
                    }
                }
            ]
        },
        "section": [
            "Formal admittance to hospital due to symptoms:"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "D-5"
            ],
            "prefix": "",
            "formatText": "{} time(s) due to depressive symptoms"
        },
        "section": [
            "Hospitalised"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "D-6"
            ],
            "prefix": "",
            "formatText": "{}"
        },
        "section": [
            "Duration of hospital stay:"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "D-7"
            ],
            "prefix": "",
            "formatText": "{}"
        },
        "section": [
            "Duration of longest stay in hospital:"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "D-8"
            ],
            "prefix": "",
            "formatText": "{}"
        },
        "section": [
            "Formal admittance to hospital due to symptoms:"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "BP112.1"
            ],
            "prefix": "Number of past episodes lasting more than 1 week: ",
            "formatText": "{}"
        },
        "section": [
            "Previous manic episode(s) details"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "BP112.4"
            ],
            "prefix": "Number of past episodes lasting at least 4 days: ",
            "formatText": "{}"
        },
        "section": [
            "Previous manic episode(s) details"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "BP3.01",
                "BP67"
            ],
            "prefix": "Number of past episodes: ",
            "formatText": "{}"
        },
        "section": [
            "Previous manic episode(s) details"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "BP2",
                "BP68"
            ],
            "prefix": "Age at first episode onset: ",
            "formatText": "{}"
        },
        "section": [
            "Previous manic episode(s) details"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} per day",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "BP3.1",
                    "optionSummaries": {
                        "b56edfbb-cf11-50ee-9149-9e36f4cc1ea0": "less than 1 hour",
                        "5bf1e313-2624-5b5f-9673-37ae9319ff0c": "1–3 hours",
                        "11a8ab35-b2d7-55a4-8a77-809dc8f1f78a": "4–6 hours",
                        "9531c3ff-ab2b-5198-a490-919ede5afb07": "7–9 hours",
                        "c31cf816-04e0-52f4-8da3-04437042c014": "10–12 hours",
                        "c053dd45-79bd-59bf-82df-7e9ecd85dc11": "more than 12 hours"
                    }
                }
            ]
        },
        "section": [
            "Previous manic episode(s) details",
            "Duration of longest previous manic episode"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "BP3.2",
                    "optionSummaries": {
                        "43011291-20a8-59ce-8bac-df882587ff5c": "one day per week",
                        "ec333f8b-146a-5700-a15d-527032ceac3f": "2–3 days per week",
                        "c3319113-403a-5ecb-aa8a-071221d92c50": "4–5 days per week",
                        "f6654515-5729-5299-a4f0-da99056e7ac9": "6–7 days per week"
                    }
                }
            ]
        },
        "section": [
            "Previous manic episode(s) details",
            "Duration of longest previous manic episode"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "BP3.3"
            ],
            "prefix": "for ",
            "formatText": "{}"
        },
        "section": [
            "Previous manic episode(s) details",
            "Duration of longest previous manic episode"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "longest episode duration is '{} certain'",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "BP3.4",
                    "optionSummaries": {
                        "93412936-97c0-5128-ad67-3fa955db1300": "not at all",
                        "f304bf93-0c68-5da0-80ba-ccecc2d0fce1": "not very",
                        "6ec82bbf-7582-5509-ace2-5ecf0ce82ec3": "moderately",
                        "8eb41335-a8c4-5822-9881-b0383524cb1c": "very"
                    }
                }
            ]
        },
        "section": [
            "Previous manic episode(s) details",
            "Duration of longest previous manic episode"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "D-1.5b"
            ],
            "prefix": "Number of previous depressive episodes: ",
            "formatText": "{}"
        },
        "section": [
            "Previous depressive episode(s) details"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "D-2"
            ],
            "prefix": "Age at time of first episode: ",
            "formatText": "{}"
        },
        "section": [
            "Previous depressive episode(s) details"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} per day",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "BP110.2",
                    "optionSummaries": {
                        "6d6f6520-7078-5bed-b90a-ee565f1a1808": "less than 1 hour",
                        "c179d355-032c-5287-9119-1349133180ef": "1–3 hours",
                        "d15430b9-4f4f-5c97-8716-cb907c561dd2": "4–6 hours",
                        "faf183ab-b9b4-5f0d-a996-dfa04be4e401": "7–9 hours",
                        "dbc05d00-3f66-51fd-825b-712e8609aa9f": "10–12 hours",
                        "38ac373b-568f-5787-b9f1-de042f507c61": "more than 12 hours"
                    }
                }
            ]
        },
        "section": [
            "Current manic symptoms",
            "Duration of manic symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "BP110.1",
                    "optionSummaries": {
                        "19f0b41f-9236-571f-8db4-20bd59fb8509": "one day per week",
                        "6a7ef643-1318-5d1c-af19-efebc5d5a5f5": "2–3 days per week",
                        "9d19a3c0-120d-5dfd-a794-8aa0e8d39e77": "4–5 days per week",
                        "9ce31a68-0e9d-55e2-a573-174bcbeb7458": "6–7 days per week"
                    }
                }
            ]
        },
        "section": [
            "Current manic symptoms",
            "Duration of manic symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "BP110"
            ],
            "prefix": "for ",
            "formatText": "{}"
        },
        "section": [
            "Current manic symptoms",
            "Duration of manic symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "episode duration estimate is '{} certain'",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "BP110.01",
                    "optionSummaries": {
                        "a6d87c31-9a11-5151-958a-040439463b3d": "not at all",
                        "85bf649a-4487-5b00-8c08-82ad143ea966": "not very",
                        "c7cabc22-64a2-5eab-b570-889f9a8f782b": "moderately",
                        "7c60a3ff-2742-5128-b0a0-3157bfe8c0f5": "very"
                    }
                }
            ]
        },
        "section": [
            "Current manic symptoms",
            "Duration of manic symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "BP106",
                    "optionSummaries": {
                        "e408e8a3-8395-5c34-992a-3c1ff862dc84": "persistently happier and more excitable",
                        "8c35da7e-8c0b-50bb-a037-b80a9f1df3d2": "persistently more irritable",
                        "3648c32c-0ce7-5c1d-a7e8-d45a899b40f9": "persistently more elated, but also irritable",
                        "9658b463-86ce-5495-a40a-7361dc568105": "cannot pinpoint mood change, but much more energetic and proactive"
                    }
                }
            ]
        },
        "section": [
            "Current manic symptoms",
            "Emotional symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "sexual thoughts and feelings {}prominent",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "HM41",
                    "optionSummaries": {
                        "5dc47933-8a72-5087-b068-38e22758b9f7": "slightly more ",
                        "db39ddc0-79fd-5c61-b063-7f0bc0bab024": "moderately more ",
                        "e8be146c-dcaf-5060-ad4a-468ca2878cb2": "significantly more ",
                        "8fea83cc-74c5-577c-b8ed-8d538140b44d": "very much more "
                    }
                }
            ]
        },
        "section": [
            "Current manic symptoms",
            "Emotional symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} social inhibition",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "BP126",
                    "optionSummaries": {
                        "52d061e3-96b8-5053-893a-03758e4f0518": "slightly lowered",
                        "3b0900cc-ebc0-5606-a6f6-066c9c6c2c97": "moderately lowered",
                        "47856c8e-63c1-5066-bf95-92ff01e0982a": "significantly lowered",
                        "f5e0c5dc-06c9-5f60-9b97-3b79d63a406c": "drastically lowered"
                    }
                }
            ]
        },
        "section": [
            "Current manic symptoms",
            "Social symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} more sociable and outgoing",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "BP117",
                    "optionSummaries": {
                        "c68c6fcb-e62b-5749-80e4-9c4ad9995b97": "somewhat",
                        "aae4e65c-c66b-5e2d-8a18-88b17f5e6eb8": "moderately",
                        "0d476185-4df9-5c70-837d-2b5ff4e08893": "significantly",
                        "1d169f46-d28a-5b85-b98f-31e378c13d40": "very much"
                    }
                }
            ]
        },
        "section": [
            "Current manic symptoms",
            "Social symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "being the life and soul of every social gathering: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "HM40",
                    "optionSummaries": {
                        "3110a9c5-47d0-5e35-952b-74c6a481b635": "to a slight degree",
                        "82682922-e4e5-5674-9a94-b96f5d798846": "to a moderate degree",
                        "7606c31a-ed49-584c-9e82-c909a18843cd": "to a significant degree",
                        "449c3bd8-c1d4-530d-8bcc-e4bf16434a9d": "very much"
                    }
                }
            ]
        },
        "section": [
            "Current manic symptoms",
            "Social symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "meeting {}more people than usual",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "HM37",
                    "optionSummaries": {
                        "a2c38330-1fdc-55c8-9a19-209f55bd344f": "slightly ",
                        "77031c75-3438-5152-9142-8478bb9bdc66": "moderately ",
                        "cfd0a32c-e988-5dce-85c8-e07628f719b5": "significantly ",
                        "05934e5e-fca9-566e-9a69-eec32b7da87b": "very "
                    }
                }
            ]
        },
        "section": [
            "Current manic symptoms",
            "Social symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} flirtatious and sexually active",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "HM45",
                    "optionSummaries": {
                        "2722d440-372d-5471-8cab-ef78b8026a24": "slightly more",
                        "637476f9-73ea-5066-9b3e-8c4bc05c3b51": "moderately more",
                        "a4667066-a91a-56d4-8b53-682a65490819": "significantly more",
                        "9cfe579e-f4fa-589a-bcd7-1f28243d92f2": "very much more"
                    }
                }
            ]
        },
        "section": [
            "Current manic symptoms",
            "Social symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} talkative and speaking faster",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "BP120",
                    "optionSummaries": {
                        "c8162ccf-aae0-5678-aeb9-fe02ed13ad99": "slightly more",
                        "3c0c2a71-99b9-5210-b4e7-2734537fe5f4": "moderately more",
                        "e500747f-17cd-555a-a1e8-8968fd2be980": "significantly more",
                        "137ac9f7-52c3-5502-8d0f-653df1384d35": "very much more"
                    }
                },
                {
                    "questionId": "HM36",
                    "optionSummaries": {
                        "fc7ec28a-9883-58d5-a088-f3b113c2b4b3": "slightly more",
                        "10794c2e-d0f1-5546-87aa-f3bc40003974": "moderately more",
                        "ee51f6bc-2dbd-5285-9ef7-f667a7a1bdb0": "significantly more",
                        "79ac7b3c-38af-5670-b744-5d39000401df": "very much more"
                    }
                }
            ]
        },
        "section": [
            "Current manic symptoms",
            "Social symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "others noticing {}faster speech",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "BP123",
                    "optionSummaries": {
                        "5c6d0cea-9bce-5b98-b6ad-dcb0e3fb6b0d": ""
                    }
                }
            ]
        },
        "section": [
            "Current manic symptoms",
            "Social symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} increase in making jokes and puns",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "HM48",
                    "optionSummaries": {
                        "2db89264-9c8a-54e5-8efc-1b1ca45b0233": "slight",
                        "9b74dd11-97db-567e-8d3d-836001d82229": "moderate",
                        "c140b03a-5169-5731-838a-a63f017c99b6": "significant",
                        "fdd2a948-7ae9-5aef-bf30-81970a357202": "very large"
                    }
                }
            ]
        },
        "section": [
            "Current manic symptoms",
            "Social symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} due to excessive and foolish activities",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "BP127.1",
                    "optionSummaries": {
                        "b76730b2-4e72-5ab7-b0c7-03b88d01b19c": "been in a bit of trouble",
                        "68c97dc1-4b15-59a6-b2ae-49597771e772": "been in serious trouble"
                    }
                }
            ]
        },
        "section": [
            "Current manic symptoms",
            "Impulsivity symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} due to risky and dangerous activities",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "BP127.2",
                    "optionSummaries": {
                        "f44c6fd9-72d7-5254-bf0d-52045ae546c7": "been in a bit of trouble",
                        "2e4d8704-5188-5d7c-bfaf-6c83462b3621": "been in serious trouble"
                    }
                }
            ]
        },
        "section": [
            "Current manic symptoms",
            "Impulsivity symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} irresponsible with money",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "BP127.3",
                    "optionSummaries": {
                        "38efc61e-4155-51f0-8b64-341f627e5482": "slightly",
                        "2d74a699-1958-513e-94d7-e25ebf081a91": "quite",
                        "cd346eed-7344-51aa-aa80-7964a3dce896": "very",
                        "e6bc1265-542f-5413-9743-ee7e5adec6e3": "extremely"
                    }
                },
                {
                    "questionId": "HM47",
                    "optionSummaries": {
                        "e03a7683-7e3e-563d-b531-33e529887c90": "slightly",
                        "c0256df5-0653-5e2e-b656-58097a53c002": "quite",
                        "49d31acb-f117-54a2-b2bc-5680ccad6e6f": "very",
                        "6f3ea1ee-6ac6-53b8-804e-609d817b88da": "extremely"
                    }
                }
            ]
        },
        "section": [
            "Current manic symptoms",
            "Impulsivity symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} distractibility",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "BP129",
                    "optionSummaries": {
                        "c534368a-9c51-5f0d-8b17-9df288fd59a0": "slightly increased",
                        "00293814-728b-5c19-8744-73c54d0d4db2": "moderately increased",
                        "4b90a2f8-960f-5d69-8e86-9c46af2fec0f": "significantly increased",
                        "477c3730-71ba-505f-9caa-07a83398580f": "very much increased"
                    }
                }
            ]
        },
        "section": [
            "Current manic symptoms",
            "Thinking symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "thinking {}faster and clearer",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "HM49",
                    "optionSummaries": {
                        "555cca84-b650-53ab-944e-2289adb21903": "slightly ",
                        "7a5feb6e-c27f-54d2-9473-fc197426685c": "moderately ",
                        "b6ba01ed-b20b-5d63-8093-eb7c245c8f85": "significantly ",
                        "7c044595-1479-5f7c-b2ac-b356553a69c2": "very much "
                    }
                }
            ]
        },
        "section": [
            "Current manic symptoms",
            "Thinking symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} more creative",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "BP130",
                    "optionSummaries": {
                        "b4725e03-4f40-5305-9d3d-1ff9297f8bff": "slightly",
                        "bf6cdff0-63a3-5516-b548-b3c2d0ef83ab": "moderately",
                        "a04d679f-abeb-5fb0-9db7-2ed9997ab672": "significantly",
                        "a2d91cbf-fa72-5028-91d6-9f00d0ce8aa3": "very much"
                    }
                }
            ]
        },
        "section": [
            "Current manic symptoms",
            "Thinking symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} more racing thoughts which cannot be slowed down",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "BP122",
                    "optionSummaries": {
                        "08c42ff1-0b0f-5018-8e88-552f8b8cf02a": "slightly",
                        "f1f5184d-35fb-5cac-b89f-4a4015d9de77": "moderately",
                        "b1759380-318d-54b0-9224-163fb659558b": "significantly",
                        "3d008b0e-8759-568c-9aae-ba21c8b6ae79": "very much"
                    }
                }
            ]
        },
        "section": [
            "Current manic symptoms",
            "Thinking symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "higher energy levels",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "BP108",
                    "optionSummaries": {
                        "d25b13ec-c438-5509-85b0-83a43b6793d4": ""
                    }
                }
            ]
        },
        "section": [
            "Current manic symptoms",
            "Activity symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} need for sleep",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "BP118",
                    "optionSummaries": {
                        "a221f7d7-f43a-5e8f-ac87-bb3cc3a447f3": "slightly reduced",
                        "a611c3a0-727b-5a9b-8d15-d805b2ecf757": "moderately reduced",
                        "e642f54c-d6fc-57f8-a53c-73dbd698d428": "significantly reduced",
                        "1eda0859-de62-56d0-836a-3f1778a6064b": "severely reduced"
                    }
                }
            ]
        },
        "section": [
            "Current manic symptoms",
            "Activity symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} restlessness",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "BP125",
                    "optionSummaries": {
                        "d7075f96-ec23-51f7-9293-9bf725963409": "slightly increased",
                        "8136d074-8df6-5a91-b6b6-e8ffcfb42d7c": "moderately increased",
                        "1926be5c-c655-5494-8fb8-9492f4a4324e": "significantly increased",
                        "19dd84c4-ccf5-5060-83ae-e1f2f977f1c0": "very much increased"
                    }
                }
            ]
        },
        "section": [
            "Current manic symptoms",
            "Activity symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "feeling {}more active",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "BP116",
                    "optionSummaries": {
                        "2d30ef1e-59ee-5b0a-8c01-0dcbfd64f6ca": "slightly ",
                        "cd9085de-4e15-5383-b814-a261f79b136f": "moderately ",
                        "bab62178-4367-5df2-99ea-d5224dffb53e": "significantly ",
                        "68c9cbe1-3f8f-5168-a960-d5ff6e8bb716": "very much "
                    }
                }
            ]
        },
        "section": [
            "Current manic symptoms",
            "Activity symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "BP124",
                    "optionSummaries": {
                        "d7e7a81a-6129-54d0-8a45-35c7e9ae3bf3": "wanting to be everywhere and do everything to a slight extent",
                        "343ddd19-6dd5-54fb-a817-730184c2faf1": "wanting to be everywhere and do everything to a moderate extent",
                        "29c09b6c-a1ca-5b73-9d71-1eeef40c8aef": "wanting to be everywhere and do everything to a significant extent",
                        "368a583d-81fc-5f77-a51c-647fd7f8f473": "wanting to be everywhere and do everything to a severe extent"
                    }
                }
            ]
        },
        "section": [
            "Current manic symptoms",
            "Activity symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "HM46",
                    "optionSummaries": {
                        "a54e0650-9e33-5e16-a4a9-2c31bc10c96c": "completing tasks more quickly and easily to a slight extent",
                        "a57434cf-7c45-5e3d-9d0d-c8b432490e99": "completing tasks more quickly and easily to a moderate extent",
                        "772a32ae-a62a-57a3-9d99-92106761d701": "completing tasks more quickly and easily to a significant extent",
                        "4aa7c56d-2337-5383-80d4-d0599322231e": "completing tasks more quickly and easily to an extreme extent"
                    }
                }
            ]
        },
        "section": [
            "Current manic symptoms",
            "Activity symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} motivated to get involved in as many activities as possible",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "HM35",
                    "optionSummaries": {
                        "2b40020d-33ba-5560-9075-d482aa324496": "slightly",
                        "9eeab515-74ed-5cdc-8b02-9ce8c1a2d197": "moderately",
                        "13ede675-77ba-58af-911e-c3a4457f4e56": "significantly",
                        "fef4dc4e-6c90-5b86-88ae-e9a5ee7c9d5a": "very much"
                    }
                }
            ]
        },
        "section": [
            "Current manic symptoms",
            "Activity symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} auditory or visual hallucinations",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "BP132",
                    "optionSummaries": {
                        "7da9fe3a-3186-562a-b4f2-170d5baf0c76": "experienced"
                    }
                }
            ]
        },
        "section": [
            "Current manic symptoms",
            "Psychotic symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} delusions",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "BP131",
                    "optionSummaries": {
                        "cf80f9fa-de7b-5954-b005-e9ec1b1b07a9": "experienced"
                    }
                }
            ]
        },
        "section": [
            "Current manic symptoms",
            "Psychotic symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "experience hallucinations or delusions {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "BP133",
                    "optionSummaries": {
                        "2e0701e7-7bb8-5a5d-9e33-d95fac9352d6": "only when mood is 'up'",
                        "75391070-9bde-59e4-b44a-68f38b6a4472": "but unsure if these relate to mood",
                        "36121dba-4b4b-584f-9f5b-4b715cf0b2af": "when mood is 'up' and when mood is normal"
                    }
                }
            ]
        },
        "section": [
            "Current manic symptoms",
            "Psychotic symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "CombinedAnswersSummary",
            "questionsIdGroups": [
                [
                    "BP115"
                ],
                [
                    "HM38"
                ]
            ],
            "groupsType": "Mean",
            "pattern": "{} self-esteem",
            "coefficients": {
                "0.1": "slightly inflated",
                "0.3": "moderately inflated",
                "0.6": "significantly inflated"
            }
        },
        "section": [
            "Current manic symptoms",
            "Other symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "wearing {}more colourful clothes and/or makeup",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "HM43",
                    "optionSummaries": {
                        "d41a8ccb-18d3-58ef-b09a-b2b0e34dc48c": "slightly ",
                        "ad8a0302-30ec-5868-a265-87c35baefdac": "moderately ",
                        "08b88c09-4278-5bd5-b72d-4f4a9a69acd0": "significantly ",
                        "2023b400-6f09-59ac-b67e-657f6d592763": "very much "
                    }
                }
            ]
        },
        "section": [
            "Current manic symptoms",
            "Other symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "current episode is {} previous episode",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "BP112.2",
                    "optionSummaries": {
                        "6079cfc0-03c5-5003-87ad-3dff357f0f74": "less severe than",
                        "9ff851b9-a640-557e-a750-a4d6e2ffbc49": "just as severe as",
                        "0220e07a-c424-5583-b5a4-d53440a7bc6e": "more severe than"
                    }
                },
                {
                    "questionId": "BP112.5",
                    "optionSummaries": {
                        "71ad4086-a47a-5265-86b2-eed25d6d5abe": "less severe than",
                        "7e08ea1c-7f7d-5f72-87fb-1df882aeed10": "just as severe as",
                        "e1e59a23-091e-54fa-b3ce-49a3fa26fb2a": "more severe than"
                    }
                }
            ]
        },
        "section": [
            "Current manic symptoms",
            "Current episode is just as severe as previous episode"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} most severe episode",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "BP112.3",
                    "optionSummaries": {
                        "f596911a-c0b8-51b8-a6c3-e0e50444761a": "less extreme than",
                        "dc8d63aa-5242-52d9-b439-526944ab2713": "just as extreme as",
                        "5de2cc88-13a4-5f04-a3d2-1333852193f9": "more extreme than"
                    }
                },
                {
                    "questionId": "BP112.6",
                    "optionSummaries": {
                        "a2924f91-50e4-560e-a7e7-327ed9b0ad26": "less extreme than",
                        "bbc68d1c-8068-5eb7-a7ff-600e2ccb4e9f": "just as extreme as",
                        "3cd21659-e85a-524c-8549-44f033916396": "more extreme than"
                    }
                }
            ]
        },
        "section": [
            "Current manic symptoms",
            "Current episode is just as severe as previous episode"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Basic needs: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "BP118.1",
                    "optionSummaries": {
                        "3299a8a7-4375-55ee-87b2-76bda766fd11": "no impairment",
                        "a7937947-986d-5fbf-83cd-d1c893dcdc7a": "mild",
                        "801fd4db-2f09-5e10-a9b7-43319a3ac1d0": "moderate",
                        "f80f4b1b-7286-52d0-8218-7083ae3bb582": "significant",
                        "3780fe32-30da-5e3b-bad1-6d360381e7e4": "severe"
                    }
                }
            ]
        },
        "section": [
            "Current manic symptoms",
            "Functional impairment"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Work: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "BP119",
                    "optionSummaries": {
                        "3f4ab649-e8be-559b-a32f-9ccb317ffb38": "no impairment",
                        "faf4eb68-5627-5070-8937-42cf8cbc74d7": "mild",
                        "50c573e0-accf-5acc-be15-616cb65704cc": "moderate",
                        "6d54b724-84ce-55bd-9912-b4951133fb61": "significant",
                        "64d22ab4-481d-5258-9244-0ec9d3ba77a7": "severe"
                    }
                }
            ]
        },
        "section": [
            "Current manic symptoms",
            "Functional impairment"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "BP119.1"
            ],
            "prefix": "Time off work: ",
            "formatText": "{}"
        },
        "section": [
            "Current manic symptoms",
            "Functional impairment"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Relationships: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "BP121",
                    "optionSummaries": {
                        "76891993-4f79-5b7a-ad6f-b7be1c4994fb": "no impairment",
                        "c344141d-3295-5ecc-bf2a-4c45f9d330db": "mild",
                        "7e71cdc3-11c7-5156-87b9-a53194156832": "moderate",
                        "7caa27c0-01f2-586f-825c-7c1bd527ef54": "significant",
                        "2dd3fa4b-7390-5c95-b04b-c5c77974e8c2": "severe"
                    }
                }
            ]
        },
        "section": [
            "Current manic symptoms",
            "Functional impairment"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Leisure: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "BP128",
                    "optionSummaries": {
                        "6b43ea3f-8741-56e6-808f-129fe78b7440": "no impairment",
                        "ebff4971-05dc-52ed-9957-33c0ca1479c1": "mild",
                        "5bf8ce18-aa1a-5c57-86d6-687efcad92de": "moderate",
                        "f2c70c18-c55b-5447-9859-67292866bede": "significant",
                        "8a7adbc6-1053-55ce-b226-1e2bc2131050": "severe"
                    }
                }
            ]
        },
        "section": [
            "Current manic symptoms",
            "Functional impairment"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} per day",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "BP7",
                    "optionSummaries": {
                        "4ceb0625-db98-50f5-a099-c9df92ffa626": "less than 1 hour",
                        "0c0ab687-08ee-5bfd-8795-5e0284c1e430": "1–3 hours",
                        "6369e481-8f15-557a-b9b6-4c06e0a3ec21": "4–6 hours",
                        "f22cc4a7-6a1e-580d-b5a0-b44bd8cd2117": "7–9 hours",
                        "f4e7e62d-742e-5c1b-b549-4b11a22c4be2": "10–12 hours",
                        "32f283d2-1a05-54df-9d98-7639d2670c41": "more than 12 hours"
                    }
                },
                {
                    "questionId": "BP6.5",
                    "optionSummaries": {
                        "f160a5be-9070-56d6-9163-15f172f107e0": "less than 1 hour",
                        "d959af5c-40b7-5278-bdcc-66c3fd17ed8d": "1–3 hours",
                        "58656f9f-9267-5c1d-9132-f342d05dc39e": "4–6 hours",
                        "a2d6fa3e-e88a-53c9-9913-428837a5c3ee": "7–9 hours",
                        "e96c7a38-d74e-5087-b14b-21d3177ce73e": "10–12 hours",
                        "4678730f-670d-50ba-94cb-71d817698a0a": "more than 12 hours"
                    }
                },
                {
                    "questionId": "BP6",
                    "optionSummaries": {
                        "6560ae14-d813-51a0-b37b-cc9e44ecffaa": "less than 1 hour",
                        "742a22d6-a570-5e3a-8e72-c001f1e9f98b": "1–3 hours",
                        "0f2295c8-9598-5e68-a9a6-c87ca1f600af": "4–6 hours",
                        "33ae5abe-63ad-57d4-86a4-0dcd33576094": "7–9 hours",
                        "29d4f06e-7031-5ec7-a155-41dd137ca140": "10–12 hours",
                        "52f153db-76e7-57fa-90bd-63d5a923964a": "more than 12 hours"
                    }
                },
                {
                    "questionId": "BP5",
                    "optionSummaries": {
                        "1f743ff4-52a2-5104-aa5a-b38e75363021": "less than 1 hour",
                        "38e2ca3d-f06e-5ca5-b301-7906fdad6741": "1–3 hours",
                        "9b037682-7f02-573a-88e8-662b5b9ae4ed": "4–6 hours",
                        "e2cba9a7-5960-5839-88e2-6af7b8af3aa9": "7–9 hours",
                        "9b30d461-df50-57fe-ba43-fbcaf82b25fc": "10–12 hours",
                        "0f5b2a9a-603c-59a7-b70a-efb21f3e5ec6": "more than 12 hours"
                    }
                },
                {
                    "questionId": "BP13",
                    "optionSummaries": {
                        "a03b4df9-48f3-5151-b6a7-dd71bfcb2fdd": "less than 1 hour",
                        "6e3b2f0d-fb56-52bf-89c6-bb98b6e0cd3b": "1–3 hours",
                        "1aa23278-1b6e-5950-91ec-0b2d3babbee3": "4–6 hours",
                        "b0ebeb98-c11e-57a1-ba58-6457ca05c434": "7–9 hours",
                        "8cd7d7ed-1de8-5155-81b5-85202bce3abc": "10–12 hours",
                        "9818f960-c604-59a3-a1ad-372baff1b59b": "more than 12 hours"
                    }
                },
                {
                    "questionId": "BP70",
                    "optionSummaries": {
                        "aca0ad52-0349-505b-a2c9-851d9151f9f4": "less than 1 hour",
                        "6713ca95-099d-5eb6-aafa-4588d8517e3f": "1–3 hours",
                        "45c6a41e-5b3d-5eee-9509-830ff88f0c48": "4–6 hours",
                        "b8c6c8ad-d35e-54bd-a51c-789c271b5b64": "7–9 hours",
                        "b064197f-ae70-5a7a-99a5-4084a68bb429": "10–12 hours",
                        "7af885b6-8430-5131-bbe0-e3b3085e1124": "more than 12 hours"
                    }
                },
                {
                    "questionId": "BP79",
                    "optionSummaries": {
                        "32c4702c-0e20-5a42-ab8c-625263b3dd54": "less than 1 hour",
                        "9fd76bef-808e-53ff-8cc7-48d025708649": "1–3 hours",
                        "bb32ed85-6e28-5e4d-9b3d-256373ad7145": "4–6 hours",
                        "62f57a12-a41d-52a5-8cd4-ea2fb118b58c": "7–9 hours",
                        "179c1c17-e5b8-5a2c-8ec3-ec6cbd59a840": "10–12 hours",
                        "4f59a1e5-dfb4-5354-9869-4f1e7a4aa6c4": "more than 12 hours"
                    }
                },
                {
                    "questionId": "BP14",
                    "optionSummaries": {
                        "5585a7e8-a4b7-567e-b339-90537a58f1e3": "less than 1 hour",
                        "97bbe87b-c5ff-51c1-a99c-cec263cff7d4": "1–3 hours",
                        "a3126449-b062-5872-80a1-5950acf6bfa4": "4–6 hours",
                        "ac3ece45-bbdf-528a-9563-b82e962351e8": "7–9 hours",
                        "dca91893-52f2-504e-b794-1f89dfa61ab8": "10–12 hours",
                        "84994ca2-fcea-54fe-a7d6-9b44ec07f0bd": "more than 12 hours"
                    }
                },
                {
                    "questionId": "BP71",
                    "optionSummaries": {
                        "0fc9c17b-b857-574e-8324-e0a11c09fa19": "less than 1 hour",
                        "af8225ea-ab7c-5332-a82a-722b6541423a": "1–3 hours",
                        "93c4a6fa-278e-5144-a6fe-c5f4d0e9aac7": "4–6 hours",
                        "ae2c1f88-830b-564f-833d-0a2b6409893a": "7–9 hours",
                        "02b44dd4-f89e-58c8-bd23-d8fd037216e0": "10–12 hours",
                        "28680d32-9e22-5e15-8a5b-7a3ad7062d02": "more than 12 hours"
                    }
                },
                {
                    "questionId": "BP80",
                    "optionSummaries": {
                        "f971ee05-8a9f-558d-a491-ec6c235f579a": "less than 1 hour",
                        "5d5dd946-9124-53b8-bafc-173b27575b5d": "1–3 hours",
                        "3feb1a3a-38f7-5830-979e-d3bba7f181f7": "4–6 hours",
                        "95b64a37-13ad-59f9-8562-53d90f02e672": "7–9 hours",
                        "aabd9ffd-ac8c-51a3-8bf3-c79d4e1bab6c": "10–12 hours",
                        "cf20772a-dc0a-58a9-8878-b3eecba225c2": "more than 12 hours"
                    }
                },
                {
                    "questionId": "BP14.5",
                    "optionSummaries": {
                        "ce1ffa1a-f506-51cf-8d4e-869680808628": "less than 1 hour",
                        "d7e78959-cebd-524d-9863-f3cb141abfb4": "1–3 hours",
                        "3a2461f2-b999-59ae-a70a-cd3a034afd00": "4–6 hours",
                        "8def5c3e-98b8-54b6-89f9-a4df19e76d66": "7–9 hours",
                        "8a362b4c-6d0e-527a-8610-6572060f7476": "10–12 hours",
                        "f2c52c87-ab50-5315-96ce-3528652a7a32": "more than 12 hours"
                    }
                },
                {
                    "questionId": "BP71.5",
                    "optionSummaries": {
                        "4f62cee0-6206-58eb-90ed-5a29705a372b": "less than 1 hour",
                        "b3996862-f449-5749-b7b9-4ae68796f6c7": "1–3 hours",
                        "69c34c7b-2729-540a-94de-673059e72121": "4–6 hours",
                        "99a45b06-3b08-543d-bf5d-9f1f0ccc662c": "7–9 hours",
                        "dfacec02-0d53-5c82-a2fa-e76119206899": "10–12 hours",
                        "5147083f-dd93-5969-b323-a37718d1b91c": "more than 12 hours"
                    }
                },
                {
                    "questionId": "BP80.5",
                    "optionSummaries": {
                        "06a8d0e8-fc8b-5f2a-8113-c4778da46faf": "less than 1 hour",
                        "a3e7092a-c243-5f8b-9108-050cc0ca0593": "1–3 hours",
                        "757ca82a-6c4a-5d04-88ee-979319b6fcd7": "4–6 hours",
                        "6d17a5fb-825d-57e3-9bc5-1c7ed4e07d26": "7–9 hours",
                        "8d702773-3ca7-5737-8e58-7a316d353551": "10–12 hours",
                        "a1f34de5-9920-5767-b94d-4c5f08713c56": "more than 12 hours"
                    }
                },
                {
                    "questionId": "BP15",
                    "optionSummaries": {
                        "1286eda2-5e31-52dc-95c5-78ded5b43b2a": "less than 1 hour",
                        "9a1158f1-f08a-581d-a088-650041097c24": "1–3 hours",
                        "2dae4847-432c-5e66-9a74-3ded3e4eada9": "4–6 hours",
                        "d8c37d8b-01b7-581e-9d97-f6c8b8bf9267": "7–9 hours",
                        "54874bb5-1fab-5ab4-b9d4-bed8f8ea4d13": "10–12 hours",
                        "e9643ff4-2842-5b40-9c98-5b5d0e9b6a5e": "more than 12 hours"
                    }
                },
                {
                    "questionId": "BP72",
                    "optionSummaries": {
                        "e5b36105-c9f7-5416-b2dd-8109e47019f9": "less than 1 hour",
                        "240181ac-d397-5683-ba61-de5f01332698": "1–3 hours",
                        "f6691e23-cdfb-567b-b2d0-99a4b804a73e": "4–6 hours",
                        "32012424-6e5d-5ebd-a173-ba0297980f44": "7–9 hours",
                        "fad72e1f-8588-5f92-840b-825f3b65a669": "10–12 hours",
                        "705b79dd-e16b-50fc-8532-645edfc14a8f": "more than 12 hours"
                    }
                },
                {
                    "questionId": "BP81",
                    "optionSummaries": {
                        "3753501c-6596-58db-96d1-2f0b31982bba": "less than 1 hour",
                        "49032c24-157e-5206-8a5a-3ddc49e99166": "1–3 hours",
                        "9a7f6546-bc93-59c9-b503-3b56ed0ccafd": "4–6 hours",
                        "254f447d-d040-5198-8a28-496e22e6d6e2": "7–9 hours",
                        "1e2b4334-2201-5b8c-ac01-97624fa1f917": "10–12 hours",
                        "8b61c95b-684f-5d31-986c-ab32bd4cff29": "more than 12 hours"
                    }
                }
            ]
        },
        "section": [
            "Previous most extreme manic symptoms",
            "Duration of manic symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "BP7.1",
                    "optionSummaries": {
                        "6b62060b-0aee-5ec9-beef-85c908359e3d": "one day per week",
                        "c077c76d-70ea-541e-b93c-aa943ccde0c2": "2–3 days per week",
                        "bedc40d1-6d09-5151-b80b-8db92115483b": "4–5 days per week",
                        "6b0b2ebe-61e1-58f5-86ad-8c05d3a16679": "6–7 days per week"
                    }
                },
                {
                    "questionId": "BP6.7",
                    "optionSummaries": {
                        "f3f2d7da-5c63-5e35-a1ab-729a174949c5": "one day per week",
                        "370c5f43-7c15-5f4f-b49a-8719bcb29c2a": "2–3 days per week",
                        "7cb3515b-004f-58a1-a02b-59c7d9510637": "4–5 days per week",
                        "ef07ce6c-9277-581a-bde7-14889359301b": "6–7 days per week"
                    }
                },
                {
                    "questionId": "BP6.1",
                    "optionSummaries": {
                        "77b4d620-93e4-5bc5-baad-4995d168f392": "one day per week",
                        "430b68c6-29cf-5a15-ab4c-89bd842383bf": "2–3 days per week",
                        "a99773bd-2bff-523e-bc29-1f761307b3d4": "4–5 days per week",
                        "f0b738f5-44c3-5a6c-9a31-ab7878568a81": "6–7 days per week"
                    }
                },
                {
                    "questionId": "BP5.1",
                    "optionSummaries": {
                        "6a2ded69-f2ba-5374-b667-a542580d92ff": "one day per week",
                        "c3c07e60-7b68-585c-8bc0-cbe7939a5c5f": "2–3 days per week",
                        "e078853b-2d60-5ce8-925e-6f3693305c5d": "4–5 days per week",
                        "8bf2b201-3339-5ac5-8365-e39aece2c3f4": "6–7 days per week"
                    }
                },
                {
                    "questionId": "BP13.1",
                    "optionSummaries": {
                        "bf94a386-34ce-5e35-a1d4-cb50686e6e01": "one day per week",
                        "7a2a48ee-f1b5-5c8d-974a-247da2a9f76b": "2–3 days per week",
                        "632d3ebf-df58-52bd-9e1e-19deb67269bf": "4–5 days per week",
                        "49f1206d-ce81-58fc-af76-30be0fffd7f5": "6–7 days per week"
                    }
                },
                {
                    "questionId": "BP70.1",
                    "optionSummaries": {
                        "0c8ee31c-61b4-553c-8f3a-98dfb5c580e9": "one day per week",
                        "1947b35d-4a64-5e7c-99da-8652829ac820": "2–3 days per week",
                        "9e283db4-1a5e-5edb-a052-544a898ae61b": "4–5 days per week",
                        "3edc89bb-15dc-51c5-810a-704abe295dfe": "6–7 days per week"
                    }
                },
                {
                    "questionId": "BP79.1",
                    "optionSummaries": {
                        "9ef68ae4-af66-5ea2-9961-2ddf2ce4425e": "one day per week",
                        "fce787ed-5443-5522-81ba-c6fa566d3743": "2–3 days per week",
                        "782b65e7-2501-5414-b12a-302daead9bf1": "4–5 days per week",
                        "be369f00-ee1d-56e4-a34e-e357cbfd2b76": "6–7 days per week"
                    }
                },
                {
                    "questionId": "BP14.1",
                    "optionSummaries": {
                        "f2b7f127-8ab7-5609-8820-43158baee15c": "one day per week",
                        "69f9a784-ce6d-5c82-bd3f-4c9c44bf5992": "2–3 days per week",
                        "e1b5b8f7-1317-5136-a8c1-2afdfa91e615": "4–5 days per week",
                        "00462af7-705a-5635-a847-029a631411c0": "6–7 days per week"
                    }
                },
                {
                    "questionId": "BP71.1",
                    "optionSummaries": {
                        "dc11a69a-230b-5c81-8d9a-8f660c70bfd9": "one day per week",
                        "6d196333-35e9-5a21-bf8e-8717a902d337": "2–3 days per week",
                        "0b299ed5-2778-5635-b13b-1fc25fe69c12": "4–5 days per week",
                        "0ea18b82-5d36-5d3d-a053-b7198a5269db": "6–7 days per week"
                    }
                },
                {
                    "questionId": "BP80.1",
                    "optionSummaries": {
                        "fcc5d718-229f-5f07-9aa1-d3dc4289afaf": "one day per week",
                        "0ba28b6c-58e5-5e14-af28-b83c64d7de59": "2–3 days per week",
                        "2250304e-421b-5cf8-9453-0eab14a2170b": "4–5 days per week",
                        "ca674276-1d63-53d4-bca8-79316c8f6295": "6–7 days per week"
                    }
                },
                {
                    "questionId": "BP14.6",
                    "optionSummaries": {
                        "282c0f7e-ea67-578e-87b3-bfab84fbf0c0": "one day per week",
                        "e05fa18d-bc4f-5678-9692-644f2e2e1a3c": "2–3 days per week",
                        "8c8a8e3b-6201-5a54-8a71-3d37f75d6d2d": "4–5 days per week",
                        "d77159fb-9574-5a11-9e4f-9dccffb0db35": "6–7 days per week"
                    }
                },
                {
                    "questionId": "BP71.6",
                    "optionSummaries": {
                        "ce19af7f-087d-5e10-a35d-7d45cb40f98e": "one day per week",
                        "46b1d982-b81d-5d0b-944a-c8f4c7c89399": "2–3 days per week",
                        "56690226-655e-5208-ba0f-54e3b33d57ae": "4–5 days per week",
                        "b32b3687-08fc-516d-9588-cc4e0e67329b": "6–7 days per week"
                    }
                },
                {
                    "questionId": "BP80.6",
                    "optionSummaries": {
                        "c57fbe44-613a-52fb-a5f4-1930edba11d7": "one day per week",
                        "47fe93bd-8834-5e54-99a9-6e1b62c9f072": "2–3 days per week",
                        "bc83c397-0824-59fe-b3a1-1683aab4f487": "4–5 days per week",
                        "25a4571f-2338-5796-be87-b1b2af243e03": "6–7 days per week"
                    }
                },
                {
                    "questionId": "BP15.1",
                    "optionSummaries": {
                        "0fe6799b-72e6-5cd8-a921-d03ac42f803d": "one day per week",
                        "aa7bd0b8-95ca-5179-a037-3956a8c2816d": "2–3 days per week",
                        "2fe2c05f-897a-55a4-876c-68763dede792": "4–5 days per week",
                        "35a5b342-38f4-5343-8a4f-a3f024b95530": "6–7 days per week"
                    }
                },
                {
                    "questionId": "BP72.1",
                    "optionSummaries": {
                        "7f041e31-3b4a-5190-bbb0-12676f8e4dc9": "one day per week",
                        "89fbcf0e-e324-56f3-a361-d614329e24ea": "2–3 days per week",
                        "03dcd030-84ff-5da2-b617-a648734921a5": "4–5 days per week",
                        "3b0835fe-f178-58d2-906f-f8a66737c021": "6–7 days per week"
                    }
                },
                {
                    "questionId": "BP81.1",
                    "optionSummaries": {
                        "6d388eb5-265a-5381-b8b4-1258f0d4c896": "one day per week",
                        "ed9f80ca-b9ec-54df-92c9-a8b252612e6c": "2–3 days per week",
                        "2a892e94-55a7-540f-994e-4d1fc65dbb15": "4–5 days per week",
                        "d465a1fd-b8bf-544d-b8ae-5b1c59299af4": "6–7 days per week"
                    }
                }
            ]
        },
        "section": [
            "Previous most extreme manic symptoms",
            "Duration of manic symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "BP7.2",
                "BP6.8",
                "BP6.2",
                "BP5.2",
                "BP13.2",
                "BP70.2",
                "BP79.2",
                "BP14.2",
                "BP71.2",
                "BP80.2",
                "BP14.7",
                "BP71.7",
                "BP80.7",
                "BP15.2",
                "BP72.2",
                "BP81.2",
                "BP106b"
            ],
            "prefix": "for ",
            "formatText": "{}"
        },
        "section": [
            "Previous most extreme manic symptoms",
            "Duration of manic symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "episode duration estimate is '{} certain'",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "BP5.3",
                    "optionSummaries": {
                        "495f2944-a25b-5bb8-aa89-c7b93e2bb0d4": "not at all",
                        "a101d0bf-f19c-53f5-8a7c-0f91a4bc5c8a": "not very",
                        "5b752ce4-66ea-5f46-8d24-7cb297d53053": "moderately",
                        "8e31a32e-2ea1-52e2-aaff-3fd252051295": "very"
                    }
                },
                {
                    "questionId": "BP6.3",
                    "optionSummaries": {
                        "df17fdbe-e756-5ece-ad3d-19cafeef31d1": "not at all",
                        "a19e0696-e780-5235-88b9-f5c36ce0afc3": "not very",
                        "e061c578-086e-5990-bba6-279cb273f3a5": "moderately",
                        "94b6a547-3559-5363-971f-5eab65977d22": "very"
                    }
                },
                {
                    "questionId": "BP6.9",
                    "optionSummaries": {
                        "8c8117af-9320-59a6-aa47-ebfe1f05ccc4": "not at all",
                        "155da231-22f3-50d9-8786-2860edd50aab": "not very",
                        "75d51935-7fc0-5e05-813c-62bbdd8f8335": "moderately",
                        "3f775bde-9e25-538e-bc79-7bcb0a91bc1f": "very"
                    }
                },
                {
                    "questionId": "BP7.3",
                    "optionSummaries": {
                        "455f915a-3cf9-5453-8bdb-a0fc0d91ca60": "not at all",
                        "067ab1b6-912a-5137-997e-91f394b7bf9a": "not very",
                        "5830f812-10e2-579d-ba9a-721f817ddb0e": "moderately",
                        "2240ae5e-dfde-5c23-875f-7c0a746812b8": "very"
                    }
                },
                {
                    "questionId": "BP13.3",
                    "optionSummaries": {
                        "d1bcedf2-2527-57c5-8faf-806dcacf0fdd": "not at all",
                        "a170743a-0467-5fe9-8ae7-6bef7627cc74": "not very",
                        "54def841-d09f-54e5-9c8f-26b666a1bc37": "moderately",
                        "9b46bc6e-5c3a-52c6-a383-b7c685f72cee": "very"
                    }
                },
                {
                    "questionId": "BP14.3",
                    "optionSummaries": {
                        "ac6d7839-60fb-5529-97b0-5d0f13ed20de": "not at all",
                        "9f5fc152-75fb-59d8-8f40-7a6ca15c69cb": "not very",
                        "d11770ab-32a3-560e-a110-52869d35ad5e": "moderately",
                        "8c8b64f5-0dae-51df-9e07-48317b805d3a": "very"
                    }
                },
                {
                    "questionId": "BP14.8",
                    "optionSummaries": {
                        "7b6f5669-2010-5ff6-84ad-f5d0422a5f52": "not at all",
                        "166cac26-72c2-51df-b8ce-2f014998b6c4": "not very",
                        "bb386470-f2f4-54ad-acb7-9f8a5b70c2d3": "moderately",
                        "e3666db0-f488-5b69-bdb7-46053d50b291": "very"
                    }
                },
                {
                    "questionId": "BP15.3",
                    "optionSummaries": {
                        "cfa519ff-08a9-5fa4-b12d-87b7a597cb13": "not at all",
                        "10414c02-0b51-5e7e-952f-8410debae858": "not very",
                        "e65c7281-c61b-5d1b-80d2-6d817860040c": "moderately",
                        "c4ce76fe-b6b8-5cae-9479-302ab45b1f45": "very"
                    }
                },
                {
                    "questionId": "BP68.4",
                    "optionSummaries": {
                        "2bd1b5ba-74ca-5b38-b914-8aada2bdbaa6": "not at all",
                        "225ded5f-bf47-5069-938c-25f4d15d564e": "not very",
                        "3831e42b-1f7b-5dce-8b8d-4b090ff95f5f": "moderately",
                        "208e1e51-488d-5fbd-8e38-1e75fd0331c4": "very"
                    }
                },
                {
                    "questionId": "BP70.3",
                    "optionSummaries": {
                        "8c24266b-7368-50b7-af0a-d9a41afec6f3": "not at all",
                        "0e46d53a-eb8f-5b24-af8d-c3a26e16446f": "not very",
                        "101f0fd0-21f4-56bb-ad9a-5f24c6662b7b": "moderately",
                        "b8ec3f2b-ea6b-56ee-a15b-4382fa6c806e": "very"
                    }
                },
                {
                    "questionId": "BP71.3",
                    "optionSummaries": {
                        "feec6124-d1e0-55c4-bfcf-addfd2abad01": "not at all",
                        "f820698f-9777-57ed-a050-d41737dceb05": "not very",
                        "87e8dac9-4f50-502c-83fc-87b3f12697d0": "moderately",
                        "2613eb2c-65e9-5af7-a49f-060d517d74ec": "very"
                    }
                },
                {
                    "questionId": "BP71.8",
                    "optionSummaries": {
                        "968cdf73-98fa-58cd-a420-f6c253add90e": "not at all",
                        "38c867bb-d5f4-500f-b531-eae3c03cbcee": "not very",
                        "86f4e988-f781-52ca-8c0f-a27e7868b3b4": "moderately",
                        "65c8698f-8f2c-5b7e-b938-93993cbafc7f": "very"
                    }
                },
                {
                    "questionId": "BP72.3",
                    "optionSummaries": {
                        "854c880e-13ab-5d3a-8ccd-e00176f28eb1": "not at all",
                        "6544f4f3-3ab1-569c-83d3-d4d8ad7ffc0c": "not very",
                        "643660a4-4811-562c-8ddb-1f3c7f8b62b4": "moderately",
                        "e6d5c97d-9c7e-50d0-9ee5-c31432fc5b6b": "very"
                    }
                },
                {
                    "questionId": "BP79.3",
                    "optionSummaries": {
                        "276d511e-9c09-5b98-a229-c39fb8b98f5b": "not at all",
                        "415137c5-8de4-5587-8f90-b99aa0aa982c": "not very",
                        "19d75cf1-7424-5fb2-b1fe-c2667b6259df": "moderately",
                        "d278c709-a305-5822-8b62-5e4ed23efeca": "very"
                    }
                },
                {
                    "questionId": "BP80.3",
                    "optionSummaries": {
                        "1b637f63-9bb8-5e04-9b03-a8cda8e1cb78": "not at all",
                        "d151602d-2086-5598-9a57-92deb45d117b": "not very",
                        "30724850-af64-59d8-aaa0-a09c00bd0067": "moderately",
                        "abea45e3-262d-5014-994d-84d6776966ea": "very"
                    }
                },
                {
                    "questionId": "BP80.8",
                    "optionSummaries": {
                        "a2bcbc4d-2432-5e32-a078-49b31d13e2d0": "not at all",
                        "beae5170-2daa-5393-a3fe-ec55762d25b2": "not very",
                        "065bdeae-4bd9-5962-952a-2d13c1cf73d3": "moderately",
                        "596658eb-cba0-5e8b-96a6-476cb7cf5c0e": "very"
                    }
                },
                {
                    "questionId": "BP81.3",
                    "optionSummaries": {
                        "32b3f551-7af2-529e-ac2f-1bd0e68c1d58": "not at all",
                        "27ce8cc1-459e-56bf-ae35-6458ccc45fe6": "not very",
                        "219990be-b4e3-503d-ad75-81ea8a3ae1b6": "moderately",
                        "4eb1cb4d-3ec0-524b-862a-a06b2cbee8a2": "very"
                    }
                },
                {
                    "questionId": "BP112.14",
                    "optionSummaries": {
                        "8bedbb44-cc6d-50cd-bf32-2f155b992c77": "not at all",
                        "ea23e7a2-72bc-5600-961e-410a47c77b68": "not very",
                        "0304c7fa-aad9-57be-9ff2-dd20913e3d1c": "moderately",
                        "6ead4f84-b429-5a17-85d6-808bd332aa25": "very"
                    }
                },
                {
                    "questionId": "BP112.44",
                    "optionSummaries": {
                        "00b06868-292a-5c53-99fa-429e2cb84810": "not at all",
                        "fc48ef94-76bc-5f16-aece-d3ada7dfb0e8": "not very",
                        "125e4463-b901-5baa-a01a-dbc3d447870f": "moderately",
                        "88eff6ec-7434-5255-b69e-d44310919588": "very"
                    }
                }
            ]
        },
        "section": [
            "Previous most extreme manic symptoms",
            "Duration of manic symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "BP78",
                    "optionSummaries": {
                        "3629add1-9666-5590-8025-6ec116bafb5c": "persistently happier and more excitable",
                        "23e37a7e-3e45-53e6-a29b-75da72b79ff0": "persistently more irritable",
                        "3477feaf-ad87-5fb3-8384-196bc93f5c05": "persistently more elated, but also irritable",
                        "bf6cf52e-7867-5073-837f-205e3e78121d": "cannot pinpoint mood change, but much more energetic and proactive"
                    }
                },
                {
                    "questionId": "BP12",
                    "optionSummaries": {
                        "89a3d3f3-747b-59f8-9a32-311618df7607": "persistently happier and more excitable",
                        "1d6299ac-2ac6-51e2-9a3f-13e8d44e652a": "persistently more irritable",
                        "74034187-2f1a-54cb-8bdf-1eb3572a286a": "persistently more elated, but also irritable",
                        "22f9a995-3f9a-5fe9-99bb-71b7f1f96621": "cannot pinpoint mood change, but much more energetic and proactive"
                    }
                },
                {
                    "questionId": "BP4",
                    "optionSummaries": {
                        "6b1af00b-b5de-5d59-bb4b-7e8d396d2138": "persistently happier and more excitable",
                        "9765aba8-7d55-5928-b79d-36aa58646036": "persistently more irritable",
                        "4884cc9c-7210-5173-9438-d1eae9e9a6a8": "persistently more elated, but also irritable",
                        "f4705d21-04ac-574a-90f3-5e73c089a3ff": "cannot pinpoint mood change, but much more energetic and proactive"
                    }
                },
                {
                    "questionId": "BP69",
                    "optionSummaries": {
                        "a36902b1-c83f-5e6f-b877-c059c1065bb5": "persistently happier and more excitable",
                        "d210ccff-4e0a-5c2c-afe5-b8f3163c72d7": "persistently more irritable",
                        "63921591-01f8-50fb-9355-b55fe483faa3": "persistently more elated, but also irritable",
                        "94ec394b-cb15-50df-8a09-56fc2267a32a": "cannot pinpoint mood change, but much more energetic and proactive"
                    }
                }
            ]
        },
        "section": [
            "Previous most extreme manic symptoms",
            "Emotional symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "sexual thoughts and feelings {}prominent",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "HM26",
                    "optionSummaries": {
                        "d2c670c4-b05b-597a-a0af-5342230535a0": "slightly more ",
                        "898957c6-8cd1-53e5-98f8-c16789aba613": "moderately more ",
                        "eb41dc92-16ec-5abf-a841-e1d0b262083e": "significantly more ",
                        "65bee7d4-bf7f-5a21-9d92-f16651b0d1aa": "very much more "
                    }
                }
            ]
        },
        "section": [
            "Previous most extreme manic symptoms",
            "Emotional symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} social inhibition",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "BP98",
                    "optionSummaries": {
                        "58777a2e-4b97-5413-b340-efe722e45936": "slightly lowered",
                        "cfb8fe0e-821a-554a-bb57-922f0ecc0f44": "moderately lowered",
                        "a0a2f2f1-ed73-59bb-add9-5f3569bbbecb": "significantly lowered",
                        "b14d53e4-f898-52ac-87d7-3aa62abc9d7b": "drastically lowered"
                    }
                },
                {
                    "questionId": "BP98b",
                    "optionSummaries": {
                        "f7b75a2b-3bd2-5734-9fa6-7706f7f8ac89": "slightly lowered",
                        "cae1d431-f7ae-5a5a-924d-25c540e96d28": "moderately lowered",
                        "1385fe83-520e-5986-8bec-9f0630d48219": "significantly lowered",
                        "81112699-9c7d-5ee5-a605-5d4647a698d1": "drastically lowered"
                    }
                }
            ]
        },
        "section": [
            "Previous most extreme manic symptoms",
            "Social symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} more sociable and outgoing",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "BP89",
                    "optionSummaries": {
                        "2404146e-cd56-5356-a23b-6427b4bbba06": "somewhat",
                        "92e118de-38b9-5d5a-96ba-7b50547c0621": "moderately",
                        "28c75e9e-94ef-5c50-8645-f4ff93ccf2ec": "significantly",
                        "0953f30c-801a-54f6-8238-b9dc42ab17f9": "very much"
                    }
                },
                {
                    "questionId": "BP89b",
                    "optionSummaries": {
                        "db509963-d872-5b97-ba9b-a276f58e0189": "somewhat",
                        "db64e5ea-3aa0-50f0-a9ad-94f7010267eb": "moderately",
                        "df5f6aa3-ab8c-5a4c-b9d9-e9a482aba1d7": "significantly",
                        "adfc4e81-0d5f-5ba6-8507-9072b6e02bba": "very much"
                    }
                }
            ]
        },
        "section": [
            "Previous most extreme manic symptoms",
            "Social symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "being the life and soul of every social gathering {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "HM25",
                    "optionSummaries": {
                        "3fd957d1-0cbb-5397-9812-353be5c2184e": "to a slight degree",
                        "c8c8c192-aea3-5585-b11f-9d13faf4367d": "to a moderate degree",
                        "0e9545ba-3f35-52b0-9a31-7fe54c933928": "to a significant degree",
                        "b60806d0-ac44-589e-a567-a91918ccf9f8": "very much"
                    }
                }
            ]
        },
        "section": [
            "Previous most extreme manic symptoms",
            "Social symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "meeting {}more people than usual",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "HM22",
                    "optionSummaries": {
                        "a43647cb-a0e8-5705-adb9-a2b68877dc77": "slightly ",
                        "9d212885-9b07-5623-8234-226adff08f6d": "moderately ",
                        "8f7acbc1-9ebe-5a52-95ce-b50d62c27345": "significantly ",
                        "5b02ea99-72f6-5b68-b536-b6f32aecc86c": "very much "
                    }
                }
            ]
        },
        "section": [
            "Previous most extreme manic symptoms",
            "Social symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} flirtatious and sexually active",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "HM30",
                    "optionSummaries": {
                        "0983ccf5-a426-53d0-adbb-1baf6832190c": "slightly more",
                        "aa90f24c-ee55-515b-b5a2-78bdacbe25a0": "moderately more",
                        "2fcb75b3-7ba4-50b5-b2a3-ad1ead57dbe1": "significantly more",
                        "73b26794-a969-527b-8268-f17c4e485bda": "very much more"
                    }
                }
            ]
        },
        "section": [
            "Previous most extreme manic symptoms",
            "Social symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} talkative and speaking faster",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "BP92",
                    "optionSummaries": {
                        "ef5a7b22-b2b2-5a5b-8a80-ce5c251033fd": "slightly more",
                        "17619692-6d18-5558-99da-766a1c275e03": "moderately more",
                        "63f0b17a-9832-5f0f-8630-e74f1a4fdfe7": "significantly more",
                        "3984b363-353f-56b1-ad96-4840225109ef": "very much more"
                    }
                },
                {
                    "questionId": "BP92b",
                    "optionSummaries": {
                        "f59918ba-eb3a-5cf1-81aa-8b7f6ac0df5e": "slightly more",
                        "dd87a55d-829b-54a2-a63d-5b8b634688ea": "moderately more",
                        "195241f6-1da5-5b54-b7ce-e73bb54d7d2b": "significantly more",
                        "bdcabc25-9c00-5456-9d9b-a30e5b36cf6c": "very much more"
                    }
                },
                {
                    "questionId": "HM21",
                    "optionSummaries": {
                        "c3ee8981-dec3-5b3c-80ca-c1023f983a6d": "slightly more",
                        "6b99de8b-634a-5eb0-bc65-bdf30eb9d433": "moderately more",
                        "0f8903ea-258c-54c8-9d4d-643924d3c234": "significantly more",
                        "7977ac6e-f8f2-5d46-ba88-d01586f6e45d": "very much more"
                    }
                }
            ]
        },
        "section": [
            "Previous most extreme manic symptoms",
            "Social symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "others noticing {}faster speech",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "BP95",
                    "optionSummaries": {
                        "de2ea429-4fcd-53b8-8d9f-ebb69a637a02": ""
                    }
                },
                {
                    "questionId": "BP95b",
                    "optionSummaries": {
                        "32255970-db0f-5cc0-a199-d412edf7ab7c": ""
                    }
                }
            ]
        },
        "section": [
            "Previous most extreme manic symptoms",
            "Social symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{}increase in making jokes and puns",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "HM33",
                    "optionSummaries": {
                        "e95276b0-ee81-580b-b532-b0215f04abd5": "slight ",
                        "bde4e5f3-19e4-571f-84a2-5b9d188d74ef": "moderate ",
                        "0360ab4b-77d5-5fcd-8d60-b7a005c12ceb": "significant ",
                        "f0be1669-63f2-5fb0-9af7-55be0b4fb2c2": "very large "
                    }
                }
            ]
        },
        "section": [
            "Previous most extreme manic symptoms",
            "Social symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} due to excessive and foolish activities",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "BP99.1",
                    "optionSummaries": {
                        "659c1b81-1b5d-5c34-9d0d-ce0ceae2a3b3": "been in a bit of trouble",
                        "74809c02-f47f-5955-a416-b40fc66ff214": "been in serious trouble"
                    }
                },
                {
                    "questionId": "BP99.1b",
                    "optionSummaries": {
                        "74ac8d69-c317-57fb-a5e5-a93c1309c0f7": "been in a bit of trouble",
                        "474c64d7-23b9-5015-9cdd-60661ad38d39": "been in serious trouble"
                    }
                }
            ]
        },
        "section": [
            "Previous most extreme manic symptoms",
            "Impulsivity symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} due to risky and dangerous activities",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "BP99.2",
                    "optionSummaries": {
                        "f55f25af-1247-5c48-8385-341f7e4fea59": "been in a bit of trouble",
                        "e8c07c0f-f594-5e38-a18d-fb9d7835cf9f": "been in serious trouble"
                    }
                },
                {
                    "questionId": "BP99.2b",
                    "optionSummaries": {
                        "32f6041f-9e2c-537c-9db7-19f89011fce9": "been in a bit of trouble",
                        "a8224369-4083-5ab5-8cad-c4563490599c": "been in serious trouble"
                    }
                }
            ]
        },
        "section": [
            "Previous most extreme manic symptoms",
            "Impulsivity symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} irresponsible with money",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "BP99.3",
                    "optionSummaries": {
                        "a020ed85-ec9d-5778-8006-41f634cab09d": "slightly",
                        "e81fe613-4923-55ed-bd6c-bd3f19e026f9": "quite",
                        "b9e6efff-f5b2-5911-ba7a-17c768e4613e": "very",
                        "dee1118a-2ca3-52a6-ac43-a24ea7385f88": "extremely"
                    }
                },
                {
                    "questionId": "BP99.3b",
                    "optionSummaries": {
                        "d0adef9f-05e3-5ddf-a0f3-60eeb50d3862": "slightly",
                        "c5d5e724-00d6-5a76-bcaf-e4b6545b7fa4": "quite",
                        "e4575ba2-20f0-519e-967b-d5769763cadc": "very",
                        "cb008e5c-46a4-5b1b-bcea-6f59111913d0": "extremely"
                    }
                },
                {
                    "questionId": "HM32",
                    "optionSummaries": {
                        "fa32b497-4a4b-5076-a391-b6dffa8e1851": "slightly",
                        "fda00d52-c8be-5b69-bdbd-905c13dddd4e": "quite",
                        "fd982070-f2ad-5ff4-be48-fb6c78e856c4": "very",
                        "e9e6eb20-c3f6-516f-8431-d94c3555cc05": "extremely"
                    }
                }
            ]
        },
        "section": [
            "Previous most extreme manic symptoms",
            "Impulsivity symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} distractibility",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "BP101b",
                    "optionSummaries": {
                        "31b2d2c8-8f28-514e-9e58-aa878f6c8761": "slightly increased",
                        "c9856080-d9de-533e-92bf-7fbe2b6cd99a": "moderately increased",
                        "fe614147-f80a-5aff-bb03-9298781e2468": "significantly increased",
                        "923cb2af-7bb5-5341-8349-003206ae043d": "very much increased"
                    }
                },
                {
                    "questionId": "BP101",
                    "optionSummaries": {
                        "e9eb902a-d327-5ec5-938c-dc317ca59b13": "slightly increased",
                        "d8c1997f-d722-536c-ad9d-296b5e10ed04": "moderately increased",
                        "ca5ac96d-2b69-5200-a063-61db840c9ef2": "significantly increased",
                        "1c4ed441-5919-569e-98ba-b49be1bc3957": "very much increased"
                    }
                }
            ]
        },
        "section": [
            "Previous most extreme manic symptoms",
            "Thinking symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "thinking {}faster and clearer",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "HM34",
                    "optionSummaries": {
                        "4b8e4843-d260-59e5-8468-1303b1c3a638": "slightly ",
                        "7d6921a2-7e63-5f12-9c75-dff8b592fabc": "moderately ",
                        "42710499-d4f3-5f65-bfad-382dde7e2f83": "significantly ",
                        "ba200400-b5a1-5ae8-aead-863c1a72be98": "very much "
                    }
                }
            ]
        },
        "section": [
            "Previous most extreme manic symptoms",
            "Thinking symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} more creative",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "BP102",
                    "optionSummaries": {
                        "1daccd4c-e5ff-5f18-ba55-7c2d2d93b849": "slightly",
                        "7230003d-a290-5a65-ae6b-b7d564b5db38": "moderately",
                        "a3a547f3-b48b-567f-a32a-6a3f5f4bacd7": "significantly",
                        "e76cac02-f6b2-5ab6-a467-e3025d857ab9": "very much"
                    }
                },
                {
                    "questionId": "BP102b",
                    "optionSummaries": {
                        "46a64b5b-6641-5b1b-92aa-5366444fb134": "slightly",
                        "00afc1e6-b78a-5223-a6e7-5d3aaab833e0": "moderately",
                        "acb61f4d-a637-587c-bd33-3e9195921dd3": "significantly",
                        "523c7d8e-e42b-5654-b037-e8837afb49e7": "very much"
                    }
                }
            ]
        },
        "section": [
            "Previous most extreme manic symptoms",
            "Thinking symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} more racing thoughts which could not be slowed down",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "BP94b",
                    "optionSummaries": {
                        "1f4fafbf-913e-5edc-bfaf-3baae49fc91d": "slightly",
                        "33cefe4b-19c9-55e0-a87c-03ffd99d30bf": "moderately",
                        "24f97aee-7df6-5c11-b615-f5064af38f63": "significantly",
                        "9cf74958-461b-59df-b1a2-7ee7d7696a0b": "very much"
                    }
                }
            ]
        },
        "section": [
            "Previous most extreme manic symptoms",
            "Thinking symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} more racing thoughts which cannot be slowed down",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "BP94",
                    "optionSummaries": {
                        "201c7ee3-56dc-51e9-ad55-69951192ab16": "slightly",
                        "ec84966e-bb9f-5ba9-a0cf-d5d3503a74e0": "moderately",
                        "2555d898-6736-5804-951e-e3c438313029": "significantly",
                        "1e86d64a-b972-500a-9120-7884e4b86ede": "very many"
                    }
                }
            ]
        },
        "section": [
            "Previous most extreme manic symptoms",
            "Thinking symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "higher energy levels",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "BP4.5",
                    "optionSummaries": {
                        "1be4b3b1-3a88-5544-af54-ebdfee1ce891": ""
                    }
                },
                {
                    "questionId": "BP78.5",
                    "optionSummaries": {
                        "9c1bb644-2129-5e71-98c2-f16f6747632e": ""
                    }
                },
                {
                    "questionId": "BP12.5",
                    "optionSummaries": {
                        "b7cc3eef-f36c-5fb4-9ebb-9ce64c834159": ""
                    }
                },
                {
                    "questionId": "BP69.5",
                    "optionSummaries": {
                        "101d58bf-4d7f-50d8-a674-2a940051d124": ""
                    }
                }
            ]
        },
        "section": [
            "Previous most extreme manic symptoms",
            "Activity symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} need for sleep",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "BP90",
                    "optionSummaries": {
                        "5d5fa968-aeb3-569a-8a71-483ef04b9a6a": "slightly reduced",
                        "a2056280-4b54-5258-8bb4-407b521fff4b": "moderately reduced",
                        "93bfeb6e-215e-5f4b-b6de-0bc364e05be5": "significantly reduced",
                        "7b2421d0-6b5b-5666-b0a9-951887636db1": "severely reduced"
                    }
                },
                {
                    "questionId": "BP90b",
                    "optionSummaries": {
                        "b4a79888-68e8-5b52-9a4e-2149be85f6e7": "slightly reduced",
                        "607460aa-6bfa-56fd-9d06-75ec91ef85a1": "moderately reduced",
                        "940868cd-d067-573d-9a59-29b2ca63222f": "significantly reduced",
                        "5915edb5-db3b-5f9a-8b43-09542fcaa677": "severely reduced"
                    }
                }
            ]
        },
        "section": [
            "Previous most extreme manic symptoms",
            "Activity symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} restlessness",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "BP97b",
                    "optionSummaries": {
                        "8fa0f169-f510-5c9e-a4be-fbcc964f7480": "slightly increased",
                        "8a1fc783-28cb-5bf7-aa78-8f6b7c36ad29": "moderately increased",
                        "b8a41bad-3222-55bd-9527-a98e36c1f3ee": "significantly increased",
                        "94b68a0a-8608-59d6-a8bb-48cf3c52f891": "very much increased"
                    }
                },
                {
                    "questionId": "BP97",
                    "optionSummaries": {
                        "2723ed09-37f6-522c-92b1-e518d4fe9315": "slightly increased",
                        "4a7683c6-774e-5362-8060-740f61986c9b": "moderately increased",
                        "79075051-dcb3-5321-9f27-b21697f8f194": "significantly increased",
                        "352885c9-c1e1-5206-9f52-8d9b6d2aa166": "very much increased"
                    }
                }
            ]
        },
        "section": [
            "Previous most extreme manic symptoms",
            "Activity symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "CombinedAnswersSummary",
            "questionsIdGroups": [
                [
                    "BP88b",
                    "BP96b"
                ],
                [
                    "BP88",
                    "BP96"
                ]
            ],
            "groupsType": "Mean",
            "pattern": "feeling {}more active",
            "coefficients": {
                "0.1": "slightly ",
                "0.3": "moderately ",
                "0.6": "significantly "
            }
        },
        "section": [
            "Previous most extreme manic symptoms",
            "Activity symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "BP96",
                    "optionSummaries": {
                        "dbab3669-e910-5267-b7b1-fcb661ea7e81": "wanting to be everywhere and do everything to a slight extent",
                        "6ade3a8d-8516-5a88-baa0-f996f32730d2": "wanting to be everywhere and do everything to a moderate extent",
                        "9b27e3ff-f8ce-54c5-9220-0479ae53e889": "wanting to be everywhere and do everything to a significant extent",
                        "e232ba2d-36a9-524e-9fb2-8541b27bb569": "wanting to be everywhere and do everything to a severe extent"
                    }
                }
            ]
        },
        "section": [
            "Previous most extreme manic symptoms",
            "Activity symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "HM31",
                    "optionSummaries": {
                        "7e32475a-3fcc-51ac-b414-ab46578efdfd": "completing tasks more quickly and easily to a slight extent",
                        "a56fcb96-f868-5d08-a358-9b403eb3cd16": "completing tasks more quickly and easily to a moderate extent",
                        "e979459c-cb23-5cea-bedd-432a62288787": "completing tasks more quickly and easily to a significant extent",
                        "0f2a6025-0d8f-5d89-81a2-c3cc425cd7c4": "completing tasks more quickly and easily to an extreme extent"
                    }
                }
            ]
        },
        "section": [
            "Previous most extreme manic symptoms",
            "Activity symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} motivated to get involved in as many activities as possible",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "HM1",
                    "optionSummaries": {
                        "60caa355-cd97-591a-9344-1e9858939855": "slightly",
                        "3d860ce1-917d-5fdf-a025-ce36b81bb1fb": "moderately",
                        "8434c112-273f-5c5a-a704-62c8cb63dd51": "significantly",
                        "c44193b6-642c-56d3-ada3-eda9bd17a42e": "very much"
                    }
                },
                {
                    "questionId": "HM2",
                    "optionSummaries": {
                        "2b56aa7d-493f-5a18-aa9f-f97304f89130": "slightly",
                        "a046d610-d27a-59c1-b130-38312005cf1c": "moderately",
                        "fc6fa8a1-ab36-5c3d-901d-feec17df2f1a": "significantly",
                        "3bf2fe22-146e-560d-8aae-39a56747f149": "very much"
                    }
                },
                {
                    "questionId": "HM3",
                    "optionSummaries": {
                        "da93cad9-334a-57d5-b26d-fabb43b2d059": "slightly",
                        "7823432e-ad44-5fbe-9781-48d9ce136451": "moderately",
                        "1d37ab0b-0386-5463-a135-eebb9f0f0e0c": "significantly",
                        "dbce9e65-8768-5d55-af51-0fd58c8e9853": "very much"
                    }
                },
                {
                    "questionId": "HM4",
                    "optionSummaries": {
                        "67ff3da2-b682-5e3d-a30c-e07aa27c64b8": "slightly",
                        "5dd99b1f-4bcd-5921-857a-6f99b2bc139c": "moderately",
                        "1f142f13-5d5e-57af-82f3-5df564dae9cb": "significantly",
                        "c8fd6c78-0f80-5c1a-9cdf-61fd1d153c52": "very much"
                    }
                },
                {
                    "questionId": "HM5",
                    "optionSummaries": {
                        "a96ba3ce-dc9b-54ab-8a24-5ece4cf16447": "slightly",
                        "f6322217-0772-5ca4-a7a8-e8efbf97dbec": "moderately",
                        "f7cb9a05-4357-5077-8730-767d9ed58b02": "significantly",
                        "67484765-83ea-5089-99bf-bbf7a66818fa": "very much"
                    }
                },
                {
                    "questionId": "HM6",
                    "optionSummaries": {
                        "868022b7-397f-52c6-9d1e-77ce0c8ed442": "slightly",
                        "462c1a4b-5cc8-591b-864d-7a9319b983c0": "moderately",
                        "661c6bc5-4691-5ba3-b0bd-96222d039db2": "significantly",
                        "ed1586b4-e29c-5b36-9283-d6a861c277df": "very much"
                    }
                },
                {
                    "questionId": "HM7",
                    "optionSummaries": {
                        "7d828e74-497e-5b42-9adc-f6559649481e": "slightly",
                        "b43d7a75-2456-5a42-9e2c-2edbe34ccc12": "moderately",
                        "931a7dd0-61d5-54b9-9e85-3c5ff79d312b": "significantly",
                        "9d06d48e-dfe4-59da-af07-4baf37a61e5c": "very much"
                    }
                },
                {
                    "questionId": "HM18",
                    "optionSummaries": {
                        "d46fbd7a-074b-5014-9767-77e52f83e365": "slightly",
                        "e91203fa-5410-59f8-a2a7-cfbd0257e576": "moderately",
                        "6bafc310-38fa-5bc1-bca7-412515a46505": "significantly",
                        "2ccd221e-eeba-5fa4-b5b1-f1439c64398b": "very much"
                    }
                },
                {
                    "questionId": "HM19",
                    "optionSummaries": {
                        "fbfbcb17-2315-5dc6-a771-7f1051d7d464": "slightly",
                        "e75678bb-0b29-540e-8fbe-40a63336374d": "moderately",
                        "38b41f78-6e4b-5925-9642-2331ed02b6ed": "significantly",
                        "05764f7c-41bd-5cc0-a71e-be182e4dd627": "very much"
                    }
                },
                {
                    "questionId": "HM20",
                    "optionSummaries": {
                        "4eb576cf-e8d9-51f2-94cd-d232476ee5cb": "slightly",
                        "b8816e4b-2104-5ce7-a5b0-b804a1a0dc20": "moderately",
                        "be55154a-b96f-5c1c-91de-2a118e42e6f8": "significantly",
                        "ba281b75-7509-59be-a0b3-12bccbd60e6d": "very much"
                    }
                }
            ]
        },
        "section": [
            "Previous most extreme manic symptoms",
            "Activity symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} auditory or visual hallucinations",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "BP104b",
                    "optionSummaries": {
                        "75eb3568-195e-5720-827c-1fd0f84c6e75": "experienced"
                    }
                },
                {
                    "questionId": "BP104",
                    "optionSummaries": {
                        "de4464c8-78fe-5876-aa00-25826ce5ec25": "experienced"
                    }
                }
            ]
        },
        "section": [
            "Previous most extreme manic symptoms",
            "Psychotic symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} delusions",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "BP103b",
                    "optionSummaries": {
                        "c4bec2f3-b4c0-5a86-abf4-28921c5b1956": "experienced"
                    }
                },
                {
                    "questionId": "BP103",
                    "optionSummaries": {
                        "f2228920-3fec-5097-a63d-990c25d50c6c": "experienced"
                    }
                }
            ]
        },
        "section": [
            "Previous most extreme manic symptoms",
            "Psychotic symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "experience hallucinations or delusions {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "BP105",
                    "optionSummaries": {
                        "64aa1680-6b5b-5a5b-bb92-75995d27ddcd": "only when mood is 'up'",
                        "978f2ff4-94d9-5f7e-9126-e8657be0c7ca": "but unsure if these relate to mood",
                        "4e9d21b3-29c7-5526-ada9-67b61957994e": "when mood is 'up' and when mood is normal"
                    }
                }
            ]
        },
        "section": [
            "Previous most extreme manic symptoms",
            "Psychotic symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "experience hallucinations or delusions {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "BP105b",
                    "optionSummaries": {
                        "2700e917-0fd4-5241-975c-959339855190": "both when mood/energy levels are normal and when mood/energy levels are 'up'",
                        "ee14461c-9f47-5bb5-bdf8-44437a25fc44": "but unsure if these relate to mood",
                        "4431a100-df22-5217-896c-62c1e7c74723": "only when mood or energy levels are 'up'"
                    }
                }
            ]
        },
        "section": [
            "Previous most extreme manic symptoms",
            "Psychotic symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "CombinedAnswersSummary",
            "questionsIdGroups": [
                [
                    "BP87"
                ],
                [
                    "HM23"
                ]
            ],
            "groupsType": "Mean",
            "pattern": "{} self-esteem",
            "coefficients": {
                "0.1": "slightly inflated",
                "0.3": "moderately inflated",
                "0.6": "significantly inflated"
            }
        },
        "section": [
            "Previous most extreme manic symptoms",
            "Other symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} more self-confident than usual",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "BP87b",
                    "optionSummaries": {
                        "fb568891-2ea3-5200-bbdb-ac0695f30b02": "slightly",
                        "659c0d9a-389d-5b62-b3ac-e385dfafe63c": "moderately",
                        "5bc9fc9f-d402-56b0-97eb-fe64add38edf": "significantly",
                        "8cf63df7-755b-597a-9125-d7435c303261": "very much"
                    }
                }
            ]
        },
        "section": [
            "Previous most extreme manic symptoms",
            "Other symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "wearing {}more colourful clothes and/or makeup",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "HM28",
                    "optionSummaries": {
                        "bcd05aa7-d9e3-5bdc-8661-0daa33c7b9b3": "slightly ",
                        "366c6a1b-c8b2-5faf-9d2f-72dbde24e869": "moderately ",
                        "57de31c8-9bf0-5003-9dbb-0e8559f994dd": "significantly ",
                        "cec918d6-b855-5672-94a8-cf14e5ae963a": "very much "
                    }
                }
            ]
        },
        "section": [
            "Previous most extreme manic symptoms",
            "Other symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "current episode is {} previous episode",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "BP112.2",
                    "optionSummaries": {
                        "6079cfc0-03c5-5003-87ad-3dff357f0f74": "less severe than",
                        "9ff851b9-a640-557e-a750-a4d6e2ffbc49": "just as severe as",
                        "0220e07a-c424-5583-b5a4-d53440a7bc6e": "more severe than"
                    }
                },
                {
                    "questionId": "BP112.5",
                    "optionSummaries": {
                        "71ad4086-a47a-5265-86b2-eed25d6d5abe": "less severe than",
                        "7e08ea1c-7f7d-5f72-87fb-1df882aeed10": "just as severe as",
                        "e1e59a23-091e-54fa-b3ce-49a3fa26fb2a": "more severe than"
                    }
                }
            ]
        },
        "section": [
            "Previous most extreme manic symptoms",
            "Current episode is just as severe as previous episode"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} most severe episode",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "BP112.3",
                    "optionSummaries": {
                        "f596911a-c0b8-51b8-a6c3-e0e50444761a": "less extreme than",
                        "dc8d63aa-5242-52d9-b439-526944ab2713": "just as extreme as",
                        "5de2cc88-13a4-5f04-a3d2-1333852193f9": "more extreme than"
                    }
                },
                {
                    "questionId": "BP112.6",
                    "optionSummaries": {
                        "a2924f91-50e4-560e-a7e7-327ed9b0ad26": "less extreme than",
                        "bbc68d1c-8068-5eb7-a7ff-600e2ccb4e9f": "just as extreme as",
                        "3cd21659-e85a-524c-8549-44f033916396": "more extreme than"
                    }
                }
            ]
        },
        "section": [
            "Previous most extreme manic symptoms",
            "Current episode is just as severe as previous episode"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Basic needs: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "BP90.1",
                    "optionSummaries": {
                        "e22cf233-c174-52fb-af55-97bf9c46e971": "no impairment",
                        "d9fb150b-68b8-50ed-bb1d-eafd41f63f3b": "mild",
                        "c211fabc-a62d-5440-8ddc-f57eb21d7679": "moderate",
                        "eb97df23-3118-56bc-8c37-c54d7a750e4f": "significant",
                        "33e48f82-ae80-5f20-a3a5-79692d21ad1a": "severe"
                    }
                }
            ]
        },
        "section": [
            "Previous most extreme manic symptoms",
            "Functional impairment"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Work: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "BP91",
                    "optionSummaries": {
                        "46c90446-3ae9-53f5-9b57-ad19d66b5be6": "no impairment",
                        "0feda24f-7b01-557b-8ca5-10eb924cbdac": "mild",
                        "1f57d1a0-3682-5d7d-b66b-8179ec5c3988": "moderate",
                        "cc7c09ae-1cb7-5b9a-9cd3-8d1012b90ee8": "significant",
                        "7cd91611-c433-5f81-b829-8454750b387c": "severe"
                    }
                }
            ]
        },
        "section": [
            "Previous most extreme manic symptoms",
            "Functional impairment"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "BP91.1"
            ],
            "prefix": "Time off work: ",
            "formatText": "{}"
        },
        "section": [
            "Previous most extreme manic symptoms",
            "Functional impairment"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Relationships: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "BP93",
                    "optionSummaries": {
                        "c88e3fb5-913a-5c5c-a42b-603f7bb60a40": "no impairment",
                        "94795194-4a80-590f-be62-ee78f2fa0ff5": "mild",
                        "f35fe383-d2aa-51c4-9fde-961b1de6d52b": "moderate",
                        "82997889-d795-572b-a597-e484ef078b39": "significant",
                        "940c16a8-1835-582a-9dad-bd52e3a49cc7": "severe"
                    }
                }
            ]
        },
        "section": [
            "Previous most extreme manic symptoms",
            "Functional impairment"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Leisure: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "BP100",
                    "optionSummaries": {
                        "4a0f1c4d-38cf-5de4-99e3-0ee818f9709c": "no impairment",
                        "db7aa4b7-99a5-5475-a4e8-a744f3ef5a4a": "mild",
                        "a20d7b84-720e-53c5-a450-45cbde434507": "moderate",
                        "182e121d-415c-5f42-a76c-a996f5f38b3f": "significant",
                        "026a1cea-7cfc-540b-bfe5-42886be90595": "severe"
                    }
                }
            ]
        },
        "section": [
            "Previous most extreme manic symptoms",
            "Functional impairment"
        ]
    }
];
