import React, { useContext, useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import { Heading, Page, Text } from '@psyomics/components'
import Styles from './ReferralIStats.module.scss'
import { TextField, Grid } from '@mui/material'
import { InsightsGroup, Stat } from 'ui/app/components/InsightsGroup/InsightsGroup'
import { useAuth } from 'registration/context/auth'
import { InfoContext } from 'configure'
import CustomError from 'ui/app/pages/CustomError'
import { Splash } from 'ui/app/pages/Splash'
import { getCurrentDateISO, getLastMonthDateISO } from 'registration/utils/date'
import { Auth } from 'aws-amplify'
import Header from 'ui/app/components/Header'
import { useParams } from 'react-router-dom'

interface DateFilter {
    startDate: string
    endDate: string
}

const ReferralStats: React.FC = () => {
    const { getCurrentUser } = useAuth()
    const info = useContext(InfoContext)
    const [stats, setStats] = useState<Stat[][] | null>(null)
    const [error, setError] = useState<string | null>(null)
    const { organisationCode } = useParams<{ organisationCode: string }>()

    const dateNow = getCurrentDateISO()
    const lastMonth = getLastMonthDateISO()
    const [selectedDateFilter, setSelectedDateFilter] = useState<DateFilter>({
        startDate: lastMonth,
        endDate: dateNow,
    })

    useEffect(() => {
        ;(async () => {
            const user = await getCurrentUser()
            const email = user?.attributes.email
            const unexpectedErrorMsg =
                'Something wrong happened. Please try again. If the problem persists, please contact us.'

            const dateFilter = {
                startDate: selectedDateFilter.startDate,
                endDate: selectedDateFilter.endDate,
            }

            const currSession = await Auth.currentSession()
            const jwt = currSession.getAccessToken().getJwtToken()

            const refApiResponse = await fetch(
                `${info?.referralApiUrl}practitioner/referral-stats?organisationCode=${encodeURIComponent(
                    organisationCode as string
                )}&dateFilter=${encodeURIComponent(JSON.stringify(dateFilter))}`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    },
                }
            )

            const apiData = await refApiResponse.json()
            if (refApiResponse.status === 404) {
                Sentry.captureException(
                    `Could not find data for Practitioner email address ${email}. Error from the API: ${apiData.message}`,
                    { tags: { section: 'Referral Stats' } }
                )
                setError(
                    'We could not find the relevant data for your organisation. Please try again. If the problem persists, please contact us.'
                )
                return
            }
            if (!refApiResponse.ok) {
                Sentry.captureException(
                    `Unexpected response from referral-api when fetching referral data. Status: ${refApiResponse.status} - ${refApiResponse.statusText}`,
                    { tags: { section: 'Referral Stats' } }
                )
                setError(unexpectedErrorMsg)
                return
            }

            const statsData = Object.fromEntries(Object.entries(apiData).map(([k, v]) => [k, Number(v)]))
            const {
                totalReferrals,
                totalCompletionsBeforeLastNDays,
                totalReferralsBeforeLastNDays,
                totalCancelledBeforeLastNDays,
                totalCompletions,
                totalStarted,
                totalCancelled,
                totalExpired,
                totalSignUps,
            } = statsData
            const isDataReceivedValid = Object.values(statsData).every((value) => value != null)
            if (!isDataReceivedValid) {
                Sentry.captureException(
                    `Referral-api not sending expected data. Data received: ${JSON.stringify(statsData)}`
                )
                setError(unexpectedErrorMsg)
            }

            const completionRateBeforeLastNDays = parseFloat(
                (
                    (totalCompletionsBeforeLastNDays /
                        (totalReferralsBeforeLastNDays - totalCancelledBeforeLastNDays)) *
                    100
                ).toFixed(2)
            )
            const completionRate = parseFloat(((totalCompletions / (totalReferrals - totalCancelled)) * 100).toFixed(2))

            setError(null)
            setStats([
                [
                    { name: 'Total referrals', value: totalReferrals },
                    { name: 'Signups', value: totalSignUps },
                    { name: 'Assessment started', value: totalStarted },
                    { name: 'Completions', value: totalCompletions },
                    { name: 'Cancelled referrals', value: totalCancelled },
                    { name: 'Expired referrals', value: totalExpired },
                    {
                        name: 'Overall completion rate',
                        value: completionRate,
                        unit: (value: string) => (totalReferrals !== 0 ? `${value}%` : '-'),
                    },
                    {
                        name: 'Projected completion rate *',
                        value: completionRateBeforeLastNDays,
                        unit: (value: string) => (totalReferralsBeforeLastNDays !== 0 ? `${value}%` : '-'),
                    },
                ],
            ])
        })()
    }, [selectedDateFilter])

    return (
        <>
            {!stats && !error && <Splash />}
            {stats && !error && (
                <Page width="wide" header={<Header patientFacing={false}></Header>}>
                    <Heading el="h1" size="heading1" color="mid" css={{ mt: 5, mb: 5 }}>
                        Referral Statistics
                    </Heading>
                    <Text css={{ mb: 5 }}>
                        The figures below represent referral metrics for your organisation, starting from the selected
                        date up to today.
                    </Text>
                    <Grid container spacing={2}>
                        <Grid item>
                            <TextField
                                InputProps={{
                                    inputProps: { min: '2022-01', max: selectedDateFilter.endDate.split('T')[0] },
                                }}
                                type="date"
                                label="From"
                                value={selectedDateFilter.startDate.split('T')[0]}
                                onChange={(event) => {
                                    const newStartDate = event.target.value
                                    if (!newStartDate || newStartDate === 'dd/mm/yyyy') {
                                        return
                                    } else {
                                        setSelectedDateFilter((prevState) => ({
                                            ...prevState,
                                            startDate: newStartDate,
                                        }))
                                    }
                                }}
                                className={Styles.datepicker}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                InputProps={{
                                    inputProps: {
                                        min: selectedDateFilter.startDate.split('T')[0],
                                        max: dateNow.split('T')[0],
                                    },
                                }}
                                type="date"
                                label="To"
                                value={selectedDateFilter.endDate.split('T')[0]}
                                onChange={(event) => {
                                    const newEndDate = event.target.value
                                    if (!newEndDate || newEndDate === 'dd/mm/yyyy') {
                                        return
                                    } else {
                                        const newEndDateDate = new Date(newEndDate)
                                        newEndDateDate.setUTCHours(23, 59, 59, 999)
                                        setSelectedDateFilter((prevState) => ({
                                            ...prevState,
                                            endDate: newEndDateDate.toISOString(),
                                        }))
                                    }
                                }}
                                className={Styles.datepicker}
                            />
                        </Grid>
                    </Grid>

                    {stats.map((group, index) => (
                        <InsightsGroup stats={group} key={index} styles={Styles} />
                    ))}
                    <Text style={{ marginTop: 'var(--s-2)' }} size="xsmall">
                        * The percentage of users that completed the assessment after having the allocated 7 days.
                    </Text>
                </Page>
            )}
            {error && !stats && <CustomError message={error} homepageRedirect={`/account/${organisationCode}`} />}
        </>
    )
}

export default ReferralStats
