import React from 'react'

const DownArrow: React.FC<{ color?: string; width: number; height: number }> = ({ color = 'white', height, width }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            shapeRendering="geometricPrecision"
            textRendering="geometricPrecision"
            imageRendering="optimizeQuality"
            fillRule="evenodd"
            clipRule="evenodd"
            viewBox="0 0 464 512.05"
            height={`${height}px`}
            width={`${width}px`}
            color={color}
        >
            <path
                fillRule="nonzero"
                d="M332.7 0v243.52h92.31c15.47.69 26.46 5.78 32.82 15.43 17.21 25.8-5.25 52.31-22.6 69.25l-173.6 169.52c-17.29 19.1-41.93 19.1-59.22 0L24.42 323.32C8.03 307.26-9.67 282.76 6.21 258.95c6.35-9.65 17.34-14.74 32.82-15.43h92.31V0H332.7z"
            />
        </svg>
    )
}

export default DownArrow
