"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.symptoms = void 0;
exports.symptoms = [
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} experiences emotions more intensely than others",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "EUPD-1",
                    "optionSummaries": {
                        "15fa7f47-7f26-595f-986d-532716789a6f": "sometimes",
                        "52fb5f3a-b30f-5f77-a2bd-0620b67a1adc": "quite often",
                        "5efe5d1e-b865-5328-b632-d1235e4f612d": "very often",
                        "9c2ca9f0-e093-5ac3-8a23-494cb9298513": "always"
                    }
                }
            ]
        },
        "section": [
            "Current symptoms and impairment",
            "Emotional symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} mood in a day",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "EUPD-2",
                    "optionSummaries": {
                        "d25ceaaa-5094-5581-a0df-81187f0ef77d": "slightly changing",
                        "04b30646-e5ef-56c6-8e67-b80b07442d24": "somewhat rapidly changing",
                        "336fae53-720d-5c45-bc21-01f833ad9e22": "rapidly changing",
                        "44285291-8282-578e-858a-31e91b05796f": "extremely rapidly changing"
                    }
                }
            ]
        },
        "section": [
            "Current symptoms and impairment",
            "Emotional symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} intense anger",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "EUPD-8",
                    "optionSummaries": {
                        "8d9927a8-6d9c-544d-88e1-b39adbe9c2dd": "sometimes experiences",
                        "4910399c-7c7b-5e77-9f24-f8a248ddf28d": "somewhat often experiences",
                        "577b2e11-8447-5953-aeb7-b99ee825deb9": "very often experiences",
                        "6bbed69c-d98b-5eff-98da-549cac6b89ac": "extremely often experiences"
                    }
                }
            ]
        },
        "section": [
            "Current symptoms and impairment",
            "Emotional symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "experiences {} paranoia or dissociation when stressed",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "EUPD-15.2",
                    "optionSummaries": {
                        "88fe8be6-0bab-4c0e-9ad8-04857d021fcf": "slight",
                        "3d3cbdd0-4ea6-4d27-b75d-769fa987bf26": "moderate",
                        "41a45947-dfc1-4f7c-b42e-fdadc495d035": "significant",
                        "e960a0f7-7a43-429b-93db-32dc25f55c53": "extreme"
                    }
                }
            ]
        },
        "section": [
            "Current symptoms and impairment",
            "Emotional symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "MultiOptionSummary",
            "questionId": "EUPD-4",
            "title": "Mood change trigger(s)",
            "overrides": {}
        },
        "section": [
            "Current symptoms and impairment",
            "Emotional symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "EUPD-3"
            ],
            "prefix": "duration of individual mood states: ",
            "formatText": "{}"
        },
        "section": [
            "Current symptoms and impairment",
            "Emotional symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} identity disturbance",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "EUPD-12",
                    "optionSummaries": {
                        "19bca353-363e-5287-84dc-f9a8692946ed": "mild",
                        "1c7ce652-b851-58f6-85e6-768f765fbe0f": "moderate",
                        "e7a6bf13-e4ed-5b8b-bed2-e40884e40e38": "significant",
                        "96e3b252-6135-5811-8a14-b5cb7973f263": "extreme"
                    }
                },
                {
                    "questionId": "EUPD-13",
                    "optionSummaries": {
                        "9dfc160e-6d03-5394-9411-5086eb0d84f6": "mild",
                        "d8bd0a18-11d0-5da4-9048-512a3c027199": "moderate",
                        "0eef6dac-01b8-5acd-90a6-b8bbdc2600d6": "significant",
                        "a8ff3134-ce03-54cd-8154-6064c567fe71": "extreme"
                    }
                }
            ]
        },
        "section": [
            "Current symptoms and impairment",
            "Identity symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} changing sense of self",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "EUPD-10",
                    "optionSummaries": {
                        "cbfbd155-5a25-52e3-9586-68db54b9dd39": "slightly",
                        "e73d0b05-7b4b-5016-9e9b-f199b371c9af": "moderately",
                        "065d8ebc-76ad-5e44-8a8a-521a6d319107": "very much",
                        "f1f68c05-871b-52f9-9891-c1eb99bc9b6f": "extremely"
                    }
                }
            ]
        },
        "section": [
            "Current symptoms and impairment",
            "Identity symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} low self-esteem",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "EUPD-11",
                    "optionSummaries": {
                        "c9665e32-d743-5773-8683-57415abd4af6": "slightly",
                        "23bece9a-88d3-5c82-8057-3015a6b79872": "moderately",
                        "9f9ba45a-2149-5fe0-96f9-38b83794dcd6": "very",
                        "2e7e6acf-184c-5ff5-97b9-b57549c75430": "extremely"
                    }
                }
            ]
        },
        "section": [
            "Current symptoms and impairment",
            "Identity symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "tendency to engage in impulsive behaviours that are harmful and risky: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "EUPD-15",
                    "optionSummaries": {
                        "6b84f725-12d7-5ce7-9dcf-1483247a1a95": "not any more than others",
                        "0e690495-a758-5d5f-93fd-5fccf3304a93": "to a small extent",
                        "83fa9a5c-2067-56d7-9d8f-7e8df8a9438f": "somewhat",
                        "9ce96b06-685e-587c-9c34-4c80e5a1497f": "very much",
                        "cf3fab17-0381-50f7-8f68-8de714f54af0": "to an extreme extent"
                    }
                }
            ]
        },
        "section": [
            "Current symptoms and impairment",
            "Impulsivity symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "acts impulsively to communicate feelings",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "EUPD-15.1",
                    "optionSummaries": {
                        "40bf173b-27ae-5980-87bd-2e9287bea4e1": ""
                    }
                }
            ]
        },
        "section": [
            "Current symptoms and impairment",
            "Impulsivity symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} of abandonment",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "EUPD-5",
                    "optionSummaries": {
                        "2f3b0b13-2c2d-578f-9059-10e39f93d419": "sometimes afraid",
                        "ef9010e1-083b-5af9-a20d-3e9bd4bb20e2": "quite often afraid",
                        "5cff41b8-0903-5527-bc17-cd7326b077d3": "very often afraid",
                        "88234b57-8f96-5f70-9e96-516192851a59": "constantly afraid"
                    }
                }
            ]
        },
        "section": [
            "Current symptoms and impairment",
            "Relationship symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} going to extreme lengths to avoid abandonment",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "EUPD-6",
                    "optionSummaries": {
                        "6ee2285c-b326-5ba8-b2cb-4296da4b714d": "sometimes",
                        "a67e923e-933d-5b5c-93f9-7f7a5712bbe6": "quite often",
                        "c851a6d3-10b8-5cbb-bf8b-93473d172427": "very often",
                        "f3b124a8-51fa-5ab6-9520-e1c0a1a4ce23": "constantly"
                    }
                }
            ]
        },
        "section": [
            "Current symptoms and impairment",
            "Relationship symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} unstable relationships",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "EUPD-7",
                    "optionSummaries": {
                        "c086bfd4-9480-5dd1-baa3-350a440d8b7e": "sometimes getting into",
                        "a7d77d06-87b4-54f2-a7ee-6388ddc50b3e": "quite often getting into",
                        "3e1c46ad-4ca6-56ce-9680-7b2936e513d1": "very often getting into",
                        "95c52894-0082-5afe-ba2e-117446e682c5": "always getting into"
                    }
                }
            ]
        },
        "section": [
            "Current symptoms and impairment",
            "Relationship symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Overall level of impairment: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "EUPD-19.0",
                    "optionSummaries": {
                        "3aad497e-3595-54e6-94a4-add4730ee861": "none",
                        "331e1b22-ef8d-5cac-8f9c-004666f13d0a": "mild",
                        "1213f822-87a0-516a-9832-682008ea5f4a": "moderate",
                        "8dd94ec7-732b-55da-add7-b38553048df6": "significant",
                        "1a640268-73b2-5133-ae7d-165973ac73a1": "severe"
                    }
                }
            ]
        },
        "section": [
            "Current symptoms and impairment",
            "Functional impairment"
        ]
    },
    {
        "answerSummary": {
            "type": "MultiOptionSummary",
            "questionId": "EUPD-19",
            "title": "Areas of impairment",
            "overrides": {
                "8d8acccc-449c-51b9-9711-e07c69c1acfd": "Other (not listed)",
                "e16bb2f7-89ba-452c-a7ba-307adca9adc4": "None reported"
            }
        },
        "section": [
            "Current symptoms and impairment",
            "Functional impairment"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Experiencing symptoms {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "EUPD-16",
                    "optionSummaries": {
                        "c5325cd4-a4dd-5ae5-b59a-a1170674c53d": "only recently, or for just a short period of time in the past",
                        "234e69c8-3812-52bc-933c-e80cd2a16c2e": "for several days",
                        "bf9981cb-fee0-51ca-9c5f-9c0859029b31": "for several weeks",
                        "4f737e01-d8bd-52b9-83ba-266c96a8cb0f": "for several months",
                        "650808a8-2e6f-5f93-aada-7e217f78687c": "for many years",
                        "549c3369-b2fa-5346-bc86-fdeb2d56f2e9": "for as long as they can remember"
                    }
                }
            ]
        },
        "section": [
            "Duration and frequency of symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "EUPD-17"
            ],
            "prefix": "Age of onset: ",
            "formatText": "{}"
        },
        "section": [
            "Duration and frequency of symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} had a period when unbothered by intense feelings",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "EUPD-18",
                    "optionSummaries": {
                        "b6e918f8-7528-5e71-89d3-76924c5c7872": "Has not",
                        "f0d62708-1864-5541-a81d-7817b478c4d4": "Has"
                    }
                }
            ]
        },
        "section": [
            "Duration and frequency of symptoms"
        ]
    }
];
