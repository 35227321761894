import { createContext, useContext } from 'react'
import { v4 } from 'uuid'
import { ReferralCode, UserType } from 'censeo-core'
import * as Sentry from '@sentry/react'

interface UnregisteredUserEvents {
    visitedSite: (userId: string | undefined) => void
    noUserFound: () => void
    startedRegistration: (userType: UserType, referralCode?: ReferralCode) => void
    confirmedOver18: (userType?: UserType, referralCode?: ReferralCode) => void
    elegibilityCompleted: (userType?: UserType, referralCode?: ReferralCode) => void
    verificationCodeSent: () => void
    insertedName: (referralCode?: ReferralCode) => void
    nhsRecordNotFound: (data: { message?: string; responseCode?: string }) => void
    nhsSearchError: (data: { message?: string; responseCode?: string }) => void
    sessionId: string
}

export const ReportingContext = createContext<UnregisteredUserEvents | undefined>(undefined)

export const sessionId = v4()

// prettier-ignore
export const createUnregisteredUserEvents = (reportingEndpoint: string): UnregisteredUserEvents => {
    return {
        visitedSite: (userId: string | undefined) => sendEvent(reportingEndpoint, sessionId, userId ? 'loggedIn' : 'visitedSite', userId ? { userId } : {}),
        noUserFound: () => sendEvent(reportingEndpoint, sessionId, 'noUserFound'),
        startedRegistration: (userType: UserType, referralCode?: ReferralCode) => sendEvent(reportingEndpoint, sessionId, 'startedRegistration', { userType, referralCode }),
        confirmedOver18: (userType?: UserType, referralCode?: ReferralCode) => sendEvent(reportingEndpoint, sessionId, 'confirmedOver18', { userType, referralCode }),
        elegibilityCompleted: (userType?: UserType, referralCode?: ReferralCode) => sendEvent(reportingEndpoint, sessionId, 'elegibilityCompleted', { userType, referralCode }),
        verificationCodeSent: () => sendEvent(reportingEndpoint, sessionId, 'verificationCodeSent'), // D2C only now
        insertedName: (referralCode?: ReferralCode) => sendEvent(reportingEndpoint, sessionId, 'insertedName', { userType: UserType.Nhs, referralCode }),
        nhsRecordNotFound: (data) => sendEvent(reportingEndpoint, sessionId, 'nhsRecordNotFound', data),
        nhsSearchError: (data) => sendEvent(reportingEndpoint, sessionId, 'nhsSearchError', data),
        sessionId
    }
}

const sendEvent = (endpoint: string, sessionId: string, event: string, data = {}) => {
    const requestOptions: RequestInit = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            event,
            sessionId,
            ...data,
        }),
        mode: 'no-cors',
    }
    fetch(endpoint, requestOptions).catch((err) => {
        Sentry.captureException(err)
    })
}

export const useReportingApi = (): any => useContext<any>(ReportingContext)
