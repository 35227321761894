"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.questionPredicates = exports.tests = exports.aspects = exports.questions = void 0;
exports.questions = [
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "aa668802-d458-5b2d-b48f-e4058a640295",
                "text": "Continue",
                "value": 1
            }
        ],
        "id": "GAD-S",
        "text": "This session will ask questions about if you have experienced any worries or anxieties in the last six months. \nSome questions might feel difficult to answer but try the best you can.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "eaaa8028-c116-5ae3-bb59-5795820feca8",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "0a2185e8-e4b7-5f48-a3ff-2fa717d647dd",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "24032a9e-f5b6-5f43-8ddf-305888669ad1",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "f8d49124-01dc-5d55-add9-5ed87d998e00",
                "text": "Very",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "c819bfe0-f79d-5132-8b3a-8604c0c5b83a",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "GAD-s1",
        "text": "In the last six months, have you been feeling very anxious or worried for a lot of the time? This includes worrying much more than other people, or about things that other people are not worried about.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "75ea5311-003b-57dc-8ca5-e08f759cdf02",
                "text": "Less than 2 weeks",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "617c53a6-a5af-5b57-a7dc-1fc4fa175844",
                "text": "Between 2 weeks and 3 months",
                "value": 0.2
            },
            {
                "__typename": "Option",
                "id": "4bbc59ac-1581-5084-9e92-2b746637ff52",
                "text": "Between 3 and 5 months",
                "value": 0.4
            },
            {
                "__typename": "Option",
                "id": "ed0ad572-166e-587e-b191-2cbe81387f5a",
                "text": "Between 6 months and 1 year",
                "value": 0.6
            },
            {
                "__typename": "Option",
                "id": "adab9f0b-f6e7-54c4-9cf1-087cdf831412",
                "text": "Longer than one year",
                "value": 0.8
            },
            {
                "__typename": "Option",
                "id": "1943ebd6-08ab-5b8e-a57b-7e397d303526",
                "text": "Most of my life",
                "value": 1
            }
        ],
        "id": "GAD-s3a",
        "text": "How long have you been feeling anxious or worried? It doesn't matter if you can't remember exactly, please just give your best estimate.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "276f0539-3b02-5717-a333-3d5ac219a5b4",
                "text": "I am mainly worried about one area or thing only (e.g. social situations, health, work)",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "0caeb854-db8e-5a20-a344-0c9282f65f78",
                "text": "I am worried about a few different areas or things",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "5a1c5af4-b628-5554-bf63-4ca68ef2b328",
                "text": "I am worried about many areas or things",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "9d0741b6-d8b6-5216-90d0-df6743af1aa0",
                "text": "I feel like I am worried about almost everything",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "8d25005e-a051-5bfc-afc2-9192701b043f",
                "text": "I'm not sure",
                "value": 1
            }
        ],
        "id": "GAD-s4a",
        "text": "How many different things or areas of your life are you worried about?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "9814dd2a-4c6e-579b-8cc1-c90b8284cb73",
                "text": "Less than 1 day per week",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "4059602c-013b-5f54-890f-64e55583f3dc",
                "text": "Between 1 and 3 days per week",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "6e2595a2-ea1e-5ca5-ab11-c65eb6cb6157",
                "text": "More than 4 days per week",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "cc1895bc-b31f-503e-a147-172d1a0faa64",
                "text": "Always",
                "value": 1
            }
        ],
        "id": "GAD-s6a",
        "text": "How many days of the week do you worry or feel anxious?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "b27d60cc-fc23-5128-8e5c-81f05cf254de",
                "text": "I mostly worry about the past",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "fa80af33-1ed9-5e02-abd3-85852c65204e",
                "text": "I mostly worry about the future",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "bdba5003-2589-507d-bb90-53534cc9b942",
                "text": "I worry about both the past and the future",
                "value": 1
            }
        ],
        "id": "GAD-3a",
        "text": "Do you worry mostly about things that have happened in the past or about future events?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "b3612239-fed1-5631-aba2-be981c6cd68c",
                "text": "They would think my worries are completely sensible and in proportion with the likelihood of the event occurring",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "748f4a0b-00a7-5b15-b390-2dfb8ac9eb1d",
                "text": "They would probably worry as much as I do",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "517a9806-ffe8-52a9-86df-e947a47e169c",
                "text": "They would probably think I tend to worry more than necessary",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "84762e8e-edca-5c2c-a00f-fed69c13b87c",
                "text": "They would probably think I tend to worry too much",
                "value": 1
            }
        ],
        "id": "GAD-4a",
        "text": "How do you think other people would view your worries?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "b35efac1-a97a-5c72-9f4f-853168eba6da",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "63fdf718-12b4-5497-abff-2ae6a86b484b",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "6bb8be71-d051-540f-8d81-5dff1869bbcc",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "bd66ed3d-4e63-5fe8-88a6-fbbf3ab55502",
                "text": "Very much so",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "59b5a96e-b0f6-5fd3-8c20-57c053f14b06",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "GAD-5a",
        "text": "Do you often worry about potentially negative outcomes of events that are not likely to happen?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "881a3a3c-3ac2-51ae-a640-e68221f14a08",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "7d60ebb2-5f2d-5ab0-b9ca-31210502ece9",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "81a08016-21d5-5f6e-b174-710e540e00c1",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "f26a3508-c284-53a5-8bc7-63514fbe6737",
                "text": "Very much so",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "8996813a-e012-57cb-8eab-b6926c27eba2",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "GAD-6a",
        "text": "Do you find it difficult to stop yourself from worrying?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "e38f6e4b-496e-5d17-a784-9c123e04aeba",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "8dc41a6e-72d4-5c1f-a416-f085995a1878",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "3b9e5247-4c0f-5c9d-9ffb-822ab95bee8a",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "d7d05c32-44cb-59c7-84b0-95e98fde6077",
                "text": "Very much so",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "80dffd87-d3ca-5977-9aee-b90eebcc4eb1",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "GAD-7a",
        "text": "Have you been having thoughts or worries that you would like to get rid of but can't?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "be94d500-cc6a-5a66-9f35-96ebe11414c3",
                "text": "Never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "80887b2c-7c9f-5105-a64a-b3bb33442ac6",
                "text": "Less than 1 day per week",
                "value": 0.2
            },
            {
                "__typename": "Option",
                "id": "e1dd4f50-28b4-51eb-8c34-bf8d3145816f",
                "text": "Between 1 and 2 days per week",
                "value": 0.4
            },
            {
                "__typename": "Option",
                "id": "985dc1a3-7ea9-5f5c-8af3-6159ac9c90bf",
                "text": "Between 3 and 4 days per week",
                "value": 0.6
            },
            {
                "__typename": "Option",
                "id": "a7ecd81c-cd6a-5592-892f-8e391b9a115c",
                "text": "Between 5 and 6 days per week",
                "value": 0.8
            },
            {
                "__typename": "Option",
                "id": "ce522c31-e5ef-51e2-8fa5-d9826566ed1e",
                "text": "Always",
                "value": 1
            }
        ],
        "id": "GAD-8a",
        "text": "How often does your anxiety make you restless and unable to relax?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "4e00dba8-beca-5357-8963-91dec200f76b",
                "text": "Never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "35150196-067f-58af-b669-6e41443a3601",
                "text": "Less than 1 day per week",
                "value": 0.2
            },
            {
                "__typename": "Option",
                "id": "03b91bf0-49f5-5760-ae70-8e92ca65aa0e",
                "text": "Between 1 and 2 days per week",
                "value": 0.4
            },
            {
                "__typename": "Option",
                "id": "b7c86f29-540d-56d7-a59a-7035d91396d8",
                "text": "Between 3 and 4 days per week",
                "value": 0.6
            },
            {
                "__typename": "Option",
                "id": "cbcfc13e-979a-5e51-8698-f4d686fb5c09",
                "text": "Between 5 and 6 days per week",
                "value": 0.8
            },
            {
                "__typename": "Option",
                "id": "b41e2b30-b377-5738-9ebe-8b9a7b6c35b7",
                "text": "Always",
                "value": 1
            }
        ],
        "id": "GAD-9a",
        "text": "How often does your anxiety make you feel tense?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "a30b13cd-454a-529d-a598-c5192a29668e",
                "text": "Never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "44ddd9b1-23aa-570e-bbce-1bef374fe68a",
                "text": "Less than 1 day per week",
                "value": 0.2
            },
            {
                "__typename": "Option",
                "id": "e16d33eb-317e-55aa-90fc-147bde51e99b",
                "text": "Between 1 and 2 days per week",
                "value": 0.4
            },
            {
                "__typename": "Option",
                "id": "e36ed8e3-ca0e-5d7e-826f-84c47842e71c",
                "text": "Between 3 and 4 days per week",
                "value": 0.6
            },
            {
                "__typename": "Option",
                "id": "0988ae4f-c9f2-5d44-a2cc-ecb83a2ec620",
                "text": "Between 5 and 6 days per week",
                "value": 0.8
            },
            {
                "__typename": "Option",
                "id": "1632140d-fefe-50b3-b11f-3ae261143e25",
                "text": "Always",
                "value": 1
            }
        ],
        "id": "GAD-10a",
        "text": "How often does your anxiety cause muscle aches, trembling, twitching or feeling shaky?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "8ce6bca8-7cea-5cae-b31f-9b03eb4f7322",
                "text": "Never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "68c692fb-cc63-5ad6-b88e-5e45ad971c82",
                "text": "Less than 1 day per week",
                "value": 0.2
            },
            {
                "__typename": "Option",
                "id": "c1926d2c-b894-5ae1-bbdd-f8c9954ce55a",
                "text": "Between 1 and 2 days per week",
                "value": 0.4
            },
            {
                "__typename": "Option",
                "id": "ba121413-fa3c-5d39-960f-827d7b0d55b8",
                "text": "Between 3 and 4 days per week",
                "value": 0.6
            },
            {
                "__typename": "Option",
                "id": "62411dbb-3d28-57dc-ade4-d19a340fc6ed",
                "text": "Between 5 and 6 days per week",
                "value": 0.8
            },
            {
                "__typename": "Option",
                "id": "5779c477-4f6c-533d-a15b-33f106f84348",
                "text": "Always",
                "value": 1
            }
        ],
        "id": "GAD-11a",
        "text": "How often do your worries interfere with your sleep by making it hard to sleep at night, making you wake up in the middle of the night or by causing poor sleep quality?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "89cda9bd-e311-53fa-95c1-1adab2202633",
                "text": "Pretty good: I don't feel too tired during the day, especially if I've had a good night's sleep",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "4fb31d91-3520-5676-90c0-d3c05e9abfb8",
                "text": "OK: I go through some periods of feeling tired during the day, but mostly have enough energy to get on with things",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "c74d8b74-1128-5f73-bfd2-2c3813bfdd4e",
                "text": "Bad: I frequently feel very tired during the day, making it hard to go about my daily routine",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "75847021-68a5-5e54-938f-ebae91570246",
                "text": "Very bad: pretty much all the time I feel so exhausted I can't function properly and have no energy to do anything",
                "value": 1
            }
        ],
        "id": "GAD-12a",
        "text": "How would you describe your energy levels during this current period of anxiety?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "04216753-61d0-5b51-b49a-4ac56b8a54dd",
                "text": "No, most of the time I feel like I can focus on tasks well enough to complete them",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "42da2d35-01a4-50f9-95e5-c7bbc4c8eda7",
                "text": "I feel like I can usually focus well enough to complete tasks, but sometimes I start to worry and find it hard to continue focusing",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "8dda5767-c89f-5932-808d-a7729c3f935b",
                "text": "I often find it hard to focus on tasks well enough to complete them because I start to worry",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "40abb98e-db00-5505-85be-59a5dd4ef092",
                "text": "I find it almost impossible to focus on a task well enough to complete it because I am constantly worrying",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "15d0ef6b-b98e-5156-9b9b-c46a9dc15ed7",
                "text": "I am never able to complete tasks because I am constantly worrying",
                "value": 1
            }
        ],
        "id": "GAD-13a",
        "text": "Do you find that your worries tend to interfere with your ability to focus on tasks?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "8f4ab7fd-f018-5862-af97-d98aa222664a",
                "text": "No, never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "6ac69add-b72f-5784-84a9-a517721463e6",
                "text": "Seldom",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "b0335c5e-d91f-5e19-9a45-4936bd9e3bea",
                "text": "About half the time",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "0623617f-3483-5ad7-ba3a-dd6b95c269d5",
                "text": "Usually",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "e3ffd1b0-96fc-5dc0-8628-99e770d220ff",
                "text": "Always",
                "value": 1
            }
        ],
        "id": "GAD-14a",
        "text": "Are you more easily annoyed or irritated than usual as a result of your anxiety?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "3283b608-28d7-5390-af36-f103b5ead7ce",
                "text": "No, never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "dfe08faa-d933-5ebc-b3dd-20cc46202224",
                "text": "Seldom",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "632e07d1-df69-590c-a8f2-0b4f7ad343e1",
                "text": "About half the time",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "6447e557-156f-59a1-9cbc-cd9d4b9bcbee",
                "text": "Usually",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "39f33fe3-3b5e-551a-8902-6a8c7ab4c148",
                "text": "Always",
                "value": 1
            }
        ],
        "id": "GAD-15a",
        "text": "Does your anxiety make it harder than usual to sit still?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "29cecca9-7507-5ebd-b392-acfda0236820",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "f468532b-0dfb-546a-87bc-4840ffcab3e0",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "ca6c7d1d-3395-58b8-a87d-95fba13b4ca3",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "93c614a5-158b-54bb-b179-2d356411ab81",
                "text": "Very much so",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "85731e72-1516-5c0b-8201-d7479523cbcd",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "GAD-17a",
        "text": "Do you feel tired more easily than usual in this current period of anxiety?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "cebd5d36-2f4f-5d6c-a864-ee09cf1af699",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "7cb98422-b8c9-5f92-aee6-74cf0192ea01",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "63b22d31-0933-571a-9c46-eb63c6373884",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "af347157-5a07-5187-b207-b672ecf979dc",
                "text": "Very much so",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "7035a10f-7dd7-55bd-946c-2cc73ffa5e6e",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "GAD-18a",
        "text": "Have your worries or anxiety been causing you emotional distress, for example you can't stop thinking about your worries or they have been keeping you up at night?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "51801ae8-62d1-5a94-b757-a0b0851ac6bc",
                "text": "Basic needs such as eating and sleeping"
            },
            {
                "__typename": "Option",
                "id": "a7dc1041-6095-5630-9480-ed7c33997997",
                "text": "Working or studying"
            },
            {
                "__typename": "Option",
                "id": "3c648d60-89ec-5e44-9bf1-dc362139d849",
                "text": "Intimate relationships"
            },
            {
                "__typename": "Option",
                "id": "863da48d-f2eb-52f4-8c4e-feda60cda5c4",
                "text": "Social life"
            },
            {
                "__typename": "Option",
                "id": "1acf1647-150f-5031-9599-8db8e23c423d",
                "text": "Family"
            },
            {
                "__typename": "Option",
                "id": "67df9ae6-c783-5f09-9975-a117ab653011",
                "text": "Leisure activities, hobbies and interests"
            },
            {
                "__typename": "Option",
                "id": "0a747bd1-8eee-563f-a6ae-8823404898a5",
                "text": "Keeping fit and healthy"
            },
            {
                "__typename": "Option",
                "id": "4ca41a8f-b524-5ef7-af86-7ef9279a7969",
                "text": "Finances"
            },
            {
                "__typename": "Option",
                "id": "2f79dc47-8e9b-5b4a-a923-e43cde4b52ce",
                "text": "Other aspects of my life not mentioned above"
            },
            {
                "__typename": "Option",
                "id": "fd920af2-9846-4037-9b45-b08ba07accaf",
                "text": "No areas of my life are affected by my worries or anxieties",
                "exclusive": true
            }
        ],
        "id": "GAD-19a",
        "text": "Which areas of your life, if any, do you feel are disrupted by your worries or anxieties? Please select all that apply.",
        "isMultiselect": true
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "647a8e7d-8b9b-583a-9cd3-30c3a4cc485c",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "4196a0b8-4e73-5d21-903f-594531ff22f0",
                "text": "To a slight extent",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "a0520f66-594b-5c56-bebc-a0d018d139d9",
                "text": "To a moderate extent",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "78df6de1-fe3e-5bed-a63d-f5f9877809af",
                "text": "To a significant extent",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "1341da8c-ffc8-5e54-9f02-4b2126601994",
                "text": "To an extreme extent",
                "value": 1
            }
        ],
        "id": "GAD-19.0a",
        "text": "To what extent do your worries or anxiety affect these areas of your life, or require you to make additional effort to maintain functioning in those areas?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "8b112b00-59f5-5465-a3c7-d3eed9586abf",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "09fba5ed-bf0e-5aa9-92a5-50c6a01ae0e2",
                "text": "Yes",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "564b4697-78ea-5000-bb19-e1ed63265906",
                "text": "I'm not sure",
                "value": 1
            }
        ],
        "id": "GAD-22a",
        "text": "In reference to your current period of worrying a lot of the time:\n\nHave you ever had a similar period of feeling worried or anxious before?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "0734fdf7-22e5-4494-98d8-f2adb89ffacb",
                "text": "Continue",
                "value": 1
            }
        ],
        "id": "GAD-END",
        "text": "You are making good progress through this question session. The next questions are about any anxiety or worries you may have about social situations.",
        "isMultiselect": false
    }
];
exports.aspects = {
    "gad_ce": {
        "type": "input",
        "signal": {
            "type": "AspectReference",
            "aspectName": "has_gad"
        }
    },
    "has_gad": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "gad_A_excessive_worry_duration"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "gad_B_worry_control"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "gad_C_symptoms"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "gad_D_distress_impairment"
                }
            }
        ],
        "anyToAll": 1
    },
    "gad_A_excessive_worry_duration": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "gad_excessive_anxiety"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "gad_6month_duration"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "gad_4day_week_duration"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "gad_number_activities"
                }
            }
        ],
        "anyToAll": 1
    },
    "gad_B_worry_control": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "gad_stop_worrying"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "gad_riddable_thoughts"
                }
            }
        ],
        "anyToAll": 0.5
    },
    "gad_C_symptoms": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "gad_restless_keyedup"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "gad_fatigued"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "gad_concentration"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "gad_irritability"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "gad_muscle_tension"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "gad_sleep_disturbance"
                }
            }
        ],
        "anyToAll": 0.5
    },
    "gad_D_distress_impairment": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "gad_distress"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "gad_functional_impairment"
                }
            }
        ],
        "anyToAll": 0.5
    },
    "gad_excessive_anxiety": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "GAD-s1",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "eaaa8028-c116-5ae3-bb59-5795820feca8": 0.0066928509242848554,
                            "0a2185e8-e4b7-5f48-a3ff-2fa717d647dd": 0.07585818002124355,
                            "24032a9e-f5b6-5f43-8ddf-305888669ad1": 0.5,
                            "f8d49124-01dc-5d55-add9-5ed87d998e00": 0.9241418199787566,
                            "c819bfe0-f79d-5132-8b3a-8604c0c5b83a": 0.9933071490757153
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "GAD-4a",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "b3612239-fed1-5631-aba2-be981c6cd68c": 0.03557118927263617,
                            "748f4a0b-00a7-5b15-b390-2dfb8ac9eb1d": 0.5,
                            "517a9806-ffe8-52a9-86df-e947a47e169c": 0.9644288107273639,
                            "84762e8e-edca-5c2c-a00f-fed69c13b87c": 0.9987706013787226
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.5
    },
    "gad_6month_duration": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "GAD-s3a",
            "options": {
                "type": "severities",
                "severities": {
                    "75ea5311-003b-57dc-8ca5-e08f759cdf02": 0.0024726231566347743,
                    "617c53a6-a5af-5b57-a7dc-1fc4fa175844": 0.017986209962091566,
                    "4bbc59ac-1581-5084-9e92-2b746637ff52": 0.1192029220221176,
                    "ed0ad572-166e-587e-b191-2cbe81387f5a": 0.5,
                    "adab9f0b-f6e7-54c4-9cf1-087cdf831412": 0.8807970779778825,
                    "1943ebd6-08ab-5b8e-a57b-7e397d303526": 0.9820137900379085
                }
            }
        }
    },
    "gad_4day_week_duration": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "GAD-s6a",
            "options": {
                "type": "severities",
                "severities": {
                    "9814dd2a-4c6e-579b-8cc1-c90b8284cb73": 0.001358519950428958,
                    "4059602c-013b-5f54-890f-64e55583f3dc": 0.03557118927263617,
                    "6e2595a2-ea1e-5ca5-ab11-c65eb6cb6157": 0.5,
                    "cc1895bc-b31f-503e-a147-172d1a0faa64": 0.9677045353015494
                }
            }
        }
    },
    "gad_number_activities": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "GAD-s4a",
            "options": {
                "type": "severities",
                "severities": {
                    "276f0539-3b02-5717-a333-3d5ac219a5b4": 0.07585818002124355,
                    "0caeb854-db8e-5a20-a344-0c9282f65f78": 0.5,
                    "5a1c5af4-b628-5554-bf63-4ca68ef2b328": 0.9241418199787566,
                    "9d0741b6-d8b6-5216-90d0-df6743af1aa0": 0.9933071490757153,
                    "8d25005e-a051-5bfc-afc2-9192701b043f": 0.9994472213630764
                }
            }
        }
    },
    "gad_stop_worrying": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "GAD-6a",
            "options": {
                "type": "severities",
                "severities": {
                    "881a3a3c-3ac2-51ae-a640-e68221f14a08": 0.0005527786369235996,
                    "7d60ebb2-5f2d-5ab0-b9ca-31210502ece9": 0.0066928509242848554,
                    "81a08016-21d5-5f6e-b174-710e540e00c1": 0.07585818002124355,
                    "f26a3508-c284-53a5-8bc7-63514fbe6737": 0.5,
                    "8996813a-e012-57cb-8eab-b6926c27eba2": 0.9241418199787566
                }
            }
        }
    },
    "gad_riddable_thoughts": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "GAD-7a",
            "options": {
                "type": "severities",
                "severities": {
                    "e38f6e4b-496e-5d17-a784-9c123e04aeba": 0.0005527786369235996,
                    "8dc41a6e-72d4-5c1f-a416-f085995a1878": 0.0066928509242848554,
                    "3b9e5247-4c0f-5c9d-9ffb-822ab95bee8a": 0.07585818002124355,
                    "d7d05c32-44cb-59c7-84b0-95e98fde6077": 0.5,
                    "80dffd87-d3ca-5977-9aee-b90eebcc4eb1": 0.9241418199787566
                }
            }
        }
    },
    "gad_restless_keyedup": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "GAD-15a",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "3283b608-28d7-5390-af36-f103b5ead7ce": 0.0005527786369235996,
                            "dfe08faa-d933-5ebc-b3dd-20cc46202224": 0.0066928509242848554,
                            "632e07d1-df69-590c-a8f2-0b4f7ad343e1": 0.07585818002124355,
                            "6447e557-156f-59a1-9cbc-cd9d4b9bcbee": 0.5,
                            "39f33fe3-3b5e-551a-8902-6a8c7ab4c148": 0.9241418199787566
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "GAD-8a",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "be94d500-cc6a-5a66-9f35-96ebe11414c3": 0.0024726231566347743,
                            "80887b2c-7c9f-5105-a64a-b3bb33442ac6": 0.017986209962091566,
                            "e1dd4f50-28b4-51eb-8c34-bf8d3145816f": 0.1192029220221176,
                            "985dc1a3-7ea9-5f5c-8af3-6159ac9c90bf": 0.5,
                            "a7ecd81c-cd6a-5592-892f-8e391b9a115c": 0.8807970779778825,
                            "ce522c31-e5ef-51e2-8fa5-d9826566ed1e": 0.9820137900379085
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.5
    },
    "gad_fatigued": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "GAD-12a",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "89cda9bd-e311-53fa-95c1-1adab2202633": 0.03557118927263617,
                            "4fb31d91-3520-5676-90c0-d3c05e9abfb8": 0.5,
                            "c74d8b74-1128-5f73-bfd2-2c3813bfdd4e": 0.9644288107273639,
                            "75847021-68a5-5e54-938f-ebae91570246": 0.9987706013787226
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "GAD-17a",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "29cecca9-7507-5ebd-b392-acfda0236820": 0.0066928509242848554,
                            "f468532b-0dfb-546a-87bc-4840ffcab3e0": 0.07585818002124355,
                            "ca6c7d1d-3395-58b8-a87d-95fba13b4ca3": 0.5,
                            "93c614a5-158b-54bb-b179-2d356411ab81": 0.9241418199787566,
                            "85731e72-1516-5c0b-8201-d7479523cbcd": 0.9933071490757153
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.5
    },
    "gad_concentration": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "GAD-13a",
            "options": {
                "type": "severities",
                "severities": {
                    "04216753-61d0-5b51-b49a-4ac56b8a54dd": 0.0066928509242848554,
                    "42da2d35-01a4-50f9-95e5-c7bbc4c8eda7": 0.07585818002124355,
                    "8dda5767-c89f-5932-808d-a7729c3f935b": 0.5,
                    "40abb98e-db00-5505-85be-59a5dd4ef092": 0.9241418199787566,
                    "15d0ef6b-b98e-5156-9b9b-c46a9dc15ed7": 0.9933071490757153
                }
            }
        }
    },
    "gad_irritability": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "GAD-14a",
            "options": {
                "type": "severities",
                "severities": {
                    "8f4ab7fd-f018-5862-af97-d98aa222664a": 0.0005527786369235996,
                    "6ac69add-b72f-5784-84a9-a517721463e6": 0.0066928509242848554,
                    "b0335c5e-d91f-5e19-9a45-4936bd9e3bea": 0.07585818002124355,
                    "0623617f-3483-5ad7-ba3a-dd6b95c269d5": 0.5,
                    "e3ffd1b0-96fc-5dc0-8628-99e770d220ff": 0.9241418199787566
                }
            }
        }
    },
    "gad_muscle_tension": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "GAD-9a",
            "options": {
                "type": "severities",
                "severities": {
                    "4e00dba8-beca-5357-8963-91dec200f76b": 0.0024726231566347743,
                    "35150196-067f-58af-b669-6e41443a3601": 0.017986209962091566,
                    "03b91bf0-49f5-5760-ae70-8e92ca65aa0e": 0.1192029220221176,
                    "b7c86f29-540d-56d7-a59a-7035d91396d8": 0.5,
                    "cbcfc13e-979a-5e51-8698-f4d686fb5c09": 0.8807970779778825,
                    "b41e2b30-b377-5738-9ebe-8b9a7b6c35b7": 0.9820137900379085
                }
            }
        }
    },
    "gad_muscle_aches": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "GAD-10a",
            "options": {
                "type": "severities",
                "severities": {
                    "a30b13cd-454a-529d-a598-c5192a29668e": 0.0024726231566347743,
                    "44ddd9b1-23aa-570e-bbce-1bef374fe68a": 0.017986209962091566,
                    "e16d33eb-317e-55aa-90fc-147bde51e99b": 0.1192029220221176,
                    "e36ed8e3-ca0e-5d7e-826f-84c47842e71c": 0.5,
                    "0988ae4f-c9f2-5d44-a2cc-ecb83a2ec620": 0.8807970779778825,
                    "1632140d-fefe-50b3-b11f-3ae261143e25": 0.9820137900379085
                }
            }
        }
    },
    "gad_sleep_disturbance": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "GAD-11a",
            "options": {
                "type": "severities",
                "severities": {
                    "8ce6bca8-7cea-5cae-b31f-9b03eb4f7322": 0.0024726231566347743,
                    "68c692fb-cc63-5ad6-b88e-5e45ad971c82": 0.017986209962091566,
                    "c1926d2c-b894-5ae1-bbdd-f8c9954ce55a": 0.1192029220221176,
                    "ba121413-fa3c-5d39-960f-827d7b0d55b8": 0.5,
                    "62411dbb-3d28-57dc-ade4-d19a340fc6ed": 0.8807970779778825,
                    "5779c477-4f6c-533d-a15b-33f106f84348": 0.9820137900379085
                }
            }
        }
    },
    "gad_distress": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "GAD-18a",
            "options": {
                "type": "severities",
                "severities": {
                    "cebd5d36-2f4f-5d6c-a864-ee09cf1af699": 0.0066928509242848554,
                    "7cb98422-b8c9-5f92-aee6-74cf0192ea01": 0.07585818002124355,
                    "63b22d31-0933-571a-9c46-eb63c6373884": 0.5,
                    "af347157-5a07-5187-b207-b672ecf979dc": 0.9241418199787566,
                    "7035a10f-7dd7-55bd-946c-2cc73ffa5e6e": 0.9933071490757153
                }
            }
        }
    },
    "gad_functional_impairment": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "GAD19a_basic"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "GAD19a_work"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "GAD19a_relationships"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "GAD19a_social"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "GAD19a_family"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "GAD20a_leisure"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "GAD20a_health"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "GAD20a_financial"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "GAD20a_other"
                        }
                    }
                ],
                "anyToAll": 0.2222222222222222
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "GAD-19.0a",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "647a8e7d-8b9b-583a-9cd3-30c3a4cc485c": 0.0005527786369235996,
                            "4196a0b8-4e73-5d21-903f-594531ff22f0": 0.0066928509242848554,
                            "a0520f66-594b-5c56-bebc-a0d018d139d9": 0.07585818002124355,
                            "78df6de1-fe3e-5bed-a63d-f5f9877809af": 0.5,
                            "1341da8c-ffc8-5e54-9f02-4b2126601994": 0.9241418199787566
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.5
    },
    "GAD19a_basic": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "GAD-19a",
            "options": {
                "type": "severities",
                "severities": {
                    "51801ae8-62d1-5a94-b757-a0b0851ac6bc": 1
                }
            }
        }
    },
    "GAD19a_work": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "GAD-19a",
            "options": {
                "type": "severities",
                "severities": {
                    "a7dc1041-6095-5630-9480-ed7c33997997": 1
                }
            }
        }
    },
    "GAD19a_relationships": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "GAD-19a",
            "options": {
                "type": "severities",
                "severities": {
                    "3c648d60-89ec-5e44-9bf1-dc362139d849": 1
                }
            }
        }
    },
    "GAD19a_social": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "GAD-19a",
            "options": {
                "type": "severities",
                "severities": {
                    "863da48d-f2eb-52f4-8c4e-feda60cda5c4": 1
                }
            }
        }
    },
    "GAD19a_family": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "GAD-19a",
            "options": {
                "type": "severities",
                "severities": {
                    "1acf1647-150f-5031-9599-8db8e23c423d": 1
                }
            }
        }
    },
    "GAD20a_leisure": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "GAD-19a",
            "options": {
                "type": "severities",
                "severities": {
                    "67df9ae6-c783-5f09-9975-a117ab653011": 1
                }
            }
        }
    },
    "GAD20a_health": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "GAD-19a",
            "options": {
                "type": "severities",
                "severities": {
                    "0a747bd1-8eee-563f-a6ae-8823404898a5": 1
                }
            }
        }
    },
    "GAD20a_financial": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "GAD-19a",
            "options": {
                "type": "severities",
                "severities": {
                    "4ca41a8f-b524-5ef7-af86-7ef9279a7969": 1
                }
            }
        }
    },
    "GAD20a_other": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "GAD-19a",
            "options": {
                "type": "severities",
                "severities": {
                    "2f79dc47-8e9b-5b4a-a923-e43cde4b52ce": 1
                }
            }
        }
    },
    "GAD19a_none": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "GAD-19a",
            "options": {
                "type": "severities",
                "severities": {
                    "fd920af2-9846-4037-9b45-b08ba07accaf": 1
                }
            }
        }
    },
    "not(GAD-s1=0)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "GAD-s1",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "eaaa8028-c116-5ae3-bb59-5795820feca8": true,
                        "0a2185e8-e4b7-5f48-a3ff-2fa717d647dd": false,
                        "24032a9e-f5b6-5f43-8ddf-305888669ad1": false,
                        "f8d49124-01dc-5d55-add9-5ed87d998e00": false,
                        "c819bfe0-f79d-5132-8b3a-8604c0c5b83a": false
                    }
                }
            }
        }
    },
    "and[or[isUndefined(GAD19a_none=1), not(GAD19a_none=1)], not(isUndefined(GAD-19a=0))]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "GAD19a_none"
                            }
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "GAD19a_none"
                            }
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "not",
                "wire": {
                    "type": "isUnknown",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "GAD-19a",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "51801ae8-62d1-5a94-b757-a0b0851ac6bc": false,
                                    "a7dc1041-6095-5630-9480-ed7c33997997": false,
                                    "3c648d60-89ec-5e44-9bf1-dc362139d849": false,
                                    "863da48d-f2eb-52f4-8c4e-feda60cda5c4": false,
                                    "1acf1647-150f-5031-9599-8db8e23c423d": false,
                                    "67df9ae6-c783-5f09-9975-a117ab653011": false,
                                    "0a747bd1-8eee-563f-a6ae-8823404898a5": false,
                                    "4ca41a8f-b524-5ef7-af86-7ef9279a7969": false,
                                    "2f79dc47-8e9b-5b4a-a923-e43cde4b52ce": false,
                                    "fd920af2-9846-4037-9b45-b08ba07accaf": false
                                }
                            }
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    }
};
exports.tests = {
    "gad_ce": [
        {
            "name": "Clinical Diagnosis user: N0N4J3Q aspect: gad_ce ",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "GAD-10a": {
                    "questionId": "GAD-10a",
                    "chosenOptionIds": [
                        "1632140d-fefe-50b3-b11f-3ae261143e25"
                    ]
                },
                "GAD-11a": {
                    "questionId": "GAD-11a",
                    "chosenOptionIds": [
                        "5779c477-4f6c-533d-a15b-33f106f84348"
                    ]
                },
                "GAD-12a": {
                    "questionId": "GAD-12a",
                    "chosenOptionIds": [
                        "c74d8b74-1128-5f73-bfd2-2c3813bfdd4e"
                    ]
                },
                "GAD-13a": {
                    "questionId": "GAD-13a",
                    "chosenOptionIds": [
                        "40abb98e-db00-5505-85be-59a5dd4ef092"
                    ]
                },
                "GAD-14a": {
                    "questionId": "GAD-14a",
                    "chosenOptionIds": [
                        "0623617f-3483-5ad7-ba3a-dd6b95c269d5"
                    ]
                },
                "GAD-15a": {
                    "questionId": "GAD-15a",
                    "chosenOptionIds": [
                        "39f33fe3-3b5e-551a-8902-6a8c7ab4c148"
                    ]
                },
                "GAD-17a": {
                    "questionId": "GAD-17a",
                    "chosenOptionIds": [
                        "93c614a5-158b-54bb-b179-2d356411ab81"
                    ]
                },
                "GAD-18a": {
                    "questionId": "GAD-18a",
                    "chosenOptionIds": [
                        "7035a10f-7dd7-55bd-946c-2cc73ffa5e6e"
                    ]
                },
                "GAD-19.0a": {
                    "questionId": "GAD-19.0a",
                    "chosenOptionIds": [
                        "78df6de1-fe3e-5bed-a63d-f5f9877809af"
                    ]
                },
                "GAD-19a": {
                    "questionId": "GAD-19a",
                    "chosenOptionIds": [
                        "51801ae8-62d1-5a94-b757-a0b0851ac6bc",
                        "863da48d-f2eb-52f4-8c4e-feda60cda5c4",
                        "a7dc1041-6095-5630-9480-ed7c33997997",
                        "1acf1647-150f-5031-9599-8db8e23c423d",
                        "67df9ae6-c783-5f09-9975-a117ab653011",
                        "0a747bd1-8eee-563f-a6ae-8823404898a5"
                    ]
                },
                "GAD-22a": {
                    "questionId": "GAD-22a",
                    "chosenOptionIds": [
                        "564b4697-78ea-5000-bb19-e1ed63265906"
                    ]
                },
                "GAD-3a": {
                    "questionId": "GAD-3a",
                    "chosenOptionIds": [
                        "bdba5003-2589-507d-bb90-53534cc9b942"
                    ]
                },
                "GAD-4a": {
                    "questionId": "GAD-4a",
                    "chosenOptionIds": [
                        "84762e8e-edca-5c2c-a00f-fed69c13b87c"
                    ]
                },
                "GAD-5a": {
                    "questionId": "GAD-5a",
                    "chosenOptionIds": [
                        "59b5a96e-b0f6-5fd3-8c20-57c053f14b06"
                    ]
                },
                "GAD-6a": {
                    "questionId": "GAD-6a",
                    "chosenOptionIds": [
                        "8996813a-e012-57cb-8eab-b6926c27eba2"
                    ]
                },
                "GAD-7a": {
                    "questionId": "GAD-7a",
                    "chosenOptionIds": [
                        "80dffd87-d3ca-5977-9aee-b90eebcc4eb1"
                    ]
                },
                "GAD-8a": {
                    "questionId": "GAD-8a",
                    "chosenOptionIds": [
                        "ce522c31-e5ef-51e2-8fa5-d9826566ed1e"
                    ]
                },
                "GAD-9a": {
                    "questionId": "GAD-9a",
                    "chosenOptionIds": [
                        "b41e2b30-b377-5738-9ebe-8b9a7b6c35b7"
                    ]
                },
                "GAD-S": {
                    "questionId": "GAD-S",
                    "chosenOptionIds": [
                        "aa668802-d458-5b2d-b48f-e4058a640295"
                    ]
                },
                "GAD-s1": {
                    "questionId": "GAD-s1",
                    "chosenOptionIds": [
                        "c819bfe0-f79d-5132-8b3a-8604c0c5b83a"
                    ]
                },
                "GAD-s3a": {
                    "questionId": "GAD-s3a",
                    "chosenOptionIds": [
                        "adab9f0b-f6e7-54c4-9cf1-087cdf831412"
                    ]
                },
                "GAD-s4a": {
                    "questionId": "GAD-s4a",
                    "chosenOptionIds": [
                        "9d0741b6-d8b6-5216-90d0-df6743af1aa0"
                    ]
                },
                "GAD-s6a": {
                    "questionId": "GAD-s6a",
                    "chosenOptionIds": [
                        "cc1895bc-b31f-503e-a147-172d1a0faa64"
                    ]
                }
            }
        },
        {
            "name": "Censeo vPrevious user: N0N4J3Q aspect: gad_ce",
            "expected": {
                "type": "CloseTo",
                "value": 0.87
            },
            "answers": {
                "GAD-10a": {
                    "questionId": "GAD-10a",
                    "chosenOptionIds": [
                        "1632140d-fefe-50b3-b11f-3ae261143e25"
                    ]
                },
                "GAD-11a": {
                    "questionId": "GAD-11a",
                    "chosenOptionIds": [
                        "5779c477-4f6c-533d-a15b-33f106f84348"
                    ]
                },
                "GAD-12a": {
                    "questionId": "GAD-12a",
                    "chosenOptionIds": [
                        "c74d8b74-1128-5f73-bfd2-2c3813bfdd4e"
                    ]
                },
                "GAD-13a": {
                    "questionId": "GAD-13a",
                    "chosenOptionIds": [
                        "40abb98e-db00-5505-85be-59a5dd4ef092"
                    ]
                },
                "GAD-14a": {
                    "questionId": "GAD-14a",
                    "chosenOptionIds": [
                        "0623617f-3483-5ad7-ba3a-dd6b95c269d5"
                    ]
                },
                "GAD-15a": {
                    "questionId": "GAD-15a",
                    "chosenOptionIds": [
                        "39f33fe3-3b5e-551a-8902-6a8c7ab4c148"
                    ]
                },
                "GAD-17a": {
                    "questionId": "GAD-17a",
                    "chosenOptionIds": [
                        "93c614a5-158b-54bb-b179-2d356411ab81"
                    ]
                },
                "GAD-18a": {
                    "questionId": "GAD-18a",
                    "chosenOptionIds": [
                        "7035a10f-7dd7-55bd-946c-2cc73ffa5e6e"
                    ]
                },
                "GAD-19.0a": {
                    "questionId": "GAD-19.0a",
                    "chosenOptionIds": [
                        "78df6de1-fe3e-5bed-a63d-f5f9877809af"
                    ]
                },
                "GAD-19a": {
                    "questionId": "GAD-19a",
                    "chosenOptionIds": [
                        "51801ae8-62d1-5a94-b757-a0b0851ac6bc",
                        "863da48d-f2eb-52f4-8c4e-feda60cda5c4",
                        "a7dc1041-6095-5630-9480-ed7c33997997",
                        "1acf1647-150f-5031-9599-8db8e23c423d",
                        "67df9ae6-c783-5f09-9975-a117ab653011",
                        "0a747bd1-8eee-563f-a6ae-8823404898a5"
                    ]
                },
                "GAD-22a": {
                    "questionId": "GAD-22a",
                    "chosenOptionIds": [
                        "564b4697-78ea-5000-bb19-e1ed63265906"
                    ]
                },
                "GAD-3a": {
                    "questionId": "GAD-3a",
                    "chosenOptionIds": [
                        "bdba5003-2589-507d-bb90-53534cc9b942"
                    ]
                },
                "GAD-4a": {
                    "questionId": "GAD-4a",
                    "chosenOptionIds": [
                        "84762e8e-edca-5c2c-a00f-fed69c13b87c"
                    ]
                },
                "GAD-5a": {
                    "questionId": "GAD-5a",
                    "chosenOptionIds": [
                        "59b5a96e-b0f6-5fd3-8c20-57c053f14b06"
                    ]
                },
                "GAD-6a": {
                    "questionId": "GAD-6a",
                    "chosenOptionIds": [
                        "8996813a-e012-57cb-8eab-b6926c27eba2"
                    ]
                },
                "GAD-7a": {
                    "questionId": "GAD-7a",
                    "chosenOptionIds": [
                        "80dffd87-d3ca-5977-9aee-b90eebcc4eb1"
                    ]
                },
                "GAD-8a": {
                    "questionId": "GAD-8a",
                    "chosenOptionIds": [
                        "ce522c31-e5ef-51e2-8fa5-d9826566ed1e"
                    ]
                },
                "GAD-9a": {
                    "questionId": "GAD-9a",
                    "chosenOptionIds": [
                        "b41e2b30-b377-5738-9ebe-8b9a7b6c35b7"
                    ]
                },
                "GAD-S": {
                    "questionId": "GAD-S",
                    "chosenOptionIds": [
                        "aa668802-d458-5b2d-b48f-e4058a640295"
                    ]
                },
                "GAD-s1": {
                    "questionId": "GAD-s1",
                    "chosenOptionIds": [
                        "c819bfe0-f79d-5132-8b3a-8604c0c5b83a"
                    ]
                },
                "GAD-s3a": {
                    "questionId": "GAD-s3a",
                    "chosenOptionIds": [
                        "adab9f0b-f6e7-54c4-9cf1-087cdf831412"
                    ]
                },
                "GAD-s4a": {
                    "questionId": "GAD-s4a",
                    "chosenOptionIds": [
                        "9d0741b6-d8b6-5216-90d0-df6743af1aa0"
                    ]
                },
                "GAD-s6a": {
                    "questionId": "GAD-s6a",
                    "chosenOptionIds": [
                        "cc1895bc-b31f-503e-a147-172d1a0faa64"
                    ]
                }
            }
        },
        {
            "name": "Clinical Diagnosis user: Y32OM08 aspect: gad_ce ",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "GAD-10a": {
                    "questionId": "GAD-10a",
                    "chosenOptionIds": [
                        "44ddd9b1-23aa-570e-bbce-1bef374fe68a"
                    ]
                },
                "GAD-11a": {
                    "questionId": "GAD-11a",
                    "chosenOptionIds": [
                        "ba121413-fa3c-5d39-960f-827d7b0d55b8"
                    ]
                },
                "GAD-12a": {
                    "questionId": "GAD-12a",
                    "chosenOptionIds": [
                        "4fb31d91-3520-5676-90c0-d3c05e9abfb8"
                    ]
                },
                "GAD-13a": {
                    "questionId": "GAD-13a",
                    "chosenOptionIds": [
                        "8dda5767-c89f-5932-808d-a7729c3f935b"
                    ]
                },
                "GAD-14a": {
                    "questionId": "GAD-14a",
                    "chosenOptionIds": [
                        "6ac69add-b72f-5784-84a9-a517721463e6"
                    ]
                },
                "GAD-15a": {
                    "questionId": "GAD-15a",
                    "chosenOptionIds": [
                        "dfe08faa-d933-5ebc-b3dd-20cc46202224"
                    ]
                },
                "GAD-17a": {
                    "questionId": "GAD-17a",
                    "chosenOptionIds": [
                        "ca6c7d1d-3395-58b8-a87d-95fba13b4ca3"
                    ]
                },
                "GAD-18a": {
                    "questionId": "GAD-18a",
                    "chosenOptionIds": [
                        "63b22d31-0933-571a-9c46-eb63c6373884"
                    ]
                },
                "GAD-19.0a": {
                    "questionId": "GAD-19.0a",
                    "chosenOptionIds": [
                        "a0520f66-594b-5c56-bebc-a0d018d139d9"
                    ]
                },
                "GAD-19a": {
                    "questionId": "GAD-19a",
                    "chosenOptionIds": [
                        "a7dc1041-6095-5630-9480-ed7c33997997",
                        "863da48d-f2eb-52f4-8c4e-feda60cda5c4",
                        "3c648d60-89ec-5e44-9bf1-dc362139d849",
                        "1acf1647-150f-5031-9599-8db8e23c423d",
                        "67df9ae6-c783-5f09-9975-a117ab653011",
                        "4ca41a8f-b524-5ef7-af86-7ef9279a7969"
                    ]
                },
                "GAD-22a": {
                    "questionId": "GAD-22a",
                    "chosenOptionIds": [
                        "09fba5ed-bf0e-5aa9-92a5-50c6a01ae0e2"
                    ]
                },
                "GAD-3a": {
                    "questionId": "GAD-3a",
                    "chosenOptionIds": [
                        "fa80af33-1ed9-5e02-abd3-85852c65204e"
                    ]
                },
                "GAD-4a": {
                    "questionId": "GAD-4a",
                    "chosenOptionIds": [
                        "517a9806-ffe8-52a9-86df-e947a47e169c"
                    ]
                },
                "GAD-5a": {
                    "questionId": "GAD-5a",
                    "chosenOptionIds": [
                        "63fdf718-12b4-5497-abff-2ae6a86b484b"
                    ]
                },
                "GAD-6a": {
                    "questionId": "GAD-6a",
                    "chosenOptionIds": [
                        "8996813a-e012-57cb-8eab-b6926c27eba2"
                    ]
                },
                "GAD-7a": {
                    "questionId": "GAD-7a",
                    "chosenOptionIds": [
                        "3b9e5247-4c0f-5c9d-9ffb-822ab95bee8a"
                    ]
                },
                "GAD-8a": {
                    "questionId": "GAD-8a",
                    "chosenOptionIds": [
                        "a7ecd81c-cd6a-5592-892f-8e391b9a115c"
                    ]
                },
                "GAD-9a": {
                    "questionId": "GAD-9a",
                    "chosenOptionIds": [
                        "b7c86f29-540d-56d7-a59a-7035d91396d8"
                    ]
                },
                "GAD-S": {
                    "questionId": "GAD-S",
                    "chosenOptionIds": [
                        "aa668802-d458-5b2d-b48f-e4058a640295"
                    ]
                },
                "GAD-s1": {
                    "questionId": "GAD-s1",
                    "chosenOptionIds": [
                        "c819bfe0-f79d-5132-8b3a-8604c0c5b83a"
                    ]
                },
                "GAD-s3a": {
                    "questionId": "GAD-s3a",
                    "chosenOptionIds": [
                        "1943ebd6-08ab-5b8e-a57b-7e397d303526"
                    ]
                },
                "GAD-s4a": {
                    "questionId": "GAD-s4a",
                    "chosenOptionIds": [
                        "9d0741b6-d8b6-5216-90d0-df6743af1aa0"
                    ]
                },
                "GAD-s6a": {
                    "questionId": "GAD-s6a",
                    "chosenOptionIds": [
                        "6e2595a2-ea1e-5ca5-ab11-c65eb6cb6157"
                    ]
                }
            }
        },
        {
            "name": "Censeo vPrevious user: Y32OM08 aspect: gad_ce",
            "expected": {
                "type": "CloseTo",
                "value": 0.79
            },
            "answers": {
                "GAD-10a": {
                    "questionId": "GAD-10a",
                    "chosenOptionIds": [
                        "44ddd9b1-23aa-570e-bbce-1bef374fe68a"
                    ]
                },
                "GAD-11a": {
                    "questionId": "GAD-11a",
                    "chosenOptionIds": [
                        "ba121413-fa3c-5d39-960f-827d7b0d55b8"
                    ]
                },
                "GAD-12a": {
                    "questionId": "GAD-12a",
                    "chosenOptionIds": [
                        "4fb31d91-3520-5676-90c0-d3c05e9abfb8"
                    ]
                },
                "GAD-13a": {
                    "questionId": "GAD-13a",
                    "chosenOptionIds": [
                        "8dda5767-c89f-5932-808d-a7729c3f935b"
                    ]
                },
                "GAD-14a": {
                    "questionId": "GAD-14a",
                    "chosenOptionIds": [
                        "6ac69add-b72f-5784-84a9-a517721463e6"
                    ]
                },
                "GAD-15a": {
                    "questionId": "GAD-15a",
                    "chosenOptionIds": [
                        "dfe08faa-d933-5ebc-b3dd-20cc46202224"
                    ]
                },
                "GAD-17a": {
                    "questionId": "GAD-17a",
                    "chosenOptionIds": [
                        "ca6c7d1d-3395-58b8-a87d-95fba13b4ca3"
                    ]
                },
                "GAD-18a": {
                    "questionId": "GAD-18a",
                    "chosenOptionIds": [
                        "63b22d31-0933-571a-9c46-eb63c6373884"
                    ]
                },
                "GAD-19.0a": {
                    "questionId": "GAD-19.0a",
                    "chosenOptionIds": [
                        "a0520f66-594b-5c56-bebc-a0d018d139d9"
                    ]
                },
                "GAD-19a": {
                    "questionId": "GAD-19a",
                    "chosenOptionIds": [
                        "a7dc1041-6095-5630-9480-ed7c33997997",
                        "863da48d-f2eb-52f4-8c4e-feda60cda5c4",
                        "3c648d60-89ec-5e44-9bf1-dc362139d849",
                        "1acf1647-150f-5031-9599-8db8e23c423d",
                        "67df9ae6-c783-5f09-9975-a117ab653011",
                        "4ca41a8f-b524-5ef7-af86-7ef9279a7969"
                    ]
                },
                "GAD-22a": {
                    "questionId": "GAD-22a",
                    "chosenOptionIds": [
                        "09fba5ed-bf0e-5aa9-92a5-50c6a01ae0e2"
                    ]
                },
                "GAD-3a": {
                    "questionId": "GAD-3a",
                    "chosenOptionIds": [
                        "fa80af33-1ed9-5e02-abd3-85852c65204e"
                    ]
                },
                "GAD-4a": {
                    "questionId": "GAD-4a",
                    "chosenOptionIds": [
                        "517a9806-ffe8-52a9-86df-e947a47e169c"
                    ]
                },
                "GAD-5a": {
                    "questionId": "GAD-5a",
                    "chosenOptionIds": [
                        "63fdf718-12b4-5497-abff-2ae6a86b484b"
                    ]
                },
                "GAD-6a": {
                    "questionId": "GAD-6a",
                    "chosenOptionIds": [
                        "8996813a-e012-57cb-8eab-b6926c27eba2"
                    ]
                },
                "GAD-7a": {
                    "questionId": "GAD-7a",
                    "chosenOptionIds": [
                        "3b9e5247-4c0f-5c9d-9ffb-822ab95bee8a"
                    ]
                },
                "GAD-8a": {
                    "questionId": "GAD-8a",
                    "chosenOptionIds": [
                        "a7ecd81c-cd6a-5592-892f-8e391b9a115c"
                    ]
                },
                "GAD-9a": {
                    "questionId": "GAD-9a",
                    "chosenOptionIds": [
                        "b7c86f29-540d-56d7-a59a-7035d91396d8"
                    ]
                },
                "GAD-S": {
                    "questionId": "GAD-S",
                    "chosenOptionIds": [
                        "aa668802-d458-5b2d-b48f-e4058a640295"
                    ]
                },
                "GAD-s1": {
                    "questionId": "GAD-s1",
                    "chosenOptionIds": [
                        "c819bfe0-f79d-5132-8b3a-8604c0c5b83a"
                    ]
                },
                "GAD-s3a": {
                    "questionId": "GAD-s3a",
                    "chosenOptionIds": [
                        "1943ebd6-08ab-5b8e-a57b-7e397d303526"
                    ]
                },
                "GAD-s4a": {
                    "questionId": "GAD-s4a",
                    "chosenOptionIds": [
                        "9d0741b6-d8b6-5216-90d0-df6743af1aa0"
                    ]
                },
                "GAD-s6a": {
                    "questionId": "GAD-s6a",
                    "chosenOptionIds": [
                        "6e2595a2-ea1e-5ca5-ab11-c65eb6cb6157"
                    ]
                }
            }
        },
        {
            "name": "Clinical Diagnosis user: K392M0L aspect: gad_ce ",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "GAD-10a": {
                    "questionId": "GAD-10a",
                    "chosenOptionIds": [
                        "0988ae4f-c9f2-5d44-a2cc-ecb83a2ec620"
                    ]
                },
                "GAD-11a": {
                    "questionId": "GAD-11a",
                    "chosenOptionIds": [
                        "5779c477-4f6c-533d-a15b-33f106f84348"
                    ]
                },
                "GAD-12a": {
                    "questionId": "GAD-12a",
                    "chosenOptionIds": [
                        "c74d8b74-1128-5f73-bfd2-2c3813bfdd4e"
                    ]
                },
                "GAD-13a": {
                    "questionId": "GAD-13a",
                    "chosenOptionIds": [
                        "8dda5767-c89f-5932-808d-a7729c3f935b"
                    ]
                },
                "GAD-14a": {
                    "questionId": "GAD-14a",
                    "chosenOptionIds": [
                        "0623617f-3483-5ad7-ba3a-dd6b95c269d5"
                    ]
                },
                "GAD-15a": {
                    "questionId": "GAD-15a",
                    "chosenOptionIds": [
                        "6447e557-156f-59a1-9cbc-cd9d4b9bcbee"
                    ]
                },
                "GAD-17a": {
                    "questionId": "GAD-17a",
                    "chosenOptionIds": [
                        "93c614a5-158b-54bb-b179-2d356411ab81"
                    ]
                },
                "GAD-18a": {
                    "questionId": "GAD-18a",
                    "chosenOptionIds": [
                        "af347157-5a07-5187-b207-b672ecf979dc"
                    ]
                },
                "GAD-19.0a": {
                    "questionId": "GAD-19.0a",
                    "chosenOptionIds": [
                        "78df6de1-fe3e-5bed-a63d-f5f9877809af"
                    ]
                },
                "GAD-19a": {
                    "questionId": "GAD-19a",
                    "chosenOptionIds": [
                        "a7dc1041-6095-5630-9480-ed7c33997997",
                        "3c648d60-89ec-5e44-9bf1-dc362139d849",
                        "863da48d-f2eb-52f4-8c4e-feda60cda5c4",
                        "1acf1647-150f-5031-9599-8db8e23c423d",
                        "4ca41a8f-b524-5ef7-af86-7ef9279a7969"
                    ]
                },
                "GAD-22a": {
                    "questionId": "GAD-22a",
                    "chosenOptionIds": [
                        "09fba5ed-bf0e-5aa9-92a5-50c6a01ae0e2"
                    ]
                },
                "GAD-3a": {
                    "questionId": "GAD-3a",
                    "chosenOptionIds": [
                        "bdba5003-2589-507d-bb90-53534cc9b942"
                    ]
                },
                "GAD-4a": {
                    "questionId": "GAD-4a",
                    "chosenOptionIds": [
                        "84762e8e-edca-5c2c-a00f-fed69c13b87c"
                    ]
                },
                "GAD-5a": {
                    "questionId": "GAD-5a",
                    "chosenOptionIds": [
                        "59b5a96e-b0f6-5fd3-8c20-57c053f14b06"
                    ]
                },
                "GAD-6a": {
                    "questionId": "GAD-6a",
                    "chosenOptionIds": [
                        "8996813a-e012-57cb-8eab-b6926c27eba2"
                    ]
                },
                "GAD-7a": {
                    "questionId": "GAD-7a",
                    "chosenOptionIds": [
                        "80dffd87-d3ca-5977-9aee-b90eebcc4eb1"
                    ]
                },
                "GAD-8a": {
                    "questionId": "GAD-8a",
                    "chosenOptionIds": [
                        "ce522c31-e5ef-51e2-8fa5-d9826566ed1e"
                    ]
                },
                "GAD-9a": {
                    "questionId": "GAD-9a",
                    "chosenOptionIds": [
                        "b41e2b30-b377-5738-9ebe-8b9a7b6c35b7"
                    ]
                },
                "GAD-S": {
                    "questionId": "GAD-S",
                    "chosenOptionIds": [
                        "aa668802-d458-5b2d-b48f-e4058a640295"
                    ]
                },
                "GAD-s1": {
                    "questionId": "GAD-s1",
                    "chosenOptionIds": [
                        "c819bfe0-f79d-5132-8b3a-8604c0c5b83a"
                    ]
                },
                "GAD-s3a": {
                    "questionId": "GAD-s3a",
                    "chosenOptionIds": [
                        "ed0ad572-166e-587e-b191-2cbe81387f5a"
                    ]
                },
                "GAD-s4a": {
                    "questionId": "GAD-s4a",
                    "chosenOptionIds": [
                        "9d0741b6-d8b6-5216-90d0-df6743af1aa0"
                    ]
                },
                "GAD-s6a": {
                    "questionId": "GAD-s6a",
                    "chosenOptionIds": [
                        "6e2595a2-ea1e-5ca5-ab11-c65eb6cb6157"
                    ]
                }
            }
        },
        {
            "name": "Censeo vPrevious user: K392M0L aspect: gad_ce",
            "expected": {
                "type": "CloseTo",
                "value": 0.79
            },
            "answers": {
                "GAD-10a": {
                    "questionId": "GAD-10a",
                    "chosenOptionIds": [
                        "0988ae4f-c9f2-5d44-a2cc-ecb83a2ec620"
                    ]
                },
                "GAD-11a": {
                    "questionId": "GAD-11a",
                    "chosenOptionIds": [
                        "5779c477-4f6c-533d-a15b-33f106f84348"
                    ]
                },
                "GAD-12a": {
                    "questionId": "GAD-12a",
                    "chosenOptionIds": [
                        "c74d8b74-1128-5f73-bfd2-2c3813bfdd4e"
                    ]
                },
                "GAD-13a": {
                    "questionId": "GAD-13a",
                    "chosenOptionIds": [
                        "8dda5767-c89f-5932-808d-a7729c3f935b"
                    ]
                },
                "GAD-14a": {
                    "questionId": "GAD-14a",
                    "chosenOptionIds": [
                        "0623617f-3483-5ad7-ba3a-dd6b95c269d5"
                    ]
                },
                "GAD-15a": {
                    "questionId": "GAD-15a",
                    "chosenOptionIds": [
                        "6447e557-156f-59a1-9cbc-cd9d4b9bcbee"
                    ]
                },
                "GAD-17a": {
                    "questionId": "GAD-17a",
                    "chosenOptionIds": [
                        "93c614a5-158b-54bb-b179-2d356411ab81"
                    ]
                },
                "GAD-18a": {
                    "questionId": "GAD-18a",
                    "chosenOptionIds": [
                        "af347157-5a07-5187-b207-b672ecf979dc"
                    ]
                },
                "GAD-19.0a": {
                    "questionId": "GAD-19.0a",
                    "chosenOptionIds": [
                        "78df6de1-fe3e-5bed-a63d-f5f9877809af"
                    ]
                },
                "GAD-19a": {
                    "questionId": "GAD-19a",
                    "chosenOptionIds": [
                        "a7dc1041-6095-5630-9480-ed7c33997997",
                        "3c648d60-89ec-5e44-9bf1-dc362139d849",
                        "863da48d-f2eb-52f4-8c4e-feda60cda5c4",
                        "1acf1647-150f-5031-9599-8db8e23c423d",
                        "4ca41a8f-b524-5ef7-af86-7ef9279a7969"
                    ]
                },
                "GAD-22a": {
                    "questionId": "GAD-22a",
                    "chosenOptionIds": [
                        "09fba5ed-bf0e-5aa9-92a5-50c6a01ae0e2"
                    ]
                },
                "GAD-3a": {
                    "questionId": "GAD-3a",
                    "chosenOptionIds": [
                        "bdba5003-2589-507d-bb90-53534cc9b942"
                    ]
                },
                "GAD-4a": {
                    "questionId": "GAD-4a",
                    "chosenOptionIds": [
                        "84762e8e-edca-5c2c-a00f-fed69c13b87c"
                    ]
                },
                "GAD-5a": {
                    "questionId": "GAD-5a",
                    "chosenOptionIds": [
                        "59b5a96e-b0f6-5fd3-8c20-57c053f14b06"
                    ]
                },
                "GAD-6a": {
                    "questionId": "GAD-6a",
                    "chosenOptionIds": [
                        "8996813a-e012-57cb-8eab-b6926c27eba2"
                    ]
                },
                "GAD-7a": {
                    "questionId": "GAD-7a",
                    "chosenOptionIds": [
                        "80dffd87-d3ca-5977-9aee-b90eebcc4eb1"
                    ]
                },
                "GAD-8a": {
                    "questionId": "GAD-8a",
                    "chosenOptionIds": [
                        "ce522c31-e5ef-51e2-8fa5-d9826566ed1e"
                    ]
                },
                "GAD-9a": {
                    "questionId": "GAD-9a",
                    "chosenOptionIds": [
                        "b41e2b30-b377-5738-9ebe-8b9a7b6c35b7"
                    ]
                },
                "GAD-S": {
                    "questionId": "GAD-S",
                    "chosenOptionIds": [
                        "aa668802-d458-5b2d-b48f-e4058a640295"
                    ]
                },
                "GAD-s1": {
                    "questionId": "GAD-s1",
                    "chosenOptionIds": [
                        "c819bfe0-f79d-5132-8b3a-8604c0c5b83a"
                    ]
                },
                "GAD-s3a": {
                    "questionId": "GAD-s3a",
                    "chosenOptionIds": [
                        "ed0ad572-166e-587e-b191-2cbe81387f5a"
                    ]
                },
                "GAD-s4a": {
                    "questionId": "GAD-s4a",
                    "chosenOptionIds": [
                        "9d0741b6-d8b6-5216-90d0-df6743af1aa0"
                    ]
                },
                "GAD-s6a": {
                    "questionId": "GAD-s6a",
                    "chosenOptionIds": [
                        "6e2595a2-ea1e-5ca5-ab11-c65eb6cb6157"
                    ]
                }
            }
        },
        {
            "name": "Clinical Diagnosis user: 50MQK36 aspect: gad_ce ",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "GAD-10a": {
                    "questionId": "GAD-10a",
                    "chosenOptionIds": [
                        "0988ae4f-c9f2-5d44-a2cc-ecb83a2ec620"
                    ]
                },
                "GAD-11a": {
                    "questionId": "GAD-11a",
                    "chosenOptionIds": [
                        "62411dbb-3d28-57dc-ade4-d19a340fc6ed"
                    ]
                },
                "GAD-12a": {
                    "questionId": "GAD-12a",
                    "chosenOptionIds": [
                        "c74d8b74-1128-5f73-bfd2-2c3813bfdd4e"
                    ]
                },
                "GAD-13a": {
                    "questionId": "GAD-13a",
                    "chosenOptionIds": [
                        "8dda5767-c89f-5932-808d-a7729c3f935b"
                    ]
                },
                "GAD-14a": {
                    "questionId": "GAD-14a",
                    "chosenOptionIds": [
                        "6ac69add-b72f-5784-84a9-a517721463e6"
                    ]
                },
                "GAD-15a": {
                    "questionId": "GAD-15a",
                    "chosenOptionIds": [
                        "dfe08faa-d933-5ebc-b3dd-20cc46202224"
                    ]
                },
                "GAD-17a": {
                    "questionId": "GAD-17a",
                    "chosenOptionIds": [
                        "93c614a5-158b-54bb-b179-2d356411ab81"
                    ]
                },
                "GAD-18a": {
                    "questionId": "GAD-18a",
                    "chosenOptionIds": [
                        "af347157-5a07-5187-b207-b672ecf979dc"
                    ]
                },
                "GAD-19.0a": {
                    "questionId": "GAD-19.0a",
                    "chosenOptionIds": [
                        "a0520f66-594b-5c56-bebc-a0d018d139d9"
                    ]
                },
                "GAD-19a": {
                    "questionId": "GAD-19a",
                    "chosenOptionIds": [
                        "a7dc1041-6095-5630-9480-ed7c33997997",
                        "3c648d60-89ec-5e44-9bf1-dc362139d849",
                        "863da48d-f2eb-52f4-8c4e-feda60cda5c4",
                        "1acf1647-150f-5031-9599-8db8e23c423d",
                        "67df9ae6-c783-5f09-9975-a117ab653011",
                        "0a747bd1-8eee-563f-a6ae-8823404898a5",
                        "4ca41a8f-b524-5ef7-af86-7ef9279a7969"
                    ]
                },
                "GAD-22a": {
                    "questionId": "GAD-22a",
                    "chosenOptionIds": [
                        "09fba5ed-bf0e-5aa9-92a5-50c6a01ae0e2"
                    ]
                },
                "GAD-3a": {
                    "questionId": "GAD-3a",
                    "chosenOptionIds": [
                        "bdba5003-2589-507d-bb90-53534cc9b942"
                    ]
                },
                "GAD-4a": {
                    "questionId": "GAD-4a",
                    "chosenOptionIds": [
                        "517a9806-ffe8-52a9-86df-e947a47e169c"
                    ]
                },
                "GAD-5a": {
                    "questionId": "GAD-5a",
                    "chosenOptionIds": [
                        "bd66ed3d-4e63-5fe8-88a6-fbbf3ab55502"
                    ]
                },
                "GAD-6a": {
                    "questionId": "GAD-6a",
                    "chosenOptionIds": [
                        "8996813a-e012-57cb-8eab-b6926c27eba2"
                    ]
                },
                "GAD-7a": {
                    "questionId": "GAD-7a",
                    "chosenOptionIds": [
                        "d7d05c32-44cb-59c7-84b0-95e98fde6077"
                    ]
                },
                "GAD-8a": {
                    "questionId": "GAD-8a",
                    "chosenOptionIds": [
                        "a7ecd81c-cd6a-5592-892f-8e391b9a115c"
                    ]
                },
                "GAD-9a": {
                    "questionId": "GAD-9a",
                    "chosenOptionIds": [
                        "cbcfc13e-979a-5e51-8698-f4d686fb5c09"
                    ]
                },
                "GAD-S": {
                    "questionId": "GAD-S",
                    "chosenOptionIds": [
                        "aa668802-d458-5b2d-b48f-e4058a640295"
                    ]
                },
                "GAD-s1": {
                    "questionId": "GAD-s1",
                    "chosenOptionIds": [
                        "24032a9e-f5b6-5f43-8ddf-305888669ad1"
                    ]
                },
                "GAD-s3a": {
                    "questionId": "GAD-s3a",
                    "chosenOptionIds": [
                        "1943ebd6-08ab-5b8e-a57b-7e397d303526"
                    ]
                },
                "GAD-s4a": {
                    "questionId": "GAD-s4a",
                    "chosenOptionIds": [
                        "5a1c5af4-b628-5554-bf63-4ca68ef2b328"
                    ]
                },
                "GAD-s6a": {
                    "questionId": "GAD-s6a",
                    "chosenOptionIds": [
                        "cc1895bc-b31f-503e-a147-172d1a0faa64"
                    ]
                }
            }
        },
        {
            "name": "Censeo vPrevious user: 50MQK36 aspect: gad_ce",
            "expected": {
                "type": "CloseTo",
                "value": 0.81
            },
            "answers": {
                "GAD-10a": {
                    "questionId": "GAD-10a",
                    "chosenOptionIds": [
                        "0988ae4f-c9f2-5d44-a2cc-ecb83a2ec620"
                    ]
                },
                "GAD-11a": {
                    "questionId": "GAD-11a",
                    "chosenOptionIds": [
                        "62411dbb-3d28-57dc-ade4-d19a340fc6ed"
                    ]
                },
                "GAD-12a": {
                    "questionId": "GAD-12a",
                    "chosenOptionIds": [
                        "c74d8b74-1128-5f73-bfd2-2c3813bfdd4e"
                    ]
                },
                "GAD-13a": {
                    "questionId": "GAD-13a",
                    "chosenOptionIds": [
                        "8dda5767-c89f-5932-808d-a7729c3f935b"
                    ]
                },
                "GAD-14a": {
                    "questionId": "GAD-14a",
                    "chosenOptionIds": [
                        "6ac69add-b72f-5784-84a9-a517721463e6"
                    ]
                },
                "GAD-15a": {
                    "questionId": "GAD-15a",
                    "chosenOptionIds": [
                        "dfe08faa-d933-5ebc-b3dd-20cc46202224"
                    ]
                },
                "GAD-17a": {
                    "questionId": "GAD-17a",
                    "chosenOptionIds": [
                        "93c614a5-158b-54bb-b179-2d356411ab81"
                    ]
                },
                "GAD-18a": {
                    "questionId": "GAD-18a",
                    "chosenOptionIds": [
                        "af347157-5a07-5187-b207-b672ecf979dc"
                    ]
                },
                "GAD-19.0a": {
                    "questionId": "GAD-19.0a",
                    "chosenOptionIds": [
                        "a0520f66-594b-5c56-bebc-a0d018d139d9"
                    ]
                },
                "GAD-19a": {
                    "questionId": "GAD-19a",
                    "chosenOptionIds": [
                        "a7dc1041-6095-5630-9480-ed7c33997997",
                        "3c648d60-89ec-5e44-9bf1-dc362139d849",
                        "863da48d-f2eb-52f4-8c4e-feda60cda5c4",
                        "1acf1647-150f-5031-9599-8db8e23c423d",
                        "67df9ae6-c783-5f09-9975-a117ab653011",
                        "0a747bd1-8eee-563f-a6ae-8823404898a5",
                        "4ca41a8f-b524-5ef7-af86-7ef9279a7969"
                    ]
                },
                "GAD-22a": {
                    "questionId": "GAD-22a",
                    "chosenOptionIds": [
                        "09fba5ed-bf0e-5aa9-92a5-50c6a01ae0e2"
                    ]
                },
                "GAD-3a": {
                    "questionId": "GAD-3a",
                    "chosenOptionIds": [
                        "bdba5003-2589-507d-bb90-53534cc9b942"
                    ]
                },
                "GAD-4a": {
                    "questionId": "GAD-4a",
                    "chosenOptionIds": [
                        "517a9806-ffe8-52a9-86df-e947a47e169c"
                    ]
                },
                "GAD-5a": {
                    "questionId": "GAD-5a",
                    "chosenOptionIds": [
                        "bd66ed3d-4e63-5fe8-88a6-fbbf3ab55502"
                    ]
                },
                "GAD-6a": {
                    "questionId": "GAD-6a",
                    "chosenOptionIds": [
                        "8996813a-e012-57cb-8eab-b6926c27eba2"
                    ]
                },
                "GAD-7a": {
                    "questionId": "GAD-7a",
                    "chosenOptionIds": [
                        "d7d05c32-44cb-59c7-84b0-95e98fde6077"
                    ]
                },
                "GAD-8a": {
                    "questionId": "GAD-8a",
                    "chosenOptionIds": [
                        "a7ecd81c-cd6a-5592-892f-8e391b9a115c"
                    ]
                },
                "GAD-9a": {
                    "questionId": "GAD-9a",
                    "chosenOptionIds": [
                        "cbcfc13e-979a-5e51-8698-f4d686fb5c09"
                    ]
                },
                "GAD-S": {
                    "questionId": "GAD-S",
                    "chosenOptionIds": [
                        "aa668802-d458-5b2d-b48f-e4058a640295"
                    ]
                },
                "GAD-s1": {
                    "questionId": "GAD-s1",
                    "chosenOptionIds": [
                        "24032a9e-f5b6-5f43-8ddf-305888669ad1"
                    ]
                },
                "GAD-s3a": {
                    "questionId": "GAD-s3a",
                    "chosenOptionIds": [
                        "1943ebd6-08ab-5b8e-a57b-7e397d303526"
                    ]
                },
                "GAD-s4a": {
                    "questionId": "GAD-s4a",
                    "chosenOptionIds": [
                        "5a1c5af4-b628-5554-bf63-4ca68ef2b328"
                    ]
                },
                "GAD-s6a": {
                    "questionId": "GAD-s6a",
                    "chosenOptionIds": [
                        "cc1895bc-b31f-503e-a147-172d1a0faa64"
                    ]
                }
            }
        },
        {
            "name": "Clinical Diagnosis user: XE7DM0L aspect: gad_ce ",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "GAD-10a": {
                    "questionId": "GAD-10a",
                    "chosenOptionIds": [
                        "e36ed8e3-ca0e-5d7e-826f-84c47842e71c"
                    ]
                },
                "GAD-11a": {
                    "questionId": "GAD-11a",
                    "chosenOptionIds": [
                        "62411dbb-3d28-57dc-ade4-d19a340fc6ed"
                    ]
                },
                "GAD-12a": {
                    "questionId": "GAD-12a",
                    "chosenOptionIds": [
                        "75847021-68a5-5e54-938f-ebae91570246"
                    ]
                },
                "GAD-13a": {
                    "questionId": "GAD-13a",
                    "chosenOptionIds": [
                        "42da2d35-01a4-50f9-95e5-c7bbc4c8eda7"
                    ]
                },
                "GAD-14a": {
                    "questionId": "GAD-14a",
                    "chosenOptionIds": [
                        "0623617f-3483-5ad7-ba3a-dd6b95c269d5"
                    ]
                },
                "GAD-15a": {
                    "questionId": "GAD-15a",
                    "chosenOptionIds": [
                        "632e07d1-df69-590c-a8f2-0b4f7ad343e1"
                    ]
                },
                "GAD-17a": {
                    "questionId": "GAD-17a",
                    "chosenOptionIds": [
                        "93c614a5-158b-54bb-b179-2d356411ab81"
                    ]
                },
                "GAD-18a": {
                    "questionId": "GAD-18a",
                    "chosenOptionIds": [
                        "7035a10f-7dd7-55bd-946c-2cc73ffa5e6e"
                    ]
                },
                "GAD-19.0a": {
                    "questionId": "GAD-19.0a",
                    "chosenOptionIds": [
                        "1341da8c-ffc8-5e54-9f02-4b2126601994"
                    ]
                },
                "GAD-19a": {
                    "questionId": "GAD-19a",
                    "chosenOptionIds": [
                        "a7dc1041-6095-5630-9480-ed7c33997997",
                        "863da48d-f2eb-52f4-8c4e-feda60cda5c4",
                        "1acf1647-150f-5031-9599-8db8e23c423d",
                        "67df9ae6-c783-5f09-9975-a117ab653011",
                        "0a747bd1-8eee-563f-a6ae-8823404898a5",
                        "4ca41a8f-b524-5ef7-af86-7ef9279a7969",
                        "2f79dc47-8e9b-5b4a-a923-e43cde4b52ce",
                        "51801ae8-62d1-5a94-b757-a0b0851ac6bc"
                    ]
                },
                "GAD-22a": {
                    "questionId": "GAD-22a",
                    "chosenOptionIds": [
                        "09fba5ed-bf0e-5aa9-92a5-50c6a01ae0e2"
                    ]
                },
                "GAD-3a": {
                    "questionId": "GAD-3a",
                    "chosenOptionIds": [
                        "bdba5003-2589-507d-bb90-53534cc9b942"
                    ]
                },
                "GAD-4a": {
                    "questionId": "GAD-4a",
                    "chosenOptionIds": [
                        "b3612239-fed1-5631-aba2-be981c6cd68c"
                    ]
                },
                "GAD-5a": {
                    "questionId": "GAD-5a",
                    "chosenOptionIds": [
                        "63fdf718-12b4-5497-abff-2ae6a86b484b"
                    ]
                },
                "GAD-6a": {
                    "questionId": "GAD-6a",
                    "chosenOptionIds": [
                        "8996813a-e012-57cb-8eab-b6926c27eba2"
                    ]
                },
                "GAD-7a": {
                    "questionId": "GAD-7a",
                    "chosenOptionIds": [
                        "d7d05c32-44cb-59c7-84b0-95e98fde6077"
                    ]
                },
                "GAD-8a": {
                    "questionId": "GAD-8a",
                    "chosenOptionIds": [
                        "a7ecd81c-cd6a-5592-892f-8e391b9a115c"
                    ]
                },
                "GAD-9a": {
                    "questionId": "GAD-9a",
                    "chosenOptionIds": [
                        "b41e2b30-b377-5738-9ebe-8b9a7b6c35b7"
                    ]
                },
                "GAD-S": {
                    "questionId": "GAD-S",
                    "chosenOptionIds": [
                        "aa668802-d458-5b2d-b48f-e4058a640295"
                    ]
                },
                "GAD-s1": {
                    "questionId": "GAD-s1",
                    "chosenOptionIds": [
                        "f8d49124-01dc-5d55-add9-5ed87d998e00"
                    ]
                },
                "GAD-s3a": {
                    "questionId": "GAD-s3a",
                    "chosenOptionIds": [
                        "adab9f0b-f6e7-54c4-9cf1-087cdf831412"
                    ]
                },
                "GAD-s4a": {
                    "questionId": "GAD-s4a",
                    "chosenOptionIds": [
                        "9d0741b6-d8b6-5216-90d0-df6743af1aa0"
                    ]
                },
                "GAD-s6a": {
                    "questionId": "GAD-s6a",
                    "chosenOptionIds": [
                        "6e2595a2-ea1e-5ca5-ab11-c65eb6cb6157"
                    ]
                }
            }
        },
        {
            "name": "Censeo vPrevious user: XE7DM0L aspect: gad_ce",
            "expected": {
                "type": "CloseTo",
                "value": 0.78
            },
            "answers": {
                "GAD-10a": {
                    "questionId": "GAD-10a",
                    "chosenOptionIds": [
                        "e36ed8e3-ca0e-5d7e-826f-84c47842e71c"
                    ]
                },
                "GAD-11a": {
                    "questionId": "GAD-11a",
                    "chosenOptionIds": [
                        "62411dbb-3d28-57dc-ade4-d19a340fc6ed"
                    ]
                },
                "GAD-12a": {
                    "questionId": "GAD-12a",
                    "chosenOptionIds": [
                        "75847021-68a5-5e54-938f-ebae91570246"
                    ]
                },
                "GAD-13a": {
                    "questionId": "GAD-13a",
                    "chosenOptionIds": [
                        "42da2d35-01a4-50f9-95e5-c7bbc4c8eda7"
                    ]
                },
                "GAD-14a": {
                    "questionId": "GAD-14a",
                    "chosenOptionIds": [
                        "0623617f-3483-5ad7-ba3a-dd6b95c269d5"
                    ]
                },
                "GAD-15a": {
                    "questionId": "GAD-15a",
                    "chosenOptionIds": [
                        "632e07d1-df69-590c-a8f2-0b4f7ad343e1"
                    ]
                },
                "GAD-17a": {
                    "questionId": "GAD-17a",
                    "chosenOptionIds": [
                        "93c614a5-158b-54bb-b179-2d356411ab81"
                    ]
                },
                "GAD-18a": {
                    "questionId": "GAD-18a",
                    "chosenOptionIds": [
                        "7035a10f-7dd7-55bd-946c-2cc73ffa5e6e"
                    ]
                },
                "GAD-19.0a": {
                    "questionId": "GAD-19.0a",
                    "chosenOptionIds": [
                        "1341da8c-ffc8-5e54-9f02-4b2126601994"
                    ]
                },
                "GAD-19a": {
                    "questionId": "GAD-19a",
                    "chosenOptionIds": [
                        "a7dc1041-6095-5630-9480-ed7c33997997",
                        "863da48d-f2eb-52f4-8c4e-feda60cda5c4",
                        "1acf1647-150f-5031-9599-8db8e23c423d",
                        "67df9ae6-c783-5f09-9975-a117ab653011",
                        "0a747bd1-8eee-563f-a6ae-8823404898a5",
                        "4ca41a8f-b524-5ef7-af86-7ef9279a7969",
                        "2f79dc47-8e9b-5b4a-a923-e43cde4b52ce",
                        "51801ae8-62d1-5a94-b757-a0b0851ac6bc"
                    ]
                },
                "GAD-22a": {
                    "questionId": "GAD-22a",
                    "chosenOptionIds": [
                        "09fba5ed-bf0e-5aa9-92a5-50c6a01ae0e2"
                    ]
                },
                "GAD-3a": {
                    "questionId": "GAD-3a",
                    "chosenOptionIds": [
                        "bdba5003-2589-507d-bb90-53534cc9b942"
                    ]
                },
                "GAD-4a": {
                    "questionId": "GAD-4a",
                    "chosenOptionIds": [
                        "b3612239-fed1-5631-aba2-be981c6cd68c"
                    ]
                },
                "GAD-5a": {
                    "questionId": "GAD-5a",
                    "chosenOptionIds": [
                        "63fdf718-12b4-5497-abff-2ae6a86b484b"
                    ]
                },
                "GAD-6a": {
                    "questionId": "GAD-6a",
                    "chosenOptionIds": [
                        "8996813a-e012-57cb-8eab-b6926c27eba2"
                    ]
                },
                "GAD-7a": {
                    "questionId": "GAD-7a",
                    "chosenOptionIds": [
                        "d7d05c32-44cb-59c7-84b0-95e98fde6077"
                    ]
                },
                "GAD-8a": {
                    "questionId": "GAD-8a",
                    "chosenOptionIds": [
                        "a7ecd81c-cd6a-5592-892f-8e391b9a115c"
                    ]
                },
                "GAD-9a": {
                    "questionId": "GAD-9a",
                    "chosenOptionIds": [
                        "b41e2b30-b377-5738-9ebe-8b9a7b6c35b7"
                    ]
                },
                "GAD-S": {
                    "questionId": "GAD-S",
                    "chosenOptionIds": [
                        "aa668802-d458-5b2d-b48f-e4058a640295"
                    ]
                },
                "GAD-s1": {
                    "questionId": "GAD-s1",
                    "chosenOptionIds": [
                        "f8d49124-01dc-5d55-add9-5ed87d998e00"
                    ]
                },
                "GAD-s3a": {
                    "questionId": "GAD-s3a",
                    "chosenOptionIds": [
                        "adab9f0b-f6e7-54c4-9cf1-087cdf831412"
                    ]
                },
                "GAD-s4a": {
                    "questionId": "GAD-s4a",
                    "chosenOptionIds": [
                        "9d0741b6-d8b6-5216-90d0-df6743af1aa0"
                    ]
                },
                "GAD-s6a": {
                    "questionId": "GAD-s6a",
                    "chosenOptionIds": [
                        "6e2595a2-ea1e-5ca5-ab11-c65eb6cb6157"
                    ]
                }
            }
        },
        {
            "name": "Clinical Diagnosis user: J3J4P0Y aspect: gad_ce ",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "GAD-10a": {
                    "questionId": "GAD-10a",
                    "chosenOptionIds": [
                        "e16d33eb-317e-55aa-90fc-147bde51e99b"
                    ]
                },
                "GAD-11a": {
                    "questionId": "GAD-11a",
                    "chosenOptionIds": [
                        "68c692fb-cc63-5ad6-b88e-5e45ad971c82"
                    ]
                },
                "GAD-12a": {
                    "questionId": "GAD-12a",
                    "chosenOptionIds": [
                        "c74d8b74-1128-5f73-bfd2-2c3813bfdd4e"
                    ]
                },
                "GAD-13a": {
                    "questionId": "GAD-13a",
                    "chosenOptionIds": [
                        "42da2d35-01a4-50f9-95e5-c7bbc4c8eda7"
                    ]
                },
                "GAD-14a": {
                    "questionId": "GAD-14a",
                    "chosenOptionIds": [
                        "b0335c5e-d91f-5e19-9a45-4936bd9e3bea"
                    ]
                },
                "GAD-15a": {
                    "questionId": "GAD-15a",
                    "chosenOptionIds": [
                        "dfe08faa-d933-5ebc-b3dd-20cc46202224"
                    ]
                },
                "GAD-17a": {
                    "questionId": "GAD-17a",
                    "chosenOptionIds": [
                        "f468532b-0dfb-546a-87bc-4840ffcab3e0"
                    ]
                },
                "GAD-18a": {
                    "questionId": "GAD-18a",
                    "chosenOptionIds": [
                        "7cb98422-b8c9-5f92-aee6-74cf0192ea01"
                    ]
                },
                "GAD-19.0a": {
                    "questionId": "GAD-19.0a",
                    "chosenOptionIds": [
                        "a0520f66-594b-5c56-bebc-a0d018d139d9"
                    ]
                },
                "GAD-19a": {
                    "questionId": "GAD-19a",
                    "chosenOptionIds": [
                        "863da48d-f2eb-52f4-8c4e-feda60cda5c4",
                        "3c648d60-89ec-5e44-9bf1-dc362139d849",
                        "1acf1647-150f-5031-9599-8db8e23c423d",
                        "0a747bd1-8eee-563f-a6ae-8823404898a5",
                        "4ca41a8f-b524-5ef7-af86-7ef9279a7969",
                        "67df9ae6-c783-5f09-9975-a117ab653011",
                        "a7dc1041-6095-5630-9480-ed7c33997997"
                    ]
                },
                "GAD-22a": {
                    "questionId": "GAD-22a",
                    "chosenOptionIds": [
                        "564b4697-78ea-5000-bb19-e1ed63265906"
                    ]
                },
                "GAD-3a": {
                    "questionId": "GAD-3a",
                    "chosenOptionIds": [
                        "bdba5003-2589-507d-bb90-53534cc9b942"
                    ]
                },
                "GAD-4a": {
                    "questionId": "GAD-4a",
                    "chosenOptionIds": [
                        "84762e8e-edca-5c2c-a00f-fed69c13b87c"
                    ]
                },
                "GAD-5a": {
                    "questionId": "GAD-5a",
                    "chosenOptionIds": [
                        "63fdf718-12b4-5497-abff-2ae6a86b484b"
                    ]
                },
                "GAD-6a": {
                    "questionId": "GAD-6a",
                    "chosenOptionIds": [
                        "f26a3508-c284-53a5-8bc7-63514fbe6737"
                    ]
                },
                "GAD-7a": {
                    "questionId": "GAD-7a",
                    "chosenOptionIds": [
                        "8dc41a6e-72d4-5c1f-a416-f085995a1878"
                    ]
                },
                "GAD-8a": {
                    "questionId": "GAD-8a",
                    "chosenOptionIds": [
                        "e1dd4f50-28b4-51eb-8c34-bf8d3145816f"
                    ]
                },
                "GAD-9a": {
                    "questionId": "GAD-9a",
                    "chosenOptionIds": [
                        "03b91bf0-49f5-5760-ae70-8e92ca65aa0e"
                    ]
                },
                "GAD-S": {
                    "questionId": "GAD-S",
                    "chosenOptionIds": [
                        "aa668802-d458-5b2d-b48f-e4058a640295"
                    ]
                },
                "GAD-s1": {
                    "questionId": "GAD-s1",
                    "chosenOptionIds": [
                        "0a2185e8-e4b7-5f48-a3ff-2fa717d647dd"
                    ]
                },
                "GAD-s3a": {
                    "questionId": "GAD-s3a",
                    "chosenOptionIds": [
                        "617c53a6-a5af-5b57-a7dc-1fc4fa175844"
                    ]
                },
                "GAD-s4a": {
                    "questionId": "GAD-s4a",
                    "chosenOptionIds": [
                        "5a1c5af4-b628-5554-bf63-4ca68ef2b328"
                    ]
                },
                "GAD-s6a": {
                    "questionId": "GAD-s6a",
                    "chosenOptionIds": [
                        "4059602c-013b-5f54-890f-64e55583f3dc"
                    ]
                }
            }
        },
        {
            "name": "Censeo vPrevious user: J3J4P0Y aspect: gad_ce",
            "expected": {
                "type": "CloseTo",
                "value": 0.14
            },
            "answers": {
                "GAD-10a": {
                    "questionId": "GAD-10a",
                    "chosenOptionIds": [
                        "e16d33eb-317e-55aa-90fc-147bde51e99b"
                    ]
                },
                "GAD-11a": {
                    "questionId": "GAD-11a",
                    "chosenOptionIds": [
                        "68c692fb-cc63-5ad6-b88e-5e45ad971c82"
                    ]
                },
                "GAD-12a": {
                    "questionId": "GAD-12a",
                    "chosenOptionIds": [
                        "c74d8b74-1128-5f73-bfd2-2c3813bfdd4e"
                    ]
                },
                "GAD-13a": {
                    "questionId": "GAD-13a",
                    "chosenOptionIds": [
                        "42da2d35-01a4-50f9-95e5-c7bbc4c8eda7"
                    ]
                },
                "GAD-14a": {
                    "questionId": "GAD-14a",
                    "chosenOptionIds": [
                        "b0335c5e-d91f-5e19-9a45-4936bd9e3bea"
                    ]
                },
                "GAD-15a": {
                    "questionId": "GAD-15a",
                    "chosenOptionIds": [
                        "dfe08faa-d933-5ebc-b3dd-20cc46202224"
                    ]
                },
                "GAD-17a": {
                    "questionId": "GAD-17a",
                    "chosenOptionIds": [
                        "f468532b-0dfb-546a-87bc-4840ffcab3e0"
                    ]
                },
                "GAD-18a": {
                    "questionId": "GAD-18a",
                    "chosenOptionIds": [
                        "7cb98422-b8c9-5f92-aee6-74cf0192ea01"
                    ]
                },
                "GAD-19.0a": {
                    "questionId": "GAD-19.0a",
                    "chosenOptionIds": [
                        "a0520f66-594b-5c56-bebc-a0d018d139d9"
                    ]
                },
                "GAD-19a": {
                    "questionId": "GAD-19a",
                    "chosenOptionIds": [
                        "863da48d-f2eb-52f4-8c4e-feda60cda5c4",
                        "3c648d60-89ec-5e44-9bf1-dc362139d849",
                        "1acf1647-150f-5031-9599-8db8e23c423d",
                        "0a747bd1-8eee-563f-a6ae-8823404898a5",
                        "4ca41a8f-b524-5ef7-af86-7ef9279a7969",
                        "67df9ae6-c783-5f09-9975-a117ab653011",
                        "a7dc1041-6095-5630-9480-ed7c33997997"
                    ]
                },
                "GAD-22a": {
                    "questionId": "GAD-22a",
                    "chosenOptionIds": [
                        "564b4697-78ea-5000-bb19-e1ed63265906"
                    ]
                },
                "GAD-3a": {
                    "questionId": "GAD-3a",
                    "chosenOptionIds": [
                        "bdba5003-2589-507d-bb90-53534cc9b942"
                    ]
                },
                "GAD-4a": {
                    "questionId": "GAD-4a",
                    "chosenOptionIds": [
                        "84762e8e-edca-5c2c-a00f-fed69c13b87c"
                    ]
                },
                "GAD-5a": {
                    "questionId": "GAD-5a",
                    "chosenOptionIds": [
                        "63fdf718-12b4-5497-abff-2ae6a86b484b"
                    ]
                },
                "GAD-6a": {
                    "questionId": "GAD-6a",
                    "chosenOptionIds": [
                        "f26a3508-c284-53a5-8bc7-63514fbe6737"
                    ]
                },
                "GAD-7a": {
                    "questionId": "GAD-7a",
                    "chosenOptionIds": [
                        "8dc41a6e-72d4-5c1f-a416-f085995a1878"
                    ]
                },
                "GAD-8a": {
                    "questionId": "GAD-8a",
                    "chosenOptionIds": [
                        "e1dd4f50-28b4-51eb-8c34-bf8d3145816f"
                    ]
                },
                "GAD-9a": {
                    "questionId": "GAD-9a",
                    "chosenOptionIds": [
                        "03b91bf0-49f5-5760-ae70-8e92ca65aa0e"
                    ]
                },
                "GAD-S": {
                    "questionId": "GAD-S",
                    "chosenOptionIds": [
                        "aa668802-d458-5b2d-b48f-e4058a640295"
                    ]
                },
                "GAD-s1": {
                    "questionId": "GAD-s1",
                    "chosenOptionIds": [
                        "0a2185e8-e4b7-5f48-a3ff-2fa717d647dd"
                    ]
                },
                "GAD-s3a": {
                    "questionId": "GAD-s3a",
                    "chosenOptionIds": [
                        "617c53a6-a5af-5b57-a7dc-1fc4fa175844"
                    ]
                },
                "GAD-s4a": {
                    "questionId": "GAD-s4a",
                    "chosenOptionIds": [
                        "5a1c5af4-b628-5554-bf63-4ca68ef2b328"
                    ]
                },
                "GAD-s6a": {
                    "questionId": "GAD-s6a",
                    "chosenOptionIds": [
                        "4059602c-013b-5f54-890f-64e55583f3dc"
                    ]
                }
            }
        },
        {
            "name": "Clinical Diagnosis user: 7EW6KE8 aspect: gad_ce ",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "GAD-10a": {
                    "questionId": "GAD-10a",
                    "chosenOptionIds": [
                        "e36ed8e3-ca0e-5d7e-826f-84c47842e71c"
                    ]
                },
                "GAD-11a": {
                    "questionId": "GAD-11a",
                    "chosenOptionIds": [
                        "ba121413-fa3c-5d39-960f-827d7b0d55b8"
                    ]
                },
                "GAD-12a": {
                    "questionId": "GAD-12a",
                    "chosenOptionIds": [
                        "c74d8b74-1128-5f73-bfd2-2c3813bfdd4e"
                    ]
                },
                "GAD-13a": {
                    "questionId": "GAD-13a",
                    "chosenOptionIds": [
                        "8dda5767-c89f-5932-808d-a7729c3f935b"
                    ]
                },
                "GAD-14a": {
                    "questionId": "GAD-14a",
                    "chosenOptionIds": [
                        "0623617f-3483-5ad7-ba3a-dd6b95c269d5"
                    ]
                },
                "GAD-15a": {
                    "questionId": "GAD-15a",
                    "chosenOptionIds": [
                        "6447e557-156f-59a1-9cbc-cd9d4b9bcbee"
                    ]
                },
                "GAD-17a": {
                    "questionId": "GAD-17a",
                    "chosenOptionIds": [
                        "ca6c7d1d-3395-58b8-a87d-95fba13b4ca3"
                    ]
                },
                "GAD-18a": {
                    "questionId": "GAD-18a",
                    "chosenOptionIds": [
                        "af347157-5a07-5187-b207-b672ecf979dc"
                    ]
                },
                "GAD-19.0a": {
                    "questionId": "GAD-19.0a",
                    "chosenOptionIds": [
                        "a0520f66-594b-5c56-bebc-a0d018d139d9"
                    ]
                },
                "GAD-19a": {
                    "questionId": "GAD-19a",
                    "chosenOptionIds": [
                        "a7dc1041-6095-5630-9480-ed7c33997997",
                        "3c648d60-89ec-5e44-9bf1-dc362139d849",
                        "863da48d-f2eb-52f4-8c4e-feda60cda5c4",
                        "1acf1647-150f-5031-9599-8db8e23c423d",
                        "67df9ae6-c783-5f09-9975-a117ab653011",
                        "0a747bd1-8eee-563f-a6ae-8823404898a5"
                    ]
                },
                "GAD-22a": {
                    "questionId": "GAD-22a",
                    "chosenOptionIds": [
                        "09fba5ed-bf0e-5aa9-92a5-50c6a01ae0e2"
                    ]
                },
                "GAD-3a": {
                    "questionId": "GAD-3a",
                    "chosenOptionIds": [
                        "bdba5003-2589-507d-bb90-53534cc9b942"
                    ]
                },
                "GAD-4a": {
                    "questionId": "GAD-4a",
                    "chosenOptionIds": [
                        "84762e8e-edca-5c2c-a00f-fed69c13b87c"
                    ]
                },
                "GAD-5a": {
                    "questionId": "GAD-5a",
                    "chosenOptionIds": [
                        "bd66ed3d-4e63-5fe8-88a6-fbbf3ab55502"
                    ]
                },
                "GAD-6a": {
                    "questionId": "GAD-6a",
                    "chosenOptionIds": [
                        "f26a3508-c284-53a5-8bc7-63514fbe6737"
                    ]
                },
                "GAD-7a": {
                    "questionId": "GAD-7a",
                    "chosenOptionIds": [
                        "d7d05c32-44cb-59c7-84b0-95e98fde6077"
                    ]
                },
                "GAD-8a": {
                    "questionId": "GAD-8a",
                    "chosenOptionIds": [
                        "ce522c31-e5ef-51e2-8fa5-d9826566ed1e"
                    ]
                },
                "GAD-9a": {
                    "questionId": "GAD-9a",
                    "chosenOptionIds": [
                        "b41e2b30-b377-5738-9ebe-8b9a7b6c35b7"
                    ]
                },
                "GAD-S": {
                    "questionId": "GAD-S",
                    "chosenOptionIds": [
                        "aa668802-d458-5b2d-b48f-e4058a640295"
                    ]
                },
                "GAD-s1": {
                    "questionId": "GAD-s1",
                    "chosenOptionIds": [
                        "c819bfe0-f79d-5132-8b3a-8604c0c5b83a"
                    ]
                },
                "GAD-s3a": {
                    "questionId": "GAD-s3a",
                    "chosenOptionIds": [
                        "1943ebd6-08ab-5b8e-a57b-7e397d303526"
                    ]
                },
                "GAD-s4a": {
                    "questionId": "GAD-s4a",
                    "chosenOptionIds": [
                        "5a1c5af4-b628-5554-bf63-4ca68ef2b328"
                    ]
                },
                "GAD-s6a": {
                    "questionId": "GAD-s6a",
                    "chosenOptionIds": [
                        "cc1895bc-b31f-503e-a147-172d1a0faa64"
                    ]
                }
            }
        },
        {
            "name": "Censeo vPrevious user: 7EW6KE8 aspect: gad_ce",
            "expected": {
                "type": "CloseTo",
                "value": 0.81
            },
            "answers": {
                "GAD-10a": {
                    "questionId": "GAD-10a",
                    "chosenOptionIds": [
                        "e36ed8e3-ca0e-5d7e-826f-84c47842e71c"
                    ]
                },
                "GAD-11a": {
                    "questionId": "GAD-11a",
                    "chosenOptionIds": [
                        "ba121413-fa3c-5d39-960f-827d7b0d55b8"
                    ]
                },
                "GAD-12a": {
                    "questionId": "GAD-12a",
                    "chosenOptionIds": [
                        "c74d8b74-1128-5f73-bfd2-2c3813bfdd4e"
                    ]
                },
                "GAD-13a": {
                    "questionId": "GAD-13a",
                    "chosenOptionIds": [
                        "8dda5767-c89f-5932-808d-a7729c3f935b"
                    ]
                },
                "GAD-14a": {
                    "questionId": "GAD-14a",
                    "chosenOptionIds": [
                        "0623617f-3483-5ad7-ba3a-dd6b95c269d5"
                    ]
                },
                "GAD-15a": {
                    "questionId": "GAD-15a",
                    "chosenOptionIds": [
                        "6447e557-156f-59a1-9cbc-cd9d4b9bcbee"
                    ]
                },
                "GAD-17a": {
                    "questionId": "GAD-17a",
                    "chosenOptionIds": [
                        "ca6c7d1d-3395-58b8-a87d-95fba13b4ca3"
                    ]
                },
                "GAD-18a": {
                    "questionId": "GAD-18a",
                    "chosenOptionIds": [
                        "af347157-5a07-5187-b207-b672ecf979dc"
                    ]
                },
                "GAD-19.0a": {
                    "questionId": "GAD-19.0a",
                    "chosenOptionIds": [
                        "a0520f66-594b-5c56-bebc-a0d018d139d9"
                    ]
                },
                "GAD-19a": {
                    "questionId": "GAD-19a",
                    "chosenOptionIds": [
                        "a7dc1041-6095-5630-9480-ed7c33997997",
                        "3c648d60-89ec-5e44-9bf1-dc362139d849",
                        "863da48d-f2eb-52f4-8c4e-feda60cda5c4",
                        "1acf1647-150f-5031-9599-8db8e23c423d",
                        "67df9ae6-c783-5f09-9975-a117ab653011",
                        "0a747bd1-8eee-563f-a6ae-8823404898a5"
                    ]
                },
                "GAD-22a": {
                    "questionId": "GAD-22a",
                    "chosenOptionIds": [
                        "09fba5ed-bf0e-5aa9-92a5-50c6a01ae0e2"
                    ]
                },
                "GAD-3a": {
                    "questionId": "GAD-3a",
                    "chosenOptionIds": [
                        "bdba5003-2589-507d-bb90-53534cc9b942"
                    ]
                },
                "GAD-4a": {
                    "questionId": "GAD-4a",
                    "chosenOptionIds": [
                        "84762e8e-edca-5c2c-a00f-fed69c13b87c"
                    ]
                },
                "GAD-5a": {
                    "questionId": "GAD-5a",
                    "chosenOptionIds": [
                        "bd66ed3d-4e63-5fe8-88a6-fbbf3ab55502"
                    ]
                },
                "GAD-6a": {
                    "questionId": "GAD-6a",
                    "chosenOptionIds": [
                        "f26a3508-c284-53a5-8bc7-63514fbe6737"
                    ]
                },
                "GAD-7a": {
                    "questionId": "GAD-7a",
                    "chosenOptionIds": [
                        "d7d05c32-44cb-59c7-84b0-95e98fde6077"
                    ]
                },
                "GAD-8a": {
                    "questionId": "GAD-8a",
                    "chosenOptionIds": [
                        "ce522c31-e5ef-51e2-8fa5-d9826566ed1e"
                    ]
                },
                "GAD-9a": {
                    "questionId": "GAD-9a",
                    "chosenOptionIds": [
                        "b41e2b30-b377-5738-9ebe-8b9a7b6c35b7"
                    ]
                },
                "GAD-S": {
                    "questionId": "GAD-S",
                    "chosenOptionIds": [
                        "aa668802-d458-5b2d-b48f-e4058a640295"
                    ]
                },
                "GAD-s1": {
                    "questionId": "GAD-s1",
                    "chosenOptionIds": [
                        "c819bfe0-f79d-5132-8b3a-8604c0c5b83a"
                    ]
                },
                "GAD-s3a": {
                    "questionId": "GAD-s3a",
                    "chosenOptionIds": [
                        "1943ebd6-08ab-5b8e-a57b-7e397d303526"
                    ]
                },
                "GAD-s4a": {
                    "questionId": "GAD-s4a",
                    "chosenOptionIds": [
                        "5a1c5af4-b628-5554-bf63-4ca68ef2b328"
                    ]
                },
                "GAD-s6a": {
                    "questionId": "GAD-s6a",
                    "chosenOptionIds": [
                        "cc1895bc-b31f-503e-a147-172d1a0faa64"
                    ]
                }
            }
        },
        {
            "name": "Clinical Diagnosis user: 4EGRG3P aspect: gad_ce ",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "GAD-10a": {
                    "questionId": "GAD-10a",
                    "chosenOptionIds": [
                        "1632140d-fefe-50b3-b11f-3ae261143e25"
                    ]
                },
                "GAD-11a": {
                    "questionId": "GAD-11a",
                    "chosenOptionIds": [
                        "62411dbb-3d28-57dc-ade4-d19a340fc6ed"
                    ]
                },
                "GAD-12a": {
                    "questionId": "GAD-12a",
                    "chosenOptionIds": [
                        "75847021-68a5-5e54-938f-ebae91570246"
                    ]
                },
                "GAD-13a": {
                    "questionId": "GAD-13a",
                    "chosenOptionIds": [
                        "40abb98e-db00-5505-85be-59a5dd4ef092"
                    ]
                },
                "GAD-14a": {
                    "questionId": "GAD-14a",
                    "chosenOptionIds": [
                        "0623617f-3483-5ad7-ba3a-dd6b95c269d5"
                    ]
                },
                "GAD-15a": {
                    "questionId": "GAD-15a",
                    "chosenOptionIds": [
                        "6447e557-156f-59a1-9cbc-cd9d4b9bcbee"
                    ]
                },
                "GAD-17a": {
                    "questionId": "GAD-17a",
                    "chosenOptionIds": [
                        "93c614a5-158b-54bb-b179-2d356411ab81"
                    ]
                },
                "GAD-18a": {
                    "questionId": "GAD-18a",
                    "chosenOptionIds": [
                        "af347157-5a07-5187-b207-b672ecf979dc"
                    ]
                },
                "GAD-19.0a": {
                    "questionId": "GAD-19.0a",
                    "chosenOptionIds": [
                        "1341da8c-ffc8-5e54-9f02-4b2126601994"
                    ]
                },
                "GAD-19a": {
                    "questionId": "GAD-19a",
                    "chosenOptionIds": [
                        "51801ae8-62d1-5a94-b757-a0b0851ac6bc",
                        "a7dc1041-6095-5630-9480-ed7c33997997",
                        "3c648d60-89ec-5e44-9bf1-dc362139d849",
                        "863da48d-f2eb-52f4-8c4e-feda60cda5c4",
                        "1acf1647-150f-5031-9599-8db8e23c423d",
                        "4ca41a8f-b524-5ef7-af86-7ef9279a7969",
                        "0a747bd1-8eee-563f-a6ae-8823404898a5",
                        "67df9ae6-c783-5f09-9975-a117ab653011"
                    ]
                },
                "GAD-22a": {
                    "questionId": "GAD-22a",
                    "chosenOptionIds": [
                        "09fba5ed-bf0e-5aa9-92a5-50c6a01ae0e2"
                    ]
                },
                "GAD-3a": {
                    "questionId": "GAD-3a",
                    "chosenOptionIds": [
                        "bdba5003-2589-507d-bb90-53534cc9b942"
                    ]
                },
                "GAD-4a": {
                    "questionId": "GAD-4a",
                    "chosenOptionIds": [
                        "84762e8e-edca-5c2c-a00f-fed69c13b87c"
                    ]
                },
                "GAD-5a": {
                    "questionId": "GAD-5a",
                    "chosenOptionIds": [
                        "59b5a96e-b0f6-5fd3-8c20-57c053f14b06"
                    ]
                },
                "GAD-6a": {
                    "questionId": "GAD-6a",
                    "chosenOptionIds": [
                        "f26a3508-c284-53a5-8bc7-63514fbe6737"
                    ]
                },
                "GAD-7a": {
                    "questionId": "GAD-7a",
                    "chosenOptionIds": [
                        "d7d05c32-44cb-59c7-84b0-95e98fde6077"
                    ]
                },
                "GAD-8a": {
                    "questionId": "GAD-8a",
                    "chosenOptionIds": [
                        "ce522c31-e5ef-51e2-8fa5-d9826566ed1e"
                    ]
                },
                "GAD-9a": {
                    "questionId": "GAD-9a",
                    "chosenOptionIds": [
                        "b41e2b30-b377-5738-9ebe-8b9a7b6c35b7"
                    ]
                },
                "GAD-S": {
                    "questionId": "GAD-S",
                    "chosenOptionIds": [
                        "aa668802-d458-5b2d-b48f-e4058a640295"
                    ]
                },
                "GAD-s1": {
                    "questionId": "GAD-s1",
                    "chosenOptionIds": [
                        "c819bfe0-f79d-5132-8b3a-8604c0c5b83a"
                    ]
                },
                "GAD-s3a": {
                    "questionId": "GAD-s3a",
                    "chosenOptionIds": [
                        "1943ebd6-08ab-5b8e-a57b-7e397d303526"
                    ]
                },
                "GAD-s4a": {
                    "questionId": "GAD-s4a",
                    "chosenOptionIds": [
                        "9d0741b6-d8b6-5216-90d0-df6743af1aa0"
                    ]
                },
                "GAD-s6a": {
                    "questionId": "GAD-s6a",
                    "chosenOptionIds": [
                        "cc1895bc-b31f-503e-a147-172d1a0faa64"
                    ]
                }
            }
        },
        {
            "name": "Censeo vPrevious user: 4EGRG3P aspect: gad_ce",
            "expected": {
                "type": "CloseTo",
                "value": 0.84
            },
            "answers": {
                "GAD-10a": {
                    "questionId": "GAD-10a",
                    "chosenOptionIds": [
                        "1632140d-fefe-50b3-b11f-3ae261143e25"
                    ]
                },
                "GAD-11a": {
                    "questionId": "GAD-11a",
                    "chosenOptionIds": [
                        "62411dbb-3d28-57dc-ade4-d19a340fc6ed"
                    ]
                },
                "GAD-12a": {
                    "questionId": "GAD-12a",
                    "chosenOptionIds": [
                        "75847021-68a5-5e54-938f-ebae91570246"
                    ]
                },
                "GAD-13a": {
                    "questionId": "GAD-13a",
                    "chosenOptionIds": [
                        "40abb98e-db00-5505-85be-59a5dd4ef092"
                    ]
                },
                "GAD-14a": {
                    "questionId": "GAD-14a",
                    "chosenOptionIds": [
                        "0623617f-3483-5ad7-ba3a-dd6b95c269d5"
                    ]
                },
                "GAD-15a": {
                    "questionId": "GAD-15a",
                    "chosenOptionIds": [
                        "6447e557-156f-59a1-9cbc-cd9d4b9bcbee"
                    ]
                },
                "GAD-17a": {
                    "questionId": "GAD-17a",
                    "chosenOptionIds": [
                        "93c614a5-158b-54bb-b179-2d356411ab81"
                    ]
                },
                "GAD-18a": {
                    "questionId": "GAD-18a",
                    "chosenOptionIds": [
                        "af347157-5a07-5187-b207-b672ecf979dc"
                    ]
                },
                "GAD-19.0a": {
                    "questionId": "GAD-19.0a",
                    "chosenOptionIds": [
                        "1341da8c-ffc8-5e54-9f02-4b2126601994"
                    ]
                },
                "GAD-19a": {
                    "questionId": "GAD-19a",
                    "chosenOptionIds": [
                        "51801ae8-62d1-5a94-b757-a0b0851ac6bc",
                        "a7dc1041-6095-5630-9480-ed7c33997997",
                        "3c648d60-89ec-5e44-9bf1-dc362139d849",
                        "863da48d-f2eb-52f4-8c4e-feda60cda5c4",
                        "1acf1647-150f-5031-9599-8db8e23c423d",
                        "4ca41a8f-b524-5ef7-af86-7ef9279a7969",
                        "0a747bd1-8eee-563f-a6ae-8823404898a5",
                        "67df9ae6-c783-5f09-9975-a117ab653011"
                    ]
                },
                "GAD-22a": {
                    "questionId": "GAD-22a",
                    "chosenOptionIds": [
                        "09fba5ed-bf0e-5aa9-92a5-50c6a01ae0e2"
                    ]
                },
                "GAD-3a": {
                    "questionId": "GAD-3a",
                    "chosenOptionIds": [
                        "bdba5003-2589-507d-bb90-53534cc9b942"
                    ]
                },
                "GAD-4a": {
                    "questionId": "GAD-4a",
                    "chosenOptionIds": [
                        "84762e8e-edca-5c2c-a00f-fed69c13b87c"
                    ]
                },
                "GAD-5a": {
                    "questionId": "GAD-5a",
                    "chosenOptionIds": [
                        "59b5a96e-b0f6-5fd3-8c20-57c053f14b06"
                    ]
                },
                "GAD-6a": {
                    "questionId": "GAD-6a",
                    "chosenOptionIds": [
                        "f26a3508-c284-53a5-8bc7-63514fbe6737"
                    ]
                },
                "GAD-7a": {
                    "questionId": "GAD-7a",
                    "chosenOptionIds": [
                        "d7d05c32-44cb-59c7-84b0-95e98fde6077"
                    ]
                },
                "GAD-8a": {
                    "questionId": "GAD-8a",
                    "chosenOptionIds": [
                        "ce522c31-e5ef-51e2-8fa5-d9826566ed1e"
                    ]
                },
                "GAD-9a": {
                    "questionId": "GAD-9a",
                    "chosenOptionIds": [
                        "b41e2b30-b377-5738-9ebe-8b9a7b6c35b7"
                    ]
                },
                "GAD-S": {
                    "questionId": "GAD-S",
                    "chosenOptionIds": [
                        "aa668802-d458-5b2d-b48f-e4058a640295"
                    ]
                },
                "GAD-s1": {
                    "questionId": "GAD-s1",
                    "chosenOptionIds": [
                        "c819bfe0-f79d-5132-8b3a-8604c0c5b83a"
                    ]
                },
                "GAD-s3a": {
                    "questionId": "GAD-s3a",
                    "chosenOptionIds": [
                        "1943ebd6-08ab-5b8e-a57b-7e397d303526"
                    ]
                },
                "GAD-s4a": {
                    "questionId": "GAD-s4a",
                    "chosenOptionIds": [
                        "9d0741b6-d8b6-5216-90d0-df6743af1aa0"
                    ]
                },
                "GAD-s6a": {
                    "questionId": "GAD-s6a",
                    "chosenOptionIds": [
                        "cc1895bc-b31f-503e-a147-172d1a0faa64"
                    ]
                }
            }
        },
        {
            "name": "Clinical Diagnosis user: 63YM43R aspect: gad_ce ",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "GAD-10a": {
                    "questionId": "GAD-10a",
                    "chosenOptionIds": [
                        "e36ed8e3-ca0e-5d7e-826f-84c47842e71c"
                    ]
                },
                "GAD-11a": {
                    "questionId": "GAD-11a",
                    "chosenOptionIds": [
                        "5779c477-4f6c-533d-a15b-33f106f84348"
                    ]
                },
                "GAD-12a": {
                    "questionId": "GAD-12a",
                    "chosenOptionIds": [
                        "c74d8b74-1128-5f73-bfd2-2c3813bfdd4e"
                    ]
                },
                "GAD-13a": {
                    "questionId": "GAD-13a",
                    "chosenOptionIds": [
                        "8dda5767-c89f-5932-808d-a7729c3f935b"
                    ]
                },
                "GAD-14a": {
                    "questionId": "GAD-14a",
                    "chosenOptionIds": [
                        "0623617f-3483-5ad7-ba3a-dd6b95c269d5"
                    ]
                },
                "GAD-15a": {
                    "questionId": "GAD-15a",
                    "chosenOptionIds": [
                        "632e07d1-df69-590c-a8f2-0b4f7ad343e1"
                    ]
                },
                "GAD-17a": {
                    "questionId": "GAD-17a",
                    "chosenOptionIds": [
                        "93c614a5-158b-54bb-b179-2d356411ab81"
                    ]
                },
                "GAD-18a": {
                    "questionId": "GAD-18a",
                    "chosenOptionIds": [
                        "af347157-5a07-5187-b207-b672ecf979dc"
                    ]
                },
                "GAD-19.0a": {
                    "questionId": "GAD-19.0a",
                    "chosenOptionIds": [
                        "78df6de1-fe3e-5bed-a63d-f5f9877809af"
                    ]
                },
                "GAD-19a": {
                    "questionId": "GAD-19a",
                    "chosenOptionIds": [
                        "4ca41a8f-b524-5ef7-af86-7ef9279a7969",
                        "0a747bd1-8eee-563f-a6ae-8823404898a5",
                        "67df9ae6-c783-5f09-9975-a117ab653011",
                        "1acf1647-150f-5031-9599-8db8e23c423d",
                        "863da48d-f2eb-52f4-8c4e-feda60cda5c4",
                        "3c648d60-89ec-5e44-9bf1-dc362139d849",
                        "a7dc1041-6095-5630-9480-ed7c33997997",
                        "51801ae8-62d1-5a94-b757-a0b0851ac6bc"
                    ]
                },
                "GAD-22a": {
                    "questionId": "GAD-22a",
                    "chosenOptionIds": [
                        "564b4697-78ea-5000-bb19-e1ed63265906"
                    ]
                },
                "GAD-3a": {
                    "questionId": "GAD-3a",
                    "chosenOptionIds": [
                        "bdba5003-2589-507d-bb90-53534cc9b942"
                    ]
                },
                "GAD-4a": {
                    "questionId": "GAD-4a",
                    "chosenOptionIds": [
                        "84762e8e-edca-5c2c-a00f-fed69c13b87c"
                    ]
                },
                "GAD-5a": {
                    "questionId": "GAD-5a",
                    "chosenOptionIds": [
                        "6bb8be71-d051-540f-8d81-5dff1869bbcc"
                    ]
                },
                "GAD-6a": {
                    "questionId": "GAD-6a",
                    "chosenOptionIds": [
                        "f26a3508-c284-53a5-8bc7-63514fbe6737"
                    ]
                },
                "GAD-7a": {
                    "questionId": "GAD-7a",
                    "chosenOptionIds": [
                        "d7d05c32-44cb-59c7-84b0-95e98fde6077"
                    ]
                },
                "GAD-8a": {
                    "questionId": "GAD-8a",
                    "chosenOptionIds": [
                        "985dc1a3-7ea9-5f5c-8af3-6159ac9c90bf"
                    ]
                },
                "GAD-9a": {
                    "questionId": "GAD-9a",
                    "chosenOptionIds": [
                        "cbcfc13e-979a-5e51-8698-f4d686fb5c09"
                    ]
                },
                "GAD-S": {
                    "questionId": "GAD-S",
                    "chosenOptionIds": [
                        "aa668802-d458-5b2d-b48f-e4058a640295"
                    ]
                },
                "GAD-s1": {
                    "questionId": "GAD-s1",
                    "chosenOptionIds": [
                        "f8d49124-01dc-5d55-add9-5ed87d998e00"
                    ]
                },
                "GAD-s3a": {
                    "questionId": "GAD-s3a",
                    "chosenOptionIds": [
                        "1943ebd6-08ab-5b8e-a57b-7e397d303526"
                    ]
                },
                "GAD-s4a": {
                    "questionId": "GAD-s4a",
                    "chosenOptionIds": [
                        "5a1c5af4-b628-5554-bf63-4ca68ef2b328"
                    ]
                },
                "GAD-s6a": {
                    "questionId": "GAD-s6a",
                    "chosenOptionIds": [
                        "cc1895bc-b31f-503e-a147-172d1a0faa64"
                    ]
                }
            }
        },
        {
            "name": "Censeo vPrevious user: 63YM43R aspect: gad_ce",
            "expected": {
                "type": "CloseTo",
                "value": 0.81
            },
            "answers": {
                "GAD-10a": {
                    "questionId": "GAD-10a",
                    "chosenOptionIds": [
                        "e36ed8e3-ca0e-5d7e-826f-84c47842e71c"
                    ]
                },
                "GAD-11a": {
                    "questionId": "GAD-11a",
                    "chosenOptionIds": [
                        "5779c477-4f6c-533d-a15b-33f106f84348"
                    ]
                },
                "GAD-12a": {
                    "questionId": "GAD-12a",
                    "chosenOptionIds": [
                        "c74d8b74-1128-5f73-bfd2-2c3813bfdd4e"
                    ]
                },
                "GAD-13a": {
                    "questionId": "GAD-13a",
                    "chosenOptionIds": [
                        "8dda5767-c89f-5932-808d-a7729c3f935b"
                    ]
                },
                "GAD-14a": {
                    "questionId": "GAD-14a",
                    "chosenOptionIds": [
                        "0623617f-3483-5ad7-ba3a-dd6b95c269d5"
                    ]
                },
                "GAD-15a": {
                    "questionId": "GAD-15a",
                    "chosenOptionIds": [
                        "632e07d1-df69-590c-a8f2-0b4f7ad343e1"
                    ]
                },
                "GAD-17a": {
                    "questionId": "GAD-17a",
                    "chosenOptionIds": [
                        "93c614a5-158b-54bb-b179-2d356411ab81"
                    ]
                },
                "GAD-18a": {
                    "questionId": "GAD-18a",
                    "chosenOptionIds": [
                        "af347157-5a07-5187-b207-b672ecf979dc"
                    ]
                },
                "GAD-19.0a": {
                    "questionId": "GAD-19.0a",
                    "chosenOptionIds": [
                        "78df6de1-fe3e-5bed-a63d-f5f9877809af"
                    ]
                },
                "GAD-19a": {
                    "questionId": "GAD-19a",
                    "chosenOptionIds": [
                        "4ca41a8f-b524-5ef7-af86-7ef9279a7969",
                        "0a747bd1-8eee-563f-a6ae-8823404898a5",
                        "67df9ae6-c783-5f09-9975-a117ab653011",
                        "1acf1647-150f-5031-9599-8db8e23c423d",
                        "863da48d-f2eb-52f4-8c4e-feda60cda5c4",
                        "3c648d60-89ec-5e44-9bf1-dc362139d849",
                        "a7dc1041-6095-5630-9480-ed7c33997997",
                        "51801ae8-62d1-5a94-b757-a0b0851ac6bc"
                    ]
                },
                "GAD-22a": {
                    "questionId": "GAD-22a",
                    "chosenOptionIds": [
                        "564b4697-78ea-5000-bb19-e1ed63265906"
                    ]
                },
                "GAD-3a": {
                    "questionId": "GAD-3a",
                    "chosenOptionIds": [
                        "bdba5003-2589-507d-bb90-53534cc9b942"
                    ]
                },
                "GAD-4a": {
                    "questionId": "GAD-4a",
                    "chosenOptionIds": [
                        "84762e8e-edca-5c2c-a00f-fed69c13b87c"
                    ]
                },
                "GAD-5a": {
                    "questionId": "GAD-5a",
                    "chosenOptionIds": [
                        "6bb8be71-d051-540f-8d81-5dff1869bbcc"
                    ]
                },
                "GAD-6a": {
                    "questionId": "GAD-6a",
                    "chosenOptionIds": [
                        "f26a3508-c284-53a5-8bc7-63514fbe6737"
                    ]
                },
                "GAD-7a": {
                    "questionId": "GAD-7a",
                    "chosenOptionIds": [
                        "d7d05c32-44cb-59c7-84b0-95e98fde6077"
                    ]
                },
                "GAD-8a": {
                    "questionId": "GAD-8a",
                    "chosenOptionIds": [
                        "985dc1a3-7ea9-5f5c-8af3-6159ac9c90bf"
                    ]
                },
                "GAD-9a": {
                    "questionId": "GAD-9a",
                    "chosenOptionIds": [
                        "cbcfc13e-979a-5e51-8698-f4d686fb5c09"
                    ]
                },
                "GAD-S": {
                    "questionId": "GAD-S",
                    "chosenOptionIds": [
                        "aa668802-d458-5b2d-b48f-e4058a640295"
                    ]
                },
                "GAD-s1": {
                    "questionId": "GAD-s1",
                    "chosenOptionIds": [
                        "f8d49124-01dc-5d55-add9-5ed87d998e00"
                    ]
                },
                "GAD-s3a": {
                    "questionId": "GAD-s3a",
                    "chosenOptionIds": [
                        "1943ebd6-08ab-5b8e-a57b-7e397d303526"
                    ]
                },
                "GAD-s4a": {
                    "questionId": "GAD-s4a",
                    "chosenOptionIds": [
                        "5a1c5af4-b628-5554-bf63-4ca68ef2b328"
                    ]
                },
                "GAD-s6a": {
                    "questionId": "GAD-s6a",
                    "chosenOptionIds": [
                        "cc1895bc-b31f-503e-a147-172d1a0faa64"
                    ]
                }
            }
        },
        {
            "name": "Clinical Diagnosis user: XE72M0L aspect: gad_ce ",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "GAD-10a": {
                    "questionId": "GAD-10a",
                    "chosenOptionIds": [
                        "0988ae4f-c9f2-5d44-a2cc-ecb83a2ec620"
                    ]
                },
                "GAD-11a": {
                    "questionId": "GAD-11a",
                    "chosenOptionIds": [
                        "5779c477-4f6c-533d-a15b-33f106f84348"
                    ]
                },
                "GAD-12a": {
                    "questionId": "GAD-12a",
                    "chosenOptionIds": [
                        "75847021-68a5-5e54-938f-ebae91570246"
                    ]
                },
                "GAD-13a": {
                    "questionId": "GAD-13a",
                    "chosenOptionIds": [
                        "15d0ef6b-b98e-5156-9b9b-c46a9dc15ed7"
                    ]
                },
                "GAD-14a": {
                    "questionId": "GAD-14a",
                    "chosenOptionIds": [
                        "e3ffd1b0-96fc-5dc0-8628-99e770d220ff"
                    ]
                },
                "GAD-15a": {
                    "questionId": "GAD-15a",
                    "chosenOptionIds": [
                        "3283b608-28d7-5390-af36-f103b5ead7ce"
                    ]
                },
                "GAD-17a": {
                    "questionId": "GAD-17a",
                    "chosenOptionIds": [
                        "85731e72-1516-5c0b-8201-d7479523cbcd"
                    ]
                },
                "GAD-18a": {
                    "questionId": "GAD-18a",
                    "chosenOptionIds": [
                        "af347157-5a07-5187-b207-b672ecf979dc"
                    ]
                },
                "GAD-19.0a": {
                    "questionId": "GAD-19.0a",
                    "chosenOptionIds": [
                        "1341da8c-ffc8-5e54-9f02-4b2126601994"
                    ]
                },
                "GAD-19a": {
                    "questionId": "GAD-19a",
                    "chosenOptionIds": [
                        "a7dc1041-6095-5630-9480-ed7c33997997",
                        "863da48d-f2eb-52f4-8c4e-feda60cda5c4",
                        "3c648d60-89ec-5e44-9bf1-dc362139d849",
                        "1acf1647-150f-5031-9599-8db8e23c423d",
                        "67df9ae6-c783-5f09-9975-a117ab653011",
                        "0a747bd1-8eee-563f-a6ae-8823404898a5",
                        "4ca41a8f-b524-5ef7-af86-7ef9279a7969"
                    ]
                },
                "GAD-22a": {
                    "questionId": "GAD-22a",
                    "chosenOptionIds": [
                        "09fba5ed-bf0e-5aa9-92a5-50c6a01ae0e2"
                    ]
                },
                "GAD-3a": {
                    "questionId": "GAD-3a",
                    "chosenOptionIds": [
                        "bdba5003-2589-507d-bb90-53534cc9b942"
                    ]
                },
                "GAD-4a": {
                    "questionId": "GAD-4a",
                    "chosenOptionIds": [
                        "517a9806-ffe8-52a9-86df-e947a47e169c"
                    ]
                },
                "GAD-5a": {
                    "questionId": "GAD-5a",
                    "chosenOptionIds": [
                        "59b5a96e-b0f6-5fd3-8c20-57c053f14b06"
                    ]
                },
                "GAD-6a": {
                    "questionId": "GAD-6a",
                    "chosenOptionIds": [
                        "8996813a-e012-57cb-8eab-b6926c27eba2"
                    ]
                },
                "GAD-7a": {
                    "questionId": "GAD-7a",
                    "chosenOptionIds": [
                        "80dffd87-d3ca-5977-9aee-b90eebcc4eb1"
                    ]
                },
                "GAD-8a": {
                    "questionId": "GAD-8a",
                    "chosenOptionIds": [
                        "ce522c31-e5ef-51e2-8fa5-d9826566ed1e"
                    ]
                },
                "GAD-9a": {
                    "questionId": "GAD-9a",
                    "chosenOptionIds": [
                        "b41e2b30-b377-5738-9ebe-8b9a7b6c35b7"
                    ]
                },
                "GAD-S": {
                    "questionId": "GAD-S",
                    "chosenOptionIds": [
                        "aa668802-d458-5b2d-b48f-e4058a640295"
                    ]
                },
                "GAD-s1": {
                    "questionId": "GAD-s1",
                    "chosenOptionIds": [
                        "24032a9e-f5b6-5f43-8ddf-305888669ad1"
                    ]
                },
                "GAD-s3a": {
                    "questionId": "GAD-s3a",
                    "chosenOptionIds": [
                        "1943ebd6-08ab-5b8e-a57b-7e397d303526"
                    ]
                },
                "GAD-s4a": {
                    "questionId": "GAD-s4a",
                    "chosenOptionIds": [
                        "9d0741b6-d8b6-5216-90d0-df6743af1aa0"
                    ]
                },
                "GAD-s6a": {
                    "questionId": "GAD-s6a",
                    "chosenOptionIds": [
                        "cc1895bc-b31f-503e-a147-172d1a0faa64"
                    ]
                }
            }
        },
        {
            "name": "Censeo vPrevious user: XE72M0L aspect: gad_ce",
            "expected": {
                "type": "CloseTo",
                "value": 0.86
            },
            "answers": {
                "GAD-10a": {
                    "questionId": "GAD-10a",
                    "chosenOptionIds": [
                        "0988ae4f-c9f2-5d44-a2cc-ecb83a2ec620"
                    ]
                },
                "GAD-11a": {
                    "questionId": "GAD-11a",
                    "chosenOptionIds": [
                        "5779c477-4f6c-533d-a15b-33f106f84348"
                    ]
                },
                "GAD-12a": {
                    "questionId": "GAD-12a",
                    "chosenOptionIds": [
                        "75847021-68a5-5e54-938f-ebae91570246"
                    ]
                },
                "GAD-13a": {
                    "questionId": "GAD-13a",
                    "chosenOptionIds": [
                        "15d0ef6b-b98e-5156-9b9b-c46a9dc15ed7"
                    ]
                },
                "GAD-14a": {
                    "questionId": "GAD-14a",
                    "chosenOptionIds": [
                        "e3ffd1b0-96fc-5dc0-8628-99e770d220ff"
                    ]
                },
                "GAD-15a": {
                    "questionId": "GAD-15a",
                    "chosenOptionIds": [
                        "3283b608-28d7-5390-af36-f103b5ead7ce"
                    ]
                },
                "GAD-17a": {
                    "questionId": "GAD-17a",
                    "chosenOptionIds": [
                        "85731e72-1516-5c0b-8201-d7479523cbcd"
                    ]
                },
                "GAD-18a": {
                    "questionId": "GAD-18a",
                    "chosenOptionIds": [
                        "af347157-5a07-5187-b207-b672ecf979dc"
                    ]
                },
                "GAD-19.0a": {
                    "questionId": "GAD-19.0a",
                    "chosenOptionIds": [
                        "1341da8c-ffc8-5e54-9f02-4b2126601994"
                    ]
                },
                "GAD-19a": {
                    "questionId": "GAD-19a",
                    "chosenOptionIds": [
                        "a7dc1041-6095-5630-9480-ed7c33997997",
                        "863da48d-f2eb-52f4-8c4e-feda60cda5c4",
                        "3c648d60-89ec-5e44-9bf1-dc362139d849",
                        "1acf1647-150f-5031-9599-8db8e23c423d",
                        "67df9ae6-c783-5f09-9975-a117ab653011",
                        "0a747bd1-8eee-563f-a6ae-8823404898a5",
                        "4ca41a8f-b524-5ef7-af86-7ef9279a7969"
                    ]
                },
                "GAD-22a": {
                    "questionId": "GAD-22a",
                    "chosenOptionIds": [
                        "09fba5ed-bf0e-5aa9-92a5-50c6a01ae0e2"
                    ]
                },
                "GAD-3a": {
                    "questionId": "GAD-3a",
                    "chosenOptionIds": [
                        "bdba5003-2589-507d-bb90-53534cc9b942"
                    ]
                },
                "GAD-4a": {
                    "questionId": "GAD-4a",
                    "chosenOptionIds": [
                        "517a9806-ffe8-52a9-86df-e947a47e169c"
                    ]
                },
                "GAD-5a": {
                    "questionId": "GAD-5a",
                    "chosenOptionIds": [
                        "59b5a96e-b0f6-5fd3-8c20-57c053f14b06"
                    ]
                },
                "GAD-6a": {
                    "questionId": "GAD-6a",
                    "chosenOptionIds": [
                        "8996813a-e012-57cb-8eab-b6926c27eba2"
                    ]
                },
                "GAD-7a": {
                    "questionId": "GAD-7a",
                    "chosenOptionIds": [
                        "80dffd87-d3ca-5977-9aee-b90eebcc4eb1"
                    ]
                },
                "GAD-8a": {
                    "questionId": "GAD-8a",
                    "chosenOptionIds": [
                        "ce522c31-e5ef-51e2-8fa5-d9826566ed1e"
                    ]
                },
                "GAD-9a": {
                    "questionId": "GAD-9a",
                    "chosenOptionIds": [
                        "b41e2b30-b377-5738-9ebe-8b9a7b6c35b7"
                    ]
                },
                "GAD-S": {
                    "questionId": "GAD-S",
                    "chosenOptionIds": [
                        "aa668802-d458-5b2d-b48f-e4058a640295"
                    ]
                },
                "GAD-s1": {
                    "questionId": "GAD-s1",
                    "chosenOptionIds": [
                        "24032a9e-f5b6-5f43-8ddf-305888669ad1"
                    ]
                },
                "GAD-s3a": {
                    "questionId": "GAD-s3a",
                    "chosenOptionIds": [
                        "1943ebd6-08ab-5b8e-a57b-7e397d303526"
                    ]
                },
                "GAD-s4a": {
                    "questionId": "GAD-s4a",
                    "chosenOptionIds": [
                        "9d0741b6-d8b6-5216-90d0-df6743af1aa0"
                    ]
                },
                "GAD-s6a": {
                    "questionId": "GAD-s6a",
                    "chosenOptionIds": [
                        "cc1895bc-b31f-503e-a147-172d1a0faa64"
                    ]
                }
            }
        },
        {
            "name": "Clinical Diagnosis user: ME8MOEW aspect: gad_ce ",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "GAD-10a": {
                    "questionId": "GAD-10a",
                    "chosenOptionIds": [
                        "e36ed8e3-ca0e-5d7e-826f-84c47842e71c"
                    ]
                },
                "GAD-11a": {
                    "questionId": "GAD-11a",
                    "chosenOptionIds": [
                        "ba121413-fa3c-5d39-960f-827d7b0d55b8"
                    ]
                },
                "GAD-12a": {
                    "questionId": "GAD-12a",
                    "chosenOptionIds": [
                        "c74d8b74-1128-5f73-bfd2-2c3813bfdd4e"
                    ]
                },
                "GAD-13a": {
                    "questionId": "GAD-13a",
                    "chosenOptionIds": [
                        "40abb98e-db00-5505-85be-59a5dd4ef092"
                    ]
                },
                "GAD-14a": {
                    "questionId": "GAD-14a",
                    "chosenOptionIds": [
                        "0623617f-3483-5ad7-ba3a-dd6b95c269d5"
                    ]
                },
                "GAD-15a": {
                    "questionId": "GAD-15a",
                    "chosenOptionIds": [
                        "dfe08faa-d933-5ebc-b3dd-20cc46202224"
                    ]
                },
                "GAD-17a": {
                    "questionId": "GAD-17a",
                    "chosenOptionIds": [
                        "93c614a5-158b-54bb-b179-2d356411ab81"
                    ]
                },
                "GAD-18a": {
                    "questionId": "GAD-18a",
                    "chosenOptionIds": [
                        "7035a10f-7dd7-55bd-946c-2cc73ffa5e6e"
                    ]
                },
                "GAD-19.0a": {
                    "questionId": "GAD-19.0a",
                    "chosenOptionIds": [
                        "1341da8c-ffc8-5e54-9f02-4b2126601994"
                    ]
                },
                "GAD-19a": {
                    "questionId": "GAD-19a",
                    "chosenOptionIds": [
                        "a7dc1041-6095-5630-9480-ed7c33997997",
                        "3c648d60-89ec-5e44-9bf1-dc362139d849",
                        "863da48d-f2eb-52f4-8c4e-feda60cda5c4",
                        "1acf1647-150f-5031-9599-8db8e23c423d",
                        "67df9ae6-c783-5f09-9975-a117ab653011",
                        "0a747bd1-8eee-563f-a6ae-8823404898a5",
                        "4ca41a8f-b524-5ef7-af86-7ef9279a7969"
                    ]
                },
                "GAD-22a": {
                    "questionId": "GAD-22a",
                    "chosenOptionIds": [
                        "09fba5ed-bf0e-5aa9-92a5-50c6a01ae0e2"
                    ]
                },
                "GAD-3a": {
                    "questionId": "GAD-3a",
                    "chosenOptionIds": [
                        "bdba5003-2589-507d-bb90-53534cc9b942"
                    ]
                },
                "GAD-4a": {
                    "questionId": "GAD-4a",
                    "chosenOptionIds": [
                        "84762e8e-edca-5c2c-a00f-fed69c13b87c"
                    ]
                },
                "GAD-5a": {
                    "questionId": "GAD-5a",
                    "chosenOptionIds": [
                        "59b5a96e-b0f6-5fd3-8c20-57c053f14b06"
                    ]
                },
                "GAD-6a": {
                    "questionId": "GAD-6a",
                    "chosenOptionIds": [
                        "f26a3508-c284-53a5-8bc7-63514fbe6737"
                    ]
                },
                "GAD-7a": {
                    "questionId": "GAD-7a",
                    "chosenOptionIds": [
                        "d7d05c32-44cb-59c7-84b0-95e98fde6077"
                    ]
                },
                "GAD-8a": {
                    "questionId": "GAD-8a",
                    "chosenOptionIds": [
                        "a7ecd81c-cd6a-5592-892f-8e391b9a115c"
                    ]
                },
                "GAD-9a": {
                    "questionId": "GAD-9a",
                    "chosenOptionIds": [
                        "cbcfc13e-979a-5e51-8698-f4d686fb5c09"
                    ]
                },
                "GAD-S": {
                    "questionId": "GAD-S",
                    "chosenOptionIds": [
                        "aa668802-d458-5b2d-b48f-e4058a640295"
                    ]
                },
                "GAD-s1": {
                    "questionId": "GAD-s1",
                    "chosenOptionIds": [
                        "c819bfe0-f79d-5132-8b3a-8604c0c5b83a"
                    ]
                },
                "GAD-s3a": {
                    "questionId": "GAD-s3a",
                    "chosenOptionIds": [
                        "1943ebd6-08ab-5b8e-a57b-7e397d303526"
                    ]
                },
                "GAD-s4a": {
                    "questionId": "GAD-s4a",
                    "chosenOptionIds": [
                        "5a1c5af4-b628-5554-bf63-4ca68ef2b328"
                    ]
                },
                "GAD-s6a": {
                    "questionId": "GAD-s6a",
                    "chosenOptionIds": [
                        "cc1895bc-b31f-503e-a147-172d1a0faa64"
                    ]
                }
            }
        },
        {
            "name": "Censeo vPrevious user: ME8MOEW aspect: gad_ce",
            "expected": {
                "type": "CloseTo",
                "value": 0.81
            },
            "answers": {
                "GAD-10a": {
                    "questionId": "GAD-10a",
                    "chosenOptionIds": [
                        "e36ed8e3-ca0e-5d7e-826f-84c47842e71c"
                    ]
                },
                "GAD-11a": {
                    "questionId": "GAD-11a",
                    "chosenOptionIds": [
                        "ba121413-fa3c-5d39-960f-827d7b0d55b8"
                    ]
                },
                "GAD-12a": {
                    "questionId": "GAD-12a",
                    "chosenOptionIds": [
                        "c74d8b74-1128-5f73-bfd2-2c3813bfdd4e"
                    ]
                },
                "GAD-13a": {
                    "questionId": "GAD-13a",
                    "chosenOptionIds": [
                        "40abb98e-db00-5505-85be-59a5dd4ef092"
                    ]
                },
                "GAD-14a": {
                    "questionId": "GAD-14a",
                    "chosenOptionIds": [
                        "0623617f-3483-5ad7-ba3a-dd6b95c269d5"
                    ]
                },
                "GAD-15a": {
                    "questionId": "GAD-15a",
                    "chosenOptionIds": [
                        "dfe08faa-d933-5ebc-b3dd-20cc46202224"
                    ]
                },
                "GAD-17a": {
                    "questionId": "GAD-17a",
                    "chosenOptionIds": [
                        "93c614a5-158b-54bb-b179-2d356411ab81"
                    ]
                },
                "GAD-18a": {
                    "questionId": "GAD-18a",
                    "chosenOptionIds": [
                        "7035a10f-7dd7-55bd-946c-2cc73ffa5e6e"
                    ]
                },
                "GAD-19.0a": {
                    "questionId": "GAD-19.0a",
                    "chosenOptionIds": [
                        "1341da8c-ffc8-5e54-9f02-4b2126601994"
                    ]
                },
                "GAD-19a": {
                    "questionId": "GAD-19a",
                    "chosenOptionIds": [
                        "a7dc1041-6095-5630-9480-ed7c33997997",
                        "3c648d60-89ec-5e44-9bf1-dc362139d849",
                        "863da48d-f2eb-52f4-8c4e-feda60cda5c4",
                        "1acf1647-150f-5031-9599-8db8e23c423d",
                        "67df9ae6-c783-5f09-9975-a117ab653011",
                        "0a747bd1-8eee-563f-a6ae-8823404898a5",
                        "4ca41a8f-b524-5ef7-af86-7ef9279a7969"
                    ]
                },
                "GAD-22a": {
                    "questionId": "GAD-22a",
                    "chosenOptionIds": [
                        "09fba5ed-bf0e-5aa9-92a5-50c6a01ae0e2"
                    ]
                },
                "GAD-3a": {
                    "questionId": "GAD-3a",
                    "chosenOptionIds": [
                        "bdba5003-2589-507d-bb90-53534cc9b942"
                    ]
                },
                "GAD-4a": {
                    "questionId": "GAD-4a",
                    "chosenOptionIds": [
                        "84762e8e-edca-5c2c-a00f-fed69c13b87c"
                    ]
                },
                "GAD-5a": {
                    "questionId": "GAD-5a",
                    "chosenOptionIds": [
                        "59b5a96e-b0f6-5fd3-8c20-57c053f14b06"
                    ]
                },
                "GAD-6a": {
                    "questionId": "GAD-6a",
                    "chosenOptionIds": [
                        "f26a3508-c284-53a5-8bc7-63514fbe6737"
                    ]
                },
                "GAD-7a": {
                    "questionId": "GAD-7a",
                    "chosenOptionIds": [
                        "d7d05c32-44cb-59c7-84b0-95e98fde6077"
                    ]
                },
                "GAD-8a": {
                    "questionId": "GAD-8a",
                    "chosenOptionIds": [
                        "a7ecd81c-cd6a-5592-892f-8e391b9a115c"
                    ]
                },
                "GAD-9a": {
                    "questionId": "GAD-9a",
                    "chosenOptionIds": [
                        "cbcfc13e-979a-5e51-8698-f4d686fb5c09"
                    ]
                },
                "GAD-S": {
                    "questionId": "GAD-S",
                    "chosenOptionIds": [
                        "aa668802-d458-5b2d-b48f-e4058a640295"
                    ]
                },
                "GAD-s1": {
                    "questionId": "GAD-s1",
                    "chosenOptionIds": [
                        "c819bfe0-f79d-5132-8b3a-8604c0c5b83a"
                    ]
                },
                "GAD-s3a": {
                    "questionId": "GAD-s3a",
                    "chosenOptionIds": [
                        "1943ebd6-08ab-5b8e-a57b-7e397d303526"
                    ]
                },
                "GAD-s4a": {
                    "questionId": "GAD-s4a",
                    "chosenOptionIds": [
                        "5a1c5af4-b628-5554-bf63-4ca68ef2b328"
                    ]
                },
                "GAD-s6a": {
                    "questionId": "GAD-s6a",
                    "chosenOptionIds": [
                        "cc1895bc-b31f-503e-a147-172d1a0faa64"
                    ]
                }
            }
        },
        {
            "name": "Clinical Diagnosis user: 4EG443P aspect: gad_ce ",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "GAD-10a": {
                    "questionId": "GAD-10a",
                    "chosenOptionIds": [
                        "e36ed8e3-ca0e-5d7e-826f-84c47842e71c"
                    ]
                },
                "GAD-11a": {
                    "questionId": "GAD-11a",
                    "chosenOptionIds": [
                        "c1926d2c-b894-5ae1-bbdd-f8c9954ce55a"
                    ]
                },
                "GAD-12a": {
                    "questionId": "GAD-12a",
                    "chosenOptionIds": [
                        "c74d8b74-1128-5f73-bfd2-2c3813bfdd4e"
                    ]
                },
                "GAD-13a": {
                    "questionId": "GAD-13a",
                    "chosenOptionIds": [
                        "8dda5767-c89f-5932-808d-a7729c3f935b"
                    ]
                },
                "GAD-14a": {
                    "questionId": "GAD-14a",
                    "chosenOptionIds": [
                        "b0335c5e-d91f-5e19-9a45-4936bd9e3bea"
                    ]
                },
                "GAD-15a": {
                    "questionId": "GAD-15a",
                    "chosenOptionIds": [
                        "6447e557-156f-59a1-9cbc-cd9d4b9bcbee"
                    ]
                },
                "GAD-17a": {
                    "questionId": "GAD-17a",
                    "chosenOptionIds": [
                        "ca6c7d1d-3395-58b8-a87d-95fba13b4ca3"
                    ]
                },
                "GAD-18a": {
                    "questionId": "GAD-18a",
                    "chosenOptionIds": [
                        "af347157-5a07-5187-b207-b672ecf979dc"
                    ]
                },
                "GAD-19.0a": {
                    "questionId": "GAD-19.0a",
                    "chosenOptionIds": [
                        "a0520f66-594b-5c56-bebc-a0d018d139d9"
                    ]
                },
                "GAD-19a": {
                    "questionId": "GAD-19a",
                    "chosenOptionIds": [
                        "51801ae8-62d1-5a94-b757-a0b0851ac6bc",
                        "a7dc1041-6095-5630-9480-ed7c33997997",
                        "863da48d-f2eb-52f4-8c4e-feda60cda5c4",
                        "67df9ae6-c783-5f09-9975-a117ab653011",
                        "1acf1647-150f-5031-9599-8db8e23c423d",
                        "4ca41a8f-b524-5ef7-af86-7ef9279a7969",
                        "2f79dc47-8e9b-5b4a-a923-e43cde4b52ce"
                    ]
                },
                "GAD-22a": {
                    "questionId": "GAD-22a",
                    "chosenOptionIds": [
                        "564b4697-78ea-5000-bb19-e1ed63265906"
                    ]
                },
                "GAD-3a": {
                    "questionId": "GAD-3a",
                    "chosenOptionIds": [
                        "bdba5003-2589-507d-bb90-53534cc9b942"
                    ]
                },
                "GAD-4a": {
                    "questionId": "GAD-4a",
                    "chosenOptionIds": [
                        "84762e8e-edca-5c2c-a00f-fed69c13b87c"
                    ]
                },
                "GAD-5a": {
                    "questionId": "GAD-5a",
                    "chosenOptionIds": [
                        "bd66ed3d-4e63-5fe8-88a6-fbbf3ab55502"
                    ]
                },
                "GAD-6a": {
                    "questionId": "GAD-6a",
                    "chosenOptionIds": [
                        "8996813a-e012-57cb-8eab-b6926c27eba2"
                    ]
                },
                "GAD-7a": {
                    "questionId": "GAD-7a",
                    "chosenOptionIds": [
                        "3b9e5247-4c0f-5c9d-9ffb-822ab95bee8a"
                    ]
                },
                "GAD-8a": {
                    "questionId": "GAD-8a",
                    "chosenOptionIds": [
                        "985dc1a3-7ea9-5f5c-8af3-6159ac9c90bf"
                    ]
                },
                "GAD-9a": {
                    "questionId": "GAD-9a",
                    "chosenOptionIds": [
                        "b41e2b30-b377-5738-9ebe-8b9a7b6c35b7"
                    ]
                },
                "GAD-S": {
                    "questionId": "GAD-S",
                    "chosenOptionIds": [
                        "aa668802-d458-5b2d-b48f-e4058a640295"
                    ]
                },
                "GAD-s1": {
                    "questionId": "GAD-s1",
                    "chosenOptionIds": [
                        "f8d49124-01dc-5d55-add9-5ed87d998e00"
                    ]
                },
                "GAD-s3a": {
                    "questionId": "GAD-s3a",
                    "chosenOptionIds": [
                        "1943ebd6-08ab-5b8e-a57b-7e397d303526"
                    ]
                },
                "GAD-s4a": {
                    "questionId": "GAD-s4a",
                    "chosenOptionIds": [
                        "9d0741b6-d8b6-5216-90d0-df6743af1aa0"
                    ]
                },
                "GAD-s6a": {
                    "questionId": "GAD-s6a",
                    "chosenOptionIds": [
                        "cc1895bc-b31f-503e-a147-172d1a0faa64"
                    ]
                }
            }
        },
        {
            "name": "Censeo vPrevious user: 4EG443P aspect: gad_ce",
            "expected": {
                "type": "CloseTo",
                "value": 0.84
            },
            "answers": {
                "GAD-10a": {
                    "questionId": "GAD-10a",
                    "chosenOptionIds": [
                        "e36ed8e3-ca0e-5d7e-826f-84c47842e71c"
                    ]
                },
                "GAD-11a": {
                    "questionId": "GAD-11a",
                    "chosenOptionIds": [
                        "c1926d2c-b894-5ae1-bbdd-f8c9954ce55a"
                    ]
                },
                "GAD-12a": {
                    "questionId": "GAD-12a",
                    "chosenOptionIds": [
                        "c74d8b74-1128-5f73-bfd2-2c3813bfdd4e"
                    ]
                },
                "GAD-13a": {
                    "questionId": "GAD-13a",
                    "chosenOptionIds": [
                        "8dda5767-c89f-5932-808d-a7729c3f935b"
                    ]
                },
                "GAD-14a": {
                    "questionId": "GAD-14a",
                    "chosenOptionIds": [
                        "b0335c5e-d91f-5e19-9a45-4936bd9e3bea"
                    ]
                },
                "GAD-15a": {
                    "questionId": "GAD-15a",
                    "chosenOptionIds": [
                        "6447e557-156f-59a1-9cbc-cd9d4b9bcbee"
                    ]
                },
                "GAD-17a": {
                    "questionId": "GAD-17a",
                    "chosenOptionIds": [
                        "ca6c7d1d-3395-58b8-a87d-95fba13b4ca3"
                    ]
                },
                "GAD-18a": {
                    "questionId": "GAD-18a",
                    "chosenOptionIds": [
                        "af347157-5a07-5187-b207-b672ecf979dc"
                    ]
                },
                "GAD-19.0a": {
                    "questionId": "GAD-19.0a",
                    "chosenOptionIds": [
                        "a0520f66-594b-5c56-bebc-a0d018d139d9"
                    ]
                },
                "GAD-19a": {
                    "questionId": "GAD-19a",
                    "chosenOptionIds": [
                        "51801ae8-62d1-5a94-b757-a0b0851ac6bc",
                        "a7dc1041-6095-5630-9480-ed7c33997997",
                        "863da48d-f2eb-52f4-8c4e-feda60cda5c4",
                        "67df9ae6-c783-5f09-9975-a117ab653011",
                        "1acf1647-150f-5031-9599-8db8e23c423d",
                        "4ca41a8f-b524-5ef7-af86-7ef9279a7969",
                        "2f79dc47-8e9b-5b4a-a923-e43cde4b52ce"
                    ]
                },
                "GAD-22a": {
                    "questionId": "GAD-22a",
                    "chosenOptionIds": [
                        "564b4697-78ea-5000-bb19-e1ed63265906"
                    ]
                },
                "GAD-3a": {
                    "questionId": "GAD-3a",
                    "chosenOptionIds": [
                        "bdba5003-2589-507d-bb90-53534cc9b942"
                    ]
                },
                "GAD-4a": {
                    "questionId": "GAD-4a",
                    "chosenOptionIds": [
                        "84762e8e-edca-5c2c-a00f-fed69c13b87c"
                    ]
                },
                "GAD-5a": {
                    "questionId": "GAD-5a",
                    "chosenOptionIds": [
                        "bd66ed3d-4e63-5fe8-88a6-fbbf3ab55502"
                    ]
                },
                "GAD-6a": {
                    "questionId": "GAD-6a",
                    "chosenOptionIds": [
                        "8996813a-e012-57cb-8eab-b6926c27eba2"
                    ]
                },
                "GAD-7a": {
                    "questionId": "GAD-7a",
                    "chosenOptionIds": [
                        "3b9e5247-4c0f-5c9d-9ffb-822ab95bee8a"
                    ]
                },
                "GAD-8a": {
                    "questionId": "GAD-8a",
                    "chosenOptionIds": [
                        "985dc1a3-7ea9-5f5c-8af3-6159ac9c90bf"
                    ]
                },
                "GAD-9a": {
                    "questionId": "GAD-9a",
                    "chosenOptionIds": [
                        "b41e2b30-b377-5738-9ebe-8b9a7b6c35b7"
                    ]
                },
                "GAD-S": {
                    "questionId": "GAD-S",
                    "chosenOptionIds": [
                        "aa668802-d458-5b2d-b48f-e4058a640295"
                    ]
                },
                "GAD-s1": {
                    "questionId": "GAD-s1",
                    "chosenOptionIds": [
                        "f8d49124-01dc-5d55-add9-5ed87d998e00"
                    ]
                },
                "GAD-s3a": {
                    "questionId": "GAD-s3a",
                    "chosenOptionIds": [
                        "1943ebd6-08ab-5b8e-a57b-7e397d303526"
                    ]
                },
                "GAD-s4a": {
                    "questionId": "GAD-s4a",
                    "chosenOptionIds": [
                        "9d0741b6-d8b6-5216-90d0-df6743af1aa0"
                    ]
                },
                "GAD-s6a": {
                    "questionId": "GAD-s6a",
                    "chosenOptionIds": [
                        "cc1895bc-b31f-503e-a147-172d1a0faa64"
                    ]
                }
            }
        },
        {
            "name": "Clinical Diagnosis user: J0V6N3L aspect: gad_ce ",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "GAD-10a": {
                    "questionId": "GAD-10a",
                    "chosenOptionIds": [
                        "a30b13cd-454a-529d-a598-c5192a29668e"
                    ]
                },
                "GAD-11a": {
                    "questionId": "GAD-11a",
                    "chosenOptionIds": [
                        "68c692fb-cc63-5ad6-b88e-5e45ad971c82"
                    ]
                },
                "GAD-12a": {
                    "questionId": "GAD-12a",
                    "chosenOptionIds": [
                        "4fb31d91-3520-5676-90c0-d3c05e9abfb8"
                    ]
                },
                "GAD-13a": {
                    "questionId": "GAD-13a",
                    "chosenOptionIds": [
                        "42da2d35-01a4-50f9-95e5-c7bbc4c8eda7"
                    ]
                },
                "GAD-14a": {
                    "questionId": "GAD-14a",
                    "chosenOptionIds": [
                        "6ac69add-b72f-5784-84a9-a517721463e6"
                    ]
                },
                "GAD-15a": {
                    "questionId": "GAD-15a",
                    "chosenOptionIds": [
                        "dfe08faa-d933-5ebc-b3dd-20cc46202224"
                    ]
                },
                "GAD-17a": {
                    "questionId": "GAD-17a",
                    "chosenOptionIds": [
                        "ca6c7d1d-3395-58b8-a87d-95fba13b4ca3"
                    ]
                },
                "GAD-18a": {
                    "questionId": "GAD-18a",
                    "chosenOptionIds": [
                        "7cb98422-b8c9-5f92-aee6-74cf0192ea01"
                    ]
                },
                "GAD-19.0a": {
                    "questionId": "GAD-19.0a",
                    "chosenOptionIds": [
                        "78df6de1-fe3e-5bed-a63d-f5f9877809af"
                    ]
                },
                "GAD-19a": {
                    "questionId": "GAD-19a",
                    "chosenOptionIds": [
                        "3c648d60-89ec-5e44-9bf1-dc362139d849",
                        "a7dc1041-6095-5630-9480-ed7c33997997",
                        "4ca41a8f-b524-5ef7-af86-7ef9279a7969",
                        "0a747bd1-8eee-563f-a6ae-8823404898a5"
                    ]
                },
                "GAD-22a": {
                    "questionId": "GAD-22a",
                    "chosenOptionIds": [
                        "09fba5ed-bf0e-5aa9-92a5-50c6a01ae0e2"
                    ]
                },
                "GAD-3a": {
                    "questionId": "GAD-3a",
                    "chosenOptionIds": [
                        "bdba5003-2589-507d-bb90-53534cc9b942"
                    ]
                },
                "GAD-4a": {
                    "questionId": "GAD-4a",
                    "chosenOptionIds": [
                        "748f4a0b-00a7-5b15-b390-2dfb8ac9eb1d"
                    ]
                },
                "GAD-5a": {
                    "questionId": "GAD-5a",
                    "chosenOptionIds": [
                        "63fdf718-12b4-5497-abff-2ae6a86b484b"
                    ]
                },
                "GAD-6a": {
                    "questionId": "GAD-6a",
                    "chosenOptionIds": [
                        "7d60ebb2-5f2d-5ab0-b9ca-31210502ece9"
                    ]
                },
                "GAD-7a": {
                    "questionId": "GAD-7a",
                    "chosenOptionIds": [
                        "3b9e5247-4c0f-5c9d-9ffb-822ab95bee8a"
                    ]
                },
                "GAD-8a": {
                    "questionId": "GAD-8a",
                    "chosenOptionIds": [
                        "80887b2c-7c9f-5105-a64a-b3bb33442ac6"
                    ]
                },
                "GAD-9a": {
                    "questionId": "GAD-9a",
                    "chosenOptionIds": [
                        "35150196-067f-58af-b669-6e41443a3601"
                    ]
                },
                "GAD-S": {
                    "questionId": "GAD-S",
                    "chosenOptionIds": [
                        "aa668802-d458-5b2d-b48f-e4058a640295"
                    ]
                },
                "GAD-s1": {
                    "questionId": "GAD-s1",
                    "chosenOptionIds": [
                        "24032a9e-f5b6-5f43-8ddf-305888669ad1"
                    ]
                },
                "GAD-s3a": {
                    "questionId": "GAD-s3a",
                    "chosenOptionIds": [
                        "adab9f0b-f6e7-54c4-9cf1-087cdf831412"
                    ]
                },
                "GAD-s4a": {
                    "questionId": "GAD-s4a",
                    "chosenOptionIds": [
                        "0caeb854-db8e-5a20-a344-0c9282f65f78"
                    ]
                },
                "GAD-s6a": {
                    "questionId": "GAD-s6a",
                    "chosenOptionIds": [
                        "4059602c-013b-5f54-890f-64e55583f3dc"
                    ]
                }
            }
        },
        {
            "name": "Censeo vPrevious user: J0V6N3L aspect: gad_ce",
            "expected": {
                "type": "CloseTo",
                "value": 0.19
            },
            "answers": {
                "GAD-10a": {
                    "questionId": "GAD-10a",
                    "chosenOptionIds": [
                        "a30b13cd-454a-529d-a598-c5192a29668e"
                    ]
                },
                "GAD-11a": {
                    "questionId": "GAD-11a",
                    "chosenOptionIds": [
                        "68c692fb-cc63-5ad6-b88e-5e45ad971c82"
                    ]
                },
                "GAD-12a": {
                    "questionId": "GAD-12a",
                    "chosenOptionIds": [
                        "4fb31d91-3520-5676-90c0-d3c05e9abfb8"
                    ]
                },
                "GAD-13a": {
                    "questionId": "GAD-13a",
                    "chosenOptionIds": [
                        "42da2d35-01a4-50f9-95e5-c7bbc4c8eda7"
                    ]
                },
                "GAD-14a": {
                    "questionId": "GAD-14a",
                    "chosenOptionIds": [
                        "6ac69add-b72f-5784-84a9-a517721463e6"
                    ]
                },
                "GAD-15a": {
                    "questionId": "GAD-15a",
                    "chosenOptionIds": [
                        "dfe08faa-d933-5ebc-b3dd-20cc46202224"
                    ]
                },
                "GAD-17a": {
                    "questionId": "GAD-17a",
                    "chosenOptionIds": [
                        "ca6c7d1d-3395-58b8-a87d-95fba13b4ca3"
                    ]
                },
                "GAD-18a": {
                    "questionId": "GAD-18a",
                    "chosenOptionIds": [
                        "7cb98422-b8c9-5f92-aee6-74cf0192ea01"
                    ]
                },
                "GAD-19.0a": {
                    "questionId": "GAD-19.0a",
                    "chosenOptionIds": [
                        "78df6de1-fe3e-5bed-a63d-f5f9877809af"
                    ]
                },
                "GAD-19a": {
                    "questionId": "GAD-19a",
                    "chosenOptionIds": [
                        "3c648d60-89ec-5e44-9bf1-dc362139d849",
                        "a7dc1041-6095-5630-9480-ed7c33997997",
                        "4ca41a8f-b524-5ef7-af86-7ef9279a7969",
                        "0a747bd1-8eee-563f-a6ae-8823404898a5"
                    ]
                },
                "GAD-22a": {
                    "questionId": "GAD-22a",
                    "chosenOptionIds": [
                        "09fba5ed-bf0e-5aa9-92a5-50c6a01ae0e2"
                    ]
                },
                "GAD-3a": {
                    "questionId": "GAD-3a",
                    "chosenOptionIds": [
                        "bdba5003-2589-507d-bb90-53534cc9b942"
                    ]
                },
                "GAD-4a": {
                    "questionId": "GAD-4a",
                    "chosenOptionIds": [
                        "748f4a0b-00a7-5b15-b390-2dfb8ac9eb1d"
                    ]
                },
                "GAD-5a": {
                    "questionId": "GAD-5a",
                    "chosenOptionIds": [
                        "63fdf718-12b4-5497-abff-2ae6a86b484b"
                    ]
                },
                "GAD-6a": {
                    "questionId": "GAD-6a",
                    "chosenOptionIds": [
                        "7d60ebb2-5f2d-5ab0-b9ca-31210502ece9"
                    ]
                },
                "GAD-7a": {
                    "questionId": "GAD-7a",
                    "chosenOptionIds": [
                        "3b9e5247-4c0f-5c9d-9ffb-822ab95bee8a"
                    ]
                },
                "GAD-8a": {
                    "questionId": "GAD-8a",
                    "chosenOptionIds": [
                        "80887b2c-7c9f-5105-a64a-b3bb33442ac6"
                    ]
                },
                "GAD-9a": {
                    "questionId": "GAD-9a",
                    "chosenOptionIds": [
                        "35150196-067f-58af-b669-6e41443a3601"
                    ]
                },
                "GAD-S": {
                    "questionId": "GAD-S",
                    "chosenOptionIds": [
                        "aa668802-d458-5b2d-b48f-e4058a640295"
                    ]
                },
                "GAD-s1": {
                    "questionId": "GAD-s1",
                    "chosenOptionIds": [
                        "24032a9e-f5b6-5f43-8ddf-305888669ad1"
                    ]
                },
                "GAD-s3a": {
                    "questionId": "GAD-s3a",
                    "chosenOptionIds": [
                        "adab9f0b-f6e7-54c4-9cf1-087cdf831412"
                    ]
                },
                "GAD-s4a": {
                    "questionId": "GAD-s4a",
                    "chosenOptionIds": [
                        "0caeb854-db8e-5a20-a344-0c9282f65f78"
                    ]
                },
                "GAD-s6a": {
                    "questionId": "GAD-s6a",
                    "chosenOptionIds": [
                        "4059602c-013b-5f54-890f-64e55583f3dc"
                    ]
                }
            }
        },
        {
            "name": "Tech team adjusted from 71, Censeo vPrevious user: Z3R680O aspect: gad_ce",
            "expected": {
                "type": "CloseTo",
                "value": 0.4
            },
            "answers": {
                "GAD-10a": {
                    "questionId": "GAD-10a",
                    "chosenOptionIds": [
                        "1632140d-fefe-50b3-b11f-3ae261143e25"
                    ]
                },
                "GAD-11a": {
                    "questionId": "GAD-11a",
                    "chosenOptionIds": [
                        "5779c477-4f6c-533d-a15b-33f106f84348"
                    ]
                },
                "GAD-12a": {
                    "questionId": "GAD-12a",
                    "chosenOptionIds": [
                        "c74d8b74-1128-5f73-bfd2-2c3813bfdd4e"
                    ]
                },
                "GAD-13a": {
                    "questionId": "GAD-13a",
                    "chosenOptionIds": [
                        "8dda5767-c89f-5932-808d-a7729c3f935b"
                    ]
                },
                "GAD-14a": {
                    "questionId": "GAD-14a",
                    "chosenOptionIds": [
                        "0623617f-3483-5ad7-ba3a-dd6b95c269d5"
                    ]
                },
                "GAD-15a": {
                    "questionId": "GAD-15a",
                    "chosenOptionIds": [
                        "6447e557-156f-59a1-9cbc-cd9d4b9bcbee"
                    ]
                },
                "GAD-17a": {
                    "questionId": "GAD-17a",
                    "chosenOptionIds": [
                        "f468532b-0dfb-546a-87bc-4840ffcab3e0"
                    ]
                },
                "GAD-18a": {
                    "questionId": "GAD-18a",
                    "chosenOptionIds": [
                        "af347157-5a07-5187-b207-b672ecf979dc"
                    ]
                },
                "GAD-19.0a": {
                    "questionId": "GAD-19.0a",
                    "chosenOptionIds": [
                        "a0520f66-594b-5c56-bebc-a0d018d139d9"
                    ]
                },
                "GAD-19a": {
                    "questionId": "GAD-19a",
                    "chosenOptionIds": [
                        "51801ae8-62d1-5a94-b757-a0b0851ac6bc",
                        "3c648d60-89ec-5e44-9bf1-dc362139d849",
                        "863da48d-f2eb-52f4-8c4e-feda60cda5c4",
                        "1acf1647-150f-5031-9599-8db8e23c423d",
                        "67df9ae6-c783-5f09-9975-a117ab653011",
                        "0a747bd1-8eee-563f-a6ae-8823404898a5"
                    ]
                },
                "GAD-22a": {
                    "questionId": "GAD-22a",
                    "chosenOptionIds": [
                        "564b4697-78ea-5000-bb19-e1ed63265906"
                    ]
                },
                "GAD-3a": {
                    "questionId": "GAD-3a",
                    "chosenOptionIds": [
                        "bdba5003-2589-507d-bb90-53534cc9b942"
                    ]
                },
                "GAD-4a": {
                    "questionId": "GAD-4a",
                    "chosenOptionIds": [
                        "517a9806-ffe8-52a9-86df-e947a47e169c"
                    ]
                },
                "GAD-5a": {
                    "questionId": "GAD-5a",
                    "chosenOptionIds": [
                        "63fdf718-12b4-5497-abff-2ae6a86b484b"
                    ]
                },
                "GAD-6a": {
                    "questionId": "GAD-6a",
                    "chosenOptionIds": [
                        "7d60ebb2-5f2d-5ab0-b9ca-31210502ece9"
                    ]
                },
                "GAD-7a": {
                    "questionId": "GAD-7a",
                    "chosenOptionIds": [
                        "80dffd87-d3ca-5977-9aee-b90eebcc4eb1"
                    ]
                },
                "GAD-8a": {
                    "questionId": "GAD-8a",
                    "chosenOptionIds": [
                        "985dc1a3-7ea9-5f5c-8af3-6159ac9c90bf"
                    ]
                },
                "GAD-9a": {
                    "questionId": "GAD-9a",
                    "chosenOptionIds": [
                        "b41e2b30-b377-5738-9ebe-8b9a7b6c35b7"
                    ]
                },
                "GAD-S": {
                    "questionId": "GAD-S",
                    "chosenOptionIds": [
                        "aa668802-d458-5b2d-b48f-e4058a640295"
                    ]
                },
                "GAD-s1": {
                    "questionId": "GAD-s1",
                    "chosenOptionIds": [
                        "0a2185e8-e4b7-5f48-a3ff-2fa717d647dd"
                    ]
                },
                "GAD-s3a": {
                    "questionId": "GAD-s3a",
                    "chosenOptionIds": [
                        "adab9f0b-f6e7-54c4-9cf1-087cdf831412"
                    ]
                },
                "GAD-s4a": {
                    "questionId": "GAD-s4a",
                    "chosenOptionIds": [
                        "5a1c5af4-b628-5554-bf63-4ca68ef2b328"
                    ]
                },
                "GAD-s6a": {
                    "questionId": "GAD-s6a",
                    "chosenOptionIds": [
                        "4059602c-013b-5f54-890f-64e55583f3dc"
                    ]
                }
            }
        },
        {
            "name": "Clinical Diagnosis user: R047J04 aspect: gad_ce ",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "GAD-10a": {
                    "questionId": "GAD-10a",
                    "chosenOptionIds": [
                        "0988ae4f-c9f2-5d44-a2cc-ecb83a2ec620"
                    ]
                },
                "GAD-11a": {
                    "questionId": "GAD-11a",
                    "chosenOptionIds": [
                        "62411dbb-3d28-57dc-ade4-d19a340fc6ed"
                    ]
                },
                "GAD-12a": {
                    "questionId": "GAD-12a",
                    "chosenOptionIds": [
                        "c74d8b74-1128-5f73-bfd2-2c3813bfdd4e"
                    ]
                },
                "GAD-13a": {
                    "questionId": "GAD-13a",
                    "chosenOptionIds": [
                        "8dda5767-c89f-5932-808d-a7729c3f935b"
                    ]
                },
                "GAD-14a": {
                    "questionId": "GAD-14a",
                    "chosenOptionIds": [
                        "b0335c5e-d91f-5e19-9a45-4936bd9e3bea"
                    ]
                },
                "GAD-15a": {
                    "questionId": "GAD-15a",
                    "chosenOptionIds": [
                        "632e07d1-df69-590c-a8f2-0b4f7ad343e1"
                    ]
                },
                "GAD-17a": {
                    "questionId": "GAD-17a",
                    "chosenOptionIds": [
                        "93c614a5-158b-54bb-b179-2d356411ab81"
                    ]
                },
                "GAD-18a": {
                    "questionId": "GAD-18a",
                    "chosenOptionIds": [
                        "63b22d31-0933-571a-9c46-eb63c6373884"
                    ]
                },
                "GAD-19.0a": {
                    "questionId": "GAD-19.0a",
                    "chosenOptionIds": [
                        "78df6de1-fe3e-5bed-a63d-f5f9877809af"
                    ]
                },
                "GAD-19a": {
                    "questionId": "GAD-19a",
                    "chosenOptionIds": [
                        "a7dc1041-6095-5630-9480-ed7c33997997",
                        "3c648d60-89ec-5e44-9bf1-dc362139d849",
                        "0a747bd1-8eee-563f-a6ae-8823404898a5",
                        "4ca41a8f-b524-5ef7-af86-7ef9279a7969"
                    ]
                },
                "GAD-22a": {
                    "questionId": "GAD-22a",
                    "chosenOptionIds": [
                        "09fba5ed-bf0e-5aa9-92a5-50c6a01ae0e2"
                    ]
                },
                "GAD-3a": {
                    "questionId": "GAD-3a",
                    "chosenOptionIds": [
                        "bdba5003-2589-507d-bb90-53534cc9b942"
                    ]
                },
                "GAD-4a": {
                    "questionId": "GAD-4a",
                    "chosenOptionIds": [
                        "517a9806-ffe8-52a9-86df-e947a47e169c"
                    ]
                },
                "GAD-5a": {
                    "questionId": "GAD-5a",
                    "chosenOptionIds": [
                        "6bb8be71-d051-540f-8d81-5dff1869bbcc"
                    ]
                },
                "GAD-6a": {
                    "questionId": "GAD-6a",
                    "chosenOptionIds": [
                        "f26a3508-c284-53a5-8bc7-63514fbe6737"
                    ]
                },
                "GAD-7a": {
                    "questionId": "GAD-7a",
                    "chosenOptionIds": [
                        "d7d05c32-44cb-59c7-84b0-95e98fde6077"
                    ]
                },
                "GAD-8a": {
                    "questionId": "GAD-8a",
                    "chosenOptionIds": [
                        "a7ecd81c-cd6a-5592-892f-8e391b9a115c"
                    ]
                },
                "GAD-9a": {
                    "questionId": "GAD-9a",
                    "chosenOptionIds": [
                        "cbcfc13e-979a-5e51-8698-f4d686fb5c09"
                    ]
                },
                "GAD-S": {
                    "questionId": "GAD-S",
                    "chosenOptionIds": [
                        "aa668802-d458-5b2d-b48f-e4058a640295"
                    ]
                },
                "GAD-s1": {
                    "questionId": "GAD-s1",
                    "chosenOptionIds": [
                        "c819bfe0-f79d-5132-8b3a-8604c0c5b83a"
                    ]
                },
                "GAD-s3a": {
                    "questionId": "GAD-s3a",
                    "chosenOptionIds": [
                        "adab9f0b-f6e7-54c4-9cf1-087cdf831412"
                    ]
                },
                "GAD-s4a": {
                    "questionId": "GAD-s4a",
                    "chosenOptionIds": [
                        "5a1c5af4-b628-5554-bf63-4ca68ef2b328"
                    ]
                },
                "GAD-s6a": {
                    "questionId": "GAD-s6a",
                    "chosenOptionIds": [
                        "6e2595a2-ea1e-5ca5-ab11-c65eb6cb6157"
                    ]
                }
            }
        },
        {
            "name": "Censeo vPrevious user: R047J04 aspect: gad_ce",
            "expected": {
                "type": "CloseTo",
                "value": 0.77
            },
            "answers": {
                "GAD-10a": {
                    "questionId": "GAD-10a",
                    "chosenOptionIds": [
                        "0988ae4f-c9f2-5d44-a2cc-ecb83a2ec620"
                    ]
                },
                "GAD-11a": {
                    "questionId": "GAD-11a",
                    "chosenOptionIds": [
                        "62411dbb-3d28-57dc-ade4-d19a340fc6ed"
                    ]
                },
                "GAD-12a": {
                    "questionId": "GAD-12a",
                    "chosenOptionIds": [
                        "c74d8b74-1128-5f73-bfd2-2c3813bfdd4e"
                    ]
                },
                "GAD-13a": {
                    "questionId": "GAD-13a",
                    "chosenOptionIds": [
                        "8dda5767-c89f-5932-808d-a7729c3f935b"
                    ]
                },
                "GAD-14a": {
                    "questionId": "GAD-14a",
                    "chosenOptionIds": [
                        "b0335c5e-d91f-5e19-9a45-4936bd9e3bea"
                    ]
                },
                "GAD-15a": {
                    "questionId": "GAD-15a",
                    "chosenOptionIds": [
                        "632e07d1-df69-590c-a8f2-0b4f7ad343e1"
                    ]
                },
                "GAD-17a": {
                    "questionId": "GAD-17a",
                    "chosenOptionIds": [
                        "93c614a5-158b-54bb-b179-2d356411ab81"
                    ]
                },
                "GAD-18a": {
                    "questionId": "GAD-18a",
                    "chosenOptionIds": [
                        "63b22d31-0933-571a-9c46-eb63c6373884"
                    ]
                },
                "GAD-19.0a": {
                    "questionId": "GAD-19.0a",
                    "chosenOptionIds": [
                        "78df6de1-fe3e-5bed-a63d-f5f9877809af"
                    ]
                },
                "GAD-19a": {
                    "questionId": "GAD-19a",
                    "chosenOptionIds": [
                        "a7dc1041-6095-5630-9480-ed7c33997997",
                        "3c648d60-89ec-5e44-9bf1-dc362139d849",
                        "0a747bd1-8eee-563f-a6ae-8823404898a5",
                        "4ca41a8f-b524-5ef7-af86-7ef9279a7969"
                    ]
                },
                "GAD-22a": {
                    "questionId": "GAD-22a",
                    "chosenOptionIds": [
                        "09fba5ed-bf0e-5aa9-92a5-50c6a01ae0e2"
                    ]
                },
                "GAD-3a": {
                    "questionId": "GAD-3a",
                    "chosenOptionIds": [
                        "bdba5003-2589-507d-bb90-53534cc9b942"
                    ]
                },
                "GAD-4a": {
                    "questionId": "GAD-4a",
                    "chosenOptionIds": [
                        "517a9806-ffe8-52a9-86df-e947a47e169c"
                    ]
                },
                "GAD-5a": {
                    "questionId": "GAD-5a",
                    "chosenOptionIds": [
                        "6bb8be71-d051-540f-8d81-5dff1869bbcc"
                    ]
                },
                "GAD-6a": {
                    "questionId": "GAD-6a",
                    "chosenOptionIds": [
                        "f26a3508-c284-53a5-8bc7-63514fbe6737"
                    ]
                },
                "GAD-7a": {
                    "questionId": "GAD-7a",
                    "chosenOptionIds": [
                        "d7d05c32-44cb-59c7-84b0-95e98fde6077"
                    ]
                },
                "GAD-8a": {
                    "questionId": "GAD-8a",
                    "chosenOptionIds": [
                        "a7ecd81c-cd6a-5592-892f-8e391b9a115c"
                    ]
                },
                "GAD-9a": {
                    "questionId": "GAD-9a",
                    "chosenOptionIds": [
                        "cbcfc13e-979a-5e51-8698-f4d686fb5c09"
                    ]
                },
                "GAD-S": {
                    "questionId": "GAD-S",
                    "chosenOptionIds": [
                        "aa668802-d458-5b2d-b48f-e4058a640295"
                    ]
                },
                "GAD-s1": {
                    "questionId": "GAD-s1",
                    "chosenOptionIds": [
                        "c819bfe0-f79d-5132-8b3a-8604c0c5b83a"
                    ]
                },
                "GAD-s3a": {
                    "questionId": "GAD-s3a",
                    "chosenOptionIds": [
                        "adab9f0b-f6e7-54c4-9cf1-087cdf831412"
                    ]
                },
                "GAD-s4a": {
                    "questionId": "GAD-s4a",
                    "chosenOptionIds": [
                        "5a1c5af4-b628-5554-bf63-4ca68ef2b328"
                    ]
                },
                "GAD-s6a": {
                    "questionId": "GAD-s6a",
                    "chosenOptionIds": [
                        "6e2595a2-ea1e-5ca5-ab11-c65eb6cb6157"
                    ]
                }
            }
        },
        {
            "name": "Clinical Diagnosis user: Z3RLO3O aspect: gad_ce ",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "GAD-10a": {
                    "questionId": "GAD-10a",
                    "chosenOptionIds": [
                        "44ddd9b1-23aa-570e-bbce-1bef374fe68a"
                    ]
                },
                "GAD-11a": {
                    "questionId": "GAD-11a",
                    "chosenOptionIds": [
                        "62411dbb-3d28-57dc-ade4-d19a340fc6ed"
                    ]
                },
                "GAD-12a": {
                    "questionId": "GAD-12a",
                    "chosenOptionIds": [
                        "4fb31d91-3520-5676-90c0-d3c05e9abfb8"
                    ]
                },
                "GAD-13a": {
                    "questionId": "GAD-13a",
                    "chosenOptionIds": [
                        "8dda5767-c89f-5932-808d-a7729c3f935b"
                    ]
                },
                "GAD-14a": {
                    "questionId": "GAD-14a",
                    "chosenOptionIds": [
                        "b0335c5e-d91f-5e19-9a45-4936bd9e3bea"
                    ]
                },
                "GAD-15a": {
                    "questionId": "GAD-15a",
                    "chosenOptionIds": [
                        "dfe08faa-d933-5ebc-b3dd-20cc46202224"
                    ]
                },
                "GAD-17a": {
                    "questionId": "GAD-17a",
                    "chosenOptionIds": [
                        "ca6c7d1d-3395-58b8-a87d-95fba13b4ca3"
                    ]
                },
                "GAD-18a": {
                    "questionId": "GAD-18a",
                    "chosenOptionIds": [
                        "af347157-5a07-5187-b207-b672ecf979dc"
                    ]
                },
                "GAD-19.0a": {
                    "questionId": "GAD-19.0a",
                    "chosenOptionIds": [
                        "78df6de1-fe3e-5bed-a63d-f5f9877809af"
                    ]
                },
                "GAD-19a": {
                    "questionId": "GAD-19a",
                    "chosenOptionIds": [
                        "51801ae8-62d1-5a94-b757-a0b0851ac6bc",
                        "a7dc1041-6095-5630-9480-ed7c33997997",
                        "3c648d60-89ec-5e44-9bf1-dc362139d849",
                        "863da48d-f2eb-52f4-8c4e-feda60cda5c4",
                        "1acf1647-150f-5031-9599-8db8e23c423d",
                        "4ca41a8f-b524-5ef7-af86-7ef9279a7969",
                        "67df9ae6-c783-5f09-9975-a117ab653011",
                        "0a747bd1-8eee-563f-a6ae-8823404898a5"
                    ]
                },
                "GAD-22a": {
                    "questionId": "GAD-22a",
                    "chosenOptionIds": [
                        "8b112b00-59f5-5465-a3c7-d3eed9586abf"
                    ]
                },
                "GAD-3a": {
                    "questionId": "GAD-3a",
                    "chosenOptionIds": [
                        "bdba5003-2589-507d-bb90-53534cc9b942"
                    ]
                },
                "GAD-4a": {
                    "questionId": "GAD-4a",
                    "chosenOptionIds": [
                        "517a9806-ffe8-52a9-86df-e947a47e169c"
                    ]
                },
                "GAD-5a": {
                    "questionId": "GAD-5a",
                    "chosenOptionIds": [
                        "63fdf718-12b4-5497-abff-2ae6a86b484b"
                    ]
                },
                "GAD-6a": {
                    "questionId": "GAD-6a",
                    "chosenOptionIds": [
                        "f26a3508-c284-53a5-8bc7-63514fbe6737"
                    ]
                },
                "GAD-7a": {
                    "questionId": "GAD-7a",
                    "chosenOptionIds": [
                        "d7d05c32-44cb-59c7-84b0-95e98fde6077"
                    ]
                },
                "GAD-8a": {
                    "questionId": "GAD-8a",
                    "chosenOptionIds": [
                        "ce522c31-e5ef-51e2-8fa5-d9826566ed1e"
                    ]
                },
                "GAD-9a": {
                    "questionId": "GAD-9a",
                    "chosenOptionIds": [
                        "b41e2b30-b377-5738-9ebe-8b9a7b6c35b7"
                    ]
                },
                "GAD-S": {
                    "questionId": "GAD-S",
                    "chosenOptionIds": [
                        "aa668802-d458-5b2d-b48f-e4058a640295"
                    ]
                },
                "GAD-s1": {
                    "questionId": "GAD-s1",
                    "chosenOptionIds": [
                        "24032a9e-f5b6-5f43-8ddf-305888669ad1"
                    ]
                },
                "GAD-s3a": {
                    "questionId": "GAD-s3a",
                    "chosenOptionIds": [
                        "ed0ad572-166e-587e-b191-2cbe81387f5a"
                    ]
                },
                "GAD-s4a": {
                    "questionId": "GAD-s4a",
                    "chosenOptionIds": [
                        "0caeb854-db8e-5a20-a344-0c9282f65f78"
                    ]
                },
                "GAD-s6a": {
                    "questionId": "GAD-s6a",
                    "chosenOptionIds": [
                        "cc1895bc-b31f-503e-a147-172d1a0faa64"
                    ]
                }
            }
        },
        {
            "name": "Censeo vPrevious user: Z3RLO3O aspect: gad_ce",
            "expected": {
                "type": "CloseTo",
                "value": 0.75
            },
            "answers": {
                "GAD-10a": {
                    "questionId": "GAD-10a",
                    "chosenOptionIds": [
                        "44ddd9b1-23aa-570e-bbce-1bef374fe68a"
                    ]
                },
                "GAD-11a": {
                    "questionId": "GAD-11a",
                    "chosenOptionIds": [
                        "62411dbb-3d28-57dc-ade4-d19a340fc6ed"
                    ]
                },
                "GAD-12a": {
                    "questionId": "GAD-12a",
                    "chosenOptionIds": [
                        "4fb31d91-3520-5676-90c0-d3c05e9abfb8"
                    ]
                },
                "GAD-13a": {
                    "questionId": "GAD-13a",
                    "chosenOptionIds": [
                        "8dda5767-c89f-5932-808d-a7729c3f935b"
                    ]
                },
                "GAD-14a": {
                    "questionId": "GAD-14a",
                    "chosenOptionIds": [
                        "b0335c5e-d91f-5e19-9a45-4936bd9e3bea"
                    ]
                },
                "GAD-15a": {
                    "questionId": "GAD-15a",
                    "chosenOptionIds": [
                        "dfe08faa-d933-5ebc-b3dd-20cc46202224"
                    ]
                },
                "GAD-17a": {
                    "questionId": "GAD-17a",
                    "chosenOptionIds": [
                        "ca6c7d1d-3395-58b8-a87d-95fba13b4ca3"
                    ]
                },
                "GAD-18a": {
                    "questionId": "GAD-18a",
                    "chosenOptionIds": [
                        "af347157-5a07-5187-b207-b672ecf979dc"
                    ]
                },
                "GAD-19.0a": {
                    "questionId": "GAD-19.0a",
                    "chosenOptionIds": [
                        "78df6de1-fe3e-5bed-a63d-f5f9877809af"
                    ]
                },
                "GAD-19a": {
                    "questionId": "GAD-19a",
                    "chosenOptionIds": [
                        "51801ae8-62d1-5a94-b757-a0b0851ac6bc",
                        "a7dc1041-6095-5630-9480-ed7c33997997",
                        "3c648d60-89ec-5e44-9bf1-dc362139d849",
                        "863da48d-f2eb-52f4-8c4e-feda60cda5c4",
                        "1acf1647-150f-5031-9599-8db8e23c423d",
                        "4ca41a8f-b524-5ef7-af86-7ef9279a7969",
                        "67df9ae6-c783-5f09-9975-a117ab653011",
                        "0a747bd1-8eee-563f-a6ae-8823404898a5"
                    ]
                },
                "GAD-22a": {
                    "questionId": "GAD-22a",
                    "chosenOptionIds": [
                        "8b112b00-59f5-5465-a3c7-d3eed9586abf"
                    ]
                },
                "GAD-3a": {
                    "questionId": "GAD-3a",
                    "chosenOptionIds": [
                        "bdba5003-2589-507d-bb90-53534cc9b942"
                    ]
                },
                "GAD-4a": {
                    "questionId": "GAD-4a",
                    "chosenOptionIds": [
                        "517a9806-ffe8-52a9-86df-e947a47e169c"
                    ]
                },
                "GAD-5a": {
                    "questionId": "GAD-5a",
                    "chosenOptionIds": [
                        "63fdf718-12b4-5497-abff-2ae6a86b484b"
                    ]
                },
                "GAD-6a": {
                    "questionId": "GAD-6a",
                    "chosenOptionIds": [
                        "f26a3508-c284-53a5-8bc7-63514fbe6737"
                    ]
                },
                "GAD-7a": {
                    "questionId": "GAD-7a",
                    "chosenOptionIds": [
                        "d7d05c32-44cb-59c7-84b0-95e98fde6077"
                    ]
                },
                "GAD-8a": {
                    "questionId": "GAD-8a",
                    "chosenOptionIds": [
                        "ce522c31-e5ef-51e2-8fa5-d9826566ed1e"
                    ]
                },
                "GAD-9a": {
                    "questionId": "GAD-9a",
                    "chosenOptionIds": [
                        "b41e2b30-b377-5738-9ebe-8b9a7b6c35b7"
                    ]
                },
                "GAD-S": {
                    "questionId": "GAD-S",
                    "chosenOptionIds": [
                        "aa668802-d458-5b2d-b48f-e4058a640295"
                    ]
                },
                "GAD-s1": {
                    "questionId": "GAD-s1",
                    "chosenOptionIds": [
                        "24032a9e-f5b6-5f43-8ddf-305888669ad1"
                    ]
                },
                "GAD-s3a": {
                    "questionId": "GAD-s3a",
                    "chosenOptionIds": [
                        "ed0ad572-166e-587e-b191-2cbe81387f5a"
                    ]
                },
                "GAD-s4a": {
                    "questionId": "GAD-s4a",
                    "chosenOptionIds": [
                        "0caeb854-db8e-5a20-a344-0c9282f65f78"
                    ]
                },
                "GAD-s6a": {
                    "questionId": "GAD-s6a",
                    "chosenOptionIds": [
                        "cc1895bc-b31f-503e-a147-172d1a0faa64"
                    ]
                }
            }
        },
        {
            "name": "11_GAD_severity1",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "GAD-S": {
                    "questionId": "GAD-S",
                    "chosenOptionIds": [
                        "aa668802-d458-5b2d-b48f-e4058a640295"
                    ]
                },
                "GAD-s1": {
                    "questionId": "GAD-s1",
                    "chosenOptionIds": [
                        "eaaa8028-c116-5ae3-bb59-5795820feca8"
                    ]
                }
            }
        },
        {
            "name": "11_GAD_severity2",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "GAD-S": {
                    "questionId": "GAD-S",
                    "chosenOptionIds": [
                        "aa668802-d458-5b2d-b48f-e4058a640295"
                    ]
                },
                "GAD-s1": {
                    "questionId": "GAD-s1",
                    "chosenOptionIds": [
                        "f8d49124-01dc-5d55-add9-5ed87d998e00"
                    ]
                },
                "GAD-s3a": {
                    "questionId": "GAD-s3a",
                    "chosenOptionIds": [
                        "4bbc59ac-1581-5084-9e92-2b746637ff52"
                    ]
                },
                "GAD-s4a": {
                    "questionId": "GAD-s4a",
                    "chosenOptionIds": [
                        "276f0539-3b02-5717-a333-3d5ac219a5b4"
                    ]
                },
                "GAD-s6a": {
                    "questionId": "GAD-s6a",
                    "chosenOptionIds": [
                        "6e2595a2-ea1e-5ca5-ab11-c65eb6cb6157"
                    ]
                },
                "GAD-3a": {
                    "questionId": "GAD-3a",
                    "chosenOptionIds": [
                        "fa80af33-1ed9-5e02-abd3-85852c65204e"
                    ]
                },
                "GAD-4a": {
                    "questionId": "GAD-4a",
                    "chosenOptionIds": [
                        "517a9806-ffe8-52a9-86df-e947a47e169c"
                    ]
                },
                "GAD-5a": {
                    "questionId": "GAD-5a",
                    "chosenOptionIds": [
                        "bd66ed3d-4e63-5fe8-88a6-fbbf3ab55502"
                    ]
                },
                "GAD-6a": {
                    "questionId": "GAD-6a",
                    "chosenOptionIds": [
                        "f26a3508-c284-53a5-8bc7-63514fbe6737"
                    ]
                },
                "GAD-7a": {
                    "questionId": "GAD-7a",
                    "chosenOptionIds": [
                        "d7d05c32-44cb-59c7-84b0-95e98fde6077"
                    ]
                },
                "GAD-8a": {
                    "questionId": "GAD-8a",
                    "chosenOptionIds": [
                        "e1dd4f50-28b4-51eb-8c34-bf8d3145816f"
                    ]
                },
                "GAD-9a": {
                    "questionId": "GAD-9a",
                    "chosenOptionIds": [
                        "03b91bf0-49f5-5760-ae70-8e92ca65aa0e"
                    ]
                },
                "GAD-10a": {
                    "questionId": "GAD-10a",
                    "chosenOptionIds": [
                        "e16d33eb-317e-55aa-90fc-147bde51e99b"
                    ]
                },
                "GAD-11a": {
                    "questionId": "GAD-11a",
                    "chosenOptionIds": [
                        "ba121413-fa3c-5d39-960f-827d7b0d55b8"
                    ]
                },
                "GAD-12a": {
                    "questionId": "GAD-12a",
                    "chosenOptionIds": [
                        "c74d8b74-1128-5f73-bfd2-2c3813bfdd4e"
                    ]
                },
                "GAD-13a": {
                    "questionId": "GAD-13a",
                    "chosenOptionIds": [
                        "8dda5767-c89f-5932-808d-a7729c3f935b"
                    ]
                },
                "GAD-14a": {
                    "questionId": "GAD-14a",
                    "chosenOptionIds": [
                        "6ac69add-b72f-5784-84a9-a517721463e6"
                    ]
                },
                "GAD-15a": {
                    "questionId": "GAD-15a",
                    "chosenOptionIds": [
                        "632e07d1-df69-590c-a8f2-0b4f7ad343e1"
                    ]
                },
                "GAD-17a": {
                    "questionId": "GAD-17a",
                    "chosenOptionIds": [
                        "93c614a5-158b-54bb-b179-2d356411ab81"
                    ]
                },
                "GAD-18a": {
                    "questionId": "GAD-18a",
                    "chosenOptionIds": [
                        "af347157-5a07-5187-b207-b672ecf979dc"
                    ]
                },
                "GAD-19.0a": {
                    "questionId": "GAD-19.0a",
                    "chosenOptionIds": [
                        "78df6de1-fe3e-5bed-a63d-f5f9877809af"
                    ]
                },
                "GAD-19a": {
                    "questionId": "GAD-19a",
                    "chosenOptionIds": [
                        "3c648d60-89ec-5e44-9bf1-dc362139d849",
                        "863da48d-f2eb-52f4-8c4e-feda60cda5c4",
                        "67df9ae6-c783-5f09-9975-a117ab653011"
                    ]
                },
                "GAD-22a": {
                    "questionId": "GAD-22a",
                    "chosenOptionIds": [
                        "09fba5ed-bf0e-5aa9-92a5-50c6a01ae0e2"
                    ]
                }
            }
        },
        {
            "name": "11_GAD_severity6current",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "GAD-S": {
                    "questionId": "GAD-S",
                    "chosenOptionIds": [
                        "aa668802-d458-5b2d-b48f-e4058a640295"
                    ]
                },
                "GAD-s1": {
                    "questionId": "GAD-s1",
                    "chosenOptionIds": [
                        "f8d49124-01dc-5d55-add9-5ed87d998e00"
                    ]
                },
                "GAD-s3a": {
                    "questionId": "GAD-s3a",
                    "chosenOptionIds": [
                        "adab9f0b-f6e7-54c4-9cf1-087cdf831412"
                    ]
                },
                "GAD-s4a": {
                    "questionId": "GAD-s4a",
                    "chosenOptionIds": [
                        "9d0741b6-d8b6-5216-90d0-df6743af1aa0"
                    ]
                },
                "GAD-s6a": {
                    "questionId": "GAD-s6a",
                    "chosenOptionIds": [
                        "6e2595a2-ea1e-5ca5-ab11-c65eb6cb6157"
                    ]
                },
                "GAD-3a": {
                    "questionId": "GAD-3a",
                    "chosenOptionIds": [
                        "fa80af33-1ed9-5e02-abd3-85852c65204e"
                    ]
                },
                "GAD-4a": {
                    "questionId": "GAD-4a",
                    "chosenOptionIds": [
                        "517a9806-ffe8-52a9-86df-e947a47e169c"
                    ]
                },
                "GAD-5a": {
                    "questionId": "GAD-5a",
                    "chosenOptionIds": [
                        "bd66ed3d-4e63-5fe8-88a6-fbbf3ab55502"
                    ]
                },
                "GAD-6a": {
                    "questionId": "GAD-6a",
                    "chosenOptionIds": [
                        "8996813a-e012-57cb-8eab-b6926c27eba2"
                    ]
                },
                "GAD-7a": {
                    "questionId": "GAD-7a",
                    "chosenOptionIds": [
                        "d7d05c32-44cb-59c7-84b0-95e98fde6077"
                    ]
                },
                "GAD-8a": {
                    "questionId": "GAD-8a",
                    "chosenOptionIds": [
                        "985dc1a3-7ea9-5f5c-8af3-6159ac9c90bf"
                    ]
                },
                "GAD-9a": {
                    "questionId": "GAD-9a",
                    "chosenOptionIds": [
                        "03b91bf0-49f5-5760-ae70-8e92ca65aa0e"
                    ]
                },
                "GAD-10a": {
                    "questionId": "GAD-10a",
                    "chosenOptionIds": [
                        "e16d33eb-317e-55aa-90fc-147bde51e99b"
                    ]
                },
                "GAD-11a": {
                    "questionId": "GAD-11a",
                    "chosenOptionIds": [
                        "ba121413-fa3c-5d39-960f-827d7b0d55b8"
                    ]
                },
                "GAD-12a": {
                    "questionId": "GAD-12a",
                    "chosenOptionIds": [
                        "c74d8b74-1128-5f73-bfd2-2c3813bfdd4e"
                    ]
                },
                "GAD-13a": {
                    "questionId": "GAD-13a",
                    "chosenOptionIds": [
                        "40abb98e-db00-5505-85be-59a5dd4ef092"
                    ]
                },
                "GAD-14a": {
                    "questionId": "GAD-14a",
                    "chosenOptionIds": [
                        "0623617f-3483-5ad7-ba3a-dd6b95c269d5"
                    ]
                },
                "GAD-15a": {
                    "questionId": "GAD-15a",
                    "chosenOptionIds": [
                        "39f33fe3-3b5e-551a-8902-6a8c7ab4c148"
                    ]
                },
                "GAD-17a": {
                    "questionId": "GAD-17a",
                    "chosenOptionIds": [
                        "93c614a5-158b-54bb-b179-2d356411ab81"
                    ]
                },
                "GAD-18a": {
                    "questionId": "GAD-18a",
                    "chosenOptionIds": [
                        "af347157-5a07-5187-b207-b672ecf979dc"
                    ]
                },
                "GAD-19.0a": {
                    "questionId": "GAD-19.0a",
                    "chosenOptionIds": [
                        "1341da8c-ffc8-5e54-9f02-4b2126601994"
                    ]
                },
                "GAD-19a": {
                    "questionId": "GAD-19a",
                    "chosenOptionIds": [
                        "a7dc1041-6095-5630-9480-ed7c33997997",
                        "3c648d60-89ec-5e44-9bf1-dc362139d849",
                        "0a747bd1-8eee-563f-a6ae-8823404898a5",
                        "4ca41a8f-b524-5ef7-af86-7ef9279a7969"
                    ]
                },
                "GAD-22a": {
                    "questionId": "GAD-22a",
                    "chosenOptionIds": [
                        "09fba5ed-bf0e-5aa9-92a5-50c6a01ae0e2"
                    ]
                }
            }
        },
        {
            "name": "11_GAD_severity7current",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "GAD-S": {
                    "questionId": "GAD-S",
                    "chosenOptionIds": [
                        "aa668802-d458-5b2d-b48f-e4058a640295"
                    ]
                },
                "GAD-s1": {
                    "questionId": "GAD-s1",
                    "chosenOptionIds": [
                        "c819bfe0-f79d-5132-8b3a-8604c0c5b83a"
                    ]
                },
                "GAD-s3a": {
                    "questionId": "GAD-s3a",
                    "chosenOptionIds": [
                        "1943ebd6-08ab-5b8e-a57b-7e397d303526"
                    ]
                },
                "GAD-s4a": {
                    "questionId": "GAD-s4a",
                    "chosenOptionIds": [
                        "9d0741b6-d8b6-5216-90d0-df6743af1aa0"
                    ]
                },
                "GAD-s6a": {
                    "questionId": "GAD-s6a",
                    "chosenOptionIds": [
                        "6e2595a2-ea1e-5ca5-ab11-c65eb6cb6157"
                    ]
                },
                "GAD-3a": {
                    "questionId": "GAD-3a",
                    "chosenOptionIds": [
                        "bdba5003-2589-507d-bb90-53534cc9b942"
                    ]
                },
                "GAD-4a": {
                    "questionId": "GAD-4a",
                    "chosenOptionIds": [
                        "84762e8e-edca-5c2c-a00f-fed69c13b87c"
                    ]
                },
                "GAD-5a": {
                    "questionId": "GAD-5a",
                    "chosenOptionIds": [
                        "59b5a96e-b0f6-5fd3-8c20-57c053f14b06"
                    ]
                },
                "GAD-6a": {
                    "questionId": "GAD-6a",
                    "chosenOptionIds": [
                        "8996813a-e012-57cb-8eab-b6926c27eba2"
                    ]
                },
                "GAD-7a": {
                    "questionId": "GAD-7a",
                    "chosenOptionIds": [
                        "80dffd87-d3ca-5977-9aee-b90eebcc4eb1"
                    ]
                },
                "GAD-8a": {
                    "questionId": "GAD-8a",
                    "chosenOptionIds": [
                        "a7ecd81c-cd6a-5592-892f-8e391b9a115c"
                    ]
                },
                "GAD-9a": {
                    "questionId": "GAD-9a",
                    "chosenOptionIds": [
                        "b7c86f29-540d-56d7-a59a-7035d91396d8"
                    ]
                },
                "GAD-10a": {
                    "questionId": "GAD-10a",
                    "chosenOptionIds": [
                        "e16d33eb-317e-55aa-90fc-147bde51e99b"
                    ]
                },
                "GAD-11a": {
                    "questionId": "GAD-11a",
                    "chosenOptionIds": [
                        "62411dbb-3d28-57dc-ade4-d19a340fc6ed"
                    ]
                },
                "GAD-12a": {
                    "questionId": "GAD-12a",
                    "chosenOptionIds": [
                        "75847021-68a5-5e54-938f-ebae91570246"
                    ]
                },
                "GAD-13a": {
                    "questionId": "GAD-13a",
                    "chosenOptionIds": [
                        "40abb98e-db00-5505-85be-59a5dd4ef092"
                    ]
                },
                "GAD-14a": {
                    "questionId": "GAD-14a",
                    "chosenOptionIds": [
                        "0623617f-3483-5ad7-ba3a-dd6b95c269d5"
                    ]
                },
                "GAD-15a": {
                    "questionId": "GAD-15a",
                    "chosenOptionIds": [
                        "39f33fe3-3b5e-551a-8902-6a8c7ab4c148"
                    ]
                },
                "GAD-17a": {
                    "questionId": "GAD-17a",
                    "chosenOptionIds": [
                        "93c614a5-158b-54bb-b179-2d356411ab81"
                    ]
                },
                "GAD-18a": {
                    "questionId": "GAD-18a",
                    "chosenOptionIds": [
                        "7035a10f-7dd7-55bd-946c-2cc73ffa5e6e"
                    ]
                },
                "GAD-19.0a": {
                    "questionId": "GAD-19.0a",
                    "chosenOptionIds": [
                        "1341da8c-ffc8-5e54-9f02-4b2126601994"
                    ]
                },
                "GAD-19a": {
                    "questionId": "GAD-19a",
                    "chosenOptionIds": [
                        "51801ae8-62d1-5a94-b757-a0b0851ac6bc",
                        "3c648d60-89ec-5e44-9bf1-dc362139d849",
                        "863da48d-f2eb-52f4-8c4e-feda60cda5c4",
                        "67df9ae6-c783-5f09-9975-a117ab653011",
                        "0a747bd1-8eee-563f-a6ae-8823404898a5"
                    ]
                },
                "GAD-22a": {
                    "questionId": "GAD-22a",
                    "chosenOptionIds": [
                        "09fba5ed-bf0e-5aa9-92a5-50c6a01ae0e2"
                    ]
                }
            }
        },
        {
            "name": "11_GAD_severity4current",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "GAD-S": {
                    "questionId": "GAD-S",
                    "chosenOptionIds": [
                        "aa668802-d458-5b2d-b48f-e4058a640295"
                    ]
                },
                "GAD-s1": {
                    "questionId": "GAD-s1",
                    "chosenOptionIds": [
                        "24032a9e-f5b6-5f43-8ddf-305888669ad1"
                    ]
                },
                "GAD-s3a": {
                    "questionId": "GAD-s3a",
                    "chosenOptionIds": [
                        "ed0ad572-166e-587e-b191-2cbe81387f5a"
                    ]
                },
                "GAD-s4a": {
                    "questionId": "GAD-s4a",
                    "chosenOptionIds": [
                        "5a1c5af4-b628-5554-bf63-4ca68ef2b328"
                    ]
                },
                "GAD-s6a": {
                    "questionId": "GAD-s6a",
                    "chosenOptionIds": [
                        "6e2595a2-ea1e-5ca5-ab11-c65eb6cb6157"
                    ]
                },
                "GAD-3a": {
                    "questionId": "GAD-3a",
                    "chosenOptionIds": [
                        "bdba5003-2589-507d-bb90-53534cc9b942"
                    ]
                },
                "GAD-4a": {
                    "questionId": "GAD-4a",
                    "chosenOptionIds": [
                        "748f4a0b-00a7-5b15-b390-2dfb8ac9eb1d"
                    ]
                },
                "GAD-5a": {
                    "questionId": "GAD-5a",
                    "chosenOptionIds": [
                        "6bb8be71-d051-540f-8d81-5dff1869bbcc"
                    ]
                },
                "GAD-6a": {
                    "questionId": "GAD-6a",
                    "chosenOptionIds": [
                        "f26a3508-c284-53a5-8bc7-63514fbe6737"
                    ]
                },
                "GAD-7a": {
                    "questionId": "GAD-7a",
                    "chosenOptionIds": [
                        "3b9e5247-4c0f-5c9d-9ffb-822ab95bee8a"
                    ]
                },
                "GAD-8a": {
                    "questionId": "GAD-8a",
                    "chosenOptionIds": [
                        "985dc1a3-7ea9-5f5c-8af3-6159ac9c90bf"
                    ]
                },
                "GAD-9a": {
                    "questionId": "GAD-9a",
                    "chosenOptionIds": [
                        "35150196-067f-58af-b669-6e41443a3601"
                    ]
                },
                "GAD-10a": {
                    "questionId": "GAD-10a",
                    "chosenOptionIds": [
                        "e16d33eb-317e-55aa-90fc-147bde51e99b"
                    ]
                },
                "GAD-11a": {
                    "questionId": "GAD-11a",
                    "chosenOptionIds": [
                        "c1926d2c-b894-5ae1-bbdd-f8c9954ce55a"
                    ]
                },
                "GAD-12a": {
                    "questionId": "GAD-12a",
                    "chosenOptionIds": [
                        "4fb31d91-3520-5676-90c0-d3c05e9abfb8"
                    ]
                },
                "GAD-13a": {
                    "questionId": "GAD-13a",
                    "chosenOptionIds": [
                        "42da2d35-01a4-50f9-95e5-c7bbc4c8eda7"
                    ]
                },
                "GAD-14a": {
                    "questionId": "GAD-14a",
                    "chosenOptionIds": [
                        "0623617f-3483-5ad7-ba3a-dd6b95c269d5"
                    ]
                },
                "GAD-15a": {
                    "questionId": "GAD-15a",
                    "chosenOptionIds": [
                        "632e07d1-df69-590c-a8f2-0b4f7ad343e1"
                    ]
                },
                "GAD-17a": {
                    "questionId": "GAD-17a",
                    "chosenOptionIds": [
                        "ca6c7d1d-3395-58b8-a87d-95fba13b4ca3"
                    ]
                },
                "GAD-18a": {
                    "questionId": "GAD-18a",
                    "chosenOptionIds": [
                        "63b22d31-0933-571a-9c46-eb63c6373884"
                    ]
                },
                "GAD-19.0a": {
                    "questionId": "GAD-19.0a",
                    "chosenOptionIds": [
                        "78df6de1-fe3e-5bed-a63d-f5f9877809af"
                    ]
                },
                "GAD-19a": {
                    "questionId": "GAD-19a",
                    "chosenOptionIds": [
                        "a7dc1041-6095-5630-9480-ed7c33997997",
                        "863da48d-f2eb-52f4-8c4e-feda60cda5c4",
                        "1acf1647-150f-5031-9599-8db8e23c423d",
                        "4ca41a8f-b524-5ef7-af86-7ef9279a7969"
                    ]
                },
                "GAD-22a": {
                    "questionId": "GAD-22a",
                    "chosenOptionIds": [
                        "564b4697-78ea-5000-bb19-e1ed63265906"
                    ]
                }
            }
        },
        {
            "name": "11_GAD_severity3current",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "GAD-S": {
                    "questionId": "GAD-S",
                    "chosenOptionIds": [
                        "aa668802-d458-5b2d-b48f-e4058a640295"
                    ]
                },
                "GAD-s1": {
                    "questionId": "GAD-s1",
                    "chosenOptionIds": [
                        "f8d49124-01dc-5d55-add9-5ed87d998e00"
                    ]
                },
                "GAD-s3a": {
                    "questionId": "GAD-s3a",
                    "chosenOptionIds": [
                        "4bbc59ac-1581-5084-9e92-2b746637ff52"
                    ]
                },
                "GAD-s4a": {
                    "questionId": "GAD-s4a",
                    "chosenOptionIds": [
                        "5a1c5af4-b628-5554-bf63-4ca68ef2b328"
                    ]
                },
                "GAD-s6a": {
                    "questionId": "GAD-s6a",
                    "chosenOptionIds": [
                        "4059602c-013b-5f54-890f-64e55583f3dc"
                    ]
                },
                "GAD-3a": {
                    "questionId": "GAD-3a",
                    "chosenOptionIds": [
                        "bdba5003-2589-507d-bb90-53534cc9b942"
                    ]
                },
                "GAD-4a": {
                    "questionId": "GAD-4a",
                    "chosenOptionIds": [
                        "517a9806-ffe8-52a9-86df-e947a47e169c"
                    ]
                },
                "GAD-5a": {
                    "questionId": "GAD-5a",
                    "chosenOptionIds": [
                        "bd66ed3d-4e63-5fe8-88a6-fbbf3ab55502"
                    ]
                },
                "GAD-6a": {
                    "questionId": "GAD-6a",
                    "chosenOptionIds": [
                        "f26a3508-c284-53a5-8bc7-63514fbe6737"
                    ]
                },
                "GAD-7a": {
                    "questionId": "GAD-7a",
                    "chosenOptionIds": [
                        "d7d05c32-44cb-59c7-84b0-95e98fde6077"
                    ]
                },
                "GAD-8a": {
                    "questionId": "GAD-8a",
                    "chosenOptionIds": [
                        "e1dd4f50-28b4-51eb-8c34-bf8d3145816f"
                    ]
                },
                "GAD-9a": {
                    "questionId": "GAD-9a",
                    "chosenOptionIds": [
                        "35150196-067f-58af-b669-6e41443a3601"
                    ]
                },
                "GAD-10a": {
                    "questionId": "GAD-10a",
                    "chosenOptionIds": [
                        "44ddd9b1-23aa-570e-bbce-1bef374fe68a"
                    ]
                },
                "GAD-11a": {
                    "questionId": "GAD-11a",
                    "chosenOptionIds": [
                        "8ce6bca8-7cea-5cae-b31f-9b03eb4f7322"
                    ]
                },
                "GAD-12a": {
                    "questionId": "GAD-12a",
                    "chosenOptionIds": [
                        "4fb31d91-3520-5676-90c0-d3c05e9abfb8"
                    ]
                },
                "GAD-13a": {
                    "questionId": "GAD-13a",
                    "chosenOptionIds": [
                        "40abb98e-db00-5505-85be-59a5dd4ef092"
                    ]
                },
                "GAD-14a": {
                    "questionId": "GAD-14a",
                    "chosenOptionIds": [
                        "b0335c5e-d91f-5e19-9a45-4936bd9e3bea"
                    ]
                },
                "GAD-15a": {
                    "questionId": "GAD-15a",
                    "chosenOptionIds": [
                        "632e07d1-df69-590c-a8f2-0b4f7ad343e1"
                    ]
                },
                "GAD-17a": {
                    "questionId": "GAD-17a",
                    "chosenOptionIds": [
                        "f468532b-0dfb-546a-87bc-4840ffcab3e0"
                    ]
                },
                "GAD-18a": {
                    "questionId": "GAD-18a",
                    "chosenOptionIds": [
                        "af347157-5a07-5187-b207-b672ecf979dc"
                    ]
                },
                "GAD-19.0a": {
                    "questionId": "GAD-19.0a",
                    "chosenOptionIds": [
                        "4196a0b8-4e73-5d21-903f-594531ff22f0"
                    ]
                },
                "GAD-22a": {
                    "questionId": "GAD-22a",
                    "chosenOptionIds": [
                        "564b4697-78ea-5000-bb19-e1ed63265906"
                    ]
                }
            }
        },
        {
            "name": "11_GAD_severity5current",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "GAD-S": {
                    "questionId": "GAD-S",
                    "chosenOptionIds": [
                        "aa668802-d458-5b2d-b48f-e4058a640295"
                    ]
                },
                "GAD-s1": {
                    "questionId": "GAD-s1",
                    "chosenOptionIds": [
                        "f8d49124-01dc-5d55-add9-5ed87d998e00"
                    ]
                },
                "GAD-s3a": {
                    "questionId": "GAD-s3a",
                    "chosenOptionIds": [
                        "ed0ad572-166e-587e-b191-2cbe81387f5a"
                    ]
                },
                "GAD-s4a": {
                    "questionId": "GAD-s4a",
                    "chosenOptionIds": [
                        "5a1c5af4-b628-5554-bf63-4ca68ef2b328"
                    ]
                },
                "GAD-s6a": {
                    "questionId": "GAD-s6a",
                    "chosenOptionIds": [
                        "6e2595a2-ea1e-5ca5-ab11-c65eb6cb6157"
                    ]
                },
                "GAD-3a": {
                    "questionId": "GAD-3a",
                    "chosenOptionIds": [
                        "fa80af33-1ed9-5e02-abd3-85852c65204e"
                    ]
                },
                "GAD-4a": {
                    "questionId": "GAD-4a",
                    "chosenOptionIds": [
                        "748f4a0b-00a7-5b15-b390-2dfb8ac9eb1d"
                    ]
                },
                "GAD-5a": {
                    "questionId": "GAD-5a",
                    "chosenOptionIds": [
                        "bd66ed3d-4e63-5fe8-88a6-fbbf3ab55502"
                    ]
                },
                "GAD-6a": {
                    "questionId": "GAD-6a",
                    "chosenOptionIds": [
                        "81a08016-21d5-5f6e-b174-710e540e00c1"
                    ]
                },
                "GAD-7a": {
                    "questionId": "GAD-7a",
                    "chosenOptionIds": [
                        "d7d05c32-44cb-59c7-84b0-95e98fde6077"
                    ]
                },
                "GAD-8a": {
                    "questionId": "GAD-8a",
                    "chosenOptionIds": [
                        "985dc1a3-7ea9-5f5c-8af3-6159ac9c90bf"
                    ]
                },
                "GAD-9a": {
                    "questionId": "GAD-9a",
                    "chosenOptionIds": [
                        "b7c86f29-540d-56d7-a59a-7035d91396d8"
                    ]
                },
                "GAD-10a": {
                    "questionId": "GAD-10a",
                    "chosenOptionIds": [
                        "e36ed8e3-ca0e-5d7e-826f-84c47842e71c"
                    ]
                },
                "GAD-11a": {
                    "questionId": "GAD-11a",
                    "chosenOptionIds": [
                        "ba121413-fa3c-5d39-960f-827d7b0d55b8"
                    ]
                },
                "GAD-12a": {
                    "questionId": "GAD-12a",
                    "chosenOptionIds": [
                        "75847021-68a5-5e54-938f-ebae91570246"
                    ]
                },
                "GAD-13a": {
                    "questionId": "GAD-13a",
                    "chosenOptionIds": [
                        "42da2d35-01a4-50f9-95e5-c7bbc4c8eda7"
                    ]
                },
                "GAD-14a": {
                    "questionId": "GAD-14a",
                    "chosenOptionIds": [
                        "b0335c5e-d91f-5e19-9a45-4936bd9e3bea"
                    ]
                },
                "GAD-15a": {
                    "questionId": "GAD-15a",
                    "chosenOptionIds": [
                        "6447e557-156f-59a1-9cbc-cd9d4b9bcbee"
                    ]
                },
                "GAD-17a": {
                    "questionId": "GAD-17a",
                    "chosenOptionIds": [
                        "93c614a5-158b-54bb-b179-2d356411ab81"
                    ]
                },
                "GAD-18a": {
                    "questionId": "GAD-18a",
                    "chosenOptionIds": [
                        "af347157-5a07-5187-b207-b672ecf979dc"
                    ]
                },
                "GAD-19.0a": {
                    "questionId": "GAD-19.0a",
                    "chosenOptionIds": [
                        "78df6de1-fe3e-5bed-a63d-f5f9877809af"
                    ]
                },
                "GAD-19a": {
                    "questionId": "GAD-19a",
                    "chosenOptionIds": [
                        "863da48d-f2eb-52f4-8c4e-feda60cda5c4",
                        "1acf1647-150f-5031-9599-8db8e23c423d",
                        "0a747bd1-8eee-563f-a6ae-8823404898a5"
                    ]
                },
                "GAD-22a": {
                    "questionId": "GAD-22a",
                    "chosenOptionIds": [
                        "8b112b00-59f5-5465-a3c7-d3eed9586abf"
                    ]
                }
            }
        },
        {
            "name": "11_GAD_severity3",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "GAD-S": {
                    "questionId": "GAD-S",
                    "chosenOptionIds": [
                        "aa668802-d458-5b2d-b48f-e4058a640295"
                    ]
                },
                "GAD-s1": {
                    "questionId": "GAD-s1",
                    "chosenOptionIds": [
                        "24032a9e-f5b6-5f43-8ddf-305888669ad1"
                    ]
                },
                "GAD-s3a": {
                    "questionId": "GAD-s3a",
                    "chosenOptionIds": [
                        "4bbc59ac-1581-5084-9e92-2b746637ff52"
                    ]
                },
                "GAD-s4a": {
                    "questionId": "GAD-s4a",
                    "chosenOptionIds": [
                        "0caeb854-db8e-5a20-a344-0c9282f65f78"
                    ]
                },
                "GAD-s6a": {
                    "questionId": "GAD-s6a",
                    "chosenOptionIds": [
                        "4059602c-013b-5f54-890f-64e55583f3dc"
                    ]
                },
                "GAD-3a": {
                    "questionId": "GAD-3a",
                    "chosenOptionIds": [
                        "fa80af33-1ed9-5e02-abd3-85852c65204e"
                    ]
                },
                "GAD-4a": {
                    "questionId": "GAD-4a",
                    "chosenOptionIds": [
                        "748f4a0b-00a7-5b15-b390-2dfb8ac9eb1d"
                    ]
                },
                "GAD-5a": {
                    "questionId": "GAD-5a",
                    "chosenOptionIds": [
                        "6bb8be71-d051-540f-8d81-5dff1869bbcc"
                    ]
                },
                "GAD-6a": {
                    "questionId": "GAD-6a",
                    "chosenOptionIds": [
                        "7d60ebb2-5f2d-5ab0-b9ca-31210502ece9"
                    ]
                },
                "GAD-7a": {
                    "questionId": "GAD-7a",
                    "chosenOptionIds": [
                        "8dc41a6e-72d4-5c1f-a416-f085995a1878"
                    ]
                },
                "GAD-8a": {
                    "questionId": "GAD-8a",
                    "chosenOptionIds": [
                        "e1dd4f50-28b4-51eb-8c34-bf8d3145816f"
                    ]
                },
                "GAD-9a": {
                    "questionId": "GAD-9a",
                    "chosenOptionIds": [
                        "03b91bf0-49f5-5760-ae70-8e92ca65aa0e"
                    ]
                },
                "GAD-10a": {
                    "questionId": "GAD-10a",
                    "chosenOptionIds": [
                        "e16d33eb-317e-55aa-90fc-147bde51e99b"
                    ]
                },
                "GAD-11a": {
                    "questionId": "GAD-11a",
                    "chosenOptionIds": [
                        "ba121413-fa3c-5d39-960f-827d7b0d55b8"
                    ]
                },
                "GAD-12a": {
                    "questionId": "GAD-12a",
                    "chosenOptionIds": [
                        "4fb31d91-3520-5676-90c0-d3c05e9abfb8"
                    ]
                },
                "GAD-13a": {
                    "questionId": "GAD-13a",
                    "chosenOptionIds": [
                        "42da2d35-01a4-50f9-95e5-c7bbc4c8eda7"
                    ]
                },
                "GAD-14a": {
                    "questionId": "GAD-14a",
                    "chosenOptionIds": [
                        "8f4ab7fd-f018-5862-af97-d98aa222664a"
                    ]
                },
                "GAD-15a": {
                    "questionId": "GAD-15a",
                    "chosenOptionIds": [
                        "632e07d1-df69-590c-a8f2-0b4f7ad343e1"
                    ]
                },
                "GAD-17a": {
                    "questionId": "GAD-17a",
                    "chosenOptionIds": [
                        "ca6c7d1d-3395-58b8-a87d-95fba13b4ca3"
                    ]
                },
                "GAD-18a": {
                    "questionId": "GAD-18a",
                    "chosenOptionIds": [
                        "63b22d31-0933-571a-9c46-eb63c6373884"
                    ]
                },
                "GAD-19.0a": {
                    "questionId": "GAD-19.0a",
                    "chosenOptionIds": [
                        "a0520f66-594b-5c56-bebc-a0d018d139d9"
                    ]
                },
                "GAD-19a": {
                    "questionId": "GAD-19a",
                    "chosenOptionIds": [
                        "a7dc1041-6095-5630-9480-ed7c33997997"
                    ]
                },
                "GAD-22a": {
                    "questionId": "GAD-22a",
                    "chosenOptionIds": [
                        "564b4697-78ea-5000-bb19-e1ed63265906"
                    ]
                }
            }
        },
        {
            "name": "11_GAD_severity4current_clinicalpartners",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "GAD-S": {
                    "questionId": "GAD-S",
                    "chosenOptionIds": [
                        "aa668802-d458-5b2d-b48f-e4058a640295"
                    ]
                },
                "GAD-s1": {
                    "questionId": "GAD-s1",
                    "chosenOptionIds": [
                        "24032a9e-f5b6-5f43-8ddf-305888669ad1"
                    ]
                },
                "GAD-s3a": {
                    "questionId": "GAD-s3a",
                    "chosenOptionIds": [
                        "ed0ad572-166e-587e-b191-2cbe81387f5a"
                    ]
                },
                "GAD-s4a": {
                    "questionId": "GAD-s4a",
                    "chosenOptionIds": [
                        "276f0539-3b02-5717-a333-3d5ac219a5b4"
                    ]
                },
                "GAD-s6a": {
                    "questionId": "GAD-s6a",
                    "chosenOptionIds": [
                        "4059602c-013b-5f54-890f-64e55583f3dc"
                    ]
                },
                "GAD-3a": {
                    "questionId": "GAD-3a",
                    "chosenOptionIds": [
                        "fa80af33-1ed9-5e02-abd3-85852c65204e"
                    ]
                },
                "GAD-4a": {
                    "questionId": "GAD-4a",
                    "chosenOptionIds": [
                        "748f4a0b-00a7-5b15-b390-2dfb8ac9eb1d"
                    ]
                },
                "GAD-5a": {
                    "questionId": "GAD-5a",
                    "chosenOptionIds": [
                        "6bb8be71-d051-540f-8d81-5dff1869bbcc"
                    ]
                },
                "GAD-6a": {
                    "questionId": "GAD-6a",
                    "chosenOptionIds": [
                        "7d60ebb2-5f2d-5ab0-b9ca-31210502ece9"
                    ]
                },
                "GAD-7a": {
                    "questionId": "GAD-7a",
                    "chosenOptionIds": [
                        "3b9e5247-4c0f-5c9d-9ffb-822ab95bee8a"
                    ]
                },
                "GAD-8a": {
                    "questionId": "GAD-8a",
                    "chosenOptionIds": [
                        "985dc1a3-7ea9-5f5c-8af3-6159ac9c90bf"
                    ]
                },
                "GAD-9a": {
                    "questionId": "GAD-9a",
                    "chosenOptionIds": [
                        "35150196-067f-58af-b669-6e41443a3601"
                    ]
                },
                "GAD-10a": {
                    "questionId": "GAD-10a",
                    "chosenOptionIds": [
                        "e16d33eb-317e-55aa-90fc-147bde51e99b"
                    ]
                },
                "GAD-11a": {
                    "questionId": "GAD-11a",
                    "chosenOptionIds": [
                        "68c692fb-cc63-5ad6-b88e-5e45ad971c82"
                    ]
                },
                "GAD-12a": {
                    "questionId": "GAD-12a",
                    "chosenOptionIds": [
                        "89cda9bd-e311-53fa-95c1-1adab2202633"
                    ]
                },
                "GAD-13a": {
                    "questionId": "GAD-13a",
                    "chosenOptionIds": [
                        "42da2d35-01a4-50f9-95e5-c7bbc4c8eda7"
                    ]
                },
                "GAD-14a": {
                    "questionId": "GAD-14a",
                    "chosenOptionIds": [
                        "6ac69add-b72f-5784-84a9-a517721463e6"
                    ]
                },
                "GAD-15a": {
                    "questionId": "GAD-15a",
                    "chosenOptionIds": [
                        "632e07d1-df69-590c-a8f2-0b4f7ad343e1"
                    ]
                },
                "GAD-17a": {
                    "questionId": "GAD-17a",
                    "chosenOptionIds": [
                        "f468532b-0dfb-546a-87bc-4840ffcab3e0"
                    ]
                },
                "GAD-18a": {
                    "questionId": "GAD-18a",
                    "chosenOptionIds": [
                        "63b22d31-0933-571a-9c46-eb63c6373884"
                    ]
                },
                "GAD-19.0a": {
                    "questionId": "GAD-19.0a",
                    "chosenOptionIds": [
                        "a0520f66-594b-5c56-bebc-a0d018d139d9"
                    ]
                },
                "GAD-19a": {
                    "questionId": "GAD-19a",
                    "chosenOptionIds": [
                        "a7dc1041-6095-5630-9480-ed7c33997997",
                        "863da48d-f2eb-52f4-8c4e-feda60cda5c4",
                        "1acf1647-150f-5031-9599-8db8e23c423d",
                        "4ca41a8f-b524-5ef7-af86-7ef9279a7969"
                    ]
                },
                "GAD-22a": {
                    "questionId": "GAD-22a",
                    "chosenOptionIds": [
                        "564b4697-78ea-5000-bb19-e1ed63265906"
                    ]
                }
            }
        },
        {
            "name": "11_GAD_severity5current_primary",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "GAD-S": {
                    "questionId": "GAD-S",
                    "chosenOptionIds": [
                        "aa668802-d458-5b2d-b48f-e4058a640295"
                    ]
                },
                "GAD-s1": {
                    "questionId": "GAD-s1",
                    "chosenOptionIds": [
                        "f8d49124-01dc-5d55-add9-5ed87d998e00"
                    ]
                },
                "GAD-s3a": {
                    "questionId": "GAD-s3a",
                    "chosenOptionIds": [
                        "ed0ad572-166e-587e-b191-2cbe81387f5a"
                    ]
                },
                "GAD-s4a": {
                    "questionId": "GAD-s4a",
                    "chosenOptionIds": [
                        "5a1c5af4-b628-5554-bf63-4ca68ef2b328"
                    ]
                },
                "GAD-s6a": {
                    "questionId": "GAD-s6a",
                    "chosenOptionIds": [
                        "6e2595a2-ea1e-5ca5-ab11-c65eb6cb6157"
                    ]
                },
                "GAD-3a": {
                    "questionId": "GAD-3a",
                    "chosenOptionIds": [
                        "fa80af33-1ed9-5e02-abd3-85852c65204e"
                    ]
                },
                "GAD-4a": {
                    "questionId": "GAD-4a",
                    "chosenOptionIds": [
                        "748f4a0b-00a7-5b15-b390-2dfb8ac9eb1d"
                    ]
                },
                "GAD-5a": {
                    "questionId": "GAD-5a",
                    "chosenOptionIds": [
                        "bd66ed3d-4e63-5fe8-88a6-fbbf3ab55502"
                    ]
                },
                "GAD-6a": {
                    "questionId": "GAD-6a",
                    "chosenOptionIds": [
                        "81a08016-21d5-5f6e-b174-710e540e00c1"
                    ]
                },
                "GAD-7a": {
                    "questionId": "GAD-7a",
                    "chosenOptionIds": [
                        "d7d05c32-44cb-59c7-84b0-95e98fde6077"
                    ]
                },
                "GAD-8a": {
                    "questionId": "GAD-8a",
                    "chosenOptionIds": [
                        "985dc1a3-7ea9-5f5c-8af3-6159ac9c90bf"
                    ]
                },
                "GAD-9a": {
                    "questionId": "GAD-9a",
                    "chosenOptionIds": [
                        "b7c86f29-540d-56d7-a59a-7035d91396d8"
                    ]
                },
                "GAD-10a": {
                    "questionId": "GAD-10a",
                    "chosenOptionIds": [
                        "e36ed8e3-ca0e-5d7e-826f-84c47842e71c"
                    ]
                },
                "GAD-11a": {
                    "questionId": "GAD-11a",
                    "chosenOptionIds": [
                        "8ce6bca8-7cea-5cae-b31f-9b03eb4f7322"
                    ]
                },
                "GAD-12a": {
                    "questionId": "GAD-12a",
                    "chosenOptionIds": [
                        "75847021-68a5-5e54-938f-ebae91570246"
                    ]
                },
                "GAD-13a": {
                    "questionId": "GAD-13a",
                    "chosenOptionIds": [
                        "42da2d35-01a4-50f9-95e5-c7bbc4c8eda7"
                    ]
                },
                "GAD-14a": {
                    "questionId": "GAD-14a",
                    "chosenOptionIds": [
                        "b0335c5e-d91f-5e19-9a45-4936bd9e3bea"
                    ]
                },
                "GAD-15a": {
                    "questionId": "GAD-15a",
                    "chosenOptionIds": [
                        "6447e557-156f-59a1-9cbc-cd9d4b9bcbee"
                    ]
                },
                "GAD-17a": {
                    "questionId": "GAD-17a",
                    "chosenOptionIds": [
                        "93c614a5-158b-54bb-b179-2d356411ab81"
                    ]
                },
                "GAD-18a": {
                    "questionId": "GAD-18a",
                    "chosenOptionIds": [
                        "af347157-5a07-5187-b207-b672ecf979dc"
                    ]
                },
                "GAD-19.0a": {
                    "questionId": "GAD-19.0a",
                    "chosenOptionIds": [
                        "78df6de1-fe3e-5bed-a63d-f5f9877809af"
                    ]
                },
                "GAD-19a": {
                    "questionId": "GAD-19a",
                    "chosenOptionIds": [
                        "863da48d-f2eb-52f4-8c4e-feda60cda5c4",
                        "1acf1647-150f-5031-9599-8db8e23c423d",
                        "67df9ae6-c783-5f09-9975-a117ab653011"
                    ]
                },
                "GAD-22a": {
                    "questionId": "GAD-22a",
                    "chosenOptionIds": [
                        "564b4697-78ea-5000-bb19-e1ed63265906"
                    ]
                }
            }
        },
        {
            "name": "11_GAD_severity1-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.05,
                "threshold": 0.1
            },
            "answers": {
                "GAD-S": {
                    "questionId": "GAD-S",
                    "chosenOptionIds": [
                        "aa668802-d458-5b2d-b48f-e4058a640295"
                    ]
                },
                "GAD-s1": {
                    "questionId": "GAD-s1",
                    "chosenOptionIds": [
                        "eaaa8028-c116-5ae3-bb59-5795820feca8"
                    ]
                }
            }
        },
        {
            "name": "11_GAD_severity2-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.38,
                "threshold": 0
            },
            "answers": {
                "GAD-S": {
                    "questionId": "GAD-S",
                    "chosenOptionIds": [
                        "aa668802-d458-5b2d-b48f-e4058a640295"
                    ]
                },
                "GAD-s1": {
                    "questionId": "GAD-s1",
                    "chosenOptionIds": [
                        "f8d49124-01dc-5d55-add9-5ed87d998e00"
                    ]
                },
                "GAD-s3a": {
                    "questionId": "GAD-s3a",
                    "chosenOptionIds": [
                        "4bbc59ac-1581-5084-9e92-2b746637ff52"
                    ]
                },
                "GAD-s4a": {
                    "questionId": "GAD-s4a",
                    "chosenOptionIds": [
                        "276f0539-3b02-5717-a333-3d5ac219a5b4"
                    ]
                },
                "GAD-s6a": {
                    "questionId": "GAD-s6a",
                    "chosenOptionIds": [
                        "6e2595a2-ea1e-5ca5-ab11-c65eb6cb6157"
                    ]
                },
                "GAD-3a": {
                    "questionId": "GAD-3a",
                    "chosenOptionIds": [
                        "fa80af33-1ed9-5e02-abd3-85852c65204e"
                    ]
                },
                "GAD-4a": {
                    "questionId": "GAD-4a",
                    "chosenOptionIds": [
                        "517a9806-ffe8-52a9-86df-e947a47e169c"
                    ]
                },
                "GAD-5a": {
                    "questionId": "GAD-5a",
                    "chosenOptionIds": [
                        "bd66ed3d-4e63-5fe8-88a6-fbbf3ab55502"
                    ]
                },
                "GAD-6a": {
                    "questionId": "GAD-6a",
                    "chosenOptionIds": [
                        "f26a3508-c284-53a5-8bc7-63514fbe6737"
                    ]
                },
                "GAD-7a": {
                    "questionId": "GAD-7a",
                    "chosenOptionIds": [
                        "d7d05c32-44cb-59c7-84b0-95e98fde6077"
                    ]
                },
                "GAD-8a": {
                    "questionId": "GAD-8a",
                    "chosenOptionIds": [
                        "e1dd4f50-28b4-51eb-8c34-bf8d3145816f"
                    ]
                },
                "GAD-9a": {
                    "questionId": "GAD-9a",
                    "chosenOptionIds": [
                        "03b91bf0-49f5-5760-ae70-8e92ca65aa0e"
                    ]
                },
                "GAD-10a": {
                    "questionId": "GAD-10a",
                    "chosenOptionIds": [
                        "e16d33eb-317e-55aa-90fc-147bde51e99b"
                    ]
                },
                "GAD-11a": {
                    "questionId": "GAD-11a",
                    "chosenOptionIds": [
                        "ba121413-fa3c-5d39-960f-827d7b0d55b8"
                    ]
                },
                "GAD-12a": {
                    "questionId": "GAD-12a",
                    "chosenOptionIds": [
                        "c74d8b74-1128-5f73-bfd2-2c3813bfdd4e"
                    ]
                },
                "GAD-13a": {
                    "questionId": "GAD-13a",
                    "chosenOptionIds": [
                        "8dda5767-c89f-5932-808d-a7729c3f935b"
                    ]
                },
                "GAD-14a": {
                    "questionId": "GAD-14a",
                    "chosenOptionIds": [
                        "6ac69add-b72f-5784-84a9-a517721463e6"
                    ]
                },
                "GAD-15a": {
                    "questionId": "GAD-15a",
                    "chosenOptionIds": [
                        "632e07d1-df69-590c-a8f2-0b4f7ad343e1"
                    ]
                },
                "GAD-17a": {
                    "questionId": "GAD-17a",
                    "chosenOptionIds": [
                        "93c614a5-158b-54bb-b179-2d356411ab81"
                    ]
                },
                "GAD-18a": {
                    "questionId": "GAD-18a",
                    "chosenOptionIds": [
                        "af347157-5a07-5187-b207-b672ecf979dc"
                    ]
                },
                "GAD-19.0a": {
                    "questionId": "GAD-19.0a",
                    "chosenOptionIds": [
                        "78df6de1-fe3e-5bed-a63d-f5f9877809af"
                    ]
                },
                "GAD-19a": {
                    "questionId": "GAD-19a",
                    "chosenOptionIds": [
                        "3c648d60-89ec-5e44-9bf1-dc362139d849",
                        "863da48d-f2eb-52f4-8c4e-feda60cda5c4",
                        "67df9ae6-c783-5f09-9975-a117ab653011"
                    ]
                },
                "GAD-22a": {
                    "questionId": "GAD-22a",
                    "chosenOptionIds": [
                        "09fba5ed-bf0e-5aa9-92a5-50c6a01ae0e2"
                    ]
                }
            }
        },
        {
            "name": "11_GAD_severity6current-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.835,
                "threshold": 0.01
            },
            "answers": {
                "GAD-S": {
                    "questionId": "GAD-S",
                    "chosenOptionIds": [
                        "aa668802-d458-5b2d-b48f-e4058a640295"
                    ]
                },
                "GAD-s1": {
                    "questionId": "GAD-s1",
                    "chosenOptionIds": [
                        "f8d49124-01dc-5d55-add9-5ed87d998e00"
                    ]
                },
                "GAD-s3a": {
                    "questionId": "GAD-s3a",
                    "chosenOptionIds": [
                        "adab9f0b-f6e7-54c4-9cf1-087cdf831412"
                    ]
                },
                "GAD-s4a": {
                    "questionId": "GAD-s4a",
                    "chosenOptionIds": [
                        "9d0741b6-d8b6-5216-90d0-df6743af1aa0"
                    ]
                },
                "GAD-s6a": {
                    "questionId": "GAD-s6a",
                    "chosenOptionIds": [
                        "6e2595a2-ea1e-5ca5-ab11-c65eb6cb6157"
                    ]
                },
                "GAD-3a": {
                    "questionId": "GAD-3a",
                    "chosenOptionIds": [
                        "fa80af33-1ed9-5e02-abd3-85852c65204e"
                    ]
                },
                "GAD-4a": {
                    "questionId": "GAD-4a",
                    "chosenOptionIds": [
                        "517a9806-ffe8-52a9-86df-e947a47e169c"
                    ]
                },
                "GAD-5a": {
                    "questionId": "GAD-5a",
                    "chosenOptionIds": [
                        "bd66ed3d-4e63-5fe8-88a6-fbbf3ab55502"
                    ]
                },
                "GAD-6a": {
                    "questionId": "GAD-6a",
                    "chosenOptionIds": [
                        "8996813a-e012-57cb-8eab-b6926c27eba2"
                    ]
                },
                "GAD-7a": {
                    "questionId": "GAD-7a",
                    "chosenOptionIds": [
                        "d7d05c32-44cb-59c7-84b0-95e98fde6077"
                    ]
                },
                "GAD-8a": {
                    "questionId": "GAD-8a",
                    "chosenOptionIds": [
                        "985dc1a3-7ea9-5f5c-8af3-6159ac9c90bf"
                    ]
                },
                "GAD-9a": {
                    "questionId": "GAD-9a",
                    "chosenOptionIds": [
                        "03b91bf0-49f5-5760-ae70-8e92ca65aa0e"
                    ]
                },
                "GAD-10a": {
                    "questionId": "GAD-10a",
                    "chosenOptionIds": [
                        "e16d33eb-317e-55aa-90fc-147bde51e99b"
                    ]
                },
                "GAD-11a": {
                    "questionId": "GAD-11a",
                    "chosenOptionIds": [
                        "ba121413-fa3c-5d39-960f-827d7b0d55b8"
                    ]
                },
                "GAD-12a": {
                    "questionId": "GAD-12a",
                    "chosenOptionIds": [
                        "c74d8b74-1128-5f73-bfd2-2c3813bfdd4e"
                    ]
                },
                "GAD-13a": {
                    "questionId": "GAD-13a",
                    "chosenOptionIds": [
                        "40abb98e-db00-5505-85be-59a5dd4ef092"
                    ]
                },
                "GAD-14a": {
                    "questionId": "GAD-14a",
                    "chosenOptionIds": [
                        "0623617f-3483-5ad7-ba3a-dd6b95c269d5"
                    ]
                },
                "GAD-15a": {
                    "questionId": "GAD-15a",
                    "chosenOptionIds": [
                        "39f33fe3-3b5e-551a-8902-6a8c7ab4c148"
                    ]
                },
                "GAD-17a": {
                    "questionId": "GAD-17a",
                    "chosenOptionIds": [
                        "93c614a5-158b-54bb-b179-2d356411ab81"
                    ]
                },
                "GAD-18a": {
                    "questionId": "GAD-18a",
                    "chosenOptionIds": [
                        "af347157-5a07-5187-b207-b672ecf979dc"
                    ]
                },
                "GAD-19.0a": {
                    "questionId": "GAD-19.0a",
                    "chosenOptionIds": [
                        "1341da8c-ffc8-5e54-9f02-4b2126601994"
                    ]
                },
                "GAD-19a": {
                    "questionId": "GAD-19a",
                    "chosenOptionIds": [
                        "a7dc1041-6095-5630-9480-ed7c33997997",
                        "3c648d60-89ec-5e44-9bf1-dc362139d849",
                        "0a747bd1-8eee-563f-a6ae-8823404898a5",
                        "4ca41a8f-b524-5ef7-af86-7ef9279a7969"
                    ]
                },
                "GAD-22a": {
                    "questionId": "GAD-22a",
                    "chosenOptionIds": [
                        "09fba5ed-bf0e-5aa9-92a5-50c6a01ae0e2"
                    ]
                }
            }
        },
        {
            "name": "11_GAD_severity7current-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.915,
                "threshold": 0.03
            },
            "answers": {
                "GAD-S": {
                    "questionId": "GAD-S",
                    "chosenOptionIds": [
                        "aa668802-d458-5b2d-b48f-e4058a640295"
                    ]
                },
                "GAD-s1": {
                    "questionId": "GAD-s1",
                    "chosenOptionIds": [
                        "c819bfe0-f79d-5132-8b3a-8604c0c5b83a"
                    ]
                },
                "GAD-s3a": {
                    "questionId": "GAD-s3a",
                    "chosenOptionIds": [
                        "1943ebd6-08ab-5b8e-a57b-7e397d303526"
                    ]
                },
                "GAD-s4a": {
                    "questionId": "GAD-s4a",
                    "chosenOptionIds": [
                        "9d0741b6-d8b6-5216-90d0-df6743af1aa0"
                    ]
                },
                "GAD-s6a": {
                    "questionId": "GAD-s6a",
                    "chosenOptionIds": [
                        "6e2595a2-ea1e-5ca5-ab11-c65eb6cb6157"
                    ]
                },
                "GAD-3a": {
                    "questionId": "GAD-3a",
                    "chosenOptionIds": [
                        "bdba5003-2589-507d-bb90-53534cc9b942"
                    ]
                },
                "GAD-4a": {
                    "questionId": "GAD-4a",
                    "chosenOptionIds": [
                        "84762e8e-edca-5c2c-a00f-fed69c13b87c"
                    ]
                },
                "GAD-5a": {
                    "questionId": "GAD-5a",
                    "chosenOptionIds": [
                        "59b5a96e-b0f6-5fd3-8c20-57c053f14b06"
                    ]
                },
                "GAD-6a": {
                    "questionId": "GAD-6a",
                    "chosenOptionIds": [
                        "8996813a-e012-57cb-8eab-b6926c27eba2"
                    ]
                },
                "GAD-7a": {
                    "questionId": "GAD-7a",
                    "chosenOptionIds": [
                        "80dffd87-d3ca-5977-9aee-b90eebcc4eb1"
                    ]
                },
                "GAD-8a": {
                    "questionId": "GAD-8a",
                    "chosenOptionIds": [
                        "a7ecd81c-cd6a-5592-892f-8e391b9a115c"
                    ]
                },
                "GAD-9a": {
                    "questionId": "GAD-9a",
                    "chosenOptionIds": [
                        "b7c86f29-540d-56d7-a59a-7035d91396d8"
                    ]
                },
                "GAD-10a": {
                    "questionId": "GAD-10a",
                    "chosenOptionIds": [
                        "e16d33eb-317e-55aa-90fc-147bde51e99b"
                    ]
                },
                "GAD-11a": {
                    "questionId": "GAD-11a",
                    "chosenOptionIds": [
                        "62411dbb-3d28-57dc-ade4-d19a340fc6ed"
                    ]
                },
                "GAD-12a": {
                    "questionId": "GAD-12a",
                    "chosenOptionIds": [
                        "75847021-68a5-5e54-938f-ebae91570246"
                    ]
                },
                "GAD-13a": {
                    "questionId": "GAD-13a",
                    "chosenOptionIds": [
                        "40abb98e-db00-5505-85be-59a5dd4ef092"
                    ]
                },
                "GAD-14a": {
                    "questionId": "GAD-14a",
                    "chosenOptionIds": [
                        "0623617f-3483-5ad7-ba3a-dd6b95c269d5"
                    ]
                },
                "GAD-15a": {
                    "questionId": "GAD-15a",
                    "chosenOptionIds": [
                        "39f33fe3-3b5e-551a-8902-6a8c7ab4c148"
                    ]
                },
                "GAD-17a": {
                    "questionId": "GAD-17a",
                    "chosenOptionIds": [
                        "93c614a5-158b-54bb-b179-2d356411ab81"
                    ]
                },
                "GAD-18a": {
                    "questionId": "GAD-18a",
                    "chosenOptionIds": [
                        "7035a10f-7dd7-55bd-946c-2cc73ffa5e6e"
                    ]
                },
                "GAD-19.0a": {
                    "questionId": "GAD-19.0a",
                    "chosenOptionIds": [
                        "1341da8c-ffc8-5e54-9f02-4b2126601994"
                    ]
                },
                "GAD-19a": {
                    "questionId": "GAD-19a",
                    "chosenOptionIds": [
                        "51801ae8-62d1-5a94-b757-a0b0851ac6bc",
                        "3c648d60-89ec-5e44-9bf1-dc362139d849",
                        "863da48d-f2eb-52f4-8c4e-feda60cda5c4",
                        "67df9ae6-c783-5f09-9975-a117ab653011",
                        "0a747bd1-8eee-563f-a6ae-8823404898a5"
                    ]
                },
                "GAD-22a": {
                    "questionId": "GAD-22a",
                    "chosenOptionIds": [
                        "09fba5ed-bf0e-5aa9-92a5-50c6a01ae0e2"
                    ]
                }
            }
        },
        {
            "name": "11_GAD_severity4current-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.675,
                "threshold": 0.01
            },
            "answers": {
                "GAD-S": {
                    "questionId": "GAD-S",
                    "chosenOptionIds": [
                        "aa668802-d458-5b2d-b48f-e4058a640295"
                    ]
                },
                "GAD-s1": {
                    "questionId": "GAD-s1",
                    "chosenOptionIds": [
                        "24032a9e-f5b6-5f43-8ddf-305888669ad1"
                    ]
                },
                "GAD-s3a": {
                    "questionId": "GAD-s3a",
                    "chosenOptionIds": [
                        "ed0ad572-166e-587e-b191-2cbe81387f5a"
                    ]
                },
                "GAD-s4a": {
                    "questionId": "GAD-s4a",
                    "chosenOptionIds": [
                        "5a1c5af4-b628-5554-bf63-4ca68ef2b328"
                    ]
                },
                "GAD-s6a": {
                    "questionId": "GAD-s6a",
                    "chosenOptionIds": [
                        "6e2595a2-ea1e-5ca5-ab11-c65eb6cb6157"
                    ]
                },
                "GAD-3a": {
                    "questionId": "GAD-3a",
                    "chosenOptionIds": [
                        "bdba5003-2589-507d-bb90-53534cc9b942"
                    ]
                },
                "GAD-4a": {
                    "questionId": "GAD-4a",
                    "chosenOptionIds": [
                        "748f4a0b-00a7-5b15-b390-2dfb8ac9eb1d"
                    ]
                },
                "GAD-5a": {
                    "questionId": "GAD-5a",
                    "chosenOptionIds": [
                        "6bb8be71-d051-540f-8d81-5dff1869bbcc"
                    ]
                },
                "GAD-6a": {
                    "questionId": "GAD-6a",
                    "chosenOptionIds": [
                        "f26a3508-c284-53a5-8bc7-63514fbe6737"
                    ]
                },
                "GAD-7a": {
                    "questionId": "GAD-7a",
                    "chosenOptionIds": [
                        "3b9e5247-4c0f-5c9d-9ffb-822ab95bee8a"
                    ]
                },
                "GAD-8a": {
                    "questionId": "GAD-8a",
                    "chosenOptionIds": [
                        "985dc1a3-7ea9-5f5c-8af3-6159ac9c90bf"
                    ]
                },
                "GAD-9a": {
                    "questionId": "GAD-9a",
                    "chosenOptionIds": [
                        "35150196-067f-58af-b669-6e41443a3601"
                    ]
                },
                "GAD-10a": {
                    "questionId": "GAD-10a",
                    "chosenOptionIds": [
                        "e16d33eb-317e-55aa-90fc-147bde51e99b"
                    ]
                },
                "GAD-11a": {
                    "questionId": "GAD-11a",
                    "chosenOptionIds": [
                        "c1926d2c-b894-5ae1-bbdd-f8c9954ce55a"
                    ]
                },
                "GAD-12a": {
                    "questionId": "GAD-12a",
                    "chosenOptionIds": [
                        "4fb31d91-3520-5676-90c0-d3c05e9abfb8"
                    ]
                },
                "GAD-13a": {
                    "questionId": "GAD-13a",
                    "chosenOptionIds": [
                        "42da2d35-01a4-50f9-95e5-c7bbc4c8eda7"
                    ]
                },
                "GAD-14a": {
                    "questionId": "GAD-14a",
                    "chosenOptionIds": [
                        "0623617f-3483-5ad7-ba3a-dd6b95c269d5"
                    ]
                },
                "GAD-15a": {
                    "questionId": "GAD-15a",
                    "chosenOptionIds": [
                        "632e07d1-df69-590c-a8f2-0b4f7ad343e1"
                    ]
                },
                "GAD-17a": {
                    "questionId": "GAD-17a",
                    "chosenOptionIds": [
                        "ca6c7d1d-3395-58b8-a87d-95fba13b4ca3"
                    ]
                },
                "GAD-18a": {
                    "questionId": "GAD-18a",
                    "chosenOptionIds": [
                        "63b22d31-0933-571a-9c46-eb63c6373884"
                    ]
                },
                "GAD-19.0a": {
                    "questionId": "GAD-19.0a",
                    "chosenOptionIds": [
                        "78df6de1-fe3e-5bed-a63d-f5f9877809af"
                    ]
                },
                "GAD-19a": {
                    "questionId": "GAD-19a",
                    "chosenOptionIds": [
                        "a7dc1041-6095-5630-9480-ed7c33997997",
                        "863da48d-f2eb-52f4-8c4e-feda60cda5c4",
                        "1acf1647-150f-5031-9599-8db8e23c423d",
                        "4ca41a8f-b524-5ef7-af86-7ef9279a7969"
                    ]
                },
                "GAD-22a": {
                    "questionId": "GAD-22a",
                    "chosenOptionIds": [
                        "564b4697-78ea-5000-bb19-e1ed63265906"
                    ]
                }
            }
        },
        {
            "name": "11_GAD_severity3current-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.27,
                "threshold": 0
            },
            "answers": {
                "GAD-S": {
                    "questionId": "GAD-S",
                    "chosenOptionIds": [
                        "aa668802-d458-5b2d-b48f-e4058a640295"
                    ]
                },
                "GAD-s1": {
                    "questionId": "GAD-s1",
                    "chosenOptionIds": [
                        "f8d49124-01dc-5d55-add9-5ed87d998e00"
                    ]
                },
                "GAD-s3a": {
                    "questionId": "GAD-s3a",
                    "chosenOptionIds": [
                        "4bbc59ac-1581-5084-9e92-2b746637ff52"
                    ]
                },
                "GAD-s4a": {
                    "questionId": "GAD-s4a",
                    "chosenOptionIds": [
                        "5a1c5af4-b628-5554-bf63-4ca68ef2b328"
                    ]
                },
                "GAD-s6a": {
                    "questionId": "GAD-s6a",
                    "chosenOptionIds": [
                        "4059602c-013b-5f54-890f-64e55583f3dc"
                    ]
                },
                "GAD-3a": {
                    "questionId": "GAD-3a",
                    "chosenOptionIds": [
                        "bdba5003-2589-507d-bb90-53534cc9b942"
                    ]
                },
                "GAD-4a": {
                    "questionId": "GAD-4a",
                    "chosenOptionIds": [
                        "517a9806-ffe8-52a9-86df-e947a47e169c"
                    ]
                },
                "GAD-5a": {
                    "questionId": "GAD-5a",
                    "chosenOptionIds": [
                        "bd66ed3d-4e63-5fe8-88a6-fbbf3ab55502"
                    ]
                },
                "GAD-6a": {
                    "questionId": "GAD-6a",
                    "chosenOptionIds": [
                        "f26a3508-c284-53a5-8bc7-63514fbe6737"
                    ]
                },
                "GAD-7a": {
                    "questionId": "GAD-7a",
                    "chosenOptionIds": [
                        "d7d05c32-44cb-59c7-84b0-95e98fde6077"
                    ]
                },
                "GAD-8a": {
                    "questionId": "GAD-8a",
                    "chosenOptionIds": [
                        "e1dd4f50-28b4-51eb-8c34-bf8d3145816f"
                    ]
                },
                "GAD-9a": {
                    "questionId": "GAD-9a",
                    "chosenOptionIds": [
                        "35150196-067f-58af-b669-6e41443a3601"
                    ]
                },
                "GAD-10a": {
                    "questionId": "GAD-10a",
                    "chosenOptionIds": [
                        "44ddd9b1-23aa-570e-bbce-1bef374fe68a"
                    ]
                },
                "GAD-11a": {
                    "questionId": "GAD-11a",
                    "chosenOptionIds": [
                        "8ce6bca8-7cea-5cae-b31f-9b03eb4f7322"
                    ]
                },
                "GAD-12a": {
                    "questionId": "GAD-12a",
                    "chosenOptionIds": [
                        "4fb31d91-3520-5676-90c0-d3c05e9abfb8"
                    ]
                },
                "GAD-13a": {
                    "questionId": "GAD-13a",
                    "chosenOptionIds": [
                        "40abb98e-db00-5505-85be-59a5dd4ef092"
                    ]
                },
                "GAD-14a": {
                    "questionId": "GAD-14a",
                    "chosenOptionIds": [
                        "b0335c5e-d91f-5e19-9a45-4936bd9e3bea"
                    ]
                },
                "GAD-15a": {
                    "questionId": "GAD-15a",
                    "chosenOptionIds": [
                        "632e07d1-df69-590c-a8f2-0b4f7ad343e1"
                    ]
                },
                "GAD-17a": {
                    "questionId": "GAD-17a",
                    "chosenOptionIds": [
                        "f468532b-0dfb-546a-87bc-4840ffcab3e0"
                    ]
                },
                "GAD-18a": {
                    "questionId": "GAD-18a",
                    "chosenOptionIds": [
                        "af347157-5a07-5187-b207-b672ecf979dc"
                    ]
                },
                "GAD-19.0a": {
                    "questionId": "GAD-19.0a",
                    "chosenOptionIds": [
                        "4196a0b8-4e73-5d21-903f-594531ff22f0"
                    ]
                },
                "GAD-22a": {
                    "questionId": "GAD-22a",
                    "chosenOptionIds": [
                        "564b4697-78ea-5000-bb19-e1ed63265906"
                    ]
                }
            }
        },
        {
            "name": "11_GAD_severity5current-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.755,
                "threshold": 0.01
            },
            "answers": {
                "GAD-S": {
                    "questionId": "GAD-S",
                    "chosenOptionIds": [
                        "aa668802-d458-5b2d-b48f-e4058a640295"
                    ]
                },
                "GAD-s1": {
                    "questionId": "GAD-s1",
                    "chosenOptionIds": [
                        "f8d49124-01dc-5d55-add9-5ed87d998e00"
                    ]
                },
                "GAD-s3a": {
                    "questionId": "GAD-s3a",
                    "chosenOptionIds": [
                        "ed0ad572-166e-587e-b191-2cbe81387f5a"
                    ]
                },
                "GAD-s4a": {
                    "questionId": "GAD-s4a",
                    "chosenOptionIds": [
                        "5a1c5af4-b628-5554-bf63-4ca68ef2b328"
                    ]
                },
                "GAD-s6a": {
                    "questionId": "GAD-s6a",
                    "chosenOptionIds": [
                        "6e2595a2-ea1e-5ca5-ab11-c65eb6cb6157"
                    ]
                },
                "GAD-3a": {
                    "questionId": "GAD-3a",
                    "chosenOptionIds": [
                        "fa80af33-1ed9-5e02-abd3-85852c65204e"
                    ]
                },
                "GAD-4a": {
                    "questionId": "GAD-4a",
                    "chosenOptionIds": [
                        "748f4a0b-00a7-5b15-b390-2dfb8ac9eb1d"
                    ]
                },
                "GAD-5a": {
                    "questionId": "GAD-5a",
                    "chosenOptionIds": [
                        "bd66ed3d-4e63-5fe8-88a6-fbbf3ab55502"
                    ]
                },
                "GAD-6a": {
                    "questionId": "GAD-6a",
                    "chosenOptionIds": [
                        "81a08016-21d5-5f6e-b174-710e540e00c1"
                    ]
                },
                "GAD-7a": {
                    "questionId": "GAD-7a",
                    "chosenOptionIds": [
                        "d7d05c32-44cb-59c7-84b0-95e98fde6077"
                    ]
                },
                "GAD-8a": {
                    "questionId": "GAD-8a",
                    "chosenOptionIds": [
                        "985dc1a3-7ea9-5f5c-8af3-6159ac9c90bf"
                    ]
                },
                "GAD-9a": {
                    "questionId": "GAD-9a",
                    "chosenOptionIds": [
                        "b7c86f29-540d-56d7-a59a-7035d91396d8"
                    ]
                },
                "GAD-10a": {
                    "questionId": "GAD-10a",
                    "chosenOptionIds": [
                        "e36ed8e3-ca0e-5d7e-826f-84c47842e71c"
                    ]
                },
                "GAD-11a": {
                    "questionId": "GAD-11a",
                    "chosenOptionIds": [
                        "ba121413-fa3c-5d39-960f-827d7b0d55b8"
                    ]
                },
                "GAD-12a": {
                    "questionId": "GAD-12a",
                    "chosenOptionIds": [
                        "75847021-68a5-5e54-938f-ebae91570246"
                    ]
                },
                "GAD-13a": {
                    "questionId": "GAD-13a",
                    "chosenOptionIds": [
                        "42da2d35-01a4-50f9-95e5-c7bbc4c8eda7"
                    ]
                },
                "GAD-14a": {
                    "questionId": "GAD-14a",
                    "chosenOptionIds": [
                        "b0335c5e-d91f-5e19-9a45-4936bd9e3bea"
                    ]
                },
                "GAD-15a": {
                    "questionId": "GAD-15a",
                    "chosenOptionIds": [
                        "6447e557-156f-59a1-9cbc-cd9d4b9bcbee"
                    ]
                },
                "GAD-17a": {
                    "questionId": "GAD-17a",
                    "chosenOptionIds": [
                        "93c614a5-158b-54bb-b179-2d356411ab81"
                    ]
                },
                "GAD-18a": {
                    "questionId": "GAD-18a",
                    "chosenOptionIds": [
                        "af347157-5a07-5187-b207-b672ecf979dc"
                    ]
                },
                "GAD-19.0a": {
                    "questionId": "GAD-19.0a",
                    "chosenOptionIds": [
                        "78df6de1-fe3e-5bed-a63d-f5f9877809af"
                    ]
                },
                "GAD-19a": {
                    "questionId": "GAD-19a",
                    "chosenOptionIds": [
                        "863da48d-f2eb-52f4-8c4e-feda60cda5c4",
                        "1acf1647-150f-5031-9599-8db8e23c423d",
                        "0a747bd1-8eee-563f-a6ae-8823404898a5"
                    ]
                },
                "GAD-22a": {
                    "questionId": "GAD-22a",
                    "chosenOptionIds": [
                        "8b112b00-59f5-5465-a3c7-d3eed9586abf"
                    ]
                }
            }
        },
        {
            "name": "11_GAD_severity3-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.195,
                "threshold": 0.01
            },
            "answers": {
                "GAD-S": {
                    "questionId": "GAD-S",
                    "chosenOptionIds": [
                        "aa668802-d458-5b2d-b48f-e4058a640295"
                    ]
                },
                "GAD-s1": {
                    "questionId": "GAD-s1",
                    "chosenOptionIds": [
                        "24032a9e-f5b6-5f43-8ddf-305888669ad1"
                    ]
                },
                "GAD-s3a": {
                    "questionId": "GAD-s3a",
                    "chosenOptionIds": [
                        "4bbc59ac-1581-5084-9e92-2b746637ff52"
                    ]
                },
                "GAD-s4a": {
                    "questionId": "GAD-s4a",
                    "chosenOptionIds": [
                        "0caeb854-db8e-5a20-a344-0c9282f65f78"
                    ]
                },
                "GAD-s6a": {
                    "questionId": "GAD-s6a",
                    "chosenOptionIds": [
                        "4059602c-013b-5f54-890f-64e55583f3dc"
                    ]
                },
                "GAD-3a": {
                    "questionId": "GAD-3a",
                    "chosenOptionIds": [
                        "fa80af33-1ed9-5e02-abd3-85852c65204e"
                    ]
                },
                "GAD-4a": {
                    "questionId": "GAD-4a",
                    "chosenOptionIds": [
                        "748f4a0b-00a7-5b15-b390-2dfb8ac9eb1d"
                    ]
                },
                "GAD-5a": {
                    "questionId": "GAD-5a",
                    "chosenOptionIds": [
                        "6bb8be71-d051-540f-8d81-5dff1869bbcc"
                    ]
                },
                "GAD-6a": {
                    "questionId": "GAD-6a",
                    "chosenOptionIds": [
                        "7d60ebb2-5f2d-5ab0-b9ca-31210502ece9"
                    ]
                },
                "GAD-7a": {
                    "questionId": "GAD-7a",
                    "chosenOptionIds": [
                        "8dc41a6e-72d4-5c1f-a416-f085995a1878"
                    ]
                },
                "GAD-8a": {
                    "questionId": "GAD-8a",
                    "chosenOptionIds": [
                        "e1dd4f50-28b4-51eb-8c34-bf8d3145816f"
                    ]
                },
                "GAD-9a": {
                    "questionId": "GAD-9a",
                    "chosenOptionIds": [
                        "03b91bf0-49f5-5760-ae70-8e92ca65aa0e"
                    ]
                },
                "GAD-10a": {
                    "questionId": "GAD-10a",
                    "chosenOptionIds": [
                        "e16d33eb-317e-55aa-90fc-147bde51e99b"
                    ]
                },
                "GAD-11a": {
                    "questionId": "GAD-11a",
                    "chosenOptionIds": [
                        "ba121413-fa3c-5d39-960f-827d7b0d55b8"
                    ]
                },
                "GAD-12a": {
                    "questionId": "GAD-12a",
                    "chosenOptionIds": [
                        "4fb31d91-3520-5676-90c0-d3c05e9abfb8"
                    ]
                },
                "GAD-13a": {
                    "questionId": "GAD-13a",
                    "chosenOptionIds": [
                        "42da2d35-01a4-50f9-95e5-c7bbc4c8eda7"
                    ]
                },
                "GAD-14a": {
                    "questionId": "GAD-14a",
                    "chosenOptionIds": [
                        "8f4ab7fd-f018-5862-af97-d98aa222664a"
                    ]
                },
                "GAD-15a": {
                    "questionId": "GAD-15a",
                    "chosenOptionIds": [
                        "632e07d1-df69-590c-a8f2-0b4f7ad343e1"
                    ]
                },
                "GAD-17a": {
                    "questionId": "GAD-17a",
                    "chosenOptionIds": [
                        "ca6c7d1d-3395-58b8-a87d-95fba13b4ca3"
                    ]
                },
                "GAD-18a": {
                    "questionId": "GAD-18a",
                    "chosenOptionIds": [
                        "63b22d31-0933-571a-9c46-eb63c6373884"
                    ]
                },
                "GAD-19.0a": {
                    "questionId": "GAD-19.0a",
                    "chosenOptionIds": [
                        "a0520f66-594b-5c56-bebc-a0d018d139d9"
                    ]
                },
                "GAD-19a": {
                    "questionId": "GAD-19a",
                    "chosenOptionIds": [
                        "a7dc1041-6095-5630-9480-ed7c33997997"
                    ]
                },
                "GAD-22a": {
                    "questionId": "GAD-22a",
                    "chosenOptionIds": [
                        "564b4697-78ea-5000-bb19-e1ed63265906"
                    ]
                }
            }
        },
        {
            "name": "11_GAD_severity5current_primary-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.75,
                "threshold": 0.02
            },
            "answers": {
                "GAD-S": {
                    "questionId": "GAD-S",
                    "chosenOptionIds": [
                        "aa668802-d458-5b2d-b48f-e4058a640295"
                    ]
                },
                "GAD-s1": {
                    "questionId": "GAD-s1",
                    "chosenOptionIds": [
                        "f8d49124-01dc-5d55-add9-5ed87d998e00"
                    ]
                },
                "GAD-s3a": {
                    "questionId": "GAD-s3a",
                    "chosenOptionIds": [
                        "ed0ad572-166e-587e-b191-2cbe81387f5a"
                    ]
                },
                "GAD-s4a": {
                    "questionId": "GAD-s4a",
                    "chosenOptionIds": [
                        "5a1c5af4-b628-5554-bf63-4ca68ef2b328"
                    ]
                },
                "GAD-s6a": {
                    "questionId": "GAD-s6a",
                    "chosenOptionIds": [
                        "6e2595a2-ea1e-5ca5-ab11-c65eb6cb6157"
                    ]
                },
                "GAD-3a": {
                    "questionId": "GAD-3a",
                    "chosenOptionIds": [
                        "fa80af33-1ed9-5e02-abd3-85852c65204e"
                    ]
                },
                "GAD-4a": {
                    "questionId": "GAD-4a",
                    "chosenOptionIds": [
                        "748f4a0b-00a7-5b15-b390-2dfb8ac9eb1d"
                    ]
                },
                "GAD-5a": {
                    "questionId": "GAD-5a",
                    "chosenOptionIds": [
                        "bd66ed3d-4e63-5fe8-88a6-fbbf3ab55502"
                    ]
                },
                "GAD-6a": {
                    "questionId": "GAD-6a",
                    "chosenOptionIds": [
                        "81a08016-21d5-5f6e-b174-710e540e00c1"
                    ]
                },
                "GAD-7a": {
                    "questionId": "GAD-7a",
                    "chosenOptionIds": [
                        "d7d05c32-44cb-59c7-84b0-95e98fde6077"
                    ]
                },
                "GAD-8a": {
                    "questionId": "GAD-8a",
                    "chosenOptionIds": [
                        "985dc1a3-7ea9-5f5c-8af3-6159ac9c90bf"
                    ]
                },
                "GAD-9a": {
                    "questionId": "GAD-9a",
                    "chosenOptionIds": [
                        "b7c86f29-540d-56d7-a59a-7035d91396d8"
                    ]
                },
                "GAD-10a": {
                    "questionId": "GAD-10a",
                    "chosenOptionIds": [
                        "e36ed8e3-ca0e-5d7e-826f-84c47842e71c"
                    ]
                },
                "GAD-11a": {
                    "questionId": "GAD-11a",
                    "chosenOptionIds": [
                        "8ce6bca8-7cea-5cae-b31f-9b03eb4f7322"
                    ]
                },
                "GAD-12a": {
                    "questionId": "GAD-12a",
                    "chosenOptionIds": [
                        "75847021-68a5-5e54-938f-ebae91570246"
                    ]
                },
                "GAD-13a": {
                    "questionId": "GAD-13a",
                    "chosenOptionIds": [
                        "42da2d35-01a4-50f9-95e5-c7bbc4c8eda7"
                    ]
                },
                "GAD-14a": {
                    "questionId": "GAD-14a",
                    "chosenOptionIds": [
                        "b0335c5e-d91f-5e19-9a45-4936bd9e3bea"
                    ]
                },
                "GAD-15a": {
                    "questionId": "GAD-15a",
                    "chosenOptionIds": [
                        "6447e557-156f-59a1-9cbc-cd9d4b9bcbee"
                    ]
                },
                "GAD-17a": {
                    "questionId": "GAD-17a",
                    "chosenOptionIds": [
                        "93c614a5-158b-54bb-b179-2d356411ab81"
                    ]
                },
                "GAD-18a": {
                    "questionId": "GAD-18a",
                    "chosenOptionIds": [
                        "af347157-5a07-5187-b207-b672ecf979dc"
                    ]
                },
                "GAD-19.0a": {
                    "questionId": "GAD-19.0a",
                    "chosenOptionIds": [
                        "78df6de1-fe3e-5bed-a63d-f5f9877809af"
                    ]
                },
                "GAD-19a": {
                    "questionId": "GAD-19a",
                    "chosenOptionIds": [
                        "863da48d-f2eb-52f4-8c4e-feda60cda5c4",
                        "1acf1647-150f-5031-9599-8db8e23c423d",
                        "67df9ae6-c783-5f09-9975-a117ab653011"
                    ]
                },
                "GAD-22a": {
                    "questionId": "GAD-22a",
                    "chosenOptionIds": [
                        "564b4697-78ea-5000-bb19-e1ed63265906"
                    ]
                }
            }
        }
    ],
    "show_gad_ce": [
        {
            "name": "11_GAD_severity1",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "GAD-S": {
                    "questionId": "GAD-S",
                    "chosenOptionIds": [
                        "aa668802-d458-5b2d-b48f-e4058a640295"
                    ]
                },
                "GAD-s1": {
                    "questionId": "GAD-s1",
                    "chosenOptionIds": [
                        "eaaa8028-c116-5ae3-bb59-5795820feca8"
                    ]
                }
            }
        },
        {
            "name": "11_GAD_severity2",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "GAD-S": {
                    "questionId": "GAD-S",
                    "chosenOptionIds": [
                        "aa668802-d458-5b2d-b48f-e4058a640295"
                    ]
                },
                "GAD-s1": {
                    "questionId": "GAD-s1",
                    "chosenOptionIds": [
                        "f8d49124-01dc-5d55-add9-5ed87d998e00"
                    ]
                },
                "GAD-s3a": {
                    "questionId": "GAD-s3a",
                    "chosenOptionIds": [
                        "4bbc59ac-1581-5084-9e92-2b746637ff52"
                    ]
                },
                "GAD-s4a": {
                    "questionId": "GAD-s4a",
                    "chosenOptionIds": [
                        "276f0539-3b02-5717-a333-3d5ac219a5b4"
                    ]
                },
                "GAD-s6a": {
                    "questionId": "GAD-s6a",
                    "chosenOptionIds": [
                        "6e2595a2-ea1e-5ca5-ab11-c65eb6cb6157"
                    ]
                },
                "GAD-3a": {
                    "questionId": "GAD-3a",
                    "chosenOptionIds": [
                        "fa80af33-1ed9-5e02-abd3-85852c65204e"
                    ]
                },
                "GAD-4a": {
                    "questionId": "GAD-4a",
                    "chosenOptionIds": [
                        "517a9806-ffe8-52a9-86df-e947a47e169c"
                    ]
                },
                "GAD-5a": {
                    "questionId": "GAD-5a",
                    "chosenOptionIds": [
                        "bd66ed3d-4e63-5fe8-88a6-fbbf3ab55502"
                    ]
                },
                "GAD-6a": {
                    "questionId": "GAD-6a",
                    "chosenOptionIds": [
                        "f26a3508-c284-53a5-8bc7-63514fbe6737"
                    ]
                },
                "GAD-7a": {
                    "questionId": "GAD-7a",
                    "chosenOptionIds": [
                        "d7d05c32-44cb-59c7-84b0-95e98fde6077"
                    ]
                },
                "GAD-8a": {
                    "questionId": "GAD-8a",
                    "chosenOptionIds": [
                        "e1dd4f50-28b4-51eb-8c34-bf8d3145816f"
                    ]
                },
                "GAD-9a": {
                    "questionId": "GAD-9a",
                    "chosenOptionIds": [
                        "03b91bf0-49f5-5760-ae70-8e92ca65aa0e"
                    ]
                },
                "GAD-10a": {
                    "questionId": "GAD-10a",
                    "chosenOptionIds": [
                        "e16d33eb-317e-55aa-90fc-147bde51e99b"
                    ]
                },
                "GAD-11a": {
                    "questionId": "GAD-11a",
                    "chosenOptionIds": [
                        "ba121413-fa3c-5d39-960f-827d7b0d55b8"
                    ]
                },
                "GAD-12a": {
                    "questionId": "GAD-12a",
                    "chosenOptionIds": [
                        "c74d8b74-1128-5f73-bfd2-2c3813bfdd4e"
                    ]
                },
                "GAD-13a": {
                    "questionId": "GAD-13a",
                    "chosenOptionIds": [
                        "8dda5767-c89f-5932-808d-a7729c3f935b"
                    ]
                },
                "GAD-14a": {
                    "questionId": "GAD-14a",
                    "chosenOptionIds": [
                        "6ac69add-b72f-5784-84a9-a517721463e6"
                    ]
                },
                "GAD-15a": {
                    "questionId": "GAD-15a",
                    "chosenOptionIds": [
                        "632e07d1-df69-590c-a8f2-0b4f7ad343e1"
                    ]
                },
                "GAD-17a": {
                    "questionId": "GAD-17a",
                    "chosenOptionIds": [
                        "93c614a5-158b-54bb-b179-2d356411ab81"
                    ]
                },
                "GAD-18a": {
                    "questionId": "GAD-18a",
                    "chosenOptionIds": [
                        "af347157-5a07-5187-b207-b672ecf979dc"
                    ]
                },
                "GAD-19.0a": {
                    "questionId": "GAD-19.0a",
                    "chosenOptionIds": [
                        "78df6de1-fe3e-5bed-a63d-f5f9877809af"
                    ]
                },
                "GAD-19a": {
                    "questionId": "GAD-19a",
                    "chosenOptionIds": [
                        "3c648d60-89ec-5e44-9bf1-dc362139d849",
                        "863da48d-f2eb-52f4-8c4e-feda60cda5c4",
                        "67df9ae6-c783-5f09-9975-a117ab653011"
                    ]
                },
                "GAD-22a": {
                    "questionId": "GAD-22a",
                    "chosenOptionIds": [
                        "09fba5ed-bf0e-5aa9-92a5-50c6a01ae0e2"
                    ]
                }
            }
        },
        {
            "name": "11_GAD_severity6current",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "GAD-S": {
                    "questionId": "GAD-S",
                    "chosenOptionIds": [
                        "aa668802-d458-5b2d-b48f-e4058a640295"
                    ]
                },
                "GAD-s1": {
                    "questionId": "GAD-s1",
                    "chosenOptionIds": [
                        "f8d49124-01dc-5d55-add9-5ed87d998e00"
                    ]
                },
                "GAD-s3a": {
                    "questionId": "GAD-s3a",
                    "chosenOptionIds": [
                        "adab9f0b-f6e7-54c4-9cf1-087cdf831412"
                    ]
                },
                "GAD-s4a": {
                    "questionId": "GAD-s4a",
                    "chosenOptionIds": [
                        "9d0741b6-d8b6-5216-90d0-df6743af1aa0"
                    ]
                },
                "GAD-s6a": {
                    "questionId": "GAD-s6a",
                    "chosenOptionIds": [
                        "6e2595a2-ea1e-5ca5-ab11-c65eb6cb6157"
                    ]
                },
                "GAD-3a": {
                    "questionId": "GAD-3a",
                    "chosenOptionIds": [
                        "fa80af33-1ed9-5e02-abd3-85852c65204e"
                    ]
                },
                "GAD-4a": {
                    "questionId": "GAD-4a",
                    "chosenOptionIds": [
                        "517a9806-ffe8-52a9-86df-e947a47e169c"
                    ]
                },
                "GAD-5a": {
                    "questionId": "GAD-5a",
                    "chosenOptionIds": [
                        "bd66ed3d-4e63-5fe8-88a6-fbbf3ab55502"
                    ]
                },
                "GAD-6a": {
                    "questionId": "GAD-6a",
                    "chosenOptionIds": [
                        "8996813a-e012-57cb-8eab-b6926c27eba2"
                    ]
                },
                "GAD-7a": {
                    "questionId": "GAD-7a",
                    "chosenOptionIds": [
                        "d7d05c32-44cb-59c7-84b0-95e98fde6077"
                    ]
                },
                "GAD-8a": {
                    "questionId": "GAD-8a",
                    "chosenOptionIds": [
                        "985dc1a3-7ea9-5f5c-8af3-6159ac9c90bf"
                    ]
                },
                "GAD-9a": {
                    "questionId": "GAD-9a",
                    "chosenOptionIds": [
                        "03b91bf0-49f5-5760-ae70-8e92ca65aa0e"
                    ]
                },
                "GAD-10a": {
                    "questionId": "GAD-10a",
                    "chosenOptionIds": [
                        "e16d33eb-317e-55aa-90fc-147bde51e99b"
                    ]
                },
                "GAD-11a": {
                    "questionId": "GAD-11a",
                    "chosenOptionIds": [
                        "ba121413-fa3c-5d39-960f-827d7b0d55b8"
                    ]
                },
                "GAD-12a": {
                    "questionId": "GAD-12a",
                    "chosenOptionIds": [
                        "c74d8b74-1128-5f73-bfd2-2c3813bfdd4e"
                    ]
                },
                "GAD-13a": {
                    "questionId": "GAD-13a",
                    "chosenOptionIds": [
                        "40abb98e-db00-5505-85be-59a5dd4ef092"
                    ]
                },
                "GAD-14a": {
                    "questionId": "GAD-14a",
                    "chosenOptionIds": [
                        "0623617f-3483-5ad7-ba3a-dd6b95c269d5"
                    ]
                },
                "GAD-15a": {
                    "questionId": "GAD-15a",
                    "chosenOptionIds": [
                        "39f33fe3-3b5e-551a-8902-6a8c7ab4c148"
                    ]
                },
                "GAD-17a": {
                    "questionId": "GAD-17a",
                    "chosenOptionIds": [
                        "93c614a5-158b-54bb-b179-2d356411ab81"
                    ]
                },
                "GAD-18a": {
                    "questionId": "GAD-18a",
                    "chosenOptionIds": [
                        "af347157-5a07-5187-b207-b672ecf979dc"
                    ]
                },
                "GAD-19.0a": {
                    "questionId": "GAD-19.0a",
                    "chosenOptionIds": [
                        "1341da8c-ffc8-5e54-9f02-4b2126601994"
                    ]
                },
                "GAD-19a": {
                    "questionId": "GAD-19a",
                    "chosenOptionIds": [
                        "a7dc1041-6095-5630-9480-ed7c33997997",
                        "3c648d60-89ec-5e44-9bf1-dc362139d849",
                        "0a747bd1-8eee-563f-a6ae-8823404898a5",
                        "4ca41a8f-b524-5ef7-af86-7ef9279a7969"
                    ]
                },
                "GAD-22a": {
                    "questionId": "GAD-22a",
                    "chosenOptionIds": [
                        "09fba5ed-bf0e-5aa9-92a5-50c6a01ae0e2"
                    ]
                }
            }
        },
        {
            "name": "11_GAD_severity7current",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "GAD-S": {
                    "questionId": "GAD-S",
                    "chosenOptionIds": [
                        "aa668802-d458-5b2d-b48f-e4058a640295"
                    ]
                },
                "GAD-s1": {
                    "questionId": "GAD-s1",
                    "chosenOptionIds": [
                        "c819bfe0-f79d-5132-8b3a-8604c0c5b83a"
                    ]
                },
                "GAD-s3a": {
                    "questionId": "GAD-s3a",
                    "chosenOptionIds": [
                        "1943ebd6-08ab-5b8e-a57b-7e397d303526"
                    ]
                },
                "GAD-s4a": {
                    "questionId": "GAD-s4a",
                    "chosenOptionIds": [
                        "9d0741b6-d8b6-5216-90d0-df6743af1aa0"
                    ]
                },
                "GAD-s6a": {
                    "questionId": "GAD-s6a",
                    "chosenOptionIds": [
                        "6e2595a2-ea1e-5ca5-ab11-c65eb6cb6157"
                    ]
                },
                "GAD-3a": {
                    "questionId": "GAD-3a",
                    "chosenOptionIds": [
                        "bdba5003-2589-507d-bb90-53534cc9b942"
                    ]
                },
                "GAD-4a": {
                    "questionId": "GAD-4a",
                    "chosenOptionIds": [
                        "84762e8e-edca-5c2c-a00f-fed69c13b87c"
                    ]
                },
                "GAD-5a": {
                    "questionId": "GAD-5a",
                    "chosenOptionIds": [
                        "59b5a96e-b0f6-5fd3-8c20-57c053f14b06"
                    ]
                },
                "GAD-6a": {
                    "questionId": "GAD-6a",
                    "chosenOptionIds": [
                        "8996813a-e012-57cb-8eab-b6926c27eba2"
                    ]
                },
                "GAD-7a": {
                    "questionId": "GAD-7a",
                    "chosenOptionIds": [
                        "80dffd87-d3ca-5977-9aee-b90eebcc4eb1"
                    ]
                },
                "GAD-8a": {
                    "questionId": "GAD-8a",
                    "chosenOptionIds": [
                        "a7ecd81c-cd6a-5592-892f-8e391b9a115c"
                    ]
                },
                "GAD-9a": {
                    "questionId": "GAD-9a",
                    "chosenOptionIds": [
                        "b7c86f29-540d-56d7-a59a-7035d91396d8"
                    ]
                },
                "GAD-10a": {
                    "questionId": "GAD-10a",
                    "chosenOptionIds": [
                        "e16d33eb-317e-55aa-90fc-147bde51e99b"
                    ]
                },
                "GAD-11a": {
                    "questionId": "GAD-11a",
                    "chosenOptionIds": [
                        "62411dbb-3d28-57dc-ade4-d19a340fc6ed"
                    ]
                },
                "GAD-12a": {
                    "questionId": "GAD-12a",
                    "chosenOptionIds": [
                        "75847021-68a5-5e54-938f-ebae91570246"
                    ]
                },
                "GAD-13a": {
                    "questionId": "GAD-13a",
                    "chosenOptionIds": [
                        "40abb98e-db00-5505-85be-59a5dd4ef092"
                    ]
                },
                "GAD-14a": {
                    "questionId": "GAD-14a",
                    "chosenOptionIds": [
                        "0623617f-3483-5ad7-ba3a-dd6b95c269d5"
                    ]
                },
                "GAD-15a": {
                    "questionId": "GAD-15a",
                    "chosenOptionIds": [
                        "39f33fe3-3b5e-551a-8902-6a8c7ab4c148"
                    ]
                },
                "GAD-17a": {
                    "questionId": "GAD-17a",
                    "chosenOptionIds": [
                        "93c614a5-158b-54bb-b179-2d356411ab81"
                    ]
                },
                "GAD-18a": {
                    "questionId": "GAD-18a",
                    "chosenOptionIds": [
                        "7035a10f-7dd7-55bd-946c-2cc73ffa5e6e"
                    ]
                },
                "GAD-19.0a": {
                    "questionId": "GAD-19.0a",
                    "chosenOptionIds": [
                        "1341da8c-ffc8-5e54-9f02-4b2126601994"
                    ]
                },
                "GAD-19a": {
                    "questionId": "GAD-19a",
                    "chosenOptionIds": [
                        "51801ae8-62d1-5a94-b757-a0b0851ac6bc",
                        "3c648d60-89ec-5e44-9bf1-dc362139d849",
                        "863da48d-f2eb-52f4-8c4e-feda60cda5c4",
                        "67df9ae6-c783-5f09-9975-a117ab653011",
                        "0a747bd1-8eee-563f-a6ae-8823404898a5"
                    ]
                },
                "GAD-22a": {
                    "questionId": "GAD-22a",
                    "chosenOptionIds": [
                        "09fba5ed-bf0e-5aa9-92a5-50c6a01ae0e2"
                    ]
                }
            }
        },
        {
            "name": "11_GAD_severity4current",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "GAD-S": {
                    "questionId": "GAD-S",
                    "chosenOptionIds": [
                        "aa668802-d458-5b2d-b48f-e4058a640295"
                    ]
                },
                "GAD-s1": {
                    "questionId": "GAD-s1",
                    "chosenOptionIds": [
                        "24032a9e-f5b6-5f43-8ddf-305888669ad1"
                    ]
                },
                "GAD-s3a": {
                    "questionId": "GAD-s3a",
                    "chosenOptionIds": [
                        "ed0ad572-166e-587e-b191-2cbe81387f5a"
                    ]
                },
                "GAD-s4a": {
                    "questionId": "GAD-s4a",
                    "chosenOptionIds": [
                        "5a1c5af4-b628-5554-bf63-4ca68ef2b328"
                    ]
                },
                "GAD-s6a": {
                    "questionId": "GAD-s6a",
                    "chosenOptionIds": [
                        "6e2595a2-ea1e-5ca5-ab11-c65eb6cb6157"
                    ]
                },
                "GAD-3a": {
                    "questionId": "GAD-3a",
                    "chosenOptionIds": [
                        "bdba5003-2589-507d-bb90-53534cc9b942"
                    ]
                },
                "GAD-4a": {
                    "questionId": "GAD-4a",
                    "chosenOptionIds": [
                        "748f4a0b-00a7-5b15-b390-2dfb8ac9eb1d"
                    ]
                },
                "GAD-5a": {
                    "questionId": "GAD-5a",
                    "chosenOptionIds": [
                        "6bb8be71-d051-540f-8d81-5dff1869bbcc"
                    ]
                },
                "GAD-6a": {
                    "questionId": "GAD-6a",
                    "chosenOptionIds": [
                        "f26a3508-c284-53a5-8bc7-63514fbe6737"
                    ]
                },
                "GAD-7a": {
                    "questionId": "GAD-7a",
                    "chosenOptionIds": [
                        "3b9e5247-4c0f-5c9d-9ffb-822ab95bee8a"
                    ]
                },
                "GAD-8a": {
                    "questionId": "GAD-8a",
                    "chosenOptionIds": [
                        "985dc1a3-7ea9-5f5c-8af3-6159ac9c90bf"
                    ]
                },
                "GAD-9a": {
                    "questionId": "GAD-9a",
                    "chosenOptionIds": [
                        "35150196-067f-58af-b669-6e41443a3601"
                    ]
                },
                "GAD-10a": {
                    "questionId": "GAD-10a",
                    "chosenOptionIds": [
                        "e16d33eb-317e-55aa-90fc-147bde51e99b"
                    ]
                },
                "GAD-11a": {
                    "questionId": "GAD-11a",
                    "chosenOptionIds": [
                        "c1926d2c-b894-5ae1-bbdd-f8c9954ce55a"
                    ]
                },
                "GAD-12a": {
                    "questionId": "GAD-12a",
                    "chosenOptionIds": [
                        "4fb31d91-3520-5676-90c0-d3c05e9abfb8"
                    ]
                },
                "GAD-13a": {
                    "questionId": "GAD-13a",
                    "chosenOptionIds": [
                        "42da2d35-01a4-50f9-95e5-c7bbc4c8eda7"
                    ]
                },
                "GAD-14a": {
                    "questionId": "GAD-14a",
                    "chosenOptionIds": [
                        "0623617f-3483-5ad7-ba3a-dd6b95c269d5"
                    ]
                },
                "GAD-15a": {
                    "questionId": "GAD-15a",
                    "chosenOptionIds": [
                        "632e07d1-df69-590c-a8f2-0b4f7ad343e1"
                    ]
                },
                "GAD-17a": {
                    "questionId": "GAD-17a",
                    "chosenOptionIds": [
                        "ca6c7d1d-3395-58b8-a87d-95fba13b4ca3"
                    ]
                },
                "GAD-18a": {
                    "questionId": "GAD-18a",
                    "chosenOptionIds": [
                        "63b22d31-0933-571a-9c46-eb63c6373884"
                    ]
                },
                "GAD-19.0a": {
                    "questionId": "GAD-19.0a",
                    "chosenOptionIds": [
                        "78df6de1-fe3e-5bed-a63d-f5f9877809af"
                    ]
                },
                "GAD-19a": {
                    "questionId": "GAD-19a",
                    "chosenOptionIds": [
                        "a7dc1041-6095-5630-9480-ed7c33997997",
                        "863da48d-f2eb-52f4-8c4e-feda60cda5c4",
                        "1acf1647-150f-5031-9599-8db8e23c423d",
                        "4ca41a8f-b524-5ef7-af86-7ef9279a7969"
                    ]
                },
                "GAD-22a": {
                    "questionId": "GAD-22a",
                    "chosenOptionIds": [
                        "564b4697-78ea-5000-bb19-e1ed63265906"
                    ]
                }
            }
        },
        {
            "name": "11_GAD_severity3current",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "GAD-S": {
                    "questionId": "GAD-S",
                    "chosenOptionIds": [
                        "aa668802-d458-5b2d-b48f-e4058a640295"
                    ]
                },
                "GAD-s1": {
                    "questionId": "GAD-s1",
                    "chosenOptionIds": [
                        "f8d49124-01dc-5d55-add9-5ed87d998e00"
                    ]
                },
                "GAD-s3a": {
                    "questionId": "GAD-s3a",
                    "chosenOptionIds": [
                        "4bbc59ac-1581-5084-9e92-2b746637ff52"
                    ]
                },
                "GAD-s4a": {
                    "questionId": "GAD-s4a",
                    "chosenOptionIds": [
                        "5a1c5af4-b628-5554-bf63-4ca68ef2b328"
                    ]
                },
                "GAD-s6a": {
                    "questionId": "GAD-s6a",
                    "chosenOptionIds": [
                        "4059602c-013b-5f54-890f-64e55583f3dc"
                    ]
                },
                "GAD-3a": {
                    "questionId": "GAD-3a",
                    "chosenOptionIds": [
                        "bdba5003-2589-507d-bb90-53534cc9b942"
                    ]
                },
                "GAD-4a": {
                    "questionId": "GAD-4a",
                    "chosenOptionIds": [
                        "517a9806-ffe8-52a9-86df-e947a47e169c"
                    ]
                },
                "GAD-5a": {
                    "questionId": "GAD-5a",
                    "chosenOptionIds": [
                        "bd66ed3d-4e63-5fe8-88a6-fbbf3ab55502"
                    ]
                },
                "GAD-6a": {
                    "questionId": "GAD-6a",
                    "chosenOptionIds": [
                        "f26a3508-c284-53a5-8bc7-63514fbe6737"
                    ]
                },
                "GAD-7a": {
                    "questionId": "GAD-7a",
                    "chosenOptionIds": [
                        "d7d05c32-44cb-59c7-84b0-95e98fde6077"
                    ]
                },
                "GAD-8a": {
                    "questionId": "GAD-8a",
                    "chosenOptionIds": [
                        "e1dd4f50-28b4-51eb-8c34-bf8d3145816f"
                    ]
                },
                "GAD-9a": {
                    "questionId": "GAD-9a",
                    "chosenOptionIds": [
                        "35150196-067f-58af-b669-6e41443a3601"
                    ]
                },
                "GAD-10a": {
                    "questionId": "GAD-10a",
                    "chosenOptionIds": [
                        "44ddd9b1-23aa-570e-bbce-1bef374fe68a"
                    ]
                },
                "GAD-11a": {
                    "questionId": "GAD-11a",
                    "chosenOptionIds": [
                        "8ce6bca8-7cea-5cae-b31f-9b03eb4f7322"
                    ]
                },
                "GAD-12a": {
                    "questionId": "GAD-12a",
                    "chosenOptionIds": [
                        "4fb31d91-3520-5676-90c0-d3c05e9abfb8"
                    ]
                },
                "GAD-13a": {
                    "questionId": "GAD-13a",
                    "chosenOptionIds": [
                        "40abb98e-db00-5505-85be-59a5dd4ef092"
                    ]
                },
                "GAD-14a": {
                    "questionId": "GAD-14a",
                    "chosenOptionIds": [
                        "b0335c5e-d91f-5e19-9a45-4936bd9e3bea"
                    ]
                },
                "GAD-15a": {
                    "questionId": "GAD-15a",
                    "chosenOptionIds": [
                        "632e07d1-df69-590c-a8f2-0b4f7ad343e1"
                    ]
                },
                "GAD-17a": {
                    "questionId": "GAD-17a",
                    "chosenOptionIds": [
                        "f468532b-0dfb-546a-87bc-4840ffcab3e0"
                    ]
                },
                "GAD-18a": {
                    "questionId": "GAD-18a",
                    "chosenOptionIds": [
                        "af347157-5a07-5187-b207-b672ecf979dc"
                    ]
                },
                "GAD-19.0a": {
                    "questionId": "GAD-19.0a",
                    "chosenOptionIds": [
                        "4196a0b8-4e73-5d21-903f-594531ff22f0"
                    ]
                },
                "GAD-22a": {
                    "questionId": "GAD-22a",
                    "chosenOptionIds": [
                        "564b4697-78ea-5000-bb19-e1ed63265906"
                    ]
                }
            }
        },
        {
            "name": "11_GAD_severity5current",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "GAD-S": {
                    "questionId": "GAD-S",
                    "chosenOptionIds": [
                        "aa668802-d458-5b2d-b48f-e4058a640295"
                    ]
                },
                "GAD-s1": {
                    "questionId": "GAD-s1",
                    "chosenOptionIds": [
                        "f8d49124-01dc-5d55-add9-5ed87d998e00"
                    ]
                },
                "GAD-s3a": {
                    "questionId": "GAD-s3a",
                    "chosenOptionIds": [
                        "ed0ad572-166e-587e-b191-2cbe81387f5a"
                    ]
                },
                "GAD-s4a": {
                    "questionId": "GAD-s4a",
                    "chosenOptionIds": [
                        "5a1c5af4-b628-5554-bf63-4ca68ef2b328"
                    ]
                },
                "GAD-s6a": {
                    "questionId": "GAD-s6a",
                    "chosenOptionIds": [
                        "6e2595a2-ea1e-5ca5-ab11-c65eb6cb6157"
                    ]
                },
                "GAD-3a": {
                    "questionId": "GAD-3a",
                    "chosenOptionIds": [
                        "fa80af33-1ed9-5e02-abd3-85852c65204e"
                    ]
                },
                "GAD-4a": {
                    "questionId": "GAD-4a",
                    "chosenOptionIds": [
                        "748f4a0b-00a7-5b15-b390-2dfb8ac9eb1d"
                    ]
                },
                "GAD-5a": {
                    "questionId": "GAD-5a",
                    "chosenOptionIds": [
                        "bd66ed3d-4e63-5fe8-88a6-fbbf3ab55502"
                    ]
                },
                "GAD-6a": {
                    "questionId": "GAD-6a",
                    "chosenOptionIds": [
                        "81a08016-21d5-5f6e-b174-710e540e00c1"
                    ]
                },
                "GAD-7a": {
                    "questionId": "GAD-7a",
                    "chosenOptionIds": [
                        "d7d05c32-44cb-59c7-84b0-95e98fde6077"
                    ]
                },
                "GAD-8a": {
                    "questionId": "GAD-8a",
                    "chosenOptionIds": [
                        "985dc1a3-7ea9-5f5c-8af3-6159ac9c90bf"
                    ]
                },
                "GAD-9a": {
                    "questionId": "GAD-9a",
                    "chosenOptionIds": [
                        "b7c86f29-540d-56d7-a59a-7035d91396d8"
                    ]
                },
                "GAD-10a": {
                    "questionId": "GAD-10a",
                    "chosenOptionIds": [
                        "e36ed8e3-ca0e-5d7e-826f-84c47842e71c"
                    ]
                },
                "GAD-11a": {
                    "questionId": "GAD-11a",
                    "chosenOptionIds": [
                        "ba121413-fa3c-5d39-960f-827d7b0d55b8"
                    ]
                },
                "GAD-12a": {
                    "questionId": "GAD-12a",
                    "chosenOptionIds": [
                        "75847021-68a5-5e54-938f-ebae91570246"
                    ]
                },
                "GAD-13a": {
                    "questionId": "GAD-13a",
                    "chosenOptionIds": [
                        "42da2d35-01a4-50f9-95e5-c7bbc4c8eda7"
                    ]
                },
                "GAD-14a": {
                    "questionId": "GAD-14a",
                    "chosenOptionIds": [
                        "b0335c5e-d91f-5e19-9a45-4936bd9e3bea"
                    ]
                },
                "GAD-15a": {
                    "questionId": "GAD-15a",
                    "chosenOptionIds": [
                        "6447e557-156f-59a1-9cbc-cd9d4b9bcbee"
                    ]
                },
                "GAD-17a": {
                    "questionId": "GAD-17a",
                    "chosenOptionIds": [
                        "93c614a5-158b-54bb-b179-2d356411ab81"
                    ]
                },
                "GAD-18a": {
                    "questionId": "GAD-18a",
                    "chosenOptionIds": [
                        "af347157-5a07-5187-b207-b672ecf979dc"
                    ]
                },
                "GAD-19.0a": {
                    "questionId": "GAD-19.0a",
                    "chosenOptionIds": [
                        "78df6de1-fe3e-5bed-a63d-f5f9877809af"
                    ]
                },
                "GAD-19a": {
                    "questionId": "GAD-19a",
                    "chosenOptionIds": [
                        "863da48d-f2eb-52f4-8c4e-feda60cda5c4",
                        "1acf1647-150f-5031-9599-8db8e23c423d",
                        "0a747bd1-8eee-563f-a6ae-8823404898a5"
                    ]
                },
                "GAD-22a": {
                    "questionId": "GAD-22a",
                    "chosenOptionIds": [
                        "8b112b00-59f5-5465-a3c7-d3eed9586abf"
                    ]
                }
            }
        },
        {
            "name": "11_GAD_severity3",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "GAD-S": {
                    "questionId": "GAD-S",
                    "chosenOptionIds": [
                        "aa668802-d458-5b2d-b48f-e4058a640295"
                    ]
                },
                "GAD-s1": {
                    "questionId": "GAD-s1",
                    "chosenOptionIds": [
                        "24032a9e-f5b6-5f43-8ddf-305888669ad1"
                    ]
                },
                "GAD-s3a": {
                    "questionId": "GAD-s3a",
                    "chosenOptionIds": [
                        "4bbc59ac-1581-5084-9e92-2b746637ff52"
                    ]
                },
                "GAD-s4a": {
                    "questionId": "GAD-s4a",
                    "chosenOptionIds": [
                        "0caeb854-db8e-5a20-a344-0c9282f65f78"
                    ]
                },
                "GAD-s6a": {
                    "questionId": "GAD-s6a",
                    "chosenOptionIds": [
                        "4059602c-013b-5f54-890f-64e55583f3dc"
                    ]
                },
                "GAD-3a": {
                    "questionId": "GAD-3a",
                    "chosenOptionIds": [
                        "fa80af33-1ed9-5e02-abd3-85852c65204e"
                    ]
                },
                "GAD-4a": {
                    "questionId": "GAD-4a",
                    "chosenOptionIds": [
                        "748f4a0b-00a7-5b15-b390-2dfb8ac9eb1d"
                    ]
                },
                "GAD-5a": {
                    "questionId": "GAD-5a",
                    "chosenOptionIds": [
                        "6bb8be71-d051-540f-8d81-5dff1869bbcc"
                    ]
                },
                "GAD-6a": {
                    "questionId": "GAD-6a",
                    "chosenOptionIds": [
                        "7d60ebb2-5f2d-5ab0-b9ca-31210502ece9"
                    ]
                },
                "GAD-7a": {
                    "questionId": "GAD-7a",
                    "chosenOptionIds": [
                        "8dc41a6e-72d4-5c1f-a416-f085995a1878"
                    ]
                },
                "GAD-8a": {
                    "questionId": "GAD-8a",
                    "chosenOptionIds": [
                        "e1dd4f50-28b4-51eb-8c34-bf8d3145816f"
                    ]
                },
                "GAD-9a": {
                    "questionId": "GAD-9a",
                    "chosenOptionIds": [
                        "03b91bf0-49f5-5760-ae70-8e92ca65aa0e"
                    ]
                },
                "GAD-10a": {
                    "questionId": "GAD-10a",
                    "chosenOptionIds": [
                        "e16d33eb-317e-55aa-90fc-147bde51e99b"
                    ]
                },
                "GAD-11a": {
                    "questionId": "GAD-11a",
                    "chosenOptionIds": [
                        "ba121413-fa3c-5d39-960f-827d7b0d55b8"
                    ]
                },
                "GAD-12a": {
                    "questionId": "GAD-12a",
                    "chosenOptionIds": [
                        "4fb31d91-3520-5676-90c0-d3c05e9abfb8"
                    ]
                },
                "GAD-13a": {
                    "questionId": "GAD-13a",
                    "chosenOptionIds": [
                        "42da2d35-01a4-50f9-95e5-c7bbc4c8eda7"
                    ]
                },
                "GAD-14a": {
                    "questionId": "GAD-14a",
                    "chosenOptionIds": [
                        "8f4ab7fd-f018-5862-af97-d98aa222664a"
                    ]
                },
                "GAD-15a": {
                    "questionId": "GAD-15a",
                    "chosenOptionIds": [
                        "632e07d1-df69-590c-a8f2-0b4f7ad343e1"
                    ]
                },
                "GAD-17a": {
                    "questionId": "GAD-17a",
                    "chosenOptionIds": [
                        "ca6c7d1d-3395-58b8-a87d-95fba13b4ca3"
                    ]
                },
                "GAD-18a": {
                    "questionId": "GAD-18a",
                    "chosenOptionIds": [
                        "63b22d31-0933-571a-9c46-eb63c6373884"
                    ]
                },
                "GAD-19.0a": {
                    "questionId": "GAD-19.0a",
                    "chosenOptionIds": [
                        "a0520f66-594b-5c56-bebc-a0d018d139d9"
                    ]
                },
                "GAD-19a": {
                    "questionId": "GAD-19a",
                    "chosenOptionIds": [
                        "a7dc1041-6095-5630-9480-ed7c33997997"
                    ]
                },
                "GAD-22a": {
                    "questionId": "GAD-22a",
                    "chosenOptionIds": [
                        "564b4697-78ea-5000-bb19-e1ed63265906"
                    ]
                }
            }
        },
        {
            "name": "11_GAD_severity4current_clinicalpartners",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "GAD-S": {
                    "questionId": "GAD-S",
                    "chosenOptionIds": [
                        "aa668802-d458-5b2d-b48f-e4058a640295"
                    ]
                },
                "GAD-s1": {
                    "questionId": "GAD-s1",
                    "chosenOptionIds": [
                        "24032a9e-f5b6-5f43-8ddf-305888669ad1"
                    ]
                },
                "GAD-s3a": {
                    "questionId": "GAD-s3a",
                    "chosenOptionIds": [
                        "ed0ad572-166e-587e-b191-2cbe81387f5a"
                    ]
                },
                "GAD-s4a": {
                    "questionId": "GAD-s4a",
                    "chosenOptionIds": [
                        "276f0539-3b02-5717-a333-3d5ac219a5b4"
                    ]
                },
                "GAD-s6a": {
                    "questionId": "GAD-s6a",
                    "chosenOptionIds": [
                        "4059602c-013b-5f54-890f-64e55583f3dc"
                    ]
                },
                "GAD-3a": {
                    "questionId": "GAD-3a",
                    "chosenOptionIds": [
                        "fa80af33-1ed9-5e02-abd3-85852c65204e"
                    ]
                },
                "GAD-4a": {
                    "questionId": "GAD-4a",
                    "chosenOptionIds": [
                        "748f4a0b-00a7-5b15-b390-2dfb8ac9eb1d"
                    ]
                },
                "GAD-5a": {
                    "questionId": "GAD-5a",
                    "chosenOptionIds": [
                        "6bb8be71-d051-540f-8d81-5dff1869bbcc"
                    ]
                },
                "GAD-6a": {
                    "questionId": "GAD-6a",
                    "chosenOptionIds": [
                        "7d60ebb2-5f2d-5ab0-b9ca-31210502ece9"
                    ]
                },
                "GAD-7a": {
                    "questionId": "GAD-7a",
                    "chosenOptionIds": [
                        "3b9e5247-4c0f-5c9d-9ffb-822ab95bee8a"
                    ]
                },
                "GAD-8a": {
                    "questionId": "GAD-8a",
                    "chosenOptionIds": [
                        "985dc1a3-7ea9-5f5c-8af3-6159ac9c90bf"
                    ]
                },
                "GAD-9a": {
                    "questionId": "GAD-9a",
                    "chosenOptionIds": [
                        "35150196-067f-58af-b669-6e41443a3601"
                    ]
                },
                "GAD-10a": {
                    "questionId": "GAD-10a",
                    "chosenOptionIds": [
                        "e16d33eb-317e-55aa-90fc-147bde51e99b"
                    ]
                },
                "GAD-11a": {
                    "questionId": "GAD-11a",
                    "chosenOptionIds": [
                        "68c692fb-cc63-5ad6-b88e-5e45ad971c82"
                    ]
                },
                "GAD-12a": {
                    "questionId": "GAD-12a",
                    "chosenOptionIds": [
                        "89cda9bd-e311-53fa-95c1-1adab2202633"
                    ]
                },
                "GAD-13a": {
                    "questionId": "GAD-13a",
                    "chosenOptionIds": [
                        "42da2d35-01a4-50f9-95e5-c7bbc4c8eda7"
                    ]
                },
                "GAD-14a": {
                    "questionId": "GAD-14a",
                    "chosenOptionIds": [
                        "6ac69add-b72f-5784-84a9-a517721463e6"
                    ]
                },
                "GAD-15a": {
                    "questionId": "GAD-15a",
                    "chosenOptionIds": [
                        "632e07d1-df69-590c-a8f2-0b4f7ad343e1"
                    ]
                },
                "GAD-17a": {
                    "questionId": "GAD-17a",
                    "chosenOptionIds": [
                        "f468532b-0dfb-546a-87bc-4840ffcab3e0"
                    ]
                },
                "GAD-18a": {
                    "questionId": "GAD-18a",
                    "chosenOptionIds": [
                        "63b22d31-0933-571a-9c46-eb63c6373884"
                    ]
                },
                "GAD-19.0a": {
                    "questionId": "GAD-19.0a",
                    "chosenOptionIds": [
                        "a0520f66-594b-5c56-bebc-a0d018d139d9"
                    ]
                },
                "GAD-19a": {
                    "questionId": "GAD-19a",
                    "chosenOptionIds": [
                        "a7dc1041-6095-5630-9480-ed7c33997997",
                        "863da48d-f2eb-52f4-8c4e-feda60cda5c4",
                        "1acf1647-150f-5031-9599-8db8e23c423d",
                        "4ca41a8f-b524-5ef7-af86-7ef9279a7969"
                    ]
                },
                "GAD-22a": {
                    "questionId": "GAD-22a",
                    "chosenOptionIds": [
                        "564b4697-78ea-5000-bb19-e1ed63265906"
                    ]
                }
            }
        },
        {
            "name": "11_GAD_severity5current_primary",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "GAD-S": {
                    "questionId": "GAD-S",
                    "chosenOptionIds": [
                        "aa668802-d458-5b2d-b48f-e4058a640295"
                    ]
                },
                "GAD-s1": {
                    "questionId": "GAD-s1",
                    "chosenOptionIds": [
                        "f8d49124-01dc-5d55-add9-5ed87d998e00"
                    ]
                },
                "GAD-s3a": {
                    "questionId": "GAD-s3a",
                    "chosenOptionIds": [
                        "ed0ad572-166e-587e-b191-2cbe81387f5a"
                    ]
                },
                "GAD-s4a": {
                    "questionId": "GAD-s4a",
                    "chosenOptionIds": [
                        "5a1c5af4-b628-5554-bf63-4ca68ef2b328"
                    ]
                },
                "GAD-s6a": {
                    "questionId": "GAD-s6a",
                    "chosenOptionIds": [
                        "6e2595a2-ea1e-5ca5-ab11-c65eb6cb6157"
                    ]
                },
                "GAD-3a": {
                    "questionId": "GAD-3a",
                    "chosenOptionIds": [
                        "fa80af33-1ed9-5e02-abd3-85852c65204e"
                    ]
                },
                "GAD-4a": {
                    "questionId": "GAD-4a",
                    "chosenOptionIds": [
                        "748f4a0b-00a7-5b15-b390-2dfb8ac9eb1d"
                    ]
                },
                "GAD-5a": {
                    "questionId": "GAD-5a",
                    "chosenOptionIds": [
                        "bd66ed3d-4e63-5fe8-88a6-fbbf3ab55502"
                    ]
                },
                "GAD-6a": {
                    "questionId": "GAD-6a",
                    "chosenOptionIds": [
                        "81a08016-21d5-5f6e-b174-710e540e00c1"
                    ]
                },
                "GAD-7a": {
                    "questionId": "GAD-7a",
                    "chosenOptionIds": [
                        "d7d05c32-44cb-59c7-84b0-95e98fde6077"
                    ]
                },
                "GAD-8a": {
                    "questionId": "GAD-8a",
                    "chosenOptionIds": [
                        "985dc1a3-7ea9-5f5c-8af3-6159ac9c90bf"
                    ]
                },
                "GAD-9a": {
                    "questionId": "GAD-9a",
                    "chosenOptionIds": [
                        "b7c86f29-540d-56d7-a59a-7035d91396d8"
                    ]
                },
                "GAD-10a": {
                    "questionId": "GAD-10a",
                    "chosenOptionIds": [
                        "e36ed8e3-ca0e-5d7e-826f-84c47842e71c"
                    ]
                },
                "GAD-11a": {
                    "questionId": "GAD-11a",
                    "chosenOptionIds": [
                        "8ce6bca8-7cea-5cae-b31f-9b03eb4f7322"
                    ]
                },
                "GAD-12a": {
                    "questionId": "GAD-12a",
                    "chosenOptionIds": [
                        "75847021-68a5-5e54-938f-ebae91570246"
                    ]
                },
                "GAD-13a": {
                    "questionId": "GAD-13a",
                    "chosenOptionIds": [
                        "42da2d35-01a4-50f9-95e5-c7bbc4c8eda7"
                    ]
                },
                "GAD-14a": {
                    "questionId": "GAD-14a",
                    "chosenOptionIds": [
                        "b0335c5e-d91f-5e19-9a45-4936bd9e3bea"
                    ]
                },
                "GAD-15a": {
                    "questionId": "GAD-15a",
                    "chosenOptionIds": [
                        "6447e557-156f-59a1-9cbc-cd9d4b9bcbee"
                    ]
                },
                "GAD-17a": {
                    "questionId": "GAD-17a",
                    "chosenOptionIds": [
                        "93c614a5-158b-54bb-b179-2d356411ab81"
                    ]
                },
                "GAD-18a": {
                    "questionId": "GAD-18a",
                    "chosenOptionIds": [
                        "af347157-5a07-5187-b207-b672ecf979dc"
                    ]
                },
                "GAD-19.0a": {
                    "questionId": "GAD-19.0a",
                    "chosenOptionIds": [
                        "78df6de1-fe3e-5bed-a63d-f5f9877809af"
                    ]
                },
                "GAD-19a": {
                    "questionId": "GAD-19a",
                    "chosenOptionIds": [
                        "863da48d-f2eb-52f4-8c4e-feda60cda5c4",
                        "1acf1647-150f-5031-9599-8db8e23c423d",
                        "67df9ae6-c783-5f09-9975-a117ab653011"
                    ]
                },
                "GAD-22a": {
                    "questionId": "GAD-22a",
                    "chosenOptionIds": [
                        "564b4697-78ea-5000-bb19-e1ed63265906"
                    ]
                }
            }
        }
    ]
};
exports.questionPredicates = {
    "GAD-s3a": "not(GAD-s1=0)",
    "GAD-s4a": "not(GAD-s1=0)",
    "GAD-s6a": "not(GAD-s1=0)",
    "GAD-3a": "not(GAD-s1=0)",
    "GAD-4a": "not(GAD-s1=0)",
    "GAD-5a": "not(GAD-s1=0)",
    "GAD-6a": "not(GAD-s1=0)",
    "GAD-7a": "not(GAD-s1=0)",
    "GAD-8a": "not(GAD-s1=0)",
    "GAD-9a": "not(GAD-s1=0)",
    "GAD-10a": "not(GAD-s1=0)",
    "GAD-11a": "not(GAD-s1=0)",
    "GAD-12a": "not(GAD-s1=0)",
    "GAD-13a": "not(GAD-s1=0)",
    "GAD-14a": "not(GAD-s1=0)",
    "GAD-15a": "not(GAD-s1=0)",
    "GAD-17a": "not(GAD-s1=0)",
    "GAD-18a": "not(GAD-s1=0)",
    "GAD-19a": "not(GAD-s1=0)",
    "GAD-19.0a": "and[or[isUndefined(GAD19a_none=1), not(GAD19a_none=1)], not(isUndefined(GAD-19a=0))]",
    "GAD-22a": "not(GAD-s1=0)",
    "GAD-END": "not(GAD-s1=0)"
};
