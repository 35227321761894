import React from 'react'
import { MobileFrame } from '@psyomics/components'
import DemoFooter from 'ui/app/components/DemoFooter'
import DemoQr from '../Demo/DemoQr/DemoQr'

interface MobileFramePageProps {
    showQRCode: boolean
    children: React.ReactNode
}

const MobileFramePage: React.FC<MobileFramePageProps> = ({ showQRCode = false, children }) => {
    return (
        <>
            <div
                style={{
                    backgroundColor: '#001919',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    paddingTop: '20px',
                    height: '100vh',
                    width: '100vw',
                    gap: '50px',
                }}
            >
                <div style={{ flex: 1 }}>
                    {showQRCode && (
                        <div style={{ marginLeft: '20px' }}>
                            {' '}
                            {/* Keeps DemoQr to the left */}
                            <DemoQr />
                        </div>
                    )}
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '20px',
                    }}
                >
                    <MobileFrame>{children}</MobileFrame>
                </div>
                <div style={{ flex: 1 }}></div>
            </div>
            <DemoFooter footerType="patient" />
        </>
    )
}

export default MobileFramePage
