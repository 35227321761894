"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filterAndSortAspects = exports.mapToAspectValue = exports.outputType = exports.evaluateWithoutStubs = exports.evaluateWithStubs = exports.aspectsWithoutStubs = exports.evaluate = exports.getSignal = exports.inlineReferences = exports.isQuestion = exports.and = exports.aspectReference = exports.answered = exports.inputEqualTo = void 0;
const circuits_1 = require("./circuits");
const sessionConfigs_1 = require("./sessionConfigs");
const inputEqualTo = (name, questionId, value) => ({
    name,
    wire: (0, circuits_1.input)({
        questionId,
        type: 'SpecificNumberInput',
        input: value,
        operator: '=',
    }),
});
exports.inputEqualTo = inputEqualTo;
const answered = (name, questionId, optionId) => ({
    name,
    wire: (0, circuits_1.input)({
        options: {
            type: 'severities',
            severities: {
                [optionId]: 1,
            },
        },
        questionId,
        type: 'AnsweredOption',
    }),
});
exports.answered = answered;
const aspectReference = (aspectName) => (0, circuits_1.input)({
    type: 'AspectReference',
    aspectName,
});
exports.aspectReference = aspectReference;
const and = (name, aspect1, aspect2) => ({
    name,
    wire: (0, circuits_1.allOf)([
        (0, circuits_1.input)({
            type: 'AspectReference',
            aspectName: aspect1.name,
        }),
        (0, circuits_1.input)({
            type: 'AspectReference',
            aspectName: aspect2.name,
        }),
    ]),
});
exports.and = and;
const isQuestion = (input) => input.type !== 'AspectReference';
exports.isQuestion = isQuestion;
const getSignalFromAnswer = (questionInput, answeredQuestions) => {
    const answeredQuestion = answeredQuestions.find((question) => question.questionId === questionInput.questionId);
    if (!answeredQuestion) {
        return undefined;
    }
    switch (questionInput.type) {
        case 'AnsweredOption': {
            const answer = answeredQuestions.find((answeredQuestions) => answeredQuestions.questionId === questionInput.questionId);
            if (answer === undefined || !answer.chosenOptionIds) {
                return undefined;
            }
            const options = questionInput.options;
            return options.type === 'severities'
                ? {
                    type: 'severity',
                    value: Math.max(...answer.chosenOptionIds.map((optionId) => options.severities[optionId] || 0)),
                }
                : {
                    type: 'predicate',
                    value: answer.chosenOptionIds
                        .map((optionId) => options.predicates[optionId] || false)
                        .some((x) => x),
                };
        }
        case 'SpecificNumberInput': {
            return answeredQuestion.freeValue === undefined || answeredQuestion.freeValue === null
                ? undefined
                : {
                    type: 'predicate',
                    value: evaluateBinaryExpression(answeredQuestion.freeValue, questionInput.operator, questionInput.input),
                };
        }
    }
};
const evaluateBinaryExpression = (lhs, operator, rhs) => {
    switch (operator) {
        case '=':
            return lhs === rhs;
        case '>=':
            return lhs >= rhs;
        case '<=':
            return lhs <= rhs;
        case '>':
            return lhs > rhs;
        case '<':
            return lhs < rhs;
    }
};
const inline = (aspects, aspectInput) => (0, exports.isQuestion)(aspectInput) || !aspects[aspectInput.aspectName]
    ? (0, circuits_1.input)(aspectInput)
    : (0, exports.inlineReferences)(aspects, aspects[aspectInput.aspectName]);
const inlineReferences = (aspects, aspect) => (0, circuits_1.replaceLeaves)((input) => inline(aspects, input), aspect);
exports.inlineReferences = inlineReferences;
const getSignal = (input, answers, stubbed) => input.type === 'AspectReference' ? stubbed[input.aspectName] : getSignalFromAnswer(input, answers);
exports.getSignal = getSignal;
const evaluate = (aspectsMap, aspectName, answers) => {
    const aspect = aspectsMap[aspectName];
    if (aspect.type === 'static') {
        return { type: 'predicate', value: true };
    }
    const signal = (0, circuits_1.evaluate)((0, circuits_1.map)((input) => (0, exports.getSignal)(input, answers, {}), (0, exports.inlineReferences)(aspectsMap, aspect)));
    return signal;
};
exports.evaluate = evaluate;
function aspectsWithoutStubs(stubbed, aspects) {
    const stubbedAspectNames = Object.keys(stubbed).reduce((acc, next) => ({
        ...acc,
        [next]: true,
    }), {});
    const withoutStubs = Object.keys(aspects).reduce((acc, next) => stubbedAspectNames[next]
        ? acc
        : {
            ...acc,
            [next]: aspects[next],
        }, {});
    return withoutStubs;
}
exports.aspectsWithoutStubs = aspectsWithoutStubs;
const evaluateWithStubs = (aspects, wire, answers, stubbed) => {
    const withoutStubs = aspectsWithoutStubs(stubbed, aspects);
    return (0, exports.evaluateWithoutStubs)(withoutStubs, wire, answers, stubbed);
};
exports.evaluateWithStubs = evaluateWithStubs;
const evaluateWithoutStubs = (withoutStubs, wire, answers, stubbed) => (0, circuits_1.evaluate)((0, circuits_1.map)((input) => (0, exports.getSignal)(input, answers, stubbed), (0, exports.inlineReferences)(withoutStubs, wire)));
exports.evaluateWithoutStubs = evaluateWithoutStubs;
const outputType = (aspects, wire) => _outputType((0, exports.inlineReferences)(aspects, wire));
exports.outputType = outputType;
const _outputType = (wire) => {
    switch (wire.type) {
        case 'filter':
        case 'isUnknown':
        case 'equals':
        case 'toPredicate':
        case 'mean':
        case 'static':
            return 'predicate';
        case 'input':
            // assume everything inlined and only multiple choice
            return wire.signal.options.type === 'severities' ? 'severity' : 'predicate';
        case 'merge':
            return wire.wires.some((x) => _outputType(x) === 'severity') ? 'severity' : 'predicate';
        case 'not':
            return _outputType(wire.wire);
        case 'max':
        case 'segmentStretch':
            return 'severity';
    }
};
const isWithinBound = (evaluatedAspects, bound, aspectName) => {
    const evaluatedAspect = evaluatedAspects[aspectName];
    return aspectName === bound.aspectName && evaluatedAspect && evaluatedAspect >= bound.mininimSeverity;
};
const findRowInOrdering = (evaluatedAspects, aspectSeverityOrdering, aspectName) => aspectSeverityOrdering.findIndex((bounds) => bounds.find((bound) => isWithinBound(evaluatedAspects, bound, aspectName)));
const compareAspects = (evaluatedAspects, aspectSeverityOrdering, aspect1, aspect2) => {
    const row1 = findRowInOrdering(evaluatedAspects, aspectSeverityOrdering, aspect1);
    const row2 = findRowInOrdering(evaluatedAspects, aspectSeverityOrdering, aspect2);
    if (row1 === row2) {
        const evaluated1 = (0, exports.mapToAspectValue)(evaluatedAspects[aspect1]);
        const evaluated2 = (0, exports.mapToAspectValue)(evaluatedAspects[aspect2]);
        return (evaluated2 === undefined ? -1 : evaluated2) - (evaluated1 === undefined ? -1 : evaluated1);
    }
    return row1 - row2;
};
const mapToAspectValue = (aspectValue) => {
    if (typeof aspectValue === 'number') {
        return aspectValue;
    }
    if (typeof aspectValue === 'string') {
        return parseFloat(aspectValue);
    }
    if (typeof aspectValue === 'boolean') {
        return aspectValue ? 1 : 0;
    }
    return aspectValue;
};
exports.mapToAspectValue = mapToAspectValue;
const filterAndSortAspects = (evaluatedAspects, aspectSeverityOrdering, noThreshold = false, sessionConfig) => {
    // Turning off all thresholds for MHRA compliance
    noThreshold = true;
    const allAspects = [
        ...new Set(aspectSeverityOrdering.flatMap((bounds) => bounds
            .filter((bound) => (noThreshold ? true : isWithinBound(evaluatedAspects, bound, bound.aspectName)))
            .map((bound) => bound.aspectName)
            .filter((aspect) => (sessionConfig ? (0, sessionConfigs_1.sessionConfigContainsAspect)(sessionConfig, aspect) : true)))),
    ];
    return allAspects.sort((a, b) => compareAspects(evaluatedAspects, aspectSeverityOrdering, a, b));
};
exports.filterAndSortAspects = filterAndSortAspects;
