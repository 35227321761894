import React, { useContext, useEffect, useState } from 'react'
import { Button, Heading, Page } from '@psyomics/components'
import { Splash } from 'ui/app/pages/Splash'
import Styles from './Stats.module.scss'
import { MixpanelContext } from 'registration/context/mixpanel/mixpanel-context'
import { useMixpanelEvent } from 'ui/app/useMixpanelEvent'
import Header from 'ui/app/components/Header'
import DemoFooter from 'ui/app/components/DemoFooter'
import PopulationHealthStats from './PopulationHealthStats'

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

const Stats: React.FC = () => {
    const mixpanelApiUrl = useContext(MixpanelContext)
    const { visitedDemoStats } = useMixpanelEvent(mixpanelApiUrl)
    const [isLoaded, setIsLoaded] = useState<boolean>(false)

    useEffect(() => {
        loadData()
    }, [])

    useEffect(() => {
        visitedDemoStats(undefined, undefined)
    }, [])

    const loadData = async () => {
        await delay(500)
        setIsLoaded(true)
    }

    const defaultFromDate = '2023-01-01'
    const [fromDate, setFromDate] = useState<string>(defaultFromDate)

    const defaultToDate = new Date().toISOString().split('T')[0]
    const [toDate, setToDate] = useState<string>(defaultToDate)

    const handleDateReset = () => {
        setFromDate(defaultFromDate)
        setToDate(defaultToDate)
    }

    if (isLoaded) {
        return (
            <Page
                layout="standard"
                width="wide"
                header={<Header patientFacing={false} demoMenu={true} />}
                footer={<DemoFooter footerType="clinician" />}
                style={{ paddingBottom: '72px' }}
            >
                <Heading el="h1" size="heading1" color="mid" css={{ mb: 2 }}>
                    Population Health
                </Heading>
                <div className={Styles.nav}>
                    <div>
                        <label>From:</label>
                        <input type="date" value={fromDate} onChange={(e) => setFromDate(e.target.value)} />
                    </div>
                    <div>
                        <label>To:</label>
                        <input type="date" value={toDate} onChange={(e) => setToDate(e.target.value)} />
                    </div>
                    <div>
                        <Button appearance="primary" size="small" label="Reset" onClick={handleDateReset}></Button>
                    </div>
                </div>
                <PopulationHealthStats fromDate={fromDate} toDate={toDate} />
            </Page>
        )
    } else {
        return <Splash />
    }
}

export default Stats
