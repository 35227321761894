import React from 'react'
import { classNameMaker } from 'ui/utils'
import Styles from './MenuItem.module.scss'
import { ArrowRightIcon } from '@psyomics/components'
import { Link as RouterLink, LinkProps } from 'react-router-dom'
import PropTypes from 'prop-types'

interface SharedProps {
    label: string
}

type RouterProps = {
    [K in keyof LinkProps]?: LinkProps[K]
}

type IMenuItemProps = SharedProps & React.ComponentPropsWithoutRef<'a'> & RouterProps
const MenuItem: React.FC<IMenuItemProps> = ({ label, to, ...props }) => {
    const containerClass: string = classNameMaker({
        [Styles.container]: true,
    })
    return to ? (
        <MenuRoute to={to} className={containerClass} label={label} {...props} />
    ) : (
        <MenuLink className={containerClass} label={label} {...props}></MenuLink>
    )
}

type IMenuLinkProps = SharedProps & React.ComponentPropsWithoutRef<'a'>

const MenuLink: React.FC<IMenuLinkProps> = ({ label, href, ...props }) => {
    return (
        <a href={href} rel="noreferrer" target="_blank" {...props}>
            {label}
            <ArrowRightIcon size="small" className={Styles.icon} />
        </a>
    )
}

MenuLink.propTypes = {
    href: PropTypes.string,
}

type IMenuRouteProps = SharedProps & RouterProps

const MenuRoute: React.FC<IMenuRouteProps> = ({ label, to, ...props }) => {
    return (
        <RouterLink to={to || '/'} {...props}>
            {label}
            <ArrowRightIcon size="small" className={Styles.icon} />
        </RouterLink>
    )
}

export default MenuItem
