import React, { useContext } from 'react'
import { Formik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../context/auth'
import { useAsync } from '../../hooks/use-async'
import { Heading, Button, Input, Page, Link } from '@psyomics/components'
import Label from '../../components/Label'
import Card from '../../components/Card'
import Content from '../../components/Content'
import Validation from '../../components/Validation'
import Field from '../../components/Field'
import Header from '../../components/Header'
import { handleSignin } from './use-Signin'
import { RegistrationRoutes } from 'registration/context/registration/registration.definitions'
import { useMixpanelEvent } from 'ui/app/useMixpanelEvent'
import { MixpanelContext } from 'registration/context/mixpanel/mixpanel-context'
import * as Sentry from '@sentry/react'
// import OrBreak from 'registration/components/OrBreak'
// import NhsButton from 'registration/components/NhsButton'

type ISignInProps = React.ComponentPropsWithoutRef<'div'> & {
    setReferralPublicId?: React.Dispatch<React.SetStateAction<string | undefined>>
}

/**
 * SignIn is the onestop auth shop UI.
 * Default is ane mail/password form for initial login, with child component / button to request to register as an alternative
 */
const SignIn: React.FC<ISignInProps> = ({ ...props }) => {
    const { initializeUser, requireConfirmation } = useAuth()
    const { error, isLoading, isError, run } = useAsync()
    const { setReferralPublicId } = props
    const navigate = useNavigate()
    const mixpanelApiUrl = useContext(MixpanelContext)

    const { loggedIn } = useMixpanelEvent(mixpanelApiUrl)

    /* Should we use global notifcation context?
  useEffect(() => {
    if (isError && error) {
      addNotification({
        type: NotificationType.ERROR,
        title: error.message,
        message: 'Your request has failed.',
      });
    }
  }, [error, isError]);
  */

    return (
        <Page>
            <Header template="landing" />
            <Content>
                <Heading el="h1" size="heading2" color="mid" css={{ mb: 4 }}>
                    Sign in to your account
                </Heading>
                <Formik
                    initialValues={{
                        email: '',
                        password: '',
                    }}
                    onSubmit={async (values) => {
                        run(
                            handleSignin({ values, initializeUser, requestConfirmation: requireConfirmation }).then(
                                (user) => {
                                    if (user) {
                                        // TODO: CP-2007 referralPublicId from PG
                                        if (setReferralPublicId && user?.attributes?.['custom:referralPublicId']) {
                                            setReferralPublicId(user?.attributes?.['custom:referralPublicId'])
                                        }

                                        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                                            const errMsg = 'User requires new password'
                                            Sentry.captureException(errMsg)
                                            throw new Error(errMsg)
                                        }
                                        loggedIn(undefined, undefined)
                                        navigate('/')
                                    }
                                }
                            )
                        )
                    }}
                >
                    {({ values, handleChange, handleBlur, handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <Card>
                                <Field>
                                    <Label htmlFor="email">Email</Label>
                                    <Input
                                        type="email"
                                        id="email"
                                        appearance="outlined"
                                        placeholder="e.g. Jane@example.com"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.email}
                                    />
                                </Field>
                                <Field>
                                    <Label htmlFor="password">Password</Label>
                                    <Input
                                        type="password"
                                        id="password"
                                        appearance="outlined"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.password}
                                    />
                                    {isError && error && <Validation>{error.message}</Validation>}
                                    <div>
                                        <Link href={RegistrationRoutes.resetpassword}>Forgotten your password?</Link>
                                    </div>
                                </Field>
                                <div>
                                    <Button
                                        type="submit"
                                        appearance="primary"
                                        rounded
                                        fullWidth
                                        size="large"
                                        label="Sign In"
                                        icon={null}
                                        disabled={!!isLoading}
                                    />
                                    {/* <OrBreak />
                                    <NhsButton /> */}
                                </div>
                            </Card>
                        </form>
                    )}
                </Formik>
            </Content>
        </Page>
    )
}

export default SignIn
