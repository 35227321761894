import React, { useState, useEffect, useRef } from 'react'
import { Logo, Button, MenuDropDown } from '@psyomics/components'
import { useNavigate, useParams } from 'react-router'
import Styles from './Header.module.scss'
import HelpModal from 'ui/app/components/HelpModal'
import { Link as RouterLink } from 'react-router-dom'
import { useAuth } from 'registration/context/auth'
import * as Sentry from '@sentry/react'
import { useDemoContext } from 'registration/pages/Demo/DemoContext'

export const Header: React.FC<{
    hideCrisisLink?: boolean
    patientFacing: boolean
    demoMenu?: boolean
    orgCode?: string
}> = ({ patientFacing, demoMenu, hideCrisisLink, orgCode }) => {
    const [menu, setMenu] = useState<boolean>(false)
    const navigate = useNavigate()
    const { signOutUser } = useAuth()
    const menuRef = useRef<HTMLDivElement | null>(null) // Ref for the MenuDropDown
    const CenseoLogo = <Logo format="composite" height="32" />
    let { organisationCode } = useParams()
    if (!organisationCode) organisationCode = orgCode
    const { demo, demoExperienceType } = useDemoContext()

    // Handles menu button click depending on state
    const onMenuClick = () => {
        if (patientFacing) {
            return () => {
                navigate(demo ? '/demo/service-user/userprofile' : '/userprofile')
            }
        } else {
            return (e: React.SyntheticEvent) => {
                e.stopPropagation()
                setMenu((prevState) => !prevState)
            }
        }
    }

    // Click outside listener to close clinician menu
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
                setMenu(false) // Close the menu when clicking outside
            }
        }

        // Only add event listener if the clinician menu is open
        if (menu) {
            document.addEventListener('click', handleClickOutside)
        }

        // Cleanup event listener on unmount or when clinician menu is closed
        return () => {
            document.removeEventListener('click', handleClickOutside)
        }
    }, [menu])

    function clinicianNav(page: string) {
        if (!organisationCode) {
            Sentry.captureException('no orgcode for menu')
        }

        if (menu) {
            navigate(demoMenu ? page : `/account/${organisationCode}/${page}`)
        }
    }

    const menuOptions = demoMenu
        ? {
              'Activity Dashboard': () => clinicianNav('/demo/healthcare/activity-dashboard'),
              'Population Health': () => clinicianNav('/demo/healthcare/population-health'),
              'Clinical Reports': () => clinicianNav('/demo/healthcare/clinical-reports'),
          }
        : {
              'Activity Dashboard': () => clinicianNav('referral-stats'),
              'Population Health': () => clinicianNav('population-health'),
              'Clinical Reports': () => clinicianNav('patient-referrals'),
              'Using clinical report instructions': () => clinicianNav('information-for-use'),
              'Sign Out': async () => {
                  await signOutUser()
                  clinicianNav('')
              },
          }

    let homeUrl = '/welcome'

    if (demo) {
        if (demoExperienceType === 'clinician') {
            homeUrl = '/demo/healthcare'
        } else if (demoExperienceType === 'service-user') {
            homeUrl = '/demo/service-user/checkIn'
        } else {
            homeUrl = '/demo'
        }
    }

    return (
        <header style={{ position: 'relative' }}>
            <div className={Styles.header}>
                {' '}
                {patientFacing && !hideCrisisLink && <HelpModal />}
                <RouterLink to={homeUrl} title="Go back to the homepage" className={Styles.homeLink}>
                    {CenseoLogo}
                </RouterLink>
                <div className={Styles.accountLink}>
                    <Button
                        appearance="link"
                        size="large"
                        aria-label="Show user account menu"
                        onClick={onMenuClick()}
                        icon="menu"
                    />
                </div>
            </div>

            {menu && (
                <div
                    ref={menuRef}
                    style={{ position: 'absolute', right: '4px', top: 'calc(100% + 2px)', zIndex: 1000 }}
                >
                    <MenuDropDown menuOptions={menuOptions} />
                </div>
            )}
        </header>
    )
}
