import React, { useContext, useState } from 'react'
import { Text, Button } from '@psyomics/components'
import { OnboardingFormProps } from '../use-Onboarding'
import RadioGroup from 'registration/components/RadioGroup/RadioGroup'
import { useMixpanelEvent } from 'ui/app/useMixpanelEvent'
import { MixpanelContext } from 'registration/context/mixpanel/mixpanel-context'
import { OrganisationContext } from 'ui/app/context/organisation'
import { ReferralDetailsContext } from 'ui/app/context/referralDetails'

export enum SexOptions {
    Female = 'Female',
    Male = 'Male',
    Other = 'Other',
    NotRecorded = 'Not recorded',
}

type IOnboarding = OnboardingFormProps & React.ComponentPropsWithoutRef<'div'>

const Sex: React.FC<IOnboarding> = ({ values, errors, onChange, validateForm, setPageState }) => {
    const [showErrors, setShowErrors] = useState(false)
    const mixpanelApiUrl = useContext(MixpanelContext)
    const organisation = useContext(OrganisationContext)
    const referralDetails = useContext(ReferralDetailsContext)
    const { sexEntered } = useMixpanelEvent(mixpanelApiUrl)

    return (
        <>
            <Text color="mid" css={{ mb: 4 }}>
                In order to improve your experience we&#39;d like to find out a little more about you.
            </Text>
            <RadioGroup
                groupName="sex"
                label="What is your sex? (as on your NHS record)"
                value={values.sex}
                onChange={onChange}
                displayOr
                options={Object.keys(SexOptions).map((m) => ({
                    label: (SexOptions as any)[m],
                    value: m,
                }))}
                error={showErrors ? errors.sex : undefined}
            />
            <Button
                type="submit"
                appearance="primary"
                rounded
                fullWidth
                size="large"
                css={{ mt: 6 }}
                label="Next"
                onClick={async () => {
                    const errors = await validateForm()
                    setShowErrors(true)
                    if (!errors.sex) {
                        sexEntered(
                            organisation?.organisationCode,
                            organisation?.organisationName,
                            values.sex,
                            referralDetails?.data?.publicId
                        )
                        setPageState('gender')
                    }
                }}
            />
        </>
    )
}

export default Sex
