import React from 'react'
import { Heading, SessionCard, SessionTimeline } from '@psyomics/components'
import ContentPage from 'ui/app/components/ContentPage'
import Styles from './CheckIn.module.scss'
import DemoFooter from 'ui/app/components/DemoFooter'

export const SessionSummary: React.FC<ISessionSummary> = ({
    isDemoUser,
    isFirstVisit,
    completeSessionCards,
    allSessionCards,
}) => {
    return (
        <ContentPage footer={isDemoUser ? <DemoFooter footerType="patient" /> : undefined}>
            <Heading color="mid" className={Styles.welcomeTitle} size="heading1" el="h1">
                Welcome {isFirstVisit ? '' : 'Back'}
            </Heading>
            <SessionTimeline currentSession={completeSessionCards.length + 1} className={Styles.timeline}>
                {allSessionCards.map((session) => (
                    <SessionCard key={session.sessionNumber} {...session} />
                ))}
            </SessionTimeline>
        </ContentPage>
    )
}
interface ISessionSummary {
    isDemoUser: boolean
    isFirstVisit: boolean
    completeSessionCards: any[]
    allSessionCards: any[]
}
