import Auth, { CognitoUser } from '@aws-amplify/auth'
import * as Sentry from '@sentry/react'

interface CognitoUserAttributes {
    address: string
    birthdate: string
    email_verified: boolean
    gender: string
    given_name: string
    family_name: string
    email: string
    'custom:nhsNumber': string
    'custom:userType': string
    'custom:ethnicity'?: string
    'custom:referralCode'?: string
    'custom:termsAcceptedDate'?: string
    'custom:referralPublicId'?: string
    'custom:sex'?: string
}

export interface CognitoUserWithAttributes extends CognitoUser {
    attributes: CognitoUserAttributes
}

export interface LoginParams {
    values: {
        email: string
        password: string
        newPassword?: string
    }
    initializeUser: () => Promise<void>
    requestConfirmation: (email: string, password: string) => Promise<void>
}

// TODO: CP-2007 CognitoUserWithAttributes to be changed to User from PG
export async function handleSignin({
    values,
    initializeUser,
    requestConfirmation,
}: LoginParams): Promise<CognitoUserWithAttributes | undefined> {
    const emailLowercase = values.email.toLowerCase()
    let user
    try {
        user = (await Auth.signIn(emailLowercase, values.password)) as CognitoUserWithAttributes & {
            challengeName?: string
            challengeParam: {
                requiredAttributes: string
            }
        }
    } catch (error: any) {
        if (error.code === 'UserNotConfirmedException') {
            requestConfirmation(emailLowercase, values.password)
            return
        } else {
            Sentry.captureException(error, { extra: { errorMessage: 'Failed to Auth.signIn user' } })
            throw error
        }
    }

    initializeUser()

    return user
}
