import React, { createContext, useEffect, useState, ReactNode, useContext } from 'react'
import { InfoContext } from 'configure'
import * as Sentry from '@sentry/react'
import { useAuth } from '../../../registration/context/auth'
import { QueryUserProfileArgs, SessionConfig } from 'censeo-core'
import { UserProfileData, userProfileQuery } from 'data/queries/userProfile'
import { useQuery } from '@apollo/client'

export interface OrgData {
    assessmentSettings: SessionConfig[]
    organisationCode: string
    organisationConfig: Record<string, unknown> & {
        practitionerReferralPortalEnabled?: string
    }
    organisationName: string
    demo: boolean
}

export const OrganisationContext = createContext<OrgData | undefined>(undefined)

interface OrganisationContextProviderProps {
    children: ReactNode
}

export function OrganisationContextProvider({ children }: OrganisationContextProviderProps): JSX.Element {
    const [data, setData] = useState<OrgData | undefined>(undefined)
    const [userId, setUserId] = useState<string>('')
    const info = useContext(InfoContext)
    const { getCurrentUser } = useAuth()

    const { data: userProfileData, error: userProfileError } = useQuery<UserProfileData, QueryUserProfileArgs>(
        userProfileQuery,
        {
            variables: { userId },
            skip: !userId,
        }
    )

    useEffect(() => {
        ;(async () => {
            if (info?.referralApiUrl) {
                const currentUser = await getCurrentUser()
                const username = currentUser?.getUsername()
                if (username) {
                    setUserId(username)
                }

                const referralPublicId = userProfileData?.userProfile.referralPublicId
                if (referralPublicId) {
                    fetch(`${info?.referralApiUrl}referrals/unauthenticated?publicId=${referralPublicId}`)
                        .then((response) => response.json())
                        .then((data) => {
                            const {
                                assessmentSettings,
                                organisationCode,
                                organisationName,
                                organisationConfig,
                            }: OrgData = data
                            setData({
                                assessmentSettings,
                                organisationCode,
                                organisationName,
                                demo: !!organisationCode.toLocaleLowerCase().startsWith('demo'),
                                organisationConfig,
                            })
                        })
                        .catch((error) => {
                            Sentry.captureException('Error fetching organisation data: ' + error)
                        })
                }

                if (userProfileError) {
                    Sentry.captureException(userProfileError)
                }
            }
        })()
    }, [info?.referralApiUrl, userProfileData, userProfileError])

    return <OrganisationContext.Provider value={data}>{children}</OrganisationContext.Provider>
}
