import React, { ReactElement, useEffect, useReducer } from 'react'
import { RegistrationContext } from './registration-context'
import { RegistrationReducer } from './registration-reducer'
import { RegistrationState } from './registration.definitions'

const initialState: RegistrationState = JSON.parse(sessionStorage.getItem('rs') as string) || {}

export function RegistrationProvider(props: any): ReactElement {
    const [state, dispatch] = useReducer(RegistrationReducer, initialState)

    useEffect(() => sessionStorage.setItem('rs', JSON.stringify(state)), [state])

    const value = React.useMemo(() => [state, dispatch], [state])

    return <RegistrationContext.Provider value={value} {...props} />
}
