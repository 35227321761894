"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DecisionType = exports.ragNodes = exports.RagIds = exports.FreeQuestionRules = exports.RagConditions = void 0;
var RagConditions;
(function (RagConditions) {
    RagConditions["eupd"] = "emi_ce";
    RagConditions["suicide"] = "s_current";
    RagConditions["selfharm"] = "sh_current";
    RagConditions["trauma"] = "trm_re_ptsd";
})(RagConditions = exports.RagConditions || (exports.RagConditions = {}));
var FreeQuestionRules;
(function (FreeQuestionRules) {
    FreeQuestionRules["greaterThan"] = ">";
    FreeQuestionRules["greaterThanOrEqual"] = ">=";
    FreeQuestionRules["lessThan"] = "<";
    FreeQuestionRules["lessThanOrEqual"] = "<=";
})(FreeQuestionRules = exports.FreeQuestionRules || (exports.FreeQuestionRules = {}));
var RagIds;
(function (RagIds) {
    RagIds["rag2"] = "rag2";
    RagIds["rag3"] = "rag3";
    RagIds["rag4"] = "rag4";
    RagIds["rag5"] = "rag5";
})(RagIds = exports.RagIds || (exports.RagIds = {}));
exports.ragNodes = Object.freeze({
    [RagIds.rag2]: {
        type: 'ragResult',
        priority: 2,
    },
    [RagIds.rag3]: {
        type: 'ragResult',
        priority: 3,
    },
    [RagIds.rag4]: {
        type: 'ragResult',
        priority: 4,
    },
    [RagIds.rag5]: {
        type: 'ragResult',
        priority: 5,
    },
});
var DecisionType;
(function (DecisionType) {
    DecisionType["SCORE"] = "SCORE";
    DecisionType["NO_ANSWER"] = "NO_ANSWER";
    DecisionType["MULTICHOICE"] = "MULTICHOICE";
    DecisionType["FREE_QUESTION"] = "FREE_QUESTION";
})(DecisionType = exports.DecisionType || (exports.DecisionType = {}));
