import Auth, { CognitoUser } from '@aws-amplify/auth'
import * as Yup from 'yup'
import dayjs from 'dayjs'
import { FormikErrors, FormikHandlers, FormikHelpers, FormikTouched } from 'formik'
import { UserType, ReferralCode } from 'censeo-core'
import { handleSignin } from '../SignIn/use-Signin'

export interface SignupFormInputs {
    notSeenUntilComplete: boolean
    confirmNotCrisisService: boolean
    email: string
    password: string
    confirmPassword: string
    birthdate: string
    agreeTerms: boolean
    userType: UserType | undefined
    // nhs only
    referralCode?: ReferralCode
    referralPublicId?: string
    // only set for NHS users during sign up
    nhsNumber?: string
}

export interface SignUpFormProps {
    values: SignupFormInputs
    errors: FormikErrors<SignupFormInputs>
    touched: FormikTouched<SignupFormInputs>
    setFieldValue: FormikHelpers<SignupFormInputs>['setFieldValue']
    onChange: FormikHandlers['handleChange']
    onBlur?: FormikHandlers['handleBlur']
    validateForm?: (values?: any) => Promise<FormikErrors<SignupFormInputs>>
    testingCheatStartNhs?: boolean
}

export const SignupFormInputsInitialValues: SignupFormInputs = {
    notSeenUntilComplete: false,
    confirmNotCrisisService: false,
    email: '',
    password: '',
    confirmPassword: '',
    agreeTerms: false,
    userType: undefined,
    birthdate: '....-..-..',
}

const EighteenYearsOld: Date = new Date()
EighteenYearsOld.setFullYear(EighteenYearsOld.getFullYear() - 18)
const notBornYesterday: Date = new Date(1, 1, 1910)

export const SignupFormSchema = Yup.object().shape({
    notSeenUntilComplete: Yup.bool().oneOf(
        [true],
        'You must confirm you understand that there is no live monitoring and that the assessment will not be seen by a clinician until you have completed all the whole questionnaire.'
    ),
    confirmNotCrisisService: Yup.bool().oneOf(
        [true],
        'You must confirm you understand Censeo is not a crisis service.'
    ),
    dobDate: Yup.date()
        .min(notBornYesterday, 'Please enter a valid date. You must be 18 or over.')
        .max(EighteenYearsOld, 'Please enter a valid date. You must be 18 or over.'),
    dobDay: Yup.number().typeError('Enter a number').min(1, '1 or more').max(31, 'Too big'),
    dobMonth: Yup.number().typeError('Enter a number').min(1, '1 or more').max(12, '12 or less'),
    dobYear: Yup.number()
        .typeError('Enter a number')
        .min(notBornYesterday.getFullYear(), 'Full year please')
        .max(EighteenYearsOld.getFullYear(), 'Must be ' + EighteenYearsOld.getFullYear() + ' or less'),
    email: Yup.string().email('Please enter a valid email address.').required('Please enter your email address.'),
    password: Yup.string().required('Password is required').min(8, 'Must be at least 8 characters'),
    confirmPassword: Yup.string().test('passwords-match', 'Passwords must match', function (value) {
        return this.parent.password === value
    }),
    agreeTerms: Yup.bool().oneOf([true], 'You must accept these to use the service'),
    userType: Yup.mixed<UserType>().oneOf(Object.values(UserType)),
})

export async function handleRegister(
    values: SignupFormInputs,
    initializeUser: () => Promise<void>,
    requestConfirmation: (email: string, password: string) => Promise<void>
): Promise<CognitoUser> {
    const email = values.email.trim().toLowerCase()

    // TODO: CP-2007 Attributes for PG instead of Cognito
    const attributes = {
        birthdate: values.birthdate,
        'custom:termsAcceptedDate': dayjs().toISOString(),
        'custom:userType': values.userType,
        // for D2C users it won't be defined at sign up
        // syntax to conditionally set props: https://stackoverflow.com/a/40560953/15909256
        ...(values?.referralCode && { 'custom:referralCode': values.referralCode }),
        ...(values?.referralPublicId && { 'custom:referralPublicId': values.referralPublicId }),
        ...(values?.nhsNumber && { 'custom:nhsNumber': values.nhsNumber }),
    }

    // TODO: CP-2007 How can we signup and not save the attributes to cognito?????
    const { user } = await Auth.signUp({
        // sadly, username will be listed internally as a UUID, not the email address
        //- see https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-attributes.html#user-pool-settings-aliases-settings-option-2
        username: email,
        password: values.password,
        attributes,
    })

    // sign in NHS users immediately
    if (user && values.userType === UserType.Nhs) {
        await handleSignin({ values: { email, password: values.password }, initializeUser, requestConfirmation })
    } else {
        await requestConfirmation(email, values.password)
    }
    return user
}
