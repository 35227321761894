import React from 'react'
import { classNameMaker } from 'ui/utils'
import { Button, Text } from '@psyomics/components'
import { useAuth } from 'registration/context/auth'
import Styles from './AccountDetails.module.scss'
import { useNavigate } from 'react-router-dom'
import { useDemoContext } from 'registration/pages/Demo/DemoContext'

type IAccountDetailsProps = React.ComponentPropsWithoutRef<'div'>

const AccountDetails: React.FC<IAccountDetailsProps> = ({ ...props }) => {
    const { demo } = useDemoContext()
    const containerClass: string = classNameMaker({
        [Styles.container]: true,
    })
    const {
        state: { user },
        signOutUser,
    } = useAuth()
    const navigate = useNavigate()

    return (
        <div className={containerClass} {...props}>
            <Text color="mid" className={Styles.email}>
                {demo ? 'example@email.com' : user?.email}
            </Text>
            <Button
                onClick={async () => {
                    await signOutUser()
                    navigate('/')
                }}
                label="Sign out"
                appearance="link"
            />
        </div>
    )
}

export default AccountDetails
