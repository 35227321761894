import React, { useEffect } from 'react'
import { useAuth } from 'registration/context/auth'
import debounce from 'ui/utils/debounce'
import { useNavigate } from 'react-router-dom'

const DEFAULT_TIMER_CHECK_INTERVAL = 1000

interface InactivityHandlerProps {
    maxInactivityMs: number
    maxConstantUseMs: number
    checkInterval?: number
    navigateTo?: string
    children: React.ReactNode
}

const InactivityHandler: React.FC<InactivityHandlerProps> = ({
    children,
    maxInactivityMs,
    maxConstantUseMs,
    checkInterval,
    navigateTo,
}) => {
    const { signOutUser, getCurrentUser } = useAuth()
    const userEvents = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress']
    let inactivityTimeout: number
    const navigate = useNavigate()

    useEffect(() => {
        // Create timer for tracking user inactivity, signs out user after n milliseconds (i.e. inactivityTimeout)
        createTimer({
            checkInterval,
            conditionFn: () => {
                return Date.now() > Number(inactivityTimeout) || false
            },
            beforeFn: () => {
                updateUserInactivityTimeout()
                Object.values(userEvents).forEach((event) => {
                    window.addEventListener(event, debounce(updateUserInactivityTimeout, 1000))
                })
            },
            afterFn: () => {
                return signOutUserAndTeardown()
            },
        })

        // Create timer for signing out the user after a certain period of constant use (i.e. constantUseTimeout)
        createTimer({
            checkInterval,
            conditionFn: async () => {
                const user = await getCurrentUser()
                const issuedAt = user?.getSignInUserSession()?.getAccessToken().getIssuedAt()
                return !issuedAt || Date.now() > issuedAt * 1000 + maxConstantUseMs
            },
            afterFn: () => {
                return signOutUserAndTeardown()
            },
        })
    }, [])

    const createTimer = (params: {
        checkInterval: number | undefined
        conditionFn: () => boolean | Promise<boolean>
        beforeFn?: () => unknown
        afterFn: () => unknown
    }) => {
        const { checkInterval, conditionFn, beforeFn, afterFn } = params

        // Run some logic before creating the timer if needed
        if (beforeFn) {
            beforeFn()
        }

        // Keep checking condition is met then stop timer
        const timer = setInterval(async () => {
            const conditionMet = await conditionFn()
            if (conditionMet) {
                afterFn()
                clearInterval(timer)
            }
        }, checkInterval || DEFAULT_TIMER_CHECK_INTERVAL)
    }

    const signOutUserAndTeardown = async () => {
        Object.values(userEvents).forEach((event) => {
            window.removeEventListener(event, updateUserInactivityTimeout)
        })
        await signOutUser()
        if (navigateTo) navigate(navigateTo)
    }

    const updateUserInactivityTimeout = () => {
        inactivityTimeout = Date.now() + maxInactivityMs
    }

    return <>{children}</>
}

export default InactivityHandler
