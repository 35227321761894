"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.symptoms = void 0;
exports.symptoms = [
    {
        "answerSummary": {
            "type": "FunctionSummary",
            "formatText": "{}",
            "functionId": "AsrsThreshold"
        },
        "section": [
            "ASRS Initial Screening (Part A)",
            "Screen threshold met:"
        ]
    },
    {
        "answerSummary": {
            "type": "FunctionSummary",
            "formatText": "{}/6",
            "functionId": "AsrsA"
        },
        "section": [
            "ASRS Initial Screening (Part A)",
            "Items meeting clinical significance:"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Trouble with wrapping up the final details of a project after the challenging parts are done: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ASRS1",
                    "optionSummaries": {
                        "b0ea051b-76ac-4fd3-9d8e-cad22d60f786": "Sometimes",
                        "ac3f5a3e-fd93-4202-9025-d4e78d22df04": "Often",
                        "f9e84075-ae14-47c6-8ac7-1c0cf7b57b3e": "Very Often"
                    }
                }
            ]
        },
        "section": [
            "ASRS Initial Screening (Part A)",
            "Responses meeting clinical significance:"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Difficulty getting things in order for tasks requiring organisation: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ASRS2",
                    "optionSummaries": {
                        "628d1f37-f6d5-4a30-b154-5ac3121b51a3": "Sometimes",
                        "bbafdd89-81bf-46c6-b7c0-ab9ede5f9b2b": "Often",
                        "e2d45793-c88e-437b-b8ac-c76a8110f0f5": "Very Often"
                    }
                }
            ]
        },
        "section": [
            "ASRS Initial Screening (Part A)",
            "Responses meeting clinical significance:"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Problems remembering appointments or obligations: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ASRS3",
                    "optionSummaries": {
                        "0a71cb5f-98cb-4130-9625-50c5cfa690ff": "Sometimes",
                        "ed30d4b1-99bf-4d1e-84c9-8af4a6136cf1": "Often",
                        "645ec227-aed2-4992-a639-fb14d6ccbdf2": "Very Often"
                    }
                }
            ]
        },
        "section": [
            "ASRS Initial Screening (Part A)",
            "Responses meeting clinical significance:"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Avoid or delay starting tasks requiring lots of thought: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ASRS4",
                    "optionSummaries": {
                        "4b03fe6b-aaa6-4aaf-9524-f71f0af63967": "Often",
                        "3b178b28-a8cf-44a5-8d8c-a20e223350be": "Very Often"
                    }
                }
            ]
        },
        "section": [
            "ASRS Initial Screening (Part A)",
            "Responses meeting clinical significance:"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Restless or fidgety when sitting still for long periods: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ASRS5",
                    "optionSummaries": {
                        "3bbad350-b4ae-4e9d-8b08-7caa5913fe8b": "Often",
                        "27597175-adf0-4d86-9371-613eebb40252": "Very Often"
                    }
                }
            ]
        },
        "section": [
            "ASRS Initial Screening (Part A)",
            "Responses meeting clinical significance:"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Overly active and compelled to do things: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ASRS6",
                    "optionSummaries": {
                        "2af14acb-3029-4501-8c38-0b15fd54d2f8": "Often",
                        "d63623e1-c39a-48a9-b55f-68c3507bf242": "Very Often"
                    }
                }
            ]
        },
        "section": [
            "ASRS Initial Screening (Part A)",
            "Responses meeting clinical significance:"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Trouble with wrapping up the final details of a project after the challenging parts are done: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ASRS1",
                    "optionSummaries": {
                        "1e2492d3-7e08-4287-a9f1-f3dbef2612b2": "Never",
                        "4c1c637b-7761-49a5-90af-e70832845e6d": "Rarely"
                    }
                }
            ]
        },
        "section": [
            "ASRS Initial Screening (Part A)",
            "Other responses:"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Difficulty getting things in order for tasks requiring organisation: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ASRS2",
                    "optionSummaries": {
                        "aa8e0ca0-41d7-454b-adb3-ca850d9ad836": "Never",
                        "ac4acf1a-ec33-44f5-a740-ba737688b826": "Rarely"
                    }
                }
            ]
        },
        "section": [
            "ASRS Initial Screening (Part A)",
            "Other responses:"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Problems remembering appointments or obligations: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ASRS3",
                    "optionSummaries": {
                        "97ef4ce5-b425-4b58-9a9c-c5f9fd00324f": "Never",
                        "f4447b42-b9fc-47f2-94ad-0f5ebf292417": "Rarely"
                    }
                }
            ]
        },
        "section": [
            "ASRS Initial Screening (Part A)",
            "Other responses:"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Avoid or delay starting tasks requiring lots of thought: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ASRS4",
                    "optionSummaries": {
                        "bb80c0a5-0acf-409d-877c-21e0eec4ba93": "Never",
                        "e7aa837c-3a8b-43ef-a87e-2c8b3b238cf5": "Rarely",
                        "1b3b13ea-b3ec-4aaa-92da-01058e86c4c6": "Sometimes"
                    }
                }
            ]
        },
        "section": [
            "ASRS Initial Screening (Part A)",
            "Other responses:"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Restless or fidgety when sitting still for long periods: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ASRS5",
                    "optionSummaries": {
                        "f30bde00-6fd5-45ce-98fe-feb2107c5a91": "Never",
                        "28c2c65e-f9b2-42bf-92a6-f94fb33a8531": "Rarely",
                        "c0469aa5-3511-4306-9e2a-769a8b2baa9d": "Sometimes"
                    }
                }
            ]
        },
        "section": [
            "ASRS Initial Screening (Part A)",
            "Other responses:"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Overly active and compelled to do things: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ASRS6",
                    "optionSummaries": {
                        "66ed09ee-495b-47f4-a535-bff7f9d53970": "Never",
                        "3d35ce2e-1ff6-408f-a313-f77066649fcc": "Rarely",
                        "7fb4d290-f352-4a2e-bf70-868ce348f766": "Sometimes"
                    }
                }
            ]
        },
        "section": [
            "ASRS Initial Screening (Part A)",
            "Other responses:"
        ]
    },
    {
        "answerSummary": {
            "type": "FunctionSummary",
            "formatText": "{}/12",
            "functionId": "AsrsB"
        },
        "section": [
            "ASRS Initial Screening (Part B)",
            "Items meeting clinical significance:"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Make careless mistakes on boring or difficult projects: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ASRS7",
                    "optionSummaries": {
                        "43e2d851-93f5-4683-bf3a-81eca0801214": "Often",
                        "26a1feb5-3d03-4573-9cd2-e355a336260f": "Very Often"
                    }
                }
            ]
        },
        "section": [
            "ASRS Initial Screening (Part B)",
            "Responses meeting clinical significance:"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Difficulty maintaining attention during boring or repetitive work: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ASRS8",
                    "optionSummaries": {
                        "7fc9c7d8-2183-4f2e-9c06-68c07288c9c8": "Often",
                        "081d5f80-4ce9-490a-8ff4-be6ecba5a784": "Very Often"
                    }
                }
            ]
        },
        "section": [
            "ASRS Initial Screening (Part B)",
            "Responses meeting clinical significance:"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Difficulty concentrating on direct conversations: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ASRS9",
                    "optionSummaries": {
                        "458cedfe-2e02-4ac2-aec6-dd12e8e97a15": "Sometimes",
                        "48eb803a-2a5b-4ec2-ae66-2b0c0e551ef8": "Often",
                        "88f28277-ed2f-4f03-8dc1-d0bf1f84b17d": "Very Often"
                    }
                }
            ]
        },
        "section": [
            "ASRS Initial Screening (Part B)",
            "Responses meeting clinical significance:"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Misplace or have difficulty finding things: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ASRS10",
                    "optionSummaries": {
                        "bfee8a4e-9d5a-417a-9991-b4d11a74e594": "Often",
                        "c7dffed4-c9b1-4262-8cd2-a75d8cc0d51b": "Very Often"
                    }
                }
            ]
        },
        "section": [
            "ASRS Initial Screening (Part B)",
            "Responses meeting clinical significance:"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Distracted by activity or noise: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ASRS11",
                    "optionSummaries": {
                        "2a043db0-25ac-4029-adb3-27b0fec3cce4": "Often",
                        "831251ef-b279-4722-a3dd-e696b3fe7007": "Very Often"
                    }
                }
            ]
        },
        "section": [
            "ASRS Initial Screening (Part B)",
            "Responses meeting clinical significance:"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Leave seat in meetings when expected to remain seated: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ASRS12",
                    "optionSummaries": {
                        "fe3ca6e9-9701-4ac3-ac92-71ba2d585c01": "Sometimes",
                        "32c8cd6c-c567-401b-8bdb-2c240950ca88": "Often",
                        "b0328927-6e29-4f25-9e5c-9223ec0b20b3": "Very Often"
                    }
                }
            ]
        },
        "section": [
            "ASRS Initial Screening (Part B)",
            "Responses meeting clinical significance:"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Feel restless or fidgety: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ASRS13",
                    "optionSummaries": {
                        "2b63acfa-8485-4d21-aa86-7295f4e65c06": "Often",
                        "124f9b9b-a33b-4890-aec0-f2d225342b68": "Very Often"
                    }
                }
            ]
        },
        "section": [
            "ASRS Initial Screening (Part B)",
            "Responses meeting clinical significance:"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Difficulty unwinding and relaxing during free time: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ASRS14",
                    "optionSummaries": {
                        "345134c7-6418-4da6-81ef-f5aabf12a8d3": "Often",
                        "536a070b-a090-41f1-bd46-c59263d0eee2": "Very Often"
                    }
                }
            ]
        },
        "section": [
            "ASRS Initial Screening (Part B)",
            "Responses meeting clinical significance:"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Talk too much in social situations: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ASRS15",
                    "optionSummaries": {
                        "8387ada7-92a4-46f9-9833-505cbb2568fe": "Often",
                        "ed4e7cf7-fd51-4dcf-b098-66b290160392": "Very Often"
                    }
                }
            ]
        },
        "section": [
            "ASRS Initial Screening (Part B)",
            "Responses meeting clinical significance:"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Finish others' sentences during conversations: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ASRS16",
                    "optionSummaries": {
                        "357825a6-bfc8-4ed7-8915-d3ea0a265eb3": "Sometimes",
                        "7e05d75b-7ad3-4456-bd44-18ce686b7680": "Often",
                        "5e23b844-e348-441e-846f-f1732175c16c": "Very Often"
                    }
                }
            ]
        },
        "section": [
            "ASRS Initial Screening (Part B)",
            "Responses meeting clinical significance:"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Difficulty waiting turn when turn-taking required: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ASRS17",
                    "optionSummaries": {
                        "20c06007-6e2a-4fc3-8d13-fbfceec83daa": "Often",
                        "04a05b95-3c7f-40cd-9b70-b14292602fb1": "Very Often"
                    }
                }
            ]
        },
        "section": [
            "ASRS Initial Screening (Part B)",
            "Responses meeting clinical significance:"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Interrupt others when busy: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ASRS18",
                    "optionSummaries": {
                        "dcfdba97-2862-419f-b106-61622f95faaf": "Sometimes",
                        "a827fb2a-b3ee-49cf-9770-0472d3555d0d": "Often",
                        "03fe899d-f60b-4ef7-a5e1-7cdf43b78d09": "Very Often"
                    }
                }
            ]
        },
        "section": [
            "ASRS Initial Screening (Part B)",
            "Responses meeting clinical significance:"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Make careless mistakes on boring or difficult projects: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ASRS7",
                    "optionSummaries": {
                        "ed15bfa8-d408-46ab-9525-b0b42e0d595a": "Never",
                        "762868bd-7c15-49e8-9bc7-f44eaf2d4c3e": "Rarely",
                        "c5c1c174-fccf-4b80-b7c2-7ffb478aad99": "Sometimes"
                    }
                }
            ]
        },
        "section": [
            "ASRS Initial Screening (Part B)",
            "Other responses:"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Difficulty maintaining attention during boring or repetitive work: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ASRS8",
                    "optionSummaries": {
                        "d5030f6f-ad19-49ae-a0cb-db56d5ba31f9": "Never",
                        "93ab502c-59cd-4c37-9e46-7a93cc4c43b2": "Rarely",
                        "e8b36a33-1c26-4ba1-b171-dc23072cb4a2": "Sometimes"
                    }
                }
            ]
        },
        "section": [
            "ASRS Initial Screening (Part B)",
            "Other responses:"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Difficulty concentrating on direct conversations: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ASRS9",
                    "optionSummaries": {
                        "79de57bb-786b-49b7-ba5d-4eef37005d2c": "Never",
                        "804a74f9-d711-4ebd-8fb5-4006bde9a792": "Rarely"
                    }
                }
            ]
        },
        "section": [
            "ASRS Initial Screening (Part B)",
            "Other responses:"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Misplace or have difficulty finding things: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ASRS10",
                    "optionSummaries": {
                        "90526640-e201-488c-a16c-c84dcb771bc9": "Never",
                        "6f4d19eb-21f7-43f3-955e-f5e26ecde9da": "Rarely",
                        "62bac245-b02c-4cf1-9386-d4d4d9e3c342": "Sometimes"
                    }
                }
            ]
        },
        "section": [
            "ASRS Initial Screening (Part B)",
            "Other responses:"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Distracted by activity or noise: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ASRS11",
                    "optionSummaries": {
                        "df93abe7-de34-4966-b517-baab39465ddd": "Never",
                        "bd51c1e5-2786-46c6-81ef-0fa63a08767a": "Rarely",
                        "30c661fd-c642-4211-a480-fd49accf8a20": "Sometimes"
                    }
                }
            ]
        },
        "section": [
            "ASRS Initial Screening (Part B)",
            "Other responses:"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Leave seat in meetings when expected to remain seated: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ASRS12",
                    "optionSummaries": {
                        "484c95df-070b-4b06-a210-c6aa2cb652c4": "Never",
                        "b9133a66-1b0f-4b5a-930b-7a20395cb9b5": "Rarely"
                    }
                }
            ]
        },
        "section": [
            "ASRS Initial Screening (Part B)",
            "Other responses:"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Feel restless or fidgety: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ASRS13",
                    "optionSummaries": {
                        "72820602-4ddb-437d-bb2b-020a8c6396e4": "Never",
                        "7948b873-0e10-4166-8797-9653ef952ae2": "Rarely",
                        "dba1ca95-33e3-4134-881b-ca5c0dcb0e94": "Sometimes"
                    }
                }
            ]
        },
        "section": [
            "ASRS Initial Screening (Part B)",
            "Other responses:"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Difficulty unwinding and relaxing during free time: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ASRS14",
                    "optionSummaries": {
                        "6c9bab2e-9d57-46f6-be81-0c931ed4e320": "Never",
                        "35a9bc08-10b9-42a8-b4de-7ecdbce0a0ee": "Rarely",
                        "2e71efd1-fcea-42f3-9cf7-428ffc0c5315": "Sometimes"
                    }
                }
            ]
        },
        "section": [
            "ASRS Initial Screening (Part B)",
            "Other responses:"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Talk too much in social situations: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ASRS15",
                    "optionSummaries": {
                        "3a809ef8-942a-4ee8-89f3-447dc5dc5d04": "Never",
                        "d81d7d63-b185-46df-9fd5-6d32c89463ed": "Rarely",
                        "b150ca31-c022-4c5d-869c-867bd61d8d61": "Sometimes"
                    }
                }
            ]
        },
        "section": [
            "ASRS Initial Screening (Part B)",
            "Other responses:"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Finish others' sentences during conversations: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ASRS16",
                    "optionSummaries": {
                        "afd3f876-98d1-4e90-892b-57d26f960c01": "Never",
                        "68b34045-9196-4026-9978-5287ab1448b6": "Rarely"
                    }
                }
            ]
        },
        "section": [
            "ASRS Initial Screening (Part B)",
            "Other responses:"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Difficulty waiting turn when turn-taking required: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ASRS17",
                    "optionSummaries": {
                        "287f23de-4ddd-4976-a2a4-76028e6f89f1": "Never",
                        "7ddd44b1-ace8-478b-a074-78b6c97f5780": "Rarely",
                        "931d1351-2285-4c95-8df4-83e41fb50689": "Sometimes"
                    }
                }
            ]
        },
        "section": [
            "ASRS Initial Screening (Part B)",
            "Other responses:"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Interrupt others when busy: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ASRS18",
                    "optionSummaries": {
                        "24e8ee51-b4e8-41e9-922b-fe93e03fe04c": "Never",
                        "0c1f5baf-cfec-4851-a6ab-3b57d211fbf5": "Rarely"
                    }
                }
            ]
        },
        "section": [
            "ASRS Initial Screening (Part B)",
            "Other responses:"
        ]
    },
    {
        "answerSummary": {
            "type": "FunctionSummary",
            "formatText": "{}",
            "functionId": "WursTotal"
        },
        "section": [
            "WURS 25",
            "Total Score:"
        ]
    },
    {
        "answerSummary": {
            "type": "FunctionSummary",
            "formatText": "Disruptive Subscale Score (mean): {}",
            "functionId": "WursDisruptive"
        },
        "section": [
            "WURS 25",
            "Disruptive"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Hot or short tempered, low boiling point: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "WURS5",
                    "optionSummaries": {
                        "f701cb56-71b4-42cb-9857-648657d3a96d": "Not at all or very slightly",
                        "3dad2929-ef01-4b2e-9ec2-bde1757efb61": "Mildy",
                        "b2be3a82-0a55-4163-b957-167c5fb7ae62": "Moderately",
                        "0a16daa4-d469-4af0-b8ff-988e722081bf": "Quite a bit",
                        "1340f1d1-b6cb-4136-bd1c-72a5d50fc076": "Very Much"
                    }
                }
            ]
        },
        "section": [
            "WURS 25",
            "Disruptive"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Temper outbursts, tantrums: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "WURS6",
                    "optionSummaries": {
                        "eefb6ce5-1a69-48b5-ac7e-2082119f0bff": "Not at all or very slightly",
                        "ed5a974b-cced-45aa-8123-af9762ce28b6": "Mildy",
                        "7e6a78ba-2f64-4493-9175-55a605929360": "Moderately",
                        "dc88eca8-192b-4b86-9e9a-330cd3dbb079": "Quite a bit",
                        "8d8c1924-c065-4875-b318-5662106fe463": "Very Much"
                    }
                }
            ]
        },
        "section": [
            "WURS 25",
            "Disruptive"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Stubborn, strong willed: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "WURS8",
                    "optionSummaries": {
                        "e35d2ae1-9df5-4d4c-842e-f1e3e9e19795": "Not at all or very slightly",
                        "08922ad8-1f41-41e1-98ef-d5e3c152e459": "Mildy",
                        "2f35db32-7e8c-4e6c-8e06-04bd2edc5bd1": "Moderately",
                        "3d4b05bd-5441-4202-b1e8-cc528a88995a": "Quite a bit",
                        "b9c317ab-73af-40e5-a934-bba4a9b31de0": "Very Much"
                    }
                }
            ]
        },
        "section": [
            "WURS 25",
            "Disruptive"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Disobedient with parents, rebellious, sassy: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "WURS10",
                    "optionSummaries": {
                        "0193b205-95ef-4609-8136-06d9e3f17a74": "Not at all or very slightly",
                        "46bb5d8e-89cf-46ab-9e26-8f09b78d34fa": "Mildy",
                        "a4d8300f-d32a-4700-9f66-34a4866c1467": "Moderately",
                        "91882f0e-3e5c-4f7b-a30b-1f0d577d9a8d": "Quite a bit",
                        "53b989bf-e82e-4e95-9fa3-9873d09c78b1": "Very Much"
                    }
                }
            ]
        },
        "section": [
            "WURS 25",
            "Disruptive"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Irritable: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "WURS12",
                    "optionSummaries": {
                        "85ff2ae0-5f1e-4b1e-9d33-7ed58b7c9e0f": "Not at all or very slightly",
                        "da825593-6b9d-4f9d-b03f-b2cfbd98e3aa": "Mildy",
                        "ac01d8f9-3e5b-4425-b6b0-8d14f0f6e882": "Moderately",
                        "dd3db7c1-7e2d-48f1-b3ec-4d80d8a4f5b2": "Quite a bit",
                        "7a0f9ba5-9068-4de6-9c47-c9350d6ad472": "Very Much"
                    }
                }
            ]
        },
        "section": [
            "WURS 25",
            "Disruptive"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Moody, ups and downs: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "WURS13",
                    "optionSummaries": {
                        "48c3d6ea-726a-4dca-99be-78d58e6be6fa": "Not at all or very slightly",
                        "f3a5d0b4-7598-4f93-9b5d-1b0a6b36e94e": "Mildy",
                        "04a2e8cc-82ab-4e9b-b4de-2e31b5675394": "Moderately",
                        "2570f8ad-8e75-4f80-939b-3ab2e88b9a72": "Quite a bit",
                        "c85c2830-64b0-4dc5-9f06-4b6261f6570e": "Very Much"
                    }
                }
            ]
        },
        "section": [
            "WURS 25",
            "Disruptive"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Angry: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "WURS14",
                    "optionSummaries": {
                        "d2e98b3d-771e-4dd5-a31e-3e7f86c7c0d5": "Not at all or very slightly",
                        "9e6f7f71-8d14-42e1-927e-92e134a4e8e7": "Mildy",
                        "71a0f4d4-7a4f-4266-b987-1376d4e0e1a3": "Moderately",
                        "55e2d506-0842-4c7d-9d8e-f3b9c36d7e6f": "Quite a bit",
                        "a929e35b-1b6e-4a5a-bb55-3de8f0867d56": "Very Much"
                    }
                }
            ]
        },
        "section": [
            "WURS 25",
            "Disruptive"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Losing control of myself: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "WURS18",
                    "optionSummaries": {
                        "62053d76-44c1-4755-95d1-7496868b9e8a": "Not at all or very slightly",
                        "65a81be0-50bb-4e14-99ba-3d8e7c4f8b3c": "Mildy",
                        "2c9e28a4-d176-49a6-b53b-bc6a57e0b4e4": "Moderately",
                        "82a156c4-0c65-4d12-bd4c-e52ec2c3d4d1": "Quite a bit",
                        "0bf63445-8c1b-4950-81c4-1ce2b44fdd91": "Very Much"
                    }
                }
            ]
        },
        "section": [
            "WURS 25",
            "Disruptive"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Tendency to be or act irrational: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "WURS19",
                    "optionSummaries": {
                        "9e2f4ef0-45b3-4f07-83af-7ae8240a0de9": "Not at all or very slightly",
                        "f942d8b6-8ed9-4c66-b6a8-9a8f5c5bb6c9": "Mildy",
                        "3ed97129-1e70-448d-9305-1dfd2ec8e44a": "Moderately",
                        "124d7b5e-6f4a-4de6-8c6d-6c73670d1a0b": "Quite a bit",
                        "49331e1e-9e65-4c0b-a69c-42b4e6d8ab05": "Very Much"
                    }
                }
            ]
        },
        "section": [
            "WURS 25",
            "Disruptive"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Trouble seeing things from someone else's point of view: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "WURS21",
                    "optionSummaries": {
                        "54d62461-1f80-4ec6-8c24-2db8d37f9f19": "Not at all or very slightly",
                        "aaf6d1ad-0a18-411a-b0d8-3a0ab1268df1": "Mildy",
                        "9b2e29f6-c819-470c-846a-4c9ad8f8b71a": "Moderately",
                        "7f466126-7655-4c49-8cbb-6fb3ef389ba3": "Quite a bit",
                        "e9bdf07c-bebb-42fb-9df8-9c4e8af9af56": "Very Much"
                    }
                }
            ]
        },
        "section": [
            "WURS 25",
            "Disruptive"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Trouble with authorities, trouble with school, visits to principal's office: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "WURS22",
                    "optionSummaries": {
                        "90a9a7b7-0e60-4b78-8d1d-8e6f1d3a6b3e": "Not at all or very slightly",
                        "6b3d0b0c-81ea-49bf-9b2c-88c5b445e7f9": "Mildy",
                        "93aee1d6-d49b-4e20-bd7b-0e57782e367a": "Moderately",
                        "47d098a1-0f79-45bb-8a2e-1dd978bbcd60": "Quite a bit",
                        "522f7318-0c8c-4e9f-bc07-2679d9d4c730": "Very Much"
                    }
                }
            ]
        },
        "section": [
            "WURS 25",
            "Disruptive"
        ]
    },
    {
        "answerSummary": {
            "type": "FunctionSummary",
            "formatText": "ADHD Subscale Score (mean): {}",
            "functionId": "WursAdhd"
        },
        "section": [
            "WURS 25",
            "ADHD"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Concentration problems, easily distracted: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "WURS1",
                    "optionSummaries": {
                        "c7f96f1a-5dd5-4324-84b8-886e6b47f8d6": "Not at all or very slightly",
                        "2de63b2c-b5f9-4d55-8c54-ef85d7791a64": "Mildy",
                        "9033a8f3-9d67-43f7-9b5c-3446e3c2c6db": "Moderately",
                        "d7b1c68b-8e18-4a76-83bd-5c2bd072b984": "Quite a bit",
                        "5fa54c0a-9589-4bb0-b934-69eaf1f5d890": "Very Much"
                    }
                }
            ]
        },
        "section": [
            "WURS 25",
            "ADHD"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Nervous, fidgety: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "WURS3",
                    "optionSummaries": {
                        "132f64dc-4c3e-4362-bb64-2fa3e6e4c76c": "Not at all or very slightly",
                        "3c0a1c7f-1a91-4bf6-b3e7-0c0e4f531976": "Mildy",
                        "9dcebf70-36b1-4ddc-8f44-89c94bbf4c8f": "Moderately",
                        "f7e8d6b2-44bb-4a1c-9bf8-1df4b5e0c7e9": "Quite a bit",
                        "83bb76b9-9d9f-4b4e-921c-c5e1e828f57e": "Very Much"
                    }
                }
            ]
        },
        "section": [
            "WURS 25",
            "ADHD"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Inattentive, daydreaming: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "WURS4",
                    "optionSummaries": {
                        "7b8333a3-9ecf-4a93-8f3b-10b3e9b5f6c2": "Not at all or very slightly",
                        "2e60fcd0-345f-43b2-9e8e-4a0f3ebcbe3d": "Mildy",
                        "0c7f4d9b-1db9-4e94-9a91-d0f0cc6d8a21": "Moderately",
                        "12e5f0c8-5a36-481d-8fa1-3f4d9b5e1b6e": "Quite a bit",
                        "6d4f80d3-11b1-4f17-a6df-6b0c6a6f2e2f": "Very Much"
                    }
                }
            ]
        },
        "section": [
            "WURS 25",
            "ADHD"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Trouble with stick-to-it-tiveness, not following through to finish things started: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "WURS7",
                    "optionSummaries": {
                        "6a1e59f8-7367-46a5-9f1c-ccfda1e2e3e8": "Not at all or very slightly",
                        "9b5f6a7c-2c33-4b9f-9e9b-8f7d5e6c4c2b": "Mildy",
                        "b99a8d85-41d5-4e1d-8a94-bf8c9d5f7d3c": "Moderately",
                        "5f3c6e0b-8e8d-4c76-a9a3-d4e9b5f6c7d8": "Quite a bit",
                        "4c1f9e2a-5d7b-4a8b-8c4f-9e6d7f5a1b2c": "Very Much"
                    }
                }
            ]
        },
        "section": [
            "WURS 25",
            "ADHD"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Acting without thinking, impulsive: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "WURS15",
                    "optionSummaries": {
                        "8f7d5e6c-4c2b-4b9f-9e9b-9b5f6a7c2c33": "Not at all or very slightly",
                        "7a1b2c3d-4e5f-6a7b-8c9d-0e1f2a3b4c5d": "Mildy",
                        "9e6d7f5a-1b2c-3d4e-5f6a-7b8c9d0e1f2a": "Moderately",
                        "5f3e4d2c-1b0a-3d4c-5e6f-7a8b9c0d1e2f": "Quite a bit",
                        "4c5d6e7f-8a9b-0c1d-2e3f-4a5b6c7d8e9f": "Very Much"
                    }
                }
            ]
        },
        "section": [
            "WURS 25",
            "ADHD"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Tendency to be immature: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "WURS16",
                    "optionSummaries": {
                        "0e1f2a3b-4c5d-6e7f-8a9b-0c1d2e3f4a5b": "Not at all or very slightly",
                        "5b6c7d8e-9f0a-1b2c-3d4e-5f6a7b8c9d0e": "Mildy",
                        "6f7a8b9c-0d1e-2f3g-4h5i-6j7k8l9m0n1o": "Moderately",
                        "2e3f4a5b-6c7d-8e9f-0a1b-2c3d4e5f6a7b": "Quite a bit",
                        "7b8c9d0e-1f2a-3b4c-5d6e-7f8a9b0c1d2e": "Very Much"
                    }
                }
            ]
        },
        "section": [
            "WURS 25",
            "ADHD"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Overall a poor student, slow learner: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "WURS23",
                    "optionSummaries": {
                        "8a9b0c1d-2e3f-4a5b-6c7d-8e9f0a1b2c3d": "Not at all or very slightly",
                        "3d4e5f6a-7b8c-9d0e-1f2a-3b4c5d6e7f8a": "Mildy",
                        "9e0f1g2h-3i4j-5k6l-7m8n-9o0p1q2r3s4t": "Moderately",
                        "4e5f6a7b-8c9d-0e1f-2a3b-4c5d6e7f8a9b": "Quite a bit",
                        "0f1g2h3i-4j5k-6l7m-8n9o-0p1q2r3s4t5u": "Very Much"
                    }
                }
            ]
        },
        "section": [
            "WURS 25",
            "ADHD"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Trouble with mathematics or numbers: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "WURS24",
                    "optionSummaries": {
                        "5u6v7w8x-9y0z-1a2b-3c4d-5e6f7g8h9i0j": "Not at all or very slightly",
                        "6e7f8a9b-0c1d-2e3f-4a5b-6c7d8e9f0a1b": "Mildy",
                        "1b2c3d4e-5f6a-7b8c-9d0e-1f2a3b4c5d6e": "Moderately",
                        "7g8h9i0j-1k2l-3m4n-5o6p-7q8r9s0t1u2v": "Quite a bit",
                        "8c9d0e1f-2a3b-4c5d-6e7f-8a9b0c1d2e3f": "Very Much"
                    }
                }
            ]
        },
        "section": [
            "WURS 25",
            "ADHD"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Not achieving up to potential: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "WURS25",
                    "optionSummaries": {
                        "9f0a1b2c-3d4e-5f6a-7b8c-9d0e1f2a3b4c": "Not at all or very slightly",
                        "0h1i2j3k-4l5m-6n7o-8p9q-0r1s2t3u4v5w": "Mildy",
                        "5d6e7f8a-9b0c-1d2e-3f4a-5b6c7d8e9f0a": "Moderately",
                        "6g7h8i9j-0k1l-2m3n-4o5p-6q7r8s9t0u1v": "Quite a bit",
                        "1f2a3b4c-5d6e-7f8a-9b0c-1d2e3f4a5b6c": "Very Much"
                    }
                }
            ]
        },
        "section": [
            "WURS 25",
            "ADHD"
        ]
    },
    {
        "answerSummary": {
            "type": "FunctionSummary",
            "formatText": "Anxiety Subscale Score (mean): {}",
            "functionId": "WursAnxiety"
        },
        "section": [
            "WURS 25",
            "Anxiety"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Anxious, worrying: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "WURS2",
                    "optionSummaries": {
                        "7w8x9y0z-1a2b-3c4d-5e6f-7g8h9i0j1k2l": "Not at all or very slightly",
                        "2e3f4a5b-6c7d-8e9f-0a1b-2c3d4e5f6a7b": "Mildy",
                        "8n9o0p1q-2r3s-4t5u-6v7w-8x9y0z1a2b3c": "Moderately",
                        "3d4e5f6a-7b8c-9d0e-1f2a-3b4c5d6e7f8a": "Quite a bit",
                        "4o5p6q7r-8s9t-0u1v-2w3x-4y5z6a7b8c9d": "Very Much"
                    }
                }
            ]
        },
        "section": [
            "WURS 25",
            "Anxiety"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Sad or blue, depressed, unhappy: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "WURS9",
                    "optionSummaries": {
                        "9d0e1f2a-3b4c-5d6e-7f8a-9b0c1d2e3f4a": "Not at all or very slightly",
                        "5d6e7f8a-9b0c-1d2e-3f4a-5b6c7d8e9f0a": "Mildy",
                        "0i1j2k3l-4m5n-6o7p-8q9r-0s1t2u3v4w5x": "Moderately",
                        "6c7d8e9f-0a1b-2c3d-4e5f-6a7b8c9d0e1f": "Quite a bit",
                        "7y8z9a0b-1c2d-3e4f-5g6h-7i8j9k0l1m2n": "Very Much"
                    }
                }
            ]
        },
        "section": [
            "WURS 25",
            "Anxiety"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Low opinion of themselves: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "WURS11",
                    "optionSummaries": {
                        "8k9l0m1n-2o3p-4q5r-6s7t-8u9v0w1x2y3z": "Not at all or very slightly",
                        "1b2c3d4e-5f6a-7b8c-9d0e-1f2a3b4c5d6e": "Mildy",
                        "9o0p1q2r-3s4t-5u6v-7w8x-9y0z1a2b3c4d": "Moderately",
                        "2e3f4a5b-6c7d-8e9f-0a1b-2c3d4e5f6a7b": "Quite a bit",
                        "5e6f7g8h-9i0j-1k2l-3m4n-5o6p7q8r9s0t": "Very Much"
                    }
                }
            ]
        },
        "section": [
            "WURS 25",
            "Anxiety"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Guilty feelings, regretful: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "WURS17",
                    "optionSummaries": {
                        "3f4g5h6i-7j8k-9l0m-1n2o-3p4q5r6s7t8u": "Not at all or very slightly",
                        "6a7b8c9d-0e1f-2a3b-4c5d-6e7f8a9b0c1d": "Mildy",
                        "4v5w6x7y-8z9a-0b1c-2d3e-4f5g6h7i8j9k": "Moderately",
                        "7g8h9i0j-1k2l-3m4n-5o6p-7q8r9s0t1u2v": "Quite a bit",
                        "0p1q2r3s-4t5u-6v7w-8x9y-0z1a2b3c4d5e": "Very Much"
                    }
                }
            ]
        },
        "section": [
            "WURS 25",
            "Anxiety"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Unpopular with other children, short friendships, didn't get along with other children: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "WURS20",
                    "optionSummaries": {
                        "9y0z1a2b-3c4d-5e6f-7g8h-9i0j1k2l3m4n": "Not at all or very slightly",
                        "5f6a7b8c-9d0e-1f2a-3b4c-5d6e7f8a9b0c": "Mildy",
                        "1n2o3p4q-5r6s-7t8u-9v0w-1x2y3z4a5b6c": "Moderately",
                        "6e7f8a9b-0c1d-2e3f-4a5b-6c7d8e9f0a1b": "Quite a bit",
                        "8o9p0q1r-2s3t-4u5v-6w7x-8y9z0a1b2c3d": "Very Much"
                    }
                }
            ]
        },
        "section": [
            "WURS 25",
            "Not Catergorised Item"
        ]
    }
];
