import React from 'react'
import { Text } from '@psyomics/components'
import ContentPage from 'ui/app/components/ContentPage'

export const NoAssessmentsInProgress: React.FC<{ isFirstVisit: boolean }> = ({ isFirstVisit }) => {
    return (
        <ContentPage headingText={`Welcome ${isFirstVisit ? '' : 'Back'}`}>
            <Text>Your assessment is no longer valid.</Text>
            <Text>
                This may be due to it expiring or being cancelled by your healthcare provider. Please get in touch with
                your healthcare provider for more information and to discuss the next steps.
            </Text>
        </ContentPage>
    )
}
