import Onboarding from './Onboarding'

// todo: move to somewhere shared
export enum GenderOptions {
    Female = 'Female',
    Male = 'Male',
    NonBinary = 'Non-binary',
    Other = 'Other',
    PreferNotToSay = 'Prefer not to say',
}

export default Onboarding
