"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.questionPredicates = exports.tests = exports.aspects = exports.questions = void 0;
const censeo_1 = require("../../schema/generated/censeo");
exports.questions = [
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "f0bf8997-4579-5872-82e1-1400b895417b",
                "text": "Stimulants: amphetamines, 'speed', 'crystal meth', MDMA ('mandy', 'MD', 'ecstasy', 'e')"
            },
            {
                "__typename": "Option",
                "id": "4cf86ba0-867d-5f6d-b8d1-3aa5ceddc374",
                "text": "Cocaine: snorting, injecting, crack, 'freebase', 'speedball'"
            },
            {
                "__typename": "Option",
                "id": "e58d41b9-ba61-5862-88ea-44bbd63946cb",
                "text": "Opiates (injectable): heroin, morphine, opium, methadone, fentanyl"
            },
            {
                "__typename": "Option",
                "id": "2075c9f4-b5e7-5615-b714-0ab3a1b5d046",
                "text": "Opiate (tablets): codeine, Dilaudid, Demerol, OxyContin, Percodan"
            },
            {
                "__typename": "Option",
                "id": "18e8ad9b-e9a7-5311-8a6f-e9e64c5997fe",
                "text": "Hallucinogens: LSD ('acid'), peyote, mescaline, PCP ('Angel Dust', 'peace pill'), Psilocybin ('magic mushrooms') or ketamine ('ket', 'k', 'vitamin K', 'special K')"
            },
            {
                "__typename": "Option",
                "id": "4f2c6cea-7f4c-55ca-b952-334af926e7b1",
                "text": "Inhalants: 'glue', ethyl chloride, nitrous oxide ('laughing gas'), amyl or butyl nitrate ('poppers')"
            },
            {
                "__typename": "Option",
                "id": "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280",
                "text": "Marijuana: hashish ('hash'), THC, 'grass', 'pot', 'weed'"
            },
            {
                "__typename": "Option",
                "id": "6248c04e-ea14-5b3e-8821-7cdbe8f84be3",
                "text": "Tranquilisers: Quaalude, Seconal ('reds'), Xanax, Valium, Librium, Ativan, Dalmane or flurazepam, barbiturates, Miltown, Halcion, GHB, Rohypnol ('Roofies')"
            },
            {
                "__typename": "Option",
                "id": "36c77f4a-59a6-588e-8346-b190d1491a17",
                "text": "Miscellaneous: steroids, diet pills or non-prescription sleeping pills"
            },
            {
                "__typename": "Option",
                "id": "084e1561-5048-57b0-8796-986f65764e19",
                "text": "A different drug that has not been listed"
            },
            {
                "__typename": "Option",
                "id": "8e076e07-f383-5ebe-aafd-63955ee35c27",
                "text": "None of the above",
                "exclusive": true
            }
        ],
        "id": "SU-s1",
        "text": "Have you used any of the drugs listed below for non-medical purposes? Please select all that apply.",
        "isMultiselect": true
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "67d6ef3c-b5a8-5230-b556-3bc42e2e5179",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "3ed9bf02-1389-5b1a-81e0-5dadb1daa205",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "SU-s3",
        "text": "Have you taken any of these drugs in the last year?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "0d449f5a-347e-5f72-8f8d-a25c20458adc",
                "text": "Stimulants: amphetamines, 'speed', 'crystal meth', MDMA ('mandy', 'MD', 'ecstasy', 'e')"
            },
            {
                "__typename": "Option",
                "id": "82702400-306e-52c0-b320-cc03e2f74660",
                "text": "Cocaine: snorting, injecting, crack, 'freebase', 'speedball'"
            },
            {
                "__typename": "Option",
                "id": "c433cd2b-8136-5f70-abcc-7e0358b27250",
                "text": "Opiates (injectable): heroin, morphine, opium, methadone, fentanyl"
            },
            {
                "__typename": "Option",
                "id": "0874416b-3740-5988-91da-b5ef5aad981f",
                "text": "Opiate (tablets): codeine, Dilaudid, Demerol, OxyContin, Percodan"
            },
            {
                "__typename": "Option",
                "id": "6492c387-163d-5eb6-a22a-ae666137625e",
                "text": "Hallucinogens: LSD ('acid'), peyote, mescaline, PCP ('Angel Dust', 'peace pill'), Psilocybin ('magic mushrooms') or ketamine ('ket', 'k', 'vitamin K', 'special K')"
            },
            {
                "__typename": "Option",
                "id": "668fd132-b94f-597d-b753-c783fa17ef62",
                "text": "Inhalants: 'glue', ethyl chloride, nitrous oxide ('laughing gas'), amyl or butyl nitrate ('poppers')"
            },
            {
                "__typename": "Option",
                "id": "28f722a5-8507-556b-abe2-8413d34679e4",
                "text": "Marijuana: hashish ('hash'), THC, 'grass', 'pot', 'weed'"
            },
            {
                "__typename": "Option",
                "id": "5e6a1288-43f9-550c-ae2a-982973f4e551",
                "text": "Tranquilisers: Quaalude, Seconal ('reds'), Xanax, Valium, Librium, Ativan, Dalmane or flurazepam, barbiturates, Miltown, Halcion, GHB, Rohypnol ('Roofies')"
            },
            {
                "__typename": "Option",
                "id": "95ba4a6b-7026-5c10-98e9-07370c2b605a",
                "text": "Miscellaneous: steroids, diet pills or non-prescription sleeping pills"
            },
            {
                "__typename": "Option",
                "id": "3a4d2bec-b4ca-56e7-9044-99ca4db70f63",
                "text": "A different drug that has not been listed"
            },
            {
                "__typename": "Option",
                "id": "d8058fa3-3c7e-51dc-9b11-824ee72cb07e",
                "text": "None of the above",
                "exclusive": true
            }
        ],
        "id": "SU-s3.1",
        "text": "Which of these drugs have you used in the last year? Please select all that apply.",
        "isMultiselect": true
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "dc9dd01a-e6c0-58c8-9b8f-b3315d6a689c",
                "text": "Monthly or less",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "64b85f3c-84e5-5d37-bc42-dab6740c8199",
                "text": "2–4 times per month",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "22270598-ff5e-5785-a9a3-9bbed0fd9751",
                "text": "2–3 times per week",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "5f82977e-8743-5b56-9f4d-7a3e8cc571cd",
                "text": "4–5 times per week",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "1a337ce3-5ab7-5773-9fe0-f0d1c23ed96c",
                "text": "More than 6 times per week",
                "value": 1
            }
        ],
        "id": "SU-1a",
        "text": "How often have you used these drugs in the last year?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "6d761e88-087b-5887-9e08-7720347f7b48",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "61e46908-594f-5831-b709-767c60df9f2b",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "SU-2a",
        "text": "Do you have a strong desire or urge to take drugs?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "cba96b30-e2c3-5d5b-80a8-c18a79809f16",
                "text": "I have not tried to cut down or control my drug use",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "8b9ec309-bccd-5e75-82c2-0b9f6bbf25fd",
                "text": "I have tried to cut down or control my drug use but found that I couldn't",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "a3720a21-cf5f-5c57-9dde-8cb54605246f",
                "text": "I have tried to cut down or control my drug use and found that I could",
                "value": 1
            }
        ],
        "id": "SU-3a",
        "text": "In the last year, have you tried to cut down or control your drug use but found that you couldn't?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "ede457f4-731b-56bd-b7b7-58d93ecdcacd",
                "text": "Never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "dd97a5ec-89cc-5ee7-b89b-644d9f1a2b7b",
                "text": "Less than monthly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "3927c90d-af04-584d-a800-515db0f2ec3d",
                "text": "Monthly",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "56dbee32-dbd2-53fe-8f12-0a5ca96ae328",
                "text": "Weekly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "6d708b4f-6b53-5630-8bc7-ce15608df0e5",
                "text": "Daily or almost daily",
                "value": 1
            }
        ],
        "id": "SU-4a",
        "text": "Due to your drug use, how often in the last year have you failed to do what was expected of you?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "7ec23bf3-d08e-58e1-a88a-9677b0b85ec8",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "e990acdb-4f26-5f2a-9794-2949aca56fb2",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "SU-5a",
        "text": "In the last year, has trying to get hold of drugs, being under the influence or recovering from the use of drugs taken up a large part of your life?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "a16682f6-84c6-5bdd-9553-eafb92701f79",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "9af0558c-80cf-59e9-b3b5-1c0df4b4cf4b",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "SU-6a",
        "text": "Have you got into serious trouble in the last year due to drugs?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "698ebf04-9eee-5b77-97f3-dd84b14ac94a",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "85e98c24-bf79-526d-8945-070f551c81c7",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "SU-7a",
        "text": "Has someone expressed concern about your drug-taking in the last year?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "38ede561-e889-51b7-baa3-51217ea1552e",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "4ade59f6-9242-595c-9d22-1c907b3dcbd6",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "SU-8a",
        "text": "In the last year, has taking drugs caused arguments or other serious problems with your friends, family neighbours or co-workers?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "ccb31960-a823-519d-a4a9-7348ba4ca6f5",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "16c3b305-ee67-59a9-9182-8d6c929a9509",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "SU-9a",
        "text": "Do you have to take more of the drug to get the same effect as you used to?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "ed6c59f2-8713-56b1-a4b2-8f1f85b224ed",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "dfdb12b6-8e8e-58eb-8a79-3ffaf59b4d9c",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "SU-10a",
        "text": "After trying to reduce drug-taking or not taking drugs for a while, some people experience sweating, a high pulse, nausea, sleep problems, shaking hands, anxiety or hallucinations, which can last for weeks. Is this something that has happened to you in the past year?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "57899734-07b2-50a2-a5d8-56142b5feb92",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "8f999645-02ed-57fb-90c7-c509bdf9217d",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "SU-11a",
        "text": "Has the use of drugs caused damage to your physical or mental health?",
        "isMultiselect": false
    },
    {
        "__typename": "FreeQuestion",
        "id": "SU-1b",
        "text": "In reference to previous drug use:\n\nApproximately, how long ago did you take the drugs you indicated previously?",
        "unit": censeo_1.Unit.DurationMinutes
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "f6b6411b-02e6-531d-b2ed-67d6dfacfd96",
                "text": "Monthly or less",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "4678ad6e-17f5-504f-84d5-d7e4bd032e1f",
                "text": "2–4 times per month",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "69f7eb47-cf61-5bc3-b485-494de2684114",
                "text": "2–3 times per week",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "f2377583-a77e-55a4-9869-e01408506abf",
                "text": "4–5 times per week",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "28fd19f5-e24d-5aa5-ab06-4e63101e3ef7",
                "text": "More than 6 times per week",
                "value": 1
            }
        ],
        "id": "SU-2b",
        "text": "In reference to previous drug use:\n\nAt the time, how often did you used to take drugs?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "48eb47f4-b5d0-5ca4-ba6a-5f811f32d488",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "97837883-2c07-58ae-87ab-970f4a8e16d7",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "SU-3b",
        "text": "In reference to previous drug use:\n\nDid you have a strong desire or urge to take drugs?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "a55e2d73-6bb1-5ddf-b7f0-be44aa25ea75",
                "text": "I did not try to cut down or control my drug use",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "c48b4250-be6d-53b5-9c4b-50a2f5facea4",
                "text": "I tried to cut down or control my drug use but found that I couldn't",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "5af10404-91e9-55e5-a3a5-5b0525ebf15c",
                "text": "I tried to cut down or control my drug use and found that I could",
                "value": 1
            }
        ],
        "id": "SU-4b",
        "text": "In reference to previous drug use:\n\nDid you try to cut down or control your drug use but found that you couldn't?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "a6d3f751-7a06-5eb4-97b5-70831bdb0e3d",
                "text": "Never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "6e890ffb-ea8c-50f4-87d8-0b9fcb05e77c",
                "text": "Less than monthly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "bcac9437-7015-5fe5-9ee4-fb99cf0d5f17",
                "text": "Monthly",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "6c0b7b5f-bac4-5d59-884f-ca8f482f11d4",
                "text": "Weekly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "61e94409-cc88-5298-9cee-7d1ff14341fa",
                "text": "Daily or almost daily",
                "value": 1
            }
        ],
        "id": "SU-5b",
        "text": "In reference to previous drug use:\n\nDue to taking drugs, how often did you fail to do what was expected of you?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "e6d0d639-7af5-5de3-9355-51cd24b425c5",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "2877cecd-91f0-5b3c-a584-708c83205504",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "SU-6b",
        "text": "In reference to previous drug use:\n\nDid trying to get hold of drugs, being under the influence or recovering from the use of drugs take up a large part of your life?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "fb9594cf-71a8-580c-80d0-18dd9696d5bc",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "b6a26449-f023-5a8a-a4c9-a6c625f0f366",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "SU-7b",
        "text": "In reference to previous drug use:\n\nDid you get into serious trouble due to drugs?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "a7206832-dc04-531b-bb17-18b8132ec246",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "32ec1d91-b47c-5754-a656-c6c0f230ace2",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "SU-8b",
        "text": "In reference to previous drug use:\n\nDid someone express concern about your drug-taking?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "70dd7443-067d-5db4-bbfd-32f5c623fe8a",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "485e4f43-7787-5f16-8ff0-e578b0ff4b83",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "SU-9b",
        "text": "In reference to previous drug use:\n\nDid taking drugs cause arguments or other serious or repeated problems with your family, friends, neighbours or co-workers?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "fe352b2c-eff2-5046-8413-d86aa9192485",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "eb7acd72-f4fa-52a4-b53f-db24e7756bbf",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "SU-10b",
        "text": "In reference to previous drug use:\n\nDid you have to take more of the drug to get the same effect as you used to?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "1c65112c-5105-5069-a400-b16d985d1bdf",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "df81db2f-9fe7-5b09-be87-64780dd81a25",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "SU-11b",
        "text": "In reference to previous drug use:\n\nAfter trying to reduce drug-taking or not taking drugs for a while, some people experience sweating, a high pulse, nausea, sleep problems, shaking hands, anxiety or hallucinations, which can last for weeks. Is this something that happened to you?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "cc068b90-c986-5049-9322-7b8b0fad4143",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "7debcb22-6637-5de5-9421-89be645c3b96",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "SU-12b",
        "text": "In reference to previous drug use:\n\nHas the use of drugs caused damage to your physical or mental health?",
        "isMultiselect": false
    }
];
exports.aspects = {
    "sbs_ce": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "has_su"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "su_c"
                }
            }
        ],
        "anyToAll": 1
    },
    "sbs_pe": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "has_su"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "su_p"
                }
            }
        ],
        "anyToAll": 1
    },
    "has_su": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "su_use"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "su_problematic_use"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "su_frequency"
                }
            }
        ],
        "anyToAll": 1
    },
    "su_use": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "SU1_stimulants"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "SUs1_stimulants"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "SU1_cocaine"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "SUs1_cocaine"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "SU1_opiateinject"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "SUs1_opiateinject"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "SU1_opiatepills"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "SUs1_opiatepills"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "SU1_hallucinogens"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "SUs1_hallucinogens"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "SU1_inhalants"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "SUs1_inhalants"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "SU1_marijuana"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "SUs1_marijuana"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "SU2_tranquilizers"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "SUs2_tranquilizers"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "SU2_misc"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "SUs2_misc"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "SU2_other"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "SUs2_other"
                }
            }
        ],
        "anyToAll": 0.05
    },
    "su_problematic_use": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "su_cut_down_inability"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "su_time_spent"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "su_craving"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "su_role_fulfillment_failure"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "su_interpersonal_problems"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "su_tolerance"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "su_withdrawal"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "su_psych_phys_problems"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "su_others_worried"
                }
            }
        ],
        "anyToAll": 0.1111111111111111
    },
    "su_cut_down_inability": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "SU-3a",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "cba96b30-e2c3-5d5b-80a8-c18a79809f16": false,
                            "8b9ec309-bccd-5e75-82c2-0b9f6bbf25fd": true,
                            "a3720a21-cf5f-5c57-9dde-8cb54605246f": false
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "SU-4b",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "a55e2d73-6bb1-5ddf-b7f0-be44aa25ea75": false,
                            "c48b4250-be6d-53b5-9c4b-50a2f5facea4": true,
                            "5af10404-91e9-55e5-a3a5-5b0525ebf15c": false
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.5
    },
    "su_time_spent": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "SU-5a",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "7ec23bf3-d08e-58e1-a88a-9677b0b85ec8": false,
                            "e990acdb-4f26-5f2a-9794-2949aca56fb2": true
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "SU-6b",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "e6d0d639-7af5-5de3-9355-51cd24b425c5": false,
                            "2877cecd-91f0-5b3c-a584-708c83205504": true
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.5
    },
    "su_craving": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "SU-2a",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "6d761e88-087b-5887-9e08-7720347f7b48": false,
                            "61e46908-594f-5831-b709-767c60df9f2b": true
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "SU-3b",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "48eb47f4-b5d0-5ca4-ba6a-5f811f32d488": false,
                            "97837883-2c07-58ae-87ab-970f4a8e16d7": true
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.5
    },
    "su_role_fulfillment_failure": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "SU-4a",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "ede457f4-731b-56bd-b7b7-58d93ecdcacd": 0.0066928509242848554,
                            "dd97a5ec-89cc-5ee7-b89b-644d9f1a2b7b": 0.07585818002124355,
                            "3927c90d-af04-584d-a800-515db0f2ec3d": 0.5,
                            "56dbee32-dbd2-53fe-8f12-0a5ca96ae328": 0.9241418199787566,
                            "6d708b4f-6b53-5630-8bc7-ce15608df0e5": 0.9933071490757153
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "SU-5b",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "a6d3f751-7a06-5eb4-97b5-70831bdb0e3d": 0.0066928509242848554,
                            "6e890ffb-ea8c-50f4-87d8-0b9fcb05e77c": 0.07585818002124355,
                            "bcac9437-7015-5fe5-9ee4-fb99cf0d5f17": 0.5,
                            "6c0b7b5f-bac4-5d59-884f-ca8f482f11d4": 0.9241418199787566,
                            "61e94409-cc88-5298-9cee-7d1ff14341fa": 0.9933071490757153
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.5
    },
    "su_interpersonal_problems": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "SU-8a",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "38ede561-e889-51b7-baa3-51217ea1552e": false,
                            "4ade59f6-9242-595c-9d22-1c907b3dcbd6": true
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "SU-9b",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "70dd7443-067d-5db4-bbfd-32f5c623fe8a": false,
                            "485e4f43-7787-5f16-8ff0-e578b0ff4b83": true
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.5
    },
    "su_tolerance": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "SU-9a",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "ccb31960-a823-519d-a4a9-7348ba4ca6f5": false,
                            "16c3b305-ee67-59a9-9182-8d6c929a9509": true
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "SU-10b",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "fe352b2c-eff2-5046-8413-d86aa9192485": false,
                            "eb7acd72-f4fa-52a4-b53f-db24e7756bbf": true
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.5
    },
    "su_withdrawal": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "SU-10a",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "ed6c59f2-8713-56b1-a4b2-8f1f85b224ed": false,
                            "dfdb12b6-8e8e-58eb-8a79-3ffaf59b4d9c": true
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "SU-11b",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "1c65112c-5105-5069-a400-b16d985d1bdf": false,
                            "df81db2f-9fe7-5b09-be87-64780dd81a25": true
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.5
    },
    "su_psych_phys_problems": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "SU-11a",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "57899734-07b2-50a2-a5d8-56142b5feb92": false,
                            "8f999645-02ed-57fb-90c7-c509bdf9217d": true
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "SU-12b",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "cc068b90-c986-5049-9322-7b8b0fad4143": false,
                            "7debcb22-6637-5de5-9421-89be645c3b96": true
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.5
    },
    "su_others_worried": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "SU-7a",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "698ebf04-9eee-5b77-97f3-dd84b14ac94a": false,
                            "85e98c24-bf79-526d-8945-070f551c81c7": true
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "SU-8b",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "a7206832-dc04-531b-bb17-18b8132ec246": false,
                            "32ec1d91-b47c-5754-a656-c6c0f230ace2": true
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.5
    },
    "su_frequency": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "SU-1a",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "dc9dd01a-e6c0-58c8-9b8f-b3315d6a689c": 0.5,
                            "64b85f3c-84e5-5d37-bc42-dab6740c8199": 0.9241418199787566,
                            "22270598-ff5e-5785-a9a3-9bbed0fd9751": 0.9933071490757153,
                            "5f82977e-8743-5b56-9f4d-7a3e8cc571cd": 0.9994472213630764,
                            "1a337ce3-5ab7-5773-9fe0-f0d1c23ed96c": 0.9999546021312976
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "SU-2b",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "f6b6411b-02e6-531d-b2ed-67d6dfacfd96": 0.5,
                            "4678ad6e-17f5-504f-84d5-d7e4bd032e1f": 0.9241418199787566,
                            "69f7eb47-cf61-5bc3-b485-494de2684114": 0.9933071490757153,
                            "f2377583-a77e-55a4-9869-e01408506abf": 0.9994472213630764,
                            "28fd19f5-e24d-5aa5-ab06-4e63101e3ef7": 0.9999546021312976
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.5
    },
    "su_c": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SU-s3",
            "options": {
                "type": "predicates",
                "predicates": {
                    "67d6ef3c-b5a8-5230-b556-3bc42e2e5179": false,
                    "3ed9bf02-1389-5b1a-81e0-5dadb1daa205": true
                }
            }
        }
    },
    "su_p": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "su_c"
            }
        }
    },
    "SUs1_stimulants": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SU-s1",
            "options": {
                "type": "severities",
                "severities": {
                    "f0bf8997-4579-5872-82e1-1400b895417b": 1
                }
            }
        }
    },
    "SUs1_cocaine": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SU-s1",
            "options": {
                "type": "severities",
                "severities": {
                    "4cf86ba0-867d-5f6d-b8d1-3aa5ceddc374": 1
                }
            }
        }
    },
    "SUs1_opiateinject": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SU-s1",
            "options": {
                "type": "severities",
                "severities": {
                    "e58d41b9-ba61-5862-88ea-44bbd63946cb": 1
                }
            }
        }
    },
    "SUs1_opiatepills": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SU-s1",
            "options": {
                "type": "severities",
                "severities": {
                    "2075c9f4-b5e7-5615-b714-0ab3a1b5d046": 1
                }
            }
        }
    },
    "SUs1_hallucinogens": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SU-s1",
            "options": {
                "type": "severities",
                "severities": {
                    "18e8ad9b-e9a7-5311-8a6f-e9e64c5997fe": 1
                }
            }
        }
    },
    "SUs1_inhalants": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SU-s1",
            "options": {
                "type": "severities",
                "severities": {
                    "4f2c6cea-7f4c-55ca-b952-334af926e7b1": 1
                }
            }
        }
    },
    "SUs1_marijuana": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SU-s1",
            "options": {
                "type": "severities",
                "severities": {
                    "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280": 1
                }
            }
        }
    },
    "SUs2_tranquilizers": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SU-s1",
            "options": {
                "type": "severities",
                "severities": {
                    "6248c04e-ea14-5b3e-8821-7cdbe8f84be3": 1
                }
            }
        }
    },
    "SUs2_misc": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SU-s1",
            "options": {
                "type": "severities",
                "severities": {
                    "36c77f4a-59a6-588e-8346-b190d1491a17": 1
                }
            }
        }
    },
    "SUs2_other": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SU-s1",
            "options": {
                "type": "severities",
                "severities": {
                    "084e1561-5048-57b0-8796-986f65764e19": 1
                }
            }
        }
    },
    "SUs2_": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SU-s1",
            "options": {
                "type": "severities",
                "severities": {
                    "8e076e07-f383-5ebe-aafd-63955ee35c27": 1
                }
            }
        }
    },
    "SU1_stimulants": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SU-s3.1",
            "options": {
                "type": "severities",
                "severities": {
                    "0d449f5a-347e-5f72-8f8d-a25c20458adc": 1
                }
            }
        }
    },
    "SU1_cocaine": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SU-s3.1",
            "options": {
                "type": "severities",
                "severities": {
                    "82702400-306e-52c0-b320-cc03e2f74660": 1
                }
            }
        }
    },
    "SU1_opiateinject": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SU-s3.1",
            "options": {
                "type": "severities",
                "severities": {
                    "c433cd2b-8136-5f70-abcc-7e0358b27250": 1
                }
            }
        }
    },
    "SU1_opiatepills": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SU-s3.1",
            "options": {
                "type": "severities",
                "severities": {
                    "0874416b-3740-5988-91da-b5ef5aad981f": 1
                }
            }
        }
    },
    "SU1_hallucinogens": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SU-s3.1",
            "options": {
                "type": "severities",
                "severities": {
                    "6492c387-163d-5eb6-a22a-ae666137625e": 1
                }
            }
        }
    },
    "SU1_inhalants": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SU-s3.1",
            "options": {
                "type": "severities",
                "severities": {
                    "668fd132-b94f-597d-b753-c783fa17ef62": 1
                }
            }
        }
    },
    "SU1_marijuana": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SU-s3.1",
            "options": {
                "type": "severities",
                "severities": {
                    "28f722a5-8507-556b-abe2-8413d34679e4": 1
                }
            }
        }
    },
    "SU2_tranquilizers": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SU-s3.1",
            "options": {
                "type": "severities",
                "severities": {
                    "5e6a1288-43f9-550c-ae2a-982973f4e551": 1
                }
            }
        }
    },
    "SU2_misc": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SU-s3.1",
            "options": {
                "type": "severities",
                "severities": {
                    "95ba4a6b-7026-5c10-98e9-07370c2b605a": 1
                }
            }
        }
    },
    "SU2_other": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SU-s3.1",
            "options": {
                "type": "severities",
                "severities": {
                    "3a4d2bec-b4ca-56e7-9044-99ca4db70f63": 1
                }
            }
        }
    },
    "SU2_": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SU-s3.1",
            "options": {
                "type": "severities",
                "severities": {
                    "d8058fa3-3c7e-51dc-9b11-824ee72cb07e": 1
                }
            }
        }
    },
    "and[or[isUndefined(SUs2_=1), not(SUs2_=1)], not(isUndefined(SU-s1=0))]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "SUs2_"
                            }
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "SUs2_"
                            }
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "not",
                "wire": {
                    "type": "isUnknown",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "SU-s1",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "f0bf8997-4579-5872-82e1-1400b895417b": false,
                                    "4cf86ba0-867d-5f6d-b8d1-3aa5ceddc374": false,
                                    "e58d41b9-ba61-5862-88ea-44bbd63946cb": false,
                                    "2075c9f4-b5e7-5615-b714-0ab3a1b5d046": false,
                                    "18e8ad9b-e9a7-5311-8a6f-e9e64c5997fe": false,
                                    "4f2c6cea-7f4c-55ca-b952-334af926e7b1": false,
                                    "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280": false,
                                    "6248c04e-ea14-5b3e-8821-7cdbe8f84be3": false,
                                    "36c77f4a-59a6-588e-8346-b190d1491a17": false,
                                    "084e1561-5048-57b0-8796-986f65764e19": false,
                                    "8e076e07-f383-5ebe-aafd-63955ee35c27": false
                                }
                            }
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "not(SU-s3=0)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "SU-s3=0"
            }
        }
    },
    "SU-s3=0": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SU-s3",
            "options": {
                "type": "predicates",
                "predicates": {
                    "67d6ef3c-b5a8-5230-b556-3bc42e2e5179": true,
                    "3ed9bf02-1389-5b1a-81e0-5dadb1daa205": false
                }
            }
        }
    }
};
exports.tests = {
    "sbs_ce": [
        {
            "name": "screened user: Z3RPNEO aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: NEL6L3V aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: K39ZL3L aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10b": {
                    "questionId": "SU-10b",
                    "chosenOptionIds": [
                        "fe352b2c-eff2-5046-8413-d86aa9192485"
                    ]
                },
                "SU-11b": {
                    "questionId": "SU-11b",
                    "chosenOptionIds": [
                        "1c65112c-5105-5069-a400-b16d985d1bdf"
                    ]
                },
                "SU-12b": {
                    "questionId": "SU-12b",
                    "chosenOptionIds": [
                        "cc068b90-c986-5049-9322-7b8b0fad4143"
                    ]
                },
                "SU-1b": {
                    "questionId": "SU-1b",
                    "freeValue": 1825
                },
                "SU-2b": {
                    "questionId": "SU-2b",
                    "chosenOptionIds": [
                        "69f7eb47-cf61-5bc3-b485-494de2684114"
                    ]
                },
                "SU-3b": {
                    "questionId": "SU-3b",
                    "chosenOptionIds": [
                        "48eb47f4-b5d0-5ca4-ba6a-5f811f32d488"
                    ]
                },
                "SU-4b": {
                    "questionId": "SU-4b",
                    "chosenOptionIds": [
                        "a55e2d73-6bb1-5ddf-b7f0-be44aa25ea75"
                    ]
                },
                "SU-5b": {
                    "questionId": "SU-5b",
                    "chosenOptionIds": [
                        "a6d3f751-7a06-5eb4-97b5-70831bdb0e3d"
                    ]
                },
                "SU-6b": {
                    "questionId": "SU-6b",
                    "chosenOptionIds": [
                        "e6d0d639-7af5-5de3-9355-51cd24b425c5"
                    ]
                },
                "SU-7b": {
                    "questionId": "SU-7b",
                    "chosenOptionIds": [
                        "fb9594cf-71a8-580c-80d0-18dd9696d5bc"
                    ]
                },
                "SU-8b": {
                    "questionId": "SU-8b",
                    "chosenOptionIds": [
                        "a7206832-dc04-531b-bb17-18b8132ec246"
                    ]
                },
                "SU-9b": {
                    "questionId": "SU-9b",
                    "chosenOptionIds": [
                        "70dd7443-067d-5db4-bbfd-32f5c623fe8a"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "67d6ef3c-b5a8-5230-b556-3bc42e2e5179"
                    ]
                }
            }
        },
        {
            "name": "screened user: 6PE670L aspect: sbs_ce should be positive",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "SU-10a": {
                    "questionId": "SU-10a",
                    "chosenOptionIds": [
                        "ed6c59f2-8713-56b1-a4b2-8f1f85b224ed"
                    ]
                },
                "SU-11a": {
                    "questionId": "SU-11a",
                    "chosenOptionIds": [
                        "57899734-07b2-50a2-a5d8-56142b5feb92"
                    ]
                },
                "SU-1a": {
                    "questionId": "SU-1a",
                    "chosenOptionIds": [
                        "1a337ce3-5ab7-5773-9fe0-f0d1c23ed96c"
                    ]
                },
                "SU-2a": {
                    "questionId": "SU-2a",
                    "chosenOptionIds": [
                        "6d761e88-087b-5887-9e08-7720347f7b48"
                    ]
                },
                "SU-3a": {
                    "questionId": "SU-3a",
                    "chosenOptionIds": [
                        "8b9ec309-bccd-5e75-82c2-0b9f6bbf25fd"
                    ]
                },
                "SU-4a": {
                    "questionId": "SU-4a",
                    "chosenOptionIds": [
                        "ede457f4-731b-56bd-b7b7-58d93ecdcacd"
                    ]
                },
                "SU-5a": {
                    "questionId": "SU-5a",
                    "chosenOptionIds": [
                        "7ec23bf3-d08e-58e1-a88a-9677b0b85ec8"
                    ]
                },
                "SU-6a": {
                    "questionId": "SU-6a",
                    "chosenOptionIds": [
                        "a16682f6-84c6-5bdd-9553-eafb92701f79"
                    ]
                },
                "SU-7a": {
                    "questionId": "SU-7a",
                    "chosenOptionIds": [
                        "698ebf04-9eee-5b77-97f3-dd84b14ac94a"
                    ]
                },
                "SU-8a": {
                    "questionId": "SU-8a",
                    "chosenOptionIds": [
                        "38ede561-e889-51b7-baa3-51217ea1552e"
                    ]
                },
                "SU-9a": {
                    "questionId": "SU-9a",
                    "chosenOptionIds": [
                        "ccb31960-a823-519d-a4a9-7348ba4ca6f5"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "36c77f4a-59a6-588e-8346-b190d1491a17"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "3ed9bf02-1389-5b1a-81e0-5dadb1daa205"
                    ]
                },
                "SU-s3.1": {
                    "questionId": "SU-s3.1",
                    "chosenOptionIds": [
                        "95ba4a6b-7026-5c10-98e9-07370c2b605a"
                    ]
                }
            }
        },
        {
            "name": "screened user: 50MRME6 aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10a": {
                    "questionId": "SU-10a",
                    "chosenOptionIds": [
                        "ed6c59f2-8713-56b1-a4b2-8f1f85b224ed"
                    ]
                },
                "SU-11a": {
                    "questionId": "SU-11a",
                    "chosenOptionIds": [
                        "57899734-07b2-50a2-a5d8-56142b5feb92"
                    ]
                },
                "SU-1a": {
                    "questionId": "SU-1a",
                    "chosenOptionIds": [
                        "22270598-ff5e-5785-a9a3-9bbed0fd9751"
                    ]
                },
                "SU-2a": {
                    "questionId": "SU-2a",
                    "chosenOptionIds": [
                        "6d761e88-087b-5887-9e08-7720347f7b48"
                    ]
                },
                "SU-3a": {
                    "questionId": "SU-3a",
                    "chosenOptionIds": [
                        "a3720a21-cf5f-5c57-9dde-8cb54605246f"
                    ]
                },
                "SU-4a": {
                    "questionId": "SU-4a",
                    "chosenOptionIds": [
                        "ede457f4-731b-56bd-b7b7-58d93ecdcacd"
                    ]
                },
                "SU-5a": {
                    "questionId": "SU-5a",
                    "chosenOptionIds": [
                        "7ec23bf3-d08e-58e1-a88a-9677b0b85ec8"
                    ]
                },
                "SU-6a": {
                    "questionId": "SU-6a",
                    "chosenOptionIds": [
                        "a16682f6-84c6-5bdd-9553-eafb92701f79"
                    ]
                },
                "SU-7a": {
                    "questionId": "SU-7a",
                    "chosenOptionIds": [
                        "698ebf04-9eee-5b77-97f3-dd84b14ac94a"
                    ]
                },
                "SU-8a": {
                    "questionId": "SU-8a",
                    "chosenOptionIds": [
                        "38ede561-e889-51b7-baa3-51217ea1552e"
                    ]
                },
                "SU-9a": {
                    "questionId": "SU-9a",
                    "chosenOptionIds": [
                        "ccb31960-a823-519d-a4a9-7348ba4ca6f5"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "3ed9bf02-1389-5b1a-81e0-5dadb1daa205"
                    ]
                },
                "SU-s3.1": {
                    "questionId": "SU-s3.1",
                    "chosenOptionIds": [
                        "28f722a5-8507-556b-abe2-8413d34679e4"
                    ]
                }
            }
        },
        {
            "name": "screened user: O3PMG3W aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10b": {
                    "questionId": "SU-10b",
                    "chosenOptionIds": [
                        "eb7acd72-f4fa-52a4-b53f-db24e7756bbf"
                    ]
                },
                "SU-11b": {
                    "questionId": "SU-11b",
                    "chosenOptionIds": [
                        "df81db2f-9fe7-5b09-be87-64780dd81a25"
                    ]
                },
                "SU-12b": {
                    "questionId": "SU-12b",
                    "chosenOptionIds": [
                        "7debcb22-6637-5de5-9421-89be645c3b96"
                    ]
                },
                "SU-1b": {
                    "questionId": "SU-1b",
                    "freeValue": 5475
                },
                "SU-2b": {
                    "questionId": "SU-2b",
                    "chosenOptionIds": [
                        "28fd19f5-e24d-5aa5-ab06-4e63101e3ef7"
                    ]
                },
                "SU-3b": {
                    "questionId": "SU-3b",
                    "chosenOptionIds": [
                        "97837883-2c07-58ae-87ab-970f4a8e16d7"
                    ]
                },
                "SU-4b": {
                    "questionId": "SU-4b",
                    "chosenOptionIds": [
                        "5af10404-91e9-55e5-a3a5-5b0525ebf15c"
                    ]
                },
                "SU-5b": {
                    "questionId": "SU-5b",
                    "chosenOptionIds": [
                        "6e890ffb-ea8c-50f4-87d8-0b9fcb05e77c"
                    ]
                },
                "SU-6b": {
                    "questionId": "SU-6b",
                    "chosenOptionIds": [
                        "e6d0d639-7af5-5de3-9355-51cd24b425c5"
                    ]
                },
                "SU-7b": {
                    "questionId": "SU-7b",
                    "chosenOptionIds": [
                        "fb9594cf-71a8-580c-80d0-18dd9696d5bc"
                    ]
                },
                "SU-8b": {
                    "questionId": "SU-8b",
                    "chosenOptionIds": [
                        "32ec1d91-b47c-5754-a656-c6c0f230ace2"
                    ]
                },
                "SU-9b": {
                    "questionId": "SU-9b",
                    "chosenOptionIds": [
                        "70dd7443-067d-5db4-bbfd-32f5c623fe8a"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "4cf86ba0-867d-5f6d-b8d1-3aa5ceddc374",
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "67d6ef3c-b5a8-5230-b556-3bc42e2e5179"
                    ]
                }
            }
        },
        {
            "name": "screened user: Y32D838 aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: XE7OXEL aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10b": {
                    "questionId": "SU-10b",
                    "chosenOptionIds": [
                        "fe352b2c-eff2-5046-8413-d86aa9192485"
                    ]
                },
                "SU-11b": {
                    "questionId": "SU-11b",
                    "chosenOptionIds": [
                        "1c65112c-5105-5069-a400-b16d985d1bdf"
                    ]
                },
                "SU-12b": {
                    "questionId": "SU-12b",
                    "chosenOptionIds": [
                        "cc068b90-c986-5049-9322-7b8b0fad4143"
                    ]
                },
                "SU-1b": {
                    "questionId": "SU-1b",
                    "freeValue": 730
                },
                "SU-2b": {
                    "questionId": "SU-2b",
                    "chosenOptionIds": [
                        "f6b6411b-02e6-531d-b2ed-67d6dfacfd96"
                    ]
                },
                "SU-3b": {
                    "questionId": "SU-3b",
                    "chosenOptionIds": [
                        "48eb47f4-b5d0-5ca4-ba6a-5f811f32d488"
                    ]
                },
                "SU-4b": {
                    "questionId": "SU-4b",
                    "chosenOptionIds": [
                        "a55e2d73-6bb1-5ddf-b7f0-be44aa25ea75"
                    ]
                },
                "SU-5b": {
                    "questionId": "SU-5b",
                    "chosenOptionIds": [
                        "a6d3f751-7a06-5eb4-97b5-70831bdb0e3d"
                    ]
                },
                "SU-6b": {
                    "questionId": "SU-6b",
                    "chosenOptionIds": [
                        "e6d0d639-7af5-5de3-9355-51cd24b425c5"
                    ]
                },
                "SU-7b": {
                    "questionId": "SU-7b",
                    "chosenOptionIds": [
                        "fb9594cf-71a8-580c-80d0-18dd9696d5bc"
                    ]
                },
                "SU-8b": {
                    "questionId": "SU-8b",
                    "chosenOptionIds": [
                        "a7206832-dc04-531b-bb17-18b8132ec246"
                    ]
                },
                "SU-9b": {
                    "questionId": "SU-9b",
                    "chosenOptionIds": [
                        "70dd7443-067d-5db4-bbfd-32f5c623fe8a"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f0bf8997-4579-5872-82e1-1400b895417b",
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "67d6ef3c-b5a8-5230-b556-3bc42e2e5179"
                    ]
                }
            }
        },
        {
            "name": "screened user: 63YX60R aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: 10QV8EP aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: 6EDLX3M aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10b": {
                    "questionId": "SU-10b",
                    "chosenOptionIds": [
                        "fe352b2c-eff2-5046-8413-d86aa9192485"
                    ]
                },
                "SU-11b": {
                    "questionId": "SU-11b",
                    "chosenOptionIds": [
                        "1c65112c-5105-5069-a400-b16d985d1bdf"
                    ]
                },
                "SU-12b": {
                    "questionId": "SU-12b",
                    "chosenOptionIds": [
                        "cc068b90-c986-5049-9322-7b8b0fad4143"
                    ]
                },
                "SU-1b": {
                    "questionId": "SU-1b",
                    "freeValue": 2920
                },
                "SU-2b": {
                    "questionId": "SU-2b",
                    "chosenOptionIds": [
                        "28fd19f5-e24d-5aa5-ab06-4e63101e3ef7"
                    ]
                },
                "SU-3b": {
                    "questionId": "SU-3b",
                    "chosenOptionIds": [
                        "48eb47f4-b5d0-5ca4-ba6a-5f811f32d488"
                    ]
                },
                "SU-4b": {
                    "questionId": "SU-4b",
                    "chosenOptionIds": [
                        "5af10404-91e9-55e5-a3a5-5b0525ebf15c"
                    ]
                },
                "SU-5b": {
                    "questionId": "SU-5b",
                    "chosenOptionIds": [
                        "a6d3f751-7a06-5eb4-97b5-70831bdb0e3d"
                    ]
                },
                "SU-6b": {
                    "questionId": "SU-6b",
                    "chosenOptionIds": [
                        "e6d0d639-7af5-5de3-9355-51cd24b425c5"
                    ]
                },
                "SU-7b": {
                    "questionId": "SU-7b",
                    "chosenOptionIds": [
                        "fb9594cf-71a8-580c-80d0-18dd9696d5bc"
                    ]
                },
                "SU-8b": {
                    "questionId": "SU-8b",
                    "chosenOptionIds": [
                        "a7206832-dc04-531b-bb17-18b8132ec246"
                    ]
                },
                "SU-9b": {
                    "questionId": "SU-9b",
                    "chosenOptionIds": [
                        "70dd7443-067d-5db4-bbfd-32f5c623fe8a"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "67d6ef3c-b5a8-5230-b556-3bc42e2e5179"
                    ]
                }
            }
        },
        {
            "name": "screened user: O0KG5E1 aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: 6ED9M0M aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10a": {
                    "questionId": "SU-10a",
                    "chosenOptionIds": [
                        "ed6c59f2-8713-56b1-a4b2-8f1f85b224ed"
                    ]
                },
                "SU-11a": {
                    "questionId": "SU-11a",
                    "chosenOptionIds": [
                        "57899734-07b2-50a2-a5d8-56142b5feb92"
                    ]
                },
                "SU-1a": {
                    "questionId": "SU-1a",
                    "chosenOptionIds": [
                        "64b85f3c-84e5-5d37-bc42-dab6740c8199"
                    ]
                },
                "SU-2a": {
                    "questionId": "SU-2a",
                    "chosenOptionIds": [
                        "6d761e88-087b-5887-9e08-7720347f7b48"
                    ]
                },
                "SU-3a": {
                    "questionId": "SU-3a",
                    "chosenOptionIds": [
                        "cba96b30-e2c3-5d5b-80a8-c18a79809f16"
                    ]
                },
                "SU-4a": {
                    "questionId": "SU-4a",
                    "chosenOptionIds": [
                        "ede457f4-731b-56bd-b7b7-58d93ecdcacd"
                    ]
                },
                "SU-5a": {
                    "questionId": "SU-5a",
                    "chosenOptionIds": [
                        "7ec23bf3-d08e-58e1-a88a-9677b0b85ec8"
                    ]
                },
                "SU-6a": {
                    "questionId": "SU-6a",
                    "chosenOptionIds": [
                        "a16682f6-84c6-5bdd-9553-eafb92701f79"
                    ]
                },
                "SU-7a": {
                    "questionId": "SU-7a",
                    "chosenOptionIds": [
                        "698ebf04-9eee-5b77-97f3-dd84b14ac94a"
                    ]
                },
                "SU-8a": {
                    "questionId": "SU-8a",
                    "chosenOptionIds": [
                        "38ede561-e889-51b7-baa3-51217ea1552e"
                    ]
                },
                "SU-9a": {
                    "questionId": "SU-9a",
                    "chosenOptionIds": [
                        "ccb31960-a823-519d-a4a9-7348ba4ca6f5"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280",
                        "4f2c6cea-7f4c-55ca-b952-334af926e7b1",
                        "18e8ad9b-e9a7-5311-8a6f-e9e64c5997fe",
                        "f0bf8997-4579-5872-82e1-1400b895417b"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "3ed9bf02-1389-5b1a-81e0-5dadb1daa205"
                    ]
                },
                "SU-s3.1": {
                    "questionId": "SU-s3.1",
                    "chosenOptionIds": [
                        "28f722a5-8507-556b-abe2-8413d34679e4"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z3R2NEO aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: NELRPEV aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: 73ZQN3R aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: REO9232 aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10b": {
                    "questionId": "SU-10b",
                    "chosenOptionIds": [
                        "fe352b2c-eff2-5046-8413-d86aa9192485"
                    ]
                },
                "SU-11b": {
                    "questionId": "SU-11b",
                    "chosenOptionIds": [
                        "1c65112c-5105-5069-a400-b16d985d1bdf"
                    ]
                },
                "SU-12b": {
                    "questionId": "SU-12b",
                    "chosenOptionIds": [
                        "7debcb22-6637-5de5-9421-89be645c3b96"
                    ]
                },
                "SU-1b": {
                    "questionId": "SU-1b",
                    "freeValue": 365
                },
                "SU-2b": {
                    "questionId": "SU-2b",
                    "chosenOptionIds": [
                        "69f7eb47-cf61-5bc3-b485-494de2684114"
                    ]
                },
                "SU-3b": {
                    "questionId": "SU-3b",
                    "chosenOptionIds": [
                        "48eb47f4-b5d0-5ca4-ba6a-5f811f32d488"
                    ]
                },
                "SU-4b": {
                    "questionId": "SU-4b",
                    "chosenOptionIds": [
                        "5af10404-91e9-55e5-a3a5-5b0525ebf15c"
                    ]
                },
                "SU-5b": {
                    "questionId": "SU-5b",
                    "chosenOptionIds": [
                        "bcac9437-7015-5fe5-9ee4-fb99cf0d5f17"
                    ]
                },
                "SU-6b": {
                    "questionId": "SU-6b",
                    "chosenOptionIds": [
                        "e6d0d639-7af5-5de3-9355-51cd24b425c5"
                    ]
                },
                "SU-7b": {
                    "questionId": "SU-7b",
                    "chosenOptionIds": [
                        "fb9594cf-71a8-580c-80d0-18dd9696d5bc"
                    ]
                },
                "SU-8b": {
                    "questionId": "SU-8b",
                    "chosenOptionIds": [
                        "a7206832-dc04-531b-bb17-18b8132ec246"
                    ]
                },
                "SU-9b": {
                    "questionId": "SU-9b",
                    "chosenOptionIds": [
                        "70dd7443-067d-5db4-bbfd-32f5c623fe8a"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f0bf8997-4579-5872-82e1-1400b895417b",
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "67d6ef3c-b5a8-5230-b556-3bc42e2e5179"
                    ]
                }
            }
        },
        {
            "name": "screened user: O0KZ701 aspect: sbs_ce should be positive",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "SU-10a": {
                    "questionId": "SU-10a",
                    "chosenOptionIds": [
                        "ed6c59f2-8713-56b1-a4b2-8f1f85b224ed"
                    ]
                },
                "SU-11a": {
                    "questionId": "SU-11a",
                    "chosenOptionIds": [
                        "57899734-07b2-50a2-a5d8-56142b5feb92"
                    ]
                },
                "SU-1a": {
                    "questionId": "SU-1a",
                    "chosenOptionIds": [
                        "dc9dd01a-e6c0-58c8-9b8f-b3315d6a689c"
                    ]
                },
                "SU-2a": {
                    "questionId": "SU-2a",
                    "chosenOptionIds": [
                        "6d761e88-087b-5887-9e08-7720347f7b48"
                    ]
                },
                "SU-3a": {
                    "questionId": "SU-3a",
                    "chosenOptionIds": [
                        "a3720a21-cf5f-5c57-9dde-8cb54605246f"
                    ]
                },
                "SU-4a": {
                    "questionId": "SU-4a",
                    "chosenOptionIds": [
                        "ede457f4-731b-56bd-b7b7-58d93ecdcacd"
                    ]
                },
                "SU-5a": {
                    "questionId": "SU-5a",
                    "chosenOptionIds": [
                        "7ec23bf3-d08e-58e1-a88a-9677b0b85ec8"
                    ]
                },
                "SU-6a": {
                    "questionId": "SU-6a",
                    "chosenOptionIds": [
                        "a16682f6-84c6-5bdd-9553-eafb92701f79"
                    ]
                },
                "SU-7a": {
                    "questionId": "SU-7a",
                    "chosenOptionIds": [
                        "85e98c24-bf79-526d-8945-070f551c81c7"
                    ]
                },
                "SU-8a": {
                    "questionId": "SU-8a",
                    "chosenOptionIds": [
                        "4ade59f6-9242-595c-9d22-1c907b3dcbd6"
                    ]
                },
                "SU-9a": {
                    "questionId": "SU-9a",
                    "chosenOptionIds": [
                        "ccb31960-a823-519d-a4a9-7348ba4ca6f5"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "3ed9bf02-1389-5b1a-81e0-5dadb1daa205"
                    ]
                },
                "SU-s3.1": {
                    "questionId": "SU-s3.1",
                    "chosenOptionIds": [
                        "28f722a5-8507-556b-abe2-8413d34679e4"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0N6P0Q aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: 2E148ED aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10b": {
                    "questionId": "SU-10b",
                    "chosenOptionIds": [
                        "fe352b2c-eff2-5046-8413-d86aa9192485"
                    ]
                },
                "SU-11b": {
                    "questionId": "SU-11b",
                    "chosenOptionIds": [
                        "1c65112c-5105-5069-a400-b16d985d1bdf"
                    ]
                },
                "SU-12b": {
                    "questionId": "SU-12b",
                    "chosenOptionIds": [
                        "cc068b90-c986-5049-9322-7b8b0fad4143"
                    ]
                },
                "SU-1b": {
                    "questionId": "SU-1b",
                    "freeValue": 1825
                },
                "SU-2b": {
                    "questionId": "SU-2b",
                    "chosenOptionIds": [
                        "f6b6411b-02e6-531d-b2ed-67d6dfacfd96"
                    ]
                },
                "SU-3b": {
                    "questionId": "SU-3b",
                    "chosenOptionIds": [
                        "48eb47f4-b5d0-5ca4-ba6a-5f811f32d488"
                    ]
                },
                "SU-4b": {
                    "questionId": "SU-4b",
                    "chosenOptionIds": [
                        "a55e2d73-6bb1-5ddf-b7f0-be44aa25ea75"
                    ]
                },
                "SU-5b": {
                    "questionId": "SU-5b",
                    "chosenOptionIds": [
                        "a6d3f751-7a06-5eb4-97b5-70831bdb0e3d"
                    ]
                },
                "SU-6b": {
                    "questionId": "SU-6b",
                    "chosenOptionIds": [
                        "e6d0d639-7af5-5de3-9355-51cd24b425c5"
                    ]
                },
                "SU-7b": {
                    "questionId": "SU-7b",
                    "chosenOptionIds": [
                        "fb9594cf-71a8-580c-80d0-18dd9696d5bc"
                    ]
                },
                "SU-8b": {
                    "questionId": "SU-8b",
                    "chosenOptionIds": [
                        "a7206832-dc04-531b-bb17-18b8132ec246"
                    ]
                },
                "SU-9b": {
                    "questionId": "SU-9b",
                    "chosenOptionIds": [
                        "70dd7443-067d-5db4-bbfd-32f5c623fe8a"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "18e8ad9b-e9a7-5311-8a6f-e9e64c5997fe"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "67d6ef3c-b5a8-5230-b556-3bc42e2e5179"
                    ]
                }
            }
        },
        {
            "name": "screened user: ME8G2EW aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10a": {
                    "questionId": "SU-10a",
                    "chosenOptionIds": [
                        "ed6c59f2-8713-56b1-a4b2-8f1f85b224ed"
                    ]
                },
                "SU-11a": {
                    "questionId": "SU-11a",
                    "chosenOptionIds": [
                        "57899734-07b2-50a2-a5d8-56142b5feb92"
                    ]
                },
                "SU-1a": {
                    "questionId": "SU-1a",
                    "chosenOptionIds": [
                        "dc9dd01a-e6c0-58c8-9b8f-b3315d6a689c"
                    ]
                },
                "SU-2a": {
                    "questionId": "SU-2a",
                    "chosenOptionIds": [
                        "6d761e88-087b-5887-9e08-7720347f7b48"
                    ]
                },
                "SU-3a": {
                    "questionId": "SU-3a",
                    "chosenOptionIds": [
                        "cba96b30-e2c3-5d5b-80a8-c18a79809f16"
                    ]
                },
                "SU-4a": {
                    "questionId": "SU-4a",
                    "chosenOptionIds": [
                        "ede457f4-731b-56bd-b7b7-58d93ecdcacd"
                    ]
                },
                "SU-5a": {
                    "questionId": "SU-5a",
                    "chosenOptionIds": [
                        "7ec23bf3-d08e-58e1-a88a-9677b0b85ec8"
                    ]
                },
                "SU-6a": {
                    "questionId": "SU-6a",
                    "chosenOptionIds": [
                        "a16682f6-84c6-5bdd-9553-eafb92701f79"
                    ]
                },
                "SU-7a": {
                    "questionId": "SU-7a",
                    "chosenOptionIds": [
                        "698ebf04-9eee-5b77-97f3-dd84b14ac94a"
                    ]
                },
                "SU-8a": {
                    "questionId": "SU-8a",
                    "chosenOptionIds": [
                        "38ede561-e889-51b7-baa3-51217ea1552e"
                    ]
                },
                "SU-9a": {
                    "questionId": "SU-9a",
                    "chosenOptionIds": [
                        "ccb31960-a823-519d-a4a9-7348ba4ca6f5"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "084e1561-5048-57b0-8796-986f65764e19"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "3ed9bf02-1389-5b1a-81e0-5dadb1daa205"
                    ]
                },
                "SU-s3.1": {
                    "questionId": "SU-s3.1",
                    "chosenOptionIds": [
                        "3a4d2bec-b4ca-56e7-9044-99ca4db70f63"
                    ]
                }
            }
        },
        {
            "name": "screened user: PE6LM0L aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: K39ML0L aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: XE7VX0L aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z3RD4EO aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10b": {
                    "questionId": "SU-10b",
                    "chosenOptionIds": [
                        "eb7acd72-f4fa-52a4-b53f-db24e7756bbf"
                    ]
                },
                "SU-11b": {
                    "questionId": "SU-11b",
                    "chosenOptionIds": [
                        "df81db2f-9fe7-5b09-be87-64780dd81a25"
                    ]
                },
                "SU-12b": {
                    "questionId": "SU-12b",
                    "chosenOptionIds": [
                        "7debcb22-6637-5de5-9421-89be645c3b96"
                    ]
                },
                "SU-1b": {
                    "questionId": "SU-1b",
                    "freeValue": 2920
                },
                "SU-2b": {
                    "questionId": "SU-2b",
                    "chosenOptionIds": [
                        "4678ad6e-17f5-504f-84d5-d7e4bd032e1f"
                    ]
                },
                "SU-3b": {
                    "questionId": "SU-3b",
                    "chosenOptionIds": [
                        "97837883-2c07-58ae-87ab-970f4a8e16d7"
                    ]
                },
                "SU-4b": {
                    "questionId": "SU-4b",
                    "chosenOptionIds": [
                        "a55e2d73-6bb1-5ddf-b7f0-be44aa25ea75"
                    ]
                },
                "SU-5b": {
                    "questionId": "SU-5b",
                    "chosenOptionIds": [
                        "a6d3f751-7a06-5eb4-97b5-70831bdb0e3d"
                    ]
                },
                "SU-6b": {
                    "questionId": "SU-6b",
                    "chosenOptionIds": [
                        "e6d0d639-7af5-5de3-9355-51cd24b425c5"
                    ]
                },
                "SU-7b": {
                    "questionId": "SU-7b",
                    "chosenOptionIds": [
                        "fb9594cf-71a8-580c-80d0-18dd9696d5bc"
                    ]
                },
                "SU-8b": {
                    "questionId": "SU-8b",
                    "chosenOptionIds": [
                        "32ec1d91-b47c-5754-a656-c6c0f230ace2"
                    ]
                },
                "SU-9b": {
                    "questionId": "SU-9b",
                    "chosenOptionIds": [
                        "485e4f43-7787-5f16-8ff0-e578b0ff4b83"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f0bf8997-4579-5872-82e1-1400b895417b",
                        "4cf86ba0-867d-5f6d-b8d1-3aa5ceddc374",
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280",
                        "36c77f4a-59a6-588e-8346-b190d1491a17"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "67d6ef3c-b5a8-5230-b556-3bc42e2e5179"
                    ]
                }
            }
        },
        {
            "name": "screened user: 4EGQQ0P aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: 7EW2K38 aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: 63YR40R aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10a": {
                    "questionId": "SU-10a",
                    "chosenOptionIds": [
                        "ed6c59f2-8713-56b1-a4b2-8f1f85b224ed"
                    ]
                },
                "SU-11a": {
                    "questionId": "SU-11a",
                    "chosenOptionIds": [
                        "57899734-07b2-50a2-a5d8-56142b5feb92"
                    ]
                },
                "SU-1a": {
                    "questionId": "SU-1a",
                    "chosenOptionIds": [
                        "64b85f3c-84e5-5d37-bc42-dab6740c8199"
                    ]
                },
                "SU-2a": {
                    "questionId": "SU-2a",
                    "chosenOptionIds": [
                        "6d761e88-087b-5887-9e08-7720347f7b48"
                    ]
                },
                "SU-3a": {
                    "questionId": "SU-3a",
                    "chosenOptionIds": [
                        "a3720a21-cf5f-5c57-9dde-8cb54605246f"
                    ]
                },
                "SU-4a": {
                    "questionId": "SU-4a",
                    "chosenOptionIds": [
                        "dd97a5ec-89cc-5ee7-b89b-644d9f1a2b7b"
                    ]
                },
                "SU-5a": {
                    "questionId": "SU-5a",
                    "chosenOptionIds": [
                        "7ec23bf3-d08e-58e1-a88a-9677b0b85ec8"
                    ]
                },
                "SU-6a": {
                    "questionId": "SU-6a",
                    "chosenOptionIds": [
                        "a16682f6-84c6-5bdd-9553-eafb92701f79"
                    ]
                },
                "SU-7a": {
                    "questionId": "SU-7a",
                    "chosenOptionIds": [
                        "698ebf04-9eee-5b77-97f3-dd84b14ac94a"
                    ]
                },
                "SU-8a": {
                    "questionId": "SU-8a",
                    "chosenOptionIds": [
                        "38ede561-e889-51b7-baa3-51217ea1552e"
                    ]
                },
                "SU-9a": {
                    "questionId": "SU-9a",
                    "chosenOptionIds": [
                        "ccb31960-a823-519d-a4a9-7348ba4ca6f5"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f0bf8997-4579-5872-82e1-1400b895417b",
                        "2075c9f4-b5e7-5615-b714-0ab3a1b5d046",
                        "18e8ad9b-e9a7-5311-8a6f-e9e64c5997fe",
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "3ed9bf02-1389-5b1a-81e0-5dadb1daa205"
                    ]
                },
                "SU-s3.1": {
                    "questionId": "SU-s3.1",
                    "chosenOptionIds": [
                        "0874416b-3740-5988-91da-b5ef5aad981f",
                        "28f722a5-8507-556b-abe2-8413d34679e4"
                    ]
                }
            }
        },
        {
            "name": "screened user: Y329ME8 aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10b": {
                    "questionId": "SU-10b",
                    "chosenOptionIds": [
                        "fe352b2c-eff2-5046-8413-d86aa9192485"
                    ]
                },
                "SU-11b": {
                    "questionId": "SU-11b",
                    "chosenOptionIds": [
                        "1c65112c-5105-5069-a400-b16d985d1bdf"
                    ]
                },
                "SU-12b": {
                    "questionId": "SU-12b",
                    "chosenOptionIds": [
                        "cc068b90-c986-5049-9322-7b8b0fad4143"
                    ]
                },
                "SU-1b": {
                    "questionId": "SU-1b",
                    "freeValue": 730
                },
                "SU-2b": {
                    "questionId": "SU-2b",
                    "chosenOptionIds": [
                        "f6b6411b-02e6-531d-b2ed-67d6dfacfd96"
                    ]
                },
                "SU-3b": {
                    "questionId": "SU-3b",
                    "chosenOptionIds": [
                        "48eb47f4-b5d0-5ca4-ba6a-5f811f32d488"
                    ]
                },
                "SU-4b": {
                    "questionId": "SU-4b",
                    "chosenOptionIds": [
                        "a55e2d73-6bb1-5ddf-b7f0-be44aa25ea75"
                    ]
                },
                "SU-5b": {
                    "questionId": "SU-5b",
                    "chosenOptionIds": [
                        "a6d3f751-7a06-5eb4-97b5-70831bdb0e3d"
                    ]
                },
                "SU-6b": {
                    "questionId": "SU-6b",
                    "chosenOptionIds": [
                        "e6d0d639-7af5-5de3-9355-51cd24b425c5"
                    ]
                },
                "SU-7b": {
                    "questionId": "SU-7b",
                    "chosenOptionIds": [
                        "fb9594cf-71a8-580c-80d0-18dd9696d5bc"
                    ]
                },
                "SU-8b": {
                    "questionId": "SU-8b",
                    "chosenOptionIds": [
                        "a7206832-dc04-531b-bb17-18b8132ec246"
                    ]
                },
                "SU-9b": {
                    "questionId": "SU-9b",
                    "chosenOptionIds": [
                        "70dd7443-067d-5db4-bbfd-32f5c623fe8a"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "67d6ef3c-b5a8-5230-b556-3bc42e2e5179"
                    ]
                }
            }
        },
        {
            "name": "screened user: J0VZ63L aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: 73ZJN0R aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: R042O34 aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: J0VRYEL aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: 73ZNN3R aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: ME8D2EW aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0NN50Q aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: 73Z220R aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10b": {
                    "questionId": "SU-10b",
                    "chosenOptionIds": [
                        "fe352b2c-eff2-5046-8413-d86aa9192485"
                    ]
                },
                "SU-11b": {
                    "questionId": "SU-11b",
                    "chosenOptionIds": [
                        "1c65112c-5105-5069-a400-b16d985d1bdf"
                    ]
                },
                "SU-12b": {
                    "questionId": "SU-12b",
                    "chosenOptionIds": [
                        "cc068b90-c986-5049-9322-7b8b0fad4143"
                    ]
                },
                "SU-1b": {
                    "questionId": "SU-1b",
                    "freeValue": 4380
                },
                "SU-2b": {
                    "questionId": "SU-2b",
                    "chosenOptionIds": [
                        "f6b6411b-02e6-531d-b2ed-67d6dfacfd96"
                    ]
                },
                "SU-3b": {
                    "questionId": "SU-3b",
                    "chosenOptionIds": [
                        "48eb47f4-b5d0-5ca4-ba6a-5f811f32d488"
                    ]
                },
                "SU-4b": {
                    "questionId": "SU-4b",
                    "chosenOptionIds": [
                        "5af10404-91e9-55e5-a3a5-5b0525ebf15c"
                    ]
                },
                "SU-5b": {
                    "questionId": "SU-5b",
                    "chosenOptionIds": [
                        "a6d3f751-7a06-5eb4-97b5-70831bdb0e3d"
                    ]
                },
                "SU-6b": {
                    "questionId": "SU-6b",
                    "chosenOptionIds": [
                        "e6d0d639-7af5-5de3-9355-51cd24b425c5"
                    ]
                },
                "SU-7b": {
                    "questionId": "SU-7b",
                    "chosenOptionIds": [
                        "fb9594cf-71a8-580c-80d0-18dd9696d5bc"
                    ]
                },
                "SU-8b": {
                    "questionId": "SU-8b",
                    "chosenOptionIds": [
                        "a7206832-dc04-531b-bb17-18b8132ec246"
                    ]
                },
                "SU-9b": {
                    "questionId": "SU-9b",
                    "chosenOptionIds": [
                        "70dd7443-067d-5db4-bbfd-32f5c623fe8a"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "67d6ef3c-b5a8-5230-b556-3bc42e2e5179"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0N4J3Q aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10b": {
                    "questionId": "SU-10b",
                    "chosenOptionIds": [
                        "fe352b2c-eff2-5046-8413-d86aa9192485"
                    ]
                },
                "SU-11b": {
                    "questionId": "SU-11b",
                    "chosenOptionIds": [
                        "1c65112c-5105-5069-a400-b16d985d1bdf"
                    ]
                },
                "SU-12b": {
                    "questionId": "SU-12b",
                    "chosenOptionIds": [
                        "cc068b90-c986-5049-9322-7b8b0fad4143"
                    ]
                },
                "SU-1b": {
                    "questionId": "SU-1b",
                    "freeValue": 3650
                },
                "SU-2b": {
                    "questionId": "SU-2b",
                    "chosenOptionIds": [
                        "f6b6411b-02e6-531d-b2ed-67d6dfacfd96"
                    ]
                },
                "SU-3b": {
                    "questionId": "SU-3b",
                    "chosenOptionIds": [
                        "48eb47f4-b5d0-5ca4-ba6a-5f811f32d488"
                    ]
                },
                "SU-4b": {
                    "questionId": "SU-4b",
                    "chosenOptionIds": [
                        "5af10404-91e9-55e5-a3a5-5b0525ebf15c"
                    ]
                },
                "SU-5b": {
                    "questionId": "SU-5b",
                    "chosenOptionIds": [
                        "a6d3f751-7a06-5eb4-97b5-70831bdb0e3d"
                    ]
                },
                "SU-6b": {
                    "questionId": "SU-6b",
                    "chosenOptionIds": [
                        "e6d0d639-7af5-5de3-9355-51cd24b425c5"
                    ]
                },
                "SU-7b": {
                    "questionId": "SU-7b",
                    "chosenOptionIds": [
                        "fb9594cf-71a8-580c-80d0-18dd9696d5bc"
                    ]
                },
                "SU-8b": {
                    "questionId": "SU-8b",
                    "chosenOptionIds": [
                        "a7206832-dc04-531b-bb17-18b8132ec246"
                    ]
                },
                "SU-9b": {
                    "questionId": "SU-9b",
                    "chosenOptionIds": [
                        "70dd7443-067d-5db4-bbfd-32f5c623fe8a"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "67d6ef3c-b5a8-5230-b556-3bc42e2e5179"
                    ]
                }
            }
        },
        {
            "name": "screened user: 10QKZ0P aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: XE7ZR3L aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10a": {
                    "questionId": "SU-10a",
                    "chosenOptionIds": [
                        "ed6c59f2-8713-56b1-a4b2-8f1f85b224ed"
                    ]
                },
                "SU-11a": {
                    "questionId": "SU-11a",
                    "chosenOptionIds": [
                        "57899734-07b2-50a2-a5d8-56142b5feb92"
                    ]
                },
                "SU-1a": {
                    "questionId": "SU-1a",
                    "chosenOptionIds": [
                        "dc9dd01a-e6c0-58c8-9b8f-b3315d6a689c"
                    ]
                },
                "SU-2a": {
                    "questionId": "SU-2a",
                    "chosenOptionIds": [
                        "6d761e88-087b-5887-9e08-7720347f7b48"
                    ]
                },
                "SU-3a": {
                    "questionId": "SU-3a",
                    "chosenOptionIds": [
                        "cba96b30-e2c3-5d5b-80a8-c18a79809f16"
                    ]
                },
                "SU-4a": {
                    "questionId": "SU-4a",
                    "chosenOptionIds": [
                        "ede457f4-731b-56bd-b7b7-58d93ecdcacd"
                    ]
                },
                "SU-5a": {
                    "questionId": "SU-5a",
                    "chosenOptionIds": [
                        "7ec23bf3-d08e-58e1-a88a-9677b0b85ec8"
                    ]
                },
                "SU-6a": {
                    "questionId": "SU-6a",
                    "chosenOptionIds": [
                        "a16682f6-84c6-5bdd-9553-eafb92701f79"
                    ]
                },
                "SU-7a": {
                    "questionId": "SU-7a",
                    "chosenOptionIds": [
                        "698ebf04-9eee-5b77-97f3-dd84b14ac94a"
                    ]
                },
                "SU-8a": {
                    "questionId": "SU-8a",
                    "chosenOptionIds": [
                        "38ede561-e889-51b7-baa3-51217ea1552e"
                    ]
                },
                "SU-9a": {
                    "questionId": "SU-9a",
                    "chosenOptionIds": [
                        "ccb31960-a823-519d-a4a9-7348ba4ca6f5"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "18e8ad9b-e9a7-5311-8a6f-e9e64c5997fe",
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280",
                        "36c77f4a-59a6-588e-8346-b190d1491a17",
                        "f0bf8997-4579-5872-82e1-1400b895417b"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "3ed9bf02-1389-5b1a-81e0-5dadb1daa205"
                    ]
                },
                "SU-s3.1": {
                    "questionId": "SU-s3.1",
                    "chosenOptionIds": [
                        "28f722a5-8507-556b-abe2-8413d34679e4"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0N5PEQ aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10b": {
                    "questionId": "SU-10b",
                    "chosenOptionIds": [
                        "fe352b2c-eff2-5046-8413-d86aa9192485"
                    ]
                },
                "SU-11b": {
                    "questionId": "SU-11b",
                    "chosenOptionIds": [
                        "1c65112c-5105-5069-a400-b16d985d1bdf"
                    ]
                },
                "SU-12b": {
                    "questionId": "SU-12b",
                    "chosenOptionIds": [
                        "cc068b90-c986-5049-9322-7b8b0fad4143"
                    ]
                },
                "SU-1b": {
                    "questionId": "SU-1b",
                    "freeValue": 1825
                },
                "SU-2b": {
                    "questionId": "SU-2b",
                    "chosenOptionIds": [
                        "4678ad6e-17f5-504f-84d5-d7e4bd032e1f"
                    ]
                },
                "SU-3b": {
                    "questionId": "SU-3b",
                    "chosenOptionIds": [
                        "48eb47f4-b5d0-5ca4-ba6a-5f811f32d488"
                    ]
                },
                "SU-4b": {
                    "questionId": "SU-4b",
                    "chosenOptionIds": [
                        "5af10404-91e9-55e5-a3a5-5b0525ebf15c"
                    ]
                },
                "SU-5b": {
                    "questionId": "SU-5b",
                    "chosenOptionIds": [
                        "a6d3f751-7a06-5eb4-97b5-70831bdb0e3d"
                    ]
                },
                "SU-6b": {
                    "questionId": "SU-6b",
                    "chosenOptionIds": [
                        "e6d0d639-7af5-5de3-9355-51cd24b425c5"
                    ]
                },
                "SU-7b": {
                    "questionId": "SU-7b",
                    "chosenOptionIds": [
                        "fb9594cf-71a8-580c-80d0-18dd9696d5bc"
                    ]
                },
                "SU-8b": {
                    "questionId": "SU-8b",
                    "chosenOptionIds": [
                        "a7206832-dc04-531b-bb17-18b8132ec246"
                    ]
                },
                "SU-9b": {
                    "questionId": "SU-9b",
                    "chosenOptionIds": [
                        "70dd7443-067d-5db4-bbfd-32f5c623fe8a"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "67d6ef3c-b5a8-5230-b556-3bc42e2e5179"
                    ]
                }
            }
        },
        {
            "name": "screened user: 23XKV0N aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10a": {
                    "questionId": "SU-10a",
                    "chosenOptionIds": [
                        "ed6c59f2-8713-56b1-a4b2-8f1f85b224ed"
                    ]
                },
                "SU-11a": {
                    "questionId": "SU-11a",
                    "chosenOptionIds": [
                        "57899734-07b2-50a2-a5d8-56142b5feb92"
                    ]
                },
                "SU-1a": {
                    "questionId": "SU-1a",
                    "chosenOptionIds": [
                        "22270598-ff5e-5785-a9a3-9bbed0fd9751"
                    ]
                },
                "SU-2a": {
                    "questionId": "SU-2a",
                    "chosenOptionIds": [
                        "6d761e88-087b-5887-9e08-7720347f7b48"
                    ]
                },
                "SU-3a": {
                    "questionId": "SU-3a",
                    "chosenOptionIds": [
                        "cba96b30-e2c3-5d5b-80a8-c18a79809f16"
                    ]
                },
                "SU-4a": {
                    "questionId": "SU-4a",
                    "chosenOptionIds": [
                        "ede457f4-731b-56bd-b7b7-58d93ecdcacd"
                    ]
                },
                "SU-5a": {
                    "questionId": "SU-5a",
                    "chosenOptionIds": [
                        "7ec23bf3-d08e-58e1-a88a-9677b0b85ec8"
                    ]
                },
                "SU-6a": {
                    "questionId": "SU-6a",
                    "chosenOptionIds": [
                        "a16682f6-84c6-5bdd-9553-eafb92701f79"
                    ]
                },
                "SU-7a": {
                    "questionId": "SU-7a",
                    "chosenOptionIds": [
                        "698ebf04-9eee-5b77-97f3-dd84b14ac94a"
                    ]
                },
                "SU-8a": {
                    "questionId": "SU-8a",
                    "chosenOptionIds": [
                        "38ede561-e889-51b7-baa3-51217ea1552e"
                    ]
                },
                "SU-9a": {
                    "questionId": "SU-9a",
                    "chosenOptionIds": [
                        "ccb31960-a823-519d-a4a9-7348ba4ca6f5"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f0bf8997-4579-5872-82e1-1400b895417b",
                        "4cf86ba0-867d-5f6d-b8d1-3aa5ceddc374",
                        "18e8ad9b-e9a7-5311-8a6f-e9e64c5997fe",
                        "4f2c6cea-7f4c-55ca-b952-334af926e7b1",
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "3ed9bf02-1389-5b1a-81e0-5dadb1daa205"
                    ]
                },
                "SU-s3.1": {
                    "questionId": "SU-s3.1",
                    "chosenOptionIds": [
                        "28f722a5-8507-556b-abe2-8413d34679e4"
                    ]
                }
            }
        },
        {
            "name": "screened user: 63YD63R aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10b": {
                    "questionId": "SU-10b",
                    "chosenOptionIds": [
                        "fe352b2c-eff2-5046-8413-d86aa9192485"
                    ]
                },
                "SU-11b": {
                    "questionId": "SU-11b",
                    "chosenOptionIds": [
                        "1c65112c-5105-5069-a400-b16d985d1bdf"
                    ]
                },
                "SU-12b": {
                    "questionId": "SU-12b",
                    "chosenOptionIds": [
                        "cc068b90-c986-5049-9322-7b8b0fad4143"
                    ]
                },
                "SU-1b": {
                    "questionId": "SU-1b",
                    "freeValue": 2920
                },
                "SU-2b": {
                    "questionId": "SU-2b",
                    "chosenOptionIds": [
                        "f6b6411b-02e6-531d-b2ed-67d6dfacfd96"
                    ]
                },
                "SU-3b": {
                    "questionId": "SU-3b",
                    "chosenOptionIds": [
                        "48eb47f4-b5d0-5ca4-ba6a-5f811f32d488"
                    ]
                },
                "SU-4b": {
                    "questionId": "SU-4b",
                    "chosenOptionIds": [
                        "5af10404-91e9-55e5-a3a5-5b0525ebf15c"
                    ]
                },
                "SU-5b": {
                    "questionId": "SU-5b",
                    "chosenOptionIds": [
                        "a6d3f751-7a06-5eb4-97b5-70831bdb0e3d"
                    ]
                },
                "SU-6b": {
                    "questionId": "SU-6b",
                    "chosenOptionIds": [
                        "e6d0d639-7af5-5de3-9355-51cd24b425c5"
                    ]
                },
                "SU-7b": {
                    "questionId": "SU-7b",
                    "chosenOptionIds": [
                        "fb9594cf-71a8-580c-80d0-18dd9696d5bc"
                    ]
                },
                "SU-8b": {
                    "questionId": "SU-8b",
                    "chosenOptionIds": [
                        "a7206832-dc04-531b-bb17-18b8132ec246"
                    ]
                },
                "SU-9b": {
                    "questionId": "SU-9b",
                    "chosenOptionIds": [
                        "70dd7443-067d-5db4-bbfd-32f5c623fe8a"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "67d6ef3c-b5a8-5230-b556-3bc42e2e5179"
                    ]
                }
            }
        },
        {
            "name": "screened user: ME8PLEW aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10a": {
                    "questionId": "SU-10a",
                    "chosenOptionIds": [
                        "ed6c59f2-8713-56b1-a4b2-8f1f85b224ed"
                    ]
                },
                "SU-11a": {
                    "questionId": "SU-11a",
                    "chosenOptionIds": [
                        "57899734-07b2-50a2-a5d8-56142b5feb92"
                    ]
                },
                "SU-1a": {
                    "questionId": "SU-1a",
                    "chosenOptionIds": [
                        "64b85f3c-84e5-5d37-bc42-dab6740c8199"
                    ]
                },
                "SU-2a": {
                    "questionId": "SU-2a",
                    "chosenOptionIds": [
                        "6d761e88-087b-5887-9e08-7720347f7b48"
                    ]
                },
                "SU-3a": {
                    "questionId": "SU-3a",
                    "chosenOptionIds": [
                        "a3720a21-cf5f-5c57-9dde-8cb54605246f"
                    ]
                },
                "SU-4a": {
                    "questionId": "SU-4a",
                    "chosenOptionIds": [
                        "ede457f4-731b-56bd-b7b7-58d93ecdcacd"
                    ]
                },
                "SU-5a": {
                    "questionId": "SU-5a",
                    "chosenOptionIds": [
                        "7ec23bf3-d08e-58e1-a88a-9677b0b85ec8"
                    ]
                },
                "SU-6a": {
                    "questionId": "SU-6a",
                    "chosenOptionIds": [
                        "a16682f6-84c6-5bdd-9553-eafb92701f79"
                    ]
                },
                "SU-7a": {
                    "questionId": "SU-7a",
                    "chosenOptionIds": [
                        "698ebf04-9eee-5b77-97f3-dd84b14ac94a"
                    ]
                },
                "SU-8a": {
                    "questionId": "SU-8a",
                    "chosenOptionIds": [
                        "38ede561-e889-51b7-baa3-51217ea1552e"
                    ]
                },
                "SU-9a": {
                    "questionId": "SU-9a",
                    "chosenOptionIds": [
                        "ccb31960-a823-519d-a4a9-7348ba4ca6f5"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "3ed9bf02-1389-5b1a-81e0-5dadb1daa205"
                    ]
                },
                "SU-s3.1": {
                    "questionId": "SU-s3.1",
                    "chosenOptionIds": [
                        "28f722a5-8507-556b-abe2-8413d34679e4"
                    ]
                }
            }
        },
        {
            "name": "screened user: K39V10L aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: O0K1931 aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: J3J1D0Y aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: 10QJ8EP aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: 73ZL2ER aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10a": {
                    "questionId": "SU-10a",
                    "chosenOptionIds": [
                        "ed6c59f2-8713-56b1-a4b2-8f1f85b224ed"
                    ]
                },
                "SU-11a": {
                    "questionId": "SU-11a",
                    "chosenOptionIds": [
                        "57899734-07b2-50a2-a5d8-56142b5feb92"
                    ]
                },
                "SU-1a": {
                    "questionId": "SU-1a",
                    "chosenOptionIds": [
                        "dc9dd01a-e6c0-58c8-9b8f-b3315d6a689c"
                    ]
                },
                "SU-2a": {
                    "questionId": "SU-2a",
                    "chosenOptionIds": [
                        "6d761e88-087b-5887-9e08-7720347f7b48"
                    ]
                },
                "SU-3a": {
                    "questionId": "SU-3a",
                    "chosenOptionIds": [
                        "a3720a21-cf5f-5c57-9dde-8cb54605246f"
                    ]
                },
                "SU-4a": {
                    "questionId": "SU-4a",
                    "chosenOptionIds": [
                        "ede457f4-731b-56bd-b7b7-58d93ecdcacd"
                    ]
                },
                "SU-5a": {
                    "questionId": "SU-5a",
                    "chosenOptionIds": [
                        "7ec23bf3-d08e-58e1-a88a-9677b0b85ec8"
                    ]
                },
                "SU-6a": {
                    "questionId": "SU-6a",
                    "chosenOptionIds": [
                        "a16682f6-84c6-5bdd-9553-eafb92701f79"
                    ]
                },
                "SU-7a": {
                    "questionId": "SU-7a",
                    "chosenOptionIds": [
                        "698ebf04-9eee-5b77-97f3-dd84b14ac94a"
                    ]
                },
                "SU-8a": {
                    "questionId": "SU-8a",
                    "chosenOptionIds": [
                        "38ede561-e889-51b7-baa3-51217ea1552e"
                    ]
                },
                "SU-9a": {
                    "questionId": "SU-9a",
                    "chosenOptionIds": [
                        "ccb31960-a823-519d-a4a9-7348ba4ca6f5"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "4cf86ba0-867d-5f6d-b8d1-3aa5ceddc374",
                        "18e8ad9b-e9a7-5311-8a6f-e9e64c5997fe",
                        "4f2c6cea-7f4c-55ca-b952-334af926e7b1",
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280",
                        "f0bf8997-4579-5872-82e1-1400b895417b"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "3ed9bf02-1389-5b1a-81e0-5dadb1daa205"
                    ]
                },
                "SU-s3.1": {
                    "questionId": "SU-s3.1",
                    "chosenOptionIds": [
                        "82702400-306e-52c0-b320-cc03e2f74660",
                        "28f722a5-8507-556b-abe2-8413d34679e4"
                    ]
                }
            }
        },
        {
            "name": "screened user: O3P66EW aspect: sbs_ce should be positive",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "SU-10a": {
                    "questionId": "SU-10a",
                    "chosenOptionIds": [
                        "ed6c59f2-8713-56b1-a4b2-8f1f85b224ed"
                    ]
                },
                "SU-11a": {
                    "questionId": "SU-11a",
                    "chosenOptionIds": [
                        "8f999645-02ed-57fb-90c7-c509bdf9217d"
                    ]
                },
                "SU-1a": {
                    "questionId": "SU-1a",
                    "chosenOptionIds": [
                        "1a337ce3-5ab7-5773-9fe0-f0d1c23ed96c"
                    ]
                },
                "SU-2a": {
                    "questionId": "SU-2a",
                    "chosenOptionIds": [
                        "61e46908-594f-5831-b709-767c60df9f2b"
                    ]
                },
                "SU-3a": {
                    "questionId": "SU-3a",
                    "chosenOptionIds": [
                        "8b9ec309-bccd-5e75-82c2-0b9f6bbf25fd"
                    ]
                },
                "SU-4a": {
                    "questionId": "SU-4a",
                    "chosenOptionIds": [
                        "ede457f4-731b-56bd-b7b7-58d93ecdcacd"
                    ]
                },
                "SU-5a": {
                    "questionId": "SU-5a",
                    "chosenOptionIds": [
                        "7ec23bf3-d08e-58e1-a88a-9677b0b85ec8"
                    ]
                },
                "SU-6a": {
                    "questionId": "SU-6a",
                    "chosenOptionIds": [
                        "a16682f6-84c6-5bdd-9553-eafb92701f79"
                    ]
                },
                "SU-7a": {
                    "questionId": "SU-7a",
                    "chosenOptionIds": [
                        "85e98c24-bf79-526d-8945-070f551c81c7"
                    ]
                },
                "SU-8a": {
                    "questionId": "SU-8a",
                    "chosenOptionIds": [
                        "38ede561-e889-51b7-baa3-51217ea1552e"
                    ]
                },
                "SU-9a": {
                    "questionId": "SU-9a",
                    "chosenOptionIds": [
                        "16c3b305-ee67-59a9-9182-8d6c929a9509"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "3ed9bf02-1389-5b1a-81e0-5dadb1daa205"
                    ]
                },
                "SU-s3.1": {
                    "questionId": "SU-s3.1",
                    "chosenOptionIds": [
                        "28f722a5-8507-556b-abe2-8413d34679e4"
                    ]
                }
            }
        },
        {
            "name": "screened user: O3PGG3W aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10b": {
                    "questionId": "SU-10b",
                    "chosenOptionIds": [
                        "fe352b2c-eff2-5046-8413-d86aa9192485"
                    ]
                },
                "SU-11b": {
                    "questionId": "SU-11b",
                    "chosenOptionIds": [
                        "1c65112c-5105-5069-a400-b16d985d1bdf"
                    ]
                },
                "SU-12b": {
                    "questionId": "SU-12b",
                    "chosenOptionIds": [
                        "cc068b90-c986-5049-9322-7b8b0fad4143"
                    ]
                },
                "SU-1b": {
                    "questionId": "SU-1b",
                    "freeValue": 1095
                },
                "SU-2b": {
                    "questionId": "SU-2b",
                    "chosenOptionIds": [
                        "f6b6411b-02e6-531d-b2ed-67d6dfacfd96"
                    ]
                },
                "SU-3b": {
                    "questionId": "SU-3b",
                    "chosenOptionIds": [
                        "48eb47f4-b5d0-5ca4-ba6a-5f811f32d488"
                    ]
                },
                "SU-4b": {
                    "questionId": "SU-4b",
                    "chosenOptionIds": [
                        "5af10404-91e9-55e5-a3a5-5b0525ebf15c"
                    ]
                },
                "SU-5b": {
                    "questionId": "SU-5b",
                    "chosenOptionIds": [
                        "a6d3f751-7a06-5eb4-97b5-70831bdb0e3d"
                    ]
                },
                "SU-6b": {
                    "questionId": "SU-6b",
                    "chosenOptionIds": [
                        "e6d0d639-7af5-5de3-9355-51cd24b425c5"
                    ]
                },
                "SU-7b": {
                    "questionId": "SU-7b",
                    "chosenOptionIds": [
                        "fb9594cf-71a8-580c-80d0-18dd9696d5bc"
                    ]
                },
                "SU-8b": {
                    "questionId": "SU-8b",
                    "chosenOptionIds": [
                        "a7206832-dc04-531b-bb17-18b8132ec246"
                    ]
                },
                "SU-9b": {
                    "questionId": "SU-9b",
                    "chosenOptionIds": [
                        "70dd7443-067d-5db4-bbfd-32f5c623fe8a"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f0bf8997-4579-5872-82e1-1400b895417b",
                        "18e8ad9b-e9a7-5311-8a6f-e9e64c5997fe",
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "67d6ef3c-b5a8-5230-b556-3bc42e2e5179"
                    ]
                }
            }
        },
        {
            "name": "screened user: Y32OM08 aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10b": {
                    "questionId": "SU-10b",
                    "chosenOptionIds": [
                        "eb7acd72-f4fa-52a4-b53f-db24e7756bbf"
                    ]
                },
                "SU-11b": {
                    "questionId": "SU-11b",
                    "chosenOptionIds": [
                        "df81db2f-9fe7-5b09-be87-64780dd81a25"
                    ]
                },
                "SU-12b": {
                    "questionId": "SU-12b",
                    "chosenOptionIds": [
                        "7debcb22-6637-5de5-9421-89be645c3b96"
                    ]
                },
                "SU-1b": {
                    "questionId": "SU-1b",
                    "freeValue": 1825
                },
                "SU-2b": {
                    "questionId": "SU-2b",
                    "chosenOptionIds": [
                        "4678ad6e-17f5-504f-84d5-d7e4bd032e1f"
                    ]
                },
                "SU-3b": {
                    "questionId": "SU-3b",
                    "chosenOptionIds": [
                        "48eb47f4-b5d0-5ca4-ba6a-5f811f32d488"
                    ]
                },
                "SU-4b": {
                    "questionId": "SU-4b",
                    "chosenOptionIds": [
                        "c48b4250-be6d-53b5-9c4b-50a2f5facea4"
                    ]
                },
                "SU-5b": {
                    "questionId": "SU-5b",
                    "chosenOptionIds": [
                        "bcac9437-7015-5fe5-9ee4-fb99cf0d5f17"
                    ]
                },
                "SU-6b": {
                    "questionId": "SU-6b",
                    "chosenOptionIds": [
                        "e6d0d639-7af5-5de3-9355-51cd24b425c5"
                    ]
                },
                "SU-7b": {
                    "questionId": "SU-7b",
                    "chosenOptionIds": [
                        "b6a26449-f023-5a8a-a4c9-a6c625f0f366"
                    ]
                },
                "SU-8b": {
                    "questionId": "SU-8b",
                    "chosenOptionIds": [
                        "a7206832-dc04-531b-bb17-18b8132ec246"
                    ]
                },
                "SU-9b": {
                    "questionId": "SU-9b",
                    "chosenOptionIds": [
                        "70dd7443-067d-5db4-bbfd-32f5c623fe8a"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f0bf8997-4579-5872-82e1-1400b895417b",
                        "4cf86ba0-867d-5f6d-b8d1-3aa5ceddc374",
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280",
                        "6248c04e-ea14-5b3e-8821-7cdbe8f84be3",
                        "4f2c6cea-7f4c-55ca-b952-334af926e7b1"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "67d6ef3c-b5a8-5230-b556-3bc42e2e5179"
                    ]
                }
            }
        },
        {
            "name": "screened user: ME84L3W aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0NJ5EQ aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0NO53Q aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: K392M0L aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10a": {
                    "questionId": "SU-10a",
                    "chosenOptionIds": [
                        "ed6c59f2-8713-56b1-a4b2-8f1f85b224ed"
                    ]
                },
                "SU-11a": {
                    "questionId": "SU-11a",
                    "chosenOptionIds": [
                        "57899734-07b2-50a2-a5d8-56142b5feb92"
                    ]
                },
                "SU-1a": {
                    "questionId": "SU-1a",
                    "chosenOptionIds": [
                        "64b85f3c-84e5-5d37-bc42-dab6740c8199"
                    ]
                },
                "SU-2a": {
                    "questionId": "SU-2a",
                    "chosenOptionIds": [
                        "6d761e88-087b-5887-9e08-7720347f7b48"
                    ]
                },
                "SU-3a": {
                    "questionId": "SU-3a",
                    "chosenOptionIds": [
                        "cba96b30-e2c3-5d5b-80a8-c18a79809f16"
                    ]
                },
                "SU-4a": {
                    "questionId": "SU-4a",
                    "chosenOptionIds": [
                        "ede457f4-731b-56bd-b7b7-58d93ecdcacd"
                    ]
                },
                "SU-5a": {
                    "questionId": "SU-5a",
                    "chosenOptionIds": [
                        "7ec23bf3-d08e-58e1-a88a-9677b0b85ec8"
                    ]
                },
                "SU-6a": {
                    "questionId": "SU-6a",
                    "chosenOptionIds": [
                        "a16682f6-84c6-5bdd-9553-eafb92701f79"
                    ]
                },
                "SU-7a": {
                    "questionId": "SU-7a",
                    "chosenOptionIds": [
                        "698ebf04-9eee-5b77-97f3-dd84b14ac94a"
                    ]
                },
                "SU-8a": {
                    "questionId": "SU-8a",
                    "chosenOptionIds": [
                        "38ede561-e889-51b7-baa3-51217ea1552e"
                    ]
                },
                "SU-9a": {
                    "questionId": "SU-9a",
                    "chosenOptionIds": [
                        "ccb31960-a823-519d-a4a9-7348ba4ca6f5"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "084e1561-5048-57b0-8796-986f65764e19"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "3ed9bf02-1389-5b1a-81e0-5dadb1daa205"
                    ]
                },
                "SU-s3.1": {
                    "questionId": "SU-s3.1",
                    "chosenOptionIds": [
                        "3a4d2bec-b4ca-56e7-9044-99ca4db70f63"
                    ]
                }
            }
        },
        {
            "name": "screened user: 6EDJMEM aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10b": {
                    "questionId": "SU-10b",
                    "chosenOptionIds": [
                        "fe352b2c-eff2-5046-8413-d86aa9192485"
                    ]
                },
                "SU-11b": {
                    "questionId": "SU-11b",
                    "chosenOptionIds": [
                        "1c65112c-5105-5069-a400-b16d985d1bdf"
                    ]
                },
                "SU-12b": {
                    "questionId": "SU-12b",
                    "chosenOptionIds": [
                        "cc068b90-c986-5049-9322-7b8b0fad4143"
                    ]
                },
                "SU-1b": {
                    "questionId": "SU-1b",
                    "freeValue": 365
                },
                "SU-2b": {
                    "questionId": "SU-2b",
                    "chosenOptionIds": [
                        "f6b6411b-02e6-531d-b2ed-67d6dfacfd96"
                    ]
                },
                "SU-3b": {
                    "questionId": "SU-3b",
                    "chosenOptionIds": [
                        "48eb47f4-b5d0-5ca4-ba6a-5f811f32d488"
                    ]
                },
                "SU-4b": {
                    "questionId": "SU-4b",
                    "chosenOptionIds": [
                        "5af10404-91e9-55e5-a3a5-5b0525ebf15c"
                    ]
                },
                "SU-5b": {
                    "questionId": "SU-5b",
                    "chosenOptionIds": [
                        "a6d3f751-7a06-5eb4-97b5-70831bdb0e3d"
                    ]
                },
                "SU-6b": {
                    "questionId": "SU-6b",
                    "chosenOptionIds": [
                        "e6d0d639-7af5-5de3-9355-51cd24b425c5"
                    ]
                },
                "SU-7b": {
                    "questionId": "SU-7b",
                    "chosenOptionIds": [
                        "fb9594cf-71a8-580c-80d0-18dd9696d5bc"
                    ]
                },
                "SU-8b": {
                    "questionId": "SU-8b",
                    "chosenOptionIds": [
                        "a7206832-dc04-531b-bb17-18b8132ec246"
                    ]
                },
                "SU-9b": {
                    "questionId": "SU-9b",
                    "chosenOptionIds": [
                        "70dd7443-067d-5db4-bbfd-32f5c623fe8a"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "4cf86ba0-867d-5f6d-b8d1-3aa5ceddc374"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "67d6ef3c-b5a8-5230-b556-3bc42e2e5179"
                    ]
                }
            }
        },
        {
            "name": "screened user: 10Q6R0P aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: O0KPDE1 aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: 63YPO3R aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10b": {
                    "questionId": "SU-10b",
                    "chosenOptionIds": [
                        "fe352b2c-eff2-5046-8413-d86aa9192485"
                    ]
                },
                "SU-11b": {
                    "questionId": "SU-11b",
                    "chosenOptionIds": [
                        "1c65112c-5105-5069-a400-b16d985d1bdf"
                    ]
                },
                "SU-12b": {
                    "questionId": "SU-12b",
                    "chosenOptionIds": [
                        "cc068b90-c986-5049-9322-7b8b0fad4143"
                    ]
                },
                "SU-1b": {
                    "questionId": "SU-1b",
                    "freeValue": 14600
                },
                "SU-2b": {
                    "questionId": "SU-2b",
                    "chosenOptionIds": [
                        "4678ad6e-17f5-504f-84d5-d7e4bd032e1f"
                    ]
                },
                "SU-3b": {
                    "questionId": "SU-3b",
                    "chosenOptionIds": [
                        "48eb47f4-b5d0-5ca4-ba6a-5f811f32d488"
                    ]
                },
                "SU-4b": {
                    "questionId": "SU-4b",
                    "chosenOptionIds": [
                        "a55e2d73-6bb1-5ddf-b7f0-be44aa25ea75"
                    ]
                },
                "SU-5b": {
                    "questionId": "SU-5b",
                    "chosenOptionIds": [
                        "a6d3f751-7a06-5eb4-97b5-70831bdb0e3d"
                    ]
                },
                "SU-6b": {
                    "questionId": "SU-6b",
                    "chosenOptionIds": [
                        "e6d0d639-7af5-5de3-9355-51cd24b425c5"
                    ]
                },
                "SU-7b": {
                    "questionId": "SU-7b",
                    "chosenOptionIds": [
                        "fb9594cf-71a8-580c-80d0-18dd9696d5bc"
                    ]
                },
                "SU-8b": {
                    "questionId": "SU-8b",
                    "chosenOptionIds": [
                        "a7206832-dc04-531b-bb17-18b8132ec246"
                    ]
                },
                "SU-9b": {
                    "questionId": "SU-9b",
                    "chosenOptionIds": [
                        "70dd7443-067d-5db4-bbfd-32f5c623fe8a"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "4cf86ba0-867d-5f6d-b8d1-3aa5ceddc374",
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "67d6ef3c-b5a8-5230-b556-3bc42e2e5179"
                    ]
                }
            }
        },
        {
            "name": "screened user: 23X6V0N aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10b": {
                    "questionId": "SU-10b",
                    "chosenOptionIds": [
                        "fe352b2c-eff2-5046-8413-d86aa9192485"
                    ]
                },
                "SU-11b": {
                    "questionId": "SU-11b",
                    "chosenOptionIds": [
                        "1c65112c-5105-5069-a400-b16d985d1bdf"
                    ]
                },
                "SU-12b": {
                    "questionId": "SU-12b",
                    "chosenOptionIds": [
                        "cc068b90-c986-5049-9322-7b8b0fad4143"
                    ]
                },
                "SU-1b": {
                    "questionId": "SU-1b",
                    "freeValue": 14600
                },
                "SU-2b": {
                    "questionId": "SU-2b",
                    "chosenOptionIds": [
                        "f6b6411b-02e6-531d-b2ed-67d6dfacfd96"
                    ]
                },
                "SU-3b": {
                    "questionId": "SU-3b",
                    "chosenOptionIds": [
                        "48eb47f4-b5d0-5ca4-ba6a-5f811f32d488"
                    ]
                },
                "SU-4b": {
                    "questionId": "SU-4b",
                    "chosenOptionIds": [
                        "a55e2d73-6bb1-5ddf-b7f0-be44aa25ea75"
                    ]
                },
                "SU-5b": {
                    "questionId": "SU-5b",
                    "chosenOptionIds": [
                        "a6d3f751-7a06-5eb4-97b5-70831bdb0e3d"
                    ]
                },
                "SU-6b": {
                    "questionId": "SU-6b",
                    "chosenOptionIds": [
                        "e6d0d639-7af5-5de3-9355-51cd24b425c5"
                    ]
                },
                "SU-7b": {
                    "questionId": "SU-7b",
                    "chosenOptionIds": [
                        "fb9594cf-71a8-580c-80d0-18dd9696d5bc"
                    ]
                },
                "SU-8b": {
                    "questionId": "SU-8b",
                    "chosenOptionIds": [
                        "a7206832-dc04-531b-bb17-18b8132ec246"
                    ]
                },
                "SU-9b": {
                    "questionId": "SU-9b",
                    "chosenOptionIds": [
                        "70dd7443-067d-5db4-bbfd-32f5c623fe8a"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f0bf8997-4579-5872-82e1-1400b895417b",
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "67d6ef3c-b5a8-5230-b556-3bc42e2e5179"
                    ]
                }
            }
        },
        {
            "name": "screened user: 50MQK36 aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10a": {
                    "questionId": "SU-10a",
                    "chosenOptionIds": [
                        "ed6c59f2-8713-56b1-a4b2-8f1f85b224ed"
                    ]
                },
                "SU-11a": {
                    "questionId": "SU-11a",
                    "chosenOptionIds": [
                        "57899734-07b2-50a2-a5d8-56142b5feb92"
                    ]
                },
                "SU-1a": {
                    "questionId": "SU-1a",
                    "chosenOptionIds": [
                        "dc9dd01a-e6c0-58c8-9b8f-b3315d6a689c"
                    ]
                },
                "SU-2a": {
                    "questionId": "SU-2a",
                    "chosenOptionIds": [
                        "6d761e88-087b-5887-9e08-7720347f7b48"
                    ]
                },
                "SU-3a": {
                    "questionId": "SU-3a",
                    "chosenOptionIds": [
                        "cba96b30-e2c3-5d5b-80a8-c18a79809f16"
                    ]
                },
                "SU-4a": {
                    "questionId": "SU-4a",
                    "chosenOptionIds": [
                        "ede457f4-731b-56bd-b7b7-58d93ecdcacd"
                    ]
                },
                "SU-5a": {
                    "questionId": "SU-5a",
                    "chosenOptionIds": [
                        "7ec23bf3-d08e-58e1-a88a-9677b0b85ec8"
                    ]
                },
                "SU-6a": {
                    "questionId": "SU-6a",
                    "chosenOptionIds": [
                        "a16682f6-84c6-5bdd-9553-eafb92701f79"
                    ]
                },
                "SU-7a": {
                    "questionId": "SU-7a",
                    "chosenOptionIds": [
                        "698ebf04-9eee-5b77-97f3-dd84b14ac94a"
                    ]
                },
                "SU-8a": {
                    "questionId": "SU-8a",
                    "chosenOptionIds": [
                        "38ede561-e889-51b7-baa3-51217ea1552e"
                    ]
                },
                "SU-9a": {
                    "questionId": "SU-9a",
                    "chosenOptionIds": [
                        "ccb31960-a823-519d-a4a9-7348ba4ca6f5"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "3ed9bf02-1389-5b1a-81e0-5dadb1daa205"
                    ]
                },
                "SU-s3.1": {
                    "questionId": "SU-s3.1",
                    "chosenOptionIds": [
                        "28f722a5-8507-556b-abe2-8413d34679e4"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0N2PEQ aspect: sbs_ce should be positive",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "SU-10a": {
                    "questionId": "SU-10a",
                    "chosenOptionIds": [
                        "dfdb12b6-8e8e-58eb-8a79-3ffaf59b4d9c"
                    ]
                },
                "SU-11a": {
                    "questionId": "SU-11a",
                    "chosenOptionIds": [
                        "57899734-07b2-50a2-a5d8-56142b5feb92"
                    ]
                },
                "SU-1a": {
                    "questionId": "SU-1a",
                    "chosenOptionIds": [
                        "1a337ce3-5ab7-5773-9fe0-f0d1c23ed96c"
                    ]
                },
                "SU-2a": {
                    "questionId": "SU-2a",
                    "chosenOptionIds": [
                        "6d761e88-087b-5887-9e08-7720347f7b48"
                    ]
                },
                "SU-3a": {
                    "questionId": "SU-3a",
                    "chosenOptionIds": [
                        "8b9ec309-bccd-5e75-82c2-0b9f6bbf25fd"
                    ]
                },
                "SU-4a": {
                    "questionId": "SU-4a",
                    "chosenOptionIds": [
                        "3927c90d-af04-584d-a800-515db0f2ec3d"
                    ]
                },
                "SU-5a": {
                    "questionId": "SU-5a",
                    "chosenOptionIds": [
                        "e990acdb-4f26-5f2a-9794-2949aca56fb2"
                    ]
                },
                "SU-6a": {
                    "questionId": "SU-6a",
                    "chosenOptionIds": [
                        "a16682f6-84c6-5bdd-9553-eafb92701f79"
                    ]
                },
                "SU-7a": {
                    "questionId": "SU-7a",
                    "chosenOptionIds": [
                        "85e98c24-bf79-526d-8945-070f551c81c7"
                    ]
                },
                "SU-8a": {
                    "questionId": "SU-8a",
                    "chosenOptionIds": [
                        "38ede561-e889-51b7-baa3-51217ea1552e"
                    ]
                },
                "SU-9a": {
                    "questionId": "SU-9a",
                    "chosenOptionIds": [
                        "16c3b305-ee67-59a9-9182-8d6c929a9509"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "4f2c6cea-7f4c-55ca-b952-334af926e7b1",
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "3ed9bf02-1389-5b1a-81e0-5dadb1daa205"
                    ]
                },
                "SU-s3.1": {
                    "questionId": "SU-s3.1",
                    "chosenOptionIds": [
                        "28f722a5-8507-556b-abe2-8413d34679e4"
                    ]
                }
            }
        },
        {
            "name": "screened user: J3JO40Y aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: 4EGGGEP aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: 2E1JL0D aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: R04JV34 aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: 7EWGK38 aspect: sbs_ce should be positive",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "SU-10a": {
                    "questionId": "SU-10a",
                    "chosenOptionIds": [
                        "dfdb12b6-8e8e-58eb-8a79-3ffaf59b4d9c"
                    ]
                },
                "SU-11a": {
                    "questionId": "SU-11a",
                    "chosenOptionIds": [
                        "57899734-07b2-50a2-a5d8-56142b5feb92"
                    ]
                },
                "SU-1a": {
                    "questionId": "SU-1a",
                    "chosenOptionIds": [
                        "1a337ce3-5ab7-5773-9fe0-f0d1c23ed96c"
                    ]
                },
                "SU-2a": {
                    "questionId": "SU-2a",
                    "chosenOptionIds": [
                        "61e46908-594f-5831-b709-767c60df9f2b"
                    ]
                },
                "SU-3a": {
                    "questionId": "SU-3a",
                    "chosenOptionIds": [
                        "8b9ec309-bccd-5e75-82c2-0b9f6bbf25fd"
                    ]
                },
                "SU-4a": {
                    "questionId": "SU-4a",
                    "chosenOptionIds": [
                        "ede457f4-731b-56bd-b7b7-58d93ecdcacd"
                    ]
                },
                "SU-5a": {
                    "questionId": "SU-5a",
                    "chosenOptionIds": [
                        "e990acdb-4f26-5f2a-9794-2949aca56fb2"
                    ]
                },
                "SU-6a": {
                    "questionId": "SU-6a",
                    "chosenOptionIds": [
                        "a16682f6-84c6-5bdd-9553-eafb92701f79"
                    ]
                },
                "SU-7a": {
                    "questionId": "SU-7a",
                    "chosenOptionIds": [
                        "698ebf04-9eee-5b77-97f3-dd84b14ac94a"
                    ]
                },
                "SU-8a": {
                    "questionId": "SU-8a",
                    "chosenOptionIds": [
                        "38ede561-e889-51b7-baa3-51217ea1552e"
                    ]
                },
                "SU-9a": {
                    "questionId": "SU-9a",
                    "chosenOptionIds": [
                        "16c3b305-ee67-59a9-9182-8d6c929a9509"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "3ed9bf02-1389-5b1a-81e0-5dadb1daa205"
                    ]
                },
                "SU-s3.1": {
                    "questionId": "SU-s3.1",
                    "chosenOptionIds": [
                        "28f722a5-8507-556b-abe2-8413d34679e4"
                    ]
                }
            }
        },
        {
            "name": "screened user: 23X4V3N aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10a": {
                    "questionId": "SU-10a",
                    "chosenOptionIds": [
                        "ed6c59f2-8713-56b1-a4b2-8f1f85b224ed"
                    ]
                },
                "SU-11a": {
                    "questionId": "SU-11a",
                    "chosenOptionIds": [
                        "57899734-07b2-50a2-a5d8-56142b5feb92"
                    ]
                },
                "SU-1a": {
                    "questionId": "SU-1a",
                    "chosenOptionIds": [
                        "dc9dd01a-e6c0-58c8-9b8f-b3315d6a689c"
                    ]
                },
                "SU-2a": {
                    "questionId": "SU-2a",
                    "chosenOptionIds": [
                        "6d761e88-087b-5887-9e08-7720347f7b48"
                    ]
                },
                "SU-3a": {
                    "questionId": "SU-3a",
                    "chosenOptionIds": [
                        "a3720a21-cf5f-5c57-9dde-8cb54605246f"
                    ]
                },
                "SU-4a": {
                    "questionId": "SU-4a",
                    "chosenOptionIds": [
                        "ede457f4-731b-56bd-b7b7-58d93ecdcacd"
                    ]
                },
                "SU-5a": {
                    "questionId": "SU-5a",
                    "chosenOptionIds": [
                        "7ec23bf3-d08e-58e1-a88a-9677b0b85ec8"
                    ]
                },
                "SU-6a": {
                    "questionId": "SU-6a",
                    "chosenOptionIds": [
                        "a16682f6-84c6-5bdd-9553-eafb92701f79"
                    ]
                },
                "SU-7a": {
                    "questionId": "SU-7a",
                    "chosenOptionIds": [
                        "698ebf04-9eee-5b77-97f3-dd84b14ac94a"
                    ]
                },
                "SU-8a": {
                    "questionId": "SU-8a",
                    "chosenOptionIds": [
                        "38ede561-e889-51b7-baa3-51217ea1552e"
                    ]
                },
                "SU-9a": {
                    "questionId": "SU-9a",
                    "chosenOptionIds": [
                        "ccb31960-a823-519d-a4a9-7348ba4ca6f5"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280",
                        "18e8ad9b-e9a7-5311-8a6f-e9e64c5997fe"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "3ed9bf02-1389-5b1a-81e0-5dadb1daa205"
                    ]
                },
                "SU-s3.1": {
                    "questionId": "SU-s3.1",
                    "chosenOptionIds": [
                        "28f722a5-8507-556b-abe2-8413d34679e4"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z35X10O aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: O0K9D01 aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: 23XP2EN aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: 4EGLK3P aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: NEL9J3V aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: PE694EL aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: K399M3L aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10b": {
                    "questionId": "SU-10b",
                    "chosenOptionIds": [
                        "fe352b2c-eff2-5046-8413-d86aa9192485"
                    ]
                },
                "SU-11b": {
                    "questionId": "SU-11b",
                    "chosenOptionIds": [
                        "1c65112c-5105-5069-a400-b16d985d1bdf"
                    ]
                },
                "SU-12b": {
                    "questionId": "SU-12b",
                    "chosenOptionIds": [
                        "cc068b90-c986-5049-9322-7b8b0fad4143"
                    ]
                },
                "SU-1b": {
                    "questionId": "SU-1b",
                    "freeValue": 1095
                },
                "SU-2b": {
                    "questionId": "SU-2b",
                    "chosenOptionIds": [
                        "f6b6411b-02e6-531d-b2ed-67d6dfacfd96"
                    ]
                },
                "SU-3b": {
                    "questionId": "SU-3b",
                    "chosenOptionIds": [
                        "48eb47f4-b5d0-5ca4-ba6a-5f811f32d488"
                    ]
                },
                "SU-4b": {
                    "questionId": "SU-4b",
                    "chosenOptionIds": [
                        "a55e2d73-6bb1-5ddf-b7f0-be44aa25ea75"
                    ]
                },
                "SU-5b": {
                    "questionId": "SU-5b",
                    "chosenOptionIds": [
                        "a6d3f751-7a06-5eb4-97b5-70831bdb0e3d"
                    ]
                },
                "SU-6b": {
                    "questionId": "SU-6b",
                    "chosenOptionIds": [
                        "e6d0d639-7af5-5de3-9355-51cd24b425c5"
                    ]
                },
                "SU-7b": {
                    "questionId": "SU-7b",
                    "chosenOptionIds": [
                        "fb9594cf-71a8-580c-80d0-18dd9696d5bc"
                    ]
                },
                "SU-8b": {
                    "questionId": "SU-8b",
                    "chosenOptionIds": [
                        "a7206832-dc04-531b-bb17-18b8132ec246"
                    ]
                },
                "SU-9b": {
                    "questionId": "SU-9b",
                    "chosenOptionIds": [
                        "70dd7443-067d-5db4-bbfd-32f5c623fe8a"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f0bf8997-4579-5872-82e1-1400b895417b",
                        "18e8ad9b-e9a7-5311-8a6f-e9e64c5997fe",
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "67d6ef3c-b5a8-5230-b556-3bc42e2e5179"
                    ]
                }
            }
        },
        {
            "name": "screened user: 50MGW06 aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: J0VGM0L aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: Y32QME8 aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: O3P770W aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: 7EWZKE8 aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10b": {
                    "questionId": "SU-10b",
                    "chosenOptionIds": [
                        "fe352b2c-eff2-5046-8413-d86aa9192485"
                    ]
                },
                "SU-11b": {
                    "questionId": "SU-11b",
                    "chosenOptionIds": [
                        "1c65112c-5105-5069-a400-b16d985d1bdf"
                    ]
                },
                "SU-12b": {
                    "questionId": "SU-12b",
                    "chosenOptionIds": [
                        "cc068b90-c986-5049-9322-7b8b0fad4143"
                    ]
                },
                "SU-1b": {
                    "questionId": "SU-1b",
                    "freeValue": 365
                },
                "SU-2b": {
                    "questionId": "SU-2b",
                    "chosenOptionIds": [
                        "f6b6411b-02e6-531d-b2ed-67d6dfacfd96"
                    ]
                },
                "SU-3b": {
                    "questionId": "SU-3b",
                    "chosenOptionIds": [
                        "48eb47f4-b5d0-5ca4-ba6a-5f811f32d488"
                    ]
                },
                "SU-4b": {
                    "questionId": "SU-4b",
                    "chosenOptionIds": [
                        "5af10404-91e9-55e5-a3a5-5b0525ebf15c"
                    ]
                },
                "SU-5b": {
                    "questionId": "SU-5b",
                    "chosenOptionIds": [
                        "6e890ffb-ea8c-50f4-87d8-0b9fcb05e77c"
                    ]
                },
                "SU-6b": {
                    "questionId": "SU-6b",
                    "chosenOptionIds": [
                        "e6d0d639-7af5-5de3-9355-51cd24b425c5"
                    ]
                },
                "SU-7b": {
                    "questionId": "SU-7b",
                    "chosenOptionIds": [
                        "fb9594cf-71a8-580c-80d0-18dd9696d5bc"
                    ]
                },
                "SU-8b": {
                    "questionId": "SU-8b",
                    "chosenOptionIds": [
                        "32ec1d91-b47c-5754-a656-c6c0f230ace2"
                    ]
                },
                "SU-9b": {
                    "questionId": "SU-9b",
                    "chosenOptionIds": [
                        "70dd7443-067d-5db4-bbfd-32f5c623fe8a"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "4cf86ba0-867d-5f6d-b8d1-3aa5ceddc374",
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "67d6ef3c-b5a8-5230-b556-3bc42e2e5179"
                    ]
                }
            }
        },
        {
            "name": "screened user: PE6NMEL aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z356NEO aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10b": {
                    "questionId": "SU-10b",
                    "chosenOptionIds": [
                        "fe352b2c-eff2-5046-8413-d86aa9192485"
                    ]
                },
                "SU-11b": {
                    "questionId": "SU-11b",
                    "chosenOptionIds": [
                        "1c65112c-5105-5069-a400-b16d985d1bdf"
                    ]
                },
                "SU-12b": {
                    "questionId": "SU-12b",
                    "chosenOptionIds": [
                        "cc068b90-c986-5049-9322-7b8b0fad4143"
                    ]
                },
                "SU-1b": {
                    "questionId": "SU-1b",
                    "freeValue": 1095
                },
                "SU-2b": {
                    "questionId": "SU-2b",
                    "chosenOptionIds": [
                        "4678ad6e-17f5-504f-84d5-d7e4bd032e1f"
                    ]
                },
                "SU-3b": {
                    "questionId": "SU-3b",
                    "chosenOptionIds": [
                        "97837883-2c07-58ae-87ab-970f4a8e16d7"
                    ]
                },
                "SU-4b": {
                    "questionId": "SU-4b",
                    "chosenOptionIds": [
                        "a55e2d73-6bb1-5ddf-b7f0-be44aa25ea75"
                    ]
                },
                "SU-5b": {
                    "questionId": "SU-5b",
                    "chosenOptionIds": [
                        "61e94409-cc88-5298-9cee-7d1ff14341fa"
                    ]
                },
                "SU-6b": {
                    "questionId": "SU-6b",
                    "chosenOptionIds": [
                        "e6d0d639-7af5-5de3-9355-51cd24b425c5"
                    ]
                },
                "SU-7b": {
                    "questionId": "SU-7b",
                    "chosenOptionIds": [
                        "fb9594cf-71a8-580c-80d0-18dd9696d5bc"
                    ]
                },
                "SU-8b": {
                    "questionId": "SU-8b",
                    "chosenOptionIds": [
                        "a7206832-dc04-531b-bb17-18b8132ec246"
                    ]
                },
                "SU-9b": {
                    "questionId": "SU-9b",
                    "chosenOptionIds": [
                        "70dd7443-067d-5db4-bbfd-32f5c623fe8a"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f0bf8997-4579-5872-82e1-1400b895417b",
                        "4cf86ba0-867d-5f6d-b8d1-3aa5ceddc374",
                        "18e8ad9b-e9a7-5311-8a6f-e9e64c5997fe",
                        "4f2c6cea-7f4c-55ca-b952-334af926e7b1",
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "67d6ef3c-b5a8-5230-b556-3bc42e2e5179"
                    ]
                }
            }
        },
        {
            "name": "screened user: 23XV2EN aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z3R443O aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: PE6Q4EL aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: 6EDW60M aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: J3JV43Y aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: PE6GM3L aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10b": {
                    "questionId": "SU-10b",
                    "chosenOptionIds": [
                        "fe352b2c-eff2-5046-8413-d86aa9192485"
                    ]
                },
                "SU-11b": {
                    "questionId": "SU-11b",
                    "chosenOptionIds": [
                        "1c65112c-5105-5069-a400-b16d985d1bdf"
                    ]
                },
                "SU-12b": {
                    "questionId": "SU-12b",
                    "chosenOptionIds": [
                        "7debcb22-6637-5de5-9421-89be645c3b96"
                    ]
                },
                "SU-1b": {
                    "questionId": "SU-1b",
                    "freeValue": 2555
                },
                "SU-2b": {
                    "questionId": "SU-2b",
                    "chosenOptionIds": [
                        "f6b6411b-02e6-531d-b2ed-67d6dfacfd96"
                    ]
                },
                "SU-3b": {
                    "questionId": "SU-3b",
                    "chosenOptionIds": [
                        "48eb47f4-b5d0-5ca4-ba6a-5f811f32d488"
                    ]
                },
                "SU-4b": {
                    "questionId": "SU-4b",
                    "chosenOptionIds": [
                        "a55e2d73-6bb1-5ddf-b7f0-be44aa25ea75"
                    ]
                },
                "SU-5b": {
                    "questionId": "SU-5b",
                    "chosenOptionIds": [
                        "6e890ffb-ea8c-50f4-87d8-0b9fcb05e77c"
                    ]
                },
                "SU-6b": {
                    "questionId": "SU-6b",
                    "chosenOptionIds": [
                        "e6d0d639-7af5-5de3-9355-51cd24b425c5"
                    ]
                },
                "SU-7b": {
                    "questionId": "SU-7b",
                    "chosenOptionIds": [
                        "fb9594cf-71a8-580c-80d0-18dd9696d5bc"
                    ]
                },
                "SU-8b": {
                    "questionId": "SU-8b",
                    "chosenOptionIds": [
                        "a7206832-dc04-531b-bb17-18b8132ec246"
                    ]
                },
                "SU-9b": {
                    "questionId": "SU-9b",
                    "chosenOptionIds": [
                        "70dd7443-067d-5db4-bbfd-32f5c623fe8a"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "4cf86ba0-867d-5f6d-b8d1-3aa5ceddc374",
                        "4f2c6cea-7f4c-55ca-b952-334af926e7b1",
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "67d6ef3c-b5a8-5230-b556-3bc42e2e5179"
                    ]
                }
            }
        },
        {
            "name": "screened user: 63Y843R aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0NQJ0Q aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: XE7DM0L aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: 23XX43N aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: Y32LM08 aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10b": {
                    "questionId": "SU-10b",
                    "chosenOptionIds": [
                        "fe352b2c-eff2-5046-8413-d86aa9192485"
                    ]
                },
                "SU-11b": {
                    "questionId": "SU-11b",
                    "chosenOptionIds": [
                        "1c65112c-5105-5069-a400-b16d985d1bdf"
                    ]
                },
                "SU-12b": {
                    "questionId": "SU-12b",
                    "chosenOptionIds": [
                        "cc068b90-c986-5049-9322-7b8b0fad4143"
                    ]
                },
                "SU-1b": {
                    "questionId": "SU-1b",
                    "freeValue": 2190
                },
                "SU-2b": {
                    "questionId": "SU-2b",
                    "chosenOptionIds": [
                        "f6b6411b-02e6-531d-b2ed-67d6dfacfd96"
                    ]
                },
                "SU-3b": {
                    "questionId": "SU-3b",
                    "chosenOptionIds": [
                        "48eb47f4-b5d0-5ca4-ba6a-5f811f32d488"
                    ]
                },
                "SU-4b": {
                    "questionId": "SU-4b",
                    "chosenOptionIds": [
                        "a55e2d73-6bb1-5ddf-b7f0-be44aa25ea75"
                    ]
                },
                "SU-5b": {
                    "questionId": "SU-5b",
                    "chosenOptionIds": [
                        "a6d3f751-7a06-5eb4-97b5-70831bdb0e3d"
                    ]
                },
                "SU-6b": {
                    "questionId": "SU-6b",
                    "chosenOptionIds": [
                        "e6d0d639-7af5-5de3-9355-51cd24b425c5"
                    ]
                },
                "SU-7b": {
                    "questionId": "SU-7b",
                    "chosenOptionIds": [
                        "fb9594cf-71a8-580c-80d0-18dd9696d5bc"
                    ]
                },
                "SU-8b": {
                    "questionId": "SU-8b",
                    "chosenOptionIds": [
                        "a7206832-dc04-531b-bb17-18b8132ec246"
                    ]
                },
                "SU-9b": {
                    "questionId": "SU-9b",
                    "chosenOptionIds": [
                        "70dd7443-067d-5db4-bbfd-32f5c623fe8a"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280",
                        "4cf86ba0-867d-5f6d-b8d1-3aa5ceddc374"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "67d6ef3c-b5a8-5230-b556-3bc42e2e5179"
                    ]
                }
            }
        },
        {
            "name": "screened user: J3J4P0Y aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: 2E1580D aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: O3PX60W aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10a": {
                    "questionId": "SU-10a",
                    "chosenOptionIds": [
                        "ed6c59f2-8713-56b1-a4b2-8f1f85b224ed"
                    ]
                },
                "SU-11a": {
                    "questionId": "SU-11a",
                    "chosenOptionIds": [
                        "57899734-07b2-50a2-a5d8-56142b5feb92"
                    ]
                },
                "SU-1a": {
                    "questionId": "SU-1a",
                    "chosenOptionIds": [
                        "64b85f3c-84e5-5d37-bc42-dab6740c8199"
                    ]
                },
                "SU-2a": {
                    "questionId": "SU-2a",
                    "chosenOptionIds": [
                        "6d761e88-087b-5887-9e08-7720347f7b48"
                    ]
                },
                "SU-3a": {
                    "questionId": "SU-3a",
                    "chosenOptionIds": [
                        "cba96b30-e2c3-5d5b-80a8-c18a79809f16"
                    ]
                },
                "SU-4a": {
                    "questionId": "SU-4a",
                    "chosenOptionIds": [
                        "ede457f4-731b-56bd-b7b7-58d93ecdcacd"
                    ]
                },
                "SU-5a": {
                    "questionId": "SU-5a",
                    "chosenOptionIds": [
                        "7ec23bf3-d08e-58e1-a88a-9677b0b85ec8"
                    ]
                },
                "SU-6a": {
                    "questionId": "SU-6a",
                    "chosenOptionIds": [
                        "a16682f6-84c6-5bdd-9553-eafb92701f79"
                    ]
                },
                "SU-7a": {
                    "questionId": "SU-7a",
                    "chosenOptionIds": [
                        "698ebf04-9eee-5b77-97f3-dd84b14ac94a"
                    ]
                },
                "SU-8a": {
                    "questionId": "SU-8a",
                    "chosenOptionIds": [
                        "38ede561-e889-51b7-baa3-51217ea1552e"
                    ]
                },
                "SU-9a": {
                    "questionId": "SU-9a",
                    "chosenOptionIds": [
                        "ccb31960-a823-519d-a4a9-7348ba4ca6f5"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f0bf8997-4579-5872-82e1-1400b895417b",
                        "4cf86ba0-867d-5f6d-b8d1-3aa5ceddc374",
                        "18e8ad9b-e9a7-5311-8a6f-e9e64c5997fe",
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280",
                        "36c77f4a-59a6-588e-8346-b190d1491a17"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "3ed9bf02-1389-5b1a-81e0-5dadb1daa205"
                    ]
                },
                "SU-s3.1": {
                    "questionId": "SU-s3.1",
                    "chosenOptionIds": [
                        "95ba4a6b-7026-5c10-98e9-07370c2b605a"
                    ]
                }
            }
        },
        {
            "name": "screened user: REOQG32 aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: 50MDW06 aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: 7EW6KE8 aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10b": {
                    "questionId": "SU-10b",
                    "chosenOptionIds": [
                        "fe352b2c-eff2-5046-8413-d86aa9192485"
                    ]
                },
                "SU-11b": {
                    "questionId": "SU-11b",
                    "chosenOptionIds": [
                        "1c65112c-5105-5069-a400-b16d985d1bdf"
                    ]
                },
                "SU-12b": {
                    "questionId": "SU-12b",
                    "chosenOptionIds": [
                        "cc068b90-c986-5049-9322-7b8b0fad4143"
                    ]
                },
                "SU-1b": {
                    "questionId": "SU-1b",
                    "freeValue": 365
                },
                "SU-2b": {
                    "questionId": "SU-2b",
                    "chosenOptionIds": [
                        "4678ad6e-17f5-504f-84d5-d7e4bd032e1f"
                    ]
                },
                "SU-3b": {
                    "questionId": "SU-3b",
                    "chosenOptionIds": [
                        "48eb47f4-b5d0-5ca4-ba6a-5f811f32d488"
                    ]
                },
                "SU-4b": {
                    "questionId": "SU-4b",
                    "chosenOptionIds": [
                        "5af10404-91e9-55e5-a3a5-5b0525ebf15c"
                    ]
                },
                "SU-5b": {
                    "questionId": "SU-5b",
                    "chosenOptionIds": [
                        "6e890ffb-ea8c-50f4-87d8-0b9fcb05e77c"
                    ]
                },
                "SU-6b": {
                    "questionId": "SU-6b",
                    "chosenOptionIds": [
                        "e6d0d639-7af5-5de3-9355-51cd24b425c5"
                    ]
                },
                "SU-7b": {
                    "questionId": "SU-7b",
                    "chosenOptionIds": [
                        "fb9594cf-71a8-580c-80d0-18dd9696d5bc"
                    ]
                },
                "SU-8b": {
                    "questionId": "SU-8b",
                    "chosenOptionIds": [
                        "a7206832-dc04-531b-bb17-18b8132ec246"
                    ]
                },
                "SU-9b": {
                    "questionId": "SU-9b",
                    "chosenOptionIds": [
                        "70dd7443-067d-5db4-bbfd-32f5c623fe8a"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "67d6ef3c-b5a8-5230-b556-3bc42e2e5179"
                    ]
                }
            }
        },
        {
            "name": "screened user: J0VQM3L aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: 4EGRG3P aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z35L10O aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: 63YM43R aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: Y325838 aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: XE72M0L aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: ME8MOEW aspect: sbs_ce should be positive",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "SU-10a": {
                    "questionId": "SU-10a",
                    "chosenOptionIds": [
                        "ed6c59f2-8713-56b1-a4b2-8f1f85b224ed"
                    ]
                },
                "SU-11a": {
                    "questionId": "SU-11a",
                    "chosenOptionIds": [
                        "8f999645-02ed-57fb-90c7-c509bdf9217d"
                    ]
                },
                "SU-1a": {
                    "questionId": "SU-1a",
                    "chosenOptionIds": [
                        "64b85f3c-84e5-5d37-bc42-dab6740c8199"
                    ]
                },
                "SU-2a": {
                    "questionId": "SU-2a",
                    "chosenOptionIds": [
                        "6d761e88-087b-5887-9e08-7720347f7b48"
                    ]
                },
                "SU-3a": {
                    "questionId": "SU-3a",
                    "chosenOptionIds": [
                        "a3720a21-cf5f-5c57-9dde-8cb54605246f"
                    ]
                },
                "SU-4a": {
                    "questionId": "SU-4a",
                    "chosenOptionIds": [
                        "dd97a5ec-89cc-5ee7-b89b-644d9f1a2b7b"
                    ]
                },
                "SU-5a": {
                    "questionId": "SU-5a",
                    "chosenOptionIds": [
                        "7ec23bf3-d08e-58e1-a88a-9677b0b85ec8"
                    ]
                },
                "SU-6a": {
                    "questionId": "SU-6a",
                    "chosenOptionIds": [
                        "a16682f6-84c6-5bdd-9553-eafb92701f79"
                    ]
                },
                "SU-7a": {
                    "questionId": "SU-7a",
                    "chosenOptionIds": [
                        "698ebf04-9eee-5b77-97f3-dd84b14ac94a"
                    ]
                },
                "SU-8a": {
                    "questionId": "SU-8a",
                    "chosenOptionIds": [
                        "38ede561-e889-51b7-baa3-51217ea1552e"
                    ]
                },
                "SU-9a": {
                    "questionId": "SU-9a",
                    "chosenOptionIds": [
                        "ccb31960-a823-519d-a4a9-7348ba4ca6f5"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "4cf86ba0-867d-5f6d-b8d1-3aa5ceddc374"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "3ed9bf02-1389-5b1a-81e0-5dadb1daa205"
                    ]
                },
                "SU-s3.1": {
                    "questionId": "SU-s3.1",
                    "chosenOptionIds": [
                        "82702400-306e-52c0-b320-cc03e2f74660"
                    ]
                }
            }
        },
        {
            "name": "screened user: 50M6136 aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z35MQ3O aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: 73ZW63R aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: 4EG443P aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: REOG402 aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10a": {
                    "questionId": "SU-10a",
                    "chosenOptionIds": [
                        "ed6c59f2-8713-56b1-a4b2-8f1f85b224ed"
                    ]
                },
                "SU-11a": {
                    "questionId": "SU-11a",
                    "chosenOptionIds": [
                        "57899734-07b2-50a2-a5d8-56142b5feb92"
                    ]
                },
                "SU-1a": {
                    "questionId": "SU-1a",
                    "chosenOptionIds": [
                        "dc9dd01a-e6c0-58c8-9b8f-b3315d6a689c"
                    ]
                },
                "SU-2a": {
                    "questionId": "SU-2a",
                    "chosenOptionIds": [
                        "6d761e88-087b-5887-9e08-7720347f7b48"
                    ]
                },
                "SU-3a": {
                    "questionId": "SU-3a",
                    "chosenOptionIds": [
                        "a3720a21-cf5f-5c57-9dde-8cb54605246f"
                    ]
                },
                "SU-4a": {
                    "questionId": "SU-4a",
                    "chosenOptionIds": [
                        "ede457f4-731b-56bd-b7b7-58d93ecdcacd"
                    ]
                },
                "SU-5a": {
                    "questionId": "SU-5a",
                    "chosenOptionIds": [
                        "7ec23bf3-d08e-58e1-a88a-9677b0b85ec8"
                    ]
                },
                "SU-6a": {
                    "questionId": "SU-6a",
                    "chosenOptionIds": [
                        "a16682f6-84c6-5bdd-9553-eafb92701f79"
                    ]
                },
                "SU-7a": {
                    "questionId": "SU-7a",
                    "chosenOptionIds": [
                        "698ebf04-9eee-5b77-97f3-dd84b14ac94a"
                    ]
                },
                "SU-8a": {
                    "questionId": "SU-8a",
                    "chosenOptionIds": [
                        "38ede561-e889-51b7-baa3-51217ea1552e"
                    ]
                },
                "SU-9a": {
                    "questionId": "SU-9a",
                    "chosenOptionIds": [
                        "ccb31960-a823-519d-a4a9-7348ba4ca6f5"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "3ed9bf02-1389-5b1a-81e0-5dadb1daa205"
                    ]
                },
                "SU-s3.1": {
                    "questionId": "SU-s3.1",
                    "chosenOptionIds": [
                        "28f722a5-8507-556b-abe2-8413d34679e4"
                    ]
                }
            }
        },
        {
            "name": "screened user: J0V6N3L aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10a": {
                    "questionId": "SU-10a",
                    "chosenOptionIds": [
                        "ed6c59f2-8713-56b1-a4b2-8f1f85b224ed"
                    ]
                },
                "SU-11a": {
                    "questionId": "SU-11a",
                    "chosenOptionIds": [
                        "57899734-07b2-50a2-a5d8-56142b5feb92"
                    ]
                },
                "SU-1a": {
                    "questionId": "SU-1a",
                    "chosenOptionIds": [
                        "dc9dd01a-e6c0-58c8-9b8f-b3315d6a689c"
                    ]
                },
                "SU-2a": {
                    "questionId": "SU-2a",
                    "chosenOptionIds": [
                        "6d761e88-087b-5887-9e08-7720347f7b48"
                    ]
                },
                "SU-3a": {
                    "questionId": "SU-3a",
                    "chosenOptionIds": [
                        "a3720a21-cf5f-5c57-9dde-8cb54605246f"
                    ]
                },
                "SU-4a": {
                    "questionId": "SU-4a",
                    "chosenOptionIds": [
                        "ede457f4-731b-56bd-b7b7-58d93ecdcacd"
                    ]
                },
                "SU-5a": {
                    "questionId": "SU-5a",
                    "chosenOptionIds": [
                        "7ec23bf3-d08e-58e1-a88a-9677b0b85ec8"
                    ]
                },
                "SU-6a": {
                    "questionId": "SU-6a",
                    "chosenOptionIds": [
                        "a16682f6-84c6-5bdd-9553-eafb92701f79"
                    ]
                },
                "SU-7a": {
                    "questionId": "SU-7a",
                    "chosenOptionIds": [
                        "698ebf04-9eee-5b77-97f3-dd84b14ac94a"
                    ]
                },
                "SU-8a": {
                    "questionId": "SU-8a",
                    "chosenOptionIds": [
                        "38ede561-e889-51b7-baa3-51217ea1552e"
                    ]
                },
                "SU-9a": {
                    "questionId": "SU-9a",
                    "chosenOptionIds": [
                        "ccb31960-a823-519d-a4a9-7348ba4ca6f5"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f0bf8997-4579-5872-82e1-1400b895417b",
                        "18e8ad9b-e9a7-5311-8a6f-e9e64c5997fe",
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280",
                        "4cf86ba0-867d-5f6d-b8d1-3aa5ceddc374"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "3ed9bf02-1389-5b1a-81e0-5dadb1daa205"
                    ]
                },
                "SU-s3.1": {
                    "questionId": "SU-s3.1",
                    "chosenOptionIds": [
                        "82702400-306e-52c0-b320-cc03e2f74660",
                        "6492c387-163d-5eb6-a22a-ae666137625e",
                        "28f722a5-8507-556b-abe2-8413d34679e4"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z3R6O0O aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z3R680O aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: R047J04 aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10b": {
                    "questionId": "SU-10b",
                    "chosenOptionIds": [
                        "fe352b2c-eff2-5046-8413-d86aa9192485"
                    ]
                },
                "SU-11b": {
                    "questionId": "SU-11b",
                    "chosenOptionIds": [
                        "1c65112c-5105-5069-a400-b16d985d1bdf"
                    ]
                },
                "SU-12b": {
                    "questionId": "SU-12b",
                    "chosenOptionIds": [
                        "cc068b90-c986-5049-9322-7b8b0fad4143"
                    ]
                },
                "SU-1b": {
                    "questionId": "SU-1b",
                    "freeValue": 5475
                },
                "SU-2b": {
                    "questionId": "SU-2b",
                    "chosenOptionIds": [
                        "69f7eb47-cf61-5bc3-b485-494de2684114"
                    ]
                },
                "SU-3b": {
                    "questionId": "SU-3b",
                    "chosenOptionIds": [
                        "48eb47f4-b5d0-5ca4-ba6a-5f811f32d488"
                    ]
                },
                "SU-4b": {
                    "questionId": "SU-4b",
                    "chosenOptionIds": [
                        "5af10404-91e9-55e5-a3a5-5b0525ebf15c"
                    ]
                },
                "SU-5b": {
                    "questionId": "SU-5b",
                    "chosenOptionIds": [
                        "a6d3f751-7a06-5eb4-97b5-70831bdb0e3d"
                    ]
                },
                "SU-6b": {
                    "questionId": "SU-6b",
                    "chosenOptionIds": [
                        "e6d0d639-7af5-5de3-9355-51cd24b425c5"
                    ]
                },
                "SU-7b": {
                    "questionId": "SU-7b",
                    "chosenOptionIds": [
                        "fb9594cf-71a8-580c-80d0-18dd9696d5bc"
                    ]
                },
                "SU-8b": {
                    "questionId": "SU-8b",
                    "chosenOptionIds": [
                        "a7206832-dc04-531b-bb17-18b8132ec246"
                    ]
                },
                "SU-9b": {
                    "questionId": "SU-9b",
                    "chosenOptionIds": [
                        "70dd7443-067d-5db4-bbfd-32f5c623fe8a"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f0bf8997-4579-5872-82e1-1400b895417b",
                        "4cf86ba0-867d-5f6d-b8d1-3aa5ceddc374",
                        "18e8ad9b-e9a7-5311-8a6f-e9e64c5997fe",
                        "4f2c6cea-7f4c-55ca-b952-334af926e7b1",
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "67d6ef3c-b5a8-5230-b556-3bc42e2e5179"
                    ]
                }
            }
        },
        {
            "name": "screened user: O3PR70W aspect: sbs_ce should be positive",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "SU-10a": {
                    "questionId": "SU-10a",
                    "chosenOptionIds": [
                        "ed6c59f2-8713-56b1-a4b2-8f1f85b224ed"
                    ]
                },
                "SU-11a": {
                    "questionId": "SU-11a",
                    "chosenOptionIds": [
                        "57899734-07b2-50a2-a5d8-56142b5feb92"
                    ]
                },
                "SU-1a": {
                    "questionId": "SU-1a",
                    "chosenOptionIds": [
                        "22270598-ff5e-5785-a9a3-9bbed0fd9751"
                    ]
                },
                "SU-2a": {
                    "questionId": "SU-2a",
                    "chosenOptionIds": [
                        "61e46908-594f-5831-b709-767c60df9f2b"
                    ]
                },
                "SU-3a": {
                    "questionId": "SU-3a",
                    "chosenOptionIds": [
                        "a3720a21-cf5f-5c57-9dde-8cb54605246f"
                    ]
                },
                "SU-4a": {
                    "questionId": "SU-4a",
                    "chosenOptionIds": [
                        "ede457f4-731b-56bd-b7b7-58d93ecdcacd"
                    ]
                },
                "SU-5a": {
                    "questionId": "SU-5a",
                    "chosenOptionIds": [
                        "e990acdb-4f26-5f2a-9794-2949aca56fb2"
                    ]
                },
                "SU-6a": {
                    "questionId": "SU-6a",
                    "chosenOptionIds": [
                        "a16682f6-84c6-5bdd-9553-eafb92701f79"
                    ]
                },
                "SU-7a": {
                    "questionId": "SU-7a",
                    "chosenOptionIds": [
                        "698ebf04-9eee-5b77-97f3-dd84b14ac94a"
                    ]
                },
                "SU-8a": {
                    "questionId": "SU-8a",
                    "chosenOptionIds": [
                        "38ede561-e889-51b7-baa3-51217ea1552e"
                    ]
                },
                "SU-9a": {
                    "questionId": "SU-9a",
                    "chosenOptionIds": [
                        "ccb31960-a823-519d-a4a9-7348ba4ca6f5"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f0bf8997-4579-5872-82e1-1400b895417b",
                        "4cf86ba0-867d-5f6d-b8d1-3aa5ceddc374",
                        "e58d41b9-ba61-5862-88ea-44bbd63946cb",
                        "18e8ad9b-e9a7-5311-8a6f-e9e64c5997fe",
                        "4f2c6cea-7f4c-55ca-b952-334af926e7b1",
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280",
                        "6248c04e-ea14-5b3e-8821-7cdbe8f84be3",
                        "36c77f4a-59a6-588e-8346-b190d1491a17"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "3ed9bf02-1389-5b1a-81e0-5dadb1daa205"
                    ]
                },
                "SU-s3.1": {
                    "questionId": "SU-s3.1",
                    "chosenOptionIds": [
                        "82702400-306e-52c0-b320-cc03e2f74660",
                        "c433cd2b-8136-5f70-abcc-7e0358b27250",
                        "28f722a5-8507-556b-abe2-8413d34679e4",
                        "5e6a1288-43f9-550c-ae2a-982973f4e551"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z35PN3O aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: ME87V3W aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10b": {
                    "questionId": "SU-10b",
                    "chosenOptionIds": [
                        "eb7acd72-f4fa-52a4-b53f-db24e7756bbf"
                    ]
                },
                "SU-11b": {
                    "questionId": "SU-11b",
                    "chosenOptionIds": [
                        "1c65112c-5105-5069-a400-b16d985d1bdf"
                    ]
                },
                "SU-12b": {
                    "questionId": "SU-12b",
                    "chosenOptionIds": [
                        "cc068b90-c986-5049-9322-7b8b0fad4143"
                    ]
                },
                "SU-1b": {
                    "questionId": "SU-1b",
                    "freeValue": 730
                },
                "SU-2b": {
                    "questionId": "SU-2b",
                    "chosenOptionIds": [
                        "4678ad6e-17f5-504f-84d5-d7e4bd032e1f"
                    ]
                },
                "SU-3b": {
                    "questionId": "SU-3b",
                    "chosenOptionIds": [
                        "97837883-2c07-58ae-87ab-970f4a8e16d7"
                    ]
                },
                "SU-4b": {
                    "questionId": "SU-4b",
                    "chosenOptionIds": [
                        "a55e2d73-6bb1-5ddf-b7f0-be44aa25ea75"
                    ]
                },
                "SU-5b": {
                    "questionId": "SU-5b",
                    "chosenOptionIds": [
                        "6c0b7b5f-bac4-5d59-884f-ca8f482f11d4"
                    ]
                },
                "SU-6b": {
                    "questionId": "SU-6b",
                    "chosenOptionIds": [
                        "e6d0d639-7af5-5de3-9355-51cd24b425c5"
                    ]
                },
                "SU-7b": {
                    "questionId": "SU-7b",
                    "chosenOptionIds": [
                        "fb9594cf-71a8-580c-80d0-18dd9696d5bc"
                    ]
                },
                "SU-8b": {
                    "questionId": "SU-8b",
                    "chosenOptionIds": [
                        "a7206832-dc04-531b-bb17-18b8132ec246"
                    ]
                },
                "SU-9b": {
                    "questionId": "SU-9b",
                    "chosenOptionIds": [
                        "70dd7443-067d-5db4-bbfd-32f5c623fe8a"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f0bf8997-4579-5872-82e1-1400b895417b",
                        "4cf86ba0-867d-5f6d-b8d1-3aa5ceddc374",
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280",
                        "18e8ad9b-e9a7-5311-8a6f-e9e64c5997fe"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "67d6ef3c-b5a8-5230-b556-3bc42e2e5179"
                    ]
                }
            }
        },
        {
            "name": "screened user: 2E17NED aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: PE61J0L aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: 50MVM06 aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: J3JK4EY aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: 7EW4D08 aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z3RLO3O aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: J3JZREY aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10b": {
                    "questionId": "SU-10b",
                    "chosenOptionIds": [
                        "fe352b2c-eff2-5046-8413-d86aa9192485"
                    ]
                },
                "SU-11b": {
                    "questionId": "SU-11b",
                    "chosenOptionIds": [
                        "1c65112c-5105-5069-a400-b16d985d1bdf"
                    ]
                },
                "SU-12b": {
                    "questionId": "SU-12b",
                    "chosenOptionIds": [
                        "cc068b90-c986-5049-9322-7b8b0fad4143"
                    ]
                },
                "SU-1b": {
                    "questionId": "SU-1b",
                    "freeValue": 9125
                },
                "SU-2b": {
                    "questionId": "SU-2b",
                    "chosenOptionIds": [
                        "f6b6411b-02e6-531d-b2ed-67d6dfacfd96"
                    ]
                },
                "SU-3b": {
                    "questionId": "SU-3b",
                    "chosenOptionIds": [
                        "48eb47f4-b5d0-5ca4-ba6a-5f811f32d488"
                    ]
                },
                "SU-4b": {
                    "questionId": "SU-4b",
                    "chosenOptionIds": [
                        "5af10404-91e9-55e5-a3a5-5b0525ebf15c"
                    ]
                },
                "SU-5b": {
                    "questionId": "SU-5b",
                    "chosenOptionIds": [
                        "a6d3f751-7a06-5eb4-97b5-70831bdb0e3d"
                    ]
                },
                "SU-6b": {
                    "questionId": "SU-6b",
                    "chosenOptionIds": [
                        "e6d0d639-7af5-5de3-9355-51cd24b425c5"
                    ]
                },
                "SU-7b": {
                    "questionId": "SU-7b",
                    "chosenOptionIds": [
                        "fb9594cf-71a8-580c-80d0-18dd9696d5bc"
                    ]
                },
                "SU-8b": {
                    "questionId": "SU-8b",
                    "chosenOptionIds": [
                        "a7206832-dc04-531b-bb17-18b8132ec246"
                    ]
                },
                "SU-9b": {
                    "questionId": "SU-9b",
                    "chosenOptionIds": [
                        "70dd7443-067d-5db4-bbfd-32f5c623fe8a"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "67d6ef3c-b5a8-5230-b556-3bc42e2e5179"
                    ]
                }
            }
        },
        {
            "name": "screened user: XE7WX3L aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10b": {
                    "questionId": "SU-10b",
                    "chosenOptionIds": [
                        "fe352b2c-eff2-5046-8413-d86aa9192485"
                    ]
                },
                "SU-11b": {
                    "questionId": "SU-11b",
                    "chosenOptionIds": [
                        "1c65112c-5105-5069-a400-b16d985d1bdf"
                    ]
                },
                "SU-12b": {
                    "questionId": "SU-12b",
                    "chosenOptionIds": [
                        "7debcb22-6637-5de5-9421-89be645c3b96"
                    ]
                },
                "SU-1b": {
                    "questionId": "SU-1b",
                    "freeValue": 1460
                },
                "SU-2b": {
                    "questionId": "SU-2b",
                    "chosenOptionIds": [
                        "28fd19f5-e24d-5aa5-ab06-4e63101e3ef7"
                    ]
                },
                "SU-3b": {
                    "questionId": "SU-3b",
                    "chosenOptionIds": [
                        "48eb47f4-b5d0-5ca4-ba6a-5f811f32d488"
                    ]
                },
                "SU-4b": {
                    "questionId": "SU-4b",
                    "chosenOptionIds": [
                        "5af10404-91e9-55e5-a3a5-5b0525ebf15c"
                    ]
                },
                "SU-5b": {
                    "questionId": "SU-5b",
                    "chosenOptionIds": [
                        "a6d3f751-7a06-5eb4-97b5-70831bdb0e3d"
                    ]
                },
                "SU-6b": {
                    "questionId": "SU-6b",
                    "chosenOptionIds": [
                        "e6d0d639-7af5-5de3-9355-51cd24b425c5"
                    ]
                },
                "SU-7b": {
                    "questionId": "SU-7b",
                    "chosenOptionIds": [
                        "b6a26449-f023-5a8a-a4c9-a6c625f0f366"
                    ]
                },
                "SU-8b": {
                    "questionId": "SU-8b",
                    "chosenOptionIds": [
                        "a7206832-dc04-531b-bb17-18b8132ec246"
                    ]
                },
                "SU-9b": {
                    "questionId": "SU-9b",
                    "chosenOptionIds": [
                        "70dd7443-067d-5db4-bbfd-32f5c623fe8a"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "67d6ef3c-b5a8-5230-b556-3bc42e2e5179"
                    ]
                }
            }
        },
        {
            "name": "screened user: PE64M0L aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10b": {
                    "questionId": "SU-10b",
                    "chosenOptionIds": [
                        "fe352b2c-eff2-5046-8413-d86aa9192485"
                    ]
                },
                "SU-11b": {
                    "questionId": "SU-11b",
                    "chosenOptionIds": [
                        "1c65112c-5105-5069-a400-b16d985d1bdf"
                    ]
                },
                "SU-12b": {
                    "questionId": "SU-12b",
                    "chosenOptionIds": [
                        "7debcb22-6637-5de5-9421-89be645c3b96"
                    ]
                },
                "SU-1b": {
                    "questionId": "SU-1b",
                    "freeValue": 365
                },
                "SU-2b": {
                    "questionId": "SU-2b",
                    "chosenOptionIds": [
                        "f6b6411b-02e6-531d-b2ed-67d6dfacfd96"
                    ]
                },
                "SU-3b": {
                    "questionId": "SU-3b",
                    "chosenOptionIds": [
                        "48eb47f4-b5d0-5ca4-ba6a-5f811f32d488"
                    ]
                },
                "SU-4b": {
                    "questionId": "SU-4b",
                    "chosenOptionIds": [
                        "5af10404-91e9-55e5-a3a5-5b0525ebf15c"
                    ]
                },
                "SU-5b": {
                    "questionId": "SU-5b",
                    "chosenOptionIds": [
                        "6e890ffb-ea8c-50f4-87d8-0b9fcb05e77c"
                    ]
                },
                "SU-6b": {
                    "questionId": "SU-6b",
                    "chosenOptionIds": [
                        "e6d0d639-7af5-5de3-9355-51cd24b425c5"
                    ]
                },
                "SU-7b": {
                    "questionId": "SU-7b",
                    "chosenOptionIds": [
                        "fb9594cf-71a8-580c-80d0-18dd9696d5bc"
                    ]
                },
                "SU-8b": {
                    "questionId": "SU-8b",
                    "chosenOptionIds": [
                        "a7206832-dc04-531b-bb17-18b8132ec246"
                    ]
                },
                "SU-9b": {
                    "questionId": "SU-9b",
                    "chosenOptionIds": [
                        "70dd7443-067d-5db4-bbfd-32f5c623fe8a"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "36c77f4a-59a6-588e-8346-b190d1491a17"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "67d6ef3c-b5a8-5230-b556-3bc42e2e5179"
                    ]
                }
            }
        },
        {
            "name": "screened user: O3P263W aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10b": {
                    "questionId": "SU-10b",
                    "chosenOptionIds": [
                        "fe352b2c-eff2-5046-8413-d86aa9192485"
                    ]
                },
                "SU-11b": {
                    "questionId": "SU-11b",
                    "chosenOptionIds": [
                        "1c65112c-5105-5069-a400-b16d985d1bdf"
                    ]
                },
                "SU-12b": {
                    "questionId": "SU-12b",
                    "chosenOptionIds": [
                        "cc068b90-c986-5049-9322-7b8b0fad4143"
                    ]
                },
                "SU-1b": {
                    "questionId": "SU-1b",
                    "freeValue": 10950
                },
                "SU-2b": {
                    "questionId": "SU-2b",
                    "chosenOptionIds": [
                        "4678ad6e-17f5-504f-84d5-d7e4bd032e1f"
                    ]
                },
                "SU-3b": {
                    "questionId": "SU-3b",
                    "chosenOptionIds": [
                        "48eb47f4-b5d0-5ca4-ba6a-5f811f32d488"
                    ]
                },
                "SU-4b": {
                    "questionId": "SU-4b",
                    "chosenOptionIds": [
                        "5af10404-91e9-55e5-a3a5-5b0525ebf15c"
                    ]
                },
                "SU-5b": {
                    "questionId": "SU-5b",
                    "chosenOptionIds": [
                        "a6d3f751-7a06-5eb4-97b5-70831bdb0e3d"
                    ]
                },
                "SU-6b": {
                    "questionId": "SU-6b",
                    "chosenOptionIds": [
                        "e6d0d639-7af5-5de3-9355-51cd24b425c5"
                    ]
                },
                "SU-7b": {
                    "questionId": "SU-7b",
                    "chosenOptionIds": [
                        "b6a26449-f023-5a8a-a4c9-a6c625f0f366"
                    ]
                },
                "SU-8b": {
                    "questionId": "SU-8b",
                    "chosenOptionIds": [
                        "a7206832-dc04-531b-bb17-18b8132ec246"
                    ]
                },
                "SU-9b": {
                    "questionId": "SU-9b",
                    "chosenOptionIds": [
                        "70dd7443-067d-5db4-bbfd-32f5c623fe8a"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280",
                        "f0bf8997-4579-5872-82e1-1400b895417b"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "67d6ef3c-b5a8-5230-b556-3bc42e2e5179"
                    ]
                }
            }
        },
        {
            "name": "screened user: NELXPEV aspect: sbs_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10b": {
                    "questionId": "SU-10b",
                    "chosenOptionIds": [
                        "fe352b2c-eff2-5046-8413-d86aa9192485"
                    ]
                },
                "SU-11b": {
                    "questionId": "SU-11b",
                    "chosenOptionIds": [
                        "1c65112c-5105-5069-a400-b16d985d1bdf"
                    ]
                },
                "SU-12b": {
                    "questionId": "SU-12b",
                    "chosenOptionIds": [
                        "cc068b90-c986-5049-9322-7b8b0fad4143"
                    ]
                },
                "SU-1b": {
                    "questionId": "SU-1b",
                    "freeValue": 1460
                },
                "SU-2b": {
                    "questionId": "SU-2b",
                    "chosenOptionIds": [
                        "f6b6411b-02e6-531d-b2ed-67d6dfacfd96"
                    ]
                },
                "SU-3b": {
                    "questionId": "SU-3b",
                    "chosenOptionIds": [
                        "48eb47f4-b5d0-5ca4-ba6a-5f811f32d488"
                    ]
                },
                "SU-4b": {
                    "questionId": "SU-4b",
                    "chosenOptionIds": [
                        "5af10404-91e9-55e5-a3a5-5b0525ebf15c"
                    ]
                },
                "SU-5b": {
                    "questionId": "SU-5b",
                    "chosenOptionIds": [
                        "a6d3f751-7a06-5eb4-97b5-70831bdb0e3d"
                    ]
                },
                "SU-6b": {
                    "questionId": "SU-6b",
                    "chosenOptionIds": [
                        "e6d0d639-7af5-5de3-9355-51cd24b425c5"
                    ]
                },
                "SU-7b": {
                    "questionId": "SU-7b",
                    "chosenOptionIds": [
                        "fb9594cf-71a8-580c-80d0-18dd9696d5bc"
                    ]
                },
                "SU-8b": {
                    "questionId": "SU-8b",
                    "chosenOptionIds": [
                        "a7206832-dc04-531b-bb17-18b8132ec246"
                    ]
                },
                "SU-9b": {
                    "questionId": "SU-9b",
                    "chosenOptionIds": [
                        "70dd7443-067d-5db4-bbfd-32f5c623fe8a"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f0bf8997-4579-5872-82e1-1400b895417b",
                        "4cf86ba0-867d-5f6d-b8d1-3aa5ceddc374",
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "67d6ef3c-b5a8-5230-b556-3bc42e2e5179"
                    ]
                }
            }
        }
    ],
    "sbs_pe": [
        {
            "name": "screened user: Z3RPNEO aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: NEL6L3V aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: K39ZL3L aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10b": {
                    "questionId": "SU-10b",
                    "chosenOptionIds": [
                        "fe352b2c-eff2-5046-8413-d86aa9192485"
                    ]
                },
                "SU-11b": {
                    "questionId": "SU-11b",
                    "chosenOptionIds": [
                        "1c65112c-5105-5069-a400-b16d985d1bdf"
                    ]
                },
                "SU-12b": {
                    "questionId": "SU-12b",
                    "chosenOptionIds": [
                        "cc068b90-c986-5049-9322-7b8b0fad4143"
                    ]
                },
                "SU-1b": {
                    "questionId": "SU-1b",
                    "freeValue": 1825
                },
                "SU-2b": {
                    "questionId": "SU-2b",
                    "chosenOptionIds": [
                        "69f7eb47-cf61-5bc3-b485-494de2684114"
                    ]
                },
                "SU-3b": {
                    "questionId": "SU-3b",
                    "chosenOptionIds": [
                        "48eb47f4-b5d0-5ca4-ba6a-5f811f32d488"
                    ]
                },
                "SU-4b": {
                    "questionId": "SU-4b",
                    "chosenOptionIds": [
                        "a55e2d73-6bb1-5ddf-b7f0-be44aa25ea75"
                    ]
                },
                "SU-5b": {
                    "questionId": "SU-5b",
                    "chosenOptionIds": [
                        "a6d3f751-7a06-5eb4-97b5-70831bdb0e3d"
                    ]
                },
                "SU-6b": {
                    "questionId": "SU-6b",
                    "chosenOptionIds": [
                        "e6d0d639-7af5-5de3-9355-51cd24b425c5"
                    ]
                },
                "SU-7b": {
                    "questionId": "SU-7b",
                    "chosenOptionIds": [
                        "fb9594cf-71a8-580c-80d0-18dd9696d5bc"
                    ]
                },
                "SU-8b": {
                    "questionId": "SU-8b",
                    "chosenOptionIds": [
                        "a7206832-dc04-531b-bb17-18b8132ec246"
                    ]
                },
                "SU-9b": {
                    "questionId": "SU-9b",
                    "chosenOptionIds": [
                        "70dd7443-067d-5db4-bbfd-32f5c623fe8a"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "67d6ef3c-b5a8-5230-b556-3bc42e2e5179"
                    ]
                }
            }
        },
        {
            "name": "screened user: 6PE670L aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10a": {
                    "questionId": "SU-10a",
                    "chosenOptionIds": [
                        "ed6c59f2-8713-56b1-a4b2-8f1f85b224ed"
                    ]
                },
                "SU-11a": {
                    "questionId": "SU-11a",
                    "chosenOptionIds": [
                        "57899734-07b2-50a2-a5d8-56142b5feb92"
                    ]
                },
                "SU-1a": {
                    "questionId": "SU-1a",
                    "chosenOptionIds": [
                        "1a337ce3-5ab7-5773-9fe0-f0d1c23ed96c"
                    ]
                },
                "SU-2a": {
                    "questionId": "SU-2a",
                    "chosenOptionIds": [
                        "6d761e88-087b-5887-9e08-7720347f7b48"
                    ]
                },
                "SU-3a": {
                    "questionId": "SU-3a",
                    "chosenOptionIds": [
                        "8b9ec309-bccd-5e75-82c2-0b9f6bbf25fd"
                    ]
                },
                "SU-4a": {
                    "questionId": "SU-4a",
                    "chosenOptionIds": [
                        "ede457f4-731b-56bd-b7b7-58d93ecdcacd"
                    ]
                },
                "SU-5a": {
                    "questionId": "SU-5a",
                    "chosenOptionIds": [
                        "7ec23bf3-d08e-58e1-a88a-9677b0b85ec8"
                    ]
                },
                "SU-6a": {
                    "questionId": "SU-6a",
                    "chosenOptionIds": [
                        "a16682f6-84c6-5bdd-9553-eafb92701f79"
                    ]
                },
                "SU-7a": {
                    "questionId": "SU-7a",
                    "chosenOptionIds": [
                        "698ebf04-9eee-5b77-97f3-dd84b14ac94a"
                    ]
                },
                "SU-8a": {
                    "questionId": "SU-8a",
                    "chosenOptionIds": [
                        "38ede561-e889-51b7-baa3-51217ea1552e"
                    ]
                },
                "SU-9a": {
                    "questionId": "SU-9a",
                    "chosenOptionIds": [
                        "ccb31960-a823-519d-a4a9-7348ba4ca6f5"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "36c77f4a-59a6-588e-8346-b190d1491a17"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "3ed9bf02-1389-5b1a-81e0-5dadb1daa205"
                    ]
                },
                "SU-s3.1": {
                    "questionId": "SU-s3.1",
                    "chosenOptionIds": [
                        "95ba4a6b-7026-5c10-98e9-07370c2b605a"
                    ]
                }
            }
        },
        {
            "name": "screened user: 50MRME6 aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10a": {
                    "questionId": "SU-10a",
                    "chosenOptionIds": [
                        "ed6c59f2-8713-56b1-a4b2-8f1f85b224ed"
                    ]
                },
                "SU-11a": {
                    "questionId": "SU-11a",
                    "chosenOptionIds": [
                        "57899734-07b2-50a2-a5d8-56142b5feb92"
                    ]
                },
                "SU-1a": {
                    "questionId": "SU-1a",
                    "chosenOptionIds": [
                        "22270598-ff5e-5785-a9a3-9bbed0fd9751"
                    ]
                },
                "SU-2a": {
                    "questionId": "SU-2a",
                    "chosenOptionIds": [
                        "6d761e88-087b-5887-9e08-7720347f7b48"
                    ]
                },
                "SU-3a": {
                    "questionId": "SU-3a",
                    "chosenOptionIds": [
                        "a3720a21-cf5f-5c57-9dde-8cb54605246f"
                    ]
                },
                "SU-4a": {
                    "questionId": "SU-4a",
                    "chosenOptionIds": [
                        "ede457f4-731b-56bd-b7b7-58d93ecdcacd"
                    ]
                },
                "SU-5a": {
                    "questionId": "SU-5a",
                    "chosenOptionIds": [
                        "7ec23bf3-d08e-58e1-a88a-9677b0b85ec8"
                    ]
                },
                "SU-6a": {
                    "questionId": "SU-6a",
                    "chosenOptionIds": [
                        "a16682f6-84c6-5bdd-9553-eafb92701f79"
                    ]
                },
                "SU-7a": {
                    "questionId": "SU-7a",
                    "chosenOptionIds": [
                        "698ebf04-9eee-5b77-97f3-dd84b14ac94a"
                    ]
                },
                "SU-8a": {
                    "questionId": "SU-8a",
                    "chosenOptionIds": [
                        "38ede561-e889-51b7-baa3-51217ea1552e"
                    ]
                },
                "SU-9a": {
                    "questionId": "SU-9a",
                    "chosenOptionIds": [
                        "ccb31960-a823-519d-a4a9-7348ba4ca6f5"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "3ed9bf02-1389-5b1a-81e0-5dadb1daa205"
                    ]
                },
                "SU-s3.1": {
                    "questionId": "SU-s3.1",
                    "chosenOptionIds": [
                        "28f722a5-8507-556b-abe2-8413d34679e4"
                    ]
                }
            }
        },
        {
            "name": "screened user: O3PMG3W aspect: sbs_pe should be positive",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "SU-10b": {
                    "questionId": "SU-10b",
                    "chosenOptionIds": [
                        "eb7acd72-f4fa-52a4-b53f-db24e7756bbf"
                    ]
                },
                "SU-11b": {
                    "questionId": "SU-11b",
                    "chosenOptionIds": [
                        "df81db2f-9fe7-5b09-be87-64780dd81a25"
                    ]
                },
                "SU-12b": {
                    "questionId": "SU-12b",
                    "chosenOptionIds": [
                        "7debcb22-6637-5de5-9421-89be645c3b96"
                    ]
                },
                "SU-1b": {
                    "questionId": "SU-1b",
                    "freeValue": 5475
                },
                "SU-2b": {
                    "questionId": "SU-2b",
                    "chosenOptionIds": [
                        "28fd19f5-e24d-5aa5-ab06-4e63101e3ef7"
                    ]
                },
                "SU-3b": {
                    "questionId": "SU-3b",
                    "chosenOptionIds": [
                        "97837883-2c07-58ae-87ab-970f4a8e16d7"
                    ]
                },
                "SU-4b": {
                    "questionId": "SU-4b",
                    "chosenOptionIds": [
                        "5af10404-91e9-55e5-a3a5-5b0525ebf15c"
                    ]
                },
                "SU-5b": {
                    "questionId": "SU-5b",
                    "chosenOptionIds": [
                        "6e890ffb-ea8c-50f4-87d8-0b9fcb05e77c"
                    ]
                },
                "SU-6b": {
                    "questionId": "SU-6b",
                    "chosenOptionIds": [
                        "e6d0d639-7af5-5de3-9355-51cd24b425c5"
                    ]
                },
                "SU-7b": {
                    "questionId": "SU-7b",
                    "chosenOptionIds": [
                        "fb9594cf-71a8-580c-80d0-18dd9696d5bc"
                    ]
                },
                "SU-8b": {
                    "questionId": "SU-8b",
                    "chosenOptionIds": [
                        "32ec1d91-b47c-5754-a656-c6c0f230ace2"
                    ]
                },
                "SU-9b": {
                    "questionId": "SU-9b",
                    "chosenOptionIds": [
                        "70dd7443-067d-5db4-bbfd-32f5c623fe8a"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "4cf86ba0-867d-5f6d-b8d1-3aa5ceddc374",
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "67d6ef3c-b5a8-5230-b556-3bc42e2e5179"
                    ]
                }
            }
        },
        {
            "name": "screened user: Y32D838 aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: XE7OXEL aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10b": {
                    "questionId": "SU-10b",
                    "chosenOptionIds": [
                        "fe352b2c-eff2-5046-8413-d86aa9192485"
                    ]
                },
                "SU-11b": {
                    "questionId": "SU-11b",
                    "chosenOptionIds": [
                        "1c65112c-5105-5069-a400-b16d985d1bdf"
                    ]
                },
                "SU-12b": {
                    "questionId": "SU-12b",
                    "chosenOptionIds": [
                        "cc068b90-c986-5049-9322-7b8b0fad4143"
                    ]
                },
                "SU-1b": {
                    "questionId": "SU-1b",
                    "freeValue": 730
                },
                "SU-2b": {
                    "questionId": "SU-2b",
                    "chosenOptionIds": [
                        "f6b6411b-02e6-531d-b2ed-67d6dfacfd96"
                    ]
                },
                "SU-3b": {
                    "questionId": "SU-3b",
                    "chosenOptionIds": [
                        "48eb47f4-b5d0-5ca4-ba6a-5f811f32d488"
                    ]
                },
                "SU-4b": {
                    "questionId": "SU-4b",
                    "chosenOptionIds": [
                        "a55e2d73-6bb1-5ddf-b7f0-be44aa25ea75"
                    ]
                },
                "SU-5b": {
                    "questionId": "SU-5b",
                    "chosenOptionIds": [
                        "a6d3f751-7a06-5eb4-97b5-70831bdb0e3d"
                    ]
                },
                "SU-6b": {
                    "questionId": "SU-6b",
                    "chosenOptionIds": [
                        "e6d0d639-7af5-5de3-9355-51cd24b425c5"
                    ]
                },
                "SU-7b": {
                    "questionId": "SU-7b",
                    "chosenOptionIds": [
                        "fb9594cf-71a8-580c-80d0-18dd9696d5bc"
                    ]
                },
                "SU-8b": {
                    "questionId": "SU-8b",
                    "chosenOptionIds": [
                        "a7206832-dc04-531b-bb17-18b8132ec246"
                    ]
                },
                "SU-9b": {
                    "questionId": "SU-9b",
                    "chosenOptionIds": [
                        "70dd7443-067d-5db4-bbfd-32f5c623fe8a"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f0bf8997-4579-5872-82e1-1400b895417b",
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "67d6ef3c-b5a8-5230-b556-3bc42e2e5179"
                    ]
                }
            }
        },
        {
            "name": "screened user: 63YX60R aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: 10QV8EP aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: 6EDLX3M aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10b": {
                    "questionId": "SU-10b",
                    "chosenOptionIds": [
                        "fe352b2c-eff2-5046-8413-d86aa9192485"
                    ]
                },
                "SU-11b": {
                    "questionId": "SU-11b",
                    "chosenOptionIds": [
                        "1c65112c-5105-5069-a400-b16d985d1bdf"
                    ]
                },
                "SU-12b": {
                    "questionId": "SU-12b",
                    "chosenOptionIds": [
                        "cc068b90-c986-5049-9322-7b8b0fad4143"
                    ]
                },
                "SU-1b": {
                    "questionId": "SU-1b",
                    "freeValue": 2920
                },
                "SU-2b": {
                    "questionId": "SU-2b",
                    "chosenOptionIds": [
                        "28fd19f5-e24d-5aa5-ab06-4e63101e3ef7"
                    ]
                },
                "SU-3b": {
                    "questionId": "SU-3b",
                    "chosenOptionIds": [
                        "48eb47f4-b5d0-5ca4-ba6a-5f811f32d488"
                    ]
                },
                "SU-4b": {
                    "questionId": "SU-4b",
                    "chosenOptionIds": [
                        "5af10404-91e9-55e5-a3a5-5b0525ebf15c"
                    ]
                },
                "SU-5b": {
                    "questionId": "SU-5b",
                    "chosenOptionIds": [
                        "a6d3f751-7a06-5eb4-97b5-70831bdb0e3d"
                    ]
                },
                "SU-6b": {
                    "questionId": "SU-6b",
                    "chosenOptionIds": [
                        "e6d0d639-7af5-5de3-9355-51cd24b425c5"
                    ]
                },
                "SU-7b": {
                    "questionId": "SU-7b",
                    "chosenOptionIds": [
                        "fb9594cf-71a8-580c-80d0-18dd9696d5bc"
                    ]
                },
                "SU-8b": {
                    "questionId": "SU-8b",
                    "chosenOptionIds": [
                        "a7206832-dc04-531b-bb17-18b8132ec246"
                    ]
                },
                "SU-9b": {
                    "questionId": "SU-9b",
                    "chosenOptionIds": [
                        "70dd7443-067d-5db4-bbfd-32f5c623fe8a"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "67d6ef3c-b5a8-5230-b556-3bc42e2e5179"
                    ]
                }
            }
        },
        {
            "name": "screened user: O0KG5E1 aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: 6ED9M0M aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10a": {
                    "questionId": "SU-10a",
                    "chosenOptionIds": [
                        "ed6c59f2-8713-56b1-a4b2-8f1f85b224ed"
                    ]
                },
                "SU-11a": {
                    "questionId": "SU-11a",
                    "chosenOptionIds": [
                        "57899734-07b2-50a2-a5d8-56142b5feb92"
                    ]
                },
                "SU-1a": {
                    "questionId": "SU-1a",
                    "chosenOptionIds": [
                        "64b85f3c-84e5-5d37-bc42-dab6740c8199"
                    ]
                },
                "SU-2a": {
                    "questionId": "SU-2a",
                    "chosenOptionIds": [
                        "6d761e88-087b-5887-9e08-7720347f7b48"
                    ]
                },
                "SU-3a": {
                    "questionId": "SU-3a",
                    "chosenOptionIds": [
                        "cba96b30-e2c3-5d5b-80a8-c18a79809f16"
                    ]
                },
                "SU-4a": {
                    "questionId": "SU-4a",
                    "chosenOptionIds": [
                        "ede457f4-731b-56bd-b7b7-58d93ecdcacd"
                    ]
                },
                "SU-5a": {
                    "questionId": "SU-5a",
                    "chosenOptionIds": [
                        "7ec23bf3-d08e-58e1-a88a-9677b0b85ec8"
                    ]
                },
                "SU-6a": {
                    "questionId": "SU-6a",
                    "chosenOptionIds": [
                        "a16682f6-84c6-5bdd-9553-eafb92701f79"
                    ]
                },
                "SU-7a": {
                    "questionId": "SU-7a",
                    "chosenOptionIds": [
                        "698ebf04-9eee-5b77-97f3-dd84b14ac94a"
                    ]
                },
                "SU-8a": {
                    "questionId": "SU-8a",
                    "chosenOptionIds": [
                        "38ede561-e889-51b7-baa3-51217ea1552e"
                    ]
                },
                "SU-9a": {
                    "questionId": "SU-9a",
                    "chosenOptionIds": [
                        "ccb31960-a823-519d-a4a9-7348ba4ca6f5"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280",
                        "4f2c6cea-7f4c-55ca-b952-334af926e7b1",
                        "18e8ad9b-e9a7-5311-8a6f-e9e64c5997fe",
                        "f0bf8997-4579-5872-82e1-1400b895417b"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "3ed9bf02-1389-5b1a-81e0-5dadb1daa205"
                    ]
                },
                "SU-s3.1": {
                    "questionId": "SU-s3.1",
                    "chosenOptionIds": [
                        "28f722a5-8507-556b-abe2-8413d34679e4"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z3R2NEO aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: NELRPEV aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: 73ZQN3R aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: REO9232 aspect: sbs_pe should be positive",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "SU-10b": {
                    "questionId": "SU-10b",
                    "chosenOptionIds": [
                        "fe352b2c-eff2-5046-8413-d86aa9192485"
                    ]
                },
                "SU-11b": {
                    "questionId": "SU-11b",
                    "chosenOptionIds": [
                        "1c65112c-5105-5069-a400-b16d985d1bdf"
                    ]
                },
                "SU-12b": {
                    "questionId": "SU-12b",
                    "chosenOptionIds": [
                        "7debcb22-6637-5de5-9421-89be645c3b96"
                    ]
                },
                "SU-1b": {
                    "questionId": "SU-1b",
                    "freeValue": 365
                },
                "SU-2b": {
                    "questionId": "SU-2b",
                    "chosenOptionIds": [
                        "69f7eb47-cf61-5bc3-b485-494de2684114"
                    ]
                },
                "SU-3b": {
                    "questionId": "SU-3b",
                    "chosenOptionIds": [
                        "48eb47f4-b5d0-5ca4-ba6a-5f811f32d488"
                    ]
                },
                "SU-4b": {
                    "questionId": "SU-4b",
                    "chosenOptionIds": [
                        "5af10404-91e9-55e5-a3a5-5b0525ebf15c"
                    ]
                },
                "SU-5b": {
                    "questionId": "SU-5b",
                    "chosenOptionIds": [
                        "bcac9437-7015-5fe5-9ee4-fb99cf0d5f17"
                    ]
                },
                "SU-6b": {
                    "questionId": "SU-6b",
                    "chosenOptionIds": [
                        "e6d0d639-7af5-5de3-9355-51cd24b425c5"
                    ]
                },
                "SU-7b": {
                    "questionId": "SU-7b",
                    "chosenOptionIds": [
                        "fb9594cf-71a8-580c-80d0-18dd9696d5bc"
                    ]
                },
                "SU-8b": {
                    "questionId": "SU-8b",
                    "chosenOptionIds": [
                        "a7206832-dc04-531b-bb17-18b8132ec246"
                    ]
                },
                "SU-9b": {
                    "questionId": "SU-9b",
                    "chosenOptionIds": [
                        "70dd7443-067d-5db4-bbfd-32f5c623fe8a"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f0bf8997-4579-5872-82e1-1400b895417b",
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "67d6ef3c-b5a8-5230-b556-3bc42e2e5179"
                    ]
                }
            }
        },
        {
            "name": "screened user: O0KZ701 aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10a": {
                    "questionId": "SU-10a",
                    "chosenOptionIds": [
                        "ed6c59f2-8713-56b1-a4b2-8f1f85b224ed"
                    ]
                },
                "SU-11a": {
                    "questionId": "SU-11a",
                    "chosenOptionIds": [
                        "57899734-07b2-50a2-a5d8-56142b5feb92"
                    ]
                },
                "SU-1a": {
                    "questionId": "SU-1a",
                    "chosenOptionIds": [
                        "dc9dd01a-e6c0-58c8-9b8f-b3315d6a689c"
                    ]
                },
                "SU-2a": {
                    "questionId": "SU-2a",
                    "chosenOptionIds": [
                        "6d761e88-087b-5887-9e08-7720347f7b48"
                    ]
                },
                "SU-3a": {
                    "questionId": "SU-3a",
                    "chosenOptionIds": [
                        "a3720a21-cf5f-5c57-9dde-8cb54605246f"
                    ]
                },
                "SU-4a": {
                    "questionId": "SU-4a",
                    "chosenOptionIds": [
                        "ede457f4-731b-56bd-b7b7-58d93ecdcacd"
                    ]
                },
                "SU-5a": {
                    "questionId": "SU-5a",
                    "chosenOptionIds": [
                        "7ec23bf3-d08e-58e1-a88a-9677b0b85ec8"
                    ]
                },
                "SU-6a": {
                    "questionId": "SU-6a",
                    "chosenOptionIds": [
                        "a16682f6-84c6-5bdd-9553-eafb92701f79"
                    ]
                },
                "SU-7a": {
                    "questionId": "SU-7a",
                    "chosenOptionIds": [
                        "85e98c24-bf79-526d-8945-070f551c81c7"
                    ]
                },
                "SU-8a": {
                    "questionId": "SU-8a",
                    "chosenOptionIds": [
                        "4ade59f6-9242-595c-9d22-1c907b3dcbd6"
                    ]
                },
                "SU-9a": {
                    "questionId": "SU-9a",
                    "chosenOptionIds": [
                        "ccb31960-a823-519d-a4a9-7348ba4ca6f5"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "3ed9bf02-1389-5b1a-81e0-5dadb1daa205"
                    ]
                },
                "SU-s3.1": {
                    "questionId": "SU-s3.1",
                    "chosenOptionIds": [
                        "28f722a5-8507-556b-abe2-8413d34679e4"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0N6P0Q aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: 2E148ED aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10b": {
                    "questionId": "SU-10b",
                    "chosenOptionIds": [
                        "fe352b2c-eff2-5046-8413-d86aa9192485"
                    ]
                },
                "SU-11b": {
                    "questionId": "SU-11b",
                    "chosenOptionIds": [
                        "1c65112c-5105-5069-a400-b16d985d1bdf"
                    ]
                },
                "SU-12b": {
                    "questionId": "SU-12b",
                    "chosenOptionIds": [
                        "cc068b90-c986-5049-9322-7b8b0fad4143"
                    ]
                },
                "SU-1b": {
                    "questionId": "SU-1b",
                    "freeValue": 1825
                },
                "SU-2b": {
                    "questionId": "SU-2b",
                    "chosenOptionIds": [
                        "f6b6411b-02e6-531d-b2ed-67d6dfacfd96"
                    ]
                },
                "SU-3b": {
                    "questionId": "SU-3b",
                    "chosenOptionIds": [
                        "48eb47f4-b5d0-5ca4-ba6a-5f811f32d488"
                    ]
                },
                "SU-4b": {
                    "questionId": "SU-4b",
                    "chosenOptionIds": [
                        "a55e2d73-6bb1-5ddf-b7f0-be44aa25ea75"
                    ]
                },
                "SU-5b": {
                    "questionId": "SU-5b",
                    "chosenOptionIds": [
                        "a6d3f751-7a06-5eb4-97b5-70831bdb0e3d"
                    ]
                },
                "SU-6b": {
                    "questionId": "SU-6b",
                    "chosenOptionIds": [
                        "e6d0d639-7af5-5de3-9355-51cd24b425c5"
                    ]
                },
                "SU-7b": {
                    "questionId": "SU-7b",
                    "chosenOptionIds": [
                        "fb9594cf-71a8-580c-80d0-18dd9696d5bc"
                    ]
                },
                "SU-8b": {
                    "questionId": "SU-8b",
                    "chosenOptionIds": [
                        "a7206832-dc04-531b-bb17-18b8132ec246"
                    ]
                },
                "SU-9b": {
                    "questionId": "SU-9b",
                    "chosenOptionIds": [
                        "70dd7443-067d-5db4-bbfd-32f5c623fe8a"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "18e8ad9b-e9a7-5311-8a6f-e9e64c5997fe"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "67d6ef3c-b5a8-5230-b556-3bc42e2e5179"
                    ]
                }
            }
        },
        {
            "name": "screened user: ME8G2EW aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10a": {
                    "questionId": "SU-10a",
                    "chosenOptionIds": [
                        "ed6c59f2-8713-56b1-a4b2-8f1f85b224ed"
                    ]
                },
                "SU-11a": {
                    "questionId": "SU-11a",
                    "chosenOptionIds": [
                        "57899734-07b2-50a2-a5d8-56142b5feb92"
                    ]
                },
                "SU-1a": {
                    "questionId": "SU-1a",
                    "chosenOptionIds": [
                        "dc9dd01a-e6c0-58c8-9b8f-b3315d6a689c"
                    ]
                },
                "SU-2a": {
                    "questionId": "SU-2a",
                    "chosenOptionIds": [
                        "6d761e88-087b-5887-9e08-7720347f7b48"
                    ]
                },
                "SU-3a": {
                    "questionId": "SU-3a",
                    "chosenOptionIds": [
                        "cba96b30-e2c3-5d5b-80a8-c18a79809f16"
                    ]
                },
                "SU-4a": {
                    "questionId": "SU-4a",
                    "chosenOptionIds": [
                        "ede457f4-731b-56bd-b7b7-58d93ecdcacd"
                    ]
                },
                "SU-5a": {
                    "questionId": "SU-5a",
                    "chosenOptionIds": [
                        "7ec23bf3-d08e-58e1-a88a-9677b0b85ec8"
                    ]
                },
                "SU-6a": {
                    "questionId": "SU-6a",
                    "chosenOptionIds": [
                        "a16682f6-84c6-5bdd-9553-eafb92701f79"
                    ]
                },
                "SU-7a": {
                    "questionId": "SU-7a",
                    "chosenOptionIds": [
                        "698ebf04-9eee-5b77-97f3-dd84b14ac94a"
                    ]
                },
                "SU-8a": {
                    "questionId": "SU-8a",
                    "chosenOptionIds": [
                        "38ede561-e889-51b7-baa3-51217ea1552e"
                    ]
                },
                "SU-9a": {
                    "questionId": "SU-9a",
                    "chosenOptionIds": [
                        "ccb31960-a823-519d-a4a9-7348ba4ca6f5"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "084e1561-5048-57b0-8796-986f65764e19"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "3ed9bf02-1389-5b1a-81e0-5dadb1daa205"
                    ]
                },
                "SU-s3.1": {
                    "questionId": "SU-s3.1",
                    "chosenOptionIds": [
                        "3a4d2bec-b4ca-56e7-9044-99ca4db70f63"
                    ]
                }
            }
        },
        {
            "name": "screened user: PE6LM0L aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: K39ML0L aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: XE7VX0L aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z3RD4EO aspect: sbs_pe should be positive",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "SU-10b": {
                    "questionId": "SU-10b",
                    "chosenOptionIds": [
                        "eb7acd72-f4fa-52a4-b53f-db24e7756bbf"
                    ]
                },
                "SU-11b": {
                    "questionId": "SU-11b",
                    "chosenOptionIds": [
                        "df81db2f-9fe7-5b09-be87-64780dd81a25"
                    ]
                },
                "SU-12b": {
                    "questionId": "SU-12b",
                    "chosenOptionIds": [
                        "7debcb22-6637-5de5-9421-89be645c3b96"
                    ]
                },
                "SU-1b": {
                    "questionId": "SU-1b",
                    "freeValue": 2920
                },
                "SU-2b": {
                    "questionId": "SU-2b",
                    "chosenOptionIds": [
                        "4678ad6e-17f5-504f-84d5-d7e4bd032e1f"
                    ]
                },
                "SU-3b": {
                    "questionId": "SU-3b",
                    "chosenOptionIds": [
                        "97837883-2c07-58ae-87ab-970f4a8e16d7"
                    ]
                },
                "SU-4b": {
                    "questionId": "SU-4b",
                    "chosenOptionIds": [
                        "a55e2d73-6bb1-5ddf-b7f0-be44aa25ea75"
                    ]
                },
                "SU-5b": {
                    "questionId": "SU-5b",
                    "chosenOptionIds": [
                        "a6d3f751-7a06-5eb4-97b5-70831bdb0e3d"
                    ]
                },
                "SU-6b": {
                    "questionId": "SU-6b",
                    "chosenOptionIds": [
                        "e6d0d639-7af5-5de3-9355-51cd24b425c5"
                    ]
                },
                "SU-7b": {
                    "questionId": "SU-7b",
                    "chosenOptionIds": [
                        "fb9594cf-71a8-580c-80d0-18dd9696d5bc"
                    ]
                },
                "SU-8b": {
                    "questionId": "SU-8b",
                    "chosenOptionIds": [
                        "32ec1d91-b47c-5754-a656-c6c0f230ace2"
                    ]
                },
                "SU-9b": {
                    "questionId": "SU-9b",
                    "chosenOptionIds": [
                        "485e4f43-7787-5f16-8ff0-e578b0ff4b83"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f0bf8997-4579-5872-82e1-1400b895417b",
                        "4cf86ba0-867d-5f6d-b8d1-3aa5ceddc374",
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280",
                        "36c77f4a-59a6-588e-8346-b190d1491a17"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "67d6ef3c-b5a8-5230-b556-3bc42e2e5179"
                    ]
                }
            }
        },
        {
            "name": "screened user: 4EGQQ0P aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: 7EW2K38 aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: 63YR40R aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10a": {
                    "questionId": "SU-10a",
                    "chosenOptionIds": [
                        "ed6c59f2-8713-56b1-a4b2-8f1f85b224ed"
                    ]
                },
                "SU-11a": {
                    "questionId": "SU-11a",
                    "chosenOptionIds": [
                        "57899734-07b2-50a2-a5d8-56142b5feb92"
                    ]
                },
                "SU-1a": {
                    "questionId": "SU-1a",
                    "chosenOptionIds": [
                        "64b85f3c-84e5-5d37-bc42-dab6740c8199"
                    ]
                },
                "SU-2a": {
                    "questionId": "SU-2a",
                    "chosenOptionIds": [
                        "6d761e88-087b-5887-9e08-7720347f7b48"
                    ]
                },
                "SU-3a": {
                    "questionId": "SU-3a",
                    "chosenOptionIds": [
                        "a3720a21-cf5f-5c57-9dde-8cb54605246f"
                    ]
                },
                "SU-4a": {
                    "questionId": "SU-4a",
                    "chosenOptionIds": [
                        "dd97a5ec-89cc-5ee7-b89b-644d9f1a2b7b"
                    ]
                },
                "SU-5a": {
                    "questionId": "SU-5a",
                    "chosenOptionIds": [
                        "7ec23bf3-d08e-58e1-a88a-9677b0b85ec8"
                    ]
                },
                "SU-6a": {
                    "questionId": "SU-6a",
                    "chosenOptionIds": [
                        "a16682f6-84c6-5bdd-9553-eafb92701f79"
                    ]
                },
                "SU-7a": {
                    "questionId": "SU-7a",
                    "chosenOptionIds": [
                        "698ebf04-9eee-5b77-97f3-dd84b14ac94a"
                    ]
                },
                "SU-8a": {
                    "questionId": "SU-8a",
                    "chosenOptionIds": [
                        "38ede561-e889-51b7-baa3-51217ea1552e"
                    ]
                },
                "SU-9a": {
                    "questionId": "SU-9a",
                    "chosenOptionIds": [
                        "ccb31960-a823-519d-a4a9-7348ba4ca6f5"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f0bf8997-4579-5872-82e1-1400b895417b",
                        "2075c9f4-b5e7-5615-b714-0ab3a1b5d046",
                        "18e8ad9b-e9a7-5311-8a6f-e9e64c5997fe",
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "3ed9bf02-1389-5b1a-81e0-5dadb1daa205"
                    ]
                },
                "SU-s3.1": {
                    "questionId": "SU-s3.1",
                    "chosenOptionIds": [
                        "0874416b-3740-5988-91da-b5ef5aad981f",
                        "28f722a5-8507-556b-abe2-8413d34679e4"
                    ]
                }
            }
        },
        {
            "name": "screened user: Y329ME8 aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10b": {
                    "questionId": "SU-10b",
                    "chosenOptionIds": [
                        "fe352b2c-eff2-5046-8413-d86aa9192485"
                    ]
                },
                "SU-11b": {
                    "questionId": "SU-11b",
                    "chosenOptionIds": [
                        "1c65112c-5105-5069-a400-b16d985d1bdf"
                    ]
                },
                "SU-12b": {
                    "questionId": "SU-12b",
                    "chosenOptionIds": [
                        "cc068b90-c986-5049-9322-7b8b0fad4143"
                    ]
                },
                "SU-1b": {
                    "questionId": "SU-1b",
                    "freeValue": 730
                },
                "SU-2b": {
                    "questionId": "SU-2b",
                    "chosenOptionIds": [
                        "f6b6411b-02e6-531d-b2ed-67d6dfacfd96"
                    ]
                },
                "SU-3b": {
                    "questionId": "SU-3b",
                    "chosenOptionIds": [
                        "48eb47f4-b5d0-5ca4-ba6a-5f811f32d488"
                    ]
                },
                "SU-4b": {
                    "questionId": "SU-4b",
                    "chosenOptionIds": [
                        "a55e2d73-6bb1-5ddf-b7f0-be44aa25ea75"
                    ]
                },
                "SU-5b": {
                    "questionId": "SU-5b",
                    "chosenOptionIds": [
                        "a6d3f751-7a06-5eb4-97b5-70831bdb0e3d"
                    ]
                },
                "SU-6b": {
                    "questionId": "SU-6b",
                    "chosenOptionIds": [
                        "e6d0d639-7af5-5de3-9355-51cd24b425c5"
                    ]
                },
                "SU-7b": {
                    "questionId": "SU-7b",
                    "chosenOptionIds": [
                        "fb9594cf-71a8-580c-80d0-18dd9696d5bc"
                    ]
                },
                "SU-8b": {
                    "questionId": "SU-8b",
                    "chosenOptionIds": [
                        "a7206832-dc04-531b-bb17-18b8132ec246"
                    ]
                },
                "SU-9b": {
                    "questionId": "SU-9b",
                    "chosenOptionIds": [
                        "70dd7443-067d-5db4-bbfd-32f5c623fe8a"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "67d6ef3c-b5a8-5230-b556-3bc42e2e5179"
                    ]
                }
            }
        },
        {
            "name": "screened user: J0VZ63L aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: 73ZJN0R aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: R042O34 aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: J0VRYEL aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: 73ZNN3R aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: ME8D2EW aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0NN50Q aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: 73Z220R aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10b": {
                    "questionId": "SU-10b",
                    "chosenOptionIds": [
                        "fe352b2c-eff2-5046-8413-d86aa9192485"
                    ]
                },
                "SU-11b": {
                    "questionId": "SU-11b",
                    "chosenOptionIds": [
                        "1c65112c-5105-5069-a400-b16d985d1bdf"
                    ]
                },
                "SU-12b": {
                    "questionId": "SU-12b",
                    "chosenOptionIds": [
                        "cc068b90-c986-5049-9322-7b8b0fad4143"
                    ]
                },
                "SU-1b": {
                    "questionId": "SU-1b",
                    "freeValue": 4380
                },
                "SU-2b": {
                    "questionId": "SU-2b",
                    "chosenOptionIds": [
                        "f6b6411b-02e6-531d-b2ed-67d6dfacfd96"
                    ]
                },
                "SU-3b": {
                    "questionId": "SU-3b",
                    "chosenOptionIds": [
                        "48eb47f4-b5d0-5ca4-ba6a-5f811f32d488"
                    ]
                },
                "SU-4b": {
                    "questionId": "SU-4b",
                    "chosenOptionIds": [
                        "5af10404-91e9-55e5-a3a5-5b0525ebf15c"
                    ]
                },
                "SU-5b": {
                    "questionId": "SU-5b",
                    "chosenOptionIds": [
                        "a6d3f751-7a06-5eb4-97b5-70831bdb0e3d"
                    ]
                },
                "SU-6b": {
                    "questionId": "SU-6b",
                    "chosenOptionIds": [
                        "e6d0d639-7af5-5de3-9355-51cd24b425c5"
                    ]
                },
                "SU-7b": {
                    "questionId": "SU-7b",
                    "chosenOptionIds": [
                        "fb9594cf-71a8-580c-80d0-18dd9696d5bc"
                    ]
                },
                "SU-8b": {
                    "questionId": "SU-8b",
                    "chosenOptionIds": [
                        "a7206832-dc04-531b-bb17-18b8132ec246"
                    ]
                },
                "SU-9b": {
                    "questionId": "SU-9b",
                    "chosenOptionIds": [
                        "70dd7443-067d-5db4-bbfd-32f5c623fe8a"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "67d6ef3c-b5a8-5230-b556-3bc42e2e5179"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0N4J3Q aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10b": {
                    "questionId": "SU-10b",
                    "chosenOptionIds": [
                        "fe352b2c-eff2-5046-8413-d86aa9192485"
                    ]
                },
                "SU-11b": {
                    "questionId": "SU-11b",
                    "chosenOptionIds": [
                        "1c65112c-5105-5069-a400-b16d985d1bdf"
                    ]
                },
                "SU-12b": {
                    "questionId": "SU-12b",
                    "chosenOptionIds": [
                        "cc068b90-c986-5049-9322-7b8b0fad4143"
                    ]
                },
                "SU-1b": {
                    "questionId": "SU-1b",
                    "freeValue": 3650
                },
                "SU-2b": {
                    "questionId": "SU-2b",
                    "chosenOptionIds": [
                        "f6b6411b-02e6-531d-b2ed-67d6dfacfd96"
                    ]
                },
                "SU-3b": {
                    "questionId": "SU-3b",
                    "chosenOptionIds": [
                        "48eb47f4-b5d0-5ca4-ba6a-5f811f32d488"
                    ]
                },
                "SU-4b": {
                    "questionId": "SU-4b",
                    "chosenOptionIds": [
                        "5af10404-91e9-55e5-a3a5-5b0525ebf15c"
                    ]
                },
                "SU-5b": {
                    "questionId": "SU-5b",
                    "chosenOptionIds": [
                        "a6d3f751-7a06-5eb4-97b5-70831bdb0e3d"
                    ]
                },
                "SU-6b": {
                    "questionId": "SU-6b",
                    "chosenOptionIds": [
                        "e6d0d639-7af5-5de3-9355-51cd24b425c5"
                    ]
                },
                "SU-7b": {
                    "questionId": "SU-7b",
                    "chosenOptionIds": [
                        "fb9594cf-71a8-580c-80d0-18dd9696d5bc"
                    ]
                },
                "SU-8b": {
                    "questionId": "SU-8b",
                    "chosenOptionIds": [
                        "a7206832-dc04-531b-bb17-18b8132ec246"
                    ]
                },
                "SU-9b": {
                    "questionId": "SU-9b",
                    "chosenOptionIds": [
                        "70dd7443-067d-5db4-bbfd-32f5c623fe8a"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "67d6ef3c-b5a8-5230-b556-3bc42e2e5179"
                    ]
                }
            }
        },
        {
            "name": "screened user: 10QKZ0P aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: XE7ZR3L aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10a": {
                    "questionId": "SU-10a",
                    "chosenOptionIds": [
                        "ed6c59f2-8713-56b1-a4b2-8f1f85b224ed"
                    ]
                },
                "SU-11a": {
                    "questionId": "SU-11a",
                    "chosenOptionIds": [
                        "57899734-07b2-50a2-a5d8-56142b5feb92"
                    ]
                },
                "SU-1a": {
                    "questionId": "SU-1a",
                    "chosenOptionIds": [
                        "dc9dd01a-e6c0-58c8-9b8f-b3315d6a689c"
                    ]
                },
                "SU-2a": {
                    "questionId": "SU-2a",
                    "chosenOptionIds": [
                        "6d761e88-087b-5887-9e08-7720347f7b48"
                    ]
                },
                "SU-3a": {
                    "questionId": "SU-3a",
                    "chosenOptionIds": [
                        "cba96b30-e2c3-5d5b-80a8-c18a79809f16"
                    ]
                },
                "SU-4a": {
                    "questionId": "SU-4a",
                    "chosenOptionIds": [
                        "ede457f4-731b-56bd-b7b7-58d93ecdcacd"
                    ]
                },
                "SU-5a": {
                    "questionId": "SU-5a",
                    "chosenOptionIds": [
                        "7ec23bf3-d08e-58e1-a88a-9677b0b85ec8"
                    ]
                },
                "SU-6a": {
                    "questionId": "SU-6a",
                    "chosenOptionIds": [
                        "a16682f6-84c6-5bdd-9553-eafb92701f79"
                    ]
                },
                "SU-7a": {
                    "questionId": "SU-7a",
                    "chosenOptionIds": [
                        "698ebf04-9eee-5b77-97f3-dd84b14ac94a"
                    ]
                },
                "SU-8a": {
                    "questionId": "SU-8a",
                    "chosenOptionIds": [
                        "38ede561-e889-51b7-baa3-51217ea1552e"
                    ]
                },
                "SU-9a": {
                    "questionId": "SU-9a",
                    "chosenOptionIds": [
                        "ccb31960-a823-519d-a4a9-7348ba4ca6f5"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "18e8ad9b-e9a7-5311-8a6f-e9e64c5997fe",
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280",
                        "36c77f4a-59a6-588e-8346-b190d1491a17",
                        "f0bf8997-4579-5872-82e1-1400b895417b"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "3ed9bf02-1389-5b1a-81e0-5dadb1daa205"
                    ]
                },
                "SU-s3.1": {
                    "questionId": "SU-s3.1",
                    "chosenOptionIds": [
                        "28f722a5-8507-556b-abe2-8413d34679e4"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0N5PEQ aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10b": {
                    "questionId": "SU-10b",
                    "chosenOptionIds": [
                        "fe352b2c-eff2-5046-8413-d86aa9192485"
                    ]
                },
                "SU-11b": {
                    "questionId": "SU-11b",
                    "chosenOptionIds": [
                        "1c65112c-5105-5069-a400-b16d985d1bdf"
                    ]
                },
                "SU-12b": {
                    "questionId": "SU-12b",
                    "chosenOptionIds": [
                        "cc068b90-c986-5049-9322-7b8b0fad4143"
                    ]
                },
                "SU-1b": {
                    "questionId": "SU-1b",
                    "freeValue": 1825
                },
                "SU-2b": {
                    "questionId": "SU-2b",
                    "chosenOptionIds": [
                        "4678ad6e-17f5-504f-84d5-d7e4bd032e1f"
                    ]
                },
                "SU-3b": {
                    "questionId": "SU-3b",
                    "chosenOptionIds": [
                        "48eb47f4-b5d0-5ca4-ba6a-5f811f32d488"
                    ]
                },
                "SU-4b": {
                    "questionId": "SU-4b",
                    "chosenOptionIds": [
                        "5af10404-91e9-55e5-a3a5-5b0525ebf15c"
                    ]
                },
                "SU-5b": {
                    "questionId": "SU-5b",
                    "chosenOptionIds": [
                        "a6d3f751-7a06-5eb4-97b5-70831bdb0e3d"
                    ]
                },
                "SU-6b": {
                    "questionId": "SU-6b",
                    "chosenOptionIds": [
                        "e6d0d639-7af5-5de3-9355-51cd24b425c5"
                    ]
                },
                "SU-7b": {
                    "questionId": "SU-7b",
                    "chosenOptionIds": [
                        "fb9594cf-71a8-580c-80d0-18dd9696d5bc"
                    ]
                },
                "SU-8b": {
                    "questionId": "SU-8b",
                    "chosenOptionIds": [
                        "a7206832-dc04-531b-bb17-18b8132ec246"
                    ]
                },
                "SU-9b": {
                    "questionId": "SU-9b",
                    "chosenOptionIds": [
                        "70dd7443-067d-5db4-bbfd-32f5c623fe8a"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "67d6ef3c-b5a8-5230-b556-3bc42e2e5179"
                    ]
                }
            }
        },
        {
            "name": "screened user: 23XKV0N aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10a": {
                    "questionId": "SU-10a",
                    "chosenOptionIds": [
                        "ed6c59f2-8713-56b1-a4b2-8f1f85b224ed"
                    ]
                },
                "SU-11a": {
                    "questionId": "SU-11a",
                    "chosenOptionIds": [
                        "57899734-07b2-50a2-a5d8-56142b5feb92"
                    ]
                },
                "SU-1a": {
                    "questionId": "SU-1a",
                    "chosenOptionIds": [
                        "22270598-ff5e-5785-a9a3-9bbed0fd9751"
                    ]
                },
                "SU-2a": {
                    "questionId": "SU-2a",
                    "chosenOptionIds": [
                        "6d761e88-087b-5887-9e08-7720347f7b48"
                    ]
                },
                "SU-3a": {
                    "questionId": "SU-3a",
                    "chosenOptionIds": [
                        "cba96b30-e2c3-5d5b-80a8-c18a79809f16"
                    ]
                },
                "SU-4a": {
                    "questionId": "SU-4a",
                    "chosenOptionIds": [
                        "ede457f4-731b-56bd-b7b7-58d93ecdcacd"
                    ]
                },
                "SU-5a": {
                    "questionId": "SU-5a",
                    "chosenOptionIds": [
                        "7ec23bf3-d08e-58e1-a88a-9677b0b85ec8"
                    ]
                },
                "SU-6a": {
                    "questionId": "SU-6a",
                    "chosenOptionIds": [
                        "a16682f6-84c6-5bdd-9553-eafb92701f79"
                    ]
                },
                "SU-7a": {
                    "questionId": "SU-7a",
                    "chosenOptionIds": [
                        "698ebf04-9eee-5b77-97f3-dd84b14ac94a"
                    ]
                },
                "SU-8a": {
                    "questionId": "SU-8a",
                    "chosenOptionIds": [
                        "38ede561-e889-51b7-baa3-51217ea1552e"
                    ]
                },
                "SU-9a": {
                    "questionId": "SU-9a",
                    "chosenOptionIds": [
                        "ccb31960-a823-519d-a4a9-7348ba4ca6f5"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f0bf8997-4579-5872-82e1-1400b895417b",
                        "4cf86ba0-867d-5f6d-b8d1-3aa5ceddc374",
                        "18e8ad9b-e9a7-5311-8a6f-e9e64c5997fe",
                        "4f2c6cea-7f4c-55ca-b952-334af926e7b1",
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "3ed9bf02-1389-5b1a-81e0-5dadb1daa205"
                    ]
                },
                "SU-s3.1": {
                    "questionId": "SU-s3.1",
                    "chosenOptionIds": [
                        "28f722a5-8507-556b-abe2-8413d34679e4"
                    ]
                }
            }
        },
        {
            "name": "screened user: 63YD63R aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10b": {
                    "questionId": "SU-10b",
                    "chosenOptionIds": [
                        "fe352b2c-eff2-5046-8413-d86aa9192485"
                    ]
                },
                "SU-11b": {
                    "questionId": "SU-11b",
                    "chosenOptionIds": [
                        "1c65112c-5105-5069-a400-b16d985d1bdf"
                    ]
                },
                "SU-12b": {
                    "questionId": "SU-12b",
                    "chosenOptionIds": [
                        "cc068b90-c986-5049-9322-7b8b0fad4143"
                    ]
                },
                "SU-1b": {
                    "questionId": "SU-1b",
                    "freeValue": 2920
                },
                "SU-2b": {
                    "questionId": "SU-2b",
                    "chosenOptionIds": [
                        "f6b6411b-02e6-531d-b2ed-67d6dfacfd96"
                    ]
                },
                "SU-3b": {
                    "questionId": "SU-3b",
                    "chosenOptionIds": [
                        "48eb47f4-b5d0-5ca4-ba6a-5f811f32d488"
                    ]
                },
                "SU-4b": {
                    "questionId": "SU-4b",
                    "chosenOptionIds": [
                        "5af10404-91e9-55e5-a3a5-5b0525ebf15c"
                    ]
                },
                "SU-5b": {
                    "questionId": "SU-5b",
                    "chosenOptionIds": [
                        "a6d3f751-7a06-5eb4-97b5-70831bdb0e3d"
                    ]
                },
                "SU-6b": {
                    "questionId": "SU-6b",
                    "chosenOptionIds": [
                        "e6d0d639-7af5-5de3-9355-51cd24b425c5"
                    ]
                },
                "SU-7b": {
                    "questionId": "SU-7b",
                    "chosenOptionIds": [
                        "fb9594cf-71a8-580c-80d0-18dd9696d5bc"
                    ]
                },
                "SU-8b": {
                    "questionId": "SU-8b",
                    "chosenOptionIds": [
                        "a7206832-dc04-531b-bb17-18b8132ec246"
                    ]
                },
                "SU-9b": {
                    "questionId": "SU-9b",
                    "chosenOptionIds": [
                        "70dd7443-067d-5db4-bbfd-32f5c623fe8a"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "67d6ef3c-b5a8-5230-b556-3bc42e2e5179"
                    ]
                }
            }
        },
        {
            "name": "screened user: ME8PLEW aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10a": {
                    "questionId": "SU-10a",
                    "chosenOptionIds": [
                        "ed6c59f2-8713-56b1-a4b2-8f1f85b224ed"
                    ]
                },
                "SU-11a": {
                    "questionId": "SU-11a",
                    "chosenOptionIds": [
                        "57899734-07b2-50a2-a5d8-56142b5feb92"
                    ]
                },
                "SU-1a": {
                    "questionId": "SU-1a",
                    "chosenOptionIds": [
                        "64b85f3c-84e5-5d37-bc42-dab6740c8199"
                    ]
                },
                "SU-2a": {
                    "questionId": "SU-2a",
                    "chosenOptionIds": [
                        "6d761e88-087b-5887-9e08-7720347f7b48"
                    ]
                },
                "SU-3a": {
                    "questionId": "SU-3a",
                    "chosenOptionIds": [
                        "a3720a21-cf5f-5c57-9dde-8cb54605246f"
                    ]
                },
                "SU-4a": {
                    "questionId": "SU-4a",
                    "chosenOptionIds": [
                        "ede457f4-731b-56bd-b7b7-58d93ecdcacd"
                    ]
                },
                "SU-5a": {
                    "questionId": "SU-5a",
                    "chosenOptionIds": [
                        "7ec23bf3-d08e-58e1-a88a-9677b0b85ec8"
                    ]
                },
                "SU-6a": {
                    "questionId": "SU-6a",
                    "chosenOptionIds": [
                        "a16682f6-84c6-5bdd-9553-eafb92701f79"
                    ]
                },
                "SU-7a": {
                    "questionId": "SU-7a",
                    "chosenOptionIds": [
                        "698ebf04-9eee-5b77-97f3-dd84b14ac94a"
                    ]
                },
                "SU-8a": {
                    "questionId": "SU-8a",
                    "chosenOptionIds": [
                        "38ede561-e889-51b7-baa3-51217ea1552e"
                    ]
                },
                "SU-9a": {
                    "questionId": "SU-9a",
                    "chosenOptionIds": [
                        "ccb31960-a823-519d-a4a9-7348ba4ca6f5"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "3ed9bf02-1389-5b1a-81e0-5dadb1daa205"
                    ]
                },
                "SU-s3.1": {
                    "questionId": "SU-s3.1",
                    "chosenOptionIds": [
                        "28f722a5-8507-556b-abe2-8413d34679e4"
                    ]
                }
            }
        },
        {
            "name": "screened user: K39V10L aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: O0K1931 aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: J3J1D0Y aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: 10QJ8EP aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: 73ZL2ER aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10a": {
                    "questionId": "SU-10a",
                    "chosenOptionIds": [
                        "ed6c59f2-8713-56b1-a4b2-8f1f85b224ed"
                    ]
                },
                "SU-11a": {
                    "questionId": "SU-11a",
                    "chosenOptionIds": [
                        "57899734-07b2-50a2-a5d8-56142b5feb92"
                    ]
                },
                "SU-1a": {
                    "questionId": "SU-1a",
                    "chosenOptionIds": [
                        "dc9dd01a-e6c0-58c8-9b8f-b3315d6a689c"
                    ]
                },
                "SU-2a": {
                    "questionId": "SU-2a",
                    "chosenOptionIds": [
                        "6d761e88-087b-5887-9e08-7720347f7b48"
                    ]
                },
                "SU-3a": {
                    "questionId": "SU-3a",
                    "chosenOptionIds": [
                        "a3720a21-cf5f-5c57-9dde-8cb54605246f"
                    ]
                },
                "SU-4a": {
                    "questionId": "SU-4a",
                    "chosenOptionIds": [
                        "ede457f4-731b-56bd-b7b7-58d93ecdcacd"
                    ]
                },
                "SU-5a": {
                    "questionId": "SU-5a",
                    "chosenOptionIds": [
                        "7ec23bf3-d08e-58e1-a88a-9677b0b85ec8"
                    ]
                },
                "SU-6a": {
                    "questionId": "SU-6a",
                    "chosenOptionIds": [
                        "a16682f6-84c6-5bdd-9553-eafb92701f79"
                    ]
                },
                "SU-7a": {
                    "questionId": "SU-7a",
                    "chosenOptionIds": [
                        "698ebf04-9eee-5b77-97f3-dd84b14ac94a"
                    ]
                },
                "SU-8a": {
                    "questionId": "SU-8a",
                    "chosenOptionIds": [
                        "38ede561-e889-51b7-baa3-51217ea1552e"
                    ]
                },
                "SU-9a": {
                    "questionId": "SU-9a",
                    "chosenOptionIds": [
                        "ccb31960-a823-519d-a4a9-7348ba4ca6f5"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "4cf86ba0-867d-5f6d-b8d1-3aa5ceddc374",
                        "18e8ad9b-e9a7-5311-8a6f-e9e64c5997fe",
                        "4f2c6cea-7f4c-55ca-b952-334af926e7b1",
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280",
                        "f0bf8997-4579-5872-82e1-1400b895417b"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "3ed9bf02-1389-5b1a-81e0-5dadb1daa205"
                    ]
                },
                "SU-s3.1": {
                    "questionId": "SU-s3.1",
                    "chosenOptionIds": [
                        "82702400-306e-52c0-b320-cc03e2f74660",
                        "28f722a5-8507-556b-abe2-8413d34679e4"
                    ]
                }
            }
        },
        {
            "name": "screened user: O3P66EW aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10a": {
                    "questionId": "SU-10a",
                    "chosenOptionIds": [
                        "ed6c59f2-8713-56b1-a4b2-8f1f85b224ed"
                    ]
                },
                "SU-11a": {
                    "questionId": "SU-11a",
                    "chosenOptionIds": [
                        "8f999645-02ed-57fb-90c7-c509bdf9217d"
                    ]
                },
                "SU-1a": {
                    "questionId": "SU-1a",
                    "chosenOptionIds": [
                        "1a337ce3-5ab7-5773-9fe0-f0d1c23ed96c"
                    ]
                },
                "SU-2a": {
                    "questionId": "SU-2a",
                    "chosenOptionIds": [
                        "61e46908-594f-5831-b709-767c60df9f2b"
                    ]
                },
                "SU-3a": {
                    "questionId": "SU-3a",
                    "chosenOptionIds": [
                        "8b9ec309-bccd-5e75-82c2-0b9f6bbf25fd"
                    ]
                },
                "SU-4a": {
                    "questionId": "SU-4a",
                    "chosenOptionIds": [
                        "ede457f4-731b-56bd-b7b7-58d93ecdcacd"
                    ]
                },
                "SU-5a": {
                    "questionId": "SU-5a",
                    "chosenOptionIds": [
                        "7ec23bf3-d08e-58e1-a88a-9677b0b85ec8"
                    ]
                },
                "SU-6a": {
                    "questionId": "SU-6a",
                    "chosenOptionIds": [
                        "a16682f6-84c6-5bdd-9553-eafb92701f79"
                    ]
                },
                "SU-7a": {
                    "questionId": "SU-7a",
                    "chosenOptionIds": [
                        "85e98c24-bf79-526d-8945-070f551c81c7"
                    ]
                },
                "SU-8a": {
                    "questionId": "SU-8a",
                    "chosenOptionIds": [
                        "38ede561-e889-51b7-baa3-51217ea1552e"
                    ]
                },
                "SU-9a": {
                    "questionId": "SU-9a",
                    "chosenOptionIds": [
                        "16c3b305-ee67-59a9-9182-8d6c929a9509"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "3ed9bf02-1389-5b1a-81e0-5dadb1daa205"
                    ]
                },
                "SU-s3.1": {
                    "questionId": "SU-s3.1",
                    "chosenOptionIds": [
                        "28f722a5-8507-556b-abe2-8413d34679e4"
                    ]
                }
            }
        },
        {
            "name": "screened user: O3PGG3W aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10b": {
                    "questionId": "SU-10b",
                    "chosenOptionIds": [
                        "fe352b2c-eff2-5046-8413-d86aa9192485"
                    ]
                },
                "SU-11b": {
                    "questionId": "SU-11b",
                    "chosenOptionIds": [
                        "1c65112c-5105-5069-a400-b16d985d1bdf"
                    ]
                },
                "SU-12b": {
                    "questionId": "SU-12b",
                    "chosenOptionIds": [
                        "cc068b90-c986-5049-9322-7b8b0fad4143"
                    ]
                },
                "SU-1b": {
                    "questionId": "SU-1b",
                    "freeValue": 1095
                },
                "SU-2b": {
                    "questionId": "SU-2b",
                    "chosenOptionIds": [
                        "f6b6411b-02e6-531d-b2ed-67d6dfacfd96"
                    ]
                },
                "SU-3b": {
                    "questionId": "SU-3b",
                    "chosenOptionIds": [
                        "48eb47f4-b5d0-5ca4-ba6a-5f811f32d488"
                    ]
                },
                "SU-4b": {
                    "questionId": "SU-4b",
                    "chosenOptionIds": [
                        "5af10404-91e9-55e5-a3a5-5b0525ebf15c"
                    ]
                },
                "SU-5b": {
                    "questionId": "SU-5b",
                    "chosenOptionIds": [
                        "a6d3f751-7a06-5eb4-97b5-70831bdb0e3d"
                    ]
                },
                "SU-6b": {
                    "questionId": "SU-6b",
                    "chosenOptionIds": [
                        "e6d0d639-7af5-5de3-9355-51cd24b425c5"
                    ]
                },
                "SU-7b": {
                    "questionId": "SU-7b",
                    "chosenOptionIds": [
                        "fb9594cf-71a8-580c-80d0-18dd9696d5bc"
                    ]
                },
                "SU-8b": {
                    "questionId": "SU-8b",
                    "chosenOptionIds": [
                        "a7206832-dc04-531b-bb17-18b8132ec246"
                    ]
                },
                "SU-9b": {
                    "questionId": "SU-9b",
                    "chosenOptionIds": [
                        "70dd7443-067d-5db4-bbfd-32f5c623fe8a"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f0bf8997-4579-5872-82e1-1400b895417b",
                        "18e8ad9b-e9a7-5311-8a6f-e9e64c5997fe",
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "67d6ef3c-b5a8-5230-b556-3bc42e2e5179"
                    ]
                }
            }
        },
        {
            "name": "screened user: Y32OM08 aspect: sbs_pe should be positive",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "SU-10b": {
                    "questionId": "SU-10b",
                    "chosenOptionIds": [
                        "eb7acd72-f4fa-52a4-b53f-db24e7756bbf"
                    ]
                },
                "SU-11b": {
                    "questionId": "SU-11b",
                    "chosenOptionIds": [
                        "df81db2f-9fe7-5b09-be87-64780dd81a25"
                    ]
                },
                "SU-12b": {
                    "questionId": "SU-12b",
                    "chosenOptionIds": [
                        "7debcb22-6637-5de5-9421-89be645c3b96"
                    ]
                },
                "SU-1b": {
                    "questionId": "SU-1b",
                    "freeValue": 1825
                },
                "SU-2b": {
                    "questionId": "SU-2b",
                    "chosenOptionIds": [
                        "4678ad6e-17f5-504f-84d5-d7e4bd032e1f"
                    ]
                },
                "SU-3b": {
                    "questionId": "SU-3b",
                    "chosenOptionIds": [
                        "48eb47f4-b5d0-5ca4-ba6a-5f811f32d488"
                    ]
                },
                "SU-4b": {
                    "questionId": "SU-4b",
                    "chosenOptionIds": [
                        "c48b4250-be6d-53b5-9c4b-50a2f5facea4"
                    ]
                },
                "SU-5b": {
                    "questionId": "SU-5b",
                    "chosenOptionIds": [
                        "bcac9437-7015-5fe5-9ee4-fb99cf0d5f17"
                    ]
                },
                "SU-6b": {
                    "questionId": "SU-6b",
                    "chosenOptionIds": [
                        "e6d0d639-7af5-5de3-9355-51cd24b425c5"
                    ]
                },
                "SU-7b": {
                    "questionId": "SU-7b",
                    "chosenOptionIds": [
                        "b6a26449-f023-5a8a-a4c9-a6c625f0f366"
                    ]
                },
                "SU-8b": {
                    "questionId": "SU-8b",
                    "chosenOptionIds": [
                        "a7206832-dc04-531b-bb17-18b8132ec246"
                    ]
                },
                "SU-9b": {
                    "questionId": "SU-9b",
                    "chosenOptionIds": [
                        "70dd7443-067d-5db4-bbfd-32f5c623fe8a"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f0bf8997-4579-5872-82e1-1400b895417b",
                        "4cf86ba0-867d-5f6d-b8d1-3aa5ceddc374",
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280",
                        "6248c04e-ea14-5b3e-8821-7cdbe8f84be3",
                        "4f2c6cea-7f4c-55ca-b952-334af926e7b1"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "67d6ef3c-b5a8-5230-b556-3bc42e2e5179"
                    ]
                }
            }
        },
        {
            "name": "screened user: ME84L3W aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0NJ5EQ aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0NO53Q aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: K392M0L aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10a": {
                    "questionId": "SU-10a",
                    "chosenOptionIds": [
                        "ed6c59f2-8713-56b1-a4b2-8f1f85b224ed"
                    ]
                },
                "SU-11a": {
                    "questionId": "SU-11a",
                    "chosenOptionIds": [
                        "57899734-07b2-50a2-a5d8-56142b5feb92"
                    ]
                },
                "SU-1a": {
                    "questionId": "SU-1a",
                    "chosenOptionIds": [
                        "64b85f3c-84e5-5d37-bc42-dab6740c8199"
                    ]
                },
                "SU-2a": {
                    "questionId": "SU-2a",
                    "chosenOptionIds": [
                        "6d761e88-087b-5887-9e08-7720347f7b48"
                    ]
                },
                "SU-3a": {
                    "questionId": "SU-3a",
                    "chosenOptionIds": [
                        "cba96b30-e2c3-5d5b-80a8-c18a79809f16"
                    ]
                },
                "SU-4a": {
                    "questionId": "SU-4a",
                    "chosenOptionIds": [
                        "ede457f4-731b-56bd-b7b7-58d93ecdcacd"
                    ]
                },
                "SU-5a": {
                    "questionId": "SU-5a",
                    "chosenOptionIds": [
                        "7ec23bf3-d08e-58e1-a88a-9677b0b85ec8"
                    ]
                },
                "SU-6a": {
                    "questionId": "SU-6a",
                    "chosenOptionIds": [
                        "a16682f6-84c6-5bdd-9553-eafb92701f79"
                    ]
                },
                "SU-7a": {
                    "questionId": "SU-7a",
                    "chosenOptionIds": [
                        "698ebf04-9eee-5b77-97f3-dd84b14ac94a"
                    ]
                },
                "SU-8a": {
                    "questionId": "SU-8a",
                    "chosenOptionIds": [
                        "38ede561-e889-51b7-baa3-51217ea1552e"
                    ]
                },
                "SU-9a": {
                    "questionId": "SU-9a",
                    "chosenOptionIds": [
                        "ccb31960-a823-519d-a4a9-7348ba4ca6f5"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "084e1561-5048-57b0-8796-986f65764e19"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "3ed9bf02-1389-5b1a-81e0-5dadb1daa205"
                    ]
                },
                "SU-s3.1": {
                    "questionId": "SU-s3.1",
                    "chosenOptionIds": [
                        "3a4d2bec-b4ca-56e7-9044-99ca4db70f63"
                    ]
                }
            }
        },
        {
            "name": "screened user: 6EDJMEM aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10b": {
                    "questionId": "SU-10b",
                    "chosenOptionIds": [
                        "fe352b2c-eff2-5046-8413-d86aa9192485"
                    ]
                },
                "SU-11b": {
                    "questionId": "SU-11b",
                    "chosenOptionIds": [
                        "1c65112c-5105-5069-a400-b16d985d1bdf"
                    ]
                },
                "SU-12b": {
                    "questionId": "SU-12b",
                    "chosenOptionIds": [
                        "cc068b90-c986-5049-9322-7b8b0fad4143"
                    ]
                },
                "SU-1b": {
                    "questionId": "SU-1b",
                    "freeValue": 365
                },
                "SU-2b": {
                    "questionId": "SU-2b",
                    "chosenOptionIds": [
                        "f6b6411b-02e6-531d-b2ed-67d6dfacfd96"
                    ]
                },
                "SU-3b": {
                    "questionId": "SU-3b",
                    "chosenOptionIds": [
                        "48eb47f4-b5d0-5ca4-ba6a-5f811f32d488"
                    ]
                },
                "SU-4b": {
                    "questionId": "SU-4b",
                    "chosenOptionIds": [
                        "5af10404-91e9-55e5-a3a5-5b0525ebf15c"
                    ]
                },
                "SU-5b": {
                    "questionId": "SU-5b",
                    "chosenOptionIds": [
                        "a6d3f751-7a06-5eb4-97b5-70831bdb0e3d"
                    ]
                },
                "SU-6b": {
                    "questionId": "SU-6b",
                    "chosenOptionIds": [
                        "e6d0d639-7af5-5de3-9355-51cd24b425c5"
                    ]
                },
                "SU-7b": {
                    "questionId": "SU-7b",
                    "chosenOptionIds": [
                        "fb9594cf-71a8-580c-80d0-18dd9696d5bc"
                    ]
                },
                "SU-8b": {
                    "questionId": "SU-8b",
                    "chosenOptionIds": [
                        "a7206832-dc04-531b-bb17-18b8132ec246"
                    ]
                },
                "SU-9b": {
                    "questionId": "SU-9b",
                    "chosenOptionIds": [
                        "70dd7443-067d-5db4-bbfd-32f5c623fe8a"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "4cf86ba0-867d-5f6d-b8d1-3aa5ceddc374"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "67d6ef3c-b5a8-5230-b556-3bc42e2e5179"
                    ]
                }
            }
        },
        {
            "name": "screened user: 10Q6R0P aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: O0KPDE1 aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: 63YPO3R aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10b": {
                    "questionId": "SU-10b",
                    "chosenOptionIds": [
                        "fe352b2c-eff2-5046-8413-d86aa9192485"
                    ]
                },
                "SU-11b": {
                    "questionId": "SU-11b",
                    "chosenOptionIds": [
                        "1c65112c-5105-5069-a400-b16d985d1bdf"
                    ]
                },
                "SU-12b": {
                    "questionId": "SU-12b",
                    "chosenOptionIds": [
                        "cc068b90-c986-5049-9322-7b8b0fad4143"
                    ]
                },
                "SU-1b": {
                    "questionId": "SU-1b",
                    "freeValue": 14600
                },
                "SU-2b": {
                    "questionId": "SU-2b",
                    "chosenOptionIds": [
                        "4678ad6e-17f5-504f-84d5-d7e4bd032e1f"
                    ]
                },
                "SU-3b": {
                    "questionId": "SU-3b",
                    "chosenOptionIds": [
                        "48eb47f4-b5d0-5ca4-ba6a-5f811f32d488"
                    ]
                },
                "SU-4b": {
                    "questionId": "SU-4b",
                    "chosenOptionIds": [
                        "a55e2d73-6bb1-5ddf-b7f0-be44aa25ea75"
                    ]
                },
                "SU-5b": {
                    "questionId": "SU-5b",
                    "chosenOptionIds": [
                        "a6d3f751-7a06-5eb4-97b5-70831bdb0e3d"
                    ]
                },
                "SU-6b": {
                    "questionId": "SU-6b",
                    "chosenOptionIds": [
                        "e6d0d639-7af5-5de3-9355-51cd24b425c5"
                    ]
                },
                "SU-7b": {
                    "questionId": "SU-7b",
                    "chosenOptionIds": [
                        "fb9594cf-71a8-580c-80d0-18dd9696d5bc"
                    ]
                },
                "SU-8b": {
                    "questionId": "SU-8b",
                    "chosenOptionIds": [
                        "a7206832-dc04-531b-bb17-18b8132ec246"
                    ]
                },
                "SU-9b": {
                    "questionId": "SU-9b",
                    "chosenOptionIds": [
                        "70dd7443-067d-5db4-bbfd-32f5c623fe8a"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "4cf86ba0-867d-5f6d-b8d1-3aa5ceddc374",
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "67d6ef3c-b5a8-5230-b556-3bc42e2e5179"
                    ]
                }
            }
        },
        {
            "name": "screened user: 23X6V0N aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10b": {
                    "questionId": "SU-10b",
                    "chosenOptionIds": [
                        "fe352b2c-eff2-5046-8413-d86aa9192485"
                    ]
                },
                "SU-11b": {
                    "questionId": "SU-11b",
                    "chosenOptionIds": [
                        "1c65112c-5105-5069-a400-b16d985d1bdf"
                    ]
                },
                "SU-12b": {
                    "questionId": "SU-12b",
                    "chosenOptionIds": [
                        "cc068b90-c986-5049-9322-7b8b0fad4143"
                    ]
                },
                "SU-1b": {
                    "questionId": "SU-1b",
                    "freeValue": 14600
                },
                "SU-2b": {
                    "questionId": "SU-2b",
                    "chosenOptionIds": [
                        "f6b6411b-02e6-531d-b2ed-67d6dfacfd96"
                    ]
                },
                "SU-3b": {
                    "questionId": "SU-3b",
                    "chosenOptionIds": [
                        "48eb47f4-b5d0-5ca4-ba6a-5f811f32d488"
                    ]
                },
                "SU-4b": {
                    "questionId": "SU-4b",
                    "chosenOptionIds": [
                        "a55e2d73-6bb1-5ddf-b7f0-be44aa25ea75"
                    ]
                },
                "SU-5b": {
                    "questionId": "SU-5b",
                    "chosenOptionIds": [
                        "a6d3f751-7a06-5eb4-97b5-70831bdb0e3d"
                    ]
                },
                "SU-6b": {
                    "questionId": "SU-6b",
                    "chosenOptionIds": [
                        "e6d0d639-7af5-5de3-9355-51cd24b425c5"
                    ]
                },
                "SU-7b": {
                    "questionId": "SU-7b",
                    "chosenOptionIds": [
                        "fb9594cf-71a8-580c-80d0-18dd9696d5bc"
                    ]
                },
                "SU-8b": {
                    "questionId": "SU-8b",
                    "chosenOptionIds": [
                        "a7206832-dc04-531b-bb17-18b8132ec246"
                    ]
                },
                "SU-9b": {
                    "questionId": "SU-9b",
                    "chosenOptionIds": [
                        "70dd7443-067d-5db4-bbfd-32f5c623fe8a"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f0bf8997-4579-5872-82e1-1400b895417b",
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "67d6ef3c-b5a8-5230-b556-3bc42e2e5179"
                    ]
                }
            }
        },
        {
            "name": "screened user: 50MQK36 aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10a": {
                    "questionId": "SU-10a",
                    "chosenOptionIds": [
                        "ed6c59f2-8713-56b1-a4b2-8f1f85b224ed"
                    ]
                },
                "SU-11a": {
                    "questionId": "SU-11a",
                    "chosenOptionIds": [
                        "57899734-07b2-50a2-a5d8-56142b5feb92"
                    ]
                },
                "SU-1a": {
                    "questionId": "SU-1a",
                    "chosenOptionIds": [
                        "dc9dd01a-e6c0-58c8-9b8f-b3315d6a689c"
                    ]
                },
                "SU-2a": {
                    "questionId": "SU-2a",
                    "chosenOptionIds": [
                        "6d761e88-087b-5887-9e08-7720347f7b48"
                    ]
                },
                "SU-3a": {
                    "questionId": "SU-3a",
                    "chosenOptionIds": [
                        "cba96b30-e2c3-5d5b-80a8-c18a79809f16"
                    ]
                },
                "SU-4a": {
                    "questionId": "SU-4a",
                    "chosenOptionIds": [
                        "ede457f4-731b-56bd-b7b7-58d93ecdcacd"
                    ]
                },
                "SU-5a": {
                    "questionId": "SU-5a",
                    "chosenOptionIds": [
                        "7ec23bf3-d08e-58e1-a88a-9677b0b85ec8"
                    ]
                },
                "SU-6a": {
                    "questionId": "SU-6a",
                    "chosenOptionIds": [
                        "a16682f6-84c6-5bdd-9553-eafb92701f79"
                    ]
                },
                "SU-7a": {
                    "questionId": "SU-7a",
                    "chosenOptionIds": [
                        "698ebf04-9eee-5b77-97f3-dd84b14ac94a"
                    ]
                },
                "SU-8a": {
                    "questionId": "SU-8a",
                    "chosenOptionIds": [
                        "38ede561-e889-51b7-baa3-51217ea1552e"
                    ]
                },
                "SU-9a": {
                    "questionId": "SU-9a",
                    "chosenOptionIds": [
                        "ccb31960-a823-519d-a4a9-7348ba4ca6f5"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "3ed9bf02-1389-5b1a-81e0-5dadb1daa205"
                    ]
                },
                "SU-s3.1": {
                    "questionId": "SU-s3.1",
                    "chosenOptionIds": [
                        "28f722a5-8507-556b-abe2-8413d34679e4"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0N2PEQ aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10a": {
                    "questionId": "SU-10a",
                    "chosenOptionIds": [
                        "dfdb12b6-8e8e-58eb-8a79-3ffaf59b4d9c"
                    ]
                },
                "SU-11a": {
                    "questionId": "SU-11a",
                    "chosenOptionIds": [
                        "57899734-07b2-50a2-a5d8-56142b5feb92"
                    ]
                },
                "SU-1a": {
                    "questionId": "SU-1a",
                    "chosenOptionIds": [
                        "1a337ce3-5ab7-5773-9fe0-f0d1c23ed96c"
                    ]
                },
                "SU-2a": {
                    "questionId": "SU-2a",
                    "chosenOptionIds": [
                        "6d761e88-087b-5887-9e08-7720347f7b48"
                    ]
                },
                "SU-3a": {
                    "questionId": "SU-3a",
                    "chosenOptionIds": [
                        "8b9ec309-bccd-5e75-82c2-0b9f6bbf25fd"
                    ]
                },
                "SU-4a": {
                    "questionId": "SU-4a",
                    "chosenOptionIds": [
                        "3927c90d-af04-584d-a800-515db0f2ec3d"
                    ]
                },
                "SU-5a": {
                    "questionId": "SU-5a",
                    "chosenOptionIds": [
                        "e990acdb-4f26-5f2a-9794-2949aca56fb2"
                    ]
                },
                "SU-6a": {
                    "questionId": "SU-6a",
                    "chosenOptionIds": [
                        "a16682f6-84c6-5bdd-9553-eafb92701f79"
                    ]
                },
                "SU-7a": {
                    "questionId": "SU-7a",
                    "chosenOptionIds": [
                        "85e98c24-bf79-526d-8945-070f551c81c7"
                    ]
                },
                "SU-8a": {
                    "questionId": "SU-8a",
                    "chosenOptionIds": [
                        "38ede561-e889-51b7-baa3-51217ea1552e"
                    ]
                },
                "SU-9a": {
                    "questionId": "SU-9a",
                    "chosenOptionIds": [
                        "16c3b305-ee67-59a9-9182-8d6c929a9509"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "4f2c6cea-7f4c-55ca-b952-334af926e7b1",
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "3ed9bf02-1389-5b1a-81e0-5dadb1daa205"
                    ]
                },
                "SU-s3.1": {
                    "questionId": "SU-s3.1",
                    "chosenOptionIds": [
                        "28f722a5-8507-556b-abe2-8413d34679e4"
                    ]
                }
            }
        },
        {
            "name": "screened user: J3JO40Y aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: 4EGGGEP aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: 2E1JL0D aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: R04JV34 aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: 7EWGK38 aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10a": {
                    "questionId": "SU-10a",
                    "chosenOptionIds": [
                        "dfdb12b6-8e8e-58eb-8a79-3ffaf59b4d9c"
                    ]
                },
                "SU-11a": {
                    "questionId": "SU-11a",
                    "chosenOptionIds": [
                        "57899734-07b2-50a2-a5d8-56142b5feb92"
                    ]
                },
                "SU-1a": {
                    "questionId": "SU-1a",
                    "chosenOptionIds": [
                        "1a337ce3-5ab7-5773-9fe0-f0d1c23ed96c"
                    ]
                },
                "SU-2a": {
                    "questionId": "SU-2a",
                    "chosenOptionIds": [
                        "61e46908-594f-5831-b709-767c60df9f2b"
                    ]
                },
                "SU-3a": {
                    "questionId": "SU-3a",
                    "chosenOptionIds": [
                        "8b9ec309-bccd-5e75-82c2-0b9f6bbf25fd"
                    ]
                },
                "SU-4a": {
                    "questionId": "SU-4a",
                    "chosenOptionIds": [
                        "ede457f4-731b-56bd-b7b7-58d93ecdcacd"
                    ]
                },
                "SU-5a": {
                    "questionId": "SU-5a",
                    "chosenOptionIds": [
                        "e990acdb-4f26-5f2a-9794-2949aca56fb2"
                    ]
                },
                "SU-6a": {
                    "questionId": "SU-6a",
                    "chosenOptionIds": [
                        "a16682f6-84c6-5bdd-9553-eafb92701f79"
                    ]
                },
                "SU-7a": {
                    "questionId": "SU-7a",
                    "chosenOptionIds": [
                        "698ebf04-9eee-5b77-97f3-dd84b14ac94a"
                    ]
                },
                "SU-8a": {
                    "questionId": "SU-8a",
                    "chosenOptionIds": [
                        "38ede561-e889-51b7-baa3-51217ea1552e"
                    ]
                },
                "SU-9a": {
                    "questionId": "SU-9a",
                    "chosenOptionIds": [
                        "16c3b305-ee67-59a9-9182-8d6c929a9509"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "3ed9bf02-1389-5b1a-81e0-5dadb1daa205"
                    ]
                },
                "SU-s3.1": {
                    "questionId": "SU-s3.1",
                    "chosenOptionIds": [
                        "28f722a5-8507-556b-abe2-8413d34679e4"
                    ]
                }
            }
        },
        {
            "name": "screened user: 23X4V3N aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10a": {
                    "questionId": "SU-10a",
                    "chosenOptionIds": [
                        "ed6c59f2-8713-56b1-a4b2-8f1f85b224ed"
                    ]
                },
                "SU-11a": {
                    "questionId": "SU-11a",
                    "chosenOptionIds": [
                        "57899734-07b2-50a2-a5d8-56142b5feb92"
                    ]
                },
                "SU-1a": {
                    "questionId": "SU-1a",
                    "chosenOptionIds": [
                        "dc9dd01a-e6c0-58c8-9b8f-b3315d6a689c"
                    ]
                },
                "SU-2a": {
                    "questionId": "SU-2a",
                    "chosenOptionIds": [
                        "6d761e88-087b-5887-9e08-7720347f7b48"
                    ]
                },
                "SU-3a": {
                    "questionId": "SU-3a",
                    "chosenOptionIds": [
                        "a3720a21-cf5f-5c57-9dde-8cb54605246f"
                    ]
                },
                "SU-4a": {
                    "questionId": "SU-4a",
                    "chosenOptionIds": [
                        "ede457f4-731b-56bd-b7b7-58d93ecdcacd"
                    ]
                },
                "SU-5a": {
                    "questionId": "SU-5a",
                    "chosenOptionIds": [
                        "7ec23bf3-d08e-58e1-a88a-9677b0b85ec8"
                    ]
                },
                "SU-6a": {
                    "questionId": "SU-6a",
                    "chosenOptionIds": [
                        "a16682f6-84c6-5bdd-9553-eafb92701f79"
                    ]
                },
                "SU-7a": {
                    "questionId": "SU-7a",
                    "chosenOptionIds": [
                        "698ebf04-9eee-5b77-97f3-dd84b14ac94a"
                    ]
                },
                "SU-8a": {
                    "questionId": "SU-8a",
                    "chosenOptionIds": [
                        "38ede561-e889-51b7-baa3-51217ea1552e"
                    ]
                },
                "SU-9a": {
                    "questionId": "SU-9a",
                    "chosenOptionIds": [
                        "ccb31960-a823-519d-a4a9-7348ba4ca6f5"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280",
                        "18e8ad9b-e9a7-5311-8a6f-e9e64c5997fe"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "3ed9bf02-1389-5b1a-81e0-5dadb1daa205"
                    ]
                },
                "SU-s3.1": {
                    "questionId": "SU-s3.1",
                    "chosenOptionIds": [
                        "28f722a5-8507-556b-abe2-8413d34679e4"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z35X10O aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: O0K9D01 aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: 23XP2EN aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: 4EGLK3P aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: NEL9J3V aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: PE694EL aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: K399M3L aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10b": {
                    "questionId": "SU-10b",
                    "chosenOptionIds": [
                        "fe352b2c-eff2-5046-8413-d86aa9192485"
                    ]
                },
                "SU-11b": {
                    "questionId": "SU-11b",
                    "chosenOptionIds": [
                        "1c65112c-5105-5069-a400-b16d985d1bdf"
                    ]
                },
                "SU-12b": {
                    "questionId": "SU-12b",
                    "chosenOptionIds": [
                        "cc068b90-c986-5049-9322-7b8b0fad4143"
                    ]
                },
                "SU-1b": {
                    "questionId": "SU-1b",
                    "freeValue": 1095
                },
                "SU-2b": {
                    "questionId": "SU-2b",
                    "chosenOptionIds": [
                        "f6b6411b-02e6-531d-b2ed-67d6dfacfd96"
                    ]
                },
                "SU-3b": {
                    "questionId": "SU-3b",
                    "chosenOptionIds": [
                        "48eb47f4-b5d0-5ca4-ba6a-5f811f32d488"
                    ]
                },
                "SU-4b": {
                    "questionId": "SU-4b",
                    "chosenOptionIds": [
                        "a55e2d73-6bb1-5ddf-b7f0-be44aa25ea75"
                    ]
                },
                "SU-5b": {
                    "questionId": "SU-5b",
                    "chosenOptionIds": [
                        "a6d3f751-7a06-5eb4-97b5-70831bdb0e3d"
                    ]
                },
                "SU-6b": {
                    "questionId": "SU-6b",
                    "chosenOptionIds": [
                        "e6d0d639-7af5-5de3-9355-51cd24b425c5"
                    ]
                },
                "SU-7b": {
                    "questionId": "SU-7b",
                    "chosenOptionIds": [
                        "fb9594cf-71a8-580c-80d0-18dd9696d5bc"
                    ]
                },
                "SU-8b": {
                    "questionId": "SU-8b",
                    "chosenOptionIds": [
                        "a7206832-dc04-531b-bb17-18b8132ec246"
                    ]
                },
                "SU-9b": {
                    "questionId": "SU-9b",
                    "chosenOptionIds": [
                        "70dd7443-067d-5db4-bbfd-32f5c623fe8a"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f0bf8997-4579-5872-82e1-1400b895417b",
                        "18e8ad9b-e9a7-5311-8a6f-e9e64c5997fe",
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "67d6ef3c-b5a8-5230-b556-3bc42e2e5179"
                    ]
                }
            }
        },
        {
            "name": "screened user: 50MGW06 aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: J0VGM0L aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: Y32QME8 aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: O3P770W aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: 7EWZKE8 aspect: sbs_pe should be positive",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "SU-10b": {
                    "questionId": "SU-10b",
                    "chosenOptionIds": [
                        "fe352b2c-eff2-5046-8413-d86aa9192485"
                    ]
                },
                "SU-11b": {
                    "questionId": "SU-11b",
                    "chosenOptionIds": [
                        "1c65112c-5105-5069-a400-b16d985d1bdf"
                    ]
                },
                "SU-12b": {
                    "questionId": "SU-12b",
                    "chosenOptionIds": [
                        "cc068b90-c986-5049-9322-7b8b0fad4143"
                    ]
                },
                "SU-1b": {
                    "questionId": "SU-1b",
                    "freeValue": 365
                },
                "SU-2b": {
                    "questionId": "SU-2b",
                    "chosenOptionIds": [
                        "f6b6411b-02e6-531d-b2ed-67d6dfacfd96"
                    ]
                },
                "SU-3b": {
                    "questionId": "SU-3b",
                    "chosenOptionIds": [
                        "48eb47f4-b5d0-5ca4-ba6a-5f811f32d488"
                    ]
                },
                "SU-4b": {
                    "questionId": "SU-4b",
                    "chosenOptionIds": [
                        "5af10404-91e9-55e5-a3a5-5b0525ebf15c"
                    ]
                },
                "SU-5b": {
                    "questionId": "SU-5b",
                    "chosenOptionIds": [
                        "6e890ffb-ea8c-50f4-87d8-0b9fcb05e77c"
                    ]
                },
                "SU-6b": {
                    "questionId": "SU-6b",
                    "chosenOptionIds": [
                        "e6d0d639-7af5-5de3-9355-51cd24b425c5"
                    ]
                },
                "SU-7b": {
                    "questionId": "SU-7b",
                    "chosenOptionIds": [
                        "fb9594cf-71a8-580c-80d0-18dd9696d5bc"
                    ]
                },
                "SU-8b": {
                    "questionId": "SU-8b",
                    "chosenOptionIds": [
                        "32ec1d91-b47c-5754-a656-c6c0f230ace2"
                    ]
                },
                "SU-9b": {
                    "questionId": "SU-9b",
                    "chosenOptionIds": [
                        "70dd7443-067d-5db4-bbfd-32f5c623fe8a"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "4cf86ba0-867d-5f6d-b8d1-3aa5ceddc374",
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "67d6ef3c-b5a8-5230-b556-3bc42e2e5179"
                    ]
                }
            }
        },
        {
            "name": "screened user: PE6NMEL aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z356NEO aspect: sbs_pe should be positive",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "SU-10b": {
                    "questionId": "SU-10b",
                    "chosenOptionIds": [
                        "fe352b2c-eff2-5046-8413-d86aa9192485"
                    ]
                },
                "SU-11b": {
                    "questionId": "SU-11b",
                    "chosenOptionIds": [
                        "1c65112c-5105-5069-a400-b16d985d1bdf"
                    ]
                },
                "SU-12b": {
                    "questionId": "SU-12b",
                    "chosenOptionIds": [
                        "cc068b90-c986-5049-9322-7b8b0fad4143"
                    ]
                },
                "SU-1b": {
                    "questionId": "SU-1b",
                    "freeValue": 1095
                },
                "SU-2b": {
                    "questionId": "SU-2b",
                    "chosenOptionIds": [
                        "4678ad6e-17f5-504f-84d5-d7e4bd032e1f"
                    ]
                },
                "SU-3b": {
                    "questionId": "SU-3b",
                    "chosenOptionIds": [
                        "97837883-2c07-58ae-87ab-970f4a8e16d7"
                    ]
                },
                "SU-4b": {
                    "questionId": "SU-4b",
                    "chosenOptionIds": [
                        "a55e2d73-6bb1-5ddf-b7f0-be44aa25ea75"
                    ]
                },
                "SU-5b": {
                    "questionId": "SU-5b",
                    "chosenOptionIds": [
                        "61e94409-cc88-5298-9cee-7d1ff14341fa"
                    ]
                },
                "SU-6b": {
                    "questionId": "SU-6b",
                    "chosenOptionIds": [
                        "e6d0d639-7af5-5de3-9355-51cd24b425c5"
                    ]
                },
                "SU-7b": {
                    "questionId": "SU-7b",
                    "chosenOptionIds": [
                        "fb9594cf-71a8-580c-80d0-18dd9696d5bc"
                    ]
                },
                "SU-8b": {
                    "questionId": "SU-8b",
                    "chosenOptionIds": [
                        "a7206832-dc04-531b-bb17-18b8132ec246"
                    ]
                },
                "SU-9b": {
                    "questionId": "SU-9b",
                    "chosenOptionIds": [
                        "70dd7443-067d-5db4-bbfd-32f5c623fe8a"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f0bf8997-4579-5872-82e1-1400b895417b",
                        "4cf86ba0-867d-5f6d-b8d1-3aa5ceddc374",
                        "18e8ad9b-e9a7-5311-8a6f-e9e64c5997fe",
                        "4f2c6cea-7f4c-55ca-b952-334af926e7b1",
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "67d6ef3c-b5a8-5230-b556-3bc42e2e5179"
                    ]
                }
            }
        },
        {
            "name": "screened user: 23XV2EN aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z3R443O aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: PE6Q4EL aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: 6EDW60M aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: J3JV43Y aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: PE6GM3L aspect: sbs_pe should be positive",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "SU-10b": {
                    "questionId": "SU-10b",
                    "chosenOptionIds": [
                        "fe352b2c-eff2-5046-8413-d86aa9192485"
                    ]
                },
                "SU-11b": {
                    "questionId": "SU-11b",
                    "chosenOptionIds": [
                        "1c65112c-5105-5069-a400-b16d985d1bdf"
                    ]
                },
                "SU-12b": {
                    "questionId": "SU-12b",
                    "chosenOptionIds": [
                        "7debcb22-6637-5de5-9421-89be645c3b96"
                    ]
                },
                "SU-1b": {
                    "questionId": "SU-1b",
                    "freeValue": 2555
                },
                "SU-2b": {
                    "questionId": "SU-2b",
                    "chosenOptionIds": [
                        "f6b6411b-02e6-531d-b2ed-67d6dfacfd96"
                    ]
                },
                "SU-3b": {
                    "questionId": "SU-3b",
                    "chosenOptionIds": [
                        "48eb47f4-b5d0-5ca4-ba6a-5f811f32d488"
                    ]
                },
                "SU-4b": {
                    "questionId": "SU-4b",
                    "chosenOptionIds": [
                        "a55e2d73-6bb1-5ddf-b7f0-be44aa25ea75"
                    ]
                },
                "SU-5b": {
                    "questionId": "SU-5b",
                    "chosenOptionIds": [
                        "6e890ffb-ea8c-50f4-87d8-0b9fcb05e77c"
                    ]
                },
                "SU-6b": {
                    "questionId": "SU-6b",
                    "chosenOptionIds": [
                        "e6d0d639-7af5-5de3-9355-51cd24b425c5"
                    ]
                },
                "SU-7b": {
                    "questionId": "SU-7b",
                    "chosenOptionIds": [
                        "fb9594cf-71a8-580c-80d0-18dd9696d5bc"
                    ]
                },
                "SU-8b": {
                    "questionId": "SU-8b",
                    "chosenOptionIds": [
                        "a7206832-dc04-531b-bb17-18b8132ec246"
                    ]
                },
                "SU-9b": {
                    "questionId": "SU-9b",
                    "chosenOptionIds": [
                        "70dd7443-067d-5db4-bbfd-32f5c623fe8a"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "4cf86ba0-867d-5f6d-b8d1-3aa5ceddc374",
                        "4f2c6cea-7f4c-55ca-b952-334af926e7b1",
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "67d6ef3c-b5a8-5230-b556-3bc42e2e5179"
                    ]
                }
            }
        },
        {
            "name": "screened user: 63Y843R aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0NQJ0Q aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: XE7DM0L aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: 23XX43N aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: Y32LM08 aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10b": {
                    "questionId": "SU-10b",
                    "chosenOptionIds": [
                        "fe352b2c-eff2-5046-8413-d86aa9192485"
                    ]
                },
                "SU-11b": {
                    "questionId": "SU-11b",
                    "chosenOptionIds": [
                        "1c65112c-5105-5069-a400-b16d985d1bdf"
                    ]
                },
                "SU-12b": {
                    "questionId": "SU-12b",
                    "chosenOptionIds": [
                        "cc068b90-c986-5049-9322-7b8b0fad4143"
                    ]
                },
                "SU-1b": {
                    "questionId": "SU-1b",
                    "freeValue": 2190
                },
                "SU-2b": {
                    "questionId": "SU-2b",
                    "chosenOptionIds": [
                        "f6b6411b-02e6-531d-b2ed-67d6dfacfd96"
                    ]
                },
                "SU-3b": {
                    "questionId": "SU-3b",
                    "chosenOptionIds": [
                        "48eb47f4-b5d0-5ca4-ba6a-5f811f32d488"
                    ]
                },
                "SU-4b": {
                    "questionId": "SU-4b",
                    "chosenOptionIds": [
                        "a55e2d73-6bb1-5ddf-b7f0-be44aa25ea75"
                    ]
                },
                "SU-5b": {
                    "questionId": "SU-5b",
                    "chosenOptionIds": [
                        "a6d3f751-7a06-5eb4-97b5-70831bdb0e3d"
                    ]
                },
                "SU-6b": {
                    "questionId": "SU-6b",
                    "chosenOptionIds": [
                        "e6d0d639-7af5-5de3-9355-51cd24b425c5"
                    ]
                },
                "SU-7b": {
                    "questionId": "SU-7b",
                    "chosenOptionIds": [
                        "fb9594cf-71a8-580c-80d0-18dd9696d5bc"
                    ]
                },
                "SU-8b": {
                    "questionId": "SU-8b",
                    "chosenOptionIds": [
                        "a7206832-dc04-531b-bb17-18b8132ec246"
                    ]
                },
                "SU-9b": {
                    "questionId": "SU-9b",
                    "chosenOptionIds": [
                        "70dd7443-067d-5db4-bbfd-32f5c623fe8a"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280",
                        "4cf86ba0-867d-5f6d-b8d1-3aa5ceddc374"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "67d6ef3c-b5a8-5230-b556-3bc42e2e5179"
                    ]
                }
            }
        },
        {
            "name": "screened user: J3J4P0Y aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: 2E1580D aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: O3PX60W aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10a": {
                    "questionId": "SU-10a",
                    "chosenOptionIds": [
                        "ed6c59f2-8713-56b1-a4b2-8f1f85b224ed"
                    ]
                },
                "SU-11a": {
                    "questionId": "SU-11a",
                    "chosenOptionIds": [
                        "57899734-07b2-50a2-a5d8-56142b5feb92"
                    ]
                },
                "SU-1a": {
                    "questionId": "SU-1a",
                    "chosenOptionIds": [
                        "64b85f3c-84e5-5d37-bc42-dab6740c8199"
                    ]
                },
                "SU-2a": {
                    "questionId": "SU-2a",
                    "chosenOptionIds": [
                        "6d761e88-087b-5887-9e08-7720347f7b48"
                    ]
                },
                "SU-3a": {
                    "questionId": "SU-3a",
                    "chosenOptionIds": [
                        "cba96b30-e2c3-5d5b-80a8-c18a79809f16"
                    ]
                },
                "SU-4a": {
                    "questionId": "SU-4a",
                    "chosenOptionIds": [
                        "ede457f4-731b-56bd-b7b7-58d93ecdcacd"
                    ]
                },
                "SU-5a": {
                    "questionId": "SU-5a",
                    "chosenOptionIds": [
                        "7ec23bf3-d08e-58e1-a88a-9677b0b85ec8"
                    ]
                },
                "SU-6a": {
                    "questionId": "SU-6a",
                    "chosenOptionIds": [
                        "a16682f6-84c6-5bdd-9553-eafb92701f79"
                    ]
                },
                "SU-7a": {
                    "questionId": "SU-7a",
                    "chosenOptionIds": [
                        "698ebf04-9eee-5b77-97f3-dd84b14ac94a"
                    ]
                },
                "SU-8a": {
                    "questionId": "SU-8a",
                    "chosenOptionIds": [
                        "38ede561-e889-51b7-baa3-51217ea1552e"
                    ]
                },
                "SU-9a": {
                    "questionId": "SU-9a",
                    "chosenOptionIds": [
                        "ccb31960-a823-519d-a4a9-7348ba4ca6f5"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f0bf8997-4579-5872-82e1-1400b895417b",
                        "4cf86ba0-867d-5f6d-b8d1-3aa5ceddc374",
                        "18e8ad9b-e9a7-5311-8a6f-e9e64c5997fe",
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280",
                        "36c77f4a-59a6-588e-8346-b190d1491a17"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "3ed9bf02-1389-5b1a-81e0-5dadb1daa205"
                    ]
                },
                "SU-s3.1": {
                    "questionId": "SU-s3.1",
                    "chosenOptionIds": [
                        "95ba4a6b-7026-5c10-98e9-07370c2b605a"
                    ]
                }
            }
        },
        {
            "name": "screened user: REOQG32 aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: 50MDW06 aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: 7EW6KE8 aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10b": {
                    "questionId": "SU-10b",
                    "chosenOptionIds": [
                        "fe352b2c-eff2-5046-8413-d86aa9192485"
                    ]
                },
                "SU-11b": {
                    "questionId": "SU-11b",
                    "chosenOptionIds": [
                        "1c65112c-5105-5069-a400-b16d985d1bdf"
                    ]
                },
                "SU-12b": {
                    "questionId": "SU-12b",
                    "chosenOptionIds": [
                        "cc068b90-c986-5049-9322-7b8b0fad4143"
                    ]
                },
                "SU-1b": {
                    "questionId": "SU-1b",
                    "freeValue": 365
                },
                "SU-2b": {
                    "questionId": "SU-2b",
                    "chosenOptionIds": [
                        "4678ad6e-17f5-504f-84d5-d7e4bd032e1f"
                    ]
                },
                "SU-3b": {
                    "questionId": "SU-3b",
                    "chosenOptionIds": [
                        "48eb47f4-b5d0-5ca4-ba6a-5f811f32d488"
                    ]
                },
                "SU-4b": {
                    "questionId": "SU-4b",
                    "chosenOptionIds": [
                        "5af10404-91e9-55e5-a3a5-5b0525ebf15c"
                    ]
                },
                "SU-5b": {
                    "questionId": "SU-5b",
                    "chosenOptionIds": [
                        "6e890ffb-ea8c-50f4-87d8-0b9fcb05e77c"
                    ]
                },
                "SU-6b": {
                    "questionId": "SU-6b",
                    "chosenOptionIds": [
                        "e6d0d639-7af5-5de3-9355-51cd24b425c5"
                    ]
                },
                "SU-7b": {
                    "questionId": "SU-7b",
                    "chosenOptionIds": [
                        "fb9594cf-71a8-580c-80d0-18dd9696d5bc"
                    ]
                },
                "SU-8b": {
                    "questionId": "SU-8b",
                    "chosenOptionIds": [
                        "a7206832-dc04-531b-bb17-18b8132ec246"
                    ]
                },
                "SU-9b": {
                    "questionId": "SU-9b",
                    "chosenOptionIds": [
                        "70dd7443-067d-5db4-bbfd-32f5c623fe8a"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "67d6ef3c-b5a8-5230-b556-3bc42e2e5179"
                    ]
                }
            }
        },
        {
            "name": "screened user: J0VQM3L aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: 4EGRG3P aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z35L10O aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: 63YM43R aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: Y325838 aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: XE72M0L aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: ME8MOEW aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10a": {
                    "questionId": "SU-10a",
                    "chosenOptionIds": [
                        "ed6c59f2-8713-56b1-a4b2-8f1f85b224ed"
                    ]
                },
                "SU-11a": {
                    "questionId": "SU-11a",
                    "chosenOptionIds": [
                        "8f999645-02ed-57fb-90c7-c509bdf9217d"
                    ]
                },
                "SU-1a": {
                    "questionId": "SU-1a",
                    "chosenOptionIds": [
                        "64b85f3c-84e5-5d37-bc42-dab6740c8199"
                    ]
                },
                "SU-2a": {
                    "questionId": "SU-2a",
                    "chosenOptionIds": [
                        "6d761e88-087b-5887-9e08-7720347f7b48"
                    ]
                },
                "SU-3a": {
                    "questionId": "SU-3a",
                    "chosenOptionIds": [
                        "a3720a21-cf5f-5c57-9dde-8cb54605246f"
                    ]
                },
                "SU-4a": {
                    "questionId": "SU-4a",
                    "chosenOptionIds": [
                        "dd97a5ec-89cc-5ee7-b89b-644d9f1a2b7b"
                    ]
                },
                "SU-5a": {
                    "questionId": "SU-5a",
                    "chosenOptionIds": [
                        "7ec23bf3-d08e-58e1-a88a-9677b0b85ec8"
                    ]
                },
                "SU-6a": {
                    "questionId": "SU-6a",
                    "chosenOptionIds": [
                        "a16682f6-84c6-5bdd-9553-eafb92701f79"
                    ]
                },
                "SU-7a": {
                    "questionId": "SU-7a",
                    "chosenOptionIds": [
                        "698ebf04-9eee-5b77-97f3-dd84b14ac94a"
                    ]
                },
                "SU-8a": {
                    "questionId": "SU-8a",
                    "chosenOptionIds": [
                        "38ede561-e889-51b7-baa3-51217ea1552e"
                    ]
                },
                "SU-9a": {
                    "questionId": "SU-9a",
                    "chosenOptionIds": [
                        "ccb31960-a823-519d-a4a9-7348ba4ca6f5"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "4cf86ba0-867d-5f6d-b8d1-3aa5ceddc374"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "3ed9bf02-1389-5b1a-81e0-5dadb1daa205"
                    ]
                },
                "SU-s3.1": {
                    "questionId": "SU-s3.1",
                    "chosenOptionIds": [
                        "82702400-306e-52c0-b320-cc03e2f74660"
                    ]
                }
            }
        },
        {
            "name": "screened user: 50M6136 aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z35MQ3O aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: 73ZW63R aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: 4EG443P aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: REOG402 aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10a": {
                    "questionId": "SU-10a",
                    "chosenOptionIds": [
                        "ed6c59f2-8713-56b1-a4b2-8f1f85b224ed"
                    ]
                },
                "SU-11a": {
                    "questionId": "SU-11a",
                    "chosenOptionIds": [
                        "57899734-07b2-50a2-a5d8-56142b5feb92"
                    ]
                },
                "SU-1a": {
                    "questionId": "SU-1a",
                    "chosenOptionIds": [
                        "dc9dd01a-e6c0-58c8-9b8f-b3315d6a689c"
                    ]
                },
                "SU-2a": {
                    "questionId": "SU-2a",
                    "chosenOptionIds": [
                        "6d761e88-087b-5887-9e08-7720347f7b48"
                    ]
                },
                "SU-3a": {
                    "questionId": "SU-3a",
                    "chosenOptionIds": [
                        "a3720a21-cf5f-5c57-9dde-8cb54605246f"
                    ]
                },
                "SU-4a": {
                    "questionId": "SU-4a",
                    "chosenOptionIds": [
                        "ede457f4-731b-56bd-b7b7-58d93ecdcacd"
                    ]
                },
                "SU-5a": {
                    "questionId": "SU-5a",
                    "chosenOptionIds": [
                        "7ec23bf3-d08e-58e1-a88a-9677b0b85ec8"
                    ]
                },
                "SU-6a": {
                    "questionId": "SU-6a",
                    "chosenOptionIds": [
                        "a16682f6-84c6-5bdd-9553-eafb92701f79"
                    ]
                },
                "SU-7a": {
                    "questionId": "SU-7a",
                    "chosenOptionIds": [
                        "698ebf04-9eee-5b77-97f3-dd84b14ac94a"
                    ]
                },
                "SU-8a": {
                    "questionId": "SU-8a",
                    "chosenOptionIds": [
                        "38ede561-e889-51b7-baa3-51217ea1552e"
                    ]
                },
                "SU-9a": {
                    "questionId": "SU-9a",
                    "chosenOptionIds": [
                        "ccb31960-a823-519d-a4a9-7348ba4ca6f5"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "3ed9bf02-1389-5b1a-81e0-5dadb1daa205"
                    ]
                },
                "SU-s3.1": {
                    "questionId": "SU-s3.1",
                    "chosenOptionIds": [
                        "28f722a5-8507-556b-abe2-8413d34679e4"
                    ]
                }
            }
        },
        {
            "name": "screened user: J0V6N3L aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10a": {
                    "questionId": "SU-10a",
                    "chosenOptionIds": [
                        "ed6c59f2-8713-56b1-a4b2-8f1f85b224ed"
                    ]
                },
                "SU-11a": {
                    "questionId": "SU-11a",
                    "chosenOptionIds": [
                        "57899734-07b2-50a2-a5d8-56142b5feb92"
                    ]
                },
                "SU-1a": {
                    "questionId": "SU-1a",
                    "chosenOptionIds": [
                        "dc9dd01a-e6c0-58c8-9b8f-b3315d6a689c"
                    ]
                },
                "SU-2a": {
                    "questionId": "SU-2a",
                    "chosenOptionIds": [
                        "6d761e88-087b-5887-9e08-7720347f7b48"
                    ]
                },
                "SU-3a": {
                    "questionId": "SU-3a",
                    "chosenOptionIds": [
                        "a3720a21-cf5f-5c57-9dde-8cb54605246f"
                    ]
                },
                "SU-4a": {
                    "questionId": "SU-4a",
                    "chosenOptionIds": [
                        "ede457f4-731b-56bd-b7b7-58d93ecdcacd"
                    ]
                },
                "SU-5a": {
                    "questionId": "SU-5a",
                    "chosenOptionIds": [
                        "7ec23bf3-d08e-58e1-a88a-9677b0b85ec8"
                    ]
                },
                "SU-6a": {
                    "questionId": "SU-6a",
                    "chosenOptionIds": [
                        "a16682f6-84c6-5bdd-9553-eafb92701f79"
                    ]
                },
                "SU-7a": {
                    "questionId": "SU-7a",
                    "chosenOptionIds": [
                        "698ebf04-9eee-5b77-97f3-dd84b14ac94a"
                    ]
                },
                "SU-8a": {
                    "questionId": "SU-8a",
                    "chosenOptionIds": [
                        "38ede561-e889-51b7-baa3-51217ea1552e"
                    ]
                },
                "SU-9a": {
                    "questionId": "SU-9a",
                    "chosenOptionIds": [
                        "ccb31960-a823-519d-a4a9-7348ba4ca6f5"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f0bf8997-4579-5872-82e1-1400b895417b",
                        "18e8ad9b-e9a7-5311-8a6f-e9e64c5997fe",
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280",
                        "4cf86ba0-867d-5f6d-b8d1-3aa5ceddc374"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "3ed9bf02-1389-5b1a-81e0-5dadb1daa205"
                    ]
                },
                "SU-s3.1": {
                    "questionId": "SU-s3.1",
                    "chosenOptionIds": [
                        "82702400-306e-52c0-b320-cc03e2f74660",
                        "6492c387-163d-5eb6-a22a-ae666137625e",
                        "28f722a5-8507-556b-abe2-8413d34679e4"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z3R6O0O aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z3R680O aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: R047J04 aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10b": {
                    "questionId": "SU-10b",
                    "chosenOptionIds": [
                        "fe352b2c-eff2-5046-8413-d86aa9192485"
                    ]
                },
                "SU-11b": {
                    "questionId": "SU-11b",
                    "chosenOptionIds": [
                        "1c65112c-5105-5069-a400-b16d985d1bdf"
                    ]
                },
                "SU-12b": {
                    "questionId": "SU-12b",
                    "chosenOptionIds": [
                        "cc068b90-c986-5049-9322-7b8b0fad4143"
                    ]
                },
                "SU-1b": {
                    "questionId": "SU-1b",
                    "freeValue": 5475
                },
                "SU-2b": {
                    "questionId": "SU-2b",
                    "chosenOptionIds": [
                        "69f7eb47-cf61-5bc3-b485-494de2684114"
                    ]
                },
                "SU-3b": {
                    "questionId": "SU-3b",
                    "chosenOptionIds": [
                        "48eb47f4-b5d0-5ca4-ba6a-5f811f32d488"
                    ]
                },
                "SU-4b": {
                    "questionId": "SU-4b",
                    "chosenOptionIds": [
                        "5af10404-91e9-55e5-a3a5-5b0525ebf15c"
                    ]
                },
                "SU-5b": {
                    "questionId": "SU-5b",
                    "chosenOptionIds": [
                        "a6d3f751-7a06-5eb4-97b5-70831bdb0e3d"
                    ]
                },
                "SU-6b": {
                    "questionId": "SU-6b",
                    "chosenOptionIds": [
                        "e6d0d639-7af5-5de3-9355-51cd24b425c5"
                    ]
                },
                "SU-7b": {
                    "questionId": "SU-7b",
                    "chosenOptionIds": [
                        "fb9594cf-71a8-580c-80d0-18dd9696d5bc"
                    ]
                },
                "SU-8b": {
                    "questionId": "SU-8b",
                    "chosenOptionIds": [
                        "a7206832-dc04-531b-bb17-18b8132ec246"
                    ]
                },
                "SU-9b": {
                    "questionId": "SU-9b",
                    "chosenOptionIds": [
                        "70dd7443-067d-5db4-bbfd-32f5c623fe8a"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f0bf8997-4579-5872-82e1-1400b895417b",
                        "4cf86ba0-867d-5f6d-b8d1-3aa5ceddc374",
                        "18e8ad9b-e9a7-5311-8a6f-e9e64c5997fe",
                        "4f2c6cea-7f4c-55ca-b952-334af926e7b1",
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "67d6ef3c-b5a8-5230-b556-3bc42e2e5179"
                    ]
                }
            }
        },
        {
            "name": "screened user: O3PR70W aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10a": {
                    "questionId": "SU-10a",
                    "chosenOptionIds": [
                        "ed6c59f2-8713-56b1-a4b2-8f1f85b224ed"
                    ]
                },
                "SU-11a": {
                    "questionId": "SU-11a",
                    "chosenOptionIds": [
                        "57899734-07b2-50a2-a5d8-56142b5feb92"
                    ]
                },
                "SU-1a": {
                    "questionId": "SU-1a",
                    "chosenOptionIds": [
                        "22270598-ff5e-5785-a9a3-9bbed0fd9751"
                    ]
                },
                "SU-2a": {
                    "questionId": "SU-2a",
                    "chosenOptionIds": [
                        "61e46908-594f-5831-b709-767c60df9f2b"
                    ]
                },
                "SU-3a": {
                    "questionId": "SU-3a",
                    "chosenOptionIds": [
                        "a3720a21-cf5f-5c57-9dde-8cb54605246f"
                    ]
                },
                "SU-4a": {
                    "questionId": "SU-4a",
                    "chosenOptionIds": [
                        "ede457f4-731b-56bd-b7b7-58d93ecdcacd"
                    ]
                },
                "SU-5a": {
                    "questionId": "SU-5a",
                    "chosenOptionIds": [
                        "e990acdb-4f26-5f2a-9794-2949aca56fb2"
                    ]
                },
                "SU-6a": {
                    "questionId": "SU-6a",
                    "chosenOptionIds": [
                        "a16682f6-84c6-5bdd-9553-eafb92701f79"
                    ]
                },
                "SU-7a": {
                    "questionId": "SU-7a",
                    "chosenOptionIds": [
                        "698ebf04-9eee-5b77-97f3-dd84b14ac94a"
                    ]
                },
                "SU-8a": {
                    "questionId": "SU-8a",
                    "chosenOptionIds": [
                        "38ede561-e889-51b7-baa3-51217ea1552e"
                    ]
                },
                "SU-9a": {
                    "questionId": "SU-9a",
                    "chosenOptionIds": [
                        "ccb31960-a823-519d-a4a9-7348ba4ca6f5"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f0bf8997-4579-5872-82e1-1400b895417b",
                        "4cf86ba0-867d-5f6d-b8d1-3aa5ceddc374",
                        "e58d41b9-ba61-5862-88ea-44bbd63946cb",
                        "18e8ad9b-e9a7-5311-8a6f-e9e64c5997fe",
                        "4f2c6cea-7f4c-55ca-b952-334af926e7b1",
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280",
                        "6248c04e-ea14-5b3e-8821-7cdbe8f84be3",
                        "36c77f4a-59a6-588e-8346-b190d1491a17"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "3ed9bf02-1389-5b1a-81e0-5dadb1daa205"
                    ]
                },
                "SU-s3.1": {
                    "questionId": "SU-s3.1",
                    "chosenOptionIds": [
                        "82702400-306e-52c0-b320-cc03e2f74660",
                        "c433cd2b-8136-5f70-abcc-7e0358b27250",
                        "28f722a5-8507-556b-abe2-8413d34679e4",
                        "5e6a1288-43f9-550c-ae2a-982973f4e551"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z35PN3O aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: ME87V3W aspect: sbs_pe should be positive",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "SU-10b": {
                    "questionId": "SU-10b",
                    "chosenOptionIds": [
                        "eb7acd72-f4fa-52a4-b53f-db24e7756bbf"
                    ]
                },
                "SU-11b": {
                    "questionId": "SU-11b",
                    "chosenOptionIds": [
                        "1c65112c-5105-5069-a400-b16d985d1bdf"
                    ]
                },
                "SU-12b": {
                    "questionId": "SU-12b",
                    "chosenOptionIds": [
                        "cc068b90-c986-5049-9322-7b8b0fad4143"
                    ]
                },
                "SU-1b": {
                    "questionId": "SU-1b",
                    "freeValue": 730
                },
                "SU-2b": {
                    "questionId": "SU-2b",
                    "chosenOptionIds": [
                        "4678ad6e-17f5-504f-84d5-d7e4bd032e1f"
                    ]
                },
                "SU-3b": {
                    "questionId": "SU-3b",
                    "chosenOptionIds": [
                        "97837883-2c07-58ae-87ab-970f4a8e16d7"
                    ]
                },
                "SU-4b": {
                    "questionId": "SU-4b",
                    "chosenOptionIds": [
                        "a55e2d73-6bb1-5ddf-b7f0-be44aa25ea75"
                    ]
                },
                "SU-5b": {
                    "questionId": "SU-5b",
                    "chosenOptionIds": [
                        "6c0b7b5f-bac4-5d59-884f-ca8f482f11d4"
                    ]
                },
                "SU-6b": {
                    "questionId": "SU-6b",
                    "chosenOptionIds": [
                        "e6d0d639-7af5-5de3-9355-51cd24b425c5"
                    ]
                },
                "SU-7b": {
                    "questionId": "SU-7b",
                    "chosenOptionIds": [
                        "fb9594cf-71a8-580c-80d0-18dd9696d5bc"
                    ]
                },
                "SU-8b": {
                    "questionId": "SU-8b",
                    "chosenOptionIds": [
                        "a7206832-dc04-531b-bb17-18b8132ec246"
                    ]
                },
                "SU-9b": {
                    "questionId": "SU-9b",
                    "chosenOptionIds": [
                        "70dd7443-067d-5db4-bbfd-32f5c623fe8a"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f0bf8997-4579-5872-82e1-1400b895417b",
                        "4cf86ba0-867d-5f6d-b8d1-3aa5ceddc374",
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280",
                        "18e8ad9b-e9a7-5311-8a6f-e9e64c5997fe"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "67d6ef3c-b5a8-5230-b556-3bc42e2e5179"
                    ]
                }
            }
        },
        {
            "name": "screened user: 2E17NED aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: PE61J0L aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: 50MVM06 aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: J3JK4EY aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: 7EW4D08 aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z3RLO3O aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "8e076e07-f383-5ebe-aafd-63955ee35c27"
                    ]
                }
            }
        },
        {
            "name": "screened user: J3JZREY aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10b": {
                    "questionId": "SU-10b",
                    "chosenOptionIds": [
                        "fe352b2c-eff2-5046-8413-d86aa9192485"
                    ]
                },
                "SU-11b": {
                    "questionId": "SU-11b",
                    "chosenOptionIds": [
                        "1c65112c-5105-5069-a400-b16d985d1bdf"
                    ]
                },
                "SU-12b": {
                    "questionId": "SU-12b",
                    "chosenOptionIds": [
                        "cc068b90-c986-5049-9322-7b8b0fad4143"
                    ]
                },
                "SU-1b": {
                    "questionId": "SU-1b",
                    "freeValue": 9125
                },
                "SU-2b": {
                    "questionId": "SU-2b",
                    "chosenOptionIds": [
                        "f6b6411b-02e6-531d-b2ed-67d6dfacfd96"
                    ]
                },
                "SU-3b": {
                    "questionId": "SU-3b",
                    "chosenOptionIds": [
                        "48eb47f4-b5d0-5ca4-ba6a-5f811f32d488"
                    ]
                },
                "SU-4b": {
                    "questionId": "SU-4b",
                    "chosenOptionIds": [
                        "5af10404-91e9-55e5-a3a5-5b0525ebf15c"
                    ]
                },
                "SU-5b": {
                    "questionId": "SU-5b",
                    "chosenOptionIds": [
                        "a6d3f751-7a06-5eb4-97b5-70831bdb0e3d"
                    ]
                },
                "SU-6b": {
                    "questionId": "SU-6b",
                    "chosenOptionIds": [
                        "e6d0d639-7af5-5de3-9355-51cd24b425c5"
                    ]
                },
                "SU-7b": {
                    "questionId": "SU-7b",
                    "chosenOptionIds": [
                        "fb9594cf-71a8-580c-80d0-18dd9696d5bc"
                    ]
                },
                "SU-8b": {
                    "questionId": "SU-8b",
                    "chosenOptionIds": [
                        "a7206832-dc04-531b-bb17-18b8132ec246"
                    ]
                },
                "SU-9b": {
                    "questionId": "SU-9b",
                    "chosenOptionIds": [
                        "70dd7443-067d-5db4-bbfd-32f5c623fe8a"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "67d6ef3c-b5a8-5230-b556-3bc42e2e5179"
                    ]
                }
            }
        },
        {
            "name": "screened user: XE7WX3L aspect: sbs_pe should be positive",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "SU-10b": {
                    "questionId": "SU-10b",
                    "chosenOptionIds": [
                        "fe352b2c-eff2-5046-8413-d86aa9192485"
                    ]
                },
                "SU-11b": {
                    "questionId": "SU-11b",
                    "chosenOptionIds": [
                        "1c65112c-5105-5069-a400-b16d985d1bdf"
                    ]
                },
                "SU-12b": {
                    "questionId": "SU-12b",
                    "chosenOptionIds": [
                        "7debcb22-6637-5de5-9421-89be645c3b96"
                    ]
                },
                "SU-1b": {
                    "questionId": "SU-1b",
                    "freeValue": 1460
                },
                "SU-2b": {
                    "questionId": "SU-2b",
                    "chosenOptionIds": [
                        "28fd19f5-e24d-5aa5-ab06-4e63101e3ef7"
                    ]
                },
                "SU-3b": {
                    "questionId": "SU-3b",
                    "chosenOptionIds": [
                        "48eb47f4-b5d0-5ca4-ba6a-5f811f32d488"
                    ]
                },
                "SU-4b": {
                    "questionId": "SU-4b",
                    "chosenOptionIds": [
                        "5af10404-91e9-55e5-a3a5-5b0525ebf15c"
                    ]
                },
                "SU-5b": {
                    "questionId": "SU-5b",
                    "chosenOptionIds": [
                        "a6d3f751-7a06-5eb4-97b5-70831bdb0e3d"
                    ]
                },
                "SU-6b": {
                    "questionId": "SU-6b",
                    "chosenOptionIds": [
                        "e6d0d639-7af5-5de3-9355-51cd24b425c5"
                    ]
                },
                "SU-7b": {
                    "questionId": "SU-7b",
                    "chosenOptionIds": [
                        "b6a26449-f023-5a8a-a4c9-a6c625f0f366"
                    ]
                },
                "SU-8b": {
                    "questionId": "SU-8b",
                    "chosenOptionIds": [
                        "a7206832-dc04-531b-bb17-18b8132ec246"
                    ]
                },
                "SU-9b": {
                    "questionId": "SU-9b",
                    "chosenOptionIds": [
                        "70dd7443-067d-5db4-bbfd-32f5c623fe8a"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "67d6ef3c-b5a8-5230-b556-3bc42e2e5179"
                    ]
                }
            }
        },
        {
            "name": "screened user: PE64M0L aspect: sbs_pe should be positive",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "SU-10b": {
                    "questionId": "SU-10b",
                    "chosenOptionIds": [
                        "fe352b2c-eff2-5046-8413-d86aa9192485"
                    ]
                },
                "SU-11b": {
                    "questionId": "SU-11b",
                    "chosenOptionIds": [
                        "1c65112c-5105-5069-a400-b16d985d1bdf"
                    ]
                },
                "SU-12b": {
                    "questionId": "SU-12b",
                    "chosenOptionIds": [
                        "7debcb22-6637-5de5-9421-89be645c3b96"
                    ]
                },
                "SU-1b": {
                    "questionId": "SU-1b",
                    "freeValue": 365
                },
                "SU-2b": {
                    "questionId": "SU-2b",
                    "chosenOptionIds": [
                        "f6b6411b-02e6-531d-b2ed-67d6dfacfd96"
                    ]
                },
                "SU-3b": {
                    "questionId": "SU-3b",
                    "chosenOptionIds": [
                        "48eb47f4-b5d0-5ca4-ba6a-5f811f32d488"
                    ]
                },
                "SU-4b": {
                    "questionId": "SU-4b",
                    "chosenOptionIds": [
                        "5af10404-91e9-55e5-a3a5-5b0525ebf15c"
                    ]
                },
                "SU-5b": {
                    "questionId": "SU-5b",
                    "chosenOptionIds": [
                        "6e890ffb-ea8c-50f4-87d8-0b9fcb05e77c"
                    ]
                },
                "SU-6b": {
                    "questionId": "SU-6b",
                    "chosenOptionIds": [
                        "e6d0d639-7af5-5de3-9355-51cd24b425c5"
                    ]
                },
                "SU-7b": {
                    "questionId": "SU-7b",
                    "chosenOptionIds": [
                        "fb9594cf-71a8-580c-80d0-18dd9696d5bc"
                    ]
                },
                "SU-8b": {
                    "questionId": "SU-8b",
                    "chosenOptionIds": [
                        "a7206832-dc04-531b-bb17-18b8132ec246"
                    ]
                },
                "SU-9b": {
                    "questionId": "SU-9b",
                    "chosenOptionIds": [
                        "70dd7443-067d-5db4-bbfd-32f5c623fe8a"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "36c77f4a-59a6-588e-8346-b190d1491a17"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "67d6ef3c-b5a8-5230-b556-3bc42e2e5179"
                    ]
                }
            }
        },
        {
            "name": "screened user: O3P263W aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10b": {
                    "questionId": "SU-10b",
                    "chosenOptionIds": [
                        "fe352b2c-eff2-5046-8413-d86aa9192485"
                    ]
                },
                "SU-11b": {
                    "questionId": "SU-11b",
                    "chosenOptionIds": [
                        "1c65112c-5105-5069-a400-b16d985d1bdf"
                    ]
                },
                "SU-12b": {
                    "questionId": "SU-12b",
                    "chosenOptionIds": [
                        "cc068b90-c986-5049-9322-7b8b0fad4143"
                    ]
                },
                "SU-1b": {
                    "questionId": "SU-1b",
                    "freeValue": 10950
                },
                "SU-2b": {
                    "questionId": "SU-2b",
                    "chosenOptionIds": [
                        "4678ad6e-17f5-504f-84d5-d7e4bd032e1f"
                    ]
                },
                "SU-3b": {
                    "questionId": "SU-3b",
                    "chosenOptionIds": [
                        "48eb47f4-b5d0-5ca4-ba6a-5f811f32d488"
                    ]
                },
                "SU-4b": {
                    "questionId": "SU-4b",
                    "chosenOptionIds": [
                        "5af10404-91e9-55e5-a3a5-5b0525ebf15c"
                    ]
                },
                "SU-5b": {
                    "questionId": "SU-5b",
                    "chosenOptionIds": [
                        "a6d3f751-7a06-5eb4-97b5-70831bdb0e3d"
                    ]
                },
                "SU-6b": {
                    "questionId": "SU-6b",
                    "chosenOptionIds": [
                        "e6d0d639-7af5-5de3-9355-51cd24b425c5"
                    ]
                },
                "SU-7b": {
                    "questionId": "SU-7b",
                    "chosenOptionIds": [
                        "b6a26449-f023-5a8a-a4c9-a6c625f0f366"
                    ]
                },
                "SU-8b": {
                    "questionId": "SU-8b",
                    "chosenOptionIds": [
                        "a7206832-dc04-531b-bb17-18b8132ec246"
                    ]
                },
                "SU-9b": {
                    "questionId": "SU-9b",
                    "chosenOptionIds": [
                        "70dd7443-067d-5db4-bbfd-32f5c623fe8a"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280",
                        "f0bf8997-4579-5872-82e1-1400b895417b"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "67d6ef3c-b5a8-5230-b556-3bc42e2e5179"
                    ]
                }
            }
        },
        {
            "name": "screened user: NELXPEV aspect: sbs_pe should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SU-10b": {
                    "questionId": "SU-10b",
                    "chosenOptionIds": [
                        "fe352b2c-eff2-5046-8413-d86aa9192485"
                    ]
                },
                "SU-11b": {
                    "questionId": "SU-11b",
                    "chosenOptionIds": [
                        "1c65112c-5105-5069-a400-b16d985d1bdf"
                    ]
                },
                "SU-12b": {
                    "questionId": "SU-12b",
                    "chosenOptionIds": [
                        "cc068b90-c986-5049-9322-7b8b0fad4143"
                    ]
                },
                "SU-1b": {
                    "questionId": "SU-1b",
                    "freeValue": 1460
                },
                "SU-2b": {
                    "questionId": "SU-2b",
                    "chosenOptionIds": [
                        "f6b6411b-02e6-531d-b2ed-67d6dfacfd96"
                    ]
                },
                "SU-3b": {
                    "questionId": "SU-3b",
                    "chosenOptionIds": [
                        "48eb47f4-b5d0-5ca4-ba6a-5f811f32d488"
                    ]
                },
                "SU-4b": {
                    "questionId": "SU-4b",
                    "chosenOptionIds": [
                        "5af10404-91e9-55e5-a3a5-5b0525ebf15c"
                    ]
                },
                "SU-5b": {
                    "questionId": "SU-5b",
                    "chosenOptionIds": [
                        "a6d3f751-7a06-5eb4-97b5-70831bdb0e3d"
                    ]
                },
                "SU-6b": {
                    "questionId": "SU-6b",
                    "chosenOptionIds": [
                        "e6d0d639-7af5-5de3-9355-51cd24b425c5"
                    ]
                },
                "SU-7b": {
                    "questionId": "SU-7b",
                    "chosenOptionIds": [
                        "fb9594cf-71a8-580c-80d0-18dd9696d5bc"
                    ]
                },
                "SU-8b": {
                    "questionId": "SU-8b",
                    "chosenOptionIds": [
                        "a7206832-dc04-531b-bb17-18b8132ec246"
                    ]
                },
                "SU-9b": {
                    "questionId": "SU-9b",
                    "chosenOptionIds": [
                        "70dd7443-067d-5db4-bbfd-32f5c623fe8a"
                    ]
                },
                "SU-s1": {
                    "questionId": "SU-s1",
                    "chosenOptionIds": [
                        "f0bf8997-4579-5872-82e1-1400b895417b",
                        "4cf86ba0-867d-5f6d-b8d1-3aa5ceddc374",
                        "f7b6e3c8-06c1-5881-8da7-7fce9cf7e280"
                    ]
                },
                "SU-s3": {
                    "questionId": "SU-s3",
                    "chosenOptionIds": [
                        "67d6ef3c-b5a8-5230-b556-3bc42e2e5179"
                    ]
                }
            }
        }
    ]
};
exports.questionPredicates = {
    "SU-s3": "and[or[isUndefined(SUs2_=1), not(SUs2_=1)], not(isUndefined(SU-s1=0))]",
    "SU-s3.1": "not(SU-s3=0)",
    "SU-1a": "not(SU-s3=0)",
    "SU-2a": "not(SU-s3=0)",
    "SU-3a": "not(SU-s3=0)",
    "SU-4a": "not(SU-s3=0)",
    "SU-5a": "not(SU-s3=0)",
    "SU-6a": "not(SU-s3=0)",
    "SU-7a": "not(SU-s3=0)",
    "SU-8a": "not(SU-s3=0)",
    "SU-9a": "not(SU-s3=0)",
    "SU-10a": "not(SU-s3=0)",
    "SU-11a": "not(SU-s3=0)",
    "SU-1b": "SU-s3=0",
    "SU-2b": "SU-s3=0",
    "SU-3b": "SU-s3=0",
    "SU-4b": "SU-s3=0",
    "SU-5b": "SU-s3=0",
    "SU-6b": "SU-s3=0",
    "SU-7b": "SU-s3=0",
    "SU-8b": "SU-s3=0",
    "SU-9b": "SU-s3=0",
    "SU-10b": "SU-s3=0",
    "SU-11b": "SU-s3=0",
    "SU-12b": "SU-s3=0"
};
