"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.questionPredicates = exports.tests = exports.aspects = exports.questions = void 0;
const censeo_1 = require("../../schema/generated/censeo");
exports.questions = [
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "7f1aeb20-7b53-50bd-98c3-05a188150863",
                "text": "Continue",
                "value": 1
            }
        ],
        "id": "SH-0",
        "text": "Thank you for answering questions about a difficult and distressing experience you have had. If you feel that you need to take a break, you can leave the page and return later. All your answers are saved automatically.\n\nIf you ever feel the need to talk to someone right away, or you need urgent help, please use the crisis contact information that can be found by clicking the 'Crisis' icon in the top left-hand corner. \n\nWhen you are ready to start the next question, please click 'Continue'.",
        "isMultiselect": false,
        "userType": censeo_1.UserType.Nhs
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "a755d608-919a-58ed-9230-2785f62dd1fc",
                "text": "Continue",
                "value": 1
            }
        ],
        "id": "SH-S",
        "text": "This set of questions will ask about whether you have ever attempted to harm yourself. You can take a break at any point, as your answers are saved automatically.",
        "isMultiselect": false,
        "userType": censeo_1.UserType.Nhs
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "519336e4-f9db-552e-a464-11ea704d6288",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "981f1af5-5769-5932-8778-58a6e01aa624",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "SH-s1",
        "text": "Has there ever been a time when you harmed yourself?",
        "isMultiselect": false,
        "userType": censeo_1.UserType.Nhs
    },
    {
        "__typename": "FreeQuestion",
        "id": "SH-s2",
        "text": "How old were you when you first self-harmed?",
        "unit": censeo_1.Unit.Age,
        "userType": censeo_1.UserType.Nhs
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "e2e1bae1-c85a-5ab1-9b76-10e31f65a40e",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "29309292-72fb-5781-ae9a-973977aac3af",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "SH-s3",
        "text": "Have you self-harmed in the last year?",
        "isMultiselect": false,
        "userType": censeo_1.UserType.Nhs
    },
    {
        "__typename": "FreeQuestion",
        "id": "SH-1a",
        "text": "Approximately, how many times have you harmed yourself in the last year?",
        "unit": censeo_1.Unit.None,
        "userType": censeo_1.UserType.Nhs
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "38c7a9b9-d5a0-5a57-b6fd-4b8f2c7499a8",
                "text": "Continue",
                "value": 1
            }
        ],
        "id": "SH-1.5a",
        "text": "We are very sorry that you have had such a difficult time recently. If it is not too distressing for you, we would like to ask you a few more questions. These might be hard to answer, but it will help us understand what you are going through so you can reach the right support.\n\nIf at any stage you feel like you need to talk to someone or you need urgent help, you can click the 'Crisis' icon in the top left-hand corner to see our crisis contact information. \n\nIf you want to take a break, you can leave the page and return later. All your answers are saved automatically.\n\nWhen you are ready to start the next question, please click 'Continue'.",
        "isMultiselect": false,
        "userType": censeo_1.UserType.Nhs
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "4992447e-a229-5af8-ba74-0d205735ec32",
                "text": "Within the last day",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "35d92f8a-6922-5810-a862-81370a7bb8a5",
                "text": "A couple of days ago",
                "value": 0.2
            },
            {
                "__typename": "Option",
                "id": "b1efc312-9d2e-5ca8-88ba-b4b2392d1bc8",
                "text": "A week ago",
                "value": 0.4
            },
            {
                "__typename": "Option",
                "id": "c81ca000-63f4-5d4d-b175-ed7c74561574",
                "text": "Several weeks ago",
                "value": 0.6
            },
            {
                "__typename": "Option",
                "id": "5d85eb1d-b45b-51cb-9405-1a914df66de6",
                "text": "A month ago",
                "value": 0.8
            },
            {
                "__typename": "Option",
                "id": "fe25eeda-81fa-58a3-9c72-545aabe803f5",
                "text": "Several months ago",
                "value": 1
            }
        ],
        "id": "SH-2a",
        "text": "When was the last time you self-harmed?",
        "isMultiselect": false,
        "userType": censeo_1.UserType.Nhs
    },
    {
        "__typename": "FreeQuestion",
        "id": "SH-4a",
        "text": "In the last year, what has been the longest time when you did not harm yourself? If you can't remember exactly, please give your best estimate.",
        "unit": censeo_1.Unit.DurationMinutes,
        "userType": censeo_1.UserType.Nhs
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "6618bab9-cf95-5c64-8182-e64a43ae5a5e",
                "text": "Cutting/burning/pinching or using bleach/acid/similar on the skin"
            },
            {
                "__typename": "Option",
                "id": "9f789285-dcea-547c-8aa8-b37291bcfae2",
                "text": "Pulling hair"
            },
            {
                "__typename": "Option",
                "id": "bf613ed8-0c2a-5ac7-8b03-abfb31ce786b",
                "text": "Banging/punching body"
            },
            {
                "__typename": "Option",
                "id": "a770529d-17f7-5b21-8f7b-3b508e9cb983",
                "text": "Self-poisoning with prescription drugs, recreational drugs, alcohol or other potentially dangerous substances"
            },
            {
                "__typename": "Option",
                "id": "fe387d52-05f9-5b58-bd76-e284e0d87378",
                "text": "Breaking bones"
            },
            {
                "__typename": "Option",
                "id": "f603cb36-6f7d-5511-a4ab-2f13f22a9f3e",
                "text": "Inserting objects into your body"
            },
            {
                "__typename": "Option",
                "id": "b28d2db7-a851-5734-bf54-0ad05544946b",
                "text": "Other"
            },
            {
                "__typename": "Option",
                "id": "f67da8e8-7a80-5726-895d-710472c17bc0",
                "text": "None of the above",
                "exclusive": true
            }
        ],
        "id": "SH-6a",
        "text": "How have you intentionally harmed your body? Please select all that apply.",
        "isMultiselect": true,
        "userType": censeo_1.UserType.Nhs
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "372a1173-b5c2-502f-9237-df57eebd80f2",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "f3060ab6-8ac7-559d-838c-a58eeb5d018e",
                "text": "Yes, I required medical assistance but was not hospitalised",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "19c53256-c3a5-5f7a-8020-fffb42c83733",
                "text": "Yes, I required medical assistance and was hospitalised",
                "value": 1
            }
        ],
        "id": "SH-16a",
        "text": "Have you ever received medical assistance or needed to be hospitalised for self-harm?",
        "isMultiselect": false,
        "userType": censeo_1.UserType.Nhs
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "e5fa1386-2290-5c3f-990e-962ef0b4f10c",
                "text": "Not at all likely",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "3e347ed1-7ea3-5b21-964f-32cf2d32e1b5",
                "text": "Slightly likely",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "5738b269-a761-5967-a3a9-e5861936cc74",
                "text": "Moderately likely",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "025fa831-b50c-51c6-8ece-7f851af56e4f",
                "text": "Very likely",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "83e881fd-401e-5ce9-9121-1472f92adb82",
                "text": "Extremely likely",
                "value": 1
            }
        ],
        "id": "SH-23a",
        "text": "How likely are you to harm yourself again?",
        "isMultiselect": false,
        "userType": censeo_1.UserType.Nhs
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "a80457e5-70be-5103-ad49-69a47f84d227",
                "text": "Continue",
                "value": 1
            }
        ],
        "id": "SH-23.5a",
        "text": "If you feel the urge to harm yourself again and would like to talk to someone or need urgent help, you can access our crisis contact information by clicking the 'Crisis' icon in the top left-hand corner.\n\nIf you want to take a break, you can leave the page and return later. All your answers are saved automatically.\n\nWhen you are ready to start the next question, please click 'Continue'.",
        "isMultiselect": false,
        "userType": censeo_1.UserType.Nhs
    },
    {
        "__typename": "FreeQuestion",
        "id": "SH-2b",
        "text": "In reference to your previous periods of self-harm:\n\nWhen was the last time you harmed yourself?",
        "unit": censeo_1.Unit.DurationHours,
        "userType": censeo_1.UserType.Nhs
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "6398c0aa-f204-57b5-b75c-e16d73e69cc3",
                "text": "Less than once per year",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "8a529b74-7040-57bc-aed3-154d0b92c82f",
                "text": "Several times per year",
                "value": 0.2
            },
            {
                "__typename": "Option",
                "id": "11ac7b73-fd3c-5d41-8801-855cb0f008b3",
                "text": "Several times per month",
                "value": 0.4
            },
            {
                "__typename": "Option",
                "id": "7083624e-4e8c-5008-81e4-0a56589a2081",
                "text": "Once per week",
                "value": 0.6
            },
            {
                "__typename": "Option",
                "id": "bc84679c-383a-5378-bd6b-3c8ed392c8fe",
                "text": "Several times per week",
                "value": 0.8
            },
            {
                "__typename": "Option",
                "id": "6d7b7291-09f2-56fe-8574-5b274c2c4c28",
                "text": "Several times per day",
                "value": 1
            }
        ],
        "id": "SH-3b",
        "text": "In reference to your previous periods of self-harm:\n\nWhen you felt the most distressed, how often did you harm yourself?",
        "isMultiselect": false,
        "userType": censeo_1.UserType.Nhs
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "7feeabee-bfe8-56cd-b16c-fd4199552577",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "db90d397-9d20-53d9-86ee-b1290cf5610f",
                "text": "Yes, I required medical assistance but was not hospitalised",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "4c4f089f-452e-5a64-ae76-c6aa84efe4eb",
                "text": "Yes, I was required medical assistance and was hospitalised",
                "value": 1
            }
        ],
        "id": "SH-16b",
        "text": "In reference to your previous periods of self-harm:\n\nDid you receive medical assistance because you harmed yourself?",
        "isMultiselect": false,
        "userType": censeo_1.UserType.Nhs
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "a5170112-22fd-5d89-96da-1f1d9abd6a28",
                "text": "Not at all likely",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "fe7c8387-4f0e-5e3f-a41c-9789da592eae",
                "text": "Slightly likely",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "47b9e301-af3c-5127-8396-1153c6696909",
                "text": "Moderately likely",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "acaeb2ca-33f6-5651-aa86-2de5e3de9d9d",
                "text": "Very likely",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "b3418704-a5d7-5927-87f8-23ae8b5ab663",
                "text": "Extremely likely",
                "value": 1
            }
        ],
        "id": "SH-23b",
        "text": "In reference to your previous periods of self-harm:\n\nCurrently, how likely is it that you might harm yourself now or in the near future?",
        "isMultiselect": false,
        "userType": censeo_1.UserType.Nhs
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "d12b412b-12f2-5463-add9-97278919536f",
                "text": "Continue",
                "value": 1
            }
        ],
        "id": "SH-23.5b",
        "text": "If you feel the urge to harm yourself again and would like to talk to someone or need urgent help, please see our crisis information by clicking the 'Crisis' icon in the top left-hand corner.",
        "isMultiselect": false,
        "userType": censeo_1.UserType.Nhs
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "9e3bfb5c-e1f9-565f-9521-261bdb19a460",
                "text": "Continue",
                "value": 1
            }
        ],
        "id": "S-S",
        "text": "The next questions are about some potentially difficult experiences and feelings that you may have had.\n\nThe first part of this session will ask whether you have had any thoughts about not wanting to live anymore. These thoughts are common, and many people experience them as part of their mental health concerns. The questions might be difficult to answer, but it will help us better understand what you are going through so you can reach the right support. If you are in distress and urgently need to talk to someone, please click on the crisis icon in the top left-hand corner.\nRemember you can take a break at any point, as your answers are saved automatically.",
        "isMultiselect": false,
        "userType": censeo_1.UserType.Nhs
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "59a1b51e-3319-5c4d-a9f4-e50455a6f5a6",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "00f4664d-929e-5301-bd5b-f405a7ab8ce4",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "S-s1",
        "text": "Have you ever felt that life was not worth living?",
        "isMultiselect": false,
        "userType": censeo_1.UserType.Nhs
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "07c32652-123e-5f58-894e-f4ed9c8b110e",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "10e008b4-feb5-5134-9869-3dfc46cb9924",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "S-s2",
        "text": "Have you ever wished you were dead or wished you weren't alive anymore?",
        "isMultiselect": false,
        "userType": censeo_1.UserType.Nhs
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "e90b0a3b-f835-5a9b-948b-d1b685da2c2e",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "S-s3",
        "text": "Have you ever felt like ending it all?",
        "isMultiselect": false,
        "userType": censeo_1.UserType.Nhs
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "e9fe5141-3a28-5ca3-9d76-2f51202a68b6",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "48d1552a-30b1-5dee-a6f1-f9e12ced08a2",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "S-s4",
        "text": "Are you having any thoughts about suicide at the moment?",
        "isMultiselect": false,
        "userType": censeo_1.UserType.Nhs
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "455f8af3-f57a-5fe0-808e-aad6d2439855",
                "text": "Continue",
                "value": 1
            }
        ],
        "id": "S-2.5a",
        "text": "We are sorry that you are having troubling thoughts of wanting to end your life and that you are going through a difficult time at the moment. If it is not too distressing for you, we would like to ask you a few more questions.\n\nIf you feel like you are currently not able to keep yourself safe and need to talk to someone right now, or need urgent help, please click the 'Crisis' icon in the top-left corner to access our crisis contact information.\n\nIf you want to take a break, all your answers are saved automatically.\n\nWhen you are ready to start the next question, please click 'Continue'.",
        "isMultiselect": false,
        "userType": censeo_1.UserType.Nhs
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "890e7c6f-4bdf-5d6c-87e4-d2fc93a63102",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "e8bbf23a-7af1-5ec5-a666-e81bbeed6043",
                "text": "Yes",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "81b3f713-07b4-5d30-8066-60c1326c2aa1",
                "text": "I am not sure",
                "value": 1
            }
        ],
        "id": "S-18a",
        "text": "Currently, do you think you are able to keep yourself safe?",
        "isMultiselect": false,
        "userType": censeo_1.UserType.Nhs
    },
    {
        "__typename": "FreeQuestion",
        "id": "S-1a",
        "text": "Approximately, how long have you been having these thoughts about taking your own life? Don't worry if you are not sure, please just give your best estimate.",
        "unit": censeo_1.Unit.DurationHours,
        "userType": censeo_1.UserType.Nhs
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "5cd8930b-afc1-5d1e-9ba3-97c0036809b5",
                "text": "Not at all intrusive or troubling",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "fccc47c0-713b-578c-a123-73b81b4d186d",
                "text": "Slightly intrusive or troubling",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "51f2de30-69cd-5a83-85a4-2e3310861a6f",
                "text": "Moderately intrusive or troubling",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "541369ff-8077-58e5-8bf7-90a8a94d14bb",
                "text": "Very intrusive or troubling",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "84b1dac4-ea2f-5f51-a205-0efe9d0e6c8c",
                "text": "Extremely intrusive or troubling",
                "value": 1
            }
        ],
        "id": "S-2a",
        "text": "How intrusive or troubling do you find these thoughts about taking your own life?",
        "isMultiselect": false,
        "userType": censeo_1.UserType.Nhs
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "1ffa1a8c-d1e3-54ab-9551-deb6e599c67f",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "dada9607-7e9d-565b-8073-73b780535bbe",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "5627de59-f614-56c2-887e-4c153fb61012",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "131ae75d-c65c-5204-9e13-603a7cd9123d",
                "text": "Very much so",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "2ad36ca1-d48c-57c8-a058-9de92c14e840",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "S-4a",
        "text": "Do you feel that you are a burden to other people and that they will be better off without you?",
        "isMultiselect": false,
        "userType": censeo_1.UserType.Nhs
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "0e07d0fe-f085-5440-b0f4-a5c8345833d3",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "86839467-8345-58f0-9355-a14ba91c7079",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "S-5a",
        "text": "Has there been a recent difficult event that has triggered the thoughts about wanting to die?",
        "isMultiselect": false,
        "userType": censeo_1.UserType.Nhs
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "c6b26b20-786e-537b-89bc-71c4c3155678",
                "text": "Not at all likely",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "c0db0893-6906-5a21-ab74-144c85c0683d",
                "text": "Slightly likely",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "0667075d-d44a-5ec5-8a05-4d25659c831d",
                "text": "Moderately likely",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "870c5cac-fb60-51ca-9d26-bf9e93d56ab2",
                "text": "Very likely",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "0181dcdd-3d3d-512a-bdf8-de8a996bd7e8",
                "text": "Extremely likely",
                "value": 1
            }
        ],
        "id": "S-6a",
        "text": "Do you think that these thoughts of wanting to die are something you will act on?",
        "isMultiselect": false,
        "userType": censeo_1.UserType.Nhs
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "cb6c8c06-d0b8-558d-8e33-bf475718cb0d",
                "text": "No, I haven't worked out any details and I don't want to",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "93743584-8c8b-5a15-afa2-95a24c811beb",
                "text": "No, I haven't worked out any details, but I am going to",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "4f14f047-a4d6-5709-95b7-4742bec76fa7",
                "text": "I have made some vague plans, but no specific details",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "fb782e60-165b-5cd2-ae70-c40b281bc387",
                "text": "I have worked out all the specific details",
                "value": 1
            }
        ],
        "id": "S-8a",
        "text": "Have you got a plan of how you would take your own life?",
        "isMultiselect": false,
        "userType": censeo_1.UserType.Nhs
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "35639af8-738a-5ba6-bf47-d0fa52511234",
                "text": "Continue",
                "value": 1
            }
        ],
        "id": "S-13.0a",
        "text": "The next questions are of a particularly sensitive nature and will ask about previous suicidal behaviours, so please take your time and if you wish to take a break, all your answers are saved automatically.",
        "isMultiselect": false,
        "userType": censeo_1.UserType.Nhs
    },
    {
        "__typename": "FreeQuestion",
        "id": "S-3a",
        "text": "How many distinct periods have there been in the past during which you experienced thoughts related to wanting to die?",
        "unit": censeo_1.Unit.None,
        "userType": censeo_1.UserType.Nhs
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "8e2bdd16-a97a-5130-8f6e-edb8dd52afe3",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "fadcf643-dd8c-5f24-90d5-f971cf3c6ec7",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "S-13a",
        "text": "Have you previously tried to take your own life?",
        "isMultiselect": false,
        "userType": censeo_1.UserType.Nhs
    },
    {
        "__typename": "FreeQuestion",
        "id": "S-14a",
        "text": "When did you last attempt to take your own life? If it is difficult to remember exactly, please give your best estimate.",
        "unit": censeo_1.Unit.DurationHours,
        "userType": censeo_1.UserType.Nhs
    },
    {
        "__typename": "FreeQuestion",
        "id": "S-15a",
        "text": "How many times in the last 12 months have you attempted to take your own life?",
        "unit": censeo_1.Unit.None,
        "userType": censeo_1.UserType.Nhs
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "56bb1b4f-6a67-505a-980b-b4b9bb1792e3",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "7bd78121-d1a4-5bd7-8be2-eefa7e976604",
                "text": "Yes, I required medical assistance but was not hospitalised",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "efb070b8-83e6-535e-bf20-76b25b83a770",
                "text": "Yes, I required medical assistance and was hospitalised",
                "value": 1
            }
        ],
        "id": "S-17a",
        "text": "After your previous attempt(s) to take your own life, did you need hospitalisation or medical assistance?",
        "isMultiselect": false,
        "userType": censeo_1.UserType.Nhs
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "9e611ece-c2d6-5900-8832-3cbf939f729d",
                "text": "Continue",
                "value": 1
            }
        ],
        "id": "S-9.5a",
        "text": "We know that thoughts about death and suicide can be overwhelming. If you are not able to keep yourself safe and need urgent help or need to talk to someone right away, please click the 'Crisis' icon in the top left-hand corner to access crisis contact information.\n\nIf you want to take a break, all your answers are saved automatically.\n\nWhen you are ready to start the next question, please click 'Continue'.",
        "isMultiselect": false,
        "userType": censeo_1.UserType.Nhs
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "28660364-460b-5f7f-a281-104044331d08",
                "text": "No, I don't have anyone to contact",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "c5321fad-d3b3-58d2-979b-11ca81e25dbf",
                "text": "Yes, but I would not contact them",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "f18f48b0-a401-5787-bb00-ca8d99523304",
                "text": "Yes, and I will contact them",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "9bce9d08-d129-5989-8b7b-5612590dc430",
                "text": "Yes, and I have contacted them",
                "value": 1
            }
        ],
        "id": "S-22a",
        "text": "Do you have someone you can talk to for help and support?",
        "isMultiselect": false,
        "userType": censeo_1.UserType.Nhs
    }
];
exports.aspects = {
    "sh_current": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "has_sh"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "sh_c"
                }
            }
        ],
        "anyToAll": 1
    },
    "sh_past": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "has_sh"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "sh_p"
                }
            }
        ],
        "anyToAll": 1
    },
    "has_sh": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SH-s1",
            "options": {
                "type": "predicates",
                "predicates": {
                    "519336e4-f9db-552e-a464-11ea704d6288": false,
                    "981f1af5-5769-5932-8778-58a6e01aa624": true
                }
            }
        }
    },
    "sh_c": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "SH-s3",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "e2e1bae1-c85a-5ab1-9b76-10e31f65a40e": false,
                            "29309292-72fb-5781-ae9a-973977aac3af": true
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "SH-23b",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "a5170112-22fd-5d89-96da-1f1d9abd6a28": 0.0066928509242848554,
                            "fe7c8387-4f0e-5e3f-a41c-9789da592eae": 0.07585818002124355,
                            "47b9e301-af3c-5127-8396-1153c6696909": 0.5,
                            "acaeb2ca-33f6-5651-aa86-2de5e3de9d9d": 0.9241418199787566,
                            "b3418704-a5d7-5927-87f8-23ae8b5ab663": 0.9933071490757153
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.5
    },
    "sh_p": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "sh_c"
            }
        }
    },
    "s_current": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "has_s"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "s_c"
                }
            }
        ],
        "anyToAll": 1
    },
    "s_past": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "has_s"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "s_p"
                }
            }
        ],
        "anyToAll": 1
    },
    "has_s": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "S-s1",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "59a1b51e-3319-5c4d-a9f4-e50455a6f5a6": false,
                            "00f4664d-929e-5301-bd5b-f405a7ab8ce4": true
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "S-s2",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "07c32652-123e-5f58-894e-f4ed9c8b110e": false,
                            "10e008b4-feb5-5134-9869-3dfc46cb9924": true
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "S-s3",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35": false,
                            "e90b0a3b-f835-5a9b-948b-d1b685da2c2e": true
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.3333333333333333
    },
    "s_c": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "S-s4",
            "options": {
                "type": "predicates",
                "predicates": {
                    "e9fe5141-3a28-5ca3-9d76-2f51202a68b6": false,
                    "48d1552a-30b1-5dee-a6f1-f9e12ced08a2": true
                }
            }
        }
    },
    "s_p": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "s_c"
            }
        }
    },
    "is_urgent": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "intent_no_attempt"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "intent_attempt"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "dep_sev_attempt"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "sh_recent"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "sh_severe"
                }
            }
        ],
        "anyToAll": 0.2
    },
    "intent_no_attempt": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "S-6a",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "c6b26b20-786e-537b-89bc-71c4c3155678": 0.0066928509242848554,
                            "c0db0893-6906-5a21-ab74-144c85c0683d": 0.07585818002124355,
                            "0667075d-d44a-5ec5-8a05-4d25659c831d": 0.5,
                            "870c5cac-fb60-51ca-9d26-bf9e93d56ab2": 0.9241418199787566,
                            "0181dcdd-3d3d-512a-bdf8-de8a996bd7e8": 0.9933071490757153
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "has_s"
                }
            }
        ],
        "anyToAll": 1
    },
    "intent_attempt": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "S-6a",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "c6b26b20-786e-537b-89bc-71c4c3155678": 0.07585818002124355,
                            "c0db0893-6906-5a21-ab74-144c85c0683d": 0.5,
                            "0667075d-d44a-5ec5-8a05-4d25659c831d": 0.9241418199787566,
                            "870c5cac-fb60-51ca-9d26-bf9e93d56ab2": 0.9933071490757153,
                            "0181dcdd-3d3d-512a-bdf8-de8a996bd7e8": 0.9994472213630764
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "S-13a",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "8e2bdd16-a97a-5130-8f6e-edb8dd52afe3": false,
                            "fadcf643-dd8c-5f24-90d5-f971cf3c6ec7": true
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "has_s"
                }
            }
        ],
        "anyToAll": 1
    },
    "dep_sev_attempt": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "has_s"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "S-13a",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "8e2bdd16-a97a-5130-8f6e-edb8dd52afe3": false,
                            "fadcf643-dd8c-5f24-90d5-f971cf3c6ec7": true
                        }
                    }
                }
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "bd1_cd_sv"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "bd2_cd_sv"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_cd_sv"
                        }
                    }
                ],
                "anyToAll": 0.3333333333333333
            }
        ],
        "anyToAll": 1
    },
    "sh_recent": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SH-2a",
            "options": {
                "type": "predicates",
                "predicates": {
                    "4992447e-a229-5af8-ba74-0d205735ec32": true,
                    "35d92f8a-6922-5810-a862-81370a7bb8a5": false,
                    "b1efc312-9d2e-5ca8-88ba-b4b2392d1bc8": false,
                    "c81ca000-63f4-5d4d-b175-ed7c74561574": false,
                    "5d85eb1d-b45b-51cb-9405-1a914df66de6": false,
                    "fe25eeda-81fa-58a3-9c72-545aabe803f5": false
                }
            }
        }
    },
    "sh_severe": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "SH8a_poison"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "SH-2a",
                            "options": {
                                "type": "severities",
                                "severities": {
                                    "4992447e-a229-5af8-ba74-0d205735ec32": 0.9975273768433652,
                                    "35d92f8a-6922-5810-a862-81370a7bb8a5": 0.9820137900379085,
                                    "b1efc312-9d2e-5ca8-88ba-b4b2392d1bc8": 0.8807970779778824,
                                    "c81ca000-63f4-5d4d-b175-ed7c74561574": 0.5,
                                    "5d85eb1d-b45b-51cb-9405-1a914df66de6": 0.11920292202211746,
                                    "fe25eeda-81fa-58a3-9c72-545aabe803f5": 0.01798620996209155
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 1
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "SH8a_gas"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "SH-2a",
                            "options": {
                                "type": "severities",
                                "severities": {
                                    "4992447e-a229-5af8-ba74-0d205735ec32": 0.9975273768433652,
                                    "35d92f8a-6922-5810-a862-81370a7bb8a5": 0.9820137900379085,
                                    "b1efc312-9d2e-5ca8-88ba-b4b2392d1bc8": 0.8807970779778824,
                                    "c81ca000-63f4-5d4d-b175-ed7c74561574": 0.5,
                                    "5d85eb1d-b45b-51cb-9405-1a914df66de6": 0.11920292202211746,
                                    "fe25eeda-81fa-58a3-9c72-545aabe803f5": 0.01798620996209155
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 1
            }
        ],
        "anyToAll": 0.5
    },
    "suish_csui_csh": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "s_current"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "sh_current"
                }
            }
        ],
        "anyToAll": 1
    },
    "suish_csui_psh": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "s_current"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "sh_past"
                }
            }
        ],
        "anyToAll": 1
    },
    "suish_psui_csh": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "s_past"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "sh_current"
                }
            }
        ],
        "anyToAll": 1
    },
    "suish_psui_psh": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "s_past"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "sh_past"
                }
            }
        ],
        "anyToAll": 1
    },
    "suish_csui": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "s_current"
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_sh"
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "suish_psui": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "s_past"
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_sh"
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "suish_csh": {
        "type": "merge",
        "wires": [
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_s"
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "sh_current"
                }
            }
        ],
        "anyToAll": 1
    },
    "suish_psh": {
        "type": "merge",
        "wires": [
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_s"
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "sh_past"
                }
            }
        ],
        "anyToAll": 1
    },
    "suish_none": {
        "type": "merge",
        "wires": [
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_s"
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_sh"
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "SH9a_skin": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SH-6a",
            "options": {
                "type": "severities",
                "severities": {
                    "6618bab9-cf95-5c64-8182-e64a43ae5a5e": 1
                }
            }
        }
    },
    "SH6a_hair": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SH-6a",
            "options": {
                "type": "severities",
                "severities": {
                    "9f789285-dcea-547c-8aa8-b37291bcfae2": 1
                }
            }
        }
    },
    "SH7a_body": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SH-6a",
            "options": {
                "type": "severities",
                "severities": {
                    "bf613ed8-0c2a-5ac7-8b03-abfb31ce786b": 1
                }
            }
        }
    },
    "SH8a_poison": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SH-6a",
            "options": {
                "type": "severities",
                "severities": {
                    "a770529d-17f7-5b21-8f7b-3b508e9cb983": 1
                }
            }
        }
    },
    "SH9a_bones": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SH-6a",
            "options": {
                "type": "severities",
                "severities": {
                    "fe387d52-05f9-5b58-bd76-e284e0d87378": 1
                }
            }
        }
    },
    "SH9a_objects": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SH-6a",
            "options": {
                "type": "severities",
                "severities": {
                    "f603cb36-6f7d-5511-a4ab-2f13f22a9f3e": 1
                }
            }
        }
    },
    "SH9a_other": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SH-6a",
            "options": {
                "type": "severities",
                "severities": {
                    "b28d2db7-a851-5734-bf54-0ad05544946b": 1
                }
            }
        }
    },
    "SH9a_": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SH-6a",
            "options": {
                "type": "severities",
                "severities": {
                    "f67da8e8-7a80-5726-895d-710472c17bc0": 1
                }
            }
        }
    },
    "not(SH-s1=0)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "SH-s1",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "519336e4-f9db-552e-a464-11ea704d6288": true,
                        "981f1af5-5769-5932-8778-58a6e01aa624": false
                    }
                }
            }
        }
    },
    "SH-s3=1": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SH-s3",
            "options": {
                "type": "predicates",
                "predicates": {
                    "e2e1bae1-c85a-5ab1-9b76-10e31f65a40e": false,
                    "29309292-72fb-5781-ae9a-973977aac3af": true
                }
            }
        }
    },
    "SH-1a>=1": {
        "type": "input",
        "signal": {
            "type": "SpecificNumberInput",
            "questionId": "SH-1a",
            "input": 1,
            "operator": ">="
        }
    },
    "SH-23a>=0.25": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SH-23a",
            "options": {
                "type": "severities",
                "severities": {
                    "e5fa1386-2290-5c3f-990e-962ef0b4f10c": 0.07585818002124355,
                    "3e347ed1-7ea3-5b21-964f-32cf2d32e1b5": 0.5,
                    "5738b269-a761-5967-a3a9-e5861936cc74": 0.9241418199787566,
                    "025fa831-b50c-51c6-8ece-7f851af56e4f": 0.9933071490757153,
                    "83e881fd-401e-5ce9-9121-1472f92adb82": 0.9994472213630764
                }
            }
        }
    },
    "not(SH-s3=1)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "SH-s3=1"
            }
        }
    },
    "SH-2b<=730": {
        "type": "input",
        "signal": {
            "type": "SpecificNumberInput",
            "questionId": "SH-2b",
            "input": 730,
            "operator": "<="
        }
    },
    "and[or[isUndefined(and[S-s1=0, S-s2=0, S-s3=0]), not(and[S-s1=0, S-s2=0, S-s3=0])], not(isUndefined(S-s3=0))]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "S-s1",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "59a1b51e-3319-5c4d-a9f4-e50455a6f5a6": true,
                                                "00f4664d-929e-5301-bd5b-f405a7ab8ce4": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "S-s2",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "07c32652-123e-5f58-894e-f4ed9c8b110e": true,
                                                "10e008b4-feb5-5134-9869-3dfc46cb9924": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "S-s3",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35": true,
                                                "e90b0a3b-f835-5a9b-948b-d1b685da2c2e": false
                                            }
                                        }
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "S-s1",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "59a1b51e-3319-5c4d-a9f4-e50455a6f5a6": true,
                                                "00f4664d-929e-5301-bd5b-f405a7ab8ce4": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "S-s2",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "07c32652-123e-5f58-894e-f4ed9c8b110e": true,
                                                "10e008b4-feb5-5134-9869-3dfc46cb9924": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "S-s3",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35": true,
                                                "e90b0a3b-f835-5a9b-948b-d1b685da2c2e": false
                                            }
                                        }
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "not",
                "wire": {
                    "type": "isUnknown",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "S-s3",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35": true,
                                    "e90b0a3b-f835-5a9b-948b-d1b685da2c2e": false
                                }
                            }
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "S-s4=1": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "S-s4",
            "options": {
                "type": "predicates",
                "predicates": {
                    "e9fe5141-3a28-5ca3-9d76-2f51202a68b6": false,
                    "48d1552a-30b1-5dee-a6f1-f9e12ced08a2": true
                }
            }
        }
    },
    "not(S-13a=0)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "S-13a",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "8e2bdd16-a97a-5130-8f6e-edb8dd52afe3": true,
                        "fadcf643-dd8c-5f24-90d5-f971cf3c6ec7": false
                    }
                }
            }
        }
    }
};
exports.tests = {
    "suish_csui_csh": [
        {
            "name": "screened user: NEL6L3V aspect: suish_csui_csh should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "59a1b51e-3319-5c4d-a9f4-e50455a6f5a6"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "07c32652-123e-5f58-894e-f4ed9c8b110e"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        },
        {
            "name": "screened user: O3PMG3W aspect: suish_csui_csh should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "59a1b51e-3319-5c4d-a9f4-e50455a6f5a6"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "07c32652-123e-5f58-894e-f4ed9c8b110e"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-1.5a": {
                    "questionId": "SH-1.5a",
                    "chosenOptionIds": [
                        "38c7a9b9-d5a0-5a57-b6fd-4b8f2c7499a8"
                    ]
                },
                "SH-16a": {
                    "questionId": "SH-16a",
                    "chosenOptionIds": [
                        "f3060ab6-8ac7-559d-838c-a58eeb5d018e"
                    ]
                },
                "SH-1a": {
                    "questionId": "SH-1a",
                    "freeValue": 3
                },
                "SH-23.5a": {
                    "questionId": "SH-23.5a",
                    "chosenOptionIds": [
                        "a80457e5-70be-5103-ad49-69a47f84d227"
                    ]
                },
                "SH-23a": {
                    "questionId": "SH-23a",
                    "chosenOptionIds": [
                        "3e347ed1-7ea3-5b21-964f-32cf2d32e1b5"
                    ]
                },
                "SH-2a": {
                    "questionId": "SH-2a",
                    "chosenOptionIds": [
                        "35d92f8a-6922-5810-a862-81370a7bb8a5"
                    ]
                },
                "SH-4a": {
                    "questionId": "SH-4a",
                    "freeValue": 210
                },
                "SH-6a": {
                    "questionId": "SH-6a",
                    "chosenOptionIds": [
                        "9f789285-dcea-547c-8aa8-b37291bcfae2",
                        "bf613ed8-0c2a-5ac7-8b03-abfb31ce786b"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "981f1af5-5769-5932-8778-58a6e01aa624"
                    ]
                },
                "SH-s2": {
                    "questionId": "SH-s2",
                    "freeValue": 15
                },
                "SH-s3": {
                    "questionId": "SH-s3",
                    "chosenOptionIds": [
                        "29309292-72fb-5781-ae9a-973977aac3af"
                    ]
                }
            }
        },
        {
            "name": "screened user: Y32D838 aspect: suish_csui_csh should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "59a1b51e-3319-5c4d-a9f4-e50455a6f5a6"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "07c32652-123e-5f58-894e-f4ed9c8b110e"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        },
        {
            "name": "screened user: 63YX60R aspect: suish_csui_csh should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "59a1b51e-3319-5c4d-a9f4-e50455a6f5a6"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "07c32652-123e-5f58-894e-f4ed9c8b110e"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0N6P0Q aspect: suish_csui_csh should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "59a1b51e-3319-5c4d-a9f4-e50455a6f5a6"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "07c32652-123e-5f58-894e-f4ed9c8b110e"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        },
        {
            "name": "Tech team adjusted screen from FALSE, Censeo vPrevious user: 2E148ED aspect: suish_csui_csh",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "S-13.0a": {
                    "questionId": "S-13.0a",
                    "chosenOptionIds": [
                        "35639af8-738a-5ba6-bf47-d0fa52511234"
                    ]
                },
                "S-13a": {
                    "questionId": "S-13a",
                    "chosenOptionIds": [
                        "fadcf643-dd8c-5f24-90d5-f971cf3c6ec7"
                    ]
                },
                "S-14a": {
                    "questionId": "S-14a",
                    "freeValue": 5840
                },
                "S-15a": {
                    "questionId": "S-15a",
                    "freeValue": 2
                },
                "S-17a": {
                    "questionId": "S-17a",
                    "chosenOptionIds": [
                        "7bd78121-d1a4-5bd7-8be2-eefa7e976604"
                    ]
                },
                "S-18a": {
                    "questionId": "S-18a",
                    "chosenOptionIds": [
                        "e8bbf23a-7af1-5ec5-a666-e81bbeed6043"
                    ]
                },
                "S-1a": {
                    "questionId": "S-1a",
                    "freeValue": 6205
                },
                "S-2.5a": {
                    "questionId": "S-2.5a",
                    "chosenOptionIds": [
                        "455f8af3-f57a-5fe0-808e-aad6d2439855"
                    ]
                },
                "S-22a": {
                    "questionId": "S-22a",
                    "chosenOptionIds": [
                        "28660364-460b-5f7f-a281-104044331d08"
                    ]
                },
                "S-2a": {
                    "questionId": "S-2a",
                    "chosenOptionIds": [
                        "51f2de30-69cd-5a83-85a4-2e3310861a6f"
                    ]
                },
                "S-3a": {
                    "questionId": "S-3a",
                    "freeValue": 6
                },
                "S-4a": {
                    "questionId": "S-4a",
                    "chosenOptionIds": [
                        "131ae75d-c65c-5204-9e13-603a7cd9123d"
                    ]
                },
                "S-5a": {
                    "questionId": "S-5a",
                    "chosenOptionIds": [
                        "86839467-8345-58f0-9355-a14ba91c7079"
                    ]
                },
                "S-6a": {
                    "questionId": "S-6a",
                    "chosenOptionIds": [
                        "c6b26b20-786e-537b-89bc-71c4c3155678"
                    ]
                },
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "00f4664d-929e-5301-bd5b-f405a7ab8ce4"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "10e008b4-feb5-5134-9869-3dfc46cb9924"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "e90b0a3b-f835-5a9b-948b-d1b685da2c2e"
                    ]
                },
                "S-s4": {
                    "questionId": "S-s4",
                    "chosenOptionIds": [
                        "48d1552a-30b1-5dee-a6f1-f9e12ced08a2"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-16a": {
                    "questionId": "SH-16a",
                    "chosenOptionIds": [
                        "372a1173-b5c2-502f-9237-df57eebd80f2"
                    ]
                },
                "SH-1a": {
                    "questionId": "SH-1a",
                    "freeValue": 0
                },
                "SH-23.5a": {
                    "questionId": "SH-23.5a",
                    "chosenOptionIds": [
                        "a80457e5-70be-5103-ad49-69a47f84d227"
                    ]
                },
                "SH-23a": {
                    "questionId": "SH-23a",
                    "chosenOptionIds": [
                        "3e347ed1-7ea3-5b21-964f-32cf2d32e1b5"
                    ]
                },
                "SH-2a": {
                    "questionId": "SH-2a",
                    "chosenOptionIds": [
                        "fe25eeda-81fa-58a3-9c72-545aabe803f5"
                    ]
                },
                "SH-4a": {
                    "questionId": "SH-4a",
                    "freeValue": 330
                },
                "SH-6a": {
                    "questionId": "SH-6a",
                    "chosenOptionIds": [
                        "6618bab9-cf95-5c64-8182-e64a43ae5a5e"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "981f1af5-5769-5932-8778-58a6e01aa624"
                    ]
                },
                "SH-s2": {
                    "questionId": "SH-s2",
                    "freeValue": 16
                },
                "SH-s3": {
                    "questionId": "SH-s3",
                    "chosenOptionIds": [
                        "29309292-72fb-5781-ae9a-973977aac3af"
                    ]
                }
            }
        },
        {
            "name": "screened user: PE6LM0L aspect: suish_csui_csh should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "59a1b51e-3319-5c4d-a9f4-e50455a6f5a6"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "07c32652-123e-5f58-894e-f4ed9c8b110e"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        },
        {
            "name": "screened user: K39ML0L aspect: suish_csui_csh should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "59a1b51e-3319-5c4d-a9f4-e50455a6f5a6"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "07c32652-123e-5f58-894e-f4ed9c8b110e"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z3RD4EO aspect: suish_csui_csh should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "S-13a": {
                    "questionId": "S-13a",
                    "chosenOptionIds": [
                        "8e2bdd16-a97a-5130-8f6e-edb8dd52afe3"
                    ]
                },
                "S-18a": {
                    "questionId": "S-18a",
                    "chosenOptionIds": [
                        "e8bbf23a-7af1-5ec5-a666-e81bbeed6043"
                    ]
                },
                "S-1a": {
                    "questionId": "S-1a",
                    "freeValue": 210
                },
                "S-2.5a": {
                    "questionId": "S-2.5a",
                    "chosenOptionIds": [
                        "455f8af3-f57a-5fe0-808e-aad6d2439855"
                    ]
                },
                "S-22a": {
                    "questionId": "S-22a",
                    "chosenOptionIds": [
                        "9bce9d08-d129-5989-8b7b-5612590dc430"
                    ]
                },
                "S-2a": {
                    "questionId": "S-2a",
                    "chosenOptionIds": [
                        "51f2de30-69cd-5a83-85a4-2e3310861a6f"
                    ]
                },
                "S-3a": {
                    "questionId": "S-3a",
                    "freeValue": 7
                },
                "S-4a": {
                    "questionId": "S-4a",
                    "chosenOptionIds": [
                        "131ae75d-c65c-5204-9e13-603a7cd9123d"
                    ]
                },
                "S-5a": {
                    "questionId": "S-5a",
                    "chosenOptionIds": [
                        "0e07d0fe-f085-5440-b0f4-a5c8345833d3"
                    ]
                },
                "S-6a": {
                    "questionId": "S-6a",
                    "chosenOptionIds": [
                        "c0db0893-6906-5a21-ab74-144c85c0683d"
                    ]
                },
                "S-8a": {
                    "questionId": "S-8a",
                    "chosenOptionIds": [
                        "4f14f047-a4d6-5709-95b7-4742bec76fa7"
                    ]
                },
                "S-9.5a": {
                    "questionId": "S-9.5a",
                    "chosenOptionIds": [
                        "9e611ece-c2d6-5900-8832-3cbf939f729d"
                    ]
                },
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "00f4664d-929e-5301-bd5b-f405a7ab8ce4"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "10e008b4-feb5-5134-9869-3dfc46cb9924"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "e90b0a3b-f835-5a9b-948b-d1b685da2c2e"
                    ]
                },
                "S-s4": {
                    "questionId": "S-s4",
                    "chosenOptionIds": [
                        "48d1552a-30b1-5dee-a6f1-f9e12ced08a2"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-16b": {
                    "questionId": "SH-16b",
                    "chosenOptionIds": [
                        "7feeabee-bfe8-56cd-b16c-fd4199552577"
                    ]
                },
                "SH-23b": {
                    "questionId": "SH-23b",
                    "chosenOptionIds": [
                        "a5170112-22fd-5d89-96da-1f1d9abd6a28"
                    ]
                },
                "SH-2b": {
                    "questionId": "SH-2b",
                    "freeValue": 730
                },
                "SH-3b": {
                    "questionId": "SH-3b",
                    "chosenOptionIds": [
                        "6398c0aa-f204-57b5-b75c-e16d73e69cc3"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "981f1af5-5769-5932-8778-58a6e01aa624"
                    ]
                },
                "SH-s2": {
                    "questionId": "SH-s2",
                    "freeValue": 25
                },
                "SH-s3": {
                    "questionId": "SH-s3",
                    "chosenOptionIds": [
                        "e2e1bae1-c85a-5ab1-9b76-10e31f65a40e"
                    ]
                }
            }
        },
        {
            "name": "screened user: 4EGQQ0P aspect: suish_csui_csh should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "59a1b51e-3319-5c4d-a9f4-e50455a6f5a6"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "07c32652-123e-5f58-894e-f4ed9c8b110e"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        },
        {
            "name": "screened user: 7EW2K38 aspect: suish_csui_csh should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "S-13a": {
                    "questionId": "S-13a",
                    "chosenOptionIds": [
                        "8e2bdd16-a97a-5130-8f6e-edb8dd52afe3"
                    ]
                },
                "S-18a": {
                    "questionId": "S-18a",
                    "chosenOptionIds": [
                        "e8bbf23a-7af1-5ec5-a666-e81bbeed6043"
                    ]
                },
                "S-1a": {
                    "questionId": "S-1a",
                    "freeValue": 2
                },
                "S-2.5a": {
                    "questionId": "S-2.5a",
                    "chosenOptionIds": [
                        "455f8af3-f57a-5fe0-808e-aad6d2439855"
                    ]
                },
                "S-22a": {
                    "questionId": "S-22a",
                    "chosenOptionIds": [
                        "28660364-460b-5f7f-a281-104044331d08"
                    ]
                },
                "S-2a": {
                    "questionId": "S-2a",
                    "chosenOptionIds": [
                        "fccc47c0-713b-578c-a123-73b81b4d186d"
                    ]
                },
                "S-3a": {
                    "questionId": "S-3a",
                    "freeValue": 12
                },
                "S-4a": {
                    "questionId": "S-4a",
                    "chosenOptionIds": [
                        "2ad36ca1-d48c-57c8-a058-9de92c14e840"
                    ]
                },
                "S-5a": {
                    "questionId": "S-5a",
                    "chosenOptionIds": [
                        "86839467-8345-58f0-9355-a14ba91c7079"
                    ]
                },
                "S-6a": {
                    "questionId": "S-6a",
                    "chosenOptionIds": [
                        "c6b26b20-786e-537b-89bc-71c4c3155678"
                    ]
                },
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "00f4664d-929e-5301-bd5b-f405a7ab8ce4"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "10e008b4-feb5-5134-9869-3dfc46cb9924"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "e90b0a3b-f835-5a9b-948b-d1b685da2c2e"
                    ]
                },
                "S-s4": {
                    "questionId": "S-s4",
                    "chosenOptionIds": [
                        "48d1552a-30b1-5dee-a6f1-f9e12ced08a2"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        },
        {
            "name": "Tech team adjusted screen from FALSE, Censeo vPrevious user: Y329ME8 aspect: suish_csui_csh",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "S-13a": {
                    "questionId": "S-13a",
                    "chosenOptionIds": [
                        "8e2bdd16-a97a-5130-8f6e-edb8dd52afe3"
                    ]
                },
                "S-18a": {
                    "questionId": "S-18a",
                    "chosenOptionIds": [
                        "81b3f713-07b4-5d30-8066-60c1326c2aa1"
                    ]
                },
                "S-1a": {
                    "questionId": "S-1a",
                    "freeValue": 42
                },
                "S-2.5a": {
                    "questionId": "S-2.5a",
                    "chosenOptionIds": [
                        "455f8af3-f57a-5fe0-808e-aad6d2439855"
                    ]
                },
                "S-22a": {
                    "questionId": "S-22a",
                    "chosenOptionIds": [
                        "9bce9d08-d129-5989-8b7b-5612590dc430"
                    ]
                },
                "S-2a": {
                    "questionId": "S-2a",
                    "chosenOptionIds": [
                        "541369ff-8077-58e5-8bf7-90a8a94d14bb"
                    ]
                },
                "S-3a": {
                    "questionId": "S-3a",
                    "freeValue": 7
                },
                "S-4a": {
                    "questionId": "S-4a",
                    "chosenOptionIds": [
                        "5627de59-f614-56c2-887e-4c153fb61012"
                    ]
                },
                "S-5a": {
                    "questionId": "S-5a",
                    "chosenOptionIds": [
                        "86839467-8345-58f0-9355-a14ba91c7079"
                    ]
                },
                "S-6a": {
                    "questionId": "S-6a",
                    "chosenOptionIds": [
                        "c0db0893-6906-5a21-ab74-144c85c0683d"
                    ]
                },
                "S-8a": {
                    "questionId": "S-8a",
                    "chosenOptionIds": [
                        "4f14f047-a4d6-5709-95b7-4742bec76fa7"
                    ]
                },
                "S-9.5a": {
                    "questionId": "S-9.5a",
                    "chosenOptionIds": [
                        "9e611ece-c2d6-5900-8832-3cbf939f729d"
                    ]
                },
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "00f4664d-929e-5301-bd5b-f405a7ab8ce4"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "10e008b4-feb5-5134-9869-3dfc46cb9924"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "e90b0a3b-f835-5a9b-948b-d1b685da2c2e"
                    ]
                },
                "S-s4": {
                    "questionId": "S-s4",
                    "chosenOptionIds": [
                        "48d1552a-30b1-5dee-a6f1-f9e12ced08a2"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "981f1af5-5769-5932-8778-58a6e01aa624"
                    ]
                },
                "SH-s2": {
                    "questionId": "SH-s2",
                    "freeValue": 17
                },
                "SH-s3": {
                    "questionId": "SH-s3",
                    "chosenOptionIds": [
                        "29309292-72fb-5781-ae9a-973977aac3af"
                    ]
                }
            }
        },
        {
            "name": "screened user: J0VZ63L aspect: suish_csui_csh should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "S-13a": {
                    "questionId": "S-13a",
                    "chosenOptionIds": [
                        "8e2bdd16-a97a-5130-8f6e-edb8dd52afe3"
                    ]
                },
                "S-18a": {
                    "questionId": "S-18a",
                    "chosenOptionIds": [
                        "e8bbf23a-7af1-5ec5-a666-e81bbeed6043"
                    ]
                },
                "S-1a": {
                    "questionId": "S-1a",
                    "freeValue": 3
                },
                "S-2.5a": {
                    "questionId": "S-2.5a",
                    "chosenOptionIds": [
                        "455f8af3-f57a-5fe0-808e-aad6d2439855"
                    ]
                },
                "S-22a": {
                    "questionId": "S-22a",
                    "chosenOptionIds": [
                        "f18f48b0-a401-5787-bb00-ca8d99523304"
                    ]
                },
                "S-2a": {
                    "questionId": "S-2a",
                    "chosenOptionIds": [
                        "51f2de30-69cd-5a83-85a4-2e3310861a6f"
                    ]
                },
                "S-3a": {
                    "questionId": "S-3a",
                    "freeValue": 1
                },
                "S-4a": {
                    "questionId": "S-4a",
                    "chosenOptionIds": [
                        "1ffa1a8c-d1e3-54ab-9551-deb6e599c67f"
                    ]
                },
                "S-5a": {
                    "questionId": "S-5a",
                    "chosenOptionIds": [
                        "0e07d0fe-f085-5440-b0f4-a5c8345833d3"
                    ]
                },
                "S-6a": {
                    "questionId": "S-6a",
                    "chosenOptionIds": [
                        "c6b26b20-786e-537b-89bc-71c4c3155678"
                    ]
                },
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "00f4664d-929e-5301-bd5b-f405a7ab8ce4"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "10e008b4-feb5-5134-9869-3dfc46cb9924"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "e90b0a3b-f835-5a9b-948b-d1b685da2c2e"
                    ]
                },
                "S-s4": {
                    "questionId": "S-s4",
                    "chosenOptionIds": [
                        "48d1552a-30b1-5dee-a6f1-f9e12ced08a2"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "981f1af5-5769-5932-8778-58a6e01aa624"
                    ]
                },
                "SH-s2": {
                    "questionId": "SH-s2",
                    "freeValue": 29
                },
                "SH-s3": {
                    "questionId": "SH-s3",
                    "chosenOptionIds": [
                        "e2e1bae1-c85a-5ab1-9b76-10e31f65a40e"
                    ]
                }
            }
        },
        {
            "name": "screened user: 73ZJN0R aspect: suish_csui_csh should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "59a1b51e-3319-5c4d-a9f4-e50455a6f5a6"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "07c32652-123e-5f58-894e-f4ed9c8b110e"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        },
        {
            "name": "screened user: R042O34 aspect: suish_csui_csh should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "S-13.0a": {
                    "questionId": "S-13.0a",
                    "chosenOptionIds": [
                        "35639af8-738a-5ba6-bf47-d0fa52511234"
                    ]
                },
                "S-13a": {
                    "questionId": "S-13a",
                    "chosenOptionIds": [
                        "fadcf643-dd8c-5f24-90d5-f971cf3c6ec7"
                    ]
                },
                "S-14a": {
                    "questionId": "S-14a",
                    "freeValue": 9490
                },
                "S-15a": {
                    "questionId": "S-15a",
                    "freeValue": 1
                },
                "S-17a": {
                    "questionId": "S-17a",
                    "chosenOptionIds": [
                        "7bd78121-d1a4-5bd7-8be2-eefa7e976604"
                    ]
                },
                "S-18a": {
                    "questionId": "S-18a",
                    "chosenOptionIds": [
                        "e8bbf23a-7af1-5ec5-a666-e81bbeed6043"
                    ]
                },
                "S-1a": {
                    "questionId": "S-1a",
                    "freeValue": 3650
                },
                "S-2.5a": {
                    "questionId": "S-2.5a",
                    "chosenOptionIds": [
                        "455f8af3-f57a-5fe0-808e-aad6d2439855"
                    ]
                },
                "S-22a": {
                    "questionId": "S-22a",
                    "chosenOptionIds": [
                        "9bce9d08-d129-5989-8b7b-5612590dc430"
                    ]
                },
                "S-2a": {
                    "questionId": "S-2a",
                    "chosenOptionIds": [
                        "51f2de30-69cd-5a83-85a4-2e3310861a6f"
                    ]
                },
                "S-3a": {
                    "questionId": "S-3a",
                    "freeValue": 20
                },
                "S-4a": {
                    "questionId": "S-4a",
                    "chosenOptionIds": [
                        "1ffa1a8c-d1e3-54ab-9551-deb6e599c67f"
                    ]
                },
                "S-5a": {
                    "questionId": "S-5a",
                    "chosenOptionIds": [
                        "0e07d0fe-f085-5440-b0f4-a5c8345833d3"
                    ]
                },
                "S-6a": {
                    "questionId": "S-6a",
                    "chosenOptionIds": [
                        "c6b26b20-786e-537b-89bc-71c4c3155678"
                    ]
                },
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "00f4664d-929e-5301-bd5b-f405a7ab8ce4"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "10e008b4-feb5-5134-9869-3dfc46cb9924"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "e90b0a3b-f835-5a9b-948b-d1b685da2c2e"
                    ]
                },
                "S-s4": {
                    "questionId": "S-s4",
                    "chosenOptionIds": [
                        "48d1552a-30b1-5dee-a6f1-f9e12ced08a2"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0NN50Q aspect: suish_csui_csh should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "59a1b51e-3319-5c4d-a9f4-e50455a6f5a6"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "07c32652-123e-5f58-894e-f4ed9c8b110e"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0N4J3Q aspect: suish_csui_csh should be positive",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "S-13.0a": {
                    "questionId": "S-13.0a",
                    "chosenOptionIds": [
                        "35639af8-738a-5ba6-bf47-d0fa52511234"
                    ]
                },
                "S-13a": {
                    "questionId": "S-13a",
                    "chosenOptionIds": [
                        "8e2bdd16-a97a-5130-8f6e-edb8dd52afe3"
                    ]
                },
                "S-18a": {
                    "questionId": "S-18a",
                    "chosenOptionIds": [
                        "e8bbf23a-7af1-5ec5-a666-e81bbeed6043"
                    ]
                },
                "S-22a": {
                    "questionId": "S-22a",
                    "chosenOptionIds": [
                        "c5321fad-d3b3-58d2-979b-11ca81e25dbf"
                    ]
                },
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "00f4664d-929e-5301-bd5b-f405a7ab8ce4"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "10e008b4-feb5-5134-9869-3dfc46cb9924"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "e90b0a3b-f835-5a9b-948b-d1b685da2c2e"
                    ]
                },
                "S-s4": {
                    "questionId": "S-s4",
                    "chosenOptionIds": [
                        "48d1552a-30b1-5dee-a6f1-f9e12ced08a2"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "981f1af5-5769-5932-8778-58a6e01aa624"
                    ]
                },
                "SH-s2": {
                    "questionId": "SH-s2",
                    "freeValue": 25
                },
                "SH-s3": {
                    "questionId": "SH-s3",
                    "chosenOptionIds": [
                        "29309292-72fb-5781-ae9a-973977aac3af"
                    ]
                }
            }
        },
        {
            "name": "screened user: XE7ZR3L aspect: suish_csui_csh should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "59a1b51e-3319-5c4d-a9f4-e50455a6f5a6"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "07c32652-123e-5f58-894e-f4ed9c8b110e"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0N5PEQ aspect: suish_csui_csh should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "59a1b51e-3319-5c4d-a9f4-e50455a6f5a6"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "07c32652-123e-5f58-894e-f4ed9c8b110e"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        },
        {
            "name": "screened user: 10QJ8EP aspect: suish_csui_csh should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "59a1b51e-3319-5c4d-a9f4-e50455a6f5a6"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "07c32652-123e-5f58-894e-f4ed9c8b110e"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        },
        {
            "name": "screened user: 73ZL2ER aspect: suish_csui_csh should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "S-13a": {
                    "questionId": "S-13a",
                    "chosenOptionIds": [
                        "8e2bdd16-a97a-5130-8f6e-edb8dd52afe3"
                    ]
                },
                "S-18a": {
                    "questionId": "S-18a",
                    "chosenOptionIds": [
                        "81b3f713-07b4-5d30-8066-60c1326c2aa1"
                    ]
                },
                "S-1a": {
                    "questionId": "S-1a",
                    "freeValue": 3650
                },
                "S-2.5a": {
                    "questionId": "S-2.5a",
                    "chosenOptionIds": [
                        "455f8af3-f57a-5fe0-808e-aad6d2439855"
                    ]
                },
                "S-22a": {
                    "questionId": "S-22a",
                    "chosenOptionIds": [
                        "28660364-460b-5f7f-a281-104044331d08"
                    ]
                },
                "S-2a": {
                    "questionId": "S-2a",
                    "chosenOptionIds": [
                        "fccc47c0-713b-578c-a123-73b81b4d186d"
                    ]
                },
                "S-3a": {
                    "questionId": "S-3a",
                    "freeValue": 6
                },
                "S-4a": {
                    "questionId": "S-4a",
                    "chosenOptionIds": [
                        "dada9607-7e9d-565b-8073-73b780535bbe"
                    ]
                },
                "S-5a": {
                    "questionId": "S-5a",
                    "chosenOptionIds": [
                        "86839467-8345-58f0-9355-a14ba91c7079"
                    ]
                },
                "S-6a": {
                    "questionId": "S-6a",
                    "chosenOptionIds": [
                        "c6b26b20-786e-537b-89bc-71c4c3155678"
                    ]
                },
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "00f4664d-929e-5301-bd5b-f405a7ab8ce4"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "10e008b4-feb5-5134-9869-3dfc46cb9924"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "e90b0a3b-f835-5a9b-948b-d1b685da2c2e"
                    ]
                },
                "S-s4": {
                    "questionId": "S-s4",
                    "chosenOptionIds": [
                        "48d1552a-30b1-5dee-a6f1-f9e12ced08a2"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "981f1af5-5769-5932-8778-58a6e01aa624"
                    ]
                },
                "SH-s2": {
                    "questionId": "SH-s2",
                    "freeValue": 14
                },
                "SH-s3": {
                    "questionId": "SH-s3",
                    "chosenOptionIds": [
                        "e2e1bae1-c85a-5ab1-9b76-10e31f65a40e"
                    ]
                }
            }
        },
        {
            "name": "screened user: ME84L3W aspect: suish_csui_csh should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "59a1b51e-3319-5c4d-a9f4-e50455a6f5a6"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "07c32652-123e-5f58-894e-f4ed9c8b110e"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0NJ5EQ aspect: suish_csui_csh should be positive",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "S-13.0a": {
                    "questionId": "S-13.0a",
                    "chosenOptionIds": [
                        "35639af8-738a-5ba6-bf47-d0fa52511234"
                    ]
                },
                "S-13a": {
                    "questionId": "S-13a",
                    "chosenOptionIds": [
                        "fadcf643-dd8c-5f24-90d5-f971cf3c6ec7"
                    ]
                },
                "S-14a": {
                    "questionId": "S-14a",
                    "freeValue": 1460
                },
                "S-15a": {
                    "questionId": "S-15a",
                    "freeValue": 1
                },
                "S-17a": {
                    "questionId": "S-17a",
                    "chosenOptionIds": [
                        "7bd78121-d1a4-5bd7-8be2-eefa7e976604"
                    ]
                },
                "S-18a": {
                    "questionId": "S-18a",
                    "chosenOptionIds": [
                        "e8bbf23a-7af1-5ec5-a666-e81bbeed6043"
                    ]
                },
                "S-1a": {
                    "questionId": "S-1a",
                    "freeValue": 1825
                },
                "S-2.5a": {
                    "questionId": "S-2.5a",
                    "chosenOptionIds": [
                        "455f8af3-f57a-5fe0-808e-aad6d2439855"
                    ]
                },
                "S-22a": {
                    "questionId": "S-22a",
                    "chosenOptionIds": [
                        "9bce9d08-d129-5989-8b7b-5612590dc430"
                    ]
                },
                "S-2a": {
                    "questionId": "S-2a",
                    "chosenOptionIds": [
                        "541369ff-8077-58e5-8bf7-90a8a94d14bb"
                    ]
                },
                "S-3a": {
                    "questionId": "S-3a",
                    "freeValue": 20
                },
                "S-4a": {
                    "questionId": "S-4a",
                    "chosenOptionIds": [
                        "2ad36ca1-d48c-57c8-a058-9de92c14e840"
                    ]
                },
                "S-5a": {
                    "questionId": "S-5a",
                    "chosenOptionIds": [
                        "0e07d0fe-f085-5440-b0f4-a5c8345833d3"
                    ]
                },
                "S-6a": {
                    "questionId": "S-6a",
                    "chosenOptionIds": [
                        "0667075d-d44a-5ec5-8a05-4d25659c831d"
                    ]
                },
                "S-8a": {
                    "questionId": "S-8a",
                    "chosenOptionIds": [
                        "4f14f047-a4d6-5709-95b7-4742bec76fa7"
                    ]
                },
                "S-9.5a": {
                    "questionId": "S-9.5a",
                    "chosenOptionIds": [
                        "9e611ece-c2d6-5900-8832-3cbf939f729d"
                    ]
                },
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "00f4664d-929e-5301-bd5b-f405a7ab8ce4"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "10e008b4-feb5-5134-9869-3dfc46cb9924"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "e90b0a3b-f835-5a9b-948b-d1b685da2c2e"
                    ]
                },
                "S-s4": {
                    "questionId": "S-s4",
                    "chosenOptionIds": [
                        "48d1552a-30b1-5dee-a6f1-f9e12ced08a2"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-1.5a": {
                    "questionId": "SH-1.5a",
                    "chosenOptionIds": [
                        "38c7a9b9-d5a0-5a57-b6fd-4b8f2c7499a8"
                    ]
                },
                "SH-16a": {
                    "questionId": "SH-16a",
                    "chosenOptionIds": [
                        "f3060ab6-8ac7-559d-838c-a58eeb5d018e"
                    ]
                },
                "SH-1a": {
                    "questionId": "SH-1a",
                    "freeValue": 7
                },
                "SH-23.5a": {
                    "questionId": "SH-23.5a",
                    "chosenOptionIds": [
                        "a80457e5-70be-5103-ad49-69a47f84d227"
                    ]
                },
                "SH-23a": {
                    "questionId": "SH-23a",
                    "chosenOptionIds": [
                        "3e347ed1-7ea3-5b21-964f-32cf2d32e1b5"
                    ]
                },
                "SH-2a": {
                    "questionId": "SH-2a",
                    "chosenOptionIds": [
                        "5d85eb1d-b45b-51cb-9405-1a914df66de6"
                    ]
                },
                "SH-4a": {
                    "questionId": "SH-4a",
                    "freeValue": 150
                },
                "SH-6a": {
                    "questionId": "SH-6a",
                    "chosenOptionIds": [
                        "6618bab9-cf95-5c64-8182-e64a43ae5a5e",
                        "b28d2db7-a851-5734-bf54-0ad05544946b"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "981f1af5-5769-5932-8778-58a6e01aa624"
                    ]
                },
                "SH-s2": {
                    "questionId": "SH-s2",
                    "freeValue": 14
                },
                "SH-s3": {
                    "questionId": "SH-s3",
                    "chosenOptionIds": [
                        "29309292-72fb-5781-ae9a-973977aac3af"
                    ]
                }
            }
        },
        {
            "name": "screened user: K392M0L aspect: suish_csui_csh should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "59a1b51e-3319-5c4d-a9f4-e50455a6f5a6"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "07c32652-123e-5f58-894e-f4ed9c8b110e"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        },
        {
            "name": "screened user: 10Q6R0P aspect: suish_csui_csh should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "59a1b51e-3319-5c4d-a9f4-e50455a6f5a6"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "07c32652-123e-5f58-894e-f4ed9c8b110e"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        },
        {
            "name": "screened user: 50MQK36 aspect: suish_csui_csh should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "59a1b51e-3319-5c4d-a9f4-e50455a6f5a6"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "07c32652-123e-5f58-894e-f4ed9c8b110e"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "981f1af5-5769-5932-8778-58a6e01aa624"
                    ]
                },
                "SH-s2": {
                    "questionId": "SH-s2",
                    "freeValue": 14
                },
                "SH-s3": {
                    "questionId": "SH-s3",
                    "chosenOptionIds": [
                        "29309292-72fb-5781-ae9a-973977aac3af"
                    ]
                }
            }
        },
        {
            "name": "screened user: 4EGGGEP aspect: suish_csui_csh should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "59a1b51e-3319-5c4d-a9f4-e50455a6f5a6"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "07c32652-123e-5f58-894e-f4ed9c8b110e"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        },
        {
            "name": "screened user: 23X4V3N aspect: suish_csui_csh should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "59a1b51e-3319-5c4d-a9f4-e50455a6f5a6"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "07c32652-123e-5f58-894e-f4ed9c8b110e"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z35X10O aspect: suish_csui_csh should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "59a1b51e-3319-5c4d-a9f4-e50455a6f5a6"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "07c32652-123e-5f58-894e-f4ed9c8b110e"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        },
        {
            "name": "screened user: O0K9D01 aspect: suish_csui_csh should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "59a1b51e-3319-5c4d-a9f4-e50455a6f5a6"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "07c32652-123e-5f58-894e-f4ed9c8b110e"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        },
        {
            "name": "screened user: 4EGLK3P aspect: suish_csui_csh should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "59a1b51e-3319-5c4d-a9f4-e50455a6f5a6"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "07c32652-123e-5f58-894e-f4ed9c8b110e"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        },
        {
            "name": "screened user: NEL9J3V aspect: suish_csui_csh should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "S-13.0a": {
                    "questionId": "S-13.0a",
                    "chosenOptionIds": [
                        "35639af8-738a-5ba6-bf47-d0fa52511234"
                    ]
                },
                "S-13a": {
                    "questionId": "S-13a",
                    "chosenOptionIds": [
                        "fadcf643-dd8c-5f24-90d5-f971cf3c6ec7"
                    ]
                },
                "S-14a": {
                    "questionId": "S-14a",
                    "freeValue": 730
                },
                "S-15a": {
                    "questionId": "S-15a",
                    "freeValue": 3
                },
                "S-17a": {
                    "questionId": "S-17a",
                    "chosenOptionIds": [
                        "7bd78121-d1a4-5bd7-8be2-eefa7e976604"
                    ]
                },
                "S-18a": {
                    "questionId": "S-18a",
                    "chosenOptionIds": [
                        "890e7c6f-4bdf-5d6c-87e4-d2fc93a63102"
                    ]
                },
                "S-1a": {
                    "questionId": "S-1a",
                    "freeValue": 90
                },
                "S-2.5a": {
                    "questionId": "S-2.5a",
                    "chosenOptionIds": [
                        "455f8af3-f57a-5fe0-808e-aad6d2439855"
                    ]
                },
                "S-22a": {
                    "questionId": "S-22a",
                    "chosenOptionIds": [
                        "28660364-460b-5f7f-a281-104044331d08"
                    ]
                },
                "S-2a": {
                    "questionId": "S-2a",
                    "chosenOptionIds": [
                        "541369ff-8077-58e5-8bf7-90a8a94d14bb"
                    ]
                },
                "S-3a": {
                    "questionId": "S-3a",
                    "freeValue": 14
                },
                "S-4a": {
                    "questionId": "S-4a",
                    "chosenOptionIds": [
                        "2ad36ca1-d48c-57c8-a058-9de92c14e840"
                    ]
                },
                "S-5a": {
                    "questionId": "S-5a",
                    "chosenOptionIds": [
                        "86839467-8345-58f0-9355-a14ba91c7079"
                    ]
                },
                "S-6a": {
                    "questionId": "S-6a",
                    "chosenOptionIds": [
                        "870c5cac-fb60-51ca-9d26-bf9e93d56ab2"
                    ]
                },
                "S-8a": {
                    "questionId": "S-8a",
                    "chosenOptionIds": [
                        "fb782e60-165b-5cd2-ae70-c40b281bc387"
                    ]
                },
                "S-9.5a": {
                    "questionId": "S-9.5a",
                    "chosenOptionIds": [
                        "9e611ece-c2d6-5900-8832-3cbf939f729d"
                    ]
                },
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "00f4664d-929e-5301-bd5b-f405a7ab8ce4"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "10e008b4-feb5-5134-9869-3dfc46cb9924"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "e90b0a3b-f835-5a9b-948b-d1b685da2c2e"
                    ]
                },
                "S-s4": {
                    "questionId": "S-s4",
                    "chosenOptionIds": [
                        "48d1552a-30b1-5dee-a6f1-f9e12ced08a2"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        },
        {
            "name": "screened user: PE694EL aspect: suish_csui_csh should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "59a1b51e-3319-5c4d-a9f4-e50455a6f5a6"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "07c32652-123e-5f58-894e-f4ed9c8b110e"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        },
        {
            "name": "screened user: K399M3L aspect: suish_csui_csh should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "S-13a": {
                    "questionId": "S-13a",
                    "chosenOptionIds": [
                        "8e2bdd16-a97a-5130-8f6e-edb8dd52afe3"
                    ]
                },
                "S-18a": {
                    "questionId": "S-18a",
                    "chosenOptionIds": [
                        "81b3f713-07b4-5d30-8066-60c1326c2aa1"
                    ]
                },
                "S-1a": {
                    "questionId": "S-1a",
                    "freeValue": 390
                },
                "S-2.5a": {
                    "questionId": "S-2.5a",
                    "chosenOptionIds": [
                        "455f8af3-f57a-5fe0-808e-aad6d2439855"
                    ]
                },
                "S-22a": {
                    "questionId": "S-22a",
                    "chosenOptionIds": [
                        "f18f48b0-a401-5787-bb00-ca8d99523304"
                    ]
                },
                "S-2a": {
                    "questionId": "S-2a",
                    "chosenOptionIds": [
                        "541369ff-8077-58e5-8bf7-90a8a94d14bb"
                    ]
                },
                "S-3a": {
                    "questionId": "S-3a",
                    "freeValue": 2
                },
                "S-4a": {
                    "questionId": "S-4a",
                    "chosenOptionIds": [
                        "dada9607-7e9d-565b-8073-73b780535bbe"
                    ]
                },
                "S-5a": {
                    "questionId": "S-5a",
                    "chosenOptionIds": [
                        "86839467-8345-58f0-9355-a14ba91c7079"
                    ]
                },
                "S-6a": {
                    "questionId": "S-6a",
                    "chosenOptionIds": [
                        "0667075d-d44a-5ec5-8a05-4d25659c831d"
                    ]
                },
                "S-8a": {
                    "questionId": "S-8a",
                    "chosenOptionIds": [
                        "4f14f047-a4d6-5709-95b7-4742bec76fa7"
                    ]
                },
                "S-9.5a": {
                    "questionId": "S-9.5a",
                    "chosenOptionIds": [
                        "9e611ece-c2d6-5900-8832-3cbf939f729d"
                    ]
                },
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "00f4664d-929e-5301-bd5b-f405a7ab8ce4"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "10e008b4-feb5-5134-9869-3dfc46cb9924"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "e90b0a3b-f835-5a9b-948b-d1b685da2c2e"
                    ]
                },
                "S-s4": {
                    "questionId": "S-s4",
                    "chosenOptionIds": [
                        "48d1552a-30b1-5dee-a6f1-f9e12ced08a2"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        },
        {
            "name": "Tech team adjusted screen from FALSE, Censeo vPrevious user: 50MGW06 aspect: suish_csui_csh",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "S-13.0a": {
                    "questionId": "S-13.0a",
                    "chosenOptionIds": [
                        "35639af8-738a-5ba6-bf47-d0fa52511234"
                    ]
                },
                "S-13a": {
                    "questionId": "S-13a",
                    "chosenOptionIds": [
                        "fadcf643-dd8c-5f24-90d5-f971cf3c6ec7"
                    ]
                },
                "S-14a": {
                    "questionId": "S-14a",
                    "freeValue": 365
                },
                "S-15a": {
                    "questionId": "S-15a",
                    "freeValue": 8
                },
                "S-17a": {
                    "questionId": "S-17a",
                    "chosenOptionIds": [
                        "7bd78121-d1a4-5bd7-8be2-eefa7e976604"
                    ]
                },
                "S-18a": {
                    "questionId": "S-18a",
                    "chosenOptionIds": [
                        "e8bbf23a-7af1-5ec5-a666-e81bbeed6043"
                    ]
                },
                "S-1a": {
                    "questionId": "S-1a",
                    "freeValue": 365
                },
                "S-2.5a": {
                    "questionId": "S-2.5a",
                    "chosenOptionIds": [
                        "455f8af3-f57a-5fe0-808e-aad6d2439855"
                    ]
                },
                "S-22a": {
                    "questionId": "S-22a",
                    "chosenOptionIds": [
                        "f18f48b0-a401-5787-bb00-ca8d99523304"
                    ]
                },
                "S-2a": {
                    "questionId": "S-2a",
                    "chosenOptionIds": [
                        "84b1dac4-ea2f-5f51-a205-0efe9d0e6c8c"
                    ]
                },
                "S-3a": {
                    "questionId": "S-3a",
                    "freeValue": 7
                },
                "S-4a": {
                    "questionId": "S-4a",
                    "chosenOptionIds": [
                        "2ad36ca1-d48c-57c8-a058-9de92c14e840"
                    ]
                },
                "S-5a": {
                    "questionId": "S-5a",
                    "chosenOptionIds": [
                        "86839467-8345-58f0-9355-a14ba91c7079"
                    ]
                },
                "S-6a": {
                    "questionId": "S-6a",
                    "chosenOptionIds": [
                        "c0db0893-6906-5a21-ab74-144c85c0683d"
                    ]
                },
                "S-8a": {
                    "questionId": "S-8a",
                    "chosenOptionIds": [
                        "4f14f047-a4d6-5709-95b7-4742bec76fa7"
                    ]
                },
                "S-9.5a": {
                    "questionId": "S-9.5a",
                    "chosenOptionIds": [
                        "9e611ece-c2d6-5900-8832-3cbf939f729d"
                    ]
                },
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "00f4664d-929e-5301-bd5b-f405a7ab8ce4"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "10e008b4-feb5-5134-9869-3dfc46cb9924"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "e90b0a3b-f835-5a9b-948b-d1b685da2c2e"
                    ]
                },
                "S-s4": {
                    "questionId": "S-s4",
                    "chosenOptionIds": [
                        "48d1552a-30b1-5dee-a6f1-f9e12ced08a2"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "981f1af5-5769-5932-8778-58a6e01aa624"
                    ]
                },
                "SH-s2": {
                    "questionId": "SH-s2",
                    "freeValue": 14
                },
                "SH-s3": {
                    "questionId": "SH-s3",
                    "chosenOptionIds": [
                        "29309292-72fb-5781-ae9a-973977aac3af"
                    ]
                }
            }
        },
        {
            "name": "screened user: J0VGM0L aspect: suish_csui_csh should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "S-13.0a": {
                    "questionId": "S-13.0a",
                    "chosenOptionIds": [
                        "35639af8-738a-5ba6-bf47-d0fa52511234"
                    ]
                },
                "S-13a": {
                    "questionId": "S-13a",
                    "chosenOptionIds": [
                        "fadcf643-dd8c-5f24-90d5-f971cf3c6ec7"
                    ]
                },
                "S-14a": {
                    "questionId": "S-14a",
                    "freeValue": 1460
                },
                "S-15a": {
                    "questionId": "S-15a",
                    "freeValue": 1
                },
                "S-17a": {
                    "questionId": "S-17a",
                    "chosenOptionIds": [
                        "7bd78121-d1a4-5bd7-8be2-eefa7e976604"
                    ]
                },
                "S-18a": {
                    "questionId": "S-18a",
                    "chosenOptionIds": [
                        "e8bbf23a-7af1-5ec5-a666-e81bbeed6043"
                    ]
                },
                "S-1a": {
                    "questionId": "S-1a",
                    "freeValue": 2555
                },
                "S-2.5a": {
                    "questionId": "S-2.5a",
                    "chosenOptionIds": [
                        "455f8af3-f57a-5fe0-808e-aad6d2439855"
                    ]
                },
                "S-22a": {
                    "questionId": "S-22a",
                    "chosenOptionIds": [
                        "28660364-460b-5f7f-a281-104044331d08"
                    ]
                },
                "S-2a": {
                    "questionId": "S-2a",
                    "chosenOptionIds": [
                        "51f2de30-69cd-5a83-85a4-2e3310861a6f"
                    ]
                },
                "S-3a": {
                    "questionId": "S-3a",
                    "freeValue": 10
                },
                "S-4a": {
                    "questionId": "S-4a",
                    "chosenOptionIds": [
                        "131ae75d-c65c-5204-9e13-603a7cd9123d"
                    ]
                },
                "S-5a": {
                    "questionId": "S-5a",
                    "chosenOptionIds": [
                        "86839467-8345-58f0-9355-a14ba91c7079"
                    ]
                },
                "S-6a": {
                    "questionId": "S-6a",
                    "chosenOptionIds": [
                        "c0db0893-6906-5a21-ab74-144c85c0683d"
                    ]
                },
                "S-8a": {
                    "questionId": "S-8a",
                    "chosenOptionIds": [
                        "cb6c8c06-d0b8-558d-8e33-bf475718cb0d"
                    ]
                },
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "00f4664d-929e-5301-bd5b-f405a7ab8ce4"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "10e008b4-feb5-5134-9869-3dfc46cb9924"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "e90b0a3b-f835-5a9b-948b-d1b685da2c2e"
                    ]
                },
                "S-s4": {
                    "questionId": "S-s4",
                    "chosenOptionIds": [
                        "48d1552a-30b1-5dee-a6f1-f9e12ced08a2"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        },
        {
            "name": "screened user: O3P770W aspect: suish_csui_csh should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "59a1b51e-3319-5c4d-a9f4-e50455a6f5a6"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "07c32652-123e-5f58-894e-f4ed9c8b110e"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        },
        {
            "name": "screened user: 7EWZKE8 aspect: suish_csui_csh should be positive",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "S-13.0a": {
                    "questionId": "S-13.0a",
                    "chosenOptionIds": [
                        "35639af8-738a-5ba6-bf47-d0fa52511234"
                    ]
                },
                "S-13a": {
                    "questionId": "S-13a",
                    "chosenOptionIds": [
                        "fadcf643-dd8c-5f24-90d5-f971cf3c6ec7"
                    ]
                },
                "S-14a": {
                    "questionId": "S-14a",
                    "freeValue": 5475
                },
                "S-15a": {
                    "questionId": "S-15a",
                    "freeValue": 1
                },
                "S-17a": {
                    "questionId": "S-17a",
                    "chosenOptionIds": [
                        "56bb1b4f-6a67-505a-980b-b4b9bb1792e3"
                    ]
                },
                "S-18a": {
                    "questionId": "S-18a",
                    "chosenOptionIds": [
                        "e8bbf23a-7af1-5ec5-a666-e81bbeed6043"
                    ]
                },
                "S-1a": {
                    "questionId": "S-1a",
                    "freeValue": 5475
                },
                "S-2.5a": {
                    "questionId": "S-2.5a",
                    "chosenOptionIds": [
                        "455f8af3-f57a-5fe0-808e-aad6d2439855"
                    ]
                },
                "S-22a": {
                    "questionId": "S-22a",
                    "chosenOptionIds": [
                        "f18f48b0-a401-5787-bb00-ca8d99523304"
                    ]
                },
                "S-2a": {
                    "questionId": "S-2a",
                    "chosenOptionIds": [
                        "51f2de30-69cd-5a83-85a4-2e3310861a6f"
                    ]
                },
                "S-3a": {
                    "questionId": "S-3a",
                    "freeValue": 30
                },
                "S-4a": {
                    "questionId": "S-4a",
                    "chosenOptionIds": [
                        "2ad36ca1-d48c-57c8-a058-9de92c14e840"
                    ]
                },
                "S-5a": {
                    "questionId": "S-5a",
                    "chosenOptionIds": [
                        "86839467-8345-58f0-9355-a14ba91c7079"
                    ]
                },
                "S-6a": {
                    "questionId": "S-6a",
                    "chosenOptionIds": [
                        "c0db0893-6906-5a21-ab74-144c85c0683d"
                    ]
                },
                "S-8a": {
                    "questionId": "S-8a",
                    "chosenOptionIds": [
                        "cb6c8c06-d0b8-558d-8e33-bf475718cb0d"
                    ]
                },
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "00f4664d-929e-5301-bd5b-f405a7ab8ce4"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "10e008b4-feb5-5134-9869-3dfc46cb9924"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "e90b0a3b-f835-5a9b-948b-d1b685da2c2e"
                    ]
                },
                "S-s4": {
                    "questionId": "S-s4",
                    "chosenOptionIds": [
                        "48d1552a-30b1-5dee-a6f1-f9e12ced08a2"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-1.5a": {
                    "questionId": "SH-1.5a",
                    "chosenOptionIds": [
                        "38c7a9b9-d5a0-5a57-b6fd-4b8f2c7499a8"
                    ]
                },
                "SH-16a": {
                    "questionId": "SH-16a",
                    "chosenOptionIds": [
                        "372a1173-b5c2-502f-9237-df57eebd80f2"
                    ]
                },
                "SH-1a": {
                    "questionId": "SH-1a",
                    "freeValue": 5
                },
                "SH-23.5a": {
                    "questionId": "SH-23.5a",
                    "chosenOptionIds": [
                        "a80457e5-70be-5103-ad49-69a47f84d227"
                    ]
                },
                "SH-23a": {
                    "questionId": "SH-23a",
                    "chosenOptionIds": [
                        "5738b269-a761-5967-a3a9-e5861936cc74"
                    ]
                },
                "SH-2a": {
                    "questionId": "SH-2a",
                    "chosenOptionIds": [
                        "fe25eeda-81fa-58a3-9c72-545aabe803f5"
                    ]
                },
                "SH-4a": {
                    "questionId": "SH-4a",
                    "freeValue": 90
                },
                "SH-6a": {
                    "questionId": "SH-6a",
                    "chosenOptionIds": [
                        "6618bab9-cf95-5c64-8182-e64a43ae5a5e",
                        "9f789285-dcea-547c-8aa8-b37291bcfae2",
                        "a770529d-17f7-5b21-8f7b-3b508e9cb983",
                        "b28d2db7-a851-5734-bf54-0ad05544946b"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "981f1af5-5769-5932-8778-58a6e01aa624"
                    ]
                },
                "SH-s2": {
                    "questionId": "SH-s2",
                    "freeValue": 14
                },
                "SH-s3": {
                    "questionId": "SH-s3",
                    "chosenOptionIds": [
                        "29309292-72fb-5781-ae9a-973977aac3af"
                    ]
                }
            }
        },
        {
            "name": "screened user: J3JV43Y aspect: suish_csui_csh should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "59a1b51e-3319-5c4d-a9f4-e50455a6f5a6"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "07c32652-123e-5f58-894e-f4ed9c8b110e"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        },
        {
            "name": "screened user: PE6GM3L aspect: suish_csui_csh should be positive",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "S-13.0a": {
                    "questionId": "S-13.0a",
                    "chosenOptionIds": [
                        "35639af8-738a-5ba6-bf47-d0fa52511234"
                    ]
                },
                "S-13a": {
                    "questionId": "S-13a",
                    "chosenOptionIds": [
                        "fadcf643-dd8c-5f24-90d5-f971cf3c6ec7"
                    ]
                },
                "S-14a": {
                    "questionId": "S-14a",
                    "freeValue": 1825
                },
                "S-15a": {
                    "questionId": "S-15a",
                    "freeValue": 5
                },
                "S-17a": {
                    "questionId": "S-17a",
                    "chosenOptionIds": [
                        "7bd78121-d1a4-5bd7-8be2-eefa7e976604"
                    ]
                },
                "S-18a": {
                    "questionId": "S-18a",
                    "chosenOptionIds": [
                        "e8bbf23a-7af1-5ec5-a666-e81bbeed6043"
                    ]
                },
                "S-1a": {
                    "questionId": "S-1a",
                    "freeValue": 3650
                },
                "S-2.5a": {
                    "questionId": "S-2.5a",
                    "chosenOptionIds": [
                        "455f8af3-f57a-5fe0-808e-aad6d2439855"
                    ]
                },
                "S-22a": {
                    "questionId": "S-22a",
                    "chosenOptionIds": [
                        "f18f48b0-a401-5787-bb00-ca8d99523304"
                    ]
                },
                "S-2a": {
                    "questionId": "S-2a",
                    "chosenOptionIds": [
                        "84b1dac4-ea2f-5f51-a205-0efe9d0e6c8c"
                    ]
                },
                "S-3a": {
                    "questionId": "S-3a",
                    "freeValue": 100
                },
                "S-4a": {
                    "questionId": "S-4a",
                    "chosenOptionIds": [
                        "2ad36ca1-d48c-57c8-a058-9de92c14e840"
                    ]
                },
                "S-5a": {
                    "questionId": "S-5a",
                    "chosenOptionIds": [
                        "86839467-8345-58f0-9355-a14ba91c7079"
                    ]
                },
                "S-6a": {
                    "questionId": "S-6a",
                    "chosenOptionIds": [
                        "870c5cac-fb60-51ca-9d26-bf9e93d56ab2"
                    ]
                },
                "S-8a": {
                    "questionId": "S-8a",
                    "chosenOptionIds": [
                        "fb782e60-165b-5cd2-ae70-c40b281bc387"
                    ]
                },
                "S-9.5a": {
                    "questionId": "S-9.5a",
                    "chosenOptionIds": [
                        "9e611ece-c2d6-5900-8832-3cbf939f729d"
                    ]
                },
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "00f4664d-929e-5301-bd5b-f405a7ab8ce4"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "10e008b4-feb5-5134-9869-3dfc46cb9924"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "e90b0a3b-f835-5a9b-948b-d1b685da2c2e"
                    ]
                },
                "S-s4": {
                    "questionId": "S-s4",
                    "chosenOptionIds": [
                        "48d1552a-30b1-5dee-a6f1-f9e12ced08a2"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-1.5a": {
                    "questionId": "SH-1.5a",
                    "chosenOptionIds": [
                        "38c7a9b9-d5a0-5a57-b6fd-4b8f2c7499a8"
                    ]
                },
                "SH-16a": {
                    "questionId": "SH-16a",
                    "chosenOptionIds": [
                        "f3060ab6-8ac7-559d-838c-a58eeb5d018e"
                    ]
                },
                "SH-1a": {
                    "questionId": "SH-1a",
                    "freeValue": 10
                },
                "SH-23.5a": {
                    "questionId": "SH-23.5a",
                    "chosenOptionIds": [
                        "a80457e5-70be-5103-ad49-69a47f84d227"
                    ]
                },
                "SH-23a": {
                    "questionId": "SH-23a",
                    "chosenOptionIds": [
                        "5738b269-a761-5967-a3a9-e5861936cc74"
                    ]
                },
                "SH-2a": {
                    "questionId": "SH-2a",
                    "chosenOptionIds": [
                        "c81ca000-63f4-5d4d-b175-ed7c74561574"
                    ]
                },
                "SH-4a": {
                    "questionId": "SH-4a",
                    "freeValue": 14
                },
                "SH-6a": {
                    "questionId": "SH-6a",
                    "chosenOptionIds": [
                        "6618bab9-cf95-5c64-8182-e64a43ae5a5e",
                        "9f789285-dcea-547c-8aa8-b37291bcfae2",
                        "b28d2db7-a851-5734-bf54-0ad05544946b"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "981f1af5-5769-5932-8778-58a6e01aa624"
                    ]
                },
                "SH-s2": {
                    "questionId": "SH-s2",
                    "freeValue": 13
                },
                "SH-s3": {
                    "questionId": "SH-s3",
                    "chosenOptionIds": [
                        "29309292-72fb-5781-ae9a-973977aac3af"
                    ]
                }
            }
        },
        {
            "name": "screened user: 63Y843R aspect: suish_csui_csh should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "59a1b51e-3319-5c4d-a9f4-e50455a6f5a6"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "07c32652-123e-5f58-894e-f4ed9c8b110e"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0NQJ0Q aspect: suish_csui_csh should be positive",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "S-13a": {
                    "questionId": "S-13a",
                    "chosenOptionIds": [
                        "8e2bdd16-a97a-5130-8f6e-edb8dd52afe3"
                    ]
                },
                "S-18a": {
                    "questionId": "S-18a",
                    "chosenOptionIds": [
                        "81b3f713-07b4-5d30-8066-60c1326c2aa1"
                    ]
                },
                "S-1a": {
                    "questionId": "S-1a",
                    "freeValue": 90
                },
                "S-2.5a": {
                    "questionId": "S-2.5a",
                    "chosenOptionIds": [
                        "455f8af3-f57a-5fe0-808e-aad6d2439855"
                    ]
                },
                "S-22a": {
                    "questionId": "S-22a",
                    "chosenOptionIds": [
                        "28660364-460b-5f7f-a281-104044331d08"
                    ]
                },
                "S-2a": {
                    "questionId": "S-2a",
                    "chosenOptionIds": [
                        "541369ff-8077-58e5-8bf7-90a8a94d14bb"
                    ]
                },
                "S-3a": {
                    "questionId": "S-3a",
                    "freeValue": 4
                },
                "S-4a": {
                    "questionId": "S-4a",
                    "chosenOptionIds": [
                        "5627de59-f614-56c2-887e-4c153fb61012"
                    ]
                },
                "S-5a": {
                    "questionId": "S-5a",
                    "chosenOptionIds": [
                        "86839467-8345-58f0-9355-a14ba91c7079"
                    ]
                },
                "S-6a": {
                    "questionId": "S-6a",
                    "chosenOptionIds": [
                        "0667075d-d44a-5ec5-8a05-4d25659c831d"
                    ]
                },
                "S-8a": {
                    "questionId": "S-8a",
                    "chosenOptionIds": [
                        "4f14f047-a4d6-5709-95b7-4742bec76fa7"
                    ]
                },
                "S-9.5a": {
                    "questionId": "S-9.5a",
                    "chosenOptionIds": [
                        "9e611ece-c2d6-5900-8832-3cbf939f729d"
                    ]
                },
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "00f4664d-929e-5301-bd5b-f405a7ab8ce4"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "10e008b4-feb5-5134-9869-3dfc46cb9924"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "e90b0a3b-f835-5a9b-948b-d1b685da2c2e"
                    ]
                },
                "S-s4": {
                    "questionId": "S-s4",
                    "chosenOptionIds": [
                        "48d1552a-30b1-5dee-a6f1-f9e12ced08a2"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-1.5a": {
                    "questionId": "SH-1.5a",
                    "chosenOptionIds": [
                        "38c7a9b9-d5a0-5a57-b6fd-4b8f2c7499a8"
                    ]
                },
                "SH-16a": {
                    "questionId": "SH-16a",
                    "chosenOptionIds": [
                        "372a1173-b5c2-502f-9237-df57eebd80f2"
                    ]
                },
                "SH-1a": {
                    "questionId": "SH-1a",
                    "freeValue": 1
                },
                "SH-23.5a": {
                    "questionId": "SH-23.5a",
                    "chosenOptionIds": [
                        "a80457e5-70be-5103-ad49-69a47f84d227"
                    ]
                },
                "SH-23a": {
                    "questionId": "SH-23a",
                    "chosenOptionIds": [
                        "5738b269-a761-5967-a3a9-e5861936cc74"
                    ]
                },
                "SH-2a": {
                    "questionId": "SH-2a",
                    "chosenOptionIds": [
                        "c81ca000-63f4-5d4d-b175-ed7c74561574"
                    ]
                },
                "SH-4a": {
                    "questionId": "SH-4a",
                    "freeValue": 300
                },
                "SH-6a": {
                    "questionId": "SH-6a",
                    "chosenOptionIds": [
                        "6618bab9-cf95-5c64-8182-e64a43ae5a5e",
                        "9f789285-dcea-547c-8aa8-b37291bcfae2",
                        "bf613ed8-0c2a-5ac7-8b03-abfb31ce786b",
                        "b28d2db7-a851-5734-bf54-0ad05544946b"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "981f1af5-5769-5932-8778-58a6e01aa624"
                    ]
                },
                "SH-s2": {
                    "questionId": "SH-s2",
                    "freeValue": 17
                },
                "SH-s3": {
                    "questionId": "SH-s3",
                    "chosenOptionIds": [
                        "29309292-72fb-5781-ae9a-973977aac3af"
                    ]
                }
            }
        },
        {
            "name": "screened user: 23XX43N aspect: suish_csui_csh should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "59a1b51e-3319-5c4d-a9f4-e50455a6f5a6"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "07c32652-123e-5f58-894e-f4ed9c8b110e"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        },
        {
            "name": "screened user: J3J4P0Y aspect: suish_csui_csh should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "59a1b51e-3319-5c4d-a9f4-e50455a6f5a6"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "07c32652-123e-5f58-894e-f4ed9c8b110e"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        },
        {
            "name": "screened user: 7EW6KE8 aspect: suish_csui_csh should be positive",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "S-13.0a": {
                    "questionId": "S-13.0a",
                    "chosenOptionIds": [
                        "35639af8-738a-5ba6-bf47-d0fa52511234"
                    ]
                },
                "S-13a": {
                    "questionId": "S-13a",
                    "chosenOptionIds": [
                        "8e2bdd16-a97a-5130-8f6e-edb8dd52afe3"
                    ]
                },
                "S-18a": {
                    "questionId": "S-18a",
                    "chosenOptionIds": [
                        "e8bbf23a-7af1-5ec5-a666-e81bbeed6043"
                    ]
                },
                "S-22a": {
                    "questionId": "S-22a",
                    "chosenOptionIds": [
                        "c5321fad-d3b3-58d2-979b-11ca81e25dbf"
                    ]
                },
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "00f4664d-929e-5301-bd5b-f405a7ab8ce4"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "10e008b4-feb5-5134-9869-3dfc46cb9924"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "e90b0a3b-f835-5a9b-948b-d1b685da2c2e"
                    ]
                },
                "S-s4": {
                    "questionId": "S-s4",
                    "chosenOptionIds": [
                        "48d1552a-30b1-5dee-a6f1-f9e12ced08a2"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "981f1af5-5769-5932-8778-58a6e01aa624"
                    ]
                },
                "SH-s2": {
                    "questionId": "SH-s2",
                    "freeValue": 47
                },
                "SH-s3": {
                    "questionId": "SH-s3",
                    "chosenOptionIds": [
                        "29309292-72fb-5781-ae9a-973977aac3af"
                    ]
                }
            }
        },
        {
            "name": "screened user: J0VQM3L aspect: suish_csui_csh should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "59a1b51e-3319-5c4d-a9f4-e50455a6f5a6"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "07c32652-123e-5f58-894e-f4ed9c8b110e"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        },
        {
            "name": "screened user: 4EGRG3P aspect: suish_csui_csh should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "59a1b51e-3319-5c4d-a9f4-e50455a6f5a6"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "07c32652-123e-5f58-894e-f4ed9c8b110e"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        },
        {
            "name": "screened user: ME8MOEW aspect: suish_csui_csh should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "S-13.0a": {
                    "questionId": "S-13.0a",
                    "chosenOptionIds": [
                        "35639af8-738a-5ba6-bf47-d0fa52511234"
                    ]
                },
                "S-13a": {
                    "questionId": "S-13a",
                    "chosenOptionIds": [
                        "8e2bdd16-a97a-5130-8f6e-edb8dd52afe3"
                    ]
                },
                "S-18a": {
                    "questionId": "S-18a",
                    "chosenOptionIds": [
                        "e8bbf23a-7af1-5ec5-a666-e81bbeed6043"
                    ]
                },
                "S-22a": {
                    "questionId": "S-22a",
                    "chosenOptionIds": [
                        "f18f48b0-a401-5787-bb00-ca8d99523304"
                    ]
                },
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "00f4664d-929e-5301-bd5b-f405a7ab8ce4"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "10e008b4-feb5-5134-9869-3dfc46cb9924"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "e90b0a3b-f835-5a9b-948b-d1b685da2c2e"
                    ]
                },
                "S-s4": {
                    "questionId": "S-s4",
                    "chosenOptionIds": [
                        "48d1552a-30b1-5dee-a6f1-f9e12ced08a2"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        },
        {
            "name": "screened user: 50M6136 aspect: suish_csui_csh should be positive",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "S-13a": {
                    "questionId": "S-13a",
                    "chosenOptionIds": [
                        "8e2bdd16-a97a-5130-8f6e-edb8dd52afe3"
                    ]
                },
                "S-18a": {
                    "questionId": "S-18a",
                    "chosenOptionIds": [
                        "81b3f713-07b4-5d30-8066-60c1326c2aa1"
                    ]
                },
                "S-1a": {
                    "questionId": "S-1a",
                    "freeValue": 1825
                },
                "S-2.5a": {
                    "questionId": "S-2.5a",
                    "chosenOptionIds": [
                        "455f8af3-f57a-5fe0-808e-aad6d2439855"
                    ]
                },
                "S-22a": {
                    "questionId": "S-22a",
                    "chosenOptionIds": [
                        "f18f48b0-a401-5787-bb00-ca8d99523304"
                    ]
                },
                "S-2a": {
                    "questionId": "S-2a",
                    "chosenOptionIds": [
                        "84b1dac4-ea2f-5f51-a205-0efe9d0e6c8c"
                    ]
                },
                "S-3a": {
                    "questionId": "S-3a",
                    "freeValue": 100
                },
                "S-4a": {
                    "questionId": "S-4a",
                    "chosenOptionIds": [
                        "2ad36ca1-d48c-57c8-a058-9de92c14e840"
                    ]
                },
                "S-5a": {
                    "questionId": "S-5a",
                    "chosenOptionIds": [
                        "0e07d0fe-f085-5440-b0f4-a5c8345833d3"
                    ]
                },
                "S-6a": {
                    "questionId": "S-6a",
                    "chosenOptionIds": [
                        "0667075d-d44a-5ec5-8a05-4d25659c831d"
                    ]
                },
                "S-8a": {
                    "questionId": "S-8a",
                    "chosenOptionIds": [
                        "fb782e60-165b-5cd2-ae70-c40b281bc387"
                    ]
                },
                "S-9.5a": {
                    "questionId": "S-9.5a",
                    "chosenOptionIds": [
                        "9e611ece-c2d6-5900-8832-3cbf939f729d"
                    ]
                },
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "00f4664d-929e-5301-bd5b-f405a7ab8ce4"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "10e008b4-feb5-5134-9869-3dfc46cb9924"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "e90b0a3b-f835-5a9b-948b-d1b685da2c2e"
                    ]
                },
                "S-s4": {
                    "questionId": "S-s4",
                    "chosenOptionIds": [
                        "48d1552a-30b1-5dee-a6f1-f9e12ced08a2"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-1.5a": {
                    "questionId": "SH-1.5a",
                    "chosenOptionIds": [
                        "38c7a9b9-d5a0-5a57-b6fd-4b8f2c7499a8"
                    ]
                },
                "SH-16a": {
                    "questionId": "SH-16a",
                    "chosenOptionIds": [
                        "f3060ab6-8ac7-559d-838c-a58eeb5d018e"
                    ]
                },
                "SH-1a": {
                    "questionId": "SH-1a",
                    "freeValue": 365
                },
                "SH-23.5a": {
                    "questionId": "SH-23.5a",
                    "chosenOptionIds": [
                        "a80457e5-70be-5103-ad49-69a47f84d227"
                    ]
                },
                "SH-23a": {
                    "questionId": "SH-23a",
                    "chosenOptionIds": [
                        "83e881fd-401e-5ce9-9121-1472f92adb82"
                    ]
                },
                "SH-2a": {
                    "questionId": "SH-2a",
                    "chosenOptionIds": [
                        "4992447e-a229-5af8-ba74-0d205735ec32"
                    ]
                },
                "SH-4a": {
                    "questionId": "SH-4a",
                    "freeValue": 730
                },
                "SH-6a": {
                    "questionId": "SH-6a",
                    "chosenOptionIds": [
                        "b28d2db7-a851-5734-bf54-0ad05544946b"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "981f1af5-5769-5932-8778-58a6e01aa624"
                    ]
                },
                "SH-s2": {
                    "questionId": "SH-s2",
                    "freeValue": 28
                },
                "SH-s3": {
                    "questionId": "SH-s3",
                    "chosenOptionIds": [
                        "29309292-72fb-5781-ae9a-973977aac3af"
                    ]
                }
            }
        },
        {
            "name": "screened user: 4EG443P aspect: suish_csui_csh should be positive",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "S-13.0a": {
                    "questionId": "S-13.0a",
                    "chosenOptionIds": [
                        "35639af8-738a-5ba6-bf47-d0fa52511234"
                    ]
                },
                "S-13a": {
                    "questionId": "S-13a",
                    "chosenOptionIds": [
                        "8e2bdd16-a97a-5130-8f6e-edb8dd52afe3"
                    ]
                },
                "S-18a": {
                    "questionId": "S-18a",
                    "chosenOptionIds": [
                        "e8bbf23a-7af1-5ec5-a666-e81bbeed6043"
                    ]
                },
                "S-22a": {
                    "questionId": "S-22a",
                    "chosenOptionIds": [
                        "9bce9d08-d129-5989-8b7b-5612590dc430"
                    ]
                },
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "00f4664d-929e-5301-bd5b-f405a7ab8ce4"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "10e008b4-feb5-5134-9869-3dfc46cb9924"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "e90b0a3b-f835-5a9b-948b-d1b685da2c2e"
                    ]
                },
                "S-s4": {
                    "questionId": "S-s4",
                    "chosenOptionIds": [
                        "48d1552a-30b1-5dee-a6f1-f9e12ced08a2"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-16a": {
                    "questionId": "SH-16a",
                    "chosenOptionIds": [
                        "372a1173-b5c2-502f-9237-df57eebd80f2"
                    ]
                },
                "SH-1a": {
                    "questionId": "SH-1a",
                    "freeValue": 0
                },
                "SH-23.5a": {
                    "questionId": "SH-23.5a",
                    "chosenOptionIds": [
                        "a80457e5-70be-5103-ad49-69a47f84d227"
                    ]
                },
                "SH-23a": {
                    "questionId": "SH-23a",
                    "chosenOptionIds": [
                        "3e347ed1-7ea3-5b21-964f-32cf2d32e1b5"
                    ]
                },
                "SH-2a": {
                    "questionId": "SH-2a",
                    "chosenOptionIds": [
                        "fe25eeda-81fa-58a3-9c72-545aabe803f5"
                    ]
                },
                "SH-4a": {
                    "questionId": "SH-4a",
                    "freeValue": 730
                },
                "SH-6a": {
                    "questionId": "SH-6a",
                    "chosenOptionIds": [
                        "9f789285-dcea-547c-8aa8-b37291bcfae2",
                        "b28d2db7-a851-5734-bf54-0ad05544946b"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "981f1af5-5769-5932-8778-58a6e01aa624"
                    ]
                },
                "SH-s2": {
                    "questionId": "SH-s2",
                    "freeValue": 7
                },
                "SH-s3": {
                    "questionId": "SH-s3",
                    "chosenOptionIds": [
                        "29309292-72fb-5781-ae9a-973977aac3af"
                    ]
                }
            }
        },
        {
            "name": "Tech team adjusted screen from FALSE, Censeo vPrevious user: O3PR70W aspect: suish_csui_csh",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "S-13a": {
                    "questionId": "S-13a",
                    "chosenOptionIds": [
                        "8e2bdd16-a97a-5130-8f6e-edb8dd52afe3"
                    ]
                },
                "S-18a": {
                    "questionId": "S-18a",
                    "chosenOptionIds": [
                        "e8bbf23a-7af1-5ec5-a666-e81bbeed6043"
                    ]
                },
                "S-1a": {
                    "questionId": "S-1a",
                    "freeValue": 7
                },
                "S-2.5a": {
                    "questionId": "S-2.5a",
                    "chosenOptionIds": [
                        "455f8af3-f57a-5fe0-808e-aad6d2439855"
                    ]
                },
                "S-22a": {
                    "questionId": "S-22a",
                    "chosenOptionIds": [
                        "28660364-460b-5f7f-a281-104044331d08"
                    ]
                },
                "S-2a": {
                    "questionId": "S-2a",
                    "chosenOptionIds": [
                        "84b1dac4-ea2f-5f51-a205-0efe9d0e6c8c"
                    ]
                },
                "S-3a": {
                    "questionId": "S-3a",
                    "freeValue": 20
                },
                "S-4a": {
                    "questionId": "S-4a",
                    "chosenOptionIds": [
                        "2ad36ca1-d48c-57c8-a058-9de92c14e840"
                    ]
                },
                "S-5a": {
                    "questionId": "S-5a",
                    "chosenOptionIds": [
                        "86839467-8345-58f0-9355-a14ba91c7079"
                    ]
                },
                "S-6a": {
                    "questionId": "S-6a",
                    "chosenOptionIds": [
                        "c0db0893-6906-5a21-ab74-144c85c0683d"
                    ]
                },
                "S-8a": {
                    "questionId": "S-8a",
                    "chosenOptionIds": [
                        "cb6c8c06-d0b8-558d-8e33-bf475718cb0d"
                    ]
                },
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "00f4664d-929e-5301-bd5b-f405a7ab8ce4"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "10e008b4-feb5-5134-9869-3dfc46cb9924"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "e90b0a3b-f835-5a9b-948b-d1b685da2c2e"
                    ]
                },
                "S-s4": {
                    "questionId": "S-s4",
                    "chosenOptionIds": [
                        "48d1552a-30b1-5dee-a6f1-f9e12ced08a2"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "981f1af5-5769-5932-8778-58a6e01aa624"
                    ]
                },
                "SH-s2": {
                    "questionId": "SH-s2",
                    "freeValue": 16
                },
                "SH-s3": {
                    "questionId": "SH-s3",
                    "chosenOptionIds": [
                        "29309292-72fb-5781-ae9a-973977aac3af"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z35PN3O aspect: suish_csui_csh should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "S-13.0a": {
                    "questionId": "S-13.0a",
                    "chosenOptionIds": [
                        "35639af8-738a-5ba6-bf47-d0fa52511234"
                    ]
                },
                "S-13a": {
                    "questionId": "S-13a",
                    "chosenOptionIds": [
                        "fadcf643-dd8c-5f24-90d5-f971cf3c6ec7"
                    ]
                },
                "S-14a": {
                    "questionId": "S-14a",
                    "freeValue": 60
                },
                "S-15a": {
                    "questionId": "S-15a",
                    "freeValue": 2
                },
                "S-17a": {
                    "questionId": "S-17a",
                    "chosenOptionIds": [
                        "56bb1b4f-6a67-505a-980b-b4b9bb1792e3"
                    ]
                },
                "S-18a": {
                    "questionId": "S-18a",
                    "chosenOptionIds": [
                        "81b3f713-07b4-5d30-8066-60c1326c2aa1"
                    ]
                },
                "S-1a": {
                    "questionId": "S-1a",
                    "freeValue": 150
                },
                "S-2.5a": {
                    "questionId": "S-2.5a",
                    "chosenOptionIds": [
                        "455f8af3-f57a-5fe0-808e-aad6d2439855"
                    ]
                },
                "S-22a": {
                    "questionId": "S-22a",
                    "chosenOptionIds": [
                        "28660364-460b-5f7f-a281-104044331d08"
                    ]
                },
                "S-2a": {
                    "questionId": "S-2a",
                    "chosenOptionIds": [
                        "84b1dac4-ea2f-5f51-a205-0efe9d0e6c8c"
                    ]
                },
                "S-3a": {
                    "questionId": "S-3a",
                    "freeValue": 6
                },
                "S-4a": {
                    "questionId": "S-4a",
                    "chosenOptionIds": [
                        "131ae75d-c65c-5204-9e13-603a7cd9123d"
                    ]
                },
                "S-5a": {
                    "questionId": "S-5a",
                    "chosenOptionIds": [
                        "86839467-8345-58f0-9355-a14ba91c7079"
                    ]
                },
                "S-6a": {
                    "questionId": "S-6a",
                    "chosenOptionIds": [
                        "870c5cac-fb60-51ca-9d26-bf9e93d56ab2"
                    ]
                },
                "S-8a": {
                    "questionId": "S-8a",
                    "chosenOptionIds": [
                        "4f14f047-a4d6-5709-95b7-4742bec76fa7"
                    ]
                },
                "S-9.5a": {
                    "questionId": "S-9.5a",
                    "chosenOptionIds": [
                        "9e611ece-c2d6-5900-8832-3cbf939f729d"
                    ]
                },
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "00f4664d-929e-5301-bd5b-f405a7ab8ce4"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "10e008b4-feb5-5134-9869-3dfc46cb9924"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "e90b0a3b-f835-5a9b-948b-d1b685da2c2e"
                    ]
                },
                "S-s4": {
                    "questionId": "S-s4",
                    "chosenOptionIds": [
                        "48d1552a-30b1-5dee-a6f1-f9e12ced08a2"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        },
        {
            "name": "screened user: PE61J0L aspect: suish_csui_csh should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "59a1b51e-3319-5c4d-a9f4-e50455a6f5a6"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "07c32652-123e-5f58-894e-f4ed9c8b110e"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        },
        {
            "name": "screened user: J3JK4EY aspect: suish_csui_csh should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "59a1b51e-3319-5c4d-a9f4-e50455a6f5a6"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "07c32652-123e-5f58-894e-f4ed9c8b110e"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "981f1af5-5769-5932-8778-58a6e01aa624"
                    ]
                },
                "SH-s2": {
                    "questionId": "SH-s2",
                    "freeValue": 14
                },
                "SH-s3": {
                    "questionId": "SH-s3",
                    "chosenOptionIds": [
                        "e2e1bae1-c85a-5ab1-9b76-10e31f65a40e"
                    ]
                }
            }
        },
        {
            "name": "Tech team adjusted screen from FALSE, Censeo vPrevious user: 7EW4D08 aspect: suish_csui_csh",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "S-13.0a": {
                    "questionId": "S-13.0a",
                    "chosenOptionIds": [
                        "35639af8-738a-5ba6-bf47-d0fa52511234"
                    ]
                },
                "S-13a": {
                    "questionId": "S-13a",
                    "chosenOptionIds": [
                        "fadcf643-dd8c-5f24-90d5-f971cf3c6ec7"
                    ]
                },
                "S-14a": {
                    "questionId": "S-14a",
                    "freeValue": 60
                },
                "S-15a": {
                    "questionId": "S-15a",
                    "freeValue": 5
                },
                "S-17a": {
                    "questionId": "S-17a",
                    "chosenOptionIds": [
                        "7bd78121-d1a4-5bd7-8be2-eefa7e976604"
                    ]
                },
                "S-18a": {
                    "questionId": "S-18a",
                    "chosenOptionIds": [
                        "e8bbf23a-7af1-5ec5-a666-e81bbeed6043"
                    ]
                },
                "S-1a": {
                    "questionId": "S-1a",
                    "freeValue": 730
                },
                "S-2.5a": {
                    "questionId": "S-2.5a",
                    "chosenOptionIds": [
                        "455f8af3-f57a-5fe0-808e-aad6d2439855"
                    ]
                },
                "S-22a": {
                    "questionId": "S-22a",
                    "chosenOptionIds": [
                        "28660364-460b-5f7f-a281-104044331d08"
                    ]
                },
                "S-2a": {
                    "questionId": "S-2a",
                    "chosenOptionIds": [
                        "541369ff-8077-58e5-8bf7-90a8a94d14bb"
                    ]
                },
                "S-3a": {
                    "questionId": "S-3a",
                    "freeValue": 20
                },
                "S-4a": {
                    "questionId": "S-4a",
                    "chosenOptionIds": [
                        "2ad36ca1-d48c-57c8-a058-9de92c14e840"
                    ]
                },
                "S-5a": {
                    "questionId": "S-5a",
                    "chosenOptionIds": [
                        "86839467-8345-58f0-9355-a14ba91c7079"
                    ]
                },
                "S-6a": {
                    "questionId": "S-6a",
                    "chosenOptionIds": [
                        "0181dcdd-3d3d-512a-bdf8-de8a996bd7e8"
                    ]
                },
                "S-8a": {
                    "questionId": "S-8a",
                    "chosenOptionIds": [
                        "fb782e60-165b-5cd2-ae70-c40b281bc387"
                    ]
                },
                "S-9.5a": {
                    "questionId": "S-9.5a",
                    "chosenOptionIds": [
                        "9e611ece-c2d6-5900-8832-3cbf939f729d"
                    ]
                },
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "00f4664d-929e-5301-bd5b-f405a7ab8ce4"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "10e008b4-feb5-5134-9869-3dfc46cb9924"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "e90b0a3b-f835-5a9b-948b-d1b685da2c2e"
                    ]
                },
                "S-s4": {
                    "questionId": "S-s4",
                    "chosenOptionIds": [
                        "48d1552a-30b1-5dee-a6f1-f9e12ced08a2"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-1.5a": {
                    "questionId": "SH-1.5a",
                    "chosenOptionIds": [
                        "38c7a9b9-d5a0-5a57-b6fd-4b8f2c7499a8"
                    ]
                },
                "SH-16a": {
                    "questionId": "SH-16a",
                    "chosenOptionIds": [
                        "f3060ab6-8ac7-559d-838c-a58eeb5d018e"
                    ]
                },
                "SH-1a": {
                    "questionId": "SH-1a",
                    "freeValue": 2
                },
                "SH-23.5a": {
                    "questionId": "SH-23.5a",
                    "chosenOptionIds": [
                        "a80457e5-70be-5103-ad49-69a47f84d227"
                    ]
                },
                "SH-23a": {
                    "questionId": "SH-23a",
                    "chosenOptionIds": [
                        "025fa831-b50c-51c6-8ece-7f851af56e4f"
                    ]
                },
                "SH-2a": {
                    "questionId": "SH-2a",
                    "chosenOptionIds": [
                        "fe25eeda-81fa-58a3-9c72-545aabe803f5"
                    ]
                },
                "SH-4a": {
                    "questionId": "SH-4a",
                    "freeValue": 180
                },
                "SH-6a": {
                    "questionId": "SH-6a",
                    "chosenOptionIds": [
                        "a770529d-17f7-5b21-8f7b-3b508e9cb983"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "981f1af5-5769-5932-8778-58a6e01aa624"
                    ]
                },
                "SH-s2": {
                    "questionId": "SH-s2",
                    "freeValue": 36
                },
                "SH-s3": {
                    "questionId": "SH-s3",
                    "chosenOptionIds": [
                        "29309292-72fb-5781-ae9a-973977aac3af"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z3RLO3O aspect: suish_csui_csh should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "59a1b51e-3319-5c4d-a9f4-e50455a6f5a6"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "07c32652-123e-5f58-894e-f4ed9c8b110e"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        },
        {
            "name": "screened user: XE7WX3L aspect: suish_csui_csh should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "59a1b51e-3319-5c4d-a9f4-e50455a6f5a6"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "07c32652-123e-5f58-894e-f4ed9c8b110e"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        },
        {
            "name": "screened user: O3P263W aspect: suish_csui_csh should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "59a1b51e-3319-5c4d-a9f4-e50455a6f5a6"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "07c32652-123e-5f58-894e-f4ed9c8b110e"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        }
    ]
};
exports.questionPredicates = {
    "SH-s2": "not(SH-s1=0)",
    "SH-s3": "not(SH-s1=0)",
    "SH-1a": "SH-s3=1",
    "SH-1.5a": "SH-1a>=1",
    "SH-2a": "SH-s3=1",
    "SH-4a": "SH-s3=1",
    "SH-6a": "SH-s3=1",
    "SH-16a": "SH-s3=1",
    "SH-23a": "SH-s3=1",
    "SH-23.5a": "SH-23a>=0.25",
    "SH-2b": "not(SH-s3=1)",
    "SH-3b": "SH-2b<=730",
    "SH-16b": "SH-2b<=730",
    "SH-23b": "not(SH-s3=1)",
    "SH-23.5b": "not(SH-s3=1)",
    "S-s4": "and[or[isUndefined(and[S-s1=0, S-s2=0, S-s3=0]), not(and[S-s1=0, S-s2=0, S-s3=0])], not(isUndefined(S-s3=0))]",
    "S-2.5a": "S-s4=1",
    "S-18a": "S-s4=1",
    "S-1a": "S-s4=1",
    "S-2a": "S-s4=1",
    "S-4a": "S-s4=1",
    "S-5a": "S-s4=1",
    "S-6a": "S-s4=1",
    "S-8a": "S-s4=1",
    "S-13.0a": "and[or[isUndefined(and[S-s1=0, S-s2=0, S-s3=0]), not(and[S-s1=0, S-s2=0, S-s3=0])], not(isUndefined(S-s3=0))]",
    "S-3a": "and[or[isUndefined(and[S-s1=0, S-s2=0, S-s3=0]), not(and[S-s1=0, S-s2=0, S-s3=0])], not(isUndefined(S-s3=0))]",
    "S-13a": "and[or[isUndefined(and[S-s1=0, S-s2=0, S-s3=0]), not(and[S-s1=0, S-s2=0, S-s3=0])], not(isUndefined(S-s3=0))]",
    "S-14a": "not(S-13a=0)",
    "S-15a": "not(S-13a=0)",
    "S-17a": "not(S-13a=0)",
    "S-9.5a": "and[or[isUndefined(and[S-s1=0, S-s2=0, S-s3=0]), not(and[S-s1=0, S-s2=0, S-s3=0])], not(isUndefined(S-s3=0))]",
    "S-22a": "and[or[isUndefined(and[S-s1=0, S-s2=0, S-s3=0]), not(and[S-s1=0, S-s2=0, S-s3=0])], not(isUndefined(S-s3=0))]"
};
