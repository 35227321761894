import * as Yup from 'yup'
import { FormikErrors, FormikHandlers, FormikHelpers, FormikTouched } from 'formik'
import { UserType } from 'censeo-core'
import { isENGBLocale } from 'registration/utils/locale'

export type OnboardingFormStage = 'gender' | 'ethnicity' | 'sex' | 'name' | 'postcode'

const isNhs = (message: string) => ({
    is: (val: UserType) => val === UserType.Nhs,
    then: (s: any) => s.required(message),
})

const isNhsWithPostcode = (message: string) => ({
    is: (val: UserType) => val === UserType.Nhs && isENGBLocale(),
    then: (s: any) => s.required(message),
})

export interface OnboardingFormInputs {
    gender: string
    sex: string
    ethnicity: string
    firstName: string
    lastName: string
    birthdate: string
    postcode: string
    dobDay: string
    dobMonth: string
    dobYear: string
    dobDate?: Date
}

export interface OnboardingFormProps {
    setPageState: (stage: OnboardingFormStage) => void
    setFieldValue: FormikHelpers<OnboardingFormInputs>['setFieldValue']
    values: OnboardingFormInputs
    errors: FormikErrors<OnboardingFormInputs>
    touched: FormikTouched<OnboardingFormInputs>
    onChange: FormikHandlers['handleChange']
    onBlur?: FormikHandlers['handleBlur']
    validateForm: (values?: any) => Promise<FormikErrors<OnboardingFormInputs>>
    submitForm: () => Promise<void>
}

export const OnboardingInputsInitialValues: OnboardingFormInputs = {
    gender: '',
    ethnicity: '',
    sex: '',
    firstName: '',
    lastName: '',
    birthdate: '',
    postcode: '',
    dobDay: '',
    dobMonth: '',
    dobYear: '',
}

export const OnboardingFormSchema = Yup.object().shape({
    sex: Yup.string().required('Please select an option above'),
    gender: Yup.string().required("Please select an option above or choose 'Prefer not to say'"),
    ethnicity: Yup.string().required("Please select an option above or choose 'Prefer not to say'"),
    birthdate: Yup.string().required('Please enter your date of birth.'),
    firstName: Yup.string().when('userType', isNhs('Please enter your first name.')),
    lastName: Yup.string().when('userType', isNhs('Please enter your last name')),
    postcode: Yup.string().when('userType', isNhsWithPostcode('Please enter a valid UK postcode.')),
})
