import React, { useEffect, useState } from 'react'
import CheckIn from 'ui/app/pages/CheckIn'
import { Questionnaire } from 'ui/app/pages/Questionnaire'
import { AnsweredQuestion } from 'censeo-core'
import { Routes, Route, useLocation, Navigate } from 'react-router-dom'
import { UserProfile } from 'ui/app/pages/UserProfile'
import { RegulatoryInformation } from 'ui/app/pages/RegulatoryInformation'
import MobileFramePage from '../MobileFramePage/MobileFramePage'
import { useDemoContext } from './DemoContext'
import { Splash } from 'ui/app/pages/Splash'
import NotFound from 'ui/app/pages/NotFound'

const DemoAssessment: React.FC = () => {
    const { demoFrom, setDemoFrom, demoExperienceType } = useDemoContext()
    const [questionId, setQuestionId] = useState('')
    const [demoAnswers, setDemoAnswers] = useState<AnsweredQuestion[]>([])
    const location = useLocation()

    useEffect(() => {
        if (new URLSearchParams(location.search).has('qrstart')) {
            sessionStorage.removeItem('demoAnswers')
            setDemoAnswers([])
            setDemoFrom('qrstart')
        } else if (new URLSearchParams(location.search).has('start')) {
            sessionStorage.removeItem('demoAnswers')
            setDemoAnswers([])
            setDemoFrom('start')
        }
    }, [location.search, setDemoFrom, demoFrom])

    useEffect(() => {
        if (demoAnswers.length > 0) sessionStorage.setItem('demoAnswers', JSON.stringify(demoAnswers))
    }, [demoAnswers])

    useEffect(() => {
        const savedAnswers = sessionStorage.getItem('demoAnswers')
        if (savedAnswers) {
            setDemoAnswers(JSON.parse(savedAnswers))
        }
    }, [])

    if (!demoFrom || !demoExperienceType) {
        return <Splash />
    }

    const routes = (
        <Routes location={location}>
            <Route path="/checkIn" element={<CheckIn setQuestionId={setQuestionId} demo={demoAnswers}></CheckIn>} />
            <Route
                path="/question"
                element={
                    questionId ? (
                        <Questionnaire
                            setQuestionId={setQuestionId}
                            questionId={questionId}
                            demo={{ allAnswers: demoAnswers, setDemoAnswers }}
                        ></Questionnaire>
                    ) : (
                        <Navigate to="/demo/service-user/checkIn" replace />
                    )
                }
            />
            <Route path="/userprofile" element={<UserProfile></UserProfile>} />
            <Route path="/regulatory-information" element={<RegulatoryInformation />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    )

    return demoFrom === 'qrstart' ? routes : <MobileFramePage showQRCode={true}>{routes}</MobileFramePage>
}

export default DemoAssessment
