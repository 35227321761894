"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.nodePaths = exports.supplementalInfoQuestionIds = exports.START_ID = void 0;
const types_1 = require("../types");
exports.START_ID = 'A';
exports.supplementalInfoQuestionIds = [];
exports.nodePaths = {
    A: {
        questionId: 'T-s1',
        answers: {
            '96789d75-b879-5f46-8963-b49a0d5a36c7': types_1.RagIds.rag5,
            '3587b76d-5e17-5be1-8482-6865a9629851': 'B',
        },
    },
    B: {
        questionId: 'T-s3',
        answers: {
            'ab657537-a14b-5d23-bcc5-8a10faac75b2': types_1.RagIds.rag5,
            '4fb618ab-1a98-5a8b-8a64-000b45d953f9': types_1.RagIds.rag5,
            'f8e62fbd-28e3-5527-94f5-9590869545a3': types_1.RagIds.rag5,
            'ab90ebb6-b526-5d58-97dd-181c2ddaefdf': 'C',
            'cd79664e-d646-54f6-8ce2-1f236d79730f': 'C',
            'e3912e04-a5d3-5bf1-869a-a651db93c0f7': types_1.RagIds.rag5,
        },
    },
    C: {
        questionId: 'T-34.4',
        answers: {
            'aa992ff2-b894-50ad-87b9-1863b8c261b3': types_1.RagIds.rag5,
            'bf6c3ef5-62fa-5172-9a8e-db0dae8fb96a': types_1.RagIds.rag5,
            '0bd84624-e550-553c-baff-f4ad880a2a4f': 'D',
            '34874654-ecda-51ca-94ea-045a0b3c6837': 'D',
            '3125e96d-cc4a-5c45-a2fa-a3d908969214': types_1.RagIds.rag5,
            '60db9fcd-8952-53a9-8db4-9a0377847211': types_1.RagIds.rag5,
        },
    },
    D: {
        questionId: 'T-34.7',
        answers: {
            '103cb9b5-785e-5301-afc4-4efa1f89d3f0': types_1.RagIds.rag5,
            '465fe7ff-e2a2-5390-a835-00807ffc1a0c': types_1.RagIds.rag5,
            'c12290da-a5de-5f26-8164-110211b6bd4c': types_1.RagIds.rag4,
            '9c131dec-46af-5942-a8f1-697d8163fe24': types_1.RagIds.rag4,
            '07417cf6-b358-5b33-b247-78ef198c90a0': types_1.RagIds.rag5,
            '639306fb-e09d-555a-a2d7-9e325c4ae9e9': types_1.RagIds.rag5,
        },
    },
};
