import { Button, Heading, Page, Text } from '@psyomics/components'
import React, { useContext, useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import Hotjar from '@hotjar/browser'
import { InfoContext } from 'configure'
import DemoHeader from 'ui/app/components/DemoHeader'
import { createReferral, tokenRequest } from 'registration/utils/inboundApi'

const ProlificLanding: React.FC = () => {
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const prolificId = queryParams.get('PROLIFIC_PID')

    useEffect(() => {
        const hotjarSiteId = import.meta.env.VITE_APP_HOTJAR_PROLIFIC_ID
        const hotjarVersion = 6
        if (hotjarSiteId) {
            Hotjar.init(Number(hotjarSiteId), hotjarVersion)
            Hotjar.identify(prolificId || 'no prolific id', {
                prolificId: prolificId || 'no prolific id',
                userType: 'prolific',
            })
        }
    }, [])

    const info = useContext(InfoContext)
    const navigate = useNavigate()

    const [error, setError] = useState<string | undefined>(undefined)
    const [buttonLoading, setButtonLoading] = useState<boolean>(false)

    async function startReferral() {
        try {
            if (!info) {
                throw new Error('No data from info endpoint')
            }
            if (!prolificId) {
                const errorMessage = 'No prolific id found to start assessment with'
                Sentry.captureException(errorMessage)
                setError(errorMessage)
                setButtonLoading(false)
                return
            }

            setButtonLoading(true)
            const demoClientId = info?.prolificUserPoolClientApp?.id
            const demoClientSecret = info?.prolificUserPoolClientApp?.secret
            if (!demoClientId || !demoClientSecret) {
                const errorMessage = 'Demo client credentials not defined'
                Sentry.captureException(errorMessage)
                setError(errorMessage)
                setButtonLoading(false)
                return
            }
            const {
                accessToken,
                tokenType,
                errorMessage: tokenErrorMessage,
            } = await tokenRequest(info.inboundApiUrl, demoClientId, demoClientSecret)

            if (tokenErrorMessage) {
                setError(tokenErrorMessage)
                setButtonLoading(false)
                return
            }

            if (!accessToken || !tokenType) {
                const errorMessage = 'no token retrieved for token endpoint for demo users.'
                Sentry.captureException(errorMessage)
                setError(errorMessage)
                return
            }
            const { errorMessage, publicReferralId } = await createReferral(
                info.inboundApiUrl,
                accessToken,
                tokenType,
                'Prolific',
                prolificId
            )

            if (errorMessage) {
                Sentry.captureException(errorMessage)
                setError(errorMessage)
                setButtonLoading(false)
                return
            }
            if (!publicReferralId) {
                const errorMessage = 'No referral id retrieved.'
                Sentry.captureException(new Error(errorMessage))
                setError(errorMessage)
                setButtonLoading(false)
                return
            }
            navigate('/referral/' + publicReferralId)
        } catch (err) {
            Sentry.captureException(err)
            setError('Failed to get create and redirect to referral')
            setButtonLoading(false)
        }
    }

    return (
        <Page
            style={{ backgroundColor: 'transparent' }}
            layout="standard"
            width="narrow"
            header={<DemoHeader navButtons={false} />}
        >
            <Heading el="h2" size="heading2" color="mid" css={{ mb: 2 }}>
                Prolific Study
            </Heading>
            <Text size="medium" css={{ mb: 5 }}>
                Thank you for signing up to the study. Some quick reminders before you start:
            </Text>
            <Text size="medium" css={{ mb: 5 }}>
                i. Please do not enter your real information during the sign up and creating an account section. You can
                use your prolific email address to create your account.
            </Text>
            <Text size="medium" css={{ mb: 5 }}>
                ii. Please take time to read and understand the questions.
            </Text>
            <Text size="medium" css={{ mb: 5 }}>
                iii. We do not expect you to answer the assessment questions truthfully if you don&apos;t want to.
            </Text>
            <Text size="medium" css={{ mb: 5 }}>
                iv. You can take a break anytime but bear in mind that the assessment will time out after 15 minutes. If
                this happens you will need to log back in using the email address you entered and password.
            </Text>

            {error && (
                <Text weight="demibold" css={{ mt: 5 }}>
                    Sorry, there has been an error in starting the assessment. If you want access, or believe this is a
                    mistake and you should have access, please contact support at{' '}
                    <a href="mailto:support@censeo.co.uk">support@censeo.co.uk</a>.
                </Text>
            )}

            <div>
                <Button
                    className="text-right"
                    appearance="primary"
                    rounded
                    loading={buttonLoading}
                    disabled={!!error}
                    size="large"
                    label="Start Assessment"
                    fullWidth={true}
                    onClick={() => {
                        startReferral()
                    }}
                />
            </div>
        </Page>
    )
}

export default ProlificLanding
