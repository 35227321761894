import React from 'react'
import { Text, Radio } from '@psyomics/components'
import Label from 'registration/components/Label'
import Styles from './RadioGroup.module.scss'
import Validation from '../Validation'

export type RadioOption = {
    label: string
    value: string
}

type RadioGroupProps = {
    label: string
    options: Array<RadioOption>
    value?: string
    groupName: string
    onChange: (val: any) => void
    displayOr?: boolean
    error?: string
}

type IRadioGroupProps = RadioGroupProps & React.ComponentPropsWithoutRef<'div'>

const RadioGroup: React.FC<IRadioGroupProps> = ({ options, label, value, groupName, displayOr, error, onChange }) => {
    const lastOption = displayOr ? options.slice(-1)[0] : undefined
    const displayOptions = displayOr ? options.slice(0, -1) : options

    return (
        <fieldset className={Styles.fieldset}>
            <Label el="legend">{label}</Label>
            <div className={Styles.options}>
                {displayOptions.map((option) => (
                    <Radio
                        size="large"
                        label={option.label}
                        key={option.value}
                        id={option.value}
                        name={groupName}
                        checked={value === option.value}
                        onChange={onChange}
                        value={option.value}
                    />
                ))}
                {lastOption && (
                    <>
                        <Text color="mid">or</Text>
                        <Radio
                            size="large"
                            label={lastOption.label}
                            key={lastOption.value}
                            id={lastOption.value}
                            name={groupName}
                            checked={value === lastOption.value}
                            onChange={onChange}
                            value={lastOption.value}
                        />
                    </>
                )}
                {error && <Validation className={Styles.validation}>{error}</Validation>}
            </div>
        </fieldset>
    )
}

export default RadioGroup
