import React, { useRef } from 'react'
import { Button, Heading, Page, Text } from '@psyomics/components'
import { useNavigate, useParams } from 'react-router-dom'
import Styles from './OrganisationAccount.module.scss'
import { IPageProps } from '@psyomics/components/components/Page/Page'
import Header from 'ui/app/components/Header'

const OrganisationAccount: React.FC<{ referralPortalEnabled: boolean }> = ({ ...props }) => {
    const { referralPortalEnabled } = props
    const { organisationCode } = useParams<{ organisationCode: string }>()
    const scrollingElementRef = useRef<HTMLDivElement>(null)
    const navigate = useNavigate()

    const pageProps = {
        layout: 'standard',
        scrollingElementRef,
        header: <Header patientFacing={false}></Header>,
    } as IPageProps

    return (
        <Page {...pageProps}>
            <Heading el="h2" size="heading2" color="mid" css={{ mt: 7 }}>
                Dashboards
            </Heading>
            <Text css={{ mt: 2, mb: 7 }}>
                Click on the following links to view individual dashboards for your organisation
            </Text>
            <div className={Styles.managementGroup}>
                <div className={Styles.managementButtonWrapper}>
                    <Button
                        className="text-right"
                        appearance="primary"
                        rounded
                        size="large"
                        fullWidth={true}
                        label="Referral Stats"
                        onClick={() => {
                            navigate(`/account/${organisationCode}/referral-stats`)
                        }}
                    />
                </div>
                <Text css={{ ml: 3 }}>This dashboard shows you statistics relating to referrals.</Text>
            </div>
            <div className={Styles.managementGroup}>
                <div className={Styles.managementButtonWrapper}>
                    <Button
                        className="text-right"
                        appearance="primary"
                        rounded
                        size="large"
                        fullWidth={true}
                        label="Population Health"
                        onClick={() => {
                            navigate(`/account/${organisationCode}/population-health`)
                        }}
                    />
                </div>
                <Text css={{ ml: 3 }}>
                    This displays the clinical data gathered from your patients / service users to give you an overall
                    picture of your population.
                </Text>
            </div>
            <div className={Styles.managementGroup}>
                <div className={Styles.managementButtonWrapper}>
                    <Button
                        className="text-right"
                        appearance="primary"
                        rounded
                        size="large"
                        fullWidth={true}
                        label="Clinical Reports"
                        onClick={() => {
                            navigate(`/account/${organisationCode}/patient-referrals`)
                        }}
                    />
                </div>
                <Text css={{ ml: 3 }}>
                    This allows you to view the status of referred patients/service users along with links to their
                    Censeo reports.
                </Text>
            </div>
            {referralPortalEnabled && (
                <div className={Styles.managementGroup}>
                    <div className={Styles.managementButtonWrapper}>
                        <Button
                            className="text-right"
                            appearance="primary"
                            rounded
                            size="large"
                            fullWidth={true}
                            label="Refer Patients"
                            onClick={() => {
                                navigate(`/account/${organisationCode}/refer-patients`)
                            }}
                        />
                    </div>
                    <Text css={{ ml: 3 }}>This allows you to import a CSV file to refer new patients.</Text>
                </div>
            )}
        </Page>
    )
}

export default OrganisationAccount
