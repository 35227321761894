import React, { useContext } from 'react'
import Styles from './NhsLanding1.module.scss'
import { Formik } from 'formik'
import HeaderImage from '../../../images/service-signup-header.svg?react'
import HertsNhsLogo from '../../../images/nhs-herts-logo.svg?react'
import { Button, Heading, Input, Logo, Page, ProgressDots, Text, Link } from '@psyomics/components'
import Header from 'registration/components/Header'
import Field from 'registration/components/Field'
import Label from 'registration/components/Label'
import Card from 'registration/components/Card'
import Validation from 'registration/components/Validation'
import { Referral, ReferralCode, Referrals, UserType } from 'censeo-core'
import { useNavigate, useLocation } from 'react-router-dom'
import { useRegistration } from 'registration/context/registration/useRegistration'
import { useReportingApi } from 'ui/app/reporting'
import { useMixpanelEvent } from 'ui/app/useMixpanelEvent'
import { useEffect } from 'react'
import { useAuth } from '../../../context/auth'
import { useAsync } from '../../../hooks/use-async'
import { Splash } from 'ui/app/pages/Splash'
import Error from 'ui/app/pages/Error'
import { handleSignin } from '../../SignIn/use-Signin'
import { RegistrationRoutes } from 'registration/context/registration/registration.definitions'
import * as Sentry from '@sentry/react'
import { classNameMaker } from 'ui/utils'
import { MixpanelContext } from 'registration/context/mixpanel/mixpanel-context'
import { ReferralDetails, ReferralDetailsContext } from 'ui/app/context/referralDetails'
import NotFound from 'ui/app/pages/NotFound'
import DemoFooter from 'ui/app/components/DemoFooter'
// import OrBreak from 'registration/components/OrBreak'
// import NhsButton from 'registration/components/NhsButton'

type NhsLandingProps = React.ComponentPropsWithoutRef<'div'> & {
    referralPublicId?: string
    setReferralPublicId?: React.Dispatch<React.SetStateAction<string | undefined>>
}

type NhsLandingPageProps = React.ComponentPropsWithoutRef<'div'> & {
    referral: Referral
    referralPublicId?: string
}

const NhsLanding1: React.FC<NhsLandingProps> = ({ ...props }) => {
    const navigate = useNavigate()
    const { referralPublicId, setReferralPublicId } = props
    const { pathname } = useLocation()
    const referral = getReferralDataFromPath(pathname)
    const reporting = useReportingApi()
    const { status: referralDetailsStatus, data: referralDetails } = useContext(ReferralDetailsContext) || {}
    const isDemo = referralDetails?.organisationConfig?.demo
    const mixpanelApiUrl = useContext(MixpanelContext)

    const { visitedCenseo } = useMixpanelEvent(mixpanelApiUrl)

    useEffect(() => {
        if (referralPublicId && setReferralPublicId) {
            setReferralPublicId((prev) => (prev !== referralPublicId ? referralPublicId : prev))
        }
    }, [referralPublicId, setReferralPublicId])

    reporting?.startedRegistration(UserType.Nhs, referral?.code)

    useEffect(() => {
        if (
            referralDetailsStatus !== 'id-not-set-yet' &&
            referralDetailsStatus !== 'loading' &&
            referralDetailsStatus !== 'pending'
        ) {
            visitedCenseo(
                referralDetails?.organisationCode,
                referralDetails?.organisationName,
                UserType.Nhs,
                referral?.code,
                referralDetails?.publicId
            )
        }
    }, [
        referralDetailsStatus, // Ensures the hook runs once the status is not 'loading' or 'pending'
        visitedCenseo,
        referral?.code,
        referralDetails?.organisationName,
        referralDetails?.organisationCode,
    ])

    if (referralDetailsStatus && referralPublicId) {
        if (referralDetailsStatus === 'loading' || referralDetailsStatus === 'id-not-set-yet') {
            return <Splash />
        }

        if (referralDetailsStatus === 'error' || referralDetailsStatus === 'no-id') {
            Sentry.captureException(`Failed to get referral public id ${referralPublicId}`)
            return <Error />
        }

        if (referralDetails && !referralDetails?.validLink && !isDemo) {
            navigate('/signin')
        }

        if (referralDetailsStatus === 'not-found') {
            return <LinkInvalid />
        }

        if (referralDetailsStatus === 'success' && referralDetails) {
            // the landing page will be changed to be a generic one and all these referralCodes will be removed soon
            const referralToUse = getReferralCodeForOrganisation(referralDetails.organisationCode)
            if (!referralToUse) {
                Sentry.captureException(`Organisation not set or found for referral public id ${referralPublicId}`)
                return <Error />
            }

            return <NhsLandingPage referral={referralToUse} referralPublicId={referralPublicId} />
        }

        return <Splash></Splash>
    }

    return <NotFound></NotFound>
}

const NhsLandingPage: React.FC<NhsLandingPageProps> = ({ ...props }) => {
    const navigate = useNavigate()
    const { startNhs, numDots } = useRegistration()
    const mixpanelApiUrl = useContext(MixpanelContext)
    const { startedRegistration } = useMixpanelEvent(mixpanelApiUrl)
    const { referral, referralPublicId } = props
    const referralDetails = useContext(ReferralDetailsContext)
    const isDemoUser = referral?.code?.startsWith('demo')

    const getStarted = () => {
        startedRegistration(
            referralDetails?.data?.organisationCode,
            referralDetails?.data?.organisationName,
            UserType.Nhs,
            referral?.code,
            referralPublicId
        )
        startNhs(
            referral?.code,
            referralPublicId,
            referralDetails?.data?.organisationCode,
            referralDetails?.data?.organisationName
        )

        navigate(RegistrationRoutes.afterLanding)
    }

    return (
        <Page
            className={Styles.page}
            layout="standard"
            width="narrow"
            header={
                <header className={Styles.headerWithIllustration}>
                    <div className={Styles.headerInner}>
                        <HeaderImage height={'300px'} />
                        <div className={Styles.nav}>
                            <Logo height="30" width="100" />
                        </div>
                        {referral?.serviceCode === 'herts' && (
                            <div className={Styles.nhsHertsLogo}>
                                <HertsNhsLogo />
                            </div>
                        )}
                    </div>
                </header>
            }
            footer={isDemoUser ? <DemoFooter footerType="patient" /> : undefined}
        >
            <div>
                <Heading el="h1" size="heading2" color="mid" css={{ mb: 3 }}>
                    Welcome
                </Heading>
                <Copy isDemoUser={isDemoUser} referralDetails={referralDetails}></Copy>
            </div>
            <ProgressDots count={numDots} current={1} css={{ mt: 7, mb: 7 }} />
            <div className={Styles.nextBtnWrapper}>
                <Button
                    type="button"
                    appearance="primary"
                    rounded
                    fullWidth
                    size="large"
                    label="Next"
                    icon={null}
                    css={{ mb: 2 }}
                    onClick={() => getStarted()}
                />
            </div>
        </Page>
    )
}

const Copy: React.FC<{ referralDetails: ReferralDetails | null; isDemoUser: boolean }> = ({
    referralDetails,
    isDemoUser,
}) => {
    if (isDemoUser)
        return (
            <>
                <Text size="medium" css={{ mb: 5 }}>
                    You have been asked to complete this by a healthcare professional. They want to make sure you get
                    the best care available, and to do that please answer the questions as accurately as you can. If you
                    get stuck at any point, you can contact support in the bottom left of your screen.
                </Text>
                <Text size="medium" css={{ mb: 5 }}>
                    The answers to the questions you are about to be asked will be analysed and a report will be sent
                    back to the health care professional who referred you to us, and their team. You will hear from them
                    after they receive the report.
                </Text>
            </>
        )

    if (
        referralDetails?.data?.organisationName &&
        ['sussex-test', 'sussex'].includes(referralDetails?.data?.organisationCode)
    )
        return (
            <>
                <Text size="medium" css={{ mb: 5 }}>
                    In order to support your referral to{' '}
                    <span style={{ fontWeight: 'var(--f-weight-demibold)' }}>
                        {referralDetails?.data?.organisationName}
                        {referralDetails?.data?.organisationNameAbbr &&
                            ` (${referralDetails?.data?.organisationNameAbbr})`}
                    </span>{' '}
                    you have been asked to complete a Censeo assessment.
                </Text>
                <Text size="medium" css={{ mb: 5 }}>
                    The assessment is designed to give your healthcare professional detailed information to help make
                    the best decisions about your care. The assessment allows you to describe your situation in your own
                    words, in a time and place that suits you.
                </Text>
                <Text size="medium" css={{ mb: 5 }}>
                    Upon completion, a report is generated for review by your clinical team.
                </Text>
                <Text size="medium" css={{ mb: 5 }}>
                    For more information about how {referralDetails?.data?.organisationNameAbbr} and Censeo are working
                    together, and the benefits of completing the questionnaire please visit:{' '}
                    <Link
                        rel="noreferrer"
                        href="https://www.sussexpartnership.nhs.uk/our-services/using-our-services/censeo-patient-questionnaire"
                    >
                        Censeo patient questionnaire.
                    </Link>
                </Text>
            </>
        )

    if (referralDetails?.data?.organisationName)
        return (
            <>
                <Text size="medium" css={{ mb: 5 }}>
                    In order to support your referral to{' '}
                    <span style={{ fontWeight: 'var(--f-weight-demibold)' }}>
                        {referralDetails?.data?.organisationName}
                        {referralDetails?.data?.organisationNameAbbr &&
                            ` (${referralDetails?.data?.organisationNameAbbr})`}
                    </span>
                    , you have been asked to complete a Censeo assessment.
                </Text>
                {['herts', 'midlands', 'herts-test', 'midlands-test', 'priory'].includes(
                    referralDetails?.data?.organisationCode
                ) && (
                    <>
                        <Text size="medium" css={{ mb: 5 }}>
                            This assessment is designed to give your healthcare professional detailed information to
                            help make the best decisions about your care. The assessment allows you to describe your
                            situation in your own words, in a time and place that suits you.
                        </Text>
                        <Text size="medium" css={{ mb: 5 }}>
                            Upon completion, a report is generated for review by your clinical team.
                        </Text>
                    </>
                )}
                {referralDetails?.data?.organisationCode.includes('herts') && (
                    <>
                        <Text size="medium" css={{ mb: 5 }}>
                            For more information about {referralDetails?.data?.organisationNameAbbr} and the benefits of
                            completing the Censeo assessment, please visit:
                        </Text>
                        <Link
                            rel="noreferrer"
                            target="_blank"
                            href="https://www.hpft.nhs.uk/service-users/online-self-assessment"
                        >
                            https://www.hpft.nhs.uk/service-users/online-self-assessment
                        </Link>
                    </>
                )}
                {referralDetails?.data?.organisationCode.includes('midlands') && (
                    <>
                        <Text size="medium" css={{ mb: 5 }}>
                            For any further support from {referralDetails?.data?.organisationNameAbbr}, please call 0808
                            196 3002.
                        </Text>
                    </>
                )}
                {referralDetails?.data?.organisationCode.includes('priory') && (
                    <Text size="medium" css={{ mb: 5 }}>
                        If you feel that you need to talk to someone, please visit the following link:{' '}
                        <Link rel="noreferrer" target="_blank" href="https://www.priorygroup.com/crisis-support">
                            https://www.priorygroup.com/crisis-support
                        </Link>
                    </Text>
                )}
            </>
        )

    return (
        <Text size="medium" css={{ mb: 5 }}>
            You have been asked to complete a Censeo assessment by your local psychological therapy service to help make
            sure you receive the best support for your needs.
        </Text>
    )
}

const getReferralDataFromPath = (pathname: string): Referral => {
    const urlParts = pathname
        .split('/')
        .filter((x) => x)
        .map((part) => part.toLowerCase())

    // TODO: find out why the path /signup/confimation is coming here maybe just the tests
    const nhs = urlParts?.[0]
    if (nhs !== 'nhs') {
        return undefined!
    }

    // we know it always returns one result because the routing doesn't allow invalid urls
    return getReferralCode(urlParts?.[1], urlParts?.[2])
}

const getReferralCode = (serviceCode: string, pathway?: string): Referral => {
    const [ReferralCode] = pathway?.length
        ? Object.values(Referrals).filter((x) => x.serviceCode === serviceCode && x.pathwayCode === pathway)
        : Object.values(Referrals).filter((x) => x.serviceCode === serviceCode)

    return ReferralCode
}

const getReferralCodeForOrganisation = (organisationCode: string): Referral | undefined => {
    if (organisationCode.toLowerCase().startsWith('demo')) {
        return Referrals[ReferralCode.demo]
    }
    if (organisationCode.toLowerCase().startsWith('prolific')) {
        return Referrals[ReferralCode.demo]
    }

    switch (organisationCode) {
        case 'test-trust':
            return Referrals[ReferralCode.cambsdigital]
        case 'herts-test':
            return Referrals[ReferralCode.hertsnwdtest]
        case 'herts':
            return Referrals[ReferralCode.herts]
        case 'midlands':
            return Referrals[ReferralCode.midlands]
        case 'midlands-test':
            return Referrals[ReferralCode.midlandstest]
        case 'sussex-test':
            return Referrals[ReferralCode.sussextest]
        case 'test-quicksilva-gp':
            return Referrals[ReferralCode.testquicksilvagp]
        case 'priory':
            return Referrals[ReferralCode.priory]
        case 'test-mesh':
            return Referrals[ReferralCode.testmesh]
        case 'sussex':
            return Referrals[ReferralCode.sussex]
        case 'devon':
            return Referrals[ReferralCode.devon]
        case 'devon-test':
            return Referrals[ReferralCode.devontest]
        case 'livewell':
            return Referrals[ReferralCode.livewell]
        case 'livewell-test':
            return Referrals[ReferralCode.livewelltest]
        default:
            return undefined
    }
}

const LinkInvalid: React.FC = () => {
    const { initializeUser, requireConfirmation } = useAuth()
    const { error, isLoading, isError, run } = useAsync()
    const navigate = useNavigate()
    const mixpanelApiUrl = useContext(MixpanelContext)
    const { loggedIn } = useMixpanelEvent(mixpanelApiUrl)

    const containerClass: string = classNameMaker({
        [Styles.container]: true,
    })

    return (
        <Page>
            <Header template="landing" />
            <Formik
                initialValues={{
                    email: '',
                    password: '',
                }}
                onSubmit={async (values) => {
                    run(
                        handleSignin({ values, initializeUser, requestConfirmation: requireConfirmation }).then(
                            (user) => {
                                if (user) {
                                    loggedIn(undefined, undefined)
                                    navigate('/')
                                }
                            }
                        )
                    )
                }}
            >
                {({ values, handleChange, handleBlur, handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <Card>
                            <div className={containerClass}>
                                <Text size="large" color="mid" role="heading">
                                    <b>Invalid Link</b>
                                </Text>
                            </div>

                            <Text css={{ mt: 4 }}>
                                This link is invalid. If you have previously registered, please log in below. If the
                                issue persists email us at:{' '}
                                <Link href="mailto:support@censeo.co.uk">support@censeo.co.uk</Link>
                            </Text>
                            <Field>
                                <Label htmlFor="email">Email</Label>
                                <Input
                                    type="email"
                                    id="email"
                                    appearance="outlined"
                                    placeholder="e.g. Jane@example.com"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                />
                            </Field>
                            <Field>
                                <Label htmlFor="password">Password</Label>
                                <Input
                                    type="password"
                                    id="password"
                                    appearance="outlined"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.password}
                                />
                                {isError && error && <Validation>{error.message}</Validation>}
                                <div>
                                    <Link href={RegistrationRoutes.resetpassword}>Forgotten your password?</Link>
                                </div>
                            </Field>
                            <div>
                                <Button
                                    type="submit"
                                    appearance="primary"
                                    rounded
                                    fullWidth
                                    size="large"
                                    label="Sign In"
                                    icon={null}
                                    disabled={!!isLoading}
                                />
                                {/* <OrBreak />
                                <NhsButton /> */}
                            </div>
                        </Card>
                    </form>
                )}
            </Formik>
        </Page>
    )
}

export default NhsLanding1
