import { gql } from '@apollo/client'
import { UserProfile } from 'censeo-core'
import { DocumentNode } from 'graphql'

export const userProfileQuery: DocumentNode = gql`
    query UserProfile($userId: String!) {
        userProfile(userId: $userId) {
            referralPublicId
            firstName
            lastName
            email
            birthDate
            gender
            sex
            ethnicity
            postalCode
            termsAcceptedAt
        }
    }
`

export interface UserProfileData {
    userProfile: UserProfile
}
