"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.questionPredicates = exports.tests = exports.aspects = exports.questions = void 0;
const censeo_1 = require("../../schema/generated/censeo");
exports.questions = [
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "c2b33c07-814c-5e05-bc1c-3b4841aca99d",
                "text": "Continue",
                "value": 1
            }
        ],
        "id": "ED-S",
        "text": "You are making good progress through this question session. Remember that you can take a break at any time. Your progress will be saved automatically.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "8d3640e0-1b8b-5786-a5b2-b2b812a6cc94",
                "text": "No, no urge at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "ca9a2825-624b-5e76-9afa-6a30812757ca",
                "text": "A slight urge",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "ed4e0160-626b-5018-a3fd-8582190b6617",
                "text": "A moderately intense urge",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "2cfff998-c76f-59bc-9e49-99e990424f1f",
                "text": "A very intense urge",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "082d778a-6204-5e7e-b181-5b3871ae40e9",
                "text": "An extremely intense urge",
                "value": 1
            }
        ],
        "id": "ED-s1",
        "text": "Have you ever had an intense urge to lose weight or change your body shape?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "b725a185-3fc1-557a-b293-3c380c37ff85",
                "text": "Not at all afraid",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "8e98bde5-9c64-51ed-b304-7a89ef6916de",
                "text": "Slightly afraid",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "34a39e0c-d3a2-509d-9601-54365808b7bc",
                "text": "Moderately afraid",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "35cdb4b5-54d0-544f-a02b-6b64dd448f5d",
                "text": "Very afraid",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "cef9c521-e355-5d92-8ecf-edb119617146",
                "text": "Extremely afraid",
                "value": 1
            }
        ],
        "id": "ED-s2",
        "text": "Have you ever been very afraid of gaining weight?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "341ad561-8953-5e62-9fa9-7235ab4fdc2c",
                "text": "It has ended",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "de401494-df90-5afc-9a03-9a7ceeb611f4",
                "text": "It is ongoing",
                "value": 1
            }
        ],
        "id": "ED-s3",
        "text": "Has this period of experiencing an intense urge to lose weight or fear of gaining weight ended?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "dbde12cc-92ab-5a7f-b269-c881f52b95d9",
                "text": "No, not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "38800324-962a-5e2a-86aa-2fe7de19f0e4",
                "text": "I think about it a bit, but I can focus enough to finish tasks",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "6af23488-c989-5fcf-b078-156a2915e8c2",
                "text": "I think about it all the time, but I can still finish tasks",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "e774449c-42ee-5867-98c9-af9f6bddeb94",
                "text": "The thoughts are ever-present and stop me from finishing tasks",
                "value": 1
            }
        ],
        "id": "ED-2a",
        "text": "Do you feel like you can't stop thinking about food, your body or your weight to such an extent that it interferes with your ability to focus?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "b381c1ba-cd91-5efb-bb01-d9d89c4737ba",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "d9b2f31b-97c9-5c52-bdc7-8d3b15df8e74",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "2a5ccb7f-3437-5616-ac25-335ed38e0cc8",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "3ecf7c28-1b3e-5019-826f-1df5f71b4e9e",
                "text": "Very much so",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "025b308d-42b4-52d0-a2bf-96091e198c9d",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "ED-3a",
        "text": "Do you feel like you are living a secret life, hidden from those around you?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "22bed9f5-f147-5ec7-8e35-f719f7ab6645",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "1cadf617-7206-59f8-98c7-e8c44436c59b",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "4b7d4e34-90ba-5dff-b480-16d4696cdeaf",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "84c09440-1bf4-5e1e-b375-a0bba5b81a1a",
                "text": "Very much so",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "d7e0c44a-0944-5a59-8a52-919e25a77986",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "ED-4a",
        "text": "Is the way you feel about yourself tied up with your weight or body shape?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "4ce3aa21-508a-5942-837b-f8f5cbe589cd",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "8a98a11e-b806-565c-a169-6273b884c919",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "14763d3b-b33f-5c4d-92ce-3ff225543c1d",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "ca7cf220-d054-5096-9b00-69718510d371",
                "text": "Very much so",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "12023b69-a8b2-5bb8-a2c1-39e8c3094426",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "ED-5a",
        "text": "Do you 'feel fat'?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "988dd04e-3244-52c6-9e84-c74e76d9f441",
                "text": "It does not scare me at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "fab56c49-aef8-511b-9537-336d33cde978",
                "text": "It scares me slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "4de3094a-5f24-5877-8aaf-ed8d96de3bea",
                "text": "It scares me moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "bc6be0c6-b35d-59fb-ba45-63cd0e72ffae",
                "text": "It scares me very much",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "ab8eb626-abd3-5d94-bc4f-538c9ff3660d",
                "text": "It is extremely scary to me",
                "value": 1
            }
        ],
        "id": "ED-5.5a",
        "text": "Does the idea of letting go of control scare you?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "394497b0-743d-5d7b-a332-c070d97a1670",
                "text": "No, never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "11fdb74b-d58d-54c9-8994-f1524b3397b1",
                "text": "Sometimes",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "724252d8-2dc5-57b3-bf51-30ed7eb456ab",
                "text": "Quite often",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "7dea9557-7010-5f45-abdd-5e0bb7bd35ff",
                "text": "Very often",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "9e60ef19-8ece-50e5-90c8-d511f71d019c",
                "text": "All the time",
                "value": 1
            }
        ],
        "id": "ED-6a",
        "text": "Do you check your body for signs of having either gained or lost weight, or to check whether it fits certain perceived standards?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "3e7d3bb8-7b96-56b8-a593-4d06971468f6",
                "text": "No, never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "9082a482-a039-5b70-b31e-a8342bbdb95a",
                "text": "Sometimes",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "2acebc45-5b8e-5782-ba7e-1ec31009bb9c",
                "text": "Quite often",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "199f3ebc-f02f-538a-b494-92f79c209192",
                "text": "Very often",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "b01109a6-f4c4-57b6-8562-09b122e04e82",
                "text": "All the time",
                "value": 1
            }
        ],
        "id": "ED-7a",
        "text": "Do you intentionally go for long periods without eating or only eating low-calorie or 'safe foods'?",
        "isMultiselect": false
    },
    {
        "__typename": "FreeQuestion",
        "id": "ED-8a",
        "text": "For how long have you tried to lose weight and restricted your food intake? Don't worry if you can't remember exactly, please give your best estimate.",
        "unit": censeo_1.Unit.DurationHours
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "615567a0-d252-51a9-a75a-7eba6ef9a6f8",
                "text": "No, never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "6eb3f978-4b7a-506c-ad67-ed64847345ff",
                "text": "Sometimes",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "1703d256-d3a0-56e9-89ef-5283fd73dece",
                "text": "Quite often",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "bd88202c-6e12-5515-84de-53703a947c11",
                "text": "Very often",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "3ded8329-cb3b-5188-b967-df9c684ce4bd",
                "text": "All the time",
                "value": 1
            }
        ],
        "id": "ED-10a",
        "text": "Do you avoid situations in which you are expected to eat?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "36718265-bc32-5a1a-8c07-401b4d9b5b44",
                "text": "No strict rules at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "5f88dfdb-d799-5181-9eb6-21249f4bb030",
                "text": "Not very strict rules",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "441a48b1-d590-5fd9-9346-e2ae3f5f5a3b",
                "text": "Moderately strict rules",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "a1be73ba-ba3e-57d7-9b19-d95309ad84e1",
                "text": "Very strict rules",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "d56ffb70-eeb6-55d8-be61-7ca6ab62a8dd",
                "text": "Extremely strict rules",
                "value": 1
            }
        ],
        "id": "ED-11a",
        "text": "Do you have strict rules that you follow around your food intake?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "13241496-1d78-5434-ad3c-e1338ed8fc77",
                "text": "No, never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "f82230ac-fbf9-5560-b520-3380c7ee20e5",
                "text": "Sometimes",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "dac50dc7-bb94-570f-94c6-6c47efb2b42b",
                "text": "Quite often",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "1db179fc-8fea-5b7b-a60c-58145451a67b",
                "text": "Very often",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "39c4ccfd-3adf-51bf-b3f6-2dbb497ad25a",
                "text": "All the time",
                "value": 1
            }
        ],
        "id": "ED-12a",
        "text": "When you sit down to eat, do you often take much longer than everyone else, cut your food up into small pieces and shuffle them around on your plate?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "ad6928ac-0d45-5e60-98c8-d08a1da794d3",
                "text": "No, never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "50de0897-bdef-5c42-9c7f-c93e13836162",
                "text": "Sometimes",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "982db003-ffe0-5842-9d24-2076f475a898",
                "text": "Quite often",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "5886e877-7bf5-5ab7-b6e2-ca88ac1ae6bb",
                "text": "Very often",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "0380b3d7-8743-57a1-a7a6-2fd7e64a97e4",
                "text": "All the time",
                "value": 1
            }
        ],
        "id": "ED-13a",
        "text": "Do other people regularly comment on you not eating enough or being too thin?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "5f81cf88-5168-57dd-ada6-fa89076a1fc2",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "fe268cc4-6fea-518a-a640-e22f055db3e3",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "ED-14a",
        "text": "Do you ever eat too much food or consume too many calories in a short time?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "7d7b1266-8035-5c61-b3d1-bbd04889c8b4",
                "text": "No, they would not think it was a large amount of food or calories",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "41312671-a780-5bad-9cb9-6fd11fa838d6",
                "text": "Yes, they would think it was large amount of food or calories",
                "value": 1
            }
        ],
        "id": "ED-15a",
        "text": "When you feel like you have eaten too much food or consumed too many calories, would other people also think the amount you have eaten is too much?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "de3ba57e-d93b-5694-80e2-f7d8a42aca21",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "95dec000-0404-5f0d-8b5e-d1f7b4a70621",
                "text": "Slightly out of control",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "76675190-5efd-5468-8cd3-5d4925569780",
                "text": "Moderately out of control",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "b6459d53-8f72-57eb-bcd8-520a8cedbc85",
                "text": "Very out of control",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "7f30a42d-6dc1-59d3-8d2b-4d87304475ae",
                "text": "Extremely out of control",
                "value": 1
            }
        ],
        "id": "ED-16a",
        "text": "You said you sometimes think you eat too much food in a short time. While you are eating, do you feel like you are losing control or are out of control?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "345f9367-23fc-5845-8ed4-e16a323611fc",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "9ae31134-40c5-51bb-974e-e292ca168d06",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "dd3d351b-a88a-54ef-bfb4-48871a6580ef",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "78ab7b7a-0ad7-5562-b25f-ef1d17f294e2",
                "text": "Very much so",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "97b51c03-ffc7-5710-8f01-61d059c5396b",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "ED-17a",
        "text": "Do you feel very upset, disgusted, guilty or ashamed after overeating?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "ad333e5e-0012-54a2-8184-d9106762ae35",
                "text": "Vomiting"
            },
            {
                "__typename": "Option",
                "id": "1233e358-735a-582b-b717-a750ba15d767",
                "text": "Using laxatives, diuretics or enemas"
            },
            {
                "__typename": "Option",
                "id": "9dc69def-599e-5f37-ac58-233a9086d649",
                "text": "Hard exercise"
            },
            {
                "__typename": "Option",
                "id": "034010b1-6107-5d00-9d5d-953fe62faace",
                "text": "Fasting for more than 8 hours at a time"
            },
            {
                "__typename": "Option",
                "id": "1701823b-3a2d-5a0b-94c2-679d8877c852",
                "text": "Taking diet pills"
            },
            {
                "__typename": "Option",
                "id": "fbaa6f2d-b7be-5320-aa8d-771ed1a2a0ca",
                "text": "Other method not mentioned above"
            },
            {
                "__typename": "Option",
                "id": "934f507a-f459-53f6-93fb-9350b236809d",
                "text": "None of the above",
                "exclusive": true
            }
        ],
        "id": "ED-18a",
        "text": "After eating, do you often try to compensate (or 'purge') by doing any of the following? Please select all that apply.",
        "isMultiselect": true
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "84588975-cc51-51c1-a08f-ffb5a3328460",
                "text": "It has ended",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "503cf098-85d2-597c-bc0a-cd4770da2bbd",
                "text": "It is ongoing",
                "value": 1
            }
        ],
        "id": "ED-20a",
        "text": "Has this period of overeating and compensation now ended or is it ongoing?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "e48b0849-948c-5e88-87ae-00bc05d6854c",
                "text": "Restricting your food intake"
            },
            {
                "__typename": "Option",
                "id": "6b75322e-af70-54cf-8186-59372418bfee",
                "text": "Eating too much in a short period of time"
            },
            {
                "__typename": "Option",
                "id": "43e5132b-0bfa-56c8-b4c7-2c517948b556",
                "text": "Engaging in compensatory behaviours after eating such as over exercising or making yourself vomit"
            },
            {
                "__typename": "Option",
                "id": "c694eee1-9afc-594d-acdf-144774817334",
                "text": "None of the above",
                "exclusive": true
            }
        ],
        "id": "ED-typeb",
        "text": "Which of the following best describes your eating habits in the past? Please select all that apply.",
        "isMultiselect": true
    },
    {
        "__typename": "FreeQuestion",
        "id": "ED-8b",
        "text": "How long ago did you last experience problems related to your eating? If you can't remember, please give your best estimate.",
        "unit": censeo_1.Unit.DurationHours
    },
    {
        "__typename": "FreeQuestion",
        "id": "ED-21a",
        "text": "On average, how many times per week does this cycle of overeating and compensation happen?",
        "unit": censeo_1.Unit.None
    },
    {
        "__typename": "FreeQuestion",
        "id": "ED-22a",
        "text": "For how long have you experienced this cycle of overeating and compensation? Don't worry if you can't remember exactly, please give your best estimate.",
        "unit": censeo_1.Unit.DurationHours
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "582a0fd2-b5e6-56f2-8c91-ec681d2bb9bc",
                "text": "Basic needs such as washing, taking care of your home and sleeping"
            },
            {
                "__typename": "Option",
                "id": "32549cb2-4927-5c8a-9499-b80b81febc69",
                "text": "Working or studying"
            },
            {
                "__typename": "Option",
                "id": "0ba65a89-5dad-5277-b9bc-ff5bd4d9ce53",
                "text": "Intimate relationships"
            },
            {
                "__typename": "Option",
                "id": "0f1503c1-1765-55f6-88c2-689b2187fa00",
                "text": "Social life"
            },
            {
                "__typename": "Option",
                "id": "8a196a52-708f-5f15-acc1-154fb5d4b53f",
                "text": "Family"
            },
            {
                "__typename": "Option",
                "id": "bb6ab166-5817-541a-ae48-7736dcee8a5f",
                "text": "Leisure activities, hobbies and interests"
            },
            {
                "__typename": "Option",
                "id": "26ea0e59-ae93-59df-abd0-6ffc5affabc5",
                "text": "Keeping fit and healthy"
            },
            {
                "__typename": "Option",
                "id": "46326163-0f95-540e-902a-f1c74a9c0c4a",
                "text": "Finances"
            },
            {
                "__typename": "Option",
                "id": "ee4ca845-9e51-55bb-9f5a-16f681e33792",
                "text": "Other aspects of my life not mentioned above"
            },
            {
                "__typename": "Option",
                "id": "eb492537-3740-4346-8f68-e26ddd65e2d6",
                "text": "No areas of my life are affected by my concerns with food",
                "exclusive": true
            }
        ],
        "id": "ED-23a",
        "text": "Which areas of your life, if any, do you feel are disrupted by your preoccupation with food? Please select all that apply.",
        "isMultiselect": true
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "a0f6bbd8-60c8-522a-ba89-7ca87093471b",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "ba15fd4b-26e1-5281-840a-48d6411bf040",
                "text": "To a slight extent",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "24ae6b5d-cdfd-5c2e-8f75-8ef5d76b902a",
                "text": "To a moderate extent",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "66187ecb-00cf-56d4-868c-85561c4324c1",
                "text": "To a significant extent",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "6331a675-84ad-5fa6-8df9-f73a483ee783",
                "text": "To an extreme extent",
                "value": 1
            }
        ],
        "id": "ED-23.0a",
        "text": "To what extent do your concerns with food affect these areas of your life, or require you to make additional effort to maintain functioning in those areas?",
        "isMultiselect": false
    },
    {
        "__typename": "FreeQuestion",
        "id": "ED-9b",
        "text": "For how long did your eating problems last? Don't worry if you can't remember exactly, please give your best estimate.",
        "unit": censeo_1.Unit.DurationHours
    }
];
exports.aspects = {
    "has_dse": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "has_an"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "has_bn"
                }
            }
        ],
        "anyToAll": 0.5
    },
    "dse_ce": {
        "type": "max",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bn_current"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "an_current"
                }
            }
        ]
    },
    "an_current": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "has_an"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "an_c"
                }
            }
        ],
        "anyToAll": 1
    },
    "bn_current": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "has_bn"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bn_c"
                }
            }
        ],
        "anyToAll": 1
    },
    "has_an": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "an_screen"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "an_A_restriction_weight"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "an_B_fear_behaviour"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "an_C_disturbance_self_image"
                }
            }
        ],
        "anyToAll": 1
    },
    "an_A_restriction_weight": {
        "type": "input",
        "signal": {
            "type": "AspectReference",
            "aspectName": "an_restricting"
        }
    },
    "an_B_fear_behaviour": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "an_fear"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "an_weightloss_behaviour"
                }
            }
        ],
        "anyToAll": 0.5
    },
    "an_C_disturbance_self_image": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "an_self_image"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "an_feel_fat"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "an_body_checking"
                }
            }
        ],
        "anyToAll": 0.3333333333333333
    },
    "an_weight": {
        "type": "merge",
        "wires": [
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.5,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "Dem3.1"
                    }
                }
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 17,
                    "width": 1
                },
                "lowPass": true,
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "bmi"
                    }
                }
            }
        ],
        "anyToAll": 0.5
    },
    "bmi": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "weight"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "inverse_height_squared"
                }
            }
        ],
        "anyToAll": 1
    },
    "inverse_height_squared": {
        "type": "input",
        "signal": {
            "type": "AspectReference",
            "aspectName": "height"
        }
    },
    "an_restricting": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "ED-7a",
            "options": {
                "type": "severities",
                "severities": {
                    "3e7d3bb8-7b96-56b8-a593-4d06971468f6": 0.0005527786369235996,
                    "9082a482-a039-5b70-b31e-a8342bbdb95a": 0.0066928509242848554,
                    "2acebc45-5b8e-5782-ba7e-1ec31009bb9c": 0.07585818002124355,
                    "199f3ebc-f02f-538a-b494-92f79c209192": 0.5,
                    "b01109a6-f4c4-57b6-8562-09b122e04e82": 0.9241418199787566
                }
            }
        }
    },
    "an_fear": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "ED-s2",
            "options": {
                "type": "severities",
                "severities": {
                    "b725a185-3fc1-557a-b293-3c380c37ff85": 0.0005527786369235996,
                    "8e98bde5-9c64-51ed-b304-7a89ef6916de": 0.0066928509242848554,
                    "34a39e0c-d3a2-509d-9601-54365808b7bc": 0.07585818002124355,
                    "35cdb4b5-54d0-544f-a02b-6b64dd448f5d": 0.5,
                    "cef9c521-e355-5d92-8ecf-edb119617146": 0.9241418199787566
                }
            }
        }
    },
    "an_weightloss_behaviour": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "ED18a_throwup"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "ED18a_laxative"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "ED18a_exercise"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "ED19a_fast"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "ED19a_pills"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "ED19a_other"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "ED-10a",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "615567a0-d252-51a9-a75a-7eba6ef9a6f8": 0.0066928509242848554,
                            "6eb3f978-4b7a-506c-ad67-ed64847345ff": 0.07585818002124355,
                            "1703d256-d3a0-56e9-89ef-5283fd73dece": 0.5,
                            "bd88202c-6e12-5515-84de-53703a947c11": 0.9241418199787566,
                            "3ded8329-cb3b-5188-b967-df9c684ce4bd": 0.9933071490757153
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "ED-11a",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "36718265-bc32-5a1a-8c07-401b4d9b5b44": 0.0005527786369235996,
                            "5f88dfdb-d799-5181-9eb6-21249f4bb030": 0.0066928509242848554,
                            "441a48b1-d590-5fd9-9346-e2ae3f5f5a3b": 0.07585818002124355,
                            "a1be73ba-ba3e-57d7-9b19-d95309ad84e1": 0.5,
                            "d56ffb70-eeb6-55d8-be61-7ca6ab62a8dd": 0.9241418199787566
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "ED-12a",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "13241496-1d78-5434-ad3c-e1338ed8fc77": 0.0066928509242848554,
                            "f82230ac-fbf9-5560-b520-3380c7ee20e5": 0.07585818002124355,
                            "dac50dc7-bb94-570f-94c6-6c47efb2b42b": 0.5,
                            "1db179fc-8fea-5b7b-a60c-58145451a67b": 0.9241418199787566,
                            "39c4ccfd-3adf-51bf-b3f6-2dbb497ad25a": 0.9933071490757153
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.2222222222222222
    },
    "an_self_image": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "ED-4a",
            "options": {
                "type": "severities",
                "severities": {
                    "22bed9f5-f147-5ec7-8e35-f719f7ab6645": 0.0005527786369235996,
                    "1cadf617-7206-59f8-98c7-e8c44436c59b": 0.0066928509242848554,
                    "4b7d4e34-90ba-5dff-b480-16d4696cdeaf": 0.07585818002124355,
                    "84c09440-1bf4-5e1e-b375-a0bba5b81a1a": 0.5,
                    "d7e0c44a-0944-5a59-8a52-919e25a77986": 0.9241418199787566
                }
            }
        }
    },
    "an_feel_fat": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "ED-5a",
            "options": {
                "type": "severities",
                "severities": {
                    "4ce3aa21-508a-5942-837b-f8f5cbe589cd": 0.0005527786369235996,
                    "8a98a11e-b806-565c-a169-6273b884c919": 0.0066928509242848554,
                    "14763d3b-b33f-5c4d-92ce-3ff225543c1d": 0.07585818002124355,
                    "ca7cf220-d054-5096-9b00-69718510d371": 0.5,
                    "12023b69-a8b2-5bb8-a2c1-39e8c3094426": 0.9241418199787566
                }
            }
        }
    },
    "an_body_checking": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "ED-6a",
            "options": {
                "type": "severities",
                "severities": {
                    "394497b0-743d-5d7b-a332-c070d97a1670": 0.0005527786369235996,
                    "11fdb74b-d58d-54c9-8994-f1524b3397b1": 0.0066928509242848554,
                    "724252d8-2dc5-57b3-bf51-30ed7eb456ab": 0.07585818002124355,
                    "7dea9557-7010-5f45-abdd-5e0bb7bd35ff": 0.5,
                    "9e60ef19-8ece-50e5-90c8-d511f71d019c": 0.9241418199787566
                }
            }
        }
    },
    "an_screen": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "ED-s3",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "341ad561-8953-5e62-9fa9-7235ab4fdc2c": false,
                            "de401494-df90-5afc-9a03-9a7ceeb611f4": true
                        }
                    }
                }
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "ED-s1",
                            "options": {
                                "type": "severities",
                                "severities": {
                                    "8d3640e0-1b8b-5786-a5b2-b2b812a6cc94": 0.0005527786369235996,
                                    "ca9a2825-624b-5e76-9afa-6a30812757ca": 0.0066928509242848554,
                                    "ed4e0160-626b-5018-a3fd-8582190b6617": 0.07585818002124355,
                                    "2cfff998-c76f-59bc-9e49-99e990424f1f": 0.5,
                                    "082d778a-6204-5e7e-b181-5b3871ae40e9": 0.9241418199787566
                                }
                            }
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "ED-s2",
                            "options": {
                                "type": "severities",
                                "severities": {
                                    "b725a185-3fc1-557a-b293-3c380c37ff85": 0.0005527786369235996,
                                    "8e98bde5-9c64-51ed-b304-7a89ef6916de": 0.0066928509242848554,
                                    "34a39e0c-d3a2-509d-9601-54365808b7bc": 0.07585818002124355,
                                    "35cdb4b5-54d0-544f-a02b-6b64dd448f5d": 0.5,
                                    "cef9c521-e355-5d92-8ecf-edb119617146": 0.9241418199787566
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 0.5
            }
        ],
        "anyToAll": 1
    },
    "has_bn": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bn_screen"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bn_A_recurrent_binge"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bn_B_recurrent_purge"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bn_C_frequency_duration"
                }
            }
        ],
        "anyToAll": 1
    },
    "bn_A_recurrent_binge": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bn_binge"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bn_control_loss"
                }
            }
        ],
        "anyToAll": 1
    },
    "bn_B_recurrent_purge": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "ED18a_throwup"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "ED18a_laxative"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "ED18a_exercise"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "ED19a_fast"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "ED19a_pills"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "ED19a_other"
                }
            }
        ],
        "anyToAll": 0.16666666666666666
    },
    "bn_C_frequency_duration": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "SpecificNumberInput",
                    "questionId": "ED-21a",
                    "input": 1,
                    "operator": ">="
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "SpecificNumberInput",
                    "questionId": "ED-22a",
                    "input": 93,
                    "operator": ">="
                }
            }
        ],
        "anyToAll": 1
    },
    "bn_binge": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "ED-14a",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "5f81cf88-5168-57dd-ada6-fa89076a1fc2": false,
                            "fe268cc4-6fea-518a-a640-e22f055db3e3": true
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "ED-15a",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "7d7b1266-8035-5c61-b3d1-bbd04889c8b4": false,
                            "41312671-a780-5bad-9cb9-6fd11fa838d6": true
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.5
    },
    "bn_control_loss": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "ED-16a",
            "options": {
                "type": "severities",
                "severities": {
                    "de3ba57e-d93b-5694-80e2-f7d8a42aca21": 0.0066928509242848554,
                    "95dec000-0404-5f0d-8b5e-d1f7b4a70621": 0.07585818002124355,
                    "76675190-5efd-5468-8cd3-5d4925569780": 0.5,
                    "b6459d53-8f72-57eb-bcd8-520a8cedbc85": 0.9241418199787566,
                    "7f30a42d-6dc1-59d3-8d2b-4d87304475ae": 0.9933071490757153
                }
            }
        }
    },
    "bn_screen": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "ED-14a",
            "options": {
                "type": "predicates",
                "predicates": {
                    "5f81cf88-5168-57dd-ada6-fa89076a1fc2": false,
                    "fe268cc4-6fea-518a-a640-e22f055db3e3": true
                }
            }
        }
    },
    "bn_c": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "ED-20a",
            "options": {
                "type": "predicates",
                "predicates": {
                    "84588975-cc51-51c1-a08f-ffb5a3328460": false,
                    "503cf098-85d2-597c-bc0a-cd4770da2bbd": true
                }
            }
        }
    },
    "an_c": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "ED-s3",
            "options": {
                "type": "predicates",
                "predicates": {
                    "341ad561-8953-5e62-9fa9-7235ab4fdc2c": false,
                    "de401494-df90-5afc-9a03-9a7ceeb611f4": true
                }
            }
        }
    },
    "ED18a_throwup": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "ED-18a",
            "options": {
                "type": "severities",
                "severities": {
                    "ad333e5e-0012-54a2-8184-d9106762ae35": 1
                }
            }
        }
    },
    "ED18a_laxative": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "ED-18a",
            "options": {
                "type": "severities",
                "severities": {
                    "1233e358-735a-582b-b717-a750ba15d767": 1
                }
            }
        }
    },
    "ED18a_exercise": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "ED-18a",
            "options": {
                "type": "severities",
                "severities": {
                    "9dc69def-599e-5f37-ac58-233a9086d649": 1
                }
            }
        }
    },
    "ED19a_fast": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "ED-18a",
            "options": {
                "type": "severities",
                "severities": {
                    "034010b1-6107-5d00-9d5d-953fe62faace": 1
                }
            }
        }
    },
    "ED19a_pills": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "ED-18a",
            "options": {
                "type": "severities",
                "severities": {
                    "1701823b-3a2d-5a0b-94c2-679d8877c852": 1
                }
            }
        }
    },
    "ED19a_other": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "ED-18a",
            "options": {
                "type": "severities",
                "severities": {
                    "fbaa6f2d-b7be-5320-aa8d-771ed1a2a0ca": 1
                }
            }
        }
    },
    "ED19a_": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "ED-18a",
            "options": {
                "type": "severities",
                "severities": {
                    "934f507a-f459-53f6-93fb-9350b236809d": 1
                }
            }
        }
    },
    "EDtypeb_AN": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "ED-typeb",
            "options": {
                "type": "severities",
                "severities": {
                    "e48b0849-948c-5e88-87ae-00bc05d6854c": 1
                }
            }
        }
    },
    "EDtypeb_binge": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "ED-typeb",
            "options": {
                "type": "severities",
                "severities": {
                    "6b75322e-af70-54cf-8186-59372418bfee": 1
                }
            }
        }
    },
    "EDtypeb_compensate": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "ED-typeb",
            "options": {
                "type": "severities",
                "severities": {
                    "43e5132b-0bfa-56c8-b4c7-2c517948b556": 1
                }
            }
        }
    },
    "EDtypeb_none": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "ED-typeb",
            "options": {
                "type": "severities",
                "severities": {
                    "c694eee1-9afc-594d-acdf-144774817334": 1
                }
            }
        }
    },
    "ED23a_basic": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "ED-23a",
            "options": {
                "type": "severities",
                "severities": {
                    "582a0fd2-b5e6-56f2-8c91-ec681d2bb9bc": 1
                }
            }
        }
    },
    "ED23a_work": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "ED-23a",
            "options": {
                "type": "severities",
                "severities": {
                    "32549cb2-4927-5c8a-9499-b80b81febc69": 1
                }
            }
        }
    },
    "ED23a_relationships": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "ED-23a",
            "options": {
                "type": "severities",
                "severities": {
                    "0ba65a89-5dad-5277-b9bc-ff5bd4d9ce53": 1
                }
            }
        }
    },
    "ED23a_social": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "ED-23a",
            "options": {
                "type": "severities",
                "severities": {
                    "0f1503c1-1765-55f6-88c2-689b2187fa00": 1
                }
            }
        }
    },
    "ED23a_family": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "ED-23a",
            "options": {
                "type": "severities",
                "severities": {
                    "8a196a52-708f-5f15-acc1-154fb5d4b53f": 1
                }
            }
        }
    },
    "ED24a_leisure": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "ED-23a",
            "options": {
                "type": "severities",
                "severities": {
                    "bb6ab166-5817-541a-ae48-7736dcee8a5f": 1
                }
            }
        }
    },
    "ED24a_health": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "ED-23a",
            "options": {
                "type": "severities",
                "severities": {
                    "26ea0e59-ae93-59df-abd0-6ffc5affabc5": 1
                }
            }
        }
    },
    "ED24a_financial": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "ED-23a",
            "options": {
                "type": "severities",
                "severities": {
                    "46326163-0f95-540e-902a-f1c74a9c0c4a": 1
                }
            }
        }
    },
    "ED24a_other": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "ED-23a",
            "options": {
                "type": "severities",
                "severities": {
                    "ee4ca845-9e51-55bb-9f5a-16f681e33792": 1
                }
            }
        }
    },
    "ED23a_none": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "ED-23a",
            "options": {
                "type": "severities",
                "severities": {
                    "eb492537-3740-4346-8f68-e26ddd65e2d6": 1
                }
            }
        }
    },
    "and[or[isUndefined(and[ED-s1<=0.5, ED-s2<=0.5]), not(and[ED-s1<=0.5, ED-s2<=0.5])], not(isUndefined(ED-s2=0))]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "ED-s1",
                                        "options": {
                                            "type": "severities",
                                            "severities": {
                                                "8d3640e0-1b8b-5786-a5b2-b2b812a6cc94": 0.9933071490757152,
                                                "ca9a2825-624b-5e76-9afa-6a30812757ca": 0.9241418199787564,
                                                "ed4e0160-626b-5018-a3fd-8582190b6617": 0.5,
                                                "2cfff998-c76f-59bc-9e49-99e990424f1f": 0.07585818002124345,
                                                "082d778a-6204-5e7e-b181-5b3871ae40e9": 0.006692850924284732
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "ED-s2",
                                        "options": {
                                            "type": "severities",
                                            "severities": {
                                                "b725a185-3fc1-557a-b293-3c380c37ff85": 0.9933071490757152,
                                                "8e98bde5-9c64-51ed-b304-7a89ef6916de": 0.9241418199787564,
                                                "34a39e0c-d3a2-509d-9601-54365808b7bc": 0.5,
                                                "35cdb4b5-54d0-544f-a02b-6b64dd448f5d": 0.07585818002124345,
                                                "cef9c521-e355-5d92-8ecf-edb119617146": 0.006692850924284732
                                            }
                                        }
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "ED-s1",
                                        "options": {
                                            "type": "severities",
                                            "severities": {
                                                "8d3640e0-1b8b-5786-a5b2-b2b812a6cc94": 0.9933071490757152,
                                                "ca9a2825-624b-5e76-9afa-6a30812757ca": 0.9241418199787564,
                                                "ed4e0160-626b-5018-a3fd-8582190b6617": 0.5,
                                                "2cfff998-c76f-59bc-9e49-99e990424f1f": 0.07585818002124345,
                                                "082d778a-6204-5e7e-b181-5b3871ae40e9": 0.006692850924284732
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "ED-s2",
                                        "options": {
                                            "type": "severities",
                                            "severities": {
                                                "b725a185-3fc1-557a-b293-3c380c37ff85": 0.9933071490757152,
                                                "8e98bde5-9c64-51ed-b304-7a89ef6916de": 0.9241418199787564,
                                                "34a39e0c-d3a2-509d-9601-54365808b7bc": 0.5,
                                                "35cdb4b5-54d0-544f-a02b-6b64dd448f5d": 0.07585818002124345,
                                                "cef9c521-e355-5d92-8ecf-edb119617146": 0.006692850924284732
                                            }
                                        }
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "not",
                "wire": {
                    "type": "isUnknown",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "ED-s2",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "b725a185-3fc1-557a-b293-3c380c37ff85": true,
                                    "8e98bde5-9c64-51ed-b304-7a89ef6916de": false,
                                    "34a39e0c-d3a2-509d-9601-54365808b7bc": false,
                                    "35cdb4b5-54d0-544f-a02b-6b64dd448f5d": false,
                                    "cef9c521-e355-5d92-8ecf-edb119617146": false
                                }
                            }
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "not(ED-s3=0)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "ED-s3=0"
            }
        }
    },
    "ED-7a>=0.25": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "ED-7a",
            "options": {
                "type": "severities",
                "severities": {
                    "3e7d3bb8-7b96-56b8-a593-4d06971468f6": 0.07585818002124355,
                    "9082a482-a039-5b70-b31e-a8342bbdb95a": 0.5,
                    "2acebc45-5b8e-5782-ba7e-1ec31009bb9c": 0.9241418199787566,
                    "199f3ebc-f02f-538a-b494-92f79c209192": 0.9933071490757153,
                    "b01109a6-f4c4-57b6-8562-09b122e04e82": 0.9994472213630764
                }
            }
        }
    },
    "ED-14a=1": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "ED-14a",
            "options": {
                "type": "predicates",
                "predicates": {
                    "5f81cf88-5168-57dd-ada6-fa89076a1fc2": false,
                    "fe268cc4-6fea-518a-a640-e22f055db3e3": true
                }
            }
        }
    },
    "or[and[ED-7a>=0.25, ED-14a=0], ED-14a=1]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "ED-7a>=0.25"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "ED-14a",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "5f81cf88-5168-57dd-ada6-fa89076a1fc2": true,
                                    "fe268cc4-6fea-518a-a640-e22f055db3e3": false
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 1
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "ED-14a=1"
                }
            }
        ],
        "anyToAll": 0.5
    },
    "and[or[isUndefined(ED19a_=1), not(ED19a_=1)], not(isUndefined(ED-18a=0))]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "ED19a_"
                            }
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "ED19a_"
                            }
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "not",
                "wire": {
                    "type": "isUnknown",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "ED-18a",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "ad333e5e-0012-54a2-8184-d9106762ae35": false,
                                    "1233e358-735a-582b-b717-a750ba15d767": false,
                                    "9dc69def-599e-5f37-ac58-233a9086d649": false,
                                    "034010b1-6107-5d00-9d5d-953fe62faace": false,
                                    "1701823b-3a2d-5a0b-94c2-679d8877c852": false,
                                    "fbaa6f2d-b7be-5320-aa8d-771ed1a2a0ca": false,
                                    "934f507a-f459-53f6-93fb-9350b236809d": false
                                }
                            }
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "ED-s3=0": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "ED-s3",
            "options": {
                "type": "predicates",
                "predicates": {
                    "341ad561-8953-5e62-9fa9-7235ab4fdc2c": true,
                    "de401494-df90-5afc-9a03-9a7ceeb611f4": false
                }
            }
        }
    },
    "or[ED-20a=0, ED-s3=0]": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "ED-20a",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "84588975-cc51-51c1-a08f-ffb5a3328460": true,
                            "503cf098-85d2-597c-bc0a-cd4770da2bbd": false
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "ED-s3=0"
                }
            }
        ],
        "anyToAll": 0.5
    },
    "not(ED-20a=0)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "ED-20a",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "84588975-cc51-51c1-a08f-ffb5a3328460": true,
                        "503cf098-85d2-597c-bc0a-cd4770da2bbd": false
                    }
                }
            }
        }
    },
    "or[and[ED19a_=1, not(isUndefined(ED-18a=0))], and[ED-20a=0, not(isUndefined(ED-8b=0))], not(ED-20a=0)]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "ED19a_"
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "isUnknown",
                            "wire": {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "ED-18a",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "ad333e5e-0012-54a2-8184-d9106762ae35": false,
                                            "1233e358-735a-582b-b717-a750ba15d767": false,
                                            "9dc69def-599e-5f37-ac58-233a9086d649": false,
                                            "034010b1-6107-5d00-9d5d-953fe62faace": false,
                                            "1701823b-3a2d-5a0b-94c2-679d8877c852": false,
                                            "fbaa6f2d-b7be-5320-aa8d-771ed1a2a0ca": false,
                                            "934f507a-f459-53f6-93fb-9350b236809d": false
                                        }
                                    }
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 1
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "ED-20a",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "84588975-cc51-51c1-a08f-ffb5a3328460": true,
                                    "503cf098-85d2-597c-bc0a-cd4770da2bbd": false
                                }
                            }
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "isUnknown",
                            "wire": {
                                "type": "input",
                                "signal": {
                                    "type": "SpecificNumberInput",
                                    "questionId": "ED-8b",
                                    "input": 0,
                                    "operator": "="
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 1
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "not(ED-20a=0)"
                }
            }
        ],
        "anyToAll": 0.3333333333333333
    },
    "and[or[isUndefined(ED23a_none=1), not(ED23a_none=1)], not(isUndefined(ED-23a=0))]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "ED23a_none"
                            }
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "ED23a_none"
                            }
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "not",
                "wire": {
                    "type": "isUnknown",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "ED-23a",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "582a0fd2-b5e6-56f2-8c91-ec681d2bb9bc": false,
                                    "32549cb2-4927-5c8a-9499-b80b81febc69": false,
                                    "0ba65a89-5dad-5277-b9bc-ff5bd4d9ce53": false,
                                    "0f1503c1-1765-55f6-88c2-689b2187fa00": false,
                                    "8a196a52-708f-5f15-acc1-154fb5d4b53f": false,
                                    "bb6ab166-5817-541a-ae48-7736dcee8a5f": false,
                                    "26ea0e59-ae93-59df-abd0-6ffc5affabc5": false,
                                    "46326163-0f95-540e-902a-f1c74a9c0c4a": false,
                                    "ee4ca845-9e51-55bb-9f5a-16f681e33792": false,
                                    "eb492537-3740-4346-8f68-e26ddd65e2d6": false
                                }
                            }
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "and[or[isUndefined(ED-20a=0), not(ED-20a=0)], not(isUndefined(ED-8b=0))]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AnsweredOption",
                                "questionId": "ED-20a",
                                "options": {
                                    "type": "predicates",
                                    "predicates": {
                                        "84588975-cc51-51c1-a08f-ffb5a3328460": true,
                                        "503cf098-85d2-597c-bc0a-cd4770da2bbd": false
                                    }
                                }
                            }
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "not(ED-20a=0)"
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "not",
                "wire": {
                    "type": "isUnknown",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "SpecificNumberInput",
                            "questionId": "ED-8b",
                            "input": 0,
                            "operator": "="
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    }
};
exports.tests = {
    "dse_ce": [
        {
            "name": "screened user: Z3RPNEO aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "8d3640e0-1b8b-5786-a5b2-b2b812a6cc94"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "b725a185-3fc1-557a-b293-3c380c37ff85"
                    ]
                }
            }
        },
        {
            "name": "screened user: NEL6L3V aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "ca9a2825-624b-5e76-9afa-6a30812757ca"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "34a39e0c-d3a2-509d-9601-54365808b7bc"
                    ]
                }
            }
        },
        {
            "name": "screened user: 6PE670L aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "8d3640e0-1b8b-5786-a5b2-b2b812a6cc94"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "b725a185-3fc1-557a-b293-3c380c37ff85"
                    ]
                }
            }
        },
        {
            "name": "screened user: 50MRME6 aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "8d3640e0-1b8b-5786-a5b2-b2b812a6cc94"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "b725a185-3fc1-557a-b293-3c380c37ff85"
                    ]
                }
            }
        },
        {
            "name": "screened user: O3PMG3W aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "ca9a2825-624b-5e76-9afa-6a30812757ca"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "b725a185-3fc1-557a-b293-3c380c37ff85"
                    ]
                }
            }
        },
        {
            "name": "screened user: Y32D838 aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "ed4e0160-626b-5018-a3fd-8582190b6617"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "34a39e0c-d3a2-509d-9601-54365808b7bc"
                    ]
                }
            }
        },
        {
            "name": "screened user: XE7OXEL aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "ca9a2825-624b-5e76-9afa-6a30812757ca"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "b725a185-3fc1-557a-b293-3c380c37ff85"
                    ]
                }
            }
        },
        {
            "name": "screened user: 63YX60R aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "ed4e0160-626b-5018-a3fd-8582190b6617"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "34a39e0c-d3a2-509d-9601-54365808b7bc"
                    ]
                }
            }
        },
        {
            "name": "screened user: 6EDLX3M aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "ca9a2825-624b-5e76-9afa-6a30812757ca"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "8e98bde5-9c64-51ed-b304-7a89ef6916de"
                    ]
                }
            }
        },
        {
            "name": "screened user: O0KG5E1 aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "8d3640e0-1b8b-5786-a5b2-b2b812a6cc94"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "b725a185-3fc1-557a-b293-3c380c37ff85"
                    ]
                }
            }
        },
        {
            "name": "screened user: 6ED9M0M aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "ed4e0160-626b-5018-a3fd-8582190b6617"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "8e98bde5-9c64-51ed-b304-7a89ef6916de"
                    ]
                }
            }
        },
        {
            "name": "screened user: NELRPEV aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "ca9a2825-624b-5e76-9afa-6a30812757ca"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "8e98bde5-9c64-51ed-b304-7a89ef6916de"
                    ]
                }
            }
        },
        {
            "name": "screened user: REO9232 aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "ca9a2825-624b-5e76-9afa-6a30812757ca"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "b725a185-3fc1-557a-b293-3c380c37ff85"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0N6P0Q aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "ed4e0160-626b-5018-a3fd-8582190b6617"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "b725a185-3fc1-557a-b293-3c380c37ff85"
                    ]
                }
            }
        },
        {
            "name": "screened user: ME8G2EW aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "ed4e0160-626b-5018-a3fd-8582190b6617"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "8e98bde5-9c64-51ed-b304-7a89ef6916de"
                    ]
                }
            }
        },
        {
            "name": "screened user: PE6LM0L aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "ca9a2825-624b-5e76-9afa-6a30812757ca"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "8e98bde5-9c64-51ed-b304-7a89ef6916de"
                    ]
                }
            }
        },
        {
            "name": "screened user: K39ML0L aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "8d3640e0-1b8b-5786-a5b2-b2b812a6cc94"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "8e98bde5-9c64-51ed-b304-7a89ef6916de"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z3RD4EO aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "ca9a2825-624b-5e76-9afa-6a30812757ca"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "34a39e0c-d3a2-509d-9601-54365808b7bc"
                    ]
                }
            }
        },
        {
            "name": "screened user: 4EGQQ0P aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "ca9a2825-624b-5e76-9afa-6a30812757ca"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "34a39e0c-d3a2-509d-9601-54365808b7bc"
                    ]
                }
            }
        },
        {
            "name": "screened user: 7EW2K38 aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "ca9a2825-624b-5e76-9afa-6a30812757ca"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "b725a185-3fc1-557a-b293-3c380c37ff85"
                    ]
                }
            }
        },
        {
            "name": "screened user: 63YR40R aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "ca9a2825-624b-5e76-9afa-6a30812757ca"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "8e98bde5-9c64-51ed-b304-7a89ef6916de"
                    ]
                }
            }
        },
        {
            "name": "screened user: Y329ME8 aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "ed4e0160-626b-5018-a3fd-8582190b6617"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "34a39e0c-d3a2-509d-9601-54365808b7bc"
                    ]
                }
            }
        },
        {
            "name": "screened user: R042O34 aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "8d3640e0-1b8b-5786-a5b2-b2b812a6cc94"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "b725a185-3fc1-557a-b293-3c380c37ff85"
                    ]
                }
            }
        },
        {
            "name": "screened user: ME8D2EW aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "ed4e0160-626b-5018-a3fd-8582190b6617"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "b725a185-3fc1-557a-b293-3c380c37ff85"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0NN50Q aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "8d3640e0-1b8b-5786-a5b2-b2b812a6cc94"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "8e98bde5-9c64-51ed-b304-7a89ef6916de"
                    ]
                }
            }
        },
        {
            "name": "screened user: 73Z220R aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "8d3640e0-1b8b-5786-a5b2-b2b812a6cc94"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "8e98bde5-9c64-51ed-b304-7a89ef6916de"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0N4J3Q aspect: dse_ce should be positive",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "ED-10a": {
                    "questionId": "ED-10a",
                    "chosenOptionIds": [
                        "3ded8329-cb3b-5188-b967-df9c684ce4bd"
                    ]
                },
                "ED-11a": {
                    "questionId": "ED-11a",
                    "chosenOptionIds": [
                        "d56ffb70-eeb6-55d8-be61-7ca6ab62a8dd"
                    ]
                },
                "ED-12a": {
                    "questionId": "ED-12a",
                    "chosenOptionIds": [
                        "39c4ccfd-3adf-51bf-b3f6-2dbb497ad25a"
                    ]
                },
                "ED-13a": {
                    "questionId": "ED-13a",
                    "chosenOptionIds": [
                        "0380b3d7-8743-57a1-a7a6-2fd7e64a97e4"
                    ]
                },
                "ED-14a": {
                    "questionId": "ED-14a",
                    "chosenOptionIds": [
                        "5f81cf88-5168-57dd-ada6-fa89076a1fc2"
                    ]
                },
                "ED-18a": {
                    "questionId": "ED-18a",
                    "chosenOptionIds": [
                        "9dc69def-599e-5f37-ac58-233a9086d649",
                        "034010b1-6107-5d00-9d5d-953fe62faace",
                        "1233e358-735a-582b-b717-a750ba15d767"
                    ]
                },
                "ED-20a": {
                    "questionId": "ED-20a",
                    "chosenOptionIds": [
                        "503cf098-85d2-597c-bc0a-cd4770da2bbd"
                    ]
                },
                "ED-21a": {
                    "questionId": "ED-21a",
                    "freeValue": 1
                },
                "ED-22a": {
                    "questionId": "ED-22a",
                    "freeValue": 0
                },
                "ED-23.0a": {
                    "questionId": "ED-23.0a",
                    "chosenOptionIds": [
                        "66187ecb-00cf-56d4-868c-85561c4324c1"
                    ]
                },
                "ED-23a": {
                    "questionId": "ED-23a",
                    "chosenOptionIds": [
                        "32549cb2-4927-5c8a-9499-b80b81febc69",
                        "0f1503c1-1765-55f6-88c2-689b2187fa00",
                        "8a196a52-708f-5f15-acc1-154fb5d4b53f",
                        "bb6ab166-5817-541a-ae48-7736dcee8a5f",
                        "26ea0e59-ae93-59df-abd0-6ffc5affabc5"
                    ]
                },
                "ED-2a": {
                    "questionId": "ED-2a",
                    "chosenOptionIds": [
                        "e774449c-42ee-5867-98c9-af9f6bddeb94"
                    ]
                },
                "ED-3a": {
                    "questionId": "ED-3a",
                    "chosenOptionIds": [
                        "025b308d-42b4-52d0-a2bf-96091e198c9d"
                    ]
                },
                "ED-4a": {
                    "questionId": "ED-4a",
                    "chosenOptionIds": [
                        "d7e0c44a-0944-5a59-8a52-919e25a77986"
                    ]
                },
                "ED-5.5a": {
                    "questionId": "ED-5.5a",
                    "chosenOptionIds": [
                        "ab8eb626-abd3-5d94-bc4f-538c9ff3660d"
                    ]
                },
                "ED-5a": {
                    "questionId": "ED-5a",
                    "chosenOptionIds": [
                        "12023b69-a8b2-5bb8-a2c1-39e8c3094426"
                    ]
                },
                "ED-6a": {
                    "questionId": "ED-6a",
                    "chosenOptionIds": [
                        "9e60ef19-8ece-50e5-90c8-d511f71d019c"
                    ]
                },
                "ED-7a": {
                    "questionId": "ED-7a",
                    "chosenOptionIds": [
                        "b01109a6-f4c4-57b6-8562-09b122e04e82"
                    ]
                },
                "ED-8a": {
                    "questionId": "ED-8a",
                    "freeValue": 3650
                },
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "082d778a-6204-5e7e-b181-5b3871ae40e9"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "cef9c521-e355-5d92-8ecf-edb119617146"
                    ]
                },
                "ED-s3": {
                    "questionId": "ED-s3",
                    "chosenOptionIds": [
                        "de401494-df90-5afc-9a03-9a7ceeb611f4"
                    ]
                }
            }
        },
        {
            "name": "screened user: 10QKZ0P aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-8b": {
                    "questionId": "ED-8b",
                    "freeValue": 3285
                },
                "ED-9b": {
                    "questionId": "ED-9b",
                    "freeValue": 730
                },
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "ed4e0160-626b-5018-a3fd-8582190b6617"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "35cdb4b5-54d0-544f-a02b-6b64dd448f5d"
                    ]
                },
                "ED-s3": {
                    "questionId": "ED-s3",
                    "chosenOptionIds": [
                        "341ad561-8953-5e62-9fa9-7235ab4fdc2c"
                    ]
                },
                "ED-typeb": {
                    "questionId": "ED-typeb",
                    "chosenOptionIds": [
                        "e48b0849-948c-5e88-87ae-00bc05d6854c"
                    ]
                }
            }
        },
        {
            "name": "screened user: XE7ZR3L aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "ca9a2825-624b-5e76-9afa-6a30812757ca"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "8e98bde5-9c64-51ed-b304-7a89ef6916de"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0N5PEQ aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "8d3640e0-1b8b-5786-a5b2-b2b812a6cc94"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "b725a185-3fc1-557a-b293-3c380c37ff85"
                    ]
                }
            }
        },
        {
            "name": "screened user: 23XKV0N aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "8d3640e0-1b8b-5786-a5b2-b2b812a6cc94"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "b725a185-3fc1-557a-b293-3c380c37ff85"
                    ]
                }
            }
        },
        {
            "name": "screened user: 63YD63R aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-14a": {
                    "questionId": "ED-14a",
                    "chosenOptionIds": [
                        "5f81cf88-5168-57dd-ada6-fa89076a1fc2"
                    ]
                },
                "ED-2a": {
                    "questionId": "ED-2a",
                    "chosenOptionIds": [
                        "e774449c-42ee-5867-98c9-af9f6bddeb94"
                    ]
                },
                "ED-3a": {
                    "questionId": "ED-3a",
                    "chosenOptionIds": [
                        "3ecf7c28-1b3e-5019-826f-1df5f71b4e9e"
                    ]
                },
                "ED-4a": {
                    "questionId": "ED-4a",
                    "chosenOptionIds": [
                        "22bed9f5-f147-5ec7-8e35-f719f7ab6645"
                    ]
                },
                "ED-5.5a": {
                    "questionId": "ED-5.5a",
                    "chosenOptionIds": [
                        "ab8eb626-abd3-5d94-bc4f-538c9ff3660d"
                    ]
                },
                "ED-5a": {
                    "questionId": "ED-5a",
                    "chosenOptionIds": [
                        "4ce3aa21-508a-5942-837b-f8f5cbe589cd"
                    ]
                },
                "ED-6a": {
                    "questionId": "ED-6a",
                    "chosenOptionIds": [
                        "394497b0-743d-5d7b-a332-c070d97a1670"
                    ]
                },
                "ED-7a": {
                    "questionId": "ED-7a",
                    "chosenOptionIds": [
                        "3e7d3bb8-7b96-56b8-a593-4d06971468f6"
                    ]
                },
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "2cfff998-c76f-59bc-9e49-99e990424f1f"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "b725a185-3fc1-557a-b293-3c380c37ff85"
                    ]
                },
                "ED-s3": {
                    "questionId": "ED-s3",
                    "chosenOptionIds": [
                        "de401494-df90-5afc-9a03-9a7ceeb611f4"
                    ]
                }
            }
        },
        {
            "name": "screened user: ME8PLEW aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "ed4e0160-626b-5018-a3fd-8582190b6617"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "8e98bde5-9c64-51ed-b304-7a89ef6916de"
                    ]
                }
            }
        },
        {
            "name": "screened user: K39V10L aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "ed4e0160-626b-5018-a3fd-8582190b6617"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "8e98bde5-9c64-51ed-b304-7a89ef6916de"
                    ]
                }
            }
        },
        {
            "name": "screened user: O0K1931 aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "ca9a2825-624b-5e76-9afa-6a30812757ca"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "34a39e0c-d3a2-509d-9601-54365808b7bc"
                    ]
                }
            }
        },
        {
            "name": "screened user: J3J1D0Y aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "ca9a2825-624b-5e76-9afa-6a30812757ca"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "34a39e0c-d3a2-509d-9601-54365808b7bc"
                    ]
                }
            }
        },
        {
            "name": "screened user: 10QJ8EP aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "8d3640e0-1b8b-5786-a5b2-b2b812a6cc94"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "b725a185-3fc1-557a-b293-3c380c37ff85"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0NO53Q aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "8d3640e0-1b8b-5786-a5b2-b2b812a6cc94"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "8e98bde5-9c64-51ed-b304-7a89ef6916de"
                    ]
                }
            }
        },
        {
            "name": "screened user: K392M0L aspect: dse_ce should be positive",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "ED-10a": {
                    "questionId": "ED-10a",
                    "chosenOptionIds": [
                        "bd88202c-6e12-5515-84de-53703a947c11"
                    ]
                },
                "ED-11a": {
                    "questionId": "ED-11a",
                    "chosenOptionIds": [
                        "d56ffb70-eeb6-55d8-be61-7ca6ab62a8dd"
                    ]
                },
                "ED-12a": {
                    "questionId": "ED-12a",
                    "chosenOptionIds": [
                        "13241496-1d78-5434-ad3c-e1338ed8fc77"
                    ]
                },
                "ED-13a": {
                    "questionId": "ED-13a",
                    "chosenOptionIds": [
                        "0380b3d7-8743-57a1-a7a6-2fd7e64a97e4"
                    ]
                },
                "ED-14a": {
                    "questionId": "ED-14a",
                    "chosenOptionIds": [
                        "fe268cc4-6fea-518a-a640-e22f055db3e3"
                    ]
                },
                "ED-15a": {
                    "questionId": "ED-15a",
                    "chosenOptionIds": [
                        "7d7b1266-8035-5c61-b3d1-bbd04889c8b4"
                    ]
                },
                "ED-16a": {
                    "questionId": "ED-16a",
                    "chosenOptionIds": [
                        "b6459d53-8f72-57eb-bcd8-520a8cedbc85"
                    ]
                },
                "ED-17a": {
                    "questionId": "ED-17a",
                    "chosenOptionIds": [
                        "78ab7b7a-0ad7-5562-b25f-ef1d17f294e2"
                    ]
                },
                "ED-18a": {
                    "questionId": "ED-18a",
                    "chosenOptionIds": [
                        "ad333e5e-0012-54a2-8184-d9106762ae35",
                        "1233e358-735a-582b-b717-a750ba15d767",
                        "9dc69def-599e-5f37-ac58-233a9086d649"
                    ]
                },
                "ED-20a": {
                    "questionId": "ED-20a",
                    "chosenOptionIds": [
                        "503cf098-85d2-597c-bc0a-cd4770da2bbd"
                    ]
                },
                "ED-21a": {
                    "questionId": "ED-21a",
                    "freeValue": 0
                },
                "ED-22a": {
                    "questionId": "ED-22a",
                    "freeValue": 0
                },
                "ED-23.0a": {
                    "questionId": "ED-23.0a",
                    "chosenOptionIds": [
                        "6331a675-84ad-5fa6-8df9-f73a483ee783"
                    ]
                },
                "ED-23a": {
                    "questionId": "ED-23a",
                    "chosenOptionIds": [
                        "32549cb2-4927-5c8a-9499-b80b81febc69",
                        "0ba65a89-5dad-5277-b9bc-ff5bd4d9ce53",
                        "0f1503c1-1765-55f6-88c2-689b2187fa00",
                        "8a196a52-708f-5f15-acc1-154fb5d4b53f",
                        "46326163-0f95-540e-902a-f1c74a9c0c4a"
                    ]
                },
                "ED-2a": {
                    "questionId": "ED-2a",
                    "chosenOptionIds": [
                        "38800324-962a-5e2a-86aa-2fe7de19f0e4"
                    ]
                },
                "ED-3a": {
                    "questionId": "ED-3a",
                    "chosenOptionIds": [
                        "d9b2f31b-97c9-5c52-bdc7-8d3b15df8e74"
                    ]
                },
                "ED-4a": {
                    "questionId": "ED-4a",
                    "chosenOptionIds": [
                        "84c09440-1bf4-5e1e-b375-a0bba5b81a1a"
                    ]
                },
                "ED-5.5a": {
                    "questionId": "ED-5.5a",
                    "chosenOptionIds": [
                        "bc6be0c6-b35d-59fb-ba45-63cd0e72ffae"
                    ]
                },
                "ED-5a": {
                    "questionId": "ED-5a",
                    "chosenOptionIds": [
                        "14763d3b-b33f-5c4d-92ce-3ff225543c1d"
                    ]
                },
                "ED-6a": {
                    "questionId": "ED-6a",
                    "chosenOptionIds": [
                        "7dea9557-7010-5f45-abdd-5e0bb7bd35ff"
                    ]
                },
                "ED-7a": {
                    "questionId": "ED-7a",
                    "chosenOptionIds": [
                        "b01109a6-f4c4-57b6-8562-09b122e04e82"
                    ]
                },
                "ED-8a": {
                    "questionId": "ED-8a",
                    "freeValue": 9125
                },
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "2cfff998-c76f-59bc-9e49-99e990424f1f"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "cef9c521-e355-5d92-8ecf-edb119617146"
                    ]
                },
                "ED-s3": {
                    "questionId": "ED-s3",
                    "chosenOptionIds": [
                        "de401494-df90-5afc-9a03-9a7ceeb611f4"
                    ]
                }
            }
        },
        {
            "name": "screened user: 63YPO3R aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "ed4e0160-626b-5018-a3fd-8582190b6617"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "8e98bde5-9c64-51ed-b304-7a89ef6916de"
                    ]
                }
            }
        },
        {
            "name": "screened user: 23X6V0N aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "8d3640e0-1b8b-5786-a5b2-b2b812a6cc94"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "b725a185-3fc1-557a-b293-3c380c37ff85"
                    ]
                }
            }
        },
        {
            "name": "screened user: 50MQK36 aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "ed4e0160-626b-5018-a3fd-8582190b6617"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "34a39e0c-d3a2-509d-9601-54365808b7bc"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0N2PEQ aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "ed4e0160-626b-5018-a3fd-8582190b6617"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "b725a185-3fc1-557a-b293-3c380c37ff85"
                    ]
                }
            }
        },
        {
            "name": "screened user: 4EGGGEP aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-14a": {
                    "questionId": "ED-14a",
                    "chosenOptionIds": [
                        "5f81cf88-5168-57dd-ada6-fa89076a1fc2"
                    ]
                },
                "ED-2a": {
                    "questionId": "ED-2a",
                    "chosenOptionIds": [
                        "dbde12cc-92ab-5a7f-b269-c881f52b95d9"
                    ]
                },
                "ED-3a": {
                    "questionId": "ED-3a",
                    "chosenOptionIds": [
                        "d9b2f31b-97c9-5c52-bdc7-8d3b15df8e74"
                    ]
                },
                "ED-4a": {
                    "questionId": "ED-4a",
                    "chosenOptionIds": [
                        "22bed9f5-f147-5ec7-8e35-f719f7ab6645"
                    ]
                },
                "ED-5.5a": {
                    "questionId": "ED-5.5a",
                    "chosenOptionIds": [
                        "fab56c49-aef8-511b-9537-336d33cde978"
                    ]
                },
                "ED-5a": {
                    "questionId": "ED-5a",
                    "chosenOptionIds": [
                        "8a98a11e-b806-565c-a169-6273b884c919"
                    ]
                },
                "ED-6a": {
                    "questionId": "ED-6a",
                    "chosenOptionIds": [
                        "394497b0-743d-5d7b-a332-c070d97a1670"
                    ]
                },
                "ED-7a": {
                    "questionId": "ED-7a",
                    "chosenOptionIds": [
                        "3e7d3bb8-7b96-56b8-a593-4d06971468f6"
                    ]
                },
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "ca9a2825-624b-5e76-9afa-6a30812757ca"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "35cdb4b5-54d0-544f-a02b-6b64dd448f5d"
                    ]
                },
                "ED-s3": {
                    "questionId": "ED-s3",
                    "chosenOptionIds": [
                        "de401494-df90-5afc-9a03-9a7ceeb611f4"
                    ]
                }
            }
        },
        {
            "name": "screened user: 2E1JL0D aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "ed4e0160-626b-5018-a3fd-8582190b6617"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "34a39e0c-d3a2-509d-9601-54365808b7bc"
                    ]
                }
            }
        },
        {
            "name": "screened user: R04JV34 aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "8d3640e0-1b8b-5786-a5b2-b2b812a6cc94"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "8e98bde5-9c64-51ed-b304-7a89ef6916de"
                    ]
                }
            }
        },
        {
            "name": "screened user: 23X4V3N aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "8d3640e0-1b8b-5786-a5b2-b2b812a6cc94"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "b725a185-3fc1-557a-b293-3c380c37ff85"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z35X10O aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "8d3640e0-1b8b-5786-a5b2-b2b812a6cc94"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "b725a185-3fc1-557a-b293-3c380c37ff85"
                    ]
                }
            }
        },
        {
            "name": "screened user: O0K9D01 aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "8d3640e0-1b8b-5786-a5b2-b2b812a6cc94"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "b725a185-3fc1-557a-b293-3c380c37ff85"
                    ]
                }
            }
        },
        {
            "name": "screened user: 4EGLK3P aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "8d3640e0-1b8b-5786-a5b2-b2b812a6cc94"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "b725a185-3fc1-557a-b293-3c380c37ff85"
                    ]
                }
            }
        },
        {
            "name": "screened user: PE694EL aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "8d3640e0-1b8b-5786-a5b2-b2b812a6cc94"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "8e98bde5-9c64-51ed-b304-7a89ef6916de"
                    ]
                }
            }
        },
        {
            "name": "screened user: K399M3L aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "8d3640e0-1b8b-5786-a5b2-b2b812a6cc94"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "b725a185-3fc1-557a-b293-3c380c37ff85"
                    ]
                }
            }
        },
        {
            "name": "screened user: 50MGW06 aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "ed4e0160-626b-5018-a3fd-8582190b6617"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "34a39e0c-d3a2-509d-9601-54365808b7bc"
                    ]
                }
            }
        },
        {
            "name": "screened user: J0VGM0L aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "ed4e0160-626b-5018-a3fd-8582190b6617"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "8e98bde5-9c64-51ed-b304-7a89ef6916de"
                    ]
                }
            }
        },
        {
            "name": "screened user: Y32QME8 aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "8d3640e0-1b8b-5786-a5b2-b2b812a6cc94"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "b725a185-3fc1-557a-b293-3c380c37ff85"
                    ]
                }
            }
        },
        {
            "name": "screened user: O3P770W aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-14a": {
                    "questionId": "ED-14a",
                    "chosenOptionIds": [
                        "5f81cf88-5168-57dd-ada6-fa89076a1fc2"
                    ]
                },
                "ED-2a": {
                    "questionId": "ED-2a",
                    "chosenOptionIds": [
                        "38800324-962a-5e2a-86aa-2fe7de19f0e4"
                    ]
                },
                "ED-3a": {
                    "questionId": "ED-3a",
                    "chosenOptionIds": [
                        "b381c1ba-cd91-5efb-bb01-d9d89c4737ba"
                    ]
                },
                "ED-4a": {
                    "questionId": "ED-4a",
                    "chosenOptionIds": [
                        "1cadf617-7206-59f8-98c7-e8c44436c59b"
                    ]
                },
                "ED-5.5a": {
                    "questionId": "ED-5.5a",
                    "chosenOptionIds": [
                        "4de3094a-5f24-5877-8aaf-ed8d96de3bea"
                    ]
                },
                "ED-5a": {
                    "questionId": "ED-5a",
                    "chosenOptionIds": [
                        "8a98a11e-b806-565c-a169-6273b884c919"
                    ]
                },
                "ED-6a": {
                    "questionId": "ED-6a",
                    "chosenOptionIds": [
                        "11fdb74b-d58d-54c9-8994-f1524b3397b1"
                    ]
                },
                "ED-7a": {
                    "questionId": "ED-7a",
                    "chosenOptionIds": [
                        "3e7d3bb8-7b96-56b8-a593-4d06971468f6"
                    ]
                },
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "ed4e0160-626b-5018-a3fd-8582190b6617"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "cef9c521-e355-5d92-8ecf-edb119617146"
                    ]
                },
                "ED-s3": {
                    "questionId": "ED-s3",
                    "chosenOptionIds": [
                        "de401494-df90-5afc-9a03-9a7ceeb611f4"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z356NEO aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "ed4e0160-626b-5018-a3fd-8582190b6617"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "8e98bde5-9c64-51ed-b304-7a89ef6916de"
                    ]
                }
            }
        },
        {
            "name": "screened user: 23XV2EN aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "8d3640e0-1b8b-5786-a5b2-b2b812a6cc94"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "b725a185-3fc1-557a-b293-3c380c37ff85"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z3R443O aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "8d3640e0-1b8b-5786-a5b2-b2b812a6cc94"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "8e98bde5-9c64-51ed-b304-7a89ef6916de"
                    ]
                }
            }
        },
        {
            "name": "screened user: PE6Q4EL aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "ca9a2825-624b-5e76-9afa-6a30812757ca"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "34a39e0c-d3a2-509d-9601-54365808b7bc"
                    ]
                }
            }
        },
        {
            "name": "screened user: J3JV43Y aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "ca9a2825-624b-5e76-9afa-6a30812757ca"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "8e98bde5-9c64-51ed-b304-7a89ef6916de"
                    ]
                }
            }
        },
        {
            "name": "screened user: 63Y843R aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "8d3640e0-1b8b-5786-a5b2-b2b812a6cc94"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "b725a185-3fc1-557a-b293-3c380c37ff85"
                    ]
                }
            }
        },
        {
            "name": "screened user: 23XX43N aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "ed4e0160-626b-5018-a3fd-8582190b6617"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "b725a185-3fc1-557a-b293-3c380c37ff85"
                    ]
                }
            }
        },
        {
            "name": "screened user: Y32LM08 aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "8d3640e0-1b8b-5786-a5b2-b2b812a6cc94"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "b725a185-3fc1-557a-b293-3c380c37ff85"
                    ]
                }
            }
        },
        {
            "name": "screened user: J3J4P0Y aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "ed4e0160-626b-5018-a3fd-8582190b6617"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "8e98bde5-9c64-51ed-b304-7a89ef6916de"
                    ]
                }
            }
        },
        {
            "name": "screened user: REOQG32 aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "ca9a2825-624b-5e76-9afa-6a30812757ca"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "8e98bde5-9c64-51ed-b304-7a89ef6916de"
                    ]
                }
            }
        },
        {
            "name": "screened user: 50MDW06 aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-8b": {
                    "questionId": "ED-8b",
                    "freeValue": 365
                },
                "ED-9b": {
                    "questionId": "ED-9b",
                    "freeValue": 730
                },
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "082d778a-6204-5e7e-b181-5b3871ae40e9"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "cef9c521-e355-5d92-8ecf-edb119617146"
                    ]
                },
                "ED-s3": {
                    "questionId": "ED-s3",
                    "chosenOptionIds": [
                        "341ad561-8953-5e62-9fa9-7235ab4fdc2c"
                    ]
                },
                "ED-typeb": {
                    "questionId": "ED-typeb",
                    "chosenOptionIds": [
                        "43e5132b-0bfa-56c8-b4c7-2c517948b556",
                        "e48b0849-948c-5e88-87ae-00bc05d6854c"
                    ]
                }
            }
        },
        {
            "name": "screened user: 7EW6KE8 aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "ed4e0160-626b-5018-a3fd-8582190b6617"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "34a39e0c-d3a2-509d-9601-54365808b7bc"
                    ]
                }
            }
        },
        {
            "name": "screened user: J0VQM3L aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "8d3640e0-1b8b-5786-a5b2-b2b812a6cc94"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "8e98bde5-9c64-51ed-b304-7a89ef6916de"
                    ]
                }
            }
        },
        {
            "name": "Tech team adjusted screen from TRUE, Censeo vPrevious user: 4EGRG3P aspect: dse_ce",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-10a": {
                    "questionId": "ED-10a",
                    "chosenOptionIds": [
                        "3ded8329-cb3b-5188-b967-df9c684ce4bd"
                    ]
                },
                "ED-11a": {
                    "questionId": "ED-11a",
                    "chosenOptionIds": [
                        "d56ffb70-eeb6-55d8-be61-7ca6ab62a8dd"
                    ]
                },
                "ED-12a": {
                    "questionId": "ED-12a",
                    "chosenOptionIds": [
                        "1db179fc-8fea-5b7b-a60c-58145451a67b"
                    ]
                },
                "ED-13a": {
                    "questionId": "ED-13a",
                    "chosenOptionIds": [
                        "50de0897-bdef-5c42-9c7f-c93e13836162"
                    ]
                },
                "ED-14a": {
                    "questionId": "ED-14a",
                    "chosenOptionIds": [
                        "fe268cc4-6fea-518a-a640-e22f055db3e3"
                    ]
                },
                "ED-15a": {
                    "questionId": "ED-15a",
                    "chosenOptionIds": [
                        "7d7b1266-8035-5c61-b3d1-bbd04889c8b4"
                    ]
                },
                "ED-16a": {
                    "questionId": "ED-16a",
                    "chosenOptionIds": [
                        "b6459d53-8f72-57eb-bcd8-520a8cedbc85"
                    ]
                },
                "ED-17a": {
                    "questionId": "ED-17a",
                    "chosenOptionIds": [
                        "78ab7b7a-0ad7-5562-b25f-ef1d17f294e2"
                    ]
                },
                "ED-18a": {
                    "questionId": "ED-18a",
                    "chosenOptionIds": [
                        "034010b1-6107-5d00-9d5d-953fe62faace"
                    ]
                },
                "ED-20a": {
                    "questionId": "ED-20a",
                    "chosenOptionIds": [
                        "503cf098-85d2-597c-bc0a-cd4770da2bbd"
                    ]
                },
                "ED-21a": {
                    "questionId": "ED-21a",
                    "freeValue": 6
                },
                "ED-22a": {
                    "questionId": "ED-22a",
                    "freeValue": 90
                },
                "ED-23.0a": {
                    "questionId": "ED-23.0a",
                    "chosenOptionIds": [
                        "66187ecb-00cf-56d4-868c-85561c4324c1"
                    ]
                },
                "ED-23a": {
                    "questionId": "ED-23a",
                    "chosenOptionIds": [
                        "32549cb2-4927-5c8a-9499-b80b81febc69",
                        "582a0fd2-b5e6-56f2-8c91-ec681d2bb9bc",
                        "0ba65a89-5dad-5277-b9bc-ff5bd4d9ce53",
                        "8a196a52-708f-5f15-acc1-154fb5d4b53f",
                        "0f1503c1-1765-55f6-88c2-689b2187fa00",
                        "26ea0e59-ae93-59df-abd0-6ffc5affabc5",
                        "46326163-0f95-540e-902a-f1c74a9c0c4a",
                        "bb6ab166-5817-541a-ae48-7736dcee8a5f"
                    ]
                },
                "ED-2a": {
                    "questionId": "ED-2a",
                    "chosenOptionIds": [
                        "e774449c-42ee-5867-98c9-af9f6bddeb94"
                    ]
                },
                "ED-3a": {
                    "questionId": "ED-3a",
                    "chosenOptionIds": [
                        "3ecf7c28-1b3e-5019-826f-1df5f71b4e9e"
                    ]
                },
                "ED-4a": {
                    "questionId": "ED-4a",
                    "chosenOptionIds": [
                        "4b7d4e34-90ba-5dff-b480-16d4696cdeaf"
                    ]
                },
                "ED-5.5a": {
                    "questionId": "ED-5.5a",
                    "chosenOptionIds": [
                        "bc6be0c6-b35d-59fb-ba45-63cd0e72ffae"
                    ]
                },
                "ED-5a": {
                    "questionId": "ED-5a",
                    "chosenOptionIds": [
                        "12023b69-a8b2-5bb8-a2c1-39e8c3094426"
                    ]
                },
                "ED-6a": {
                    "questionId": "ED-6a",
                    "chosenOptionIds": [
                        "394497b0-743d-5d7b-a332-c070d97a1670"
                    ]
                },
                "ED-7a": {
                    "questionId": "ED-7a",
                    "chosenOptionIds": [
                        "2acebc45-5b8e-5782-ba7e-1ec31009bb9c"
                    ]
                },
                "ED-8a": {
                    "questionId": "ED-8a",
                    "freeValue": 90
                },
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "082d778a-6204-5e7e-b181-5b3871ae40e9"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "34a39e0c-d3a2-509d-9601-54365808b7bc"
                    ]
                },
                "ED-s3": {
                    "questionId": "ED-s3",
                    "chosenOptionIds": [
                        "de401494-df90-5afc-9a03-9a7ceeb611f4"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z35L10O aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-8b": {
                    "questionId": "ED-8b",
                    "freeValue": 1825
                },
                "ED-9b": {
                    "questionId": "ED-9b",
                    "freeValue": 30
                },
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "2cfff998-c76f-59bc-9e49-99e990424f1f"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "8e98bde5-9c64-51ed-b304-7a89ef6916de"
                    ]
                },
                "ED-s3": {
                    "questionId": "ED-s3",
                    "chosenOptionIds": [
                        "341ad561-8953-5e62-9fa9-7235ab4fdc2c"
                    ]
                },
                "ED-typeb": {
                    "questionId": "ED-typeb",
                    "chosenOptionIds": [
                        "c694eee1-9afc-594d-acdf-144774817334"
                    ]
                }
            }
        },
        {
            "name": "screened user: 63YM43R aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-8b": {
                    "questionId": "ED-8b",
                    "freeValue": 30
                },
                "ED-9b": {
                    "questionId": "ED-9b",
                    "freeValue": 730
                },
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "2cfff998-c76f-59bc-9e49-99e990424f1f"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "35cdb4b5-54d0-544f-a02b-6b64dd448f5d"
                    ]
                },
                "ED-s3": {
                    "questionId": "ED-s3",
                    "chosenOptionIds": [
                        "341ad561-8953-5e62-9fa9-7235ab4fdc2c"
                    ]
                },
                "ED-typeb": {
                    "questionId": "ED-typeb",
                    "chosenOptionIds": [
                        "e48b0849-948c-5e88-87ae-00bc05d6854c",
                        "43e5132b-0bfa-56c8-b4c7-2c517948b556"
                    ]
                }
            }
        },
        {
            "name": "screened user: XE72M0L aspect: dse_ce should be positive",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "ED-10a": {
                    "questionId": "ED-10a",
                    "chosenOptionIds": [
                        "6eb3f978-4b7a-506c-ad67-ed64847345ff"
                    ]
                },
                "ED-11a": {
                    "questionId": "ED-11a",
                    "chosenOptionIds": [
                        "36718265-bc32-5a1a-8c07-401b4d9b5b44"
                    ]
                },
                "ED-12a": {
                    "questionId": "ED-12a",
                    "chosenOptionIds": [
                        "13241496-1d78-5434-ad3c-e1338ed8fc77"
                    ]
                },
                "ED-13a": {
                    "questionId": "ED-13a",
                    "chosenOptionIds": [
                        "ad6928ac-0d45-5e60-98c8-d08a1da794d3"
                    ]
                },
                "ED-14a": {
                    "questionId": "ED-14a",
                    "chosenOptionIds": [
                        "fe268cc4-6fea-518a-a640-e22f055db3e3"
                    ]
                },
                "ED-15a": {
                    "questionId": "ED-15a",
                    "chosenOptionIds": [
                        "41312671-a780-5bad-9cb9-6fd11fa838d6"
                    ]
                },
                "ED-16a": {
                    "questionId": "ED-16a",
                    "chosenOptionIds": [
                        "b6459d53-8f72-57eb-bcd8-520a8cedbc85"
                    ]
                },
                "ED-17a": {
                    "questionId": "ED-17a",
                    "chosenOptionIds": [
                        "dd3d351b-a88a-54ef-bfb4-48871a6580ef"
                    ]
                },
                "ED-18a": {
                    "questionId": "ED-18a",
                    "chosenOptionIds": [
                        "034010b1-6107-5d00-9d5d-953fe62faace"
                    ]
                },
                "ED-20a": {
                    "questionId": "ED-20a",
                    "chosenOptionIds": [
                        "503cf098-85d2-597c-bc0a-cd4770da2bbd"
                    ]
                },
                "ED-21a": {
                    "questionId": "ED-21a",
                    "freeValue": 7
                },
                "ED-22a": {
                    "questionId": "ED-22a",
                    "freeValue": 9125
                },
                "ED-23.0a": {
                    "questionId": "ED-23.0a",
                    "chosenOptionIds": [
                        "66187ecb-00cf-56d4-868c-85561c4324c1"
                    ]
                },
                "ED-23a": {
                    "questionId": "ED-23a",
                    "chosenOptionIds": [
                        "46326163-0f95-540e-902a-f1c74a9c0c4a",
                        "26ea0e59-ae93-59df-abd0-6ffc5affabc5",
                        "bb6ab166-5817-541a-ae48-7736dcee8a5f",
                        "0f1503c1-1765-55f6-88c2-689b2187fa00",
                        "8a196a52-708f-5f15-acc1-154fb5d4b53f",
                        "0ba65a89-5dad-5277-b9bc-ff5bd4d9ce53",
                        "32549cb2-4927-5c8a-9499-b80b81febc69",
                        "582a0fd2-b5e6-56f2-8c91-ec681d2bb9bc",
                        "ee4ca845-9e51-55bb-9f5a-16f681e33792"
                    ]
                },
                "ED-2a": {
                    "questionId": "ED-2a",
                    "chosenOptionIds": [
                        "e774449c-42ee-5867-98c9-af9f6bddeb94"
                    ]
                },
                "ED-3a": {
                    "questionId": "ED-3a",
                    "chosenOptionIds": [
                        "025b308d-42b4-52d0-a2bf-96091e198c9d"
                    ]
                },
                "ED-4a": {
                    "questionId": "ED-4a",
                    "chosenOptionIds": [
                        "4b7d4e34-90ba-5dff-b480-16d4696cdeaf"
                    ]
                },
                "ED-5.5a": {
                    "questionId": "ED-5.5a",
                    "chosenOptionIds": [
                        "ab8eb626-abd3-5d94-bc4f-538c9ff3660d"
                    ]
                },
                "ED-5a": {
                    "questionId": "ED-5a",
                    "chosenOptionIds": [
                        "8a98a11e-b806-565c-a169-6273b884c919"
                    ]
                },
                "ED-6a": {
                    "questionId": "ED-6a",
                    "chosenOptionIds": [
                        "7dea9557-7010-5f45-abdd-5e0bb7bd35ff"
                    ]
                },
                "ED-7a": {
                    "questionId": "ED-7a",
                    "chosenOptionIds": [
                        "b01109a6-f4c4-57b6-8562-09b122e04e82"
                    ]
                },
                "ED-8a": {
                    "questionId": "ED-8a",
                    "freeValue": 5475
                },
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "ed4e0160-626b-5018-a3fd-8582190b6617"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "cef9c521-e355-5d92-8ecf-edb119617146"
                    ]
                },
                "ED-s3": {
                    "questionId": "ED-s3",
                    "chosenOptionIds": [
                        "de401494-df90-5afc-9a03-9a7ceeb611f4"
                    ]
                }
            }
        },
        {
            "name": "screened user: ME8MOEW aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-8b": {
                    "questionId": "ED-8b",
                    "freeValue": 0
                },
                "ED-9b": {
                    "questionId": "ED-9b",
                    "freeValue": 0
                },
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "2cfff998-c76f-59bc-9e49-99e990424f1f"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "b725a185-3fc1-557a-b293-3c380c37ff85"
                    ]
                },
                "ED-s3": {
                    "questionId": "ED-s3",
                    "chosenOptionIds": [
                        "341ad561-8953-5e62-9fa9-7235ab4fdc2c"
                    ]
                },
                "ED-typeb": {
                    "questionId": "ED-typeb",
                    "chosenOptionIds": [
                        "c694eee1-9afc-594d-acdf-144774817334"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z35MQ3O aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "ca9a2825-624b-5e76-9afa-6a30812757ca"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "b725a185-3fc1-557a-b293-3c380c37ff85"
                    ]
                }
            }
        },
        {
            "name": "screened user: 4EG443P aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "ed4e0160-626b-5018-a3fd-8582190b6617"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "8e98bde5-9c64-51ed-b304-7a89ef6916de"
                    ]
                }
            }
        },
        {
            "name": "screened user: J0V6N3L aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "ed4e0160-626b-5018-a3fd-8582190b6617"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "b725a185-3fc1-557a-b293-3c380c37ff85"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z3R680O aspect: dse_ce should be positive",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "ED-10a": {
                    "questionId": "ED-10a",
                    "chosenOptionIds": [
                        "bd88202c-6e12-5515-84de-53703a947c11"
                    ]
                },
                "ED-11a": {
                    "questionId": "ED-11a",
                    "chosenOptionIds": [
                        "441a48b1-d590-5fd9-9346-e2ae3f5f5a3b"
                    ]
                },
                "ED-12a": {
                    "questionId": "ED-12a",
                    "chosenOptionIds": [
                        "39c4ccfd-3adf-51bf-b3f6-2dbb497ad25a"
                    ]
                },
                "ED-13a": {
                    "questionId": "ED-13a",
                    "chosenOptionIds": [
                        "ad6928ac-0d45-5e60-98c8-d08a1da794d3"
                    ]
                },
                "ED-14a": {
                    "questionId": "ED-14a",
                    "chosenOptionIds": [
                        "fe268cc4-6fea-518a-a640-e22f055db3e3"
                    ]
                },
                "ED-15a": {
                    "questionId": "ED-15a",
                    "chosenOptionIds": [
                        "7d7b1266-8035-5c61-b3d1-bbd04889c8b4"
                    ]
                },
                "ED-16a": {
                    "questionId": "ED-16a",
                    "chosenOptionIds": [
                        "76675190-5efd-5468-8cd3-5d4925569780"
                    ]
                },
                "ED-17a": {
                    "questionId": "ED-17a",
                    "chosenOptionIds": [
                        "dd3d351b-a88a-54ef-bfb4-48871a6580ef"
                    ]
                },
                "ED-18a": {
                    "questionId": "ED-18a",
                    "chosenOptionIds": [
                        "fbaa6f2d-b7be-5320-aa8d-771ed1a2a0ca"
                    ]
                },
                "ED-20a": {
                    "questionId": "ED-20a",
                    "chosenOptionIds": [
                        "503cf098-85d2-597c-bc0a-cd4770da2bbd"
                    ]
                },
                "ED-21a": {
                    "questionId": "ED-21a",
                    "freeValue": 3
                },
                "ED-22a": {
                    "questionId": "ED-22a",
                    "freeValue": 14600
                },
                "ED-23.0a": {
                    "questionId": "ED-23.0a",
                    "chosenOptionIds": [
                        "24ae6b5d-cdfd-5c2e-8f75-8ef5d76b902a"
                    ]
                },
                "ED-23a": {
                    "questionId": "ED-23a",
                    "chosenOptionIds": [
                        "0ba65a89-5dad-5277-b9bc-ff5bd4d9ce53",
                        "0f1503c1-1765-55f6-88c2-689b2187fa00",
                        "8a196a52-708f-5f15-acc1-154fb5d4b53f",
                        "bb6ab166-5817-541a-ae48-7736dcee8a5f",
                        "26ea0e59-ae93-59df-abd0-6ffc5affabc5"
                    ]
                },
                "ED-2a": {
                    "questionId": "ED-2a",
                    "chosenOptionIds": [
                        "6af23488-c989-5fcf-b078-156a2915e8c2"
                    ]
                },
                "ED-3a": {
                    "questionId": "ED-3a",
                    "chosenOptionIds": [
                        "d9b2f31b-97c9-5c52-bdc7-8d3b15df8e74"
                    ]
                },
                "ED-4a": {
                    "questionId": "ED-4a",
                    "chosenOptionIds": [
                        "4b7d4e34-90ba-5dff-b480-16d4696cdeaf"
                    ]
                },
                "ED-5.5a": {
                    "questionId": "ED-5.5a",
                    "chosenOptionIds": [
                        "bc6be0c6-b35d-59fb-ba45-63cd0e72ffae"
                    ]
                },
                "ED-5a": {
                    "questionId": "ED-5a",
                    "chosenOptionIds": [
                        "12023b69-a8b2-5bb8-a2c1-39e8c3094426"
                    ]
                },
                "ED-6a": {
                    "questionId": "ED-6a",
                    "chosenOptionIds": [
                        "394497b0-743d-5d7b-a332-c070d97a1670"
                    ]
                },
                "ED-7a": {
                    "questionId": "ED-7a",
                    "chosenOptionIds": [
                        "2acebc45-5b8e-5782-ba7e-1ec31009bb9c"
                    ]
                },
                "ED-8a": {
                    "questionId": "ED-8a",
                    "freeValue": 14600
                },
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "082d778a-6204-5e7e-b181-5b3871ae40e9"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "34a39e0c-d3a2-509d-9601-54365808b7bc"
                    ]
                },
                "ED-s3": {
                    "questionId": "ED-s3",
                    "chosenOptionIds": [
                        "de401494-df90-5afc-9a03-9a7ceeb611f4"
                    ]
                }
            }
        },
        {
            "name": "screened user: R047J04 aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "ed4e0160-626b-5018-a3fd-8582190b6617"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "8e98bde5-9c64-51ed-b304-7a89ef6916de"
                    ]
                }
            }
        },
        {
            "name": "screened user: O3PR70W aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "ca9a2825-624b-5e76-9afa-6a30812757ca"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "b725a185-3fc1-557a-b293-3c380c37ff85"
                    ]
                }
            }
        },
        {
            "name": "screened user: ME87V3W aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "ca9a2825-624b-5e76-9afa-6a30812757ca"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "34a39e0c-d3a2-509d-9601-54365808b7bc"
                    ]
                }
            }
        },
        {
            "name": "screened user: 2E17NED aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "ed4e0160-626b-5018-a3fd-8582190b6617"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "34a39e0c-d3a2-509d-9601-54365808b7bc"
                    ]
                }
            }
        },
        {
            "name": "screened user: PE61J0L aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "8d3640e0-1b8b-5786-a5b2-b2b812a6cc94"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "b725a185-3fc1-557a-b293-3c380c37ff85"
                    ]
                }
            }
        },
        {
            "name": "screened user: 50MVM06 aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "ca9a2825-624b-5e76-9afa-6a30812757ca"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "8e98bde5-9c64-51ed-b304-7a89ef6916de"
                    ]
                }
            }
        },
        {
            "name": "screened user: J3JK4EY aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "ed4e0160-626b-5018-a3fd-8582190b6617"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "8e98bde5-9c64-51ed-b304-7a89ef6916de"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z3RLO3O aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "ca9a2825-624b-5e76-9afa-6a30812757ca"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "b725a185-3fc1-557a-b293-3c380c37ff85"
                    ]
                }
            }
        },
        {
            "name": "screened user: XE7WX3L aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "8d3640e0-1b8b-5786-a5b2-b2b812a6cc94"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "b725a185-3fc1-557a-b293-3c380c37ff85"
                    ]
                }
            }
        },
        {
            "name": "screened user: PE64M0L aspect: dse_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "ED-S": {
                    "questionId": "ED-S",
                    "chosenOptionIds": [
                        "c2b33c07-814c-5e05-bc1c-3b4841aca99d"
                    ]
                },
                "ED-s1": {
                    "questionId": "ED-s1",
                    "chosenOptionIds": [
                        "ca9a2825-624b-5e76-9afa-6a30812757ca"
                    ]
                },
                "ED-s2": {
                    "questionId": "ED-s2",
                    "chosenOptionIds": [
                        "34a39e0c-d3a2-509d-9601-54365808b7bc"
                    ]
                }
            }
        }
    ]
};
exports.questionPredicates = {
    "ED-s3": "and[or[isUndefined(and[ED-s1<=0.5, ED-s2<=0.5]), not(and[ED-s1<=0.5, ED-s2<=0.5])], not(isUndefined(ED-s2=0))]",
    "ED-2a": "not(ED-s3=0)",
    "ED-3a": "not(ED-s3=0)",
    "ED-4a": "not(ED-s3=0)",
    "ED-5a": "not(ED-s3=0)",
    "ED-5.5a": "not(ED-s3=0)",
    "ED-6a": "not(ED-s3=0)",
    "ED-7a": "not(ED-s3=0)",
    "ED-8a": "ED-7a>=0.25",
    "ED-10a": "ED-7a>=0.25",
    "ED-11a": "ED-7a>=0.25",
    "ED-12a": "ED-7a>=0.25",
    "ED-13a": "ED-7a>=0.25",
    "ED-14a": "not(ED-s3=0)",
    "ED-15a": "ED-14a=1",
    "ED-16a": "ED-14a=1",
    "ED-17a": "ED-14a=1",
    "ED-18a": "or[and[ED-7a>=0.25, ED-14a=0], ED-14a=1]",
    "ED-20a": "and[or[isUndefined(ED19a_=1), not(ED19a_=1)], not(isUndefined(ED-18a=0))]",
    "ED-typeb": "ED-s3=0",
    "ED-8b": "or[ED-20a=0, ED-s3=0]",
    "ED-21a": "not(ED-20a=0)",
    "ED-22a": "not(ED-20a=0)",
    "ED-23a": "or[and[ED19a_=1, not(isUndefined(ED-18a=0))], and[ED-20a=0, not(isUndefined(ED-8b=0))], not(ED-20a=0)]",
    "ED-23.0a": "and[or[isUndefined(ED23a_none=1), not(ED23a_none=1)], not(isUndefined(ED-23a=0))]",
    "ED-9b": "and[or[isUndefined(ED-20a=0), not(ED-20a=0)], not(isUndefined(ED-8b=0))]"
};
