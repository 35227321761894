import { UserType } from 'censeo-core'
import { RegistrationAction, RegistrationActionKind, RegistrationState } from './registration.definitions'

export function RegistrationReducer(
    _state: RegistrationState | undefined,
    action: RegistrationAction
): RegistrationState | undefined {
    switch (action.type) {
        case RegistrationActionKind.StartD2C: {
            return { userType: UserType.D2C }
        }
        case RegistrationActionKind.StartNHS: {
            const { referralCode, referralPublicId, organisationCode, organisationName } = action.payload
            const newState = {
                userType: UserType.Nhs,
                referralCode,
                referralPublicId,
                organisationCode,
                organisationName,
            }
            return newState
        }
        case RegistrationActionKind.EndRegistration: {
            return {}
        }
        default: {
            throw new Error(`Unsupported action type of action: ${JSON.stringify(action)}`)
        }
    }
}
