import React from 'react'
import { domAnimation, LazyMotion, m } from 'framer-motion'
import Styles from './Content.module.scss'

type IContentProps = React.ComponentPropsWithoutRef<'div'>

/**
 * Content is a....
 */
const Content: React.FC<IContentProps> = ({ children }) => {
    return (
        <LazyMotion features={domAnimation}>
            <m.div
                initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
                transition={pageTransition}
                className={Styles.container}
            >
                {children}
            </m.div>
        </LazyMotion>
    )
}

const pageVariants = {
    initial: {
        opacity: 0,
        x: '-100%',
    },
    in: {
        opacity: 1,
        x: 0,
    },
    out: {
        opacity: 0,
        x: '100%',
    },
}

const pageTransition = {
    ease: 'anticipate',
    duration: 0.2,
}

export default Content
