export async function tokenRequest(
    inboundApiUrl: string,
    clientId: string,
    clientSecret: string
): Promise<{ errorMessage?: string; accessToken?: string; tokenType?: string }> {
    const tokenHeaders = new Headers()
    tokenHeaders.append('Content-Type', 'application/x-www-form-urlencoded')
    const urlencoded = new URLSearchParams()
    urlencoded.append('client_id', clientId)
    urlencoded.append('client_secret', clientSecret)
    urlencoded.append('grant_type', 'client_credentials')
    const tokenResponse = await fetch(`${inboundApiUrl}v1/oauth2/token`, {
        method: 'POST',
        headers: tokenHeaders,
        body: urlencoded,
    })
    if (!tokenResponse.ok) {
        const errorMessage = `${tokenResponse.status} - ${tokenResponse.statusText}: ${tokenResponse.text}`
        return { errorMessage }
    }
    let tokenResponseData
    try {
        tokenResponseData = await tokenResponse.json()
    } catch (err) {
        const errorMessage = 'Error parsing token response json when creating patient referral for demo users | ' + err
        return { errorMessage }
    }
    const accessToken = tokenResponseData?.access_token
    const tokenType = tokenResponseData?.token_type
    return { accessToken, tokenType }
}

export async function createReferral(
    inboundApiUrl: string,
    accessToken: string,
    tokenType: string,
    systemIdentifier?: string,
    logicalIdentifier?: string
): Promise<{ errorMessage?: string; publicReferralId?: string }> {
    const createReferralResponse = await fetch(`${inboundApiUrl}v1/nhs/Task/$createReferral`, {
        method: 'POST',
        headers: {
            Authorization: `${tokenType} ${accessToken}`,
        },
        body: JSON.stringify({
            resourceType: 'Parameters',
            parameter: [
                {
                    name: 'patient',
                    valueIdentifier: {
                        system: systemIdentifier || 'EprId',
                        value: logicalIdentifier || '123456',
                    },
                },
                {
                    name: 'nhsNumber',
                    valueString: '1234567890',
                },
                {
                    name: 'givenName',
                    valueString: 'John',
                },
                {
                    name: 'lastName',
                    valueString: 'Doe',
                },
                {
                    name: 'phone',
                    valueString: '+447908683338',
                },
                {
                    name: 'referringStaffMember',
                    valueString: 'user@example.com',
                },
            ],
        }),
    })
    if (!createReferralResponse.ok) {
        const errorMessage = `${createReferralResponse.status} - ${createReferralResponse.statusText}: ${createReferralResponse.text}`
        return { errorMessage }
    }

    try {
        const createReferralResponseData = await createReferralResponse.json()
        const publicReferralId = createReferralResponseData?.id
        if (!publicReferralId) {
            const errorMessage = 'No public referral id found on createReferral response for demo users'
            return { errorMessage }
        }
        return { publicReferralId }
    } catch (err) {
        const errorMessage =
            'Error parsing createReferral response json when creating patient referral for demo users | ' + err
        return { errorMessage }
    }
}
