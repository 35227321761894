export function getCookie(name: string): string | null {
    const nameEQ = name + '='
    const ca = document.cookie.split(';')
    for (const c of ca) {
        const trimmedC = c.trim()
        if (trimmedC.startsWith(nameEQ)) return trimmedC.substring(nameEQ.length)
    }
    return null
}

export function setCookie(name: string, content: string, expiryInDays: number): void {
    const date = new Date()
    date.setTime(date.getTime() + expiryInDays * 24 * 60 * 60 * 1000)
    const expires = `expires=${date.toUTCString()}`
    const secure = window.location.protocol === 'https:' ? ';secure' : ''
    const path = ';path=/'
    document.cookie = `${name}=${content};${expires}${path}${secure};SameSite=Strict`
}
