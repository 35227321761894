"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cognitoAttributeNames = exports.cognitoCustomAttributeNamesDeprecated = exports.cognitoCustomAttributeNames = void 0;
exports.cognitoCustomAttributeNames = [
    'gpPracticeId',
    'ccgId',
    'termsAcceptedDate',
    'nhsNumber',
    'ethnicity',
    'pregnant',
    'userType',
    'referralCode',
    'referralPublicId',
    'pathway',
    'sex',
];
exports.cognitoCustomAttributeNamesDeprecated = ['gpPracticeId', 'ccgId'];
const cognitoUserAttributeNames = [
    'email',
    'family_name',
    'given_name',
    'birthdate',
    'fullname',
    'nickname',
    'phone_number',
];
// i'm not really sure what the difference between the above and these is?
const cognitoUpdatableUserAttributeNames = [
    'gender',
    'address', // the value of address _isn't_ always a string! in a lambda event, its an object
];
exports.cognitoAttributeNames = [...cognitoUserAttributeNames, ...cognitoUpdatableUserAttributeNames];
