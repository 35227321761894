import React from 'react'
import { classNameMaker } from '../../utils'
import Styles from './Field.module.scss'

type IFieldProps = React.ComponentPropsWithoutRef<'div'>

/**
 * Field is a very basic layout element to space labels an inputs
 */
const Field: React.FC<IFieldProps> = ({ children, ...props }) => {
    const containerClass: string = classNameMaker({
        [Styles.container]: true,
    })
    return (
        <div className={containerClass} {...props}>
            {children}
        </div>
    )
}

export default Field
