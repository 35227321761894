/// <reference types="vite-plugin-svgr/client" />
import React, { useContext } from 'react'
import { Heading, Text, Page, Logo, Button } from '@psyomics/components'
import HeaderImage from '../../images/nhs-assessment-complete.svg?react'
import Styles from './AssessmentCompleteSent.module.scss'
import { useAuth } from 'registration/context/auth'
import { Referrals, ReferralCode, ServiceCode } from 'censeo-core'
import { useNavigate } from 'react-router-dom'
import { TypeformEmbed, TypeformType } from './TypeformEmbed'
import { ReferralDetailsContext } from 'ui/app/context/referralDetails'
import * as Sentry from '@sentry/react'
import { Splash } from '../Splash'
import CenseoError from '../Error'
import { useDemoContext } from 'registration/pages/Demo/DemoContext'

export const AssessmentCompleteSent: React.FC = () => {
    const { demo } = useDemoContext()
    const { referralCode, signOutUser } = useAuth()
    const teamName = getMHTeamName(referralCode)
    const referralDetails = useContext(ReferralDetailsContext)
    const organisationCode = referralDetails?.data?.organisationCode

    const navigate = useNavigate()

    if (!demo && (referralDetails?.status === 'loading' || !organisationCode)) {
        return <Splash />
    }

    if (referralDetails?.status === 'error' || referralDetails?.status === 'not-found') {
        Sentry.captureException(`No orgcode found on referralCode: ${referralCode}.`, {
            extra: { referralDetails },
        })
        return (
            <CenseoError
                homepageButton={false}
                logOutButton={true}
                customMessage="We could not retrieve your details. Please log out and try again."
            />
        )
    }

    const Header = (
        <header className={Styles.header}>
            <div className={Styles.headerInner}>
                <div className={Styles.logo}>
                    <Logo height="30" width="100" />
                </div>
                <HeaderImage className={Styles.headerImage} />
            </div>
        </header>
    )

    return (
        <Page layout="standard" header={Header}>
            <Heading className={Styles.heading} el="h1" size="heading2" color="mid">
                Thank you for completing the assessment.
            </Heading>
            <Text css={{ mb: 5 }}>
                Your report has been sent to {teamName ? `${teamName}` : `your clinical team`} to review.
            </Text>
            <Text css={{ mb: 5 }}>
                We know that the questions you have just answered may feel challenging or upsetting. If you are in need
                urgent support with your mental health, call Samaritans on 116123 or NHS 111 option 2. Or visit
                CheckPoint for global crisis support options.
            </Text>
            {!demo && (
                <TypeformEmbed type={TypeformType.PATIENT_FEEDBACK} contract={String(organisationCode)}></TypeformEmbed>
            )}
            <Button
                type="submit"
                appearance="primary"
                rounded
                fullWidth
                css={{ mt: 6 }}
                size="large"
                label="Sign out"
                onClick={async () => {
                    await signOutUser()
                    navigate('/')
                }}
            />
        </Page>
    )
}

const getMHTeamName = (referralCode?: string) => {
    if (referralCode && referralCode in ReferralCode) {
        return mhTeamNames[Referrals[referralCode as ReferralCode].serviceCode]
    }
}

const mhTeamNames: Partial<Record<ServiceCode, string>> = {
    herts: 'Hertfordshire Partnership University NHS Foundation Trust',
}
