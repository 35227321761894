import { gql } from '@apollo/client'
import { DocumentNode } from 'graphql'

export const authoriseReportMutation: DocumentNode = gql`
    mutation AuthoriseReport($assessmentID: ID!) {
        authoriseReport(assessmentId: $assessmentID)
    }
`

export interface AuthoriseReportResponse {
    authoriseReport: boolean
}
