"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.nodePaths = exports.supplementalInfoQuestionIds = exports.START_ID = void 0;
const types_1 = require("../types");
exports.START_ID = 'A';
exports.supplementalInfoQuestionIds = [];
exports.nodePaths = {
    A: {
        questionId: 'EUPD-1',
        answers: {
            '0e1d7bfa-9457-5852-b487-4628a4c83ef1': 'B',
            '15fa7f47-7f26-595f-986d-532716789a6f': 'B',
            '52fb5f3a-b30f-5f77-a2bd-0620b67a1adc': 'B',
            '5efe5d1e-b865-5328-b632-d1235e4f612d': 'C',
            '9c2ca9f0-e093-5ac3-8a23-494cb9298513': 'C',
        },
    },
    B: {
        questionId: 'EUPD-2',
        answers: {
            'ec6f9d53-dae0-5eb6-b0a6-ae8dc7774cb5': types_1.RagIds.rag5,
            'd25ceaaa-5094-5581-a0df-81187f0ef77d': types_1.RagIds.rag5,
            '04b30646-e5ef-56c6-8e67-b80b07442d24': types_1.RagIds.rag5,
            '336fae53-720d-5c45-bc21-01f833ad9e22': 'C',
            '44285291-8282-578e-858a-31e91b05796f': 'C',
        },
    },
    C: {
        questionId: 'EUPD-15',
        answers: {
            '6b84f725-12d7-5ce7-9dcf-1483247a1a95': types_1.RagIds.rag5,
            '0e690495-a758-5d5f-93fd-5fccf3304a93': types_1.RagIds.rag5,
            '83fa9a5c-2067-56d7-9d8f-7e8df8a9438f': types_1.RagIds.rag5,
            '9ce96b06-685e-587c-9c34-4c80e5a1497f': types_1.RagIds.rag4,
            'cf3fab17-0381-50f7-8f68-8de714f54af0': types_1.RagIds.rag3,
        },
    },
};
