import React from 'react'
import LinearProgress from '@mui/material/LinearProgress'
import { styled } from '@mui/system'

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    '&.MuiLinearProgress-colorPrimary': {
        backgroundColor: 'var(--c-paper-light)',
    },
    '& .MuiLinearProgress-bar': {
        borderRadius: 5,
        backgroundColor: 'var(--c-green-400)',
    },
}))

export const Splash: React.FC = () => <BorderLinearProgress />
