"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.screenInQuestionIds = exports.filterDuplicateQuestionIds = exports.getQuestionById = exports.isRequired = exports.nextQuestionId = void 0;
const algorithms_1 = require("./algorithms");
const nextQuestionId = (aspects, questions, questionPredicates, answers) => {
    const answerForQuestionExists = answers.reduce((acc, answer) => {
        return {
            ...acc,
            [answer.questionId]: true,
        };
    }, {});
    const questionFinder = questions.reduce((acc, question) => {
        const result = (0, exports.isRequired)(aspects, question, questionPredicates, answers);
        const answerExists = answerForQuestionExists[question.id];
        if (!acc.result && result && answerExists) {
            acc.previousValidAnswerQuestionId = question.id;
        }
        if (acc.result || acc.previouslyTriedAspects[questionPredicates[question.id]] || answerExists) {
            return acc;
        }
        return result
            ? {
                ...acc,
                result: question.id,
            }
            : {
                ...acc,
                previouslyTriedAspects: {
                    ...acc.previouslyTriedAspects,
                    [questionPredicates[question.id]]: true,
                },
            };
    }, {
        result: undefined,
        previouslyTriedAspects: {},
        previousValidAnswerQuestionId: undefined,
    });
    return {
        nextId: questionFinder.result,
        previousId: questionFinder.previousValidAnswerQuestionId,
    };
};
exports.nextQuestionId = nextQuestionId;
const isRequired = (aspects, question, questionPredicates, answers) => {
    if (!questionPredicates[question.id]) {
        return true;
    }
    const result = (0, algorithms_1.evaluate)(aspects, questionPredicates[question.id], answers);
    return result !== undefined && (result.type === 'predicate' ? result.value : result.value >= 0.5);
};
exports.isRequired = isRequired;
const getQuestionById = (questions, answers, questionId) => {
    const question = questions.find((question) => question.id === questionId);
    if (!question) {
        return undefined;
    }
    const chosenOptionsById = answers.reduce((acc, next) => next.chosenOptionIds
        ? {
            ...acc,
            [next.questionId]: next.chosenOptionIds,
        }
        : acc, {});
    const freeValuesById = answers.reduce((acc, next) => next.freeValue
        ? {
            ...acc,
            [next.questionId]: next.freeValue,
        }
        : acc, {});
    const freeTextById = answers.reduce((acc, next) => next.freeText
        ? {
            ...acc,
            [next.questionId]: next.freeText,
        }
        : acc, {});
    if (question.__typename === 'RestrictedChoiceQuestion') {
        return {
            ...question,
            chosenOptionIds: chosenOptionsById[question.id],
        };
    }
    else if (question.__typename === 'FreeQuestion') {
        return {
            ...question,
            answer: freeValuesById[question.id],
        };
    }
    else if (question.__typename === 'FreeTextQuestion') {
        return {
            ...question,
            answerText: freeTextById[question.id],
        };
    }
};
exports.getQuestionById = getQuestionById;
function filterDuplicateQuestionIds(sortedByTime) {
    return Object.values(sortedByTime.reduce((acc, next) => {
        if (!(next === null || next === void 0 ? void 0 : next.questionId)) {
            return acc;
        }
        return {
            ...acc,
            [next.questionId]: next,
        };
    }, {}));
}
exports.filterDuplicateQuestionIds = filterDuplicateQuestionIds;
exports.screenInQuestionIds = [
    'AU-10',
    'BP106',
    'BP2',
    'BPS2',
    'D-1.5b',
    'D-15a',
    'ED-2a',
    'EUPD-16a',
    'EUPD-3a',
    'GAD-s3a',
    'In-2',
    'LR38',
    'OCD-3a',
    'OCD-4a',
    'P-1',
    'P-10',
    'PD-1a',
    'S-s4',
    'SH-s2',
    'SP-s6a',
    'SU-s3',
];
