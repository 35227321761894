"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateReportDetails = exports.evaluateRag = exports.getDataFromAnswersDbCsv = exports.getAnsweredQuestionsFromAnswersDbCsv = exports.screenInQuestionIds = exports.allTemplates = exports.wrapWithBrand = exports.swap = exports.getLeaves = exports.FilterPass = exports.tests = exports.allAspectsByName = exports.allAspects = exports.allQuestionPredicates = exports.questionPredicates = exports.getQuestionsForSession = exports.getSessionFromQuestionId = exports.allQuestionsBySessionConfigs = exports.allQuestions = exports.questionsBySessionFiltered = exports.questionsBySession = exports.allSessionsFiltered = exports.sessionConfigsFiltered = exports.conditionByQuestionId = exports.allQuestionIdsPerCondition = exports.Dem = exports.Psychosis = exports.OCD = exports.Substance = exports.Alcohol = exports.SuicideAndSelfHarm = exports.Ed = exports.Eupd = exports.Pd = exports.Insomnia = exports.SoA = exports.Adhd = exports.Ad = exports.Gad = exports.ReportResultMessage = void 0;
const BP_1 = require("./yaml/generated/BP");
const MDD_1 = require("./yaml/generated/MDD");
const GAD_1 = require("./yaml/generated/GAD");
const AD_1 = require("./yaml/generated/AD");
const ADHD_1 = require("./yaml/generated/ADHD");
const SoA_1 = require("./yaml/generated/SoA");
const Insomnia_1 = require("./yaml/generated/Insomnia");
const PD_1 = require("./yaml/generated/PD");
const EUPD_1 = require("./yaml/generated/EUPD");
const Suicide_1 = require("./yaml/generated/Suicide");
const ED_1 = require("./yaml/generated/ED");
const OCD_1 = require("./yaml/generated/OCD");
const Alcohol_1 = require("./yaml/generated/Alcohol");
const Substance_1 = require("./yaml/generated/Substance");
const Psychosis_1 = require("./yaml/generated/Psychosis");
const PTSD_1 = require("./yaml/generated/PTSD");
const Dem_1 = require("./yaml/generated/Dem");
const PC_1 = require("./yaml/generated/PC");
const MoodTests_1 = require("./yaml/generated/MoodTests");
const sessionConfigs_1 = require("./sessionConfigs");
__exportStar(require("./schema/generated/censeo"), exports);
var management_1 = require("./schema/generated/management");
Object.defineProperty(exports, "ReportResultMessage", { enumerable: true, get: function () { return management_1.ReportResultMessage; } });
__exportStar(require("./questions"), exports);
__exportStar(require("./answers"), exports);
__exportStar(require("./sessionConfigs"), exports);
__exportStar(require("./reports"), exports);
__exportStar(require("./utils/calculateAge"), exports);
__exportStar(require("./utils/upperCaseFirst"), exports);
__exportStar(require("./yaml/generated/conditions"), exports);
exports.Gad = __importStar(require("./yaml/generated/GAD"));
exports.Ad = __importStar(require("./yaml/generated/AD"));
exports.Adhd = __importStar(require("./yaml/generated/ADHD"));
exports.SoA = __importStar(require("./yaml/generated/SoA"));
exports.Insomnia = __importStar(require("./yaml/generated/Insomnia"));
exports.Pd = __importStar(require("./yaml/generated/PD"));
exports.Eupd = __importStar(require("./yaml/generated/EUPD"));
exports.Ed = __importStar(require("./yaml/generated/ED"));
exports.SuicideAndSelfHarm = __importStar(require("./yaml/generated/Suicide"));
exports.Alcohol = __importStar(require("./yaml/generated/Alcohol"));
exports.Substance = __importStar(require("./yaml/generated/Substance"));
exports.OCD = __importStar(require("./yaml/generated/OCD"));
exports.Psychosis = __importStar(require("./yaml/generated/Psychosis"));
exports.Dem = __importStar(require("./yaml/generated/Dem"));
const filterQuestion = (question, userType) => !question.userType || question.userType === userType;
const allQuestionIdsPerCondition = () => {
    return {
        ad: AD_1.questions.map((q) => q.id),
        adhd: ADHD_1.questions.map((q) => q.id),
        alcohol: Alcohol_1.questions.map((q) => q.id),
        bp: BP_1.questions.map((q) => q.id),
        dem: Dem_1.questions.map((q) => q.id),
        ed: ED_1.questions.map((q) => q.id),
        eupd: EUPD_1.questions.map((q) => q.id),
        gad: GAD_1.questions.map((q) => q.id),
        insomnia: Insomnia_1.questions.map((q) => q.id),
        mdd: MDD_1.questions.map((q) => q.id),
        ocd: OCD_1.questions.map((q) => q.id),
        pc: PC_1.questions.map((q) => q.id),
        pd: PD_1.questions.map((q) => q.id),
        ptsd: PTSD_1.questions.map((q) => q.id),
        psychosis: Psychosis_1.questions.map((q) => q.id),
        soa: SoA_1.questions.map((q) => q.id),
        substance: Substance_1.questions.map((q) => q.id),
        suicideAndSelfHarm: Suicide_1.questions.map((q) => q.id),
    };
};
exports.allQuestionIdsPerCondition = allQuestionIdsPerCondition;
const conditionByQuestionId = (questionId) => {
    const conditions = (0, exports.allQuestionIdsPerCondition)();
    for (const condition in conditions) {
        if (conditions[condition].includes(questionId)) {
            return condition;
        }
    }
};
exports.conditionByQuestionId = conditionByQuestionId;
const sessionConfigsFiltered = (userType, sessionConfigs) => {
    return (0, sessionConfigs_1.sessionConfigsWithQuestions)(sessionConfigs)
        .filter((config) => config.questions.some((q) => filterQuestion(q, userType)))
        .map((config) => ({
        ...config,
        questions: config.questions.filter((q) => filterQuestion(q, userType)),
    }));
};
exports.sessionConfigsFiltered = sessionConfigsFiltered;
const getSessions = (sessionConfigs) => sessionConfigs.reduce((acc, next) => [
    ...acc,
    {
        name: next.name,
        firstQuestionId: next.questions[0].id,
        minutesDuration: Math.ceil(next.questions.length * 0.05),
        __typename: 'Session',
    },
], []);
const allSessionsFiltered = (userType, sessionConfigs) => getSessions((0, exports.sessionConfigsFiltered)(userType, sessionConfigs));
exports.allSessionsFiltered = allSessionsFiltered;
const getQuestionsBySession = (sessionConfigsWithQuestions) => sessionConfigsWithQuestions.reduce((acc, next) => ({
    ...acc,
    [next.name]: next.questions,
}), {});
const questionsBySession = (sessionConfigs) => getQuestionsBySession((0, sessionConfigs_1.sessionConfigsWithQuestions)(sessionConfigs));
exports.questionsBySession = questionsBySession;
const questionsBySessionFiltered = (userType, sessionConfigs) => {
    return getQuestionsBySession((0, exports.sessionConfigsFiltered)(userType, sessionConfigs));
};
exports.questionsBySessionFiltered = questionsBySessionFiltered;
const allQuestions = (sessionConfigs) => Object.values((0, exports.questionsBySession)(sessionConfigs)).flatMap((x) => x);
exports.allQuestions = allQuestions;
// exposing this for integration test purposes, by doing this if in the future the session config changes the tests will still pass
const allQuestionsBySessionConfigs = (sessionConfigs) => Object.values(getQuestionsBySession((0, sessionConfigs_1.sessionConfigsWithQuestions)(sessionConfigs))).flatMap((x) => x);
exports.allQuestionsBySessionConfigs = allQuestionsBySessionConfigs;
// currently this is not filtered by userType - filtering would only help by breaking if you're on a question you shouldn't be
const sessionByQuestionId = (sessionConfigs) => {
    return Object.entries((0, exports.questionsBySession)(sessionConfigs)).reduce((acc, [session, questions]) => ({
        ...questions.reduce((acc, next) => ({
            ...acc,
            [next.id]: session,
        }), acc),
    }), {});
};
const getSessionFromQuestionId = (questionId, sessionConfigs) => sessionByQuestionId(sessionConfigs)[questionId];
exports.getSessionFromQuestionId = getSessionFromQuestionId;
const getQuestionsForSession = (sessionName, userType, sessionConfigs) => {
    return (0, exports.questionsBySessionFiltered)(userType, sessionConfigs)[sessionName];
};
exports.getQuestionsForSession = getQuestionsForSession;
// todo hide
exports.questionPredicates = {
    ...AD_1.questionPredicates,
    ...ADHD_1.questionPredicates,
    ...SoA_1.questionPredicates,
    ...GAD_1.questionPredicates,
    ...Insomnia_1.questionPredicates,
    ...PD_1.questionPredicates,
    ...EUPD_1.questionPredicates,
    ...Suicide_1.questionPredicates,
    ...ED_1.questionPredicates,
    ...BP_1.questionPredicates,
    ...MDD_1.questionPredicates,
    ...Alcohol_1.questionPredicates,
    ...Substance_1.questionPredicates,
    ...OCD_1.questionPredicates,
    ...Psychosis_1.questionPredicates,
    ...PTSD_1.questionPredicates,
    ...Dem_1.questionPredicates,
    ...PC_1.questionPredicates,
};
const allQuestionPredicates = () => exports.questionPredicates;
exports.allQuestionPredicates = allQuestionPredicates;
const aspects = {
    ...AD_1.aspects,
    ...ADHD_1.aspects,
    ...SoA_1.aspects,
    ...GAD_1.aspects,
    ...Insomnia_1.aspects,
    ...PD_1.aspects,
    ...EUPD_1.aspects,
    ...Suicide_1.aspects,
    ...ED_1.aspects,
    ...BP_1.aspects,
    ...MDD_1.aspects,
    ...OCD_1.aspects,
    ...Alcohol_1.aspects,
    ...Substance_1.aspects,
    ...Psychosis_1.aspects,
    ...PTSD_1.aspects,
    ...Dem_1.aspects,
    ...PC_1.aspects,
};
const allAspects = () => Object.values(aspects);
exports.allAspects = allAspects;
const allAspectsByName = () => aspects;
exports.allAspectsByName = allAspectsByName;
exports.tests = {
    ...GAD_1.tests,
    ...AD_1.tests,
    ...ADHD_1.tests,
    ...SoA_1.tests,
    ...Insomnia_1.tests,
    ...PD_1.tests,
    ...EUPD_1.tests,
    ...Suicide_1.tests,
    ...ED_1.tests,
    ...combineTests(MoodTests_1.moodTests, BP_1.tests),
    ...OCD_1.tests,
    ...Alcohol_1.tests,
    ...Substance_1.tests,
    ...Psychosis_1.tests,
    ...PTSD_1.tests,
    ...Dem_1.tests,
};
function combineTests(tests, testsSubset) {
    const combinedTests = tests;
    Object.keys(testsSubset).forEach((x) => (combinedTests[x] = tests[x].concat(testsSubset[x])));
    return combinedTests;
}
__exportStar(require("./conditions/reportData"), exports);
__exportStar(require("./conditions/brand"), exports);
__exportStar(require("./tests"), exports);
__exportStar(require("./algorithms"), exports);
var circuits_1 = require("./circuits");
Object.defineProperty(exports, "FilterPass", { enumerable: true, get: function () { return circuits_1.FilterPass; } });
Object.defineProperty(exports, "getLeaves", { enumerable: true, get: function () { return circuits_1.getLeaves; } });
Object.defineProperty(exports, "swap", { enumerable: true, get: function () { return circuits_1.swap; } });
__exportStar(require("./templates/generated"), exports);
var brand_1 = require("./conditions/brand");
Object.defineProperty(exports, "wrapWithBrand", { enumerable: true, get: function () { return brand_1.wrapWithBrand; } });
var generated_1 = require("./templates/generated");
Object.defineProperty(exports, "allTemplates", { enumerable: true, get: function () { return generated_1.allTemplates; } });
var questions_1 = require("./questions");
Object.defineProperty(exports, "screenInQuestionIds", { enumerable: true, get: function () { return questions_1.screenInQuestionIds; } });
__exportStar(require("./user/User"), exports);
__exportStar(require("./user/CustomAttributes"), exports);
__exportStar(require("./user/mapToUserFromAttributes"), exports);
__exportStar(require("./user/userTypeFromString"), exports);
__exportStar(require("./service"), exports);
var test_data_1 = require("./test.data");
Object.defineProperty(exports, "getAnsweredQuestionsFromAnswersDbCsv", { enumerable: true, get: function () { return test_data_1.getAnsweredQuestionsFromAnswersDbCsv; } });
Object.defineProperty(exports, "getDataFromAnswersDbCsv", { enumerable: true, get: function () { return test_data_1.getDataFromAnswersDbCsv; } });
var ragEvaluation_1 = require("./ragAlgorithms/ragEvaluation");
Object.defineProperty(exports, "evaluateRag", { enumerable: true, get: function () { return ragEvaluation_1.evaluateRag; } });
var reportDetailsGenerator_1 = require("./reportDetailsGenerator");
Object.defineProperty(exports, "generateReportDetails", { enumerable: true, get: function () { return reportDetailsGenerator_1.generateReportDetails; } });
