import React, { useContext, useEffect } from 'react'
import { ApolloProvider } from '@apollo/client'
import { client } from './data/GraphQLClient'
import configure, { Info, InfoContext, Infrastructure } from './configure'
import { Splash } from './ui/app/pages/Splash'
import { AppRoutes } from './ui/app/Routes'
import { useAuth } from './registration/context/auth'
import Welcome from './registration/pages/Welcome'
import VerificationCode from './registration/pages/VerificationCode/VerificationCode'
import { createUnregisteredUserEvents, ReportingContext, useReportingApi } from './ui/app/reporting'
import { useMixpanelEvent } from 'ui/app/useMixpanelEvent'
import InactivityHandler from './ui/app/components/InactivityHandler/InactivityHandler'
import * as Sentry from '@sentry/react'
import { MixpanelContext } from 'registration/context/mixpanel/mixpanel-context'
import { useMixpanelApiUrlLoaded } from 'registration/utils/Mixpanel'
import { OrganisationContextProvider } from './ui/app/context/organisation'
import { ReferralDetailsContext, ReferralDetailsContextProvider } from 'ui/app/context/referralDetails'
import { OnboardingProvider } from 'registration/context/onboarding/onboarding-context'

export const AuthenticatedApp: React.FC = () => {
    const [info, setInfo] = React.useState<Info & Infrastructure>()
    const [referralPublicId, setReferralPublicId] = React.useState<string | undefined>('')

    useEffect(() => {
        configure()
            .then((info) => {
                setInfo(info)
            })
            .catch((error) => {
                Sentry.captureException(`Configure error: ${error}`)
            })
    }, [])

    return info ? (
        <InfoContext.Provider value={info}>
            <ReferralDetailsContextProvider
                referralPublicId={referralPublicId}
                setReferralPublicId={setReferralPublicId}
            >
                <ReportingContext.Provider value={createUnregisteredUserEvents(info.monitoringEndpoint)}>
                    <View referralPublicId={referralPublicId} setReferralPublicId={setReferralPublicId} />
                </ReportingContext.Provider>
            </ReferralDetailsContextProvider>
        </InfoContext.Provider>
    ) : (
        <Splash />
    )
}

type ViewProps = {
    referralPublicId?: string
    setReferralPublicId: React.Dispatch<React.SetStateAction<string | undefined>>
} & React.ComponentPropsWithoutRef<'div'>

const View: React.FC<ViewProps> = ({ ...props }) => {
    const { referralPublicId, setReferralPublicId } = props
    const {
        state: { isAuthenticated: userStatus, user },
        initializeUser,
    } = useAuth()

    useEffect(() => {
        const parentDocumentHead = document.getElementsByTagName('head')[0]
        const clinicalChatWidgetScriptNode = document.createElement('script')
        clinicalChatWidgetScriptNode.src = '//eu.fw-cdn.com/11389412/422929.js'
        clinicalChatWidgetScriptNode.setAttribute('chat', 'true')
        clinicalChatWidgetScriptNode.setAttribute('widgetId', '79f386ac-2c1e-4b1c-861e-d96cbed72585')
        parentDocumentHead.appendChild(clinicalChatWidgetScriptNode)
    }, [])

    useEffect(() => {
        initializeUser().catch((error) => {
            Sentry.captureException(`InitializeUser Error: ${error}`)
        })
    }, [initializeUser])

    const reporting = useReportingApi()

    useEffect(() => {
        reporting?.visitedSite(user?.id)
    }, [userStatus, reporting, user])
    useEffect(() => {
        if (userStatus.type === 'Unauthenticated') reporting?.noUserFound()
    }, [userStatus.type, reporting])

    const mixpanelApiUrl = useContext(MixpanelContext)
    const referralDetails = useContext(ReferralDetailsContext)
    const [isMixpanelApiUrlLoaded, setIsMixpanelApiUrlLoaded] = useMixpanelApiUrlLoaded()
    const { authenticated } = useMixpanelEvent(mixpanelApiUrl)
    const isDemoUser = !!referralDetails?.data?.organisationCode?.startsWith('demo')
    const isReferralDetailsLoaded = referralDetails?.status !== 'pending' && referralDetails?.status !== 'loading'

    useEffect(() => {
        if (mixpanelApiUrl && mixpanelApiUrl !== '') {
            setIsMixpanelApiUrlLoaded(true)
        }
    }, [mixpanelApiUrl])

    useEffect(() => {
        if (userStatus.type === 'Authenticated' && user?.id && referralDetails?.status === 'success') {
            authenticated(
                referralDetails?.data?.organisationCode,
                referralDetails?.data?.organisationName,
                user,
                referralDetails.data?.publicId
            )
        }
    }, [userStatus.type, referralDetails?.status])

    if (!isMixpanelApiUrlLoaded || !isReferralDetailsLoaded) {
        return <Splash />
    }

    switch (userStatus.type) {
        case 'Unknown':
            return <Splash />
        case 'Authenticated':
            return (
                <InactivityHandler
                    maxInactivityMs={900000}
                    maxConstantUseMs={43200000}
                    navigateTo={isDemoUser ? '/demo/healthcare' : '/signin'}
                >
                    <ApolloProvider client={client()}>
                        <OnboardingProvider>
                            <OrganisationContextProvider>
                                <AppRoutes setReferralPublicId={setReferralPublicId} />
                            </OrganisationContextProvider>
                        </OnboardingProvider>
                    </ApolloProvider>
                </InactivityHandler>
            )
        case 'Unauthenticated':
            return <Welcome referralPublicId={referralPublicId} setReferralPublicId={setReferralPublicId} />
        case 'Unconfirmed':
            return <VerificationCode email={userStatus.emailAddress} password={userStatus.password} />
    }
}
