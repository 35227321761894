import React, { useEffect, createContext } from 'react'
import { ApolloProvider } from '@apollo/client'
import { client } from './data/GraphQLClient'
import configure, { AuthType, Info, InfoContext, Infrastructure } from './configure'
import { Splash } from './ui/app/pages/Splash'
import { useAuth } from './registration/context/auth'
import InactivityHandler from './ui/app/components/InactivityHandler/InactivityHandler'
import * as Sentry from '@sentry/react'
import PractitionerCognitoLogin from 'ui/app/components/PractitionerCognitoLogin'
import PractitionerCallback from 'ui/app/components/PractitionerCallback'
import { Page, Heading } from '@psyomics/components'
import Header from 'registration/components/Header'
import Content from 'registration/components/Content'
import { Route, Routes, useLocation } from 'react-router-dom'
import PractitionerLogin from 'ui/app/components/PractitionerLogin'
import { Hub } from 'aws-amplify'
import { demoReports } from 'registration/pages/Demo/ClinicalReports/fakeData'
import PatientOutcomes from 'ui/app/pages/PatientOutcomes/PatientOutcomes'
import ClinicianIFU from 'ui/app/pages/ClinicianIFU'
import ReferralStats from 'registration/pages/ReferralStats/ReferralStats'
import PatientReferrals from 'ui/app/pages/PatientReferrals/PatientReferrals'
import NotFound from 'ui/app/pages/NotFound'
import { LoadReportV2 } from 'ui/app/pages/LoadReportV2'
import OrganisationAccount from 'ui/app/pages/OrganisationAccount'
import { PendoProvider } from 'ui/app/context/pendo'
import SignOut from 'ui/app/pages/SignOut'
import posthog from 'posthog-js'
import ReferPatients from 'registration/pages/ReferPatients/ReferPatients'
import { OrgData } from 'ui/app/context/organisation'
import { getReferralsEndpoint } from 'data/hooks/getReferralsEndpoint'

export const CustomStateContext = createContext<string | undefined>(undefined)

export const AuthenticatedAppPractitioner: React.FC = () => {
    const [info, setInfo] = React.useState<Info & Infrastructure>()
    const [referralPortalEnabled, setReferralPortalEnabled] = React.useState<boolean>(false)
    const [customState, setCustomState] = React.useState<string | undefined>()

    useEffect(() => {
        const parentDocumentHead = document.getElementsByTagName('head')[0]
        const clinicalChatWidgetScriptNode = document.createElement('script')
        clinicalChatWidgetScriptNode.src = '//eu.fw-cdn.com/11389412/422929.js'
        clinicalChatWidgetScriptNode.setAttribute('chat', 'true')
        parentDocumentHead.appendChild(clinicalChatWidgetScriptNode)
    }, [])

    useEffect(() => {
        Hub.listen('auth', async ({ payload: { event, data } }) => {
            if (event === 'customOAuthState') {
                setCustomState(decodeURIComponent(data))
            }
        })
        configure(AuthType.PRACTITIONER)
            .then((info) => {
                setInfo(info)
            })
            .catch((error) => {
                Sentry.captureException(error, { tags: { section: 'Practitioner login' } })
            })
    }, [])

    useEffect(() => {
        if (info?.posthogProxyDomain && !posthog.__loaded) {
            const key = import.meta.env.VITE_APP_POSTHOG_KEY
            if (key) {
                posthog.init(key, {
                    api_host: `https://${info.posthogProxyDomain}`,
                    ui_host: 'https://eu.i.posthog.com',
                })
            }
        }
    }, [info?.posthogProxyDomain])

    useEffect(() => {
        ;(async () => {
            if (!info?.referralApiUrl || !window.location.pathname.includes('account')) return

            const endpoint = getReferralsEndpoint(window.location.pathname, info?.referralApiUrl)
            const response = await fetch(endpoint)
            const { organisationConfig } = (await response.json()) as unknown as OrgData
            if (organisationConfig?.practitionerReferralPortalEnabled) {
                setReferralPortalEnabled(organisationConfig.practitionerReferralPortalEnabled === 'true')
            }

            if (!response.ok) {
                Sentry.captureException(new Error(`Referral API response not ok for endpoint: ${endpoint}`), {
                    extra: { endpoint, status: response.status, response },
                })
            }
        })()
    }, [info, window.location.pathname])

    return info ? (
        <InfoContext.Provider value={info}>
            <CustomStateContext.Provider value={customState}>
                <View referralPortalEnabled={referralPortalEnabled} />
            </CustomStateContext.Provider>
        </InfoContext.Provider>
    ) : (
        <Splash />
    )
}

const View: React.FC<{
    referralPortalEnabled: boolean
}> = ({ referralPortalEnabled }) => {
    const {
        state: { isAuthenticated: userStatus, user },
        initializeUser,
    } = useAuth()

    useEffect(() => {
        initializeUser().catch((error) => {
            Sentry.captureException(error, { tags: { section: 'Practitioner login' } })
        })
    }, [initializeUser])

    const location = useLocation()

    switch (userStatus.type) {
        case 'Unknown':
            return <Splash />
        case 'Authenticated':
            return (
                <InactivityHandler maxInactivityMs={900000} maxConstantUseMs={43200000}>
                    <ApolloProvider client={client()}>
                        <Routes location={location}>
                            {location.pathname.split('/')[1] === 'psyomics-login' && (
                                <Route
                                    path="/*"
                                    element={
                                        <Page>
                                            <Header template="landing" />
                                            <Content>
                                                <Heading el="h1" size="heading2" color="mid" css={{ mb: 4 }}>
                                                    Signed in as: {user?.email}
                                                    <br />
                                                    <br />
                                                    Go to a report url
                                                </Heading>
                                            </Content>
                                        </Page>
                                    }
                                />
                            )}
                            {location.pathname.split('/')[1] === 'practitioner-callback' && (
                                <Route path="/*" element={<PractitionerCallback />} />
                            )}
                            {location.pathname.split('/')[1] === 'report' && (
                                <Route
                                    path="/:id"
                                    element={
                                        <PendoProvider>
                                            <LoadReportV2 />
                                        </PendoProvider>
                                    }
                                />
                            )}
                            {location.pathname.split('/')[1] === 'account' && (
                                <>
                                    <Route path="/:organisationCode/information-for-use" element={<ClinicianIFU />} />
                                    <Route path="/:organisationCode/population-health" element={<PatientOutcomes />} />
                                    <Route path="/:organisationCode/referral-stats" element={<ReferralStats />} />
                                    {referralPortalEnabled && (
                                        <Route path="/:organisationCode/refer-patients" element={<ReferPatients />} />
                                    )}
                                    <Route path="/:organisationCode/patient-referrals" element={<PatientReferrals />} />
                                    <Route
                                        path="/:organisationCode"
                                        element={<OrganisationAccount referralPortalEnabled={referralPortalEnabled} />}
                                    />
                                </>
                            )}
                            <Route path="*" element={<NotFound />} />
                        </Routes>
                    </ApolloProvider>
                </InactivityHandler>
            )
        case 'Unauthenticated':
            return (
                <Routes location={location}>
                    {location.pathname.split('/')[1] === 'psyomics-login' && (
                        <Route path="/*" element={<PractitionerCognitoLogin />} />
                    )}

                    {location.pathname.split('/')[1] === 'report' && (
                        <>
                            {demoReports.ids.map((reportId) => (
                                <Route
                                    key={reportId}
                                    path={`/${reportId}`}
                                    element={
                                        <PendoProvider>
                                            <LoadReportV2 />
                                        </PendoProvider>
                                    }
                                />
                            ))}
                            <Route path={'/:id'} element={<PractitionerLogin />} />
                        </>
                    )}

                    {location.pathname.split('/')[1] === 'account' && (
                        <>
                            <Route path="/sign-out" element={<SignOut />} />
                            <Route path={'/:organisationCode'} element={<PractitionerLogin />} />
                        </>
                    )}
                    {location.pathname.split('/')[1] === 'practitioner-callback' && (
                        <Route path={'/*'} element={<PractitionerLogin />} />
                    )}

                    <Route path="*" element={<NotFound />} />
                </Routes>
            )
        default:
            return <Splash />
    }
}
