import React from 'react'
import { Widget } from '@typeform/embed-react'
import Styles from './TypeformEmbed.module.scss'

export enum TypeformType {
    PATIENT_FEEDBACK = 'eltxP25H',
    DEMO_FEEDBACK = 'aVhJpeco',
}
interface TypeformEmbedProps {
    contract: string
    type: TypeformType
}

export const TypeformEmbed: React.FC<TypeformEmbedProps> = ({ contract, type }) => {
    return <Widget id={type} className={Styles.typeformFeedback} hidden={{ contract }}></Widget>
}
