import React, { useContext, useEffect, useState } from 'react'
import { Heading, Page } from '@psyomics/components'
import Styles from './ROI.module.scss'
import { MixpanelContext } from 'registration/context/mixpanel/mixpanel-context'
import { useMixpanelEvent } from 'ui/app/useMixpanelEvent'
import { TextField } from '@mui/material'
import { InsightsGroup, Stat } from 'ui/app/components/InsightsGroup/InsightsGroup'
import Header from 'ui/app/components/Header'
import DemoFooter from 'ui/app/components/DemoFooter'

const defaultStats: Stat[][] = [
    [
        {
            name: 'Referrals to Censeo delivered',
            value: 1043,
        },
        {
            name: 'Completed Censeo assessments',
            value: 920,
        },
        {
            name: 'Completion rate',
            value: Math.ceil((920 / 1043) * 100),
            unit: (value: string) => `${value}%`,
        },
        {
            name: 'Average time taken to complete assessment',
            value: 45,
            unit: (value: string) => `${value} minutes`,
            minRamdomFactor: 0.9,
            maxRamdomFactor: 1,
        },
    ],
    [
        {
            name: 'Clinician agreement with report outcome',
            value: 91,
            unit: (value: string) => `${value}%`,
        },
        {
            name: 'Reports opened',
            value: 98,
            unit: (value: string) => `${value}%`,
        },
        {
            name: 'Clinical hours released',
            value: Math.ceil(920 * (30 / 100)), // 920 completed assessments x 30% = 332 hours
        },
    ],
    [
        {
            name: 'System uptime',
            value: 99.9,
            unit: (value: string) => `${value}%`,
        },
    ],
]

const ROI: React.FC = () => {
    const mixpanelApiUrl = useContext(MixpanelContext)
    const { visitedDemoInsights } = useMixpanelEvent(mixpanelApiUrl)

    useEffect(() => {
        visitedDemoInsights(undefined, undefined)
    }, [])

    const [stats, setStats] = useState<readonly Stat[][]>(defaultStats)

    const date = new Date()
    const month = date.getMonth().toString().padStart(2, '0')
    const year = date.getFullYear()
    const defaultDate = `${year}-${month}`

    function ramdomiseStats(stats: Stat[][], selectedDate: string) {
        const ramdomStats = stats.map((group, index) => {
            if (index === stats.length - 1) return group
            return group.map((stat) => {
                const ramdomValueKey = `${stat.name}_${selectedDate}`
                const storedValue = localStorage.getItem(ramdomValueKey) as unknown as number
                const randomValue =
                    storedValue ||
                    (() => {
                        const min = stat?.minRamdomFactor ?? 0.9
                        const max = stat?.maxRamdomFactor ?? 1
                        const newValue = Math.random() * (max - min) + min
                        localStorage.setItem(ramdomValueKey, newValue.toString())
                        return newValue
                    })()
                const copiedStat = { ...stat }
                return {
                    ...copiedStat,
                    value: Math.ceil(stat.value * randomValue),
                }
            })
        })

        return ramdomStats
    }

    return (
        <Page
            width="wide"
            header={<Header patientFacing={false} demoMenu={true} />}
            footer={<DemoFooter footerType="clinician" />}
        >
            <Heading el="h1" size="heading1" color="mid" css={{ mb: 2 }}>
                Activity Dashboard
            </Heading>
            <TextField
                InputProps={{ inputProps: { min: '2022-01', max: defaultDate } }}
                id="date"
                type="month"
                defaultValue={defaultDate}
                onChange={(event) => {
                    const selectedDate = event.target.value
                    setStats(selectedDate === defaultDate ? defaultStats : ramdomiseStats(defaultStats, selectedDate))
                }}
                className={Styles.datepicker}
            />
            {stats &&
                stats.map((group, index) => {
                    return <InsightsGroup stats={group} key={index} styles={Styles} />
                })}
        </Page>
    )
}

export default ROI
