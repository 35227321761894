import React from 'react'
import { ProgressDots } from '@psyomics/components'
import { RegistrationStep } from 'registration/context/registration/registration.definitions'
import { useRegistration } from 'registration/context/registration/useRegistration'

export const ProgressDotsRegistration: React.FC<{ step: RegistrationStep }> = ({ step }) => {
    const { getDotIdx, numDots } = useRegistration()

    return <ProgressDots current={getDotIdx(step)} count={numDots} css={{ mt: 7, mb: 7 }} />
}
