import { useCallback, useEffect, useRef } from 'react'
import { useFormikContext } from 'formik'
import isEqual from 'react-fast-compare'
import useComponentWillMount from './useComponentWillMount'
import usePrevious from './usePrevious'

export interface FormikRememberProps<T> {
    name: string

    debounceWaitMs?: number
    clearOnOnmount?: boolean
    saveOnlyOnSubmit?: boolean

    ignoreValues?: string[]

    parse?: (rawString: string) => T
    dump?: (data: T) => string

    setData?: (name: string, stringData: string) => void
    getData?: (name: string) => string | undefined | null
    clearData?: (name: string) => void

    onLoaded?: (data: T) => never
}

const DEFAULT_PROPS = {
    debounceWaitMs: 300,
    clearOnOnmount: true,
    saveOnlyOnSubmit: false,
    parse: JSON.parse,
    dump: JSON.stringify,
    ignoreValues: [],
}

const FormikRemember = <T,>(props: FormikRememberProps<T>): null => {
    const {
        getData = window.sessionStorage.getItem.bind(window.sessionStorage),
        setData = window.sessionStorage.setItem.bind(window.sessionStorage),
        clearData = window.sessionStorage.removeItem.bind(window.sessionStorage),
        name,
        parse,
        dump,
        clearOnOnmount,
        saveOnlyOnSubmit,
        onLoaded,
        ignoreValues,
    } = Object.assign(DEFAULT_PROPS, props)

    const { setValues, values, isSubmitting, isValid } = useFormikContext<T>()
    const wasSubmitting = usePrevious(isSubmitting)

    const $savedValues = useRef<T>()

    // Debounce doesn't work with tests
    const saveForm = useCallback(
        (data: T) => {
            const stringData = dump(data)

            setData(name, stringData)
        },
        [dump, setData, name]
    )

    // Load state from storage
    useComponentWillMount(() => {
        const stringData = getData(name)

        if (stringData) {
            const savedValues = parse(stringData)

            if (!isEqual(savedValues, values)) {
                $savedValues.current = savedValues
                setValues(savedValues)

                if (onLoaded) {
                    onLoaded(savedValues)
                }
            }
        }
    })

    // Save state
    useEffect(() => {
        const valuesToPersist = Object.assign({}, values)
        if (ignoreValues) {
            ignoreValues.forEach((value) => {
                delete valuesToPersist[value]
            })
        }

        if (!saveOnlyOnSubmit && !isEqual(valuesToPersist, $savedValues.current)) {
            saveForm(valuesToPersist)
        }
    }, [values, saveForm, saveOnlyOnSubmit, ignoreValues])

    // Clear data after unmount
    useEffect(
        () => () => {
            if (clearOnOnmount && isSubmitting) {
                clearData(name)
            }
        },
        [clearOnOnmount, isSubmitting, clearData, name]
    )

    // saveOnlyOnSubmit
    useEffect(
        () => () => {
            if (saveOnlyOnSubmit && wasSubmitting && !isSubmitting && isValid) {
                saveForm(values)
            }
        },
        [saveOnlyOnSubmit, isSubmitting, saveForm, values, isValid, wasSubmitting]
    )

    return null
}

export default FormikRemember
