import React from 'react'
import TopNavBar from '../TopNavBar'
import Header from 'registration/components/Header'
import Styles from './DemoHeader.module.scss'

interface DemoHeaderProps {
    navButtons: boolean
}

const DemoHeader: React.FC<DemoHeaderProps> = ({ navButtons }) => {
    return (
        <>
            <div className={Styles.headerOuter}>
                <div className={Styles.headerInner}></div>
            </div>
            <Header template="landing" />

            {navButtons && (
                <div className={Styles.navContainer}>
                    <div>
                        <TopNavBar />
                    </div>
                </div>
            )}
        </>
    )
}

export default DemoHeader
