import React from 'react'
import { useSpring, animated } from 'react-spring'

const NumberAnimate: React.FC<Omit<Stat, 'name'>> = ({ value, unit }) => {
    const countDecimals = function (number: number) {
        if (Math.floor(number.valueOf()) === number.valueOf()) return 0
        return number.toString().split('.')[1]?.length || 0
    }

    const toFixTo = countDecimals(value)
    const { number } = useSpring({
        from: { number: 0 },
        number: value,
        delay: 200,
        config: { mass: 1, tension: 20, friction: 10 },
    })
    const newNum = number.to((n) => n.toFixed(toFixTo)).to((n) => (unit ? unit(n) : n))
    return <animated.div>{newNum}</animated.div>
}

export interface Stat {
    name: string
    value: number
    unit?: (value: string) => string
    minRamdomFactor?: number
    maxRamdomFactor?: number
}

interface StatsProps {
    stats: Stat[]
    styles: { [className: string]: string }
}

export const InsightsGroup: React.FC<StatsProps> = ({ ...props }) => {
    const { styles } = props

    return (
        <div className={styles.card}>
            {props.stats.map(({ name, unit, value }, i) => {
                return (
                    <>
                        <div key={name} className={styles.row}>
                            <div>{name}</div>
                            <div className={styles.statVal}>
                                <NumberAnimate value={value} unit={unit}></NumberAnimate>
                            </div>
                        </div>
                        {i !== props.stats.length - 1 && <hr />}
                    </>
                )
            })}
        </div>
    )
}
