"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.runTest = exports.evaluateTest = void 0;
const algorithms_1 = require("./algorithms");
const failed = (test, signal) => ({
    type: 'failure',
    expected: test.expected,
    got: signal,
});
const evaluateTest = (test, signal, closeToThreshold = 0.2) => {
    const expected = test.expected;
    switch (expected.type) {
        case 'ClinicalDiagnosis':
            return signal === undefined ||
                (signal.type === 'severity' ? signal.value > 0.5 === expected.value : signal.value === expected.value)
                ? { type: 'passed' }
                : failed(test, signal);
        case 'Severity':
            switch (expected.value) {
                case 'severe':
                    return signal !== undefined &&
                        signal.type === 'severity' &&
                        signal.value >= 0.75 &&
                        signal.value <= 1
                        ? { type: 'passed' }
                        : failed(test, signal);
                case 'clinical':
                    return signal !== undefined &&
                        signal.type === 'severity' &&
                        signal.value >= 0.5 &&
                        signal.value < 0.75
                        ? { type: 'passed' }
                        : failed(test, signal);
                case 'subclinical':
                    return signal !== undefined &&
                        signal.type === 'severity' &&
                        signal.value >= 0.25 &&
                        signal.value < 0.5
                        ? { type: 'passed' }
                        : failed(test, signal);
                case 'low':
                    return signal !== undefined && signal.type === 'severity' && signal.value < 0.25
                        ? { type: 'passed' }
                        : failed(test, signal);
            }
            break;
        case 'CloseTo': {
            const roundedSignalValue = signal !== undefined && signal.type === 'severity' ? Number(signal.value.toFixed(2)) : null;
            return roundedSignalValue != null &&
                Number(Math.abs(expected.value - roundedSignalValue).toFixed(2)) <= closeToThreshold
                ? { type: 'passed' }
                : failed(test, signal);
        }
        case 'Unknown':
            return signal === undefined ? { type: 'passed' } : failed(test, signal);
    }
};
exports.evaluateTest = evaluateTest;
const runTest = (aspects, aspectName, test, closeToThreshold = 0.2) => {
    const answers = Object.keys(test.answers).map((questionId) => ({
        questionId,
        chosenOptionIds: test.answers[questionId].chosenOptionIds,
        freeValue: test.answers[questionId].freeValue,
    }));
    return (0, exports.evaluateTest)(test, (0, algorithms_1.evaluate)(aspects, aspectName, answers), closeToThreshold);
};
exports.runTest = runTest;
