"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.moodTests = void 0;
exports.moodTests = {
    "simple_bd1": [
        {
            "name": "Mood test: 6PE670L aspect: simple_bd1 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "a16e8e14-524e-53df-b4ef-730a2c8e5f32"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "c0453e8e-7a9c-5bf5-b8a2-167a61a6387e"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "826538fc-8a97-55e4-8013-fe6a93f8e451"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 180
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "913bc1e9-8da1-54c7-b935-13fc5d48fbd9"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "a9525e10-4e34-5d6b-9c93-c7546ec49231"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "de11fb6b-7e7d-52b1-9d09-2d6debc53314"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "77c6088d-2d8f-5c3b-89c9-79cb0d317226"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "1d4beed1-78e5-531c-a255-643fac260dee",
                        "9d1b858c-7064-5a84-80d6-786c18e603b6"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "f3efc764-ca53-52c3-9c3a-38c7f787d415"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "5d271ac0-beca-56cc-9d0b-adafcd67da53"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "d362cd87-7515-551e-bc74-19ef903484aa"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "4896b504-2025-52c3-85e2-c6cf3205cef7"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "ccbdcbec-96d0-5362-977b-784289f5cfc0"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "026a1cea-7cfc-540b-bfe5-42886be90595"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "6a5aa899-7b89-5661-a554-71b6058ad4a4"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "d3224e16-1b0c-5266-9e14-ac72d2b2e988"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "b24df75b-6bb9-5f5f-99e3-c883ec3644ec"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP12": {
                    "questionId": "BP12",
                    "chosenOptionIds": [
                        "1d6299ac-2ac6-51e2-9a3f-13e8d44e652a"
                    ]
                },
                "BP12.5": {
                    "questionId": "BP12.5",
                    "chosenOptionIds": [
                        "cd7ce00f-4e7c-57ac-9c6c-4137efa24c8c"
                    ]
                },
                "BP14": {
                    "questionId": "BP14",
                    "chosenOptionIds": [
                        "84994ca2-fcea-54fe-a7d6-9b44ec07f0bd"
                    ]
                },
                "BP14.1": {
                    "questionId": "BP14.1",
                    "chosenOptionIds": [
                        "f2b7f127-8ab7-5609-8820-43158baee15c"
                    ]
                },
                "BP14.2": {
                    "questionId": "BP14.2",
                    "freeValue": 1
                },
                "BP14.3": {
                    "questionId": "BP14.3",
                    "chosenOptionIds": [
                        "ac6d7839-60fb-5529-97b0-5d0f13ed20de"
                    ]
                },
                "BP16": {
                    "questionId": "BP16",
                    "chosenOptionIds": [
                        "fabceeea-8fea-5444-8b67-8f189f366c27"
                    ]
                },
                "BP16.0": {
                    "questionId": "BP16.0",
                    "chosenOptionIds": [
                        "4b26caa5-9124-51b2-ab08-2f4e977cee1f"
                    ]
                },
                "BP16.01": {
                    "questionId": "BP16.01",
                    "chosenOptionIds": [
                        "f81655d7-78e5-59b1-a7eb-90a2ec7a107d"
                    ]
                },
                "BP16.02": {
                    "questionId": "BP16.02",
                    "chosenOptionIds": [
                        "16a695c9-3ac1-5d34-b799-44602d85b556"
                    ]
                },
                "BP18": {
                    "questionId": "BP18",
                    "chosenOptionIds": [
                        "b577f323-ec54-5d25-a2b9-4f8de30d60a2"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 58
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "f03de28d-8f71-5af4-be2f-45f4e6092a3d"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "387b19c0-67d0-557e-9fba-ae065dcf3587"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "afb57366-c0b3-5f73-92eb-27bc133ad2ed"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "667ba57c-b1cd-5f9b-aa12-58e65a981910"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "5d5fa968-aeb3-569a-8a71-483ef04b9a6a"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "e22cf233-c174-52fb-af55-97bf9c46e971"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "1f57d1a0-3682-5d7d-b66b-8179ec5c3988"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "d680a8d9-e35f-563f-9d1d-e7c3136aff6d"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "c88e3fb5-913a-5c5c-a42b-603f7bb60a40"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "732c51f9-a0b6-5951-bd7d-eaaec24316ba"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "6928c696-e6d7-5a14-a004-15a497ac6562"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "17b4da6f-7135-523c-b90b-2fe3b66785ff"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "3cf77140-2757-58eb-b4ea-f2a90cd766e7"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "5c06d516-e816-58d6-997e-f8fa91131ac7"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "c0cc09c3-1335-5eb4-8801-b3cdaf74f6fa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM19": {
                    "questionId": "HM19",
                    "chosenOptionIds": [
                        "99f474d7-d509-592a-856a-afed343fb1b8"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "98ea07a0-ae95-570f-8d11-a3e7c682e986"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "696fdd9d-5ced-504b-a933-bb3e575b0099"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c41974b6-d394-569c-8f45-49f79fcac5c6"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "43c32319-c75f-5169-b0e5-713820721d4f"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "b2cb304f-f035-53fc-8763-e2f91b031f9b"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "0170b4cf-0541-53cd-a9db-818d5067dc23"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "2be66549-5767-51b4-82ed-deeab0723f8d"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "2a17c2fe-2592-58b1-98e9-3dc4168fe3e3"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "834b04f2-af09-5337-8fd5-be4a70c99e75"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "86277249-cf50-5f91-a27f-f3a69344e796"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "8823c74f-ff7c-52d8-9c73-fd3b4180f48a"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "1d07911e-929d-5719-aeea-a68f593d18e8"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: 6ED9M0M aspect: simple_bd1 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "0ec0e224-a5cf-5ece-b3ab-37061a600ace"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "3bd18f66-6f1e-5ea4-ae24-2990ed197bef"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "9b47900d-e5a7-5e58-830b-d9b3130a7990"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "b74511c7-9f97-5c15-8bf5-6ec4357b4814"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 1095
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "7a5958fc-e0f7-5d43-9cd0-abfe95cb7026"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "c695fd1c-2cb7-5654-b9f7-b32ecb158229"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "db7514e2-d317-55fb-82d3-2e4a49c2494b"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "9d1b858c-7064-5a84-80d6-786c18e603b6",
                        "1d4beed1-78e5-531c-a255-643fac260dee",
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "f3efc764-ca53-52c3-9c3a-38c7f787d415"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "3e093dd3-857e-5004-a4fb-961fbfa59ece"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "adecf2d3-f302-50f8-a4a9-3ffac1789bc6"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "d362cd87-7515-551e-bc74-19ef903484aa"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "13f8bac1-d0f5-539b-8596-1c55821bea46"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "44fd9eb3-aee2-5af0-b83c-dbbbf9514022"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "7599bcaa-89a5-5f71-80ea-b70c360bb44a"
                    ]
                },
                "BP101b": {
                    "questionId": "BP101b",
                    "chosenOptionIds": [
                        "31b2d2c8-8f28-514e-9e58-aa878f6c8761"
                    ]
                },
                "BP102b": {
                    "questionId": "BP102b",
                    "chosenOptionIds": [
                        "00afc1e6-b78a-5223-a6e7-5d3aaab833e0"
                    ]
                },
                "BP103b": {
                    "questionId": "BP103b",
                    "chosenOptionIds": [
                        "867b0ee4-73d9-5aac-b96d-bc2d85d5b183"
                    ]
                },
                "BP104b": {
                    "questionId": "BP104b",
                    "chosenOptionIds": [
                        "75eb3568-195e-5720-827c-1fd0f84c6e75"
                    ]
                },
                "BP105b": {
                    "questionId": "BP105b",
                    "chosenOptionIds": [
                        "2700e917-0fd4-5241-975c-959339855190"
                    ]
                },
                "BP106b": {
                    "questionId": "BP106b",
                    "freeValue": 1460
                },
                "BP87b": {
                    "questionId": "BP87b",
                    "chosenOptionIds": [
                        "fb568891-2ea3-5200-bbdb-ac0695f30b02"
                    ]
                },
                "BP88b": {
                    "questionId": "BP88b",
                    "chosenOptionIds": [
                        "7e4bdef7-d166-5a09-b3a4-639c1700578c"
                    ]
                },
                "BP89b": {
                    "questionId": "BP89b",
                    "chosenOptionIds": [
                        "db509963-d872-5b97-ba9b-a276f58e0189"
                    ]
                },
                "BP90b": {
                    "questionId": "BP90b",
                    "chosenOptionIds": [
                        "940868cd-d067-573d-9a59-29b2ca63222f"
                    ]
                },
                "BP92b": {
                    "questionId": "BP92b",
                    "chosenOptionIds": [
                        "f59918ba-eb3a-5cf1-81aa-8b7f6ac0df5e"
                    ]
                },
                "BP94b": {
                    "questionId": "BP94b",
                    "chosenOptionIds": [
                        "038cb12b-2783-569a-b1d7-e28c65e591a3"
                    ]
                },
                "BP95b": {
                    "questionId": "BP95b",
                    "chosenOptionIds": [
                        "fb0bd4a7-2793-5b81-badc-fe81b4bc82dc"
                    ]
                },
                "BP96b": {
                    "questionId": "BP96b",
                    "chosenOptionIds": [
                        "c1f02353-e805-53f2-ab3b-34499538a407"
                    ]
                },
                "BP97b": {
                    "questionId": "BP97b",
                    "chosenOptionIds": [
                        "8a1fc783-28cb-5bf7-aa78-8f6b7c36ad29"
                    ]
                },
                "BP98b": {
                    "questionId": "BP98b",
                    "chosenOptionIds": [
                        "01641419-fbfc-52a5-ae63-0fa736ff9847"
                    ]
                },
                "BP99.3b": {
                    "questionId": "BP99.3b",
                    "chosenOptionIds": [
                        "c5d5e724-00d6-5a76-bcaf-e4b6545b7fa4"
                    ]
                },
                "BP99b": {
                    "questionId": "BP99b",
                    "chosenOptionIds": [
                        "68860df1-e835-50d6-8eb2-f9d9f2f97efa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "BP_neg1": {
                    "questionId": "BP_neg1",
                    "chosenOptionIds": [
                        "119208b0-ff3b-5c43-80be-4f07e65b6248"
                    ]
                }
            }
        },
        {
            "name": "Mood test: O0K9D01 aspect: simple_bd1 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "0ec0e224-a5cf-5ece-b3ab-37061a600ace"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "a16e8e14-524e-53df-b4ef-730a2c8e5f32"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "eb68a88d-b49b-5cde-a9c6-5e4c78e27d19"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "b74511c7-9f97-5c15-8bf5-6ec4357b4814"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 3650
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "db360e93-3616-5d89-be36-5ccee4aacf1c"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "11f457f5-4c39-5458-ba9a-84478dd379ba"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "db7514e2-d317-55fb-82d3-2e4a49c2494b"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "9d1b858c-7064-5a84-80d6-786c18e603b6",
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b",
                        "1d4beed1-78e5-531c-a255-643fac260dee"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "b52073e3-c813-51f5-88ba-9cd3bddd7023"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "ef6fddab-7a07-5f20-af20-74f2444eee53"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "d362cd87-7515-551e-bc74-19ef903484aa"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "44fd9eb3-aee2-5af0-b83c-dbbbf9514022"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "6215a3de-a3e4-5f86-a039-1006646e6b93"
                    ]
                },
                "BP106": {
                    "questionId": "BP106",
                    "chosenOptionIds": [
                        "3648c32c-0ce7-5c1d-a7e8-d45a899b40f9"
                    ]
                },
                "BP108": {
                    "questionId": "BP108",
                    "chosenOptionIds": [
                        "6c88c07e-4483-5b5b-b6ad-44cff962863e"
                    ]
                },
                "BP110": {
                    "questionId": "BP110",
                    "freeValue": 0.25
                },
                "BP110.01": {
                    "questionId": "BP110.01",
                    "chosenOptionIds": [
                        "a6d87c31-9a11-5151-958a-040439463b3d"
                    ]
                },
                "BP110.1": {
                    "questionId": "BP110.1",
                    "chosenOptionIds": [
                        "9d19a3c0-120d-5dfd-a794-8aa0e8d39e77"
                    ]
                },
                "BP110.2": {
                    "questionId": "BP110.2",
                    "chosenOptionIds": [
                        "faf183ab-b9b4-5f0d-a996-dfa04be4e401"
                    ]
                },
                "BP111": {
                    "questionId": "BP111",
                    "chosenOptionIds": [
                        "059fdaea-ce9e-527c-ae94-e00d2b0dbe01"
                    ]
                },
                "BP111.1": {
                    "questionId": "BP111.1",
                    "chosenOptionIds": [
                        "c532b673-2762-5ed0-8cbb-6f70d9977c20"
                    ]
                },
                "BP111.2": {
                    "questionId": "BP111.2",
                    "chosenOptionIds": [
                        "6d6a3478-7734-5127-b59e-092b42109314"
                    ]
                },
                "BP112": {
                    "questionId": "BP112",
                    "chosenOptionIds": [
                        "6db4ba23-1d8f-58b9-b11e-eaea15417e28"
                    ]
                },
                "BP113": {
                    "questionId": "BP113",
                    "chosenOptionIds": [
                        "b16103eb-2ecf-595e-80e4-30baf575e01b"
                    ]
                },
                "BP114": {
                    "questionId": "BP114",
                    "chosenOptionIds": [
                        "a0206e39-59ad-539c-a2ff-e9fd935cf48b"
                    ]
                },
                "BP115": {
                    "questionId": "BP115",
                    "chosenOptionIds": [
                        "e7b2a68b-de30-51bd-bb56-cda1254d3dbc"
                    ]
                },
                "BP116": {
                    "questionId": "BP116",
                    "chosenOptionIds": [
                        "cd9085de-4e15-5383-b814-a261f79b136f"
                    ]
                },
                "BP117": {
                    "questionId": "BP117",
                    "chosenOptionIds": [
                        "c68c6fcb-e62b-5749-80e4-9c4ad9995b97"
                    ]
                },
                "BP118": {
                    "questionId": "BP118",
                    "chosenOptionIds": [
                        "a611c3a0-727b-5a9b-8d15-d805b2ecf757"
                    ]
                },
                "BP118.1": {
                    "questionId": "BP118.1",
                    "chosenOptionIds": [
                        "3780fe32-30da-5e3b-bad1-6d360381e7e4"
                    ]
                },
                "BP119": {
                    "questionId": "BP119",
                    "chosenOptionIds": [
                        "3f4ab649-e8be-559b-a32f-9ccb317ffb38"
                    ]
                },
                "BP119.1": {
                    "questionId": "BP119.1",
                    "freeValue": 0
                },
                "BP120": {
                    "questionId": "BP120",
                    "chosenOptionIds": [
                        "548c329b-3169-5742-b17d-437216e6a454"
                    ]
                },
                "BP121": {
                    "questionId": "BP121",
                    "chosenOptionIds": [
                        "76891993-4f79-5b7a-ad6f-b7be1c4994fb"
                    ]
                },
                "BP122": {
                    "questionId": "BP122",
                    "chosenOptionIds": [
                        "84ec10d0-2e9c-5671-9d59-dc91b256ca0b"
                    ]
                },
                "BP123": {
                    "questionId": "BP123",
                    "chosenOptionIds": [
                        "972a0931-d7f1-5ad4-90af-91046fd7b193"
                    ]
                },
                "BP124": {
                    "questionId": "BP124",
                    "chosenOptionIds": [
                        "d7e7a81a-6129-54d0-8a45-35c7e9ae3bf3"
                    ]
                },
                "BP125": {
                    "questionId": "BP125",
                    "chosenOptionIds": [
                        "1926be5c-c655-5494-8fb8-9492f4a4324e"
                    ]
                },
                "BP126": {
                    "questionId": "BP126",
                    "chosenOptionIds": [
                        "47856c8e-63c1-5066-bf95-92ff01e0982a"
                    ]
                },
                "BP127": {
                    "questionId": "BP127",
                    "chosenOptionIds": [
                        "5f58bb60-dff1-585c-a5f3-26e34e4dc957"
                    ]
                },
                "BP128": {
                    "questionId": "BP128",
                    "chosenOptionIds": [
                        "6b43ea3f-8741-56e6-808f-129fe78b7440"
                    ]
                },
                "BP129": {
                    "questionId": "BP129",
                    "chosenOptionIds": [
                        "c534368a-9c51-5f0d-8b17-9df288fd59a0"
                    ]
                },
                "BP130": {
                    "questionId": "BP130",
                    "chosenOptionIds": [
                        "b4725e03-4f40-5305-9d3d-1ff9297f8bff"
                    ]
                },
                "BP131": {
                    "questionId": "BP131",
                    "chosenOptionIds": [
                        "e5863f5d-32a6-520a-9b40-8591adf1c1b8"
                    ]
                },
                "BP132": {
                    "questionId": "BP132",
                    "chosenOptionIds": [
                        "da9000ec-69f6-580b-9ec9-756b702c2962"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM35": {
                    "questionId": "HM35",
                    "chosenOptionIds": [
                        "2b40020d-33ba-5560-9075-d482aa324496"
                    ]
                },
                "HM36": {
                    "questionId": "HM36",
                    "chosenOptionIds": [
                        "47bfc26b-4237-5a82-9cc4-67d348c4acb0"
                    ]
                },
                "HM37": {
                    "questionId": "HM37",
                    "chosenOptionIds": [
                        "20de534d-f482-51c5-ad87-bd054787fddc"
                    ]
                },
                "HM38": {
                    "questionId": "HM38",
                    "chosenOptionIds": [
                        "5125bbbb-70fb-5adc-a41b-c65c6b96180d"
                    ]
                },
                "HM40": {
                    "questionId": "HM40",
                    "chosenOptionIds": [
                        "596144bf-d0ea-5d17-a2ae-7096c31e2def"
                    ]
                },
                "HM41": {
                    "questionId": "HM41",
                    "chosenOptionIds": [
                        "dd9cb999-d487-51f2-8ca3-f121593feca0"
                    ]
                },
                "HM43": {
                    "questionId": "HM43",
                    "chosenOptionIds": [
                        "a8b07611-dcc0-55ef-ae78-d4ceb7ad42e3"
                    ]
                },
                "HM44": {
                    "questionId": "HM44",
                    "chosenOptionIds": [
                        "3675f391-ff12-51e5-b53f-b851b05414ee"
                    ]
                },
                "HM45": {
                    "questionId": "HM45",
                    "chosenOptionIds": [
                        "28a20a2a-3318-5dba-9e59-86d943d3ac73"
                    ]
                },
                "HM46": {
                    "questionId": "HM46",
                    "chosenOptionIds": [
                        "a57434cf-7c45-5e3d-9d0d-c8b432490e99"
                    ]
                },
                "HM47": {
                    "questionId": "HM47",
                    "chosenOptionIds": [
                        "41ec9370-ba26-5cbc-ad35-d9e578788aa4"
                    ]
                },
                "HM48": {
                    "questionId": "HM48",
                    "chosenOptionIds": [
                        "c0e7db40-86e9-5cba-98b0-e42a3fc0835b"
                    ]
                },
                "HM49": {
                    "questionId": "HM49",
                    "chosenOptionIds": [
                        "ad65e31f-19f9-587c-bc99-0b3549cc5cb0"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: NELXPEV aspect: simple_bd1 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "0ec0e224-a5cf-5ece-b3ab-37061a600ace"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "45f0ce85-9e19-53da-bc0d-2af8b383318a"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "c0453e8e-7a9c-5bf5-b8a2-167a61a6387e"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "322210fb-135d-5ac8-9617-7fc4f85e016d"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 0.041666666666666664
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "913bc1e9-8da1-54c7-b935-13fc5d48fbd9"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "9222be3e-443f-5146-9d69-d48fe9041ba0"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "77c6088d-2d8f-5c3b-89c9-79cb0d317226"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "a449e4ae-663b-50fb-9b62-1708b0a32669"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "f3efc764-ca53-52c3-9c3a-38c7f787d415"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "ef6fddab-7a07-5f20-af20-74f2444eee53"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "3b216cf8-72e7-558c-bdd3-cc23a1865674"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "4896b504-2025-52c3-85e2-c6cf3205cef7"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "7599bcaa-89a5-5f71-80ea-b70c360bb44a"
                    ]
                },
                "BP101b": {
                    "questionId": "BP101b",
                    "chosenOptionIds": [
                        "cb80bcac-db78-55bd-b051-bdd00801fd54"
                    ]
                },
                "BP102b": {
                    "questionId": "BP102b",
                    "chosenOptionIds": [
                        "71338232-94be-5805-b822-94db8be9c5a1"
                    ]
                },
                "BP103b": {
                    "questionId": "BP103b",
                    "chosenOptionIds": [
                        "867b0ee4-73d9-5aac-b96d-bc2d85d5b183"
                    ]
                },
                "BP104b": {
                    "questionId": "BP104b",
                    "chosenOptionIds": [
                        "c59b5910-3279-509f-8175-f2fd0da2dc86"
                    ]
                },
                "BP87b": {
                    "questionId": "BP87b",
                    "chosenOptionIds": [
                        "7949bd96-3757-5573-bd89-3a91a383cc99"
                    ]
                },
                "BP88b": {
                    "questionId": "BP88b",
                    "chosenOptionIds": [
                        "fa24b924-a8e1-5f02-893b-3d9643007991"
                    ]
                },
                "BP89b": {
                    "questionId": "BP89b",
                    "chosenOptionIds": [
                        "41d2829a-3971-552f-8c5a-c77e7d0073e7"
                    ]
                },
                "BP90b": {
                    "questionId": "BP90b",
                    "chosenOptionIds": [
                        "95c2822d-27ad-5bb0-982f-8c2b949c8918"
                    ]
                },
                "BP92b": {
                    "questionId": "BP92b",
                    "chosenOptionIds": [
                        "b2f4c490-30df-5c8f-87c0-bc4acbcfb8e0"
                    ]
                },
                "BP94b": {
                    "questionId": "BP94b",
                    "chosenOptionIds": [
                        "038cb12b-2783-569a-b1d7-e28c65e591a3"
                    ]
                },
                "BP95b": {
                    "questionId": "BP95b",
                    "chosenOptionIds": [
                        "fb0bd4a7-2793-5b81-badc-fe81b4bc82dc"
                    ]
                },
                "BP96b": {
                    "questionId": "BP96b",
                    "chosenOptionIds": [
                        "8c3d3503-6334-55b9-b0f0-94c8e520094e"
                    ]
                },
                "BP97b": {
                    "questionId": "BP97b",
                    "chosenOptionIds": [
                        "6895096a-ef29-546e-9967-d7a23b4a2859"
                    ]
                },
                "BP98b": {
                    "questionId": "BP98b",
                    "chosenOptionIds": [
                        "01641419-fbfc-52a5-ae63-0fa736ff9847"
                    ]
                },
                "BP99.3b": {
                    "questionId": "BP99.3b",
                    "chosenOptionIds": [
                        "bf30a6c2-f8e2-5305-b4d4-7ad6227449d3"
                    ]
                },
                "BP99b": {
                    "questionId": "BP99b",
                    "chosenOptionIds": [
                        "68860df1-e835-50d6-8eb2-f9d9f2f97efa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "BP_neg1": {
                    "questionId": "BP_neg1",
                    "chosenOptionIds": [
                        "119208b0-ff3b-5c43-80be-4f07e65b6248"
                    ]
                }
            }
        },
        {
            "name": "Mood test: 73ZQN3R aspect: simple_bd1 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "0ec0e224-a5cf-5ece-b3ab-37061a600ace"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "3bd18f66-6f1e-5ea4-ae24-2990ed197bef"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "eb68a88d-b49b-5cde-a9c6-5e4c78e27d19"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "b74511c7-9f97-5c15-8bf5-6ec4357b4814"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 4
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "80c993c8-1a94-5684-a8f9-d8f5072c8e9c"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "cc243947-5067-5a98-ade6-a4e10d0b42cf"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "f110f5ec-75f1-51be-9c45-ea7685a54d64"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b",
                        "ba6dc2ba-3c20-57b6-a276-32c863109ff6",
                        "1d4beed1-78e5-531c-a255-643fac260dee"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "f3efc764-ca53-52c3-9c3a-38c7f787d415"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "08a0f83f-2f37-5904-9364-4e40deb90e98"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "5d271ac0-beca-56cc-9d0b-adafcd67da53"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "33a5fff0-9587-521f-bc53-0dacc36a0bb2"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "13f8bac1-d0f5-539b-8596-1c55821bea46"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "4896b504-2025-52c3-85e2-c6cf3205cef7"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "83e6efd5-64a2-5f0e-a973-9278eae90cfb"
                    ]
                },
                "BP10": {
                    "questionId": "BP10",
                    "chosenOptionIds": [
                        "7ad54f0a-87a6-5e82-bfc3-44b540230082"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "182e121d-415c-5f42-a76c-a996f5f38b3f"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "d8c1997f-d722-536c-ad9d-296b5e10ed04"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "d3224e16-1b0c-5266-9e14-ac72d2b2e988"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "f2228920-3fec-5097-a63d-990c25d50c6c"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP105": {
                    "questionId": "BP105",
                    "chosenOptionIds": [
                        "978f2ff4-94d9-5f7e-9126-e8657be0c7ca"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 25
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "c7bee7a0-3037-5eb1-96a1-85b884d7e1dd"
                    ]
                },
                "BP3.1": {
                    "questionId": "BP3.1",
                    "chosenOptionIds": [
                        "9531c3ff-ab2b-5198-a490-919ede5afb07"
                    ]
                },
                "BP3.2": {
                    "questionId": "BP3.2",
                    "chosenOptionIds": [
                        "ec333f8b-146a-5700-a15d-527032ceac3f"
                    ]
                },
                "BP3.3": {
                    "questionId": "BP3.3",
                    "freeValue": 4
                },
                "BP3.4": {
                    "questionId": "BP3.4",
                    "chosenOptionIds": [
                        "f304bf93-0c68-5da0-80ba-ccecc2d0fce1"
                    ]
                },
                "BP4": {
                    "questionId": "BP4",
                    "chosenOptionIds": [
                        "9765aba8-7d55-5928-b79d-36aa58646036"
                    ]
                },
                "BP4.5": {
                    "questionId": "BP4.5",
                    "chosenOptionIds": [
                        "66a3460c-65ce-5a4c-9774-2e303ae4961e"
                    ]
                },
                "BP6": {
                    "questionId": "BP6",
                    "chosenOptionIds": [
                        "33ae5abe-63ad-57d4-86a4-0dcd33576094"
                    ]
                },
                "BP6.1": {
                    "questionId": "BP6.1",
                    "chosenOptionIds": [
                        "a99773bd-2bff-523e-bc29-1f761307b3d4"
                    ]
                },
                "BP6.2": {
                    "questionId": "BP6.2",
                    "freeValue": 3
                },
                "BP6.3": {
                    "questionId": "BP6.3",
                    "chosenOptionIds": [
                        "a19e0696-e780-5235-88b9-f5c36ce0afc3"
                    ]
                },
                "BP8": {
                    "questionId": "BP8",
                    "chosenOptionIds": [
                        "9b3c44f8-4468-5f37-86e9-ed0908e71d5c"
                    ]
                },
                "BP8.0": {
                    "questionId": "BP8.0",
                    "chosenOptionIds": [
                        "3568cdb1-2500-512b-aada-2547c5bfd835"
                    ]
                },
                "BP8.01": {
                    "questionId": "BP8.01",
                    "chosenOptionIds": [
                        "5020bfdd-a280-56cf-b7f5-3b0b7e667165"
                    ]
                },
                "BP8.02": {
                    "questionId": "BP8.02",
                    "chosenOptionIds": [
                        "7d3a0aa5-605b-58d1-a834-073feefe8e5d"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "387b19c0-67d0-557e-9fba-ae065dcf3587"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "afb57366-c0b3-5f73-92eb-27bc133ad2ed"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "667ba57c-b1cd-5f9b-aa12-58e65a981910"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "a2056280-4b54-5258-8bb4-407b521fff4b"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "c211fabc-a62d-5440-8ddc-f57eb21d7679"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "cc7c09ae-1cb7-5b9a-9cd3-8d1012b90ee8"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "d680a8d9-e35f-563f-9d1d-e7c3136aff6d"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "940c16a8-1835-582a-9dad-bd52e3a49cc7"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "1e86d64a-b972-500a-9120-7884e4b86ede"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "dbab3669-e910-5267-b7b1-fcb661ea7e81"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "2723ed09-37f6-522c-92b1-e518d4fe9315"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "3cf77140-2757-58eb-b4ea-f2a90cd766e7"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "5c06d516-e816-58d6-997e-f8fa91131ac7"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "e81fe613-4923-55ed-bd6c-bd3f19e026f9"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM2": {
                    "questionId": "HM2",
                    "chosenOptionIds": [
                        "de662c3f-66b4-566f-856d-2124e2725542"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "98ea07a0-ae95-570f-8d11-a3e7c682e986"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "696fdd9d-5ced-504b-a933-bb3e575b0099"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c41974b6-d394-569c-8f45-49f79fcac5c6"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "43c32319-c75f-5169-b0e5-713820721d4f"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "b2cb304f-f035-53fc-8763-e2f91b031f9b"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "0170b4cf-0541-53cd-a9db-818d5067dc23"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "7b310da1-94e3-5d45-a6c3-3905eeb28ec6"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "2a17c2fe-2592-58b1-98e9-3dc4168fe3e3"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "834b04f2-af09-5337-8fd5-be4a70c99e75"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "fa32b497-4a4b-5076-a391-b6dffa8e1851"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "8823c74f-ff7c-52d8-9c73-fd3b4180f48a"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "1d07911e-929d-5719-aeea-a68f593d18e8"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: NELRPEV aspect: simple_bd1 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "0ec0e224-a5cf-5ece-b3ab-37061a600ace"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "a16e8e14-524e-53df-b4ef-730a2c8e5f32"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "c0453e8e-7a9c-5bf5-b8a2-167a61a6387e"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "b74511c7-9f97-5c15-8bf5-6ec4357b4814"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 60
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "913bc1e9-8da1-54c7-b935-13fc5d48fbd9"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "11f457f5-4c39-5458-ba9a-84478dd379ba"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "77c6088d-2d8f-5c3b-89c9-79cb0d317226"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b",
                        "1d4beed1-78e5-531c-a255-643fac260dee"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "c9813959-ba61-5220-9e27-659abb256037"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "3e093dd3-857e-5004-a4fb-961fbfa59ece"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "adecf2d3-f302-50f8-a4a9-3ffac1789bc6"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "d362cd87-7515-551e-bc74-19ef903484aa"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "44fd9eb3-aee2-5af0-b83c-dbbbf9514022"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "83e6efd5-64a2-5f0e-a973-9278eae90cfb"
                    ]
                },
                "BP10": {
                    "questionId": "BP10",
                    "chosenOptionIds": [
                        "7ad54f0a-87a6-5e82-bfc3-44b540230082"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "4a0f1c4d-38cf-5de4-99e3-0ee818f9709c"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "e9eb902a-d327-5ec5-938c-dc317ca59b13"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "1daccd4c-e5ff-5f18-ba55-7c2d2d93b849"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "b24df75b-6bb9-5f5f-99e3-c883ec3644ec"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 15
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "c7bee7a0-3037-5eb1-96a1-85b884d7e1dd"
                    ]
                },
                "BP3.1": {
                    "questionId": "BP3.1",
                    "chosenOptionIds": [
                        "5bf1e313-2624-5b5f-9673-37ae9319ff0c"
                    ]
                },
                "BP3.2": {
                    "questionId": "BP3.2",
                    "chosenOptionIds": [
                        "43011291-20a8-59ce-8bac-df882587ff5c"
                    ]
                },
                "BP3.3": {
                    "questionId": "BP3.3",
                    "freeValue": 1
                },
                "BP3.4": {
                    "questionId": "BP3.4",
                    "chosenOptionIds": [
                        "f304bf93-0c68-5da0-80ba-ccecc2d0fce1"
                    ]
                },
                "BP4": {
                    "questionId": "BP4",
                    "chosenOptionIds": [
                        "9765aba8-7d55-5928-b79d-36aa58646036"
                    ]
                },
                "BP4.5": {
                    "questionId": "BP4.5",
                    "chosenOptionIds": [
                        "66a3460c-65ce-5a4c-9774-2e303ae4961e"
                    ]
                },
                "BP6": {
                    "questionId": "BP6",
                    "chosenOptionIds": [
                        "742a22d6-a570-5e3a-8e72-c001f1e9f98b"
                    ]
                },
                "BP6.1": {
                    "questionId": "BP6.1",
                    "chosenOptionIds": [
                        "77b4d620-93e4-5bc5-baad-4995d168f392"
                    ]
                },
                "BP6.2": {
                    "questionId": "BP6.2",
                    "freeValue": 0.08333333333333333
                },
                "BP6.3": {
                    "questionId": "BP6.3",
                    "chosenOptionIds": [
                        "a19e0696-e780-5235-88b9-f5c36ce0afc3"
                    ]
                },
                "BP8": {
                    "questionId": "BP8",
                    "chosenOptionIds": [
                        "9b3c44f8-4468-5f37-86e9-ed0908e71d5c"
                    ]
                },
                "BP8.0": {
                    "questionId": "BP8.0",
                    "chosenOptionIds": [
                        "3568cdb1-2500-512b-aada-2547c5bfd835"
                    ]
                },
                "BP8.01": {
                    "questionId": "BP8.01",
                    "chosenOptionIds": [
                        "5020bfdd-a280-56cf-b7f5-3b0b7e667165"
                    ]
                },
                "BP8.02": {
                    "questionId": "BP8.02",
                    "chosenOptionIds": [
                        "e88670a3-c6d8-5c96-914e-b9fc1818aeda"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "387b19c0-67d0-557e-9fba-ae065dcf3587"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "afb57366-c0b3-5f73-92eb-27bc133ad2ed"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "667ba57c-b1cd-5f9b-aa12-58e65a981910"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "a2056280-4b54-5258-8bb4-407b521fff4b"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "e22cf233-c174-52fb-af55-97bf9c46e971"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "46c90446-3ae9-53f5-9b57-ad19d66b5be6"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "ef5a7b22-b2b2-5a5b-8a80-ce5c251033fd"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "82997889-d795-572b-a597-e484ef078b39"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "201c7ee3-56dc-51e9-ad55-69951192ab16"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "6928c696-e6d7-5a14-a004-15a497ac6562"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "2723ed09-37f6-522c-92b1-e518d4fe9315"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "3cf77140-2757-58eb-b4ea-f2a90cd766e7"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "5c06d516-e816-58d6-997e-f8fa91131ac7"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "c0cc09c3-1335-5eb4-8801-b3cdaf74f6fa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM2": {
                    "questionId": "HM2",
                    "chosenOptionIds": [
                        "de662c3f-66b4-566f-856d-2124e2725542"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "98ea07a0-ae95-570f-8d11-a3e7c682e986"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "a43647cb-a0e8-5705-adb9-a2b68877dc77"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c41974b6-d394-569c-8f45-49f79fcac5c6"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "3fd957d1-0cbb-5397-9812-353be5c2184e"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "b2cb304f-f035-53fc-8763-e2f91b031f9b"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "0170b4cf-0541-53cd-a9db-818d5067dc23"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "6b35cd3b-d67a-54af-94f6-e39ddf5af6c6"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "2a17c2fe-2592-58b1-98e9-3dc4168fe3e3"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "834b04f2-af09-5337-8fd5-be4a70c99e75"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "86277249-cf50-5f91-a27f-f3a69344e796"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "e95276b0-ee81-580b-b532-b0215f04abd5"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "4b8e4843-d260-59e5-8468-1303b1c3a638"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: N0N6P0Q aspect: simple_bd1 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "a16e8e14-524e-53df-b4ef-730a2c8e5f32"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "916d657d-9f2a-5451-8465-18e3bd863347"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "a0e528ca-2fbb-5da1-9398-0758f79755aa"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 540
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "db360e93-3616-5d89-be36-5ccee4aacf1c"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "a9525e10-4e34-5d6b-9c93-c7546ec49231"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "11f457f5-4c39-5458-ba9a-84478dd379ba"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "db7514e2-d317-55fb-82d3-2e4a49c2494b"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b",
                        "1d4beed1-78e5-531c-a255-643fac260dee"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "c9813959-ba61-5220-9e27-659abb256037"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "3e093dd3-857e-5004-a4fb-961fbfa59ece"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "adecf2d3-f302-50f8-a4a9-3ffac1789bc6"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "d362cd87-7515-551e-bc74-19ef903484aa"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "4896b504-2025-52c3-85e2-c6cf3205cef7"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "7599bcaa-89a5-5f71-80ea-b70c360bb44a"
                    ]
                },
                "BP101b": {
                    "questionId": "BP101b",
                    "chosenOptionIds": [
                        "31b2d2c8-8f28-514e-9e58-aa878f6c8761"
                    ]
                },
                "BP102b": {
                    "questionId": "BP102b",
                    "chosenOptionIds": [
                        "71338232-94be-5805-b822-94db8be9c5a1"
                    ]
                },
                "BP103b": {
                    "questionId": "BP103b",
                    "chosenOptionIds": [
                        "867b0ee4-73d9-5aac-b96d-bc2d85d5b183"
                    ]
                },
                "BP104b": {
                    "questionId": "BP104b",
                    "chosenOptionIds": [
                        "c59b5910-3279-509f-8175-f2fd0da2dc86"
                    ]
                },
                "BP87b": {
                    "questionId": "BP87b",
                    "chosenOptionIds": [
                        "659c0d9a-389d-5b62-b3ac-e385dfafe63c"
                    ]
                },
                "BP88b": {
                    "questionId": "BP88b",
                    "chosenOptionIds": [
                        "e29e159f-f691-5aa7-b5a0-d13fe2c54170"
                    ]
                },
                "BP89b": {
                    "questionId": "BP89b",
                    "chosenOptionIds": [
                        "db509963-d872-5b97-ba9b-a276f58e0189"
                    ]
                },
                "BP90b": {
                    "questionId": "BP90b",
                    "chosenOptionIds": [
                        "95c2822d-27ad-5bb0-982f-8c2b949c8918"
                    ]
                },
                "BP92b": {
                    "questionId": "BP92b",
                    "chosenOptionIds": [
                        "f59918ba-eb3a-5cf1-81aa-8b7f6ac0df5e"
                    ]
                },
                "BP94b": {
                    "questionId": "BP94b",
                    "chosenOptionIds": [
                        "038cb12b-2783-569a-b1d7-e28c65e591a3"
                    ]
                },
                "BP95b": {
                    "questionId": "BP95b",
                    "chosenOptionIds": [
                        "fb0bd4a7-2793-5b81-badc-fe81b4bc82dc"
                    ]
                },
                "BP96b": {
                    "questionId": "BP96b",
                    "chosenOptionIds": [
                        "8c3d3503-6334-55b9-b0f0-94c8e520094e"
                    ]
                },
                "BP97b": {
                    "questionId": "BP97b",
                    "chosenOptionIds": [
                        "6895096a-ef29-546e-9967-d7a23b4a2859"
                    ]
                },
                "BP98b": {
                    "questionId": "BP98b",
                    "chosenOptionIds": [
                        "01641419-fbfc-52a5-ae63-0fa736ff9847"
                    ]
                },
                "BP99.1b": {
                    "questionId": "BP99.1b",
                    "chosenOptionIds": [
                        "255f682d-5067-5a6d-835a-fe587b4169e6"
                    ]
                },
                "BP99.3b": {
                    "questionId": "BP99.3b",
                    "chosenOptionIds": [
                        "e4575ba2-20f0-519e-967b-d5769763cadc"
                    ]
                },
                "BP99b": {
                    "questionId": "BP99b",
                    "chosenOptionIds": [
                        "96faf550-f157-55b9-8f15-6be82cd511a8"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "BP_neg1": {
                    "questionId": "BP_neg1",
                    "chosenOptionIds": [
                        "119208b0-ff3b-5c43-80be-4f07e65b6248"
                    ]
                }
            }
        },
        {
            "name": "Mood test: K39V10L aspect: simple_bd1 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "0ec0e224-a5cf-5ece-b3ab-37061a600ace"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "45f0ce85-9e19-53da-bc0d-2af8b383318a"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "c0453e8e-7a9c-5bf5-b8a2-167a61a6387e"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "322210fb-135d-5ac8-9617-7fc4f85e016d"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 1
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "913bc1e9-8da1-54c7-b935-13fc5d48fbd9"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "9222be3e-443f-5146-9d69-d48fe9041ba0"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "77c6088d-2d8f-5c3b-89c9-79cb0d317226"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "1d4beed1-78e5-531c-a255-643fac260dee"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "f3efc764-ca53-52c3-9c3a-38c7f787d415"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "228ef7d9-008b-559d-ba14-6b928b486b38"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "3b216cf8-72e7-558c-bdd3-cc23a1865674"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "f52974b4-d0c2-589c-88d8-8628f43a2f5e",
                        "07106ab9-5949-5b5f-bc96-117139ebd397",
                        "44fd9eb3-aee2-5af0-b83c-dbbbf9514022"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "ccbdcbec-96d0-5362-977b-784289f5cfc0"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "4a0f1c4d-38cf-5de4-99e3-0ee818f9709c"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "e9eb902a-d327-5ec5-938c-dc317ca59b13"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "d3224e16-1b0c-5266-9e14-ac72d2b2e988"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "f2228920-3fec-5097-a63d-990c25d50c6c"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP105": {
                    "questionId": "BP105",
                    "chosenOptionIds": [
                        "64aa1680-6b5b-5a5b-bb92-75995d27ddcd"
                    ]
                },
                "BP12": {
                    "questionId": "BP12",
                    "chosenOptionIds": [
                        "1d6299ac-2ac6-51e2-9a3f-13e8d44e652a"
                    ]
                },
                "BP12.5": {
                    "questionId": "BP12.5",
                    "chosenOptionIds": [
                        "cd7ce00f-4e7c-57ac-9c6c-4137efa24c8c"
                    ]
                },
                "BP14": {
                    "questionId": "BP14",
                    "chosenOptionIds": [
                        "a3126449-b062-5872-80a1-5950acf6bfa4"
                    ]
                },
                "BP14.1": {
                    "questionId": "BP14.1",
                    "chosenOptionIds": [
                        "69f9a784-ce6d-5c82-bd3f-4c9c44bf5992"
                    ]
                },
                "BP14.2": {
                    "questionId": "BP14.2",
                    "freeValue": 21
                },
                "BP14.3": {
                    "questionId": "BP14.3",
                    "chosenOptionIds": [
                        "9f5fc152-75fb-59d8-8f40-7a6ca15c69cb"
                    ]
                },
                "BP16": {
                    "questionId": "BP16",
                    "chosenOptionIds": [
                        "fabceeea-8fea-5444-8b67-8f189f366c27"
                    ]
                },
                "BP16.0": {
                    "questionId": "BP16.0",
                    "chosenOptionIds": [
                        "4b26caa5-9124-51b2-ab08-2f4e977cee1f"
                    ]
                },
                "BP16.01": {
                    "questionId": "BP16.01",
                    "chosenOptionIds": [
                        "f81655d7-78e5-59b1-a7eb-90a2ec7a107d"
                    ]
                },
                "BP16.02": {
                    "questionId": "BP16.02",
                    "chosenOptionIds": [
                        "16a695c9-3ac1-5d34-b799-44602d85b556"
                    ]
                },
                "BP18": {
                    "questionId": "BP18",
                    "chosenOptionIds": [
                        "b577f323-ec54-5d25-a2b9-4f8de30d60a2"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 59
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "f03de28d-8f71-5af4-be2f-45f4e6092a3d"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "387b19c0-67d0-557e-9fba-ae065dcf3587"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "afb57366-c0b3-5f73-92eb-27bc133ad2ed"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "667ba57c-b1cd-5f9b-aa12-58e65a981910"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "5d5fa968-aeb3-569a-8a71-483ef04b9a6a"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "c211fabc-a62d-5440-8ddc-f57eb21d7679"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "46c90446-3ae9-53f5-9b57-ad19d66b5be6"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "d680a8d9-e35f-563f-9d1d-e7c3136aff6d"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "82997889-d795-572b-a597-e484ef078b39"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "ec84966e-bb9f-5ba9-a0cf-d5d3503a74e0"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "6928c696-e6d7-5a14-a004-15a497ac6562"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "4a7683c6-774e-5362-8060-740f61986c9b"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "58777a2e-4b97-5413-b340-efe722e45936"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "5c06d516-e816-58d6-997e-f8fa91131ac7"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "c0cc09c3-1335-5eb4-8801-b3cdaf74f6fa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM19": {
                    "questionId": "HM19",
                    "chosenOptionIds": [
                        "99f474d7-d509-592a-856a-afed343fb1b8"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "c3ee8981-dec3-5b3c-80ca-c1023f983a6d"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "696fdd9d-5ced-504b-a933-bb3e575b0099"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c41974b6-d394-569c-8f45-49f79fcac5c6"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "43c32319-c75f-5169-b0e5-713820721d4f"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "b2cb304f-f035-53fc-8763-e2f91b031f9b"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "0170b4cf-0541-53cd-a9db-818d5067dc23"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "6b35cd3b-d67a-54af-94f6-e39ddf5af6c6"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "2a17c2fe-2592-58b1-98e9-3dc4168fe3e3"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "834b04f2-af09-5337-8fd5-be4a70c99e75"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "86277249-cf50-5f91-a27f-f3a69344e796"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "8823c74f-ff7c-52d8-9c73-fd3b4180f48a"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "1d07911e-929d-5719-aeea-a68f593d18e8"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: N0NN50Q aspect: simple_bd1 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "45f0ce85-9e19-53da-bc0d-2af8b383318a"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "916d657d-9f2a-5451-8465-18e3bd863347"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "b74511c7-9f97-5c15-8bf5-6ec4357b4814"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 0.125
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "7a5958fc-e0f7-5d43-9cd0-abfe95cb7026"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "a9525e10-4e34-5d6b-9c93-c7546ec49231"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "11f457f5-4c39-5458-ba9a-84478dd379ba"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5c30c60e-d53e-5cc3-b4a9-fdad9bec4cb7"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "1d4beed1-78e5-531c-a255-643fac260dee",
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "f3efc764-ca53-52c3-9c3a-38c7f787d415"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "228ef7d9-008b-559d-ba14-6b928b486b38"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "d362cd87-7515-551e-bc74-19ef903484aa"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "4896b504-2025-52c3-85e2-c6cf3205cef7"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "7599bcaa-89a5-5f71-80ea-b70c360bb44a"
                    ]
                },
                "BP101b": {
                    "questionId": "BP101b",
                    "chosenOptionIds": [
                        "31b2d2c8-8f28-514e-9e58-aa878f6c8761"
                    ]
                },
                "BP102b": {
                    "questionId": "BP102b",
                    "chosenOptionIds": [
                        "00afc1e6-b78a-5223-a6e7-5d3aaab833e0"
                    ]
                },
                "BP103b": {
                    "questionId": "BP103b",
                    "chosenOptionIds": [
                        "867b0ee4-73d9-5aac-b96d-bc2d85d5b183"
                    ]
                },
                "BP104b": {
                    "questionId": "BP104b",
                    "chosenOptionIds": [
                        "c59b5910-3279-509f-8175-f2fd0da2dc86"
                    ]
                },
                "BP87b": {
                    "questionId": "BP87b",
                    "chosenOptionIds": [
                        "659c0d9a-389d-5b62-b3ac-e385dfafe63c"
                    ]
                },
                "BP88b": {
                    "questionId": "BP88b",
                    "chosenOptionIds": [
                        "e29e159f-f691-5aa7-b5a0-d13fe2c54170"
                    ]
                },
                "BP89b": {
                    "questionId": "BP89b",
                    "chosenOptionIds": [
                        "df5f6aa3-ab8c-5a4c-b9d9-e9a482aba1d7"
                    ]
                },
                "BP90b": {
                    "questionId": "BP90b",
                    "chosenOptionIds": [
                        "b4a79888-68e8-5b52-9a4e-2149be85f6e7"
                    ]
                },
                "BP92b": {
                    "questionId": "BP92b",
                    "chosenOptionIds": [
                        "dd87a55d-829b-54a2-a63d-5b8b634688ea"
                    ]
                },
                "BP94b": {
                    "questionId": "BP94b",
                    "chosenOptionIds": [
                        "038cb12b-2783-569a-b1d7-e28c65e591a3"
                    ]
                },
                "BP95b": {
                    "questionId": "BP95b",
                    "chosenOptionIds": [
                        "fb0bd4a7-2793-5b81-badc-fe81b4bc82dc"
                    ]
                },
                "BP96b": {
                    "questionId": "BP96b",
                    "chosenOptionIds": [
                        "477d4084-be50-50ae-9e88-dc9e03cdad29"
                    ]
                },
                "BP97b": {
                    "questionId": "BP97b",
                    "chosenOptionIds": [
                        "6895096a-ef29-546e-9967-d7a23b4a2859"
                    ]
                },
                "BP98b": {
                    "questionId": "BP98b",
                    "chosenOptionIds": [
                        "01641419-fbfc-52a5-ae63-0fa736ff9847"
                    ]
                },
                "BP99.3b": {
                    "questionId": "BP99.3b",
                    "chosenOptionIds": [
                        "d0adef9f-05e3-5ddf-a0f3-60eeb50d3862"
                    ]
                },
                "BP99b": {
                    "questionId": "BP99b",
                    "chosenOptionIds": [
                        "68860df1-e835-50d6-8eb2-f9d9f2f97efa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "BP_neg1": {
                    "questionId": "BP_neg1",
                    "chosenOptionIds": [
                        "119208b0-ff3b-5c43-80be-4f07e65b6248"
                    ]
                }
            }
        },
        {
            "name": "Mood test: 4EGQQ0P aspect: simple_bd1 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "0ec0e224-a5cf-5ece-b3ab-37061a600ace"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "a16e8e14-524e-53df-b4ef-730a2c8e5f32"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "916d657d-9f2a-5451-8465-18e3bd863347"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "b74511c7-9f97-5c15-8bf5-6ec4357b4814"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 365
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "913bc1e9-8da1-54c7-b935-13fc5d48fbd9"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "11f457f5-4c39-5458-ba9a-84478dd379ba"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5c30c60e-d53e-5cc3-b4a9-fdad9bec4cb7"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "1d4beed1-78e5-531c-a255-643fac260dee",
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b",
                        "9d1b858c-7064-5a84-80d6-786c18e603b6"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "f3efc764-ca53-52c3-9c3a-38c7f787d415"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "9506449e-b2aa-5498-b896-901dd248b1b3"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "228ef7d9-008b-559d-ba14-6b928b486b38"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "d362cd87-7515-551e-bc74-19ef903484aa"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "07106ab9-5949-5b5f-bc96-117139ebd397"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "83e6efd5-64a2-5f0e-a973-9278eae90cfb"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "4a0f1c4d-38cf-5de4-99e3-0ee818f9709c"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "6a5aa899-7b89-5661-a554-71b6058ad4a4"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "d3224e16-1b0c-5266-9e14-ac72d2b2e988"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "b24df75b-6bb9-5f5f-99e3-c883ec3644ec"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 19
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "c7bee7a0-3037-5eb1-96a1-85b884d7e1dd"
                    ]
                },
                "BP3.1": {
                    "questionId": "BP3.1",
                    "chosenOptionIds": [
                        "5bf1e313-2624-5b5f-9673-37ae9319ff0c"
                    ]
                },
                "BP3.2": {
                    "questionId": "BP3.2",
                    "chosenOptionIds": [
                        "f6654515-5729-5299-a4f0-da99056e7ac9"
                    ]
                },
                "BP3.3": {
                    "questionId": "BP3.3",
                    "freeValue": 30
                },
                "BP3.4": {
                    "questionId": "BP3.4",
                    "chosenOptionIds": [
                        "f304bf93-0c68-5da0-80ba-ccecc2d0fce1"
                    ]
                },
                "BP4": {
                    "questionId": "BP4",
                    "chosenOptionIds": [
                        "4884cc9c-7210-5173-9438-d1eae9e9a6a8"
                    ]
                },
                "BP4.5": {
                    "questionId": "BP4.5",
                    "chosenOptionIds": [
                        "1be4b3b1-3a88-5544-af54-ebdfee1ce891"
                    ]
                },
                "BP6.5": {
                    "questionId": "BP6.5",
                    "chosenOptionIds": [
                        "58656f9f-9267-5c1d-9132-f342d05dc39e"
                    ]
                },
                "BP6.7": {
                    "questionId": "BP6.7",
                    "chosenOptionIds": [
                        "ef07ce6c-9277-581a-bde7-14889359301b"
                    ]
                },
                "BP6.8": {
                    "questionId": "BP6.8",
                    "freeValue": 30
                },
                "BP6.9": {
                    "questionId": "BP6.9",
                    "chosenOptionIds": [
                        "155da231-22f3-50d9-8786-2860edd50aab"
                    ]
                },
                "BP8": {
                    "questionId": "BP8",
                    "chosenOptionIds": [
                        "87e0081b-9455-5ced-9225-10c2f70b1734"
                    ]
                },
                "BP8.0": {
                    "questionId": "BP8.0",
                    "chosenOptionIds": [
                        "3568cdb1-2500-512b-aada-2547c5bfd835"
                    ]
                },
                "BP8.01": {
                    "questionId": "BP8.01",
                    "chosenOptionIds": [
                        "5020bfdd-a280-56cf-b7f5-3b0b7e667165"
                    ]
                },
                "BP8.02": {
                    "questionId": "BP8.02",
                    "chosenOptionIds": [
                        "e88670a3-c6d8-5c96-914e-b9fc1818aeda"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "ad1cfcc2-c1dc-57ef-b964-17d355fa0402"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "a4ed572c-6aae-57f6-bc35-ea46929c1437"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "28c75e9e-94ef-5c50-8645-f4ff93ccf2ec"
                    ]
                },
                "BP9": {
                    "questionId": "BP9",
                    "chosenOptionIds": [
                        "becfe35c-2096-5848-87ae-3f763cf59dd6"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "5d5fa968-aeb3-569a-8a71-483ef04b9a6a"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "e22cf233-c174-52fb-af55-97bf9c46e971"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "46c90446-3ae9-53f5-9b57-ad19d66b5be6"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "ef5a7b22-b2b2-5a5b-8a80-ce5c251033fd"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "c88e3fb5-913a-5c5c-a42b-603f7bb60a40"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "732c51f9-a0b6-5951-bd7d-eaaec24316ba"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "6928c696-e6d7-5a14-a004-15a497ac6562"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "2723ed09-37f6-522c-92b1-e518d4fe9315"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "3cf77140-2757-58eb-b4ea-f2a90cd766e7"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "63509127-aadf-5fe2-8e1f-19d0d846d6ce"
                    ]
                },
                "BP99.1": {
                    "questionId": "BP99.1",
                    "chosenOptionIds": [
                        "34d19f12-207f-56ce-bde8-8d616f6e8b26"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "e81fe613-4923-55ed-bd6c-bd3f19e026f9"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM1": {
                    "questionId": "HM1",
                    "chosenOptionIds": [
                        "60caa355-cd97-591a-9344-1e9858939855"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "98ea07a0-ae95-570f-8d11-a3e7c682e986"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "a43647cb-a0e8-5705-adb9-a2b68877dc77"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c41974b6-d394-569c-8f45-49f79fcac5c6"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "43c32319-c75f-5169-b0e5-713820721d4f"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "b2cb304f-f035-53fc-8763-e2f91b031f9b"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "0170b4cf-0541-53cd-a9db-818d5067dc23"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "2be66549-5767-51b4-82ed-deeab0723f8d"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "2a17c2fe-2592-58b1-98e9-3dc4168fe3e3"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "834b04f2-af09-5337-8fd5-be4a70c99e75"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "fda00d52-c8be-5b69-bdbd-905c13dddd4e"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "8823c74f-ff7c-52d8-9c73-fd3b4180f48a"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "1d07911e-929d-5719-aeea-a68f593d18e8"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: 73ZJN0R aspect: simple_bd1 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "45f0ce85-9e19-53da-bc0d-2af8b383318a"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "916d657d-9f2a-5451-8465-18e3bd863347"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "b74511c7-9f97-5c15-8bf5-6ec4357b4814"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 0.08333333333333333
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "913bc1e9-8da1-54c7-b935-13fc5d48fbd9"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "a9525e10-4e34-5d6b-9c93-c7546ec49231"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "11f457f5-4c39-5458-ba9a-84478dd379ba"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5c30c60e-d53e-5cc3-b4a9-fdad9bec4cb7"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "9d1b858c-7064-5a84-80d6-786c18e603b6"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "f3efc764-ca53-52c3-9c3a-38c7f787d415"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "228ef7d9-008b-559d-ba14-6b928b486b38"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "3b216cf8-72e7-558c-bdd3-cc23a1865674"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "4896b504-2025-52c3-85e2-c6cf3205cef7"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "ccbdcbec-96d0-5362-977b-784289f5cfc0"
                    ]
                },
                "BP10": {
                    "questionId": "BP10",
                    "chosenOptionIds": [
                        "7ad54f0a-87a6-5e82-bfc3-44b540230082"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "4a0f1c4d-38cf-5de4-99e3-0ee818f9709c"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "e9eb902a-d327-5ec5-938c-dc317ca59b13"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "d3224e16-1b0c-5266-9e14-ac72d2b2e988"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "b24df75b-6bb9-5f5f-99e3-c883ec3644ec"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 39
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "c7bee7a0-3037-5eb1-96a1-85b884d7e1dd"
                    ]
                },
                "BP3.1": {
                    "questionId": "BP3.1",
                    "chosenOptionIds": [
                        "5bf1e313-2624-5b5f-9673-37ae9319ff0c"
                    ]
                },
                "BP3.2": {
                    "questionId": "BP3.2",
                    "chosenOptionIds": [
                        "ec333f8b-146a-5700-a15d-527032ceac3f"
                    ]
                },
                "BP3.3": {
                    "questionId": "BP3.3",
                    "freeValue": 0.08333333333333333
                },
                "BP3.4": {
                    "questionId": "BP3.4",
                    "chosenOptionIds": [
                        "f304bf93-0c68-5da0-80ba-ccecc2d0fce1"
                    ]
                },
                "BP4": {
                    "questionId": "BP4",
                    "chosenOptionIds": [
                        "9765aba8-7d55-5928-b79d-36aa58646036"
                    ]
                },
                "BP4.5": {
                    "questionId": "BP4.5",
                    "chosenOptionIds": [
                        "66a3460c-65ce-5a4c-9774-2e303ae4961e"
                    ]
                },
                "BP6": {
                    "questionId": "BP6",
                    "chosenOptionIds": [
                        "742a22d6-a570-5e3a-8e72-c001f1e9f98b"
                    ]
                },
                "BP6.1": {
                    "questionId": "BP6.1",
                    "chosenOptionIds": [
                        "430b68c6-29cf-5a15-ab4c-89bd842383bf"
                    ]
                },
                "BP6.2": {
                    "questionId": "BP6.2",
                    "freeValue": 0.08333333333333333
                },
                "BP6.3": {
                    "questionId": "BP6.3",
                    "chosenOptionIds": [
                        "a19e0696-e780-5235-88b9-f5c36ce0afc3"
                    ]
                },
                "BP8": {
                    "questionId": "BP8",
                    "chosenOptionIds": [
                        "87e0081b-9455-5ced-9225-10c2f70b1734"
                    ]
                },
                "BP8.0": {
                    "questionId": "BP8.0",
                    "chosenOptionIds": [
                        "3568cdb1-2500-512b-aada-2547c5bfd835"
                    ]
                },
                "BP8.01": {
                    "questionId": "BP8.01",
                    "chosenOptionIds": [
                        "5020bfdd-a280-56cf-b7f5-3b0b7e667165"
                    ]
                },
                "BP8.02": {
                    "questionId": "BP8.02",
                    "chosenOptionIds": [
                        "e88670a3-c6d8-5c96-914e-b9fc1818aeda"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "387b19c0-67d0-557e-9fba-ae065dcf3587"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "afb57366-c0b3-5f73-92eb-27bc133ad2ed"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "667ba57c-b1cd-5f9b-aa12-58e65a981910"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "fd30dc27-8e89-5d1a-9d95-8844603d4379"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "e22cf233-c174-52fb-af55-97bf9c46e971"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "46c90446-3ae9-53f5-9b57-ad19d66b5be6"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "d680a8d9-e35f-563f-9d1d-e7c3136aff6d"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "94795194-4a80-590f-be62-ee78f2fa0ff5"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "732c51f9-a0b6-5951-bd7d-eaaec24316ba"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "6928c696-e6d7-5a14-a004-15a497ac6562"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "17b4da6f-7135-523c-b90b-2fe3b66785ff"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "3cf77140-2757-58eb-b4ea-f2a90cd766e7"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "5c06d516-e816-58d6-997e-f8fa91131ac7"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "c0cc09c3-1335-5eb4-8801-b3cdaf74f6fa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM2": {
                    "questionId": "HM2",
                    "chosenOptionIds": [
                        "de662c3f-66b4-566f-856d-2124e2725542"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "98ea07a0-ae95-570f-8d11-a3e7c682e986"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "696fdd9d-5ced-504b-a933-bb3e575b0099"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c41974b6-d394-569c-8f45-49f79fcac5c6"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "43c32319-c75f-5169-b0e5-713820721d4f"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "b2cb304f-f035-53fc-8763-e2f91b031f9b"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "0170b4cf-0541-53cd-a9db-818d5067dc23"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "2be66549-5767-51b4-82ed-deeab0723f8d"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "2a17c2fe-2592-58b1-98e9-3dc4168fe3e3"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "7e32475a-3fcc-51ac-b414-ab46578efdfd"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "86277249-cf50-5f91-a27f-f3a69344e796"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "8823c74f-ff7c-52d8-9c73-fd3b4180f48a"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "1d07911e-929d-5719-aeea-a68f593d18e8"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: 6EDLX3M aspect: simple_bd1 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "0ec0e224-a5cf-5ece-b3ab-37061a600ace"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "a16e8e14-524e-53df-b4ef-730a2c8e5f32"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "eb68a88d-b49b-5cde-a9c6-5e4c78e27d19"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "a0e528ca-2fbb-5da1-9398-0758f79755aa"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 14
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "913bc1e9-8da1-54c7-b935-13fc5d48fbd9"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "11f457f5-4c39-5458-ba9a-84478dd379ba"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "db7514e2-d317-55fb-82d3-2e4a49c2494b"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b",
                        "1d4beed1-78e5-531c-a255-643fac260dee",
                        "9d1b858c-7064-5a84-80d6-786c18e603b6"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "dde1af45-4686-52c9-b960-369527b28f6c"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "adecf2d3-f302-50f8-a4a9-3ffac1789bc6"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "d362cd87-7515-551e-bc74-19ef903484aa"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "4896b504-2025-52c3-85e2-c6cf3205cef7"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "83e6efd5-64a2-5f0e-a973-9278eae90cfb"
                    ]
                },
                "BP10": {
                    "questionId": "BP10",
                    "chosenOptionIds": [
                        "7ad54f0a-87a6-5e82-bfc3-44b540230082"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "db7aa4b7-99a5-5475-a4e8-a744f3ef5a4a"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "e9eb902a-d327-5ec5-938c-dc317ca59b13"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "1daccd4c-e5ff-5f18-ba55-7c2d2d93b849"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "b24df75b-6bb9-5f5f-99e3-c883ec3644ec"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 21
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "c7bee7a0-3037-5eb1-96a1-85b884d7e1dd"
                    ]
                },
                "BP3.1": {
                    "questionId": "BP3.1",
                    "chosenOptionIds": [
                        "c053dd45-79bd-59bf-82df-7e9ecd85dc11"
                    ]
                },
                "BP3.2": {
                    "questionId": "BP3.2",
                    "chosenOptionIds": [
                        "c3319113-403a-5ecb-aa8a-071221d92c50"
                    ]
                },
                "BP3.3": {
                    "questionId": "BP3.3",
                    "freeValue": 180
                },
                "BP3.4": {
                    "questionId": "BP3.4",
                    "chosenOptionIds": [
                        "f304bf93-0c68-5da0-80ba-ccecc2d0fce1"
                    ]
                },
                "BP4": {
                    "questionId": "BP4",
                    "chosenOptionIds": [
                        "9765aba8-7d55-5928-b79d-36aa58646036"
                    ]
                },
                "BP4.5": {
                    "questionId": "BP4.5",
                    "chosenOptionIds": [
                        "1be4b3b1-3a88-5544-af54-ebdfee1ce891"
                    ]
                },
                "BP6": {
                    "questionId": "BP6",
                    "chosenOptionIds": [
                        "742a22d6-a570-5e3a-8e72-c001f1e9f98b"
                    ]
                },
                "BP6.1": {
                    "questionId": "BP6.1",
                    "chosenOptionIds": [
                        "77b4d620-93e4-5bc5-baad-4995d168f392"
                    ]
                },
                "BP6.2": {
                    "questionId": "BP6.2",
                    "freeValue": 1
                },
                "BP6.3": {
                    "questionId": "BP6.3",
                    "chosenOptionIds": [
                        "df17fdbe-e756-5ece-ad3d-19cafeef31d1"
                    ]
                },
                "BP8": {
                    "questionId": "BP8",
                    "chosenOptionIds": [
                        "87e0081b-9455-5ced-9225-10c2f70b1734"
                    ]
                },
                "BP8.0": {
                    "questionId": "BP8.0",
                    "chosenOptionIds": [
                        "3568cdb1-2500-512b-aada-2547c5bfd835"
                    ]
                },
                "BP8.01": {
                    "questionId": "BP8.01",
                    "chosenOptionIds": [
                        "e12f28e7-b4ae-57af-96d7-8d83e672785a"
                    ]
                },
                "BP8.02": {
                    "questionId": "BP8.02",
                    "chosenOptionIds": [
                        "e88670a3-c6d8-5c96-914e-b9fc1818aeda"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "e620af65-9b5b-57e6-b13a-65616e67e084"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "a4ed572c-6aae-57f6-bc35-ea46929c1437"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "28c75e9e-94ef-5c50-8645-f4ff93ccf2ec"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "a2056280-4b54-5258-8bb4-407b521fff4b"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "d9fb150b-68b8-50ed-bb1d-eafd41f63f3b"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "46c90446-3ae9-53f5-9b57-ad19d66b5be6"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "63f0b17a-9832-5f0f-8630-e74f1a4fdfe7"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "f35fe383-d2aa-51c4-9fde-961b1de6d52b"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "2555d898-6736-5804-951e-e3c438313029"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "dbab3669-e910-5267-b7b1-fcb661ea7e81"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "4a7683c6-774e-5362-8060-740f61986c9b"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "b14d53e4-f898-52ac-87d7-3aa62abc9d7b"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "63509127-aadf-5fe2-8e1f-19d0d846d6ce"
                    ]
                },
                "BP99.1": {
                    "questionId": "BP99.1",
                    "chosenOptionIds": [
                        "74809c02-f47f-5955-a416-b40fc66ff214"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "a020ed85-ec9d-5778-8006-41f634cab09d"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM2": {
                    "questionId": "HM2",
                    "chosenOptionIds": [
                        "2b56aa7d-493f-5a18-aa9f-f97304f89130"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "6b99de8b-634a-5eb0-bc65-bdf30eb9d433"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "8f7acbc1-9ebe-5a52-95ce-b50d62c27345"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c41974b6-d394-569c-8f45-49f79fcac5c6"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "c8c8c192-aea3-5585-b11f-9d13faf4367d"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "65bee7d4-bf7f-5a21-9d92-f16651b0d1aa"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "366c6a1b-c8b2-5faf-9d2f-72dbde24e869"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "7b310da1-94e3-5d45-a6c3-3905eeb28ec6"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "73b26794-a969-527b-8268-f17c4e485bda"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "7e32475a-3fcc-51ac-b414-ab46578efdfd"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "fda00d52-c8be-5b69-bdbd-905c13dddd4e"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "e95276b0-ee81-580b-b532-b0215f04abd5"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "7d6921a2-7e63-5f12-9c75-dff8b592fabc"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: 73ZL2ER aspect: simple_bd1 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "0ec0e224-a5cf-5ece-b3ab-37061a600ace"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "3bd18f66-6f1e-5ea4-ae24-2990ed197bef"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "9b47900d-e5a7-5e58-830b-d9b3130a7990"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "a0e528ca-2fbb-5da1-9398-0758f79755aa"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 360
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "913bc1e9-8da1-54c7-b935-13fc5d48fbd9"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "de11fb6b-7e7d-52b1-9d09-2d6debc53314"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "db7514e2-d317-55fb-82d3-2e4a49c2494b"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b",
                        "1d4beed1-78e5-531c-a255-643fac260dee"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "c9813959-ba61-5220-9e27-659abb256037"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "228ef7d9-008b-559d-ba14-6b928b486b38"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "d362cd87-7515-551e-bc74-19ef903484aa"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "13f8bac1-d0f5-539b-8596-1c55821bea46"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "44fd9eb3-aee2-5af0-b83c-dbbbf9514022"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "83e6efd5-64a2-5f0e-a973-9278eae90cfb"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "db7aa4b7-99a5-5475-a4e8-a744f3ef5a4a"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "d8c1997f-d722-536c-ad9d-296b5e10ed04"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "1daccd4c-e5ff-5f18-ba55-7c2d2d93b849"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "f2228920-3fec-5097-a63d-990c25d50c6c"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP105": {
                    "questionId": "BP105",
                    "chosenOptionIds": [
                        "978f2ff4-94d9-5f7e-9126-e8657be0c7ca"
                    ]
                },
                "BP11": {
                    "questionId": "BP11",
                    "chosenOptionIds": [
                        "e54bdb41-2f8b-5524-b99b-415c31ee1576"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 15
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "c7bee7a0-3037-5eb1-96a1-85b884d7e1dd"
                    ]
                },
                "BP3.1": {
                    "questionId": "BP3.1",
                    "chosenOptionIds": [
                        "b56edfbb-cf11-50ee-9149-9e36f4cc1ea0"
                    ]
                },
                "BP3.2": {
                    "questionId": "BP3.2",
                    "chosenOptionIds": [
                        "ec333f8b-146a-5700-a15d-527032ceac3f"
                    ]
                },
                "BP3.3": {
                    "questionId": "BP3.3",
                    "freeValue": 3
                },
                "BP3.4": {
                    "questionId": "BP3.4",
                    "chosenOptionIds": [
                        "93412936-97c0-5128-ad67-3fa955db1300"
                    ]
                },
                "BP4": {
                    "questionId": "BP4",
                    "chosenOptionIds": [
                        "f4705d21-04ac-574a-90f3-5e73c089a3ff"
                    ]
                },
                "BP7": {
                    "questionId": "BP7",
                    "chosenOptionIds": [
                        "0c0ab687-08ee-5bfd-8795-5e0284c1e430"
                    ]
                },
                "BP7.1": {
                    "questionId": "BP7.1",
                    "chosenOptionIds": [
                        "6b62060b-0aee-5ec9-beef-85c908359e3d"
                    ]
                },
                "BP7.2": {
                    "questionId": "BP7.2",
                    "freeValue": 5
                },
                "BP7.3": {
                    "questionId": "BP7.3",
                    "chosenOptionIds": [
                        "455f915a-3cf9-5453-8bdb-a0fc0d91ca60"
                    ]
                },
                "BP8": {
                    "questionId": "BP8",
                    "chosenOptionIds": [
                        "87e0081b-9455-5ced-9225-10c2f70b1734"
                    ]
                },
                "BP8.0": {
                    "questionId": "BP8.0",
                    "chosenOptionIds": [
                        "3568cdb1-2500-512b-aada-2547c5bfd835"
                    ]
                },
                "BP8.01": {
                    "questionId": "BP8.01",
                    "chosenOptionIds": [
                        "a5d71cc4-3c6f-5e37-a507-1dfa312124b8"
                    ]
                },
                "BP8.02": {
                    "questionId": "BP8.02",
                    "chosenOptionIds": [
                        "1ff60197-2010-588f-bf6a-9be48f2b3359"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "e620af65-9b5b-57e6-b13a-65616e67e084"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "a4ed572c-6aae-57f6-bc35-ea46929c1437"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "2404146e-cd56-5356-a23b-6427b4bbba06"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "a2056280-4b54-5258-8bb4-407b521fff4b"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "33e48f82-ae80-5f20-a3a5-79692d21ad1a"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "0feda24f-7b01-557b-8ca5-10eb924cbdac"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "17619692-6d18-5558-99da-766a1c275e03"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "f35fe383-d2aa-51c4-9fde-961b1de6d52b"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "2555d898-6736-5804-951e-e3c438313029"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "9b27e3ff-f8ce-54c5-9220-0479ae53e889"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "4a7683c6-774e-5362-8060-740f61986c9b"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "58777a2e-4b97-5413-b340-efe722e45936"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "63509127-aadf-5fe2-8e1f-19d0d846d6ce"
                    ]
                },
                "BP99.1": {
                    "questionId": "BP99.1",
                    "chosenOptionIds": [
                        "34d19f12-207f-56ce-bde8-8d616f6e8b26"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "e81fe613-4923-55ed-bd6c-bd3f19e026f9"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "0f8903ea-258c-54c8-9d4d-643924d3c234"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "9d212885-9b07-5623-8234-226adff08f6d"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "3ab9515b-73f0-5fcf-a4d0-aebbc24c2721"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "0e9545ba-3f35-52b0-9a31-7fe54c933928"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "eb41dc92-16ec-5abf-a841-e1d0b262083e"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "57de31c8-9bf0-5003-9dbb-0e8559f994dd"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "7b310da1-94e3-5d45-a6c3-3905eeb28ec6"
                    ]
                },
                "HM3": {
                    "questionId": "HM3",
                    "chosenOptionIds": [
                        "7823432e-ad44-5fbe-9781-48d9ce136451"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "0983ccf5-a426-53d0-adbb-1baf6832190c"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "834b04f2-af09-5337-8fd5-be4a70c99e75"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "fda00d52-c8be-5b69-bdbd-905c13dddd4e"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "bde4e5f3-19e4-571f-84a2-5b9d188d74ef"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "4b8e4843-d260-59e5-8468-1303b1c3a638"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: XE7ZR3L aspect: simple_bd1 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "0ec0e224-a5cf-5ece-b3ab-37061a600ace"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "45f0ce85-9e19-53da-bc0d-2af8b383318a"
                    ]
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "7a5958fc-e0f7-5d43-9cd0-abfe95cb7026"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "9222be3e-443f-5146-9d69-d48fe9041ba0"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5c30c60e-d53e-5cc3-b4a9-fdad9bec4cb7"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b",
                        "ba6dc2ba-3c20-57b6-a276-32c863109ff6",
                        "9d1b858c-7064-5a84-80d6-786c18e603b6"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "c9813959-ba61-5220-9e27-659abb256037"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "ef6fddab-7a07-5f20-af20-74f2444eee53"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "3b216cf8-72e7-558c-bdd3-cc23a1865674"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "4896b504-2025-52c3-85e2-c6cf3205cef7"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "83e6efd5-64a2-5f0e-a973-9278eae90cfb"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "db7aa4b7-99a5-5475-a4e8-a744f3ef5a4a"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "e9eb902a-d327-5ec5-938c-dc317ca59b13"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "7230003d-a290-5a65-ae6b-b7d564b5db38"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "b24df75b-6bb9-5f5f-99e3-c883ec3644ec"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 17
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "c7bee7a0-3037-5eb1-96a1-85b884d7e1dd"
                    ]
                },
                "BP3.1": {
                    "questionId": "BP3.1",
                    "chosenOptionIds": [
                        "c31cf816-04e0-52f4-8da3-04437042c014"
                    ]
                },
                "BP3.2": {
                    "questionId": "BP3.2",
                    "chosenOptionIds": [
                        "c3319113-403a-5ecb-aa8a-071221d92c50"
                    ]
                },
                "BP3.3": {
                    "questionId": "BP3.3",
                    "freeValue": 7
                },
                "BP3.4": {
                    "questionId": "BP3.4",
                    "chosenOptionIds": [
                        "6ec82bbf-7582-5509-ace2-5ecf0ce82ec3"
                    ]
                },
                "BP4": {
                    "questionId": "BP4",
                    "chosenOptionIds": [
                        "4884cc9c-7210-5173-9438-d1eae9e9a6a8"
                    ]
                },
                "BP4.5": {
                    "questionId": "BP4.5",
                    "chosenOptionIds": [
                        "1be4b3b1-3a88-5544-af54-ebdfee1ce891"
                    ]
                },
                "BP6.5": {
                    "questionId": "BP6.5",
                    "chosenOptionIds": [
                        "e96c7a38-d74e-5087-b14b-21d3177ce73e"
                    ]
                },
                "BP6.7": {
                    "questionId": "BP6.7",
                    "chosenOptionIds": [
                        "ef07ce6c-9277-581a-bde7-14889359301b"
                    ]
                },
                "BP6.8": {
                    "questionId": "BP6.8",
                    "freeValue": 7
                },
                "BP6.9": {
                    "questionId": "BP6.9",
                    "chosenOptionIds": [
                        "75d51935-7fc0-5e05-813c-62bbdd8f8335"
                    ]
                },
                "BP8": {
                    "questionId": "BP8",
                    "chosenOptionIds": [
                        "87e0081b-9455-5ced-9225-10c2f70b1734"
                    ]
                },
                "BP8.0": {
                    "questionId": "BP8.0",
                    "chosenOptionIds": [
                        "3568cdb1-2500-512b-aada-2547c5bfd835"
                    ]
                },
                "BP8.01": {
                    "questionId": "BP8.01",
                    "chosenOptionIds": [
                        "5020bfdd-a280-56cf-b7f5-3b0b7e667165"
                    ]
                },
                "BP8.02": {
                    "questionId": "BP8.02",
                    "chosenOptionIds": [
                        "e88670a3-c6d8-5c96-914e-b9fc1818aeda"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "9ae96e50-5c86-58a7-a845-fb6347c7edd1"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "f501dc76-7fcd-586b-ba1d-ad70ab8e8d33"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "92e118de-38b9-5d5a-96ba-7b50547c0621"
                    ]
                },
                "BP9": {
                    "questionId": "BP9",
                    "chosenOptionIds": [
                        "becfe35c-2096-5848-87ae-3f763cf59dd6"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "93bfeb6e-215e-5f4b-b6de-0bc364e05be5"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "e22cf233-c174-52fb-af55-97bf9c46e971"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "0feda24f-7b01-557b-8ca5-10eb924cbdac"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "ef5a7b22-b2b2-5a5b-8a80-ce5c251033fd"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "94795194-4a80-590f-be62-ee78f2fa0ff5"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "201c7ee3-56dc-51e9-ad55-69951192ab16"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "de2ea429-4fcd-53b8-8d9f-ebb69a637a02"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "6ade3a8d-8516-5a88-baa0-f996f32730d2"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "2723ed09-37f6-522c-92b1-e518d4fe9315"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "58777a2e-4b97-5413-b340-efe722e45936"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "63509127-aadf-5fe2-8e1f-19d0d846d6ce",
                        "36502f0b-4563-5e03-80f2-a46dabeeae74"
                    ]
                },
                "BP99.1": {
                    "questionId": "BP99.1",
                    "chosenOptionIds": [
                        "34d19f12-207f-56ce-bde8-8d616f6e8b26"
                    ]
                },
                "BP99.2": {
                    "questionId": "BP99.2",
                    "chosenOptionIds": [
                        "ac6c9baf-e6df-5453-8665-0b796e1b83f8"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "a020ed85-ec9d-5778-8006-41f634cab09d"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM1": {
                    "questionId": "HM1",
                    "chosenOptionIds": [
                        "60caa355-cd97-591a-9344-1e9858939855"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "c3ee8981-dec3-5b3c-80ca-c1023f983a6d"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "a43647cb-a0e8-5705-adb9-a2b68877dc77"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "5574c021-c3c4-513a-a4b6-5ee416e8aa9e"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "3fd957d1-0cbb-5397-9812-353be5c2184e"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "d2c670c4-b05b-597a-a0af-5342230535a0"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "bcd05aa7-d9e3-5bdc-8661-0daa33c7b9b3"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "2be66549-5767-51b4-82ed-deeab0723f8d"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "0983ccf5-a426-53d0-adbb-1baf6832190c"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "e979459c-cb23-5cea-bedd-432a62288787"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "fa32b497-4a4b-5076-a391-b6dffa8e1851"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "bde4e5f3-19e4-571f-84a2-5b9d188d74ef"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "7d6921a2-7e63-5f12-9c75-dff8b592fabc"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: Y32QME8 aspect: simple_bd1 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "0ec0e224-a5cf-5ece-b3ab-37061a600ace"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "a16e8e14-524e-53df-b4ef-730a2c8e5f32"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "916d657d-9f2a-5451-8465-18e3bd863347"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "b74511c7-9f97-5c15-8bf5-6ec4357b4814"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 365
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "7a5958fc-e0f7-5d43-9cd0-abfe95cb7026"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "9222be3e-443f-5146-9d69-d48fe9041ba0"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5c30c60e-d53e-5cc3-b4a9-fdad9bec4cb7"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "1d4beed1-78e5-531c-a255-643fac260dee"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "f3efc764-ca53-52c3-9c3a-38c7f787d415"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "ef6fddab-7a07-5f20-af20-74f2444eee53"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "3b216cf8-72e7-558c-bdd3-cc23a1865674"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "44fd9eb3-aee2-5af0-b83c-dbbbf9514022"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "7599bcaa-89a5-5f71-80ea-b70c360bb44a"
                    ]
                },
                "BP101b": {
                    "questionId": "BP101b",
                    "chosenOptionIds": [
                        "cb80bcac-db78-55bd-b051-bdd00801fd54"
                    ]
                },
                "BP102b": {
                    "questionId": "BP102b",
                    "chosenOptionIds": [
                        "71338232-94be-5805-b822-94db8be9c5a1"
                    ]
                },
                "BP103b": {
                    "questionId": "BP103b",
                    "chosenOptionIds": [
                        "867b0ee4-73d9-5aac-b96d-bc2d85d5b183"
                    ]
                },
                "BP104b": {
                    "questionId": "BP104b",
                    "chosenOptionIds": [
                        "c59b5910-3279-509f-8175-f2fd0da2dc86"
                    ]
                },
                "BP87b": {
                    "questionId": "BP87b",
                    "chosenOptionIds": [
                        "7949bd96-3757-5573-bd89-3a91a383cc99"
                    ]
                },
                "BP88b": {
                    "questionId": "BP88b",
                    "chosenOptionIds": [
                        "fa24b924-a8e1-5f02-893b-3d9643007991"
                    ]
                },
                "BP89b": {
                    "questionId": "BP89b",
                    "chosenOptionIds": [
                        "41d2829a-3971-552f-8c5a-c77e7d0073e7"
                    ]
                },
                "BP90b": {
                    "questionId": "BP90b",
                    "chosenOptionIds": [
                        "95c2822d-27ad-5bb0-982f-8c2b949c8918"
                    ]
                },
                "BP92b": {
                    "questionId": "BP92b",
                    "chosenOptionIds": [
                        "b2f4c490-30df-5c8f-87c0-bc4acbcfb8e0"
                    ]
                },
                "BP94b": {
                    "questionId": "BP94b",
                    "chosenOptionIds": [
                        "038cb12b-2783-569a-b1d7-e28c65e591a3"
                    ]
                },
                "BP95b": {
                    "questionId": "BP95b",
                    "chosenOptionIds": [
                        "fb0bd4a7-2793-5b81-badc-fe81b4bc82dc"
                    ]
                },
                "BP96b": {
                    "questionId": "BP96b",
                    "chosenOptionIds": [
                        "8c3d3503-6334-55b9-b0f0-94c8e520094e"
                    ]
                },
                "BP97b": {
                    "questionId": "BP97b",
                    "chosenOptionIds": [
                        "6895096a-ef29-546e-9967-d7a23b4a2859"
                    ]
                },
                "BP98b": {
                    "questionId": "BP98b",
                    "chosenOptionIds": [
                        "01641419-fbfc-52a5-ae63-0fa736ff9847"
                    ]
                },
                "BP99.3b": {
                    "questionId": "BP99.3b",
                    "chosenOptionIds": [
                        "bf30a6c2-f8e2-5305-b4d4-7ad6227449d3"
                    ]
                },
                "BP99b": {
                    "questionId": "BP99b",
                    "chosenOptionIds": [
                        "68860df1-e835-50d6-8eb2-f9d9f2f97efa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "BP_neg1": {
                    "questionId": "BP_neg1",
                    "chosenOptionIds": [
                        "119208b0-ff3b-5c43-80be-4f07e65b6248"
                    ]
                }
            }
        },
        {
            "name": "Mood test: 10QKZ0P aspect: simple_bd1 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "0ec0e224-a5cf-5ece-b3ab-37061a600ace"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "a16e8e14-524e-53df-b4ef-730a2c8e5f32"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "9b47900d-e5a7-5e58-830b-d9b3130a7990"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "b74511c7-9f97-5c15-8bf5-6ec4357b4814"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 2920
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "913bc1e9-8da1-54c7-b935-13fc5d48fbd9"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "11f457f5-4c39-5458-ba9a-84478dd379ba"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5c30c60e-d53e-5cc3-b4a9-fdad9bec4cb7"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "9d1b858c-7064-5a84-80d6-786c18e603b6",
                        "ba6dc2ba-3c20-57b6-a276-32c863109ff6",
                        "1d4beed1-78e5-531c-a255-643fac260dee"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "c9813959-ba61-5220-9e27-659abb256037"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "ef6fddab-7a07-5f20-af20-74f2444eee53"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "d362cd87-7515-551e-bc74-19ef903484aa"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "44fd9eb3-aee2-5af0-b83c-dbbbf9514022",
                        "07106ab9-5949-5b5f-bc96-117139ebd397"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "7599bcaa-89a5-5f71-80ea-b70c360bb44a"
                    ]
                },
                "BP101b": {
                    "questionId": "BP101b",
                    "chosenOptionIds": [
                        "cb80bcac-db78-55bd-b051-bdd00801fd54"
                    ]
                },
                "BP102b": {
                    "questionId": "BP102b",
                    "chosenOptionIds": [
                        "71338232-94be-5805-b822-94db8be9c5a1"
                    ]
                },
                "BP103b": {
                    "questionId": "BP103b",
                    "chosenOptionIds": [
                        "867b0ee4-73d9-5aac-b96d-bc2d85d5b183"
                    ]
                },
                "BP104b": {
                    "questionId": "BP104b",
                    "chosenOptionIds": [
                        "c59b5910-3279-509f-8175-f2fd0da2dc86"
                    ]
                },
                "BP87b": {
                    "questionId": "BP87b",
                    "chosenOptionIds": [
                        "7949bd96-3757-5573-bd89-3a91a383cc99"
                    ]
                },
                "BP88b": {
                    "questionId": "BP88b",
                    "chosenOptionIds": [
                        "fa24b924-a8e1-5f02-893b-3d9643007991"
                    ]
                },
                "BP89b": {
                    "questionId": "BP89b",
                    "chosenOptionIds": [
                        "41d2829a-3971-552f-8c5a-c77e7d0073e7"
                    ]
                },
                "BP90b": {
                    "questionId": "BP90b",
                    "chosenOptionIds": [
                        "95c2822d-27ad-5bb0-982f-8c2b949c8918"
                    ]
                },
                "BP92b": {
                    "questionId": "BP92b",
                    "chosenOptionIds": [
                        "b2f4c490-30df-5c8f-87c0-bc4acbcfb8e0"
                    ]
                },
                "BP94b": {
                    "questionId": "BP94b",
                    "chosenOptionIds": [
                        "038cb12b-2783-569a-b1d7-e28c65e591a3"
                    ]
                },
                "BP95b": {
                    "questionId": "BP95b",
                    "chosenOptionIds": [
                        "fb0bd4a7-2793-5b81-badc-fe81b4bc82dc"
                    ]
                },
                "BP96b": {
                    "questionId": "BP96b",
                    "chosenOptionIds": [
                        "8c3d3503-6334-55b9-b0f0-94c8e520094e"
                    ]
                },
                "BP97b": {
                    "questionId": "BP97b",
                    "chosenOptionIds": [
                        "6895096a-ef29-546e-9967-d7a23b4a2859"
                    ]
                },
                "BP98b": {
                    "questionId": "BP98b",
                    "chosenOptionIds": [
                        "01641419-fbfc-52a5-ae63-0fa736ff9847"
                    ]
                },
                "BP99.3b": {
                    "questionId": "BP99.3b",
                    "chosenOptionIds": [
                        "bf30a6c2-f8e2-5305-b4d4-7ad6227449d3"
                    ]
                },
                "BP99b": {
                    "questionId": "BP99b",
                    "chosenOptionIds": [
                        "68860df1-e835-50d6-8eb2-f9d9f2f97efa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "BP_neg1": {
                    "questionId": "BP_neg1",
                    "chosenOptionIds": [
                        "119208b0-ff3b-5c43-80be-4f07e65b6248"
                    ]
                }
            }
        },
        {
            "name": "Mood test: Z35X10O aspect: simple_bd1 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "45f0ce85-9e19-53da-bc0d-2af8b383318a"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "eb68a88d-b49b-5cde-a9c6-5e4c78e27d19"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "826538fc-8a97-55e4-8013-fe6a93f8e451"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 1095
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "db360e93-3616-5d89-be36-5ccee4aacf1c"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "a9525e10-4e34-5d6b-9c93-c7546ec49231"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "de11fb6b-7e7d-52b1-9d09-2d6debc53314"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5c30c60e-d53e-5cc3-b4a9-fdad9bec4cb7"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "9d1b858c-7064-5a84-80d6-786c18e603b6"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "dde1af45-4686-52c9-b960-369527b28f6c"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "228ef7d9-008b-559d-ba14-6b928b486b38"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "d362cd87-7515-551e-bc74-19ef903484aa"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "8c599171-d554-5547-9f54-2066c1a711dd"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "7599bcaa-89a5-5f71-80ea-b70c360bb44a"
                    ]
                },
                "BP101b": {
                    "questionId": "BP101b",
                    "chosenOptionIds": [
                        "31b2d2c8-8f28-514e-9e58-aa878f6c8761"
                    ]
                },
                "BP102b": {
                    "questionId": "BP102b",
                    "chosenOptionIds": [
                        "71338232-94be-5805-b822-94db8be9c5a1"
                    ]
                },
                "BP103b": {
                    "questionId": "BP103b",
                    "chosenOptionIds": [
                        "867b0ee4-73d9-5aac-b96d-bc2d85d5b183"
                    ]
                },
                "BP104b": {
                    "questionId": "BP104b",
                    "chosenOptionIds": [
                        "c59b5910-3279-509f-8175-f2fd0da2dc86"
                    ]
                },
                "BP87b": {
                    "questionId": "BP87b",
                    "chosenOptionIds": [
                        "fb568891-2ea3-5200-bbdb-ac0695f30b02"
                    ]
                },
                "BP88b": {
                    "questionId": "BP88b",
                    "chosenOptionIds": [
                        "fa24b924-a8e1-5f02-893b-3d9643007991"
                    ]
                },
                "BP89b": {
                    "questionId": "BP89b",
                    "chosenOptionIds": [
                        "db509963-d872-5b97-ba9b-a276f58e0189"
                    ]
                },
                "BP90b": {
                    "questionId": "BP90b",
                    "chosenOptionIds": [
                        "95c2822d-27ad-5bb0-982f-8c2b949c8918"
                    ]
                },
                "BP92b": {
                    "questionId": "BP92b",
                    "chosenOptionIds": [
                        "f59918ba-eb3a-5cf1-81aa-8b7f6ac0df5e"
                    ]
                },
                "BP94b": {
                    "questionId": "BP94b",
                    "chosenOptionIds": [
                        "24f97aee-7df6-5c11-b615-f5064af38f63"
                    ]
                },
                "BP95b": {
                    "questionId": "BP95b",
                    "chosenOptionIds": [
                        "fb0bd4a7-2793-5b81-badc-fe81b4bc82dc"
                    ]
                },
                "BP96b": {
                    "questionId": "BP96b",
                    "chosenOptionIds": [
                        "8c3d3503-6334-55b9-b0f0-94c8e520094e"
                    ]
                },
                "BP97b": {
                    "questionId": "BP97b",
                    "chosenOptionIds": [
                        "8fa0f169-f510-5c9e-a4be-fbcc964f7480"
                    ]
                },
                "BP98b": {
                    "questionId": "BP98b",
                    "chosenOptionIds": [
                        "01641419-fbfc-52a5-ae63-0fa736ff9847"
                    ]
                },
                "BP99.3b": {
                    "questionId": "BP99.3b",
                    "chosenOptionIds": [
                        "bf30a6c2-f8e2-5305-b4d4-7ad6227449d3"
                    ]
                },
                "BP99b": {
                    "questionId": "BP99b",
                    "chosenOptionIds": [
                        "68860df1-e835-50d6-8eb2-f9d9f2f97efa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "BP_neg1": {
                    "questionId": "BP_neg1",
                    "chosenOptionIds": [
                        "119208b0-ff3b-5c43-80be-4f07e65b6248"
                    ]
                }
            }
        },
        {
            "name": "Mood test: ME84L3W aspect: simple_bd1 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "45f0ce85-9e19-53da-bc0d-2af8b383318a"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "c0453e8e-7a9c-5bf5-b8a2-167a61a6387e"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "322210fb-135d-5ac8-9617-7fc4f85e016d"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 0
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "7a5958fc-e0f7-5d43-9cd0-abfe95cb7026"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "a9525e10-4e34-5d6b-9c93-c7546ec49231"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "11f457f5-4c39-5458-ba9a-84478dd379ba"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5c30c60e-d53e-5cc3-b4a9-fdad9bec4cb7"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "a449e4ae-663b-50fb-9b62-1708b0a32669"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "f3efc764-ca53-52c3-9c3a-38c7f787d415"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "228ef7d9-008b-559d-ba14-6b928b486b38"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "3b216cf8-72e7-558c-bdd3-cc23a1865674"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "4896b504-2025-52c3-85e2-c6cf3205cef7"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "ccbdcbec-96d0-5362-977b-784289f5cfc0"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "4a0f1c4d-38cf-5de4-99e3-0ee818f9709c"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "e9eb902a-d327-5ec5-938c-dc317ca59b13"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "1daccd4c-e5ff-5f18-ba55-7c2d2d93b849"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "b24df75b-6bb9-5f5f-99e3-c883ec3644ec"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP12": {
                    "questionId": "BP12",
                    "chosenOptionIds": [
                        "1d6299ac-2ac6-51e2-9a3f-13e8d44e652a"
                    ]
                },
                "BP12.5": {
                    "questionId": "BP12.5",
                    "chosenOptionIds": [
                        "cd7ce00f-4e7c-57ac-9c6c-4137efa24c8c"
                    ]
                },
                "BP14": {
                    "questionId": "BP14",
                    "chosenOptionIds": [
                        "97bbe87b-c5ff-51c1-a99c-cec263cff7d4"
                    ]
                },
                "BP14.1": {
                    "questionId": "BP14.1",
                    "chosenOptionIds": [
                        "f2b7f127-8ab7-5609-8820-43158baee15c"
                    ]
                },
                "BP14.2": {
                    "questionId": "BP14.2",
                    "freeValue": 0.041666666666666664
                },
                "BP14.3": {
                    "questionId": "BP14.3",
                    "chosenOptionIds": [
                        "9f5fc152-75fb-59d8-8f40-7a6ca15c69cb"
                    ]
                },
                "BP16": {
                    "questionId": "BP16",
                    "chosenOptionIds": [
                        "fabceeea-8fea-5444-8b67-8f189f366c27"
                    ]
                },
                "BP16.0": {
                    "questionId": "BP16.0",
                    "chosenOptionIds": [
                        "4b26caa5-9124-51b2-ab08-2f4e977cee1f"
                    ]
                },
                "BP16.01": {
                    "questionId": "BP16.01",
                    "chosenOptionIds": [
                        "f81655d7-78e5-59b1-a7eb-90a2ec7a107d"
                    ]
                },
                "BP16.02": {
                    "questionId": "BP16.02",
                    "chosenOptionIds": [
                        "16a695c9-3ac1-5d34-b799-44602d85b556"
                    ]
                },
                "BP18": {
                    "questionId": "BP18",
                    "chosenOptionIds": [
                        "b577f323-ec54-5d25-a2b9-4f8de30d60a2"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 23
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "f03de28d-8f71-5af4-be2f-45f4e6092a3d"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "ad1cfcc2-c1dc-57ef-b964-17d355fa0402"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "afb57366-c0b3-5f73-92eb-27bc133ad2ed"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "667ba57c-b1cd-5f9b-aa12-58e65a981910"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "fd30dc27-8e89-5d1a-9d95-8844603d4379"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "d9fb150b-68b8-50ed-bb1d-eafd41f63f3b"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "46c90446-3ae9-53f5-9b57-ad19d66b5be6"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "d680a8d9-e35f-563f-9d1d-e7c3136aff6d"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "c88e3fb5-913a-5c5c-a42b-603f7bb60a40"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "201c7ee3-56dc-51e9-ad55-69951192ab16"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "dbab3669-e910-5267-b7b1-fcb661ea7e81"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "2723ed09-37f6-522c-92b1-e518d4fe9315"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "58777a2e-4b97-5413-b340-efe722e45936"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "5c06d516-e816-58d6-997e-f8fa91131ac7"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "a020ed85-ec9d-5778-8006-41f634cab09d"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM19": {
                    "questionId": "HM19",
                    "chosenOptionIds": [
                        "99f474d7-d509-592a-856a-afed343fb1b8"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "98ea07a0-ae95-570f-8d11-a3e7c682e986"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "696fdd9d-5ced-504b-a933-bb3e575b0099"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c41974b6-d394-569c-8f45-49f79fcac5c6"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "43c32319-c75f-5169-b0e5-713820721d4f"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "b2cb304f-f035-53fc-8763-e2f91b031f9b"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "0170b4cf-0541-53cd-a9db-818d5067dc23"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "2be66549-5767-51b4-82ed-deeab0723f8d"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "2a17c2fe-2592-58b1-98e9-3dc4168fe3e3"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "a56fcb96-f868-5d08-a358-9b403eb3cd16"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "fa32b497-4a4b-5076-a391-b6dffa8e1851"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "8823c74f-ff7c-52d8-9c73-fd3b4180f48a"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "1d07911e-929d-5719-aeea-a68f593d18e8"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: PE694EL aspect: simple_bd1 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "3bd18f66-6f1e-5ea4-ae24-2990ed197bef"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "916d657d-9f2a-5451-8465-18e3bd863347"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "826538fc-8a97-55e4-8013-fe6a93f8e451"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 1095
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "db360e93-3616-5d89-be36-5ccee4aacf1c"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "a9525e10-4e34-5d6b-9c93-c7546ec49231"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "9222be3e-443f-5146-9d69-d48fe9041ba0"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "db7514e2-d317-55fb-82d3-2e4a49c2494b"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "9d1b858c-7064-5a84-80d6-786c18e603b6",
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "f3efc764-ca53-52c3-9c3a-38c7f787d415"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "9506449e-b2aa-5498-b896-901dd248b1b3"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "ef6fddab-7a07-5f20-af20-74f2444eee53"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "3b216cf8-72e7-558c-bdd3-cc23a1865674"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "07106ab9-5949-5b5f-bc96-117139ebd397"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "83e6efd5-64a2-5f0e-a973-9278eae90cfb"
                    ]
                },
                "BP10": {
                    "questionId": "BP10",
                    "chosenOptionIds": [
                        "7ad54f0a-87a6-5e82-bfc3-44b540230082"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "4a0f1c4d-38cf-5de4-99e3-0ee818f9709c"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "6a5aa899-7b89-5661-a554-71b6058ad4a4"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "d3224e16-1b0c-5266-9e14-ac72d2b2e988"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "b24df75b-6bb9-5f5f-99e3-c883ec3644ec"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 45
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "c7bee7a0-3037-5eb1-96a1-85b884d7e1dd"
                    ]
                },
                "BP3.1": {
                    "questionId": "BP3.1",
                    "chosenOptionIds": [
                        "b56edfbb-cf11-50ee-9149-9e36f4cc1ea0"
                    ]
                },
                "BP3.2": {
                    "questionId": "BP3.2",
                    "chosenOptionIds": [
                        "ec333f8b-146a-5700-a15d-527032ceac3f"
                    ]
                },
                "BP3.3": {
                    "questionId": "BP3.3",
                    "freeValue": 0.041666666666666664
                },
                "BP3.4": {
                    "questionId": "BP3.4",
                    "chosenOptionIds": [
                        "6ec82bbf-7582-5509-ace2-5ecf0ce82ec3"
                    ]
                },
                "BP4": {
                    "questionId": "BP4",
                    "chosenOptionIds": [
                        "9765aba8-7d55-5928-b79d-36aa58646036"
                    ]
                },
                "BP4.5": {
                    "questionId": "BP4.5",
                    "chosenOptionIds": [
                        "66a3460c-65ce-5a4c-9774-2e303ae4961e"
                    ]
                },
                "BP6": {
                    "questionId": "BP6",
                    "chosenOptionIds": [
                        "742a22d6-a570-5e3a-8e72-c001f1e9f98b"
                    ]
                },
                "BP6.1": {
                    "questionId": "BP6.1",
                    "chosenOptionIds": [
                        "430b68c6-29cf-5a15-ab4c-89bd842383bf"
                    ]
                },
                "BP6.2": {
                    "questionId": "BP6.2",
                    "freeValue": 0.041666666666666664
                },
                "BP6.3": {
                    "questionId": "BP6.3",
                    "chosenOptionIds": [
                        "e061c578-086e-5990-bba6-279cb273f3a5"
                    ]
                },
                "BP8": {
                    "questionId": "BP8",
                    "chosenOptionIds": [
                        "87e0081b-9455-5ced-9225-10c2f70b1734"
                    ]
                },
                "BP8.0": {
                    "questionId": "BP8.0",
                    "chosenOptionIds": [
                        "3568cdb1-2500-512b-aada-2547c5bfd835"
                    ]
                },
                "BP8.01": {
                    "questionId": "BP8.01",
                    "chosenOptionIds": [
                        "5020bfdd-a280-56cf-b7f5-3b0b7e667165"
                    ]
                },
                "BP8.02": {
                    "questionId": "BP8.02",
                    "chosenOptionIds": [
                        "e88670a3-c6d8-5c96-914e-b9fc1818aeda"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "9ae96e50-5c86-58a7-a845-fb6347c7edd1"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "afb57366-c0b3-5f73-92eb-27bc133ad2ed"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "28c75e9e-94ef-5c50-8645-f4ff93ccf2ec"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "fd30dc27-8e89-5d1a-9d95-8844603d4379"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "e22cf233-c174-52fb-af55-97bf9c46e971"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "46c90446-3ae9-53f5-9b57-ad19d66b5be6"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "d680a8d9-e35f-563f-9d1d-e7c3136aff6d"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "c88e3fb5-913a-5c5c-a42b-603f7bb60a40"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "ec84966e-bb9f-5ba9-a0cf-d5d3503a74e0"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "6928c696-e6d7-5a14-a004-15a497ac6562"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "17b4da6f-7135-523c-b90b-2fe3b66785ff"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "3cf77140-2757-58eb-b4ea-f2a90cd766e7"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "5c06d516-e816-58d6-997e-f8fa91131ac7"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "c0cc09c3-1335-5eb4-8801-b3cdaf74f6fa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM2": {
                    "questionId": "HM2",
                    "chosenOptionIds": [
                        "de662c3f-66b4-566f-856d-2124e2725542"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "98ea07a0-ae95-570f-8d11-a3e7c682e986"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "a43647cb-a0e8-5705-adb9-a2b68877dc77"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c41974b6-d394-569c-8f45-49f79fcac5c6"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "43c32319-c75f-5169-b0e5-713820721d4f"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "b2cb304f-f035-53fc-8763-e2f91b031f9b"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "0170b4cf-0541-53cd-a9db-818d5067dc23"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "efcb2ed0-864a-521d-ac0d-99e0fd4b38f5"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "2a17c2fe-2592-58b1-98e9-3dc4168fe3e3"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "834b04f2-af09-5337-8fd5-be4a70c99e75"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "86277249-cf50-5f91-a27f-f3a69344e796"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "8823c74f-ff7c-52d8-9c73-fd3b4180f48a"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "1d07911e-929d-5719-aeea-a68f593d18e8"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: 63Y843R aspect: simple_bd1 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "45f0ce85-9e19-53da-bc0d-2af8b383318a"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "c0453e8e-7a9c-5bf5-b8a2-167a61a6387e"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "b74511c7-9f97-5c15-8bf5-6ec4357b4814"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 90
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "7a5958fc-e0f7-5d43-9cd0-abfe95cb7026"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "a9525e10-4e34-5d6b-9c93-c7546ec49231"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "11f457f5-4c39-5458-ba9a-84478dd379ba"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5c30c60e-d53e-5cc3-b4a9-fdad9bec4cb7"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "9d1b858c-7064-5a84-80d6-786c18e603b6",
                        "1d4beed1-78e5-531c-a255-643fac260dee"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "b52073e3-c813-51f5-88ba-9cd3bddd7023"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "ef6fddab-7a07-5f20-af20-74f2444eee53"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "d362cd87-7515-551e-bc74-19ef903484aa"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "07106ab9-5949-5b5f-bc96-117139ebd397",
                        "4896b504-2025-52c3-85e2-c6cf3205cef7"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "7599bcaa-89a5-5f71-80ea-b70c360bb44a"
                    ]
                },
                "BP101b": {
                    "questionId": "BP101b",
                    "chosenOptionIds": [
                        "cb80bcac-db78-55bd-b051-bdd00801fd54"
                    ]
                },
                "BP102b": {
                    "questionId": "BP102b",
                    "chosenOptionIds": [
                        "71338232-94be-5805-b822-94db8be9c5a1"
                    ]
                },
                "BP103b": {
                    "questionId": "BP103b",
                    "chosenOptionIds": [
                        "867b0ee4-73d9-5aac-b96d-bc2d85d5b183"
                    ]
                },
                "BP104b": {
                    "questionId": "BP104b",
                    "chosenOptionIds": [
                        "c59b5910-3279-509f-8175-f2fd0da2dc86"
                    ]
                },
                "BP87b": {
                    "questionId": "BP87b",
                    "chosenOptionIds": [
                        "7949bd96-3757-5573-bd89-3a91a383cc99"
                    ]
                },
                "BP88b": {
                    "questionId": "BP88b",
                    "chosenOptionIds": [
                        "fa24b924-a8e1-5f02-893b-3d9643007991"
                    ]
                },
                "BP89b": {
                    "questionId": "BP89b",
                    "chosenOptionIds": [
                        "41d2829a-3971-552f-8c5a-c77e7d0073e7"
                    ]
                },
                "BP90b": {
                    "questionId": "BP90b",
                    "chosenOptionIds": [
                        "95c2822d-27ad-5bb0-982f-8c2b949c8918"
                    ]
                },
                "BP92b": {
                    "questionId": "BP92b",
                    "chosenOptionIds": [
                        "b2f4c490-30df-5c8f-87c0-bc4acbcfb8e0"
                    ]
                },
                "BP94b": {
                    "questionId": "BP94b",
                    "chosenOptionIds": [
                        "33cefe4b-19c9-55e0-a87c-03ffd99d30bf"
                    ]
                },
                "BP95b": {
                    "questionId": "BP95b",
                    "chosenOptionIds": [
                        "fb0bd4a7-2793-5b81-badc-fe81b4bc82dc"
                    ]
                },
                "BP96b": {
                    "questionId": "BP96b",
                    "chosenOptionIds": [
                        "8c3d3503-6334-55b9-b0f0-94c8e520094e"
                    ]
                },
                "BP97b": {
                    "questionId": "BP97b",
                    "chosenOptionIds": [
                        "8fa0f169-f510-5c9e-a4be-fbcc964f7480"
                    ]
                },
                "BP98b": {
                    "questionId": "BP98b",
                    "chosenOptionIds": [
                        "01641419-fbfc-52a5-ae63-0fa736ff9847"
                    ]
                },
                "BP99.3b": {
                    "questionId": "BP99.3b",
                    "chosenOptionIds": [
                        "bf30a6c2-f8e2-5305-b4d4-7ad6227449d3"
                    ]
                },
                "BP99b": {
                    "questionId": "BP99b",
                    "chosenOptionIds": [
                        "68860df1-e835-50d6-8eb2-f9d9f2f97efa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "BP_neg1": {
                    "questionId": "BP_neg1",
                    "chosenOptionIds": [
                        "119208b0-ff3b-5c43-80be-4f07e65b6248"
                    ]
                }
            }
        },
        {
            "name": "Mood test: J0VQM3L aspect: simple_bd1 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "45f0ce85-9e19-53da-bc0d-2af8b383318a"
                    ]
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "7a5958fc-e0f7-5d43-9cd0-abfe95cb7026"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "a9525e10-4e34-5d6b-9c93-c7546ec49231"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "9222be3e-443f-5146-9d69-d48fe9041ba0"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "77c6088d-2d8f-5c3b-89c9-79cb0d317226"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "1d4beed1-78e5-531c-a255-643fac260dee"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "f3efc764-ca53-52c3-9c3a-38c7f787d415"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "9506449e-b2aa-5498-b896-901dd248b1b3"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "ef6fddab-7a07-5f20-af20-74f2444eee53"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "3b216cf8-72e7-558c-bdd3-cc23a1865674"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "60fe0bb4-d770-56f2-b05a-864625a48e4d"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "7599bcaa-89a5-5f71-80ea-b70c360bb44a"
                    ]
                },
                "BP101b": {
                    "questionId": "BP101b",
                    "chosenOptionIds": [
                        "31b2d2c8-8f28-514e-9e58-aa878f6c8761"
                    ]
                },
                "BP102b": {
                    "questionId": "BP102b",
                    "chosenOptionIds": [
                        "00afc1e6-b78a-5223-a6e7-5d3aaab833e0"
                    ]
                },
                "BP103b": {
                    "questionId": "BP103b",
                    "chosenOptionIds": [
                        "867b0ee4-73d9-5aac-b96d-bc2d85d5b183"
                    ]
                },
                "BP104b": {
                    "questionId": "BP104b",
                    "chosenOptionIds": [
                        "c59b5910-3279-509f-8175-f2fd0da2dc86"
                    ]
                },
                "BP87b": {
                    "questionId": "BP87b",
                    "chosenOptionIds": [
                        "5bc9fc9f-d402-56b0-97eb-fe64add38edf"
                    ]
                },
                "BP88b": {
                    "questionId": "BP88b",
                    "chosenOptionIds": [
                        "fa24b924-a8e1-5f02-893b-3d9643007991"
                    ]
                },
                "BP89b": {
                    "questionId": "BP89b",
                    "chosenOptionIds": [
                        "41d2829a-3971-552f-8c5a-c77e7d0073e7"
                    ]
                },
                "BP90b": {
                    "questionId": "BP90b",
                    "chosenOptionIds": [
                        "95c2822d-27ad-5bb0-982f-8c2b949c8918"
                    ]
                },
                "BP92b": {
                    "questionId": "BP92b",
                    "chosenOptionIds": [
                        "b2f4c490-30df-5c8f-87c0-bc4acbcfb8e0"
                    ]
                },
                "BP94b": {
                    "questionId": "BP94b",
                    "chosenOptionIds": [
                        "038cb12b-2783-569a-b1d7-e28c65e591a3"
                    ]
                },
                "BP95b": {
                    "questionId": "BP95b",
                    "chosenOptionIds": [
                        "fb0bd4a7-2793-5b81-badc-fe81b4bc82dc"
                    ]
                },
                "BP96b": {
                    "questionId": "BP96b",
                    "chosenOptionIds": [
                        "8c3d3503-6334-55b9-b0f0-94c8e520094e"
                    ]
                },
                "BP97b": {
                    "questionId": "BP97b",
                    "chosenOptionIds": [
                        "6895096a-ef29-546e-9967-d7a23b4a2859"
                    ]
                },
                "BP98b": {
                    "questionId": "BP98b",
                    "chosenOptionIds": [
                        "01641419-fbfc-52a5-ae63-0fa736ff9847"
                    ]
                },
                "BP99.3b": {
                    "questionId": "BP99.3b",
                    "chosenOptionIds": [
                        "bf30a6c2-f8e2-5305-b4d4-7ad6227449d3"
                    ]
                },
                "BP99b": {
                    "questionId": "BP99b",
                    "chosenOptionIds": [
                        "68860df1-e835-50d6-8eb2-f9d9f2f97efa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "BP_neg1": {
                    "questionId": "BP_neg1",
                    "chosenOptionIds": [
                        "119208b0-ff3b-5c43-80be-4f07e65b6248"
                    ]
                }
            }
        },
        {
            "name": "Mood test: Y32OM08 aspect: simple_bd1 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "c61d70d4-b773-54dd-94d0-d858a47be0fb"
                    ]
                },
                "D-1.5b": {
                    "questionId": "D-1.5b",
                    "freeValue": 26
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "a16e8e14-524e-53df-b4ef-730a2c8e5f32"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "9b47900d-e5a7-5e58-830b-d9b3130a7990"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "b74511c7-9f97-5c15-8bf5-6ec4357b4814"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 1460
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "913bc1e9-8da1-54c7-b935-13fc5d48fbd9"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-1bl": {
                    "questionId": "D-1bl",
                    "chosenOptionIds": [
                        "4105c26a-4ff2-5fd7-984d-f0bcb7b5656e"
                    ]
                },
                "D-2": {
                    "questionId": "D-2",
                    "freeValue": 15
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "c695fd1c-2cb7-5654-b9f7-b32ecb158229"
                    ]
                },
                "D-2bl": {
                    "questionId": "D-2bl",
                    "chosenOptionIds": [
                        "78c1434b-e585-5f1d-b291-a1728f789022"
                    ]
                },
                "D-2bs": {
                    "questionId": "D-2bs",
                    "chosenOptionIds": [
                        "bbd43ce6-ea63-5321-9d05-0bbf9a19513b"
                    ]
                },
                "D-2bt": {
                    "questionId": "D-2bt",
                    "chosenOptionIds": [
                        "acdba44c-4419-55d5-891f-5c03c9b52309"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "fd9c4394-6664-5144-8797-00d27a4dda01"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "db7514e2-d317-55fb-82d3-2e4a49c2494b"
                    ]
                },
                "D-3bl": {
                    "questionId": "D-3bl",
                    "freeValue": 2
                },
                "D-3bs": {
                    "questionId": "D-3bs",
                    "chosenOptionIds": [
                        "fe554f25-dfd7-5062-a2b8-f2662e2c3466"
                    ]
                },
                "D-3bt": {
                    "questionId": "D-3bt",
                    "chosenOptionIds": [
                        "b9abc28d-6e22-5b67-bc5e-27431e123974"
                    ]
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-42bt": {
                    "questionId": "D-42bt",
                    "freeValue": 3
                },
                "D-43bt": {
                    "questionId": "D-43bt",
                    "chosenOptionIds": [
                        "90e60395-fc3d-56cb-b6f7-2e24fba02803"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "1d4beed1-78e5-531c-a255-643fac260dee",
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b"
                    ]
                },
                "D-4bs": {
                    "questionId": "D-4bs",
                    "freeValue": 2
                },
                "D-4bt": {
                    "questionId": "D-4bt",
                    "freeValue": 5
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "c9813959-ba61-5220-9e27-659abb256037"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "3e093dd3-857e-5004-a4fb-961fbfa59ece"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "adecf2d3-f302-50f8-a4a9-3ffac1789bc6"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "4c78ae3e-7de4-545f-aca1-03fa41fc0c23"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "13f8bac1-d0f5-539b-8596-1c55821bea46"
                    ]
                },
                "D-s10bt": {
                    "questionId": "D-s10bt",
                    "chosenOptionIds": [
                        "e3ab5219-3af6-5da7-8464-a145eb508a0f"
                    ]
                },
                "D-s11bt": {
                    "questionId": "D-s11bt",
                    "chosenOptionIds": [
                        "bae5acc8-8664-54a2-ba97-c36244be4c44",
                        "dc476220-9479-5def-8a97-20e6152d44af",
                        "6994b88e-cfa5-5986-b9e8-625d17d1147c",
                        "16e1a0b8-28c5-5bcd-931d-335c858c3f88",
                        "64af3cae-713a-51e1-99c1-09e2fdaa755d",
                        "7921bccf-4756-59fe-b678-d536c2ab5945"
                    ]
                },
                "D-s1bt": {
                    "questionId": "D-s1bt",
                    "chosenOptionIds": [
                        "c17482e4-818a-50cd-90fa-1246d037825e"
                    ]
                },
                "D-s2bt": {
                    "questionId": "D-s2bt",
                    "chosenOptionIds": [
                        "8783a6d5-cd94-5982-8fd6-66ae46e1c6df"
                    ]
                },
                "D-s3bt": {
                    "questionId": "D-s3bt",
                    "chosenOptionIds": [
                        "d065c0ca-9e6d-5300-8e3e-688b16f88c59"
                    ]
                },
                "D-s4bt": {
                    "questionId": "D-s4bt",
                    "chosenOptionIds": [
                        "a4021497-af94-56a4-8075-392653262e2e"
                    ]
                },
                "D-s5bt": {
                    "questionId": "D-s5bt",
                    "chosenOptionIds": [
                        "508d38ea-9336-504a-a8f2-c375a9bcc839"
                    ]
                },
                "D-s6bt": {
                    "questionId": "D-s6bt",
                    "chosenOptionIds": [
                        "53625e56-4f03-5f54-a340-d5b8cabe24d9"
                    ]
                },
                "D-s7bt": {
                    "questionId": "D-s7bt",
                    "chosenOptionIds": [
                        "1df67815-0b9f-56ef-bd48-96ed977db141"
                    ]
                },
                "D-s8bt": {
                    "questionId": "D-s8bt",
                    "chosenOptionIds": [
                        "6f5067cf-ef4d-59d9-afa1-b3dfc4ac861f"
                    ]
                },
                "D-s9bt": {
                    "questionId": "D-s9bt",
                    "chosenOptionIds": [
                        "da503bbd-e567-576e-a21f-e0fad60dccdd"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "60fe0bb4-d770-56f2-b05a-864625a48e4d"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "7599bcaa-89a5-5f71-80ea-b70c360bb44a"
                    ]
                },
                "BP101b": {
                    "questionId": "BP101b",
                    "chosenOptionIds": [
                        "c9856080-d9de-533e-92bf-7fbe2b6cd99a"
                    ]
                },
                "BP102b": {
                    "questionId": "BP102b",
                    "chosenOptionIds": [
                        "46a64b5b-6641-5b1b-92aa-5366444fb134"
                    ]
                },
                "BP103b": {
                    "questionId": "BP103b",
                    "chosenOptionIds": [
                        "867b0ee4-73d9-5aac-b96d-bc2d85d5b183"
                    ]
                },
                "BP104b": {
                    "questionId": "BP104b",
                    "chosenOptionIds": [
                        "c59b5910-3279-509f-8175-f2fd0da2dc86"
                    ]
                },
                "BP87b": {
                    "questionId": "BP87b",
                    "chosenOptionIds": [
                        "7949bd96-3757-5573-bd89-3a91a383cc99"
                    ]
                },
                "BP88b": {
                    "questionId": "BP88b",
                    "chosenOptionIds": [
                        "e29e159f-f691-5aa7-b5a0-d13fe2c54170"
                    ]
                },
                "BP89b": {
                    "questionId": "BP89b",
                    "chosenOptionIds": [
                        "41d2829a-3971-552f-8c5a-c77e7d0073e7"
                    ]
                },
                "BP90b": {
                    "questionId": "BP90b",
                    "chosenOptionIds": [
                        "95c2822d-27ad-5bb0-982f-8c2b949c8918"
                    ]
                },
                "BP92b": {
                    "questionId": "BP92b",
                    "chosenOptionIds": [
                        "b2f4c490-30df-5c8f-87c0-bc4acbcfb8e0"
                    ]
                },
                "BP94b": {
                    "questionId": "BP94b",
                    "chosenOptionIds": [
                        "1f4fafbf-913e-5edc-bfaf-3baae49fc91d"
                    ]
                },
                "BP95b": {
                    "questionId": "BP95b",
                    "chosenOptionIds": [
                        "fb0bd4a7-2793-5b81-badc-fe81b4bc82dc"
                    ]
                },
                "BP96b": {
                    "questionId": "BP96b",
                    "chosenOptionIds": [
                        "8c3d3503-6334-55b9-b0f0-94c8e520094e"
                    ]
                },
                "BP97b": {
                    "questionId": "BP97b",
                    "chosenOptionIds": [
                        "8fa0f169-f510-5c9e-a4be-fbcc964f7480"
                    ]
                },
                "BP98b": {
                    "questionId": "BP98b",
                    "chosenOptionIds": [
                        "f7b75a2b-3bd2-5734-9fa6-7706f7f8ac89"
                    ]
                },
                "BP99.1b": {
                    "questionId": "BP99.1b",
                    "chosenOptionIds": [
                        "74ac8d69-c317-57fb-a5e5-a93c1309c0f7"
                    ]
                },
                "BP99.2b": {
                    "questionId": "BP99.2b",
                    "chosenOptionIds": [
                        "a8224369-4083-5ab5-8cad-c4563490599c"
                    ]
                },
                "BP99.3b": {
                    "questionId": "BP99.3b",
                    "chosenOptionIds": [
                        "e4575ba2-20f0-519e-967b-d5769763cadc"
                    ]
                },
                "BP99b": {
                    "questionId": "BP99b",
                    "chosenOptionIds": [
                        "3f987aaa-e435-55a9-9c1b-aa6d2e476738",
                        "96faf550-f157-55b9-8f15-6be82cd511a8"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "BP_neg1": {
                    "questionId": "BP_neg1",
                    "chosenOptionIds": [
                        "119208b0-ff3b-5c43-80be-4f07e65b6248"
                    ]
                }
            }
        },
        {
            "name": "Mood test: 50MDW06 aspect: simple_bd1 should be diagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "fe031280-7d5d-52cb-aca8-1a8664b3234a"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "9b47900d-e5a7-5e58-830b-d9b3130a7990"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "a0e528ca-2fbb-5da1-9398-0758f79755aa"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 1460
                },
                "D-15a": {
                    "questionId": "D-15a",
                    "chosenOptionIds": [
                        "7b763ff0-7703-54f7-8eae-aa83417ca133"
                    ]
                },
                "D-16.5a": {
                    "questionId": "D-16.5a",
                    "chosenOptionIds": [
                        "ae0a4d4e-e9bf-51bf-8506-0cbced38db92"
                    ]
                },
                "D-17a": {
                    "questionId": "D-17a",
                    "chosenOptionIds": [
                        "495d3d07-8a1f-5213-bf79-59443d7aa5cf"
                    ]
                },
                "D-18a": {
                    "questionId": "D-18a",
                    "chosenOptionIds": [
                        "d5dff57c-3626-5197-b54a-ddca85aa8775"
                    ]
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "db360e93-3616-5d89-be36-5ccee4aacf1c"
                    ]
                },
                "D-1bl": {
                    "questionId": "D-1bl",
                    "chosenOptionIds": [
                        "5da400b0-5bff-5744-a664-4305892ae669"
                    ]
                },
                "D-2": {
                    "questionId": "D-2",
                    "freeValue": 14
                },
                "D-20a": {
                    "questionId": "D-20a",
                    "chosenOptionIds": [
                        "15c694bd-3089-51d8-8fff-6286e2113f9c"
                    ]
                },
                "D-21a": {
                    "questionId": "D-21a",
                    "chosenOptionIds": [
                        "4c0f1be4-1f17-5e09-86a5-e8e8e51285ef"
                    ]
                },
                "D-23a": {
                    "questionId": "D-23a",
                    "chosenOptionIds": [
                        "f6cf63c5-0c57-5422-9431-55fec4c93c63"
                    ]
                },
                "D-26a": {
                    "questionId": "D-26a",
                    "chosenOptionIds": [
                        "400d260d-4647-5f4d-8105-894b04c366f2"
                    ]
                },
                "D-27a": {
                    "questionId": "D-27a",
                    "chosenOptionIds": [
                        "13a1b08d-9817-5c2f-96cb-f8d03b80a37d"
                    ]
                },
                "D-28a": {
                    "questionId": "D-28a",
                    "chosenOptionIds": [
                        "801353cf-2f72-53db-9179-e7ecc31b1fc1"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "c695fd1c-2cb7-5654-b9f7-b32ecb158229"
                    ]
                },
                "D-2bl": {
                    "questionId": "D-2bl",
                    "chosenOptionIds": [
                        "2a8c96e2-cb5b-596a-99b6-a4885253776c"
                    ]
                },
                "D-2bs": {
                    "questionId": "D-2bs",
                    "chosenOptionIds": [
                        "d9fc40d3-aa51-587a-a54d-305ad26f67ae"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "e797900f-129d-553e-b83f-8494d7f03986"
                    ]
                },
                "D-30a": {
                    "questionId": "D-30a",
                    "chosenOptionIds": [
                        "d309cef4-2204-5a10-a98d-b66740336838"
                    ]
                },
                "D-31a": {
                    "questionId": "D-31a",
                    "chosenOptionIds": [
                        "bfd75ca8-5c95-564e-9603-3b1a4f34f8d8"
                    ]
                },
                "D-32a": {
                    "questionId": "D-32a",
                    "chosenOptionIds": [
                        "ea2a4fbd-008a-571b-bb68-cbd0ba351521"
                    ]
                },
                "D-33a": {
                    "questionId": "D-33a",
                    "chosenOptionIds": [
                        "3968dd70-f060-5cbd-bb6a-d63f702a3691"
                    ]
                },
                "D-34a": {
                    "questionId": "D-34a",
                    "chosenOptionIds": [
                        "6bfab4b8-7d57-5039-8933-4dee0c958a19"
                    ]
                },
                "D-35a": {
                    "questionId": "D-35a",
                    "chosenOptionIds": [
                        "9c9d44bb-b50f-5ddf-9553-03b254272362"
                    ]
                },
                "D-36a": {
                    "questionId": "D-36a",
                    "chosenOptionIds": [
                        "7fb54260-7d7e-5c80-809e-584be2bfee10"
                    ]
                },
                "D-37a": {
                    "questionId": "D-37a",
                    "chosenOptionIds": [
                        "fd4e0608-283c-5f56-a026-edfa381519c9"
                    ]
                },
                "D-38a": {
                    "questionId": "D-38a",
                    "chosenOptionIds": [
                        "8d5bbc66-0b33-5de5-838e-ff0fccbb6fbc"
                    ]
                },
                "D-39a": {
                    "questionId": "D-39a",
                    "chosenOptionIds": [
                        "756b91dc-7358-504f-aeab-84552f2bf28b"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5b6c0a69-6369-53d1-b2e1-5cd6294a9f71"
                    ]
                },
                "D-3bl": {
                    "questionId": "D-3bl",
                    "freeValue": 14
                },
                "D-3bs": {
                    "questionId": "D-3bs",
                    "chosenOptionIds": [
                        "c4422e8a-6a0a-51ea-8592-9157036922d1"
                    ]
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-40a": {
                    "questionId": "D-40a",
                    "chosenOptionIds": [
                        "582119a7-d4a0-58ff-9b1d-2af045e11a7f"
                    ]
                },
                "D-41a": {
                    "questionId": "D-41a",
                    "chosenOptionIds": [
                        "6a5d1a65-0b53-5485-995a-76d8d7eafb1a"
                    ]
                },
                "D-42a": {
                    "questionId": "D-42a",
                    "chosenOptionIds": [
                        "bc912c4c-69a9-5a39-89fb-2072bd945067"
                    ]
                },
                "D-43a": {
                    "questionId": "D-43a",
                    "chosenOptionIds": [
                        "cd0abe72-b3e8-5c78-8330-7ee4a1657536"
                    ]
                },
                "D-43bt": {
                    "questionId": "D-43bt",
                    "chosenOptionIds": [
                        "90e60395-fc3d-56cb-b6f7-2e24fba02803"
                    ]
                },
                "D-44.5a": {
                    "questionId": "D-44.5a",
                    "chosenOptionIds": [
                        "4a53cb20-fae8-59f1-934c-ed83e0709795"
                    ]
                },
                "D-44a": {
                    "questionId": "D-44a",
                    "chosenOptionIds": [
                        "7d6584ca-1b93-543d-9ee4-400ca40134be"
                    ]
                },
                "D-45a": {
                    "questionId": "D-45a",
                    "freeValue": 7
                },
                "D-46a": {
                    "questionId": "D-46a",
                    "chosenOptionIds": [
                        "6e49f368-62af-5abb-9034-a58bd534944f",
                        "4c178978-f79a-58fc-8e73-3a98614c4b6c",
                        "7492eab7-c625-52d0-a2e3-4bf3332decc0",
                        "d9665b1f-cdd2-5979-895e-27bca6487533"
                    ]
                },
                "D-49.1a": {
                    "questionId": "D-49.1a",
                    "chosenOptionIds": [
                        "7108af97-c956-5da8-8dfa-518e346c197c"
                    ]
                },
                "D-49.2a": {
                    "questionId": "D-49.2a",
                    "freeValue": 100
                },
                "D-49a": {
                    "questionId": "D-49a",
                    "chosenOptionIds": [
                        "16dc6af1-32a9-59c5-b66c-57f6becea1e0"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b"
                    ]
                },
                "D-4bs": {
                    "questionId": "D-4bs",
                    "freeValue": 21
                },
                "D-50a": {
                    "questionId": "D-50a",
                    "chosenOptionIds": [
                        "d14da42d-9612-5827-9583-b2bf528130de"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "188a7831-38c4-55c4-880a-1e4db4e9bab1"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "08a0f83f-2f37-5904-9364-4e40deb90e98"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "772bee29-c3a5-52e1-b785-a77d506ae4fb"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "4c78ae3e-7de4-545f-aca1-03fa41fc0c23"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "1200dadf-a0de-5a54-b0b8-fc640f7bc8d1"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "c2921864-bbf7-5735-9313-848fb9d5a6d1",
                        "44fd9eb3-aee2-5af0-b83c-dbbbf9514022"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "83e6efd5-64a2-5f0e-a973-9278eae90cfb"
                    ]
                },
                "BP10": {
                    "questionId": "BP10",
                    "chosenOptionIds": [
                        "7ad54f0a-87a6-5e82-bfc3-44b540230082"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "a20d7b84-720e-53c5-a450-45cbde434507"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "ca5ac96d-2b69-5200-a063-61db840c9ef2"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "7230003d-a290-5a65-ae6b-b7d564b5db38"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "b24df75b-6bb9-5f5f-99e3-c883ec3644ec"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "de4464c8-78fe-5876-aa00-25826ce5ec25"
                    ]
                },
                "BP105": {
                    "questionId": "BP105",
                    "chosenOptionIds": [
                        "64aa1680-6b5b-5a5b-bb92-75995d27ddcd"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 19
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "c7bee7a0-3037-5eb1-96a1-85b884d7e1dd"
                    ]
                },
                "BP3.01": {
                    "questionId": "BP3.01",
                    "freeValue": 40
                },
                "BP3.1": {
                    "questionId": "BP3.1",
                    "chosenOptionIds": [
                        "9531c3ff-ab2b-5198-a490-919ede5afb07"
                    ]
                },
                "BP3.2": {
                    "questionId": "BP3.2",
                    "chosenOptionIds": [
                        "ec333f8b-146a-5700-a15d-527032ceac3f"
                    ]
                },
                "BP3.3": {
                    "questionId": "BP3.3",
                    "freeValue": 2
                },
                "BP3.4": {
                    "questionId": "BP3.4",
                    "chosenOptionIds": [
                        "f304bf93-0c68-5da0-80ba-ccecc2d0fce1"
                    ]
                },
                "BP4": {
                    "questionId": "BP4",
                    "chosenOptionIds": [
                        "9765aba8-7d55-5928-b79d-36aa58646036"
                    ]
                },
                "BP4.5": {
                    "questionId": "BP4.5",
                    "chosenOptionIds": [
                        "1be4b3b1-3a88-5544-af54-ebdfee1ce891"
                    ]
                },
                "BP6": {
                    "questionId": "BP6",
                    "chosenOptionIds": [
                        "52f153db-76e7-57fa-90bd-63d5a923964a"
                    ]
                },
                "BP6.1": {
                    "questionId": "BP6.1",
                    "chosenOptionIds": [
                        "a99773bd-2bff-523e-bc29-1f761307b3d4"
                    ]
                },
                "BP6.2": {
                    "questionId": "BP6.2",
                    "freeValue": 4
                },
                "BP6.3": {
                    "questionId": "BP6.3",
                    "chosenOptionIds": [
                        "e061c578-086e-5990-bba6-279cb273f3a5"
                    ]
                },
                "BP8": {
                    "questionId": "BP8",
                    "chosenOptionIds": [
                        "87e0081b-9455-5ced-9225-10c2f70b1734"
                    ]
                },
                "BP8.0": {
                    "questionId": "BP8.0",
                    "chosenOptionIds": [
                        "3568cdb1-2500-512b-aada-2547c5bfd835"
                    ]
                },
                "BP8.01": {
                    "questionId": "BP8.01",
                    "chosenOptionIds": [
                        "5020bfdd-a280-56cf-b7f5-3b0b7e667165"
                    ]
                },
                "BP8.02": {
                    "questionId": "BP8.02",
                    "chosenOptionIds": [
                        "e88670a3-c6d8-5c96-914e-b9fc1818aeda"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "e620af65-9b5b-57e6-b13a-65616e67e084"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "5f694075-3a97-5f8b-8701-2710a43127a9"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "92e118de-38b9-5d5a-96ba-7b50547c0621"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "93bfeb6e-215e-5f4b-b6de-0bc364e05be5"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "e22cf233-c174-52fb-af55-97bf9c46e971"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "1f57d1a0-3682-5d7d-b66b-8179ec5c3988"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 7
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "17619692-6d18-5558-99da-766a1c275e03"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "82997889-d795-572b-a597-e484ef078b39"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "2555d898-6736-5804-951e-e3c438313029"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "de2ea429-4fcd-53b8-8d9f-ebb69a637a02"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "6ade3a8d-8516-5a88-baa0-f996f32730d2"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "79075051-dcb3-5321-9f27-b21697f8f194"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "58777a2e-4b97-5413-b340-efe722e45936"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "5c06d516-e816-58d6-997e-f8fa91131ac7"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "e81fe613-4923-55ed-bd6c-bd3f19e026f9"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM2": {
                    "questionId": "HM2",
                    "chosenOptionIds": [
                        "a046d610-d27a-59c1-b130-38312005cf1c"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "6b99de8b-634a-5eb0-bc65-bdf30eb9d433"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "8f7acbc1-9ebe-5a52-95ce-b50d62c27345"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "5574c021-c3c4-513a-a4b6-5ee416e8aa9e"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "43c32319-c75f-5169-b0e5-713820721d4f"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "898957c6-8cd1-53e5-98f8-c16789aba613"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "366c6a1b-c8b2-5faf-9d2f-72dbde24e869"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "6b35cd3b-d67a-54af-94f6-e39ddf5af6c6"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "0983ccf5-a426-53d0-adbb-1baf6832190c"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "7e32475a-3fcc-51ac-b414-ab46578efdfd"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "fda00d52-c8be-5b69-bdbd-905c13dddd4e"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "0360ab4b-77d5-5fcd-8d60-b7a005c12ceb"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "7d6921a2-7e63-5f12-9c75-dff8b592fabc"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: Z35L10O aspect: simple_bd1 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "3bd18f66-6f1e-5ea4-ae24-2990ed197bef"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "9b47900d-e5a7-5e58-830b-d9b3130a7990"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "b74511c7-9f97-5c15-8bf5-6ec4357b4814"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 3650
                },
                "D-15a": {
                    "questionId": "D-15a",
                    "chosenOptionIds": [
                        "7b763ff0-7703-54f7-8eae-aa83417ca133"
                    ]
                },
                "D-16.5a": {
                    "questionId": "D-16.5a",
                    "chosenOptionIds": [
                        "0c4e0b7f-132d-5e75-aeae-fd29d3b2eb67"
                    ]
                },
                "D-17a": {
                    "questionId": "D-17a",
                    "chosenOptionIds": [
                        "33b013af-d830-516a-9632-69dcc7a0f736"
                    ]
                },
                "D-19a": {
                    "questionId": "D-19a",
                    "chosenOptionIds": [
                        "d5d03509-fbd0-500d-a2a9-51acdb2f03db"
                    ]
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "7f1d6182-f8a7-5f4e-a0f8-2556b306ae36"
                    ]
                },
                "D-1bl": {
                    "questionId": "D-1bl",
                    "chosenOptionIds": [
                        "4105c26a-4ff2-5fd7-984d-f0bcb7b5656e"
                    ]
                },
                "D-2": {
                    "questionId": "D-2",
                    "freeValue": 18
                },
                "D-20a": {
                    "questionId": "D-20a",
                    "chosenOptionIds": [
                        "15c694bd-3089-51d8-8fff-6286e2113f9c"
                    ]
                },
                "D-21a": {
                    "questionId": "D-21a",
                    "chosenOptionIds": [
                        "4c0f1be4-1f17-5e09-86a5-e8e8e51285ef"
                    ]
                },
                "D-23a": {
                    "questionId": "D-23a",
                    "chosenOptionIds": [
                        "44fe96b8-192b-5b6b-88fa-9c2f7ba73a6d"
                    ]
                },
                "D-26a": {
                    "questionId": "D-26a",
                    "chosenOptionIds": [
                        "400d260d-4647-5f4d-8105-894b04c366f2"
                    ]
                },
                "D-27a": {
                    "questionId": "D-27a",
                    "chosenOptionIds": [
                        "54c8142c-ee38-51de-ae81-4a95bdcd18ca"
                    ]
                },
                "D-28a": {
                    "questionId": "D-28a",
                    "chosenOptionIds": [
                        "801353cf-2f72-53db-9179-e7ecc31b1fc1"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "de11fb6b-7e7d-52b1-9d09-2d6debc53314"
                    ]
                },
                "D-2bl": {
                    "questionId": "D-2bl",
                    "chosenOptionIds": [
                        "2a8c96e2-cb5b-596a-99b6-a4885253776c"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "fd9c4394-6664-5144-8797-00d27a4dda01"
                    ]
                },
                "D-30a": {
                    "questionId": "D-30a",
                    "chosenOptionIds": [
                        "d141b80e-0ad8-511c-a1e0-b5fe8cae385f"
                    ]
                },
                "D-31a": {
                    "questionId": "D-31a",
                    "chosenOptionIds": [
                        "eba65e81-9b02-55d6-82cb-0b7ce6da41de"
                    ]
                },
                "D-32.1a": {
                    "questionId": "D-32.1a",
                    "chosenOptionIds": [
                        "da39b8b6-3178-5cf4-b68e-92b08cbf087e"
                    ]
                },
                "D-32a": {
                    "questionId": "D-32a",
                    "chosenOptionIds": [
                        "f992ce3a-fffd-5f38-aee4-24930ded9288"
                    ]
                },
                "D-33a": {
                    "questionId": "D-33a",
                    "chosenOptionIds": [
                        "379331e4-3b82-582c-af51-cc4ed79308d7"
                    ]
                },
                "D-34a": {
                    "questionId": "D-34a",
                    "chosenOptionIds": [
                        "e919c54f-d9a4-5a15-9ae7-8b6b52607a22"
                    ]
                },
                "D-35a": {
                    "questionId": "D-35a",
                    "chosenOptionIds": [
                        "bcace469-f380-599d-a33a-26db6d0053ad"
                    ]
                },
                "D-36a": {
                    "questionId": "D-36a",
                    "chosenOptionIds": [
                        "df6b03d1-7322-5277-91ae-7c8e08091a87"
                    ]
                },
                "D-37a": {
                    "questionId": "D-37a",
                    "chosenOptionIds": [
                        "fd4e0608-283c-5f56-a026-edfa381519c9"
                    ]
                },
                "D-38a": {
                    "questionId": "D-38a",
                    "chosenOptionIds": [
                        "39b328fc-f944-51dc-ae4d-9aab44697f32"
                    ]
                },
                "D-39a": {
                    "questionId": "D-39a",
                    "chosenOptionIds": [
                        "6325d970-8ab3-5759-b71d-96b5909ec039"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "db7514e2-d317-55fb-82d3-2e4a49c2494b"
                    ]
                },
                "D-3bl": {
                    "questionId": "D-3bl",
                    "freeValue": 270
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-40a": {
                    "questionId": "D-40a",
                    "chosenOptionIds": [
                        "582119a7-d4a0-58ff-9b1d-2af045e11a7f"
                    ]
                },
                "D-41a": {
                    "questionId": "D-41a",
                    "chosenOptionIds": [
                        "6a5d1a65-0b53-5485-995a-76d8d7eafb1a"
                    ]
                },
                "D-42a": {
                    "questionId": "D-42a",
                    "chosenOptionIds": [
                        "bc912c4c-69a9-5a39-89fb-2072bd945067"
                    ]
                },
                "D-43a": {
                    "questionId": "D-43a",
                    "chosenOptionIds": [
                        "cd0abe72-b3e8-5c78-8330-7ee4a1657536"
                    ]
                },
                "D-43bt": {
                    "questionId": "D-43bt",
                    "chosenOptionIds": [
                        "644dd944-5a2a-533b-9ef0-fadb5054c422"
                    ]
                },
                "D-44.5a": {
                    "questionId": "D-44.5a",
                    "chosenOptionIds": [
                        "4a53cb20-fae8-59f1-934c-ed83e0709795"
                    ]
                },
                "D-44a": {
                    "questionId": "D-44a",
                    "chosenOptionIds": [
                        "5f4eda72-db17-5c72-b0a5-8a71e1644f25"
                    ]
                },
                "D-45a": {
                    "questionId": "D-45a",
                    "freeValue": 4
                },
                "D-46a": {
                    "questionId": "D-46a",
                    "chosenOptionIds": [
                        "d9665b1f-cdd2-5979-895e-27bca6487533",
                        "1fd426df-808a-5c68-9b6e-23ff2f128b7d",
                        "7492eab7-c625-52d0-a2e3-4bf3332decc0",
                        "6e49f368-62af-5abb-9034-a58bd534944f",
                        "b3e43bd8-fbe3-5cda-b891-6f11e43993d0"
                    ]
                },
                "D-49.1a": {
                    "questionId": "D-49.1a",
                    "chosenOptionIds": [
                        "7108af97-c956-5da8-8dfa-518e346c197c"
                    ]
                },
                "D-49.2a": {
                    "questionId": "D-49.2a",
                    "freeValue": 15
                },
                "D-49a": {
                    "questionId": "D-49a",
                    "chosenOptionIds": [
                        "16dc6af1-32a9-59c5-b66c-57f6becea1e0"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "1d4beed1-78e5-531c-a255-643fac260dee",
                        "9d1b858c-7064-5a84-80d6-786c18e603b6"
                    ]
                },
                "D-50a": {
                    "questionId": "D-50a",
                    "chosenOptionIds": [
                        "d14da42d-9612-5827-9583-b2bf528130de"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "c9813959-ba61-5220-9e27-659abb256037"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "3e093dd3-857e-5004-a4fb-961fbfa59ece"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "5d271ac0-beca-56cc-9d0b-adafcd67da53"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "4c78ae3e-7de4-545f-aca1-03fa41fc0c23"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "f8e2518c-72cf-563b-b322-a03406c8ebac"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "60fe0bb4-d770-56f2-b05a-864625a48e4d"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "ccbdcbec-96d0-5362-977b-784289f5cfc0"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "4a0f1c4d-38cf-5de4-99e3-0ee818f9709c"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "6a5aa899-7b89-5661-a554-71b6058ad4a4"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "a3a547f3-b48b-567f-a32a-6a3f5f4bacd7"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "b24df75b-6bb9-5f5f-99e3-c883ec3644ec"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP12": {
                    "questionId": "BP12",
                    "chosenOptionIds": [
                        "22f9a995-3f9a-5fe9-99bb-71b7f1f96621"
                    ]
                },
                "BP15": {
                    "questionId": "BP15",
                    "chosenOptionIds": [
                        "1286eda2-5e31-52dc-95c5-78ded5b43b2a"
                    ]
                },
                "BP15.1": {
                    "questionId": "BP15.1",
                    "chosenOptionIds": [
                        "aa7bd0b8-95ca-5179-a037-3956a8c2816d"
                    ]
                },
                "BP15.2": {
                    "questionId": "BP15.2",
                    "freeValue": 2
                },
                "BP15.3": {
                    "questionId": "BP15.3",
                    "chosenOptionIds": [
                        "cfa519ff-08a9-5fa4-b12d-87b7a597cb13"
                    ]
                },
                "BP16": {
                    "questionId": "BP16",
                    "chosenOptionIds": [
                        "fabceeea-8fea-5444-8b67-8f189f366c27"
                    ]
                },
                "BP16.0": {
                    "questionId": "BP16.0",
                    "chosenOptionIds": [
                        "4b26caa5-9124-51b2-ab08-2f4e977cee1f"
                    ]
                },
                "BP16.01": {
                    "questionId": "BP16.01",
                    "chosenOptionIds": [
                        "f81655d7-78e5-59b1-a7eb-90a2ec7a107d"
                    ]
                },
                "BP16.02": {
                    "questionId": "BP16.02",
                    "chosenOptionIds": [
                        "16a695c9-3ac1-5d34-b799-44602d85b556"
                    ]
                },
                "BP19": {
                    "questionId": "BP19",
                    "chosenOptionIds": [
                        "601bc370-8a4c-5b1f-a7f6-6c174f421733"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 24
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "f03de28d-8f71-5af4-be2f-45f4e6092a3d"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "e620af65-9b5b-57e6-b13a-65616e67e084"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "5f694075-3a97-5f8b-8701-2710a43127a9"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "28c75e9e-94ef-5c50-8645-f4ff93ccf2ec"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "a2056280-4b54-5258-8bb4-407b521fff4b"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "e22cf233-c174-52fb-af55-97bf9c46e971"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "46c90446-3ae9-53f5-9b57-ad19d66b5be6"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "63f0b17a-9832-5f0f-8630-e74f1a4fdfe7"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "c88e3fb5-913a-5c5c-a42b-603f7bb60a40"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "201c7ee3-56dc-51e9-ad55-69951192ab16"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "6ade3a8d-8516-5a88-baa0-f996f32730d2"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "4a7683c6-774e-5362-8060-740f61986c9b"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "58777a2e-4b97-5413-b340-efe722e45936"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "5c06d516-e816-58d6-997e-f8fa91131ac7"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "c0cc09c3-1335-5eb4-8801-b3cdaf74f6fa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM20": {
                    "questionId": "HM20",
                    "chosenOptionIds": [
                        "be55154a-b96f-5c1c-91de-2a118e42e6f8"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "0f8903ea-258c-54c8-9d4d-643924d3c234"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "8f7acbc1-9ebe-5a52-95ce-b50d62c27345"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c41974b6-d394-569c-8f45-49f79fcac5c6"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "0e9545ba-3f35-52b0-9a31-7fe54c933928"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "898957c6-8cd1-53e5-98f8-c16789aba613"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "366c6a1b-c8b2-5faf-9d2f-72dbde24e869"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "2be66549-5767-51b4-82ed-deeab0723f8d"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "aa90f24c-ee55-515b-b5a2-78bdacbe25a0"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "a56fcb96-f868-5d08-a358-9b403eb3cd16"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "fa32b497-4a4b-5076-a391-b6dffa8e1851"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "0360ab4b-77d5-5fcd-8d60-b7a005c12ceb"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "7d6921a2-7e63-5f12-9c75-dff8b592fabc"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: 4EGRG3P aspect: simple_bd1 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "bc1523b6-7d57-5471-90cd-884ef1f5ea5a"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "98772fed-cf55-5d42-b4e1-0e6a1a31ce56"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "826538fc-8a97-55e4-8013-fe6a93f8e451"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 28
                },
                "D-15a": {
                    "questionId": "D-15a",
                    "chosenOptionIds": [
                        "7b763ff0-7703-54f7-8eae-aa83417ca133"
                    ]
                },
                "D-16.5a": {
                    "questionId": "D-16.5a",
                    "chosenOptionIds": [
                        "b409dbb9-80ef-5de1-96d0-1075735134db"
                    ]
                },
                "D-17a": {
                    "questionId": "D-17a",
                    "chosenOptionIds": [
                        "8413ed32-e959-5c2a-9d19-8cc68709c34d"
                    ]
                },
                "D-19a": {
                    "questionId": "D-19a",
                    "chosenOptionIds": [
                        "6fde9b5c-ed1c-561c-b516-8f5028a1bcd5"
                    ]
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "80c993c8-1a94-5684-a8f9-d8f5072c8e9c"
                    ]
                },
                "D-1bl": {
                    "questionId": "D-1bl",
                    "chosenOptionIds": [
                        "4105c26a-4ff2-5fd7-984d-f0bcb7b5656e"
                    ]
                },
                "D-2": {
                    "questionId": "D-2",
                    "freeValue": 27
                },
                "D-20a": {
                    "questionId": "D-20a",
                    "chosenOptionIds": [
                        "b28ed556-a386-5556-a96f-ae7708ded1ab"
                    ]
                },
                "D-21a": {
                    "questionId": "D-21a",
                    "chosenOptionIds": [
                        "79fb502d-53ef-5cbb-a08a-c346d1ba9310"
                    ]
                },
                "D-23a": {
                    "questionId": "D-23a",
                    "chosenOptionIds": [
                        "f6cf63c5-0c57-5422-9431-55fec4c93c63"
                    ]
                },
                "D-26a": {
                    "questionId": "D-26a",
                    "chosenOptionIds": [
                        "eb4d6df4-7e45-5437-a36e-540fa95a45f9"
                    ]
                },
                "D-27a": {
                    "questionId": "D-27a",
                    "chosenOptionIds": [
                        "3d120f97-fb12-5e86-8bb9-8fc93babc803"
                    ]
                },
                "D-28a": {
                    "questionId": "D-28a",
                    "chosenOptionIds": [
                        "009661f3-60b5-52e8-bc5f-44c65767874c"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "cc243947-5067-5a98-ade6-a4e10d0b42cf"
                    ]
                },
                "D-2bl": {
                    "questionId": "D-2bl",
                    "chosenOptionIds": [
                        "2a8c96e2-cb5b-596a-99b6-a4885253776c"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "75d33f2f-6fcd-5e09-8574-899eabeb22e6",
                        "fd9c4394-6664-5144-8797-00d27a4dda01"
                    ]
                },
                "D-30a": {
                    "questionId": "D-30a",
                    "chosenOptionIds": [
                        "1f6e9ea6-3402-596b-a518-31105c7a277b"
                    ]
                },
                "D-31a": {
                    "questionId": "D-31a",
                    "chosenOptionIds": [
                        "c78e0def-d73e-5c4e-8800-7c6f92d0a1ec"
                    ]
                },
                "D-32a": {
                    "questionId": "D-32a",
                    "chosenOptionIds": [
                        "ea2a4fbd-008a-571b-bb68-cbd0ba351521"
                    ]
                },
                "D-33a": {
                    "questionId": "D-33a",
                    "chosenOptionIds": [
                        "3968dd70-f060-5cbd-bb6a-d63f702a3691"
                    ]
                },
                "D-34a": {
                    "questionId": "D-34a",
                    "chosenOptionIds": [
                        "9d969817-a8d1-57f5-8c15-5e89835ed2a4"
                    ]
                },
                "D-35a": {
                    "questionId": "D-35a",
                    "chosenOptionIds": [
                        "3a07e052-2332-5a23-bb19-3147a5b9d2c7"
                    ]
                },
                "D-36a": {
                    "questionId": "D-36a",
                    "chosenOptionIds": [
                        "df6b03d1-7322-5277-91ae-7c8e08091a87"
                    ]
                },
                "D-37a": {
                    "questionId": "D-37a",
                    "chosenOptionIds": [
                        "fd4e0608-283c-5f56-a026-edfa381519c9"
                    ]
                },
                "D-38a": {
                    "questionId": "D-38a",
                    "chosenOptionIds": [
                        "a03f02de-f86d-525a-b375-383ad9d3cefe"
                    ]
                },
                "D-39a": {
                    "questionId": "D-39a",
                    "chosenOptionIds": [
                        "756b91dc-7358-504f-aeab-84552f2bf28b"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "f110f5ec-75f1-51be-9c45-ea7685a54d64"
                    ]
                },
                "D-3bl": {
                    "questionId": "D-3bl",
                    "freeValue": 56
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-40a": {
                    "questionId": "D-40a",
                    "chosenOptionIds": [
                        "582119a7-d4a0-58ff-9b1d-2af045e11a7f"
                    ]
                },
                "D-41a": {
                    "questionId": "D-41a",
                    "chosenOptionIds": [
                        "6a5d1a65-0b53-5485-995a-76d8d7eafb1a"
                    ]
                },
                "D-42a": {
                    "questionId": "D-42a",
                    "chosenOptionIds": [
                        "926931de-e675-5117-9e61-78dd541393be"
                    ]
                },
                "D-43a": {
                    "questionId": "D-43a",
                    "chosenOptionIds": [
                        "cd0abe72-b3e8-5c78-8330-7ee4a1657536"
                    ]
                },
                "D-43bt": {
                    "questionId": "D-43bt",
                    "chosenOptionIds": [
                        "644dd944-5a2a-533b-9ef0-fadb5054c422"
                    ]
                },
                "D-44.5a": {
                    "questionId": "D-44.5a",
                    "chosenOptionIds": [
                        "b5fc3288-710a-5aef-9842-611757b40967",
                        "bcf5fb9f-f4b9-5f0c-a964-69a4e1a73334"
                    ]
                },
                "D-44a": {
                    "questionId": "D-44a",
                    "chosenOptionIds": [
                        "68a987a9-8bad-50d7-a4d9-e970334f0460"
                    ]
                },
                "D-45a": {
                    "questionId": "D-45a",
                    "freeValue": 21
                },
                "D-46a": {
                    "questionId": "D-46a",
                    "chosenOptionIds": [
                        "c2c0e745-9e7b-5f61-8565-fbc0d5c49f71",
                        "1fd426df-808a-5c68-9b6e-23ff2f128b7d",
                        "d9665b1f-cdd2-5979-895e-27bca6487533",
                        "7492eab7-c625-52d0-a2e3-4bf3332decc0",
                        "4c178978-f79a-58fc-8e73-3a98614c4b6c",
                        "6e49f368-62af-5abb-9034-a58bd534944f",
                        "b3e43bd8-fbe3-5cda-b891-6f11e43993d0",
                        "f0b15a45-ae49-59d2-97a7-33f866b0e629"
                    ]
                },
                "D-49.1a": {
                    "questionId": "D-49.1a",
                    "chosenOptionIds": [
                        "7108af97-c956-5da8-8dfa-518e346c197c"
                    ]
                },
                "D-49.2a": {
                    "questionId": "D-49.2a",
                    "freeValue": 350
                },
                "D-49a": {
                    "questionId": "D-49a",
                    "chosenOptionIds": [
                        "16dc6af1-32a9-59c5-b66c-57f6becea1e0"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b",
                        "1d4beed1-78e5-531c-a255-643fac260dee",
                        "9d1b858c-7064-5a84-80d6-786c18e603b6"
                    ]
                },
                "D-50a": {
                    "questionId": "D-50a",
                    "chosenOptionIds": [
                        "d14da42d-9612-5827-9583-b2bf528130de"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "dde1af45-4686-52c9-b960-369527b28f6c"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "08a0f83f-2f37-5904-9364-4e40deb90e98"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "5d271ac0-beca-56cc-9d0b-adafcd67da53"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "33a5fff0-9587-521f-bc53-0dacc36a0bb2"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "44fd9eb3-aee2-5af0-b83c-dbbbf9514022",
                        "4896b504-2025-52c3-85e2-c6cf3205cef7",
                        "07106ab9-5949-5b5f-bc96-117139ebd397",
                        "afcb6534-a0a0-5fe6-9729-1269be20cdf3"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "83e6efd5-64a2-5f0e-a973-9278eae90cfb"
                    ]
                },
                "BP10": {
                    "questionId": "BP10",
                    "chosenOptionIds": [
                        "7ad54f0a-87a6-5e82-bfc3-44b540230082"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "4a0f1c4d-38cf-5de4-99e3-0ee818f9709c"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "6a5aa899-7b89-5661-a554-71b6058ad4a4"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "1daccd4c-e5ff-5f18-ba55-7c2d2d93b849"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "b24df75b-6bb9-5f5f-99e3-c883ec3644ec"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 26
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "c7bee7a0-3037-5eb1-96a1-85b884d7e1dd"
                    ]
                },
                "BP3.01": {
                    "questionId": "BP3.01",
                    "freeValue": 2
                },
                "BP3.1": {
                    "questionId": "BP3.1",
                    "chosenOptionIds": [
                        "c31cf816-04e0-52f4-8da3-04437042c014"
                    ]
                },
                "BP3.2": {
                    "questionId": "BP3.2",
                    "chosenOptionIds": [
                        "ec333f8b-146a-5700-a15d-527032ceac3f"
                    ]
                },
                "BP3.3": {
                    "questionId": "BP3.3",
                    "freeValue": 2
                },
                "BP3.4": {
                    "questionId": "BP3.4",
                    "chosenOptionIds": [
                        "f304bf93-0c68-5da0-80ba-ccecc2d0fce1"
                    ]
                },
                "BP4": {
                    "questionId": "BP4",
                    "chosenOptionIds": [
                        "9765aba8-7d55-5928-b79d-36aa58646036"
                    ]
                },
                "BP4.5": {
                    "questionId": "BP4.5",
                    "chosenOptionIds": [
                        "66a3460c-65ce-5a4c-9774-2e303ae4961e"
                    ]
                },
                "BP6": {
                    "questionId": "BP6",
                    "chosenOptionIds": [
                        "0f2295c8-9598-5e68-a9a6-c87ca1f600af"
                    ]
                },
                "BP6.1": {
                    "questionId": "BP6.1",
                    "chosenOptionIds": [
                        "430b68c6-29cf-5a15-ab4c-89bd842383bf"
                    ]
                },
                "BP6.2": {
                    "questionId": "BP6.2",
                    "freeValue": 2
                },
                "BP6.3": {
                    "questionId": "BP6.3",
                    "chosenOptionIds": [
                        "a19e0696-e780-5235-88b9-f5c36ce0afc3"
                    ]
                },
                "BP8": {
                    "questionId": "BP8",
                    "chosenOptionIds": [
                        "9b3c44f8-4468-5f37-86e9-ed0908e71d5c"
                    ]
                },
                "BP8.0": {
                    "questionId": "BP8.0",
                    "chosenOptionIds": [
                        "3568cdb1-2500-512b-aada-2547c5bfd835"
                    ]
                },
                "BP8.01": {
                    "questionId": "BP8.01",
                    "chosenOptionIds": [
                        "5020bfdd-a280-56cf-b7f5-3b0b7e667165"
                    ]
                },
                "BP8.02": {
                    "questionId": "BP8.02",
                    "chosenOptionIds": [
                        "e88670a3-c6d8-5c96-914e-b9fc1818aeda"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "387b19c0-67d0-557e-9fba-ae065dcf3587"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "f501dc76-7fcd-586b-ba1d-ad70ab8e8d33"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "2404146e-cd56-5356-a23b-6427b4bbba06"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "fd30dc27-8e89-5d1a-9d95-8844603d4379"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "33e48f82-ae80-5f20-a3a5-79692d21ad1a"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "cc7c09ae-1cb7-5b9a-9cd3-8d1012b90ee8"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 42
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "ef5a7b22-b2b2-5a5b-8a80-ce5c251033fd"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "f35fe383-d2aa-51c4-9fde-961b1de6d52b"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "2555d898-6736-5804-951e-e3c438313029"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "de2ea429-4fcd-53b8-8d9f-ebb69a637a02"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "9b27e3ff-f8ce-54c5-9220-0479ae53e889"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "79075051-dcb3-5321-9f27-b21697f8f194"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "3cf77140-2757-58eb-b4ea-f2a90cd766e7"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "5c06d516-e816-58d6-997e-f8fa91131ac7"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "a020ed85-ec9d-5778-8006-41f634cab09d"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM2": {
                    "questionId": "HM2",
                    "chosenOptionIds": [
                        "de662c3f-66b4-566f-856d-2124e2725542"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "98ea07a0-ae95-570f-8d11-a3e7c682e986"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "696fdd9d-5ced-504b-a933-bb3e575b0099"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c41974b6-d394-569c-8f45-49f79fcac5c6"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "43c32319-c75f-5169-b0e5-713820721d4f"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "b2cb304f-f035-53fc-8763-e2f91b031f9b"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "0170b4cf-0541-53cd-a9db-818d5067dc23"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "efcb2ed0-864a-521d-ac0d-99e0fd4b38f5"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "2a17c2fe-2592-58b1-98e9-3dc4168fe3e3"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "0f2a6025-0d8f-5d89-81a2-c3cc425cd7c4"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "fda00d52-c8be-5b69-bdbd-905c13dddd4e"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "8823c74f-ff7c-52d8-9c73-fd3b4180f48a"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "1d07911e-929d-5719-aeea-a68f593d18e8"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: XE72M0L aspect: simple_bd1 should be diagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "bc1523b6-7d57-5471-90cd-884ef1f5ea5a"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "6ede390f-0e43-50e4-82e9-1203db9d8343"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "a0e528ca-2fbb-5da1-9398-0758f79755aa"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 1825
                },
                "D-15a": {
                    "questionId": "D-15a",
                    "chosenOptionIds": [
                        "7b763ff0-7703-54f7-8eae-aa83417ca133"
                    ]
                },
                "D-16.5a": {
                    "questionId": "D-16.5a",
                    "chosenOptionIds": [
                        "b409dbb9-80ef-5de1-96d0-1075735134db"
                    ]
                },
                "D-17a": {
                    "questionId": "D-17a",
                    "chosenOptionIds": [
                        "495d3d07-8a1f-5213-bf79-59443d7aa5cf"
                    ]
                },
                "D-18a": {
                    "questionId": "D-18a",
                    "chosenOptionIds": [
                        "1586e7ea-daf7-5a6d-806f-aee9cc02b7e7"
                    ]
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "80c993c8-1a94-5684-a8f9-d8f5072c8e9c"
                    ]
                },
                "D-1bl": {
                    "questionId": "D-1bl",
                    "chosenOptionIds": [
                        "4105c26a-4ff2-5fd7-984d-f0bcb7b5656e"
                    ]
                },
                "D-2": {
                    "questionId": "D-2",
                    "freeValue": 18
                },
                "D-20a": {
                    "questionId": "D-20a",
                    "chosenOptionIds": [
                        "15c694bd-3089-51d8-8fff-6286e2113f9c"
                    ]
                },
                "D-21a": {
                    "questionId": "D-21a",
                    "chosenOptionIds": [
                        "79fb502d-53ef-5cbb-a08a-c346d1ba9310"
                    ]
                },
                "D-23a": {
                    "questionId": "D-23a",
                    "chosenOptionIds": [
                        "44fe96b8-192b-5b6b-88fa-9c2f7ba73a6d"
                    ]
                },
                "D-26a": {
                    "questionId": "D-26a",
                    "chosenOptionIds": [
                        "eb4d6df4-7e45-5437-a36e-540fa95a45f9"
                    ]
                },
                "D-27a": {
                    "questionId": "D-27a",
                    "chosenOptionIds": [
                        "13a1b08d-9817-5c2f-96cb-f8d03b80a37d"
                    ]
                },
                "D-28a": {
                    "questionId": "D-28a",
                    "chosenOptionIds": [
                        "009661f3-60b5-52e8-bc5f-44c65767874c"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "cc243947-5067-5a98-ade6-a4e10d0b42cf"
                    ]
                },
                "D-2bl": {
                    "questionId": "D-2bl",
                    "chosenOptionIds": [
                        "2a8c96e2-cb5b-596a-99b6-a4885253776c"
                    ]
                },
                "D-2bs": {
                    "questionId": "D-2bs",
                    "chosenOptionIds": [
                        "d9fc40d3-aa51-587a-a54d-305ad26f67ae"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "fd9c4394-6664-5144-8797-00d27a4dda01"
                    ]
                },
                "D-30a": {
                    "questionId": "D-30a",
                    "chosenOptionIds": [
                        "1f6e9ea6-3402-596b-a518-31105c7a277b"
                    ]
                },
                "D-31a": {
                    "questionId": "D-31a",
                    "chosenOptionIds": [
                        "c78e0def-d73e-5c4e-8800-7c6f92d0a1ec"
                    ]
                },
                "D-32a": {
                    "questionId": "D-32a",
                    "chosenOptionIds": [
                        "ea2a4fbd-008a-571b-bb68-cbd0ba351521"
                    ]
                },
                "D-33a": {
                    "questionId": "D-33a",
                    "chosenOptionIds": [
                        "3968dd70-f060-5cbd-bb6a-d63f702a3691"
                    ]
                },
                "D-34a": {
                    "questionId": "D-34a",
                    "chosenOptionIds": [
                        "9d969817-a8d1-57f5-8c15-5e89835ed2a4"
                    ]
                },
                "D-35a": {
                    "questionId": "D-35a",
                    "chosenOptionIds": [
                        "45d31a47-6275-5105-b7d4-eea88b6ba775"
                    ]
                },
                "D-36a": {
                    "questionId": "D-36a",
                    "chosenOptionIds": [
                        "df6b03d1-7322-5277-91ae-7c8e08091a87"
                    ]
                },
                "D-37a": {
                    "questionId": "D-37a",
                    "chosenOptionIds": [
                        "dc9f1b80-2303-5ac8-86ff-e9a39a6c0e16"
                    ]
                },
                "D-38a": {
                    "questionId": "D-38a",
                    "chosenOptionIds": [
                        "cfa17d19-5b15-56ef-82c1-07949e21dd81"
                    ]
                },
                "D-39a": {
                    "questionId": "D-39a",
                    "chosenOptionIds": [
                        "ae31125f-c3db-5082-ae70-4329a2508744"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "f110f5ec-75f1-51be-9c45-ea7685a54d64"
                    ]
                },
                "D-3bl": {
                    "questionId": "D-3bl",
                    "freeValue": 1825
                },
                "D-3bs": {
                    "questionId": "D-3bs",
                    "chosenOptionIds": [
                        "c4422e8a-6a0a-51ea-8592-9157036922d1"
                    ]
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-40a": {
                    "questionId": "D-40a",
                    "chosenOptionIds": [
                        "08157bf8-4ab6-5b83-a59a-9f27ee75995d"
                    ]
                },
                "D-41a": {
                    "questionId": "D-41a",
                    "chosenOptionIds": [
                        "6a5d1a65-0b53-5485-995a-76d8d7eafb1a"
                    ]
                },
                "D-42a": {
                    "questionId": "D-42a",
                    "chosenOptionIds": [
                        "926931de-e675-5117-9e61-78dd541393be"
                    ]
                },
                "D-43a": {
                    "questionId": "D-43a",
                    "chosenOptionIds": [
                        "22b1ff23-c3a7-56b8-8108-65eacd7d3fbe"
                    ]
                },
                "D-43bt": {
                    "questionId": "D-43bt",
                    "chosenOptionIds": [
                        "90e60395-fc3d-56cb-b6f7-2e24fba02803"
                    ]
                },
                "D-44.5a": {
                    "questionId": "D-44.5a",
                    "chosenOptionIds": [
                        "bcf5fb9f-f4b9-5f0c-a964-69a4e1a73334"
                    ]
                },
                "D-44a": {
                    "questionId": "D-44a",
                    "chosenOptionIds": [
                        "68a987a9-8bad-50d7-a4d9-e970334f0460"
                    ]
                },
                "D-45a": {
                    "questionId": "D-45a",
                    "freeValue": 1460
                },
                "D-46a": {
                    "questionId": "D-46a",
                    "chosenOptionIds": [
                        "1fd426df-808a-5c68-9b6e-23ff2f128b7d",
                        "d9665b1f-cdd2-5979-895e-27bca6487533",
                        "7492eab7-c625-52d0-a2e3-4bf3332decc0",
                        "4c178978-f79a-58fc-8e73-3a98614c4b6c",
                        "6e49f368-62af-5abb-9034-a58bd534944f",
                        "b3e43bd8-fbe3-5cda-b891-6f11e43993d0",
                        "f0b15a45-ae49-59d2-97a7-33f866b0e629"
                    ]
                },
                "D-49.1a": {
                    "questionId": "D-49.1a",
                    "chosenOptionIds": [
                        "7108af97-c956-5da8-8dfa-518e346c197c"
                    ]
                },
                "D-49.2a": {
                    "questionId": "D-49.2a",
                    "freeValue": 100
                },
                "D-49a": {
                    "questionId": "D-49a",
                    "chosenOptionIds": [
                        "16dc6af1-32a9-59c5-b66c-57f6becea1e0"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "ba6dc2ba-3c20-57b6-a276-32c863109ff6"
                    ]
                },
                "D-4bs": {
                    "questionId": "D-4bs",
                    "freeValue": 1460
                },
                "D-50a": {
                    "questionId": "D-50a",
                    "chosenOptionIds": [
                        "d14da42d-9612-5827-9583-b2bf528130de"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "188a7831-38c4-55c4-880a-1e4db4e9bab1"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "3e093dd3-857e-5004-a4fb-961fbfa59ece"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "5d271ac0-beca-56cc-9d0b-adafcd67da53"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "67316d94-1cf0-5bd0-a4db-f57bc70684ba"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "60fe0bb4-d770-56f2-b05a-864625a48e4d"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "83e6efd5-64a2-5f0e-a973-9278eae90cfb"
                    ]
                },
                "BP10": {
                    "questionId": "BP10",
                    "chosenOptionIds": [
                        "7ad54f0a-87a6-5e82-bfc3-44b540230082"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "182e121d-415c-5f42-a76c-a996f5f38b3f"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "1c4ed441-5919-569e-98ba-b49be1bc3957"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "e76cac02-f6b2-5ab6-a467-e3025d857ab9"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "f2228920-3fec-5097-a63d-990c25d50c6c"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP105": {
                    "questionId": "BP105",
                    "chosenOptionIds": [
                        "4e9d21b3-29c7-5526-ada9-67b61957994e"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 24
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "c7bee7a0-3037-5eb1-96a1-85b884d7e1dd"
                    ]
                },
                "BP3.01": {
                    "questionId": "BP3.01",
                    "freeValue": 100
                },
                "BP3.1": {
                    "questionId": "BP3.1",
                    "chosenOptionIds": [
                        "11a8ab35-b2d7-55a4-8a77-809dc8f1f78a"
                    ]
                },
                "BP3.2": {
                    "questionId": "BP3.2",
                    "chosenOptionIds": [
                        "ec333f8b-146a-5700-a15d-527032ceac3f"
                    ]
                },
                "BP3.3": {
                    "questionId": "BP3.3",
                    "freeValue": 14
                },
                "BP3.4": {
                    "questionId": "BP3.4",
                    "chosenOptionIds": [
                        "f304bf93-0c68-5da0-80ba-ccecc2d0fce1"
                    ]
                },
                "BP4": {
                    "questionId": "BP4",
                    "chosenOptionIds": [
                        "9765aba8-7d55-5928-b79d-36aa58646036"
                    ]
                },
                "BP4.5": {
                    "questionId": "BP4.5",
                    "chosenOptionIds": [
                        "1be4b3b1-3a88-5544-af54-ebdfee1ce891"
                    ]
                },
                "BP6": {
                    "questionId": "BP6",
                    "chosenOptionIds": [
                        "33ae5abe-63ad-57d4-86a4-0dcd33576094"
                    ]
                },
                "BP6.1": {
                    "questionId": "BP6.1",
                    "chosenOptionIds": [
                        "f0b738f5-44c3-5a6c-9a31-ab7878568a81"
                    ]
                },
                "BP6.2": {
                    "questionId": "BP6.2",
                    "freeValue": 14
                },
                "BP6.3": {
                    "questionId": "BP6.3",
                    "chosenOptionIds": [
                        "a19e0696-e780-5235-88b9-f5c36ce0afc3"
                    ]
                },
                "BP8": {
                    "questionId": "BP8",
                    "chosenOptionIds": [
                        "87e0081b-9455-5ced-9225-10c2f70b1734"
                    ]
                },
                "BP8.0": {
                    "questionId": "BP8.0",
                    "chosenOptionIds": [
                        "3568cdb1-2500-512b-aada-2547c5bfd835"
                    ]
                },
                "BP8.01": {
                    "questionId": "BP8.01",
                    "chosenOptionIds": [
                        "5020bfdd-a280-56cf-b7f5-3b0b7e667165"
                    ]
                },
                "BP8.02": {
                    "questionId": "BP8.02",
                    "chosenOptionIds": [
                        "e88670a3-c6d8-5c96-914e-b9fc1818aeda"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "960a08ff-7af7-56da-afda-5415a1654c85"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "a4ed572c-6aae-57f6-bc35-ea46929c1437"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "2404146e-cd56-5356-a23b-6427b4bbba06"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "fd30dc27-8e89-5d1a-9d95-8844603d4379"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "33e48f82-ae80-5f20-a3a5-79692d21ad1a"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "46c90446-3ae9-53f5-9b57-ad19d66b5be6"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 1460
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "ef5a7b22-b2b2-5a5b-8a80-ce5c251033fd"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "940c16a8-1835-582a-9dad-bd52e3a49cc7"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "1e86d64a-b972-500a-9120-7884e4b86ede"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "6928c696-e6d7-5a14-a004-15a497ac6562"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "2723ed09-37f6-522c-92b1-e518d4fe9315"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "cfb8fe0e-821a-554a-bb57-922f0ecc0f44"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "63509127-aadf-5fe2-8e1f-19d0d846d6ce"
                    ]
                },
                "BP99.1": {
                    "questionId": "BP99.1",
                    "chosenOptionIds": [
                        "34d19f12-207f-56ce-bde8-8d616f6e8b26"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "e81fe613-4923-55ed-bd6c-bd3f19e026f9"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM2": {
                    "questionId": "HM2",
                    "chosenOptionIds": [
                        "a046d610-d27a-59c1-b130-38312005cf1c"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "c3ee8981-dec3-5b3c-80ca-c1023f983a6d"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "a43647cb-a0e8-5705-adb9-a2b68877dc77"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c41974b6-d394-569c-8f45-49f79fcac5c6"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "43c32319-c75f-5169-b0e5-713820721d4f"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "d2c670c4-b05b-597a-a0af-5342230535a0"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "cec918d6-b855-5672-94a8-cf14e5ae963a"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "7b310da1-94e3-5d45-a6c3-3905eeb28ec6"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "2a17c2fe-2592-58b1-98e9-3dc4168fe3e3"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "7e32475a-3fcc-51ac-b414-ab46578efdfd"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "fda00d52-c8be-5b69-bdbd-905c13dddd4e"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "e95276b0-ee81-580b-b532-b0215f04abd5"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "4b8e4843-d260-59e5-8468-1303b1c3a638"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: 7EW6KE8 aspect: simple_bd1 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "3bd18f66-6f1e-5ea4-ae24-2990ed197bef"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "98772fed-cf55-5d42-b4e1-0e6a1a31ce56"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "a0e528ca-2fbb-5da1-9398-0758f79755aa"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 1825
                },
                "D-15a": {
                    "questionId": "D-15a",
                    "chosenOptionIds": [
                        "7b763ff0-7703-54f7-8eae-aa83417ca133"
                    ]
                },
                "D-16.5a": {
                    "questionId": "D-16.5a",
                    "chosenOptionIds": [
                        "b409dbb9-80ef-5de1-96d0-1075735134db"
                    ]
                },
                "D-17a": {
                    "questionId": "D-17a",
                    "chosenOptionIds": [
                        "8413ed32-e959-5c2a-9d19-8cc68709c34d"
                    ]
                },
                "D-19a": {
                    "questionId": "D-19a",
                    "chosenOptionIds": [
                        "6fde9b5c-ed1c-561c-b516-8f5028a1bcd5"
                    ]
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "7f1d6182-f8a7-5f4e-a0f8-2556b306ae36"
                    ]
                },
                "D-1bl": {
                    "questionId": "D-1bl",
                    "chosenOptionIds": [
                        "87ef50c9-ba5e-513e-8889-09d8395d4b9f"
                    ]
                },
                "D-2": {
                    "questionId": "D-2",
                    "freeValue": 25
                },
                "D-20a": {
                    "questionId": "D-20a",
                    "chosenOptionIds": [
                        "15c694bd-3089-51d8-8fff-6286e2113f9c"
                    ]
                },
                "D-21a": {
                    "questionId": "D-21a",
                    "chosenOptionIds": [
                        "dc2b002f-2d96-504d-bde9-66faceececb6"
                    ]
                },
                "D-23a": {
                    "questionId": "D-23a",
                    "chosenOptionIds": [
                        "f6cf63c5-0c57-5422-9431-55fec4c93c63"
                    ]
                },
                "D-26a": {
                    "questionId": "D-26a",
                    "chosenOptionIds": [
                        "400d260d-4647-5f4d-8105-894b04c366f2"
                    ]
                },
                "D-27a": {
                    "questionId": "D-27a",
                    "chosenOptionIds": [
                        "8896026c-15ab-577e-8c44-e63eb7e6dba3"
                    ]
                },
                "D-28a": {
                    "questionId": "D-28a",
                    "chosenOptionIds": [
                        "ffc9781f-639e-5caa-9d2e-16250d30bf6a"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "c695fd1c-2cb7-5654-b9f7-b32ecb158229"
                    ]
                },
                "D-2bl": {
                    "questionId": "D-2bl",
                    "chosenOptionIds": [
                        "2a8c96e2-cb5b-596a-99b6-a4885253776c"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "e797900f-129d-553e-b83f-8494d7f03986"
                    ]
                },
                "D-30a": {
                    "questionId": "D-30a",
                    "chosenOptionIds": [
                        "d309cef4-2204-5a10-a98d-b66740336838"
                    ]
                },
                "D-31a": {
                    "questionId": "D-31a",
                    "chosenOptionIds": [
                        "eba65e81-9b02-55d6-82cb-0b7ce6da41de"
                    ]
                },
                "D-32a": {
                    "questionId": "D-32a",
                    "chosenOptionIds": [
                        "ea2a4fbd-008a-571b-bb68-cbd0ba351521"
                    ]
                },
                "D-33a": {
                    "questionId": "D-33a",
                    "chosenOptionIds": [
                        "3968dd70-f060-5cbd-bb6a-d63f702a3691"
                    ]
                },
                "D-34a": {
                    "questionId": "D-34a",
                    "chosenOptionIds": [
                        "6bfab4b8-7d57-5039-8933-4dee0c958a19"
                    ]
                },
                "D-35a": {
                    "questionId": "D-35a",
                    "chosenOptionIds": [
                        "bcace469-f380-599d-a33a-26db6d0053ad"
                    ]
                },
                "D-36a": {
                    "questionId": "D-36a",
                    "chosenOptionIds": [
                        "4ab32dca-be9e-573e-a330-24f9350ffaca"
                    ]
                },
                "D-37a": {
                    "questionId": "D-37a",
                    "chosenOptionIds": [
                        "c8ebfb14-b9aa-5da4-8de3-ab8b83254249"
                    ]
                },
                "D-38a": {
                    "questionId": "D-38a",
                    "chosenOptionIds": [
                        "8d5bbc66-0b33-5de5-838e-ff0fccbb6fbc"
                    ]
                },
                "D-39a": {
                    "questionId": "D-39a",
                    "chosenOptionIds": [
                        "a2f071cb-74be-518d-a33d-12a101ac9770"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "db7514e2-d317-55fb-82d3-2e4a49c2494b"
                    ]
                },
                "D-3bl": {
                    "questionId": "D-3bl",
                    "freeValue": 60
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-40a": {
                    "questionId": "D-40a",
                    "chosenOptionIds": [
                        "08157bf8-4ab6-5b83-a59a-9f27ee75995d"
                    ]
                },
                "D-41a": {
                    "questionId": "D-41a",
                    "chosenOptionIds": [
                        "cb89e9b0-6872-5961-bc91-049308b5860f"
                    ]
                },
                "D-42a": {
                    "questionId": "D-42a",
                    "chosenOptionIds": [
                        "7099f8a9-4650-5da2-a627-bcd62cd99375"
                    ]
                },
                "D-43a": {
                    "questionId": "D-43a",
                    "chosenOptionIds": [
                        "cd0abe72-b3e8-5c78-8330-7ee4a1657536"
                    ]
                },
                "D-43bt": {
                    "questionId": "D-43bt",
                    "chosenOptionIds": [
                        "644dd944-5a2a-533b-9ef0-fadb5054c422"
                    ]
                },
                "D-44.5a": {
                    "questionId": "D-44.5a",
                    "chosenOptionIds": [
                        "b5fc3288-710a-5aef-9842-611757b40967",
                        "884767e3-ed0c-5db3-a227-f84f7ecaf8d3"
                    ]
                },
                "D-44a": {
                    "questionId": "D-44a",
                    "chosenOptionIds": [
                        "57c0e55d-5ee4-5237-9c5d-e317b90c5dbd"
                    ]
                },
                "D-45a": {
                    "questionId": "D-45a",
                    "freeValue": 0
                },
                "D-46a": {
                    "questionId": "D-46a",
                    "chosenOptionIds": [
                        "1fd426df-808a-5c68-9b6e-23ff2f128b7d",
                        "d9665b1f-cdd2-5979-895e-27bca6487533",
                        "7492eab7-c625-52d0-a2e3-4bf3332decc0",
                        "6e49f368-62af-5abb-9034-a58bd534944f",
                        "4c178978-f79a-58fc-8e73-3a98614c4b6c",
                        "b3e43bd8-fbe3-5cda-b891-6f11e43993d0",
                        "f0b15a45-ae49-59d2-97a7-33f866b0e629"
                    ]
                },
                "D-49.1a": {
                    "questionId": "D-49.1a",
                    "chosenOptionIds": [
                        "7108af97-c956-5da8-8dfa-518e346c197c"
                    ]
                },
                "D-49.2a": {
                    "questionId": "D-49.2a",
                    "freeValue": 7
                },
                "D-49a": {
                    "questionId": "D-49a",
                    "chosenOptionIds": [
                        "16dc6af1-32a9-59c5-b66c-57f6becea1e0"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b"
                    ]
                },
                "D-50a": {
                    "questionId": "D-50a",
                    "chosenOptionIds": [
                        "d14da42d-9612-5827-9583-b2bf528130de"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "dde1af45-4686-52c9-b960-369527b28f6c"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "08a0f83f-2f37-5904-9364-4e40deb90e98"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "772bee29-c3a5-52e1-b785-a77d506ae4fb"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "67316d94-1cf0-5bd0-a4db-f57bc70684ba"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "f8e2518c-72cf-563b-b322-a03406c8ebac"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "60fe0bb4-d770-56f2-b05a-864625a48e4d"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "7599bcaa-89a5-5f71-80ea-b70c360bb44a"
                    ]
                },
                "BP101b": {
                    "questionId": "BP101b",
                    "chosenOptionIds": [
                        "31b2d2c8-8f28-514e-9e58-aa878f6c8761"
                    ]
                },
                "BP102b": {
                    "questionId": "BP102b",
                    "chosenOptionIds": [
                        "00afc1e6-b78a-5223-a6e7-5d3aaab833e0"
                    ]
                },
                "BP103b": {
                    "questionId": "BP103b",
                    "chosenOptionIds": [
                        "867b0ee4-73d9-5aac-b96d-bc2d85d5b183"
                    ]
                },
                "BP104b": {
                    "questionId": "BP104b",
                    "chosenOptionIds": [
                        "c59b5910-3279-509f-8175-f2fd0da2dc86"
                    ]
                },
                "BP87b": {
                    "questionId": "BP87b",
                    "chosenOptionIds": [
                        "fb568891-2ea3-5200-bbdb-ac0695f30b02"
                    ]
                },
                "BP88b": {
                    "questionId": "BP88b",
                    "chosenOptionIds": [
                        "e29e159f-f691-5aa7-b5a0-d13fe2c54170"
                    ]
                },
                "BP89b": {
                    "questionId": "BP89b",
                    "chosenOptionIds": [
                        "db509963-d872-5b97-ba9b-a276f58e0189"
                    ]
                },
                "BP90b": {
                    "questionId": "BP90b",
                    "chosenOptionIds": [
                        "b4a79888-68e8-5b52-9a4e-2149be85f6e7"
                    ]
                },
                "BP92b": {
                    "questionId": "BP92b",
                    "chosenOptionIds": [
                        "f59918ba-eb3a-5cf1-81aa-8b7f6ac0df5e"
                    ]
                },
                "BP94b": {
                    "questionId": "BP94b",
                    "chosenOptionIds": [
                        "1f4fafbf-913e-5edc-bfaf-3baae49fc91d"
                    ]
                },
                "BP95b": {
                    "questionId": "BP95b",
                    "chosenOptionIds": [
                        "32255970-db0f-5cc0-a199-d412edf7ab7c"
                    ]
                },
                "BP96b": {
                    "questionId": "BP96b",
                    "chosenOptionIds": [
                        "8c3d3503-6334-55b9-b0f0-94c8e520094e"
                    ]
                },
                "BP97b": {
                    "questionId": "BP97b",
                    "chosenOptionIds": [
                        "8fa0f169-f510-5c9e-a4be-fbcc964f7480"
                    ]
                },
                "BP98b": {
                    "questionId": "BP98b",
                    "chosenOptionIds": [
                        "1385fe83-520e-5986-8bec-9f0630d48219"
                    ]
                },
                "BP99.1b": {
                    "questionId": "BP99.1b",
                    "chosenOptionIds": [
                        "474c64d7-23b9-5015-9cdd-60661ad38d39"
                    ]
                },
                "BP99.2b": {
                    "questionId": "BP99.2b",
                    "chosenOptionIds": [
                        "32f6041f-9e2c-537c-9db7-19f89011fce9"
                    ]
                },
                "BP99.3b": {
                    "questionId": "BP99.3b",
                    "chosenOptionIds": [
                        "bf30a6c2-f8e2-5305-b4d4-7ad6227449d3"
                    ]
                },
                "BP99b": {
                    "questionId": "BP99b",
                    "chosenOptionIds": [
                        "96faf550-f157-55b9-8f15-6be82cd511a8",
                        "3f987aaa-e435-55a9-9c1b-aa6d2e476738"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "BP_neg1": {
                    "questionId": "BP_neg1",
                    "chosenOptionIds": [
                        "119208b0-ff3b-5c43-80be-4f07e65b6248"
                    ]
                }
            }
        },
        {
            "name": "Mood test: Y32LM08 aspect: simple_bd1 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "c61d70d4-b773-54dd-94d0-d858a47be0fb"
                    ]
                },
                "D-1.5b": {
                    "questionId": "D-1.5b",
                    "freeValue": 4
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "45f0ce85-9e19-53da-bc0d-2af8b383318a"
                    ]
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "7a5958fc-e0f7-5d43-9cd0-abfe95cb7026"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-1bl": {
                    "questionId": "D-1bl",
                    "chosenOptionIds": [
                        "5da400b0-5bff-5744-a664-4305892ae669"
                    ]
                },
                "D-2": {
                    "questionId": "D-2",
                    "freeValue": 28
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "9222be3e-443f-5146-9d69-d48fe9041ba0"
                    ]
                },
                "D-2bl": {
                    "questionId": "D-2bl",
                    "chosenOptionIds": [
                        "2a8c96e2-cb5b-596a-99b6-a4885253776c"
                    ]
                },
                "D-2bt": {
                    "questionId": "D-2bt",
                    "chosenOptionIds": [
                        "90222879-e5d9-50a0-bfbe-a5cbadbff017"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "fd9c4394-6664-5144-8797-00d27a4dda01"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "77c6088d-2d8f-5c3b-89c9-79cb0d317226"
                    ]
                },
                "D-3bl": {
                    "questionId": "D-3bl",
                    "freeValue": 28
                },
                "D-3bt": {
                    "questionId": "D-3bt",
                    "chosenOptionIds": [
                        "b9abc28d-6e22-5b67-bc5e-27431e123974"
                    ]
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-42bt": {
                    "questionId": "D-42bt",
                    "freeValue": 0
                },
                "D-43bt": {
                    "questionId": "D-43bt",
                    "chosenOptionIds": [
                        "644dd944-5a2a-533b-9ef0-fadb5054c422"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "a449e4ae-663b-50fb-9b62-1708b0a32669"
                    ]
                },
                "D-4bt": {
                    "questionId": "D-4bt",
                    "freeValue": 21
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "f3efc764-ca53-52c3-9c3a-38c7f787d415"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "9506449e-b2aa-5498-b896-901dd248b1b3"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "ef6fddab-7a07-5f20-af20-74f2444eee53"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "3b216cf8-72e7-558c-bdd3-cc23a1865674"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "D-s10bt": {
                    "questionId": "D-s10bt",
                    "chosenOptionIds": [
                        "3deea42c-5d93-54cd-ba0f-4242654c3747"
                    ]
                },
                "D-s1bt": {
                    "questionId": "D-s1bt",
                    "chosenOptionIds": [
                        "33dd907f-3e0e-5735-96bb-c101f0acced9"
                    ]
                },
                "D-s2bt": {
                    "questionId": "D-s2bt",
                    "chosenOptionIds": [
                        "e074592a-4667-5b65-b713-b8892451b433"
                    ]
                },
                "D-s3bt": {
                    "questionId": "D-s3bt",
                    "chosenOptionIds": [
                        "11f57889-ac93-53ab-88a7-9aea03a29429"
                    ]
                },
                "D-s4bt": {
                    "questionId": "D-s4bt",
                    "chosenOptionIds": [
                        "fd9851a0-7ce7-55a8-b257-5617537d0013",
                        "27313271-8ec6-5f99-bb2c-0ed50a7c1527"
                    ]
                },
                "D-s5bt": {
                    "questionId": "D-s5bt",
                    "chosenOptionIds": [
                        "d033bb4c-5d3c-519b-be31-1177042bffb4"
                    ]
                },
                "D-s6bt": {
                    "questionId": "D-s6bt",
                    "chosenOptionIds": [
                        "f7aa2ac0-a087-5371-8266-7601901cd1f9"
                    ]
                },
                "D-s7bt": {
                    "questionId": "D-s7bt",
                    "chosenOptionIds": [
                        "d703f0d6-4b67-5a66-8740-b5e4b3b0eb4d"
                    ]
                },
                "D-s8bt": {
                    "questionId": "D-s8bt",
                    "chosenOptionIds": [
                        "b5d48aea-22b2-52a7-9306-3f2a4bcedc6c"
                    ]
                },
                "D-s9bt": {
                    "questionId": "D-s9bt",
                    "chosenOptionIds": [
                        "da503bbd-e567-576e-a21f-e0fad60dccdd"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "44fd9eb3-aee2-5af0-b83c-dbbbf9514022"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "7599bcaa-89a5-5f71-80ea-b70c360bb44a"
                    ]
                },
                "BP101b": {
                    "questionId": "BP101b",
                    "chosenOptionIds": [
                        "cb80bcac-db78-55bd-b051-bdd00801fd54"
                    ]
                },
                "BP102b": {
                    "questionId": "BP102b",
                    "chosenOptionIds": [
                        "46a64b5b-6641-5b1b-92aa-5366444fb134"
                    ]
                },
                "BP103b": {
                    "questionId": "BP103b",
                    "chosenOptionIds": [
                        "867b0ee4-73d9-5aac-b96d-bc2d85d5b183"
                    ]
                },
                "BP104b": {
                    "questionId": "BP104b",
                    "chosenOptionIds": [
                        "c59b5910-3279-509f-8175-f2fd0da2dc86"
                    ]
                },
                "BP87b": {
                    "questionId": "BP87b",
                    "chosenOptionIds": [
                        "7949bd96-3757-5573-bd89-3a91a383cc99"
                    ]
                },
                "BP88b": {
                    "questionId": "BP88b",
                    "chosenOptionIds": [
                        "fa24b924-a8e1-5f02-893b-3d9643007991"
                    ]
                },
                "BP89b": {
                    "questionId": "BP89b",
                    "chosenOptionIds": [
                        "41d2829a-3971-552f-8c5a-c77e7d0073e7"
                    ]
                },
                "BP90b": {
                    "questionId": "BP90b",
                    "chosenOptionIds": [
                        "95c2822d-27ad-5bb0-982f-8c2b949c8918"
                    ]
                },
                "BP92b": {
                    "questionId": "BP92b",
                    "chosenOptionIds": [
                        "b2f4c490-30df-5c8f-87c0-bc4acbcfb8e0"
                    ]
                },
                "BP94b": {
                    "questionId": "BP94b",
                    "chosenOptionIds": [
                        "038cb12b-2783-569a-b1d7-e28c65e591a3"
                    ]
                },
                "BP95b": {
                    "questionId": "BP95b",
                    "chosenOptionIds": [
                        "fb0bd4a7-2793-5b81-badc-fe81b4bc82dc"
                    ]
                },
                "BP96b": {
                    "questionId": "BP96b",
                    "chosenOptionIds": [
                        "8c3d3503-6334-55b9-b0f0-94c8e520094e"
                    ]
                },
                "BP97b": {
                    "questionId": "BP97b",
                    "chosenOptionIds": [
                        "6895096a-ef29-546e-9967-d7a23b4a2859"
                    ]
                },
                "BP98b": {
                    "questionId": "BP98b",
                    "chosenOptionIds": [
                        "01641419-fbfc-52a5-ae63-0fa736ff9847"
                    ]
                },
                "BP99.3b": {
                    "questionId": "BP99.3b",
                    "chosenOptionIds": [
                        "bf30a6c2-f8e2-5305-b4d4-7ad6227449d3"
                    ]
                },
                "BP99b": {
                    "questionId": "BP99b",
                    "chosenOptionIds": [
                        "68860df1-e835-50d6-8eb2-f9d9f2f97efa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "BP_neg1": {
                    "questionId": "BP_neg1",
                    "chosenOptionIds": [
                        "119208b0-ff3b-5c43-80be-4f07e65b6248"
                    ]
                }
            }
        },
        {
            "name": "Mood test: 63YM43R aspect: simple_bd1 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "3bd18f66-6f1e-5ea4-ae24-2990ed197bef"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "6ede390f-0e43-50e4-82e9-1203db9d8343"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "826538fc-8a97-55e4-8013-fe6a93f8e451"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 540
                },
                "D-15a": {
                    "questionId": "D-15a",
                    "chosenOptionIds": [
                        "7b763ff0-7703-54f7-8eae-aa83417ca133"
                    ]
                },
                "D-16.5a": {
                    "questionId": "D-16.5a",
                    "chosenOptionIds": [
                        "ae0a4d4e-e9bf-51bf-8506-0cbced38db92"
                    ]
                },
                "D-17a": {
                    "questionId": "D-17a",
                    "chosenOptionIds": [
                        "495d3d07-8a1f-5213-bf79-59443d7aa5cf"
                    ]
                },
                "D-19a": {
                    "questionId": "D-19a",
                    "chosenOptionIds": [
                        "b956a09d-6c39-5acc-a8d7-9024b1b2a2b3"
                    ]
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "7f1d6182-f8a7-5f4e-a0f8-2556b306ae36"
                    ]
                },
                "D-1bl": {
                    "questionId": "D-1bl",
                    "chosenOptionIds": [
                        "4105c26a-4ff2-5fd7-984d-f0bcb7b5656e"
                    ]
                },
                "D-2": {
                    "questionId": "D-2",
                    "freeValue": 30
                },
                "D-20a": {
                    "questionId": "D-20a",
                    "chosenOptionIds": [
                        "795e06c8-4055-5583-af5a-e7eafe89bb56"
                    ]
                },
                "D-21a": {
                    "questionId": "D-21a",
                    "chosenOptionIds": [
                        "e64b46f6-a0c1-5378-95de-f1de59b491b4"
                    ]
                },
                "D-23a": {
                    "questionId": "D-23a",
                    "chosenOptionIds": [
                        "44fe96b8-192b-5b6b-88fa-9c2f7ba73a6d"
                    ]
                },
                "D-26a": {
                    "questionId": "D-26a",
                    "chosenOptionIds": [
                        "eb4d6df4-7e45-5437-a36e-540fa95a45f9"
                    ]
                },
                "D-27a": {
                    "questionId": "D-27a",
                    "chosenOptionIds": [
                        "13a1b08d-9817-5c2f-96cb-f8d03b80a37d"
                    ]
                },
                "D-28a": {
                    "questionId": "D-28a",
                    "chosenOptionIds": [
                        "801353cf-2f72-53db-9179-e7ecc31b1fc1"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "c695fd1c-2cb7-5654-b9f7-b32ecb158229"
                    ]
                },
                "D-2bl": {
                    "questionId": "D-2bl",
                    "chosenOptionIds": [
                        "2a8c96e2-cb5b-596a-99b6-a4885253776c"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "fd9c4394-6664-5144-8797-00d27a4dda01"
                    ]
                },
                "D-30a": {
                    "questionId": "D-30a",
                    "chosenOptionIds": [
                        "d309cef4-2204-5a10-a98d-b66740336838"
                    ]
                },
                "D-31a": {
                    "questionId": "D-31a",
                    "chosenOptionIds": [
                        "c78e0def-d73e-5c4e-8800-7c6f92d0a1ec"
                    ]
                },
                "D-32a": {
                    "questionId": "D-32a",
                    "chosenOptionIds": [
                        "ea2a4fbd-008a-571b-bb68-cbd0ba351521"
                    ]
                },
                "D-33a": {
                    "questionId": "D-33a",
                    "chosenOptionIds": [
                        "3968dd70-f060-5cbd-bb6a-d63f702a3691"
                    ]
                },
                "D-34a": {
                    "questionId": "D-34a",
                    "chosenOptionIds": [
                        "e919c54f-d9a4-5a15-9ae7-8b6b52607a22"
                    ]
                },
                "D-35a": {
                    "questionId": "D-35a",
                    "chosenOptionIds": [
                        "3a07e052-2332-5a23-bb19-3147a5b9d2c7"
                    ]
                },
                "D-36a": {
                    "questionId": "D-36a",
                    "chosenOptionIds": [
                        "7fb54260-7d7e-5c80-809e-584be2bfee10"
                    ]
                },
                "D-37a": {
                    "questionId": "D-37a",
                    "chosenOptionIds": [
                        "fd4e0608-283c-5f56-a026-edfa381519c9"
                    ]
                },
                "D-38a": {
                    "questionId": "D-38a",
                    "chosenOptionIds": [
                        "39b328fc-f944-51dc-ae4d-9aab44697f32"
                    ]
                },
                "D-39a": {
                    "questionId": "D-39a",
                    "chosenOptionIds": [
                        "6325d970-8ab3-5759-b71d-96b5909ec039"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5b6c0a69-6369-53d1-b2e1-5cd6294a9f71"
                    ]
                },
                "D-3bl": {
                    "questionId": "D-3bl",
                    "freeValue": 365
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-40a": {
                    "questionId": "D-40a",
                    "chosenOptionIds": [
                        "7cc72838-ebcf-5802-933c-4100ebc01407"
                    ]
                },
                "D-41a": {
                    "questionId": "D-41a",
                    "chosenOptionIds": [
                        "89f5ff80-0cae-515a-a4d1-02448dbc11b8"
                    ]
                },
                "D-42a": {
                    "questionId": "D-42a",
                    "chosenOptionIds": [
                        "926931de-e675-5117-9e61-78dd541393be"
                    ]
                },
                "D-43a": {
                    "questionId": "D-43a",
                    "chosenOptionIds": [
                        "dc3faaa6-4a47-5998-83e3-4bbff8838194"
                    ]
                },
                "D-43bt": {
                    "questionId": "D-43bt",
                    "chosenOptionIds": [
                        "644dd944-5a2a-533b-9ef0-fadb5054c422"
                    ]
                },
                "D-44.5a": {
                    "questionId": "D-44.5a",
                    "chosenOptionIds": [
                        "b5fc3288-710a-5aef-9842-611757b40967",
                        "d87971dc-d745-5b64-ac37-37fbe1e722ac"
                    ]
                },
                "D-44a": {
                    "questionId": "D-44a",
                    "chosenOptionIds": [
                        "57c0e55d-5ee4-5237-9c5d-e317b90c5dbd"
                    ]
                },
                "D-45a": {
                    "questionId": "D-45a",
                    "freeValue": 15
                },
                "D-46a": {
                    "questionId": "D-46a",
                    "chosenOptionIds": [
                        "1fd426df-808a-5c68-9b6e-23ff2f128b7d",
                        "d9665b1f-cdd2-5979-895e-27bca6487533",
                        "7492eab7-c625-52d0-a2e3-4bf3332decc0",
                        "6e49f368-62af-5abb-9034-a58bd534944f",
                        "f0b15a45-ae49-59d2-97a7-33f866b0e629",
                        "c2c0e745-9e7b-5f61-8565-fbc0d5c49f71"
                    ]
                },
                "D-49.1a": {
                    "questionId": "D-49.1a",
                    "chosenOptionIds": [
                        "7108af97-c956-5da8-8dfa-518e346c197c"
                    ]
                },
                "D-49.2a": {
                    "questionId": "D-49.2a",
                    "freeValue": 2
                },
                "D-49a": {
                    "questionId": "D-49a",
                    "chosenOptionIds": [
                        "16dc6af1-32a9-59c5-b66c-57f6becea1e0"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "9d1b858c-7064-5a84-80d6-786c18e603b6",
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b"
                    ]
                },
                "D-50a": {
                    "questionId": "D-50a",
                    "chosenOptionIds": [
                        "d14da42d-9612-5827-9583-b2bf528130de"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "c9813959-ba61-5220-9e27-659abb256037"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "3e093dd3-857e-5004-a4fb-961fbfa59ece"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "772bee29-c3a5-52e1-b785-a77d506ae4fb"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "4c78ae3e-7de4-545f-aca1-03fa41fc0c23"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "13f8bac1-d0f5-539b-8596-1c55821bea46"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "44fd9eb3-aee2-5af0-b83c-dbbbf9514022",
                        "c2921864-bbf7-5735-9313-848fb9d5a6d1"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "ccbdcbec-96d0-5362-977b-784289f5cfc0"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "4a0f1c4d-38cf-5de4-99e3-0ee818f9709c"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "6a5aa899-7b89-5661-a554-71b6058ad4a4"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "d3224e16-1b0c-5266-9e14-ac72d2b2e988"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "b24df75b-6bb9-5f5f-99e3-c883ec3644ec"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP12": {
                    "questionId": "BP12",
                    "chosenOptionIds": [
                        "1d6299ac-2ac6-51e2-9a3f-13e8d44e652a"
                    ]
                },
                "BP12.5": {
                    "questionId": "BP12.5",
                    "chosenOptionIds": [
                        "cd7ce00f-4e7c-57ac-9c6c-4137efa24c8c"
                    ]
                },
                "BP14": {
                    "questionId": "BP14",
                    "chosenOptionIds": [
                        "a3126449-b062-5872-80a1-5950acf6bfa4"
                    ]
                },
                "BP14.1": {
                    "questionId": "BP14.1",
                    "chosenOptionIds": [
                        "69f9a784-ce6d-5c82-bd3f-4c9c44bf5992"
                    ]
                },
                "BP14.2": {
                    "questionId": "BP14.2",
                    "freeValue": 365
                },
                "BP14.3": {
                    "questionId": "BP14.3",
                    "chosenOptionIds": [
                        "9f5fc152-75fb-59d8-8f40-7a6ca15c69cb"
                    ]
                },
                "BP16": {
                    "questionId": "BP16",
                    "chosenOptionIds": [
                        "fabceeea-8fea-5444-8b67-8f189f366c27"
                    ]
                },
                "BP16.0": {
                    "questionId": "BP16.0",
                    "chosenOptionIds": [
                        "4b26caa5-9124-51b2-ab08-2f4e977cee1f"
                    ]
                },
                "BP16.01": {
                    "questionId": "BP16.01",
                    "chosenOptionIds": [
                        "f81655d7-78e5-59b1-a7eb-90a2ec7a107d"
                    ]
                },
                "BP16.02": {
                    "questionId": "BP16.02",
                    "chosenOptionIds": [
                        "16a695c9-3ac1-5d34-b799-44602d85b556"
                    ]
                },
                "BP18": {
                    "questionId": "BP18",
                    "chosenOptionIds": [
                        "b577f323-ec54-5d25-a2b9-4f8de30d60a2"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 20
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "f03de28d-8f71-5af4-be2f-45f4e6092a3d"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "e620af65-9b5b-57e6-b13a-65616e67e084"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "a4ed572c-6aae-57f6-bc35-ea46929c1437"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "2404146e-cd56-5356-a23b-6427b4bbba06"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "93bfeb6e-215e-5f4b-b6de-0bc364e05be5"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "e22cf233-c174-52fb-af55-97bf9c46e971"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "46c90446-3ae9-53f5-9b57-ad19d66b5be6"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "d680a8d9-e35f-563f-9d1d-e7c3136aff6d"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "94795194-4a80-590f-be62-ee78f2fa0ff5"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "201c7ee3-56dc-51e9-ad55-69951192ab16"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "dbab3669-e910-5267-b7b1-fcb661ea7e81"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "2723ed09-37f6-522c-92b1-e518d4fe9315"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "58777a2e-4b97-5413-b340-efe722e45936"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "63509127-aadf-5fe2-8e1f-19d0d846d6ce"
                    ]
                },
                "BP99.1": {
                    "questionId": "BP99.1",
                    "chosenOptionIds": [
                        "34d19f12-207f-56ce-bde8-8d616f6e8b26"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "a020ed85-ec9d-5778-8006-41f634cab09d"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM19": {
                    "questionId": "HM19",
                    "chosenOptionIds": [
                        "fbfbcb17-2315-5dc6-a771-7f1051d7d464"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "c3ee8981-dec3-5b3c-80ca-c1023f983a6d"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "a43647cb-a0e8-5705-adb9-a2b68877dc77"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c41974b6-d394-569c-8f45-49f79fcac5c6"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "43c32319-c75f-5169-b0e5-713820721d4f"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "b2cb304f-f035-53fc-8763-e2f91b031f9b"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "0170b4cf-0541-53cd-a9db-818d5067dc23"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "2be66549-5767-51b4-82ed-deeab0723f8d"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "2a17c2fe-2592-58b1-98e9-3dc4168fe3e3"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "834b04f2-af09-5337-8fd5-be4a70c99e75"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "fda00d52-c8be-5b69-bdbd-905c13dddd4e"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "e95276b0-ee81-580b-b532-b0215f04abd5"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "1d07911e-929d-5719-aeea-a68f593d18e8"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: K392M0L aspect: simple_bd1 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "c61d70d4-b773-54dd-94d0-d858a47be0fb"
                    ]
                },
                "D-1.5b": {
                    "questionId": "D-1.5b",
                    "freeValue": 3
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "45f0ce85-9e19-53da-bc0d-2af8b383318a"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "eb68a88d-b49b-5cde-a9c6-5e4c78e27d19"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "a0e528ca-2fbb-5da1-9398-0758f79755aa"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 360
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "7f1d6182-f8a7-5f4e-a0f8-2556b306ae36"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-1bl": {
                    "questionId": "D-1bl",
                    "chosenOptionIds": [
                        "83937123-de06-5b20-a479-c5e3e92848c4"
                    ]
                },
                "D-2": {
                    "questionId": "D-2",
                    "freeValue": 20
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "c695fd1c-2cb7-5654-b9f7-b32ecb158229"
                    ]
                },
                "D-2bl": {
                    "questionId": "D-2bl",
                    "chosenOptionIds": [
                        "78c1434b-e585-5f1d-b291-a1728f789022"
                    ]
                },
                "D-2bt": {
                    "questionId": "D-2bt",
                    "chosenOptionIds": [
                        "618070b6-e9e5-599a-a906-eed44f5c2dd9"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "fd9c4394-6664-5144-8797-00d27a4dda01"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5b6c0a69-6369-53d1-b2e1-5cd6294a9f71"
                    ]
                },
                "D-3bl": {
                    "questionId": "D-3bl",
                    "freeValue": 0.25
                },
                "D-3bt": {
                    "questionId": "D-3bt",
                    "chosenOptionIds": [
                        "b9abc28d-6e22-5b67-bc5e-27431e123974"
                    ]
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-42bt": {
                    "questionId": "D-42bt",
                    "freeValue": 0
                },
                "D-43bt": {
                    "questionId": "D-43bt",
                    "chosenOptionIds": [
                        "644dd944-5a2a-533b-9ef0-fadb5054c422"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "1d4beed1-78e5-531c-a255-643fac260dee"
                    ]
                },
                "D-4bt": {
                    "questionId": "D-4bt",
                    "freeValue": 0.16666666666666666
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "f3efc764-ca53-52c3-9c3a-38c7f787d415"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "772bee29-c3a5-52e1-b785-a77d506ae4fb"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "4c78ae3e-7de4-545f-aca1-03fa41fc0c23"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "13f8bac1-d0f5-539b-8596-1c55821bea46"
                    ]
                },
                "D-s10bt": {
                    "questionId": "D-s10bt",
                    "chosenOptionIds": [
                        "e3ab5219-3af6-5da7-8464-a145eb508a0f"
                    ]
                },
                "D-s11bt": {
                    "questionId": "D-s11bt",
                    "chosenOptionIds": [
                        "9c3fa6af-8b7b-5993-a587-641683d934d5",
                        "6994b88e-cfa5-5986-b9e8-625d17d1147c",
                        "7921bccf-4756-59fe-b678-d536c2ab5945",
                        "dc476220-9479-5def-8a97-20e6152d44af"
                    ]
                },
                "D-s1bt": {
                    "questionId": "D-s1bt",
                    "chosenOptionIds": [
                        "33dd907f-3e0e-5735-96bb-c101f0acced9"
                    ]
                },
                "D-s2bt": {
                    "questionId": "D-s2bt",
                    "chosenOptionIds": [
                        "b75d06ef-eb6c-5e23-bf8c-9b566fa4d9dd"
                    ]
                },
                "D-s3bt": {
                    "questionId": "D-s3bt",
                    "chosenOptionIds": [
                        "11f57889-ac93-53ab-88a7-9aea03a29429"
                    ]
                },
                "D-s4bt": {
                    "questionId": "D-s4bt",
                    "chosenOptionIds": [
                        "5a198500-fc4b-55a1-9521-3abe49eed06f"
                    ]
                },
                "D-s5bt": {
                    "questionId": "D-s5bt",
                    "chosenOptionIds": [
                        "d033bb4c-5d3c-519b-be31-1177042bffb4"
                    ]
                },
                "D-s6bt": {
                    "questionId": "D-s6bt",
                    "chosenOptionIds": [
                        "f7aa2ac0-a087-5371-8266-7601901cd1f9"
                    ]
                },
                "D-s7bt": {
                    "questionId": "D-s7bt",
                    "chosenOptionIds": [
                        "1df67815-0b9f-56ef-bd48-96ed977db141"
                    ]
                },
                "D-s8bt": {
                    "questionId": "D-s8bt",
                    "chosenOptionIds": [
                        "6f5067cf-ef4d-59d9-afa1-b3dfc4ac861f"
                    ]
                },
                "D-s9bt": {
                    "questionId": "D-s9bt",
                    "chosenOptionIds": [
                        "34daded3-efdb-5981-a1fc-e31ec1176383"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "8c599171-d554-5547-9f54-2066c1a711dd",
                        "c2921864-bbf7-5735-9313-848fb9d5a6d1",
                        "07106ab9-5949-5b5f-bc96-117139ebd397"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "6215a3de-a3e4-5f86-a039-1006646e6b93"
                    ]
                },
                "BP106": {
                    "questionId": "BP106",
                    "chosenOptionIds": [
                        "8c35da7e-8c0b-50bb-a037-b80a9f1df3d2"
                    ]
                },
                "BP108": {
                    "questionId": "BP108",
                    "chosenOptionIds": [
                        "6c88c07e-4483-5b5b-b6ad-44cff962863e"
                    ]
                },
                "BP110": {
                    "questionId": "BP110",
                    "freeValue": 180
                },
                "BP110.01": {
                    "questionId": "BP110.01",
                    "chosenOptionIds": [
                        "85bf649a-4487-5b00-8c08-82ad143ea966"
                    ]
                },
                "BP110.1": {
                    "questionId": "BP110.1",
                    "chosenOptionIds": [
                        "9d19a3c0-120d-5dfd-a794-8aa0e8d39e77"
                    ]
                },
                "BP110.2": {
                    "questionId": "BP110.2",
                    "chosenOptionIds": [
                        "faf183ab-b9b4-5f0d-a996-dfa04be4e401"
                    ]
                },
                "BP111": {
                    "questionId": "BP111",
                    "chosenOptionIds": [
                        "059fdaea-ce9e-527c-ae94-e00d2b0dbe01"
                    ]
                },
                "BP111.1": {
                    "questionId": "BP111.1",
                    "chosenOptionIds": [
                        "c532b673-2762-5ed0-8cbb-6f70d9977c20"
                    ]
                },
                "BP111.2": {
                    "questionId": "BP111.2",
                    "chosenOptionIds": [
                        "4c9c60d0-cd73-504d-8b03-b734045376ae"
                    ]
                },
                "BP112": {
                    "questionId": "BP112",
                    "chosenOptionIds": [
                        "6db4ba23-1d8f-58b9-b11e-eaea15417e28"
                    ]
                },
                "BP113": {
                    "questionId": "BP113",
                    "chosenOptionIds": [
                        "b16103eb-2ecf-595e-80e4-30baf575e01b"
                    ]
                },
                "BP114": {
                    "questionId": "BP114",
                    "chosenOptionIds": [
                        "a0206e39-59ad-539c-a2ff-e9fd935cf48b"
                    ]
                },
                "BP115": {
                    "questionId": "BP115",
                    "chosenOptionIds": [
                        "e7b2a68b-de30-51bd-bb56-cda1254d3dbc"
                    ]
                },
                "BP116": {
                    "questionId": "BP116",
                    "chosenOptionIds": [
                        "bce3bb8c-216a-5167-8ec7-35c39096d501"
                    ]
                },
                "BP117": {
                    "questionId": "BP117",
                    "chosenOptionIds": [
                        "354c012f-3484-58ad-9300-d9e29a212ae3"
                    ]
                },
                "BP118": {
                    "questionId": "BP118",
                    "chosenOptionIds": [
                        "e642f54c-d6fc-57f8-a53c-73dbd698d428"
                    ]
                },
                "BP118.1": {
                    "questionId": "BP118.1",
                    "chosenOptionIds": [
                        "3780fe32-30da-5e3b-bad1-6d360381e7e4"
                    ]
                },
                "BP119": {
                    "questionId": "BP119",
                    "chosenOptionIds": [
                        "3f4ab649-e8be-559b-a32f-9ccb317ffb38"
                    ]
                },
                "BP119.1": {
                    "questionId": "BP119.1",
                    "freeValue": 0
                },
                "BP120": {
                    "questionId": "BP120",
                    "chosenOptionIds": [
                        "548c329b-3169-5742-b17d-437216e6a454"
                    ]
                },
                "BP121": {
                    "questionId": "BP121",
                    "chosenOptionIds": [
                        "76891993-4f79-5b7a-ad6f-b7be1c4994fb"
                    ]
                },
                "BP122": {
                    "questionId": "BP122",
                    "chosenOptionIds": [
                        "b1759380-318d-54b0-9224-163fb659558b"
                    ]
                },
                "BP123": {
                    "questionId": "BP123",
                    "chosenOptionIds": [
                        "5c6d0cea-9bce-5b98-b6ad-dcb0e3fb6b0d"
                    ]
                },
                "BP124": {
                    "questionId": "BP124",
                    "chosenOptionIds": [
                        "6410ed4b-d607-5edd-baae-ffeb61769bf8"
                    ]
                },
                "BP125": {
                    "questionId": "BP125",
                    "chosenOptionIds": [
                        "d7075f96-ec23-51f7-9293-9bf725963409"
                    ]
                },
                "BP126": {
                    "questionId": "BP126",
                    "chosenOptionIds": [
                        "7bdce4bf-24ca-5470-a5cd-68c1e5c7ef03"
                    ]
                },
                "BP127": {
                    "questionId": "BP127",
                    "chosenOptionIds": [
                        "5f58bb60-dff1-585c-a5f3-26e34e4dc957"
                    ]
                },
                "BP128": {
                    "questionId": "BP128",
                    "chosenOptionIds": [
                        "ebff4971-05dc-52ed-9957-33c0ca1479c1"
                    ]
                },
                "BP129": {
                    "questionId": "BP129",
                    "chosenOptionIds": [
                        "4b90a2f8-960f-5d69-8e86-9c46af2fec0f"
                    ]
                },
                "BP130": {
                    "questionId": "BP130",
                    "chosenOptionIds": [
                        "7a066bdc-85a0-56cc-8632-2e83740855c1"
                    ]
                },
                "BP131": {
                    "questionId": "BP131",
                    "chosenOptionIds": [
                        "e5863f5d-32a6-520a-9b40-8591adf1c1b8"
                    ]
                },
                "BP132": {
                    "questionId": "BP132",
                    "chosenOptionIds": [
                        "da9000ec-69f6-580b-9ec9-756b702c2962"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM35": {
                    "questionId": "HM35",
                    "chosenOptionIds": [
                        "c5f21a2b-5af8-525d-bfab-0ed035defec0"
                    ]
                },
                "HM36": {
                    "questionId": "HM36",
                    "chosenOptionIds": [
                        "47bfc26b-4237-5a82-9cc4-67d348c4acb0"
                    ]
                },
                "HM37": {
                    "questionId": "HM37",
                    "chosenOptionIds": [
                        "20de534d-f482-51c5-ad87-bd054787fddc"
                    ]
                },
                "HM38": {
                    "questionId": "HM38",
                    "chosenOptionIds": [
                        "5125bbbb-70fb-5adc-a41b-c65c6b96180d"
                    ]
                },
                "HM40": {
                    "questionId": "HM40",
                    "chosenOptionIds": [
                        "596144bf-d0ea-5d17-a2ae-7096c31e2def"
                    ]
                },
                "HM41": {
                    "questionId": "HM41",
                    "chosenOptionIds": [
                        "dd9cb999-d487-51f2-8ca3-f121593feca0"
                    ]
                },
                "HM43": {
                    "questionId": "HM43",
                    "chosenOptionIds": [
                        "a8b07611-dcc0-55ef-ae78-d4ceb7ad42e3"
                    ]
                },
                "HM44": {
                    "questionId": "HM44",
                    "chosenOptionIds": [
                        "3675f391-ff12-51e5-b53f-b851b05414ee"
                    ]
                },
                "HM45": {
                    "questionId": "HM45",
                    "chosenOptionIds": [
                        "28a20a2a-3318-5dba-9e59-86d943d3ac73"
                    ]
                },
                "HM46": {
                    "questionId": "HM46",
                    "chosenOptionIds": [
                        "9f868300-e1df-5567-8ebf-e2f984022e52"
                    ]
                },
                "HM47": {
                    "questionId": "HM47",
                    "chosenOptionIds": [
                        "41ec9370-ba26-5cbc-ad35-d9e578788aa4"
                    ]
                },
                "HM48": {
                    "questionId": "HM48",
                    "chosenOptionIds": [
                        "2db89264-9c8a-54e5-8efc-1b1ca45b0233"
                    ]
                },
                "HM49": {
                    "questionId": "HM49",
                    "chosenOptionIds": [
                        "555cca84-b650-53ab-944e-2289adb21903"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: N0N4J3Q aspect: simple_bd1 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "a16e8e14-524e-53df-b4ef-730a2c8e5f32"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "98772fed-cf55-5d42-b4e1-0e6a1a31ce56"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "826538fc-8a97-55e4-8013-fe6a93f8e451"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 3650
                },
                "D-15a": {
                    "questionId": "D-15a",
                    "chosenOptionIds": [
                        "7b763ff0-7703-54f7-8eae-aa83417ca133"
                    ]
                },
                "D-16.5a": {
                    "questionId": "D-16.5a",
                    "chosenOptionIds": [
                        "b409dbb9-80ef-5de1-96d0-1075735134db"
                    ]
                },
                "D-17a": {
                    "questionId": "D-17a",
                    "chosenOptionIds": [
                        "495d3d07-8a1f-5213-bf79-59443d7aa5cf"
                    ]
                },
                "D-18a": {
                    "questionId": "D-18a",
                    "chosenOptionIds": [
                        "d5dff57c-3626-5197-b54a-ddca85aa8775"
                    ]
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "80c993c8-1a94-5684-a8f9-d8f5072c8e9c"
                    ]
                },
                "D-20a": {
                    "questionId": "D-20a",
                    "chosenOptionIds": [
                        "b28ed556-a386-5556-a96f-ae7708ded1ab"
                    ]
                },
                "D-21a": {
                    "questionId": "D-21a",
                    "chosenOptionIds": [
                        "79fb502d-53ef-5cbb-a08a-c346d1ba9310"
                    ]
                },
                "D-23a": {
                    "questionId": "D-23a",
                    "chosenOptionIds": [
                        "32a2b9d2-46ee-5235-9c03-8fb1fb1e5800"
                    ]
                },
                "D-26a": {
                    "questionId": "D-26a",
                    "chosenOptionIds": [
                        "220827bb-16f1-5e00-9549-b98382b6e2ac"
                    ]
                },
                "D-27a": {
                    "questionId": "D-27a",
                    "chosenOptionIds": [
                        "3d120f97-fb12-5e86-8bb9-8fc93babc803"
                    ]
                },
                "D-28a": {
                    "questionId": "D-28a",
                    "chosenOptionIds": [
                        "009661f3-60b5-52e8-bc5f-44c65767874c"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "cc243947-5067-5a98-ade6-a4e10d0b42cf"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "e797900f-129d-553e-b83f-8494d7f03986"
                    ]
                },
                "D-30a": {
                    "questionId": "D-30a",
                    "chosenOptionIds": [
                        "1f6e9ea6-3402-596b-a518-31105c7a277b"
                    ]
                },
                "D-31a": {
                    "questionId": "D-31a",
                    "chosenOptionIds": [
                        "c78e0def-d73e-5c4e-8800-7c6f92d0a1ec"
                    ]
                },
                "D-32a": {
                    "questionId": "D-32a",
                    "chosenOptionIds": [
                        "ea2a4fbd-008a-571b-bb68-cbd0ba351521"
                    ]
                },
                "D-33a": {
                    "questionId": "D-33a",
                    "chosenOptionIds": [
                        "3968dd70-f060-5cbd-bb6a-d63f702a3691"
                    ]
                },
                "D-34a": {
                    "questionId": "D-34a",
                    "chosenOptionIds": [
                        "6bfab4b8-7d57-5039-8933-4dee0c958a19"
                    ]
                },
                "D-35a": {
                    "questionId": "D-35a",
                    "chosenOptionIds": [
                        "3a07e052-2332-5a23-bb19-3147a5b9d2c7"
                    ]
                },
                "D-36a": {
                    "questionId": "D-36a",
                    "chosenOptionIds": [
                        "185038dd-ce55-53b2-ba09-0bd163af90b3"
                    ]
                },
                "D-37a": {
                    "questionId": "D-37a",
                    "chosenOptionIds": [
                        "fd4e0608-283c-5f56-a026-edfa381519c9"
                    ]
                },
                "D-38a": {
                    "questionId": "D-38a",
                    "chosenOptionIds": [
                        "5ca199a0-50f8-57f0-a098-03c824bb83ba"
                    ]
                },
                "D-39a": {
                    "questionId": "D-39a",
                    "chosenOptionIds": [
                        "ae54b405-473d-53a5-8ded-1108dc74f928"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5b6c0a69-6369-53d1-b2e1-5cd6294a9f71"
                    ]
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-40a": {
                    "questionId": "D-40a",
                    "chosenOptionIds": [
                        "582119a7-d4a0-58ff-9b1d-2af045e11a7f"
                    ]
                },
                "D-41a": {
                    "questionId": "D-41a",
                    "chosenOptionIds": [
                        "6a5d1a65-0b53-5485-995a-76d8d7eafb1a"
                    ]
                },
                "D-42a": {
                    "questionId": "D-42a",
                    "chosenOptionIds": [
                        "926931de-e675-5117-9e61-78dd541393be"
                    ]
                },
                "D-43a": {
                    "questionId": "D-43a",
                    "chosenOptionIds": [
                        "22b1ff23-c3a7-56b8-8108-65eacd7d3fbe"
                    ]
                },
                "D-44.5a": {
                    "questionId": "D-44.5a",
                    "chosenOptionIds": [
                        "d87971dc-d745-5b64-ac37-37fbe1e722ac"
                    ]
                },
                "D-44a": {
                    "questionId": "D-44a",
                    "chosenOptionIds": [
                        "68a987a9-8bad-50d7-a4d9-e970334f0460"
                    ]
                },
                "D-45a": {
                    "questionId": "D-45a",
                    "freeValue": 0
                },
                "D-49a": {
                    "questionId": "D-49a",
                    "chosenOptionIds": [
                        "8eaac5aa-39e4-5f7d-bbe9-ba405b3dba9c"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "9d1b858c-7064-5a84-80d6-786c18e603b6",
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b",
                        "1d4beed1-78e5-531c-a255-643fac260dee"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "188a7831-38c4-55c4-880a-1e4db4e9bab1"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "81e9f63b-35b9-5032-8ea0-462275a87cce"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "5d271ac0-beca-56cc-9d0b-adafcd67da53"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "33a5fff0-9587-521f-bc53-0dacc36a0bb2"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "f8e2518c-72cf-563b-b322-a03406c8ebac"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "c2921864-bbf7-5735-9313-848fb9d5a6d1"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "7599bcaa-89a5-5f71-80ea-b70c360bb44a"
                    ]
                },
                "BP101b": {
                    "questionId": "BP101b",
                    "chosenOptionIds": [
                        "923cb2af-7bb5-5341-8349-003206ae043d"
                    ]
                },
                "BP102b": {
                    "questionId": "BP102b",
                    "chosenOptionIds": [
                        "523c7d8e-e42b-5654-b037-e8837afb49e7"
                    ]
                },
                "BP103b": {
                    "questionId": "BP103b",
                    "chosenOptionIds": [
                        "867b0ee4-73d9-5aac-b96d-bc2d85d5b183"
                    ]
                },
                "BP104b": {
                    "questionId": "BP104b",
                    "chosenOptionIds": [
                        "c59b5910-3279-509f-8175-f2fd0da2dc86"
                    ]
                },
                "BP87b": {
                    "questionId": "BP87b",
                    "chosenOptionIds": [
                        "7949bd96-3757-5573-bd89-3a91a383cc99"
                    ]
                },
                "BP88b": {
                    "questionId": "BP88b",
                    "chosenOptionIds": [
                        "fa24b924-a8e1-5f02-893b-3d9643007991"
                    ]
                },
                "BP89b": {
                    "questionId": "BP89b",
                    "chosenOptionIds": [
                        "41d2829a-3971-552f-8c5a-c77e7d0073e7"
                    ]
                },
                "BP90b": {
                    "questionId": "BP90b",
                    "chosenOptionIds": [
                        "95c2822d-27ad-5bb0-982f-8c2b949c8918"
                    ]
                },
                "BP92b": {
                    "questionId": "BP92b",
                    "chosenOptionIds": [
                        "b2f4c490-30df-5c8f-87c0-bc4acbcfb8e0"
                    ]
                },
                "BP94b": {
                    "questionId": "BP94b",
                    "chosenOptionIds": [
                        "9cf74958-461b-59df-b1a2-7ee7d7696a0b"
                    ]
                },
                "BP95b": {
                    "questionId": "BP95b",
                    "chosenOptionIds": [
                        "32255970-db0f-5cc0-a199-d412edf7ab7c"
                    ]
                },
                "BP96b": {
                    "questionId": "BP96b",
                    "chosenOptionIds": [
                        "e200f36c-3005-5e37-82d3-9228fcf60bfb"
                    ]
                },
                "BP97b": {
                    "questionId": "BP97b",
                    "chosenOptionIds": [
                        "94b68a0a-8608-59d6-a8bb-48cf3c52f891"
                    ]
                },
                "BP98b": {
                    "questionId": "BP98b",
                    "chosenOptionIds": [
                        "01641419-fbfc-52a5-ae63-0fa736ff9847"
                    ]
                },
                "BP99.2b": {
                    "questionId": "BP99.2b",
                    "chosenOptionIds": [
                        "0f933dbb-72b4-5212-b415-8ea806f59368"
                    ]
                },
                "BP99.3b": {
                    "questionId": "BP99.3b",
                    "chosenOptionIds": [
                        "bf30a6c2-f8e2-5305-b4d4-7ad6227449d3"
                    ]
                },
                "BP99b": {
                    "questionId": "BP99b",
                    "chosenOptionIds": [
                        "3f987aaa-e435-55a9-9c1b-aa6d2e476738"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "BP_neg1": {
                    "questionId": "BP_neg1",
                    "chosenOptionIds": [
                        "119208b0-ff3b-5c43-80be-4f07e65b6248"
                    ]
                }
            }
        },
        {
            "name": "Mood test: ME8MOEW aspect: simple_bd1 should be diagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "fe031280-7d5d-52cb-aca8-1a8664b3234a"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "98772fed-cf55-5d42-b4e1-0e6a1a31ce56"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "826538fc-8a97-55e4-8013-fe6a93f8e451"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 1095
                },
                "D-15a": {
                    "questionId": "D-15a",
                    "chosenOptionIds": [
                        "7b763ff0-7703-54f7-8eae-aa83417ca133"
                    ]
                },
                "D-16.5a": {
                    "questionId": "D-16.5a",
                    "chosenOptionIds": [
                        "0c4e0b7f-132d-5e75-aeae-fd29d3b2eb67"
                    ]
                },
                "D-17a": {
                    "questionId": "D-17a",
                    "chosenOptionIds": [
                        "8413ed32-e959-5c2a-9d19-8cc68709c34d"
                    ]
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "7f1d6182-f8a7-5f4e-a0f8-2556b306ae36"
                    ]
                },
                "D-1bl": {
                    "questionId": "D-1bl",
                    "chosenOptionIds": [
                        "4105c26a-4ff2-5fd7-984d-f0bcb7b5656e"
                    ]
                },
                "D-2": {
                    "questionId": "D-2",
                    "freeValue": 25
                },
                "D-20a": {
                    "questionId": "D-20a",
                    "chosenOptionIds": [
                        "b28ed556-a386-5556-a96f-ae7708ded1ab"
                    ]
                },
                "D-21a": {
                    "questionId": "D-21a",
                    "chosenOptionIds": [
                        "79fb502d-53ef-5cbb-a08a-c346d1ba9310"
                    ]
                },
                "D-23a": {
                    "questionId": "D-23a",
                    "chosenOptionIds": [
                        "32a2b9d2-46ee-5235-9c03-8fb1fb1e5800"
                    ]
                },
                "D-26a": {
                    "questionId": "D-26a",
                    "chosenOptionIds": [
                        "945792e1-fdf5-5959-89c8-6a1caa4eff88"
                    ]
                },
                "D-27a": {
                    "questionId": "D-27a",
                    "chosenOptionIds": [
                        "3d120f97-fb12-5e86-8bb9-8fc93babc803"
                    ]
                },
                "D-28a": {
                    "questionId": "D-28a",
                    "chosenOptionIds": [
                        "801353cf-2f72-53db-9179-e7ecc31b1fc1"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "c695fd1c-2cb7-5654-b9f7-b32ecb158229"
                    ]
                },
                "D-2bl": {
                    "questionId": "D-2bl",
                    "chosenOptionIds": [
                        "2a8c96e2-cb5b-596a-99b6-a4885253776c"
                    ]
                },
                "D-2bt": {
                    "questionId": "D-2bt",
                    "chosenOptionIds": [
                        "acdba44c-4419-55d5-891f-5c03c9b52309"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "fd9c4394-6664-5144-8797-00d27a4dda01",
                        "bccbebb3-ed8c-5251-856b-e93a1d4386f6",
                        "75d33f2f-6fcd-5e09-8574-899eabeb22e6"
                    ]
                },
                "D-30a": {
                    "questionId": "D-30a",
                    "chosenOptionIds": [
                        "1f6e9ea6-3402-596b-a518-31105c7a277b"
                    ]
                },
                "D-31a": {
                    "questionId": "D-31a",
                    "chosenOptionIds": [
                        "c78e0def-d73e-5c4e-8800-7c6f92d0a1ec"
                    ]
                },
                "D-32.1a": {
                    "questionId": "D-32.1a",
                    "chosenOptionIds": [
                        "536a2dd7-0943-5d01-9537-428496ec789b"
                    ]
                },
                "D-32.2a": {
                    "questionId": "D-32.2a",
                    "chosenOptionIds": [
                        "c3cfa538-c5a3-55dd-8922-622dfa302b7f"
                    ]
                },
                "D-32a": {
                    "questionId": "D-32a",
                    "chosenOptionIds": [
                        "93c47fae-1a07-5ad0-b521-e56d8f62f763"
                    ]
                },
                "D-33a": {
                    "questionId": "D-33a",
                    "chosenOptionIds": [
                        "379331e4-3b82-582c-af51-cc4ed79308d7"
                    ]
                },
                "D-34a": {
                    "questionId": "D-34a",
                    "chosenOptionIds": [
                        "6bfab4b8-7d57-5039-8933-4dee0c958a19"
                    ]
                },
                "D-35a": {
                    "questionId": "D-35a",
                    "chosenOptionIds": [
                        "9c9d44bb-b50f-5ddf-9553-03b254272362"
                    ]
                },
                "D-36a": {
                    "questionId": "D-36a",
                    "chosenOptionIds": [
                        "185038dd-ce55-53b2-ba09-0bd163af90b3"
                    ]
                },
                "D-37a": {
                    "questionId": "D-37a",
                    "chosenOptionIds": [
                        "dc9f1b80-2303-5ac8-86ff-e9a39a6c0e16"
                    ]
                },
                "D-38a": {
                    "questionId": "D-38a",
                    "chosenOptionIds": [
                        "cfa17d19-5b15-56ef-82c1-07949e21dd81"
                    ]
                },
                "D-39a": {
                    "questionId": "D-39a",
                    "chosenOptionIds": [
                        "756b91dc-7358-504f-aeab-84552f2bf28b"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "db7514e2-d317-55fb-82d3-2e4a49c2494b"
                    ]
                },
                "D-3bl": {
                    "questionId": "D-3bl",
                    "freeValue": 5
                },
                "D-3bt": {
                    "questionId": "D-3bt",
                    "chosenOptionIds": [
                        "b9abc28d-6e22-5b67-bc5e-27431e123974"
                    ]
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-40a": {
                    "questionId": "D-40a",
                    "chosenOptionIds": [
                        "582119a7-d4a0-58ff-9b1d-2af045e11a7f"
                    ]
                },
                "D-41a": {
                    "questionId": "D-41a",
                    "chosenOptionIds": [
                        "6a5d1a65-0b53-5485-995a-76d8d7eafb1a"
                    ]
                },
                "D-42a": {
                    "questionId": "D-42a",
                    "chosenOptionIds": [
                        "926931de-e675-5117-9e61-78dd541393be"
                    ]
                },
                "D-42bt": {
                    "questionId": "D-42bt",
                    "freeValue": 0.3333333333333333
                },
                "D-43a": {
                    "questionId": "D-43a",
                    "chosenOptionIds": [
                        "dc3faaa6-4a47-5998-83e3-4bbff8838194"
                    ]
                },
                "D-43bt": {
                    "questionId": "D-43bt",
                    "chosenOptionIds": [
                        "644dd944-5a2a-533b-9ef0-fadb5054c422"
                    ]
                },
                "D-44.5a": {
                    "questionId": "D-44.5a",
                    "chosenOptionIds": [
                        "d87971dc-d745-5b64-ac37-37fbe1e722ac",
                        "bcf5fb9f-f4b9-5f0c-a964-69a4e1a73334"
                    ]
                },
                "D-44a": {
                    "questionId": "D-44a",
                    "chosenOptionIds": [
                        "57c0e55d-5ee4-5237-9c5d-e317b90c5dbd"
                    ]
                },
                "D-45a": {
                    "questionId": "D-45a",
                    "freeValue": 360
                },
                "D-46a": {
                    "questionId": "D-46a",
                    "chosenOptionIds": [
                        "1fd426df-808a-5c68-9b6e-23ff2f128b7d",
                        "d9665b1f-cdd2-5979-895e-27bca6487533",
                        "7492eab7-c625-52d0-a2e3-4bf3332decc0",
                        "f0b15a45-ae49-59d2-97a7-33f866b0e629",
                        "13260a8b-4ec0-5e4d-84fc-1df990527937",
                        "4c178978-f79a-58fc-8e73-3a98614c4b6c",
                        "b3e43bd8-fbe3-5cda-b891-6f11e43993d0",
                        "6e49f368-62af-5abb-9034-a58bd534944f"
                    ]
                },
                "D-49.1a": {
                    "questionId": "D-49.1a",
                    "chosenOptionIds": [
                        "7108af97-c956-5da8-8dfa-518e346c197c"
                    ]
                },
                "D-49.2a": {
                    "questionId": "D-49.2a",
                    "freeValue": 5
                },
                "D-49a": {
                    "questionId": "D-49a",
                    "chosenOptionIds": [
                        "16dc6af1-32a9-59c5-b66c-57f6becea1e0"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "9d1b858c-7064-5a84-80d6-786c18e603b6"
                    ]
                },
                "D-4bt": {
                    "questionId": "D-4bt",
                    "freeValue": 360
                },
                "D-50a": {
                    "questionId": "D-50a",
                    "chosenOptionIds": [
                        "d91f7277-0497-57d2-93a3-ea638d14162c"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "f3efc764-ca53-52c3-9c3a-38c7f787d415"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "3e093dd3-857e-5004-a4fb-961fbfa59ece"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "772bee29-c3a5-52e1-b785-a77d506ae4fb"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "33a5fff0-9587-521f-bc53-0dacc36a0bb2"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "a70aee2a-9d23-554d-b7d3-9f1a025fd82d"
                    ]
                },
                "D-s10bt": {
                    "questionId": "D-s10bt",
                    "chosenOptionIds": [
                        "e7b114de-aed8-536e-8edf-baf8a2a673bf"
                    ]
                },
                "D-s11bt": {
                    "questionId": "D-s11bt",
                    "chosenOptionIds": [
                        "16e1a0b8-28c5-5bcd-931d-335c858c3f88",
                        "6994b88e-cfa5-5986-b9e8-625d17d1147c",
                        "9c3fa6af-8b7b-5993-a587-641683d934d5",
                        "dc476220-9479-5def-8a97-20e6152d44af",
                        "daf8b3c7-fb19-5a3f-ba9a-784adda7414f",
                        "bae5acc8-8664-54a2-ba97-c36244be4c44",
                        "64af3cae-713a-51e1-99c1-09e2fdaa755d",
                        "7921bccf-4756-59fe-b678-d536c2ab5945"
                    ]
                },
                "D-s1bt": {
                    "questionId": "D-s1bt",
                    "chosenOptionIds": [
                        "c17482e4-818a-50cd-90fa-1246d037825e"
                    ]
                },
                "D-s2bt": {
                    "questionId": "D-s2bt",
                    "chosenOptionIds": [
                        "e074592a-4667-5b65-b713-b8892451b433"
                    ]
                },
                "D-s3bt": {
                    "questionId": "D-s3bt",
                    "chosenOptionIds": [
                        "ae77a2d7-a52c-5cdc-8c8d-93e294ade96d"
                    ]
                },
                "D-s4bt": {
                    "questionId": "D-s4bt",
                    "chosenOptionIds": [
                        "27313271-8ec6-5f99-bb2c-0ed50a7c1527"
                    ]
                },
                "D-s5bt": {
                    "questionId": "D-s5bt",
                    "chosenOptionIds": [
                        "e6a5403c-95a3-58c9-9259-e02e5fafd441"
                    ]
                },
                "D-s6bt": {
                    "questionId": "D-s6bt",
                    "chosenOptionIds": [
                        "53625e56-4f03-5f54-a340-d5b8cabe24d9"
                    ]
                },
                "D-s7bt": {
                    "questionId": "D-s7bt",
                    "chosenOptionIds": [
                        "1df67815-0b9f-56ef-bd48-96ed977db141"
                    ]
                },
                "D-s8bt": {
                    "questionId": "D-s8bt",
                    "chosenOptionIds": [
                        "ebfe9c3e-852e-5116-8185-8430f6b361f8"
                    ]
                },
                "D-s9bt": {
                    "questionId": "D-s9bt",
                    "chosenOptionIds": [
                        "8d2e7af7-07c2-5459-8865-609f820de278"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "60fe0bb4-d770-56f2-b05a-864625a48e4d"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "ccbdcbec-96d0-5362-977b-784289f5cfc0"
                    ]
                },
                "BP10": {
                    "questionId": "BP10",
                    "chosenOptionIds": [
                        "7ad54f0a-87a6-5e82-bfc3-44b540230082"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "a20d7b84-720e-53c5-a450-45cbde434507"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "ca5ac96d-2b69-5200-a063-61db840c9ef2"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "1daccd4c-e5ff-5f18-ba55-7c2d2d93b849"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "f2228920-3fec-5097-a63d-990c25d50c6c"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP105": {
                    "questionId": "BP105",
                    "chosenOptionIds": [
                        "978f2ff4-94d9-5f7e-9126-e8657be0c7ca"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 25
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "c7bee7a0-3037-5eb1-96a1-85b884d7e1dd"
                    ]
                },
                "BP3.01": {
                    "questionId": "BP3.01",
                    "freeValue": 2
                },
                "BP3.1": {
                    "questionId": "BP3.1",
                    "chosenOptionIds": [
                        "9531c3ff-ab2b-5198-a490-919ede5afb07"
                    ]
                },
                "BP3.2": {
                    "questionId": "BP3.2",
                    "chosenOptionIds": [
                        "ec333f8b-146a-5700-a15d-527032ceac3f"
                    ]
                },
                "BP3.3": {
                    "questionId": "BP3.3",
                    "freeValue": 60
                },
                "BP3.4": {
                    "questionId": "BP3.4",
                    "chosenOptionIds": [
                        "f304bf93-0c68-5da0-80ba-ccecc2d0fce1"
                    ]
                },
                "BP4": {
                    "questionId": "BP4",
                    "chosenOptionIds": [
                        "9765aba8-7d55-5928-b79d-36aa58646036"
                    ]
                },
                "BP4.5": {
                    "questionId": "BP4.5",
                    "chosenOptionIds": [
                        "1be4b3b1-3a88-5544-af54-ebdfee1ce891"
                    ]
                },
                "BP6": {
                    "questionId": "BP6",
                    "chosenOptionIds": [
                        "52f153db-76e7-57fa-90bd-63d5a923964a"
                    ]
                },
                "BP6.1": {
                    "questionId": "BP6.1",
                    "chosenOptionIds": [
                        "a99773bd-2bff-523e-bc29-1f761307b3d4"
                    ]
                },
                "BP6.2": {
                    "questionId": "BP6.2",
                    "freeValue": 2190
                },
                "BP6.3": {
                    "questionId": "BP6.3",
                    "chosenOptionIds": [
                        "df17fdbe-e756-5ece-ad3d-19cafeef31d1"
                    ]
                },
                "BP8": {
                    "questionId": "BP8",
                    "chosenOptionIds": [
                        "87e0081b-9455-5ced-9225-10c2f70b1734"
                    ]
                },
                "BP8.0": {
                    "questionId": "BP8.0",
                    "chosenOptionIds": [
                        "3568cdb1-2500-512b-aada-2547c5bfd835"
                    ]
                },
                "BP8.01": {
                    "questionId": "BP8.01",
                    "chosenOptionIds": [
                        "a5d71cc4-3c6f-5e37-a507-1dfa312124b8"
                    ]
                },
                "BP8.02": {
                    "questionId": "BP8.02",
                    "chosenOptionIds": [
                        "e88670a3-c6d8-5c96-914e-b9fc1818aeda"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "e620af65-9b5b-57e6-b13a-65616e67e084"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "a4ed572c-6aae-57f6-bc35-ea46929c1437"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "0953f30c-801a-54f6-8238-b9dc42ab17f9"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "fd30dc27-8e89-5d1a-9d95-8844603d4379"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "33e48f82-ae80-5f20-a3a5-79692d21ad1a"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "cc7c09ae-1cb7-5b9a-9cd3-8d1012b90ee8"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "17619692-6d18-5558-99da-766a1c275e03"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "940c16a8-1835-582a-9dad-bd52e3a49cc7"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "2555d898-6736-5804-951e-e3c438313029"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "9b27e3ff-f8ce-54c5-9220-0479ae53e889"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "4a7683c6-774e-5362-8060-740f61986c9b"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "a0a2f2f1-ed73-59bb-add9-5f3569bbbecb"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "63509127-aadf-5fe2-8e1f-19d0d846d6ce"
                    ]
                },
                "BP99.1": {
                    "questionId": "BP99.1",
                    "chosenOptionIds": [
                        "34d19f12-207f-56ce-bde8-8d616f6e8b26"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "a020ed85-ec9d-5778-8006-41f634cab09d"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM2": {
                    "questionId": "HM2",
                    "chosenOptionIds": [
                        "2b56aa7d-493f-5a18-aa9f-f97304f89130"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "6b99de8b-634a-5eb0-bc65-bdf30eb9d433"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "8f7acbc1-9ebe-5a52-95ce-b50d62c27345"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c41974b6-d394-569c-8f45-49f79fcac5c6"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "43c32319-c75f-5169-b0e5-713820721d4f"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "eb41dc92-16ec-5abf-a841-e1d0b262083e"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "0170b4cf-0541-53cd-a9db-818d5067dc23"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "1240a093-e011-5bb9-9dbe-b61af95e9cde"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "2a17c2fe-2592-58b1-98e9-3dc4168fe3e3"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "7e32475a-3fcc-51ac-b414-ab46578efdfd"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "86277249-cf50-5f91-a27f-f3a69344e796"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "bde4e5f3-19e4-571f-84a2-5b9d188d74ef"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "1d07911e-929d-5719-aeea-a68f593d18e8"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: NEL6L3V aspect: simple_bd1 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "0ec0e224-a5cf-5ece-b3ab-37061a600ace"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "a16e8e14-524e-53df-b4ef-730a2c8e5f32"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "c0453e8e-7a9c-5bf5-b8a2-167a61a6387e"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "b74511c7-9f97-5c15-8bf5-6ec4357b4814"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 240
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "7a5958fc-e0f7-5d43-9cd0-abfe95cb7026"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "11f457f5-4c39-5458-ba9a-84478dd379ba"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5b6c0a69-6369-53d1-b2e1-5cd6294a9f71"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "a449e4ae-663b-50fb-9b62-1708b0a32669"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "dde1af45-4686-52c9-b960-369527b28f6c"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "adecf2d3-f302-50f8-a4a9-3ffac1789bc6"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "67316d94-1cf0-5bd0-a4db-f57bc70684ba"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "60fe0bb4-d770-56f2-b05a-864625a48e4d"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "ccbdcbec-96d0-5362-977b-784289f5cfc0"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "a20d7b84-720e-53c5-a450-45cbde434507"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "d8c1997f-d722-536c-ad9d-296b5e10ed04"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "d3224e16-1b0c-5266-9e14-ac72d2b2e988"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "b24df75b-6bb9-5f5f-99e3-c883ec3644ec"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP12": {
                    "questionId": "BP12",
                    "chosenOptionIds": [
                        "1d6299ac-2ac6-51e2-9a3f-13e8d44e652a"
                    ]
                },
                "BP12.5": {
                    "questionId": "BP12.5",
                    "chosenOptionIds": [
                        "cd7ce00f-4e7c-57ac-9c6c-4137efa24c8c"
                    ]
                },
                "BP14": {
                    "questionId": "BP14",
                    "chosenOptionIds": [
                        "97bbe87b-c5ff-51c1-a99c-cec263cff7d4"
                    ]
                },
                "BP14.1": {
                    "questionId": "BP14.1",
                    "chosenOptionIds": [
                        "e1b5b8f7-1317-5136-a8c1-2afdfa91e615"
                    ]
                },
                "BP14.2": {
                    "questionId": "BP14.2",
                    "freeValue": 84
                },
                "BP14.3": {
                    "questionId": "BP14.3",
                    "chosenOptionIds": [
                        "9f5fc152-75fb-59d8-8f40-7a6ca15c69cb"
                    ]
                },
                "BP16": {
                    "questionId": "BP16",
                    "chosenOptionIds": [
                        "fabceeea-8fea-5444-8b67-8f189f366c27"
                    ]
                },
                "BP16.0": {
                    "questionId": "BP16.0",
                    "chosenOptionIds": [
                        "4b26caa5-9124-51b2-ab08-2f4e977cee1f"
                    ]
                },
                "BP16.01": {
                    "questionId": "BP16.01",
                    "chosenOptionIds": [
                        "f81655d7-78e5-59b1-a7eb-90a2ec7a107d"
                    ]
                },
                "BP16.02": {
                    "questionId": "BP16.02",
                    "chosenOptionIds": [
                        "16a695c9-3ac1-5d34-b799-44602d85b556"
                    ]
                },
                "BP18": {
                    "questionId": "BP18",
                    "chosenOptionIds": [
                        "b577f323-ec54-5d25-a2b9-4f8de30d60a2"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 48
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "f03de28d-8f71-5af4-be2f-45f4e6092a3d"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "387b19c0-67d0-557e-9fba-ae065dcf3587"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "a4ed572c-6aae-57f6-bc35-ea46929c1437"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "667ba57c-b1cd-5f9b-aa12-58e65a981910"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "a2056280-4b54-5258-8bb4-407b521fff4b"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "d9fb150b-68b8-50ed-bb1d-eafd41f63f3b"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "0feda24f-7b01-557b-8ca5-10eb924cbdac"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 360
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "d680a8d9-e35f-563f-9d1d-e7c3136aff6d"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "94795194-4a80-590f-be62-ee78f2fa0ff5"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "201c7ee3-56dc-51e9-ad55-69951192ab16"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "de2ea429-4fcd-53b8-8d9f-ebb69a637a02"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "dbab3669-e910-5267-b7b1-fcb661ea7e81"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "4a7683c6-774e-5362-8060-740f61986c9b"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "58777a2e-4b97-5413-b340-efe722e45936"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "5c06d516-e816-58d6-997e-f8fa91131ac7"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "c0cc09c3-1335-5eb4-8801-b3cdaf74f6fa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM19": {
                    "questionId": "HM19",
                    "chosenOptionIds": [
                        "99f474d7-d509-592a-856a-afed343fb1b8"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "c3ee8981-dec3-5b3c-80ca-c1023f983a6d"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "a43647cb-a0e8-5705-adb9-a2b68877dc77"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c41974b6-d394-569c-8f45-49f79fcac5c6"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "43c32319-c75f-5169-b0e5-713820721d4f"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "b2cb304f-f035-53fc-8763-e2f91b031f9b"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "0170b4cf-0541-53cd-a9db-818d5067dc23"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "2be66549-5767-51b4-82ed-deeab0723f8d"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "2a17c2fe-2592-58b1-98e9-3dc4168fe3e3"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "7e32475a-3fcc-51ac-b414-ab46578efdfd"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "86277249-cf50-5f91-a27f-f3a69344e796"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "8823c74f-ff7c-52d8-9c73-fd3b4180f48a"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "4b8e4843-d260-59e5-8468-1303b1c3a638"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: Z3R680O aspect: simple_bd1 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "3bd18f66-6f1e-5ea4-ae24-2990ed197bef"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "9b47900d-e5a7-5e58-830b-d9b3130a7990"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "826538fc-8a97-55e4-8013-fe6a93f8e451"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 4015
                },
                "D-15a": {
                    "questionId": "D-15a",
                    "chosenOptionIds": [
                        "7b763ff0-7703-54f7-8eae-aa83417ca133"
                    ]
                },
                "D-16.5a": {
                    "questionId": "D-16.5a",
                    "chosenOptionIds": [
                        "5f88b934-32eb-5ed5-8896-a818cbe4b033"
                    ]
                },
                "D-17a": {
                    "questionId": "D-17a",
                    "chosenOptionIds": [
                        "8f929de7-3c80-5c53-9cb9-14fee6708b8c"
                    ]
                },
                "D-18a": {
                    "questionId": "D-18a",
                    "chosenOptionIds": [
                        "615b852b-6f81-54a9-bc4a-a8843e82c230"
                    ]
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "db360e93-3616-5d89-be36-5ccee4aacf1c"
                    ]
                },
                "D-20a": {
                    "questionId": "D-20a",
                    "chosenOptionIds": [
                        "d262a22d-afad-541d-82b0-fabd40c42423"
                    ]
                },
                "D-21a": {
                    "questionId": "D-21a",
                    "chosenOptionIds": [
                        "e64b46f6-a0c1-5378-95de-f1de59b491b4"
                    ]
                },
                "D-23a": {
                    "questionId": "D-23a",
                    "chosenOptionIds": [
                        "44fe96b8-192b-5b6b-88fa-9c2f7ba73a6d"
                    ]
                },
                "D-26a": {
                    "questionId": "D-26a",
                    "chosenOptionIds": [
                        "823c244d-25bb-5b84-9f3c-16e82c594aee"
                    ]
                },
                "D-27a": {
                    "questionId": "D-27a",
                    "chosenOptionIds": [
                        "8896026c-15ab-577e-8c44-e63eb7e6dba3"
                    ]
                },
                "D-28a": {
                    "questionId": "D-28a",
                    "chosenOptionIds": [
                        "801353cf-2f72-53db-9179-e7ecc31b1fc1"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "de11fb6b-7e7d-52b1-9d09-2d6debc53314"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "75d33f2f-6fcd-5e09-8574-899eabeb22e6",
                        "14d310b2-5fb6-5f79-8bfa-e3524002e63d",
                        "bccbebb3-ed8c-5251-856b-e93a1d4386f6"
                    ]
                },
                "D-30a": {
                    "questionId": "D-30a",
                    "chosenOptionIds": [
                        "d309cef4-2204-5a10-a98d-b66740336838"
                    ]
                },
                "D-31a": {
                    "questionId": "D-31a",
                    "chosenOptionIds": [
                        "1cb5b1dd-fcae-50f1-b26b-53e0d5e03ed5"
                    ]
                },
                "D-32a": {
                    "questionId": "D-32a",
                    "chosenOptionIds": [
                        "ea2a4fbd-008a-571b-bb68-cbd0ba351521"
                    ]
                },
                "D-33a": {
                    "questionId": "D-33a",
                    "chosenOptionIds": [
                        "379331e4-3b82-582c-af51-cc4ed79308d7"
                    ]
                },
                "D-34a": {
                    "questionId": "D-34a",
                    "chosenOptionIds": [
                        "e919c54f-d9a4-5a15-9ae7-8b6b52607a22"
                    ]
                },
                "D-35a": {
                    "questionId": "D-35a",
                    "chosenOptionIds": [
                        "3a07e052-2332-5a23-bb19-3147a5b9d2c7"
                    ]
                },
                "D-36a": {
                    "questionId": "D-36a",
                    "chosenOptionIds": [
                        "4ab32dca-be9e-573e-a330-24f9350ffaca"
                    ]
                },
                "D-37a": {
                    "questionId": "D-37a",
                    "chosenOptionIds": [
                        "fd4e0608-283c-5f56-a026-edfa381519c9"
                    ]
                },
                "D-38a": {
                    "questionId": "D-38a",
                    "chosenOptionIds": [
                        "8d5bbc66-0b33-5de5-838e-ff0fccbb6fbc"
                    ]
                },
                "D-39a": {
                    "questionId": "D-39a",
                    "chosenOptionIds": [
                        "a2f071cb-74be-518d-a33d-12a101ac9770"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5b6c0a69-6369-53d1-b2e1-5cd6294a9f71"
                    ]
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-40a": {
                    "questionId": "D-40a",
                    "chosenOptionIds": [
                        "582119a7-d4a0-58ff-9b1d-2af045e11a7f"
                    ]
                },
                "D-41a": {
                    "questionId": "D-41a",
                    "chosenOptionIds": [
                        "89f5ff80-0cae-515a-a4d1-02448dbc11b8"
                    ]
                },
                "D-42a": {
                    "questionId": "D-42a",
                    "chosenOptionIds": [
                        "926931de-e675-5117-9e61-78dd541393be"
                    ]
                },
                "D-43a": {
                    "questionId": "D-43a",
                    "chosenOptionIds": [
                        "22b1ff23-c3a7-56b8-8108-65eacd7d3fbe"
                    ]
                },
                "D-44.5a": {
                    "questionId": "D-44.5a",
                    "chosenOptionIds": [
                        "b5fc3288-710a-5aef-9842-611757b40967",
                        "884767e3-ed0c-5db3-a227-f84f7ecaf8d3"
                    ]
                },
                "D-44a": {
                    "questionId": "D-44a",
                    "chosenOptionIds": [
                        "57c0e55d-5ee4-5237-9c5d-e317b90c5dbd"
                    ]
                },
                "D-45a": {
                    "questionId": "D-45a",
                    "freeValue": 0
                },
                "D-46a": {
                    "questionId": "D-46a",
                    "chosenOptionIds": [
                        "c2c0e745-9e7b-5f61-8565-fbc0d5c49f71",
                        "d9665b1f-cdd2-5979-895e-27bca6487533",
                        "7492eab7-c625-52d0-a2e3-4bf3332decc0",
                        "6e49f368-62af-5abb-9034-a58bd534944f",
                        "b3e43bd8-fbe3-5cda-b891-6f11e43993d0"
                    ]
                },
                "D-49a": {
                    "questionId": "D-49a",
                    "chosenOptionIds": [
                        "8eaac5aa-39e4-5f7d-bbe9-ba405b3dba9c"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b",
                        "1d4beed1-78e5-531c-a255-643fac260dee"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "b52073e3-c813-51f5-88ba-9cd3bddd7023"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "3e093dd3-857e-5004-a4fb-961fbfa59ece"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "772bee29-c3a5-52e1-b785-a77d506ae4fb"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "67316d94-1cf0-5bd0-a4db-f57bc70684ba"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "13f8bac1-d0f5-539b-8596-1c55821bea46"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "60fe0bb4-d770-56f2-b05a-864625a48e4d"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "ccbdcbec-96d0-5362-977b-784289f5cfc0"
                    ]
                },
                "BP10": {
                    "questionId": "BP10",
                    "chosenOptionIds": [
                        "7ad54f0a-87a6-5e82-bfc3-44b540230082"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "026a1cea-7cfc-540b-bfe5-42886be90595"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "1c4ed441-5919-569e-98ba-b49be1bc3957"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "d3224e16-1b0c-5266-9e14-ac72d2b2e988"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "b24df75b-6bb9-5f5f-99e3-c883ec3644ec"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 16
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "c7bee7a0-3037-5eb1-96a1-85b884d7e1dd"
                    ]
                },
                "BP3.01": {
                    "questionId": "BP3.01",
                    "freeValue": 12
                },
                "BP3.1": {
                    "questionId": "BP3.1",
                    "chosenOptionIds": [
                        "c053dd45-79bd-59bf-82df-7e9ecd85dc11"
                    ]
                },
                "BP3.2": {
                    "questionId": "BP3.2",
                    "chosenOptionIds": [
                        "ec333f8b-146a-5700-a15d-527032ceac3f"
                    ]
                },
                "BP3.3": {
                    "questionId": "BP3.3",
                    "freeValue": 3
                },
                "BP3.4": {
                    "questionId": "BP3.4",
                    "chosenOptionIds": [
                        "f304bf93-0c68-5da0-80ba-ccecc2d0fce1"
                    ]
                },
                "BP4": {
                    "questionId": "BP4",
                    "chosenOptionIds": [
                        "9765aba8-7d55-5928-b79d-36aa58646036"
                    ]
                },
                "BP4.5": {
                    "questionId": "BP4.5",
                    "chosenOptionIds": [
                        "66a3460c-65ce-5a4c-9774-2e303ae4961e"
                    ]
                },
                "BP6": {
                    "questionId": "BP6",
                    "chosenOptionIds": [
                        "52f153db-76e7-57fa-90bd-63d5a923964a"
                    ]
                },
                "BP6.1": {
                    "questionId": "BP6.1",
                    "chosenOptionIds": [
                        "430b68c6-29cf-5a15-ab4c-89bd842383bf"
                    ]
                },
                "BP6.2": {
                    "questionId": "BP6.2",
                    "freeValue": 3
                },
                "BP6.3": {
                    "questionId": "BP6.3",
                    "chosenOptionIds": [
                        "e061c578-086e-5990-bba6-279cb273f3a5"
                    ]
                },
                "BP8": {
                    "questionId": "BP8",
                    "chosenOptionIds": [
                        "87e0081b-9455-5ced-9225-10c2f70b1734"
                    ]
                },
                "BP8.0": {
                    "questionId": "BP8.0",
                    "chosenOptionIds": [
                        "3568cdb1-2500-512b-aada-2547c5bfd835"
                    ]
                },
                "BP8.01": {
                    "questionId": "BP8.01",
                    "chosenOptionIds": [
                        "5020bfdd-a280-56cf-b7f5-3b0b7e667165"
                    ]
                },
                "BP8.02": {
                    "questionId": "BP8.02",
                    "chosenOptionIds": [
                        "e88670a3-c6d8-5c96-914e-b9fc1818aeda"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "387b19c0-67d0-557e-9fba-ae065dcf3587"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "afb57366-c0b3-5f73-92eb-27bc133ad2ed"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "667ba57c-b1cd-5f9b-aa12-58e65a981910"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "fd30dc27-8e89-5d1a-9d95-8844603d4379"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "e22cf233-c174-52fb-af55-97bf9c46e971"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "1f57d1a0-3682-5d7d-b66b-8179ec5c3988"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "d680a8d9-e35f-563f-9d1d-e7c3136aff6d"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "82997889-d795-572b-a597-e484ef078b39"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "1e86d64a-b972-500a-9120-7884e4b86ede"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "e232ba2d-36a9-524e-9fb2-8541b27bb569"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "352885c9-c1e1-5206-9f52-8d9b6d2aa166"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "b14d53e4-f898-52ac-87d7-3aa62abc9d7b"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "5c06d516-e816-58d6-997e-f8fa91131ac7"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "c0cc09c3-1335-5eb4-8801-b3cdaf74f6fa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM2": {
                    "questionId": "HM2",
                    "chosenOptionIds": [
                        "de662c3f-66b4-566f-856d-2124e2725542"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "98ea07a0-ae95-570f-8d11-a3e7c682e986"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "696fdd9d-5ced-504b-a933-bb3e575b0099"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c41974b6-d394-569c-8f45-49f79fcac5c6"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "43c32319-c75f-5169-b0e5-713820721d4f"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "b2cb304f-f035-53fc-8763-e2f91b031f9b"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "0170b4cf-0541-53cd-a9db-818d5067dc23"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "6b35cd3b-d67a-54af-94f6-e39ddf5af6c6"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "2a17c2fe-2592-58b1-98e9-3dc4168fe3e3"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "834b04f2-af09-5337-8fd5-be4a70c99e75"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "86277249-cf50-5f91-a27f-f3a69344e796"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "8823c74f-ff7c-52d8-9c73-fd3b4180f48a"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "1d07911e-929d-5719-aeea-a68f593d18e8"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: Z35MQ3O aspect: simple_bd1 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "0ec0e224-a5cf-5ece-b3ab-37061a600ace"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "a16e8e14-524e-53df-b4ef-730a2c8e5f32"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "916d657d-9f2a-5451-8465-18e3bd863347"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "b74511c7-9f97-5c15-8bf5-6ec4357b4814"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 365
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "913bc1e9-8da1-54c7-b935-13fc5d48fbd9"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "11f457f5-4c39-5458-ba9a-84478dd379ba"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5c30c60e-d53e-5cc3-b4a9-fdad9bec4cb7"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "f3efc764-ca53-52c3-9c3a-38c7f787d415"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "08a0f83f-2f37-5904-9364-4e40deb90e98"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "adecf2d3-f302-50f8-a4a9-3ffac1789bc6"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "d362cd87-7515-551e-bc74-19ef903484aa"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "44fd9eb3-aee2-5af0-b83c-dbbbf9514022"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "ccbdcbec-96d0-5362-977b-784289f5cfc0"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "4a0f1c4d-38cf-5de4-99e3-0ee818f9709c"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "6a5aa899-7b89-5661-a554-71b6058ad4a4"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "a3a547f3-b48b-567f-a32a-6a3f5f4bacd7"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "b24df75b-6bb9-5f5f-99e3-c883ec3644ec"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP12": {
                    "questionId": "BP12",
                    "chosenOptionIds": [
                        "22f9a995-3f9a-5fe9-99bb-71b7f1f96621"
                    ]
                },
                "BP15": {
                    "questionId": "BP15",
                    "chosenOptionIds": [
                        "d8c37d8b-01b7-581e-9d97-f6c8b8bf9267"
                    ]
                },
                "BP15.1": {
                    "questionId": "BP15.1",
                    "chosenOptionIds": [
                        "35a5b342-38f4-5343-8a4f-a3f024b95530"
                    ]
                },
                "BP15.2": {
                    "questionId": "BP15.2",
                    "freeValue": 1825
                },
                "BP15.3": {
                    "questionId": "BP15.3",
                    "chosenOptionIds": [
                        "e65c7281-c61b-5d1b-80d2-6d817860040c"
                    ]
                },
                "BP16": {
                    "questionId": "BP16",
                    "chosenOptionIds": [
                        "fabceeea-8fea-5444-8b67-8f189f366c27"
                    ]
                },
                "BP16.0": {
                    "questionId": "BP16.0",
                    "chosenOptionIds": [
                        "4b26caa5-9124-51b2-ab08-2f4e977cee1f"
                    ]
                },
                "BP16.01": {
                    "questionId": "BP16.01",
                    "chosenOptionIds": [
                        "f81655d7-78e5-59b1-a7eb-90a2ec7a107d"
                    ]
                },
                "BP16.02": {
                    "questionId": "BP16.02",
                    "chosenOptionIds": [
                        "16a695c9-3ac1-5d34-b799-44602d85b556"
                    ]
                },
                "BP19": {
                    "questionId": "BP19",
                    "chosenOptionIds": [
                        "601bc370-8a4c-5b1f-a7f6-6c174f421733"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 25
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "f03de28d-8f71-5af4-be2f-45f4e6092a3d"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "e620af65-9b5b-57e6-b13a-65616e67e084"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "cb636d32-4f4e-51b3-a23f-2a11b8771b7e"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "92e118de-38b9-5d5a-96ba-7b50547c0621"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "fd30dc27-8e89-5d1a-9d95-8844603d4379"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "e22cf233-c174-52fb-af55-97bf9c46e971"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "46c90446-3ae9-53f5-9b57-ad19d66b5be6"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "d680a8d9-e35f-563f-9d1d-e7c3136aff6d"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "f35fe383-d2aa-51c4-9fde-961b1de6d52b"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "201c7ee3-56dc-51e9-ad55-69951192ab16"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "dbab3669-e910-5267-b7b1-fcb661ea7e81"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "17b4da6f-7135-523c-b90b-2fe3b66785ff"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "3cf77140-2757-58eb-b4ea-f2a90cd766e7"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "5c06d516-e816-58d6-997e-f8fa91131ac7"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "c0cc09c3-1335-5eb4-8801-b3cdaf74f6fa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM20": {
                    "questionId": "HM20",
                    "chosenOptionIds": [
                        "b8816e4b-2104-5ce7-a5b0-b804a1a0dc20"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "c3ee8981-dec3-5b3c-80ca-c1023f983a6d"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "696fdd9d-5ced-504b-a933-bb3e575b0099"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c41974b6-d394-569c-8f45-49f79fcac5c6"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "43c32319-c75f-5169-b0e5-713820721d4f"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "b2cb304f-f035-53fc-8763-e2f91b031f9b"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "0170b4cf-0541-53cd-a9db-818d5067dc23"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "6b35cd3b-d67a-54af-94f6-e39ddf5af6c6"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "2a17c2fe-2592-58b1-98e9-3dc4168fe3e3"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "0f2a6025-0d8f-5d89-81a2-c3cc425cd7c4"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "86277249-cf50-5f91-a27f-f3a69344e796"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "bde4e5f3-19e4-571f-84a2-5b9d188d74ef"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "42710499-d4f3-5f65-bfad-382dde7e2f83"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: REOG402 aspect: simple_bd1 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "c61d70d4-b773-54dd-94d0-d858a47be0fb"
                    ]
                },
                "D-1.5b": {
                    "questionId": "D-1.5b",
                    "freeValue": 3
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "a16e8e14-524e-53df-b4ef-730a2c8e5f32"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "916d657d-9f2a-5451-8465-18e3bd863347"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "b74511c7-9f97-5c15-8bf5-6ec4357b4814"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 730
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "913bc1e9-8da1-54c7-b935-13fc5d48fbd9"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-1bl": {
                    "questionId": "D-1bl",
                    "chosenOptionIds": [
                        "83937123-de06-5b20-a479-c5e3e92848c4"
                    ]
                },
                "D-2": {
                    "questionId": "D-2",
                    "freeValue": 18
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "11f457f5-4c39-5458-ba9a-84478dd379ba"
                    ]
                },
                "D-2bl": {
                    "questionId": "D-2bl",
                    "chosenOptionIds": [
                        "02464c9b-d1d8-5f60-993d-5f37cbfcc102"
                    ]
                },
                "D-2bs": {
                    "questionId": "D-2bs",
                    "chosenOptionIds": [
                        "71e4fa61-0420-5a47-8362-38d1db6b5895"
                    ]
                },
                "D-2bt": {
                    "questionId": "D-2bt",
                    "chosenOptionIds": [
                        "618070b6-e9e5-599a-a906-eed44f5c2dd9"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "fd9c4394-6664-5144-8797-00d27a4dda01",
                        "bccbebb3-ed8c-5251-856b-e93a1d4386f6",
                        "75d33f2f-6fcd-5e09-8574-899eabeb22e6"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5c30c60e-d53e-5cc3-b4a9-fdad9bec4cb7"
                    ]
                },
                "D-3bl": {
                    "questionId": "D-3bl",
                    "freeValue": 0.08333333333333333
                },
                "D-3bs": {
                    "questionId": "D-3bs",
                    "chosenOptionIds": [
                        "fe554f25-dfd7-5062-a2b8-f2662e2c3466"
                    ]
                },
                "D-3bt": {
                    "questionId": "D-3bt",
                    "chosenOptionIds": [
                        "0d89dae6-d14a-56da-8409-ea7c21e59375"
                    ]
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-42bt": {
                    "questionId": "D-42bt",
                    "freeValue": 0
                },
                "D-43bt": {
                    "questionId": "D-43bt",
                    "chosenOptionIds": [
                        "90e60395-fc3d-56cb-b6f7-2e24fba02803"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b"
                    ]
                },
                "D-4bs": {
                    "questionId": "D-4bs",
                    "freeValue": 0.08333333333333333
                },
                "D-4bt": {
                    "questionId": "D-4bt",
                    "freeValue": 0.08333333333333333
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "dde1af45-4686-52c9-b960-369527b28f6c"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "772bee29-c3a5-52e1-b785-a77d506ae4fb"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "d362cd87-7515-551e-bc74-19ef903484aa"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "13f8bac1-d0f5-539b-8596-1c55821bea46"
                    ]
                },
                "D-s10bt": {
                    "questionId": "D-s10bt",
                    "chosenOptionIds": [
                        "ce0e07f8-36b3-5424-a489-abd9de8a21a4"
                    ]
                },
                "D-s1bt": {
                    "questionId": "D-s1bt",
                    "chosenOptionIds": [
                        "33dd907f-3e0e-5735-96bb-c101f0acced9"
                    ]
                },
                "D-s2bt": {
                    "questionId": "D-s2bt",
                    "chosenOptionIds": [
                        "aef7f114-e448-575f-bcbb-035e54001375"
                    ]
                },
                "D-s3bt": {
                    "questionId": "D-s3bt",
                    "chosenOptionIds": [
                        "11f57889-ac93-53ab-88a7-9aea03a29429"
                    ]
                },
                "D-s4bt": {
                    "questionId": "D-s4bt",
                    "chosenOptionIds": [
                        "a4021497-af94-56a4-8075-392653262e2e"
                    ]
                },
                "D-s5bt": {
                    "questionId": "D-s5bt",
                    "chosenOptionIds": [
                        "055edc25-4e0b-55a7-8739-145992d4156a"
                    ]
                },
                "D-s6bt": {
                    "questionId": "D-s6bt",
                    "chosenOptionIds": [
                        "f7aa2ac0-a087-5371-8266-7601901cd1f9"
                    ]
                },
                "D-s7bt": {
                    "questionId": "D-s7bt",
                    "chosenOptionIds": [
                        "1df67815-0b9f-56ef-bd48-96ed977db141"
                    ]
                },
                "D-s8bt": {
                    "questionId": "D-s8bt",
                    "chosenOptionIds": [
                        "ebfe9c3e-852e-5116-8185-8430f6b361f8"
                    ]
                },
                "D-s9bt": {
                    "questionId": "D-s9bt",
                    "chosenOptionIds": [
                        "da503bbd-e567-576e-a21f-e0fad60dccdd"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "60fe0bb4-d770-56f2-b05a-864625a48e4d"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "ccbdcbec-96d0-5362-977b-784289f5cfc0"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "db7aa4b7-99a5-5475-a4e8-a744f3ef5a4a"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "e9eb902a-d327-5ec5-938c-dc317ca59b13"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "d3224e16-1b0c-5266-9e14-ac72d2b2e988"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "b24df75b-6bb9-5f5f-99e3-c883ec3644ec"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "de4464c8-78fe-5876-aa00-25826ce5ec25"
                    ]
                },
                "BP105": {
                    "questionId": "BP105",
                    "chosenOptionIds": [
                        "4e9d21b3-29c7-5526-ada9-67b61957994e"
                    ]
                },
                "BP12": {
                    "questionId": "BP12",
                    "chosenOptionIds": [
                        "22f9a995-3f9a-5fe9-99bb-71b7f1f96621"
                    ]
                },
                "BP15": {
                    "questionId": "BP15",
                    "chosenOptionIds": [
                        "e9643ff4-2842-5b40-9c98-5b5d0e9b6a5e"
                    ]
                },
                "BP15.1": {
                    "questionId": "BP15.1",
                    "chosenOptionIds": [
                        "35a5b342-38f4-5343-8a4f-a3f024b95530"
                    ]
                },
                "BP15.2": {
                    "questionId": "BP15.2",
                    "freeValue": 3650
                },
                "BP15.3": {
                    "questionId": "BP15.3",
                    "chosenOptionIds": [
                        "cfa519ff-08a9-5fa4-b12d-87b7a597cb13"
                    ]
                },
                "BP16": {
                    "questionId": "BP16",
                    "chosenOptionIds": [
                        "fabceeea-8fea-5444-8b67-8f189f366c27"
                    ]
                },
                "BP16.0": {
                    "questionId": "BP16.0",
                    "chosenOptionIds": [
                        "4b26caa5-9124-51b2-ab08-2f4e977cee1f"
                    ]
                },
                "BP16.01": {
                    "questionId": "BP16.01",
                    "chosenOptionIds": [
                        "f81655d7-78e5-59b1-a7eb-90a2ec7a107d"
                    ]
                },
                "BP16.02": {
                    "questionId": "BP16.02",
                    "chosenOptionIds": [
                        "16a695c9-3ac1-5d34-b799-44602d85b556"
                    ]
                },
                "BP19": {
                    "questionId": "BP19",
                    "chosenOptionIds": [
                        "601bc370-8a4c-5b1f-a7f6-6c174f421733"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 30
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "f03de28d-8f71-5af4-be2f-45f4e6092a3d"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "9ae96e50-5c86-58a7-a845-fb6347c7edd1"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "5f694075-3a97-5f8b-8701-2710a43127a9"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "2404146e-cd56-5356-a23b-6427b4bbba06"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "5d5fa968-aeb3-569a-8a71-483ef04b9a6a"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "e22cf233-c174-52fb-af55-97bf9c46e971"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "0feda24f-7b01-557b-8ca5-10eb924cbdac"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "d680a8d9-e35f-563f-9d1d-e7c3136aff6d"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "f35fe383-d2aa-51c4-9fde-961b1de6d52b"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "732c51f9-a0b6-5951-bd7d-eaaec24316ba"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "dbab3669-e910-5267-b7b1-fcb661ea7e81"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "2723ed09-37f6-522c-92b1-e518d4fe9315"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "3cf77140-2757-58eb-b4ea-f2a90cd766e7"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "5c06d516-e816-58d6-997e-f8fa91131ac7"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "c0cc09c3-1335-5eb4-8801-b3cdaf74f6fa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM20": {
                    "questionId": "HM20",
                    "chosenOptionIds": [
                        "4ff0f88c-e951-5e87-a941-815105819115"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "98ea07a0-ae95-570f-8d11-a3e7c682e986"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "696fdd9d-5ced-504b-a933-bb3e575b0099"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c41974b6-d394-569c-8f45-49f79fcac5c6"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "43c32319-c75f-5169-b0e5-713820721d4f"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "d2c670c4-b05b-597a-a0af-5342230535a0"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "0170b4cf-0541-53cd-a9db-818d5067dc23"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "6b35cd3b-d67a-54af-94f6-e39ddf5af6c6"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "0983ccf5-a426-53d0-adbb-1baf6832190c"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "a56fcb96-f868-5d08-a358-9b403eb3cd16"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "fa32b497-4a4b-5076-a391-b6dffa8e1851"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "bde4e5f3-19e4-571f-84a2-5b9d188d74ef"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "1d07911e-929d-5719-aeea-a68f593d18e8"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: 4EG443P aspect: simple_bd1 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "c61d70d4-b773-54dd-94d0-d858a47be0fb"
                    ]
                },
                "D-1.5b": {
                    "questionId": "D-1.5b",
                    "freeValue": 8
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "3bd18f66-6f1e-5ea4-ae24-2990ed197bef"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "eb68a88d-b49b-5cde-a9c6-5e4c78e27d19"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "a0e528ca-2fbb-5da1-9398-0758f79755aa"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 60
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "7f1d6182-f8a7-5f4e-a0f8-2556b306ae36"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-1bl": {
                    "questionId": "D-1bl",
                    "chosenOptionIds": [
                        "4105c26a-4ff2-5fd7-984d-f0bcb7b5656e"
                    ]
                },
                "D-2": {
                    "questionId": "D-2",
                    "freeValue": 13
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "11f457f5-4c39-5458-ba9a-84478dd379ba"
                    ]
                },
                "D-2bl": {
                    "questionId": "D-2bl",
                    "chosenOptionIds": [
                        "02464c9b-d1d8-5f60-993d-5f37cbfcc102"
                    ]
                },
                "D-2bs": {
                    "questionId": "D-2bs",
                    "chosenOptionIds": [
                        "7caa4281-408a-5c83-b2b0-b42968a69322"
                    ]
                },
                "D-2bt": {
                    "questionId": "D-2bt",
                    "chosenOptionIds": [
                        "d585762b-2491-592f-9340-3053d9151b68"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "e797900f-129d-553e-b83f-8494d7f03986"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5c30c60e-d53e-5cc3-b4a9-fdad9bec4cb7"
                    ]
                },
                "D-3bl": {
                    "questionId": "D-3bl",
                    "freeValue": 7
                },
                "D-3bs": {
                    "questionId": "D-3bs",
                    "chosenOptionIds": [
                        "fe554f25-dfd7-5062-a2b8-f2662e2c3466"
                    ]
                },
                "D-3bt": {
                    "questionId": "D-3bt",
                    "chosenOptionIds": [
                        "0d89dae6-d14a-56da-8409-ea7c21e59375"
                    ]
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-42bt": {
                    "questionId": "D-42bt",
                    "freeValue": 0
                },
                "D-43bt": {
                    "questionId": "D-43bt",
                    "chosenOptionIds": [
                        "90e60395-fc3d-56cb-b6f7-2e24fba02803"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "9d1b858c-7064-5a84-80d6-786c18e603b6",
                        "1d4beed1-78e5-531c-a255-643fac260dee"
                    ]
                },
                "D-4bs": {
                    "questionId": "D-4bs",
                    "freeValue": 7
                },
                "D-4bt": {
                    "questionId": "D-4bt",
                    "freeValue": 1
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "188a7831-38c4-55c4-880a-1e4db4e9bab1"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "ef6fddab-7a07-5f20-af20-74f2444eee53"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "4c78ae3e-7de4-545f-aca1-03fa41fc0c23"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "13f8bac1-d0f5-539b-8596-1c55821bea46"
                    ]
                },
                "D-s10bt": {
                    "questionId": "D-s10bt",
                    "chosenOptionIds": [
                        "e3ab5219-3af6-5da7-8464-a145eb508a0f"
                    ]
                },
                "D-s11bt": {
                    "questionId": "D-s11bt",
                    "chosenOptionIds": [
                        "daf8b3c7-fb19-5a3f-ba9a-784adda7414f",
                        "dc476220-9479-5def-8a97-20e6152d44af",
                        "9c3fa6af-8b7b-5993-a587-641683d934d5",
                        "6994b88e-cfa5-5986-b9e8-625d17d1147c",
                        "16e1a0b8-28c5-5bcd-931d-335c858c3f88",
                        "64af3cae-713a-51e1-99c1-09e2fdaa755d",
                        "7921bccf-4756-59fe-b678-d536c2ab5945"
                    ]
                },
                "D-s1bt": {
                    "questionId": "D-s1bt",
                    "chosenOptionIds": [
                        "c17482e4-818a-50cd-90fa-1246d037825e"
                    ]
                },
                "D-s2bt": {
                    "questionId": "D-s2bt",
                    "chosenOptionIds": [
                        "aef7f114-e448-575f-bcbb-035e54001375"
                    ]
                },
                "D-s3bt": {
                    "questionId": "D-s3bt",
                    "chosenOptionIds": [
                        "11f57889-ac93-53ab-88a7-9aea03a29429"
                    ]
                },
                "D-s4bt": {
                    "questionId": "D-s4bt",
                    "chosenOptionIds": [
                        "fd9851a0-7ce7-55a8-b257-5617537d0013",
                        "a4021497-af94-56a4-8075-392653262e2e"
                    ]
                },
                "D-s5bt": {
                    "questionId": "D-s5bt",
                    "chosenOptionIds": [
                        "8260d432-3a96-519e-b824-d24940e4aa65"
                    ]
                },
                "D-s6bt": {
                    "questionId": "D-s6bt",
                    "chosenOptionIds": [
                        "bb7e3f7e-73b0-56b0-b3d3-e336498509e8"
                    ]
                },
                "D-s7bt": {
                    "questionId": "D-s7bt",
                    "chosenOptionIds": [
                        "d703f0d6-4b67-5a66-8740-b5e4b3b0eb4d"
                    ]
                },
                "D-s8bt": {
                    "questionId": "D-s8bt",
                    "chosenOptionIds": [
                        "b5d48aea-22b2-52a7-9306-3f2a4bcedc6c"
                    ]
                },
                "D-s9bt": {
                    "questionId": "D-s9bt",
                    "chosenOptionIds": [
                        "da503bbd-e567-576e-a21f-e0fad60dccdd"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "60fe0bb4-d770-56f2-b05a-864625a48e4d"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "6215a3de-a3e4-5f86-a039-1006646e6b93"
                    ]
                },
                "BP106": {
                    "questionId": "BP106",
                    "chosenOptionIds": [
                        "8c35da7e-8c0b-50bb-a037-b80a9f1df3d2"
                    ]
                },
                "BP108": {
                    "questionId": "BP108",
                    "chosenOptionIds": [
                        "d25b13ec-c438-5509-85b0-83a43b6793d4"
                    ]
                },
                "BP110": {
                    "questionId": "BP110",
                    "freeValue": 60
                },
                "BP110.01": {
                    "questionId": "BP110.01",
                    "chosenOptionIds": [
                        "a6d87c31-9a11-5151-958a-040439463b3d"
                    ]
                },
                "BP110.1": {
                    "questionId": "BP110.1",
                    "chosenOptionIds": [
                        "9d19a3c0-120d-5dfd-a794-8aa0e8d39e77"
                    ]
                },
                "BP110.2": {
                    "questionId": "BP110.2",
                    "chosenOptionIds": [
                        "d15430b9-4f4f-5c97-8716-cb907c561dd2"
                    ]
                },
                "BP111": {
                    "questionId": "BP111",
                    "chosenOptionIds": [
                        "02b20612-0b18-5aba-91d5-3ebd2589fb3d"
                    ]
                },
                "BP111.1": {
                    "questionId": "BP111.1",
                    "chosenOptionIds": [
                        "c532b673-2762-5ed0-8cbb-6f70d9977c20"
                    ]
                },
                "BP111.2": {
                    "questionId": "BP111.2",
                    "chosenOptionIds": [
                        "4c9c60d0-cd73-504d-8b03-b734045376ae"
                    ]
                },
                "BP112": {
                    "questionId": "BP112",
                    "chosenOptionIds": [
                        "6db4ba23-1d8f-58b9-b11e-eaea15417e28"
                    ]
                },
                "BP113": {
                    "questionId": "BP113",
                    "chosenOptionIds": [
                        "b16103eb-2ecf-595e-80e4-30baf575e01b"
                    ]
                },
                "BP114": {
                    "questionId": "BP114",
                    "chosenOptionIds": [
                        "a0206e39-59ad-539c-a2ff-e9fd935cf48b"
                    ]
                },
                "BP115": {
                    "questionId": "BP115",
                    "chosenOptionIds": [
                        "dd02d36a-25b2-5daa-8cc6-5bb2d1382229"
                    ]
                },
                "BP116": {
                    "questionId": "BP116",
                    "chosenOptionIds": [
                        "2d30ef1e-59ee-5b0a-8c01-0dcbfd64f6ca"
                    ]
                },
                "BP117": {
                    "questionId": "BP117",
                    "chosenOptionIds": [
                        "354c012f-3484-58ad-9300-d9e29a212ae3"
                    ]
                },
                "BP118": {
                    "questionId": "BP118",
                    "chosenOptionIds": [
                        "a611c3a0-727b-5a9b-8d15-d805b2ecf757"
                    ]
                },
                "BP118.1": {
                    "questionId": "BP118.1",
                    "chosenOptionIds": [
                        "801fd4db-2f09-5e10-a9b7-43319a3ac1d0"
                    ]
                },
                "BP119": {
                    "questionId": "BP119",
                    "chosenOptionIds": [
                        "50c573e0-accf-5acc-be15-616cb65704cc"
                    ]
                },
                "BP119.1": {
                    "questionId": "BP119.1",
                    "freeValue": 0
                },
                "BP120": {
                    "questionId": "BP120",
                    "chosenOptionIds": [
                        "c8162ccf-aae0-5678-aeb9-fe02ed13ad99"
                    ]
                },
                "BP121": {
                    "questionId": "BP121",
                    "chosenOptionIds": [
                        "7caa27c0-01f2-586f-825c-7c1bd527ef54"
                    ]
                },
                "BP122": {
                    "questionId": "BP122",
                    "chosenOptionIds": [
                        "b1759380-318d-54b0-9224-163fb659558b"
                    ]
                },
                "BP123": {
                    "questionId": "BP123",
                    "chosenOptionIds": [
                        "972a0931-d7f1-5ad4-90af-91046fd7b193"
                    ]
                },
                "BP124": {
                    "questionId": "BP124",
                    "chosenOptionIds": [
                        "343ddd19-6dd5-54fb-a817-730184c2faf1"
                    ]
                },
                "BP125": {
                    "questionId": "BP125",
                    "chosenOptionIds": [
                        "8136d074-8df6-5a91-b6b6-e8ffcfb42d7c"
                    ]
                },
                "BP126": {
                    "questionId": "BP126",
                    "chosenOptionIds": [
                        "52d061e3-96b8-5053-893a-03758e4f0518"
                    ]
                },
                "BP127": {
                    "questionId": "BP127",
                    "chosenOptionIds": [
                        "1fc78738-6afa-5714-89a1-8d5698fb9f69"
                    ]
                },
                "BP127.1": {
                    "questionId": "BP127.1",
                    "chosenOptionIds": [
                        "a2bbf0b5-4203-5b73-89f8-05f59515356f"
                    ]
                },
                "BP127.3": {
                    "questionId": "BP127.3",
                    "chosenOptionIds": [
                        "38efc61e-4155-51f0-8b64-341f627e5482"
                    ]
                },
                "BP128": {
                    "questionId": "BP128",
                    "chosenOptionIds": [
                        "5bf8ce18-aa1a-5c57-86d6-687efcad92de"
                    ]
                },
                "BP129": {
                    "questionId": "BP129",
                    "chosenOptionIds": [
                        "4b90a2f8-960f-5d69-8e86-9c46af2fec0f"
                    ]
                },
                "BP130": {
                    "questionId": "BP130",
                    "chosenOptionIds": [
                        "a04d679f-abeb-5fb0-9db7-2ed9997ab672"
                    ]
                },
                "BP131": {
                    "questionId": "BP131",
                    "chosenOptionIds": [
                        "e5863f5d-32a6-520a-9b40-8591adf1c1b8"
                    ]
                },
                "BP132": {
                    "questionId": "BP132",
                    "chosenOptionIds": [
                        "7da9fe3a-3186-562a-b4f2-170d5baf0c76"
                    ]
                },
                "BP133": {
                    "questionId": "BP133",
                    "chosenOptionIds": [
                        "75391070-9bde-59e4-b44a-68f38b6a4472"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM35": {
                    "questionId": "HM35",
                    "chosenOptionIds": [
                        "2b40020d-33ba-5560-9075-d482aa324496"
                    ]
                },
                "HM36": {
                    "questionId": "HM36",
                    "chosenOptionIds": [
                        "fc7ec28a-9883-58d5-a088-f3b113c2b4b3"
                    ]
                },
                "HM37": {
                    "questionId": "HM37",
                    "chosenOptionIds": [
                        "20de534d-f482-51c5-ad87-bd054787fddc"
                    ]
                },
                "HM38": {
                    "questionId": "HM38",
                    "chosenOptionIds": [
                        "5125bbbb-70fb-5adc-a41b-c65c6b96180d"
                    ]
                },
                "HM40": {
                    "questionId": "HM40",
                    "chosenOptionIds": [
                        "596144bf-d0ea-5d17-a2ae-7096c31e2def"
                    ]
                },
                "HM41": {
                    "questionId": "HM41",
                    "chosenOptionIds": [
                        "db39ddc0-79fd-5c61-b063-7f0bc0bab024"
                    ]
                },
                "HM43": {
                    "questionId": "HM43",
                    "chosenOptionIds": [
                        "08b88c09-4278-5bd5-b72d-4f4a9a69acd0"
                    ]
                },
                "HM44": {
                    "questionId": "HM44",
                    "chosenOptionIds": [
                        "00b843b3-a869-5c49-8c2b-f15c3bfbbb6d"
                    ]
                },
                "HM45": {
                    "questionId": "HM45",
                    "chosenOptionIds": [
                        "2722d440-372d-5471-8cab-ef78b8026a24"
                    ]
                },
                "HM46": {
                    "questionId": "HM46",
                    "chosenOptionIds": [
                        "9f868300-e1df-5567-8ebf-e2f984022e52"
                    ]
                },
                "HM47": {
                    "questionId": "HM47",
                    "chosenOptionIds": [
                        "e03a7683-7e3e-563d-b531-33e529887c90"
                    ]
                },
                "HM48": {
                    "questionId": "HM48",
                    "chosenOptionIds": [
                        "2db89264-9c8a-54e5-8efc-1b1ca45b0233"
                    ]
                },
                "HM49": {
                    "questionId": "HM49",
                    "chosenOptionIds": [
                        "ad65e31f-19f9-587c-bc99-0b3549cc5cb0"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: 73ZW63R aspect: simple_bd1 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "c61d70d4-b773-54dd-94d0-d858a47be0fb"
                    ]
                },
                "D-1.5b": {
                    "questionId": "D-1.5b",
                    "freeValue": 100
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "a16e8e14-524e-53df-b4ef-730a2c8e5f32"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "eb68a88d-b49b-5cde-a9c6-5e4c78e27d19"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "a0e528ca-2fbb-5da1-9398-0758f79755aa"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 3650
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "db360e93-3616-5d89-be36-5ccee4aacf1c"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-1bl": {
                    "questionId": "D-1bl",
                    "chosenOptionIds": [
                        "5da400b0-5bff-5744-a664-4305892ae669"
                    ]
                },
                "D-2": {
                    "questionId": "D-2",
                    "freeValue": 6
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "11f457f5-4c39-5458-ba9a-84478dd379ba"
                    ]
                },
                "D-2bl": {
                    "questionId": "D-2bl",
                    "chosenOptionIds": [
                        "2a8c96e2-cb5b-596a-99b6-a4885253776c"
                    ]
                },
                "D-2bs": {
                    "questionId": "D-2bs",
                    "chosenOptionIds": [
                        "7caa4281-408a-5c83-b2b0-b42968a69322"
                    ]
                },
                "D-2bt": {
                    "questionId": "D-2bt",
                    "chosenOptionIds": [
                        "d585762b-2491-592f-9340-3053d9151b68"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "e797900f-129d-553e-b83f-8494d7f03986"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "db7514e2-d317-55fb-82d3-2e4a49c2494b"
                    ]
                },
                "D-3bl": {
                    "questionId": "D-3bl",
                    "freeValue": 180
                },
                "D-3bs": {
                    "questionId": "D-3bs",
                    "chosenOptionIds": [
                        "c4422e8a-6a0a-51ea-8592-9157036922d1"
                    ]
                },
                "D-3bt": {
                    "questionId": "D-3bt",
                    "chosenOptionIds": [
                        "1a1865e2-c338-59ef-a2db-6e2cc7a17d77"
                    ]
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-42bt": {
                    "questionId": "D-42bt",
                    "freeValue": 2
                },
                "D-43bt": {
                    "questionId": "D-43bt",
                    "chosenOptionIds": [
                        "90e60395-fc3d-56cb-b6f7-2e24fba02803"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "9d1b858c-7064-5a84-80d6-786c18e603b6",
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b"
                    ]
                },
                "D-4bs": {
                    "questionId": "D-4bs",
                    "freeValue": 90
                },
                "D-4bt": {
                    "questionId": "D-4bt",
                    "freeValue": 30
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "b52073e3-c813-51f5-88ba-9cd3bddd7023"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "08a0f83f-2f37-5904-9364-4e40deb90e98"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "772bee29-c3a5-52e1-b785-a77d506ae4fb"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "67316d94-1cf0-5bd0-a4db-f57bc70684ba"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "f8e2518c-72cf-563b-b322-a03406c8ebac"
                    ]
                },
                "D-s10bt": {
                    "questionId": "D-s10bt",
                    "chosenOptionIds": [
                        "e3ab5219-3af6-5da7-8464-a145eb508a0f"
                    ]
                },
                "D-s11bt": {
                    "questionId": "D-s11bt",
                    "chosenOptionIds": [
                        "daf8b3c7-fb19-5a3f-ba9a-784adda7414f",
                        "dc476220-9479-5def-8a97-20e6152d44af",
                        "9c3fa6af-8b7b-5993-a587-641683d934d5",
                        "6994b88e-cfa5-5986-b9e8-625d17d1147c",
                        "16e1a0b8-28c5-5bcd-931d-335c858c3f88",
                        "bae5acc8-8664-54a2-ba97-c36244be4c44",
                        "64af3cae-713a-51e1-99c1-09e2fdaa755d",
                        "7921bccf-4756-59fe-b678-d536c2ab5945",
                        "049f46ae-b7bd-5a1e-8c63-351f481236ec"
                    ]
                },
                "D-s1bt": {
                    "questionId": "D-s1bt",
                    "chosenOptionIds": [
                        "5ebc2bd4-4b83-5875-b7b0-dd3698a7674c"
                    ]
                },
                "D-s2bt": {
                    "questionId": "D-s2bt",
                    "chosenOptionIds": [
                        "8783a6d5-cd94-5982-8fd6-66ae46e1c6df"
                    ]
                },
                "D-s3bt": {
                    "questionId": "D-s3bt",
                    "chosenOptionIds": [
                        "d065c0ca-9e6d-5300-8e3e-688b16f88c59"
                    ]
                },
                "D-s4bt": {
                    "questionId": "D-s4bt",
                    "chosenOptionIds": [
                        "fd9851a0-7ce7-55a8-b257-5617537d0013",
                        "5a198500-fc4b-55a1-9521-3abe49eed06f",
                        "a4021497-af94-56a4-8075-392653262e2e"
                    ]
                },
                "D-s5bt": {
                    "questionId": "D-s5bt",
                    "chosenOptionIds": [
                        "8260d432-3a96-519e-b824-d24940e4aa65"
                    ]
                },
                "D-s6bt": {
                    "questionId": "D-s6bt",
                    "chosenOptionIds": [
                        "57e8cb6f-9ce4-5078-acb5-c3f96ce8e463"
                    ]
                },
                "D-s7bt": {
                    "questionId": "D-s7bt",
                    "chosenOptionIds": [
                        "1df67815-0b9f-56ef-bd48-96ed977db141"
                    ]
                },
                "D-s8bt": {
                    "questionId": "D-s8bt",
                    "chosenOptionIds": [
                        "1a4dbf6d-5e13-509e-b1f1-1f8aa1072529"
                    ]
                },
                "D-s9bt": {
                    "questionId": "D-s9bt",
                    "chosenOptionIds": [
                        "c9f1ca13-44a9-5bfb-8999-eb1f82965c59"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "60fe0bb4-d770-56f2-b05a-864625a48e4d"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "83e6efd5-64a2-5f0e-a973-9278eae90cfb"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "a20d7b84-720e-53c5-a450-45cbde434507"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "ca5ac96d-2b69-5200-a063-61db840c9ef2"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "e76cac02-f6b2-5ab6-a467-e3025d857ab9"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "b24df75b-6bb9-5f5f-99e3-c883ec3644ec"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 12
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "c7bee7a0-3037-5eb1-96a1-85b884d7e1dd"
                    ]
                },
                "BP3.01": {
                    "questionId": "BP3.01",
                    "freeValue": 3
                },
                "BP3.1": {
                    "questionId": "BP3.1",
                    "chosenOptionIds": [
                        "11a8ab35-b2d7-55a4-8a77-809dc8f1f78a"
                    ]
                },
                "BP3.2": {
                    "questionId": "BP3.2",
                    "chosenOptionIds": [
                        "f6654515-5729-5299-a4f0-da99056e7ac9"
                    ]
                },
                "BP3.3": {
                    "questionId": "BP3.3",
                    "freeValue": 30
                },
                "BP3.4": {
                    "questionId": "BP3.4",
                    "chosenOptionIds": [
                        "f304bf93-0c68-5da0-80ba-ccecc2d0fce1"
                    ]
                },
                "BP4": {
                    "questionId": "BP4",
                    "chosenOptionIds": [
                        "4884cc9c-7210-5173-9438-d1eae9e9a6a8"
                    ]
                },
                "BP4.5": {
                    "questionId": "BP4.5",
                    "chosenOptionIds": [
                        "1be4b3b1-3a88-5544-af54-ebdfee1ce891"
                    ]
                },
                "BP6.5": {
                    "questionId": "BP6.5",
                    "chosenOptionIds": [
                        "58656f9f-9267-5c1d-9132-f342d05dc39e"
                    ]
                },
                "BP6.7": {
                    "questionId": "BP6.7",
                    "chosenOptionIds": [
                        "7cb3515b-004f-58a1-a02b-59c7d9510637"
                    ]
                },
                "BP6.8": {
                    "questionId": "BP6.8",
                    "freeValue": 30
                },
                "BP6.9": {
                    "questionId": "BP6.9",
                    "chosenOptionIds": [
                        "155da231-22f3-50d9-8786-2860edd50aab"
                    ]
                },
                "BP8": {
                    "questionId": "BP8",
                    "chosenOptionIds": [
                        "87e0081b-9455-5ced-9225-10c2f70b1734"
                    ]
                },
                "BP8.0": {
                    "questionId": "BP8.0",
                    "chosenOptionIds": [
                        "3568cdb1-2500-512b-aada-2547c5bfd835"
                    ]
                },
                "BP8.01": {
                    "questionId": "BP8.01",
                    "chosenOptionIds": [
                        "5020bfdd-a280-56cf-b7f5-3b0b7e667165"
                    ]
                },
                "BP8.02": {
                    "questionId": "BP8.02",
                    "chosenOptionIds": [
                        "e88670a3-c6d8-5c96-914e-b9fc1818aeda"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "960a08ff-7af7-56da-afda-5415a1654c85"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "f501dc76-7fcd-586b-ba1d-ad70ab8e8d33"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "28c75e9e-94ef-5c50-8645-f4ff93ccf2ec"
                    ]
                },
                "BP9": {
                    "questionId": "BP9",
                    "chosenOptionIds": [
                        "becfe35c-2096-5848-87ae-3f763cf59dd6"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "fd30dc27-8e89-5d1a-9d95-8844603d4379"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "c211fabc-a62d-5440-8ddc-f57eb21d7679"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "1f57d1a0-3682-5d7d-b66b-8179ec5c3988"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "ef5a7b22-b2b2-5a5b-8a80-ce5c251033fd"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "94795194-4a80-590f-be62-ee78f2fa0ff5"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "ec84966e-bb9f-5ba9-a0cf-d5d3503a74e0"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "9b27e3ff-f8ce-54c5-9220-0479ae53e889"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "352885c9-c1e1-5206-9f52-8d9b6d2aa166"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "b14d53e4-f898-52ac-87d7-3aa62abc9d7b"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "63509127-aadf-5fe2-8e1f-19d0d846d6ce"
                    ]
                },
                "BP99.1": {
                    "questionId": "BP99.1",
                    "chosenOptionIds": [
                        "34d19f12-207f-56ce-bde8-8d616f6e8b26"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "dee1118a-2ca3-52a6-ac43-a24ea7385f88"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM1": {
                    "questionId": "HM1",
                    "chosenOptionIds": [
                        "c44193b6-642c-56d3-ada3-eda9bd17a42e"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "c3ee8981-dec3-5b3c-80ca-c1023f983a6d"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "9d212885-9b07-5623-8234-226adff08f6d"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "3ab9515b-73f0-5fcf-a4d0-aebbc24c2721"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "0e9545ba-3f35-52b0-9a31-7fe54c933928"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "eb41dc92-16ec-5abf-a841-e1d0b262083e"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "cec918d6-b855-5672-94a8-cf14e5ae963a"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "7b310da1-94e3-5d45-a6c3-3905eeb28ec6"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "0983ccf5-a426-53d0-adbb-1baf6832190c"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "834b04f2-af09-5337-8fd5-be4a70c99e75"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "fda00d52-c8be-5b69-bdbd-905c13dddd4e"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "0360ab4b-77d5-5fcd-8d60-b7a005c12ceb"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "ba200400-b5a1-5ae8-aead-863c1a72be98"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: O0K1931 aspect: simple_bd1 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "0ec0e224-a5cf-5ece-b3ab-37061a600ace"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "a16e8e14-524e-53df-b4ef-730a2c8e5f32"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "916d657d-9f2a-5451-8465-18e3bd863347"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "b74511c7-9f97-5c15-8bf5-6ec4357b4814"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 42
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "db360e93-3616-5d89-be36-5ccee4aacf1c"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "de11fb6b-7e7d-52b1-9d09-2d6debc53314"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5c30c60e-d53e-5cc3-b4a9-fdad9bec4cb7"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "c9813959-ba61-5220-9e27-659abb256037"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "228ef7d9-008b-559d-ba14-6b928b486b38"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "d362cd87-7515-551e-bc74-19ef903484aa"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "4896b504-2025-52c3-85e2-c6cf3205cef7"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "7599bcaa-89a5-5f71-80ea-b70c360bb44a"
                    ]
                },
                "BP101b": {
                    "questionId": "BP101b",
                    "chosenOptionIds": [
                        "31b2d2c8-8f28-514e-9e58-aa878f6c8761"
                    ]
                },
                "BP102b": {
                    "questionId": "BP102b",
                    "chosenOptionIds": [
                        "46a64b5b-6641-5b1b-92aa-5366444fb134"
                    ]
                },
                "BP103b": {
                    "questionId": "BP103b",
                    "chosenOptionIds": [
                        "867b0ee4-73d9-5aac-b96d-bc2d85d5b183"
                    ]
                },
                "BP104b": {
                    "questionId": "BP104b",
                    "chosenOptionIds": [
                        "c59b5910-3279-509f-8175-f2fd0da2dc86"
                    ]
                },
                "BP87b": {
                    "questionId": "BP87b",
                    "chosenOptionIds": [
                        "fb568891-2ea3-5200-bbdb-ac0695f30b02"
                    ]
                },
                "BP88b": {
                    "questionId": "BP88b",
                    "chosenOptionIds": [
                        "e29e159f-f691-5aa7-b5a0-d13fe2c54170"
                    ]
                },
                "BP89b": {
                    "questionId": "BP89b",
                    "chosenOptionIds": [
                        "df5f6aa3-ab8c-5a4c-b9d9-e9a482aba1d7"
                    ]
                },
                "BP90b": {
                    "questionId": "BP90b",
                    "chosenOptionIds": [
                        "607460aa-6bfa-56fd-9d06-75ec91ef85a1"
                    ]
                },
                "BP92b": {
                    "questionId": "BP92b",
                    "chosenOptionIds": [
                        "dd87a55d-829b-54a2-a63d-5b8b634688ea"
                    ]
                },
                "BP94b": {
                    "questionId": "BP94b",
                    "chosenOptionIds": [
                        "1f4fafbf-913e-5edc-bfaf-3baae49fc91d"
                    ]
                },
                "BP95b": {
                    "questionId": "BP95b",
                    "chosenOptionIds": [
                        "fb0bd4a7-2793-5b81-badc-fe81b4bc82dc"
                    ]
                },
                "BP96b": {
                    "questionId": "BP96b",
                    "chosenOptionIds": [
                        "477d4084-be50-50ae-9e88-dc9e03cdad29"
                    ]
                },
                "BP97b": {
                    "questionId": "BP97b",
                    "chosenOptionIds": [
                        "6895096a-ef29-546e-9967-d7a23b4a2859"
                    ]
                },
                "BP98b": {
                    "questionId": "BP98b",
                    "chosenOptionIds": [
                        "f7b75a2b-3bd2-5734-9fa6-7706f7f8ac89"
                    ]
                },
                "BP99.3b": {
                    "questionId": "BP99.3b",
                    "chosenOptionIds": [
                        "bf30a6c2-f8e2-5305-b4d4-7ad6227449d3"
                    ]
                },
                "BP99b": {
                    "questionId": "BP99b",
                    "chosenOptionIds": [
                        "68860df1-e835-50d6-8eb2-f9d9f2f97efa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "BP_neg1": {
                    "questionId": "BP_neg1",
                    "chosenOptionIds": [
                        "119208b0-ff3b-5c43-80be-4f07e65b6248"
                    ]
                }
            }
        },
        {
            "name": "Mood test: J0V6N3L aspect: simple_bd1 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "fe031280-7d5d-52cb-aca8-1a8664b3234a"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "6ede390f-0e43-50e4-82e9-1203db9d8343"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "a0e528ca-2fbb-5da1-9398-0758f79755aa"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 60
                },
                "D-15a": {
                    "questionId": "D-15a",
                    "chosenOptionIds": [
                        "7b763ff0-7703-54f7-8eae-aa83417ca133"
                    ]
                },
                "D-16.5a": {
                    "questionId": "D-16.5a",
                    "chosenOptionIds": [
                        "ae0a4d4e-e9bf-51bf-8506-0cbced38db92"
                    ]
                },
                "D-17a": {
                    "questionId": "D-17a",
                    "chosenOptionIds": [
                        "495d3d07-8a1f-5213-bf79-59443d7aa5cf"
                    ]
                },
                "D-18a": {
                    "questionId": "D-18a",
                    "chosenOptionIds": [
                        "615b852b-6f81-54a9-bc4a-a8843e82c230"
                    ]
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "80c993c8-1a94-5684-a8f9-d8f5072c8e9c"
                    ]
                },
                "D-1bl": {
                    "questionId": "D-1bl",
                    "chosenOptionIds": [
                        "87ef50c9-ba5e-513e-8889-09d8395d4b9f"
                    ]
                },
                "D-2": {
                    "questionId": "D-2",
                    "freeValue": 17
                },
                "D-20a": {
                    "questionId": "D-20a",
                    "chosenOptionIds": [
                        "b28ed556-a386-5556-a96f-ae7708ded1ab"
                    ]
                },
                "D-21a": {
                    "questionId": "D-21a",
                    "chosenOptionIds": [
                        "dc2b002f-2d96-504d-bde9-66faceececb6"
                    ]
                },
                "D-23a": {
                    "questionId": "D-23a",
                    "chosenOptionIds": [
                        "f6cf63c5-0c57-5422-9431-55fec4c93c63"
                    ]
                },
                "D-26a": {
                    "questionId": "D-26a",
                    "chosenOptionIds": [
                        "823c244d-25bb-5b84-9f3c-16e82c594aee"
                    ]
                },
                "D-27a": {
                    "questionId": "D-27a",
                    "chosenOptionIds": [
                        "13a1b08d-9817-5c2f-96cb-f8d03b80a37d"
                    ]
                },
                "D-28a": {
                    "questionId": "D-28a",
                    "chosenOptionIds": [
                        "801353cf-2f72-53db-9179-e7ecc31b1fc1"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "cc243947-5067-5a98-ade6-a4e10d0b42cf"
                    ]
                },
                "D-2bl": {
                    "questionId": "D-2bl",
                    "chosenOptionIds": [
                        "78c1434b-e585-5f1d-b291-a1728f789022"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "75d33f2f-6fcd-5e09-8574-899eabeb22e6",
                        "bccbebb3-ed8c-5251-856b-e93a1d4386f6"
                    ]
                },
                "D-30a": {
                    "questionId": "D-30a",
                    "chosenOptionIds": [
                        "d309cef4-2204-5a10-a98d-b66740336838"
                    ]
                },
                "D-31a": {
                    "questionId": "D-31a",
                    "chosenOptionIds": [
                        "eba65e81-9b02-55d6-82cb-0b7ce6da41de"
                    ]
                },
                "D-32a": {
                    "questionId": "D-32a",
                    "chosenOptionIds": [
                        "ea2a4fbd-008a-571b-bb68-cbd0ba351521"
                    ]
                },
                "D-33a": {
                    "questionId": "D-33a",
                    "chosenOptionIds": [
                        "3968dd70-f060-5cbd-bb6a-d63f702a3691"
                    ]
                },
                "D-34a": {
                    "questionId": "D-34a",
                    "chosenOptionIds": [
                        "e919c54f-d9a4-5a15-9ae7-8b6b52607a22"
                    ]
                },
                "D-35a": {
                    "questionId": "D-35a",
                    "chosenOptionIds": [
                        "3a07e052-2332-5a23-bb19-3147a5b9d2c7"
                    ]
                },
                "D-36a": {
                    "questionId": "D-36a",
                    "chosenOptionIds": [
                        "185038dd-ce55-53b2-ba09-0bd163af90b3"
                    ]
                },
                "D-37a": {
                    "questionId": "D-37a",
                    "chosenOptionIds": [
                        "c8ebfb14-b9aa-5da4-8de3-ab8b83254249"
                    ]
                },
                "D-38a": {
                    "questionId": "D-38a",
                    "chosenOptionIds": [
                        "a03f02de-f86d-525a-b375-383ad9d3cefe"
                    ]
                },
                "D-39a": {
                    "questionId": "D-39a",
                    "chosenOptionIds": [
                        "6325d970-8ab3-5759-b71d-96b5909ec039"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5b6c0a69-6369-53d1-b2e1-5cd6294a9f71"
                    ]
                },
                "D-3bl": {
                    "questionId": "D-3bl",
                    "freeValue": 730
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-40a": {
                    "questionId": "D-40a",
                    "chosenOptionIds": [
                        "582119a7-d4a0-58ff-9b1d-2af045e11a7f"
                    ]
                },
                "D-41a": {
                    "questionId": "D-41a",
                    "chosenOptionIds": [
                        "cb89e9b0-6872-5961-bc91-049308b5860f"
                    ]
                },
                "D-42a": {
                    "questionId": "D-42a",
                    "chosenOptionIds": [
                        "926931de-e675-5117-9e61-78dd541393be"
                    ]
                },
                "D-43a": {
                    "questionId": "D-43a",
                    "chosenOptionIds": [
                        "dc3faaa6-4a47-5998-83e3-4bbff8838194"
                    ]
                },
                "D-43bt": {
                    "questionId": "D-43bt",
                    "chosenOptionIds": [
                        "644dd944-5a2a-533b-9ef0-fadb5054c422"
                    ]
                },
                "D-44.5a": {
                    "questionId": "D-44.5a",
                    "chosenOptionIds": [
                        "bcf5fb9f-f4b9-5f0c-a964-69a4e1a73334"
                    ]
                },
                "D-44a": {
                    "questionId": "D-44a",
                    "chosenOptionIds": [
                        "7d6584ca-1b93-543d-9ee4-400ca40134be"
                    ]
                },
                "D-45a": {
                    "questionId": "D-45a",
                    "freeValue": 2
                },
                "D-46a": {
                    "questionId": "D-46a",
                    "chosenOptionIds": [
                        "1fd426df-808a-5c68-9b6e-23ff2f128b7d",
                        "d9665b1f-cdd2-5979-895e-27bca6487533",
                        "7492eab7-c625-52d0-a2e3-4bf3332decc0",
                        "6e49f368-62af-5abb-9034-a58bd534944f",
                        "f0b15a45-ae49-59d2-97a7-33f866b0e629",
                        "b3e43bd8-fbe3-5cda-b891-6f11e43993d0",
                        "4c178978-f79a-58fc-8e73-3a98614c4b6c"
                    ]
                },
                "D-49.1a": {
                    "questionId": "D-49.1a",
                    "chosenOptionIds": [
                        "7108af97-c956-5da8-8dfa-518e346c197c"
                    ]
                },
                "D-49.2a": {
                    "questionId": "D-49.2a",
                    "freeValue": 7
                },
                "D-49a": {
                    "questionId": "D-49a",
                    "chosenOptionIds": [
                        "16dc6af1-32a9-59c5-b66c-57f6becea1e0"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "ba6dc2ba-3c20-57b6-a276-32c863109ff6"
                    ]
                },
                "D-50a": {
                    "questionId": "D-50a",
                    "chosenOptionIds": [
                        "d14da42d-9612-5827-9583-b2bf528130de"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "b52073e3-c813-51f5-88ba-9cd3bddd7023"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "08a0f83f-2f37-5904-9364-4e40deb90e98"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "5d271ac0-beca-56cc-9d0b-adafcd67da53"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "4c78ae3e-7de4-545f-aca1-03fa41fc0c23"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "1200dadf-a0de-5a54-b0b8-fc640f7bc8d1"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "44fd9eb3-aee2-5af0-b83c-dbbbf9514022"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "83e6efd5-64a2-5f0e-a973-9278eae90cfb"
                    ]
                },
                "BP10": {
                    "questionId": "BP10",
                    "chosenOptionIds": [
                        "7ad54f0a-87a6-5e82-bfc3-44b540230082"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "182e121d-415c-5f42-a76c-a996f5f38b3f"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "1c4ed441-5919-569e-98ba-b49be1bc3957"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "a3a547f3-b48b-567f-a32a-6a3f5f4bacd7"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "b24df75b-6bb9-5f5f-99e3-c883ec3644ec"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 20
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "c7bee7a0-3037-5eb1-96a1-85b884d7e1dd"
                    ]
                },
                "BP3.01": {
                    "questionId": "BP3.01",
                    "freeValue": 4
                },
                "BP3.1": {
                    "questionId": "BP3.1",
                    "chosenOptionIds": [
                        "5bf1e313-2624-5b5f-9673-37ae9319ff0c"
                    ]
                },
                "BP3.2": {
                    "questionId": "BP3.2",
                    "chosenOptionIds": [
                        "c3319113-403a-5ecb-aa8a-071221d92c50"
                    ]
                },
                "BP3.3": {
                    "questionId": "BP3.3",
                    "freeValue": 2
                },
                "BP3.4": {
                    "questionId": "BP3.4",
                    "chosenOptionIds": [
                        "93412936-97c0-5128-ad67-3fa955db1300"
                    ]
                },
                "BP4": {
                    "questionId": "BP4",
                    "chosenOptionIds": [
                        "9765aba8-7d55-5928-b79d-36aa58646036"
                    ]
                },
                "BP4.5": {
                    "questionId": "BP4.5",
                    "chosenOptionIds": [
                        "66a3460c-65ce-5a4c-9774-2e303ae4961e"
                    ]
                },
                "BP6": {
                    "questionId": "BP6",
                    "chosenOptionIds": [
                        "0f2295c8-9598-5e68-a9a6-c87ca1f600af"
                    ]
                },
                "BP6.1": {
                    "questionId": "BP6.1",
                    "chosenOptionIds": [
                        "430b68c6-29cf-5a15-ab4c-89bd842383bf"
                    ]
                },
                "BP6.2": {
                    "questionId": "BP6.2",
                    "freeValue": 1
                },
                "BP6.3": {
                    "questionId": "BP6.3",
                    "chosenOptionIds": [
                        "df17fdbe-e756-5ece-ad3d-19cafeef31d1"
                    ]
                },
                "BP8": {
                    "questionId": "BP8",
                    "chosenOptionIds": [
                        "87e0081b-9455-5ced-9225-10c2f70b1734"
                    ]
                },
                "BP8.0": {
                    "questionId": "BP8.0",
                    "chosenOptionIds": [
                        "3568cdb1-2500-512b-aada-2547c5bfd835"
                    ]
                },
                "BP8.01": {
                    "questionId": "BP8.01",
                    "chosenOptionIds": [
                        "a5d71cc4-3c6f-5e37-a507-1dfa312124b8"
                    ]
                },
                "BP8.02": {
                    "questionId": "BP8.02",
                    "chosenOptionIds": [
                        "e88670a3-c6d8-5c96-914e-b9fc1818aeda"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "387b19c0-67d0-557e-9fba-ae065dcf3587"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "afb57366-c0b3-5f73-92eb-27bc133ad2ed"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "667ba57c-b1cd-5f9b-aa12-58e65a981910"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "fd30dc27-8e89-5d1a-9d95-8844603d4379"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "c211fabc-a62d-5440-8ddc-f57eb21d7679"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "cc7c09ae-1cb7-5b9a-9cd3-8d1012b90ee8"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "d680a8d9-e35f-563f-9d1d-e7c3136aff6d"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "82997889-d795-572b-a597-e484ef078b39"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "2555d898-6736-5804-951e-e3c438313029"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "de2ea429-4fcd-53b8-8d9f-ebb69a637a02"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "e232ba2d-36a9-524e-9fb2-8541b27bb569"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "4a7683c6-774e-5362-8060-740f61986c9b"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "cfb8fe0e-821a-554a-bb57-922f0ecc0f44"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "36502f0b-4563-5e03-80f2-a46dabeeae74"
                    ]
                },
                "BP99.2": {
                    "questionId": "BP99.2",
                    "chosenOptionIds": [
                        "f55f25af-1247-5c48-8385-341f7e4fea59"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "e81fe613-4923-55ed-bd6c-bd3f19e026f9"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM2": {
                    "questionId": "HM2",
                    "chosenOptionIds": [
                        "2b56aa7d-493f-5a18-aa9f-f97304f89130"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "98ea07a0-ae95-570f-8d11-a3e7c682e986"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "a43647cb-a0e8-5705-adb9-a2b68877dc77"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "56aac6d1-1fcc-5a72-bfda-2ae5d875df21"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "43c32319-c75f-5169-b0e5-713820721d4f"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "65bee7d4-bf7f-5a21-9d92-f16651b0d1aa"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "0170b4cf-0541-53cd-a9db-818d5067dc23"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "7b310da1-94e3-5d45-a6c3-3905eeb28ec6"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "0983ccf5-a426-53d0-adbb-1baf6832190c"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "834b04f2-af09-5337-8fd5-be4a70c99e75"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "fda00d52-c8be-5b69-bdbd-905c13dddd4e"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "0360ab4b-77d5-5fcd-8d60-b7a005c12ceb"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "1d07911e-929d-5719-aeea-a68f593d18e8"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: R047J04 aspect: simple_bd1 should be diagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "c61d70d4-b773-54dd-94d0-d858a47be0fb"
                    ]
                },
                "D-1.5b": {
                    "questionId": "D-1.5b",
                    "freeValue": 10
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "a16e8e14-524e-53df-b4ef-730a2c8e5f32"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "9b47900d-e5a7-5e58-830b-d9b3130a7990"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "a0e528ca-2fbb-5da1-9398-0758f79755aa"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 1825
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "7f1d6182-f8a7-5f4e-a0f8-2556b306ae36"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-1bl": {
                    "questionId": "D-1bl",
                    "chosenOptionIds": [
                        "5da400b0-5bff-5744-a664-4305892ae669"
                    ]
                },
                "D-2": {
                    "questionId": "D-2",
                    "freeValue": 45
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "c695fd1c-2cb7-5654-b9f7-b32ecb158229"
                    ]
                },
                "D-2bl": {
                    "questionId": "D-2bl",
                    "chosenOptionIds": [
                        "78c1434b-e585-5f1d-b291-a1728f789022"
                    ]
                },
                "D-2bt": {
                    "questionId": "D-2bt",
                    "chosenOptionIds": [
                        "315fa694-3bc0-5231-93f2-ceb1d9766403"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "14d310b2-5fb6-5f79-8bfa-e3524002e63d"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "db7514e2-d317-55fb-82d3-2e4a49c2494b"
                    ]
                },
                "D-3bl": {
                    "questionId": "D-3bl",
                    "freeValue": 6
                },
                "D-3bt": {
                    "questionId": "D-3bt",
                    "chosenOptionIds": [
                        "1a1865e2-c338-59ef-a2db-6e2cc7a17d77"
                    ]
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-42bt": {
                    "questionId": "D-42bt",
                    "freeValue": 5
                },
                "D-43bt": {
                    "questionId": "D-43bt",
                    "chosenOptionIds": [
                        "644dd944-5a2a-533b-9ef0-fadb5054c422"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b"
                    ]
                },
                "D-4bt": {
                    "questionId": "D-4bt",
                    "freeValue": 5
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "b52073e3-c813-51f5-88ba-9cd3bddd7023"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "adecf2d3-f302-50f8-a4a9-3ffac1789bc6"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "4c78ae3e-7de4-545f-aca1-03fa41fc0c23"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "13f8bac1-d0f5-539b-8596-1c55821bea46"
                    ]
                },
                "D-s10bt": {
                    "questionId": "D-s10bt",
                    "chosenOptionIds": [
                        "ce0e07f8-36b3-5424-a489-abd9de8a21a4"
                    ]
                },
                "D-s1bt": {
                    "questionId": "D-s1bt",
                    "chosenOptionIds": [
                        "c17482e4-818a-50cd-90fa-1246d037825e"
                    ]
                },
                "D-s2bt": {
                    "questionId": "D-s2bt",
                    "chosenOptionIds": [
                        "b75d06ef-eb6c-5e23-bf8c-9b566fa4d9dd"
                    ]
                },
                "D-s3bt": {
                    "questionId": "D-s3bt",
                    "chosenOptionIds": [
                        "ae77a2d7-a52c-5cdc-8c8d-93e294ade96d"
                    ]
                },
                "D-s4bt": {
                    "questionId": "D-s4bt",
                    "chosenOptionIds": [
                        "5a198500-fc4b-55a1-9521-3abe49eed06f"
                    ]
                },
                "D-s5bt": {
                    "questionId": "D-s5bt",
                    "chosenOptionIds": [
                        "d033bb4c-5d3c-519b-be31-1177042bffb4"
                    ]
                },
                "D-s6bt": {
                    "questionId": "D-s6bt",
                    "chosenOptionIds": [
                        "bb7e3f7e-73b0-56b0-b3d3-e336498509e8"
                    ]
                },
                "D-s7bt": {
                    "questionId": "D-s7bt",
                    "chosenOptionIds": [
                        "1df67815-0b9f-56ef-bd48-96ed977db141"
                    ]
                },
                "D-s8bt": {
                    "questionId": "D-s8bt",
                    "chosenOptionIds": [
                        "6f5067cf-ef4d-59d9-afa1-b3dfc4ac861f"
                    ]
                },
                "D-s9bt": {
                    "questionId": "D-s9bt",
                    "chosenOptionIds": [
                        "da503bbd-e567-576e-a21f-e0fad60dccdd"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "8c599171-d554-5547-9f54-2066c1a711dd"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "ccbdcbec-96d0-5362-977b-784289f5cfc0"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "db7aa4b7-99a5-5475-a4e8-a744f3ef5a4a"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "e9eb902a-d327-5ec5-938c-dc317ca59b13"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "a3a547f3-b48b-567f-a32a-6a3f5f4bacd7"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "f2228920-3fec-5097-a63d-990c25d50c6c"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP105": {
                    "questionId": "BP105",
                    "chosenOptionIds": [
                        "64aa1680-6b5b-5a5b-bb92-75995d27ddcd"
                    ]
                },
                "BP12": {
                    "questionId": "BP12",
                    "chosenOptionIds": [
                        "89a3d3f3-747b-59f8-9a32-311618df7607"
                    ]
                },
                "BP12.5": {
                    "questionId": "BP12.5",
                    "chosenOptionIds": [
                        "b7cc3eef-f36c-5fb4-9ebb-9ce64c834159"
                    ]
                },
                "BP13": {
                    "questionId": "BP13",
                    "chosenOptionIds": [
                        "8cd7d7ed-1de8-5155-81b5-85202bce3abc"
                    ]
                },
                "BP13.1": {
                    "questionId": "BP13.1",
                    "chosenOptionIds": [
                        "49f1206d-ce81-58fc-af76-30be0fffd7f5"
                    ]
                },
                "BP13.2": {
                    "questionId": "BP13.2",
                    "freeValue": 6
                },
                "BP13.3": {
                    "questionId": "BP13.3",
                    "chosenOptionIds": [
                        "d1bcedf2-2527-57c5-8faf-806dcacf0fdd"
                    ]
                },
                "BP16": {
                    "questionId": "BP16",
                    "chosenOptionIds": [
                        "fabceeea-8fea-5444-8b67-8f189f366c27"
                    ]
                },
                "BP16.0": {
                    "questionId": "BP16.0",
                    "chosenOptionIds": [
                        "4b26caa5-9124-51b2-ab08-2f4e977cee1f"
                    ]
                },
                "BP16.01": {
                    "questionId": "BP16.01",
                    "chosenOptionIds": [
                        "5d4d2592-d29a-513f-8e9c-461e29598f49"
                    ]
                },
                "BP16.02": {
                    "questionId": "BP16.02",
                    "chosenOptionIds": [
                        "16a695c9-3ac1-5d34-b799-44602d85b556"
                    ]
                },
                "BP17": {
                    "questionId": "BP17",
                    "chosenOptionIds": [
                        "9fa962fb-6bd5-50fc-9063-459e175cbcc3"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 25
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "c7bee7a0-3037-5eb1-96a1-85b884d7e1dd"
                    ]
                },
                "BP3.01": {
                    "questionId": "BP3.01",
                    "freeValue": 1
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "960a08ff-7af7-56da-afda-5415a1654c85"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "5f694075-3a97-5f8b-8701-2710a43127a9"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "28c75e9e-94ef-5c50-8645-f4ff93ccf2ec"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "fd30dc27-8e89-5d1a-9d95-8844603d4379"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "e22cf233-c174-52fb-af55-97bf9c46e971"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "46c90446-3ae9-53f5-9b57-ad19d66b5be6"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "ef5a7b22-b2b2-5a5b-8a80-ce5c251033fd"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "c88e3fb5-913a-5c5c-a42b-603f7bb60a40"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "201c7ee3-56dc-51e9-ad55-69951192ab16"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "de2ea429-4fcd-53b8-8d9f-ebb69a637a02"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "9b27e3ff-f8ce-54c5-9220-0479ae53e889"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "2723ed09-37f6-522c-92b1-e518d4fe9315"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "cfb8fe0e-821a-554a-bb57-922f0ecc0f44"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "5c06d516-e816-58d6-997e-f8fa91131ac7"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "e81fe613-4923-55ed-bd6c-bd3f19e026f9"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM18": {
                    "questionId": "HM18",
                    "chosenOptionIds": [
                        "e91203fa-5410-59f8-a2a7-cfbd0257e576"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "6b99de8b-634a-5eb0-bc65-bdf30eb9d433"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "9d212885-9b07-5623-8234-226adff08f6d"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "5574c021-c3c4-513a-a4b6-5ee416e8aa9e"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "3fd957d1-0cbb-5397-9812-353be5c2184e"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "898957c6-8cd1-53e5-98f8-c16789aba613"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "0170b4cf-0541-53cd-a9db-818d5067dc23"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "2be66549-5767-51b4-82ed-deeab0723f8d"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "0983ccf5-a426-53d0-adbb-1baf6832190c"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "7e32475a-3fcc-51ac-b414-ab46578efdfd"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "fa32b497-4a4b-5076-a391-b6dffa8e1851"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "e95276b0-ee81-580b-b532-b0215f04abd5"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "4b8e4843-d260-59e5-8468-1303b1c3a638"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: O0KG5E1 aspect: simple_bd1 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "c61d70d4-b773-54dd-94d0-d858a47be0fb"
                    ]
                },
                "D-1.5b": {
                    "questionId": "D-1.5b",
                    "freeValue": 4
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "45f0ce85-9e19-53da-bc0d-2af8b383318a"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "eb68a88d-b49b-5cde-a9c6-5e4c78e27d19"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "826538fc-8a97-55e4-8013-fe6a93f8e451"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 7
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "7f1d6182-f8a7-5f4e-a0f8-2556b306ae36"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-1bl": {
                    "questionId": "D-1bl",
                    "chosenOptionIds": [
                        "87ef50c9-ba5e-513e-8889-09d8395d4b9f"
                    ]
                },
                "D-2": {
                    "questionId": "D-2",
                    "freeValue": 21
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "c695fd1c-2cb7-5654-b9f7-b32ecb158229"
                    ]
                },
                "D-2bl": {
                    "questionId": "D-2bl",
                    "chosenOptionIds": [
                        "2a8c96e2-cb5b-596a-99b6-a4885253776c"
                    ]
                },
                "D-2bt": {
                    "questionId": "D-2bt",
                    "chosenOptionIds": [
                        "d585762b-2491-592f-9340-3053d9151b68"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "fd9c4394-6664-5144-8797-00d27a4dda01"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "77c6088d-2d8f-5c3b-89c9-79cb0d317226"
                    ]
                },
                "D-3bl": {
                    "questionId": "D-3bl",
                    "freeValue": 14
                },
                "D-3bt": {
                    "questionId": "D-3bt",
                    "chosenOptionIds": [
                        "b9abc28d-6e22-5b67-bc5e-27431e123974"
                    ]
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-42bt": {
                    "questionId": "D-42bt",
                    "freeValue": 0
                },
                "D-43bt": {
                    "questionId": "D-43bt",
                    "chosenOptionIds": [
                        "644dd944-5a2a-533b-9ef0-fadb5054c422"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "9d1b858c-7064-5a84-80d6-786c18e603b6",
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b",
                        "1d4beed1-78e5-531c-a255-643fac260dee"
                    ]
                },
                "D-4bt": {
                    "questionId": "D-4bt",
                    "freeValue": 14
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "b52073e3-c813-51f5-88ba-9cd3bddd7023"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "9506449e-b2aa-5498-b896-901dd248b1b3"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "772bee29-c3a5-52e1-b785-a77d506ae4fb"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "3b216cf8-72e7-558c-bdd3-cc23a1865674"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "f8e2518c-72cf-563b-b322-a03406c8ebac"
                    ]
                },
                "D-s10bt": {
                    "questionId": "D-s10bt",
                    "chosenOptionIds": [
                        "3deea42c-5d93-54cd-ba0f-4242654c3747"
                    ]
                },
                "D-s1bt": {
                    "questionId": "D-s1bt",
                    "chosenOptionIds": [
                        "c17482e4-818a-50cd-90fa-1246d037825e"
                    ]
                },
                "D-s2bt": {
                    "questionId": "D-s2bt",
                    "chosenOptionIds": [
                        "e074592a-4667-5b65-b713-b8892451b433"
                    ]
                },
                "D-s3bt": {
                    "questionId": "D-s3bt",
                    "chosenOptionIds": [
                        "19b82b7e-f108-59dd-b277-dae38a3a8d8d"
                    ]
                },
                "D-s4bt": {
                    "questionId": "D-s4bt",
                    "chosenOptionIds": [
                        "fd9851a0-7ce7-55a8-b257-5617537d0013",
                        "5a198500-fc4b-55a1-9521-3abe49eed06f",
                        "a4021497-af94-56a4-8075-392653262e2e"
                    ]
                },
                "D-s5bt": {
                    "questionId": "D-s5bt",
                    "chosenOptionIds": [
                        "d033bb4c-5d3c-519b-be31-1177042bffb4"
                    ]
                },
                "D-s6bt": {
                    "questionId": "D-s6bt",
                    "chosenOptionIds": [
                        "f7aa2ac0-a087-5371-8266-7601901cd1f9"
                    ]
                },
                "D-s7bt": {
                    "questionId": "D-s7bt",
                    "chosenOptionIds": [
                        "b0305c3f-5a4a-5a36-9793-cec574da7f4e"
                    ]
                },
                "D-s8bt": {
                    "questionId": "D-s8bt",
                    "chosenOptionIds": [
                        "b089fe08-53f3-59bb-a2eb-55bfc744e7ca"
                    ]
                },
                "D-s9bt": {
                    "questionId": "D-s9bt",
                    "chosenOptionIds": [
                        "8d2e7af7-07c2-5459-8865-609f820de278"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "60fe0bb4-d770-56f2-b05a-864625a48e4d"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "ccbdcbec-96d0-5362-977b-784289f5cfc0"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "4a0f1c4d-38cf-5de4-99e3-0ee818f9709c"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "6a5aa899-7b89-5661-a554-71b6058ad4a4"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "d3224e16-1b0c-5266-9e14-ac72d2b2e988"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "b24df75b-6bb9-5f5f-99e3-c883ec3644ec"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP12": {
                    "questionId": "BP12",
                    "chosenOptionIds": [
                        "22f9a995-3f9a-5fe9-99bb-71b7f1f96621"
                    ]
                },
                "BP15": {
                    "questionId": "BP15",
                    "chosenOptionIds": [
                        "1286eda2-5e31-52dc-95c5-78ded5b43b2a"
                    ]
                },
                "BP15.1": {
                    "questionId": "BP15.1",
                    "chosenOptionIds": [
                        "0fe6799b-72e6-5cd8-a921-d03ac42f803d"
                    ]
                },
                "BP15.2": {
                    "questionId": "BP15.2",
                    "freeValue": 0.041666666666666664
                },
                "BP15.3": {
                    "questionId": "BP15.3",
                    "chosenOptionIds": [
                        "cfa519ff-08a9-5fa4-b12d-87b7a597cb13"
                    ]
                },
                "BP16": {
                    "questionId": "BP16",
                    "chosenOptionIds": [
                        "fabceeea-8fea-5444-8b67-8f189f366c27"
                    ]
                },
                "BP16.0": {
                    "questionId": "BP16.0",
                    "chosenOptionIds": [
                        "4b26caa5-9124-51b2-ab08-2f4e977cee1f"
                    ]
                },
                "BP16.01": {
                    "questionId": "BP16.01",
                    "chosenOptionIds": [
                        "f81655d7-78e5-59b1-a7eb-90a2ec7a107d"
                    ]
                },
                "BP16.02": {
                    "questionId": "BP16.02",
                    "chosenOptionIds": [
                        "16a695c9-3ac1-5d34-b799-44602d85b556"
                    ]
                },
                "BP19": {
                    "questionId": "BP19",
                    "chosenOptionIds": [
                        "601bc370-8a4c-5b1f-a7f6-6c174f421733"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 31
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "f03de28d-8f71-5af4-be2f-45f4e6092a3d"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "387b19c0-67d0-557e-9fba-ae065dcf3587"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "afb57366-c0b3-5f73-92eb-27bc133ad2ed"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "667ba57c-b1cd-5f9b-aa12-58e65a981910"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "fd30dc27-8e89-5d1a-9d95-8844603d4379"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "e22cf233-c174-52fb-af55-97bf9c46e971"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "46c90446-3ae9-53f5-9b57-ad19d66b5be6"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "d680a8d9-e35f-563f-9d1d-e7c3136aff6d"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "c88e3fb5-913a-5c5c-a42b-603f7bb60a40"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "732c51f9-a0b6-5951-bd7d-eaaec24316ba"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "6928c696-e6d7-5a14-a004-15a497ac6562"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "17b4da6f-7135-523c-b90b-2fe3b66785ff"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "3cf77140-2757-58eb-b4ea-f2a90cd766e7"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "5c06d516-e816-58d6-997e-f8fa91131ac7"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "c0cc09c3-1335-5eb4-8801-b3cdaf74f6fa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM20": {
                    "questionId": "HM20",
                    "chosenOptionIds": [
                        "4ff0f88c-e951-5e87-a941-815105819115"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "98ea07a0-ae95-570f-8d11-a3e7c682e986"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "696fdd9d-5ced-504b-a933-bb3e575b0099"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c41974b6-d394-569c-8f45-49f79fcac5c6"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "43c32319-c75f-5169-b0e5-713820721d4f"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "b2cb304f-f035-53fc-8763-e2f91b031f9b"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "0170b4cf-0541-53cd-a9db-818d5067dc23"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "2be66549-5767-51b4-82ed-deeab0723f8d"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "2a17c2fe-2592-58b1-98e9-3dc4168fe3e3"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "834b04f2-af09-5337-8fd5-be4a70c99e75"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "86277249-cf50-5f91-a27f-f3a69344e796"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "8823c74f-ff7c-52d8-9c73-fd3b4180f48a"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "1d07911e-929d-5719-aeea-a68f593d18e8"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: Z3R6O0O aspect: simple_bd1 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "0ec0e224-a5cf-5ece-b3ab-37061a600ace"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "45f0ce85-9e19-53da-bc0d-2af8b383318a"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "6ede390f-0e43-50e4-82e9-1203db9d8343"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "b74511c7-9f97-5c15-8bf5-6ec4357b4814"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 730
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "db360e93-3616-5d89-be36-5ccee4aacf1c"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "de11fb6b-7e7d-52b1-9d09-2d6debc53314"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5c30c60e-d53e-5cc3-b4a9-fdad9bec4cb7"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "188a7831-38c4-55c4-880a-1e4db4e9bab1"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "3e093dd3-857e-5004-a4fb-961fbfa59ece"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "772bee29-c3a5-52e1-b785-a77d506ae4fb"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "67316d94-1cf0-5bd0-a4db-f57bc70684ba"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "f8e2518c-72cf-563b-b322-a03406c8ebac"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "60fe0bb4-d770-56f2-b05a-864625a48e4d"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "83e6efd5-64a2-5f0e-a973-9278eae90cfb"
                    ]
                },
                "BP10": {
                    "questionId": "BP10",
                    "chosenOptionIds": [
                        "7ad54f0a-87a6-5e82-bfc3-44b540230082"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "4a0f1c4d-38cf-5de4-99e3-0ee818f9709c"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "d8c1997f-d722-536c-ad9d-296b5e10ed04"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "7230003d-a290-5a65-ae6b-b7d564b5db38"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "f2228920-3fec-5097-a63d-990c25d50c6c"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "de4464c8-78fe-5876-aa00-25826ce5ec25"
                    ]
                },
                "BP105": {
                    "questionId": "BP105",
                    "chosenOptionIds": [
                        "4e9d21b3-29c7-5526-ada9-67b61957994e"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 49
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "c7bee7a0-3037-5eb1-96a1-85b884d7e1dd"
                    ]
                },
                "BP3.01": {
                    "questionId": "BP3.01",
                    "freeValue": 99
                },
                "BP3.1": {
                    "questionId": "BP3.1",
                    "chosenOptionIds": [
                        "5bf1e313-2624-5b5f-9673-37ae9319ff0c"
                    ]
                },
                "BP3.2": {
                    "questionId": "BP3.2",
                    "chosenOptionIds": [
                        "43011291-20a8-59ce-8bac-df882587ff5c"
                    ]
                },
                "BP3.3": {
                    "questionId": "BP3.3",
                    "freeValue": 0.041666666666666664
                },
                "BP3.4": {
                    "questionId": "BP3.4",
                    "chosenOptionIds": [
                        "93412936-97c0-5128-ad67-3fa955db1300"
                    ]
                },
                "BP4": {
                    "questionId": "BP4",
                    "chosenOptionIds": [
                        "9765aba8-7d55-5928-b79d-36aa58646036"
                    ]
                },
                "BP4.5": {
                    "questionId": "BP4.5",
                    "chosenOptionIds": [
                        "1be4b3b1-3a88-5544-af54-ebdfee1ce891"
                    ]
                },
                "BP6": {
                    "questionId": "BP6",
                    "chosenOptionIds": [
                        "742a22d6-a570-5e3a-8e72-c001f1e9f98b"
                    ]
                },
                "BP6.1": {
                    "questionId": "BP6.1",
                    "chosenOptionIds": [
                        "77b4d620-93e4-5bc5-baad-4995d168f392"
                    ]
                },
                "BP6.2": {
                    "questionId": "BP6.2",
                    "freeValue": 0.041666666666666664
                },
                "BP6.3": {
                    "questionId": "BP6.3",
                    "chosenOptionIds": [
                        "e061c578-086e-5990-bba6-279cb273f3a5"
                    ]
                },
                "BP8": {
                    "questionId": "BP8",
                    "chosenOptionIds": [
                        "87e0081b-9455-5ced-9225-10c2f70b1734"
                    ]
                },
                "BP8.0": {
                    "questionId": "BP8.0",
                    "chosenOptionIds": [
                        "3568cdb1-2500-512b-aada-2547c5bfd835"
                    ]
                },
                "BP8.01": {
                    "questionId": "BP8.01",
                    "chosenOptionIds": [
                        "5020bfdd-a280-56cf-b7f5-3b0b7e667165"
                    ]
                },
                "BP8.02": {
                    "questionId": "BP8.02",
                    "chosenOptionIds": [
                        "1ff60197-2010-588f-bf6a-9be48f2b3359"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "387b19c0-67d0-557e-9fba-ae065dcf3587"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "afb57366-c0b3-5f73-92eb-27bc133ad2ed"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "667ba57c-b1cd-5f9b-aa12-58e65a981910"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "a2056280-4b54-5258-8bb4-407b521fff4b"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "e22cf233-c174-52fb-af55-97bf9c46e971"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "1f57d1a0-3682-5d7d-b66b-8179ec5c3988"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 1
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "ef5a7b22-b2b2-5a5b-8a80-ce5c251033fd"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "f35fe383-d2aa-51c4-9fde-961b1de6d52b"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "201c7ee3-56dc-51e9-ad55-69951192ab16"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "6ade3a8d-8516-5a88-baa0-f996f32730d2"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "2723ed09-37f6-522c-92b1-e518d4fe9315"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "58777a2e-4b97-5413-b340-efe722e45936"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "63509127-aadf-5fe2-8e1f-19d0d846d6ce"
                    ]
                },
                "BP99.1": {
                    "questionId": "BP99.1",
                    "chosenOptionIds": [
                        "34d19f12-207f-56ce-bde8-8d616f6e8b26"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "a020ed85-ec9d-5778-8006-41f634cab09d"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM2": {
                    "questionId": "HM2",
                    "chosenOptionIds": [
                        "a046d610-d27a-59c1-b130-38312005cf1c"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "c3ee8981-dec3-5b3c-80ca-c1023f983a6d"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "a43647cb-a0e8-5705-adb9-a2b68877dc77"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "3ab9515b-73f0-5fcf-a4d0-aebbc24c2721"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "c8c8c192-aea3-5585-b11f-9d13faf4367d"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "d2c670c4-b05b-597a-a0af-5342230535a0"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "0170b4cf-0541-53cd-a9db-818d5067dc23"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "7b310da1-94e3-5d45-a6c3-3905eeb28ec6"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "2a17c2fe-2592-58b1-98e9-3dc4168fe3e3"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "a56fcb96-f868-5d08-a358-9b403eb3cd16"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "fa32b497-4a4b-5076-a391-b6dffa8e1851"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "bde4e5f3-19e4-571f-84a2-5b9d188d74ef"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "7d6921a2-7e63-5f12-9c75-dff8b592fabc"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: ME87V3W aspect: simple_bd1 should be diagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "3bd18f66-6f1e-5ea4-ae24-2990ed197bef"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "eb68a88d-b49b-5cde-a9c6-5e4c78e27d19"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "826538fc-8a97-55e4-8013-fe6a93f8e451"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 1825
                },
                "D-15a": {
                    "questionId": "D-15a",
                    "chosenOptionIds": [
                        "7b763ff0-7703-54f7-8eae-aa83417ca133"
                    ]
                },
                "D-16.5a": {
                    "questionId": "D-16.5a",
                    "chosenOptionIds": [
                        "5f88b934-32eb-5ed5-8896-a818cbe4b033"
                    ]
                },
                "D-17a": {
                    "questionId": "D-17a",
                    "chosenOptionIds": [
                        "33b013af-d830-516a-9632-69dcc7a0f736"
                    ]
                },
                "D-18a": {
                    "questionId": "D-18a",
                    "chosenOptionIds": [
                        "615b852b-6f81-54a9-bc4a-a8843e82c230"
                    ]
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "80c993c8-1a94-5684-a8f9-d8f5072c8e9c"
                    ]
                },
                "D-1bl": {
                    "questionId": "D-1bl",
                    "chosenOptionIds": [
                        "87ef50c9-ba5e-513e-8889-09d8395d4b9f"
                    ]
                },
                "D-2": {
                    "questionId": "D-2",
                    "freeValue": 19
                },
                "D-20a": {
                    "questionId": "D-20a",
                    "chosenOptionIds": [
                        "b28ed556-a386-5556-a96f-ae7708ded1ab"
                    ]
                },
                "D-21a": {
                    "questionId": "D-21a",
                    "chosenOptionIds": [
                        "dc2b002f-2d96-504d-bde9-66faceececb6"
                    ]
                },
                "D-23a": {
                    "questionId": "D-23a",
                    "chosenOptionIds": [
                        "44fe96b8-192b-5b6b-88fa-9c2f7ba73a6d"
                    ]
                },
                "D-26a": {
                    "questionId": "D-26a",
                    "chosenOptionIds": [
                        "220827bb-16f1-5e00-9549-b98382b6e2ac"
                    ]
                },
                "D-27a": {
                    "questionId": "D-27a",
                    "chosenOptionIds": [
                        "13a1b08d-9817-5c2f-96cb-f8d03b80a37d"
                    ]
                },
                "D-28a": {
                    "questionId": "D-28a",
                    "chosenOptionIds": [
                        "8de8c31e-96ef-5e7d-8024-f6a72d07dd28"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "cc243947-5067-5a98-ade6-a4e10d0b42cf"
                    ]
                },
                "D-2bl": {
                    "questionId": "D-2bl",
                    "chosenOptionIds": [
                        "2a8c96e2-cb5b-596a-99b6-a4885253776c"
                    ]
                },
                "D-2bs": {
                    "questionId": "D-2bs",
                    "chosenOptionIds": [
                        "d9fc40d3-aa51-587a-a54d-305ad26f67ae"
                    ]
                },
                "D-2bt": {
                    "questionId": "D-2bt",
                    "chosenOptionIds": [
                        "90222879-e5d9-50a0-bfbe-a5cbadbff017"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "fd9c4394-6664-5144-8797-00d27a4dda01"
                    ]
                },
                "D-30a": {
                    "questionId": "D-30a",
                    "chosenOptionIds": [
                        "5b3f3d57-9d8a-54ab-a6ab-b02b57bc262e"
                    ]
                },
                "D-31a": {
                    "questionId": "D-31a",
                    "chosenOptionIds": [
                        "c78e0def-d73e-5c4e-8800-7c6f92d0a1ec"
                    ]
                },
                "D-32.1a": {
                    "questionId": "D-32.1a",
                    "chosenOptionIds": [
                        "da39b8b6-3178-5cf4-b68e-92b08cbf087e"
                    ]
                },
                "D-32a": {
                    "questionId": "D-32a",
                    "chosenOptionIds": [
                        "f992ce3a-fffd-5f38-aee4-24930ded9288"
                    ]
                },
                "D-33a": {
                    "questionId": "D-33a",
                    "chosenOptionIds": [
                        "a73a7546-0627-5437-b9f9-5664558c7d54"
                    ]
                },
                "D-34a": {
                    "questionId": "D-34a",
                    "chosenOptionIds": [
                        "e919c54f-d9a4-5a15-9ae7-8b6b52607a22"
                    ]
                },
                "D-35a": {
                    "questionId": "D-35a",
                    "chosenOptionIds": [
                        "bcace469-f380-599d-a33a-26db6d0053ad"
                    ]
                },
                "D-36a": {
                    "questionId": "D-36a",
                    "chosenOptionIds": [
                        "185038dd-ce55-53b2-ba09-0bd163af90b3"
                    ]
                },
                "D-37a": {
                    "questionId": "D-37a",
                    "chosenOptionIds": [
                        "c8ebfb14-b9aa-5da4-8de3-ab8b83254249"
                    ]
                },
                "D-38a": {
                    "questionId": "D-38a",
                    "chosenOptionIds": [
                        "39b328fc-f944-51dc-ae4d-9aab44697f32"
                    ]
                },
                "D-39a": {
                    "questionId": "D-39a",
                    "chosenOptionIds": [
                        "ae31125f-c3db-5082-ae70-4329a2508744"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "db7514e2-d317-55fb-82d3-2e4a49c2494b"
                    ]
                },
                "D-3bl": {
                    "questionId": "D-3bl",
                    "freeValue": 28
                },
                "D-3bs": {
                    "questionId": "D-3bs",
                    "chosenOptionIds": [
                        "664c5f59-06d3-53a2-81e0-eaf62108625d"
                    ]
                },
                "D-3bt": {
                    "questionId": "D-3bt",
                    "chosenOptionIds": [
                        "b9abc28d-6e22-5b67-bc5e-27431e123974"
                    ]
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-40a": {
                    "questionId": "D-40a",
                    "chosenOptionIds": [
                        "582119a7-d4a0-58ff-9b1d-2af045e11a7f"
                    ]
                },
                "D-41a": {
                    "questionId": "D-41a",
                    "chosenOptionIds": [
                        "cb89e9b0-6872-5961-bc91-049308b5860f"
                    ]
                },
                "D-42a": {
                    "questionId": "D-42a",
                    "chosenOptionIds": [
                        "7099f8a9-4650-5da2-a627-bcd62cd99375"
                    ]
                },
                "D-42bt": {
                    "questionId": "D-42bt",
                    "freeValue": 2
                },
                "D-43a": {
                    "questionId": "D-43a",
                    "chosenOptionIds": [
                        "cd0abe72-b3e8-5c78-8330-7ee4a1657536"
                    ]
                },
                "D-43bt": {
                    "questionId": "D-43bt",
                    "chosenOptionIds": [
                        "90e60395-fc3d-56cb-b6f7-2e24fba02803"
                    ]
                },
                "D-44.5a": {
                    "questionId": "D-44.5a",
                    "chosenOptionIds": [
                        "bcf5fb9f-f4b9-5f0c-a964-69a4e1a73334"
                    ]
                },
                "D-44a": {
                    "questionId": "D-44a",
                    "chosenOptionIds": [
                        "57c0e55d-5ee4-5237-9c5d-e317b90c5dbd"
                    ]
                },
                "D-45a": {
                    "questionId": "D-45a",
                    "freeValue": 15
                },
                "D-46a": {
                    "questionId": "D-46a",
                    "chosenOptionIds": [
                        "1fd426df-808a-5c68-9b6e-23ff2f128b7d",
                        "7492eab7-c625-52d0-a2e3-4bf3332decc0",
                        "4c178978-f79a-58fc-8e73-3a98614c4b6c",
                        "6e49f368-62af-5abb-9034-a58bd534944f"
                    ]
                },
                "D-49.1a": {
                    "questionId": "D-49.1a",
                    "chosenOptionIds": [
                        "7108af97-c956-5da8-8dfa-518e346c197c"
                    ]
                },
                "D-49.2a": {
                    "questionId": "D-49.2a",
                    "freeValue": 10
                },
                "D-49a": {
                    "questionId": "D-49a",
                    "chosenOptionIds": [
                        "16dc6af1-32a9-59c5-b66c-57f6becea1e0"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "a449e4ae-663b-50fb-9b62-1708b0a32669"
                    ]
                },
                "D-4bs": {
                    "questionId": "D-4bs",
                    "freeValue": 14
                },
                "D-4bt": {
                    "questionId": "D-4bt",
                    "freeValue": 0.29166666666666663
                },
                "D-50a": {
                    "questionId": "D-50a",
                    "chosenOptionIds": [
                        "b591ccff-25d0-5b4f-8091-4e8f8438987b"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "b52073e3-c813-51f5-88ba-9cd3bddd7023"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "772bee29-c3a5-52e1-b785-a77d506ae4fb"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "33a5fff0-9587-521f-bc53-0dacc36a0bb2"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "1200dadf-a0de-5a54-b0b8-fc640f7bc8d1"
                    ]
                },
                "D-s10bt": {
                    "questionId": "D-s10bt",
                    "chosenOptionIds": [
                        "da993bd6-c107-53a4-9f6d-09f5a79aff65"
                    ]
                },
                "D-s11bt": {
                    "questionId": "D-s11bt",
                    "chosenOptionIds": [
                        "dc476220-9479-5def-8a97-20e6152d44af",
                        "daf8b3c7-fb19-5a3f-ba9a-784adda7414f",
                        "6994b88e-cfa5-5986-b9e8-625d17d1147c",
                        "bae5acc8-8664-54a2-ba97-c36244be4c44",
                        "64af3cae-713a-51e1-99c1-09e2fdaa755d",
                        "9c3fa6af-8b7b-5993-a587-641683d934d5",
                        "16e1a0b8-28c5-5bcd-931d-335c858c3f88"
                    ]
                },
                "D-s1bt": {
                    "questionId": "D-s1bt",
                    "chosenOptionIds": [
                        "c17482e4-818a-50cd-90fa-1246d037825e"
                    ]
                },
                "D-s2bt": {
                    "questionId": "D-s2bt",
                    "chosenOptionIds": [
                        "8783a6d5-cd94-5982-8fd6-66ae46e1c6df"
                    ]
                },
                "D-s3bt": {
                    "questionId": "D-s3bt",
                    "chosenOptionIds": [
                        "19b82b7e-f108-59dd-b277-dae38a3a8d8d"
                    ]
                },
                "D-s4bt": {
                    "questionId": "D-s4bt",
                    "chosenOptionIds": [
                        "6dd2621e-8242-58f6-8c6c-77df4a9eef53"
                    ]
                },
                "D-s5bt": {
                    "questionId": "D-s5bt",
                    "chosenOptionIds": [
                        "8260d432-3a96-519e-b824-d24940e4aa65"
                    ]
                },
                "D-s6bt": {
                    "questionId": "D-s6bt",
                    "chosenOptionIds": [
                        "f7aa2ac0-a087-5371-8266-7601901cd1f9"
                    ]
                },
                "D-s7bt": {
                    "questionId": "D-s7bt",
                    "chosenOptionIds": [
                        "1df67815-0b9f-56ef-bd48-96ed977db141"
                    ]
                },
                "D-s8bt": {
                    "questionId": "D-s8bt",
                    "chosenOptionIds": [
                        "6f5067cf-ef4d-59d9-afa1-b3dfc4ac861f"
                    ]
                },
                "D-s9bt": {
                    "questionId": "D-s9bt",
                    "chosenOptionIds": [
                        "da503bbd-e567-576e-a21f-e0fad60dccdd"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "afcb6534-a0a0-5fe6-9729-1269be20cdf3",
                        "44fd9eb3-aee2-5af0-b83c-dbbbf9514022"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "ccbdcbec-96d0-5362-977b-784289f5cfc0"
                    ]
                },
                "BP10": {
                    "questionId": "BP10",
                    "chosenOptionIds": [
                        "7ad54f0a-87a6-5e82-bfc3-44b540230082"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "a20d7b84-720e-53c5-a450-45cbde434507"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "d8c1997f-d722-536c-ad9d-296b5e10ed04"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "7230003d-a290-5a65-ae6b-b7d564b5db38"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "f2228920-3fec-5097-a63d-990c25d50c6c"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP105": {
                    "questionId": "BP105",
                    "chosenOptionIds": [
                        "978f2ff4-94d9-5f7e-9126-e8657be0c7ca"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 22
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "c7bee7a0-3037-5eb1-96a1-85b884d7e1dd"
                    ]
                },
                "BP3.01": {
                    "questionId": "BP3.01",
                    "freeValue": 20
                },
                "BP3.1": {
                    "questionId": "BP3.1",
                    "chosenOptionIds": [
                        "c31cf816-04e0-52f4-8da3-04437042c014"
                    ]
                },
                "BP3.2": {
                    "questionId": "BP3.2",
                    "chosenOptionIds": [
                        "ec333f8b-146a-5700-a15d-527032ceac3f"
                    ]
                },
                "BP3.3": {
                    "questionId": "BP3.3",
                    "freeValue": 20
                },
                "BP3.4": {
                    "questionId": "BP3.4",
                    "chosenOptionIds": [
                        "f304bf93-0c68-5da0-80ba-ccecc2d0fce1"
                    ]
                },
                "BP4": {
                    "questionId": "BP4",
                    "chosenOptionIds": [
                        "9765aba8-7d55-5928-b79d-36aa58646036"
                    ]
                },
                "BP4.5": {
                    "questionId": "BP4.5",
                    "chosenOptionIds": [
                        "66a3460c-65ce-5a4c-9774-2e303ae4961e"
                    ]
                },
                "BP6": {
                    "questionId": "BP6",
                    "chosenOptionIds": [
                        "33ae5abe-63ad-57d4-86a4-0dcd33576094"
                    ]
                },
                "BP6.1": {
                    "questionId": "BP6.1",
                    "chosenOptionIds": [
                        "a99773bd-2bff-523e-bc29-1f761307b3d4"
                    ]
                },
                "BP6.2": {
                    "questionId": "BP6.2",
                    "freeValue": 7
                },
                "BP6.3": {
                    "questionId": "BP6.3",
                    "chosenOptionIds": [
                        "e061c578-086e-5990-bba6-279cb273f3a5"
                    ]
                },
                "BP8": {
                    "questionId": "BP8",
                    "chosenOptionIds": [
                        "5d89a61f-a292-5fa7-b2ec-af892cf73338"
                    ]
                },
                "BP8.0": {
                    "questionId": "BP8.0",
                    "chosenOptionIds": [
                        "3568cdb1-2500-512b-aada-2547c5bfd835"
                    ]
                },
                "BP8.01": {
                    "questionId": "BP8.01",
                    "chosenOptionIds": [
                        "a5d71cc4-3c6f-5e37-a507-1dfa312124b8"
                    ]
                },
                "BP8.02": {
                    "questionId": "BP8.02",
                    "chosenOptionIds": [
                        "e88670a3-c6d8-5c96-914e-b9fc1818aeda"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "960a08ff-7af7-56da-afda-5415a1654c85"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "cb636d32-4f4e-51b3-a23f-2a11b8771b7e"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "0953f30c-801a-54f6-8238-b9dc42ab17f9"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "fd30dc27-8e89-5d1a-9d95-8844603d4379"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "c211fabc-a62d-5440-8ddc-f57eb21d7679"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "1f57d1a0-3682-5d7d-b66b-8179ec5c3988"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 10
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "63f0b17a-9832-5f0f-8630-e74f1a4fdfe7"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "82997889-d795-572b-a597-e484ef078b39"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "2555d898-6736-5804-951e-e3c438313029"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "e232ba2d-36a9-524e-9fb2-8541b27bb569"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "2723ed09-37f6-522c-92b1-e518d4fe9315"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "3cf77140-2757-58eb-b4ea-f2a90cd766e7"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "5c06d516-e816-58d6-997e-f8fa91131ac7"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "c0cc09c3-1335-5eb4-8801-b3cdaf74f6fa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM2": {
                    "questionId": "HM2",
                    "chosenOptionIds": [
                        "a046d610-d27a-59c1-b130-38312005cf1c"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "6b99de8b-634a-5eb0-bc65-bdf30eb9d433"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "9d212885-9b07-5623-8234-226adff08f6d"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c347cbdd-4788-538c-b190-8008a1e964e2"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "c8c8c192-aea3-5585-b11f-9d13faf4367d"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "d2c670c4-b05b-597a-a0af-5342230535a0"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "366c6a1b-c8b2-5faf-9d2f-72dbde24e869"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "1240a093-e011-5bb9-9dbe-b61af95e9cde"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "2a17c2fe-2592-58b1-98e9-3dc4168fe3e3"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "a56fcb96-f868-5d08-a358-9b403eb3cd16"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "fda00d52-c8be-5b69-bdbd-905c13dddd4e"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "bde4e5f3-19e4-571f-84a2-5b9d188d74ef"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "7d6921a2-7e63-5f12-9c75-dff8b592fabc"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: PE61J0L aspect: simple_bd1 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "45f0ce85-9e19-53da-bc0d-2af8b383318a"
                    ]
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "7a5958fc-e0f7-5d43-9cd0-abfe95cb7026"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "a9525e10-4e34-5d6b-9c93-c7546ec49231"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "9222be3e-443f-5146-9d69-d48fe9041ba0"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "77c6088d-2d8f-5c3b-89c9-79cb0d317226"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "a449e4ae-663b-50fb-9b62-1708b0a32669"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "f3efc764-ca53-52c3-9c3a-38c7f787d415"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "9506449e-b2aa-5498-b896-901dd248b1b3"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "ef6fddab-7a07-5f20-af20-74f2444eee53"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "3b216cf8-72e7-558c-bdd3-cc23a1865674"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "60fe0bb4-d770-56f2-b05a-864625a48e4d"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "7599bcaa-89a5-5f71-80ea-b70c360bb44a"
                    ]
                },
                "BP101b": {
                    "questionId": "BP101b",
                    "chosenOptionIds": [
                        "cb80bcac-db78-55bd-b051-bdd00801fd54"
                    ]
                },
                "BP102b": {
                    "questionId": "BP102b",
                    "chosenOptionIds": [
                        "acb61f4d-a637-587c-bd33-3e9195921dd3"
                    ]
                },
                "BP103b": {
                    "questionId": "BP103b",
                    "chosenOptionIds": [
                        "867b0ee4-73d9-5aac-b96d-bc2d85d5b183"
                    ]
                },
                "BP104b": {
                    "questionId": "BP104b",
                    "chosenOptionIds": [
                        "c59b5910-3279-509f-8175-f2fd0da2dc86"
                    ]
                },
                "BP87b": {
                    "questionId": "BP87b",
                    "chosenOptionIds": [
                        "8cf63df7-755b-597a-9125-d7435c303261"
                    ]
                },
                "BP88b": {
                    "questionId": "BP88b",
                    "chosenOptionIds": [
                        "a6244707-7c91-5858-a2e1-f6f953f7b85e"
                    ]
                },
                "BP89b": {
                    "questionId": "BP89b",
                    "chosenOptionIds": [
                        "41d2829a-3971-552f-8c5a-c77e7d0073e7"
                    ]
                },
                "BP90b": {
                    "questionId": "BP90b",
                    "chosenOptionIds": [
                        "95c2822d-27ad-5bb0-982f-8c2b949c8918"
                    ]
                },
                "BP92b": {
                    "questionId": "BP92b",
                    "chosenOptionIds": [
                        "b2f4c490-30df-5c8f-87c0-bc4acbcfb8e0"
                    ]
                },
                "BP94b": {
                    "questionId": "BP94b",
                    "chosenOptionIds": [
                        "038cb12b-2783-569a-b1d7-e28c65e591a3"
                    ]
                },
                "BP95b": {
                    "questionId": "BP95b",
                    "chosenOptionIds": [
                        "fb0bd4a7-2793-5b81-badc-fe81b4bc82dc"
                    ]
                },
                "BP96b": {
                    "questionId": "BP96b",
                    "chosenOptionIds": [
                        "8c3d3503-6334-55b9-b0f0-94c8e520094e"
                    ]
                },
                "BP97b": {
                    "questionId": "BP97b",
                    "chosenOptionIds": [
                        "6895096a-ef29-546e-9967-d7a23b4a2859"
                    ]
                },
                "BP98b": {
                    "questionId": "BP98b",
                    "chosenOptionIds": [
                        "01641419-fbfc-52a5-ae63-0fa736ff9847"
                    ]
                },
                "BP99.3b": {
                    "questionId": "BP99.3b",
                    "chosenOptionIds": [
                        "bf30a6c2-f8e2-5305-b4d4-7ad6227449d3"
                    ]
                },
                "BP99b": {
                    "questionId": "BP99b",
                    "chosenOptionIds": [
                        "68860df1-e835-50d6-8eb2-f9d9f2f97efa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "BP_neg1": {
                    "questionId": "BP_neg1",
                    "chosenOptionIds": [
                        "119208b0-ff3b-5c43-80be-4f07e65b6248"
                    ]
                }
            }
        },
        {
            "name": "Mood test: J3J4P0Y aspect: simple_bd1 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "c61d70d4-b773-54dd-94d0-d858a47be0fb"
                    ]
                },
                "D-1.5b": {
                    "questionId": "D-1.5b",
                    "freeValue": 5
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "fe031280-7d5d-52cb-aca8-1a8664b3234a"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "916d657d-9f2a-5451-8465-18e3bd863347"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "b74511c7-9f97-5c15-8bf5-6ec4357b4814"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 0.20833333333333331
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "db360e93-3616-5d89-be36-5ccee4aacf1c"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-1bl": {
                    "questionId": "D-1bl",
                    "chosenOptionIds": [
                        "83937123-de06-5b20-a479-c5e3e92848c4"
                    ]
                },
                "D-2": {
                    "questionId": "D-2",
                    "freeValue": 25
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "11f457f5-4c39-5458-ba9a-84478dd379ba"
                    ]
                },
                "D-2bl": {
                    "questionId": "D-2bl",
                    "chosenOptionIds": [
                        "02464c9b-d1d8-5f60-993d-5f37cbfcc102"
                    ]
                },
                "D-2bt": {
                    "questionId": "D-2bt",
                    "chosenOptionIds": [
                        "618070b6-e9e5-599a-a906-eed44f5c2dd9"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "bccbebb3-ed8c-5251-856b-e93a1d4386f6"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5c30c60e-d53e-5cc3-b4a9-fdad9bec4cb7"
                    ]
                },
                "D-3bl": {
                    "questionId": "D-3bl",
                    "freeValue": 3
                },
                "D-3bt": {
                    "questionId": "D-3bt",
                    "chosenOptionIds": [
                        "0d89dae6-d14a-56da-8409-ea7c21e59375"
                    ]
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-42bt": {
                    "questionId": "D-42bt",
                    "freeValue": 0.16666666666666666
                },
                "D-43bt": {
                    "questionId": "D-43bt",
                    "chosenOptionIds": [
                        "644dd944-5a2a-533b-9ef0-fadb5054c422"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "a449e4ae-663b-50fb-9b62-1708b0a32669"
                    ]
                },
                "D-4bt": {
                    "questionId": "D-4bt",
                    "freeValue": 4
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "f3efc764-ca53-52c3-9c3a-38c7f787d415"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "3e093dd3-857e-5004-a4fb-961fbfa59ece"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "adecf2d3-f302-50f8-a4a9-3ffac1789bc6"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "4c78ae3e-7de4-545f-aca1-03fa41fc0c23"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "13f8bac1-d0f5-539b-8596-1c55821bea46"
                    ]
                },
                "D-s10bt": {
                    "questionId": "D-s10bt",
                    "chosenOptionIds": [
                        "da993bd6-c107-53a4-9f6d-09f5a79aff65"
                    ]
                },
                "D-s11bt": {
                    "questionId": "D-s11bt",
                    "chosenOptionIds": [
                        "daf8b3c7-fb19-5a3f-ba9a-784adda7414f",
                        "6994b88e-cfa5-5986-b9e8-625d17d1147c",
                        "16e1a0b8-28c5-5bcd-931d-335c858c3f88",
                        "64af3cae-713a-51e1-99c1-09e2fdaa755d"
                    ]
                },
                "D-s1bt": {
                    "questionId": "D-s1bt",
                    "chosenOptionIds": [
                        "c17482e4-818a-50cd-90fa-1246d037825e"
                    ]
                },
                "D-s2bt": {
                    "questionId": "D-s2bt",
                    "chosenOptionIds": [
                        "aef7f114-e448-575f-bcbb-035e54001375"
                    ]
                },
                "D-s3bt": {
                    "questionId": "D-s3bt",
                    "chosenOptionIds": [
                        "11f57889-ac93-53ab-88a7-9aea03a29429"
                    ]
                },
                "D-s4bt": {
                    "questionId": "D-s4bt",
                    "chosenOptionIds": [
                        "6dd2621e-8242-58f6-8c6c-77df4a9eef53"
                    ]
                },
                "D-s5bt": {
                    "questionId": "D-s5bt",
                    "chosenOptionIds": [
                        "e6a5403c-95a3-58c9-9259-e02e5fafd441"
                    ]
                },
                "D-s6bt": {
                    "questionId": "D-s6bt",
                    "chosenOptionIds": [
                        "bb7e3f7e-73b0-56b0-b3d3-e336498509e8"
                    ]
                },
                "D-s7bt": {
                    "questionId": "D-s7bt",
                    "chosenOptionIds": [
                        "d703f0d6-4b67-5a66-8740-b5e4b3b0eb4d"
                    ]
                },
                "D-s8bt": {
                    "questionId": "D-s8bt",
                    "chosenOptionIds": [
                        "ebfe9c3e-852e-5116-8185-8430f6b361f8"
                    ]
                },
                "D-s9bt": {
                    "questionId": "D-s9bt",
                    "chosenOptionIds": [
                        "da503bbd-e567-576e-a21f-e0fad60dccdd"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "60fe0bb4-d770-56f2-b05a-864625a48e4d"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "7599bcaa-89a5-5f71-80ea-b70c360bb44a"
                    ]
                },
                "BP101b": {
                    "questionId": "BP101b",
                    "chosenOptionIds": [
                        "cb80bcac-db78-55bd-b051-bdd00801fd54"
                    ]
                },
                "BP102b": {
                    "questionId": "BP102b",
                    "chosenOptionIds": [
                        "46a64b5b-6641-5b1b-92aa-5366444fb134"
                    ]
                },
                "BP103b": {
                    "questionId": "BP103b",
                    "chosenOptionIds": [
                        "867b0ee4-73d9-5aac-b96d-bc2d85d5b183"
                    ]
                },
                "BP104b": {
                    "questionId": "BP104b",
                    "chosenOptionIds": [
                        "c59b5910-3279-509f-8175-f2fd0da2dc86"
                    ]
                },
                "BP87b": {
                    "questionId": "BP87b",
                    "chosenOptionIds": [
                        "7949bd96-3757-5573-bd89-3a91a383cc99"
                    ]
                },
                "BP88b": {
                    "questionId": "BP88b",
                    "chosenOptionIds": [
                        "fa24b924-a8e1-5f02-893b-3d9643007991"
                    ]
                },
                "BP89b": {
                    "questionId": "BP89b",
                    "chosenOptionIds": [
                        "db509963-d872-5b97-ba9b-a276f58e0189"
                    ]
                },
                "BP90b": {
                    "questionId": "BP90b",
                    "chosenOptionIds": [
                        "95c2822d-27ad-5bb0-982f-8c2b949c8918"
                    ]
                },
                "BP92b": {
                    "questionId": "BP92b",
                    "chosenOptionIds": [
                        "f59918ba-eb3a-5cf1-81aa-8b7f6ac0df5e"
                    ]
                },
                "BP94b": {
                    "questionId": "BP94b",
                    "chosenOptionIds": [
                        "038cb12b-2783-569a-b1d7-e28c65e591a3"
                    ]
                },
                "BP95b": {
                    "questionId": "BP95b",
                    "chosenOptionIds": [
                        "fb0bd4a7-2793-5b81-badc-fe81b4bc82dc"
                    ]
                },
                "BP96b": {
                    "questionId": "BP96b",
                    "chosenOptionIds": [
                        "8c3d3503-6334-55b9-b0f0-94c8e520094e"
                    ]
                },
                "BP97b": {
                    "questionId": "BP97b",
                    "chosenOptionIds": [
                        "8a1fc783-28cb-5bf7-aa78-8f6b7c36ad29"
                    ]
                },
                "BP98b": {
                    "questionId": "BP98b",
                    "chosenOptionIds": [
                        "01641419-fbfc-52a5-ae63-0fa736ff9847"
                    ]
                },
                "BP99.2b": {
                    "questionId": "BP99.2b",
                    "chosenOptionIds": [
                        "0f933dbb-72b4-5212-b415-8ea806f59368"
                    ]
                },
                "BP99.3b": {
                    "questionId": "BP99.3b",
                    "chosenOptionIds": [
                        "bf30a6c2-f8e2-5305-b4d4-7ad6227449d3"
                    ]
                },
                "BP99b": {
                    "questionId": "BP99b",
                    "chosenOptionIds": [
                        "3f987aaa-e435-55a9-9c1b-aa6d2e476738"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "BP_neg1": {
                    "questionId": "BP_neg1",
                    "chosenOptionIds": [
                        "119208b0-ff3b-5c43-80be-4f07e65b6248"
                    ]
                }
            }
        },
        {
            "name": "Mood test: Z3RLO3O aspect: simple_bd1 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "3bd18f66-6f1e-5ea4-ae24-2990ed197bef"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "eb68a88d-b49b-5cde-a9c6-5e4c78e27d19"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "a0e528ca-2fbb-5da1-9398-0758f79755aa"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 240
                },
                "D-15a": {
                    "questionId": "D-15a",
                    "chosenOptionIds": [
                        "7b763ff0-7703-54f7-8eae-aa83417ca133"
                    ]
                },
                "D-16.5a": {
                    "questionId": "D-16.5a",
                    "chosenOptionIds": [
                        "ae0a4d4e-e9bf-51bf-8506-0cbced38db92"
                    ]
                },
                "D-17a": {
                    "questionId": "D-17a",
                    "chosenOptionIds": [
                        "33b013af-d830-516a-9632-69dcc7a0f736"
                    ]
                },
                "D-18a": {
                    "questionId": "D-18a",
                    "chosenOptionIds": [
                        "24079de6-89e4-58c8-bb94-8e281cfc37da"
                    ]
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "db360e93-3616-5d89-be36-5ccee4aacf1c"
                    ]
                },
                "D-20a": {
                    "questionId": "D-20a",
                    "chosenOptionIds": [
                        "15c694bd-3089-51d8-8fff-6286e2113f9c"
                    ]
                },
                "D-21a": {
                    "questionId": "D-21a",
                    "chosenOptionIds": [
                        "e64b46f6-a0c1-5378-95de-f1de59b491b4"
                    ]
                },
                "D-23a": {
                    "questionId": "D-23a",
                    "chosenOptionIds": [
                        "f6cf63c5-0c57-5422-9431-55fec4c93c63"
                    ]
                },
                "D-26a": {
                    "questionId": "D-26a",
                    "chosenOptionIds": [
                        "945792e1-fdf5-5959-89c8-6a1caa4eff88"
                    ]
                },
                "D-27a": {
                    "questionId": "D-27a",
                    "chosenOptionIds": [
                        "8896026c-15ab-577e-8c44-e63eb7e6dba3"
                    ]
                },
                "D-28a": {
                    "questionId": "D-28a",
                    "chosenOptionIds": [
                        "8de8c31e-96ef-5e7d-8024-f6a72d07dd28"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "de11fb6b-7e7d-52b1-9d09-2d6debc53314"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "fd9c4394-6664-5144-8797-00d27a4dda01"
                    ]
                },
                "D-30a": {
                    "questionId": "D-30a",
                    "chosenOptionIds": [
                        "d309cef4-2204-5a10-a98d-b66740336838"
                    ]
                },
                "D-31a": {
                    "questionId": "D-31a",
                    "chosenOptionIds": [
                        "eba65e81-9b02-55d6-82cb-0b7ce6da41de"
                    ]
                },
                "D-32a": {
                    "questionId": "D-32a",
                    "chosenOptionIds": [
                        "ea2a4fbd-008a-571b-bb68-cbd0ba351521"
                    ]
                },
                "D-33a": {
                    "questionId": "D-33a",
                    "chosenOptionIds": [
                        "84dd5565-eec7-54c7-86e9-fa11de50e2c3"
                    ]
                },
                "D-34a": {
                    "questionId": "D-34a",
                    "chosenOptionIds": [
                        "e919c54f-d9a4-5a15-9ae7-8b6b52607a22"
                    ]
                },
                "D-35a": {
                    "questionId": "D-35a",
                    "chosenOptionIds": [
                        "bcace469-f380-599d-a33a-26db6d0053ad"
                    ]
                },
                "D-36a": {
                    "questionId": "D-36a",
                    "chosenOptionIds": [
                        "185038dd-ce55-53b2-ba09-0bd163af90b3"
                    ]
                },
                "D-37a": {
                    "questionId": "D-37a",
                    "chosenOptionIds": [
                        "fd4e0608-283c-5f56-a026-edfa381519c9"
                    ]
                },
                "D-38a": {
                    "questionId": "D-38a",
                    "chosenOptionIds": [
                        "cfa17d19-5b15-56ef-82c1-07949e21dd81"
                    ]
                },
                "D-39a": {
                    "questionId": "D-39a",
                    "chosenOptionIds": [
                        "ae31125f-c3db-5082-ae70-4329a2508744"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "db7514e2-d317-55fb-82d3-2e4a49c2494b"
                    ]
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-40a": {
                    "questionId": "D-40a",
                    "chosenOptionIds": [
                        "582119a7-d4a0-58ff-9b1d-2af045e11a7f"
                    ]
                },
                "D-41a": {
                    "questionId": "D-41a",
                    "chosenOptionIds": [
                        "89f5ff80-0cae-515a-a4d1-02448dbc11b8"
                    ]
                },
                "D-42a": {
                    "questionId": "D-42a",
                    "chosenOptionIds": [
                        "7099f8a9-4650-5da2-a627-bcd62cd99375"
                    ]
                },
                "D-43a": {
                    "questionId": "D-43a",
                    "chosenOptionIds": [
                        "cd0abe72-b3e8-5c78-8330-7ee4a1657536"
                    ]
                },
                "D-44.5a": {
                    "questionId": "D-44.5a",
                    "chosenOptionIds": [
                        "d87971dc-d745-5b64-ac37-37fbe1e722ac",
                        "bcf5fb9f-f4b9-5f0c-a964-69a4e1a73334"
                    ]
                },
                "D-44a": {
                    "questionId": "D-44a",
                    "chosenOptionIds": [
                        "7d6584ca-1b93-543d-9ee4-400ca40134be"
                    ]
                },
                "D-45a": {
                    "questionId": "D-45a",
                    "freeValue": 14
                },
                "D-46a": {
                    "questionId": "D-46a",
                    "chosenOptionIds": [
                        "1fd426df-808a-5c68-9b6e-23ff2f128b7d",
                        "4c178978-f79a-58fc-8e73-3a98614c4b6c",
                        "6e49f368-62af-5abb-9034-a58bd534944f",
                        "b3e43bd8-fbe3-5cda-b891-6f11e43993d0",
                        "d9665b1f-cdd2-5979-895e-27bca6487533",
                        "7492eab7-c625-52d0-a2e3-4bf3332decc0"
                    ]
                },
                "D-49.1a": {
                    "questionId": "D-49.1a",
                    "chosenOptionIds": [
                        "649f1c9d-3139-5567-b605-e22de7ed4f87"
                    ]
                },
                "D-49a": {
                    "questionId": "D-49a",
                    "chosenOptionIds": [
                        "16dc6af1-32a9-59c5-b66c-57f6becea1e0"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "1d4beed1-78e5-531c-a255-643fac260dee"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "b52073e3-c813-51f5-88ba-9cd3bddd7023"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "3e093dd3-857e-5004-a4fb-961fbfa59ece"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "772bee29-c3a5-52e1-b785-a77d506ae4fb"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "4c78ae3e-7de4-545f-aca1-03fa41fc0c23"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "60fe0bb4-d770-56f2-b05a-864625a48e4d"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "ccbdcbec-96d0-5362-977b-784289f5cfc0"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "4a0f1c4d-38cf-5de4-99e3-0ee818f9709c"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "6a5aa899-7b89-5661-a554-71b6058ad4a4"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "d3224e16-1b0c-5266-9e14-ac72d2b2e988"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "b24df75b-6bb9-5f5f-99e3-c883ec3644ec"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP12": {
                    "questionId": "BP12",
                    "chosenOptionIds": [
                        "1d6299ac-2ac6-51e2-9a3f-13e8d44e652a"
                    ]
                },
                "BP12.5": {
                    "questionId": "BP12.5",
                    "chosenOptionIds": [
                        "cd7ce00f-4e7c-57ac-9c6c-4137efa24c8c"
                    ]
                },
                "BP14": {
                    "questionId": "BP14",
                    "chosenOptionIds": [
                        "5585a7e8-a4b7-567e-b339-90537a58f1e3"
                    ]
                },
                "BP14.1": {
                    "questionId": "BP14.1",
                    "chosenOptionIds": [
                        "69f9a784-ce6d-5c82-bd3f-4c9c44bf5992"
                    ]
                },
                "BP14.2": {
                    "questionId": "BP14.2",
                    "freeValue": 365
                },
                "BP14.3": {
                    "questionId": "BP14.3",
                    "chosenOptionIds": [
                        "9f5fc152-75fb-59d8-8f40-7a6ca15c69cb"
                    ]
                },
                "BP16": {
                    "questionId": "BP16",
                    "chosenOptionIds": [
                        "fabceeea-8fea-5444-8b67-8f189f366c27"
                    ]
                },
                "BP16.0": {
                    "questionId": "BP16.0",
                    "chosenOptionIds": [
                        "4b26caa5-9124-51b2-ab08-2f4e977cee1f"
                    ]
                },
                "BP16.01": {
                    "questionId": "BP16.01",
                    "chosenOptionIds": [
                        "f81655d7-78e5-59b1-a7eb-90a2ec7a107d"
                    ]
                },
                "BP16.02": {
                    "questionId": "BP16.02",
                    "chosenOptionIds": [
                        "16a695c9-3ac1-5d34-b799-44602d85b556"
                    ]
                },
                "BP18": {
                    "questionId": "BP18",
                    "chosenOptionIds": [
                        "b577f323-ec54-5d25-a2b9-4f8de30d60a2"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 43
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "f03de28d-8f71-5af4-be2f-45f4e6092a3d"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "387b19c0-67d0-557e-9fba-ae065dcf3587"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "afb57366-c0b3-5f73-92eb-27bc133ad2ed"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "667ba57c-b1cd-5f9b-aa12-58e65a981910"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "fd30dc27-8e89-5d1a-9d95-8844603d4379"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "e22cf233-c174-52fb-af55-97bf9c46e971"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "0feda24f-7b01-557b-8ca5-10eb924cbdac"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "d680a8d9-e35f-563f-9d1d-e7c3136aff6d"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "94795194-4a80-590f-be62-ee78f2fa0ff5"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "732c51f9-a0b6-5951-bd7d-eaaec24316ba"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "6928c696-e6d7-5a14-a004-15a497ac6562"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "17b4da6f-7135-523c-b90b-2fe3b66785ff"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "3cf77140-2757-58eb-b4ea-f2a90cd766e7"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "5c06d516-e816-58d6-997e-f8fa91131ac7"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "a020ed85-ec9d-5778-8006-41f634cab09d"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM19": {
                    "questionId": "HM19",
                    "chosenOptionIds": [
                        "99f474d7-d509-592a-856a-afed343fb1b8"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "98ea07a0-ae95-570f-8d11-a3e7c682e986"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "696fdd9d-5ced-504b-a933-bb3e575b0099"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c41974b6-d394-569c-8f45-49f79fcac5c6"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "43c32319-c75f-5169-b0e5-713820721d4f"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "b2cb304f-f035-53fc-8763-e2f91b031f9b"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "0170b4cf-0541-53cd-a9db-818d5067dc23"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "2be66549-5767-51b4-82ed-deeab0723f8d"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "2a17c2fe-2592-58b1-98e9-3dc4168fe3e3"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "834b04f2-af09-5337-8fd5-be4a70c99e75"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "fa32b497-4a4b-5076-a391-b6dffa8e1851"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "8823c74f-ff7c-52d8-9c73-fd3b4180f48a"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "1d07911e-929d-5719-aeea-a68f593d18e8"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: 50MQK36 aspect: simple_bd1 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "0ec0e224-a5cf-5ece-b3ab-37061a600ace"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "a16e8e14-524e-53df-b4ef-730a2c8e5f32"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "eb68a88d-b49b-5cde-a9c6-5e4c78e27d19"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "a0e528ca-2fbb-5da1-9398-0758f79755aa"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 270
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "913bc1e9-8da1-54c7-b935-13fc5d48fbd9"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "11f457f5-4c39-5458-ba9a-84478dd379ba"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5b6c0a69-6369-53d1-b2e1-5cd6294a9f71"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "9d1b858c-7064-5a84-80d6-786c18e603b6",
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b",
                        "1d4beed1-78e5-531c-a255-643fac260dee"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "b52073e3-c813-51f5-88ba-9cd3bddd7023"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "3e093dd3-857e-5004-a4fb-961fbfa59ece"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "adecf2d3-f302-50f8-a4a9-3ffac1789bc6"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "67316d94-1cf0-5bd0-a4db-f57bc70684ba"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "60fe0bb4-d770-56f2-b05a-864625a48e4d"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "7599bcaa-89a5-5f71-80ea-b70c360bb44a"
                    ]
                },
                "BP101b": {
                    "questionId": "BP101b",
                    "chosenOptionIds": [
                        "fe614147-f80a-5aff-bb03-9298781e2468"
                    ]
                },
                "BP102b": {
                    "questionId": "BP102b",
                    "chosenOptionIds": [
                        "523c7d8e-e42b-5654-b037-e8837afb49e7"
                    ]
                },
                "BP103b": {
                    "questionId": "BP103b",
                    "chosenOptionIds": [
                        "867b0ee4-73d9-5aac-b96d-bc2d85d5b183"
                    ]
                },
                "BP104b": {
                    "questionId": "BP104b",
                    "chosenOptionIds": [
                        "c59b5910-3279-509f-8175-f2fd0da2dc86"
                    ]
                },
                "BP87b": {
                    "questionId": "BP87b",
                    "chosenOptionIds": [
                        "659c0d9a-389d-5b62-b3ac-e385dfafe63c"
                    ]
                },
                "BP88b": {
                    "questionId": "BP88b",
                    "chosenOptionIds": [
                        "7e4bdef7-d166-5a09-b3a4-639c1700578c"
                    ]
                },
                "BP89b": {
                    "questionId": "BP89b",
                    "chosenOptionIds": [
                        "db509963-d872-5b97-ba9b-a276f58e0189"
                    ]
                },
                "BP90b": {
                    "questionId": "BP90b",
                    "chosenOptionIds": [
                        "b4a79888-68e8-5b52-9a4e-2149be85f6e7"
                    ]
                },
                "BP92b": {
                    "questionId": "BP92b",
                    "chosenOptionIds": [
                        "b2f4c490-30df-5c8f-87c0-bc4acbcfb8e0"
                    ]
                },
                "BP94b": {
                    "questionId": "BP94b",
                    "chosenOptionIds": [
                        "9cf74958-461b-59df-b1a2-7ee7d7696a0b"
                    ]
                },
                "BP95b": {
                    "questionId": "BP95b",
                    "chosenOptionIds": [
                        "fb0bd4a7-2793-5b81-badc-fe81b4bc82dc"
                    ]
                },
                "BP96b": {
                    "questionId": "BP96b",
                    "chosenOptionIds": [
                        "4043e017-8609-50ef-bb4a-29f57c28965a"
                    ]
                },
                "BP97b": {
                    "questionId": "BP97b",
                    "chosenOptionIds": [
                        "8a1fc783-28cb-5bf7-aa78-8f6b7c36ad29"
                    ]
                },
                "BP98b": {
                    "questionId": "BP98b",
                    "chosenOptionIds": [
                        "cae1d431-f7ae-5a5a-924d-25c540e96d28"
                    ]
                },
                "BP99.3b": {
                    "questionId": "BP99.3b",
                    "chosenOptionIds": [
                        "d0adef9f-05e3-5ddf-a0f3-60eeb50d3862"
                    ]
                },
                "BP99b": {
                    "questionId": "BP99b",
                    "chosenOptionIds": [
                        "68860df1-e835-50d6-8eb2-f9d9f2f97efa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "BP_neg1": {
                    "questionId": "BP_neg1",
                    "chosenOptionIds": [
                        "119208b0-ff3b-5c43-80be-4f07e65b6248"
                    ]
                }
            }
        },
        {
            "name": "Mood test: 2E17NED aspect: simple_bd1 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "c61d70d4-b773-54dd-94d0-d858a47be0fb"
                    ]
                },
                "D-1.5b": {
                    "questionId": "D-1.5b",
                    "freeValue": 3
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "3bd18f66-6f1e-5ea4-ae24-2990ed197bef"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "916d657d-9f2a-5451-8465-18e3bd863347"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "b74511c7-9f97-5c15-8bf5-6ec4357b4814"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 0.125
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "db360e93-3616-5d89-be36-5ccee4aacf1c"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-1bl": {
                    "questionId": "D-1bl",
                    "chosenOptionIds": [
                        "83937123-de06-5b20-a479-c5e3e92848c4"
                    ]
                },
                "D-2": {
                    "questionId": "D-2",
                    "freeValue": 28
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "de11fb6b-7e7d-52b1-9d09-2d6debc53314"
                    ]
                },
                "D-2bl": {
                    "questionId": "D-2bl",
                    "chosenOptionIds": [
                        "02464c9b-d1d8-5f60-993d-5f37cbfcc102"
                    ]
                },
                "D-2bs": {
                    "questionId": "D-2bs",
                    "chosenOptionIds": [
                        "71e4fa61-0420-5a47-8362-38d1db6b5895"
                    ]
                },
                "D-2bt": {
                    "questionId": "D-2bt",
                    "chosenOptionIds": [
                        "618070b6-e9e5-599a-a906-eed44f5c2dd9"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "fd9c4394-6664-5144-8797-00d27a4dda01"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "db7514e2-d317-55fb-82d3-2e4a49c2494b"
                    ]
                },
                "D-3bl": {
                    "questionId": "D-3bl",
                    "freeValue": 2
                },
                "D-3bs": {
                    "questionId": "D-3bs",
                    "chosenOptionIds": [
                        "fe554f25-dfd7-5062-a2b8-f2662e2c3466"
                    ]
                },
                "D-3bt": {
                    "questionId": "D-3bt",
                    "chosenOptionIds": [
                        "0d89dae6-d14a-56da-8409-ea7c21e59375"
                    ]
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-42bt": {
                    "questionId": "D-42bt",
                    "freeValue": 0
                },
                "D-43bt": {
                    "questionId": "D-43bt",
                    "chosenOptionIds": [
                        "90e60395-fc3d-56cb-b6f7-2e24fba02803"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "a449e4ae-663b-50fb-9b62-1708b0a32669"
                    ]
                },
                "D-4bs": {
                    "questionId": "D-4bs",
                    "freeValue": 7
                },
                "D-4bt": {
                    "questionId": "D-4bt",
                    "freeValue": 0.08333333333333333
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "b52073e3-c813-51f5-88ba-9cd3bddd7023"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "ef6fddab-7a07-5f20-af20-74f2444eee53"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "3b216cf8-72e7-558c-bdd3-cc23a1865674"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "D-s10bt": {
                    "questionId": "D-s10bt",
                    "chosenOptionIds": [
                        "3deea42c-5d93-54cd-ba0f-4242654c3747"
                    ]
                },
                "D-s1bt": {
                    "questionId": "D-s1bt",
                    "chosenOptionIds": [
                        "4fa2ad77-a998-57d3-ae06-568782e0fb81"
                    ]
                },
                "D-s2bt": {
                    "questionId": "D-s2bt",
                    "chosenOptionIds": [
                        "e074592a-4667-5b65-b713-b8892451b433"
                    ]
                },
                "D-s3bt": {
                    "questionId": "D-s3bt",
                    "chosenOptionIds": [
                        "11f57889-ac93-53ab-88a7-9aea03a29429"
                    ]
                },
                "D-s4bt": {
                    "questionId": "D-s4bt",
                    "chosenOptionIds": [
                        "27313271-8ec6-5f99-bb2c-0ed50a7c1527"
                    ]
                },
                "D-s5bt": {
                    "questionId": "D-s5bt",
                    "chosenOptionIds": [
                        "d033bb4c-5d3c-519b-be31-1177042bffb4"
                    ]
                },
                "D-s6bt": {
                    "questionId": "D-s6bt",
                    "chosenOptionIds": [
                        "53625e56-4f03-5f54-a340-d5b8cabe24d9"
                    ]
                },
                "D-s7bt": {
                    "questionId": "D-s7bt",
                    "chosenOptionIds": [
                        "321f8608-d6e5-5e11-a88a-11d3351e8650"
                    ]
                },
                "D-s8bt": {
                    "questionId": "D-s8bt",
                    "chosenOptionIds": [
                        "ebfe9c3e-852e-5116-8185-8430f6b361f8"
                    ]
                },
                "D-s9bt": {
                    "questionId": "D-s9bt",
                    "chosenOptionIds": [
                        "34daded3-efdb-5981-a1fc-e31ec1176383"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "6a4d8b43-be3d-59b5-9bf4-1ca9f35b6973"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "ccbdcbec-96d0-5362-977b-784289f5cfc0"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "db7aa4b7-99a5-5475-a4e8-a744f3ef5a4a"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "e9eb902a-d327-5ec5-938c-dc317ca59b13"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "1daccd4c-e5ff-5f18-ba55-7c2d2d93b849"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "b24df75b-6bb9-5f5f-99e3-c883ec3644ec"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP12": {
                    "questionId": "BP12",
                    "chosenOptionIds": [
                        "22f9a995-3f9a-5fe9-99bb-71b7f1f96621"
                    ]
                },
                "BP15": {
                    "questionId": "BP15",
                    "chosenOptionIds": [
                        "9a1158f1-f08a-581d-a088-650041097c24"
                    ]
                },
                "BP15.1": {
                    "questionId": "BP15.1",
                    "chosenOptionIds": [
                        "aa7bd0b8-95ca-5179-a037-3956a8c2816d"
                    ]
                },
                "BP15.2": {
                    "questionId": "BP15.2",
                    "freeValue": 0.08333333333333333
                },
                "BP15.3": {
                    "questionId": "BP15.3",
                    "chosenOptionIds": [
                        "cfa519ff-08a9-5fa4-b12d-87b7a597cb13"
                    ]
                },
                "BP16": {
                    "questionId": "BP16",
                    "chosenOptionIds": [
                        "f1d6b61f-7ae0-5a6f-bbd0-a81b771141d3"
                    ]
                },
                "BP16.0": {
                    "questionId": "BP16.0",
                    "chosenOptionIds": [
                        "4b26caa5-9124-51b2-ab08-2f4e977cee1f"
                    ]
                },
                "BP16.01": {
                    "questionId": "BP16.01",
                    "chosenOptionIds": [
                        "f81655d7-78e5-59b1-a7eb-90a2ec7a107d"
                    ]
                },
                "BP16.02": {
                    "questionId": "BP16.02",
                    "chosenOptionIds": [
                        "16a695c9-3ac1-5d34-b799-44602d85b556"
                    ]
                },
                "BP19": {
                    "questionId": "BP19",
                    "chosenOptionIds": [
                        "601bc370-8a4c-5b1f-a7f6-6c174f421733"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 35
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "c7bee7a0-3037-5eb1-96a1-85b884d7e1dd"
                    ]
                },
                "BP3.01": {
                    "questionId": "BP3.01",
                    "freeValue": 1
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "e620af65-9b5b-57e6-b13a-65616e67e084"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "cb636d32-4f4e-51b3-a23f-2a11b8771b7e"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "0953f30c-801a-54f6-8238-b9dc42ab17f9"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "fd30dc27-8e89-5d1a-9d95-8844603d4379"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "d9fb150b-68b8-50ed-bb1d-eafd41f63f3b"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "46c90446-3ae9-53f5-9b57-ad19d66b5be6"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "63f0b17a-9832-5f0f-8630-e74f1a4fdfe7"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "c88e3fb5-913a-5c5c-a42b-603f7bb60a40"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "732c51f9-a0b6-5951-bd7d-eaaec24316ba"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "de2ea429-4fcd-53b8-8d9f-ebb69a637a02"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "6928c696-e6d7-5a14-a004-15a497ac6562"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "17b4da6f-7135-523c-b90b-2fe3b66785ff"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "cfb8fe0e-821a-554a-bb57-922f0ecc0f44"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "5c06d516-e816-58d6-997e-f8fa91131ac7"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "c0cc09c3-1335-5eb4-8801-b3cdaf74f6fa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM20": {
                    "questionId": "HM20",
                    "chosenOptionIds": [
                        "4eb576cf-e8d9-51f2-94cd-d232476ee5cb"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "6b99de8b-634a-5eb0-bc65-bdf30eb9d433"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "9d212885-9b07-5623-8234-226adff08f6d"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c41974b6-d394-569c-8f45-49f79fcac5c6"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "43c32319-c75f-5169-b0e5-713820721d4f"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "b2cb304f-f035-53fc-8763-e2f91b031f9b"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "366c6a1b-c8b2-5faf-9d2f-72dbde24e869"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "2be66549-5767-51b4-82ed-deeab0723f8d"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "2a17c2fe-2592-58b1-98e9-3dc4168fe3e3"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "834b04f2-af09-5337-8fd5-be4a70c99e75"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "86277249-cf50-5f91-a27f-f3a69344e796"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "8823c74f-ff7c-52d8-9c73-fd3b4180f48a"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "7d6921a2-7e63-5f12-9c75-dff8b592fabc"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: 10QJ8EP aspect: simple_bd1 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "0ec0e224-a5cf-5ece-b3ab-37061a600ace"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "a16e8e14-524e-53df-b4ef-730a2c8e5f32"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "916d657d-9f2a-5451-8465-18e3bd863347"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "a0e528ca-2fbb-5da1-9398-0758f79755aa"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 365
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "913bc1e9-8da1-54c7-b935-13fc5d48fbd9"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "11f457f5-4c39-5458-ba9a-84478dd379ba"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5c30c60e-d53e-5cc3-b4a9-fdad9bec4cb7"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "ba6dc2ba-3c20-57b6-a276-32c863109ff6",
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b",
                        "9d1b858c-7064-5a84-80d6-786c18e603b6"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "c9813959-ba61-5220-9e27-659abb256037"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "adecf2d3-f302-50f8-a4a9-3ffac1789bc6"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "d362cd87-7515-551e-bc74-19ef903484aa"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "4896b504-2025-52c3-85e2-c6cf3205cef7"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "ccbdcbec-96d0-5362-977b-784289f5cfc0"
                    ]
                },
                "BP10": {
                    "questionId": "BP10",
                    "chosenOptionIds": [
                        "7ad54f0a-87a6-5e82-bfc3-44b540230082"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "db7aa4b7-99a5-5475-a4e8-a744f3ef5a4a"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "ca5ac96d-2b69-5200-a063-61db840c9ef2"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "1daccd4c-e5ff-5f18-ba55-7c2d2d93b849"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "b24df75b-6bb9-5f5f-99e3-c883ec3644ec"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 25
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "c7bee7a0-3037-5eb1-96a1-85b884d7e1dd"
                    ]
                },
                "BP3.1": {
                    "questionId": "BP3.1",
                    "chosenOptionIds": [
                        "5bf1e313-2624-5b5f-9673-37ae9319ff0c"
                    ]
                },
                "BP3.2": {
                    "questionId": "BP3.2",
                    "chosenOptionIds": [
                        "ec333f8b-146a-5700-a15d-527032ceac3f"
                    ]
                },
                "BP3.3": {
                    "questionId": "BP3.3",
                    "freeValue": 5
                },
                "BP3.4": {
                    "questionId": "BP3.4",
                    "chosenOptionIds": [
                        "6ec82bbf-7582-5509-ace2-5ecf0ce82ec3"
                    ]
                },
                "BP4": {
                    "questionId": "BP4",
                    "chosenOptionIds": [
                        "9765aba8-7d55-5928-b79d-36aa58646036"
                    ]
                },
                "BP4.5": {
                    "questionId": "BP4.5",
                    "chosenOptionIds": [
                        "66a3460c-65ce-5a4c-9774-2e303ae4961e"
                    ]
                },
                "BP6": {
                    "questionId": "BP6",
                    "chosenOptionIds": [
                        "742a22d6-a570-5e3a-8e72-c001f1e9f98b"
                    ]
                },
                "BP6.1": {
                    "questionId": "BP6.1",
                    "chosenOptionIds": [
                        "a99773bd-2bff-523e-bc29-1f761307b3d4"
                    ]
                },
                "BP6.2": {
                    "questionId": "BP6.2",
                    "freeValue": 5
                },
                "BP6.3": {
                    "questionId": "BP6.3",
                    "chosenOptionIds": [
                        "e061c578-086e-5990-bba6-279cb273f3a5"
                    ]
                },
                "BP8": {
                    "questionId": "BP8",
                    "chosenOptionIds": [
                        "87e0081b-9455-5ced-9225-10c2f70b1734"
                    ]
                },
                "BP8.0": {
                    "questionId": "BP8.0",
                    "chosenOptionIds": [
                        "3568cdb1-2500-512b-aada-2547c5bfd835"
                    ]
                },
                "BP8.01": {
                    "questionId": "BP8.01",
                    "chosenOptionIds": [
                        "5020bfdd-a280-56cf-b7f5-3b0b7e667165"
                    ]
                },
                "BP8.02": {
                    "questionId": "BP8.02",
                    "chosenOptionIds": [
                        "7d3a0aa5-605b-58d1-a834-073feefe8e5d"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "387b19c0-67d0-557e-9fba-ae065dcf3587"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "afb57366-c0b3-5f73-92eb-27bc133ad2ed"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "667ba57c-b1cd-5f9b-aa12-58e65a981910"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "fd30dc27-8e89-5d1a-9d95-8844603d4379"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "e22cf233-c174-52fb-af55-97bf9c46e971"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "cc7c09ae-1cb7-5b9a-9cd3-8d1012b90ee8"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 5
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "17619692-6d18-5558-99da-766a1c275e03"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "f35fe383-d2aa-51c4-9fde-961b1de6d52b"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "ec84966e-bb9f-5ba9-a0cf-d5d3503a74e0"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "6928c696-e6d7-5a14-a004-15a497ac6562"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "2723ed09-37f6-522c-92b1-e518d4fe9315"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "3cf77140-2757-58eb-b4ea-f2a90cd766e7"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "5c06d516-e816-58d6-997e-f8fa91131ac7"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "e81fe613-4923-55ed-bd6c-bd3f19e026f9"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM2": {
                    "questionId": "HM2",
                    "chosenOptionIds": [
                        "de662c3f-66b4-566f-856d-2124e2725542"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "c3ee8981-dec3-5b3c-80ca-c1023f983a6d"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "696fdd9d-5ced-504b-a933-bb3e575b0099"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c41974b6-d394-569c-8f45-49f79fcac5c6"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "43c32319-c75f-5169-b0e5-713820721d4f"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "b2cb304f-f035-53fc-8763-e2f91b031f9b"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "0170b4cf-0541-53cd-a9db-818d5067dc23"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "2be66549-5767-51b4-82ed-deeab0723f8d"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "2a17c2fe-2592-58b1-98e9-3dc4168fe3e3"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "834b04f2-af09-5337-8fd5-be4a70c99e75"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "fda00d52-c8be-5b69-bdbd-905c13dddd4e"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "8823c74f-ff7c-52d8-9c73-fd3b4180f48a"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "1d07911e-929d-5719-aeea-a68f593d18e8"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: XE7DM0L aspect: simple_bd1 should be diagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "fe031280-7d5d-52cb-aca8-1a8664b3234a"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "9b47900d-e5a7-5e58-830b-d9b3130a7990"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "826538fc-8a97-55e4-8013-fe6a93f8e451"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 4745
                },
                "D-15a": {
                    "questionId": "D-15a",
                    "chosenOptionIds": [
                        "7b763ff0-7703-54f7-8eae-aa83417ca133"
                    ]
                },
                "D-16.5a": {
                    "questionId": "D-16.5a",
                    "chosenOptionIds": [
                        "b409dbb9-80ef-5de1-96d0-1075735134db"
                    ]
                },
                "D-17a": {
                    "questionId": "D-17a",
                    "chosenOptionIds": [
                        "8413ed32-e959-5c2a-9d19-8cc68709c34d"
                    ]
                },
                "D-19a": {
                    "questionId": "D-19a",
                    "chosenOptionIds": [
                        "6fde9b5c-ed1c-561c-b516-8f5028a1bcd5"
                    ]
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "80c993c8-1a94-5684-a8f9-d8f5072c8e9c"
                    ]
                },
                "D-1bl": {
                    "questionId": "D-1bl",
                    "chosenOptionIds": [
                        "5da400b0-5bff-5744-a664-4305892ae669"
                    ]
                },
                "D-2": {
                    "questionId": "D-2",
                    "freeValue": 48
                },
                "D-20a": {
                    "questionId": "D-20a",
                    "chosenOptionIds": [
                        "15c694bd-3089-51d8-8fff-6286e2113f9c"
                    ]
                },
                "D-21a": {
                    "questionId": "D-21a",
                    "chosenOptionIds": [
                        "dc2b002f-2d96-504d-bde9-66faceececb6"
                    ]
                },
                "D-23a": {
                    "questionId": "D-23a",
                    "chosenOptionIds": [
                        "32a2b9d2-46ee-5235-9c03-8fb1fb1e5800"
                    ]
                },
                "D-26a": {
                    "questionId": "D-26a",
                    "chosenOptionIds": [
                        "eb4d6df4-7e45-5437-a36e-540fa95a45f9"
                    ]
                },
                "D-27a": {
                    "questionId": "D-27a",
                    "chosenOptionIds": [
                        "3d120f97-fb12-5e86-8bb9-8fc93babc803"
                    ]
                },
                "D-28a": {
                    "questionId": "D-28a",
                    "chosenOptionIds": [
                        "009661f3-60b5-52e8-bc5f-44c65767874c"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "cc243947-5067-5a98-ade6-a4e10d0b42cf"
                    ]
                },
                "D-2bl": {
                    "questionId": "D-2bl",
                    "chosenOptionIds": [
                        "2a8c96e2-cb5b-596a-99b6-a4885253776c"
                    ]
                },
                "D-2bs": {
                    "questionId": "D-2bs",
                    "chosenOptionIds": [
                        "bbd43ce6-ea63-5321-9d05-0bbf9a19513b"
                    ]
                },
                "D-2bt": {
                    "questionId": "D-2bt",
                    "chosenOptionIds": [
                        "315fa694-3bc0-5231-93f2-ceb1d9766403"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "bccbebb3-ed8c-5251-856b-e93a1d4386f6",
                        "14d310b2-5fb6-5f79-8bfa-e3524002e63d",
                        "fd9c4394-6664-5144-8797-00d27a4dda01"
                    ]
                },
                "D-30a": {
                    "questionId": "D-30a",
                    "chosenOptionIds": [
                        "1f6e9ea6-3402-596b-a518-31105c7a277b"
                    ]
                },
                "D-31a": {
                    "questionId": "D-31a",
                    "chosenOptionIds": [
                        "c78e0def-d73e-5c4e-8800-7c6f92d0a1ec"
                    ]
                },
                "D-32a": {
                    "questionId": "D-32a",
                    "chosenOptionIds": [
                        "ea2a4fbd-008a-571b-bb68-cbd0ba351521"
                    ]
                },
                "D-33a": {
                    "questionId": "D-33a",
                    "chosenOptionIds": [
                        "3968dd70-f060-5cbd-bb6a-d63f702a3691"
                    ]
                },
                "D-34a": {
                    "questionId": "D-34a",
                    "chosenOptionIds": [
                        "9d969817-a8d1-57f5-8c15-5e89835ed2a4"
                    ]
                },
                "D-35a": {
                    "questionId": "D-35a",
                    "chosenOptionIds": [
                        "9c9d44bb-b50f-5ddf-9553-03b254272362"
                    ]
                },
                "D-36a": {
                    "questionId": "D-36a",
                    "chosenOptionIds": [
                        "f6958030-4bae-5f83-a569-35cb7675b803"
                    ]
                },
                "D-37a": {
                    "questionId": "D-37a",
                    "chosenOptionIds": [
                        "fd4e0608-283c-5f56-a026-edfa381519c9"
                    ]
                },
                "D-38a": {
                    "questionId": "D-38a",
                    "chosenOptionIds": [
                        "a03f02de-f86d-525a-b375-383ad9d3cefe"
                    ]
                },
                "D-39a": {
                    "questionId": "D-39a",
                    "chosenOptionIds": [
                        "ae31125f-c3db-5082-ae70-4329a2508744"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "f110f5ec-75f1-51be-9c45-ea7685a54d64"
                    ]
                },
                "D-3bl": {
                    "questionId": "D-3bl",
                    "freeValue": 4745
                },
                "D-3bs": {
                    "questionId": "D-3bs",
                    "chosenOptionIds": [
                        "c4422e8a-6a0a-51ea-8592-9157036922d1"
                    ]
                },
                "D-3bt": {
                    "questionId": "D-3bt",
                    "chosenOptionIds": [
                        "b9abc28d-6e22-5b67-bc5e-27431e123974"
                    ]
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-40a": {
                    "questionId": "D-40a",
                    "chosenOptionIds": [
                        "eece7786-6774-532b-ac46-218f4cd6f083"
                    ]
                },
                "D-41a": {
                    "questionId": "D-41a",
                    "chosenOptionIds": [
                        "6a5d1a65-0b53-5485-995a-76d8d7eafb1a"
                    ]
                },
                "D-42a": {
                    "questionId": "D-42a",
                    "chosenOptionIds": [
                        "926931de-e675-5117-9e61-78dd541393be"
                    ]
                },
                "D-42bt": {
                    "questionId": "D-42bt",
                    "freeValue": 1095
                },
                "D-43a": {
                    "questionId": "D-43a",
                    "chosenOptionIds": [
                        "cd0abe72-b3e8-5c78-8330-7ee4a1657536"
                    ]
                },
                "D-43bt": {
                    "questionId": "D-43bt",
                    "chosenOptionIds": [
                        "90e60395-fc3d-56cb-b6f7-2e24fba02803"
                    ]
                },
                "D-44.5a": {
                    "questionId": "D-44.5a",
                    "chosenOptionIds": [
                        "d87971dc-d745-5b64-ac37-37fbe1e722ac",
                        "b5fc3288-710a-5aef-9842-611757b40967",
                        "884767e3-ed0c-5db3-a227-f84f7ecaf8d3",
                        "d3ed5c81-5b77-58c3-983c-d29f9788c396",
                        "bcf5fb9f-f4b9-5f0c-a964-69a4e1a73334"
                    ]
                },
                "D-44a": {
                    "questionId": "D-44a",
                    "chosenOptionIds": [
                        "68a987a9-8bad-50d7-a4d9-e970334f0460"
                    ]
                },
                "D-45a": {
                    "questionId": "D-45a",
                    "freeValue": 1095
                },
                "D-46a": {
                    "questionId": "D-46a",
                    "chosenOptionIds": [
                        "f0b15a45-ae49-59d2-97a7-33f866b0e629",
                        "b3e43bd8-fbe3-5cda-b891-6f11e43993d0",
                        "6e49f368-62af-5abb-9034-a58bd534944f",
                        "4c178978-f79a-58fc-8e73-3a98614c4b6c",
                        "7492eab7-c625-52d0-a2e3-4bf3332decc0",
                        "1fd426df-808a-5c68-9b6e-23ff2f128b7d",
                        "13260a8b-4ec0-5e4d-84fc-1df990527937"
                    ]
                },
                "D-49.1a": {
                    "questionId": "D-49.1a",
                    "chosenOptionIds": [
                        "7108af97-c956-5da8-8dfa-518e346c197c"
                    ]
                },
                "D-49.2a": {
                    "questionId": "D-49.2a",
                    "freeValue": 800
                },
                "D-49a": {
                    "questionId": "D-49a",
                    "chosenOptionIds": [
                        "16dc6af1-32a9-59c5-b66c-57f6becea1e0"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b"
                    ]
                },
                "D-4bs": {
                    "questionId": "D-4bs",
                    "freeValue": 1095
                },
                "D-4bt": {
                    "questionId": "D-4bt",
                    "freeValue": 2920
                },
                "D-50a": {
                    "questionId": "D-50a",
                    "chosenOptionIds": [
                        "d91f7277-0497-57d2-93a3-ea638d14162c"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "dde1af45-4686-52c9-b960-369527b28f6c"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "81e9f63b-35b9-5032-8ea0-462275a87cce"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "5d271ac0-beca-56cc-9d0b-adafcd67da53"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "33a5fff0-9587-521f-bc53-0dacc36a0bb2"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "13f8bac1-d0f5-539b-8596-1c55821bea46"
                    ]
                },
                "D-s10bt": {
                    "questionId": "D-s10bt",
                    "chosenOptionIds": [
                        "da993bd6-c107-53a4-9f6d-09f5a79aff65"
                    ]
                },
                "D-s11bt": {
                    "questionId": "D-s11bt",
                    "chosenOptionIds": [
                        "daf8b3c7-fb19-5a3f-ba9a-784adda7414f",
                        "dc476220-9479-5def-8a97-20e6152d44af",
                        "9c3fa6af-8b7b-5993-a587-641683d934d5",
                        "6994b88e-cfa5-5986-b9e8-625d17d1147c",
                        "16e1a0b8-28c5-5bcd-931d-335c858c3f88",
                        "bae5acc8-8664-54a2-ba97-c36244be4c44",
                        "64af3cae-713a-51e1-99c1-09e2fdaa755d",
                        "7921bccf-4756-59fe-b678-d536c2ab5945",
                        "049f46ae-b7bd-5a1e-8c63-351f481236ec"
                    ]
                },
                "D-s1bt": {
                    "questionId": "D-s1bt",
                    "chosenOptionIds": [
                        "c17482e4-818a-50cd-90fa-1246d037825e"
                    ]
                },
                "D-s2bt": {
                    "questionId": "D-s2bt",
                    "chosenOptionIds": [
                        "b75d06ef-eb6c-5e23-bf8c-9b566fa4d9dd"
                    ]
                },
                "D-s3bt": {
                    "questionId": "D-s3bt",
                    "chosenOptionIds": [
                        "d065c0ca-9e6d-5300-8e3e-688b16f88c59"
                    ]
                },
                "D-s4bt": {
                    "questionId": "D-s4bt",
                    "chosenOptionIds": [
                        "5a198500-fc4b-55a1-9521-3abe49eed06f",
                        "fd9851a0-7ce7-55a8-b257-5617537d0013",
                        "a4021497-af94-56a4-8075-392653262e2e"
                    ]
                },
                "D-s5bt": {
                    "questionId": "D-s5bt",
                    "chosenOptionIds": [
                        "8260d432-3a96-519e-b824-d24940e4aa65"
                    ]
                },
                "D-s6bt": {
                    "questionId": "D-s6bt",
                    "chosenOptionIds": [
                        "33cda0a0-a5db-5a6c-8603-b5b899edac54"
                    ]
                },
                "D-s7bt": {
                    "questionId": "D-s7bt",
                    "chosenOptionIds": [
                        "1df67815-0b9f-56ef-bd48-96ed977db141"
                    ]
                },
                "D-s8bt": {
                    "questionId": "D-s8bt",
                    "chosenOptionIds": [
                        "6f5067cf-ef4d-59d9-afa1-b3dfc4ac861f"
                    ]
                },
                "D-s9bt": {
                    "questionId": "D-s9bt",
                    "chosenOptionIds": [
                        "da503bbd-e567-576e-a21f-e0fad60dccdd"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "44fd9eb3-aee2-5af0-b83c-dbbbf9514022"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "6215a3de-a3e4-5f86-a039-1006646e6b93"
                    ]
                },
                "BP106": {
                    "questionId": "BP106",
                    "chosenOptionIds": [
                        "8c35da7e-8c0b-50bb-a037-b80a9f1df3d2"
                    ]
                },
                "BP108": {
                    "questionId": "BP108",
                    "chosenOptionIds": [
                        "6c88c07e-4483-5b5b-b6ad-44cff962863e"
                    ]
                },
                "BP110": {
                    "questionId": "BP110",
                    "freeValue": 4745
                },
                "BP110.01": {
                    "questionId": "BP110.01",
                    "chosenOptionIds": [
                        "7c60a3ff-2742-5128-b0a0-3157bfe8c0f5"
                    ]
                },
                "BP110.1": {
                    "questionId": "BP110.1",
                    "chosenOptionIds": [
                        "9ce31a68-0e9d-55e2-a573-174bcbeb7458"
                    ]
                },
                "BP110.2": {
                    "questionId": "BP110.2",
                    "chosenOptionIds": [
                        "dbc05d00-3f66-51fd-825b-712e8609aa9f"
                    ]
                },
                "BP111": {
                    "questionId": "BP111",
                    "chosenOptionIds": [
                        "02b20612-0b18-5aba-91d5-3ebd2589fb3d"
                    ]
                },
                "BP111.1": {
                    "questionId": "BP111.1",
                    "chosenOptionIds": [
                        "c532b673-2762-5ed0-8cbb-6f70d9977c20"
                    ]
                },
                "BP111.2": {
                    "questionId": "BP111.2",
                    "chosenOptionIds": [
                        "b218cc67-f452-588d-b809-7cd97ee83e20"
                    ]
                },
                "BP112": {
                    "questionId": "BP112",
                    "chosenOptionIds": [
                        "71867305-9c3a-564c-a0c7-e187b27e8d79"
                    ]
                },
                "BP112.1": {
                    "questionId": "BP112.1",
                    "freeValue": 676
                },
                "BP112.11": {
                    "questionId": "BP112.11",
                    "chosenOptionIds": [
                        "158f7b58-6651-5c6e-8a4b-41995ae3a765"
                    ]
                },
                "BP112.12": {
                    "questionId": "BP112.12",
                    "chosenOptionIds": [
                        "add1ea84-0327-5281-9cf4-5ddbd95b2a8f"
                    ]
                },
                "BP112.13": {
                    "questionId": "BP112.13",
                    "freeValue": 4745
                },
                "BP112.14": {
                    "questionId": "BP112.14",
                    "chosenOptionIds": [
                        "6ead4f84-b429-5a17-85d6-808bd332aa25"
                    ]
                },
                "BP112.3": {
                    "questionId": "BP112.3",
                    "chosenOptionIds": [
                        "5de2cc88-13a4-5f04-a3d2-1333852193f9"
                    ]
                },
                "BP113": {
                    "questionId": "BP113",
                    "chosenOptionIds": [
                        "b16103eb-2ecf-595e-80e4-30baf575e01b"
                    ]
                },
                "BP114": {
                    "questionId": "BP114",
                    "chosenOptionIds": [
                        "a0206e39-59ad-539c-a2ff-e9fd935cf48b"
                    ]
                },
                "BP115": {
                    "questionId": "BP115",
                    "chosenOptionIds": [
                        "e7b2a68b-de30-51bd-bb56-cda1254d3dbc"
                    ]
                },
                "BP116": {
                    "questionId": "BP116",
                    "chosenOptionIds": [
                        "bce3bb8c-216a-5167-8ec7-35c39096d501"
                    ]
                },
                "BP117": {
                    "questionId": "BP117",
                    "chosenOptionIds": [
                        "354c012f-3484-58ad-9300-d9e29a212ae3"
                    ]
                },
                "BP118": {
                    "questionId": "BP118",
                    "chosenOptionIds": [
                        "e642f54c-d6fc-57f8-a53c-73dbd698d428"
                    ]
                },
                "BP118.1": {
                    "questionId": "BP118.1",
                    "chosenOptionIds": [
                        "3299a8a7-4375-55ee-87b2-76bda766fd11"
                    ]
                },
                "BP119": {
                    "questionId": "BP119",
                    "chosenOptionIds": [
                        "64d22ab4-481d-5258-9244-0ec9d3ba77a7"
                    ]
                },
                "BP119.1": {
                    "questionId": "BP119.1",
                    "freeValue": 1095
                },
                "BP120": {
                    "questionId": "BP120",
                    "chosenOptionIds": [
                        "548c329b-3169-5742-b17d-437216e6a454"
                    ]
                },
                "BP121": {
                    "questionId": "BP121",
                    "chosenOptionIds": [
                        "2dd3fa4b-7390-5c95-b04b-c5c77974e8c2"
                    ]
                },
                "BP122": {
                    "questionId": "BP122",
                    "chosenOptionIds": [
                        "3d008b0e-8759-568c-9aae-ba21c8b6ae79"
                    ]
                },
                "BP123": {
                    "questionId": "BP123",
                    "chosenOptionIds": [
                        "972a0931-d7f1-5ad4-90af-91046fd7b193"
                    ]
                },
                "BP124": {
                    "questionId": "BP124",
                    "chosenOptionIds": [
                        "368a583d-81fc-5f77-a51c-647fd7f8f473"
                    ]
                },
                "BP125": {
                    "questionId": "BP125",
                    "chosenOptionIds": [
                        "19dd84c4-ccf5-5060-83ae-e1f2f977f1c0"
                    ]
                },
                "BP126": {
                    "questionId": "BP126",
                    "chosenOptionIds": [
                        "7bdce4bf-24ca-5470-a5cd-68c1e5c7ef03"
                    ]
                },
                "BP127": {
                    "questionId": "BP127",
                    "chosenOptionIds": [
                        "5f58bb60-dff1-585c-a5f3-26e34e4dc957"
                    ]
                },
                "BP128": {
                    "questionId": "BP128",
                    "chosenOptionIds": [
                        "f2c70c18-c55b-5447-9859-67292866bede"
                    ]
                },
                "BP129": {
                    "questionId": "BP129",
                    "chosenOptionIds": [
                        "477c3730-71ba-505f-9caa-07a83398580f"
                    ]
                },
                "BP130": {
                    "questionId": "BP130",
                    "chosenOptionIds": [
                        "7a066bdc-85a0-56cc-8632-2e83740855c1"
                    ]
                },
                "BP131": {
                    "questionId": "BP131",
                    "chosenOptionIds": [
                        "cf80f9fa-de7b-5954-b005-e9ec1b1b07a9"
                    ]
                },
                "BP132": {
                    "questionId": "BP132",
                    "chosenOptionIds": [
                        "da9000ec-69f6-580b-9ec9-756b702c2962"
                    ]
                },
                "BP133": {
                    "questionId": "BP133",
                    "chosenOptionIds": [
                        "36121dba-4b4b-584f-9f5b-4b715cf0b2af"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM35": {
                    "questionId": "HM35",
                    "chosenOptionIds": [
                        "c5f21a2b-5af8-525d-bfab-0ed035defec0"
                    ]
                },
                "HM36": {
                    "questionId": "HM36",
                    "chosenOptionIds": [
                        "47bfc26b-4237-5a82-9cc4-67d348c4acb0"
                    ]
                },
                "HM37": {
                    "questionId": "HM37",
                    "chosenOptionIds": [
                        "20de534d-f482-51c5-ad87-bd054787fddc"
                    ]
                },
                "HM38": {
                    "questionId": "HM38",
                    "chosenOptionIds": [
                        "5125bbbb-70fb-5adc-a41b-c65c6b96180d"
                    ]
                },
                "HM40": {
                    "questionId": "HM40",
                    "chosenOptionIds": [
                        "596144bf-d0ea-5d17-a2ae-7096c31e2def"
                    ]
                },
                "HM41": {
                    "questionId": "HM41",
                    "chosenOptionIds": [
                        "dd9cb999-d487-51f2-8ca3-f121593feca0"
                    ]
                },
                "HM43": {
                    "questionId": "HM43",
                    "chosenOptionIds": [
                        "a8b07611-dcc0-55ef-ae78-d4ceb7ad42e3"
                    ]
                },
                "HM44": {
                    "questionId": "HM44",
                    "chosenOptionIds": [
                        "fe1e5434-ebd9-5a33-92c8-acca24a484b7"
                    ]
                },
                "HM45": {
                    "questionId": "HM45",
                    "chosenOptionIds": [
                        "28a20a2a-3318-5dba-9e59-86d943d3ac73"
                    ]
                },
                "HM46": {
                    "questionId": "HM46",
                    "chosenOptionIds": [
                        "9f868300-e1df-5567-8ebf-e2f984022e52"
                    ]
                },
                "HM47": {
                    "questionId": "HM47",
                    "chosenOptionIds": [
                        "41ec9370-ba26-5cbc-ad35-d9e578788aa4"
                    ]
                },
                "HM48": {
                    "questionId": "HM48",
                    "chosenOptionIds": [
                        "9b74dd11-97db-567e-8d3d-836001d82229"
                    ]
                },
                "HM49": {
                    "questionId": "HM49",
                    "chosenOptionIds": [
                        "7a5feb6e-c27f-54d2-9473-fc197426685c"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        }
    ],
    "simple_bd2": [
        {
            "name": "Mood test: 6PE670L aspect: simple_bd2 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "a16e8e14-524e-53df-b4ef-730a2c8e5f32"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "c0453e8e-7a9c-5bf5-b8a2-167a61a6387e"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "826538fc-8a97-55e4-8013-fe6a93f8e451"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 180
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "913bc1e9-8da1-54c7-b935-13fc5d48fbd9"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "a9525e10-4e34-5d6b-9c93-c7546ec49231"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "de11fb6b-7e7d-52b1-9d09-2d6debc53314"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "77c6088d-2d8f-5c3b-89c9-79cb0d317226"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "1d4beed1-78e5-531c-a255-643fac260dee",
                        "9d1b858c-7064-5a84-80d6-786c18e603b6"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "f3efc764-ca53-52c3-9c3a-38c7f787d415"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "5d271ac0-beca-56cc-9d0b-adafcd67da53"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "d362cd87-7515-551e-bc74-19ef903484aa"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "4896b504-2025-52c3-85e2-c6cf3205cef7"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "ccbdcbec-96d0-5362-977b-784289f5cfc0"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "026a1cea-7cfc-540b-bfe5-42886be90595"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "6a5aa899-7b89-5661-a554-71b6058ad4a4"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "d3224e16-1b0c-5266-9e14-ac72d2b2e988"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "b24df75b-6bb9-5f5f-99e3-c883ec3644ec"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP12": {
                    "questionId": "BP12",
                    "chosenOptionIds": [
                        "1d6299ac-2ac6-51e2-9a3f-13e8d44e652a"
                    ]
                },
                "BP12.5": {
                    "questionId": "BP12.5",
                    "chosenOptionIds": [
                        "cd7ce00f-4e7c-57ac-9c6c-4137efa24c8c"
                    ]
                },
                "BP14": {
                    "questionId": "BP14",
                    "chosenOptionIds": [
                        "84994ca2-fcea-54fe-a7d6-9b44ec07f0bd"
                    ]
                },
                "BP14.1": {
                    "questionId": "BP14.1",
                    "chosenOptionIds": [
                        "f2b7f127-8ab7-5609-8820-43158baee15c"
                    ]
                },
                "BP14.2": {
                    "questionId": "BP14.2",
                    "freeValue": 1
                },
                "BP14.3": {
                    "questionId": "BP14.3",
                    "chosenOptionIds": [
                        "ac6d7839-60fb-5529-97b0-5d0f13ed20de"
                    ]
                },
                "BP16": {
                    "questionId": "BP16",
                    "chosenOptionIds": [
                        "fabceeea-8fea-5444-8b67-8f189f366c27"
                    ]
                },
                "BP16.0": {
                    "questionId": "BP16.0",
                    "chosenOptionIds": [
                        "4b26caa5-9124-51b2-ab08-2f4e977cee1f"
                    ]
                },
                "BP16.01": {
                    "questionId": "BP16.01",
                    "chosenOptionIds": [
                        "f81655d7-78e5-59b1-a7eb-90a2ec7a107d"
                    ]
                },
                "BP16.02": {
                    "questionId": "BP16.02",
                    "chosenOptionIds": [
                        "16a695c9-3ac1-5d34-b799-44602d85b556"
                    ]
                },
                "BP18": {
                    "questionId": "BP18",
                    "chosenOptionIds": [
                        "b577f323-ec54-5d25-a2b9-4f8de30d60a2"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 58
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "f03de28d-8f71-5af4-be2f-45f4e6092a3d"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "387b19c0-67d0-557e-9fba-ae065dcf3587"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "afb57366-c0b3-5f73-92eb-27bc133ad2ed"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "667ba57c-b1cd-5f9b-aa12-58e65a981910"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "5d5fa968-aeb3-569a-8a71-483ef04b9a6a"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "e22cf233-c174-52fb-af55-97bf9c46e971"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "1f57d1a0-3682-5d7d-b66b-8179ec5c3988"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "d680a8d9-e35f-563f-9d1d-e7c3136aff6d"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "c88e3fb5-913a-5c5c-a42b-603f7bb60a40"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "732c51f9-a0b6-5951-bd7d-eaaec24316ba"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "6928c696-e6d7-5a14-a004-15a497ac6562"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "17b4da6f-7135-523c-b90b-2fe3b66785ff"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "3cf77140-2757-58eb-b4ea-f2a90cd766e7"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "5c06d516-e816-58d6-997e-f8fa91131ac7"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "c0cc09c3-1335-5eb4-8801-b3cdaf74f6fa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM19": {
                    "questionId": "HM19",
                    "chosenOptionIds": [
                        "99f474d7-d509-592a-856a-afed343fb1b8"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "98ea07a0-ae95-570f-8d11-a3e7c682e986"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "696fdd9d-5ced-504b-a933-bb3e575b0099"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c41974b6-d394-569c-8f45-49f79fcac5c6"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "43c32319-c75f-5169-b0e5-713820721d4f"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "b2cb304f-f035-53fc-8763-e2f91b031f9b"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "0170b4cf-0541-53cd-a9db-818d5067dc23"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "2be66549-5767-51b4-82ed-deeab0723f8d"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "2a17c2fe-2592-58b1-98e9-3dc4168fe3e3"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "834b04f2-af09-5337-8fd5-be4a70c99e75"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "86277249-cf50-5f91-a27f-f3a69344e796"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "8823c74f-ff7c-52d8-9c73-fd3b4180f48a"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "1d07911e-929d-5719-aeea-a68f593d18e8"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: 6ED9M0M aspect: simple_bd2 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "0ec0e224-a5cf-5ece-b3ab-37061a600ace"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "3bd18f66-6f1e-5ea4-ae24-2990ed197bef"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "9b47900d-e5a7-5e58-830b-d9b3130a7990"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "b74511c7-9f97-5c15-8bf5-6ec4357b4814"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 1095
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "7a5958fc-e0f7-5d43-9cd0-abfe95cb7026"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "c695fd1c-2cb7-5654-b9f7-b32ecb158229"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "db7514e2-d317-55fb-82d3-2e4a49c2494b"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "9d1b858c-7064-5a84-80d6-786c18e603b6",
                        "1d4beed1-78e5-531c-a255-643fac260dee",
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "f3efc764-ca53-52c3-9c3a-38c7f787d415"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "3e093dd3-857e-5004-a4fb-961fbfa59ece"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "adecf2d3-f302-50f8-a4a9-3ffac1789bc6"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "d362cd87-7515-551e-bc74-19ef903484aa"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "13f8bac1-d0f5-539b-8596-1c55821bea46"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "44fd9eb3-aee2-5af0-b83c-dbbbf9514022"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "7599bcaa-89a5-5f71-80ea-b70c360bb44a"
                    ]
                },
                "BP101b": {
                    "questionId": "BP101b",
                    "chosenOptionIds": [
                        "31b2d2c8-8f28-514e-9e58-aa878f6c8761"
                    ]
                },
                "BP102b": {
                    "questionId": "BP102b",
                    "chosenOptionIds": [
                        "00afc1e6-b78a-5223-a6e7-5d3aaab833e0"
                    ]
                },
                "BP103b": {
                    "questionId": "BP103b",
                    "chosenOptionIds": [
                        "867b0ee4-73d9-5aac-b96d-bc2d85d5b183"
                    ]
                },
                "BP104b": {
                    "questionId": "BP104b",
                    "chosenOptionIds": [
                        "75eb3568-195e-5720-827c-1fd0f84c6e75"
                    ]
                },
                "BP105b": {
                    "questionId": "BP105b",
                    "chosenOptionIds": [
                        "2700e917-0fd4-5241-975c-959339855190"
                    ]
                },
                "BP106b": {
                    "questionId": "BP106b",
                    "freeValue": 1460
                },
                "BP87b": {
                    "questionId": "BP87b",
                    "chosenOptionIds": [
                        "fb568891-2ea3-5200-bbdb-ac0695f30b02"
                    ]
                },
                "BP88b": {
                    "questionId": "BP88b",
                    "chosenOptionIds": [
                        "7e4bdef7-d166-5a09-b3a4-639c1700578c"
                    ]
                },
                "BP89b": {
                    "questionId": "BP89b",
                    "chosenOptionIds": [
                        "db509963-d872-5b97-ba9b-a276f58e0189"
                    ]
                },
                "BP90b": {
                    "questionId": "BP90b",
                    "chosenOptionIds": [
                        "940868cd-d067-573d-9a59-29b2ca63222f"
                    ]
                },
                "BP92b": {
                    "questionId": "BP92b",
                    "chosenOptionIds": [
                        "f59918ba-eb3a-5cf1-81aa-8b7f6ac0df5e"
                    ]
                },
                "BP94b": {
                    "questionId": "BP94b",
                    "chosenOptionIds": [
                        "038cb12b-2783-569a-b1d7-e28c65e591a3"
                    ]
                },
                "BP95b": {
                    "questionId": "BP95b",
                    "chosenOptionIds": [
                        "fb0bd4a7-2793-5b81-badc-fe81b4bc82dc"
                    ]
                },
                "BP96b": {
                    "questionId": "BP96b",
                    "chosenOptionIds": [
                        "c1f02353-e805-53f2-ab3b-34499538a407"
                    ]
                },
                "BP97b": {
                    "questionId": "BP97b",
                    "chosenOptionIds": [
                        "8a1fc783-28cb-5bf7-aa78-8f6b7c36ad29"
                    ]
                },
                "BP98b": {
                    "questionId": "BP98b",
                    "chosenOptionIds": [
                        "01641419-fbfc-52a5-ae63-0fa736ff9847"
                    ]
                },
                "BP99.3b": {
                    "questionId": "BP99.3b",
                    "chosenOptionIds": [
                        "c5d5e724-00d6-5a76-bcaf-e4b6545b7fa4"
                    ]
                },
                "BP99b": {
                    "questionId": "BP99b",
                    "chosenOptionIds": [
                        "68860df1-e835-50d6-8eb2-f9d9f2f97efa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "BP_neg1": {
                    "questionId": "BP_neg1",
                    "chosenOptionIds": [
                        "119208b0-ff3b-5c43-80be-4f07e65b6248"
                    ]
                }
            }
        },
        {
            "name": "Mood test: O0K9D01 aspect: simple_bd2 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "0ec0e224-a5cf-5ece-b3ab-37061a600ace"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "a16e8e14-524e-53df-b4ef-730a2c8e5f32"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "eb68a88d-b49b-5cde-a9c6-5e4c78e27d19"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "b74511c7-9f97-5c15-8bf5-6ec4357b4814"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 3650
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "db360e93-3616-5d89-be36-5ccee4aacf1c"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "11f457f5-4c39-5458-ba9a-84478dd379ba"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "db7514e2-d317-55fb-82d3-2e4a49c2494b"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "9d1b858c-7064-5a84-80d6-786c18e603b6",
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b",
                        "1d4beed1-78e5-531c-a255-643fac260dee"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "b52073e3-c813-51f5-88ba-9cd3bddd7023"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "ef6fddab-7a07-5f20-af20-74f2444eee53"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "d362cd87-7515-551e-bc74-19ef903484aa"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "44fd9eb3-aee2-5af0-b83c-dbbbf9514022"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "6215a3de-a3e4-5f86-a039-1006646e6b93"
                    ]
                },
                "BP106": {
                    "questionId": "BP106",
                    "chosenOptionIds": [
                        "3648c32c-0ce7-5c1d-a7e8-d45a899b40f9"
                    ]
                },
                "BP108": {
                    "questionId": "BP108",
                    "chosenOptionIds": [
                        "6c88c07e-4483-5b5b-b6ad-44cff962863e"
                    ]
                },
                "BP110": {
                    "questionId": "BP110",
                    "freeValue": 0.25
                },
                "BP110.01": {
                    "questionId": "BP110.01",
                    "chosenOptionIds": [
                        "a6d87c31-9a11-5151-958a-040439463b3d"
                    ]
                },
                "BP110.1": {
                    "questionId": "BP110.1",
                    "chosenOptionIds": [
                        "9d19a3c0-120d-5dfd-a794-8aa0e8d39e77"
                    ]
                },
                "BP110.2": {
                    "questionId": "BP110.2",
                    "chosenOptionIds": [
                        "faf183ab-b9b4-5f0d-a996-dfa04be4e401"
                    ]
                },
                "BP111": {
                    "questionId": "BP111",
                    "chosenOptionIds": [
                        "059fdaea-ce9e-527c-ae94-e00d2b0dbe01"
                    ]
                },
                "BP111.1": {
                    "questionId": "BP111.1",
                    "chosenOptionIds": [
                        "c532b673-2762-5ed0-8cbb-6f70d9977c20"
                    ]
                },
                "BP111.2": {
                    "questionId": "BP111.2",
                    "chosenOptionIds": [
                        "6d6a3478-7734-5127-b59e-092b42109314"
                    ]
                },
                "BP112": {
                    "questionId": "BP112",
                    "chosenOptionIds": [
                        "6db4ba23-1d8f-58b9-b11e-eaea15417e28"
                    ]
                },
                "BP113": {
                    "questionId": "BP113",
                    "chosenOptionIds": [
                        "b16103eb-2ecf-595e-80e4-30baf575e01b"
                    ]
                },
                "BP114": {
                    "questionId": "BP114",
                    "chosenOptionIds": [
                        "a0206e39-59ad-539c-a2ff-e9fd935cf48b"
                    ]
                },
                "BP115": {
                    "questionId": "BP115",
                    "chosenOptionIds": [
                        "e7b2a68b-de30-51bd-bb56-cda1254d3dbc"
                    ]
                },
                "BP116": {
                    "questionId": "BP116",
                    "chosenOptionIds": [
                        "cd9085de-4e15-5383-b814-a261f79b136f"
                    ]
                },
                "BP117": {
                    "questionId": "BP117",
                    "chosenOptionIds": [
                        "c68c6fcb-e62b-5749-80e4-9c4ad9995b97"
                    ]
                },
                "BP118": {
                    "questionId": "BP118",
                    "chosenOptionIds": [
                        "a611c3a0-727b-5a9b-8d15-d805b2ecf757"
                    ]
                },
                "BP118.1": {
                    "questionId": "BP118.1",
                    "chosenOptionIds": [
                        "3780fe32-30da-5e3b-bad1-6d360381e7e4"
                    ]
                },
                "BP119": {
                    "questionId": "BP119",
                    "chosenOptionIds": [
                        "3f4ab649-e8be-559b-a32f-9ccb317ffb38"
                    ]
                },
                "BP119.1": {
                    "questionId": "BP119.1",
                    "freeValue": 0
                },
                "BP120": {
                    "questionId": "BP120",
                    "chosenOptionIds": [
                        "548c329b-3169-5742-b17d-437216e6a454"
                    ]
                },
                "BP121": {
                    "questionId": "BP121",
                    "chosenOptionIds": [
                        "76891993-4f79-5b7a-ad6f-b7be1c4994fb"
                    ]
                },
                "BP122": {
                    "questionId": "BP122",
                    "chosenOptionIds": [
                        "84ec10d0-2e9c-5671-9d59-dc91b256ca0b"
                    ]
                },
                "BP123": {
                    "questionId": "BP123",
                    "chosenOptionIds": [
                        "972a0931-d7f1-5ad4-90af-91046fd7b193"
                    ]
                },
                "BP124": {
                    "questionId": "BP124",
                    "chosenOptionIds": [
                        "d7e7a81a-6129-54d0-8a45-35c7e9ae3bf3"
                    ]
                },
                "BP125": {
                    "questionId": "BP125",
                    "chosenOptionIds": [
                        "1926be5c-c655-5494-8fb8-9492f4a4324e"
                    ]
                },
                "BP126": {
                    "questionId": "BP126",
                    "chosenOptionIds": [
                        "47856c8e-63c1-5066-bf95-92ff01e0982a"
                    ]
                },
                "BP127": {
                    "questionId": "BP127",
                    "chosenOptionIds": [
                        "5f58bb60-dff1-585c-a5f3-26e34e4dc957"
                    ]
                },
                "BP128": {
                    "questionId": "BP128",
                    "chosenOptionIds": [
                        "6b43ea3f-8741-56e6-808f-129fe78b7440"
                    ]
                },
                "BP129": {
                    "questionId": "BP129",
                    "chosenOptionIds": [
                        "c534368a-9c51-5f0d-8b17-9df288fd59a0"
                    ]
                },
                "BP130": {
                    "questionId": "BP130",
                    "chosenOptionIds": [
                        "b4725e03-4f40-5305-9d3d-1ff9297f8bff"
                    ]
                },
                "BP131": {
                    "questionId": "BP131",
                    "chosenOptionIds": [
                        "e5863f5d-32a6-520a-9b40-8591adf1c1b8"
                    ]
                },
                "BP132": {
                    "questionId": "BP132",
                    "chosenOptionIds": [
                        "da9000ec-69f6-580b-9ec9-756b702c2962"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM35": {
                    "questionId": "HM35",
                    "chosenOptionIds": [
                        "2b40020d-33ba-5560-9075-d482aa324496"
                    ]
                },
                "HM36": {
                    "questionId": "HM36",
                    "chosenOptionIds": [
                        "47bfc26b-4237-5a82-9cc4-67d348c4acb0"
                    ]
                },
                "HM37": {
                    "questionId": "HM37",
                    "chosenOptionIds": [
                        "20de534d-f482-51c5-ad87-bd054787fddc"
                    ]
                },
                "HM38": {
                    "questionId": "HM38",
                    "chosenOptionIds": [
                        "5125bbbb-70fb-5adc-a41b-c65c6b96180d"
                    ]
                },
                "HM40": {
                    "questionId": "HM40",
                    "chosenOptionIds": [
                        "596144bf-d0ea-5d17-a2ae-7096c31e2def"
                    ]
                },
                "HM41": {
                    "questionId": "HM41",
                    "chosenOptionIds": [
                        "dd9cb999-d487-51f2-8ca3-f121593feca0"
                    ]
                },
                "HM43": {
                    "questionId": "HM43",
                    "chosenOptionIds": [
                        "a8b07611-dcc0-55ef-ae78-d4ceb7ad42e3"
                    ]
                },
                "HM44": {
                    "questionId": "HM44",
                    "chosenOptionIds": [
                        "3675f391-ff12-51e5-b53f-b851b05414ee"
                    ]
                },
                "HM45": {
                    "questionId": "HM45",
                    "chosenOptionIds": [
                        "28a20a2a-3318-5dba-9e59-86d943d3ac73"
                    ]
                },
                "HM46": {
                    "questionId": "HM46",
                    "chosenOptionIds": [
                        "a57434cf-7c45-5e3d-9d0d-c8b432490e99"
                    ]
                },
                "HM47": {
                    "questionId": "HM47",
                    "chosenOptionIds": [
                        "41ec9370-ba26-5cbc-ad35-d9e578788aa4"
                    ]
                },
                "HM48": {
                    "questionId": "HM48",
                    "chosenOptionIds": [
                        "c0e7db40-86e9-5cba-98b0-e42a3fc0835b"
                    ]
                },
                "HM49": {
                    "questionId": "HM49",
                    "chosenOptionIds": [
                        "ad65e31f-19f9-587c-bc99-0b3549cc5cb0"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: NELXPEV aspect: simple_bd2 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "0ec0e224-a5cf-5ece-b3ab-37061a600ace"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "45f0ce85-9e19-53da-bc0d-2af8b383318a"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "c0453e8e-7a9c-5bf5-b8a2-167a61a6387e"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "322210fb-135d-5ac8-9617-7fc4f85e016d"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 0.041666666666666664
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "913bc1e9-8da1-54c7-b935-13fc5d48fbd9"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "9222be3e-443f-5146-9d69-d48fe9041ba0"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "77c6088d-2d8f-5c3b-89c9-79cb0d317226"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "a449e4ae-663b-50fb-9b62-1708b0a32669"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "f3efc764-ca53-52c3-9c3a-38c7f787d415"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "ef6fddab-7a07-5f20-af20-74f2444eee53"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "3b216cf8-72e7-558c-bdd3-cc23a1865674"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "4896b504-2025-52c3-85e2-c6cf3205cef7"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "7599bcaa-89a5-5f71-80ea-b70c360bb44a"
                    ]
                },
                "BP101b": {
                    "questionId": "BP101b",
                    "chosenOptionIds": [
                        "cb80bcac-db78-55bd-b051-bdd00801fd54"
                    ]
                },
                "BP102b": {
                    "questionId": "BP102b",
                    "chosenOptionIds": [
                        "71338232-94be-5805-b822-94db8be9c5a1"
                    ]
                },
                "BP103b": {
                    "questionId": "BP103b",
                    "chosenOptionIds": [
                        "867b0ee4-73d9-5aac-b96d-bc2d85d5b183"
                    ]
                },
                "BP104b": {
                    "questionId": "BP104b",
                    "chosenOptionIds": [
                        "c59b5910-3279-509f-8175-f2fd0da2dc86"
                    ]
                },
                "BP87b": {
                    "questionId": "BP87b",
                    "chosenOptionIds": [
                        "7949bd96-3757-5573-bd89-3a91a383cc99"
                    ]
                },
                "BP88b": {
                    "questionId": "BP88b",
                    "chosenOptionIds": [
                        "fa24b924-a8e1-5f02-893b-3d9643007991"
                    ]
                },
                "BP89b": {
                    "questionId": "BP89b",
                    "chosenOptionIds": [
                        "41d2829a-3971-552f-8c5a-c77e7d0073e7"
                    ]
                },
                "BP90b": {
                    "questionId": "BP90b",
                    "chosenOptionIds": [
                        "95c2822d-27ad-5bb0-982f-8c2b949c8918"
                    ]
                },
                "BP92b": {
                    "questionId": "BP92b",
                    "chosenOptionIds": [
                        "b2f4c490-30df-5c8f-87c0-bc4acbcfb8e0"
                    ]
                },
                "BP94b": {
                    "questionId": "BP94b",
                    "chosenOptionIds": [
                        "038cb12b-2783-569a-b1d7-e28c65e591a3"
                    ]
                },
                "BP95b": {
                    "questionId": "BP95b",
                    "chosenOptionIds": [
                        "fb0bd4a7-2793-5b81-badc-fe81b4bc82dc"
                    ]
                },
                "BP96b": {
                    "questionId": "BP96b",
                    "chosenOptionIds": [
                        "8c3d3503-6334-55b9-b0f0-94c8e520094e"
                    ]
                },
                "BP97b": {
                    "questionId": "BP97b",
                    "chosenOptionIds": [
                        "6895096a-ef29-546e-9967-d7a23b4a2859"
                    ]
                },
                "BP98b": {
                    "questionId": "BP98b",
                    "chosenOptionIds": [
                        "01641419-fbfc-52a5-ae63-0fa736ff9847"
                    ]
                },
                "BP99.3b": {
                    "questionId": "BP99.3b",
                    "chosenOptionIds": [
                        "bf30a6c2-f8e2-5305-b4d4-7ad6227449d3"
                    ]
                },
                "BP99b": {
                    "questionId": "BP99b",
                    "chosenOptionIds": [
                        "68860df1-e835-50d6-8eb2-f9d9f2f97efa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "BP_neg1": {
                    "questionId": "BP_neg1",
                    "chosenOptionIds": [
                        "119208b0-ff3b-5c43-80be-4f07e65b6248"
                    ]
                }
            }
        },
        {
            "name": "Mood test: 73ZQN3R aspect: simple_bd2 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "0ec0e224-a5cf-5ece-b3ab-37061a600ace"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "3bd18f66-6f1e-5ea4-ae24-2990ed197bef"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "eb68a88d-b49b-5cde-a9c6-5e4c78e27d19"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "b74511c7-9f97-5c15-8bf5-6ec4357b4814"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 4
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "80c993c8-1a94-5684-a8f9-d8f5072c8e9c"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "cc243947-5067-5a98-ade6-a4e10d0b42cf"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "f110f5ec-75f1-51be-9c45-ea7685a54d64"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b",
                        "ba6dc2ba-3c20-57b6-a276-32c863109ff6",
                        "1d4beed1-78e5-531c-a255-643fac260dee"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "f3efc764-ca53-52c3-9c3a-38c7f787d415"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "08a0f83f-2f37-5904-9364-4e40deb90e98"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "5d271ac0-beca-56cc-9d0b-adafcd67da53"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "33a5fff0-9587-521f-bc53-0dacc36a0bb2"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "13f8bac1-d0f5-539b-8596-1c55821bea46"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "4896b504-2025-52c3-85e2-c6cf3205cef7"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "83e6efd5-64a2-5f0e-a973-9278eae90cfb"
                    ]
                },
                "BP10": {
                    "questionId": "BP10",
                    "chosenOptionIds": [
                        "7ad54f0a-87a6-5e82-bfc3-44b540230082"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "182e121d-415c-5f42-a76c-a996f5f38b3f"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "d8c1997f-d722-536c-ad9d-296b5e10ed04"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "d3224e16-1b0c-5266-9e14-ac72d2b2e988"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "f2228920-3fec-5097-a63d-990c25d50c6c"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP105": {
                    "questionId": "BP105",
                    "chosenOptionIds": [
                        "978f2ff4-94d9-5f7e-9126-e8657be0c7ca"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 25
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "c7bee7a0-3037-5eb1-96a1-85b884d7e1dd"
                    ]
                },
                "BP3.1": {
                    "questionId": "BP3.1",
                    "chosenOptionIds": [
                        "9531c3ff-ab2b-5198-a490-919ede5afb07"
                    ]
                },
                "BP3.2": {
                    "questionId": "BP3.2",
                    "chosenOptionIds": [
                        "ec333f8b-146a-5700-a15d-527032ceac3f"
                    ]
                },
                "BP3.3": {
                    "questionId": "BP3.3",
                    "freeValue": 4
                },
                "BP3.4": {
                    "questionId": "BP3.4",
                    "chosenOptionIds": [
                        "f304bf93-0c68-5da0-80ba-ccecc2d0fce1"
                    ]
                },
                "BP4": {
                    "questionId": "BP4",
                    "chosenOptionIds": [
                        "9765aba8-7d55-5928-b79d-36aa58646036"
                    ]
                },
                "BP4.5": {
                    "questionId": "BP4.5",
                    "chosenOptionIds": [
                        "66a3460c-65ce-5a4c-9774-2e303ae4961e"
                    ]
                },
                "BP6": {
                    "questionId": "BP6",
                    "chosenOptionIds": [
                        "33ae5abe-63ad-57d4-86a4-0dcd33576094"
                    ]
                },
                "BP6.1": {
                    "questionId": "BP6.1",
                    "chosenOptionIds": [
                        "a99773bd-2bff-523e-bc29-1f761307b3d4"
                    ]
                },
                "BP6.2": {
                    "questionId": "BP6.2",
                    "freeValue": 3
                },
                "BP6.3": {
                    "questionId": "BP6.3",
                    "chosenOptionIds": [
                        "a19e0696-e780-5235-88b9-f5c36ce0afc3"
                    ]
                },
                "BP8": {
                    "questionId": "BP8",
                    "chosenOptionIds": [
                        "9b3c44f8-4468-5f37-86e9-ed0908e71d5c"
                    ]
                },
                "BP8.0": {
                    "questionId": "BP8.0",
                    "chosenOptionIds": [
                        "3568cdb1-2500-512b-aada-2547c5bfd835"
                    ]
                },
                "BP8.01": {
                    "questionId": "BP8.01",
                    "chosenOptionIds": [
                        "5020bfdd-a280-56cf-b7f5-3b0b7e667165"
                    ]
                },
                "BP8.02": {
                    "questionId": "BP8.02",
                    "chosenOptionIds": [
                        "7d3a0aa5-605b-58d1-a834-073feefe8e5d"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "387b19c0-67d0-557e-9fba-ae065dcf3587"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "afb57366-c0b3-5f73-92eb-27bc133ad2ed"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "667ba57c-b1cd-5f9b-aa12-58e65a981910"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "a2056280-4b54-5258-8bb4-407b521fff4b"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "c211fabc-a62d-5440-8ddc-f57eb21d7679"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "cc7c09ae-1cb7-5b9a-9cd3-8d1012b90ee8"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "d680a8d9-e35f-563f-9d1d-e7c3136aff6d"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "940c16a8-1835-582a-9dad-bd52e3a49cc7"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "1e86d64a-b972-500a-9120-7884e4b86ede"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "dbab3669-e910-5267-b7b1-fcb661ea7e81"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "2723ed09-37f6-522c-92b1-e518d4fe9315"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "3cf77140-2757-58eb-b4ea-f2a90cd766e7"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "5c06d516-e816-58d6-997e-f8fa91131ac7"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "e81fe613-4923-55ed-bd6c-bd3f19e026f9"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM2": {
                    "questionId": "HM2",
                    "chosenOptionIds": [
                        "de662c3f-66b4-566f-856d-2124e2725542"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "98ea07a0-ae95-570f-8d11-a3e7c682e986"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "696fdd9d-5ced-504b-a933-bb3e575b0099"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c41974b6-d394-569c-8f45-49f79fcac5c6"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "43c32319-c75f-5169-b0e5-713820721d4f"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "b2cb304f-f035-53fc-8763-e2f91b031f9b"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "0170b4cf-0541-53cd-a9db-818d5067dc23"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "7b310da1-94e3-5d45-a6c3-3905eeb28ec6"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "2a17c2fe-2592-58b1-98e9-3dc4168fe3e3"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "834b04f2-af09-5337-8fd5-be4a70c99e75"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "fa32b497-4a4b-5076-a391-b6dffa8e1851"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "8823c74f-ff7c-52d8-9c73-fd3b4180f48a"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "1d07911e-929d-5719-aeea-a68f593d18e8"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: NELRPEV aspect: simple_bd2 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "0ec0e224-a5cf-5ece-b3ab-37061a600ace"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "a16e8e14-524e-53df-b4ef-730a2c8e5f32"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "c0453e8e-7a9c-5bf5-b8a2-167a61a6387e"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "b74511c7-9f97-5c15-8bf5-6ec4357b4814"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 60
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "913bc1e9-8da1-54c7-b935-13fc5d48fbd9"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "11f457f5-4c39-5458-ba9a-84478dd379ba"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "77c6088d-2d8f-5c3b-89c9-79cb0d317226"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b",
                        "1d4beed1-78e5-531c-a255-643fac260dee"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "c9813959-ba61-5220-9e27-659abb256037"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "3e093dd3-857e-5004-a4fb-961fbfa59ece"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "adecf2d3-f302-50f8-a4a9-3ffac1789bc6"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "d362cd87-7515-551e-bc74-19ef903484aa"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "44fd9eb3-aee2-5af0-b83c-dbbbf9514022"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "83e6efd5-64a2-5f0e-a973-9278eae90cfb"
                    ]
                },
                "BP10": {
                    "questionId": "BP10",
                    "chosenOptionIds": [
                        "7ad54f0a-87a6-5e82-bfc3-44b540230082"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "4a0f1c4d-38cf-5de4-99e3-0ee818f9709c"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "e9eb902a-d327-5ec5-938c-dc317ca59b13"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "1daccd4c-e5ff-5f18-ba55-7c2d2d93b849"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "b24df75b-6bb9-5f5f-99e3-c883ec3644ec"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 15
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "c7bee7a0-3037-5eb1-96a1-85b884d7e1dd"
                    ]
                },
                "BP3.1": {
                    "questionId": "BP3.1",
                    "chosenOptionIds": [
                        "5bf1e313-2624-5b5f-9673-37ae9319ff0c"
                    ]
                },
                "BP3.2": {
                    "questionId": "BP3.2",
                    "chosenOptionIds": [
                        "43011291-20a8-59ce-8bac-df882587ff5c"
                    ]
                },
                "BP3.3": {
                    "questionId": "BP3.3",
                    "freeValue": 1
                },
                "BP3.4": {
                    "questionId": "BP3.4",
                    "chosenOptionIds": [
                        "f304bf93-0c68-5da0-80ba-ccecc2d0fce1"
                    ]
                },
                "BP4": {
                    "questionId": "BP4",
                    "chosenOptionIds": [
                        "9765aba8-7d55-5928-b79d-36aa58646036"
                    ]
                },
                "BP4.5": {
                    "questionId": "BP4.5",
                    "chosenOptionIds": [
                        "66a3460c-65ce-5a4c-9774-2e303ae4961e"
                    ]
                },
                "BP6": {
                    "questionId": "BP6",
                    "chosenOptionIds": [
                        "742a22d6-a570-5e3a-8e72-c001f1e9f98b"
                    ]
                },
                "BP6.1": {
                    "questionId": "BP6.1",
                    "chosenOptionIds": [
                        "77b4d620-93e4-5bc5-baad-4995d168f392"
                    ]
                },
                "BP6.2": {
                    "questionId": "BP6.2",
                    "freeValue": 0.08333333333333333
                },
                "BP6.3": {
                    "questionId": "BP6.3",
                    "chosenOptionIds": [
                        "a19e0696-e780-5235-88b9-f5c36ce0afc3"
                    ]
                },
                "BP8": {
                    "questionId": "BP8",
                    "chosenOptionIds": [
                        "9b3c44f8-4468-5f37-86e9-ed0908e71d5c"
                    ]
                },
                "BP8.0": {
                    "questionId": "BP8.0",
                    "chosenOptionIds": [
                        "3568cdb1-2500-512b-aada-2547c5bfd835"
                    ]
                },
                "BP8.01": {
                    "questionId": "BP8.01",
                    "chosenOptionIds": [
                        "5020bfdd-a280-56cf-b7f5-3b0b7e667165"
                    ]
                },
                "BP8.02": {
                    "questionId": "BP8.02",
                    "chosenOptionIds": [
                        "e88670a3-c6d8-5c96-914e-b9fc1818aeda"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "387b19c0-67d0-557e-9fba-ae065dcf3587"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "afb57366-c0b3-5f73-92eb-27bc133ad2ed"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "667ba57c-b1cd-5f9b-aa12-58e65a981910"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "a2056280-4b54-5258-8bb4-407b521fff4b"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "e22cf233-c174-52fb-af55-97bf9c46e971"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "46c90446-3ae9-53f5-9b57-ad19d66b5be6"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "ef5a7b22-b2b2-5a5b-8a80-ce5c251033fd"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "82997889-d795-572b-a597-e484ef078b39"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "201c7ee3-56dc-51e9-ad55-69951192ab16"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "6928c696-e6d7-5a14-a004-15a497ac6562"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "2723ed09-37f6-522c-92b1-e518d4fe9315"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "3cf77140-2757-58eb-b4ea-f2a90cd766e7"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "5c06d516-e816-58d6-997e-f8fa91131ac7"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "c0cc09c3-1335-5eb4-8801-b3cdaf74f6fa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM2": {
                    "questionId": "HM2",
                    "chosenOptionIds": [
                        "de662c3f-66b4-566f-856d-2124e2725542"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "98ea07a0-ae95-570f-8d11-a3e7c682e986"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "a43647cb-a0e8-5705-adb9-a2b68877dc77"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c41974b6-d394-569c-8f45-49f79fcac5c6"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "3fd957d1-0cbb-5397-9812-353be5c2184e"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "b2cb304f-f035-53fc-8763-e2f91b031f9b"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "0170b4cf-0541-53cd-a9db-818d5067dc23"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "6b35cd3b-d67a-54af-94f6-e39ddf5af6c6"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "2a17c2fe-2592-58b1-98e9-3dc4168fe3e3"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "834b04f2-af09-5337-8fd5-be4a70c99e75"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "86277249-cf50-5f91-a27f-f3a69344e796"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "e95276b0-ee81-580b-b532-b0215f04abd5"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "4b8e4843-d260-59e5-8468-1303b1c3a638"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: N0N6P0Q aspect: simple_bd2 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "a16e8e14-524e-53df-b4ef-730a2c8e5f32"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "916d657d-9f2a-5451-8465-18e3bd863347"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "a0e528ca-2fbb-5da1-9398-0758f79755aa"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 540
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "db360e93-3616-5d89-be36-5ccee4aacf1c"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "a9525e10-4e34-5d6b-9c93-c7546ec49231"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "11f457f5-4c39-5458-ba9a-84478dd379ba"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "db7514e2-d317-55fb-82d3-2e4a49c2494b"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b",
                        "1d4beed1-78e5-531c-a255-643fac260dee"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "c9813959-ba61-5220-9e27-659abb256037"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "3e093dd3-857e-5004-a4fb-961fbfa59ece"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "adecf2d3-f302-50f8-a4a9-3ffac1789bc6"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "d362cd87-7515-551e-bc74-19ef903484aa"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "4896b504-2025-52c3-85e2-c6cf3205cef7"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "7599bcaa-89a5-5f71-80ea-b70c360bb44a"
                    ]
                },
                "BP101b": {
                    "questionId": "BP101b",
                    "chosenOptionIds": [
                        "31b2d2c8-8f28-514e-9e58-aa878f6c8761"
                    ]
                },
                "BP102b": {
                    "questionId": "BP102b",
                    "chosenOptionIds": [
                        "71338232-94be-5805-b822-94db8be9c5a1"
                    ]
                },
                "BP103b": {
                    "questionId": "BP103b",
                    "chosenOptionIds": [
                        "867b0ee4-73d9-5aac-b96d-bc2d85d5b183"
                    ]
                },
                "BP104b": {
                    "questionId": "BP104b",
                    "chosenOptionIds": [
                        "c59b5910-3279-509f-8175-f2fd0da2dc86"
                    ]
                },
                "BP87b": {
                    "questionId": "BP87b",
                    "chosenOptionIds": [
                        "659c0d9a-389d-5b62-b3ac-e385dfafe63c"
                    ]
                },
                "BP88b": {
                    "questionId": "BP88b",
                    "chosenOptionIds": [
                        "e29e159f-f691-5aa7-b5a0-d13fe2c54170"
                    ]
                },
                "BP89b": {
                    "questionId": "BP89b",
                    "chosenOptionIds": [
                        "db509963-d872-5b97-ba9b-a276f58e0189"
                    ]
                },
                "BP90b": {
                    "questionId": "BP90b",
                    "chosenOptionIds": [
                        "95c2822d-27ad-5bb0-982f-8c2b949c8918"
                    ]
                },
                "BP92b": {
                    "questionId": "BP92b",
                    "chosenOptionIds": [
                        "f59918ba-eb3a-5cf1-81aa-8b7f6ac0df5e"
                    ]
                },
                "BP94b": {
                    "questionId": "BP94b",
                    "chosenOptionIds": [
                        "038cb12b-2783-569a-b1d7-e28c65e591a3"
                    ]
                },
                "BP95b": {
                    "questionId": "BP95b",
                    "chosenOptionIds": [
                        "fb0bd4a7-2793-5b81-badc-fe81b4bc82dc"
                    ]
                },
                "BP96b": {
                    "questionId": "BP96b",
                    "chosenOptionIds": [
                        "8c3d3503-6334-55b9-b0f0-94c8e520094e"
                    ]
                },
                "BP97b": {
                    "questionId": "BP97b",
                    "chosenOptionIds": [
                        "6895096a-ef29-546e-9967-d7a23b4a2859"
                    ]
                },
                "BP98b": {
                    "questionId": "BP98b",
                    "chosenOptionIds": [
                        "01641419-fbfc-52a5-ae63-0fa736ff9847"
                    ]
                },
                "BP99.1b": {
                    "questionId": "BP99.1b",
                    "chosenOptionIds": [
                        "255f682d-5067-5a6d-835a-fe587b4169e6"
                    ]
                },
                "BP99.3b": {
                    "questionId": "BP99.3b",
                    "chosenOptionIds": [
                        "e4575ba2-20f0-519e-967b-d5769763cadc"
                    ]
                },
                "BP99b": {
                    "questionId": "BP99b",
                    "chosenOptionIds": [
                        "96faf550-f157-55b9-8f15-6be82cd511a8"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "BP_neg1": {
                    "questionId": "BP_neg1",
                    "chosenOptionIds": [
                        "119208b0-ff3b-5c43-80be-4f07e65b6248"
                    ]
                }
            }
        },
        {
            "name": "Mood test: K39V10L aspect: simple_bd2 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "0ec0e224-a5cf-5ece-b3ab-37061a600ace"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "45f0ce85-9e19-53da-bc0d-2af8b383318a"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "c0453e8e-7a9c-5bf5-b8a2-167a61a6387e"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "322210fb-135d-5ac8-9617-7fc4f85e016d"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 1
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "913bc1e9-8da1-54c7-b935-13fc5d48fbd9"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "9222be3e-443f-5146-9d69-d48fe9041ba0"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "77c6088d-2d8f-5c3b-89c9-79cb0d317226"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "1d4beed1-78e5-531c-a255-643fac260dee"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "f3efc764-ca53-52c3-9c3a-38c7f787d415"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "228ef7d9-008b-559d-ba14-6b928b486b38"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "3b216cf8-72e7-558c-bdd3-cc23a1865674"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "f52974b4-d0c2-589c-88d8-8628f43a2f5e",
                        "07106ab9-5949-5b5f-bc96-117139ebd397",
                        "44fd9eb3-aee2-5af0-b83c-dbbbf9514022"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "ccbdcbec-96d0-5362-977b-784289f5cfc0"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "4a0f1c4d-38cf-5de4-99e3-0ee818f9709c"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "e9eb902a-d327-5ec5-938c-dc317ca59b13"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "d3224e16-1b0c-5266-9e14-ac72d2b2e988"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "f2228920-3fec-5097-a63d-990c25d50c6c"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP105": {
                    "questionId": "BP105",
                    "chosenOptionIds": [
                        "64aa1680-6b5b-5a5b-bb92-75995d27ddcd"
                    ]
                },
                "BP12": {
                    "questionId": "BP12",
                    "chosenOptionIds": [
                        "1d6299ac-2ac6-51e2-9a3f-13e8d44e652a"
                    ]
                },
                "BP12.5": {
                    "questionId": "BP12.5",
                    "chosenOptionIds": [
                        "cd7ce00f-4e7c-57ac-9c6c-4137efa24c8c"
                    ]
                },
                "BP14": {
                    "questionId": "BP14",
                    "chosenOptionIds": [
                        "a3126449-b062-5872-80a1-5950acf6bfa4"
                    ]
                },
                "BP14.1": {
                    "questionId": "BP14.1",
                    "chosenOptionIds": [
                        "69f9a784-ce6d-5c82-bd3f-4c9c44bf5992"
                    ]
                },
                "BP14.2": {
                    "questionId": "BP14.2",
                    "freeValue": 21
                },
                "BP14.3": {
                    "questionId": "BP14.3",
                    "chosenOptionIds": [
                        "9f5fc152-75fb-59d8-8f40-7a6ca15c69cb"
                    ]
                },
                "BP16": {
                    "questionId": "BP16",
                    "chosenOptionIds": [
                        "fabceeea-8fea-5444-8b67-8f189f366c27"
                    ]
                },
                "BP16.0": {
                    "questionId": "BP16.0",
                    "chosenOptionIds": [
                        "4b26caa5-9124-51b2-ab08-2f4e977cee1f"
                    ]
                },
                "BP16.01": {
                    "questionId": "BP16.01",
                    "chosenOptionIds": [
                        "f81655d7-78e5-59b1-a7eb-90a2ec7a107d"
                    ]
                },
                "BP16.02": {
                    "questionId": "BP16.02",
                    "chosenOptionIds": [
                        "16a695c9-3ac1-5d34-b799-44602d85b556"
                    ]
                },
                "BP18": {
                    "questionId": "BP18",
                    "chosenOptionIds": [
                        "b577f323-ec54-5d25-a2b9-4f8de30d60a2"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 59
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "f03de28d-8f71-5af4-be2f-45f4e6092a3d"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "387b19c0-67d0-557e-9fba-ae065dcf3587"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "afb57366-c0b3-5f73-92eb-27bc133ad2ed"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "667ba57c-b1cd-5f9b-aa12-58e65a981910"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "5d5fa968-aeb3-569a-8a71-483ef04b9a6a"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "c211fabc-a62d-5440-8ddc-f57eb21d7679"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "46c90446-3ae9-53f5-9b57-ad19d66b5be6"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "d680a8d9-e35f-563f-9d1d-e7c3136aff6d"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "82997889-d795-572b-a597-e484ef078b39"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "ec84966e-bb9f-5ba9-a0cf-d5d3503a74e0"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "6928c696-e6d7-5a14-a004-15a497ac6562"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "4a7683c6-774e-5362-8060-740f61986c9b"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "58777a2e-4b97-5413-b340-efe722e45936"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "5c06d516-e816-58d6-997e-f8fa91131ac7"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "c0cc09c3-1335-5eb4-8801-b3cdaf74f6fa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM19": {
                    "questionId": "HM19",
                    "chosenOptionIds": [
                        "99f474d7-d509-592a-856a-afed343fb1b8"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "c3ee8981-dec3-5b3c-80ca-c1023f983a6d"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "696fdd9d-5ced-504b-a933-bb3e575b0099"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c41974b6-d394-569c-8f45-49f79fcac5c6"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "43c32319-c75f-5169-b0e5-713820721d4f"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "b2cb304f-f035-53fc-8763-e2f91b031f9b"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "0170b4cf-0541-53cd-a9db-818d5067dc23"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "6b35cd3b-d67a-54af-94f6-e39ddf5af6c6"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "2a17c2fe-2592-58b1-98e9-3dc4168fe3e3"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "834b04f2-af09-5337-8fd5-be4a70c99e75"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "86277249-cf50-5f91-a27f-f3a69344e796"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "8823c74f-ff7c-52d8-9c73-fd3b4180f48a"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "1d07911e-929d-5719-aeea-a68f593d18e8"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: N0NN50Q aspect: simple_bd2 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "45f0ce85-9e19-53da-bc0d-2af8b383318a"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "916d657d-9f2a-5451-8465-18e3bd863347"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "b74511c7-9f97-5c15-8bf5-6ec4357b4814"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 0.125
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "7a5958fc-e0f7-5d43-9cd0-abfe95cb7026"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "a9525e10-4e34-5d6b-9c93-c7546ec49231"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "11f457f5-4c39-5458-ba9a-84478dd379ba"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5c30c60e-d53e-5cc3-b4a9-fdad9bec4cb7"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "1d4beed1-78e5-531c-a255-643fac260dee",
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "f3efc764-ca53-52c3-9c3a-38c7f787d415"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "228ef7d9-008b-559d-ba14-6b928b486b38"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "d362cd87-7515-551e-bc74-19ef903484aa"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "4896b504-2025-52c3-85e2-c6cf3205cef7"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "7599bcaa-89a5-5f71-80ea-b70c360bb44a"
                    ]
                },
                "BP101b": {
                    "questionId": "BP101b",
                    "chosenOptionIds": [
                        "31b2d2c8-8f28-514e-9e58-aa878f6c8761"
                    ]
                },
                "BP102b": {
                    "questionId": "BP102b",
                    "chosenOptionIds": [
                        "00afc1e6-b78a-5223-a6e7-5d3aaab833e0"
                    ]
                },
                "BP103b": {
                    "questionId": "BP103b",
                    "chosenOptionIds": [
                        "867b0ee4-73d9-5aac-b96d-bc2d85d5b183"
                    ]
                },
                "BP104b": {
                    "questionId": "BP104b",
                    "chosenOptionIds": [
                        "c59b5910-3279-509f-8175-f2fd0da2dc86"
                    ]
                },
                "BP87b": {
                    "questionId": "BP87b",
                    "chosenOptionIds": [
                        "659c0d9a-389d-5b62-b3ac-e385dfafe63c"
                    ]
                },
                "BP88b": {
                    "questionId": "BP88b",
                    "chosenOptionIds": [
                        "e29e159f-f691-5aa7-b5a0-d13fe2c54170"
                    ]
                },
                "BP89b": {
                    "questionId": "BP89b",
                    "chosenOptionIds": [
                        "df5f6aa3-ab8c-5a4c-b9d9-e9a482aba1d7"
                    ]
                },
                "BP90b": {
                    "questionId": "BP90b",
                    "chosenOptionIds": [
                        "b4a79888-68e8-5b52-9a4e-2149be85f6e7"
                    ]
                },
                "BP92b": {
                    "questionId": "BP92b",
                    "chosenOptionIds": [
                        "dd87a55d-829b-54a2-a63d-5b8b634688ea"
                    ]
                },
                "BP94b": {
                    "questionId": "BP94b",
                    "chosenOptionIds": [
                        "038cb12b-2783-569a-b1d7-e28c65e591a3"
                    ]
                },
                "BP95b": {
                    "questionId": "BP95b",
                    "chosenOptionIds": [
                        "fb0bd4a7-2793-5b81-badc-fe81b4bc82dc"
                    ]
                },
                "BP96b": {
                    "questionId": "BP96b",
                    "chosenOptionIds": [
                        "477d4084-be50-50ae-9e88-dc9e03cdad29"
                    ]
                },
                "BP97b": {
                    "questionId": "BP97b",
                    "chosenOptionIds": [
                        "6895096a-ef29-546e-9967-d7a23b4a2859"
                    ]
                },
                "BP98b": {
                    "questionId": "BP98b",
                    "chosenOptionIds": [
                        "01641419-fbfc-52a5-ae63-0fa736ff9847"
                    ]
                },
                "BP99.3b": {
                    "questionId": "BP99.3b",
                    "chosenOptionIds": [
                        "d0adef9f-05e3-5ddf-a0f3-60eeb50d3862"
                    ]
                },
                "BP99b": {
                    "questionId": "BP99b",
                    "chosenOptionIds": [
                        "68860df1-e835-50d6-8eb2-f9d9f2f97efa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "BP_neg1": {
                    "questionId": "BP_neg1",
                    "chosenOptionIds": [
                        "119208b0-ff3b-5c43-80be-4f07e65b6248"
                    ]
                }
            }
        },
        {
            "name": "Mood test: 4EGQQ0P aspect: simple_bd2 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "0ec0e224-a5cf-5ece-b3ab-37061a600ace"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "a16e8e14-524e-53df-b4ef-730a2c8e5f32"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "916d657d-9f2a-5451-8465-18e3bd863347"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "b74511c7-9f97-5c15-8bf5-6ec4357b4814"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 365
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "913bc1e9-8da1-54c7-b935-13fc5d48fbd9"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "11f457f5-4c39-5458-ba9a-84478dd379ba"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5c30c60e-d53e-5cc3-b4a9-fdad9bec4cb7"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "1d4beed1-78e5-531c-a255-643fac260dee",
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b",
                        "9d1b858c-7064-5a84-80d6-786c18e603b6"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "f3efc764-ca53-52c3-9c3a-38c7f787d415"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "9506449e-b2aa-5498-b896-901dd248b1b3"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "228ef7d9-008b-559d-ba14-6b928b486b38"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "d362cd87-7515-551e-bc74-19ef903484aa"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "07106ab9-5949-5b5f-bc96-117139ebd397"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "83e6efd5-64a2-5f0e-a973-9278eae90cfb"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "4a0f1c4d-38cf-5de4-99e3-0ee818f9709c"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "6a5aa899-7b89-5661-a554-71b6058ad4a4"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "d3224e16-1b0c-5266-9e14-ac72d2b2e988"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "b24df75b-6bb9-5f5f-99e3-c883ec3644ec"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 19
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "c7bee7a0-3037-5eb1-96a1-85b884d7e1dd"
                    ]
                },
                "BP3.1": {
                    "questionId": "BP3.1",
                    "chosenOptionIds": [
                        "5bf1e313-2624-5b5f-9673-37ae9319ff0c"
                    ]
                },
                "BP3.2": {
                    "questionId": "BP3.2",
                    "chosenOptionIds": [
                        "f6654515-5729-5299-a4f0-da99056e7ac9"
                    ]
                },
                "BP3.3": {
                    "questionId": "BP3.3",
                    "freeValue": 30
                },
                "BP3.4": {
                    "questionId": "BP3.4",
                    "chosenOptionIds": [
                        "f304bf93-0c68-5da0-80ba-ccecc2d0fce1"
                    ]
                },
                "BP4": {
                    "questionId": "BP4",
                    "chosenOptionIds": [
                        "4884cc9c-7210-5173-9438-d1eae9e9a6a8"
                    ]
                },
                "BP4.5": {
                    "questionId": "BP4.5",
                    "chosenOptionIds": [
                        "1be4b3b1-3a88-5544-af54-ebdfee1ce891"
                    ]
                },
                "BP6.5": {
                    "questionId": "BP6.5",
                    "chosenOptionIds": [
                        "58656f9f-9267-5c1d-9132-f342d05dc39e"
                    ]
                },
                "BP6.7": {
                    "questionId": "BP6.7",
                    "chosenOptionIds": [
                        "ef07ce6c-9277-581a-bde7-14889359301b"
                    ]
                },
                "BP6.8": {
                    "questionId": "BP6.8",
                    "freeValue": 30
                },
                "BP6.9": {
                    "questionId": "BP6.9",
                    "chosenOptionIds": [
                        "155da231-22f3-50d9-8786-2860edd50aab"
                    ]
                },
                "BP8": {
                    "questionId": "BP8",
                    "chosenOptionIds": [
                        "87e0081b-9455-5ced-9225-10c2f70b1734"
                    ]
                },
                "BP8.0": {
                    "questionId": "BP8.0",
                    "chosenOptionIds": [
                        "3568cdb1-2500-512b-aada-2547c5bfd835"
                    ]
                },
                "BP8.01": {
                    "questionId": "BP8.01",
                    "chosenOptionIds": [
                        "5020bfdd-a280-56cf-b7f5-3b0b7e667165"
                    ]
                },
                "BP8.02": {
                    "questionId": "BP8.02",
                    "chosenOptionIds": [
                        "e88670a3-c6d8-5c96-914e-b9fc1818aeda"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "ad1cfcc2-c1dc-57ef-b964-17d355fa0402"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "a4ed572c-6aae-57f6-bc35-ea46929c1437"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "28c75e9e-94ef-5c50-8645-f4ff93ccf2ec"
                    ]
                },
                "BP9": {
                    "questionId": "BP9",
                    "chosenOptionIds": [
                        "becfe35c-2096-5848-87ae-3f763cf59dd6"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "5d5fa968-aeb3-569a-8a71-483ef04b9a6a"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "e22cf233-c174-52fb-af55-97bf9c46e971"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "46c90446-3ae9-53f5-9b57-ad19d66b5be6"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "ef5a7b22-b2b2-5a5b-8a80-ce5c251033fd"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "c88e3fb5-913a-5c5c-a42b-603f7bb60a40"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "732c51f9-a0b6-5951-bd7d-eaaec24316ba"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "6928c696-e6d7-5a14-a004-15a497ac6562"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "2723ed09-37f6-522c-92b1-e518d4fe9315"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "3cf77140-2757-58eb-b4ea-f2a90cd766e7"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "63509127-aadf-5fe2-8e1f-19d0d846d6ce"
                    ]
                },
                "BP99.1": {
                    "questionId": "BP99.1",
                    "chosenOptionIds": [
                        "34d19f12-207f-56ce-bde8-8d616f6e8b26"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "e81fe613-4923-55ed-bd6c-bd3f19e026f9"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM1": {
                    "questionId": "HM1",
                    "chosenOptionIds": [
                        "60caa355-cd97-591a-9344-1e9858939855"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "98ea07a0-ae95-570f-8d11-a3e7c682e986"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "a43647cb-a0e8-5705-adb9-a2b68877dc77"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c41974b6-d394-569c-8f45-49f79fcac5c6"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "43c32319-c75f-5169-b0e5-713820721d4f"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "b2cb304f-f035-53fc-8763-e2f91b031f9b"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "0170b4cf-0541-53cd-a9db-818d5067dc23"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "2be66549-5767-51b4-82ed-deeab0723f8d"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "2a17c2fe-2592-58b1-98e9-3dc4168fe3e3"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "834b04f2-af09-5337-8fd5-be4a70c99e75"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "fda00d52-c8be-5b69-bdbd-905c13dddd4e"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "8823c74f-ff7c-52d8-9c73-fd3b4180f48a"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "1d07911e-929d-5719-aeea-a68f593d18e8"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: 73ZJN0R aspect: simple_bd2 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "45f0ce85-9e19-53da-bc0d-2af8b383318a"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "916d657d-9f2a-5451-8465-18e3bd863347"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "b74511c7-9f97-5c15-8bf5-6ec4357b4814"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 0.08333333333333333
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "913bc1e9-8da1-54c7-b935-13fc5d48fbd9"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "a9525e10-4e34-5d6b-9c93-c7546ec49231"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "11f457f5-4c39-5458-ba9a-84478dd379ba"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5c30c60e-d53e-5cc3-b4a9-fdad9bec4cb7"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "9d1b858c-7064-5a84-80d6-786c18e603b6"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "f3efc764-ca53-52c3-9c3a-38c7f787d415"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "228ef7d9-008b-559d-ba14-6b928b486b38"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "3b216cf8-72e7-558c-bdd3-cc23a1865674"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "4896b504-2025-52c3-85e2-c6cf3205cef7"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "ccbdcbec-96d0-5362-977b-784289f5cfc0"
                    ]
                },
                "BP10": {
                    "questionId": "BP10",
                    "chosenOptionIds": [
                        "7ad54f0a-87a6-5e82-bfc3-44b540230082"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "4a0f1c4d-38cf-5de4-99e3-0ee818f9709c"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "e9eb902a-d327-5ec5-938c-dc317ca59b13"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "d3224e16-1b0c-5266-9e14-ac72d2b2e988"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "b24df75b-6bb9-5f5f-99e3-c883ec3644ec"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 39
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "c7bee7a0-3037-5eb1-96a1-85b884d7e1dd"
                    ]
                },
                "BP3.1": {
                    "questionId": "BP3.1",
                    "chosenOptionIds": [
                        "5bf1e313-2624-5b5f-9673-37ae9319ff0c"
                    ]
                },
                "BP3.2": {
                    "questionId": "BP3.2",
                    "chosenOptionIds": [
                        "ec333f8b-146a-5700-a15d-527032ceac3f"
                    ]
                },
                "BP3.3": {
                    "questionId": "BP3.3",
                    "freeValue": 0.08333333333333333
                },
                "BP3.4": {
                    "questionId": "BP3.4",
                    "chosenOptionIds": [
                        "f304bf93-0c68-5da0-80ba-ccecc2d0fce1"
                    ]
                },
                "BP4": {
                    "questionId": "BP4",
                    "chosenOptionIds": [
                        "9765aba8-7d55-5928-b79d-36aa58646036"
                    ]
                },
                "BP4.5": {
                    "questionId": "BP4.5",
                    "chosenOptionIds": [
                        "66a3460c-65ce-5a4c-9774-2e303ae4961e"
                    ]
                },
                "BP6": {
                    "questionId": "BP6",
                    "chosenOptionIds": [
                        "742a22d6-a570-5e3a-8e72-c001f1e9f98b"
                    ]
                },
                "BP6.1": {
                    "questionId": "BP6.1",
                    "chosenOptionIds": [
                        "430b68c6-29cf-5a15-ab4c-89bd842383bf"
                    ]
                },
                "BP6.2": {
                    "questionId": "BP6.2",
                    "freeValue": 0.08333333333333333
                },
                "BP6.3": {
                    "questionId": "BP6.3",
                    "chosenOptionIds": [
                        "a19e0696-e780-5235-88b9-f5c36ce0afc3"
                    ]
                },
                "BP8": {
                    "questionId": "BP8",
                    "chosenOptionIds": [
                        "87e0081b-9455-5ced-9225-10c2f70b1734"
                    ]
                },
                "BP8.0": {
                    "questionId": "BP8.0",
                    "chosenOptionIds": [
                        "3568cdb1-2500-512b-aada-2547c5bfd835"
                    ]
                },
                "BP8.01": {
                    "questionId": "BP8.01",
                    "chosenOptionIds": [
                        "5020bfdd-a280-56cf-b7f5-3b0b7e667165"
                    ]
                },
                "BP8.02": {
                    "questionId": "BP8.02",
                    "chosenOptionIds": [
                        "e88670a3-c6d8-5c96-914e-b9fc1818aeda"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "387b19c0-67d0-557e-9fba-ae065dcf3587"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "afb57366-c0b3-5f73-92eb-27bc133ad2ed"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "667ba57c-b1cd-5f9b-aa12-58e65a981910"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "fd30dc27-8e89-5d1a-9d95-8844603d4379"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "e22cf233-c174-52fb-af55-97bf9c46e971"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "46c90446-3ae9-53f5-9b57-ad19d66b5be6"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "d680a8d9-e35f-563f-9d1d-e7c3136aff6d"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "94795194-4a80-590f-be62-ee78f2fa0ff5"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "732c51f9-a0b6-5951-bd7d-eaaec24316ba"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "6928c696-e6d7-5a14-a004-15a497ac6562"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "17b4da6f-7135-523c-b90b-2fe3b66785ff"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "3cf77140-2757-58eb-b4ea-f2a90cd766e7"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "5c06d516-e816-58d6-997e-f8fa91131ac7"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "c0cc09c3-1335-5eb4-8801-b3cdaf74f6fa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM2": {
                    "questionId": "HM2",
                    "chosenOptionIds": [
                        "de662c3f-66b4-566f-856d-2124e2725542"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "98ea07a0-ae95-570f-8d11-a3e7c682e986"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "696fdd9d-5ced-504b-a933-bb3e575b0099"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c41974b6-d394-569c-8f45-49f79fcac5c6"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "43c32319-c75f-5169-b0e5-713820721d4f"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "b2cb304f-f035-53fc-8763-e2f91b031f9b"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "0170b4cf-0541-53cd-a9db-818d5067dc23"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "2be66549-5767-51b4-82ed-deeab0723f8d"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "2a17c2fe-2592-58b1-98e9-3dc4168fe3e3"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "7e32475a-3fcc-51ac-b414-ab46578efdfd"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "86277249-cf50-5f91-a27f-f3a69344e796"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "8823c74f-ff7c-52d8-9c73-fd3b4180f48a"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "1d07911e-929d-5719-aeea-a68f593d18e8"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: 6EDLX3M aspect: simple_bd2 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "0ec0e224-a5cf-5ece-b3ab-37061a600ace"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "a16e8e14-524e-53df-b4ef-730a2c8e5f32"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "eb68a88d-b49b-5cde-a9c6-5e4c78e27d19"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "a0e528ca-2fbb-5da1-9398-0758f79755aa"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 14
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "913bc1e9-8da1-54c7-b935-13fc5d48fbd9"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "11f457f5-4c39-5458-ba9a-84478dd379ba"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "db7514e2-d317-55fb-82d3-2e4a49c2494b"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b",
                        "1d4beed1-78e5-531c-a255-643fac260dee",
                        "9d1b858c-7064-5a84-80d6-786c18e603b6"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "dde1af45-4686-52c9-b960-369527b28f6c"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "adecf2d3-f302-50f8-a4a9-3ffac1789bc6"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "d362cd87-7515-551e-bc74-19ef903484aa"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "4896b504-2025-52c3-85e2-c6cf3205cef7"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "83e6efd5-64a2-5f0e-a973-9278eae90cfb"
                    ]
                },
                "BP10": {
                    "questionId": "BP10",
                    "chosenOptionIds": [
                        "7ad54f0a-87a6-5e82-bfc3-44b540230082"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "db7aa4b7-99a5-5475-a4e8-a744f3ef5a4a"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "e9eb902a-d327-5ec5-938c-dc317ca59b13"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "1daccd4c-e5ff-5f18-ba55-7c2d2d93b849"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "b24df75b-6bb9-5f5f-99e3-c883ec3644ec"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 21
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "c7bee7a0-3037-5eb1-96a1-85b884d7e1dd"
                    ]
                },
                "BP3.1": {
                    "questionId": "BP3.1",
                    "chosenOptionIds": [
                        "c053dd45-79bd-59bf-82df-7e9ecd85dc11"
                    ]
                },
                "BP3.2": {
                    "questionId": "BP3.2",
                    "chosenOptionIds": [
                        "c3319113-403a-5ecb-aa8a-071221d92c50"
                    ]
                },
                "BP3.3": {
                    "questionId": "BP3.3",
                    "freeValue": 180
                },
                "BP3.4": {
                    "questionId": "BP3.4",
                    "chosenOptionIds": [
                        "f304bf93-0c68-5da0-80ba-ccecc2d0fce1"
                    ]
                },
                "BP4": {
                    "questionId": "BP4",
                    "chosenOptionIds": [
                        "9765aba8-7d55-5928-b79d-36aa58646036"
                    ]
                },
                "BP4.5": {
                    "questionId": "BP4.5",
                    "chosenOptionIds": [
                        "1be4b3b1-3a88-5544-af54-ebdfee1ce891"
                    ]
                },
                "BP6": {
                    "questionId": "BP6",
                    "chosenOptionIds": [
                        "742a22d6-a570-5e3a-8e72-c001f1e9f98b"
                    ]
                },
                "BP6.1": {
                    "questionId": "BP6.1",
                    "chosenOptionIds": [
                        "77b4d620-93e4-5bc5-baad-4995d168f392"
                    ]
                },
                "BP6.2": {
                    "questionId": "BP6.2",
                    "freeValue": 1
                },
                "BP6.3": {
                    "questionId": "BP6.3",
                    "chosenOptionIds": [
                        "df17fdbe-e756-5ece-ad3d-19cafeef31d1"
                    ]
                },
                "BP8": {
                    "questionId": "BP8",
                    "chosenOptionIds": [
                        "87e0081b-9455-5ced-9225-10c2f70b1734"
                    ]
                },
                "BP8.0": {
                    "questionId": "BP8.0",
                    "chosenOptionIds": [
                        "3568cdb1-2500-512b-aada-2547c5bfd835"
                    ]
                },
                "BP8.01": {
                    "questionId": "BP8.01",
                    "chosenOptionIds": [
                        "e12f28e7-b4ae-57af-96d7-8d83e672785a"
                    ]
                },
                "BP8.02": {
                    "questionId": "BP8.02",
                    "chosenOptionIds": [
                        "e88670a3-c6d8-5c96-914e-b9fc1818aeda"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "e620af65-9b5b-57e6-b13a-65616e67e084"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "a4ed572c-6aae-57f6-bc35-ea46929c1437"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "28c75e9e-94ef-5c50-8645-f4ff93ccf2ec"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "a2056280-4b54-5258-8bb4-407b521fff4b"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "d9fb150b-68b8-50ed-bb1d-eafd41f63f3b"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "46c90446-3ae9-53f5-9b57-ad19d66b5be6"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "63f0b17a-9832-5f0f-8630-e74f1a4fdfe7"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "f35fe383-d2aa-51c4-9fde-961b1de6d52b"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "2555d898-6736-5804-951e-e3c438313029"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "dbab3669-e910-5267-b7b1-fcb661ea7e81"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "4a7683c6-774e-5362-8060-740f61986c9b"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "b14d53e4-f898-52ac-87d7-3aa62abc9d7b"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "63509127-aadf-5fe2-8e1f-19d0d846d6ce"
                    ]
                },
                "BP99.1": {
                    "questionId": "BP99.1",
                    "chosenOptionIds": [
                        "74809c02-f47f-5955-a416-b40fc66ff214"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "a020ed85-ec9d-5778-8006-41f634cab09d"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM2": {
                    "questionId": "HM2",
                    "chosenOptionIds": [
                        "2b56aa7d-493f-5a18-aa9f-f97304f89130"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "6b99de8b-634a-5eb0-bc65-bdf30eb9d433"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "8f7acbc1-9ebe-5a52-95ce-b50d62c27345"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c41974b6-d394-569c-8f45-49f79fcac5c6"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "c8c8c192-aea3-5585-b11f-9d13faf4367d"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "65bee7d4-bf7f-5a21-9d92-f16651b0d1aa"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "366c6a1b-c8b2-5faf-9d2f-72dbde24e869"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "7b310da1-94e3-5d45-a6c3-3905eeb28ec6"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "73b26794-a969-527b-8268-f17c4e485bda"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "7e32475a-3fcc-51ac-b414-ab46578efdfd"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "fda00d52-c8be-5b69-bdbd-905c13dddd4e"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "e95276b0-ee81-580b-b532-b0215f04abd5"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "7d6921a2-7e63-5f12-9c75-dff8b592fabc"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: 73ZL2ER aspect: simple_bd2 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "0ec0e224-a5cf-5ece-b3ab-37061a600ace"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "3bd18f66-6f1e-5ea4-ae24-2990ed197bef"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "9b47900d-e5a7-5e58-830b-d9b3130a7990"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "a0e528ca-2fbb-5da1-9398-0758f79755aa"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 360
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "913bc1e9-8da1-54c7-b935-13fc5d48fbd9"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "de11fb6b-7e7d-52b1-9d09-2d6debc53314"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "db7514e2-d317-55fb-82d3-2e4a49c2494b"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b",
                        "1d4beed1-78e5-531c-a255-643fac260dee"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "c9813959-ba61-5220-9e27-659abb256037"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "228ef7d9-008b-559d-ba14-6b928b486b38"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "d362cd87-7515-551e-bc74-19ef903484aa"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "13f8bac1-d0f5-539b-8596-1c55821bea46"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "44fd9eb3-aee2-5af0-b83c-dbbbf9514022"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "83e6efd5-64a2-5f0e-a973-9278eae90cfb"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "db7aa4b7-99a5-5475-a4e8-a744f3ef5a4a"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "d8c1997f-d722-536c-ad9d-296b5e10ed04"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "1daccd4c-e5ff-5f18-ba55-7c2d2d93b849"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "f2228920-3fec-5097-a63d-990c25d50c6c"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP105": {
                    "questionId": "BP105",
                    "chosenOptionIds": [
                        "978f2ff4-94d9-5f7e-9126-e8657be0c7ca"
                    ]
                },
                "BP11": {
                    "questionId": "BP11",
                    "chosenOptionIds": [
                        "e54bdb41-2f8b-5524-b99b-415c31ee1576"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 15
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "c7bee7a0-3037-5eb1-96a1-85b884d7e1dd"
                    ]
                },
                "BP3.1": {
                    "questionId": "BP3.1",
                    "chosenOptionIds": [
                        "b56edfbb-cf11-50ee-9149-9e36f4cc1ea0"
                    ]
                },
                "BP3.2": {
                    "questionId": "BP3.2",
                    "chosenOptionIds": [
                        "ec333f8b-146a-5700-a15d-527032ceac3f"
                    ]
                },
                "BP3.3": {
                    "questionId": "BP3.3",
                    "freeValue": 3
                },
                "BP3.4": {
                    "questionId": "BP3.4",
                    "chosenOptionIds": [
                        "93412936-97c0-5128-ad67-3fa955db1300"
                    ]
                },
                "BP4": {
                    "questionId": "BP4",
                    "chosenOptionIds": [
                        "f4705d21-04ac-574a-90f3-5e73c089a3ff"
                    ]
                },
                "BP7": {
                    "questionId": "BP7",
                    "chosenOptionIds": [
                        "0c0ab687-08ee-5bfd-8795-5e0284c1e430"
                    ]
                },
                "BP7.1": {
                    "questionId": "BP7.1",
                    "chosenOptionIds": [
                        "6b62060b-0aee-5ec9-beef-85c908359e3d"
                    ]
                },
                "BP7.2": {
                    "questionId": "BP7.2",
                    "freeValue": 5
                },
                "BP7.3": {
                    "questionId": "BP7.3",
                    "chosenOptionIds": [
                        "455f915a-3cf9-5453-8bdb-a0fc0d91ca60"
                    ]
                },
                "BP8": {
                    "questionId": "BP8",
                    "chosenOptionIds": [
                        "87e0081b-9455-5ced-9225-10c2f70b1734"
                    ]
                },
                "BP8.0": {
                    "questionId": "BP8.0",
                    "chosenOptionIds": [
                        "3568cdb1-2500-512b-aada-2547c5bfd835"
                    ]
                },
                "BP8.01": {
                    "questionId": "BP8.01",
                    "chosenOptionIds": [
                        "a5d71cc4-3c6f-5e37-a507-1dfa312124b8"
                    ]
                },
                "BP8.02": {
                    "questionId": "BP8.02",
                    "chosenOptionIds": [
                        "1ff60197-2010-588f-bf6a-9be48f2b3359"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "e620af65-9b5b-57e6-b13a-65616e67e084"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "a4ed572c-6aae-57f6-bc35-ea46929c1437"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "2404146e-cd56-5356-a23b-6427b4bbba06"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "a2056280-4b54-5258-8bb4-407b521fff4b"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "33e48f82-ae80-5f20-a3a5-79692d21ad1a"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "0feda24f-7b01-557b-8ca5-10eb924cbdac"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "17619692-6d18-5558-99da-766a1c275e03"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "f35fe383-d2aa-51c4-9fde-961b1de6d52b"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "2555d898-6736-5804-951e-e3c438313029"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "9b27e3ff-f8ce-54c5-9220-0479ae53e889"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "4a7683c6-774e-5362-8060-740f61986c9b"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "58777a2e-4b97-5413-b340-efe722e45936"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "63509127-aadf-5fe2-8e1f-19d0d846d6ce"
                    ]
                },
                "BP99.1": {
                    "questionId": "BP99.1",
                    "chosenOptionIds": [
                        "34d19f12-207f-56ce-bde8-8d616f6e8b26"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "e81fe613-4923-55ed-bd6c-bd3f19e026f9"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "0f8903ea-258c-54c8-9d4d-643924d3c234"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "9d212885-9b07-5623-8234-226adff08f6d"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "3ab9515b-73f0-5fcf-a4d0-aebbc24c2721"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "0e9545ba-3f35-52b0-9a31-7fe54c933928"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "eb41dc92-16ec-5abf-a841-e1d0b262083e"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "57de31c8-9bf0-5003-9dbb-0e8559f994dd"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "7b310da1-94e3-5d45-a6c3-3905eeb28ec6"
                    ]
                },
                "HM3": {
                    "questionId": "HM3",
                    "chosenOptionIds": [
                        "7823432e-ad44-5fbe-9781-48d9ce136451"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "0983ccf5-a426-53d0-adbb-1baf6832190c"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "834b04f2-af09-5337-8fd5-be4a70c99e75"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "fda00d52-c8be-5b69-bdbd-905c13dddd4e"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "bde4e5f3-19e4-571f-84a2-5b9d188d74ef"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "4b8e4843-d260-59e5-8468-1303b1c3a638"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: XE7ZR3L aspect: simple_bd2 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "0ec0e224-a5cf-5ece-b3ab-37061a600ace"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "45f0ce85-9e19-53da-bc0d-2af8b383318a"
                    ]
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "7a5958fc-e0f7-5d43-9cd0-abfe95cb7026"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "9222be3e-443f-5146-9d69-d48fe9041ba0"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5c30c60e-d53e-5cc3-b4a9-fdad9bec4cb7"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b",
                        "ba6dc2ba-3c20-57b6-a276-32c863109ff6",
                        "9d1b858c-7064-5a84-80d6-786c18e603b6"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "c9813959-ba61-5220-9e27-659abb256037"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "ef6fddab-7a07-5f20-af20-74f2444eee53"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "3b216cf8-72e7-558c-bdd3-cc23a1865674"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "4896b504-2025-52c3-85e2-c6cf3205cef7"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "83e6efd5-64a2-5f0e-a973-9278eae90cfb"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "db7aa4b7-99a5-5475-a4e8-a744f3ef5a4a"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "e9eb902a-d327-5ec5-938c-dc317ca59b13"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "7230003d-a290-5a65-ae6b-b7d564b5db38"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "b24df75b-6bb9-5f5f-99e3-c883ec3644ec"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 17
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "c7bee7a0-3037-5eb1-96a1-85b884d7e1dd"
                    ]
                },
                "BP3.1": {
                    "questionId": "BP3.1",
                    "chosenOptionIds": [
                        "c31cf816-04e0-52f4-8da3-04437042c014"
                    ]
                },
                "BP3.2": {
                    "questionId": "BP3.2",
                    "chosenOptionIds": [
                        "c3319113-403a-5ecb-aa8a-071221d92c50"
                    ]
                },
                "BP3.3": {
                    "questionId": "BP3.3",
                    "freeValue": 7
                },
                "BP3.4": {
                    "questionId": "BP3.4",
                    "chosenOptionIds": [
                        "6ec82bbf-7582-5509-ace2-5ecf0ce82ec3"
                    ]
                },
                "BP4": {
                    "questionId": "BP4",
                    "chosenOptionIds": [
                        "4884cc9c-7210-5173-9438-d1eae9e9a6a8"
                    ]
                },
                "BP4.5": {
                    "questionId": "BP4.5",
                    "chosenOptionIds": [
                        "1be4b3b1-3a88-5544-af54-ebdfee1ce891"
                    ]
                },
                "BP6.5": {
                    "questionId": "BP6.5",
                    "chosenOptionIds": [
                        "e96c7a38-d74e-5087-b14b-21d3177ce73e"
                    ]
                },
                "BP6.7": {
                    "questionId": "BP6.7",
                    "chosenOptionIds": [
                        "ef07ce6c-9277-581a-bde7-14889359301b"
                    ]
                },
                "BP6.8": {
                    "questionId": "BP6.8",
                    "freeValue": 7
                },
                "BP6.9": {
                    "questionId": "BP6.9",
                    "chosenOptionIds": [
                        "75d51935-7fc0-5e05-813c-62bbdd8f8335"
                    ]
                },
                "BP8": {
                    "questionId": "BP8",
                    "chosenOptionIds": [
                        "87e0081b-9455-5ced-9225-10c2f70b1734"
                    ]
                },
                "BP8.0": {
                    "questionId": "BP8.0",
                    "chosenOptionIds": [
                        "3568cdb1-2500-512b-aada-2547c5bfd835"
                    ]
                },
                "BP8.01": {
                    "questionId": "BP8.01",
                    "chosenOptionIds": [
                        "5020bfdd-a280-56cf-b7f5-3b0b7e667165"
                    ]
                },
                "BP8.02": {
                    "questionId": "BP8.02",
                    "chosenOptionIds": [
                        "e88670a3-c6d8-5c96-914e-b9fc1818aeda"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "9ae96e50-5c86-58a7-a845-fb6347c7edd1"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "f501dc76-7fcd-586b-ba1d-ad70ab8e8d33"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "92e118de-38b9-5d5a-96ba-7b50547c0621"
                    ]
                },
                "BP9": {
                    "questionId": "BP9",
                    "chosenOptionIds": [
                        "becfe35c-2096-5848-87ae-3f763cf59dd6"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "93bfeb6e-215e-5f4b-b6de-0bc364e05be5"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "e22cf233-c174-52fb-af55-97bf9c46e971"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "0feda24f-7b01-557b-8ca5-10eb924cbdac"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "ef5a7b22-b2b2-5a5b-8a80-ce5c251033fd"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "94795194-4a80-590f-be62-ee78f2fa0ff5"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "201c7ee3-56dc-51e9-ad55-69951192ab16"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "de2ea429-4fcd-53b8-8d9f-ebb69a637a02"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "6ade3a8d-8516-5a88-baa0-f996f32730d2"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "2723ed09-37f6-522c-92b1-e518d4fe9315"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "58777a2e-4b97-5413-b340-efe722e45936"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "63509127-aadf-5fe2-8e1f-19d0d846d6ce",
                        "36502f0b-4563-5e03-80f2-a46dabeeae74"
                    ]
                },
                "BP99.1": {
                    "questionId": "BP99.1",
                    "chosenOptionIds": [
                        "34d19f12-207f-56ce-bde8-8d616f6e8b26"
                    ]
                },
                "BP99.2": {
                    "questionId": "BP99.2",
                    "chosenOptionIds": [
                        "ac6c9baf-e6df-5453-8665-0b796e1b83f8"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "a020ed85-ec9d-5778-8006-41f634cab09d"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM1": {
                    "questionId": "HM1",
                    "chosenOptionIds": [
                        "60caa355-cd97-591a-9344-1e9858939855"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "c3ee8981-dec3-5b3c-80ca-c1023f983a6d"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "a43647cb-a0e8-5705-adb9-a2b68877dc77"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "5574c021-c3c4-513a-a4b6-5ee416e8aa9e"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "3fd957d1-0cbb-5397-9812-353be5c2184e"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "d2c670c4-b05b-597a-a0af-5342230535a0"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "bcd05aa7-d9e3-5bdc-8661-0daa33c7b9b3"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "2be66549-5767-51b4-82ed-deeab0723f8d"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "0983ccf5-a426-53d0-adbb-1baf6832190c"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "e979459c-cb23-5cea-bedd-432a62288787"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "fa32b497-4a4b-5076-a391-b6dffa8e1851"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "bde4e5f3-19e4-571f-84a2-5b9d188d74ef"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "7d6921a2-7e63-5f12-9c75-dff8b592fabc"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: Y32QME8 aspect: simple_bd2 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "0ec0e224-a5cf-5ece-b3ab-37061a600ace"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "a16e8e14-524e-53df-b4ef-730a2c8e5f32"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "916d657d-9f2a-5451-8465-18e3bd863347"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "b74511c7-9f97-5c15-8bf5-6ec4357b4814"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 365
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "7a5958fc-e0f7-5d43-9cd0-abfe95cb7026"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "9222be3e-443f-5146-9d69-d48fe9041ba0"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5c30c60e-d53e-5cc3-b4a9-fdad9bec4cb7"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "1d4beed1-78e5-531c-a255-643fac260dee"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "f3efc764-ca53-52c3-9c3a-38c7f787d415"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "ef6fddab-7a07-5f20-af20-74f2444eee53"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "3b216cf8-72e7-558c-bdd3-cc23a1865674"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "44fd9eb3-aee2-5af0-b83c-dbbbf9514022"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "7599bcaa-89a5-5f71-80ea-b70c360bb44a"
                    ]
                },
                "BP101b": {
                    "questionId": "BP101b",
                    "chosenOptionIds": [
                        "cb80bcac-db78-55bd-b051-bdd00801fd54"
                    ]
                },
                "BP102b": {
                    "questionId": "BP102b",
                    "chosenOptionIds": [
                        "71338232-94be-5805-b822-94db8be9c5a1"
                    ]
                },
                "BP103b": {
                    "questionId": "BP103b",
                    "chosenOptionIds": [
                        "867b0ee4-73d9-5aac-b96d-bc2d85d5b183"
                    ]
                },
                "BP104b": {
                    "questionId": "BP104b",
                    "chosenOptionIds": [
                        "c59b5910-3279-509f-8175-f2fd0da2dc86"
                    ]
                },
                "BP87b": {
                    "questionId": "BP87b",
                    "chosenOptionIds": [
                        "7949bd96-3757-5573-bd89-3a91a383cc99"
                    ]
                },
                "BP88b": {
                    "questionId": "BP88b",
                    "chosenOptionIds": [
                        "fa24b924-a8e1-5f02-893b-3d9643007991"
                    ]
                },
                "BP89b": {
                    "questionId": "BP89b",
                    "chosenOptionIds": [
                        "41d2829a-3971-552f-8c5a-c77e7d0073e7"
                    ]
                },
                "BP90b": {
                    "questionId": "BP90b",
                    "chosenOptionIds": [
                        "95c2822d-27ad-5bb0-982f-8c2b949c8918"
                    ]
                },
                "BP92b": {
                    "questionId": "BP92b",
                    "chosenOptionIds": [
                        "b2f4c490-30df-5c8f-87c0-bc4acbcfb8e0"
                    ]
                },
                "BP94b": {
                    "questionId": "BP94b",
                    "chosenOptionIds": [
                        "038cb12b-2783-569a-b1d7-e28c65e591a3"
                    ]
                },
                "BP95b": {
                    "questionId": "BP95b",
                    "chosenOptionIds": [
                        "fb0bd4a7-2793-5b81-badc-fe81b4bc82dc"
                    ]
                },
                "BP96b": {
                    "questionId": "BP96b",
                    "chosenOptionIds": [
                        "8c3d3503-6334-55b9-b0f0-94c8e520094e"
                    ]
                },
                "BP97b": {
                    "questionId": "BP97b",
                    "chosenOptionIds": [
                        "6895096a-ef29-546e-9967-d7a23b4a2859"
                    ]
                },
                "BP98b": {
                    "questionId": "BP98b",
                    "chosenOptionIds": [
                        "01641419-fbfc-52a5-ae63-0fa736ff9847"
                    ]
                },
                "BP99.3b": {
                    "questionId": "BP99.3b",
                    "chosenOptionIds": [
                        "bf30a6c2-f8e2-5305-b4d4-7ad6227449d3"
                    ]
                },
                "BP99b": {
                    "questionId": "BP99b",
                    "chosenOptionIds": [
                        "68860df1-e835-50d6-8eb2-f9d9f2f97efa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "BP_neg1": {
                    "questionId": "BP_neg1",
                    "chosenOptionIds": [
                        "119208b0-ff3b-5c43-80be-4f07e65b6248"
                    ]
                }
            }
        },
        {
            "name": "Mood test: 10QKZ0P aspect: simple_bd2 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "0ec0e224-a5cf-5ece-b3ab-37061a600ace"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "a16e8e14-524e-53df-b4ef-730a2c8e5f32"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "9b47900d-e5a7-5e58-830b-d9b3130a7990"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "b74511c7-9f97-5c15-8bf5-6ec4357b4814"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 2920
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "913bc1e9-8da1-54c7-b935-13fc5d48fbd9"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "11f457f5-4c39-5458-ba9a-84478dd379ba"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5c30c60e-d53e-5cc3-b4a9-fdad9bec4cb7"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "9d1b858c-7064-5a84-80d6-786c18e603b6",
                        "ba6dc2ba-3c20-57b6-a276-32c863109ff6",
                        "1d4beed1-78e5-531c-a255-643fac260dee"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "c9813959-ba61-5220-9e27-659abb256037"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "ef6fddab-7a07-5f20-af20-74f2444eee53"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "d362cd87-7515-551e-bc74-19ef903484aa"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "44fd9eb3-aee2-5af0-b83c-dbbbf9514022",
                        "07106ab9-5949-5b5f-bc96-117139ebd397"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "7599bcaa-89a5-5f71-80ea-b70c360bb44a"
                    ]
                },
                "BP101b": {
                    "questionId": "BP101b",
                    "chosenOptionIds": [
                        "cb80bcac-db78-55bd-b051-bdd00801fd54"
                    ]
                },
                "BP102b": {
                    "questionId": "BP102b",
                    "chosenOptionIds": [
                        "71338232-94be-5805-b822-94db8be9c5a1"
                    ]
                },
                "BP103b": {
                    "questionId": "BP103b",
                    "chosenOptionIds": [
                        "867b0ee4-73d9-5aac-b96d-bc2d85d5b183"
                    ]
                },
                "BP104b": {
                    "questionId": "BP104b",
                    "chosenOptionIds": [
                        "c59b5910-3279-509f-8175-f2fd0da2dc86"
                    ]
                },
                "BP87b": {
                    "questionId": "BP87b",
                    "chosenOptionIds": [
                        "7949bd96-3757-5573-bd89-3a91a383cc99"
                    ]
                },
                "BP88b": {
                    "questionId": "BP88b",
                    "chosenOptionIds": [
                        "fa24b924-a8e1-5f02-893b-3d9643007991"
                    ]
                },
                "BP89b": {
                    "questionId": "BP89b",
                    "chosenOptionIds": [
                        "41d2829a-3971-552f-8c5a-c77e7d0073e7"
                    ]
                },
                "BP90b": {
                    "questionId": "BP90b",
                    "chosenOptionIds": [
                        "95c2822d-27ad-5bb0-982f-8c2b949c8918"
                    ]
                },
                "BP92b": {
                    "questionId": "BP92b",
                    "chosenOptionIds": [
                        "b2f4c490-30df-5c8f-87c0-bc4acbcfb8e0"
                    ]
                },
                "BP94b": {
                    "questionId": "BP94b",
                    "chosenOptionIds": [
                        "038cb12b-2783-569a-b1d7-e28c65e591a3"
                    ]
                },
                "BP95b": {
                    "questionId": "BP95b",
                    "chosenOptionIds": [
                        "fb0bd4a7-2793-5b81-badc-fe81b4bc82dc"
                    ]
                },
                "BP96b": {
                    "questionId": "BP96b",
                    "chosenOptionIds": [
                        "8c3d3503-6334-55b9-b0f0-94c8e520094e"
                    ]
                },
                "BP97b": {
                    "questionId": "BP97b",
                    "chosenOptionIds": [
                        "6895096a-ef29-546e-9967-d7a23b4a2859"
                    ]
                },
                "BP98b": {
                    "questionId": "BP98b",
                    "chosenOptionIds": [
                        "01641419-fbfc-52a5-ae63-0fa736ff9847"
                    ]
                },
                "BP99.3b": {
                    "questionId": "BP99.3b",
                    "chosenOptionIds": [
                        "bf30a6c2-f8e2-5305-b4d4-7ad6227449d3"
                    ]
                },
                "BP99b": {
                    "questionId": "BP99b",
                    "chosenOptionIds": [
                        "68860df1-e835-50d6-8eb2-f9d9f2f97efa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "BP_neg1": {
                    "questionId": "BP_neg1",
                    "chosenOptionIds": [
                        "119208b0-ff3b-5c43-80be-4f07e65b6248"
                    ]
                }
            }
        },
        {
            "name": "Mood test: Z35X10O aspect: simple_bd2 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "45f0ce85-9e19-53da-bc0d-2af8b383318a"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "eb68a88d-b49b-5cde-a9c6-5e4c78e27d19"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "826538fc-8a97-55e4-8013-fe6a93f8e451"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 1095
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "db360e93-3616-5d89-be36-5ccee4aacf1c"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "a9525e10-4e34-5d6b-9c93-c7546ec49231"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "de11fb6b-7e7d-52b1-9d09-2d6debc53314"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5c30c60e-d53e-5cc3-b4a9-fdad9bec4cb7"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "9d1b858c-7064-5a84-80d6-786c18e603b6"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "dde1af45-4686-52c9-b960-369527b28f6c"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "228ef7d9-008b-559d-ba14-6b928b486b38"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "d362cd87-7515-551e-bc74-19ef903484aa"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "8c599171-d554-5547-9f54-2066c1a711dd"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "7599bcaa-89a5-5f71-80ea-b70c360bb44a"
                    ]
                },
                "BP101b": {
                    "questionId": "BP101b",
                    "chosenOptionIds": [
                        "31b2d2c8-8f28-514e-9e58-aa878f6c8761"
                    ]
                },
                "BP102b": {
                    "questionId": "BP102b",
                    "chosenOptionIds": [
                        "71338232-94be-5805-b822-94db8be9c5a1"
                    ]
                },
                "BP103b": {
                    "questionId": "BP103b",
                    "chosenOptionIds": [
                        "867b0ee4-73d9-5aac-b96d-bc2d85d5b183"
                    ]
                },
                "BP104b": {
                    "questionId": "BP104b",
                    "chosenOptionIds": [
                        "c59b5910-3279-509f-8175-f2fd0da2dc86"
                    ]
                },
                "BP87b": {
                    "questionId": "BP87b",
                    "chosenOptionIds": [
                        "fb568891-2ea3-5200-bbdb-ac0695f30b02"
                    ]
                },
                "BP88b": {
                    "questionId": "BP88b",
                    "chosenOptionIds": [
                        "fa24b924-a8e1-5f02-893b-3d9643007991"
                    ]
                },
                "BP89b": {
                    "questionId": "BP89b",
                    "chosenOptionIds": [
                        "db509963-d872-5b97-ba9b-a276f58e0189"
                    ]
                },
                "BP90b": {
                    "questionId": "BP90b",
                    "chosenOptionIds": [
                        "95c2822d-27ad-5bb0-982f-8c2b949c8918"
                    ]
                },
                "BP92b": {
                    "questionId": "BP92b",
                    "chosenOptionIds": [
                        "f59918ba-eb3a-5cf1-81aa-8b7f6ac0df5e"
                    ]
                },
                "BP94b": {
                    "questionId": "BP94b",
                    "chosenOptionIds": [
                        "24f97aee-7df6-5c11-b615-f5064af38f63"
                    ]
                },
                "BP95b": {
                    "questionId": "BP95b",
                    "chosenOptionIds": [
                        "fb0bd4a7-2793-5b81-badc-fe81b4bc82dc"
                    ]
                },
                "BP96b": {
                    "questionId": "BP96b",
                    "chosenOptionIds": [
                        "8c3d3503-6334-55b9-b0f0-94c8e520094e"
                    ]
                },
                "BP97b": {
                    "questionId": "BP97b",
                    "chosenOptionIds": [
                        "8fa0f169-f510-5c9e-a4be-fbcc964f7480"
                    ]
                },
                "BP98b": {
                    "questionId": "BP98b",
                    "chosenOptionIds": [
                        "01641419-fbfc-52a5-ae63-0fa736ff9847"
                    ]
                },
                "BP99.3b": {
                    "questionId": "BP99.3b",
                    "chosenOptionIds": [
                        "bf30a6c2-f8e2-5305-b4d4-7ad6227449d3"
                    ]
                },
                "BP99b": {
                    "questionId": "BP99b",
                    "chosenOptionIds": [
                        "68860df1-e835-50d6-8eb2-f9d9f2f97efa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "BP_neg1": {
                    "questionId": "BP_neg1",
                    "chosenOptionIds": [
                        "119208b0-ff3b-5c43-80be-4f07e65b6248"
                    ]
                }
            }
        },
        {
            "name": "Mood test: ME84L3W aspect: simple_bd2 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "45f0ce85-9e19-53da-bc0d-2af8b383318a"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "c0453e8e-7a9c-5bf5-b8a2-167a61a6387e"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "322210fb-135d-5ac8-9617-7fc4f85e016d"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 0
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "7a5958fc-e0f7-5d43-9cd0-abfe95cb7026"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "a9525e10-4e34-5d6b-9c93-c7546ec49231"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "11f457f5-4c39-5458-ba9a-84478dd379ba"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5c30c60e-d53e-5cc3-b4a9-fdad9bec4cb7"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "a449e4ae-663b-50fb-9b62-1708b0a32669"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "f3efc764-ca53-52c3-9c3a-38c7f787d415"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "228ef7d9-008b-559d-ba14-6b928b486b38"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "3b216cf8-72e7-558c-bdd3-cc23a1865674"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "4896b504-2025-52c3-85e2-c6cf3205cef7"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "ccbdcbec-96d0-5362-977b-784289f5cfc0"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "4a0f1c4d-38cf-5de4-99e3-0ee818f9709c"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "e9eb902a-d327-5ec5-938c-dc317ca59b13"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "1daccd4c-e5ff-5f18-ba55-7c2d2d93b849"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "b24df75b-6bb9-5f5f-99e3-c883ec3644ec"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP12": {
                    "questionId": "BP12",
                    "chosenOptionIds": [
                        "1d6299ac-2ac6-51e2-9a3f-13e8d44e652a"
                    ]
                },
                "BP12.5": {
                    "questionId": "BP12.5",
                    "chosenOptionIds": [
                        "cd7ce00f-4e7c-57ac-9c6c-4137efa24c8c"
                    ]
                },
                "BP14": {
                    "questionId": "BP14",
                    "chosenOptionIds": [
                        "97bbe87b-c5ff-51c1-a99c-cec263cff7d4"
                    ]
                },
                "BP14.1": {
                    "questionId": "BP14.1",
                    "chosenOptionIds": [
                        "f2b7f127-8ab7-5609-8820-43158baee15c"
                    ]
                },
                "BP14.2": {
                    "questionId": "BP14.2",
                    "freeValue": 0.041666666666666664
                },
                "BP14.3": {
                    "questionId": "BP14.3",
                    "chosenOptionIds": [
                        "9f5fc152-75fb-59d8-8f40-7a6ca15c69cb"
                    ]
                },
                "BP16": {
                    "questionId": "BP16",
                    "chosenOptionIds": [
                        "fabceeea-8fea-5444-8b67-8f189f366c27"
                    ]
                },
                "BP16.0": {
                    "questionId": "BP16.0",
                    "chosenOptionIds": [
                        "4b26caa5-9124-51b2-ab08-2f4e977cee1f"
                    ]
                },
                "BP16.01": {
                    "questionId": "BP16.01",
                    "chosenOptionIds": [
                        "f81655d7-78e5-59b1-a7eb-90a2ec7a107d"
                    ]
                },
                "BP16.02": {
                    "questionId": "BP16.02",
                    "chosenOptionIds": [
                        "16a695c9-3ac1-5d34-b799-44602d85b556"
                    ]
                },
                "BP18": {
                    "questionId": "BP18",
                    "chosenOptionIds": [
                        "b577f323-ec54-5d25-a2b9-4f8de30d60a2"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 23
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "f03de28d-8f71-5af4-be2f-45f4e6092a3d"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "ad1cfcc2-c1dc-57ef-b964-17d355fa0402"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "afb57366-c0b3-5f73-92eb-27bc133ad2ed"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "667ba57c-b1cd-5f9b-aa12-58e65a981910"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "fd30dc27-8e89-5d1a-9d95-8844603d4379"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "d9fb150b-68b8-50ed-bb1d-eafd41f63f3b"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "46c90446-3ae9-53f5-9b57-ad19d66b5be6"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "d680a8d9-e35f-563f-9d1d-e7c3136aff6d"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "c88e3fb5-913a-5c5c-a42b-603f7bb60a40"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "201c7ee3-56dc-51e9-ad55-69951192ab16"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "dbab3669-e910-5267-b7b1-fcb661ea7e81"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "2723ed09-37f6-522c-92b1-e518d4fe9315"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "58777a2e-4b97-5413-b340-efe722e45936"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "5c06d516-e816-58d6-997e-f8fa91131ac7"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "a020ed85-ec9d-5778-8006-41f634cab09d"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM19": {
                    "questionId": "HM19",
                    "chosenOptionIds": [
                        "99f474d7-d509-592a-856a-afed343fb1b8"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "98ea07a0-ae95-570f-8d11-a3e7c682e986"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "696fdd9d-5ced-504b-a933-bb3e575b0099"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c41974b6-d394-569c-8f45-49f79fcac5c6"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "43c32319-c75f-5169-b0e5-713820721d4f"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "b2cb304f-f035-53fc-8763-e2f91b031f9b"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "0170b4cf-0541-53cd-a9db-818d5067dc23"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "2be66549-5767-51b4-82ed-deeab0723f8d"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "2a17c2fe-2592-58b1-98e9-3dc4168fe3e3"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "a56fcb96-f868-5d08-a358-9b403eb3cd16"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "fa32b497-4a4b-5076-a391-b6dffa8e1851"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "8823c74f-ff7c-52d8-9c73-fd3b4180f48a"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "1d07911e-929d-5719-aeea-a68f593d18e8"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: PE694EL aspect: simple_bd2 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "3bd18f66-6f1e-5ea4-ae24-2990ed197bef"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "916d657d-9f2a-5451-8465-18e3bd863347"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "826538fc-8a97-55e4-8013-fe6a93f8e451"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 1095
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "db360e93-3616-5d89-be36-5ccee4aacf1c"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "a9525e10-4e34-5d6b-9c93-c7546ec49231"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "9222be3e-443f-5146-9d69-d48fe9041ba0"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "db7514e2-d317-55fb-82d3-2e4a49c2494b"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "9d1b858c-7064-5a84-80d6-786c18e603b6",
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "f3efc764-ca53-52c3-9c3a-38c7f787d415"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "9506449e-b2aa-5498-b896-901dd248b1b3"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "ef6fddab-7a07-5f20-af20-74f2444eee53"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "3b216cf8-72e7-558c-bdd3-cc23a1865674"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "07106ab9-5949-5b5f-bc96-117139ebd397"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "83e6efd5-64a2-5f0e-a973-9278eae90cfb"
                    ]
                },
                "BP10": {
                    "questionId": "BP10",
                    "chosenOptionIds": [
                        "7ad54f0a-87a6-5e82-bfc3-44b540230082"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "4a0f1c4d-38cf-5de4-99e3-0ee818f9709c"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "6a5aa899-7b89-5661-a554-71b6058ad4a4"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "d3224e16-1b0c-5266-9e14-ac72d2b2e988"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "b24df75b-6bb9-5f5f-99e3-c883ec3644ec"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 45
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "c7bee7a0-3037-5eb1-96a1-85b884d7e1dd"
                    ]
                },
                "BP3.1": {
                    "questionId": "BP3.1",
                    "chosenOptionIds": [
                        "b56edfbb-cf11-50ee-9149-9e36f4cc1ea0"
                    ]
                },
                "BP3.2": {
                    "questionId": "BP3.2",
                    "chosenOptionIds": [
                        "ec333f8b-146a-5700-a15d-527032ceac3f"
                    ]
                },
                "BP3.3": {
                    "questionId": "BP3.3",
                    "freeValue": 0.041666666666666664
                },
                "BP3.4": {
                    "questionId": "BP3.4",
                    "chosenOptionIds": [
                        "6ec82bbf-7582-5509-ace2-5ecf0ce82ec3"
                    ]
                },
                "BP4": {
                    "questionId": "BP4",
                    "chosenOptionIds": [
                        "9765aba8-7d55-5928-b79d-36aa58646036"
                    ]
                },
                "BP4.5": {
                    "questionId": "BP4.5",
                    "chosenOptionIds": [
                        "66a3460c-65ce-5a4c-9774-2e303ae4961e"
                    ]
                },
                "BP6": {
                    "questionId": "BP6",
                    "chosenOptionIds": [
                        "742a22d6-a570-5e3a-8e72-c001f1e9f98b"
                    ]
                },
                "BP6.1": {
                    "questionId": "BP6.1",
                    "chosenOptionIds": [
                        "430b68c6-29cf-5a15-ab4c-89bd842383bf"
                    ]
                },
                "BP6.2": {
                    "questionId": "BP6.2",
                    "freeValue": 0.041666666666666664
                },
                "BP6.3": {
                    "questionId": "BP6.3",
                    "chosenOptionIds": [
                        "e061c578-086e-5990-bba6-279cb273f3a5"
                    ]
                },
                "BP8": {
                    "questionId": "BP8",
                    "chosenOptionIds": [
                        "87e0081b-9455-5ced-9225-10c2f70b1734"
                    ]
                },
                "BP8.0": {
                    "questionId": "BP8.0",
                    "chosenOptionIds": [
                        "3568cdb1-2500-512b-aada-2547c5bfd835"
                    ]
                },
                "BP8.01": {
                    "questionId": "BP8.01",
                    "chosenOptionIds": [
                        "5020bfdd-a280-56cf-b7f5-3b0b7e667165"
                    ]
                },
                "BP8.02": {
                    "questionId": "BP8.02",
                    "chosenOptionIds": [
                        "e88670a3-c6d8-5c96-914e-b9fc1818aeda"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "9ae96e50-5c86-58a7-a845-fb6347c7edd1"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "afb57366-c0b3-5f73-92eb-27bc133ad2ed"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "28c75e9e-94ef-5c50-8645-f4ff93ccf2ec"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "fd30dc27-8e89-5d1a-9d95-8844603d4379"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "e22cf233-c174-52fb-af55-97bf9c46e971"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "46c90446-3ae9-53f5-9b57-ad19d66b5be6"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "d680a8d9-e35f-563f-9d1d-e7c3136aff6d"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "c88e3fb5-913a-5c5c-a42b-603f7bb60a40"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "ec84966e-bb9f-5ba9-a0cf-d5d3503a74e0"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "6928c696-e6d7-5a14-a004-15a497ac6562"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "17b4da6f-7135-523c-b90b-2fe3b66785ff"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "3cf77140-2757-58eb-b4ea-f2a90cd766e7"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "5c06d516-e816-58d6-997e-f8fa91131ac7"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "c0cc09c3-1335-5eb4-8801-b3cdaf74f6fa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM2": {
                    "questionId": "HM2",
                    "chosenOptionIds": [
                        "de662c3f-66b4-566f-856d-2124e2725542"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "98ea07a0-ae95-570f-8d11-a3e7c682e986"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "a43647cb-a0e8-5705-adb9-a2b68877dc77"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c41974b6-d394-569c-8f45-49f79fcac5c6"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "43c32319-c75f-5169-b0e5-713820721d4f"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "b2cb304f-f035-53fc-8763-e2f91b031f9b"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "0170b4cf-0541-53cd-a9db-818d5067dc23"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "efcb2ed0-864a-521d-ac0d-99e0fd4b38f5"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "2a17c2fe-2592-58b1-98e9-3dc4168fe3e3"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "834b04f2-af09-5337-8fd5-be4a70c99e75"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "86277249-cf50-5f91-a27f-f3a69344e796"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "8823c74f-ff7c-52d8-9c73-fd3b4180f48a"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "1d07911e-929d-5719-aeea-a68f593d18e8"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: 63Y843R aspect: simple_bd2 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "45f0ce85-9e19-53da-bc0d-2af8b383318a"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "c0453e8e-7a9c-5bf5-b8a2-167a61a6387e"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "b74511c7-9f97-5c15-8bf5-6ec4357b4814"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 90
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "7a5958fc-e0f7-5d43-9cd0-abfe95cb7026"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "a9525e10-4e34-5d6b-9c93-c7546ec49231"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "11f457f5-4c39-5458-ba9a-84478dd379ba"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5c30c60e-d53e-5cc3-b4a9-fdad9bec4cb7"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "9d1b858c-7064-5a84-80d6-786c18e603b6",
                        "1d4beed1-78e5-531c-a255-643fac260dee"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "b52073e3-c813-51f5-88ba-9cd3bddd7023"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "ef6fddab-7a07-5f20-af20-74f2444eee53"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "d362cd87-7515-551e-bc74-19ef903484aa"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "07106ab9-5949-5b5f-bc96-117139ebd397",
                        "4896b504-2025-52c3-85e2-c6cf3205cef7"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "7599bcaa-89a5-5f71-80ea-b70c360bb44a"
                    ]
                },
                "BP101b": {
                    "questionId": "BP101b",
                    "chosenOptionIds": [
                        "cb80bcac-db78-55bd-b051-bdd00801fd54"
                    ]
                },
                "BP102b": {
                    "questionId": "BP102b",
                    "chosenOptionIds": [
                        "71338232-94be-5805-b822-94db8be9c5a1"
                    ]
                },
                "BP103b": {
                    "questionId": "BP103b",
                    "chosenOptionIds": [
                        "867b0ee4-73d9-5aac-b96d-bc2d85d5b183"
                    ]
                },
                "BP104b": {
                    "questionId": "BP104b",
                    "chosenOptionIds": [
                        "c59b5910-3279-509f-8175-f2fd0da2dc86"
                    ]
                },
                "BP87b": {
                    "questionId": "BP87b",
                    "chosenOptionIds": [
                        "7949bd96-3757-5573-bd89-3a91a383cc99"
                    ]
                },
                "BP88b": {
                    "questionId": "BP88b",
                    "chosenOptionIds": [
                        "fa24b924-a8e1-5f02-893b-3d9643007991"
                    ]
                },
                "BP89b": {
                    "questionId": "BP89b",
                    "chosenOptionIds": [
                        "41d2829a-3971-552f-8c5a-c77e7d0073e7"
                    ]
                },
                "BP90b": {
                    "questionId": "BP90b",
                    "chosenOptionIds": [
                        "95c2822d-27ad-5bb0-982f-8c2b949c8918"
                    ]
                },
                "BP92b": {
                    "questionId": "BP92b",
                    "chosenOptionIds": [
                        "b2f4c490-30df-5c8f-87c0-bc4acbcfb8e0"
                    ]
                },
                "BP94b": {
                    "questionId": "BP94b",
                    "chosenOptionIds": [
                        "33cefe4b-19c9-55e0-a87c-03ffd99d30bf"
                    ]
                },
                "BP95b": {
                    "questionId": "BP95b",
                    "chosenOptionIds": [
                        "fb0bd4a7-2793-5b81-badc-fe81b4bc82dc"
                    ]
                },
                "BP96b": {
                    "questionId": "BP96b",
                    "chosenOptionIds": [
                        "8c3d3503-6334-55b9-b0f0-94c8e520094e"
                    ]
                },
                "BP97b": {
                    "questionId": "BP97b",
                    "chosenOptionIds": [
                        "8fa0f169-f510-5c9e-a4be-fbcc964f7480"
                    ]
                },
                "BP98b": {
                    "questionId": "BP98b",
                    "chosenOptionIds": [
                        "01641419-fbfc-52a5-ae63-0fa736ff9847"
                    ]
                },
                "BP99.3b": {
                    "questionId": "BP99.3b",
                    "chosenOptionIds": [
                        "bf30a6c2-f8e2-5305-b4d4-7ad6227449d3"
                    ]
                },
                "BP99b": {
                    "questionId": "BP99b",
                    "chosenOptionIds": [
                        "68860df1-e835-50d6-8eb2-f9d9f2f97efa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "BP_neg1": {
                    "questionId": "BP_neg1",
                    "chosenOptionIds": [
                        "119208b0-ff3b-5c43-80be-4f07e65b6248"
                    ]
                }
            }
        },
        {
            "name": "Mood test: J0VQM3L aspect: simple_bd2 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "45f0ce85-9e19-53da-bc0d-2af8b383318a"
                    ]
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "7a5958fc-e0f7-5d43-9cd0-abfe95cb7026"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "a9525e10-4e34-5d6b-9c93-c7546ec49231"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "9222be3e-443f-5146-9d69-d48fe9041ba0"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "77c6088d-2d8f-5c3b-89c9-79cb0d317226"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "1d4beed1-78e5-531c-a255-643fac260dee"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "f3efc764-ca53-52c3-9c3a-38c7f787d415"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "9506449e-b2aa-5498-b896-901dd248b1b3"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "ef6fddab-7a07-5f20-af20-74f2444eee53"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "3b216cf8-72e7-558c-bdd3-cc23a1865674"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "60fe0bb4-d770-56f2-b05a-864625a48e4d"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "7599bcaa-89a5-5f71-80ea-b70c360bb44a"
                    ]
                },
                "BP101b": {
                    "questionId": "BP101b",
                    "chosenOptionIds": [
                        "31b2d2c8-8f28-514e-9e58-aa878f6c8761"
                    ]
                },
                "BP102b": {
                    "questionId": "BP102b",
                    "chosenOptionIds": [
                        "00afc1e6-b78a-5223-a6e7-5d3aaab833e0"
                    ]
                },
                "BP103b": {
                    "questionId": "BP103b",
                    "chosenOptionIds": [
                        "867b0ee4-73d9-5aac-b96d-bc2d85d5b183"
                    ]
                },
                "BP104b": {
                    "questionId": "BP104b",
                    "chosenOptionIds": [
                        "c59b5910-3279-509f-8175-f2fd0da2dc86"
                    ]
                },
                "BP87b": {
                    "questionId": "BP87b",
                    "chosenOptionIds": [
                        "5bc9fc9f-d402-56b0-97eb-fe64add38edf"
                    ]
                },
                "BP88b": {
                    "questionId": "BP88b",
                    "chosenOptionIds": [
                        "fa24b924-a8e1-5f02-893b-3d9643007991"
                    ]
                },
                "BP89b": {
                    "questionId": "BP89b",
                    "chosenOptionIds": [
                        "41d2829a-3971-552f-8c5a-c77e7d0073e7"
                    ]
                },
                "BP90b": {
                    "questionId": "BP90b",
                    "chosenOptionIds": [
                        "95c2822d-27ad-5bb0-982f-8c2b949c8918"
                    ]
                },
                "BP92b": {
                    "questionId": "BP92b",
                    "chosenOptionIds": [
                        "b2f4c490-30df-5c8f-87c0-bc4acbcfb8e0"
                    ]
                },
                "BP94b": {
                    "questionId": "BP94b",
                    "chosenOptionIds": [
                        "038cb12b-2783-569a-b1d7-e28c65e591a3"
                    ]
                },
                "BP95b": {
                    "questionId": "BP95b",
                    "chosenOptionIds": [
                        "fb0bd4a7-2793-5b81-badc-fe81b4bc82dc"
                    ]
                },
                "BP96b": {
                    "questionId": "BP96b",
                    "chosenOptionIds": [
                        "8c3d3503-6334-55b9-b0f0-94c8e520094e"
                    ]
                },
                "BP97b": {
                    "questionId": "BP97b",
                    "chosenOptionIds": [
                        "6895096a-ef29-546e-9967-d7a23b4a2859"
                    ]
                },
                "BP98b": {
                    "questionId": "BP98b",
                    "chosenOptionIds": [
                        "01641419-fbfc-52a5-ae63-0fa736ff9847"
                    ]
                },
                "BP99.3b": {
                    "questionId": "BP99.3b",
                    "chosenOptionIds": [
                        "bf30a6c2-f8e2-5305-b4d4-7ad6227449d3"
                    ]
                },
                "BP99b": {
                    "questionId": "BP99b",
                    "chosenOptionIds": [
                        "68860df1-e835-50d6-8eb2-f9d9f2f97efa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "BP_neg1": {
                    "questionId": "BP_neg1",
                    "chosenOptionIds": [
                        "119208b0-ff3b-5c43-80be-4f07e65b6248"
                    ]
                }
            }
        },
        {
            "name": "Mood test: Y32OM08 aspect: simple_bd2 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "c61d70d4-b773-54dd-94d0-d858a47be0fb"
                    ]
                },
                "D-1.5b": {
                    "questionId": "D-1.5b",
                    "freeValue": 26
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "a16e8e14-524e-53df-b4ef-730a2c8e5f32"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "9b47900d-e5a7-5e58-830b-d9b3130a7990"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "b74511c7-9f97-5c15-8bf5-6ec4357b4814"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 1460
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "913bc1e9-8da1-54c7-b935-13fc5d48fbd9"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-1bl": {
                    "questionId": "D-1bl",
                    "chosenOptionIds": [
                        "4105c26a-4ff2-5fd7-984d-f0bcb7b5656e"
                    ]
                },
                "D-2": {
                    "questionId": "D-2",
                    "freeValue": 15
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "c695fd1c-2cb7-5654-b9f7-b32ecb158229"
                    ]
                },
                "D-2bl": {
                    "questionId": "D-2bl",
                    "chosenOptionIds": [
                        "78c1434b-e585-5f1d-b291-a1728f789022"
                    ]
                },
                "D-2bs": {
                    "questionId": "D-2bs",
                    "chosenOptionIds": [
                        "bbd43ce6-ea63-5321-9d05-0bbf9a19513b"
                    ]
                },
                "D-2bt": {
                    "questionId": "D-2bt",
                    "chosenOptionIds": [
                        "acdba44c-4419-55d5-891f-5c03c9b52309"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "fd9c4394-6664-5144-8797-00d27a4dda01"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "db7514e2-d317-55fb-82d3-2e4a49c2494b"
                    ]
                },
                "D-3bl": {
                    "questionId": "D-3bl",
                    "freeValue": 2
                },
                "D-3bs": {
                    "questionId": "D-3bs",
                    "chosenOptionIds": [
                        "fe554f25-dfd7-5062-a2b8-f2662e2c3466"
                    ]
                },
                "D-3bt": {
                    "questionId": "D-3bt",
                    "chosenOptionIds": [
                        "b9abc28d-6e22-5b67-bc5e-27431e123974"
                    ]
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-42bt": {
                    "questionId": "D-42bt",
                    "freeValue": 3
                },
                "D-43bt": {
                    "questionId": "D-43bt",
                    "chosenOptionIds": [
                        "90e60395-fc3d-56cb-b6f7-2e24fba02803"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "1d4beed1-78e5-531c-a255-643fac260dee",
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b"
                    ]
                },
                "D-4bs": {
                    "questionId": "D-4bs",
                    "freeValue": 2
                },
                "D-4bt": {
                    "questionId": "D-4bt",
                    "freeValue": 5
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "c9813959-ba61-5220-9e27-659abb256037"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "3e093dd3-857e-5004-a4fb-961fbfa59ece"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "adecf2d3-f302-50f8-a4a9-3ffac1789bc6"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "4c78ae3e-7de4-545f-aca1-03fa41fc0c23"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "13f8bac1-d0f5-539b-8596-1c55821bea46"
                    ]
                },
                "D-s10bt": {
                    "questionId": "D-s10bt",
                    "chosenOptionIds": [
                        "e3ab5219-3af6-5da7-8464-a145eb508a0f"
                    ]
                },
                "D-s11bt": {
                    "questionId": "D-s11bt",
                    "chosenOptionIds": [
                        "bae5acc8-8664-54a2-ba97-c36244be4c44",
                        "dc476220-9479-5def-8a97-20e6152d44af",
                        "6994b88e-cfa5-5986-b9e8-625d17d1147c",
                        "16e1a0b8-28c5-5bcd-931d-335c858c3f88",
                        "64af3cae-713a-51e1-99c1-09e2fdaa755d",
                        "7921bccf-4756-59fe-b678-d536c2ab5945"
                    ]
                },
                "D-s1bt": {
                    "questionId": "D-s1bt",
                    "chosenOptionIds": [
                        "c17482e4-818a-50cd-90fa-1246d037825e"
                    ]
                },
                "D-s2bt": {
                    "questionId": "D-s2bt",
                    "chosenOptionIds": [
                        "8783a6d5-cd94-5982-8fd6-66ae46e1c6df"
                    ]
                },
                "D-s3bt": {
                    "questionId": "D-s3bt",
                    "chosenOptionIds": [
                        "d065c0ca-9e6d-5300-8e3e-688b16f88c59"
                    ]
                },
                "D-s4bt": {
                    "questionId": "D-s4bt",
                    "chosenOptionIds": [
                        "a4021497-af94-56a4-8075-392653262e2e"
                    ]
                },
                "D-s5bt": {
                    "questionId": "D-s5bt",
                    "chosenOptionIds": [
                        "508d38ea-9336-504a-a8f2-c375a9bcc839"
                    ]
                },
                "D-s6bt": {
                    "questionId": "D-s6bt",
                    "chosenOptionIds": [
                        "53625e56-4f03-5f54-a340-d5b8cabe24d9"
                    ]
                },
                "D-s7bt": {
                    "questionId": "D-s7bt",
                    "chosenOptionIds": [
                        "1df67815-0b9f-56ef-bd48-96ed977db141"
                    ]
                },
                "D-s8bt": {
                    "questionId": "D-s8bt",
                    "chosenOptionIds": [
                        "6f5067cf-ef4d-59d9-afa1-b3dfc4ac861f"
                    ]
                },
                "D-s9bt": {
                    "questionId": "D-s9bt",
                    "chosenOptionIds": [
                        "da503bbd-e567-576e-a21f-e0fad60dccdd"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "60fe0bb4-d770-56f2-b05a-864625a48e4d"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "7599bcaa-89a5-5f71-80ea-b70c360bb44a"
                    ]
                },
                "BP101b": {
                    "questionId": "BP101b",
                    "chosenOptionIds": [
                        "c9856080-d9de-533e-92bf-7fbe2b6cd99a"
                    ]
                },
                "BP102b": {
                    "questionId": "BP102b",
                    "chosenOptionIds": [
                        "46a64b5b-6641-5b1b-92aa-5366444fb134"
                    ]
                },
                "BP103b": {
                    "questionId": "BP103b",
                    "chosenOptionIds": [
                        "867b0ee4-73d9-5aac-b96d-bc2d85d5b183"
                    ]
                },
                "BP104b": {
                    "questionId": "BP104b",
                    "chosenOptionIds": [
                        "c59b5910-3279-509f-8175-f2fd0da2dc86"
                    ]
                },
                "BP87b": {
                    "questionId": "BP87b",
                    "chosenOptionIds": [
                        "7949bd96-3757-5573-bd89-3a91a383cc99"
                    ]
                },
                "BP88b": {
                    "questionId": "BP88b",
                    "chosenOptionIds": [
                        "e29e159f-f691-5aa7-b5a0-d13fe2c54170"
                    ]
                },
                "BP89b": {
                    "questionId": "BP89b",
                    "chosenOptionIds": [
                        "41d2829a-3971-552f-8c5a-c77e7d0073e7"
                    ]
                },
                "BP90b": {
                    "questionId": "BP90b",
                    "chosenOptionIds": [
                        "95c2822d-27ad-5bb0-982f-8c2b949c8918"
                    ]
                },
                "BP92b": {
                    "questionId": "BP92b",
                    "chosenOptionIds": [
                        "b2f4c490-30df-5c8f-87c0-bc4acbcfb8e0"
                    ]
                },
                "BP94b": {
                    "questionId": "BP94b",
                    "chosenOptionIds": [
                        "1f4fafbf-913e-5edc-bfaf-3baae49fc91d"
                    ]
                },
                "BP95b": {
                    "questionId": "BP95b",
                    "chosenOptionIds": [
                        "fb0bd4a7-2793-5b81-badc-fe81b4bc82dc"
                    ]
                },
                "BP96b": {
                    "questionId": "BP96b",
                    "chosenOptionIds": [
                        "8c3d3503-6334-55b9-b0f0-94c8e520094e"
                    ]
                },
                "BP97b": {
                    "questionId": "BP97b",
                    "chosenOptionIds": [
                        "8fa0f169-f510-5c9e-a4be-fbcc964f7480"
                    ]
                },
                "BP98b": {
                    "questionId": "BP98b",
                    "chosenOptionIds": [
                        "f7b75a2b-3bd2-5734-9fa6-7706f7f8ac89"
                    ]
                },
                "BP99.1b": {
                    "questionId": "BP99.1b",
                    "chosenOptionIds": [
                        "74ac8d69-c317-57fb-a5e5-a93c1309c0f7"
                    ]
                },
                "BP99.2b": {
                    "questionId": "BP99.2b",
                    "chosenOptionIds": [
                        "a8224369-4083-5ab5-8cad-c4563490599c"
                    ]
                },
                "BP99.3b": {
                    "questionId": "BP99.3b",
                    "chosenOptionIds": [
                        "e4575ba2-20f0-519e-967b-d5769763cadc"
                    ]
                },
                "BP99b": {
                    "questionId": "BP99b",
                    "chosenOptionIds": [
                        "3f987aaa-e435-55a9-9c1b-aa6d2e476738",
                        "96faf550-f157-55b9-8f15-6be82cd511a8"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "BP_neg1": {
                    "questionId": "BP_neg1",
                    "chosenOptionIds": [
                        "119208b0-ff3b-5c43-80be-4f07e65b6248"
                    ]
                }
            }
        },
        {
            "name": "Mood test: 50MDW06 aspect: simple_bd2 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "fe031280-7d5d-52cb-aca8-1a8664b3234a"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "9b47900d-e5a7-5e58-830b-d9b3130a7990"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "a0e528ca-2fbb-5da1-9398-0758f79755aa"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 1460
                },
                "D-15a": {
                    "questionId": "D-15a",
                    "chosenOptionIds": [
                        "7b763ff0-7703-54f7-8eae-aa83417ca133"
                    ]
                },
                "D-16.5a": {
                    "questionId": "D-16.5a",
                    "chosenOptionIds": [
                        "ae0a4d4e-e9bf-51bf-8506-0cbced38db92"
                    ]
                },
                "D-17a": {
                    "questionId": "D-17a",
                    "chosenOptionIds": [
                        "495d3d07-8a1f-5213-bf79-59443d7aa5cf"
                    ]
                },
                "D-18a": {
                    "questionId": "D-18a",
                    "chosenOptionIds": [
                        "d5dff57c-3626-5197-b54a-ddca85aa8775"
                    ]
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "db360e93-3616-5d89-be36-5ccee4aacf1c"
                    ]
                },
                "D-1bl": {
                    "questionId": "D-1bl",
                    "chosenOptionIds": [
                        "5da400b0-5bff-5744-a664-4305892ae669"
                    ]
                },
                "D-2": {
                    "questionId": "D-2",
                    "freeValue": 14
                },
                "D-20a": {
                    "questionId": "D-20a",
                    "chosenOptionIds": [
                        "15c694bd-3089-51d8-8fff-6286e2113f9c"
                    ]
                },
                "D-21a": {
                    "questionId": "D-21a",
                    "chosenOptionIds": [
                        "4c0f1be4-1f17-5e09-86a5-e8e8e51285ef"
                    ]
                },
                "D-23a": {
                    "questionId": "D-23a",
                    "chosenOptionIds": [
                        "f6cf63c5-0c57-5422-9431-55fec4c93c63"
                    ]
                },
                "D-26a": {
                    "questionId": "D-26a",
                    "chosenOptionIds": [
                        "400d260d-4647-5f4d-8105-894b04c366f2"
                    ]
                },
                "D-27a": {
                    "questionId": "D-27a",
                    "chosenOptionIds": [
                        "13a1b08d-9817-5c2f-96cb-f8d03b80a37d"
                    ]
                },
                "D-28a": {
                    "questionId": "D-28a",
                    "chosenOptionIds": [
                        "801353cf-2f72-53db-9179-e7ecc31b1fc1"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "c695fd1c-2cb7-5654-b9f7-b32ecb158229"
                    ]
                },
                "D-2bl": {
                    "questionId": "D-2bl",
                    "chosenOptionIds": [
                        "2a8c96e2-cb5b-596a-99b6-a4885253776c"
                    ]
                },
                "D-2bs": {
                    "questionId": "D-2bs",
                    "chosenOptionIds": [
                        "d9fc40d3-aa51-587a-a54d-305ad26f67ae"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "e797900f-129d-553e-b83f-8494d7f03986"
                    ]
                },
                "D-30a": {
                    "questionId": "D-30a",
                    "chosenOptionIds": [
                        "d309cef4-2204-5a10-a98d-b66740336838"
                    ]
                },
                "D-31a": {
                    "questionId": "D-31a",
                    "chosenOptionIds": [
                        "bfd75ca8-5c95-564e-9603-3b1a4f34f8d8"
                    ]
                },
                "D-32a": {
                    "questionId": "D-32a",
                    "chosenOptionIds": [
                        "ea2a4fbd-008a-571b-bb68-cbd0ba351521"
                    ]
                },
                "D-33a": {
                    "questionId": "D-33a",
                    "chosenOptionIds": [
                        "3968dd70-f060-5cbd-bb6a-d63f702a3691"
                    ]
                },
                "D-34a": {
                    "questionId": "D-34a",
                    "chosenOptionIds": [
                        "6bfab4b8-7d57-5039-8933-4dee0c958a19"
                    ]
                },
                "D-35a": {
                    "questionId": "D-35a",
                    "chosenOptionIds": [
                        "9c9d44bb-b50f-5ddf-9553-03b254272362"
                    ]
                },
                "D-36a": {
                    "questionId": "D-36a",
                    "chosenOptionIds": [
                        "7fb54260-7d7e-5c80-809e-584be2bfee10"
                    ]
                },
                "D-37a": {
                    "questionId": "D-37a",
                    "chosenOptionIds": [
                        "fd4e0608-283c-5f56-a026-edfa381519c9"
                    ]
                },
                "D-38a": {
                    "questionId": "D-38a",
                    "chosenOptionIds": [
                        "8d5bbc66-0b33-5de5-838e-ff0fccbb6fbc"
                    ]
                },
                "D-39a": {
                    "questionId": "D-39a",
                    "chosenOptionIds": [
                        "756b91dc-7358-504f-aeab-84552f2bf28b"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5b6c0a69-6369-53d1-b2e1-5cd6294a9f71"
                    ]
                },
                "D-3bl": {
                    "questionId": "D-3bl",
                    "freeValue": 14
                },
                "D-3bs": {
                    "questionId": "D-3bs",
                    "chosenOptionIds": [
                        "c4422e8a-6a0a-51ea-8592-9157036922d1"
                    ]
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-40a": {
                    "questionId": "D-40a",
                    "chosenOptionIds": [
                        "582119a7-d4a0-58ff-9b1d-2af045e11a7f"
                    ]
                },
                "D-41a": {
                    "questionId": "D-41a",
                    "chosenOptionIds": [
                        "6a5d1a65-0b53-5485-995a-76d8d7eafb1a"
                    ]
                },
                "D-42a": {
                    "questionId": "D-42a",
                    "chosenOptionIds": [
                        "bc912c4c-69a9-5a39-89fb-2072bd945067"
                    ]
                },
                "D-43a": {
                    "questionId": "D-43a",
                    "chosenOptionIds": [
                        "cd0abe72-b3e8-5c78-8330-7ee4a1657536"
                    ]
                },
                "D-43bt": {
                    "questionId": "D-43bt",
                    "chosenOptionIds": [
                        "90e60395-fc3d-56cb-b6f7-2e24fba02803"
                    ]
                },
                "D-44.5a": {
                    "questionId": "D-44.5a",
                    "chosenOptionIds": [
                        "4a53cb20-fae8-59f1-934c-ed83e0709795"
                    ]
                },
                "D-44a": {
                    "questionId": "D-44a",
                    "chosenOptionIds": [
                        "7d6584ca-1b93-543d-9ee4-400ca40134be"
                    ]
                },
                "D-45a": {
                    "questionId": "D-45a",
                    "freeValue": 7
                },
                "D-46a": {
                    "questionId": "D-46a",
                    "chosenOptionIds": [
                        "6e49f368-62af-5abb-9034-a58bd534944f",
                        "4c178978-f79a-58fc-8e73-3a98614c4b6c",
                        "7492eab7-c625-52d0-a2e3-4bf3332decc0",
                        "d9665b1f-cdd2-5979-895e-27bca6487533"
                    ]
                },
                "D-49.1a": {
                    "questionId": "D-49.1a",
                    "chosenOptionIds": [
                        "7108af97-c956-5da8-8dfa-518e346c197c"
                    ]
                },
                "D-49.2a": {
                    "questionId": "D-49.2a",
                    "freeValue": 100
                },
                "D-49a": {
                    "questionId": "D-49a",
                    "chosenOptionIds": [
                        "16dc6af1-32a9-59c5-b66c-57f6becea1e0"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b"
                    ]
                },
                "D-4bs": {
                    "questionId": "D-4bs",
                    "freeValue": 21
                },
                "D-50a": {
                    "questionId": "D-50a",
                    "chosenOptionIds": [
                        "d14da42d-9612-5827-9583-b2bf528130de"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "188a7831-38c4-55c4-880a-1e4db4e9bab1"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "08a0f83f-2f37-5904-9364-4e40deb90e98"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "772bee29-c3a5-52e1-b785-a77d506ae4fb"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "4c78ae3e-7de4-545f-aca1-03fa41fc0c23"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "1200dadf-a0de-5a54-b0b8-fc640f7bc8d1"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "c2921864-bbf7-5735-9313-848fb9d5a6d1",
                        "44fd9eb3-aee2-5af0-b83c-dbbbf9514022"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "83e6efd5-64a2-5f0e-a973-9278eae90cfb"
                    ]
                },
                "BP10": {
                    "questionId": "BP10",
                    "chosenOptionIds": [
                        "7ad54f0a-87a6-5e82-bfc3-44b540230082"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "a20d7b84-720e-53c5-a450-45cbde434507"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "ca5ac96d-2b69-5200-a063-61db840c9ef2"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "7230003d-a290-5a65-ae6b-b7d564b5db38"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "b24df75b-6bb9-5f5f-99e3-c883ec3644ec"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "de4464c8-78fe-5876-aa00-25826ce5ec25"
                    ]
                },
                "BP105": {
                    "questionId": "BP105",
                    "chosenOptionIds": [
                        "64aa1680-6b5b-5a5b-bb92-75995d27ddcd"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 19
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "c7bee7a0-3037-5eb1-96a1-85b884d7e1dd"
                    ]
                },
                "BP3.01": {
                    "questionId": "BP3.01",
                    "freeValue": 40
                },
                "BP3.1": {
                    "questionId": "BP3.1",
                    "chosenOptionIds": [
                        "9531c3ff-ab2b-5198-a490-919ede5afb07"
                    ]
                },
                "BP3.2": {
                    "questionId": "BP3.2",
                    "chosenOptionIds": [
                        "ec333f8b-146a-5700-a15d-527032ceac3f"
                    ]
                },
                "BP3.3": {
                    "questionId": "BP3.3",
                    "freeValue": 2
                },
                "BP3.4": {
                    "questionId": "BP3.4",
                    "chosenOptionIds": [
                        "f304bf93-0c68-5da0-80ba-ccecc2d0fce1"
                    ]
                },
                "BP4": {
                    "questionId": "BP4",
                    "chosenOptionIds": [
                        "9765aba8-7d55-5928-b79d-36aa58646036"
                    ]
                },
                "BP4.5": {
                    "questionId": "BP4.5",
                    "chosenOptionIds": [
                        "1be4b3b1-3a88-5544-af54-ebdfee1ce891"
                    ]
                },
                "BP6": {
                    "questionId": "BP6",
                    "chosenOptionIds": [
                        "52f153db-76e7-57fa-90bd-63d5a923964a"
                    ]
                },
                "BP6.1": {
                    "questionId": "BP6.1",
                    "chosenOptionIds": [
                        "a99773bd-2bff-523e-bc29-1f761307b3d4"
                    ]
                },
                "BP6.2": {
                    "questionId": "BP6.2",
                    "freeValue": 4
                },
                "BP6.3": {
                    "questionId": "BP6.3",
                    "chosenOptionIds": [
                        "e061c578-086e-5990-bba6-279cb273f3a5"
                    ]
                },
                "BP8": {
                    "questionId": "BP8",
                    "chosenOptionIds": [
                        "87e0081b-9455-5ced-9225-10c2f70b1734"
                    ]
                },
                "BP8.0": {
                    "questionId": "BP8.0",
                    "chosenOptionIds": [
                        "3568cdb1-2500-512b-aada-2547c5bfd835"
                    ]
                },
                "BP8.01": {
                    "questionId": "BP8.01",
                    "chosenOptionIds": [
                        "5020bfdd-a280-56cf-b7f5-3b0b7e667165"
                    ]
                },
                "BP8.02": {
                    "questionId": "BP8.02",
                    "chosenOptionIds": [
                        "e88670a3-c6d8-5c96-914e-b9fc1818aeda"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "e620af65-9b5b-57e6-b13a-65616e67e084"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "5f694075-3a97-5f8b-8701-2710a43127a9"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "92e118de-38b9-5d5a-96ba-7b50547c0621"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "93bfeb6e-215e-5f4b-b6de-0bc364e05be5"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "e22cf233-c174-52fb-af55-97bf9c46e971"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "1f57d1a0-3682-5d7d-b66b-8179ec5c3988"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 7
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "17619692-6d18-5558-99da-766a1c275e03"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "82997889-d795-572b-a597-e484ef078b39"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "2555d898-6736-5804-951e-e3c438313029"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "de2ea429-4fcd-53b8-8d9f-ebb69a637a02"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "6ade3a8d-8516-5a88-baa0-f996f32730d2"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "79075051-dcb3-5321-9f27-b21697f8f194"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "58777a2e-4b97-5413-b340-efe722e45936"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "5c06d516-e816-58d6-997e-f8fa91131ac7"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "e81fe613-4923-55ed-bd6c-bd3f19e026f9"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM2": {
                    "questionId": "HM2",
                    "chosenOptionIds": [
                        "a046d610-d27a-59c1-b130-38312005cf1c"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "6b99de8b-634a-5eb0-bc65-bdf30eb9d433"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "8f7acbc1-9ebe-5a52-95ce-b50d62c27345"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "5574c021-c3c4-513a-a4b6-5ee416e8aa9e"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "43c32319-c75f-5169-b0e5-713820721d4f"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "898957c6-8cd1-53e5-98f8-c16789aba613"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "366c6a1b-c8b2-5faf-9d2f-72dbde24e869"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "6b35cd3b-d67a-54af-94f6-e39ddf5af6c6"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "0983ccf5-a426-53d0-adbb-1baf6832190c"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "7e32475a-3fcc-51ac-b414-ab46578efdfd"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "fda00d52-c8be-5b69-bdbd-905c13dddd4e"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "0360ab4b-77d5-5fcd-8d60-b7a005c12ceb"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "7d6921a2-7e63-5f12-9c75-dff8b592fabc"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: Z35L10O aspect: simple_bd2 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "3bd18f66-6f1e-5ea4-ae24-2990ed197bef"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "9b47900d-e5a7-5e58-830b-d9b3130a7990"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "b74511c7-9f97-5c15-8bf5-6ec4357b4814"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 3650
                },
                "D-15a": {
                    "questionId": "D-15a",
                    "chosenOptionIds": [
                        "7b763ff0-7703-54f7-8eae-aa83417ca133"
                    ]
                },
                "D-16.5a": {
                    "questionId": "D-16.5a",
                    "chosenOptionIds": [
                        "0c4e0b7f-132d-5e75-aeae-fd29d3b2eb67"
                    ]
                },
                "D-17a": {
                    "questionId": "D-17a",
                    "chosenOptionIds": [
                        "33b013af-d830-516a-9632-69dcc7a0f736"
                    ]
                },
                "D-19a": {
                    "questionId": "D-19a",
                    "chosenOptionIds": [
                        "d5d03509-fbd0-500d-a2a9-51acdb2f03db"
                    ]
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "7f1d6182-f8a7-5f4e-a0f8-2556b306ae36"
                    ]
                },
                "D-1bl": {
                    "questionId": "D-1bl",
                    "chosenOptionIds": [
                        "4105c26a-4ff2-5fd7-984d-f0bcb7b5656e"
                    ]
                },
                "D-2": {
                    "questionId": "D-2",
                    "freeValue": 18
                },
                "D-20a": {
                    "questionId": "D-20a",
                    "chosenOptionIds": [
                        "15c694bd-3089-51d8-8fff-6286e2113f9c"
                    ]
                },
                "D-21a": {
                    "questionId": "D-21a",
                    "chosenOptionIds": [
                        "4c0f1be4-1f17-5e09-86a5-e8e8e51285ef"
                    ]
                },
                "D-23a": {
                    "questionId": "D-23a",
                    "chosenOptionIds": [
                        "44fe96b8-192b-5b6b-88fa-9c2f7ba73a6d"
                    ]
                },
                "D-26a": {
                    "questionId": "D-26a",
                    "chosenOptionIds": [
                        "400d260d-4647-5f4d-8105-894b04c366f2"
                    ]
                },
                "D-27a": {
                    "questionId": "D-27a",
                    "chosenOptionIds": [
                        "54c8142c-ee38-51de-ae81-4a95bdcd18ca"
                    ]
                },
                "D-28a": {
                    "questionId": "D-28a",
                    "chosenOptionIds": [
                        "801353cf-2f72-53db-9179-e7ecc31b1fc1"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "de11fb6b-7e7d-52b1-9d09-2d6debc53314"
                    ]
                },
                "D-2bl": {
                    "questionId": "D-2bl",
                    "chosenOptionIds": [
                        "2a8c96e2-cb5b-596a-99b6-a4885253776c"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "fd9c4394-6664-5144-8797-00d27a4dda01"
                    ]
                },
                "D-30a": {
                    "questionId": "D-30a",
                    "chosenOptionIds": [
                        "d141b80e-0ad8-511c-a1e0-b5fe8cae385f"
                    ]
                },
                "D-31a": {
                    "questionId": "D-31a",
                    "chosenOptionIds": [
                        "eba65e81-9b02-55d6-82cb-0b7ce6da41de"
                    ]
                },
                "D-32.1a": {
                    "questionId": "D-32.1a",
                    "chosenOptionIds": [
                        "da39b8b6-3178-5cf4-b68e-92b08cbf087e"
                    ]
                },
                "D-32a": {
                    "questionId": "D-32a",
                    "chosenOptionIds": [
                        "f992ce3a-fffd-5f38-aee4-24930ded9288"
                    ]
                },
                "D-33a": {
                    "questionId": "D-33a",
                    "chosenOptionIds": [
                        "379331e4-3b82-582c-af51-cc4ed79308d7"
                    ]
                },
                "D-34a": {
                    "questionId": "D-34a",
                    "chosenOptionIds": [
                        "e919c54f-d9a4-5a15-9ae7-8b6b52607a22"
                    ]
                },
                "D-35a": {
                    "questionId": "D-35a",
                    "chosenOptionIds": [
                        "bcace469-f380-599d-a33a-26db6d0053ad"
                    ]
                },
                "D-36a": {
                    "questionId": "D-36a",
                    "chosenOptionIds": [
                        "df6b03d1-7322-5277-91ae-7c8e08091a87"
                    ]
                },
                "D-37a": {
                    "questionId": "D-37a",
                    "chosenOptionIds": [
                        "fd4e0608-283c-5f56-a026-edfa381519c9"
                    ]
                },
                "D-38a": {
                    "questionId": "D-38a",
                    "chosenOptionIds": [
                        "39b328fc-f944-51dc-ae4d-9aab44697f32"
                    ]
                },
                "D-39a": {
                    "questionId": "D-39a",
                    "chosenOptionIds": [
                        "6325d970-8ab3-5759-b71d-96b5909ec039"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "db7514e2-d317-55fb-82d3-2e4a49c2494b"
                    ]
                },
                "D-3bl": {
                    "questionId": "D-3bl",
                    "freeValue": 270
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-40a": {
                    "questionId": "D-40a",
                    "chosenOptionIds": [
                        "582119a7-d4a0-58ff-9b1d-2af045e11a7f"
                    ]
                },
                "D-41a": {
                    "questionId": "D-41a",
                    "chosenOptionIds": [
                        "6a5d1a65-0b53-5485-995a-76d8d7eafb1a"
                    ]
                },
                "D-42a": {
                    "questionId": "D-42a",
                    "chosenOptionIds": [
                        "bc912c4c-69a9-5a39-89fb-2072bd945067"
                    ]
                },
                "D-43a": {
                    "questionId": "D-43a",
                    "chosenOptionIds": [
                        "cd0abe72-b3e8-5c78-8330-7ee4a1657536"
                    ]
                },
                "D-43bt": {
                    "questionId": "D-43bt",
                    "chosenOptionIds": [
                        "644dd944-5a2a-533b-9ef0-fadb5054c422"
                    ]
                },
                "D-44.5a": {
                    "questionId": "D-44.5a",
                    "chosenOptionIds": [
                        "4a53cb20-fae8-59f1-934c-ed83e0709795"
                    ]
                },
                "D-44a": {
                    "questionId": "D-44a",
                    "chosenOptionIds": [
                        "5f4eda72-db17-5c72-b0a5-8a71e1644f25"
                    ]
                },
                "D-45a": {
                    "questionId": "D-45a",
                    "freeValue": 4
                },
                "D-46a": {
                    "questionId": "D-46a",
                    "chosenOptionIds": [
                        "d9665b1f-cdd2-5979-895e-27bca6487533",
                        "1fd426df-808a-5c68-9b6e-23ff2f128b7d",
                        "7492eab7-c625-52d0-a2e3-4bf3332decc0",
                        "6e49f368-62af-5abb-9034-a58bd534944f",
                        "b3e43bd8-fbe3-5cda-b891-6f11e43993d0"
                    ]
                },
                "D-49.1a": {
                    "questionId": "D-49.1a",
                    "chosenOptionIds": [
                        "7108af97-c956-5da8-8dfa-518e346c197c"
                    ]
                },
                "D-49.2a": {
                    "questionId": "D-49.2a",
                    "freeValue": 15
                },
                "D-49a": {
                    "questionId": "D-49a",
                    "chosenOptionIds": [
                        "16dc6af1-32a9-59c5-b66c-57f6becea1e0"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "1d4beed1-78e5-531c-a255-643fac260dee",
                        "9d1b858c-7064-5a84-80d6-786c18e603b6"
                    ]
                },
                "D-50a": {
                    "questionId": "D-50a",
                    "chosenOptionIds": [
                        "d14da42d-9612-5827-9583-b2bf528130de"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "c9813959-ba61-5220-9e27-659abb256037"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "3e093dd3-857e-5004-a4fb-961fbfa59ece"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "5d271ac0-beca-56cc-9d0b-adafcd67da53"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "4c78ae3e-7de4-545f-aca1-03fa41fc0c23"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "f8e2518c-72cf-563b-b322-a03406c8ebac"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "60fe0bb4-d770-56f2-b05a-864625a48e4d"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "ccbdcbec-96d0-5362-977b-784289f5cfc0"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "4a0f1c4d-38cf-5de4-99e3-0ee818f9709c"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "6a5aa899-7b89-5661-a554-71b6058ad4a4"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "a3a547f3-b48b-567f-a32a-6a3f5f4bacd7"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "b24df75b-6bb9-5f5f-99e3-c883ec3644ec"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP12": {
                    "questionId": "BP12",
                    "chosenOptionIds": [
                        "22f9a995-3f9a-5fe9-99bb-71b7f1f96621"
                    ]
                },
                "BP15": {
                    "questionId": "BP15",
                    "chosenOptionIds": [
                        "1286eda2-5e31-52dc-95c5-78ded5b43b2a"
                    ]
                },
                "BP15.1": {
                    "questionId": "BP15.1",
                    "chosenOptionIds": [
                        "aa7bd0b8-95ca-5179-a037-3956a8c2816d"
                    ]
                },
                "BP15.2": {
                    "questionId": "BP15.2",
                    "freeValue": 2
                },
                "BP15.3": {
                    "questionId": "BP15.3",
                    "chosenOptionIds": [
                        "cfa519ff-08a9-5fa4-b12d-87b7a597cb13"
                    ]
                },
                "BP16": {
                    "questionId": "BP16",
                    "chosenOptionIds": [
                        "fabceeea-8fea-5444-8b67-8f189f366c27"
                    ]
                },
                "BP16.0": {
                    "questionId": "BP16.0",
                    "chosenOptionIds": [
                        "4b26caa5-9124-51b2-ab08-2f4e977cee1f"
                    ]
                },
                "BP16.01": {
                    "questionId": "BP16.01",
                    "chosenOptionIds": [
                        "f81655d7-78e5-59b1-a7eb-90a2ec7a107d"
                    ]
                },
                "BP16.02": {
                    "questionId": "BP16.02",
                    "chosenOptionIds": [
                        "16a695c9-3ac1-5d34-b799-44602d85b556"
                    ]
                },
                "BP19": {
                    "questionId": "BP19",
                    "chosenOptionIds": [
                        "601bc370-8a4c-5b1f-a7f6-6c174f421733"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 24
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "f03de28d-8f71-5af4-be2f-45f4e6092a3d"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "e620af65-9b5b-57e6-b13a-65616e67e084"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "5f694075-3a97-5f8b-8701-2710a43127a9"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "28c75e9e-94ef-5c50-8645-f4ff93ccf2ec"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "a2056280-4b54-5258-8bb4-407b521fff4b"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "e22cf233-c174-52fb-af55-97bf9c46e971"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "46c90446-3ae9-53f5-9b57-ad19d66b5be6"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "63f0b17a-9832-5f0f-8630-e74f1a4fdfe7"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "c88e3fb5-913a-5c5c-a42b-603f7bb60a40"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "201c7ee3-56dc-51e9-ad55-69951192ab16"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "6ade3a8d-8516-5a88-baa0-f996f32730d2"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "4a7683c6-774e-5362-8060-740f61986c9b"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "58777a2e-4b97-5413-b340-efe722e45936"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "5c06d516-e816-58d6-997e-f8fa91131ac7"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "c0cc09c3-1335-5eb4-8801-b3cdaf74f6fa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM20": {
                    "questionId": "HM20",
                    "chosenOptionIds": [
                        "be55154a-b96f-5c1c-91de-2a118e42e6f8"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "0f8903ea-258c-54c8-9d4d-643924d3c234"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "8f7acbc1-9ebe-5a52-95ce-b50d62c27345"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c41974b6-d394-569c-8f45-49f79fcac5c6"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "0e9545ba-3f35-52b0-9a31-7fe54c933928"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "898957c6-8cd1-53e5-98f8-c16789aba613"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "366c6a1b-c8b2-5faf-9d2f-72dbde24e869"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "2be66549-5767-51b4-82ed-deeab0723f8d"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "aa90f24c-ee55-515b-b5a2-78bdacbe25a0"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "a56fcb96-f868-5d08-a358-9b403eb3cd16"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "fa32b497-4a4b-5076-a391-b6dffa8e1851"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "0360ab4b-77d5-5fcd-8d60-b7a005c12ceb"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "7d6921a2-7e63-5f12-9c75-dff8b592fabc"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: 4EGRG3P aspect: simple_bd2 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "bc1523b6-7d57-5471-90cd-884ef1f5ea5a"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "98772fed-cf55-5d42-b4e1-0e6a1a31ce56"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "826538fc-8a97-55e4-8013-fe6a93f8e451"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 28
                },
                "D-15a": {
                    "questionId": "D-15a",
                    "chosenOptionIds": [
                        "7b763ff0-7703-54f7-8eae-aa83417ca133"
                    ]
                },
                "D-16.5a": {
                    "questionId": "D-16.5a",
                    "chosenOptionIds": [
                        "b409dbb9-80ef-5de1-96d0-1075735134db"
                    ]
                },
                "D-17a": {
                    "questionId": "D-17a",
                    "chosenOptionIds": [
                        "8413ed32-e959-5c2a-9d19-8cc68709c34d"
                    ]
                },
                "D-19a": {
                    "questionId": "D-19a",
                    "chosenOptionIds": [
                        "6fde9b5c-ed1c-561c-b516-8f5028a1bcd5"
                    ]
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "80c993c8-1a94-5684-a8f9-d8f5072c8e9c"
                    ]
                },
                "D-1bl": {
                    "questionId": "D-1bl",
                    "chosenOptionIds": [
                        "4105c26a-4ff2-5fd7-984d-f0bcb7b5656e"
                    ]
                },
                "D-2": {
                    "questionId": "D-2",
                    "freeValue": 27
                },
                "D-20a": {
                    "questionId": "D-20a",
                    "chosenOptionIds": [
                        "b28ed556-a386-5556-a96f-ae7708ded1ab"
                    ]
                },
                "D-21a": {
                    "questionId": "D-21a",
                    "chosenOptionIds": [
                        "79fb502d-53ef-5cbb-a08a-c346d1ba9310"
                    ]
                },
                "D-23a": {
                    "questionId": "D-23a",
                    "chosenOptionIds": [
                        "f6cf63c5-0c57-5422-9431-55fec4c93c63"
                    ]
                },
                "D-26a": {
                    "questionId": "D-26a",
                    "chosenOptionIds": [
                        "eb4d6df4-7e45-5437-a36e-540fa95a45f9"
                    ]
                },
                "D-27a": {
                    "questionId": "D-27a",
                    "chosenOptionIds": [
                        "3d120f97-fb12-5e86-8bb9-8fc93babc803"
                    ]
                },
                "D-28a": {
                    "questionId": "D-28a",
                    "chosenOptionIds": [
                        "009661f3-60b5-52e8-bc5f-44c65767874c"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "cc243947-5067-5a98-ade6-a4e10d0b42cf"
                    ]
                },
                "D-2bl": {
                    "questionId": "D-2bl",
                    "chosenOptionIds": [
                        "2a8c96e2-cb5b-596a-99b6-a4885253776c"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "75d33f2f-6fcd-5e09-8574-899eabeb22e6",
                        "fd9c4394-6664-5144-8797-00d27a4dda01"
                    ]
                },
                "D-30a": {
                    "questionId": "D-30a",
                    "chosenOptionIds": [
                        "1f6e9ea6-3402-596b-a518-31105c7a277b"
                    ]
                },
                "D-31a": {
                    "questionId": "D-31a",
                    "chosenOptionIds": [
                        "c78e0def-d73e-5c4e-8800-7c6f92d0a1ec"
                    ]
                },
                "D-32a": {
                    "questionId": "D-32a",
                    "chosenOptionIds": [
                        "ea2a4fbd-008a-571b-bb68-cbd0ba351521"
                    ]
                },
                "D-33a": {
                    "questionId": "D-33a",
                    "chosenOptionIds": [
                        "3968dd70-f060-5cbd-bb6a-d63f702a3691"
                    ]
                },
                "D-34a": {
                    "questionId": "D-34a",
                    "chosenOptionIds": [
                        "9d969817-a8d1-57f5-8c15-5e89835ed2a4"
                    ]
                },
                "D-35a": {
                    "questionId": "D-35a",
                    "chosenOptionIds": [
                        "3a07e052-2332-5a23-bb19-3147a5b9d2c7"
                    ]
                },
                "D-36a": {
                    "questionId": "D-36a",
                    "chosenOptionIds": [
                        "df6b03d1-7322-5277-91ae-7c8e08091a87"
                    ]
                },
                "D-37a": {
                    "questionId": "D-37a",
                    "chosenOptionIds": [
                        "fd4e0608-283c-5f56-a026-edfa381519c9"
                    ]
                },
                "D-38a": {
                    "questionId": "D-38a",
                    "chosenOptionIds": [
                        "a03f02de-f86d-525a-b375-383ad9d3cefe"
                    ]
                },
                "D-39a": {
                    "questionId": "D-39a",
                    "chosenOptionIds": [
                        "756b91dc-7358-504f-aeab-84552f2bf28b"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "f110f5ec-75f1-51be-9c45-ea7685a54d64"
                    ]
                },
                "D-3bl": {
                    "questionId": "D-3bl",
                    "freeValue": 56
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-40a": {
                    "questionId": "D-40a",
                    "chosenOptionIds": [
                        "582119a7-d4a0-58ff-9b1d-2af045e11a7f"
                    ]
                },
                "D-41a": {
                    "questionId": "D-41a",
                    "chosenOptionIds": [
                        "6a5d1a65-0b53-5485-995a-76d8d7eafb1a"
                    ]
                },
                "D-42a": {
                    "questionId": "D-42a",
                    "chosenOptionIds": [
                        "926931de-e675-5117-9e61-78dd541393be"
                    ]
                },
                "D-43a": {
                    "questionId": "D-43a",
                    "chosenOptionIds": [
                        "cd0abe72-b3e8-5c78-8330-7ee4a1657536"
                    ]
                },
                "D-43bt": {
                    "questionId": "D-43bt",
                    "chosenOptionIds": [
                        "644dd944-5a2a-533b-9ef0-fadb5054c422"
                    ]
                },
                "D-44.5a": {
                    "questionId": "D-44.5a",
                    "chosenOptionIds": [
                        "b5fc3288-710a-5aef-9842-611757b40967",
                        "bcf5fb9f-f4b9-5f0c-a964-69a4e1a73334"
                    ]
                },
                "D-44a": {
                    "questionId": "D-44a",
                    "chosenOptionIds": [
                        "68a987a9-8bad-50d7-a4d9-e970334f0460"
                    ]
                },
                "D-45a": {
                    "questionId": "D-45a",
                    "freeValue": 21
                },
                "D-46a": {
                    "questionId": "D-46a",
                    "chosenOptionIds": [
                        "c2c0e745-9e7b-5f61-8565-fbc0d5c49f71",
                        "1fd426df-808a-5c68-9b6e-23ff2f128b7d",
                        "d9665b1f-cdd2-5979-895e-27bca6487533",
                        "7492eab7-c625-52d0-a2e3-4bf3332decc0",
                        "4c178978-f79a-58fc-8e73-3a98614c4b6c",
                        "6e49f368-62af-5abb-9034-a58bd534944f",
                        "b3e43bd8-fbe3-5cda-b891-6f11e43993d0",
                        "f0b15a45-ae49-59d2-97a7-33f866b0e629"
                    ]
                },
                "D-49.1a": {
                    "questionId": "D-49.1a",
                    "chosenOptionIds": [
                        "7108af97-c956-5da8-8dfa-518e346c197c"
                    ]
                },
                "D-49.2a": {
                    "questionId": "D-49.2a",
                    "freeValue": 350
                },
                "D-49a": {
                    "questionId": "D-49a",
                    "chosenOptionIds": [
                        "16dc6af1-32a9-59c5-b66c-57f6becea1e0"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b",
                        "1d4beed1-78e5-531c-a255-643fac260dee",
                        "9d1b858c-7064-5a84-80d6-786c18e603b6"
                    ]
                },
                "D-50a": {
                    "questionId": "D-50a",
                    "chosenOptionIds": [
                        "d14da42d-9612-5827-9583-b2bf528130de"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "dde1af45-4686-52c9-b960-369527b28f6c"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "08a0f83f-2f37-5904-9364-4e40deb90e98"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "5d271ac0-beca-56cc-9d0b-adafcd67da53"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "33a5fff0-9587-521f-bc53-0dacc36a0bb2"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "44fd9eb3-aee2-5af0-b83c-dbbbf9514022",
                        "4896b504-2025-52c3-85e2-c6cf3205cef7",
                        "07106ab9-5949-5b5f-bc96-117139ebd397",
                        "afcb6534-a0a0-5fe6-9729-1269be20cdf3"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "83e6efd5-64a2-5f0e-a973-9278eae90cfb"
                    ]
                },
                "BP10": {
                    "questionId": "BP10",
                    "chosenOptionIds": [
                        "7ad54f0a-87a6-5e82-bfc3-44b540230082"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "4a0f1c4d-38cf-5de4-99e3-0ee818f9709c"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "6a5aa899-7b89-5661-a554-71b6058ad4a4"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "1daccd4c-e5ff-5f18-ba55-7c2d2d93b849"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "b24df75b-6bb9-5f5f-99e3-c883ec3644ec"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 26
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "c7bee7a0-3037-5eb1-96a1-85b884d7e1dd"
                    ]
                },
                "BP3.01": {
                    "questionId": "BP3.01",
                    "freeValue": 2
                },
                "BP3.1": {
                    "questionId": "BP3.1",
                    "chosenOptionIds": [
                        "c31cf816-04e0-52f4-8da3-04437042c014"
                    ]
                },
                "BP3.2": {
                    "questionId": "BP3.2",
                    "chosenOptionIds": [
                        "ec333f8b-146a-5700-a15d-527032ceac3f"
                    ]
                },
                "BP3.3": {
                    "questionId": "BP3.3",
                    "freeValue": 2
                },
                "BP3.4": {
                    "questionId": "BP3.4",
                    "chosenOptionIds": [
                        "f304bf93-0c68-5da0-80ba-ccecc2d0fce1"
                    ]
                },
                "BP4": {
                    "questionId": "BP4",
                    "chosenOptionIds": [
                        "9765aba8-7d55-5928-b79d-36aa58646036"
                    ]
                },
                "BP4.5": {
                    "questionId": "BP4.5",
                    "chosenOptionIds": [
                        "66a3460c-65ce-5a4c-9774-2e303ae4961e"
                    ]
                },
                "BP6": {
                    "questionId": "BP6",
                    "chosenOptionIds": [
                        "0f2295c8-9598-5e68-a9a6-c87ca1f600af"
                    ]
                },
                "BP6.1": {
                    "questionId": "BP6.1",
                    "chosenOptionIds": [
                        "430b68c6-29cf-5a15-ab4c-89bd842383bf"
                    ]
                },
                "BP6.2": {
                    "questionId": "BP6.2",
                    "freeValue": 2
                },
                "BP6.3": {
                    "questionId": "BP6.3",
                    "chosenOptionIds": [
                        "a19e0696-e780-5235-88b9-f5c36ce0afc3"
                    ]
                },
                "BP8": {
                    "questionId": "BP8",
                    "chosenOptionIds": [
                        "9b3c44f8-4468-5f37-86e9-ed0908e71d5c"
                    ]
                },
                "BP8.0": {
                    "questionId": "BP8.0",
                    "chosenOptionIds": [
                        "3568cdb1-2500-512b-aada-2547c5bfd835"
                    ]
                },
                "BP8.01": {
                    "questionId": "BP8.01",
                    "chosenOptionIds": [
                        "5020bfdd-a280-56cf-b7f5-3b0b7e667165"
                    ]
                },
                "BP8.02": {
                    "questionId": "BP8.02",
                    "chosenOptionIds": [
                        "e88670a3-c6d8-5c96-914e-b9fc1818aeda"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "387b19c0-67d0-557e-9fba-ae065dcf3587"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "f501dc76-7fcd-586b-ba1d-ad70ab8e8d33"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "2404146e-cd56-5356-a23b-6427b4bbba06"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "fd30dc27-8e89-5d1a-9d95-8844603d4379"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "33e48f82-ae80-5f20-a3a5-79692d21ad1a"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "cc7c09ae-1cb7-5b9a-9cd3-8d1012b90ee8"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 42
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "ef5a7b22-b2b2-5a5b-8a80-ce5c251033fd"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "f35fe383-d2aa-51c4-9fde-961b1de6d52b"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "2555d898-6736-5804-951e-e3c438313029"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "de2ea429-4fcd-53b8-8d9f-ebb69a637a02"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "9b27e3ff-f8ce-54c5-9220-0479ae53e889"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "79075051-dcb3-5321-9f27-b21697f8f194"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "3cf77140-2757-58eb-b4ea-f2a90cd766e7"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "5c06d516-e816-58d6-997e-f8fa91131ac7"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "a020ed85-ec9d-5778-8006-41f634cab09d"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM2": {
                    "questionId": "HM2",
                    "chosenOptionIds": [
                        "de662c3f-66b4-566f-856d-2124e2725542"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "98ea07a0-ae95-570f-8d11-a3e7c682e986"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "696fdd9d-5ced-504b-a933-bb3e575b0099"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c41974b6-d394-569c-8f45-49f79fcac5c6"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "43c32319-c75f-5169-b0e5-713820721d4f"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "b2cb304f-f035-53fc-8763-e2f91b031f9b"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "0170b4cf-0541-53cd-a9db-818d5067dc23"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "efcb2ed0-864a-521d-ac0d-99e0fd4b38f5"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "2a17c2fe-2592-58b1-98e9-3dc4168fe3e3"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "0f2a6025-0d8f-5d89-81a2-c3cc425cd7c4"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "fda00d52-c8be-5b69-bdbd-905c13dddd4e"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "8823c74f-ff7c-52d8-9c73-fd3b4180f48a"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "1d07911e-929d-5719-aeea-a68f593d18e8"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: XE72M0L aspect: simple_bd2 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "bc1523b6-7d57-5471-90cd-884ef1f5ea5a"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "6ede390f-0e43-50e4-82e9-1203db9d8343"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "a0e528ca-2fbb-5da1-9398-0758f79755aa"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 1825
                },
                "D-15a": {
                    "questionId": "D-15a",
                    "chosenOptionIds": [
                        "7b763ff0-7703-54f7-8eae-aa83417ca133"
                    ]
                },
                "D-16.5a": {
                    "questionId": "D-16.5a",
                    "chosenOptionIds": [
                        "b409dbb9-80ef-5de1-96d0-1075735134db"
                    ]
                },
                "D-17a": {
                    "questionId": "D-17a",
                    "chosenOptionIds": [
                        "495d3d07-8a1f-5213-bf79-59443d7aa5cf"
                    ]
                },
                "D-18a": {
                    "questionId": "D-18a",
                    "chosenOptionIds": [
                        "1586e7ea-daf7-5a6d-806f-aee9cc02b7e7"
                    ]
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "80c993c8-1a94-5684-a8f9-d8f5072c8e9c"
                    ]
                },
                "D-1bl": {
                    "questionId": "D-1bl",
                    "chosenOptionIds": [
                        "4105c26a-4ff2-5fd7-984d-f0bcb7b5656e"
                    ]
                },
                "D-2": {
                    "questionId": "D-2",
                    "freeValue": 18
                },
                "D-20a": {
                    "questionId": "D-20a",
                    "chosenOptionIds": [
                        "15c694bd-3089-51d8-8fff-6286e2113f9c"
                    ]
                },
                "D-21a": {
                    "questionId": "D-21a",
                    "chosenOptionIds": [
                        "79fb502d-53ef-5cbb-a08a-c346d1ba9310"
                    ]
                },
                "D-23a": {
                    "questionId": "D-23a",
                    "chosenOptionIds": [
                        "44fe96b8-192b-5b6b-88fa-9c2f7ba73a6d"
                    ]
                },
                "D-26a": {
                    "questionId": "D-26a",
                    "chosenOptionIds": [
                        "eb4d6df4-7e45-5437-a36e-540fa95a45f9"
                    ]
                },
                "D-27a": {
                    "questionId": "D-27a",
                    "chosenOptionIds": [
                        "13a1b08d-9817-5c2f-96cb-f8d03b80a37d"
                    ]
                },
                "D-28a": {
                    "questionId": "D-28a",
                    "chosenOptionIds": [
                        "009661f3-60b5-52e8-bc5f-44c65767874c"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "cc243947-5067-5a98-ade6-a4e10d0b42cf"
                    ]
                },
                "D-2bl": {
                    "questionId": "D-2bl",
                    "chosenOptionIds": [
                        "2a8c96e2-cb5b-596a-99b6-a4885253776c"
                    ]
                },
                "D-2bs": {
                    "questionId": "D-2bs",
                    "chosenOptionIds": [
                        "d9fc40d3-aa51-587a-a54d-305ad26f67ae"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "fd9c4394-6664-5144-8797-00d27a4dda01"
                    ]
                },
                "D-30a": {
                    "questionId": "D-30a",
                    "chosenOptionIds": [
                        "1f6e9ea6-3402-596b-a518-31105c7a277b"
                    ]
                },
                "D-31a": {
                    "questionId": "D-31a",
                    "chosenOptionIds": [
                        "c78e0def-d73e-5c4e-8800-7c6f92d0a1ec"
                    ]
                },
                "D-32a": {
                    "questionId": "D-32a",
                    "chosenOptionIds": [
                        "ea2a4fbd-008a-571b-bb68-cbd0ba351521"
                    ]
                },
                "D-33a": {
                    "questionId": "D-33a",
                    "chosenOptionIds": [
                        "3968dd70-f060-5cbd-bb6a-d63f702a3691"
                    ]
                },
                "D-34a": {
                    "questionId": "D-34a",
                    "chosenOptionIds": [
                        "9d969817-a8d1-57f5-8c15-5e89835ed2a4"
                    ]
                },
                "D-35a": {
                    "questionId": "D-35a",
                    "chosenOptionIds": [
                        "45d31a47-6275-5105-b7d4-eea88b6ba775"
                    ]
                },
                "D-36a": {
                    "questionId": "D-36a",
                    "chosenOptionIds": [
                        "df6b03d1-7322-5277-91ae-7c8e08091a87"
                    ]
                },
                "D-37a": {
                    "questionId": "D-37a",
                    "chosenOptionIds": [
                        "dc9f1b80-2303-5ac8-86ff-e9a39a6c0e16"
                    ]
                },
                "D-38a": {
                    "questionId": "D-38a",
                    "chosenOptionIds": [
                        "cfa17d19-5b15-56ef-82c1-07949e21dd81"
                    ]
                },
                "D-39a": {
                    "questionId": "D-39a",
                    "chosenOptionIds": [
                        "ae31125f-c3db-5082-ae70-4329a2508744"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "f110f5ec-75f1-51be-9c45-ea7685a54d64"
                    ]
                },
                "D-3bl": {
                    "questionId": "D-3bl",
                    "freeValue": 1825
                },
                "D-3bs": {
                    "questionId": "D-3bs",
                    "chosenOptionIds": [
                        "c4422e8a-6a0a-51ea-8592-9157036922d1"
                    ]
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-40a": {
                    "questionId": "D-40a",
                    "chosenOptionIds": [
                        "08157bf8-4ab6-5b83-a59a-9f27ee75995d"
                    ]
                },
                "D-41a": {
                    "questionId": "D-41a",
                    "chosenOptionIds": [
                        "6a5d1a65-0b53-5485-995a-76d8d7eafb1a"
                    ]
                },
                "D-42a": {
                    "questionId": "D-42a",
                    "chosenOptionIds": [
                        "926931de-e675-5117-9e61-78dd541393be"
                    ]
                },
                "D-43a": {
                    "questionId": "D-43a",
                    "chosenOptionIds": [
                        "22b1ff23-c3a7-56b8-8108-65eacd7d3fbe"
                    ]
                },
                "D-43bt": {
                    "questionId": "D-43bt",
                    "chosenOptionIds": [
                        "90e60395-fc3d-56cb-b6f7-2e24fba02803"
                    ]
                },
                "D-44.5a": {
                    "questionId": "D-44.5a",
                    "chosenOptionIds": [
                        "bcf5fb9f-f4b9-5f0c-a964-69a4e1a73334"
                    ]
                },
                "D-44a": {
                    "questionId": "D-44a",
                    "chosenOptionIds": [
                        "68a987a9-8bad-50d7-a4d9-e970334f0460"
                    ]
                },
                "D-45a": {
                    "questionId": "D-45a",
                    "freeValue": 1460
                },
                "D-46a": {
                    "questionId": "D-46a",
                    "chosenOptionIds": [
                        "1fd426df-808a-5c68-9b6e-23ff2f128b7d",
                        "d9665b1f-cdd2-5979-895e-27bca6487533",
                        "7492eab7-c625-52d0-a2e3-4bf3332decc0",
                        "4c178978-f79a-58fc-8e73-3a98614c4b6c",
                        "6e49f368-62af-5abb-9034-a58bd534944f",
                        "b3e43bd8-fbe3-5cda-b891-6f11e43993d0",
                        "f0b15a45-ae49-59d2-97a7-33f866b0e629"
                    ]
                },
                "D-49.1a": {
                    "questionId": "D-49.1a",
                    "chosenOptionIds": [
                        "7108af97-c956-5da8-8dfa-518e346c197c"
                    ]
                },
                "D-49.2a": {
                    "questionId": "D-49.2a",
                    "freeValue": 100
                },
                "D-49a": {
                    "questionId": "D-49a",
                    "chosenOptionIds": [
                        "16dc6af1-32a9-59c5-b66c-57f6becea1e0"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "ba6dc2ba-3c20-57b6-a276-32c863109ff6"
                    ]
                },
                "D-4bs": {
                    "questionId": "D-4bs",
                    "freeValue": 1460
                },
                "D-50a": {
                    "questionId": "D-50a",
                    "chosenOptionIds": [
                        "d14da42d-9612-5827-9583-b2bf528130de"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "188a7831-38c4-55c4-880a-1e4db4e9bab1"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "3e093dd3-857e-5004-a4fb-961fbfa59ece"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "5d271ac0-beca-56cc-9d0b-adafcd67da53"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "67316d94-1cf0-5bd0-a4db-f57bc70684ba"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "60fe0bb4-d770-56f2-b05a-864625a48e4d"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "83e6efd5-64a2-5f0e-a973-9278eae90cfb"
                    ]
                },
                "BP10": {
                    "questionId": "BP10",
                    "chosenOptionIds": [
                        "7ad54f0a-87a6-5e82-bfc3-44b540230082"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "182e121d-415c-5f42-a76c-a996f5f38b3f"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "1c4ed441-5919-569e-98ba-b49be1bc3957"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "e76cac02-f6b2-5ab6-a467-e3025d857ab9"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "f2228920-3fec-5097-a63d-990c25d50c6c"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP105": {
                    "questionId": "BP105",
                    "chosenOptionIds": [
                        "4e9d21b3-29c7-5526-ada9-67b61957994e"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 24
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "c7bee7a0-3037-5eb1-96a1-85b884d7e1dd"
                    ]
                },
                "BP3.01": {
                    "questionId": "BP3.01",
                    "freeValue": 100
                },
                "BP3.1": {
                    "questionId": "BP3.1",
                    "chosenOptionIds": [
                        "11a8ab35-b2d7-55a4-8a77-809dc8f1f78a"
                    ]
                },
                "BP3.2": {
                    "questionId": "BP3.2",
                    "chosenOptionIds": [
                        "ec333f8b-146a-5700-a15d-527032ceac3f"
                    ]
                },
                "BP3.3": {
                    "questionId": "BP3.3",
                    "freeValue": 14
                },
                "BP3.4": {
                    "questionId": "BP3.4",
                    "chosenOptionIds": [
                        "f304bf93-0c68-5da0-80ba-ccecc2d0fce1"
                    ]
                },
                "BP4": {
                    "questionId": "BP4",
                    "chosenOptionIds": [
                        "9765aba8-7d55-5928-b79d-36aa58646036"
                    ]
                },
                "BP4.5": {
                    "questionId": "BP4.5",
                    "chosenOptionIds": [
                        "1be4b3b1-3a88-5544-af54-ebdfee1ce891"
                    ]
                },
                "BP6": {
                    "questionId": "BP6",
                    "chosenOptionIds": [
                        "33ae5abe-63ad-57d4-86a4-0dcd33576094"
                    ]
                },
                "BP6.1": {
                    "questionId": "BP6.1",
                    "chosenOptionIds": [
                        "f0b738f5-44c3-5a6c-9a31-ab7878568a81"
                    ]
                },
                "BP6.2": {
                    "questionId": "BP6.2",
                    "freeValue": 14
                },
                "BP6.3": {
                    "questionId": "BP6.3",
                    "chosenOptionIds": [
                        "a19e0696-e780-5235-88b9-f5c36ce0afc3"
                    ]
                },
                "BP8": {
                    "questionId": "BP8",
                    "chosenOptionIds": [
                        "87e0081b-9455-5ced-9225-10c2f70b1734"
                    ]
                },
                "BP8.0": {
                    "questionId": "BP8.0",
                    "chosenOptionIds": [
                        "3568cdb1-2500-512b-aada-2547c5bfd835"
                    ]
                },
                "BP8.01": {
                    "questionId": "BP8.01",
                    "chosenOptionIds": [
                        "5020bfdd-a280-56cf-b7f5-3b0b7e667165"
                    ]
                },
                "BP8.02": {
                    "questionId": "BP8.02",
                    "chosenOptionIds": [
                        "e88670a3-c6d8-5c96-914e-b9fc1818aeda"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "960a08ff-7af7-56da-afda-5415a1654c85"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "a4ed572c-6aae-57f6-bc35-ea46929c1437"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "2404146e-cd56-5356-a23b-6427b4bbba06"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "fd30dc27-8e89-5d1a-9d95-8844603d4379"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "33e48f82-ae80-5f20-a3a5-79692d21ad1a"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "46c90446-3ae9-53f5-9b57-ad19d66b5be6"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 1460
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "ef5a7b22-b2b2-5a5b-8a80-ce5c251033fd"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "940c16a8-1835-582a-9dad-bd52e3a49cc7"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "1e86d64a-b972-500a-9120-7884e4b86ede"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "6928c696-e6d7-5a14-a004-15a497ac6562"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "2723ed09-37f6-522c-92b1-e518d4fe9315"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "cfb8fe0e-821a-554a-bb57-922f0ecc0f44"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "63509127-aadf-5fe2-8e1f-19d0d846d6ce"
                    ]
                },
                "BP99.1": {
                    "questionId": "BP99.1",
                    "chosenOptionIds": [
                        "34d19f12-207f-56ce-bde8-8d616f6e8b26"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "e81fe613-4923-55ed-bd6c-bd3f19e026f9"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM2": {
                    "questionId": "HM2",
                    "chosenOptionIds": [
                        "a046d610-d27a-59c1-b130-38312005cf1c"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "c3ee8981-dec3-5b3c-80ca-c1023f983a6d"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "a43647cb-a0e8-5705-adb9-a2b68877dc77"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c41974b6-d394-569c-8f45-49f79fcac5c6"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "43c32319-c75f-5169-b0e5-713820721d4f"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "d2c670c4-b05b-597a-a0af-5342230535a0"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "cec918d6-b855-5672-94a8-cf14e5ae963a"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "7b310da1-94e3-5d45-a6c3-3905eeb28ec6"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "2a17c2fe-2592-58b1-98e9-3dc4168fe3e3"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "7e32475a-3fcc-51ac-b414-ab46578efdfd"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "fda00d52-c8be-5b69-bdbd-905c13dddd4e"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "e95276b0-ee81-580b-b532-b0215f04abd5"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "4b8e4843-d260-59e5-8468-1303b1c3a638"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: 7EW6KE8 aspect: simple_bd2 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "3bd18f66-6f1e-5ea4-ae24-2990ed197bef"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "98772fed-cf55-5d42-b4e1-0e6a1a31ce56"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "a0e528ca-2fbb-5da1-9398-0758f79755aa"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 1825
                },
                "D-15a": {
                    "questionId": "D-15a",
                    "chosenOptionIds": [
                        "7b763ff0-7703-54f7-8eae-aa83417ca133"
                    ]
                },
                "D-16.5a": {
                    "questionId": "D-16.5a",
                    "chosenOptionIds": [
                        "b409dbb9-80ef-5de1-96d0-1075735134db"
                    ]
                },
                "D-17a": {
                    "questionId": "D-17a",
                    "chosenOptionIds": [
                        "8413ed32-e959-5c2a-9d19-8cc68709c34d"
                    ]
                },
                "D-19a": {
                    "questionId": "D-19a",
                    "chosenOptionIds": [
                        "6fde9b5c-ed1c-561c-b516-8f5028a1bcd5"
                    ]
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "7f1d6182-f8a7-5f4e-a0f8-2556b306ae36"
                    ]
                },
                "D-1bl": {
                    "questionId": "D-1bl",
                    "chosenOptionIds": [
                        "87ef50c9-ba5e-513e-8889-09d8395d4b9f"
                    ]
                },
                "D-2": {
                    "questionId": "D-2",
                    "freeValue": 25
                },
                "D-20a": {
                    "questionId": "D-20a",
                    "chosenOptionIds": [
                        "15c694bd-3089-51d8-8fff-6286e2113f9c"
                    ]
                },
                "D-21a": {
                    "questionId": "D-21a",
                    "chosenOptionIds": [
                        "dc2b002f-2d96-504d-bde9-66faceececb6"
                    ]
                },
                "D-23a": {
                    "questionId": "D-23a",
                    "chosenOptionIds": [
                        "f6cf63c5-0c57-5422-9431-55fec4c93c63"
                    ]
                },
                "D-26a": {
                    "questionId": "D-26a",
                    "chosenOptionIds": [
                        "400d260d-4647-5f4d-8105-894b04c366f2"
                    ]
                },
                "D-27a": {
                    "questionId": "D-27a",
                    "chosenOptionIds": [
                        "8896026c-15ab-577e-8c44-e63eb7e6dba3"
                    ]
                },
                "D-28a": {
                    "questionId": "D-28a",
                    "chosenOptionIds": [
                        "ffc9781f-639e-5caa-9d2e-16250d30bf6a"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "c695fd1c-2cb7-5654-b9f7-b32ecb158229"
                    ]
                },
                "D-2bl": {
                    "questionId": "D-2bl",
                    "chosenOptionIds": [
                        "2a8c96e2-cb5b-596a-99b6-a4885253776c"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "e797900f-129d-553e-b83f-8494d7f03986"
                    ]
                },
                "D-30a": {
                    "questionId": "D-30a",
                    "chosenOptionIds": [
                        "d309cef4-2204-5a10-a98d-b66740336838"
                    ]
                },
                "D-31a": {
                    "questionId": "D-31a",
                    "chosenOptionIds": [
                        "eba65e81-9b02-55d6-82cb-0b7ce6da41de"
                    ]
                },
                "D-32a": {
                    "questionId": "D-32a",
                    "chosenOptionIds": [
                        "ea2a4fbd-008a-571b-bb68-cbd0ba351521"
                    ]
                },
                "D-33a": {
                    "questionId": "D-33a",
                    "chosenOptionIds": [
                        "3968dd70-f060-5cbd-bb6a-d63f702a3691"
                    ]
                },
                "D-34a": {
                    "questionId": "D-34a",
                    "chosenOptionIds": [
                        "6bfab4b8-7d57-5039-8933-4dee0c958a19"
                    ]
                },
                "D-35a": {
                    "questionId": "D-35a",
                    "chosenOptionIds": [
                        "bcace469-f380-599d-a33a-26db6d0053ad"
                    ]
                },
                "D-36a": {
                    "questionId": "D-36a",
                    "chosenOptionIds": [
                        "4ab32dca-be9e-573e-a330-24f9350ffaca"
                    ]
                },
                "D-37a": {
                    "questionId": "D-37a",
                    "chosenOptionIds": [
                        "c8ebfb14-b9aa-5da4-8de3-ab8b83254249"
                    ]
                },
                "D-38a": {
                    "questionId": "D-38a",
                    "chosenOptionIds": [
                        "8d5bbc66-0b33-5de5-838e-ff0fccbb6fbc"
                    ]
                },
                "D-39a": {
                    "questionId": "D-39a",
                    "chosenOptionIds": [
                        "a2f071cb-74be-518d-a33d-12a101ac9770"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "db7514e2-d317-55fb-82d3-2e4a49c2494b"
                    ]
                },
                "D-3bl": {
                    "questionId": "D-3bl",
                    "freeValue": 60
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-40a": {
                    "questionId": "D-40a",
                    "chosenOptionIds": [
                        "08157bf8-4ab6-5b83-a59a-9f27ee75995d"
                    ]
                },
                "D-41a": {
                    "questionId": "D-41a",
                    "chosenOptionIds": [
                        "cb89e9b0-6872-5961-bc91-049308b5860f"
                    ]
                },
                "D-42a": {
                    "questionId": "D-42a",
                    "chosenOptionIds": [
                        "7099f8a9-4650-5da2-a627-bcd62cd99375"
                    ]
                },
                "D-43a": {
                    "questionId": "D-43a",
                    "chosenOptionIds": [
                        "cd0abe72-b3e8-5c78-8330-7ee4a1657536"
                    ]
                },
                "D-43bt": {
                    "questionId": "D-43bt",
                    "chosenOptionIds": [
                        "644dd944-5a2a-533b-9ef0-fadb5054c422"
                    ]
                },
                "D-44.5a": {
                    "questionId": "D-44.5a",
                    "chosenOptionIds": [
                        "b5fc3288-710a-5aef-9842-611757b40967",
                        "884767e3-ed0c-5db3-a227-f84f7ecaf8d3"
                    ]
                },
                "D-44a": {
                    "questionId": "D-44a",
                    "chosenOptionIds": [
                        "57c0e55d-5ee4-5237-9c5d-e317b90c5dbd"
                    ]
                },
                "D-45a": {
                    "questionId": "D-45a",
                    "freeValue": 0
                },
                "D-46a": {
                    "questionId": "D-46a",
                    "chosenOptionIds": [
                        "1fd426df-808a-5c68-9b6e-23ff2f128b7d",
                        "d9665b1f-cdd2-5979-895e-27bca6487533",
                        "7492eab7-c625-52d0-a2e3-4bf3332decc0",
                        "6e49f368-62af-5abb-9034-a58bd534944f",
                        "4c178978-f79a-58fc-8e73-3a98614c4b6c",
                        "b3e43bd8-fbe3-5cda-b891-6f11e43993d0",
                        "f0b15a45-ae49-59d2-97a7-33f866b0e629"
                    ]
                },
                "D-49.1a": {
                    "questionId": "D-49.1a",
                    "chosenOptionIds": [
                        "7108af97-c956-5da8-8dfa-518e346c197c"
                    ]
                },
                "D-49.2a": {
                    "questionId": "D-49.2a",
                    "freeValue": 7
                },
                "D-49a": {
                    "questionId": "D-49a",
                    "chosenOptionIds": [
                        "16dc6af1-32a9-59c5-b66c-57f6becea1e0"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b"
                    ]
                },
                "D-50a": {
                    "questionId": "D-50a",
                    "chosenOptionIds": [
                        "d14da42d-9612-5827-9583-b2bf528130de"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "dde1af45-4686-52c9-b960-369527b28f6c"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "08a0f83f-2f37-5904-9364-4e40deb90e98"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "772bee29-c3a5-52e1-b785-a77d506ae4fb"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "67316d94-1cf0-5bd0-a4db-f57bc70684ba"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "f8e2518c-72cf-563b-b322-a03406c8ebac"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "60fe0bb4-d770-56f2-b05a-864625a48e4d"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "7599bcaa-89a5-5f71-80ea-b70c360bb44a"
                    ]
                },
                "BP101b": {
                    "questionId": "BP101b",
                    "chosenOptionIds": [
                        "31b2d2c8-8f28-514e-9e58-aa878f6c8761"
                    ]
                },
                "BP102b": {
                    "questionId": "BP102b",
                    "chosenOptionIds": [
                        "00afc1e6-b78a-5223-a6e7-5d3aaab833e0"
                    ]
                },
                "BP103b": {
                    "questionId": "BP103b",
                    "chosenOptionIds": [
                        "867b0ee4-73d9-5aac-b96d-bc2d85d5b183"
                    ]
                },
                "BP104b": {
                    "questionId": "BP104b",
                    "chosenOptionIds": [
                        "c59b5910-3279-509f-8175-f2fd0da2dc86"
                    ]
                },
                "BP87b": {
                    "questionId": "BP87b",
                    "chosenOptionIds": [
                        "fb568891-2ea3-5200-bbdb-ac0695f30b02"
                    ]
                },
                "BP88b": {
                    "questionId": "BP88b",
                    "chosenOptionIds": [
                        "e29e159f-f691-5aa7-b5a0-d13fe2c54170"
                    ]
                },
                "BP89b": {
                    "questionId": "BP89b",
                    "chosenOptionIds": [
                        "db509963-d872-5b97-ba9b-a276f58e0189"
                    ]
                },
                "BP90b": {
                    "questionId": "BP90b",
                    "chosenOptionIds": [
                        "b4a79888-68e8-5b52-9a4e-2149be85f6e7"
                    ]
                },
                "BP92b": {
                    "questionId": "BP92b",
                    "chosenOptionIds": [
                        "f59918ba-eb3a-5cf1-81aa-8b7f6ac0df5e"
                    ]
                },
                "BP94b": {
                    "questionId": "BP94b",
                    "chosenOptionIds": [
                        "1f4fafbf-913e-5edc-bfaf-3baae49fc91d"
                    ]
                },
                "BP95b": {
                    "questionId": "BP95b",
                    "chosenOptionIds": [
                        "32255970-db0f-5cc0-a199-d412edf7ab7c"
                    ]
                },
                "BP96b": {
                    "questionId": "BP96b",
                    "chosenOptionIds": [
                        "8c3d3503-6334-55b9-b0f0-94c8e520094e"
                    ]
                },
                "BP97b": {
                    "questionId": "BP97b",
                    "chosenOptionIds": [
                        "8fa0f169-f510-5c9e-a4be-fbcc964f7480"
                    ]
                },
                "BP98b": {
                    "questionId": "BP98b",
                    "chosenOptionIds": [
                        "1385fe83-520e-5986-8bec-9f0630d48219"
                    ]
                },
                "BP99.1b": {
                    "questionId": "BP99.1b",
                    "chosenOptionIds": [
                        "474c64d7-23b9-5015-9cdd-60661ad38d39"
                    ]
                },
                "BP99.2b": {
                    "questionId": "BP99.2b",
                    "chosenOptionIds": [
                        "32f6041f-9e2c-537c-9db7-19f89011fce9"
                    ]
                },
                "BP99.3b": {
                    "questionId": "BP99.3b",
                    "chosenOptionIds": [
                        "bf30a6c2-f8e2-5305-b4d4-7ad6227449d3"
                    ]
                },
                "BP99b": {
                    "questionId": "BP99b",
                    "chosenOptionIds": [
                        "96faf550-f157-55b9-8f15-6be82cd511a8",
                        "3f987aaa-e435-55a9-9c1b-aa6d2e476738"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "BP_neg1": {
                    "questionId": "BP_neg1",
                    "chosenOptionIds": [
                        "119208b0-ff3b-5c43-80be-4f07e65b6248"
                    ]
                }
            }
        },
        {
            "name": "Mood test: Y32LM08 aspect: simple_bd2 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "c61d70d4-b773-54dd-94d0-d858a47be0fb"
                    ]
                },
                "D-1.5b": {
                    "questionId": "D-1.5b",
                    "freeValue": 4
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "45f0ce85-9e19-53da-bc0d-2af8b383318a"
                    ]
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "7a5958fc-e0f7-5d43-9cd0-abfe95cb7026"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-1bl": {
                    "questionId": "D-1bl",
                    "chosenOptionIds": [
                        "5da400b0-5bff-5744-a664-4305892ae669"
                    ]
                },
                "D-2": {
                    "questionId": "D-2",
                    "freeValue": 28
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "9222be3e-443f-5146-9d69-d48fe9041ba0"
                    ]
                },
                "D-2bl": {
                    "questionId": "D-2bl",
                    "chosenOptionIds": [
                        "2a8c96e2-cb5b-596a-99b6-a4885253776c"
                    ]
                },
                "D-2bt": {
                    "questionId": "D-2bt",
                    "chosenOptionIds": [
                        "90222879-e5d9-50a0-bfbe-a5cbadbff017"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "fd9c4394-6664-5144-8797-00d27a4dda01"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "77c6088d-2d8f-5c3b-89c9-79cb0d317226"
                    ]
                },
                "D-3bl": {
                    "questionId": "D-3bl",
                    "freeValue": 28
                },
                "D-3bt": {
                    "questionId": "D-3bt",
                    "chosenOptionIds": [
                        "b9abc28d-6e22-5b67-bc5e-27431e123974"
                    ]
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-42bt": {
                    "questionId": "D-42bt",
                    "freeValue": 0
                },
                "D-43bt": {
                    "questionId": "D-43bt",
                    "chosenOptionIds": [
                        "644dd944-5a2a-533b-9ef0-fadb5054c422"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "a449e4ae-663b-50fb-9b62-1708b0a32669"
                    ]
                },
                "D-4bt": {
                    "questionId": "D-4bt",
                    "freeValue": 21
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "f3efc764-ca53-52c3-9c3a-38c7f787d415"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "9506449e-b2aa-5498-b896-901dd248b1b3"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "ef6fddab-7a07-5f20-af20-74f2444eee53"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "3b216cf8-72e7-558c-bdd3-cc23a1865674"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "D-s10bt": {
                    "questionId": "D-s10bt",
                    "chosenOptionIds": [
                        "3deea42c-5d93-54cd-ba0f-4242654c3747"
                    ]
                },
                "D-s1bt": {
                    "questionId": "D-s1bt",
                    "chosenOptionIds": [
                        "33dd907f-3e0e-5735-96bb-c101f0acced9"
                    ]
                },
                "D-s2bt": {
                    "questionId": "D-s2bt",
                    "chosenOptionIds": [
                        "e074592a-4667-5b65-b713-b8892451b433"
                    ]
                },
                "D-s3bt": {
                    "questionId": "D-s3bt",
                    "chosenOptionIds": [
                        "11f57889-ac93-53ab-88a7-9aea03a29429"
                    ]
                },
                "D-s4bt": {
                    "questionId": "D-s4bt",
                    "chosenOptionIds": [
                        "fd9851a0-7ce7-55a8-b257-5617537d0013",
                        "27313271-8ec6-5f99-bb2c-0ed50a7c1527"
                    ]
                },
                "D-s5bt": {
                    "questionId": "D-s5bt",
                    "chosenOptionIds": [
                        "d033bb4c-5d3c-519b-be31-1177042bffb4"
                    ]
                },
                "D-s6bt": {
                    "questionId": "D-s6bt",
                    "chosenOptionIds": [
                        "f7aa2ac0-a087-5371-8266-7601901cd1f9"
                    ]
                },
                "D-s7bt": {
                    "questionId": "D-s7bt",
                    "chosenOptionIds": [
                        "d703f0d6-4b67-5a66-8740-b5e4b3b0eb4d"
                    ]
                },
                "D-s8bt": {
                    "questionId": "D-s8bt",
                    "chosenOptionIds": [
                        "b5d48aea-22b2-52a7-9306-3f2a4bcedc6c"
                    ]
                },
                "D-s9bt": {
                    "questionId": "D-s9bt",
                    "chosenOptionIds": [
                        "da503bbd-e567-576e-a21f-e0fad60dccdd"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "44fd9eb3-aee2-5af0-b83c-dbbbf9514022"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "7599bcaa-89a5-5f71-80ea-b70c360bb44a"
                    ]
                },
                "BP101b": {
                    "questionId": "BP101b",
                    "chosenOptionIds": [
                        "cb80bcac-db78-55bd-b051-bdd00801fd54"
                    ]
                },
                "BP102b": {
                    "questionId": "BP102b",
                    "chosenOptionIds": [
                        "46a64b5b-6641-5b1b-92aa-5366444fb134"
                    ]
                },
                "BP103b": {
                    "questionId": "BP103b",
                    "chosenOptionIds": [
                        "867b0ee4-73d9-5aac-b96d-bc2d85d5b183"
                    ]
                },
                "BP104b": {
                    "questionId": "BP104b",
                    "chosenOptionIds": [
                        "c59b5910-3279-509f-8175-f2fd0da2dc86"
                    ]
                },
                "BP87b": {
                    "questionId": "BP87b",
                    "chosenOptionIds": [
                        "7949bd96-3757-5573-bd89-3a91a383cc99"
                    ]
                },
                "BP88b": {
                    "questionId": "BP88b",
                    "chosenOptionIds": [
                        "fa24b924-a8e1-5f02-893b-3d9643007991"
                    ]
                },
                "BP89b": {
                    "questionId": "BP89b",
                    "chosenOptionIds": [
                        "41d2829a-3971-552f-8c5a-c77e7d0073e7"
                    ]
                },
                "BP90b": {
                    "questionId": "BP90b",
                    "chosenOptionIds": [
                        "95c2822d-27ad-5bb0-982f-8c2b949c8918"
                    ]
                },
                "BP92b": {
                    "questionId": "BP92b",
                    "chosenOptionIds": [
                        "b2f4c490-30df-5c8f-87c0-bc4acbcfb8e0"
                    ]
                },
                "BP94b": {
                    "questionId": "BP94b",
                    "chosenOptionIds": [
                        "038cb12b-2783-569a-b1d7-e28c65e591a3"
                    ]
                },
                "BP95b": {
                    "questionId": "BP95b",
                    "chosenOptionIds": [
                        "fb0bd4a7-2793-5b81-badc-fe81b4bc82dc"
                    ]
                },
                "BP96b": {
                    "questionId": "BP96b",
                    "chosenOptionIds": [
                        "8c3d3503-6334-55b9-b0f0-94c8e520094e"
                    ]
                },
                "BP97b": {
                    "questionId": "BP97b",
                    "chosenOptionIds": [
                        "6895096a-ef29-546e-9967-d7a23b4a2859"
                    ]
                },
                "BP98b": {
                    "questionId": "BP98b",
                    "chosenOptionIds": [
                        "01641419-fbfc-52a5-ae63-0fa736ff9847"
                    ]
                },
                "BP99.3b": {
                    "questionId": "BP99.3b",
                    "chosenOptionIds": [
                        "bf30a6c2-f8e2-5305-b4d4-7ad6227449d3"
                    ]
                },
                "BP99b": {
                    "questionId": "BP99b",
                    "chosenOptionIds": [
                        "68860df1-e835-50d6-8eb2-f9d9f2f97efa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "BP_neg1": {
                    "questionId": "BP_neg1",
                    "chosenOptionIds": [
                        "119208b0-ff3b-5c43-80be-4f07e65b6248"
                    ]
                }
            }
        },
        {
            "name": "Mood test: 63YM43R aspect: simple_bd2 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "3bd18f66-6f1e-5ea4-ae24-2990ed197bef"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "6ede390f-0e43-50e4-82e9-1203db9d8343"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "826538fc-8a97-55e4-8013-fe6a93f8e451"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 540
                },
                "D-15a": {
                    "questionId": "D-15a",
                    "chosenOptionIds": [
                        "7b763ff0-7703-54f7-8eae-aa83417ca133"
                    ]
                },
                "D-16.5a": {
                    "questionId": "D-16.5a",
                    "chosenOptionIds": [
                        "ae0a4d4e-e9bf-51bf-8506-0cbced38db92"
                    ]
                },
                "D-17a": {
                    "questionId": "D-17a",
                    "chosenOptionIds": [
                        "495d3d07-8a1f-5213-bf79-59443d7aa5cf"
                    ]
                },
                "D-19a": {
                    "questionId": "D-19a",
                    "chosenOptionIds": [
                        "b956a09d-6c39-5acc-a8d7-9024b1b2a2b3"
                    ]
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "7f1d6182-f8a7-5f4e-a0f8-2556b306ae36"
                    ]
                },
                "D-1bl": {
                    "questionId": "D-1bl",
                    "chosenOptionIds": [
                        "4105c26a-4ff2-5fd7-984d-f0bcb7b5656e"
                    ]
                },
                "D-2": {
                    "questionId": "D-2",
                    "freeValue": 30
                },
                "D-20a": {
                    "questionId": "D-20a",
                    "chosenOptionIds": [
                        "795e06c8-4055-5583-af5a-e7eafe89bb56"
                    ]
                },
                "D-21a": {
                    "questionId": "D-21a",
                    "chosenOptionIds": [
                        "e64b46f6-a0c1-5378-95de-f1de59b491b4"
                    ]
                },
                "D-23a": {
                    "questionId": "D-23a",
                    "chosenOptionIds": [
                        "44fe96b8-192b-5b6b-88fa-9c2f7ba73a6d"
                    ]
                },
                "D-26a": {
                    "questionId": "D-26a",
                    "chosenOptionIds": [
                        "eb4d6df4-7e45-5437-a36e-540fa95a45f9"
                    ]
                },
                "D-27a": {
                    "questionId": "D-27a",
                    "chosenOptionIds": [
                        "13a1b08d-9817-5c2f-96cb-f8d03b80a37d"
                    ]
                },
                "D-28a": {
                    "questionId": "D-28a",
                    "chosenOptionIds": [
                        "801353cf-2f72-53db-9179-e7ecc31b1fc1"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "c695fd1c-2cb7-5654-b9f7-b32ecb158229"
                    ]
                },
                "D-2bl": {
                    "questionId": "D-2bl",
                    "chosenOptionIds": [
                        "2a8c96e2-cb5b-596a-99b6-a4885253776c"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "fd9c4394-6664-5144-8797-00d27a4dda01"
                    ]
                },
                "D-30a": {
                    "questionId": "D-30a",
                    "chosenOptionIds": [
                        "d309cef4-2204-5a10-a98d-b66740336838"
                    ]
                },
                "D-31a": {
                    "questionId": "D-31a",
                    "chosenOptionIds": [
                        "c78e0def-d73e-5c4e-8800-7c6f92d0a1ec"
                    ]
                },
                "D-32a": {
                    "questionId": "D-32a",
                    "chosenOptionIds": [
                        "ea2a4fbd-008a-571b-bb68-cbd0ba351521"
                    ]
                },
                "D-33a": {
                    "questionId": "D-33a",
                    "chosenOptionIds": [
                        "3968dd70-f060-5cbd-bb6a-d63f702a3691"
                    ]
                },
                "D-34a": {
                    "questionId": "D-34a",
                    "chosenOptionIds": [
                        "e919c54f-d9a4-5a15-9ae7-8b6b52607a22"
                    ]
                },
                "D-35a": {
                    "questionId": "D-35a",
                    "chosenOptionIds": [
                        "3a07e052-2332-5a23-bb19-3147a5b9d2c7"
                    ]
                },
                "D-36a": {
                    "questionId": "D-36a",
                    "chosenOptionIds": [
                        "7fb54260-7d7e-5c80-809e-584be2bfee10"
                    ]
                },
                "D-37a": {
                    "questionId": "D-37a",
                    "chosenOptionIds": [
                        "fd4e0608-283c-5f56-a026-edfa381519c9"
                    ]
                },
                "D-38a": {
                    "questionId": "D-38a",
                    "chosenOptionIds": [
                        "39b328fc-f944-51dc-ae4d-9aab44697f32"
                    ]
                },
                "D-39a": {
                    "questionId": "D-39a",
                    "chosenOptionIds": [
                        "6325d970-8ab3-5759-b71d-96b5909ec039"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5b6c0a69-6369-53d1-b2e1-5cd6294a9f71"
                    ]
                },
                "D-3bl": {
                    "questionId": "D-3bl",
                    "freeValue": 365
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-40a": {
                    "questionId": "D-40a",
                    "chosenOptionIds": [
                        "7cc72838-ebcf-5802-933c-4100ebc01407"
                    ]
                },
                "D-41a": {
                    "questionId": "D-41a",
                    "chosenOptionIds": [
                        "89f5ff80-0cae-515a-a4d1-02448dbc11b8"
                    ]
                },
                "D-42a": {
                    "questionId": "D-42a",
                    "chosenOptionIds": [
                        "926931de-e675-5117-9e61-78dd541393be"
                    ]
                },
                "D-43a": {
                    "questionId": "D-43a",
                    "chosenOptionIds": [
                        "dc3faaa6-4a47-5998-83e3-4bbff8838194"
                    ]
                },
                "D-43bt": {
                    "questionId": "D-43bt",
                    "chosenOptionIds": [
                        "644dd944-5a2a-533b-9ef0-fadb5054c422"
                    ]
                },
                "D-44.5a": {
                    "questionId": "D-44.5a",
                    "chosenOptionIds": [
                        "b5fc3288-710a-5aef-9842-611757b40967",
                        "d87971dc-d745-5b64-ac37-37fbe1e722ac"
                    ]
                },
                "D-44a": {
                    "questionId": "D-44a",
                    "chosenOptionIds": [
                        "57c0e55d-5ee4-5237-9c5d-e317b90c5dbd"
                    ]
                },
                "D-45a": {
                    "questionId": "D-45a",
                    "freeValue": 15
                },
                "D-46a": {
                    "questionId": "D-46a",
                    "chosenOptionIds": [
                        "1fd426df-808a-5c68-9b6e-23ff2f128b7d",
                        "d9665b1f-cdd2-5979-895e-27bca6487533",
                        "7492eab7-c625-52d0-a2e3-4bf3332decc0",
                        "6e49f368-62af-5abb-9034-a58bd534944f",
                        "f0b15a45-ae49-59d2-97a7-33f866b0e629",
                        "c2c0e745-9e7b-5f61-8565-fbc0d5c49f71"
                    ]
                },
                "D-49.1a": {
                    "questionId": "D-49.1a",
                    "chosenOptionIds": [
                        "7108af97-c956-5da8-8dfa-518e346c197c"
                    ]
                },
                "D-49.2a": {
                    "questionId": "D-49.2a",
                    "freeValue": 2
                },
                "D-49a": {
                    "questionId": "D-49a",
                    "chosenOptionIds": [
                        "16dc6af1-32a9-59c5-b66c-57f6becea1e0"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "9d1b858c-7064-5a84-80d6-786c18e603b6",
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b"
                    ]
                },
                "D-50a": {
                    "questionId": "D-50a",
                    "chosenOptionIds": [
                        "d14da42d-9612-5827-9583-b2bf528130de"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "c9813959-ba61-5220-9e27-659abb256037"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "3e093dd3-857e-5004-a4fb-961fbfa59ece"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "772bee29-c3a5-52e1-b785-a77d506ae4fb"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "4c78ae3e-7de4-545f-aca1-03fa41fc0c23"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "13f8bac1-d0f5-539b-8596-1c55821bea46"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "44fd9eb3-aee2-5af0-b83c-dbbbf9514022",
                        "c2921864-bbf7-5735-9313-848fb9d5a6d1"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "ccbdcbec-96d0-5362-977b-784289f5cfc0"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "4a0f1c4d-38cf-5de4-99e3-0ee818f9709c"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "6a5aa899-7b89-5661-a554-71b6058ad4a4"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "d3224e16-1b0c-5266-9e14-ac72d2b2e988"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "b24df75b-6bb9-5f5f-99e3-c883ec3644ec"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP12": {
                    "questionId": "BP12",
                    "chosenOptionIds": [
                        "1d6299ac-2ac6-51e2-9a3f-13e8d44e652a"
                    ]
                },
                "BP12.5": {
                    "questionId": "BP12.5",
                    "chosenOptionIds": [
                        "cd7ce00f-4e7c-57ac-9c6c-4137efa24c8c"
                    ]
                },
                "BP14": {
                    "questionId": "BP14",
                    "chosenOptionIds": [
                        "a3126449-b062-5872-80a1-5950acf6bfa4"
                    ]
                },
                "BP14.1": {
                    "questionId": "BP14.1",
                    "chosenOptionIds": [
                        "69f9a784-ce6d-5c82-bd3f-4c9c44bf5992"
                    ]
                },
                "BP14.2": {
                    "questionId": "BP14.2",
                    "freeValue": 365
                },
                "BP14.3": {
                    "questionId": "BP14.3",
                    "chosenOptionIds": [
                        "9f5fc152-75fb-59d8-8f40-7a6ca15c69cb"
                    ]
                },
                "BP16": {
                    "questionId": "BP16",
                    "chosenOptionIds": [
                        "fabceeea-8fea-5444-8b67-8f189f366c27"
                    ]
                },
                "BP16.0": {
                    "questionId": "BP16.0",
                    "chosenOptionIds": [
                        "4b26caa5-9124-51b2-ab08-2f4e977cee1f"
                    ]
                },
                "BP16.01": {
                    "questionId": "BP16.01",
                    "chosenOptionIds": [
                        "f81655d7-78e5-59b1-a7eb-90a2ec7a107d"
                    ]
                },
                "BP16.02": {
                    "questionId": "BP16.02",
                    "chosenOptionIds": [
                        "16a695c9-3ac1-5d34-b799-44602d85b556"
                    ]
                },
                "BP18": {
                    "questionId": "BP18",
                    "chosenOptionIds": [
                        "b577f323-ec54-5d25-a2b9-4f8de30d60a2"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 20
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "f03de28d-8f71-5af4-be2f-45f4e6092a3d"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "e620af65-9b5b-57e6-b13a-65616e67e084"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "a4ed572c-6aae-57f6-bc35-ea46929c1437"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "2404146e-cd56-5356-a23b-6427b4bbba06"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "93bfeb6e-215e-5f4b-b6de-0bc364e05be5"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "e22cf233-c174-52fb-af55-97bf9c46e971"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "46c90446-3ae9-53f5-9b57-ad19d66b5be6"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "d680a8d9-e35f-563f-9d1d-e7c3136aff6d"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "94795194-4a80-590f-be62-ee78f2fa0ff5"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "201c7ee3-56dc-51e9-ad55-69951192ab16"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "dbab3669-e910-5267-b7b1-fcb661ea7e81"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "2723ed09-37f6-522c-92b1-e518d4fe9315"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "58777a2e-4b97-5413-b340-efe722e45936"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "63509127-aadf-5fe2-8e1f-19d0d846d6ce"
                    ]
                },
                "BP99.1": {
                    "questionId": "BP99.1",
                    "chosenOptionIds": [
                        "34d19f12-207f-56ce-bde8-8d616f6e8b26"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "a020ed85-ec9d-5778-8006-41f634cab09d"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM19": {
                    "questionId": "HM19",
                    "chosenOptionIds": [
                        "fbfbcb17-2315-5dc6-a771-7f1051d7d464"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "c3ee8981-dec3-5b3c-80ca-c1023f983a6d"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "a43647cb-a0e8-5705-adb9-a2b68877dc77"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c41974b6-d394-569c-8f45-49f79fcac5c6"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "43c32319-c75f-5169-b0e5-713820721d4f"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "b2cb304f-f035-53fc-8763-e2f91b031f9b"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "0170b4cf-0541-53cd-a9db-818d5067dc23"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "2be66549-5767-51b4-82ed-deeab0723f8d"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "2a17c2fe-2592-58b1-98e9-3dc4168fe3e3"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "834b04f2-af09-5337-8fd5-be4a70c99e75"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "fda00d52-c8be-5b69-bdbd-905c13dddd4e"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "e95276b0-ee81-580b-b532-b0215f04abd5"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "1d07911e-929d-5719-aeea-a68f593d18e8"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: K392M0L aspect: simple_bd2 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "c61d70d4-b773-54dd-94d0-d858a47be0fb"
                    ]
                },
                "D-1.5b": {
                    "questionId": "D-1.5b",
                    "freeValue": 3
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "45f0ce85-9e19-53da-bc0d-2af8b383318a"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "eb68a88d-b49b-5cde-a9c6-5e4c78e27d19"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "a0e528ca-2fbb-5da1-9398-0758f79755aa"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 360
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "7f1d6182-f8a7-5f4e-a0f8-2556b306ae36"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-1bl": {
                    "questionId": "D-1bl",
                    "chosenOptionIds": [
                        "83937123-de06-5b20-a479-c5e3e92848c4"
                    ]
                },
                "D-2": {
                    "questionId": "D-2",
                    "freeValue": 20
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "c695fd1c-2cb7-5654-b9f7-b32ecb158229"
                    ]
                },
                "D-2bl": {
                    "questionId": "D-2bl",
                    "chosenOptionIds": [
                        "78c1434b-e585-5f1d-b291-a1728f789022"
                    ]
                },
                "D-2bt": {
                    "questionId": "D-2bt",
                    "chosenOptionIds": [
                        "618070b6-e9e5-599a-a906-eed44f5c2dd9"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "fd9c4394-6664-5144-8797-00d27a4dda01"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5b6c0a69-6369-53d1-b2e1-5cd6294a9f71"
                    ]
                },
                "D-3bl": {
                    "questionId": "D-3bl",
                    "freeValue": 0.25
                },
                "D-3bt": {
                    "questionId": "D-3bt",
                    "chosenOptionIds": [
                        "b9abc28d-6e22-5b67-bc5e-27431e123974"
                    ]
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-42bt": {
                    "questionId": "D-42bt",
                    "freeValue": 0
                },
                "D-43bt": {
                    "questionId": "D-43bt",
                    "chosenOptionIds": [
                        "644dd944-5a2a-533b-9ef0-fadb5054c422"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "1d4beed1-78e5-531c-a255-643fac260dee"
                    ]
                },
                "D-4bt": {
                    "questionId": "D-4bt",
                    "freeValue": 0.16666666666666666
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "f3efc764-ca53-52c3-9c3a-38c7f787d415"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "772bee29-c3a5-52e1-b785-a77d506ae4fb"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "4c78ae3e-7de4-545f-aca1-03fa41fc0c23"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "13f8bac1-d0f5-539b-8596-1c55821bea46"
                    ]
                },
                "D-s10bt": {
                    "questionId": "D-s10bt",
                    "chosenOptionIds": [
                        "e3ab5219-3af6-5da7-8464-a145eb508a0f"
                    ]
                },
                "D-s11bt": {
                    "questionId": "D-s11bt",
                    "chosenOptionIds": [
                        "9c3fa6af-8b7b-5993-a587-641683d934d5",
                        "6994b88e-cfa5-5986-b9e8-625d17d1147c",
                        "7921bccf-4756-59fe-b678-d536c2ab5945",
                        "dc476220-9479-5def-8a97-20e6152d44af"
                    ]
                },
                "D-s1bt": {
                    "questionId": "D-s1bt",
                    "chosenOptionIds": [
                        "33dd907f-3e0e-5735-96bb-c101f0acced9"
                    ]
                },
                "D-s2bt": {
                    "questionId": "D-s2bt",
                    "chosenOptionIds": [
                        "b75d06ef-eb6c-5e23-bf8c-9b566fa4d9dd"
                    ]
                },
                "D-s3bt": {
                    "questionId": "D-s3bt",
                    "chosenOptionIds": [
                        "11f57889-ac93-53ab-88a7-9aea03a29429"
                    ]
                },
                "D-s4bt": {
                    "questionId": "D-s4bt",
                    "chosenOptionIds": [
                        "5a198500-fc4b-55a1-9521-3abe49eed06f"
                    ]
                },
                "D-s5bt": {
                    "questionId": "D-s5bt",
                    "chosenOptionIds": [
                        "d033bb4c-5d3c-519b-be31-1177042bffb4"
                    ]
                },
                "D-s6bt": {
                    "questionId": "D-s6bt",
                    "chosenOptionIds": [
                        "f7aa2ac0-a087-5371-8266-7601901cd1f9"
                    ]
                },
                "D-s7bt": {
                    "questionId": "D-s7bt",
                    "chosenOptionIds": [
                        "1df67815-0b9f-56ef-bd48-96ed977db141"
                    ]
                },
                "D-s8bt": {
                    "questionId": "D-s8bt",
                    "chosenOptionIds": [
                        "6f5067cf-ef4d-59d9-afa1-b3dfc4ac861f"
                    ]
                },
                "D-s9bt": {
                    "questionId": "D-s9bt",
                    "chosenOptionIds": [
                        "34daded3-efdb-5981-a1fc-e31ec1176383"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "8c599171-d554-5547-9f54-2066c1a711dd",
                        "c2921864-bbf7-5735-9313-848fb9d5a6d1",
                        "07106ab9-5949-5b5f-bc96-117139ebd397"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "6215a3de-a3e4-5f86-a039-1006646e6b93"
                    ]
                },
                "BP106": {
                    "questionId": "BP106",
                    "chosenOptionIds": [
                        "8c35da7e-8c0b-50bb-a037-b80a9f1df3d2"
                    ]
                },
                "BP108": {
                    "questionId": "BP108",
                    "chosenOptionIds": [
                        "6c88c07e-4483-5b5b-b6ad-44cff962863e"
                    ]
                },
                "BP110": {
                    "questionId": "BP110",
                    "freeValue": 180
                },
                "BP110.01": {
                    "questionId": "BP110.01",
                    "chosenOptionIds": [
                        "85bf649a-4487-5b00-8c08-82ad143ea966"
                    ]
                },
                "BP110.1": {
                    "questionId": "BP110.1",
                    "chosenOptionIds": [
                        "9d19a3c0-120d-5dfd-a794-8aa0e8d39e77"
                    ]
                },
                "BP110.2": {
                    "questionId": "BP110.2",
                    "chosenOptionIds": [
                        "faf183ab-b9b4-5f0d-a996-dfa04be4e401"
                    ]
                },
                "BP111": {
                    "questionId": "BP111",
                    "chosenOptionIds": [
                        "059fdaea-ce9e-527c-ae94-e00d2b0dbe01"
                    ]
                },
                "BP111.1": {
                    "questionId": "BP111.1",
                    "chosenOptionIds": [
                        "c532b673-2762-5ed0-8cbb-6f70d9977c20"
                    ]
                },
                "BP111.2": {
                    "questionId": "BP111.2",
                    "chosenOptionIds": [
                        "4c9c60d0-cd73-504d-8b03-b734045376ae"
                    ]
                },
                "BP112": {
                    "questionId": "BP112",
                    "chosenOptionIds": [
                        "6db4ba23-1d8f-58b9-b11e-eaea15417e28"
                    ]
                },
                "BP113": {
                    "questionId": "BP113",
                    "chosenOptionIds": [
                        "b16103eb-2ecf-595e-80e4-30baf575e01b"
                    ]
                },
                "BP114": {
                    "questionId": "BP114",
                    "chosenOptionIds": [
                        "a0206e39-59ad-539c-a2ff-e9fd935cf48b"
                    ]
                },
                "BP115": {
                    "questionId": "BP115",
                    "chosenOptionIds": [
                        "e7b2a68b-de30-51bd-bb56-cda1254d3dbc"
                    ]
                },
                "BP116": {
                    "questionId": "BP116",
                    "chosenOptionIds": [
                        "bce3bb8c-216a-5167-8ec7-35c39096d501"
                    ]
                },
                "BP117": {
                    "questionId": "BP117",
                    "chosenOptionIds": [
                        "354c012f-3484-58ad-9300-d9e29a212ae3"
                    ]
                },
                "BP118": {
                    "questionId": "BP118",
                    "chosenOptionIds": [
                        "e642f54c-d6fc-57f8-a53c-73dbd698d428"
                    ]
                },
                "BP118.1": {
                    "questionId": "BP118.1",
                    "chosenOptionIds": [
                        "3780fe32-30da-5e3b-bad1-6d360381e7e4"
                    ]
                },
                "BP119": {
                    "questionId": "BP119",
                    "chosenOptionIds": [
                        "3f4ab649-e8be-559b-a32f-9ccb317ffb38"
                    ]
                },
                "BP119.1": {
                    "questionId": "BP119.1",
                    "freeValue": 0
                },
                "BP120": {
                    "questionId": "BP120",
                    "chosenOptionIds": [
                        "548c329b-3169-5742-b17d-437216e6a454"
                    ]
                },
                "BP121": {
                    "questionId": "BP121",
                    "chosenOptionIds": [
                        "76891993-4f79-5b7a-ad6f-b7be1c4994fb"
                    ]
                },
                "BP122": {
                    "questionId": "BP122",
                    "chosenOptionIds": [
                        "b1759380-318d-54b0-9224-163fb659558b"
                    ]
                },
                "BP123": {
                    "questionId": "BP123",
                    "chosenOptionIds": [
                        "5c6d0cea-9bce-5b98-b6ad-dcb0e3fb6b0d"
                    ]
                },
                "BP124": {
                    "questionId": "BP124",
                    "chosenOptionIds": [
                        "6410ed4b-d607-5edd-baae-ffeb61769bf8"
                    ]
                },
                "BP125": {
                    "questionId": "BP125",
                    "chosenOptionIds": [
                        "d7075f96-ec23-51f7-9293-9bf725963409"
                    ]
                },
                "BP126": {
                    "questionId": "BP126",
                    "chosenOptionIds": [
                        "7bdce4bf-24ca-5470-a5cd-68c1e5c7ef03"
                    ]
                },
                "BP127": {
                    "questionId": "BP127",
                    "chosenOptionIds": [
                        "5f58bb60-dff1-585c-a5f3-26e34e4dc957"
                    ]
                },
                "BP128": {
                    "questionId": "BP128",
                    "chosenOptionIds": [
                        "ebff4971-05dc-52ed-9957-33c0ca1479c1"
                    ]
                },
                "BP129": {
                    "questionId": "BP129",
                    "chosenOptionIds": [
                        "4b90a2f8-960f-5d69-8e86-9c46af2fec0f"
                    ]
                },
                "BP130": {
                    "questionId": "BP130",
                    "chosenOptionIds": [
                        "7a066bdc-85a0-56cc-8632-2e83740855c1"
                    ]
                },
                "BP131": {
                    "questionId": "BP131",
                    "chosenOptionIds": [
                        "e5863f5d-32a6-520a-9b40-8591adf1c1b8"
                    ]
                },
                "BP132": {
                    "questionId": "BP132",
                    "chosenOptionIds": [
                        "da9000ec-69f6-580b-9ec9-756b702c2962"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM35": {
                    "questionId": "HM35",
                    "chosenOptionIds": [
                        "c5f21a2b-5af8-525d-bfab-0ed035defec0"
                    ]
                },
                "HM36": {
                    "questionId": "HM36",
                    "chosenOptionIds": [
                        "47bfc26b-4237-5a82-9cc4-67d348c4acb0"
                    ]
                },
                "HM37": {
                    "questionId": "HM37",
                    "chosenOptionIds": [
                        "20de534d-f482-51c5-ad87-bd054787fddc"
                    ]
                },
                "HM38": {
                    "questionId": "HM38",
                    "chosenOptionIds": [
                        "5125bbbb-70fb-5adc-a41b-c65c6b96180d"
                    ]
                },
                "HM40": {
                    "questionId": "HM40",
                    "chosenOptionIds": [
                        "596144bf-d0ea-5d17-a2ae-7096c31e2def"
                    ]
                },
                "HM41": {
                    "questionId": "HM41",
                    "chosenOptionIds": [
                        "dd9cb999-d487-51f2-8ca3-f121593feca0"
                    ]
                },
                "HM43": {
                    "questionId": "HM43",
                    "chosenOptionIds": [
                        "a8b07611-dcc0-55ef-ae78-d4ceb7ad42e3"
                    ]
                },
                "HM44": {
                    "questionId": "HM44",
                    "chosenOptionIds": [
                        "3675f391-ff12-51e5-b53f-b851b05414ee"
                    ]
                },
                "HM45": {
                    "questionId": "HM45",
                    "chosenOptionIds": [
                        "28a20a2a-3318-5dba-9e59-86d943d3ac73"
                    ]
                },
                "HM46": {
                    "questionId": "HM46",
                    "chosenOptionIds": [
                        "9f868300-e1df-5567-8ebf-e2f984022e52"
                    ]
                },
                "HM47": {
                    "questionId": "HM47",
                    "chosenOptionIds": [
                        "41ec9370-ba26-5cbc-ad35-d9e578788aa4"
                    ]
                },
                "HM48": {
                    "questionId": "HM48",
                    "chosenOptionIds": [
                        "2db89264-9c8a-54e5-8efc-1b1ca45b0233"
                    ]
                },
                "HM49": {
                    "questionId": "HM49",
                    "chosenOptionIds": [
                        "555cca84-b650-53ab-944e-2289adb21903"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: N0N4J3Q aspect: simple_bd2 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "a16e8e14-524e-53df-b4ef-730a2c8e5f32"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "98772fed-cf55-5d42-b4e1-0e6a1a31ce56"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "826538fc-8a97-55e4-8013-fe6a93f8e451"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 3650
                },
                "D-15a": {
                    "questionId": "D-15a",
                    "chosenOptionIds": [
                        "7b763ff0-7703-54f7-8eae-aa83417ca133"
                    ]
                },
                "D-16.5a": {
                    "questionId": "D-16.5a",
                    "chosenOptionIds": [
                        "b409dbb9-80ef-5de1-96d0-1075735134db"
                    ]
                },
                "D-17a": {
                    "questionId": "D-17a",
                    "chosenOptionIds": [
                        "495d3d07-8a1f-5213-bf79-59443d7aa5cf"
                    ]
                },
                "D-18a": {
                    "questionId": "D-18a",
                    "chosenOptionIds": [
                        "d5dff57c-3626-5197-b54a-ddca85aa8775"
                    ]
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "80c993c8-1a94-5684-a8f9-d8f5072c8e9c"
                    ]
                },
                "D-20a": {
                    "questionId": "D-20a",
                    "chosenOptionIds": [
                        "b28ed556-a386-5556-a96f-ae7708ded1ab"
                    ]
                },
                "D-21a": {
                    "questionId": "D-21a",
                    "chosenOptionIds": [
                        "79fb502d-53ef-5cbb-a08a-c346d1ba9310"
                    ]
                },
                "D-23a": {
                    "questionId": "D-23a",
                    "chosenOptionIds": [
                        "32a2b9d2-46ee-5235-9c03-8fb1fb1e5800"
                    ]
                },
                "D-26a": {
                    "questionId": "D-26a",
                    "chosenOptionIds": [
                        "220827bb-16f1-5e00-9549-b98382b6e2ac"
                    ]
                },
                "D-27a": {
                    "questionId": "D-27a",
                    "chosenOptionIds": [
                        "3d120f97-fb12-5e86-8bb9-8fc93babc803"
                    ]
                },
                "D-28a": {
                    "questionId": "D-28a",
                    "chosenOptionIds": [
                        "009661f3-60b5-52e8-bc5f-44c65767874c"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "cc243947-5067-5a98-ade6-a4e10d0b42cf"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "e797900f-129d-553e-b83f-8494d7f03986"
                    ]
                },
                "D-30a": {
                    "questionId": "D-30a",
                    "chosenOptionIds": [
                        "1f6e9ea6-3402-596b-a518-31105c7a277b"
                    ]
                },
                "D-31a": {
                    "questionId": "D-31a",
                    "chosenOptionIds": [
                        "c78e0def-d73e-5c4e-8800-7c6f92d0a1ec"
                    ]
                },
                "D-32a": {
                    "questionId": "D-32a",
                    "chosenOptionIds": [
                        "ea2a4fbd-008a-571b-bb68-cbd0ba351521"
                    ]
                },
                "D-33a": {
                    "questionId": "D-33a",
                    "chosenOptionIds": [
                        "3968dd70-f060-5cbd-bb6a-d63f702a3691"
                    ]
                },
                "D-34a": {
                    "questionId": "D-34a",
                    "chosenOptionIds": [
                        "6bfab4b8-7d57-5039-8933-4dee0c958a19"
                    ]
                },
                "D-35a": {
                    "questionId": "D-35a",
                    "chosenOptionIds": [
                        "3a07e052-2332-5a23-bb19-3147a5b9d2c7"
                    ]
                },
                "D-36a": {
                    "questionId": "D-36a",
                    "chosenOptionIds": [
                        "185038dd-ce55-53b2-ba09-0bd163af90b3"
                    ]
                },
                "D-37a": {
                    "questionId": "D-37a",
                    "chosenOptionIds": [
                        "fd4e0608-283c-5f56-a026-edfa381519c9"
                    ]
                },
                "D-38a": {
                    "questionId": "D-38a",
                    "chosenOptionIds": [
                        "5ca199a0-50f8-57f0-a098-03c824bb83ba"
                    ]
                },
                "D-39a": {
                    "questionId": "D-39a",
                    "chosenOptionIds": [
                        "ae54b405-473d-53a5-8ded-1108dc74f928"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5b6c0a69-6369-53d1-b2e1-5cd6294a9f71"
                    ]
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-40a": {
                    "questionId": "D-40a",
                    "chosenOptionIds": [
                        "582119a7-d4a0-58ff-9b1d-2af045e11a7f"
                    ]
                },
                "D-41a": {
                    "questionId": "D-41a",
                    "chosenOptionIds": [
                        "6a5d1a65-0b53-5485-995a-76d8d7eafb1a"
                    ]
                },
                "D-42a": {
                    "questionId": "D-42a",
                    "chosenOptionIds": [
                        "926931de-e675-5117-9e61-78dd541393be"
                    ]
                },
                "D-43a": {
                    "questionId": "D-43a",
                    "chosenOptionIds": [
                        "22b1ff23-c3a7-56b8-8108-65eacd7d3fbe"
                    ]
                },
                "D-44.5a": {
                    "questionId": "D-44.5a",
                    "chosenOptionIds": [
                        "d87971dc-d745-5b64-ac37-37fbe1e722ac"
                    ]
                },
                "D-44a": {
                    "questionId": "D-44a",
                    "chosenOptionIds": [
                        "68a987a9-8bad-50d7-a4d9-e970334f0460"
                    ]
                },
                "D-45a": {
                    "questionId": "D-45a",
                    "freeValue": 0
                },
                "D-49a": {
                    "questionId": "D-49a",
                    "chosenOptionIds": [
                        "8eaac5aa-39e4-5f7d-bbe9-ba405b3dba9c"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "9d1b858c-7064-5a84-80d6-786c18e603b6",
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b",
                        "1d4beed1-78e5-531c-a255-643fac260dee"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "188a7831-38c4-55c4-880a-1e4db4e9bab1"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "81e9f63b-35b9-5032-8ea0-462275a87cce"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "5d271ac0-beca-56cc-9d0b-adafcd67da53"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "33a5fff0-9587-521f-bc53-0dacc36a0bb2"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "f8e2518c-72cf-563b-b322-a03406c8ebac"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "c2921864-bbf7-5735-9313-848fb9d5a6d1"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "7599bcaa-89a5-5f71-80ea-b70c360bb44a"
                    ]
                },
                "BP101b": {
                    "questionId": "BP101b",
                    "chosenOptionIds": [
                        "923cb2af-7bb5-5341-8349-003206ae043d"
                    ]
                },
                "BP102b": {
                    "questionId": "BP102b",
                    "chosenOptionIds": [
                        "523c7d8e-e42b-5654-b037-e8837afb49e7"
                    ]
                },
                "BP103b": {
                    "questionId": "BP103b",
                    "chosenOptionIds": [
                        "867b0ee4-73d9-5aac-b96d-bc2d85d5b183"
                    ]
                },
                "BP104b": {
                    "questionId": "BP104b",
                    "chosenOptionIds": [
                        "c59b5910-3279-509f-8175-f2fd0da2dc86"
                    ]
                },
                "BP87b": {
                    "questionId": "BP87b",
                    "chosenOptionIds": [
                        "7949bd96-3757-5573-bd89-3a91a383cc99"
                    ]
                },
                "BP88b": {
                    "questionId": "BP88b",
                    "chosenOptionIds": [
                        "fa24b924-a8e1-5f02-893b-3d9643007991"
                    ]
                },
                "BP89b": {
                    "questionId": "BP89b",
                    "chosenOptionIds": [
                        "41d2829a-3971-552f-8c5a-c77e7d0073e7"
                    ]
                },
                "BP90b": {
                    "questionId": "BP90b",
                    "chosenOptionIds": [
                        "95c2822d-27ad-5bb0-982f-8c2b949c8918"
                    ]
                },
                "BP92b": {
                    "questionId": "BP92b",
                    "chosenOptionIds": [
                        "b2f4c490-30df-5c8f-87c0-bc4acbcfb8e0"
                    ]
                },
                "BP94b": {
                    "questionId": "BP94b",
                    "chosenOptionIds": [
                        "9cf74958-461b-59df-b1a2-7ee7d7696a0b"
                    ]
                },
                "BP95b": {
                    "questionId": "BP95b",
                    "chosenOptionIds": [
                        "32255970-db0f-5cc0-a199-d412edf7ab7c"
                    ]
                },
                "BP96b": {
                    "questionId": "BP96b",
                    "chosenOptionIds": [
                        "e200f36c-3005-5e37-82d3-9228fcf60bfb"
                    ]
                },
                "BP97b": {
                    "questionId": "BP97b",
                    "chosenOptionIds": [
                        "94b68a0a-8608-59d6-a8bb-48cf3c52f891"
                    ]
                },
                "BP98b": {
                    "questionId": "BP98b",
                    "chosenOptionIds": [
                        "01641419-fbfc-52a5-ae63-0fa736ff9847"
                    ]
                },
                "BP99.2b": {
                    "questionId": "BP99.2b",
                    "chosenOptionIds": [
                        "0f933dbb-72b4-5212-b415-8ea806f59368"
                    ]
                },
                "BP99.3b": {
                    "questionId": "BP99.3b",
                    "chosenOptionIds": [
                        "bf30a6c2-f8e2-5305-b4d4-7ad6227449d3"
                    ]
                },
                "BP99b": {
                    "questionId": "BP99b",
                    "chosenOptionIds": [
                        "3f987aaa-e435-55a9-9c1b-aa6d2e476738"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "BP_neg1": {
                    "questionId": "BP_neg1",
                    "chosenOptionIds": [
                        "119208b0-ff3b-5c43-80be-4f07e65b6248"
                    ]
                }
            }
        },
        {
            "name": "Mood test: ME8MOEW aspect: simple_bd2 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "fe031280-7d5d-52cb-aca8-1a8664b3234a"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "98772fed-cf55-5d42-b4e1-0e6a1a31ce56"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "826538fc-8a97-55e4-8013-fe6a93f8e451"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 1095
                },
                "D-15a": {
                    "questionId": "D-15a",
                    "chosenOptionIds": [
                        "7b763ff0-7703-54f7-8eae-aa83417ca133"
                    ]
                },
                "D-16.5a": {
                    "questionId": "D-16.5a",
                    "chosenOptionIds": [
                        "0c4e0b7f-132d-5e75-aeae-fd29d3b2eb67"
                    ]
                },
                "D-17a": {
                    "questionId": "D-17a",
                    "chosenOptionIds": [
                        "8413ed32-e959-5c2a-9d19-8cc68709c34d"
                    ]
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "7f1d6182-f8a7-5f4e-a0f8-2556b306ae36"
                    ]
                },
                "D-1bl": {
                    "questionId": "D-1bl",
                    "chosenOptionIds": [
                        "4105c26a-4ff2-5fd7-984d-f0bcb7b5656e"
                    ]
                },
                "D-2": {
                    "questionId": "D-2",
                    "freeValue": 25
                },
                "D-20a": {
                    "questionId": "D-20a",
                    "chosenOptionIds": [
                        "b28ed556-a386-5556-a96f-ae7708ded1ab"
                    ]
                },
                "D-21a": {
                    "questionId": "D-21a",
                    "chosenOptionIds": [
                        "79fb502d-53ef-5cbb-a08a-c346d1ba9310"
                    ]
                },
                "D-23a": {
                    "questionId": "D-23a",
                    "chosenOptionIds": [
                        "32a2b9d2-46ee-5235-9c03-8fb1fb1e5800"
                    ]
                },
                "D-26a": {
                    "questionId": "D-26a",
                    "chosenOptionIds": [
                        "945792e1-fdf5-5959-89c8-6a1caa4eff88"
                    ]
                },
                "D-27a": {
                    "questionId": "D-27a",
                    "chosenOptionIds": [
                        "3d120f97-fb12-5e86-8bb9-8fc93babc803"
                    ]
                },
                "D-28a": {
                    "questionId": "D-28a",
                    "chosenOptionIds": [
                        "801353cf-2f72-53db-9179-e7ecc31b1fc1"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "c695fd1c-2cb7-5654-b9f7-b32ecb158229"
                    ]
                },
                "D-2bl": {
                    "questionId": "D-2bl",
                    "chosenOptionIds": [
                        "2a8c96e2-cb5b-596a-99b6-a4885253776c"
                    ]
                },
                "D-2bt": {
                    "questionId": "D-2bt",
                    "chosenOptionIds": [
                        "acdba44c-4419-55d5-891f-5c03c9b52309"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "fd9c4394-6664-5144-8797-00d27a4dda01",
                        "bccbebb3-ed8c-5251-856b-e93a1d4386f6",
                        "75d33f2f-6fcd-5e09-8574-899eabeb22e6"
                    ]
                },
                "D-30a": {
                    "questionId": "D-30a",
                    "chosenOptionIds": [
                        "1f6e9ea6-3402-596b-a518-31105c7a277b"
                    ]
                },
                "D-31a": {
                    "questionId": "D-31a",
                    "chosenOptionIds": [
                        "c78e0def-d73e-5c4e-8800-7c6f92d0a1ec"
                    ]
                },
                "D-32.1a": {
                    "questionId": "D-32.1a",
                    "chosenOptionIds": [
                        "536a2dd7-0943-5d01-9537-428496ec789b"
                    ]
                },
                "D-32.2a": {
                    "questionId": "D-32.2a",
                    "chosenOptionIds": [
                        "c3cfa538-c5a3-55dd-8922-622dfa302b7f"
                    ]
                },
                "D-32a": {
                    "questionId": "D-32a",
                    "chosenOptionIds": [
                        "93c47fae-1a07-5ad0-b521-e56d8f62f763"
                    ]
                },
                "D-33a": {
                    "questionId": "D-33a",
                    "chosenOptionIds": [
                        "379331e4-3b82-582c-af51-cc4ed79308d7"
                    ]
                },
                "D-34a": {
                    "questionId": "D-34a",
                    "chosenOptionIds": [
                        "6bfab4b8-7d57-5039-8933-4dee0c958a19"
                    ]
                },
                "D-35a": {
                    "questionId": "D-35a",
                    "chosenOptionIds": [
                        "9c9d44bb-b50f-5ddf-9553-03b254272362"
                    ]
                },
                "D-36a": {
                    "questionId": "D-36a",
                    "chosenOptionIds": [
                        "185038dd-ce55-53b2-ba09-0bd163af90b3"
                    ]
                },
                "D-37a": {
                    "questionId": "D-37a",
                    "chosenOptionIds": [
                        "dc9f1b80-2303-5ac8-86ff-e9a39a6c0e16"
                    ]
                },
                "D-38a": {
                    "questionId": "D-38a",
                    "chosenOptionIds": [
                        "cfa17d19-5b15-56ef-82c1-07949e21dd81"
                    ]
                },
                "D-39a": {
                    "questionId": "D-39a",
                    "chosenOptionIds": [
                        "756b91dc-7358-504f-aeab-84552f2bf28b"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "db7514e2-d317-55fb-82d3-2e4a49c2494b"
                    ]
                },
                "D-3bl": {
                    "questionId": "D-3bl",
                    "freeValue": 5
                },
                "D-3bt": {
                    "questionId": "D-3bt",
                    "chosenOptionIds": [
                        "b9abc28d-6e22-5b67-bc5e-27431e123974"
                    ]
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-40a": {
                    "questionId": "D-40a",
                    "chosenOptionIds": [
                        "582119a7-d4a0-58ff-9b1d-2af045e11a7f"
                    ]
                },
                "D-41a": {
                    "questionId": "D-41a",
                    "chosenOptionIds": [
                        "6a5d1a65-0b53-5485-995a-76d8d7eafb1a"
                    ]
                },
                "D-42a": {
                    "questionId": "D-42a",
                    "chosenOptionIds": [
                        "926931de-e675-5117-9e61-78dd541393be"
                    ]
                },
                "D-42bt": {
                    "questionId": "D-42bt",
                    "freeValue": 0.3333333333333333
                },
                "D-43a": {
                    "questionId": "D-43a",
                    "chosenOptionIds": [
                        "dc3faaa6-4a47-5998-83e3-4bbff8838194"
                    ]
                },
                "D-43bt": {
                    "questionId": "D-43bt",
                    "chosenOptionIds": [
                        "644dd944-5a2a-533b-9ef0-fadb5054c422"
                    ]
                },
                "D-44.5a": {
                    "questionId": "D-44.5a",
                    "chosenOptionIds": [
                        "d87971dc-d745-5b64-ac37-37fbe1e722ac",
                        "bcf5fb9f-f4b9-5f0c-a964-69a4e1a73334"
                    ]
                },
                "D-44a": {
                    "questionId": "D-44a",
                    "chosenOptionIds": [
                        "57c0e55d-5ee4-5237-9c5d-e317b90c5dbd"
                    ]
                },
                "D-45a": {
                    "questionId": "D-45a",
                    "freeValue": 360
                },
                "D-46a": {
                    "questionId": "D-46a",
                    "chosenOptionIds": [
                        "1fd426df-808a-5c68-9b6e-23ff2f128b7d",
                        "d9665b1f-cdd2-5979-895e-27bca6487533",
                        "7492eab7-c625-52d0-a2e3-4bf3332decc0",
                        "f0b15a45-ae49-59d2-97a7-33f866b0e629",
                        "13260a8b-4ec0-5e4d-84fc-1df990527937",
                        "4c178978-f79a-58fc-8e73-3a98614c4b6c",
                        "b3e43bd8-fbe3-5cda-b891-6f11e43993d0",
                        "6e49f368-62af-5abb-9034-a58bd534944f"
                    ]
                },
                "D-49.1a": {
                    "questionId": "D-49.1a",
                    "chosenOptionIds": [
                        "7108af97-c956-5da8-8dfa-518e346c197c"
                    ]
                },
                "D-49.2a": {
                    "questionId": "D-49.2a",
                    "freeValue": 5
                },
                "D-49a": {
                    "questionId": "D-49a",
                    "chosenOptionIds": [
                        "16dc6af1-32a9-59c5-b66c-57f6becea1e0"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "9d1b858c-7064-5a84-80d6-786c18e603b6"
                    ]
                },
                "D-4bt": {
                    "questionId": "D-4bt",
                    "freeValue": 360
                },
                "D-50a": {
                    "questionId": "D-50a",
                    "chosenOptionIds": [
                        "d91f7277-0497-57d2-93a3-ea638d14162c"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "f3efc764-ca53-52c3-9c3a-38c7f787d415"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "3e093dd3-857e-5004-a4fb-961fbfa59ece"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "772bee29-c3a5-52e1-b785-a77d506ae4fb"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "33a5fff0-9587-521f-bc53-0dacc36a0bb2"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "a70aee2a-9d23-554d-b7d3-9f1a025fd82d"
                    ]
                },
                "D-s10bt": {
                    "questionId": "D-s10bt",
                    "chosenOptionIds": [
                        "e7b114de-aed8-536e-8edf-baf8a2a673bf"
                    ]
                },
                "D-s11bt": {
                    "questionId": "D-s11bt",
                    "chosenOptionIds": [
                        "16e1a0b8-28c5-5bcd-931d-335c858c3f88",
                        "6994b88e-cfa5-5986-b9e8-625d17d1147c",
                        "9c3fa6af-8b7b-5993-a587-641683d934d5",
                        "dc476220-9479-5def-8a97-20e6152d44af",
                        "daf8b3c7-fb19-5a3f-ba9a-784adda7414f",
                        "bae5acc8-8664-54a2-ba97-c36244be4c44",
                        "64af3cae-713a-51e1-99c1-09e2fdaa755d",
                        "7921bccf-4756-59fe-b678-d536c2ab5945"
                    ]
                },
                "D-s1bt": {
                    "questionId": "D-s1bt",
                    "chosenOptionIds": [
                        "c17482e4-818a-50cd-90fa-1246d037825e"
                    ]
                },
                "D-s2bt": {
                    "questionId": "D-s2bt",
                    "chosenOptionIds": [
                        "e074592a-4667-5b65-b713-b8892451b433"
                    ]
                },
                "D-s3bt": {
                    "questionId": "D-s3bt",
                    "chosenOptionIds": [
                        "ae77a2d7-a52c-5cdc-8c8d-93e294ade96d"
                    ]
                },
                "D-s4bt": {
                    "questionId": "D-s4bt",
                    "chosenOptionIds": [
                        "27313271-8ec6-5f99-bb2c-0ed50a7c1527"
                    ]
                },
                "D-s5bt": {
                    "questionId": "D-s5bt",
                    "chosenOptionIds": [
                        "e6a5403c-95a3-58c9-9259-e02e5fafd441"
                    ]
                },
                "D-s6bt": {
                    "questionId": "D-s6bt",
                    "chosenOptionIds": [
                        "53625e56-4f03-5f54-a340-d5b8cabe24d9"
                    ]
                },
                "D-s7bt": {
                    "questionId": "D-s7bt",
                    "chosenOptionIds": [
                        "1df67815-0b9f-56ef-bd48-96ed977db141"
                    ]
                },
                "D-s8bt": {
                    "questionId": "D-s8bt",
                    "chosenOptionIds": [
                        "ebfe9c3e-852e-5116-8185-8430f6b361f8"
                    ]
                },
                "D-s9bt": {
                    "questionId": "D-s9bt",
                    "chosenOptionIds": [
                        "8d2e7af7-07c2-5459-8865-609f820de278"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "60fe0bb4-d770-56f2-b05a-864625a48e4d"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "ccbdcbec-96d0-5362-977b-784289f5cfc0"
                    ]
                },
                "BP10": {
                    "questionId": "BP10",
                    "chosenOptionIds": [
                        "7ad54f0a-87a6-5e82-bfc3-44b540230082"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "a20d7b84-720e-53c5-a450-45cbde434507"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "ca5ac96d-2b69-5200-a063-61db840c9ef2"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "1daccd4c-e5ff-5f18-ba55-7c2d2d93b849"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "f2228920-3fec-5097-a63d-990c25d50c6c"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP105": {
                    "questionId": "BP105",
                    "chosenOptionIds": [
                        "978f2ff4-94d9-5f7e-9126-e8657be0c7ca"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 25
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "c7bee7a0-3037-5eb1-96a1-85b884d7e1dd"
                    ]
                },
                "BP3.01": {
                    "questionId": "BP3.01",
                    "freeValue": 2
                },
                "BP3.1": {
                    "questionId": "BP3.1",
                    "chosenOptionIds": [
                        "9531c3ff-ab2b-5198-a490-919ede5afb07"
                    ]
                },
                "BP3.2": {
                    "questionId": "BP3.2",
                    "chosenOptionIds": [
                        "ec333f8b-146a-5700-a15d-527032ceac3f"
                    ]
                },
                "BP3.3": {
                    "questionId": "BP3.3",
                    "freeValue": 60
                },
                "BP3.4": {
                    "questionId": "BP3.4",
                    "chosenOptionIds": [
                        "f304bf93-0c68-5da0-80ba-ccecc2d0fce1"
                    ]
                },
                "BP4": {
                    "questionId": "BP4",
                    "chosenOptionIds": [
                        "9765aba8-7d55-5928-b79d-36aa58646036"
                    ]
                },
                "BP4.5": {
                    "questionId": "BP4.5",
                    "chosenOptionIds": [
                        "1be4b3b1-3a88-5544-af54-ebdfee1ce891"
                    ]
                },
                "BP6": {
                    "questionId": "BP6",
                    "chosenOptionIds": [
                        "52f153db-76e7-57fa-90bd-63d5a923964a"
                    ]
                },
                "BP6.1": {
                    "questionId": "BP6.1",
                    "chosenOptionIds": [
                        "a99773bd-2bff-523e-bc29-1f761307b3d4"
                    ]
                },
                "BP6.2": {
                    "questionId": "BP6.2",
                    "freeValue": 2190
                },
                "BP6.3": {
                    "questionId": "BP6.3",
                    "chosenOptionIds": [
                        "df17fdbe-e756-5ece-ad3d-19cafeef31d1"
                    ]
                },
                "BP8": {
                    "questionId": "BP8",
                    "chosenOptionIds": [
                        "87e0081b-9455-5ced-9225-10c2f70b1734"
                    ]
                },
                "BP8.0": {
                    "questionId": "BP8.0",
                    "chosenOptionIds": [
                        "3568cdb1-2500-512b-aada-2547c5bfd835"
                    ]
                },
                "BP8.01": {
                    "questionId": "BP8.01",
                    "chosenOptionIds": [
                        "a5d71cc4-3c6f-5e37-a507-1dfa312124b8"
                    ]
                },
                "BP8.02": {
                    "questionId": "BP8.02",
                    "chosenOptionIds": [
                        "e88670a3-c6d8-5c96-914e-b9fc1818aeda"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "e620af65-9b5b-57e6-b13a-65616e67e084"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "a4ed572c-6aae-57f6-bc35-ea46929c1437"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "0953f30c-801a-54f6-8238-b9dc42ab17f9"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "fd30dc27-8e89-5d1a-9d95-8844603d4379"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "33e48f82-ae80-5f20-a3a5-79692d21ad1a"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "cc7c09ae-1cb7-5b9a-9cd3-8d1012b90ee8"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "17619692-6d18-5558-99da-766a1c275e03"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "940c16a8-1835-582a-9dad-bd52e3a49cc7"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "2555d898-6736-5804-951e-e3c438313029"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "9b27e3ff-f8ce-54c5-9220-0479ae53e889"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "4a7683c6-774e-5362-8060-740f61986c9b"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "a0a2f2f1-ed73-59bb-add9-5f3569bbbecb"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "63509127-aadf-5fe2-8e1f-19d0d846d6ce"
                    ]
                },
                "BP99.1": {
                    "questionId": "BP99.1",
                    "chosenOptionIds": [
                        "34d19f12-207f-56ce-bde8-8d616f6e8b26"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "a020ed85-ec9d-5778-8006-41f634cab09d"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM2": {
                    "questionId": "HM2",
                    "chosenOptionIds": [
                        "2b56aa7d-493f-5a18-aa9f-f97304f89130"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "6b99de8b-634a-5eb0-bc65-bdf30eb9d433"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "8f7acbc1-9ebe-5a52-95ce-b50d62c27345"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c41974b6-d394-569c-8f45-49f79fcac5c6"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "43c32319-c75f-5169-b0e5-713820721d4f"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "eb41dc92-16ec-5abf-a841-e1d0b262083e"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "0170b4cf-0541-53cd-a9db-818d5067dc23"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "1240a093-e011-5bb9-9dbe-b61af95e9cde"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "2a17c2fe-2592-58b1-98e9-3dc4168fe3e3"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "7e32475a-3fcc-51ac-b414-ab46578efdfd"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "86277249-cf50-5f91-a27f-f3a69344e796"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "bde4e5f3-19e4-571f-84a2-5b9d188d74ef"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "1d07911e-929d-5719-aeea-a68f593d18e8"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: NEL6L3V aspect: simple_bd2 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "0ec0e224-a5cf-5ece-b3ab-37061a600ace"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "a16e8e14-524e-53df-b4ef-730a2c8e5f32"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "c0453e8e-7a9c-5bf5-b8a2-167a61a6387e"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "b74511c7-9f97-5c15-8bf5-6ec4357b4814"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 240
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "7a5958fc-e0f7-5d43-9cd0-abfe95cb7026"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "11f457f5-4c39-5458-ba9a-84478dd379ba"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5b6c0a69-6369-53d1-b2e1-5cd6294a9f71"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "a449e4ae-663b-50fb-9b62-1708b0a32669"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "dde1af45-4686-52c9-b960-369527b28f6c"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "adecf2d3-f302-50f8-a4a9-3ffac1789bc6"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "67316d94-1cf0-5bd0-a4db-f57bc70684ba"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "60fe0bb4-d770-56f2-b05a-864625a48e4d"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "ccbdcbec-96d0-5362-977b-784289f5cfc0"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "a20d7b84-720e-53c5-a450-45cbde434507"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "d8c1997f-d722-536c-ad9d-296b5e10ed04"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "d3224e16-1b0c-5266-9e14-ac72d2b2e988"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "b24df75b-6bb9-5f5f-99e3-c883ec3644ec"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP12": {
                    "questionId": "BP12",
                    "chosenOptionIds": [
                        "1d6299ac-2ac6-51e2-9a3f-13e8d44e652a"
                    ]
                },
                "BP12.5": {
                    "questionId": "BP12.5",
                    "chosenOptionIds": [
                        "cd7ce00f-4e7c-57ac-9c6c-4137efa24c8c"
                    ]
                },
                "BP14": {
                    "questionId": "BP14",
                    "chosenOptionIds": [
                        "97bbe87b-c5ff-51c1-a99c-cec263cff7d4"
                    ]
                },
                "BP14.1": {
                    "questionId": "BP14.1",
                    "chosenOptionIds": [
                        "e1b5b8f7-1317-5136-a8c1-2afdfa91e615"
                    ]
                },
                "BP14.2": {
                    "questionId": "BP14.2",
                    "freeValue": 84
                },
                "BP14.3": {
                    "questionId": "BP14.3",
                    "chosenOptionIds": [
                        "9f5fc152-75fb-59d8-8f40-7a6ca15c69cb"
                    ]
                },
                "BP16": {
                    "questionId": "BP16",
                    "chosenOptionIds": [
                        "fabceeea-8fea-5444-8b67-8f189f366c27"
                    ]
                },
                "BP16.0": {
                    "questionId": "BP16.0",
                    "chosenOptionIds": [
                        "4b26caa5-9124-51b2-ab08-2f4e977cee1f"
                    ]
                },
                "BP16.01": {
                    "questionId": "BP16.01",
                    "chosenOptionIds": [
                        "f81655d7-78e5-59b1-a7eb-90a2ec7a107d"
                    ]
                },
                "BP16.02": {
                    "questionId": "BP16.02",
                    "chosenOptionIds": [
                        "16a695c9-3ac1-5d34-b799-44602d85b556"
                    ]
                },
                "BP18": {
                    "questionId": "BP18",
                    "chosenOptionIds": [
                        "b577f323-ec54-5d25-a2b9-4f8de30d60a2"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 48
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "f03de28d-8f71-5af4-be2f-45f4e6092a3d"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "387b19c0-67d0-557e-9fba-ae065dcf3587"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "a4ed572c-6aae-57f6-bc35-ea46929c1437"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "667ba57c-b1cd-5f9b-aa12-58e65a981910"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "a2056280-4b54-5258-8bb4-407b521fff4b"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "d9fb150b-68b8-50ed-bb1d-eafd41f63f3b"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "0feda24f-7b01-557b-8ca5-10eb924cbdac"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 360
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "d680a8d9-e35f-563f-9d1d-e7c3136aff6d"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "94795194-4a80-590f-be62-ee78f2fa0ff5"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "201c7ee3-56dc-51e9-ad55-69951192ab16"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "de2ea429-4fcd-53b8-8d9f-ebb69a637a02"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "dbab3669-e910-5267-b7b1-fcb661ea7e81"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "4a7683c6-774e-5362-8060-740f61986c9b"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "58777a2e-4b97-5413-b340-efe722e45936"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "5c06d516-e816-58d6-997e-f8fa91131ac7"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "c0cc09c3-1335-5eb4-8801-b3cdaf74f6fa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM19": {
                    "questionId": "HM19",
                    "chosenOptionIds": [
                        "99f474d7-d509-592a-856a-afed343fb1b8"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "c3ee8981-dec3-5b3c-80ca-c1023f983a6d"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "a43647cb-a0e8-5705-adb9-a2b68877dc77"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c41974b6-d394-569c-8f45-49f79fcac5c6"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "43c32319-c75f-5169-b0e5-713820721d4f"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "b2cb304f-f035-53fc-8763-e2f91b031f9b"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "0170b4cf-0541-53cd-a9db-818d5067dc23"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "2be66549-5767-51b4-82ed-deeab0723f8d"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "2a17c2fe-2592-58b1-98e9-3dc4168fe3e3"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "7e32475a-3fcc-51ac-b414-ab46578efdfd"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "86277249-cf50-5f91-a27f-f3a69344e796"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "8823c74f-ff7c-52d8-9c73-fd3b4180f48a"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "4b8e4843-d260-59e5-8468-1303b1c3a638"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: Z3R680O aspect: simple_bd2 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "3bd18f66-6f1e-5ea4-ae24-2990ed197bef"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "9b47900d-e5a7-5e58-830b-d9b3130a7990"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "826538fc-8a97-55e4-8013-fe6a93f8e451"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 4015
                },
                "D-15a": {
                    "questionId": "D-15a",
                    "chosenOptionIds": [
                        "7b763ff0-7703-54f7-8eae-aa83417ca133"
                    ]
                },
                "D-16.5a": {
                    "questionId": "D-16.5a",
                    "chosenOptionIds": [
                        "5f88b934-32eb-5ed5-8896-a818cbe4b033"
                    ]
                },
                "D-17a": {
                    "questionId": "D-17a",
                    "chosenOptionIds": [
                        "8f929de7-3c80-5c53-9cb9-14fee6708b8c"
                    ]
                },
                "D-18a": {
                    "questionId": "D-18a",
                    "chosenOptionIds": [
                        "615b852b-6f81-54a9-bc4a-a8843e82c230"
                    ]
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "db360e93-3616-5d89-be36-5ccee4aacf1c"
                    ]
                },
                "D-20a": {
                    "questionId": "D-20a",
                    "chosenOptionIds": [
                        "d262a22d-afad-541d-82b0-fabd40c42423"
                    ]
                },
                "D-21a": {
                    "questionId": "D-21a",
                    "chosenOptionIds": [
                        "e64b46f6-a0c1-5378-95de-f1de59b491b4"
                    ]
                },
                "D-23a": {
                    "questionId": "D-23a",
                    "chosenOptionIds": [
                        "44fe96b8-192b-5b6b-88fa-9c2f7ba73a6d"
                    ]
                },
                "D-26a": {
                    "questionId": "D-26a",
                    "chosenOptionIds": [
                        "823c244d-25bb-5b84-9f3c-16e82c594aee"
                    ]
                },
                "D-27a": {
                    "questionId": "D-27a",
                    "chosenOptionIds": [
                        "8896026c-15ab-577e-8c44-e63eb7e6dba3"
                    ]
                },
                "D-28a": {
                    "questionId": "D-28a",
                    "chosenOptionIds": [
                        "801353cf-2f72-53db-9179-e7ecc31b1fc1"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "de11fb6b-7e7d-52b1-9d09-2d6debc53314"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "75d33f2f-6fcd-5e09-8574-899eabeb22e6",
                        "14d310b2-5fb6-5f79-8bfa-e3524002e63d",
                        "bccbebb3-ed8c-5251-856b-e93a1d4386f6"
                    ]
                },
                "D-30a": {
                    "questionId": "D-30a",
                    "chosenOptionIds": [
                        "d309cef4-2204-5a10-a98d-b66740336838"
                    ]
                },
                "D-31a": {
                    "questionId": "D-31a",
                    "chosenOptionIds": [
                        "1cb5b1dd-fcae-50f1-b26b-53e0d5e03ed5"
                    ]
                },
                "D-32a": {
                    "questionId": "D-32a",
                    "chosenOptionIds": [
                        "ea2a4fbd-008a-571b-bb68-cbd0ba351521"
                    ]
                },
                "D-33a": {
                    "questionId": "D-33a",
                    "chosenOptionIds": [
                        "379331e4-3b82-582c-af51-cc4ed79308d7"
                    ]
                },
                "D-34a": {
                    "questionId": "D-34a",
                    "chosenOptionIds": [
                        "e919c54f-d9a4-5a15-9ae7-8b6b52607a22"
                    ]
                },
                "D-35a": {
                    "questionId": "D-35a",
                    "chosenOptionIds": [
                        "3a07e052-2332-5a23-bb19-3147a5b9d2c7"
                    ]
                },
                "D-36a": {
                    "questionId": "D-36a",
                    "chosenOptionIds": [
                        "4ab32dca-be9e-573e-a330-24f9350ffaca"
                    ]
                },
                "D-37a": {
                    "questionId": "D-37a",
                    "chosenOptionIds": [
                        "fd4e0608-283c-5f56-a026-edfa381519c9"
                    ]
                },
                "D-38a": {
                    "questionId": "D-38a",
                    "chosenOptionIds": [
                        "8d5bbc66-0b33-5de5-838e-ff0fccbb6fbc"
                    ]
                },
                "D-39a": {
                    "questionId": "D-39a",
                    "chosenOptionIds": [
                        "a2f071cb-74be-518d-a33d-12a101ac9770"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5b6c0a69-6369-53d1-b2e1-5cd6294a9f71"
                    ]
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-40a": {
                    "questionId": "D-40a",
                    "chosenOptionIds": [
                        "582119a7-d4a0-58ff-9b1d-2af045e11a7f"
                    ]
                },
                "D-41a": {
                    "questionId": "D-41a",
                    "chosenOptionIds": [
                        "89f5ff80-0cae-515a-a4d1-02448dbc11b8"
                    ]
                },
                "D-42a": {
                    "questionId": "D-42a",
                    "chosenOptionIds": [
                        "926931de-e675-5117-9e61-78dd541393be"
                    ]
                },
                "D-43a": {
                    "questionId": "D-43a",
                    "chosenOptionIds": [
                        "22b1ff23-c3a7-56b8-8108-65eacd7d3fbe"
                    ]
                },
                "D-44.5a": {
                    "questionId": "D-44.5a",
                    "chosenOptionIds": [
                        "b5fc3288-710a-5aef-9842-611757b40967",
                        "884767e3-ed0c-5db3-a227-f84f7ecaf8d3"
                    ]
                },
                "D-44a": {
                    "questionId": "D-44a",
                    "chosenOptionIds": [
                        "57c0e55d-5ee4-5237-9c5d-e317b90c5dbd"
                    ]
                },
                "D-45a": {
                    "questionId": "D-45a",
                    "freeValue": 0
                },
                "D-46a": {
                    "questionId": "D-46a",
                    "chosenOptionIds": [
                        "c2c0e745-9e7b-5f61-8565-fbc0d5c49f71",
                        "d9665b1f-cdd2-5979-895e-27bca6487533",
                        "7492eab7-c625-52d0-a2e3-4bf3332decc0",
                        "6e49f368-62af-5abb-9034-a58bd534944f",
                        "b3e43bd8-fbe3-5cda-b891-6f11e43993d0"
                    ]
                },
                "D-49a": {
                    "questionId": "D-49a",
                    "chosenOptionIds": [
                        "8eaac5aa-39e4-5f7d-bbe9-ba405b3dba9c"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b",
                        "1d4beed1-78e5-531c-a255-643fac260dee"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "b52073e3-c813-51f5-88ba-9cd3bddd7023"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "3e093dd3-857e-5004-a4fb-961fbfa59ece"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "772bee29-c3a5-52e1-b785-a77d506ae4fb"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "67316d94-1cf0-5bd0-a4db-f57bc70684ba"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "13f8bac1-d0f5-539b-8596-1c55821bea46"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "60fe0bb4-d770-56f2-b05a-864625a48e4d"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "ccbdcbec-96d0-5362-977b-784289f5cfc0"
                    ]
                },
                "BP10": {
                    "questionId": "BP10",
                    "chosenOptionIds": [
                        "7ad54f0a-87a6-5e82-bfc3-44b540230082"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "026a1cea-7cfc-540b-bfe5-42886be90595"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "1c4ed441-5919-569e-98ba-b49be1bc3957"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "d3224e16-1b0c-5266-9e14-ac72d2b2e988"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "b24df75b-6bb9-5f5f-99e3-c883ec3644ec"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 16
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "c7bee7a0-3037-5eb1-96a1-85b884d7e1dd"
                    ]
                },
                "BP3.01": {
                    "questionId": "BP3.01",
                    "freeValue": 12
                },
                "BP3.1": {
                    "questionId": "BP3.1",
                    "chosenOptionIds": [
                        "c053dd45-79bd-59bf-82df-7e9ecd85dc11"
                    ]
                },
                "BP3.2": {
                    "questionId": "BP3.2",
                    "chosenOptionIds": [
                        "ec333f8b-146a-5700-a15d-527032ceac3f"
                    ]
                },
                "BP3.3": {
                    "questionId": "BP3.3",
                    "freeValue": 3
                },
                "BP3.4": {
                    "questionId": "BP3.4",
                    "chosenOptionIds": [
                        "f304bf93-0c68-5da0-80ba-ccecc2d0fce1"
                    ]
                },
                "BP4": {
                    "questionId": "BP4",
                    "chosenOptionIds": [
                        "9765aba8-7d55-5928-b79d-36aa58646036"
                    ]
                },
                "BP4.5": {
                    "questionId": "BP4.5",
                    "chosenOptionIds": [
                        "66a3460c-65ce-5a4c-9774-2e303ae4961e"
                    ]
                },
                "BP6": {
                    "questionId": "BP6",
                    "chosenOptionIds": [
                        "52f153db-76e7-57fa-90bd-63d5a923964a"
                    ]
                },
                "BP6.1": {
                    "questionId": "BP6.1",
                    "chosenOptionIds": [
                        "430b68c6-29cf-5a15-ab4c-89bd842383bf"
                    ]
                },
                "BP6.2": {
                    "questionId": "BP6.2",
                    "freeValue": 3
                },
                "BP6.3": {
                    "questionId": "BP6.3",
                    "chosenOptionIds": [
                        "e061c578-086e-5990-bba6-279cb273f3a5"
                    ]
                },
                "BP8": {
                    "questionId": "BP8",
                    "chosenOptionIds": [
                        "87e0081b-9455-5ced-9225-10c2f70b1734"
                    ]
                },
                "BP8.0": {
                    "questionId": "BP8.0",
                    "chosenOptionIds": [
                        "3568cdb1-2500-512b-aada-2547c5bfd835"
                    ]
                },
                "BP8.01": {
                    "questionId": "BP8.01",
                    "chosenOptionIds": [
                        "5020bfdd-a280-56cf-b7f5-3b0b7e667165"
                    ]
                },
                "BP8.02": {
                    "questionId": "BP8.02",
                    "chosenOptionIds": [
                        "e88670a3-c6d8-5c96-914e-b9fc1818aeda"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "387b19c0-67d0-557e-9fba-ae065dcf3587"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "afb57366-c0b3-5f73-92eb-27bc133ad2ed"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "667ba57c-b1cd-5f9b-aa12-58e65a981910"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "fd30dc27-8e89-5d1a-9d95-8844603d4379"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "e22cf233-c174-52fb-af55-97bf9c46e971"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "1f57d1a0-3682-5d7d-b66b-8179ec5c3988"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "d680a8d9-e35f-563f-9d1d-e7c3136aff6d"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "82997889-d795-572b-a597-e484ef078b39"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "1e86d64a-b972-500a-9120-7884e4b86ede"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "e232ba2d-36a9-524e-9fb2-8541b27bb569"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "352885c9-c1e1-5206-9f52-8d9b6d2aa166"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "b14d53e4-f898-52ac-87d7-3aa62abc9d7b"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "5c06d516-e816-58d6-997e-f8fa91131ac7"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "c0cc09c3-1335-5eb4-8801-b3cdaf74f6fa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM2": {
                    "questionId": "HM2",
                    "chosenOptionIds": [
                        "de662c3f-66b4-566f-856d-2124e2725542"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "98ea07a0-ae95-570f-8d11-a3e7c682e986"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "696fdd9d-5ced-504b-a933-bb3e575b0099"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c41974b6-d394-569c-8f45-49f79fcac5c6"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "43c32319-c75f-5169-b0e5-713820721d4f"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "b2cb304f-f035-53fc-8763-e2f91b031f9b"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "0170b4cf-0541-53cd-a9db-818d5067dc23"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "6b35cd3b-d67a-54af-94f6-e39ddf5af6c6"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "2a17c2fe-2592-58b1-98e9-3dc4168fe3e3"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "834b04f2-af09-5337-8fd5-be4a70c99e75"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "86277249-cf50-5f91-a27f-f3a69344e796"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "8823c74f-ff7c-52d8-9c73-fd3b4180f48a"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "1d07911e-929d-5719-aeea-a68f593d18e8"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: Z35MQ3O aspect: simple_bd2 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "0ec0e224-a5cf-5ece-b3ab-37061a600ace"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "a16e8e14-524e-53df-b4ef-730a2c8e5f32"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "916d657d-9f2a-5451-8465-18e3bd863347"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "b74511c7-9f97-5c15-8bf5-6ec4357b4814"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 365
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "913bc1e9-8da1-54c7-b935-13fc5d48fbd9"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "11f457f5-4c39-5458-ba9a-84478dd379ba"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5c30c60e-d53e-5cc3-b4a9-fdad9bec4cb7"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "f3efc764-ca53-52c3-9c3a-38c7f787d415"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "08a0f83f-2f37-5904-9364-4e40deb90e98"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "adecf2d3-f302-50f8-a4a9-3ffac1789bc6"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "d362cd87-7515-551e-bc74-19ef903484aa"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "44fd9eb3-aee2-5af0-b83c-dbbbf9514022"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "ccbdcbec-96d0-5362-977b-784289f5cfc0"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "4a0f1c4d-38cf-5de4-99e3-0ee818f9709c"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "6a5aa899-7b89-5661-a554-71b6058ad4a4"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "a3a547f3-b48b-567f-a32a-6a3f5f4bacd7"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "b24df75b-6bb9-5f5f-99e3-c883ec3644ec"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP12": {
                    "questionId": "BP12",
                    "chosenOptionIds": [
                        "22f9a995-3f9a-5fe9-99bb-71b7f1f96621"
                    ]
                },
                "BP15": {
                    "questionId": "BP15",
                    "chosenOptionIds": [
                        "d8c37d8b-01b7-581e-9d97-f6c8b8bf9267"
                    ]
                },
                "BP15.1": {
                    "questionId": "BP15.1",
                    "chosenOptionIds": [
                        "35a5b342-38f4-5343-8a4f-a3f024b95530"
                    ]
                },
                "BP15.2": {
                    "questionId": "BP15.2",
                    "freeValue": 1825
                },
                "BP15.3": {
                    "questionId": "BP15.3",
                    "chosenOptionIds": [
                        "e65c7281-c61b-5d1b-80d2-6d817860040c"
                    ]
                },
                "BP16": {
                    "questionId": "BP16",
                    "chosenOptionIds": [
                        "fabceeea-8fea-5444-8b67-8f189f366c27"
                    ]
                },
                "BP16.0": {
                    "questionId": "BP16.0",
                    "chosenOptionIds": [
                        "4b26caa5-9124-51b2-ab08-2f4e977cee1f"
                    ]
                },
                "BP16.01": {
                    "questionId": "BP16.01",
                    "chosenOptionIds": [
                        "f81655d7-78e5-59b1-a7eb-90a2ec7a107d"
                    ]
                },
                "BP16.02": {
                    "questionId": "BP16.02",
                    "chosenOptionIds": [
                        "16a695c9-3ac1-5d34-b799-44602d85b556"
                    ]
                },
                "BP19": {
                    "questionId": "BP19",
                    "chosenOptionIds": [
                        "601bc370-8a4c-5b1f-a7f6-6c174f421733"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 25
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "f03de28d-8f71-5af4-be2f-45f4e6092a3d"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "e620af65-9b5b-57e6-b13a-65616e67e084"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "cb636d32-4f4e-51b3-a23f-2a11b8771b7e"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "92e118de-38b9-5d5a-96ba-7b50547c0621"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "fd30dc27-8e89-5d1a-9d95-8844603d4379"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "e22cf233-c174-52fb-af55-97bf9c46e971"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "46c90446-3ae9-53f5-9b57-ad19d66b5be6"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "d680a8d9-e35f-563f-9d1d-e7c3136aff6d"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "f35fe383-d2aa-51c4-9fde-961b1de6d52b"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "201c7ee3-56dc-51e9-ad55-69951192ab16"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "dbab3669-e910-5267-b7b1-fcb661ea7e81"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "17b4da6f-7135-523c-b90b-2fe3b66785ff"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "3cf77140-2757-58eb-b4ea-f2a90cd766e7"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "5c06d516-e816-58d6-997e-f8fa91131ac7"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "c0cc09c3-1335-5eb4-8801-b3cdaf74f6fa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM20": {
                    "questionId": "HM20",
                    "chosenOptionIds": [
                        "b8816e4b-2104-5ce7-a5b0-b804a1a0dc20"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "c3ee8981-dec3-5b3c-80ca-c1023f983a6d"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "696fdd9d-5ced-504b-a933-bb3e575b0099"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c41974b6-d394-569c-8f45-49f79fcac5c6"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "43c32319-c75f-5169-b0e5-713820721d4f"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "b2cb304f-f035-53fc-8763-e2f91b031f9b"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "0170b4cf-0541-53cd-a9db-818d5067dc23"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "6b35cd3b-d67a-54af-94f6-e39ddf5af6c6"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "2a17c2fe-2592-58b1-98e9-3dc4168fe3e3"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "0f2a6025-0d8f-5d89-81a2-c3cc425cd7c4"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "86277249-cf50-5f91-a27f-f3a69344e796"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "bde4e5f3-19e4-571f-84a2-5b9d188d74ef"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "42710499-d4f3-5f65-bfad-382dde7e2f83"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: REOG402 aspect: simple_bd2 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "c61d70d4-b773-54dd-94d0-d858a47be0fb"
                    ]
                },
                "D-1.5b": {
                    "questionId": "D-1.5b",
                    "freeValue": 3
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "a16e8e14-524e-53df-b4ef-730a2c8e5f32"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "916d657d-9f2a-5451-8465-18e3bd863347"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "b74511c7-9f97-5c15-8bf5-6ec4357b4814"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 730
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "913bc1e9-8da1-54c7-b935-13fc5d48fbd9"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-1bl": {
                    "questionId": "D-1bl",
                    "chosenOptionIds": [
                        "83937123-de06-5b20-a479-c5e3e92848c4"
                    ]
                },
                "D-2": {
                    "questionId": "D-2",
                    "freeValue": 18
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "11f457f5-4c39-5458-ba9a-84478dd379ba"
                    ]
                },
                "D-2bl": {
                    "questionId": "D-2bl",
                    "chosenOptionIds": [
                        "02464c9b-d1d8-5f60-993d-5f37cbfcc102"
                    ]
                },
                "D-2bs": {
                    "questionId": "D-2bs",
                    "chosenOptionIds": [
                        "71e4fa61-0420-5a47-8362-38d1db6b5895"
                    ]
                },
                "D-2bt": {
                    "questionId": "D-2bt",
                    "chosenOptionIds": [
                        "618070b6-e9e5-599a-a906-eed44f5c2dd9"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "fd9c4394-6664-5144-8797-00d27a4dda01",
                        "bccbebb3-ed8c-5251-856b-e93a1d4386f6",
                        "75d33f2f-6fcd-5e09-8574-899eabeb22e6"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5c30c60e-d53e-5cc3-b4a9-fdad9bec4cb7"
                    ]
                },
                "D-3bl": {
                    "questionId": "D-3bl",
                    "freeValue": 0.08333333333333333
                },
                "D-3bs": {
                    "questionId": "D-3bs",
                    "chosenOptionIds": [
                        "fe554f25-dfd7-5062-a2b8-f2662e2c3466"
                    ]
                },
                "D-3bt": {
                    "questionId": "D-3bt",
                    "chosenOptionIds": [
                        "0d89dae6-d14a-56da-8409-ea7c21e59375"
                    ]
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-42bt": {
                    "questionId": "D-42bt",
                    "freeValue": 0
                },
                "D-43bt": {
                    "questionId": "D-43bt",
                    "chosenOptionIds": [
                        "90e60395-fc3d-56cb-b6f7-2e24fba02803"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b"
                    ]
                },
                "D-4bs": {
                    "questionId": "D-4bs",
                    "freeValue": 0.08333333333333333
                },
                "D-4bt": {
                    "questionId": "D-4bt",
                    "freeValue": 0.08333333333333333
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "dde1af45-4686-52c9-b960-369527b28f6c"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "772bee29-c3a5-52e1-b785-a77d506ae4fb"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "d362cd87-7515-551e-bc74-19ef903484aa"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "13f8bac1-d0f5-539b-8596-1c55821bea46"
                    ]
                },
                "D-s10bt": {
                    "questionId": "D-s10bt",
                    "chosenOptionIds": [
                        "ce0e07f8-36b3-5424-a489-abd9de8a21a4"
                    ]
                },
                "D-s1bt": {
                    "questionId": "D-s1bt",
                    "chosenOptionIds": [
                        "33dd907f-3e0e-5735-96bb-c101f0acced9"
                    ]
                },
                "D-s2bt": {
                    "questionId": "D-s2bt",
                    "chosenOptionIds": [
                        "aef7f114-e448-575f-bcbb-035e54001375"
                    ]
                },
                "D-s3bt": {
                    "questionId": "D-s3bt",
                    "chosenOptionIds": [
                        "11f57889-ac93-53ab-88a7-9aea03a29429"
                    ]
                },
                "D-s4bt": {
                    "questionId": "D-s4bt",
                    "chosenOptionIds": [
                        "a4021497-af94-56a4-8075-392653262e2e"
                    ]
                },
                "D-s5bt": {
                    "questionId": "D-s5bt",
                    "chosenOptionIds": [
                        "055edc25-4e0b-55a7-8739-145992d4156a"
                    ]
                },
                "D-s6bt": {
                    "questionId": "D-s6bt",
                    "chosenOptionIds": [
                        "f7aa2ac0-a087-5371-8266-7601901cd1f9"
                    ]
                },
                "D-s7bt": {
                    "questionId": "D-s7bt",
                    "chosenOptionIds": [
                        "1df67815-0b9f-56ef-bd48-96ed977db141"
                    ]
                },
                "D-s8bt": {
                    "questionId": "D-s8bt",
                    "chosenOptionIds": [
                        "ebfe9c3e-852e-5116-8185-8430f6b361f8"
                    ]
                },
                "D-s9bt": {
                    "questionId": "D-s9bt",
                    "chosenOptionIds": [
                        "da503bbd-e567-576e-a21f-e0fad60dccdd"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "60fe0bb4-d770-56f2-b05a-864625a48e4d"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "ccbdcbec-96d0-5362-977b-784289f5cfc0"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "db7aa4b7-99a5-5475-a4e8-a744f3ef5a4a"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "e9eb902a-d327-5ec5-938c-dc317ca59b13"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "d3224e16-1b0c-5266-9e14-ac72d2b2e988"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "b24df75b-6bb9-5f5f-99e3-c883ec3644ec"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "de4464c8-78fe-5876-aa00-25826ce5ec25"
                    ]
                },
                "BP105": {
                    "questionId": "BP105",
                    "chosenOptionIds": [
                        "4e9d21b3-29c7-5526-ada9-67b61957994e"
                    ]
                },
                "BP12": {
                    "questionId": "BP12",
                    "chosenOptionIds": [
                        "22f9a995-3f9a-5fe9-99bb-71b7f1f96621"
                    ]
                },
                "BP15": {
                    "questionId": "BP15",
                    "chosenOptionIds": [
                        "e9643ff4-2842-5b40-9c98-5b5d0e9b6a5e"
                    ]
                },
                "BP15.1": {
                    "questionId": "BP15.1",
                    "chosenOptionIds": [
                        "35a5b342-38f4-5343-8a4f-a3f024b95530"
                    ]
                },
                "BP15.2": {
                    "questionId": "BP15.2",
                    "freeValue": 3650
                },
                "BP15.3": {
                    "questionId": "BP15.3",
                    "chosenOptionIds": [
                        "cfa519ff-08a9-5fa4-b12d-87b7a597cb13"
                    ]
                },
                "BP16": {
                    "questionId": "BP16",
                    "chosenOptionIds": [
                        "fabceeea-8fea-5444-8b67-8f189f366c27"
                    ]
                },
                "BP16.0": {
                    "questionId": "BP16.0",
                    "chosenOptionIds": [
                        "4b26caa5-9124-51b2-ab08-2f4e977cee1f"
                    ]
                },
                "BP16.01": {
                    "questionId": "BP16.01",
                    "chosenOptionIds": [
                        "f81655d7-78e5-59b1-a7eb-90a2ec7a107d"
                    ]
                },
                "BP16.02": {
                    "questionId": "BP16.02",
                    "chosenOptionIds": [
                        "16a695c9-3ac1-5d34-b799-44602d85b556"
                    ]
                },
                "BP19": {
                    "questionId": "BP19",
                    "chosenOptionIds": [
                        "601bc370-8a4c-5b1f-a7f6-6c174f421733"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 30
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "f03de28d-8f71-5af4-be2f-45f4e6092a3d"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "9ae96e50-5c86-58a7-a845-fb6347c7edd1"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "5f694075-3a97-5f8b-8701-2710a43127a9"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "2404146e-cd56-5356-a23b-6427b4bbba06"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "5d5fa968-aeb3-569a-8a71-483ef04b9a6a"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "e22cf233-c174-52fb-af55-97bf9c46e971"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "0feda24f-7b01-557b-8ca5-10eb924cbdac"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "d680a8d9-e35f-563f-9d1d-e7c3136aff6d"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "f35fe383-d2aa-51c4-9fde-961b1de6d52b"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "732c51f9-a0b6-5951-bd7d-eaaec24316ba"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "dbab3669-e910-5267-b7b1-fcb661ea7e81"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "2723ed09-37f6-522c-92b1-e518d4fe9315"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "3cf77140-2757-58eb-b4ea-f2a90cd766e7"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "5c06d516-e816-58d6-997e-f8fa91131ac7"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "c0cc09c3-1335-5eb4-8801-b3cdaf74f6fa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM20": {
                    "questionId": "HM20",
                    "chosenOptionIds": [
                        "4ff0f88c-e951-5e87-a941-815105819115"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "98ea07a0-ae95-570f-8d11-a3e7c682e986"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "696fdd9d-5ced-504b-a933-bb3e575b0099"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c41974b6-d394-569c-8f45-49f79fcac5c6"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "43c32319-c75f-5169-b0e5-713820721d4f"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "d2c670c4-b05b-597a-a0af-5342230535a0"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "0170b4cf-0541-53cd-a9db-818d5067dc23"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "6b35cd3b-d67a-54af-94f6-e39ddf5af6c6"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "0983ccf5-a426-53d0-adbb-1baf6832190c"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "a56fcb96-f868-5d08-a358-9b403eb3cd16"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "fa32b497-4a4b-5076-a391-b6dffa8e1851"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "bde4e5f3-19e4-571f-84a2-5b9d188d74ef"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "1d07911e-929d-5719-aeea-a68f593d18e8"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: 4EG443P aspect: simple_bd2 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "c61d70d4-b773-54dd-94d0-d858a47be0fb"
                    ]
                },
                "D-1.5b": {
                    "questionId": "D-1.5b",
                    "freeValue": 8
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "3bd18f66-6f1e-5ea4-ae24-2990ed197bef"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "eb68a88d-b49b-5cde-a9c6-5e4c78e27d19"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "a0e528ca-2fbb-5da1-9398-0758f79755aa"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 60
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "7f1d6182-f8a7-5f4e-a0f8-2556b306ae36"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-1bl": {
                    "questionId": "D-1bl",
                    "chosenOptionIds": [
                        "4105c26a-4ff2-5fd7-984d-f0bcb7b5656e"
                    ]
                },
                "D-2": {
                    "questionId": "D-2",
                    "freeValue": 13
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "11f457f5-4c39-5458-ba9a-84478dd379ba"
                    ]
                },
                "D-2bl": {
                    "questionId": "D-2bl",
                    "chosenOptionIds": [
                        "02464c9b-d1d8-5f60-993d-5f37cbfcc102"
                    ]
                },
                "D-2bs": {
                    "questionId": "D-2bs",
                    "chosenOptionIds": [
                        "7caa4281-408a-5c83-b2b0-b42968a69322"
                    ]
                },
                "D-2bt": {
                    "questionId": "D-2bt",
                    "chosenOptionIds": [
                        "d585762b-2491-592f-9340-3053d9151b68"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "e797900f-129d-553e-b83f-8494d7f03986"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5c30c60e-d53e-5cc3-b4a9-fdad9bec4cb7"
                    ]
                },
                "D-3bl": {
                    "questionId": "D-3bl",
                    "freeValue": 7
                },
                "D-3bs": {
                    "questionId": "D-3bs",
                    "chosenOptionIds": [
                        "fe554f25-dfd7-5062-a2b8-f2662e2c3466"
                    ]
                },
                "D-3bt": {
                    "questionId": "D-3bt",
                    "chosenOptionIds": [
                        "0d89dae6-d14a-56da-8409-ea7c21e59375"
                    ]
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-42bt": {
                    "questionId": "D-42bt",
                    "freeValue": 0
                },
                "D-43bt": {
                    "questionId": "D-43bt",
                    "chosenOptionIds": [
                        "90e60395-fc3d-56cb-b6f7-2e24fba02803"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "9d1b858c-7064-5a84-80d6-786c18e603b6",
                        "1d4beed1-78e5-531c-a255-643fac260dee"
                    ]
                },
                "D-4bs": {
                    "questionId": "D-4bs",
                    "freeValue": 7
                },
                "D-4bt": {
                    "questionId": "D-4bt",
                    "freeValue": 1
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "188a7831-38c4-55c4-880a-1e4db4e9bab1"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "ef6fddab-7a07-5f20-af20-74f2444eee53"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "4c78ae3e-7de4-545f-aca1-03fa41fc0c23"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "13f8bac1-d0f5-539b-8596-1c55821bea46"
                    ]
                },
                "D-s10bt": {
                    "questionId": "D-s10bt",
                    "chosenOptionIds": [
                        "e3ab5219-3af6-5da7-8464-a145eb508a0f"
                    ]
                },
                "D-s11bt": {
                    "questionId": "D-s11bt",
                    "chosenOptionIds": [
                        "daf8b3c7-fb19-5a3f-ba9a-784adda7414f",
                        "dc476220-9479-5def-8a97-20e6152d44af",
                        "9c3fa6af-8b7b-5993-a587-641683d934d5",
                        "6994b88e-cfa5-5986-b9e8-625d17d1147c",
                        "16e1a0b8-28c5-5bcd-931d-335c858c3f88",
                        "64af3cae-713a-51e1-99c1-09e2fdaa755d",
                        "7921bccf-4756-59fe-b678-d536c2ab5945"
                    ]
                },
                "D-s1bt": {
                    "questionId": "D-s1bt",
                    "chosenOptionIds": [
                        "c17482e4-818a-50cd-90fa-1246d037825e"
                    ]
                },
                "D-s2bt": {
                    "questionId": "D-s2bt",
                    "chosenOptionIds": [
                        "aef7f114-e448-575f-bcbb-035e54001375"
                    ]
                },
                "D-s3bt": {
                    "questionId": "D-s3bt",
                    "chosenOptionIds": [
                        "11f57889-ac93-53ab-88a7-9aea03a29429"
                    ]
                },
                "D-s4bt": {
                    "questionId": "D-s4bt",
                    "chosenOptionIds": [
                        "fd9851a0-7ce7-55a8-b257-5617537d0013",
                        "a4021497-af94-56a4-8075-392653262e2e"
                    ]
                },
                "D-s5bt": {
                    "questionId": "D-s5bt",
                    "chosenOptionIds": [
                        "8260d432-3a96-519e-b824-d24940e4aa65"
                    ]
                },
                "D-s6bt": {
                    "questionId": "D-s6bt",
                    "chosenOptionIds": [
                        "bb7e3f7e-73b0-56b0-b3d3-e336498509e8"
                    ]
                },
                "D-s7bt": {
                    "questionId": "D-s7bt",
                    "chosenOptionIds": [
                        "d703f0d6-4b67-5a66-8740-b5e4b3b0eb4d"
                    ]
                },
                "D-s8bt": {
                    "questionId": "D-s8bt",
                    "chosenOptionIds": [
                        "b5d48aea-22b2-52a7-9306-3f2a4bcedc6c"
                    ]
                },
                "D-s9bt": {
                    "questionId": "D-s9bt",
                    "chosenOptionIds": [
                        "da503bbd-e567-576e-a21f-e0fad60dccdd"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "60fe0bb4-d770-56f2-b05a-864625a48e4d"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "6215a3de-a3e4-5f86-a039-1006646e6b93"
                    ]
                },
                "BP106": {
                    "questionId": "BP106",
                    "chosenOptionIds": [
                        "8c35da7e-8c0b-50bb-a037-b80a9f1df3d2"
                    ]
                },
                "BP108": {
                    "questionId": "BP108",
                    "chosenOptionIds": [
                        "d25b13ec-c438-5509-85b0-83a43b6793d4"
                    ]
                },
                "BP110": {
                    "questionId": "BP110",
                    "freeValue": 60
                },
                "BP110.01": {
                    "questionId": "BP110.01",
                    "chosenOptionIds": [
                        "a6d87c31-9a11-5151-958a-040439463b3d"
                    ]
                },
                "BP110.1": {
                    "questionId": "BP110.1",
                    "chosenOptionIds": [
                        "9d19a3c0-120d-5dfd-a794-8aa0e8d39e77"
                    ]
                },
                "BP110.2": {
                    "questionId": "BP110.2",
                    "chosenOptionIds": [
                        "d15430b9-4f4f-5c97-8716-cb907c561dd2"
                    ]
                },
                "BP111": {
                    "questionId": "BP111",
                    "chosenOptionIds": [
                        "02b20612-0b18-5aba-91d5-3ebd2589fb3d"
                    ]
                },
                "BP111.1": {
                    "questionId": "BP111.1",
                    "chosenOptionIds": [
                        "c532b673-2762-5ed0-8cbb-6f70d9977c20"
                    ]
                },
                "BP111.2": {
                    "questionId": "BP111.2",
                    "chosenOptionIds": [
                        "4c9c60d0-cd73-504d-8b03-b734045376ae"
                    ]
                },
                "BP112": {
                    "questionId": "BP112",
                    "chosenOptionIds": [
                        "6db4ba23-1d8f-58b9-b11e-eaea15417e28"
                    ]
                },
                "BP113": {
                    "questionId": "BP113",
                    "chosenOptionIds": [
                        "b16103eb-2ecf-595e-80e4-30baf575e01b"
                    ]
                },
                "BP114": {
                    "questionId": "BP114",
                    "chosenOptionIds": [
                        "a0206e39-59ad-539c-a2ff-e9fd935cf48b"
                    ]
                },
                "BP115": {
                    "questionId": "BP115",
                    "chosenOptionIds": [
                        "dd02d36a-25b2-5daa-8cc6-5bb2d1382229"
                    ]
                },
                "BP116": {
                    "questionId": "BP116",
                    "chosenOptionIds": [
                        "2d30ef1e-59ee-5b0a-8c01-0dcbfd64f6ca"
                    ]
                },
                "BP117": {
                    "questionId": "BP117",
                    "chosenOptionIds": [
                        "354c012f-3484-58ad-9300-d9e29a212ae3"
                    ]
                },
                "BP118": {
                    "questionId": "BP118",
                    "chosenOptionIds": [
                        "a611c3a0-727b-5a9b-8d15-d805b2ecf757"
                    ]
                },
                "BP118.1": {
                    "questionId": "BP118.1",
                    "chosenOptionIds": [
                        "801fd4db-2f09-5e10-a9b7-43319a3ac1d0"
                    ]
                },
                "BP119": {
                    "questionId": "BP119",
                    "chosenOptionIds": [
                        "50c573e0-accf-5acc-be15-616cb65704cc"
                    ]
                },
                "BP119.1": {
                    "questionId": "BP119.1",
                    "freeValue": 0
                },
                "BP120": {
                    "questionId": "BP120",
                    "chosenOptionIds": [
                        "c8162ccf-aae0-5678-aeb9-fe02ed13ad99"
                    ]
                },
                "BP121": {
                    "questionId": "BP121",
                    "chosenOptionIds": [
                        "7caa27c0-01f2-586f-825c-7c1bd527ef54"
                    ]
                },
                "BP122": {
                    "questionId": "BP122",
                    "chosenOptionIds": [
                        "b1759380-318d-54b0-9224-163fb659558b"
                    ]
                },
                "BP123": {
                    "questionId": "BP123",
                    "chosenOptionIds": [
                        "972a0931-d7f1-5ad4-90af-91046fd7b193"
                    ]
                },
                "BP124": {
                    "questionId": "BP124",
                    "chosenOptionIds": [
                        "343ddd19-6dd5-54fb-a817-730184c2faf1"
                    ]
                },
                "BP125": {
                    "questionId": "BP125",
                    "chosenOptionIds": [
                        "8136d074-8df6-5a91-b6b6-e8ffcfb42d7c"
                    ]
                },
                "BP126": {
                    "questionId": "BP126",
                    "chosenOptionIds": [
                        "52d061e3-96b8-5053-893a-03758e4f0518"
                    ]
                },
                "BP127": {
                    "questionId": "BP127",
                    "chosenOptionIds": [
                        "1fc78738-6afa-5714-89a1-8d5698fb9f69"
                    ]
                },
                "BP127.1": {
                    "questionId": "BP127.1",
                    "chosenOptionIds": [
                        "a2bbf0b5-4203-5b73-89f8-05f59515356f"
                    ]
                },
                "BP127.3": {
                    "questionId": "BP127.3",
                    "chosenOptionIds": [
                        "38efc61e-4155-51f0-8b64-341f627e5482"
                    ]
                },
                "BP128": {
                    "questionId": "BP128",
                    "chosenOptionIds": [
                        "5bf8ce18-aa1a-5c57-86d6-687efcad92de"
                    ]
                },
                "BP129": {
                    "questionId": "BP129",
                    "chosenOptionIds": [
                        "4b90a2f8-960f-5d69-8e86-9c46af2fec0f"
                    ]
                },
                "BP130": {
                    "questionId": "BP130",
                    "chosenOptionIds": [
                        "a04d679f-abeb-5fb0-9db7-2ed9997ab672"
                    ]
                },
                "BP131": {
                    "questionId": "BP131",
                    "chosenOptionIds": [
                        "e5863f5d-32a6-520a-9b40-8591adf1c1b8"
                    ]
                },
                "BP132": {
                    "questionId": "BP132",
                    "chosenOptionIds": [
                        "7da9fe3a-3186-562a-b4f2-170d5baf0c76"
                    ]
                },
                "BP133": {
                    "questionId": "BP133",
                    "chosenOptionIds": [
                        "75391070-9bde-59e4-b44a-68f38b6a4472"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM35": {
                    "questionId": "HM35",
                    "chosenOptionIds": [
                        "2b40020d-33ba-5560-9075-d482aa324496"
                    ]
                },
                "HM36": {
                    "questionId": "HM36",
                    "chosenOptionIds": [
                        "fc7ec28a-9883-58d5-a088-f3b113c2b4b3"
                    ]
                },
                "HM37": {
                    "questionId": "HM37",
                    "chosenOptionIds": [
                        "20de534d-f482-51c5-ad87-bd054787fddc"
                    ]
                },
                "HM38": {
                    "questionId": "HM38",
                    "chosenOptionIds": [
                        "5125bbbb-70fb-5adc-a41b-c65c6b96180d"
                    ]
                },
                "HM40": {
                    "questionId": "HM40",
                    "chosenOptionIds": [
                        "596144bf-d0ea-5d17-a2ae-7096c31e2def"
                    ]
                },
                "HM41": {
                    "questionId": "HM41",
                    "chosenOptionIds": [
                        "db39ddc0-79fd-5c61-b063-7f0bc0bab024"
                    ]
                },
                "HM43": {
                    "questionId": "HM43",
                    "chosenOptionIds": [
                        "08b88c09-4278-5bd5-b72d-4f4a9a69acd0"
                    ]
                },
                "HM44": {
                    "questionId": "HM44",
                    "chosenOptionIds": [
                        "00b843b3-a869-5c49-8c2b-f15c3bfbbb6d"
                    ]
                },
                "HM45": {
                    "questionId": "HM45",
                    "chosenOptionIds": [
                        "2722d440-372d-5471-8cab-ef78b8026a24"
                    ]
                },
                "HM46": {
                    "questionId": "HM46",
                    "chosenOptionIds": [
                        "9f868300-e1df-5567-8ebf-e2f984022e52"
                    ]
                },
                "HM47": {
                    "questionId": "HM47",
                    "chosenOptionIds": [
                        "e03a7683-7e3e-563d-b531-33e529887c90"
                    ]
                },
                "HM48": {
                    "questionId": "HM48",
                    "chosenOptionIds": [
                        "2db89264-9c8a-54e5-8efc-1b1ca45b0233"
                    ]
                },
                "HM49": {
                    "questionId": "HM49",
                    "chosenOptionIds": [
                        "ad65e31f-19f9-587c-bc99-0b3549cc5cb0"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: 73ZW63R aspect: simple_bd2 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "c61d70d4-b773-54dd-94d0-d858a47be0fb"
                    ]
                },
                "D-1.5b": {
                    "questionId": "D-1.5b",
                    "freeValue": 100
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "a16e8e14-524e-53df-b4ef-730a2c8e5f32"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "eb68a88d-b49b-5cde-a9c6-5e4c78e27d19"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "a0e528ca-2fbb-5da1-9398-0758f79755aa"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 3650
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "db360e93-3616-5d89-be36-5ccee4aacf1c"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-1bl": {
                    "questionId": "D-1bl",
                    "chosenOptionIds": [
                        "5da400b0-5bff-5744-a664-4305892ae669"
                    ]
                },
                "D-2": {
                    "questionId": "D-2",
                    "freeValue": 6
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "11f457f5-4c39-5458-ba9a-84478dd379ba"
                    ]
                },
                "D-2bl": {
                    "questionId": "D-2bl",
                    "chosenOptionIds": [
                        "2a8c96e2-cb5b-596a-99b6-a4885253776c"
                    ]
                },
                "D-2bs": {
                    "questionId": "D-2bs",
                    "chosenOptionIds": [
                        "7caa4281-408a-5c83-b2b0-b42968a69322"
                    ]
                },
                "D-2bt": {
                    "questionId": "D-2bt",
                    "chosenOptionIds": [
                        "d585762b-2491-592f-9340-3053d9151b68"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "e797900f-129d-553e-b83f-8494d7f03986"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "db7514e2-d317-55fb-82d3-2e4a49c2494b"
                    ]
                },
                "D-3bl": {
                    "questionId": "D-3bl",
                    "freeValue": 180
                },
                "D-3bs": {
                    "questionId": "D-3bs",
                    "chosenOptionIds": [
                        "c4422e8a-6a0a-51ea-8592-9157036922d1"
                    ]
                },
                "D-3bt": {
                    "questionId": "D-3bt",
                    "chosenOptionIds": [
                        "1a1865e2-c338-59ef-a2db-6e2cc7a17d77"
                    ]
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-42bt": {
                    "questionId": "D-42bt",
                    "freeValue": 2
                },
                "D-43bt": {
                    "questionId": "D-43bt",
                    "chosenOptionIds": [
                        "90e60395-fc3d-56cb-b6f7-2e24fba02803"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "9d1b858c-7064-5a84-80d6-786c18e603b6",
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b"
                    ]
                },
                "D-4bs": {
                    "questionId": "D-4bs",
                    "freeValue": 90
                },
                "D-4bt": {
                    "questionId": "D-4bt",
                    "freeValue": 30
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "b52073e3-c813-51f5-88ba-9cd3bddd7023"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "08a0f83f-2f37-5904-9364-4e40deb90e98"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "772bee29-c3a5-52e1-b785-a77d506ae4fb"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "67316d94-1cf0-5bd0-a4db-f57bc70684ba"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "f8e2518c-72cf-563b-b322-a03406c8ebac"
                    ]
                },
                "D-s10bt": {
                    "questionId": "D-s10bt",
                    "chosenOptionIds": [
                        "e3ab5219-3af6-5da7-8464-a145eb508a0f"
                    ]
                },
                "D-s11bt": {
                    "questionId": "D-s11bt",
                    "chosenOptionIds": [
                        "daf8b3c7-fb19-5a3f-ba9a-784adda7414f",
                        "dc476220-9479-5def-8a97-20e6152d44af",
                        "9c3fa6af-8b7b-5993-a587-641683d934d5",
                        "6994b88e-cfa5-5986-b9e8-625d17d1147c",
                        "16e1a0b8-28c5-5bcd-931d-335c858c3f88",
                        "bae5acc8-8664-54a2-ba97-c36244be4c44",
                        "64af3cae-713a-51e1-99c1-09e2fdaa755d",
                        "7921bccf-4756-59fe-b678-d536c2ab5945",
                        "049f46ae-b7bd-5a1e-8c63-351f481236ec"
                    ]
                },
                "D-s1bt": {
                    "questionId": "D-s1bt",
                    "chosenOptionIds": [
                        "5ebc2bd4-4b83-5875-b7b0-dd3698a7674c"
                    ]
                },
                "D-s2bt": {
                    "questionId": "D-s2bt",
                    "chosenOptionIds": [
                        "8783a6d5-cd94-5982-8fd6-66ae46e1c6df"
                    ]
                },
                "D-s3bt": {
                    "questionId": "D-s3bt",
                    "chosenOptionIds": [
                        "d065c0ca-9e6d-5300-8e3e-688b16f88c59"
                    ]
                },
                "D-s4bt": {
                    "questionId": "D-s4bt",
                    "chosenOptionIds": [
                        "fd9851a0-7ce7-55a8-b257-5617537d0013",
                        "5a198500-fc4b-55a1-9521-3abe49eed06f",
                        "a4021497-af94-56a4-8075-392653262e2e"
                    ]
                },
                "D-s5bt": {
                    "questionId": "D-s5bt",
                    "chosenOptionIds": [
                        "8260d432-3a96-519e-b824-d24940e4aa65"
                    ]
                },
                "D-s6bt": {
                    "questionId": "D-s6bt",
                    "chosenOptionIds": [
                        "57e8cb6f-9ce4-5078-acb5-c3f96ce8e463"
                    ]
                },
                "D-s7bt": {
                    "questionId": "D-s7bt",
                    "chosenOptionIds": [
                        "1df67815-0b9f-56ef-bd48-96ed977db141"
                    ]
                },
                "D-s8bt": {
                    "questionId": "D-s8bt",
                    "chosenOptionIds": [
                        "1a4dbf6d-5e13-509e-b1f1-1f8aa1072529"
                    ]
                },
                "D-s9bt": {
                    "questionId": "D-s9bt",
                    "chosenOptionIds": [
                        "c9f1ca13-44a9-5bfb-8999-eb1f82965c59"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "60fe0bb4-d770-56f2-b05a-864625a48e4d"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "83e6efd5-64a2-5f0e-a973-9278eae90cfb"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "a20d7b84-720e-53c5-a450-45cbde434507"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "ca5ac96d-2b69-5200-a063-61db840c9ef2"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "e76cac02-f6b2-5ab6-a467-e3025d857ab9"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "b24df75b-6bb9-5f5f-99e3-c883ec3644ec"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 12
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "c7bee7a0-3037-5eb1-96a1-85b884d7e1dd"
                    ]
                },
                "BP3.01": {
                    "questionId": "BP3.01",
                    "freeValue": 3
                },
                "BP3.1": {
                    "questionId": "BP3.1",
                    "chosenOptionIds": [
                        "11a8ab35-b2d7-55a4-8a77-809dc8f1f78a"
                    ]
                },
                "BP3.2": {
                    "questionId": "BP3.2",
                    "chosenOptionIds": [
                        "f6654515-5729-5299-a4f0-da99056e7ac9"
                    ]
                },
                "BP3.3": {
                    "questionId": "BP3.3",
                    "freeValue": 30
                },
                "BP3.4": {
                    "questionId": "BP3.4",
                    "chosenOptionIds": [
                        "f304bf93-0c68-5da0-80ba-ccecc2d0fce1"
                    ]
                },
                "BP4": {
                    "questionId": "BP4",
                    "chosenOptionIds": [
                        "4884cc9c-7210-5173-9438-d1eae9e9a6a8"
                    ]
                },
                "BP4.5": {
                    "questionId": "BP4.5",
                    "chosenOptionIds": [
                        "1be4b3b1-3a88-5544-af54-ebdfee1ce891"
                    ]
                },
                "BP6.5": {
                    "questionId": "BP6.5",
                    "chosenOptionIds": [
                        "58656f9f-9267-5c1d-9132-f342d05dc39e"
                    ]
                },
                "BP6.7": {
                    "questionId": "BP6.7",
                    "chosenOptionIds": [
                        "7cb3515b-004f-58a1-a02b-59c7d9510637"
                    ]
                },
                "BP6.8": {
                    "questionId": "BP6.8",
                    "freeValue": 30
                },
                "BP6.9": {
                    "questionId": "BP6.9",
                    "chosenOptionIds": [
                        "155da231-22f3-50d9-8786-2860edd50aab"
                    ]
                },
                "BP8": {
                    "questionId": "BP8",
                    "chosenOptionIds": [
                        "87e0081b-9455-5ced-9225-10c2f70b1734"
                    ]
                },
                "BP8.0": {
                    "questionId": "BP8.0",
                    "chosenOptionIds": [
                        "3568cdb1-2500-512b-aada-2547c5bfd835"
                    ]
                },
                "BP8.01": {
                    "questionId": "BP8.01",
                    "chosenOptionIds": [
                        "5020bfdd-a280-56cf-b7f5-3b0b7e667165"
                    ]
                },
                "BP8.02": {
                    "questionId": "BP8.02",
                    "chosenOptionIds": [
                        "e88670a3-c6d8-5c96-914e-b9fc1818aeda"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "960a08ff-7af7-56da-afda-5415a1654c85"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "f501dc76-7fcd-586b-ba1d-ad70ab8e8d33"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "28c75e9e-94ef-5c50-8645-f4ff93ccf2ec"
                    ]
                },
                "BP9": {
                    "questionId": "BP9",
                    "chosenOptionIds": [
                        "becfe35c-2096-5848-87ae-3f763cf59dd6"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "fd30dc27-8e89-5d1a-9d95-8844603d4379"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "c211fabc-a62d-5440-8ddc-f57eb21d7679"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "1f57d1a0-3682-5d7d-b66b-8179ec5c3988"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "ef5a7b22-b2b2-5a5b-8a80-ce5c251033fd"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "94795194-4a80-590f-be62-ee78f2fa0ff5"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "ec84966e-bb9f-5ba9-a0cf-d5d3503a74e0"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "9b27e3ff-f8ce-54c5-9220-0479ae53e889"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "352885c9-c1e1-5206-9f52-8d9b6d2aa166"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "b14d53e4-f898-52ac-87d7-3aa62abc9d7b"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "63509127-aadf-5fe2-8e1f-19d0d846d6ce"
                    ]
                },
                "BP99.1": {
                    "questionId": "BP99.1",
                    "chosenOptionIds": [
                        "34d19f12-207f-56ce-bde8-8d616f6e8b26"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "dee1118a-2ca3-52a6-ac43-a24ea7385f88"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM1": {
                    "questionId": "HM1",
                    "chosenOptionIds": [
                        "c44193b6-642c-56d3-ada3-eda9bd17a42e"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "c3ee8981-dec3-5b3c-80ca-c1023f983a6d"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "9d212885-9b07-5623-8234-226adff08f6d"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "3ab9515b-73f0-5fcf-a4d0-aebbc24c2721"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "0e9545ba-3f35-52b0-9a31-7fe54c933928"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "eb41dc92-16ec-5abf-a841-e1d0b262083e"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "cec918d6-b855-5672-94a8-cf14e5ae963a"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "7b310da1-94e3-5d45-a6c3-3905eeb28ec6"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "0983ccf5-a426-53d0-adbb-1baf6832190c"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "834b04f2-af09-5337-8fd5-be4a70c99e75"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "fda00d52-c8be-5b69-bdbd-905c13dddd4e"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "0360ab4b-77d5-5fcd-8d60-b7a005c12ceb"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "ba200400-b5a1-5ae8-aead-863c1a72be98"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: O0K1931 aspect: simple_bd2 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "0ec0e224-a5cf-5ece-b3ab-37061a600ace"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "a16e8e14-524e-53df-b4ef-730a2c8e5f32"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "916d657d-9f2a-5451-8465-18e3bd863347"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "b74511c7-9f97-5c15-8bf5-6ec4357b4814"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 42
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "db360e93-3616-5d89-be36-5ccee4aacf1c"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "de11fb6b-7e7d-52b1-9d09-2d6debc53314"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5c30c60e-d53e-5cc3-b4a9-fdad9bec4cb7"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "c9813959-ba61-5220-9e27-659abb256037"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "228ef7d9-008b-559d-ba14-6b928b486b38"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "d362cd87-7515-551e-bc74-19ef903484aa"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "4896b504-2025-52c3-85e2-c6cf3205cef7"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "7599bcaa-89a5-5f71-80ea-b70c360bb44a"
                    ]
                },
                "BP101b": {
                    "questionId": "BP101b",
                    "chosenOptionIds": [
                        "31b2d2c8-8f28-514e-9e58-aa878f6c8761"
                    ]
                },
                "BP102b": {
                    "questionId": "BP102b",
                    "chosenOptionIds": [
                        "46a64b5b-6641-5b1b-92aa-5366444fb134"
                    ]
                },
                "BP103b": {
                    "questionId": "BP103b",
                    "chosenOptionIds": [
                        "867b0ee4-73d9-5aac-b96d-bc2d85d5b183"
                    ]
                },
                "BP104b": {
                    "questionId": "BP104b",
                    "chosenOptionIds": [
                        "c59b5910-3279-509f-8175-f2fd0da2dc86"
                    ]
                },
                "BP87b": {
                    "questionId": "BP87b",
                    "chosenOptionIds": [
                        "fb568891-2ea3-5200-bbdb-ac0695f30b02"
                    ]
                },
                "BP88b": {
                    "questionId": "BP88b",
                    "chosenOptionIds": [
                        "e29e159f-f691-5aa7-b5a0-d13fe2c54170"
                    ]
                },
                "BP89b": {
                    "questionId": "BP89b",
                    "chosenOptionIds": [
                        "df5f6aa3-ab8c-5a4c-b9d9-e9a482aba1d7"
                    ]
                },
                "BP90b": {
                    "questionId": "BP90b",
                    "chosenOptionIds": [
                        "607460aa-6bfa-56fd-9d06-75ec91ef85a1"
                    ]
                },
                "BP92b": {
                    "questionId": "BP92b",
                    "chosenOptionIds": [
                        "dd87a55d-829b-54a2-a63d-5b8b634688ea"
                    ]
                },
                "BP94b": {
                    "questionId": "BP94b",
                    "chosenOptionIds": [
                        "1f4fafbf-913e-5edc-bfaf-3baae49fc91d"
                    ]
                },
                "BP95b": {
                    "questionId": "BP95b",
                    "chosenOptionIds": [
                        "fb0bd4a7-2793-5b81-badc-fe81b4bc82dc"
                    ]
                },
                "BP96b": {
                    "questionId": "BP96b",
                    "chosenOptionIds": [
                        "477d4084-be50-50ae-9e88-dc9e03cdad29"
                    ]
                },
                "BP97b": {
                    "questionId": "BP97b",
                    "chosenOptionIds": [
                        "6895096a-ef29-546e-9967-d7a23b4a2859"
                    ]
                },
                "BP98b": {
                    "questionId": "BP98b",
                    "chosenOptionIds": [
                        "f7b75a2b-3bd2-5734-9fa6-7706f7f8ac89"
                    ]
                },
                "BP99.3b": {
                    "questionId": "BP99.3b",
                    "chosenOptionIds": [
                        "bf30a6c2-f8e2-5305-b4d4-7ad6227449d3"
                    ]
                },
                "BP99b": {
                    "questionId": "BP99b",
                    "chosenOptionIds": [
                        "68860df1-e835-50d6-8eb2-f9d9f2f97efa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "BP_neg1": {
                    "questionId": "BP_neg1",
                    "chosenOptionIds": [
                        "119208b0-ff3b-5c43-80be-4f07e65b6248"
                    ]
                }
            }
        },
        {
            "name": "Mood test: J0V6N3L aspect: simple_bd2 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "fe031280-7d5d-52cb-aca8-1a8664b3234a"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "6ede390f-0e43-50e4-82e9-1203db9d8343"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "a0e528ca-2fbb-5da1-9398-0758f79755aa"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 60
                },
                "D-15a": {
                    "questionId": "D-15a",
                    "chosenOptionIds": [
                        "7b763ff0-7703-54f7-8eae-aa83417ca133"
                    ]
                },
                "D-16.5a": {
                    "questionId": "D-16.5a",
                    "chosenOptionIds": [
                        "ae0a4d4e-e9bf-51bf-8506-0cbced38db92"
                    ]
                },
                "D-17a": {
                    "questionId": "D-17a",
                    "chosenOptionIds": [
                        "495d3d07-8a1f-5213-bf79-59443d7aa5cf"
                    ]
                },
                "D-18a": {
                    "questionId": "D-18a",
                    "chosenOptionIds": [
                        "615b852b-6f81-54a9-bc4a-a8843e82c230"
                    ]
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "80c993c8-1a94-5684-a8f9-d8f5072c8e9c"
                    ]
                },
                "D-1bl": {
                    "questionId": "D-1bl",
                    "chosenOptionIds": [
                        "87ef50c9-ba5e-513e-8889-09d8395d4b9f"
                    ]
                },
                "D-2": {
                    "questionId": "D-2",
                    "freeValue": 17
                },
                "D-20a": {
                    "questionId": "D-20a",
                    "chosenOptionIds": [
                        "b28ed556-a386-5556-a96f-ae7708ded1ab"
                    ]
                },
                "D-21a": {
                    "questionId": "D-21a",
                    "chosenOptionIds": [
                        "dc2b002f-2d96-504d-bde9-66faceececb6"
                    ]
                },
                "D-23a": {
                    "questionId": "D-23a",
                    "chosenOptionIds": [
                        "f6cf63c5-0c57-5422-9431-55fec4c93c63"
                    ]
                },
                "D-26a": {
                    "questionId": "D-26a",
                    "chosenOptionIds": [
                        "823c244d-25bb-5b84-9f3c-16e82c594aee"
                    ]
                },
                "D-27a": {
                    "questionId": "D-27a",
                    "chosenOptionIds": [
                        "13a1b08d-9817-5c2f-96cb-f8d03b80a37d"
                    ]
                },
                "D-28a": {
                    "questionId": "D-28a",
                    "chosenOptionIds": [
                        "801353cf-2f72-53db-9179-e7ecc31b1fc1"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "cc243947-5067-5a98-ade6-a4e10d0b42cf"
                    ]
                },
                "D-2bl": {
                    "questionId": "D-2bl",
                    "chosenOptionIds": [
                        "78c1434b-e585-5f1d-b291-a1728f789022"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "75d33f2f-6fcd-5e09-8574-899eabeb22e6",
                        "bccbebb3-ed8c-5251-856b-e93a1d4386f6"
                    ]
                },
                "D-30a": {
                    "questionId": "D-30a",
                    "chosenOptionIds": [
                        "d309cef4-2204-5a10-a98d-b66740336838"
                    ]
                },
                "D-31a": {
                    "questionId": "D-31a",
                    "chosenOptionIds": [
                        "eba65e81-9b02-55d6-82cb-0b7ce6da41de"
                    ]
                },
                "D-32a": {
                    "questionId": "D-32a",
                    "chosenOptionIds": [
                        "ea2a4fbd-008a-571b-bb68-cbd0ba351521"
                    ]
                },
                "D-33a": {
                    "questionId": "D-33a",
                    "chosenOptionIds": [
                        "3968dd70-f060-5cbd-bb6a-d63f702a3691"
                    ]
                },
                "D-34a": {
                    "questionId": "D-34a",
                    "chosenOptionIds": [
                        "e919c54f-d9a4-5a15-9ae7-8b6b52607a22"
                    ]
                },
                "D-35a": {
                    "questionId": "D-35a",
                    "chosenOptionIds": [
                        "3a07e052-2332-5a23-bb19-3147a5b9d2c7"
                    ]
                },
                "D-36a": {
                    "questionId": "D-36a",
                    "chosenOptionIds": [
                        "185038dd-ce55-53b2-ba09-0bd163af90b3"
                    ]
                },
                "D-37a": {
                    "questionId": "D-37a",
                    "chosenOptionIds": [
                        "c8ebfb14-b9aa-5da4-8de3-ab8b83254249"
                    ]
                },
                "D-38a": {
                    "questionId": "D-38a",
                    "chosenOptionIds": [
                        "a03f02de-f86d-525a-b375-383ad9d3cefe"
                    ]
                },
                "D-39a": {
                    "questionId": "D-39a",
                    "chosenOptionIds": [
                        "6325d970-8ab3-5759-b71d-96b5909ec039"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5b6c0a69-6369-53d1-b2e1-5cd6294a9f71"
                    ]
                },
                "D-3bl": {
                    "questionId": "D-3bl",
                    "freeValue": 730
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-40a": {
                    "questionId": "D-40a",
                    "chosenOptionIds": [
                        "582119a7-d4a0-58ff-9b1d-2af045e11a7f"
                    ]
                },
                "D-41a": {
                    "questionId": "D-41a",
                    "chosenOptionIds": [
                        "cb89e9b0-6872-5961-bc91-049308b5860f"
                    ]
                },
                "D-42a": {
                    "questionId": "D-42a",
                    "chosenOptionIds": [
                        "926931de-e675-5117-9e61-78dd541393be"
                    ]
                },
                "D-43a": {
                    "questionId": "D-43a",
                    "chosenOptionIds": [
                        "dc3faaa6-4a47-5998-83e3-4bbff8838194"
                    ]
                },
                "D-43bt": {
                    "questionId": "D-43bt",
                    "chosenOptionIds": [
                        "644dd944-5a2a-533b-9ef0-fadb5054c422"
                    ]
                },
                "D-44.5a": {
                    "questionId": "D-44.5a",
                    "chosenOptionIds": [
                        "bcf5fb9f-f4b9-5f0c-a964-69a4e1a73334"
                    ]
                },
                "D-44a": {
                    "questionId": "D-44a",
                    "chosenOptionIds": [
                        "7d6584ca-1b93-543d-9ee4-400ca40134be"
                    ]
                },
                "D-45a": {
                    "questionId": "D-45a",
                    "freeValue": 2
                },
                "D-46a": {
                    "questionId": "D-46a",
                    "chosenOptionIds": [
                        "1fd426df-808a-5c68-9b6e-23ff2f128b7d",
                        "d9665b1f-cdd2-5979-895e-27bca6487533",
                        "7492eab7-c625-52d0-a2e3-4bf3332decc0",
                        "6e49f368-62af-5abb-9034-a58bd534944f",
                        "f0b15a45-ae49-59d2-97a7-33f866b0e629",
                        "b3e43bd8-fbe3-5cda-b891-6f11e43993d0",
                        "4c178978-f79a-58fc-8e73-3a98614c4b6c"
                    ]
                },
                "D-49.1a": {
                    "questionId": "D-49.1a",
                    "chosenOptionIds": [
                        "7108af97-c956-5da8-8dfa-518e346c197c"
                    ]
                },
                "D-49.2a": {
                    "questionId": "D-49.2a",
                    "freeValue": 7
                },
                "D-49a": {
                    "questionId": "D-49a",
                    "chosenOptionIds": [
                        "16dc6af1-32a9-59c5-b66c-57f6becea1e0"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "ba6dc2ba-3c20-57b6-a276-32c863109ff6"
                    ]
                },
                "D-50a": {
                    "questionId": "D-50a",
                    "chosenOptionIds": [
                        "d14da42d-9612-5827-9583-b2bf528130de"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "b52073e3-c813-51f5-88ba-9cd3bddd7023"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "08a0f83f-2f37-5904-9364-4e40deb90e98"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "5d271ac0-beca-56cc-9d0b-adafcd67da53"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "4c78ae3e-7de4-545f-aca1-03fa41fc0c23"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "1200dadf-a0de-5a54-b0b8-fc640f7bc8d1"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "44fd9eb3-aee2-5af0-b83c-dbbbf9514022"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "83e6efd5-64a2-5f0e-a973-9278eae90cfb"
                    ]
                },
                "BP10": {
                    "questionId": "BP10",
                    "chosenOptionIds": [
                        "7ad54f0a-87a6-5e82-bfc3-44b540230082"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "182e121d-415c-5f42-a76c-a996f5f38b3f"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "1c4ed441-5919-569e-98ba-b49be1bc3957"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "a3a547f3-b48b-567f-a32a-6a3f5f4bacd7"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "b24df75b-6bb9-5f5f-99e3-c883ec3644ec"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 20
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "c7bee7a0-3037-5eb1-96a1-85b884d7e1dd"
                    ]
                },
                "BP3.01": {
                    "questionId": "BP3.01",
                    "freeValue": 4
                },
                "BP3.1": {
                    "questionId": "BP3.1",
                    "chosenOptionIds": [
                        "5bf1e313-2624-5b5f-9673-37ae9319ff0c"
                    ]
                },
                "BP3.2": {
                    "questionId": "BP3.2",
                    "chosenOptionIds": [
                        "c3319113-403a-5ecb-aa8a-071221d92c50"
                    ]
                },
                "BP3.3": {
                    "questionId": "BP3.3",
                    "freeValue": 2
                },
                "BP3.4": {
                    "questionId": "BP3.4",
                    "chosenOptionIds": [
                        "93412936-97c0-5128-ad67-3fa955db1300"
                    ]
                },
                "BP4": {
                    "questionId": "BP4",
                    "chosenOptionIds": [
                        "9765aba8-7d55-5928-b79d-36aa58646036"
                    ]
                },
                "BP4.5": {
                    "questionId": "BP4.5",
                    "chosenOptionIds": [
                        "66a3460c-65ce-5a4c-9774-2e303ae4961e"
                    ]
                },
                "BP6": {
                    "questionId": "BP6",
                    "chosenOptionIds": [
                        "0f2295c8-9598-5e68-a9a6-c87ca1f600af"
                    ]
                },
                "BP6.1": {
                    "questionId": "BP6.1",
                    "chosenOptionIds": [
                        "430b68c6-29cf-5a15-ab4c-89bd842383bf"
                    ]
                },
                "BP6.2": {
                    "questionId": "BP6.2",
                    "freeValue": 1
                },
                "BP6.3": {
                    "questionId": "BP6.3",
                    "chosenOptionIds": [
                        "df17fdbe-e756-5ece-ad3d-19cafeef31d1"
                    ]
                },
                "BP8": {
                    "questionId": "BP8",
                    "chosenOptionIds": [
                        "87e0081b-9455-5ced-9225-10c2f70b1734"
                    ]
                },
                "BP8.0": {
                    "questionId": "BP8.0",
                    "chosenOptionIds": [
                        "3568cdb1-2500-512b-aada-2547c5bfd835"
                    ]
                },
                "BP8.01": {
                    "questionId": "BP8.01",
                    "chosenOptionIds": [
                        "a5d71cc4-3c6f-5e37-a507-1dfa312124b8"
                    ]
                },
                "BP8.02": {
                    "questionId": "BP8.02",
                    "chosenOptionIds": [
                        "e88670a3-c6d8-5c96-914e-b9fc1818aeda"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "387b19c0-67d0-557e-9fba-ae065dcf3587"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "afb57366-c0b3-5f73-92eb-27bc133ad2ed"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "667ba57c-b1cd-5f9b-aa12-58e65a981910"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "fd30dc27-8e89-5d1a-9d95-8844603d4379"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "c211fabc-a62d-5440-8ddc-f57eb21d7679"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "cc7c09ae-1cb7-5b9a-9cd3-8d1012b90ee8"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "d680a8d9-e35f-563f-9d1d-e7c3136aff6d"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "82997889-d795-572b-a597-e484ef078b39"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "2555d898-6736-5804-951e-e3c438313029"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "de2ea429-4fcd-53b8-8d9f-ebb69a637a02"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "e232ba2d-36a9-524e-9fb2-8541b27bb569"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "4a7683c6-774e-5362-8060-740f61986c9b"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "cfb8fe0e-821a-554a-bb57-922f0ecc0f44"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "36502f0b-4563-5e03-80f2-a46dabeeae74"
                    ]
                },
                "BP99.2": {
                    "questionId": "BP99.2",
                    "chosenOptionIds": [
                        "f55f25af-1247-5c48-8385-341f7e4fea59"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "e81fe613-4923-55ed-bd6c-bd3f19e026f9"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM2": {
                    "questionId": "HM2",
                    "chosenOptionIds": [
                        "2b56aa7d-493f-5a18-aa9f-f97304f89130"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "98ea07a0-ae95-570f-8d11-a3e7c682e986"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "a43647cb-a0e8-5705-adb9-a2b68877dc77"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "56aac6d1-1fcc-5a72-bfda-2ae5d875df21"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "43c32319-c75f-5169-b0e5-713820721d4f"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "65bee7d4-bf7f-5a21-9d92-f16651b0d1aa"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "0170b4cf-0541-53cd-a9db-818d5067dc23"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "7b310da1-94e3-5d45-a6c3-3905eeb28ec6"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "0983ccf5-a426-53d0-adbb-1baf6832190c"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "834b04f2-af09-5337-8fd5-be4a70c99e75"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "fda00d52-c8be-5b69-bdbd-905c13dddd4e"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "0360ab4b-77d5-5fcd-8d60-b7a005c12ceb"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "1d07911e-929d-5719-aeea-a68f593d18e8"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: R047J04 aspect: simple_bd2 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "c61d70d4-b773-54dd-94d0-d858a47be0fb"
                    ]
                },
                "D-1.5b": {
                    "questionId": "D-1.5b",
                    "freeValue": 10
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "a16e8e14-524e-53df-b4ef-730a2c8e5f32"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "9b47900d-e5a7-5e58-830b-d9b3130a7990"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "a0e528ca-2fbb-5da1-9398-0758f79755aa"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 1825
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "7f1d6182-f8a7-5f4e-a0f8-2556b306ae36"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-1bl": {
                    "questionId": "D-1bl",
                    "chosenOptionIds": [
                        "5da400b0-5bff-5744-a664-4305892ae669"
                    ]
                },
                "D-2": {
                    "questionId": "D-2",
                    "freeValue": 45
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "c695fd1c-2cb7-5654-b9f7-b32ecb158229"
                    ]
                },
                "D-2bl": {
                    "questionId": "D-2bl",
                    "chosenOptionIds": [
                        "78c1434b-e585-5f1d-b291-a1728f789022"
                    ]
                },
                "D-2bt": {
                    "questionId": "D-2bt",
                    "chosenOptionIds": [
                        "315fa694-3bc0-5231-93f2-ceb1d9766403"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "14d310b2-5fb6-5f79-8bfa-e3524002e63d"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "db7514e2-d317-55fb-82d3-2e4a49c2494b"
                    ]
                },
                "D-3bl": {
                    "questionId": "D-3bl",
                    "freeValue": 6
                },
                "D-3bt": {
                    "questionId": "D-3bt",
                    "chosenOptionIds": [
                        "1a1865e2-c338-59ef-a2db-6e2cc7a17d77"
                    ]
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-42bt": {
                    "questionId": "D-42bt",
                    "freeValue": 5
                },
                "D-43bt": {
                    "questionId": "D-43bt",
                    "chosenOptionIds": [
                        "644dd944-5a2a-533b-9ef0-fadb5054c422"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b"
                    ]
                },
                "D-4bt": {
                    "questionId": "D-4bt",
                    "freeValue": 5
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "b52073e3-c813-51f5-88ba-9cd3bddd7023"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "adecf2d3-f302-50f8-a4a9-3ffac1789bc6"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "4c78ae3e-7de4-545f-aca1-03fa41fc0c23"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "13f8bac1-d0f5-539b-8596-1c55821bea46"
                    ]
                },
                "D-s10bt": {
                    "questionId": "D-s10bt",
                    "chosenOptionIds": [
                        "ce0e07f8-36b3-5424-a489-abd9de8a21a4"
                    ]
                },
                "D-s1bt": {
                    "questionId": "D-s1bt",
                    "chosenOptionIds": [
                        "c17482e4-818a-50cd-90fa-1246d037825e"
                    ]
                },
                "D-s2bt": {
                    "questionId": "D-s2bt",
                    "chosenOptionIds": [
                        "b75d06ef-eb6c-5e23-bf8c-9b566fa4d9dd"
                    ]
                },
                "D-s3bt": {
                    "questionId": "D-s3bt",
                    "chosenOptionIds": [
                        "ae77a2d7-a52c-5cdc-8c8d-93e294ade96d"
                    ]
                },
                "D-s4bt": {
                    "questionId": "D-s4bt",
                    "chosenOptionIds": [
                        "5a198500-fc4b-55a1-9521-3abe49eed06f"
                    ]
                },
                "D-s5bt": {
                    "questionId": "D-s5bt",
                    "chosenOptionIds": [
                        "d033bb4c-5d3c-519b-be31-1177042bffb4"
                    ]
                },
                "D-s6bt": {
                    "questionId": "D-s6bt",
                    "chosenOptionIds": [
                        "bb7e3f7e-73b0-56b0-b3d3-e336498509e8"
                    ]
                },
                "D-s7bt": {
                    "questionId": "D-s7bt",
                    "chosenOptionIds": [
                        "1df67815-0b9f-56ef-bd48-96ed977db141"
                    ]
                },
                "D-s8bt": {
                    "questionId": "D-s8bt",
                    "chosenOptionIds": [
                        "6f5067cf-ef4d-59d9-afa1-b3dfc4ac861f"
                    ]
                },
                "D-s9bt": {
                    "questionId": "D-s9bt",
                    "chosenOptionIds": [
                        "da503bbd-e567-576e-a21f-e0fad60dccdd"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "8c599171-d554-5547-9f54-2066c1a711dd"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "ccbdcbec-96d0-5362-977b-784289f5cfc0"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "db7aa4b7-99a5-5475-a4e8-a744f3ef5a4a"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "e9eb902a-d327-5ec5-938c-dc317ca59b13"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "a3a547f3-b48b-567f-a32a-6a3f5f4bacd7"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "f2228920-3fec-5097-a63d-990c25d50c6c"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP105": {
                    "questionId": "BP105",
                    "chosenOptionIds": [
                        "64aa1680-6b5b-5a5b-bb92-75995d27ddcd"
                    ]
                },
                "BP12": {
                    "questionId": "BP12",
                    "chosenOptionIds": [
                        "89a3d3f3-747b-59f8-9a32-311618df7607"
                    ]
                },
                "BP12.5": {
                    "questionId": "BP12.5",
                    "chosenOptionIds": [
                        "b7cc3eef-f36c-5fb4-9ebb-9ce64c834159"
                    ]
                },
                "BP13": {
                    "questionId": "BP13",
                    "chosenOptionIds": [
                        "8cd7d7ed-1de8-5155-81b5-85202bce3abc"
                    ]
                },
                "BP13.1": {
                    "questionId": "BP13.1",
                    "chosenOptionIds": [
                        "49f1206d-ce81-58fc-af76-30be0fffd7f5"
                    ]
                },
                "BP13.2": {
                    "questionId": "BP13.2",
                    "freeValue": 6
                },
                "BP13.3": {
                    "questionId": "BP13.3",
                    "chosenOptionIds": [
                        "d1bcedf2-2527-57c5-8faf-806dcacf0fdd"
                    ]
                },
                "BP16": {
                    "questionId": "BP16",
                    "chosenOptionIds": [
                        "fabceeea-8fea-5444-8b67-8f189f366c27"
                    ]
                },
                "BP16.0": {
                    "questionId": "BP16.0",
                    "chosenOptionIds": [
                        "4b26caa5-9124-51b2-ab08-2f4e977cee1f"
                    ]
                },
                "BP16.01": {
                    "questionId": "BP16.01",
                    "chosenOptionIds": [
                        "5d4d2592-d29a-513f-8e9c-461e29598f49"
                    ]
                },
                "BP16.02": {
                    "questionId": "BP16.02",
                    "chosenOptionIds": [
                        "16a695c9-3ac1-5d34-b799-44602d85b556"
                    ]
                },
                "BP17": {
                    "questionId": "BP17",
                    "chosenOptionIds": [
                        "9fa962fb-6bd5-50fc-9063-459e175cbcc3"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 25
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "c7bee7a0-3037-5eb1-96a1-85b884d7e1dd"
                    ]
                },
                "BP3.01": {
                    "questionId": "BP3.01",
                    "freeValue": 1
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "960a08ff-7af7-56da-afda-5415a1654c85"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "5f694075-3a97-5f8b-8701-2710a43127a9"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "28c75e9e-94ef-5c50-8645-f4ff93ccf2ec"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "fd30dc27-8e89-5d1a-9d95-8844603d4379"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "e22cf233-c174-52fb-af55-97bf9c46e971"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "46c90446-3ae9-53f5-9b57-ad19d66b5be6"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "ef5a7b22-b2b2-5a5b-8a80-ce5c251033fd"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "c88e3fb5-913a-5c5c-a42b-603f7bb60a40"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "201c7ee3-56dc-51e9-ad55-69951192ab16"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "de2ea429-4fcd-53b8-8d9f-ebb69a637a02"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "9b27e3ff-f8ce-54c5-9220-0479ae53e889"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "2723ed09-37f6-522c-92b1-e518d4fe9315"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "cfb8fe0e-821a-554a-bb57-922f0ecc0f44"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "5c06d516-e816-58d6-997e-f8fa91131ac7"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "e81fe613-4923-55ed-bd6c-bd3f19e026f9"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM18": {
                    "questionId": "HM18",
                    "chosenOptionIds": [
                        "e91203fa-5410-59f8-a2a7-cfbd0257e576"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "6b99de8b-634a-5eb0-bc65-bdf30eb9d433"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "9d212885-9b07-5623-8234-226adff08f6d"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "5574c021-c3c4-513a-a4b6-5ee416e8aa9e"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "3fd957d1-0cbb-5397-9812-353be5c2184e"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "898957c6-8cd1-53e5-98f8-c16789aba613"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "0170b4cf-0541-53cd-a9db-818d5067dc23"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "2be66549-5767-51b4-82ed-deeab0723f8d"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "0983ccf5-a426-53d0-adbb-1baf6832190c"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "7e32475a-3fcc-51ac-b414-ab46578efdfd"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "fa32b497-4a4b-5076-a391-b6dffa8e1851"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "e95276b0-ee81-580b-b532-b0215f04abd5"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "4b8e4843-d260-59e5-8468-1303b1c3a638"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: O0KG5E1 aspect: simple_bd2 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "c61d70d4-b773-54dd-94d0-d858a47be0fb"
                    ]
                },
                "D-1.5b": {
                    "questionId": "D-1.5b",
                    "freeValue": 4
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "45f0ce85-9e19-53da-bc0d-2af8b383318a"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "eb68a88d-b49b-5cde-a9c6-5e4c78e27d19"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "826538fc-8a97-55e4-8013-fe6a93f8e451"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 7
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "7f1d6182-f8a7-5f4e-a0f8-2556b306ae36"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-1bl": {
                    "questionId": "D-1bl",
                    "chosenOptionIds": [
                        "87ef50c9-ba5e-513e-8889-09d8395d4b9f"
                    ]
                },
                "D-2": {
                    "questionId": "D-2",
                    "freeValue": 21
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "c695fd1c-2cb7-5654-b9f7-b32ecb158229"
                    ]
                },
                "D-2bl": {
                    "questionId": "D-2bl",
                    "chosenOptionIds": [
                        "2a8c96e2-cb5b-596a-99b6-a4885253776c"
                    ]
                },
                "D-2bt": {
                    "questionId": "D-2bt",
                    "chosenOptionIds": [
                        "d585762b-2491-592f-9340-3053d9151b68"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "fd9c4394-6664-5144-8797-00d27a4dda01"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "77c6088d-2d8f-5c3b-89c9-79cb0d317226"
                    ]
                },
                "D-3bl": {
                    "questionId": "D-3bl",
                    "freeValue": 14
                },
                "D-3bt": {
                    "questionId": "D-3bt",
                    "chosenOptionIds": [
                        "b9abc28d-6e22-5b67-bc5e-27431e123974"
                    ]
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-42bt": {
                    "questionId": "D-42bt",
                    "freeValue": 0
                },
                "D-43bt": {
                    "questionId": "D-43bt",
                    "chosenOptionIds": [
                        "644dd944-5a2a-533b-9ef0-fadb5054c422"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "9d1b858c-7064-5a84-80d6-786c18e603b6",
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b",
                        "1d4beed1-78e5-531c-a255-643fac260dee"
                    ]
                },
                "D-4bt": {
                    "questionId": "D-4bt",
                    "freeValue": 14
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "b52073e3-c813-51f5-88ba-9cd3bddd7023"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "9506449e-b2aa-5498-b896-901dd248b1b3"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "772bee29-c3a5-52e1-b785-a77d506ae4fb"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "3b216cf8-72e7-558c-bdd3-cc23a1865674"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "f8e2518c-72cf-563b-b322-a03406c8ebac"
                    ]
                },
                "D-s10bt": {
                    "questionId": "D-s10bt",
                    "chosenOptionIds": [
                        "3deea42c-5d93-54cd-ba0f-4242654c3747"
                    ]
                },
                "D-s1bt": {
                    "questionId": "D-s1bt",
                    "chosenOptionIds": [
                        "c17482e4-818a-50cd-90fa-1246d037825e"
                    ]
                },
                "D-s2bt": {
                    "questionId": "D-s2bt",
                    "chosenOptionIds": [
                        "e074592a-4667-5b65-b713-b8892451b433"
                    ]
                },
                "D-s3bt": {
                    "questionId": "D-s3bt",
                    "chosenOptionIds": [
                        "19b82b7e-f108-59dd-b277-dae38a3a8d8d"
                    ]
                },
                "D-s4bt": {
                    "questionId": "D-s4bt",
                    "chosenOptionIds": [
                        "fd9851a0-7ce7-55a8-b257-5617537d0013",
                        "5a198500-fc4b-55a1-9521-3abe49eed06f",
                        "a4021497-af94-56a4-8075-392653262e2e"
                    ]
                },
                "D-s5bt": {
                    "questionId": "D-s5bt",
                    "chosenOptionIds": [
                        "d033bb4c-5d3c-519b-be31-1177042bffb4"
                    ]
                },
                "D-s6bt": {
                    "questionId": "D-s6bt",
                    "chosenOptionIds": [
                        "f7aa2ac0-a087-5371-8266-7601901cd1f9"
                    ]
                },
                "D-s7bt": {
                    "questionId": "D-s7bt",
                    "chosenOptionIds": [
                        "b0305c3f-5a4a-5a36-9793-cec574da7f4e"
                    ]
                },
                "D-s8bt": {
                    "questionId": "D-s8bt",
                    "chosenOptionIds": [
                        "b089fe08-53f3-59bb-a2eb-55bfc744e7ca"
                    ]
                },
                "D-s9bt": {
                    "questionId": "D-s9bt",
                    "chosenOptionIds": [
                        "8d2e7af7-07c2-5459-8865-609f820de278"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "60fe0bb4-d770-56f2-b05a-864625a48e4d"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "ccbdcbec-96d0-5362-977b-784289f5cfc0"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "4a0f1c4d-38cf-5de4-99e3-0ee818f9709c"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "6a5aa899-7b89-5661-a554-71b6058ad4a4"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "d3224e16-1b0c-5266-9e14-ac72d2b2e988"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "b24df75b-6bb9-5f5f-99e3-c883ec3644ec"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP12": {
                    "questionId": "BP12",
                    "chosenOptionIds": [
                        "22f9a995-3f9a-5fe9-99bb-71b7f1f96621"
                    ]
                },
                "BP15": {
                    "questionId": "BP15",
                    "chosenOptionIds": [
                        "1286eda2-5e31-52dc-95c5-78ded5b43b2a"
                    ]
                },
                "BP15.1": {
                    "questionId": "BP15.1",
                    "chosenOptionIds": [
                        "0fe6799b-72e6-5cd8-a921-d03ac42f803d"
                    ]
                },
                "BP15.2": {
                    "questionId": "BP15.2",
                    "freeValue": 0.041666666666666664
                },
                "BP15.3": {
                    "questionId": "BP15.3",
                    "chosenOptionIds": [
                        "cfa519ff-08a9-5fa4-b12d-87b7a597cb13"
                    ]
                },
                "BP16": {
                    "questionId": "BP16",
                    "chosenOptionIds": [
                        "fabceeea-8fea-5444-8b67-8f189f366c27"
                    ]
                },
                "BP16.0": {
                    "questionId": "BP16.0",
                    "chosenOptionIds": [
                        "4b26caa5-9124-51b2-ab08-2f4e977cee1f"
                    ]
                },
                "BP16.01": {
                    "questionId": "BP16.01",
                    "chosenOptionIds": [
                        "f81655d7-78e5-59b1-a7eb-90a2ec7a107d"
                    ]
                },
                "BP16.02": {
                    "questionId": "BP16.02",
                    "chosenOptionIds": [
                        "16a695c9-3ac1-5d34-b799-44602d85b556"
                    ]
                },
                "BP19": {
                    "questionId": "BP19",
                    "chosenOptionIds": [
                        "601bc370-8a4c-5b1f-a7f6-6c174f421733"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 31
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "f03de28d-8f71-5af4-be2f-45f4e6092a3d"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "387b19c0-67d0-557e-9fba-ae065dcf3587"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "afb57366-c0b3-5f73-92eb-27bc133ad2ed"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "667ba57c-b1cd-5f9b-aa12-58e65a981910"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "fd30dc27-8e89-5d1a-9d95-8844603d4379"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "e22cf233-c174-52fb-af55-97bf9c46e971"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "46c90446-3ae9-53f5-9b57-ad19d66b5be6"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "d680a8d9-e35f-563f-9d1d-e7c3136aff6d"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "c88e3fb5-913a-5c5c-a42b-603f7bb60a40"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "732c51f9-a0b6-5951-bd7d-eaaec24316ba"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "6928c696-e6d7-5a14-a004-15a497ac6562"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "17b4da6f-7135-523c-b90b-2fe3b66785ff"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "3cf77140-2757-58eb-b4ea-f2a90cd766e7"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "5c06d516-e816-58d6-997e-f8fa91131ac7"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "c0cc09c3-1335-5eb4-8801-b3cdaf74f6fa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM20": {
                    "questionId": "HM20",
                    "chosenOptionIds": [
                        "4ff0f88c-e951-5e87-a941-815105819115"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "98ea07a0-ae95-570f-8d11-a3e7c682e986"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "696fdd9d-5ced-504b-a933-bb3e575b0099"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c41974b6-d394-569c-8f45-49f79fcac5c6"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "43c32319-c75f-5169-b0e5-713820721d4f"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "b2cb304f-f035-53fc-8763-e2f91b031f9b"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "0170b4cf-0541-53cd-a9db-818d5067dc23"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "2be66549-5767-51b4-82ed-deeab0723f8d"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "2a17c2fe-2592-58b1-98e9-3dc4168fe3e3"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "834b04f2-af09-5337-8fd5-be4a70c99e75"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "86277249-cf50-5f91-a27f-f3a69344e796"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "8823c74f-ff7c-52d8-9c73-fd3b4180f48a"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "1d07911e-929d-5719-aeea-a68f593d18e8"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: Z3R6O0O aspect: simple_bd2 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "0ec0e224-a5cf-5ece-b3ab-37061a600ace"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "45f0ce85-9e19-53da-bc0d-2af8b383318a"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "6ede390f-0e43-50e4-82e9-1203db9d8343"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "b74511c7-9f97-5c15-8bf5-6ec4357b4814"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 730
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "db360e93-3616-5d89-be36-5ccee4aacf1c"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "de11fb6b-7e7d-52b1-9d09-2d6debc53314"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5c30c60e-d53e-5cc3-b4a9-fdad9bec4cb7"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "188a7831-38c4-55c4-880a-1e4db4e9bab1"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "3e093dd3-857e-5004-a4fb-961fbfa59ece"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "772bee29-c3a5-52e1-b785-a77d506ae4fb"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "67316d94-1cf0-5bd0-a4db-f57bc70684ba"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "f8e2518c-72cf-563b-b322-a03406c8ebac"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "60fe0bb4-d770-56f2-b05a-864625a48e4d"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "83e6efd5-64a2-5f0e-a973-9278eae90cfb"
                    ]
                },
                "BP10": {
                    "questionId": "BP10",
                    "chosenOptionIds": [
                        "7ad54f0a-87a6-5e82-bfc3-44b540230082"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "4a0f1c4d-38cf-5de4-99e3-0ee818f9709c"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "d8c1997f-d722-536c-ad9d-296b5e10ed04"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "7230003d-a290-5a65-ae6b-b7d564b5db38"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "f2228920-3fec-5097-a63d-990c25d50c6c"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "de4464c8-78fe-5876-aa00-25826ce5ec25"
                    ]
                },
                "BP105": {
                    "questionId": "BP105",
                    "chosenOptionIds": [
                        "4e9d21b3-29c7-5526-ada9-67b61957994e"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 49
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "c7bee7a0-3037-5eb1-96a1-85b884d7e1dd"
                    ]
                },
                "BP3.01": {
                    "questionId": "BP3.01",
                    "freeValue": 99
                },
                "BP3.1": {
                    "questionId": "BP3.1",
                    "chosenOptionIds": [
                        "5bf1e313-2624-5b5f-9673-37ae9319ff0c"
                    ]
                },
                "BP3.2": {
                    "questionId": "BP3.2",
                    "chosenOptionIds": [
                        "43011291-20a8-59ce-8bac-df882587ff5c"
                    ]
                },
                "BP3.3": {
                    "questionId": "BP3.3",
                    "freeValue": 0.041666666666666664
                },
                "BP3.4": {
                    "questionId": "BP3.4",
                    "chosenOptionIds": [
                        "93412936-97c0-5128-ad67-3fa955db1300"
                    ]
                },
                "BP4": {
                    "questionId": "BP4",
                    "chosenOptionIds": [
                        "9765aba8-7d55-5928-b79d-36aa58646036"
                    ]
                },
                "BP4.5": {
                    "questionId": "BP4.5",
                    "chosenOptionIds": [
                        "1be4b3b1-3a88-5544-af54-ebdfee1ce891"
                    ]
                },
                "BP6": {
                    "questionId": "BP6",
                    "chosenOptionIds": [
                        "742a22d6-a570-5e3a-8e72-c001f1e9f98b"
                    ]
                },
                "BP6.1": {
                    "questionId": "BP6.1",
                    "chosenOptionIds": [
                        "77b4d620-93e4-5bc5-baad-4995d168f392"
                    ]
                },
                "BP6.2": {
                    "questionId": "BP6.2",
                    "freeValue": 0.041666666666666664
                },
                "BP6.3": {
                    "questionId": "BP6.3",
                    "chosenOptionIds": [
                        "e061c578-086e-5990-bba6-279cb273f3a5"
                    ]
                },
                "BP8": {
                    "questionId": "BP8",
                    "chosenOptionIds": [
                        "87e0081b-9455-5ced-9225-10c2f70b1734"
                    ]
                },
                "BP8.0": {
                    "questionId": "BP8.0",
                    "chosenOptionIds": [
                        "3568cdb1-2500-512b-aada-2547c5bfd835"
                    ]
                },
                "BP8.01": {
                    "questionId": "BP8.01",
                    "chosenOptionIds": [
                        "5020bfdd-a280-56cf-b7f5-3b0b7e667165"
                    ]
                },
                "BP8.02": {
                    "questionId": "BP8.02",
                    "chosenOptionIds": [
                        "1ff60197-2010-588f-bf6a-9be48f2b3359"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "387b19c0-67d0-557e-9fba-ae065dcf3587"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "afb57366-c0b3-5f73-92eb-27bc133ad2ed"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "667ba57c-b1cd-5f9b-aa12-58e65a981910"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "a2056280-4b54-5258-8bb4-407b521fff4b"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "e22cf233-c174-52fb-af55-97bf9c46e971"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "1f57d1a0-3682-5d7d-b66b-8179ec5c3988"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 1
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "ef5a7b22-b2b2-5a5b-8a80-ce5c251033fd"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "f35fe383-d2aa-51c4-9fde-961b1de6d52b"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "201c7ee3-56dc-51e9-ad55-69951192ab16"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "6ade3a8d-8516-5a88-baa0-f996f32730d2"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "2723ed09-37f6-522c-92b1-e518d4fe9315"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "58777a2e-4b97-5413-b340-efe722e45936"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "63509127-aadf-5fe2-8e1f-19d0d846d6ce"
                    ]
                },
                "BP99.1": {
                    "questionId": "BP99.1",
                    "chosenOptionIds": [
                        "34d19f12-207f-56ce-bde8-8d616f6e8b26"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "a020ed85-ec9d-5778-8006-41f634cab09d"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM2": {
                    "questionId": "HM2",
                    "chosenOptionIds": [
                        "a046d610-d27a-59c1-b130-38312005cf1c"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "c3ee8981-dec3-5b3c-80ca-c1023f983a6d"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "a43647cb-a0e8-5705-adb9-a2b68877dc77"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "3ab9515b-73f0-5fcf-a4d0-aebbc24c2721"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "c8c8c192-aea3-5585-b11f-9d13faf4367d"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "d2c670c4-b05b-597a-a0af-5342230535a0"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "0170b4cf-0541-53cd-a9db-818d5067dc23"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "7b310da1-94e3-5d45-a6c3-3905eeb28ec6"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "2a17c2fe-2592-58b1-98e9-3dc4168fe3e3"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "a56fcb96-f868-5d08-a358-9b403eb3cd16"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "fa32b497-4a4b-5076-a391-b6dffa8e1851"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "bde4e5f3-19e4-571f-84a2-5b9d188d74ef"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "7d6921a2-7e63-5f12-9c75-dff8b592fabc"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: ME87V3W aspect: simple_bd2 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "3bd18f66-6f1e-5ea4-ae24-2990ed197bef"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "eb68a88d-b49b-5cde-a9c6-5e4c78e27d19"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "826538fc-8a97-55e4-8013-fe6a93f8e451"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 1825
                },
                "D-15a": {
                    "questionId": "D-15a",
                    "chosenOptionIds": [
                        "7b763ff0-7703-54f7-8eae-aa83417ca133"
                    ]
                },
                "D-16.5a": {
                    "questionId": "D-16.5a",
                    "chosenOptionIds": [
                        "5f88b934-32eb-5ed5-8896-a818cbe4b033"
                    ]
                },
                "D-17a": {
                    "questionId": "D-17a",
                    "chosenOptionIds": [
                        "33b013af-d830-516a-9632-69dcc7a0f736"
                    ]
                },
                "D-18a": {
                    "questionId": "D-18a",
                    "chosenOptionIds": [
                        "615b852b-6f81-54a9-bc4a-a8843e82c230"
                    ]
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "80c993c8-1a94-5684-a8f9-d8f5072c8e9c"
                    ]
                },
                "D-1bl": {
                    "questionId": "D-1bl",
                    "chosenOptionIds": [
                        "87ef50c9-ba5e-513e-8889-09d8395d4b9f"
                    ]
                },
                "D-2": {
                    "questionId": "D-2",
                    "freeValue": 19
                },
                "D-20a": {
                    "questionId": "D-20a",
                    "chosenOptionIds": [
                        "b28ed556-a386-5556-a96f-ae7708ded1ab"
                    ]
                },
                "D-21a": {
                    "questionId": "D-21a",
                    "chosenOptionIds": [
                        "dc2b002f-2d96-504d-bde9-66faceececb6"
                    ]
                },
                "D-23a": {
                    "questionId": "D-23a",
                    "chosenOptionIds": [
                        "44fe96b8-192b-5b6b-88fa-9c2f7ba73a6d"
                    ]
                },
                "D-26a": {
                    "questionId": "D-26a",
                    "chosenOptionIds": [
                        "220827bb-16f1-5e00-9549-b98382b6e2ac"
                    ]
                },
                "D-27a": {
                    "questionId": "D-27a",
                    "chosenOptionIds": [
                        "13a1b08d-9817-5c2f-96cb-f8d03b80a37d"
                    ]
                },
                "D-28a": {
                    "questionId": "D-28a",
                    "chosenOptionIds": [
                        "8de8c31e-96ef-5e7d-8024-f6a72d07dd28"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "cc243947-5067-5a98-ade6-a4e10d0b42cf"
                    ]
                },
                "D-2bl": {
                    "questionId": "D-2bl",
                    "chosenOptionIds": [
                        "2a8c96e2-cb5b-596a-99b6-a4885253776c"
                    ]
                },
                "D-2bs": {
                    "questionId": "D-2bs",
                    "chosenOptionIds": [
                        "d9fc40d3-aa51-587a-a54d-305ad26f67ae"
                    ]
                },
                "D-2bt": {
                    "questionId": "D-2bt",
                    "chosenOptionIds": [
                        "90222879-e5d9-50a0-bfbe-a5cbadbff017"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "fd9c4394-6664-5144-8797-00d27a4dda01"
                    ]
                },
                "D-30a": {
                    "questionId": "D-30a",
                    "chosenOptionIds": [
                        "5b3f3d57-9d8a-54ab-a6ab-b02b57bc262e"
                    ]
                },
                "D-31a": {
                    "questionId": "D-31a",
                    "chosenOptionIds": [
                        "c78e0def-d73e-5c4e-8800-7c6f92d0a1ec"
                    ]
                },
                "D-32.1a": {
                    "questionId": "D-32.1a",
                    "chosenOptionIds": [
                        "da39b8b6-3178-5cf4-b68e-92b08cbf087e"
                    ]
                },
                "D-32a": {
                    "questionId": "D-32a",
                    "chosenOptionIds": [
                        "f992ce3a-fffd-5f38-aee4-24930ded9288"
                    ]
                },
                "D-33a": {
                    "questionId": "D-33a",
                    "chosenOptionIds": [
                        "a73a7546-0627-5437-b9f9-5664558c7d54"
                    ]
                },
                "D-34a": {
                    "questionId": "D-34a",
                    "chosenOptionIds": [
                        "e919c54f-d9a4-5a15-9ae7-8b6b52607a22"
                    ]
                },
                "D-35a": {
                    "questionId": "D-35a",
                    "chosenOptionIds": [
                        "bcace469-f380-599d-a33a-26db6d0053ad"
                    ]
                },
                "D-36a": {
                    "questionId": "D-36a",
                    "chosenOptionIds": [
                        "185038dd-ce55-53b2-ba09-0bd163af90b3"
                    ]
                },
                "D-37a": {
                    "questionId": "D-37a",
                    "chosenOptionIds": [
                        "c8ebfb14-b9aa-5da4-8de3-ab8b83254249"
                    ]
                },
                "D-38a": {
                    "questionId": "D-38a",
                    "chosenOptionIds": [
                        "39b328fc-f944-51dc-ae4d-9aab44697f32"
                    ]
                },
                "D-39a": {
                    "questionId": "D-39a",
                    "chosenOptionIds": [
                        "ae31125f-c3db-5082-ae70-4329a2508744"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "db7514e2-d317-55fb-82d3-2e4a49c2494b"
                    ]
                },
                "D-3bl": {
                    "questionId": "D-3bl",
                    "freeValue": 28
                },
                "D-3bs": {
                    "questionId": "D-3bs",
                    "chosenOptionIds": [
                        "664c5f59-06d3-53a2-81e0-eaf62108625d"
                    ]
                },
                "D-3bt": {
                    "questionId": "D-3bt",
                    "chosenOptionIds": [
                        "b9abc28d-6e22-5b67-bc5e-27431e123974"
                    ]
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-40a": {
                    "questionId": "D-40a",
                    "chosenOptionIds": [
                        "582119a7-d4a0-58ff-9b1d-2af045e11a7f"
                    ]
                },
                "D-41a": {
                    "questionId": "D-41a",
                    "chosenOptionIds": [
                        "cb89e9b0-6872-5961-bc91-049308b5860f"
                    ]
                },
                "D-42a": {
                    "questionId": "D-42a",
                    "chosenOptionIds": [
                        "7099f8a9-4650-5da2-a627-bcd62cd99375"
                    ]
                },
                "D-42bt": {
                    "questionId": "D-42bt",
                    "freeValue": 2
                },
                "D-43a": {
                    "questionId": "D-43a",
                    "chosenOptionIds": [
                        "cd0abe72-b3e8-5c78-8330-7ee4a1657536"
                    ]
                },
                "D-43bt": {
                    "questionId": "D-43bt",
                    "chosenOptionIds": [
                        "90e60395-fc3d-56cb-b6f7-2e24fba02803"
                    ]
                },
                "D-44.5a": {
                    "questionId": "D-44.5a",
                    "chosenOptionIds": [
                        "bcf5fb9f-f4b9-5f0c-a964-69a4e1a73334"
                    ]
                },
                "D-44a": {
                    "questionId": "D-44a",
                    "chosenOptionIds": [
                        "57c0e55d-5ee4-5237-9c5d-e317b90c5dbd"
                    ]
                },
                "D-45a": {
                    "questionId": "D-45a",
                    "freeValue": 15
                },
                "D-46a": {
                    "questionId": "D-46a",
                    "chosenOptionIds": [
                        "1fd426df-808a-5c68-9b6e-23ff2f128b7d",
                        "7492eab7-c625-52d0-a2e3-4bf3332decc0",
                        "4c178978-f79a-58fc-8e73-3a98614c4b6c",
                        "6e49f368-62af-5abb-9034-a58bd534944f"
                    ]
                },
                "D-49.1a": {
                    "questionId": "D-49.1a",
                    "chosenOptionIds": [
                        "7108af97-c956-5da8-8dfa-518e346c197c"
                    ]
                },
                "D-49.2a": {
                    "questionId": "D-49.2a",
                    "freeValue": 10
                },
                "D-49a": {
                    "questionId": "D-49a",
                    "chosenOptionIds": [
                        "16dc6af1-32a9-59c5-b66c-57f6becea1e0"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "a449e4ae-663b-50fb-9b62-1708b0a32669"
                    ]
                },
                "D-4bs": {
                    "questionId": "D-4bs",
                    "freeValue": 14
                },
                "D-4bt": {
                    "questionId": "D-4bt",
                    "freeValue": 0.29166666666666663
                },
                "D-50a": {
                    "questionId": "D-50a",
                    "chosenOptionIds": [
                        "b591ccff-25d0-5b4f-8091-4e8f8438987b"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "b52073e3-c813-51f5-88ba-9cd3bddd7023"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "772bee29-c3a5-52e1-b785-a77d506ae4fb"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "33a5fff0-9587-521f-bc53-0dacc36a0bb2"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "1200dadf-a0de-5a54-b0b8-fc640f7bc8d1"
                    ]
                },
                "D-s10bt": {
                    "questionId": "D-s10bt",
                    "chosenOptionIds": [
                        "da993bd6-c107-53a4-9f6d-09f5a79aff65"
                    ]
                },
                "D-s11bt": {
                    "questionId": "D-s11bt",
                    "chosenOptionIds": [
                        "dc476220-9479-5def-8a97-20e6152d44af",
                        "daf8b3c7-fb19-5a3f-ba9a-784adda7414f",
                        "6994b88e-cfa5-5986-b9e8-625d17d1147c",
                        "bae5acc8-8664-54a2-ba97-c36244be4c44",
                        "64af3cae-713a-51e1-99c1-09e2fdaa755d",
                        "9c3fa6af-8b7b-5993-a587-641683d934d5",
                        "16e1a0b8-28c5-5bcd-931d-335c858c3f88"
                    ]
                },
                "D-s1bt": {
                    "questionId": "D-s1bt",
                    "chosenOptionIds": [
                        "c17482e4-818a-50cd-90fa-1246d037825e"
                    ]
                },
                "D-s2bt": {
                    "questionId": "D-s2bt",
                    "chosenOptionIds": [
                        "8783a6d5-cd94-5982-8fd6-66ae46e1c6df"
                    ]
                },
                "D-s3bt": {
                    "questionId": "D-s3bt",
                    "chosenOptionIds": [
                        "19b82b7e-f108-59dd-b277-dae38a3a8d8d"
                    ]
                },
                "D-s4bt": {
                    "questionId": "D-s4bt",
                    "chosenOptionIds": [
                        "6dd2621e-8242-58f6-8c6c-77df4a9eef53"
                    ]
                },
                "D-s5bt": {
                    "questionId": "D-s5bt",
                    "chosenOptionIds": [
                        "8260d432-3a96-519e-b824-d24940e4aa65"
                    ]
                },
                "D-s6bt": {
                    "questionId": "D-s6bt",
                    "chosenOptionIds": [
                        "f7aa2ac0-a087-5371-8266-7601901cd1f9"
                    ]
                },
                "D-s7bt": {
                    "questionId": "D-s7bt",
                    "chosenOptionIds": [
                        "1df67815-0b9f-56ef-bd48-96ed977db141"
                    ]
                },
                "D-s8bt": {
                    "questionId": "D-s8bt",
                    "chosenOptionIds": [
                        "6f5067cf-ef4d-59d9-afa1-b3dfc4ac861f"
                    ]
                },
                "D-s9bt": {
                    "questionId": "D-s9bt",
                    "chosenOptionIds": [
                        "da503bbd-e567-576e-a21f-e0fad60dccdd"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "afcb6534-a0a0-5fe6-9729-1269be20cdf3",
                        "44fd9eb3-aee2-5af0-b83c-dbbbf9514022"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "ccbdcbec-96d0-5362-977b-784289f5cfc0"
                    ]
                },
                "BP10": {
                    "questionId": "BP10",
                    "chosenOptionIds": [
                        "7ad54f0a-87a6-5e82-bfc3-44b540230082"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "a20d7b84-720e-53c5-a450-45cbde434507"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "d8c1997f-d722-536c-ad9d-296b5e10ed04"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "7230003d-a290-5a65-ae6b-b7d564b5db38"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "f2228920-3fec-5097-a63d-990c25d50c6c"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP105": {
                    "questionId": "BP105",
                    "chosenOptionIds": [
                        "978f2ff4-94d9-5f7e-9126-e8657be0c7ca"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 22
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "c7bee7a0-3037-5eb1-96a1-85b884d7e1dd"
                    ]
                },
                "BP3.01": {
                    "questionId": "BP3.01",
                    "freeValue": 20
                },
                "BP3.1": {
                    "questionId": "BP3.1",
                    "chosenOptionIds": [
                        "c31cf816-04e0-52f4-8da3-04437042c014"
                    ]
                },
                "BP3.2": {
                    "questionId": "BP3.2",
                    "chosenOptionIds": [
                        "ec333f8b-146a-5700-a15d-527032ceac3f"
                    ]
                },
                "BP3.3": {
                    "questionId": "BP3.3",
                    "freeValue": 20
                },
                "BP3.4": {
                    "questionId": "BP3.4",
                    "chosenOptionIds": [
                        "f304bf93-0c68-5da0-80ba-ccecc2d0fce1"
                    ]
                },
                "BP4": {
                    "questionId": "BP4",
                    "chosenOptionIds": [
                        "9765aba8-7d55-5928-b79d-36aa58646036"
                    ]
                },
                "BP4.5": {
                    "questionId": "BP4.5",
                    "chosenOptionIds": [
                        "66a3460c-65ce-5a4c-9774-2e303ae4961e"
                    ]
                },
                "BP6": {
                    "questionId": "BP6",
                    "chosenOptionIds": [
                        "33ae5abe-63ad-57d4-86a4-0dcd33576094"
                    ]
                },
                "BP6.1": {
                    "questionId": "BP6.1",
                    "chosenOptionIds": [
                        "a99773bd-2bff-523e-bc29-1f761307b3d4"
                    ]
                },
                "BP6.2": {
                    "questionId": "BP6.2",
                    "freeValue": 7
                },
                "BP6.3": {
                    "questionId": "BP6.3",
                    "chosenOptionIds": [
                        "e061c578-086e-5990-bba6-279cb273f3a5"
                    ]
                },
                "BP8": {
                    "questionId": "BP8",
                    "chosenOptionIds": [
                        "5d89a61f-a292-5fa7-b2ec-af892cf73338"
                    ]
                },
                "BP8.0": {
                    "questionId": "BP8.0",
                    "chosenOptionIds": [
                        "3568cdb1-2500-512b-aada-2547c5bfd835"
                    ]
                },
                "BP8.01": {
                    "questionId": "BP8.01",
                    "chosenOptionIds": [
                        "a5d71cc4-3c6f-5e37-a507-1dfa312124b8"
                    ]
                },
                "BP8.02": {
                    "questionId": "BP8.02",
                    "chosenOptionIds": [
                        "e88670a3-c6d8-5c96-914e-b9fc1818aeda"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "960a08ff-7af7-56da-afda-5415a1654c85"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "cb636d32-4f4e-51b3-a23f-2a11b8771b7e"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "0953f30c-801a-54f6-8238-b9dc42ab17f9"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "fd30dc27-8e89-5d1a-9d95-8844603d4379"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "c211fabc-a62d-5440-8ddc-f57eb21d7679"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "1f57d1a0-3682-5d7d-b66b-8179ec5c3988"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 10
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "63f0b17a-9832-5f0f-8630-e74f1a4fdfe7"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "82997889-d795-572b-a597-e484ef078b39"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "2555d898-6736-5804-951e-e3c438313029"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "e232ba2d-36a9-524e-9fb2-8541b27bb569"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "2723ed09-37f6-522c-92b1-e518d4fe9315"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "3cf77140-2757-58eb-b4ea-f2a90cd766e7"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "5c06d516-e816-58d6-997e-f8fa91131ac7"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "c0cc09c3-1335-5eb4-8801-b3cdaf74f6fa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM2": {
                    "questionId": "HM2",
                    "chosenOptionIds": [
                        "a046d610-d27a-59c1-b130-38312005cf1c"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "6b99de8b-634a-5eb0-bc65-bdf30eb9d433"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "9d212885-9b07-5623-8234-226adff08f6d"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c347cbdd-4788-538c-b190-8008a1e964e2"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "c8c8c192-aea3-5585-b11f-9d13faf4367d"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "d2c670c4-b05b-597a-a0af-5342230535a0"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "366c6a1b-c8b2-5faf-9d2f-72dbde24e869"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "1240a093-e011-5bb9-9dbe-b61af95e9cde"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "2a17c2fe-2592-58b1-98e9-3dc4168fe3e3"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "a56fcb96-f868-5d08-a358-9b403eb3cd16"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "fda00d52-c8be-5b69-bdbd-905c13dddd4e"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "bde4e5f3-19e4-571f-84a2-5b9d188d74ef"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "7d6921a2-7e63-5f12-9c75-dff8b592fabc"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: PE61J0L aspect: simple_bd2 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "45f0ce85-9e19-53da-bc0d-2af8b383318a"
                    ]
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "7a5958fc-e0f7-5d43-9cd0-abfe95cb7026"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "a9525e10-4e34-5d6b-9c93-c7546ec49231"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "9222be3e-443f-5146-9d69-d48fe9041ba0"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "77c6088d-2d8f-5c3b-89c9-79cb0d317226"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "a449e4ae-663b-50fb-9b62-1708b0a32669"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "f3efc764-ca53-52c3-9c3a-38c7f787d415"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "9506449e-b2aa-5498-b896-901dd248b1b3"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "ef6fddab-7a07-5f20-af20-74f2444eee53"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "3b216cf8-72e7-558c-bdd3-cc23a1865674"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "60fe0bb4-d770-56f2-b05a-864625a48e4d"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "7599bcaa-89a5-5f71-80ea-b70c360bb44a"
                    ]
                },
                "BP101b": {
                    "questionId": "BP101b",
                    "chosenOptionIds": [
                        "cb80bcac-db78-55bd-b051-bdd00801fd54"
                    ]
                },
                "BP102b": {
                    "questionId": "BP102b",
                    "chosenOptionIds": [
                        "acb61f4d-a637-587c-bd33-3e9195921dd3"
                    ]
                },
                "BP103b": {
                    "questionId": "BP103b",
                    "chosenOptionIds": [
                        "867b0ee4-73d9-5aac-b96d-bc2d85d5b183"
                    ]
                },
                "BP104b": {
                    "questionId": "BP104b",
                    "chosenOptionIds": [
                        "c59b5910-3279-509f-8175-f2fd0da2dc86"
                    ]
                },
                "BP87b": {
                    "questionId": "BP87b",
                    "chosenOptionIds": [
                        "8cf63df7-755b-597a-9125-d7435c303261"
                    ]
                },
                "BP88b": {
                    "questionId": "BP88b",
                    "chosenOptionIds": [
                        "a6244707-7c91-5858-a2e1-f6f953f7b85e"
                    ]
                },
                "BP89b": {
                    "questionId": "BP89b",
                    "chosenOptionIds": [
                        "41d2829a-3971-552f-8c5a-c77e7d0073e7"
                    ]
                },
                "BP90b": {
                    "questionId": "BP90b",
                    "chosenOptionIds": [
                        "95c2822d-27ad-5bb0-982f-8c2b949c8918"
                    ]
                },
                "BP92b": {
                    "questionId": "BP92b",
                    "chosenOptionIds": [
                        "b2f4c490-30df-5c8f-87c0-bc4acbcfb8e0"
                    ]
                },
                "BP94b": {
                    "questionId": "BP94b",
                    "chosenOptionIds": [
                        "038cb12b-2783-569a-b1d7-e28c65e591a3"
                    ]
                },
                "BP95b": {
                    "questionId": "BP95b",
                    "chosenOptionIds": [
                        "fb0bd4a7-2793-5b81-badc-fe81b4bc82dc"
                    ]
                },
                "BP96b": {
                    "questionId": "BP96b",
                    "chosenOptionIds": [
                        "8c3d3503-6334-55b9-b0f0-94c8e520094e"
                    ]
                },
                "BP97b": {
                    "questionId": "BP97b",
                    "chosenOptionIds": [
                        "6895096a-ef29-546e-9967-d7a23b4a2859"
                    ]
                },
                "BP98b": {
                    "questionId": "BP98b",
                    "chosenOptionIds": [
                        "01641419-fbfc-52a5-ae63-0fa736ff9847"
                    ]
                },
                "BP99.3b": {
                    "questionId": "BP99.3b",
                    "chosenOptionIds": [
                        "bf30a6c2-f8e2-5305-b4d4-7ad6227449d3"
                    ]
                },
                "BP99b": {
                    "questionId": "BP99b",
                    "chosenOptionIds": [
                        "68860df1-e835-50d6-8eb2-f9d9f2f97efa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "BP_neg1": {
                    "questionId": "BP_neg1",
                    "chosenOptionIds": [
                        "119208b0-ff3b-5c43-80be-4f07e65b6248"
                    ]
                }
            }
        },
        {
            "name": "Mood test: J3J4P0Y aspect: simple_bd2 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "c61d70d4-b773-54dd-94d0-d858a47be0fb"
                    ]
                },
                "D-1.5b": {
                    "questionId": "D-1.5b",
                    "freeValue": 5
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "fe031280-7d5d-52cb-aca8-1a8664b3234a"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "916d657d-9f2a-5451-8465-18e3bd863347"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "b74511c7-9f97-5c15-8bf5-6ec4357b4814"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 0.20833333333333331
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "db360e93-3616-5d89-be36-5ccee4aacf1c"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-1bl": {
                    "questionId": "D-1bl",
                    "chosenOptionIds": [
                        "83937123-de06-5b20-a479-c5e3e92848c4"
                    ]
                },
                "D-2": {
                    "questionId": "D-2",
                    "freeValue": 25
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "11f457f5-4c39-5458-ba9a-84478dd379ba"
                    ]
                },
                "D-2bl": {
                    "questionId": "D-2bl",
                    "chosenOptionIds": [
                        "02464c9b-d1d8-5f60-993d-5f37cbfcc102"
                    ]
                },
                "D-2bt": {
                    "questionId": "D-2bt",
                    "chosenOptionIds": [
                        "618070b6-e9e5-599a-a906-eed44f5c2dd9"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "bccbebb3-ed8c-5251-856b-e93a1d4386f6"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5c30c60e-d53e-5cc3-b4a9-fdad9bec4cb7"
                    ]
                },
                "D-3bl": {
                    "questionId": "D-3bl",
                    "freeValue": 3
                },
                "D-3bt": {
                    "questionId": "D-3bt",
                    "chosenOptionIds": [
                        "0d89dae6-d14a-56da-8409-ea7c21e59375"
                    ]
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-42bt": {
                    "questionId": "D-42bt",
                    "freeValue": 0.16666666666666666
                },
                "D-43bt": {
                    "questionId": "D-43bt",
                    "chosenOptionIds": [
                        "644dd944-5a2a-533b-9ef0-fadb5054c422"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "a449e4ae-663b-50fb-9b62-1708b0a32669"
                    ]
                },
                "D-4bt": {
                    "questionId": "D-4bt",
                    "freeValue": 4
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "f3efc764-ca53-52c3-9c3a-38c7f787d415"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "3e093dd3-857e-5004-a4fb-961fbfa59ece"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "adecf2d3-f302-50f8-a4a9-3ffac1789bc6"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "4c78ae3e-7de4-545f-aca1-03fa41fc0c23"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "13f8bac1-d0f5-539b-8596-1c55821bea46"
                    ]
                },
                "D-s10bt": {
                    "questionId": "D-s10bt",
                    "chosenOptionIds": [
                        "da993bd6-c107-53a4-9f6d-09f5a79aff65"
                    ]
                },
                "D-s11bt": {
                    "questionId": "D-s11bt",
                    "chosenOptionIds": [
                        "daf8b3c7-fb19-5a3f-ba9a-784adda7414f",
                        "6994b88e-cfa5-5986-b9e8-625d17d1147c",
                        "16e1a0b8-28c5-5bcd-931d-335c858c3f88",
                        "64af3cae-713a-51e1-99c1-09e2fdaa755d"
                    ]
                },
                "D-s1bt": {
                    "questionId": "D-s1bt",
                    "chosenOptionIds": [
                        "c17482e4-818a-50cd-90fa-1246d037825e"
                    ]
                },
                "D-s2bt": {
                    "questionId": "D-s2bt",
                    "chosenOptionIds": [
                        "aef7f114-e448-575f-bcbb-035e54001375"
                    ]
                },
                "D-s3bt": {
                    "questionId": "D-s3bt",
                    "chosenOptionIds": [
                        "11f57889-ac93-53ab-88a7-9aea03a29429"
                    ]
                },
                "D-s4bt": {
                    "questionId": "D-s4bt",
                    "chosenOptionIds": [
                        "6dd2621e-8242-58f6-8c6c-77df4a9eef53"
                    ]
                },
                "D-s5bt": {
                    "questionId": "D-s5bt",
                    "chosenOptionIds": [
                        "e6a5403c-95a3-58c9-9259-e02e5fafd441"
                    ]
                },
                "D-s6bt": {
                    "questionId": "D-s6bt",
                    "chosenOptionIds": [
                        "bb7e3f7e-73b0-56b0-b3d3-e336498509e8"
                    ]
                },
                "D-s7bt": {
                    "questionId": "D-s7bt",
                    "chosenOptionIds": [
                        "d703f0d6-4b67-5a66-8740-b5e4b3b0eb4d"
                    ]
                },
                "D-s8bt": {
                    "questionId": "D-s8bt",
                    "chosenOptionIds": [
                        "ebfe9c3e-852e-5116-8185-8430f6b361f8"
                    ]
                },
                "D-s9bt": {
                    "questionId": "D-s9bt",
                    "chosenOptionIds": [
                        "da503bbd-e567-576e-a21f-e0fad60dccdd"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "60fe0bb4-d770-56f2-b05a-864625a48e4d"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "7599bcaa-89a5-5f71-80ea-b70c360bb44a"
                    ]
                },
                "BP101b": {
                    "questionId": "BP101b",
                    "chosenOptionIds": [
                        "cb80bcac-db78-55bd-b051-bdd00801fd54"
                    ]
                },
                "BP102b": {
                    "questionId": "BP102b",
                    "chosenOptionIds": [
                        "46a64b5b-6641-5b1b-92aa-5366444fb134"
                    ]
                },
                "BP103b": {
                    "questionId": "BP103b",
                    "chosenOptionIds": [
                        "867b0ee4-73d9-5aac-b96d-bc2d85d5b183"
                    ]
                },
                "BP104b": {
                    "questionId": "BP104b",
                    "chosenOptionIds": [
                        "c59b5910-3279-509f-8175-f2fd0da2dc86"
                    ]
                },
                "BP87b": {
                    "questionId": "BP87b",
                    "chosenOptionIds": [
                        "7949bd96-3757-5573-bd89-3a91a383cc99"
                    ]
                },
                "BP88b": {
                    "questionId": "BP88b",
                    "chosenOptionIds": [
                        "fa24b924-a8e1-5f02-893b-3d9643007991"
                    ]
                },
                "BP89b": {
                    "questionId": "BP89b",
                    "chosenOptionIds": [
                        "db509963-d872-5b97-ba9b-a276f58e0189"
                    ]
                },
                "BP90b": {
                    "questionId": "BP90b",
                    "chosenOptionIds": [
                        "95c2822d-27ad-5bb0-982f-8c2b949c8918"
                    ]
                },
                "BP92b": {
                    "questionId": "BP92b",
                    "chosenOptionIds": [
                        "f59918ba-eb3a-5cf1-81aa-8b7f6ac0df5e"
                    ]
                },
                "BP94b": {
                    "questionId": "BP94b",
                    "chosenOptionIds": [
                        "038cb12b-2783-569a-b1d7-e28c65e591a3"
                    ]
                },
                "BP95b": {
                    "questionId": "BP95b",
                    "chosenOptionIds": [
                        "fb0bd4a7-2793-5b81-badc-fe81b4bc82dc"
                    ]
                },
                "BP96b": {
                    "questionId": "BP96b",
                    "chosenOptionIds": [
                        "8c3d3503-6334-55b9-b0f0-94c8e520094e"
                    ]
                },
                "BP97b": {
                    "questionId": "BP97b",
                    "chosenOptionIds": [
                        "8a1fc783-28cb-5bf7-aa78-8f6b7c36ad29"
                    ]
                },
                "BP98b": {
                    "questionId": "BP98b",
                    "chosenOptionIds": [
                        "01641419-fbfc-52a5-ae63-0fa736ff9847"
                    ]
                },
                "BP99.2b": {
                    "questionId": "BP99.2b",
                    "chosenOptionIds": [
                        "0f933dbb-72b4-5212-b415-8ea806f59368"
                    ]
                },
                "BP99.3b": {
                    "questionId": "BP99.3b",
                    "chosenOptionIds": [
                        "bf30a6c2-f8e2-5305-b4d4-7ad6227449d3"
                    ]
                },
                "BP99b": {
                    "questionId": "BP99b",
                    "chosenOptionIds": [
                        "3f987aaa-e435-55a9-9c1b-aa6d2e476738"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "BP_neg1": {
                    "questionId": "BP_neg1",
                    "chosenOptionIds": [
                        "119208b0-ff3b-5c43-80be-4f07e65b6248"
                    ]
                }
            }
        },
        {
            "name": "Mood test: Z3RLO3O aspect: simple_bd2 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "3bd18f66-6f1e-5ea4-ae24-2990ed197bef"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "eb68a88d-b49b-5cde-a9c6-5e4c78e27d19"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "a0e528ca-2fbb-5da1-9398-0758f79755aa"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 240
                },
                "D-15a": {
                    "questionId": "D-15a",
                    "chosenOptionIds": [
                        "7b763ff0-7703-54f7-8eae-aa83417ca133"
                    ]
                },
                "D-16.5a": {
                    "questionId": "D-16.5a",
                    "chosenOptionIds": [
                        "ae0a4d4e-e9bf-51bf-8506-0cbced38db92"
                    ]
                },
                "D-17a": {
                    "questionId": "D-17a",
                    "chosenOptionIds": [
                        "33b013af-d830-516a-9632-69dcc7a0f736"
                    ]
                },
                "D-18a": {
                    "questionId": "D-18a",
                    "chosenOptionIds": [
                        "24079de6-89e4-58c8-bb94-8e281cfc37da"
                    ]
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "db360e93-3616-5d89-be36-5ccee4aacf1c"
                    ]
                },
                "D-20a": {
                    "questionId": "D-20a",
                    "chosenOptionIds": [
                        "15c694bd-3089-51d8-8fff-6286e2113f9c"
                    ]
                },
                "D-21a": {
                    "questionId": "D-21a",
                    "chosenOptionIds": [
                        "e64b46f6-a0c1-5378-95de-f1de59b491b4"
                    ]
                },
                "D-23a": {
                    "questionId": "D-23a",
                    "chosenOptionIds": [
                        "f6cf63c5-0c57-5422-9431-55fec4c93c63"
                    ]
                },
                "D-26a": {
                    "questionId": "D-26a",
                    "chosenOptionIds": [
                        "945792e1-fdf5-5959-89c8-6a1caa4eff88"
                    ]
                },
                "D-27a": {
                    "questionId": "D-27a",
                    "chosenOptionIds": [
                        "8896026c-15ab-577e-8c44-e63eb7e6dba3"
                    ]
                },
                "D-28a": {
                    "questionId": "D-28a",
                    "chosenOptionIds": [
                        "8de8c31e-96ef-5e7d-8024-f6a72d07dd28"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "de11fb6b-7e7d-52b1-9d09-2d6debc53314"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "fd9c4394-6664-5144-8797-00d27a4dda01"
                    ]
                },
                "D-30a": {
                    "questionId": "D-30a",
                    "chosenOptionIds": [
                        "d309cef4-2204-5a10-a98d-b66740336838"
                    ]
                },
                "D-31a": {
                    "questionId": "D-31a",
                    "chosenOptionIds": [
                        "eba65e81-9b02-55d6-82cb-0b7ce6da41de"
                    ]
                },
                "D-32a": {
                    "questionId": "D-32a",
                    "chosenOptionIds": [
                        "ea2a4fbd-008a-571b-bb68-cbd0ba351521"
                    ]
                },
                "D-33a": {
                    "questionId": "D-33a",
                    "chosenOptionIds": [
                        "84dd5565-eec7-54c7-86e9-fa11de50e2c3"
                    ]
                },
                "D-34a": {
                    "questionId": "D-34a",
                    "chosenOptionIds": [
                        "e919c54f-d9a4-5a15-9ae7-8b6b52607a22"
                    ]
                },
                "D-35a": {
                    "questionId": "D-35a",
                    "chosenOptionIds": [
                        "bcace469-f380-599d-a33a-26db6d0053ad"
                    ]
                },
                "D-36a": {
                    "questionId": "D-36a",
                    "chosenOptionIds": [
                        "185038dd-ce55-53b2-ba09-0bd163af90b3"
                    ]
                },
                "D-37a": {
                    "questionId": "D-37a",
                    "chosenOptionIds": [
                        "fd4e0608-283c-5f56-a026-edfa381519c9"
                    ]
                },
                "D-38a": {
                    "questionId": "D-38a",
                    "chosenOptionIds": [
                        "cfa17d19-5b15-56ef-82c1-07949e21dd81"
                    ]
                },
                "D-39a": {
                    "questionId": "D-39a",
                    "chosenOptionIds": [
                        "ae31125f-c3db-5082-ae70-4329a2508744"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "db7514e2-d317-55fb-82d3-2e4a49c2494b"
                    ]
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-40a": {
                    "questionId": "D-40a",
                    "chosenOptionIds": [
                        "582119a7-d4a0-58ff-9b1d-2af045e11a7f"
                    ]
                },
                "D-41a": {
                    "questionId": "D-41a",
                    "chosenOptionIds": [
                        "89f5ff80-0cae-515a-a4d1-02448dbc11b8"
                    ]
                },
                "D-42a": {
                    "questionId": "D-42a",
                    "chosenOptionIds": [
                        "7099f8a9-4650-5da2-a627-bcd62cd99375"
                    ]
                },
                "D-43a": {
                    "questionId": "D-43a",
                    "chosenOptionIds": [
                        "cd0abe72-b3e8-5c78-8330-7ee4a1657536"
                    ]
                },
                "D-44.5a": {
                    "questionId": "D-44.5a",
                    "chosenOptionIds": [
                        "d87971dc-d745-5b64-ac37-37fbe1e722ac",
                        "bcf5fb9f-f4b9-5f0c-a964-69a4e1a73334"
                    ]
                },
                "D-44a": {
                    "questionId": "D-44a",
                    "chosenOptionIds": [
                        "7d6584ca-1b93-543d-9ee4-400ca40134be"
                    ]
                },
                "D-45a": {
                    "questionId": "D-45a",
                    "freeValue": 14
                },
                "D-46a": {
                    "questionId": "D-46a",
                    "chosenOptionIds": [
                        "1fd426df-808a-5c68-9b6e-23ff2f128b7d",
                        "4c178978-f79a-58fc-8e73-3a98614c4b6c",
                        "6e49f368-62af-5abb-9034-a58bd534944f",
                        "b3e43bd8-fbe3-5cda-b891-6f11e43993d0",
                        "d9665b1f-cdd2-5979-895e-27bca6487533",
                        "7492eab7-c625-52d0-a2e3-4bf3332decc0"
                    ]
                },
                "D-49.1a": {
                    "questionId": "D-49.1a",
                    "chosenOptionIds": [
                        "649f1c9d-3139-5567-b605-e22de7ed4f87"
                    ]
                },
                "D-49a": {
                    "questionId": "D-49a",
                    "chosenOptionIds": [
                        "16dc6af1-32a9-59c5-b66c-57f6becea1e0"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "1d4beed1-78e5-531c-a255-643fac260dee"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "b52073e3-c813-51f5-88ba-9cd3bddd7023"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "3e093dd3-857e-5004-a4fb-961fbfa59ece"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "772bee29-c3a5-52e1-b785-a77d506ae4fb"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "4c78ae3e-7de4-545f-aca1-03fa41fc0c23"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "60fe0bb4-d770-56f2-b05a-864625a48e4d"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "ccbdcbec-96d0-5362-977b-784289f5cfc0"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "4a0f1c4d-38cf-5de4-99e3-0ee818f9709c"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "6a5aa899-7b89-5661-a554-71b6058ad4a4"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "d3224e16-1b0c-5266-9e14-ac72d2b2e988"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "b24df75b-6bb9-5f5f-99e3-c883ec3644ec"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP12": {
                    "questionId": "BP12",
                    "chosenOptionIds": [
                        "1d6299ac-2ac6-51e2-9a3f-13e8d44e652a"
                    ]
                },
                "BP12.5": {
                    "questionId": "BP12.5",
                    "chosenOptionIds": [
                        "cd7ce00f-4e7c-57ac-9c6c-4137efa24c8c"
                    ]
                },
                "BP14": {
                    "questionId": "BP14",
                    "chosenOptionIds": [
                        "5585a7e8-a4b7-567e-b339-90537a58f1e3"
                    ]
                },
                "BP14.1": {
                    "questionId": "BP14.1",
                    "chosenOptionIds": [
                        "69f9a784-ce6d-5c82-bd3f-4c9c44bf5992"
                    ]
                },
                "BP14.2": {
                    "questionId": "BP14.2",
                    "freeValue": 365
                },
                "BP14.3": {
                    "questionId": "BP14.3",
                    "chosenOptionIds": [
                        "9f5fc152-75fb-59d8-8f40-7a6ca15c69cb"
                    ]
                },
                "BP16": {
                    "questionId": "BP16",
                    "chosenOptionIds": [
                        "fabceeea-8fea-5444-8b67-8f189f366c27"
                    ]
                },
                "BP16.0": {
                    "questionId": "BP16.0",
                    "chosenOptionIds": [
                        "4b26caa5-9124-51b2-ab08-2f4e977cee1f"
                    ]
                },
                "BP16.01": {
                    "questionId": "BP16.01",
                    "chosenOptionIds": [
                        "f81655d7-78e5-59b1-a7eb-90a2ec7a107d"
                    ]
                },
                "BP16.02": {
                    "questionId": "BP16.02",
                    "chosenOptionIds": [
                        "16a695c9-3ac1-5d34-b799-44602d85b556"
                    ]
                },
                "BP18": {
                    "questionId": "BP18",
                    "chosenOptionIds": [
                        "b577f323-ec54-5d25-a2b9-4f8de30d60a2"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 43
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "f03de28d-8f71-5af4-be2f-45f4e6092a3d"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "387b19c0-67d0-557e-9fba-ae065dcf3587"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "afb57366-c0b3-5f73-92eb-27bc133ad2ed"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "667ba57c-b1cd-5f9b-aa12-58e65a981910"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "fd30dc27-8e89-5d1a-9d95-8844603d4379"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "e22cf233-c174-52fb-af55-97bf9c46e971"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "0feda24f-7b01-557b-8ca5-10eb924cbdac"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "d680a8d9-e35f-563f-9d1d-e7c3136aff6d"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "94795194-4a80-590f-be62-ee78f2fa0ff5"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "732c51f9-a0b6-5951-bd7d-eaaec24316ba"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "6928c696-e6d7-5a14-a004-15a497ac6562"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "17b4da6f-7135-523c-b90b-2fe3b66785ff"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "3cf77140-2757-58eb-b4ea-f2a90cd766e7"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "5c06d516-e816-58d6-997e-f8fa91131ac7"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "a020ed85-ec9d-5778-8006-41f634cab09d"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM19": {
                    "questionId": "HM19",
                    "chosenOptionIds": [
                        "99f474d7-d509-592a-856a-afed343fb1b8"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "98ea07a0-ae95-570f-8d11-a3e7c682e986"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "696fdd9d-5ced-504b-a933-bb3e575b0099"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c41974b6-d394-569c-8f45-49f79fcac5c6"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "43c32319-c75f-5169-b0e5-713820721d4f"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "b2cb304f-f035-53fc-8763-e2f91b031f9b"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "0170b4cf-0541-53cd-a9db-818d5067dc23"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "2be66549-5767-51b4-82ed-deeab0723f8d"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "2a17c2fe-2592-58b1-98e9-3dc4168fe3e3"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "834b04f2-af09-5337-8fd5-be4a70c99e75"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "fa32b497-4a4b-5076-a391-b6dffa8e1851"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "8823c74f-ff7c-52d8-9c73-fd3b4180f48a"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "1d07911e-929d-5719-aeea-a68f593d18e8"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: 50MQK36 aspect: simple_bd2 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "0ec0e224-a5cf-5ece-b3ab-37061a600ace"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "a16e8e14-524e-53df-b4ef-730a2c8e5f32"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "eb68a88d-b49b-5cde-a9c6-5e4c78e27d19"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "a0e528ca-2fbb-5da1-9398-0758f79755aa"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 270
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "913bc1e9-8da1-54c7-b935-13fc5d48fbd9"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "11f457f5-4c39-5458-ba9a-84478dd379ba"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5b6c0a69-6369-53d1-b2e1-5cd6294a9f71"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "9d1b858c-7064-5a84-80d6-786c18e603b6",
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b",
                        "1d4beed1-78e5-531c-a255-643fac260dee"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "b52073e3-c813-51f5-88ba-9cd3bddd7023"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "3e093dd3-857e-5004-a4fb-961fbfa59ece"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "adecf2d3-f302-50f8-a4a9-3ffac1789bc6"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "67316d94-1cf0-5bd0-a4db-f57bc70684ba"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "60fe0bb4-d770-56f2-b05a-864625a48e4d"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "7599bcaa-89a5-5f71-80ea-b70c360bb44a"
                    ]
                },
                "BP101b": {
                    "questionId": "BP101b",
                    "chosenOptionIds": [
                        "fe614147-f80a-5aff-bb03-9298781e2468"
                    ]
                },
                "BP102b": {
                    "questionId": "BP102b",
                    "chosenOptionIds": [
                        "523c7d8e-e42b-5654-b037-e8837afb49e7"
                    ]
                },
                "BP103b": {
                    "questionId": "BP103b",
                    "chosenOptionIds": [
                        "867b0ee4-73d9-5aac-b96d-bc2d85d5b183"
                    ]
                },
                "BP104b": {
                    "questionId": "BP104b",
                    "chosenOptionIds": [
                        "c59b5910-3279-509f-8175-f2fd0da2dc86"
                    ]
                },
                "BP87b": {
                    "questionId": "BP87b",
                    "chosenOptionIds": [
                        "659c0d9a-389d-5b62-b3ac-e385dfafe63c"
                    ]
                },
                "BP88b": {
                    "questionId": "BP88b",
                    "chosenOptionIds": [
                        "7e4bdef7-d166-5a09-b3a4-639c1700578c"
                    ]
                },
                "BP89b": {
                    "questionId": "BP89b",
                    "chosenOptionIds": [
                        "db509963-d872-5b97-ba9b-a276f58e0189"
                    ]
                },
                "BP90b": {
                    "questionId": "BP90b",
                    "chosenOptionIds": [
                        "b4a79888-68e8-5b52-9a4e-2149be85f6e7"
                    ]
                },
                "BP92b": {
                    "questionId": "BP92b",
                    "chosenOptionIds": [
                        "b2f4c490-30df-5c8f-87c0-bc4acbcfb8e0"
                    ]
                },
                "BP94b": {
                    "questionId": "BP94b",
                    "chosenOptionIds": [
                        "9cf74958-461b-59df-b1a2-7ee7d7696a0b"
                    ]
                },
                "BP95b": {
                    "questionId": "BP95b",
                    "chosenOptionIds": [
                        "fb0bd4a7-2793-5b81-badc-fe81b4bc82dc"
                    ]
                },
                "BP96b": {
                    "questionId": "BP96b",
                    "chosenOptionIds": [
                        "4043e017-8609-50ef-bb4a-29f57c28965a"
                    ]
                },
                "BP97b": {
                    "questionId": "BP97b",
                    "chosenOptionIds": [
                        "8a1fc783-28cb-5bf7-aa78-8f6b7c36ad29"
                    ]
                },
                "BP98b": {
                    "questionId": "BP98b",
                    "chosenOptionIds": [
                        "cae1d431-f7ae-5a5a-924d-25c540e96d28"
                    ]
                },
                "BP99.3b": {
                    "questionId": "BP99.3b",
                    "chosenOptionIds": [
                        "d0adef9f-05e3-5ddf-a0f3-60eeb50d3862"
                    ]
                },
                "BP99b": {
                    "questionId": "BP99b",
                    "chosenOptionIds": [
                        "68860df1-e835-50d6-8eb2-f9d9f2f97efa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "BP_neg1": {
                    "questionId": "BP_neg1",
                    "chosenOptionIds": [
                        "119208b0-ff3b-5c43-80be-4f07e65b6248"
                    ]
                }
            }
        },
        {
            "name": "Mood test: 2E17NED aspect: simple_bd2 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "c61d70d4-b773-54dd-94d0-d858a47be0fb"
                    ]
                },
                "D-1.5b": {
                    "questionId": "D-1.5b",
                    "freeValue": 3
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "3bd18f66-6f1e-5ea4-ae24-2990ed197bef"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "916d657d-9f2a-5451-8465-18e3bd863347"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "b74511c7-9f97-5c15-8bf5-6ec4357b4814"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 0.125
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "db360e93-3616-5d89-be36-5ccee4aacf1c"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-1bl": {
                    "questionId": "D-1bl",
                    "chosenOptionIds": [
                        "83937123-de06-5b20-a479-c5e3e92848c4"
                    ]
                },
                "D-2": {
                    "questionId": "D-2",
                    "freeValue": 28
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "de11fb6b-7e7d-52b1-9d09-2d6debc53314"
                    ]
                },
                "D-2bl": {
                    "questionId": "D-2bl",
                    "chosenOptionIds": [
                        "02464c9b-d1d8-5f60-993d-5f37cbfcc102"
                    ]
                },
                "D-2bs": {
                    "questionId": "D-2bs",
                    "chosenOptionIds": [
                        "71e4fa61-0420-5a47-8362-38d1db6b5895"
                    ]
                },
                "D-2bt": {
                    "questionId": "D-2bt",
                    "chosenOptionIds": [
                        "618070b6-e9e5-599a-a906-eed44f5c2dd9"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "fd9c4394-6664-5144-8797-00d27a4dda01"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "db7514e2-d317-55fb-82d3-2e4a49c2494b"
                    ]
                },
                "D-3bl": {
                    "questionId": "D-3bl",
                    "freeValue": 2
                },
                "D-3bs": {
                    "questionId": "D-3bs",
                    "chosenOptionIds": [
                        "fe554f25-dfd7-5062-a2b8-f2662e2c3466"
                    ]
                },
                "D-3bt": {
                    "questionId": "D-3bt",
                    "chosenOptionIds": [
                        "0d89dae6-d14a-56da-8409-ea7c21e59375"
                    ]
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-42bt": {
                    "questionId": "D-42bt",
                    "freeValue": 0
                },
                "D-43bt": {
                    "questionId": "D-43bt",
                    "chosenOptionIds": [
                        "90e60395-fc3d-56cb-b6f7-2e24fba02803"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "a449e4ae-663b-50fb-9b62-1708b0a32669"
                    ]
                },
                "D-4bs": {
                    "questionId": "D-4bs",
                    "freeValue": 7
                },
                "D-4bt": {
                    "questionId": "D-4bt",
                    "freeValue": 0.08333333333333333
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "b52073e3-c813-51f5-88ba-9cd3bddd7023"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "ef6fddab-7a07-5f20-af20-74f2444eee53"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "3b216cf8-72e7-558c-bdd3-cc23a1865674"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "D-s10bt": {
                    "questionId": "D-s10bt",
                    "chosenOptionIds": [
                        "3deea42c-5d93-54cd-ba0f-4242654c3747"
                    ]
                },
                "D-s1bt": {
                    "questionId": "D-s1bt",
                    "chosenOptionIds": [
                        "4fa2ad77-a998-57d3-ae06-568782e0fb81"
                    ]
                },
                "D-s2bt": {
                    "questionId": "D-s2bt",
                    "chosenOptionIds": [
                        "e074592a-4667-5b65-b713-b8892451b433"
                    ]
                },
                "D-s3bt": {
                    "questionId": "D-s3bt",
                    "chosenOptionIds": [
                        "11f57889-ac93-53ab-88a7-9aea03a29429"
                    ]
                },
                "D-s4bt": {
                    "questionId": "D-s4bt",
                    "chosenOptionIds": [
                        "27313271-8ec6-5f99-bb2c-0ed50a7c1527"
                    ]
                },
                "D-s5bt": {
                    "questionId": "D-s5bt",
                    "chosenOptionIds": [
                        "d033bb4c-5d3c-519b-be31-1177042bffb4"
                    ]
                },
                "D-s6bt": {
                    "questionId": "D-s6bt",
                    "chosenOptionIds": [
                        "53625e56-4f03-5f54-a340-d5b8cabe24d9"
                    ]
                },
                "D-s7bt": {
                    "questionId": "D-s7bt",
                    "chosenOptionIds": [
                        "321f8608-d6e5-5e11-a88a-11d3351e8650"
                    ]
                },
                "D-s8bt": {
                    "questionId": "D-s8bt",
                    "chosenOptionIds": [
                        "ebfe9c3e-852e-5116-8185-8430f6b361f8"
                    ]
                },
                "D-s9bt": {
                    "questionId": "D-s9bt",
                    "chosenOptionIds": [
                        "34daded3-efdb-5981-a1fc-e31ec1176383"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "6a4d8b43-be3d-59b5-9bf4-1ca9f35b6973"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "ccbdcbec-96d0-5362-977b-784289f5cfc0"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "db7aa4b7-99a5-5475-a4e8-a744f3ef5a4a"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "e9eb902a-d327-5ec5-938c-dc317ca59b13"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "1daccd4c-e5ff-5f18-ba55-7c2d2d93b849"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "b24df75b-6bb9-5f5f-99e3-c883ec3644ec"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP12": {
                    "questionId": "BP12",
                    "chosenOptionIds": [
                        "22f9a995-3f9a-5fe9-99bb-71b7f1f96621"
                    ]
                },
                "BP15": {
                    "questionId": "BP15",
                    "chosenOptionIds": [
                        "9a1158f1-f08a-581d-a088-650041097c24"
                    ]
                },
                "BP15.1": {
                    "questionId": "BP15.1",
                    "chosenOptionIds": [
                        "aa7bd0b8-95ca-5179-a037-3956a8c2816d"
                    ]
                },
                "BP15.2": {
                    "questionId": "BP15.2",
                    "freeValue": 0.08333333333333333
                },
                "BP15.3": {
                    "questionId": "BP15.3",
                    "chosenOptionIds": [
                        "cfa519ff-08a9-5fa4-b12d-87b7a597cb13"
                    ]
                },
                "BP16": {
                    "questionId": "BP16",
                    "chosenOptionIds": [
                        "f1d6b61f-7ae0-5a6f-bbd0-a81b771141d3"
                    ]
                },
                "BP16.0": {
                    "questionId": "BP16.0",
                    "chosenOptionIds": [
                        "4b26caa5-9124-51b2-ab08-2f4e977cee1f"
                    ]
                },
                "BP16.01": {
                    "questionId": "BP16.01",
                    "chosenOptionIds": [
                        "f81655d7-78e5-59b1-a7eb-90a2ec7a107d"
                    ]
                },
                "BP16.02": {
                    "questionId": "BP16.02",
                    "chosenOptionIds": [
                        "16a695c9-3ac1-5d34-b799-44602d85b556"
                    ]
                },
                "BP19": {
                    "questionId": "BP19",
                    "chosenOptionIds": [
                        "601bc370-8a4c-5b1f-a7f6-6c174f421733"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 35
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "c7bee7a0-3037-5eb1-96a1-85b884d7e1dd"
                    ]
                },
                "BP3.01": {
                    "questionId": "BP3.01",
                    "freeValue": 1
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "e620af65-9b5b-57e6-b13a-65616e67e084"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "cb636d32-4f4e-51b3-a23f-2a11b8771b7e"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "0953f30c-801a-54f6-8238-b9dc42ab17f9"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "fd30dc27-8e89-5d1a-9d95-8844603d4379"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "d9fb150b-68b8-50ed-bb1d-eafd41f63f3b"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "46c90446-3ae9-53f5-9b57-ad19d66b5be6"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "63f0b17a-9832-5f0f-8630-e74f1a4fdfe7"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "c88e3fb5-913a-5c5c-a42b-603f7bb60a40"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "732c51f9-a0b6-5951-bd7d-eaaec24316ba"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "de2ea429-4fcd-53b8-8d9f-ebb69a637a02"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "6928c696-e6d7-5a14-a004-15a497ac6562"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "17b4da6f-7135-523c-b90b-2fe3b66785ff"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "cfb8fe0e-821a-554a-bb57-922f0ecc0f44"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "5c06d516-e816-58d6-997e-f8fa91131ac7"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "c0cc09c3-1335-5eb4-8801-b3cdaf74f6fa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM20": {
                    "questionId": "HM20",
                    "chosenOptionIds": [
                        "4eb576cf-e8d9-51f2-94cd-d232476ee5cb"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "6b99de8b-634a-5eb0-bc65-bdf30eb9d433"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "9d212885-9b07-5623-8234-226adff08f6d"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c41974b6-d394-569c-8f45-49f79fcac5c6"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "43c32319-c75f-5169-b0e5-713820721d4f"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "b2cb304f-f035-53fc-8763-e2f91b031f9b"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "366c6a1b-c8b2-5faf-9d2f-72dbde24e869"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "2be66549-5767-51b4-82ed-deeab0723f8d"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "2a17c2fe-2592-58b1-98e9-3dc4168fe3e3"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "834b04f2-af09-5337-8fd5-be4a70c99e75"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "86277249-cf50-5f91-a27f-f3a69344e796"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "8823c74f-ff7c-52d8-9c73-fd3b4180f48a"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "7d6921a2-7e63-5f12-9c75-dff8b592fabc"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: 10QJ8EP aspect: simple_bd2 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "0ec0e224-a5cf-5ece-b3ab-37061a600ace"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "a16e8e14-524e-53df-b4ef-730a2c8e5f32"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "916d657d-9f2a-5451-8465-18e3bd863347"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "a0e528ca-2fbb-5da1-9398-0758f79755aa"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 365
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "913bc1e9-8da1-54c7-b935-13fc5d48fbd9"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "11f457f5-4c39-5458-ba9a-84478dd379ba"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5c30c60e-d53e-5cc3-b4a9-fdad9bec4cb7"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "ba6dc2ba-3c20-57b6-a276-32c863109ff6",
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b",
                        "9d1b858c-7064-5a84-80d6-786c18e603b6"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "c9813959-ba61-5220-9e27-659abb256037"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "adecf2d3-f302-50f8-a4a9-3ffac1789bc6"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "d362cd87-7515-551e-bc74-19ef903484aa"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "4896b504-2025-52c3-85e2-c6cf3205cef7"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "ccbdcbec-96d0-5362-977b-784289f5cfc0"
                    ]
                },
                "BP10": {
                    "questionId": "BP10",
                    "chosenOptionIds": [
                        "7ad54f0a-87a6-5e82-bfc3-44b540230082"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "db7aa4b7-99a5-5475-a4e8-a744f3ef5a4a"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "ca5ac96d-2b69-5200-a063-61db840c9ef2"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "1daccd4c-e5ff-5f18-ba55-7c2d2d93b849"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "b24df75b-6bb9-5f5f-99e3-c883ec3644ec"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 25
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "c7bee7a0-3037-5eb1-96a1-85b884d7e1dd"
                    ]
                },
                "BP3.1": {
                    "questionId": "BP3.1",
                    "chosenOptionIds": [
                        "5bf1e313-2624-5b5f-9673-37ae9319ff0c"
                    ]
                },
                "BP3.2": {
                    "questionId": "BP3.2",
                    "chosenOptionIds": [
                        "ec333f8b-146a-5700-a15d-527032ceac3f"
                    ]
                },
                "BP3.3": {
                    "questionId": "BP3.3",
                    "freeValue": 5
                },
                "BP3.4": {
                    "questionId": "BP3.4",
                    "chosenOptionIds": [
                        "6ec82bbf-7582-5509-ace2-5ecf0ce82ec3"
                    ]
                },
                "BP4": {
                    "questionId": "BP4",
                    "chosenOptionIds": [
                        "9765aba8-7d55-5928-b79d-36aa58646036"
                    ]
                },
                "BP4.5": {
                    "questionId": "BP4.5",
                    "chosenOptionIds": [
                        "66a3460c-65ce-5a4c-9774-2e303ae4961e"
                    ]
                },
                "BP6": {
                    "questionId": "BP6",
                    "chosenOptionIds": [
                        "742a22d6-a570-5e3a-8e72-c001f1e9f98b"
                    ]
                },
                "BP6.1": {
                    "questionId": "BP6.1",
                    "chosenOptionIds": [
                        "a99773bd-2bff-523e-bc29-1f761307b3d4"
                    ]
                },
                "BP6.2": {
                    "questionId": "BP6.2",
                    "freeValue": 5
                },
                "BP6.3": {
                    "questionId": "BP6.3",
                    "chosenOptionIds": [
                        "e061c578-086e-5990-bba6-279cb273f3a5"
                    ]
                },
                "BP8": {
                    "questionId": "BP8",
                    "chosenOptionIds": [
                        "87e0081b-9455-5ced-9225-10c2f70b1734"
                    ]
                },
                "BP8.0": {
                    "questionId": "BP8.0",
                    "chosenOptionIds": [
                        "3568cdb1-2500-512b-aada-2547c5bfd835"
                    ]
                },
                "BP8.01": {
                    "questionId": "BP8.01",
                    "chosenOptionIds": [
                        "5020bfdd-a280-56cf-b7f5-3b0b7e667165"
                    ]
                },
                "BP8.02": {
                    "questionId": "BP8.02",
                    "chosenOptionIds": [
                        "7d3a0aa5-605b-58d1-a834-073feefe8e5d"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "387b19c0-67d0-557e-9fba-ae065dcf3587"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "afb57366-c0b3-5f73-92eb-27bc133ad2ed"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "667ba57c-b1cd-5f9b-aa12-58e65a981910"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "fd30dc27-8e89-5d1a-9d95-8844603d4379"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "e22cf233-c174-52fb-af55-97bf9c46e971"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "cc7c09ae-1cb7-5b9a-9cd3-8d1012b90ee8"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 5
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "17619692-6d18-5558-99da-766a1c275e03"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "f35fe383-d2aa-51c4-9fde-961b1de6d52b"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "ec84966e-bb9f-5ba9-a0cf-d5d3503a74e0"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "6928c696-e6d7-5a14-a004-15a497ac6562"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "2723ed09-37f6-522c-92b1-e518d4fe9315"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "3cf77140-2757-58eb-b4ea-f2a90cd766e7"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "5c06d516-e816-58d6-997e-f8fa91131ac7"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "e81fe613-4923-55ed-bd6c-bd3f19e026f9"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM2": {
                    "questionId": "HM2",
                    "chosenOptionIds": [
                        "de662c3f-66b4-566f-856d-2124e2725542"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "c3ee8981-dec3-5b3c-80ca-c1023f983a6d"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "696fdd9d-5ced-504b-a933-bb3e575b0099"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c41974b6-d394-569c-8f45-49f79fcac5c6"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "43c32319-c75f-5169-b0e5-713820721d4f"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "b2cb304f-f035-53fc-8763-e2f91b031f9b"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "0170b4cf-0541-53cd-a9db-818d5067dc23"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "2be66549-5767-51b4-82ed-deeab0723f8d"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "2a17c2fe-2592-58b1-98e9-3dc4168fe3e3"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "834b04f2-af09-5337-8fd5-be4a70c99e75"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "fda00d52-c8be-5b69-bdbd-905c13dddd4e"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "8823c74f-ff7c-52d8-9c73-fd3b4180f48a"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "1d07911e-929d-5719-aeea-a68f593d18e8"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: XE7DM0L aspect: simple_bd2 should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "fe031280-7d5d-52cb-aca8-1a8664b3234a"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "9b47900d-e5a7-5e58-830b-d9b3130a7990"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "826538fc-8a97-55e4-8013-fe6a93f8e451"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 4745
                },
                "D-15a": {
                    "questionId": "D-15a",
                    "chosenOptionIds": [
                        "7b763ff0-7703-54f7-8eae-aa83417ca133"
                    ]
                },
                "D-16.5a": {
                    "questionId": "D-16.5a",
                    "chosenOptionIds": [
                        "b409dbb9-80ef-5de1-96d0-1075735134db"
                    ]
                },
                "D-17a": {
                    "questionId": "D-17a",
                    "chosenOptionIds": [
                        "8413ed32-e959-5c2a-9d19-8cc68709c34d"
                    ]
                },
                "D-19a": {
                    "questionId": "D-19a",
                    "chosenOptionIds": [
                        "6fde9b5c-ed1c-561c-b516-8f5028a1bcd5"
                    ]
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "80c993c8-1a94-5684-a8f9-d8f5072c8e9c"
                    ]
                },
                "D-1bl": {
                    "questionId": "D-1bl",
                    "chosenOptionIds": [
                        "5da400b0-5bff-5744-a664-4305892ae669"
                    ]
                },
                "D-2": {
                    "questionId": "D-2",
                    "freeValue": 48
                },
                "D-20a": {
                    "questionId": "D-20a",
                    "chosenOptionIds": [
                        "15c694bd-3089-51d8-8fff-6286e2113f9c"
                    ]
                },
                "D-21a": {
                    "questionId": "D-21a",
                    "chosenOptionIds": [
                        "dc2b002f-2d96-504d-bde9-66faceececb6"
                    ]
                },
                "D-23a": {
                    "questionId": "D-23a",
                    "chosenOptionIds": [
                        "32a2b9d2-46ee-5235-9c03-8fb1fb1e5800"
                    ]
                },
                "D-26a": {
                    "questionId": "D-26a",
                    "chosenOptionIds": [
                        "eb4d6df4-7e45-5437-a36e-540fa95a45f9"
                    ]
                },
                "D-27a": {
                    "questionId": "D-27a",
                    "chosenOptionIds": [
                        "3d120f97-fb12-5e86-8bb9-8fc93babc803"
                    ]
                },
                "D-28a": {
                    "questionId": "D-28a",
                    "chosenOptionIds": [
                        "009661f3-60b5-52e8-bc5f-44c65767874c"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "cc243947-5067-5a98-ade6-a4e10d0b42cf"
                    ]
                },
                "D-2bl": {
                    "questionId": "D-2bl",
                    "chosenOptionIds": [
                        "2a8c96e2-cb5b-596a-99b6-a4885253776c"
                    ]
                },
                "D-2bs": {
                    "questionId": "D-2bs",
                    "chosenOptionIds": [
                        "bbd43ce6-ea63-5321-9d05-0bbf9a19513b"
                    ]
                },
                "D-2bt": {
                    "questionId": "D-2bt",
                    "chosenOptionIds": [
                        "315fa694-3bc0-5231-93f2-ceb1d9766403"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "bccbebb3-ed8c-5251-856b-e93a1d4386f6",
                        "14d310b2-5fb6-5f79-8bfa-e3524002e63d",
                        "fd9c4394-6664-5144-8797-00d27a4dda01"
                    ]
                },
                "D-30a": {
                    "questionId": "D-30a",
                    "chosenOptionIds": [
                        "1f6e9ea6-3402-596b-a518-31105c7a277b"
                    ]
                },
                "D-31a": {
                    "questionId": "D-31a",
                    "chosenOptionIds": [
                        "c78e0def-d73e-5c4e-8800-7c6f92d0a1ec"
                    ]
                },
                "D-32a": {
                    "questionId": "D-32a",
                    "chosenOptionIds": [
                        "ea2a4fbd-008a-571b-bb68-cbd0ba351521"
                    ]
                },
                "D-33a": {
                    "questionId": "D-33a",
                    "chosenOptionIds": [
                        "3968dd70-f060-5cbd-bb6a-d63f702a3691"
                    ]
                },
                "D-34a": {
                    "questionId": "D-34a",
                    "chosenOptionIds": [
                        "9d969817-a8d1-57f5-8c15-5e89835ed2a4"
                    ]
                },
                "D-35a": {
                    "questionId": "D-35a",
                    "chosenOptionIds": [
                        "9c9d44bb-b50f-5ddf-9553-03b254272362"
                    ]
                },
                "D-36a": {
                    "questionId": "D-36a",
                    "chosenOptionIds": [
                        "f6958030-4bae-5f83-a569-35cb7675b803"
                    ]
                },
                "D-37a": {
                    "questionId": "D-37a",
                    "chosenOptionIds": [
                        "fd4e0608-283c-5f56-a026-edfa381519c9"
                    ]
                },
                "D-38a": {
                    "questionId": "D-38a",
                    "chosenOptionIds": [
                        "a03f02de-f86d-525a-b375-383ad9d3cefe"
                    ]
                },
                "D-39a": {
                    "questionId": "D-39a",
                    "chosenOptionIds": [
                        "ae31125f-c3db-5082-ae70-4329a2508744"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "f110f5ec-75f1-51be-9c45-ea7685a54d64"
                    ]
                },
                "D-3bl": {
                    "questionId": "D-3bl",
                    "freeValue": 4745
                },
                "D-3bs": {
                    "questionId": "D-3bs",
                    "chosenOptionIds": [
                        "c4422e8a-6a0a-51ea-8592-9157036922d1"
                    ]
                },
                "D-3bt": {
                    "questionId": "D-3bt",
                    "chosenOptionIds": [
                        "b9abc28d-6e22-5b67-bc5e-27431e123974"
                    ]
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-40a": {
                    "questionId": "D-40a",
                    "chosenOptionIds": [
                        "eece7786-6774-532b-ac46-218f4cd6f083"
                    ]
                },
                "D-41a": {
                    "questionId": "D-41a",
                    "chosenOptionIds": [
                        "6a5d1a65-0b53-5485-995a-76d8d7eafb1a"
                    ]
                },
                "D-42a": {
                    "questionId": "D-42a",
                    "chosenOptionIds": [
                        "926931de-e675-5117-9e61-78dd541393be"
                    ]
                },
                "D-42bt": {
                    "questionId": "D-42bt",
                    "freeValue": 1095
                },
                "D-43a": {
                    "questionId": "D-43a",
                    "chosenOptionIds": [
                        "cd0abe72-b3e8-5c78-8330-7ee4a1657536"
                    ]
                },
                "D-43bt": {
                    "questionId": "D-43bt",
                    "chosenOptionIds": [
                        "90e60395-fc3d-56cb-b6f7-2e24fba02803"
                    ]
                },
                "D-44.5a": {
                    "questionId": "D-44.5a",
                    "chosenOptionIds": [
                        "d87971dc-d745-5b64-ac37-37fbe1e722ac",
                        "b5fc3288-710a-5aef-9842-611757b40967",
                        "884767e3-ed0c-5db3-a227-f84f7ecaf8d3",
                        "d3ed5c81-5b77-58c3-983c-d29f9788c396",
                        "bcf5fb9f-f4b9-5f0c-a964-69a4e1a73334"
                    ]
                },
                "D-44a": {
                    "questionId": "D-44a",
                    "chosenOptionIds": [
                        "68a987a9-8bad-50d7-a4d9-e970334f0460"
                    ]
                },
                "D-45a": {
                    "questionId": "D-45a",
                    "freeValue": 1095
                },
                "D-46a": {
                    "questionId": "D-46a",
                    "chosenOptionIds": [
                        "f0b15a45-ae49-59d2-97a7-33f866b0e629",
                        "b3e43bd8-fbe3-5cda-b891-6f11e43993d0",
                        "6e49f368-62af-5abb-9034-a58bd534944f",
                        "4c178978-f79a-58fc-8e73-3a98614c4b6c",
                        "7492eab7-c625-52d0-a2e3-4bf3332decc0",
                        "1fd426df-808a-5c68-9b6e-23ff2f128b7d",
                        "13260a8b-4ec0-5e4d-84fc-1df990527937"
                    ]
                },
                "D-49.1a": {
                    "questionId": "D-49.1a",
                    "chosenOptionIds": [
                        "7108af97-c956-5da8-8dfa-518e346c197c"
                    ]
                },
                "D-49.2a": {
                    "questionId": "D-49.2a",
                    "freeValue": 800
                },
                "D-49a": {
                    "questionId": "D-49a",
                    "chosenOptionIds": [
                        "16dc6af1-32a9-59c5-b66c-57f6becea1e0"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b"
                    ]
                },
                "D-4bs": {
                    "questionId": "D-4bs",
                    "freeValue": 1095
                },
                "D-4bt": {
                    "questionId": "D-4bt",
                    "freeValue": 2920
                },
                "D-50a": {
                    "questionId": "D-50a",
                    "chosenOptionIds": [
                        "d91f7277-0497-57d2-93a3-ea638d14162c"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "dde1af45-4686-52c9-b960-369527b28f6c"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "81e9f63b-35b9-5032-8ea0-462275a87cce"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "5d271ac0-beca-56cc-9d0b-adafcd67da53"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "33a5fff0-9587-521f-bc53-0dacc36a0bb2"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "13f8bac1-d0f5-539b-8596-1c55821bea46"
                    ]
                },
                "D-s10bt": {
                    "questionId": "D-s10bt",
                    "chosenOptionIds": [
                        "da993bd6-c107-53a4-9f6d-09f5a79aff65"
                    ]
                },
                "D-s11bt": {
                    "questionId": "D-s11bt",
                    "chosenOptionIds": [
                        "daf8b3c7-fb19-5a3f-ba9a-784adda7414f",
                        "dc476220-9479-5def-8a97-20e6152d44af",
                        "9c3fa6af-8b7b-5993-a587-641683d934d5",
                        "6994b88e-cfa5-5986-b9e8-625d17d1147c",
                        "16e1a0b8-28c5-5bcd-931d-335c858c3f88",
                        "bae5acc8-8664-54a2-ba97-c36244be4c44",
                        "64af3cae-713a-51e1-99c1-09e2fdaa755d",
                        "7921bccf-4756-59fe-b678-d536c2ab5945",
                        "049f46ae-b7bd-5a1e-8c63-351f481236ec"
                    ]
                },
                "D-s1bt": {
                    "questionId": "D-s1bt",
                    "chosenOptionIds": [
                        "c17482e4-818a-50cd-90fa-1246d037825e"
                    ]
                },
                "D-s2bt": {
                    "questionId": "D-s2bt",
                    "chosenOptionIds": [
                        "b75d06ef-eb6c-5e23-bf8c-9b566fa4d9dd"
                    ]
                },
                "D-s3bt": {
                    "questionId": "D-s3bt",
                    "chosenOptionIds": [
                        "d065c0ca-9e6d-5300-8e3e-688b16f88c59"
                    ]
                },
                "D-s4bt": {
                    "questionId": "D-s4bt",
                    "chosenOptionIds": [
                        "5a198500-fc4b-55a1-9521-3abe49eed06f",
                        "fd9851a0-7ce7-55a8-b257-5617537d0013",
                        "a4021497-af94-56a4-8075-392653262e2e"
                    ]
                },
                "D-s5bt": {
                    "questionId": "D-s5bt",
                    "chosenOptionIds": [
                        "8260d432-3a96-519e-b824-d24940e4aa65"
                    ]
                },
                "D-s6bt": {
                    "questionId": "D-s6bt",
                    "chosenOptionIds": [
                        "33cda0a0-a5db-5a6c-8603-b5b899edac54"
                    ]
                },
                "D-s7bt": {
                    "questionId": "D-s7bt",
                    "chosenOptionIds": [
                        "1df67815-0b9f-56ef-bd48-96ed977db141"
                    ]
                },
                "D-s8bt": {
                    "questionId": "D-s8bt",
                    "chosenOptionIds": [
                        "6f5067cf-ef4d-59d9-afa1-b3dfc4ac861f"
                    ]
                },
                "D-s9bt": {
                    "questionId": "D-s9bt",
                    "chosenOptionIds": [
                        "da503bbd-e567-576e-a21f-e0fad60dccdd"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "44fd9eb3-aee2-5af0-b83c-dbbbf9514022"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "6215a3de-a3e4-5f86-a039-1006646e6b93"
                    ]
                },
                "BP106": {
                    "questionId": "BP106",
                    "chosenOptionIds": [
                        "8c35da7e-8c0b-50bb-a037-b80a9f1df3d2"
                    ]
                },
                "BP108": {
                    "questionId": "BP108",
                    "chosenOptionIds": [
                        "6c88c07e-4483-5b5b-b6ad-44cff962863e"
                    ]
                },
                "BP110": {
                    "questionId": "BP110",
                    "freeValue": 4745
                },
                "BP110.01": {
                    "questionId": "BP110.01",
                    "chosenOptionIds": [
                        "7c60a3ff-2742-5128-b0a0-3157bfe8c0f5"
                    ]
                },
                "BP110.1": {
                    "questionId": "BP110.1",
                    "chosenOptionIds": [
                        "9ce31a68-0e9d-55e2-a573-174bcbeb7458"
                    ]
                },
                "BP110.2": {
                    "questionId": "BP110.2",
                    "chosenOptionIds": [
                        "dbc05d00-3f66-51fd-825b-712e8609aa9f"
                    ]
                },
                "BP111": {
                    "questionId": "BP111",
                    "chosenOptionIds": [
                        "02b20612-0b18-5aba-91d5-3ebd2589fb3d"
                    ]
                },
                "BP111.1": {
                    "questionId": "BP111.1",
                    "chosenOptionIds": [
                        "c532b673-2762-5ed0-8cbb-6f70d9977c20"
                    ]
                },
                "BP111.2": {
                    "questionId": "BP111.2",
                    "chosenOptionIds": [
                        "b218cc67-f452-588d-b809-7cd97ee83e20"
                    ]
                },
                "BP112": {
                    "questionId": "BP112",
                    "chosenOptionIds": [
                        "71867305-9c3a-564c-a0c7-e187b27e8d79"
                    ]
                },
                "BP112.1": {
                    "questionId": "BP112.1",
                    "freeValue": 676
                },
                "BP112.11": {
                    "questionId": "BP112.11",
                    "chosenOptionIds": [
                        "158f7b58-6651-5c6e-8a4b-41995ae3a765"
                    ]
                },
                "BP112.12": {
                    "questionId": "BP112.12",
                    "chosenOptionIds": [
                        "add1ea84-0327-5281-9cf4-5ddbd95b2a8f"
                    ]
                },
                "BP112.13": {
                    "questionId": "BP112.13",
                    "freeValue": 4745
                },
                "BP112.14": {
                    "questionId": "BP112.14",
                    "chosenOptionIds": [
                        "6ead4f84-b429-5a17-85d6-808bd332aa25"
                    ]
                },
                "BP112.3": {
                    "questionId": "BP112.3",
                    "chosenOptionIds": [
                        "5de2cc88-13a4-5f04-a3d2-1333852193f9"
                    ]
                },
                "BP113": {
                    "questionId": "BP113",
                    "chosenOptionIds": [
                        "b16103eb-2ecf-595e-80e4-30baf575e01b"
                    ]
                },
                "BP114": {
                    "questionId": "BP114",
                    "chosenOptionIds": [
                        "a0206e39-59ad-539c-a2ff-e9fd935cf48b"
                    ]
                },
                "BP115": {
                    "questionId": "BP115",
                    "chosenOptionIds": [
                        "e7b2a68b-de30-51bd-bb56-cda1254d3dbc"
                    ]
                },
                "BP116": {
                    "questionId": "BP116",
                    "chosenOptionIds": [
                        "bce3bb8c-216a-5167-8ec7-35c39096d501"
                    ]
                },
                "BP117": {
                    "questionId": "BP117",
                    "chosenOptionIds": [
                        "354c012f-3484-58ad-9300-d9e29a212ae3"
                    ]
                },
                "BP118": {
                    "questionId": "BP118",
                    "chosenOptionIds": [
                        "e642f54c-d6fc-57f8-a53c-73dbd698d428"
                    ]
                },
                "BP118.1": {
                    "questionId": "BP118.1",
                    "chosenOptionIds": [
                        "3299a8a7-4375-55ee-87b2-76bda766fd11"
                    ]
                },
                "BP119": {
                    "questionId": "BP119",
                    "chosenOptionIds": [
                        "64d22ab4-481d-5258-9244-0ec9d3ba77a7"
                    ]
                },
                "BP119.1": {
                    "questionId": "BP119.1",
                    "freeValue": 1095
                },
                "BP120": {
                    "questionId": "BP120",
                    "chosenOptionIds": [
                        "548c329b-3169-5742-b17d-437216e6a454"
                    ]
                },
                "BP121": {
                    "questionId": "BP121",
                    "chosenOptionIds": [
                        "2dd3fa4b-7390-5c95-b04b-c5c77974e8c2"
                    ]
                },
                "BP122": {
                    "questionId": "BP122",
                    "chosenOptionIds": [
                        "3d008b0e-8759-568c-9aae-ba21c8b6ae79"
                    ]
                },
                "BP123": {
                    "questionId": "BP123",
                    "chosenOptionIds": [
                        "972a0931-d7f1-5ad4-90af-91046fd7b193"
                    ]
                },
                "BP124": {
                    "questionId": "BP124",
                    "chosenOptionIds": [
                        "368a583d-81fc-5f77-a51c-647fd7f8f473"
                    ]
                },
                "BP125": {
                    "questionId": "BP125",
                    "chosenOptionIds": [
                        "19dd84c4-ccf5-5060-83ae-e1f2f977f1c0"
                    ]
                },
                "BP126": {
                    "questionId": "BP126",
                    "chosenOptionIds": [
                        "7bdce4bf-24ca-5470-a5cd-68c1e5c7ef03"
                    ]
                },
                "BP127": {
                    "questionId": "BP127",
                    "chosenOptionIds": [
                        "5f58bb60-dff1-585c-a5f3-26e34e4dc957"
                    ]
                },
                "BP128": {
                    "questionId": "BP128",
                    "chosenOptionIds": [
                        "f2c70c18-c55b-5447-9859-67292866bede"
                    ]
                },
                "BP129": {
                    "questionId": "BP129",
                    "chosenOptionIds": [
                        "477c3730-71ba-505f-9caa-07a83398580f"
                    ]
                },
                "BP130": {
                    "questionId": "BP130",
                    "chosenOptionIds": [
                        "7a066bdc-85a0-56cc-8632-2e83740855c1"
                    ]
                },
                "BP131": {
                    "questionId": "BP131",
                    "chosenOptionIds": [
                        "cf80f9fa-de7b-5954-b005-e9ec1b1b07a9"
                    ]
                },
                "BP132": {
                    "questionId": "BP132",
                    "chosenOptionIds": [
                        "da9000ec-69f6-580b-9ec9-756b702c2962"
                    ]
                },
                "BP133": {
                    "questionId": "BP133",
                    "chosenOptionIds": [
                        "36121dba-4b4b-584f-9f5b-4b715cf0b2af"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM35": {
                    "questionId": "HM35",
                    "chosenOptionIds": [
                        "c5f21a2b-5af8-525d-bfab-0ed035defec0"
                    ]
                },
                "HM36": {
                    "questionId": "HM36",
                    "chosenOptionIds": [
                        "47bfc26b-4237-5a82-9cc4-67d348c4acb0"
                    ]
                },
                "HM37": {
                    "questionId": "HM37",
                    "chosenOptionIds": [
                        "20de534d-f482-51c5-ad87-bd054787fddc"
                    ]
                },
                "HM38": {
                    "questionId": "HM38",
                    "chosenOptionIds": [
                        "5125bbbb-70fb-5adc-a41b-c65c6b96180d"
                    ]
                },
                "HM40": {
                    "questionId": "HM40",
                    "chosenOptionIds": [
                        "596144bf-d0ea-5d17-a2ae-7096c31e2def"
                    ]
                },
                "HM41": {
                    "questionId": "HM41",
                    "chosenOptionIds": [
                        "dd9cb999-d487-51f2-8ca3-f121593feca0"
                    ]
                },
                "HM43": {
                    "questionId": "HM43",
                    "chosenOptionIds": [
                        "a8b07611-dcc0-55ef-ae78-d4ceb7ad42e3"
                    ]
                },
                "HM44": {
                    "questionId": "HM44",
                    "chosenOptionIds": [
                        "fe1e5434-ebd9-5a33-92c8-acca24a484b7"
                    ]
                },
                "HM45": {
                    "questionId": "HM45",
                    "chosenOptionIds": [
                        "28a20a2a-3318-5dba-9e59-86d943d3ac73"
                    ]
                },
                "HM46": {
                    "questionId": "HM46",
                    "chosenOptionIds": [
                        "9f868300-e1df-5567-8ebf-e2f984022e52"
                    ]
                },
                "HM47": {
                    "questionId": "HM47",
                    "chosenOptionIds": [
                        "41ec9370-ba26-5cbc-ad35-d9e578788aa4"
                    ]
                },
                "HM48": {
                    "questionId": "HM48",
                    "chosenOptionIds": [
                        "9b74dd11-97db-567e-8d3d-836001d82229"
                    ]
                },
                "HM49": {
                    "questionId": "HM49",
                    "chosenOptionIds": [
                        "7a5feb6e-c27f-54d2-9473-fc197426685c"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        }
    ],
    "simple_mdd": [
        {
            "name": "Mood test: 6PE670L aspect: simple_mdd should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "a16e8e14-524e-53df-b4ef-730a2c8e5f32"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "c0453e8e-7a9c-5bf5-b8a2-167a61a6387e"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "826538fc-8a97-55e4-8013-fe6a93f8e451"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 180
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "913bc1e9-8da1-54c7-b935-13fc5d48fbd9"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "a9525e10-4e34-5d6b-9c93-c7546ec49231"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "de11fb6b-7e7d-52b1-9d09-2d6debc53314"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "77c6088d-2d8f-5c3b-89c9-79cb0d317226"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "1d4beed1-78e5-531c-a255-643fac260dee",
                        "9d1b858c-7064-5a84-80d6-786c18e603b6"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "f3efc764-ca53-52c3-9c3a-38c7f787d415"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "5d271ac0-beca-56cc-9d0b-adafcd67da53"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "d362cd87-7515-551e-bc74-19ef903484aa"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "4896b504-2025-52c3-85e2-c6cf3205cef7"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "ccbdcbec-96d0-5362-977b-784289f5cfc0"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "026a1cea-7cfc-540b-bfe5-42886be90595"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "6a5aa899-7b89-5661-a554-71b6058ad4a4"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "d3224e16-1b0c-5266-9e14-ac72d2b2e988"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "b24df75b-6bb9-5f5f-99e3-c883ec3644ec"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP12": {
                    "questionId": "BP12",
                    "chosenOptionIds": [
                        "1d6299ac-2ac6-51e2-9a3f-13e8d44e652a"
                    ]
                },
                "BP12.5": {
                    "questionId": "BP12.5",
                    "chosenOptionIds": [
                        "cd7ce00f-4e7c-57ac-9c6c-4137efa24c8c"
                    ]
                },
                "BP14": {
                    "questionId": "BP14",
                    "chosenOptionIds": [
                        "84994ca2-fcea-54fe-a7d6-9b44ec07f0bd"
                    ]
                },
                "BP14.1": {
                    "questionId": "BP14.1",
                    "chosenOptionIds": [
                        "f2b7f127-8ab7-5609-8820-43158baee15c"
                    ]
                },
                "BP14.2": {
                    "questionId": "BP14.2",
                    "freeValue": 1
                },
                "BP14.3": {
                    "questionId": "BP14.3",
                    "chosenOptionIds": [
                        "ac6d7839-60fb-5529-97b0-5d0f13ed20de"
                    ]
                },
                "BP16": {
                    "questionId": "BP16",
                    "chosenOptionIds": [
                        "fabceeea-8fea-5444-8b67-8f189f366c27"
                    ]
                },
                "BP16.0": {
                    "questionId": "BP16.0",
                    "chosenOptionIds": [
                        "4b26caa5-9124-51b2-ab08-2f4e977cee1f"
                    ]
                },
                "BP16.01": {
                    "questionId": "BP16.01",
                    "chosenOptionIds": [
                        "f81655d7-78e5-59b1-a7eb-90a2ec7a107d"
                    ]
                },
                "BP16.02": {
                    "questionId": "BP16.02",
                    "chosenOptionIds": [
                        "16a695c9-3ac1-5d34-b799-44602d85b556"
                    ]
                },
                "BP18": {
                    "questionId": "BP18",
                    "chosenOptionIds": [
                        "b577f323-ec54-5d25-a2b9-4f8de30d60a2"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 58
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "f03de28d-8f71-5af4-be2f-45f4e6092a3d"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "387b19c0-67d0-557e-9fba-ae065dcf3587"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "afb57366-c0b3-5f73-92eb-27bc133ad2ed"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "667ba57c-b1cd-5f9b-aa12-58e65a981910"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "5d5fa968-aeb3-569a-8a71-483ef04b9a6a"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "e22cf233-c174-52fb-af55-97bf9c46e971"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "1f57d1a0-3682-5d7d-b66b-8179ec5c3988"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "d680a8d9-e35f-563f-9d1d-e7c3136aff6d"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "c88e3fb5-913a-5c5c-a42b-603f7bb60a40"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "732c51f9-a0b6-5951-bd7d-eaaec24316ba"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "6928c696-e6d7-5a14-a004-15a497ac6562"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "17b4da6f-7135-523c-b90b-2fe3b66785ff"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "3cf77140-2757-58eb-b4ea-f2a90cd766e7"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "5c06d516-e816-58d6-997e-f8fa91131ac7"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "c0cc09c3-1335-5eb4-8801-b3cdaf74f6fa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM19": {
                    "questionId": "HM19",
                    "chosenOptionIds": [
                        "99f474d7-d509-592a-856a-afed343fb1b8"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "98ea07a0-ae95-570f-8d11-a3e7c682e986"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "696fdd9d-5ced-504b-a933-bb3e575b0099"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c41974b6-d394-569c-8f45-49f79fcac5c6"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "43c32319-c75f-5169-b0e5-713820721d4f"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "b2cb304f-f035-53fc-8763-e2f91b031f9b"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "0170b4cf-0541-53cd-a9db-818d5067dc23"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "2be66549-5767-51b4-82ed-deeab0723f8d"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "2a17c2fe-2592-58b1-98e9-3dc4168fe3e3"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "834b04f2-af09-5337-8fd5-be4a70c99e75"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "86277249-cf50-5f91-a27f-f3a69344e796"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "8823c74f-ff7c-52d8-9c73-fd3b4180f48a"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "1d07911e-929d-5719-aeea-a68f593d18e8"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: 6ED9M0M aspect: simple_mdd should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "0ec0e224-a5cf-5ece-b3ab-37061a600ace"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "3bd18f66-6f1e-5ea4-ae24-2990ed197bef"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "9b47900d-e5a7-5e58-830b-d9b3130a7990"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "b74511c7-9f97-5c15-8bf5-6ec4357b4814"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 1095
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "7a5958fc-e0f7-5d43-9cd0-abfe95cb7026"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "c695fd1c-2cb7-5654-b9f7-b32ecb158229"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "db7514e2-d317-55fb-82d3-2e4a49c2494b"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "9d1b858c-7064-5a84-80d6-786c18e603b6",
                        "1d4beed1-78e5-531c-a255-643fac260dee",
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "f3efc764-ca53-52c3-9c3a-38c7f787d415"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "3e093dd3-857e-5004-a4fb-961fbfa59ece"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "adecf2d3-f302-50f8-a4a9-3ffac1789bc6"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "d362cd87-7515-551e-bc74-19ef903484aa"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "13f8bac1-d0f5-539b-8596-1c55821bea46"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "44fd9eb3-aee2-5af0-b83c-dbbbf9514022"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "7599bcaa-89a5-5f71-80ea-b70c360bb44a"
                    ]
                },
                "BP101b": {
                    "questionId": "BP101b",
                    "chosenOptionIds": [
                        "31b2d2c8-8f28-514e-9e58-aa878f6c8761"
                    ]
                },
                "BP102b": {
                    "questionId": "BP102b",
                    "chosenOptionIds": [
                        "00afc1e6-b78a-5223-a6e7-5d3aaab833e0"
                    ]
                },
                "BP103b": {
                    "questionId": "BP103b",
                    "chosenOptionIds": [
                        "867b0ee4-73d9-5aac-b96d-bc2d85d5b183"
                    ]
                },
                "BP104b": {
                    "questionId": "BP104b",
                    "chosenOptionIds": [
                        "75eb3568-195e-5720-827c-1fd0f84c6e75"
                    ]
                },
                "BP105b": {
                    "questionId": "BP105b",
                    "chosenOptionIds": [
                        "2700e917-0fd4-5241-975c-959339855190"
                    ]
                },
                "BP106b": {
                    "questionId": "BP106b",
                    "freeValue": 1460
                },
                "BP87b": {
                    "questionId": "BP87b",
                    "chosenOptionIds": [
                        "fb568891-2ea3-5200-bbdb-ac0695f30b02"
                    ]
                },
                "BP88b": {
                    "questionId": "BP88b",
                    "chosenOptionIds": [
                        "7e4bdef7-d166-5a09-b3a4-639c1700578c"
                    ]
                },
                "BP89b": {
                    "questionId": "BP89b",
                    "chosenOptionIds": [
                        "db509963-d872-5b97-ba9b-a276f58e0189"
                    ]
                },
                "BP90b": {
                    "questionId": "BP90b",
                    "chosenOptionIds": [
                        "940868cd-d067-573d-9a59-29b2ca63222f"
                    ]
                },
                "BP92b": {
                    "questionId": "BP92b",
                    "chosenOptionIds": [
                        "f59918ba-eb3a-5cf1-81aa-8b7f6ac0df5e"
                    ]
                },
                "BP94b": {
                    "questionId": "BP94b",
                    "chosenOptionIds": [
                        "038cb12b-2783-569a-b1d7-e28c65e591a3"
                    ]
                },
                "BP95b": {
                    "questionId": "BP95b",
                    "chosenOptionIds": [
                        "fb0bd4a7-2793-5b81-badc-fe81b4bc82dc"
                    ]
                },
                "BP96b": {
                    "questionId": "BP96b",
                    "chosenOptionIds": [
                        "c1f02353-e805-53f2-ab3b-34499538a407"
                    ]
                },
                "BP97b": {
                    "questionId": "BP97b",
                    "chosenOptionIds": [
                        "8a1fc783-28cb-5bf7-aa78-8f6b7c36ad29"
                    ]
                },
                "BP98b": {
                    "questionId": "BP98b",
                    "chosenOptionIds": [
                        "01641419-fbfc-52a5-ae63-0fa736ff9847"
                    ]
                },
                "BP99.3b": {
                    "questionId": "BP99.3b",
                    "chosenOptionIds": [
                        "c5d5e724-00d6-5a76-bcaf-e4b6545b7fa4"
                    ]
                },
                "BP99b": {
                    "questionId": "BP99b",
                    "chosenOptionIds": [
                        "68860df1-e835-50d6-8eb2-f9d9f2f97efa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "BP_neg1": {
                    "questionId": "BP_neg1",
                    "chosenOptionIds": [
                        "119208b0-ff3b-5c43-80be-4f07e65b6248"
                    ]
                }
            }
        },
        {
            "name": "Mood test: O0K9D01 aspect: simple_mdd should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "0ec0e224-a5cf-5ece-b3ab-37061a600ace"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "a16e8e14-524e-53df-b4ef-730a2c8e5f32"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "eb68a88d-b49b-5cde-a9c6-5e4c78e27d19"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "b74511c7-9f97-5c15-8bf5-6ec4357b4814"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 3650
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "db360e93-3616-5d89-be36-5ccee4aacf1c"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "11f457f5-4c39-5458-ba9a-84478dd379ba"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "db7514e2-d317-55fb-82d3-2e4a49c2494b"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "9d1b858c-7064-5a84-80d6-786c18e603b6",
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b",
                        "1d4beed1-78e5-531c-a255-643fac260dee"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "b52073e3-c813-51f5-88ba-9cd3bddd7023"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "ef6fddab-7a07-5f20-af20-74f2444eee53"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "d362cd87-7515-551e-bc74-19ef903484aa"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "44fd9eb3-aee2-5af0-b83c-dbbbf9514022"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "6215a3de-a3e4-5f86-a039-1006646e6b93"
                    ]
                },
                "BP106": {
                    "questionId": "BP106",
                    "chosenOptionIds": [
                        "3648c32c-0ce7-5c1d-a7e8-d45a899b40f9"
                    ]
                },
                "BP108": {
                    "questionId": "BP108",
                    "chosenOptionIds": [
                        "6c88c07e-4483-5b5b-b6ad-44cff962863e"
                    ]
                },
                "BP110": {
                    "questionId": "BP110",
                    "freeValue": 0.25
                },
                "BP110.01": {
                    "questionId": "BP110.01",
                    "chosenOptionIds": [
                        "a6d87c31-9a11-5151-958a-040439463b3d"
                    ]
                },
                "BP110.1": {
                    "questionId": "BP110.1",
                    "chosenOptionIds": [
                        "9d19a3c0-120d-5dfd-a794-8aa0e8d39e77"
                    ]
                },
                "BP110.2": {
                    "questionId": "BP110.2",
                    "chosenOptionIds": [
                        "faf183ab-b9b4-5f0d-a996-dfa04be4e401"
                    ]
                },
                "BP111": {
                    "questionId": "BP111",
                    "chosenOptionIds": [
                        "059fdaea-ce9e-527c-ae94-e00d2b0dbe01"
                    ]
                },
                "BP111.1": {
                    "questionId": "BP111.1",
                    "chosenOptionIds": [
                        "c532b673-2762-5ed0-8cbb-6f70d9977c20"
                    ]
                },
                "BP111.2": {
                    "questionId": "BP111.2",
                    "chosenOptionIds": [
                        "6d6a3478-7734-5127-b59e-092b42109314"
                    ]
                },
                "BP112": {
                    "questionId": "BP112",
                    "chosenOptionIds": [
                        "6db4ba23-1d8f-58b9-b11e-eaea15417e28"
                    ]
                },
                "BP113": {
                    "questionId": "BP113",
                    "chosenOptionIds": [
                        "b16103eb-2ecf-595e-80e4-30baf575e01b"
                    ]
                },
                "BP114": {
                    "questionId": "BP114",
                    "chosenOptionIds": [
                        "a0206e39-59ad-539c-a2ff-e9fd935cf48b"
                    ]
                },
                "BP115": {
                    "questionId": "BP115",
                    "chosenOptionIds": [
                        "e7b2a68b-de30-51bd-bb56-cda1254d3dbc"
                    ]
                },
                "BP116": {
                    "questionId": "BP116",
                    "chosenOptionIds": [
                        "cd9085de-4e15-5383-b814-a261f79b136f"
                    ]
                },
                "BP117": {
                    "questionId": "BP117",
                    "chosenOptionIds": [
                        "c68c6fcb-e62b-5749-80e4-9c4ad9995b97"
                    ]
                },
                "BP118": {
                    "questionId": "BP118",
                    "chosenOptionIds": [
                        "a611c3a0-727b-5a9b-8d15-d805b2ecf757"
                    ]
                },
                "BP118.1": {
                    "questionId": "BP118.1",
                    "chosenOptionIds": [
                        "3780fe32-30da-5e3b-bad1-6d360381e7e4"
                    ]
                },
                "BP119": {
                    "questionId": "BP119",
                    "chosenOptionIds": [
                        "3f4ab649-e8be-559b-a32f-9ccb317ffb38"
                    ]
                },
                "BP119.1": {
                    "questionId": "BP119.1",
                    "freeValue": 0
                },
                "BP120": {
                    "questionId": "BP120",
                    "chosenOptionIds": [
                        "548c329b-3169-5742-b17d-437216e6a454"
                    ]
                },
                "BP121": {
                    "questionId": "BP121",
                    "chosenOptionIds": [
                        "76891993-4f79-5b7a-ad6f-b7be1c4994fb"
                    ]
                },
                "BP122": {
                    "questionId": "BP122",
                    "chosenOptionIds": [
                        "84ec10d0-2e9c-5671-9d59-dc91b256ca0b"
                    ]
                },
                "BP123": {
                    "questionId": "BP123",
                    "chosenOptionIds": [
                        "972a0931-d7f1-5ad4-90af-91046fd7b193"
                    ]
                },
                "BP124": {
                    "questionId": "BP124",
                    "chosenOptionIds": [
                        "d7e7a81a-6129-54d0-8a45-35c7e9ae3bf3"
                    ]
                },
                "BP125": {
                    "questionId": "BP125",
                    "chosenOptionIds": [
                        "1926be5c-c655-5494-8fb8-9492f4a4324e"
                    ]
                },
                "BP126": {
                    "questionId": "BP126",
                    "chosenOptionIds": [
                        "47856c8e-63c1-5066-bf95-92ff01e0982a"
                    ]
                },
                "BP127": {
                    "questionId": "BP127",
                    "chosenOptionIds": [
                        "5f58bb60-dff1-585c-a5f3-26e34e4dc957"
                    ]
                },
                "BP128": {
                    "questionId": "BP128",
                    "chosenOptionIds": [
                        "6b43ea3f-8741-56e6-808f-129fe78b7440"
                    ]
                },
                "BP129": {
                    "questionId": "BP129",
                    "chosenOptionIds": [
                        "c534368a-9c51-5f0d-8b17-9df288fd59a0"
                    ]
                },
                "BP130": {
                    "questionId": "BP130",
                    "chosenOptionIds": [
                        "b4725e03-4f40-5305-9d3d-1ff9297f8bff"
                    ]
                },
                "BP131": {
                    "questionId": "BP131",
                    "chosenOptionIds": [
                        "e5863f5d-32a6-520a-9b40-8591adf1c1b8"
                    ]
                },
                "BP132": {
                    "questionId": "BP132",
                    "chosenOptionIds": [
                        "da9000ec-69f6-580b-9ec9-756b702c2962"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM35": {
                    "questionId": "HM35",
                    "chosenOptionIds": [
                        "2b40020d-33ba-5560-9075-d482aa324496"
                    ]
                },
                "HM36": {
                    "questionId": "HM36",
                    "chosenOptionIds": [
                        "47bfc26b-4237-5a82-9cc4-67d348c4acb0"
                    ]
                },
                "HM37": {
                    "questionId": "HM37",
                    "chosenOptionIds": [
                        "20de534d-f482-51c5-ad87-bd054787fddc"
                    ]
                },
                "HM38": {
                    "questionId": "HM38",
                    "chosenOptionIds": [
                        "5125bbbb-70fb-5adc-a41b-c65c6b96180d"
                    ]
                },
                "HM40": {
                    "questionId": "HM40",
                    "chosenOptionIds": [
                        "596144bf-d0ea-5d17-a2ae-7096c31e2def"
                    ]
                },
                "HM41": {
                    "questionId": "HM41",
                    "chosenOptionIds": [
                        "dd9cb999-d487-51f2-8ca3-f121593feca0"
                    ]
                },
                "HM43": {
                    "questionId": "HM43",
                    "chosenOptionIds": [
                        "a8b07611-dcc0-55ef-ae78-d4ceb7ad42e3"
                    ]
                },
                "HM44": {
                    "questionId": "HM44",
                    "chosenOptionIds": [
                        "3675f391-ff12-51e5-b53f-b851b05414ee"
                    ]
                },
                "HM45": {
                    "questionId": "HM45",
                    "chosenOptionIds": [
                        "28a20a2a-3318-5dba-9e59-86d943d3ac73"
                    ]
                },
                "HM46": {
                    "questionId": "HM46",
                    "chosenOptionIds": [
                        "a57434cf-7c45-5e3d-9d0d-c8b432490e99"
                    ]
                },
                "HM47": {
                    "questionId": "HM47",
                    "chosenOptionIds": [
                        "41ec9370-ba26-5cbc-ad35-d9e578788aa4"
                    ]
                },
                "HM48": {
                    "questionId": "HM48",
                    "chosenOptionIds": [
                        "c0e7db40-86e9-5cba-98b0-e42a3fc0835b"
                    ]
                },
                "HM49": {
                    "questionId": "HM49",
                    "chosenOptionIds": [
                        "ad65e31f-19f9-587c-bc99-0b3549cc5cb0"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: NELXPEV aspect: simple_mdd should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "0ec0e224-a5cf-5ece-b3ab-37061a600ace"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "45f0ce85-9e19-53da-bc0d-2af8b383318a"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "c0453e8e-7a9c-5bf5-b8a2-167a61a6387e"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "322210fb-135d-5ac8-9617-7fc4f85e016d"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 0.041666666666666664
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "913bc1e9-8da1-54c7-b935-13fc5d48fbd9"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "9222be3e-443f-5146-9d69-d48fe9041ba0"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "77c6088d-2d8f-5c3b-89c9-79cb0d317226"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "a449e4ae-663b-50fb-9b62-1708b0a32669"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "f3efc764-ca53-52c3-9c3a-38c7f787d415"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "ef6fddab-7a07-5f20-af20-74f2444eee53"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "3b216cf8-72e7-558c-bdd3-cc23a1865674"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "4896b504-2025-52c3-85e2-c6cf3205cef7"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "7599bcaa-89a5-5f71-80ea-b70c360bb44a"
                    ]
                },
                "BP101b": {
                    "questionId": "BP101b",
                    "chosenOptionIds": [
                        "cb80bcac-db78-55bd-b051-bdd00801fd54"
                    ]
                },
                "BP102b": {
                    "questionId": "BP102b",
                    "chosenOptionIds": [
                        "71338232-94be-5805-b822-94db8be9c5a1"
                    ]
                },
                "BP103b": {
                    "questionId": "BP103b",
                    "chosenOptionIds": [
                        "867b0ee4-73d9-5aac-b96d-bc2d85d5b183"
                    ]
                },
                "BP104b": {
                    "questionId": "BP104b",
                    "chosenOptionIds": [
                        "c59b5910-3279-509f-8175-f2fd0da2dc86"
                    ]
                },
                "BP87b": {
                    "questionId": "BP87b",
                    "chosenOptionIds": [
                        "7949bd96-3757-5573-bd89-3a91a383cc99"
                    ]
                },
                "BP88b": {
                    "questionId": "BP88b",
                    "chosenOptionIds": [
                        "fa24b924-a8e1-5f02-893b-3d9643007991"
                    ]
                },
                "BP89b": {
                    "questionId": "BP89b",
                    "chosenOptionIds": [
                        "41d2829a-3971-552f-8c5a-c77e7d0073e7"
                    ]
                },
                "BP90b": {
                    "questionId": "BP90b",
                    "chosenOptionIds": [
                        "95c2822d-27ad-5bb0-982f-8c2b949c8918"
                    ]
                },
                "BP92b": {
                    "questionId": "BP92b",
                    "chosenOptionIds": [
                        "b2f4c490-30df-5c8f-87c0-bc4acbcfb8e0"
                    ]
                },
                "BP94b": {
                    "questionId": "BP94b",
                    "chosenOptionIds": [
                        "038cb12b-2783-569a-b1d7-e28c65e591a3"
                    ]
                },
                "BP95b": {
                    "questionId": "BP95b",
                    "chosenOptionIds": [
                        "fb0bd4a7-2793-5b81-badc-fe81b4bc82dc"
                    ]
                },
                "BP96b": {
                    "questionId": "BP96b",
                    "chosenOptionIds": [
                        "8c3d3503-6334-55b9-b0f0-94c8e520094e"
                    ]
                },
                "BP97b": {
                    "questionId": "BP97b",
                    "chosenOptionIds": [
                        "6895096a-ef29-546e-9967-d7a23b4a2859"
                    ]
                },
                "BP98b": {
                    "questionId": "BP98b",
                    "chosenOptionIds": [
                        "01641419-fbfc-52a5-ae63-0fa736ff9847"
                    ]
                },
                "BP99.3b": {
                    "questionId": "BP99.3b",
                    "chosenOptionIds": [
                        "bf30a6c2-f8e2-5305-b4d4-7ad6227449d3"
                    ]
                },
                "BP99b": {
                    "questionId": "BP99b",
                    "chosenOptionIds": [
                        "68860df1-e835-50d6-8eb2-f9d9f2f97efa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "BP_neg1": {
                    "questionId": "BP_neg1",
                    "chosenOptionIds": [
                        "119208b0-ff3b-5c43-80be-4f07e65b6248"
                    ]
                }
            }
        },
        {
            "name": "Mood test: 73ZQN3R aspect: simple_mdd should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "0ec0e224-a5cf-5ece-b3ab-37061a600ace"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "3bd18f66-6f1e-5ea4-ae24-2990ed197bef"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "eb68a88d-b49b-5cde-a9c6-5e4c78e27d19"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "b74511c7-9f97-5c15-8bf5-6ec4357b4814"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 4
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "80c993c8-1a94-5684-a8f9-d8f5072c8e9c"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "cc243947-5067-5a98-ade6-a4e10d0b42cf"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "f110f5ec-75f1-51be-9c45-ea7685a54d64"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b",
                        "ba6dc2ba-3c20-57b6-a276-32c863109ff6",
                        "1d4beed1-78e5-531c-a255-643fac260dee"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "f3efc764-ca53-52c3-9c3a-38c7f787d415"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "08a0f83f-2f37-5904-9364-4e40deb90e98"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "5d271ac0-beca-56cc-9d0b-adafcd67da53"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "33a5fff0-9587-521f-bc53-0dacc36a0bb2"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "13f8bac1-d0f5-539b-8596-1c55821bea46"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "4896b504-2025-52c3-85e2-c6cf3205cef7"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "83e6efd5-64a2-5f0e-a973-9278eae90cfb"
                    ]
                },
                "BP10": {
                    "questionId": "BP10",
                    "chosenOptionIds": [
                        "7ad54f0a-87a6-5e82-bfc3-44b540230082"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "182e121d-415c-5f42-a76c-a996f5f38b3f"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "d8c1997f-d722-536c-ad9d-296b5e10ed04"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "d3224e16-1b0c-5266-9e14-ac72d2b2e988"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "f2228920-3fec-5097-a63d-990c25d50c6c"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP105": {
                    "questionId": "BP105",
                    "chosenOptionIds": [
                        "978f2ff4-94d9-5f7e-9126-e8657be0c7ca"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 25
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "c7bee7a0-3037-5eb1-96a1-85b884d7e1dd"
                    ]
                },
                "BP3.1": {
                    "questionId": "BP3.1",
                    "chosenOptionIds": [
                        "9531c3ff-ab2b-5198-a490-919ede5afb07"
                    ]
                },
                "BP3.2": {
                    "questionId": "BP3.2",
                    "chosenOptionIds": [
                        "ec333f8b-146a-5700-a15d-527032ceac3f"
                    ]
                },
                "BP3.3": {
                    "questionId": "BP3.3",
                    "freeValue": 4
                },
                "BP3.4": {
                    "questionId": "BP3.4",
                    "chosenOptionIds": [
                        "f304bf93-0c68-5da0-80ba-ccecc2d0fce1"
                    ]
                },
                "BP4": {
                    "questionId": "BP4",
                    "chosenOptionIds": [
                        "9765aba8-7d55-5928-b79d-36aa58646036"
                    ]
                },
                "BP4.5": {
                    "questionId": "BP4.5",
                    "chosenOptionIds": [
                        "66a3460c-65ce-5a4c-9774-2e303ae4961e"
                    ]
                },
                "BP6": {
                    "questionId": "BP6",
                    "chosenOptionIds": [
                        "33ae5abe-63ad-57d4-86a4-0dcd33576094"
                    ]
                },
                "BP6.1": {
                    "questionId": "BP6.1",
                    "chosenOptionIds": [
                        "a99773bd-2bff-523e-bc29-1f761307b3d4"
                    ]
                },
                "BP6.2": {
                    "questionId": "BP6.2",
                    "freeValue": 3
                },
                "BP6.3": {
                    "questionId": "BP6.3",
                    "chosenOptionIds": [
                        "a19e0696-e780-5235-88b9-f5c36ce0afc3"
                    ]
                },
                "BP8": {
                    "questionId": "BP8",
                    "chosenOptionIds": [
                        "9b3c44f8-4468-5f37-86e9-ed0908e71d5c"
                    ]
                },
                "BP8.0": {
                    "questionId": "BP8.0",
                    "chosenOptionIds": [
                        "3568cdb1-2500-512b-aada-2547c5bfd835"
                    ]
                },
                "BP8.01": {
                    "questionId": "BP8.01",
                    "chosenOptionIds": [
                        "5020bfdd-a280-56cf-b7f5-3b0b7e667165"
                    ]
                },
                "BP8.02": {
                    "questionId": "BP8.02",
                    "chosenOptionIds": [
                        "7d3a0aa5-605b-58d1-a834-073feefe8e5d"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "387b19c0-67d0-557e-9fba-ae065dcf3587"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "afb57366-c0b3-5f73-92eb-27bc133ad2ed"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "667ba57c-b1cd-5f9b-aa12-58e65a981910"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "a2056280-4b54-5258-8bb4-407b521fff4b"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "c211fabc-a62d-5440-8ddc-f57eb21d7679"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "cc7c09ae-1cb7-5b9a-9cd3-8d1012b90ee8"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "d680a8d9-e35f-563f-9d1d-e7c3136aff6d"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "940c16a8-1835-582a-9dad-bd52e3a49cc7"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "1e86d64a-b972-500a-9120-7884e4b86ede"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "dbab3669-e910-5267-b7b1-fcb661ea7e81"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "2723ed09-37f6-522c-92b1-e518d4fe9315"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "3cf77140-2757-58eb-b4ea-f2a90cd766e7"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "5c06d516-e816-58d6-997e-f8fa91131ac7"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "e81fe613-4923-55ed-bd6c-bd3f19e026f9"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM2": {
                    "questionId": "HM2",
                    "chosenOptionIds": [
                        "de662c3f-66b4-566f-856d-2124e2725542"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "98ea07a0-ae95-570f-8d11-a3e7c682e986"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "696fdd9d-5ced-504b-a933-bb3e575b0099"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c41974b6-d394-569c-8f45-49f79fcac5c6"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "43c32319-c75f-5169-b0e5-713820721d4f"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "b2cb304f-f035-53fc-8763-e2f91b031f9b"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "0170b4cf-0541-53cd-a9db-818d5067dc23"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "7b310da1-94e3-5d45-a6c3-3905eeb28ec6"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "2a17c2fe-2592-58b1-98e9-3dc4168fe3e3"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "834b04f2-af09-5337-8fd5-be4a70c99e75"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "fa32b497-4a4b-5076-a391-b6dffa8e1851"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "8823c74f-ff7c-52d8-9c73-fd3b4180f48a"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "1d07911e-929d-5719-aeea-a68f593d18e8"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: NELRPEV aspect: simple_mdd should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "0ec0e224-a5cf-5ece-b3ab-37061a600ace"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "a16e8e14-524e-53df-b4ef-730a2c8e5f32"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "c0453e8e-7a9c-5bf5-b8a2-167a61a6387e"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "b74511c7-9f97-5c15-8bf5-6ec4357b4814"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 60
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "913bc1e9-8da1-54c7-b935-13fc5d48fbd9"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "11f457f5-4c39-5458-ba9a-84478dd379ba"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "77c6088d-2d8f-5c3b-89c9-79cb0d317226"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b",
                        "1d4beed1-78e5-531c-a255-643fac260dee"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "c9813959-ba61-5220-9e27-659abb256037"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "3e093dd3-857e-5004-a4fb-961fbfa59ece"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "adecf2d3-f302-50f8-a4a9-3ffac1789bc6"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "d362cd87-7515-551e-bc74-19ef903484aa"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "44fd9eb3-aee2-5af0-b83c-dbbbf9514022"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "83e6efd5-64a2-5f0e-a973-9278eae90cfb"
                    ]
                },
                "BP10": {
                    "questionId": "BP10",
                    "chosenOptionIds": [
                        "7ad54f0a-87a6-5e82-bfc3-44b540230082"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "4a0f1c4d-38cf-5de4-99e3-0ee818f9709c"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "e9eb902a-d327-5ec5-938c-dc317ca59b13"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "1daccd4c-e5ff-5f18-ba55-7c2d2d93b849"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "b24df75b-6bb9-5f5f-99e3-c883ec3644ec"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 15
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "c7bee7a0-3037-5eb1-96a1-85b884d7e1dd"
                    ]
                },
                "BP3.1": {
                    "questionId": "BP3.1",
                    "chosenOptionIds": [
                        "5bf1e313-2624-5b5f-9673-37ae9319ff0c"
                    ]
                },
                "BP3.2": {
                    "questionId": "BP3.2",
                    "chosenOptionIds": [
                        "43011291-20a8-59ce-8bac-df882587ff5c"
                    ]
                },
                "BP3.3": {
                    "questionId": "BP3.3",
                    "freeValue": 1
                },
                "BP3.4": {
                    "questionId": "BP3.4",
                    "chosenOptionIds": [
                        "f304bf93-0c68-5da0-80ba-ccecc2d0fce1"
                    ]
                },
                "BP4": {
                    "questionId": "BP4",
                    "chosenOptionIds": [
                        "9765aba8-7d55-5928-b79d-36aa58646036"
                    ]
                },
                "BP4.5": {
                    "questionId": "BP4.5",
                    "chosenOptionIds": [
                        "66a3460c-65ce-5a4c-9774-2e303ae4961e"
                    ]
                },
                "BP6": {
                    "questionId": "BP6",
                    "chosenOptionIds": [
                        "742a22d6-a570-5e3a-8e72-c001f1e9f98b"
                    ]
                },
                "BP6.1": {
                    "questionId": "BP6.1",
                    "chosenOptionIds": [
                        "77b4d620-93e4-5bc5-baad-4995d168f392"
                    ]
                },
                "BP6.2": {
                    "questionId": "BP6.2",
                    "freeValue": 0.08333333333333333
                },
                "BP6.3": {
                    "questionId": "BP6.3",
                    "chosenOptionIds": [
                        "a19e0696-e780-5235-88b9-f5c36ce0afc3"
                    ]
                },
                "BP8": {
                    "questionId": "BP8",
                    "chosenOptionIds": [
                        "9b3c44f8-4468-5f37-86e9-ed0908e71d5c"
                    ]
                },
                "BP8.0": {
                    "questionId": "BP8.0",
                    "chosenOptionIds": [
                        "3568cdb1-2500-512b-aada-2547c5bfd835"
                    ]
                },
                "BP8.01": {
                    "questionId": "BP8.01",
                    "chosenOptionIds": [
                        "5020bfdd-a280-56cf-b7f5-3b0b7e667165"
                    ]
                },
                "BP8.02": {
                    "questionId": "BP8.02",
                    "chosenOptionIds": [
                        "e88670a3-c6d8-5c96-914e-b9fc1818aeda"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "387b19c0-67d0-557e-9fba-ae065dcf3587"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "afb57366-c0b3-5f73-92eb-27bc133ad2ed"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "667ba57c-b1cd-5f9b-aa12-58e65a981910"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "a2056280-4b54-5258-8bb4-407b521fff4b"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "e22cf233-c174-52fb-af55-97bf9c46e971"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "46c90446-3ae9-53f5-9b57-ad19d66b5be6"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "ef5a7b22-b2b2-5a5b-8a80-ce5c251033fd"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "82997889-d795-572b-a597-e484ef078b39"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "201c7ee3-56dc-51e9-ad55-69951192ab16"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "6928c696-e6d7-5a14-a004-15a497ac6562"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "2723ed09-37f6-522c-92b1-e518d4fe9315"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "3cf77140-2757-58eb-b4ea-f2a90cd766e7"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "5c06d516-e816-58d6-997e-f8fa91131ac7"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "c0cc09c3-1335-5eb4-8801-b3cdaf74f6fa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM2": {
                    "questionId": "HM2",
                    "chosenOptionIds": [
                        "de662c3f-66b4-566f-856d-2124e2725542"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "98ea07a0-ae95-570f-8d11-a3e7c682e986"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "a43647cb-a0e8-5705-adb9-a2b68877dc77"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c41974b6-d394-569c-8f45-49f79fcac5c6"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "3fd957d1-0cbb-5397-9812-353be5c2184e"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "b2cb304f-f035-53fc-8763-e2f91b031f9b"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "0170b4cf-0541-53cd-a9db-818d5067dc23"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "6b35cd3b-d67a-54af-94f6-e39ddf5af6c6"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "2a17c2fe-2592-58b1-98e9-3dc4168fe3e3"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "834b04f2-af09-5337-8fd5-be4a70c99e75"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "86277249-cf50-5f91-a27f-f3a69344e796"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "e95276b0-ee81-580b-b532-b0215f04abd5"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "4b8e4843-d260-59e5-8468-1303b1c3a638"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: N0N6P0Q aspect: simple_mdd should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "a16e8e14-524e-53df-b4ef-730a2c8e5f32"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "916d657d-9f2a-5451-8465-18e3bd863347"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "a0e528ca-2fbb-5da1-9398-0758f79755aa"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 540
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "db360e93-3616-5d89-be36-5ccee4aacf1c"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "a9525e10-4e34-5d6b-9c93-c7546ec49231"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "11f457f5-4c39-5458-ba9a-84478dd379ba"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "db7514e2-d317-55fb-82d3-2e4a49c2494b"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b",
                        "1d4beed1-78e5-531c-a255-643fac260dee"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "c9813959-ba61-5220-9e27-659abb256037"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "3e093dd3-857e-5004-a4fb-961fbfa59ece"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "adecf2d3-f302-50f8-a4a9-3ffac1789bc6"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "d362cd87-7515-551e-bc74-19ef903484aa"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "4896b504-2025-52c3-85e2-c6cf3205cef7"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "7599bcaa-89a5-5f71-80ea-b70c360bb44a"
                    ]
                },
                "BP101b": {
                    "questionId": "BP101b",
                    "chosenOptionIds": [
                        "31b2d2c8-8f28-514e-9e58-aa878f6c8761"
                    ]
                },
                "BP102b": {
                    "questionId": "BP102b",
                    "chosenOptionIds": [
                        "71338232-94be-5805-b822-94db8be9c5a1"
                    ]
                },
                "BP103b": {
                    "questionId": "BP103b",
                    "chosenOptionIds": [
                        "867b0ee4-73d9-5aac-b96d-bc2d85d5b183"
                    ]
                },
                "BP104b": {
                    "questionId": "BP104b",
                    "chosenOptionIds": [
                        "c59b5910-3279-509f-8175-f2fd0da2dc86"
                    ]
                },
                "BP87b": {
                    "questionId": "BP87b",
                    "chosenOptionIds": [
                        "659c0d9a-389d-5b62-b3ac-e385dfafe63c"
                    ]
                },
                "BP88b": {
                    "questionId": "BP88b",
                    "chosenOptionIds": [
                        "e29e159f-f691-5aa7-b5a0-d13fe2c54170"
                    ]
                },
                "BP89b": {
                    "questionId": "BP89b",
                    "chosenOptionIds": [
                        "db509963-d872-5b97-ba9b-a276f58e0189"
                    ]
                },
                "BP90b": {
                    "questionId": "BP90b",
                    "chosenOptionIds": [
                        "95c2822d-27ad-5bb0-982f-8c2b949c8918"
                    ]
                },
                "BP92b": {
                    "questionId": "BP92b",
                    "chosenOptionIds": [
                        "f59918ba-eb3a-5cf1-81aa-8b7f6ac0df5e"
                    ]
                },
                "BP94b": {
                    "questionId": "BP94b",
                    "chosenOptionIds": [
                        "038cb12b-2783-569a-b1d7-e28c65e591a3"
                    ]
                },
                "BP95b": {
                    "questionId": "BP95b",
                    "chosenOptionIds": [
                        "fb0bd4a7-2793-5b81-badc-fe81b4bc82dc"
                    ]
                },
                "BP96b": {
                    "questionId": "BP96b",
                    "chosenOptionIds": [
                        "8c3d3503-6334-55b9-b0f0-94c8e520094e"
                    ]
                },
                "BP97b": {
                    "questionId": "BP97b",
                    "chosenOptionIds": [
                        "6895096a-ef29-546e-9967-d7a23b4a2859"
                    ]
                },
                "BP98b": {
                    "questionId": "BP98b",
                    "chosenOptionIds": [
                        "01641419-fbfc-52a5-ae63-0fa736ff9847"
                    ]
                },
                "BP99.1b": {
                    "questionId": "BP99.1b",
                    "chosenOptionIds": [
                        "255f682d-5067-5a6d-835a-fe587b4169e6"
                    ]
                },
                "BP99.3b": {
                    "questionId": "BP99.3b",
                    "chosenOptionIds": [
                        "e4575ba2-20f0-519e-967b-d5769763cadc"
                    ]
                },
                "BP99b": {
                    "questionId": "BP99b",
                    "chosenOptionIds": [
                        "96faf550-f157-55b9-8f15-6be82cd511a8"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "BP_neg1": {
                    "questionId": "BP_neg1",
                    "chosenOptionIds": [
                        "119208b0-ff3b-5c43-80be-4f07e65b6248"
                    ]
                }
            }
        },
        {
            "name": "Mood test: K39V10L aspect: simple_mdd should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "0ec0e224-a5cf-5ece-b3ab-37061a600ace"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "45f0ce85-9e19-53da-bc0d-2af8b383318a"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "c0453e8e-7a9c-5bf5-b8a2-167a61a6387e"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "322210fb-135d-5ac8-9617-7fc4f85e016d"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 1
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "913bc1e9-8da1-54c7-b935-13fc5d48fbd9"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "9222be3e-443f-5146-9d69-d48fe9041ba0"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "77c6088d-2d8f-5c3b-89c9-79cb0d317226"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "1d4beed1-78e5-531c-a255-643fac260dee"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "f3efc764-ca53-52c3-9c3a-38c7f787d415"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "228ef7d9-008b-559d-ba14-6b928b486b38"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "3b216cf8-72e7-558c-bdd3-cc23a1865674"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "f52974b4-d0c2-589c-88d8-8628f43a2f5e",
                        "07106ab9-5949-5b5f-bc96-117139ebd397",
                        "44fd9eb3-aee2-5af0-b83c-dbbbf9514022"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "ccbdcbec-96d0-5362-977b-784289f5cfc0"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "4a0f1c4d-38cf-5de4-99e3-0ee818f9709c"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "e9eb902a-d327-5ec5-938c-dc317ca59b13"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "d3224e16-1b0c-5266-9e14-ac72d2b2e988"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "f2228920-3fec-5097-a63d-990c25d50c6c"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP105": {
                    "questionId": "BP105",
                    "chosenOptionIds": [
                        "64aa1680-6b5b-5a5b-bb92-75995d27ddcd"
                    ]
                },
                "BP12": {
                    "questionId": "BP12",
                    "chosenOptionIds": [
                        "1d6299ac-2ac6-51e2-9a3f-13e8d44e652a"
                    ]
                },
                "BP12.5": {
                    "questionId": "BP12.5",
                    "chosenOptionIds": [
                        "cd7ce00f-4e7c-57ac-9c6c-4137efa24c8c"
                    ]
                },
                "BP14": {
                    "questionId": "BP14",
                    "chosenOptionIds": [
                        "a3126449-b062-5872-80a1-5950acf6bfa4"
                    ]
                },
                "BP14.1": {
                    "questionId": "BP14.1",
                    "chosenOptionIds": [
                        "69f9a784-ce6d-5c82-bd3f-4c9c44bf5992"
                    ]
                },
                "BP14.2": {
                    "questionId": "BP14.2",
                    "freeValue": 21
                },
                "BP14.3": {
                    "questionId": "BP14.3",
                    "chosenOptionIds": [
                        "9f5fc152-75fb-59d8-8f40-7a6ca15c69cb"
                    ]
                },
                "BP16": {
                    "questionId": "BP16",
                    "chosenOptionIds": [
                        "fabceeea-8fea-5444-8b67-8f189f366c27"
                    ]
                },
                "BP16.0": {
                    "questionId": "BP16.0",
                    "chosenOptionIds": [
                        "4b26caa5-9124-51b2-ab08-2f4e977cee1f"
                    ]
                },
                "BP16.01": {
                    "questionId": "BP16.01",
                    "chosenOptionIds": [
                        "f81655d7-78e5-59b1-a7eb-90a2ec7a107d"
                    ]
                },
                "BP16.02": {
                    "questionId": "BP16.02",
                    "chosenOptionIds": [
                        "16a695c9-3ac1-5d34-b799-44602d85b556"
                    ]
                },
                "BP18": {
                    "questionId": "BP18",
                    "chosenOptionIds": [
                        "b577f323-ec54-5d25-a2b9-4f8de30d60a2"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 59
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "f03de28d-8f71-5af4-be2f-45f4e6092a3d"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "387b19c0-67d0-557e-9fba-ae065dcf3587"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "afb57366-c0b3-5f73-92eb-27bc133ad2ed"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "667ba57c-b1cd-5f9b-aa12-58e65a981910"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "5d5fa968-aeb3-569a-8a71-483ef04b9a6a"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "c211fabc-a62d-5440-8ddc-f57eb21d7679"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "46c90446-3ae9-53f5-9b57-ad19d66b5be6"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "d680a8d9-e35f-563f-9d1d-e7c3136aff6d"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "82997889-d795-572b-a597-e484ef078b39"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "ec84966e-bb9f-5ba9-a0cf-d5d3503a74e0"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "6928c696-e6d7-5a14-a004-15a497ac6562"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "4a7683c6-774e-5362-8060-740f61986c9b"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "58777a2e-4b97-5413-b340-efe722e45936"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "5c06d516-e816-58d6-997e-f8fa91131ac7"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "c0cc09c3-1335-5eb4-8801-b3cdaf74f6fa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM19": {
                    "questionId": "HM19",
                    "chosenOptionIds": [
                        "99f474d7-d509-592a-856a-afed343fb1b8"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "c3ee8981-dec3-5b3c-80ca-c1023f983a6d"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "696fdd9d-5ced-504b-a933-bb3e575b0099"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c41974b6-d394-569c-8f45-49f79fcac5c6"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "43c32319-c75f-5169-b0e5-713820721d4f"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "b2cb304f-f035-53fc-8763-e2f91b031f9b"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "0170b4cf-0541-53cd-a9db-818d5067dc23"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "6b35cd3b-d67a-54af-94f6-e39ddf5af6c6"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "2a17c2fe-2592-58b1-98e9-3dc4168fe3e3"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "834b04f2-af09-5337-8fd5-be4a70c99e75"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "86277249-cf50-5f91-a27f-f3a69344e796"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "8823c74f-ff7c-52d8-9c73-fd3b4180f48a"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "1d07911e-929d-5719-aeea-a68f593d18e8"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: N0NN50Q aspect: simple_mdd should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "45f0ce85-9e19-53da-bc0d-2af8b383318a"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "916d657d-9f2a-5451-8465-18e3bd863347"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "b74511c7-9f97-5c15-8bf5-6ec4357b4814"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 0.125
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "7a5958fc-e0f7-5d43-9cd0-abfe95cb7026"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "a9525e10-4e34-5d6b-9c93-c7546ec49231"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "11f457f5-4c39-5458-ba9a-84478dd379ba"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5c30c60e-d53e-5cc3-b4a9-fdad9bec4cb7"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "1d4beed1-78e5-531c-a255-643fac260dee",
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "f3efc764-ca53-52c3-9c3a-38c7f787d415"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "228ef7d9-008b-559d-ba14-6b928b486b38"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "d362cd87-7515-551e-bc74-19ef903484aa"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "4896b504-2025-52c3-85e2-c6cf3205cef7"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "7599bcaa-89a5-5f71-80ea-b70c360bb44a"
                    ]
                },
                "BP101b": {
                    "questionId": "BP101b",
                    "chosenOptionIds": [
                        "31b2d2c8-8f28-514e-9e58-aa878f6c8761"
                    ]
                },
                "BP102b": {
                    "questionId": "BP102b",
                    "chosenOptionIds": [
                        "00afc1e6-b78a-5223-a6e7-5d3aaab833e0"
                    ]
                },
                "BP103b": {
                    "questionId": "BP103b",
                    "chosenOptionIds": [
                        "867b0ee4-73d9-5aac-b96d-bc2d85d5b183"
                    ]
                },
                "BP104b": {
                    "questionId": "BP104b",
                    "chosenOptionIds": [
                        "c59b5910-3279-509f-8175-f2fd0da2dc86"
                    ]
                },
                "BP87b": {
                    "questionId": "BP87b",
                    "chosenOptionIds": [
                        "659c0d9a-389d-5b62-b3ac-e385dfafe63c"
                    ]
                },
                "BP88b": {
                    "questionId": "BP88b",
                    "chosenOptionIds": [
                        "e29e159f-f691-5aa7-b5a0-d13fe2c54170"
                    ]
                },
                "BP89b": {
                    "questionId": "BP89b",
                    "chosenOptionIds": [
                        "df5f6aa3-ab8c-5a4c-b9d9-e9a482aba1d7"
                    ]
                },
                "BP90b": {
                    "questionId": "BP90b",
                    "chosenOptionIds": [
                        "b4a79888-68e8-5b52-9a4e-2149be85f6e7"
                    ]
                },
                "BP92b": {
                    "questionId": "BP92b",
                    "chosenOptionIds": [
                        "dd87a55d-829b-54a2-a63d-5b8b634688ea"
                    ]
                },
                "BP94b": {
                    "questionId": "BP94b",
                    "chosenOptionIds": [
                        "038cb12b-2783-569a-b1d7-e28c65e591a3"
                    ]
                },
                "BP95b": {
                    "questionId": "BP95b",
                    "chosenOptionIds": [
                        "fb0bd4a7-2793-5b81-badc-fe81b4bc82dc"
                    ]
                },
                "BP96b": {
                    "questionId": "BP96b",
                    "chosenOptionIds": [
                        "477d4084-be50-50ae-9e88-dc9e03cdad29"
                    ]
                },
                "BP97b": {
                    "questionId": "BP97b",
                    "chosenOptionIds": [
                        "6895096a-ef29-546e-9967-d7a23b4a2859"
                    ]
                },
                "BP98b": {
                    "questionId": "BP98b",
                    "chosenOptionIds": [
                        "01641419-fbfc-52a5-ae63-0fa736ff9847"
                    ]
                },
                "BP99.3b": {
                    "questionId": "BP99.3b",
                    "chosenOptionIds": [
                        "d0adef9f-05e3-5ddf-a0f3-60eeb50d3862"
                    ]
                },
                "BP99b": {
                    "questionId": "BP99b",
                    "chosenOptionIds": [
                        "68860df1-e835-50d6-8eb2-f9d9f2f97efa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "BP_neg1": {
                    "questionId": "BP_neg1",
                    "chosenOptionIds": [
                        "119208b0-ff3b-5c43-80be-4f07e65b6248"
                    ]
                }
            }
        },
        {
            "name": "Mood test: 4EGQQ0P aspect: simple_mdd should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "0ec0e224-a5cf-5ece-b3ab-37061a600ace"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "a16e8e14-524e-53df-b4ef-730a2c8e5f32"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "916d657d-9f2a-5451-8465-18e3bd863347"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "b74511c7-9f97-5c15-8bf5-6ec4357b4814"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 365
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "913bc1e9-8da1-54c7-b935-13fc5d48fbd9"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "11f457f5-4c39-5458-ba9a-84478dd379ba"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5c30c60e-d53e-5cc3-b4a9-fdad9bec4cb7"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "1d4beed1-78e5-531c-a255-643fac260dee",
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b",
                        "9d1b858c-7064-5a84-80d6-786c18e603b6"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "f3efc764-ca53-52c3-9c3a-38c7f787d415"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "9506449e-b2aa-5498-b896-901dd248b1b3"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "228ef7d9-008b-559d-ba14-6b928b486b38"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "d362cd87-7515-551e-bc74-19ef903484aa"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "07106ab9-5949-5b5f-bc96-117139ebd397"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "83e6efd5-64a2-5f0e-a973-9278eae90cfb"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "4a0f1c4d-38cf-5de4-99e3-0ee818f9709c"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "6a5aa899-7b89-5661-a554-71b6058ad4a4"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "d3224e16-1b0c-5266-9e14-ac72d2b2e988"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "b24df75b-6bb9-5f5f-99e3-c883ec3644ec"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 19
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "c7bee7a0-3037-5eb1-96a1-85b884d7e1dd"
                    ]
                },
                "BP3.1": {
                    "questionId": "BP3.1",
                    "chosenOptionIds": [
                        "5bf1e313-2624-5b5f-9673-37ae9319ff0c"
                    ]
                },
                "BP3.2": {
                    "questionId": "BP3.2",
                    "chosenOptionIds": [
                        "f6654515-5729-5299-a4f0-da99056e7ac9"
                    ]
                },
                "BP3.3": {
                    "questionId": "BP3.3",
                    "freeValue": 30
                },
                "BP3.4": {
                    "questionId": "BP3.4",
                    "chosenOptionIds": [
                        "f304bf93-0c68-5da0-80ba-ccecc2d0fce1"
                    ]
                },
                "BP4": {
                    "questionId": "BP4",
                    "chosenOptionIds": [
                        "4884cc9c-7210-5173-9438-d1eae9e9a6a8"
                    ]
                },
                "BP4.5": {
                    "questionId": "BP4.5",
                    "chosenOptionIds": [
                        "1be4b3b1-3a88-5544-af54-ebdfee1ce891"
                    ]
                },
                "BP6.5": {
                    "questionId": "BP6.5",
                    "chosenOptionIds": [
                        "58656f9f-9267-5c1d-9132-f342d05dc39e"
                    ]
                },
                "BP6.7": {
                    "questionId": "BP6.7",
                    "chosenOptionIds": [
                        "ef07ce6c-9277-581a-bde7-14889359301b"
                    ]
                },
                "BP6.8": {
                    "questionId": "BP6.8",
                    "freeValue": 30
                },
                "BP6.9": {
                    "questionId": "BP6.9",
                    "chosenOptionIds": [
                        "155da231-22f3-50d9-8786-2860edd50aab"
                    ]
                },
                "BP8": {
                    "questionId": "BP8",
                    "chosenOptionIds": [
                        "87e0081b-9455-5ced-9225-10c2f70b1734"
                    ]
                },
                "BP8.0": {
                    "questionId": "BP8.0",
                    "chosenOptionIds": [
                        "3568cdb1-2500-512b-aada-2547c5bfd835"
                    ]
                },
                "BP8.01": {
                    "questionId": "BP8.01",
                    "chosenOptionIds": [
                        "5020bfdd-a280-56cf-b7f5-3b0b7e667165"
                    ]
                },
                "BP8.02": {
                    "questionId": "BP8.02",
                    "chosenOptionIds": [
                        "e88670a3-c6d8-5c96-914e-b9fc1818aeda"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "ad1cfcc2-c1dc-57ef-b964-17d355fa0402"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "a4ed572c-6aae-57f6-bc35-ea46929c1437"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "28c75e9e-94ef-5c50-8645-f4ff93ccf2ec"
                    ]
                },
                "BP9": {
                    "questionId": "BP9",
                    "chosenOptionIds": [
                        "becfe35c-2096-5848-87ae-3f763cf59dd6"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "5d5fa968-aeb3-569a-8a71-483ef04b9a6a"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "e22cf233-c174-52fb-af55-97bf9c46e971"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "46c90446-3ae9-53f5-9b57-ad19d66b5be6"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "ef5a7b22-b2b2-5a5b-8a80-ce5c251033fd"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "c88e3fb5-913a-5c5c-a42b-603f7bb60a40"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "732c51f9-a0b6-5951-bd7d-eaaec24316ba"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "6928c696-e6d7-5a14-a004-15a497ac6562"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "2723ed09-37f6-522c-92b1-e518d4fe9315"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "3cf77140-2757-58eb-b4ea-f2a90cd766e7"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "63509127-aadf-5fe2-8e1f-19d0d846d6ce"
                    ]
                },
                "BP99.1": {
                    "questionId": "BP99.1",
                    "chosenOptionIds": [
                        "34d19f12-207f-56ce-bde8-8d616f6e8b26"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "e81fe613-4923-55ed-bd6c-bd3f19e026f9"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM1": {
                    "questionId": "HM1",
                    "chosenOptionIds": [
                        "60caa355-cd97-591a-9344-1e9858939855"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "98ea07a0-ae95-570f-8d11-a3e7c682e986"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "a43647cb-a0e8-5705-adb9-a2b68877dc77"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c41974b6-d394-569c-8f45-49f79fcac5c6"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "43c32319-c75f-5169-b0e5-713820721d4f"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "b2cb304f-f035-53fc-8763-e2f91b031f9b"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "0170b4cf-0541-53cd-a9db-818d5067dc23"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "2be66549-5767-51b4-82ed-deeab0723f8d"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "2a17c2fe-2592-58b1-98e9-3dc4168fe3e3"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "834b04f2-af09-5337-8fd5-be4a70c99e75"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "fda00d52-c8be-5b69-bdbd-905c13dddd4e"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "8823c74f-ff7c-52d8-9c73-fd3b4180f48a"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "1d07911e-929d-5719-aeea-a68f593d18e8"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: 73ZJN0R aspect: simple_mdd should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "45f0ce85-9e19-53da-bc0d-2af8b383318a"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "916d657d-9f2a-5451-8465-18e3bd863347"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "b74511c7-9f97-5c15-8bf5-6ec4357b4814"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 0.08333333333333333
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "913bc1e9-8da1-54c7-b935-13fc5d48fbd9"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "a9525e10-4e34-5d6b-9c93-c7546ec49231"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "11f457f5-4c39-5458-ba9a-84478dd379ba"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5c30c60e-d53e-5cc3-b4a9-fdad9bec4cb7"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "9d1b858c-7064-5a84-80d6-786c18e603b6"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "f3efc764-ca53-52c3-9c3a-38c7f787d415"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "228ef7d9-008b-559d-ba14-6b928b486b38"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "3b216cf8-72e7-558c-bdd3-cc23a1865674"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "4896b504-2025-52c3-85e2-c6cf3205cef7"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "ccbdcbec-96d0-5362-977b-784289f5cfc0"
                    ]
                },
                "BP10": {
                    "questionId": "BP10",
                    "chosenOptionIds": [
                        "7ad54f0a-87a6-5e82-bfc3-44b540230082"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "4a0f1c4d-38cf-5de4-99e3-0ee818f9709c"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "e9eb902a-d327-5ec5-938c-dc317ca59b13"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "d3224e16-1b0c-5266-9e14-ac72d2b2e988"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "b24df75b-6bb9-5f5f-99e3-c883ec3644ec"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 39
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "c7bee7a0-3037-5eb1-96a1-85b884d7e1dd"
                    ]
                },
                "BP3.1": {
                    "questionId": "BP3.1",
                    "chosenOptionIds": [
                        "5bf1e313-2624-5b5f-9673-37ae9319ff0c"
                    ]
                },
                "BP3.2": {
                    "questionId": "BP3.2",
                    "chosenOptionIds": [
                        "ec333f8b-146a-5700-a15d-527032ceac3f"
                    ]
                },
                "BP3.3": {
                    "questionId": "BP3.3",
                    "freeValue": 0.08333333333333333
                },
                "BP3.4": {
                    "questionId": "BP3.4",
                    "chosenOptionIds": [
                        "f304bf93-0c68-5da0-80ba-ccecc2d0fce1"
                    ]
                },
                "BP4": {
                    "questionId": "BP4",
                    "chosenOptionIds": [
                        "9765aba8-7d55-5928-b79d-36aa58646036"
                    ]
                },
                "BP4.5": {
                    "questionId": "BP4.5",
                    "chosenOptionIds": [
                        "66a3460c-65ce-5a4c-9774-2e303ae4961e"
                    ]
                },
                "BP6": {
                    "questionId": "BP6",
                    "chosenOptionIds": [
                        "742a22d6-a570-5e3a-8e72-c001f1e9f98b"
                    ]
                },
                "BP6.1": {
                    "questionId": "BP6.1",
                    "chosenOptionIds": [
                        "430b68c6-29cf-5a15-ab4c-89bd842383bf"
                    ]
                },
                "BP6.2": {
                    "questionId": "BP6.2",
                    "freeValue": 0.08333333333333333
                },
                "BP6.3": {
                    "questionId": "BP6.3",
                    "chosenOptionIds": [
                        "a19e0696-e780-5235-88b9-f5c36ce0afc3"
                    ]
                },
                "BP8": {
                    "questionId": "BP8",
                    "chosenOptionIds": [
                        "87e0081b-9455-5ced-9225-10c2f70b1734"
                    ]
                },
                "BP8.0": {
                    "questionId": "BP8.0",
                    "chosenOptionIds": [
                        "3568cdb1-2500-512b-aada-2547c5bfd835"
                    ]
                },
                "BP8.01": {
                    "questionId": "BP8.01",
                    "chosenOptionIds": [
                        "5020bfdd-a280-56cf-b7f5-3b0b7e667165"
                    ]
                },
                "BP8.02": {
                    "questionId": "BP8.02",
                    "chosenOptionIds": [
                        "e88670a3-c6d8-5c96-914e-b9fc1818aeda"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "387b19c0-67d0-557e-9fba-ae065dcf3587"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "afb57366-c0b3-5f73-92eb-27bc133ad2ed"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "667ba57c-b1cd-5f9b-aa12-58e65a981910"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "fd30dc27-8e89-5d1a-9d95-8844603d4379"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "e22cf233-c174-52fb-af55-97bf9c46e971"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "46c90446-3ae9-53f5-9b57-ad19d66b5be6"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "d680a8d9-e35f-563f-9d1d-e7c3136aff6d"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "94795194-4a80-590f-be62-ee78f2fa0ff5"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "732c51f9-a0b6-5951-bd7d-eaaec24316ba"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "6928c696-e6d7-5a14-a004-15a497ac6562"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "17b4da6f-7135-523c-b90b-2fe3b66785ff"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "3cf77140-2757-58eb-b4ea-f2a90cd766e7"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "5c06d516-e816-58d6-997e-f8fa91131ac7"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "c0cc09c3-1335-5eb4-8801-b3cdaf74f6fa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM2": {
                    "questionId": "HM2",
                    "chosenOptionIds": [
                        "de662c3f-66b4-566f-856d-2124e2725542"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "98ea07a0-ae95-570f-8d11-a3e7c682e986"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "696fdd9d-5ced-504b-a933-bb3e575b0099"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c41974b6-d394-569c-8f45-49f79fcac5c6"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "43c32319-c75f-5169-b0e5-713820721d4f"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "b2cb304f-f035-53fc-8763-e2f91b031f9b"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "0170b4cf-0541-53cd-a9db-818d5067dc23"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "2be66549-5767-51b4-82ed-deeab0723f8d"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "2a17c2fe-2592-58b1-98e9-3dc4168fe3e3"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "7e32475a-3fcc-51ac-b414-ab46578efdfd"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "86277249-cf50-5f91-a27f-f3a69344e796"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "8823c74f-ff7c-52d8-9c73-fd3b4180f48a"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "1d07911e-929d-5719-aeea-a68f593d18e8"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: 6EDLX3M aspect: simple_mdd should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "0ec0e224-a5cf-5ece-b3ab-37061a600ace"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "a16e8e14-524e-53df-b4ef-730a2c8e5f32"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "eb68a88d-b49b-5cde-a9c6-5e4c78e27d19"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "a0e528ca-2fbb-5da1-9398-0758f79755aa"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 14
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "913bc1e9-8da1-54c7-b935-13fc5d48fbd9"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "11f457f5-4c39-5458-ba9a-84478dd379ba"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "db7514e2-d317-55fb-82d3-2e4a49c2494b"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b",
                        "1d4beed1-78e5-531c-a255-643fac260dee",
                        "9d1b858c-7064-5a84-80d6-786c18e603b6"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "dde1af45-4686-52c9-b960-369527b28f6c"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "adecf2d3-f302-50f8-a4a9-3ffac1789bc6"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "d362cd87-7515-551e-bc74-19ef903484aa"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "4896b504-2025-52c3-85e2-c6cf3205cef7"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "83e6efd5-64a2-5f0e-a973-9278eae90cfb"
                    ]
                },
                "BP10": {
                    "questionId": "BP10",
                    "chosenOptionIds": [
                        "7ad54f0a-87a6-5e82-bfc3-44b540230082"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "db7aa4b7-99a5-5475-a4e8-a744f3ef5a4a"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "e9eb902a-d327-5ec5-938c-dc317ca59b13"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "1daccd4c-e5ff-5f18-ba55-7c2d2d93b849"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "b24df75b-6bb9-5f5f-99e3-c883ec3644ec"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 21
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "c7bee7a0-3037-5eb1-96a1-85b884d7e1dd"
                    ]
                },
                "BP3.1": {
                    "questionId": "BP3.1",
                    "chosenOptionIds": [
                        "c053dd45-79bd-59bf-82df-7e9ecd85dc11"
                    ]
                },
                "BP3.2": {
                    "questionId": "BP3.2",
                    "chosenOptionIds": [
                        "c3319113-403a-5ecb-aa8a-071221d92c50"
                    ]
                },
                "BP3.3": {
                    "questionId": "BP3.3",
                    "freeValue": 180
                },
                "BP3.4": {
                    "questionId": "BP3.4",
                    "chosenOptionIds": [
                        "f304bf93-0c68-5da0-80ba-ccecc2d0fce1"
                    ]
                },
                "BP4": {
                    "questionId": "BP4",
                    "chosenOptionIds": [
                        "9765aba8-7d55-5928-b79d-36aa58646036"
                    ]
                },
                "BP4.5": {
                    "questionId": "BP4.5",
                    "chosenOptionIds": [
                        "1be4b3b1-3a88-5544-af54-ebdfee1ce891"
                    ]
                },
                "BP6": {
                    "questionId": "BP6",
                    "chosenOptionIds": [
                        "742a22d6-a570-5e3a-8e72-c001f1e9f98b"
                    ]
                },
                "BP6.1": {
                    "questionId": "BP6.1",
                    "chosenOptionIds": [
                        "77b4d620-93e4-5bc5-baad-4995d168f392"
                    ]
                },
                "BP6.2": {
                    "questionId": "BP6.2",
                    "freeValue": 1
                },
                "BP6.3": {
                    "questionId": "BP6.3",
                    "chosenOptionIds": [
                        "df17fdbe-e756-5ece-ad3d-19cafeef31d1"
                    ]
                },
                "BP8": {
                    "questionId": "BP8",
                    "chosenOptionIds": [
                        "87e0081b-9455-5ced-9225-10c2f70b1734"
                    ]
                },
                "BP8.0": {
                    "questionId": "BP8.0",
                    "chosenOptionIds": [
                        "3568cdb1-2500-512b-aada-2547c5bfd835"
                    ]
                },
                "BP8.01": {
                    "questionId": "BP8.01",
                    "chosenOptionIds": [
                        "e12f28e7-b4ae-57af-96d7-8d83e672785a"
                    ]
                },
                "BP8.02": {
                    "questionId": "BP8.02",
                    "chosenOptionIds": [
                        "e88670a3-c6d8-5c96-914e-b9fc1818aeda"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "e620af65-9b5b-57e6-b13a-65616e67e084"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "a4ed572c-6aae-57f6-bc35-ea46929c1437"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "28c75e9e-94ef-5c50-8645-f4ff93ccf2ec"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "a2056280-4b54-5258-8bb4-407b521fff4b"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "d9fb150b-68b8-50ed-bb1d-eafd41f63f3b"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "46c90446-3ae9-53f5-9b57-ad19d66b5be6"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "63f0b17a-9832-5f0f-8630-e74f1a4fdfe7"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "f35fe383-d2aa-51c4-9fde-961b1de6d52b"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "2555d898-6736-5804-951e-e3c438313029"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "dbab3669-e910-5267-b7b1-fcb661ea7e81"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "4a7683c6-774e-5362-8060-740f61986c9b"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "b14d53e4-f898-52ac-87d7-3aa62abc9d7b"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "63509127-aadf-5fe2-8e1f-19d0d846d6ce"
                    ]
                },
                "BP99.1": {
                    "questionId": "BP99.1",
                    "chosenOptionIds": [
                        "74809c02-f47f-5955-a416-b40fc66ff214"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "a020ed85-ec9d-5778-8006-41f634cab09d"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM2": {
                    "questionId": "HM2",
                    "chosenOptionIds": [
                        "2b56aa7d-493f-5a18-aa9f-f97304f89130"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "6b99de8b-634a-5eb0-bc65-bdf30eb9d433"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "8f7acbc1-9ebe-5a52-95ce-b50d62c27345"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c41974b6-d394-569c-8f45-49f79fcac5c6"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "c8c8c192-aea3-5585-b11f-9d13faf4367d"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "65bee7d4-bf7f-5a21-9d92-f16651b0d1aa"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "366c6a1b-c8b2-5faf-9d2f-72dbde24e869"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "7b310da1-94e3-5d45-a6c3-3905eeb28ec6"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "73b26794-a969-527b-8268-f17c4e485bda"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "7e32475a-3fcc-51ac-b414-ab46578efdfd"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "fda00d52-c8be-5b69-bdbd-905c13dddd4e"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "e95276b0-ee81-580b-b532-b0215f04abd5"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "7d6921a2-7e63-5f12-9c75-dff8b592fabc"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: 73ZL2ER aspect: simple_mdd should be subclinical",
            "expected": {
                "type": "Severity",
                "value": "subclinical"
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "0ec0e224-a5cf-5ece-b3ab-37061a600ace"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "3bd18f66-6f1e-5ea4-ae24-2990ed197bef"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "9b47900d-e5a7-5e58-830b-d9b3130a7990"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "a0e528ca-2fbb-5da1-9398-0758f79755aa"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 360
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "913bc1e9-8da1-54c7-b935-13fc5d48fbd9"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "de11fb6b-7e7d-52b1-9d09-2d6debc53314"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "db7514e2-d317-55fb-82d3-2e4a49c2494b"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b",
                        "1d4beed1-78e5-531c-a255-643fac260dee"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "c9813959-ba61-5220-9e27-659abb256037"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "228ef7d9-008b-559d-ba14-6b928b486b38"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "d362cd87-7515-551e-bc74-19ef903484aa"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "13f8bac1-d0f5-539b-8596-1c55821bea46"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "44fd9eb3-aee2-5af0-b83c-dbbbf9514022"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "83e6efd5-64a2-5f0e-a973-9278eae90cfb"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "db7aa4b7-99a5-5475-a4e8-a744f3ef5a4a"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "d8c1997f-d722-536c-ad9d-296b5e10ed04"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "1daccd4c-e5ff-5f18-ba55-7c2d2d93b849"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "f2228920-3fec-5097-a63d-990c25d50c6c"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP105": {
                    "questionId": "BP105",
                    "chosenOptionIds": [
                        "978f2ff4-94d9-5f7e-9126-e8657be0c7ca"
                    ]
                },
                "BP11": {
                    "questionId": "BP11",
                    "chosenOptionIds": [
                        "e54bdb41-2f8b-5524-b99b-415c31ee1576"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 15
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "c7bee7a0-3037-5eb1-96a1-85b884d7e1dd"
                    ]
                },
                "BP3.1": {
                    "questionId": "BP3.1",
                    "chosenOptionIds": [
                        "b56edfbb-cf11-50ee-9149-9e36f4cc1ea0"
                    ]
                },
                "BP3.2": {
                    "questionId": "BP3.2",
                    "chosenOptionIds": [
                        "ec333f8b-146a-5700-a15d-527032ceac3f"
                    ]
                },
                "BP3.3": {
                    "questionId": "BP3.3",
                    "freeValue": 3
                },
                "BP3.4": {
                    "questionId": "BP3.4",
                    "chosenOptionIds": [
                        "93412936-97c0-5128-ad67-3fa955db1300"
                    ]
                },
                "BP4": {
                    "questionId": "BP4",
                    "chosenOptionIds": [
                        "f4705d21-04ac-574a-90f3-5e73c089a3ff"
                    ]
                },
                "BP7": {
                    "questionId": "BP7",
                    "chosenOptionIds": [
                        "0c0ab687-08ee-5bfd-8795-5e0284c1e430"
                    ]
                },
                "BP7.1": {
                    "questionId": "BP7.1",
                    "chosenOptionIds": [
                        "6b62060b-0aee-5ec9-beef-85c908359e3d"
                    ]
                },
                "BP7.2": {
                    "questionId": "BP7.2",
                    "freeValue": 5
                },
                "BP7.3": {
                    "questionId": "BP7.3",
                    "chosenOptionIds": [
                        "455f915a-3cf9-5453-8bdb-a0fc0d91ca60"
                    ]
                },
                "BP8": {
                    "questionId": "BP8",
                    "chosenOptionIds": [
                        "87e0081b-9455-5ced-9225-10c2f70b1734"
                    ]
                },
                "BP8.0": {
                    "questionId": "BP8.0",
                    "chosenOptionIds": [
                        "3568cdb1-2500-512b-aada-2547c5bfd835"
                    ]
                },
                "BP8.01": {
                    "questionId": "BP8.01",
                    "chosenOptionIds": [
                        "a5d71cc4-3c6f-5e37-a507-1dfa312124b8"
                    ]
                },
                "BP8.02": {
                    "questionId": "BP8.02",
                    "chosenOptionIds": [
                        "1ff60197-2010-588f-bf6a-9be48f2b3359"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "e620af65-9b5b-57e6-b13a-65616e67e084"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "a4ed572c-6aae-57f6-bc35-ea46929c1437"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "2404146e-cd56-5356-a23b-6427b4bbba06"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "a2056280-4b54-5258-8bb4-407b521fff4b"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "33e48f82-ae80-5f20-a3a5-79692d21ad1a"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "0feda24f-7b01-557b-8ca5-10eb924cbdac"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "17619692-6d18-5558-99da-766a1c275e03"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "f35fe383-d2aa-51c4-9fde-961b1de6d52b"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "2555d898-6736-5804-951e-e3c438313029"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "9b27e3ff-f8ce-54c5-9220-0479ae53e889"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "4a7683c6-774e-5362-8060-740f61986c9b"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "58777a2e-4b97-5413-b340-efe722e45936"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "63509127-aadf-5fe2-8e1f-19d0d846d6ce"
                    ]
                },
                "BP99.1": {
                    "questionId": "BP99.1",
                    "chosenOptionIds": [
                        "34d19f12-207f-56ce-bde8-8d616f6e8b26"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "e81fe613-4923-55ed-bd6c-bd3f19e026f9"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "0f8903ea-258c-54c8-9d4d-643924d3c234"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "9d212885-9b07-5623-8234-226adff08f6d"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "3ab9515b-73f0-5fcf-a4d0-aebbc24c2721"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "0e9545ba-3f35-52b0-9a31-7fe54c933928"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "eb41dc92-16ec-5abf-a841-e1d0b262083e"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "57de31c8-9bf0-5003-9dbb-0e8559f994dd"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "7b310da1-94e3-5d45-a6c3-3905eeb28ec6"
                    ]
                },
                "HM3": {
                    "questionId": "HM3",
                    "chosenOptionIds": [
                        "7823432e-ad44-5fbe-9781-48d9ce136451"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "0983ccf5-a426-53d0-adbb-1baf6832190c"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "834b04f2-af09-5337-8fd5-be4a70c99e75"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "fda00d52-c8be-5b69-bdbd-905c13dddd4e"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "bde4e5f3-19e4-571f-84a2-5b9d188d74ef"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "4b8e4843-d260-59e5-8468-1303b1c3a638"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: XE7ZR3L aspect: simple_mdd should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "0ec0e224-a5cf-5ece-b3ab-37061a600ace"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "45f0ce85-9e19-53da-bc0d-2af8b383318a"
                    ]
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "7a5958fc-e0f7-5d43-9cd0-abfe95cb7026"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "9222be3e-443f-5146-9d69-d48fe9041ba0"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5c30c60e-d53e-5cc3-b4a9-fdad9bec4cb7"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b",
                        "ba6dc2ba-3c20-57b6-a276-32c863109ff6",
                        "9d1b858c-7064-5a84-80d6-786c18e603b6"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "c9813959-ba61-5220-9e27-659abb256037"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "ef6fddab-7a07-5f20-af20-74f2444eee53"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "3b216cf8-72e7-558c-bdd3-cc23a1865674"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "4896b504-2025-52c3-85e2-c6cf3205cef7"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "83e6efd5-64a2-5f0e-a973-9278eae90cfb"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "db7aa4b7-99a5-5475-a4e8-a744f3ef5a4a"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "e9eb902a-d327-5ec5-938c-dc317ca59b13"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "7230003d-a290-5a65-ae6b-b7d564b5db38"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "b24df75b-6bb9-5f5f-99e3-c883ec3644ec"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 17
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "c7bee7a0-3037-5eb1-96a1-85b884d7e1dd"
                    ]
                },
                "BP3.1": {
                    "questionId": "BP3.1",
                    "chosenOptionIds": [
                        "c31cf816-04e0-52f4-8da3-04437042c014"
                    ]
                },
                "BP3.2": {
                    "questionId": "BP3.2",
                    "chosenOptionIds": [
                        "c3319113-403a-5ecb-aa8a-071221d92c50"
                    ]
                },
                "BP3.3": {
                    "questionId": "BP3.3",
                    "freeValue": 7
                },
                "BP3.4": {
                    "questionId": "BP3.4",
                    "chosenOptionIds": [
                        "6ec82bbf-7582-5509-ace2-5ecf0ce82ec3"
                    ]
                },
                "BP4": {
                    "questionId": "BP4",
                    "chosenOptionIds": [
                        "4884cc9c-7210-5173-9438-d1eae9e9a6a8"
                    ]
                },
                "BP4.5": {
                    "questionId": "BP4.5",
                    "chosenOptionIds": [
                        "1be4b3b1-3a88-5544-af54-ebdfee1ce891"
                    ]
                },
                "BP6.5": {
                    "questionId": "BP6.5",
                    "chosenOptionIds": [
                        "e96c7a38-d74e-5087-b14b-21d3177ce73e"
                    ]
                },
                "BP6.7": {
                    "questionId": "BP6.7",
                    "chosenOptionIds": [
                        "ef07ce6c-9277-581a-bde7-14889359301b"
                    ]
                },
                "BP6.8": {
                    "questionId": "BP6.8",
                    "freeValue": 7
                },
                "BP6.9": {
                    "questionId": "BP6.9",
                    "chosenOptionIds": [
                        "75d51935-7fc0-5e05-813c-62bbdd8f8335"
                    ]
                },
                "BP8": {
                    "questionId": "BP8",
                    "chosenOptionIds": [
                        "87e0081b-9455-5ced-9225-10c2f70b1734"
                    ]
                },
                "BP8.0": {
                    "questionId": "BP8.0",
                    "chosenOptionIds": [
                        "3568cdb1-2500-512b-aada-2547c5bfd835"
                    ]
                },
                "BP8.01": {
                    "questionId": "BP8.01",
                    "chosenOptionIds": [
                        "5020bfdd-a280-56cf-b7f5-3b0b7e667165"
                    ]
                },
                "BP8.02": {
                    "questionId": "BP8.02",
                    "chosenOptionIds": [
                        "e88670a3-c6d8-5c96-914e-b9fc1818aeda"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "9ae96e50-5c86-58a7-a845-fb6347c7edd1"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "f501dc76-7fcd-586b-ba1d-ad70ab8e8d33"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "92e118de-38b9-5d5a-96ba-7b50547c0621"
                    ]
                },
                "BP9": {
                    "questionId": "BP9",
                    "chosenOptionIds": [
                        "becfe35c-2096-5848-87ae-3f763cf59dd6"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "93bfeb6e-215e-5f4b-b6de-0bc364e05be5"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "e22cf233-c174-52fb-af55-97bf9c46e971"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "0feda24f-7b01-557b-8ca5-10eb924cbdac"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "ef5a7b22-b2b2-5a5b-8a80-ce5c251033fd"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "94795194-4a80-590f-be62-ee78f2fa0ff5"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "201c7ee3-56dc-51e9-ad55-69951192ab16"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "de2ea429-4fcd-53b8-8d9f-ebb69a637a02"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "6ade3a8d-8516-5a88-baa0-f996f32730d2"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "2723ed09-37f6-522c-92b1-e518d4fe9315"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "58777a2e-4b97-5413-b340-efe722e45936"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "63509127-aadf-5fe2-8e1f-19d0d846d6ce",
                        "36502f0b-4563-5e03-80f2-a46dabeeae74"
                    ]
                },
                "BP99.1": {
                    "questionId": "BP99.1",
                    "chosenOptionIds": [
                        "34d19f12-207f-56ce-bde8-8d616f6e8b26"
                    ]
                },
                "BP99.2": {
                    "questionId": "BP99.2",
                    "chosenOptionIds": [
                        "ac6c9baf-e6df-5453-8665-0b796e1b83f8"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "a020ed85-ec9d-5778-8006-41f634cab09d"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM1": {
                    "questionId": "HM1",
                    "chosenOptionIds": [
                        "60caa355-cd97-591a-9344-1e9858939855"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "c3ee8981-dec3-5b3c-80ca-c1023f983a6d"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "a43647cb-a0e8-5705-adb9-a2b68877dc77"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "5574c021-c3c4-513a-a4b6-5ee416e8aa9e"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "3fd957d1-0cbb-5397-9812-353be5c2184e"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "d2c670c4-b05b-597a-a0af-5342230535a0"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "bcd05aa7-d9e3-5bdc-8661-0daa33c7b9b3"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "2be66549-5767-51b4-82ed-deeab0723f8d"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "0983ccf5-a426-53d0-adbb-1baf6832190c"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "e979459c-cb23-5cea-bedd-432a62288787"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "fa32b497-4a4b-5076-a391-b6dffa8e1851"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "bde4e5f3-19e4-571f-84a2-5b9d188d74ef"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "7d6921a2-7e63-5f12-9c75-dff8b592fabc"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: Y32QME8 aspect: simple_mdd should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "0ec0e224-a5cf-5ece-b3ab-37061a600ace"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "a16e8e14-524e-53df-b4ef-730a2c8e5f32"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "916d657d-9f2a-5451-8465-18e3bd863347"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "b74511c7-9f97-5c15-8bf5-6ec4357b4814"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 365
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "7a5958fc-e0f7-5d43-9cd0-abfe95cb7026"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "9222be3e-443f-5146-9d69-d48fe9041ba0"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5c30c60e-d53e-5cc3-b4a9-fdad9bec4cb7"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "1d4beed1-78e5-531c-a255-643fac260dee"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "f3efc764-ca53-52c3-9c3a-38c7f787d415"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "ef6fddab-7a07-5f20-af20-74f2444eee53"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "3b216cf8-72e7-558c-bdd3-cc23a1865674"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "44fd9eb3-aee2-5af0-b83c-dbbbf9514022"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "7599bcaa-89a5-5f71-80ea-b70c360bb44a"
                    ]
                },
                "BP101b": {
                    "questionId": "BP101b",
                    "chosenOptionIds": [
                        "cb80bcac-db78-55bd-b051-bdd00801fd54"
                    ]
                },
                "BP102b": {
                    "questionId": "BP102b",
                    "chosenOptionIds": [
                        "71338232-94be-5805-b822-94db8be9c5a1"
                    ]
                },
                "BP103b": {
                    "questionId": "BP103b",
                    "chosenOptionIds": [
                        "867b0ee4-73d9-5aac-b96d-bc2d85d5b183"
                    ]
                },
                "BP104b": {
                    "questionId": "BP104b",
                    "chosenOptionIds": [
                        "c59b5910-3279-509f-8175-f2fd0da2dc86"
                    ]
                },
                "BP87b": {
                    "questionId": "BP87b",
                    "chosenOptionIds": [
                        "7949bd96-3757-5573-bd89-3a91a383cc99"
                    ]
                },
                "BP88b": {
                    "questionId": "BP88b",
                    "chosenOptionIds": [
                        "fa24b924-a8e1-5f02-893b-3d9643007991"
                    ]
                },
                "BP89b": {
                    "questionId": "BP89b",
                    "chosenOptionIds": [
                        "41d2829a-3971-552f-8c5a-c77e7d0073e7"
                    ]
                },
                "BP90b": {
                    "questionId": "BP90b",
                    "chosenOptionIds": [
                        "95c2822d-27ad-5bb0-982f-8c2b949c8918"
                    ]
                },
                "BP92b": {
                    "questionId": "BP92b",
                    "chosenOptionIds": [
                        "b2f4c490-30df-5c8f-87c0-bc4acbcfb8e0"
                    ]
                },
                "BP94b": {
                    "questionId": "BP94b",
                    "chosenOptionIds": [
                        "038cb12b-2783-569a-b1d7-e28c65e591a3"
                    ]
                },
                "BP95b": {
                    "questionId": "BP95b",
                    "chosenOptionIds": [
                        "fb0bd4a7-2793-5b81-badc-fe81b4bc82dc"
                    ]
                },
                "BP96b": {
                    "questionId": "BP96b",
                    "chosenOptionIds": [
                        "8c3d3503-6334-55b9-b0f0-94c8e520094e"
                    ]
                },
                "BP97b": {
                    "questionId": "BP97b",
                    "chosenOptionIds": [
                        "6895096a-ef29-546e-9967-d7a23b4a2859"
                    ]
                },
                "BP98b": {
                    "questionId": "BP98b",
                    "chosenOptionIds": [
                        "01641419-fbfc-52a5-ae63-0fa736ff9847"
                    ]
                },
                "BP99.3b": {
                    "questionId": "BP99.3b",
                    "chosenOptionIds": [
                        "bf30a6c2-f8e2-5305-b4d4-7ad6227449d3"
                    ]
                },
                "BP99b": {
                    "questionId": "BP99b",
                    "chosenOptionIds": [
                        "68860df1-e835-50d6-8eb2-f9d9f2f97efa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "BP_neg1": {
                    "questionId": "BP_neg1",
                    "chosenOptionIds": [
                        "119208b0-ff3b-5c43-80be-4f07e65b6248"
                    ]
                }
            }
        },
        {
            "name": "Mood test: 10QKZ0P aspect: simple_mdd should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "0ec0e224-a5cf-5ece-b3ab-37061a600ace"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "a16e8e14-524e-53df-b4ef-730a2c8e5f32"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "9b47900d-e5a7-5e58-830b-d9b3130a7990"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "b74511c7-9f97-5c15-8bf5-6ec4357b4814"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 2920
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "913bc1e9-8da1-54c7-b935-13fc5d48fbd9"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "11f457f5-4c39-5458-ba9a-84478dd379ba"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5c30c60e-d53e-5cc3-b4a9-fdad9bec4cb7"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "9d1b858c-7064-5a84-80d6-786c18e603b6",
                        "ba6dc2ba-3c20-57b6-a276-32c863109ff6",
                        "1d4beed1-78e5-531c-a255-643fac260dee"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "c9813959-ba61-5220-9e27-659abb256037"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "ef6fddab-7a07-5f20-af20-74f2444eee53"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "d362cd87-7515-551e-bc74-19ef903484aa"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "44fd9eb3-aee2-5af0-b83c-dbbbf9514022",
                        "07106ab9-5949-5b5f-bc96-117139ebd397"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "7599bcaa-89a5-5f71-80ea-b70c360bb44a"
                    ]
                },
                "BP101b": {
                    "questionId": "BP101b",
                    "chosenOptionIds": [
                        "cb80bcac-db78-55bd-b051-bdd00801fd54"
                    ]
                },
                "BP102b": {
                    "questionId": "BP102b",
                    "chosenOptionIds": [
                        "71338232-94be-5805-b822-94db8be9c5a1"
                    ]
                },
                "BP103b": {
                    "questionId": "BP103b",
                    "chosenOptionIds": [
                        "867b0ee4-73d9-5aac-b96d-bc2d85d5b183"
                    ]
                },
                "BP104b": {
                    "questionId": "BP104b",
                    "chosenOptionIds": [
                        "c59b5910-3279-509f-8175-f2fd0da2dc86"
                    ]
                },
                "BP87b": {
                    "questionId": "BP87b",
                    "chosenOptionIds": [
                        "7949bd96-3757-5573-bd89-3a91a383cc99"
                    ]
                },
                "BP88b": {
                    "questionId": "BP88b",
                    "chosenOptionIds": [
                        "fa24b924-a8e1-5f02-893b-3d9643007991"
                    ]
                },
                "BP89b": {
                    "questionId": "BP89b",
                    "chosenOptionIds": [
                        "41d2829a-3971-552f-8c5a-c77e7d0073e7"
                    ]
                },
                "BP90b": {
                    "questionId": "BP90b",
                    "chosenOptionIds": [
                        "95c2822d-27ad-5bb0-982f-8c2b949c8918"
                    ]
                },
                "BP92b": {
                    "questionId": "BP92b",
                    "chosenOptionIds": [
                        "b2f4c490-30df-5c8f-87c0-bc4acbcfb8e0"
                    ]
                },
                "BP94b": {
                    "questionId": "BP94b",
                    "chosenOptionIds": [
                        "038cb12b-2783-569a-b1d7-e28c65e591a3"
                    ]
                },
                "BP95b": {
                    "questionId": "BP95b",
                    "chosenOptionIds": [
                        "fb0bd4a7-2793-5b81-badc-fe81b4bc82dc"
                    ]
                },
                "BP96b": {
                    "questionId": "BP96b",
                    "chosenOptionIds": [
                        "8c3d3503-6334-55b9-b0f0-94c8e520094e"
                    ]
                },
                "BP97b": {
                    "questionId": "BP97b",
                    "chosenOptionIds": [
                        "6895096a-ef29-546e-9967-d7a23b4a2859"
                    ]
                },
                "BP98b": {
                    "questionId": "BP98b",
                    "chosenOptionIds": [
                        "01641419-fbfc-52a5-ae63-0fa736ff9847"
                    ]
                },
                "BP99.3b": {
                    "questionId": "BP99.3b",
                    "chosenOptionIds": [
                        "bf30a6c2-f8e2-5305-b4d4-7ad6227449d3"
                    ]
                },
                "BP99b": {
                    "questionId": "BP99b",
                    "chosenOptionIds": [
                        "68860df1-e835-50d6-8eb2-f9d9f2f97efa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "BP_neg1": {
                    "questionId": "BP_neg1",
                    "chosenOptionIds": [
                        "119208b0-ff3b-5c43-80be-4f07e65b6248"
                    ]
                }
            }
        },
        {
            "name": "Mood test: Z35X10O aspect: simple_mdd should be subclinical",
            "expected": {
                "type": "Severity",
                "value": "subclinical"
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "45f0ce85-9e19-53da-bc0d-2af8b383318a"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "eb68a88d-b49b-5cde-a9c6-5e4c78e27d19"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "826538fc-8a97-55e4-8013-fe6a93f8e451"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 1095
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "db360e93-3616-5d89-be36-5ccee4aacf1c"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "a9525e10-4e34-5d6b-9c93-c7546ec49231"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "de11fb6b-7e7d-52b1-9d09-2d6debc53314"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5c30c60e-d53e-5cc3-b4a9-fdad9bec4cb7"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "9d1b858c-7064-5a84-80d6-786c18e603b6"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "dde1af45-4686-52c9-b960-369527b28f6c"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "228ef7d9-008b-559d-ba14-6b928b486b38"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "d362cd87-7515-551e-bc74-19ef903484aa"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "8c599171-d554-5547-9f54-2066c1a711dd"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "7599bcaa-89a5-5f71-80ea-b70c360bb44a"
                    ]
                },
                "BP101b": {
                    "questionId": "BP101b",
                    "chosenOptionIds": [
                        "31b2d2c8-8f28-514e-9e58-aa878f6c8761"
                    ]
                },
                "BP102b": {
                    "questionId": "BP102b",
                    "chosenOptionIds": [
                        "71338232-94be-5805-b822-94db8be9c5a1"
                    ]
                },
                "BP103b": {
                    "questionId": "BP103b",
                    "chosenOptionIds": [
                        "867b0ee4-73d9-5aac-b96d-bc2d85d5b183"
                    ]
                },
                "BP104b": {
                    "questionId": "BP104b",
                    "chosenOptionIds": [
                        "c59b5910-3279-509f-8175-f2fd0da2dc86"
                    ]
                },
                "BP87b": {
                    "questionId": "BP87b",
                    "chosenOptionIds": [
                        "fb568891-2ea3-5200-bbdb-ac0695f30b02"
                    ]
                },
                "BP88b": {
                    "questionId": "BP88b",
                    "chosenOptionIds": [
                        "fa24b924-a8e1-5f02-893b-3d9643007991"
                    ]
                },
                "BP89b": {
                    "questionId": "BP89b",
                    "chosenOptionIds": [
                        "db509963-d872-5b97-ba9b-a276f58e0189"
                    ]
                },
                "BP90b": {
                    "questionId": "BP90b",
                    "chosenOptionIds": [
                        "95c2822d-27ad-5bb0-982f-8c2b949c8918"
                    ]
                },
                "BP92b": {
                    "questionId": "BP92b",
                    "chosenOptionIds": [
                        "f59918ba-eb3a-5cf1-81aa-8b7f6ac0df5e"
                    ]
                },
                "BP94b": {
                    "questionId": "BP94b",
                    "chosenOptionIds": [
                        "24f97aee-7df6-5c11-b615-f5064af38f63"
                    ]
                },
                "BP95b": {
                    "questionId": "BP95b",
                    "chosenOptionIds": [
                        "fb0bd4a7-2793-5b81-badc-fe81b4bc82dc"
                    ]
                },
                "BP96b": {
                    "questionId": "BP96b",
                    "chosenOptionIds": [
                        "8c3d3503-6334-55b9-b0f0-94c8e520094e"
                    ]
                },
                "BP97b": {
                    "questionId": "BP97b",
                    "chosenOptionIds": [
                        "8fa0f169-f510-5c9e-a4be-fbcc964f7480"
                    ]
                },
                "BP98b": {
                    "questionId": "BP98b",
                    "chosenOptionIds": [
                        "01641419-fbfc-52a5-ae63-0fa736ff9847"
                    ]
                },
                "BP99.3b": {
                    "questionId": "BP99.3b",
                    "chosenOptionIds": [
                        "bf30a6c2-f8e2-5305-b4d4-7ad6227449d3"
                    ]
                },
                "BP99b": {
                    "questionId": "BP99b",
                    "chosenOptionIds": [
                        "68860df1-e835-50d6-8eb2-f9d9f2f97efa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "BP_neg1": {
                    "questionId": "BP_neg1",
                    "chosenOptionIds": [
                        "119208b0-ff3b-5c43-80be-4f07e65b6248"
                    ]
                }
            }
        },
        {
            "name": "Mood test: ME84L3W aspect: simple_mdd should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "45f0ce85-9e19-53da-bc0d-2af8b383318a"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "c0453e8e-7a9c-5bf5-b8a2-167a61a6387e"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "322210fb-135d-5ac8-9617-7fc4f85e016d"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 0
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "7a5958fc-e0f7-5d43-9cd0-abfe95cb7026"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "a9525e10-4e34-5d6b-9c93-c7546ec49231"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "11f457f5-4c39-5458-ba9a-84478dd379ba"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5c30c60e-d53e-5cc3-b4a9-fdad9bec4cb7"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "a449e4ae-663b-50fb-9b62-1708b0a32669"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "f3efc764-ca53-52c3-9c3a-38c7f787d415"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "228ef7d9-008b-559d-ba14-6b928b486b38"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "3b216cf8-72e7-558c-bdd3-cc23a1865674"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "4896b504-2025-52c3-85e2-c6cf3205cef7"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "ccbdcbec-96d0-5362-977b-784289f5cfc0"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "4a0f1c4d-38cf-5de4-99e3-0ee818f9709c"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "e9eb902a-d327-5ec5-938c-dc317ca59b13"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "1daccd4c-e5ff-5f18-ba55-7c2d2d93b849"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "b24df75b-6bb9-5f5f-99e3-c883ec3644ec"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP12": {
                    "questionId": "BP12",
                    "chosenOptionIds": [
                        "1d6299ac-2ac6-51e2-9a3f-13e8d44e652a"
                    ]
                },
                "BP12.5": {
                    "questionId": "BP12.5",
                    "chosenOptionIds": [
                        "cd7ce00f-4e7c-57ac-9c6c-4137efa24c8c"
                    ]
                },
                "BP14": {
                    "questionId": "BP14",
                    "chosenOptionIds": [
                        "97bbe87b-c5ff-51c1-a99c-cec263cff7d4"
                    ]
                },
                "BP14.1": {
                    "questionId": "BP14.1",
                    "chosenOptionIds": [
                        "f2b7f127-8ab7-5609-8820-43158baee15c"
                    ]
                },
                "BP14.2": {
                    "questionId": "BP14.2",
                    "freeValue": 0.041666666666666664
                },
                "BP14.3": {
                    "questionId": "BP14.3",
                    "chosenOptionIds": [
                        "9f5fc152-75fb-59d8-8f40-7a6ca15c69cb"
                    ]
                },
                "BP16": {
                    "questionId": "BP16",
                    "chosenOptionIds": [
                        "fabceeea-8fea-5444-8b67-8f189f366c27"
                    ]
                },
                "BP16.0": {
                    "questionId": "BP16.0",
                    "chosenOptionIds": [
                        "4b26caa5-9124-51b2-ab08-2f4e977cee1f"
                    ]
                },
                "BP16.01": {
                    "questionId": "BP16.01",
                    "chosenOptionIds": [
                        "f81655d7-78e5-59b1-a7eb-90a2ec7a107d"
                    ]
                },
                "BP16.02": {
                    "questionId": "BP16.02",
                    "chosenOptionIds": [
                        "16a695c9-3ac1-5d34-b799-44602d85b556"
                    ]
                },
                "BP18": {
                    "questionId": "BP18",
                    "chosenOptionIds": [
                        "b577f323-ec54-5d25-a2b9-4f8de30d60a2"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 23
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "f03de28d-8f71-5af4-be2f-45f4e6092a3d"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "ad1cfcc2-c1dc-57ef-b964-17d355fa0402"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "afb57366-c0b3-5f73-92eb-27bc133ad2ed"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "667ba57c-b1cd-5f9b-aa12-58e65a981910"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "fd30dc27-8e89-5d1a-9d95-8844603d4379"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "d9fb150b-68b8-50ed-bb1d-eafd41f63f3b"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "46c90446-3ae9-53f5-9b57-ad19d66b5be6"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "d680a8d9-e35f-563f-9d1d-e7c3136aff6d"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "c88e3fb5-913a-5c5c-a42b-603f7bb60a40"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "201c7ee3-56dc-51e9-ad55-69951192ab16"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "dbab3669-e910-5267-b7b1-fcb661ea7e81"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "2723ed09-37f6-522c-92b1-e518d4fe9315"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "58777a2e-4b97-5413-b340-efe722e45936"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "5c06d516-e816-58d6-997e-f8fa91131ac7"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "a020ed85-ec9d-5778-8006-41f634cab09d"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM19": {
                    "questionId": "HM19",
                    "chosenOptionIds": [
                        "99f474d7-d509-592a-856a-afed343fb1b8"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "98ea07a0-ae95-570f-8d11-a3e7c682e986"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "696fdd9d-5ced-504b-a933-bb3e575b0099"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c41974b6-d394-569c-8f45-49f79fcac5c6"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "43c32319-c75f-5169-b0e5-713820721d4f"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "b2cb304f-f035-53fc-8763-e2f91b031f9b"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "0170b4cf-0541-53cd-a9db-818d5067dc23"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "2be66549-5767-51b4-82ed-deeab0723f8d"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "2a17c2fe-2592-58b1-98e9-3dc4168fe3e3"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "a56fcb96-f868-5d08-a358-9b403eb3cd16"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "fa32b497-4a4b-5076-a391-b6dffa8e1851"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "8823c74f-ff7c-52d8-9c73-fd3b4180f48a"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "1d07911e-929d-5719-aeea-a68f593d18e8"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: PE694EL aspect: simple_mdd should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "3bd18f66-6f1e-5ea4-ae24-2990ed197bef"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "916d657d-9f2a-5451-8465-18e3bd863347"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "826538fc-8a97-55e4-8013-fe6a93f8e451"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 1095
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "db360e93-3616-5d89-be36-5ccee4aacf1c"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "a9525e10-4e34-5d6b-9c93-c7546ec49231"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "9222be3e-443f-5146-9d69-d48fe9041ba0"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "db7514e2-d317-55fb-82d3-2e4a49c2494b"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "9d1b858c-7064-5a84-80d6-786c18e603b6",
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "f3efc764-ca53-52c3-9c3a-38c7f787d415"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "9506449e-b2aa-5498-b896-901dd248b1b3"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "ef6fddab-7a07-5f20-af20-74f2444eee53"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "3b216cf8-72e7-558c-bdd3-cc23a1865674"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "07106ab9-5949-5b5f-bc96-117139ebd397"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "83e6efd5-64a2-5f0e-a973-9278eae90cfb"
                    ]
                },
                "BP10": {
                    "questionId": "BP10",
                    "chosenOptionIds": [
                        "7ad54f0a-87a6-5e82-bfc3-44b540230082"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "4a0f1c4d-38cf-5de4-99e3-0ee818f9709c"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "6a5aa899-7b89-5661-a554-71b6058ad4a4"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "d3224e16-1b0c-5266-9e14-ac72d2b2e988"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "b24df75b-6bb9-5f5f-99e3-c883ec3644ec"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 45
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "c7bee7a0-3037-5eb1-96a1-85b884d7e1dd"
                    ]
                },
                "BP3.1": {
                    "questionId": "BP3.1",
                    "chosenOptionIds": [
                        "b56edfbb-cf11-50ee-9149-9e36f4cc1ea0"
                    ]
                },
                "BP3.2": {
                    "questionId": "BP3.2",
                    "chosenOptionIds": [
                        "ec333f8b-146a-5700-a15d-527032ceac3f"
                    ]
                },
                "BP3.3": {
                    "questionId": "BP3.3",
                    "freeValue": 0.041666666666666664
                },
                "BP3.4": {
                    "questionId": "BP3.4",
                    "chosenOptionIds": [
                        "6ec82bbf-7582-5509-ace2-5ecf0ce82ec3"
                    ]
                },
                "BP4": {
                    "questionId": "BP4",
                    "chosenOptionIds": [
                        "9765aba8-7d55-5928-b79d-36aa58646036"
                    ]
                },
                "BP4.5": {
                    "questionId": "BP4.5",
                    "chosenOptionIds": [
                        "66a3460c-65ce-5a4c-9774-2e303ae4961e"
                    ]
                },
                "BP6": {
                    "questionId": "BP6",
                    "chosenOptionIds": [
                        "742a22d6-a570-5e3a-8e72-c001f1e9f98b"
                    ]
                },
                "BP6.1": {
                    "questionId": "BP6.1",
                    "chosenOptionIds": [
                        "430b68c6-29cf-5a15-ab4c-89bd842383bf"
                    ]
                },
                "BP6.2": {
                    "questionId": "BP6.2",
                    "freeValue": 0.041666666666666664
                },
                "BP6.3": {
                    "questionId": "BP6.3",
                    "chosenOptionIds": [
                        "e061c578-086e-5990-bba6-279cb273f3a5"
                    ]
                },
                "BP8": {
                    "questionId": "BP8",
                    "chosenOptionIds": [
                        "87e0081b-9455-5ced-9225-10c2f70b1734"
                    ]
                },
                "BP8.0": {
                    "questionId": "BP8.0",
                    "chosenOptionIds": [
                        "3568cdb1-2500-512b-aada-2547c5bfd835"
                    ]
                },
                "BP8.01": {
                    "questionId": "BP8.01",
                    "chosenOptionIds": [
                        "5020bfdd-a280-56cf-b7f5-3b0b7e667165"
                    ]
                },
                "BP8.02": {
                    "questionId": "BP8.02",
                    "chosenOptionIds": [
                        "e88670a3-c6d8-5c96-914e-b9fc1818aeda"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "9ae96e50-5c86-58a7-a845-fb6347c7edd1"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "afb57366-c0b3-5f73-92eb-27bc133ad2ed"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "28c75e9e-94ef-5c50-8645-f4ff93ccf2ec"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "fd30dc27-8e89-5d1a-9d95-8844603d4379"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "e22cf233-c174-52fb-af55-97bf9c46e971"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "46c90446-3ae9-53f5-9b57-ad19d66b5be6"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "d680a8d9-e35f-563f-9d1d-e7c3136aff6d"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "c88e3fb5-913a-5c5c-a42b-603f7bb60a40"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "ec84966e-bb9f-5ba9-a0cf-d5d3503a74e0"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "6928c696-e6d7-5a14-a004-15a497ac6562"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "17b4da6f-7135-523c-b90b-2fe3b66785ff"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "3cf77140-2757-58eb-b4ea-f2a90cd766e7"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "5c06d516-e816-58d6-997e-f8fa91131ac7"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "c0cc09c3-1335-5eb4-8801-b3cdaf74f6fa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM2": {
                    "questionId": "HM2",
                    "chosenOptionIds": [
                        "de662c3f-66b4-566f-856d-2124e2725542"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "98ea07a0-ae95-570f-8d11-a3e7c682e986"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "a43647cb-a0e8-5705-adb9-a2b68877dc77"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c41974b6-d394-569c-8f45-49f79fcac5c6"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "43c32319-c75f-5169-b0e5-713820721d4f"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "b2cb304f-f035-53fc-8763-e2f91b031f9b"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "0170b4cf-0541-53cd-a9db-818d5067dc23"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "efcb2ed0-864a-521d-ac0d-99e0fd4b38f5"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "2a17c2fe-2592-58b1-98e9-3dc4168fe3e3"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "834b04f2-af09-5337-8fd5-be4a70c99e75"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "86277249-cf50-5f91-a27f-f3a69344e796"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "8823c74f-ff7c-52d8-9c73-fd3b4180f48a"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "1d07911e-929d-5719-aeea-a68f593d18e8"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: 63Y843R aspect: simple_mdd should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "45f0ce85-9e19-53da-bc0d-2af8b383318a"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "c0453e8e-7a9c-5bf5-b8a2-167a61a6387e"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "b74511c7-9f97-5c15-8bf5-6ec4357b4814"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 90
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "7a5958fc-e0f7-5d43-9cd0-abfe95cb7026"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "a9525e10-4e34-5d6b-9c93-c7546ec49231"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "11f457f5-4c39-5458-ba9a-84478dd379ba"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5c30c60e-d53e-5cc3-b4a9-fdad9bec4cb7"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "9d1b858c-7064-5a84-80d6-786c18e603b6",
                        "1d4beed1-78e5-531c-a255-643fac260dee"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "b52073e3-c813-51f5-88ba-9cd3bddd7023"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "ef6fddab-7a07-5f20-af20-74f2444eee53"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "d362cd87-7515-551e-bc74-19ef903484aa"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "07106ab9-5949-5b5f-bc96-117139ebd397",
                        "4896b504-2025-52c3-85e2-c6cf3205cef7"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "7599bcaa-89a5-5f71-80ea-b70c360bb44a"
                    ]
                },
                "BP101b": {
                    "questionId": "BP101b",
                    "chosenOptionIds": [
                        "cb80bcac-db78-55bd-b051-bdd00801fd54"
                    ]
                },
                "BP102b": {
                    "questionId": "BP102b",
                    "chosenOptionIds": [
                        "71338232-94be-5805-b822-94db8be9c5a1"
                    ]
                },
                "BP103b": {
                    "questionId": "BP103b",
                    "chosenOptionIds": [
                        "867b0ee4-73d9-5aac-b96d-bc2d85d5b183"
                    ]
                },
                "BP104b": {
                    "questionId": "BP104b",
                    "chosenOptionIds": [
                        "c59b5910-3279-509f-8175-f2fd0da2dc86"
                    ]
                },
                "BP87b": {
                    "questionId": "BP87b",
                    "chosenOptionIds": [
                        "7949bd96-3757-5573-bd89-3a91a383cc99"
                    ]
                },
                "BP88b": {
                    "questionId": "BP88b",
                    "chosenOptionIds": [
                        "fa24b924-a8e1-5f02-893b-3d9643007991"
                    ]
                },
                "BP89b": {
                    "questionId": "BP89b",
                    "chosenOptionIds": [
                        "41d2829a-3971-552f-8c5a-c77e7d0073e7"
                    ]
                },
                "BP90b": {
                    "questionId": "BP90b",
                    "chosenOptionIds": [
                        "95c2822d-27ad-5bb0-982f-8c2b949c8918"
                    ]
                },
                "BP92b": {
                    "questionId": "BP92b",
                    "chosenOptionIds": [
                        "b2f4c490-30df-5c8f-87c0-bc4acbcfb8e0"
                    ]
                },
                "BP94b": {
                    "questionId": "BP94b",
                    "chosenOptionIds": [
                        "33cefe4b-19c9-55e0-a87c-03ffd99d30bf"
                    ]
                },
                "BP95b": {
                    "questionId": "BP95b",
                    "chosenOptionIds": [
                        "fb0bd4a7-2793-5b81-badc-fe81b4bc82dc"
                    ]
                },
                "BP96b": {
                    "questionId": "BP96b",
                    "chosenOptionIds": [
                        "8c3d3503-6334-55b9-b0f0-94c8e520094e"
                    ]
                },
                "BP97b": {
                    "questionId": "BP97b",
                    "chosenOptionIds": [
                        "8fa0f169-f510-5c9e-a4be-fbcc964f7480"
                    ]
                },
                "BP98b": {
                    "questionId": "BP98b",
                    "chosenOptionIds": [
                        "01641419-fbfc-52a5-ae63-0fa736ff9847"
                    ]
                },
                "BP99.3b": {
                    "questionId": "BP99.3b",
                    "chosenOptionIds": [
                        "bf30a6c2-f8e2-5305-b4d4-7ad6227449d3"
                    ]
                },
                "BP99b": {
                    "questionId": "BP99b",
                    "chosenOptionIds": [
                        "68860df1-e835-50d6-8eb2-f9d9f2f97efa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "BP_neg1": {
                    "questionId": "BP_neg1",
                    "chosenOptionIds": [
                        "119208b0-ff3b-5c43-80be-4f07e65b6248"
                    ]
                }
            }
        },
        {
            "name": "Mood test: J0VQM3L aspect: simple_mdd should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "45f0ce85-9e19-53da-bc0d-2af8b383318a"
                    ]
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "7a5958fc-e0f7-5d43-9cd0-abfe95cb7026"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "a9525e10-4e34-5d6b-9c93-c7546ec49231"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "9222be3e-443f-5146-9d69-d48fe9041ba0"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "77c6088d-2d8f-5c3b-89c9-79cb0d317226"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "1d4beed1-78e5-531c-a255-643fac260dee"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "f3efc764-ca53-52c3-9c3a-38c7f787d415"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "9506449e-b2aa-5498-b896-901dd248b1b3"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "ef6fddab-7a07-5f20-af20-74f2444eee53"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "3b216cf8-72e7-558c-bdd3-cc23a1865674"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "60fe0bb4-d770-56f2-b05a-864625a48e4d"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "7599bcaa-89a5-5f71-80ea-b70c360bb44a"
                    ]
                },
                "BP101b": {
                    "questionId": "BP101b",
                    "chosenOptionIds": [
                        "31b2d2c8-8f28-514e-9e58-aa878f6c8761"
                    ]
                },
                "BP102b": {
                    "questionId": "BP102b",
                    "chosenOptionIds": [
                        "00afc1e6-b78a-5223-a6e7-5d3aaab833e0"
                    ]
                },
                "BP103b": {
                    "questionId": "BP103b",
                    "chosenOptionIds": [
                        "867b0ee4-73d9-5aac-b96d-bc2d85d5b183"
                    ]
                },
                "BP104b": {
                    "questionId": "BP104b",
                    "chosenOptionIds": [
                        "c59b5910-3279-509f-8175-f2fd0da2dc86"
                    ]
                },
                "BP87b": {
                    "questionId": "BP87b",
                    "chosenOptionIds": [
                        "5bc9fc9f-d402-56b0-97eb-fe64add38edf"
                    ]
                },
                "BP88b": {
                    "questionId": "BP88b",
                    "chosenOptionIds": [
                        "fa24b924-a8e1-5f02-893b-3d9643007991"
                    ]
                },
                "BP89b": {
                    "questionId": "BP89b",
                    "chosenOptionIds": [
                        "41d2829a-3971-552f-8c5a-c77e7d0073e7"
                    ]
                },
                "BP90b": {
                    "questionId": "BP90b",
                    "chosenOptionIds": [
                        "95c2822d-27ad-5bb0-982f-8c2b949c8918"
                    ]
                },
                "BP92b": {
                    "questionId": "BP92b",
                    "chosenOptionIds": [
                        "b2f4c490-30df-5c8f-87c0-bc4acbcfb8e0"
                    ]
                },
                "BP94b": {
                    "questionId": "BP94b",
                    "chosenOptionIds": [
                        "038cb12b-2783-569a-b1d7-e28c65e591a3"
                    ]
                },
                "BP95b": {
                    "questionId": "BP95b",
                    "chosenOptionIds": [
                        "fb0bd4a7-2793-5b81-badc-fe81b4bc82dc"
                    ]
                },
                "BP96b": {
                    "questionId": "BP96b",
                    "chosenOptionIds": [
                        "8c3d3503-6334-55b9-b0f0-94c8e520094e"
                    ]
                },
                "BP97b": {
                    "questionId": "BP97b",
                    "chosenOptionIds": [
                        "6895096a-ef29-546e-9967-d7a23b4a2859"
                    ]
                },
                "BP98b": {
                    "questionId": "BP98b",
                    "chosenOptionIds": [
                        "01641419-fbfc-52a5-ae63-0fa736ff9847"
                    ]
                },
                "BP99.3b": {
                    "questionId": "BP99.3b",
                    "chosenOptionIds": [
                        "bf30a6c2-f8e2-5305-b4d4-7ad6227449d3"
                    ]
                },
                "BP99b": {
                    "questionId": "BP99b",
                    "chosenOptionIds": [
                        "68860df1-e835-50d6-8eb2-f9d9f2f97efa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "BP_neg1": {
                    "questionId": "BP_neg1",
                    "chosenOptionIds": [
                        "119208b0-ff3b-5c43-80be-4f07e65b6248"
                    ]
                }
            }
        },
        {
            "name": "Mood test: Y32OM08 aspect: simple_mdd should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "c61d70d4-b773-54dd-94d0-d858a47be0fb"
                    ]
                },
                "D-1.5b": {
                    "questionId": "D-1.5b",
                    "freeValue": 26
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "a16e8e14-524e-53df-b4ef-730a2c8e5f32"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "9b47900d-e5a7-5e58-830b-d9b3130a7990"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "b74511c7-9f97-5c15-8bf5-6ec4357b4814"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 1460
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "913bc1e9-8da1-54c7-b935-13fc5d48fbd9"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-1bl": {
                    "questionId": "D-1bl",
                    "chosenOptionIds": [
                        "4105c26a-4ff2-5fd7-984d-f0bcb7b5656e"
                    ]
                },
                "D-2": {
                    "questionId": "D-2",
                    "freeValue": 15
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "c695fd1c-2cb7-5654-b9f7-b32ecb158229"
                    ]
                },
                "D-2bl": {
                    "questionId": "D-2bl",
                    "chosenOptionIds": [
                        "78c1434b-e585-5f1d-b291-a1728f789022"
                    ]
                },
                "D-2bs": {
                    "questionId": "D-2bs",
                    "chosenOptionIds": [
                        "bbd43ce6-ea63-5321-9d05-0bbf9a19513b"
                    ]
                },
                "D-2bt": {
                    "questionId": "D-2bt",
                    "chosenOptionIds": [
                        "acdba44c-4419-55d5-891f-5c03c9b52309"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "fd9c4394-6664-5144-8797-00d27a4dda01"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "db7514e2-d317-55fb-82d3-2e4a49c2494b"
                    ]
                },
                "D-3bl": {
                    "questionId": "D-3bl",
                    "freeValue": 2
                },
                "D-3bs": {
                    "questionId": "D-3bs",
                    "chosenOptionIds": [
                        "fe554f25-dfd7-5062-a2b8-f2662e2c3466"
                    ]
                },
                "D-3bt": {
                    "questionId": "D-3bt",
                    "chosenOptionIds": [
                        "b9abc28d-6e22-5b67-bc5e-27431e123974"
                    ]
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-42bt": {
                    "questionId": "D-42bt",
                    "freeValue": 3
                },
                "D-43bt": {
                    "questionId": "D-43bt",
                    "chosenOptionIds": [
                        "90e60395-fc3d-56cb-b6f7-2e24fba02803"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "1d4beed1-78e5-531c-a255-643fac260dee",
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b"
                    ]
                },
                "D-4bs": {
                    "questionId": "D-4bs",
                    "freeValue": 2
                },
                "D-4bt": {
                    "questionId": "D-4bt",
                    "freeValue": 5
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "c9813959-ba61-5220-9e27-659abb256037"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "3e093dd3-857e-5004-a4fb-961fbfa59ece"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "adecf2d3-f302-50f8-a4a9-3ffac1789bc6"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "4c78ae3e-7de4-545f-aca1-03fa41fc0c23"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "13f8bac1-d0f5-539b-8596-1c55821bea46"
                    ]
                },
                "D-s10bt": {
                    "questionId": "D-s10bt",
                    "chosenOptionIds": [
                        "e3ab5219-3af6-5da7-8464-a145eb508a0f"
                    ]
                },
                "D-s11bt": {
                    "questionId": "D-s11bt",
                    "chosenOptionIds": [
                        "bae5acc8-8664-54a2-ba97-c36244be4c44",
                        "dc476220-9479-5def-8a97-20e6152d44af",
                        "6994b88e-cfa5-5986-b9e8-625d17d1147c",
                        "16e1a0b8-28c5-5bcd-931d-335c858c3f88",
                        "64af3cae-713a-51e1-99c1-09e2fdaa755d",
                        "7921bccf-4756-59fe-b678-d536c2ab5945"
                    ]
                },
                "D-s1bt": {
                    "questionId": "D-s1bt",
                    "chosenOptionIds": [
                        "c17482e4-818a-50cd-90fa-1246d037825e"
                    ]
                },
                "D-s2bt": {
                    "questionId": "D-s2bt",
                    "chosenOptionIds": [
                        "8783a6d5-cd94-5982-8fd6-66ae46e1c6df"
                    ]
                },
                "D-s3bt": {
                    "questionId": "D-s3bt",
                    "chosenOptionIds": [
                        "d065c0ca-9e6d-5300-8e3e-688b16f88c59"
                    ]
                },
                "D-s4bt": {
                    "questionId": "D-s4bt",
                    "chosenOptionIds": [
                        "a4021497-af94-56a4-8075-392653262e2e"
                    ]
                },
                "D-s5bt": {
                    "questionId": "D-s5bt",
                    "chosenOptionIds": [
                        "508d38ea-9336-504a-a8f2-c375a9bcc839"
                    ]
                },
                "D-s6bt": {
                    "questionId": "D-s6bt",
                    "chosenOptionIds": [
                        "53625e56-4f03-5f54-a340-d5b8cabe24d9"
                    ]
                },
                "D-s7bt": {
                    "questionId": "D-s7bt",
                    "chosenOptionIds": [
                        "1df67815-0b9f-56ef-bd48-96ed977db141"
                    ]
                },
                "D-s8bt": {
                    "questionId": "D-s8bt",
                    "chosenOptionIds": [
                        "6f5067cf-ef4d-59d9-afa1-b3dfc4ac861f"
                    ]
                },
                "D-s9bt": {
                    "questionId": "D-s9bt",
                    "chosenOptionIds": [
                        "da503bbd-e567-576e-a21f-e0fad60dccdd"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "60fe0bb4-d770-56f2-b05a-864625a48e4d"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "7599bcaa-89a5-5f71-80ea-b70c360bb44a"
                    ]
                },
                "BP101b": {
                    "questionId": "BP101b",
                    "chosenOptionIds": [
                        "c9856080-d9de-533e-92bf-7fbe2b6cd99a"
                    ]
                },
                "BP102b": {
                    "questionId": "BP102b",
                    "chosenOptionIds": [
                        "46a64b5b-6641-5b1b-92aa-5366444fb134"
                    ]
                },
                "BP103b": {
                    "questionId": "BP103b",
                    "chosenOptionIds": [
                        "867b0ee4-73d9-5aac-b96d-bc2d85d5b183"
                    ]
                },
                "BP104b": {
                    "questionId": "BP104b",
                    "chosenOptionIds": [
                        "c59b5910-3279-509f-8175-f2fd0da2dc86"
                    ]
                },
                "BP87b": {
                    "questionId": "BP87b",
                    "chosenOptionIds": [
                        "7949bd96-3757-5573-bd89-3a91a383cc99"
                    ]
                },
                "BP88b": {
                    "questionId": "BP88b",
                    "chosenOptionIds": [
                        "e29e159f-f691-5aa7-b5a0-d13fe2c54170"
                    ]
                },
                "BP89b": {
                    "questionId": "BP89b",
                    "chosenOptionIds": [
                        "41d2829a-3971-552f-8c5a-c77e7d0073e7"
                    ]
                },
                "BP90b": {
                    "questionId": "BP90b",
                    "chosenOptionIds": [
                        "95c2822d-27ad-5bb0-982f-8c2b949c8918"
                    ]
                },
                "BP92b": {
                    "questionId": "BP92b",
                    "chosenOptionIds": [
                        "b2f4c490-30df-5c8f-87c0-bc4acbcfb8e0"
                    ]
                },
                "BP94b": {
                    "questionId": "BP94b",
                    "chosenOptionIds": [
                        "1f4fafbf-913e-5edc-bfaf-3baae49fc91d"
                    ]
                },
                "BP95b": {
                    "questionId": "BP95b",
                    "chosenOptionIds": [
                        "fb0bd4a7-2793-5b81-badc-fe81b4bc82dc"
                    ]
                },
                "BP96b": {
                    "questionId": "BP96b",
                    "chosenOptionIds": [
                        "8c3d3503-6334-55b9-b0f0-94c8e520094e"
                    ]
                },
                "BP97b": {
                    "questionId": "BP97b",
                    "chosenOptionIds": [
                        "8fa0f169-f510-5c9e-a4be-fbcc964f7480"
                    ]
                },
                "BP98b": {
                    "questionId": "BP98b",
                    "chosenOptionIds": [
                        "f7b75a2b-3bd2-5734-9fa6-7706f7f8ac89"
                    ]
                },
                "BP99.1b": {
                    "questionId": "BP99.1b",
                    "chosenOptionIds": [
                        "74ac8d69-c317-57fb-a5e5-a93c1309c0f7"
                    ]
                },
                "BP99.2b": {
                    "questionId": "BP99.2b",
                    "chosenOptionIds": [
                        "a8224369-4083-5ab5-8cad-c4563490599c"
                    ]
                },
                "BP99.3b": {
                    "questionId": "BP99.3b",
                    "chosenOptionIds": [
                        "e4575ba2-20f0-519e-967b-d5769763cadc"
                    ]
                },
                "BP99b": {
                    "questionId": "BP99b",
                    "chosenOptionIds": [
                        "3f987aaa-e435-55a9-9c1b-aa6d2e476738",
                        "96faf550-f157-55b9-8f15-6be82cd511a8"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "BP_neg1": {
                    "questionId": "BP_neg1",
                    "chosenOptionIds": [
                        "119208b0-ff3b-5c43-80be-4f07e65b6248"
                    ]
                }
            }
        },
        {
            "name": "Mood test: 50MDW06 aspect: simple_mdd should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "fe031280-7d5d-52cb-aca8-1a8664b3234a"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "9b47900d-e5a7-5e58-830b-d9b3130a7990"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "a0e528ca-2fbb-5da1-9398-0758f79755aa"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 1460
                },
                "D-15a": {
                    "questionId": "D-15a",
                    "chosenOptionIds": [
                        "7b763ff0-7703-54f7-8eae-aa83417ca133"
                    ]
                },
                "D-16.5a": {
                    "questionId": "D-16.5a",
                    "chosenOptionIds": [
                        "ae0a4d4e-e9bf-51bf-8506-0cbced38db92"
                    ]
                },
                "D-17a": {
                    "questionId": "D-17a",
                    "chosenOptionIds": [
                        "495d3d07-8a1f-5213-bf79-59443d7aa5cf"
                    ]
                },
                "D-18a": {
                    "questionId": "D-18a",
                    "chosenOptionIds": [
                        "d5dff57c-3626-5197-b54a-ddca85aa8775"
                    ]
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "db360e93-3616-5d89-be36-5ccee4aacf1c"
                    ]
                },
                "D-1bl": {
                    "questionId": "D-1bl",
                    "chosenOptionIds": [
                        "5da400b0-5bff-5744-a664-4305892ae669"
                    ]
                },
                "D-2": {
                    "questionId": "D-2",
                    "freeValue": 14
                },
                "D-20a": {
                    "questionId": "D-20a",
                    "chosenOptionIds": [
                        "15c694bd-3089-51d8-8fff-6286e2113f9c"
                    ]
                },
                "D-21a": {
                    "questionId": "D-21a",
                    "chosenOptionIds": [
                        "4c0f1be4-1f17-5e09-86a5-e8e8e51285ef"
                    ]
                },
                "D-23a": {
                    "questionId": "D-23a",
                    "chosenOptionIds": [
                        "f6cf63c5-0c57-5422-9431-55fec4c93c63"
                    ]
                },
                "D-26a": {
                    "questionId": "D-26a",
                    "chosenOptionIds": [
                        "400d260d-4647-5f4d-8105-894b04c366f2"
                    ]
                },
                "D-27a": {
                    "questionId": "D-27a",
                    "chosenOptionIds": [
                        "13a1b08d-9817-5c2f-96cb-f8d03b80a37d"
                    ]
                },
                "D-28a": {
                    "questionId": "D-28a",
                    "chosenOptionIds": [
                        "801353cf-2f72-53db-9179-e7ecc31b1fc1"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "c695fd1c-2cb7-5654-b9f7-b32ecb158229"
                    ]
                },
                "D-2bl": {
                    "questionId": "D-2bl",
                    "chosenOptionIds": [
                        "2a8c96e2-cb5b-596a-99b6-a4885253776c"
                    ]
                },
                "D-2bs": {
                    "questionId": "D-2bs",
                    "chosenOptionIds": [
                        "d9fc40d3-aa51-587a-a54d-305ad26f67ae"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "e797900f-129d-553e-b83f-8494d7f03986"
                    ]
                },
                "D-30a": {
                    "questionId": "D-30a",
                    "chosenOptionIds": [
                        "d309cef4-2204-5a10-a98d-b66740336838"
                    ]
                },
                "D-31a": {
                    "questionId": "D-31a",
                    "chosenOptionIds": [
                        "bfd75ca8-5c95-564e-9603-3b1a4f34f8d8"
                    ]
                },
                "D-32a": {
                    "questionId": "D-32a",
                    "chosenOptionIds": [
                        "ea2a4fbd-008a-571b-bb68-cbd0ba351521"
                    ]
                },
                "D-33a": {
                    "questionId": "D-33a",
                    "chosenOptionIds": [
                        "3968dd70-f060-5cbd-bb6a-d63f702a3691"
                    ]
                },
                "D-34a": {
                    "questionId": "D-34a",
                    "chosenOptionIds": [
                        "6bfab4b8-7d57-5039-8933-4dee0c958a19"
                    ]
                },
                "D-35a": {
                    "questionId": "D-35a",
                    "chosenOptionIds": [
                        "9c9d44bb-b50f-5ddf-9553-03b254272362"
                    ]
                },
                "D-36a": {
                    "questionId": "D-36a",
                    "chosenOptionIds": [
                        "7fb54260-7d7e-5c80-809e-584be2bfee10"
                    ]
                },
                "D-37a": {
                    "questionId": "D-37a",
                    "chosenOptionIds": [
                        "fd4e0608-283c-5f56-a026-edfa381519c9"
                    ]
                },
                "D-38a": {
                    "questionId": "D-38a",
                    "chosenOptionIds": [
                        "8d5bbc66-0b33-5de5-838e-ff0fccbb6fbc"
                    ]
                },
                "D-39a": {
                    "questionId": "D-39a",
                    "chosenOptionIds": [
                        "756b91dc-7358-504f-aeab-84552f2bf28b"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5b6c0a69-6369-53d1-b2e1-5cd6294a9f71"
                    ]
                },
                "D-3bl": {
                    "questionId": "D-3bl",
                    "freeValue": 14
                },
                "D-3bs": {
                    "questionId": "D-3bs",
                    "chosenOptionIds": [
                        "c4422e8a-6a0a-51ea-8592-9157036922d1"
                    ]
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-40a": {
                    "questionId": "D-40a",
                    "chosenOptionIds": [
                        "582119a7-d4a0-58ff-9b1d-2af045e11a7f"
                    ]
                },
                "D-41a": {
                    "questionId": "D-41a",
                    "chosenOptionIds": [
                        "6a5d1a65-0b53-5485-995a-76d8d7eafb1a"
                    ]
                },
                "D-42a": {
                    "questionId": "D-42a",
                    "chosenOptionIds": [
                        "bc912c4c-69a9-5a39-89fb-2072bd945067"
                    ]
                },
                "D-43a": {
                    "questionId": "D-43a",
                    "chosenOptionIds": [
                        "cd0abe72-b3e8-5c78-8330-7ee4a1657536"
                    ]
                },
                "D-43bt": {
                    "questionId": "D-43bt",
                    "chosenOptionIds": [
                        "90e60395-fc3d-56cb-b6f7-2e24fba02803"
                    ]
                },
                "D-44.5a": {
                    "questionId": "D-44.5a",
                    "chosenOptionIds": [
                        "4a53cb20-fae8-59f1-934c-ed83e0709795"
                    ]
                },
                "D-44a": {
                    "questionId": "D-44a",
                    "chosenOptionIds": [
                        "7d6584ca-1b93-543d-9ee4-400ca40134be"
                    ]
                },
                "D-45a": {
                    "questionId": "D-45a",
                    "freeValue": 7
                },
                "D-46a": {
                    "questionId": "D-46a",
                    "chosenOptionIds": [
                        "6e49f368-62af-5abb-9034-a58bd534944f",
                        "4c178978-f79a-58fc-8e73-3a98614c4b6c",
                        "7492eab7-c625-52d0-a2e3-4bf3332decc0",
                        "d9665b1f-cdd2-5979-895e-27bca6487533"
                    ]
                },
                "D-49.1a": {
                    "questionId": "D-49.1a",
                    "chosenOptionIds": [
                        "7108af97-c956-5da8-8dfa-518e346c197c"
                    ]
                },
                "D-49.2a": {
                    "questionId": "D-49.2a",
                    "freeValue": 100
                },
                "D-49a": {
                    "questionId": "D-49a",
                    "chosenOptionIds": [
                        "16dc6af1-32a9-59c5-b66c-57f6becea1e0"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b"
                    ]
                },
                "D-4bs": {
                    "questionId": "D-4bs",
                    "freeValue": 21
                },
                "D-50a": {
                    "questionId": "D-50a",
                    "chosenOptionIds": [
                        "d14da42d-9612-5827-9583-b2bf528130de"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "188a7831-38c4-55c4-880a-1e4db4e9bab1"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "08a0f83f-2f37-5904-9364-4e40deb90e98"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "772bee29-c3a5-52e1-b785-a77d506ae4fb"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "4c78ae3e-7de4-545f-aca1-03fa41fc0c23"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "1200dadf-a0de-5a54-b0b8-fc640f7bc8d1"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "c2921864-bbf7-5735-9313-848fb9d5a6d1",
                        "44fd9eb3-aee2-5af0-b83c-dbbbf9514022"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "83e6efd5-64a2-5f0e-a973-9278eae90cfb"
                    ]
                },
                "BP10": {
                    "questionId": "BP10",
                    "chosenOptionIds": [
                        "7ad54f0a-87a6-5e82-bfc3-44b540230082"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "a20d7b84-720e-53c5-a450-45cbde434507"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "ca5ac96d-2b69-5200-a063-61db840c9ef2"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "7230003d-a290-5a65-ae6b-b7d564b5db38"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "b24df75b-6bb9-5f5f-99e3-c883ec3644ec"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "de4464c8-78fe-5876-aa00-25826ce5ec25"
                    ]
                },
                "BP105": {
                    "questionId": "BP105",
                    "chosenOptionIds": [
                        "64aa1680-6b5b-5a5b-bb92-75995d27ddcd"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 19
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "c7bee7a0-3037-5eb1-96a1-85b884d7e1dd"
                    ]
                },
                "BP3.01": {
                    "questionId": "BP3.01",
                    "freeValue": 40
                },
                "BP3.1": {
                    "questionId": "BP3.1",
                    "chosenOptionIds": [
                        "9531c3ff-ab2b-5198-a490-919ede5afb07"
                    ]
                },
                "BP3.2": {
                    "questionId": "BP3.2",
                    "chosenOptionIds": [
                        "ec333f8b-146a-5700-a15d-527032ceac3f"
                    ]
                },
                "BP3.3": {
                    "questionId": "BP3.3",
                    "freeValue": 2
                },
                "BP3.4": {
                    "questionId": "BP3.4",
                    "chosenOptionIds": [
                        "f304bf93-0c68-5da0-80ba-ccecc2d0fce1"
                    ]
                },
                "BP4": {
                    "questionId": "BP4",
                    "chosenOptionIds": [
                        "9765aba8-7d55-5928-b79d-36aa58646036"
                    ]
                },
                "BP4.5": {
                    "questionId": "BP4.5",
                    "chosenOptionIds": [
                        "1be4b3b1-3a88-5544-af54-ebdfee1ce891"
                    ]
                },
                "BP6": {
                    "questionId": "BP6",
                    "chosenOptionIds": [
                        "52f153db-76e7-57fa-90bd-63d5a923964a"
                    ]
                },
                "BP6.1": {
                    "questionId": "BP6.1",
                    "chosenOptionIds": [
                        "a99773bd-2bff-523e-bc29-1f761307b3d4"
                    ]
                },
                "BP6.2": {
                    "questionId": "BP6.2",
                    "freeValue": 4
                },
                "BP6.3": {
                    "questionId": "BP6.3",
                    "chosenOptionIds": [
                        "e061c578-086e-5990-bba6-279cb273f3a5"
                    ]
                },
                "BP8": {
                    "questionId": "BP8",
                    "chosenOptionIds": [
                        "87e0081b-9455-5ced-9225-10c2f70b1734"
                    ]
                },
                "BP8.0": {
                    "questionId": "BP8.0",
                    "chosenOptionIds": [
                        "3568cdb1-2500-512b-aada-2547c5bfd835"
                    ]
                },
                "BP8.01": {
                    "questionId": "BP8.01",
                    "chosenOptionIds": [
                        "5020bfdd-a280-56cf-b7f5-3b0b7e667165"
                    ]
                },
                "BP8.02": {
                    "questionId": "BP8.02",
                    "chosenOptionIds": [
                        "e88670a3-c6d8-5c96-914e-b9fc1818aeda"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "e620af65-9b5b-57e6-b13a-65616e67e084"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "5f694075-3a97-5f8b-8701-2710a43127a9"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "92e118de-38b9-5d5a-96ba-7b50547c0621"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "93bfeb6e-215e-5f4b-b6de-0bc364e05be5"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "e22cf233-c174-52fb-af55-97bf9c46e971"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "1f57d1a0-3682-5d7d-b66b-8179ec5c3988"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 7
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "17619692-6d18-5558-99da-766a1c275e03"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "82997889-d795-572b-a597-e484ef078b39"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "2555d898-6736-5804-951e-e3c438313029"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "de2ea429-4fcd-53b8-8d9f-ebb69a637a02"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "6ade3a8d-8516-5a88-baa0-f996f32730d2"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "79075051-dcb3-5321-9f27-b21697f8f194"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "58777a2e-4b97-5413-b340-efe722e45936"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "5c06d516-e816-58d6-997e-f8fa91131ac7"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "e81fe613-4923-55ed-bd6c-bd3f19e026f9"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM2": {
                    "questionId": "HM2",
                    "chosenOptionIds": [
                        "a046d610-d27a-59c1-b130-38312005cf1c"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "6b99de8b-634a-5eb0-bc65-bdf30eb9d433"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "8f7acbc1-9ebe-5a52-95ce-b50d62c27345"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "5574c021-c3c4-513a-a4b6-5ee416e8aa9e"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "43c32319-c75f-5169-b0e5-713820721d4f"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "898957c6-8cd1-53e5-98f8-c16789aba613"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "366c6a1b-c8b2-5faf-9d2f-72dbde24e869"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "6b35cd3b-d67a-54af-94f6-e39ddf5af6c6"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "0983ccf5-a426-53d0-adbb-1baf6832190c"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "7e32475a-3fcc-51ac-b414-ab46578efdfd"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "fda00d52-c8be-5b69-bdbd-905c13dddd4e"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "0360ab4b-77d5-5fcd-8d60-b7a005c12ceb"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "7d6921a2-7e63-5f12-9c75-dff8b592fabc"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: Z35L10O aspect: simple_mdd should be subclinical",
            "expected": {
                "type": "Severity",
                "value": "subclinical"
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "3bd18f66-6f1e-5ea4-ae24-2990ed197bef"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "9b47900d-e5a7-5e58-830b-d9b3130a7990"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "b74511c7-9f97-5c15-8bf5-6ec4357b4814"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 3650
                },
                "D-15a": {
                    "questionId": "D-15a",
                    "chosenOptionIds": [
                        "7b763ff0-7703-54f7-8eae-aa83417ca133"
                    ]
                },
                "D-16.5a": {
                    "questionId": "D-16.5a",
                    "chosenOptionIds": [
                        "0c4e0b7f-132d-5e75-aeae-fd29d3b2eb67"
                    ]
                },
                "D-17a": {
                    "questionId": "D-17a",
                    "chosenOptionIds": [
                        "33b013af-d830-516a-9632-69dcc7a0f736"
                    ]
                },
                "D-19a": {
                    "questionId": "D-19a",
                    "chosenOptionIds": [
                        "d5d03509-fbd0-500d-a2a9-51acdb2f03db"
                    ]
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "7f1d6182-f8a7-5f4e-a0f8-2556b306ae36"
                    ]
                },
                "D-1bl": {
                    "questionId": "D-1bl",
                    "chosenOptionIds": [
                        "4105c26a-4ff2-5fd7-984d-f0bcb7b5656e"
                    ]
                },
                "D-2": {
                    "questionId": "D-2",
                    "freeValue": 18
                },
                "D-20a": {
                    "questionId": "D-20a",
                    "chosenOptionIds": [
                        "15c694bd-3089-51d8-8fff-6286e2113f9c"
                    ]
                },
                "D-21a": {
                    "questionId": "D-21a",
                    "chosenOptionIds": [
                        "4c0f1be4-1f17-5e09-86a5-e8e8e51285ef"
                    ]
                },
                "D-23a": {
                    "questionId": "D-23a",
                    "chosenOptionIds": [
                        "44fe96b8-192b-5b6b-88fa-9c2f7ba73a6d"
                    ]
                },
                "D-26a": {
                    "questionId": "D-26a",
                    "chosenOptionIds": [
                        "400d260d-4647-5f4d-8105-894b04c366f2"
                    ]
                },
                "D-27a": {
                    "questionId": "D-27a",
                    "chosenOptionIds": [
                        "54c8142c-ee38-51de-ae81-4a95bdcd18ca"
                    ]
                },
                "D-28a": {
                    "questionId": "D-28a",
                    "chosenOptionIds": [
                        "801353cf-2f72-53db-9179-e7ecc31b1fc1"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "de11fb6b-7e7d-52b1-9d09-2d6debc53314"
                    ]
                },
                "D-2bl": {
                    "questionId": "D-2bl",
                    "chosenOptionIds": [
                        "2a8c96e2-cb5b-596a-99b6-a4885253776c"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "fd9c4394-6664-5144-8797-00d27a4dda01"
                    ]
                },
                "D-30a": {
                    "questionId": "D-30a",
                    "chosenOptionIds": [
                        "d141b80e-0ad8-511c-a1e0-b5fe8cae385f"
                    ]
                },
                "D-31a": {
                    "questionId": "D-31a",
                    "chosenOptionIds": [
                        "eba65e81-9b02-55d6-82cb-0b7ce6da41de"
                    ]
                },
                "D-32.1a": {
                    "questionId": "D-32.1a",
                    "chosenOptionIds": [
                        "da39b8b6-3178-5cf4-b68e-92b08cbf087e"
                    ]
                },
                "D-32a": {
                    "questionId": "D-32a",
                    "chosenOptionIds": [
                        "f992ce3a-fffd-5f38-aee4-24930ded9288"
                    ]
                },
                "D-33a": {
                    "questionId": "D-33a",
                    "chosenOptionIds": [
                        "379331e4-3b82-582c-af51-cc4ed79308d7"
                    ]
                },
                "D-34a": {
                    "questionId": "D-34a",
                    "chosenOptionIds": [
                        "e919c54f-d9a4-5a15-9ae7-8b6b52607a22"
                    ]
                },
                "D-35a": {
                    "questionId": "D-35a",
                    "chosenOptionIds": [
                        "bcace469-f380-599d-a33a-26db6d0053ad"
                    ]
                },
                "D-36a": {
                    "questionId": "D-36a",
                    "chosenOptionIds": [
                        "df6b03d1-7322-5277-91ae-7c8e08091a87"
                    ]
                },
                "D-37a": {
                    "questionId": "D-37a",
                    "chosenOptionIds": [
                        "fd4e0608-283c-5f56-a026-edfa381519c9"
                    ]
                },
                "D-38a": {
                    "questionId": "D-38a",
                    "chosenOptionIds": [
                        "39b328fc-f944-51dc-ae4d-9aab44697f32"
                    ]
                },
                "D-39a": {
                    "questionId": "D-39a",
                    "chosenOptionIds": [
                        "6325d970-8ab3-5759-b71d-96b5909ec039"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "db7514e2-d317-55fb-82d3-2e4a49c2494b"
                    ]
                },
                "D-3bl": {
                    "questionId": "D-3bl",
                    "freeValue": 270
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-40a": {
                    "questionId": "D-40a",
                    "chosenOptionIds": [
                        "582119a7-d4a0-58ff-9b1d-2af045e11a7f"
                    ]
                },
                "D-41a": {
                    "questionId": "D-41a",
                    "chosenOptionIds": [
                        "6a5d1a65-0b53-5485-995a-76d8d7eafb1a"
                    ]
                },
                "D-42a": {
                    "questionId": "D-42a",
                    "chosenOptionIds": [
                        "bc912c4c-69a9-5a39-89fb-2072bd945067"
                    ]
                },
                "D-43a": {
                    "questionId": "D-43a",
                    "chosenOptionIds": [
                        "cd0abe72-b3e8-5c78-8330-7ee4a1657536"
                    ]
                },
                "D-43bt": {
                    "questionId": "D-43bt",
                    "chosenOptionIds": [
                        "644dd944-5a2a-533b-9ef0-fadb5054c422"
                    ]
                },
                "D-44.5a": {
                    "questionId": "D-44.5a",
                    "chosenOptionIds": [
                        "4a53cb20-fae8-59f1-934c-ed83e0709795"
                    ]
                },
                "D-44a": {
                    "questionId": "D-44a",
                    "chosenOptionIds": [
                        "5f4eda72-db17-5c72-b0a5-8a71e1644f25"
                    ]
                },
                "D-45a": {
                    "questionId": "D-45a",
                    "freeValue": 4
                },
                "D-46a": {
                    "questionId": "D-46a",
                    "chosenOptionIds": [
                        "d9665b1f-cdd2-5979-895e-27bca6487533",
                        "1fd426df-808a-5c68-9b6e-23ff2f128b7d",
                        "7492eab7-c625-52d0-a2e3-4bf3332decc0",
                        "6e49f368-62af-5abb-9034-a58bd534944f",
                        "b3e43bd8-fbe3-5cda-b891-6f11e43993d0"
                    ]
                },
                "D-49.1a": {
                    "questionId": "D-49.1a",
                    "chosenOptionIds": [
                        "7108af97-c956-5da8-8dfa-518e346c197c"
                    ]
                },
                "D-49.2a": {
                    "questionId": "D-49.2a",
                    "freeValue": 15
                },
                "D-49a": {
                    "questionId": "D-49a",
                    "chosenOptionIds": [
                        "16dc6af1-32a9-59c5-b66c-57f6becea1e0"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "1d4beed1-78e5-531c-a255-643fac260dee",
                        "9d1b858c-7064-5a84-80d6-786c18e603b6"
                    ]
                },
                "D-50a": {
                    "questionId": "D-50a",
                    "chosenOptionIds": [
                        "d14da42d-9612-5827-9583-b2bf528130de"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "c9813959-ba61-5220-9e27-659abb256037"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "3e093dd3-857e-5004-a4fb-961fbfa59ece"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "5d271ac0-beca-56cc-9d0b-adafcd67da53"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "4c78ae3e-7de4-545f-aca1-03fa41fc0c23"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "f8e2518c-72cf-563b-b322-a03406c8ebac"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "60fe0bb4-d770-56f2-b05a-864625a48e4d"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "ccbdcbec-96d0-5362-977b-784289f5cfc0"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "4a0f1c4d-38cf-5de4-99e3-0ee818f9709c"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "6a5aa899-7b89-5661-a554-71b6058ad4a4"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "a3a547f3-b48b-567f-a32a-6a3f5f4bacd7"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "b24df75b-6bb9-5f5f-99e3-c883ec3644ec"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP12": {
                    "questionId": "BP12",
                    "chosenOptionIds": [
                        "22f9a995-3f9a-5fe9-99bb-71b7f1f96621"
                    ]
                },
                "BP15": {
                    "questionId": "BP15",
                    "chosenOptionIds": [
                        "1286eda2-5e31-52dc-95c5-78ded5b43b2a"
                    ]
                },
                "BP15.1": {
                    "questionId": "BP15.1",
                    "chosenOptionIds": [
                        "aa7bd0b8-95ca-5179-a037-3956a8c2816d"
                    ]
                },
                "BP15.2": {
                    "questionId": "BP15.2",
                    "freeValue": 2
                },
                "BP15.3": {
                    "questionId": "BP15.3",
                    "chosenOptionIds": [
                        "cfa519ff-08a9-5fa4-b12d-87b7a597cb13"
                    ]
                },
                "BP16": {
                    "questionId": "BP16",
                    "chosenOptionIds": [
                        "fabceeea-8fea-5444-8b67-8f189f366c27"
                    ]
                },
                "BP16.0": {
                    "questionId": "BP16.0",
                    "chosenOptionIds": [
                        "4b26caa5-9124-51b2-ab08-2f4e977cee1f"
                    ]
                },
                "BP16.01": {
                    "questionId": "BP16.01",
                    "chosenOptionIds": [
                        "f81655d7-78e5-59b1-a7eb-90a2ec7a107d"
                    ]
                },
                "BP16.02": {
                    "questionId": "BP16.02",
                    "chosenOptionIds": [
                        "16a695c9-3ac1-5d34-b799-44602d85b556"
                    ]
                },
                "BP19": {
                    "questionId": "BP19",
                    "chosenOptionIds": [
                        "601bc370-8a4c-5b1f-a7f6-6c174f421733"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 24
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "f03de28d-8f71-5af4-be2f-45f4e6092a3d"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "e620af65-9b5b-57e6-b13a-65616e67e084"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "5f694075-3a97-5f8b-8701-2710a43127a9"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "28c75e9e-94ef-5c50-8645-f4ff93ccf2ec"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "a2056280-4b54-5258-8bb4-407b521fff4b"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "e22cf233-c174-52fb-af55-97bf9c46e971"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "46c90446-3ae9-53f5-9b57-ad19d66b5be6"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "63f0b17a-9832-5f0f-8630-e74f1a4fdfe7"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "c88e3fb5-913a-5c5c-a42b-603f7bb60a40"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "201c7ee3-56dc-51e9-ad55-69951192ab16"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "6ade3a8d-8516-5a88-baa0-f996f32730d2"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "4a7683c6-774e-5362-8060-740f61986c9b"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "58777a2e-4b97-5413-b340-efe722e45936"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "5c06d516-e816-58d6-997e-f8fa91131ac7"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "c0cc09c3-1335-5eb4-8801-b3cdaf74f6fa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM20": {
                    "questionId": "HM20",
                    "chosenOptionIds": [
                        "be55154a-b96f-5c1c-91de-2a118e42e6f8"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "0f8903ea-258c-54c8-9d4d-643924d3c234"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "8f7acbc1-9ebe-5a52-95ce-b50d62c27345"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c41974b6-d394-569c-8f45-49f79fcac5c6"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "0e9545ba-3f35-52b0-9a31-7fe54c933928"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "898957c6-8cd1-53e5-98f8-c16789aba613"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "366c6a1b-c8b2-5faf-9d2f-72dbde24e869"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "2be66549-5767-51b4-82ed-deeab0723f8d"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "aa90f24c-ee55-515b-b5a2-78bdacbe25a0"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "a56fcb96-f868-5d08-a358-9b403eb3cd16"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "fa32b497-4a4b-5076-a391-b6dffa8e1851"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "0360ab4b-77d5-5fcd-8d60-b7a005c12ceb"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "7d6921a2-7e63-5f12-9c75-dff8b592fabc"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: 4EGRG3P aspect: simple_mdd should be severe",
            "expected": {
                "type": "Severity",
                "value": "severe"
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "bc1523b6-7d57-5471-90cd-884ef1f5ea5a"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "98772fed-cf55-5d42-b4e1-0e6a1a31ce56"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "826538fc-8a97-55e4-8013-fe6a93f8e451"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 28
                },
                "D-15a": {
                    "questionId": "D-15a",
                    "chosenOptionIds": [
                        "7b763ff0-7703-54f7-8eae-aa83417ca133"
                    ]
                },
                "D-16.5a": {
                    "questionId": "D-16.5a",
                    "chosenOptionIds": [
                        "b409dbb9-80ef-5de1-96d0-1075735134db"
                    ]
                },
                "D-17a": {
                    "questionId": "D-17a",
                    "chosenOptionIds": [
                        "8413ed32-e959-5c2a-9d19-8cc68709c34d"
                    ]
                },
                "D-19a": {
                    "questionId": "D-19a",
                    "chosenOptionIds": [
                        "6fde9b5c-ed1c-561c-b516-8f5028a1bcd5"
                    ]
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "80c993c8-1a94-5684-a8f9-d8f5072c8e9c"
                    ]
                },
                "D-1bl": {
                    "questionId": "D-1bl",
                    "chosenOptionIds": [
                        "4105c26a-4ff2-5fd7-984d-f0bcb7b5656e"
                    ]
                },
                "D-2": {
                    "questionId": "D-2",
                    "freeValue": 27
                },
                "D-20a": {
                    "questionId": "D-20a",
                    "chosenOptionIds": [
                        "b28ed556-a386-5556-a96f-ae7708ded1ab"
                    ]
                },
                "D-21a": {
                    "questionId": "D-21a",
                    "chosenOptionIds": [
                        "79fb502d-53ef-5cbb-a08a-c346d1ba9310"
                    ]
                },
                "D-23a": {
                    "questionId": "D-23a",
                    "chosenOptionIds": [
                        "f6cf63c5-0c57-5422-9431-55fec4c93c63"
                    ]
                },
                "D-26a": {
                    "questionId": "D-26a",
                    "chosenOptionIds": [
                        "eb4d6df4-7e45-5437-a36e-540fa95a45f9"
                    ]
                },
                "D-27a": {
                    "questionId": "D-27a",
                    "chosenOptionIds": [
                        "3d120f97-fb12-5e86-8bb9-8fc93babc803"
                    ]
                },
                "D-28a": {
                    "questionId": "D-28a",
                    "chosenOptionIds": [
                        "009661f3-60b5-52e8-bc5f-44c65767874c"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "cc243947-5067-5a98-ade6-a4e10d0b42cf"
                    ]
                },
                "D-2bl": {
                    "questionId": "D-2bl",
                    "chosenOptionIds": [
                        "2a8c96e2-cb5b-596a-99b6-a4885253776c"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "75d33f2f-6fcd-5e09-8574-899eabeb22e6",
                        "fd9c4394-6664-5144-8797-00d27a4dda01"
                    ]
                },
                "D-30a": {
                    "questionId": "D-30a",
                    "chosenOptionIds": [
                        "1f6e9ea6-3402-596b-a518-31105c7a277b"
                    ]
                },
                "D-31a": {
                    "questionId": "D-31a",
                    "chosenOptionIds": [
                        "c78e0def-d73e-5c4e-8800-7c6f92d0a1ec"
                    ]
                },
                "D-32a": {
                    "questionId": "D-32a",
                    "chosenOptionIds": [
                        "ea2a4fbd-008a-571b-bb68-cbd0ba351521"
                    ]
                },
                "D-33a": {
                    "questionId": "D-33a",
                    "chosenOptionIds": [
                        "3968dd70-f060-5cbd-bb6a-d63f702a3691"
                    ]
                },
                "D-34a": {
                    "questionId": "D-34a",
                    "chosenOptionIds": [
                        "9d969817-a8d1-57f5-8c15-5e89835ed2a4"
                    ]
                },
                "D-35a": {
                    "questionId": "D-35a",
                    "chosenOptionIds": [
                        "3a07e052-2332-5a23-bb19-3147a5b9d2c7"
                    ]
                },
                "D-36a": {
                    "questionId": "D-36a",
                    "chosenOptionIds": [
                        "df6b03d1-7322-5277-91ae-7c8e08091a87"
                    ]
                },
                "D-37a": {
                    "questionId": "D-37a",
                    "chosenOptionIds": [
                        "fd4e0608-283c-5f56-a026-edfa381519c9"
                    ]
                },
                "D-38a": {
                    "questionId": "D-38a",
                    "chosenOptionIds": [
                        "a03f02de-f86d-525a-b375-383ad9d3cefe"
                    ]
                },
                "D-39a": {
                    "questionId": "D-39a",
                    "chosenOptionIds": [
                        "756b91dc-7358-504f-aeab-84552f2bf28b"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "f110f5ec-75f1-51be-9c45-ea7685a54d64"
                    ]
                },
                "D-3bl": {
                    "questionId": "D-3bl",
                    "freeValue": 56
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-40a": {
                    "questionId": "D-40a",
                    "chosenOptionIds": [
                        "582119a7-d4a0-58ff-9b1d-2af045e11a7f"
                    ]
                },
                "D-41a": {
                    "questionId": "D-41a",
                    "chosenOptionIds": [
                        "6a5d1a65-0b53-5485-995a-76d8d7eafb1a"
                    ]
                },
                "D-42a": {
                    "questionId": "D-42a",
                    "chosenOptionIds": [
                        "926931de-e675-5117-9e61-78dd541393be"
                    ]
                },
                "D-43a": {
                    "questionId": "D-43a",
                    "chosenOptionIds": [
                        "cd0abe72-b3e8-5c78-8330-7ee4a1657536"
                    ]
                },
                "D-43bt": {
                    "questionId": "D-43bt",
                    "chosenOptionIds": [
                        "644dd944-5a2a-533b-9ef0-fadb5054c422"
                    ]
                },
                "D-44.5a": {
                    "questionId": "D-44.5a",
                    "chosenOptionIds": [
                        "b5fc3288-710a-5aef-9842-611757b40967",
                        "bcf5fb9f-f4b9-5f0c-a964-69a4e1a73334"
                    ]
                },
                "D-44a": {
                    "questionId": "D-44a",
                    "chosenOptionIds": [
                        "68a987a9-8bad-50d7-a4d9-e970334f0460"
                    ]
                },
                "D-45a": {
                    "questionId": "D-45a",
                    "freeValue": 21
                },
                "D-46a": {
                    "questionId": "D-46a",
                    "chosenOptionIds": [
                        "c2c0e745-9e7b-5f61-8565-fbc0d5c49f71",
                        "1fd426df-808a-5c68-9b6e-23ff2f128b7d",
                        "d9665b1f-cdd2-5979-895e-27bca6487533",
                        "7492eab7-c625-52d0-a2e3-4bf3332decc0",
                        "4c178978-f79a-58fc-8e73-3a98614c4b6c",
                        "6e49f368-62af-5abb-9034-a58bd534944f",
                        "b3e43bd8-fbe3-5cda-b891-6f11e43993d0",
                        "f0b15a45-ae49-59d2-97a7-33f866b0e629"
                    ]
                },
                "D-49.1a": {
                    "questionId": "D-49.1a",
                    "chosenOptionIds": [
                        "7108af97-c956-5da8-8dfa-518e346c197c"
                    ]
                },
                "D-49.2a": {
                    "questionId": "D-49.2a",
                    "freeValue": 350
                },
                "D-49a": {
                    "questionId": "D-49a",
                    "chosenOptionIds": [
                        "16dc6af1-32a9-59c5-b66c-57f6becea1e0"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b",
                        "1d4beed1-78e5-531c-a255-643fac260dee",
                        "9d1b858c-7064-5a84-80d6-786c18e603b6"
                    ]
                },
                "D-50a": {
                    "questionId": "D-50a",
                    "chosenOptionIds": [
                        "d14da42d-9612-5827-9583-b2bf528130de"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "dde1af45-4686-52c9-b960-369527b28f6c"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "08a0f83f-2f37-5904-9364-4e40deb90e98"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "5d271ac0-beca-56cc-9d0b-adafcd67da53"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "33a5fff0-9587-521f-bc53-0dacc36a0bb2"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "44fd9eb3-aee2-5af0-b83c-dbbbf9514022",
                        "4896b504-2025-52c3-85e2-c6cf3205cef7",
                        "07106ab9-5949-5b5f-bc96-117139ebd397",
                        "afcb6534-a0a0-5fe6-9729-1269be20cdf3"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "83e6efd5-64a2-5f0e-a973-9278eae90cfb"
                    ]
                },
                "BP10": {
                    "questionId": "BP10",
                    "chosenOptionIds": [
                        "7ad54f0a-87a6-5e82-bfc3-44b540230082"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "4a0f1c4d-38cf-5de4-99e3-0ee818f9709c"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "6a5aa899-7b89-5661-a554-71b6058ad4a4"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "1daccd4c-e5ff-5f18-ba55-7c2d2d93b849"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "b24df75b-6bb9-5f5f-99e3-c883ec3644ec"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 26
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "c7bee7a0-3037-5eb1-96a1-85b884d7e1dd"
                    ]
                },
                "BP3.01": {
                    "questionId": "BP3.01",
                    "freeValue": 2
                },
                "BP3.1": {
                    "questionId": "BP3.1",
                    "chosenOptionIds": [
                        "c31cf816-04e0-52f4-8da3-04437042c014"
                    ]
                },
                "BP3.2": {
                    "questionId": "BP3.2",
                    "chosenOptionIds": [
                        "ec333f8b-146a-5700-a15d-527032ceac3f"
                    ]
                },
                "BP3.3": {
                    "questionId": "BP3.3",
                    "freeValue": 2
                },
                "BP3.4": {
                    "questionId": "BP3.4",
                    "chosenOptionIds": [
                        "f304bf93-0c68-5da0-80ba-ccecc2d0fce1"
                    ]
                },
                "BP4": {
                    "questionId": "BP4",
                    "chosenOptionIds": [
                        "9765aba8-7d55-5928-b79d-36aa58646036"
                    ]
                },
                "BP4.5": {
                    "questionId": "BP4.5",
                    "chosenOptionIds": [
                        "66a3460c-65ce-5a4c-9774-2e303ae4961e"
                    ]
                },
                "BP6": {
                    "questionId": "BP6",
                    "chosenOptionIds": [
                        "0f2295c8-9598-5e68-a9a6-c87ca1f600af"
                    ]
                },
                "BP6.1": {
                    "questionId": "BP6.1",
                    "chosenOptionIds": [
                        "430b68c6-29cf-5a15-ab4c-89bd842383bf"
                    ]
                },
                "BP6.2": {
                    "questionId": "BP6.2",
                    "freeValue": 2
                },
                "BP6.3": {
                    "questionId": "BP6.3",
                    "chosenOptionIds": [
                        "a19e0696-e780-5235-88b9-f5c36ce0afc3"
                    ]
                },
                "BP8": {
                    "questionId": "BP8",
                    "chosenOptionIds": [
                        "9b3c44f8-4468-5f37-86e9-ed0908e71d5c"
                    ]
                },
                "BP8.0": {
                    "questionId": "BP8.0",
                    "chosenOptionIds": [
                        "3568cdb1-2500-512b-aada-2547c5bfd835"
                    ]
                },
                "BP8.01": {
                    "questionId": "BP8.01",
                    "chosenOptionIds": [
                        "5020bfdd-a280-56cf-b7f5-3b0b7e667165"
                    ]
                },
                "BP8.02": {
                    "questionId": "BP8.02",
                    "chosenOptionIds": [
                        "e88670a3-c6d8-5c96-914e-b9fc1818aeda"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "387b19c0-67d0-557e-9fba-ae065dcf3587"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "f501dc76-7fcd-586b-ba1d-ad70ab8e8d33"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "2404146e-cd56-5356-a23b-6427b4bbba06"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "fd30dc27-8e89-5d1a-9d95-8844603d4379"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "33e48f82-ae80-5f20-a3a5-79692d21ad1a"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "cc7c09ae-1cb7-5b9a-9cd3-8d1012b90ee8"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 42
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "ef5a7b22-b2b2-5a5b-8a80-ce5c251033fd"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "f35fe383-d2aa-51c4-9fde-961b1de6d52b"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "2555d898-6736-5804-951e-e3c438313029"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "de2ea429-4fcd-53b8-8d9f-ebb69a637a02"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "9b27e3ff-f8ce-54c5-9220-0479ae53e889"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "79075051-dcb3-5321-9f27-b21697f8f194"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "3cf77140-2757-58eb-b4ea-f2a90cd766e7"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "5c06d516-e816-58d6-997e-f8fa91131ac7"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "a020ed85-ec9d-5778-8006-41f634cab09d"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM2": {
                    "questionId": "HM2",
                    "chosenOptionIds": [
                        "de662c3f-66b4-566f-856d-2124e2725542"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "98ea07a0-ae95-570f-8d11-a3e7c682e986"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "696fdd9d-5ced-504b-a933-bb3e575b0099"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c41974b6-d394-569c-8f45-49f79fcac5c6"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "43c32319-c75f-5169-b0e5-713820721d4f"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "b2cb304f-f035-53fc-8763-e2f91b031f9b"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "0170b4cf-0541-53cd-a9db-818d5067dc23"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "efcb2ed0-864a-521d-ac0d-99e0fd4b38f5"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "2a17c2fe-2592-58b1-98e9-3dc4168fe3e3"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "0f2a6025-0d8f-5d89-81a2-c3cc425cd7c4"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "fda00d52-c8be-5b69-bdbd-905c13dddd4e"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "8823c74f-ff7c-52d8-9c73-fd3b4180f48a"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "1d07911e-929d-5719-aeea-a68f593d18e8"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: XE72M0L aspect: simple_mdd should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "bc1523b6-7d57-5471-90cd-884ef1f5ea5a"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "6ede390f-0e43-50e4-82e9-1203db9d8343"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "a0e528ca-2fbb-5da1-9398-0758f79755aa"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 1825
                },
                "D-15a": {
                    "questionId": "D-15a",
                    "chosenOptionIds": [
                        "7b763ff0-7703-54f7-8eae-aa83417ca133"
                    ]
                },
                "D-16.5a": {
                    "questionId": "D-16.5a",
                    "chosenOptionIds": [
                        "b409dbb9-80ef-5de1-96d0-1075735134db"
                    ]
                },
                "D-17a": {
                    "questionId": "D-17a",
                    "chosenOptionIds": [
                        "495d3d07-8a1f-5213-bf79-59443d7aa5cf"
                    ]
                },
                "D-18a": {
                    "questionId": "D-18a",
                    "chosenOptionIds": [
                        "1586e7ea-daf7-5a6d-806f-aee9cc02b7e7"
                    ]
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "80c993c8-1a94-5684-a8f9-d8f5072c8e9c"
                    ]
                },
                "D-1bl": {
                    "questionId": "D-1bl",
                    "chosenOptionIds": [
                        "4105c26a-4ff2-5fd7-984d-f0bcb7b5656e"
                    ]
                },
                "D-2": {
                    "questionId": "D-2",
                    "freeValue": 18
                },
                "D-20a": {
                    "questionId": "D-20a",
                    "chosenOptionIds": [
                        "15c694bd-3089-51d8-8fff-6286e2113f9c"
                    ]
                },
                "D-21a": {
                    "questionId": "D-21a",
                    "chosenOptionIds": [
                        "79fb502d-53ef-5cbb-a08a-c346d1ba9310"
                    ]
                },
                "D-23a": {
                    "questionId": "D-23a",
                    "chosenOptionIds": [
                        "44fe96b8-192b-5b6b-88fa-9c2f7ba73a6d"
                    ]
                },
                "D-26a": {
                    "questionId": "D-26a",
                    "chosenOptionIds": [
                        "eb4d6df4-7e45-5437-a36e-540fa95a45f9"
                    ]
                },
                "D-27a": {
                    "questionId": "D-27a",
                    "chosenOptionIds": [
                        "13a1b08d-9817-5c2f-96cb-f8d03b80a37d"
                    ]
                },
                "D-28a": {
                    "questionId": "D-28a",
                    "chosenOptionIds": [
                        "009661f3-60b5-52e8-bc5f-44c65767874c"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "cc243947-5067-5a98-ade6-a4e10d0b42cf"
                    ]
                },
                "D-2bl": {
                    "questionId": "D-2bl",
                    "chosenOptionIds": [
                        "2a8c96e2-cb5b-596a-99b6-a4885253776c"
                    ]
                },
                "D-2bs": {
                    "questionId": "D-2bs",
                    "chosenOptionIds": [
                        "d9fc40d3-aa51-587a-a54d-305ad26f67ae"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "fd9c4394-6664-5144-8797-00d27a4dda01"
                    ]
                },
                "D-30a": {
                    "questionId": "D-30a",
                    "chosenOptionIds": [
                        "1f6e9ea6-3402-596b-a518-31105c7a277b"
                    ]
                },
                "D-31a": {
                    "questionId": "D-31a",
                    "chosenOptionIds": [
                        "c78e0def-d73e-5c4e-8800-7c6f92d0a1ec"
                    ]
                },
                "D-32a": {
                    "questionId": "D-32a",
                    "chosenOptionIds": [
                        "ea2a4fbd-008a-571b-bb68-cbd0ba351521"
                    ]
                },
                "D-33a": {
                    "questionId": "D-33a",
                    "chosenOptionIds": [
                        "3968dd70-f060-5cbd-bb6a-d63f702a3691"
                    ]
                },
                "D-34a": {
                    "questionId": "D-34a",
                    "chosenOptionIds": [
                        "9d969817-a8d1-57f5-8c15-5e89835ed2a4"
                    ]
                },
                "D-35a": {
                    "questionId": "D-35a",
                    "chosenOptionIds": [
                        "45d31a47-6275-5105-b7d4-eea88b6ba775"
                    ]
                },
                "D-36a": {
                    "questionId": "D-36a",
                    "chosenOptionIds": [
                        "df6b03d1-7322-5277-91ae-7c8e08091a87"
                    ]
                },
                "D-37a": {
                    "questionId": "D-37a",
                    "chosenOptionIds": [
                        "dc9f1b80-2303-5ac8-86ff-e9a39a6c0e16"
                    ]
                },
                "D-38a": {
                    "questionId": "D-38a",
                    "chosenOptionIds": [
                        "cfa17d19-5b15-56ef-82c1-07949e21dd81"
                    ]
                },
                "D-39a": {
                    "questionId": "D-39a",
                    "chosenOptionIds": [
                        "ae31125f-c3db-5082-ae70-4329a2508744"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "f110f5ec-75f1-51be-9c45-ea7685a54d64"
                    ]
                },
                "D-3bl": {
                    "questionId": "D-3bl",
                    "freeValue": 1825
                },
                "D-3bs": {
                    "questionId": "D-3bs",
                    "chosenOptionIds": [
                        "c4422e8a-6a0a-51ea-8592-9157036922d1"
                    ]
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-40a": {
                    "questionId": "D-40a",
                    "chosenOptionIds": [
                        "08157bf8-4ab6-5b83-a59a-9f27ee75995d"
                    ]
                },
                "D-41a": {
                    "questionId": "D-41a",
                    "chosenOptionIds": [
                        "6a5d1a65-0b53-5485-995a-76d8d7eafb1a"
                    ]
                },
                "D-42a": {
                    "questionId": "D-42a",
                    "chosenOptionIds": [
                        "926931de-e675-5117-9e61-78dd541393be"
                    ]
                },
                "D-43a": {
                    "questionId": "D-43a",
                    "chosenOptionIds": [
                        "22b1ff23-c3a7-56b8-8108-65eacd7d3fbe"
                    ]
                },
                "D-43bt": {
                    "questionId": "D-43bt",
                    "chosenOptionIds": [
                        "90e60395-fc3d-56cb-b6f7-2e24fba02803"
                    ]
                },
                "D-44.5a": {
                    "questionId": "D-44.5a",
                    "chosenOptionIds": [
                        "bcf5fb9f-f4b9-5f0c-a964-69a4e1a73334"
                    ]
                },
                "D-44a": {
                    "questionId": "D-44a",
                    "chosenOptionIds": [
                        "68a987a9-8bad-50d7-a4d9-e970334f0460"
                    ]
                },
                "D-45a": {
                    "questionId": "D-45a",
                    "freeValue": 1460
                },
                "D-46a": {
                    "questionId": "D-46a",
                    "chosenOptionIds": [
                        "1fd426df-808a-5c68-9b6e-23ff2f128b7d",
                        "d9665b1f-cdd2-5979-895e-27bca6487533",
                        "7492eab7-c625-52d0-a2e3-4bf3332decc0",
                        "4c178978-f79a-58fc-8e73-3a98614c4b6c",
                        "6e49f368-62af-5abb-9034-a58bd534944f",
                        "b3e43bd8-fbe3-5cda-b891-6f11e43993d0",
                        "f0b15a45-ae49-59d2-97a7-33f866b0e629"
                    ]
                },
                "D-49.1a": {
                    "questionId": "D-49.1a",
                    "chosenOptionIds": [
                        "7108af97-c956-5da8-8dfa-518e346c197c"
                    ]
                },
                "D-49.2a": {
                    "questionId": "D-49.2a",
                    "freeValue": 100
                },
                "D-49a": {
                    "questionId": "D-49a",
                    "chosenOptionIds": [
                        "16dc6af1-32a9-59c5-b66c-57f6becea1e0"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "ba6dc2ba-3c20-57b6-a276-32c863109ff6"
                    ]
                },
                "D-4bs": {
                    "questionId": "D-4bs",
                    "freeValue": 1460
                },
                "D-50a": {
                    "questionId": "D-50a",
                    "chosenOptionIds": [
                        "d14da42d-9612-5827-9583-b2bf528130de"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "188a7831-38c4-55c4-880a-1e4db4e9bab1"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "3e093dd3-857e-5004-a4fb-961fbfa59ece"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "5d271ac0-beca-56cc-9d0b-adafcd67da53"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "67316d94-1cf0-5bd0-a4db-f57bc70684ba"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "60fe0bb4-d770-56f2-b05a-864625a48e4d"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "83e6efd5-64a2-5f0e-a973-9278eae90cfb"
                    ]
                },
                "BP10": {
                    "questionId": "BP10",
                    "chosenOptionIds": [
                        "7ad54f0a-87a6-5e82-bfc3-44b540230082"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "182e121d-415c-5f42-a76c-a996f5f38b3f"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "1c4ed441-5919-569e-98ba-b49be1bc3957"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "e76cac02-f6b2-5ab6-a467-e3025d857ab9"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "f2228920-3fec-5097-a63d-990c25d50c6c"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP105": {
                    "questionId": "BP105",
                    "chosenOptionIds": [
                        "4e9d21b3-29c7-5526-ada9-67b61957994e"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 24
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "c7bee7a0-3037-5eb1-96a1-85b884d7e1dd"
                    ]
                },
                "BP3.01": {
                    "questionId": "BP3.01",
                    "freeValue": 100
                },
                "BP3.1": {
                    "questionId": "BP3.1",
                    "chosenOptionIds": [
                        "11a8ab35-b2d7-55a4-8a77-809dc8f1f78a"
                    ]
                },
                "BP3.2": {
                    "questionId": "BP3.2",
                    "chosenOptionIds": [
                        "ec333f8b-146a-5700-a15d-527032ceac3f"
                    ]
                },
                "BP3.3": {
                    "questionId": "BP3.3",
                    "freeValue": 14
                },
                "BP3.4": {
                    "questionId": "BP3.4",
                    "chosenOptionIds": [
                        "f304bf93-0c68-5da0-80ba-ccecc2d0fce1"
                    ]
                },
                "BP4": {
                    "questionId": "BP4",
                    "chosenOptionIds": [
                        "9765aba8-7d55-5928-b79d-36aa58646036"
                    ]
                },
                "BP4.5": {
                    "questionId": "BP4.5",
                    "chosenOptionIds": [
                        "1be4b3b1-3a88-5544-af54-ebdfee1ce891"
                    ]
                },
                "BP6": {
                    "questionId": "BP6",
                    "chosenOptionIds": [
                        "33ae5abe-63ad-57d4-86a4-0dcd33576094"
                    ]
                },
                "BP6.1": {
                    "questionId": "BP6.1",
                    "chosenOptionIds": [
                        "f0b738f5-44c3-5a6c-9a31-ab7878568a81"
                    ]
                },
                "BP6.2": {
                    "questionId": "BP6.2",
                    "freeValue": 14
                },
                "BP6.3": {
                    "questionId": "BP6.3",
                    "chosenOptionIds": [
                        "a19e0696-e780-5235-88b9-f5c36ce0afc3"
                    ]
                },
                "BP8": {
                    "questionId": "BP8",
                    "chosenOptionIds": [
                        "87e0081b-9455-5ced-9225-10c2f70b1734"
                    ]
                },
                "BP8.0": {
                    "questionId": "BP8.0",
                    "chosenOptionIds": [
                        "3568cdb1-2500-512b-aada-2547c5bfd835"
                    ]
                },
                "BP8.01": {
                    "questionId": "BP8.01",
                    "chosenOptionIds": [
                        "5020bfdd-a280-56cf-b7f5-3b0b7e667165"
                    ]
                },
                "BP8.02": {
                    "questionId": "BP8.02",
                    "chosenOptionIds": [
                        "e88670a3-c6d8-5c96-914e-b9fc1818aeda"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "960a08ff-7af7-56da-afda-5415a1654c85"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "a4ed572c-6aae-57f6-bc35-ea46929c1437"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "2404146e-cd56-5356-a23b-6427b4bbba06"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "fd30dc27-8e89-5d1a-9d95-8844603d4379"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "33e48f82-ae80-5f20-a3a5-79692d21ad1a"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "46c90446-3ae9-53f5-9b57-ad19d66b5be6"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 1460
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "ef5a7b22-b2b2-5a5b-8a80-ce5c251033fd"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "940c16a8-1835-582a-9dad-bd52e3a49cc7"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "1e86d64a-b972-500a-9120-7884e4b86ede"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "6928c696-e6d7-5a14-a004-15a497ac6562"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "2723ed09-37f6-522c-92b1-e518d4fe9315"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "cfb8fe0e-821a-554a-bb57-922f0ecc0f44"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "63509127-aadf-5fe2-8e1f-19d0d846d6ce"
                    ]
                },
                "BP99.1": {
                    "questionId": "BP99.1",
                    "chosenOptionIds": [
                        "34d19f12-207f-56ce-bde8-8d616f6e8b26"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "e81fe613-4923-55ed-bd6c-bd3f19e026f9"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM2": {
                    "questionId": "HM2",
                    "chosenOptionIds": [
                        "a046d610-d27a-59c1-b130-38312005cf1c"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "c3ee8981-dec3-5b3c-80ca-c1023f983a6d"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "a43647cb-a0e8-5705-adb9-a2b68877dc77"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c41974b6-d394-569c-8f45-49f79fcac5c6"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "43c32319-c75f-5169-b0e5-713820721d4f"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "d2c670c4-b05b-597a-a0af-5342230535a0"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "cec918d6-b855-5672-94a8-cf14e5ae963a"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "7b310da1-94e3-5d45-a6c3-3905eeb28ec6"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "2a17c2fe-2592-58b1-98e9-3dc4168fe3e3"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "7e32475a-3fcc-51ac-b414-ab46578efdfd"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "fda00d52-c8be-5b69-bdbd-905c13dddd4e"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "e95276b0-ee81-580b-b532-b0215f04abd5"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "4b8e4843-d260-59e5-8468-1303b1c3a638"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: 7EW6KE8 aspect: simple_mdd should be severe",
            "expected": {
                "type": "Severity",
                "value": "severe"
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "3bd18f66-6f1e-5ea4-ae24-2990ed197bef"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "98772fed-cf55-5d42-b4e1-0e6a1a31ce56"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "a0e528ca-2fbb-5da1-9398-0758f79755aa"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 1825
                },
                "D-15a": {
                    "questionId": "D-15a",
                    "chosenOptionIds": [
                        "7b763ff0-7703-54f7-8eae-aa83417ca133"
                    ]
                },
                "D-16.5a": {
                    "questionId": "D-16.5a",
                    "chosenOptionIds": [
                        "b409dbb9-80ef-5de1-96d0-1075735134db"
                    ]
                },
                "D-17a": {
                    "questionId": "D-17a",
                    "chosenOptionIds": [
                        "8413ed32-e959-5c2a-9d19-8cc68709c34d"
                    ]
                },
                "D-19a": {
                    "questionId": "D-19a",
                    "chosenOptionIds": [
                        "6fde9b5c-ed1c-561c-b516-8f5028a1bcd5"
                    ]
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "7f1d6182-f8a7-5f4e-a0f8-2556b306ae36"
                    ]
                },
                "D-1bl": {
                    "questionId": "D-1bl",
                    "chosenOptionIds": [
                        "87ef50c9-ba5e-513e-8889-09d8395d4b9f"
                    ]
                },
                "D-2": {
                    "questionId": "D-2",
                    "freeValue": 25
                },
                "D-20a": {
                    "questionId": "D-20a",
                    "chosenOptionIds": [
                        "15c694bd-3089-51d8-8fff-6286e2113f9c"
                    ]
                },
                "D-21a": {
                    "questionId": "D-21a",
                    "chosenOptionIds": [
                        "dc2b002f-2d96-504d-bde9-66faceececb6"
                    ]
                },
                "D-23a": {
                    "questionId": "D-23a",
                    "chosenOptionIds": [
                        "f6cf63c5-0c57-5422-9431-55fec4c93c63"
                    ]
                },
                "D-26a": {
                    "questionId": "D-26a",
                    "chosenOptionIds": [
                        "400d260d-4647-5f4d-8105-894b04c366f2"
                    ]
                },
                "D-27a": {
                    "questionId": "D-27a",
                    "chosenOptionIds": [
                        "8896026c-15ab-577e-8c44-e63eb7e6dba3"
                    ]
                },
                "D-28a": {
                    "questionId": "D-28a",
                    "chosenOptionIds": [
                        "ffc9781f-639e-5caa-9d2e-16250d30bf6a"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "c695fd1c-2cb7-5654-b9f7-b32ecb158229"
                    ]
                },
                "D-2bl": {
                    "questionId": "D-2bl",
                    "chosenOptionIds": [
                        "2a8c96e2-cb5b-596a-99b6-a4885253776c"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "e797900f-129d-553e-b83f-8494d7f03986"
                    ]
                },
                "D-30a": {
                    "questionId": "D-30a",
                    "chosenOptionIds": [
                        "d309cef4-2204-5a10-a98d-b66740336838"
                    ]
                },
                "D-31a": {
                    "questionId": "D-31a",
                    "chosenOptionIds": [
                        "eba65e81-9b02-55d6-82cb-0b7ce6da41de"
                    ]
                },
                "D-32a": {
                    "questionId": "D-32a",
                    "chosenOptionIds": [
                        "ea2a4fbd-008a-571b-bb68-cbd0ba351521"
                    ]
                },
                "D-33a": {
                    "questionId": "D-33a",
                    "chosenOptionIds": [
                        "3968dd70-f060-5cbd-bb6a-d63f702a3691"
                    ]
                },
                "D-34a": {
                    "questionId": "D-34a",
                    "chosenOptionIds": [
                        "6bfab4b8-7d57-5039-8933-4dee0c958a19"
                    ]
                },
                "D-35a": {
                    "questionId": "D-35a",
                    "chosenOptionIds": [
                        "bcace469-f380-599d-a33a-26db6d0053ad"
                    ]
                },
                "D-36a": {
                    "questionId": "D-36a",
                    "chosenOptionIds": [
                        "4ab32dca-be9e-573e-a330-24f9350ffaca"
                    ]
                },
                "D-37a": {
                    "questionId": "D-37a",
                    "chosenOptionIds": [
                        "c8ebfb14-b9aa-5da4-8de3-ab8b83254249"
                    ]
                },
                "D-38a": {
                    "questionId": "D-38a",
                    "chosenOptionIds": [
                        "8d5bbc66-0b33-5de5-838e-ff0fccbb6fbc"
                    ]
                },
                "D-39a": {
                    "questionId": "D-39a",
                    "chosenOptionIds": [
                        "a2f071cb-74be-518d-a33d-12a101ac9770"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "db7514e2-d317-55fb-82d3-2e4a49c2494b"
                    ]
                },
                "D-3bl": {
                    "questionId": "D-3bl",
                    "freeValue": 60
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-40a": {
                    "questionId": "D-40a",
                    "chosenOptionIds": [
                        "08157bf8-4ab6-5b83-a59a-9f27ee75995d"
                    ]
                },
                "D-41a": {
                    "questionId": "D-41a",
                    "chosenOptionIds": [
                        "cb89e9b0-6872-5961-bc91-049308b5860f"
                    ]
                },
                "D-42a": {
                    "questionId": "D-42a",
                    "chosenOptionIds": [
                        "7099f8a9-4650-5da2-a627-bcd62cd99375"
                    ]
                },
                "D-43a": {
                    "questionId": "D-43a",
                    "chosenOptionIds": [
                        "cd0abe72-b3e8-5c78-8330-7ee4a1657536"
                    ]
                },
                "D-43bt": {
                    "questionId": "D-43bt",
                    "chosenOptionIds": [
                        "644dd944-5a2a-533b-9ef0-fadb5054c422"
                    ]
                },
                "D-44.5a": {
                    "questionId": "D-44.5a",
                    "chosenOptionIds": [
                        "b5fc3288-710a-5aef-9842-611757b40967",
                        "884767e3-ed0c-5db3-a227-f84f7ecaf8d3"
                    ]
                },
                "D-44a": {
                    "questionId": "D-44a",
                    "chosenOptionIds": [
                        "57c0e55d-5ee4-5237-9c5d-e317b90c5dbd"
                    ]
                },
                "D-45a": {
                    "questionId": "D-45a",
                    "freeValue": 0
                },
                "D-46a": {
                    "questionId": "D-46a",
                    "chosenOptionIds": [
                        "1fd426df-808a-5c68-9b6e-23ff2f128b7d",
                        "d9665b1f-cdd2-5979-895e-27bca6487533",
                        "7492eab7-c625-52d0-a2e3-4bf3332decc0",
                        "6e49f368-62af-5abb-9034-a58bd534944f",
                        "4c178978-f79a-58fc-8e73-3a98614c4b6c",
                        "b3e43bd8-fbe3-5cda-b891-6f11e43993d0",
                        "f0b15a45-ae49-59d2-97a7-33f866b0e629"
                    ]
                },
                "D-49.1a": {
                    "questionId": "D-49.1a",
                    "chosenOptionIds": [
                        "7108af97-c956-5da8-8dfa-518e346c197c"
                    ]
                },
                "D-49.2a": {
                    "questionId": "D-49.2a",
                    "freeValue": 7
                },
                "D-49a": {
                    "questionId": "D-49a",
                    "chosenOptionIds": [
                        "16dc6af1-32a9-59c5-b66c-57f6becea1e0"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b"
                    ]
                },
                "D-50a": {
                    "questionId": "D-50a",
                    "chosenOptionIds": [
                        "d14da42d-9612-5827-9583-b2bf528130de"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "dde1af45-4686-52c9-b960-369527b28f6c"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "08a0f83f-2f37-5904-9364-4e40deb90e98"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "772bee29-c3a5-52e1-b785-a77d506ae4fb"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "67316d94-1cf0-5bd0-a4db-f57bc70684ba"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "f8e2518c-72cf-563b-b322-a03406c8ebac"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "60fe0bb4-d770-56f2-b05a-864625a48e4d"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "7599bcaa-89a5-5f71-80ea-b70c360bb44a"
                    ]
                },
                "BP101b": {
                    "questionId": "BP101b",
                    "chosenOptionIds": [
                        "31b2d2c8-8f28-514e-9e58-aa878f6c8761"
                    ]
                },
                "BP102b": {
                    "questionId": "BP102b",
                    "chosenOptionIds": [
                        "00afc1e6-b78a-5223-a6e7-5d3aaab833e0"
                    ]
                },
                "BP103b": {
                    "questionId": "BP103b",
                    "chosenOptionIds": [
                        "867b0ee4-73d9-5aac-b96d-bc2d85d5b183"
                    ]
                },
                "BP104b": {
                    "questionId": "BP104b",
                    "chosenOptionIds": [
                        "c59b5910-3279-509f-8175-f2fd0da2dc86"
                    ]
                },
                "BP87b": {
                    "questionId": "BP87b",
                    "chosenOptionIds": [
                        "fb568891-2ea3-5200-bbdb-ac0695f30b02"
                    ]
                },
                "BP88b": {
                    "questionId": "BP88b",
                    "chosenOptionIds": [
                        "e29e159f-f691-5aa7-b5a0-d13fe2c54170"
                    ]
                },
                "BP89b": {
                    "questionId": "BP89b",
                    "chosenOptionIds": [
                        "db509963-d872-5b97-ba9b-a276f58e0189"
                    ]
                },
                "BP90b": {
                    "questionId": "BP90b",
                    "chosenOptionIds": [
                        "b4a79888-68e8-5b52-9a4e-2149be85f6e7"
                    ]
                },
                "BP92b": {
                    "questionId": "BP92b",
                    "chosenOptionIds": [
                        "f59918ba-eb3a-5cf1-81aa-8b7f6ac0df5e"
                    ]
                },
                "BP94b": {
                    "questionId": "BP94b",
                    "chosenOptionIds": [
                        "1f4fafbf-913e-5edc-bfaf-3baae49fc91d"
                    ]
                },
                "BP95b": {
                    "questionId": "BP95b",
                    "chosenOptionIds": [
                        "32255970-db0f-5cc0-a199-d412edf7ab7c"
                    ]
                },
                "BP96b": {
                    "questionId": "BP96b",
                    "chosenOptionIds": [
                        "8c3d3503-6334-55b9-b0f0-94c8e520094e"
                    ]
                },
                "BP97b": {
                    "questionId": "BP97b",
                    "chosenOptionIds": [
                        "8fa0f169-f510-5c9e-a4be-fbcc964f7480"
                    ]
                },
                "BP98b": {
                    "questionId": "BP98b",
                    "chosenOptionIds": [
                        "1385fe83-520e-5986-8bec-9f0630d48219"
                    ]
                },
                "BP99.1b": {
                    "questionId": "BP99.1b",
                    "chosenOptionIds": [
                        "474c64d7-23b9-5015-9cdd-60661ad38d39"
                    ]
                },
                "BP99.2b": {
                    "questionId": "BP99.2b",
                    "chosenOptionIds": [
                        "32f6041f-9e2c-537c-9db7-19f89011fce9"
                    ]
                },
                "BP99.3b": {
                    "questionId": "BP99.3b",
                    "chosenOptionIds": [
                        "bf30a6c2-f8e2-5305-b4d4-7ad6227449d3"
                    ]
                },
                "BP99b": {
                    "questionId": "BP99b",
                    "chosenOptionIds": [
                        "96faf550-f157-55b9-8f15-6be82cd511a8",
                        "3f987aaa-e435-55a9-9c1b-aa6d2e476738"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "BP_neg1": {
                    "questionId": "BP_neg1",
                    "chosenOptionIds": [
                        "119208b0-ff3b-5c43-80be-4f07e65b6248"
                    ]
                }
            }
        },
        {
            "name": "Mood test: Y32LM08 aspect: simple_mdd should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "c61d70d4-b773-54dd-94d0-d858a47be0fb"
                    ]
                },
                "D-1.5b": {
                    "questionId": "D-1.5b",
                    "freeValue": 4
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "45f0ce85-9e19-53da-bc0d-2af8b383318a"
                    ]
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "7a5958fc-e0f7-5d43-9cd0-abfe95cb7026"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-1bl": {
                    "questionId": "D-1bl",
                    "chosenOptionIds": [
                        "5da400b0-5bff-5744-a664-4305892ae669"
                    ]
                },
                "D-2": {
                    "questionId": "D-2",
                    "freeValue": 28
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "9222be3e-443f-5146-9d69-d48fe9041ba0"
                    ]
                },
                "D-2bl": {
                    "questionId": "D-2bl",
                    "chosenOptionIds": [
                        "2a8c96e2-cb5b-596a-99b6-a4885253776c"
                    ]
                },
                "D-2bt": {
                    "questionId": "D-2bt",
                    "chosenOptionIds": [
                        "90222879-e5d9-50a0-bfbe-a5cbadbff017"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "fd9c4394-6664-5144-8797-00d27a4dda01"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "77c6088d-2d8f-5c3b-89c9-79cb0d317226"
                    ]
                },
                "D-3bl": {
                    "questionId": "D-3bl",
                    "freeValue": 28
                },
                "D-3bt": {
                    "questionId": "D-3bt",
                    "chosenOptionIds": [
                        "b9abc28d-6e22-5b67-bc5e-27431e123974"
                    ]
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-42bt": {
                    "questionId": "D-42bt",
                    "freeValue": 0
                },
                "D-43bt": {
                    "questionId": "D-43bt",
                    "chosenOptionIds": [
                        "644dd944-5a2a-533b-9ef0-fadb5054c422"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "a449e4ae-663b-50fb-9b62-1708b0a32669"
                    ]
                },
                "D-4bt": {
                    "questionId": "D-4bt",
                    "freeValue": 21
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "f3efc764-ca53-52c3-9c3a-38c7f787d415"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "9506449e-b2aa-5498-b896-901dd248b1b3"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "ef6fddab-7a07-5f20-af20-74f2444eee53"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "3b216cf8-72e7-558c-bdd3-cc23a1865674"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "D-s10bt": {
                    "questionId": "D-s10bt",
                    "chosenOptionIds": [
                        "3deea42c-5d93-54cd-ba0f-4242654c3747"
                    ]
                },
                "D-s1bt": {
                    "questionId": "D-s1bt",
                    "chosenOptionIds": [
                        "33dd907f-3e0e-5735-96bb-c101f0acced9"
                    ]
                },
                "D-s2bt": {
                    "questionId": "D-s2bt",
                    "chosenOptionIds": [
                        "e074592a-4667-5b65-b713-b8892451b433"
                    ]
                },
                "D-s3bt": {
                    "questionId": "D-s3bt",
                    "chosenOptionIds": [
                        "11f57889-ac93-53ab-88a7-9aea03a29429"
                    ]
                },
                "D-s4bt": {
                    "questionId": "D-s4bt",
                    "chosenOptionIds": [
                        "fd9851a0-7ce7-55a8-b257-5617537d0013",
                        "27313271-8ec6-5f99-bb2c-0ed50a7c1527"
                    ]
                },
                "D-s5bt": {
                    "questionId": "D-s5bt",
                    "chosenOptionIds": [
                        "d033bb4c-5d3c-519b-be31-1177042bffb4"
                    ]
                },
                "D-s6bt": {
                    "questionId": "D-s6bt",
                    "chosenOptionIds": [
                        "f7aa2ac0-a087-5371-8266-7601901cd1f9"
                    ]
                },
                "D-s7bt": {
                    "questionId": "D-s7bt",
                    "chosenOptionIds": [
                        "d703f0d6-4b67-5a66-8740-b5e4b3b0eb4d"
                    ]
                },
                "D-s8bt": {
                    "questionId": "D-s8bt",
                    "chosenOptionIds": [
                        "b5d48aea-22b2-52a7-9306-3f2a4bcedc6c"
                    ]
                },
                "D-s9bt": {
                    "questionId": "D-s9bt",
                    "chosenOptionIds": [
                        "da503bbd-e567-576e-a21f-e0fad60dccdd"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "44fd9eb3-aee2-5af0-b83c-dbbbf9514022"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "7599bcaa-89a5-5f71-80ea-b70c360bb44a"
                    ]
                },
                "BP101b": {
                    "questionId": "BP101b",
                    "chosenOptionIds": [
                        "cb80bcac-db78-55bd-b051-bdd00801fd54"
                    ]
                },
                "BP102b": {
                    "questionId": "BP102b",
                    "chosenOptionIds": [
                        "46a64b5b-6641-5b1b-92aa-5366444fb134"
                    ]
                },
                "BP103b": {
                    "questionId": "BP103b",
                    "chosenOptionIds": [
                        "867b0ee4-73d9-5aac-b96d-bc2d85d5b183"
                    ]
                },
                "BP104b": {
                    "questionId": "BP104b",
                    "chosenOptionIds": [
                        "c59b5910-3279-509f-8175-f2fd0da2dc86"
                    ]
                },
                "BP87b": {
                    "questionId": "BP87b",
                    "chosenOptionIds": [
                        "7949bd96-3757-5573-bd89-3a91a383cc99"
                    ]
                },
                "BP88b": {
                    "questionId": "BP88b",
                    "chosenOptionIds": [
                        "fa24b924-a8e1-5f02-893b-3d9643007991"
                    ]
                },
                "BP89b": {
                    "questionId": "BP89b",
                    "chosenOptionIds": [
                        "41d2829a-3971-552f-8c5a-c77e7d0073e7"
                    ]
                },
                "BP90b": {
                    "questionId": "BP90b",
                    "chosenOptionIds": [
                        "95c2822d-27ad-5bb0-982f-8c2b949c8918"
                    ]
                },
                "BP92b": {
                    "questionId": "BP92b",
                    "chosenOptionIds": [
                        "b2f4c490-30df-5c8f-87c0-bc4acbcfb8e0"
                    ]
                },
                "BP94b": {
                    "questionId": "BP94b",
                    "chosenOptionIds": [
                        "038cb12b-2783-569a-b1d7-e28c65e591a3"
                    ]
                },
                "BP95b": {
                    "questionId": "BP95b",
                    "chosenOptionIds": [
                        "fb0bd4a7-2793-5b81-badc-fe81b4bc82dc"
                    ]
                },
                "BP96b": {
                    "questionId": "BP96b",
                    "chosenOptionIds": [
                        "8c3d3503-6334-55b9-b0f0-94c8e520094e"
                    ]
                },
                "BP97b": {
                    "questionId": "BP97b",
                    "chosenOptionIds": [
                        "6895096a-ef29-546e-9967-d7a23b4a2859"
                    ]
                },
                "BP98b": {
                    "questionId": "BP98b",
                    "chosenOptionIds": [
                        "01641419-fbfc-52a5-ae63-0fa736ff9847"
                    ]
                },
                "BP99.3b": {
                    "questionId": "BP99.3b",
                    "chosenOptionIds": [
                        "bf30a6c2-f8e2-5305-b4d4-7ad6227449d3"
                    ]
                },
                "BP99b": {
                    "questionId": "BP99b",
                    "chosenOptionIds": [
                        "68860df1-e835-50d6-8eb2-f9d9f2f97efa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "BP_neg1": {
                    "questionId": "BP_neg1",
                    "chosenOptionIds": [
                        "119208b0-ff3b-5c43-80be-4f07e65b6248"
                    ]
                }
            }
        },
        {
            "name": "Mood test: 63YM43R aspect: simple_mdd should be severe",
            "expected": {
                "type": "Severity",
                "value": "severe"
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "3bd18f66-6f1e-5ea4-ae24-2990ed197bef"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "6ede390f-0e43-50e4-82e9-1203db9d8343"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "826538fc-8a97-55e4-8013-fe6a93f8e451"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 540
                },
                "D-15a": {
                    "questionId": "D-15a",
                    "chosenOptionIds": [
                        "7b763ff0-7703-54f7-8eae-aa83417ca133"
                    ]
                },
                "D-16.5a": {
                    "questionId": "D-16.5a",
                    "chosenOptionIds": [
                        "ae0a4d4e-e9bf-51bf-8506-0cbced38db92"
                    ]
                },
                "D-17a": {
                    "questionId": "D-17a",
                    "chosenOptionIds": [
                        "495d3d07-8a1f-5213-bf79-59443d7aa5cf"
                    ]
                },
                "D-19a": {
                    "questionId": "D-19a",
                    "chosenOptionIds": [
                        "b956a09d-6c39-5acc-a8d7-9024b1b2a2b3"
                    ]
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "7f1d6182-f8a7-5f4e-a0f8-2556b306ae36"
                    ]
                },
                "D-1bl": {
                    "questionId": "D-1bl",
                    "chosenOptionIds": [
                        "4105c26a-4ff2-5fd7-984d-f0bcb7b5656e"
                    ]
                },
                "D-2": {
                    "questionId": "D-2",
                    "freeValue": 30
                },
                "D-20a": {
                    "questionId": "D-20a",
                    "chosenOptionIds": [
                        "795e06c8-4055-5583-af5a-e7eafe89bb56"
                    ]
                },
                "D-21a": {
                    "questionId": "D-21a",
                    "chosenOptionIds": [
                        "e64b46f6-a0c1-5378-95de-f1de59b491b4"
                    ]
                },
                "D-23a": {
                    "questionId": "D-23a",
                    "chosenOptionIds": [
                        "44fe96b8-192b-5b6b-88fa-9c2f7ba73a6d"
                    ]
                },
                "D-26a": {
                    "questionId": "D-26a",
                    "chosenOptionIds": [
                        "eb4d6df4-7e45-5437-a36e-540fa95a45f9"
                    ]
                },
                "D-27a": {
                    "questionId": "D-27a",
                    "chosenOptionIds": [
                        "13a1b08d-9817-5c2f-96cb-f8d03b80a37d"
                    ]
                },
                "D-28a": {
                    "questionId": "D-28a",
                    "chosenOptionIds": [
                        "801353cf-2f72-53db-9179-e7ecc31b1fc1"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "c695fd1c-2cb7-5654-b9f7-b32ecb158229"
                    ]
                },
                "D-2bl": {
                    "questionId": "D-2bl",
                    "chosenOptionIds": [
                        "2a8c96e2-cb5b-596a-99b6-a4885253776c"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "fd9c4394-6664-5144-8797-00d27a4dda01"
                    ]
                },
                "D-30a": {
                    "questionId": "D-30a",
                    "chosenOptionIds": [
                        "d309cef4-2204-5a10-a98d-b66740336838"
                    ]
                },
                "D-31a": {
                    "questionId": "D-31a",
                    "chosenOptionIds": [
                        "c78e0def-d73e-5c4e-8800-7c6f92d0a1ec"
                    ]
                },
                "D-32a": {
                    "questionId": "D-32a",
                    "chosenOptionIds": [
                        "ea2a4fbd-008a-571b-bb68-cbd0ba351521"
                    ]
                },
                "D-33a": {
                    "questionId": "D-33a",
                    "chosenOptionIds": [
                        "3968dd70-f060-5cbd-bb6a-d63f702a3691"
                    ]
                },
                "D-34a": {
                    "questionId": "D-34a",
                    "chosenOptionIds": [
                        "e919c54f-d9a4-5a15-9ae7-8b6b52607a22"
                    ]
                },
                "D-35a": {
                    "questionId": "D-35a",
                    "chosenOptionIds": [
                        "3a07e052-2332-5a23-bb19-3147a5b9d2c7"
                    ]
                },
                "D-36a": {
                    "questionId": "D-36a",
                    "chosenOptionIds": [
                        "7fb54260-7d7e-5c80-809e-584be2bfee10"
                    ]
                },
                "D-37a": {
                    "questionId": "D-37a",
                    "chosenOptionIds": [
                        "fd4e0608-283c-5f56-a026-edfa381519c9"
                    ]
                },
                "D-38a": {
                    "questionId": "D-38a",
                    "chosenOptionIds": [
                        "39b328fc-f944-51dc-ae4d-9aab44697f32"
                    ]
                },
                "D-39a": {
                    "questionId": "D-39a",
                    "chosenOptionIds": [
                        "6325d970-8ab3-5759-b71d-96b5909ec039"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5b6c0a69-6369-53d1-b2e1-5cd6294a9f71"
                    ]
                },
                "D-3bl": {
                    "questionId": "D-3bl",
                    "freeValue": 365
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-40a": {
                    "questionId": "D-40a",
                    "chosenOptionIds": [
                        "7cc72838-ebcf-5802-933c-4100ebc01407"
                    ]
                },
                "D-41a": {
                    "questionId": "D-41a",
                    "chosenOptionIds": [
                        "89f5ff80-0cae-515a-a4d1-02448dbc11b8"
                    ]
                },
                "D-42a": {
                    "questionId": "D-42a",
                    "chosenOptionIds": [
                        "926931de-e675-5117-9e61-78dd541393be"
                    ]
                },
                "D-43a": {
                    "questionId": "D-43a",
                    "chosenOptionIds": [
                        "dc3faaa6-4a47-5998-83e3-4bbff8838194"
                    ]
                },
                "D-43bt": {
                    "questionId": "D-43bt",
                    "chosenOptionIds": [
                        "644dd944-5a2a-533b-9ef0-fadb5054c422"
                    ]
                },
                "D-44.5a": {
                    "questionId": "D-44.5a",
                    "chosenOptionIds": [
                        "b5fc3288-710a-5aef-9842-611757b40967",
                        "d87971dc-d745-5b64-ac37-37fbe1e722ac"
                    ]
                },
                "D-44a": {
                    "questionId": "D-44a",
                    "chosenOptionIds": [
                        "57c0e55d-5ee4-5237-9c5d-e317b90c5dbd"
                    ]
                },
                "D-45a": {
                    "questionId": "D-45a",
                    "freeValue": 15
                },
                "D-46a": {
                    "questionId": "D-46a",
                    "chosenOptionIds": [
                        "1fd426df-808a-5c68-9b6e-23ff2f128b7d",
                        "d9665b1f-cdd2-5979-895e-27bca6487533",
                        "7492eab7-c625-52d0-a2e3-4bf3332decc0",
                        "6e49f368-62af-5abb-9034-a58bd534944f",
                        "f0b15a45-ae49-59d2-97a7-33f866b0e629",
                        "c2c0e745-9e7b-5f61-8565-fbc0d5c49f71"
                    ]
                },
                "D-49.1a": {
                    "questionId": "D-49.1a",
                    "chosenOptionIds": [
                        "7108af97-c956-5da8-8dfa-518e346c197c"
                    ]
                },
                "D-49.2a": {
                    "questionId": "D-49.2a",
                    "freeValue": 2
                },
                "D-49a": {
                    "questionId": "D-49a",
                    "chosenOptionIds": [
                        "16dc6af1-32a9-59c5-b66c-57f6becea1e0"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "9d1b858c-7064-5a84-80d6-786c18e603b6",
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b"
                    ]
                },
                "D-50a": {
                    "questionId": "D-50a",
                    "chosenOptionIds": [
                        "d14da42d-9612-5827-9583-b2bf528130de"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "c9813959-ba61-5220-9e27-659abb256037"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "3e093dd3-857e-5004-a4fb-961fbfa59ece"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "772bee29-c3a5-52e1-b785-a77d506ae4fb"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "4c78ae3e-7de4-545f-aca1-03fa41fc0c23"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "13f8bac1-d0f5-539b-8596-1c55821bea46"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "44fd9eb3-aee2-5af0-b83c-dbbbf9514022",
                        "c2921864-bbf7-5735-9313-848fb9d5a6d1"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "ccbdcbec-96d0-5362-977b-784289f5cfc0"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "4a0f1c4d-38cf-5de4-99e3-0ee818f9709c"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "6a5aa899-7b89-5661-a554-71b6058ad4a4"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "d3224e16-1b0c-5266-9e14-ac72d2b2e988"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "b24df75b-6bb9-5f5f-99e3-c883ec3644ec"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP12": {
                    "questionId": "BP12",
                    "chosenOptionIds": [
                        "1d6299ac-2ac6-51e2-9a3f-13e8d44e652a"
                    ]
                },
                "BP12.5": {
                    "questionId": "BP12.5",
                    "chosenOptionIds": [
                        "cd7ce00f-4e7c-57ac-9c6c-4137efa24c8c"
                    ]
                },
                "BP14": {
                    "questionId": "BP14",
                    "chosenOptionIds": [
                        "a3126449-b062-5872-80a1-5950acf6bfa4"
                    ]
                },
                "BP14.1": {
                    "questionId": "BP14.1",
                    "chosenOptionIds": [
                        "69f9a784-ce6d-5c82-bd3f-4c9c44bf5992"
                    ]
                },
                "BP14.2": {
                    "questionId": "BP14.2",
                    "freeValue": 365
                },
                "BP14.3": {
                    "questionId": "BP14.3",
                    "chosenOptionIds": [
                        "9f5fc152-75fb-59d8-8f40-7a6ca15c69cb"
                    ]
                },
                "BP16": {
                    "questionId": "BP16",
                    "chosenOptionIds": [
                        "fabceeea-8fea-5444-8b67-8f189f366c27"
                    ]
                },
                "BP16.0": {
                    "questionId": "BP16.0",
                    "chosenOptionIds": [
                        "4b26caa5-9124-51b2-ab08-2f4e977cee1f"
                    ]
                },
                "BP16.01": {
                    "questionId": "BP16.01",
                    "chosenOptionIds": [
                        "f81655d7-78e5-59b1-a7eb-90a2ec7a107d"
                    ]
                },
                "BP16.02": {
                    "questionId": "BP16.02",
                    "chosenOptionIds": [
                        "16a695c9-3ac1-5d34-b799-44602d85b556"
                    ]
                },
                "BP18": {
                    "questionId": "BP18",
                    "chosenOptionIds": [
                        "b577f323-ec54-5d25-a2b9-4f8de30d60a2"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 20
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "f03de28d-8f71-5af4-be2f-45f4e6092a3d"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "e620af65-9b5b-57e6-b13a-65616e67e084"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "a4ed572c-6aae-57f6-bc35-ea46929c1437"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "2404146e-cd56-5356-a23b-6427b4bbba06"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "93bfeb6e-215e-5f4b-b6de-0bc364e05be5"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "e22cf233-c174-52fb-af55-97bf9c46e971"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "46c90446-3ae9-53f5-9b57-ad19d66b5be6"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "d680a8d9-e35f-563f-9d1d-e7c3136aff6d"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "94795194-4a80-590f-be62-ee78f2fa0ff5"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "201c7ee3-56dc-51e9-ad55-69951192ab16"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "dbab3669-e910-5267-b7b1-fcb661ea7e81"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "2723ed09-37f6-522c-92b1-e518d4fe9315"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "58777a2e-4b97-5413-b340-efe722e45936"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "63509127-aadf-5fe2-8e1f-19d0d846d6ce"
                    ]
                },
                "BP99.1": {
                    "questionId": "BP99.1",
                    "chosenOptionIds": [
                        "34d19f12-207f-56ce-bde8-8d616f6e8b26"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "a020ed85-ec9d-5778-8006-41f634cab09d"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM19": {
                    "questionId": "HM19",
                    "chosenOptionIds": [
                        "fbfbcb17-2315-5dc6-a771-7f1051d7d464"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "c3ee8981-dec3-5b3c-80ca-c1023f983a6d"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "a43647cb-a0e8-5705-adb9-a2b68877dc77"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c41974b6-d394-569c-8f45-49f79fcac5c6"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "43c32319-c75f-5169-b0e5-713820721d4f"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "b2cb304f-f035-53fc-8763-e2f91b031f9b"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "0170b4cf-0541-53cd-a9db-818d5067dc23"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "2be66549-5767-51b4-82ed-deeab0723f8d"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "2a17c2fe-2592-58b1-98e9-3dc4168fe3e3"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "834b04f2-af09-5337-8fd5-be4a70c99e75"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "fda00d52-c8be-5b69-bdbd-905c13dddd4e"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "e95276b0-ee81-580b-b532-b0215f04abd5"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "1d07911e-929d-5719-aeea-a68f593d18e8"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: K392M0L aspect: simple_mdd should be subclinical",
            "expected": {
                "type": "Severity",
                "value": "subclinical"
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "c61d70d4-b773-54dd-94d0-d858a47be0fb"
                    ]
                },
                "D-1.5b": {
                    "questionId": "D-1.5b",
                    "freeValue": 3
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "45f0ce85-9e19-53da-bc0d-2af8b383318a"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "eb68a88d-b49b-5cde-a9c6-5e4c78e27d19"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "a0e528ca-2fbb-5da1-9398-0758f79755aa"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 360
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "7f1d6182-f8a7-5f4e-a0f8-2556b306ae36"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-1bl": {
                    "questionId": "D-1bl",
                    "chosenOptionIds": [
                        "83937123-de06-5b20-a479-c5e3e92848c4"
                    ]
                },
                "D-2": {
                    "questionId": "D-2",
                    "freeValue": 20
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "c695fd1c-2cb7-5654-b9f7-b32ecb158229"
                    ]
                },
                "D-2bl": {
                    "questionId": "D-2bl",
                    "chosenOptionIds": [
                        "78c1434b-e585-5f1d-b291-a1728f789022"
                    ]
                },
                "D-2bt": {
                    "questionId": "D-2bt",
                    "chosenOptionIds": [
                        "618070b6-e9e5-599a-a906-eed44f5c2dd9"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "fd9c4394-6664-5144-8797-00d27a4dda01"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5b6c0a69-6369-53d1-b2e1-5cd6294a9f71"
                    ]
                },
                "D-3bl": {
                    "questionId": "D-3bl",
                    "freeValue": 0.25
                },
                "D-3bt": {
                    "questionId": "D-3bt",
                    "chosenOptionIds": [
                        "b9abc28d-6e22-5b67-bc5e-27431e123974"
                    ]
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-42bt": {
                    "questionId": "D-42bt",
                    "freeValue": 0
                },
                "D-43bt": {
                    "questionId": "D-43bt",
                    "chosenOptionIds": [
                        "644dd944-5a2a-533b-9ef0-fadb5054c422"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "1d4beed1-78e5-531c-a255-643fac260dee"
                    ]
                },
                "D-4bt": {
                    "questionId": "D-4bt",
                    "freeValue": 0.16666666666666666
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "f3efc764-ca53-52c3-9c3a-38c7f787d415"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "772bee29-c3a5-52e1-b785-a77d506ae4fb"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "4c78ae3e-7de4-545f-aca1-03fa41fc0c23"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "13f8bac1-d0f5-539b-8596-1c55821bea46"
                    ]
                },
                "D-s10bt": {
                    "questionId": "D-s10bt",
                    "chosenOptionIds": [
                        "e3ab5219-3af6-5da7-8464-a145eb508a0f"
                    ]
                },
                "D-s11bt": {
                    "questionId": "D-s11bt",
                    "chosenOptionIds": [
                        "9c3fa6af-8b7b-5993-a587-641683d934d5",
                        "6994b88e-cfa5-5986-b9e8-625d17d1147c",
                        "7921bccf-4756-59fe-b678-d536c2ab5945",
                        "dc476220-9479-5def-8a97-20e6152d44af"
                    ]
                },
                "D-s1bt": {
                    "questionId": "D-s1bt",
                    "chosenOptionIds": [
                        "33dd907f-3e0e-5735-96bb-c101f0acced9"
                    ]
                },
                "D-s2bt": {
                    "questionId": "D-s2bt",
                    "chosenOptionIds": [
                        "b75d06ef-eb6c-5e23-bf8c-9b566fa4d9dd"
                    ]
                },
                "D-s3bt": {
                    "questionId": "D-s3bt",
                    "chosenOptionIds": [
                        "11f57889-ac93-53ab-88a7-9aea03a29429"
                    ]
                },
                "D-s4bt": {
                    "questionId": "D-s4bt",
                    "chosenOptionIds": [
                        "5a198500-fc4b-55a1-9521-3abe49eed06f"
                    ]
                },
                "D-s5bt": {
                    "questionId": "D-s5bt",
                    "chosenOptionIds": [
                        "d033bb4c-5d3c-519b-be31-1177042bffb4"
                    ]
                },
                "D-s6bt": {
                    "questionId": "D-s6bt",
                    "chosenOptionIds": [
                        "f7aa2ac0-a087-5371-8266-7601901cd1f9"
                    ]
                },
                "D-s7bt": {
                    "questionId": "D-s7bt",
                    "chosenOptionIds": [
                        "1df67815-0b9f-56ef-bd48-96ed977db141"
                    ]
                },
                "D-s8bt": {
                    "questionId": "D-s8bt",
                    "chosenOptionIds": [
                        "6f5067cf-ef4d-59d9-afa1-b3dfc4ac861f"
                    ]
                },
                "D-s9bt": {
                    "questionId": "D-s9bt",
                    "chosenOptionIds": [
                        "34daded3-efdb-5981-a1fc-e31ec1176383"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "8c599171-d554-5547-9f54-2066c1a711dd",
                        "c2921864-bbf7-5735-9313-848fb9d5a6d1",
                        "07106ab9-5949-5b5f-bc96-117139ebd397"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "6215a3de-a3e4-5f86-a039-1006646e6b93"
                    ]
                },
                "BP106": {
                    "questionId": "BP106",
                    "chosenOptionIds": [
                        "8c35da7e-8c0b-50bb-a037-b80a9f1df3d2"
                    ]
                },
                "BP108": {
                    "questionId": "BP108",
                    "chosenOptionIds": [
                        "6c88c07e-4483-5b5b-b6ad-44cff962863e"
                    ]
                },
                "BP110": {
                    "questionId": "BP110",
                    "freeValue": 180
                },
                "BP110.01": {
                    "questionId": "BP110.01",
                    "chosenOptionIds": [
                        "85bf649a-4487-5b00-8c08-82ad143ea966"
                    ]
                },
                "BP110.1": {
                    "questionId": "BP110.1",
                    "chosenOptionIds": [
                        "9d19a3c0-120d-5dfd-a794-8aa0e8d39e77"
                    ]
                },
                "BP110.2": {
                    "questionId": "BP110.2",
                    "chosenOptionIds": [
                        "faf183ab-b9b4-5f0d-a996-dfa04be4e401"
                    ]
                },
                "BP111": {
                    "questionId": "BP111",
                    "chosenOptionIds": [
                        "059fdaea-ce9e-527c-ae94-e00d2b0dbe01"
                    ]
                },
                "BP111.1": {
                    "questionId": "BP111.1",
                    "chosenOptionIds": [
                        "c532b673-2762-5ed0-8cbb-6f70d9977c20"
                    ]
                },
                "BP111.2": {
                    "questionId": "BP111.2",
                    "chosenOptionIds": [
                        "4c9c60d0-cd73-504d-8b03-b734045376ae"
                    ]
                },
                "BP112": {
                    "questionId": "BP112",
                    "chosenOptionIds": [
                        "6db4ba23-1d8f-58b9-b11e-eaea15417e28"
                    ]
                },
                "BP113": {
                    "questionId": "BP113",
                    "chosenOptionIds": [
                        "b16103eb-2ecf-595e-80e4-30baf575e01b"
                    ]
                },
                "BP114": {
                    "questionId": "BP114",
                    "chosenOptionIds": [
                        "a0206e39-59ad-539c-a2ff-e9fd935cf48b"
                    ]
                },
                "BP115": {
                    "questionId": "BP115",
                    "chosenOptionIds": [
                        "e7b2a68b-de30-51bd-bb56-cda1254d3dbc"
                    ]
                },
                "BP116": {
                    "questionId": "BP116",
                    "chosenOptionIds": [
                        "bce3bb8c-216a-5167-8ec7-35c39096d501"
                    ]
                },
                "BP117": {
                    "questionId": "BP117",
                    "chosenOptionIds": [
                        "354c012f-3484-58ad-9300-d9e29a212ae3"
                    ]
                },
                "BP118": {
                    "questionId": "BP118",
                    "chosenOptionIds": [
                        "e642f54c-d6fc-57f8-a53c-73dbd698d428"
                    ]
                },
                "BP118.1": {
                    "questionId": "BP118.1",
                    "chosenOptionIds": [
                        "3780fe32-30da-5e3b-bad1-6d360381e7e4"
                    ]
                },
                "BP119": {
                    "questionId": "BP119",
                    "chosenOptionIds": [
                        "3f4ab649-e8be-559b-a32f-9ccb317ffb38"
                    ]
                },
                "BP119.1": {
                    "questionId": "BP119.1",
                    "freeValue": 0
                },
                "BP120": {
                    "questionId": "BP120",
                    "chosenOptionIds": [
                        "548c329b-3169-5742-b17d-437216e6a454"
                    ]
                },
                "BP121": {
                    "questionId": "BP121",
                    "chosenOptionIds": [
                        "76891993-4f79-5b7a-ad6f-b7be1c4994fb"
                    ]
                },
                "BP122": {
                    "questionId": "BP122",
                    "chosenOptionIds": [
                        "b1759380-318d-54b0-9224-163fb659558b"
                    ]
                },
                "BP123": {
                    "questionId": "BP123",
                    "chosenOptionIds": [
                        "5c6d0cea-9bce-5b98-b6ad-dcb0e3fb6b0d"
                    ]
                },
                "BP124": {
                    "questionId": "BP124",
                    "chosenOptionIds": [
                        "6410ed4b-d607-5edd-baae-ffeb61769bf8"
                    ]
                },
                "BP125": {
                    "questionId": "BP125",
                    "chosenOptionIds": [
                        "d7075f96-ec23-51f7-9293-9bf725963409"
                    ]
                },
                "BP126": {
                    "questionId": "BP126",
                    "chosenOptionIds": [
                        "7bdce4bf-24ca-5470-a5cd-68c1e5c7ef03"
                    ]
                },
                "BP127": {
                    "questionId": "BP127",
                    "chosenOptionIds": [
                        "5f58bb60-dff1-585c-a5f3-26e34e4dc957"
                    ]
                },
                "BP128": {
                    "questionId": "BP128",
                    "chosenOptionIds": [
                        "ebff4971-05dc-52ed-9957-33c0ca1479c1"
                    ]
                },
                "BP129": {
                    "questionId": "BP129",
                    "chosenOptionIds": [
                        "4b90a2f8-960f-5d69-8e86-9c46af2fec0f"
                    ]
                },
                "BP130": {
                    "questionId": "BP130",
                    "chosenOptionIds": [
                        "7a066bdc-85a0-56cc-8632-2e83740855c1"
                    ]
                },
                "BP131": {
                    "questionId": "BP131",
                    "chosenOptionIds": [
                        "e5863f5d-32a6-520a-9b40-8591adf1c1b8"
                    ]
                },
                "BP132": {
                    "questionId": "BP132",
                    "chosenOptionIds": [
                        "da9000ec-69f6-580b-9ec9-756b702c2962"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM35": {
                    "questionId": "HM35",
                    "chosenOptionIds": [
                        "c5f21a2b-5af8-525d-bfab-0ed035defec0"
                    ]
                },
                "HM36": {
                    "questionId": "HM36",
                    "chosenOptionIds": [
                        "47bfc26b-4237-5a82-9cc4-67d348c4acb0"
                    ]
                },
                "HM37": {
                    "questionId": "HM37",
                    "chosenOptionIds": [
                        "20de534d-f482-51c5-ad87-bd054787fddc"
                    ]
                },
                "HM38": {
                    "questionId": "HM38",
                    "chosenOptionIds": [
                        "5125bbbb-70fb-5adc-a41b-c65c6b96180d"
                    ]
                },
                "HM40": {
                    "questionId": "HM40",
                    "chosenOptionIds": [
                        "596144bf-d0ea-5d17-a2ae-7096c31e2def"
                    ]
                },
                "HM41": {
                    "questionId": "HM41",
                    "chosenOptionIds": [
                        "dd9cb999-d487-51f2-8ca3-f121593feca0"
                    ]
                },
                "HM43": {
                    "questionId": "HM43",
                    "chosenOptionIds": [
                        "a8b07611-dcc0-55ef-ae78-d4ceb7ad42e3"
                    ]
                },
                "HM44": {
                    "questionId": "HM44",
                    "chosenOptionIds": [
                        "3675f391-ff12-51e5-b53f-b851b05414ee"
                    ]
                },
                "HM45": {
                    "questionId": "HM45",
                    "chosenOptionIds": [
                        "28a20a2a-3318-5dba-9e59-86d943d3ac73"
                    ]
                },
                "HM46": {
                    "questionId": "HM46",
                    "chosenOptionIds": [
                        "9f868300-e1df-5567-8ebf-e2f984022e52"
                    ]
                },
                "HM47": {
                    "questionId": "HM47",
                    "chosenOptionIds": [
                        "41ec9370-ba26-5cbc-ad35-d9e578788aa4"
                    ]
                },
                "HM48": {
                    "questionId": "HM48",
                    "chosenOptionIds": [
                        "2db89264-9c8a-54e5-8efc-1b1ca45b0233"
                    ]
                },
                "HM49": {
                    "questionId": "HM49",
                    "chosenOptionIds": [
                        "555cca84-b650-53ab-944e-2289adb21903"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: N0N4J3Q aspect: simple_mdd should be subclinical",
            "expected": {
                "type": "Severity",
                "value": "subclinical"
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "a16e8e14-524e-53df-b4ef-730a2c8e5f32"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "98772fed-cf55-5d42-b4e1-0e6a1a31ce56"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "826538fc-8a97-55e4-8013-fe6a93f8e451"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 3650
                },
                "D-15a": {
                    "questionId": "D-15a",
                    "chosenOptionIds": [
                        "7b763ff0-7703-54f7-8eae-aa83417ca133"
                    ]
                },
                "D-16.5a": {
                    "questionId": "D-16.5a",
                    "chosenOptionIds": [
                        "b409dbb9-80ef-5de1-96d0-1075735134db"
                    ]
                },
                "D-17a": {
                    "questionId": "D-17a",
                    "chosenOptionIds": [
                        "495d3d07-8a1f-5213-bf79-59443d7aa5cf"
                    ]
                },
                "D-18a": {
                    "questionId": "D-18a",
                    "chosenOptionIds": [
                        "d5dff57c-3626-5197-b54a-ddca85aa8775"
                    ]
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "80c993c8-1a94-5684-a8f9-d8f5072c8e9c"
                    ]
                },
                "D-20a": {
                    "questionId": "D-20a",
                    "chosenOptionIds": [
                        "b28ed556-a386-5556-a96f-ae7708ded1ab"
                    ]
                },
                "D-21a": {
                    "questionId": "D-21a",
                    "chosenOptionIds": [
                        "79fb502d-53ef-5cbb-a08a-c346d1ba9310"
                    ]
                },
                "D-23a": {
                    "questionId": "D-23a",
                    "chosenOptionIds": [
                        "32a2b9d2-46ee-5235-9c03-8fb1fb1e5800"
                    ]
                },
                "D-26a": {
                    "questionId": "D-26a",
                    "chosenOptionIds": [
                        "220827bb-16f1-5e00-9549-b98382b6e2ac"
                    ]
                },
                "D-27a": {
                    "questionId": "D-27a",
                    "chosenOptionIds": [
                        "3d120f97-fb12-5e86-8bb9-8fc93babc803"
                    ]
                },
                "D-28a": {
                    "questionId": "D-28a",
                    "chosenOptionIds": [
                        "009661f3-60b5-52e8-bc5f-44c65767874c"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "cc243947-5067-5a98-ade6-a4e10d0b42cf"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "e797900f-129d-553e-b83f-8494d7f03986"
                    ]
                },
                "D-30a": {
                    "questionId": "D-30a",
                    "chosenOptionIds": [
                        "1f6e9ea6-3402-596b-a518-31105c7a277b"
                    ]
                },
                "D-31a": {
                    "questionId": "D-31a",
                    "chosenOptionIds": [
                        "c78e0def-d73e-5c4e-8800-7c6f92d0a1ec"
                    ]
                },
                "D-32a": {
                    "questionId": "D-32a",
                    "chosenOptionIds": [
                        "ea2a4fbd-008a-571b-bb68-cbd0ba351521"
                    ]
                },
                "D-33a": {
                    "questionId": "D-33a",
                    "chosenOptionIds": [
                        "3968dd70-f060-5cbd-bb6a-d63f702a3691"
                    ]
                },
                "D-34a": {
                    "questionId": "D-34a",
                    "chosenOptionIds": [
                        "6bfab4b8-7d57-5039-8933-4dee0c958a19"
                    ]
                },
                "D-35a": {
                    "questionId": "D-35a",
                    "chosenOptionIds": [
                        "3a07e052-2332-5a23-bb19-3147a5b9d2c7"
                    ]
                },
                "D-36a": {
                    "questionId": "D-36a",
                    "chosenOptionIds": [
                        "185038dd-ce55-53b2-ba09-0bd163af90b3"
                    ]
                },
                "D-37a": {
                    "questionId": "D-37a",
                    "chosenOptionIds": [
                        "fd4e0608-283c-5f56-a026-edfa381519c9"
                    ]
                },
                "D-38a": {
                    "questionId": "D-38a",
                    "chosenOptionIds": [
                        "5ca199a0-50f8-57f0-a098-03c824bb83ba"
                    ]
                },
                "D-39a": {
                    "questionId": "D-39a",
                    "chosenOptionIds": [
                        "ae54b405-473d-53a5-8ded-1108dc74f928"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5b6c0a69-6369-53d1-b2e1-5cd6294a9f71"
                    ]
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-40a": {
                    "questionId": "D-40a",
                    "chosenOptionIds": [
                        "582119a7-d4a0-58ff-9b1d-2af045e11a7f"
                    ]
                },
                "D-41a": {
                    "questionId": "D-41a",
                    "chosenOptionIds": [
                        "6a5d1a65-0b53-5485-995a-76d8d7eafb1a"
                    ]
                },
                "D-42a": {
                    "questionId": "D-42a",
                    "chosenOptionIds": [
                        "926931de-e675-5117-9e61-78dd541393be"
                    ]
                },
                "D-43a": {
                    "questionId": "D-43a",
                    "chosenOptionIds": [
                        "22b1ff23-c3a7-56b8-8108-65eacd7d3fbe"
                    ]
                },
                "D-44.5a": {
                    "questionId": "D-44.5a",
                    "chosenOptionIds": [
                        "d87971dc-d745-5b64-ac37-37fbe1e722ac"
                    ]
                },
                "D-44a": {
                    "questionId": "D-44a",
                    "chosenOptionIds": [
                        "68a987a9-8bad-50d7-a4d9-e970334f0460"
                    ]
                },
                "D-45a": {
                    "questionId": "D-45a",
                    "freeValue": 0
                },
                "D-49a": {
                    "questionId": "D-49a",
                    "chosenOptionIds": [
                        "8eaac5aa-39e4-5f7d-bbe9-ba405b3dba9c"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "9d1b858c-7064-5a84-80d6-786c18e603b6",
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b",
                        "1d4beed1-78e5-531c-a255-643fac260dee"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "188a7831-38c4-55c4-880a-1e4db4e9bab1"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "81e9f63b-35b9-5032-8ea0-462275a87cce"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "5d271ac0-beca-56cc-9d0b-adafcd67da53"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "33a5fff0-9587-521f-bc53-0dacc36a0bb2"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "f8e2518c-72cf-563b-b322-a03406c8ebac"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "c2921864-bbf7-5735-9313-848fb9d5a6d1"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "7599bcaa-89a5-5f71-80ea-b70c360bb44a"
                    ]
                },
                "BP101b": {
                    "questionId": "BP101b",
                    "chosenOptionIds": [
                        "923cb2af-7bb5-5341-8349-003206ae043d"
                    ]
                },
                "BP102b": {
                    "questionId": "BP102b",
                    "chosenOptionIds": [
                        "523c7d8e-e42b-5654-b037-e8837afb49e7"
                    ]
                },
                "BP103b": {
                    "questionId": "BP103b",
                    "chosenOptionIds": [
                        "867b0ee4-73d9-5aac-b96d-bc2d85d5b183"
                    ]
                },
                "BP104b": {
                    "questionId": "BP104b",
                    "chosenOptionIds": [
                        "c59b5910-3279-509f-8175-f2fd0da2dc86"
                    ]
                },
                "BP87b": {
                    "questionId": "BP87b",
                    "chosenOptionIds": [
                        "7949bd96-3757-5573-bd89-3a91a383cc99"
                    ]
                },
                "BP88b": {
                    "questionId": "BP88b",
                    "chosenOptionIds": [
                        "fa24b924-a8e1-5f02-893b-3d9643007991"
                    ]
                },
                "BP89b": {
                    "questionId": "BP89b",
                    "chosenOptionIds": [
                        "41d2829a-3971-552f-8c5a-c77e7d0073e7"
                    ]
                },
                "BP90b": {
                    "questionId": "BP90b",
                    "chosenOptionIds": [
                        "95c2822d-27ad-5bb0-982f-8c2b949c8918"
                    ]
                },
                "BP92b": {
                    "questionId": "BP92b",
                    "chosenOptionIds": [
                        "b2f4c490-30df-5c8f-87c0-bc4acbcfb8e0"
                    ]
                },
                "BP94b": {
                    "questionId": "BP94b",
                    "chosenOptionIds": [
                        "9cf74958-461b-59df-b1a2-7ee7d7696a0b"
                    ]
                },
                "BP95b": {
                    "questionId": "BP95b",
                    "chosenOptionIds": [
                        "32255970-db0f-5cc0-a199-d412edf7ab7c"
                    ]
                },
                "BP96b": {
                    "questionId": "BP96b",
                    "chosenOptionIds": [
                        "e200f36c-3005-5e37-82d3-9228fcf60bfb"
                    ]
                },
                "BP97b": {
                    "questionId": "BP97b",
                    "chosenOptionIds": [
                        "94b68a0a-8608-59d6-a8bb-48cf3c52f891"
                    ]
                },
                "BP98b": {
                    "questionId": "BP98b",
                    "chosenOptionIds": [
                        "01641419-fbfc-52a5-ae63-0fa736ff9847"
                    ]
                },
                "BP99.2b": {
                    "questionId": "BP99.2b",
                    "chosenOptionIds": [
                        "0f933dbb-72b4-5212-b415-8ea806f59368"
                    ]
                },
                "BP99.3b": {
                    "questionId": "BP99.3b",
                    "chosenOptionIds": [
                        "bf30a6c2-f8e2-5305-b4d4-7ad6227449d3"
                    ]
                },
                "BP99b": {
                    "questionId": "BP99b",
                    "chosenOptionIds": [
                        "3f987aaa-e435-55a9-9c1b-aa6d2e476738"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "BP_neg1": {
                    "questionId": "BP_neg1",
                    "chosenOptionIds": [
                        "119208b0-ff3b-5c43-80be-4f07e65b6248"
                    ]
                }
            }
        },
        {
            "name": "Mood test: ME8MOEW aspect: simple_mdd should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "fe031280-7d5d-52cb-aca8-1a8664b3234a"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "98772fed-cf55-5d42-b4e1-0e6a1a31ce56"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "826538fc-8a97-55e4-8013-fe6a93f8e451"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 1095
                },
                "D-15a": {
                    "questionId": "D-15a",
                    "chosenOptionIds": [
                        "7b763ff0-7703-54f7-8eae-aa83417ca133"
                    ]
                },
                "D-16.5a": {
                    "questionId": "D-16.5a",
                    "chosenOptionIds": [
                        "0c4e0b7f-132d-5e75-aeae-fd29d3b2eb67"
                    ]
                },
                "D-17a": {
                    "questionId": "D-17a",
                    "chosenOptionIds": [
                        "8413ed32-e959-5c2a-9d19-8cc68709c34d"
                    ]
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "7f1d6182-f8a7-5f4e-a0f8-2556b306ae36"
                    ]
                },
                "D-1bl": {
                    "questionId": "D-1bl",
                    "chosenOptionIds": [
                        "4105c26a-4ff2-5fd7-984d-f0bcb7b5656e"
                    ]
                },
                "D-2": {
                    "questionId": "D-2",
                    "freeValue": 25
                },
                "D-20a": {
                    "questionId": "D-20a",
                    "chosenOptionIds": [
                        "b28ed556-a386-5556-a96f-ae7708ded1ab"
                    ]
                },
                "D-21a": {
                    "questionId": "D-21a",
                    "chosenOptionIds": [
                        "79fb502d-53ef-5cbb-a08a-c346d1ba9310"
                    ]
                },
                "D-23a": {
                    "questionId": "D-23a",
                    "chosenOptionIds": [
                        "32a2b9d2-46ee-5235-9c03-8fb1fb1e5800"
                    ]
                },
                "D-26a": {
                    "questionId": "D-26a",
                    "chosenOptionIds": [
                        "945792e1-fdf5-5959-89c8-6a1caa4eff88"
                    ]
                },
                "D-27a": {
                    "questionId": "D-27a",
                    "chosenOptionIds": [
                        "3d120f97-fb12-5e86-8bb9-8fc93babc803"
                    ]
                },
                "D-28a": {
                    "questionId": "D-28a",
                    "chosenOptionIds": [
                        "801353cf-2f72-53db-9179-e7ecc31b1fc1"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "c695fd1c-2cb7-5654-b9f7-b32ecb158229"
                    ]
                },
                "D-2bl": {
                    "questionId": "D-2bl",
                    "chosenOptionIds": [
                        "2a8c96e2-cb5b-596a-99b6-a4885253776c"
                    ]
                },
                "D-2bt": {
                    "questionId": "D-2bt",
                    "chosenOptionIds": [
                        "acdba44c-4419-55d5-891f-5c03c9b52309"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "fd9c4394-6664-5144-8797-00d27a4dda01",
                        "bccbebb3-ed8c-5251-856b-e93a1d4386f6",
                        "75d33f2f-6fcd-5e09-8574-899eabeb22e6"
                    ]
                },
                "D-30a": {
                    "questionId": "D-30a",
                    "chosenOptionIds": [
                        "1f6e9ea6-3402-596b-a518-31105c7a277b"
                    ]
                },
                "D-31a": {
                    "questionId": "D-31a",
                    "chosenOptionIds": [
                        "c78e0def-d73e-5c4e-8800-7c6f92d0a1ec"
                    ]
                },
                "D-32.1a": {
                    "questionId": "D-32.1a",
                    "chosenOptionIds": [
                        "536a2dd7-0943-5d01-9537-428496ec789b"
                    ]
                },
                "D-32.2a": {
                    "questionId": "D-32.2a",
                    "chosenOptionIds": [
                        "c3cfa538-c5a3-55dd-8922-622dfa302b7f"
                    ]
                },
                "D-32a": {
                    "questionId": "D-32a",
                    "chosenOptionIds": [
                        "93c47fae-1a07-5ad0-b521-e56d8f62f763"
                    ]
                },
                "D-33a": {
                    "questionId": "D-33a",
                    "chosenOptionIds": [
                        "379331e4-3b82-582c-af51-cc4ed79308d7"
                    ]
                },
                "D-34a": {
                    "questionId": "D-34a",
                    "chosenOptionIds": [
                        "6bfab4b8-7d57-5039-8933-4dee0c958a19"
                    ]
                },
                "D-35a": {
                    "questionId": "D-35a",
                    "chosenOptionIds": [
                        "9c9d44bb-b50f-5ddf-9553-03b254272362"
                    ]
                },
                "D-36a": {
                    "questionId": "D-36a",
                    "chosenOptionIds": [
                        "185038dd-ce55-53b2-ba09-0bd163af90b3"
                    ]
                },
                "D-37a": {
                    "questionId": "D-37a",
                    "chosenOptionIds": [
                        "dc9f1b80-2303-5ac8-86ff-e9a39a6c0e16"
                    ]
                },
                "D-38a": {
                    "questionId": "D-38a",
                    "chosenOptionIds": [
                        "cfa17d19-5b15-56ef-82c1-07949e21dd81"
                    ]
                },
                "D-39a": {
                    "questionId": "D-39a",
                    "chosenOptionIds": [
                        "756b91dc-7358-504f-aeab-84552f2bf28b"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "db7514e2-d317-55fb-82d3-2e4a49c2494b"
                    ]
                },
                "D-3bl": {
                    "questionId": "D-3bl",
                    "freeValue": 5
                },
                "D-3bt": {
                    "questionId": "D-3bt",
                    "chosenOptionIds": [
                        "b9abc28d-6e22-5b67-bc5e-27431e123974"
                    ]
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-40a": {
                    "questionId": "D-40a",
                    "chosenOptionIds": [
                        "582119a7-d4a0-58ff-9b1d-2af045e11a7f"
                    ]
                },
                "D-41a": {
                    "questionId": "D-41a",
                    "chosenOptionIds": [
                        "6a5d1a65-0b53-5485-995a-76d8d7eafb1a"
                    ]
                },
                "D-42a": {
                    "questionId": "D-42a",
                    "chosenOptionIds": [
                        "926931de-e675-5117-9e61-78dd541393be"
                    ]
                },
                "D-42bt": {
                    "questionId": "D-42bt",
                    "freeValue": 0.3333333333333333
                },
                "D-43a": {
                    "questionId": "D-43a",
                    "chosenOptionIds": [
                        "dc3faaa6-4a47-5998-83e3-4bbff8838194"
                    ]
                },
                "D-43bt": {
                    "questionId": "D-43bt",
                    "chosenOptionIds": [
                        "644dd944-5a2a-533b-9ef0-fadb5054c422"
                    ]
                },
                "D-44.5a": {
                    "questionId": "D-44.5a",
                    "chosenOptionIds": [
                        "d87971dc-d745-5b64-ac37-37fbe1e722ac",
                        "bcf5fb9f-f4b9-5f0c-a964-69a4e1a73334"
                    ]
                },
                "D-44a": {
                    "questionId": "D-44a",
                    "chosenOptionIds": [
                        "57c0e55d-5ee4-5237-9c5d-e317b90c5dbd"
                    ]
                },
                "D-45a": {
                    "questionId": "D-45a",
                    "freeValue": 360
                },
                "D-46a": {
                    "questionId": "D-46a",
                    "chosenOptionIds": [
                        "1fd426df-808a-5c68-9b6e-23ff2f128b7d",
                        "d9665b1f-cdd2-5979-895e-27bca6487533",
                        "7492eab7-c625-52d0-a2e3-4bf3332decc0",
                        "f0b15a45-ae49-59d2-97a7-33f866b0e629",
                        "13260a8b-4ec0-5e4d-84fc-1df990527937",
                        "4c178978-f79a-58fc-8e73-3a98614c4b6c",
                        "b3e43bd8-fbe3-5cda-b891-6f11e43993d0",
                        "6e49f368-62af-5abb-9034-a58bd534944f"
                    ]
                },
                "D-49.1a": {
                    "questionId": "D-49.1a",
                    "chosenOptionIds": [
                        "7108af97-c956-5da8-8dfa-518e346c197c"
                    ]
                },
                "D-49.2a": {
                    "questionId": "D-49.2a",
                    "freeValue": 5
                },
                "D-49a": {
                    "questionId": "D-49a",
                    "chosenOptionIds": [
                        "16dc6af1-32a9-59c5-b66c-57f6becea1e0"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "9d1b858c-7064-5a84-80d6-786c18e603b6"
                    ]
                },
                "D-4bt": {
                    "questionId": "D-4bt",
                    "freeValue": 360
                },
                "D-50a": {
                    "questionId": "D-50a",
                    "chosenOptionIds": [
                        "d91f7277-0497-57d2-93a3-ea638d14162c"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "f3efc764-ca53-52c3-9c3a-38c7f787d415"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "3e093dd3-857e-5004-a4fb-961fbfa59ece"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "772bee29-c3a5-52e1-b785-a77d506ae4fb"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "33a5fff0-9587-521f-bc53-0dacc36a0bb2"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "a70aee2a-9d23-554d-b7d3-9f1a025fd82d"
                    ]
                },
                "D-s10bt": {
                    "questionId": "D-s10bt",
                    "chosenOptionIds": [
                        "e7b114de-aed8-536e-8edf-baf8a2a673bf"
                    ]
                },
                "D-s11bt": {
                    "questionId": "D-s11bt",
                    "chosenOptionIds": [
                        "16e1a0b8-28c5-5bcd-931d-335c858c3f88",
                        "6994b88e-cfa5-5986-b9e8-625d17d1147c",
                        "9c3fa6af-8b7b-5993-a587-641683d934d5",
                        "dc476220-9479-5def-8a97-20e6152d44af",
                        "daf8b3c7-fb19-5a3f-ba9a-784adda7414f",
                        "bae5acc8-8664-54a2-ba97-c36244be4c44",
                        "64af3cae-713a-51e1-99c1-09e2fdaa755d",
                        "7921bccf-4756-59fe-b678-d536c2ab5945"
                    ]
                },
                "D-s1bt": {
                    "questionId": "D-s1bt",
                    "chosenOptionIds": [
                        "c17482e4-818a-50cd-90fa-1246d037825e"
                    ]
                },
                "D-s2bt": {
                    "questionId": "D-s2bt",
                    "chosenOptionIds": [
                        "e074592a-4667-5b65-b713-b8892451b433"
                    ]
                },
                "D-s3bt": {
                    "questionId": "D-s3bt",
                    "chosenOptionIds": [
                        "ae77a2d7-a52c-5cdc-8c8d-93e294ade96d"
                    ]
                },
                "D-s4bt": {
                    "questionId": "D-s4bt",
                    "chosenOptionIds": [
                        "27313271-8ec6-5f99-bb2c-0ed50a7c1527"
                    ]
                },
                "D-s5bt": {
                    "questionId": "D-s5bt",
                    "chosenOptionIds": [
                        "e6a5403c-95a3-58c9-9259-e02e5fafd441"
                    ]
                },
                "D-s6bt": {
                    "questionId": "D-s6bt",
                    "chosenOptionIds": [
                        "53625e56-4f03-5f54-a340-d5b8cabe24d9"
                    ]
                },
                "D-s7bt": {
                    "questionId": "D-s7bt",
                    "chosenOptionIds": [
                        "1df67815-0b9f-56ef-bd48-96ed977db141"
                    ]
                },
                "D-s8bt": {
                    "questionId": "D-s8bt",
                    "chosenOptionIds": [
                        "ebfe9c3e-852e-5116-8185-8430f6b361f8"
                    ]
                },
                "D-s9bt": {
                    "questionId": "D-s9bt",
                    "chosenOptionIds": [
                        "8d2e7af7-07c2-5459-8865-609f820de278"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "60fe0bb4-d770-56f2-b05a-864625a48e4d"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "ccbdcbec-96d0-5362-977b-784289f5cfc0"
                    ]
                },
                "BP10": {
                    "questionId": "BP10",
                    "chosenOptionIds": [
                        "7ad54f0a-87a6-5e82-bfc3-44b540230082"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "a20d7b84-720e-53c5-a450-45cbde434507"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "ca5ac96d-2b69-5200-a063-61db840c9ef2"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "1daccd4c-e5ff-5f18-ba55-7c2d2d93b849"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "f2228920-3fec-5097-a63d-990c25d50c6c"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP105": {
                    "questionId": "BP105",
                    "chosenOptionIds": [
                        "978f2ff4-94d9-5f7e-9126-e8657be0c7ca"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 25
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "c7bee7a0-3037-5eb1-96a1-85b884d7e1dd"
                    ]
                },
                "BP3.01": {
                    "questionId": "BP3.01",
                    "freeValue": 2
                },
                "BP3.1": {
                    "questionId": "BP3.1",
                    "chosenOptionIds": [
                        "9531c3ff-ab2b-5198-a490-919ede5afb07"
                    ]
                },
                "BP3.2": {
                    "questionId": "BP3.2",
                    "chosenOptionIds": [
                        "ec333f8b-146a-5700-a15d-527032ceac3f"
                    ]
                },
                "BP3.3": {
                    "questionId": "BP3.3",
                    "freeValue": 60
                },
                "BP3.4": {
                    "questionId": "BP3.4",
                    "chosenOptionIds": [
                        "f304bf93-0c68-5da0-80ba-ccecc2d0fce1"
                    ]
                },
                "BP4": {
                    "questionId": "BP4",
                    "chosenOptionIds": [
                        "9765aba8-7d55-5928-b79d-36aa58646036"
                    ]
                },
                "BP4.5": {
                    "questionId": "BP4.5",
                    "chosenOptionIds": [
                        "1be4b3b1-3a88-5544-af54-ebdfee1ce891"
                    ]
                },
                "BP6": {
                    "questionId": "BP6",
                    "chosenOptionIds": [
                        "52f153db-76e7-57fa-90bd-63d5a923964a"
                    ]
                },
                "BP6.1": {
                    "questionId": "BP6.1",
                    "chosenOptionIds": [
                        "a99773bd-2bff-523e-bc29-1f761307b3d4"
                    ]
                },
                "BP6.2": {
                    "questionId": "BP6.2",
                    "freeValue": 2190
                },
                "BP6.3": {
                    "questionId": "BP6.3",
                    "chosenOptionIds": [
                        "df17fdbe-e756-5ece-ad3d-19cafeef31d1"
                    ]
                },
                "BP8": {
                    "questionId": "BP8",
                    "chosenOptionIds": [
                        "87e0081b-9455-5ced-9225-10c2f70b1734"
                    ]
                },
                "BP8.0": {
                    "questionId": "BP8.0",
                    "chosenOptionIds": [
                        "3568cdb1-2500-512b-aada-2547c5bfd835"
                    ]
                },
                "BP8.01": {
                    "questionId": "BP8.01",
                    "chosenOptionIds": [
                        "a5d71cc4-3c6f-5e37-a507-1dfa312124b8"
                    ]
                },
                "BP8.02": {
                    "questionId": "BP8.02",
                    "chosenOptionIds": [
                        "e88670a3-c6d8-5c96-914e-b9fc1818aeda"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "e620af65-9b5b-57e6-b13a-65616e67e084"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "a4ed572c-6aae-57f6-bc35-ea46929c1437"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "0953f30c-801a-54f6-8238-b9dc42ab17f9"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "fd30dc27-8e89-5d1a-9d95-8844603d4379"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "33e48f82-ae80-5f20-a3a5-79692d21ad1a"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "cc7c09ae-1cb7-5b9a-9cd3-8d1012b90ee8"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "17619692-6d18-5558-99da-766a1c275e03"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "940c16a8-1835-582a-9dad-bd52e3a49cc7"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "2555d898-6736-5804-951e-e3c438313029"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "9b27e3ff-f8ce-54c5-9220-0479ae53e889"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "4a7683c6-774e-5362-8060-740f61986c9b"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "a0a2f2f1-ed73-59bb-add9-5f3569bbbecb"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "63509127-aadf-5fe2-8e1f-19d0d846d6ce"
                    ]
                },
                "BP99.1": {
                    "questionId": "BP99.1",
                    "chosenOptionIds": [
                        "34d19f12-207f-56ce-bde8-8d616f6e8b26"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "a020ed85-ec9d-5778-8006-41f634cab09d"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM2": {
                    "questionId": "HM2",
                    "chosenOptionIds": [
                        "2b56aa7d-493f-5a18-aa9f-f97304f89130"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "6b99de8b-634a-5eb0-bc65-bdf30eb9d433"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "8f7acbc1-9ebe-5a52-95ce-b50d62c27345"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c41974b6-d394-569c-8f45-49f79fcac5c6"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "43c32319-c75f-5169-b0e5-713820721d4f"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "eb41dc92-16ec-5abf-a841-e1d0b262083e"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "0170b4cf-0541-53cd-a9db-818d5067dc23"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "1240a093-e011-5bb9-9dbe-b61af95e9cde"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "2a17c2fe-2592-58b1-98e9-3dc4168fe3e3"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "7e32475a-3fcc-51ac-b414-ab46578efdfd"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "86277249-cf50-5f91-a27f-f3a69344e796"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "bde4e5f3-19e4-571f-84a2-5b9d188d74ef"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "1d07911e-929d-5719-aeea-a68f593d18e8"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: NEL6L3V aspect: simple_mdd should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "0ec0e224-a5cf-5ece-b3ab-37061a600ace"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "a16e8e14-524e-53df-b4ef-730a2c8e5f32"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "c0453e8e-7a9c-5bf5-b8a2-167a61a6387e"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "b74511c7-9f97-5c15-8bf5-6ec4357b4814"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 240
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "7a5958fc-e0f7-5d43-9cd0-abfe95cb7026"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "11f457f5-4c39-5458-ba9a-84478dd379ba"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5b6c0a69-6369-53d1-b2e1-5cd6294a9f71"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "a449e4ae-663b-50fb-9b62-1708b0a32669"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "dde1af45-4686-52c9-b960-369527b28f6c"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "adecf2d3-f302-50f8-a4a9-3ffac1789bc6"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "67316d94-1cf0-5bd0-a4db-f57bc70684ba"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "60fe0bb4-d770-56f2-b05a-864625a48e4d"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "ccbdcbec-96d0-5362-977b-784289f5cfc0"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "a20d7b84-720e-53c5-a450-45cbde434507"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "d8c1997f-d722-536c-ad9d-296b5e10ed04"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "d3224e16-1b0c-5266-9e14-ac72d2b2e988"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "b24df75b-6bb9-5f5f-99e3-c883ec3644ec"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP12": {
                    "questionId": "BP12",
                    "chosenOptionIds": [
                        "1d6299ac-2ac6-51e2-9a3f-13e8d44e652a"
                    ]
                },
                "BP12.5": {
                    "questionId": "BP12.5",
                    "chosenOptionIds": [
                        "cd7ce00f-4e7c-57ac-9c6c-4137efa24c8c"
                    ]
                },
                "BP14": {
                    "questionId": "BP14",
                    "chosenOptionIds": [
                        "97bbe87b-c5ff-51c1-a99c-cec263cff7d4"
                    ]
                },
                "BP14.1": {
                    "questionId": "BP14.1",
                    "chosenOptionIds": [
                        "e1b5b8f7-1317-5136-a8c1-2afdfa91e615"
                    ]
                },
                "BP14.2": {
                    "questionId": "BP14.2",
                    "freeValue": 84
                },
                "BP14.3": {
                    "questionId": "BP14.3",
                    "chosenOptionIds": [
                        "9f5fc152-75fb-59d8-8f40-7a6ca15c69cb"
                    ]
                },
                "BP16": {
                    "questionId": "BP16",
                    "chosenOptionIds": [
                        "fabceeea-8fea-5444-8b67-8f189f366c27"
                    ]
                },
                "BP16.0": {
                    "questionId": "BP16.0",
                    "chosenOptionIds": [
                        "4b26caa5-9124-51b2-ab08-2f4e977cee1f"
                    ]
                },
                "BP16.01": {
                    "questionId": "BP16.01",
                    "chosenOptionIds": [
                        "f81655d7-78e5-59b1-a7eb-90a2ec7a107d"
                    ]
                },
                "BP16.02": {
                    "questionId": "BP16.02",
                    "chosenOptionIds": [
                        "16a695c9-3ac1-5d34-b799-44602d85b556"
                    ]
                },
                "BP18": {
                    "questionId": "BP18",
                    "chosenOptionIds": [
                        "b577f323-ec54-5d25-a2b9-4f8de30d60a2"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 48
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "f03de28d-8f71-5af4-be2f-45f4e6092a3d"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "387b19c0-67d0-557e-9fba-ae065dcf3587"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "a4ed572c-6aae-57f6-bc35-ea46929c1437"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "667ba57c-b1cd-5f9b-aa12-58e65a981910"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "a2056280-4b54-5258-8bb4-407b521fff4b"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "d9fb150b-68b8-50ed-bb1d-eafd41f63f3b"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "0feda24f-7b01-557b-8ca5-10eb924cbdac"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 360
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "d680a8d9-e35f-563f-9d1d-e7c3136aff6d"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "94795194-4a80-590f-be62-ee78f2fa0ff5"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "201c7ee3-56dc-51e9-ad55-69951192ab16"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "de2ea429-4fcd-53b8-8d9f-ebb69a637a02"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "dbab3669-e910-5267-b7b1-fcb661ea7e81"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "4a7683c6-774e-5362-8060-740f61986c9b"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "58777a2e-4b97-5413-b340-efe722e45936"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "5c06d516-e816-58d6-997e-f8fa91131ac7"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "c0cc09c3-1335-5eb4-8801-b3cdaf74f6fa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM19": {
                    "questionId": "HM19",
                    "chosenOptionIds": [
                        "99f474d7-d509-592a-856a-afed343fb1b8"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "c3ee8981-dec3-5b3c-80ca-c1023f983a6d"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "a43647cb-a0e8-5705-adb9-a2b68877dc77"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c41974b6-d394-569c-8f45-49f79fcac5c6"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "43c32319-c75f-5169-b0e5-713820721d4f"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "b2cb304f-f035-53fc-8763-e2f91b031f9b"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "0170b4cf-0541-53cd-a9db-818d5067dc23"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "2be66549-5767-51b4-82ed-deeab0723f8d"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "2a17c2fe-2592-58b1-98e9-3dc4168fe3e3"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "7e32475a-3fcc-51ac-b414-ab46578efdfd"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "86277249-cf50-5f91-a27f-f3a69344e796"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "8823c74f-ff7c-52d8-9c73-fd3b4180f48a"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "4b8e4843-d260-59e5-8468-1303b1c3a638"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: Z3R680O aspect: simple_mdd should be severe",
            "expected": {
                "type": "Severity",
                "value": "severe"
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "3bd18f66-6f1e-5ea4-ae24-2990ed197bef"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "9b47900d-e5a7-5e58-830b-d9b3130a7990"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "826538fc-8a97-55e4-8013-fe6a93f8e451"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 4015
                },
                "D-15a": {
                    "questionId": "D-15a",
                    "chosenOptionIds": [
                        "7b763ff0-7703-54f7-8eae-aa83417ca133"
                    ]
                },
                "D-16.5a": {
                    "questionId": "D-16.5a",
                    "chosenOptionIds": [
                        "5f88b934-32eb-5ed5-8896-a818cbe4b033"
                    ]
                },
                "D-17a": {
                    "questionId": "D-17a",
                    "chosenOptionIds": [
                        "8f929de7-3c80-5c53-9cb9-14fee6708b8c"
                    ]
                },
                "D-18a": {
                    "questionId": "D-18a",
                    "chosenOptionIds": [
                        "615b852b-6f81-54a9-bc4a-a8843e82c230"
                    ]
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "db360e93-3616-5d89-be36-5ccee4aacf1c"
                    ]
                },
                "D-20a": {
                    "questionId": "D-20a",
                    "chosenOptionIds": [
                        "d262a22d-afad-541d-82b0-fabd40c42423"
                    ]
                },
                "D-21a": {
                    "questionId": "D-21a",
                    "chosenOptionIds": [
                        "e64b46f6-a0c1-5378-95de-f1de59b491b4"
                    ]
                },
                "D-23a": {
                    "questionId": "D-23a",
                    "chosenOptionIds": [
                        "44fe96b8-192b-5b6b-88fa-9c2f7ba73a6d"
                    ]
                },
                "D-26a": {
                    "questionId": "D-26a",
                    "chosenOptionIds": [
                        "823c244d-25bb-5b84-9f3c-16e82c594aee"
                    ]
                },
                "D-27a": {
                    "questionId": "D-27a",
                    "chosenOptionIds": [
                        "8896026c-15ab-577e-8c44-e63eb7e6dba3"
                    ]
                },
                "D-28a": {
                    "questionId": "D-28a",
                    "chosenOptionIds": [
                        "801353cf-2f72-53db-9179-e7ecc31b1fc1"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "de11fb6b-7e7d-52b1-9d09-2d6debc53314"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "75d33f2f-6fcd-5e09-8574-899eabeb22e6",
                        "14d310b2-5fb6-5f79-8bfa-e3524002e63d",
                        "bccbebb3-ed8c-5251-856b-e93a1d4386f6"
                    ]
                },
                "D-30a": {
                    "questionId": "D-30a",
                    "chosenOptionIds": [
                        "d309cef4-2204-5a10-a98d-b66740336838"
                    ]
                },
                "D-31a": {
                    "questionId": "D-31a",
                    "chosenOptionIds": [
                        "1cb5b1dd-fcae-50f1-b26b-53e0d5e03ed5"
                    ]
                },
                "D-32a": {
                    "questionId": "D-32a",
                    "chosenOptionIds": [
                        "ea2a4fbd-008a-571b-bb68-cbd0ba351521"
                    ]
                },
                "D-33a": {
                    "questionId": "D-33a",
                    "chosenOptionIds": [
                        "379331e4-3b82-582c-af51-cc4ed79308d7"
                    ]
                },
                "D-34a": {
                    "questionId": "D-34a",
                    "chosenOptionIds": [
                        "e919c54f-d9a4-5a15-9ae7-8b6b52607a22"
                    ]
                },
                "D-35a": {
                    "questionId": "D-35a",
                    "chosenOptionIds": [
                        "3a07e052-2332-5a23-bb19-3147a5b9d2c7"
                    ]
                },
                "D-36a": {
                    "questionId": "D-36a",
                    "chosenOptionIds": [
                        "4ab32dca-be9e-573e-a330-24f9350ffaca"
                    ]
                },
                "D-37a": {
                    "questionId": "D-37a",
                    "chosenOptionIds": [
                        "fd4e0608-283c-5f56-a026-edfa381519c9"
                    ]
                },
                "D-38a": {
                    "questionId": "D-38a",
                    "chosenOptionIds": [
                        "8d5bbc66-0b33-5de5-838e-ff0fccbb6fbc"
                    ]
                },
                "D-39a": {
                    "questionId": "D-39a",
                    "chosenOptionIds": [
                        "a2f071cb-74be-518d-a33d-12a101ac9770"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5b6c0a69-6369-53d1-b2e1-5cd6294a9f71"
                    ]
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-40a": {
                    "questionId": "D-40a",
                    "chosenOptionIds": [
                        "582119a7-d4a0-58ff-9b1d-2af045e11a7f"
                    ]
                },
                "D-41a": {
                    "questionId": "D-41a",
                    "chosenOptionIds": [
                        "89f5ff80-0cae-515a-a4d1-02448dbc11b8"
                    ]
                },
                "D-42a": {
                    "questionId": "D-42a",
                    "chosenOptionIds": [
                        "926931de-e675-5117-9e61-78dd541393be"
                    ]
                },
                "D-43a": {
                    "questionId": "D-43a",
                    "chosenOptionIds": [
                        "22b1ff23-c3a7-56b8-8108-65eacd7d3fbe"
                    ]
                },
                "D-44.5a": {
                    "questionId": "D-44.5a",
                    "chosenOptionIds": [
                        "b5fc3288-710a-5aef-9842-611757b40967",
                        "884767e3-ed0c-5db3-a227-f84f7ecaf8d3"
                    ]
                },
                "D-44a": {
                    "questionId": "D-44a",
                    "chosenOptionIds": [
                        "57c0e55d-5ee4-5237-9c5d-e317b90c5dbd"
                    ]
                },
                "D-45a": {
                    "questionId": "D-45a",
                    "freeValue": 0
                },
                "D-46a": {
                    "questionId": "D-46a",
                    "chosenOptionIds": [
                        "c2c0e745-9e7b-5f61-8565-fbc0d5c49f71",
                        "d9665b1f-cdd2-5979-895e-27bca6487533",
                        "7492eab7-c625-52d0-a2e3-4bf3332decc0",
                        "6e49f368-62af-5abb-9034-a58bd534944f",
                        "b3e43bd8-fbe3-5cda-b891-6f11e43993d0"
                    ]
                },
                "D-49a": {
                    "questionId": "D-49a",
                    "chosenOptionIds": [
                        "8eaac5aa-39e4-5f7d-bbe9-ba405b3dba9c"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b",
                        "1d4beed1-78e5-531c-a255-643fac260dee"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "b52073e3-c813-51f5-88ba-9cd3bddd7023"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "3e093dd3-857e-5004-a4fb-961fbfa59ece"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "772bee29-c3a5-52e1-b785-a77d506ae4fb"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "67316d94-1cf0-5bd0-a4db-f57bc70684ba"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "13f8bac1-d0f5-539b-8596-1c55821bea46"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "60fe0bb4-d770-56f2-b05a-864625a48e4d"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "ccbdcbec-96d0-5362-977b-784289f5cfc0"
                    ]
                },
                "BP10": {
                    "questionId": "BP10",
                    "chosenOptionIds": [
                        "7ad54f0a-87a6-5e82-bfc3-44b540230082"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "026a1cea-7cfc-540b-bfe5-42886be90595"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "1c4ed441-5919-569e-98ba-b49be1bc3957"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "d3224e16-1b0c-5266-9e14-ac72d2b2e988"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "b24df75b-6bb9-5f5f-99e3-c883ec3644ec"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 16
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "c7bee7a0-3037-5eb1-96a1-85b884d7e1dd"
                    ]
                },
                "BP3.01": {
                    "questionId": "BP3.01",
                    "freeValue": 12
                },
                "BP3.1": {
                    "questionId": "BP3.1",
                    "chosenOptionIds": [
                        "c053dd45-79bd-59bf-82df-7e9ecd85dc11"
                    ]
                },
                "BP3.2": {
                    "questionId": "BP3.2",
                    "chosenOptionIds": [
                        "ec333f8b-146a-5700-a15d-527032ceac3f"
                    ]
                },
                "BP3.3": {
                    "questionId": "BP3.3",
                    "freeValue": 3
                },
                "BP3.4": {
                    "questionId": "BP3.4",
                    "chosenOptionIds": [
                        "f304bf93-0c68-5da0-80ba-ccecc2d0fce1"
                    ]
                },
                "BP4": {
                    "questionId": "BP4",
                    "chosenOptionIds": [
                        "9765aba8-7d55-5928-b79d-36aa58646036"
                    ]
                },
                "BP4.5": {
                    "questionId": "BP4.5",
                    "chosenOptionIds": [
                        "66a3460c-65ce-5a4c-9774-2e303ae4961e"
                    ]
                },
                "BP6": {
                    "questionId": "BP6",
                    "chosenOptionIds": [
                        "52f153db-76e7-57fa-90bd-63d5a923964a"
                    ]
                },
                "BP6.1": {
                    "questionId": "BP6.1",
                    "chosenOptionIds": [
                        "430b68c6-29cf-5a15-ab4c-89bd842383bf"
                    ]
                },
                "BP6.2": {
                    "questionId": "BP6.2",
                    "freeValue": 3
                },
                "BP6.3": {
                    "questionId": "BP6.3",
                    "chosenOptionIds": [
                        "e061c578-086e-5990-bba6-279cb273f3a5"
                    ]
                },
                "BP8": {
                    "questionId": "BP8",
                    "chosenOptionIds": [
                        "87e0081b-9455-5ced-9225-10c2f70b1734"
                    ]
                },
                "BP8.0": {
                    "questionId": "BP8.0",
                    "chosenOptionIds": [
                        "3568cdb1-2500-512b-aada-2547c5bfd835"
                    ]
                },
                "BP8.01": {
                    "questionId": "BP8.01",
                    "chosenOptionIds": [
                        "5020bfdd-a280-56cf-b7f5-3b0b7e667165"
                    ]
                },
                "BP8.02": {
                    "questionId": "BP8.02",
                    "chosenOptionIds": [
                        "e88670a3-c6d8-5c96-914e-b9fc1818aeda"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "387b19c0-67d0-557e-9fba-ae065dcf3587"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "afb57366-c0b3-5f73-92eb-27bc133ad2ed"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "667ba57c-b1cd-5f9b-aa12-58e65a981910"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "fd30dc27-8e89-5d1a-9d95-8844603d4379"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "e22cf233-c174-52fb-af55-97bf9c46e971"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "1f57d1a0-3682-5d7d-b66b-8179ec5c3988"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "d680a8d9-e35f-563f-9d1d-e7c3136aff6d"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "82997889-d795-572b-a597-e484ef078b39"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "1e86d64a-b972-500a-9120-7884e4b86ede"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "e232ba2d-36a9-524e-9fb2-8541b27bb569"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "352885c9-c1e1-5206-9f52-8d9b6d2aa166"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "b14d53e4-f898-52ac-87d7-3aa62abc9d7b"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "5c06d516-e816-58d6-997e-f8fa91131ac7"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "c0cc09c3-1335-5eb4-8801-b3cdaf74f6fa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM2": {
                    "questionId": "HM2",
                    "chosenOptionIds": [
                        "de662c3f-66b4-566f-856d-2124e2725542"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "98ea07a0-ae95-570f-8d11-a3e7c682e986"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "696fdd9d-5ced-504b-a933-bb3e575b0099"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c41974b6-d394-569c-8f45-49f79fcac5c6"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "43c32319-c75f-5169-b0e5-713820721d4f"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "b2cb304f-f035-53fc-8763-e2f91b031f9b"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "0170b4cf-0541-53cd-a9db-818d5067dc23"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "6b35cd3b-d67a-54af-94f6-e39ddf5af6c6"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "2a17c2fe-2592-58b1-98e9-3dc4168fe3e3"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "834b04f2-af09-5337-8fd5-be4a70c99e75"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "86277249-cf50-5f91-a27f-f3a69344e796"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "8823c74f-ff7c-52d8-9c73-fd3b4180f48a"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "1d07911e-929d-5719-aeea-a68f593d18e8"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: Z35MQ3O aspect: simple_mdd should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "0ec0e224-a5cf-5ece-b3ab-37061a600ace"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "a16e8e14-524e-53df-b4ef-730a2c8e5f32"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "916d657d-9f2a-5451-8465-18e3bd863347"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "b74511c7-9f97-5c15-8bf5-6ec4357b4814"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 365
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "913bc1e9-8da1-54c7-b935-13fc5d48fbd9"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "11f457f5-4c39-5458-ba9a-84478dd379ba"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5c30c60e-d53e-5cc3-b4a9-fdad9bec4cb7"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "f3efc764-ca53-52c3-9c3a-38c7f787d415"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "08a0f83f-2f37-5904-9364-4e40deb90e98"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "adecf2d3-f302-50f8-a4a9-3ffac1789bc6"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "d362cd87-7515-551e-bc74-19ef903484aa"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "44fd9eb3-aee2-5af0-b83c-dbbbf9514022"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "ccbdcbec-96d0-5362-977b-784289f5cfc0"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "4a0f1c4d-38cf-5de4-99e3-0ee818f9709c"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "6a5aa899-7b89-5661-a554-71b6058ad4a4"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "a3a547f3-b48b-567f-a32a-6a3f5f4bacd7"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "b24df75b-6bb9-5f5f-99e3-c883ec3644ec"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP12": {
                    "questionId": "BP12",
                    "chosenOptionIds": [
                        "22f9a995-3f9a-5fe9-99bb-71b7f1f96621"
                    ]
                },
                "BP15": {
                    "questionId": "BP15",
                    "chosenOptionIds": [
                        "d8c37d8b-01b7-581e-9d97-f6c8b8bf9267"
                    ]
                },
                "BP15.1": {
                    "questionId": "BP15.1",
                    "chosenOptionIds": [
                        "35a5b342-38f4-5343-8a4f-a3f024b95530"
                    ]
                },
                "BP15.2": {
                    "questionId": "BP15.2",
                    "freeValue": 1825
                },
                "BP15.3": {
                    "questionId": "BP15.3",
                    "chosenOptionIds": [
                        "e65c7281-c61b-5d1b-80d2-6d817860040c"
                    ]
                },
                "BP16": {
                    "questionId": "BP16",
                    "chosenOptionIds": [
                        "fabceeea-8fea-5444-8b67-8f189f366c27"
                    ]
                },
                "BP16.0": {
                    "questionId": "BP16.0",
                    "chosenOptionIds": [
                        "4b26caa5-9124-51b2-ab08-2f4e977cee1f"
                    ]
                },
                "BP16.01": {
                    "questionId": "BP16.01",
                    "chosenOptionIds": [
                        "f81655d7-78e5-59b1-a7eb-90a2ec7a107d"
                    ]
                },
                "BP16.02": {
                    "questionId": "BP16.02",
                    "chosenOptionIds": [
                        "16a695c9-3ac1-5d34-b799-44602d85b556"
                    ]
                },
                "BP19": {
                    "questionId": "BP19",
                    "chosenOptionIds": [
                        "601bc370-8a4c-5b1f-a7f6-6c174f421733"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 25
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "f03de28d-8f71-5af4-be2f-45f4e6092a3d"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "e620af65-9b5b-57e6-b13a-65616e67e084"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "cb636d32-4f4e-51b3-a23f-2a11b8771b7e"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "92e118de-38b9-5d5a-96ba-7b50547c0621"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "fd30dc27-8e89-5d1a-9d95-8844603d4379"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "e22cf233-c174-52fb-af55-97bf9c46e971"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "46c90446-3ae9-53f5-9b57-ad19d66b5be6"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "d680a8d9-e35f-563f-9d1d-e7c3136aff6d"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "f35fe383-d2aa-51c4-9fde-961b1de6d52b"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "201c7ee3-56dc-51e9-ad55-69951192ab16"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "dbab3669-e910-5267-b7b1-fcb661ea7e81"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "17b4da6f-7135-523c-b90b-2fe3b66785ff"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "3cf77140-2757-58eb-b4ea-f2a90cd766e7"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "5c06d516-e816-58d6-997e-f8fa91131ac7"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "c0cc09c3-1335-5eb4-8801-b3cdaf74f6fa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM20": {
                    "questionId": "HM20",
                    "chosenOptionIds": [
                        "b8816e4b-2104-5ce7-a5b0-b804a1a0dc20"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "c3ee8981-dec3-5b3c-80ca-c1023f983a6d"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "696fdd9d-5ced-504b-a933-bb3e575b0099"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c41974b6-d394-569c-8f45-49f79fcac5c6"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "43c32319-c75f-5169-b0e5-713820721d4f"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "b2cb304f-f035-53fc-8763-e2f91b031f9b"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "0170b4cf-0541-53cd-a9db-818d5067dc23"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "6b35cd3b-d67a-54af-94f6-e39ddf5af6c6"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "2a17c2fe-2592-58b1-98e9-3dc4168fe3e3"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "0f2a6025-0d8f-5d89-81a2-c3cc425cd7c4"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "86277249-cf50-5f91-a27f-f3a69344e796"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "bde4e5f3-19e4-571f-84a2-5b9d188d74ef"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "42710499-d4f3-5f65-bfad-382dde7e2f83"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: REOG402 aspect: simple_mdd should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "c61d70d4-b773-54dd-94d0-d858a47be0fb"
                    ]
                },
                "D-1.5b": {
                    "questionId": "D-1.5b",
                    "freeValue": 3
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "a16e8e14-524e-53df-b4ef-730a2c8e5f32"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "916d657d-9f2a-5451-8465-18e3bd863347"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "b74511c7-9f97-5c15-8bf5-6ec4357b4814"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 730
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "913bc1e9-8da1-54c7-b935-13fc5d48fbd9"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-1bl": {
                    "questionId": "D-1bl",
                    "chosenOptionIds": [
                        "83937123-de06-5b20-a479-c5e3e92848c4"
                    ]
                },
                "D-2": {
                    "questionId": "D-2",
                    "freeValue": 18
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "11f457f5-4c39-5458-ba9a-84478dd379ba"
                    ]
                },
                "D-2bl": {
                    "questionId": "D-2bl",
                    "chosenOptionIds": [
                        "02464c9b-d1d8-5f60-993d-5f37cbfcc102"
                    ]
                },
                "D-2bs": {
                    "questionId": "D-2bs",
                    "chosenOptionIds": [
                        "71e4fa61-0420-5a47-8362-38d1db6b5895"
                    ]
                },
                "D-2bt": {
                    "questionId": "D-2bt",
                    "chosenOptionIds": [
                        "618070b6-e9e5-599a-a906-eed44f5c2dd9"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "fd9c4394-6664-5144-8797-00d27a4dda01",
                        "bccbebb3-ed8c-5251-856b-e93a1d4386f6",
                        "75d33f2f-6fcd-5e09-8574-899eabeb22e6"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5c30c60e-d53e-5cc3-b4a9-fdad9bec4cb7"
                    ]
                },
                "D-3bl": {
                    "questionId": "D-3bl",
                    "freeValue": 0.08333333333333333
                },
                "D-3bs": {
                    "questionId": "D-3bs",
                    "chosenOptionIds": [
                        "fe554f25-dfd7-5062-a2b8-f2662e2c3466"
                    ]
                },
                "D-3bt": {
                    "questionId": "D-3bt",
                    "chosenOptionIds": [
                        "0d89dae6-d14a-56da-8409-ea7c21e59375"
                    ]
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-42bt": {
                    "questionId": "D-42bt",
                    "freeValue": 0
                },
                "D-43bt": {
                    "questionId": "D-43bt",
                    "chosenOptionIds": [
                        "90e60395-fc3d-56cb-b6f7-2e24fba02803"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b"
                    ]
                },
                "D-4bs": {
                    "questionId": "D-4bs",
                    "freeValue": 0.08333333333333333
                },
                "D-4bt": {
                    "questionId": "D-4bt",
                    "freeValue": 0.08333333333333333
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "dde1af45-4686-52c9-b960-369527b28f6c"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "772bee29-c3a5-52e1-b785-a77d506ae4fb"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "d362cd87-7515-551e-bc74-19ef903484aa"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "13f8bac1-d0f5-539b-8596-1c55821bea46"
                    ]
                },
                "D-s10bt": {
                    "questionId": "D-s10bt",
                    "chosenOptionIds": [
                        "ce0e07f8-36b3-5424-a489-abd9de8a21a4"
                    ]
                },
                "D-s1bt": {
                    "questionId": "D-s1bt",
                    "chosenOptionIds": [
                        "33dd907f-3e0e-5735-96bb-c101f0acced9"
                    ]
                },
                "D-s2bt": {
                    "questionId": "D-s2bt",
                    "chosenOptionIds": [
                        "aef7f114-e448-575f-bcbb-035e54001375"
                    ]
                },
                "D-s3bt": {
                    "questionId": "D-s3bt",
                    "chosenOptionIds": [
                        "11f57889-ac93-53ab-88a7-9aea03a29429"
                    ]
                },
                "D-s4bt": {
                    "questionId": "D-s4bt",
                    "chosenOptionIds": [
                        "a4021497-af94-56a4-8075-392653262e2e"
                    ]
                },
                "D-s5bt": {
                    "questionId": "D-s5bt",
                    "chosenOptionIds": [
                        "055edc25-4e0b-55a7-8739-145992d4156a"
                    ]
                },
                "D-s6bt": {
                    "questionId": "D-s6bt",
                    "chosenOptionIds": [
                        "f7aa2ac0-a087-5371-8266-7601901cd1f9"
                    ]
                },
                "D-s7bt": {
                    "questionId": "D-s7bt",
                    "chosenOptionIds": [
                        "1df67815-0b9f-56ef-bd48-96ed977db141"
                    ]
                },
                "D-s8bt": {
                    "questionId": "D-s8bt",
                    "chosenOptionIds": [
                        "ebfe9c3e-852e-5116-8185-8430f6b361f8"
                    ]
                },
                "D-s9bt": {
                    "questionId": "D-s9bt",
                    "chosenOptionIds": [
                        "da503bbd-e567-576e-a21f-e0fad60dccdd"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "60fe0bb4-d770-56f2-b05a-864625a48e4d"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "ccbdcbec-96d0-5362-977b-784289f5cfc0"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "db7aa4b7-99a5-5475-a4e8-a744f3ef5a4a"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "e9eb902a-d327-5ec5-938c-dc317ca59b13"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "d3224e16-1b0c-5266-9e14-ac72d2b2e988"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "b24df75b-6bb9-5f5f-99e3-c883ec3644ec"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "de4464c8-78fe-5876-aa00-25826ce5ec25"
                    ]
                },
                "BP105": {
                    "questionId": "BP105",
                    "chosenOptionIds": [
                        "4e9d21b3-29c7-5526-ada9-67b61957994e"
                    ]
                },
                "BP12": {
                    "questionId": "BP12",
                    "chosenOptionIds": [
                        "22f9a995-3f9a-5fe9-99bb-71b7f1f96621"
                    ]
                },
                "BP15": {
                    "questionId": "BP15",
                    "chosenOptionIds": [
                        "e9643ff4-2842-5b40-9c98-5b5d0e9b6a5e"
                    ]
                },
                "BP15.1": {
                    "questionId": "BP15.1",
                    "chosenOptionIds": [
                        "35a5b342-38f4-5343-8a4f-a3f024b95530"
                    ]
                },
                "BP15.2": {
                    "questionId": "BP15.2",
                    "freeValue": 3650
                },
                "BP15.3": {
                    "questionId": "BP15.3",
                    "chosenOptionIds": [
                        "cfa519ff-08a9-5fa4-b12d-87b7a597cb13"
                    ]
                },
                "BP16": {
                    "questionId": "BP16",
                    "chosenOptionIds": [
                        "fabceeea-8fea-5444-8b67-8f189f366c27"
                    ]
                },
                "BP16.0": {
                    "questionId": "BP16.0",
                    "chosenOptionIds": [
                        "4b26caa5-9124-51b2-ab08-2f4e977cee1f"
                    ]
                },
                "BP16.01": {
                    "questionId": "BP16.01",
                    "chosenOptionIds": [
                        "f81655d7-78e5-59b1-a7eb-90a2ec7a107d"
                    ]
                },
                "BP16.02": {
                    "questionId": "BP16.02",
                    "chosenOptionIds": [
                        "16a695c9-3ac1-5d34-b799-44602d85b556"
                    ]
                },
                "BP19": {
                    "questionId": "BP19",
                    "chosenOptionIds": [
                        "601bc370-8a4c-5b1f-a7f6-6c174f421733"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 30
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "f03de28d-8f71-5af4-be2f-45f4e6092a3d"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "9ae96e50-5c86-58a7-a845-fb6347c7edd1"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "5f694075-3a97-5f8b-8701-2710a43127a9"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "2404146e-cd56-5356-a23b-6427b4bbba06"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "5d5fa968-aeb3-569a-8a71-483ef04b9a6a"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "e22cf233-c174-52fb-af55-97bf9c46e971"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "0feda24f-7b01-557b-8ca5-10eb924cbdac"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "d680a8d9-e35f-563f-9d1d-e7c3136aff6d"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "f35fe383-d2aa-51c4-9fde-961b1de6d52b"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "732c51f9-a0b6-5951-bd7d-eaaec24316ba"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "dbab3669-e910-5267-b7b1-fcb661ea7e81"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "2723ed09-37f6-522c-92b1-e518d4fe9315"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "3cf77140-2757-58eb-b4ea-f2a90cd766e7"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "5c06d516-e816-58d6-997e-f8fa91131ac7"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "c0cc09c3-1335-5eb4-8801-b3cdaf74f6fa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM20": {
                    "questionId": "HM20",
                    "chosenOptionIds": [
                        "4ff0f88c-e951-5e87-a941-815105819115"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "98ea07a0-ae95-570f-8d11-a3e7c682e986"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "696fdd9d-5ced-504b-a933-bb3e575b0099"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c41974b6-d394-569c-8f45-49f79fcac5c6"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "43c32319-c75f-5169-b0e5-713820721d4f"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "d2c670c4-b05b-597a-a0af-5342230535a0"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "0170b4cf-0541-53cd-a9db-818d5067dc23"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "6b35cd3b-d67a-54af-94f6-e39ddf5af6c6"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "0983ccf5-a426-53d0-adbb-1baf6832190c"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "a56fcb96-f868-5d08-a358-9b403eb3cd16"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "fa32b497-4a4b-5076-a391-b6dffa8e1851"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "bde4e5f3-19e4-571f-84a2-5b9d188d74ef"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "1d07911e-929d-5719-aeea-a68f593d18e8"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: 4EG443P aspect: simple_mdd should be subclinical",
            "expected": {
                "type": "Severity",
                "value": "subclinical"
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "c61d70d4-b773-54dd-94d0-d858a47be0fb"
                    ]
                },
                "D-1.5b": {
                    "questionId": "D-1.5b",
                    "freeValue": 8
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "3bd18f66-6f1e-5ea4-ae24-2990ed197bef"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "eb68a88d-b49b-5cde-a9c6-5e4c78e27d19"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "a0e528ca-2fbb-5da1-9398-0758f79755aa"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 60
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "7f1d6182-f8a7-5f4e-a0f8-2556b306ae36"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-1bl": {
                    "questionId": "D-1bl",
                    "chosenOptionIds": [
                        "4105c26a-4ff2-5fd7-984d-f0bcb7b5656e"
                    ]
                },
                "D-2": {
                    "questionId": "D-2",
                    "freeValue": 13
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "11f457f5-4c39-5458-ba9a-84478dd379ba"
                    ]
                },
                "D-2bl": {
                    "questionId": "D-2bl",
                    "chosenOptionIds": [
                        "02464c9b-d1d8-5f60-993d-5f37cbfcc102"
                    ]
                },
                "D-2bs": {
                    "questionId": "D-2bs",
                    "chosenOptionIds": [
                        "7caa4281-408a-5c83-b2b0-b42968a69322"
                    ]
                },
                "D-2bt": {
                    "questionId": "D-2bt",
                    "chosenOptionIds": [
                        "d585762b-2491-592f-9340-3053d9151b68"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "e797900f-129d-553e-b83f-8494d7f03986"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5c30c60e-d53e-5cc3-b4a9-fdad9bec4cb7"
                    ]
                },
                "D-3bl": {
                    "questionId": "D-3bl",
                    "freeValue": 7
                },
                "D-3bs": {
                    "questionId": "D-3bs",
                    "chosenOptionIds": [
                        "fe554f25-dfd7-5062-a2b8-f2662e2c3466"
                    ]
                },
                "D-3bt": {
                    "questionId": "D-3bt",
                    "chosenOptionIds": [
                        "0d89dae6-d14a-56da-8409-ea7c21e59375"
                    ]
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-42bt": {
                    "questionId": "D-42bt",
                    "freeValue": 0
                },
                "D-43bt": {
                    "questionId": "D-43bt",
                    "chosenOptionIds": [
                        "90e60395-fc3d-56cb-b6f7-2e24fba02803"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "9d1b858c-7064-5a84-80d6-786c18e603b6",
                        "1d4beed1-78e5-531c-a255-643fac260dee"
                    ]
                },
                "D-4bs": {
                    "questionId": "D-4bs",
                    "freeValue": 7
                },
                "D-4bt": {
                    "questionId": "D-4bt",
                    "freeValue": 1
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "188a7831-38c4-55c4-880a-1e4db4e9bab1"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "ef6fddab-7a07-5f20-af20-74f2444eee53"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "4c78ae3e-7de4-545f-aca1-03fa41fc0c23"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "13f8bac1-d0f5-539b-8596-1c55821bea46"
                    ]
                },
                "D-s10bt": {
                    "questionId": "D-s10bt",
                    "chosenOptionIds": [
                        "e3ab5219-3af6-5da7-8464-a145eb508a0f"
                    ]
                },
                "D-s11bt": {
                    "questionId": "D-s11bt",
                    "chosenOptionIds": [
                        "daf8b3c7-fb19-5a3f-ba9a-784adda7414f",
                        "dc476220-9479-5def-8a97-20e6152d44af",
                        "9c3fa6af-8b7b-5993-a587-641683d934d5",
                        "6994b88e-cfa5-5986-b9e8-625d17d1147c",
                        "16e1a0b8-28c5-5bcd-931d-335c858c3f88",
                        "64af3cae-713a-51e1-99c1-09e2fdaa755d",
                        "7921bccf-4756-59fe-b678-d536c2ab5945"
                    ]
                },
                "D-s1bt": {
                    "questionId": "D-s1bt",
                    "chosenOptionIds": [
                        "c17482e4-818a-50cd-90fa-1246d037825e"
                    ]
                },
                "D-s2bt": {
                    "questionId": "D-s2bt",
                    "chosenOptionIds": [
                        "aef7f114-e448-575f-bcbb-035e54001375"
                    ]
                },
                "D-s3bt": {
                    "questionId": "D-s3bt",
                    "chosenOptionIds": [
                        "11f57889-ac93-53ab-88a7-9aea03a29429"
                    ]
                },
                "D-s4bt": {
                    "questionId": "D-s4bt",
                    "chosenOptionIds": [
                        "fd9851a0-7ce7-55a8-b257-5617537d0013",
                        "a4021497-af94-56a4-8075-392653262e2e"
                    ]
                },
                "D-s5bt": {
                    "questionId": "D-s5bt",
                    "chosenOptionIds": [
                        "8260d432-3a96-519e-b824-d24940e4aa65"
                    ]
                },
                "D-s6bt": {
                    "questionId": "D-s6bt",
                    "chosenOptionIds": [
                        "bb7e3f7e-73b0-56b0-b3d3-e336498509e8"
                    ]
                },
                "D-s7bt": {
                    "questionId": "D-s7bt",
                    "chosenOptionIds": [
                        "d703f0d6-4b67-5a66-8740-b5e4b3b0eb4d"
                    ]
                },
                "D-s8bt": {
                    "questionId": "D-s8bt",
                    "chosenOptionIds": [
                        "b5d48aea-22b2-52a7-9306-3f2a4bcedc6c"
                    ]
                },
                "D-s9bt": {
                    "questionId": "D-s9bt",
                    "chosenOptionIds": [
                        "da503bbd-e567-576e-a21f-e0fad60dccdd"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "60fe0bb4-d770-56f2-b05a-864625a48e4d"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "6215a3de-a3e4-5f86-a039-1006646e6b93"
                    ]
                },
                "BP106": {
                    "questionId": "BP106",
                    "chosenOptionIds": [
                        "8c35da7e-8c0b-50bb-a037-b80a9f1df3d2"
                    ]
                },
                "BP108": {
                    "questionId": "BP108",
                    "chosenOptionIds": [
                        "d25b13ec-c438-5509-85b0-83a43b6793d4"
                    ]
                },
                "BP110": {
                    "questionId": "BP110",
                    "freeValue": 60
                },
                "BP110.01": {
                    "questionId": "BP110.01",
                    "chosenOptionIds": [
                        "a6d87c31-9a11-5151-958a-040439463b3d"
                    ]
                },
                "BP110.1": {
                    "questionId": "BP110.1",
                    "chosenOptionIds": [
                        "9d19a3c0-120d-5dfd-a794-8aa0e8d39e77"
                    ]
                },
                "BP110.2": {
                    "questionId": "BP110.2",
                    "chosenOptionIds": [
                        "d15430b9-4f4f-5c97-8716-cb907c561dd2"
                    ]
                },
                "BP111": {
                    "questionId": "BP111",
                    "chosenOptionIds": [
                        "02b20612-0b18-5aba-91d5-3ebd2589fb3d"
                    ]
                },
                "BP111.1": {
                    "questionId": "BP111.1",
                    "chosenOptionIds": [
                        "c532b673-2762-5ed0-8cbb-6f70d9977c20"
                    ]
                },
                "BP111.2": {
                    "questionId": "BP111.2",
                    "chosenOptionIds": [
                        "4c9c60d0-cd73-504d-8b03-b734045376ae"
                    ]
                },
                "BP112": {
                    "questionId": "BP112",
                    "chosenOptionIds": [
                        "6db4ba23-1d8f-58b9-b11e-eaea15417e28"
                    ]
                },
                "BP113": {
                    "questionId": "BP113",
                    "chosenOptionIds": [
                        "b16103eb-2ecf-595e-80e4-30baf575e01b"
                    ]
                },
                "BP114": {
                    "questionId": "BP114",
                    "chosenOptionIds": [
                        "a0206e39-59ad-539c-a2ff-e9fd935cf48b"
                    ]
                },
                "BP115": {
                    "questionId": "BP115",
                    "chosenOptionIds": [
                        "dd02d36a-25b2-5daa-8cc6-5bb2d1382229"
                    ]
                },
                "BP116": {
                    "questionId": "BP116",
                    "chosenOptionIds": [
                        "2d30ef1e-59ee-5b0a-8c01-0dcbfd64f6ca"
                    ]
                },
                "BP117": {
                    "questionId": "BP117",
                    "chosenOptionIds": [
                        "354c012f-3484-58ad-9300-d9e29a212ae3"
                    ]
                },
                "BP118": {
                    "questionId": "BP118",
                    "chosenOptionIds": [
                        "a611c3a0-727b-5a9b-8d15-d805b2ecf757"
                    ]
                },
                "BP118.1": {
                    "questionId": "BP118.1",
                    "chosenOptionIds": [
                        "801fd4db-2f09-5e10-a9b7-43319a3ac1d0"
                    ]
                },
                "BP119": {
                    "questionId": "BP119",
                    "chosenOptionIds": [
                        "50c573e0-accf-5acc-be15-616cb65704cc"
                    ]
                },
                "BP119.1": {
                    "questionId": "BP119.1",
                    "freeValue": 0
                },
                "BP120": {
                    "questionId": "BP120",
                    "chosenOptionIds": [
                        "c8162ccf-aae0-5678-aeb9-fe02ed13ad99"
                    ]
                },
                "BP121": {
                    "questionId": "BP121",
                    "chosenOptionIds": [
                        "7caa27c0-01f2-586f-825c-7c1bd527ef54"
                    ]
                },
                "BP122": {
                    "questionId": "BP122",
                    "chosenOptionIds": [
                        "b1759380-318d-54b0-9224-163fb659558b"
                    ]
                },
                "BP123": {
                    "questionId": "BP123",
                    "chosenOptionIds": [
                        "972a0931-d7f1-5ad4-90af-91046fd7b193"
                    ]
                },
                "BP124": {
                    "questionId": "BP124",
                    "chosenOptionIds": [
                        "343ddd19-6dd5-54fb-a817-730184c2faf1"
                    ]
                },
                "BP125": {
                    "questionId": "BP125",
                    "chosenOptionIds": [
                        "8136d074-8df6-5a91-b6b6-e8ffcfb42d7c"
                    ]
                },
                "BP126": {
                    "questionId": "BP126",
                    "chosenOptionIds": [
                        "52d061e3-96b8-5053-893a-03758e4f0518"
                    ]
                },
                "BP127": {
                    "questionId": "BP127",
                    "chosenOptionIds": [
                        "1fc78738-6afa-5714-89a1-8d5698fb9f69"
                    ]
                },
                "BP127.1": {
                    "questionId": "BP127.1",
                    "chosenOptionIds": [
                        "a2bbf0b5-4203-5b73-89f8-05f59515356f"
                    ]
                },
                "BP127.3": {
                    "questionId": "BP127.3",
                    "chosenOptionIds": [
                        "38efc61e-4155-51f0-8b64-341f627e5482"
                    ]
                },
                "BP128": {
                    "questionId": "BP128",
                    "chosenOptionIds": [
                        "5bf8ce18-aa1a-5c57-86d6-687efcad92de"
                    ]
                },
                "BP129": {
                    "questionId": "BP129",
                    "chosenOptionIds": [
                        "4b90a2f8-960f-5d69-8e86-9c46af2fec0f"
                    ]
                },
                "BP130": {
                    "questionId": "BP130",
                    "chosenOptionIds": [
                        "a04d679f-abeb-5fb0-9db7-2ed9997ab672"
                    ]
                },
                "BP131": {
                    "questionId": "BP131",
                    "chosenOptionIds": [
                        "e5863f5d-32a6-520a-9b40-8591adf1c1b8"
                    ]
                },
                "BP132": {
                    "questionId": "BP132",
                    "chosenOptionIds": [
                        "7da9fe3a-3186-562a-b4f2-170d5baf0c76"
                    ]
                },
                "BP133": {
                    "questionId": "BP133",
                    "chosenOptionIds": [
                        "75391070-9bde-59e4-b44a-68f38b6a4472"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM35": {
                    "questionId": "HM35",
                    "chosenOptionIds": [
                        "2b40020d-33ba-5560-9075-d482aa324496"
                    ]
                },
                "HM36": {
                    "questionId": "HM36",
                    "chosenOptionIds": [
                        "fc7ec28a-9883-58d5-a088-f3b113c2b4b3"
                    ]
                },
                "HM37": {
                    "questionId": "HM37",
                    "chosenOptionIds": [
                        "20de534d-f482-51c5-ad87-bd054787fddc"
                    ]
                },
                "HM38": {
                    "questionId": "HM38",
                    "chosenOptionIds": [
                        "5125bbbb-70fb-5adc-a41b-c65c6b96180d"
                    ]
                },
                "HM40": {
                    "questionId": "HM40",
                    "chosenOptionIds": [
                        "596144bf-d0ea-5d17-a2ae-7096c31e2def"
                    ]
                },
                "HM41": {
                    "questionId": "HM41",
                    "chosenOptionIds": [
                        "db39ddc0-79fd-5c61-b063-7f0bc0bab024"
                    ]
                },
                "HM43": {
                    "questionId": "HM43",
                    "chosenOptionIds": [
                        "08b88c09-4278-5bd5-b72d-4f4a9a69acd0"
                    ]
                },
                "HM44": {
                    "questionId": "HM44",
                    "chosenOptionIds": [
                        "00b843b3-a869-5c49-8c2b-f15c3bfbbb6d"
                    ]
                },
                "HM45": {
                    "questionId": "HM45",
                    "chosenOptionIds": [
                        "2722d440-372d-5471-8cab-ef78b8026a24"
                    ]
                },
                "HM46": {
                    "questionId": "HM46",
                    "chosenOptionIds": [
                        "9f868300-e1df-5567-8ebf-e2f984022e52"
                    ]
                },
                "HM47": {
                    "questionId": "HM47",
                    "chosenOptionIds": [
                        "e03a7683-7e3e-563d-b531-33e529887c90"
                    ]
                },
                "HM48": {
                    "questionId": "HM48",
                    "chosenOptionIds": [
                        "2db89264-9c8a-54e5-8efc-1b1ca45b0233"
                    ]
                },
                "HM49": {
                    "questionId": "HM49",
                    "chosenOptionIds": [
                        "ad65e31f-19f9-587c-bc99-0b3549cc5cb0"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: 73ZW63R aspect: simple_mdd should be subclinical",
            "expected": {
                "type": "Severity",
                "value": "subclinical"
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "c61d70d4-b773-54dd-94d0-d858a47be0fb"
                    ]
                },
                "D-1.5b": {
                    "questionId": "D-1.5b",
                    "freeValue": 100
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "a16e8e14-524e-53df-b4ef-730a2c8e5f32"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "eb68a88d-b49b-5cde-a9c6-5e4c78e27d19"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "a0e528ca-2fbb-5da1-9398-0758f79755aa"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 3650
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "db360e93-3616-5d89-be36-5ccee4aacf1c"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-1bl": {
                    "questionId": "D-1bl",
                    "chosenOptionIds": [
                        "5da400b0-5bff-5744-a664-4305892ae669"
                    ]
                },
                "D-2": {
                    "questionId": "D-2",
                    "freeValue": 6
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "11f457f5-4c39-5458-ba9a-84478dd379ba"
                    ]
                },
                "D-2bl": {
                    "questionId": "D-2bl",
                    "chosenOptionIds": [
                        "2a8c96e2-cb5b-596a-99b6-a4885253776c"
                    ]
                },
                "D-2bs": {
                    "questionId": "D-2bs",
                    "chosenOptionIds": [
                        "7caa4281-408a-5c83-b2b0-b42968a69322"
                    ]
                },
                "D-2bt": {
                    "questionId": "D-2bt",
                    "chosenOptionIds": [
                        "d585762b-2491-592f-9340-3053d9151b68"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "e797900f-129d-553e-b83f-8494d7f03986"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "db7514e2-d317-55fb-82d3-2e4a49c2494b"
                    ]
                },
                "D-3bl": {
                    "questionId": "D-3bl",
                    "freeValue": 180
                },
                "D-3bs": {
                    "questionId": "D-3bs",
                    "chosenOptionIds": [
                        "c4422e8a-6a0a-51ea-8592-9157036922d1"
                    ]
                },
                "D-3bt": {
                    "questionId": "D-3bt",
                    "chosenOptionIds": [
                        "1a1865e2-c338-59ef-a2db-6e2cc7a17d77"
                    ]
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-42bt": {
                    "questionId": "D-42bt",
                    "freeValue": 2
                },
                "D-43bt": {
                    "questionId": "D-43bt",
                    "chosenOptionIds": [
                        "90e60395-fc3d-56cb-b6f7-2e24fba02803"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "9d1b858c-7064-5a84-80d6-786c18e603b6",
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b"
                    ]
                },
                "D-4bs": {
                    "questionId": "D-4bs",
                    "freeValue": 90
                },
                "D-4bt": {
                    "questionId": "D-4bt",
                    "freeValue": 30
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "b52073e3-c813-51f5-88ba-9cd3bddd7023"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "08a0f83f-2f37-5904-9364-4e40deb90e98"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "772bee29-c3a5-52e1-b785-a77d506ae4fb"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "67316d94-1cf0-5bd0-a4db-f57bc70684ba"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "f8e2518c-72cf-563b-b322-a03406c8ebac"
                    ]
                },
                "D-s10bt": {
                    "questionId": "D-s10bt",
                    "chosenOptionIds": [
                        "e3ab5219-3af6-5da7-8464-a145eb508a0f"
                    ]
                },
                "D-s11bt": {
                    "questionId": "D-s11bt",
                    "chosenOptionIds": [
                        "daf8b3c7-fb19-5a3f-ba9a-784adda7414f",
                        "dc476220-9479-5def-8a97-20e6152d44af",
                        "9c3fa6af-8b7b-5993-a587-641683d934d5",
                        "6994b88e-cfa5-5986-b9e8-625d17d1147c",
                        "16e1a0b8-28c5-5bcd-931d-335c858c3f88",
                        "bae5acc8-8664-54a2-ba97-c36244be4c44",
                        "64af3cae-713a-51e1-99c1-09e2fdaa755d",
                        "7921bccf-4756-59fe-b678-d536c2ab5945",
                        "049f46ae-b7bd-5a1e-8c63-351f481236ec"
                    ]
                },
                "D-s1bt": {
                    "questionId": "D-s1bt",
                    "chosenOptionIds": [
                        "5ebc2bd4-4b83-5875-b7b0-dd3698a7674c"
                    ]
                },
                "D-s2bt": {
                    "questionId": "D-s2bt",
                    "chosenOptionIds": [
                        "8783a6d5-cd94-5982-8fd6-66ae46e1c6df"
                    ]
                },
                "D-s3bt": {
                    "questionId": "D-s3bt",
                    "chosenOptionIds": [
                        "d065c0ca-9e6d-5300-8e3e-688b16f88c59"
                    ]
                },
                "D-s4bt": {
                    "questionId": "D-s4bt",
                    "chosenOptionIds": [
                        "fd9851a0-7ce7-55a8-b257-5617537d0013",
                        "5a198500-fc4b-55a1-9521-3abe49eed06f",
                        "a4021497-af94-56a4-8075-392653262e2e"
                    ]
                },
                "D-s5bt": {
                    "questionId": "D-s5bt",
                    "chosenOptionIds": [
                        "8260d432-3a96-519e-b824-d24940e4aa65"
                    ]
                },
                "D-s6bt": {
                    "questionId": "D-s6bt",
                    "chosenOptionIds": [
                        "57e8cb6f-9ce4-5078-acb5-c3f96ce8e463"
                    ]
                },
                "D-s7bt": {
                    "questionId": "D-s7bt",
                    "chosenOptionIds": [
                        "1df67815-0b9f-56ef-bd48-96ed977db141"
                    ]
                },
                "D-s8bt": {
                    "questionId": "D-s8bt",
                    "chosenOptionIds": [
                        "1a4dbf6d-5e13-509e-b1f1-1f8aa1072529"
                    ]
                },
                "D-s9bt": {
                    "questionId": "D-s9bt",
                    "chosenOptionIds": [
                        "c9f1ca13-44a9-5bfb-8999-eb1f82965c59"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "60fe0bb4-d770-56f2-b05a-864625a48e4d"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "83e6efd5-64a2-5f0e-a973-9278eae90cfb"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "a20d7b84-720e-53c5-a450-45cbde434507"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "ca5ac96d-2b69-5200-a063-61db840c9ef2"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "e76cac02-f6b2-5ab6-a467-e3025d857ab9"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "b24df75b-6bb9-5f5f-99e3-c883ec3644ec"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 12
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "c7bee7a0-3037-5eb1-96a1-85b884d7e1dd"
                    ]
                },
                "BP3.01": {
                    "questionId": "BP3.01",
                    "freeValue": 3
                },
                "BP3.1": {
                    "questionId": "BP3.1",
                    "chosenOptionIds": [
                        "11a8ab35-b2d7-55a4-8a77-809dc8f1f78a"
                    ]
                },
                "BP3.2": {
                    "questionId": "BP3.2",
                    "chosenOptionIds": [
                        "f6654515-5729-5299-a4f0-da99056e7ac9"
                    ]
                },
                "BP3.3": {
                    "questionId": "BP3.3",
                    "freeValue": 30
                },
                "BP3.4": {
                    "questionId": "BP3.4",
                    "chosenOptionIds": [
                        "f304bf93-0c68-5da0-80ba-ccecc2d0fce1"
                    ]
                },
                "BP4": {
                    "questionId": "BP4",
                    "chosenOptionIds": [
                        "4884cc9c-7210-5173-9438-d1eae9e9a6a8"
                    ]
                },
                "BP4.5": {
                    "questionId": "BP4.5",
                    "chosenOptionIds": [
                        "1be4b3b1-3a88-5544-af54-ebdfee1ce891"
                    ]
                },
                "BP6.5": {
                    "questionId": "BP6.5",
                    "chosenOptionIds": [
                        "58656f9f-9267-5c1d-9132-f342d05dc39e"
                    ]
                },
                "BP6.7": {
                    "questionId": "BP6.7",
                    "chosenOptionIds": [
                        "7cb3515b-004f-58a1-a02b-59c7d9510637"
                    ]
                },
                "BP6.8": {
                    "questionId": "BP6.8",
                    "freeValue": 30
                },
                "BP6.9": {
                    "questionId": "BP6.9",
                    "chosenOptionIds": [
                        "155da231-22f3-50d9-8786-2860edd50aab"
                    ]
                },
                "BP8": {
                    "questionId": "BP8",
                    "chosenOptionIds": [
                        "87e0081b-9455-5ced-9225-10c2f70b1734"
                    ]
                },
                "BP8.0": {
                    "questionId": "BP8.0",
                    "chosenOptionIds": [
                        "3568cdb1-2500-512b-aada-2547c5bfd835"
                    ]
                },
                "BP8.01": {
                    "questionId": "BP8.01",
                    "chosenOptionIds": [
                        "5020bfdd-a280-56cf-b7f5-3b0b7e667165"
                    ]
                },
                "BP8.02": {
                    "questionId": "BP8.02",
                    "chosenOptionIds": [
                        "e88670a3-c6d8-5c96-914e-b9fc1818aeda"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "960a08ff-7af7-56da-afda-5415a1654c85"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "f501dc76-7fcd-586b-ba1d-ad70ab8e8d33"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "28c75e9e-94ef-5c50-8645-f4ff93ccf2ec"
                    ]
                },
                "BP9": {
                    "questionId": "BP9",
                    "chosenOptionIds": [
                        "becfe35c-2096-5848-87ae-3f763cf59dd6"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "fd30dc27-8e89-5d1a-9d95-8844603d4379"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "c211fabc-a62d-5440-8ddc-f57eb21d7679"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "1f57d1a0-3682-5d7d-b66b-8179ec5c3988"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "ef5a7b22-b2b2-5a5b-8a80-ce5c251033fd"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "94795194-4a80-590f-be62-ee78f2fa0ff5"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "ec84966e-bb9f-5ba9-a0cf-d5d3503a74e0"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "9b27e3ff-f8ce-54c5-9220-0479ae53e889"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "352885c9-c1e1-5206-9f52-8d9b6d2aa166"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "b14d53e4-f898-52ac-87d7-3aa62abc9d7b"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "63509127-aadf-5fe2-8e1f-19d0d846d6ce"
                    ]
                },
                "BP99.1": {
                    "questionId": "BP99.1",
                    "chosenOptionIds": [
                        "34d19f12-207f-56ce-bde8-8d616f6e8b26"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "dee1118a-2ca3-52a6-ac43-a24ea7385f88"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM1": {
                    "questionId": "HM1",
                    "chosenOptionIds": [
                        "c44193b6-642c-56d3-ada3-eda9bd17a42e"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "c3ee8981-dec3-5b3c-80ca-c1023f983a6d"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "9d212885-9b07-5623-8234-226adff08f6d"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "3ab9515b-73f0-5fcf-a4d0-aebbc24c2721"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "0e9545ba-3f35-52b0-9a31-7fe54c933928"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "eb41dc92-16ec-5abf-a841-e1d0b262083e"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "cec918d6-b855-5672-94a8-cf14e5ae963a"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "7b310da1-94e3-5d45-a6c3-3905eeb28ec6"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "0983ccf5-a426-53d0-adbb-1baf6832190c"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "834b04f2-af09-5337-8fd5-be4a70c99e75"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "fda00d52-c8be-5b69-bdbd-905c13dddd4e"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "0360ab4b-77d5-5fcd-8d60-b7a005c12ceb"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "ba200400-b5a1-5ae8-aead-863c1a72be98"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: O0K1931 aspect: simple_mdd should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "0ec0e224-a5cf-5ece-b3ab-37061a600ace"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "a16e8e14-524e-53df-b4ef-730a2c8e5f32"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "916d657d-9f2a-5451-8465-18e3bd863347"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "b74511c7-9f97-5c15-8bf5-6ec4357b4814"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 42
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "db360e93-3616-5d89-be36-5ccee4aacf1c"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "de11fb6b-7e7d-52b1-9d09-2d6debc53314"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5c30c60e-d53e-5cc3-b4a9-fdad9bec4cb7"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "c9813959-ba61-5220-9e27-659abb256037"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "228ef7d9-008b-559d-ba14-6b928b486b38"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "d362cd87-7515-551e-bc74-19ef903484aa"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "4896b504-2025-52c3-85e2-c6cf3205cef7"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "7599bcaa-89a5-5f71-80ea-b70c360bb44a"
                    ]
                },
                "BP101b": {
                    "questionId": "BP101b",
                    "chosenOptionIds": [
                        "31b2d2c8-8f28-514e-9e58-aa878f6c8761"
                    ]
                },
                "BP102b": {
                    "questionId": "BP102b",
                    "chosenOptionIds": [
                        "46a64b5b-6641-5b1b-92aa-5366444fb134"
                    ]
                },
                "BP103b": {
                    "questionId": "BP103b",
                    "chosenOptionIds": [
                        "867b0ee4-73d9-5aac-b96d-bc2d85d5b183"
                    ]
                },
                "BP104b": {
                    "questionId": "BP104b",
                    "chosenOptionIds": [
                        "c59b5910-3279-509f-8175-f2fd0da2dc86"
                    ]
                },
                "BP87b": {
                    "questionId": "BP87b",
                    "chosenOptionIds": [
                        "fb568891-2ea3-5200-bbdb-ac0695f30b02"
                    ]
                },
                "BP88b": {
                    "questionId": "BP88b",
                    "chosenOptionIds": [
                        "e29e159f-f691-5aa7-b5a0-d13fe2c54170"
                    ]
                },
                "BP89b": {
                    "questionId": "BP89b",
                    "chosenOptionIds": [
                        "df5f6aa3-ab8c-5a4c-b9d9-e9a482aba1d7"
                    ]
                },
                "BP90b": {
                    "questionId": "BP90b",
                    "chosenOptionIds": [
                        "607460aa-6bfa-56fd-9d06-75ec91ef85a1"
                    ]
                },
                "BP92b": {
                    "questionId": "BP92b",
                    "chosenOptionIds": [
                        "dd87a55d-829b-54a2-a63d-5b8b634688ea"
                    ]
                },
                "BP94b": {
                    "questionId": "BP94b",
                    "chosenOptionIds": [
                        "1f4fafbf-913e-5edc-bfaf-3baae49fc91d"
                    ]
                },
                "BP95b": {
                    "questionId": "BP95b",
                    "chosenOptionIds": [
                        "fb0bd4a7-2793-5b81-badc-fe81b4bc82dc"
                    ]
                },
                "BP96b": {
                    "questionId": "BP96b",
                    "chosenOptionIds": [
                        "477d4084-be50-50ae-9e88-dc9e03cdad29"
                    ]
                },
                "BP97b": {
                    "questionId": "BP97b",
                    "chosenOptionIds": [
                        "6895096a-ef29-546e-9967-d7a23b4a2859"
                    ]
                },
                "BP98b": {
                    "questionId": "BP98b",
                    "chosenOptionIds": [
                        "f7b75a2b-3bd2-5734-9fa6-7706f7f8ac89"
                    ]
                },
                "BP99.3b": {
                    "questionId": "BP99.3b",
                    "chosenOptionIds": [
                        "bf30a6c2-f8e2-5305-b4d4-7ad6227449d3"
                    ]
                },
                "BP99b": {
                    "questionId": "BP99b",
                    "chosenOptionIds": [
                        "68860df1-e835-50d6-8eb2-f9d9f2f97efa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "BP_neg1": {
                    "questionId": "BP_neg1",
                    "chosenOptionIds": [
                        "119208b0-ff3b-5c43-80be-4f07e65b6248"
                    ]
                }
            }
        },
        {
            "name": "Mood test: J0V6N3L aspect: simple_mdd should be severe",
            "expected": {
                "type": "Severity",
                "value": "severe"
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "fe031280-7d5d-52cb-aca8-1a8664b3234a"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "6ede390f-0e43-50e4-82e9-1203db9d8343"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "a0e528ca-2fbb-5da1-9398-0758f79755aa"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 60
                },
                "D-15a": {
                    "questionId": "D-15a",
                    "chosenOptionIds": [
                        "7b763ff0-7703-54f7-8eae-aa83417ca133"
                    ]
                },
                "D-16.5a": {
                    "questionId": "D-16.5a",
                    "chosenOptionIds": [
                        "ae0a4d4e-e9bf-51bf-8506-0cbced38db92"
                    ]
                },
                "D-17a": {
                    "questionId": "D-17a",
                    "chosenOptionIds": [
                        "495d3d07-8a1f-5213-bf79-59443d7aa5cf"
                    ]
                },
                "D-18a": {
                    "questionId": "D-18a",
                    "chosenOptionIds": [
                        "615b852b-6f81-54a9-bc4a-a8843e82c230"
                    ]
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "80c993c8-1a94-5684-a8f9-d8f5072c8e9c"
                    ]
                },
                "D-1bl": {
                    "questionId": "D-1bl",
                    "chosenOptionIds": [
                        "87ef50c9-ba5e-513e-8889-09d8395d4b9f"
                    ]
                },
                "D-2": {
                    "questionId": "D-2",
                    "freeValue": 17
                },
                "D-20a": {
                    "questionId": "D-20a",
                    "chosenOptionIds": [
                        "b28ed556-a386-5556-a96f-ae7708ded1ab"
                    ]
                },
                "D-21a": {
                    "questionId": "D-21a",
                    "chosenOptionIds": [
                        "dc2b002f-2d96-504d-bde9-66faceececb6"
                    ]
                },
                "D-23a": {
                    "questionId": "D-23a",
                    "chosenOptionIds": [
                        "f6cf63c5-0c57-5422-9431-55fec4c93c63"
                    ]
                },
                "D-26a": {
                    "questionId": "D-26a",
                    "chosenOptionIds": [
                        "823c244d-25bb-5b84-9f3c-16e82c594aee"
                    ]
                },
                "D-27a": {
                    "questionId": "D-27a",
                    "chosenOptionIds": [
                        "13a1b08d-9817-5c2f-96cb-f8d03b80a37d"
                    ]
                },
                "D-28a": {
                    "questionId": "D-28a",
                    "chosenOptionIds": [
                        "801353cf-2f72-53db-9179-e7ecc31b1fc1"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "cc243947-5067-5a98-ade6-a4e10d0b42cf"
                    ]
                },
                "D-2bl": {
                    "questionId": "D-2bl",
                    "chosenOptionIds": [
                        "78c1434b-e585-5f1d-b291-a1728f789022"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "75d33f2f-6fcd-5e09-8574-899eabeb22e6",
                        "bccbebb3-ed8c-5251-856b-e93a1d4386f6"
                    ]
                },
                "D-30a": {
                    "questionId": "D-30a",
                    "chosenOptionIds": [
                        "d309cef4-2204-5a10-a98d-b66740336838"
                    ]
                },
                "D-31a": {
                    "questionId": "D-31a",
                    "chosenOptionIds": [
                        "eba65e81-9b02-55d6-82cb-0b7ce6da41de"
                    ]
                },
                "D-32a": {
                    "questionId": "D-32a",
                    "chosenOptionIds": [
                        "ea2a4fbd-008a-571b-bb68-cbd0ba351521"
                    ]
                },
                "D-33a": {
                    "questionId": "D-33a",
                    "chosenOptionIds": [
                        "3968dd70-f060-5cbd-bb6a-d63f702a3691"
                    ]
                },
                "D-34a": {
                    "questionId": "D-34a",
                    "chosenOptionIds": [
                        "e919c54f-d9a4-5a15-9ae7-8b6b52607a22"
                    ]
                },
                "D-35a": {
                    "questionId": "D-35a",
                    "chosenOptionIds": [
                        "3a07e052-2332-5a23-bb19-3147a5b9d2c7"
                    ]
                },
                "D-36a": {
                    "questionId": "D-36a",
                    "chosenOptionIds": [
                        "185038dd-ce55-53b2-ba09-0bd163af90b3"
                    ]
                },
                "D-37a": {
                    "questionId": "D-37a",
                    "chosenOptionIds": [
                        "c8ebfb14-b9aa-5da4-8de3-ab8b83254249"
                    ]
                },
                "D-38a": {
                    "questionId": "D-38a",
                    "chosenOptionIds": [
                        "a03f02de-f86d-525a-b375-383ad9d3cefe"
                    ]
                },
                "D-39a": {
                    "questionId": "D-39a",
                    "chosenOptionIds": [
                        "6325d970-8ab3-5759-b71d-96b5909ec039"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5b6c0a69-6369-53d1-b2e1-5cd6294a9f71"
                    ]
                },
                "D-3bl": {
                    "questionId": "D-3bl",
                    "freeValue": 730
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-40a": {
                    "questionId": "D-40a",
                    "chosenOptionIds": [
                        "582119a7-d4a0-58ff-9b1d-2af045e11a7f"
                    ]
                },
                "D-41a": {
                    "questionId": "D-41a",
                    "chosenOptionIds": [
                        "cb89e9b0-6872-5961-bc91-049308b5860f"
                    ]
                },
                "D-42a": {
                    "questionId": "D-42a",
                    "chosenOptionIds": [
                        "926931de-e675-5117-9e61-78dd541393be"
                    ]
                },
                "D-43a": {
                    "questionId": "D-43a",
                    "chosenOptionIds": [
                        "dc3faaa6-4a47-5998-83e3-4bbff8838194"
                    ]
                },
                "D-43bt": {
                    "questionId": "D-43bt",
                    "chosenOptionIds": [
                        "644dd944-5a2a-533b-9ef0-fadb5054c422"
                    ]
                },
                "D-44.5a": {
                    "questionId": "D-44.5a",
                    "chosenOptionIds": [
                        "bcf5fb9f-f4b9-5f0c-a964-69a4e1a73334"
                    ]
                },
                "D-44a": {
                    "questionId": "D-44a",
                    "chosenOptionIds": [
                        "7d6584ca-1b93-543d-9ee4-400ca40134be"
                    ]
                },
                "D-45a": {
                    "questionId": "D-45a",
                    "freeValue": 2
                },
                "D-46a": {
                    "questionId": "D-46a",
                    "chosenOptionIds": [
                        "1fd426df-808a-5c68-9b6e-23ff2f128b7d",
                        "d9665b1f-cdd2-5979-895e-27bca6487533",
                        "7492eab7-c625-52d0-a2e3-4bf3332decc0",
                        "6e49f368-62af-5abb-9034-a58bd534944f",
                        "f0b15a45-ae49-59d2-97a7-33f866b0e629",
                        "b3e43bd8-fbe3-5cda-b891-6f11e43993d0",
                        "4c178978-f79a-58fc-8e73-3a98614c4b6c"
                    ]
                },
                "D-49.1a": {
                    "questionId": "D-49.1a",
                    "chosenOptionIds": [
                        "7108af97-c956-5da8-8dfa-518e346c197c"
                    ]
                },
                "D-49.2a": {
                    "questionId": "D-49.2a",
                    "freeValue": 7
                },
                "D-49a": {
                    "questionId": "D-49a",
                    "chosenOptionIds": [
                        "16dc6af1-32a9-59c5-b66c-57f6becea1e0"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "ba6dc2ba-3c20-57b6-a276-32c863109ff6"
                    ]
                },
                "D-50a": {
                    "questionId": "D-50a",
                    "chosenOptionIds": [
                        "d14da42d-9612-5827-9583-b2bf528130de"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "b52073e3-c813-51f5-88ba-9cd3bddd7023"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "08a0f83f-2f37-5904-9364-4e40deb90e98"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "5d271ac0-beca-56cc-9d0b-adafcd67da53"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "4c78ae3e-7de4-545f-aca1-03fa41fc0c23"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "1200dadf-a0de-5a54-b0b8-fc640f7bc8d1"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "44fd9eb3-aee2-5af0-b83c-dbbbf9514022"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "83e6efd5-64a2-5f0e-a973-9278eae90cfb"
                    ]
                },
                "BP10": {
                    "questionId": "BP10",
                    "chosenOptionIds": [
                        "7ad54f0a-87a6-5e82-bfc3-44b540230082"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "182e121d-415c-5f42-a76c-a996f5f38b3f"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "1c4ed441-5919-569e-98ba-b49be1bc3957"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "a3a547f3-b48b-567f-a32a-6a3f5f4bacd7"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "b24df75b-6bb9-5f5f-99e3-c883ec3644ec"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 20
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "c7bee7a0-3037-5eb1-96a1-85b884d7e1dd"
                    ]
                },
                "BP3.01": {
                    "questionId": "BP3.01",
                    "freeValue": 4
                },
                "BP3.1": {
                    "questionId": "BP3.1",
                    "chosenOptionIds": [
                        "5bf1e313-2624-5b5f-9673-37ae9319ff0c"
                    ]
                },
                "BP3.2": {
                    "questionId": "BP3.2",
                    "chosenOptionIds": [
                        "c3319113-403a-5ecb-aa8a-071221d92c50"
                    ]
                },
                "BP3.3": {
                    "questionId": "BP3.3",
                    "freeValue": 2
                },
                "BP3.4": {
                    "questionId": "BP3.4",
                    "chosenOptionIds": [
                        "93412936-97c0-5128-ad67-3fa955db1300"
                    ]
                },
                "BP4": {
                    "questionId": "BP4",
                    "chosenOptionIds": [
                        "9765aba8-7d55-5928-b79d-36aa58646036"
                    ]
                },
                "BP4.5": {
                    "questionId": "BP4.5",
                    "chosenOptionIds": [
                        "66a3460c-65ce-5a4c-9774-2e303ae4961e"
                    ]
                },
                "BP6": {
                    "questionId": "BP6",
                    "chosenOptionIds": [
                        "0f2295c8-9598-5e68-a9a6-c87ca1f600af"
                    ]
                },
                "BP6.1": {
                    "questionId": "BP6.1",
                    "chosenOptionIds": [
                        "430b68c6-29cf-5a15-ab4c-89bd842383bf"
                    ]
                },
                "BP6.2": {
                    "questionId": "BP6.2",
                    "freeValue": 1
                },
                "BP6.3": {
                    "questionId": "BP6.3",
                    "chosenOptionIds": [
                        "df17fdbe-e756-5ece-ad3d-19cafeef31d1"
                    ]
                },
                "BP8": {
                    "questionId": "BP8",
                    "chosenOptionIds": [
                        "87e0081b-9455-5ced-9225-10c2f70b1734"
                    ]
                },
                "BP8.0": {
                    "questionId": "BP8.0",
                    "chosenOptionIds": [
                        "3568cdb1-2500-512b-aada-2547c5bfd835"
                    ]
                },
                "BP8.01": {
                    "questionId": "BP8.01",
                    "chosenOptionIds": [
                        "a5d71cc4-3c6f-5e37-a507-1dfa312124b8"
                    ]
                },
                "BP8.02": {
                    "questionId": "BP8.02",
                    "chosenOptionIds": [
                        "e88670a3-c6d8-5c96-914e-b9fc1818aeda"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "387b19c0-67d0-557e-9fba-ae065dcf3587"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "afb57366-c0b3-5f73-92eb-27bc133ad2ed"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "667ba57c-b1cd-5f9b-aa12-58e65a981910"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "fd30dc27-8e89-5d1a-9d95-8844603d4379"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "c211fabc-a62d-5440-8ddc-f57eb21d7679"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "cc7c09ae-1cb7-5b9a-9cd3-8d1012b90ee8"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "d680a8d9-e35f-563f-9d1d-e7c3136aff6d"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "82997889-d795-572b-a597-e484ef078b39"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "2555d898-6736-5804-951e-e3c438313029"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "de2ea429-4fcd-53b8-8d9f-ebb69a637a02"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "e232ba2d-36a9-524e-9fb2-8541b27bb569"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "4a7683c6-774e-5362-8060-740f61986c9b"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "cfb8fe0e-821a-554a-bb57-922f0ecc0f44"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "36502f0b-4563-5e03-80f2-a46dabeeae74"
                    ]
                },
                "BP99.2": {
                    "questionId": "BP99.2",
                    "chosenOptionIds": [
                        "f55f25af-1247-5c48-8385-341f7e4fea59"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "e81fe613-4923-55ed-bd6c-bd3f19e026f9"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM2": {
                    "questionId": "HM2",
                    "chosenOptionIds": [
                        "2b56aa7d-493f-5a18-aa9f-f97304f89130"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "98ea07a0-ae95-570f-8d11-a3e7c682e986"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "a43647cb-a0e8-5705-adb9-a2b68877dc77"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "56aac6d1-1fcc-5a72-bfda-2ae5d875df21"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "43c32319-c75f-5169-b0e5-713820721d4f"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "65bee7d4-bf7f-5a21-9d92-f16651b0d1aa"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "0170b4cf-0541-53cd-a9db-818d5067dc23"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "7b310da1-94e3-5d45-a6c3-3905eeb28ec6"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "0983ccf5-a426-53d0-adbb-1baf6832190c"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "834b04f2-af09-5337-8fd5-be4a70c99e75"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "fda00d52-c8be-5b69-bdbd-905c13dddd4e"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "0360ab4b-77d5-5fcd-8d60-b7a005c12ceb"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "1d07911e-929d-5719-aeea-a68f593d18e8"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: R047J04 aspect: simple_mdd should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "c61d70d4-b773-54dd-94d0-d858a47be0fb"
                    ]
                },
                "D-1.5b": {
                    "questionId": "D-1.5b",
                    "freeValue": 10
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "a16e8e14-524e-53df-b4ef-730a2c8e5f32"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "9b47900d-e5a7-5e58-830b-d9b3130a7990"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "a0e528ca-2fbb-5da1-9398-0758f79755aa"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 1825
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "7f1d6182-f8a7-5f4e-a0f8-2556b306ae36"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-1bl": {
                    "questionId": "D-1bl",
                    "chosenOptionIds": [
                        "5da400b0-5bff-5744-a664-4305892ae669"
                    ]
                },
                "D-2": {
                    "questionId": "D-2",
                    "freeValue": 45
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "c695fd1c-2cb7-5654-b9f7-b32ecb158229"
                    ]
                },
                "D-2bl": {
                    "questionId": "D-2bl",
                    "chosenOptionIds": [
                        "78c1434b-e585-5f1d-b291-a1728f789022"
                    ]
                },
                "D-2bt": {
                    "questionId": "D-2bt",
                    "chosenOptionIds": [
                        "315fa694-3bc0-5231-93f2-ceb1d9766403"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "14d310b2-5fb6-5f79-8bfa-e3524002e63d"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "db7514e2-d317-55fb-82d3-2e4a49c2494b"
                    ]
                },
                "D-3bl": {
                    "questionId": "D-3bl",
                    "freeValue": 6
                },
                "D-3bt": {
                    "questionId": "D-3bt",
                    "chosenOptionIds": [
                        "1a1865e2-c338-59ef-a2db-6e2cc7a17d77"
                    ]
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-42bt": {
                    "questionId": "D-42bt",
                    "freeValue": 5
                },
                "D-43bt": {
                    "questionId": "D-43bt",
                    "chosenOptionIds": [
                        "644dd944-5a2a-533b-9ef0-fadb5054c422"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b"
                    ]
                },
                "D-4bt": {
                    "questionId": "D-4bt",
                    "freeValue": 5
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "b52073e3-c813-51f5-88ba-9cd3bddd7023"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "adecf2d3-f302-50f8-a4a9-3ffac1789bc6"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "4c78ae3e-7de4-545f-aca1-03fa41fc0c23"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "13f8bac1-d0f5-539b-8596-1c55821bea46"
                    ]
                },
                "D-s10bt": {
                    "questionId": "D-s10bt",
                    "chosenOptionIds": [
                        "ce0e07f8-36b3-5424-a489-abd9de8a21a4"
                    ]
                },
                "D-s1bt": {
                    "questionId": "D-s1bt",
                    "chosenOptionIds": [
                        "c17482e4-818a-50cd-90fa-1246d037825e"
                    ]
                },
                "D-s2bt": {
                    "questionId": "D-s2bt",
                    "chosenOptionIds": [
                        "b75d06ef-eb6c-5e23-bf8c-9b566fa4d9dd"
                    ]
                },
                "D-s3bt": {
                    "questionId": "D-s3bt",
                    "chosenOptionIds": [
                        "ae77a2d7-a52c-5cdc-8c8d-93e294ade96d"
                    ]
                },
                "D-s4bt": {
                    "questionId": "D-s4bt",
                    "chosenOptionIds": [
                        "5a198500-fc4b-55a1-9521-3abe49eed06f"
                    ]
                },
                "D-s5bt": {
                    "questionId": "D-s5bt",
                    "chosenOptionIds": [
                        "d033bb4c-5d3c-519b-be31-1177042bffb4"
                    ]
                },
                "D-s6bt": {
                    "questionId": "D-s6bt",
                    "chosenOptionIds": [
                        "bb7e3f7e-73b0-56b0-b3d3-e336498509e8"
                    ]
                },
                "D-s7bt": {
                    "questionId": "D-s7bt",
                    "chosenOptionIds": [
                        "1df67815-0b9f-56ef-bd48-96ed977db141"
                    ]
                },
                "D-s8bt": {
                    "questionId": "D-s8bt",
                    "chosenOptionIds": [
                        "6f5067cf-ef4d-59d9-afa1-b3dfc4ac861f"
                    ]
                },
                "D-s9bt": {
                    "questionId": "D-s9bt",
                    "chosenOptionIds": [
                        "da503bbd-e567-576e-a21f-e0fad60dccdd"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "8c599171-d554-5547-9f54-2066c1a711dd"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "ccbdcbec-96d0-5362-977b-784289f5cfc0"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "db7aa4b7-99a5-5475-a4e8-a744f3ef5a4a"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "e9eb902a-d327-5ec5-938c-dc317ca59b13"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "a3a547f3-b48b-567f-a32a-6a3f5f4bacd7"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "f2228920-3fec-5097-a63d-990c25d50c6c"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP105": {
                    "questionId": "BP105",
                    "chosenOptionIds": [
                        "64aa1680-6b5b-5a5b-bb92-75995d27ddcd"
                    ]
                },
                "BP12": {
                    "questionId": "BP12",
                    "chosenOptionIds": [
                        "89a3d3f3-747b-59f8-9a32-311618df7607"
                    ]
                },
                "BP12.5": {
                    "questionId": "BP12.5",
                    "chosenOptionIds": [
                        "b7cc3eef-f36c-5fb4-9ebb-9ce64c834159"
                    ]
                },
                "BP13": {
                    "questionId": "BP13",
                    "chosenOptionIds": [
                        "8cd7d7ed-1de8-5155-81b5-85202bce3abc"
                    ]
                },
                "BP13.1": {
                    "questionId": "BP13.1",
                    "chosenOptionIds": [
                        "49f1206d-ce81-58fc-af76-30be0fffd7f5"
                    ]
                },
                "BP13.2": {
                    "questionId": "BP13.2",
                    "freeValue": 6
                },
                "BP13.3": {
                    "questionId": "BP13.3",
                    "chosenOptionIds": [
                        "d1bcedf2-2527-57c5-8faf-806dcacf0fdd"
                    ]
                },
                "BP16": {
                    "questionId": "BP16",
                    "chosenOptionIds": [
                        "fabceeea-8fea-5444-8b67-8f189f366c27"
                    ]
                },
                "BP16.0": {
                    "questionId": "BP16.0",
                    "chosenOptionIds": [
                        "4b26caa5-9124-51b2-ab08-2f4e977cee1f"
                    ]
                },
                "BP16.01": {
                    "questionId": "BP16.01",
                    "chosenOptionIds": [
                        "5d4d2592-d29a-513f-8e9c-461e29598f49"
                    ]
                },
                "BP16.02": {
                    "questionId": "BP16.02",
                    "chosenOptionIds": [
                        "16a695c9-3ac1-5d34-b799-44602d85b556"
                    ]
                },
                "BP17": {
                    "questionId": "BP17",
                    "chosenOptionIds": [
                        "9fa962fb-6bd5-50fc-9063-459e175cbcc3"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 25
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "c7bee7a0-3037-5eb1-96a1-85b884d7e1dd"
                    ]
                },
                "BP3.01": {
                    "questionId": "BP3.01",
                    "freeValue": 1
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "960a08ff-7af7-56da-afda-5415a1654c85"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "5f694075-3a97-5f8b-8701-2710a43127a9"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "28c75e9e-94ef-5c50-8645-f4ff93ccf2ec"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "fd30dc27-8e89-5d1a-9d95-8844603d4379"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "e22cf233-c174-52fb-af55-97bf9c46e971"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "46c90446-3ae9-53f5-9b57-ad19d66b5be6"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "ef5a7b22-b2b2-5a5b-8a80-ce5c251033fd"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "c88e3fb5-913a-5c5c-a42b-603f7bb60a40"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "201c7ee3-56dc-51e9-ad55-69951192ab16"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "de2ea429-4fcd-53b8-8d9f-ebb69a637a02"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "9b27e3ff-f8ce-54c5-9220-0479ae53e889"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "2723ed09-37f6-522c-92b1-e518d4fe9315"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "cfb8fe0e-821a-554a-bb57-922f0ecc0f44"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "5c06d516-e816-58d6-997e-f8fa91131ac7"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "e81fe613-4923-55ed-bd6c-bd3f19e026f9"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM18": {
                    "questionId": "HM18",
                    "chosenOptionIds": [
                        "e91203fa-5410-59f8-a2a7-cfbd0257e576"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "6b99de8b-634a-5eb0-bc65-bdf30eb9d433"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "9d212885-9b07-5623-8234-226adff08f6d"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "5574c021-c3c4-513a-a4b6-5ee416e8aa9e"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "3fd957d1-0cbb-5397-9812-353be5c2184e"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "898957c6-8cd1-53e5-98f8-c16789aba613"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "0170b4cf-0541-53cd-a9db-818d5067dc23"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "2be66549-5767-51b4-82ed-deeab0723f8d"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "0983ccf5-a426-53d0-adbb-1baf6832190c"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "7e32475a-3fcc-51ac-b414-ab46578efdfd"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "fa32b497-4a4b-5076-a391-b6dffa8e1851"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "e95276b0-ee81-580b-b532-b0215f04abd5"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "4b8e4843-d260-59e5-8468-1303b1c3a638"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: O0KG5E1 aspect: simple_mdd should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "c61d70d4-b773-54dd-94d0-d858a47be0fb"
                    ]
                },
                "D-1.5b": {
                    "questionId": "D-1.5b",
                    "freeValue": 4
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "45f0ce85-9e19-53da-bc0d-2af8b383318a"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "eb68a88d-b49b-5cde-a9c6-5e4c78e27d19"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "826538fc-8a97-55e4-8013-fe6a93f8e451"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 7
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "7f1d6182-f8a7-5f4e-a0f8-2556b306ae36"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-1bl": {
                    "questionId": "D-1bl",
                    "chosenOptionIds": [
                        "87ef50c9-ba5e-513e-8889-09d8395d4b9f"
                    ]
                },
                "D-2": {
                    "questionId": "D-2",
                    "freeValue": 21
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "c695fd1c-2cb7-5654-b9f7-b32ecb158229"
                    ]
                },
                "D-2bl": {
                    "questionId": "D-2bl",
                    "chosenOptionIds": [
                        "2a8c96e2-cb5b-596a-99b6-a4885253776c"
                    ]
                },
                "D-2bt": {
                    "questionId": "D-2bt",
                    "chosenOptionIds": [
                        "d585762b-2491-592f-9340-3053d9151b68"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "fd9c4394-6664-5144-8797-00d27a4dda01"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "77c6088d-2d8f-5c3b-89c9-79cb0d317226"
                    ]
                },
                "D-3bl": {
                    "questionId": "D-3bl",
                    "freeValue": 14
                },
                "D-3bt": {
                    "questionId": "D-3bt",
                    "chosenOptionIds": [
                        "b9abc28d-6e22-5b67-bc5e-27431e123974"
                    ]
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-42bt": {
                    "questionId": "D-42bt",
                    "freeValue": 0
                },
                "D-43bt": {
                    "questionId": "D-43bt",
                    "chosenOptionIds": [
                        "644dd944-5a2a-533b-9ef0-fadb5054c422"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "9d1b858c-7064-5a84-80d6-786c18e603b6",
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b",
                        "1d4beed1-78e5-531c-a255-643fac260dee"
                    ]
                },
                "D-4bt": {
                    "questionId": "D-4bt",
                    "freeValue": 14
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "b52073e3-c813-51f5-88ba-9cd3bddd7023"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "9506449e-b2aa-5498-b896-901dd248b1b3"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "772bee29-c3a5-52e1-b785-a77d506ae4fb"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "3b216cf8-72e7-558c-bdd3-cc23a1865674"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "f8e2518c-72cf-563b-b322-a03406c8ebac"
                    ]
                },
                "D-s10bt": {
                    "questionId": "D-s10bt",
                    "chosenOptionIds": [
                        "3deea42c-5d93-54cd-ba0f-4242654c3747"
                    ]
                },
                "D-s1bt": {
                    "questionId": "D-s1bt",
                    "chosenOptionIds": [
                        "c17482e4-818a-50cd-90fa-1246d037825e"
                    ]
                },
                "D-s2bt": {
                    "questionId": "D-s2bt",
                    "chosenOptionIds": [
                        "e074592a-4667-5b65-b713-b8892451b433"
                    ]
                },
                "D-s3bt": {
                    "questionId": "D-s3bt",
                    "chosenOptionIds": [
                        "19b82b7e-f108-59dd-b277-dae38a3a8d8d"
                    ]
                },
                "D-s4bt": {
                    "questionId": "D-s4bt",
                    "chosenOptionIds": [
                        "fd9851a0-7ce7-55a8-b257-5617537d0013",
                        "5a198500-fc4b-55a1-9521-3abe49eed06f",
                        "a4021497-af94-56a4-8075-392653262e2e"
                    ]
                },
                "D-s5bt": {
                    "questionId": "D-s5bt",
                    "chosenOptionIds": [
                        "d033bb4c-5d3c-519b-be31-1177042bffb4"
                    ]
                },
                "D-s6bt": {
                    "questionId": "D-s6bt",
                    "chosenOptionIds": [
                        "f7aa2ac0-a087-5371-8266-7601901cd1f9"
                    ]
                },
                "D-s7bt": {
                    "questionId": "D-s7bt",
                    "chosenOptionIds": [
                        "b0305c3f-5a4a-5a36-9793-cec574da7f4e"
                    ]
                },
                "D-s8bt": {
                    "questionId": "D-s8bt",
                    "chosenOptionIds": [
                        "b089fe08-53f3-59bb-a2eb-55bfc744e7ca"
                    ]
                },
                "D-s9bt": {
                    "questionId": "D-s9bt",
                    "chosenOptionIds": [
                        "8d2e7af7-07c2-5459-8865-609f820de278"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "60fe0bb4-d770-56f2-b05a-864625a48e4d"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "ccbdcbec-96d0-5362-977b-784289f5cfc0"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "4a0f1c4d-38cf-5de4-99e3-0ee818f9709c"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "6a5aa899-7b89-5661-a554-71b6058ad4a4"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "d3224e16-1b0c-5266-9e14-ac72d2b2e988"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "b24df75b-6bb9-5f5f-99e3-c883ec3644ec"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP12": {
                    "questionId": "BP12",
                    "chosenOptionIds": [
                        "22f9a995-3f9a-5fe9-99bb-71b7f1f96621"
                    ]
                },
                "BP15": {
                    "questionId": "BP15",
                    "chosenOptionIds": [
                        "1286eda2-5e31-52dc-95c5-78ded5b43b2a"
                    ]
                },
                "BP15.1": {
                    "questionId": "BP15.1",
                    "chosenOptionIds": [
                        "0fe6799b-72e6-5cd8-a921-d03ac42f803d"
                    ]
                },
                "BP15.2": {
                    "questionId": "BP15.2",
                    "freeValue": 0.041666666666666664
                },
                "BP15.3": {
                    "questionId": "BP15.3",
                    "chosenOptionIds": [
                        "cfa519ff-08a9-5fa4-b12d-87b7a597cb13"
                    ]
                },
                "BP16": {
                    "questionId": "BP16",
                    "chosenOptionIds": [
                        "fabceeea-8fea-5444-8b67-8f189f366c27"
                    ]
                },
                "BP16.0": {
                    "questionId": "BP16.0",
                    "chosenOptionIds": [
                        "4b26caa5-9124-51b2-ab08-2f4e977cee1f"
                    ]
                },
                "BP16.01": {
                    "questionId": "BP16.01",
                    "chosenOptionIds": [
                        "f81655d7-78e5-59b1-a7eb-90a2ec7a107d"
                    ]
                },
                "BP16.02": {
                    "questionId": "BP16.02",
                    "chosenOptionIds": [
                        "16a695c9-3ac1-5d34-b799-44602d85b556"
                    ]
                },
                "BP19": {
                    "questionId": "BP19",
                    "chosenOptionIds": [
                        "601bc370-8a4c-5b1f-a7f6-6c174f421733"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 31
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "f03de28d-8f71-5af4-be2f-45f4e6092a3d"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "387b19c0-67d0-557e-9fba-ae065dcf3587"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "afb57366-c0b3-5f73-92eb-27bc133ad2ed"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "667ba57c-b1cd-5f9b-aa12-58e65a981910"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "fd30dc27-8e89-5d1a-9d95-8844603d4379"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "e22cf233-c174-52fb-af55-97bf9c46e971"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "46c90446-3ae9-53f5-9b57-ad19d66b5be6"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "d680a8d9-e35f-563f-9d1d-e7c3136aff6d"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "c88e3fb5-913a-5c5c-a42b-603f7bb60a40"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "732c51f9-a0b6-5951-bd7d-eaaec24316ba"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "6928c696-e6d7-5a14-a004-15a497ac6562"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "17b4da6f-7135-523c-b90b-2fe3b66785ff"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "3cf77140-2757-58eb-b4ea-f2a90cd766e7"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "5c06d516-e816-58d6-997e-f8fa91131ac7"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "c0cc09c3-1335-5eb4-8801-b3cdaf74f6fa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM20": {
                    "questionId": "HM20",
                    "chosenOptionIds": [
                        "4ff0f88c-e951-5e87-a941-815105819115"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "98ea07a0-ae95-570f-8d11-a3e7c682e986"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "696fdd9d-5ced-504b-a933-bb3e575b0099"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c41974b6-d394-569c-8f45-49f79fcac5c6"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "43c32319-c75f-5169-b0e5-713820721d4f"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "b2cb304f-f035-53fc-8763-e2f91b031f9b"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "0170b4cf-0541-53cd-a9db-818d5067dc23"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "2be66549-5767-51b4-82ed-deeab0723f8d"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "2a17c2fe-2592-58b1-98e9-3dc4168fe3e3"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "834b04f2-af09-5337-8fd5-be4a70c99e75"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "86277249-cf50-5f91-a27f-f3a69344e796"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "8823c74f-ff7c-52d8-9c73-fd3b4180f48a"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "1d07911e-929d-5719-aeea-a68f593d18e8"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: Z3R6O0O aspect: simple_mdd should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "0ec0e224-a5cf-5ece-b3ab-37061a600ace"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "45f0ce85-9e19-53da-bc0d-2af8b383318a"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "6ede390f-0e43-50e4-82e9-1203db9d8343"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "b74511c7-9f97-5c15-8bf5-6ec4357b4814"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 730
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "db360e93-3616-5d89-be36-5ccee4aacf1c"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "de11fb6b-7e7d-52b1-9d09-2d6debc53314"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5c30c60e-d53e-5cc3-b4a9-fdad9bec4cb7"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "188a7831-38c4-55c4-880a-1e4db4e9bab1"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "3e093dd3-857e-5004-a4fb-961fbfa59ece"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "772bee29-c3a5-52e1-b785-a77d506ae4fb"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "67316d94-1cf0-5bd0-a4db-f57bc70684ba"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "f8e2518c-72cf-563b-b322-a03406c8ebac"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "60fe0bb4-d770-56f2-b05a-864625a48e4d"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "83e6efd5-64a2-5f0e-a973-9278eae90cfb"
                    ]
                },
                "BP10": {
                    "questionId": "BP10",
                    "chosenOptionIds": [
                        "7ad54f0a-87a6-5e82-bfc3-44b540230082"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "4a0f1c4d-38cf-5de4-99e3-0ee818f9709c"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "d8c1997f-d722-536c-ad9d-296b5e10ed04"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "7230003d-a290-5a65-ae6b-b7d564b5db38"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "f2228920-3fec-5097-a63d-990c25d50c6c"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "de4464c8-78fe-5876-aa00-25826ce5ec25"
                    ]
                },
                "BP105": {
                    "questionId": "BP105",
                    "chosenOptionIds": [
                        "4e9d21b3-29c7-5526-ada9-67b61957994e"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 49
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "c7bee7a0-3037-5eb1-96a1-85b884d7e1dd"
                    ]
                },
                "BP3.01": {
                    "questionId": "BP3.01",
                    "freeValue": 99
                },
                "BP3.1": {
                    "questionId": "BP3.1",
                    "chosenOptionIds": [
                        "5bf1e313-2624-5b5f-9673-37ae9319ff0c"
                    ]
                },
                "BP3.2": {
                    "questionId": "BP3.2",
                    "chosenOptionIds": [
                        "43011291-20a8-59ce-8bac-df882587ff5c"
                    ]
                },
                "BP3.3": {
                    "questionId": "BP3.3",
                    "freeValue": 0.041666666666666664
                },
                "BP3.4": {
                    "questionId": "BP3.4",
                    "chosenOptionIds": [
                        "93412936-97c0-5128-ad67-3fa955db1300"
                    ]
                },
                "BP4": {
                    "questionId": "BP4",
                    "chosenOptionIds": [
                        "9765aba8-7d55-5928-b79d-36aa58646036"
                    ]
                },
                "BP4.5": {
                    "questionId": "BP4.5",
                    "chosenOptionIds": [
                        "1be4b3b1-3a88-5544-af54-ebdfee1ce891"
                    ]
                },
                "BP6": {
                    "questionId": "BP6",
                    "chosenOptionIds": [
                        "742a22d6-a570-5e3a-8e72-c001f1e9f98b"
                    ]
                },
                "BP6.1": {
                    "questionId": "BP6.1",
                    "chosenOptionIds": [
                        "77b4d620-93e4-5bc5-baad-4995d168f392"
                    ]
                },
                "BP6.2": {
                    "questionId": "BP6.2",
                    "freeValue": 0.041666666666666664
                },
                "BP6.3": {
                    "questionId": "BP6.3",
                    "chosenOptionIds": [
                        "e061c578-086e-5990-bba6-279cb273f3a5"
                    ]
                },
                "BP8": {
                    "questionId": "BP8",
                    "chosenOptionIds": [
                        "87e0081b-9455-5ced-9225-10c2f70b1734"
                    ]
                },
                "BP8.0": {
                    "questionId": "BP8.0",
                    "chosenOptionIds": [
                        "3568cdb1-2500-512b-aada-2547c5bfd835"
                    ]
                },
                "BP8.01": {
                    "questionId": "BP8.01",
                    "chosenOptionIds": [
                        "5020bfdd-a280-56cf-b7f5-3b0b7e667165"
                    ]
                },
                "BP8.02": {
                    "questionId": "BP8.02",
                    "chosenOptionIds": [
                        "1ff60197-2010-588f-bf6a-9be48f2b3359"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "387b19c0-67d0-557e-9fba-ae065dcf3587"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "afb57366-c0b3-5f73-92eb-27bc133ad2ed"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "667ba57c-b1cd-5f9b-aa12-58e65a981910"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "a2056280-4b54-5258-8bb4-407b521fff4b"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "e22cf233-c174-52fb-af55-97bf9c46e971"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "1f57d1a0-3682-5d7d-b66b-8179ec5c3988"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 1
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "ef5a7b22-b2b2-5a5b-8a80-ce5c251033fd"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "f35fe383-d2aa-51c4-9fde-961b1de6d52b"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "201c7ee3-56dc-51e9-ad55-69951192ab16"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "6ade3a8d-8516-5a88-baa0-f996f32730d2"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "2723ed09-37f6-522c-92b1-e518d4fe9315"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "58777a2e-4b97-5413-b340-efe722e45936"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "63509127-aadf-5fe2-8e1f-19d0d846d6ce"
                    ]
                },
                "BP99.1": {
                    "questionId": "BP99.1",
                    "chosenOptionIds": [
                        "34d19f12-207f-56ce-bde8-8d616f6e8b26"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "a020ed85-ec9d-5778-8006-41f634cab09d"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM2": {
                    "questionId": "HM2",
                    "chosenOptionIds": [
                        "a046d610-d27a-59c1-b130-38312005cf1c"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "c3ee8981-dec3-5b3c-80ca-c1023f983a6d"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "a43647cb-a0e8-5705-adb9-a2b68877dc77"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "3ab9515b-73f0-5fcf-a4d0-aebbc24c2721"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "c8c8c192-aea3-5585-b11f-9d13faf4367d"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "d2c670c4-b05b-597a-a0af-5342230535a0"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "0170b4cf-0541-53cd-a9db-818d5067dc23"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "7b310da1-94e3-5d45-a6c3-3905eeb28ec6"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "2a17c2fe-2592-58b1-98e9-3dc4168fe3e3"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "a56fcb96-f868-5d08-a358-9b403eb3cd16"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "fa32b497-4a4b-5076-a391-b6dffa8e1851"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "bde4e5f3-19e4-571f-84a2-5b9d188d74ef"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "7d6921a2-7e63-5f12-9c75-dff8b592fabc"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: ME87V3W aspect: simple_mdd should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "3bd18f66-6f1e-5ea4-ae24-2990ed197bef"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "eb68a88d-b49b-5cde-a9c6-5e4c78e27d19"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "826538fc-8a97-55e4-8013-fe6a93f8e451"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 1825
                },
                "D-15a": {
                    "questionId": "D-15a",
                    "chosenOptionIds": [
                        "7b763ff0-7703-54f7-8eae-aa83417ca133"
                    ]
                },
                "D-16.5a": {
                    "questionId": "D-16.5a",
                    "chosenOptionIds": [
                        "5f88b934-32eb-5ed5-8896-a818cbe4b033"
                    ]
                },
                "D-17a": {
                    "questionId": "D-17a",
                    "chosenOptionIds": [
                        "33b013af-d830-516a-9632-69dcc7a0f736"
                    ]
                },
                "D-18a": {
                    "questionId": "D-18a",
                    "chosenOptionIds": [
                        "615b852b-6f81-54a9-bc4a-a8843e82c230"
                    ]
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "80c993c8-1a94-5684-a8f9-d8f5072c8e9c"
                    ]
                },
                "D-1bl": {
                    "questionId": "D-1bl",
                    "chosenOptionIds": [
                        "87ef50c9-ba5e-513e-8889-09d8395d4b9f"
                    ]
                },
                "D-2": {
                    "questionId": "D-2",
                    "freeValue": 19
                },
                "D-20a": {
                    "questionId": "D-20a",
                    "chosenOptionIds": [
                        "b28ed556-a386-5556-a96f-ae7708ded1ab"
                    ]
                },
                "D-21a": {
                    "questionId": "D-21a",
                    "chosenOptionIds": [
                        "dc2b002f-2d96-504d-bde9-66faceececb6"
                    ]
                },
                "D-23a": {
                    "questionId": "D-23a",
                    "chosenOptionIds": [
                        "44fe96b8-192b-5b6b-88fa-9c2f7ba73a6d"
                    ]
                },
                "D-26a": {
                    "questionId": "D-26a",
                    "chosenOptionIds": [
                        "220827bb-16f1-5e00-9549-b98382b6e2ac"
                    ]
                },
                "D-27a": {
                    "questionId": "D-27a",
                    "chosenOptionIds": [
                        "13a1b08d-9817-5c2f-96cb-f8d03b80a37d"
                    ]
                },
                "D-28a": {
                    "questionId": "D-28a",
                    "chosenOptionIds": [
                        "8de8c31e-96ef-5e7d-8024-f6a72d07dd28"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "cc243947-5067-5a98-ade6-a4e10d0b42cf"
                    ]
                },
                "D-2bl": {
                    "questionId": "D-2bl",
                    "chosenOptionIds": [
                        "2a8c96e2-cb5b-596a-99b6-a4885253776c"
                    ]
                },
                "D-2bs": {
                    "questionId": "D-2bs",
                    "chosenOptionIds": [
                        "d9fc40d3-aa51-587a-a54d-305ad26f67ae"
                    ]
                },
                "D-2bt": {
                    "questionId": "D-2bt",
                    "chosenOptionIds": [
                        "90222879-e5d9-50a0-bfbe-a5cbadbff017"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "fd9c4394-6664-5144-8797-00d27a4dda01"
                    ]
                },
                "D-30a": {
                    "questionId": "D-30a",
                    "chosenOptionIds": [
                        "5b3f3d57-9d8a-54ab-a6ab-b02b57bc262e"
                    ]
                },
                "D-31a": {
                    "questionId": "D-31a",
                    "chosenOptionIds": [
                        "c78e0def-d73e-5c4e-8800-7c6f92d0a1ec"
                    ]
                },
                "D-32.1a": {
                    "questionId": "D-32.1a",
                    "chosenOptionIds": [
                        "da39b8b6-3178-5cf4-b68e-92b08cbf087e"
                    ]
                },
                "D-32a": {
                    "questionId": "D-32a",
                    "chosenOptionIds": [
                        "f992ce3a-fffd-5f38-aee4-24930ded9288"
                    ]
                },
                "D-33a": {
                    "questionId": "D-33a",
                    "chosenOptionIds": [
                        "a73a7546-0627-5437-b9f9-5664558c7d54"
                    ]
                },
                "D-34a": {
                    "questionId": "D-34a",
                    "chosenOptionIds": [
                        "e919c54f-d9a4-5a15-9ae7-8b6b52607a22"
                    ]
                },
                "D-35a": {
                    "questionId": "D-35a",
                    "chosenOptionIds": [
                        "bcace469-f380-599d-a33a-26db6d0053ad"
                    ]
                },
                "D-36a": {
                    "questionId": "D-36a",
                    "chosenOptionIds": [
                        "185038dd-ce55-53b2-ba09-0bd163af90b3"
                    ]
                },
                "D-37a": {
                    "questionId": "D-37a",
                    "chosenOptionIds": [
                        "c8ebfb14-b9aa-5da4-8de3-ab8b83254249"
                    ]
                },
                "D-38a": {
                    "questionId": "D-38a",
                    "chosenOptionIds": [
                        "39b328fc-f944-51dc-ae4d-9aab44697f32"
                    ]
                },
                "D-39a": {
                    "questionId": "D-39a",
                    "chosenOptionIds": [
                        "ae31125f-c3db-5082-ae70-4329a2508744"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "db7514e2-d317-55fb-82d3-2e4a49c2494b"
                    ]
                },
                "D-3bl": {
                    "questionId": "D-3bl",
                    "freeValue": 28
                },
                "D-3bs": {
                    "questionId": "D-3bs",
                    "chosenOptionIds": [
                        "664c5f59-06d3-53a2-81e0-eaf62108625d"
                    ]
                },
                "D-3bt": {
                    "questionId": "D-3bt",
                    "chosenOptionIds": [
                        "b9abc28d-6e22-5b67-bc5e-27431e123974"
                    ]
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-40a": {
                    "questionId": "D-40a",
                    "chosenOptionIds": [
                        "582119a7-d4a0-58ff-9b1d-2af045e11a7f"
                    ]
                },
                "D-41a": {
                    "questionId": "D-41a",
                    "chosenOptionIds": [
                        "cb89e9b0-6872-5961-bc91-049308b5860f"
                    ]
                },
                "D-42a": {
                    "questionId": "D-42a",
                    "chosenOptionIds": [
                        "7099f8a9-4650-5da2-a627-bcd62cd99375"
                    ]
                },
                "D-42bt": {
                    "questionId": "D-42bt",
                    "freeValue": 2
                },
                "D-43a": {
                    "questionId": "D-43a",
                    "chosenOptionIds": [
                        "cd0abe72-b3e8-5c78-8330-7ee4a1657536"
                    ]
                },
                "D-43bt": {
                    "questionId": "D-43bt",
                    "chosenOptionIds": [
                        "90e60395-fc3d-56cb-b6f7-2e24fba02803"
                    ]
                },
                "D-44.5a": {
                    "questionId": "D-44.5a",
                    "chosenOptionIds": [
                        "bcf5fb9f-f4b9-5f0c-a964-69a4e1a73334"
                    ]
                },
                "D-44a": {
                    "questionId": "D-44a",
                    "chosenOptionIds": [
                        "57c0e55d-5ee4-5237-9c5d-e317b90c5dbd"
                    ]
                },
                "D-45a": {
                    "questionId": "D-45a",
                    "freeValue": 15
                },
                "D-46a": {
                    "questionId": "D-46a",
                    "chosenOptionIds": [
                        "1fd426df-808a-5c68-9b6e-23ff2f128b7d",
                        "7492eab7-c625-52d0-a2e3-4bf3332decc0",
                        "4c178978-f79a-58fc-8e73-3a98614c4b6c",
                        "6e49f368-62af-5abb-9034-a58bd534944f"
                    ]
                },
                "D-49.1a": {
                    "questionId": "D-49.1a",
                    "chosenOptionIds": [
                        "7108af97-c956-5da8-8dfa-518e346c197c"
                    ]
                },
                "D-49.2a": {
                    "questionId": "D-49.2a",
                    "freeValue": 10
                },
                "D-49a": {
                    "questionId": "D-49a",
                    "chosenOptionIds": [
                        "16dc6af1-32a9-59c5-b66c-57f6becea1e0"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "a449e4ae-663b-50fb-9b62-1708b0a32669"
                    ]
                },
                "D-4bs": {
                    "questionId": "D-4bs",
                    "freeValue": 14
                },
                "D-4bt": {
                    "questionId": "D-4bt",
                    "freeValue": 0.29166666666666663
                },
                "D-50a": {
                    "questionId": "D-50a",
                    "chosenOptionIds": [
                        "b591ccff-25d0-5b4f-8091-4e8f8438987b"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "b52073e3-c813-51f5-88ba-9cd3bddd7023"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "772bee29-c3a5-52e1-b785-a77d506ae4fb"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "33a5fff0-9587-521f-bc53-0dacc36a0bb2"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "1200dadf-a0de-5a54-b0b8-fc640f7bc8d1"
                    ]
                },
                "D-s10bt": {
                    "questionId": "D-s10bt",
                    "chosenOptionIds": [
                        "da993bd6-c107-53a4-9f6d-09f5a79aff65"
                    ]
                },
                "D-s11bt": {
                    "questionId": "D-s11bt",
                    "chosenOptionIds": [
                        "dc476220-9479-5def-8a97-20e6152d44af",
                        "daf8b3c7-fb19-5a3f-ba9a-784adda7414f",
                        "6994b88e-cfa5-5986-b9e8-625d17d1147c",
                        "bae5acc8-8664-54a2-ba97-c36244be4c44",
                        "64af3cae-713a-51e1-99c1-09e2fdaa755d",
                        "9c3fa6af-8b7b-5993-a587-641683d934d5",
                        "16e1a0b8-28c5-5bcd-931d-335c858c3f88"
                    ]
                },
                "D-s1bt": {
                    "questionId": "D-s1bt",
                    "chosenOptionIds": [
                        "c17482e4-818a-50cd-90fa-1246d037825e"
                    ]
                },
                "D-s2bt": {
                    "questionId": "D-s2bt",
                    "chosenOptionIds": [
                        "8783a6d5-cd94-5982-8fd6-66ae46e1c6df"
                    ]
                },
                "D-s3bt": {
                    "questionId": "D-s3bt",
                    "chosenOptionIds": [
                        "19b82b7e-f108-59dd-b277-dae38a3a8d8d"
                    ]
                },
                "D-s4bt": {
                    "questionId": "D-s4bt",
                    "chosenOptionIds": [
                        "6dd2621e-8242-58f6-8c6c-77df4a9eef53"
                    ]
                },
                "D-s5bt": {
                    "questionId": "D-s5bt",
                    "chosenOptionIds": [
                        "8260d432-3a96-519e-b824-d24940e4aa65"
                    ]
                },
                "D-s6bt": {
                    "questionId": "D-s6bt",
                    "chosenOptionIds": [
                        "f7aa2ac0-a087-5371-8266-7601901cd1f9"
                    ]
                },
                "D-s7bt": {
                    "questionId": "D-s7bt",
                    "chosenOptionIds": [
                        "1df67815-0b9f-56ef-bd48-96ed977db141"
                    ]
                },
                "D-s8bt": {
                    "questionId": "D-s8bt",
                    "chosenOptionIds": [
                        "6f5067cf-ef4d-59d9-afa1-b3dfc4ac861f"
                    ]
                },
                "D-s9bt": {
                    "questionId": "D-s9bt",
                    "chosenOptionIds": [
                        "da503bbd-e567-576e-a21f-e0fad60dccdd"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "afcb6534-a0a0-5fe6-9729-1269be20cdf3",
                        "44fd9eb3-aee2-5af0-b83c-dbbbf9514022"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "ccbdcbec-96d0-5362-977b-784289f5cfc0"
                    ]
                },
                "BP10": {
                    "questionId": "BP10",
                    "chosenOptionIds": [
                        "7ad54f0a-87a6-5e82-bfc3-44b540230082"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "a20d7b84-720e-53c5-a450-45cbde434507"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "d8c1997f-d722-536c-ad9d-296b5e10ed04"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "7230003d-a290-5a65-ae6b-b7d564b5db38"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "f2228920-3fec-5097-a63d-990c25d50c6c"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP105": {
                    "questionId": "BP105",
                    "chosenOptionIds": [
                        "978f2ff4-94d9-5f7e-9126-e8657be0c7ca"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 22
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "c7bee7a0-3037-5eb1-96a1-85b884d7e1dd"
                    ]
                },
                "BP3.01": {
                    "questionId": "BP3.01",
                    "freeValue": 20
                },
                "BP3.1": {
                    "questionId": "BP3.1",
                    "chosenOptionIds": [
                        "c31cf816-04e0-52f4-8da3-04437042c014"
                    ]
                },
                "BP3.2": {
                    "questionId": "BP3.2",
                    "chosenOptionIds": [
                        "ec333f8b-146a-5700-a15d-527032ceac3f"
                    ]
                },
                "BP3.3": {
                    "questionId": "BP3.3",
                    "freeValue": 20
                },
                "BP3.4": {
                    "questionId": "BP3.4",
                    "chosenOptionIds": [
                        "f304bf93-0c68-5da0-80ba-ccecc2d0fce1"
                    ]
                },
                "BP4": {
                    "questionId": "BP4",
                    "chosenOptionIds": [
                        "9765aba8-7d55-5928-b79d-36aa58646036"
                    ]
                },
                "BP4.5": {
                    "questionId": "BP4.5",
                    "chosenOptionIds": [
                        "66a3460c-65ce-5a4c-9774-2e303ae4961e"
                    ]
                },
                "BP6": {
                    "questionId": "BP6",
                    "chosenOptionIds": [
                        "33ae5abe-63ad-57d4-86a4-0dcd33576094"
                    ]
                },
                "BP6.1": {
                    "questionId": "BP6.1",
                    "chosenOptionIds": [
                        "a99773bd-2bff-523e-bc29-1f761307b3d4"
                    ]
                },
                "BP6.2": {
                    "questionId": "BP6.2",
                    "freeValue": 7
                },
                "BP6.3": {
                    "questionId": "BP6.3",
                    "chosenOptionIds": [
                        "e061c578-086e-5990-bba6-279cb273f3a5"
                    ]
                },
                "BP8": {
                    "questionId": "BP8",
                    "chosenOptionIds": [
                        "5d89a61f-a292-5fa7-b2ec-af892cf73338"
                    ]
                },
                "BP8.0": {
                    "questionId": "BP8.0",
                    "chosenOptionIds": [
                        "3568cdb1-2500-512b-aada-2547c5bfd835"
                    ]
                },
                "BP8.01": {
                    "questionId": "BP8.01",
                    "chosenOptionIds": [
                        "a5d71cc4-3c6f-5e37-a507-1dfa312124b8"
                    ]
                },
                "BP8.02": {
                    "questionId": "BP8.02",
                    "chosenOptionIds": [
                        "e88670a3-c6d8-5c96-914e-b9fc1818aeda"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "960a08ff-7af7-56da-afda-5415a1654c85"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "cb636d32-4f4e-51b3-a23f-2a11b8771b7e"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "0953f30c-801a-54f6-8238-b9dc42ab17f9"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "fd30dc27-8e89-5d1a-9d95-8844603d4379"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "c211fabc-a62d-5440-8ddc-f57eb21d7679"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "1f57d1a0-3682-5d7d-b66b-8179ec5c3988"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 10
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "63f0b17a-9832-5f0f-8630-e74f1a4fdfe7"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "82997889-d795-572b-a597-e484ef078b39"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "2555d898-6736-5804-951e-e3c438313029"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "e232ba2d-36a9-524e-9fb2-8541b27bb569"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "2723ed09-37f6-522c-92b1-e518d4fe9315"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "3cf77140-2757-58eb-b4ea-f2a90cd766e7"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "5c06d516-e816-58d6-997e-f8fa91131ac7"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "c0cc09c3-1335-5eb4-8801-b3cdaf74f6fa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM2": {
                    "questionId": "HM2",
                    "chosenOptionIds": [
                        "a046d610-d27a-59c1-b130-38312005cf1c"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "6b99de8b-634a-5eb0-bc65-bdf30eb9d433"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "9d212885-9b07-5623-8234-226adff08f6d"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c347cbdd-4788-538c-b190-8008a1e964e2"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "c8c8c192-aea3-5585-b11f-9d13faf4367d"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "d2c670c4-b05b-597a-a0af-5342230535a0"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "366c6a1b-c8b2-5faf-9d2f-72dbde24e869"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "1240a093-e011-5bb9-9dbe-b61af95e9cde"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "2a17c2fe-2592-58b1-98e9-3dc4168fe3e3"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "a56fcb96-f868-5d08-a358-9b403eb3cd16"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "fda00d52-c8be-5b69-bdbd-905c13dddd4e"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "bde4e5f3-19e4-571f-84a2-5b9d188d74ef"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "7d6921a2-7e63-5f12-9c75-dff8b592fabc"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: PE61J0L aspect: simple_mdd should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "45f0ce85-9e19-53da-bc0d-2af8b383318a"
                    ]
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "7a5958fc-e0f7-5d43-9cd0-abfe95cb7026"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "a9525e10-4e34-5d6b-9c93-c7546ec49231"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "9222be3e-443f-5146-9d69-d48fe9041ba0"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "77c6088d-2d8f-5c3b-89c9-79cb0d317226"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "a449e4ae-663b-50fb-9b62-1708b0a32669"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "f3efc764-ca53-52c3-9c3a-38c7f787d415"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "9506449e-b2aa-5498-b896-901dd248b1b3"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "ef6fddab-7a07-5f20-af20-74f2444eee53"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "3b216cf8-72e7-558c-bdd3-cc23a1865674"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "60fe0bb4-d770-56f2-b05a-864625a48e4d"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "7599bcaa-89a5-5f71-80ea-b70c360bb44a"
                    ]
                },
                "BP101b": {
                    "questionId": "BP101b",
                    "chosenOptionIds": [
                        "cb80bcac-db78-55bd-b051-bdd00801fd54"
                    ]
                },
                "BP102b": {
                    "questionId": "BP102b",
                    "chosenOptionIds": [
                        "acb61f4d-a637-587c-bd33-3e9195921dd3"
                    ]
                },
                "BP103b": {
                    "questionId": "BP103b",
                    "chosenOptionIds": [
                        "867b0ee4-73d9-5aac-b96d-bc2d85d5b183"
                    ]
                },
                "BP104b": {
                    "questionId": "BP104b",
                    "chosenOptionIds": [
                        "c59b5910-3279-509f-8175-f2fd0da2dc86"
                    ]
                },
                "BP87b": {
                    "questionId": "BP87b",
                    "chosenOptionIds": [
                        "8cf63df7-755b-597a-9125-d7435c303261"
                    ]
                },
                "BP88b": {
                    "questionId": "BP88b",
                    "chosenOptionIds": [
                        "a6244707-7c91-5858-a2e1-f6f953f7b85e"
                    ]
                },
                "BP89b": {
                    "questionId": "BP89b",
                    "chosenOptionIds": [
                        "41d2829a-3971-552f-8c5a-c77e7d0073e7"
                    ]
                },
                "BP90b": {
                    "questionId": "BP90b",
                    "chosenOptionIds": [
                        "95c2822d-27ad-5bb0-982f-8c2b949c8918"
                    ]
                },
                "BP92b": {
                    "questionId": "BP92b",
                    "chosenOptionIds": [
                        "b2f4c490-30df-5c8f-87c0-bc4acbcfb8e0"
                    ]
                },
                "BP94b": {
                    "questionId": "BP94b",
                    "chosenOptionIds": [
                        "038cb12b-2783-569a-b1d7-e28c65e591a3"
                    ]
                },
                "BP95b": {
                    "questionId": "BP95b",
                    "chosenOptionIds": [
                        "fb0bd4a7-2793-5b81-badc-fe81b4bc82dc"
                    ]
                },
                "BP96b": {
                    "questionId": "BP96b",
                    "chosenOptionIds": [
                        "8c3d3503-6334-55b9-b0f0-94c8e520094e"
                    ]
                },
                "BP97b": {
                    "questionId": "BP97b",
                    "chosenOptionIds": [
                        "6895096a-ef29-546e-9967-d7a23b4a2859"
                    ]
                },
                "BP98b": {
                    "questionId": "BP98b",
                    "chosenOptionIds": [
                        "01641419-fbfc-52a5-ae63-0fa736ff9847"
                    ]
                },
                "BP99.3b": {
                    "questionId": "BP99.3b",
                    "chosenOptionIds": [
                        "bf30a6c2-f8e2-5305-b4d4-7ad6227449d3"
                    ]
                },
                "BP99b": {
                    "questionId": "BP99b",
                    "chosenOptionIds": [
                        "68860df1-e835-50d6-8eb2-f9d9f2f97efa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "BP_neg1": {
                    "questionId": "BP_neg1",
                    "chosenOptionIds": [
                        "119208b0-ff3b-5c43-80be-4f07e65b6248"
                    ]
                }
            }
        },
        {
            "name": "Mood test: J3J4P0Y aspect: simple_mdd should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "c61d70d4-b773-54dd-94d0-d858a47be0fb"
                    ]
                },
                "D-1.5b": {
                    "questionId": "D-1.5b",
                    "freeValue": 5
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "fe031280-7d5d-52cb-aca8-1a8664b3234a"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "916d657d-9f2a-5451-8465-18e3bd863347"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "b74511c7-9f97-5c15-8bf5-6ec4357b4814"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 0.20833333333333331
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "db360e93-3616-5d89-be36-5ccee4aacf1c"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-1bl": {
                    "questionId": "D-1bl",
                    "chosenOptionIds": [
                        "83937123-de06-5b20-a479-c5e3e92848c4"
                    ]
                },
                "D-2": {
                    "questionId": "D-2",
                    "freeValue": 25
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "11f457f5-4c39-5458-ba9a-84478dd379ba"
                    ]
                },
                "D-2bl": {
                    "questionId": "D-2bl",
                    "chosenOptionIds": [
                        "02464c9b-d1d8-5f60-993d-5f37cbfcc102"
                    ]
                },
                "D-2bt": {
                    "questionId": "D-2bt",
                    "chosenOptionIds": [
                        "618070b6-e9e5-599a-a906-eed44f5c2dd9"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "bccbebb3-ed8c-5251-856b-e93a1d4386f6"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5c30c60e-d53e-5cc3-b4a9-fdad9bec4cb7"
                    ]
                },
                "D-3bl": {
                    "questionId": "D-3bl",
                    "freeValue": 3
                },
                "D-3bt": {
                    "questionId": "D-3bt",
                    "chosenOptionIds": [
                        "0d89dae6-d14a-56da-8409-ea7c21e59375"
                    ]
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-42bt": {
                    "questionId": "D-42bt",
                    "freeValue": 0.16666666666666666
                },
                "D-43bt": {
                    "questionId": "D-43bt",
                    "chosenOptionIds": [
                        "644dd944-5a2a-533b-9ef0-fadb5054c422"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "a449e4ae-663b-50fb-9b62-1708b0a32669"
                    ]
                },
                "D-4bt": {
                    "questionId": "D-4bt",
                    "freeValue": 4
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "f3efc764-ca53-52c3-9c3a-38c7f787d415"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "3e093dd3-857e-5004-a4fb-961fbfa59ece"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "adecf2d3-f302-50f8-a4a9-3ffac1789bc6"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "4c78ae3e-7de4-545f-aca1-03fa41fc0c23"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "13f8bac1-d0f5-539b-8596-1c55821bea46"
                    ]
                },
                "D-s10bt": {
                    "questionId": "D-s10bt",
                    "chosenOptionIds": [
                        "da993bd6-c107-53a4-9f6d-09f5a79aff65"
                    ]
                },
                "D-s11bt": {
                    "questionId": "D-s11bt",
                    "chosenOptionIds": [
                        "daf8b3c7-fb19-5a3f-ba9a-784adda7414f",
                        "6994b88e-cfa5-5986-b9e8-625d17d1147c",
                        "16e1a0b8-28c5-5bcd-931d-335c858c3f88",
                        "64af3cae-713a-51e1-99c1-09e2fdaa755d"
                    ]
                },
                "D-s1bt": {
                    "questionId": "D-s1bt",
                    "chosenOptionIds": [
                        "c17482e4-818a-50cd-90fa-1246d037825e"
                    ]
                },
                "D-s2bt": {
                    "questionId": "D-s2bt",
                    "chosenOptionIds": [
                        "aef7f114-e448-575f-bcbb-035e54001375"
                    ]
                },
                "D-s3bt": {
                    "questionId": "D-s3bt",
                    "chosenOptionIds": [
                        "11f57889-ac93-53ab-88a7-9aea03a29429"
                    ]
                },
                "D-s4bt": {
                    "questionId": "D-s4bt",
                    "chosenOptionIds": [
                        "6dd2621e-8242-58f6-8c6c-77df4a9eef53"
                    ]
                },
                "D-s5bt": {
                    "questionId": "D-s5bt",
                    "chosenOptionIds": [
                        "e6a5403c-95a3-58c9-9259-e02e5fafd441"
                    ]
                },
                "D-s6bt": {
                    "questionId": "D-s6bt",
                    "chosenOptionIds": [
                        "bb7e3f7e-73b0-56b0-b3d3-e336498509e8"
                    ]
                },
                "D-s7bt": {
                    "questionId": "D-s7bt",
                    "chosenOptionIds": [
                        "d703f0d6-4b67-5a66-8740-b5e4b3b0eb4d"
                    ]
                },
                "D-s8bt": {
                    "questionId": "D-s8bt",
                    "chosenOptionIds": [
                        "ebfe9c3e-852e-5116-8185-8430f6b361f8"
                    ]
                },
                "D-s9bt": {
                    "questionId": "D-s9bt",
                    "chosenOptionIds": [
                        "da503bbd-e567-576e-a21f-e0fad60dccdd"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "60fe0bb4-d770-56f2-b05a-864625a48e4d"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "7599bcaa-89a5-5f71-80ea-b70c360bb44a"
                    ]
                },
                "BP101b": {
                    "questionId": "BP101b",
                    "chosenOptionIds": [
                        "cb80bcac-db78-55bd-b051-bdd00801fd54"
                    ]
                },
                "BP102b": {
                    "questionId": "BP102b",
                    "chosenOptionIds": [
                        "46a64b5b-6641-5b1b-92aa-5366444fb134"
                    ]
                },
                "BP103b": {
                    "questionId": "BP103b",
                    "chosenOptionIds": [
                        "867b0ee4-73d9-5aac-b96d-bc2d85d5b183"
                    ]
                },
                "BP104b": {
                    "questionId": "BP104b",
                    "chosenOptionIds": [
                        "c59b5910-3279-509f-8175-f2fd0da2dc86"
                    ]
                },
                "BP87b": {
                    "questionId": "BP87b",
                    "chosenOptionIds": [
                        "7949bd96-3757-5573-bd89-3a91a383cc99"
                    ]
                },
                "BP88b": {
                    "questionId": "BP88b",
                    "chosenOptionIds": [
                        "fa24b924-a8e1-5f02-893b-3d9643007991"
                    ]
                },
                "BP89b": {
                    "questionId": "BP89b",
                    "chosenOptionIds": [
                        "db509963-d872-5b97-ba9b-a276f58e0189"
                    ]
                },
                "BP90b": {
                    "questionId": "BP90b",
                    "chosenOptionIds": [
                        "95c2822d-27ad-5bb0-982f-8c2b949c8918"
                    ]
                },
                "BP92b": {
                    "questionId": "BP92b",
                    "chosenOptionIds": [
                        "f59918ba-eb3a-5cf1-81aa-8b7f6ac0df5e"
                    ]
                },
                "BP94b": {
                    "questionId": "BP94b",
                    "chosenOptionIds": [
                        "038cb12b-2783-569a-b1d7-e28c65e591a3"
                    ]
                },
                "BP95b": {
                    "questionId": "BP95b",
                    "chosenOptionIds": [
                        "fb0bd4a7-2793-5b81-badc-fe81b4bc82dc"
                    ]
                },
                "BP96b": {
                    "questionId": "BP96b",
                    "chosenOptionIds": [
                        "8c3d3503-6334-55b9-b0f0-94c8e520094e"
                    ]
                },
                "BP97b": {
                    "questionId": "BP97b",
                    "chosenOptionIds": [
                        "8a1fc783-28cb-5bf7-aa78-8f6b7c36ad29"
                    ]
                },
                "BP98b": {
                    "questionId": "BP98b",
                    "chosenOptionIds": [
                        "01641419-fbfc-52a5-ae63-0fa736ff9847"
                    ]
                },
                "BP99.2b": {
                    "questionId": "BP99.2b",
                    "chosenOptionIds": [
                        "0f933dbb-72b4-5212-b415-8ea806f59368"
                    ]
                },
                "BP99.3b": {
                    "questionId": "BP99.3b",
                    "chosenOptionIds": [
                        "bf30a6c2-f8e2-5305-b4d4-7ad6227449d3"
                    ]
                },
                "BP99b": {
                    "questionId": "BP99b",
                    "chosenOptionIds": [
                        "3f987aaa-e435-55a9-9c1b-aa6d2e476738"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "BP_neg1": {
                    "questionId": "BP_neg1",
                    "chosenOptionIds": [
                        "119208b0-ff3b-5c43-80be-4f07e65b6248"
                    ]
                }
            }
        },
        {
            "name": "Mood test: Z3RLO3O aspect: simple_mdd should be clinical",
            "expected": {
                "type": "Severity",
                "value": "clinical"
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "3bd18f66-6f1e-5ea4-ae24-2990ed197bef"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "eb68a88d-b49b-5cde-a9c6-5e4c78e27d19"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "a0e528ca-2fbb-5da1-9398-0758f79755aa"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 240
                },
                "D-15a": {
                    "questionId": "D-15a",
                    "chosenOptionIds": [
                        "7b763ff0-7703-54f7-8eae-aa83417ca133"
                    ]
                },
                "D-16.5a": {
                    "questionId": "D-16.5a",
                    "chosenOptionIds": [
                        "ae0a4d4e-e9bf-51bf-8506-0cbced38db92"
                    ]
                },
                "D-17a": {
                    "questionId": "D-17a",
                    "chosenOptionIds": [
                        "33b013af-d830-516a-9632-69dcc7a0f736"
                    ]
                },
                "D-18a": {
                    "questionId": "D-18a",
                    "chosenOptionIds": [
                        "24079de6-89e4-58c8-bb94-8e281cfc37da"
                    ]
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "db360e93-3616-5d89-be36-5ccee4aacf1c"
                    ]
                },
                "D-20a": {
                    "questionId": "D-20a",
                    "chosenOptionIds": [
                        "15c694bd-3089-51d8-8fff-6286e2113f9c"
                    ]
                },
                "D-21a": {
                    "questionId": "D-21a",
                    "chosenOptionIds": [
                        "e64b46f6-a0c1-5378-95de-f1de59b491b4"
                    ]
                },
                "D-23a": {
                    "questionId": "D-23a",
                    "chosenOptionIds": [
                        "f6cf63c5-0c57-5422-9431-55fec4c93c63"
                    ]
                },
                "D-26a": {
                    "questionId": "D-26a",
                    "chosenOptionIds": [
                        "945792e1-fdf5-5959-89c8-6a1caa4eff88"
                    ]
                },
                "D-27a": {
                    "questionId": "D-27a",
                    "chosenOptionIds": [
                        "8896026c-15ab-577e-8c44-e63eb7e6dba3"
                    ]
                },
                "D-28a": {
                    "questionId": "D-28a",
                    "chosenOptionIds": [
                        "8de8c31e-96ef-5e7d-8024-f6a72d07dd28"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "de11fb6b-7e7d-52b1-9d09-2d6debc53314"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "fd9c4394-6664-5144-8797-00d27a4dda01"
                    ]
                },
                "D-30a": {
                    "questionId": "D-30a",
                    "chosenOptionIds": [
                        "d309cef4-2204-5a10-a98d-b66740336838"
                    ]
                },
                "D-31a": {
                    "questionId": "D-31a",
                    "chosenOptionIds": [
                        "eba65e81-9b02-55d6-82cb-0b7ce6da41de"
                    ]
                },
                "D-32a": {
                    "questionId": "D-32a",
                    "chosenOptionIds": [
                        "ea2a4fbd-008a-571b-bb68-cbd0ba351521"
                    ]
                },
                "D-33a": {
                    "questionId": "D-33a",
                    "chosenOptionIds": [
                        "84dd5565-eec7-54c7-86e9-fa11de50e2c3"
                    ]
                },
                "D-34a": {
                    "questionId": "D-34a",
                    "chosenOptionIds": [
                        "e919c54f-d9a4-5a15-9ae7-8b6b52607a22"
                    ]
                },
                "D-35a": {
                    "questionId": "D-35a",
                    "chosenOptionIds": [
                        "bcace469-f380-599d-a33a-26db6d0053ad"
                    ]
                },
                "D-36a": {
                    "questionId": "D-36a",
                    "chosenOptionIds": [
                        "185038dd-ce55-53b2-ba09-0bd163af90b3"
                    ]
                },
                "D-37a": {
                    "questionId": "D-37a",
                    "chosenOptionIds": [
                        "fd4e0608-283c-5f56-a026-edfa381519c9"
                    ]
                },
                "D-38a": {
                    "questionId": "D-38a",
                    "chosenOptionIds": [
                        "cfa17d19-5b15-56ef-82c1-07949e21dd81"
                    ]
                },
                "D-39a": {
                    "questionId": "D-39a",
                    "chosenOptionIds": [
                        "ae31125f-c3db-5082-ae70-4329a2508744"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "db7514e2-d317-55fb-82d3-2e4a49c2494b"
                    ]
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-40a": {
                    "questionId": "D-40a",
                    "chosenOptionIds": [
                        "582119a7-d4a0-58ff-9b1d-2af045e11a7f"
                    ]
                },
                "D-41a": {
                    "questionId": "D-41a",
                    "chosenOptionIds": [
                        "89f5ff80-0cae-515a-a4d1-02448dbc11b8"
                    ]
                },
                "D-42a": {
                    "questionId": "D-42a",
                    "chosenOptionIds": [
                        "7099f8a9-4650-5da2-a627-bcd62cd99375"
                    ]
                },
                "D-43a": {
                    "questionId": "D-43a",
                    "chosenOptionIds": [
                        "cd0abe72-b3e8-5c78-8330-7ee4a1657536"
                    ]
                },
                "D-44.5a": {
                    "questionId": "D-44.5a",
                    "chosenOptionIds": [
                        "d87971dc-d745-5b64-ac37-37fbe1e722ac",
                        "bcf5fb9f-f4b9-5f0c-a964-69a4e1a73334"
                    ]
                },
                "D-44a": {
                    "questionId": "D-44a",
                    "chosenOptionIds": [
                        "7d6584ca-1b93-543d-9ee4-400ca40134be"
                    ]
                },
                "D-45a": {
                    "questionId": "D-45a",
                    "freeValue": 14
                },
                "D-46a": {
                    "questionId": "D-46a",
                    "chosenOptionIds": [
                        "1fd426df-808a-5c68-9b6e-23ff2f128b7d",
                        "4c178978-f79a-58fc-8e73-3a98614c4b6c",
                        "6e49f368-62af-5abb-9034-a58bd534944f",
                        "b3e43bd8-fbe3-5cda-b891-6f11e43993d0",
                        "d9665b1f-cdd2-5979-895e-27bca6487533",
                        "7492eab7-c625-52d0-a2e3-4bf3332decc0"
                    ]
                },
                "D-49.1a": {
                    "questionId": "D-49.1a",
                    "chosenOptionIds": [
                        "649f1c9d-3139-5567-b605-e22de7ed4f87"
                    ]
                },
                "D-49a": {
                    "questionId": "D-49a",
                    "chosenOptionIds": [
                        "16dc6af1-32a9-59c5-b66c-57f6becea1e0"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "1d4beed1-78e5-531c-a255-643fac260dee"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "b52073e3-c813-51f5-88ba-9cd3bddd7023"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "3e093dd3-857e-5004-a4fb-961fbfa59ece"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "772bee29-c3a5-52e1-b785-a77d506ae4fb"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "4c78ae3e-7de4-545f-aca1-03fa41fc0c23"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "60fe0bb4-d770-56f2-b05a-864625a48e4d"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "ccbdcbec-96d0-5362-977b-784289f5cfc0"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "4a0f1c4d-38cf-5de4-99e3-0ee818f9709c"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "6a5aa899-7b89-5661-a554-71b6058ad4a4"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "d3224e16-1b0c-5266-9e14-ac72d2b2e988"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "b24df75b-6bb9-5f5f-99e3-c883ec3644ec"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP12": {
                    "questionId": "BP12",
                    "chosenOptionIds": [
                        "1d6299ac-2ac6-51e2-9a3f-13e8d44e652a"
                    ]
                },
                "BP12.5": {
                    "questionId": "BP12.5",
                    "chosenOptionIds": [
                        "cd7ce00f-4e7c-57ac-9c6c-4137efa24c8c"
                    ]
                },
                "BP14": {
                    "questionId": "BP14",
                    "chosenOptionIds": [
                        "5585a7e8-a4b7-567e-b339-90537a58f1e3"
                    ]
                },
                "BP14.1": {
                    "questionId": "BP14.1",
                    "chosenOptionIds": [
                        "69f9a784-ce6d-5c82-bd3f-4c9c44bf5992"
                    ]
                },
                "BP14.2": {
                    "questionId": "BP14.2",
                    "freeValue": 365
                },
                "BP14.3": {
                    "questionId": "BP14.3",
                    "chosenOptionIds": [
                        "9f5fc152-75fb-59d8-8f40-7a6ca15c69cb"
                    ]
                },
                "BP16": {
                    "questionId": "BP16",
                    "chosenOptionIds": [
                        "fabceeea-8fea-5444-8b67-8f189f366c27"
                    ]
                },
                "BP16.0": {
                    "questionId": "BP16.0",
                    "chosenOptionIds": [
                        "4b26caa5-9124-51b2-ab08-2f4e977cee1f"
                    ]
                },
                "BP16.01": {
                    "questionId": "BP16.01",
                    "chosenOptionIds": [
                        "f81655d7-78e5-59b1-a7eb-90a2ec7a107d"
                    ]
                },
                "BP16.02": {
                    "questionId": "BP16.02",
                    "chosenOptionIds": [
                        "16a695c9-3ac1-5d34-b799-44602d85b556"
                    ]
                },
                "BP18": {
                    "questionId": "BP18",
                    "chosenOptionIds": [
                        "b577f323-ec54-5d25-a2b9-4f8de30d60a2"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 43
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "f03de28d-8f71-5af4-be2f-45f4e6092a3d"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "387b19c0-67d0-557e-9fba-ae065dcf3587"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "afb57366-c0b3-5f73-92eb-27bc133ad2ed"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "667ba57c-b1cd-5f9b-aa12-58e65a981910"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "fd30dc27-8e89-5d1a-9d95-8844603d4379"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "e22cf233-c174-52fb-af55-97bf9c46e971"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "0feda24f-7b01-557b-8ca5-10eb924cbdac"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "d680a8d9-e35f-563f-9d1d-e7c3136aff6d"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "94795194-4a80-590f-be62-ee78f2fa0ff5"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "732c51f9-a0b6-5951-bd7d-eaaec24316ba"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "6928c696-e6d7-5a14-a004-15a497ac6562"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "17b4da6f-7135-523c-b90b-2fe3b66785ff"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "3cf77140-2757-58eb-b4ea-f2a90cd766e7"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "5c06d516-e816-58d6-997e-f8fa91131ac7"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "a020ed85-ec9d-5778-8006-41f634cab09d"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM19": {
                    "questionId": "HM19",
                    "chosenOptionIds": [
                        "99f474d7-d509-592a-856a-afed343fb1b8"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "98ea07a0-ae95-570f-8d11-a3e7c682e986"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "696fdd9d-5ced-504b-a933-bb3e575b0099"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c41974b6-d394-569c-8f45-49f79fcac5c6"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "43c32319-c75f-5169-b0e5-713820721d4f"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "b2cb304f-f035-53fc-8763-e2f91b031f9b"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "0170b4cf-0541-53cd-a9db-818d5067dc23"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "2be66549-5767-51b4-82ed-deeab0723f8d"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "2a17c2fe-2592-58b1-98e9-3dc4168fe3e3"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "834b04f2-af09-5337-8fd5-be4a70c99e75"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "fa32b497-4a4b-5076-a391-b6dffa8e1851"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "8823c74f-ff7c-52d8-9c73-fd3b4180f48a"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "1d07911e-929d-5719-aeea-a68f593d18e8"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: 50MQK36 aspect: simple_mdd should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "0ec0e224-a5cf-5ece-b3ab-37061a600ace"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "a16e8e14-524e-53df-b4ef-730a2c8e5f32"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "eb68a88d-b49b-5cde-a9c6-5e4c78e27d19"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "a0e528ca-2fbb-5da1-9398-0758f79755aa"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 270
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "913bc1e9-8da1-54c7-b935-13fc5d48fbd9"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "11f457f5-4c39-5458-ba9a-84478dd379ba"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5b6c0a69-6369-53d1-b2e1-5cd6294a9f71"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "9d1b858c-7064-5a84-80d6-786c18e603b6",
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b",
                        "1d4beed1-78e5-531c-a255-643fac260dee"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "b52073e3-c813-51f5-88ba-9cd3bddd7023"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "3e093dd3-857e-5004-a4fb-961fbfa59ece"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "adecf2d3-f302-50f8-a4a9-3ffac1789bc6"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "67316d94-1cf0-5bd0-a4db-f57bc70684ba"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "60fe0bb4-d770-56f2-b05a-864625a48e4d"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "7599bcaa-89a5-5f71-80ea-b70c360bb44a"
                    ]
                },
                "BP101b": {
                    "questionId": "BP101b",
                    "chosenOptionIds": [
                        "fe614147-f80a-5aff-bb03-9298781e2468"
                    ]
                },
                "BP102b": {
                    "questionId": "BP102b",
                    "chosenOptionIds": [
                        "523c7d8e-e42b-5654-b037-e8837afb49e7"
                    ]
                },
                "BP103b": {
                    "questionId": "BP103b",
                    "chosenOptionIds": [
                        "867b0ee4-73d9-5aac-b96d-bc2d85d5b183"
                    ]
                },
                "BP104b": {
                    "questionId": "BP104b",
                    "chosenOptionIds": [
                        "c59b5910-3279-509f-8175-f2fd0da2dc86"
                    ]
                },
                "BP87b": {
                    "questionId": "BP87b",
                    "chosenOptionIds": [
                        "659c0d9a-389d-5b62-b3ac-e385dfafe63c"
                    ]
                },
                "BP88b": {
                    "questionId": "BP88b",
                    "chosenOptionIds": [
                        "7e4bdef7-d166-5a09-b3a4-639c1700578c"
                    ]
                },
                "BP89b": {
                    "questionId": "BP89b",
                    "chosenOptionIds": [
                        "db509963-d872-5b97-ba9b-a276f58e0189"
                    ]
                },
                "BP90b": {
                    "questionId": "BP90b",
                    "chosenOptionIds": [
                        "b4a79888-68e8-5b52-9a4e-2149be85f6e7"
                    ]
                },
                "BP92b": {
                    "questionId": "BP92b",
                    "chosenOptionIds": [
                        "b2f4c490-30df-5c8f-87c0-bc4acbcfb8e0"
                    ]
                },
                "BP94b": {
                    "questionId": "BP94b",
                    "chosenOptionIds": [
                        "9cf74958-461b-59df-b1a2-7ee7d7696a0b"
                    ]
                },
                "BP95b": {
                    "questionId": "BP95b",
                    "chosenOptionIds": [
                        "fb0bd4a7-2793-5b81-badc-fe81b4bc82dc"
                    ]
                },
                "BP96b": {
                    "questionId": "BP96b",
                    "chosenOptionIds": [
                        "4043e017-8609-50ef-bb4a-29f57c28965a"
                    ]
                },
                "BP97b": {
                    "questionId": "BP97b",
                    "chosenOptionIds": [
                        "8a1fc783-28cb-5bf7-aa78-8f6b7c36ad29"
                    ]
                },
                "BP98b": {
                    "questionId": "BP98b",
                    "chosenOptionIds": [
                        "cae1d431-f7ae-5a5a-924d-25c540e96d28"
                    ]
                },
                "BP99.3b": {
                    "questionId": "BP99.3b",
                    "chosenOptionIds": [
                        "d0adef9f-05e3-5ddf-a0f3-60eeb50d3862"
                    ]
                },
                "BP99b": {
                    "questionId": "BP99b",
                    "chosenOptionIds": [
                        "68860df1-e835-50d6-8eb2-f9d9f2f97efa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "BP_neg1": {
                    "questionId": "BP_neg1",
                    "chosenOptionIds": [
                        "119208b0-ff3b-5c43-80be-4f07e65b6248"
                    ]
                }
            }
        },
        {
            "name": "Mood test: 2E17NED aspect: simple_mdd should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "c61d70d4-b773-54dd-94d0-d858a47be0fb"
                    ]
                },
                "D-1.5b": {
                    "questionId": "D-1.5b",
                    "freeValue": 3
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "3bd18f66-6f1e-5ea4-ae24-2990ed197bef"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "916d657d-9f2a-5451-8465-18e3bd863347"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "b74511c7-9f97-5c15-8bf5-6ec4357b4814"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 0.125
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "db360e93-3616-5d89-be36-5ccee4aacf1c"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-1bl": {
                    "questionId": "D-1bl",
                    "chosenOptionIds": [
                        "83937123-de06-5b20-a479-c5e3e92848c4"
                    ]
                },
                "D-2": {
                    "questionId": "D-2",
                    "freeValue": 28
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "de11fb6b-7e7d-52b1-9d09-2d6debc53314"
                    ]
                },
                "D-2bl": {
                    "questionId": "D-2bl",
                    "chosenOptionIds": [
                        "02464c9b-d1d8-5f60-993d-5f37cbfcc102"
                    ]
                },
                "D-2bs": {
                    "questionId": "D-2bs",
                    "chosenOptionIds": [
                        "71e4fa61-0420-5a47-8362-38d1db6b5895"
                    ]
                },
                "D-2bt": {
                    "questionId": "D-2bt",
                    "chosenOptionIds": [
                        "618070b6-e9e5-599a-a906-eed44f5c2dd9"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "fd9c4394-6664-5144-8797-00d27a4dda01"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "db7514e2-d317-55fb-82d3-2e4a49c2494b"
                    ]
                },
                "D-3bl": {
                    "questionId": "D-3bl",
                    "freeValue": 2
                },
                "D-3bs": {
                    "questionId": "D-3bs",
                    "chosenOptionIds": [
                        "fe554f25-dfd7-5062-a2b8-f2662e2c3466"
                    ]
                },
                "D-3bt": {
                    "questionId": "D-3bt",
                    "chosenOptionIds": [
                        "0d89dae6-d14a-56da-8409-ea7c21e59375"
                    ]
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-42bt": {
                    "questionId": "D-42bt",
                    "freeValue": 0
                },
                "D-43bt": {
                    "questionId": "D-43bt",
                    "chosenOptionIds": [
                        "90e60395-fc3d-56cb-b6f7-2e24fba02803"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "a449e4ae-663b-50fb-9b62-1708b0a32669"
                    ]
                },
                "D-4bs": {
                    "questionId": "D-4bs",
                    "freeValue": 7
                },
                "D-4bt": {
                    "questionId": "D-4bt",
                    "freeValue": 0.08333333333333333
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "b52073e3-c813-51f5-88ba-9cd3bddd7023"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "ef6fddab-7a07-5f20-af20-74f2444eee53"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "3b216cf8-72e7-558c-bdd3-cc23a1865674"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "D-s10bt": {
                    "questionId": "D-s10bt",
                    "chosenOptionIds": [
                        "3deea42c-5d93-54cd-ba0f-4242654c3747"
                    ]
                },
                "D-s1bt": {
                    "questionId": "D-s1bt",
                    "chosenOptionIds": [
                        "4fa2ad77-a998-57d3-ae06-568782e0fb81"
                    ]
                },
                "D-s2bt": {
                    "questionId": "D-s2bt",
                    "chosenOptionIds": [
                        "e074592a-4667-5b65-b713-b8892451b433"
                    ]
                },
                "D-s3bt": {
                    "questionId": "D-s3bt",
                    "chosenOptionIds": [
                        "11f57889-ac93-53ab-88a7-9aea03a29429"
                    ]
                },
                "D-s4bt": {
                    "questionId": "D-s4bt",
                    "chosenOptionIds": [
                        "27313271-8ec6-5f99-bb2c-0ed50a7c1527"
                    ]
                },
                "D-s5bt": {
                    "questionId": "D-s5bt",
                    "chosenOptionIds": [
                        "d033bb4c-5d3c-519b-be31-1177042bffb4"
                    ]
                },
                "D-s6bt": {
                    "questionId": "D-s6bt",
                    "chosenOptionIds": [
                        "53625e56-4f03-5f54-a340-d5b8cabe24d9"
                    ]
                },
                "D-s7bt": {
                    "questionId": "D-s7bt",
                    "chosenOptionIds": [
                        "321f8608-d6e5-5e11-a88a-11d3351e8650"
                    ]
                },
                "D-s8bt": {
                    "questionId": "D-s8bt",
                    "chosenOptionIds": [
                        "ebfe9c3e-852e-5116-8185-8430f6b361f8"
                    ]
                },
                "D-s9bt": {
                    "questionId": "D-s9bt",
                    "chosenOptionIds": [
                        "34daded3-efdb-5981-a1fc-e31ec1176383"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "6a4d8b43-be3d-59b5-9bf4-1ca9f35b6973"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "ccbdcbec-96d0-5362-977b-784289f5cfc0"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "db7aa4b7-99a5-5475-a4e8-a744f3ef5a4a"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "e9eb902a-d327-5ec5-938c-dc317ca59b13"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "1daccd4c-e5ff-5f18-ba55-7c2d2d93b849"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "b24df75b-6bb9-5f5f-99e3-c883ec3644ec"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP12": {
                    "questionId": "BP12",
                    "chosenOptionIds": [
                        "22f9a995-3f9a-5fe9-99bb-71b7f1f96621"
                    ]
                },
                "BP15": {
                    "questionId": "BP15",
                    "chosenOptionIds": [
                        "9a1158f1-f08a-581d-a088-650041097c24"
                    ]
                },
                "BP15.1": {
                    "questionId": "BP15.1",
                    "chosenOptionIds": [
                        "aa7bd0b8-95ca-5179-a037-3956a8c2816d"
                    ]
                },
                "BP15.2": {
                    "questionId": "BP15.2",
                    "freeValue": 0.08333333333333333
                },
                "BP15.3": {
                    "questionId": "BP15.3",
                    "chosenOptionIds": [
                        "cfa519ff-08a9-5fa4-b12d-87b7a597cb13"
                    ]
                },
                "BP16": {
                    "questionId": "BP16",
                    "chosenOptionIds": [
                        "f1d6b61f-7ae0-5a6f-bbd0-a81b771141d3"
                    ]
                },
                "BP16.0": {
                    "questionId": "BP16.0",
                    "chosenOptionIds": [
                        "4b26caa5-9124-51b2-ab08-2f4e977cee1f"
                    ]
                },
                "BP16.01": {
                    "questionId": "BP16.01",
                    "chosenOptionIds": [
                        "f81655d7-78e5-59b1-a7eb-90a2ec7a107d"
                    ]
                },
                "BP16.02": {
                    "questionId": "BP16.02",
                    "chosenOptionIds": [
                        "16a695c9-3ac1-5d34-b799-44602d85b556"
                    ]
                },
                "BP19": {
                    "questionId": "BP19",
                    "chosenOptionIds": [
                        "601bc370-8a4c-5b1f-a7f6-6c174f421733"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 35
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "c7bee7a0-3037-5eb1-96a1-85b884d7e1dd"
                    ]
                },
                "BP3.01": {
                    "questionId": "BP3.01",
                    "freeValue": 1
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "e620af65-9b5b-57e6-b13a-65616e67e084"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "cb636d32-4f4e-51b3-a23f-2a11b8771b7e"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "0953f30c-801a-54f6-8238-b9dc42ab17f9"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "fd30dc27-8e89-5d1a-9d95-8844603d4379"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "d9fb150b-68b8-50ed-bb1d-eafd41f63f3b"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "46c90446-3ae9-53f5-9b57-ad19d66b5be6"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 0
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "63f0b17a-9832-5f0f-8630-e74f1a4fdfe7"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "c88e3fb5-913a-5c5c-a42b-603f7bb60a40"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "732c51f9-a0b6-5951-bd7d-eaaec24316ba"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "de2ea429-4fcd-53b8-8d9f-ebb69a637a02"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "6928c696-e6d7-5a14-a004-15a497ac6562"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "17b4da6f-7135-523c-b90b-2fe3b66785ff"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "cfb8fe0e-821a-554a-bb57-922f0ecc0f44"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "5c06d516-e816-58d6-997e-f8fa91131ac7"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "c0cc09c3-1335-5eb4-8801-b3cdaf74f6fa"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM20": {
                    "questionId": "HM20",
                    "chosenOptionIds": [
                        "4eb576cf-e8d9-51f2-94cd-d232476ee5cb"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "6b99de8b-634a-5eb0-bc65-bdf30eb9d433"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "9d212885-9b07-5623-8234-226adff08f6d"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c41974b6-d394-569c-8f45-49f79fcac5c6"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "43c32319-c75f-5169-b0e5-713820721d4f"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "b2cb304f-f035-53fc-8763-e2f91b031f9b"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "366c6a1b-c8b2-5faf-9d2f-72dbde24e869"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "2be66549-5767-51b4-82ed-deeab0723f8d"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "2a17c2fe-2592-58b1-98e9-3dc4168fe3e3"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "834b04f2-af09-5337-8fd5-be4a70c99e75"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "86277249-cf50-5f91-a27f-f3a69344e796"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "8823c74f-ff7c-52d8-9c73-fd3b4180f48a"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "7d6921a2-7e63-5f12-9c75-dff8b592fabc"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: 10QJ8EP aspect: simple_mdd should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-1.1b": {
                    "questionId": "D-1.1b",
                    "chosenOptionIds": [
                        "0ec0e224-a5cf-5ece-b3ab-37061a600ace"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "a16e8e14-524e-53df-b4ef-730a2c8e5f32"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "916d657d-9f2a-5451-8465-18e3bd863347"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "a0e528ca-2fbb-5da1-9398-0758f79755aa"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 365
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "913bc1e9-8da1-54c7-b935-13fc5d48fbd9"
                    ]
                },
                "D-1b": {
                    "questionId": "D-1b",
                    "chosenOptionIds": [
                        "dddc811b-ed7f-5814-8c74-717a66be2243"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "11f457f5-4c39-5458-ba9a-84478dd379ba"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "5c30c60e-d53e-5cc3-b4a9-fdad9bec4cb7"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "ba6dc2ba-3c20-57b6-a276-32c863109ff6",
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b",
                        "9d1b858c-7064-5a84-80d6-786c18e603b6"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "c9813959-ba61-5220-9e27-659abb256037"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "e4732e97-da13-522c-aff4-41be6940e7b0"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "adecf2d3-f302-50f8-a4a9-3ffac1789bc6"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "d362cd87-7515-551e-bc74-19ef903484aa"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "d73821d2-c39e-5ad6-adb0-011f4a1d23c5"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "4896b504-2025-52c3-85e2-c6cf3205cef7"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "ccbdcbec-96d0-5362-977b-784289f5cfc0"
                    ]
                },
                "BP10": {
                    "questionId": "BP10",
                    "chosenOptionIds": [
                        "7ad54f0a-87a6-5e82-bfc3-44b540230082"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "db7aa4b7-99a5-5475-a4e8-a744f3ef5a4a"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "ca5ac96d-2b69-5200-a063-61db840c9ef2"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "1daccd4c-e5ff-5f18-ba55-7c2d2d93b849"
                    ]
                },
                "BP103": {
                    "questionId": "BP103",
                    "chosenOptionIds": [
                        "b24df75b-6bb9-5f5f-99e3-c883ec3644ec"
                    ]
                },
                "BP104": {
                    "questionId": "BP104",
                    "chosenOptionIds": [
                        "71f4aadd-848c-56d9-b008-3b1bf7ddae28"
                    ]
                },
                "BP2": {
                    "questionId": "BP2",
                    "freeValue": 25
                },
                "BP3": {
                    "questionId": "BP3",
                    "chosenOptionIds": [
                        "c7bee7a0-3037-5eb1-96a1-85b884d7e1dd"
                    ]
                },
                "BP3.1": {
                    "questionId": "BP3.1",
                    "chosenOptionIds": [
                        "5bf1e313-2624-5b5f-9673-37ae9319ff0c"
                    ]
                },
                "BP3.2": {
                    "questionId": "BP3.2",
                    "chosenOptionIds": [
                        "ec333f8b-146a-5700-a15d-527032ceac3f"
                    ]
                },
                "BP3.3": {
                    "questionId": "BP3.3",
                    "freeValue": 5
                },
                "BP3.4": {
                    "questionId": "BP3.4",
                    "chosenOptionIds": [
                        "6ec82bbf-7582-5509-ace2-5ecf0ce82ec3"
                    ]
                },
                "BP4": {
                    "questionId": "BP4",
                    "chosenOptionIds": [
                        "9765aba8-7d55-5928-b79d-36aa58646036"
                    ]
                },
                "BP4.5": {
                    "questionId": "BP4.5",
                    "chosenOptionIds": [
                        "66a3460c-65ce-5a4c-9774-2e303ae4961e"
                    ]
                },
                "BP6": {
                    "questionId": "BP6",
                    "chosenOptionIds": [
                        "742a22d6-a570-5e3a-8e72-c001f1e9f98b"
                    ]
                },
                "BP6.1": {
                    "questionId": "BP6.1",
                    "chosenOptionIds": [
                        "a99773bd-2bff-523e-bc29-1f761307b3d4"
                    ]
                },
                "BP6.2": {
                    "questionId": "BP6.2",
                    "freeValue": 5
                },
                "BP6.3": {
                    "questionId": "BP6.3",
                    "chosenOptionIds": [
                        "e061c578-086e-5990-bba6-279cb273f3a5"
                    ]
                },
                "BP8": {
                    "questionId": "BP8",
                    "chosenOptionIds": [
                        "87e0081b-9455-5ced-9225-10c2f70b1734"
                    ]
                },
                "BP8.0": {
                    "questionId": "BP8.0",
                    "chosenOptionIds": [
                        "3568cdb1-2500-512b-aada-2547c5bfd835"
                    ]
                },
                "BP8.01": {
                    "questionId": "BP8.01",
                    "chosenOptionIds": [
                        "5020bfdd-a280-56cf-b7f5-3b0b7e667165"
                    ]
                },
                "BP8.02": {
                    "questionId": "BP8.02",
                    "chosenOptionIds": [
                        "7d3a0aa5-605b-58d1-a834-073feefe8e5d"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "387b19c0-67d0-557e-9fba-ae065dcf3587"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "afb57366-c0b3-5f73-92eb-27bc133ad2ed"
                    ]
                },
                "BP89": {
                    "questionId": "BP89",
                    "chosenOptionIds": [
                        "667ba57c-b1cd-5f9b-aa12-58e65a981910"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "fd30dc27-8e89-5d1a-9d95-8844603d4379"
                    ]
                },
                "BP90.1": {
                    "questionId": "BP90.1",
                    "chosenOptionIds": [
                        "e22cf233-c174-52fb-af55-97bf9c46e971"
                    ]
                },
                "BP91": {
                    "questionId": "BP91",
                    "chosenOptionIds": [
                        "cc7c09ae-1cb7-5b9a-9cd3-8d1012b90ee8"
                    ]
                },
                "BP91.1": {
                    "questionId": "BP91.1",
                    "freeValue": 5
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "17619692-6d18-5558-99da-766a1c275e03"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "f35fe383-d2aa-51c4-9fde-961b1de6d52b"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "ec84966e-bb9f-5ba9-a0cf-d5d3503a74e0"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "4a7d2992-b9e5-5e91-9a28-c2283dda5106"
                    ]
                },
                "BP96": {
                    "questionId": "BP96",
                    "chosenOptionIds": [
                        "6928c696-e6d7-5a14-a004-15a497ac6562"
                    ]
                },
                "BP97": {
                    "questionId": "BP97",
                    "chosenOptionIds": [
                        "2723ed09-37f6-522c-92b1-e518d4fe9315"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "3cf77140-2757-58eb-b4ea-f2a90cd766e7"
                    ]
                },
                "BP99": {
                    "questionId": "BP99",
                    "chosenOptionIds": [
                        "5c06d516-e816-58d6-997e-f8fa91131ac7"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "e81fe613-4923-55ed-bd6c-bd3f19e026f9"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM2": {
                    "questionId": "HM2",
                    "chosenOptionIds": [
                        "de662c3f-66b4-566f-856d-2124e2725542"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "c3ee8981-dec3-5b3c-80ca-c1023f983a6d"
                    ]
                },
                "HM22": {
                    "questionId": "HM22",
                    "chosenOptionIds": [
                        "696fdd9d-5ced-504b-a933-bb3e575b0099"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c41974b6-d394-569c-8f45-49f79fcac5c6"
                    ]
                },
                "HM25": {
                    "questionId": "HM25",
                    "chosenOptionIds": [
                        "43c32319-c75f-5169-b0e5-713820721d4f"
                    ]
                },
                "HM26": {
                    "questionId": "HM26",
                    "chosenOptionIds": [
                        "b2cb304f-f035-53fc-8763-e2f91b031f9b"
                    ]
                },
                "HM28": {
                    "questionId": "HM28",
                    "chosenOptionIds": [
                        "0170b4cf-0541-53cd-a9db-818d5067dc23"
                    ]
                },
                "HM29": {
                    "questionId": "HM29",
                    "chosenOptionIds": [
                        "2be66549-5767-51b4-82ed-deeab0723f8d"
                    ]
                },
                "HM30": {
                    "questionId": "HM30",
                    "chosenOptionIds": [
                        "2a17c2fe-2592-58b1-98e9-3dc4168fe3e3"
                    ]
                },
                "HM31": {
                    "questionId": "HM31",
                    "chosenOptionIds": [
                        "834b04f2-af09-5337-8fd5-be4a70c99e75"
                    ]
                },
                "HM32": {
                    "questionId": "HM32",
                    "chosenOptionIds": [
                        "fda00d52-c8be-5b69-bdbd-905c13dddd4e"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "8823c74f-ff7c-52d8-9c73-fd3b4180f48a"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "1d07911e-929d-5719-aeea-a68f593d18e8"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        },
        {
            "name": "Mood test: XE7DM0L aspect: simple_mdd should be undiagnosed",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "D-1": {
                    "questionId": "D-1",
                    "chosenOptionIds": [
                        "ecfeaa5a-5775-5d90-945c-aba2fa2a2678"
                    ]
                },
                "D-10a": {
                    "questionId": "D-10a",
                    "chosenOptionIds": [
                        "fe031280-7d5d-52cb-aca8-1a8664b3234a"
                    ]
                },
                "D-12a": {
                    "questionId": "D-12a",
                    "chosenOptionIds": [
                        "9b47900d-e5a7-5e58-830b-d9b3130a7990"
                    ]
                },
                "D-13a": {
                    "questionId": "D-13a",
                    "chosenOptionIds": [
                        "826538fc-8a97-55e4-8013-fe6a93f8e451"
                    ]
                },
                "D-14a": {
                    "questionId": "D-14a",
                    "freeValue": 4745
                },
                "D-15a": {
                    "questionId": "D-15a",
                    "chosenOptionIds": [
                        "7b763ff0-7703-54f7-8eae-aa83417ca133"
                    ]
                },
                "D-16.5a": {
                    "questionId": "D-16.5a",
                    "chosenOptionIds": [
                        "b409dbb9-80ef-5de1-96d0-1075735134db"
                    ]
                },
                "D-17a": {
                    "questionId": "D-17a",
                    "chosenOptionIds": [
                        "8413ed32-e959-5c2a-9d19-8cc68709c34d"
                    ]
                },
                "D-19a": {
                    "questionId": "D-19a",
                    "chosenOptionIds": [
                        "6fde9b5c-ed1c-561c-b516-8f5028a1bcd5"
                    ]
                },
                "D-1a": {
                    "questionId": "D-1a",
                    "chosenOptionIds": [
                        "80c993c8-1a94-5684-a8f9-d8f5072c8e9c"
                    ]
                },
                "D-1bl": {
                    "questionId": "D-1bl",
                    "chosenOptionIds": [
                        "5da400b0-5bff-5744-a664-4305892ae669"
                    ]
                },
                "D-2": {
                    "questionId": "D-2",
                    "freeValue": 48
                },
                "D-20a": {
                    "questionId": "D-20a",
                    "chosenOptionIds": [
                        "15c694bd-3089-51d8-8fff-6286e2113f9c"
                    ]
                },
                "D-21a": {
                    "questionId": "D-21a",
                    "chosenOptionIds": [
                        "dc2b002f-2d96-504d-bde9-66faceececb6"
                    ]
                },
                "D-23a": {
                    "questionId": "D-23a",
                    "chosenOptionIds": [
                        "32a2b9d2-46ee-5235-9c03-8fb1fb1e5800"
                    ]
                },
                "D-26a": {
                    "questionId": "D-26a",
                    "chosenOptionIds": [
                        "eb4d6df4-7e45-5437-a36e-540fa95a45f9"
                    ]
                },
                "D-27a": {
                    "questionId": "D-27a",
                    "chosenOptionIds": [
                        "3d120f97-fb12-5e86-8bb9-8fc93babc803"
                    ]
                },
                "D-28a": {
                    "questionId": "D-28a",
                    "chosenOptionIds": [
                        "009661f3-60b5-52e8-bc5f-44c65767874c"
                    ]
                },
                "D-2a": {
                    "questionId": "D-2a",
                    "chosenOptionIds": [
                        "cc243947-5067-5a98-ade6-a4e10d0b42cf"
                    ]
                },
                "D-2bl": {
                    "questionId": "D-2bl",
                    "chosenOptionIds": [
                        "2a8c96e2-cb5b-596a-99b6-a4885253776c"
                    ]
                },
                "D-2bs": {
                    "questionId": "D-2bs",
                    "chosenOptionIds": [
                        "bbd43ce6-ea63-5321-9d05-0bbf9a19513b"
                    ]
                },
                "D-2bt": {
                    "questionId": "D-2bt",
                    "chosenOptionIds": [
                        "315fa694-3bc0-5231-93f2-ceb1d9766403"
                    ]
                },
                "D-3": {
                    "questionId": "D-3",
                    "chosenOptionIds": [
                        "bccbebb3-ed8c-5251-856b-e93a1d4386f6",
                        "14d310b2-5fb6-5f79-8bfa-e3524002e63d",
                        "fd9c4394-6664-5144-8797-00d27a4dda01"
                    ]
                },
                "D-30a": {
                    "questionId": "D-30a",
                    "chosenOptionIds": [
                        "1f6e9ea6-3402-596b-a518-31105c7a277b"
                    ]
                },
                "D-31a": {
                    "questionId": "D-31a",
                    "chosenOptionIds": [
                        "c78e0def-d73e-5c4e-8800-7c6f92d0a1ec"
                    ]
                },
                "D-32a": {
                    "questionId": "D-32a",
                    "chosenOptionIds": [
                        "ea2a4fbd-008a-571b-bb68-cbd0ba351521"
                    ]
                },
                "D-33a": {
                    "questionId": "D-33a",
                    "chosenOptionIds": [
                        "3968dd70-f060-5cbd-bb6a-d63f702a3691"
                    ]
                },
                "D-34a": {
                    "questionId": "D-34a",
                    "chosenOptionIds": [
                        "9d969817-a8d1-57f5-8c15-5e89835ed2a4"
                    ]
                },
                "D-35a": {
                    "questionId": "D-35a",
                    "chosenOptionIds": [
                        "9c9d44bb-b50f-5ddf-9553-03b254272362"
                    ]
                },
                "D-36a": {
                    "questionId": "D-36a",
                    "chosenOptionIds": [
                        "f6958030-4bae-5f83-a569-35cb7675b803"
                    ]
                },
                "D-37a": {
                    "questionId": "D-37a",
                    "chosenOptionIds": [
                        "fd4e0608-283c-5f56-a026-edfa381519c9"
                    ]
                },
                "D-38a": {
                    "questionId": "D-38a",
                    "chosenOptionIds": [
                        "a03f02de-f86d-525a-b375-383ad9d3cefe"
                    ]
                },
                "D-39a": {
                    "questionId": "D-39a",
                    "chosenOptionIds": [
                        "ae31125f-c3db-5082-ae70-4329a2508744"
                    ]
                },
                "D-3a": {
                    "questionId": "D-3a",
                    "chosenOptionIds": [
                        "f110f5ec-75f1-51be-9c45-ea7685a54d64"
                    ]
                },
                "D-3bl": {
                    "questionId": "D-3bl",
                    "freeValue": 4745
                },
                "D-3bs": {
                    "questionId": "D-3bs",
                    "chosenOptionIds": [
                        "c4422e8a-6a0a-51ea-8592-9157036922d1"
                    ]
                },
                "D-3bt": {
                    "questionId": "D-3bt",
                    "chosenOptionIds": [
                        "b9abc28d-6e22-5b67-bc5e-27431e123974"
                    ]
                },
                "D-4": {
                    "questionId": "D-4",
                    "chosenOptionIds": [
                        "81979846-ac0a-5857-8380-6c04ef0064b5"
                    ]
                },
                "D-40a": {
                    "questionId": "D-40a",
                    "chosenOptionIds": [
                        "eece7786-6774-532b-ac46-218f4cd6f083"
                    ]
                },
                "D-41a": {
                    "questionId": "D-41a",
                    "chosenOptionIds": [
                        "6a5d1a65-0b53-5485-995a-76d8d7eafb1a"
                    ]
                },
                "D-42a": {
                    "questionId": "D-42a",
                    "chosenOptionIds": [
                        "926931de-e675-5117-9e61-78dd541393be"
                    ]
                },
                "D-42bt": {
                    "questionId": "D-42bt",
                    "freeValue": 1095
                },
                "D-43a": {
                    "questionId": "D-43a",
                    "chosenOptionIds": [
                        "cd0abe72-b3e8-5c78-8330-7ee4a1657536"
                    ]
                },
                "D-43bt": {
                    "questionId": "D-43bt",
                    "chosenOptionIds": [
                        "90e60395-fc3d-56cb-b6f7-2e24fba02803"
                    ]
                },
                "D-44.5a": {
                    "questionId": "D-44.5a",
                    "chosenOptionIds": [
                        "d87971dc-d745-5b64-ac37-37fbe1e722ac",
                        "b5fc3288-710a-5aef-9842-611757b40967",
                        "884767e3-ed0c-5db3-a227-f84f7ecaf8d3",
                        "d3ed5c81-5b77-58c3-983c-d29f9788c396",
                        "bcf5fb9f-f4b9-5f0c-a964-69a4e1a73334"
                    ]
                },
                "D-44a": {
                    "questionId": "D-44a",
                    "chosenOptionIds": [
                        "68a987a9-8bad-50d7-a4d9-e970334f0460"
                    ]
                },
                "D-45a": {
                    "questionId": "D-45a",
                    "freeValue": 1095
                },
                "D-46a": {
                    "questionId": "D-46a",
                    "chosenOptionIds": [
                        "f0b15a45-ae49-59d2-97a7-33f866b0e629",
                        "b3e43bd8-fbe3-5cda-b891-6f11e43993d0",
                        "6e49f368-62af-5abb-9034-a58bd534944f",
                        "4c178978-f79a-58fc-8e73-3a98614c4b6c",
                        "7492eab7-c625-52d0-a2e3-4bf3332decc0",
                        "1fd426df-808a-5c68-9b6e-23ff2f128b7d",
                        "13260a8b-4ec0-5e4d-84fc-1df990527937"
                    ]
                },
                "D-49.1a": {
                    "questionId": "D-49.1a",
                    "chosenOptionIds": [
                        "7108af97-c956-5da8-8dfa-518e346c197c"
                    ]
                },
                "D-49.2a": {
                    "questionId": "D-49.2a",
                    "freeValue": 800
                },
                "D-49a": {
                    "questionId": "D-49a",
                    "chosenOptionIds": [
                        "16dc6af1-32a9-59c5-b66c-57f6becea1e0"
                    ]
                },
                "D-4a": {
                    "questionId": "D-4a",
                    "chosenOptionIds": [
                        "e5ae393a-9aad-50b4-844e-6f42cdf16c5b"
                    ]
                },
                "D-4bs": {
                    "questionId": "D-4bs",
                    "freeValue": 1095
                },
                "D-4bt": {
                    "questionId": "D-4bt",
                    "freeValue": 2920
                },
                "D-50a": {
                    "questionId": "D-50a",
                    "chosenOptionIds": [
                        "d91f7277-0497-57d2-93a3-ea638d14162c"
                    ]
                },
                "D-5a": {
                    "questionId": "D-5a",
                    "chosenOptionIds": [
                        "dde1af45-4686-52c9-b960-369527b28f6c"
                    ]
                },
                "D-6a": {
                    "questionId": "D-6a",
                    "chosenOptionIds": [
                        "81e9f63b-35b9-5032-8ea0-462275a87cce"
                    ]
                },
                "D-7a": {
                    "questionId": "D-7a",
                    "chosenOptionIds": [
                        "5d271ac0-beca-56cc-9d0b-adafcd67da53"
                    ]
                },
                "D-8a": {
                    "questionId": "D-8a",
                    "chosenOptionIds": [
                        "33a5fff0-9587-521f-bc53-0dacc36a0bb2"
                    ]
                },
                "D-9a": {
                    "questionId": "D-9a",
                    "chosenOptionIds": [
                        "13f8bac1-d0f5-539b-8596-1c55821bea46"
                    ]
                },
                "D-s10bt": {
                    "questionId": "D-s10bt",
                    "chosenOptionIds": [
                        "da993bd6-c107-53a4-9f6d-09f5a79aff65"
                    ]
                },
                "D-s11bt": {
                    "questionId": "D-s11bt",
                    "chosenOptionIds": [
                        "daf8b3c7-fb19-5a3f-ba9a-784adda7414f",
                        "dc476220-9479-5def-8a97-20e6152d44af",
                        "9c3fa6af-8b7b-5993-a587-641683d934d5",
                        "6994b88e-cfa5-5986-b9e8-625d17d1147c",
                        "16e1a0b8-28c5-5bcd-931d-335c858c3f88",
                        "bae5acc8-8664-54a2-ba97-c36244be4c44",
                        "64af3cae-713a-51e1-99c1-09e2fdaa755d",
                        "7921bccf-4756-59fe-b678-d536c2ab5945",
                        "049f46ae-b7bd-5a1e-8c63-351f481236ec"
                    ]
                },
                "D-s1bt": {
                    "questionId": "D-s1bt",
                    "chosenOptionIds": [
                        "c17482e4-818a-50cd-90fa-1246d037825e"
                    ]
                },
                "D-s2bt": {
                    "questionId": "D-s2bt",
                    "chosenOptionIds": [
                        "b75d06ef-eb6c-5e23-bf8c-9b566fa4d9dd"
                    ]
                },
                "D-s3bt": {
                    "questionId": "D-s3bt",
                    "chosenOptionIds": [
                        "d065c0ca-9e6d-5300-8e3e-688b16f88c59"
                    ]
                },
                "D-s4bt": {
                    "questionId": "D-s4bt",
                    "chosenOptionIds": [
                        "5a198500-fc4b-55a1-9521-3abe49eed06f",
                        "fd9851a0-7ce7-55a8-b257-5617537d0013",
                        "a4021497-af94-56a4-8075-392653262e2e"
                    ]
                },
                "D-s5bt": {
                    "questionId": "D-s5bt",
                    "chosenOptionIds": [
                        "8260d432-3a96-519e-b824-d24940e4aa65"
                    ]
                },
                "D-s6bt": {
                    "questionId": "D-s6bt",
                    "chosenOptionIds": [
                        "33cda0a0-a5db-5a6c-8603-b5b899edac54"
                    ]
                },
                "D-s7bt": {
                    "questionId": "D-s7bt",
                    "chosenOptionIds": [
                        "1df67815-0b9f-56ef-bd48-96ed977db141"
                    ]
                },
                "D-s8bt": {
                    "questionId": "D-s8bt",
                    "chosenOptionIds": [
                        "6f5067cf-ef4d-59d9-afa1-b3dfc4ac861f"
                    ]
                },
                "D-s9bt": {
                    "questionId": "D-s9bt",
                    "chosenOptionIds": [
                        "da503bbd-e567-576e-a21f-e0fad60dccdd"
                    ]
                },
                "Dem11": {
                    "questionId": "Dem11",
                    "chosenOptionIds": [
                        "44fd9eb3-aee2-5af0-b83c-dbbbf9514022"
                    ]
                },
                "BP1": {
                    "questionId": "BP1",
                    "chosenOptionIds": [
                        "7053b295-aee4-5fb6-be13-c442378a9971"
                    ]
                },
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "6215a3de-a3e4-5f86-a039-1006646e6b93"
                    ]
                },
                "BP106": {
                    "questionId": "BP106",
                    "chosenOptionIds": [
                        "8c35da7e-8c0b-50bb-a037-b80a9f1df3d2"
                    ]
                },
                "BP108": {
                    "questionId": "BP108",
                    "chosenOptionIds": [
                        "6c88c07e-4483-5b5b-b6ad-44cff962863e"
                    ]
                },
                "BP110": {
                    "questionId": "BP110",
                    "freeValue": 4745
                },
                "BP110.01": {
                    "questionId": "BP110.01",
                    "chosenOptionIds": [
                        "7c60a3ff-2742-5128-b0a0-3157bfe8c0f5"
                    ]
                },
                "BP110.1": {
                    "questionId": "BP110.1",
                    "chosenOptionIds": [
                        "9ce31a68-0e9d-55e2-a573-174bcbeb7458"
                    ]
                },
                "BP110.2": {
                    "questionId": "BP110.2",
                    "chosenOptionIds": [
                        "dbc05d00-3f66-51fd-825b-712e8609aa9f"
                    ]
                },
                "BP111": {
                    "questionId": "BP111",
                    "chosenOptionIds": [
                        "02b20612-0b18-5aba-91d5-3ebd2589fb3d"
                    ]
                },
                "BP111.1": {
                    "questionId": "BP111.1",
                    "chosenOptionIds": [
                        "c532b673-2762-5ed0-8cbb-6f70d9977c20"
                    ]
                },
                "BP111.2": {
                    "questionId": "BP111.2",
                    "chosenOptionIds": [
                        "b218cc67-f452-588d-b809-7cd97ee83e20"
                    ]
                },
                "BP112": {
                    "questionId": "BP112",
                    "chosenOptionIds": [
                        "71867305-9c3a-564c-a0c7-e187b27e8d79"
                    ]
                },
                "BP112.1": {
                    "questionId": "BP112.1",
                    "freeValue": 676
                },
                "BP112.11": {
                    "questionId": "BP112.11",
                    "chosenOptionIds": [
                        "158f7b58-6651-5c6e-8a4b-41995ae3a765"
                    ]
                },
                "BP112.12": {
                    "questionId": "BP112.12",
                    "chosenOptionIds": [
                        "add1ea84-0327-5281-9cf4-5ddbd95b2a8f"
                    ]
                },
                "BP112.13": {
                    "questionId": "BP112.13",
                    "freeValue": 4745
                },
                "BP112.14": {
                    "questionId": "BP112.14",
                    "chosenOptionIds": [
                        "6ead4f84-b429-5a17-85d6-808bd332aa25"
                    ]
                },
                "BP112.3": {
                    "questionId": "BP112.3",
                    "chosenOptionIds": [
                        "5de2cc88-13a4-5f04-a3d2-1333852193f9"
                    ]
                },
                "BP113": {
                    "questionId": "BP113",
                    "chosenOptionIds": [
                        "b16103eb-2ecf-595e-80e4-30baf575e01b"
                    ]
                },
                "BP114": {
                    "questionId": "BP114",
                    "chosenOptionIds": [
                        "a0206e39-59ad-539c-a2ff-e9fd935cf48b"
                    ]
                },
                "BP115": {
                    "questionId": "BP115",
                    "chosenOptionIds": [
                        "e7b2a68b-de30-51bd-bb56-cda1254d3dbc"
                    ]
                },
                "BP116": {
                    "questionId": "BP116",
                    "chosenOptionIds": [
                        "bce3bb8c-216a-5167-8ec7-35c39096d501"
                    ]
                },
                "BP117": {
                    "questionId": "BP117",
                    "chosenOptionIds": [
                        "354c012f-3484-58ad-9300-d9e29a212ae3"
                    ]
                },
                "BP118": {
                    "questionId": "BP118",
                    "chosenOptionIds": [
                        "e642f54c-d6fc-57f8-a53c-73dbd698d428"
                    ]
                },
                "BP118.1": {
                    "questionId": "BP118.1",
                    "chosenOptionIds": [
                        "3299a8a7-4375-55ee-87b2-76bda766fd11"
                    ]
                },
                "BP119": {
                    "questionId": "BP119",
                    "chosenOptionIds": [
                        "64d22ab4-481d-5258-9244-0ec9d3ba77a7"
                    ]
                },
                "BP119.1": {
                    "questionId": "BP119.1",
                    "freeValue": 1095
                },
                "BP120": {
                    "questionId": "BP120",
                    "chosenOptionIds": [
                        "548c329b-3169-5742-b17d-437216e6a454"
                    ]
                },
                "BP121": {
                    "questionId": "BP121",
                    "chosenOptionIds": [
                        "2dd3fa4b-7390-5c95-b04b-c5c77974e8c2"
                    ]
                },
                "BP122": {
                    "questionId": "BP122",
                    "chosenOptionIds": [
                        "3d008b0e-8759-568c-9aae-ba21c8b6ae79"
                    ]
                },
                "BP123": {
                    "questionId": "BP123",
                    "chosenOptionIds": [
                        "972a0931-d7f1-5ad4-90af-91046fd7b193"
                    ]
                },
                "BP124": {
                    "questionId": "BP124",
                    "chosenOptionIds": [
                        "368a583d-81fc-5f77-a51c-647fd7f8f473"
                    ]
                },
                "BP125": {
                    "questionId": "BP125",
                    "chosenOptionIds": [
                        "19dd84c4-ccf5-5060-83ae-e1f2f977f1c0"
                    ]
                },
                "BP126": {
                    "questionId": "BP126",
                    "chosenOptionIds": [
                        "7bdce4bf-24ca-5470-a5cd-68c1e5c7ef03"
                    ]
                },
                "BP127": {
                    "questionId": "BP127",
                    "chosenOptionIds": [
                        "5f58bb60-dff1-585c-a5f3-26e34e4dc957"
                    ]
                },
                "BP128": {
                    "questionId": "BP128",
                    "chosenOptionIds": [
                        "f2c70c18-c55b-5447-9859-67292866bede"
                    ]
                },
                "BP129": {
                    "questionId": "BP129",
                    "chosenOptionIds": [
                        "477c3730-71ba-505f-9caa-07a83398580f"
                    ]
                },
                "BP130": {
                    "questionId": "BP130",
                    "chosenOptionIds": [
                        "7a066bdc-85a0-56cc-8632-2e83740855c1"
                    ]
                },
                "BP131": {
                    "questionId": "BP131",
                    "chosenOptionIds": [
                        "cf80f9fa-de7b-5954-b005-e9ec1b1b07a9"
                    ]
                },
                "BP132": {
                    "questionId": "BP132",
                    "chosenOptionIds": [
                        "da9000ec-69f6-580b-9ec9-756b702c2962"
                    ]
                },
                "BP133": {
                    "questionId": "BP133",
                    "chosenOptionIds": [
                        "36121dba-4b4b-584f-9f5b-4b715cf0b2af"
                    ]
                },
                "BPS": {
                    "questionId": "BPS",
                    "chosenOptionIds": [
                        "e2984767-d667-5b32-9670-f54a4f34aebf"
                    ]
                },
                "HM35": {
                    "questionId": "HM35",
                    "chosenOptionIds": [
                        "c5f21a2b-5af8-525d-bfab-0ed035defec0"
                    ]
                },
                "HM36": {
                    "questionId": "HM36",
                    "chosenOptionIds": [
                        "47bfc26b-4237-5a82-9cc4-67d348c4acb0"
                    ]
                },
                "HM37": {
                    "questionId": "HM37",
                    "chosenOptionIds": [
                        "20de534d-f482-51c5-ad87-bd054787fddc"
                    ]
                },
                "HM38": {
                    "questionId": "HM38",
                    "chosenOptionIds": [
                        "5125bbbb-70fb-5adc-a41b-c65c6b96180d"
                    ]
                },
                "HM40": {
                    "questionId": "HM40",
                    "chosenOptionIds": [
                        "596144bf-d0ea-5d17-a2ae-7096c31e2def"
                    ]
                },
                "HM41": {
                    "questionId": "HM41",
                    "chosenOptionIds": [
                        "dd9cb999-d487-51f2-8ca3-f121593feca0"
                    ]
                },
                "HM43": {
                    "questionId": "HM43",
                    "chosenOptionIds": [
                        "a8b07611-dcc0-55ef-ae78-d4ceb7ad42e3"
                    ]
                },
                "HM44": {
                    "questionId": "HM44",
                    "chosenOptionIds": [
                        "fe1e5434-ebd9-5a33-92c8-acca24a484b7"
                    ]
                },
                "HM45": {
                    "questionId": "HM45",
                    "chosenOptionIds": [
                        "28a20a2a-3318-5dba-9e59-86d943d3ac73"
                    ]
                },
                "HM46": {
                    "questionId": "HM46",
                    "chosenOptionIds": [
                        "9f868300-e1df-5567-8ebf-e2f984022e52"
                    ]
                },
                "HM47": {
                    "questionId": "HM47",
                    "chosenOptionIds": [
                        "41ec9370-ba26-5cbc-ad35-d9e578788aa4"
                    ]
                },
                "HM48": {
                    "questionId": "HM48",
                    "chosenOptionIds": [
                        "9b74dd11-97db-567e-8d3d-836001d82229"
                    ]
                },
                "HM49": {
                    "questionId": "HM49",
                    "chosenOptionIds": [
                        "7a5feb6e-c27f-54d2-9473-fc197426685c"
                    ]
                },
                "HMS": {
                    "questionId": "HMS",
                    "chosenOptionIds": [
                        "976f055b-ac14-5a89-8536-7c66d9ec95e5"
                    ]
                }
            }
        }
    ]
};
