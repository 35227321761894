import React, { useContext, useRef } from 'react'
import { Button, Heading, Page } from '@psyomics/components'
import Header from 'registration/components/Header'
import { InfoContext } from 'configure'

export const TestStripe: React.FC = () => {
    return (
        <Page style={{ backgroundColor: 'transparent' }} layout="standard" width="narrow">
            <Header template="landing" />
            <StripeCheckoutForm />
        </Page>
    )
}

const StripeCheckoutForm: React.FC = () => {
    const info = useContext(InfoContext)
    const stripeServerApiUrl = info?.stripeServerApiUrl + 'create-checkout-session'

    const formRef = useRef<HTMLFormElement>(null)
    const handleCustomButtonClick = () => {
        formRef.current?.submit()
    }

    return (
        <section>
            <Heading el="h2" size="heading2" color="mid" css={{ mt: 5 }}>
                Assessment
            </Heading>
            <Heading el="h2" size="heading2" color="mid" css={{ mb: 5 }}>
                £30.00
            </Heading>
            <form ref={formRef} action={stripeServerApiUrl} method="POST" style={{ display: 'none' }} />
            <Button
                className="text-right"
                appearance="primary"
                rounded
                size="large"
                label="Buy"
                css={{ mt: 3 }}
                onClick={handleCustomButtonClick}
            />
        </section>
    )
}

export default TestStripe
