import { isInternationalDemo } from './locale'

export const getAboutYouMessage = (isD2CUser: boolean): string => {
    if (isD2CUser) {
        return 'This will be shown on your report once you have finished the questionnaire.'
    } else if (isInternationalDemo()) {
        return 'Before you begin your assessment, we need to confirm some details.'
    } else {
        return 'Before you begin your assessment, we need to match your details with your NHS record.'
    }
}

export const getYourNameMessage = (isD2CUser: boolean): string => {
    if (isD2CUser) {
        return 'Please enter your preferred first and last name, this will be used on your report.'
    }

    if (isInternationalDemo()) {
        return 'Please enter your first and last name.'
    } else {
        return 'Please enter your first and last name as it appears on your NHS record.'
    }
}

export const getYourSexMessage = (): string => {
    if (isInternationalDemo()) {
        return 'Your answer should reflect the information that is used on your birth certificate.'
    } else {
        return 'Your answer should reflect the information that appears on your NHS record.'
    }
}
