import { Auth } from 'aws-amplify'

export const uploadFileForScan = async (file: File, filename: string, avScanApiUrl?: string): Promise<Response> => {
    const jwt = (await Auth.currentSession()).getAccessToken().getJwtToken()
    const response = await fetch(`${avScanApiUrl}/scan?fileName=${filename}`, {
        method: 'POST',
        body: file,
        headers: {
            Authorization: `Bearer ${jwt}`,
        },
    })

    if (!response.ok) throw new Error('Failed to upload file for scanning.')
    return response
}
