import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { Heading, Text, Button } from '@psyomics/components'
import Styles from './NhsLanding2.module.scss'
import { useAsync } from '../../../hooks/use-async'
import { RegistrationRoutes, RegistrationStep } from 'registration/context/registration/registration.definitions'
import { ProgressDotsRegistration } from 'registration/components/ProgressDotsRegistration'
import { ReferralDetailsContext } from 'ui/app/context/referralDetails'

const NhsLanding2: React.FC = () => {
    const { isLoading } = useAsync()
    const navigate = useNavigate()
    const referralDetails = useContext(ReferralDetailsContext)

    return (
        <div className={Styles.container}>
            <div className={Styles.intro}>
                <Heading el="h1" size="heading2" color="mid" css={{ mb: 3 }}>
                    Overview
                </Heading>
                {referralDetails?.data?.organisationName &&
                ['herts', 'midlands', 'herts-test', 'midlands-test'].includes(
                    referralDetails?.data?.organisationCode
                ) ? (
                    <>
                        <Text size="medium" css={{ mb: 5 }}>
                            Completing the assessment is optional, if you choose not to complete the assessment your
                            referral will still be processed by {referralDetails?.data?.organisationNameAbbr} in the
                            traditional manner.
                        </Text>
                        <Text size="medium" css={{ mb: 5 }}>
                            If you have already completed this assessment within the last 6 months, and there have been
                            no significant changes in your situation, there is no need to repeat it. However, if it has
                            been more than 6 months or if you believe there are updates to share, please feel free to
                            complete the assessment again.
                        </Text>
                    </>
                ) : (
                    <>
                        <Text size="medium" css={{ mb: 5 }}>
                            This assessment is designed to give your healthcare professional the information they need
                            to make the best decisions about your care. Upon completion of your question sessions, a
                            healthcare professional will review the report.
                        </Text>
                        <Text size="medium" css={{ mb: 5 }}>
                            If you have already completed this assessment within the last 6 months, and there have been
                            no significant changes in your situation, there&#39;s no need to repeat it. Your clinical
                            team retains access to your previous information. However, if it has been more than 6 months
                            or if you believe there are updates to share, please feel free to complete the assessment
                            again.
                        </Text>
                    </>
                )}
            </div>

            <ProgressDotsRegistration step={RegistrationStep.afterLanding} />
            <div className={Styles.actions}>
                <Button
                    type="button"
                    appearance="primary"
                    rounded
                    fullWidth
                    size="large"
                    label="Next"
                    icon={null}
                    disabled={!!isLoading}
                    onClick={() => {
                        navigate(RegistrationRoutes.confirmation)
                    }}
                />
            </div>
        </div>
    )
}

export default NhsLanding2
