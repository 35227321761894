"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.questionPredicates = exports.tests = exports.aspects = exports.questions = void 0;
const censeo_1 = require("../../schema/generated/censeo");
exports.questions = [
    {
        "__typename": "FreeTextQuestion",
        "id": "DemR",
        "text": "In a few sentences, please describe your reasons for seeking help regarding your mental health (e.g. feeling low, a recent event has prompted you, someone else has shown concern). You can also share any aims or goals you have to feel better.",
        "userType": censeo_1.UserType.Nhs,
        "mandatory": true
    }
];
exports.aspects = {};
exports.tests = {};
exports.questionPredicates = {};
