"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ragData = void 0;
const eupd_1 = require("./eupd");
const suicide_1 = require("./suicide");
const selfharm_1 = require("./selfharm");
const trauma_1 = require("./trauma");
exports.ragData = {
    emi_ce: {
        start: eupd_1.START_ID,
        nodePaths: eupd_1.nodePaths,
        supplementalInfoQuestionIds: eupd_1.supplementalInfoQuestionIds,
    },
    s_current: {
        start: suicide_1.START_ID,
        nodePaths: suicide_1.nodePaths,
        supplementalInfoQuestionIds: suicide_1.supplementalInfoQuestionIds,
    },
    sh_current: {
        start: selfharm_1.START_ID,
        nodePaths: selfharm_1.nodePaths,
        supplementalInfoQuestionIds: selfharm_1.supplementalInfoQuestionIds,
    },
    trm_re_ptsd: {
        start: trauma_1.START_ID,
        nodePaths: trauma_1.nodePaths,
        supplementalInfoQuestionIds: trauma_1.supplementalInfoQuestionIds,
    },
};
