import React from 'react'
import { classNameMaker } from '../../utils'
import Styles from './Label.module.scss'

interface OurProps {
    children: string | React.ReactNode
    appearance?: 'primary' | 'secondary'
    el?: 'label' | 'legend'
}

type ILabelProps = OurProps & React.ComponentPropsWithoutRef<'label'>

/**
 * Label is a....
 */
const Label: React.FC<ILabelProps> = ({ el = 'label', children, appearance = 'primary', ...props }) => {
    const className: string = classNameMaker({
        [Styles.container]: true,
        [Styles[`appearance-${appearance}`]]: appearance,
    })

    return React.createElement(el, { className: className, ...props }, children)
}

export default Label
