import React, { useContext } from 'react'
import { Page, Heading, Text } from '@psyomics/components'
import RefIcon from '../../images/ref-icon.svg?react'
import ReleaseIcon from '../../images/release-date-icon.svg?react'
import ManufacturerIcon from '../../images/manufacturer-icon.svg?react'
import UkcaIcon from '../../images/ukca-icon.svg?react'
import Styles from './ClincianIFU.module.scss'
import Header from 'ui/app/components/Header'
import { InfoContext } from '../../../../configure'

const ClinicianIFU: React.FC = () => {
    const info = useContext(InfoContext)
    const releaseDate = info?.date
        ? new Date(info.date).toLocaleDateString('en-GB', { year: 'numeric', month: 'short', day: 'numeric' })
        : 'No release date information'
    const version = info?.appVersion ? info?.appVersion : 'No version information'
    return (
        <Page width="wide" header={<Header patientFacing={false}></Header>}>
            <Heading el="h1" size="heading1" color="mid" css={{ mt: 5, mb: 5 }}>
                Product information
            </Heading>
            <Heading el="h2" size="heading2" color="mid" css={{ mt: 6, mb: 5 }}>
                What is Censeo?
            </Heading>

            <Text css={{ mt: 4, mb: 3 }}>
                The ‘Censeo Digital’ medical device is software intended for use as a clinical decision support tool for
                mental health concerns in patients/service users who have been referred to Censeo by a healthcare
                service. This software is intended to be used by patients/service users aged 18-65 anywhere in which
                they have internet access with an internet enabled device.
            </Text>

            <Text css={{ mt: 4, mb: 3 }}>
                This medical device is inappropriate for being used to make a diagnosis or as a risk assessment tool in
                place of a clinician directly assessing a patient. This medical device should not be used as a crisis
                support tool.{' '}
            </Text>
            <Text css={{ mt: 4, mb: 3 }}>
                This medical device software uses service users&apos; answers to produce a mental health report. The
                report informs clinicians in making triage, assessment or treatment decisions related to mental health,
                by providing a consistent and comprehensive set of data to assist their decision-making.
            </Text>
            <Heading el="h2" size="heading2" color="mid" css={{ mt: 6, mb: 5 }}>
                Intended Purpose:
            </Heading>
            <Text css={{ mt: 4, mb: 3 }}>
                Censeo is a cloud-based precision mental health platform clinical decision support tool that collects
                data from service users and helps inform clinicians in making triage, assessment or treatment decisions
                related to mental health. It is specifically designed for use with routine only referrals (e.g., NHS
                28-day referral to treatment), and is not intended for urgent or emergency cases.
            </Text>
            <Text css={{ mt: 4, mb: 3 }}>
                Service users input their data through a web-based questionnaire, which is then structured and
                summarised into a report available only to trained professionals.
            </Text>
            <Heading el="h2" size="heading2" color="mid" css={{ mt: 6, mb: 5 }}>
                Intended Users:
            </Heading>
            <Text css={{ mt: 4, mb: 3 }}>
                The Censeo questionnaire is designed for users within the age range of 18 to 65. They should be within
                the eligibility criteria, and be capable of undertaking an online assessment.{' '}
            </Text>
            <Text css={{ mt: 4, mb: 3 }}>
                The Censeo report is designed to be used by two categories of healthcare professionals:
            </Text>
            <Text css={{ mt: 4, mb: 3 }}>
                <ol className={Styles.orderedList}>
                    <li>
                        Mental Health Professionals: This includes, but is not limited to, psychiatrists, clinical
                        psychologists, mental health nurses, mental health social workers, and occupational therapists
                        specialising in mental health.{' '}
                    </li>
                    <li>
                        Primary Care Professionals: General practitioners (GPs) and other primary care providers who are
                        involved in the initial assessment, triage, and referral of patients with potential mental
                        health conditions.
                    </li>
                </ol>
            </Text>
            <Text css={{ mt: 4, mb: 3 }}>Both categories of professionals must be: </Text>
            <Text css={{ mt: 4, mb: 3 }}>
                <ol className={Styles.orderedList}>
                    <li>
                        Trained and competent to make autonomous clinical decisions about a patient&apos;s mental
                        health, including triage, referral, and treatment planning.
                    </li>
                    <li>
                        Experienced in interpreting mental health assessment reports that reference DSM-5- TR and ICD-11
                        diagnosable mental health-related conditions.
                    </li>
                    <li>
                        Able to integrate the assessment report&apos;s triage prioritisation categories for suicidality
                        risk, self-harm risk, level of distress due to trauma symptoms, and tendency to engage in risky
                        impulsive behaviour into their clinical decision-making.
                    </li>
                    <li>
                        Capable of considering relevant social factors like the patient&apos;s housing situation,
                        relationship status, and employment status when determining appropriate next steps.
                    </li>
                    <li>
                        Responsible for making the final professional judgement on triage, referral and treatment
                        recommendations for the patient, using the Censeo assessment report as one component of a
                        comprehensive evaluation.
                    </li>
                </ol>
            </Text>
            <Text css={{ mt: 4, mb: 3 }}>
                Secondary users include administrative staff, information governance or research staff that may need to
                access a report for non-direct care purposes; for example, to facilitate a subject access request. All
                users must always comply with all relevant information management and security procedures.
            </Text>
            <Heading el="h2" size="heading2" color="mid" css={{ mt: 6, mb: 5 }}>
                How it Works:
            </Heading>
            <Text css={{ mt: 4, mb: 3 }}>
                <ol className={Styles.orderedList}>
                    <li>
                        <b>Service User Interaction:</b> Service users access Censeo on any internet-enabled device
                        (computer, smartphone, tablet). Users are encouraged to provide honest and accurate responses to
                        ensure the most appropriate clinical support.
                    </li>
                    <li>
                        <b>Adaptive Questions:</b> Service users answer questions about their mental health and related
                        factors.
                    </li>
                    <li>
                        <b> Report Generation:</b> Censeo analyses the responses and produces a comprehensive mental
                        health report.
                    </li>
                    <li>
                        <b>Crisis Support:</b> Throughout the assessment, crisis support information and contact details
                        are prominently displayed for immediate access if needed.
                    </li>
                    <li>
                        <b>Review Process:</b> Only fully completed assessments are sent to and reviewed by clinicians.
                        Partial or incomplete assessments are not transmitted or evaluated.
                    </li>
                </ol>
            </Text>
            <Heading el="h2" size="heading2" color="mid" css={{ mt: 6, mb: 5 }}>
                Mental Health Report Contents:
            </Heading>
            <Text css={{ mt: 4, mb: 3 }}>
                <ol className={Styles.orderedList}>
                    <li>
                        <b>Condition Outcomes:</b> Likelihood ratings of potential mental health conditions and
                        associated symptoms. Triage Priority: Ratings based on the UK Mental Health Triage scoring
                        framework, suggesting the urgency for clinical review. These are not definitive risk assessments
                        but indicators to support clinical decision-making.
                    </li>
                    <li>
                        <b>Patient Context: </b> Information about the service user&apos;s living situation and other
                        relevant factors.
                    </li>
                    <li>
                        <b>Report Access:</b> While reports are primarily for clinical use, patients can request access
                        to their report information through their healthcare provider following standard medical record
                        access procedures.
                    </li>
                </ol>
            </Text>
            <Heading el="h2" size="heading2" color="mid" css={{ mt: 6, mb: 5 }}>
                Important Limitations:
            </Heading>
            <Text css={{ mt: 4, mb: 3 }}>
                Censeo is <b>NOT</b> intended for:
            </Text>
            <Text css={{ mt: 4, mb: 3 }}>
                <ol className={Styles.orderedList}>
                    <li>
                        <b>Diagnosis:</b> The report alone cannot determine the presence or absence of a mental health
                        disorder.
                    </li>
                    <li>
                        <b>Risk Assessment: </b> Censeo does not assess the risk of self-harm or harm to others.
                    </li>
                    <li>
                        <b>Crisis Support: </b> Censeo is not a substitute for immediate support during a mental health
                        crisis.
                    </li>
                </ol>
            </Text>
            <Text css={{ mt: 4, mb: 3 }}>
                <i>
                    Important: Censeo is designed as a supportive tool to enhance, not replace, existing clinical
                    processes. The Censeo report should always be used in conjunction with a clinician&apos;s
                    professional judgement, other relevant clinical data, and established clinical practices. It is
                    intended to complement, not substitute, comprehensive clinical assessment and decision-making.
                </i>
            </Text>
            <Heading el="h2" size="heading2" color="mid" css={{ mt: 6, mb: 5 }}>
                Contact:
            </Heading>
            <Text css={{ mt: 4, mb: 3 }}>
                <i>
                    Please contact us at <a href={'mailto:vigilance@psyomics.com'}>vigilance@psyomics.com</a> if you
                    have discovered a potential issue with Censeo. In case a serious incident has occurred in relation
                    to the product, please also report it to the Medicines and Healthcare products Regulatory Agency
                    (MHRA) through the Yellow Card Reporting Scheme.
                </i>
            </Text>
            <Heading el="h2" size="heading2" color="mid" css={{ mt: 6, mb: 5 }}>
                Software information
            </Heading>
            <Text>App name:</Text>
            <Text weight="demibold" css={{ mb: 4 }}>
                Censeo Digital
            </Text>
            <div className={Styles.iconRow}>
                <RefIcon />
                <div>
                    <Text>App version</Text>
                    <Text weight="demibold" el="p">
                        {version}
                    </Text>
                </div>
            </div>
            <div className={Styles.iconRow}>
                <ReleaseIcon />
                <div>
                    <Text>Date of release</Text>
                    <Text weight="demibold" el="p">
                        {releaseDate}
                    </Text>
                </div>
            </div>
            <div className={Styles.iconRow}>
                <ManufacturerIcon />
                <div>
                    <Text>Manufacturer or maintainer</Text>
                    <Text el="p" weight="demibold">
                        Psyomics Ltd.
                        <br />
                        St. John&#39;s Innovation Centre,
                        <br />
                        Cowley Road,
                        <br />
                        Cambridge,
                        <br />
                        CB4 0WS
                        <br />
                    </Text>
                </div>
            </div>
            <UkcaIcon />
        </Page>
    )
}

export default ClinicianIFU
