import * as Sentry from '@sentry/react'

export const handleError = (
    error: unknown,
    defaultMessage: string,
    extra: { practitionerId: string; fileTimestamp: string }
): string => {
    Sentry.captureException(error, {
        extra: {
            ...extra,
            errorMessage: defaultMessage,
        },
    })
    return typeof error === 'string' ? error : defaultMessage
}
