"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.symptoms = void 0;
exports.symptoms = [
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} delusions",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "P-s1",
                    "optionSummaries": {
                        "185cf99e-324a-580e-8ca4-ee1fedf8eaa5": "experienced"
                    }
                }
            ]
        },
        "section": [
            {
                "conditionalTitle": {
                    "questionId": "P-19",
                    "possibleTexts": [
                        {
                            "text": "Recent symptoms (in the last year)",
                            "optionIds": [
                                "884145d1-bb81-5bd8-83d7-0f7e50f5ac1a",
                                "d769295c-c6b4-5794-9bf4-edc732d602c8",
                                "b6b7d9e6-0fb5-53d1-8bc0-c59ab9e5a767",
                                "59621fe0-d7f7-5376-8349-d2e9aeef0119"
                            ]
                        },
                        {
                            "text": "Previous symptoms (not in the last year)",
                            "optionIds": [
                                "5ea8a949-f8ab-50ee-b925-eab3db9d05a0",
                                "6a6b0064-8980-5160-8a72-8f84497df01c"
                            ]
                        }
                    ]
                },
                "altTitle": "Symptoms"
            },
            "Delusions"
        ]
    },
    {
        "answerSummary": {
            "type": "CombinedAnswersSummary",
            "questionsIdGroups": [
                [
                    "P1_powers"
                ],
                [
                    "P1_famous"
                ]
            ],
            "groupsType": "Mean",
            "pattern": "{} delusions of grandeur",
            "coefficients": {
                "0.5": "experienced"
            }
        },
        "section": [
            {
                "conditionalTitle": {
                    "questionId": "P-19",
                    "possibleTexts": [
                        {
                            "text": "Recent symptoms (in the last year)",
                            "optionIds": [
                                "884145d1-bb81-5bd8-83d7-0f7e50f5ac1a",
                                "d769295c-c6b4-5794-9bf4-edc732d602c8",
                                "b6b7d9e6-0fb5-53d1-8bc0-c59ab9e5a767",
                                "59621fe0-d7f7-5376-8349-d2e9aeef0119"
                            ]
                        },
                        {
                            "text": "Previous symptoms (not in the last year)",
                            "optionIds": [
                                "5ea8a949-f8ab-50ee-b925-eab3db9d05a0",
                                "6a6b0064-8980-5160-8a72-8f84497df01c"
                            ]
                        }
                    ]
                },
                "altTitle": "Symptoms"
            },
            "Delusions"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} paranoia",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "P-1",
                    "optionSummaries": {
                        "49a9e0be-f179-5a9b-b636-94800133eb7d": "experienced"
                    }
                }
            ]
        },
        "section": [
            {
                "conditionalTitle": {
                    "questionId": "P-19",
                    "possibleTexts": [
                        {
                            "text": "Recent symptoms (in the last year)",
                            "optionIds": [
                                "884145d1-bb81-5bd8-83d7-0f7e50f5ac1a",
                                "d769295c-c6b4-5794-9bf4-edc732d602c8",
                                "b6b7d9e6-0fb5-53d1-8bc0-c59ab9e5a767",
                                "59621fe0-d7f7-5376-8349-d2e9aeef0119"
                            ]
                        },
                        {
                            "text": "Previous symptoms (not in the last year)",
                            "optionIds": [
                                "5ea8a949-f8ab-50ee-b925-eab3db9d05a0",
                                "6a6b0064-8980-5160-8a72-8f84497df01c"
                            ]
                        }
                    ]
                },
                "altTitle": "Symptoms"
            },
            "Delusions"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} mind-control",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "P-1",
                    "optionSummaries": {
                        "a88a1596-13eb-5b68-8059-14a5457a3e80": "experienced"
                    }
                }
            ]
        },
        "section": [
            {
                "conditionalTitle": {
                    "questionId": "P-19",
                    "possibleTexts": [
                        {
                            "text": "Recent symptoms (in the last year)",
                            "optionIds": [
                                "884145d1-bb81-5bd8-83d7-0f7e50f5ac1a",
                                "d769295c-c6b4-5794-9bf4-edc732d602c8",
                                "b6b7d9e6-0fb5-53d1-8bc0-c59ab9e5a767",
                                "59621fe0-d7f7-5376-8349-d2e9aeef0119"
                            ]
                        },
                        {
                            "text": "Previous symptoms (not in the last year)",
                            "optionIds": [
                                "5ea8a949-f8ab-50ee-b925-eab3db9d05a0",
                                "6a6b0064-8980-5160-8a72-8f84497df01c"
                            ]
                        }
                    ]
                },
                "altTitle": "Symptoms"
            },
            "Delusions"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} thought broadcasting",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "P-1",
                    "optionSummaries": {
                        "ffe61661-4b61-5c35-8953-a4f5931f07eb": "experienced"
                    }
                }
            ]
        },
        "section": [
            {
                "conditionalTitle": {
                    "questionId": "P-19",
                    "possibleTexts": [
                        {
                            "text": "Recent symptoms (in the last year)",
                            "optionIds": [
                                "884145d1-bb81-5bd8-83d7-0f7e50f5ac1a",
                                "d769295c-c6b4-5794-9bf4-edc732d602c8",
                                "b6b7d9e6-0fb5-53d1-8bc0-c59ab9e5a767",
                                "59621fe0-d7f7-5376-8349-d2e9aeef0119"
                            ]
                        },
                        {
                            "text": "Previous symptoms (not in the last year)",
                            "optionIds": [
                                "5ea8a949-f8ab-50ee-b925-eab3db9d05a0",
                                "6a6b0064-8980-5160-8a72-8f84497df01c"
                            ]
                        }
                    ]
                },
                "altTitle": "Symptoms"
            },
            "Delusions"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} delusions of reference",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "P-1",
                    "optionSummaries": {
                        "91337a60-b5cd-5ad4-9da7-147a82ad9981": "experienced"
                    }
                }
            ]
        },
        "section": [
            {
                "conditionalTitle": {
                    "questionId": "P-19",
                    "possibleTexts": [
                        {
                            "text": "Recent symptoms (in the last year)",
                            "optionIds": [
                                "884145d1-bb81-5bd8-83d7-0f7e50f5ac1a",
                                "d769295c-c6b4-5794-9bf4-edc732d602c8",
                                "b6b7d9e6-0fb5-53d1-8bc0-c59ab9e5a767",
                                "59621fe0-d7f7-5376-8349-d2e9aeef0119"
                            ]
                        },
                        {
                            "text": "Previous symptoms (not in the last year)",
                            "optionIds": [
                                "5ea8a949-f8ab-50ee-b925-eab3db9d05a0",
                                "6a6b0064-8980-5160-8a72-8f84497df01c"
                            ]
                        }
                    ]
                },
                "altTitle": "Symptoms"
            },
            "Delusions"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} somatic delusions (related to the body)",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "P-1",
                    "optionSummaries": {
                        "8c7def60-8271-53a0-9d16-69792c21b125": "experienced"
                    }
                }
            ]
        },
        "section": [
            {
                "conditionalTitle": {
                    "questionId": "P-19",
                    "possibleTexts": [
                        {
                            "text": "Recent symptoms (in the last year)",
                            "optionIds": [
                                "884145d1-bb81-5bd8-83d7-0f7e50f5ac1a",
                                "d769295c-c6b4-5794-9bf4-edc732d602c8",
                                "b6b7d9e6-0fb5-53d1-8bc0-c59ab9e5a767",
                                "59621fe0-d7f7-5376-8349-d2e9aeef0119"
                            ]
                        },
                        {
                            "text": "Previous symptoms (not in the last year)",
                            "optionIds": [
                                "5ea8a949-f8ab-50ee-b925-eab3db9d05a0",
                                "6a6b0064-8980-5160-8a72-8f84497df01c"
                            ]
                        }
                    ]
                },
                "altTitle": "Symptoms"
            },
            "Delusions"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "has {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "P-10",
                    "optionSummaries": {
                        "50570c73-4998-522a-9f38-8c250f0b4870": "heard voices",
                        "1e84b880-6bc4-56ef-a39c-861146a22d2b": "heard sounds",
                        "2d131e4d-a23b-5c69-9c1f-5238481f9ff0": "heard voices and sounds"
                    }
                }
            ]
        },
        "section": [
            {
                "conditionalTitle": {
                    "questionId": "P-19",
                    "possibleTexts": [
                        {
                            "text": "Recent symptoms (in the last year)",
                            "optionIds": [
                                "884145d1-bb81-5bd8-83d7-0f7e50f5ac1a",
                                "d769295c-c6b4-5794-9bf4-edc732d602c8",
                                "b6b7d9e6-0fb5-53d1-8bc0-c59ab9e5a767",
                                "59621fe0-d7f7-5376-8349-d2e9aeef0119"
                            ]
                        },
                        {
                            "text": "Previous symptoms (not in the last year)",
                            "optionIds": [
                                "5ea8a949-f8ab-50ee-b925-eab3db9d05a0",
                                "6a6b0064-8980-5160-8a72-8f84497df01c"
                            ]
                        }
                    ]
                },
                "altTitle": "Symptoms"
            },
            "Auditory hallucinations"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} hear these voices or sounds",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "P-11",
                    "optionSummaries": {
                        "da4433ed-e848-550b-bdd9-39388bcde9df": "others could not"
                    }
                }
            ]
        },
        "section": [
            {
                "conditionalTitle": {
                    "questionId": "P-19",
                    "possibleTexts": [
                        {
                            "text": "Recent symptoms (in the last year)",
                            "optionIds": [
                                "884145d1-bb81-5bd8-83d7-0f7e50f5ac1a",
                                "d769295c-c6b4-5794-9bf4-edc732d602c8",
                                "b6b7d9e6-0fb5-53d1-8bc0-c59ab9e5a767",
                                "59621fe0-d7f7-5376-8349-d2e9aeef0119"
                            ]
                        },
                        {
                            "text": "Previous symptoms (not in the last year)",
                            "optionIds": [
                                "5ea8a949-f8ab-50ee-b925-eab3db9d05a0",
                                "6a6b0064-8980-5160-8a72-8f84497df01c"
                            ]
                        }
                    ]
                },
                "altTitle": "Symptoms"
            },
            "Auditory hallucinations"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{}emotional distress",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "P-12",
                    "optionSummaries": {
                        "21113b65-0b4d-5940-b8d9-83cfb0a3868e": "caused slight ",
                        "01c44680-7676-5bca-829e-d9cc7f45d38b": "caused moderate ",
                        "852e4d61-d1aa-5572-8b01-9d18239300dc": "very much caused ",
                        "b4162925-93a1-5d13-a982-01df565c634e": "caused extreme "
                    }
                }
            ]
        },
        "section": [
            {
                "conditionalTitle": {
                    "questionId": "P-19",
                    "possibleTexts": [
                        {
                            "text": "Recent symptoms (in the last year)",
                            "optionIds": [
                                "884145d1-bb81-5bd8-83d7-0f7e50f5ac1a",
                                "d769295c-c6b4-5794-9bf4-edc732d602c8",
                                "b6b7d9e6-0fb5-53d1-8bc0-c59ab9e5a767",
                                "59621fe0-d7f7-5376-8349-d2e9aeef0119"
                            ]
                        },
                        {
                            "text": "Previous symptoms (not in the last year)",
                            "optionIds": [
                                "5ea8a949-f8ab-50ee-b925-eab3db9d05a0",
                                "6a6b0064-8980-5160-8a72-8f84497df01c"
                            ]
                        }
                    ]
                },
                "altTitle": "Symptoms"
            },
            "Auditory hallucinations"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} interfered with concentration",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "P-13",
                    "optionSummaries": {
                        "03dea62b-8d69-5468-8f3f-ac2f048d439d": "slightly",
                        "24f64a32-4946-58dd-9650-198f1f101558": "moderately",
                        "a7e245dc-06d7-510d-ab65-047a2d519068": "very much",
                        "7c6020eb-9e56-596b-9a1c-164840917df1": "severely"
                    }
                }
            ]
        },
        "section": [
            {
                "conditionalTitle": {
                    "questionId": "P-19",
                    "possibleTexts": [
                        {
                            "text": "Recent symptoms (in the last year)",
                            "optionIds": [
                                "884145d1-bb81-5bd8-83d7-0f7e50f5ac1a",
                                "d769295c-c6b4-5794-9bf4-edc732d602c8",
                                "b6b7d9e6-0fb5-53d1-8bc0-c59ab9e5a767",
                                "59621fe0-d7f7-5376-8349-d2e9aeef0119"
                            ]
                        },
                        {
                            "text": "Previous symptoms (not in the last year)",
                            "optionIds": [
                                "5ea8a949-f8ab-50ee-b925-eab3db9d05a0",
                                "6a6b0064-8980-5160-8a72-8f84497df01c"
                            ]
                        }
                    ]
                },
                "altTitle": "Symptoms"
            },
            "Auditory hallucinations"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} visual hallucinations",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "P-14",
                    "optionSummaries": {
                        "ddf3bc5f-45a4-59e2-af71-ec9206686f5e": "experienced"
                    }
                }
            ]
        },
        "section": [
            {
                "conditionalTitle": {
                    "questionId": "P-19",
                    "possibleTexts": [
                        {
                            "text": "Recent symptoms (in the last year)",
                            "optionIds": [
                                "884145d1-bb81-5bd8-83d7-0f7e50f5ac1a",
                                "d769295c-c6b4-5794-9bf4-edc732d602c8",
                                "b6b7d9e6-0fb5-53d1-8bc0-c59ab9e5a767",
                                "59621fe0-d7f7-5376-8349-d2e9aeef0119"
                            ]
                        },
                        {
                            "text": "Previous symptoms (not in the last year)",
                            "optionIds": [
                                "5ea8a949-f8ab-50ee-b925-eab3db9d05a0",
                                "6a6b0064-8980-5160-8a72-8f84497df01c"
                            ]
                        }
                    ]
                },
                "altTitle": "Symptoms"
            },
            "Visual hallucinations"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{}emotional distress",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "P-15",
                    "optionSummaries": {
                        "7caf039d-925a-52dc-afb2-8cc42e72e537": "caused slight ",
                        "077fe7f1-c94c-5422-8dde-3ba3a5cfa1cc": "caused moderate ",
                        "bb2712bc-d3a6-5b66-a7f1-e3f823742776": "very much caused ",
                        "27dd9cd9-f58f-5881-8028-4e1fd8cc8b8e": "caused extreme "
                    }
                }
            ]
        },
        "section": [
            {
                "conditionalTitle": {
                    "questionId": "P-19",
                    "possibleTexts": [
                        {
                            "text": "Recent symptoms (in the last year)",
                            "optionIds": [
                                "884145d1-bb81-5bd8-83d7-0f7e50f5ac1a",
                                "d769295c-c6b4-5794-9bf4-edc732d602c8",
                                "b6b7d9e6-0fb5-53d1-8bc0-c59ab9e5a767",
                                "59621fe0-d7f7-5376-8349-d2e9aeef0119"
                            ]
                        },
                        {
                            "text": "Previous symptoms (not in the last year)",
                            "optionIds": [
                                "5ea8a949-f8ab-50ee-b925-eab3db9d05a0",
                                "6a6b0064-8980-5160-8a72-8f84497df01c"
                            ]
                        }
                    ]
                },
                "altTitle": "Symptoms"
            },
            "Visual hallucinations"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} gustatory and olfactory hallucinations",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "P-16",
                    "optionSummaries": {
                        "cc6abf84-fdcb-5fd0-9073-ffd1d19fd3e8": "experienced"
                    }
                }
            ]
        },
        "section": [
            {
                "conditionalTitle": {
                    "questionId": "P-19",
                    "possibleTexts": [
                        {
                            "text": "Recent symptoms (in the last year)",
                            "optionIds": [
                                "884145d1-bb81-5bd8-83d7-0f7e50f5ac1a",
                                "d769295c-c6b4-5794-9bf4-edc732d602c8",
                                "b6b7d9e6-0fb5-53d1-8bc0-c59ab9e5a767",
                                "59621fe0-d7f7-5376-8349-d2e9aeef0119"
                            ]
                        },
                        {
                            "text": "Previous symptoms (not in the last year)",
                            "optionIds": [
                                "5ea8a949-f8ab-50ee-b925-eab3db9d05a0",
                                "6a6b0064-8980-5160-8a72-8f84497df01c"
                            ]
                        }
                    ]
                },
                "altTitle": "Symptoms"
            },
            "Gustatory and olfactory hallucinations"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} the delusions or hallucinations were true",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "P-8",
                    "optionSummaries": {
                        "fb76bfd1-76cd-5888-838b-49749d113e99": "not sure if",
                        "68f70517-4c97-5340-93dd-b79eebf6d594": "somewhat strongly believed",
                        "8797dc78-1465-551b-adf1-9a9b616b2818": "strongly believed"
                    }
                }
            ]
        },
        "section": [
            {
                "conditionalTitle": {
                    "questionId": "P-19",
                    "possibleTexts": [
                        {
                            "text": "Recent symptoms (in the last year)",
                            "optionIds": [
                                "884145d1-bb81-5bd8-83d7-0f7e50f5ac1a",
                                "d769295c-c6b4-5794-9bf4-edc732d602c8",
                                "b6b7d9e6-0fb5-53d1-8bc0-c59ab9e5a767",
                                "59621fe0-d7f7-5376-8349-d2e9aeef0119"
                            ]
                        },
                        {
                            "text": "Previous symptoms (not in the last year)",
                            "optionIds": [
                                "5ea8a949-f8ab-50ee-b925-eab3db9d05a0",
                                "6a6b0064-8980-5160-8a72-8f84497df01c"
                            ]
                        }
                    ]
                },
                "altTitle": "Symptoms"
            },
            "Other details"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "P-8.5",
                    "optionSummaries": {
                        "c1521e66-a422-5675-afca-b3a23a7ac8dc": "patient belongs to a group which shares these delusions/hallucinations",
                        "f0c19643-aa63-5752-9797-fe7f87bd4d42": "patient is unsure if they belong to a group which shares these delusions/hallucinations"
                    }
                }
            ]
        },
        "section": [
            {
                "conditionalTitle": {
                    "questionId": "P-19",
                    "possibleTexts": [
                        {
                            "text": "Recent symptoms (in the last year)",
                            "optionIds": [
                                "884145d1-bb81-5bd8-83d7-0f7e50f5ac1a",
                                "d769295c-c6b4-5794-9bf4-edc732d602c8",
                                "b6b7d9e6-0fb5-53d1-8bc0-c59ab9e5a767",
                                "59621fe0-d7f7-5376-8349-d2e9aeef0119"
                            ]
                        },
                        {
                            "text": "Previous symptoms (not in the last year)",
                            "optionIds": [
                                "5ea8a949-f8ab-50ee-b925-eab3db9d05a0",
                                "6a6b0064-8980-5160-8a72-8f84497df01c"
                            ]
                        }
                    ]
                },
                "altTitle": "Symptoms"
            },
            "Other details"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} hallucinations or delusions experienced",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "P-20",
                    "optionSummaries": {
                        "4d7f84b8-e916-54f9-ad2e-84726c1112d2": "primarily feeling very low when",
                        "1cf436b7-34b9-562b-966f-8f33c82a0930": "primarily feeling very up or hyper when",
                        "8edcffd0-696e-5c38-a606-16d46b768cb9": "overall mood does not relate to",
                        "21b7e519-0939-5691-bee9-265027ada775": "not sure if overall mood relates to"
                    }
                }
            ]
        },
        "section": [
            {
                "conditionalTitle": {
                    "questionId": "P-19",
                    "possibleTexts": [
                        {
                            "text": "Recent symptoms (in the last year)",
                            "optionIds": [
                                "884145d1-bb81-5bd8-83d7-0f7e50f5ac1a",
                                "d769295c-c6b4-5794-9bf4-edc732d602c8",
                                "b6b7d9e6-0fb5-53d1-8bc0-c59ab9e5a767",
                                "59621fe0-d7f7-5376-8349-d2e9aeef0119"
                            ]
                        },
                        {
                            "text": "Previous symptoms (not in the last year)",
                            "optionIds": [
                                "5ea8a949-f8ab-50ee-b925-eab3db9d05a0",
                                "6a6b0064-8980-5160-8a72-8f84497df01c"
                            ]
                        }
                    ]
                },
                "altTitle": "Symptoms"
            },
            "Other details"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "P-21",
                    "optionSummaries": {
                        "ee604d74-bf61-53ed-921e-25bc321ebb69": "hallucinations or delusions experienced only in the context of taking certain substances",
                        "457f40b9-1409-50a8-ae61-860d8e492eb3": "not sure if hallucinations or delusions experienced in the context of taking certain substances"
                    }
                }
            ]
        },
        "section": [
            {
                "conditionalTitle": {
                    "questionId": "P-19",
                    "possibleTexts": [
                        {
                            "text": "Recent symptoms (in the last year)",
                            "optionIds": [
                                "884145d1-bb81-5bd8-83d7-0f7e50f5ac1a",
                                "d769295c-c6b4-5794-9bf4-edc732d602c8",
                                "b6b7d9e6-0fb5-53d1-8bc0-c59ab9e5a767",
                                "59621fe0-d7f7-5376-8349-d2e9aeef0119"
                            ]
                        },
                        {
                            "text": "Previous symptoms (not in the last year)",
                            "optionIds": [
                                "5ea8a949-f8ab-50ee-b925-eab3db9d05a0",
                                "6a6b0064-8980-5160-8a72-8f84497df01c"
                            ]
                        }
                    ]
                },
                "altTitle": "Symptoms"
            },
            "Other details"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "hallucinations or delusions {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "P-22",
                    "optionSummaries": {
                        "e15af503-7259-5384-a744-c2e08d529c44": "are brought on by taking prescription medication",
                        "d5f166e9-fe1e-5b24-a39d-c4ec26b7312b": "may or may not be brought on by taking prescription medication (unsure)"
                    }
                }
            ]
        },
        "section": [
            {
                "conditionalTitle": {
                    "questionId": "P-19",
                    "possibleTexts": [
                        {
                            "text": "Recent symptoms (in the last year)",
                            "optionIds": [
                                "884145d1-bb81-5bd8-83d7-0f7e50f5ac1a",
                                "d769295c-c6b4-5794-9bf4-edc732d602c8",
                                "b6b7d9e6-0fb5-53d1-8bc0-c59ab9e5a767",
                                "59621fe0-d7f7-5376-8349-d2e9aeef0119"
                            ]
                        },
                        {
                            "text": "Previous symptoms (not in the last year)",
                            "optionIds": [
                                "5ea8a949-f8ab-50ee-b925-eab3db9d05a0",
                                "6a6b0064-8980-5160-8a72-8f84497df01c"
                            ]
                        }
                    ]
                },
                "altTitle": "Symptoms"
            },
            "Other details"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Overall level of impairment: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "P-23.0",
                    "optionSummaries": {
                        "a159e629-4bd4-50bd-aa38-972050915c3b": "none",
                        "81c10138-67e0-58b8-9b02-e20473ba4c05": "mild",
                        "c4578377-d25c-50db-aa9b-876271a1ca23": "moderate",
                        "1dc197b0-71ea-5b5f-9525-04118c59921f": "significant",
                        "f711c07d-0cce-5fdb-952b-4bf5e4436de2": "severe"
                    }
                }
            ]
        },
        "section": [
            {
                "conditionalTitle": {
                    "questionId": "P-19",
                    "possibleTexts": [
                        {
                            "text": "Recent symptoms (in the last year)",
                            "optionIds": [
                                "884145d1-bb81-5bd8-83d7-0f7e50f5ac1a",
                                "d769295c-c6b4-5794-9bf4-edc732d602c8",
                                "b6b7d9e6-0fb5-53d1-8bc0-c59ab9e5a767",
                                "59621fe0-d7f7-5376-8349-d2e9aeef0119"
                            ]
                        },
                        {
                            "text": "Previous symptoms (not in the last year)",
                            "optionIds": [
                                "5ea8a949-f8ab-50ee-b925-eab3db9d05a0",
                                "6a6b0064-8980-5160-8a72-8f84497df01c"
                            ]
                        }
                    ]
                },
                "altTitle": "Symptoms"
            },
            "Functional impairment"
        ]
    },
    {
        "answerSummary": {
            "type": "MultiOptionSummary",
            "questionId": "P-23",
            "title": "Areas of impairment",
            "overrides": {
                "9d9e0e60-87d9-5d02-ab73-63bc3d19b120": "Other (not listed)",
                "f7c15310-f5de-403e-946f-88b613b696de": "None reported"
            }
        },
        "section": [
            {
                "conditionalTitle": {
                    "questionId": "P-19",
                    "possibleTexts": [
                        {
                            "text": "Recent symptoms (in the last year)",
                            "optionIds": [
                                "884145d1-bb81-5bd8-83d7-0f7e50f5ac1a",
                                "d769295c-c6b4-5794-9bf4-edc732d602c8",
                                "b6b7d9e6-0fb5-53d1-8bc0-c59ab9e5a767",
                                "59621fe0-d7f7-5376-8349-d2e9aeef0119"
                            ]
                        },
                        {
                            "text": "Previous symptoms (not in the last year)",
                            "optionIds": [
                                "5ea8a949-f8ab-50ee-b925-eab3db9d05a0",
                                "6a6b0064-8980-5160-8a72-8f84497df01c"
                            ]
                        }
                    ]
                },
                "altTitle": "Symptoms"
            },
            "Functional impairment"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} avoided situations or got into trouble because of these beliefs and experiences",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "P-9",
                    "optionSummaries": {
                        "61ae818a-e9b7-5b6e-81e2-fe8ff4956c28": "rarely",
                        "47b2f4ab-6f1e-529a-8de4-dc0b4af0905e": "occasionally",
                        "4b7e41e5-7c75-5000-84c3-aa1eef851fd8": "regularly",
                        "440b6e79-aa4f-5d30-8830-93db28db0080": "very often",
                        "21fa3fb8-4df5-5e40-9d3d-466de786b439": "extremely often"
                    }
                }
            ]
        },
        "section": [
            {
                "conditionalTitle": {
                    "questionId": "P-19",
                    "possibleTexts": [
                        {
                            "text": "Recent symptoms (in the last year)",
                            "optionIds": [
                                "884145d1-bb81-5bd8-83d7-0f7e50f5ac1a",
                                "d769295c-c6b4-5794-9bf4-edc732d602c8",
                                "b6b7d9e6-0fb5-53d1-8bc0-c59ab9e5a767",
                                "59621fe0-d7f7-5376-8349-d2e9aeef0119"
                            ]
                        },
                        {
                            "text": "Previous symptoms (not in the last year)",
                            "optionIds": [
                                "5ea8a949-f8ab-50ee-b925-eab3db9d05a0",
                                "6a6b0064-8980-5160-8a72-8f84497df01c"
                            ]
                        }
                    ]
                },
                "altTitle": "Symptoms"
            },
            "Functional impairment"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "P-17",
                    "optionSummaries": {
                        "fe1965b1-472d-512e-a4df-f11c5f97a9f7": "currently experiencing delusions or hallucinations",
                        "e6d49d14-493d-577d-ae4d-d01a9ed811dd": "delusions or hallucinations started in the last week",
                        "1c9ccc9d-580c-5254-b388-c6963f9c4f38": "delusions or hallucinations started in the last month",
                        "f377b4ff-5afe-5f67-8cc1-49b006063262": "delusions or hallucinations started in the last year",
                        "0afed356-d53d-59a5-83b6-a6d3f6667129": "delusions or hallucinations started several years ago",
                        "abbfc8c8-8fea-5212-90ec-6d96fa522603": "delusions or hallucinations started decades ago"
                    }
                }
            ]
        },
        "section": [
            "Duration and onset"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "P-18"
            ],
            "prefix": "delusions or hallucinations lasted for ",
            "formatText": "{}"
        },
        "section": [
            "Duration and onset"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "last experienced delusions or hallucinations {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "P-19",
                    "optionSummaries": {
                        "884145d1-bb81-5bd8-83d7-0f7e50f5ac1a": "in the last couple of weeks",
                        "d769295c-c6b4-5794-9bf4-edc732d602c8": "a month ago",
                        "b6b7d9e6-0fb5-53d1-8bc0-c59ab9e5a767": "several months ago",
                        "59621fe0-d7f7-5376-8349-d2e9aeef0119": "in the last year",
                        "5ea8a949-f8ab-50ee-b925-eab3db9d05a0": "several years ago",
                        "6a6b0064-8980-5160-8a72-8f84497df01c": "decades ago"
                    }
                }
            ]
        },
        "section": [
            "Duration and onset"
        ]
    }
];
