import React from 'react'

interface IInitialState<T> {
    status?: StatusType
    error?: Error | null
    data?: T | null
}

interface IUseAsync<T> {
    isIdle: boolean
    isLoading: boolean
    isError: boolean
    isSuccess: boolean
    setData: (newData: T) => void
    setError: (newError: Error) => void
    error: Error | null | undefined
    status: StatusType | undefined
    data: T | null | undefined
    run: (promise: Promise<T>) => Promise<Error | T>
    reset: () => void
}

type ActionTypes<T> = { status: 'pending' } | { status: 'resolved'; data: T } | { status: 'rejected'; error: Error }

function useSafeDispatch<T>(dispatch: React.Dispatch<ActionTypes<T>>) {
    const mounted = React.useRef(false)
    React.useLayoutEffect(() => {
        mounted.current = true
        return () => {
            mounted.current = false
        }
    }, [])
    return React.useCallback((action: any) => (mounted.current ? dispatch(action) : undefined), [dispatch])
}

type StatusType = 'idle' | 'pending' | 'rejected' | 'resolved'

const defaultInitialState = {
    status: 'idle' as StatusType,
    data: null,
    error: null,
}

export function useAsync<T>(initialState?: IInitialState<T>): IUseAsync<T> {
    const initialStateRef = React.useRef({
        ...defaultInitialState,
        ...initialState,
    })

    function reducer(state: IInitialState<T>, action: ActionTypes<T>): IInitialState<T> {
        return {
            ...state,
            ...action,
        }
    }

    const [{ status, data, error }, setState] = React.useReducer(reducer, initialStateRef.current)

    const safeSetState = useSafeDispatch(setState)

    const setData = React.useCallback(
        (newData: T) => safeSetState({ status: 'resolved', data: newData }),
        [safeSetState]
    )
    const setError = React.useCallback(
        (newError: Error) => safeSetState({ status: 'rejected', error: newError }),
        [safeSetState]
    )
    const reset = React.useCallback(() => safeSetState(initialStateRef.current), [safeSetState])

    // this is from kent c dodds' epic-react course
    const run = React.useCallback(
        (promise: Promise<T>) => {
            if (!promise.then) {
                throw new Error(
                    "The argument passed to useAsync().run must be a promise. Maybe a function that's passed isn't returning anything?"
                )
            }
            safeSetState({ status: 'pending' })
            return promise
                .then((promiseData) => {
                    safeSetState({ status: 'resolved', data: promiseData })

                    return promiseData
                })
                .catch((promiseError) => {
                    if (promiseError instanceof Error) {
                        safeSetState({ status: 'rejected', error: promiseError })
                        return promiseError
                    }

                    if (typeof promiseError === 'object' && 'message' in promiseError) {
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                        const e = new Error(promiseError.message)
                        safeSetState({
                            status: 'rejected',
                            error: e,
                        })
                        return e
                    }

                    safeSetState({
                        status: 'rejected',
                        error: new Error(JSON.stringify(promiseError)),
                    })
                    return new Error(JSON.stringify(promiseError))
                })
        },
        [safeSetState]
    )

    return {
        // using the same names that react-query uses for convenience
        isIdle: status === 'idle',
        isLoading: status === 'pending',
        isError: status === 'rejected',
        isSuccess: status === 'resolved',

        setData,
        setError,
        error,
        status,
        data,
        run,
        reset,
    }
}
