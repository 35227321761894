import React from 'react'
import { ReferralCode, UserType } from 'censeo-core'
import { RegistrationContext } from './registration-context'
import {
    RegistrationActionKind,
    RegistrationStep,
    RegistrationRoutes,
    RegistrationState,
} from './registration.definitions'

export const nhsFlowArray: RegistrationStep[] = [
    RegistrationStep.afterLanding,
    RegistrationStep.confirmation,
    RegistrationStep.creationOptions,
    RegistrationStep.account,
]

const d2cFlowArray: RegistrationStep[] = [
    RegistrationStep.confirmation,
    RegistrationStep.creationOptions,
    RegistrationStep.account,
]

interface IUseRegistrationProps {
    state: RegistrationState | undefined
    getNextStepRoute: (step: RegistrationStep) => string
    startD2C: StartD2C
    startNhs: StartNhs
    getDotIdx: (step: RegistrationStep) => number
    clearRegistrationState: () => void
    numDots: number
}

type StartD2C = () => void

type StartNhs = (
    referralCode: ReferralCode,
    referralPublicId?: string,
    organisationCode?: string,
    organisationName?: string
) => void

export function useRegistration(): IUseRegistrationProps {
    const context = React.useContext(RegistrationContext)

    if (!context) {
        throw new Error('useRegistration must be within a RegistrationProvider')
    }

    const [state, dispatch] = context

    const startD2C = () => {
        dispatch({ type: RegistrationActionKind.StartD2C })
    }

    const startNhs = (
        referralCode: ReferralCode,
        referralPublicId?: string,
        organisationCode?: string,
        organisationName?: string
    ) => {
        dispatch({
            type: RegistrationActionKind.StartNHS,
            payload: { referralCode, referralPublicId, organisationCode, organisationName },
        })
    }

    const getNextStepRoute = (step: RegistrationStep) => {
        // todo: these functions shouldn't live in a hook - useEffect needs to happen instead
        // https://stackoverflow.com/questions/62336340/cannot-update-a-component-while-rendering-a-different-component-warning
        const next = nextStep(step)

        return next ? RegistrationRoutes[next] : 'not-found'
    }

    const clearRegistrationState = () => {
        dispatch({ type: RegistrationActionKind.EndRegistration })
    }

    const flowArray = state?.userType === UserType.D2C ? d2cFlowArray : nhsFlowArray

    function nextStep(step: RegistrationStep): RegistrationStep {
        const currentStepIdx = flowArray.indexOf(step)
        return flowArray[currentStepIdx + 1] ?? step
    }

    function getDotIdx(step: RegistrationStep) {
        return flowArray.indexOf(step) + 2 // 1-based, +1 for the landing page
    }

    const numDots = flowArray.length + 1 // take into account the landing page

    return { state, getNextStepRoute, startD2C, startNhs, getDotIdx, clearRegistrationState, numDots }
}
