"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isQuestionNode = exports.evaluateRag = exports.evaluateFreeQuestionRule = void 0;
const index_1 = require("./conditions/index");
const types_1 = require("./types");
const evaluateFreeQuestionRule = (rule, comparisonValue, answer) => {
    switch (rule) {
        case types_1.FreeQuestionRules.greaterThan:
            return answer > comparisonValue;
        case types_1.FreeQuestionRules.greaterThanOrEqual:
            return answer >= comparisonValue;
        case types_1.FreeQuestionRules.lessThan:
            return answer < comparisonValue;
        case types_1.FreeQuestionRules.lessThanOrEqual:
            return answer <= comparisonValue;
        default:
            throw new Error(`Unknown free question rule ${rule}`);
    }
};
exports.evaluateFreeQuestionRule = evaluateFreeQuestionRule;
const evaluateRag = function (rawAnswers) {
    const answers = {};
    rawAnswers.forEach((answer) => {
        if ((answer === null || answer === void 0 ? void 0 : answer.questionId) && (answer === null || answer === void 0 ? void 0 : answer.chosenOptionIds) && (answer === null || answer === void 0 ? void 0 : answer.chosenOptionIds.length) > 0) {
            answers[answer.questionId] = answer.chosenOptionIds;
        }
        else if (answer && answer.questionId && answer.freeValue !== undefined && answer.freeValue !== null) {
            answers[answer.questionId] = answer.freeValue;
        }
    });
    const evaluatedRag = {};
    for (const [condition, conditionData] of Object.entries(index_1.ragData)) {
        evaluatedRag[condition] = {
            ...evaluateNodePath(conditionData.start, [], conditionData, answers, []),
            supplementaryInfoQuestionIds: conditionData.supplementalInfoQuestionIds,
        };
    }
    const output = {};
    const decisions = {};
    for (const condition in evaluatedRag) {
        decisions[condition] = evaluatedRag[condition].decisions;
        delete evaluatedRag[condition].decisions;
        output[condition] = evaluatedRag[condition];
    }
    return { output, decisions };
};
exports.evaluateRag = evaluateRag;
function evaluateNodePath(currentNodeId, usedQuestionIds, conditionData, answers, decisions) {
    const currentNode = conditionData.nodePaths[currentNodeId];
    if (isKeyOfRagNode(currentNodeId)) {
        // if the current node is a rag node, return the priority of that node
        const currentNode = types_1.ragNodes[currentNodeId];
        decisions.push({ score: currentNode.priority, type: types_1.DecisionType.SCORE });
        return { score: currentNode.priority, usedQuestionIds, decisions };
    }
    else if (currentNode && isQuestionNode(currentNode)) {
        // if the current node is a question node, get the next node and recurse
        const currentQuestion = currentNode.questionId;
        const answer = answers[currentQuestion];
        if ((answer === undefined || answer === null) && currentNode.noAnswer) {
            usedQuestionIds.push(currentQuestion);
            decisions.push({ questionId: currentQuestion, type: types_1.DecisionType.NO_ANSWER });
            const nextId = currentNode.noAnswer;
            return evaluateNodePath(nextId, usedQuestionIds, conditionData, answers, decisions);
        }
        else if (currentNode.freeQuestion && typeof answer === 'number') {
            for (let i = 0; i < currentNode.freeQuestion.length; i++) {
                const rule = currentNode.freeQuestion[i];
                if ((0, exports.evaluateFreeQuestionRule)(rule.rule, rule.comparisonValue, answer)) {
                    usedQuestionIds.push(currentQuestion);
                    decisions.push({
                        questionId: currentNode.questionId,
                        type: types_1.DecisionType.FREE_QUESTION,
                        rule: rule.rule,
                        answer: answer,
                        comparisonValue: rule.comparisonValue,
                    });
                    return evaluateNodePath(rule.next, usedQuestionIds, conditionData, answers, decisions);
                }
            }
            throw new Error(`No free question rule matched for question ${currentQuestion}`);
        }
        else if (answer && Array.isArray(answer) && answer.length > 0) {
            /**
             * 1. Process all chosenOptionIds and determine next node path (e.g. A, B, C, ...) or RAG outcome for each
             * 2. Determine which one to return next in that order of priority:
             *  2.1 Any found node path that leads to a next question (e.g. A, B, C, ...)
             *  2.2 Return highest RAG priority in that order: rag2 -> rag3 -> rag4 -> rag5
             *  2.3 Return rag0 (i.e. "Not enough information") by default
             */
            let selectedNodePath = { score: 0, usedQuestionIds, decisions };
            let currentPriority = Number.MAX_VALUE;
            let decidingOptionId = null;
            usedQuestionIds.push(currentQuestion);
            for (const chosenOptionId of answer) {
                const nextId = currentNode.answers[chosenOptionId];
                if (!nextId)
                    continue;
                const newDecisions = [
                    ...decisions,
                    ...answer.map((optionId) => ({
                        questionId: currentQuestion,
                        type: types_1.DecisionType.MULTICHOICE,
                        chosenOptionId: optionId,
                        isDecidingOption: answer.length > 1 && optionId === chosenOptionId ? true : undefined,
                    })),
                ];
                const nodePath = evaluateNodePath(nextId, usedQuestionIds, conditionData, answers, newDecisions);
                if (!isKeyOfRagNode(nextId)) {
                    return nodePath;
                }
                const nodePriority = types_1.ragNodes[nextId].priority;
                if (nodePriority < currentPriority) {
                    selectedNodePath = nodePath;
                    currentPriority = nodePriority;
                    decidingOptionId = chosenOptionId;
                }
            }
            selectedNodePath.decisions = selectedNodePath.decisions.map((decision) => {
                const isDecidingOption = decision.type === types_1.DecisionType.MULTICHOICE &&
                    decision.chosenOptionId === decidingOptionId &&
                    answer.length > 1;
                return isDecidingOption ? { ...decision, isDecidingOption } : decision;
            });
            return selectedNodePath;
        }
        else {
            decisions.push({ score: 0, type: types_1.DecisionType.SCORE });
            return { score: 0, usedQuestionIds, decisions };
        }
    }
    else {
        throw new Error(`Node id ${currentNodeId} not found in condition data`);
    }
}
function isQuestionNode(node) {
    return 'questionId' in node;
}
exports.isQuestionNode = isQuestionNode;
function isKeyOfRagNode(key) {
    return key in types_1.ragNodes;
}
