"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.questionPredicates = exports.tests = exports.aspects = exports.questions = void 0;
const censeo_1 = require("../../schema/generated/censeo");
exports.questions = [
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "8995767f-a3f5-4f5f-aa30-ce4c0b48d1e6",
                "text": "My typical low mood are as bad as my most severe previous episode",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "4f823cae-c013-4f68-86ad-46b27927ec2b",
                "text": "My typical low mood symptoms are not as bad as my most severe previous episode",
                "value": 1
            }
        ],
        "id": "D-43bt-demo",
        "text": "How do your usual feelings of low mood compare to your most severe period of feeling low?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "abce3dbd-8dae-59b7-bdf7-e2d73f29950f",
                "text": "Less than 1 hour",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "615f248a-5de9-487d-a20e-c000a3bba3da",
                "text": "1-3 hours a day",
                "value": 0.2
            },
            {
                "__typename": "Option",
                "id": "cab3a65e-8b79-4d4a-b2a2-25a28f00fe4d",
                "text": "4-6 hours a day",
                "value": 0.4
            },
            {
                "__typename": "Option",
                "id": "7954c2eb-b470-4240-8b72-dd23edaa003d",
                "text": "7-9 hours a day",
                "value": 0.6
            },
            {
                "__typename": "Option",
                "id": "4b2d2a62-564f-4e86-b540-1712cc3ce769",
                "text": "10-12 hours a day",
                "value": 0.8
            },
            {
                "__typename": "Option",
                "id": "2ea20be1-dd85-4199-8a9c-54922edac03d",
                "text": "Over 12 hours a day",
                "value": 1
            }
        ],
        "id": "D-2bs-demo",
        "text": "Think back to your most severe period of low mood. Can you remember how much of the day it would affect you?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "2d8a4faf-d897-42c3-b804-fc756cbc7eec",
                "text": "1 day a week",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "fe554f25-dfd7-5062-a2b8-f2662e2c3466",
                "text": "2–3 days a week",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "664c5f59-06d3-53a2-81e0-eaf62108625d",
                "text": "4–5 days a week",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "c4422e8a-6a0a-51ea-8592-9157036922d1",
                "text": "6–7 days a week",
                "value": 1
            }
        ],
        "id": "D-3bs-demo",
        "text": "Can you remember how many days a week you felt this way?",
        "isMultiselect": false
    },
    {
        "__typename": "FreeQuestion",
        "id": "D-4bs-demo",
        "text": "How long did this period of time last for?",
        "unit": censeo_1.Unit.DurationHours
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "bc739f78-ad04-4330-98fc-888cdb2a16be",
                "text": "Less than 1 hour an hour",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "bb9b7810-1a57-4676-b7e9-a98e8aa7bf9d",
                "text": "1-3 hours a day",
                "value": 0.2
            },
            {
                "__typename": "Option",
                "id": "25abd244-73fa-4c43-b988-b2d9da189db6",
                "text": "4-6 hours a day",
                "value": 0.4
            },
            {
                "__typename": "Option",
                "id": "1bc7d23a-361e-46d6-a3e6-24ab3019e8b7",
                "text": "7-9 hours a day",
                "value": 0.6
            },
            {
                "__typename": "Option",
                "id": "5dbb9efe-206d-41f1-945e-a1cfee6eee4d",
                "text": "10-12 hours a day",
                "value": 0.8
            },
            {
                "__typename": "Option",
                "id": "6918445d-32ba-4fc0-b56a-fe33ff251b9d",
                "text": "Over 12 hours a day",
                "value": 1
            }
        ],
        "id": "D-1bl-demo",
        "text": "Think back to your longest period of low mood. How much of the day would it affect you?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "3c285c4b-03a9-4dd8-9602-5e03ec3ea255",
                "text": "1 day a week",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "6918445d-32ba-4fc0-b56a-fe33ff251b9d",
                "text": "2–3 days a week",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "1c5f228f-84d5-4723-a40f-576de50cbd76",
                "text": "4–5 days a week",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "45d300ca-07dd-473a-b96b-ba1fe11dcac2",
                "text": "6–7 days a week",
                "value": 1
            }
        ],
        "id": "D-2bl-demo",
        "text": "Can you remember how many days a week you felt this way?",
        "isMultiselect": false
    },
    {
        "__typename": "FreeQuestion",
        "id": "D-3bl-demo",
        "text": "How long did your longest period of low mood last for?",
        "unit": censeo_1.Unit.DurationHours
    },
    {
        "__typename": "FreeQuestion",
        "id": "D-2-demo",
        "text": "How old were you when you first experienced an episode of low mood like this?",
        "unit": censeo_1.Unit.Age
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "4bcd0861-dcf1-4fb9-8573-923aaf3044f8",
                "text": "The death of someone close to me"
            },
            {
                "__typename": "Option",
                "id": "bc6a5665-2d8e-45cc-9a5f-df22581d2abb",
                "text": "Stress related to work (e.g. job loss, overwork at work)"
            },
            {
                "__typename": "Option",
                "id": "d34c911e-3ddf-4985-9ac1-d7ee3211ed74",
                "text": "Physical illness or injury"
            },
            {
                "__typename": "Option",
                "id": "7ba0702f-7585-4e7f-a3a3-9156e801289c",
                "text": "Coronavirus outbreak"
            },
            {
                "__typename": "Option",
                "id": "8b2291a6-262c-4831-b10f-9bb9e3e21e6f",
                "text": "Another stressful event"
            },
            {
                "__typename": "Option",
                "id": "662267d6-b668-47fc-b4c5-0d4a4255bce1",
                "text": "These feelings started out of the blue",
                "exclusive": true
            }
        ],
        "id": "D-3-demo",
        "text": "Think back to the first time you felt low in mood. Do you think it started because of any of the below? Select all that apply.",
        "isMultiselect": true
    }
];
exports.aspects = {
    "bd_threshold_manic_episode": {
        "type": "merge",
        "wires": [
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.6,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "bd_severity"
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_manic_episode"
                }
            }
        ],
        "anyToAll": 0.5
    },
    "bd_threshold_hypomanic_episode": {
        "type": "merge",
        "wires": [
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.25,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "bd_severity"
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_hypomanic_episode"
                }
            }
        ],
        "anyToAll": 0.5
    },
    "bd_threshold_submanic_episode": {
        "type": "filter",
        "sigmoid": {
            "middle": 0.1,
            "width": 1
        },
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "bd_severity"
            }
        }
    },
    "has_bd1": {
        "type": "input",
        "signal": {
            "type": "AspectReference",
            "aspectName": "bd_threshold_manic_episode"
        }
    },
    "has_bd2": {
        "type": "merge",
        "wires": [
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_bd1"
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_threshold_hypomanic_episode"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "has_lifetime_mdd_episode"
                }
            }
        ],
        "anyToAll": 1
    },
    "has_current_mdd": {
        "type": "merge",
        "wires": [
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_bd1"
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_bd2"
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "mdd_current_episode_above_mild"
                }
            }
        ],
        "anyToAll": 1
    },
    "has_past_mdd": {
        "type": "merge",
        "wires": [
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_bd1"
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_bd2"
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_current_mdd"
                    }
                }
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "has_past_mild_dep"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "has_past_moderate_dep"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "has_past_severe_dep"
                        }
                    }
                ],
                "anyToAll": 0.3333333333333333
            }
        ],
        "anyToAll": 1
    },
    "has_current_normal_mood": {
        "type": "merge",
        "wires": [
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_current_subclinical_lowmood"
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_current_subclinical_manic"
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_current_mild_dep"
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_current_moderate_dep"
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_current_severe_dep"
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_current_manic_symptoms"
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "has_current_subclinical_lowmood": {
        "type": "merge",
        "wires": [
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_current_mild_dep"
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_current_moderate_dep"
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_current_severe_dep"
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_current_subclinical_manic"
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_current_manic_symptoms"
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "has_current_subclinical_manic": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "bd_threshold_submanic_episode"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "bd_threshold_hypomanic_episode"
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_current_episode"
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_bd1"
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_bd2"
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_current_mild_dep"
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_current_moderate_dep"
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_current_severe_dep"
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "has_current_manic_symptoms": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_current_episode"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_threshold_hypomanic_episode"
                }
            }
        ],
        "anyToAll": 1
    },
    "has_current_mild_dep": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "mdd_current_episode_above_mild"
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_current_moderate_dep"
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_current_severe_dep"
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_current_manic_symptoms"
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "has_current_moderate_dep": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "mdd_current_episode_above_moderate"
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_current_severe_dep"
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_current_manic_symptoms"
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "has_current_severe_dep": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "mdd_current_episode_above_severe"
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_current_manic_symptoms"
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "mdd_current_episode_above_mild": {
        "type": "merge",
        "wires": [
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.3,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "mdd_current_severity"
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "has_current_mdd_mild"
                }
            }
        ],
        "anyToAll": 0.5
    },
    "mdd_current_episode_above_moderate": {
        "type": "filter",
        "sigmoid": {
            "middle": 0.6,
            "width": 1
        },
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "mdd_current_severity"
            }
        }
    },
    "mdd_current_episode_above_severe": {
        "type": "merge",
        "wires": [
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.8,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "mdd_current_severity"
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "has_current_mdd_severe"
                }
            }
        ],
        "anyToAll": 0.5
    },
    "has_past_mild_dep": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "mdd_past_above_mild"
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_past_moderate_dep"
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_past_severe_dep"
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "has_past_moderate_dep": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "mdd_past_above_moderate"
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_past_severe_dep"
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "has_past_severe_dep": {
        "type": "input",
        "signal": {
            "type": "AspectReference",
            "aspectName": "mdd_past_above_severe"
        }
    },
    "mdd_past_above_mild": {
        "type": "merge",
        "wires": [
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.3,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "mdd_worst_severity"
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "has_past_mdd_mild"
                }
            }
        ],
        "anyToAll": 0.5
    },
    "mdd_past_above_moderate": {
        "type": "filter",
        "sigmoid": {
            "middle": 0.6,
            "width": 1
        },
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "mdd_worst_severity"
            }
        }
    },
    "mdd_past_above_severe": {
        "type": "merge",
        "wires": [
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.8,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "mdd_worst_severity"
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "has_past_mdd_severe"
                }
            }
        ],
        "anyToAll": 0.5
    },
    "always_false": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "DemS"
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "DemS"
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "always_true": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "always_false"
            }
        }
    },
    "bd1_cm": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "has_bd1"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "has_current_manic_symptoms"
                }
            }
        ],
        "anyToAll": 1
    },
    "mdd_cd_mi": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "has_current_mdd"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "has_current_mild_dep"
                }
            }
        ],
        "anyToAll": 1
    },
    "simple_bd1": {
        "type": "input",
        "signal": {
            "type": "AspectReference",
            "aspectName": "bd_manic_episode"
        }
    },
    "simple_lifetime_mdd_episode": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "has_current_mdd_mild"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "has_past_mdd_mild"
                }
            }
        ],
        "anyToAll": 0.5
    },
    "mdd_for_bd2": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "simple_lifetime_mdd_episode"
                }
            },
            {
                "type": "toPredicate",
                "wire": {
                    "type": "filter",
                    "sigmoid": {
                        "middle": 0.25,
                        "width": 1
                    },
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "bd_hypomanic_episode"
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "simple_bd2": {
        "type": "merge",
        "wires": [
            {
                "type": "toPredicate",
                "wire": {
                    "type": "not",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "simple_bd1"
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_hypomanic_episode"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "mdd_for_bd2"
                }
            }
        ],
        "anyToAll": 1
    },
    "simple_mdd": {
        "type": "merge",
        "wires": [
            {
                "type": "toPredicate",
                "wire": {
                    "type": "not",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "simple_bd1"
                        }
                    }
                }
            },
            {
                "type": "toPredicate",
                "wire": {
                    "type": "not",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "simple_bd2"
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "has_current_mdd_mild"
                }
            }
        ],
        "anyToAll": 1
    },
    "has_lifetime_mdd_episode": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "has_current_mdd_mild"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "has_current_mdd_severe"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "mdd_current_episode_above_mild"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "has_past_mdd_mild"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "has_past_mdd_severe"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "has_past_severe_dep"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "has_past_moderate_dep"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "has_past_mild_dep"
                }
            }
        ],
        "anyToAll": 0.125
    },
    "mdd_current_severity_precursor": {
        "type": "input",
        "signal": {
            "type": "AspectReference",
            "aspectName": "mdd_c_has_screen"
        }
    },
    "mdd_current_severity_precursor_screenout": {
        "type": "merge",
        "wires": [
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.5,
                    "width": 1
                },
                "lowPass": true,
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "mdd_c_has_screen"
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "has_current_lm"
                }
            }
        ],
        "anyToAll": 1
    },
    "mdd_current_screenout_severity": {
        "type": "input",
        "signal": {
            "type": "AspectReference",
            "aspectName": "mdd_current_severity_precursor_screenout"
        }
    },
    "mdd_current_screenin_severity": {
        "type": "input",
        "signal": {
            "type": "AspectReference",
            "aspectName": "mdd_current_severity_precursor"
        }
    },
    "mdd_worst_severity": {
        "type": "input",
        "signal": {
            "type": "AspectReference",
            "aspectName": "mdd_past_combined_b"
        }
    },
    "has_current_mdd_mild": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "mdd_c_mild_A_symptoms"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "mdd_c_mild_B_impairment"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "mdd_c_mild_duration"
                }
            }
        ],
        "anyToAll": 1
    },
    "has_current_mdd_severe": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "mdd_c_severe_A_symptoms"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "mdd_c_severe_B_impairment"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "mdd_c_severe_duration"
                }
            }
        ],
        "anyToAll": 1
    },
    "has_past_mdd_mild": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "has_p_mdd_mild"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "has_pt_mdd_mild"
                }
            }
        ],
        "anyToAll": 0.5
    },
    "has_past_mdd_severe": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "has_p_mdd_severe"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "has_pt_mdd_severe"
                }
            }
        ],
        "anyToAll": 0.5
    },
    "has_p_mdd_mild": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "mdd_p_mild_A_symptoms"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "mdd_p_mild_B_impairment"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "mdd_p_mild_duration"
                }
            }
        ],
        "anyToAll": 1
    },
    "has_p_mdd_severe": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "mdd_p_severe_A_symptoms"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "mdd_p_severe_B_impairment"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "mdd_p_severe_duration"
                }
            }
        ],
        "anyToAll": 1
    },
    "has_pt_mdd_mild": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "mdd_pt_mild_A_symptoms"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "mdd_pt_mild_B_impairment"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "mdd_pt_mild_duration"
                }
            }
        ],
        "anyToAll": 1
    },
    "has_pt_mdd_severe": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "mdd_pt_severe_A_symptoms"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "mdd_pt_severe_B_impairment"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "mdd_pt_severe_duration"
                }
            }
        ],
        "anyToAll": 1
    },
    "mdd_c_mild_A_symptoms": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_c_mild_low_mood"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_c_mild_anhedonia"
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_c_mild_weight_appetite"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_c_mild_sleep"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_c_mild_psychomotor"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_c_mild_fatigue"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_c_mild_worthless_guilt"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_c_mild_concentration"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_c_mild_death"
                        }
                    }
                ],
                "anyToAll": 0.42857142857142855
            }
        ],
        "anyToAll": 1
    },
    "mdd_c_has_screen": {
        "type": "filter",
        "sigmoid": {
            "middle": 0,
            "width": 1
        },
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "D-15a"
            }
        }
    },
    "mdd_c_mild_low_mood": {
        "type": "merge",
        "wires": [
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.5,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-1a"
                    }
                }
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.5,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-16.5a"
                    }
                }
            }
        ],
        "anyToAll": 0.5
    },
    "mdd_c_mild_anhedonia": {
        "type": "merge",
        "wires": [
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.5,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-2a"
                    }
                }
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.5,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-26a"
                    }
                }
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.5,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-33a"
                    }
                }
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.5,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-34a"
                    }
                }
            }
        ],
        "anyToAll": 0.25
    },
    "mdd_c_mild_weight_appetite": {
        "type": "merge",
        "wires": [
            {
                "type": "equals",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-5a"
                    }
                },
                "value": 0.5
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "D-5a"
                }
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.5,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-18a"
                    }
                }
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.5,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-19a"
                    }
                }
            },
            {
                "type": "equals",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-36a"
                    }
                },
                "value": 0.2
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "D-36a"
                }
            }
        ],
        "anyToAll": 0.16666666666666666
    },
    "mdd_c_mild_sleep": {
        "type": "merge",
        "wires": [
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 1,
                    "width": 1
                },
                "lowPass": true,
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D4a_none"
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-37a"
                    }
                }
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.75,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-37a"
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "ins_ce"
                }
            }
        ],
        "anyToAll": 0.25
    },
    "mdd_c_mild_psychomotor": {
        "type": "merge",
        "wires": [
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.5,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-6a"
                    }
                }
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.5,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-23a"
                    }
                }
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.75,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-30a"
                    }
                }
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.5,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-38a"
                    }
                }
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.5,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-39a"
                    }
                }
            }
        ],
        "anyToAll": 0.2
    },
    "mdd_c_mild_fatigue": {
        "type": "merge",
        "wires": [
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.5,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-3a"
                    }
                }
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.5,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-28a"
                    }
                }
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.5,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-40a"
                    }
                }
            }
        ],
        "anyToAll": 0.3333333333333333
    },
    "mdd_c_mild_worthless_guilt": {
        "type": "merge",
        "wires": [
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.75,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-7a"
                    }
                }
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.5,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-20a"
                    }
                }
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.75,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-41a"
                    }
                }
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.5,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-42a"
                    }
                }
            }
        ],
        "anyToAll": 0.25
    },
    "mdd_c_mild_concentration": {
        "type": "merge",
        "wires": [
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.5,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-8a"
                    }
                }
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.5,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-21a"
                    }
                }
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.5,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-43a"
                    }
                }
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.5,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-44a"
                    }
                }
            }
        ],
        "anyToAll": 0.25
    },
    "mdd_c_mild_death": {
        "type": "merge",
        "wires": [
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.5,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-9a"
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "s_current"
                }
            }
        ],
        "anyToAll": 0.5
    },
    "mdd_c_mild_B_impairment": {
        "type": "merge",
        "wires": [
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.5,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-10a"
                    }
                }
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "D46a_basic"
                        }
                    },
                    {
                        "type": "filter",
                        "sigmoid": {
                            "middle": 7,
                            "width": 1
                        },
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "D-45a"
                            }
                        }
                    },
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AspectReference",
                                    "aspectName": "D46a_basic"
                                }
                            },
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AspectReference",
                                    "aspectName": "D46a_work"
                                }
                            },
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AspectReference",
                                    "aspectName": "D46a_relationships"
                                }
                            },
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AspectReference",
                                    "aspectName": "D46a_social"
                                }
                            },
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AspectReference",
                                    "aspectName": "D46a_family"
                                }
                            },
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AspectReference",
                                    "aspectName": "D47a_leisure"
                                }
                            },
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AspectReference",
                                    "aspectName": "D47a_health"
                                }
                            },
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AspectReference",
                                    "aspectName": "D47a_financial"
                                }
                            },
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AspectReference",
                                    "aspectName": "D47a_other"
                                }
                            },
                            {
                                "type": "filter",
                                "sigmoid": {
                                    "middle": 1,
                                    "width": 1
                                },
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "AspectReference",
                                        "aspectName": "D-45a"
                                    }
                                }
                            }
                        ],
                        "anyToAll": 0.2
                    }
                ],
                "anyToAll": 0.3333333333333333
            }
        ],
        "anyToAll": 1
    },
    "mdd_c_mild_duration": {
        "type": "merge",
        "wires": [
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.4,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-12a"
                    }
                }
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.5,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-13a"
                    }
                }
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 14,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-14a"
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "mdd_c_severe_A_symptoms": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_c_mild_low_mood"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_c_mild_anhedonia"
                        }
                    }
                ],
                "anyToAll": 1
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_c_mild_weight_appetite"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_c_mild_sleep"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_c_mild_psychomotor"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_c_mild_fatigue"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_c_mild_worthless_guilt"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_c_mild_concentration"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_c_mild_death"
                        }
                    }
                ],
                "anyToAll": 0.7142857142857143
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_c_severe_low_mood"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_c_severe_anhedonia"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_c_severe_weight_appetite"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_c_severe_sleep"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_c_severe_psychomotor"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_c_severe_fatigue"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_c_severe_worthless_guilt"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_c_severe_concentration"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_c_severe_death"
                        }
                    }
                ],
                "anyToAll": 0.3333333333333333
            }
        ],
        "anyToAll": 1
    },
    "mdd_c_severe_low_mood": {
        "type": "merge",
        "wires": [
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 1,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-1a"
                    }
                }
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 1,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-16.5a"
                    }
                }
            }
        ],
        "anyToAll": 0.5
    },
    "mdd_c_severe_anhedonia": {
        "type": "merge",
        "wires": [
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 1,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-2a"
                    }
                }
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 1,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-26a"
                    }
                }
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 1,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-33a"
                    }
                }
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 1,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-34a"
                    }
                }
            }
        ],
        "anyToAll": 0.25
    },
    "mdd_c_severe_weight_appetite": {
        "type": "merge",
        "wires": [
            {
                "type": "equals",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-5a"
                    }
                },
                "value": 0.5
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 1,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-5a"
                    }
                }
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 1,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-18a"
                    }
                }
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 1,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-19a"
                    }
                }
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 1,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-36a"
                    }
                }
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 1,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-36a"
                    }
                }
            }
        ],
        "anyToAll": 0.16666666666666666
    },
    "mdd_c_severe_sleep": {
        "type": "merge",
        "wires": [
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 1,
                    "width": 1
                },
                "lowPass": true,
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D4a_none"
                    }
                }
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 1,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-37a"
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "ins_ce"
                }
            }
        ],
        "anyToAll": 0.3333333333333333
    },
    "mdd_c_severe_psychomotor": {
        "type": "merge",
        "wires": [
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 1,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-6a"
                    }
                }
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 1,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-23a"
                    }
                }
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 1,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-30a"
                    }
                }
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 1,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-38a"
                    }
                }
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 1,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-39a"
                    }
                }
            }
        ],
        "anyToAll": 0.2
    },
    "mdd_c_severe_fatigue": {
        "type": "merge",
        "wires": [
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 1,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-3a"
                    }
                }
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 1,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-28a"
                    }
                }
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 1,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-40a"
                    }
                }
            }
        ],
        "anyToAll": 0.3333333333333333
    },
    "mdd_c_severe_worthless_guilt": {
        "type": "merge",
        "wires": [
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 1,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-7a"
                    }
                }
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 1,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-20a"
                    }
                }
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 1,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-41a"
                    }
                }
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 1,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-42a"
                    }
                }
            }
        ],
        "anyToAll": 0.25
    },
    "mdd_c_severe_concentration": {
        "type": "merge",
        "wires": [
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 1,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-8a"
                    }
                }
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 1,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-21a"
                    }
                }
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 1,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-43a"
                    }
                }
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 1,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-44a"
                    }
                }
            }
        ],
        "anyToAll": 0.25
    },
    "mdd_c_severe_death": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "mdd_c_severe_death_question"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "s_current"
                }
            }
        ],
        "anyToAll": 0.5
    },
    "mdd_c_severe_death_question": {
        "type": "filter",
        "sigmoid": {
            "middle": 1,
            "width": 1
        },
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "D-9a"
            }
        }
    },
    "mdd_c_severe_B_impairment": {
        "type": "merge",
        "wires": [
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 1,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-10a"
                    }
                }
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "D46a_basic"
                        }
                    },
                    {
                        "type": "filter",
                        "sigmoid": {
                            "middle": 7,
                            "width": 1
                        },
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "D-45a"
                            }
                        }
                    },
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AspectReference",
                                    "aspectName": "D46a_basic"
                                }
                            },
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AspectReference",
                                    "aspectName": "D46a_work"
                                }
                            },
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AspectReference",
                                    "aspectName": "D46a_relationships"
                                }
                            },
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AspectReference",
                                    "aspectName": "D46a_social"
                                }
                            },
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AspectReference",
                                    "aspectName": "D46a_family"
                                }
                            },
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AspectReference",
                                    "aspectName": "D47a_leisure"
                                }
                            },
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AspectReference",
                                    "aspectName": "D47a_health"
                                }
                            },
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AspectReference",
                                    "aspectName": "D47a_financial"
                                }
                            },
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AspectReference",
                                    "aspectName": "D47a_other"
                                }
                            },
                            {
                                "type": "filter",
                                "sigmoid": {
                                    "middle": 1,
                                    "width": 1
                                },
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "AspectReference",
                                        "aspectName": "D-45a"
                                    }
                                }
                            }
                        ],
                        "anyToAll": 0.2
                    }
                ],
                "anyToAll": 0.3333333333333333
            }
        ],
        "anyToAll": 1
    },
    "mdd_c_severe_duration": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "mdd_c_severe_duration_hours_duty"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "mdd_c_severe_duration_days_duty"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "mdd_c_severe_duration_length"
                }
            }
        ],
        "anyToAll": 1
    },
    "mdd_c_severe_duration_hours_duty": {
        "type": "filter",
        "sigmoid": {
            "middle": 0.4,
            "width": 1
        },
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "D-12a"
            }
        }
    },
    "mdd_c_severe_duration_days_duty": {
        "type": "filter",
        "sigmoid": {
            "middle": 0.5,
            "width": 1
        },
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "D-13a"
            }
        }
    },
    "mdd_c_severe_duration_length": {
        "type": "filter",
        "sigmoid": {
            "middle": 14,
            "width": 1
        },
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "D-14a"
            }
        }
    },
    "mdd_p_mild_A_symptoms": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_p_mild_low_mood"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_p_mild_anhedonia"
                        }
                    }
                ],
                "anyToAll": 1
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_p_mild_weight_appetite"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_p_mild_sleep"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_p_mild_psychomotor"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_p_mild_fatigue"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_p_mild_worthless_guilt"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_p_mild_concentration"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_p_mild_death"
                        }
                    }
                ],
                "anyToAll": 0.2857142857142857
            }
        ],
        "anyToAll": 1
    },
    "mdd_p_mild_low_mood": {
        "type": "filter",
        "sigmoid": {
            "middle": 0.5,
            "width": 1
        },
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "D-s1b"
            }
        }
    },
    "mdd_p_mild_anhedonia": {
        "type": "filter",
        "sigmoid": {
            "middle": 0.5,
            "width": 1
        },
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "D-s2b"
            }
        }
    },
    "mdd_p_mild_weight_appetite": {
        "type": "merge",
        "wires": [
            {
                "type": "equals",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-s5b"
                    }
                },
                "value": 0.5
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 1,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-s5b"
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "mdd_p_mild_sleep": {
        "type": "filter",
        "sigmoid": {
            "middle": 1,
            "width": 1
        },
        "lowPass": true,
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "Ds4b_none"
            }
        }
    },
    "mdd_p_mild_psychomotor": {
        "type": "filter",
        "sigmoid": {
            "middle": 0.5,
            "width": 1
        },
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "D-s6b"
            }
        }
    },
    "mdd_p_mild_fatigue": {
        "type": "filter",
        "sigmoid": {
            "middle": 0.5,
            "width": 1
        },
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "D-s3b"
            }
        }
    },
    "mdd_p_mild_worthless_guilt": {
        "type": "filter",
        "sigmoid": {
            "middle": 0.75,
            "width": 1
        },
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "D-s7b"
            }
        }
    },
    "mdd_p_mild_concentration": {
        "type": "filter",
        "sigmoid": {
            "middle": 0.5,
            "width": 1
        },
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "D-s8b"
            }
        }
    },
    "mdd_p_mild_death": {
        "type": "filter",
        "sigmoid": {
            "middle": 0.5,
            "width": 1
        },
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "D-s9b"
            }
        }
    },
    "mdd_p_mild_B_impairment": {
        "type": "merge",
        "wires": [
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.5,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-s10b"
                    }
                }
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "Ds11b_basic"
                        }
                    },
                    {
                        "type": "filter",
                        "sigmoid": {
                            "middle": 7,
                            "width": 1
                        },
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "D-42b"
                            }
                        }
                    },
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "filter",
                                "sigmoid": {
                                    "middle": 1,
                                    "width": 1
                                },
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "AspectReference",
                                        "aspectName": "Ds11b_basic"
                                    }
                                }
                            },
                            {
                                "type": "filter",
                                "sigmoid": {
                                    "middle": 1,
                                    "width": 1
                                },
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "AspectReference",
                                        "aspectName": "Ds11b_work"
                                    }
                                }
                            },
                            {
                                "type": "filter",
                                "sigmoid": {
                                    "middle": 1,
                                    "width": 1
                                },
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "AspectReference",
                                        "aspectName": "Ds11b_relationships"
                                    }
                                }
                            },
                            {
                                "type": "filter",
                                "sigmoid": {
                                    "middle": 1,
                                    "width": 1
                                },
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "AspectReference",
                                        "aspectName": "Ds11b_social"
                                    }
                                }
                            },
                            {
                                "type": "filter",
                                "sigmoid": {
                                    "middle": 1,
                                    "width": 1
                                },
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "AspectReference",
                                        "aspectName": "Ds11b_family"
                                    }
                                }
                            },
                            {
                                "type": "filter",
                                "sigmoid": {
                                    "middle": 1,
                                    "width": 1
                                },
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "AspectReference",
                                        "aspectName": "Ds11b_leisure"
                                    }
                                }
                            },
                            {
                                "type": "filter",
                                "sigmoid": {
                                    "middle": 1,
                                    "width": 1
                                },
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "AspectReference",
                                        "aspectName": "Ds11b_health"
                                    }
                                }
                            },
                            {
                                "type": "filter",
                                "sigmoid": {
                                    "middle": 1,
                                    "width": 1
                                },
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "AspectReference",
                                        "aspectName": "Ds11b_financial"
                                    }
                                }
                            },
                            {
                                "type": "filter",
                                "sigmoid": {
                                    "middle": 1,
                                    "width": 1
                                },
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "AspectReference",
                                        "aspectName": "Ds11b_other"
                                    }
                                }
                            },
                            {
                                "type": "filter",
                                "sigmoid": {
                                    "middle": 1,
                                    "width": 1
                                },
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "AspectReference",
                                        "aspectName": "D-42b"
                                    }
                                }
                            }
                        ],
                        "anyToAll": 0.2
                    }
                ],
                "anyToAll": 0.3333333333333333
            }
        ],
        "anyToAll": 1
    },
    "mdd_p_mild_duration": {
        "type": "merge",
        "wires": [
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.4,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-2b"
                    }
                }
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.5,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-3b"
                    }
                }
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 14,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-4b"
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "mdd_p_severe_A_symptoms": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_p_mild_low_mood"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_p_mild_anhedonia"
                        }
                    }
                ],
                "anyToAll": 1
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_p_mild_weight_appetite"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_p_mild_sleep"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_p_mild_psychomotor"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_p_mild_fatigue"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_p_mild_worthless_guilt"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_p_mild_concentration"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_p_mild_death"
                        }
                    }
                ],
                "anyToAll": 0.7142857142857143
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_p_severe_low_mood"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_p_severe_anhedonia"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_p_severe_weight_appetite"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_p_severe_sleep"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_p_severe_psychomotor"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_p_severe_fatigue"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_p_severe_worthless_guilt"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_p_severe_concentration"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_p_severe_death"
                        }
                    }
                ],
                "anyToAll": 0.3333333333333333
            }
        ],
        "anyToAll": 1
    },
    "mdd_p_severe_low_mood": {
        "type": "filter",
        "sigmoid": {
            "middle": 1,
            "width": 1
        },
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "D-s1b"
            }
        }
    },
    "mdd_p_severe_anhedonia": {
        "type": "filter",
        "sigmoid": {
            "middle": 1,
            "width": 1
        },
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "D-s2b"
            }
        }
    },
    "mdd_p_severe_weight_appetite": {
        "type": "merge",
        "wires": [
            {
                "type": "equals",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-s5b"
                    }
                },
                "value": 0.5
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 1,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-s5b"
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "mdd_p_severe_sleep": {
        "type": "filter",
        "sigmoid": {
            "middle": 1,
            "width": 1
        },
        "lowPass": true,
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "Ds4b_none"
            }
        }
    },
    "mdd_p_severe_psychomotor": {
        "type": "filter",
        "sigmoid": {
            "middle": 1,
            "width": 1
        },
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "D-s6b"
            }
        }
    },
    "mdd_p_severe_fatigue": {
        "type": "filter",
        "sigmoid": {
            "middle": 1,
            "width": 1
        },
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "D-s3b"
            }
        }
    },
    "mdd_p_severe_worthless_guilt": {
        "type": "filter",
        "sigmoid": {
            "middle": 1,
            "width": 1
        },
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "D-s7b"
            }
        }
    },
    "mdd_p_severe_concentration": {
        "type": "filter",
        "sigmoid": {
            "middle": 1,
            "width": 1
        },
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "D-s8b"
            }
        }
    },
    "mdd_p_severe_death": {
        "type": "filter",
        "sigmoid": {
            "middle": 1,
            "width": 1
        },
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "D-s9b"
            }
        }
    },
    "mdd_p_severe_B_impairment": {
        "type": "merge",
        "wires": [
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 1,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-s10b"
                    }
                }
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "Ds11b_basic"
                        }
                    },
                    {
                        "type": "filter",
                        "sigmoid": {
                            "middle": 7,
                            "width": 1
                        },
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "D-42b"
                            }
                        }
                    },
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "filter",
                                "sigmoid": {
                                    "middle": 1,
                                    "width": 1
                                },
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "AspectReference",
                                        "aspectName": "Ds11b_basic"
                                    }
                                }
                            },
                            {
                                "type": "filter",
                                "sigmoid": {
                                    "middle": 1,
                                    "width": 1
                                },
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "AspectReference",
                                        "aspectName": "Ds11b_work"
                                    }
                                }
                            },
                            {
                                "type": "filter",
                                "sigmoid": {
                                    "middle": 1,
                                    "width": 1
                                },
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "AspectReference",
                                        "aspectName": "Ds11b_relationships"
                                    }
                                }
                            },
                            {
                                "type": "filter",
                                "sigmoid": {
                                    "middle": 1,
                                    "width": 1
                                },
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "AspectReference",
                                        "aspectName": "Ds11b_social"
                                    }
                                }
                            },
                            {
                                "type": "filter",
                                "sigmoid": {
                                    "middle": 1,
                                    "width": 1
                                },
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "AspectReference",
                                        "aspectName": "Ds11b_family"
                                    }
                                }
                            },
                            {
                                "type": "filter",
                                "sigmoid": {
                                    "middle": 1,
                                    "width": 1
                                },
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "AspectReference",
                                        "aspectName": "Ds11b_leisure"
                                    }
                                }
                            },
                            {
                                "type": "filter",
                                "sigmoid": {
                                    "middle": 1,
                                    "width": 1
                                },
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "AspectReference",
                                        "aspectName": "Ds11b_health"
                                    }
                                }
                            },
                            {
                                "type": "filter",
                                "sigmoid": {
                                    "middle": 1,
                                    "width": 1
                                },
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "AspectReference",
                                        "aspectName": "Ds11b_financial"
                                    }
                                }
                            },
                            {
                                "type": "filter",
                                "sigmoid": {
                                    "middle": 1,
                                    "width": 1
                                },
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "AspectReference",
                                        "aspectName": "Ds11b_other"
                                    }
                                }
                            },
                            {
                                "type": "filter",
                                "sigmoid": {
                                    "middle": 1,
                                    "width": 1
                                },
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "AspectReference",
                                        "aspectName": "D-42b"
                                    }
                                }
                            }
                        ],
                        "anyToAll": 0.2
                    }
                ],
                "anyToAll": 0.3333333333333333
            }
        ],
        "anyToAll": 1
    },
    "mdd_p_severe_duration": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "mdd_p_severe_duration_hours_duty"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "mdd_p_severe_duration_days_duty"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "mdd_p_severe_duration_length"
                }
            }
        ],
        "anyToAll": 1
    },
    "mdd_p_severe_duration_hours_duty": {
        "type": "filter",
        "sigmoid": {
            "middle": 0.4,
            "width": 1
        },
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "D-2b"
            }
        }
    },
    "mdd_p_severe_duration_days_duty": {
        "type": "filter",
        "sigmoid": {
            "middle": 0.5,
            "width": 1
        },
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "D-3b"
            }
        }
    },
    "mdd_p_severe_duration_length": {
        "type": "filter",
        "sigmoid": {
            "middle": 14,
            "width": 1
        },
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "D-4b"
            }
        }
    },
    "mdd_pt_mild_A_symptoms": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_pt_mild_low_mood"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_pt_mild_anhedonia"
                        }
                    }
                ],
                "anyToAll": 1
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_pt_mild_weight_appetite"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_pt_mild_sleep"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_pt_mild_psychomotor"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_pt_mild_fatigue"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_pt_mild_worthless_guilt"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_pt_mild_concentration"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_pt_mild_death"
                        }
                    }
                ],
                "anyToAll": 0.2857142857142857
            }
        ],
        "anyToAll": 1
    },
    "mdd_pt_mild_low_mood": {
        "type": "filter",
        "sigmoid": {
            "middle": 0.5,
            "width": 1
        },
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "D-s1bt"
            }
        }
    },
    "mdd_pt_mild_anhedonia": {
        "type": "filter",
        "sigmoid": {
            "middle": 0.5,
            "width": 1
        },
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "D-s2bt"
            }
        }
    },
    "mdd_pt_mild_weight_appetite": {
        "type": "merge",
        "wires": [
            {
                "type": "equals",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-s5bt"
                    }
                },
                "value": 0.5
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 1,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-s5bt"
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "mdd_pt_mild_sleep": {
        "type": "filter",
        "sigmoid": {
            "middle": 1,
            "width": 1
        },
        "lowPass": true,
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "Ds4bt_none"
            }
        }
    },
    "mdd_pt_mild_psychomotor": {
        "type": "filter",
        "sigmoid": {
            "middle": 0.5,
            "width": 1
        },
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "D-s6bt"
            }
        }
    },
    "mdd_pt_mild_fatigue": {
        "type": "filter",
        "sigmoid": {
            "middle": 0.5,
            "width": 1
        },
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "D-s3bt"
            }
        }
    },
    "mdd_pt_mild_worthless_guilt": {
        "type": "filter",
        "sigmoid": {
            "middle": 0.75,
            "width": 1
        },
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "D-s7bt"
            }
        }
    },
    "mdd_pt_mild_concentration": {
        "type": "filter",
        "sigmoid": {
            "middle": 0.5,
            "width": 1
        },
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "D-s8bt"
            }
        }
    },
    "mdd_pt_mild_death": {
        "type": "filter",
        "sigmoid": {
            "middle": 0.5,
            "width": 1
        },
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "D-s9bt"
            }
        }
    },
    "mdd_pt_mild_B_impairment": {
        "type": "merge",
        "wires": [
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.5,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-s10bt"
                    }
                }
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "filter",
                        "sigmoid": {
                            "middle": 1,
                            "width": 1
                        },
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "Ds11bt_basic"
                            }
                        }
                    },
                    {
                        "type": "filter",
                        "sigmoid": {
                            "middle": 7,
                            "width": 1
                        },
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "D-42bt"
                            }
                        }
                    },
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "filter",
                                "sigmoid": {
                                    "middle": 1,
                                    "width": 1
                                },
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "AspectReference",
                                        "aspectName": "Ds11bt_basic"
                                    }
                                }
                            },
                            {
                                "type": "filter",
                                "sigmoid": {
                                    "middle": 1,
                                    "width": 1
                                },
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "AspectReference",
                                        "aspectName": "Ds11bt_work"
                                    }
                                }
                            },
                            {
                                "type": "filter",
                                "sigmoid": {
                                    "middle": 1,
                                    "width": 1
                                },
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "AspectReference",
                                        "aspectName": "Ds11bt_relationships"
                                    }
                                }
                            },
                            {
                                "type": "filter",
                                "sigmoid": {
                                    "middle": 1,
                                    "width": 1
                                },
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "AspectReference",
                                        "aspectName": "Ds11bt_social"
                                    }
                                }
                            },
                            {
                                "type": "filter",
                                "sigmoid": {
                                    "middle": 1,
                                    "width": 1
                                },
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "AspectReference",
                                        "aspectName": "Ds11bt_family"
                                    }
                                }
                            },
                            {
                                "type": "filter",
                                "sigmoid": {
                                    "middle": 1,
                                    "width": 1
                                },
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "AspectReference",
                                        "aspectName": "Ds11bt_leisure"
                                    }
                                }
                            },
                            {
                                "type": "filter",
                                "sigmoid": {
                                    "middle": 1,
                                    "width": 1
                                },
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "AspectReference",
                                        "aspectName": "Ds11bt_health"
                                    }
                                }
                            },
                            {
                                "type": "filter",
                                "sigmoid": {
                                    "middle": 1,
                                    "width": 1
                                },
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "AspectReference",
                                        "aspectName": "Ds11bt_financial"
                                    }
                                }
                            },
                            {
                                "type": "filter",
                                "sigmoid": {
                                    "middle": 1,
                                    "width": 1
                                },
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "AspectReference",
                                        "aspectName": "Ds11bt_other"
                                    }
                                }
                            },
                            {
                                "type": "filter",
                                "sigmoid": {
                                    "middle": 1,
                                    "width": 1
                                },
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "AspectReference",
                                        "aspectName": "D-42bt"
                                    }
                                }
                            }
                        ],
                        "anyToAll": 0.2
                    }
                ],
                "anyToAll": 0.3333333333333333
            }
        ],
        "anyToAll": 1
    },
    "mdd_pt_mild_duration": {
        "type": "merge",
        "wires": [
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.4,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-2bt"
                    }
                }
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.5,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-3bt"
                    }
                }
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 14,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-4bt"
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "mdd_pt_severe_A_symptoms": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_pt_mild_low_mood"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_pt_mild_anhedonia"
                        }
                    }
                ],
                "anyToAll": 1
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_pt_mild_weight_appetite"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_pt_mild_sleep"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_pt_mild_psychomotor"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_pt_mild_fatigue"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_pt_mild_worthless_guilt"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_pt_mild_concentration"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_pt_mild_death"
                        }
                    }
                ],
                "anyToAll": 0.7142857142857143
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_pt_severe_low_mood"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_pt_severe_anhedonia"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_pt_severe_weight_appetite"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_pt_severe_sleep"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_pt_severe_psychomotor"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_pt_severe_fatigue"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_pt_severe_worthless_guilt"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_pt_severe_concentration"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "mdd_pt_severe_death"
                        }
                    }
                ],
                "anyToAll": 0.3333333333333333
            }
        ],
        "anyToAll": 1
    },
    "mdd_pt_severe_low_mood": {
        "type": "filter",
        "sigmoid": {
            "middle": 1,
            "width": 1
        },
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "D-s1bt"
            }
        }
    },
    "mdd_pt_severe_anhedonia": {
        "type": "filter",
        "sigmoid": {
            "middle": 1,
            "width": 1
        },
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "D-s2bt"
            }
        }
    },
    "mdd_pt_severe_weight_appetite": {
        "type": "merge",
        "wires": [
            {
                "type": "equals",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-s5bt"
                    }
                },
                "value": 0.5
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 1,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-s5bt"
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "mdd_pt_severe_sleep": {
        "type": "filter",
        "sigmoid": {
            "middle": 1,
            "width": 1
        },
        "lowPass": true,
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "Ds4bt_none"
            }
        }
    },
    "mdd_pt_severe_psychomotor": {
        "type": "filter",
        "sigmoid": {
            "middle": 1,
            "width": 1
        },
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "D-s6bt"
            }
        }
    },
    "mdd_pt_severe_fatigue": {
        "type": "filter",
        "sigmoid": {
            "middle": 1,
            "width": 1
        },
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "D-s3bt"
            }
        }
    },
    "mdd_pt_severe_worthless_guilt": {
        "type": "filter",
        "sigmoid": {
            "middle": 1,
            "width": 1
        },
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "D-s7bt"
            }
        }
    },
    "mdd_pt_severe_concentration": {
        "type": "filter",
        "sigmoid": {
            "middle": 1,
            "width": 1
        },
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "D-s8bt"
            }
        }
    },
    "mdd_pt_severe_death": {
        "type": "filter",
        "sigmoid": {
            "middle": 1,
            "width": 1
        },
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "D-s9bt"
            }
        }
    },
    "mdd_pt_severe_B_impairment": {
        "type": "merge",
        "wires": [
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 1,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-s10bt"
                    }
                }
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "filter",
                        "sigmoid": {
                            "middle": 1,
                            "width": 1
                        },
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "Ds11bt_basic"
                            }
                        }
                    },
                    {
                        "type": "filter",
                        "sigmoid": {
                            "middle": 7,
                            "width": 1
                        },
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "D-42bt"
                            }
                        }
                    },
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "filter",
                                "sigmoid": {
                                    "middle": 1,
                                    "width": 1
                                },
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "AspectReference",
                                        "aspectName": "Ds11bt_basic"
                                    }
                                }
                            },
                            {
                                "type": "filter",
                                "sigmoid": {
                                    "middle": 1,
                                    "width": 1
                                },
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "AspectReference",
                                        "aspectName": "Ds11bt_work"
                                    }
                                }
                            },
                            {
                                "type": "filter",
                                "sigmoid": {
                                    "middle": 1,
                                    "width": 1
                                },
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "AspectReference",
                                        "aspectName": "Ds11bt_relationships"
                                    }
                                }
                            },
                            {
                                "type": "filter",
                                "sigmoid": {
                                    "middle": 1,
                                    "width": 1
                                },
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "AspectReference",
                                        "aspectName": "Ds11bt_social"
                                    }
                                }
                            },
                            {
                                "type": "filter",
                                "sigmoid": {
                                    "middle": 1,
                                    "width": 1
                                },
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "AspectReference",
                                        "aspectName": "Ds11bt_family"
                                    }
                                }
                            },
                            {
                                "type": "filter",
                                "sigmoid": {
                                    "middle": 1,
                                    "width": 1
                                },
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "AspectReference",
                                        "aspectName": "Ds11bt_leisure"
                                    }
                                }
                            },
                            {
                                "type": "filter",
                                "sigmoid": {
                                    "middle": 1,
                                    "width": 1
                                },
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "AspectReference",
                                        "aspectName": "Ds11bt_health"
                                    }
                                }
                            },
                            {
                                "type": "filter",
                                "sigmoid": {
                                    "middle": 1,
                                    "width": 1
                                },
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "AspectReference",
                                        "aspectName": "Ds11bt_financial"
                                    }
                                }
                            },
                            {
                                "type": "filter",
                                "sigmoid": {
                                    "middle": 1,
                                    "width": 1
                                },
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "AspectReference",
                                        "aspectName": "Ds11bt_other"
                                    }
                                }
                            },
                            {
                                "type": "filter",
                                "sigmoid": {
                                    "middle": 1,
                                    "width": 1
                                },
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "AspectReference",
                                        "aspectName": "D-42bt"
                                    }
                                }
                            }
                        ],
                        "anyToAll": 0.2
                    }
                ],
                "anyToAll": 0.3333333333333333
            }
        ],
        "anyToAll": 1
    },
    "mdd_pt_severe_duration": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "mdd_pt_severe_duration_hours_duty"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "mdd_pt_severe_duration_days_duty"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "mdd_pt_severe_duration_length"
                }
            }
        ],
        "anyToAll": 1
    },
    "mdd_pt_severe_duration_hours_duty": {
        "type": "filter",
        "sigmoid": {
            "middle": 0.4,
            "width": 1
        },
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "D-2bt"
            }
        }
    },
    "mdd_pt_severe_duration_days_duty": {
        "type": "filter",
        "sigmoid": {
            "middle": 0.5,
            "width": 1
        },
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "D-3bt"
            }
        }
    },
    "mdd_pt_severe_duration_length": {
        "type": "filter",
        "sigmoid": {
            "middle": 14,
            "width": 1
        },
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "D-4bt"
            }
        }
    },
    "has_current_lm": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "mdd_c_lm_symptoms"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "mdd_c_lm_duration"
                }
            }
        ],
        "anyToAll": 1
    },
    "mdd_c_lm_symptoms": {
        "type": "merge",
        "wires": [
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.5,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-1a"
                    }
                }
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.5,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-2a"
                    }
                }
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.5,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-5a"
                    }
                }
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 1,
                    "width": 1
                },
                "lowPass": true,
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D4a_none"
                    }
                }
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.5,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-6a"
                    }
                }
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.5,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-3a"
                    }
                }
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.5,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-7a"
                    }
                }
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.5,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-8a"
                    }
                }
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.5,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-9a"
                    }
                }
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.5,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-10a"
                    }
                }
            }
        ],
        "anyToAll": 0.4
    },
    "mdd_c_lm_duration": {
        "type": "merge",
        "wires": [
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.2,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-12a"
                    }
                }
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.33,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "D-13a"
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "mdd_c_lm_duration_days"
                }
            }
        ],
        "anyToAll": 1
    },
    "mdd_c_lm_duration_days": {
        "type": "filter",
        "sigmoid": {
            "middle": 30,
            "width": 1
        },
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "D-14a"
            }
        }
    },
    "D3_death": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "D-3-demo",
            "options": {
                "type": "severities",
                "severities": {
                    "4bcd0861-dcf1-4fb9-8573-923aaf3044f8": 1
                }
            }
        }
    },
    "D3_overwork": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "D-3-demo",
            "options": {
                "type": "severities",
                "severities": {
                    "bc6a5665-2d8e-45cc-9a5f-df22581d2abb": 1
                }
            }
        }
    },
    "D3_jobloss": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "D-3-demo",
            "options": {
                "type": "severities",
                "severities": {
                    "d34c911e-3ddf-4985-9ac1-d7ee3211ed74": 1
                }
            }
        }
    },
    "D3_illness": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "D-3-demo",
            "options": {
                "type": "severities",
                "severities": {
                    "7ba0702f-7585-4e7f-a3a3-9156e801289c": 1
                }
            }
        }
    },
    "D3_other": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "D-3-demo",
            "options": {
                "type": "severities",
                "severities": {
                    "8b2291a6-262c-4831-b10f-9bb9e3e21e6f": 1
                }
            }
        }
    },
    "D3_": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "D-3-demo",
            "options": {
                "type": "severities",
                "severities": {
                    "662267d6-b668-47fc-b4c5-0d4a4255bce1": 1
                }
            }
        }
    },
    "not(D-43bt-demo=0)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "D-43bt-demo",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "8995767f-a3f5-4f5f-aa30-ce4c0b48d1e6": true,
                        "4f823cae-c013-4f68-86ad-46b27927ec2b": false
                    }
                }
            }
        }
    }
};
exports.tests = {};
exports.questionPredicates = {
    "D-2bs-demo": "not(D-43bt-demo=0)",
    "D-3bs-demo": "not(D-43bt-demo=0)",
    "D-4bs-demo": "not(D-43bt-demo=0)"
};
