import React, { useEffect, useState, useContext } from 'react'
import { UserType } from 'censeo-core'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { Page, Text } from '@psyomics/components'
import Header from '../../components/Header'
import { useAuth } from '../../context/auth'
import { Auth } from 'aws-amplify'
import { useMixpanelEvent } from 'ui/app/useMixpanelEvent'
import Styles from './NhsSingleSignOnRedirect.module.scss'
import configure, { AuthType } from '../../../../src/configure'
import { useRegistration } from '../../context/registration/useRegistration'
import { MixpanelContext } from 'registration/context/mixpanel/mixpanel-context'

export const NhsSingleSignOnRedirect: React.FC = ({ ...props }) => {
    const { initializeUser } = useAuth()
    const navigate = useNavigate()
    const mixpanelApiUrl = useContext(MixpanelContext)
    const { accountCreated } = useMixpanelEvent(mixpanelApiUrl)
    const [nhsReferredState, setNhsReferredState] = useState('wait')
    const { clearRegistrationState, state: registrationState } = useRegistration()

    useEffect(() => {
        const updateAttributesAndRedirect = async () => {
            const user = await Auth.currentAuthenticatedUser()

            // TODO: CP-2007 User type from PG instead of cognito
            if (user && !user.attributes['custom:userType'] && UserType.Nhs !== registrationState?.userType) {
                setNhsReferredState('notNhsReferred')
                try {
                    const signOutQuery = '/signin?notNhsUser=true'
                    await configure(AuthType.PATIENT, signOutQuery)
                    await Auth.deleteUser()
                } catch (error) {
                    console.error('Error deleting user', error)
                }
                return
            }

            const attributesToUpdate: {
                'custom:termsAcceptedDate'?: string
                'custom:referralCode'?: string
                'custom:userType'?: string
            } = {}
            if (!user.attributes['custom:termsAcceptedDate']) {
                attributesToUpdate['custom:termsAcceptedDate'] = dayjs().toISOString()
                accountCreated(
                    registrationState?.organisationCode,
                    registrationState?.organisationName,
                    registrationState?.referralPublicId
                )
            }
            if (!user.attributes['custom:referralCode'] && registrationState?.referralCode) {
                attributesToUpdate['custom:referralCode'] = registrationState?.referralCode
            }
            if (!user.attributes['custom:userType'] && registrationState?.userType) {
                attributesToUpdate['custom:userType'] = registrationState?.userType
            }

            if (Object.keys(attributesToUpdate).length > 0) {
                await Auth.updateUserAttributes(user, attributesToUpdate)
            }

            await initializeUser()
            sessionStorage.removeItem('signup-form')
            clearRegistrationState()
            navigate('/')
        }

        updateAttributesAndRedirect()
    }, [])

    return (
        <Page>
            <Header template="landing" />
            <div className={'containerClass'} {...props}>
                {nhsReferredState !== 'notNhsReferred' && (
                    <Text size="large" color="mid" role="heading">
                        Please wait until you are redirected...
                    </Text>
                )}
                {nhsReferredState === 'notNhsReferred' && (
                    <div className={Styles.noUserContainer}>
                        <Text size="large" color="mid" role="heading">
                            We could not find an NHS account for you. You will be redirected...
                        </Text>
                    </div>
                )}
            </div>
        </Page>
    )
}
