import React, { useState, useEffect, ReactNode } from 'react'
import { MixpanelContext } from './mixpanel-context'
import { toAbsolutePath } from 'hostOverride'

interface MixpanelProviderProps {
    children: ReactNode
}

export const MixpanelProvider: React.FC<MixpanelProviderProps> = ({ children }) => {
    const [mixpanelApiUrl, setMixpanelApiUrl] = useState('')

    useEffect(() => {
        fetch(toAbsolutePath('/info'))
            .then((response) => response.json())
            .then((data) => setMixpanelApiUrl(data.mixpanelApiUrl))
            .catch((error) => console.error('Error:', error))
    }, [])

    return <MixpanelContext.Provider value={mixpanelApiUrl}>{children}</MixpanelContext.Provider>
}
