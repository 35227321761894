"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.nodePaths = exports.supplementalInfoQuestionIds = exports.START_ID = void 0;
const types_1 = require("../types");
exports.START_ID = 'A';
exports.supplementalInfoQuestionIds = ['SH-16a'];
exports.nodePaths = {
    A: {
        questionId: 'SH-s1',
        answers: {
            '519336e4-f9db-552e-a464-11ea704d6288': types_1.RagIds.rag5,
            '981f1af5-5769-5932-8778-58a6e01aa624': 'B',
        },
    },
    B: {
        questionId: 'SH-s3',
        answers: {
            'e2e1bae1-c85a-5ab1-9b76-10e31f65a40e': 'L',
            '29309292-72fb-5781-ae9a-973977aac3af': 'C',
        },
    },
    C: {
        questionId: 'SH-2a',
        answers: {
            '4992447e-a229-5af8-ba74-0d205735ec32': 'D',
            '35d92f8a-6922-5810-a862-81370a7bb8a5': 'D',
            'b1efc312-9d2e-5ca8-88ba-b4b2392d1bc8': 'D',
            'c81ca000-63f4-5d4d-b175-ed7c74561574': 'K',
            '5d85eb1d-b45b-51cb-9405-1a914df66de6': 'K',
            'fe25eeda-81fa-58a3-9c72-545aabe803f5': 'K',
        },
    },
    D: {
        questionId: 'SH-6a',
        answers: {
            '6618bab9-cf95-5c64-8182-e64a43ae5a5e': 'J',
            '9f789285-dcea-547c-8aa8-b37291bcfae2': types_1.RagIds.rag5,
            'a770529d-17f7-5b21-8f7b-3b508e9cb983': 'J',
            'bf613ed8-0c2a-5ac7-8b03-abfb31ce786b': 'J',
            'fe387d52-05f9-5b58-bd76-e284e0d87378': 'J',
            'f603cb36-6f7d-5511-a4ab-2f13f22a9f3e': 'J',
            'b28d2db7-a851-5734-bf54-0ad05544946b': 'J',
            'f67da8e8-7a80-5726-895d-710472c17bc0': 'J',
        },
    },
    F: {
        questionId: 'SH-16b',
        answers: {
            '7feeabee-bfe8-56cd-b16c-fd4199552577': 'G',
            'db90d397-9d20-53d9-86ee-b1290cf5610f': 'H',
            '4c4f089f-452e-5a64-ae76-c6aa84efe4eb': 'H',
        },
    },
    G: {
        questionId: 'SH-23b',
        answers: {
            'a5170112-22fd-5d89-96da-1f1d9abd6a28': types_1.RagIds.rag5,
            'fe7c8387-4f0e-5e3f-a41c-9789da592eae': types_1.RagIds.rag5,
            '47b9e301-af3c-5127-8396-1153c6696909': types_1.RagIds.rag4,
            'acaeb2ca-33f6-5651-aa86-2de5e3de9d9d': types_1.RagIds.rag4,
            'b3418704-a5d7-5927-87f8-23ae8b5ab663': types_1.RagIds.rag3,
        },
    },
    H: {
        questionId: 'SH-23b',
        answers: {
            'a5170112-22fd-5d89-96da-1f1d9abd6a28': types_1.RagIds.rag5,
            'fe7c8387-4f0e-5e3f-a41c-9789da592eae': types_1.RagIds.rag4,
            '47b9e301-af3c-5127-8396-1153c6696909': types_1.RagIds.rag3,
            'acaeb2ca-33f6-5651-aa86-2de5e3de9d9d': types_1.RagIds.rag2,
            'b3418704-a5d7-5927-87f8-23ae8b5ab663': types_1.RagIds.rag2,
        },
    },
    J: {
        questionId: 'SH-23a',
        answers: {
            'e5fa1386-2290-5c3f-990e-962ef0b4f10c': types_1.RagIds.rag4,
            '3e347ed1-7ea3-5b21-964f-32cf2d32e1b5': types_1.RagIds.rag3,
            '5738b269-a761-5967-a3a9-e5861936cc74': types_1.RagIds.rag3,
            '025fa831-b50c-51c6-8ece-7f851af56e4f': types_1.RagIds.rag2,
            '83e881fd-401e-5ce9-9121-1472f92adb82': types_1.RagIds.rag2,
        },
    },
    K: {
        questionId: 'SH-23a',
        answers: {
            'e5fa1386-2290-5c3f-990e-962ef0b4f10c': types_1.RagIds.rag5,
            '3e347ed1-7ea3-5b21-964f-32cf2d32e1b5': types_1.RagIds.rag4,
            '5738b269-a761-5967-a3a9-e5861936cc74': types_1.RagIds.rag4,
            '025fa831-b50c-51c6-8ece-7f851af56e4f': types_1.RagIds.rag3,
            '83e881fd-401e-5ce9-9121-1472f92adb82': types_1.RagIds.rag3,
        },
    },
    L: {
        questionId: 'SH-2b',
        answers: {},
        freeQuestion: [
            {
                rule: types_1.FreeQuestionRules.lessThanOrEqual,
                comparisonValue: 730,
                next: 'F',
            },
            {
                rule: types_1.FreeQuestionRules.greaterThan,
                comparisonValue: 730,
                next: 'G',
            },
        ],
    },
};
