import Auth from '@aws-amplify/auth'
import { toAbsolutePath } from './hostOverride'
import { createContext } from 'react'

export interface Infrastructure {
    userPoolId: string
    webClientId: string
    practitionerUserPoolId: string
    region: string
    monitoringEndpoint: string
    mixpanel: {
        token: string
        isDebug: boolean
    }
    referralApiUrl: string
    inboundApiUrl: string
    stripeServerApiUrl: string
    mixpanelApiUrl: string
    posthogProxyDomain: string
    avScanApiUrl: string
    practitionerUserPoolAppClientId: string
    practitionerUserPoolDomainName: string
    demoClientApp: {
        id: string
        secret: string
    }
    oauthNHS: {
        domain: string
        scope: string[]
        redirectSignIn: string
        redirectSignOut: string
        responseType: string
    }
    longDemoClientApp: {
        id: string
        secret: string
    }
    prolificUserPoolClientApp: {
        id: string
        secret: string
    }
    clinicianUserPoolInUse?: boolean
}

export interface Info {
    commitSha: string
    version: string | undefined
    appVersion: string | undefined
    environment: string
    date: number
    unauthenticatedIdentityPoolId: string
    unauthenticatedApiKey: string
}

export enum AuthType {
    PATIENT = 'PATIENT',
    PRACTITIONER = 'PRACTITIONER',
}
const configure = async (useAuthType = AuthType.PATIENT, signOutQuery?: string): Promise<Info & Infrastructure> => {
    const response = await fetch(toAbsolutePath('/info'))
    const info: Info & Infrastructure = await response.json()
    const patientAuth = {
        userPoolId: info.userPoolId,
        userPoolWebClientId: info.webClientId,
        region: info.region,
        oauth: info.oauthNHS,
    }

    if (patientAuth?.oauth?.redirectSignOut) {
        patientAuth.oauth.redirectSignOut = signOutQuery
            ? patientAuth.oauth.redirectSignOut + signOutQuery
            : patientAuth.oauth.redirectSignOut
    }

    replaceAmplifySensitiveQueryStringParams()

    const practitionerAuth = {
        userPoolId: info.practitionerUserPoolId,
        userPoolWebClientId: info.practitionerUserPoolAppClientId,
        region: info.region,
        oauth: {
            domain: info.practitionerUserPoolDomainName,
            scope: info.clinicianUserPoolInUse
                ? ['email', 'openid', 'aws.cognito.signin.user.admin', 'profile']
                : ['email', 'openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: `${window.location.origin}/practitioner-callback`,
            redirectSignOut: `${window.location.origin}/account/sign-out`,
            clientId: info.practitionerUserPoolAppClientId,
            responseType: 'token',
        },
    }

    const authConfig =
        useAuthType === AuthType.PATIENT
            ? patientAuth
            : useAuthType === AuthType.PRACTITIONER
            ? practitionerAuth
            : undefined

    await Auth.configure(authConfig)

    return info
}

export const InfoContext = createContext<(Info & Infrastructure) | undefined>(undefined)

function replaceAmplifySensitiveQueryStringParams() {
    // Amplify listens for the error and code param when using SSO and returns it to the default signin redirect page, nhs returns an url with and error param but we want to
    // handle it uniquely if error_description=ConsentNotGiven (+ more), need to bypass amplify for this particular url with this partucular query params.
    const url = window.location.href
    if (
        (url.includes('/federated-sign-in') || url.includes('/practitioner-callback')) &&
        (url.includes('&error=') || url.includes('?error='))
    ) {
        let newUrl = url.replaceAll('&error=', '&nhserror=')
        newUrl = newUrl.replaceAll('?error=', '?nhserror=')
        window.location.href = newUrl
    }
}

export default configure
