import { gql } from '@apollo/client'
import { DocumentNode } from 'graphql'
import { FetchJsonReportResult } from 'censeo-core'

export const fetchJsonReportQuery: DocumentNode = gql`
    query fetchJsonReport($id: ID!) {
        fetchJsonReport(id: $id) {
            message
            allExistingReports {
                assessmentId
                createdAt
            }
            reportDetails {
                completedDate
                conditions {
                    code
                    name
                    patientFacingName
                    isSevere
                    isSubClinical
                    seriousness
                    symptomsSections {
                        title
                        symptomsSubSections {
                            title
                            symptoms {
                                description
                            }
                        }
                        symptoms {
                            description
                        }
                    }
                }
                conditionsSummary {
                    code
                    displayName
                    patientFacingDisplayName
                    group
                    order
                }
                demographics {
                    email
                    givenName
                    familyName
                    birthdate
                    gender
                    sex
                    nhsNumber
                    userType
                    referralCode
                    nhsNumberTrust
                }
                keyProblems {
                    title
                    description
                }
                patientContext {
                    title
                    sections {
                        description
                        title
                    }
                }
                patientContextSummary {
                    name
                    group
                    order
                }
                risk {
                    score
                    outcomes {
                        score
                        name
                        friendlyName
                        code
                        symptomsSections {
                            title
                            symptomsSubSections {
                                title
                                symptoms {
                                    description
                                }
                            }
                            symptoms {
                                description
                            }
                        }
                    }
                }
                riskSummary {
                    score
                    outcomes {
                        code
                        friendlyName
                        name
                        score
                    }
                }
                identifierLogical
                isAuthorisedForPatient
                organisationConfig {
                    omitNhsLookup
                    patientReport
                }
            }
        }
    }
`

export interface FetchJsonReportData {
    fetchJsonReport: FetchJsonReportResult
}
