import Auth from '@aws-amplify/auth'
import { handleSignin, LoginParams } from '../SignIn/use-Signin'

export interface ConfirmParams extends LoginParams {
    code: string
}

export const handleConfirm = async (params: ConfirmParams): Promise<any> => {
    const emailLowercase = params.values.email.toLowerCase()
    await Auth.confirmSignUp(emailLowercase, params.code, { clientMetadata: { action: 'ConfirmSignUp' } })
    return await handleSignin(params)
}
