import React, { useState } from 'react'
import { Heading, Page } from '@psyomics/components'
import Content from 'registration/components/Content'
import Header from 'registration/components/Header'
import {
    ResetPasswordFormInputsInitialValues,
    ResetPasswordFormProps,
    ResetPasswordFormSchema,
} from './use-ResetPassword'
import { Formik } from 'formik'
import ResetPasswordEnterEmail from './ResetPasswordEnterEmail/ResetPasswordEnterEmail'
import ResetPasswordEnterCode from './ResetPasswordEnterCode/ResetPasswordEnterCode'
import ResetPasswordSuccess from './ResetPasswordSuccess/ResetPasswordSuccess'

type IResetPassword = React.ComponentPropsWithoutRef<'div'>
type PageState = 'enteremail' | 'entercode' | 'success'

const ResetPassword: React.FC<IResetPassword> = () => {
    const [pageState, setPageState] = useState<PageState>('enteremail')
    const formInitialValues = ResetPasswordFormInputsInitialValues
    const formSchema = ResetPasswordFormSchema

    return (
        <Page>
            <Header template="landing" />
            <Content>
                <Heading el="h2" size="heading2" color="mid" css={{ mb: 4 }}>
                    Forgotten your password?
                </Heading>

                <Formik initialValues={formInitialValues} validationSchema={formSchema} onSubmit={() => undefined}>
                    {(formikProps) => (
                        <form onSubmit={formikProps.handleSubmit}>
                            <View formikProps={formikProps} pageState={pageState} setPageState={setPageState} />
                        </form>
                    )}
                </Formik>
            </Content>
        </Page>
    )
}

type ViewProps = ResetPasswordFormProps & { pageState: PageState } & React.ComponentPropsWithoutRef<'div'>

const View: React.FC<ViewProps> = ({ formikProps, pageState, setPageState }) => {
    switch (pageState) {
        case 'enteremail':
            return (
                <React.Suspense fallback={<p>loading...</p>}>
                    <ResetPasswordEnterEmail formikProps={formikProps} setPageState={setPageState} />
                </React.Suspense>
            )
        case 'entercode':
            return (
                <React.Suspense fallback={<p>loading...</p>}>
                    <ResetPasswordEnterCode formikProps={formikProps} setPageState={setPageState} />
                </React.Suspense>
            )
        case 'success':
            return (
                <React.Suspense fallback={<p>loading...</p>}>
                    <ResetPasswordSuccess />
                </React.Suspense>
            )
        default:
            return <></>
    }
}

export default ResetPassword
