import { Option } from 'censeo-core'
import React, { useState } from 'react'
import QuestionPage from 'ui/app/components/QuestionPage'
import { ChoiceItem, Markdown } from '@psyomics/components'
import useEventListener from '@use-it/event-listener'
import Validation from 'registration/components/Validation'

interface Props {
    options: Option[]
    selectedOptionIds: string[]
    onSelect: (optionIds: string[]) => void
    onBack: () => void
    question: string
    isMultiselect?: boolean
    updating?: boolean
}

const alphabet = 'abcdefghijklmnopqrstuvwxyz0123456789'.split('')

export const MultipleChoice: React.FC<Props> = ({
    question,
    selectedOptionIds,
    options,
    onSelect,
    onBack,
    isMultiselect,
    updating = false,
}: Props) => {
    const [value, setValue] = useState<string[]>(selectedOptionIds)
    const isInformational = options.length === 1
    const okDisabled = updating || (!isInformational && value.length === 0)
    const isAutoNext = !isMultiselect && !isInformational
    const [nextPressed, setNextPressed] = useState(false)
    const [isValidCombination, setIsValidCombination] = useState(true)

    const handleOk = () => {
        setNextPressed(true)
        if (isValidCombination) isInformational ? onSelect([options[0].id]) : value && onSelect(value)
    }

    const updateSelected = (clickedId: string) => {
        if (!isMultiselect) {
            if (isAutoNext) {
                // ALWAYS set to the selected ID if we're about to go next, otherwise we go next with []
                return [clickedId]
            }
            return value.includes(clickedId) ? [] : [clickedId]
        }

        if (value.includes(clickedId)) {
            return value.filter((selectedId) => selectedId !== clickedId)
        }

        const clickedOption = options.find((o) => o.id === clickedId)
        if (clickedOption?.exclusive) {
            return [clickedId]
        } else {
            // todo: this is tiny bit expensive - be nice if the value wasn't just ids...
            const selectedOptions = value.map((v) => options.find((o) => o.id === v))
            const selectedExclusives = selectedOptions.filter((o) => o?.exclusive)
            const newValue = value.filter((v) => !selectedExclusives.find((e) => e?.id === v))
            return [...newValue, clickedId]
        }
    }

    const handleSelectionChange = (clickedId: string) => {
        const updatedvalue = updateSelected(clickedId)
        setValue(updatedvalue)
        updateIsValidCombination(updatedvalue)
        if (isAutoNext) {
            onSelect(updatedvalue)
        }
    }

    const updateIsValidCombination = (value: string[]) => {
        const selectedOptions = value.map((v) => options.find((o) => o.id === v))
        const anyExclusive = !!selectedOptions.find((o) => o?.exclusive)
        const numSelected = value.length
        const noneAndMoreSelected = anyExclusive && numSelected > 1

        setIsValidCombination(!noneAndMoreSelected)
    }

    const exclusiveAnswersText = () => {
        const exclusiveOpts = options.filter((o) => o.exclusive)

        if (!exclusiveOpts?.length) {
            return
        }
        if (exclusiveOpts?.length === 1) {
            return exclusiveOpts[0]
        }
        return `${exclusiveOpts
            .map((o) => o.text)
            .slice(0, -1)
            .join(', ')} or ${exclusiveOpts[exclusiveOpts.length - 1].text}`
    }

    useEventListener('keypress', (keyPress: KeyboardEvent) => {
        const keyIndex = typeof keyPress.key === 'string' ? alphabet.indexOf(keyPress.key.toLowerCase()) : -1

        if (keyIndex >= 0 && keyIndex < options.length) {
            handleSelectionChange(options[keyIndex].id)
        }
    })

    return (
        <QuestionPage
            isInformational={isInformational}
            onOk={handleOk}
            okVisible={!isAutoNext}
            okDisabled={okDisabled}
            backDisabled={updating}
            updating={updating}
            onBack={onBack}
        >
            <Markdown color="mid" css={{ mb: 4 }}>
                {question}
            </Markdown>
            {isInformational
                ? null
                : options.map((option, i) => (
                      <ChoiceItem
                          css={{ mt: 2 }}
                          selected={value.includes(option.id)}
                          fullWidth={true}
                          selectMultiple={false}
                          label={option.text}
                          shortcutKey={alphabet[i] || ' '}
                          key={option.id}
                          onClick={() => handleSelectionChange(option.id)}
                          readonly={updating}
                      />
                  ))}
            {!isValidCombination && nextPressed && (
                <Validation>You cannot select other options with {String(exclusiveAnswersText())}.</Validation>
            )}
        </QuestionPage>
    )
}
