"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.symptoms = void 0;
exports.symptoms = [
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Sleeping difficulties {} the main reason for seeking help",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "In-19",
                    "optionSummaries": {
                        "78bc4ce9-eede-5e71-8f2f-ccc07e727c3d": "are not",
                        "96213629-1187-52b2-9e7a-a0c851bcbe0e": "are"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Additional symptoms and details"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} another medical condition or take medication that may affect sleep",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "In-18",
                    "optionSummaries": {
                        "8926a3e0-4939-57d9-ab63-21eaa6cdbfaf": "Does not have",
                        "7260a0f8-9cca-5761-b31d-bdcf0b07be65": "Does have",
                        "7249e1aa-bac0-5e81-9189-841edcfa568c": "Not sure if they have"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Additional symptoms and details"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} with another sleep disorder like sleep apnoea, narcolepsy or parasomnia",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "In-17",
                    "optionSummaries": {
                        "4b22dd2f-c083-5b47-89c2-536f0e141377": "Not diagnosed",
                        "85c708df-eaf0-5749-ab43-08470798a3f9": "Diagnosed"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Additional symptoms and details"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Affected {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "In-9",
                    "optionSummaries": {
                        "06603bb4-61d6-5865-ba16-bfd5ace66da2": "1–2 nights per week",
                        "50e6662a-fe0d-5151-8d7f-96a4e52e3756": "3–4 nights per week",
                        "f494b02f-9ac9-5ae9-94b9-aea01e773c36": "5–6 nights per week",
                        "8c6f7f1c-ae12-52b6-bb87-867f7be40803": "every night"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Duration of symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "In-10"
            ],
            "prefix": "Sleep problems have lasted for ",
            "formatText": "{}"
        },
        "section": [
            "Current episode",
            "Duration of symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "In-8"
            ],
            "prefix": "Hours sleep on an average night ",
            "formatText": "{}"
        },
        "section": [
            "Current episode",
            "Duration of symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} problems with sleeping at night, waking up during the night or in the morning",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "In-1",
                    "optionSummaries": {
                        "a06e5af9-7423-5a52-9f90-b813c26565cd": "slight",
                        "aef2d8b9-cf9f-56c2-ad48-e1ebd632ca77": "significant",
                        "ee98184e-21e5-5ba1-9200-93b8b793d5b0": "severe"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Sleep-related symptoms and details"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "In-2",
                    "optionSummaries": {
                        "3eac8bff-c7ef-590d-b79e-6a34771e0384": "no external circumstances affecting ability to sleep",
                        "7a5cbb84-cf16-51e0-84b7-e4723c916836": "external circumstances affecting ability to sleep: lack of time or opportunity",
                        "28bb9c4d-6d74-5d8c-93fa-6bb1d018bccb": "external circumstances affecting ability to sleep: inadequate sleeping conditions"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Sleep-related symptoms and details"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} dissatisfied with sleep quality",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "In-3",
                    "optionSummaries": {
                        "9301153f-717c-5274-ba9d-30ef58506872": "slightly",
                        "1fdf222e-20cd-55e5-84c5-fdb29cb8c10a": "moderately",
                        "1f2fa891-03ee-520d-85f2-0effd7ed4929": "very",
                        "49e95f65-9b56-5793-b77f-cf84c5edde1c": "severely"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Sleep-related symptoms and details"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} takes more than 30 minutes to fall asleep",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "In-4",
                    "optionSummaries": {
                        "6356b6e1-c3d1-5625-921b-02ed5e7906f1": "sometimes",
                        "008a928e-e359-5012-9ab5-7f10f55cba4e": "often",
                        "b2ed5b66-1b5f-5103-905d-93cc7cc24b29": "almost always or always"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Sleep-related symptoms and details"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} wakes during the night",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "In-5",
                    "optionSummaries": {
                        "e1280b2b-4883-55d4-9fde-006cb7a9ff65": "sometimes",
                        "4632255c-da2c-5ecd-8625-5a11c077f318": "often",
                        "0c2db685-4d19-5af4-a79f-b493e2f1b5dd": "almost always or always"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Sleep-related symptoms and details"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} wakes up more than 30 minutes early",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "In-7",
                    "optionSummaries": {
                        "0f9486d2-6653-5883-81af-6639d6b2864a": "sometimes",
                        "89ae5db3-64e0-583c-9851-2bb2aca93eed": "often",
                        "9582d3c5-0593-5b97-8919-1a3c8ba4969f": "almost always or always"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Sleep-related symptoms and details"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "lack of sleep {} affects concentration, memory or attention span",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "In-15",
                    "optionSummaries": {
                        "8369b683-4933-57db-b8ca-4bc363efebb3": "soemtimes",
                        "eaa71b08-9994-5cd6-a747-dbc9c7028d41": "quite often",
                        "768d36b2-99f6-52d5-828e-ac4aa7c1af98": "very often",
                        "fed858ba-a9ce-5ca0-8fe3-53947fa72a39": "always"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Additional symptoms and details"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{}emotionally distressed due to sleep problems",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "In-11",
                    "optionSummaries": {
                        "dbc05e91-2751-5ca2-a9a6-d500e05aac76": "slightly ",
                        "c6a59208-d315-55f8-bf9a-7858994cb083": "moderately ",
                        "93a6f63e-0a2a-56ab-8cb7-35aaa2472713": "very ",
                        "f1537ed3-6bcd-5fef-924c-804b5c18536a": "extremely "
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Additional symptoms and details"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{}uses alcohol or over-the-counter/unprescribed medication to cope with sleep problems",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "In-16",
                    "optionSummaries": {
                        "38000a7d-95f8-509b-a2fc-645d6c241769": "sometimes ",
                        "70cbb18e-77a3-51ec-8d9c-48d624956d47": "quite often ",
                        "ab1bdce5-400a-567d-a0ff-92fe1fc099d2": "very often ",
                        "b7e4e182-a94b-5d50-b7f2-cc8e323ae638": "extremely often "
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Additional symptoms and details"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{}falls asleep unintentionally",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "In-12",
                    "optionSummaries": {
                        "54e3b956-9bed-500e-8688-61a31c2cf50c": "sometimes ",
                        "663a518d-aa06-511e-b86b-bdd379c2df9c": "quite often ",
                        "cff540a8-01a6-5fd5-9549-11e45e4f293a": "very often ",
                        "199c9da9-7dd8-5667-baa6-a45176b4661c": "extremely often "
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Additional symptoms and details"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{}fallen asleep in dangerous situations",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "In-13",
                    "optionSummaries": {
                        "d9aa763a-58bc-583a-9b2f-c32f61474f83": "sometimes ",
                        "14131069-85b9-5bb8-be85-e0ddd452a5a9": "quite often ",
                        "3727c048-d68f-5b27-955f-9fde9d11b57c": "very often ",
                        "2af66c9b-bab3-5448-bbcc-1c124ff4d276": "extremely often "
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Additional symptoms and details"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{}concerned with lack of sleep and how it may affect them",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "In-14",
                    "optionSummaries": {
                        "66324493-9e40-5b6e-a1e2-218684881c4b": "slightly ",
                        "1411fb90-e50a-5075-a1df-655689e3e23a": "moderately ",
                        "cc16c77f-e49a-5238-aa1d-e3cc4844a8b5": "very ",
                        "3052c6fe-22a4-5451-818a-eb6819d8572c": "extremely "
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Additional symptoms and details"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Overall level of impairment: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "In-20.0",
                    "optionSummaries": {
                        "f358d4ad-61fb-50d6-9e7b-96f74c083255": "none",
                        "c5b2f626-3dbd-5274-b94a-80038bb354ec": "mild",
                        "94fb5213-cd49-543d-a957-4b7cb2b0a384": "moderate",
                        "6227fa46-142f-57ce-85d8-c558a73f98a8": "significant",
                        "bdacf2ac-3084-55f2-9c3d-5f7a86fbc4ab": "severe"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Functional impairment"
        ]
    },
    {
        "answerSummary": {
            "type": "MultiOptionSummary",
            "questionId": "In-20",
            "title": "",
            "overrides": {
                "a176e73d-22fd-541f-98df-d9dc34dc5748": "Other (not listed)",
                "252e68b2-7776-4d91-aded-fa8334f1a7cb": "None reported"
            }
        },
        "section": [
            "Current episode",
            "Areas of impairment"
        ]
    }
];
