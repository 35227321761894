const debounce = (cb: () => unknown, wait: number): (() => void) => {
    let onGoing = false

    return () => {
        if (!onGoing) {
            onGoing = true

            // Run callback function
            cb()

            // Wait for specified delay before running callback again
            setTimeout(() => {
                onGoing = false
            }, wait)
        }
    }
}

export default debounce
