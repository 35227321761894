import React from 'react'
import { Page, Text, Link } from '@psyomics/components'
import Header from 'registration/components/Header'
import { classNameMaker } from 'ui/utils'
import Styles from './QuickSilvaError.module.scss'

const QuickSilvaError: React.FC = () => {
    const containerClass: string = classNameMaker({
        [Styles.container]: true,
    })

    return (
        <Page>
            <Header template="landing" />
            <div className={containerClass}>
                <Text size="large" color="mid" role="heading">
                    Create referral unexpected error
                </Text>
            </div>
            <Text css={{ mt: 5 }} style={{ texAlign: 'center' }}>
                Sorry, we were unable to load the patient details
                <br />
                If this problem persists, please get in touch with us at{' '}
                <Link href="mailto:support@censeo.co.uk">support@censeo.co.uk</Link> if the problem continues.
            </Text>
        </Page>
    )
}

export default QuickSilvaError
