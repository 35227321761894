import React from 'react'
import Styles from './NhsButton.module.scss'
import { Auth } from 'aws-amplify'

const NhsButton: React.FC<{ demo?: boolean }> = ({ demo }) => {
    return (
        <div className={Styles.container}>
            <svg
                className={Styles.nhsButton}
                onClick={() => {
                    // TODO add mixpanel event
                    Auth.federatedSignIn({
                        customProvider: 'nhs-identity-provider',
                    })
                }}
                role="button"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 343.48 59"
            >
                <defs>
                    <style
                        dangerouslySetInnerHTML={{
                            __html: '.cls-1{fill:#003087;}.cls-2{fill:#005eb8;}.cls-3{fill:#fff;}',
                        }}
                    />
                </defs>
                <title>Continue with NHS</title>
                <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                        <rect className="cls-1" y={4} width="343.48" height={55} rx={4} />
                        <rect className="cls-2" width="343.48" height={55} rx={4} />
                        <rect className="cls-2" x={2} y={2} width="339.63" height={51} />
                        <rect className="cls-2" x="101.05" y={14} width="224.58" height={27} />
                        <path
                            className="cls-3"
                            d="M112.38,33.89a13.83,13.83,0,0,1-3.06.42c-4.6,0-7.37-2.38-7.37-6.71,0-4.54,2.75-7.18,7.37-7.18a8.78,8.78,0,0,1,3,.56l-.19,2.27a5.51,5.51,0,0,0-2.9-.71c-2.72,0-4.51,2.06-4.51,4.87s1.77,4.79,4.56,4.79a7.6,7.6,0,0,0,3-.56Z"
                        />
                        <path
                            className="cls-3"
                            d="M118.68,34.31a4.89,4.89,0,0,1-5.18-5.12,5.19,5.19,0,1,1,10.37,0A4.89,4.89,0,0,1,118.68,34.31Zm2.5-5.42c0-1.58-.85-2.95-2.5-2.95s-2.48,1.39-2.48,2.95c0,2.09.8,3.42,2.48,3.42S121.18,31,121.18,28.89Z"
                        />
                        <path
                            className="cls-3"
                            d="M132.42,34.08V28.52c0-1.48-.31-2.58-1.67-2.58-1.64,0-2.27,1.58-2.27,3.33v4.81H125.9V24.17h2.44v1.35h0a3.7,3.7,0,0,1,3.25-1.58c2.27,0,3.37,1.56,3.37,3.89v6.25Z"
                        />
                        <path
                            className="cls-3"
                            d="M143.55,34.08a6.56,6.56,0,0,1-1.89.23,2.92,2.92,0,0,1-3.16-3.21v-5h-1.9V24.17h1.9v-2l2.58-.83v2.81h2.29v1.89h-2.29v4.6c0,1,.33,1.65,1.23,1.65a2.21,2.21,0,0,0,1.16-.27Z"
                        />
                        <path className="cls-3" d="M145.2,22.36V19.9h2.58v2.46Zm0,11.72V24.17h2.58v9.91Z" />
                        <path
                            className="cls-3"
                            d="M157,34.08V28.52c0-1.48-.31-2.58-1.67-2.58-1.64,0-2.28,1.58-2.28,3.33v4.81H150.5V24.17h2.44v1.35h0a3.69,3.69,0,0,1,3.25-1.58c2.27,0,3.37,1.56,3.37,3.89v6.25Z"
                        />
                        <path
                            className="cls-3"
                            d="M168.91,34.08V32.74h0a4.17,4.17,0,0,1-3.26,1.57c-2.21,0-3.36-1.48-3.36-3.88V24.17h2.58v5.66c0,2,.73,2.48,1.67,2.48,1.56,0,2.27-1.44,2.27-3.35V24.17h2.58v9.91Z"
                        />
                        <path
                            className="cls-3"
                            d="M182,33.52a6.76,6.76,0,0,1-3.38.79c-3.29,0-5.2-1.88-5.2-5.13,0-2.78,1.45-5.24,4.6-5.24,3.37,0,4.72,2.08,4.72,6h-6.85a2.58,2.58,0,0,0,2.84,2.5,6.66,6.66,0,0,0,3.27-1Zm-1.73-5.36c-.09-1.43-.77-2.33-2.11-2.33a2.23,2.23,0,0,0-2.27,2.33Z"
                        />
                        <path
                            className="cls-3"
                            d="M199.56,34.08l-2.16-7.56h0l-2.11,7.56H192.3L189,24.17h2.74l2.19,7.33h0l2-7.33h3l2.16,7.33h0l2.06-7.33h2.47l-3,9.91Z"
                        />
                        <path className="cls-3" d="M207.24,22.36V19.9h2.58v2.46Zm0,11.72V24.17h2.58v9.91Z" />
                        <path
                            className="cls-3"
                            d="M218.42,34.08a6.56,6.56,0,0,1-1.89.23,2.92,2.92,0,0,1-3.15-3.21v-5h-1.91V24.17h1.91v-2l2.58-.83v2.81h2.29v1.89H216v4.6c0,1,.32,1.65,1.23,1.65a2.2,2.2,0,0,0,1.15-.27Z"
                        />
                        <path
                            className="cls-3"
                            d="M226.54,34.08v-5.6c0-1.81-.54-2.54-1.67-2.54-1.52,0-2.27,1.39-2.27,3.35v4.79H220V19.65h2.58v5.87h0a3.68,3.68,0,0,1,3.11-1.58c2.29,0,3.37,1.6,3.37,3.87v6.27Z"
                        />
                        <path
                            className="cls-3"
                            d="M245,34.08l-5.14-10h0v10h-2.58V20.65h3.31l5.12,10.05h0v-10h2.58V34.08Z"
                        />
                        <path
                            className="cls-3"
                            d="M259.34,34.08V28.19H254v5.89h-2.69V20.65H254v5.43h5.39V20.65H262V34.08Z"
                        />
                        <path
                            className="cls-3"
                            d="M265.18,31.37a6.49,6.49,0,0,0,3,.83c1,0,2.38-.5,2.38-1.74,0-1-.9-1.46-2.23-2-1.79-.69-3.66-1.44-3.66-4,0-2.89,2.18-4.1,4.72-4.1a10,10,0,0,1,3.19.48l-.25,2.21a7,7,0,0,0-2.73-.57,1.87,1.87,0,0,0-2.12,1.86c0,1,1.16,1.41,2.27,1.85,1.81.67,3.62,1.5,3.62,4s-1.85,4.06-5.06,4.06a11.7,11.7,0,0,1-3.42-.52Z"
                        />
                        <path className="cls-3" d="M281,34.08V19.65h2.57V34.08Z" />
                        <path
                            className="cls-3"
                            d="M290.87,34.31a4.9,4.9,0,0,1-5.18-5.12,5.19,5.19,0,1,1,10.37,0A4.89,4.89,0,0,1,290.87,34.31Zm2.5-5.42c0-1.58-.85-2.95-2.5-2.95s-2.48,1.39-2.48,2.95c0,2.09.81,3.42,2.48,3.42S293.37,31,293.37,28.89Z"
                        />
                        <path
                            className="cls-3"
                            d="M304.61,32.5h0a3.52,3.52,0,0,1-3.08,1.58c-2.73,0-4-2.08-4-5,0-2.39,1.24-5.14,4.2-5.14a3.28,3.28,0,0,1,3,1.58h0V24.17h2.46v9c0,3.35-1.73,5.19-5.2,5.19a8.68,8.68,0,0,1-3.46-.67l.21-2.19a7.34,7.34,0,0,0,2.91.86c2.42,0,3-1.56,3-3.61Zm0-3.48c0-1.52-.54-3.08-2.17-3.08s-2.29,1.33-2.29,3.14c0,1.35.71,3,2.19,3C304,32.08,304.61,30.62,304.61,29Z"
                        />
                        <path className="cls-3" d="M309.91,22.36V19.9h2.58v2.46Zm0,11.72V24.17h2.58v9.91Z" />
                        <path
                            className="cls-3"
                            d="M321.72,34.08V28.52c0-1.48-.3-2.58-1.67-2.58-1.64,0-2.27,1.58-2.27,3.33v4.81H315.2V24.17h2.44v1.35h0a3.7,3.7,0,0,1,3.25-1.58c2.27,0,3.37,1.56,3.37,3.89v6.25Z"
                        />
                        <polygon
                            className="cls-2"
                            points="18.54 14.54 18.54 40.46 84.42 40.46 84.42 14.54 18.54 14.54 18.54 14.54"
                        />
                        <path
                            className="cls-3"
                            d="M85.05,41V14H18V41ZM45,16.61,40.33,38.34H33.07l-4.57-15h-.06l-3,15H19.89l4.67-21.73h7.28l4.48,15.07h.06l3.07-15.07Zm20.77,0L61.2,38.34H55.35L57.29,29H50.37l-1.94,9.31H42.58l4.54-21.73H53l-1.72,8.31h6.91l1.72-8.31Zm16.81.59-1.41,4.33a11,11,0,0,0-4.82-1c-2.31,0-4.19.34-4.19,2.09,0,3.08,8.48,1.93,8.48,8.53,0,6-5.6,7.56-10.67,7.56a24.58,24.58,0,0,1-6.76-1.12l1.38-4.42a11.37,11.37,0,0,0,5.38,1.25c1.81,0,4.66-.35,4.66-2.59,0-3.49-8.48-2.18-8.48-8.31,0-5.61,4.94-7.29,9.73-7.29a17.91,17.91,0,0,1,6.7,1Z"
                        />
                    </g>
                </g>
            </svg>
        </div>
    )
}

export default NhsButton
