const dateFilter = (from: string | null, to: string | null, rowValue?: string): boolean => {
    if (!rowValue || isNaN(Date.parse(rowValue))) return false
    const rowDate = new Date(rowValue)

    if (from) {
        if (rowDate < new Date(from)) {
            return false
        }
    }
    if (to) {
        const d = new Date(to)
        d.setDate(d.getDate() + 1)
        if (rowDate >= d) {
            return false
        }
    }
    return true
}
export const filteringFunctions = {
    assessmentCompletedAt: dateFilter,
    referralCreatedAt: dateFilter,
} as const
