"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.symptoms = void 0;
exports.symptoms = [
    {
        "answerSummary": {
            "type": "MultiOptionSummary",
            "questionId": "LR38",
            "title": "Recent major life event(s)",
            "overrides": {
                "d6e1b247-4aa2-55fd-bce7-42c7fa8cea51": "Major illness or injury",
                "e632ac5a-ad6f-58ad-8e51-f5e957428c4b": "other (not listed)"
            }
        },
        "section": []
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "AD-6"
            ],
            "prefix": "occurred ",
            "formatText": "{}"
        },
        "section": [
            "Recent major life event(s)"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "AD-7"
            ],
            "prefix": "negatively affected for ",
            "formatText": "{}"
        },
        "section": [
            "Recent major life event(s)"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "AD-1",
                    "optionSummaries": {
                        "edce7c20-cd14-5a0e-8967-7b9ddfd67902": "never thinks about the event(s)",
                        "e1d660f1-6645-5b81-a34e-fefc52e9d032": "occasionally thinks about the event(s) but this is not affecting their wellbeing",
                        "cc006783-1c90-5563-b82b-27569e53841b": "frequent thoughts about the event(s) are affecting their wellbeing",
                        "5f86c500-1e25-5d62-b1e2-c5eb2f1a303d": "constant thoughts about the event(s) are severely affecting their wellbeing"
                    }
                }
            ]
        },
        "section": [
            "Symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} struggles to concentrate",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "AD-2",
                    "optionSummaries": {
                        "31a917bd-12c0-5383-a1ec-39d93cde0f4a": "never",
                        "cfd73f84-24e9-535b-ada3-99cb5889fdeb": "rarely",
                        "146614a4-8f78-5b23-9023-00289d053afe": "sometimes",
                        "20477c57-d15a-5aa2-8dd0-fc9d6e1f06e3": "often",
                        "891529c7-6886-545f-a95f-0170801469c4": "always"
                    }
                }
            ]
        },
        "section": [
            "Symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} intrusive thoughts of the event(s)",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "AD-3",
                    "optionSummaries": {
                        "112416fc-c866-5dfa-aa0d-a1fbb099631b": "no",
                        "84a7ab1a-66e3-555f-a9fb-f5d2052e62c5": "rare",
                        "ce4ee6e1-3416-5740-a7b4-c0e48c96d697": "occasional",
                        "9494d279-dd58-5706-b368-d083f8e96425": "frequent",
                        "e6ce6121-8966-5331-9de6-225c495a82f4": "constant"
                    }
                }
            ]
        },
        "section": [
            "Symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} unable to carry out everyday tasks",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "AD-4",
                    "optionSummaries": {
                        "2e503b7c-e5fe-5c3a-b406-1ed4a20a0403": "never",
                        "52680975-884a-5bef-9738-98b9d8d16f7a": "rarely",
                        "fb98be42-399a-57a7-a353-1581ef3b84c7": "sometimes",
                        "dc26ea00-568b-5208-bb47-a3a3167b2219": "often",
                        "0cec152e-1e7f-5ba8-ab55-4d35a78cf0ea": "always"
                    }
                }
            ]
        },
        "section": [
            "Symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "finds the event(s) {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "AD-5",
                    "optionSummaries": {
                        "377912ba-72cd-571f-acee-44c2bc8b2363": "as distressing as other people would",
                        "9d918248-bec6-57d3-bf94-b0f54531d598": "slightly more distressing than other people would",
                        "614d3969-b25d-5bd8-84b9-d168fe0c4e43": "much more distressing than other people would"
                    }
                }
            ]
        },
        "section": [
            "Symptoms"
        ]
    }
];
