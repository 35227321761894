"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.questionPredicates = exports.tests = exports.aspects = exports.questions = void 0;
const censeo_1 = require("../../schema/generated/censeo");
exports.questions = [
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "805a9685-9fd4-55de-94cd-4a19dd4107b8",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "263b64b2-8e44-59b6-8761-9556068db3c2",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "OCD-s1",
        "text": "Have you ever had a time when you were bothered by repetitive unpleasant thoughts, images  or impulses that you were unable to stop or control?\n\nUnwanted thoughts, images, or impulses that repeatedly enter your mind are called obsessions  and can be very distressing. Examples of obsessions include: worrying about germs or contamination,  needing things to be arranged or ordered in a certain way, or thoughts that others might see as wrong.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "6581df20-1f12-5563-9671-fd51c07fd098",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "934f0a07-8342-52aa-9c61-ec87c8d7b6b9",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "OCD-s1.5",
        "text": "Have you been experiencing obsessions in the last 6 months?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "91fad731-ad66-57f3-87fd-70f4246e63ed",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "OCD-s2",
        "text": "Have you ever felt the urge to perform repetitive behaviours or mental acts?\n\nThese are called compulsions, where you feel repeatedly driven to perform certain  physical or mental actions. Compulsions may temporarily relieve distressing thoughts,  feelings or impulses associated with obsessions. Examples of compulsions include:  repeated hand washing, checking, counting or repeating words to prevent anxiety or  something bad happening.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "786ef202-eae2-58ab-bec2-7bbe1746fd5c",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "f543076e-83c3-56df-9539-f976a57d2fac",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "OCD-s2.5",
        "text": "Have you been experiencing compulsions in the last 6 months?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "73175241-22a6-5d30-8d75-ae6a9f0851e3",
                "text": "No, not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "95603b88-c05c-5344-ab26-5d338cd499ff",
                "text": "A small amount of distress or anxiety",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "76162037-755b-57b1-93d4-4f9ab77522dc",
                "text": "A fair bit of distress or anxiety",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "42716cc5-7285-5b4e-9d65-4be48e7606b1",
                "text": "A lot of distress or anxiety",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "d1a809d8-8068-5778-a74f-a8f209fff96e",
                "text": "An extreme amount of distress or anxiety",
                "value": 1
            }
        ],
        "id": "OCD-7a",
        "text": "Do these obsessions cause you a lot of distress or anxiety?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "940dca6f-6249-503f-80e8-19590e269d2e",
                "text": "Not distressed at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "84d8ca85-4007-5557-aa20-b6f3b32a5819",
                "text": "A small amount of distress or anxiety",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "d1f530a9-4ad1-5d89-8d08-2d819227639b",
                "text": "A fair bit of distress or anxiety",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "3ed49476-9c02-5a93-b8fc-3921a14daaf3",
                "text": "A lot of distress or anxiety",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "1272197a-b659-540a-80ee-1a5ec9b59d3c",
                "text": "A severe amount of distress or anxiety",
                "value": 1
            }
        ],
        "id": "OCD-15a",
        "text": "How much distress or anxiety would you feel if you were unable to carry out a compulsion?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "5f1c8429-44a8-5ffe-b25a-83342c0377d2",
                "text": "Less than 1 hour per day",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "cc0e9f4f-d2cf-5611-8b94-f2de2ba87c8d",
                "text": "1–3 hours per day",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "f177854d-7188-5142-8325-b27ef8aac03f",
                "text": "More than 3 hours per day",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "e3809079-bc8b-52c6-b811-7f6f2437e4a9",
                "text": "More than 8 hours per day",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "8b96bfb1-38bb-56f7-a9cb-693bd9bed98a",
                "text": "More than 12 hours per day/nearly constant",
                "value": 1
            }
        ],
        "id": "OCD-19a",
        "text": "How much time do these obsessions and compulsions take up in a typical day?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "f58cb8d9-832c-58f1-ad61-a38722e1ac30",
                "text": "Less than 1 hour per day",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "4fec4813-10b7-5b75-a92a-c3ef0bd0829f",
                "text": "1–3 hours per day",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "a8cdbe2a-7125-51f9-a704-7cc6b5e94a7b",
                "text": "More than 3 hours per day",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "aa57f892-e9e7-52b0-a516-b7541d79ad82",
                "text": "More than 8 hours per day",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "d1baedd2-5bdb-5255-aa0a-5fb0bdbe86d1",
                "text": "More than 12 hours per day/nearly constant",
                "value": 1
            }
        ],
        "id": "OCD-o19a",
        "text": "How much time do these obsessions take up in a typical day?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "85b5a64f-a893-5a4d-98c5-e7ab4f819b8b",
                "text": "Less than 1 hour per day",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "b8bc16e5-c342-5285-8986-82ddbf511a09",
                "text": "1–3 hours per day",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "dfdfabbf-250c-57bc-99c2-017778956597",
                "text": "More than 3 hours per day",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "17b4fbea-ef73-56af-ae4d-765c41ceedd1",
                "text": "More than 8 hours per day",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "f239adce-d665-5b87-ac6f-23b1fe1d840c",
                "text": "More than 12 hours per day/nearly constant",
                "value": 1
            }
        ],
        "id": "OCD-c19a",
        "text": "How much time do these compulsions take up in a typical day?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "375ddbf7-072f-50cc-b5ca-f7dd2b8d301c",
                "text": "Concerns about contamination (e.g., germs, viruses, dirt) or illness"
            },
            {
                "__typename": "Option",
                "id": "400ab343-bdc4-567f-81a4-9e6a6d192ec5",
                "text": "Concerns about safety or harm to oneself (e.g., worries about unlocked doors)"
            },
            {
                "__typename": "Option",
                "id": "5f203c70-531e-5f85-b902-825d95db880a",
                "text": "Wanting things to be symmetrical or ordered"
            },
            {
                "__typename": "Option",
                "id": "86a75005-4b0f-5cdc-bdf2-dc356189360e",
                "text": "Taboo or forbidden thoughts (e.g., taboo sexual thoughts, thoughts of violence)"
            },
            {
                "__typename": "Option",
                "id": "41541011-61c0-5d8e-bea5-ac6618eb0499",
                "text": "Acquiring and retaining certain objects"
            },
            {
                "__typename": "Option",
                "id": "654f0850-433c-5068-81b1-cbc8da0115d4",
                "text": "None of the above",
                "exclusive": true
            }
        ],
        "id": "OCD-3a",
        "text": "Do you experience any of these obsessions? Please select all that apply.",
        "isMultiselect": true
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "2edc3093-336a-5fc1-b7ac-3466362526bb",
                "text": "Checking"
            },
            {
                "__typename": "Option",
                "id": "bd33b2e5-ba63-507e-bd58-7576ee2f60f0",
                "text": "Seeking reassurance from others"
            },
            {
                "__typename": "Option",
                "id": "4151b231-67fb-5826-a373-b716f21235f1",
                "text": "Ordering or counting"
            },
            {
                "__typename": "Option",
                "id": "dc2fd953-74d4-5446-be05-66611ee351d5",
                "text": "Washing and cleaning"
            },
            {
                "__typename": "Option",
                "id": "7b4dd465-0ec8-551c-9086-241199467acb",
                "text": "Collecting objects"
            },
            {
                "__typename": "Option",
                "id": "3e8b1149-e933-56f0-986f-88ae3cc6eee4",
                "text": "None of the above",
                "exclusive": true
            }
        ],
        "id": "OCD-4a",
        "text": "Do you experience any of these compulsions? Please select all that apply.",
        "isMultiselect": true
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "63785b0d-d331-58f4-bf9f-d7d402daea1b",
                "text": "No, not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "2435ec34-7006-5e0a-b25b-021f0ff5e79e",
                "text": "A small amount",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "fc1867dc-1ad7-5599-848b-dea1a7dd7584",
                "text": "A fair bit",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "dffa8a56-9791-59eb-8f9c-3e7badb3a26e",
                "text": "A lot",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "15202dc0-e463-59a3-886a-81521814b4a3",
                "text": "An extreme amount",
                "value": 1
            }
        ],
        "id": "OCD-5a",
        "text": "Do you attempt to ignore or suppress these obsessions?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "a114895c-d8d0-550b-92cd-6cc7fa00b4c9",
                "text": "No, not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "aee9c4fc-09a8-5417-bcf6-72489e6c2295",
                "text": "A small amount",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "d8b5b0e1-310e-5a1e-be90-df3ab36ada8e",
                "text": "A fair bit",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "a32a57d8-8aa9-5e76-a8cc-4102a757ca38",
                "text": "A lot",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "52b17754-786a-58c8-b625-bf1153ae0647",
                "text": "An extreme amount",
                "value": 1
            }
        ],
        "id": "OCD-6a",
        "text": "Do you feel that by carrying out these compulsions you have \"cancelled out\" the obsessions  (i.e., reduces anxiety or distress, or prevents an unwanted event from happening)?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "f78932aa-7c48-5e79-895a-a3dcbc1659ea",
                "text": "I believe they are irrational",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "257e5561-0975-5e0b-85d0-618d9799a7e9",
                "text": "I believe they are somewhat rational",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "32d87341-36a2-5c2b-a385-5ac2114fe805",
                "text": "I believe they are mostly rational",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "4fcc66a3-e0bd-5b6c-bb12-277918c042ee",
                "text": "I believe they are almost completely or completely rational",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "a5935093-4c49-5c9c-acd1-1ace1c4f5d23",
                "text": "I am not sure if they are rational",
                "value": 1
            }
        ],
        "id": "OCD-8a",
        "text": "Do you believe that these obsessions are rational (i.e., sensible and likely to happen)?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "73abd2a9-18bf-5cab-aaa9-7433806d7c36",
                "text": "I believe carrying out these compulsions is irrational",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "18f0fe22-b536-5176-a1a6-b0deb375b436",
                "text": "I believe these compulsions are probably rational",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "06e76867-91d9-521e-9102-62a647414f8c",
                "text": "I believe these compulsions are completely or almost completely rational",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "c9936a18-a4eb-59f5-bc80-479d96aac203",
                "text": "I am not sure",
                "value": 1
            }
        ],
        "id": "OCD-13a",
        "text": "Do you believe that these compulsions are rational (i.e., sensible and make sense)?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "54e837d8-5c1b-5a1b-8b6a-03cc3c37bb60",
                "text": "Others would not find these compulsive acts unreasonable or excessive",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "b04c74ed-9e1d-5829-80a8-bde749828475",
                "text": "Others would find these compulsive acts unnecessary, but not excessive",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "20710ec3-44fe-50bb-966c-e7e197979d04",
                "text": "Others would find these compulsive acts unnecessary and excessive",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "30be1b0f-a1a9-5da9-8ca2-293a63a6d4fe",
                "text": "I am not sure",
                "value": 1
            }
        ],
        "id": "OCD-14a",
        "text": "Would other people see these compulsive acts as unreasonable or excessive?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "0b3c97b3-8583-51ba-a419-36454c1e0bfc",
                "text": "Never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "0cfe50db-bb47-5366-bb5a-f34ce230f42c",
                "text": "Sometimes",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "d02a6bf0-adb3-55fb-a4e6-06e451ceb402",
                "text": "Often",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "587d85a1-edaa-5939-b424-3d49529d9fd5",
                "text": "Always or almost always",
                "value": 1
            }
        ],
        "id": "OCD-16a",
        "text": "Do you avoid situations or people that may trigger these obsessions and compulsions?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "26b009d5-4741-5d96-8bba-71085368006a",
                "text": "Never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "f52473cd-db0d-5582-9623-4cdd13d4716e",
                "text": "Sometimes",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "9614f14b-2e9a-5351-9bf9-29aa1d436e0f",
                "text": "Often",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "9dff1c6f-8e4c-5704-b7df-e8cc3e672d72",
                "text": "Always or almost always",
                "value": 1
            }
        ],
        "id": "OCD-o16a",
        "text": "Do you avoid situations or people that might trigger these obsessions?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "dfc81794-4900-5d4d-a3c7-935d68cebed3",
                "text": "Never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "b9a761bd-2631-5587-beec-4d0edcc823b9",
                "text": "Sometimes",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "91fa7bdb-7715-5cfc-8b8b-fcfdef582591",
                "text": "Often",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "baefcf54-02e4-5c0f-af0b-80af2083b847",
                "text": "Always or almost always",
                "value": 1
            }
        ],
        "id": "OCD-c16a",
        "text": "Do you avoid situations or people that might trigger these compulsions?",
        "isMultiselect": false
    },
    {
        "__typename": "FreeQuestion",
        "id": "OCD-20a",
        "text": "For how long have these obsessions and compulsions affected you most days of the week? If you are unsure, please give your best estimate.",
        "unit": censeo_1.Unit.DurationHours
    },
    {
        "__typename": "FreeQuestion",
        "id": "OCD-o20a",
        "text": "For how long have these obsessions affected you most days of the week? If you are unsure, please give your best estimate.",
        "unit": censeo_1.Unit.DurationHours
    },
    {
        "__typename": "FreeQuestion",
        "id": "OCD-c20a",
        "text": "For how long have these compulsions affected you most days of the week? If you are unsure, please give your best estimate.",
        "unit": censeo_1.Unit.DurationHours
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "bbd1bf19-ffb3-5b11-b9cf-8b41aa1a8a5b",
                "text": "Basic needs such as eating and sleeping"
            },
            {
                "__typename": "Option",
                "id": "6c7ed729-bf52-5ae0-bb2b-f972dd45f6b7",
                "text": "Working or studying"
            },
            {
                "__typename": "Option",
                "id": "70210027-e232-5418-af44-c3b7a09626b4",
                "text": "Intimate relationships"
            },
            {
                "__typename": "Option",
                "id": "ef8a703b-115d-5386-83ba-7fcaf5d4a472",
                "text": "Social life"
            },
            {
                "__typename": "Option",
                "id": "b80eadb8-27cd-5a76-aa06-67ac6097a450",
                "text": "Family"
            },
            {
                "__typename": "Option",
                "id": "cdda9eee-7fcb-5928-a324-3e52b7009404",
                "text": "Leisure activities, hobbies and interests"
            },
            {
                "__typename": "Option",
                "id": "9f12bdd5-995b-501e-b882-f1b6f98214ab",
                "text": "Keeping fit and healthy"
            },
            {
                "__typename": "Option",
                "id": "42b340f0-5f94-5210-acbd-e6b643d37b06",
                "text": "Finances"
            },
            {
                "__typename": "Option",
                "id": "28155bbf-d385-5709-ac3e-39b6a2493683",
                "text": "Other aspects of my life not mentioned above"
            },
            {
                "__typename": "Option",
                "id": "430451ee-6442-43d1-9516-4ce0722d75d7",
                "text": "No areas of my life are affected by these obsessions and compulsions",
                "exclusive": true
            }
        ],
        "id": "OCD-21a",
        "text": "Which areas of your life, if any, do you feel are disrupted by these obsessions and compulsions? Please select all that apply.",
        "isMultiselect": true
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "bd98e2a1-2c4e-588a-98f8-bd6c32b61dd1",
                "text": "Basic needs such as eating and sleeping"
            },
            {
                "__typename": "Option",
                "id": "f1b01b7d-49d6-536d-902b-3228f7ad46bd",
                "text": "Working or studying"
            },
            {
                "__typename": "Option",
                "id": "cbba77bc-609e-5db3-8d21-fee900dbcfff",
                "text": "Intimate relationships"
            },
            {
                "__typename": "Option",
                "id": "955a7492-dcff-5875-bd50-1da0eced106d",
                "text": "Social life"
            },
            {
                "__typename": "Option",
                "id": "09816b86-f171-5853-9be0-c80684ef9303",
                "text": "Family"
            },
            {
                "__typename": "Option",
                "id": "f817bb6f-2193-50bc-9617-58c2996fc08a",
                "text": "Leisure activities, hobbies and interests"
            },
            {
                "__typename": "Option",
                "id": "9963fa72-6972-59a6-9a8c-bdeae02b1b87",
                "text": "Keeping fit and healthy"
            },
            {
                "__typename": "Option",
                "id": "4e806099-42b1-55ca-9a25-324cbde4a3aa",
                "text": "Finances"
            },
            {
                "__typename": "Option",
                "id": "3683169a-d99a-5d7e-b1f5-54c44e3af918",
                "text": "Other aspects of my life not mentioned above"
            },
            {
                "__typename": "Option",
                "id": "4b614374-c0f6-49e8-8e6e-2f7ea5364497",
                "text": "No areas of my life are affected by these obsessions",
                "exclusive": true
            }
        ],
        "id": "OCD-o21a",
        "text": "Which areas of your life, if any, do you feel are disrupted by these obsessions? Please select all that apply.",
        "isMultiselect": true
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "2a3697e4-6f9f-5970-ab5b-98b0e41cae83",
                "text": "Basic needs such as eating and sleeping"
            },
            {
                "__typename": "Option",
                "id": "c0fefc8c-c4a2-5b5c-8921-a5cd9bb47ac1",
                "text": "Working or studying"
            },
            {
                "__typename": "Option",
                "id": "425db81d-3464-578c-a8f8-92112274b117",
                "text": "Intimate relationships"
            },
            {
                "__typename": "Option",
                "id": "a0d448d1-4d27-5e31-a5d0-16e9a360bbcb",
                "text": "Social life"
            },
            {
                "__typename": "Option",
                "id": "a7f7b36d-2c31-51b9-b0a1-233aeb20a056",
                "text": "Family"
            },
            {
                "__typename": "Option",
                "id": "7c703f50-8cec-5240-9cf9-f76575cdaace",
                "text": "Leisure activities, hobbies and interests"
            },
            {
                "__typename": "Option",
                "id": "8902c5b4-519c-5daa-86bc-49642bb9c5a7",
                "text": "Keeping fit and healthy"
            },
            {
                "__typename": "Option",
                "id": "0ae1e720-f983-5218-b553-e881e3589a8b",
                "text": "Finances"
            },
            {
                "__typename": "Option",
                "id": "4f3419ed-c6f4-5024-83e0-3149f6c96807",
                "text": "Other aspects of my life not mentioned above"
            },
            {
                "__typename": "Option",
                "id": "a29a9a5c-e0c7-46e1-b48c-171d55dca1f1",
                "text": "No areas of my life are affected by these compulsions",
                "exclusive": true
            }
        ],
        "id": "OCD-c21a",
        "text": "Which areas of your life, if any, do you feel are disrupted by your need to carry out these compulsions? Please select all that apply.",
        "isMultiselect": true
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "68c4c071-8e58-5ec7-a403-ce8a9c115727",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "3f5d0c33-fc5f-55f0-850c-3d48c2572c35",
                "text": "To a slight extent",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "9e90d287-ca8e-5942-a6f7-80fc50e48179",
                "text": "To a moderate extent",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "345c0bc7-484c-511d-9071-9b43afc5a7f3",
                "text": "To a significant extent",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "d3da923e-63ce-57df-b770-213e64c2b98f",
                "text": "To an extreme extent",
                "value": 1
            }
        ],
        "id": "OCD-21.0a",
        "text": "To what extent do these obsessions and compulsions affect these areas of your life, or require you to make additional effort to maintain functioning in those areas?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "afbe8a05-7261-5d24-a19f-35d6b9f3ff9e",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "750f7056-9da3-5ccd-9e7c-25b8c7d9569e",
                "text": "To a slight extent",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "7a7e149a-c672-5237-9199-8769482ee74a",
                "text": "To a moderate extent",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "e0651ad9-2972-53fc-984f-c55d8aa950c0",
                "text": "To a significant extent",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "1fb95c5e-97e0-5aae-8744-a1da4aab42e4",
                "text": "To an extreme extent",
                "value": 1
            }
        ],
        "id": "OCD-o21.0a",
        "text": "To what extent do these obsessions affect these areas of your life, or require you to make additional effort to maintain functioning in those areas?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "04ec36f5-6c17-524d-84c5-964056142f3c",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "9fa6cec1-d9d3-567d-9c01-727603b77847",
                "text": "To a slight extent",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "a6302a85-ec37-51a4-989e-05e001c75b1f",
                "text": "To a moderate extent",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "9fe10eb3-a49b-5f56-a4e3-e70fadd249a9",
                "text": "To a significant extent",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "440a730f-02f2-55d0-bcdd-e1977b2f5b0c",
                "text": "To an extreme extent",
                "value": 1
            }
        ],
        "id": "OCD-c21.0a",
        "text": "To what extent do these compulsions affect these areas of your life, or require you to make additional effort to maintain functioning in those areas?",
        "isMultiselect": false
    }
];
exports.aspects = {
    "ocd_ce": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "has_ocd_current"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "ocd_c"
                }
            }
        ],
        "anyToAll": 1
    },
    "has_ocd_current": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "ocd_A_obsession_or_compulsions_current"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "ocd_B_time_distress_impairment_current"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "ocd_duration_current"
                }
            }
        ],
        "anyToAll": 1
    },
    "ocd_A_obsession_or_compulsions_current": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "ocd_obsessions_current"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "ocd_compulsions_current"
                }
            }
        ],
        "anyToAll": 0.5
    },
    "ocd_B_time_distress_impairment_current": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "ocd_time_current"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "ocd_distress_current"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "ocd_impairment_current"
                }
            }
        ],
        "anyToAll": 0.6666666666666666
    },
    "ocd_duration_current": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "SpecificNumberInput",
                    "questionId": "OCD-20a",
                    "input": 30,
                    "operator": ">="
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "SpecificNumberInput",
                    "questionId": "OCD-o20a",
                    "input": 30,
                    "operator": ">="
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "SpecificNumberInput",
                    "questionId": "OCD-c20a",
                    "input": 30,
                    "operator": ">="
                }
            }
        ],
        "anyToAll": 0.3333333333333333
    },
    "ocd_obsessions_current": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "ocd_ob"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "ocd_ignore_current"
                }
            }
        ],
        "anyToAll": 1
    },
    "ocd_ob": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "OCD-s1",
            "options": {
                "type": "predicates",
                "predicates": {
                    "805a9685-9fd4-55de-94cd-4a19dd4107b8": false,
                    "263b64b2-8e44-59b6-8761-9556068db3c2": true
                }
            }
        }
    },
    "ocd_ignore_current": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCD-5a",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "63785b0d-d331-58f4-bf9f-d7d402daea1b": 0.0066928509242848554,
                            "2435ec34-7006-5e0a-b25b-021f0ff5e79e": 0.07585818002124355,
                            "fc1867dc-1ad7-5599-848b-dea1a7dd7584": 0.5,
                            "dffa8a56-9791-59eb-8f9c-3e7badb3a26e": 0.9241418199787566,
                            "15202dc0-e463-59a3-886a-81521814b4a3": 0.9933071490757153
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCD-6a",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "a114895c-d8d0-550b-92cd-6cc7fa00b4c9": 0.0066928509242848554,
                            "aee9c4fc-09a8-5417-bcf6-72489e6c2295": 0.07585818002124355,
                            "d8b5b0e1-310e-5a1e-be90-df3ab36ada8e": 0.5,
                            "a32a57d8-8aa9-5e76-a8cc-4102a757ca38": 0.9241418199787566,
                            "52b17754-786a-58c8-b625-bf1153ae0647": 0.9933071490757153
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.5
    },
    "ocd_compulsions_current": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "ocd_co"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "ocd_prevent_current"
                }
            }
        ],
        "anyToAll": 1
    },
    "ocd_co": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "OCD-s2",
            "options": {
                "type": "predicates",
                "predicates": {
                    "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": false,
                    "91fad731-ad66-57f3-87fd-70f4246e63ed": true
                }
            }
        }
    },
    "ocd_prevent_current": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCD-6a",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "a114895c-d8d0-550b-92cd-6cc7fa00b4c9": 0.0066928509242848554,
                            "aee9c4fc-09a8-5417-bcf6-72489e6c2295": 0.07585818002124355,
                            "d8b5b0e1-310e-5a1e-be90-df3ab36ada8e": 0.5,
                            "a32a57d8-8aa9-5e76-a8cc-4102a757ca38": 0.9241418199787566,
                            "52b17754-786a-58c8-b625-bf1153ae0647": 0.9933071490757153
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCD-14a",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "54e837d8-5c1b-5a1b-8b6a-03cc3c37bb60": 0.03557118927263617,
                            "b04c74ed-9e1d-5829-80a8-bde749828475": 0.5,
                            "20710ec3-44fe-50bb-966c-e7e197979d04": 0.9644288107273639,
                            "30be1b0f-a1a9-5da9-8ca2-293a63a6d4fe": 0.9987706013787226
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.5
    },
    "ocd_time_current": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCD-19a",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "5f1c8429-44a8-5ffe-b25a-83342c0377d2": 0.07585818002124355,
                            "cc0e9f4f-d2cf-5611-8b94-f2de2ba87c8d": 0.5,
                            "f177854d-7188-5142-8325-b27ef8aac03f": 0.9241418199787566,
                            "e3809079-bc8b-52c6-b811-7f6f2437e4a9": 0.9933071490757153,
                            "8b96bfb1-38bb-56f7-a9cb-693bd9bed98a": 0.9994472213630764
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCD-o19a",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "f58cb8d9-832c-58f1-ad61-a38722e1ac30": 0.07585818002124355,
                            "4fec4813-10b7-5b75-a92a-c3ef0bd0829f": 0.5,
                            "a8cdbe2a-7125-51f9-a704-7cc6b5e94a7b": 0.9241418199787566,
                            "aa57f892-e9e7-52b0-a516-b7541d79ad82": 0.9933071490757153,
                            "d1baedd2-5bdb-5255-aa0a-5fb0bdbe86d1": 0.9994472213630764
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCD-c19a",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "85b5a64f-a893-5a4d-98c5-e7ab4f819b8b": 0.07585818002124355,
                            "b8bc16e5-c342-5285-8986-82ddbf511a09": 0.5,
                            "dfdfabbf-250c-57bc-99c2-017778956597": 0.9241418199787566,
                            "17b4fbea-ef73-56af-ae4d-765c41ceedd1": 0.9933071490757153,
                            "f239adce-d665-5b87-ac6f-23b1fe1d840c": 0.9994472213630764
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.3333333333333333
    },
    "ocd_distress_current": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCD-7a",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "73175241-22a6-5d30-8d75-ae6a9f0851e3": 0.0066928509242848554,
                            "95603b88-c05c-5344-ab26-5d338cd499ff": 0.07585818002124355,
                            "76162037-755b-57b1-93d4-4f9ab77522dc": 0.5,
                            "42716cc5-7285-5b4e-9d65-4be48e7606b1": 0.9241418199787566,
                            "d1a809d8-8068-5778-a74f-a8f209fff96e": 0.9933071490757153
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCD-15a",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "940dca6f-6249-503f-80e8-19590e269d2e": 0.0066928509242848554,
                            "84d8ca85-4007-5557-aa20-b6f3b32a5819": 0.07585818002124355,
                            "d1f530a9-4ad1-5d89-8d08-2d819227639b": 0.5,
                            "3ed49476-9c02-5a93-b8fc-3921a14daaf3": 0.9241418199787566,
                            "1272197a-b659-540a-80ee-1a5ec9b59d3c": 0.9933071490757153
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.5
    },
    "ocd_impairment_current": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "OCDc21a_basic"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "OCDc21a_work"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "OCDc21a_relationships"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "OCDc21a_social"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "OCDc21a_family"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "OCDc22a_leisure"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "OCDc22a_health"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "OCDc22a_financial"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "OCDc22a_other"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "OCD21a_basic"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "OCD21a_work"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "OCD21a_relationships"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "OCD21a_social"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "OCD21a_family"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "OCD22a_leisure"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "OCD22a_health"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "OCD22a_financial"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "OCD22a_other"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "OCDc21a_basic"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "OCDc21a_work"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "OCDc21a_social"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "OCDc21a_family"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "OCDc22a_leisure"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "OCDc22a_health"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "OCDc22a_financial"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "OCDc22a_other"
                        }
                    }
                ],
                "anyToAll": 0.07692307692307693
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "OCD-c21.0a",
                            "options": {
                                "type": "severities",
                                "severities": {
                                    "04ec36f5-6c17-524d-84c5-964056142f3c": 0.0005527786369235996,
                                    "9fa6cec1-d9d3-567d-9c01-727603b77847": 0.0066928509242848554,
                                    "a6302a85-ec37-51a4-989e-05e001c75b1f": 0.07585818002124355,
                                    "9fe10eb3-a49b-5f56-a4e3-e70fadd249a9": 0.5,
                                    "440a730f-02f2-55d0-bcdd-e1977b2f5b0c": 0.9241418199787566
                                }
                            }
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "OCD-o21.0a",
                            "options": {
                                "type": "severities",
                                "severities": {
                                    "afbe8a05-7261-5d24-a19f-35d6b9f3ff9e": 0.0005527786369235996,
                                    "750f7056-9da3-5ccd-9e7c-25b8c7d9569e": 0.0066928509242848554,
                                    "7a7e149a-c672-5237-9199-8769482ee74a": 0.07585818002124355,
                                    "e0651ad9-2972-53fc-984f-c55d8aa950c0": 0.5,
                                    "1fb95c5e-97e0-5aae-8744-a1da4aab42e4": 0.9241418199787566
                                }
                            }
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "OCD-21.0a",
                            "options": {
                                "type": "severities",
                                "severities": {
                                    "68c4c071-8e58-5ec7-a403-ce8a9c115727": 0.0005527786369235996,
                                    "3f5d0c33-fc5f-55f0-850c-3d48c2572c35": 0.0066928509242848554,
                                    "9e90d287-ca8e-5942-a6f7-80fc50e48179": 0.07585818002124355,
                                    "345c0bc7-484c-511d-9071-9b43afc5a7f3": 0.5,
                                    "d3da923e-63ce-57df-b770-213e64c2b98f": 0.9241418199787566
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 0.3333333333333333
            }
        ],
        "anyToAll": 0.5
    },
    "ocd_c": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCD-s1.5",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "6581df20-1f12-5563-9671-fd51c07fd098": false,
                            "934f0a07-8342-52aa-9c61-ec87c8d7b6b9": true
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCD-s2.5",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "786ef202-eae2-58ab-bec2-7bbe1746fd5c": false,
                            "f543076e-83c3-56df-9539-f976a57d2fac": true
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.5
    },
    "OCD3a_contamination": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "OCD-3a",
            "options": {
                "type": "severities",
                "severities": {
                    "375ddbf7-072f-50cc-b5ca-f7dd2b8d301c": 1
                }
            }
        }
    },
    "OCD3a_harm": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "OCD-3a",
            "options": {
                "type": "severities",
                "severities": {
                    "400ab343-bdc4-567f-81a4-9e6a6d192ec5": 1
                }
            }
        }
    },
    "OCD3a_symmetry": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "OCD-3a",
            "options": {
                "type": "severities",
                "severities": {
                    "5f203c70-531e-5f85-b902-825d95db880a": 1
                }
            }
        }
    },
    "OCD3a_taboo": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "OCD-3a",
            "options": {
                "type": "severities",
                "severities": {
                    "86a75005-4b0f-5cdc-bdf2-dc356189360e": 1
                }
            }
        }
    },
    "OCD3a_hoarding": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "OCD-3a",
            "options": {
                "type": "severities",
                "severities": {
                    "41541011-61c0-5d8e-bea5-ac6618eb0499": 1
                }
            }
        }
    },
    "OCD3a_": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "OCD-3a",
            "options": {
                "type": "severities",
                "severities": {
                    "654f0850-433c-5068-81b1-cbc8da0115d4": 1
                }
            }
        }
    },
    "OD4a_checking": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "OCD-4a",
            "options": {
                "type": "severities",
                "severities": {
                    "2edc3093-336a-5fc1-b7ac-3466362526bb": 1
                }
            }
        }
    },
    "OCD4a_reassurance": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "OCD-4a",
            "options": {
                "type": "severities",
                "severities": {
                    "bd33b2e5-ba63-507e-bd58-7576ee2f60f0": 1
                }
            }
        }
    },
    "OCD4a_ordering": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "OCD-4a",
            "options": {
                "type": "severities",
                "severities": {
                    "4151b231-67fb-5826-a373-b716f21235f1": 1
                }
            }
        }
    },
    "OCD4a_washing": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "OCD-4a",
            "options": {
                "type": "severities",
                "severities": {
                    "dc2fd953-74d4-5446-be05-66611ee351d5": 1
                }
            }
        }
    },
    "OCD4a_hoarding": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "OCD-4a",
            "options": {
                "type": "severities",
                "severities": {
                    "7b4dd465-0ec8-551c-9086-241199467acb": 1
                }
            }
        }
    },
    "OCD4a_": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "OCD-4a",
            "options": {
                "type": "severities",
                "severities": {
                    "3e8b1149-e933-56f0-986f-88ae3cc6eee4": 1
                }
            }
        }
    },
    "OCD21a_basic": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "OCD-21a",
            "options": {
                "type": "severities",
                "severities": {
                    "bbd1bf19-ffb3-5b11-b9cf-8b41aa1a8a5b": 1
                }
            }
        }
    },
    "OCD21a_work": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "OCD-21a",
            "options": {
                "type": "severities",
                "severities": {
                    "6c7ed729-bf52-5ae0-bb2b-f972dd45f6b7": 1
                }
            }
        }
    },
    "OCD21a_relationships": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "OCD-21a",
            "options": {
                "type": "severities",
                "severities": {
                    "70210027-e232-5418-af44-c3b7a09626b4": 1
                }
            }
        }
    },
    "OCD21a_social": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "OCD-21a",
            "options": {
                "type": "severities",
                "severities": {
                    "ef8a703b-115d-5386-83ba-7fcaf5d4a472": 1
                }
            }
        }
    },
    "OCD21a_family": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "OCD-21a",
            "options": {
                "type": "severities",
                "severities": {
                    "b80eadb8-27cd-5a76-aa06-67ac6097a450": 1
                }
            }
        }
    },
    "OCD22a_leisure": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "OCD-21a",
            "options": {
                "type": "severities",
                "severities": {
                    "cdda9eee-7fcb-5928-a324-3e52b7009404": 1
                }
            }
        }
    },
    "OCD22a_health": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "OCD-21a",
            "options": {
                "type": "severities",
                "severities": {
                    "9f12bdd5-995b-501e-b882-f1b6f98214ab": 1
                }
            }
        }
    },
    "OCD22a_financial": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "OCD-21a",
            "options": {
                "type": "severities",
                "severities": {
                    "42b340f0-5f94-5210-acbd-e6b643d37b06": 1
                }
            }
        }
    },
    "OCD22a_other": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "OCD-21a",
            "options": {
                "type": "severities",
                "severities": {
                    "28155bbf-d385-5709-ac3e-39b6a2493683": 1
                }
            }
        }
    },
    "OCD21a_none": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "OCD-21a",
            "options": {
                "type": "severities",
                "severities": {
                    "430451ee-6442-43d1-9516-4ce0722d75d7": 1
                }
            }
        }
    },
    "OCDo21a_basic": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "OCD-o21a",
            "options": {
                "type": "severities",
                "severities": {
                    "bd98e2a1-2c4e-588a-98f8-bd6c32b61dd1": 1
                }
            }
        }
    },
    "OCDo21a_work": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "OCD-o21a",
            "options": {
                "type": "severities",
                "severities": {
                    "f1b01b7d-49d6-536d-902b-3228f7ad46bd": 1
                }
            }
        }
    },
    "OCDo21a_relationships": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "OCD-o21a",
            "options": {
                "type": "severities",
                "severities": {
                    "cbba77bc-609e-5db3-8d21-fee900dbcfff": 1
                }
            }
        }
    },
    "OCDo21a_social": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "OCD-o21a",
            "options": {
                "type": "severities",
                "severities": {
                    "955a7492-dcff-5875-bd50-1da0eced106d": 1
                }
            }
        }
    },
    "OCDo21a_family": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "OCD-o21a",
            "options": {
                "type": "severities",
                "severities": {
                    "09816b86-f171-5853-9be0-c80684ef9303": 1
                }
            }
        }
    },
    "OCDo22a_leisure": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "OCD-o21a",
            "options": {
                "type": "severities",
                "severities": {
                    "f817bb6f-2193-50bc-9617-58c2996fc08a": 1
                }
            }
        }
    },
    "OCDo22a_health": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "OCD-o21a",
            "options": {
                "type": "severities",
                "severities": {
                    "9963fa72-6972-59a6-9a8c-bdeae02b1b87": 1
                }
            }
        }
    },
    "OCDo22a_financial": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "OCD-o21a",
            "options": {
                "type": "severities",
                "severities": {
                    "4e806099-42b1-55ca-9a25-324cbde4a3aa": 1
                }
            }
        }
    },
    "OCDo22a_other": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "OCD-o21a",
            "options": {
                "type": "severities",
                "severities": {
                    "3683169a-d99a-5d7e-b1f5-54c44e3af918": 1
                }
            }
        }
    },
    "OCDo21a_none": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "OCD-o21a",
            "options": {
                "type": "severities",
                "severities": {
                    "4b614374-c0f6-49e8-8e6e-2f7ea5364497": 1
                }
            }
        }
    },
    "OCDc21a_basic": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "OCD-c21a",
            "options": {
                "type": "severities",
                "severities": {
                    "2a3697e4-6f9f-5970-ab5b-98b0e41cae83": 1
                }
            }
        }
    },
    "OCDc21a_work": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "OCD-c21a",
            "options": {
                "type": "severities",
                "severities": {
                    "c0fefc8c-c4a2-5b5c-8921-a5cd9bb47ac1": 1
                }
            }
        }
    },
    "OCDc21a_relationships": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "OCD-c21a",
            "options": {
                "type": "severities",
                "severities": {
                    "425db81d-3464-578c-a8f8-92112274b117": 1
                }
            }
        }
    },
    "OCDc21a_social": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "OCD-c21a",
            "options": {
                "type": "severities",
                "severities": {
                    "a0d448d1-4d27-5e31-a5d0-16e9a360bbcb": 1
                }
            }
        }
    },
    "OCDc21a_family": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "OCD-c21a",
            "options": {
                "type": "severities",
                "severities": {
                    "a7f7b36d-2c31-51b9-b0a1-233aeb20a056": 1
                }
            }
        }
    },
    "OCDc22a_leisure": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "OCD-c21a",
            "options": {
                "type": "severities",
                "severities": {
                    "7c703f50-8cec-5240-9cf9-f76575cdaace": 1
                }
            }
        }
    },
    "OCDc22a_health": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "OCD-c21a",
            "options": {
                "type": "severities",
                "severities": {
                    "8902c5b4-519c-5daa-86bc-49642bb9c5a7": 1
                }
            }
        }
    },
    "OCDc22a_financial": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "OCD-c21a",
            "options": {
                "type": "severities",
                "severities": {
                    "0ae1e720-f983-5218-b553-e881e3589a8b": 1
                }
            }
        }
    },
    "OCDc22a_other": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "OCD-c21a",
            "options": {
                "type": "severities",
                "severities": {
                    "4f3419ed-c6f4-5024-83e0-3149f6c96807": 1
                }
            }
        }
    },
    "OCDc21a_none": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "OCD-c21a",
            "options": {
                "type": "severities",
                "severities": {
                    "a29a9a5c-e0c7-46e1-b48c-171d55dca1f1": 1
                }
            }
        }
    },
    "not(OCD-s1=0)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "OCD-s1",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "805a9685-9fd4-55de-94cd-4a19dd4107b8": true,
                        "263b64b2-8e44-59b6-8761-9556068db3c2": false
                    }
                }
            }
        }
    },
    "and[or[isUndefined(and[OCD-s1=0, OCD-s2=0]), not(and[OCD-s1=0, OCD-s2=0])], OCD-s2=1]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "OCD-s1",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "805a9685-9fd4-55de-94cd-4a19dd4107b8": true,
                                                "263b64b2-8e44-59b6-8761-9556068db3c2": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "OCD-s2",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": true,
                                                "91fad731-ad66-57f3-87fd-70f4246e63ed": false
                                            }
                                        }
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "OCD-s1",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "805a9685-9fd4-55de-94cd-4a19dd4107b8": true,
                                                "263b64b2-8e44-59b6-8761-9556068db3c2": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "OCD-s2",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": true,
                                                "91fad731-ad66-57f3-87fd-70f4246e63ed": false
                                            }
                                        }
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCD-s2",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": false,
                            "91fad731-ad66-57f3-87fd-70f4246e63ed": true
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "or[and[or[isUndefined(and[OCD-s1=0, OCD-s2=0]), not(and[OCD-s1=0, OCD-s2=0])], not(OCD-s2=1)], and[or[isUndefined(and[OCD-s1=0, OCD-s2=1, OCD-s2.5=1]), not(and[OCD-s1=0, OCD-s2=1, OCD-s2.5=1])], or[isUndefined(and[OCD-s1=1, OCD-s1.5=0, OCD-s2=1, OCD-s2.5=1]), not(and[OCD-s1=1, OCD-s1.5=0, OCD-s2=1, OCD-s2.5=1]), and[OCD-s1=1, OCD-s1.5=1, OCD-s2=1, OCD-s2.5=1]], not(isUndefined(OCD-s2.5=0))]]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "isUnknown",
                                "wire": {
                                    "type": "merge",
                                    "wires": [
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s1",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "805a9685-9fd4-55de-94cd-4a19dd4107b8": true,
                                                        "263b64b2-8e44-59b6-8761-9556068db3c2": false
                                                    }
                                                }
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s2",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": true,
                                                        "91fad731-ad66-57f3-87fd-70f4246e63ed": false
                                                    }
                                                }
                                            }
                                        }
                                    ],
                                    "anyToAll": 1
                                }
                            },
                            {
                                "type": "not",
                                "wire": {
                                    "type": "merge",
                                    "wires": [
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s1",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "805a9685-9fd4-55de-94cd-4a19dd4107b8": true,
                                                        "263b64b2-8e44-59b6-8761-9556068db3c2": false
                                                    }
                                                }
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s2",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": true,
                                                        "91fad731-ad66-57f3-87fd-70f4246e63ed": false
                                                    }
                                                }
                                            }
                                        }
                                    ],
                                    "anyToAll": 1
                                }
                            }
                        ],
                        "anyToAll": 0.5
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AnsweredOption",
                                "questionId": "OCD-s2",
                                "options": {
                                    "type": "predicates",
                                    "predicates": {
                                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": false,
                                        "91fad731-ad66-57f3-87fd-70f4246e63ed": true
                                    }
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 1
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "isUnknown",
                                "wire": {
                                    "type": "merge",
                                    "wires": [
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s1",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "805a9685-9fd4-55de-94cd-4a19dd4107b8": true,
                                                        "263b64b2-8e44-59b6-8761-9556068db3c2": false
                                                    }
                                                }
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s2",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": false,
                                                        "91fad731-ad66-57f3-87fd-70f4246e63ed": true
                                                    }
                                                }
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s2.5",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "786ef202-eae2-58ab-bec2-7bbe1746fd5c": false,
                                                        "f543076e-83c3-56df-9539-f976a57d2fac": true
                                                    }
                                                }
                                            }
                                        }
                                    ],
                                    "anyToAll": 1
                                }
                            },
                            {
                                "type": "not",
                                "wire": {
                                    "type": "merge",
                                    "wires": [
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s1",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "805a9685-9fd4-55de-94cd-4a19dd4107b8": true,
                                                        "263b64b2-8e44-59b6-8761-9556068db3c2": false
                                                    }
                                                }
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s2",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": false,
                                                        "91fad731-ad66-57f3-87fd-70f4246e63ed": true
                                                    }
                                                }
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s2.5",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "786ef202-eae2-58ab-bec2-7bbe1746fd5c": false,
                                                        "f543076e-83c3-56df-9539-f976a57d2fac": true
                                                    }
                                                }
                                            }
                                        }
                                    ],
                                    "anyToAll": 1
                                }
                            }
                        ],
                        "anyToAll": 0.5
                    },
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "isUnknown",
                                "wire": {
                                    "type": "merge",
                                    "wires": [
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s1",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "805a9685-9fd4-55de-94cd-4a19dd4107b8": false,
                                                        "263b64b2-8e44-59b6-8761-9556068db3c2": true
                                                    }
                                                }
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s1.5",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "6581df20-1f12-5563-9671-fd51c07fd098": true,
                                                        "934f0a07-8342-52aa-9c61-ec87c8d7b6b9": false
                                                    }
                                                }
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s2",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": false,
                                                        "91fad731-ad66-57f3-87fd-70f4246e63ed": true
                                                    }
                                                }
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s2.5",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "786ef202-eae2-58ab-bec2-7bbe1746fd5c": false,
                                                        "f543076e-83c3-56df-9539-f976a57d2fac": true
                                                    }
                                                }
                                            }
                                        }
                                    ],
                                    "anyToAll": 1
                                }
                            },
                            {
                                "type": "not",
                                "wire": {
                                    "type": "merge",
                                    "wires": [
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s1",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "805a9685-9fd4-55de-94cd-4a19dd4107b8": false,
                                                        "263b64b2-8e44-59b6-8761-9556068db3c2": true
                                                    }
                                                }
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s1.5",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "6581df20-1f12-5563-9671-fd51c07fd098": true,
                                                        "934f0a07-8342-52aa-9c61-ec87c8d7b6b9": false
                                                    }
                                                }
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s2",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": false,
                                                        "91fad731-ad66-57f3-87fd-70f4246e63ed": true
                                                    }
                                                }
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s2.5",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "786ef202-eae2-58ab-bec2-7bbe1746fd5c": false,
                                                        "f543076e-83c3-56df-9539-f976a57d2fac": true
                                                    }
                                                }
                                            }
                                        }
                                    ],
                                    "anyToAll": 1
                                }
                            },
                            {
                                "type": "merge",
                                "wires": [
                                    {
                                        "type": "input",
                                        "signal": {
                                            "type": "AnsweredOption",
                                            "questionId": "OCD-s1",
                                            "options": {
                                                "type": "predicates",
                                                "predicates": {
                                                    "805a9685-9fd4-55de-94cd-4a19dd4107b8": false,
                                                    "263b64b2-8e44-59b6-8761-9556068db3c2": true
                                                }
                                            }
                                        }
                                    },
                                    {
                                        "type": "input",
                                        "signal": {
                                            "type": "AnsweredOption",
                                            "questionId": "OCD-s1.5",
                                            "options": {
                                                "type": "predicates",
                                                "predicates": {
                                                    "6581df20-1f12-5563-9671-fd51c07fd098": false,
                                                    "934f0a07-8342-52aa-9c61-ec87c8d7b6b9": true
                                                }
                                            }
                                        }
                                    },
                                    {
                                        "type": "input",
                                        "signal": {
                                            "type": "AnsweredOption",
                                            "questionId": "OCD-s2",
                                            "options": {
                                                "type": "predicates",
                                                "predicates": {
                                                    "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": false,
                                                    "91fad731-ad66-57f3-87fd-70f4246e63ed": true
                                                }
                                            }
                                        }
                                    },
                                    {
                                        "type": "input",
                                        "signal": {
                                            "type": "AnsweredOption",
                                            "questionId": "OCD-s2.5",
                                            "options": {
                                                "type": "predicates",
                                                "predicates": {
                                                    "786ef202-eae2-58ab-bec2-7bbe1746fd5c": false,
                                                    "f543076e-83c3-56df-9539-f976a57d2fac": true
                                                }
                                            }
                                        }
                                    }
                                ],
                                "anyToAll": 1
                            }
                        ],
                        "anyToAll": 0.3333333333333333
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "isUnknown",
                            "wire": {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "OCD-s2.5",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "786ef202-eae2-58ab-bec2-7bbe1746fd5c": true,
                                            "f543076e-83c3-56df-9539-f976a57d2fac": false
                                        }
                                    }
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 1
            }
        ],
        "anyToAll": 0.5
    },
    "and[OCD-s2=1, OCD-s2.5=1, or[OCD-s1=0, and[OCD-s1=1, OCD-s1.5=0, or[isUndefined(and[OCD-s1=1, OCD-s1.5=1, OCD-s2=1, OCD-s2.5=1]), not(and[OCD-s1=1, OCD-s1.5=1, OCD-s2=1, OCD-s2.5=1])]], not(isUndefined(OCD-7a=0))]]": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCD-s2",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": false,
                            "91fad731-ad66-57f3-87fd-70f4246e63ed": true
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCD-s2.5",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "786ef202-eae2-58ab-bec2-7bbe1746fd5c": false,
                            "f543076e-83c3-56df-9539-f976a57d2fac": true
                        }
                    }
                }
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "OCD-s1",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "805a9685-9fd4-55de-94cd-4a19dd4107b8": true,
                                    "263b64b2-8e44-59b6-8761-9556068db3c2": false
                                }
                            }
                        }
                    },
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "OCD-s1",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "805a9685-9fd4-55de-94cd-4a19dd4107b8": false,
                                            "263b64b2-8e44-59b6-8761-9556068db3c2": true
                                        }
                                    }
                                }
                            },
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "OCD-s1.5",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "6581df20-1f12-5563-9671-fd51c07fd098": true,
                                            "934f0a07-8342-52aa-9c61-ec87c8d7b6b9": false
                                        }
                                    }
                                }
                            },
                            {
                                "type": "merge",
                                "wires": [
                                    {
                                        "type": "isUnknown",
                                        "wire": {
                                            "type": "merge",
                                            "wires": [
                                                {
                                                    "type": "input",
                                                    "signal": {
                                                        "type": "AnsweredOption",
                                                        "questionId": "OCD-s1",
                                                        "options": {
                                                            "type": "predicates",
                                                            "predicates": {
                                                                "805a9685-9fd4-55de-94cd-4a19dd4107b8": false,
                                                                "263b64b2-8e44-59b6-8761-9556068db3c2": true
                                                            }
                                                        }
                                                    }
                                                },
                                                {
                                                    "type": "input",
                                                    "signal": {
                                                        "type": "AnsweredOption",
                                                        "questionId": "OCD-s1.5",
                                                        "options": {
                                                            "type": "predicates",
                                                            "predicates": {
                                                                "6581df20-1f12-5563-9671-fd51c07fd098": false,
                                                                "934f0a07-8342-52aa-9c61-ec87c8d7b6b9": true
                                                            }
                                                        }
                                                    }
                                                },
                                                {
                                                    "type": "input",
                                                    "signal": {
                                                        "type": "AnsweredOption",
                                                        "questionId": "OCD-s2",
                                                        "options": {
                                                            "type": "predicates",
                                                            "predicates": {
                                                                "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": false,
                                                                "91fad731-ad66-57f3-87fd-70f4246e63ed": true
                                                            }
                                                        }
                                                    }
                                                },
                                                {
                                                    "type": "input",
                                                    "signal": {
                                                        "type": "AnsweredOption",
                                                        "questionId": "OCD-s2.5",
                                                        "options": {
                                                            "type": "predicates",
                                                            "predicates": {
                                                                "786ef202-eae2-58ab-bec2-7bbe1746fd5c": false,
                                                                "f543076e-83c3-56df-9539-f976a57d2fac": true
                                                            }
                                                        }
                                                    }
                                                }
                                            ],
                                            "anyToAll": 1
                                        }
                                    },
                                    {
                                        "type": "not",
                                        "wire": {
                                            "type": "merge",
                                            "wires": [
                                                {
                                                    "type": "input",
                                                    "signal": {
                                                        "type": "AnsweredOption",
                                                        "questionId": "OCD-s1",
                                                        "options": {
                                                            "type": "predicates",
                                                            "predicates": {
                                                                "805a9685-9fd4-55de-94cd-4a19dd4107b8": false,
                                                                "263b64b2-8e44-59b6-8761-9556068db3c2": true
                                                            }
                                                        }
                                                    }
                                                },
                                                {
                                                    "type": "input",
                                                    "signal": {
                                                        "type": "AnsweredOption",
                                                        "questionId": "OCD-s1.5",
                                                        "options": {
                                                            "type": "predicates",
                                                            "predicates": {
                                                                "6581df20-1f12-5563-9671-fd51c07fd098": false,
                                                                "934f0a07-8342-52aa-9c61-ec87c8d7b6b9": true
                                                            }
                                                        }
                                                    }
                                                },
                                                {
                                                    "type": "input",
                                                    "signal": {
                                                        "type": "AnsweredOption",
                                                        "questionId": "OCD-s2",
                                                        "options": {
                                                            "type": "predicates",
                                                            "predicates": {
                                                                "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": false,
                                                                "91fad731-ad66-57f3-87fd-70f4246e63ed": true
                                                            }
                                                        }
                                                    }
                                                },
                                                {
                                                    "type": "input",
                                                    "signal": {
                                                        "type": "AnsweredOption",
                                                        "questionId": "OCD-s2.5",
                                                        "options": {
                                                            "type": "predicates",
                                                            "predicates": {
                                                                "786ef202-eae2-58ab-bec2-7bbe1746fd5c": false,
                                                                "f543076e-83c3-56df-9539-f976a57d2fac": true
                                                            }
                                                        }
                                                    }
                                                }
                                            ],
                                            "anyToAll": 1
                                        }
                                    }
                                ],
                                "anyToAll": 0.5
                            }
                        ],
                        "anyToAll": 1
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "isUnknown",
                            "wire": {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "OCD-7a",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "73175241-22a6-5d30-8d75-ae6a9f0851e3": true,
                                            "95603b88-c05c-5344-ab26-5d338cd499ff": false,
                                            "76162037-755b-57b1-93d4-4f9ab77522dc": false,
                                            "42716cc5-7285-5b4e-9d65-4be48e7606b1": false,
                                            "d1a809d8-8068-5778-a74f-a8f209fff96e": false
                                        }
                                    }
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 0.3333333333333333
            }
        ],
        "anyToAll": 1
    },
    "and[or[isUndefined(and[OCD-s1=1, OCD-s1.5=1, OCD-s2=1, OCD-s2.5=0]), not(and[OCD-s1=1, OCD-s1.5=1, OCD-s2=1, OCD-s2.5=0])], or[and[or[isUndefined(and[OCD-s2=1, OCD-s2.5=1]), not(and[OCD-s2=1, OCD-s2.5=1])], or[isUndefined(and[OCD-s1=1, OCD-s1.5=1, OCD-s2=0]), not(and[OCD-s1=1, OCD-s1.5=1, OCD-s2=0])], not(isUndefined(OCD-7a=0))], and[or[isUndefined(and[OCD-s1=1, OCD-s2=0]), not(and[OCD-s1=1, OCD-s2=0])], or[isUndefined(and[OCD-s1=0, OCD-s2=1]), not(and[OCD-s1=0, OCD-s2=1])], or[isUndefined(and[OCD-s1=1, OCD-s1.5=0, OCD-s2=1, OCD-s2.5=1]), not(and[OCD-s1=1, OCD-s1.5=0, OCD-s2=1, OCD-s2.5=1])], not(isUndefined(OCD-15a=0))]]]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "OCD-s1",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "805a9685-9fd4-55de-94cd-4a19dd4107b8": false,
                                                "263b64b2-8e44-59b6-8761-9556068db3c2": true
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "OCD-s1.5",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "6581df20-1f12-5563-9671-fd51c07fd098": false,
                                                "934f0a07-8342-52aa-9c61-ec87c8d7b6b9": true
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "OCD-s2",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": false,
                                                "91fad731-ad66-57f3-87fd-70f4246e63ed": true
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "OCD-s2.5",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "786ef202-eae2-58ab-bec2-7bbe1746fd5c": true,
                                                "f543076e-83c3-56df-9539-f976a57d2fac": false
                                            }
                                        }
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "OCD-s1",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "805a9685-9fd4-55de-94cd-4a19dd4107b8": false,
                                                "263b64b2-8e44-59b6-8761-9556068db3c2": true
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "OCD-s1.5",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "6581df20-1f12-5563-9671-fd51c07fd098": false,
                                                "934f0a07-8342-52aa-9c61-ec87c8d7b6b9": true
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "OCD-s2",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": false,
                                                "91fad731-ad66-57f3-87fd-70f4246e63ed": true
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "OCD-s2.5",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "786ef202-eae2-58ab-bec2-7bbe1746fd5c": true,
                                                "f543076e-83c3-56df-9539-f976a57d2fac": false
                                            }
                                        }
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "merge",
                                "wires": [
                                    {
                                        "type": "isUnknown",
                                        "wire": {
                                            "type": "merge",
                                            "wires": [
                                                {
                                                    "type": "input",
                                                    "signal": {
                                                        "type": "AnsweredOption",
                                                        "questionId": "OCD-s2",
                                                        "options": {
                                                            "type": "predicates",
                                                            "predicates": {
                                                                "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": false,
                                                                "91fad731-ad66-57f3-87fd-70f4246e63ed": true
                                                            }
                                                        }
                                                    }
                                                },
                                                {
                                                    "type": "input",
                                                    "signal": {
                                                        "type": "AnsweredOption",
                                                        "questionId": "OCD-s2.5",
                                                        "options": {
                                                            "type": "predicates",
                                                            "predicates": {
                                                                "786ef202-eae2-58ab-bec2-7bbe1746fd5c": false,
                                                                "f543076e-83c3-56df-9539-f976a57d2fac": true
                                                            }
                                                        }
                                                    }
                                                }
                                            ],
                                            "anyToAll": 1
                                        }
                                    },
                                    {
                                        "type": "not",
                                        "wire": {
                                            "type": "merge",
                                            "wires": [
                                                {
                                                    "type": "input",
                                                    "signal": {
                                                        "type": "AnsweredOption",
                                                        "questionId": "OCD-s2",
                                                        "options": {
                                                            "type": "predicates",
                                                            "predicates": {
                                                                "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": false,
                                                                "91fad731-ad66-57f3-87fd-70f4246e63ed": true
                                                            }
                                                        }
                                                    }
                                                },
                                                {
                                                    "type": "input",
                                                    "signal": {
                                                        "type": "AnsweredOption",
                                                        "questionId": "OCD-s2.5",
                                                        "options": {
                                                            "type": "predicates",
                                                            "predicates": {
                                                                "786ef202-eae2-58ab-bec2-7bbe1746fd5c": false,
                                                                "f543076e-83c3-56df-9539-f976a57d2fac": true
                                                            }
                                                        }
                                                    }
                                                }
                                            ],
                                            "anyToAll": 1
                                        }
                                    }
                                ],
                                "anyToAll": 0.5
                            },
                            {
                                "type": "merge",
                                "wires": [
                                    {
                                        "type": "isUnknown",
                                        "wire": {
                                            "type": "merge",
                                            "wires": [
                                                {
                                                    "type": "input",
                                                    "signal": {
                                                        "type": "AnsweredOption",
                                                        "questionId": "OCD-s1",
                                                        "options": {
                                                            "type": "predicates",
                                                            "predicates": {
                                                                "805a9685-9fd4-55de-94cd-4a19dd4107b8": false,
                                                                "263b64b2-8e44-59b6-8761-9556068db3c2": true
                                                            }
                                                        }
                                                    }
                                                },
                                                {
                                                    "type": "input",
                                                    "signal": {
                                                        "type": "AnsweredOption",
                                                        "questionId": "OCD-s1.5",
                                                        "options": {
                                                            "type": "predicates",
                                                            "predicates": {
                                                                "6581df20-1f12-5563-9671-fd51c07fd098": false,
                                                                "934f0a07-8342-52aa-9c61-ec87c8d7b6b9": true
                                                            }
                                                        }
                                                    }
                                                },
                                                {
                                                    "type": "input",
                                                    "signal": {
                                                        "type": "AnsweredOption",
                                                        "questionId": "OCD-s2",
                                                        "options": {
                                                            "type": "predicates",
                                                            "predicates": {
                                                                "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": true,
                                                                "91fad731-ad66-57f3-87fd-70f4246e63ed": false
                                                            }
                                                        }
                                                    }
                                                }
                                            ],
                                            "anyToAll": 1
                                        }
                                    },
                                    {
                                        "type": "not",
                                        "wire": {
                                            "type": "merge",
                                            "wires": [
                                                {
                                                    "type": "input",
                                                    "signal": {
                                                        "type": "AnsweredOption",
                                                        "questionId": "OCD-s1",
                                                        "options": {
                                                            "type": "predicates",
                                                            "predicates": {
                                                                "805a9685-9fd4-55de-94cd-4a19dd4107b8": false,
                                                                "263b64b2-8e44-59b6-8761-9556068db3c2": true
                                                            }
                                                        }
                                                    }
                                                },
                                                {
                                                    "type": "input",
                                                    "signal": {
                                                        "type": "AnsweredOption",
                                                        "questionId": "OCD-s1.5",
                                                        "options": {
                                                            "type": "predicates",
                                                            "predicates": {
                                                                "6581df20-1f12-5563-9671-fd51c07fd098": false,
                                                                "934f0a07-8342-52aa-9c61-ec87c8d7b6b9": true
                                                            }
                                                        }
                                                    }
                                                },
                                                {
                                                    "type": "input",
                                                    "signal": {
                                                        "type": "AnsweredOption",
                                                        "questionId": "OCD-s2",
                                                        "options": {
                                                            "type": "predicates",
                                                            "predicates": {
                                                                "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": true,
                                                                "91fad731-ad66-57f3-87fd-70f4246e63ed": false
                                                            }
                                                        }
                                                    }
                                                }
                                            ],
                                            "anyToAll": 1
                                        }
                                    }
                                ],
                                "anyToAll": 0.5
                            },
                            {
                                "type": "not",
                                "wire": {
                                    "type": "isUnknown",
                                    "wire": {
                                        "type": "input",
                                        "signal": {
                                            "type": "AnsweredOption",
                                            "questionId": "OCD-7a",
                                            "options": {
                                                "type": "predicates",
                                                "predicates": {
                                                    "73175241-22a6-5d30-8d75-ae6a9f0851e3": true,
                                                    "95603b88-c05c-5344-ab26-5d338cd499ff": false,
                                                    "76162037-755b-57b1-93d4-4f9ab77522dc": false,
                                                    "42716cc5-7285-5b4e-9d65-4be48e7606b1": false,
                                                    "d1a809d8-8068-5778-a74f-a8f209fff96e": false
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        ],
                        "anyToAll": 1
                    },
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "merge",
                                "wires": [
                                    {
                                        "type": "isUnknown",
                                        "wire": {
                                            "type": "merge",
                                            "wires": [
                                                {
                                                    "type": "input",
                                                    "signal": {
                                                        "type": "AnsweredOption",
                                                        "questionId": "OCD-s1",
                                                        "options": {
                                                            "type": "predicates",
                                                            "predicates": {
                                                                "805a9685-9fd4-55de-94cd-4a19dd4107b8": false,
                                                                "263b64b2-8e44-59b6-8761-9556068db3c2": true
                                                            }
                                                        }
                                                    }
                                                },
                                                {
                                                    "type": "input",
                                                    "signal": {
                                                        "type": "AnsweredOption",
                                                        "questionId": "OCD-s2",
                                                        "options": {
                                                            "type": "predicates",
                                                            "predicates": {
                                                                "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": true,
                                                                "91fad731-ad66-57f3-87fd-70f4246e63ed": false
                                                            }
                                                        }
                                                    }
                                                }
                                            ],
                                            "anyToAll": 1
                                        }
                                    },
                                    {
                                        "type": "not",
                                        "wire": {
                                            "type": "merge",
                                            "wires": [
                                                {
                                                    "type": "input",
                                                    "signal": {
                                                        "type": "AnsweredOption",
                                                        "questionId": "OCD-s1",
                                                        "options": {
                                                            "type": "predicates",
                                                            "predicates": {
                                                                "805a9685-9fd4-55de-94cd-4a19dd4107b8": false,
                                                                "263b64b2-8e44-59b6-8761-9556068db3c2": true
                                                            }
                                                        }
                                                    }
                                                },
                                                {
                                                    "type": "input",
                                                    "signal": {
                                                        "type": "AnsweredOption",
                                                        "questionId": "OCD-s2",
                                                        "options": {
                                                            "type": "predicates",
                                                            "predicates": {
                                                                "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": true,
                                                                "91fad731-ad66-57f3-87fd-70f4246e63ed": false
                                                            }
                                                        }
                                                    }
                                                }
                                            ],
                                            "anyToAll": 1
                                        }
                                    }
                                ],
                                "anyToAll": 0.5
                            },
                            {
                                "type": "merge",
                                "wires": [
                                    {
                                        "type": "isUnknown",
                                        "wire": {
                                            "type": "merge",
                                            "wires": [
                                                {
                                                    "type": "input",
                                                    "signal": {
                                                        "type": "AnsweredOption",
                                                        "questionId": "OCD-s1",
                                                        "options": {
                                                            "type": "predicates",
                                                            "predicates": {
                                                                "805a9685-9fd4-55de-94cd-4a19dd4107b8": true,
                                                                "263b64b2-8e44-59b6-8761-9556068db3c2": false
                                                            }
                                                        }
                                                    }
                                                },
                                                {
                                                    "type": "input",
                                                    "signal": {
                                                        "type": "AnsweredOption",
                                                        "questionId": "OCD-s2",
                                                        "options": {
                                                            "type": "predicates",
                                                            "predicates": {
                                                                "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": false,
                                                                "91fad731-ad66-57f3-87fd-70f4246e63ed": true
                                                            }
                                                        }
                                                    }
                                                }
                                            ],
                                            "anyToAll": 1
                                        }
                                    },
                                    {
                                        "type": "not",
                                        "wire": {
                                            "type": "merge",
                                            "wires": [
                                                {
                                                    "type": "input",
                                                    "signal": {
                                                        "type": "AnsweredOption",
                                                        "questionId": "OCD-s1",
                                                        "options": {
                                                            "type": "predicates",
                                                            "predicates": {
                                                                "805a9685-9fd4-55de-94cd-4a19dd4107b8": true,
                                                                "263b64b2-8e44-59b6-8761-9556068db3c2": false
                                                            }
                                                        }
                                                    }
                                                },
                                                {
                                                    "type": "input",
                                                    "signal": {
                                                        "type": "AnsweredOption",
                                                        "questionId": "OCD-s2",
                                                        "options": {
                                                            "type": "predicates",
                                                            "predicates": {
                                                                "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": false,
                                                                "91fad731-ad66-57f3-87fd-70f4246e63ed": true
                                                            }
                                                        }
                                                    }
                                                }
                                            ],
                                            "anyToAll": 1
                                        }
                                    }
                                ],
                                "anyToAll": 0.5
                            },
                            {
                                "type": "merge",
                                "wires": [
                                    {
                                        "type": "isUnknown",
                                        "wire": {
                                            "type": "merge",
                                            "wires": [
                                                {
                                                    "type": "input",
                                                    "signal": {
                                                        "type": "AnsweredOption",
                                                        "questionId": "OCD-s1",
                                                        "options": {
                                                            "type": "predicates",
                                                            "predicates": {
                                                                "805a9685-9fd4-55de-94cd-4a19dd4107b8": false,
                                                                "263b64b2-8e44-59b6-8761-9556068db3c2": true
                                                            }
                                                        }
                                                    }
                                                },
                                                {
                                                    "type": "input",
                                                    "signal": {
                                                        "type": "AnsweredOption",
                                                        "questionId": "OCD-s1.5",
                                                        "options": {
                                                            "type": "predicates",
                                                            "predicates": {
                                                                "6581df20-1f12-5563-9671-fd51c07fd098": true,
                                                                "934f0a07-8342-52aa-9c61-ec87c8d7b6b9": false
                                                            }
                                                        }
                                                    }
                                                },
                                                {
                                                    "type": "input",
                                                    "signal": {
                                                        "type": "AnsweredOption",
                                                        "questionId": "OCD-s2",
                                                        "options": {
                                                            "type": "predicates",
                                                            "predicates": {
                                                                "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": false,
                                                                "91fad731-ad66-57f3-87fd-70f4246e63ed": true
                                                            }
                                                        }
                                                    }
                                                },
                                                {
                                                    "type": "input",
                                                    "signal": {
                                                        "type": "AnsweredOption",
                                                        "questionId": "OCD-s2.5",
                                                        "options": {
                                                            "type": "predicates",
                                                            "predicates": {
                                                                "786ef202-eae2-58ab-bec2-7bbe1746fd5c": false,
                                                                "f543076e-83c3-56df-9539-f976a57d2fac": true
                                                            }
                                                        }
                                                    }
                                                }
                                            ],
                                            "anyToAll": 1
                                        }
                                    },
                                    {
                                        "type": "not",
                                        "wire": {
                                            "type": "merge",
                                            "wires": [
                                                {
                                                    "type": "input",
                                                    "signal": {
                                                        "type": "AnsweredOption",
                                                        "questionId": "OCD-s1",
                                                        "options": {
                                                            "type": "predicates",
                                                            "predicates": {
                                                                "805a9685-9fd4-55de-94cd-4a19dd4107b8": false,
                                                                "263b64b2-8e44-59b6-8761-9556068db3c2": true
                                                            }
                                                        }
                                                    }
                                                },
                                                {
                                                    "type": "input",
                                                    "signal": {
                                                        "type": "AnsweredOption",
                                                        "questionId": "OCD-s1.5",
                                                        "options": {
                                                            "type": "predicates",
                                                            "predicates": {
                                                                "6581df20-1f12-5563-9671-fd51c07fd098": true,
                                                                "934f0a07-8342-52aa-9c61-ec87c8d7b6b9": false
                                                            }
                                                        }
                                                    }
                                                },
                                                {
                                                    "type": "input",
                                                    "signal": {
                                                        "type": "AnsweredOption",
                                                        "questionId": "OCD-s2",
                                                        "options": {
                                                            "type": "predicates",
                                                            "predicates": {
                                                                "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": false,
                                                                "91fad731-ad66-57f3-87fd-70f4246e63ed": true
                                                            }
                                                        }
                                                    }
                                                },
                                                {
                                                    "type": "input",
                                                    "signal": {
                                                        "type": "AnsweredOption",
                                                        "questionId": "OCD-s2.5",
                                                        "options": {
                                                            "type": "predicates",
                                                            "predicates": {
                                                                "786ef202-eae2-58ab-bec2-7bbe1746fd5c": false,
                                                                "f543076e-83c3-56df-9539-f976a57d2fac": true
                                                            }
                                                        }
                                                    }
                                                }
                                            ],
                                            "anyToAll": 1
                                        }
                                    }
                                ],
                                "anyToAll": 0.5
                            },
                            {
                                "type": "not",
                                "wire": {
                                    "type": "isUnknown",
                                    "wire": {
                                        "type": "input",
                                        "signal": {
                                            "type": "AnsweredOption",
                                            "questionId": "OCD-15a",
                                            "options": {
                                                "type": "predicates",
                                                "predicates": {
                                                    "940dca6f-6249-503f-80e8-19590e269d2e": true,
                                                    "84d8ca85-4007-5557-aa20-b6f3b32a5819": false,
                                                    "d1f530a9-4ad1-5d89-8d08-2d819227639b": false,
                                                    "3ed49476-9c02-5a93-b8fc-3921a14daaf3": false,
                                                    "1272197a-b659-540a-80ee-1a5ec9b59d3c": false
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        ],
                        "anyToAll": 1
                    }
                ],
                "anyToAll": 0.5
            }
        ],
        "anyToAll": 1
    },
    "and[OCD-s1=1, or[and[OCD-s1.5=1, or[isUndefined(and[OCD-s2=1, OCD-s2.5=1]), not(and[OCD-s2=1, OCD-s2.5=1])], or[OCD-s2=0, and[OCD-s2=1, OCD-s2.5=0]], not(isUndefined(OCD-7a=0))], and[or[OCD-s2=0, and[OCD-s1.5=1, OCD-s2=1, OCD-s2.5=0, or[isUndefined(and[OCD-s1=0, OCD-s2=1]), not(and[OCD-s1=0, OCD-s2=1])]]], not(isUndefined(OCD-15a=0))]]]": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCD-s1",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "805a9685-9fd4-55de-94cd-4a19dd4107b8": false,
                            "263b64b2-8e44-59b6-8761-9556068db3c2": true
                        }
                    }
                }
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "OCD-s1.5",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "6581df20-1f12-5563-9671-fd51c07fd098": false,
                                            "934f0a07-8342-52aa-9c61-ec87c8d7b6b9": true
                                        }
                                    }
                                }
                            },
                            {
                                "type": "merge",
                                "wires": [
                                    {
                                        "type": "isUnknown",
                                        "wire": {
                                            "type": "merge",
                                            "wires": [
                                                {
                                                    "type": "input",
                                                    "signal": {
                                                        "type": "AnsweredOption",
                                                        "questionId": "OCD-s2",
                                                        "options": {
                                                            "type": "predicates",
                                                            "predicates": {
                                                                "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": false,
                                                                "91fad731-ad66-57f3-87fd-70f4246e63ed": true
                                                            }
                                                        }
                                                    }
                                                },
                                                {
                                                    "type": "input",
                                                    "signal": {
                                                        "type": "AnsweredOption",
                                                        "questionId": "OCD-s2.5",
                                                        "options": {
                                                            "type": "predicates",
                                                            "predicates": {
                                                                "786ef202-eae2-58ab-bec2-7bbe1746fd5c": false,
                                                                "f543076e-83c3-56df-9539-f976a57d2fac": true
                                                            }
                                                        }
                                                    }
                                                }
                                            ],
                                            "anyToAll": 1
                                        }
                                    },
                                    {
                                        "type": "not",
                                        "wire": {
                                            "type": "merge",
                                            "wires": [
                                                {
                                                    "type": "input",
                                                    "signal": {
                                                        "type": "AnsweredOption",
                                                        "questionId": "OCD-s2",
                                                        "options": {
                                                            "type": "predicates",
                                                            "predicates": {
                                                                "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": false,
                                                                "91fad731-ad66-57f3-87fd-70f4246e63ed": true
                                                            }
                                                        }
                                                    }
                                                },
                                                {
                                                    "type": "input",
                                                    "signal": {
                                                        "type": "AnsweredOption",
                                                        "questionId": "OCD-s2.5",
                                                        "options": {
                                                            "type": "predicates",
                                                            "predicates": {
                                                                "786ef202-eae2-58ab-bec2-7bbe1746fd5c": false,
                                                                "f543076e-83c3-56df-9539-f976a57d2fac": true
                                                            }
                                                        }
                                                    }
                                                }
                                            ],
                                            "anyToAll": 1
                                        }
                                    }
                                ],
                                "anyToAll": 0.5
                            },
                            {
                                "type": "merge",
                                "wires": [
                                    {
                                        "type": "input",
                                        "signal": {
                                            "type": "AnsweredOption",
                                            "questionId": "OCD-s2",
                                            "options": {
                                                "type": "predicates",
                                                "predicates": {
                                                    "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": true,
                                                    "91fad731-ad66-57f3-87fd-70f4246e63ed": false
                                                }
                                            }
                                        }
                                    },
                                    {
                                        "type": "merge",
                                        "wires": [
                                            {
                                                "type": "input",
                                                "signal": {
                                                    "type": "AnsweredOption",
                                                    "questionId": "OCD-s2",
                                                    "options": {
                                                        "type": "predicates",
                                                        "predicates": {
                                                            "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": false,
                                                            "91fad731-ad66-57f3-87fd-70f4246e63ed": true
                                                        }
                                                    }
                                                }
                                            },
                                            {
                                                "type": "input",
                                                "signal": {
                                                    "type": "AnsweredOption",
                                                    "questionId": "OCD-s2.5",
                                                    "options": {
                                                        "type": "predicates",
                                                        "predicates": {
                                                            "786ef202-eae2-58ab-bec2-7bbe1746fd5c": true,
                                                            "f543076e-83c3-56df-9539-f976a57d2fac": false
                                                        }
                                                    }
                                                }
                                            }
                                        ],
                                        "anyToAll": 1
                                    }
                                ],
                                "anyToAll": 0.5
                            },
                            {
                                "type": "not",
                                "wire": {
                                    "type": "isUnknown",
                                    "wire": {
                                        "type": "input",
                                        "signal": {
                                            "type": "AnsweredOption",
                                            "questionId": "OCD-7a",
                                            "options": {
                                                "type": "predicates",
                                                "predicates": {
                                                    "73175241-22a6-5d30-8d75-ae6a9f0851e3": true,
                                                    "95603b88-c05c-5344-ab26-5d338cd499ff": false,
                                                    "76162037-755b-57b1-93d4-4f9ab77522dc": false,
                                                    "42716cc5-7285-5b4e-9d65-4be48e7606b1": false,
                                                    "d1a809d8-8068-5778-a74f-a8f209fff96e": false
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        ],
                        "anyToAll": 1
                    },
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "merge",
                                "wires": [
                                    {
                                        "type": "input",
                                        "signal": {
                                            "type": "AnsweredOption",
                                            "questionId": "OCD-s2",
                                            "options": {
                                                "type": "predicates",
                                                "predicates": {
                                                    "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": true,
                                                    "91fad731-ad66-57f3-87fd-70f4246e63ed": false
                                                }
                                            }
                                        }
                                    },
                                    {
                                        "type": "merge",
                                        "wires": [
                                            {
                                                "type": "input",
                                                "signal": {
                                                    "type": "AnsweredOption",
                                                    "questionId": "OCD-s1.5",
                                                    "options": {
                                                        "type": "predicates",
                                                        "predicates": {
                                                            "6581df20-1f12-5563-9671-fd51c07fd098": false,
                                                            "934f0a07-8342-52aa-9c61-ec87c8d7b6b9": true
                                                        }
                                                    }
                                                }
                                            },
                                            {
                                                "type": "input",
                                                "signal": {
                                                    "type": "AnsweredOption",
                                                    "questionId": "OCD-s2",
                                                    "options": {
                                                        "type": "predicates",
                                                        "predicates": {
                                                            "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": false,
                                                            "91fad731-ad66-57f3-87fd-70f4246e63ed": true
                                                        }
                                                    }
                                                }
                                            },
                                            {
                                                "type": "input",
                                                "signal": {
                                                    "type": "AnsweredOption",
                                                    "questionId": "OCD-s2.5",
                                                    "options": {
                                                        "type": "predicates",
                                                        "predicates": {
                                                            "786ef202-eae2-58ab-bec2-7bbe1746fd5c": true,
                                                            "f543076e-83c3-56df-9539-f976a57d2fac": false
                                                        }
                                                    }
                                                }
                                            },
                                            {
                                                "type": "merge",
                                                "wires": [
                                                    {
                                                        "type": "isUnknown",
                                                        "wire": {
                                                            "type": "merge",
                                                            "wires": [
                                                                {
                                                                    "type": "input",
                                                                    "signal": {
                                                                        "type": "AnsweredOption",
                                                                        "questionId": "OCD-s1",
                                                                        "options": {
                                                                            "type": "predicates",
                                                                            "predicates": {
                                                                                "805a9685-9fd4-55de-94cd-4a19dd4107b8": true,
                                                                                "263b64b2-8e44-59b6-8761-9556068db3c2": false
                                                                            }
                                                                        }
                                                                    }
                                                                },
                                                                {
                                                                    "type": "input",
                                                                    "signal": {
                                                                        "type": "AnsweredOption",
                                                                        "questionId": "OCD-s2",
                                                                        "options": {
                                                                            "type": "predicates",
                                                                            "predicates": {
                                                                                "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": false,
                                                                                "91fad731-ad66-57f3-87fd-70f4246e63ed": true
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            ],
                                                            "anyToAll": 1
                                                        }
                                                    },
                                                    {
                                                        "type": "not",
                                                        "wire": {
                                                            "type": "merge",
                                                            "wires": [
                                                                {
                                                                    "type": "input",
                                                                    "signal": {
                                                                        "type": "AnsweredOption",
                                                                        "questionId": "OCD-s1",
                                                                        "options": {
                                                                            "type": "predicates",
                                                                            "predicates": {
                                                                                "805a9685-9fd4-55de-94cd-4a19dd4107b8": true,
                                                                                "263b64b2-8e44-59b6-8761-9556068db3c2": false
                                                                            }
                                                                        }
                                                                    }
                                                                },
                                                                {
                                                                    "type": "input",
                                                                    "signal": {
                                                                        "type": "AnsweredOption",
                                                                        "questionId": "OCD-s2",
                                                                        "options": {
                                                                            "type": "predicates",
                                                                            "predicates": {
                                                                                "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": false,
                                                                                "91fad731-ad66-57f3-87fd-70f4246e63ed": true
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            ],
                                                            "anyToAll": 1
                                                        }
                                                    }
                                                ],
                                                "anyToAll": 0.5
                                            }
                                        ],
                                        "anyToAll": 1
                                    }
                                ],
                                "anyToAll": 0.5
                            },
                            {
                                "type": "not",
                                "wire": {
                                    "type": "isUnknown",
                                    "wire": {
                                        "type": "input",
                                        "signal": {
                                            "type": "AnsweredOption",
                                            "questionId": "OCD-15a",
                                            "options": {
                                                "type": "predicates",
                                                "predicates": {
                                                    "940dca6f-6249-503f-80e8-19590e269d2e": true,
                                                    "84d8ca85-4007-5557-aa20-b6f3b32a5819": false,
                                                    "d1f530a9-4ad1-5d89-8d08-2d819227639b": false,
                                                    "3ed49476-9c02-5a93-b8fc-3921a14daaf3": false,
                                                    "1272197a-b659-540a-80ee-1a5ec9b59d3c": false
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        ],
                        "anyToAll": 1
                    }
                ],
                "anyToAll": 0.5
            }
        ],
        "anyToAll": 1
    },
    "and[OCD-s2=1, or[isUndefined(and[OCD-s1=1, OCD-s2=0]), not(and[OCD-s1=1, OCD-s2=0])], or[OCD-s1=0, and[OCD-s1=1, OCD-s1.5=0, OCD-s2.5=1, or[isUndefined(and[OCD-s1=1, OCD-s1.5=1, OCD-s2=1, OCD-s2.5=0]), not(and[OCD-s1=1, OCD-s1.5=1, OCD-s2=1, OCD-s2.5=0])]]], not(isUndefined(OCD-15a=0))]": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCD-s2",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": false,
                            "91fad731-ad66-57f3-87fd-70f4246e63ed": true
                        }
                    }
                }
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "OCD-s1",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "805a9685-9fd4-55de-94cd-4a19dd4107b8": false,
                                                "263b64b2-8e44-59b6-8761-9556068db3c2": true
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "OCD-s2",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": true,
                                                "91fad731-ad66-57f3-87fd-70f4246e63ed": false
                                            }
                                        }
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "OCD-s1",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "805a9685-9fd4-55de-94cd-4a19dd4107b8": false,
                                                "263b64b2-8e44-59b6-8761-9556068db3c2": true
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "OCD-s2",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": true,
                                                "91fad731-ad66-57f3-87fd-70f4246e63ed": false
                                            }
                                        }
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "OCD-s1",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "805a9685-9fd4-55de-94cd-4a19dd4107b8": true,
                                    "263b64b2-8e44-59b6-8761-9556068db3c2": false
                                }
                            }
                        }
                    },
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "OCD-s1",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "805a9685-9fd4-55de-94cd-4a19dd4107b8": false,
                                            "263b64b2-8e44-59b6-8761-9556068db3c2": true
                                        }
                                    }
                                }
                            },
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "OCD-s1.5",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "6581df20-1f12-5563-9671-fd51c07fd098": true,
                                            "934f0a07-8342-52aa-9c61-ec87c8d7b6b9": false
                                        }
                                    }
                                }
                            },
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "OCD-s2.5",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "786ef202-eae2-58ab-bec2-7bbe1746fd5c": false,
                                            "f543076e-83c3-56df-9539-f976a57d2fac": true
                                        }
                                    }
                                }
                            },
                            {
                                "type": "merge",
                                "wires": [
                                    {
                                        "type": "isUnknown",
                                        "wire": {
                                            "type": "merge",
                                            "wires": [
                                                {
                                                    "type": "input",
                                                    "signal": {
                                                        "type": "AnsweredOption",
                                                        "questionId": "OCD-s1",
                                                        "options": {
                                                            "type": "predicates",
                                                            "predicates": {
                                                                "805a9685-9fd4-55de-94cd-4a19dd4107b8": false,
                                                                "263b64b2-8e44-59b6-8761-9556068db3c2": true
                                                            }
                                                        }
                                                    }
                                                },
                                                {
                                                    "type": "input",
                                                    "signal": {
                                                        "type": "AnsweredOption",
                                                        "questionId": "OCD-s1.5",
                                                        "options": {
                                                            "type": "predicates",
                                                            "predicates": {
                                                                "6581df20-1f12-5563-9671-fd51c07fd098": false,
                                                                "934f0a07-8342-52aa-9c61-ec87c8d7b6b9": true
                                                            }
                                                        }
                                                    }
                                                },
                                                {
                                                    "type": "input",
                                                    "signal": {
                                                        "type": "AnsweredOption",
                                                        "questionId": "OCD-s2",
                                                        "options": {
                                                            "type": "predicates",
                                                            "predicates": {
                                                                "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": false,
                                                                "91fad731-ad66-57f3-87fd-70f4246e63ed": true
                                                            }
                                                        }
                                                    }
                                                },
                                                {
                                                    "type": "input",
                                                    "signal": {
                                                        "type": "AnsweredOption",
                                                        "questionId": "OCD-s2.5",
                                                        "options": {
                                                            "type": "predicates",
                                                            "predicates": {
                                                                "786ef202-eae2-58ab-bec2-7bbe1746fd5c": true,
                                                                "f543076e-83c3-56df-9539-f976a57d2fac": false
                                                            }
                                                        }
                                                    }
                                                }
                                            ],
                                            "anyToAll": 1
                                        }
                                    },
                                    {
                                        "type": "not",
                                        "wire": {
                                            "type": "merge",
                                            "wires": [
                                                {
                                                    "type": "input",
                                                    "signal": {
                                                        "type": "AnsweredOption",
                                                        "questionId": "OCD-s1",
                                                        "options": {
                                                            "type": "predicates",
                                                            "predicates": {
                                                                "805a9685-9fd4-55de-94cd-4a19dd4107b8": false,
                                                                "263b64b2-8e44-59b6-8761-9556068db3c2": true
                                                            }
                                                        }
                                                    }
                                                },
                                                {
                                                    "type": "input",
                                                    "signal": {
                                                        "type": "AnsweredOption",
                                                        "questionId": "OCD-s1.5",
                                                        "options": {
                                                            "type": "predicates",
                                                            "predicates": {
                                                                "6581df20-1f12-5563-9671-fd51c07fd098": false,
                                                                "934f0a07-8342-52aa-9c61-ec87c8d7b6b9": true
                                                            }
                                                        }
                                                    }
                                                },
                                                {
                                                    "type": "input",
                                                    "signal": {
                                                        "type": "AnsweredOption",
                                                        "questionId": "OCD-s2",
                                                        "options": {
                                                            "type": "predicates",
                                                            "predicates": {
                                                                "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": false,
                                                                "91fad731-ad66-57f3-87fd-70f4246e63ed": true
                                                            }
                                                        }
                                                    }
                                                },
                                                {
                                                    "type": "input",
                                                    "signal": {
                                                        "type": "AnsweredOption",
                                                        "questionId": "OCD-s2.5",
                                                        "options": {
                                                            "type": "predicates",
                                                            "predicates": {
                                                                "786ef202-eae2-58ab-bec2-7bbe1746fd5c": true,
                                                                "f543076e-83c3-56df-9539-f976a57d2fac": false
                                                            }
                                                        }
                                                    }
                                                }
                                            ],
                                            "anyToAll": 1
                                        }
                                    }
                                ],
                                "anyToAll": 0.5
                            }
                        ],
                        "anyToAll": 1
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "not",
                "wire": {
                    "type": "isUnknown",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "OCD-15a",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "940dca6f-6249-503f-80e8-19590e269d2e": true,
                                    "84d8ca85-4007-5557-aa20-b6f3b32a5819": false,
                                    "d1f530a9-4ad1-5d89-8d08-2d819227639b": false,
                                    "3ed49476-9c02-5a93-b8fc-3921a14daaf3": false,
                                    "1272197a-b659-540a-80ee-1a5ec9b59d3c": false
                                }
                            }
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "or[and[or[OCD-7a>=0.5, OCD-15a>=0.5, OCD-19a>=0.5], not(isUndefined(OCD-19a=0))], and[or[OCD-7a>=0.5, OCD-o19a>=0.5], not(isUndefined(OCD-o19a=0))]]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "OCD-7a",
                                    "options": {
                                        "type": "severities",
                                        "severities": {
                                            "73175241-22a6-5d30-8d75-ae6a9f0851e3": 0.0066928509242848554,
                                            "95603b88-c05c-5344-ab26-5d338cd499ff": 0.07585818002124355,
                                            "76162037-755b-57b1-93d4-4f9ab77522dc": 0.5,
                                            "42716cc5-7285-5b4e-9d65-4be48e7606b1": 0.9241418199787566,
                                            "d1a809d8-8068-5778-a74f-a8f209fff96e": 0.9933071490757153
                                        }
                                    }
                                }
                            },
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "OCD-15a",
                                    "options": {
                                        "type": "severities",
                                        "severities": {
                                            "940dca6f-6249-503f-80e8-19590e269d2e": 0.0066928509242848554,
                                            "84d8ca85-4007-5557-aa20-b6f3b32a5819": 0.07585818002124355,
                                            "d1f530a9-4ad1-5d89-8d08-2d819227639b": 0.5,
                                            "3ed49476-9c02-5a93-b8fc-3921a14daaf3": 0.9241418199787566,
                                            "1272197a-b659-540a-80ee-1a5ec9b59d3c": 0.9933071490757153
                                        }
                                    }
                                }
                            },
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "OCD-19a",
                                    "options": {
                                        "type": "severities",
                                        "severities": {
                                            "5f1c8429-44a8-5ffe-b25a-83342c0377d2": 0.0066928509242848554,
                                            "cc0e9f4f-d2cf-5611-8b94-f2de2ba87c8d": 0.07585818002124355,
                                            "f177854d-7188-5142-8325-b27ef8aac03f": 0.5,
                                            "e3809079-bc8b-52c6-b811-7f6f2437e4a9": 0.9241418199787566,
                                            "8b96bfb1-38bb-56f7-a9cb-693bd9bed98a": 0.9933071490757153
                                        }
                                    }
                                }
                            }
                        ],
                        "anyToAll": 0.3333333333333333
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "isUnknown",
                            "wire": {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "OCD-19a",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "5f1c8429-44a8-5ffe-b25a-83342c0377d2": true,
                                            "cc0e9f4f-d2cf-5611-8b94-f2de2ba87c8d": false,
                                            "f177854d-7188-5142-8325-b27ef8aac03f": false,
                                            "e3809079-bc8b-52c6-b811-7f6f2437e4a9": false,
                                            "8b96bfb1-38bb-56f7-a9cb-693bd9bed98a": false
                                        }
                                    }
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 1
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "OCD-7a",
                                    "options": {
                                        "type": "severities",
                                        "severities": {
                                            "73175241-22a6-5d30-8d75-ae6a9f0851e3": 0.0066928509242848554,
                                            "95603b88-c05c-5344-ab26-5d338cd499ff": 0.07585818002124355,
                                            "76162037-755b-57b1-93d4-4f9ab77522dc": 0.5,
                                            "42716cc5-7285-5b4e-9d65-4be48e7606b1": 0.9241418199787566,
                                            "d1a809d8-8068-5778-a74f-a8f209fff96e": 0.9933071490757153
                                        }
                                    }
                                }
                            },
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "OCD-o19a",
                                    "options": {
                                        "type": "severities",
                                        "severities": {
                                            "f58cb8d9-832c-58f1-ad61-a38722e1ac30": 0.0066928509242848554,
                                            "4fec4813-10b7-5b75-a92a-c3ef0bd0829f": 0.07585818002124355,
                                            "a8cdbe2a-7125-51f9-a704-7cc6b5e94a7b": 0.5,
                                            "aa57f892-e9e7-52b0-a516-b7541d79ad82": 0.9241418199787566,
                                            "d1baedd2-5bdb-5255-aa0a-5fb0bdbe86d1": 0.9933071490757153
                                        }
                                    }
                                }
                            }
                        ],
                        "anyToAll": 0.5
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "isUnknown",
                            "wire": {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "OCD-o19a",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "f58cb8d9-832c-58f1-ad61-a38722e1ac30": true,
                                            "4fec4813-10b7-5b75-a92a-c3ef0bd0829f": false,
                                            "a8cdbe2a-7125-51f9-a704-7cc6b5e94a7b": false,
                                            "aa57f892-e9e7-52b0-a516-b7541d79ad82": false,
                                            "d1baedd2-5bdb-5255-aa0a-5fb0bdbe86d1": false
                                        }
                                    }
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 1
            }
        ],
        "anyToAll": 0.5
    },
    "or[and[or[OCD-15a>=0.5, OCD-c19a>=0.5], not(isUndefined(OCD-c19a=0))], and[or[isUndefined(and[OCD-s1=1, OCD-s1.5=1, OCD-s2=0]), not(and[OCD-s1=1, OCD-s1.5=1, OCD-s2=0])], or[isUndefined(and[OCD-s1=1, OCD-s1.5=1, OCD-s2=1, OCD-s2.5=0]), not(and[OCD-s1=1, OCD-s1.5=1, OCD-s2=1, OCD-s2.5=0])], not(isUndefined(OCD-3a=0))]]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "OCD-15a",
                                    "options": {
                                        "type": "severities",
                                        "severities": {
                                            "940dca6f-6249-503f-80e8-19590e269d2e": 0.0066928509242848554,
                                            "84d8ca85-4007-5557-aa20-b6f3b32a5819": 0.07585818002124355,
                                            "d1f530a9-4ad1-5d89-8d08-2d819227639b": 0.5,
                                            "3ed49476-9c02-5a93-b8fc-3921a14daaf3": 0.9241418199787566,
                                            "1272197a-b659-540a-80ee-1a5ec9b59d3c": 0.9933071490757153
                                        }
                                    }
                                }
                            },
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "OCD-c19a",
                                    "options": {
                                        "type": "severities",
                                        "severities": {
                                            "85b5a64f-a893-5a4d-98c5-e7ab4f819b8b": 0.0066928509242848554,
                                            "b8bc16e5-c342-5285-8986-82ddbf511a09": 0.07585818002124355,
                                            "dfdfabbf-250c-57bc-99c2-017778956597": 0.5,
                                            "17b4fbea-ef73-56af-ae4d-765c41ceedd1": 0.9241418199787566,
                                            "f239adce-d665-5b87-ac6f-23b1fe1d840c": 0.9933071490757153
                                        }
                                    }
                                }
                            }
                        ],
                        "anyToAll": 0.5
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "isUnknown",
                            "wire": {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "OCD-c19a",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "85b5a64f-a893-5a4d-98c5-e7ab4f819b8b": true,
                                            "b8bc16e5-c342-5285-8986-82ddbf511a09": false,
                                            "dfdfabbf-250c-57bc-99c2-017778956597": false,
                                            "17b4fbea-ef73-56af-ae4d-765c41ceedd1": false,
                                            "f239adce-d665-5b87-ac6f-23b1fe1d840c": false
                                        }
                                    }
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 1
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "isUnknown",
                                "wire": {
                                    "type": "merge",
                                    "wires": [
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s1",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "805a9685-9fd4-55de-94cd-4a19dd4107b8": false,
                                                        "263b64b2-8e44-59b6-8761-9556068db3c2": true
                                                    }
                                                }
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s1.5",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "6581df20-1f12-5563-9671-fd51c07fd098": false,
                                                        "934f0a07-8342-52aa-9c61-ec87c8d7b6b9": true
                                                    }
                                                }
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s2",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": true,
                                                        "91fad731-ad66-57f3-87fd-70f4246e63ed": false
                                                    }
                                                }
                                            }
                                        }
                                    ],
                                    "anyToAll": 1
                                }
                            },
                            {
                                "type": "not",
                                "wire": {
                                    "type": "merge",
                                    "wires": [
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s1",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "805a9685-9fd4-55de-94cd-4a19dd4107b8": false,
                                                        "263b64b2-8e44-59b6-8761-9556068db3c2": true
                                                    }
                                                }
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s1.5",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "6581df20-1f12-5563-9671-fd51c07fd098": false,
                                                        "934f0a07-8342-52aa-9c61-ec87c8d7b6b9": true
                                                    }
                                                }
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s2",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": true,
                                                        "91fad731-ad66-57f3-87fd-70f4246e63ed": false
                                                    }
                                                }
                                            }
                                        }
                                    ],
                                    "anyToAll": 1
                                }
                            }
                        ],
                        "anyToAll": 0.5
                    },
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "isUnknown",
                                "wire": {
                                    "type": "merge",
                                    "wires": [
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s1",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "805a9685-9fd4-55de-94cd-4a19dd4107b8": false,
                                                        "263b64b2-8e44-59b6-8761-9556068db3c2": true
                                                    }
                                                }
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s1.5",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "6581df20-1f12-5563-9671-fd51c07fd098": false,
                                                        "934f0a07-8342-52aa-9c61-ec87c8d7b6b9": true
                                                    }
                                                }
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s2",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": false,
                                                        "91fad731-ad66-57f3-87fd-70f4246e63ed": true
                                                    }
                                                }
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s2.5",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "786ef202-eae2-58ab-bec2-7bbe1746fd5c": true,
                                                        "f543076e-83c3-56df-9539-f976a57d2fac": false
                                                    }
                                                }
                                            }
                                        }
                                    ],
                                    "anyToAll": 1
                                }
                            },
                            {
                                "type": "not",
                                "wire": {
                                    "type": "merge",
                                    "wires": [
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s1",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "805a9685-9fd4-55de-94cd-4a19dd4107b8": false,
                                                        "263b64b2-8e44-59b6-8761-9556068db3c2": true
                                                    }
                                                }
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s1.5",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "6581df20-1f12-5563-9671-fd51c07fd098": false,
                                                        "934f0a07-8342-52aa-9c61-ec87c8d7b6b9": true
                                                    }
                                                }
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s2",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": false,
                                                        "91fad731-ad66-57f3-87fd-70f4246e63ed": true
                                                    }
                                                }
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s2.5",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "786ef202-eae2-58ab-bec2-7bbe1746fd5c": true,
                                                        "f543076e-83c3-56df-9539-f976a57d2fac": false
                                                    }
                                                }
                                            }
                                        }
                                    ],
                                    "anyToAll": 1
                                }
                            }
                        ],
                        "anyToAll": 0.5
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "isUnknown",
                            "wire": {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "OCD-3a",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "375ddbf7-072f-50cc-b5ca-f7dd2b8d301c": false,
                                            "400ab343-bdc4-567f-81a4-9e6a6d192ec5": false,
                                            "5f203c70-531e-5f85-b902-825d95db880a": false,
                                            "86a75005-4b0f-5cdc-bdf2-dc356189360e": false,
                                            "41541011-61c0-5d8e-bea5-ac6618eb0499": false,
                                            "654f0850-433c-5068-81b1-cbc8da0115d4": false
                                        }
                                    }
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 1
            }
        ],
        "anyToAll": 0.5
    },
    "or[and[OCD-s1=1, OCD-s1.5=1, or[OCD-s2=0, and[OCD-s2=1, OCD-s2.5=0]], not(isUndefined(OCD-3a=0))], and[or[isUndefined(and[OCD-s1=0, OCD-s2=1, OCD-s2.5=1]), not(and[OCD-s1=0, OCD-s2=1, OCD-s2.5=1])], or[isUndefined(and[OCD-s1=1, OCD-s1.5=0, OCD-s2=1, OCD-s2.5=1]), not(and[OCD-s1=1, OCD-s1.5=0, OCD-s2=1, OCD-s2.5=1])], not(isUndefined(OCD-4a=0))]]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "OCD-s1",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "805a9685-9fd4-55de-94cd-4a19dd4107b8": false,
                                    "263b64b2-8e44-59b6-8761-9556068db3c2": true
                                }
                            }
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "OCD-s1.5",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "6581df20-1f12-5563-9671-fd51c07fd098": false,
                                    "934f0a07-8342-52aa-9c61-ec87c8d7b6b9": true
                                }
                            }
                        }
                    },
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "OCD-s2",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": true,
                                            "91fad731-ad66-57f3-87fd-70f4246e63ed": false
                                        }
                                    }
                                }
                            },
                            {
                                "type": "merge",
                                "wires": [
                                    {
                                        "type": "input",
                                        "signal": {
                                            "type": "AnsweredOption",
                                            "questionId": "OCD-s2",
                                            "options": {
                                                "type": "predicates",
                                                "predicates": {
                                                    "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": false,
                                                    "91fad731-ad66-57f3-87fd-70f4246e63ed": true
                                                }
                                            }
                                        }
                                    },
                                    {
                                        "type": "input",
                                        "signal": {
                                            "type": "AnsweredOption",
                                            "questionId": "OCD-s2.5",
                                            "options": {
                                                "type": "predicates",
                                                "predicates": {
                                                    "786ef202-eae2-58ab-bec2-7bbe1746fd5c": true,
                                                    "f543076e-83c3-56df-9539-f976a57d2fac": false
                                                }
                                            }
                                        }
                                    }
                                ],
                                "anyToAll": 1
                            }
                        ],
                        "anyToAll": 0.5
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "isUnknown",
                            "wire": {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "OCD-3a",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "375ddbf7-072f-50cc-b5ca-f7dd2b8d301c": false,
                                            "400ab343-bdc4-567f-81a4-9e6a6d192ec5": false,
                                            "5f203c70-531e-5f85-b902-825d95db880a": false,
                                            "86a75005-4b0f-5cdc-bdf2-dc356189360e": false,
                                            "41541011-61c0-5d8e-bea5-ac6618eb0499": false,
                                            "654f0850-433c-5068-81b1-cbc8da0115d4": false
                                        }
                                    }
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 1
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "isUnknown",
                                "wire": {
                                    "type": "merge",
                                    "wires": [
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s1",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "805a9685-9fd4-55de-94cd-4a19dd4107b8": true,
                                                        "263b64b2-8e44-59b6-8761-9556068db3c2": false
                                                    }
                                                }
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s2",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": false,
                                                        "91fad731-ad66-57f3-87fd-70f4246e63ed": true
                                                    }
                                                }
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s2.5",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "786ef202-eae2-58ab-bec2-7bbe1746fd5c": false,
                                                        "f543076e-83c3-56df-9539-f976a57d2fac": true
                                                    }
                                                }
                                            }
                                        }
                                    ],
                                    "anyToAll": 1
                                }
                            },
                            {
                                "type": "not",
                                "wire": {
                                    "type": "merge",
                                    "wires": [
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s1",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "805a9685-9fd4-55de-94cd-4a19dd4107b8": true,
                                                        "263b64b2-8e44-59b6-8761-9556068db3c2": false
                                                    }
                                                }
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s2",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": false,
                                                        "91fad731-ad66-57f3-87fd-70f4246e63ed": true
                                                    }
                                                }
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s2.5",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "786ef202-eae2-58ab-bec2-7bbe1746fd5c": false,
                                                        "f543076e-83c3-56df-9539-f976a57d2fac": true
                                                    }
                                                }
                                            }
                                        }
                                    ],
                                    "anyToAll": 1
                                }
                            }
                        ],
                        "anyToAll": 0.5
                    },
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "isUnknown",
                                "wire": {
                                    "type": "merge",
                                    "wires": [
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s1",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "805a9685-9fd4-55de-94cd-4a19dd4107b8": false,
                                                        "263b64b2-8e44-59b6-8761-9556068db3c2": true
                                                    }
                                                }
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s1.5",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "6581df20-1f12-5563-9671-fd51c07fd098": true,
                                                        "934f0a07-8342-52aa-9c61-ec87c8d7b6b9": false
                                                    }
                                                }
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s2",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": false,
                                                        "91fad731-ad66-57f3-87fd-70f4246e63ed": true
                                                    }
                                                }
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s2.5",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "786ef202-eae2-58ab-bec2-7bbe1746fd5c": false,
                                                        "f543076e-83c3-56df-9539-f976a57d2fac": true
                                                    }
                                                }
                                            }
                                        }
                                    ],
                                    "anyToAll": 1
                                }
                            },
                            {
                                "type": "not",
                                "wire": {
                                    "type": "merge",
                                    "wires": [
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s1",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "805a9685-9fd4-55de-94cd-4a19dd4107b8": false,
                                                        "263b64b2-8e44-59b6-8761-9556068db3c2": true
                                                    }
                                                }
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s1.5",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "6581df20-1f12-5563-9671-fd51c07fd098": true,
                                                        "934f0a07-8342-52aa-9c61-ec87c8d7b6b9": false
                                                    }
                                                }
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s2",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": false,
                                                        "91fad731-ad66-57f3-87fd-70f4246e63ed": true
                                                    }
                                                }
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s2.5",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "786ef202-eae2-58ab-bec2-7bbe1746fd5c": false,
                                                        "f543076e-83c3-56df-9539-f976a57d2fac": true
                                                    }
                                                }
                                            }
                                        }
                                    ],
                                    "anyToAll": 1
                                }
                            }
                        ],
                        "anyToAll": 0.5
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "isUnknown",
                            "wire": {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "OCD-4a",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "2edc3093-336a-5fc1-b7ac-3466362526bb": false,
                                            "bd33b2e5-ba63-507e-bd58-7576ee2f60f0": false,
                                            "4151b231-67fb-5826-a373-b716f21235f1": false,
                                            "dc2fd953-74d4-5446-be05-66611ee351d5": false,
                                            "7b4dd465-0ec8-551c-9086-241199467acb": false,
                                            "3e8b1149-e933-56f0-986f-88ae3cc6eee4": false
                                        }
                                    }
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 1
            }
        ],
        "anyToAll": 0.5
    },
    "and[or[isUndefined(and[OCD-s1=0, OCD-s2=1]), not(and[OCD-s1=0, OCD-s2=1])], or[isUndefined(and[OCD-s1=1, OCD-s1.5=1, OCD-s2=1, OCD-s2.5=0]), not(and[OCD-s1=1, OCD-s1.5=1, OCD-s2=1, OCD-s2.5=0])], not(isUndefined(OCD-5a=0))]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "OCD-s1",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "805a9685-9fd4-55de-94cd-4a19dd4107b8": true,
                                                "263b64b2-8e44-59b6-8761-9556068db3c2": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "OCD-s2",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": false,
                                                "91fad731-ad66-57f3-87fd-70f4246e63ed": true
                                            }
                                        }
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "OCD-s1",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "805a9685-9fd4-55de-94cd-4a19dd4107b8": true,
                                                "263b64b2-8e44-59b6-8761-9556068db3c2": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "OCD-s2",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": false,
                                                "91fad731-ad66-57f3-87fd-70f4246e63ed": true
                                            }
                                        }
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "OCD-s1",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "805a9685-9fd4-55de-94cd-4a19dd4107b8": false,
                                                "263b64b2-8e44-59b6-8761-9556068db3c2": true
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "OCD-s1.5",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "6581df20-1f12-5563-9671-fd51c07fd098": false,
                                                "934f0a07-8342-52aa-9c61-ec87c8d7b6b9": true
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "OCD-s2",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": false,
                                                "91fad731-ad66-57f3-87fd-70f4246e63ed": true
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "OCD-s2.5",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "786ef202-eae2-58ab-bec2-7bbe1746fd5c": true,
                                                "f543076e-83c3-56df-9539-f976a57d2fac": false
                                            }
                                        }
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "OCD-s1",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "805a9685-9fd4-55de-94cd-4a19dd4107b8": false,
                                                "263b64b2-8e44-59b6-8761-9556068db3c2": true
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "OCD-s1.5",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "6581df20-1f12-5563-9671-fd51c07fd098": false,
                                                "934f0a07-8342-52aa-9c61-ec87c8d7b6b9": true
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "OCD-s2",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": false,
                                                "91fad731-ad66-57f3-87fd-70f4246e63ed": true
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "OCD-s2.5",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "786ef202-eae2-58ab-bec2-7bbe1746fd5c": true,
                                                "f543076e-83c3-56df-9539-f976a57d2fac": false
                                            }
                                        }
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "not",
                "wire": {
                    "type": "isUnknown",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "OCD-5a",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "63785b0d-d331-58f4-bf9f-d7d402daea1b": true,
                                    "2435ec34-7006-5e0a-b25b-021f0ff5e79e": false,
                                    "fc1867dc-1ad7-5599-848b-dea1a7dd7584": false,
                                    "dffa8a56-9791-59eb-8f9c-3e7badb3a26e": false,
                                    "15202dc0-e463-59a3-886a-81521814b4a3": false
                                }
                            }
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "or[and[OCD-s2=1, or[OCD-s1=0, and[OCD-s1=1, OCD-s1.5=1, OCD-s2.5=0]], not(isUndefined(OCD-5a=0))], and[or[isUndefined(and[OCD-s1=0, OCD-s2=1]), not(and[OCD-s1=0, OCD-s2=1])], or[isUndefined(and[OCD-s1=1, OCD-s1.5=0, OCD-s2=1, OCD-s2.5=1]), not(and[OCD-s1=1, OCD-s1.5=0, OCD-s2=1, OCD-s2.5=1])], not(isUndefined(OCD-6a=0))]]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "OCD-s2",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": false,
                                    "91fad731-ad66-57f3-87fd-70f4246e63ed": true
                                }
                            }
                        }
                    },
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "OCD-s1",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "805a9685-9fd4-55de-94cd-4a19dd4107b8": true,
                                            "263b64b2-8e44-59b6-8761-9556068db3c2": false
                                        }
                                    }
                                }
                            },
                            {
                                "type": "merge",
                                "wires": [
                                    {
                                        "type": "input",
                                        "signal": {
                                            "type": "AnsweredOption",
                                            "questionId": "OCD-s1",
                                            "options": {
                                                "type": "predicates",
                                                "predicates": {
                                                    "805a9685-9fd4-55de-94cd-4a19dd4107b8": false,
                                                    "263b64b2-8e44-59b6-8761-9556068db3c2": true
                                                }
                                            }
                                        }
                                    },
                                    {
                                        "type": "input",
                                        "signal": {
                                            "type": "AnsweredOption",
                                            "questionId": "OCD-s1.5",
                                            "options": {
                                                "type": "predicates",
                                                "predicates": {
                                                    "6581df20-1f12-5563-9671-fd51c07fd098": false,
                                                    "934f0a07-8342-52aa-9c61-ec87c8d7b6b9": true
                                                }
                                            }
                                        }
                                    },
                                    {
                                        "type": "input",
                                        "signal": {
                                            "type": "AnsweredOption",
                                            "questionId": "OCD-s2.5",
                                            "options": {
                                                "type": "predicates",
                                                "predicates": {
                                                    "786ef202-eae2-58ab-bec2-7bbe1746fd5c": true,
                                                    "f543076e-83c3-56df-9539-f976a57d2fac": false
                                                }
                                            }
                                        }
                                    }
                                ],
                                "anyToAll": 1
                            }
                        ],
                        "anyToAll": 0.5
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "isUnknown",
                            "wire": {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "OCD-5a",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "63785b0d-d331-58f4-bf9f-d7d402daea1b": true,
                                            "2435ec34-7006-5e0a-b25b-021f0ff5e79e": false,
                                            "fc1867dc-1ad7-5599-848b-dea1a7dd7584": false,
                                            "dffa8a56-9791-59eb-8f9c-3e7badb3a26e": false,
                                            "15202dc0-e463-59a3-886a-81521814b4a3": false
                                        }
                                    }
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 1
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "isUnknown",
                                "wire": {
                                    "type": "merge",
                                    "wires": [
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s1",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "805a9685-9fd4-55de-94cd-4a19dd4107b8": true,
                                                        "263b64b2-8e44-59b6-8761-9556068db3c2": false
                                                    }
                                                }
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s2",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": false,
                                                        "91fad731-ad66-57f3-87fd-70f4246e63ed": true
                                                    }
                                                }
                                            }
                                        }
                                    ],
                                    "anyToAll": 1
                                }
                            },
                            {
                                "type": "not",
                                "wire": {
                                    "type": "merge",
                                    "wires": [
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s1",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "805a9685-9fd4-55de-94cd-4a19dd4107b8": true,
                                                        "263b64b2-8e44-59b6-8761-9556068db3c2": false
                                                    }
                                                }
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s2",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": false,
                                                        "91fad731-ad66-57f3-87fd-70f4246e63ed": true
                                                    }
                                                }
                                            }
                                        }
                                    ],
                                    "anyToAll": 1
                                }
                            }
                        ],
                        "anyToAll": 0.5
                    },
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "isUnknown",
                                "wire": {
                                    "type": "merge",
                                    "wires": [
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s1",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "805a9685-9fd4-55de-94cd-4a19dd4107b8": false,
                                                        "263b64b2-8e44-59b6-8761-9556068db3c2": true
                                                    }
                                                }
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s1.5",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "6581df20-1f12-5563-9671-fd51c07fd098": true,
                                                        "934f0a07-8342-52aa-9c61-ec87c8d7b6b9": false
                                                    }
                                                }
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s2",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": false,
                                                        "91fad731-ad66-57f3-87fd-70f4246e63ed": true
                                                    }
                                                }
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s2.5",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "786ef202-eae2-58ab-bec2-7bbe1746fd5c": false,
                                                        "f543076e-83c3-56df-9539-f976a57d2fac": true
                                                    }
                                                }
                                            }
                                        }
                                    ],
                                    "anyToAll": 1
                                }
                            },
                            {
                                "type": "not",
                                "wire": {
                                    "type": "merge",
                                    "wires": [
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s1",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "805a9685-9fd4-55de-94cd-4a19dd4107b8": false,
                                                        "263b64b2-8e44-59b6-8761-9556068db3c2": true
                                                    }
                                                }
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s1.5",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "6581df20-1f12-5563-9671-fd51c07fd098": true,
                                                        "934f0a07-8342-52aa-9c61-ec87c8d7b6b9": false
                                                    }
                                                }
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s2",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": false,
                                                        "91fad731-ad66-57f3-87fd-70f4246e63ed": true
                                                    }
                                                }
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s2.5",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "786ef202-eae2-58ab-bec2-7bbe1746fd5c": false,
                                                        "f543076e-83c3-56df-9539-f976a57d2fac": true
                                                    }
                                                }
                                            }
                                        }
                                    ],
                                    "anyToAll": 1
                                }
                            }
                        ],
                        "anyToAll": 0.5
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "isUnknown",
                            "wire": {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "OCD-6a",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "a114895c-d8d0-550b-92cd-6cc7fa00b4c9": true,
                                            "aee9c4fc-09a8-5417-bcf6-72489e6c2295": false,
                                            "d8b5b0e1-310e-5a1e-be90-df3ab36ada8e": false,
                                            "a32a57d8-8aa9-5e76-a8cc-4102a757ca38": false,
                                            "52b17754-786a-58c8-b625-bf1153ae0647": false
                                        }
                                    }
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 1
            }
        ],
        "anyToAll": 0.5
    },
    "or[and[OCD-s2=1, or[and[OCD-s2.5=1, or[OCD-s1=0, and[OCD-s1=1, OCD-s1.5=0]], not(isUndefined(OCD-4a=0))], and[or[OCD-s1=0, and[OCD-s1=1, OCD-s1.5=0, OCD-s2.5=1]], not(isUndefined(OCD-6a=0))]]], and[or[isUndefined(and[OCD-s1=1, OCD-s2=0]), not(and[OCD-s1=1, OCD-s2=0])], or[isUndefined(and[OCD-s1=1, OCD-s1.5=0, OCD-s2=1, OCD-s2.5=1]), not(and[OCD-s1=1, OCD-s1.5=0, OCD-s2=1, OCD-s2.5=1])], not(isUndefined(OCD-8a=0))]]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "OCD-s2",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": false,
                                    "91fad731-ad66-57f3-87fd-70f4246e63ed": true
                                }
                            }
                        }
                    },
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "merge",
                                "wires": [
                                    {
                                        "type": "input",
                                        "signal": {
                                            "type": "AnsweredOption",
                                            "questionId": "OCD-s2.5",
                                            "options": {
                                                "type": "predicates",
                                                "predicates": {
                                                    "786ef202-eae2-58ab-bec2-7bbe1746fd5c": false,
                                                    "f543076e-83c3-56df-9539-f976a57d2fac": true
                                                }
                                            }
                                        }
                                    },
                                    {
                                        "type": "merge",
                                        "wires": [
                                            {
                                                "type": "input",
                                                "signal": {
                                                    "type": "AnsweredOption",
                                                    "questionId": "OCD-s1",
                                                    "options": {
                                                        "type": "predicates",
                                                        "predicates": {
                                                            "805a9685-9fd4-55de-94cd-4a19dd4107b8": true,
                                                            "263b64b2-8e44-59b6-8761-9556068db3c2": false
                                                        }
                                                    }
                                                }
                                            },
                                            {
                                                "type": "merge",
                                                "wires": [
                                                    {
                                                        "type": "input",
                                                        "signal": {
                                                            "type": "AnsweredOption",
                                                            "questionId": "OCD-s1",
                                                            "options": {
                                                                "type": "predicates",
                                                                "predicates": {
                                                                    "805a9685-9fd4-55de-94cd-4a19dd4107b8": false,
                                                                    "263b64b2-8e44-59b6-8761-9556068db3c2": true
                                                                }
                                                            }
                                                        }
                                                    },
                                                    {
                                                        "type": "input",
                                                        "signal": {
                                                            "type": "AnsweredOption",
                                                            "questionId": "OCD-s1.5",
                                                            "options": {
                                                                "type": "predicates",
                                                                "predicates": {
                                                                    "6581df20-1f12-5563-9671-fd51c07fd098": true,
                                                                    "934f0a07-8342-52aa-9c61-ec87c8d7b6b9": false
                                                                }
                                                            }
                                                        }
                                                    }
                                                ],
                                                "anyToAll": 1
                                            }
                                        ],
                                        "anyToAll": 0.5
                                    },
                                    {
                                        "type": "not",
                                        "wire": {
                                            "type": "isUnknown",
                                            "wire": {
                                                "type": "input",
                                                "signal": {
                                                    "type": "AnsweredOption",
                                                    "questionId": "OCD-4a",
                                                    "options": {
                                                        "type": "predicates",
                                                        "predicates": {
                                                            "2edc3093-336a-5fc1-b7ac-3466362526bb": false,
                                                            "bd33b2e5-ba63-507e-bd58-7576ee2f60f0": false,
                                                            "4151b231-67fb-5826-a373-b716f21235f1": false,
                                                            "dc2fd953-74d4-5446-be05-66611ee351d5": false,
                                                            "7b4dd465-0ec8-551c-9086-241199467acb": false,
                                                            "3e8b1149-e933-56f0-986f-88ae3cc6eee4": false
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                ],
                                "anyToAll": 1
                            },
                            {
                                "type": "merge",
                                "wires": [
                                    {
                                        "type": "merge",
                                        "wires": [
                                            {
                                                "type": "input",
                                                "signal": {
                                                    "type": "AnsweredOption",
                                                    "questionId": "OCD-s1",
                                                    "options": {
                                                        "type": "predicates",
                                                        "predicates": {
                                                            "805a9685-9fd4-55de-94cd-4a19dd4107b8": true,
                                                            "263b64b2-8e44-59b6-8761-9556068db3c2": false
                                                        }
                                                    }
                                                }
                                            },
                                            {
                                                "type": "merge",
                                                "wires": [
                                                    {
                                                        "type": "input",
                                                        "signal": {
                                                            "type": "AnsweredOption",
                                                            "questionId": "OCD-s1",
                                                            "options": {
                                                                "type": "predicates",
                                                                "predicates": {
                                                                    "805a9685-9fd4-55de-94cd-4a19dd4107b8": false,
                                                                    "263b64b2-8e44-59b6-8761-9556068db3c2": true
                                                                }
                                                            }
                                                        }
                                                    },
                                                    {
                                                        "type": "input",
                                                        "signal": {
                                                            "type": "AnsweredOption",
                                                            "questionId": "OCD-s1.5",
                                                            "options": {
                                                                "type": "predicates",
                                                                "predicates": {
                                                                    "6581df20-1f12-5563-9671-fd51c07fd098": true,
                                                                    "934f0a07-8342-52aa-9c61-ec87c8d7b6b9": false
                                                                }
                                                            }
                                                        }
                                                    },
                                                    {
                                                        "type": "input",
                                                        "signal": {
                                                            "type": "AnsweredOption",
                                                            "questionId": "OCD-s2.5",
                                                            "options": {
                                                                "type": "predicates",
                                                                "predicates": {
                                                                    "786ef202-eae2-58ab-bec2-7bbe1746fd5c": false,
                                                                    "f543076e-83c3-56df-9539-f976a57d2fac": true
                                                                }
                                                            }
                                                        }
                                                    }
                                                ],
                                                "anyToAll": 1
                                            }
                                        ],
                                        "anyToAll": 0.5
                                    },
                                    {
                                        "type": "not",
                                        "wire": {
                                            "type": "isUnknown",
                                            "wire": {
                                                "type": "input",
                                                "signal": {
                                                    "type": "AnsweredOption",
                                                    "questionId": "OCD-6a",
                                                    "options": {
                                                        "type": "predicates",
                                                        "predicates": {
                                                            "a114895c-d8d0-550b-92cd-6cc7fa00b4c9": true,
                                                            "aee9c4fc-09a8-5417-bcf6-72489e6c2295": false,
                                                            "d8b5b0e1-310e-5a1e-be90-df3ab36ada8e": false,
                                                            "a32a57d8-8aa9-5e76-a8cc-4102a757ca38": false,
                                                            "52b17754-786a-58c8-b625-bf1153ae0647": false
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                ],
                                "anyToAll": 1
                            }
                        ],
                        "anyToAll": 0.5
                    }
                ],
                "anyToAll": 1
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "isUnknown",
                                "wire": {
                                    "type": "merge",
                                    "wires": [
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s1",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "805a9685-9fd4-55de-94cd-4a19dd4107b8": false,
                                                        "263b64b2-8e44-59b6-8761-9556068db3c2": true
                                                    }
                                                }
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s2",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": true,
                                                        "91fad731-ad66-57f3-87fd-70f4246e63ed": false
                                                    }
                                                }
                                            }
                                        }
                                    ],
                                    "anyToAll": 1
                                }
                            },
                            {
                                "type": "not",
                                "wire": {
                                    "type": "merge",
                                    "wires": [
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s1",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "805a9685-9fd4-55de-94cd-4a19dd4107b8": false,
                                                        "263b64b2-8e44-59b6-8761-9556068db3c2": true
                                                    }
                                                }
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s2",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": true,
                                                        "91fad731-ad66-57f3-87fd-70f4246e63ed": false
                                                    }
                                                }
                                            }
                                        }
                                    ],
                                    "anyToAll": 1
                                }
                            }
                        ],
                        "anyToAll": 0.5
                    },
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "isUnknown",
                                "wire": {
                                    "type": "merge",
                                    "wires": [
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s1",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "805a9685-9fd4-55de-94cd-4a19dd4107b8": false,
                                                        "263b64b2-8e44-59b6-8761-9556068db3c2": true
                                                    }
                                                }
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s1.5",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "6581df20-1f12-5563-9671-fd51c07fd098": true,
                                                        "934f0a07-8342-52aa-9c61-ec87c8d7b6b9": false
                                                    }
                                                }
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s2",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": false,
                                                        "91fad731-ad66-57f3-87fd-70f4246e63ed": true
                                                    }
                                                }
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s2.5",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "786ef202-eae2-58ab-bec2-7bbe1746fd5c": false,
                                                        "f543076e-83c3-56df-9539-f976a57d2fac": true
                                                    }
                                                }
                                            }
                                        }
                                    ],
                                    "anyToAll": 1
                                }
                            },
                            {
                                "type": "not",
                                "wire": {
                                    "type": "merge",
                                    "wires": [
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s1",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "805a9685-9fd4-55de-94cd-4a19dd4107b8": false,
                                                        "263b64b2-8e44-59b6-8761-9556068db3c2": true
                                                    }
                                                }
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s1.5",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "6581df20-1f12-5563-9671-fd51c07fd098": true,
                                                        "934f0a07-8342-52aa-9c61-ec87c8d7b6b9": false
                                                    }
                                                }
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s2",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": false,
                                                        "91fad731-ad66-57f3-87fd-70f4246e63ed": true
                                                    }
                                                }
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "OCD-s2.5",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "786ef202-eae2-58ab-bec2-7bbe1746fd5c": false,
                                                        "f543076e-83c3-56df-9539-f976a57d2fac": true
                                                    }
                                                }
                                            }
                                        }
                                    ],
                                    "anyToAll": 1
                                }
                            }
                        ],
                        "anyToAll": 0.5
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "isUnknown",
                            "wire": {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "OCD-8a",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "f78932aa-7c48-5e79-895a-a3dcbc1659ea": true,
                                            "257e5561-0975-5e0b-85d0-618d9799a7e9": false,
                                            "32d87341-36a2-5c2b-a385-5ac2114fe805": false,
                                            "4fcc66a3-e0bd-5b6c-bb12-277918c042ee": false,
                                            "a5935093-4c49-5c9c-acd1-1ace1c4f5d23": false
                                        }
                                    }
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 1
            }
        ],
        "anyToAll": 0.5
    },
    "and[or[isUndefined(and[OCD-s1=0, OCD-s2=1, OCD-s2.5=1]), not(and[OCD-s1=0, OCD-s2=1, OCD-s2.5=1])], or[isUndefined(and[OCD-s1=1, OCD-s1.5=0, OCD-s2=1, OCD-s2.5=1]), not(and[OCD-s1=1, OCD-s1.5=0, OCD-s2=1, OCD-s2.5=1])], not(isUndefined(OCD-14a=0))]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "OCD-s1",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "805a9685-9fd4-55de-94cd-4a19dd4107b8": true,
                                                "263b64b2-8e44-59b6-8761-9556068db3c2": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "OCD-s2",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": false,
                                                "91fad731-ad66-57f3-87fd-70f4246e63ed": true
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "OCD-s2.5",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "786ef202-eae2-58ab-bec2-7bbe1746fd5c": false,
                                                "f543076e-83c3-56df-9539-f976a57d2fac": true
                                            }
                                        }
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "OCD-s1",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "805a9685-9fd4-55de-94cd-4a19dd4107b8": true,
                                                "263b64b2-8e44-59b6-8761-9556068db3c2": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "OCD-s2",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": false,
                                                "91fad731-ad66-57f3-87fd-70f4246e63ed": true
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "OCD-s2.5",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "786ef202-eae2-58ab-bec2-7bbe1746fd5c": false,
                                                "f543076e-83c3-56df-9539-f976a57d2fac": true
                                            }
                                        }
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "OCD-s1",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "805a9685-9fd4-55de-94cd-4a19dd4107b8": false,
                                                "263b64b2-8e44-59b6-8761-9556068db3c2": true
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "OCD-s1.5",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "6581df20-1f12-5563-9671-fd51c07fd098": true,
                                                "934f0a07-8342-52aa-9c61-ec87c8d7b6b9": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "OCD-s2",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": false,
                                                "91fad731-ad66-57f3-87fd-70f4246e63ed": true
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "OCD-s2.5",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "786ef202-eae2-58ab-bec2-7bbe1746fd5c": false,
                                                "f543076e-83c3-56df-9539-f976a57d2fac": true
                                            }
                                        }
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "OCD-s1",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "805a9685-9fd4-55de-94cd-4a19dd4107b8": false,
                                                "263b64b2-8e44-59b6-8761-9556068db3c2": true
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "OCD-s1.5",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "6581df20-1f12-5563-9671-fd51c07fd098": true,
                                                "934f0a07-8342-52aa-9c61-ec87c8d7b6b9": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "OCD-s2",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": false,
                                                "91fad731-ad66-57f3-87fd-70f4246e63ed": true
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "OCD-s2.5",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "786ef202-eae2-58ab-bec2-7bbe1746fd5c": false,
                                                "f543076e-83c3-56df-9539-f976a57d2fac": true
                                            }
                                        }
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "not",
                "wire": {
                    "type": "isUnknown",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "OCD-14a",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "54e837d8-5c1b-5a1b-8b6a-03cc3c37bb60": true,
                                    "b04c74ed-9e1d-5829-80a8-bde749828475": false,
                                    "20710ec3-44fe-50bb-966c-e7e197979d04": false,
                                    "30be1b0f-a1a9-5da9-8ca2-293a63a6d4fe": false
                                }
                            }
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "and[OCD-s1=1, or[OCD-s2=0, and[OCD-s1.5=0, OCD-s2=1, OCD-s2.5=1]], not(isUndefined(OCD-8a=0))]": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCD-s1",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "805a9685-9fd4-55de-94cd-4a19dd4107b8": false,
                            "263b64b2-8e44-59b6-8761-9556068db3c2": true
                        }
                    }
                }
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "OCD-s2",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": true,
                                    "91fad731-ad66-57f3-87fd-70f4246e63ed": false
                                }
                            }
                        }
                    },
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "OCD-s1.5",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "6581df20-1f12-5563-9671-fd51c07fd098": true,
                                            "934f0a07-8342-52aa-9c61-ec87c8d7b6b9": false
                                        }
                                    }
                                }
                            },
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "OCD-s2",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": false,
                                            "91fad731-ad66-57f3-87fd-70f4246e63ed": true
                                        }
                                    }
                                }
                            },
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "OCD-s2.5",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "786ef202-eae2-58ab-bec2-7bbe1746fd5c": false,
                                            "f543076e-83c3-56df-9539-f976a57d2fac": true
                                        }
                                    }
                                }
                            }
                        ],
                        "anyToAll": 1
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "not",
                "wire": {
                    "type": "isUnknown",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "OCD-8a",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "f78932aa-7c48-5e79-895a-a3dcbc1659ea": true,
                                    "257e5561-0975-5e0b-85d0-618d9799a7e9": false,
                                    "32d87341-36a2-5c2b-a385-5ac2114fe805": false,
                                    "4fcc66a3-e0bd-5b6c-bb12-277918c042ee": false,
                                    "a5935093-4c49-5c9c-acd1-1ace1c4f5d23": false
                                }
                            }
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "and[OCD-s2=1, OCD-s2.5=1, or[OCD-s1=0, and[OCD-s1=1, OCD-s1.5=0]], not(isUndefined(OCD-14a=0))]": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCD-s2",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6": false,
                            "91fad731-ad66-57f3-87fd-70f4246e63ed": true
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCD-s2.5",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "786ef202-eae2-58ab-bec2-7bbe1746fd5c": false,
                            "f543076e-83c3-56df-9539-f976a57d2fac": true
                        }
                    }
                }
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "OCD-s1",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "805a9685-9fd4-55de-94cd-4a19dd4107b8": true,
                                    "263b64b2-8e44-59b6-8761-9556068db3c2": false
                                }
                            }
                        }
                    },
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "OCD-s1",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "805a9685-9fd4-55de-94cd-4a19dd4107b8": false,
                                            "263b64b2-8e44-59b6-8761-9556068db3c2": true
                                        }
                                    }
                                }
                            },
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "OCD-s1.5",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "6581df20-1f12-5563-9671-fd51c07fd098": true,
                                            "934f0a07-8342-52aa-9c61-ec87c8d7b6b9": false
                                        }
                                    }
                                }
                            }
                        ],
                        "anyToAll": 1
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "not",
                "wire": {
                    "type": "isUnknown",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "OCD-14a",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "54e837d8-5c1b-5a1b-8b6a-03cc3c37bb60": true,
                                    "b04c74ed-9e1d-5829-80a8-bde749828475": false,
                                    "20710ec3-44fe-50bb-966c-e7e197979d04": false,
                                    "30be1b0f-a1a9-5da9-8ca2-293a63a6d4fe": false
                                }
                            }
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "and[or[isUndefined(OCD21a_none=1), not(OCD21a_none=1)], not(isUndefined(OCD-21a=0))]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "OCD21a_none"
                            }
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "OCD21a_none"
                            }
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "not",
                "wire": {
                    "type": "isUnknown",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "OCD-21a",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "bbd1bf19-ffb3-5b11-b9cf-8b41aa1a8a5b": false,
                                    "6c7ed729-bf52-5ae0-bb2b-f972dd45f6b7": false,
                                    "70210027-e232-5418-af44-c3b7a09626b4": false,
                                    "ef8a703b-115d-5386-83ba-7fcaf5d4a472": false,
                                    "b80eadb8-27cd-5a76-aa06-67ac6097a450": false,
                                    "cdda9eee-7fcb-5928-a324-3e52b7009404": false,
                                    "9f12bdd5-995b-501e-b882-f1b6f98214ab": false,
                                    "42b340f0-5f94-5210-acbd-e6b643d37b06": false,
                                    "28155bbf-d385-5709-ac3e-39b6a2493683": false,
                                    "430451ee-6442-43d1-9516-4ce0722d75d7": false
                                }
                            }
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "and[or[isUndefined(OCDo21a_none=1), not(OCDo21a_none=1)], not(isUndefined(OCD-o21a=0))]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "OCDo21a_none"
                            }
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "OCDo21a_none"
                            }
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "not",
                "wire": {
                    "type": "isUnknown",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "OCD-o21a",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "bd98e2a1-2c4e-588a-98f8-bd6c32b61dd1": false,
                                    "f1b01b7d-49d6-536d-902b-3228f7ad46bd": false,
                                    "cbba77bc-609e-5db3-8d21-fee900dbcfff": false,
                                    "955a7492-dcff-5875-bd50-1da0eced106d": false,
                                    "09816b86-f171-5853-9be0-c80684ef9303": false,
                                    "f817bb6f-2193-50bc-9617-58c2996fc08a": false,
                                    "9963fa72-6972-59a6-9a8c-bdeae02b1b87": false,
                                    "4e806099-42b1-55ca-9a25-324cbde4a3aa": false,
                                    "3683169a-d99a-5d7e-b1f5-54c44e3af918": false,
                                    "4b614374-c0f6-49e8-8e6e-2f7ea5364497": false
                                }
                            }
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "and[or[isUndefined(OCDc21a_none=1), not(OCDc21a_none=1)], not(isUndefined(OCD-c21a=0))]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "OCDc21a_none"
                            }
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "OCDc21a_none"
                            }
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "not",
                "wire": {
                    "type": "isUnknown",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "OCD-c21a",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "2a3697e4-6f9f-5970-ab5b-98b0e41cae83": false,
                                    "c0fefc8c-c4a2-5b5c-8921-a5cd9bb47ac1": false,
                                    "425db81d-3464-578c-a8f8-92112274b117": false,
                                    "a0d448d1-4d27-5e31-a5d0-16e9a360bbcb": false,
                                    "a7f7b36d-2c31-51b9-b0a1-233aeb20a056": false,
                                    "7c703f50-8cec-5240-9cf9-f76575cdaace": false,
                                    "8902c5b4-519c-5daa-86bc-49642bb9c5a7": false,
                                    "0ae1e720-f983-5218-b553-e881e3589a8b": false,
                                    "4f3419ed-c6f4-5024-83e0-3149f6c96807": false,
                                    "a29a9a5c-e0c7-46e1-b48c-171d55dca1f1": false
                                }
                            }
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    }
};
exports.tests = {
    "ocd_ce": [
        {
            "name": "0 confidence subclinical: user: NEL6L3V aspect: ocd_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "OCD-s1": {
                    "questionId": "OCD-s1",
                    "chosenOptionIds": [
                        "805a9685-9fd4-55de-94cd-4a19dd4107b8"
                    ]
                },
                "OCD-s2": {
                    "questionId": "OCD-s2",
                    "chosenOptionIds": [
                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: K39ZL3L aspect: ocd_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "OCD-s1": {
                    "questionId": "OCD-s1",
                    "chosenOptionIds": [
                        "805a9685-9fd4-55de-94cd-4a19dd4107b8"
                    ]
                },
                "OCD-s2": {
                    "questionId": "OCD-s2",
                    "chosenOptionIds": [
                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: 50MRME6 aspect: ocd_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "OCD-s1": {
                    "questionId": "OCD-s1",
                    "chosenOptionIds": [
                        "805a9685-9fd4-55de-94cd-4a19dd4107b8"
                    ]
                },
                "OCD-s2": {
                    "questionId": "OCD-s2",
                    "chosenOptionIds": [
                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: Y32D838 aspect: ocd_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "OCD-s1": {
                    "questionId": "OCD-s1",
                    "chosenOptionIds": [
                        "805a9685-9fd4-55de-94cd-4a19dd4107b8"
                    ]
                },
                "OCD-s2": {
                    "questionId": "OCD-s2",
                    "chosenOptionIds": [
                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: O0KG5E1 aspect: ocd_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "OCD-s1": {
                    "questionId": "OCD-s1",
                    "chosenOptionIds": [
                        "263b64b2-8e44-59b6-8761-9556068db3c2"
                    ]
                },
                "OCD-s1.5": {
                    "questionId": "OCD-s1.5",
                    "chosenOptionIds": [
                        "6581df20-1f12-5563-9671-fd51c07fd098"
                    ]
                },
                "OCD-s2": {
                    "questionId": "OCD-s2",
                    "chosenOptionIds": [
                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: NELRPEV aspect: ocd_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "OCD-s1": {
                    "questionId": "OCD-s1",
                    "chosenOptionIds": [
                        "805a9685-9fd4-55de-94cd-4a19dd4107b8"
                    ]
                },
                "OCD-s2": {
                    "questionId": "OCD-s2",
                    "chosenOptionIds": [
                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: 73ZQN3R aspect: ocd_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "OCD-s1": {
                    "questionId": "OCD-s1",
                    "chosenOptionIds": [
                        "805a9685-9fd4-55de-94cd-4a19dd4107b8"
                    ]
                },
                "OCD-s2": {
                    "questionId": "OCD-s2",
                    "chosenOptionIds": [
                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: K39ML0L aspect: ocd_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "OCD-s1": {
                    "questionId": "OCD-s1",
                    "chosenOptionIds": [
                        "805a9685-9fd4-55de-94cd-4a19dd4107b8"
                    ]
                },
                "OCD-s2": {
                    "questionId": "OCD-s2",
                    "chosenOptionIds": [
                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: 7EW2K38 aspect: ocd_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "OCD-s1": {
                    "questionId": "OCD-s1",
                    "chosenOptionIds": [
                        "805a9685-9fd4-55de-94cd-4a19dd4107b8"
                    ]
                },
                "OCD-s2": {
                    "questionId": "OCD-s2",
                    "chosenOptionIds": [
                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: 63YR40R aspect: ocd_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "OCD-s1": {
                    "questionId": "OCD-s1",
                    "chosenOptionIds": [
                        "805a9685-9fd4-55de-94cd-4a19dd4107b8"
                    ]
                },
                "OCD-s2": {
                    "questionId": "OCD-s2",
                    "chosenOptionIds": [
                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: 73ZJN0R aspect: ocd_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "OCD-s1": {
                    "questionId": "OCD-s1",
                    "chosenOptionIds": [
                        "805a9685-9fd4-55de-94cd-4a19dd4107b8"
                    ]
                },
                "OCD-s2": {
                    "questionId": "OCD-s2",
                    "chosenOptionIds": [
                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: J0VRYEL aspect: ocd_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "OCD-s1": {
                    "questionId": "OCD-s1",
                    "chosenOptionIds": [
                        "805a9685-9fd4-55de-94cd-4a19dd4107b8"
                    ]
                },
                "OCD-s2": {
                    "questionId": "OCD-s2",
                    "chosenOptionIds": [
                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: N0NN50Q aspect: ocd_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "OCD-s1": {
                    "questionId": "OCD-s1",
                    "chosenOptionIds": [
                        "805a9685-9fd4-55de-94cd-4a19dd4107b8"
                    ]
                },
                "OCD-s2": {
                    "questionId": "OCD-s2",
                    "chosenOptionIds": [
                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: N0N4J3Q aspect: ocd_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "OCD-s1": {
                    "questionId": "OCD-s1",
                    "chosenOptionIds": [
                        "805a9685-9fd4-55de-94cd-4a19dd4107b8"
                    ]
                },
                "OCD-s2": {
                    "questionId": "OCD-s2",
                    "chosenOptionIds": [
                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: 10QKZ0P aspect: ocd_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "OCD-s1": {
                    "questionId": "OCD-s1",
                    "chosenOptionIds": [
                        "263b64b2-8e44-59b6-8761-9556068db3c2"
                    ]
                },
                "OCD-s1.5": {
                    "questionId": "OCD-s1.5",
                    "chosenOptionIds": [
                        "6581df20-1f12-5563-9671-fd51c07fd098"
                    ]
                },
                "OCD-s2": {
                    "questionId": "OCD-s2",
                    "chosenOptionIds": [
                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: O0K1931 aspect: ocd_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "OCD-s1": {
                    "questionId": "OCD-s1",
                    "chosenOptionIds": [
                        "805a9685-9fd4-55de-94cd-4a19dd4107b8"
                    ]
                },
                "OCD-s2": {
                    "questionId": "OCD-s2",
                    "chosenOptionIds": [
                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: J3J1D0Y aspect: ocd_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "OCD-s1": {
                    "questionId": "OCD-s1",
                    "chosenOptionIds": [
                        "805a9685-9fd4-55de-94cd-4a19dd4107b8"
                    ]
                },
                "OCD-s2": {
                    "questionId": "OCD-s2",
                    "chosenOptionIds": [
                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: 10QJ8EP aspect: ocd_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "OCD-s1": {
                    "questionId": "OCD-s1",
                    "chosenOptionIds": [
                        "805a9685-9fd4-55de-94cd-4a19dd4107b8"
                    ]
                },
                "OCD-s2": {
                    "questionId": "OCD-s2",
                    "chosenOptionIds": [
                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: Y32OM08 aspect: ocd_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "OCD-s1": {
                    "questionId": "OCD-s1",
                    "chosenOptionIds": [
                        "805a9685-9fd4-55de-94cd-4a19dd4107b8"
                    ]
                },
                "OCD-s2": {
                    "questionId": "OCD-s2",
                    "chosenOptionIds": [
                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: ME84L3W aspect: ocd_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "OCD-s1": {
                    "questionId": "OCD-s1",
                    "chosenOptionIds": [
                        "805a9685-9fd4-55de-94cd-4a19dd4107b8"
                    ]
                },
                "OCD-s2": {
                    "questionId": "OCD-s2",
                    "chosenOptionIds": [
                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: N0NO53Q aspect: ocd_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "OCD-s1": {
                    "questionId": "OCD-s1",
                    "chosenOptionIds": [
                        "805a9685-9fd4-55de-94cd-4a19dd4107b8"
                    ]
                },
                "OCD-s2": {
                    "questionId": "OCD-s2",
                    "chosenOptionIds": [
                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6"
                    ]
                }
            }
        },
        {
            "name": "Clinical Diagnosis user: K392M0L aspect: ocd_ce ",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "OCD-3a": {
                    "questionId": "OCD-3a",
                    "chosenOptionIds": [
                        "654f0850-433c-5068-81b1-cbc8da0115d4"
                    ]
                },
                "OCD-5a": {
                    "questionId": "OCD-5a",
                    "chosenOptionIds": [
                        "2435ec34-7006-5e0a-b25b-021f0ff5e79e"
                    ]
                },
                "OCD-7a": {
                    "questionId": "OCD-7a",
                    "chosenOptionIds": [
                        "d1a809d8-8068-5778-a74f-a8f209fff96e"
                    ]
                },
                "OCD-8a": {
                    "questionId": "OCD-8a",
                    "chosenOptionIds": [
                        "257e5561-0975-5e0b-85d0-618d9799a7e9"
                    ]
                },
                "OCD-o16a": {
                    "questionId": "OCD-o16a",
                    "chosenOptionIds": [
                        "f52473cd-db0d-5582-9623-4cdd13d4716e"
                    ]
                },
                "OCD-o19a": {
                    "questionId": "OCD-o19a",
                    "chosenOptionIds": [
                        "aa57f892-e9e7-52b0-a516-b7541d79ad82"
                    ]
                },
                "OCD-o20a": {
                    "questionId": "OCD-o20a",
                    "freeValue": 5
                },
                "OCD-o21.0a": {
                    "questionId": "OCD-o21.0a",
                    "chosenOptionIds": [
                        "e0651ad9-2972-53fc-984f-c55d8aa950c0"
                    ]
                },
                "OCD-o21a": {
                    "questionId": "OCD-o21a",
                    "chosenOptionIds": [
                        "f1b01b7d-49d6-536d-902b-3228f7ad46bd",
                        "cbba77bc-609e-5db3-8d21-fee900dbcfff",
                        "955a7492-dcff-5875-bd50-1da0eced106d"
                    ]
                },
                "OCD-s1": {
                    "questionId": "OCD-s1",
                    "chosenOptionIds": [
                        "263b64b2-8e44-59b6-8761-9556068db3c2"
                    ]
                },
                "OCD-s1.5": {
                    "questionId": "OCD-s1.5",
                    "chosenOptionIds": [
                        "934f0a07-8342-52aa-9c61-ec87c8d7b6b9"
                    ]
                },
                "OCD-s2": {
                    "questionId": "OCD-s2",
                    "chosenOptionIds": [
                        "91fad731-ad66-57f3-87fd-70f4246e63ed"
                    ]
                },
                "OCD-s2.5": {
                    "questionId": "OCD-s2.5",
                    "chosenOptionIds": [
                        "786ef202-eae2-58ab-bec2-7bbe1746fd5c"
                    ]
                }
            }
        },
        {
            "name": "Censeo vPrevious user: K392M0L aspect: ocd_ce",
            "expected": {
                "type": "CloseTo",
                "value": 0.04
            },
            "answers": {
                "OCD-3a": {
                    "questionId": "OCD-3a",
                    "chosenOptionIds": [
                        "654f0850-433c-5068-81b1-cbc8da0115d4"
                    ]
                },
                "OCD-5a": {
                    "questionId": "OCD-5a",
                    "chosenOptionIds": [
                        "2435ec34-7006-5e0a-b25b-021f0ff5e79e"
                    ]
                },
                "OCD-7a": {
                    "questionId": "OCD-7a",
                    "chosenOptionIds": [
                        "d1a809d8-8068-5778-a74f-a8f209fff96e"
                    ]
                },
                "OCD-8a": {
                    "questionId": "OCD-8a",
                    "chosenOptionIds": [
                        "257e5561-0975-5e0b-85d0-618d9799a7e9"
                    ]
                },
                "OCD-o16a": {
                    "questionId": "OCD-o16a",
                    "chosenOptionIds": [
                        "f52473cd-db0d-5582-9623-4cdd13d4716e"
                    ]
                },
                "OCD-o19a": {
                    "questionId": "OCD-o19a",
                    "chosenOptionIds": [
                        "aa57f892-e9e7-52b0-a516-b7541d79ad82"
                    ]
                },
                "OCD-o20a": {
                    "questionId": "OCD-o20a",
                    "freeValue": 5
                },
                "OCD-o21.0a": {
                    "questionId": "OCD-o21.0a",
                    "chosenOptionIds": [
                        "e0651ad9-2972-53fc-984f-c55d8aa950c0"
                    ]
                },
                "OCD-o21a": {
                    "questionId": "OCD-o21a",
                    "chosenOptionIds": [
                        "f1b01b7d-49d6-536d-902b-3228f7ad46bd",
                        "cbba77bc-609e-5db3-8d21-fee900dbcfff",
                        "955a7492-dcff-5875-bd50-1da0eced106d"
                    ]
                },
                "OCD-s1": {
                    "questionId": "OCD-s1",
                    "chosenOptionIds": [
                        "263b64b2-8e44-59b6-8761-9556068db3c2"
                    ]
                },
                "OCD-s1.5": {
                    "questionId": "OCD-s1.5",
                    "chosenOptionIds": [
                        "934f0a07-8342-52aa-9c61-ec87c8d7b6b9"
                    ]
                },
                "OCD-s2": {
                    "questionId": "OCD-s2",
                    "chosenOptionIds": [
                        "91fad731-ad66-57f3-87fd-70f4246e63ed"
                    ]
                },
                "OCD-s2.5": {
                    "questionId": "OCD-s2.5",
                    "chosenOptionIds": [
                        "786ef202-eae2-58ab-bec2-7bbe1746fd5c"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: J3JO40Y aspect: ocd_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "OCD-s1": {
                    "questionId": "OCD-s1",
                    "chosenOptionIds": [
                        "805a9685-9fd4-55de-94cd-4a19dd4107b8"
                    ]
                },
                "OCD-s2": {
                    "questionId": "OCD-s2",
                    "chosenOptionIds": [
                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: 4EGGGEP aspect: ocd_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "OCD-s1": {
                    "questionId": "OCD-s1",
                    "chosenOptionIds": [
                        "805a9685-9fd4-55de-94cd-4a19dd4107b8"
                    ]
                },
                "OCD-s2": {
                    "questionId": "OCD-s2",
                    "chosenOptionIds": [
                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: 7EWGK38 aspect: ocd_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "OCD-s1": {
                    "questionId": "OCD-s1",
                    "chosenOptionIds": [
                        "805a9685-9fd4-55de-94cd-4a19dd4107b8"
                    ]
                },
                "OCD-s2": {
                    "questionId": "OCD-s2",
                    "chosenOptionIds": [
                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: Z35X10O aspect: ocd_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "OCD-s1": {
                    "questionId": "OCD-s1",
                    "chosenOptionIds": [
                        "805a9685-9fd4-55de-94cd-4a19dd4107b8"
                    ]
                },
                "OCD-s2": {
                    "questionId": "OCD-s2",
                    "chosenOptionIds": [
                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: O0K9D01 aspect: ocd_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "OCD-s1": {
                    "questionId": "OCD-s1",
                    "chosenOptionIds": [
                        "805a9685-9fd4-55de-94cd-4a19dd4107b8"
                    ]
                },
                "OCD-s2": {
                    "questionId": "OCD-s2",
                    "chosenOptionIds": [
                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: NEL9J3V aspect: ocd_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "OCD-s1": {
                    "questionId": "OCD-s1",
                    "chosenOptionIds": [
                        "805a9685-9fd4-55de-94cd-4a19dd4107b8"
                    ]
                },
                "OCD-s2": {
                    "questionId": "OCD-s2",
                    "chosenOptionIds": [
                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: PE694EL aspect: ocd_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "OCD-s1": {
                    "questionId": "OCD-s1",
                    "chosenOptionIds": [
                        "805a9685-9fd4-55de-94cd-4a19dd4107b8"
                    ]
                },
                "OCD-s2": {
                    "questionId": "OCD-s2",
                    "chosenOptionIds": [
                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: 50MGW06 aspect: ocd_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "OCD-s1": {
                    "questionId": "OCD-s1",
                    "chosenOptionIds": [
                        "805a9685-9fd4-55de-94cd-4a19dd4107b8"
                    ]
                },
                "OCD-s2": {
                    "questionId": "OCD-s2",
                    "chosenOptionIds": [
                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: Y32QME8 aspect: ocd_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "OCD-s1": {
                    "questionId": "OCD-s1",
                    "chosenOptionIds": [
                        "805a9685-9fd4-55de-94cd-4a19dd4107b8"
                    ]
                },
                "OCD-s2": {
                    "questionId": "OCD-s2",
                    "chosenOptionIds": [
                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6"
                    ]
                }
            }
        },
        {
            "name": "Tech team adjusted from 52, Censeo vPrevious user: XE7DM0L aspect: ocd_ce",
            "expected": {
                "type": "CloseTo",
                "value": 0.75
            },
            "answers": {
                "OCD-3a": {
                    "questionId": "OCD-3a",
                    "chosenOptionIds": [
                        "86a75005-4b0f-5cdc-bdf2-dc356189360e"
                    ]
                },
                "OCD-5a": {
                    "questionId": "OCD-5a",
                    "chosenOptionIds": [
                        "dffa8a56-9791-59eb-8f9c-3e7badb3a26e"
                    ]
                },
                "OCD-7a": {
                    "questionId": "OCD-7a",
                    "chosenOptionIds": [
                        "42716cc5-7285-5b4e-9d65-4be48e7606b1"
                    ]
                },
                "OCD-8a": {
                    "questionId": "OCD-8a",
                    "chosenOptionIds": [
                        "32d87341-36a2-5c2b-a385-5ac2114fe805"
                    ]
                },
                "OCD-o16a": {
                    "questionId": "OCD-o16a",
                    "chosenOptionIds": [
                        "9dff1c6f-8e4c-5704-b7df-e8cc3e672d72"
                    ]
                },
                "OCD-o19a": {
                    "questionId": "OCD-o19a",
                    "chosenOptionIds": [
                        "aa57f892-e9e7-52b0-a516-b7541d79ad82"
                    ]
                },
                "OCD-o20a": {
                    "questionId": "OCD-o20a",
                    "freeValue": 5475
                },
                "OCD-o21.0a": {
                    "questionId": "OCD-o21.0a",
                    "chosenOptionIds": [
                        "e0651ad9-2972-53fc-984f-c55d8aa950c0"
                    ]
                },
                "OCD-o21a": {
                    "questionId": "OCD-o21a",
                    "chosenOptionIds": [
                        "bd98e2a1-2c4e-588a-98f8-bd6c32b61dd1",
                        "f1b01b7d-49d6-536d-902b-3228f7ad46bd",
                        "cbba77bc-609e-5db3-8d21-fee900dbcfff",
                        "955a7492-dcff-5875-bd50-1da0eced106d",
                        "09816b86-f171-5853-9be0-c80684ef9303",
                        "f817bb6f-2193-50bc-9617-58c2996fc08a",
                        "9963fa72-6972-59a6-9a8c-bdeae02b1b87",
                        "4e806099-42b1-55ca-9a25-324cbde4a3aa",
                        "3683169a-d99a-5d7e-b1f5-54c44e3af918"
                    ]
                },
                "OCD-s1": {
                    "questionId": "OCD-s1",
                    "chosenOptionIds": [
                        "263b64b2-8e44-59b6-8761-9556068db3c2"
                    ]
                },
                "OCD-s1.5": {
                    "questionId": "OCD-s1.5",
                    "chosenOptionIds": [
                        "934f0a07-8342-52aa-9c61-ec87c8d7b6b9"
                    ]
                },
                "OCD-s2": {
                    "questionId": "OCD-s2",
                    "chosenOptionIds": [
                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: Y32LM08 aspect: ocd_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "OCD-s1": {
                    "questionId": "OCD-s1",
                    "chosenOptionIds": [
                        "805a9685-9fd4-55de-94cd-4a19dd4107b8"
                    ]
                },
                "OCD-s2": {
                    "questionId": "OCD-s2",
                    "chosenOptionIds": [
                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: REOQG32 aspect: ocd_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "OCD-s1": {
                    "questionId": "OCD-s1",
                    "chosenOptionIds": [
                        "805a9685-9fd4-55de-94cd-4a19dd4107b8"
                    ]
                },
                "OCD-s2": {
                    "questionId": "OCD-s2",
                    "chosenOptionIds": [
                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6"
                    ]
                }
            }
        },
        {
            "name": "Clinical Diagnosis user: 7EW6KE8 aspect: ocd_ce ",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "OCD-13a": {
                    "questionId": "OCD-13a",
                    "chosenOptionIds": [
                        "18f0fe22-b536-5176-a1a6-b0deb375b436"
                    ]
                },
                "OCD-14a": {
                    "questionId": "OCD-14a",
                    "chosenOptionIds": [
                        "b04c74ed-9e1d-5829-80a8-bde749828475"
                    ]
                },
                "OCD-15a": {
                    "questionId": "OCD-15a",
                    "chosenOptionIds": [
                        "3ed49476-9c02-5a93-b8fc-3921a14daaf3"
                    ]
                },
                "OCD-16a": {
                    "questionId": "OCD-16a",
                    "chosenOptionIds": [
                        "0b3c97b3-8583-51ba-a419-36454c1e0bfc"
                    ]
                },
                "OCD-19a": {
                    "questionId": "OCD-19a",
                    "chosenOptionIds": [
                        "f177854d-7188-5142-8325-b27ef8aac03f"
                    ]
                },
                "OCD-20a": {
                    "questionId": "OCD-20a",
                    "freeValue": 7300
                },
                "OCD-21.0a": {
                    "questionId": "OCD-21.0a",
                    "chosenOptionIds": [
                        "9e90d287-ca8e-5942-a6f7-80fc50e48179"
                    ]
                },
                "OCD-21a": {
                    "questionId": "OCD-21a",
                    "chosenOptionIds": [
                        "70210027-e232-5418-af44-c3b7a09626b4",
                        "ef8a703b-115d-5386-83ba-7fcaf5d4a472",
                        "b80eadb8-27cd-5a76-aa06-67ac6097a450",
                        "bbd1bf19-ffb3-5b11-b9cf-8b41aa1a8a5b"
                    ]
                },
                "OCD-3a": {
                    "questionId": "OCD-3a",
                    "chosenOptionIds": [
                        "86a75005-4b0f-5cdc-bdf2-dc356189360e",
                        "41541011-61c0-5d8e-bea5-ac6618eb0499",
                        "400ab343-bdc4-567f-81a4-9e6a6d192ec5",
                        "5f203c70-531e-5f85-b902-825d95db880a"
                    ]
                },
                "OCD-4a": {
                    "questionId": "OCD-4a",
                    "chosenOptionIds": [
                        "2edc3093-336a-5fc1-b7ac-3466362526bb",
                        "4151b231-67fb-5826-a373-b716f21235f1",
                        "dc2fd953-74d4-5446-be05-66611ee351d5",
                        "7b4dd465-0ec8-551c-9086-241199467acb",
                        "bd33b2e5-ba63-507e-bd58-7576ee2f60f0"
                    ]
                },
                "OCD-5a": {
                    "questionId": "OCD-5a",
                    "chosenOptionIds": [
                        "2435ec34-7006-5e0a-b25b-021f0ff5e79e"
                    ]
                },
                "OCD-6a": {
                    "questionId": "OCD-6a",
                    "chosenOptionIds": [
                        "a114895c-d8d0-550b-92cd-6cc7fa00b4c9"
                    ]
                },
                "OCD-7a": {
                    "questionId": "OCD-7a",
                    "chosenOptionIds": [
                        "42716cc5-7285-5b4e-9d65-4be48e7606b1"
                    ]
                },
                "OCD-8a": {
                    "questionId": "OCD-8a",
                    "chosenOptionIds": [
                        "257e5561-0975-5e0b-85d0-618d9799a7e9"
                    ]
                },
                "OCD-s1": {
                    "questionId": "OCD-s1",
                    "chosenOptionIds": [
                        "263b64b2-8e44-59b6-8761-9556068db3c2"
                    ]
                },
                "OCD-s1.5": {
                    "questionId": "OCD-s1.5",
                    "chosenOptionIds": [
                        "934f0a07-8342-52aa-9c61-ec87c8d7b6b9"
                    ]
                },
                "OCD-s2": {
                    "questionId": "OCD-s2",
                    "chosenOptionIds": [
                        "91fad731-ad66-57f3-87fd-70f4246e63ed"
                    ]
                },
                "OCD-s2.5": {
                    "questionId": "OCD-s2.5",
                    "chosenOptionIds": [
                        "f543076e-83c3-56df-9539-f976a57d2fac"
                    ]
                }
            }
        },
        {
            "name": "Censeo vPrevious user: 7EW6KE8 aspect: ocd_ce",
            "expected": {
                "type": "CloseTo",
                "value": 0.54
            },
            "answers": {
                "OCD-13a": {
                    "questionId": "OCD-13a",
                    "chosenOptionIds": [
                        "18f0fe22-b536-5176-a1a6-b0deb375b436"
                    ]
                },
                "OCD-14a": {
                    "questionId": "OCD-14a",
                    "chosenOptionIds": [
                        "b04c74ed-9e1d-5829-80a8-bde749828475"
                    ]
                },
                "OCD-15a": {
                    "questionId": "OCD-15a",
                    "chosenOptionIds": [
                        "3ed49476-9c02-5a93-b8fc-3921a14daaf3"
                    ]
                },
                "OCD-16a": {
                    "questionId": "OCD-16a",
                    "chosenOptionIds": [
                        "0b3c97b3-8583-51ba-a419-36454c1e0bfc"
                    ]
                },
                "OCD-19a": {
                    "questionId": "OCD-19a",
                    "chosenOptionIds": [
                        "f177854d-7188-5142-8325-b27ef8aac03f"
                    ]
                },
                "OCD-20a": {
                    "questionId": "OCD-20a",
                    "freeValue": 7300
                },
                "OCD-21.0a": {
                    "questionId": "OCD-21.0a",
                    "chosenOptionIds": [
                        "9e90d287-ca8e-5942-a6f7-80fc50e48179"
                    ]
                },
                "OCD-21a": {
                    "questionId": "OCD-21a",
                    "chosenOptionIds": [
                        "70210027-e232-5418-af44-c3b7a09626b4",
                        "ef8a703b-115d-5386-83ba-7fcaf5d4a472",
                        "b80eadb8-27cd-5a76-aa06-67ac6097a450",
                        "bbd1bf19-ffb3-5b11-b9cf-8b41aa1a8a5b"
                    ]
                },
                "OCD-3a": {
                    "questionId": "OCD-3a",
                    "chosenOptionIds": [
                        "86a75005-4b0f-5cdc-bdf2-dc356189360e",
                        "41541011-61c0-5d8e-bea5-ac6618eb0499",
                        "400ab343-bdc4-567f-81a4-9e6a6d192ec5",
                        "5f203c70-531e-5f85-b902-825d95db880a"
                    ]
                },
                "OCD-4a": {
                    "questionId": "OCD-4a",
                    "chosenOptionIds": [
                        "2edc3093-336a-5fc1-b7ac-3466362526bb",
                        "4151b231-67fb-5826-a373-b716f21235f1",
                        "dc2fd953-74d4-5446-be05-66611ee351d5",
                        "7b4dd465-0ec8-551c-9086-241199467acb",
                        "bd33b2e5-ba63-507e-bd58-7576ee2f60f0"
                    ]
                },
                "OCD-5a": {
                    "questionId": "OCD-5a",
                    "chosenOptionIds": [
                        "2435ec34-7006-5e0a-b25b-021f0ff5e79e"
                    ]
                },
                "OCD-6a": {
                    "questionId": "OCD-6a",
                    "chosenOptionIds": [
                        "a114895c-d8d0-550b-92cd-6cc7fa00b4c9"
                    ]
                },
                "OCD-7a": {
                    "questionId": "OCD-7a",
                    "chosenOptionIds": [
                        "42716cc5-7285-5b4e-9d65-4be48e7606b1"
                    ]
                },
                "OCD-8a": {
                    "questionId": "OCD-8a",
                    "chosenOptionIds": [
                        "257e5561-0975-5e0b-85d0-618d9799a7e9"
                    ]
                },
                "OCD-s1": {
                    "questionId": "OCD-s1",
                    "chosenOptionIds": [
                        "263b64b2-8e44-59b6-8761-9556068db3c2"
                    ]
                },
                "OCD-s1.5": {
                    "questionId": "OCD-s1.5",
                    "chosenOptionIds": [
                        "934f0a07-8342-52aa-9c61-ec87c8d7b6b9"
                    ]
                },
                "OCD-s2": {
                    "questionId": "OCD-s2",
                    "chosenOptionIds": [
                        "91fad731-ad66-57f3-87fd-70f4246e63ed"
                    ]
                },
                "OCD-s2.5": {
                    "questionId": "OCD-s2.5",
                    "chosenOptionIds": [
                        "f543076e-83c3-56df-9539-f976a57d2fac"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: J0VQM3L aspect: ocd_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "OCD-s1": {
                    "questionId": "OCD-s1",
                    "chosenOptionIds": [
                        "805a9685-9fd4-55de-94cd-4a19dd4107b8"
                    ]
                },
                "OCD-s2": {
                    "questionId": "OCD-s2",
                    "chosenOptionIds": [
                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6"
                    ]
                }
            }
        },
        {
            "name": "Clinical Diagnosis user: 4EGRG3P aspect: ocd_ce ",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "OCD-13a": {
                    "questionId": "OCD-13a",
                    "chosenOptionIds": [
                        "06e76867-91d9-521e-9102-62a647414f8c"
                    ]
                },
                "OCD-14a": {
                    "questionId": "OCD-14a",
                    "chosenOptionIds": [
                        "30be1b0f-a1a9-5da9-8ca2-293a63a6d4fe"
                    ]
                },
                "OCD-15a": {
                    "questionId": "OCD-15a",
                    "chosenOptionIds": [
                        "1272197a-b659-540a-80ee-1a5ec9b59d3c"
                    ]
                },
                "OCD-16a": {
                    "questionId": "OCD-16a",
                    "chosenOptionIds": [
                        "587d85a1-edaa-5939-b424-3d49529d9fd5"
                    ]
                },
                "OCD-19a": {
                    "questionId": "OCD-19a",
                    "chosenOptionIds": [
                        "e3809079-bc8b-52c6-b811-7f6f2437e4a9"
                    ]
                },
                "OCD-20a": {
                    "questionId": "OCD-20a",
                    "freeValue": 7
                },
                "OCD-21.0a": {
                    "questionId": "OCD-21.0a",
                    "chosenOptionIds": [
                        "345c0bc7-484c-511d-9071-9b43afc5a7f3"
                    ]
                },
                "OCD-21a": {
                    "questionId": "OCD-21a",
                    "chosenOptionIds": [
                        "bbd1bf19-ffb3-5b11-b9cf-8b41aa1a8a5b",
                        "6c7ed729-bf52-5ae0-bb2b-f972dd45f6b7",
                        "70210027-e232-5418-af44-c3b7a09626b4",
                        "ef8a703b-115d-5386-83ba-7fcaf5d4a472",
                        "b80eadb8-27cd-5a76-aa06-67ac6097a450",
                        "cdda9eee-7fcb-5928-a324-3e52b7009404",
                        "9f12bdd5-995b-501e-b882-f1b6f98214ab",
                        "42b340f0-5f94-5210-acbd-e6b643d37b06"
                    ]
                },
                "OCD-3a": {
                    "questionId": "OCD-3a",
                    "chosenOptionIds": [
                        "375ddbf7-072f-50cc-b5ca-f7dd2b8d301c",
                        "5f203c70-531e-5f85-b902-825d95db880a"
                    ]
                },
                "OCD-4a": {
                    "questionId": "OCD-4a",
                    "chosenOptionIds": [
                        "2edc3093-336a-5fc1-b7ac-3466362526bb",
                        "4151b231-67fb-5826-a373-b716f21235f1",
                        "dc2fd953-74d4-5446-be05-66611ee351d5"
                    ]
                },
                "OCD-5a": {
                    "questionId": "OCD-5a",
                    "chosenOptionIds": [
                        "63785b0d-d331-58f4-bf9f-d7d402daea1b"
                    ]
                },
                "OCD-6a": {
                    "questionId": "OCD-6a",
                    "chosenOptionIds": [
                        "d8b5b0e1-310e-5a1e-be90-df3ab36ada8e"
                    ]
                },
                "OCD-7a": {
                    "questionId": "OCD-7a",
                    "chosenOptionIds": [
                        "42716cc5-7285-5b4e-9d65-4be48e7606b1"
                    ]
                },
                "OCD-8a": {
                    "questionId": "OCD-8a",
                    "chosenOptionIds": [
                        "32d87341-36a2-5c2b-a385-5ac2114fe805"
                    ]
                },
                "OCD-s1": {
                    "questionId": "OCD-s1",
                    "chosenOptionIds": [
                        "263b64b2-8e44-59b6-8761-9556068db3c2"
                    ]
                },
                "OCD-s1.5": {
                    "questionId": "OCD-s1.5",
                    "chosenOptionIds": [
                        "934f0a07-8342-52aa-9c61-ec87c8d7b6b9"
                    ]
                },
                "OCD-s2": {
                    "questionId": "OCD-s2",
                    "chosenOptionIds": [
                        "91fad731-ad66-57f3-87fd-70f4246e63ed"
                    ]
                },
                "OCD-s2.5": {
                    "questionId": "OCD-s2.5",
                    "chosenOptionIds": [
                        "f543076e-83c3-56df-9539-f976a57d2fac"
                    ]
                }
            }
        },
        {
            "name": "Censeo vPrevious user: 4EGRG3P aspect: ocd_ce",
            "expected": {
                "type": "CloseTo",
                "value": 0.32
            },
            "answers": {
                "OCD-13a": {
                    "questionId": "OCD-13a",
                    "chosenOptionIds": [
                        "06e76867-91d9-521e-9102-62a647414f8c"
                    ]
                },
                "OCD-14a": {
                    "questionId": "OCD-14a",
                    "chosenOptionIds": [
                        "30be1b0f-a1a9-5da9-8ca2-293a63a6d4fe"
                    ]
                },
                "OCD-15a": {
                    "questionId": "OCD-15a",
                    "chosenOptionIds": [
                        "1272197a-b659-540a-80ee-1a5ec9b59d3c"
                    ]
                },
                "OCD-16a": {
                    "questionId": "OCD-16a",
                    "chosenOptionIds": [
                        "587d85a1-edaa-5939-b424-3d49529d9fd5"
                    ]
                },
                "OCD-19a": {
                    "questionId": "OCD-19a",
                    "chosenOptionIds": [
                        "e3809079-bc8b-52c6-b811-7f6f2437e4a9"
                    ]
                },
                "OCD-20a": {
                    "questionId": "OCD-20a",
                    "freeValue": 7
                },
                "OCD-21.0a": {
                    "questionId": "OCD-21.0a",
                    "chosenOptionIds": [
                        "345c0bc7-484c-511d-9071-9b43afc5a7f3"
                    ]
                },
                "OCD-21a": {
                    "questionId": "OCD-21a",
                    "chosenOptionIds": [
                        "bbd1bf19-ffb3-5b11-b9cf-8b41aa1a8a5b",
                        "6c7ed729-bf52-5ae0-bb2b-f972dd45f6b7",
                        "70210027-e232-5418-af44-c3b7a09626b4",
                        "ef8a703b-115d-5386-83ba-7fcaf5d4a472",
                        "b80eadb8-27cd-5a76-aa06-67ac6097a450",
                        "cdda9eee-7fcb-5928-a324-3e52b7009404",
                        "9f12bdd5-995b-501e-b882-f1b6f98214ab",
                        "42b340f0-5f94-5210-acbd-e6b643d37b06"
                    ]
                },
                "OCD-3a": {
                    "questionId": "OCD-3a",
                    "chosenOptionIds": [
                        "375ddbf7-072f-50cc-b5ca-f7dd2b8d301c",
                        "5f203c70-531e-5f85-b902-825d95db880a"
                    ]
                },
                "OCD-4a": {
                    "questionId": "OCD-4a",
                    "chosenOptionIds": [
                        "2edc3093-336a-5fc1-b7ac-3466362526bb",
                        "4151b231-67fb-5826-a373-b716f21235f1",
                        "dc2fd953-74d4-5446-be05-66611ee351d5"
                    ]
                },
                "OCD-5a": {
                    "questionId": "OCD-5a",
                    "chosenOptionIds": [
                        "63785b0d-d331-58f4-bf9f-d7d402daea1b"
                    ]
                },
                "OCD-6a": {
                    "questionId": "OCD-6a",
                    "chosenOptionIds": [
                        "d8b5b0e1-310e-5a1e-be90-df3ab36ada8e"
                    ]
                },
                "OCD-7a": {
                    "questionId": "OCD-7a",
                    "chosenOptionIds": [
                        "42716cc5-7285-5b4e-9d65-4be48e7606b1"
                    ]
                },
                "OCD-8a": {
                    "questionId": "OCD-8a",
                    "chosenOptionIds": [
                        "32d87341-36a2-5c2b-a385-5ac2114fe805"
                    ]
                },
                "OCD-s1": {
                    "questionId": "OCD-s1",
                    "chosenOptionIds": [
                        "263b64b2-8e44-59b6-8761-9556068db3c2"
                    ]
                },
                "OCD-s1.5": {
                    "questionId": "OCD-s1.5",
                    "chosenOptionIds": [
                        "934f0a07-8342-52aa-9c61-ec87c8d7b6b9"
                    ]
                },
                "OCD-s2": {
                    "questionId": "OCD-s2",
                    "chosenOptionIds": [
                        "91fad731-ad66-57f3-87fd-70f4246e63ed"
                    ]
                },
                "OCD-s2.5": {
                    "questionId": "OCD-s2.5",
                    "chosenOptionIds": [
                        "f543076e-83c3-56df-9539-f976a57d2fac"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: Z35L10O aspect: ocd_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "OCD-s1": {
                    "questionId": "OCD-s1",
                    "chosenOptionIds": [
                        "805a9685-9fd4-55de-94cd-4a19dd4107b8"
                    ]
                },
                "OCD-s2": {
                    "questionId": "OCD-s2",
                    "chosenOptionIds": [
                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6"
                    ]
                }
            }
        },
        {
            "name": "Clinical Diagnosis user: XE72M0L aspect: ocd_ce ",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "OCD-3a": {
                    "questionId": "OCD-3a",
                    "chosenOptionIds": [
                        "5f203c70-531e-5f85-b902-825d95db880a",
                        "41541011-61c0-5d8e-bea5-ac6618eb0499"
                    ]
                },
                "OCD-5a": {
                    "questionId": "OCD-5a",
                    "chosenOptionIds": [
                        "63785b0d-d331-58f4-bf9f-d7d402daea1b"
                    ]
                },
                "OCD-7a": {
                    "questionId": "OCD-7a",
                    "chosenOptionIds": [
                        "76162037-755b-57b1-93d4-4f9ab77522dc"
                    ]
                },
                "OCD-8a": {
                    "questionId": "OCD-8a",
                    "chosenOptionIds": [
                        "257e5561-0975-5e0b-85d0-618d9799a7e9"
                    ]
                },
                "OCD-o16a": {
                    "questionId": "OCD-o16a",
                    "chosenOptionIds": [
                        "9614f14b-2e9a-5351-9bf9-29aa1d436e0f"
                    ]
                },
                "OCD-o19a": {
                    "questionId": "OCD-o19a",
                    "chosenOptionIds": [
                        "4fec4813-10b7-5b75-a92a-c3ef0bd0829f"
                    ]
                },
                "OCD-o20a": {
                    "questionId": "OCD-o20a",
                    "freeValue": 1460
                },
                "OCD-o21.0a": {
                    "questionId": "OCD-o21.0a",
                    "chosenOptionIds": [
                        "e0651ad9-2972-53fc-984f-c55d8aa950c0"
                    ]
                },
                "OCD-o21a": {
                    "questionId": "OCD-o21a",
                    "chosenOptionIds": [
                        "f1b01b7d-49d6-536d-902b-3228f7ad46bd",
                        "cbba77bc-609e-5db3-8d21-fee900dbcfff",
                        "955a7492-dcff-5875-bd50-1da0eced106d",
                        "09816b86-f171-5853-9be0-c80684ef9303",
                        "f817bb6f-2193-50bc-9617-58c2996fc08a",
                        "9963fa72-6972-59a6-9a8c-bdeae02b1b87",
                        "4e806099-42b1-55ca-9a25-324cbde4a3aa",
                        "3683169a-d99a-5d7e-b1f5-54c44e3af918"
                    ]
                },
                "OCD-s1": {
                    "questionId": "OCD-s1",
                    "chosenOptionIds": [
                        "263b64b2-8e44-59b6-8761-9556068db3c2"
                    ]
                },
                "OCD-s1.5": {
                    "questionId": "OCD-s1.5",
                    "chosenOptionIds": [
                        "934f0a07-8342-52aa-9c61-ec87c8d7b6b9"
                    ]
                },
                "OCD-s2": {
                    "questionId": "OCD-s2",
                    "chosenOptionIds": [
                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6"
                    ]
                }
            }
        },
        {
            "name": "Censeo vPrevious user: XE72M0L aspect: ocd_ce",
            "expected": {
                "type": "CloseTo",
                "value": 0.17
            },
            "answers": {
                "OCD-3a": {
                    "questionId": "OCD-3a",
                    "chosenOptionIds": [
                        "5f203c70-531e-5f85-b902-825d95db880a",
                        "41541011-61c0-5d8e-bea5-ac6618eb0499"
                    ]
                },
                "OCD-5a": {
                    "questionId": "OCD-5a",
                    "chosenOptionIds": [
                        "63785b0d-d331-58f4-bf9f-d7d402daea1b"
                    ]
                },
                "OCD-7a": {
                    "questionId": "OCD-7a",
                    "chosenOptionIds": [
                        "76162037-755b-57b1-93d4-4f9ab77522dc"
                    ]
                },
                "OCD-8a": {
                    "questionId": "OCD-8a",
                    "chosenOptionIds": [
                        "257e5561-0975-5e0b-85d0-618d9799a7e9"
                    ]
                },
                "OCD-o16a": {
                    "questionId": "OCD-o16a",
                    "chosenOptionIds": [
                        "9614f14b-2e9a-5351-9bf9-29aa1d436e0f"
                    ]
                },
                "OCD-o19a": {
                    "questionId": "OCD-o19a",
                    "chosenOptionIds": [
                        "4fec4813-10b7-5b75-a92a-c3ef0bd0829f"
                    ]
                },
                "OCD-o20a": {
                    "questionId": "OCD-o20a",
                    "freeValue": 1460
                },
                "OCD-o21.0a": {
                    "questionId": "OCD-o21.0a",
                    "chosenOptionIds": [
                        "e0651ad9-2972-53fc-984f-c55d8aa950c0"
                    ]
                },
                "OCD-o21a": {
                    "questionId": "OCD-o21a",
                    "chosenOptionIds": [
                        "f1b01b7d-49d6-536d-902b-3228f7ad46bd",
                        "cbba77bc-609e-5db3-8d21-fee900dbcfff",
                        "955a7492-dcff-5875-bd50-1da0eced106d",
                        "09816b86-f171-5853-9be0-c80684ef9303",
                        "f817bb6f-2193-50bc-9617-58c2996fc08a",
                        "9963fa72-6972-59a6-9a8c-bdeae02b1b87",
                        "4e806099-42b1-55ca-9a25-324cbde4a3aa",
                        "3683169a-d99a-5d7e-b1f5-54c44e3af918"
                    ]
                },
                "OCD-s1": {
                    "questionId": "OCD-s1",
                    "chosenOptionIds": [
                        "263b64b2-8e44-59b6-8761-9556068db3c2"
                    ]
                },
                "OCD-s1.5": {
                    "questionId": "OCD-s1.5",
                    "chosenOptionIds": [
                        "934f0a07-8342-52aa-9c61-ec87c8d7b6b9"
                    ]
                },
                "OCD-s2": {
                    "questionId": "OCD-s2",
                    "chosenOptionIds": [
                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6"
                    ]
                }
            }
        },
        {
            "name": "Clinical Diagnosis user: ME8MOEW aspect: ocd_ce ",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "OCD-3a": {
                    "questionId": "OCD-3a",
                    "chosenOptionIds": [
                        "41541011-61c0-5d8e-bea5-ac6618eb0499",
                        "400ab343-bdc4-567f-81a4-9e6a6d192ec5"
                    ]
                },
                "OCD-5a": {
                    "questionId": "OCD-5a",
                    "chosenOptionIds": [
                        "fc1867dc-1ad7-5599-848b-dea1a7dd7584"
                    ]
                },
                "OCD-7a": {
                    "questionId": "OCD-7a",
                    "chosenOptionIds": [
                        "d1a809d8-8068-5778-a74f-a8f209fff96e"
                    ]
                },
                "OCD-8a": {
                    "questionId": "OCD-8a",
                    "chosenOptionIds": [
                        "4fcc66a3-e0bd-5b6c-bb12-277918c042ee"
                    ]
                },
                "OCD-o16a": {
                    "questionId": "OCD-o16a",
                    "chosenOptionIds": [
                        "9614f14b-2e9a-5351-9bf9-29aa1d436e0f"
                    ]
                },
                "OCD-o19a": {
                    "questionId": "OCD-o19a",
                    "chosenOptionIds": [
                        "aa57f892-e9e7-52b0-a516-b7541d79ad82"
                    ]
                },
                "OCD-o20a": {
                    "questionId": "OCD-o20a",
                    "freeValue": 1095
                },
                "OCD-o21.0a": {
                    "questionId": "OCD-o21.0a",
                    "chosenOptionIds": [
                        "e0651ad9-2972-53fc-984f-c55d8aa950c0"
                    ]
                },
                "OCD-o21a": {
                    "questionId": "OCD-o21a",
                    "chosenOptionIds": [
                        "bd98e2a1-2c4e-588a-98f8-bd6c32b61dd1",
                        "f1b01b7d-49d6-536d-902b-3228f7ad46bd",
                        "cbba77bc-609e-5db3-8d21-fee900dbcfff",
                        "955a7492-dcff-5875-bd50-1da0eced106d",
                        "09816b86-f171-5853-9be0-c80684ef9303",
                        "f817bb6f-2193-50bc-9617-58c2996fc08a",
                        "9963fa72-6972-59a6-9a8c-bdeae02b1b87",
                        "4e806099-42b1-55ca-9a25-324cbde4a3aa"
                    ]
                },
                "OCD-s1": {
                    "questionId": "OCD-s1",
                    "chosenOptionIds": [
                        "263b64b2-8e44-59b6-8761-9556068db3c2"
                    ]
                },
                "OCD-s1.5": {
                    "questionId": "OCD-s1.5",
                    "chosenOptionIds": [
                        "934f0a07-8342-52aa-9c61-ec87c8d7b6b9"
                    ]
                },
                "OCD-s2": {
                    "questionId": "OCD-s2",
                    "chosenOptionIds": [
                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6"
                    ]
                }
            }
        },
        {
            "name": "Censeo vPrevious user: ME8MOEW aspect: ocd_ce",
            "expected": {
                "type": "CloseTo",
                "value": 0.5
            },
            "answers": {
                "OCD-3a": {
                    "questionId": "OCD-3a",
                    "chosenOptionIds": [
                        "41541011-61c0-5d8e-bea5-ac6618eb0499",
                        "400ab343-bdc4-567f-81a4-9e6a6d192ec5"
                    ]
                },
                "OCD-5a": {
                    "questionId": "OCD-5a",
                    "chosenOptionIds": [
                        "fc1867dc-1ad7-5599-848b-dea1a7dd7584"
                    ]
                },
                "OCD-7a": {
                    "questionId": "OCD-7a",
                    "chosenOptionIds": [
                        "d1a809d8-8068-5778-a74f-a8f209fff96e"
                    ]
                },
                "OCD-8a": {
                    "questionId": "OCD-8a",
                    "chosenOptionIds": [
                        "4fcc66a3-e0bd-5b6c-bb12-277918c042ee"
                    ]
                },
                "OCD-o16a": {
                    "questionId": "OCD-o16a",
                    "chosenOptionIds": [
                        "9614f14b-2e9a-5351-9bf9-29aa1d436e0f"
                    ]
                },
                "OCD-o19a": {
                    "questionId": "OCD-o19a",
                    "chosenOptionIds": [
                        "aa57f892-e9e7-52b0-a516-b7541d79ad82"
                    ]
                },
                "OCD-o20a": {
                    "questionId": "OCD-o20a",
                    "freeValue": 1095
                },
                "OCD-o21.0a": {
                    "questionId": "OCD-o21.0a",
                    "chosenOptionIds": [
                        "e0651ad9-2972-53fc-984f-c55d8aa950c0"
                    ]
                },
                "OCD-o21a": {
                    "questionId": "OCD-o21a",
                    "chosenOptionIds": [
                        "bd98e2a1-2c4e-588a-98f8-bd6c32b61dd1",
                        "f1b01b7d-49d6-536d-902b-3228f7ad46bd",
                        "cbba77bc-609e-5db3-8d21-fee900dbcfff",
                        "955a7492-dcff-5875-bd50-1da0eced106d",
                        "09816b86-f171-5853-9be0-c80684ef9303",
                        "f817bb6f-2193-50bc-9617-58c2996fc08a",
                        "9963fa72-6972-59a6-9a8c-bdeae02b1b87",
                        "4e806099-42b1-55ca-9a25-324cbde4a3aa"
                    ]
                },
                "OCD-s1": {
                    "questionId": "OCD-s1",
                    "chosenOptionIds": [
                        "263b64b2-8e44-59b6-8761-9556068db3c2"
                    ]
                },
                "OCD-s1.5": {
                    "questionId": "OCD-s1.5",
                    "chosenOptionIds": [
                        "934f0a07-8342-52aa-9c61-ec87c8d7b6b9"
                    ]
                },
                "OCD-s2": {
                    "questionId": "OCD-s2",
                    "chosenOptionIds": [
                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: Z35MQ3O aspect: ocd_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "OCD-s1": {
                    "questionId": "OCD-s1",
                    "chosenOptionIds": [
                        "805a9685-9fd4-55de-94cd-4a19dd4107b8"
                    ]
                },
                "OCD-s2": {
                    "questionId": "OCD-s2",
                    "chosenOptionIds": [
                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6"
                    ]
                }
            }
        },
        {
            "name": "Clinical Diagnosis user: 73ZW63R aspect: ocd_ce ",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "OCD-13a": {
                    "questionId": "OCD-13a",
                    "chosenOptionIds": [
                        "73abd2a9-18bf-5cab-aaa9-7433806d7c36"
                    ]
                },
                "OCD-14a": {
                    "questionId": "OCD-14a",
                    "chosenOptionIds": [
                        "30be1b0f-a1a9-5da9-8ca2-293a63a6d4fe"
                    ]
                },
                "OCD-15a": {
                    "questionId": "OCD-15a",
                    "chosenOptionIds": [
                        "84d8ca85-4007-5557-aa20-b6f3b32a5819"
                    ]
                },
                "OCD-16a": {
                    "questionId": "OCD-16a",
                    "chosenOptionIds": [
                        "0b3c97b3-8583-51ba-a419-36454c1e0bfc"
                    ]
                },
                "OCD-19a": {
                    "questionId": "OCD-19a",
                    "chosenOptionIds": [
                        "cc0e9f4f-d2cf-5611-8b94-f2de2ba87c8d"
                    ]
                },
                "OCD-20a": {
                    "questionId": "OCD-20a",
                    "freeValue": 2920
                },
                "OCD-21.0a": {
                    "questionId": "OCD-21.0a",
                    "chosenOptionIds": [
                        "9e90d287-ca8e-5942-a6f7-80fc50e48179"
                    ]
                },
                "OCD-21a": {
                    "questionId": "OCD-21a",
                    "chosenOptionIds": [
                        "ef8a703b-115d-5386-83ba-7fcaf5d4a472",
                        "b80eadb8-27cd-5a76-aa06-67ac6097a450",
                        "cdda9eee-7fcb-5928-a324-3e52b7009404",
                        "9f12bdd5-995b-501e-b882-f1b6f98214ab",
                        "28155bbf-d385-5709-ac3e-39b6a2493683"
                    ]
                },
                "OCD-3a": {
                    "questionId": "OCD-3a",
                    "chosenOptionIds": [
                        "654f0850-433c-5068-81b1-cbc8da0115d4"
                    ]
                },
                "OCD-4a": {
                    "questionId": "OCD-4a",
                    "chosenOptionIds": [
                        "3e8b1149-e933-56f0-986f-88ae3cc6eee4"
                    ]
                },
                "OCD-5a": {
                    "questionId": "OCD-5a",
                    "chosenOptionIds": [
                        "fc1867dc-1ad7-5599-848b-dea1a7dd7584"
                    ]
                },
                "OCD-6a": {
                    "questionId": "OCD-6a",
                    "chosenOptionIds": [
                        "a32a57d8-8aa9-5e76-a8cc-4102a757ca38"
                    ]
                },
                "OCD-7a": {
                    "questionId": "OCD-7a",
                    "chosenOptionIds": [
                        "d1a809d8-8068-5778-a74f-a8f209fff96e"
                    ]
                },
                "OCD-8a": {
                    "questionId": "OCD-8a",
                    "chosenOptionIds": [
                        "f78932aa-7c48-5e79-895a-a3dcbc1659ea"
                    ]
                },
                "OCD-s1": {
                    "questionId": "OCD-s1",
                    "chosenOptionIds": [
                        "263b64b2-8e44-59b6-8761-9556068db3c2"
                    ]
                },
                "OCD-s1.5": {
                    "questionId": "OCD-s1.5",
                    "chosenOptionIds": [
                        "934f0a07-8342-52aa-9c61-ec87c8d7b6b9"
                    ]
                },
                "OCD-s2": {
                    "questionId": "OCD-s2",
                    "chosenOptionIds": [
                        "91fad731-ad66-57f3-87fd-70f4246e63ed"
                    ]
                },
                "OCD-s2.5": {
                    "questionId": "OCD-s2.5",
                    "chosenOptionIds": [
                        "f543076e-83c3-56df-9539-f976a57d2fac"
                    ]
                }
            }
        },
        {
            "name": "Censeo vPrevious user: 73ZW63R aspect: ocd_ce",
            "expected": {
                "type": "CloseTo",
                "value": 0.76
            },
            "answers": {
                "OCD-13a": {
                    "questionId": "OCD-13a",
                    "chosenOptionIds": [
                        "73abd2a9-18bf-5cab-aaa9-7433806d7c36"
                    ]
                },
                "OCD-14a": {
                    "questionId": "OCD-14a",
                    "chosenOptionIds": [
                        "30be1b0f-a1a9-5da9-8ca2-293a63a6d4fe"
                    ]
                },
                "OCD-15a": {
                    "questionId": "OCD-15a",
                    "chosenOptionIds": [
                        "84d8ca85-4007-5557-aa20-b6f3b32a5819"
                    ]
                },
                "OCD-16a": {
                    "questionId": "OCD-16a",
                    "chosenOptionIds": [
                        "0b3c97b3-8583-51ba-a419-36454c1e0bfc"
                    ]
                },
                "OCD-19a": {
                    "questionId": "OCD-19a",
                    "chosenOptionIds": [
                        "cc0e9f4f-d2cf-5611-8b94-f2de2ba87c8d"
                    ]
                },
                "OCD-20a": {
                    "questionId": "OCD-20a",
                    "freeValue": 2920
                },
                "OCD-21.0a": {
                    "questionId": "OCD-21.0a",
                    "chosenOptionIds": [
                        "9e90d287-ca8e-5942-a6f7-80fc50e48179"
                    ]
                },
                "OCD-21a": {
                    "questionId": "OCD-21a",
                    "chosenOptionIds": [
                        "ef8a703b-115d-5386-83ba-7fcaf5d4a472",
                        "b80eadb8-27cd-5a76-aa06-67ac6097a450",
                        "cdda9eee-7fcb-5928-a324-3e52b7009404",
                        "9f12bdd5-995b-501e-b882-f1b6f98214ab",
                        "28155bbf-d385-5709-ac3e-39b6a2493683"
                    ]
                },
                "OCD-3a": {
                    "questionId": "OCD-3a",
                    "chosenOptionIds": [
                        "654f0850-433c-5068-81b1-cbc8da0115d4"
                    ]
                },
                "OCD-4a": {
                    "questionId": "OCD-4a",
                    "chosenOptionIds": [
                        "3e8b1149-e933-56f0-986f-88ae3cc6eee4"
                    ]
                },
                "OCD-5a": {
                    "questionId": "OCD-5a",
                    "chosenOptionIds": [
                        "fc1867dc-1ad7-5599-848b-dea1a7dd7584"
                    ]
                },
                "OCD-6a": {
                    "questionId": "OCD-6a",
                    "chosenOptionIds": [
                        "a32a57d8-8aa9-5e76-a8cc-4102a757ca38"
                    ]
                },
                "OCD-7a": {
                    "questionId": "OCD-7a",
                    "chosenOptionIds": [
                        "d1a809d8-8068-5778-a74f-a8f209fff96e"
                    ]
                },
                "OCD-8a": {
                    "questionId": "OCD-8a",
                    "chosenOptionIds": [
                        "f78932aa-7c48-5e79-895a-a3dcbc1659ea"
                    ]
                },
                "OCD-s1": {
                    "questionId": "OCD-s1",
                    "chosenOptionIds": [
                        "263b64b2-8e44-59b6-8761-9556068db3c2"
                    ]
                },
                "OCD-s1.5": {
                    "questionId": "OCD-s1.5",
                    "chosenOptionIds": [
                        "934f0a07-8342-52aa-9c61-ec87c8d7b6b9"
                    ]
                },
                "OCD-s2": {
                    "questionId": "OCD-s2",
                    "chosenOptionIds": [
                        "91fad731-ad66-57f3-87fd-70f4246e63ed"
                    ]
                },
                "OCD-s2.5": {
                    "questionId": "OCD-s2.5",
                    "chosenOptionIds": [
                        "f543076e-83c3-56df-9539-f976a57d2fac"
                    ]
                }
            }
        },
        {
            "name": "Clinical Diagnosis user: 4EG443P aspect: ocd_ce ",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "OCD-13a": {
                    "questionId": "OCD-13a",
                    "chosenOptionIds": [
                        "18f0fe22-b536-5176-a1a6-b0deb375b436"
                    ]
                },
                "OCD-14a": {
                    "questionId": "OCD-14a",
                    "chosenOptionIds": [
                        "b04c74ed-9e1d-5829-80a8-bde749828475"
                    ]
                },
                "OCD-15a": {
                    "questionId": "OCD-15a",
                    "chosenOptionIds": [
                        "d1f530a9-4ad1-5d89-8d08-2d819227639b"
                    ]
                },
                "OCD-4a": {
                    "questionId": "OCD-4a",
                    "chosenOptionIds": [
                        "bd33b2e5-ba63-507e-bd58-7576ee2f60f0",
                        "7b4dd465-0ec8-551c-9086-241199467acb",
                        "4151b231-67fb-5826-a373-b716f21235f1"
                    ]
                },
                "OCD-c16a": {
                    "questionId": "OCD-c16a",
                    "chosenOptionIds": [
                        "b9a761bd-2631-5587-beec-4d0edcc823b9"
                    ]
                },
                "OCD-c19a": {
                    "questionId": "OCD-c19a",
                    "chosenOptionIds": [
                        "85b5a64f-a893-5a4d-98c5-e7ab4f819b8b"
                    ]
                },
                "OCD-c20a": {
                    "questionId": "OCD-c20a",
                    "freeValue": 6570
                },
                "OCD-c21.0a": {
                    "questionId": "OCD-c21.0a",
                    "chosenOptionIds": [
                        "a6302a85-ec37-51a4-989e-05e001c75b1f"
                    ]
                },
                "OCD-c21a": {
                    "questionId": "OCD-c21a",
                    "chosenOptionIds": [
                        "2a3697e4-6f9f-5970-ab5b-98b0e41cae83",
                        "c0fefc8c-c4a2-5b5c-8921-a5cd9bb47ac1",
                        "a0d448d1-4d27-5e31-a5d0-16e9a360bbcb",
                        "7c703f50-8cec-5240-9cf9-f76575cdaace",
                        "4f3419ed-c6f4-5024-83e0-3149f6c96807"
                    ]
                },
                "OCD-s1": {
                    "questionId": "OCD-s1",
                    "chosenOptionIds": [
                        "805a9685-9fd4-55de-94cd-4a19dd4107b8"
                    ]
                },
                "OCD-s2": {
                    "questionId": "OCD-s2",
                    "chosenOptionIds": [
                        "91fad731-ad66-57f3-87fd-70f4246e63ed"
                    ]
                },
                "OCD-s2.5": {
                    "questionId": "OCD-s2.5",
                    "chosenOptionIds": [
                        "f543076e-83c3-56df-9539-f976a57d2fac"
                    ]
                }
            }
        },
        {
            "name": "Censeo vPrevious user: 4EG443P aspect: ocd_ce",
            "expected": {
                "type": "CloseTo",
                "value": 0.46
            },
            "answers": {
                "OCD-13a": {
                    "questionId": "OCD-13a",
                    "chosenOptionIds": [
                        "18f0fe22-b536-5176-a1a6-b0deb375b436"
                    ]
                },
                "OCD-14a": {
                    "questionId": "OCD-14a",
                    "chosenOptionIds": [
                        "b04c74ed-9e1d-5829-80a8-bde749828475"
                    ]
                },
                "OCD-15a": {
                    "questionId": "OCD-15a",
                    "chosenOptionIds": [
                        "d1f530a9-4ad1-5d89-8d08-2d819227639b"
                    ]
                },
                "OCD-4a": {
                    "questionId": "OCD-4a",
                    "chosenOptionIds": [
                        "bd33b2e5-ba63-507e-bd58-7576ee2f60f0",
                        "7b4dd465-0ec8-551c-9086-241199467acb",
                        "4151b231-67fb-5826-a373-b716f21235f1"
                    ]
                },
                "OCD-c16a": {
                    "questionId": "OCD-c16a",
                    "chosenOptionIds": [
                        "b9a761bd-2631-5587-beec-4d0edcc823b9"
                    ]
                },
                "OCD-c19a": {
                    "questionId": "OCD-c19a",
                    "chosenOptionIds": [
                        "85b5a64f-a893-5a4d-98c5-e7ab4f819b8b"
                    ]
                },
                "OCD-c20a": {
                    "questionId": "OCD-c20a",
                    "freeValue": 6570
                },
                "OCD-c21.0a": {
                    "questionId": "OCD-c21.0a",
                    "chosenOptionIds": [
                        "a6302a85-ec37-51a4-989e-05e001c75b1f"
                    ]
                },
                "OCD-c21a": {
                    "questionId": "OCD-c21a",
                    "chosenOptionIds": [
                        "2a3697e4-6f9f-5970-ab5b-98b0e41cae83",
                        "c0fefc8c-c4a2-5b5c-8921-a5cd9bb47ac1",
                        "a0d448d1-4d27-5e31-a5d0-16e9a360bbcb",
                        "7c703f50-8cec-5240-9cf9-f76575cdaace",
                        "4f3419ed-c6f4-5024-83e0-3149f6c96807"
                    ]
                },
                "OCD-s1": {
                    "questionId": "OCD-s1",
                    "chosenOptionIds": [
                        "805a9685-9fd4-55de-94cd-4a19dd4107b8"
                    ]
                },
                "OCD-s2": {
                    "questionId": "OCD-s2",
                    "chosenOptionIds": [
                        "91fad731-ad66-57f3-87fd-70f4246e63ed"
                    ]
                },
                "OCD-s2.5": {
                    "questionId": "OCD-s2.5",
                    "chosenOptionIds": [
                        "f543076e-83c3-56df-9539-f976a57d2fac"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: REOG402 aspect: ocd_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "OCD-s1": {
                    "questionId": "OCD-s1",
                    "chosenOptionIds": [
                        "805a9685-9fd4-55de-94cd-4a19dd4107b8"
                    ]
                },
                "OCD-s2": {
                    "questionId": "OCD-s2",
                    "chosenOptionIds": [
                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: Z3R680O aspect: ocd_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "OCD-s1": {
                    "questionId": "OCD-s1",
                    "chosenOptionIds": [
                        "805a9685-9fd4-55de-94cd-4a19dd4107b8"
                    ]
                },
                "OCD-s2": {
                    "questionId": "OCD-s2",
                    "chosenOptionIds": [
                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: R047J04 aspect: ocd_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "OCD-s1": {
                    "questionId": "OCD-s1",
                    "chosenOptionIds": [
                        "263b64b2-8e44-59b6-8761-9556068db3c2"
                    ]
                },
                "OCD-s1.5": {
                    "questionId": "OCD-s1.5",
                    "chosenOptionIds": [
                        "6581df20-1f12-5563-9671-fd51c07fd098"
                    ]
                },
                "OCD-s2": {
                    "questionId": "OCD-s2",
                    "chosenOptionIds": [
                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: 2E17NED aspect: ocd_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "OCD-s1": {
                    "questionId": "OCD-s1",
                    "chosenOptionIds": [
                        "805a9685-9fd4-55de-94cd-4a19dd4107b8"
                    ]
                },
                "OCD-s2": {
                    "questionId": "OCD-s2",
                    "chosenOptionIds": [
                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: PE61J0L aspect: ocd_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "OCD-s1": {
                    "questionId": "OCD-s1",
                    "chosenOptionIds": [
                        "805a9685-9fd4-55de-94cd-4a19dd4107b8"
                    ]
                },
                "OCD-s2": {
                    "questionId": "OCD-s2",
                    "chosenOptionIds": [
                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: J3JK4EY aspect: ocd_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "OCD-s1": {
                    "questionId": "OCD-s1",
                    "chosenOptionIds": [
                        "805a9685-9fd4-55de-94cd-4a19dd4107b8"
                    ]
                },
                "OCD-s2": {
                    "questionId": "OCD-s2",
                    "chosenOptionIds": [
                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: Z3RLO3O aspect: ocd_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "OCD-s1": {
                    "questionId": "OCD-s1",
                    "chosenOptionIds": [
                        "805a9685-9fd4-55de-94cd-4a19dd4107b8"
                    ]
                },
                "OCD-s2": {
                    "questionId": "OCD-s2",
                    "chosenOptionIds": [
                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: XE7WX3L aspect: ocd_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "OCD-s1": {
                    "questionId": "OCD-s1",
                    "chosenOptionIds": [
                        "805a9685-9fd4-55de-94cd-4a19dd4107b8"
                    ]
                },
                "OCD-s2": {
                    "questionId": "OCD-s2",
                    "chosenOptionIds": [
                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: O3P263W aspect: ocd_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "OCD-s1": {
                    "questionId": "OCD-s1",
                    "chosenOptionIds": [
                        "805a9685-9fd4-55de-94cd-4a19dd4107b8"
                    ]
                },
                "OCD-s2": {
                    "questionId": "OCD-s2",
                    "chosenOptionIds": [
                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: NELXPEV aspect: ocd_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "OCD-s1": {
                    "questionId": "OCD-s1",
                    "chosenOptionIds": [
                        "805a9685-9fd4-55de-94cd-4a19dd4107b8"
                    ]
                },
                "OCD-s2": {
                    "questionId": "OCD-s2",
                    "chosenOptionIds": [
                        "f3ab9fb9-1a69-53a1-ab9c-cff3824714b6"
                    ]
                }
            }
        }
    ]
};
exports.questionPredicates = {
    "OCD-s1.5": "not(OCD-s1=0)",
    "OCD-s2.5": "and[or[isUndefined(and[OCD-s1=0, OCD-s2=0]), not(and[OCD-s1=0, OCD-s2=0])], OCD-s2=1]",
    "OCD-7a": "or[and[or[isUndefined(and[OCD-s1=0, OCD-s2=0]), not(and[OCD-s1=0, OCD-s2=0])], not(OCD-s2=1)], and[or[isUndefined(and[OCD-s1=0, OCD-s2=1, OCD-s2.5=1]), not(and[OCD-s1=0, OCD-s2=1, OCD-s2.5=1])], or[isUndefined(and[OCD-s1=1, OCD-s1.5=0, OCD-s2=1, OCD-s2.5=1]), not(and[OCD-s1=1, OCD-s1.5=0, OCD-s2=1, OCD-s2.5=1]), and[OCD-s1=1, OCD-s1.5=1, OCD-s2=1, OCD-s2.5=1]], not(isUndefined(OCD-s2.5=0))]]",
    "OCD-15a": "and[OCD-s2=1, OCD-s2.5=1, or[OCD-s1=0, and[OCD-s1=1, OCD-s1.5=0, or[isUndefined(and[OCD-s1=1, OCD-s1.5=1, OCD-s2=1, OCD-s2.5=1]), not(and[OCD-s1=1, OCD-s1.5=1, OCD-s2=1, OCD-s2.5=1])]], not(isUndefined(OCD-7a=0))]]",
    "OCD-19a": "and[or[isUndefined(and[OCD-s1=1, OCD-s1.5=1, OCD-s2=1, OCD-s2.5=0]), not(and[OCD-s1=1, OCD-s1.5=1, OCD-s2=1, OCD-s2.5=0])], or[and[or[isUndefined(and[OCD-s2=1, OCD-s2.5=1]), not(and[OCD-s2=1, OCD-s2.5=1])], or[isUndefined(and[OCD-s1=1, OCD-s1.5=1, OCD-s2=0]), not(and[OCD-s1=1, OCD-s1.5=1, OCD-s2=0])], not(isUndefined(OCD-7a=0))], and[or[isUndefined(and[OCD-s1=1, OCD-s2=0]), not(and[OCD-s1=1, OCD-s2=0])], or[isUndefined(and[OCD-s1=0, OCD-s2=1]), not(and[OCD-s1=0, OCD-s2=1])], or[isUndefined(and[OCD-s1=1, OCD-s1.5=0, OCD-s2=1, OCD-s2.5=1]), not(and[OCD-s1=1, OCD-s1.5=0, OCD-s2=1, OCD-s2.5=1])], not(isUndefined(OCD-15a=0))]]]",
    "OCD-o19a": "and[OCD-s1=1, or[and[OCD-s1.5=1, or[isUndefined(and[OCD-s2=1, OCD-s2.5=1]), not(and[OCD-s2=1, OCD-s2.5=1])], or[OCD-s2=0, and[OCD-s2=1, OCD-s2.5=0]], not(isUndefined(OCD-7a=0))], and[or[OCD-s2=0, and[OCD-s1.5=1, OCD-s2=1, OCD-s2.5=0, or[isUndefined(and[OCD-s1=0, OCD-s2=1]), not(and[OCD-s1=0, OCD-s2=1])]]], not(isUndefined(OCD-15a=0))]]]",
    "OCD-c19a": "and[OCD-s2=1, or[isUndefined(and[OCD-s1=1, OCD-s2=0]), not(and[OCD-s1=1, OCD-s2=0])], or[OCD-s1=0, and[OCD-s1=1, OCD-s1.5=0, OCD-s2.5=1, or[isUndefined(and[OCD-s1=1, OCD-s1.5=1, OCD-s2=1, OCD-s2.5=0]), not(and[OCD-s1=1, OCD-s1.5=1, OCD-s2=1, OCD-s2.5=0])]]], not(isUndefined(OCD-15a=0))]",
    "OCD-3a": "or[and[or[OCD-7a>=0.5, OCD-15a>=0.5, OCD-19a>=0.5], not(isUndefined(OCD-19a=0))], and[or[OCD-7a>=0.5, OCD-o19a>=0.5], not(isUndefined(OCD-o19a=0))]]",
    "OCD-4a": "or[and[or[OCD-15a>=0.5, OCD-c19a>=0.5], not(isUndefined(OCD-c19a=0))], and[or[isUndefined(and[OCD-s1=1, OCD-s1.5=1, OCD-s2=0]), not(and[OCD-s1=1, OCD-s1.5=1, OCD-s2=0])], or[isUndefined(and[OCD-s1=1, OCD-s1.5=1, OCD-s2=1, OCD-s2.5=0]), not(and[OCD-s1=1, OCD-s1.5=1, OCD-s2=1, OCD-s2.5=0])], not(isUndefined(OCD-3a=0))]]",
    "OCD-5a": "or[and[OCD-s1=1, OCD-s1.5=1, or[OCD-s2=0, and[OCD-s2=1, OCD-s2.5=0]], not(isUndefined(OCD-3a=0))], and[or[isUndefined(and[OCD-s1=0, OCD-s2=1, OCD-s2.5=1]), not(and[OCD-s1=0, OCD-s2=1, OCD-s2.5=1])], or[isUndefined(and[OCD-s1=1, OCD-s1.5=0, OCD-s2=1, OCD-s2.5=1]), not(and[OCD-s1=1, OCD-s1.5=0, OCD-s2=1, OCD-s2.5=1])], not(isUndefined(OCD-4a=0))]]",
    "OCD-6a": "and[or[isUndefined(and[OCD-s1=0, OCD-s2=1]), not(and[OCD-s1=0, OCD-s2=1])], or[isUndefined(and[OCD-s1=1, OCD-s1.5=1, OCD-s2=1, OCD-s2.5=0]), not(and[OCD-s1=1, OCD-s1.5=1, OCD-s2=1, OCD-s2.5=0])], not(isUndefined(OCD-5a=0))]",
    "OCD-8a": "or[and[OCD-s2=1, or[OCD-s1=0, and[OCD-s1=1, OCD-s1.5=1, OCD-s2.5=0]], not(isUndefined(OCD-5a=0))], and[or[isUndefined(and[OCD-s1=0, OCD-s2=1]), not(and[OCD-s1=0, OCD-s2=1])], or[isUndefined(and[OCD-s1=1, OCD-s1.5=0, OCD-s2=1, OCD-s2.5=1]), not(and[OCD-s1=1, OCD-s1.5=0, OCD-s2=1, OCD-s2.5=1])], not(isUndefined(OCD-6a=0))]]",
    "OCD-13a": "or[and[OCD-s2=1, or[and[OCD-s2.5=1, or[OCD-s1=0, and[OCD-s1=1, OCD-s1.5=0]], not(isUndefined(OCD-4a=0))], and[or[OCD-s1=0, and[OCD-s1=1, OCD-s1.5=0, OCD-s2.5=1]], not(isUndefined(OCD-6a=0))]]], and[or[isUndefined(and[OCD-s1=1, OCD-s2=0]), not(and[OCD-s1=1, OCD-s2=0])], or[isUndefined(and[OCD-s1=1, OCD-s1.5=0, OCD-s2=1, OCD-s2.5=1]), not(and[OCD-s1=1, OCD-s1.5=0, OCD-s2=1, OCD-s2.5=1])], not(isUndefined(OCD-8a=0))]]",
    "OCD-14a": "or[and[OCD-s2=1, or[and[OCD-s2.5=1, or[OCD-s1=0, and[OCD-s1=1, OCD-s1.5=0]], not(isUndefined(OCD-4a=0))], and[or[OCD-s1=0, and[OCD-s1=1, OCD-s1.5=0, OCD-s2.5=1]], not(isUndefined(OCD-6a=0))]]], and[or[isUndefined(and[OCD-s1=1, OCD-s2=0]), not(and[OCD-s1=1, OCD-s2=0])], or[isUndefined(and[OCD-s1=1, OCD-s1.5=0, OCD-s2=1, OCD-s2.5=1]), not(and[OCD-s1=1, OCD-s1.5=0, OCD-s2=1, OCD-s2.5=1])], not(isUndefined(OCD-8a=0))]]",
    "OCD-16a": "and[or[isUndefined(and[OCD-s1=0, OCD-s2=1, OCD-s2.5=1]), not(and[OCD-s1=0, OCD-s2=1, OCD-s2.5=1])], or[isUndefined(and[OCD-s1=1, OCD-s1.5=0, OCD-s2=1, OCD-s2.5=1]), not(and[OCD-s1=1, OCD-s1.5=0, OCD-s2=1, OCD-s2.5=1])], not(isUndefined(OCD-14a=0))]",
    "OCD-o16a": "and[OCD-s1=1, or[OCD-s2=0, and[OCD-s1.5=0, OCD-s2=1, OCD-s2.5=1]], not(isUndefined(OCD-8a=0))]",
    "OCD-c16a": "and[OCD-s2=1, OCD-s2.5=1, or[OCD-s1=0, and[OCD-s1=1, OCD-s1.5=0]], not(isUndefined(OCD-14a=0))]",
    "OCD-20a": "and[or[isUndefined(and[OCD-s1=0, OCD-s2=1, OCD-s2.5=1]), not(and[OCD-s1=0, OCD-s2=1, OCD-s2.5=1])], or[isUndefined(and[OCD-s1=1, OCD-s1.5=0, OCD-s2=1, OCD-s2.5=1]), not(and[OCD-s1=1, OCD-s1.5=0, OCD-s2=1, OCD-s2.5=1])], not(isUndefined(OCD-14a=0))]",
    "OCD-o20a": "and[OCD-s1=1, or[OCD-s2=0, and[OCD-s1.5=0, OCD-s2=1, OCD-s2.5=1]], not(isUndefined(OCD-8a=0))]",
    "OCD-c20a": "and[OCD-s2=1, OCD-s2.5=1, or[OCD-s1=0, and[OCD-s1=1, OCD-s1.5=0]], not(isUndefined(OCD-14a=0))]",
    "OCD-21a": "and[or[isUndefined(and[OCD-s1=0, OCD-s2=1, OCD-s2.5=1]), not(and[OCD-s1=0, OCD-s2=1, OCD-s2.5=1])], or[isUndefined(and[OCD-s1=1, OCD-s1.5=0, OCD-s2=1, OCD-s2.5=1]), not(and[OCD-s1=1, OCD-s1.5=0, OCD-s2=1, OCD-s2.5=1])], not(isUndefined(OCD-14a=0))]",
    "OCD-o21a": "and[OCD-s1=1, or[OCD-s2=0, and[OCD-s1.5=0, OCD-s2=1, OCD-s2.5=1]], not(isUndefined(OCD-8a=0))]",
    "OCD-c21a": "and[OCD-s2=1, OCD-s2.5=1, or[OCD-s1=0, and[OCD-s1=1, OCD-s1.5=0]], not(isUndefined(OCD-14a=0))]",
    "OCD-21.0a": "and[or[isUndefined(OCD21a_none=1), not(OCD21a_none=1)], not(isUndefined(OCD-21a=0))]",
    "OCD-o21.0a": "and[or[isUndefined(OCDo21a_none=1), not(OCDo21a_none=1)], not(isUndefined(OCD-o21a=0))]",
    "OCD-c21.0a": "and[or[isUndefined(OCDc21a_none=1), not(OCDc21a_none=1)], not(isUndefined(OCD-c21a=0))]"
};
