import React, { createContext, useContext, useState, ReactNode, useMemo, useEffect } from 'react'

type DemoFromType = 'start' | 'qrstart' | null

export enum DemoExperience {
    ServiceUser = 'service-user',
    Clinician = 'clinician',
}

export type DemoExperienceType = DemoExperience | null
interface DemoContextType {
    demo: boolean
    setDemo: (value: boolean) => void
    demoFrom: string | null
    setDemoFrom: (value: DemoFromType) => void
    demoExperienceType: string | null
    setDemoExperienceType: (value: DemoExperienceType) => void
}

// Create the context with default values
const DemoContext = createContext<DemoContextType | undefined>(undefined)

interface DemoProviderProps {
    children: ReactNode
}

export const DemoProvider: React.FC<DemoProviderProps> = ({ children }) => {
    const [demo, setDemo] = useState(localStorage.getItem('demo') === 'true')
    const [demoFrom, setDemoFrom] = useState<string | null>(localStorage.getItem('demoFrom') ?? null)
    const [demoExperienceType, setDemoExperienceType] = useState<string | null>(
        localStorage.getItem('demoExperienceType') ?? null
    )
    useEffect(() => {
        localStorage.setItem('demo', demo.toString() ?? 'false')
    }, [demo])

    useEffect(() => {
        localStorage.setItem('demoFrom', demoFrom ?? '')
    }, [demoFrom])

    useEffect(() => {
        localStorage.setItem('demoExperienceType', demoExperienceType ?? '')
    }, [demoExperienceType])

    const contextValue = useMemo(
        () => ({
            demo,
            setDemo,
            demoFrom,
            setDemoFrom,
            demoExperienceType,
            setDemoExperienceType,
        }),
        [demo, demoFrom, demoExperienceType]
    )

    return <DemoContext.Provider value={contextValue}>{children}</DemoContext.Provider>
}

export const useDemoContext = (): DemoContextType => {
    const context = useContext(DemoContext)
    if (!context) {
        throw new Error('useDemo must be used within a DemoProvider')
    }
    return context
}
