import React, { useState, useEffect, useContext } from 'react'
import { Button, Input, Text } from '@psyomics/components'
import { OnboardingFormProps } from '../use-Onboarding'
import RadioGroup, { RadioOption } from 'registration/components/RadioGroup/RadioGroup'
import { EthnicGroup, EthnicGroups, FormStage, GeneralEthnicity } from './Ethnicity.types'
import { useMixpanelEvent } from 'ui/app/useMixpanelEvent'
import { MixpanelContext } from 'registration/context/mixpanel/mixpanel-context'
import { OrganisationContext } from 'ui/app/context/organisation'
import { ReferralDetailsContext } from 'ui/app/context/referralDetails'

type IOnboarding = OnboardingFormProps & React.ComponentPropsWithoutRef<'div'>

const Ethnicity: React.FC<IOnboarding> = ({ values, errors, validateForm, submitForm }) => {
    const [showErrors, setShowErrors] = useState(false)
    const [stage, setStage] = useState<FormStage>('general')

    const [general, setGeneral] = useState<string>()
    const [background, setBackground] = useState<string>()
    const [showAdditionalInfo, setShowAdditionalInfo] = useState<boolean>(false)
    const [additionalDescription, setAdditionalDescription] = useState<string>('')
    const [backgrounds, setBackgrounds] = useState<RadioOption[]>()
    const mixpanelApiUrl = useContext(MixpanelContext)
    const organisation = useContext(OrganisationContext)
    const referralDetails = useContext(ReferralDetailsContext)
    const { ethnicGroupBackgroundEntered, ethnicGroupEntered } = useMixpanelEvent(mixpanelApiUrl)

    const selectedEthnicGroup: EthnicGroup | undefined =
        EthnicGroups.find((f) => f.general === (GeneralEthnicity as any)[general || '']) || undefined
    const onCompletedEthnicity = async () => {
        ethnicGroupBackgroundEntered(
            organisation?.organisationCode,
            organisation?.organisationName,
            values.ethnicity,
            referralDetails?.data?.publicId
        )
        await submitForm()
    }

    useEffect(() => {
        if (!general) return

        if (selectedEthnicGroup?.backgrounds.length) {
            setBackgrounds(selectedEthnicGroup?.backgrounds.map((m) => ({ label: m.text, value: m.text })))
        } else {
            values.ethnicity = general
        }
    }, [general, selectedEthnicGroup, values])

    useEffect(() => {
        if (!background) return
        values.ethnicity = background

        const selectedBackground = selectedEthnicGroup?.backgrounds.find((f) => f.text === background)
        if (selectedBackground !== undefined) {
            setShowAdditionalInfo(selectedBackground.additionalInfo || false)
        }
    }, [background, selectedEthnicGroup?.backgrounds, values])

    useEffect(() => {
        if (additionalDescription.length) {
            values.ethnicity = additionalDescription
        }
    }, [additionalDescription, values])

    return (
        <>
            {stage === 'general' && (
                <>
                    <Text color="mid" css={{ mb: 4 }}>
                        In order to improve your experience we&#39;d like to find out a little more about you.
                    </Text>
                    <RadioGroup
                        label="What is your ethnic group?"
                        options={Object.keys(GeneralEthnicity).map((m) => ({
                            label: (GeneralEthnicity as any)[m],
                            value: m,
                        }))}
                        groupName="general"
                        value={general}
                        displayOr={true}
                        onChange={(e) => setGeneral(e.target.value)}
                        error={showErrors && !general ? errors.ethnicity : undefined}
                    />
                    <Button
                        css={{ mt: 6 }}
                        type="submit"
                        appearance="primary"
                        rounded
                        fullWidth
                        size="large"
                        label="Next"
                        onClick={() => {
                            if (!general) {
                                setShowErrors(true)
                                return
                            }
                            setShowErrors(false)
                            ethnicGroupEntered(
                                organisation?.organisationCode,
                                organisation?.organisationName,
                                general,
                                referralDetails?.data?.publicId
                            )

                            if (!values.ethnicity) {
                                setStage('background')
                            } else {
                                onCompletedEthnicity()
                            }
                        }}
                    />
                </>
            )}

            {stage === 'background' && backgrounds && (
                <>
                    <RadioGroup
                        label={`Which of the following best describes your ${general} background?`}
                        options={backgrounds}
                        groupName="ethnicity"
                        displayOr={true}
                        value={background}
                        onChange={(e) => setBackground(e.target.value)}
                        error={showErrors ? errors.ethnicity : undefined}
                    />
                    {showAdditionalInfo && (
                        <Input
                            placeholder="Please describe"
                            value={additionalDescription}
                            css={{ mb: 2, mt: 2 }}
                            onChange={(e: any) => setAdditionalDescription(e.target.value)}
                        />
                    )}
                    <Button
                        type="submit"
                        css={{ mt: 6 }}
                        appearance="primary"
                        rounded
                        fullWidth
                        size="large"
                        label="Next"
                        onClick={async () => {
                            const errors = await validateForm()
                            if (errors.ethnicity) {
                                setShowErrors(true)
                                return
                            }

                            onCompletedEthnicity()
                        }}
                    />
                </>
            )}
        </>
    )
}

export default Ethnicity
