"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.symptoms = void 0;
exports.symptoms = [
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} restricts food",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ED-7a",
                    "optionSummaries": {
                        "9082a482-a039-5b70-b31e-a8342bbdb95a": "sometimes",
                        "2acebc45-5b8e-5782-ba7e-1ec31009bb9c": "quite often",
                        "199f3ebc-f02f-538a-b494-92f79c209192": "very often",
                        "b01109a6-f4c4-57b6-8562-09b122e04e82": "always"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Restriction and behaviours preventing weight gain"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} body checks",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ED-6a",
                    "optionSummaries": {
                        "11fdb74b-d58d-54c9-8994-f1524b3397b1": "sometimes",
                        "724252d8-2dc5-57b3-bf51-30ed7eb456ab": "quite often",
                        "7dea9557-7010-5f45-abdd-5e0bb7bd35ff": "very often",
                        "9e60ef19-8ece-50e5-90c8-d511f71d019c": "always"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Restriction and behaviours preventing weight gain"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} avoids social meals",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ED-10a",
                    "optionSummaries": {
                        "6eb3f978-4b7a-506c-ad67-ed64847345ff": "sometimes",
                        "1703d256-d3a0-56e9-89ef-5283fd73dece": "quite often",
                        "bd88202c-6e12-5515-84de-53703a947c11": "very often",
                        "3ded8329-cb3b-5188-b967-df9c684ce4bd": "always"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Restriction and behaviours preventing weight gain"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} strict eating rules",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ED-11a",
                    "optionSummaries": {
                        "5f88dfdb-d799-5181-9eb6-21249f4bb030": "mildly",
                        "441a48b1-d590-5fd9-9346-e2ae3f5f5a3b": "moderately",
                        "a1be73ba-ba3e-57d7-9b19-d95309ad84e1": "very",
                        "d56ffb70-eeb6-55d8-be61-7ca6ab62a8dd": "extremely"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Restriction and behaviours preventing weight gain"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} shuffles food and spends longer eating",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ED-12a",
                    "optionSummaries": {
                        "f82230ac-fbf9-5560-b520-3380c7ee20e5": "sometimes",
                        "dac50dc7-bb94-570f-94c6-6c47efb2b42b": "quite often",
                        "1db179fc-8fea-5b7b-a60c-58145451a67b": "very often",
                        "39c4ccfd-3adf-51bf-b3f6-2dbb497ad25a": "always"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Restriction and behaviours preventing weight gain"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "ED-8a"
            ],
            "prefix": "",
            "formatText": "{}"
        },
        "section": [
            "Current episode",
            "Duration of restriction and behaviours preventing weight gain"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "eats a large amount of food in a short time",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ED-14a",
                    "optionSummaries": {
                        "fe268cc4-6fea-518a-a640-e22f055db3e3": ""
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Binge/purge behaviours"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "amount of food is objectively {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ED-15a",
                    "optionSummaries": {
                        "7d7b1266-8035-5c61-b3d1-bbd04889c8b4": "small",
                        "41312671-a780-5bad-9cb9-6fd11fa838d6": "large"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Binge/purge behaviours"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} loss of control while eating",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ED-16a",
                    "optionSummaries": {
                        "95dec000-0404-5f0d-8b5e-d1f7b4a70621": "mild",
                        "76675190-5efd-5468-8cd3-5d4925569780": "moderate",
                        "b6459d53-8f72-57eb-bcd8-520a8cedbc85": "significant",
                        "7f30a42d-6dc1-59d3-8d2b-4d87304475ae": "severe"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Binge/purge behaviours"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} guilt and shame after overeating",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ED-17a",
                    "optionSummaries": {
                        "9ae31134-40c5-51bb-974e-e292ca168d06": "mild",
                        "dd3d351b-a88a-54ef-bfb4-48871a6580ef": "moderate",
                        "78ab7b7a-0ad7-5562-b25f-ef1d17f294e2": "very much feel",
                        "97b51c03-ffc7-5710-8f01-61d059c5396b": "severe"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Binge/purge behaviours"
        ]
    },
    {
        "answerSummary": {
            "type": "MultiOptionSummary",
            "questionId": "ED-18a",
            "title": "",
            "overrides": {
                "fbaa6f2d-b7be-5320-aa8d-771ed1a2a0ca": "other method (not listed)",
                "934f507a-f459-53f6-93fb-9350b236809d": "unknown"
            }
        },
        "section": [
            "Current episode",
            "Compensatory behaviours after eating"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "ED-22a"
            ],
            "prefix": "",
            "formatText": "{}"
        },
        "section": [
            "Current episode",
            "Duration of binge/purge cycle"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "ED-21a"
            ],
            "prefix": "",
            "formatText": "{}"
        },
        "section": [
            "Current episode",
            "Frequency of binge/purge cycle (per week)"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "ED-8b"
            ],
            "prefix": "",
            "formatText": "{}"
        },
        "section": [
            "Current episode",
            "Time since last episode of binge/compensatory behaviours"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} desire to lose weight",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ED-s1",
                    "optionSummaries": {
                        "ca9a2825-624b-5e76-9afa-6a30812757ca": "mild",
                        "ed4e0160-626b-5018-a3fd-8582190b6617": "moderate",
                        "2cfff998-c76f-59bc-9e49-99e990424f1f": "very intense",
                        "082d778a-6204-5e7e-b181-5b3871ae40e9": "extreme"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Other symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} afraid of gaining weight",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ED-s2",
                    "optionSummaries": {
                        "8e98bde5-9c64-51ed-b304-7a89ef6916de": "slightly",
                        "34a39e0c-d3a2-509d-9601-54365808b7bc": "moderately",
                        "35cdb4b5-54d0-544f-a02b-6b64dd448f5d": "very",
                        "cef9c521-e355-5d92-8ecf-edb119617146": "extremely"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Other symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} need for control",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ED-5.5a",
                    "optionSummaries": {
                        "fab56c49-aef8-511b-9537-336d33cde978": "mild",
                        "4de3094a-5f24-5877-8aaf-ed8d96de3bea": "moderate",
                        "bc6be0c6-b35d-59fb-ba45-63cd0e72ffae": "very much has a",
                        "ab8eb626-abd3-5d94-bc4f-538c9ff3660d": "extreme"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Other symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "others {}express concern about patient not eating enough",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ED-13a",
                    "optionSummaries": {
                        "50de0897-bdef-5c42-9c7f-c93e13836162": "sometimes ",
                        "982db003-ffe0-5842-9d24-2076f475a898": "quite often ",
                        "5886e877-7bf5-5ab7-b6e2-ca88ac1ae6bb": "very often ",
                        "0380b3d7-8743-57a1-a7a6-2fd7e64a97e4": "constantly "
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Other symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "living {} secret life because of eating behaviours",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ED-3a",
                    "optionSummaries": {
                        "d9b2f31b-97c9-5c52-bdc7-8d3b15df8e74": "a slightly",
                        "2a5ccb7f-3437-5616-ac25-335ed38e0cc8": "a moderately",
                        "3ecf7c28-1b3e-5019-826f-1df5f71b4e9e": "a very much",
                        "025b308d-42b4-52d0-a2bf-96091e198c9d": "an extremely"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Other symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "CombinedAnswersSummary",
            "questionsIdGroups": [
                [
                    "ED-4a"
                ],
                [
                    "ED-5a"
                ]
            ],
            "groupsType": "Mean",
            "pattern": "self-image is {}influenced by body shape or weight",
            "coefficients": {
                "0.1": "mildly ",
                "0.3": "moderately ",
                "0.6": "extremely "
            }
        },
        "section": [
            "Current episode",
            "Other symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} concentration difficulties",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ED-2a",
                    "optionSummaries": {
                        "38800324-962a-5e2a-86aa-2fe7de19f0e4": "mild",
                        "6af23488-c989-5fcf-b078-156a2915e8c2": "moderate",
                        "e774449c-42ee-5867-98c9-af9f6bddeb94": "severe"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Other symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Overall level of impairment: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ED-23.0a",
                    "optionSummaries": {
                        "a0f6bbd8-60c8-522a-ba89-7ca87093471b": "none",
                        "ba15fd4b-26e1-5281-840a-48d6411bf040": "mild",
                        "24ae6b5d-cdfd-5c2e-8f75-8ef5d76b902a": "moderate",
                        "66187ecb-00cf-56d4-868c-85561c4324c1": "significant",
                        "6331a675-84ad-5fa6-8df9-f73a483ee783": "severe"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Functional impairment"
        ]
    },
    {
        "answerSummary": {
            "type": "MultiOptionSummary",
            "questionId": "ED-23a",
            "title": "",
            "overrides": {
                "ee4ca845-9e51-55bb-9f5a-16f681e33792": "Other (not listed)",
                "eb492537-3740-4346-8f68-e26ddd65e2d6": "None reported"
            }
        },
        "section": [
            "Current episode",
            "Areas of impairment"
        ]
    }
];
