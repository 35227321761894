import { useRef } from 'react'

const useComponentWillMount = (func: () => void): void => {
    const willMount = useRef<boolean>(true)

    if (willMount.current) {
        func()
    }

    willMount.current = false
}

export default useComponentWillMount
