import React from 'react'

const SortArrow: React.FC<{ color?: string; width: number; height: number }> = ({ color = 'white', height, width }) => {
    return (
        <svg
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 49.11 122.88"
            height={`${height}px`}
            width={`${width}px`}
            color={color}
        >
            <title>height-arrow</title>
            <path d="M36.08,92.15h8.67a4.38,4.38,0,0,1,3.75,1.77,4.79,4.79,0,0,1,.2,4.35,1.73,1.73,0,0,1-.28.52c-6.79,8.66-13.58,13.67-20.37,22.33l-.11.12a4.31,4.31,0,0,1-6.77,0l-.11-.12C14.27,112.46,7.48,107.45.69,98.79a1.73,1.73,0,0,1-.28-.52,4.79,4.79,0,0,1,.2-4.35,4.4,4.4,0,0,1,3.75-1.77H13V30.73H4.36A4.4,4.4,0,0,1,.61,29a4.79,4.79,0,0,1-.2-4.35,1.73,1.73,0,0,1,.28-.52C7.48,15.43,14.27,10.42,21.06,1.76l.11-.12a4.31,4.31,0,0,1,6.77,0l.11.12c6.79,8.66,13.58,13.67,20.37,22.33a1.73,1.73,0,0,1,.28.52A4.79,4.79,0,0,1,48.5,29a4.38,4.38,0,0,1-3.75,1.77H36.08V92.15Z" />
        </svg>
    )
}

export default SortArrow
