"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.questionPredicates = exports.tests = exports.aspects = exports.questions = void 0;
exports.questions = [
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "65cd18c1-f52b-57f3-aee1-86ae16c71430",
                "text": "Continue",
                "value": 1
            }
        ],
        "id": "T-S",
        "text": "This session will ask about difficult experiences you may have had.\n\nThis can sometimes feel distressing and trigger unwanted memories, but it is important for us to build a full understanding of your indiviual needs.\n\nRemember, you can take a break at any point if you need to.\n\nThe first question will ask whether you have ever  experienced a traumatic event. After this, you will be given the option to skip this section at any point if you need to and move to the next session\n\nIf you feel like you need to speak to someone urgently, you can access crisis contact information by clicking the crisis icon.\n\nSelect 'Continue' to start.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "96789d75-b879-5f46-8963-b49a0d5a36c7",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "3587b76d-5e17-5be1-8482-6865a9629851",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "T-s1",
        "text": "Have you had one or more very distressing and traumatic experiences in your life that you feel are still affecting you now?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5",
                "text": "Continue",
                "value": 1
            }
        ],
        "id": "T-s1.5",
        "text": "We are sorry to hear you've dealt with some difficult experiences. If you feel able to carry on, we would like to ask you some more questions about this. We understand that answering questions about this can be difficult and upsetting but it is useful to help us understand us much as possible about your needs.\n\nRemember, if you feel like you need to speak to someone urgently, you can access crisis contact information by clicking the crisis icon.\n\nWhen you are ready to start the next question, please click 'Continue'.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "663d4bdf-a395-58c2-80c2-892ac7e59f91",
                "text": "Yes, I would like to start answering the questions",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "d3770e6a-b463-59c3-9904-5893bd7ead01",
                "text": "No, I would like to skip this section",
                "value": 1
            }
        ],
        "id": "T-s2",
        "text": "Would you like to start answering questions about the traumatic experience(s) you've had?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "ab657537-a14b-5d23-bcc5-8a10faac75b2",
                "text": "No, not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "4fb618ab-1a98-5a8b-8a64-000b45d953f9",
                "text": "Slightly",
                "value": 0.2
            },
            {
                "__typename": "Option",
                "id": "f8e62fbd-28e3-5527-94f5-9590869545a3",
                "text": "Moderately",
                "value": 0.4
            },
            {
                "__typename": "Option",
                "id": "ab90ebb6-b526-5d58-97dd-181c2ddaefdf",
                "text": "Very much so",
                "value": 0.6
            },
            {
                "__typename": "Option",
                "id": "cd79664e-d646-54f6-8ce2-1f236d79730f",
                "text": "Extremely",
                "value": 0.8
            },
            {
                "__typename": "Option",
                "id": "e3912e04-a5d3-5bf1-869a-a651db93c0f7",
                "text": "Skip this section",
                "value": 1
            }
        ],
        "id": "T-s3",
        "text": "Do you feel that your daily life is being affected by your distressing or traumatic experience(s)?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "2f912715-981e-56cd-9769-6fe50451871e",
                "text": "Death of somebody close to you"
            },
            {
                "__typename": "Option",
                "id": "0a40d484-e62c-53ec-811c-a195a979b58a",
                "text": "Separation from parents or caregivers (e.g. adoption or foster homes)"
            },
            {
                "__typename": "Option",
                "id": "1e6dfe79-7a7f-56cd-b00d-e51c7741148d",
                "text": "Separation or divorce of parents or caregivers"
            },
            {
                "__typename": "Option",
                "id": "8d925beb-6b03-56cb-8348-c4952f097b9f",
                "text": "Separation from partner or marriage breakdown"
            },
            {
                "__typename": "Option",
                "id": "646c6678-d42f-51d3-bf03-0e1ae066d88c",
                "text": "Personal illness, injury or accident"
            },
            {
                "__typename": "Option",
                "id": "af0c6a89-6944-5fae-be10-5243fe9bc934",
                "text": "Injury, illness or accident of someone you know"
            },
            {
                "__typename": "Option",
                "id": "b9a35392-1bfe-5319-bdb2-34a68f9dff20",
                "text": "Seeing a stranger being harmed (e.g. car crash, robbery or assault)"
            },
            {
                "__typename": "Option",
                "id": "a4253f51-5531-586e-bb9f-47e454c7456f",
                "text": "Being involved in accidental harm to another person (e.g. car crash)"
            },
            {
                "__typename": "Option",
                "id": "088cfa77-013c-5c1d-8d66-b8766ed0535c",
                "text": "Major housing change (e.g. homelessness, eviction or relocation)"
            },
            {
                "__typename": "Option",
                "id": "7f175744-2ae6-569f-a689-54802eeafbbb",
                "text": "Major job change (e.g. dismissed, laid off, demoted, signed off, relocated or made redundant)"
            },
            {
                "__typename": "Option",
                "id": "d8fafb20-a4f7-5702-82a4-2bf31ee2b523",
                "text": "Being physically assaulted or robbed by a stranger"
            },
            {
                "__typename": "Option",
                "id": "2717e852-867d-5e13-8293-2fd8ccb66dd5",
                "text": "War, torture, hostage situation or terrorist attack"
            },
            {
                "__typename": "Option",
                "id": "edcd2e11-470a-54c0-8806-13a92037301e",
                "text": "Natural disaster (e.g. tornados, tsunamis, hurricanes, floods or forest fires)"
            },
            {
                "__typename": "Option",
                "id": "99d1f7f9-ab68-56f1-b976-6c314f1b528a",
                "text": "Man-made disaster (e.g. house fires, public transportation fires or explosions)"
            },
            {
                "__typename": "Option",
                "id": "20e21d03-7bd9-504e-8726-d2e3e2fc8ef9",
                "text": "As a child: physically hurt by a family member or someone you know"
            },
            {
                "__typename": "Option",
                "id": "6137d2c9-5a9b-5ec3-a2ae-18d6ad8c5ba0",
                "text": "As an adult: physically hurt by a family member or someone you know"
            },
            {
                "__typename": "Option",
                "id": "b32206e4-0455-500a-9564-b9d1b1ddb853",
                "text": "As a child: screamed at, humiliated, insulted, intimidated or controlled by a family member or someone you know, and making you feel like you are walking on eggshells"
            },
            {
                "__typename": "Option",
                "id": "966cf398-e957-5889-b1a4-cffa64703207",
                "text": "As an adult: screamed at, humiliated, insulted, intimidated or controlled by a family member or someone you know, and making you feel like you are walking on eggshells"
            },
            {
                "__typename": "Option",
                "id": "e24f82b7-a712-5954-b5fb-421e2119db45",
                "text": "As an adult: seeing or hearing someone in your household being physically hurt, screamed at, insulted or humiliated"
            },
            {
                "__typename": "Option",
                "id": "8ec3c46b-8732-5ae9-8c3d-1846b1d05562",
                "text": "As a child: seeing or hearing someone in your household being physically hurt, screamed at, insulted or humiliated"
            },
            {
                "__typename": "Option",
                "id": "38b6ec1a-a824-5fd9-bf53-faa9fe2ee355",
                "text": "As a child: your parents or caregivers being unable to care for you in either a physical or emotional way (e.g. by not being able to feed you, not making sure you got to school or not giving you comfort and affection)"
            },
            {
                "__typename": "Option",
                "id": "8436c041-416d-5abf-8987-658006b97aef",
                "text": "Pregnancy, miscarriage, stillbirth or childbirth"
            },
            {
                "__typename": "Option",
                "id": "be940464-04bc-5c41-8742-c8094ae34ec5",
                "text": "Infertility"
            },
            {
                "__typename": "Option",
                "id": "afa84b91-8bb8-582a-97d1-8a13ec771151",
                "text": "Bullying, stigma or abuse based on your identity (e.g. racism, homophobia, sexism)"
            },
            {
                "__typename": "Option",
                "id": "eaeabf95-3b89-5b38-8233-c94102db83a4",
                "text": "Financial difficulties"
            },
            {
                "__typename": "Option",
                "id": "a64cebc6-da46-5d9b-bef5-379159acdadd",
                "text": "Other experience not listed above"
            },
            {
                "__typename": "Option",
                "id": "3a55ecb6-c41a-5f30-b1b1-e7cf0728d3a7",
                "text": "None of the above",
                "exclusive": true
            },
            {
                "__typename": "Option",
                "id": "169ef228-17c2-5dea-9ea8-4c03cf4e73eb",
                "text": "Skip this section",
                "exclusive": true
            }
        ],
        "id": "T-1",
        "text": "How would you best describe the traumatic experience(s)? Please select all that apply.",
        "isMultiselect": true
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "9083faa9-7932-5540-973c-87dff42ec1b5",
                "text": "Within the last week ",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "6c280560-c4a9-5455-b732-15e1b1c292b6",
                "text": "In the last month ",
                "value": 0.2
            },
            {
                "__typename": "Option",
                "id": "572e18ea-b0f3-5d76-a94f-f1d8c085df82",
                "text": "Several months ago ",
                "value": 0.4
            },
            {
                "__typename": "Option",
                "id": "54067bec-c99e-5b1b-a364-f59f74d62208",
                "text": "One year or longer ",
                "value": 0.6
            },
            {
                "__typename": "Option",
                "id": "a3cc76a7-4007-53c5-bc63-ad42b9f64463",
                "text": "Prefer not to say ",
                "value": 0.8
            },
            {
                "__typename": "Option",
                "id": "d4f5bbff-1a99-5091-b7cb-e485a73974f4",
                "text": "Skip the rest of this section ",
                "value": 1
            }
        ],
        "id": "T-34.1",
        "text": "The following questions will ask you more about having experienced a traumatic event. If you have experienced more than one traumatic event please answer about the event which is currently affecting you the most.\n\n\nApproximately, how long ago was this?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "dda752d7-8511-5e86-872a-a9c10dd0987c",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "b0424e65-9188-5c13-996b-2e13fca215c6",
                "text": "Somewhat",
                "value": 0.2
            },
            {
                "__typename": "Option",
                "id": "5ba6670e-35ef-5545-8135-834feda201d5",
                "text": "Very difficult",
                "value": 0.4
            },
            {
                "__typename": "Option",
                "id": "5df58c8b-2b22-57fd-a9e1-f6e61c7ddb61",
                "text": "Extremely difficult",
                "value": 0.6
            },
            {
                "__typename": "Option",
                "id": "1b126b00-ec1b-5366-89a4-d0bacc703e89",
                "text": "Prefer not to say ",
                "value": 0.8
            },
            {
                "__typename": "Option",
                "id": "cd55bc55-7826-54d9-940c-8d59fb1638f1",
                "text": "Skip the rest of this section ",
                "value": 1
            }
        ],
        "id": "T-34.2",
        "text": "At the time, how emotionally difficult did you find the traumatic experience?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "2fc0a79a-7df3-5799-9e1a-c74782435a73",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "b7db11e3-885c-51fc-843f-610d8d00db10",
                "text": "Somewhat",
                "value": 0.2
            },
            {
                "__typename": "Option",
                "id": "6c7691dc-1c1a-5410-98b7-48ffdd995bcd",
                "text": "Very much so",
                "value": 0.4
            },
            {
                "__typename": "Option",
                "id": "90158eab-7f98-553e-b73b-d954fda86727",
                "text": "Extremely",
                "value": 0.6
            },
            {
                "__typename": "Option",
                "id": "fc231225-070b-500f-b890-891ab25596fe",
                "text": "Prefer not to say ",
                "value": 0.8
            },
            {
                "__typename": "Option",
                "id": "1cb8da11-92e9-5426-90e7-445972499786",
                "text": "Skip the rest of this section",
                "value": 1
            }
        ],
        "id": "T-34.3",
        "text": "Are you still emotionally affected by the traumatic experience?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "aa992ff2-b894-50ad-87b9-1863b8c261b3",
                "text": "No, never ",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "bf6c3ef5-62fa-5172-9a8e-db0dae8fb96a",
                "text": "Sometimes",
                "value": 0.2
            },
            {
                "__typename": "Option",
                "id": "0bd84624-e550-553c-baff-f4ad880a2a4f",
                "text": "Often",
                "value": 0.4
            },
            {
                "__typename": "Option",
                "id": "34874654-ecda-51ca-94ea-045a0b3c6837",
                "text": "Always",
                "value": 0.6
            },
            {
                "__typename": "Option",
                "id": "3125e96d-cc4a-5c45-a2fa-a3d908969214",
                "text": "Prefer not to say ",
                "value": 0.8
            },
            {
                "__typename": "Option",
                "id": "60db9fcd-8952-53a9-8db4-9a0377847211",
                "text": "Skip the rest of this section",
                "value": 1
            }
        ],
        "id": "T-34.4",
        "text": "Are you experiencing repeated, distressing and unwanted memories or dreams related to the traumatic experience?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "04f50633-67b2-5a22-93dd-5faf1c805400",
                "text": "No, never ",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "40f243ca-75a7-582b-98d6-4cd8b0923958",
                "text": "Sometimes",
                "value": 0.2
            },
            {
                "__typename": "Option",
                "id": "9843f58c-c4c4-59f4-9b4f-a6507272dd62",
                "text": "Often",
                "value": 0.4
            },
            {
                "__typename": "Option",
                "id": "8ef2ba4b-524a-5546-aa24-57e8221880f7",
                "text": "Always",
                "value": 0.6
            },
            {
                "__typename": "Option",
                "id": "220dc4d5-3390-5466-91bc-3ef271716e08",
                "text": "Prefer not to say ",
                "value": 0.8
            },
            {
                "__typename": "Option",
                "id": "6c3e308f-ffec-59cc-8dec-2f10fb27061a",
                "text": "Skip the rest of this section",
                "value": 1
            }
        ],
        "id": "T-34.5",
        "text": "Some people who have gone through a traumatic event might experience sudden flashbacks where it feels as if it is happening all over again or that they are back in the moment it occurred. Is this something you are experiencing?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "5f45ff1f-a46f-55ed-9d4d-837f817e371d",
                "text": "No, never ",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "d4d2f085-f924-5ee5-a59d-b214823dab4d",
                "text": "Sometimes",
                "value": 0.2
            },
            {
                "__typename": "Option",
                "id": "10bf3060-43e8-52e2-9382-9427d00fd16a",
                "text": "Often",
                "value": 0.4
            },
            {
                "__typename": "Option",
                "id": "eff45049-db8f-5586-a84e-edfc649e51da",
                "text": "Always",
                "value": 0.6
            },
            {
                "__typename": "Option",
                "id": "28b44107-292c-5c97-9dfc-5b3cf25c6d14",
                "text": "Prefer not to say ",
                "value": 0.8
            },
            {
                "__typename": "Option",
                "id": "f437edad-132f-576f-8ca7-e4a0c66e0816",
                "text": "Skip the rest of this section",
                "value": 1
            }
        ],
        "id": "T-34.6",
        "text": "After experiencing a traumatic event, some people start avoiding situations or experiences that remind them of the event. This can make it difficult to live life in the way they want. Is this something you are experiencing?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "103cb9b5-785e-5301-afc4-4efa1f89d3f0",
                "text": "No, never ",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "465fe7ff-e2a2-5390-a835-00807ffc1a0c",
                "text": "Sometimes",
                "value": 0.2
            },
            {
                "__typename": "Option",
                "id": "c12290da-a5de-5f26-8164-110211b6bd4c",
                "text": "Often",
                "value": 0.4
            },
            {
                "__typename": "Option",
                "id": "9c131dec-46af-5942-a8f1-697d8163fe24",
                "text": "Always",
                "value": 0.6
            },
            {
                "__typename": "Option",
                "id": "07417cf6-b358-5b33-b247-78ef198c90a0",
                "text": "Prefer not to say ",
                "value": 0.8
            },
            {
                "__typename": "Option",
                "id": "639306fb-e09d-555a-a2d7-9e325c4ae9e9",
                "text": "Skip the rest of this section",
                "value": 1
            }
        ],
        "id": "T-34.7",
        "text": "If you are reminded of the traumatic event do you feel extremely upset?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "01b5d2af-e4c0-5841-934f-2b54d10e0dc5",
                "text": "No, never ",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "d97bfb6d-a890-5a97-b3fe-76734424468f",
                "text": "Sometimes",
                "value": 0.2
            },
            {
                "__typename": "Option",
                "id": "6ecd2132-af6f-569f-9a93-8c8d5f5eb615",
                "text": "Often",
                "value": 0.4
            },
            {
                "__typename": "Option",
                "id": "dd31cb8b-2e4a-542c-990e-2c589a8fb373",
                "text": "Always",
                "value": 0.6
            },
            {
                "__typename": "Option",
                "id": "4ad0ad43-f2d0-5f81-aa72-67dcb9c6b587",
                "text": "Prefer not to say ",
                "value": 0.8
            },
            {
                "__typename": "Option",
                "id": "06ea517e-15cd-50b0-be91-56266dc55c6d",
                "text": "Skip the rest of this section",
                "value": 1
            }
        ],
        "id": "T-34.8",
        "text": "If you are reminded of the traumatic event, do you experience dizziness, stomach churning, sweating or a faster heart rate?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "4cced3cb-bba5-507f-95ce-04d9f458585e",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "1478fd0b-fd2f-5293-89b6-aa0cb1307a51",
                "text": "Somewhat",
                "value": 0.2
            },
            {
                "__typename": "Option",
                "id": "3d567f1d-ec6a-53e2-90fd-c0a9ea495bb6",
                "text": "Very much so",
                "value": 0.4
            },
            {
                "__typename": "Option",
                "id": "9f3eee57-d048-5a80-acb5-e43f43f60228",
                "text": "Extremely",
                "value": 0.6
            },
            {
                "__typename": "Option",
                "id": "e93574dd-3327-519b-96a8-e3ea6d3a55ac",
                "text": "Prefer not to say ",
                "value": 0.8
            },
            {
                "__typename": "Option",
                "id": "49210a61-58a9-5942-9a9b-2f674f2c707b",
                "text": "Skip the rest of this section",
                "value": 1
            }
        ],
        "id": "T-34.9",
        "text": "Do you find it hard to control your emotions and notice yourself being more irritated or having anger outbursts after experiencing the traumatic event?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "b68a8081-73da-5df9-afe7-a19832e7ecf1",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "e3007f71-71f0-5447-9503-dd86a7329d1f",
                "text": "Somewhat",
                "value": 0.2
            },
            {
                "__typename": "Option",
                "id": "21593b50-f48c-5400-8caf-d3b67a445453",
                "text": "Very much so",
                "value": 0.4
            },
            {
                "__typename": "Option",
                "id": "086bb9b7-2311-5049-9167-f36a1437927c",
                "text": "Extremely",
                "value": 0.6
            },
            {
                "__typename": "Option",
                "id": "ffde4ab6-1983-5272-b638-ccde7958e222",
                "text": "Prefer not to say ",
                "value": 0.8
            },
            {
                "__typename": "Option",
                "id": "b92a0864-2a5e-55e4-a357-7561323780e4",
                "text": "Skip the rest of this section",
                "value": 1
            }
        ],
        "id": "T-34.10",
        "text": "Since the traumatic event, are you more likely to feel jumpy or startled when something unexpected happens?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "0d0d7d9e-83fe-54b9-8c4c-af41dd8b906c",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "e1649842-01ce-5bed-aae0-eda574ace3d2",
                "text": "Somewhat",
                "value": 0.2
            },
            {
                "__typename": "Option",
                "id": "5f030af0-4a37-526d-81c4-0134760ebf84",
                "text": "Very much so",
                "value": 0.4
            },
            {
                "__typename": "Option",
                "id": "ff7ed352-5958-5063-ac3c-8f00d3dde1d1",
                "text": "Extremely",
                "value": 0.6
            },
            {
                "__typename": "Option",
                "id": "7b3dac7e-6476-5729-bbf8-e00f963b29df",
                "text": "Prefer not to say ",
                "value": 0.8
            },
            {
                "__typename": "Option",
                "id": "21b2e91e-a1ad-54cc-9672-429635c6b1bb",
                "text": "Skip the rest of this section",
                "value": 1
            }
        ],
        "id": "T-34.11",
        "text": "Has your awareness of potential dangers become more intense since you experienced the traumatic event?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "f53887e9-04f3-5b86-9558-cb6e9b92b333",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "f8239bbb-1e37-5974-bca5-b6ba0daea1fe",
                "text": "Somewhat",
                "value": 0.2
            },
            {
                "__typename": "Option",
                "id": "6ae9c2cf-9e52-5909-bd05-ac047a11aed0",
                "text": "Very much so",
                "value": 0.4
            },
            {
                "__typename": "Option",
                "id": "d3daacee-e0e8-59d7-ac29-3f299611ee5d",
                "text": "Extremely",
                "value": 0.6
            },
            {
                "__typename": "Option",
                "id": "1711ad14-0101-530c-8ab0-20df80146988",
                "text": "Prefer not to say ",
                "value": 0.8
            },
            {
                "__typename": "Option",
                "id": "68a6733d-effe-50d0-9c07-a953c5f6128a",
                "text": "Skip the rest of this section",
                "value": 1
            }
        ],
        "id": "T-34.12",
        "text": "Have you become less able to concentrate since the traumatic event?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "f1ddd7e9-7543-5199-8926-31481a1fbd64",
                "text": "Basic needs such as eating and sleeping"
            },
            {
                "__typename": "Option",
                "id": "548224b5-3e26-5fc1-8797-11d1f01e6919",
                "text": "Working or studying"
            },
            {
                "__typename": "Option",
                "id": "0bc1e06b-52bf-59fd-95c9-5c5ae905d59c",
                "text": "Intimate relationships"
            },
            {
                "__typename": "Option",
                "id": "7e1f026a-e129-5fb8-a8a3-4eb401c4a392",
                "text": "Social life"
            },
            {
                "__typename": "Option",
                "id": "b4532aa6-0bcc-5f11-af1e-d7cb1565da1d",
                "text": "Family"
            },
            {
                "__typename": "Option",
                "id": "edf7cf90-8669-5c78-af40-3d5b6217c483",
                "text": "Leisure activities, hobbies and interests"
            },
            {
                "__typename": "Option",
                "id": "6b0a5e9f-3607-584f-99ec-6b0178992c84",
                "text": "Keeping fit and healthy"
            },
            {
                "__typename": "Option",
                "id": "aaf4ead8-1b3e-53bb-9758-560d1046b30a",
                "text": "Finances"
            },
            {
                "__typename": "Option",
                "id": "70ab1917-9ef6-5273-a79a-38f34a97fd4d",
                "text": "Other aspects of my life not mentioned above"
            },
            {
                "__typename": "Option",
                "id": "9e2be8bf-318c-46ac-b804-0c36a1c80f22",
                "text": "No areas of my life are affected since experiencing the traumatic event",
                "exclusive": true
            },
            {
                "__typename": "Option",
                "id": "b15c726a-f089-59a6-b4af-ce66c0698fbc",
                "text": "Skip the rest of this section",
                "exclusive": true
            }
        ],
        "id": "T-34.13",
        "text": "Because of experiencing the traumatic event, are you currently having difficulties in any areas of your life? Select all that apply.",
        "isMultiselect": true
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "dea28bb5-7e53-5032-a4db-a76aca90041a",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "80949ead-3bd4-5888-878f-224cddbebd60",
                "text": "To a slight extent",
                "value": 0.2
            },
            {
                "__typename": "Option",
                "id": "04711b6d-71e7-58ea-a595-a9092bd42bde",
                "text": "To a moderate extent",
                "value": 0.4
            },
            {
                "__typename": "Option",
                "id": "2061ca08-518e-5daa-b35e-03819ac07b88",
                "text": "To a significant extent",
                "value": 0.6
            },
            {
                "__typename": "Option",
                "id": "b07e2ee9-a63c-5902-9683-25e3eb727231",
                "text": "Prefer not to say ",
                "value": 0.8
            },
            {
                "__typename": "Option",
                "id": "3c34b3bd-23eb-51fd-9980-cc71c43126cf",
                "text": "Skip the rest of this section",
                "value": 1
            }
        ],
        "id": "T-34.13a",
        "text": "To what extent do the symptoms you've described related to the traumatic event affect these areas of your life, or require additional effort to maintain functioning in those areas?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "8f245622-a6a2-5b80-9d90-88282267bfcf",
                "text": "Within the last week ",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "cce99747-f442-5342-811f-2952a9cc3246",
                "text": "In the last month ",
                "value": 0.2
            },
            {
                "__typename": "Option",
                "id": "10fd8dda-f5dc-51b9-93fc-fc09ed79b932",
                "text": "Several months ago ",
                "value": 0.4
            },
            {
                "__typename": "Option",
                "id": "a8521592-054a-5b2b-98e1-f8c0b3ea00a8",
                "text": "One year or longer ",
                "value": 0.6
            },
            {
                "__typename": "Option",
                "id": "fc423d44-8ec4-521f-b617-97b57c13d0c0",
                "text": "Prefer not to say ",
                "value": 0.8
            },
            {
                "__typename": "Option",
                "id": "0366d501-9bb3-5b7c-9e65-ce9fa9bdc19e",
                "text": "Skip the rest of this section ",
                "value": 1
            }
        ],
        "id": "T-35.1",
        "text": "The following questions will ask you more about having experienced a traumatic event. If you have experienced more than one traumatic event please answer about the event which is currently affecting you the most.\n\n\nApproximately, how long ago was this?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "67809f9d-5bc0-59bb-92cc-152028b5dfd8",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "2d8f0fc4-245d-51f9-860d-df2b9ec99a3c",
                "text": "Somewhat",
                "value": 0.2
            },
            {
                "__typename": "Option",
                "id": "afa0086f-aed1-569d-8b56-6273d9e8fcbb",
                "text": "Very difficult",
                "value": 0.4
            },
            {
                "__typename": "Option",
                "id": "f25bcc46-8c38-5275-9be9-3f4ba80df5dd",
                "text": "Extremely difficult",
                "value": 0.6
            },
            {
                "__typename": "Option",
                "id": "8818f5c3-ca8b-5e1a-8f4a-5738238d28fc",
                "text": "Prefer not to say ",
                "value": 0.8
            },
            {
                "__typename": "Option",
                "id": "97706d03-4cae-5a94-87d6-7df3a9db2e41",
                "text": "Skip the rest of this section ",
                "value": 1
            }
        ],
        "id": "T-35.2",
        "text": "At the time, how emotionally difficult did you find the traumatic experience?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "f174a9f1-b064-5cf2-a3f7-e7adfc80cee7",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "39788390-fbb7-5cc6-b6a3-a65d420d91f0",
                "text": "Somewhat",
                "value": 0.2
            },
            {
                "__typename": "Option",
                "id": "ee7318eb-c751-57b3-9a6e-38248f55fbaa",
                "text": "Very much so",
                "value": 0.4
            },
            {
                "__typename": "Option",
                "id": "5aed6bad-c53b-59e3-9fa8-47fbf9f32a38",
                "text": "Extremely",
                "value": 0.6
            },
            {
                "__typename": "Option",
                "id": "ac7cd80b-8d42-562e-b921-f1fe8e38cbad",
                "text": "Prefer not to say ",
                "value": 0.8
            },
            {
                "__typename": "Option",
                "id": "d856d770-6f7c-57fd-a118-53ffb679faf4",
                "text": "Skip the rest of this section",
                "value": 1
            }
        ],
        "id": "T-35.3",
        "text": "Are you still emotionally affected by the traumatic experience?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "a67a8dc6-9238-5c10-aa20-b7a0e16f9c4a",
                "text": "No, never ",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "c5118e92-1bdf-56c8-b12f-90e686eba051",
                "text": "Sometimes",
                "value": 0.2
            },
            {
                "__typename": "Option",
                "id": "acc7c276-c5bd-59e7-b41e-6a6d5f3e1fbc",
                "text": "Often",
                "value": 0.4
            },
            {
                "__typename": "Option",
                "id": "85152711-1a93-59c8-9cf4-3e2d320646d6",
                "text": "Always",
                "value": 0.6
            },
            {
                "__typename": "Option",
                "id": "a8870f77-99d6-5f47-ba29-0b0454a4f6ae",
                "text": "Prefer not to say ",
                "value": 0.8
            },
            {
                "__typename": "Option",
                "id": "270448a7-ecf9-56f2-9a83-132ad71e9ed4",
                "text": "Skip the rest of this section",
                "value": 1
            }
        ],
        "id": "T-35.4",
        "text": "If you are reminded of the traumatic event do you feel extremely upset?",
        "isMultiselect": false
    }
];
exports.aspects = {
    "trm_re_ptsd": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "trm_re"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "has_ptsd"
                }
            }
        ],
        "anyToAll": 1
    },
    "trm_re": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "T-s1",
            "options": {
                "type": "severities",
                "severities": {
                    "96789d75-b879-5f46-8963-b49a0d5a36c7": 0.0066928509242848554,
                    "3587b76d-5e17-5be1-8482-6865a9629851": 0.9933071490757153
                }
            }
        }
    },
    "has_ptsd": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "ptsd_intrusion"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "ptsd_avoidance"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "ptsd_arousal"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "ptsd_duration"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "ptsd_distress_impairment"
                }
            }
        ],
        "anyToAll": 1
    },
    "ptsd_intrusion": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "T-34.4",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "aa992ff2-b894-50ad-87b9-1863b8c261b3": false,
                            "bf6c3ef5-62fa-5172-9a8e-db0dae8fb96a": false,
                            "0bd84624-e550-553c-baff-f4ad880a2a4f": true,
                            "34874654-ecda-51ca-94ea-045a0b3c6837": false,
                            "3125e96d-cc4a-5c45-a2fa-a3d908969214": false,
                            "60db9fcd-8952-53a9-8db4-9a0377847211": false
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "T-34.5",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "04f50633-67b2-5a22-93dd-5faf1c805400": false,
                            "40f243ca-75a7-582b-98d6-4cd8b0923958": false,
                            "9843f58c-c4c4-59f4-9b4f-a6507272dd62": true,
                            "8ef2ba4b-524a-5546-aa24-57e8221880f7": false,
                            "220dc4d5-3390-5466-91bc-3ef271716e08": false,
                            "6c3e308f-ffec-59cc-8dec-2f10fb27061a": false
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "T-34.7",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "103cb9b5-785e-5301-afc4-4efa1f89d3f0": false,
                            "465fe7ff-e2a2-5390-a835-00807ffc1a0c": false,
                            "c12290da-a5de-5f26-8164-110211b6bd4c": true,
                            "9c131dec-46af-5942-a8f1-697d8163fe24": false,
                            "07417cf6-b358-5b33-b247-78ef198c90a0": false,
                            "639306fb-e09d-555a-a2d7-9e325c4ae9e9": false
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "T-34.8",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "01b5d2af-e4c0-5841-934f-2b54d10e0dc5": false,
                            "d97bfb6d-a890-5a97-b3fe-76734424468f": false,
                            "6ecd2132-af6f-569f-9a93-8c8d5f5eb615": true,
                            "dd31cb8b-2e4a-542c-990e-2c589a8fb373": false,
                            "4ad0ad43-f2d0-5f81-aa72-67dcb9c6b587": false,
                            "06ea517e-15cd-50b0-be91-56266dc55c6d": false
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "T-34.4",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "aa992ff2-b894-50ad-87b9-1863b8c261b3": false,
                            "bf6c3ef5-62fa-5172-9a8e-db0dae8fb96a": false,
                            "0bd84624-e550-553c-baff-f4ad880a2a4f": false,
                            "34874654-ecda-51ca-94ea-045a0b3c6837": true,
                            "3125e96d-cc4a-5c45-a2fa-a3d908969214": false,
                            "60db9fcd-8952-53a9-8db4-9a0377847211": false
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "T-34.5",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "04f50633-67b2-5a22-93dd-5faf1c805400": false,
                            "40f243ca-75a7-582b-98d6-4cd8b0923958": false,
                            "9843f58c-c4c4-59f4-9b4f-a6507272dd62": false,
                            "8ef2ba4b-524a-5546-aa24-57e8221880f7": true,
                            "220dc4d5-3390-5466-91bc-3ef271716e08": false,
                            "6c3e308f-ffec-59cc-8dec-2f10fb27061a": false
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "T-34.7",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "103cb9b5-785e-5301-afc4-4efa1f89d3f0": false,
                            "465fe7ff-e2a2-5390-a835-00807ffc1a0c": false,
                            "c12290da-a5de-5f26-8164-110211b6bd4c": false,
                            "9c131dec-46af-5942-a8f1-697d8163fe24": true,
                            "07417cf6-b358-5b33-b247-78ef198c90a0": false,
                            "639306fb-e09d-555a-a2d7-9e325c4ae9e9": false
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "T-34.8",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "01b5d2af-e4c0-5841-934f-2b54d10e0dc5": false,
                            "d97bfb6d-a890-5a97-b3fe-76734424468f": false,
                            "6ecd2132-af6f-569f-9a93-8c8d5f5eb615": false,
                            "dd31cb8b-2e4a-542c-990e-2c589a8fb373": true,
                            "4ad0ad43-f2d0-5f81-aa72-67dcb9c6b587": false,
                            "06ea517e-15cd-50b0-be91-56266dc55c6d": false
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.125
    },
    "ptsd_avoidance": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "T-34.6",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "5f45ff1f-a46f-55ed-9d4d-837f817e371d": false,
                            "d4d2f085-f924-5ee5-a59d-b214823dab4d": false,
                            "10bf3060-43e8-52e2-9382-9427d00fd16a": true,
                            "eff45049-db8f-5586-a84e-edfc649e51da": false,
                            "28b44107-292c-5c97-9dfc-5b3cf25c6d14": false,
                            "f437edad-132f-576f-8ca7-e4a0c66e0816": false
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "T-34.6",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "5f45ff1f-a46f-55ed-9d4d-837f817e371d": false,
                            "d4d2f085-f924-5ee5-a59d-b214823dab4d": false,
                            "10bf3060-43e8-52e2-9382-9427d00fd16a": false,
                            "eff45049-db8f-5586-a84e-edfc649e51da": true,
                            "28b44107-292c-5c97-9dfc-5b3cf25c6d14": false,
                            "f437edad-132f-576f-8ca7-e4a0c66e0816": false
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.5
    },
    "ptsd_arousal": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "T-34.9",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "4cced3cb-bba5-507f-95ce-04d9f458585e": false,
                            "1478fd0b-fd2f-5293-89b6-aa0cb1307a51": false,
                            "3d567f1d-ec6a-53e2-90fd-c0a9ea495bb6": true,
                            "9f3eee57-d048-5a80-acb5-e43f43f60228": false,
                            "e93574dd-3327-519b-96a8-e3ea6d3a55ac": false,
                            "49210a61-58a9-5942-9a9b-2f674f2c707b": false
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "T-34.10",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "b68a8081-73da-5df9-afe7-a19832e7ecf1": false,
                            "e3007f71-71f0-5447-9503-dd86a7329d1f": false,
                            "21593b50-f48c-5400-8caf-d3b67a445453": true,
                            "086bb9b7-2311-5049-9167-f36a1437927c": false,
                            "ffde4ab6-1983-5272-b638-ccde7958e222": false,
                            "b92a0864-2a5e-55e4-a357-7561323780e4": false
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "T-34.11",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "0d0d7d9e-83fe-54b9-8c4c-af41dd8b906c": false,
                            "e1649842-01ce-5bed-aae0-eda574ace3d2": false,
                            "5f030af0-4a37-526d-81c4-0134760ebf84": true,
                            "ff7ed352-5958-5063-ac3c-8f00d3dde1d1": false,
                            "7b3dac7e-6476-5729-bbf8-e00f963b29df": false,
                            "21b2e91e-a1ad-54cc-9672-429635c6b1bb": false
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "T-34.12",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "f53887e9-04f3-5b86-9558-cb6e9b92b333": false,
                            "f8239bbb-1e37-5974-bca5-b6ba0daea1fe": false,
                            "6ae9c2cf-9e52-5909-bd05-ac047a11aed0": true,
                            "d3daacee-e0e8-59d7-ac29-3f299611ee5d": false,
                            "1711ad14-0101-530c-8ab0-20df80146988": false,
                            "68a6733d-effe-50d0-9c07-a953c5f6128a": false
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "T-34.9",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "4cced3cb-bba5-507f-95ce-04d9f458585e": false,
                            "1478fd0b-fd2f-5293-89b6-aa0cb1307a51": false,
                            "3d567f1d-ec6a-53e2-90fd-c0a9ea495bb6": false,
                            "9f3eee57-d048-5a80-acb5-e43f43f60228": true,
                            "e93574dd-3327-519b-96a8-e3ea6d3a55ac": false,
                            "49210a61-58a9-5942-9a9b-2f674f2c707b": false
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "T-34.10",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "b68a8081-73da-5df9-afe7-a19832e7ecf1": false,
                            "e3007f71-71f0-5447-9503-dd86a7329d1f": false,
                            "21593b50-f48c-5400-8caf-d3b67a445453": false,
                            "086bb9b7-2311-5049-9167-f36a1437927c": true,
                            "ffde4ab6-1983-5272-b638-ccde7958e222": false,
                            "b92a0864-2a5e-55e4-a357-7561323780e4": false
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "T-34.11",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "0d0d7d9e-83fe-54b9-8c4c-af41dd8b906c": false,
                            "e1649842-01ce-5bed-aae0-eda574ace3d2": false,
                            "5f030af0-4a37-526d-81c4-0134760ebf84": false,
                            "ff7ed352-5958-5063-ac3c-8f00d3dde1d1": true,
                            "7b3dac7e-6476-5729-bbf8-e00f963b29df": false,
                            "21b2e91e-a1ad-54cc-9672-429635c6b1bb": false
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "T-34.12",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "f53887e9-04f3-5b86-9558-cb6e9b92b333": false,
                            "f8239bbb-1e37-5974-bca5-b6ba0daea1fe": false,
                            "6ae9c2cf-9e52-5909-bd05-ac047a11aed0": false,
                            "d3daacee-e0e8-59d7-ac29-3f299611ee5d": true,
                            "1711ad14-0101-530c-8ab0-20df80146988": false,
                            "68a6733d-effe-50d0-9c07-a953c5f6128a": false
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.125
    },
    "ptsd_duration": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "T-34.1",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "9083faa9-7932-5540-973c-87dff42ec1b5": false,
                            "6c280560-c4a9-5455-b732-15e1b1c292b6": false,
                            "572e18ea-b0f3-5d76-a94f-f1d8c085df82": true,
                            "54067bec-c99e-5b1b-a364-f59f74d62208": false,
                            "a3cc76a7-4007-53c5-bc63-ad42b9f64463": false,
                            "d4f5bbff-1a99-5091-b7cb-e485a73974f4": false
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "T-34.1",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "9083faa9-7932-5540-973c-87dff42ec1b5": false,
                            "6c280560-c4a9-5455-b732-15e1b1c292b6": false,
                            "572e18ea-b0f3-5d76-a94f-f1d8c085df82": false,
                            "54067bec-c99e-5b1b-a364-f59f74d62208": true,
                            "a3cc76a7-4007-53c5-bc63-ad42b9f64463": false,
                            "d4f5bbff-1a99-5091-b7cb-e485a73974f4": false
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.5
    },
    "ptsd_distress_impairment": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "T-34.4",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "aa992ff2-b894-50ad-87b9-1863b8c261b3": false,
                            "bf6c3ef5-62fa-5172-9a8e-db0dae8fb96a": false,
                            "0bd84624-e550-553c-baff-f4ad880a2a4f": true,
                            "34874654-ecda-51ca-94ea-045a0b3c6837": false,
                            "3125e96d-cc4a-5c45-a2fa-a3d908969214": false,
                            "60db9fcd-8952-53a9-8db4-9a0377847211": false
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "T-34.4",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "aa992ff2-b894-50ad-87b9-1863b8c261b3": false,
                            "bf6c3ef5-62fa-5172-9a8e-db0dae8fb96a": false,
                            "0bd84624-e550-553c-baff-f4ad880a2a4f": false,
                            "34874654-ecda-51ca-94ea-045a0b3c6837": true,
                            "3125e96d-cc4a-5c45-a2fa-a3d908969214": false,
                            "60db9fcd-8952-53a9-8db4-9a0377847211": false
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "ptsd_impairment"
                }
            }
        ],
        "anyToAll": 0.3333333333333333
    },
    "ptsd_impairment": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "T34.13_basic"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "T34.13_work"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "T34.13_relationships"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "T34.13_social"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "T34.14_family"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "T34.14_leisure"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "T34.14_health"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "T34.14_financial"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "T34.14_other"
                        }
                    }
                ],
                "anyToAll": 0.2222222222222222
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "T-34.13a",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "dea28bb5-7e53-5032-a4db-a76aca90041a": false,
                                    "80949ead-3bd4-5888-878f-224cddbebd60": false,
                                    "04711b6d-71e7-58ea-a595-a9092bd42bde": true,
                                    "2061ca08-518e-5daa-b35e-03819ac07b88": false,
                                    "b07e2ee9-a63c-5902-9683-25e3eb727231": false,
                                    "3c34b3bd-23eb-51fd-9980-cc71c43126cf": false
                                }
                            }
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "T-34.13a",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "dea28bb5-7e53-5032-a4db-a76aca90041a": false,
                                    "80949ead-3bd4-5888-878f-224cddbebd60": false,
                                    "04711b6d-71e7-58ea-a595-a9092bd42bde": false,
                                    "2061ca08-518e-5daa-b35e-03819ac07b88": true,
                                    "b07e2ee9-a63c-5902-9683-25e3eb727231": false,
                                    "3c34b3bd-23eb-51fd-9980-cc71c43126cf": false
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 0.5
            }
        ],
        "anyToAll": 0.5
    },
    "T1_death": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "T-1",
            "options": {
                "type": "severities",
                "severities": {
                    "2f912715-981e-56cd-9769-6fe50451871e": 1
                }
            }
        }
    },
    "T1_adopt": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "T-1",
            "options": {
                "type": "severities",
                "severities": {
                    "0a40d484-e62c-53ec-811c-a195a979b58a": 1
                }
            }
        }
    },
    "T1_parentsep": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "T-1",
            "options": {
                "type": "severities",
                "severities": {
                    "1e6dfe79-7a7f-56cd-b00d-e51c7741148d": 1
                }
            }
        }
    },
    "T1_partnersep": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "T-1",
            "options": {
                "type": "severities",
                "severities": {
                    "8d925beb-6b03-56cb-8348-c4952f097b9f": 1
                }
            }
        }
    },
    "T2_personalill": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "T-1",
            "options": {
                "type": "severities",
                "severities": {
                    "646c6678-d42f-51d3-bf03-0e1ae066d88c": 1
                }
            }
        }
    },
    "T2_otherill": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "T-1",
            "options": {
                "type": "severities",
                "severities": {
                    "af0c6a89-6944-5fae-be10-5243fe9bc934": 1
                }
            }
        }
    },
    "T2_harmtoother": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "T-1",
            "options": {
                "type": "severities",
                "severities": {
                    "b9a35392-1bfe-5319-bdb2-34a68f9dff20": 1
                }
            }
        }
    },
    "T2_accidentharm": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "T-1",
            "options": {
                "type": "severities",
                "severities": {
                    "a4253f51-5531-586e-bb9f-47e454c7456f": 1
                }
            }
        }
    },
    "T3_housing": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "T-1",
            "options": {
                "type": "severities",
                "severities": {
                    "088cfa77-013c-5c1d-8d66-b8766ed0535c": 1
                }
            }
        }
    },
    "T3_job": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "T-1",
            "options": {
                "type": "severities",
                "severities": {
                    "7f175744-2ae6-569f-a689-54802eeafbbb": 1
                }
            }
        }
    },
    "T3_physassault": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "T-1",
            "options": {
                "type": "severities",
                "severities": {
                    "d8fafb20-a4f7-5702-82a4-2bf31ee2b523": 1
                }
            }
        }
    },
    "T3_war": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "T-1",
            "options": {
                "type": "severities",
                "severities": {
                    "2717e852-867d-5e13-8293-2fd8ccb66dd5": 1
                }
            }
        }
    },
    "T4_natdisaster": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "T-1",
            "options": {
                "type": "severities",
                "severities": {
                    "edcd2e11-470a-54c0-8806-13a92037301e": 1
                }
            }
        }
    },
    "T4_mandisaster": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "T-1",
            "options": {
                "type": "severities",
                "severities": {
                    "99d1f7f9-ab68-56f1-b976-6c314f1b528a": 1
                }
            }
        }
    },
    "T4_chphysabuse": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "T-1",
            "options": {
                "type": "severities",
                "severities": {
                    "20e21d03-7bd9-504e-8726-d2e3e2fc8ef9": 1
                }
            }
        }
    },
    "T4_adphysabuse": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "T-1",
            "options": {
                "type": "severities",
                "severities": {
                    "6137d2c9-5a9b-5ec3-a2ae-18d6ad8c5ba0": 1
                }
            }
        }
    },
    "T5_chemoabuse": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "T-1",
            "options": {
                "type": "severities",
                "severities": {
                    "b32206e4-0455-500a-9564-b9d1b1ddb853": 1
                }
            }
        }
    },
    "T5_ademoabuse": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "T-1",
            "options": {
                "type": "severities",
                "severities": {
                    "966cf398-e957-5889-b1a4-cffa64703207": 1
                }
            }
        }
    },
    "T5_adwitnessabuse": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "T-1",
            "options": {
                "type": "severities",
                "severities": {
                    "e24f82b7-a712-5954-b5fb-421e2119db45": 1
                }
            }
        }
    },
    "T5_chwitnessabuse": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "T-1",
            "options": {
                "type": "severities",
                "severities": {
                    "8ec3c46b-8732-5ae9-8c3d-1846b1d05562": 1
                }
            }
        }
    },
    "T6_neglect": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "T-1",
            "options": {
                "type": "severities",
                "severities": {
                    "38b6ec1a-a824-5fd9-bf53-faa9fe2ee355": 1
                }
            }
        }
    },
    "T6_preg": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "T-1",
            "options": {
                "type": "severities",
                "severities": {
                    "8436c041-416d-5abf-8987-658006b97aef": 1
                }
            }
        }
    },
    "T6_infertility": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "T-1",
            "options": {
                "type": "severities",
                "severities": {
                    "be940464-04bc-5c41-8742-c8094ae34ec5": 1
                }
            }
        }
    },
    "T6_bullying": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "T-1",
            "options": {
                "type": "severities",
                "severities": {
                    "afa84b91-8bb8-582a-97d1-8a13ec771151": 1
                }
            }
        }
    },
    "T7_finance": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "T-1",
            "options": {
                "type": "severities",
                "severities": {
                    "eaeabf95-3b89-5b38-8233-c94102db83a4": 1
                }
            }
        }
    },
    "T7_other": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "T-1",
            "options": {
                "type": "severities",
                "severities": {
                    "a64cebc6-da46-5d9b-bef5-379159acdadd": 1
                }
            }
        }
    },
    "T7_none": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "T-1",
            "options": {
                "type": "severities",
                "severities": {
                    "3a55ecb6-c41a-5f30-b1b1-e7cf0728d3a7": 1
                }
            }
        }
    },
    "T7_skip": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "T-1",
            "options": {
                "type": "severities",
                "severities": {
                    "169ef228-17c2-5dea-9ea8-4c03cf4e73eb": 1
                }
            }
        }
    },
    "T34.13_basic": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "T-34.13",
            "options": {
                "type": "severities",
                "severities": {
                    "f1ddd7e9-7543-5199-8926-31481a1fbd64": 1
                }
            }
        }
    },
    "T34.13_work": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "T-34.13",
            "options": {
                "type": "severities",
                "severities": {
                    "548224b5-3e26-5fc1-8797-11d1f01e6919": 1
                }
            }
        }
    },
    "T34.13_relationships": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "T-34.13",
            "options": {
                "type": "severities",
                "severities": {
                    "0bc1e06b-52bf-59fd-95c9-5c5ae905d59c": 1
                }
            }
        }
    },
    "T34.13_social": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "T-34.13",
            "options": {
                "type": "severities",
                "severities": {
                    "7e1f026a-e129-5fb8-a8a3-4eb401c4a392": 1
                }
            }
        }
    },
    "T34.14_family": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "T-34.13",
            "options": {
                "type": "severities",
                "severities": {
                    "b4532aa6-0bcc-5f11-af1e-d7cb1565da1d": 1
                }
            }
        }
    },
    "T34.14_leisure": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "T-34.13",
            "options": {
                "type": "severities",
                "severities": {
                    "edf7cf90-8669-5c78-af40-3d5b6217c483": 1
                }
            }
        }
    },
    "T34.14_health": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "T-34.13",
            "options": {
                "type": "severities",
                "severities": {
                    "6b0a5e9f-3607-584f-99ec-6b0178992c84": 1
                }
            }
        }
    },
    "T34.14_financial": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "T-34.13",
            "options": {
                "type": "severities",
                "severities": {
                    "aaf4ead8-1b3e-53bb-9758-560d1046b30a": 1
                }
            }
        }
    },
    "T34.14_other": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "T-34.13",
            "options": {
                "type": "severities",
                "severities": {
                    "70ab1917-9ef6-5273-a79a-38f34a97fd4d": 1
                }
            }
        }
    },
    "T34.13_none": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "T-34.13",
            "options": {
                "type": "severities",
                "severities": {
                    "9e2be8bf-318c-46ac-b804-0c36a1c80f22": 1
                }
            }
        }
    },
    "T34.14_skip": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "T-34.13",
            "options": {
                "type": "severities",
                "severities": {
                    "b15c726a-f089-59a6-b4af-ce66c0698fbc": 1
                }
            }
        }
    },
    "not(T-s1=0)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "T-s1",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "96789d75-b879-5f46-8963-b49a0d5a36c7": true,
                        "3587b76d-5e17-5be1-8482-6865a9629851": false
                    }
                }
            }
        }
    },
    "T-s2=0": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "T-s2",
            "options": {
                "type": "predicates",
                "predicates": {
                    "663d4bdf-a395-58c2-80c2-892ac7e59f91": true,
                    "d3770e6a-b463-59c3-9904-5893bd7ead01": false
                }
            }
        }
    },
    "not(T-s3=1)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "T-s3",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "ab657537-a14b-5d23-bcc5-8a10faac75b2": false,
                        "4fb618ab-1a98-5a8b-8a64-000b45d953f9": false,
                        "f8e62fbd-28e3-5527-94f5-9590869545a3": false,
                        "ab90ebb6-b526-5d58-97dd-181c2ddaefdf": false,
                        "cd79664e-d646-54f6-8ce2-1f236d79730f": false,
                        "e3912e04-a5d3-5bf1-869a-a651db93c0f7": true
                    }
                }
            }
        }
    },
    "and[or[isUndefined(T7_skip=1), not(T7_skip=1)], or[T2_personalill=1, T2_otherill=1, T2_harmtoother=1, T2_accidentharm=1, T3_physassault=1, T3_war=1, T4_natdisaster=1, T4_mandisaster=1, T4_adphysabuse=1, T5_ademoabuse=1, T5_adwitnessabuse=1, T6_preg=1, T6_bullying=1, T7_other=1], not(isUndefined(T-1=0))]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "T7_skip"
                            }
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "T7_skip"
                            }
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "T2_personalill"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "T2_otherill"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "T2_harmtoother"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "T2_accidentharm"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "T3_physassault"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "T3_war"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "T4_natdisaster"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "T4_mandisaster"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "T4_adphysabuse"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "T5_ademoabuse"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "T5_adwitnessabuse"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "T6_preg"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "T6_bullying"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "T7_other"
                        }
                    }
                ],
                "anyToAll": 0.07142857142857142
            },
            {
                "type": "not",
                "wire": {
                    "type": "isUnknown",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "T-1",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "2f912715-981e-56cd-9769-6fe50451871e": false,
                                    "0a40d484-e62c-53ec-811c-a195a979b58a": false,
                                    "1e6dfe79-7a7f-56cd-b00d-e51c7741148d": false,
                                    "8d925beb-6b03-56cb-8348-c4952f097b9f": false,
                                    "646c6678-d42f-51d3-bf03-0e1ae066d88c": false,
                                    "af0c6a89-6944-5fae-be10-5243fe9bc934": false,
                                    "b9a35392-1bfe-5319-bdb2-34a68f9dff20": false,
                                    "a4253f51-5531-586e-bb9f-47e454c7456f": false,
                                    "088cfa77-013c-5c1d-8d66-b8766ed0535c": false,
                                    "7f175744-2ae6-569f-a689-54802eeafbbb": false,
                                    "d8fafb20-a4f7-5702-82a4-2bf31ee2b523": false,
                                    "2717e852-867d-5e13-8293-2fd8ccb66dd5": false,
                                    "edcd2e11-470a-54c0-8806-13a92037301e": false,
                                    "99d1f7f9-ab68-56f1-b976-6c314f1b528a": false,
                                    "20e21d03-7bd9-504e-8726-d2e3e2fc8ef9": false,
                                    "6137d2c9-5a9b-5ec3-a2ae-18d6ad8c5ba0": false,
                                    "b32206e4-0455-500a-9564-b9d1b1ddb853": false,
                                    "966cf398-e957-5889-b1a4-cffa64703207": false,
                                    "e24f82b7-a712-5954-b5fb-421e2119db45": false,
                                    "8ec3c46b-8732-5ae9-8c3d-1846b1d05562": false,
                                    "38b6ec1a-a824-5fd9-bf53-faa9fe2ee355": false,
                                    "8436c041-416d-5abf-8987-658006b97aef": false,
                                    "be940464-04bc-5c41-8742-c8094ae34ec5": false,
                                    "afa84b91-8bb8-582a-97d1-8a13ec771151": false,
                                    "eaeabf95-3b89-5b38-8233-c94102db83a4": false,
                                    "a64cebc6-da46-5d9b-bef5-379159acdadd": false,
                                    "3a55ecb6-c41a-5f30-b1b1-e7cf0728d3a7": false,
                                    "169ef228-17c2-5dea-9ea8-4c03cf4e73eb": false
                                }
                            }
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "not(T-34.1=1)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "T-34.1",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "9083faa9-7932-5540-973c-87dff42ec1b5": false,
                        "6c280560-c4a9-5455-b732-15e1b1c292b6": false,
                        "572e18ea-b0f3-5d76-a94f-f1d8c085df82": false,
                        "54067bec-c99e-5b1b-a364-f59f74d62208": false,
                        "a3cc76a7-4007-53c5-bc63-ad42b9f64463": false,
                        "d4f5bbff-1a99-5091-b7cb-e485a73974f4": true
                    }
                }
            }
        }
    },
    "not(T-34.2=1)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "T-34.2",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "dda752d7-8511-5e86-872a-a9c10dd0987c": false,
                        "b0424e65-9188-5c13-996b-2e13fca215c6": false,
                        "5ba6670e-35ef-5545-8135-834feda201d5": false,
                        "5df58c8b-2b22-57fd-a9e1-f6e61c7ddb61": false,
                        "1b126b00-ec1b-5366-89a4-d0bacc703e89": false,
                        "cd55bc55-7826-54d9-940c-8d59fb1638f1": true
                    }
                }
            }
        }
    },
    "not(T-34.3=1)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "T-34.3",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "2fc0a79a-7df3-5799-9e1a-c74782435a73": false,
                        "b7db11e3-885c-51fc-843f-610d8d00db10": false,
                        "6c7691dc-1c1a-5410-98b7-48ffdd995bcd": false,
                        "90158eab-7f98-553e-b73b-d954fda86727": false,
                        "fc231225-070b-500f-b890-891ab25596fe": false,
                        "1cb8da11-92e9-5426-90e7-445972499786": true
                    }
                }
            }
        }
    },
    "not(T-34.4=1)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "T-34.4",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "aa992ff2-b894-50ad-87b9-1863b8c261b3": false,
                        "bf6c3ef5-62fa-5172-9a8e-db0dae8fb96a": false,
                        "0bd84624-e550-553c-baff-f4ad880a2a4f": false,
                        "34874654-ecda-51ca-94ea-045a0b3c6837": false,
                        "3125e96d-cc4a-5c45-a2fa-a3d908969214": false,
                        "60db9fcd-8952-53a9-8db4-9a0377847211": true
                    }
                }
            }
        }
    },
    "not(T-34.5=1)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "T-34.5",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "04f50633-67b2-5a22-93dd-5faf1c805400": false,
                        "40f243ca-75a7-582b-98d6-4cd8b0923958": false,
                        "9843f58c-c4c4-59f4-9b4f-a6507272dd62": false,
                        "8ef2ba4b-524a-5546-aa24-57e8221880f7": false,
                        "220dc4d5-3390-5466-91bc-3ef271716e08": false,
                        "6c3e308f-ffec-59cc-8dec-2f10fb27061a": true
                    }
                }
            }
        }
    },
    "not(T-34.6=1)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "T-34.6",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "5f45ff1f-a46f-55ed-9d4d-837f817e371d": false,
                        "d4d2f085-f924-5ee5-a59d-b214823dab4d": false,
                        "10bf3060-43e8-52e2-9382-9427d00fd16a": false,
                        "eff45049-db8f-5586-a84e-edfc649e51da": false,
                        "28b44107-292c-5c97-9dfc-5b3cf25c6d14": false,
                        "f437edad-132f-576f-8ca7-e4a0c66e0816": true
                    }
                }
            }
        }
    },
    "not(T-34.7=1)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "T-34.7",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "103cb9b5-785e-5301-afc4-4efa1f89d3f0": false,
                        "465fe7ff-e2a2-5390-a835-00807ffc1a0c": false,
                        "c12290da-a5de-5f26-8164-110211b6bd4c": false,
                        "9c131dec-46af-5942-a8f1-697d8163fe24": false,
                        "07417cf6-b358-5b33-b247-78ef198c90a0": false,
                        "639306fb-e09d-555a-a2d7-9e325c4ae9e9": true
                    }
                }
            }
        }
    },
    "not(T-34.8=1)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "T-34.8",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "01b5d2af-e4c0-5841-934f-2b54d10e0dc5": false,
                        "d97bfb6d-a890-5a97-b3fe-76734424468f": false,
                        "6ecd2132-af6f-569f-9a93-8c8d5f5eb615": false,
                        "dd31cb8b-2e4a-542c-990e-2c589a8fb373": false,
                        "4ad0ad43-f2d0-5f81-aa72-67dcb9c6b587": false,
                        "06ea517e-15cd-50b0-be91-56266dc55c6d": true
                    }
                }
            }
        }
    },
    "not(T-34.9=1)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "T-34.9",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "4cced3cb-bba5-507f-95ce-04d9f458585e": false,
                        "1478fd0b-fd2f-5293-89b6-aa0cb1307a51": false,
                        "3d567f1d-ec6a-53e2-90fd-c0a9ea495bb6": false,
                        "9f3eee57-d048-5a80-acb5-e43f43f60228": false,
                        "e93574dd-3327-519b-96a8-e3ea6d3a55ac": false,
                        "49210a61-58a9-5942-9a9b-2f674f2c707b": true
                    }
                }
            }
        }
    },
    "not(T-34.10=1)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "T-34.10",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "b68a8081-73da-5df9-afe7-a19832e7ecf1": false,
                        "e3007f71-71f0-5447-9503-dd86a7329d1f": false,
                        "21593b50-f48c-5400-8caf-d3b67a445453": false,
                        "086bb9b7-2311-5049-9167-f36a1437927c": false,
                        "ffde4ab6-1983-5272-b638-ccde7958e222": false,
                        "b92a0864-2a5e-55e4-a357-7561323780e4": true
                    }
                }
            }
        }
    },
    "not(T-34.11=1)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "T-34.11",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "0d0d7d9e-83fe-54b9-8c4c-af41dd8b906c": false,
                        "e1649842-01ce-5bed-aae0-eda574ace3d2": false,
                        "5f030af0-4a37-526d-81c4-0134760ebf84": false,
                        "ff7ed352-5958-5063-ac3c-8f00d3dde1d1": false,
                        "7b3dac7e-6476-5729-bbf8-e00f963b29df": false,
                        "21b2e91e-a1ad-54cc-9672-429635c6b1bb": true
                    }
                }
            }
        }
    },
    "not(T-34.12=1)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "T-34.12",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "f53887e9-04f3-5b86-9558-cb6e9b92b333": false,
                        "f8239bbb-1e37-5974-bca5-b6ba0daea1fe": false,
                        "6ae9c2cf-9e52-5909-bd05-ac047a11aed0": false,
                        "d3daacee-e0e8-59d7-ac29-3f299611ee5d": false,
                        "1711ad14-0101-530c-8ab0-20df80146988": false,
                        "68a6733d-effe-50d0-9c07-a953c5f6128a": true
                    }
                }
            }
        }
    },
    "and[or[isUndefined(T34.14_skip=1), not(T34.14_skip=1)], or[isUndefined(T34.13_none=1), not(T34.13_none=1)], not(isUndefined(T-34.13=0))]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "T34.14_skip"
                            }
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "T34.14_skip"
                            }
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "T34.13_none"
                            }
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "T34.13_none"
                            }
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "not",
                "wire": {
                    "type": "isUnknown",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "T-34.13",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "f1ddd7e9-7543-5199-8926-31481a1fbd64": false,
                                    "548224b5-3e26-5fc1-8797-11d1f01e6919": false,
                                    "0bc1e06b-52bf-59fd-95c9-5c5ae905d59c": false,
                                    "7e1f026a-e129-5fb8-a8a3-4eb401c4a392": false,
                                    "b4532aa6-0bcc-5f11-af1e-d7cb1565da1d": false,
                                    "edf7cf90-8669-5c78-af40-3d5b6217c483": false,
                                    "6b0a5e9f-3607-584f-99ec-6b0178992c84": false,
                                    "aaf4ead8-1b3e-53bb-9758-560d1046b30a": false,
                                    "70ab1917-9ef6-5273-a79a-38f34a97fd4d": false,
                                    "9e2be8bf-318c-46ac-b804-0c36a1c80f22": false,
                                    "b15c726a-f089-59a6-b4af-ce66c0698fbc": false
                                }
                            }
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "and[or[isUndefined(T7_skip=1), not(T7_skip=1)], or[isUndefined(T2_personalill=1), not(T2_personalill=1)], or[isUndefined(T2_otherill=1), not(T2_otherill=1)], or[isUndefined(T2_harmtoother=1), not(T2_harmtoother=1)], or[isUndefined(T2_accidentharm=1), not(T2_accidentharm=1)], or[isUndefined(T3_physassault=1), not(T3_physassault=1)], or[isUndefined(T3_war=1), not(T3_war=1)], or[isUndefined(T4_natdisaster=1), not(T4_natdisaster=1)], or[isUndefined(T4_mandisaster=1), not(T4_mandisaster=1)], or[isUndefined(T4_adphysabuse=1), not(T4_adphysabuse=1)], or[isUndefined(T5_ademoabuse=1), not(T5_ademoabuse=1)], or[isUndefined(T5_adwitnessabuse=1), not(T5_adwitnessabuse=1)], or[isUndefined(T6_preg=1), not(T6_preg=1)], or[isUndefined(T6_bullying=1), not(T6_bullying=1)], or[isUndefined(T7_other=1), not(T7_other=1)], or[T1_death=1, T1_adopt=1, T1_parentsep=1, T1_partnersep=1, T3_housing=1, T3_job=1, T4_chphysabuse=1, T5_chemoabuse=1, T5_chwitnessabuse=1, T6_neglect=1, T6_infertility=1, T7_finance=1], not(isUndefined(T-1=0))]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "T7_skip"
                            }
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "T7_skip"
                            }
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "T2_personalill"
                            }
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "T2_personalill"
                            }
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "T2_otherill"
                            }
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "T2_otherill"
                            }
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "T2_harmtoother"
                            }
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "T2_harmtoother"
                            }
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "T2_accidentharm"
                            }
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "T2_accidentharm"
                            }
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "T3_physassault"
                            }
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "T3_physassault"
                            }
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "T3_war"
                            }
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "T3_war"
                            }
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "T4_natdisaster"
                            }
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "T4_natdisaster"
                            }
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "T4_mandisaster"
                            }
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "T4_mandisaster"
                            }
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "T4_adphysabuse"
                            }
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "T4_adphysabuse"
                            }
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "T5_ademoabuse"
                            }
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "T5_ademoabuse"
                            }
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "T5_adwitnessabuse"
                            }
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "T5_adwitnessabuse"
                            }
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "T6_preg"
                            }
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "T6_preg"
                            }
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "T6_bullying"
                            }
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "T6_bullying"
                            }
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "T7_other"
                            }
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "T7_other"
                            }
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "T1_death"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "T1_adopt"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "T1_parentsep"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "T1_partnersep"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "T3_housing"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "T3_job"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "T4_chphysabuse"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "T5_chemoabuse"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "T5_chwitnessabuse"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "T6_neglect"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "T6_infertility"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "T7_finance"
                        }
                    }
                ],
                "anyToAll": 0.08333333333333333
            },
            {
                "type": "not",
                "wire": {
                    "type": "isUnknown",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "T-1",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "2f912715-981e-56cd-9769-6fe50451871e": false,
                                    "0a40d484-e62c-53ec-811c-a195a979b58a": false,
                                    "1e6dfe79-7a7f-56cd-b00d-e51c7741148d": false,
                                    "8d925beb-6b03-56cb-8348-c4952f097b9f": false,
                                    "646c6678-d42f-51d3-bf03-0e1ae066d88c": false,
                                    "af0c6a89-6944-5fae-be10-5243fe9bc934": false,
                                    "b9a35392-1bfe-5319-bdb2-34a68f9dff20": false,
                                    "a4253f51-5531-586e-bb9f-47e454c7456f": false,
                                    "088cfa77-013c-5c1d-8d66-b8766ed0535c": false,
                                    "7f175744-2ae6-569f-a689-54802eeafbbb": false,
                                    "d8fafb20-a4f7-5702-82a4-2bf31ee2b523": false,
                                    "2717e852-867d-5e13-8293-2fd8ccb66dd5": false,
                                    "edcd2e11-470a-54c0-8806-13a92037301e": false,
                                    "99d1f7f9-ab68-56f1-b976-6c314f1b528a": false,
                                    "20e21d03-7bd9-504e-8726-d2e3e2fc8ef9": false,
                                    "6137d2c9-5a9b-5ec3-a2ae-18d6ad8c5ba0": false,
                                    "b32206e4-0455-500a-9564-b9d1b1ddb853": false,
                                    "966cf398-e957-5889-b1a4-cffa64703207": false,
                                    "e24f82b7-a712-5954-b5fb-421e2119db45": false,
                                    "8ec3c46b-8732-5ae9-8c3d-1846b1d05562": false,
                                    "38b6ec1a-a824-5fd9-bf53-faa9fe2ee355": false,
                                    "8436c041-416d-5abf-8987-658006b97aef": false,
                                    "be940464-04bc-5c41-8742-c8094ae34ec5": false,
                                    "afa84b91-8bb8-582a-97d1-8a13ec771151": false,
                                    "eaeabf95-3b89-5b38-8233-c94102db83a4": false,
                                    "a64cebc6-da46-5d9b-bef5-379159acdadd": false,
                                    "3a55ecb6-c41a-5f30-b1b1-e7cf0728d3a7": false,
                                    "169ef228-17c2-5dea-9ea8-4c03cf4e73eb": false
                                }
                            }
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "not(T-35.1=1)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "T-35.1",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "8f245622-a6a2-5b80-9d90-88282267bfcf": false,
                        "cce99747-f442-5342-811f-2952a9cc3246": false,
                        "10fd8dda-f5dc-51b9-93fc-fc09ed79b932": false,
                        "a8521592-054a-5b2b-98e1-f8c0b3ea00a8": false,
                        "fc423d44-8ec4-521f-b617-97b57c13d0c0": false,
                        "0366d501-9bb3-5b7c-9e65-ce9fa9bdc19e": true
                    }
                }
            }
        }
    },
    "not(T-35.2=1)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "T-35.2",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "67809f9d-5bc0-59bb-92cc-152028b5dfd8": false,
                        "2d8f0fc4-245d-51f9-860d-df2b9ec99a3c": false,
                        "afa0086f-aed1-569d-8b56-6273d9e8fcbb": false,
                        "f25bcc46-8c38-5275-9be9-3f4ba80df5dd": false,
                        "8818f5c3-ca8b-5e1a-8f4a-5738238d28fc": false,
                        "97706d03-4cae-5a94-87d6-7df3a9db2e41": true
                    }
                }
            }
        }
    },
    "not(T-35.3=1)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "T-35.3",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "f174a9f1-b064-5cf2-a3f7-e7adfc80cee7": false,
                        "39788390-fbb7-5cc6-b6a3-a65d420d91f0": false,
                        "ee7318eb-c751-57b3-9a6e-38248f55fbaa": false,
                        "5aed6bad-c53b-59e3-9fa8-47fbf9f32a38": false,
                        "ac7cd80b-8d42-562e-b921-f1fe8e38cbad": false,
                        "d856d770-6f7c-57fd-a118-53ffb679faf4": true
                    }
                }
            }
        }
    }
};
exports.tests = {
    "trm_re_ptsd": [
        {
            "name": "Tech team adjusted screen from TRUE, Censeo vPrevious user: Z3RPNEO aspect: trm_re_ptsd",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-1": {
                    "questionId": "T-1",
                    "chosenOptionIds": [
                        "8d925beb-6b03-56cb-8348-c4952f097b9f",
                        "20e21d03-7bd9-504e-8726-d2e3e2fc8ef9",
                        "966cf398-e957-5889-b1a4-cffa64703207",
                        "8ec3c46b-8732-5ae9-8c3d-1846b1d05562",
                        "8436c041-416d-5abf-8987-658006b97aef"
                    ]
                },
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "cd79664e-d646-54f6-8ce2-1f236d79730f"
                    ]
                }
            }
        },
        {
            "name": "screened user: NEL6L3V aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-1": {
                    "questionId": "T-1",
                    "chosenOptionIds": [
                        "8d925beb-6b03-56cb-8348-c4952f097b9f",
                        "966cf398-e957-5889-b1a4-cffa64703207"
                    ]
                },
                "T-34.1": {
                    "questionId": "T-34.1",
                    "chosenOptionIds": [
                        "54067bec-c99e-5b1b-a364-f59f74d62208"
                    ]
                },
                "T-34.10": {
                    "questionId": "T-34.10",
                    "chosenOptionIds": [
                        "b68a8081-73da-5df9-afe7-a19832e7ecf1"
                    ]
                },
                "T-34.11": {
                    "questionId": "T-34.11",
                    "chosenOptionIds": [
                        "e1649842-01ce-5bed-aae0-eda574ace3d2"
                    ]
                },
                "T-34.12": {
                    "questionId": "T-34.12",
                    "chosenOptionIds": [
                        "f8239bbb-1e37-5974-bca5-b6ba0daea1fe"
                    ]
                },
                "T-34.13a": {
                    "questionId": "T-34.13a",
                    "chosenOptionIds": [
                        "dea28bb5-7e53-5032-a4db-a76aca90041a"
                    ]
                },
                "T-34.2": {
                    "questionId": "T-34.2",
                    "chosenOptionIds": [
                        "5df58c8b-2b22-57fd-a9e1-f6e61c7ddb61"
                    ]
                },
                "T-34.3": {
                    "questionId": "T-34.3",
                    "chosenOptionIds": [
                        "b7db11e3-885c-51fc-843f-610d8d00db10"
                    ]
                },
                "T-34.4": {
                    "questionId": "T-34.4",
                    "chosenOptionIds": [
                        "aa992ff2-b894-50ad-87b9-1863b8c261b3"
                    ]
                },
                "T-34.5": {
                    "questionId": "T-34.5",
                    "chosenOptionIds": [
                        "04f50633-67b2-5a22-93dd-5faf1c805400"
                    ]
                },
                "T-34.6": {
                    "questionId": "T-34.6",
                    "chosenOptionIds": [
                        "5f45ff1f-a46f-55ed-9d4d-837f817e371d"
                    ]
                },
                "T-34.7": {
                    "questionId": "T-34.7",
                    "chosenOptionIds": [
                        "465fe7ff-e2a2-5390-a835-00807ffc1a0c"
                    ]
                },
                "T-34.8": {
                    "questionId": "T-34.8",
                    "chosenOptionIds": [
                        "01b5d2af-e4c0-5841-934f-2b54d10e0dc5"
                    ]
                },
                "T-34.9": {
                    "questionId": "T-34.9",
                    "chosenOptionIds": [
                        "1478fd0b-fd2f-5293-89b6-aa0cb1307a51"
                    ]
                },
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "4fb618ab-1a98-5a8b-8a64-000b45d953f9"
                    ]
                }
            }
        },
        {
            "name": "screened user: K39ZL3L aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "ab657537-a14b-5d23-bcc5-8a10faac75b2"
                    ]
                }
            }
        },
        {
            "name": "screened user: 6PE670L aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "4fb618ab-1a98-5a8b-8a64-000b45d953f9"
                    ]
                }
            }
        },
        {
            "name": "screened user: 50MRME6 aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "4fb618ab-1a98-5a8b-8a64-000b45d953f9"
                    ]
                }
            }
        },
        {
            "name": "screened user: O3PMG3W aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "d3770e6a-b463-59c3-9904-5893bd7ead01"
                    ]
                }
            }
        },
        {
            "name": "screened user: Y32D838 aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-1": {
                    "questionId": "T-1",
                    "chosenOptionIds": [
                        "1e6dfe79-7a7f-56cd-b00d-e51c7741148d"
                    ]
                },
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "ab90ebb6-b526-5d58-97dd-181c2ddaefdf"
                    ]
                }
            }
        },
        {
            "name": "screened user: XE7OXEL aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "d3770e6a-b463-59c3-9904-5893bd7ead01"
                    ]
                }
            }
        },
        {
            "name": "screened user: 63YX60R aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "ab657537-a14b-5d23-bcc5-8a10faac75b2"
                    ]
                }
            }
        },
        {
            "name": "screened user: 10QV8EP aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "d3770e6a-b463-59c3-9904-5893bd7ead01"
                    ]
                }
            }
        },
        {
            "name": "screened user: 6EDLX3M aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "4fb618ab-1a98-5a8b-8a64-000b45d953f9"
                    ]
                }
            }
        },
        {
            "name": "screened user: O0KG5E1 aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-1": {
                    "questionId": "T-1",
                    "chosenOptionIds": [
                        "8d925beb-6b03-56cb-8348-c4952f097b9f"
                    ]
                },
                "T-35.1": {
                    "questionId": "T-35.1",
                    "chosenOptionIds": [
                        "8f245622-a6a2-5b80-9d90-88282267bfcf"
                    ]
                },
                "T-35.2": {
                    "questionId": "T-35.2",
                    "chosenOptionIds": [
                        "f25bcc46-8c38-5275-9be9-3f4ba80df5dd"
                    ]
                },
                "T-35.3": {
                    "questionId": "T-35.3",
                    "chosenOptionIds": [
                        "ee7318eb-c751-57b3-9a6e-38248f55fbaa"
                    ]
                },
                "T-35.4": {
                    "questionId": "T-35.4",
                    "chosenOptionIds": [
                        "c5118e92-1bdf-56c8-b12f-90e686eba051"
                    ]
                },
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "f8e62fbd-28e3-5527-94f5-9590869545a3"
                    ]
                }
            }
        },
        {
            "name": "screened user: 6ED9M0M aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "ab657537-a14b-5d23-bcc5-8a10faac75b2"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z3R2NEO aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-1": {
                    "questionId": "T-1",
                    "chosenOptionIds": [
                        "6137d2c9-5a9b-5ec3-a2ae-18d6ad8c5ba0"
                    ]
                },
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "f8e62fbd-28e3-5527-94f5-9590869545a3"
                    ]
                }
            }
        },
        {
            "name": "screened user: NELRPEV aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "4fb618ab-1a98-5a8b-8a64-000b45d953f9"
                    ]
                }
            }
        },
        {
            "name": "screened user: 73ZQN3R aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "96789d75-b879-5f46-8963-b49a0d5a36c7"
                    ]
                }
            }
        },
        {
            "name": "screened user: REO9232 aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "e3912e04-a5d3-5bf1-869a-a651db93c0f7"
                    ]
                }
            }
        },
        {
            "name": "screened user: O0KZ701 aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "e3912e04-a5d3-5bf1-869a-a651db93c0f7"
                    ]
                }
            }
        },
        {
            "name": "screened user: ME8G2EW aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-1": {
                    "questionId": "T-1",
                    "chosenOptionIds": [
                        "2f912715-981e-56cd-9769-6fe50451871e",
                        "20e21d03-7bd9-504e-8726-d2e3e2fc8ef9",
                        "b32206e4-0455-500a-9564-b9d1b1ddb853",
                        "8ec3c46b-8732-5ae9-8c3d-1846b1d05562",
                        "eaeabf95-3b89-5b38-8233-c94102db83a4",
                        "af0c6a89-6944-5fae-be10-5243fe9bc934",
                        "1e6dfe79-7a7f-56cd-b00d-e51c7741148d"
                    ]
                },
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "ab90ebb6-b526-5d58-97dd-181c2ddaefdf"
                    ]
                }
            }
        },
        {
            "name": "screened user: PE6LM0L aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "4fb618ab-1a98-5a8b-8a64-000b45d953f9"
                    ]
                }
            }
        },
        {
            "name": "screened user: K39ML0L aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "4fb618ab-1a98-5a8b-8a64-000b45d953f9"
                    ]
                }
            }
        },
        {
            "name": "screened user: XE7VX0L aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-1": {
                    "questionId": "T-1",
                    "chosenOptionIds": [
                        "8ec3c46b-8732-5ae9-8c3d-1846b1d05562",
                        "2f912715-981e-56cd-9769-6fe50451871e"
                    ]
                },
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "cd79664e-d646-54f6-8ce2-1f236d79730f"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z3RD4EO aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-1": {
                    "questionId": "T-1",
                    "chosenOptionIds": [
                        "20e21d03-7bd9-504e-8726-d2e3e2fc8ef9",
                        "b32206e4-0455-500a-9564-b9d1b1ddb853",
                        "8ec3c46b-8732-5ae9-8c3d-1846b1d05562",
                        "646c6678-d42f-51d3-bf03-0e1ae066d88c",
                        "afa84b91-8bb8-582a-97d1-8a13ec771151"
                    ]
                },
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "cd79664e-d646-54f6-8ce2-1f236d79730f"
                    ]
                }
            }
        },
        {
            "name": "screened user: 4EGQQ0P aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-1": {
                    "questionId": "T-1",
                    "chosenOptionIds": [
                        "af0c6a89-6944-5fae-be10-5243fe9bc934"
                    ]
                },
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "f8e62fbd-28e3-5527-94f5-9590869545a3"
                    ]
                }
            }
        },
        {
            "name": "screened user: 7EW2K38 aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-1": {
                    "questionId": "T-1",
                    "chosenOptionIds": [
                        "b32206e4-0455-500a-9564-b9d1b1ddb853",
                        "8ec3c46b-8732-5ae9-8c3d-1846b1d05562",
                        "38b6ec1a-a824-5fd9-bf53-faa9fe2ee355"
                    ]
                },
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "ab90ebb6-b526-5d58-97dd-181c2ddaefdf"
                    ]
                }
            }
        },
        {
            "name": "screened user: 63YR40R aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "96789d75-b879-5f46-8963-b49a0d5a36c7"
                    ]
                }
            }
        },
        {
            "name": "screened user: Y329ME8 aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "96789d75-b879-5f46-8963-b49a0d5a36c7"
                    ]
                }
            }
        },
        {
            "name": "screened user: J0VZ63L aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "96789d75-b879-5f46-8963-b49a0d5a36c7"
                    ]
                }
            }
        },
        {
            "name": "screened user: 73ZJN0R aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "96789d75-b879-5f46-8963-b49a0d5a36c7"
                    ]
                }
            }
        },
        {
            "name": "Tech team adjusted screen from TRUE, Censeo vPrevious user: J0VRYEL aspect: trm_re_ptsd",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-1": {
                    "questionId": "T-1",
                    "chosenOptionIds": [
                        "20e21d03-7bd9-504e-8726-d2e3e2fc8ef9",
                        "6137d2c9-5a9b-5ec3-a2ae-18d6ad8c5ba0",
                        "b32206e4-0455-500a-9564-b9d1b1ddb853",
                        "966cf398-e957-5889-b1a4-cffa64703207",
                        "8ec3c46b-8732-5ae9-8c3d-1846b1d05562",
                        "38b6ec1a-a824-5fd9-bf53-faa9fe2ee355",
                        "eaeabf95-3b89-5b38-8233-c94102db83a4"
                    ]
                },
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "f8e62fbd-28e3-5527-94f5-9590869545a3"
                    ]
                }
            }
        },
        {
            "name": "screened user: 73ZNN3R aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-1": {
                    "questionId": "T-1",
                    "chosenOptionIds": [
                        "2f912715-981e-56cd-9769-6fe50451871e",
                        "d8fafb20-a4f7-5702-82a4-2bf31ee2b523",
                        "20e21d03-7bd9-504e-8726-d2e3e2fc8ef9",
                        "6137d2c9-5a9b-5ec3-a2ae-18d6ad8c5ba0",
                        "b32206e4-0455-500a-9564-b9d1b1ddb853",
                        "966cf398-e957-5889-b1a4-cffa64703207",
                        "8ec3c46b-8732-5ae9-8c3d-1846b1d05562",
                        "38b6ec1a-a824-5fd9-bf53-faa9fe2ee355",
                        "8436c041-416d-5abf-8987-658006b97aef",
                        "afa84b91-8bb8-582a-97d1-8a13ec771151",
                        "a64cebc6-da46-5d9b-bef5-379159acdadd",
                        "088cfa77-013c-5c1d-8d66-b8766ed0535c"
                    ]
                },
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "ab90ebb6-b526-5d58-97dd-181c2ddaefdf"
                    ]
                }
            }
        },
        {
            "name": "screened user: ME8D2EW aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "d3770e6a-b463-59c3-9904-5893bd7ead01"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0NN50Q aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-1": {
                    "questionId": "T-1",
                    "chosenOptionIds": [
                        "6137d2c9-5a9b-5ec3-a2ae-18d6ad8c5ba0",
                        "e24f82b7-a712-5954-b5fb-421e2119db45",
                        "966cf398-e957-5889-b1a4-cffa64703207",
                        "eaeabf95-3b89-5b38-8233-c94102db83a4",
                        "af0c6a89-6944-5fae-be10-5243fe9bc934"
                    ]
                },
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "f8e62fbd-28e3-5527-94f5-9590869545a3"
                    ]
                }
            }
        },
        {
            "name": "Tech team adjusted screen from TRUE, Censeo vPrevious user: 73Z220R aspect: trm_re_ptsd",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-1": {
                    "questionId": "T-1",
                    "chosenOptionIds": [
                        "646c6678-d42f-51d3-bf03-0e1ae066d88c",
                        "7f175744-2ae6-569f-a689-54802eeafbbb",
                        "20e21d03-7bd9-504e-8726-d2e3e2fc8ef9",
                        "6137d2c9-5a9b-5ec3-a2ae-18d6ad8c5ba0",
                        "b32206e4-0455-500a-9564-b9d1b1ddb853",
                        "966cf398-e957-5889-b1a4-cffa64703207",
                        "8ec3c46b-8732-5ae9-8c3d-1846b1d05562",
                        "e24f82b7-a712-5954-b5fb-421e2119db45",
                        "38b6ec1a-a824-5fd9-bf53-faa9fe2ee355",
                        "afa84b91-8bb8-582a-97d1-8a13ec771151",
                        "eaeabf95-3b89-5b38-8233-c94102db83a4"
                    ]
                },
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "cd79664e-d646-54f6-8ce2-1f236d79730f"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0N4J3Q aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "96789d75-b879-5f46-8963-b49a0d5a36c7"
                    ]
                }
            }
        },
        {
            "name": "screened user: 10QKZ0P aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "96789d75-b879-5f46-8963-b49a0d5a36c7"
                    ]
                }
            }
        },
        {
            "name": "screened user: XE7ZR3L aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "96789d75-b879-5f46-8963-b49a0d5a36c7"
                    ]
                }
            }
        },
        {
            "name": "Tech team adjusted screen from TRUE, Censeo vPrevious user: N0N5PEQ aspect: trm_re_ptsd",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-1": {
                    "questionId": "T-1",
                    "chosenOptionIds": [
                        "2f912715-981e-56cd-9769-6fe50451871e",
                        "d8fafb20-a4f7-5702-82a4-2bf31ee2b523",
                        "8436c041-416d-5abf-8987-658006b97aef"
                    ]
                },
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "f8e62fbd-28e3-5527-94f5-9590869545a3"
                    ]
                }
            }
        },
        {
            "name": "screened user: 23XKV0N aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-1": {
                    "questionId": "T-1",
                    "chosenOptionIds": [
                        "2f912715-981e-56cd-9769-6fe50451871e"
                    ]
                },
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "ab90ebb6-b526-5d58-97dd-181c2ddaefdf"
                    ]
                }
            }
        },
        {
            "name": "screened user: ME8PLEW aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-1": {
                    "questionId": "T-1",
                    "chosenOptionIds": [
                        "2f912715-981e-56cd-9769-6fe50451871e",
                        "b9a35392-1bfe-5319-bdb2-34a68f9dff20",
                        "20e21d03-7bd9-504e-8726-d2e3e2fc8ef9",
                        "b32206e4-0455-500a-9564-b9d1b1ddb853",
                        "966cf398-e957-5889-b1a4-cffa64703207",
                        "8ec3c46b-8732-5ae9-8c3d-1846b1d05562",
                        "8436c041-416d-5abf-8987-658006b97aef"
                    ]
                },
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "f8e62fbd-28e3-5527-94f5-9590869545a3"
                    ]
                }
            }
        },
        {
            "name": "screened user: K39V10L aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "4fb618ab-1a98-5a8b-8a64-000b45d953f9"
                    ]
                }
            }
        },
        {
            "name": "screened user: O0K1931 aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "96789d75-b879-5f46-8963-b49a0d5a36c7"
                    ]
                }
            }
        },
        {
            "name": "screened user: J3J1D0Y aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "4fb618ab-1a98-5a8b-8a64-000b45d953f9"
                    ]
                }
            }
        },
        {
            "name": "screened user: 10QJ8EP aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-1": {
                    "questionId": "T-1",
                    "chosenOptionIds": [
                        "2f912715-981e-56cd-9769-6fe50451871e",
                        "8d925beb-6b03-56cb-8348-c4952f097b9f",
                        "646c6678-d42f-51d3-bf03-0e1ae066d88c",
                        "38b6ec1a-a824-5fd9-bf53-faa9fe2ee355",
                        "8436c041-416d-5abf-8987-658006b97aef"
                    ]
                },
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "f8e62fbd-28e3-5527-94f5-9590869545a3"
                    ]
                }
            }
        },
        {
            "name": "screened user: 73ZL2ER aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "4fb618ab-1a98-5a8b-8a64-000b45d953f9"
                    ]
                }
            }
        },
        {
            "name": "screened user: O3P66EW aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-1": {
                    "questionId": "T-1",
                    "chosenOptionIds": [
                        "2f912715-981e-56cd-9769-6fe50451871e",
                        "1e6dfe79-7a7f-56cd-b00d-e51c7741148d",
                        "646c6678-d42f-51d3-bf03-0e1ae066d88c",
                        "af0c6a89-6944-5fae-be10-5243fe9bc934",
                        "7f175744-2ae6-569f-a689-54802eeafbbb",
                        "d8fafb20-a4f7-5702-82a4-2bf31ee2b523",
                        "6137d2c9-5a9b-5ec3-a2ae-18d6ad8c5ba0",
                        "966cf398-e957-5889-b1a4-cffa64703207",
                        "be940464-04bc-5c41-8742-c8094ae34ec5",
                        "eaeabf95-3b89-5b38-8233-c94102db83a4"
                    ]
                },
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "f8e62fbd-28e3-5527-94f5-9590869545a3"
                    ]
                }
            }
        },
        {
            "name": "screened user: O3PGG3W aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "d3770e6a-b463-59c3-9904-5893bd7ead01"
                    ]
                }
            }
        },
        {
            "name": "screened user: Y32OM08 aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-1": {
                    "questionId": "T-1",
                    "chosenOptionIds": [
                        "20e21d03-7bd9-504e-8726-d2e3e2fc8ef9",
                        "b32206e4-0455-500a-9564-b9d1b1ddb853",
                        "a64cebc6-da46-5d9b-bef5-379159acdadd"
                    ]
                },
                "T-34.1": {
                    "questionId": "T-34.1",
                    "chosenOptionIds": [
                        "54067bec-c99e-5b1b-a364-f59f74d62208"
                    ]
                },
                "T-34.10": {
                    "questionId": "T-34.10",
                    "chosenOptionIds": [
                        "e3007f71-71f0-5447-9503-dd86a7329d1f"
                    ]
                },
                "T-34.11": {
                    "questionId": "T-34.11",
                    "chosenOptionIds": [
                        "e1649842-01ce-5bed-aae0-eda574ace3d2"
                    ]
                },
                "T-34.12": {
                    "questionId": "T-34.12",
                    "chosenOptionIds": [
                        "f8239bbb-1e37-5974-bca5-b6ba0daea1fe"
                    ]
                },
                "T-34.13a": {
                    "questionId": "T-34.13a",
                    "chosenOptionIds": [
                        "dea28bb5-7e53-5032-a4db-a76aca90041a"
                    ]
                },
                "T-34.2": {
                    "questionId": "T-34.2",
                    "chosenOptionIds": [
                        "5df58c8b-2b22-57fd-a9e1-f6e61c7ddb61"
                    ]
                },
                "T-34.3": {
                    "questionId": "T-34.3",
                    "chosenOptionIds": [
                        "b7db11e3-885c-51fc-843f-610d8d00db10"
                    ]
                },
                "T-34.4": {
                    "questionId": "T-34.4",
                    "chosenOptionIds": [
                        "aa992ff2-b894-50ad-87b9-1863b8c261b3"
                    ]
                },
                "T-34.5": {
                    "questionId": "T-34.5",
                    "chosenOptionIds": [
                        "04f50633-67b2-5a22-93dd-5faf1c805400"
                    ]
                },
                "T-34.6": {
                    "questionId": "T-34.6",
                    "chosenOptionIds": [
                        "10bf3060-43e8-52e2-9382-9427d00fd16a"
                    ]
                },
                "T-34.7": {
                    "questionId": "T-34.7",
                    "chosenOptionIds": [
                        "465fe7ff-e2a2-5390-a835-00807ffc1a0c"
                    ]
                },
                "T-34.8": {
                    "questionId": "T-34.8",
                    "chosenOptionIds": [
                        "01b5d2af-e4c0-5841-934f-2b54d10e0dc5"
                    ]
                },
                "T-34.9": {
                    "questionId": "T-34.9",
                    "chosenOptionIds": [
                        "1478fd0b-fd2f-5293-89b6-aa0cb1307a51"
                    ]
                },
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "ab657537-a14b-5d23-bcc5-8a10faac75b2"
                    ]
                }
            }
        },
        {
            "name": "screened user: ME84L3W aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "96789d75-b879-5f46-8963-b49a0d5a36c7"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0NJ5EQ aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "96789d75-b879-5f46-8963-b49a0d5a36c7"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0NO53Q aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "d3770e6a-b463-59c3-9904-5893bd7ead01"
                    ]
                }
            }
        },
        {
            "name": "screened user: K392M0L aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-1": {
                    "questionId": "T-1",
                    "chosenOptionIds": [
                        "8d925beb-6b03-56cb-8348-c4952f097b9f",
                        "a64cebc6-da46-5d9b-bef5-379159acdadd"
                    ]
                },
                "T-34.1": {
                    "questionId": "T-34.1",
                    "chosenOptionIds": [
                        "d4f5bbff-1a99-5091-b7cb-e485a73974f4"
                    ]
                },
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "f8e62fbd-28e3-5527-94f5-9590869545a3"
                    ]
                }
            }
        },
        {
            "name": "screened user: 6EDJMEM aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-1": {
                    "questionId": "T-1",
                    "chosenOptionIds": [
                        "2f912715-981e-56cd-9769-6fe50451871e"
                    ]
                },
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "cd79664e-d646-54f6-8ce2-1f236d79730f"
                    ]
                }
            }
        },
        {
            "name": "screened user: 10Q6R0P aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "96789d75-b879-5f46-8963-b49a0d5a36c7"
                    ]
                }
            }
        },
        {
            "name": "screened user: O0KPDE1 aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-1": {
                    "questionId": "T-1",
                    "chosenOptionIds": [
                        "2f912715-981e-56cd-9769-6fe50451871e",
                        "0a40d484-e62c-53ec-811c-a195a979b58a",
                        "8d925beb-6b03-56cb-8348-c4952f097b9f",
                        "20e21d03-7bd9-504e-8726-d2e3e2fc8ef9",
                        "8436c041-416d-5abf-8987-658006b97aef",
                        "afa84b91-8bb8-582a-97d1-8a13ec771151"
                    ]
                },
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "ab90ebb6-b526-5d58-97dd-181c2ddaefdf"
                    ]
                }
            }
        },
        {
            "name": "screened user: 63YPO3R aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "4fb618ab-1a98-5a8b-8a64-000b45d953f9"
                    ]
                }
            }
        },
        {
            "name": "screened user: 23X6V0N aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-1": {
                    "questionId": "T-1",
                    "chosenOptionIds": [
                        "2f912715-981e-56cd-9769-6fe50451871e",
                        "646c6678-d42f-51d3-bf03-0e1ae066d88c",
                        "7f175744-2ae6-569f-a689-54802eeafbbb",
                        "38b6ec1a-a824-5fd9-bf53-faa9fe2ee355"
                    ]
                },
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "ab90ebb6-b526-5d58-97dd-181c2ddaefdf"
                    ]
                }
            }
        },
        {
            "name": "screened user: 50MQK36 aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "d3770e6a-b463-59c3-9904-5893bd7ead01"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0N2PEQ aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-1": {
                    "questionId": "T-1",
                    "chosenOptionIds": [
                        "2f912715-981e-56cd-9769-6fe50451871e",
                        "8d925beb-6b03-56cb-8348-c4952f097b9f",
                        "7f175744-2ae6-569f-a689-54802eeafbbb",
                        "afa84b91-8bb8-582a-97d1-8a13ec771151",
                        "eaeabf95-3b89-5b38-8233-c94102db83a4",
                        "a64cebc6-da46-5d9b-bef5-379159acdadd",
                        "20e21d03-7bd9-504e-8726-d2e3e2fc8ef9"
                    ]
                },
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "ab90ebb6-b526-5d58-97dd-181c2ddaefdf"
                    ]
                }
            }
        },
        {
            "name": "screened user: J3JO40Y aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "4fb618ab-1a98-5a8b-8a64-000b45d953f9"
                    ]
                }
            }
        },
        {
            "name": "screened user: 4EGGGEP aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "96789d75-b879-5f46-8963-b49a0d5a36c7"
                    ]
                }
            }
        },
        {
            "name": "screened user: 2E1JL0D aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "4fb618ab-1a98-5a8b-8a64-000b45d953f9"
                    ]
                }
            }
        },
        {
            "name": "screened user: R04JV34 aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "ab657537-a14b-5d23-bcc5-8a10faac75b2"
                    ]
                }
            }
        },
        {
            "name": "screened user: 7EWGK38 aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "96789d75-b879-5f46-8963-b49a0d5a36c7"
                    ]
                }
            }
        },
        {
            "name": "screened user: 23X4V3N aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "96789d75-b879-5f46-8963-b49a0d5a36c7"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z35X10O aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-1": {
                    "questionId": "T-1",
                    "chosenOptionIds": [
                        "2f912715-981e-56cd-9769-6fe50451871e"
                    ]
                },
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "cd79664e-d646-54f6-8ce2-1f236d79730f"
                    ]
                }
            }
        },
        {
            "name": "screened user: O0K9D01 aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "ab657537-a14b-5d23-bcc5-8a10faac75b2"
                    ]
                }
            }
        },
        {
            "name": "screened user: 23XP2EN aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-1": {
                    "questionId": "T-1",
                    "chosenOptionIds": [
                        "6137d2c9-5a9b-5ec3-a2ae-18d6ad8c5ba0",
                        "966cf398-e957-5889-b1a4-cffa64703207",
                        "38b6ec1a-a824-5fd9-bf53-faa9fe2ee355",
                        "b32206e4-0455-500a-9564-b9d1b1ddb853"
                    ]
                },
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "f8e62fbd-28e3-5527-94f5-9590869545a3"
                    ]
                }
            }
        },
        {
            "name": "screened user: 4EGLK3P aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "ab657537-a14b-5d23-bcc5-8a10faac75b2"
                    ]
                }
            }
        },
        {
            "name": "screened user: NEL9J3V aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "96789d75-b879-5f46-8963-b49a0d5a36c7"
                    ]
                }
            }
        },
        {
            "name": "screened user: PE694EL aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-1": {
                    "questionId": "T-1",
                    "chosenOptionIds": [
                        "2f912715-981e-56cd-9769-6fe50451871e",
                        "8436c041-416d-5abf-8987-658006b97aef"
                    ]
                },
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "f8e62fbd-28e3-5527-94f5-9590869545a3"
                    ]
                }
            }
        },
        {
            "name": "screened user: K399M3L aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-1": {
                    "questionId": "T-1",
                    "chosenOptionIds": [
                        "eaeabf95-3b89-5b38-8233-c94102db83a4"
                    ]
                },
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "cd79664e-d646-54f6-8ce2-1f236d79730f"
                    ]
                }
            }
        },
        {
            "name": "screened user: 50MGW06 aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-1": {
                    "questionId": "T-1",
                    "chosenOptionIds": [
                        "b9a35392-1bfe-5319-bdb2-34a68f9dff20",
                        "b32206e4-0455-500a-9564-b9d1b1ddb853",
                        "afa84b91-8bb8-582a-97d1-8a13ec771151"
                    ]
                },
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "ab90ebb6-b526-5d58-97dd-181c2ddaefdf"
                    ]
                }
            }
        },
        {
            "name": "screened user: J0VGM0L aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "4fb618ab-1a98-5a8b-8a64-000b45d953f9"
                    ]
                }
            }
        },
        {
            "name": "screened user: Y32QME8 aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "96789d75-b879-5f46-8963-b49a0d5a36c7"
                    ]
                }
            }
        },
        {
            "name": "Tech team adjusted screen from TRUE, Censeo vPrevious user: 7EWZKE8 aspect: trm_re_ptsd",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-1": {
                    "questionId": "T-1",
                    "chosenOptionIds": [
                        "8d925beb-6b03-56cb-8348-c4952f097b9f",
                        "088cfa77-013c-5c1d-8d66-b8766ed0535c",
                        "966cf398-e957-5889-b1a4-cffa64703207",
                        "8436c041-416d-5abf-8987-658006b97aef",
                        "eaeabf95-3b89-5b38-8233-c94102db83a4",
                        "38b6ec1a-a824-5fd9-bf53-faa9fe2ee355"
                    ]
                },
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "cd79664e-d646-54f6-8ce2-1f236d79730f"
                    ]
                }
            }
        },
        {
            "name": "screened user: PE6NMEL aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "4fb618ab-1a98-5a8b-8a64-000b45d953f9"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z356NEO aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "4fb618ab-1a98-5a8b-8a64-000b45d953f9"
                    ]
                }
            }
        },
        {
            "name": "screened user: 23XV2EN aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "96789d75-b879-5f46-8963-b49a0d5a36c7"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z3R443O aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "ab657537-a14b-5d23-bcc5-8a10faac75b2"
                    ]
                }
            }
        },
        {
            "name": "screened user: 6EDW60M aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-1": {
                    "questionId": "T-1",
                    "chosenOptionIds": [
                        "088cfa77-013c-5c1d-8d66-b8766ed0535c",
                        "20e21d03-7bd9-504e-8726-d2e3e2fc8ef9",
                        "6137d2c9-5a9b-5ec3-a2ae-18d6ad8c5ba0",
                        "b32206e4-0455-500a-9564-b9d1b1ddb853",
                        "966cf398-e957-5889-b1a4-cffa64703207",
                        "e24f82b7-a712-5954-b5fb-421e2119db45",
                        "8ec3c46b-8732-5ae9-8c3d-1846b1d05562",
                        "38b6ec1a-a824-5fd9-bf53-faa9fe2ee355",
                        "afa84b91-8bb8-582a-97d1-8a13ec771151",
                        "eaeabf95-3b89-5b38-8233-c94102db83a4"
                    ]
                },
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "cd79664e-d646-54f6-8ce2-1f236d79730f"
                    ]
                }
            }
        },
        {
            "name": "Tech team adjusted screen from TRUE, Censeo vPrevious user: J3JV43Y aspect: trm_re_ptsd",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-1": {
                    "questionId": "T-1",
                    "chosenOptionIds": [
                        "8d925beb-6b03-56cb-8348-c4952f097b9f",
                        "646c6678-d42f-51d3-bf03-0e1ae066d88c",
                        "af0c6a89-6944-5fae-be10-5243fe9bc934"
                    ]
                },
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "cd79664e-d646-54f6-8ce2-1f236d79730f"
                    ]
                }
            }
        },
        {
            "name": "Tech team adjusted screen from TRUE, Censeo vPrevious user: PE6GM3L aspect: trm_re_ptsd",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-1": {
                    "questionId": "T-1",
                    "chosenOptionIds": [
                        "2f912715-981e-56cd-9769-6fe50451871e",
                        "1e6dfe79-7a7f-56cd-b00d-e51c7741148d",
                        "8d925beb-6b03-56cb-8348-c4952f097b9f",
                        "646c6678-d42f-51d3-bf03-0e1ae066d88c",
                        "af0c6a89-6944-5fae-be10-5243fe9bc934",
                        "088cfa77-013c-5c1d-8d66-b8766ed0535c",
                        "d8fafb20-a4f7-5702-82a4-2bf31ee2b523",
                        "2717e852-867d-5e13-8293-2fd8ccb66dd5",
                        "99d1f7f9-ab68-56f1-b976-6c314f1b528a",
                        "20e21d03-7bd9-504e-8726-d2e3e2fc8ef9",
                        "6137d2c9-5a9b-5ec3-a2ae-18d6ad8c5ba0",
                        "b32206e4-0455-500a-9564-b9d1b1ddb853",
                        "966cf398-e957-5889-b1a4-cffa64703207",
                        "e24f82b7-a712-5954-b5fb-421e2119db45",
                        "38b6ec1a-a824-5fd9-bf53-faa9fe2ee355",
                        "8436c041-416d-5abf-8987-658006b97aef",
                        "afa84b91-8bb8-582a-97d1-8a13ec771151",
                        "eaeabf95-3b89-5b38-8233-c94102db83a4",
                        "a64cebc6-da46-5d9b-bef5-379159acdadd"
                    ]
                },
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "cd79664e-d646-54f6-8ce2-1f236d79730f"
                    ]
                }
            }
        },
        {
            "name": "screened user: 63Y843R aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-1": {
                    "questionId": "T-1",
                    "chosenOptionIds": [
                        "1e6dfe79-7a7f-56cd-b00d-e51c7741148d"
                    ]
                },
                "T-35.1": {
                    "questionId": "T-35.1",
                    "chosenOptionIds": [
                        "a8521592-054a-5b2b-98e1-f8c0b3ea00a8"
                    ]
                },
                "T-35.2": {
                    "questionId": "T-35.2",
                    "chosenOptionIds": [
                        "f25bcc46-8c38-5275-9be9-3f4ba80df5dd"
                    ]
                },
                "T-35.3": {
                    "questionId": "T-35.3",
                    "chosenOptionIds": [
                        "39788390-fbb7-5cc6-b6a3-a65d420d91f0"
                    ]
                },
                "T-35.4": {
                    "questionId": "T-35.4",
                    "chosenOptionIds": [
                        "a67a8dc6-9238-5c10-aa20-b7a0e16f9c4a"
                    ]
                },
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "ab657537-a14b-5d23-bcc5-8a10faac75b2"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0NQJ0Q aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "d3770e6a-b463-59c3-9904-5893bd7ead01"
                    ]
                }
            }
        },
        {
            "name": "screened user: XE7DM0L aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-1": {
                    "questionId": "T-1",
                    "chosenOptionIds": [
                        "8d925beb-6b03-56cb-8348-c4952f097b9f",
                        "646c6678-d42f-51d3-bf03-0e1ae066d88c",
                        "088cfa77-013c-5c1d-8d66-b8766ed0535c",
                        "7f175744-2ae6-569f-a689-54802eeafbbb",
                        "d8fafb20-a4f7-5702-82a4-2bf31ee2b523",
                        "966cf398-e957-5889-b1a4-cffa64703207",
                        "e24f82b7-a712-5954-b5fb-421e2119db45",
                        "8ec3c46b-8732-5ae9-8c3d-1846b1d05562",
                        "8436c041-416d-5abf-8987-658006b97aef",
                        "afa84b91-8bb8-582a-97d1-8a13ec771151",
                        "eaeabf95-3b89-5b38-8233-c94102db83a4",
                        "a64cebc6-da46-5d9b-bef5-379159acdadd",
                        "2f912715-981e-56cd-9769-6fe50451871e",
                        "af0c6a89-6944-5fae-be10-5243fe9bc934"
                    ]
                },
                "T-34.1": {
                    "questionId": "T-34.1",
                    "chosenOptionIds": [
                        "6c280560-c4a9-5455-b732-15e1b1c292b6"
                    ]
                },
                "T-34.10": {
                    "questionId": "T-34.10",
                    "chosenOptionIds": [
                        "21593b50-f48c-5400-8caf-d3b67a445453"
                    ]
                },
                "T-34.11": {
                    "questionId": "T-34.11",
                    "chosenOptionIds": [
                        "ff7ed352-5958-5063-ac3c-8f00d3dde1d1"
                    ]
                },
                "T-34.12": {
                    "questionId": "T-34.12",
                    "chosenOptionIds": [
                        "6ae9c2cf-9e52-5909-bd05-ac047a11aed0"
                    ]
                },
                "T-34.13": {
                    "questionId": "T-34.13",
                    "chosenOptionIds": [
                        "548224b5-3e26-5fc1-8797-11d1f01e6919",
                        "0bc1e06b-52bf-59fd-95c9-5c5ae905d59c",
                        "7e1f026a-e129-5fb8-a8a3-4eb401c4a392",
                        "b4532aa6-0bcc-5f11-af1e-d7cb1565da1d",
                        "edf7cf90-8669-5c78-af40-3d5b6217c483",
                        "6b0a5e9f-3607-584f-99ec-6b0178992c84",
                        "aaf4ead8-1b3e-53bb-9758-560d1046b30a",
                        "70ab1917-9ef6-5273-a79a-38f34a97fd4d"
                    ]
                },
                "T-34.13a": {
                    "questionId": "T-34.13a",
                    "chosenOptionIds": [
                        "04711b6d-71e7-58ea-a595-a9092bd42bde"
                    ]
                },
                "T-34.2": {
                    "questionId": "T-34.2",
                    "chosenOptionIds": [
                        "5ba6670e-35ef-5545-8135-834feda201d5"
                    ]
                },
                "T-34.3": {
                    "questionId": "T-34.3",
                    "chosenOptionIds": [
                        "b7db11e3-885c-51fc-843f-610d8d00db10"
                    ]
                },
                "T-34.4": {
                    "questionId": "T-34.4",
                    "chosenOptionIds": [
                        "bf6c3ef5-62fa-5172-9a8e-db0dae8fb96a"
                    ]
                },
                "T-34.5": {
                    "questionId": "T-34.5",
                    "chosenOptionIds": [
                        "9843f58c-c4c4-59f4-9b4f-a6507272dd62"
                    ]
                },
                "T-34.6": {
                    "questionId": "T-34.6",
                    "chosenOptionIds": [
                        "d4d2f085-f924-5ee5-a59d-b214823dab4d"
                    ]
                },
                "T-34.7": {
                    "questionId": "T-34.7",
                    "chosenOptionIds": [
                        "9c131dec-46af-5942-a8f1-697d8163fe24"
                    ]
                },
                "T-34.8": {
                    "questionId": "T-34.8",
                    "chosenOptionIds": [
                        "d97bfb6d-a890-5a97-b3fe-76734424468f"
                    ]
                },
                "T-34.9": {
                    "questionId": "T-34.9",
                    "chosenOptionIds": [
                        "3d567f1d-ec6a-53e2-90fd-c0a9ea495bb6"
                    ]
                },
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "ab90ebb6-b526-5d58-97dd-181c2ddaefdf"
                    ]
                }
            }
        },
        {
            "name": "screened user: 23XX43N aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "d3770e6a-b463-59c3-9904-5893bd7ead01"
                    ]
                }
            }
        },
        {
            "name": "screened user: Y32LM08 aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "96789d75-b879-5f46-8963-b49a0d5a36c7"
                    ]
                }
            }
        },
        {
            "name": "screened user: J3J4P0Y aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "d3770e6a-b463-59c3-9904-5893bd7ead01"
                    ]
                }
            }
        },
        {
            "name": "screened user: 2E1580D aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-1": {
                    "questionId": "T-1",
                    "chosenOptionIds": [
                        "20e21d03-7bd9-504e-8726-d2e3e2fc8ef9",
                        "b32206e4-0455-500a-9564-b9d1b1ddb853"
                    ]
                },
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "f8e62fbd-28e3-5527-94f5-9590869545a3"
                    ]
                }
            }
        },
        {
            "name": "screened user: REOQG32 aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "4fb618ab-1a98-5a8b-8a64-000b45d953f9"
                    ]
                }
            }
        },
        {
            "name": "screened user: 50MDW06 aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "96789d75-b879-5f46-8963-b49a0d5a36c7"
                    ]
                }
            }
        },
        {
            "name": "screened user: 7EW6KE8 aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-1": {
                    "questionId": "T-1",
                    "chosenOptionIds": [
                        "2f912715-981e-56cd-9769-6fe50451871e",
                        "646c6678-d42f-51d3-bf03-0e1ae066d88c",
                        "8ec3c46b-8732-5ae9-8c3d-1846b1d05562",
                        "38b6ec1a-a824-5fd9-bf53-faa9fe2ee355",
                        "afa84b91-8bb8-582a-97d1-8a13ec771151"
                    ]
                },
                "T-34.1": {
                    "questionId": "T-34.1",
                    "chosenOptionIds": [
                        "54067bec-c99e-5b1b-a364-f59f74d62208"
                    ]
                },
                "T-34.10": {
                    "questionId": "T-34.10",
                    "chosenOptionIds": [
                        "086bb9b7-2311-5049-9167-f36a1437927c"
                    ]
                },
                "T-34.11": {
                    "questionId": "T-34.11",
                    "chosenOptionIds": [
                        "ff7ed352-5958-5063-ac3c-8f00d3dde1d1"
                    ]
                },
                "T-34.12": {
                    "questionId": "T-34.12",
                    "chosenOptionIds": [
                        "6ae9c2cf-9e52-5909-bd05-ac047a11aed0"
                    ]
                },
                "T-34.13": {
                    "questionId": "T-34.13",
                    "chosenOptionIds": [
                        "7e1f026a-e129-5fb8-a8a3-4eb401c4a392",
                        "b4532aa6-0bcc-5f11-af1e-d7cb1565da1d",
                        "0bc1e06b-52bf-59fd-95c9-5c5ae905d59c",
                        "edf7cf90-8669-5c78-af40-3d5b6217c483",
                        "548224b5-3e26-5fc1-8797-11d1f01e6919"
                    ]
                },
                "T-34.13a": {
                    "questionId": "T-34.13a",
                    "chosenOptionIds": [
                        "80949ead-3bd4-5888-878f-224cddbebd60"
                    ]
                },
                "T-34.2": {
                    "questionId": "T-34.2",
                    "chosenOptionIds": [
                        "5df58c8b-2b22-57fd-a9e1-f6e61c7ddb61"
                    ]
                },
                "T-34.3": {
                    "questionId": "T-34.3",
                    "chosenOptionIds": [
                        "6c7691dc-1c1a-5410-98b7-48ffdd995bcd"
                    ]
                },
                "T-34.4": {
                    "questionId": "T-34.4",
                    "chosenOptionIds": [
                        "0bd84624-e550-553c-baff-f4ad880a2a4f"
                    ]
                },
                "T-34.5": {
                    "questionId": "T-34.5",
                    "chosenOptionIds": [
                        "40f243ca-75a7-582b-98d6-4cd8b0923958"
                    ]
                },
                "T-34.6": {
                    "questionId": "T-34.6",
                    "chosenOptionIds": [
                        "d4d2f085-f924-5ee5-a59d-b214823dab4d"
                    ]
                },
                "T-34.7": {
                    "questionId": "T-34.7",
                    "chosenOptionIds": [
                        "465fe7ff-e2a2-5390-a835-00807ffc1a0c"
                    ]
                },
                "T-34.8": {
                    "questionId": "T-34.8",
                    "chosenOptionIds": [
                        "01b5d2af-e4c0-5841-934f-2b54d10e0dc5"
                    ]
                },
                "T-34.9": {
                    "questionId": "T-34.9",
                    "chosenOptionIds": [
                        "1478fd0b-fd2f-5293-89b6-aa0cb1307a51"
                    ]
                },
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "ab90ebb6-b526-5d58-97dd-181c2ddaefdf"
                    ]
                }
            }
        },
        {
            "name": "screened user: J0VQM3L aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "96789d75-b879-5f46-8963-b49a0d5a36c7"
                    ]
                }
            }
        },
        {
            "name": "screened user: 4EGRG3P aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "d3770e6a-b463-59c3-9904-5893bd7ead01"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z35L10O aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "96789d75-b879-5f46-8963-b49a0d5a36c7"
                    ]
                }
            }
        },
        {
            "name": "screened user: 63YM43R aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-1": {
                    "questionId": "T-1",
                    "chosenOptionIds": [
                        "2f912715-981e-56cd-9769-6fe50451871e"
                    ]
                },
                "T-35.1": {
                    "questionId": "T-35.1",
                    "chosenOptionIds": [
                        "a8521592-054a-5b2b-98e1-f8c0b3ea00a8"
                    ]
                },
                "T-35.2": {
                    "questionId": "T-35.2",
                    "chosenOptionIds": [
                        "f25bcc46-8c38-5275-9be9-3f4ba80df5dd"
                    ]
                },
                "T-35.3": {
                    "questionId": "T-35.3",
                    "chosenOptionIds": [
                        "5aed6bad-c53b-59e3-9fa8-47fbf9f32a38"
                    ]
                },
                "T-35.4": {
                    "questionId": "T-35.4",
                    "chosenOptionIds": [
                        "85152711-1a93-59c8-9cf4-3e2d320646d6"
                    ]
                },
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "ab90ebb6-b526-5d58-97dd-181c2ddaefdf"
                    ]
                }
            }
        },
        {
            "name": "screened user: Y325838 aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "4fb618ab-1a98-5a8b-8a64-000b45d953f9"
                    ]
                }
            }
        },
        {
            "name": "screened user: XE72M0L aspect: trm_re_ptsd should be positive",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "T-1": {
                    "questionId": "T-1",
                    "chosenOptionIds": [
                        "088cfa77-013c-5c1d-8d66-b8766ed0535c",
                        "7f175744-2ae6-569f-a689-54802eeafbbb",
                        "20e21d03-7bd9-504e-8726-d2e3e2fc8ef9",
                        "b32206e4-0455-500a-9564-b9d1b1ddb853",
                        "966cf398-e957-5889-b1a4-cffa64703207",
                        "e24f82b7-a712-5954-b5fb-421e2119db45",
                        "8ec3c46b-8732-5ae9-8c3d-1846b1d05562",
                        "38b6ec1a-a824-5fd9-bf53-faa9fe2ee355",
                        "eaeabf95-3b89-5b38-8233-c94102db83a4",
                        "a4253f51-5531-586e-bb9f-47e454c7456f"
                    ]
                },
                "T-34.1": {
                    "questionId": "T-34.1",
                    "chosenOptionIds": [
                        "54067bec-c99e-5b1b-a364-f59f74d62208"
                    ]
                },
                "T-34.10": {
                    "questionId": "T-34.10",
                    "chosenOptionIds": [
                        "086bb9b7-2311-5049-9167-f36a1437927c"
                    ]
                },
                "T-34.11": {
                    "questionId": "T-34.11",
                    "chosenOptionIds": [
                        "ff7ed352-5958-5063-ac3c-8f00d3dde1d1"
                    ]
                },
                "T-34.12": {
                    "questionId": "T-34.12",
                    "chosenOptionIds": [
                        "d3daacee-e0e8-59d7-ac29-3f299611ee5d"
                    ]
                },
                "T-34.13": {
                    "questionId": "T-34.13",
                    "chosenOptionIds": [
                        "70ab1917-9ef6-5273-a79a-38f34a97fd4d",
                        "aaf4ead8-1b3e-53bb-9758-560d1046b30a",
                        "6b0a5e9f-3607-584f-99ec-6b0178992c84",
                        "edf7cf90-8669-5c78-af40-3d5b6217c483",
                        "b4532aa6-0bcc-5f11-af1e-d7cb1565da1d",
                        "7e1f026a-e129-5fb8-a8a3-4eb401c4a392",
                        "0bc1e06b-52bf-59fd-95c9-5c5ae905d59c",
                        "548224b5-3e26-5fc1-8797-11d1f01e6919",
                        "f1ddd7e9-7543-5199-8926-31481a1fbd64"
                    ]
                },
                "T-34.13a": {
                    "questionId": "T-34.13a",
                    "chosenOptionIds": [
                        "2061ca08-518e-5daa-b35e-03819ac07b88"
                    ]
                },
                "T-34.2": {
                    "questionId": "T-34.2",
                    "chosenOptionIds": [
                        "5df58c8b-2b22-57fd-a9e1-f6e61c7ddb61"
                    ]
                },
                "T-34.3": {
                    "questionId": "T-34.3",
                    "chosenOptionIds": [
                        "90158eab-7f98-553e-b73b-d954fda86727"
                    ]
                },
                "T-34.4": {
                    "questionId": "T-34.4",
                    "chosenOptionIds": [
                        "0bd84624-e550-553c-baff-f4ad880a2a4f"
                    ]
                },
                "T-34.5": {
                    "questionId": "T-34.5",
                    "chosenOptionIds": [
                        "40f243ca-75a7-582b-98d6-4cd8b0923958"
                    ]
                },
                "T-34.6": {
                    "questionId": "T-34.6",
                    "chosenOptionIds": [
                        "eff45049-db8f-5586-a84e-edfc649e51da"
                    ]
                },
                "T-34.7": {
                    "questionId": "T-34.7",
                    "chosenOptionIds": [
                        "9c131dec-46af-5942-a8f1-697d8163fe24"
                    ]
                },
                "T-34.8": {
                    "questionId": "T-34.8",
                    "chosenOptionIds": [
                        "dd31cb8b-2e4a-542c-990e-2c589a8fb373"
                    ]
                },
                "T-34.9": {
                    "questionId": "T-34.9",
                    "chosenOptionIds": [
                        "9f3eee57-d048-5a80-acb5-e43f43f60228"
                    ]
                },
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "cd79664e-d646-54f6-8ce2-1f236d79730f"
                    ]
                }
            }
        },
        {
            "name": "screened user: ME8MOEW aspect: trm_re_ptsd should be positive",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "T-1": {
                    "questionId": "T-1",
                    "chosenOptionIds": [
                        "7f175744-2ae6-569f-a689-54802eeafbbb",
                        "b32206e4-0455-500a-9564-b9d1b1ddb853",
                        "afa84b91-8bb8-582a-97d1-8a13ec771151"
                    ]
                },
                "T-34.1": {
                    "questionId": "T-34.1",
                    "chosenOptionIds": [
                        "54067bec-c99e-5b1b-a364-f59f74d62208"
                    ]
                },
                "T-34.10": {
                    "questionId": "T-34.10",
                    "chosenOptionIds": [
                        "21593b50-f48c-5400-8caf-d3b67a445453"
                    ]
                },
                "T-34.11": {
                    "questionId": "T-34.11",
                    "chosenOptionIds": [
                        "e1649842-01ce-5bed-aae0-eda574ace3d2"
                    ]
                },
                "T-34.12": {
                    "questionId": "T-34.12",
                    "chosenOptionIds": [
                        "6ae9c2cf-9e52-5909-bd05-ac047a11aed0"
                    ]
                },
                "T-34.13": {
                    "questionId": "T-34.13",
                    "chosenOptionIds": [
                        "548224b5-3e26-5fc1-8797-11d1f01e6919",
                        "0bc1e06b-52bf-59fd-95c9-5c5ae905d59c",
                        "7e1f026a-e129-5fb8-a8a3-4eb401c4a392",
                        "b4532aa6-0bcc-5f11-af1e-d7cb1565da1d",
                        "edf7cf90-8669-5c78-af40-3d5b6217c483",
                        "aaf4ead8-1b3e-53bb-9758-560d1046b30a",
                        "70ab1917-9ef6-5273-a79a-38f34a97fd4d"
                    ]
                },
                "T-34.13a": {
                    "questionId": "T-34.13a",
                    "chosenOptionIds": [
                        "04711b6d-71e7-58ea-a595-a9092bd42bde"
                    ]
                },
                "T-34.2": {
                    "questionId": "T-34.2",
                    "chosenOptionIds": [
                        "5ba6670e-35ef-5545-8135-834feda201d5"
                    ]
                },
                "T-34.3": {
                    "questionId": "T-34.3",
                    "chosenOptionIds": [
                        "90158eab-7f98-553e-b73b-d954fda86727"
                    ]
                },
                "T-34.4": {
                    "questionId": "T-34.4",
                    "chosenOptionIds": [
                        "0bd84624-e550-553c-baff-f4ad880a2a4f"
                    ]
                },
                "T-34.5": {
                    "questionId": "T-34.5",
                    "chosenOptionIds": [
                        "40f243ca-75a7-582b-98d6-4cd8b0923958"
                    ]
                },
                "T-34.6": {
                    "questionId": "T-34.6",
                    "chosenOptionIds": [
                        "eff45049-db8f-5586-a84e-edfc649e51da"
                    ]
                },
                "T-34.7": {
                    "questionId": "T-34.7",
                    "chosenOptionIds": [
                        "9c131dec-46af-5942-a8f1-697d8163fe24"
                    ]
                },
                "T-34.8": {
                    "questionId": "T-34.8",
                    "chosenOptionIds": [
                        "01b5d2af-e4c0-5841-934f-2b54d10e0dc5"
                    ]
                },
                "T-34.9": {
                    "questionId": "T-34.9",
                    "chosenOptionIds": [
                        "9f3eee57-d048-5a80-acb5-e43f43f60228"
                    ]
                },
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "ab90ebb6-b526-5d58-97dd-181c2ddaefdf"
                    ]
                }
            }
        },
        {
            "name": "screened user: 50M6136 aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "d3770e6a-b463-59c3-9904-5893bd7ead01"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z35MQ3O aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-1": {
                    "questionId": "T-1",
                    "chosenOptionIds": [
                        "2717e852-867d-5e13-8293-2fd8ccb66dd5",
                        "20e21d03-7bd9-504e-8726-d2e3e2fc8ef9",
                        "b32206e4-0455-500a-9564-b9d1b1ddb853",
                        "6137d2c9-5a9b-5ec3-a2ae-18d6ad8c5ba0"
                    ]
                },
                "T-34.1": {
                    "questionId": "T-34.1",
                    "chosenOptionIds": [
                        "54067bec-c99e-5b1b-a364-f59f74d62208"
                    ]
                },
                "T-34.10": {
                    "questionId": "T-34.10",
                    "chosenOptionIds": [
                        "e3007f71-71f0-5447-9503-dd86a7329d1f"
                    ]
                },
                "T-34.11": {
                    "questionId": "T-34.11",
                    "chosenOptionIds": [
                        "ff7ed352-5958-5063-ac3c-8f00d3dde1d1"
                    ]
                },
                "T-34.12": {
                    "questionId": "T-34.12",
                    "chosenOptionIds": [
                        "f53887e9-04f3-5b86-9558-cb6e9b92b333"
                    ]
                },
                "T-34.13": {
                    "questionId": "T-34.13",
                    "chosenOptionIds": [
                        "aaf4ead8-1b3e-53bb-9758-560d1046b30a",
                        "0bc1e06b-52bf-59fd-95c9-5c5ae905d59c",
                        "548224b5-3e26-5fc1-8797-11d1f01e6919",
                        "6b0a5e9f-3607-584f-99ec-6b0178992c84"
                    ]
                },
                "T-34.13a": {
                    "questionId": "T-34.13a",
                    "chosenOptionIds": [
                        "80949ead-3bd4-5888-878f-224cddbebd60"
                    ]
                },
                "T-34.2": {
                    "questionId": "T-34.2",
                    "chosenOptionIds": [
                        "5df58c8b-2b22-57fd-a9e1-f6e61c7ddb61"
                    ]
                },
                "T-34.3": {
                    "questionId": "T-34.3",
                    "chosenOptionIds": [
                        "6c7691dc-1c1a-5410-98b7-48ffdd995bcd"
                    ]
                },
                "T-34.4": {
                    "questionId": "T-34.4",
                    "chosenOptionIds": [
                        "bf6c3ef5-62fa-5172-9a8e-db0dae8fb96a"
                    ]
                },
                "T-34.5": {
                    "questionId": "T-34.5",
                    "chosenOptionIds": [
                        "40f243ca-75a7-582b-98d6-4cd8b0923958"
                    ]
                },
                "T-34.6": {
                    "questionId": "T-34.6",
                    "chosenOptionIds": [
                        "d4d2f085-f924-5ee5-a59d-b214823dab4d"
                    ]
                },
                "T-34.7": {
                    "questionId": "T-34.7",
                    "chosenOptionIds": [
                        "c12290da-a5de-5f26-8164-110211b6bd4c"
                    ]
                },
                "T-34.8": {
                    "questionId": "T-34.8",
                    "chosenOptionIds": [
                        "d97bfb6d-a890-5a97-b3fe-76734424468f"
                    ]
                },
                "T-34.9": {
                    "questionId": "T-34.9",
                    "chosenOptionIds": [
                        "1478fd0b-fd2f-5293-89b6-aa0cb1307a51"
                    ]
                },
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "ab90ebb6-b526-5d58-97dd-181c2ddaefdf"
                    ]
                }
            }
        },
        {
            "name": "screened user: 73ZW63R aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-1": {
                    "questionId": "T-1",
                    "chosenOptionIds": [
                        "d8fafb20-a4f7-5702-82a4-2bf31ee2b523",
                        "b32206e4-0455-500a-9564-b9d1b1ddb853",
                        "966cf398-e957-5889-b1a4-cffa64703207",
                        "8ec3c46b-8732-5ae9-8c3d-1846b1d05562",
                        "e24f82b7-a712-5954-b5fb-421e2119db45",
                        "38b6ec1a-a824-5fd9-bf53-faa9fe2ee355",
                        "a64cebc6-da46-5d9b-bef5-379159acdadd"
                    ]
                },
                "T-34.1": {
                    "questionId": "T-34.1",
                    "chosenOptionIds": [
                        "572e18ea-b0f3-5d76-a94f-f1d8c085df82"
                    ]
                },
                "T-34.10": {
                    "questionId": "T-34.10",
                    "chosenOptionIds": [
                        "21593b50-f48c-5400-8caf-d3b67a445453"
                    ]
                },
                "T-34.11": {
                    "questionId": "T-34.11",
                    "chosenOptionIds": [
                        "ff7ed352-5958-5063-ac3c-8f00d3dde1d1"
                    ]
                },
                "T-34.12": {
                    "questionId": "T-34.12",
                    "chosenOptionIds": [
                        "f8239bbb-1e37-5974-bca5-b6ba0daea1fe"
                    ]
                },
                "T-34.13": {
                    "questionId": "T-34.13",
                    "chosenOptionIds": [
                        "0bc1e06b-52bf-59fd-95c9-5c5ae905d59c",
                        "7e1f026a-e129-5fb8-a8a3-4eb401c4a392",
                        "edf7cf90-8669-5c78-af40-3d5b6217c483",
                        "70ab1917-9ef6-5273-a79a-38f34a97fd4d",
                        "b4532aa6-0bcc-5f11-af1e-d7cb1565da1d"
                    ]
                },
                "T-34.13a": {
                    "questionId": "T-34.13a",
                    "chosenOptionIds": [
                        "04711b6d-71e7-58ea-a595-a9092bd42bde"
                    ]
                },
                "T-34.2": {
                    "questionId": "T-34.2",
                    "chosenOptionIds": [
                        "5df58c8b-2b22-57fd-a9e1-f6e61c7ddb61"
                    ]
                },
                "T-34.3": {
                    "questionId": "T-34.3",
                    "chosenOptionIds": [
                        "6c7691dc-1c1a-5410-98b7-48ffdd995bcd"
                    ]
                },
                "T-34.4": {
                    "questionId": "T-34.4",
                    "chosenOptionIds": [
                        "aa992ff2-b894-50ad-87b9-1863b8c261b3"
                    ]
                },
                "T-34.5": {
                    "questionId": "T-34.5",
                    "chosenOptionIds": [
                        "40f243ca-75a7-582b-98d6-4cd8b0923958"
                    ]
                },
                "T-34.6": {
                    "questionId": "T-34.6",
                    "chosenOptionIds": [
                        "eff45049-db8f-5586-a84e-edfc649e51da"
                    ]
                },
                "T-34.7": {
                    "questionId": "T-34.7",
                    "chosenOptionIds": [
                        "465fe7ff-e2a2-5390-a835-00807ffc1a0c"
                    ]
                },
                "T-34.8": {
                    "questionId": "T-34.8",
                    "chosenOptionIds": [
                        "d97bfb6d-a890-5a97-b3fe-76734424468f"
                    ]
                },
                "T-34.9": {
                    "questionId": "T-34.9",
                    "chosenOptionIds": [
                        "1478fd0b-fd2f-5293-89b6-aa0cb1307a51"
                    ]
                },
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "f8e62fbd-28e3-5527-94f5-9590869545a3"
                    ]
                }
            }
        },
        {
            "name": "screened user: 4EG443P aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-1": {
                    "questionId": "T-1",
                    "chosenOptionIds": [
                        "b32206e4-0455-500a-9564-b9d1b1ddb853",
                        "38b6ec1a-a824-5fd9-bf53-faa9fe2ee355",
                        "8ec3c46b-8732-5ae9-8c3d-1846b1d05562",
                        "eaeabf95-3b89-5b38-8233-c94102db83a4",
                        "afa84b91-8bb8-582a-97d1-8a13ec771151",
                        "a64cebc6-da46-5d9b-bef5-379159acdadd"
                    ]
                },
                "T-34.1": {
                    "questionId": "T-34.1",
                    "chosenOptionIds": [
                        "54067bec-c99e-5b1b-a364-f59f74d62208"
                    ]
                },
                "T-34.10": {
                    "questionId": "T-34.10",
                    "chosenOptionIds": [
                        "21593b50-f48c-5400-8caf-d3b67a445453"
                    ]
                },
                "T-34.11": {
                    "questionId": "T-34.11",
                    "chosenOptionIds": [
                        "ff7ed352-5958-5063-ac3c-8f00d3dde1d1"
                    ]
                },
                "T-34.12": {
                    "questionId": "T-34.12",
                    "chosenOptionIds": [
                        "f53887e9-04f3-5b86-9558-cb6e9b92b333"
                    ]
                },
                "T-34.13": {
                    "questionId": "T-34.13",
                    "chosenOptionIds": [
                        "f1ddd7e9-7543-5199-8926-31481a1fbd64",
                        "548224b5-3e26-5fc1-8797-11d1f01e6919",
                        "0bc1e06b-52bf-59fd-95c9-5c5ae905d59c",
                        "7e1f026a-e129-5fb8-a8a3-4eb401c4a392",
                        "b4532aa6-0bcc-5f11-af1e-d7cb1565da1d",
                        "aaf4ead8-1b3e-53bb-9758-560d1046b30a",
                        "70ab1917-9ef6-5273-a79a-38f34a97fd4d"
                    ]
                },
                "T-34.13a": {
                    "questionId": "T-34.13a",
                    "chosenOptionIds": [
                        "2061ca08-518e-5daa-b35e-03819ac07b88"
                    ]
                },
                "T-34.2": {
                    "questionId": "T-34.2",
                    "chosenOptionIds": [
                        "5ba6670e-35ef-5545-8135-834feda201d5"
                    ]
                },
                "T-34.3": {
                    "questionId": "T-34.3",
                    "chosenOptionIds": [
                        "90158eab-7f98-553e-b73b-d954fda86727"
                    ]
                },
                "T-34.4": {
                    "questionId": "T-34.4",
                    "chosenOptionIds": [
                        "aa992ff2-b894-50ad-87b9-1863b8c261b3"
                    ]
                },
                "T-34.5": {
                    "questionId": "T-34.5",
                    "chosenOptionIds": [
                        "40f243ca-75a7-582b-98d6-4cd8b0923958"
                    ]
                },
                "T-34.6": {
                    "questionId": "T-34.6",
                    "chosenOptionIds": [
                        "5f45ff1f-a46f-55ed-9d4d-837f817e371d"
                    ]
                },
                "T-34.7": {
                    "questionId": "T-34.7",
                    "chosenOptionIds": [
                        "465fe7ff-e2a2-5390-a835-00807ffc1a0c"
                    ]
                },
                "T-34.8": {
                    "questionId": "T-34.8",
                    "chosenOptionIds": [
                        "d97bfb6d-a890-5a97-b3fe-76734424468f"
                    ]
                },
                "T-34.9": {
                    "questionId": "T-34.9",
                    "chosenOptionIds": [
                        "1478fd0b-fd2f-5293-89b6-aa0cb1307a51"
                    ]
                },
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "ab90ebb6-b526-5d58-97dd-181c2ddaefdf"
                    ]
                }
            }
        },
        {
            "name": "screened user: REOG402 aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-1": {
                    "questionId": "T-1",
                    "chosenOptionIds": [
                        "8d925beb-6b03-56cb-8348-c4952f097b9f"
                    ]
                },
                "T-35.1": {
                    "questionId": "T-35.1",
                    "chosenOptionIds": [
                        "0366d501-9bb3-5b7c-9e65-ce9fa9bdc19e"
                    ]
                },
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "f8e62fbd-28e3-5527-94f5-9590869545a3"
                    ]
                }
            }
        },
        {
            "name": "screened user: J0V6N3L aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-1": {
                    "questionId": "T-1",
                    "chosenOptionIds": [
                        "2f912715-981e-56cd-9769-6fe50451871e",
                        "8d925beb-6b03-56cb-8348-c4952f097b9f"
                    ]
                },
                "T-35.1": {
                    "questionId": "T-35.1",
                    "chosenOptionIds": [
                        "a8521592-054a-5b2b-98e1-f8c0b3ea00a8"
                    ]
                },
                "T-35.2": {
                    "questionId": "T-35.2",
                    "chosenOptionIds": [
                        "afa0086f-aed1-569d-8b56-6273d9e8fcbb"
                    ]
                },
                "T-35.3": {
                    "questionId": "T-35.3",
                    "chosenOptionIds": [
                        "39788390-fbb7-5cc6-b6a3-a65d420d91f0"
                    ]
                },
                "T-35.4": {
                    "questionId": "T-35.4",
                    "chosenOptionIds": [
                        "c5118e92-1bdf-56c8-b12f-90e686eba051"
                    ]
                },
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "4fb618ab-1a98-5a8b-8a64-000b45d953f9"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z3R6O0O aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "d3770e6a-b463-59c3-9904-5893bd7ead01"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z3R680O aspect: trm_re_ptsd should be positive",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "T-1": {
                    "questionId": "T-1",
                    "chosenOptionIds": [
                        "2f912715-981e-56cd-9769-6fe50451871e",
                        "646c6678-d42f-51d3-bf03-0e1ae066d88c",
                        "7f175744-2ae6-569f-a689-54802eeafbbb"
                    ]
                },
                "T-34.1": {
                    "questionId": "T-34.1",
                    "chosenOptionIds": [
                        "54067bec-c99e-5b1b-a364-f59f74d62208"
                    ]
                },
                "T-34.10": {
                    "questionId": "T-34.10",
                    "chosenOptionIds": [
                        "086bb9b7-2311-5049-9167-f36a1437927c"
                    ]
                },
                "T-34.11": {
                    "questionId": "T-34.11",
                    "chosenOptionIds": [
                        "5f030af0-4a37-526d-81c4-0134760ebf84"
                    ]
                },
                "T-34.12": {
                    "questionId": "T-34.12",
                    "chosenOptionIds": [
                        "6ae9c2cf-9e52-5909-bd05-ac047a11aed0"
                    ]
                },
                "T-34.13": {
                    "questionId": "T-34.13",
                    "chosenOptionIds": [
                        "f1ddd7e9-7543-5199-8926-31481a1fbd64",
                        "0bc1e06b-52bf-59fd-95c9-5c5ae905d59c",
                        "7e1f026a-e129-5fb8-a8a3-4eb401c4a392",
                        "b4532aa6-0bcc-5f11-af1e-d7cb1565da1d",
                        "edf7cf90-8669-5c78-af40-3d5b6217c483",
                        "6b0a5e9f-3607-584f-99ec-6b0178992c84",
                        "aaf4ead8-1b3e-53bb-9758-560d1046b30a"
                    ]
                },
                "T-34.13a": {
                    "questionId": "T-34.13a",
                    "chosenOptionIds": [
                        "2061ca08-518e-5daa-b35e-03819ac07b88"
                    ]
                },
                "T-34.2": {
                    "questionId": "T-34.2",
                    "chosenOptionIds": [
                        "5df58c8b-2b22-57fd-a9e1-f6e61c7ddb61"
                    ]
                },
                "T-34.3": {
                    "questionId": "T-34.3",
                    "chosenOptionIds": [
                        "90158eab-7f98-553e-b73b-d954fda86727"
                    ]
                },
                "T-34.4": {
                    "questionId": "T-34.4",
                    "chosenOptionIds": [
                        "0bd84624-e550-553c-baff-f4ad880a2a4f"
                    ]
                },
                "T-34.5": {
                    "questionId": "T-34.5",
                    "chosenOptionIds": [
                        "9843f58c-c4c4-59f4-9b4f-a6507272dd62"
                    ]
                },
                "T-34.6": {
                    "questionId": "T-34.6",
                    "chosenOptionIds": [
                        "10bf3060-43e8-52e2-9382-9427d00fd16a"
                    ]
                },
                "T-34.7": {
                    "questionId": "T-34.7",
                    "chosenOptionIds": [
                        "9c131dec-46af-5942-a8f1-697d8163fe24"
                    ]
                },
                "T-34.8": {
                    "questionId": "T-34.8",
                    "chosenOptionIds": [
                        "6ecd2132-af6f-569f-9a93-8c8d5f5eb615"
                    ]
                },
                "T-34.9": {
                    "questionId": "T-34.9",
                    "chosenOptionIds": [
                        "3d567f1d-ec6a-53e2-90fd-c0a9ea495bb6"
                    ]
                },
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "ab90ebb6-b526-5d58-97dd-181c2ddaefdf"
                    ]
                }
            }
        },
        {
            "name": "screened user: R047J04 aspect: trm_re_ptsd should be positive",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "T-1": {
                    "questionId": "T-1",
                    "chosenOptionIds": [
                        "8d925beb-6b03-56cb-8348-c4952f097b9f",
                        "646c6678-d42f-51d3-bf03-0e1ae066d88c",
                        "088cfa77-013c-5c1d-8d66-b8766ed0535c",
                        "7f175744-2ae6-569f-a689-54802eeafbbb",
                        "d8fafb20-a4f7-5702-82a4-2bf31ee2b523",
                        "6137d2c9-5a9b-5ec3-a2ae-18d6ad8c5ba0",
                        "20e21d03-7bd9-504e-8726-d2e3e2fc8ef9",
                        "afa84b91-8bb8-582a-97d1-8a13ec771151",
                        "eaeabf95-3b89-5b38-8233-c94102db83a4"
                    ]
                },
                "T-34.1": {
                    "questionId": "T-34.1",
                    "chosenOptionIds": [
                        "54067bec-c99e-5b1b-a364-f59f74d62208"
                    ]
                },
                "T-34.10": {
                    "questionId": "T-34.10",
                    "chosenOptionIds": [
                        "21593b50-f48c-5400-8caf-d3b67a445453"
                    ]
                },
                "T-34.11": {
                    "questionId": "T-34.11",
                    "chosenOptionIds": [
                        "e1649842-01ce-5bed-aae0-eda574ace3d2"
                    ]
                },
                "T-34.12": {
                    "questionId": "T-34.12",
                    "chosenOptionIds": [
                        "6ae9c2cf-9e52-5909-bd05-ac047a11aed0"
                    ]
                },
                "T-34.13": {
                    "questionId": "T-34.13",
                    "chosenOptionIds": [
                        "548224b5-3e26-5fc1-8797-11d1f01e6919",
                        "0bc1e06b-52bf-59fd-95c9-5c5ae905d59c",
                        "7e1f026a-e129-5fb8-a8a3-4eb401c4a392",
                        "aaf4ead8-1b3e-53bb-9758-560d1046b30a"
                    ]
                },
                "T-34.13a": {
                    "questionId": "T-34.13a",
                    "chosenOptionIds": [
                        "04711b6d-71e7-58ea-a595-a9092bd42bde"
                    ]
                },
                "T-34.2": {
                    "questionId": "T-34.2",
                    "chosenOptionIds": [
                        "5df58c8b-2b22-57fd-a9e1-f6e61c7ddb61"
                    ]
                },
                "T-34.3": {
                    "questionId": "T-34.3",
                    "chosenOptionIds": [
                        "90158eab-7f98-553e-b73b-d954fda86727"
                    ]
                },
                "T-34.4": {
                    "questionId": "T-34.4",
                    "chosenOptionIds": [
                        "34874654-ecda-51ca-94ea-045a0b3c6837"
                    ]
                },
                "T-34.5": {
                    "questionId": "T-34.5",
                    "chosenOptionIds": [
                        "8ef2ba4b-524a-5546-aa24-57e8221880f7"
                    ]
                },
                "T-34.6": {
                    "questionId": "T-34.6",
                    "chosenOptionIds": [
                        "eff45049-db8f-5586-a84e-edfc649e51da"
                    ]
                },
                "T-34.7": {
                    "questionId": "T-34.7",
                    "chosenOptionIds": [
                        "c12290da-a5de-5f26-8164-110211b6bd4c"
                    ]
                },
                "T-34.8": {
                    "questionId": "T-34.8",
                    "chosenOptionIds": [
                        "d97bfb6d-a890-5a97-b3fe-76734424468f"
                    ]
                },
                "T-34.9": {
                    "questionId": "T-34.9",
                    "chosenOptionIds": [
                        "3d567f1d-ec6a-53e2-90fd-c0a9ea495bb6"
                    ]
                },
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "ab90ebb6-b526-5d58-97dd-181c2ddaefdf"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z35PN3O aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-1": {
                    "questionId": "T-1",
                    "chosenOptionIds": [
                        "2f912715-981e-56cd-9769-6fe50451871e"
                    ]
                },
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "ab90ebb6-b526-5d58-97dd-181c2ddaefdf"
                    ]
                }
            }
        },
        {
            "name": "screened user: ME87V3W aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-1": {
                    "questionId": "T-1",
                    "chosenOptionIds": [
                        "088cfa77-013c-5c1d-8d66-b8766ed0535c",
                        "7f175744-2ae6-569f-a689-54802eeafbbb",
                        "b32206e4-0455-500a-9564-b9d1b1ddb853",
                        "966cf398-e957-5889-b1a4-cffa64703207",
                        "e24f82b7-a712-5954-b5fb-421e2119db45",
                        "8ec3c46b-8732-5ae9-8c3d-1846b1d05562",
                        "afa84b91-8bb8-582a-97d1-8a13ec771151",
                        "2f912715-981e-56cd-9769-6fe50451871e"
                    ]
                },
                "T-34.1": {
                    "questionId": "T-34.1",
                    "chosenOptionIds": [
                        "9083faa9-7932-5540-973c-87dff42ec1b5"
                    ]
                },
                "T-34.10": {
                    "questionId": "T-34.10",
                    "chosenOptionIds": [
                        "086bb9b7-2311-5049-9167-f36a1437927c"
                    ]
                },
                "T-34.11": {
                    "questionId": "T-34.11",
                    "chosenOptionIds": [
                        "e1649842-01ce-5bed-aae0-eda574ace3d2"
                    ]
                },
                "T-34.12": {
                    "questionId": "T-34.12",
                    "chosenOptionIds": [
                        "d3daacee-e0e8-59d7-ac29-3f299611ee5d"
                    ]
                },
                "T-34.13": {
                    "questionId": "T-34.13",
                    "chosenOptionIds": [
                        "0bc1e06b-52bf-59fd-95c9-5c5ae905d59c",
                        "7e1f026a-e129-5fb8-a8a3-4eb401c4a392",
                        "b4532aa6-0bcc-5f11-af1e-d7cb1565da1d",
                        "edf7cf90-8669-5c78-af40-3d5b6217c483",
                        "548224b5-3e26-5fc1-8797-11d1f01e6919"
                    ]
                },
                "T-34.13a": {
                    "questionId": "T-34.13a",
                    "chosenOptionIds": [
                        "04711b6d-71e7-58ea-a595-a9092bd42bde"
                    ]
                },
                "T-34.2": {
                    "questionId": "T-34.2",
                    "chosenOptionIds": [
                        "1b126b00-ec1b-5366-89a4-d0bacc703e89"
                    ]
                },
                "T-34.3": {
                    "questionId": "T-34.3",
                    "chosenOptionIds": [
                        "fc231225-070b-500f-b890-891ab25596fe"
                    ]
                },
                "T-34.4": {
                    "questionId": "T-34.4",
                    "chosenOptionIds": [
                        "3125e96d-cc4a-5c45-a2fa-a3d908969214"
                    ]
                },
                "T-34.5": {
                    "questionId": "T-34.5",
                    "chosenOptionIds": [
                        "9843f58c-c4c4-59f4-9b4f-a6507272dd62"
                    ]
                },
                "T-34.6": {
                    "questionId": "T-34.6",
                    "chosenOptionIds": [
                        "eff45049-db8f-5586-a84e-edfc649e51da"
                    ]
                },
                "T-34.7": {
                    "questionId": "T-34.7",
                    "chosenOptionIds": [
                        "465fe7ff-e2a2-5390-a835-00807ffc1a0c"
                    ]
                },
                "T-34.8": {
                    "questionId": "T-34.8",
                    "chosenOptionIds": [
                        "6ecd2132-af6f-569f-9a93-8c8d5f5eb615"
                    ]
                },
                "T-34.9": {
                    "questionId": "T-34.9",
                    "chosenOptionIds": [
                        "1478fd0b-fd2f-5293-89b6-aa0cb1307a51"
                    ]
                },
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "cd79664e-d646-54f6-8ce2-1f236d79730f"
                    ]
                }
            }
        },
        {
            "name": "screened user: 2E17NED aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "d3770e6a-b463-59c3-9904-5893bd7ead01"
                    ]
                }
            }
        },
        {
            "name": "screened user: PE61J0L aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "96789d75-b879-5f46-8963-b49a0d5a36c7"
                    ]
                }
            }
        },
        {
            "name": "screened user: 50MVM06 aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "4fb618ab-1a98-5a8b-8a64-000b45d953f9"
                    ]
                }
            }
        },
        {
            "name": "screened user: J3JK4EY aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-1": {
                    "questionId": "T-1",
                    "chosenOptionIds": [
                        "8436c041-416d-5abf-8987-658006b97aef",
                        "eaeabf95-3b89-5b38-8233-c94102db83a4",
                        "38b6ec1a-a824-5fd9-bf53-faa9fe2ee355",
                        "8ec3c46b-8732-5ae9-8c3d-1846b1d05562",
                        "b32206e4-0455-500a-9564-b9d1b1ddb853",
                        "20e21d03-7bd9-504e-8726-d2e3e2fc8ef9"
                    ]
                },
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "ab90ebb6-b526-5d58-97dd-181c2ddaefdf"
                    ]
                }
            }
        },
        {
            "name": "Tech team adjusted screen from TRUE, Censeo vPrevious user: 7EW4D08 aspect: trm_re_ptsd",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-1": {
                    "questionId": "T-1",
                    "chosenOptionIds": [
                        "646c6678-d42f-51d3-bf03-0e1ae066d88c",
                        "2f912715-981e-56cd-9769-6fe50451871e",
                        "8d925beb-6b03-56cb-8348-c4952f097b9f",
                        "088cfa77-013c-5c1d-8d66-b8766ed0535c",
                        "20e21d03-7bd9-504e-8726-d2e3e2fc8ef9",
                        "6137d2c9-5a9b-5ec3-a2ae-18d6ad8c5ba0",
                        "b32206e4-0455-500a-9564-b9d1b1ddb853",
                        "966cf398-e957-5889-b1a4-cffa64703207",
                        "8ec3c46b-8732-5ae9-8c3d-1846b1d05562",
                        "8436c041-416d-5abf-8987-658006b97aef"
                    ]
                },
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "f8e62fbd-28e3-5527-94f5-9590869545a3"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z3RLO3O aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-1": {
                    "questionId": "T-1",
                    "chosenOptionIds": [
                        "966cf398-e957-5889-b1a4-cffa64703207",
                        "a64cebc6-da46-5d9b-bef5-379159acdadd"
                    ]
                },
                "T-34.1": {
                    "questionId": "T-34.1",
                    "chosenOptionIds": [
                        "54067bec-c99e-5b1b-a364-f59f74d62208"
                    ]
                },
                "T-34.10": {
                    "questionId": "T-34.10",
                    "chosenOptionIds": [
                        "b68a8081-73da-5df9-afe7-a19832e7ecf1"
                    ]
                },
                "T-34.11": {
                    "questionId": "T-34.11",
                    "chosenOptionIds": [
                        "0d0d7d9e-83fe-54b9-8c4c-af41dd8b906c"
                    ]
                },
                "T-34.12": {
                    "questionId": "T-34.12",
                    "chosenOptionIds": [
                        "f8239bbb-1e37-5974-bca5-b6ba0daea1fe"
                    ]
                },
                "T-34.13": {
                    "questionId": "T-34.13",
                    "chosenOptionIds": [
                        "0bc1e06b-52bf-59fd-95c9-5c5ae905d59c",
                        "f1ddd7e9-7543-5199-8926-31481a1fbd64",
                        "548224b5-3e26-5fc1-8797-11d1f01e6919",
                        "7e1f026a-e129-5fb8-a8a3-4eb401c4a392",
                        "b4532aa6-0bcc-5f11-af1e-d7cb1565da1d",
                        "edf7cf90-8669-5c78-af40-3d5b6217c483",
                        "6b0a5e9f-3607-584f-99ec-6b0178992c84",
                        "aaf4ead8-1b3e-53bb-9758-560d1046b30a"
                    ]
                },
                "T-34.13a": {
                    "questionId": "T-34.13a",
                    "chosenOptionIds": [
                        "04711b6d-71e7-58ea-a595-a9092bd42bde"
                    ]
                },
                "T-34.2": {
                    "questionId": "T-34.2",
                    "chosenOptionIds": [
                        "5df58c8b-2b22-57fd-a9e1-f6e61c7ddb61"
                    ]
                },
                "T-34.3": {
                    "questionId": "T-34.3",
                    "chosenOptionIds": [
                        "90158eab-7f98-553e-b73b-d954fda86727"
                    ]
                },
                "T-34.4": {
                    "questionId": "T-34.4",
                    "chosenOptionIds": [
                        "0bd84624-e550-553c-baff-f4ad880a2a4f"
                    ]
                },
                "T-34.5": {
                    "questionId": "T-34.5",
                    "chosenOptionIds": [
                        "9843f58c-c4c4-59f4-9b4f-a6507272dd62"
                    ]
                },
                "T-34.6": {
                    "questionId": "T-34.6",
                    "chosenOptionIds": [
                        "5f45ff1f-a46f-55ed-9d4d-837f817e371d"
                    ]
                },
                "T-34.7": {
                    "questionId": "T-34.7",
                    "chosenOptionIds": [
                        "9c131dec-46af-5942-a8f1-697d8163fe24"
                    ]
                },
                "T-34.8": {
                    "questionId": "T-34.8",
                    "chosenOptionIds": [
                        "6ecd2132-af6f-569f-9a93-8c8d5f5eb615"
                    ]
                },
                "T-34.9": {
                    "questionId": "T-34.9",
                    "chosenOptionIds": [
                        "1478fd0b-fd2f-5293-89b6-aa0cb1307a51"
                    ]
                },
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "cd79664e-d646-54f6-8ce2-1f236d79730f"
                    ]
                }
            }
        },
        {
            "name": "screened user: J3JZREY aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-1": {
                    "questionId": "T-1",
                    "chosenOptionIds": [
                        "20e21d03-7bd9-504e-8726-d2e3e2fc8ef9",
                        "b32206e4-0455-500a-9564-b9d1b1ddb853",
                        "966cf398-e957-5889-b1a4-cffa64703207",
                        "8ec3c46b-8732-5ae9-8c3d-1846b1d05562",
                        "38b6ec1a-a824-5fd9-bf53-faa9fe2ee355",
                        "afa84b91-8bb8-582a-97d1-8a13ec771151",
                        "646c6678-d42f-51d3-bf03-0e1ae066d88c"
                    ]
                },
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "cd79664e-d646-54f6-8ce2-1f236d79730f"
                    ]
                }
            }
        },
        {
            "name": "screened user: XE7WX3L aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "e3912e04-a5d3-5bf1-869a-a651db93c0f7"
                    ]
                }
            }
        },
        {
            "name": "screened user: PE64M0L aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "d3770e6a-b463-59c3-9904-5893bd7ead01"
                    ]
                }
            }
        },
        {
            "name": "screened user: O3P263W aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "3587b76d-5e17-5be1-8482-6865a9629851"
                    ]
                },
                "T-s1.5": {
                    "questionId": "T-s1.5",
                    "chosenOptionIds": [
                        "0a07af27-a393-5ebf-a8cd-9a5a8e944ee5"
                    ]
                },
                "T-s2": {
                    "questionId": "T-s2",
                    "chosenOptionIds": [
                        "663d4bdf-a395-58c2-80c2-892ac7e59f91"
                    ]
                },
                "T-s3": {
                    "questionId": "T-s3",
                    "chosenOptionIds": [
                        "4fb618ab-1a98-5a8b-8a64-000b45d953f9"
                    ]
                }
            }
        },
        {
            "name": "screened user: NELXPEV aspect: trm_re_ptsd should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "T-S": {
                    "questionId": "T-S",
                    "chosenOptionIds": [
                        "65cd18c1-f52b-57f3-aee1-86ae16c71430"
                    ]
                },
                "T-s1": {
                    "questionId": "T-s1",
                    "chosenOptionIds": [
                        "96789d75-b879-5f46-8963-b49a0d5a36c7"
                    ]
                }
            }
        }
    ]
};
exports.questionPredicates = {
    "T-s1.5": "not(T-s1=0)",
    "T-s2": "not(T-s1=0)",
    "T-s3": "T-s2=0",
    "T-1": "not(T-s3=1)",
    "T-34.1": "and[or[isUndefined(T7_skip=1), not(T7_skip=1)], or[T2_personalill=1, T2_otherill=1, T2_harmtoother=1, T2_accidentharm=1, T3_physassault=1, T3_war=1, T4_natdisaster=1, T4_mandisaster=1, T4_adphysabuse=1, T5_ademoabuse=1, T5_adwitnessabuse=1, T6_preg=1, T6_bullying=1, T7_other=1], not(isUndefined(T-1=0))]",
    "T-34.2": "not(T-34.1=1)",
    "T-34.3": "not(T-34.2=1)",
    "T-34.4": "not(T-34.3=1)",
    "T-34.5": "not(T-34.4=1)",
    "T-34.6": "not(T-34.5=1)",
    "T-34.7": "not(T-34.6=1)",
    "T-34.8": "not(T-34.7=1)",
    "T-34.9": "not(T-34.8=1)",
    "T-34.10": "not(T-34.9=1)",
    "T-34.11": "not(T-34.10=1)",
    "T-34.12": "not(T-34.11=1)",
    "T-34.13": "not(T-34.12=1)",
    "T-34.13a": "and[or[isUndefined(T34.14_skip=1), not(T34.14_skip=1)], or[isUndefined(T34.13_none=1), not(T34.13_none=1)], not(isUndefined(T-34.13=0))]",
    "T-35.1": "and[or[isUndefined(T7_skip=1), not(T7_skip=1)], or[isUndefined(T2_personalill=1), not(T2_personalill=1)], or[isUndefined(T2_otherill=1), not(T2_otherill=1)], or[isUndefined(T2_harmtoother=1), not(T2_harmtoother=1)], or[isUndefined(T2_accidentharm=1), not(T2_accidentharm=1)], or[isUndefined(T3_physassault=1), not(T3_physassault=1)], or[isUndefined(T3_war=1), not(T3_war=1)], or[isUndefined(T4_natdisaster=1), not(T4_natdisaster=1)], or[isUndefined(T4_mandisaster=1), not(T4_mandisaster=1)], or[isUndefined(T4_adphysabuse=1), not(T4_adphysabuse=1)], or[isUndefined(T5_ademoabuse=1), not(T5_ademoabuse=1)], or[isUndefined(T5_adwitnessabuse=1), not(T5_adwitnessabuse=1)], or[isUndefined(T6_preg=1), not(T6_preg=1)], or[isUndefined(T6_bullying=1), not(T6_bullying=1)], or[isUndefined(T7_other=1), not(T7_other=1)], or[T1_death=1, T1_adopt=1, T1_parentsep=1, T1_partnersep=1, T3_housing=1, T3_job=1, T4_chphysabuse=1, T5_chemoabuse=1, T5_chwitnessabuse=1, T6_neglect=1, T6_infertility=1, T7_finance=1], not(isUndefined(T-1=0))]",
    "T-35.2": "not(T-35.1=1)",
    "T-35.3": "not(T-35.2=1)",
    "T-35.4": "not(T-35.3=1)"
};
