"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.questionPredicates = exports.tests = exports.aspects = exports.questions = void 0;
const censeo_1 = require("../../schema/generated/censeo");
exports.questions = [
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "ab920687-add2-51b6-a5d1-ae8b69095749",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "d56877af-e8cf-54d7-9156-2130960436d9",
                "text": "Yes",
                "value": 1
            },
            {
                "__typename": "Option",
                "id": "2f54c966-c1a2-57ac-82af-d3724cf9ac8f",
                "text": "Prefer not to say",
                "value": 0.5
            }
        ],
        "id": "LR37",
        "text": "Have you experienced a major life event in the last 6 months which is affecting you negatively?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "4864aca1-818d-59ee-8da9-ff7a4e9c2b63",
                "text": "Loss of job"
            },
            {
                "__typename": "Option",
                "id": "5cd60e24-de33-56f8-8b04-13d2e683ec0b",
                "text": "Divorce, separation or relationship breakdown"
            },
            {
                "__typename": "Option",
                "id": "26ca934e-26fd-54b7-ab52-c162ddffbe00",
                "text": "Major illness or injury of a loved one"
            },
            {
                "__typename": "Option",
                "id": "d6e1b247-4aa2-55fd-bce7-42c7fa8cea51",
                "text": "Major illness or injury of yourself"
            },
            {
                "__typename": "Option",
                "id": "93eedc10-44cc-5759-8cea-df93ae21e4d3",
                "text": "Children leaving home"
            },
            {
                "__typename": "Option",
                "id": "6c237476-6277-530e-bc2a-fe3482245062",
                "text": "Difficult experience(s) caused by the coronavirus"
            },
            {
                "__typename": "Option",
                "id": "e632ac5a-ad6f-58ad-8e51-f5e957428c4b",
                "text": "None of the above",
                "exclusive": true
            },
            {
                "__typename": "Option",
                "id": "882c4fbd-d183-5bc6-b4c9-5db5acd9dc98",
                "text": "Prefer not to say",
                "exclusive": true
            }
        ],
        "id": "LR38",
        "text": "Which of the following most accurately describes any life events you have experienced in the last 6 months? Select all that apply.",
        "isMultiselect": true
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "edce7c20-cd14-5a0e-8967-7b9ddfd67902",
                "text": "No, not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "e1d660f1-6645-5b81-a34e-fefc52e9d032",
                "text": "I think about it sometimes, but it is not negatively affecting my wellbeing",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "cc006783-1c90-5563-b82b-27569e53841b",
                "text": "I think about it quite a lot and it is negatively affecting my wellbeing",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "5f86c500-1e25-5d62-b1e2-c5eb2f1a303d",
                "text": "I think about it all the time and it is severely affecting my wellbeing",
                "value": 1
            }
        ],
        "id": "AD-1",
        "text": "Do you think about the stressful experience or life event all the time in such a way that it negatively affects your wellbeing?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "31a917bd-12c0-5383-a1ec-39d93cde0f4a",
                "text": "Never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "cfd73f84-24e9-535b-ada3-99cb5889fdeb",
                "text": "Rarely",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "146614a4-8f78-5b23-9023-00289d053afe",
                "text": "Sometimes",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "20477c57-d15a-5aa2-8dd0-fc9d6e1f06e3",
                "text": "Often",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "891529c7-6886-545f-a95f-0170801469c4",
                "text": "Always",
                "value": 1
            }
        ],
        "id": "AD-2",
        "text": "Do you find it difficult to concentrate since the stressful experience or life event?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "112416fc-c866-5dfa-aa0d-a1fbb099631b",
                "text": "Never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "84a7ab1a-66e3-555f-a9fb-f5d2052e62c5",
                "text": "Rarely",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "ce4ee6e1-3416-5740-a7b4-c0e48c96d697",
                "text": "Sometimes",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "9494d279-dd58-5706-b368-d083f8e96425",
                "text": "Often",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "e6ce6121-8966-5331-9de6-225c495a82f4",
                "text": "Always",
                "value": 1
            }
        ],
        "id": "AD-3",
        "text": "Are you constantly reminded of the stressful experience or life event and feel like you can't do anything to stop thinking about it?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "2e503b7c-e5fe-5c3a-b406-1ed4a20a0403",
                "text": "Never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "52680975-884a-5bef-9738-98b9d8d16f7a",
                "text": "Rarely",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "fb98be42-399a-57a7-a353-1581ef3b84c7",
                "text": "Sometimes",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "dc26ea00-568b-5208-bb47-a3a3167b2219",
                "text": "Often",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "0cec152e-1e7f-5ba8-ab55-4d35a78cf0ea",
                "text": "Always",
                "value": 1
            }
        ],
        "id": "AD-4",
        "text": "Since the stressful experience or life event, do you feel like you are not able to carry out everyday tasks, such as working or studying, cooking or cleaning?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "377912ba-72cd-571f-acee-44c2bc8b2363",
                "text": "No, I seem to be reacting similarly to other people",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "9d918248-bec6-57d3-bf94-b0f54531d598",
                "text": "It seems to be influencing me slightly more negatively than other people",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "614d3969-b25d-5bd8-84b9-d168fe0c4e43",
                "text": "It is definitely influencing me more negatively than other people",
                "value": 1
            }
        ],
        "id": "AD-5",
        "text": "Do you feel that the stressful experience or life event is more distressing for you than for other people?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "25fc9c0f-e544-51bb-952c-3a91b65976e5",
                "text": "Less than 3 days ago",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "f3d8a43a-1eb9-5222-ab39-e3c1b1f5f520",
                "text": "Within the last week",
                "value": 0.2
            },
            {
                "__typename": "Option",
                "id": "2ea3b72d-3412-5908-b73d-391d71782dd6",
                "text": "Within the last month",
                "value": 0.4
            },
            {
                "__typename": "Option",
                "id": "6d57f118-e094-52e4-8258-b0b28f3c5114",
                "text": "Within the last 3 months",
                "value": 0.6
            },
            {
                "__typename": "Option",
                "id": "637a6aa3-0919-5f8f-a871-3549131afcbf",
                "text": "Within the last 6 months",
                "value": 0.8
            },
            {
                "__typename": "Option",
                "id": "03859405-00f6-5f74-bf51-212297d409fa",
                "text": "More than 6 months ago",
                "value": 1
            }
        ],
        "id": "AD-6",
        "text": "How long ago did the stressful experience or life event occur? If it is ongoing, please indicate when it started.",
        "isMultiselect": false
    },
    {
        "__typename": "FreeQuestion",
        "id": "AD-7",
        "text": "How long has the stressful experience or life event been affecting you negatively?",
        "unit": censeo_1.Unit.DurationHours
    }
];
exports.aspects = {
    "adj_ce": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "ad_preoccupation"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "ad_failure_to_adapt"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "ad_impairment"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "ad_onset"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "ad_duration"
                }
            }
        ],
        "anyToAll": 1
    },
    "ad_preoccupation": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "AD-1",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "edce7c20-cd14-5a0e-8967-7b9ddfd67902": 0.0066928509242848554,
                            "e1d660f1-6645-5b81-a34e-fefc52e9d032": 0.15446526508353475,
                            "cc006783-1c90-5563-b82b-27569e53841b": 0.8320183851339246,
                            "5f86c500-1e25-5d62-b1e2-c5eb2f1a303d": 0.9933071490757153
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "AD-3",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "112416fc-c866-5dfa-aa0d-a1fbb099631b": 0.0066928509242848554,
                            "84a7ab1a-66e3-555f-a9fb-f5d2052e62c5": 0.07585818002124355,
                            "ce4ee6e1-3416-5740-a7b4-c0e48c96d697": 0.5,
                            "9494d279-dd58-5706-b368-d083f8e96425": 0.9241418199787566,
                            "e6ce6121-8966-5331-9de6-225c495a82f4": 0.9933071490757153
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.5
    },
    "ad_failure_to_adapt": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "AD-2",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "31a917bd-12c0-5383-a1ec-39d93cde0f4a": 0.0066928509242848554,
                            "cfd73f84-24e9-535b-ada3-99cb5889fdeb": 0.07585818002124355,
                            "146614a4-8f78-5b23-9023-00289d053afe": 0.5,
                            "20477c57-d15a-5aa2-8dd0-fc9d6e1f06e3": 0.9241418199787566,
                            "891529c7-6886-545f-a95f-0170801469c4": 0.9933071490757153
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "AD-5",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "377912ba-72cd-571f-acee-44c2bc8b2363": 0.0066928509242848554,
                            "9d918248-bec6-57d3-bf94-b0f54531d598": 0.5,
                            "614d3969-b25d-5bd8-84b9-d168fe0c4e43": 0.9933071490757153
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "ad_onset": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "AD-6",
            "options": {
                "type": "severities",
                "severities": {
                    "25fc9c0f-e544-51bb-952c-3a91b65976e5": 0.9975273768433652,
                    "f3d8a43a-1eb9-5222-ab39-e3c1b1f5f520": 0.9820137900379085,
                    "2ea3b72d-3412-5908-b73d-391d71782dd6": 0.8807970779778824,
                    "6d57f118-e094-52e4-8258-b0b28f3c5114": 0.5,
                    "637a6aa3-0919-5f8f-a871-3549131afcbf": 0.11920292202211746,
                    "03859405-00f6-5f74-bf51-212297d409fa": 0.01798620996209155
                }
            }
        }
    },
    "ad_duration": {
        "type": "input",
        "signal": {
            "type": "SpecificNumberInput",
            "questionId": "AD-7",
            "input": 180,
            "operator": "<="
        }
    },
    "ad_impairment": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "AD-4",
            "options": {
                "type": "severities",
                "severities": {
                    "2e503b7c-e5fe-5c3a-b406-1ed4a20a0403": 0.0066928509242848554,
                    "52680975-884a-5bef-9738-98b9d8d16f7a": 0.07585818002124355,
                    "fb98be42-399a-57a7-a353-1581ef3b84c7": 0.5,
                    "dc26ea00-568b-5208-bb47-a3a3167b2219": 0.9241418199787566,
                    "0cec152e-1e7f-5ba8-ab55-4d35a78cf0ea": 0.9933071490757153
                }
            }
        }
    },
    "LE_jobloss": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "LR38",
            "options": {
                "type": "severities",
                "severities": {
                    "4864aca1-818d-59ee-8da9-ff7a4e9c2b63": 1
                }
            }
        }
    },
    "LE_divorce": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "LR38",
            "options": {
                "type": "severities",
                "severities": {
                    "5cd60e24-de33-56f8-8b04-13d2e683ec0b": 1
                }
            }
        }
    },
    "LE_illnessother": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "LR38",
            "options": {
                "type": "severities",
                "severities": {
                    "26ca934e-26fd-54b7-ab52-c162ddffbe00": 1
                }
            }
        }
    },
    "LE_corona": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "LR38",
            "options": {
                "type": "severities",
                "severities": {
                    "d6e1b247-4aa2-55fd-bce7-42c7fa8cea51": 1
                }
            }
        }
    },
    "LE_children": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "LR38",
            "options": {
                "type": "severities",
                "severities": {
                    "93eedc10-44cc-5759-8cea-df93ae21e4d3": 1
                }
            }
        }
    },
    "LE_illnessself": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "LR38",
            "options": {
                "type": "severities",
                "severities": {
                    "6c237476-6277-530e-bc2a-fe3482245062": 1
                }
            }
        }
    },
    "LE_none2": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "LR38",
            "options": {
                "type": "severities",
                "severities": {
                    "e632ac5a-ad6f-58ad-8e51-f5e957428c4b": 1
                }
            }
        }
    },
    "LE_prefernot": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "LR38",
            "options": {
                "type": "severities",
                "severities": {
                    "882c4fbd-d183-5bc6-b4c9-5db5acd9dc98": 1
                }
            }
        }
    },
    "and[not(LR37=0), not(LR37=0.5)]": {
        "type": "merge",
        "wires": [
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AnsweredOption",
                        "questionId": "LR37",
                        "options": {
                            "type": "predicates",
                            "predicates": {
                                "ab920687-add2-51b6-a5d1-ae8b69095749": true,
                                "d56877af-e8cf-54d7-9156-2130960436d9": false,
                                "2f54c966-c1a2-57ac-82af-d3724cf9ac8f": false
                            }
                        }
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AnsweredOption",
                        "questionId": "LR37",
                        "options": {
                            "type": "predicates",
                            "predicates": {
                                "ab920687-add2-51b6-a5d1-ae8b69095749": false,
                                "d56877af-e8cf-54d7-9156-2130960436d9": false,
                                "2f54c966-c1a2-57ac-82af-d3724cf9ac8f": true
                            }
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    }
};
exports.tests = {
    "adj_ce": [
        {
            "name": "screened user: Z3RPNEO aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "2f54c966-c1a2-57ac-82af-d3724cf9ac8f"
                    ]
                }
            }
        },
        {
            "name": "screened user: NEL6L3V aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: K39ZL3L aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AD-1": {
                    "questionId": "AD-1",
                    "chosenOptionIds": [
                        "e1d660f1-6645-5b81-a34e-fefc52e9d032"
                    ]
                },
                "AD-2": {
                    "questionId": "AD-2",
                    "chosenOptionIds": [
                        "31a917bd-12c0-5383-a1ec-39d93cde0f4a"
                    ]
                },
                "AD-3": {
                    "questionId": "AD-3",
                    "chosenOptionIds": [
                        "112416fc-c866-5dfa-aa0d-a1fbb099631b"
                    ]
                },
                "AD-4": {
                    "questionId": "AD-4",
                    "chosenOptionIds": [
                        "2e503b7c-e5fe-5c3a-b406-1ed4a20a0403"
                    ]
                },
                "AD-5": {
                    "questionId": "AD-5",
                    "chosenOptionIds": [
                        "377912ba-72cd-571f-acee-44c2bc8b2363"
                    ]
                },
                "AD-6": {
                    "questionId": "AD-6",
                    "chosenOptionIds": [
                        "03859405-00f6-5f74-bf51-212297d409fa"
                    ]
                },
                "AD-7": {
                    "questionId": "AD-7",
                    "freeValue": 90
                },
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "d56877af-e8cf-54d7-9156-2130960436d9"
                    ]
                },
                "LR38": {
                    "questionId": "LR38",
                    "chosenOptionIds": [
                        "e632ac5a-ad6f-58ad-8e51-f5e957428c4b"
                    ]
                }
            }
        },
        {
            "name": "screened user: 6PE670L aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: 50MRME6 aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: O3PMG3W aspect: adj_ce should be positive",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "AD-1": {
                    "questionId": "AD-1",
                    "chosenOptionIds": [
                        "5f86c500-1e25-5d62-b1e2-c5eb2f1a303d"
                    ]
                },
                "AD-2": {
                    "questionId": "AD-2",
                    "chosenOptionIds": [
                        "891529c7-6886-545f-a95f-0170801469c4"
                    ]
                },
                "AD-3": {
                    "questionId": "AD-3",
                    "chosenOptionIds": [
                        "e6ce6121-8966-5331-9de6-225c495a82f4"
                    ]
                },
                "AD-4": {
                    "questionId": "AD-4",
                    "chosenOptionIds": [
                        "0cec152e-1e7f-5ba8-ab55-4d35a78cf0ea"
                    ]
                },
                "AD-5": {
                    "questionId": "AD-5",
                    "chosenOptionIds": [
                        "614d3969-b25d-5bd8-84b9-d168fe0c4e43"
                    ]
                },
                "AD-6": {
                    "questionId": "AD-6",
                    "chosenOptionIds": [
                        "2ea3b72d-3412-5908-b73d-391d71782dd6"
                    ]
                },
                "AD-7": {
                    "questionId": "AD-7",
                    "freeValue": 60
                },
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "d56877af-e8cf-54d7-9156-2130960436d9"
                    ]
                },
                "LR38": {
                    "questionId": "LR38",
                    "chosenOptionIds": [
                        "5cd60e24-de33-56f8-8b04-13d2e683ec0b"
                    ]
                }
            }
        },
        {
            "name": "screened user: Y32D838 aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AD-1": {
                    "questionId": "AD-1",
                    "chosenOptionIds": [
                        "cc006783-1c90-5563-b82b-27569e53841b"
                    ]
                },
                "AD-2": {
                    "questionId": "AD-2",
                    "chosenOptionIds": [
                        "146614a4-8f78-5b23-9023-00289d053afe"
                    ]
                },
                "AD-3": {
                    "questionId": "AD-3",
                    "chosenOptionIds": [
                        "ce4ee6e1-3416-5740-a7b4-c0e48c96d697"
                    ]
                },
                "AD-4": {
                    "questionId": "AD-4",
                    "chosenOptionIds": [
                        "fb98be42-399a-57a7-a353-1581ef3b84c7"
                    ]
                },
                "AD-5": {
                    "questionId": "AD-5",
                    "chosenOptionIds": [
                        "9d918248-bec6-57d3-bf94-b0f54531d598"
                    ]
                },
                "AD-6": {
                    "questionId": "AD-6",
                    "chosenOptionIds": [
                        "25fc9c0f-e544-51bb-952c-3a91b65976e5"
                    ]
                },
                "AD-7": {
                    "questionId": "AD-7",
                    "freeValue": 730
                },
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "d56877af-e8cf-54d7-9156-2130960436d9"
                    ]
                },
                "LR38": {
                    "questionId": "LR38",
                    "chosenOptionIds": [
                        "93eedc10-44cc-5759-8cea-df93ae21e4d3"
                    ]
                }
            }
        },
        {
            "name": "screened user: XE7OXEL aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "2f54c966-c1a2-57ac-82af-d3724cf9ac8f"
                    ]
                }
            }
        },
        {
            "name": "screened user: 63YX60R aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: 10QV8EP aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AD-1": {
                    "questionId": "AD-1",
                    "chosenOptionIds": [
                        "5f86c500-1e25-5d62-b1e2-c5eb2f1a303d"
                    ]
                },
                "AD-2": {
                    "questionId": "AD-2",
                    "chosenOptionIds": [
                        "891529c7-6886-545f-a95f-0170801469c4"
                    ]
                },
                "AD-3": {
                    "questionId": "AD-3",
                    "chosenOptionIds": [
                        "e6ce6121-8966-5331-9de6-225c495a82f4"
                    ]
                },
                "AD-4": {
                    "questionId": "AD-4",
                    "chosenOptionIds": [
                        "dc26ea00-568b-5208-bb47-a3a3167b2219"
                    ]
                },
                "AD-5": {
                    "questionId": "AD-5",
                    "chosenOptionIds": [
                        "614d3969-b25d-5bd8-84b9-d168fe0c4e43"
                    ]
                },
                "AD-6": {
                    "questionId": "AD-6",
                    "chosenOptionIds": [
                        "03859405-00f6-5f74-bf51-212297d409fa"
                    ]
                },
                "AD-7": {
                    "questionId": "AD-7",
                    "freeValue": 6205
                },
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "d56877af-e8cf-54d7-9156-2130960436d9"
                    ]
                },
                "LR38": {
                    "questionId": "LR38",
                    "chosenOptionIds": [
                        "26ca934e-26fd-54b7-ab52-c162ddffbe00",
                        "6c237476-6277-530e-bc2a-fe3482245062"
                    ]
                }
            }
        },
        {
            "name": "screened user: 6EDLX3M aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AD-1": {
                    "questionId": "AD-1",
                    "chosenOptionIds": [
                        "cc006783-1c90-5563-b82b-27569e53841b"
                    ]
                },
                "AD-2": {
                    "questionId": "AD-2",
                    "chosenOptionIds": [
                        "146614a4-8f78-5b23-9023-00289d053afe"
                    ]
                },
                "AD-3": {
                    "questionId": "AD-3",
                    "chosenOptionIds": [
                        "ce4ee6e1-3416-5740-a7b4-c0e48c96d697"
                    ]
                },
                "AD-4": {
                    "questionId": "AD-4",
                    "chosenOptionIds": [
                        "52680975-884a-5bef-9738-98b9d8d16f7a"
                    ]
                },
                "AD-5": {
                    "questionId": "AD-5",
                    "chosenOptionIds": [
                        "9d918248-bec6-57d3-bf94-b0f54531d598"
                    ]
                },
                "AD-6": {
                    "questionId": "AD-6",
                    "chosenOptionIds": [
                        "f3d8a43a-1eb9-5222-ab39-e3c1b1f5f520"
                    ]
                },
                "AD-7": {
                    "questionId": "AD-7",
                    "freeValue": 7
                },
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "d56877af-e8cf-54d7-9156-2130960436d9"
                    ]
                },
                "LR38": {
                    "questionId": "LR38",
                    "chosenOptionIds": [
                        "e632ac5a-ad6f-58ad-8e51-f5e957428c4b"
                    ]
                }
            }
        },
        {
            "name": "screened user: O0KG5E1 aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AD-1": {
                    "questionId": "AD-1",
                    "chosenOptionIds": [
                        "5f86c500-1e25-5d62-b1e2-c5eb2f1a303d"
                    ]
                },
                "AD-2": {
                    "questionId": "AD-2",
                    "chosenOptionIds": [
                        "cfd73f84-24e9-535b-ada3-99cb5889fdeb"
                    ]
                },
                "AD-3": {
                    "questionId": "AD-3",
                    "chosenOptionIds": [
                        "ce4ee6e1-3416-5740-a7b4-c0e48c96d697"
                    ]
                },
                "AD-4": {
                    "questionId": "AD-4",
                    "chosenOptionIds": [
                        "52680975-884a-5bef-9738-98b9d8d16f7a"
                    ]
                },
                "AD-5": {
                    "questionId": "AD-5",
                    "chosenOptionIds": [
                        "9d918248-bec6-57d3-bf94-b0f54531d598"
                    ]
                },
                "AD-6": {
                    "questionId": "AD-6",
                    "chosenOptionIds": [
                        "637a6aa3-0919-5f8f-a871-3549131afcbf"
                    ]
                },
                "AD-7": {
                    "questionId": "AD-7",
                    "freeValue": 180
                },
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "d56877af-e8cf-54d7-9156-2130960436d9"
                    ]
                },
                "LR38": {
                    "questionId": "LR38",
                    "chosenOptionIds": [
                        "5cd60e24-de33-56f8-8b04-13d2e683ec0b"
                    ]
                }
            }
        },
        {
            "name": "screened user: 6ED9M0M aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z3R2NEO aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: NELRPEV aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AD-1": {
                    "questionId": "AD-1",
                    "chosenOptionIds": [
                        "cc006783-1c90-5563-b82b-27569e53841b"
                    ]
                },
                "AD-2": {
                    "questionId": "AD-2",
                    "chosenOptionIds": [
                        "146614a4-8f78-5b23-9023-00289d053afe"
                    ]
                },
                "AD-3": {
                    "questionId": "AD-3",
                    "chosenOptionIds": [
                        "ce4ee6e1-3416-5740-a7b4-c0e48c96d697"
                    ]
                },
                "AD-4": {
                    "questionId": "AD-4",
                    "chosenOptionIds": [
                        "0cec152e-1e7f-5ba8-ab55-4d35a78cf0ea"
                    ]
                },
                "AD-5": {
                    "questionId": "AD-5",
                    "chosenOptionIds": [
                        "614d3969-b25d-5bd8-84b9-d168fe0c4e43"
                    ]
                },
                "AD-6": {
                    "questionId": "AD-6",
                    "chosenOptionIds": [
                        "03859405-00f6-5f74-bf51-212297d409fa"
                    ]
                },
                "AD-7": {
                    "questionId": "AD-7",
                    "freeValue": 3650
                },
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "d56877af-e8cf-54d7-9156-2130960436d9"
                    ]
                },
                "LR38": {
                    "questionId": "LR38",
                    "chosenOptionIds": [
                        "4864aca1-818d-59ee-8da9-ff7a4e9c2b63",
                        "6c237476-6277-530e-bc2a-fe3482245062"
                    ]
                }
            }
        },
        {
            "name": "screened user: 73ZQN3R aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: REO9232 aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: O0KZ701 aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AD-1": {
                    "questionId": "AD-1",
                    "chosenOptionIds": [
                        "5f86c500-1e25-5d62-b1e2-c5eb2f1a303d"
                    ]
                },
                "AD-2": {
                    "questionId": "AD-2",
                    "chosenOptionIds": [
                        "891529c7-6886-545f-a95f-0170801469c4"
                    ]
                },
                "AD-3": {
                    "questionId": "AD-3",
                    "chosenOptionIds": [
                        "9494d279-dd58-5706-b368-d083f8e96425"
                    ]
                },
                "AD-4": {
                    "questionId": "AD-4",
                    "chosenOptionIds": [
                        "0cec152e-1e7f-5ba8-ab55-4d35a78cf0ea"
                    ]
                },
                "AD-5": {
                    "questionId": "AD-5",
                    "chosenOptionIds": [
                        "614d3969-b25d-5bd8-84b9-d168fe0c4e43"
                    ]
                },
                "AD-6": {
                    "questionId": "AD-6",
                    "chosenOptionIds": [
                        "03859405-00f6-5f74-bf51-212297d409fa"
                    ]
                },
                "AD-7": {
                    "questionId": "AD-7",
                    "freeValue": 2190
                },
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "d56877af-e8cf-54d7-9156-2130960436d9"
                    ]
                },
                "LR38": {
                    "questionId": "LR38",
                    "chosenOptionIds": [
                        "e632ac5a-ad6f-58ad-8e51-f5e957428c4b"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0N6P0Q aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AD-1": {
                    "questionId": "AD-1",
                    "chosenOptionIds": [
                        "cc006783-1c90-5563-b82b-27569e53841b"
                    ]
                },
                "AD-2": {
                    "questionId": "AD-2",
                    "chosenOptionIds": [
                        "20477c57-d15a-5aa2-8dd0-fc9d6e1f06e3"
                    ]
                },
                "AD-3": {
                    "questionId": "AD-3",
                    "chosenOptionIds": [
                        "9494d279-dd58-5706-b368-d083f8e96425"
                    ]
                },
                "AD-4": {
                    "questionId": "AD-4",
                    "chosenOptionIds": [
                        "dc26ea00-568b-5208-bb47-a3a3167b2219"
                    ]
                },
                "AD-5": {
                    "questionId": "AD-5",
                    "chosenOptionIds": [
                        "614d3969-b25d-5bd8-84b9-d168fe0c4e43"
                    ]
                },
                "AD-6": {
                    "questionId": "AD-6",
                    "chosenOptionIds": [
                        "637a6aa3-0919-5f8f-a871-3549131afcbf"
                    ]
                },
                "AD-7": {
                    "questionId": "AD-7",
                    "freeValue": 540
                },
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "d56877af-e8cf-54d7-9156-2130960436d9"
                    ]
                },
                "LR38": {
                    "questionId": "LR38",
                    "chosenOptionIds": [
                        "5cd60e24-de33-56f8-8b04-13d2e683ec0b"
                    ]
                }
            }
        },
        {
            "name": "screened user: 2E148ED aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: ME8G2EW aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: PE6LM0L aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: K39ML0L aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: XE7VX0L aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AD-1": {
                    "questionId": "AD-1",
                    "chosenOptionIds": [
                        "cc006783-1c90-5563-b82b-27569e53841b"
                    ]
                },
                "AD-2": {
                    "questionId": "AD-2",
                    "chosenOptionIds": [
                        "20477c57-d15a-5aa2-8dd0-fc9d6e1f06e3"
                    ]
                },
                "AD-3": {
                    "questionId": "AD-3",
                    "chosenOptionIds": [
                        "9494d279-dd58-5706-b368-d083f8e96425"
                    ]
                },
                "AD-4": {
                    "questionId": "AD-4",
                    "chosenOptionIds": [
                        "fb98be42-399a-57a7-a353-1581ef3b84c7"
                    ]
                },
                "AD-5": {
                    "questionId": "AD-5",
                    "chosenOptionIds": [
                        "9d918248-bec6-57d3-bf94-b0f54531d598"
                    ]
                },
                "AD-6": {
                    "questionId": "AD-6",
                    "chosenOptionIds": [
                        "03859405-00f6-5f74-bf51-212297d409fa"
                    ]
                },
                "AD-7": {
                    "questionId": "AD-7",
                    "freeValue": 365
                },
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "d56877af-e8cf-54d7-9156-2130960436d9"
                    ]
                },
                "LR38": {
                    "questionId": "LR38",
                    "chosenOptionIds": [
                        "5cd60e24-de33-56f8-8b04-13d2e683ec0b",
                        "6c237476-6277-530e-bc2a-fe3482245062"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z3RD4EO aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: 4EGQQ0P aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: 7EW2K38 aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: 63YR40R aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: Y329ME8 aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: J0VZ63L aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: 73ZJN0R aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: R042O34 aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: J0VRYEL aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: 73ZNN3R aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AD-1": {
                    "questionId": "AD-1",
                    "chosenOptionIds": [
                        "cc006783-1c90-5563-b82b-27569e53841b"
                    ]
                },
                "AD-2": {
                    "questionId": "AD-2",
                    "chosenOptionIds": [
                        "146614a4-8f78-5b23-9023-00289d053afe"
                    ]
                },
                "AD-3": {
                    "questionId": "AD-3",
                    "chosenOptionIds": [
                        "ce4ee6e1-3416-5740-a7b4-c0e48c96d697"
                    ]
                },
                "AD-4": {
                    "questionId": "AD-4",
                    "chosenOptionIds": [
                        "fb98be42-399a-57a7-a353-1581ef3b84c7"
                    ]
                },
                "AD-5": {
                    "questionId": "AD-5",
                    "chosenOptionIds": [
                        "377912ba-72cd-571f-acee-44c2bc8b2363"
                    ]
                },
                "AD-6": {
                    "questionId": "AD-6",
                    "chosenOptionIds": [
                        "03859405-00f6-5f74-bf51-212297d409fa"
                    ]
                },
                "AD-7": {
                    "questionId": "AD-7",
                    "freeValue": 365
                },
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "d56877af-e8cf-54d7-9156-2130960436d9"
                    ]
                },
                "LR38": {
                    "questionId": "LR38",
                    "chosenOptionIds": [
                        "e632ac5a-ad6f-58ad-8e51-f5e957428c4b"
                    ]
                }
            }
        },
        {
            "name": "screened user: ME8D2EW aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0NN50Q aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: 73Z220R aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AD-1": {
                    "questionId": "AD-1",
                    "chosenOptionIds": [
                        "cc006783-1c90-5563-b82b-27569e53841b"
                    ]
                },
                "AD-2": {
                    "questionId": "AD-2",
                    "chosenOptionIds": [
                        "891529c7-6886-545f-a95f-0170801469c4"
                    ]
                },
                "AD-3": {
                    "questionId": "AD-3",
                    "chosenOptionIds": [
                        "9494d279-dd58-5706-b368-d083f8e96425"
                    ]
                },
                "AD-4": {
                    "questionId": "AD-4",
                    "chosenOptionIds": [
                        "fb98be42-399a-57a7-a353-1581ef3b84c7"
                    ]
                },
                "AD-5": {
                    "questionId": "AD-5",
                    "chosenOptionIds": [
                        "614d3969-b25d-5bd8-84b9-d168fe0c4e43"
                    ]
                },
                "AD-6": {
                    "questionId": "AD-6",
                    "chosenOptionIds": [
                        "03859405-00f6-5f74-bf51-212297d409fa"
                    ]
                },
                "AD-7": {
                    "questionId": "AD-7",
                    "freeValue": 3650
                },
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "d56877af-e8cf-54d7-9156-2130960436d9"
                    ]
                },
                "LR38": {
                    "questionId": "LR38",
                    "chosenOptionIds": [
                        "6c237476-6277-530e-bc2a-fe3482245062"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0N4J3Q aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: 10QKZ0P aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: XE7ZR3L aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0N5PEQ aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AD-1": {
                    "questionId": "AD-1",
                    "chosenOptionIds": [
                        "5f86c500-1e25-5d62-b1e2-c5eb2f1a303d"
                    ]
                },
                "AD-2": {
                    "questionId": "AD-2",
                    "chosenOptionIds": [
                        "891529c7-6886-545f-a95f-0170801469c4"
                    ]
                },
                "AD-3": {
                    "questionId": "AD-3",
                    "chosenOptionIds": [
                        "e6ce6121-8966-5331-9de6-225c495a82f4"
                    ]
                },
                "AD-4": {
                    "questionId": "AD-4",
                    "chosenOptionIds": [
                        "dc26ea00-568b-5208-bb47-a3a3167b2219"
                    ]
                },
                "AD-5": {
                    "questionId": "AD-5",
                    "chosenOptionIds": [
                        "614d3969-b25d-5bd8-84b9-d168fe0c4e43"
                    ]
                },
                "AD-6": {
                    "questionId": "AD-6",
                    "chosenOptionIds": [
                        "03859405-00f6-5f74-bf51-212297d409fa"
                    ]
                },
                "AD-7": {
                    "questionId": "AD-7",
                    "freeValue": 270
                },
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "d56877af-e8cf-54d7-9156-2130960436d9"
                    ]
                },
                "LR38": {
                    "questionId": "LR38",
                    "chosenOptionIds": [
                        "e632ac5a-ad6f-58ad-8e51-f5e957428c4b"
                    ]
                }
            }
        },
        {
            "name": "screened user: 23XKV0N aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AD-1": {
                    "questionId": "AD-1",
                    "chosenOptionIds": [
                        "e1d660f1-6645-5b81-a34e-fefc52e9d032"
                    ]
                },
                "AD-2": {
                    "questionId": "AD-2",
                    "chosenOptionIds": [
                        "146614a4-8f78-5b23-9023-00289d053afe"
                    ]
                },
                "AD-3": {
                    "questionId": "AD-3",
                    "chosenOptionIds": [
                        "ce4ee6e1-3416-5740-a7b4-c0e48c96d697"
                    ]
                },
                "AD-4": {
                    "questionId": "AD-4",
                    "chosenOptionIds": [
                        "52680975-884a-5bef-9738-98b9d8d16f7a"
                    ]
                },
                "AD-5": {
                    "questionId": "AD-5",
                    "chosenOptionIds": [
                        "377912ba-72cd-571f-acee-44c2bc8b2363"
                    ]
                },
                "AD-6": {
                    "questionId": "AD-6",
                    "chosenOptionIds": [
                        "6d57f118-e094-52e4-8258-b0b28f3c5114"
                    ]
                },
                "AD-7": {
                    "questionId": "AD-7",
                    "freeValue": 90
                },
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "d56877af-e8cf-54d7-9156-2130960436d9"
                    ]
                },
                "LR38": {
                    "questionId": "LR38",
                    "chosenOptionIds": [
                        "e632ac5a-ad6f-58ad-8e51-f5e957428c4b"
                    ]
                }
            }
        },
        {
            "name": "screened user: 63YD63R aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AD-1": {
                    "questionId": "AD-1",
                    "chosenOptionIds": [
                        "5f86c500-1e25-5d62-b1e2-c5eb2f1a303d"
                    ]
                },
                "AD-2": {
                    "questionId": "AD-2",
                    "chosenOptionIds": [
                        "891529c7-6886-545f-a95f-0170801469c4"
                    ]
                },
                "AD-3": {
                    "questionId": "AD-3",
                    "chosenOptionIds": [
                        "e6ce6121-8966-5331-9de6-225c495a82f4"
                    ]
                },
                "AD-4": {
                    "questionId": "AD-4",
                    "chosenOptionIds": [
                        "0cec152e-1e7f-5ba8-ab55-4d35a78cf0ea"
                    ]
                },
                "AD-5": {
                    "questionId": "AD-5",
                    "chosenOptionIds": [
                        "377912ba-72cd-571f-acee-44c2bc8b2363"
                    ]
                },
                "AD-6": {
                    "questionId": "AD-6",
                    "chosenOptionIds": [
                        "03859405-00f6-5f74-bf51-212297d409fa"
                    ]
                },
                "AD-7": {
                    "questionId": "AD-7",
                    "freeValue": 7300
                },
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "d56877af-e8cf-54d7-9156-2130960436d9"
                    ]
                },
                "LR38": {
                    "questionId": "LR38",
                    "chosenOptionIds": [
                        "5cd60e24-de33-56f8-8b04-13d2e683ec0b",
                        "d6e1b247-4aa2-55fd-bce7-42c7fa8cea51",
                        "6c237476-6277-530e-bc2a-fe3482245062"
                    ]
                }
            }
        },
        {
            "name": "screened user: ME8PLEW aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: K39V10L aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AD-1": {
                    "questionId": "AD-1",
                    "chosenOptionIds": [
                        "e1d660f1-6645-5b81-a34e-fefc52e9d032"
                    ]
                },
                "AD-2": {
                    "questionId": "AD-2",
                    "chosenOptionIds": [
                        "146614a4-8f78-5b23-9023-00289d053afe"
                    ]
                },
                "AD-3": {
                    "questionId": "AD-3",
                    "chosenOptionIds": [
                        "ce4ee6e1-3416-5740-a7b4-c0e48c96d697"
                    ]
                },
                "AD-4": {
                    "questionId": "AD-4",
                    "chosenOptionIds": [
                        "52680975-884a-5bef-9738-98b9d8d16f7a"
                    ]
                },
                "AD-5": {
                    "questionId": "AD-5",
                    "chosenOptionIds": [
                        "9d918248-bec6-57d3-bf94-b0f54531d598"
                    ]
                },
                "AD-6": {
                    "questionId": "AD-6",
                    "chosenOptionIds": [
                        "637a6aa3-0919-5f8f-a871-3549131afcbf"
                    ]
                },
                "AD-7": {
                    "questionId": "AD-7",
                    "freeValue": 180
                },
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "d56877af-e8cf-54d7-9156-2130960436d9"
                    ]
                },
                "LR38": {
                    "questionId": "LR38",
                    "chosenOptionIds": [
                        "e632ac5a-ad6f-58ad-8e51-f5e957428c4b"
                    ]
                }
            }
        },
        {
            "name": "screened user: O0K1931 aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: J3J1D0Y aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: 10QJ8EP aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: 73ZL2ER aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: O3P66EW aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: O3PGG3W aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AD-1": {
                    "questionId": "AD-1",
                    "chosenOptionIds": [
                        "cc006783-1c90-5563-b82b-27569e53841b"
                    ]
                },
                "AD-2": {
                    "questionId": "AD-2",
                    "chosenOptionIds": [
                        "20477c57-d15a-5aa2-8dd0-fc9d6e1f06e3"
                    ]
                },
                "AD-3": {
                    "questionId": "AD-3",
                    "chosenOptionIds": [
                        "9494d279-dd58-5706-b368-d083f8e96425"
                    ]
                },
                "AD-4": {
                    "questionId": "AD-4",
                    "chosenOptionIds": [
                        "dc26ea00-568b-5208-bb47-a3a3167b2219"
                    ]
                },
                "AD-5": {
                    "questionId": "AD-5",
                    "chosenOptionIds": [
                        "9d918248-bec6-57d3-bf94-b0f54531d598"
                    ]
                },
                "AD-6": {
                    "questionId": "AD-6",
                    "chosenOptionIds": [
                        "637a6aa3-0919-5f8f-a871-3549131afcbf"
                    ]
                },
                "AD-7": {
                    "questionId": "AD-7",
                    "freeValue": 180
                },
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "d56877af-e8cf-54d7-9156-2130960436d9"
                    ]
                },
                "LR38": {
                    "questionId": "LR38",
                    "chosenOptionIds": [
                        "882c4fbd-d183-5bc6-b4c9-5db5acd9dc98"
                    ]
                }
            }
        },
        {
            "name": "screened user: Y32OM08 aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AD-1": {
                    "questionId": "AD-1",
                    "chosenOptionIds": [
                        "e1d660f1-6645-5b81-a34e-fefc52e9d032"
                    ]
                },
                "AD-2": {
                    "questionId": "AD-2",
                    "chosenOptionIds": [
                        "146614a4-8f78-5b23-9023-00289d053afe"
                    ]
                },
                "AD-3": {
                    "questionId": "AD-3",
                    "chosenOptionIds": [
                        "84a7ab1a-66e3-555f-a9fb-f5d2052e62c5"
                    ]
                },
                "AD-4": {
                    "questionId": "AD-4",
                    "chosenOptionIds": [
                        "fb98be42-399a-57a7-a353-1581ef3b84c7"
                    ]
                },
                "AD-5": {
                    "questionId": "AD-5",
                    "chosenOptionIds": [
                        "377912ba-72cd-571f-acee-44c2bc8b2363"
                    ]
                },
                "AD-6": {
                    "questionId": "AD-6",
                    "chosenOptionIds": [
                        "6d57f118-e094-52e4-8258-b0b28f3c5114"
                    ]
                },
                "AD-7": {
                    "questionId": "AD-7",
                    "freeValue": 60
                },
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "d56877af-e8cf-54d7-9156-2130960436d9"
                    ]
                },
                "LR38": {
                    "questionId": "LR38",
                    "chosenOptionIds": [
                        "6c237476-6277-530e-bc2a-fe3482245062"
                    ]
                }
            }
        },
        {
            "name": "screened user: ME84L3W aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0NJ5EQ aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "2f54c966-c1a2-57ac-82af-d3724cf9ac8f"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0NO53Q aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: K392M0L aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AD-1": {
                    "questionId": "AD-1",
                    "chosenOptionIds": [
                        "5f86c500-1e25-5d62-b1e2-c5eb2f1a303d"
                    ]
                },
                "AD-2": {
                    "questionId": "AD-2",
                    "chosenOptionIds": [
                        "20477c57-d15a-5aa2-8dd0-fc9d6e1f06e3"
                    ]
                },
                "AD-3": {
                    "questionId": "AD-3",
                    "chosenOptionIds": [
                        "9494d279-dd58-5706-b368-d083f8e96425"
                    ]
                },
                "AD-4": {
                    "questionId": "AD-4",
                    "chosenOptionIds": [
                        "dc26ea00-568b-5208-bb47-a3a3167b2219"
                    ]
                },
                "AD-5": {
                    "questionId": "AD-5",
                    "chosenOptionIds": [
                        "614d3969-b25d-5bd8-84b9-d168fe0c4e43"
                    ]
                },
                "AD-6": {
                    "questionId": "AD-6",
                    "chosenOptionIds": [
                        "03859405-00f6-5f74-bf51-212297d409fa"
                    ]
                },
                "AD-7": {
                    "questionId": "AD-7",
                    "freeValue": 360
                },
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "d56877af-e8cf-54d7-9156-2130960436d9"
                    ]
                },
                "LR38": {
                    "questionId": "LR38",
                    "chosenOptionIds": [
                        "882c4fbd-d183-5bc6-b4c9-5db5acd9dc98"
                    ]
                }
            }
        },
        {
            "name": "screened user: 6EDJMEM aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: 10Q6R0P aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: O0KPDE1 aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "2f54c966-c1a2-57ac-82af-d3724cf9ac8f"
                    ]
                }
            }
        },
        {
            "name": "screened user: 63YPO3R aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: 23X6V0N aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: 50MQK36 aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0N2PEQ aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "2f54c966-c1a2-57ac-82af-d3724cf9ac8f"
                    ]
                }
            }
        },
        {
            "name": "screened user: J3JO40Y aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AD-1": {
                    "questionId": "AD-1",
                    "chosenOptionIds": [
                        "e1d660f1-6645-5b81-a34e-fefc52e9d032"
                    ]
                },
                "AD-2": {
                    "questionId": "AD-2",
                    "chosenOptionIds": [
                        "146614a4-8f78-5b23-9023-00289d053afe"
                    ]
                },
                "AD-3": {
                    "questionId": "AD-3",
                    "chosenOptionIds": [
                        "ce4ee6e1-3416-5740-a7b4-c0e48c96d697"
                    ]
                },
                "AD-4": {
                    "questionId": "AD-4",
                    "chosenOptionIds": [
                        "52680975-884a-5bef-9738-98b9d8d16f7a"
                    ]
                },
                "AD-5": {
                    "questionId": "AD-5",
                    "chosenOptionIds": [
                        "377912ba-72cd-571f-acee-44c2bc8b2363"
                    ]
                },
                "AD-6": {
                    "questionId": "AD-6",
                    "chosenOptionIds": [
                        "03859405-00f6-5f74-bf51-212297d409fa"
                    ]
                },
                "AD-7": {
                    "questionId": "AD-7",
                    "freeValue": 270
                },
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "d56877af-e8cf-54d7-9156-2130960436d9"
                    ]
                },
                "LR38": {
                    "questionId": "LR38",
                    "chosenOptionIds": [
                        "5cd60e24-de33-56f8-8b04-13d2e683ec0b"
                    ]
                }
            }
        },
        {
            "name": "screened user: 4EGGGEP aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: 2E1JL0D aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: R04JV34 aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: 7EWGK38 aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AD-1": {
                    "questionId": "AD-1",
                    "chosenOptionIds": [
                        "cc006783-1c90-5563-b82b-27569e53841b"
                    ]
                },
                "AD-2": {
                    "questionId": "AD-2",
                    "chosenOptionIds": [
                        "146614a4-8f78-5b23-9023-00289d053afe"
                    ]
                },
                "AD-3": {
                    "questionId": "AD-3",
                    "chosenOptionIds": [
                        "ce4ee6e1-3416-5740-a7b4-c0e48c96d697"
                    ]
                },
                "AD-4": {
                    "questionId": "AD-4",
                    "chosenOptionIds": [
                        "dc26ea00-568b-5208-bb47-a3a3167b2219"
                    ]
                },
                "AD-5": {
                    "questionId": "AD-5",
                    "chosenOptionIds": [
                        "9d918248-bec6-57d3-bf94-b0f54531d598"
                    ]
                },
                "AD-6": {
                    "questionId": "AD-6",
                    "chosenOptionIds": [
                        "03859405-00f6-5f74-bf51-212297d409fa"
                    ]
                },
                "AD-7": {
                    "questionId": "AD-7",
                    "freeValue": 570
                },
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "d56877af-e8cf-54d7-9156-2130960436d9"
                    ]
                },
                "LR38": {
                    "questionId": "LR38",
                    "chosenOptionIds": [
                        "6c237476-6277-530e-bc2a-fe3482245062"
                    ]
                }
            }
        },
        {
            "name": "screened user: 23X4V3N aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z35X10O aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AD-1": {
                    "questionId": "AD-1",
                    "chosenOptionIds": [
                        "5f86c500-1e25-5d62-b1e2-c5eb2f1a303d"
                    ]
                },
                "AD-2": {
                    "questionId": "AD-2",
                    "chosenOptionIds": [
                        "891529c7-6886-545f-a95f-0170801469c4"
                    ]
                },
                "AD-3": {
                    "questionId": "AD-3",
                    "chosenOptionIds": [
                        "e6ce6121-8966-5331-9de6-225c495a82f4"
                    ]
                },
                "AD-4": {
                    "questionId": "AD-4",
                    "chosenOptionIds": [
                        "52680975-884a-5bef-9738-98b9d8d16f7a"
                    ]
                },
                "AD-5": {
                    "questionId": "AD-5",
                    "chosenOptionIds": [
                        "9d918248-bec6-57d3-bf94-b0f54531d598"
                    ]
                },
                "AD-6": {
                    "questionId": "AD-6",
                    "chosenOptionIds": [
                        "03859405-00f6-5f74-bf51-212297d409fa"
                    ]
                },
                "AD-7": {
                    "questionId": "AD-7",
                    "freeValue": 1095
                },
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "d56877af-e8cf-54d7-9156-2130960436d9"
                    ]
                },
                "LR38": {
                    "questionId": "LR38",
                    "chosenOptionIds": [
                        "e632ac5a-ad6f-58ad-8e51-f5e957428c4b"
                    ]
                }
            }
        },
        {
            "name": "screened user: O0K9D01 aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: 23XP2EN aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AD-1": {
                    "questionId": "AD-1",
                    "chosenOptionIds": [
                        "cc006783-1c90-5563-b82b-27569e53841b"
                    ]
                },
                "AD-2": {
                    "questionId": "AD-2",
                    "chosenOptionIds": [
                        "20477c57-d15a-5aa2-8dd0-fc9d6e1f06e3"
                    ]
                },
                "AD-3": {
                    "questionId": "AD-3",
                    "chosenOptionIds": [
                        "9494d279-dd58-5706-b368-d083f8e96425"
                    ]
                },
                "AD-4": {
                    "questionId": "AD-4",
                    "chosenOptionIds": [
                        "fb98be42-399a-57a7-a353-1581ef3b84c7"
                    ]
                },
                "AD-5": {
                    "questionId": "AD-5",
                    "chosenOptionIds": [
                        "614d3969-b25d-5bd8-84b9-d168fe0c4e43"
                    ]
                },
                "AD-6": {
                    "questionId": "AD-6",
                    "chosenOptionIds": [
                        "637a6aa3-0919-5f8f-a871-3549131afcbf"
                    ]
                },
                "AD-7": {
                    "questionId": "AD-7",
                    "freeValue": 3650
                },
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "d56877af-e8cf-54d7-9156-2130960436d9"
                    ]
                },
                "LR38": {
                    "questionId": "LR38",
                    "chosenOptionIds": [
                        "e632ac5a-ad6f-58ad-8e51-f5e957428c4b"
                    ]
                }
            }
        },
        {
            "name": "screened user: 4EGLK3P aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: NEL9J3V aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: PE694EL aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: K399M3L aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AD-1": {
                    "questionId": "AD-1",
                    "chosenOptionIds": [
                        "5f86c500-1e25-5d62-b1e2-c5eb2f1a303d"
                    ]
                },
                "AD-2": {
                    "questionId": "AD-2",
                    "chosenOptionIds": [
                        "146614a4-8f78-5b23-9023-00289d053afe"
                    ]
                },
                "AD-3": {
                    "questionId": "AD-3",
                    "chosenOptionIds": [
                        "9494d279-dd58-5706-b368-d083f8e96425"
                    ]
                },
                "AD-4": {
                    "questionId": "AD-4",
                    "chosenOptionIds": [
                        "fb98be42-399a-57a7-a353-1581ef3b84c7"
                    ]
                },
                "AD-5": {
                    "questionId": "AD-5",
                    "chosenOptionIds": [
                        "614d3969-b25d-5bd8-84b9-d168fe0c4e43"
                    ]
                },
                "AD-6": {
                    "questionId": "AD-6",
                    "chosenOptionIds": [
                        "03859405-00f6-5f74-bf51-212297d409fa"
                    ]
                },
                "AD-7": {
                    "questionId": "AD-7",
                    "freeValue": 450
                },
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "d56877af-e8cf-54d7-9156-2130960436d9"
                    ]
                },
                "LR38": {
                    "questionId": "LR38",
                    "chosenOptionIds": [
                        "e632ac5a-ad6f-58ad-8e51-f5e957428c4b"
                    ]
                }
            }
        },
        {
            "name": "screened user: 50MGW06 aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AD-1": {
                    "questionId": "AD-1",
                    "chosenOptionIds": [
                        "cc006783-1c90-5563-b82b-27569e53841b"
                    ]
                },
                "AD-2": {
                    "questionId": "AD-2",
                    "chosenOptionIds": [
                        "20477c57-d15a-5aa2-8dd0-fc9d6e1f06e3"
                    ]
                },
                "AD-3": {
                    "questionId": "AD-3",
                    "chosenOptionIds": [
                        "9494d279-dd58-5706-b368-d083f8e96425"
                    ]
                },
                "AD-4": {
                    "questionId": "AD-4",
                    "chosenOptionIds": [
                        "fb98be42-399a-57a7-a353-1581ef3b84c7"
                    ]
                },
                "AD-5": {
                    "questionId": "AD-5",
                    "chosenOptionIds": [
                        "9d918248-bec6-57d3-bf94-b0f54531d598"
                    ]
                },
                "AD-6": {
                    "questionId": "AD-6",
                    "chosenOptionIds": [
                        "6d57f118-e094-52e4-8258-b0b28f3c5114"
                    ]
                },
                "AD-7": {
                    "questionId": "AD-7",
                    "freeValue": 3650
                },
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "d56877af-e8cf-54d7-9156-2130960436d9"
                    ]
                },
                "LR38": {
                    "questionId": "LR38",
                    "chosenOptionIds": [
                        "6c237476-6277-530e-bc2a-fe3482245062"
                    ]
                }
            }
        },
        {
            "name": "screened user: J0VGM0L aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AD-1": {
                    "questionId": "AD-1",
                    "chosenOptionIds": [
                        "cc006783-1c90-5563-b82b-27569e53841b"
                    ]
                },
                "AD-2": {
                    "questionId": "AD-2",
                    "chosenOptionIds": [
                        "20477c57-d15a-5aa2-8dd0-fc9d6e1f06e3"
                    ]
                },
                "AD-3": {
                    "questionId": "AD-3",
                    "chosenOptionIds": [
                        "9494d279-dd58-5706-b368-d083f8e96425"
                    ]
                },
                "AD-4": {
                    "questionId": "AD-4",
                    "chosenOptionIds": [
                        "dc26ea00-568b-5208-bb47-a3a3167b2219"
                    ]
                },
                "AD-5": {
                    "questionId": "AD-5",
                    "chosenOptionIds": [
                        "614d3969-b25d-5bd8-84b9-d168fe0c4e43"
                    ]
                },
                "AD-6": {
                    "questionId": "AD-6",
                    "chosenOptionIds": [
                        "03859405-00f6-5f74-bf51-212297d409fa"
                    ]
                },
                "AD-7": {
                    "questionId": "AD-7",
                    "freeValue": 240
                },
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "d56877af-e8cf-54d7-9156-2130960436d9"
                    ]
                },
                "LR38": {
                    "questionId": "LR38",
                    "chosenOptionIds": [
                        "e632ac5a-ad6f-58ad-8e51-f5e957428c4b"
                    ]
                }
            }
        },
        {
            "name": "screened user: Y32QME8 aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: O3P770W aspect: adj_ce should be positive",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "AD-1": {
                    "questionId": "AD-1",
                    "chosenOptionIds": [
                        "5f86c500-1e25-5d62-b1e2-c5eb2f1a303d"
                    ]
                },
                "AD-2": {
                    "questionId": "AD-2",
                    "chosenOptionIds": [
                        "146614a4-8f78-5b23-9023-00289d053afe"
                    ]
                },
                "AD-3": {
                    "questionId": "AD-3",
                    "chosenOptionIds": [
                        "e6ce6121-8966-5331-9de6-225c495a82f4"
                    ]
                },
                "AD-4": {
                    "questionId": "AD-4",
                    "chosenOptionIds": [
                        "fb98be42-399a-57a7-a353-1581ef3b84c7"
                    ]
                },
                "AD-5": {
                    "questionId": "AD-5",
                    "chosenOptionIds": [
                        "614d3969-b25d-5bd8-84b9-d168fe0c4e43"
                    ]
                },
                "AD-6": {
                    "questionId": "AD-6",
                    "chosenOptionIds": [
                        "2ea3b72d-3412-5908-b73d-391d71782dd6"
                    ]
                },
                "AD-7": {
                    "questionId": "AD-7",
                    "freeValue": 0.20833333333333331
                },
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "d56877af-e8cf-54d7-9156-2130960436d9"
                    ]
                },
                "LR38": {
                    "questionId": "LR38",
                    "chosenOptionIds": [
                        "5cd60e24-de33-56f8-8b04-13d2e683ec0b",
                        "6c237476-6277-530e-bc2a-fe3482245062"
                    ]
                }
            }
        },
        {
            "name": "screened user: 7EWZKE8 aspect: adj_ce should be positive",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "AD-1": {
                    "questionId": "AD-1",
                    "chosenOptionIds": [
                        "cc006783-1c90-5563-b82b-27569e53841b"
                    ]
                },
                "AD-2": {
                    "questionId": "AD-2",
                    "chosenOptionIds": [
                        "20477c57-d15a-5aa2-8dd0-fc9d6e1f06e3"
                    ]
                },
                "AD-3": {
                    "questionId": "AD-3",
                    "chosenOptionIds": [
                        "9494d279-dd58-5706-b368-d083f8e96425"
                    ]
                },
                "AD-4": {
                    "questionId": "AD-4",
                    "chosenOptionIds": [
                        "dc26ea00-568b-5208-bb47-a3a3167b2219"
                    ]
                },
                "AD-5": {
                    "questionId": "AD-5",
                    "chosenOptionIds": [
                        "9d918248-bec6-57d3-bf94-b0f54531d598"
                    ]
                },
                "AD-6": {
                    "questionId": "AD-6",
                    "chosenOptionIds": [
                        "6d57f118-e094-52e4-8258-b0b28f3c5114"
                    ]
                },
                "AD-7": {
                    "questionId": "AD-7",
                    "freeValue": 60
                },
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "d56877af-e8cf-54d7-9156-2130960436d9"
                    ]
                },
                "LR38": {
                    "questionId": "LR38",
                    "chosenOptionIds": [
                        "6c237476-6277-530e-bc2a-fe3482245062"
                    ]
                }
            }
        },
        {
            "name": "screened user: PE6NMEL aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AD-1": {
                    "questionId": "AD-1",
                    "chosenOptionIds": [
                        "cc006783-1c90-5563-b82b-27569e53841b"
                    ]
                },
                "AD-2": {
                    "questionId": "AD-2",
                    "chosenOptionIds": [
                        "20477c57-d15a-5aa2-8dd0-fc9d6e1f06e3"
                    ]
                },
                "AD-3": {
                    "questionId": "AD-3",
                    "chosenOptionIds": [
                        "9494d279-dd58-5706-b368-d083f8e96425"
                    ]
                },
                "AD-4": {
                    "questionId": "AD-4",
                    "chosenOptionIds": [
                        "fb98be42-399a-57a7-a353-1581ef3b84c7"
                    ]
                },
                "AD-5": {
                    "questionId": "AD-5",
                    "chosenOptionIds": [
                        "377912ba-72cd-571f-acee-44c2bc8b2363"
                    ]
                },
                "AD-6": {
                    "questionId": "AD-6",
                    "chosenOptionIds": [
                        "2ea3b72d-3412-5908-b73d-391d71782dd6"
                    ]
                },
                "AD-7": {
                    "questionId": "AD-7",
                    "freeValue": 21
                },
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "d56877af-e8cf-54d7-9156-2130960436d9"
                    ]
                },
                "LR38": {
                    "questionId": "LR38",
                    "chosenOptionIds": [
                        "e632ac5a-ad6f-58ad-8e51-f5e957428c4b"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z356NEO aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: 23XV2EN aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z3R443O aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: PE6Q4EL aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: 6EDW60M aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AD-1": {
                    "questionId": "AD-1",
                    "chosenOptionIds": [
                        "5f86c500-1e25-5d62-b1e2-c5eb2f1a303d"
                    ]
                },
                "AD-2": {
                    "questionId": "AD-2",
                    "chosenOptionIds": [
                        "20477c57-d15a-5aa2-8dd0-fc9d6e1f06e3"
                    ]
                },
                "AD-3": {
                    "questionId": "AD-3",
                    "chosenOptionIds": [
                        "e6ce6121-8966-5331-9de6-225c495a82f4"
                    ]
                },
                "AD-4": {
                    "questionId": "AD-4",
                    "chosenOptionIds": [
                        "fb98be42-399a-57a7-a353-1581ef3b84c7"
                    ]
                },
                "AD-5": {
                    "questionId": "AD-5",
                    "chosenOptionIds": [
                        "377912ba-72cd-571f-acee-44c2bc8b2363"
                    ]
                },
                "AD-6": {
                    "questionId": "AD-6",
                    "chosenOptionIds": [
                        "2ea3b72d-3412-5908-b73d-391d71782dd6"
                    ]
                },
                "AD-7": {
                    "questionId": "AD-7",
                    "freeValue": 3650
                },
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "d56877af-e8cf-54d7-9156-2130960436d9"
                    ]
                },
                "LR38": {
                    "questionId": "LR38",
                    "chosenOptionIds": [
                        "e632ac5a-ad6f-58ad-8e51-f5e957428c4b"
                    ]
                }
            }
        },
        {
            "name": "screened user: J3JV43Y aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: PE6GM3L aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AD-1": {
                    "questionId": "AD-1",
                    "chosenOptionIds": [
                        "5f86c500-1e25-5d62-b1e2-c5eb2f1a303d"
                    ]
                },
                "AD-2": {
                    "questionId": "AD-2",
                    "chosenOptionIds": [
                        "891529c7-6886-545f-a95f-0170801469c4"
                    ]
                },
                "AD-3": {
                    "questionId": "AD-3",
                    "chosenOptionIds": [
                        "e6ce6121-8966-5331-9de6-225c495a82f4"
                    ]
                },
                "AD-4": {
                    "questionId": "AD-4",
                    "chosenOptionIds": [
                        "dc26ea00-568b-5208-bb47-a3a3167b2219"
                    ]
                },
                "AD-5": {
                    "questionId": "AD-5",
                    "chosenOptionIds": [
                        "614d3969-b25d-5bd8-84b9-d168fe0c4e43"
                    ]
                },
                "AD-6": {
                    "questionId": "AD-6",
                    "chosenOptionIds": [
                        "03859405-00f6-5f74-bf51-212297d409fa"
                    ]
                },
                "AD-7": {
                    "questionId": "AD-7",
                    "freeValue": 270
                },
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "d56877af-e8cf-54d7-9156-2130960436d9"
                    ]
                },
                "LR38": {
                    "questionId": "LR38",
                    "chosenOptionIds": [
                        "5cd60e24-de33-56f8-8b04-13d2e683ec0b",
                        "d6e1b247-4aa2-55fd-bce7-42c7fa8cea51",
                        "6c237476-6277-530e-bc2a-fe3482245062"
                    ]
                }
            }
        },
        {
            "name": "screened user: 63Y843R aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0NQJ0Q aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: XE7DM0L aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AD-1": {
                    "questionId": "AD-1",
                    "chosenOptionIds": [
                        "5f86c500-1e25-5d62-b1e2-c5eb2f1a303d"
                    ]
                },
                "AD-2": {
                    "questionId": "AD-2",
                    "chosenOptionIds": [
                        "20477c57-d15a-5aa2-8dd0-fc9d6e1f06e3"
                    ]
                },
                "AD-3": {
                    "questionId": "AD-3",
                    "chosenOptionIds": [
                        "e6ce6121-8966-5331-9de6-225c495a82f4"
                    ]
                },
                "AD-4": {
                    "questionId": "AD-4",
                    "chosenOptionIds": [
                        "dc26ea00-568b-5208-bb47-a3a3167b2219"
                    ]
                },
                "AD-5": {
                    "questionId": "AD-5",
                    "chosenOptionIds": [
                        "614d3969-b25d-5bd8-84b9-d168fe0c4e43"
                    ]
                },
                "AD-6": {
                    "questionId": "AD-6",
                    "chosenOptionIds": [
                        "03859405-00f6-5f74-bf51-212297d409fa"
                    ]
                },
                "AD-7": {
                    "questionId": "AD-7",
                    "freeValue": 4745
                },
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "d56877af-e8cf-54d7-9156-2130960436d9"
                    ]
                },
                "LR38": {
                    "questionId": "LR38",
                    "chosenOptionIds": [
                        "6c237476-6277-530e-bc2a-fe3482245062",
                        "d6e1b247-4aa2-55fd-bce7-42c7fa8cea51",
                        "4864aca1-818d-59ee-8da9-ff7a4e9c2b63"
                    ]
                }
            }
        },
        {
            "name": "screened user: 23XX43N aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "2f54c966-c1a2-57ac-82af-d3724cf9ac8f"
                    ]
                }
            }
        },
        {
            "name": "screened user: Y32LM08 aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: J3J4P0Y aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: 2E1580D aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AD-1": {
                    "questionId": "AD-1",
                    "chosenOptionIds": [
                        "cc006783-1c90-5563-b82b-27569e53841b"
                    ]
                },
                "AD-2": {
                    "questionId": "AD-2",
                    "chosenOptionIds": [
                        "20477c57-d15a-5aa2-8dd0-fc9d6e1f06e3"
                    ]
                },
                "AD-3": {
                    "questionId": "AD-3",
                    "chosenOptionIds": [
                        "e6ce6121-8966-5331-9de6-225c495a82f4"
                    ]
                },
                "AD-4": {
                    "questionId": "AD-4",
                    "chosenOptionIds": [
                        "fb98be42-399a-57a7-a353-1581ef3b84c7"
                    ]
                },
                "AD-5": {
                    "questionId": "AD-5",
                    "chosenOptionIds": [
                        "9d918248-bec6-57d3-bf94-b0f54531d598"
                    ]
                },
                "AD-6": {
                    "questionId": "AD-6",
                    "chosenOptionIds": [
                        "03859405-00f6-5f74-bf51-212297d409fa"
                    ]
                },
                "AD-7": {
                    "questionId": "AD-7",
                    "freeValue": 730
                },
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "d56877af-e8cf-54d7-9156-2130960436d9"
                    ]
                },
                "LR38": {
                    "questionId": "LR38",
                    "chosenOptionIds": [
                        "5cd60e24-de33-56f8-8b04-13d2e683ec0b"
                    ]
                }
            }
        },
        {
            "name": "screened user: O3PX60W aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: REOQG32 aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: 50MDW06 aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: 7EW6KE8 aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AD-1": {
                    "questionId": "AD-1",
                    "chosenOptionIds": [
                        "5f86c500-1e25-5d62-b1e2-c5eb2f1a303d"
                    ]
                },
                "AD-2": {
                    "questionId": "AD-2",
                    "chosenOptionIds": [
                        "891529c7-6886-545f-a95f-0170801469c4"
                    ]
                },
                "AD-3": {
                    "questionId": "AD-3",
                    "chosenOptionIds": [
                        "e6ce6121-8966-5331-9de6-225c495a82f4"
                    ]
                },
                "AD-4": {
                    "questionId": "AD-4",
                    "chosenOptionIds": [
                        "dc26ea00-568b-5208-bb47-a3a3167b2219"
                    ]
                },
                "AD-5": {
                    "questionId": "AD-5",
                    "chosenOptionIds": [
                        "614d3969-b25d-5bd8-84b9-d168fe0c4e43"
                    ]
                },
                "AD-6": {
                    "questionId": "AD-6",
                    "chosenOptionIds": [
                        "03859405-00f6-5f74-bf51-212297d409fa"
                    ]
                },
                "AD-7": {
                    "questionId": "AD-7",
                    "freeValue": 360
                },
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "d56877af-e8cf-54d7-9156-2130960436d9"
                    ]
                },
                "LR38": {
                    "questionId": "LR38",
                    "chosenOptionIds": [
                        "d6e1b247-4aa2-55fd-bce7-42c7fa8cea51"
                    ]
                }
            }
        },
        {
            "name": "screened user: J0VQM3L aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: 4EGRG3P aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z35L10O aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AD-1": {
                    "questionId": "AD-1",
                    "chosenOptionIds": [
                        "cc006783-1c90-5563-b82b-27569e53841b"
                    ]
                },
                "AD-2": {
                    "questionId": "AD-2",
                    "chosenOptionIds": [
                        "20477c57-d15a-5aa2-8dd0-fc9d6e1f06e3"
                    ]
                },
                "AD-3": {
                    "questionId": "AD-3",
                    "chosenOptionIds": [
                        "ce4ee6e1-3416-5740-a7b4-c0e48c96d697"
                    ]
                },
                "AD-4": {
                    "questionId": "AD-4",
                    "chosenOptionIds": [
                        "52680975-884a-5bef-9738-98b9d8d16f7a"
                    ]
                },
                "AD-5": {
                    "questionId": "AD-5",
                    "chosenOptionIds": [
                        "9d918248-bec6-57d3-bf94-b0f54531d598"
                    ]
                },
                "AD-6": {
                    "questionId": "AD-6",
                    "chosenOptionIds": [
                        "6d57f118-e094-52e4-8258-b0b28f3c5114"
                    ]
                },
                "AD-7": {
                    "questionId": "AD-7",
                    "freeValue": 120
                },
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "d56877af-e8cf-54d7-9156-2130960436d9"
                    ]
                },
                "LR38": {
                    "questionId": "LR38",
                    "chosenOptionIds": [
                        "5cd60e24-de33-56f8-8b04-13d2e683ec0b"
                    ]
                }
            }
        },
        {
            "name": "screened user: 63YM43R aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AD-1": {
                    "questionId": "AD-1",
                    "chosenOptionIds": [
                        "5f86c500-1e25-5d62-b1e2-c5eb2f1a303d"
                    ]
                },
                "AD-2": {
                    "questionId": "AD-2",
                    "chosenOptionIds": [
                        "891529c7-6886-545f-a95f-0170801469c4"
                    ]
                },
                "AD-3": {
                    "questionId": "AD-3",
                    "chosenOptionIds": [
                        "e6ce6121-8966-5331-9de6-225c495a82f4"
                    ]
                },
                "AD-4": {
                    "questionId": "AD-4",
                    "chosenOptionIds": [
                        "dc26ea00-568b-5208-bb47-a3a3167b2219"
                    ]
                },
                "AD-5": {
                    "questionId": "AD-5",
                    "chosenOptionIds": [
                        "614d3969-b25d-5bd8-84b9-d168fe0c4e43"
                    ]
                },
                "AD-6": {
                    "questionId": "AD-6",
                    "chosenOptionIds": [
                        "03859405-00f6-5f74-bf51-212297d409fa"
                    ]
                },
                "AD-7": {
                    "questionId": "AD-7",
                    "freeValue": 540
                },
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "d56877af-e8cf-54d7-9156-2130960436d9"
                    ]
                },
                "LR38": {
                    "questionId": "LR38",
                    "chosenOptionIds": [
                        "26ca934e-26fd-54b7-ab52-c162ddffbe00"
                    ]
                }
            }
        },
        {
            "name": "screened user: Y325838 aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: XE72M0L aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: ME8MOEW aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "2f54c966-c1a2-57ac-82af-d3724cf9ac8f"
                    ]
                }
            }
        },
        {
            "name": "screened user: 50M6136 aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AD-1": {
                    "questionId": "AD-1",
                    "chosenOptionIds": [
                        "cc006783-1c90-5563-b82b-27569e53841b"
                    ]
                },
                "AD-2": {
                    "questionId": "AD-2",
                    "chosenOptionIds": [
                        "891529c7-6886-545f-a95f-0170801469c4"
                    ]
                },
                "AD-3": {
                    "questionId": "AD-3",
                    "chosenOptionIds": [
                        "9494d279-dd58-5706-b368-d083f8e96425"
                    ]
                },
                "AD-4": {
                    "questionId": "AD-4",
                    "chosenOptionIds": [
                        "fb98be42-399a-57a7-a353-1581ef3b84c7"
                    ]
                },
                "AD-5": {
                    "questionId": "AD-5",
                    "chosenOptionIds": [
                        "9d918248-bec6-57d3-bf94-b0f54531d598"
                    ]
                },
                "AD-6": {
                    "questionId": "AD-6",
                    "chosenOptionIds": [
                        "637a6aa3-0919-5f8f-a871-3549131afcbf"
                    ]
                },
                "AD-7": {
                    "questionId": "AD-7",
                    "freeValue": 365
                },
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "d56877af-e8cf-54d7-9156-2130960436d9"
                    ]
                },
                "LR38": {
                    "questionId": "LR38",
                    "chosenOptionIds": [
                        "d6e1b247-4aa2-55fd-bce7-42c7fa8cea51",
                        "26ca934e-26fd-54b7-ab52-c162ddffbe00"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z35MQ3O aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AD-1": {
                    "questionId": "AD-1",
                    "chosenOptionIds": [
                        "cc006783-1c90-5563-b82b-27569e53841b"
                    ]
                },
                "AD-2": {
                    "questionId": "AD-2",
                    "chosenOptionIds": [
                        "20477c57-d15a-5aa2-8dd0-fc9d6e1f06e3"
                    ]
                },
                "AD-3": {
                    "questionId": "AD-3",
                    "chosenOptionIds": [
                        "9494d279-dd58-5706-b368-d083f8e96425"
                    ]
                },
                "AD-4": {
                    "questionId": "AD-4",
                    "chosenOptionIds": [
                        "dc26ea00-568b-5208-bb47-a3a3167b2219"
                    ]
                },
                "AD-5": {
                    "questionId": "AD-5",
                    "chosenOptionIds": [
                        "377912ba-72cd-571f-acee-44c2bc8b2363"
                    ]
                },
                "AD-6": {
                    "questionId": "AD-6",
                    "chosenOptionIds": [
                        "f3d8a43a-1eb9-5222-ab39-e3c1b1f5f520"
                    ]
                },
                "AD-7": {
                    "questionId": "AD-7",
                    "freeValue": 360
                },
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "d56877af-e8cf-54d7-9156-2130960436d9"
                    ]
                },
                "LR38": {
                    "questionId": "LR38",
                    "chosenOptionIds": [
                        "6c237476-6277-530e-bc2a-fe3482245062"
                    ]
                }
            }
        },
        {
            "name": "screened user: 73ZW63R aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AD-1": {
                    "questionId": "AD-1",
                    "chosenOptionIds": [
                        "cc006783-1c90-5563-b82b-27569e53841b"
                    ]
                },
                "AD-2": {
                    "questionId": "AD-2",
                    "chosenOptionIds": [
                        "cfd73f84-24e9-535b-ada3-99cb5889fdeb"
                    ]
                },
                "AD-3": {
                    "questionId": "AD-3",
                    "chosenOptionIds": [
                        "84a7ab1a-66e3-555f-a9fb-f5d2052e62c5"
                    ]
                },
                "AD-4": {
                    "questionId": "AD-4",
                    "chosenOptionIds": [
                        "2e503b7c-e5fe-5c3a-b406-1ed4a20a0403"
                    ]
                },
                "AD-5": {
                    "questionId": "AD-5",
                    "chosenOptionIds": [
                        "9d918248-bec6-57d3-bf94-b0f54531d598"
                    ]
                },
                "AD-6": {
                    "questionId": "AD-6",
                    "chosenOptionIds": [
                        "03859405-00f6-5f74-bf51-212297d409fa"
                    ]
                },
                "AD-7": {
                    "questionId": "AD-7",
                    "freeValue": 365
                },
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "d56877af-e8cf-54d7-9156-2130960436d9"
                    ]
                },
                "LR38": {
                    "questionId": "LR38",
                    "chosenOptionIds": [
                        "6c237476-6277-530e-bc2a-fe3482245062",
                        "5cd60e24-de33-56f8-8b04-13d2e683ec0b"
                    ]
                }
            }
        },
        {
            "name": "screened user: 4EG443P aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AD-1": {
                    "questionId": "AD-1",
                    "chosenOptionIds": [
                        "cc006783-1c90-5563-b82b-27569e53841b"
                    ]
                },
                "AD-2": {
                    "questionId": "AD-2",
                    "chosenOptionIds": [
                        "cfd73f84-24e9-535b-ada3-99cb5889fdeb"
                    ]
                },
                "AD-3": {
                    "questionId": "AD-3",
                    "chosenOptionIds": [
                        "ce4ee6e1-3416-5740-a7b4-c0e48c96d697"
                    ]
                },
                "AD-4": {
                    "questionId": "AD-4",
                    "chosenOptionIds": [
                        "2e503b7c-e5fe-5c3a-b406-1ed4a20a0403"
                    ]
                },
                "AD-5": {
                    "questionId": "AD-5",
                    "chosenOptionIds": [
                        "9d918248-bec6-57d3-bf94-b0f54531d598"
                    ]
                },
                "AD-6": {
                    "questionId": "AD-6",
                    "chosenOptionIds": [
                        "6d57f118-e094-52e4-8258-b0b28f3c5114"
                    ]
                },
                "AD-7": {
                    "questionId": "AD-7",
                    "freeValue": 90
                },
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "d56877af-e8cf-54d7-9156-2130960436d9"
                    ]
                },
                "LR38": {
                    "questionId": "LR38",
                    "chosenOptionIds": [
                        "6c237476-6277-530e-bc2a-fe3482245062"
                    ]
                }
            }
        },
        {
            "name": "screened user: REOG402 aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: J0V6N3L aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z3R6O0O aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z3R680O aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: R047J04 aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "2f54c966-c1a2-57ac-82af-d3724cf9ac8f"
                    ]
                }
            }
        },
        {
            "name": "screened user: O3PR70W aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AD-1": {
                    "questionId": "AD-1",
                    "chosenOptionIds": [
                        "5f86c500-1e25-5d62-b1e2-c5eb2f1a303d"
                    ]
                },
                "AD-2": {
                    "questionId": "AD-2",
                    "chosenOptionIds": [
                        "891529c7-6886-545f-a95f-0170801469c4"
                    ]
                },
                "AD-3": {
                    "questionId": "AD-3",
                    "chosenOptionIds": [
                        "e6ce6121-8966-5331-9de6-225c495a82f4"
                    ]
                },
                "AD-4": {
                    "questionId": "AD-4",
                    "chosenOptionIds": [
                        "0cec152e-1e7f-5ba8-ab55-4d35a78cf0ea"
                    ]
                },
                "AD-5": {
                    "questionId": "AD-5",
                    "chosenOptionIds": [
                        "614d3969-b25d-5bd8-84b9-d168fe0c4e43"
                    ]
                },
                "AD-6": {
                    "questionId": "AD-6",
                    "chosenOptionIds": [
                        "637a6aa3-0919-5f8f-a871-3549131afcbf"
                    ]
                },
                "AD-7": {
                    "questionId": "AD-7",
                    "freeValue": 180
                },
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "d56877af-e8cf-54d7-9156-2130960436d9"
                    ]
                },
                "LR38": {
                    "questionId": "LR38",
                    "chosenOptionIds": [
                        "d6e1b247-4aa2-55fd-bce7-42c7fa8cea51",
                        "26ca934e-26fd-54b7-ab52-c162ddffbe00",
                        "6c237476-6277-530e-bc2a-fe3482245062"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z35PN3O aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: ME87V3W aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AD-1": {
                    "questionId": "AD-1",
                    "chosenOptionIds": [
                        "cc006783-1c90-5563-b82b-27569e53841b"
                    ]
                },
                "AD-2": {
                    "questionId": "AD-2",
                    "chosenOptionIds": [
                        "20477c57-d15a-5aa2-8dd0-fc9d6e1f06e3"
                    ]
                },
                "AD-3": {
                    "questionId": "AD-3",
                    "chosenOptionIds": [
                        "ce4ee6e1-3416-5740-a7b4-c0e48c96d697"
                    ]
                },
                "AD-4": {
                    "questionId": "AD-4",
                    "chosenOptionIds": [
                        "fb98be42-399a-57a7-a353-1581ef3b84c7"
                    ]
                },
                "AD-5": {
                    "questionId": "AD-5",
                    "chosenOptionIds": [
                        "9d918248-bec6-57d3-bf94-b0f54531d598"
                    ]
                },
                "AD-6": {
                    "questionId": "AD-6",
                    "chosenOptionIds": [
                        "03859405-00f6-5f74-bf51-212297d409fa"
                    ]
                },
                "AD-7": {
                    "questionId": "AD-7",
                    "freeValue": 180
                },
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "d56877af-e8cf-54d7-9156-2130960436d9"
                    ]
                },
                "LR38": {
                    "questionId": "LR38",
                    "chosenOptionIds": [
                        "5cd60e24-de33-56f8-8b04-13d2e683ec0b",
                        "6c237476-6277-530e-bc2a-fe3482245062"
                    ]
                }
            }
        },
        {
            "name": "screened user: 2E17NED aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AD-1": {
                    "questionId": "AD-1",
                    "chosenOptionIds": [
                        "cc006783-1c90-5563-b82b-27569e53841b"
                    ]
                },
                "AD-2": {
                    "questionId": "AD-2",
                    "chosenOptionIds": [
                        "146614a4-8f78-5b23-9023-00289d053afe"
                    ]
                },
                "AD-3": {
                    "questionId": "AD-3",
                    "chosenOptionIds": [
                        "ce4ee6e1-3416-5740-a7b4-c0e48c96d697"
                    ]
                },
                "AD-4": {
                    "questionId": "AD-4",
                    "chosenOptionIds": [
                        "fb98be42-399a-57a7-a353-1581ef3b84c7"
                    ]
                },
                "AD-5": {
                    "questionId": "AD-5",
                    "chosenOptionIds": [
                        "377912ba-72cd-571f-acee-44c2bc8b2363"
                    ]
                },
                "AD-6": {
                    "questionId": "AD-6",
                    "chosenOptionIds": [
                        "03859405-00f6-5f74-bf51-212297d409fa"
                    ]
                },
                "AD-7": {
                    "questionId": "AD-7",
                    "freeValue": 365
                },
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "d56877af-e8cf-54d7-9156-2130960436d9"
                    ]
                },
                "LR38": {
                    "questionId": "LR38",
                    "chosenOptionIds": [
                        "6c237476-6277-530e-bc2a-fe3482245062",
                        "93eedc10-44cc-5759-8cea-df93ae21e4d3"
                    ]
                }
            }
        },
        {
            "name": "screened user: PE61J0L aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: 50MVM06 aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AD-1": {
                    "questionId": "AD-1",
                    "chosenOptionIds": [
                        "cc006783-1c90-5563-b82b-27569e53841b"
                    ]
                },
                "AD-2": {
                    "questionId": "AD-2",
                    "chosenOptionIds": [
                        "146614a4-8f78-5b23-9023-00289d053afe"
                    ]
                },
                "AD-3": {
                    "questionId": "AD-3",
                    "chosenOptionIds": [
                        "ce4ee6e1-3416-5740-a7b4-c0e48c96d697"
                    ]
                },
                "AD-4": {
                    "questionId": "AD-4",
                    "chosenOptionIds": [
                        "fb98be42-399a-57a7-a353-1581ef3b84c7"
                    ]
                },
                "AD-5": {
                    "questionId": "AD-5",
                    "chosenOptionIds": [
                        "377912ba-72cd-571f-acee-44c2bc8b2363"
                    ]
                },
                "AD-6": {
                    "questionId": "AD-6",
                    "chosenOptionIds": [
                        "03859405-00f6-5f74-bf51-212297d409fa"
                    ]
                },
                "AD-7": {
                    "questionId": "AD-7",
                    "freeValue": 365
                },
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "d56877af-e8cf-54d7-9156-2130960436d9"
                    ]
                },
                "LR38": {
                    "questionId": "LR38",
                    "chosenOptionIds": [
                        "26ca934e-26fd-54b7-ab52-c162ddffbe00"
                    ]
                }
            }
        },
        {
            "name": "screened user: J3JK4EY aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: 7EW4D08 aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z3RLO3O aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AD-1": {
                    "questionId": "AD-1",
                    "chosenOptionIds": [
                        "5f86c500-1e25-5d62-b1e2-c5eb2f1a303d"
                    ]
                },
                "AD-2": {
                    "questionId": "AD-2",
                    "chosenOptionIds": [
                        "20477c57-d15a-5aa2-8dd0-fc9d6e1f06e3"
                    ]
                },
                "AD-3": {
                    "questionId": "AD-3",
                    "chosenOptionIds": [
                        "9494d279-dd58-5706-b368-d083f8e96425"
                    ]
                },
                "AD-4": {
                    "questionId": "AD-4",
                    "chosenOptionIds": [
                        "dc26ea00-568b-5208-bb47-a3a3167b2219"
                    ]
                },
                "AD-5": {
                    "questionId": "AD-5",
                    "chosenOptionIds": [
                        "377912ba-72cd-571f-acee-44c2bc8b2363"
                    ]
                },
                "AD-6": {
                    "questionId": "AD-6",
                    "chosenOptionIds": [
                        "03859405-00f6-5f74-bf51-212297d409fa"
                    ]
                },
                "AD-7": {
                    "questionId": "AD-7",
                    "freeValue": 365
                },
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "d56877af-e8cf-54d7-9156-2130960436d9"
                    ]
                },
                "LR38": {
                    "questionId": "LR38",
                    "chosenOptionIds": [
                        "4864aca1-818d-59ee-8da9-ff7a4e9c2b63",
                        "93eedc10-44cc-5759-8cea-df93ae21e4d3"
                    ]
                }
            }
        },
        {
            "name": "screened user: J3JZREY aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "2f54c966-c1a2-57ac-82af-d3724cf9ac8f"
                    ]
                }
            }
        },
        {
            "name": "screened user: XE7WX3L aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: PE64M0L aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AD-1": {
                    "questionId": "AD-1",
                    "chosenOptionIds": [
                        "cc006783-1c90-5563-b82b-27569e53841b"
                    ]
                },
                "AD-2": {
                    "questionId": "AD-2",
                    "chosenOptionIds": [
                        "20477c57-d15a-5aa2-8dd0-fc9d6e1f06e3"
                    ]
                },
                "AD-3": {
                    "questionId": "AD-3",
                    "chosenOptionIds": [
                        "9494d279-dd58-5706-b368-d083f8e96425"
                    ]
                },
                "AD-4": {
                    "questionId": "AD-4",
                    "chosenOptionIds": [
                        "52680975-884a-5bef-9738-98b9d8d16f7a"
                    ]
                },
                "AD-5": {
                    "questionId": "AD-5",
                    "chosenOptionIds": [
                        "614d3969-b25d-5bd8-84b9-d168fe0c4e43"
                    ]
                },
                "AD-6": {
                    "questionId": "AD-6",
                    "chosenOptionIds": [
                        "03859405-00f6-5f74-bf51-212297d409fa"
                    ]
                },
                "AD-7": {
                    "questionId": "AD-7",
                    "freeValue": 9855
                },
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "d56877af-e8cf-54d7-9156-2130960436d9"
                    ]
                },
                "LR38": {
                    "questionId": "LR38",
                    "chosenOptionIds": [
                        "5cd60e24-de33-56f8-8b04-13d2e683ec0b",
                        "6c237476-6277-530e-bc2a-fe3482245062"
                    ]
                }
            }
        },
        {
            "name": "screened user: O3P263W aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        },
        {
            "name": "screened user: NELXPEV aspect: adj_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "LR37": {
                    "questionId": "LR37",
                    "chosenOptionIds": [
                        "ab920687-add2-51b6-a5d1-ae8b69095749"
                    ]
                }
            }
        }
    ]
};
exports.questionPredicates = {
    "LR38": "and[not(LR37=0), not(LR37=0.5)]",
    "AD-1": "and[not(LR37=0), not(LR37=0.5)]",
    "AD-2": "and[not(LR37=0), not(LR37=0.5)]",
    "AD-3": "and[not(LR37=0), not(LR37=0.5)]",
    "AD-4": "and[not(LR37=0), not(LR37=0.5)]",
    "AD-5": "and[not(LR37=0), not(LR37=0.5)]",
    "AD-6": "and[not(LR37=0), not(LR37=0.5)]",
    "AD-7": "and[not(LR37=0), not(LR37=0.5)]"
};
