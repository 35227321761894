import { useState, useEffect } from 'react'
import { ReportUrlData, reportUrlQuery } from '../../../data/queries/reportUrl'
import { QueryReportUrlArgs } from 'censeo-core'
import { useLazyQuery } from '@apollo/client'
import * as Sentry from '@sentry/react'

const POLL_FOR_PDF_TIME_LIMIT = 25000
const POLL_FOR_PDF_INTERVAL_MS = 3000

export function downloadFromLink(linkHref: string): void {
    const link = document.createElement('a')
    link.href = linkHref
    link.target = '_blank'
    link.download = ''
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}

interface UsePdfDownloadProps {
    id: string
    skip: boolean
    patientFacing: boolean
}

export function usePdfDownload({ id, skip, patientFacing }: UsePdfDownloadProps): {
    foundPdf: boolean
    pdfErrorToShow: boolean
    closePdfError: () => void
    handlePdfDownloadClick: () => void
} {
    const [savedId, setSavedId] = useState<string | null>(null)
    const [fetchPdfUrl, { loading: pdfLoading, data: pdfData, error: pdfError }] = useLazyQuery<
        ReportUrlData,
        QueryReportUrlArgs
    >(reportUrlQuery, {
        variables: {
            id,
            patientFacing,
        },
        fetchPolicy: 'no-cache',
    })

    const [isFirstFetchDone, setIsFirstFetchDone] = useState<boolean>(false)
    const [foundPdf, setFoundPdf] = useState<boolean>(false)
    const [isPdfDataFetched, setIsPdfDataFetched] = useState<boolean>(false)

    useEffect(() => {
        setIsFirstFetchDone(false)
        setSavedId(id)
    }, [id])

    useEffect(() => {
        if (savedId && !pdfData && !skip && !isFirstFetchDone) {
            fetchPdfUrl({ variables: { id: savedId, patientFacing } })

            setIsFirstFetchDone(true)
        }
    }, [savedId, pdfData, skip])

    useEffect(() => {
        if (isPdfDataFetched && isFirstFetchDone) {
            if (foundPdf && pdfData?.reportUrl.url) {
                downloadFromLink(pdfData.reportUrl.url)
            }
            if (!foundPdf && pdfData?.reportUrl.url) {
                setFoundPdf(true)
            }
            setIsPdfDataFetched(false)
        }
    }, [isPdfDataFetched, pdfData, foundPdf, isFirstFetchDone])

    useEffect(() => {
        if (pdfData && !pdfLoading) {
            setIsPdfDataFetched(true)
        }
    }, [savedId, pdfData, pdfLoading])

    useEffect(() => {
        let pollingIntervalId: ReturnType<typeof setInterval> | undefined
        let timeoutToStopPolling: ReturnType<typeof setTimeout> | undefined

        if (!isFirstFetchDone) {
            return
        }

        if (savedId && !foundPdf) {
            pollingIntervalId = setInterval(() => {
                if (!skip) {
                    fetchPdfUrl({ variables: { id: savedId, patientFacing } })
                }
            }, POLL_FOR_PDF_INTERVAL_MS)
            timeoutToStopPolling = setTimeout(() => {
                clearInterval(pollingIntervalId)
            }, POLL_FOR_PDF_TIME_LIMIT)
        }
        return () => {
            if (pollingIntervalId) clearInterval(pollingIntervalId)
            if (timeoutToStopPolling) clearTimeout(timeoutToStopPolling)
        }
    }, [foundPdf, savedId, skip, fetchPdfUrl, isFirstFetchDone])

    function handlePdfDownloadClick() {
        setIsPdfDataFetched(false)
        fetchPdfUrl({
            variables: {
                id,
                patientFacing,
            },
        })
    }

    const [showPdfModal, setShowPdfModal] = useState<boolean>(false)

    useEffect(() => {
        if (pdfError && foundPdf) {
            setShowPdfModal(true)
        } else {
            setShowPdfModal(false)
        }
    }, [pdfError, foundPdf])

    useEffect(() => {
        if (pdfError) {
            Sentry.captureException(pdfError, {
                extra: { detail: `Report id: ${id}` },
                tags: { section: 'Load report', reportVersion: 2 },
            })
        }
    }, [pdfError])

    return {
        foundPdf,
        pdfErrorToShow: showPdfModal,
        closePdfError: () => {
            setShowPdfModal(false)
        },
        handlePdfDownloadClick,
    }
}
