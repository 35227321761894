import { Button, Heading, Page, Text } from '@psyomics/components'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { InfoContext } from 'configure'
import Styles from './Demo.module.scss'
import { MixpanelContext } from 'registration/context/mixpanel/mixpanel-context'
import { useMixpanelEvent } from 'ui/app/useMixpanelEvent'
import { createReferral, tokenRequest } from 'registration/utils/inboundApi'
import DemoFooter from 'ui/app/components/DemoFooter'
import Header from 'ui/app/components/Header'

interface IPatientIntroProps {
    demoType: string
}

const PatientIntro: React.FC<IPatientIntroProps> = ({ demoType }) => {
    const info = useContext(InfoContext)
    const navigate = useNavigate()
    const mixpanelApiUrl = useContext(MixpanelContext)
    const { visitedDemoPatientIntro } = useMixpanelEvent(mixpanelApiUrl)

    useEffect(() => {
        visitedDemoPatientIntro(undefined, undefined)
    }, [])

    const [error, setError] = useState<string | undefined>(undefined)
    const [buttonLoading, setButtonLoading] = useState<boolean>(false)

    async function startReferral() {
        try {
            if (!info) {
                throw new Error('No data from info endpoint')
            }
            setButtonLoading(true)
            const demoClientId = demoType === 'LONG' ? info?.longDemoClientApp?.id : info?.demoClientApp?.id
            const demoClientSecret = demoType === 'LONG' ? info?.longDemoClientApp?.secret : info?.demoClientApp?.secret
            if (!demoClientId || !demoClientSecret) {
                const errorMessage = `Demo client credentials not defined`
                Sentry.captureException(errorMessage)
                setError(errorMessage)
                setButtonLoading(false)
                return
            }
            const {
                errorMessage: tokenErrorMessage,
                accessToken,
                tokenType,
            } = await tokenRequest(info.inboundApiUrl, demoClientId, demoClientSecret)

            if (tokenErrorMessage) {
                Sentry.captureException(new Error(tokenErrorMessage))
                setError(tokenErrorMessage)
                setButtonLoading(false)
                return
            }

            if (!accessToken || !tokenType) {
                const errorMessage =
                    'access token or token type not defined: accessToken:' + accessToken + ' tokenType:' + tokenType
                Sentry.captureException(new Error(errorMessage))
                setError(errorMessage)
                setButtonLoading(false)
                return
            }

            const { errorMessage: referralErrorMessage, publicReferralId } = await createReferral(
                info.inboundApiUrl,
                accessToken,
                tokenType,
                'DemoEprId',
                Date.now().toString()
            )

            if (referralErrorMessage) {
                Sentry.captureException(new Error(referralErrorMessage))
                setError(referralErrorMessage)
                setButtonLoading(false)
                return
            }

            if (!publicReferralId) {
                const errorMessage = 'no referral public id retrieved'
                Sentry.captureException(new Error(errorMessage))
                setError(errorMessage)
                setButtonLoading(false)
                return
            }

            navigate('/referral/' + publicReferralId)
        } catch (err) {
            Sentry.captureException(err)
            setError('Failed to get create and redirect to referral')
            setButtonLoading(false)
        }
    }

    return (
        <Page
            style={{ backgroundColor: 'transparent' }}
            layout="standard"
            width="narrow"
            header={<Header patientFacing={false} demoMenu={true} />}
            footer={<DemoFooter footerType="patient" />}
        >
            <Heading el="h2" size="heading2" color="mid" css={{ mb: 2 }}>
                User Journey
            </Heading>
            <Text size="medium" css={{ mb: 5 }}>
                You are about to experience Censeo as if you were a patient/service user.
            </Text>
            <Text size="medium" css={{ mb: 5 }}>
                Firstly you will be asked to create an account, this enables users to come back to the assessment if a
                break is needed. Patients/service users are required to do this and this demo replicates that
                experience, however, there is no eligibility check based on the information you enter. You will then be
                asked to answer a series of questions that are adaptive based on your previous answers.
            </Text>
            <Text size="medium" css={{ mb: 5 }}>
                All entry into the demo site during sign-up and answer data is strictly confidential and will not be
                used for marketing or analytical purposes.
            </Text>
            {error && (
                <Text weight="demibold" css={{ mt: 5 }}>
                    Sorry we are unable to allow you access to the &quot;Start Patient Flow&quot; functionality at the
                    moment. If you want access, or believe this is a mistake and you should have access, please contact
                    support at <a href="mailto:support@censeo.co.uk">support@censeo.co.uk</a>.
                </Text>
            )}
            <div className={Styles.btnWrapper}>
                <Button
                    className="text-right"
                    appearance="primary"
                    rounded
                    loading={buttonLoading}
                    disabled={!!error}
                    size="large"
                    label="Start Assessment"
                    fullWidth={true}
                    onClick={() => {
                        startReferral()
                    }}
                />
            </div>
        </Page>
    )
}

export default PatientIntro
