"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.symptoms = void 0;
exports.symptoms = [
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} per week",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "GAD-s6a",
                    "optionSummaries": {
                        "9814dd2a-4c6e-579b-8cc1-c90b8284cb73": "Less than 1 day",
                        "4059602c-013b-5f54-890f-64e55583f3dc": "Between 1 and 3 days",
                        "6e2595a2-ea1e-5ca5-ab11-c65eb6cb6157": "More than 4 days",
                        "cc1895bc-b31f-503e-a147-172d1a0faa64": "7 days"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Duration of symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "For {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "GAD-s3a",
                    "optionSummaries": {
                        "75ea5311-003b-57dc-8ca5-e08f759cdf02": "less than 2 weeks",
                        "617c53a6-a5af-5b57-a7dc-1fc4fa175844": "between 2 weeks and 3 months",
                        "4bbc59ac-1581-5084-9e92-2b746637ff52": "between 3 and 5 months",
                        "ed0ad572-166e-587e-b191-2cbe81387f5a": "between 6 months and 1 year",
                        "adab9f0b-f6e7-54c4-9cf1-087cdf831412": "longer than 1 year",
                        "1943ebd6-08ab-5b8e-a57b-7e397d303526": "most of their life"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Duration of symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} worrying",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "GAD-s1",
                    "optionSummaries": {
                        "0a2185e8-e4b7-5f48-a3ff-2fa717d647dd": "mild",
                        "24032a9e-f5b6-5f43-8ddf-305888669ad1": "moderate",
                        "f8d49124-01dc-5d55-add9-5ed87d998e00": "significant",
                        "c819bfe0-f79d-5132-8b3a-8604c0c5b83a": "severe"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Psychological symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "CombinedAnswersSummary",
            "questionsIdGroups": [
                [
                    "GAD-6a"
                ],
                [
                    "GAD-7a"
                ]
            ],
            "groupsType": "Mean",
            "pattern": "{} difficulty controlling worries",
            "coefficients": {
                "0.1": "mild",
                "0.3": "moderate",
                "0.6": "severe"
            }
        },
        "section": [
            "Current episode",
            "Psychological symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "GAD-s4a",
                    "optionSummaries": {
                        "276f0539-3b02-5717-a333-3d5ac219a5b4": "worrying about one thing only",
                        "0caeb854-db8e-5a20-a344-0c9282f65f78": "worrying about a few things",
                        "5a1c5af4-b628-5554-bf63-4ca68ef2b328": "worrying about many things",
                        "9d0741b6-d8b6-5216-90d0-df6743af1aa0": "worrying about everything"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Psychological symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "worries {}out of proportion",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "GAD-5a",
                    "optionSummaries": {
                        "63fdf718-12b4-5497-abff-2ae6a86b484b": "slightly ",
                        "6bb8be71-d051-540f-8d81-5dff1869bbcc": "moderately ",
                        "bd66ed3d-4e63-5fe8-88a6-fbbf3ab55502": "very much ",
                        "59b5a96e-b0f6-5fd3-8c20-57c053f14b06": "severely "
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Psychological symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "worrying about {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "GAD-3a",
                    "optionSummaries": {
                        "b27d60cc-fc23-5128-8e5c-81f05cf254de": "the past",
                        "fa80af33-1ed9-5e02-abd3-85852c65204e": "the future",
                        "bdba5003-2589-507d-bb90-53534cc9b942": "the past and the future"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Psychological symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} emotional distress",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "GAD-18a",
                    "optionSummaries": {
                        "7cb98422-b8c9-5f92-aee6-74cf0192ea01": "mild",
                        "63b22d31-0933-571a-9c46-eb63c6373884": "moderate",
                        "af347157-5a07-5187-b207-b672ecf979dc": "very much feeling",
                        "7035a10f-7dd7-55bd-946c-2cc73ffa5e6e": "severe"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Psychological symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} excessive worrying",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "GAD-4a",
                    "optionSummaries": {
                        "748f4a0b-00a7-5b15-b390-2dfb8ac9eb1d": "mild",
                        "517a9806-ffe8-52a9-86df-e947a47e169c": "moderate",
                        "84762e8e-edca-5c2c-a00f-fed69c13b87c": "severe"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Psychological symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "GAD-14a",
                    "optionSummaries": {
                        "6ac69add-b72f-5784-84a9-a517721463e6": "irritable infrequently",
                        "b0335c5e-d91f-5e19-9a45-4936bd9e3bea": "irritable about half the time",
                        "0623617f-3483-5ad7-ba3a-dd6b95c269d5": "usually irritable",
                        "e3ffd1b0-96fc-5dc0-8628-99e770d220ff": "always irritable"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Psychological symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "concentration difficulties {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "GAD-13a",
                    "optionSummaries": {
                        "42da2d35-01a4-50f9-95e5-c7bbc4c8eda7": "occur infrequently",
                        "8dda5767-c89f-5932-808d-a7729c3f935b": "occur about half the time",
                        "40abb98e-db00-5505-85be-59a5dd4ef092": "occur most of the time",
                        "15d0ef6b-b98e-5156-9b9b-c46a9dc15ed7": "occur constantly"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Psychological symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "GAD-8a",
                    "optionSummaries": {
                        "80887b2c-7c9f-5105-a64a-b3bb33442ac6": "feeling restless less than 1 day per week",
                        "e1dd4f50-28b4-51eb-8c34-bf8d3145816f": "feeling restless between 1 and 2 days per week",
                        "985dc1a3-7ea9-5f5c-8af3-6159ac9c90bf": "feeling restless between 3 and 4 days per week",
                        "a7ecd81c-cd6a-5592-892f-8e391b9a115c": "feeling restless between 5 and 6 days per week",
                        "ce522c31-e5ef-51e2-8fa5-d9826566ed1e": "always feeling restless"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Somatic symptoms (affecting the body)"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "GAD-15a",
                    "optionSummaries": {
                        "dfe08faa-d933-5ebc-b3dd-20cc46202224": "infrequently has trouble sitting still",
                        "632e07d1-df69-590c-a8f2-0b4f7ad343e1": "has trouble sitting still about half the time",
                        "6447e557-156f-59a1-9cbc-cd9d4b9bcbee": "usually has trouble sitting still",
                        "39f33fe3-3b5e-551a-8902-6a8c7ab4c148": "always has trouble sitting still"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Somatic symptoms (affecting the body)"
        ]
    },
    {
        "answerSummary": {
            "type": "CombinedAnswersSummary",
            "questionsIdGroups": [
                [
                    "GAD-9a"
                ],
                [
                    "GAD-10a"
                ]
            ],
            "groupsType": "Max",
            "pattern": "muscle tension and aches {}",
            "coefficients": {
                "1": "experienced daily",
                "0.2": "less than 1 day per week",
                "0.4": "between 1 and 2 days per week",
                "0.6": "between 3 and 4 days per week",
                "0.8": "between 5 and 6 days per week"
            }
        },
        "section": [
            "Current episode",
            "Somatic symptoms (affecting the body)"
        ]
    },
    {
        "answerSummary": {
            "type": "CombinedAnswersSummary",
            "questionsIdGroups": [
                [
                    "GAD-12a"
                ],
                [
                    "GAD-17a"
                ]
            ],
            "groupsType": "Max",
            "pattern": "{} fatigue and lack of energy",
            "coefficients": {
                "1": "severe",
                "0.25": "mild",
                "0.66": "moderate",
                "0.75": "significant"
            }
        },
        "section": [
            "Current episode",
            "Somatic symptoms (affecting the body)"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "sleep disturbances {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "GAD-11a",
                    "optionSummaries": {
                        "68c692fb-cc63-5ad6-b88e-5e45ad971c82": "less than 1 day per week",
                        "c1926d2c-b894-5ae1-bbdd-f8c9954ce55a": "between 1 and 2 days per week",
                        "ba121413-fa3c-5d39-960f-827d7b0d55b8": "between 3 and 4 days per week",
                        "62411dbb-3d28-57dc-ade4-d19a340fc6ed": "between 5 and 6 days per week",
                        "5779c477-4f6c-533d-a15b-33f106f84348": "experienced daily"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Somatic symptoms (affecting the body)"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Overall level of impairment: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "GAD-19.0a",
                    "optionSummaries": {
                        "647a8e7d-8b9b-583a-9cd3-30c3a4cc485c": "none",
                        "4196a0b8-4e73-5d21-903f-594531ff22f0": "mild",
                        "a0520f66-594b-5c56-bebc-a0d018d139d9": "moderate",
                        "78df6de1-fe3e-5bed-a63d-f5f9877809af": "significant",
                        "1341da8c-ffc8-5e54-9f02-4b2126601994": "severe"
                    }
                }
            ]
        },
        "section": [
            "Current episode",
            "Functional impairment"
        ]
    },
    {
        "answerSummary": {
            "type": "MultiOptionSummary",
            "questionId": "GAD-19a",
            "title": "Areas of impairment",
            "overrides": {
                "2f79dc47-8e9b-5b4a-a923-e43cde4b52ce": "Other (not listed)",
                "fd920af2-9846-4037-9b45-b08ba07accaf": "None reported"
            }
        },
        "section": [
            "Current episode",
            "Functional impairment"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "{} episodes of generalised anxiety",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "GAD-22a",
                    "optionSummaries": {
                        "8b112b00-59f5-5465-a3c7-d3eed9586abf": "No previous",
                        "09fba5ed-bf0e-5aa9-92a5-50c6a01ae0e2": "Previous",
                        "564b4697-78ea-5000-bb19-e1ed63265906": "Unsure about previous"
                    }
                }
            ]
        },
        "section": [
            "Previous episode",
            "Duration of symptoms"
        ]
    }
];
