"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.swap = exports.evaluate = exports.segmentStretchWire = exports.getLeaves = exports.map = exports.replaceLeaves = exports.toPredicate = exports.isEqualTo = exports.filter = exports.FilterPass = exports.not = exports.or = exports.and = exports.allOf = exports.anyOf = exports.isUnknown = exports.atLeast = exports.input = exports.segmentStretch = exports.max = exports.mean = exports.aspectValueBinaryFunction = void 0;
const alphaFromWidth = (width) => 10 / width;
const evaluateSigmoid = (sigmoid, value) => sigmoid.width === 0
    ? value >= sigmoid.middle
        ? 1
        : 0
    : 1 / (1 + Math.exp(-alphaFromWidth(sigmoid.width) * (value - sigmoid.middle)));
const aspectValueBinaryFunction = (f1, f2, f3) => (aspectValue1, aspectValue2) => {
    if (aspectValue1.type === 'severity') {
        if (aspectValue2.type === 'severity') {
            return f1(aspectValue1.value, aspectValue2.value);
        }
        else {
            return f2(aspectValue1.value, aspectValue2.value);
        }
    }
    else {
        if (aspectValue2.type === 'severity') {
            return f2(aspectValue2.value, aspectValue1.value);
        }
        else {
            return f3(aspectValue1.value, aspectValue2.value);
        }
    }
};
exports.aspectValueBinaryFunction = aspectValueBinaryFunction;
const mean = (wires, includeUnanswered) => {
    if (wires.length === 1) {
        return wires[0];
    }
    return {
        type: 'mean',
        includeUnanswered,
        wires,
    };
};
exports.mean = mean;
const max = (wires) => {
    if (wires.length === 1) {
        return wires[0];
    }
    return {
        type: 'max',
        wires,
    };
};
exports.max = max;
const segmentStretch = (wire, thresholds) => {
    return {
        type: 'segmentStretch',
        thresholds,
        wire,
    };
};
exports.segmentStretch = segmentStretch;
const input = (signal) => ({
    type: 'input',
    signal: signal,
});
exports.input = input;
const atLeast = (wires, howMany) => {
    if (wires.length === 1) {
        return wires[0];
    }
    return {
        type: 'merge',
        wires,
        anyToAll: howMany / wires.length,
    };
};
exports.atLeast = atLeast;
const isUnknown = (wire) => ({
    type: 'isUnknown',
    wire,
});
exports.isUnknown = isUnknown;
const anyOf = (wires) => (0, exports.atLeast)(wires, 1);
exports.anyOf = anyOf;
const allOf = (wires) => (0, exports.atLeast)(wires, wires.length);
exports.allOf = allOf;
const and = (wire1, wire2) => (0, exports.allOf)([wire1, wire2]);
exports.and = and;
const or = (wire1, wire2) => (0, exports.anyOf)([wire1, wire2]);
exports.or = or;
const not = (wire) => ({
    type: 'not',
    wire,
});
exports.not = not;
var FilterPass;
(function (FilterPass) {
    FilterPass[FilterPass["HighPass"] = 0] = "HighPass";
    FilterPass[FilterPass["LowPass"] = 1] = "LowPass";
})(FilterPass = exports.FilterPass || (exports.FilterPass = {}));
const filter = (threshold, filterPass, wire, width = 1) => ({
    type: 'filter',
    sigmoid: {
        middle: threshold,
        width,
    },
    lowPass: filterPass === FilterPass.LowPass ? true : undefined,
    wire,
});
exports.filter = filter;
const isEqualTo = (value, wire) => ({
    type: 'equals',
    wire,
    value,
});
exports.isEqualTo = isEqualTo;
const toPredicate = (wire) => ({
    type: 'toPredicate',
    wire,
});
exports.toPredicate = toPredicate;
const replaceLeaves = (f, wire) => {
    switch (wire.type) {
        case 'input':
            return f(wire.signal);
        case 'merge':
        case 'mean':
            return {
                ...wire,
                wires: wire.wires.map((w) => (0, exports.replaceLeaves)(f, w)),
            };
        case 'max':
            return {
                ...wire,
                wires: wire.wires.map((w) => (0, exports.replaceLeaves)(f, w)),
            };
        case 'not':
        case 'filter':
        case 'isUnknown':
        case 'equals':
        case 'toPredicate':
        case 'segmentStretch':
            return {
                ...wire,
                wire: (0, exports.replaceLeaves)(f, wire.wire),
            };
        case 'static':
            return wire;
    }
};
exports.replaceLeaves = replaceLeaves;
const map = (f, wire) => (0, exports.replaceLeaves)((signal) => ({
    type: 'input',
    signal: f(signal),
}), wire);
exports.map = map;
const getLeaves = (wire) => {
    switch (wire.type) {
        case 'input':
            return [wire.signal];
        case 'merge':
        case 'mean':
            return wire.wires.flatMap(exports.getLeaves);
        case 'max':
            return wire.wires.flatMap(exports.getLeaves);
        case 'not':
        case 'filter':
        case 'isUnknown':
        case 'equals':
        case 'toPredicate':
        case 'segmentStretch':
            return (0, exports.getLeaves)(wire.wire);
        case 'static':
            return [];
    }
};
exports.getLeaves = getLeaves;
const aspectValueNot = (aspectValue) => aspectValue.type === 'predicate'
    ? {
        ...aspectValue,
        value: !aspectValue.value,
    }
    : {
        ...aspectValue,
        value: aspectValue.value === 0.5 ? 0.4999 : 1 - aspectValue.value,
    };
const aspectValueAnd = (0, exports.aspectValueBinaryFunction)((d1, d2) => ({
    type: 'severity',
    value: fuzzyAnd(d1, d2),
}), (d, p) => ({
    type: 'severity',
    value: p ? d : 0.4999 * d,
}), (p1, p2) => ({
    type: 'predicate',
    value: p1 && p2,
}));
const getZeroValueAspect = () => ({ type: 'severity', value: 0 });
const aspectValueOr = (aspectValue1, aspectValue2) => aspectValueNot(aspectValueAnd(aspectValueNot(aspectValue1), aspectValueNot(aspectValue2)));
// const fuzzyOr = (a: number, b: number): number => Math.max(a, b) >= 0.5 ? (1 + a + b) / 3 : (a + b) / 2
const evaluateAnyOf = (as) => {
    const knownAspectsToUse = as.filter((aspectValue) => !!aspectValue);
    if (knownAspectsToUse.length === 0)
        return getZeroValueAspect();
    return knownAspectsToUse.reduce(aspectValueOr);
};
const fuzzyAnd = (a, b) => (Math.min(a, b) >= 0.5 ? 0.5 + (a + b - 1) / 2 : ((a + b) * 1) / 3);
const evaluateAllOf = (as) => {
    return as.map(replaceUndefinedAspectWithZero).reduce(aspectValueAnd);
};
const evaluateAtLeast = (as, howMany) => {
    const knownAspectsToUse = as.filter((aspectValue) => !!aspectValue);
    if (knownAspectsToUse.length < howMany) {
        const zerosToAdd = howMany - knownAspectsToUse.length;
        for (let i = 0; i < zerosToAdd; i++) {
            knownAspectsToUse.push(getZeroValueAspect());
        }
    }
    const subsets = knownAspectsToUse
        .reduce((acc, next) => [
        ...acc,
        ...acc.map((set) => [next, ...set]).filter((x) => x.length <= howMany), // filter for effieciency with large sets
    ], [[]])
        .filter((set) => set.length == howMany);
    return evaluateAnyOf(subsets.map((s) => evaluateAllOf(s)));
};
const replaceUndefinedAspectWithZero = (aspectValue) => {
    if (aspectValue === undefined)
        return getZeroValueAspect();
    return aspectValue;
};
const evaluateList = (inputs, f) => {
    const allIncomingResults = inputs.map(exports.evaluate);
    if (allIncomingResults.length === 0)
        return getZeroValueAspect();
    return f(allIncomingResults);
};
const meanWires = (wires, includeUnanswered) => {
    const correctionFactor = 100000;
    const evaluatedWithDefaults = wires.reduce((acc, wire) => {
        let evaluated = (0, exports.evaluate)(wire);
        if (!evaluated) {
            if (includeUnanswered) {
                evaluated = { type: 'severity', value: 0 };
            }
            else {
                return acc;
            }
        }
        if (evaluated.type === 'predicate') {
            acc.push((evaluated.value ? 1 : 0) * correctionFactor);
        }
        else {
            acc.push(evaluated.value * correctionFactor);
        }
        return acc;
    }, []);
    if (evaluatedWithDefaults.length === 0)
        return 0;
    return (evaluatedWithDefaults.reduce((acc, next) => acc + next, 0) / (evaluatedWithDefaults.length * correctionFactor));
};
const maxWires = (wires) => {
    const evaluatedWithDefaults = wires.reduce((acc, wire) => {
        const evaluated = (0, exports.evaluate)(wire);
        if (!evaluated)
            return acc;
        if (evaluated.type === 'predicate') {
            acc.push(evaluated.value ? 1 : 0);
        }
        else {
            acc.push(evaluated.value);
        }
        return acc;
    }, []);
    if (evaluatedWithDefaults.length === 0)
        return 0;
    return Math.max(...evaluatedWithDefaults);
};
const segmentStretchWire = (wire) => {
    const correctionFactor = 100000;
    const evaluated = (0, exports.evaluate)(wire.wire);
    const value = +((evaluated === null || evaluated === void 0 ? void 0 : evaluated.value) || 0); //undefined -> 0, false -> 0, true -> 1
    let prevFirst = 0;
    let prevLast = 1;
    let newFirst = 0;
    let newLast = 1;
    let indexOfFirstAbove = -1;
    for (let i = 0; i < wire.thresholds.length; i++) {
        if (value >= wire.thresholds[i][0])
            indexOfFirstAbove = i;
    }
    if (indexOfFirstAbove === -1) {
        prevLast = wire.thresholds[0][0];
        newLast = wire.thresholds[0][1];
    }
    else {
        prevFirst = wire.thresholds[indexOfFirstAbove][0];
        newFirst = wire.thresholds[indexOfFirstAbove][1];
        if (wire.thresholds[indexOfFirstAbove + 1]) {
            prevLast = wire.thresholds[indexOfFirstAbove + 1][0];
            newLast = wire.thresholds[indexOfFirstAbove + 1][1];
        }
    }
    let newValue = value;
    if (prevLast - prevFirst !== 0) {
        newValue =
            (((value * correctionFactor - prevFirst * correctionFactor) /
                (prevLast * correctionFactor - prevFirst * correctionFactor)) *
                (newLast * correctionFactor - newFirst * correctionFactor) +
                newFirst * correctionFactor) /
                correctionFactor;
    }
    return newValue;
};
exports.segmentStretchWire = segmentStretchWire;
const evaluate = (wire) => {
    switch (wire.type) {
        case 'input':
            return wire.signal;
        case 'mean':
            return {
                type: 'severity',
                value: meanWires(wire.wires, wire.includeUnanswered),
            };
        case 'max':
            return {
                type: 'severity',
                value: maxWires(wire.wires),
            };
        case 'merge': {
            let fuzzyOutput = undefined;
            if (wire.anyToAll === 1 / wire.wires.length) {
                fuzzyOutput = evaluateList(wire.wires, evaluateAnyOf);
            }
            else if (wire.anyToAll === 1) {
                fuzzyOutput = evaluateList(wire.wires, evaluateAllOf);
            }
            else {
                const howMany = wire.anyToAll * wire.wires.length;
                fuzzyOutput = evaluateList(wire.wires, (inputs) => evaluateAtLeast(inputs, howMany));
            }
            return fuzzyOutput === undefined ? undefined : fuzzyOutput;
        }
        case 'filter':
            return applyToEvaluated(wire.wire, (x) => {
                if (x.type === 'predicate') {
                    throw Error('cant evaluate filter on a predicate');
                }
                const signal = evaluateSigmoid(wire.sigmoid, x.value);
                return {
                    ...x,
                    value: wire.lowPass ? 1 - signal : signal,
                };
            });
        case 'toPredicate':
            return applyToEvaluated(wire.wire, (result) => ({
                type: 'predicate',
                value: result.type === 'severity' ? result.value >= 0.5 : result.value,
            }));
        case 'not':
            return applyToEvaluated(wire.wire, aspectValueNot);
        case 'isUnknown':
            return {
                type: 'predicate',
                value: (0, exports.evaluate)(wire.wire) === undefined,
            };
        case 'segmentStretch':
            return {
                type: 'severity',
                value: (0, exports.segmentStretchWire)(wire),
            };
        case 'equals':
            return applyToEvaluated(wire.wire, (result) => aspectIsEqual(result, wire.value));
    }
};
exports.evaluate = evaluate;
const aspectIsEqual = (aspectValue, value) => {
    if (aspectValue.type === 'severity') {
        return { type: 'predicate', value: aspectValue.value === value };
    }
    throw new Error('cant evaluate aspectIsEqual on a predicate');
};
const applyToEvaluated = (wire, f) => {
    const incoming = (0, exports.evaluate)(wire);
    return incoming === undefined ? undefined : f(incoming);
};
const swap = (wire, lookfor, replaceWith) => {
    if (wire === lookfor) {
        return replaceWith;
    }
    switch (wire.type) {
        case 'input':
            return wire;
        case 'merge':
        case 'mean':
            return {
                ...wire,
                wires: wire.wires.map((w) => (0, exports.swap)(w, lookfor, replaceWith)),
            };
        case 'max':
            return {
                ...wire,
                wires: wire.wires.map((w) => (0, exports.swap)(w, lookfor, replaceWith)),
            };
        case 'not':
        case 'filter':
        case 'isUnknown':
        case 'equals':
        case 'toPredicate':
            return {
                ...wire,
                wire: (0, exports.swap)(wire.wire, lookfor, replaceWith),
            };
        case 'static':
            return wire;
        case 'segmentStretch':
            return wire;
    }
};
exports.swap = swap;
